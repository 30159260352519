import axios from "axios";
import {
  getPendingBedTransfer,
  getCompletedBedTransfer,
  getAdmittedEDRs,
  getDischargedEDRs,
  getDeceasedEDRs,
  getTransferedEDRs,
  pendingEdTransfer,
  completedEdTransfer,
  getPendingEouTransfer,
  getPendingSurveyEdrs,
  getCompletedSurveyEdrs,
  getCompletedEouTransfer,
  getisUpdateCompletedEouTransfer,
  getisUpdateCompletedEDTransfer,
  getPendingDischargeEdrs,
  getCompletedDischargeEdrs,
  getisUpdateCompleteDischarge,
  getPendingMedications,
  getCompletedMedications,
  getUpdateMedicationStatus,
  getPendingAmbulance,
  getCompletedAmbulance,
  getUpdateAmbulanceRequest,
  updateNurseLabSample,
  getPsychiatrist,
  getMentalCare,
  getAdvocate,
  getCompletedAdmittedEDRs,
  getCompletedDeceasedEDRs,
  getCompletedDischargedEDRs,
  getCompletedTransferedEDRs,


  getCCTransferRequest
} from "../../public/endpoins";
import FormatDate from "../../utils/formatDate";

export const getTransferRequestCall = (
  CurrentUser,
  buttonValue,
  fromDepartment,
  toDepartment,
  setTransferEDtoEOUArray,
  setErrorMsg,
  setOpenNotification
) => {
  let apiCall;
  if (buttonValue === "pending") {
    apiCall = getCCTransferRequest;
  } else if (buttonValue === "completed") {
    apiCall = getCCTransferRequest;
  }
  axios
    .get(apiCall + "/" + CurrentUser, {toDept: toDepartment, fromDept: fromDepartment})
    .then((response) => {
      console.log("response transferEDtoEOU", response.data);

      response.data.data.map((val) => {
        val.patientName = val.edrId.patientId.name[0].given[0] + " " + val.edrId.patientId.name[0].family;
        val.roomNo = val.edrId.room[0].roomId.roomNo;
        val.mrn = val.edrId.patientId.identifier[0].value;
        val.episodeNo = val.edrId.requestNo;

        // Age
        var today = new Date();
        var birthDate = new Date(val.edrId.patientId.birthDate);
        var age_now = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
          age_now--;
        }
        val.ageNow = age_now;


      });
      console.log("hhh", response.data.data);
      setTransferEDtoEOUArray(response.data.data);
    })
    .catch((error) => {
      console.log("Error", error);
      setOpenNotification(true);
      setErrorMsg(error.message);
    });
};

export const getTransferBedCall = (
  CurrentUser,
  buttonValue,
  setTransferBedArray,
  setErrorMsg,
  setOpenNotification
) => {
  let apiCall;
  if (buttonValue === "pending") {
    apiCall = getPendingBedTransfer;
  } else if (buttonValue === "completed") {
    apiCall = getCompletedBedTransfer;
  }
      console.log("response transfertoBed", apiCall + "/" + CurrentUser);
  axios
    .get(apiCall + "/" + CurrentUser)
    .then((response) => {
      console.log("response transfertoBed", response.data);
      response.data.data.map(
        (val) => (
          // (val.productionArea = val.edrId.room[ val.edrId.room.length - 1 ].productionRoomId.paName),
          (val.roomNo = val.edrId.room[val.edrId.room.length - 1].roomId.roomNo),
          (val.requestNo = val.edrId.requestNo),
          (val.status = val.status)
        )
      );
      console.log("hhh", response.data.data);
      setTransferBedArray(response.data.data);
    })
    .catch((error) => {
      console.log("Error", error);
      setOpenNotification(true);
      setErrorMsg(error);
    });
};

export const getTransferEDtoEOUCall = (
  CurrentUser,
  buttonValue,
  setTransferEDtoEOUArray,
  setErrorMsg,
  setOpenNotification
) => {
  let apiCall;
  if (buttonValue === "pending") {
    apiCall = getPendingEouTransfer;
  } else if (buttonValue === "completed") {
    apiCall = getCompletedEouTransfer;
  }
  axios
    .get(apiCall + "/" + CurrentUser)
    .then((response) => {
      console.log("response transferEDtoEOU", response.data);
      response.data.data.map(
        (val) => (
          (val.productionArea =
            val.edrId.chiefComplaint[
              val.edrId.chiefComplaint.length - 1
            ].chiefComplaintId.productionArea[0].productionAreaId.paName),
          (val.roomNo =
            val.edrId.room[val.edrId.room.length - 1].roomId.roomNo),
          (val.mrn = val.edrId.patientId.identifier[0].value),
          (val.status = val.status)
        )
      );
      console.log("hhh", response.data.data);
      setTransferEDtoEOUArray(response.data.data);
    })
    .catch((error) => {
      console.log("Error", error);
      setOpenNotification(true);
      setErrorMsg(error);
    });
};
export const getTransferEOUtoEDCall = (
  CurrentUser,
  buttonValue,
  setTransferEOUtoEDArray,
  setErrorMsg,
  setOpenNotification
) => {
  let apiCall;
  if (buttonValue === "pending") {
    apiCall = pendingEdTransfer;
  } else if (buttonValue === "completed") {
    apiCall = completedEdTransfer;
  }
  axios
    .get(apiCall + "/" + CurrentUser)
    .then((response) => {
      console.log("response transferEOUtoED", response.data);
      response.data.data.map(
        (val) => (
          (val.productionArea =
            val.edrId.chiefComplaint[
              val.edrId.chiefComplaint.length - 1
            ].chiefComplaintId.productionArea[0].productionAreaId.paName),
          (val.roomNo =
            val.edrId.room[val.edrId.room.length - 1].roomId.roomNo),
          (val.mrn = val.edrId.patientId.identifier[0].value),
          (val.status = val.status)
        )
      );

      setTransferEOUtoEDArray(response.data.data);
    })
    .catch((error) => {
      console.log("Error", error);
      setOpenNotification(true);
      setErrorMsg(error);
    });
};

// update ED to EOU
export const getUpdateTransferEDtoEOUCall = (
  props,
  transferId,
  status,
  setErrorMsg,
  setOpenNotification
) => {
  axios
    .put(getisUpdateCompletedEouTransfer + "/" + transferId, {
      status: status === "in_progress" ? "completed" : "in_progress",
    })
    .then((response) => {
      if (response.data.success) {
        console.log("response after", response.data);

        props.history.push({
          pathname: "success",
          state: {
            message1: `Patient `,
            code: `MRN: ${response.data.data.edrId.patientId.identifier[0].value}`,
            message2: ` Transferred from ED to EOU successfully`,
          },
        });
      } else if (!response.data.success) {
        setOpenNotification(true);
        setErrorMsg("Error while updating the status");
      } else {
        setOpenNotification(true);
        setErrorMsg("Error while updating the status");
      }
    })
    .catch((error) => {
      console.log("Error", error);
      setOpenNotification(true);
      setErrorMsg(error);
    });
};
//update EOU to ED
export const getUpdateTransferEOUtoEDCall = (
  props,
  transferId,
  status,
  setErrorMsg,
  setOpenNotification
) => {
  axios
    .get(getisUpdateCompletedEDTransfer + "/" + transferId, {
      status: status === "in_progress" ? "completed" : "in_progress",
    })
    .then((response) => {
      if (response.data.success) {
        console.log("response after", response.data);

        props.history.push({
          pathname: "success",
          state: {
            message1: `Patient `,
            code: `MRN: ${response.data.data.edrId.patientId.identifier[0].value}`,
            message2: ` Transferred from EOU to ED successfully`,
          },
        });
      } else if (!response.data.success) {
        setOpenNotification(true);
        setErrorMsg("Error while updating the status");
      }
    })
    .catch((error) => {
      console.log("Error", error);
      setOpenNotification(true);
      setErrorMsg(error);
    });
};

export const getAmbulanceRequestCall = (
  CurrentUser,
  buttonValue,
  setAmbulanceRequestArray,
  setErrorMsg,
  setOpenNotification
) => {
  let apiCall;
  if (buttonValue === "pending") {
    apiCall = getPendingAmbulance;
  } else if (buttonValue === "completed") {
    apiCall = getCompletedAmbulance;
  }
  axios
    .get(apiCall + "/" + CurrentUser)
    .then((response) => {
      console.log("Response ambulance ", response.data);
      response.data.data.map(
        (val) => (
          (val.productionArea =
            val.edrId.chiefComplaint[val.edrId.chiefComplaint.length - 1] &&
            val.edrId.chiefComplaint[val.edrId.chiefComplaint.length - 1]
              .chiefComplaintId.productionArea[0].productionAreaId.paName
              ? val.edrId.chiefComplaint[val.edrId.chiefComplaint.length - 1]
                  .chiefComplaintId.productionArea[0].productionAreaId.paName
              : "N/A"),
          (val.roomNo =
            val.edrId.room[val.edrId.room.length - 1] &&
            val.edrId.room[val.edrId.room.length - 1].roomId.roomNo
              ? val.room[val.room.length - 1].roomId.roomNo
              : "N/A"),
          (val.requestedBy =
            val.staffId.name[0].given[0] + " " + val.staffId.name[0].family),
          (val.mrn = val.edrId.patientId.identifier[0].value)
        )
      );
      console.log("Response Pending", response.data.data);
      setAmbulanceRequestArray(response.data.data);
    })
    .catch((error) => {
      console.log("Error", error);
      setOpenNotification(true);
      setErrorMsg(error);
    });
};

export const getTransferredCall = (
  CurrentUser,
  buttonValue,
  setMedicationsRequestArray,
  setErrorMsg,
  setOpenNotification
) => {
  let apiCall;
  if (buttonValue === "pending") {
    apiCall = getPendingMedications;
  } else if (buttonValue === "completed") {
    apiCall = getCompletedMedications;
  }
  axios
    .get(apiCall + "/" + CurrentUser)
    .then((response) => {
      console.log("Responsee", response.data);
      response.data.data.map(
        (val) => (
          (val.productionArea =
            val.chiefComplaint[
              val.chiefComplaint.length - 1
            ].chiefComplaintId.productionArea[0].productionAreaId.paName),
          (val.roomNo = val.room[val.room.length - 1].roomId.roomNo),
          (val.pharmaStatus = val.pharmacyRequest.status),
          (val.pharmacistId = val.pharmacyRequest.pharmacyRequestNo),
          (val.mrn = val.patientId.identifier[0].value)
        )
      );
      setMedicationsRequestArray(response.data.data);
    })
    .catch((error) => {
      console.log("Error", error);
      setOpenNotification(true);
      setErrorMsg(error);
    });
};

export const getUpdateCompleteDischargeCall = (
  props,
  dischargeId,
  setErrorMsg,
  setOpenNotification
) => {
  axios
    .get(getisUpdateCompleteDischarge + "/" + dischargeId)
    .then((response) => {
      if (response.data.success) {
        console.log("response after", response.data);

        props.history.push({
          pathname: "success",
          state: {
            message1: `Discharged Request ${response.data.data.requestNo} for Patient `,
            code: `MRN: ${response.data.data.edrId.patientId.identifier[0].value}`,
            message2: ` has been submitted successfully`,
          },
        });
      } else if (!response.data.success) {
        setOpenNotification(true);
        setErrorMsg("Error while updating the status");
      }
    })
    .catch((error) => {
      console.log("Error", error);
      setOpenNotification(true);
      setErrorMsg(error);
    });
};

export const getDischargeCall = (
  CurrentUser,
  buttonValue,
  setDeceasedArray,
  setErrorMsg,
  setOpenNotification
) => {
  let apiCall;
  if (buttonValue === "pending") {
    apiCall = getPendingDischargeEdrs;
  } else if (buttonValue === "completed") {
    apiCall = getCompletedDischargeEdrs;
  }
  axios
    .get(apiCall + "/" + CurrentUser)
    .then((response) => {
      console.log("Response ", response.data);
      response.data.data.map(
        (val) => (
          // (val.productionArea =
          //   val.edrId.chiefComplaint[
          //     val.edrId.chiefComplaint.length - 1
          //   ].chiefComplaintId.productionArea[0].productionAreaId.paName),
          (val.roomNo =
            val.edrId.room.length > 0
              ? val.edrId.room[val.edrId.room.length - 1].roomId.roomNo
              : "N/A"),
          // (val.requestedBy =
          //   val.staffId.name[0].given[0] + " " + val.staffId.name[0].family),
          (val.mrn = val.edrId.patientId.identifier[0].value)
        )
      );
      setDeceasedArray(response.data.data);
    })
    .catch((error) => {
      console.log("Error", error);
      setOpenNotification(true);
      setErrorMsg(error);
    });
};

export function updateMedicationStatus(
  props,
  params,
  setOpenNotification,
  setErrorMsg
) {
  console.log("propss", props);
  axios
    .put(getUpdateMedicationStatus, params)
    .then((res) => {
      if (res.data.success) {
        console.log("response after medication update", res.data);

        // res.data.data.map((val.mrn = val.patientId.identifier[0].value));
        props.history.push({
          pathname: "success",
          state: {
            message1: `Medications Request by `,
            reqCode: `${
              res.data.data.pharmacyRequest[
                res.data.data.pharmacyRequest.length - 1
              ].pharmacyRequestNo
            }
          `,
            code: `MRN: ${res.data.data.mrn}`,

            message2: ` has been submitted successfully for Patient`,
          },
        });
      } else if (!res.data.success) {
        setOpenNotification(true);
        setErrorMsg("Error while updating the status");
      }
    })
    .catch((e) => {
      setOpenNotification(true);
      setErrorMsg("Error while updating the status");
    });
}
export function updateAmbulanceStatus(
  props,
  params,
  setOpenNotification,
  setErrorMsg
) {
  console.log("propss", props);
  axios
    .put(getUpdateAmbulanceRequest, params)
    .then((res) => {
      if (res.data.success) {
        console.log("response after ambulance update", res.data);

        // res.data.data.map((val.mrn = val.patientId.identifier[0].value));
        props.history.push({
          pathname: "success",
          state: {
            message1: `Ambulance Request`,
            reqCode: `${res.data.data.requestNo}`,
            code: `MRN: ${res.data.data.edrId.patientId.identifier[0].value}`,
            message2: ` has been generated successfully for Patient`,
          },
        });
      } else if (!res.data.success) {
        setOpenNotification(true);
        setErrorMsg("Error while updating the status");
      }
    })
    .catch((e) => {
      setOpenNotification(true);
      setErrorMsg("Error while updating the status");
    });
}

export const getPsychiatristCall = (
  setPsychiatristArray,
  setErrorMsg,
  setOpenNotification
) => {
  axios
    .get(getPsychiatrist)
    .then((response) => {
      console.log("Responseee", response.data);
      setPsychiatristArray(response.data.data);
    })
    .catch((error) => {
      console.log("Error", error);
      setOpenNotification(true);
      setErrorMsg(error);
    });
};

export const getMentalCareCall = (
  setMentalCareArray,
  setErrorMsg,
  setOpenNotification
) => {
  axios
    .get(getMentalCare)
    .then((response) => {
      console.log("Responseee", response.data);
      setMentalCareArray(response.data.data);
    })
    .catch((error) => {
      console.log("Error", error);
      setOpenNotification(true);
      setErrorMsg(error);
    });
};

export const getAdvocateCall = (
  setAdvocateArray,
  setErrorMsg,
  setOpenNotification
) => {
  axios
    .get(getAdvocate)
    .then((response) => {
      console.log("Responseee", response.data);
      setAdvocateArray(response.data.data);
    })
    .catch((error) => {
      console.log("Error", error);
      setOpenNotification(true);
      setErrorMsg(error);
    });
};
export function updateEouTransferStatus(
  props,
  params,
  setOpenNotification,
  setErrorMsg
) {
  console.log("propss", props);
  axios
    .put(updateNurseLabSample, params)
    .then((res) => {
      if (res.data.success) {
        console.log("response after", res.data);
        // res.data.data.map(
        //   (val) => (val.mrn = val.patientId.identifier[0].value)
        // );

        // res.data.data.map((val.mrn = val.patientId.identifier[0].value));
        props.history.push({
          pathname: "success",
          state: {
            reqCode: `${
              res.data.data.labRequest[res.data.data.labRequest.length - 1]
                .requestId
            }`,

            message3: `Lab Sample Collected Successfully for Patient`,
            code: `MRN: ${res.data.data.patientId.identifier[0].value}`,
          },
        });
      } else if (!res.data.success) {
        setOpenNotification(true);
        setErrorMsg("Error while updating the status");
      }
    })
    .catch((e) => {
      setOpenNotification(true);
      setErrorMsg("Error while updating the status");
    });
}
export const getPendingSurveyEdrsCall = (
  // CurrentUser,
  buttonValue,
  setSurveyArray,
  setErrorMsg,
  setOpenNotification
) => {
  let apiCall;
  if (buttonValue === "pending") {
    apiCall = getPendingSurveyEdrs;
  } else if (buttonValue === "completed") {
    apiCall = getCompletedSurveyEdrs;
  }
  axios
    .get(apiCall)
    .then((response) => {
      console.log("Pending Survey", response.data);
      response.data.data.map(
        (val) => (
          // (val.productionArea =
          //   val.chiefComplaint[val.chiefComplaint.length - 1] &&
          //   val.chiefComplaint[val.chiefComplaint.length - 1].chiefComplaintId
          //     .productionArea[0]
          //     ? val.chiefComplaint[val.chiefComplaint.length - 1]
          //         .chiefComplaintId.productionArea[0].productionAreaId.paName
          //     : "N/A"),
          (val.roomNo = val.room[val.room.length - 1]
            ? val.room[val.room.length - 1].roomId.roomNo
            : "N/A"),
          // (val.mrn = val.patientId?val.patientId.identifier[0].value:''),
          (val.mrn = val.requestNo?val.requestNo:''),
          (val.patientName = val.patientId? (val.patientId.name[0].given[0] + " " + val.patientId.name[0].family):'' )

          // (val.status = val.status)
        )
      );

      setSurveyArray(response.data.data);
    })
    .catch((error) => {
      console.log("Error", error);
      setOpenNotification(true);
      setErrorMsg(error.message);
    });
};
