/*eslint-disable*/
import React, { useState, useEffect, useReducer } from 'react'
import Button from '@material-ui/core/Button'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Notification from '../../components/Snackbar/Notification.js'
import CustomTable from '../../components/Table/Table'
import TextField from '@material-ui/core/TextField'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Loader from 'react-loader-spinner'
import AccountCircle from '@material-ui/icons/SearchOutlined'
import InputAdornment from '@material-ui/core/InputAdornment'
import MenuItem from '@material-ui/core/MenuItem'
import Fingerprint from '../../assets/img/fingerprint.png'
import BarCode from '../../assets/img/Bar Code.png'
import Header from '../../components/Header/Header'
import FlagManagementIcon from '../../assets/img/Housekeeping.png'
import eye_icon from '../../assets/img/Eye.png'
import Back from '../../assets/img/Back_Arrow.png'
import NewPatientIcon from '../../assets/img/New Patient.png'
import '../../assets/jss/material-dashboard-react/components/loaderStyle.css'
import cookie from 'react-cookies'
import classNames from "classnames";
import { displayInHumanDifference, replacePatientIdIfNull } from "../../utils/commonFunc";
import axios from "axios";
import {Dialog, DialogContent, DialogTitle, DialogContentText, DialogActions, FormControlLabel, Checkbox, withStyles } from '@material-ui/core'

import {
  addToFavoriteList,
  updateFavoriteList,
  getLabServiceCategory,
  getRadServiceCategory,
  getRadShortServiceCategory,
  getFavoriteList,
  getFavoriteItem
} from "../../public/endpoins";


const tableHeading = [
  'Favorites',
  'List Type',
  'Total Services',
  'Action',
  '',
]
const tableDataKeys = ['listName', 'listType', 'totalServices']

const actions = { edit: true }
const actions2 = { assign: true }

export default function Favorites(props) {

  const initialState = {
    listNewEntries: [],
    listId: ''
  };
  function reducer(state, { field, value }) {
    return {
      ...state,
      [field]: value,
    };
  }
  const [state, dispatch] = useReducer(reducer, initialState);
  const {
    listNewEntries,
    listId,
  } = state;

  const matches = useMediaQuery('(min-width:600px)')
  const [currentUser, setCurrentUser] = useState(cookie.load('current_user'))
  const [pendingSenseiArray, setPendingSenseiArray] = useState([])
  const [completedSenseiArray, setCompletedSenseiArray] = useState([])
  const [pendingPorterArray, setPendingPorterArray] = useState([])
  const [
    completedPorterArray,
    setCompletedPorterArray,
  ] = useState([])

  const [ serviceType, setServiceType ] = useState(props.match.params.serviceType);

  const [loading, setLoading] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')
  const [openNotification, setOpenNotification] = useState(false)
  const [newDialog, setNewDialog] = useState(false)
  const [editDialog, setEditDialog] = useState(false)
  
  const [cookies, setCookies] = useState(cookie.load('current_user'))

  const [ categoriesData, setCategoriesData] = useState([]);
  const [ labRequests, setLabRequests] = useState([])
  const [ newRequestName, setNewRequestName ] = useState("");

  const GreenCheckbox = withStyles({
    root: {
      color: '#AAAAAA',
      '&$checked': {
        color: '#AAAAAA',
      },
    },
    checked: {
      color: '#2C6DDD',
      '&$checked': {
        color: '#2C6DDD',
      },
    },
  })((props) => <Checkbox color="default" {...props} />);

  if (openNotification) {
    setTimeout(() => {
      setOpenNotification(false)
      setErrorMsg('')
    }, 2000)
  }

  useEffect(() => {
    console.log('props', props)
    // let currentNurseId = currentUser._id;
    console.log('current user', currentUser._id)

    getPatientRemoteRequest();
    loadAsyncData ();
  }, [])

  const getPatientRemoteRequest = () => {
    

    axios
      .get(`${getFavoriteList}?staffId=${currentUser._id}&listType=${serviceType}`)
      .then((res) => {
        if (res.data.success) {
          res.data.data.map((val) => {
            val.totalServices = val.serviceIds.length;

            // Services
            let servicesNames = [];
            Object.entries(val.serviceIds).map(([key, val]) => {
              servicesNames.push(val.requestId)
            });
            val.serviceName = servicesNames.join(',');


          });
          // var allLabSorted = _.sortBy(res.data.data, "createdAt").reverse();
          setLabRequests(res.data.data.reverse())
        } else if (!res.data.success) {
          setErrorMsg(res.data.error)
          setOpenNotification(true)
        }
        return res
      })
      .catch((e) => {
        console.log('error: ', e)
      })
  }

  const loadAsyncData = () => {
    axios
      .get(serviceType==="rad"?getRadShortServiceCategory:getLabServiceCategory)
      .then((res) => {
        if (res.data.success) {
          // console.log('labServiceCategoryCall', res.data.data);
          let remoteData = res.data.data;
          setCategoriesData(remoteData);
        }
      })
      .catch((e) => {
        console.log("error while searching req", e);
      });
  }

  const handleNewChange = (event) => {
    console.log('handleNewChange::', event.target.value)
    let readyEntries = listNewEntries;
    if ( readyEntries.includes( event.target.value ) ){ // Already Exists Item
      let indexOfItem = readyEntries.indexOf( event.target.value );
      readyEntries.splice( indexOfItem, 1 );
    }else{ // Adding Item
      readyEntries.push( event.target.value );
    }

    dispatch({
      field: 'listNewEntries',
      value: readyEntries
    });
  }

  const handleNewAddition = () => {
    if (newRequestName.trim().length > 0){
      if ( listNewEntries.length > 0){
        let idsToSave = [];
        Object.entries(listNewEntries).map((obj, item) => {
          idsToSave.push({
            requestId: obj[1]
          })
        })

        const params = {
          listName: newRequestName,
          serviceIds: idsToSave,
          staffId: currentUser._id,
          listType: serviceType,
        };

        axios
          .put(addToFavoriteList, params)
          .then((res) => {
            if (res.data.success) {
              window.location.reload();
            } else if (!res.data.success) {
              setOpenNotification(true);
              setErrorMsg("Error while adding the Lab Request");
            }
          })
          .catch((e) => {
            console.log('ConsoleLabRequest', "error after adding Lab Request", e);
            setOpenNotification(true);
            setErrorMsg("An error occurred while adding the Lab Request.");
          });
      }else{
        setOpenNotification(true)
        setErrorMsg('Please select some items.');
      }
    }else{
      setOpenNotification(true)
      setErrorMsg('Please enter a valid list name!')
    }
  }

  const handleUpdateAddition = () => {
    if (newRequestName.trim().length > 0){
      if ( listNewEntries.length > 0){
        let idsToSave = [];
        Object.entries(listNewEntries).map((obj, item) => {
          idsToSave.push({
            requestId: obj[1]
          })
        })

        const params = {
          listName: newRequestName,
          serviceIds: idsToSave,
          staffId: currentUser._id,
          listType: serviceType,
          listId: listId,
        };

        axios
          .put(updateFavoriteList, params)
          .then((res) => {
            if (res.data.success) {
              window.location.reload();
            } else if (!res.data.success) {
              setOpenNotification(true);
              setErrorMsg("Error while adding the Lab Request");
            }
          })
          .catch((e) => {
            console.log('ConsoleLabRequest', "error after adding Lab Request", e);
            setOpenNotification(true);
            setErrorMsg("An error occurred while adding the Lab Request.");
          });
      }else{
        setOpenNotification(true)
        setErrorMsg('Please select some items.');
      }
    }else{
      setOpenNotification(true)
      setErrorMsg('Please enter a valid list name!')
    }
  }

  const handleEdit = (rec) => {
    axios
      .get(`${getFavoriteItem}?staffId=${currentUser._id}&favoriteId=${rec._id}`)
      .then((res) => {
        if (res.data.success) {
          // console.log('labServiceCategoryCall', res.data.data);
          let remoteData = res.data.data;
          // setCategoriesData(remoteData);

          setNewRequestName(remoteData.listName);

          let readyEntries = [];
          Object.entries(remoteData.serviceIds).map((obj, ind) => {
            readyEntries.push(obj[1].requestId);
          })


          dispatch({ field: 'listId', value: remoteData._id });
          dispatch({
            field: 'listNewEntries',
            value: readyEntries
          });

          setEditDialog(true);
        }else{
          setOpenNotification(true)
          setErrorMsg('Sorry no record found')
        }
      })
      .catch((e) => {
        setOpenNotification(true)
        setErrorMsg('Something went wrong...')
      });
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        position: 'fixed',
        width: '100%',
        height: '100%',
        backgroundColor: '#60d69f',
        overflowY: 'scroll',
      }}
    >
      <Header history={props.history} />

      <div className='cPadding'>
        <div className='subheader'>
          <div>
            <img src={NewPatientIcon} style={{ marginLeft: '0px' }} />
            <h4>Favorite List</h4>
          </div>
          <Button
              onClick={() => {
                setNewDialog(true)
              }}
              style={{
                fontSize: matches ? ' ' : 10,
              }}
              variant='contained'
              color='primary'
            >
              <img src={eye_icon} className='icon-view' />
              &nbsp;&nbsp;
              <strong>New List</strong>
            </Button>
        </div>

        <div
          className='container-fluid'
          style={{ paddingLeft: 0, paddingRight: 0 }}
        >

          <div className='row row-5'>

            <CustomTable
              tableData={labRequests}
              tableDataKeys={tableDataKeys}
              tableHeading={tableHeading}
              handleEdit={handleEdit}
              action={
                { edit: true }
              }
              borderBottomColor={"#60d69f"}
              borderBottomWidth={20}
            />


            {labRequests !== ' ' ? (
              <>
              
                <Notification msg={errorMsg} open={openNotification} />

                <div className="col-12" style={{ marginBottom: 20, marginTop: 50 }}>
                  <img
                    onClick={() => props.history.goBack()}
                    src={Back}
                    style={{ width: 45, height: 35, cursor: 'pointer' }}
                  />
                </div>
              </>
            ) : (
              <div className='row ' style={{ marginTop: '25px' }}>
                <div className='col-11'>
                  <h3
                    style={{
                      color: 'white',
                      textAlign: 'center',
                      width: '100%',
                      position: 'absolute',
                    }}
                  >
                    Opps...No Data Found
                  </h3>
                </div>
                <div className='col-1' style={{ marginTop: 45 }}>
                  {/* <img
                    onClick={() => props.history.goBack()}
                    src={Back_Arrow}
                    style={{
                      maxWidth: '60%',
                      height: 'auto',
                      cursor: 'pointer',
                    }}
                  /> */}
                </div>
              </div>
            )}            
          </div>
        </div>


        <Dialog
          aria-labelledby="form-dialog-title"
          open={newDialog}
          maxWidth="lg">
          <DialogTitle id="simple-dialog-title"><strong>Favorite</strong>: New List</DialogTitle>
          <DialogContent>
            <DialogContentText>
              To create a new list just enter the list name and select some tests.
            </DialogContentText>
            <TextField
              required
              className="textInputStyle mb-3"
              id="newRequestName"
              type="text"
              variant="filled"
              label="List Name"
              name={"newRequestName"}
              value={newRequestName}
              onChange={(event) => {
                setNewRequestName( event.target.value );
              }}
              InputProps={{
                disableUnderline: true,
              }}
              error={newRequestName.trim() === ""}
              helperText={newRequestName.trim() === "" ? 'Required':undefined}
            />

            <div style={{ height: 360, overflowY: "scroll"}}>
            {
              categoriesData.length > 0 ? 
              categoriesData.map((d, index) => {
                return (
                  <div className="mt-3">
                    <h4>{ d._id }</h4>
                    {
                      d.items.length > 0 ? 
                        d.items.map((nd, nindex) => {
                          return (
                            <div className="">
                              <FormControlLabel
                                control={<GreenCheckbox checked={ listNewEntries.includes( nd._id ) } value={ nd._id } onChange={handleNewChange} />}
                                label={nd.name}
                                style={{
                                  margin: 0,
                                }}
                              />
                            </div>
                          )
                        }) : ''
                    }
                  </div>
                )
              })
              : ''
            }
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => {
              setNewDialog(false)
              setNewRequestName("");
              dispatch({ field: 'listId', value: '' });
              dispatch({ field: 'listNewEntries', value: [] });
            }} color="primary">Cancel</Button>
            <Button onClick={handleNewAddition} color="primary">Add to List</Button>
          </DialogActions>
        </Dialog>




        <Dialog
          aria-labelledby="form-dialog-title-2"
          open={editDialog}
          maxWidth="lg">
          <DialogTitle id="simple-dialog-title-2"><strong>Favorite</strong>: Update List</DialogTitle>
          <DialogContent>
            <DialogContentText>
              To update a list just enter the list name and select some tests.
            </DialogContentText>
            <TextField
              required
              className="textInputStyle mb-3"
              id="newRequestName"
              type="text"
              variant="filled"
              label="List Name"
              name={"newRequestName"}
              value={newRequestName}
              onChange={(event) => {
                setNewRequestName( event.target.value );
              }}
              InputProps={{
                disableUnderline: true,
              }}
              error={newRequestName.trim() === ""}
              helperText={newRequestName.trim() === "" ? 'Required':undefined}
            />

            <div style={{ height: 360, overflowY: "scroll"}}>
            {
              categoriesData.length > 0 ? 
              categoriesData.map((d, index) => {
                return (
                  <div className="mt-3">
                    <h4>{ d._id }</h4>
                    {
                      d.items.length > 0 ? 
                        d.items.map((nd, nindex) => {
                          return (
                            <div className="">
                              <FormControlLabel
                                control={<GreenCheckbox checked={ listNewEntries.includes( nd._id ) } value={ nd._id } onChange={handleNewChange} />}
                                label={nd.name}
                                style={{
                                  margin: 0,
                                }}
                              />
                            </div>
                          )
                        }) : ''
                    }
                  </div>
                )
              })
              : ''
            }
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => {
              setEditDialog(false)
              setNewRequestName("");
              dispatch({ field: 'listId', value: '' });
              dispatch({ field: 'listNewEntries', value: [] });
            }} color="primary">Cancel</Button>
            <Button onClick={handleUpdateAddition} color="primary">Update Favorite</Button>
          </DialogActions>
        </Dialog>

        <Notification msg={errorMsg} open={openNotification} />
      </div>
    </div>
  )
}
