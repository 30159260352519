export const priorityArray = [
  {
    key: 'High',
    value: 'High',
  },
  {
    key: 'Normal',
    value: 'Normal',
  },
  /*
  {
    key: 'Low',
    value: 'Low',
  },
  */
]

export const routeArray = [
  { key: "ROA000", value: "N/A" },
  { key: "ROA001", value: "DENTAL" },
  { key: "ROA002", value: "DENTAL INFILTRATION INJ. DENTAL BLOCK" },
  { key: "ROA003", value: "DENTAL INJECTION" },
  { key: "ROA007", value: "EPIDURAL" },
  { key: "ROA009", value: "CAUDAL" },
  { key: "ROA010", value: "EXTERNAL" },
  { key: "ROA011", value: "GENITAL" },
  { key: "ROA012", value: "IM" },
  { key: "ROA032", value: "IMPLANT" },
  { key: "ROA033", value: "INFILTRATION DENTAL BLOCK" },
  { key: "ROA034", value: "INHALATION" },
  { key: "ROA036", value: "INTRA-AMNIOTIC" },
  { key: "ROA037", value: "INTRA-ARTICULAR" },
  { key: "ROA039", value: "INTRACAVERNOSAL" },
  { key: "ROA040", value: "INTRACERVICAL" },
  { key: "ROA041", value: "INTRAGLUTEALLY" },
  { key: "ROA042", value: "INTRA-MASTITIS" },
  { key: "ROA043", value: "INTRAMMAMARY" },
  { key: "ROA045", value: "INTRA-PERIODONTAL POCKET" },
  { key: "ROA046", value: "INTRA-PERITONEAL" },
  { key: "ROA047", value: "INTRATRACHEAL" },
  { key: "ROA048", value: "INTRAUTERIN" },
  { key: "ROA049", value: "INTRAVAGINAL" },
  { key: "ROA050", value: "INTRA-VESICALLY" },
  { key: "ROA051", value: "IV" },
  { key: "ROA052", value: "IV CATHETER" },
  { key: "ROA053", value: "IV INFUSION" },
  { key: "ROA064", value: "LOCAL ORAL" },
  { key: "ROA065", value: "LOCAL ORAL DENTAL" },
  { key: "ROA066", value: "ORAL RINSE" },
  { key: "ROA067", value: "NASAL" },
  { key: "ROA071", value: "NEBULISATION" },
  { key: "ROA072", value: "OCULAR" },
  { key: "ROA074", value: "ORAL" },
  { key: "ROA075", value: "ORAL INHALATION" },
  { key: "ROA083", value: "ORALLY TUBE FEEDING" },
  { key: "ROA084", value: "OTIC" },
  { key: "ROA085", value: "PARENTERAL" },
  { key: "ROA087", value: "PERITONEAL DIALYSIS" },
  { key: "ROA088", value: "RECTAL" },
  { key: "ROA089", value: "SC" },
  { key: "ROA090", value: "SPINAL" },
  { key: "ROA091", value: "SUBLINGUAL" },
  { key: "ROA092", value: "TOPICAL" },
  { key: "ROA093", value: "TOPICAL ANAL" },
  { key: "ROA094", value: "TOPICAL- SCALP" },
  { key: "ROA095", value: "TRANSDERMAL" },
  { key: "ROA096", value: "TRANSMUCOSAL" },
  { key: "ROA097", value: "VAGINAL" },
  { key: "ROA100", value: "URETHRAL" },
  { key: "ROA101", value: "MISCELLANEOUS" },
  { key: "ROA103", value: "SUBARACHNOID" },
  { key: "ROA107", value: "INTRATHECAL" },
  { key: "ROA108", value: "INTRAOCULAR" },
  { key: "ROA109", value: "INTRACARDIAC" },
  { key: "ROA112", value: "INTRADERMAL" },
  { key: "ROA114", value: "INTRAVENTRICULAR" },
  { key: "ROA115", value: "SUBMUCOSAL" },
  { key: "ROA123", value: "INTRAPLEURAL" },
  { key: "ROA133", value: "INFILTRATION" },
  { key: "ROA141", value: "IRRIGATION" },
  { key: "ROA147", value: "SUBDERMAL" },
  { key: "ROA148", value: "BUCCAL" },
  { key: "ROA159", value: "ARTERIOVENOUS" },
  { key: "ROA160", value: "CENTRAL NERVE BLOCK" },
  { key: "ROA161", value: "DEEP SC" },
  { key: "ROA162", value: "DERMAL" },
  { key: "ROA163", value: "ENDOTRACHEAL" },
  { key: "ROA164", value: "ILIOHYPOGASTRIC (NERVE BLOCK)" },
  { key: "ROA165", value: "ILIONGUINAL" },
  { key: "ROA166", value: "INTRA-ARTERIAL" },
  { key: "ROA167", value: "INTRABURSAL" },
  { key: "ROA168", value: "INTRACAVITARY" },
  { key: "ROA169", value: "INTRALESIONAL" },
  { key: "ROA170", value: "INTRAMEDULLARILY" },
  { key: "ROA171", value: "INTRAOSSEOUS" },
  { key: "ROA172", value: "INTRA-PULMONARY" },
  { key: "ROA173", value: "INTRASPINAL" },
  { key: "ROA174", value: "INTRASUBLESIONAL" },
  { key: "ROA175", value: "INTRASYNOVIAL" },
  { key: "ROA176", value: "IV BOLUS" },
  { key: "ROA177", value: "OROFACIAL" },
  { key: "ROA178", value: "OROPHARYNGEAL" },
  { key: "ROA179", value: "PERIANAL" },
  { key: "ROA180", value: "PERIARTICULAR" },
  { key: "ROA181", value: "PERIBULBAR" },
  { key: "ROA182", value: "PERIPHERAL NERVE BLOCK" },
  { key: "ROA183", value: "SUBCONJUNCTIVAL" },
  { key: "ROA184", value: "SYMPATHETIC NERVE BLOCK" },
  { key: "ROA185", value: "TRANSCERVICAL" },
  { key: "ROA187", value: "SC INFUSION" },
  { key: "ROA191", value: "INTRAVITREAL" },
  { key: "ROA192", value: "CARDIAC PERFUSION" },
  { key: "ROA202", value: "INTRACAMERAL" },
  { key: "ROA206", value: "INTESTINAL INFUSION" },
  { key: "ROA207", value: "EPILESIONAL" },
  { key: "ROA209", value: "INTRAURETERIC" },
  { key: "ROA210", value: "INTRA-LYMPHATIC" },
  { key: "ROA222", value: "PERINEURAL" },
];



export const routeArrayNames = {
  "ROA000": "N/A",
  "ROA001": "DENTAL",
  "ROA002": "DENTAL INFILTRATION INJ. DENTAL BLOCK",
  "ROA003": "DENTAL INJECTION",
  "ROA007": "EPIDURAL",
  "ROA009": "CAUDAL",
  "ROA010": "EXTERNAL",
  "ROA011": "GENITAL",
  "ROA012": "IM",
  "ROA032": "IMPLANT",
  "ROA033": "INFILTRATION DENTAL BLOCK",
  "ROA034": "INHALATION",
  "ROA036": "INTRA-AMNIOTIC",
  "ROA037": "INTRA-ARTICULAR",
  "ROA039": "INTRACAVERNOSAL",
  "ROA040": "INTRACERVICAL",
  "ROA041": "INTRAGLUTEALLY",
  "ROA042": "INTRA-MASTITIS",
  "ROA043": "INTRAMMAMARY",
  "ROA045": "INTRA-PERIODONTAL POCKET",
  "ROA046": "INTRA-PERITONEAL",
  "ROA047": "INTRATRACHEAL",
  "ROA048": "INTRAUTERIN",
  "ROA049": "INTRAVAGINAL",
  "ROA050": "INTRA-VESICALLY",
  "ROA051": "IV",
  "ROA052": "IV CATHETER",
  "ROA053": "IV INFUSION",
  "ROA064": "LOCAL ORAL",
  "ROA065": "LOCAL ORAL DENTAL",
  "ROA066": "ORAL RINSE",
  "ROA067": "NASAL",
  "ROA071": "NEBULISATION",
  "ROA072": "OCULAR",
  "ROA074": "ORAL",
  "ROA075": "ORAL INHALATION",
  "ROA083": "ORALLY TUBE FEEDING",
  "ROA084": "OTIC",
  "ROA085": "PARENTERAL",
  "ROA087": "PERITONEAL DIALYSIS",
  "ROA088": "RECTAL",
  "ROA089": "SC",
  "ROA090": "SPINAL",
  "ROA091": "SUBLINGUAL",
  "ROA092": "TOPICAL",
  "ROA093": "TOPICAL ANAL",
  "ROA094": "TOPICAL- SCALP",
  "ROA095": "TRANSDERMAL",
  "ROA096": "TRANSMUCOSAL",
  "ROA097": "VAGINAL",
  "ROA100": "URETHRAL",
  "ROA101": "MISCELLANEOUS",
  "ROA103": "SUBARACHNOID",
  "ROA107": "INTRATHECAL",
  "ROA108": "INTRAOCULAR",
  "ROA109": "INTRACARDIAC",
  "ROA112": "INTRADERMAL",
  "ROA114": "INTRAVENTRICULAR",
  "ROA115": "SUBMUCOSAL",
  "ROA123": "INTRAPLEURAL",
  "ROA133": "INFILTRATION",
  "ROA141": "IRRIGATION",
  "ROA147": "SUBDERMAL",
  "ROA148": "BUCCAL",
  "ROA159": "ARTERIOVENOUS",
  "ROA160": "CENTRAL NERVE BLOCK",
  "ROA161": "DEEP SC",
  "ROA162": "DERMAL",
  "ROA163": "ENDOTRACHEAL",
  "ROA164": "ILIOHYPOGASTRIC (NERVE BLOCK)",
  "ROA165": "ILIONGUINAL",
  "ROA166": "INTRA-ARTERIAL",
  "ROA167": "INTRABURSAL",
  "ROA168": "INTRACAVITARY",
  "ROA169": "INTRALESIONAL",
  "ROA170": "INTRAMEDULLARILY",
  "ROA171": "INTRAOSSEOUS",
  "ROA172": "INTRA-PULMONARY",
  "ROA173": "INTRASPINAL",
  "ROA174": "INTRASUBLESIONAL",
  "ROA175": "INTRASYNOVIAL",
  "ROA176": "IV BOLUS",
  "ROA177": "OROFACIAL",
  "ROA178": "OROPHARYNGEAL",
  "ROA179": "PERIANAL",
  "ROA180": "PERIARTICULAR",
  "ROA181": "PERIBULBAR",
  "ROA182": "PERIPHERAL NERVE BLOCK",
  "ROA183": "SUBCONJUNCTIVAL",
  "ROA184": "SYMPATHETIC NERVE BLOCK",
  "ROA185": "TRANSCERVICAL",
  "ROA187": "SC INFUSION",
  "ROA191": "INTRAVITREAL",
  "ROA192": "CARDIAC PERFUSION",
  "ROA202": "INTRACAMERAL",
  "ROA206": "INTESTINAL INFUSION",
  "ROA207": "EPILESIONAL",
  "ROA209": "INTRAURETERIC",
  "ROA210": "INTRA-LYMPHATIC",
  "ROA222": "PERINEURAL",
};

export const medicineFrequencyArray = {
  1:   "Q.H",
  11:  "Q.D",
  2:   "B.I.D",
  3:   "T.I.D",
  4:   "Q.I.D",
  20:  "Q.S",
  21:  "Q.A.C",
  22:  "Q.O.D",
  23:  "Q.I",
  24:  "Q.H.S",
  0:   "PRN",
  25:  "Other",
}

export const statusArray = [
  {
    key: 'Pending',
    value: 'pending',
  },
  {
    key: 'Completed',
    value: 'completed',
  },
]

export const tableHeadingForEDDoctorNotes = [
  'Date / Time',
  'Main Diagnosis',
  'Additional Diagnosis/Clinical Conditions',
  'Doctor',
  '-',
]
export const tableDataKeysForEDDoctorNotes = ['assignedTime', 'diagnosisList', 'diagnosisAdditionalList', 'doctor']
export const tableHeadingForNurse = [
  'Date / Time',
  'Request ID',
  'Task Details',
  'Assigned By',
  'Status',
  'Action',
]
export const tableDataKeysForNurse = [
  'requestedAt',
  'requestNo',
  'notes',
  'doctorName',
  'status',
]

export const tableHeadingForConsultationNotes = [
  'Date/Time',
  'Description / Condition',
  'Doctor Ref',
  'Consultant',
  'Status',
  'Action',
]
export const tableDataKeysForConsultationNotes = [
  'noteTime',
  'notes',
  'addedByName',
  'consultantName',
  'status',
]

export const tableForSensei = [
  'Date/Time',
  'Description / Condition',
  'Doctor Ref',
  'Consultant',
  'Consultation Type',
  'Status',
  'Action',
]
export const keyForSensei = [
  'noteTime',
  'notes',
  'addedByName',
  'consultantName',
  'consultationType',
  'status',
]

export const tableHeadingForAnesthesiologist = [
  'Date/Time',
  'Request ID',
  'Description',
  'Anesthesiologist Ref',
  'Status',
  'Action',
]
export const tableDataKeysForAnesthesiologist = [
  'noteTime',
  'anesthesiologistNo',
  'notes',
  'anesthesiologistRef',
  'status',
]

export const tableHeadingForPharma = [
  'Request ID',
  'Date/Time',
  'Requester',
  'Status',
  'Action',
]
export const tableDataKeysForPharma = [
  'pharmacyRequestNo',
  'createdAt',
  'requesterName',
  'status',
]

export const tableHeadingForLab = [
  'Request ID',
  'Request Date',
  'Bundle/Test',
  // 'Requester',
  // 'Priority',
  // 'Status',
  'Action',
]
export const tableDataKeysForLab = [
  'requestId',
  'requestDate',
  'serviceName',
  // 'requester',
  // 'priority',
  // 'status',
]

export const tableHeadingForLabRad = [
  'Request ID',
  'Service Code',
  'Service Name',
  'Requester',
  'Priority',
  'Status',
  'Action',
]
export const tableDataKeysForLabRad = [
  'requestId',
  'serviceCode',
  'name',
  'requester',
  'priority',
  'status',
]
