import React, { useState, useEffect, useReducer } from "react";
import Notification from "../../components/Snackbar/Notification.js";
import Header from "../../components/Header/Header";
import Button from "@material-ui/core/Button";
import "../../assets/jss/material-dashboard-react/components/TextInputStyle.css";
import AssignIcon from "../../assets/img/carestream/CareStream.png";
import ViewCS from "./ViewCS";
import Investigations from "./Steps/Investigations";
import Precautions from "./Steps/Precautions";
import TreatmentAndFluids from "./Steps/TreatmentAndFluids";
import Medication from "./Steps/Medication";
import MDNotifications from "./Steps/MDNotifications";
import Back from "../../assets/img/Back_Arrow.png";

import {
  getAllCareStreams,
  asignCareStreamCall,
} from "./NetworkCallForCareStreamAssign";
import {
  Checkbox,
  TextField,
  MenuItem,
  Typography,
  DialogContent,
  DialogTitle,
  Dialog,
  DialogActions,
  useMediaQuery,
  makeStyles,
  useTheme,
  MobileStepper,
} from "@material-ui/core";
import cookie from "react-cookies";
const useStyles = makeStyles(() => ({
  mobileStepper: {
    backgroundColor: "transparent",
    "& .MuiButtonBase-root": {
      backgroundColor: "#2973CF",
      color: "#FFFFFF",
      padding: 10,
    },
  },
  textFieldPadding: {
    paddingLeft: 5,
    paddingRight: 5,
  },
  save: {
    cursor: "pointer",
    borderRadius: 5,
    backgroundColor: "#2973CF",
    width: "130px",
    height: "45px",
    outline: "none",
    color: "white",
  },
  image: {
    width: 50,
    height: 50,
    marginLeft: 11,
  },
  dropdown: {
    justifyContent: "space-around",
    overflow: "hidden",
    borderRadius: 5,
    backgroundColor: "#FFFFFF",
    "& .MuiSelect-root": {
      backgroundColor: "#FFFFFF",
    },
    "& .MuiInputLabel-filled.MuiInputLabel-shrink": {
      color: "black",
      transform: "translate(22px, 19px) scale(1)",
    },
    "& .MuiPaper-root": {
      top: 20,
      backgroundColor: "black",
    },
  },
  title: {
    color: "#A1A1A1",
    fontSize: 20,
    marginTop: 10,
    paddingTop: 10,
    fontWeight: 400,
    paddingLeft: 10,
  },
  heading: {
    color: "black",
    fontSize: 14,
    fontWeight: 400,
    paddingTop: 21,
    paddingLeft: 10,
  },
  headingIE: {
    color: "black",
    fontSize: 14,
    fontWeight: 400,
    paddingTop: 12,
    paddingLeft: 10,
  },
  box: {
    borderRadius: 5,
  },
  cancel: {
    cursor: "pointer",
    borderRadius: 5,
    width: "130px",
    height: "45px",
    outline: "none",
  },
}));

function CareStreamMain(props) {
  const classes = useStyles();
  const matches = useMediaQuery("(min-width:600px)");
  const [openNotification, setOpenNotification] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);
  const [successMsg, setsuccessMsg] = useState(false);
  const [careStream, setCareStream] = useState([]);
  const [filteredCareStream, setFilteredCareStream] = useState([]);
  const [inclusionCriteriaList, setInclusionCriteriaList] = useState([]);
  const [exclusionCriteriaList, setExclusionCriteriaList] = useState([]);
  const [investigationsList, setInvestigationsList] = useState([]);
  const [precautionsList, setPrecautionsList] = useState([]);
  const [treatmentOrdersList, setTreatmentOrdersList] = useState([]);
  const [fluidsIVList, setFluidsIVList] = useState([]);
  const [medicationsList, setMedicationsList] = useState([]);
  const [mdNotificationList, setMdNotificationList] = useState([]);
  const [names, setNames] = useState([]);
  const [idOfCareStream, setIdOfCareStream] = useState("");
  const [nameOfCareStream, setNameOfCareStream] = useState("");
  const [currentUser] = useState(cookie.load("current_user"));
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);

  const [openRequestedQtyDialog, setOpenRequestedQtyDialog] = useState(false);
  const [selectedMedicine, setSelectedMedicine] = useState("");
  const [medicationsListWithObjs, setMedicationsListWithObjs] = useState([]);

  const initialState = {
    itemId: "",
    itemName: "",
    dosage: "",
    frequency: "",
    duration: "",
    requestedQty: "",
  };

  function reducer(state, { field, value }) {
    return {
      ...state,
      [field]: value,
    };
  }

  const [state, dispatch] = useReducer(reducer, initialState);

  const { itemId, itemName, dosage, frequency, duration, requestedQty } = state;

  if (openNotification) {
    setTimeout(() => {
      setOpenNotification(false);
      setErrorMsg("");
      setsuccessMsg("");
    }, 2000);
  }

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  useEffect(() => {
    getAllCareStreams(
      setNames,
      setCareStream,
      setErrorMsg,
      setOpenNotification
    );

    console.log("props", props);
  }, []);

  const pushingToPrint = () => {
    console.log("prop.history", props.history.location.state.selectedItem);
    const data = {
      edrId: props.history.location.state.selectedItem._id,
      name: nameOfCareStream,
      inclusionCriteria: inclusionCriteriaList,
      exclusionCriteria: exclusionCriteriaList,
      investigations: investigationsList,
      precautions: precautionsList,
      treatmentOrders: treatmentOrdersList,
      fluidsIV: fluidsIVList,
      medications: medicationsList,
      medicationListWithObj: medicationsListWithObjs,
      mdNotification: mdNotificationList,
      careStreamId: idOfCareStream,
      staffId: currentUser._id,
      // Add reason to update
      // reason,
    };
    // asignCareStreamCall(data, setErrorMsg, setOpenNotification)
    let path = `assign/printAssignCS`;
    props.history.push({
      pathname: path,
      state: {
        careStream: data,
        patientMRN:
          props.history.location.state.selectedItem.patientId.identifier[0]
            .value,
      },
    });
  };

  const getNameHandler = (e) => {
    console.log("careStream", careStream);
    const filteredCareStream = careStream.filter(
      (careStream) => careStream.name === e.target.value
    );
    setIdOfCareStream(filteredCareStream[0]._id);
    setNameOfCareStream(e.target.value);
    setFilteredCareStream(filteredCareStream);
  };

  const onAddSubType = (e, parent) => {
    console.log(parent, e.target.value);

    if (!checkAddedSubType(e.target.value, parent)) {
      let tre = [...treatmentOrdersList];
      let obj = treatmentOrdersList.map((t, index) => {
        if (t.name && t.name === parent) {
          return { t, index };
        }
      });

      tre[obj[0].index].subType.push(e.target.value);
      setTreatmentOrdersList(tre);
    } else {
      let tre = [...treatmentOrdersList];
      let obj = treatmentOrdersList.map((t, index) => {
        if (t.name && t.name === parent) {
          return { t, index };
        }
      });

      let newSubType = tre[obj[0].index].subType.filter(
        (k) => e.target.value !== k
      );

      tre[obj[0].index].subType = [...newSubType];
      setTreatmentOrdersList(tre);
    }
  };

  const checkAddedSubType = (st, parent) => {
    for (let i = 0; i < treatmentOrdersList.length; i++) {
      if (treatmentOrdersList[i].subType) {
        for (let j = 0; j < treatmentOrdersList[i].subType.length; j++) {
          if (treatmentOrdersList[i].subType[j] === st) {
            return true;
          }
        }
      }
    }
    return false;
  };

  const onAddmdNSubType = (e, parent) => {
    console.log(parent, e.target.value);

    if (!checkAddedMdSubType(e.target.value, parent)) {
      let mdN = [...mdNotificationList];
      let obj = mdNotificationList.map((t, index) => {
        if (t.name && t.name === parent) {
          return { t, index };
        }
      });

      mdN[obj[0].index].subType.push(e.target.value);
      setTreatmentOrdersList(mdN);
    } else {
      let mdN = [...mdNotificationList];
      let obj = mdNotificationList.map((t, index) => {
        if (t.name && t.name === parent) {
          return { t, index };
        }
      });

      let newSubType = mdN[obj[0].index].subType.filter(
        (k) => e.target.value !== k
      );

      mdN[obj[0].index].subType = [...newSubType];
      setTreatmentOrdersList(mdN);
    }
  };

  const checkAddedMdSubType = (st, parent) => {
    for (let i = 0; i < mdNotificationList.length; i++) {
      if (mdNotificationList[i].subType) {
        for (let j = 0; j < mdNotificationList[i].subType.length; j++) {
          if (mdNotificationList[i].subType[j] === st) {
            return true;
          }
        }
      }
    }
    return false;
  };

  const onChangeHandler = (e) => {
    switch (e.target.name) {
      case "inclusionCriteria":
        var inc = [...inclusionCriteriaList];
        if (!inc.includes(e.target.value)) {
          inc.push(e.target.value);
          setInclusionCriteriaList(inc);
        } else {
          let indexOf = inc.indexOf(e.target.value);
          inc.splice(indexOf, 1);
          setInclusionCriteriaList(inc);
        }
        break;
      case "exclusionCriteria":
        var exc = [...exclusionCriteriaList];
        if (!exc.includes(e.target.value)) {
          exc.push(e.target.value);
          setExclusionCriteriaList(exc);
        } else {
          let indexOf = exc.indexOf(e.target.value);
          exc.splice(indexOf, 1);
          setExclusionCriteriaList(exc);
        }
        break;
      case "investigations":
        var inves = [...investigationsList];
        if (!inves.includes(e.target.value)) {
          inves.push(e.target.value);
          setInvestigationsList(inves);
        } else {
          let indexOf = inves.indexOf(e.target.value);
          inves.splice(indexOf, 1);
          setInvestigationsList(inves);
        }
        break;
      case "precautions":
        var pre = [...precautionsList];
        if (!pre.includes(e.target.value)) {
          pre.push(e.target.value);
          setPrecautionsList(pre);
        } else {
          let indexOf = pre.indexOf(e.target.value);
          pre.splice(indexOf, 1);
          setPrecautionsList(pre);
        }
        break;
      case "treatmentOrders":
        var tre = [...treatmentOrdersList];
        if (!tre.find((s) => s.name === e.target.value)) {
          let obj = { name: e.target.value, subType: [] };
          tre.push(obj);
          setTreatmentOrdersList(tre);
        } else {
          let indexOf = tre.map((t, i) => {
            if (t.name === e.target.value) {
              return i;
            }
          });
          tre.splice(indexOf, 1);
          setTreatmentOrdersList(tre);
        }
        break;
      case "fluidsIV":
        var flu = [...fluidsIVList];
        if (!flu.includes(e.target.value)) {
          flu.push(e.target.value);
          setFluidsIVList(flu);
        } else {
          let indexOf = flu.indexOf(e.target.value);
          flu.splice(indexOf, 1);
          setFluidsIVList(flu);
        }
        break;
      case "medications":
        setSelectedMedicine(e.target.value);
        let medi = [...medicationsList];
        let medListWithObjs = [...medicationsListWithObjs];
        if (!medi.includes(e.target.value)) {
          setOpenRequestedQtyDialog(true);
          medi.push(e.target.value);
          setMedicationsList(medi);
        } else {
          let indexOf = medi.indexOf(e.target.value);
          let indexOfObjList = medListWithObjs.indexOf({
            itemName: e.target.value,
          });
          medi.splice(indexOf, 1);
          medListWithObjs.splice(indexOfObjList, 1);
          setMedicationsListWithObjs(medListWithObjs);
          setMedicationsList(medi);
        }
        break;
      case "mdNotification":
        var mdNoti = [...mdNotificationList];
        if (!mdNoti.find((s) => s.name === e.target.value)) {
          let obj = { name: e.target.value, subType: [] };
          mdNoti.push(obj);
          setMdNotificationList(mdNoti);
        } else {
          let indexOf = mdNoti.map((t, i) => {
            if (t.name === e.target.value) {
              return i;
            }
          });
          mdNoti.splice(indexOf, 1);
          setMdNotificationList(mdNoti);
        }
        break;
      default:
        break;
    }
  };

  const onChangeValue = (e) => {
    var pattern = /^[0-9]*$/;
    if (
      e.target.name === "frequency" ||
      e.target.name === "dosage" ||
      e.target.name === "duration"
    ) {
      if (pattern.test(e.target.value) === false) {
        return;
      }
    }
    dispatch({
      field: e.target.name,
      value: e.target.value.replace(/[^\w.\s]/gi, ""),
    });
  };

  const handleCloseDialog = () => {
    var medi = [...medicationsList];
    let indexOf = medi.indexOf(selectedMedicine);
    medi.splice(indexOf, 1);
    setMedicationsList(medi);
    setSelectedMedicine("");
    setOpenRequestedQtyDialog(false);
    dispatch({ field: "requestedQty", value: "" });
    dispatch({ field: "frequency", value: "" });
    dispatch({ field: "dosage", value: "" });
    dispatch({ field: "duration", value: "" });
  };

  const handleAddRequestedQty = () => {
    let obj = {
      itemName: selectedMedicine,
      dosage,
      duration,
      frequency,
      requestedQty: dosage * duration * frequency,
    };

    setMedicationsListWithObjs((prevState) => [...prevState, obj]);
    setSelectedMedicine("");
    setOpenRequestedQtyDialog(false);
    dispatch({ field: "requestedQty", value: "" });
    dispatch({ field: "frequency", value: "" });
    dispatch({ field: "dosage", value: "" });
    dispatch({ field: "duration", value: "" });
  };

  function handleValidateRequestedQtyForm() {
    return frequency !== "" && dosage !== "" && duration !== "";
  }

  if (
    props.history.location.pathname ===
      "/dashboard/home/patientmanagement/careStreamPatients/assign" ||
    props.history.location.pathname ===
      "/dashboard/home/careStreamPatients/assign"
  ) {
    return (
      <div
        style={{
          backgroundColor: "rgb(19 213 159)",
          position: "fixed",
          display: "flex",
          width: "100%",
          height: "100%",
          flexDirection: "column",
          flex: 1,
          overflowY: "scroll",
        }}
      >
        <Header history={props.history} />
        <div className="cPadding">
          {activeStep === 0 ? (
            <div>
              <div style={{ marginLeft: "-10px" }}>
                <div
                  style={{
                    flex: 4,
                    display: "flex",
                    alignItems: "center",
                    marginBottom: 10,
                  }}
                >
                  <img src={AssignIcon} className={classes.image} />
                  <h4
                    style={{
                      color: "white",
                      fontWeight: "700",
                      marginLeft: 10,
                      marginTop: 7,
                    }}
                  >
                    Assign CareStream
                  </h4>
                </div>

                <TextField
                  select
                  fullWidth
                  className={classes.dropdown}
                  onChange={getNameHandler}
                  style={{ marginLeft: 19, width: matches ? "99%" : "93%" }}
                  variant="filled"
                  label="Select CareStream"
                  className="textInputStyle"
                  InputProps={{
                    className: classes.input,
                    classes: { input: classes.input },
                    style: { backgroundColor: "white" },
                  }}
                  InputLabelProps={{
                    style: { color: "#B5A1A1" },
                  }}
                >
                  {names.length > 0 &&
                    names.map((array) => {
                      return (
                        <MenuItem key={array._id} value={array.name}>
                          {array.name}
                        </MenuItem>
                      );
                    })}
                </TextField>

                <div
                  style={{
                    backgroundColor: "white",
                    marginTop: 20,
                    borderRadius: 5,
                    marginLeft: 19,
                    width: matches ? "99%" : "93%",
                    minHeight: 200,
                  }}
                >
                  <Typography color={"textSecondary"} className={classes.title}>
                    Inclusion Criteria
                  </Typography>

                  {filteredCareStream.length > 0 &&
                    filteredCareStream.map((filtered) => {
                      return filtered.inclusionCriteria.map(
                        (inclusionCriteria, index) => {
                          return (
                            <div style={{ display: "flex" }} key={index}>
                              <Checkbox
                                value={inclusionCriteria}
                                onChange={onChangeHandler}
                                name="inclusionCriteria"
                                checked={
                                  inclusionCriteriaList.includes(
                                    inclusionCriteria
                                  )
                                    ? true
                                    : false
                                }
                              />
                              <Typography
                                color={"textSecondary"}
                                className={classes.headingIE}
                              >
                                {inclusionCriteria}
                              </Typography>
                            </div>
                          );
                        }
                      );
                    })}
                </div>
              </div>

              <div style={{ marginLeft: "-10px" }}>
                <div
                  style={{
                    backgroundColor: "white",
                    marginTop: 20,
                    borderRadius: 5,
                    marginLeft: 19,
                    width: matches ? "99%" : "93%",
                    minHeight: 200,
                  }}
                >
                  <Typography color={"textSecondary"} className={classes.title}>
                    Exclusion Criteria
                  </Typography>
                  {filteredCareStream.length > 0 &&
                    filteredCareStream.map((filtered) => {
                      return filtered.exclusionCriteria.map(
                        (exclusionCriteria, index) => {
                          return (
                            <div style={{ display: "flex" }} key={index}>
                              <Checkbox
                                value={exclusionCriteria}
                                onChange={onChangeHandler}
                                name="exclusionCriteria"
                                checked={
                                  exclusionCriteriaList.includes(
                                    exclusionCriteria
                                  )
                                    ? true
                                    : false
                                }
                              />
                              <Typography
                                color={"textSecondary"}
                                className={classes.headingIE}
                              >
                                {exclusionCriteria}
                              </Typography>
                            </div>
                          );
                        }
                      );
                    })}
                </div>
              </div>
            </div>
          ) : activeStep === 1 ? (
            <div style={{ marginLeft: "-10px" }}>
              <div style={{ flex: 4, display: "flex", alignItems: "center" }}>
                <img src={AssignIcon} className={classes.image} />
                <h4
                  style={{
                    color: "white",
                    fontWeight: "700",
                    marginLeft: 10,
                    marginTop: 7,
                  }}
                >
                  Investigations
                </h4>
              </div>
              <div
                style={{
                  backgroundColor: "white",
                  marginTop: 20,
                  borderRadius: 5,
                  marginLeft: 19,
                  width: matches ? "99%" : "93%",
                  minHeight: 200,
                }}
              >
                {filteredCareStream.length > 0 &&
                  filteredCareStream.map((filtered) => {
                    return filtered.investigations.map((investigations) => {
                      return (
                        <div style={{ display: "flex" }}>
                          <Checkbox
                            style={{ marginLeft: 10, marginTop: 10 }}
                            value={investigations}
                            onChange={onChangeHandler}
                            name="investigations"
                            checked={
                              investigationsList.includes(investigations)
                                ? true
                                : false
                            }
                          />
                          <Typography
                            color={"textSecondary"}
                            className={classes.heading}
                          >
                            {investigations}
                          </Typography>
                        </div>
                      );
                    });
                  })}
              </div>
            </div>
          ) : activeStep === 2 ? (
            <div style={{ marginLeft: "-10px" }}>
              <div style={{ flex: 4, display: "flex", alignItems: "center" }}>
                <img src={AssignIcon} className={classes.image} />
                <h4
                  style={{
                    color: "white",
                    fontWeight: "700",
                    marginLeft: 10,
                    marginTop: 7,
                  }}
                >
                  Precautions
                </h4>
              </div>
              <div
                style={{
                  backgroundColor: "white",
                  marginTop: 20,
                  borderRadius: 5,
                  marginLeft: 19,
                  width: matches ? "99%" : "93%",
                  minHeight: 200,
                }}
              >
                {filteredCareStream.length > 0 &&
                  filteredCareStream.map((filtered) => {
                    return filtered.precautions.map((precautions) => {
                      return (
                        <div style={{ display: "flex" }}>
                          <Checkbox
                            style={{ marginLeft: 10, marginTop: 10 }}
                            value={precautions}
                            onChange={onChangeHandler}
                            name="precautions"
                            checked={
                              precautionsList.includes(precautions)
                                ? true
                                : false
                            }
                          />
                          <Typography
                            color={"textSecondary"}
                            className={classes.heading}
                          >
                            {precautions}
                          </Typography>
                        </div>
                      );
                    });
                  })}
              </div>
            </div>
          ) : activeStep === 3 ? (
            <div style={{ marginLeft: "-10px" }}>
              <div style={{ flex: 4, display: "flex", alignItems: "center" }}>
                <img src={AssignIcon} className={classes.image} />
                <h4
                  style={{
                    color: "white",
                    fontWeight: "700",
                    marginLeft: 10,
                    marginTop: 7,
                  }}
                >
                  Treatment Orders
                </h4>
              </div>
              <div
                style={{
                  backgroundColor: "white",
                  marginTop: 20,
                  borderRadius: 5,
                  marginLeft: 19,
                  width: matches ? "99%" : "93%",
                  minHeight: 200,
                }}
              >
                {filteredCareStream.length > 0 &&
                  filteredCareStream.map((filtered) => {
                    return filtered.treatmentOrders.map((treatmentOrders) => {
                      return (
                        <div style={{}}>
                          <div style={{ display: "flex" }}>
                            <Checkbox
                              style={{ marginLeft: 10, marginTop: 10 }}
                              value={treatmentOrders.name}
                              onChange={onChangeHandler}
                              name="treatmentOrders"
                              checked={
                                treatmentOrdersList.find(
                                  (i) => i.name === treatmentOrders.name
                                )
                                  ? true
                                  : false
                              }
                            />
                            <p
                              color={"textSecondary"}
                              className={classes.heading}
                            >
                              {treatmentOrders.name}
                            </p>
                          </div>

                          {treatmentOrdersList.find(
                            (i) => i.name === treatmentOrders.name
                          ) ? (
                            <div style={{ display: "flex", marginLeft: 20 }}>
                              {treatmentOrders.subType.length > 0
                                ? treatmentOrders.subType.map((i) => {
                                    return (
                                      <>
                                        <Checkbox
                                          style={{
                                            marginLeft: 10,
                                            marginTop: 10,
                                          }}
                                          value={i}
                                          onChange={(e) =>
                                            onAddSubType(
                                              e,
                                              treatmentOrders.name
                                            )
                                          }
                                          // name='treatmentOrders'
                                          checked={checkAddedSubType(
                                            i,
                                            treatmentOrders.name
                                          )}
                                        />
                                        <Typography
                                          color={"textSecondary"}
                                          className={classes.heading}
                                        >
                                          {i}
                                        </Typography>
                                      </>
                                    );
                                  })
                                : undefined}
                            </div>
                          ) : (
                            undefined
                          )}
                        </div>
                      );
                    });
                  })}
              </div>

              <div style={{ flex: 4, display: "flex", alignItems: "center" }}>
                <img src={AssignIcon} className={classes.image} />
                <h4
                  style={{
                    color: "white",
                    fontWeight: "700",
                    marginLeft: 10,
                    marginTop: 7,
                  }}
                >
                  Fluids IV
                </h4>
              </div>

              <div
                style={{
                  backgroundColor: "white",
                  marginTop: 20,
                  borderRadius: 5,
                  marginLeft: 19,
                  width: matches ? "99%" : "93%",
                  minHeight: 200,
                }}
              >
                {filteredCareStream.length > 0 &&
                  filteredCareStream.map((filtered) => {
                    return filtered.fluidsIV.map((fluidsIV) => {
                      return (
                        <div style={{ display: "flex" }}>
                          <Checkbox
                            style={{ marginLeft: 10, marginTop: 10 }}
                            value={fluidsIV}
                            onChange={onChangeHandler}
                            name="fluidsIV"
                            checked={
                              fluidsIVList.includes(fluidsIV) ? true : false
                            }
                          />
                          <Typography
                            color={"textSecondary"}
                            className={classes.heading}
                          >
                            {fluidsIV}
                          </Typography>
                        </div>
                      );
                    });
                  })}
              </div>
            </div>
          ) : activeStep === 4 ? (
            <div style={{ marginLeft: "-10px" }}>
              <div style={{ flex: 4, display: "flex", alignItems: "center" }}>
                <img src={AssignIcon} className={classes.image} />
                <h4
                  style={{
                    color: "white",
                    fontWeight: "700",
                    marginLeft: 10,
                    marginTop: 7,
                  }}
                >
                  Medications
                </h4>
              </div>
              <div
                style={{
                  backgroundColor: "white",
                  marginTop: 20,
                  borderRadius: 5,
                  marginLeft: 19,
                  width: matches ? "99%" : "93%",
                  minHeight: 200,
                }}
              >
                {filteredCareStream.length > 0 &&
                  filteredCareStream.map((filtered) => {
                    return filtered.medications.map((medications) => {
                      return (
                        <div style={{ display: "flex" }}>
                          <Checkbox
                            style={{ marginLeft: 10, marginTop: 10 }}
                            value={medications}
                            onChange={onChangeHandler}
                            name="medications"
                            checked={
                              medicationsList.includes(medications)
                                ? true
                                : false
                            }
                          />
                          <Typography
                            color={"textSecondary"}
                            className={classes.heading}
                          >
                            {medications}
                          </Typography>
                        </div>
                      );
                    });
                  })}
              </div>
            </div>
          ) : activeStep === 5 ? (
            <div style={{ marginLeft: "-10px" }}>
              <div style={{ flex: 4, display: "flex", alignItems: "center" }}>
                <img src={AssignIcon} className={classes.image} />
                <h4
                  style={{
                    color: "white",
                    fontWeight: "700",
                    marginLeft: 10,
                    marginTop: 7,
                  }}
                >
                  MD Notification
                </h4>
              </div>
              <div
                style={{
                  backgroundColor: "white",
                  marginTop: 20,
                  borderRadius: 5,
                  marginLeft: 19,
                  width: matches ? "99%" : "93%",
                  minHeight: 200,
                }}
              >
                {filteredCareStream.length > 0 &&
                  filteredCareStream.map((filtered) => {
                    return filtered.mdNotification.map((mdNotification) => {
                      return (
                        <div style={{}}>
                          <div style={{ display: "flex" }}>
                            <Checkbox
                              style={{ marginLeft: 10, marginTop: 10 }}
                              value={mdNotification.name}
                              onChange={onChangeHandler}
                              name="mdNotification"
                              checked={
                                mdNotificationList.find(
                                  (i) => i.name === mdNotification.name
                                )
                                  ? true
                                  : false
                              }
                            />
                            <p
                              color={"textSecondary"}
                              className={classes.heading}
                            >
                              {mdNotification.name}
                            </p>
                          </div>

                          {mdNotificationList.find(
                            (i) => i.name === mdNotification.name
                          ) ? (
                            <div style={{ display: "flex", marginLeft: 20 }}>
                              {mdNotification.subType.length > 0
                                ? mdNotification.subType.map((i) => {
                                    return (
                                      <>
                                        <Checkbox
                                          style={{
                                            marginLeft: 10,
                                            marginTop: 10,
                                          }}
                                          value={i}
                                          onChange={(e) =>
                                            onAddmdNSubType(
                                              e,
                                              mdNotification.name
                                            )
                                          }
                                          // name='mdNotification'
                                          checked={checkAddedMdSubType(
                                            i,
                                            mdNotification.name
                                          )}
                                        />
                                        <Typography
                                          color={"textSecondary"}
                                          className={classes.heading}
                                        >
                                          {i}
                                        </Typography>
                                      </>
                                    );
                                  })
                                : undefined}
                            </div>
                          ) : (
                            undefined
                          )}
                        </div>
                      );
                    });
                  })}
              </div>
            </div>
          ) : (
            undefined
          )}
          <MobileStepper
            className={classes.mobileStepper}
            steps={7}
            position="static"
            variant="dots"
            style={{ marginTop: 10, marginBottom: 70 }}
            activeStep={activeStep}
            nextButton={
              <div>
                {activeStep === 0 ? (
                  <Button
                    size="small"
                    style={{
                      marginRight: matches ? 16 : 6,
                      border: "2px solid",
                      backgroundColor: "transparent",
                      height: 41,
                      marginTop: matches ? "" : -5,
                    }}
                    onClick={handleNext}
                    // disabled={activeStep === 6 - 1}
                  >
                    Skip
                  </Button>
                ) : activeStep === 4 ? (
                  // <Button
                  //   size='small'
                  //   style={{
                  //     color: 'white',
                  //     backgroundColor: '#FF6F91',
                  //     marginRight: matches ? 16 : -5,
                  //     marginTop: matches ? '' : -5,
                  //   }}
                  //   onClick={handleNext}
                  //   // disabled={activeStep === 6 - 1}
                  // >
                  //   Generate Reconcilation Request
                  // </Button>
                  undefined
                ) : (
                  undefined
                )}
                <Button
                  size="small"
                  style={{
                    marginRight: matches ? -17 : -5,
                    marginTop: matches ? "" : -5,
                  }}
                  onClick={activeStep === 5 ? pushingToPrint : handleNext}
                  // disabled={activeStep === 6 - 1}
                >
                  {activeStep === 0
                    ? "Assign"
                    : activeStep === 5
                    ? "Submit"
                    : "Next"}
                </Button>
              </div>
            }
            backButton={
              <div
                className="row"
                style={{
                  marginBottom: 20,
                  marginLeft: 5,
                  marginTop: matches ? "" : 20,
                }}
              >
                {activeStep === 0 ? (
                  <img
                    onClick={() => props.history.goBack()}
                    src={Back}
                    style={{
                      width: 45,
                      height: 35,
                      cursor: "pointer",
                    }}
                  />
                ) : (
                  <img
                    onClick={handleBack}
                    src={Back}
                    style={{
                      width: 45,
                      height: 35,
                      cursor: "pointer",
                    }}
                  />
                )}
              </div>
            }
          />
          <Notification
            msg={errorMsg}
            open={openNotification}
            success={successMsg}
          />
        </div>

        <Dialog open={openRequestedQtyDialog} maxWidth="xl" fullWidth={true}>
          <DialogContent>
            <div>
              <TextField
                required
                label="Dosage"
                name={"dosage"}
                value={dosage}
                onChange={onChangeValue}
                className="textInputStyle"
                variant="filled"
                InputProps={{
                  className: classes.input,
                  classes: { input: classes.input },
                }}
              />
            </div>
            <div style={{ marginTop: 10 }}>
              <TextField
                required
                label="Duration"
                name={"duration"}
                value={duration}
                onChange={onChangeValue}
                className="textInputStyle"
                variant="filled"
                InputProps={{
                  className: classes.input,
                  classes: { input: classes.input },
                }}
              />
            </div>

            <div style={{ marginTop: 10 }}>
              <TextField
                required
                label="Frequency"
                name={"frequency"}
                value={frequency}
                onChange={onChangeValue}
                className="textInputStyle"
                variant="filled"
                InputProps={{
                  className: classes.input,
                  classes: { input: classes.input },
                }}
              />
            </div>
            <div style={{ marginTop: 10 }}>
              <TextField
                required
                disabled
                label="Requested Quantity"
                name={"requestedQty"}
                value={dosage * duration * frequency}
                onChange={onChangeValue}
                className="textInputStyle"
                variant="filled"
                InputProps={{
                  className: classes.input,
                  classes: { input: classes.input },
                }}
              />
            </div>
          </DialogContent>

          <DialogActions>
            <Button
              style={{
                marginRight: 15,
                marginTop: 10,
                border: "2px solid",
                backgroundColor: "transparent",
              }}
              onClick={() => handleCloseDialog()}
            >
              Cancel
            </Button>

            <Button
              style={{
                // marginRight: 15,
                marginTop: 10,
                // border: "2px solid",
                backgroundColor: "#1562e8",
                color: "white",
              }}
              disabled={!handleValidateRequestedQtyForm()}
              onClick={handleAddRequestedQty}
            >
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  } else if (
    props.history.location.pathname ===
      "/dashboard/home/patientmanagement/careStreamPatients/assign/printAssignCS" ||
    props.history.location.pathname ===
      "/dashboard/home/careStreamPatients/assign/printAssignCS"
  ) {
    return <ViewCS propsData={{ ...props }} />;
  }
}
export default CareStreamMain;
