export const tableHeadingForTransfer = [
    "MRN",
    "Patient Name",
    "Production Area",
    "Bed Cell No",
    " "
];
export const tableDataKeysForTransfer = [
    "mrn",
    "patientName",
    "productionArea",
    "bedCell"
];

export const tableHeadingForTimeInterval = [
    "MRN",
    "Patient Name",
    "Admitted Date/Time",
    "Discharged Date/Time",
    "Time Interval",
    " "
];
export const tableDataKeysForTimeInterval = [
    "mrn",
    "patientName",
    "createdTimeStamp",
    "dischargeTimestamp",
    "timeInterval"
];

export const tableHeadingForCareStream = [
    "MRN",
    "Patient Name",
    "Admitted Date/Time",
    "Discharged Date/Time",
    "Time Interval",
    " "
];
export const tableDataKeysForCareStream = [
    "mrn",
    "patientName",
    "createdTimeStamp",
    "dischargeTimestamp",
    "hrs"
];