import axios from "axios";
import {
  getEdrPatients,
  SearchEDRPatients,
  getClearedPatientsURL,
  searchClearedPatientsURL,
} from "../../../../public/endpoins";

export function getClearedPatients(
  setStaffData,
  setGeneralArray,
  setOpenNotification,
  setErrorMsg
) {
  axios
    .get(getClearedPatientsURL)
    .then((res) => {
      if (res.data.success) {
        setGeneralArray(res.data.data);
      } else if (!res.data.success) {
        setErrorMsg(res.data.error);
        setOpenNotification(true);
      }
      return res;
    })
    .catch((e) => {
      console.log("error: ", e);
    });
}

export function getAllPatients(
  setStaffData,
  setGeneralArray,
  setOpenNotification,
  setErrorMsg,
  setisLoading
) {
  axios
    .get(getEdrPatients)
    .then((res) => {
      if (res.data.success) {
        setGeneralArray(res.data.data);
        setisLoading(false);
      } else if (!res.data.success) {
        setErrorMsg(res.data.error);
        setOpenNotification(true);
      }
      return res;
    })
    .catch((e) => {
      console.log("error: ", e);
    });
}

export const searchEdrPatientsForCC = (
  a,
  setGeneralArray,
  setErrorMsg,
  setOpenNotification
) => {
  axios
    .get(SearchEDRPatients + "/" + a)
    .then((res) => {
      if (res.data.success) {
        console.log("response after search", res);
        setGeneralArray(res.data.data);
      } else if (!res.data.success) {
        setErrorMsg(res.data.error);
        setOpenNotification(true);
      }
    })
    .catch((e) => {
      console.log("error after searching patient request", e);
    });
};

export const searchClearedPatients = (
  a,
  setGeneralArray,
  setErrorMsg,
  setOpenNotification
) => {
  axios
    .get(searchClearedPatientsURL + "/" + a)
    .then((res) => {
      if (res.data.success) {
        setGeneralArray(res.data.data);
      } else if (!res.data.success) {
        setErrorMsg(res.data.error);
        setOpenNotification(true);
      }
    })
    .catch((e) => {
      console.log("error after searching patient request", e);
    });
};
