import React, { useEffect, useState } from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Flip from "../../../components/Dial/Flip";
import TwoValue from "../../../components/Dial/TwoValue";
import OneValue from "../../../components/Dial/OneValue";
import Loader from "react-loader-spinner";
import {
  reportsExternalConsultantDBURL,
  reportsInternalConsultantDBURL,
} from "../../../public/endpoins";
import cookie from "react-cookies";

import {
  getDialerGraphCall,
  getValuesCardCalls,
  getResponse,
} from "../ExternalDashboard/NetworkCallForExternal";
import { socketUrl } from "../../../public/endpoins";
import socketIOClient from "socket.io-client";

export default function EDdoctor() {
  const matches = useMediaQuery("(min-width:600px)");
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setsuccessMsg] = useState("");
  const [openNotification, setOpenNotification] = useState(false);
  const [isLoading, setisLoading] = useState(true);
  const [responseData, setResponseData] = useState([]);

  //First Card Hooks
  const [totalPending, setTotalPending] = useState("");
  const [totalPendingColor, setTotalPendingColor] = useState("");
  const [pendingTat, setPendingTat] = useState("");
  const [pendingTATColor, setPendingTATColor] = useState("");
  const [graphPending, setGraphPending] = useState([]);
  //Second Cards Hooks
  const [followUp, setFollowUp] = useState("");
  const [followUpColor, setFollowUpColor] = useState("");
  const [followUpTat, setFollowUpTat] = useState("");
  const [followUpTatColor, setFollowUpTatColor] = useState("");
  const [graphFollowUp, setGraphFollowUp] = useState([]);
  //3rd Card consultedPerHour
  const [consultedPerHour, setConsultedPerHour] = useState("");
  const [consultedPerHourColor, setConsultedPerHourColor] = useState("");

  //cumulativePatientSeen
  const [cumulativePatientSeen, setCumulativePatientSeen] = useState("");
  const [cumulativePatientSeenColor, setCumulativePatientSeenColor] = useState(
    ""
  );

  const [flip, setFlip] = useState(false);
  const [currentUser, setCurrentUser] = useState(cookie.load("current_user"));

  const [flag1Count, setFlag1Count] = useState(0);
  const [flag2Count, setFlag2Count] = useState(0);

  useEffect(() => {
    let apiCall;
    if (currentUser.subType.includes("External")) {
      apiCall = reportsExternalConsultantDBURL;
    } else if (currentUser.subType.includes("Internal")) {
      apiCall = reportsInternalConsultantDBURL;
    }

    getResponse(
      apiCall,
      setResponseData,
      setOpenNotification,
      setErrorMsg,
      setisLoading
    );
    if (currentUser.subType.includes("External")) {
      console.log("*in getResponse External");
    } else if (currentUser.subType.includes("Internal")) {
      console.log("*in getResponse Internal");
    }

    const flagCount = [
      { value: "1st", setFlagCount: (value) => setFlag1Count(value) },
      { value: "2nd", setFlagCount: (value) => setFlag2Count(value) },
    ];

    const socket = socketIOClient(socketUrl);
    socket.emit("internal_flags");
    socket.on("internalPending", (data) => {
      console.log("response coming through socket for Internal Flags", data);
      flagCount.forEach(({ value, setFlagCount }) => {
        const cardCount = data.filter((item) => item.card === value).length;
        cardCount && setFlagCount(cardCount);
      });

      console.log("data", data);
    });
  }, []);

  if (openNotification) {
    setTimeout(() => {
      setOpenNotification(true);
      setErrorMsg(" ");
      setsuccessMsg("");
    }, 2000);
  }

  useEffect(() => {
    let arr = [];

    Object.entries(responseData).map(([key, val]) => {
      if (key !== "success") {
        console.log("key of data", key, "val of data", val);
        arr.push({ [key]: val });
      }
    });

    console.log("arr::", arr);
    if (arr && arr.length > 0) {
      getDialerGraphCall(
        2,
        4,
        45,
        60,
        arr[0].firstCard,
        setTotalPending,
        setTotalPendingColor,
        setPendingTat,
        setPendingTATColor,
        setGraphPending,
        "totalPending"
      );
      getDialerGraphCall(
        2,
        4,
        30,
        45,
        arr[1].secondCard,
        setFollowUp,
        setFollowUpColor,
        setFollowUpTat,
        setFollowUpTatColor,
        setGraphFollowUp,
        "totalFollowUps"
      );

      getValuesCardCalls(
        2,
        4,
        arr[2].consultedPerHour,
        setConsultedPerHour,
        setConsultedPerHourColor
      );
      getValuesCardCalls(
        5,
        10,
        arr[3].cumulativePatientSeen,
        setCumulativePatientSeen,
        setCumulativePatientSeenColor
      );
    }
  }, [responseData]);

  // useEffect(() => {
  //   getReportsExternal(
  //     setTotalPending,
  //     setTotalPendingColor,
  //     setPendingTat,
  //     setPendingTATColor,
  //     setGraphPending,
  //     setFollowUp,
  //     setFollowUpColor,
  //     setFollowUpTat,
  //     setFollowUpTatColor,
  //     setGraphFollowUp,
  //     setConsultedPerHour,
  //     setConsultedPerHourColor,
  //     setCumulativePatientSeen,
  //     setCumulativePatientSeenColor,
  //     currentUser
  //   );
  // }, []);

  const flipIt = () => {
    setFlip(true);
  };
  return (
    <div className="container-fluid" style={{ marginBottom: 10 }}>
      <div className="row">
        <div
          className="col-md-4 col-6"
          style={{ paddingLeft: 4, paddingRight: 4, marginTop: 10 }}
        >
          <div>
            <Flip
              flipped={!flip}
              flipHandler={flipIt}
              mainHeading={"Patient Consultations Pending"}
              value={
                isLoading ? (
                  <Loader
                    type="TailSpin"
                    color="green"
                    height={35}
                    width={35}
                  />
                ) : totalPending === 0 || totalPending === null ? (
                  "0"
                ) : (
                  totalPending
                )
              }
              flagsCount={flag1Count}
              mainHeadingForGraph="Patient Consultations Pending"
              color={totalPendingColor}
              subHeading={"TAT"}
              childHeading={"Request To Completion"}
              time={
                isLoading ? (
                  <Loader
                    type="TailSpin"
                    color="green"
                    height={35}
                    width={35}
                  />
                ) : pendingTat === null || pendingTat === 0 ? (
                  "0"
                ) : (
                  pendingTat
                )
              }
              idForGraph={"container1"}
              data={graphPending}
              timecolor={pendingTATColor}
            />
          </div>
        </div>

        <div
          className="col-md-4 col-6"
          style={{ paddingLeft: 4, paddingRight: 4, marginTop: 10 }}
        >
          <div>
            <Flip
              flipped={!flip}
              flipHandler={flipIt}
              mainHeading={"Patient Follow-Ups Pending"}
              value={
                isLoading ? (
                  <Loader
                    type="TailSpin"
                    color="green"
                    height={35}
                    width={35}
                  />
                ) : followUp === 0 || followUp === null ? (
                  "0"
                ) : (
                  followUp
                )
              }
              flagsCount={flag2Count}
              mainHeadingForGraph="Patient Follow-Ups Pending"
              color={followUpColor}
              subHeading={"TAT"}
              childHeading={"Request To Completion"}
              time={
                isLoading ? (
                  <Loader
                    type="TailSpin"
                    color="green"
                    height={35}
                    width={35}
                  />
                ) : followUpTat === null || followUpTat === 0 ? (
                  "0"
                ) : (
                  followUpTat
                )
              }
              idForGraph={"container2"}
              data={graphFollowUp}
              timecolor={followUpTatColor}
            />
          </div>
        </div>

        <div
          className="col-md-4 col-6"
          style={{ paddingLeft: 4, paddingRight: 4, marginTop: 10 }}
        >
          <div>
            <TwoValue
              heading1={"Compliance/Variance Report"}
              time1={"106"}
              time1Color={"#FFBC28"}
              time2Color={consultedPerHourColor}
              heading2={"Patients Consulted/Hour"}
              time2={
                isLoading ? (
                  <Loader
                    type="TailSpin"
                    color="green"
                    height={35}
                    width={35}
                  />
                ) : consultedPerHour === 0 || consultedPerHour === null ? (
                  "0"
                ) : (
                  consultedPerHour
                )
              }
            />
          </div>
        </div>

        <div
          className="col-md-4 col-6"
          style={{ paddingLeft: 4, paddingRight: 4, marginTop: 10 }}
        >
          <div>
            <OneValue
              time={
                isLoading ? (
                  <Loader
                    type="TailSpin"
                    color="green"
                    height={35}
                    width={35}
                  />
                ) : cumulativePatientSeen === 0 ||
                  cumulativePatientSeen === null ? (
                  "0"
                ) : (
                  cumulativePatientSeen
                )
              }
              colorTime={cumulativePatientSeenColor}
              heading={"Commulative total number of Patients seen"}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
