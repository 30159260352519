import React, { useState, useEffect, useReducer } from "react";
import axios from "axios";
import {
  getRegisteredPharmaReqDetail, qrBuilder,nurseTechnicianQRView, uploadsUrl
} from "../../public/endpoins";
import moment from 'moment';
import { displayInHumanDifference, replacePatientIdIfNull } from "../../utils/commonFunc";
import cookie from "react-cookies";
import _ from "lodash";

function RegisteredPharmaRequestQRAll(props) {



  const capitalizeWord = s => s && s[0].toUpperCase() + s.slice(1)
  const capitalizeWords = (s) => {
    const words = s.split(" ");

    return words.map((word) => { 
        return capitalizeWord(word); 
    }).join(" ");
  }

  const [nameDisplay, setNameDisplay] = useState(true);

  const [currentUser] = useState(cookie.load("current_user"));
  const [patientDetails, setPatientDetails] = useState("");
  const [selectedRecord, setSelectedRecord] = useState({});

  const [ episodeId, setEpisodeId ] = useState(props.match.params.edrId);
  const [ pharmaServiceId, setPharmaServiceId ] = useState(props.match.params.serviceId);
  const [ pharmaSubServiceId, setPharmaSubServiceId ] = useState(props.match.params.subServiceId);

  const [ pharmaSingular, setPharmaSingular ] = useState('no');

  const [ pharmaRequestDisplayId, setPharmaRequestDisplayId ] = useState("");
  const [ pharmaRequest, setPharmaRequest ] = useState({});
  const [ pharmaServices, setPharmaServices ] = useState([]);

  const initialState = {
    qrData: {},
  };
  function reducer(state, { field, value }) {
    return {
      ...state,
      [field]: value,
    };
  }
  const [state, dispatch] = useReducer(reducer, initialState);
  const {
    qrData,
  } = state;

  

  useEffect(() => {
    console.log("Props passed ", props.history.location);
    let edrId = "";

    let searchURL = `${getRegisteredPharmaReqDetail}/${episodeId}/${pharmaServiceId}`; // + '/'+ currentUser._id;

    axios
      .get(searchURL)
      .then((res) => {
        console.log('handleView::', res.data.data);
        if (res.data.success) {

          let remoteRecord = res.data.data[0];
          if (remoteRecord){
            remoteRecord = replacePatientIdIfNull(remoteRecord);

            setSelectedRecord(remoteRecord);

            const object = {
              '_id': remoteRecord._id,
              patientName: remoteRecord.patientId.name[0].given[0] + " " + remoteRecord.patientId.name[0].family,
              episodeNumber: remoteRecord.requestNo,//remoteRecord.patientId.identifier[0].value,
              mrn: remoteRecord.patientId.identifier[0].value,
              birthDate: remoteRecord.patientId.birthDate,
              age: remoteRecord.patientId.age,
              gender: remoteRecord.patientId.gender,
              weight: remoteRecord.patientId.weight,
              department: remoteRecord.currentLocation,
              chiefComplaint: remoteRecord.chiefComplaint.length > 0 ? remoteRecord.chiefComplaint.pop().chiefComplaintId.short_desc : "N/A",
              chiefComplaintOthers: remoteRecord.chiefComplaintOthers,
            };
            setPatientDetails(object);

            console.log('RegisteredPharmaRequestDetail::pharmacyRequest', remoteRecord.pharmacyRequest)
            setPharmaRequestDisplayId(remoteRecord.pharmacyRequest.pharmacyRequestNo);
            setPharmaRequest(remoteRecord.pharmacyRequest)

            console.log('nItem::nItem', pharmaSubServiceId);
            if ( pharmaSubServiceId=="" || pharmaSubServiceId !== undefined ){
              let nxItem = remoteRecord.pharmacyRequest.item.filter((obj) => {
                // console.log('nItem::nItem', obj, `${pharmaSubServiceId}`)
                return obj._id === pharmaSubServiceId
              })

              setPharmaServices(nxItem)
              // setPharmaServices(remoteRecord.pharmacyRequest.item)
            }else{
              setPharmaServices(remoteRecord.pharmacyRequest.item)
            }

            /*
            Object.entries(remoteRecord.pharmacyRequest.item).forEach(([ix, iv]) => {
              //
              console.log('QRP Bothiya::QR', capitalizeWords(iv.itemName.toLowerCase()));
              axios
                .get(`${qrBuilder}/?requestId=${iv._id}&text=${capitalizeWords(iv.itemName.toLowerCase())}`, {
                  edrId: edrId
                })
                .then((res) => {
                  let qrCodes = qrData;
                  qrCodes[res.data.labRequestId.replace('-','')] = res.data.qr
                  dispatch({ field: 'qrData', value: qrCodes });
                })
                .catch((e) => {
                  console.log("QRP error: ", e);
                });
              return false;
            });
            */

            /*
            let labServicesX = remoteRecord.labRequest.filter(obj => obj.requestId === labRequestId);
            console.log('labServicesX', labServicesX, labServicesX.length);
            if (labServicesX.length > 0){
              let labXN = labServicesX[0];
              labXN.requestedName = labXN.requestedBy.name[0].given[0] + " " + labXN.requestedBy.name[0].family;
              console.log('labServicesX', labXN.requestedBy.name);
              console.log('labServicesX', labXN);
              setLabRequest(labXN)
              // setLabRequest(labServicesX[0])
              setLabServices(labServicesX[0].services);


              // 
              Object.entries(remoteRecord.pharmacyRequest.item).map(([ix, iv]) => {
                //
                console.log('Bothiya::QR', iv);
                
                axios
                  .get(`${nurseTechnicianQRView}/?labRequest=${iv.requestId}`, {
                    edrId: edrId
                  })
                  .then((res) => {
                    let qrCodes = qrData;
                    qrCodes[res.data.labRequestId.replace('-','')] = res.data.qr
                    dispatch({ field: 'qrData', value: qrCodes });
                  })
                  .catch((e) => {
                    console.log("QRP error: ", e);
                  });
              });

              console.log('Bothiya::QR', qrData);
            }
            */
          }

        }
      })
      .catch((e) => {
        console.log("error after searching patient request", e);
      });

    setPharmaSingular( (new URLSearchParams(props.location.search).get("onelabel")==="yes"?"yes":"no") )

    // const { selectedItem } = props.history.location.state;
    
  }, []);

  return (
    <div
      style={{
        position: "fixed",
        display: "flex",
        width: "100%",
        height: "100%",
        flexDirection: "column",
        flex: 1,
        overflowY: "scroll",
        paddingBottom: 50,
      }}
    >

      {
        patientDetails ? (
          Object.entries(pharmaServices).map(([ix, iv]) => {
            return _.times((pharmaSingular==="yes"?1:iv.requestedQty), (i) => (
              <>
                <div className="mb-2">
                  <div className="d-flex align-items-center">
                    <img src={`${qrBuilder}/?text=${capitalizeWords(iv.itemName.toLowerCase())} _-_N_L_-_Dosage: ${iv.dosage} _-_N_L_-_Duration: ${iv.duration} _-_N_L_-_Frequency: ${iv.frequency} _-_N_L_-_Route: ${iv.route} _-_N_L_-_Requested Qty: ${iv.requestedQty}`} width="160" />
                    <div>
                      {
                        nameDisplay?
                        <>
                          <strong>Name:</strong> { patientDetails.patientName }<br />
                          <strong>Date of Birth:</strong> { moment(patientDetails.birthDate).format('MMM DD, YYYY') }<br />
                          <strong>MRN:</strong> { patientDetails.mrn }<br />
                        </>:undefined
                      }
                      <strong>Episode:</strong> {patientDetails.episodeNumber.toString()}<br />
                      <strong className="text-capitalize">{ iv.itemName.toLowerCase() }</strong>
                    </div>
                  </div>
                  <p className="mt-1 mb-0 small ml-3">Scan the QR Code and get the complete medication administration details</p>
                </div>
                <hr />
              </>
            ))
          })
        ):undefined
      }
    </div>
  );
}
export default RegisteredPharmaRequestQRAll;
