import React, { useEffect, useState, useReducer } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import { FaUpload } from "react-icons/fa";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import DateFnsUtils from "@date-io/date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import Notification from "../../components/Snackbar/Notification.js";
import { MdRemoveCircle } from "react-icons/md";
import axios from "axios";
import Fingerprint from "../../assets/img/fingerprint.png";
import validateEmergencyName from "../../public/inputValidator";
import validateRelation from "../../public/relationValidator";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import BarCode from "../../assets/img/Bar Code.png";
import ErrorMessage from "../../components/ErrorMessage/errorMessage";
import validateEmail from "../../public/emailValidator";
import validateFirstName from "../../public/inputValidator";
import validateLastName from "../../public/inputValidatorLName";
import validateNationalId from "../../public/numbersValidator";
import MuiPhoneNumber from "material-ui-phone-number";
import validateCountryCity from "../../public/countryCityValidator";
import validateGender from "../../public/genderValidator";
import validateAddress from "../../public/addressValidator";
import validateOtherDetails from "../../public/TextFieldValidator";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import useStylesForSearchInput from "../../assets/jss/material-dashboard-react/inputStyle";
import styles from "./styles";
import { uploadsUrl, patientRegistration } from "../../public/endpoins";
// import "../../assets/jss/material-dash  board-react/components/TextInputStyle.css";
import FormData from "form-data";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import AccountCircle from "@material-ui/icons/SearchOutlined";
import InputAdornment from "@material-ui/core/InputAdornment";
import Loader from "react-loader-spinner";
import validatePhone from "../../public/validatePhone";
import Cookies from "react-cookies";
import CloseIcon from "../../assets/img/CloseIconPng.png";

import { generateEDR, SearchPatients } from "../../public/endpoins";

import {
  savePatientData,
  editPatientData,
  patientSearch,
  addSearchedPatient,
  generateEDEpisodeRecord,
} from "./NetworkCallForShort";
import Header from "../../components/Header/Header";
import patientRegister from "../../assets/img/PatientRegistration.png";

import QRCodeScannerComponent from "../../components/QRCodeScanner/QRCodeScanner";
let matches;

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(0),
  },
  input: {
    backgroundColor: "white",
    boxShadow: "none",
    borderRadius: 5,
    "&:after": {
      borderBottomColor: "black",
      boxShadow: "none",
    },
    "&:hover": {
      backgroundColor: "white",
      boxShadow: "none",
    },
    "&:focus": {
      backgroundColor: "white",
      boxShadow: "none",
      borderRadius: 5,
    },
  },
  multilineColor: {
    boxShadow: "none",
    backgroundColor: "white",
    borderRadius: 5,
    "&:hover": {
      backgroundColor: "white",
      boxShadow: "none",
    },
    "&:after": {
      borderBottomColor: "black",
      boxShadow: "none",
    },
    "&:focus": {
      boxShadow: "none",
    },
  },
  root: {
    "& .MuiTextField-root": {
      backgroundColor: "white",
    },
    "& .Mui-focused": {
      backgroundColor: "white",
      color: "black",
      boxShadow: "none",
    },
    "& .Mui-disabled": {
      backgroundColor: "white",
      color: "gray",
    },
    "&:focus": {
      backgroundColor: "white",
      boxShadow: "none",
      display: matches ? " " : "none",
    },
    "& .MuiFormLabel-root": {
      fontSize: "15px",
      paddingRight: "45px",
    },
  },
  label: {
    "&$focusedLabel": {
      color: "red",
      display: "none",
    },
  },

  square: {
    // color: theme.palette.getContrastText(deepOrange[500]),
    // backgroundColor: deepOrange[500],
    width: theme.spacing(35),
    height: theme.spacing(25),
  },
  large: {
    width: theme.spacing(20),
    height: theme.spacing(20),
  },
}));

function EpisodeRegistration(props) {
  const matches = useMediaQuery("(min-width:600px)");
  const classes = useStyles();

  const initialState = {
    edrId: "",
    patientId: "",
  };

  function reducer(state, { field, value }) {
    return {
      ...state,
      [field]: value,
    };
  }

  const [state, dispatch] = useReducer(reducer, initialState);

  const {
    edrId,
    patientId,
  } = state;

  const [currentUser, setCurrentUser] = useState(Cookies.load("current_user"));
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setsuccessMsg] = useState("");
  const [openNotification, setOpenNotification] = useState(false);

  const [time, setTime] = useState([{ processStartTime: new Date() }]);

  // console.log("props::", props);

  useEffect(() => {
    
  }, []);

  // props.SaveData(saveData);

  // console.log("current user", currentUser);
  // console.log("props", props);

  const handleGenerateEDR = () => {
    const params = {
      patientId,
      generatedBy: currentUser._id,
      status: "pending",
      generatedFrom: currentUser.staffType === "Paramedics" ? "Paramedics" : "Sensei",
      patientInHospital: true,
    };
    //currentUser.staffType === "Paramedics" ? false : true,
    console.log("PARAMS ARE : ", params);
    generateEDEpisodeRecord(
      props,
      generateEDR,
      params,
      setsuccessMsg,
      setOpenNotification,
      setErrorMsg
    );
  };

  const onChangeValue = (e) => {
    var heightWeightPattern = /^[0-9. ]*$/;
    if (e.target.name === "height" || e.target.name === "weight") {
      if (heightWeightPattern.test(e.target.value) === false) {
        return;
      }
    }
    if (e.target.name === "birthDate") {
      dispatch({
        field: e.target.name,
        value: e.target.value,
      });
    } else {
      dispatch({
        field: e.target.name,
        value: e.target.value.replace(/[^\w.'-\s]/gi, ""),
      });
    }
  };

  if (openNotification) {
    setTimeout(() => {
      setOpenNotification(false);
      setErrorMsg("");
      setsuccessMsg("");
    }, 2000);
  }

  
  // Episode Registration
  return (
    <div
      style={{
        backgroundColor: "rgb(19 213 159)",
        position: "fixed",
        display: "flex",
        width: "100%",
        height: "100%",
        flexDirection: "column",
        flex: 1,
        overflowY: "scroll",
        paddingBottom: 50,
      }}
    >
      <Header history={props.props.history} />
      <div className="cPadding">
        <div className="subheader" style={{ marginLeft: "-20px" }}>
          <div>
            <img src={patientRegister} />
            <div style={{ flex: 4, display: "flex", alignItems: "center" }}>
              <h4 style={{ color: "white", fontWeight: "700" }}>
                New Episode Registration <small>(Short Form)</small>
              </h4>
            </div>
          </div>
        </div>

        <div className="row mt-4">
          <div className="col-sm-4 offset-md-4">
            <div className="card card-body">
              <p>Press the following button to generate the new episode number.</p>
              <Button
                style={
                  matches
                    ? { ...styles.generate, width: "100%" }
                    : { ...styles.mobileGenerate, width: "100%" }
                }
                onClick={handleGenerateEDR}
                variant="contained"
                color="primary"
              >
                {"Generate Episode Number"}
              </Button>
              <Button
                className="mt-3"
                style={{ ...styles.stylesForButton, width: "100%" }}
                onClick={() => props.props.history.goBack()}
                variant="contained"
                color="default"
              >Cancel</Button>
            </div>
          </div>
        </div>

        <div
          style={{ flex: 4, display: "flex", flexDirection: "column" }}
          className={`${"container-fluid"} ${classes.root}`}
        >

            <>

              <div
                className="row"
                style={{
                  display: "flex",
                  flex: 1,
                  justifyContent: "center",
                  paddingLeft: 6,
                  paddingRight: 6,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    justifyContent: "flex",
                    marginTop: "1%",
                    marginBottom: "1%",
                  }}
                >
                  
                </div>

                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    justifyContent: "flex-end",
                    marginTop: "1%",
                    marginBottom: "1%",
                  }}
                >
                  
                </div>
                
              </div>
            </>
          

          <Notification
            msg={errorMsg}
            open={openNotification}
            success={successMsg}
          />
        </div>
      </div>
    </div>
  );
}
export default EpisodeRegistration;
