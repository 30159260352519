import React, { useState } from "react";
import {
  makeStyles,
  Grid,
  Paper,
  Button,
  Typography,
  TextField,
} from "@material-ui/core";
import CheckBox from "./CheckBox";
import DropDown from "./DropDown";

const useStyles = makeStyles(() => ({
  root: {
    marginTop: 10,
    marginBottom: 10,
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: "#FFFFFF",
    ".MuiMenu-paper": {
      top: 80,
      left: 2,
    },
  },
  scrollBar: {
    maxHeight: "200px",
    overflowX: "hidden",
    overflowY: "scroll",
  },
  title: {
    color: "#A1A1A1",
    fontSize: 20,
    fontWeight: 400,
    paddingLeft: 10,
  },
  dropDown: {
    width: "100%",
    paddingRight: 10,
  },
}));

export default function CareSteam(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={6} md={9}>
          <Typography color={"textSecondary"} className={classes.title}>
            {props.title}
          </Typography>
        </Grid>
      </Grid>
      <div className={classes.scrollBar}>
        {props.checkBoxes !== undefined &&
          props.checkBoxes.map((object) => {
            return <CheckBox checkBoxValue={object} />;
          })}
      </div>
    </div>
  );
}
