import axios from "axios";
import {
  getLabServices,
  getAllRadServices,
  searchAllLabs,
  searchRadService,
  addLabService,
  addRadService,
  updateLabService,
  updateRadService,
} from "../../public/endpoins";
import _ from "lodash";

export function getAllLabTest(setLabTests, setOpenNotification, setErrorMsg) {
  axios
    .get(getLabServices)
    .then((res) => {
      if (res.data.success) {
        res.data.data.map((val) => (val.ID = val.identifier[0].value));
        var allLabSorted = _.sortBy(res.data.data, "createdAt").reverse();
        setLabTests(allLabSorted);
      } else if (!res.data.success) {
        setErrorMsg(res.data.error);
        setOpenNotification(true);
      }
    })
    .catch((e) => {
      console.log("error: ", e);
    });
}

export function getAllRadTest(setRadTests, setOpenNotification, setErrorMsg) {
  axios
    .get(getAllRadServices)
    .then((res) => {
      if (res.data.success) {
        res.data.data.docs.map((val) => (val.ID = val.identifier[0].value));
        var allRadSorted = _.sortBy(res.data.data.docs, "createdAt").reverse();
        setRadTests(allRadSorted);
      } else if (!res.data.success) {
        setErrorMsg(res.data.error);
        setOpenNotification(true);
      }
    })
    .catch((e) => {
      console.log("error: ", e);
    });
}

// export function LabTestsSearch(
//   e,
//   SetSearchLabTests,
//   setLabTests,
//   getAllLabData
// ) {
//   const a = e.target.value.replace(/[^\w\s]/gi, '')
//   SetSearchLabTests(a)
//   if (a.length >= 3) {
//     axios
//       .get(searchAllLabs + '/' + a)
//       .then((res) => {
//         console.log('response', res)
//         if (res.data.success) {
//           if (res.data.data) {
//             console.log('lab dataaa', res.data.data)
//             res.data.data.map((val) => (val.ID = val.identifier[0].value))
//             const SearchedLabObjs = _.sortBy(
//               res.data.data,
//               'createdAt'
//             ).reverse()
//             setLabTests(SearchedLabObjs)
//           } else {
//             setLabTests(' ')
//           }
//         }
//       })
//       .catch((e) => {
//         console.log('error after searching patient request', e)
//       })
//   } else if (a.length === 0) {
//     getAllLabData()
//   }
// }

export function LabTestsSearch(
  e,
  SetSearchLabTests,
  setLabTests,
  getAllLabData
) {
  const a = e.target.value.replace(/[^\w\s]/gi, "");
  SetSearchLabTests(a);
  if (a.length >= 3) {
    axios
      .get(searchAllLabs + "/" + a)
      .then((res) => {
        if (res.data.success) {
          if (res.data.data) {
            res.data.data.map((val) => (val.ID = val.identifier[0].value));
            const SearchedLabObjs = _.sortBy(
              res.data.data,
              "createdAt"
            ).reverse();
            setLabTests(SearchedLabObjs);
          } else {
            setLabTests(" ");
          }
        }
      })
      .catch((e) => {
        console.log("error after searching patient request", e);
      });
  } else if (a.length === 0) {
    getAllLabData();
  }
}

export function RadTestsSearch(
  e,
  setSearchRadTests,
  setRadTests,
  getAllRadData
) {
  const a = e.target.value.replace(/[^\w\s]/gi, "");
  setSearchRadTests(a);
  if (a.length >= 3) {
    axios
      .get(searchRadService + "/" + a)
      .then((res) => {
        if (res.data.success) {
          if (res.data.data) {
            res.data.data.map((val) => (val.ID = val.identifier[0].value));
            const SearchedRadObjs = _.sortBy(
              res.data.data,
              "createdAt"
            ).reverse();
            setRadTests(SearchedRadObjs);
          } else {
            setRadTests(" ");
          }
        }
      })
      .catch((e) => {
        console.log("error after searching patient request", e);
      });
  } else if (a.length === 0) {
    getAllRadData();
  }
}

export function LabTestsSubmit(
  params,
  setOpen,
  setOpenNotification,
  setErrorMsg
) {
  axios
    .post(addLabService, params)
    .then((res) => {
      if (res.data.success) {
        setOpen(false);
        window.location.reload(false);
      } else if (!res.data.success) {
        setOpenNotification(true);
      }
    })
    .catch((e) => {
      setOpenNotification(true);
      setErrorMsg("Error while adding lab test");
    });
}

export function RadTestsSubmit(
  params,
  setOpen,
  setOpenNotification,
  setErrorMsg
) {
  axios
    .post(addRadService, params)
    .then((res) => {
      if (res.data.success) {
        setOpen(false);
        window.location.reload(false);
      } else if (!res.data.success) {
        setOpenNotification(true);
      }
    })
    .catch((e) => {
      setOpenNotification(true);
      setErrorMsg("Error while adding lab test");
    });
}

export function LabTestEdit(params, setOpen, setOpenNotification, setErrorMsg) {
  axios
    .put(updateLabService, params)
    .then((res) => {
      if (res.data.success) {
        setOpen(false);
        window.location.reload(false);
      } else if (!res.data.success) {
        setOpenNotification(true);
      }
    })
    .catch((e) => {
      setOpenNotification(true);
      setErrorMsg("Error while adding staff");
    });
}

export function RadTestEdit(params, setOpen, setOpenNotification, setErrorMsg) {
  axios
    .put(updateRadService, params)
    .then((res) => {
      if (res.data.success) {
        console.log("lab data", res.data);
        setOpen(false);
        window.location.reload(false);
      } else if (!res.data.success) {
        setOpenNotification(true);
      }
    })
    .catch((e) => {
      console.log("Error while adding staff", e);
      setOpenNotification(true);
      setErrorMsg("Error while adding staff");
    });
}

export function ConfirmEnableDisable(
  url,
  obj,
  setOpenEnableDisabledModal,
  selectedRecord,
  setSelectedRecord,
  setErrorMsg,
  setOpenNotification
) {
  axios
    .put(url + "/" + selectedRecord._id, obj)
    .then((res) => {
      if (res.data.success) {
        setOpenEnableDisabledModal(false);
        setSelectedRecord("");
        window.location.reload(false);
      } else if (!res.data.success) {
        setErrorMsg(res.data.error);
        setOpenNotification(true);
      }
      return res;
    })
    .catch((e) => {
      console.log("error while deletion ", e);
    });
}
