import React, { useState, useEffect, useReducer, useCallback } from "react";
import {
  Chip,
  MenuItem,
  makeStyles,
  TextField,
  Input,
  Select,
  InputAdornment,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  useMediaQuery,
} from "@material-ui/core";
import DoneIcon from "@material-ui/icons/Done";
import AddIcon from "@material-ui/icons/Add";
import Back from "../../../../assets/img/Back_Arrow.png";
import Notification from "../../../../components/Snackbar/Notification.js";
import ImageViewer from "react-simple-image-viewer";
import { IoIosCloseCircle } from "react-icons/io";
import {
  uploadsUrl,
  getSearchedPharmaceuticalItemsUrl,
  getAllergiesDropdown,
} from "../../../../public/endpoins";
import "./formRow.css";
import Autocomplete from "@material-ui/lab/Autocomplete";
import axios from "axios";
import MicIcon from "@material-ui/icons/Mic";
import StopIcon from "@material-ui/icons/Stop";
import MicRecorder from "mic-recorder-to-mp3";
import Loader from "react-loader-spinner";

const Mp3Recorder = new MicRecorder({ bitRate: 128 });

const styles = {
  ROSRowPadding: {
    marginLeft: 0,
    marginRight: 0,
    backgroundColor: "white",
    marginTop: 10,
    padding: "10px 0px 10px 0px",
    borderRadius: 5,
  },
  chipAlignment: {
    display: "inline-block",
    padding: "5px",
  },
  save: {
    color: "white",
    cursor: "pointer",
    borderRadius: 5,
    backgroundColor: "#2c6ddd",
    width: "115px",
    height: "40px",
    outline: "none",
  },
};

const useStyles = makeStyles((theme) => ({
  input: {
    backgroundColor: "#F5F5F5",
    boxShadow: "none",
    borderRadius: 5,
    "&:after": {
      borderBottomColor: "black",
      boxShadow: "none",
    },
    "&:hover": {
      backgroundColor: "#F5F5F5",
      boxShadow: "none",
    },
    "&:focus": {
      backgroundColor: "#F5F5F5",
      boxShadow: "none",
      borderRadius: 5,
    },
  },
  root: {
    "& .MuiTextField-root": {
      backgroundColor: "white",
    },
    "& .Mui-focused": {
      backgroundColor: "white",
      color: "black",
      boxShadow: "none",
    },
    "& .Mui-disabled": {
      backgroundColor: "white",
      color: "gray",
    },
    "& .MuiFormLabel-root": {
      fontSize: "12px",
      color: "black",
      paddingRight: "15px",
    },
    "& .MuiInputLabel-filled": {
      color: "gray",
    },
    "& .MuiFilledInput-adornedEnd": {
      backgroundColor: "#F5F5F5",
      paddingRight: "20px",
    },
  },
}));

const useAutocompleteStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(3),
    },
  },
}));

export default function FormRow(props) {
  const matches = useMediaQuery("(min-width: 600)");
  const classes = useStyles();
  const [congestionSelectedItem, setCongestionSelectedItem] = useState({});

  const initialState = {
    investigation: [],
    isBlocked: true,
    isRecording: false,
    blobURL: [],
    recordingMode: ""
  };

  function reducer(state, { field, value }) {
    return {
      ...state,
      [field]: value,
    };
  }

  const [state, dispatch] = useReducer(reducer, initialState);

  const { investigation, isBlocked, isRecording, blobURL, recordingMode } = state;

  const [clickedRow, setClickedRow] = useState([]);
  const [openList, setOpenList] = useState(false);
  const [listItems, setlistItems] = useState([]);
  const [ECGreport, setECGreport] = useState([]);
  const [ECGpreview, setECGpreview] = useState([]);
  const [SkinReportPreview, setSkinReportPreview] = useState([]);
  const [XRAYreport, setXRAYreport] = useState([]);
  const [XRAYpreview, setXRAYpreview] = useState([]);
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [imagesDisplay, setImagesDisplay] = useState([]);

  const [MedicationImages, setMedicationImages] = useState([]);
  const [SkinReport, setSkinReport] = useState([]);


  const autocompleteClasses = useAutocompleteStyles();
  const [searchedKeyword, setSearchedKeyword] = useState("");
  const [medicines, setMedicines] = useState([]);
  const [selectedMedicines, setselectedMedicines] = useState([]);

  const [personName, setPersonName] = useState([]);

  const [defaultSelectedAlergies, setDefaultSelectedAlergies] = useState([]);
  const [allergiesList, setAllergiesList] = useState([]);
  const [selectedAllergiesList, setSelectedAllergiesList] = useState([]);
  const [otherAlergiesText, setOtherAlergiesText] = useState("");
  const [selectedAlertgiesContainOthers, setSelectedAlertgiesContainOthers] = useState(false);

  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setsuccessMsg] = useState("");
  const [openNotification, setOpenNotification] = useState(false);
  const [skipROSFemale, setSkipROSFemale] = useState(false);


  useEffect(() => {
    let state = props.history.location.state;
    if (props.skipROSFemale!=="undefined"){
      setSkipROSFemale(true);
    }
    const filteredDropdownSelectedValue = props.form
      .map(
        (item) =>
          item.chips &&
          item.chips.find(
            (x) => x.dropdownSelectedValue && x.dropdownSelectedValue
          )
      )
      .filter(Boolean)[0];
    filteredDropdownSelectedValue &&
      filteredDropdownSelectedValue.length &&
      setCongestionSelectedItem(
        filteredDropdownSelectedValue.dropdownSelectedValue
      );
    if (state.comingFrom && state.comingFrom === "edit") {
      if (state.priorECG && state.priorECG.length > 0) {
        let ECG = [];

        state.priorECG.map((item, i) => {
          if (item.includes("\\")) {
            ECG.push(uploadsUrl + item.split("\\")[1]);
          } else if (item.includes("/")) {
            ECG.push(uploadsUrl + item);
          }
        });
        setECGpreview(ECG);
      }
      if (state.priorXray && state.priorXray.length > 0) {
        let Xray = [];

        state.priorXray.map((item, i) => {
          if (item.includes("\\")) {
            Xray.push(uploadsUrl + item.split("\\")[1]);
          } else if (item.includes("/")) {
            Xray.push(uploadsUrl + item);
          }
        });
        setXRAYpreview(Xray);
      }


      if (state.SkinReport && state.SkinReport.length > 0) {
        let tmpSkinReport = [];

        state.SkinReport.map((item, i) => {
          if (item.includes("\\")) {
            tmpSkinReport.push(uploadsUrl + item.split("\\")[1]);
          } else if (item.includes("/")) {
            tmpSkinReport.push(uploadsUrl + item);
          }
        });
        setSkinReportPreview(tmpSkinReport);
      }
    }

    // Get all Allergies List
    axios
      .get(getAllergiesDropdown)
      .then((response) => {
        console.log("allergiesDropdown", response.data.items);
        let allergiesAltered = [];
        response.data.items.map((valx) => {
          allergiesAltered.push({
            id: valx._id,
            name: valx.name,
            code: valx.code,
          });
        });
        setAllergiesList(allergiesAltered);
      })
      .catch((error) => {
        console.log("Allergies Error", error);
      });

    let modePage = 'add';
    // Hello Hello -> Edit Mode
    console.log("FormRow::currentFields::", investigation); 
    if (state.currentFields && state.comingFrom === "edit"){
      modePage = 'edit';
      console.log("FormRow::currentFields::", state.currentFields);

      let nData = [];
      Object.entries(state.currentFields.details).map(([key, val]) => {
        if (val.name === "Allergies"){
          setDefaultSelectedAlergies(val.values);
        }
        nData.push(val.name);
      });
      if (nData.length > 0){
        setClickedRow(nData);
      }

      console.log("FormRow::currentFields::", state.currentFields.details);
      dispatch({
          field: "investigation",
          value: state.currentFields.details
      });


      console.log("FormRow::currentFields::", investigation);
    }
    console.log("FormRow::modePage::", modePage);


    // Set up Default
    let nInvestigations = []
    console.log('Submitting::', modePage, investigation.length, props);
    if (investigation.length <= 0 && modePage === 'add') {
      Object.entries(props.form).map(([key, val]) => {
        let obj = {
          name: val.name,
          sortNumber: val.sortNumber,
          value: '',
          chips: [],
          Texts: [],
          DropDowns: [],
          voiceNotes: {
            audio: '',
            notes: ''
          }
        };
        nInvestigations.push(obj);
        //console.log('FormRow::', obj);
      });
      console.log('FormRow::', nInvestigations);
    }
    if (nInvestigations.length > 0){
      dispatch({
        field: 'investigation', 
        value: nInvestigations
      });
    }



    // Navigator for MP3
    navigator.mediaDevices &&
      navigator
        .mediaDevices.getUserMedia({ audio: true })
        .then( stream => {
          dispatch({ field: "isBlocked", value: false });
        })
        .catch( error => {
          dispatch({ field: "isBlocked", value: true });
        });
  }, []);

  useEffect(() => {
    const getSearchedMedicinesList = () => {
      axios
        .get(getSearchedPharmaceuticalItemsUrl + "/" + searchedKeyword)
        .then((res) => {
          const { data } = res.data;
          const medicinesList = data.items.map((d) => ({ title: d.name }));
          setMedicines(medicinesList);
        })
        .catch((e) => {
          console.log("error: ", e);
        });
    };
    searchedKeyword && getSearchedMedicinesList();
  }, [searchedKeyword]);

  useEffect(() => {
    const handleselectedMedicines = () => {
        const modifiedInvestigation = investigation.map((item) =>
          ( item.chips && item.chips.length && {...item, chips: item.chips.map(c => ( c.autocomplete && {...c, selectedMedicines: selectedMedicines.map(medicineObj => medicineObj.title)}) )})
        );
        console.log('modifiedInvestigation: ', modifiedInvestigation)
        dispatch({
          field: "investigation",
          value: modifiedInvestigation,
        });
    };
    selectedMedicines && selectedMedicines.length && handleselectedMedicines();
  }, [selectedMedicines])



  // Handle Allergies List
  useEffect(() => {
    const handleallergiesList = () => {
        console.log('allergies', selectedAllergiesList);

        let SelectedOther = false;
        setSelectedAlertgiesContainOthers(false);
        selectedAllergiesList.map((val) => {
          if (val.name.toLowerCase().includes('other')){
            setSelectedAlertgiesContainOthers(true)
          }
        });

        if (investigation.length > 0) {
          var rowFound = false;
          for (let i = 0; i < investigation.length; i++) {
            if (!rowFound) {
              if (investigation[i].name === "Allergies") {
                rowFound = true;
                investigation[i].values = selectedAllergiesList;
                dispatch({
                  field: "investigation",
                  value: investigation,
                });
              }
            }
          }
          if (!rowFound) {
            dispatch({
              field: "investigation",
              value: [
                ...investigation,
                {
                  name: "Allergies",
                  values: selectedAllergiesList,
                },
              ],
            });
          }
        } else {
          dispatch({
            field: "investigation",
            value: [
              {
                name: "Allergies",
                values: selectedAllergiesList,
              },
            ],
          });
        }
        console.log("props.form: ", props.form);



        // dispatch({
        //   field: "allergies",
        //   values: selectedAllergiesList,
        // });
    };
    allergiesList && allergiesList.length && handleallergiesList();
  }, [selectedAllergiesList])

  const onRowClick = (row) => {
    if (!clickedRow.includes(row)) {
      setClickedRow([...clickedRow, row]);
    }
    props.rowsSelected(true);
  };

  const onSeeList = (row) => {
    if (row === "") {
      setOpenList(false);
      setlistItems([]);
    } else {
      setOpenList(true);
      setlistItems(row.List);
    }
  };

  const handleRadioClick = (e, row, val) => {
    
    let tempVal = val;

    if (investigation.length > 0) {
      var rowFound = false;
      for (let i = 0; i < investigation.length; i++) {
        if (!rowFound) {
          if (investigation[i].name === row) {
            rowFound = true;
            investigation[i].value = e;
            dispatch({
              field: "investigation",
              value: investigation,
            });
          }
        }
      }
      if (!rowFound) {
        dispatch({
          field: "investigation",
          value: [
            ...investigation,
            {
              name: row,
              value: e,
            },
          ],
        });
      }
    } else {
      dispatch({
        field: "investigation",
        value: [
          {
            name: row,
            value: e,
            chips: [],
            Texts: [],
            voiceNotes: {
              audio: '',
              notes: ''
            }
          },
        ],
      });
    }
  };

  const handleChipClick = (e, row, val) => {
    console.log("HandleChip:: handleChipClick:: e::", e);
    console.log("HandleChip:: handleChipClick:: row::", row);
    console.log("HandleChip:: handleChipClick:: val::", val);
    if (e === "See List") {
      onSeeList(val);
    } else {
      let tempVal = val;

      console.log("HandleChip:: handleChipClick:: investigation::", investigation.length);
      if (investigation.length > 0) {
        var rowFound = false;
        for (let i = 0; i < investigation.length; i++) {
          if (!rowFound) {
            console.log("HandleChip:: handleChipClick:: investigation:: check", investigation[i].name, row, (investigation[i].name === row) );
            if (investigation[i].name === row) {
              rowFound = true;
              let chip = investigation[i].chips;
              var chipFound = false;
              console.log("HandleChip:: handleChipClick:: investigation:: chips", investigation[i].chips );
              for (let j = 0; j < chip.length; j++) {
                if (!chipFound) {
                  console.log("HandleChip:: handleChipClick:: investigation:: chips :: check", chip[j].name, val, val.name, (chip[j].name === val.name) );
                  if (chip[j].name === val.name) {
                    console.log("HandleChip:: handleChipClick:: investigation:: audio", chip[j] );
                    if (chip[j].name.includes("Add")) {
                      if (val.image && val.image.length > 0) {
                        chip.splice(j, 1);
                        chip.push(tempVal);
                        dispatch({
                          field: "investigation",
                          value: investigation,
                        });
                        chipFound = true;
                      } else {
                        chip.splice(j, 1);
                        dispatch({
                          field: "investigation",
                          value: investigation,
                        });
                        chipFound = true;
                      }
                    } else {
                      /*if (chip[j].audio!=""){
                        chip.splice(j, 1);
                        chip.push(tempVal);
                        dispatch({
                          field: "investigation",
                          value: investigation,
                        });
                        chipFound = true;
                      } else {*/
                        chip.splice(j, 1);
                        dispatch({
                          field: "investigation",
                          value: investigation,
                        });
                        chipFound = true;
                      // }
                      j--;
                    }
                  }
                }
              }
              if (!chipFound) {
                chip.push(tempVal);
                dispatch({
                  field: "investigation",
                  value: investigation,
                });
              }
            }
          }
        }
        if (!rowFound) {
          dispatch({
            field: "investigation",
            value: [
              ...investigation,
              {
                name: row,
                chips: [tempVal],
                Texts: [],
                voiceNotes: {
                  audio: '',
                  notes: ''
                }
              },
            ],
          });
        }
      } else {
        let obj = {};
        let chipsArray = [];
        obj.name = row;
        chipsArray.push(tempVal);
        obj.chips = chipsArray;
        obj.Texts = [];
        obj.voiceNotes = {
          audio: '',
          notes: ''
        };

        dispatch({
          field: "investigation",
          value: [obj],
        });
      }
    }
  };

  const setAudioFileToChips = (e, row, val) => {
    let tempVal = val;
    if (investigation.length > 0) {
      var rowFound = false;
      for (let i = 0; i < investigation.length; i++) {
        if (!rowFound) {
          if (investigation[i].name === row) {
            rowFound = true;
            let chip = investigation[i].chips;
            var chipFound = false;
            for (let j = 0; j < chip.length; j++) {
              if (!chipFound) {
                if (chip[j].name === val.name) {
                  if (chip[j].audio!=""){
                    chip.splice(j, 1);
                    chip.push(tempVal);
                    dispatch({
                      field: "investigation",
                      value: investigation,
                    });
                    chipFound = true;
                  }
                }
              }
            }
            if (!chipFound) {
              chip.push(tempVal);
              dispatch({
                field: "investigation",
                value: investigation,
              });
            }
          }
        }
      }
    } else {
      let obj = {};
      let chipsArray = [];
      obj.name = row;
      chipsArray.push(tempVal);
      obj.chips = chipsArray;
      obj.Texts = [];
      obj.voiceNotes = {
        audio: '',
        notes: ''
      };

      dispatch({
        field: "investigation",
        value: [obj],
      });
    }
  };

  const handleSubChipClick = (subChip, row, chipp) => {
    var rowFound = false;
    for (let i = 0; i < investigation.length; i++) {
      if (!rowFound) {
        if (investigation[i].name === row) {
          rowFound = true;
          let chip = investigation[i].chips;

          for (let j = 0; j < chip.length; j++) {
            if (chip[j].name === chipp.name) {
              let subChips = chip[j].subChips;
              var subchipFound = false;

              for (let k = 0; k < subChips.length; k++) {
                if (!subchipFound) {
                  if (subChips[k].name === subChip.name) {
                    if (subChips[k].selected) {
                      subChips[k].selected = false;
                      dispatch({
                        field: "investigation",
                        value: investigation,
                      });
                      subchipFound = true;
                    } else if (!subChips[k].selected) {
                      subChips[k].selected = true;
                      dispatch({
                        field: "investigation",
                        value: investigation,
                      });
                      subchipFound = true;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  };

  const onAlergiesTextChange = (e) => {
    setOtherAlergiesText(e.target.value);
    for (let i = 0; i < investigation.length; i++) {
      if (investigation[i].name === "Allergies") {
        investigation[i].detail = e.target.value;
      }
    }
  };

  const onChipText = (e, row, chipText) => {
    for (let i = 0; i < investigation.length; i++) {
      if (investigation[i].name === row) {
        let chip = investigation[i].chips;
        for (let j = 0; j < chip.length; j++) {
          if (chip[j].name === chipText.name) {
            chip[j].detail = e.target.value;
            dispatch({
              field: "investigation",
              value: investigation,
            });
          }
        }
      }
    }
  };

  const onTextChange = (e, row, text) => {
    text.value = e.target.value;
    if (investigation.length > 0) {
      var rowFound = false;
      for (let i = 0; i < investigation.length; i++) {
        if (!rowFound) {
          if (investigation[i].name === row) {
            rowFound = true;
            let texts = investigation[i].Texts;
            var fieldFound = false;
            console.log('onTextChange', texts);
            for (let j = 0; j < texts.length; j++) {
              if (!fieldFound) {
                if (texts[j].name === text.name) {
                  texts[j].value = e.target.value;

                  dispatch({
                    field: "investigation",
                    value: investigation,
                  });
                  fieldFound = true;
                }
              }
            }
            if (!fieldFound) {
              texts.push(text);
              dispatch({
                field: "investigation",
                value: investigation,
              });
            }
          }
        }
      }
      if (!rowFound) {
        dispatch({
          field: "investigation",
          value: [
            ...investigation,
            {
              name: row,
              Texts: [text],
              chips: [],
              voiceNotes: {
                audio: "",
                notes: ""
              },
            },
          ],
        });
      }
    } else {
      let obj = {};
      let textsArray = [];
      obj.name = row;
      textsArray.push(text);
      obj.Texts = textsArray;
      obj.chips = [];
      obj.voiceNotes = {
        audio: "",
        notes: ""
      };

      dispatch({
        field: "investigation",
        value: [obj],
      });
    }
  };

  function toArray(fileList) {
    return Array.prototype.slice.call(fileList);
  }

  const onRecordAudioFinishGlobal = (file, chipName, rowName, val) => {
    console.log("ST+", file, chipName, rowName, val);
    val.voiceNotes.audio = file;
    console.log("ST+", val);
    setAudioFileToNotes(chipName, rowName, val);
  };

  const onRecordAudioFinish = (file, chipName, rowName, val) => {
    console.log("ST+", file, chipName, rowName, val);
    val.audio = file;
    console.log("ST+", val);
    setAudioFileToChips(chipName, rowName, val);
  };

  const checkTextValue = (codeName) => {
    let codeValue = investigation.filter((row1) => {
      return row1.name == codeName
    });
    let output = "";
    if (codeValue.length > 0){
      if ( codeValue[0].voiceNotes ){
        // console.log("codeValue", codeValue[0].voiceNotes);
        output = codeValue[0].voiceNotes  ? codeValue[0].voiceNotes.notes : ""
      }
    }
    return output;
  }

  const onGlobalChipText = (e, row, chipText) => {
    if (investigation.length > 0) {
      for (let i = 0; i < investigation.length; i++) {
        if (investigation[i].name === row) {
          investigation[i].voiceNotes.notes = e.target.value;
          dispatch({
            field: "investigation",
            value: investigation,
          });
        }
      }
    }else{
      let obj = {
        name: row,
        chips: [],
        Texts: [],
        voiceNotes: {
          audio: "",
          notes: e.target.value
        },
      };
      dispatch({
        field: "investigation",
        value: [obj],
      });
    }
  };

  const setAudioFileToNotes = (e, row, val) => {
    let tempVal = val;
    if (investigation.length > 0) {
      var rowFound = false;
      for (let i = 0; i < investigation.length; i++) {
        if (!rowFound) {
          if (investigation[i].name === row) {
            rowFound = true;
            console.log("INVESTIGATION GLOBAL: ", investigation[i]);
            investigation[i].voiceNotes.audio = val.voiceNotes.audio;
            console.log("INVESTIGATION GLOBAL: FINISH", investigation[i]);
            dispatch({
              field: "investigation",
              value: investigation,
            });
          }
        }
      }
      if (!rowFound) {
        dispatch({
          field: "investigation",
          value: [
            ...investigation,
            {
              name: row,
              chips: [],
              Texts: [],
              voiceNotes: {
                audio: val.voiceNotes.audio,
                notes: ''
              }
            },
          ],
        });
      }
    } else {
      let obj = {};
      let chipsArray = [];
      obj.name = row;
      obj.chips = chipsArray;
      obj.Texts = [];
      obj.voiceNotes = {
        audio: val.voiceNotes.audio,
        notes: ''
      };

      dispatch({
        field: "investigation",
        value: [obj],
      });
    }
  };

  const onDocumentUpload = (event, chipName, rowName, val) => {
    var file = event.target.files;

    if (chipName === "Add ECG Report") {
      var joined = toArray(ECGreport).concat(toArray(file));
      setECGreport(joined);
    }
    if (chipName === "Add CXR Report") {
      var joined = toArray(XRAYreport).concat(toArray(file));
      setXRAYreport(joined);
    }
    if (chipName === "Add Medication Images"){
      var joined = toArray(MedicationImages).concat(toArray(file));
      setMedicationImages(joined);
    }
    if (chipName === "Add Skin Image"){
      var joined = toArray(SkinReport).concat(toArray(file));
      setSkinReport(joined);
    }

    for (let i = 0; i < file.length; i++) {
      let reader = new FileReader();
      reader.readAsDataURL(file[i]);
      reader.onload = function(event) {
        val.image.push(event.target.result);
        handleChipClick(chipName, rowName, val);
      };
    }
  };

  const onImageDelete = (index, chipName, rowName, val) => {
    if (chipName === "Add ECG Report") {
      let temp = ECGreport;
      temp.splice(index, 1);
      setECGreport(temp);
    }
    if (chipName === "Add CXR Report") {
      let temp = XRAYreport;
      temp.splice(index, 1);
      setXRAYreport(temp);
    }
    if (chipName === "Add Medication Images"){
      let temp = MedicationImages;
      temp.splice(index, 1);
      setMedicationImages(temp);
    }
    if (chipName === "Add Skin Image"){
      let temp = SkinReport;
      temp.splice(index, 1);
      setSkinReport(temp);
    }
    val.image.splice(index, 1);
    handleChipClick(chipName, rowName, val);
  };

  const openImageViewer = useCallback((index, val) => {
    setImagesDisplay(val);
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
    setImagesDisplay([]);
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const handleSubItemsChange = (event) => {
    const { value } = event.target;
    console.log(value);
    const valueX = [];
    for (let i = 0, l = value.length; i < l; i += 1) {
      if (value[i]) {
        valueX.push(value[i]);
      }
    }
    setPersonName(valueX);
  };

  const handleDropdownChange = (
    setter,
    value,
    dropdownOptions,
    rowName,
    dropdownName
  ) => {
    setter(value);
    const selectedItem = dropdownOptions.find(
      (option) => option.name === value
    );
    setCongestionSelectedItem(selectedItem);
    const modifiedInvestigation = investigation.map((item) =>
      item.name === rowName
        ? {
            ...item,
            chips: item.chips.map((c) =>
              c.name === dropdownName
                ? { ...c, dropdownSelectedValue: selectedItem }
                : { ...c }
            ),
          }
        : item
    );
    dispatch({
      field: "investigation",
      value: modifiedInvestigation,
    });
  };

  return (
    <>
      {props.form.map((row) => {
        if ("FEMALE GENITAL" === row.name && props.skipROSFemale===true){

        }else{
          return (
            <div
              className="row"
              style={styles.ROSRowPadding}
              onClick={() => onRowClick(row.name)}
            >
              <div className="col-md-12 col-sm-12 col-12">
                {clickedRow.includes(row.name) ? (
                  <>
                    <div
                      style={{
                        fontWeight: "400",
                        color: "#939393",
                      }}
                    >
                      {row.name}
                    </div>

                    {row.name === "Prior ECG" ? (
                      <div
                        className={
                          ECGpreview.length > 0 ? "imageContainer" : "row"
                        }
                      >
                        {ECGpreview && ECGpreview.length > 0 ? (
                          ECGpreview.map((item, index) => {
                            return (
                              <div key={index}>
                                <img
                                  alt=""
                                  key={index}
                                  src={item}
                                  onClick={() =>
                                    openImageViewer(index, ECGpreview)
                                  }
                                  className="reportImage"
                                />
                              </div>
                            );
                          })
                        ) : undefined/*(
                          <h5>No Prior ECGs</h5>
                        )*/}
                      </div>
                    ) : row.name === "Prior Xray" ? (
                      <div
                        className={
                          XRAYpreview.length > 0 ? "imageContainer" : "row"
                        }
                      >
                        {XRAYpreview && XRAYpreview.length > 0 ? (
                          XRAYpreview.map((item, index) => {
                            return (
                              <div key={index}>
                                <img
                                  alt=""
                                  key={index}
                                  src={item}
                                  onClick={() =>
                                    openImageViewer(index, XRAYpreview)
                                  }
                                  className="reportImage"
                                />
                              </div>
                            );
                          })
                        ) : undefined/*(
                          <h5>No Prior X-rays</h5>
                        )*/}
                      </div>
                    ) : row.name === "Allergies" ? (
                      <div className="row">
                        <div className="col-md-12" style={{
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}>
                          <Autocomplete
                            multiple
                              id="allergiesDropdown"
                              options={allergiesList}
                              getOptionLabel={(option) =>
                                option.name || ""
                              }
                              defaultValue={defaultSelectedAlergies}
                              onChange={(e, value) => {
                                console.log('allergies', value)
                                setSelectedAllergiesList(value)
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  className="textInputStyle"
                                  variant="filled"
                                  label="Choose Allergies (If any)"
                                  placeholder="Type for Allergies"
                                />
                              )}
                            />
                            {
                              selectedAlertgiesContainOthers ? (
                                <>
                                  <TextField
                                    type="text"
                                    label="Other Details about Alergies..."
                                    value={otherAlergiesText}
                                    onChange={(e) =>{
                                      onAlergiesTextChange(e)
                                    }}
                                    rows={8}
                                    className="textInputStyle"
                                    variant="filled"
                                    InputProps={{
                                      className: classes.input,
                                      classes: {
                                        input: classes.input,
                                      },
                                      disableUnderline: true,
                                    }}
                                            />
                                </>
                              ): (
                                undefined
                              )
                            }
                        </div>
                      </div>
                    ) : (
                      undefined
                    )}

                    <div
                      className={
                        row.name === "Emergency Department Course" ? "covRow" : ""
                      }
                    >
                      {row.chips && row.chips.length > 0 ? (
                        <div style={{ marginTop: 10 }}>
                          {row.chips.map((val) => {
                            let Icon;
                            let Style = {
                              color: "#070707",
                              backgroundColor: "#F5F5F5",
                            };
                            let chipIncluded = false;

                            if (val.name) {
                              if (val.name.includes("Add")) {
                                Icon = <AddIcon style={{ color: "#FF6F91" }} />;
                                Style = {
                                  color: "#FF6F91",
                                  backgroundColor: "#FFE2E9",
                                  borderRadius: "25px",
                                };
                              }

                              investigation.map((row1) => {
                                if (row1.name === row.name) {
                                  if (row1.chips && row1.chips.length > 0) {
                                    row1.chips.map((chip) => {
                                      if (chip.name === val.name) {
                                        if (val.name.includes("Add")) {
                                          Icon = (
                                            <AddIcon style={{ color: "white" }} />
                                          );
                                          Style = {
                                            color: "white",
                                            backgroundColor: "#FF6F91",
                                            borderRadius: "25px",
                                          };
                                        } else {
                                          Icon = (
                                            <DoneIcon
                                              style={{ color: "white" }}
                                            />
                                          );
                                          Style = {
                                            color: "white",
                                            backgroundColor: "rgb(19 213 159)",
                                          };
                                          chipIncluded = true;
                                          val.detail = chip.detail;
                                        }

                                        // console.log('FormRow::chips::val::', val);
                                        // console.log('FormRow::chips::subChips::', val.subChips);
                                        if (val.subChips && val.subChips.length > 0){
                                          val.subChips.map((sci) => {
                                            if (chip.subChips && chip.subChips.length > 0){
                                              chip.subChips.map((ichip) => {
                                                if (ichip.name === sci.name){
                                                  sci.selected = ichip.selected;
                                                }
                                              });
                                            }
                                          })
                                        }
                                      }
                                    });
                                  }
                                }
                              });
                            }

                            return (
                              <>
                                {chipIncluded ? (
                                  <>
                                    {val.detail === "" ||
                                    (
                                      val.detail && val.detail.length > 0 ||
                                      val.subChipsItems && val.subChipsItems.length > 0
                                    ) ? (
                                      <hr />
                                    ) : (
                                      undefined
                                    )}
                                  </>
                                ) : (
                                  undefined
                                )}

                                <span style={styles.chipAlignment}>
                                  {val.name && val.name.includes("Add") ? (
                                    <Button
                                      variant="contained"
                                      component="label"
                                      style={Style}
                                    >
                                      {Icon}
                                      {val.name}
                                      <input
                                        type="file"
                                        accept=".png,.PNG,.peg,.PEG,.jpeg,.jpg"
                                        multiple
                                        name="document"
                                        onChange={(e) =>
                                          onDocumentUpload(
                                            e,
                                            val.name,
                                            row.name,
                                            val
                                          )
                                        }
                                        onClick={(event) => {
                                          event.target.value = null;
                                        }}
                                        style={{ display: "none" }}
                                      />
                                    </Button>
                                  ) : (
                                    <Chip
                                      label={val.name}
                                      onClick={() =>
                                        handleChipClick(val.name, row.name, val)
                                      }
                                      icon={Icon}
                                      style={Style}
                                    />
                                  )}
                                </span>

                                {chipIncluded ? (
                                  <>
                                    {val.detail === "" ||
                                    (val.detail && val.detail.length > 0) ? (
                                      <>
                                        <div
                                          className={`${"row"} ${classes.root}`}
                                          style={{
                                            marginTop: 15,
                                            marginBottom: 15,
                                          }}
                                        >
                                          <div
                                            className={
                                              val.subChips &&
                                              val.subChips.length > 0
                                                ? "col-md-8 col-sm-8 col-8"
                                                : "col-md-12 col-sm-12 col-12"
                                            }
                                          >


                                          
                                            <div className="row">
                                              <div className={
                                                val.subChips &&
                                                val.subChips.length > 0
                                                  ? "col-md-4"
                                                  : "col-md-3"
                                              }>
                                                <div className="d-flex align-items-center">
                                                  <div
                                                    style={{
                                                      marginRight: matches ? 15 : 7,
                                                      backgroundColor: (isRecording && (recordingMode === (row.name + "_" + val.name))) ? "#DC004E" : "#2C6DDD",
                                                      height: matches ? 55 : 50,
                                                      borderRadius: 30,
                                                      width: matches ? 55 : 50,
                                                      paddingLeft: matches ? 10 : 8,
                                                      cursor: 'pointer'
                                                    }}
                                                  >

                                                    {isRecording && (recordingMode === (row.name + "_" + val.name)) ? (
                                                      <StopIcon
                                                        style={{ marginTop: matches ? 11 : 9, color: "white" }}
                                                        fontSize="large"
                                                        onClick={(name, field) => {
                                                          console.log("Recording: stop", row.name, val.name);
                                                          Mp3Recorder.stop()
                                                            .getMp3()
                                                            .then(([buffer, blob]) => {
                                                              const blobURLFinal = URL.createObjectURL(blob);
                                                              let blobURLx = blobURL;
                                                              blobURLx[(row.name + "_" + val.name)] = blobURLFinal;
                                                              dispatch({ field: "blobURL", value: blobURLx });
                                                              dispatch({ field: "isRecording", value: false });
                                                              dispatch({ field: "recordingMode", value: "" });
                                                              var file = new File([blob], "first", { type: "audio/mp3" });
                                                              // dispatch({ field: "fileAudio", value: file });
                                                              onRecordAudioFinish(file, val.name, row.name, val)
                                                            })
                                                            .catch((e) => console.log(e));
                                                        }}
                                                        disabled={!isRecording}
                                                        color="secondary"
                                                      />
                                                    ) : (
                                                      <MicIcon
                                                        style={{ marginTop: matches ? 12 : 10, color: "white" }}
                                                        fontSize="large"
                                                        onClick={(e) => {
                                                          if (isBlocked) {
                                                            alert("Your browser doesn't have permission to record Audio!");
                                                          } else {
                                                            console.log("Recording: start", row.name, val.name);
                                                            Mp3Recorder.start()
                                                              .then(() => {
                                                                dispatch({ field: "isRecording", value: true });
                                                                dispatch({ field: "recordingMode", value: `${row.name}_${val.name}` });
                                                              })
                                                              .catch((e) => console.error(e));
                                                          }
                                                        }}
                                                        disabled={isRecording && recordingMode === `${row.name}_${val.name}`}
                                                        color="primary"
                                                      />
                                                    )}
                                                  </div>


                                                  {isRecording && (recordingMode === (row.name + "_" + val.name)) ? (
                                                    <div
                                                      style={{
                                                        marginLeft: matches ? 110 : 18,
                                                        marginRight: matches ? 110 : 75,
                                                        width: matches ? 550 : 100,
                                                      }}
                                                    >
                                                      <Loader
                                                        type="ThreeDots"
                                                        color="blue"
                                                        height={40}
                                                        width={40}
                                                      />
                                                    </div>
                                                  ) : (
                                                    <audio
                                                      style={{
                                                        marginRight: 10,
                                                        width: matches ? 450 : 260,
                                                        marginTop: 6,
                                                      }}
                                                      src={blobURL[(row.name + "_" + val.name)]}
                                                      controls="controls"
                                                    />
                                                  )}
                                                </div>
                                              </div>
                                              <div className={
                                                val.subChips &&
                                                val.subChips.length > 0
                                                  ? "col-md-8"
                                                  : "col-md-9"
                                              }>
                                                <TextField
                                                  type="text"
                                                  label="Text/Audio Notes for Chip.."
                                                  value={val.detail}
                                                  onChange={(e) =>
                                                    onChipText(e, row.name, val)
                                                  }
                                                  rows={4}
                                                  className="textInputStyle"
                                                  variant="filled"
                                                  InputProps={{
                                                    endAdornment:
                                                      val.name === "Fever" ? (
                                                        <InputAdornment position="end">
                                                          &deg;C
                                                        </InputAdornment>
                                                      ) : null,
                                                    className: classes.input,
                                                    classes: {
                                                      input: classes.input,
                                                      adornedEnd: classes.root,
                                                    },
                                                    disableUnderline: true,
                                                  }}
                                                />
                                              </div>
                                            </div>


                                          </div>

                                          {val.subChips &&
                                          val.subChips.length > 0 ? (
                                            <div className="col-md-4 col-sm-4 col-4">
                                              {val.subChips.map((subChip) => {
                                                let subchipIcon;
                                                let subchipStyle = {
                                                  color: "#070707",
                                                  backgroundColor: "#F5F5F5",
                                                };

                                                investigation.map((row2) => {
                                                  if (row2.name === row.name) {
                                                    if (
                                                      row2.chips &&
                                                      row2.chips.length > 0
                                                    ) {
                                                      row2.chips.map((chip) => {
                                                        if (
                                                          chip.name === val.name
                                                        ) {
                                                          if (
                                                            chip.subChips &&
                                                            chip.subChips.length >
                                                              0
                                                          ) {
                                                            chip.subChips.map(
                                                              (subChips) => {
                                                                if (
                                                                  subChips.name ===
                                                                    subChip.name &&
                                                                  subChip.selected
                                                                ) {
                                                                  subchipIcon = (
                                                                    <DoneIcon
                                                                      style={{
                                                                        color:
                                                                          "#16D5A0",
                                                                      }}
                                                                    />
                                                                  );
                                                                  subchipStyle = {
                                                                    color:
                                                                      "#16D5A0",
                                                                    backgroundColor:
                                                                      "#D0F7EC",
                                                                  };
                                                                }
                                                              }
                                                            );
                                                          }
                                                        }
                                                      });
                                                    }
                                                  }
                                                });

                                                return (
                                                  <span
                                                    style={{
                                                      ...styles.chipAlignment,
                                                      padding: "10px",
                                                    }}
                                                  >
                                                    <Chip
                                                      label={subChip.name}
                                                      onClick={() =>
                                                        handleSubChipClick(
                                                          subChip,
                                                          row.name,
                                                          val
                                                        )
                                                      }
                                                      icon={subchipIcon}
                                                      style={subchipStyle}
                                                    />
                                                  </span>
                                                );
                                              })}
                                            </div>
                                          ) : (
                                            undefined
                                          )}
                                        </div>
                                      </>
                                    ) : val.dropdownOptions &&
                                      val.dropdownOptions.length > 0 ? (
                                      // Code here
                                      <TextField
                                        select
                                        fullWidth
                                        id={val.name + "Id"}
                                        // name={defaultValue}
                                        value={val.defaultValue}
                                        label={val.label}
                                        variant="filled"
                                        className="dropDownStyle"
                                        {...(val.selectMultiple ? "multiple" : "")}
                                        InputProps={{
                                          className: classes.input,
                                          classes: { input: classes.input },
                                          disableUnderline: true,
                                        }}
                                        onChange={(e) =>
                                          handleDropdownChange(
                                            val.setter,
                                            e.target.value,
                                            val.dropdownOptions,
                                            row.name,
                                            val.name
                                          )
                                        }
                                      >
                                        {val.dropdownOptions.map((item) => (
                                          <MenuItem
                                            key={item.code}
                                            value={item.name}
                                          >
                                            {item.name}
                                          </MenuItem>
                                        ))}
                                      </TextField>
                                    ) : //code for autocomplete
                                    val.autocomplete ? (
                                      <div className={autocompleteClasses.root}>
                                        <Autocomplete
                                          multiple
                                          id="tags-standard"
                                          options={medicines}
                                          getOptionLabel={(option) =>
                                            option.title
                                          }
                                          defaultValue={[]}
                                          onChange={(event, value) => setselectedMedicines(value)}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              variant="standard"
                                              label="Select Medicines"
                                              placeholder="Select Medicines"
                                              onChange={(event) => {
                                                setSearchedKeyword(
                                                  event.target.value
                                                );
                                              }}
                                            />
                                          )}
                                        />
                                      </div>
                                    ) : //code for subchips dropdown
                                    val.subChipsItems ? (
                                      <Select
                                        id={val.name + "Id"}
                                        multiple
                                        fullWidth
                                        value={val.defaultValue}
                                        input={<Input />}
                                        MenuProps={MenuProps}
                                        className="dropDownStyle"
                                        variant="filled"
                                        InputProps={{
                                          className: classes.input,
                                          classes: { input: classes.input },
                                          disableUnderline: true,
                                        }}
                                        onChange={(e) =>
                                          handleDropdownChange(
                                            val.setter,
                                            e.target.value,
                                            val.subChipsItems,
                                            row.name,
                                            val.name
                                          )
                                        }
                                      >
                                        {val.subChipsItems.map((item) => (
                                          <MenuItem
                                            key={item.code}
                                            value={item.name}
                                          >
                                            {item.name}
                                          </MenuItem>
                                        ))}
                                      </Select>
                                    ) : (
                                      undefined
                                    )}
                                  </>
                                ) : (
                                  undefined
                                )}

                                {val.name === "Add Skin Image" && SkinReportPreview && SkinReportPreview.length > 0 ? (
                                  SkinReportPreview.map((item, index) => {
                                    return (
                                      <div key={index}>
                                        <img
                                          alt=""
                                          key={index}
                                          src={item}
                                          onClick={() =>
                                            openImageViewer(index, SkinReportPreview)
                                          }
                                          className="reportImage"
                                        />
                                      </div>
                                    );
                                  })
                                ) : undefined/*(
                                  <h5>No Prior ECGs</h5>
                                )*/}

                                {val.image && val.image.length > 0 ? (
                                  <span className="imageContainer">
                                    {val.image.map((view, index) => {
                                      return (
                                        <div key={index}>
                                          <img
                                            alt=""
                                            key={index}
                                            src={view}
                                            onClick={() =>
                                              openImageViewer(index, val.image)
                                            }
                                            className="reportImage"
                                          />
                                          <IoIosCloseCircle
                                            onClick={() =>
                                              onImageDelete(
                                                index,
                                                val.name,
                                                row.name,
                                                val
                                              )
                                            }
                                            size={30}
                                            className="closeIcon"
                                          />
                                        </div>
                                      );
                                    })}
                                  </span>
                                ) : (
                                  undefined
                                )}

                                {isViewerOpen && (
                                  <ImageViewer
                                    src={imagesDisplay}
                                    currentIndex={currentImage}
                                    onClose={closeImageViewer}
                                    backgroundStyle={{
                                      backgroundColor: "rgba(0,0,0,0.9)",
                                    }}
                                  />
                                )}
                              </>
                            );
                          })}
                        </div>
                      ) : (
                        undefined
                      )}

                      {row.radios && row.radios.length > 0 ? (
                        <div style={{ marginTop: 10 }}>
                          {row.radios.map((val) => {
                            let Icon;
                            let Style = {
                              color: "#070707",
                              backgroundColor: "#F5F5F5",
                            };
                            let chipIncluded = false;

                            if (val.name) {
                              investigation.map((row1) => {
                                if (row1.name === row.name) {
                                  if (row1.value === val.code) {
                                    Icon = (
                                      <DoneIcon
                                        style={{ color: "white" }}
                                      />
                                    );
                                    Style = {
                                      color: "white",
                                      backgroundColor: "rgb(19 213 159)",
                                    };
                                    chipIncluded = true;
                                  }
                                }
                              });
                            }

                            return (
                              <>

                                <span style={styles.chipAlignment}>
                                  <Chip
                                    label={val.name}
                                    onClick={() =>
                                      handleRadioClick(val.code, row.name, val)
                                    }
                                    icon={Icon}
                                    style={Style}
                                  />
                                </span>

                                {isViewerOpen && (
                                  <ImageViewer
                                    src={imagesDisplay}
                                    currentIndex={currentImage}
                                    onClose={closeImageViewer}
                                    backgroundStyle={{
                                      backgroundColor: "rgba(0,0,0,0.9)",
                                    }}
                                  />
                                )}
                              </>
                            );
                          })}
                        </div>
                      ) : (
                        undefined
                      )}

                      {row.Texts && row.Texts.length > 0 ? (
                        <div
                          className={`${"row"} ${classes.root}`}
                          style={{ marginTop: 10 }}
                        >
                          {row.Texts.map((textVal) => {
                            return (
                              <div
                                className={
                                  row.Texts.length > 1
                                    ? "col-md-6 col-sm-6 col-6"
                                    : "col-md-12 col-sm-12 col-12"
                                }
                                style={
                                  row.Texts.length > 1
                                    ? { padding: "5px 0px 5px 15px" }
                                    : { padding: "5px 15px 5px 15px" }
                                }
                              >
                                <TextField
                                  type="text"
                                  label={textVal.name}
                                  value={textVal.value}
                                  onChange={(e) =>
                                    onTextChange(e, row.name, textVal)
                                  }
                                  rows={4}
                                  className="textInputStyle"
                                  variant="filled"
                                  InputProps={{
                                    className: classes.input,
                                    classes: { input: classes.input },
                                    disableUnderline: true,
                                  }}
                                />
                              </div>
                            );
                          })}
                        </div>
                      ) : (
                        undefined
                      )}

                      {
                        row.voiceNotes ? (
                          <div className="row mt-4">
                            <div className="col-md-3">
                              <div className="d-flex align-items-center">
                                <div
                                  className="text-center"
                                  style={{
                                    marginRight: matches ? 15 : 7,
                                    backgroundColor: (isRecording && (recordingMode === (row.name + "_" + "voiceNotes"))) ? "#DC004E" : "#2C6DDD",
                                    height: 50,
                                    borderRadius: 30,
                                    width: 50,
                                    cursor: 'pointer',
                                    flex: '0 0 50px'
                                  }}
                                >

                                  {isRecording && (recordingMode === (row.name + "_" + "voiceNotes")) ? (
                                    <StopIcon
                                      style={{ marginTop: matches ? 11 : 9, color: "white" }}
                                      fontSize="large"
                                      onClick={(name, field) => {
                                        console.log("Recording: stop", row.name, "voiceNotes");
                                        Mp3Recorder.stop()
                                          .getMp3()
                                          .then(([buffer, blob]) => {
                                            const blobURLFinal = URL.createObjectURL(blob);
                                            let blobURLx = blobURL;
                                            blobURLx[(row.name + "_" + "voiceNotes")] = blobURLFinal;
                                            dispatch({ field: "blobURL", value: blobURLx });
                                            dispatch({ field: "isRecording", value: false });
                                            dispatch({ field: "recordingMode", value: "" });
                                            var file = new File([blob], "first", { type: "audio/mp3" });
                                            // dispatch({ field: "fileAudio", value: file });
                                            onRecordAudioFinishGlobal(file, "voiceNotes", row.name, row)
                                          })
                                          .catch((e) => console.log(e));
                                      }}
                                      disabled={!isRecording}
                                      color="secondary"
                                    />
                                  ) : (
                                    <MicIcon
                                      style={{ marginTop: matches ? 12 : 10, color: "white" }}
                                      fontSize="large"
                                      onClick={(e) => {
                                        if (isBlocked) {
                                          alert("Your browser doesn't have permission to record Audio!");
                                        } else {
                                          console.log("Recording: start", row.name, "voiceNotes");
                                          Mp3Recorder.start()
                                            .then(() => {
                                              dispatch({ field: "isRecording", value: true });
                                              dispatch({ field: "recordingMode", value: `${row.name}_voiceNotes` });
                                            })
                                            .catch((e) => console.error(e));
                                        }
                                      }}
                                      disabled={isRecording && recordingMode === `${row.name}_voiceNotes`}
                                      color="primary"
                                    />
                                  )}
                                </div>


                                {isRecording && (recordingMode === (row.name + "_" + "voiceNotes")) ? (
                                  <div
                                    style={{
                                      marginLeft: matches ? 110 : 18,
                                      marginRight: matches ? 110 : 75,
                                      width: matches ? 550 : 100,
                                    }}
                                  >
                                    <Loader
                                      type="ThreeDots"
                                      color="blue"
                                      height={40}
                                      width={40}
                                    />
                                  </div>
                                ) : (
                                  <audio
                                    style={{
                                      marginRight: 10,
                                      width: matches ? 450 : 260,
                                      marginTop: 6,
                                    }}
                                    src={blobURL[(row.name + "_" + "voiceNotes")]}
                                    controls="controls"
                                  />
                                )}
                              </div>
                            </div>
                            <div className="col-md-9">
                              {
                                <TextField
                                  type="text"
                                  label="Notes ..."
                                  value={ checkTextValue(row.name) }
                                  onChange={(e) =>
                                    onGlobalChipText(e, row.name, row.voiceNotes.notes)
                                  }
                                  rows={4}
                                  className="textInputStyle"
                                  variant="filled"
                                  InputProps={{
                                    className: classes.input,
                                    classes: {
                                      input: classes.input,
                                      adornedEnd: classes.root,
                                    },
                                    disableUnderline: true,
                                  }}
                                />
                              }
                            </div>
                          </div>
                        ) : undefined
                      }

                    </div>

                    {
                      row.name === "Medications" ? (
                        <p style={{ marginTop: 20 }} className="text-center"><strong><em>The medications listed here may not be a complete list of the medications that the patient is taking.</em></strong></p>
                      ) : undefined
                    }
                  </>
                ) : (
                  <div
                    style={{
                      paddingTop: 10,
                      paddingBottom: 10,
                      cursor: "pointer",
                    }}
                  >
                    <span style={{ fontWeight: "bold", color: "#939393" }}>
                      {row.name}
                    </span>
                  </div>
                )}
              </div>
            </div>
          );
        }
      })}

      <div
        className="row"
        style={{
          display: "flex",
          justifyContent: "flex-start",
          marginTop: "30px",
          marginBottom: "2%",
        }}
      >
        <div className="col-md-6 col-sm-6 col-6 d-flex justify-content-start">
          <img
            onClick={() => props.history.goBack()}
            src={Back}
            style={{
              width: 45,
              height: 35,
              cursor: "pointer",
            }}
          />
        </div>
        <div className="col-md-6 col-sm-6 col-6 d-flex justify-content-end">
          <Button
            disabled={investigation.length > 0 ? false : true}
            onClick={() =>{
              console.log('Submitting::investigation::', investigation);

              let formNameCode = props.history.location.state.comingFor;

              let countError = 0;

              if (formNameCode === "Past Medical History"){
                Object.entries(investigation).map(([key, val]) => {
                  if (val.name !=undefined ){
                    if (val.name === "Past History" && val.chips.length <= 0){
                      console.log("Submitting", "Past History")
                      countError++;
                    }
                    /*if (val.name === "Medications" && val.chips.length <= 0){
                      console.log("Submitting", "Medications")
                      countError++;
                    }
                    if (val.name === "Allergies" && val.DropDowns.length <= 0){
                      console.log("Submitting", "Allergies")
                      countError++;
                    }
                    if (val.name === "Social HX" && val.value === ""){
                      console.log("Submitting", "Social HX")
                      countError++;
                    }
                    if (val.name === "Family HX" && val.chips.length <= 0){
                      console.log("Submitting", "Family HX")
                      countError++;
                    }*/
                  }
                  //console.log('FormRow::', obj);
                });
              }

              if (formNameCode === "ROS"){
                Object.entries(investigation).map(([key, val]) => {
                  if (val.name !=undefined ){
                    if (val.name === "Constitutional" && val.chips.length <= 0){
                      console.log("Submitting", "Constitutional")
                      countError++;
                    }
                    if (val.name === "Ear, Nose & Throat" && val.chips.length <= 0){
                      console.log("Submitting", "Ear, Nose & Throat")
                      countError++;
                    }
                    if (val.name === "Cardiovascular System" && val.chips.length <= 0){
                      console.log("Submitting", "Cardiovascular System")
                      countError++;
                    }
                    if (val.name === "Gastrointestinal" && val.chips.length <= 0){
                      console.log("Submitting", "Gastrointestinal")
                      countError++;
                    }
                    if (val.name === "URINARY" && val.chips.length <= 0){
                      console.log("Submitting", "URINARY")
                      countError++;
                    }
                    /*if (val.name === "FEMALE GENITAL" && val.chips.length <= 0){
                      console.log("Submitting", "FEMALE GENITAL")
                      countError++;
                    }*/
                    if (val.name === "SKIN / Musculoskeletal" && val.chips.length <= 0){
                      console.log("Submitting", "SKIN / Musculoskeletal")
                      countError++;
                    }
                    if (val.name === "NEURO / EYES" && val.chips.length <= 0){
                      console.log("Submitting", "NEURO / EYES")
                      countError++;
                    }
                  }
                  //console.log('FormRow::', obj);
                });
              }

              if (countError > 0){
                setErrorMsg(`Please fill up the complete form in order to submit. ${countError} fields are to fill.`);
                setOpenNotification(true);
              }else{
                props.handleSave(investigation, ECGreport, XRAYreport, SkinReport, MedicationImages)
              }
            }}
            style={
              clickedRow.length > 0
                ? styles.save
                : { ...styles.save, backgroundColor: "#13D59F" }
            }
            variant="contained"
            color="default"
          >Save</Button>
        </div>
      </div>
      <Dialog
        onClose={() => onSeeList("")}
        fullWidth={true}
        maxWidth={"lg"}
        bodyStyle={{ backgroundColor: "red" }}
        contentStyle={{ backgroundColor: "red" }}
        aria-labelledby="simple-dialog-title"
        open={openList}
      >
        <DialogTitle id="simple-dialog-title">
          <h4>List</h4>
          <hr />
        </DialogTitle>

        <DialogContent>
          <div className="container-fluid">
            <div className="row">
              {listItems.length > 0
                ? listItems.map((list) => {
                    return (
                      <div className="col-md-12 col-sm-12 col-12">
                        {list.name}
                        <hr />
                      </div>
                    );
                  })
                : undefined}
            </div>
          </div>
        </DialogContent>

        <DialogActions style={{ display: "flex", justifyContent: "center" }}>
          <Button
            style={styles.save}
            onClick={() => onSeeList("")}
            variant="contained"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>


      <Notification
        msg={errorMsg}
        success={successMsg}
        open={openNotification}
      />
    </>
  );
}

const top100Films = [
  { title: "The Shawshank Redemption", year: 1994 },
  { title: "The Godfather", year: 1972 },
  { title: "The Godfather: Part II", year: 1974 },
  { title: "The Dark Knight", year: 2008 },
  { title: "12 Angry Men", year: 1957 },
  { title: "Schindler's List", year: 1993 },
  { title: "Pulp Fiction", year: 1994 },
  { title: "The Lord of the Rings: The Return of the King", year: 2003 },
  { title: "The Good, the Bad and the Ugly", year: 1966 },
  { title: "Fight Club", year: 1999 },
  { title: "The Lord of the Rings: The Fellowship of the Ring", year: 2001 },
  { title: "Star Wars: Episode V - The Empire Strikes Back", year: 1980 },
  { title: "Forrest Gump", year: 1994 },
  { title: "Inception", year: 2010 },
  { title: "The Lord of the Rings: The Two Towers", year: 2002 },
  { title: "One Flew Over the Cuckoo's Nest", year: 1975 },
  { title: "Goodfellas", year: 1990 },
  { title: "The Matrix", year: 1999 },
  { title: "Seven Samurai", year: 1954 },
  { title: "Star Wars: Episode IV - A New Hope", year: 1977 },
  { title: "City of God", year: 2002 },
  { title: "Se7en", year: 1995 },
  { title: "The Silence of the Lambs", year: 1991 },
  { title: "It's a Wonderful Life", year: 1946 },
  { title: "Life Is Beautiful", year: 1997 },
  { title: "The Usual Suspects", year: 1995 },
  { title: "Léon: The Professional", year: 1994 },
  { title: "Spirited Away", year: 2001 },
  { title: "Saving Private Ryan", year: 1998 },
  { title: "Once Upon a Time in the West", year: 1968 },
  { title: "American History X", year: 1998 },
  { title: "Interstellar", year: 2014 },
  { title: "Casablanca", year: 1942 },
  { title: "City Lights", year: 1931 },
  { title: "Psycho", year: 1960 },
  { title: "The Green Mile", year: 1999 },
  { title: "The Intouchables", year: 2011 },
  { title: "Modern Times", year: 1936 },
  { title: "Raiders of the Lost Ark", year: 1981 },
  { title: "Rear Window", year: 1954 },
  { title: "The Pianist", year: 2002 },
  { title: "The Departed", year: 2006 },
  { title: "Terminator 2: Judgment Day", year: 1991 },
  { title: "Back to the Future", year: 1985 },
  { title: "Whiplash", year: 2014 },
  { title: "Gladiator", year: 2000 },
  { title: "Memento", year: 2000 },
  { title: "The Prestige", year: 2006 },
  { title: "The Lion King", year: 1994 },
  { title: "Apocalypse Now", year: 1979 },
  { title: "Alien", year: 1979 },
  { title: "Sunset Boulevard", year: 1950 },
  {
    title:
      "Dr. Strangelove or: How I Learned to Stop Worrying and Love the Bomb",
    year: 1964,
  },
  { title: "The Great Dictator", year: 1940 },
  { title: "Cinema Paradiso", year: 1988 },
  { title: "The Lives of Others", year: 2006 },
  { title: "Grave of the Fireflies", year: 1988 },
  { title: "Paths of Glory", year: 1957 },
  { title: "Django Unchained", year: 2012 },
  { title: "The Shining", year: 1980 },
  { title: "WALL·E", year: 2008 },
  { title: "American Beauty", year: 1999 },
  { title: "The Dark Knight Rises", year: 2012 },
  { title: "Princess Mononoke", year: 1997 },
  { title: "Aliens", year: 1986 },
  { title: "Oldboy", year: 2003 },
  { title: "Once Upon a Time in America", year: 1984 },
  { title: "Witness for the Prosecution", year: 1957 },
  { title: "Das Boot", year: 1981 },
  { title: "Citizen Kane", year: 1941 },
  { title: "North by Northwest", year: 1959 },
  { title: "Vertigo", year: 1958 },
  { title: "Star Wars: Episode VI - Return of the Jedi", year: 1983 },
  { title: "Reservoir Dogs", year: 1992 },
  { title: "Braveheart", year: 1995 },
  { title: "M", year: 1931 },
  { title: "Requiem for a Dream", year: 2000 },
  { title: "Amélie", year: 2001 },
  { title: "A Clockwork Orange", year: 1971 },
  { title: "Like Stars on Earth", year: 2007 },
  { title: "Taxi Driver", year: 1976 },
  { title: "Lawrence of Arabia", year: 1962 },
  { title: "Double Indemnity", year: 1944 },
  { title: "Eternal Sunshine of the Spotless Mind", year: 2004 },
  { title: "Amadeus", year: 1984 },
  { title: "To Kill a Mockingbird", year: 1962 },
  { title: "Toy Story 3", year: 2010 },
  { title: "Logan", year: 2017 },
  { title: "Full Metal Jacket", year: 1987 },
  { title: "Dangal", year: 2016 },
  { title: "The Sting", year: 1973 },
  { title: "2001: A Space Odyssey", year: 1968 },
  { title: "Singin' in the Rain", year: 1952 },
  { title: "Toy Story", year: 1995 },
  { title: "Bicycle Thieves", year: 1948 },
  { title: "The Kid", year: 1921 },
  { title: "Inglourious Basterds", year: 2009 },
  { title: "Snatch", year: 2000 },
  { title: "3 Idiots", year: 2009 },
  { title: "Monty Python and the Holy Grail", year: 1975 },
];
