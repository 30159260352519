/*eslint-disable*/
import React, { useState, useEffect, useReducer } from 'react'
import Button from '@material-ui/core/Button'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Notification from '../../components/Snackbar/Notification.js'
import CustomTable from '../../components/Table/Table'
import TextField from '@material-ui/core/TextField'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Loader from 'react-loader-spinner'
import AccountCircle from '@material-ui/icons/SearchOutlined'
import InputAdornment from '@material-ui/core/InputAdornment'
import MenuItem from '@material-ui/core/MenuItem'
import Fingerprint from '../../assets/img/fingerprint.png'
import BarCode from '../../assets/img/Bar Code.png'
import Header from '../../components/Header/Header'
import FlagManagementIcon from '../../assets/img/Housekeeping.png'
import eye_icon from '../../assets/img/Eye.png'
import Back from '../../assets/img/Back_Arrow.png'
import NewPatientIcon from '../../assets/img/New Patient.png'
import '../../assets/jss/material-dashboard-react/components/loaderStyle.css'
import cookie from 'react-cookies'
import { styles, useStyles, useStylesForTabs } from './styles.js'
import classNames from "classnames";
import {
  getPendingPatientTransfers,
  getCompletedPatientTransfers,
  getCompletedImgTechnicianCall,
  getPendingImgTechnicianCall,
  updateStatus,
  updateSenseiStatusCall,
} from './networkCallsForNurseTechnician'

const tableHeading = [
  'MRN No',
  'Patient Name',
  'Room No',
  'Status',
  'Action',
  '',
]
const tableDataKeys = ['mrn', 'patientName', 'roomNo', 'status']

const tableHeadingForImgTechnician = [
  'MRN No',
  'Patient Name',
  'Request ID',
  'Bundle/Test',
  'Requested By',
  'Room No',
  'status',
  'Action',
  '',
]
const tableDataKeysForImgTechnician = ['mrn', 'patientName', 'labRequestId', 'serviceName',  'serviceRequestedBy', 'roomNo', 'status']

const actions = { edit: true }
const actions2 = { assign: true }

export default function FlagMgmt(props) {
  const classesForTabs = useStylesForTabs()
  const classes = useStyles()
  const matches = useMediaQuery('(min-width:600px)')
  const [currentUser, setCurrentUser] = useState(cookie.load('current_user'))
  const [pendingSenseiArray, setPendingSenseiArray] = useState([])
  const [completedSenseiArray, setCompletedSenseiArray] = useState([])
  const [pendingImgTechnicianArray, setPendingImgTechnicianArray] = useState([])
  const [
    completedImgTechnicianArray,
    setCompletedImgTechnicianArray,
  ] = useState([])

  const [loading, setLoading] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')
  const [openNotification, setOpenNotification] = useState(false)
  const [tabName, setTabName] = useState('labSample')
  const [buttonValue, setButtonValue] = useState('pending')
  const [cookies, setCookies] = useState(cookie.load('current_user'))

  if (openNotification) {
    setTimeout(() => {
      setOpenNotification(false)
      setErrorMsg('')
    }, 2000)
  }

  useEffect(() => {
    console.log('props', props)
    // let currentNurseId = currentUser._id;
    console.log('current user', currentUser._id)
    if (
      props.history.location.pathname ===
      '/dashboard/home/nursetechniciancompleted'
    ) {
      setButtonValue('completed')
      getCompletedImgTechnicianCall(
        currentUser._id,
        setCompletedImgTechnicianArray,
        setErrorMsg,
        setOpenNotification
      )
    } else {
      getPendingImgTechnicianCall(
        currentUser._id,
        setPendingImgTechnicianArray,
        setErrorMsg,
        setOpenNotification
      )
    }
  }, [])

  // useEffect(() => {}, [tabName])

  const handleButtonChange = (buttonValue) => {
    console.log('buttonValue', buttonValue)
    console.log('ID', currentUser._id)
    setButtonValue(buttonValue)
    if (tabName === 'transferPatient') {
      if (buttonValue === 'completed') {
        getCompletedPatientTransfers(
          currentUser._id,
          setCompletedSenseiArray,
          setErrorMsg,
          setOpenNotification
        )
      } else if (buttonValue === 'pending') {
        getPendingPatientTransfers(
          currentUser._id,
          setPendingSenseiArray,
          setErrorMsg,
          setOpenNotification
        )
      }
    } else {
      if (buttonValue === 'completed') {
        getCompletedImgTechnicianCall(
          currentUser._id,
          setCompletedImgTechnicianArray,
          setErrorMsg,
          setOpenNotification
        )
      } else if (buttonValue === 'pending') {
        getPendingImgTechnicianCall(
          currentUser._id,
          setPendingImgTechnicianArray,
          setErrorMsg,
          setOpenNotification
        )
      }
    }
  }

  const handleTabChange = (e, tabName) => {
    console.log('tabName', tabName)
    setTabName(tabName)
    // getPendingImgTechnicianCall(
    //   setPendingImgTechnicianArray,
    //   setErrorMsg,
    //   setOpenNotification
    // )
    if (tabName === 'transferPatient') {
      if (buttonValue === 'completed') {
        getCompletedPatientTransfers(
          currentUser._id,
          setCompletedSenseiArray,
          setErrorMsg,
          setOpenNotification
        )
      } else if (buttonValue === 'pending') {
        getPendingPatientTransfers(
          currentUser._id,
          setPendingSenseiArray,
          setErrorMsg,
          setOpenNotification
        )
      }
    } else {
      if (buttonValue === 'completed') {
        getCompletedImgTechnicianCall(
          currentUser._id,
          setCompletedImgTechnicianArray,
          setErrorMsg,
          setOpenNotification
        )
      } else if (buttonValue === 'pending') {
        getPendingImgTechnicianCall(
          currentUser._id,
          setPendingImgTechnicianArray,
          setErrorMsg,
          setOpenNotification
        )
      }
    }
  }

  function handletransferPatient(rec) {
    console.log('rec', rec)
    props.history.push({
      pathname: '/dashboard/home/patienttransfer',
      state: {
        selectedRecord: rec,
        //   selectedItem: props.history.location.state,
      },
    })
    // if (rec.task === "To Be Clean") {
    //   const params = {
    //     requestId: rec._id,
    //     task: rec.task === "To Be Clean" ? "Cleaned" : "",
    //     roomId: rec.roomId._id,
    //   };
    //   updateSenseiStatusCall(props, params, setOpenNotification, setErrorMsg);
    // } else {
    //   setOpenNotification(true);
    //   setErrorMsg("Status is already cleaned");
    // }
  }
  function handleUnderObserved() {
    setOpenNotification(true)
    setErrorMsg('Already Observed patient')
  }

  const handleView = (rec) => {
    let url = '/dashboard/home/nursetechnician/details/'+rec._id+'/'+rec.labRequest.requestId;
    props.history.push({
      pathname: url,
    });
  }

  function handleAssign(rec) {
    console.log('rec', rec)
    console.log('rec', rec.labRequest.nurseTechnicianStatus)

    if (rec.labRequest.nurseTechnicianStatus === 'Not Collected') {
      const params = {
        staffId: currentUser._id,
        edrId: rec._id,
        // task: rec.task === "To Be Clean" ? "Cleaned" : "",
      }
      updateStatus(props, params, setOpenNotification, setErrorMsg)
    } else {
      setOpenNotification(true)
      setErrorMsg('Already Collected Lab Sample....Thanks')
    }
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        position: 'fixed',
        width: '100%',
        height: '100%',
        backgroundColor: '#60d69f',
        overflowY: 'scroll',
      }}
    >
      <Header history={props.history} />

      <div className='cPadding'>
        <div className='subheader'>
          <div>
            <img src={NewPatientIcon} style={{ marginLeft: '0px' }} />
            <h4>Sample Collection Requests</h4>
          </div>

          {buttonValue === 'pending' ? (
            <Button
              onClick={() => handleButtonChange('completed')}
              style={{
                ...styles.stylesForButton,
                fontSize: matches ? ' ' : 10,
              }}
              variant='contained'
              color='primary'
            >
              <img src={eye_icon} className='icon-view' />
              &nbsp;&nbsp;
              <strong>Completed Tasks</strong>
            </Button>
          ) : (
            <Button
              onClick={() => handleButtonChange('pending')}
              style={{
                ...styles.stylesForButton,
                fontSize: matches ? ' ' : 10,
              }}
              variant='contained'
              color='primary'
            >
              <img src={eye_icon} className='icon-view' />
              &nbsp;&nbsp;
              <strong>Pending Tasks</strong>
            </Button>
          )}
        </div>

        <div
          className='container-fluid'
          style={{ paddingLeft: 0, paddingRight: 0 }}
        >
          <div className={classesForTabs.root}>
            <Tabs
              classes={{
                root: classesForTabs.root,
                scroller: classesForTabs.scroller,
              }}
              value={tabName}
              onChange={handleTabChange}
              textColor='primary'
              TabIndicatorProps={{ style: { background: '#12387a' } }}
              centered
            >
              <Tab
                style={{
                  color: 'white',
                  borderRadius: 5,
                  outline: 'none',
                  color: tabName === 'labSample' ? '#12387a' : '#3B988C',
                }}
                label='Lab Sample'
                value='labSample'
              />
              {/*
              <Tab
                style={{
                  color: 'white',
                  borderRadius: 5,
                  outline: 'none',
                  color: tabName === 'transferPatient' ? '#12387a' : '#3B988C',
                }}
                label='Transfer Patient'
                value='transferPatient'
              />
              */}
            </Tabs>
          </div>

          <div className='row'>
            {(pendingSenseiArray || completedSenseiArray) &&
            tabName === 'transferPatient' ? (
              <div className='col-md-12 col-sm-12 col-12'>
                {loading ? (
                  <div style={{ margin: 'auto', width: '0%', padding: 10 }}>
                    <Loader
                      type='TailSpin'
                      color='blue'
                      height={50}
                      width={50}
                    />
                  </div>
                ) : (
                  <CustomTable
                    tableData={
                      buttonValue === 'pending'
                        ? pendingSenseiArray
                        : completedSenseiArray
                    }
                    handleEdit={handletransferPatient}
                    handleAssign={handleUnderObserved}
                    tableDataKeys={tableDataKeys}
                    tableHeading={tableHeading}
                    action={buttonValue === 'pending' ? actions : actions2}
                    borderBottomColor={'#60d69f'}
                    borderBottomWidth={20}
                  />
                )}
              </div>
            ) : (completedImgTechnicianArray || pendingImgTechnicianArray) &&
              tabName === 'labSample' ? (
              <div className='col-md-12 col-sm-12 col-12'>
                {loading ? (
                  <div style={{ margin: 'auto', width: '0%', padding: 10 }}>
                    <Loader
                      type='TailSpin'
                      color='blue'
                      height={50}
                      width={50}
                    />
                  </div>
                ) : (
                  <div className="row row-5 mt-5 mb-5">
                    {
                      (buttonValue === 'pending' ? pendingImgTechnicianArray : completedImgTechnicianArray).length>0?
                      Object.entries((buttonValue === 'pending' ? pendingImgTechnicianArray : completedImgTechnicianArray)).map((obj, entry) => {
                        return (
                          <div className="col-12 col-md-6 col-lg-6 col-xl-6">
                            <div className="card card-detail-info card-detail-small mb-2">
                              <div className="row row-5">
                                <div className="col-sm-12"><strong>Request #:</strong> <span>{obj[1].labRequestId}</span> &nbsp; <strong>PA/Bed#:</strong> <span>{obj[1].roomNo}</span></div>
                                <div className="col-sm-6  mt-3"><strong>Epi.#:</strong> <span>{obj[1].episodeNo}</span></div>
                                <div className="col-sm-6  mt-3"><strong>MRN:</strong> <span>{obj[1].mrn}</span></div>
                                <div className="col-sm-10 mt-3"><strong>Patient:</strong> <span>{obj[1].patientName} / {obj[1].gender==="Female"?"F":"M"} / {obj[1].ageNow}y</span></div>
                                <div className="col-sm-2  mt-3 text-right"><strong>T:</strong> <span>0:00</span></div>
                              </div>
                              <div className="card-status-action">
                                <span className={classNames(`badge`, `status-`+obj[1].statusesCodes.toLowerCase().replace(' ', '-'))}>{obj[1].statusesCodes}</span>
                                <span className={classNames(`badge`, `status-`+obj[1].status)}>{obj[1].status}</span>
                                { 
                                  obj[1].labRequest.urgent==="Yes"?<span className="ml-info" title="Life/Limb Situation">!</span>:undefined
                                }
                                <i className="ml-view ml-10 zmdi zmdi-eye zmdi-hc-2x" onClick={() => {
                                  handleView(obj[1]);
                                }} />
                              </div>
                            </div>
                          </div>
                        )
                      }):
                      <div className="col-sm-12"><div className="alert alert-info">No Tasks available at the moment.</div></div>
                    }
                  </div>
                )}
              </div>
            ) : (
              <div className='LoaderStyle'>
                <Loader type='TailSpin' color='red' height={50} width={50} />
              </div>
            )}
          </div>

          {/* <h4 style={{ textAlign: 'center' }}>
            Module is under development for now
          </h4> */}

          <div className='row' style={{ marginBottom: 20 }}>
            <div className='col-md-12 col-sm-12 col-12'>
              <img
                onClick={() => props.history.goBack()}
                src={Back}
                style={{
                  width: 45,
                  height: 35,
                  cursor: 'pointer',
                }}
              />
            </div>
          </div>
        </div>

        <Notification msg={errorMsg} open={openNotification} />
      </div>
    </div>
  )
}
