import axios from "axios";
import {
  getPendingTasksForEOUurl,
  getCompletedTasksForEOUurl,
  getPendingTasksForEDurl,
  getCompletedTasksForEDurl,
  updateEDNurseTask,
  updateEOUNurseTask,
  getSpecialityNurse,
  getEOUNurse,
} from "../../public/endpoins";

export const getPendingTasksForEOU = (
  id,
  setPendingTasks,
  setOpenNotification,
  setErrorMsg
) => {
  axios
    .get(getPendingTasksForEOUurl + "/" + id)
    .then((res) => {
      if (res.data.success) {
        console.log("Pending Requests , ", res.data.data);
        res.data.data.map(
          (d) => (
            (d.mrn = d.patientId.identifier[0].value),
            (d.patientName =
              d.patientId.name[0].given[0] + " " + d.patientId.name[0].family),
            (d.status = d.eouNurseRequest.status),
            (d.requestedAt = d.eouNurseRequest.requestedAt),
            (d.notes = d.eouNurseRequest.notes),
            (d.voiceNotes = d.eouNurseRequest.voiceNotes),
            (d.completedAt = d.eouNurseRequest.completedAt),
            (d.edNurseId = d.eouNurseRequest.edNurseId),
            (d.requestNo = d.eouNurseRequest.requestNo),
            (d.speciality = d.eouNurseRequest.speciality)
          )
        );
        setPendingTasks(res.data.data.reverse());
      } else if (!res.data.success) {
        setOpenNotification(true);
        setErrorMsg("Cannot get the tasks at the moment");
      }
    })
    .catch((e) => {
      setOpenNotification(true);
      setErrorMsg("Cannot get the tasks at the moment");
    });
};

export const getCompletedTasksForEOU = (
  id,
  setCompletedTasks,
  setOpenNotification,
  setErrorMsg
) => {
  axios
    .get(getCompletedTasksForEOUurl + "/" + id)
    .then((res) => {
      if (res.data.success) {
        console.log("Completed Requests , ", res.data.data);
        res.data.data.map(
          (d) => (
            (d.mrn = d.patientId.identifier[0].value),
            (d.patientName =
              d.patientId.name[0].given[0] + " " + d.patientId.name[0].family),
            (d.status = d.eouNurseRequest.status),
            (d.requestedAt = d.eouNurseRequest.requestedAt),
            (d.notes = d.eouNurseRequest.notes),
            (d.voiceNotes = d.eouNurseRequest.voiceNotes),
            (d.completedAt = d.eouNurseRequest.completedAt),
            (d.edNurseId = d.eouNurseRequest.edNurseId),
            (d.requestNo = d.eouNurseRequest.requestNo),
            (d.speciality = d.eouNurseRequest.speciality)
          )
        );
        setCompletedTasks(res.data.data.reverse());
      } else if (!res.data.success) {
        setOpenNotification(true);
        setErrorMsg("Cannot get the tasks at the moment");
      }
    })
    .catch((e) => {
      setOpenNotification(true);
      setErrorMsg("Cannot get the tasks at the moment");
    });
};

export const getPendingTasksForED = (
  id,
  setPendingTasks,
  setOpenNotification,
  setErrorMsg
) => {
  axios
    .get(getPendingTasksForEDurl + "/" + id)
    .then((res) => {
      if (res.data.success) {
        console.log("Pending Requests , ", res.data.data);
        res.data.data.map(
          (d) => (
            (d.mrn = d.patientId.identifier[0].value),
            (d.patientName =
              d.patientId.name[0].given[0] + " " + d.patientId.name[0].family),
            (d.status = d.edNurseRequest.status),
            (d.requestedAt = d.edNurseRequest.requestedAt),
            (d.notes = d.edNurseRequest.notes),
            (d.voiceNotes = d.edNurseRequest.voiceNotes),
            (d.completedAt = d.edNurseRequest.completedAt),
            (d.edNurseId = d.edNurseRequest.edNurseId),
            (d.requestNo = d.edNurseRequest.requestNo),
            (d.speciality = d.edNurseRequest.speciality)
          )
        );
        setPendingTasks(res.data.data.reverse());
      } else if (!res.data.success) {
        setOpenNotification(true);
        setErrorMsg("Cannot get the tasks at the moment");
      }
    })
    .catch((e) => {
      setOpenNotification(true);
      setErrorMsg("Cannot get the tasks at the moment");
    });
};

export const getCompletedTasksForED = (
  id,
  setCompletedTasks,
  setOpenNotification,
  setErrorMsg
) => {
  axios
    .get(getCompletedTasksForEDurl + "/" + id)
    .then((res) => {
      if (res.data.success) {
        console.log("Completed Requests , ", res.data.data);
        res.data.data.map(
          (d) => (
            (d.mrn = d.patientId.identifier[0].value),
            (d.patientName =
              d.patientId.name[0].given[0] + " " + d.patientId.name[0].family),
            (d.status = d.edNurseRequest.status),
            (d.requestedAt = d.edNurseRequest.requestedAt),
            (d.notes = d.edNurseRequest.notes),
            (d.voiceNotes = d.edNurseRequest.voiceNotes),
            (d.completedAt = d.edNurseRequest.completedAt),
            (d.edNurseId = d.edNurseRequest.edNurseId),
            (d.requestNo = d.edNurseRequest.requestNo),
            (d.speciality = d.edNurseRequest.speciality)
          )
        );
        setCompletedTasks(res.data.data.reverse());
      } else if (!res.data.success) {
        setOpenNotification(true);
        setErrorMsg("Cannot get the tasks at the moment");
      }
    })
    .catch((e) => {
      setOpenNotification(true);
      setErrorMsg("Cannot get the tasks at the moment");
    });
};

export function editNurseCall(value, data, setOpenDialog, props) {
  let apiCall;
  if (value.includes("ED Nurse")) {
    apiCall = updateEDNurseTask;
  } else if (value.includes("EOU Nurse")) {
    apiCall = updateEOUNurseTask;
  }
  axios
    .put(apiCall, data)
    .then((response) => {
      console.log("Response for Edit Nurse", response);
      setOpenDialog(false);
      props.history.push({
        pathname: "/dashboard/home/success",
        state: {
          message1: `Task marked as Completed`,
        },
      });
    })
    .catch((error) => {
      console.log("Error");
    });
}

export function getNurseCall(
  value,
  rec,
  url,
  dispatch,
  setOpenDialog,
  staffId
) {
  let apiCall;
  if (value.includes("ED Nurse")) {
    apiCall = getSpecialityNurse;
  } else if (value.includes("EOU Nurse")) {
    apiCall = getEOUNurse;
  }
  // else if (value === 3) {
  //   apiCall = getNurseTechnician;
  // }
  axios
    .get(apiCall + "/" + rec.speciality + "/" + staffId)
    .then((res) => {
      console.log("response for ED Nurse Call", res);
      if (res.data.success) {
        console.log(res.data.data);
        dispatch({
          field: "edNurseList",
          value: res.data.data,
        });
        dispatch({ field: "blobURL", value: url });
        dispatch({ field: "requestDescription", value: rec.notes });
        dispatch({ field: "edNurseSpeciality", value: rec.speciality });
        dispatch({ field: "requestStatus", value: rec.status });
        if (value.includes("ED Nurse")) {
          dispatch({
            field: "edNurse",
            value: rec.edNurseId,
          });
        } else if (value.includes("EOU Nurse")) {
          dispatch({
            field: "edNurse",
            value: rec.eouNurseId,
          });
        }
        // else if (value === 3) {
        //   dispatch({
        //     field: "edNurse",
        //     value: rec.nurseTechnicianId,
        //   });
        // }
        setOpenDialog(true);
      }
    })
    .catch((e) => {
      console.log("error while searching req", e);
    });
}
