import React from "react"
import { Route, Switch } from "react-router-dom"
import NotFound from "../components/NotFound/NotFound"
import DashboardDCD from "../views/DCD/DashboardDCD"
// import DashboardDCD from "../views/DCD_new/dcdForm"
import Form from "../views/DCD/InputForm/Form"
import ViewForm from "../views/DCD/ViewForm/viewForm"
import ViewFormsHistory from "../views/DCD/ViewForm/viewFormHistory"
import TriageHistory from "../views/DCD/ViewForm/triageHistory"
import ViewTriageHistory from "../views/DCD/ViewForm/viewTriageHistory"
import TriageForm from "../views/TriageAndAssesment/Tabs"

class DCDRoutes extends React.PureComponent {
  render() {
    return (
      <Switch>
        <Route
          exact
          path={`${this.props.match.url}`}
          component={DashboardDCD}
        />

        <Route
          exact
          path={`${this.props.match.url}/triageForm`}
          component={TriageForm}
        />

        <Route
          exact
          path={`${this.props.match.url}/triageForm/viewTriageForm`}
          component={TriageForm}
        />

        <Route exact path={`${this.props.match.url}/triage-history`} component={TriageHistory} />
        <Route exact path={`${this.props.match.url}/triage-history/view`} component={ViewTriageHistory} />
        <Route exact path={`${this.props.match.url}/Form`} component={Form} />
        <Route exact path={`${this.props.match.url}/viewForm`} component={ViewForm} />
        <Route exact path={`${this.props.match.url}/view-history`} component={ViewFormsHistory} />

        <Route path={`${this.props.match.url}/notfound`} component={NotFound} />

        <Route path="*" component={NotFound} />
      </Switch>
    )
  }
}
export default DCDRoutes