import axios from "axios";
import {
  getEOUPatients,
  getEDPatients,
  searchEOUPatients,
  searchEDPatients,
  getInProgressCS,
  searchInProgressCS,
  getInProgressCSForDoctors,
  completeCareStream,
  getCompletedCS,
} from "../../public/endpoins";
import _ from "lodash";

export function getAllEdrPatients(
  setLoading,
  setPatientData,
  setErrorMsg,
  setOpenNotification
) {
  axios
    .get(getEDPatients)
    .then((res) => {
      if (res.data.success) {
        res.data.data.map(
          (d) => (
            (d.patientName =
              d.patientId.name[0].given[0] + " " + d.patientId.name[0].family),
            (d.mrn = d.patientId.identifier[0].value),
            (d.gender = d.patientId.gender),
            (d.age = d.patientId.age),
            (d.createdAt = d.patientId.createdAt)
          )
        );
        console.log(res.data.data, "ED patient data");
        setPatientData(res.data.data.reverse());
        setLoading(false);
      } else if (!res.data.success) {
        setErrorMsg(res.data.error);
        setOpenNotification(true);
      }
      return res;
    })
    .catch((e) => {
      console.log("error: ", e);
    });
}
export const searchPatientCS = (
  a,
  setCareStreamData,
  setErrorMsg,
  setOpenNotification
) => {
  console.log("A", a);
  axios
    .get(searchInProgressCS + "/" + a)
    .then((res) => {
      console.log("res", res);
      if (res.data.success) {
        console.log(res.data.data, "patient data");

        res.data.data.map(
          (d) => (
            (d.productionArea =
              d.chiefComplaint &&
              d.chiefComplaint.length > 0 &&
              d.chiefComplaint[d.chiefComplaint.length - 1].chiefComplaintId
                .productionArea.length > 0
                ? d.chiefComplaint[d.chiefComplaint.length - 1].chiefComplaintId
                    .productionArea[
                    d.chiefComplaint[d.chiefComplaint.length - 1]
                      .chiefComplaintId.productionArea.length - 1
                  ].productionAreaId.paName
                : "N/A"),
            (d.CSId = d.careStream.careStreamId.identifier[0].value),
            (d.createdAt = d.careStream ? d.careStream.assignedTime : "N/A"),
            (d.careStreams = d.careStream.name)
            // d.age = d.patientId.age,
          )
        );

        setCareStreamData(res.data.data.reverse());
      } else if (!res.data.success) {
        setErrorMsg(res.data.error);
        setOpenNotification(true);
      }
    })
    .catch((e) => {
      console.log("error after searching patient request", e);
    });
};

export function getCSInProgress(
  setLoading,
  setCareStreamData,
  setErrorMsg,
  setOpenNotification
) {
  axios
    .get(getInProgressCS)
    .then((res) => {
      if (res.data.success) {
        console.log(res.data.data, "Care Stream dataa");

        res.data.data.map(
          (d) => (
            (d.requestNo = d.requestNo),
            (d.mrn = d.patientId.identifier[0].value),
            (d.patientName =
              d.patientId.name[0].given[0] + " " + d.patientId.name[0].family),
            (d.careStatus = d.careStream.status),
            (d.productionArea =
              d.chiefComplaint &&
              d.chiefComplaint.length > 0 &&
              d.chiefComplaint[d.chiefComplaint.length - 1].chiefComplaintId
                .productionArea.length > 0
                ? d.chiefComplaint[d.chiefComplaint.length - 1].chiefComplaintId
                    .productionArea[
                    d.chiefComplaint[d.chiefComplaint.length - 1]
                      .chiefComplaintId.productionArea.length - 1
                  ].productionAreaId.paName
                : "N/A"),
            (d.CSId = d.careStream.careStreamId.identifier[0].value),
            (d.createdAt = d.careStream ? d.careStream.assignedTime : "N/A"),
            (d.careStreams = d.careStream.name)
            // d.age = d.patientId.age,
          )
        );

        setCareStreamData(res.data.data.reverse());
        setLoading(false);
      } else if (!res.data.success) {
        setErrorMsg(res.data.error);
        setOpenNotification(true);
      }
      return res;
    })
    .catch((e) => {
      console.log("error: ", e);
    });
}

export function getCSInProgressForDoctors(
  setLoading,
  setCareStreamData,
  setErrorMsg,
  setOpenNotification
) {
  axios
    .get(getInProgressCSForDoctors)
    .then((res) => {
      if (res.data.success) {
        console.log(res.data.data, "Care Stream data");

        res.data.data.map(
          (d) => (
            // (d.requestNo = d.requestNo),
            (d.mrn = d.patientId.identifier[0].value),
            (d.patientName =
              d.patientId.name[0].given[0] + " " + d.patientId.name[0].family),
            (d.careStreamName = d.careStream.careStreamId.name),
            (d.careStatus = d.careStream.status),
            (d.createdAt = d.careStream.assignedTime)
          )
        );

        setCareStreamData(res.data.data.reverse());
        setLoading(false);
      } else if (!res.data.success) {
        setErrorMsg(res.data.error);
        setOpenNotification(true);
      }
      return res;
    })
    .catch((e) => {
      console.log("error: ", e);
    });
}

export function getCompletedCSCall(
  setLoading,
  setCareStreamData,
  setErrorMsg,
  setOpenNotification
) {
  axios
    .get(getCompletedCS)
    .then((res) => {
      if (res.data.success) {
        console.log(res.data.data, "Completed Care Stream data");

        res.data.data.map(
          (d) => (
            // (d.requestNo = d.requestNo),
            (d.mrn = d.patientId.identifier[0].value),
            (d.patientName =
              d.patientId.name[0].given[0] + " " + d.patientId.name[0].family),
            (d.careStreamName = d.careStream.careStreamId.name),
            (d.careStatus = d.careStream.status),
            (d.createdAt = d.careStream.assignedTime)
          )
        );

        setCareStreamData(res.data.data.reverse());
        setLoading(false);
      } else if (!res.data.success) {
        setErrorMsg(res.data.error);
        setOpenNotification(true);
      }
      return res;
    })
    .catch((e) => {
      console.log("error: ", e);
    });
}

export function completeCareStreamCall(body, setErrorMsg, setOpenNotification) {
  axios
    .put(completeCareStream, body)
    .then((res) => {
      if (res.data.success) {
        window.location.reload();
      } else if (!res.data.success) {
        setErrorMsg(res.data.error);
        setOpenNotification(true);
      }
      return res;
    })
    .catch((e) => {
      console.log("error: ", e);
    });
}
export function getAllEOUPatients(
  setLoading,
  setPatientData,
  setErrorMsg,
  setOpenNotification
) {
  axios
    .get(getEOUPatients)
    .then((res) => {
      if (res.data.success) {
        res.data.data.map(
          (d) => (
            (d.patientName =
              d.patientId.name[0].given[0] + " " + d.patientId.name[0].family),
            (d.mrn = d.patientId.identifier[0].value),
            (d.gender = d.patientId.gender),
            (d.age = d.patientId.age),
            (d.createdAt = d.patientId.createdAt)
          )
        );
        console.log(res.data.data, "EOU patient data");
        setPatientData(res.data.data.reverse());
        setLoading(false);
      } else if (!res.data.success) {
        setErrorMsg(res.data.error);
        setOpenNotification(true);
      }
      return res;
    })
    .catch((e) => {
      console.log("error: ", e);
    });
}

export const searchEdrPatientsFor = (
  a,
  setPatientData,
  setErrorMsg,
  setOpenNotification
) => {
  axios
    .get(searchEOUPatients + "/" + a)
    .then((res) => {
      if (res.data.success) {
        res.data.data.map(
          (d) => (
            (d.patientName =
              d.patientId.name[0].given[0] + " " + d.patientId.name[0].family),
            (d.mrn = d.patientId.identifier[0].value),
            (d.gender = d.patientId.gender),
            (d.age = d.patientId.age),
            (d.createdAt = d.patientId.createdAt)
          )
        );
        console.log(res.data.data, "patient data from search");
        setPatientData(res.data.data);
      } else if (!res.data.success) {
        setErrorMsg(res.data.error);
        setOpenNotification(true);
      }
    })
    .catch((e) => {
      console.log("error after searching patient request", e);
    });
};
