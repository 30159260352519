import { makeStyles } from "@material-ui/core/styles";

export const styles = {
    stylesForButton: {
        color: "white",
        cursor: "pointer",
        borderRadius: 5,
        backgroundColor: "#2c6ddd",
        height: "40px",
        outline: "none",
    },
    stylesForCloseButton: {
        color: "gray",
        cursor: "pointer",
        borderRadius: 5,
        border: '1px solid gray',
        backgroundColor: "transparent",
        height: "40px",
        outline: "none",
    }
}

export const useStylesForTabs = makeStyles(() => ({
    root: {
        justifyContent: "center",
    },
    scroller: {
        flexGrow: "0",
    },
}));

export const useStylesForInput = makeStyles((theme) => ({
    input: {
        backgroundColor: "#F5F5F5",
        boxShadow: "none",
        borderRadius: 5,
        "&:after": {
            borderBottomColor: "black",
            boxShadow: "none",
        },
        "&:hover": {
            backgroundColor: "#F5F5F5",
            boxShadow: "none",
        },
        "&:focus": {
            backgroundColor: "#F5F5F5",
            boxShadow: "none",
            borderRadius: 5,
        }
    },
    root: {
        "& .MuiTextField-root": {
            backgroundColor: "#F5F5F5",
            padding: '0px 0px 5px 10px'
        },
        "& .Mui-focused": {
            backgroundColor: "#F5F5F5",
            color: "black",
        },
        "& .Mui-disabled": {
            backgroundColor: "#F5F5F5",
            color: "gray",
        },
        '& .MuiFormLabel-root': {
            padding: '5px 0px 0px 10px',
        },
    }
}));