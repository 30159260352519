import React from "react";
import { displayInHumanDifference, replacePatientIdIfNull } from "../../utils/commonFunc";

export default function CardDataManual(props) {
  // console.log('props', props.patient)
  console.log("CardDataManual", props.patient.notificationData);
  if (props.patient.patient) {
    console.log("propsss", props.patient.patient.patientId);
  }
  // let patient = replacePatientIdIfNull(props.patient.patient);
  // console.log('patient', patient);
  return (
    <div>
      <div className="row">
        { 
          Object.entries(props.patient.notificationData).map(([v,vv]) => {
            return <div className="col-md-3">
              <span
                style={{
                  color: "#939393",
                  fontSize: 14,
                  marginLeft: 15,
                }}
              >
                {vv.name}
              </span>
              <div>
                <span style={{ fontSize: 18, marginLeft: 15 }}>
                  {vv.value}
                </span>
              </div>
            </div>
          })
        }
      </div>
    </div>
  );
}
