import React, { useEffect } from "react";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import useStylesForInput from "../../assets/jss/material-dashboard-react/inputStyle";
import styles from "./styles";
import { Grid } from "@material-ui/core";
import Loader from "react-loader-spinner";
import MicIcon from "@material-ui/icons/Mic";
import StopIcon from "@material-ui/icons/Stop";
import MicRecorder from "mic-recorder-to-mp3";
import MenuItem from "@material-ui/core/MenuItem";
import InputAdornment from "@material-ui/core/InputAdornment";
import AccountCircle from "@material-ui/icons/SearchOutlined";

export default function EdrRequest(props) {
  const matches = useMediaQuery("(min-width:600px)");

  const classes = useStylesForInput();

  useEffect(() => {
    console.log(props.item, "view Data");
  }, []);

  return (
    <Dialog
      fullScreen
      aria-labelledby="form-dialog-title"
      open={props.openDialog}
      maxWidth="xl"
      fullWidth={true}
    >
      <DialogContent style={{ backgroundColor: "rgb(19 213 159)" }}>
        <DialogTitle
          id="simple-dialog-title"
          style={{ color: "white", marginLeft: -9 }}
        >
          {props.value === 1
            ? props.viewRecord
              ? "View ED Nurse Request"
              : "Add ED Nurse Request"
            : props.value === 2
            ? "Add EOU Nurse Request"
            : props.value === 3
            ? "Add Nurse Technician Request"
            : props.value === 4
            ? "Add Consultation Request"
            : "Add Anesthesiologist Request"}
        </DialogTitle>
        <div className={`container-fluid ${classes.root}`}>
          <div className="row">
            <div
              className="col-md-12 col-sm-12 col-12"
              style={{
                ...styles.inputContainerForTextField,
                ...styles.textFieldPadding,
                padding: matches ? "" : "0px",
              }}
            >
              <TextField
                required
                multiline
                disabled={props.viewRecord ? true : false}
                rows={4}
                label={"Description"}
                name={"requestDescription"}
                value={props.requestDescription}
                error={props.requestDescription === "" && props.dialogForm}
                onChange={props.onChangeValue}
                className="textInputStyle"
                variant="filled"
                InputProps={{
                  className: classes.multilineColor,
                  classes: {
                    input: classes.multilineColor,
                  },
                }}
              />
            </div>
          </div>

          <h5
            style={{
              color: "white",
              fontWeight: "bold",
              marginTop: 10,
            }}
          >
            Doctor Notes
          </h5>
          <div className="row">
            <div
              className="col-md-12 col-sm-12 col-12"
              style={{
                padding: matches ? "" : "0px",
              }}
            >
              <Grid
                justify="flex-start"
                container
                spacing={24}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  backgroundColor: "white",
                  marginLeft: 0,
                  marginRight: 0,
                  marginTop: 15,
                  borderRadius: 5,
                  height: 125,
                  marginBottom: 15,
                  paddingLeft: 10,
                }}
              >
                <label
                  style={{
                    color: "#878787",
                    paddingRight: 30,
                    fontSize: "smaller",
                  }}
                >
                  Voice Note
                </label>
                <div>
                  {!props.isRecording ? (
                    <audio
                      style={{
                        marginRight: 10,
                        width: matches ? 450 : 220,
                        marginTop: 6,
                        outline: "none",
                      }}
                      src={props.blobURL}
                      controls="controls"
                    />
                  ) : (
                    <div
                      style={{
                        marginLeft: matches ? 110 : 18,
                        marginRight: matches ? 110 : 75,
                        width: matches ? 550 : 100,
                      }}
                    >
                      <Loader
                        type="ThreeDots"
                        color="blue"
                        height={40}
                        width={40}
                      />
                    </div>
                  )}
                </div>
                {!props.viewRecord ? (
                  <div
                    style={{
                      marginRight: matches ? 15 : 7,
                      backgroundColor: props.isRecording
                        ? "#DC004E"
                        : "#2C6DDD",
                      height: matches ? 55 : 50,
                      borderRadius: 30,
                      width: matches ? 55 : 50,
                      paddingLeft: matches ? 10 : 8,
                    }}
                  >
                    {props.isRecording ? (
                      <StopIcon
                        style={{
                          marginTop: matches ? 11 : 9,
                          color: "white",
                          cursor: "pointer",
                        }}
                        fontSize="large"
                        onClick={
                          !props.viewRecord ? () => props.stop("") : () => {}
                        }
                      />
                    ) : (
                      <MicIcon
                        style={{
                          marginTop: matches ? 12 : 10,
                          color: "white",
                          cursor: "pointer",
                        }}
                        fontSize="large"
                        onClick={
                          !props.viewRecord ? () => props.start("") : () => {}
                        }
                      />
                    )}
                  </div>
                ) : (
                  undefined
                )}
              </Grid>
            </div>
          </div>

          {props.value === 1 || props.value === 2 || props.value === 3 ? (
            !props.viewRecord ? (
              <div className="row">
                <div
                  className="col-md-6"
                  style={{
                    ...styles.inputContainerForTextField,
                    ...styles.textFieldPadding,
                    padding: matches ? "" : "0px",
                  }}
                >
                  <TextField
                    required
                    select
                    fullWidth
                    label="Speciality"
                    name={"edNurseSpeciality"}
                    value={props.edNurseSpeciality}
                    error={props.edNurseSpeciality === "" && props.dialogForm}
                    onChange={props.onChangeValue}
                    variant="filled"
                    className="dropDownStyle"
                    InputProps={{
                      className: classes.input,
                      classes: { input: classes.input },
                      disableUnderline: true,
                    }}
                  >
                    <MenuItem value="">
                      <em>Speciality</em>
                    </MenuItem>

                    {props.edNurseSpecialityList.map((val) => {
                      return (
                        <MenuItem key={val} value={val}>
                          {val}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </div>
                <div
                  className="col-md-6"
                  style={{
                    ...styles.inputContainerForTextField,
                    ...styles.textFieldPadding,
                    padding: matches ? "" : "0px",
                  }}
                >
                  <TextField
                    required
                    select
                    fullWidth
                    label={
                      props.value === 1
                        ? "Select ED Nurse"
                        : props.value === 2
                        ? "Select EOU Nurse"
                        : props.value === 3
                        ? "Select Nurse Technician"
                        : undefined
                    }
                    name={"edNurse"}
                    value={props.edNurse}
                    error={props.edNurse === "" && props.dialogForm}
                    onChange={props.onChangeValue}
                    variant="filled"
                    className="dropDownStyle"
                    InputProps={{
                      className: classes.input,
                      classes: { input: classes.input },
                      disableUnderline: true,
                    }}
                  >
                    {props.edNurseList.map((array) => {
                      return (
                        <MenuItem key={array._id} value={array._id}>
                          {array.name[0].given[0] + " " + array.name[0].family}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </div>
              </div>
            ) : (
              undefined
            )
          ) : props.value === 4 ? (
            <>
              <div className="row">
                <div
                  className="col-md-12"
                  style={{
                    ...styles.inputContainerForTextField,
                    ...styles.textFieldPadding,
                    padding: matches ? "" : "0px",
                  }}
                >
                  <TextField
                    required
                    select
                    fullWidth
                    disabled={!props.viewRecord ? false : true}
                    label="Select Consultant SubType"
                    name={"consultationType"}
                    value={props.consultationType}
                    error={props.consultationType === "" && props.dialogForm}
                    onChange={props.onChangeValue}
                    variant="filled"
                    className="dropDownStyle"
                    InputProps={{
                      className: classes.input,
                      classes: { input: classes.input },
                      disableUnderline: true,
                    }}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {props.consultantTypeArray.map((val) => {
                      return (
                        <MenuItem key={val.key} value={val.value}>
                          {val.value}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </div>
              </div>

              <div className="row">
                <div
                  className="col-md-6"
                  style={{
                    ...styles.inputContainerForTextField,
                    ...styles.textFieldPadding,
                    padding: matches ? "" : "0px",
                  }}
                >
                  <TextField
                    required
                    select
                    fullWidth
                    disabled={!props.viewRecord ? false : true}
                    label="Speciality"
                    name={"consultationSpeciality"}
                    value={props.consultationSpeciality}
                    error={
                      props.consultationSpeciality === "" && props.dialogForm
                    }
                    onChange={props.onChangeValue}
                    variant="filled"
                    className="dropDownStyle"
                    InputProps={{
                      className: classes.input,
                      classes: { input: classes.input },
                      disableUnderline: true,
                    }}
                  >
                    <MenuItem value="">
                      <em>Speciality</em>
                    </MenuItem>

                    {props.consultationSpecialityList.map((val) => {
                      return (
                        <MenuItem key={val} value={val}>
                          {val}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </div>
                <div
                  className="col-md-6"
                  style={{
                    ...styles.inputContainerForTextField,
                    ...styles.textFieldPadding,
                    padding: matches ? "" : "0px",
                  }}
                >
                  <TextField
                    required
                    select
                    fullWidth
                    disabled={!props.viewRecord ? false : true}
                    label="Select Consultant"
                    name={"consultant"}
                    value={props.consultant}
                    error={props.consultant === "" && props.dialogForm}
                    onChange={props.onChangeValue}
                    variant="filled"
                    className="dropDownStyle"
                    InputProps={{
                      className: classes.input,
                      classes: { input: classes.input },
                      disableUnderline: true,
                    }}
                  >
                    {props.consultantArray.map((array) => {
                      if (array.subType[0] === props.consultationType) {
                        return (
                          <MenuItem key={array._id} value={array._id}>
                            {array.name[0].given[0] +
                              " " +
                              array.name[0].family}
                          </MenuItem>
                        );
                      }
                    })}
                  </TextField>
                </div>
              </div>

              {props.currentUser.subType[0] &&
              props.currentUser.subType[0] !== "ED Doctor" ? (
                <>
                  <h5
                    style={{
                      color: "white",
                      fontWeight: "bold",
                      marginTop: 10,
                    }}
                  >
                    Consultant Notess
                  </h5>

                  <div className="row">
                    <div
                      className="col-md-12 col-sm-12 col-12"
                      style={{
                        ...styles.inputContainerForTextField,
                        padding: matches ? "" : "0px",
                      }}
                    >
                      <TextField
                        disabled={!props.viewRecord ? false : true}
                        required
                        multiline
                        type="text"
                        error={props.consultantNotes === "" && props.doctorForm}
                        label="Consultant Notes"
                        name={"consultantNotes"}
                        value={props.consultantNotes}
                        onChange={props.onChangeValue}
                        rows={4}
                        className="textInputStyle"
                        variant="filled"
                        InputProps={{
                          className: classes.multilineColor,
                          classes: {
                            input: classes.multilineColor,
                          },
                        }}
                        // inputProps={{ maxLength: 300 }}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div
                      className="col-md-12 col-sm-12 col-12"
                      style={{
                        padding: matches ? "" : "0px",
                      }}
                    >
                      <Grid
                        justify="flex-start" // Add it here :)
                        container
                        spacing={24}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          backgroundColor: "white",
                          marginLeft: 0,
                          marginRight: 0,
                          marginTop: 15,
                          borderRadius: 5,
                          height: 125,
                          marginBottom: 15,
                          paddingLeft: 10,
                          // justifyContent: "center",
                        }}
                      >
                        <label
                          style={{
                            color: "#878787",
                            paddingRight: 30,
                            fontSize: "smaller",
                          }}
                        >
                          Voice Note
                        </label>
                        <div>
                          {!props.isRecordingForConsultant ? (
                            <audio
                              style={{
                                marginRight: 10,
                                width: matches ? 450 : 220,
                                marginTop: 6,
                                outline: "none",
                              }}
                              src={props.consultantVoiceNotes}
                              controls="controls"
                            />
                          ) : (
                            <div
                              style={{
                                marginLeft: matches ? 110 : 18,
                                marginRight: matches ? 110 : 75,
                                width: matches ? 550 : 100,
                              }}
                            >
                              <Loader
                                type="ThreeDots"
                                color="blue"
                                height={40}
                                width={40}
                                //3 secs
                              />
                            </div>
                          )}
                        </div>

                        <div
                          style={{
                            marginRight: matches ? 15 : 7,
                            backgroundColor: props.isRecordingForConsultant
                              ? "#DC004E"
                              : "#2C6DDD",
                            height: matches ? 55 : 50,
                            borderRadius: 30,
                            width: matches ? 55 : 50,
                            paddingLeft: matches ? 10 : 8,
                          }}
                        >
                          {props.isRecordingForConsultant ? (
                            <StopIcon
                              style={{
                                marginTop: matches ? 11 : 9,
                                color: "white",
                                cursor: "pointer",
                              }}
                              fontSize="large"
                              onClick={
                                !props.viewRecord
                                  ? () => props.stop("consultant")
                                  : () => {}
                              }
                            />
                          ) : (
                            <MicIcon
                              style={{
                                marginTop: matches ? 12 : 10,
                                color: "white",
                                cursor: "pointer",
                              }}
                              fontSize="large"
                              onClick={
                                !props.viewRecord
                                  ? () => props.start("consultant")
                                  : () => {}
                              }
                            />
                          )}
                        </div>
                      </Grid>
                    </div>
                  </div>
                </>
              ) : (
                undefined
              )}
            </>
          ) : (
            <div className="row">
              <div
                className="col-md-12"
                style={{
                  ...styles.inputContainerForTextField,
                  ...styles.textFieldPadding,
                }}
              >
                <TextField
                  required
                  disabled={props.viewRecord}
                  select
                  fullWidth
                  label={"Select Anesthesiologist"}
                  name={"anesthesiologist"}
                  value={props.anesthesiologist}
                  error={props.anesthesiologist === "" && props.dialogForm}
                  onChange={props.onChangeValue}
                  variant="filled"
                  className="dropDownStyle"
                  InputProps={{
                    className: classes.input,
                    classes: { input: classes.input },
                    disableUnderline: true,
                  }}
                >
                  <MenuItem value="">
                    <em>Anesthesiologist</em>
                  </MenuItem>

                  {props.anesthesiologistList.map((val) => {
                    return (
                      <MenuItem key={val._id} value={val._id}>
                        {val.name[0].given[0] + " " + val.name[0].family}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </div>
            </div>
          )}

          <div
            class="row"
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingLeft: 5,
              paddingRight: 5,
              marginTop: 20,
            }}
          >
            <div style={{ marginTop: "2%", marginBottom: "2%" }}>
              <Button
                onClick={() => props.hideDialog()}
                style={{
                  ...styles.stylesForButton,
                  backgroundColor: "white",
                  color: "grey",
                  width: matches ? " " : 150,
                }}
                variant="contained"
              >
                <strong>Cancel</strong>
              </Button>
            </div>
            {!props.viewRecord ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: "2%",
                  marginBottom: "2%",
                }}
              >
                {props.value === 1 || props.value === 2 || props.value === 3 ? (
                  <Button
                    style={{
                      ...styles.stylesForSubmitButton,
                    }}
                    onClick={
                      props.selectedEDNurseToEdit != ""
                        ? props.handleEditEDNurse
                        : props.submitEDNurseRequest
                    }
                    variant="contained"
                    color="primary"
                  >
                    Submit
                  </Button>
                ) : props.value === 4 ? (
                  <Button
                    style={{
                      ...styles.stylesForSubmitButton,
                    }}
                    onClick={
                      props.currentUser.subType.includes("Internal") ||
                      props.currentUser.subType.includes("External")
                        ? props.completeConsultationRequest
                        : props.selectedConsultationToEdit
                        ? props.handleEditConsultationNotes
                        : props.submitConsultationNotes
                    }
                    variant="contained"
                    color="primary"
                  >
                    Submit
                  </Button>
                ) : !props.viewRecord ? (
                  <Button
                    style={{
                      ...styles.stylesForSubmitButton,
                    }}
                    onClick={
                      props.anesthesiologistToEdit != ""
                        ? props.handleEditAnesthesiologistNotes
                        : props.submitAnesthesiologistNotes
                    }
                    variant="contained"
                    color="primary"
                  >
                    Submit
                  </Button>
                ) : (
                  undefined
                )}
              </div>
            ) : (
              undefined
            )}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
