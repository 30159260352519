/*eslint-disable*/
import React, { useState, useEffect, useReducer, useCallback } from "react";
import Button from "@material-ui/core/Button";
import Notification from "../../components/Snackbar/Notification.js";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Header from "../../components/Header/Header";
import LabRequests from "../../assets/img/Lab Requests.png";
import formatDate from "../../utils/formatDate";
import ImageViewer from "react-simple-image-viewer";
import { IoIosCloseCircle } from "react-icons/io";

import { uploadsUrl } from "../../public/endpoins";
import eye_icon from "../../assets/img/Eye.png";
import { FaUpload } from "react-icons/fa";
import Back from "../../assets/img/Back_Arrow.png";
import "../../assets/jss/material-dashboard-react/components/loaderStyle.css";
import cookie from "react-cookies";
import { styles, useStyles } from "./styles.js";
import { addLabTechRequestCall } from "./networkCallsForLabTech";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import SliderComponent from "../../components/SliderComponent/sliderComponent";

import MultiSlider, { Progress, Dot } from "react-multi-bar-slider";

const statusArray = [
  {
    key: "completed",
    value: "Completed",
  },

  {
    key: "pending",
    value: "Pending",
  },
];

export default function CommunicationMgmt(props) {
  const matches = useMediaQuery("(min-width:600px)");
  const initialState = {
    delayedReason: "",
    name: "",
    status: "",
    notes: "",
    requestId: "",
    requestedAt: "",
    diagnosisArray: "",
    medicationArray: "",
    image: [],
  };

  function reducer(state, { field, value }) {
    return {
      ...state,
      [field]: value,
    };
  }

  const [state, dispatch] = useReducer(reducer, initialState);

  const {
    name,
    requestedAt,
    requestId,
    status,
    notes,
    // diagnosisArray,
    // medicationArray,
    image,
    delayedReason,
  } = state;

  const classes = useStyles();

  const [currentUser] = useState(cookie.load("current_user"));
  const [errorMsg, setErrorMsg] = useState("");
  const [DocumentUpload, setDocumentUpload] = useState([]);
  const [openNotification, setOpenNotification] = useState(false);
  const [slipUpload, setSlipUpload] = useState("");
  const [photo, setphoto] = useState([]);
  const [imagePreview, setImagePreview] = useState([]);
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [imagesDisplay, setImagesDisplay] = useState([]);
  const [edrId, setEdrId] = useState("");
  const [labId, setlabId] = useState("");
  const [viewMode, setViewMode] = useState(false);

  if (openNotification) {
    setTimeout(() => {
      setOpenNotification(false);
      setErrorMsg("");
    }, 2000);
  }

  useEffect(() => {
    console.log("props::", props);
    const { selectedRecord } = props.history.location.state;
    // const { selectedItem } = props.history.location.state;
    if (
      props.history.location.state.selectedItem &&
      props.history.location.state.selectedItem.selectedItem
    ) {
      setEdrId(props.history.location.state.selectedItem.selectedItem._id);
    } else {
      setEdrId(props.history.location.state.selectedItem._id);
    }

    setlabId(selectedRecord._id);
    // console.log("edr ID ", selectedItem.labRequest._id);
    // console.log("lab ID ", selectedRecord._id);
    if (selectedRecord.status === "completed") {
      setViewMode(true);
    }

    // setEdrId(selectedRecord._id);
    // setRadId(selectedRecord.labRequest._id);

    dispatch({ field: "requestedAt", value: selectedRecord.requestedAt });
    dispatch({ field: "requestId", value: selectedRecord.requestId });
    dispatch({ field: "notes", value: selectedRecord.notes });
    dispatch({ field: "status", value: selectedRecord.status });
    // dispatch({ field: "name", value: selectedRecord.name });
    dispatch({ field: "name", value: selectedRecord.name });
    dispatch({ field: "delayedReason", value: selectedRecord.delayedReason });
    dispatch({ field: "image", value: selectedRecord.image });
  }, []);

  const handleSubmit = () => {
    // if (status === "completed") {
    //   setOpenNotification(true);
    //   setErrorMsg("Already Completed Lab Request");
    // } else {

    let formData = new FormData();
    if (DocumentUpload && DocumentUpload.length > 0) {
      for (var x = 0; x < DocumentUpload.length; x++) {
        formData.append("Reports", DocumentUpload[x], DocumentUpload[x].name);
      }
    }
    const object = {
      labId,
      edrId,
      staffId: currentUser._id,
      status,
      // notes,
      requestedAt,
      requestId,
      delayedReason,
      staffType: currentUser.staffType,
      // activeTime: status === "active" ? new Date() : "",
      // pendingApprovalTime: status === "pending approval" ? new Date() : "",
      // completeTime: status === "completed" ? new Date() : "",
      // holdTime: status === "hold" ? new Date() : "",
    };
    formData.append("data", JSON.stringify(object));
    // console.log('form data ', formData)
    // console.log('Document ', DocumentUpload)
    if (DocumentUpload.length > 0) {
      addLabTechRequestCall(
        props,
        requestId,
        formData,
        setOpenNotification,
        setErrorMsg
      );
    } else {
      setOpenNotification(true);
      setErrorMsg("Please upload the report before submitting");
    }
  };

  function toArray(fileList) {
    return Array.prototype.slice.call(fileList);
  }

  const onDocumentUpload = (event) => {
    var file = event.target.files;

    var joined = toArray(DocumentUpload).concat(toArray(file));
    setDocumentUpload(joined);

    let fileType = [];
    for (let x = 0; x < file.length; x++) {
      fileType.push(file[x].name.slice(file[x].name.length - 3));
    }

    let arr = imagePreview;
    for (let i = 0; i < file.length; i++) {
      let reader = new FileReader();
      reader.readAsDataURL(file[i]);
      reader.onload = function(event) {
        arr.push(event.target.result);
        setImagePreview([...arr]);
      };
    }
    // dispatch({ field: "status", value: "pending" });
  };

  const onImageDelete = (index) => {
    let temp = imagePreview;
    temp.splice(index, 1);
    setImagePreview([...temp]);

    let temp1 = DocumentUpload;
    temp1.splice(index, 1);
    setDocumentUpload([...temp1]);
  };

  const openImageViewer = useCallback((index, val) => {
    setImagesDisplay(val);
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const openUploadedImageViewer = useCallback((index, val) => {
    let newImage = [];

    val.map((item, i) => {
      if (item.includes("\\")) {
        newImage.push(uploadsUrl + item.split("\\")[1]);
      } else if (item.includes("/")) {
        newImage.push(uploadsUrl + item);
      }
    });

    setImagesDisplay(newImage);
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
    setImagesDisplay([]);
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        position: "fixed",
        width: "100%",
        height: "100%",
        backgroundColor: "#60d69f",
        overflowY: "scroll",
      }}
    >
      <Header history={props.history} />

      <div className="cPadding">
        <div className="subheader">
          <div>
            <img src={LabRequests} />
            <h4>Lab Service Requests</h4>
          </div>
        </div>

        <div style={{ height: "20px" }} />

        <div className={`container-fluid ${classes.root}`}>
          <div className="row" style={styles.textFieldPadding}>
            <div
              className="col-md-12 col-sm-12 col-12"
              style={styles.textFieldPadding}
            >
              <TextField
                // select
                disabled
                fullWidth
                label="Lab Test Name"
                id="reason"
                name="name"
                value={name}
                // onChange={(e) =>
                //   dispatch({ field: "reason", value: e.target.value })
                // }
                variant="filled"
                className="dropDownStyle"
                InputProps={{
                  className: classes.input,
                  classes: { input: classes.input },
                  disableUnderline: true,
                }}
              />
              {/* <MenuItem value="">
                  <em>None</em>
                </MenuItem>

                {reasonArray.map((val) => {
                  return (
                    <MenuItem key={val.key} value={val.value}>
                      {val.value}
                    </MenuItem>
                  )
                })}
              </TextField> */}
            </div>
            <div
              className="col-md-6 col-sm-6 col-6"
              style={styles.textFieldPadding}
            >
              <TextField
                // select
                fullWidth
                label="Date"
                disabled
                id="requestedAt"
                name="requestedAt"
                value={formatDate(requestedAt)}
                // onChange={(e) =>
                //   dispatch({ field: "reason", value: e.target.value })
                // }
                variant="filled"
                className="dropDownStyle"
                InputProps={{
                  className: classes.input,
                  classes: { input: classes.input },
                  disableUnderline: true,
                }}
              />
            </div>
            <div
              className="col-md-6 col-sm-6 col-6"
              style={styles.textFieldPadding}
            >
              <TextField
                // select
                disabled
                fullWidth
                label="Requested Id"
                id="requestId"
                name="requestId"
                value={requestId}
                // onChange={(e) =>
                //   dispatch({ field: "requestId", value: e.target.value })
                // }
                variant="filled"
                className="dropDownStyle"
                InputProps={{
                  className: classes.input,
                  classes: { input: classes.input },
                  disableUnderline: true,
                }}
              />
            </div>
            <div
              className="col-md-12 col-sm-12 col-12"
              style={styles.textFieldPadding}
            >
              <TextField
                select
                fullWidth
                disabled={viewMode}
                label="Status"
                id="status"
                name="status"
                value={status}
                onChange={(e) =>
                  dispatch({ field: "status", value: e.target.value })
                }
                variant="filled"
                className="dropDownStyle"
                InputProps={{
                  className: classes.input,
                  classes: { input: classes.input },
                  disableUnderline: true,
                }}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>

                {statusArray.map((val) => {
                  return (
                    <MenuItem key={val.key} value={val.key}>
                      {val.value}
                    </MenuItem>
                  );
                })}
              </TextField>
            </div>
            <div
              className="col-md-12 col-sm-12 col-12"
              style={styles.textFieldPadding}
            >
              <Button
                variant="contained"
                component="label"
                // disabled={status === "completed"}
                style={styles.upload}
              >
                Upload Reports
                <FaUpload size={18} />
                <input
                  type="file"
                  accept=".png,.PNG,.peg,.PEG,.jpeg,.jpg,."
                  multiple
                  name="document"
                  onChange={onDocumentUpload}
                  style={{ display: "none" }}
                />
              </Button>
              {/* <TextField
                // select
                fullWidth
                label="Upload Reports"
                id="reason"
                name="reason"
                value={reason}
                onChange={(e) =>
                  dispatch({ field: "reason", value: e.target.value })
                }
                variant="filled"
                className="dropDownStyle"
                InputProps={{
                  className: classes.input,
                  classes: { input: classes.input },
                  disableUnderline: true,
                }}
              /> */}
              {/* <MenuItem value="">
                  <em>None</em>
                </MenuItem>

                {reasonArray.map((val) => {
                  return (
                    <MenuItem key={val.key} value={val.value}>
                      {val.value}
                    </MenuItem>
                  )
                })}
              </TextField> */}
            </div>
          </div>
          {image && image.length > 0 ? (
            <div className="row" style={styles.textFieldPadding}>
              <div className="col-md-12 col-sm-12 col-12">
                <h4 style={{ color: "white" }}>Laboratory Reports</h4>
              </div>
              <div className="col-md-12 col-sm-12 col-12 imageContainer">
                {image.map((item, index) => {
                  // if (item.includes("\\")) {
                  //   return (
                  //     <div key={index}>
                  //       <img
                  //         alt=""
                  //         key={index}
                  //         onClick={() => openUploadedImageViewer(index, image)}
                  //         src={uploadsUrl + item.split("\\")[1]}
                  //         className="reportImage"
                  //       />
                  //     </div>
                  //   );
                  // } else if (item.includes("/")) {
                  //   return (
                  //     <div key={index}>
                  //       <img
                  //         alt=""
                  //         key={index}
                  //         onClick={() => openUploadedImageViewer(index, image)}
                  //         src={uploadsUrl + item}
                  //         className="reportImage"
                  //       />
                  //     </div>
                  //   );
                  // }

                  if (uploadsUrl.includes("/uploads")) {
                    return (
                      <div key={index}>
                        <img
                          alt=""
                          key={index}
                          onClick={() => openUploadedImageViewer(index, image)}
                          src={uploadsUrl.split("uploads/")[0] + item}
                          className="reportImage"
                        />
                      </div>
                    );
                  } else {
                    return (
                      <div key={index}>
                        <img
                          alt=""
                          key={index}
                          onClick={() => openUploadedImageViewer(index, image)}
                          src={uploadsUrl + item}
                          className="reportImage"
                        />
                      </div>
                    );
                  }
                })}
              </div>
            </div>
          ) : (
            undefined
          )}

          {imagePreview && imagePreview.length > 0 ? (
            <div className="row" style={styles.textFieldPadding}>
              <div className="col-md-12 col-sm-12 col-12">
                <h4 style={{ color: "white" }}>Laboratory Reports</h4>
              </div>
              <div className="col-md-12 col-sm-12 col-12 imageContainer">
                {imagePreview.map((view, index) => {
                  return (
                    <div key={index}>
                      <img
                        alt=""
                        key={index}
                        src={view}
                        onClick={() => openImageViewer(index, imagePreview)}
                        className="reportImage"
                      />
                      <IoIosCloseCircle
                        onClick={() => onImageDelete(index)}
                        size={30}
                        className="closeIcon"
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          ) : (
            undefined
          )}

          <div className="row" style={styles.textFieldPadding}>
            <div
              className="col-md-12 col-sm-12 col-12"
              style={styles.textFieldPadding}
            >
              <TextField
                // required
                disabled={viewMode}
                multiline
                label="Reason of Delay ( If any )"
                name={"delayedReason"}
                value={delayedReason}
                onChange={(e) =>
                  dispatch({ field: "delayedReason", value: e.target.value })
                }
                className="textInputStyle"
                rows={4}
                variant="filled"
                InputProps={{
                  className: classes.input,
                  classes: { input: classes.input },
                  disableUnderline: true,
                }}
              />
              {/* )} */}
            </div>
          </div>

          <div className="row" style={{ marginTop: 30 }}>
            {/* <div className="col-md-6 col-sm-6 col-6">
              <img
                onClick={() => props.history.goBack()}
                src={Back}
                style={{
                  width: 45,
                  height: 35,
                  cursor: "pointer",
                }}
              />
            </div> */}

            <div
              className="col-md-12  col-12 d-flex justify-content-end"
              style={{ paddingRight: 0 }}
            >
              {!viewMode ? (
                <Button
                  // disabled={viewMode}
                  onClick={handleSubmit}
                  style={{
                    ...styles.stylesForButton,
                    width: matches ? 150 : 302,
                    height: matches ? 45 : 40,
                  }}
                  variant="contained"
                  color="primary"
                >
                  <strong>Submit</strong>
                </Button>
              ) : (
                ""
              )}
            </div>
          </div>

          <div className="row" style={{ marginTop: 20 }}>
            <div className="col-md-6 col-sm-6 col-12">
              <img
                onClick={() => props.history.goBack()}
                src={Back}
                style={{
                  width: 45,
                  height: 35,
                  cursor: "pointer",
                }}
              />
            </div>
          </div>
        </div>

        <Notification msg={errorMsg} open={openNotification} />
      </div>
    </div>
  );
}
