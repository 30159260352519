import {
  getSearchedLaboratoryService,
  getSearchedRadiologyService,
  getSearchedNurseService,
  updateEdrIpr,
  getIcd,
  searchpatient,
  notifyConsultation,
  getSearchedpatient,
  notifyLab,
  notifyRad,
  SearchEDRPatients,
  getPatientByIdUrl,
  getEDRFromPatientForDischarge,
} from "../../public/endpoins";

import axios from "axios";

export const handleSearchPatient = (
  e,
  setpatientFoundSuccessfully,
  setpatientFound,
  setLoadSearchedData
) => {
  if (e.length >= 3) {
    axios
      .get(getEDRFromPatientForDischarge + "/" + e)
      .then((res) => {
        if (res.data.success) {
          if (res.data.data.length > 0) {
            console.log(res.data.data);
            // let response = res.data.data.map((edr) => {
            //   return { ...edr.patientId };
            // });

            let response = res.data.data;
            response.map(
              (p) => (
                (p.profileNo = p.patientFullInfo.identifier[0].value),
                (p.firstName = p.patientFullInfo.name[0].given[0]),
                (p.lastName = p.patientFullInfo.name[0].family),
                (p.gender = p.patientFullInfo.gender),
                (p.age = p.patientFullInfo.age),
                (p.weight = p.patientFullInfo.weight)
              )
            );
            console.log(response);

            setpatientFoundSuccessfully(true);
            setpatientFound(response);
            setLoadSearchedData(false);
          } else {
            setpatientFoundSuccessfully(false);
            setpatientFound("");
            setLoadSearchedData(false);
          }
        }
      })
      .catch((e) => {
        console.log("error after searching patient request", e);
      });
  }
};
