import axios from "axios";
import ExtractTimeFromDate from "../../utils/extractTimeFromDate";
import {
  getAllParamedicsUrl,
  searchParamedics,
  edrFromParamedicsURL,
  searchParamedicsEdrURL,
  criticalCasesPerParamedicsUrl,
  searchParamedicsByCriricalCasesUrl,
} from "../../public/endpoins";

export function getAllParamedics(
  setStaffData,
  setGeneralArray,
  setOpenNotification,
  setErrorMsg
) {
  axios
    .get(getAllParamedicsUrl)
    .then((res) => {
      if (res.data.success) {
        console.log(res.data.data);
        setGeneralArray(res.data.data);
      } else if (!res.data.success) {
        setErrorMsg(res.data.error);
        setOpenNotification(true);
      }
      return res;
    })
    .catch((e) => {
      console.log("error: ", e);
    });
}

export function criticalCasesPerParamedics(
  setStaffData,
  setGeneralArray,
  setOpenNotification,
  setErrorMsg
) {
  axios
    .get(criticalCasesPerParamedicsUrl)
    .then((res) => {
      if (res.data.success) {
        console.log(res.data.data);
        setGeneralArray(res.data.data);
      } else if (!res.data.success) {
        setErrorMsg(res.data.error);
        setOpenNotification(true);
      }
      return res;
    })
    .catch((e) => {
      console.log("error: ", e);
    });
}

export const getSearchParamedics = (
  a,
  setGeneralArray,
  setOpenNotification,
  setErrorMsg
) => {
  axios
    .get(searchParamedicsEdrURL + "/" + a)
    .then((res) => {
      console.log("res", res);
      if (res.data.success) {
        if (res.data.data) {
          console.log("Search Sensei ", res.data.data);
          setGeneralArray(res.data.data);
          // res.data.data.map(
          //   (d) =>
          //     (d.patientName =
          //       d.patientId.name[0].given[0] + " " + d.patientId.name[0].family)
          // );
          console.log(
            "after map Search Sensei "
            // res.data.data.map((patInfo) => {
            //   console.log(
            //     ("" ,patInfo.patientName = patInfo.patientId.name[0].given[0])
            //   );
            // })
          );
        } else {
          console.log("Your  Sensei Not Found");
          setGeneralArray(" ");
        }
      } else if (!res.data.success) {
        setErrorMsg(res.data.error);
        setOpenNotification(true);
      }
    })
    .catch((e) => {
      console.log("error after searching patient request", e);
    });
};

export const searchParamedicsByCriricalCases = (
  a,
  setGeneralArray,
  setOpenNotification,
  setErrorMsg
) => {
  axios
    .get(searchParamedicsByCriricalCasesUrl + "/" + a)
    .then((res) => {
      console.log("res", res);
      if (res.data.success) {
        if (res.data.data) {
          console.log("Search Sensei ", res.data.data);
          setGeneralArray(res.data.data);
          // res.data.data.map(
          //   (d) =>
          //     (d.patientName =
          //       d.patientId.name[0].given[0] + " " + d.patientId.name[0].family)
          // );
          console.log(
            "after map Search Sensei "
            // res.data.data.map((patInfo) => {
            //   console.log(
            //     ("" ,patInfo.patientName = patInfo.patientId.name[0].given[0])
            //   );
            // })
          );
        } else {
          console.log("Your  Sensei Not Found");
          setGeneralArray(" ");
        }
      } else if (!res.data.success) {
        setErrorMsg(res.data.error);
        setOpenNotification(true);
      }
    })
    .catch((e) => {
      console.log("error after searching patient request", e);
    });
};

export function getAllPatients(
  setStaffData,
  setGeneralArray,
  setOpenNotification,
  setErrorMsg
) {
  axios
    .get(edrFromParamedicsURL)
    .then((res) => {
      if (res.data.success) {
        console.log(res.data.data);
        setGeneralArray(res.data.data);
      } else if (!res.data.success) {
        setErrorMsg(res.data.error);
        setOpenNotification(true);
      }
      return res;
    })
    .catch((e) => {
      console.log("error: ", e);
    });
}
