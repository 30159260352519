export default function ValidateEmail(mail) {
  if (
    /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=])(?=\S+$).{8,}$/.test(
      mail
    )
  ) {
    return true
  }
  return false
}
