import axios from 'axios'
import { getCCPatients, searchCCPatients, getSingleEdr } from '../../public/endpoins'
import _ from 'lodash'
import { displayInHumanDifference, replacePatientIdIfNull } from "../../utils/commonFunc";

export function getAllCCPatients(setData, setErrorMsg, setOpenNotification) {
  axios
    .get(getCCPatients)
    .then((res) => {
      console.log('response', res.data.data)
      if (res.data.success) {
        res.data.data.map(
          (d) =>
            (d.patientName =
              d.patientId.name[0].given[0] + ' ' + d.patientId.name[0].family)
        )
        res.data.data.map((d) => (d.createdAt = d.patientId.createdAt))
        res.data.data.map((d) => (d.mrn = d.patientId.identifier[0].value))
        res.data.data.map(
          (d) =>
            (d.chiefCompl =
              d.chiefComplaint.length > 0 &&
              d.chiefComplaint[d.chiefComplaint.length - 1].chiefComplaintId &&
                  (
                    d.chiefComplaint[d.chiefComplaint.length - 1].chiefComplaintId.code == "OTHER" ? 
                    d.chiefComplaint[d.chiefComplaint.length - 1].chiefComplaintOthers[0].short_desc : d.chiefComplaint[d.chiefComplaint.length - 1].chiefComplaintId.short_name
                  )
              )
        )

        var sortObj = _.sortBy(res.data.data, 'createdAt').reverse()
        setData(sortObj)
      } else if (!res.data.success) {
        setErrorMsg(res.data.error)
        setOpenNotification(true)
      }
      return res
    })
    .catch((e) => {
      console.log('error: ', e)
    })
}

export function SearchProductionArea(setData, a) {
  axios
    .get(searchCCPatients + '/' + a)
    .then((res) => {
      console.log('searched response', res)
      if (res.data.success) {
        if (res.data.data) {
          res.data.data.map(
            (d) =>
              (d.patientName =
                d.patientId.name[0].given[0] + ' ' + d.patientId.name[0].family)
          )
          res.data.data.map((d) => (d.createdAt = d.patientId.createdAt))
          res.data.data.map((d) => (d.mrn = d.patientId.identifier[0].value))
          res.data.data.map(
            (d) =>
              (d.chiefCompl =
                d.chiefComplaint.length > 0 &&
                d.chiefComplaint[d.chiefComplaint.length - 1]
                  .chiefComplaintId &&
                d.chiefComplaint[d.chiefComplaint.length - 1].chiefComplaintId
                  .name)
          )
          var sortSearchedObj = _.sortBy(res.data.data, 'createdAt').reverse()
          setData(sortSearchedObj)
        } else {
          setData(' ')
        }
      }
    })
    .catch((e) => {
      console.log('error after searching patient request', e)
    })
}

export function getPatientEdrDetails(edrId, setData) {
  axios
    .get(`${getSingleEdr}/${edrId}`)
    .then((res) => {
      let dataReturn = replacePatientIdIfNull(res.data.data);
      setData(dataReturn);
      return res;
    })
    .catch((e) => {
      console.log("getPatientEdrDetails:: error: ", e);
    });
}
