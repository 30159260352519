import React from "react";
import Avatar from "@material-ui/core/Avatar";
import Badge from "@material-ui/core/Badge";
import { makeStyles, withStyles, fade } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  avatar: {
    height: 60,
    width: 60,
    backgroundColor:"#F35528"
  },
  badgeImage: {
    backgroundColor: "green",
    color: "white",
    top: 10,
    right: 10,
  },

  badgeImageForOfflineUser: {
    backgroundColor: "white",
    color: "white",
    top: 10,
    right: 10,
  },
}));

function User(props) {
  const classes = useStyles();

  function loggedInUser() {
    if (props.connectedUsers.length > 0) {
      return props.connectedUsers.find((id) => props.selectedUser._id === id);
    }
  }

  return (
    <div
      style={{ marginRight: 30, cursor: "pointer" }}
      key={props.key}
      onClick={() => props.getChatHandler(props.selectedUser)}
    >
      <Badge
        invisible={loggedInUser() ? false : true}
        color="primary"
        variant="dot"
        // overlap="dot"
        badgeContent="    "
        classes={{
          badge: classes.badgeImage,
        }}
      >
        <Avatar
          alt="Remy Sharp"
          // src="/static/images/avatar/1.jpg"
          className={classes.avatar}
        >
          {props.name[0].toUpperCase()}
        </Avatar>
      </Badge>
      <p
        style={{
          whiteSpace: "nowrap",
          width: 90,
          overflow: "hidden",
          textOverflow: "ellipsis",
          fontSize:14
        }}
      >
        {props.name}
      </p>
    </div>
  );
}

export default React.memo(User);
