import React, { useEffect, useState, useReducer } from "react";
import { withStyles } from '@material-ui/core/styles';
import Header from "../../components/Header/Header";
import classNames from "classnames";
import { 
  Button, 
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment,
  MenuItem,
  TextField,
  Select,
  useMediaQuery,
} from "@material-ui/core";
import cookie from "react-cookies";

import axios from "axios";
import {
  getSingleRRRP2Sample,
} from "../../public/endpoins";

import useStylesForInput from "../../assets/jss/material-dashboard-react/inputStyle";
import styles from "./styles";
import Loader from "react-loader-spinner";
import Icon from '@material-ui/core/Icon';
import CloseIcon from '@material-ui/icons/Close';
import MicIcon from "@material-ui/icons/Mic";
import StopIcon from "@material-ui/icons/Stop";
import MicRecorder from "mic-recorder-to-mp3";
import AccountCircle from "@material-ui/icons/SearchOutlined";
import Notification from "../../components/Snackbar/Notification.js";

import LabIcon from "../../assets/img/Lab.png";
import { displayInHumanClassic, replacePatientIdIfNull } from "../../utils/commonFunc";
import PatientDetailsQuick from "../../components/PatientDetails/PatientDetailsQuick";
import PatientDetails from "../../components/PatientDetails/PatientDetails";
import PatientDetails2 from "../../components/PatientDetails/PatientDetailsDirect";
import Back from '../../assets/img/Back_Arrow.png'

export default function RadReqReceiverWorkDetails(props) {
  
  const matches = useMediaQuery("(min-width:600px)");
  const classes = useStylesForInput();

  const mystyle = {
    color: "white",
    backgroundColor: "DodgerBlue",
    padding: "10px",
    fontFamily: "Arial"
  };

  


  const initialState = {
    bulkTestSelected: false,
    resetedOn: "",
    defaultItems: {},
    seletedLabItems: [],
    labPriority: "",
    labTechnician: "",
    labComments: "",
    labTechniciansList: [],
  };
  function reducer(state, { field, value }) {
    return {
      ...state,
      [field]: value,
    };
  }
  const [state, dispatch] = useReducer(reducer, initialState);
  const {
    bulkTestSelected,
    resetedOn,
    defaultItems,
    seletedLabItems,
    labPriority,
    labTechnician,
    labComments,
    labTechniciansList,
  } = state;

  const handleCheckupChange = (event) => {
    dispatch({field: 'bulkTestSelected', value: event.target.value});
  };

  const uniqueCodeField = (name) => {
    name = name.toLowerCase();
    name = name.replace(/[^a-z0-9]+/g, '-');
    name = name.replace(/^-+|-+$/g, '');
    return name;
  }

  const [currentUser] = useState(cookie.load("current_user"));
  const [timer, setTimer] = useState(null);
  const [selectedLab, setSelectedLab] = useState("");
  const [categoriesData, setCategoriesData] = useState([]);
  const [mItems, setMItems] = useState([]);

  const [patientDetails, setPatientDetails] = useState("");
  const [selectedRecord, setSelectedRecord] = useState({});

  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setsuccessMsg] = useState("");
  const [openNotification, setOpenNotification] = useState(false);

  const [ episodeId, setEpisodeId ] = useState(props.match.params.id);
  const [ radRequestId, setRadRequestId ] = useState(props.match.params.subRequestId);
  const [ radRequestDisplayId, setRadRequestDisplayId ] = useState("");
  const [ radRequest, setRadRequest ] = useState({});
  const [ radServices, setRadServices ] = useState([]);
  const [ hasPending, setHasPending] = useState(false);

  
  if (openNotification) {
    setTimeout(() => {
      setOpenNotification(false);
      setErrorMsg("");
      setsuccessMsg("");
    }, 9000);
  }

  useEffect(() => {
    axios
      .get(`${getSingleRRRP2Sample}/${episodeId}/${radRequestId}`)
      .then((res) => {
        if (res.data.success) {

          let mData = res.data.data[0];
          let remoteRecord = replacePatientIdIfNull(mData);
          setSelectedRecord(remoteRecord);
          console.log('handleView::remoteRecord::', remoteRecord);

          const object = {
            '_id': remoteRecord._id,
            patientName: remoteRecord.patientId.name[0].given[0] + " " + remoteRecord.patientId.name[0].family,
            episodeNumber: remoteRecord.requestNo,//remoteRecord.patientId.identifier[0].value,
            mrn: remoteRecord.patientId.identifier[0].value,
            age: remoteRecord.patientId.age,
            gender: remoteRecord.patientId.gender,
            weight: remoteRecord.patientId.weight,
            department: remoteRecord.currentLocation,
            chiefComplaint: remoteRecord.chiefComplaint.length > 0 ? remoteRecord.chiefComplaint.pop().chiefComplaintId.short_desc : "N/A",
            chiefComplaintOthers: remoteRecord.chiefComplaintOthers,
          };
          setPatientDetails(object);

          let radServicesX = remoteRecord.radRequest;
          console.log('handleView::radServicesX::', radServicesX);
          if (radServicesX){
            let labXN = radServicesX;
            setRadRequestDisplayId(labXN.requestId)
            labXN.requestedName = labXN.requestedBy.name[0].given[0] + " " + labXN.requestedBy.name[0].family;
            console.log('handleView::radServicesX', labXN.requestedBy.name);
            console.log('handleView::radServicesX', labXN);
            setRadRequest(labXN)
            // setRadRequest(radServicesX[0])
            setRadServices(radServicesX.services);
            console.log('handleView::radServicesX::radServices', radServicesX.services);
          }else{
            console.log('handleView::radServicesX::radServices', 'No Services');
          }

        }
      })
      .catch((e) => {
        console.log("error after searching patient request", e);
      });
    /*getLabTechniciansCall( currentUser._id, dispatch, setOpenNotification, setErrorMsg );*/
  }, []);

  const handleView = (rec) => {
    console.log('rec::rec::', rec);
    let url = '/dashboard/home/rrrp2/details/'+episodeId+'/'+radRequestId+'/view/'+rec.requestId+'';
    props.history.push({
      pathname: url,
    });
  }

  return (
    
    <div
      style={{
        backgroundColor: "rgb(19 213 159)",
        position: "fixed",
        display: "flex",
        width: "100%",
        height: "100%",
        flexDirection: "column",
        flex: 1,
        overflowY: "scroll",
      }}
    >
      <Header history={props.history} />

      <PatientDetailsQuick
        edrId={episodeId} />

      <div className="cPadding">

        {patientDetails && radRequest ? (
          <div className="mb-3">
            <div className="card p-3">
              <div className="row row-5">
                <div className="col-6 col-lg-3 card-detail-block block-xdiv mb-2"><strong className="lblX">Episode #</strong><span className="valX">{patientDetails.episodeNumber}</span></div>
                <div className="col-6 col-lg-3 card-detail-block block-xdiv mb-2"><strong className="lblX">MRN #</strong><span className="valX">{patientDetails.mrn}</span></div>
                <div className="col-6 col-lg-3 card-detail-block block-xdiv mb-2"><strong className="lblX">Requester</strong><span className="valX">{radRequest.requestedName}</span></div>
                <div className="col-6 col-lg-3 card-detail-block block-xdiv mb-2"><strong className="lblX">Date and Time</strong><span className="valX">{displayInHumanClassic(radRequest.requestedAt)}</span></div>
              </div>
            </div>
          </div>
        ) : (
          undefined
        )}

        <h4 className="h6 text-white mb-3"
          style={{
            fontWeight: '700'
          }}>{radRequestDisplayId} Service Details</h4>
        <div className="row row-5">
          {
            radServices ? 
            Object.entries(radServices).map((obj, entry) => {
              return (
                <div className="col-12 col-md-6 col-lg-6 col-xl-6">
                  <div className="card card-detail-info mb-2">
                    <div className="row row-5">
                      {/*<div className="col-sm-6 card-detail-block block-xdiv mb-2"><strong className="lblX">Request ID</strong><span className="valX">{obj[1].requestId}</span></div>*/}
                      <div className="col-sm-12">&nbsp;</div>
                      <div className="col-sm-12 card-detail-block block-xdiv"><strong className="lblX">Profile/Test</strong><span className="valX">{obj[1].category}: <span>{obj[1].name}</span></span></div>
                    </div>
                    <div className="card-status-action">
                      <span className={classNames(`badge`, (obj[1].status==='completed'?'badge-success':'status-pending'))}>{(obj[1].insuranceStatus=="Approved" || obj[1].insuranceStatus=="Covered" || obj[1].insuranceStatus=="Selfpay")?"RCM Approved":`RCM ${obj[1].insuranceStatus}`} / {obj[1].status==="completed"?'Completed':'Pending'}</span>
                      { 
                        obj[1].urgent==="Yes"?<span className="ml-info">!</span>:undefined
                      }
                      <i className="ml-view ml-10 zmdi zmdi-eye zmdi-hc-2x" onClick={() => {
                        handleView(obj[1]);
                      }} />
                    </div>
                  </div>
                </div>
              );
            }):undefined
          }
        </div>
        <div className='row mt-4 mb-4'>
          <div className='col-md-12 col-sm-12 col-12'>
            <img
              onClick={() => {
                let url = '/dashboard/home/rrrp2';
                props.history.push({
                  pathname: url,
                });
              }}
              src={Back}
              style={{
                width: 45,
                height: 35,
                cursor: 'pointer',
              }}
            />
          </div>
        </div>
      </div>
      <Notification
        msg={errorMsg}
        open={openNotification}
        success={successMsg}
      />
    </div>
  );
}