import axios from "axios";
import { roDashboard } from "../../../public/endpoins";
export function getRODashboardCalls(
  colors,
  endpoint,
  dataKey,
  pendingValue,
  setTAT,
  setTATColor,
  setDailorValue,
  setDialorColor,
  setOpenNotification,
  setErrorMsg,
  setGraphData,
  perHourData,
  setisLoading
) {
  axios
    .get(endpoint)
    .then((res) => {
      // console.log('hello')
      if (res.data.success) {
        console.log("response", res.data);
        console.log("response after fetch**", perHourData, res.data[dataKey]);
        setGraphData(res.data[dataKey][perHourData]);
        if (
          res.data[dataKey].averageTAT >= 0 &&
          res.data[dataKey].averageTAT <= 5
        ) {
          setTATColor(colors.first);
        } else if (
          res.data[dataKey].averageTAT >= 6 &&
          res.data[dataKey].averageTAT <= 10
        ) {
          setTATColor(colors.second);
        } else if (res.data[dataKey].averageTAT >= 11) {
          setTATColor(colors.third);
        }

        if (
          res.data[dataKey][pendingValue] >= 0 &&
          res.data[dataKey][pendingValue] <= 3
        ) {
          setDialorColor(colors.first);
        } else if (
          res.data[dataKey][pendingValue] >= 4 &&
          res.data[dataKey][pendingValue] <= 5
        ) {
          setDialorColor(colors.second);
        } else if (res.data[dataKey][pendingValue] >= 6) {
          setDialorColor(colors.third);
        }

        setTAT(res.data[dataKey].averageTAT);
        setDailorValue(res.data[dataKey][pendingValue]);
        setisLoading(false);
      } else if (!res.data.success) {
        setErrorMsg(res.data.error);
        setOpenNotification(true);
      }
      return res;
    })
    .catch((e) => {
      console.log("error: ", e);
    });
}

export function getROCall(
  setResponseData,
  setTotalInsuredColor,
  setTotalUnInsuredColor,
  setCumulativeRegistrationsColor,
  setAverageTATColor,
  setRegisteredPatientsColor,
  setTotalInsured,
  setTotalUnInsured,
  setOpenNotification,
  setErrorMsg,
  setCumulativeRegistrations,
  setAvailableEdBeds,
  setAverageTAT,
  setRegisteredPatients,
  setDischargesPending,
  setDichargeTAT,
  setDichargeTATColor,
  setDischargesPendingColor,
  setDischargeGraphData,
  setisLoading
) {
  axios
    .get(roDashboard)
    .then((res) => {
      console.log("ro response", res.data);
      if (res.data.success) {
        setResponseData(res.data);
        if (res.data.totalInsured >= 0 && res.data.totalInsured <= 24) {
          setTotalInsuredColor("#60D69F");
        } else if (res.data.totalInsured >= 25 && res.data.totalInsured <= 36) {
          setTotalInsuredColor("#FFBC28");
        } else if (res.data.totalInsured >= 37) {
          setTotalInsuredColor("#FF0000");
        }
        if (res.data.totalUnInsured >= 0 && res.data.totalUnInsured <= 16) {
          setTotalUnInsuredColor("#60D69F");
        } else if (
          res.data.totalUnInsured >= 17 &&
          res.data.totalUnInsured <= 24
        ) {
          setTotalUnInsuredColor("#FFBC28");
        } else if (res.data.totalUnInsured >= 25) {
          setTotalUnInsuredColor("#FF0000");
        }
        if (
          res.data.cumulativeRegistrations >= 0 &&
          res.data.cumulativeRegistrations <= 40
        ) {
          setCumulativeRegistrationsColor("#60D69F");
        } else if (
          res.data.cumulativeRegistrations >= 41 &&
          res.data.cumulativeRegistrations <= 60
        ) {
          setCumulativeRegistrationsColor("#FFBC28");
        } else if (res.data.cumulativeRegistrations >= 61) {
          setCumulativeRegistrationsColor("#FF0000");
        }
        if (res.data.averageTAT >= 0 && res.data.averageTAT <= 39) {
          setAverageTATColor("#60D69F");
        } else if (res.data.averageTAT >= 40 && res.data.averageTAT <= 70) {
          setAverageTATColor("#FFBC28");
        } else if (res.data.averageTAT >= 80) {
          setAverageTATColor("#FF0000");
        }
        if (
          res.data.registeredPatients >= 0 &&
          res.data.registeredPatients <= 5
        ) {
          setRegisteredPatientsColor("#60D69F");
        } else if (
          res.data.registeredPatients >= 6 &&
          res.data.registeredPatients <= 9
        ) {
          setRegisteredPatientsColor("#FFBC28");
        } else if (res.data.registeredPatients >= 10) {
          setRegisteredPatientsColor("#FF0000");
        }

        if (res.data.fifthCard.TAT >= 0 && res.data.fifthCard.TAT <= 20) {
          setDichargeTATColor("#60D69F");
        } else if (
          res.data.fifthCard.TAT >= 21 &&
          res.data.fifthCard.TAT <= 30
        ) {
          setDichargeTATColor("#FFBC28");
        } else if (res.data.fifthCard.TAT >= 31) {
          setDichargeTATColor("#FF0000");
        }

        if (
          res.data.fifthCard.totalPending >= 0 &&
          res.data.fifthCard.totalPending <= 2
        ) {
          setDischargesPendingColor("#60D69F");
        } else if (
          res.data.fifthCard.totalPending >= 3 &&
          res.data.fifthCard.totalPending <= 4
        ) {
          setDischargesPendingColor("#FFBC28");
        } else if (res.data.fifthCard.totalPending >= 5) {
          setDischargesPendingColor("#FF0000");
        }

        setTotalInsured(res.data.totalInsured);
        setTotalUnInsured(res.data.totalUnInsured);
        setCumulativeRegistrations(res.data.cumulativeRegistrations);
        setAvailableEdBeds(res.data.availableEdBeds);
        setAverageTAT(res.data.averageTAT);
        setRegisteredPatients(res.data.registeredPatients);
        setDischargesPending(res.data.fifthCard.totalPending);
        setDichargeTAT(res.data.fifthCard.TAT);
        setDischargeGraphData(res.data.fifthCard.perHour);
        setisLoading(false);
      } else if (!res.data.success) {
        setErrorMsg(res.data.error);
        setOpenNotification(true);
      }
      return res;
    })
    .catch((error) => {
      console.log(error);
    });
}
