import React, { useEffect, useState, useReducer } from "react";
import { withStyles } from '@material-ui/core/styles';
import Header from "../../components/Header/Header";
import classNames from "classnames";
import Back from "../../assets/img/Back_Arrow.png";
import { 
  Button, 
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment,
  MenuItem,
  TextField,
  Select,
  useMediaQuery,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
} from "@material-ui/core";
import cookie from "react-cookies";

import axios from "axios";
import {
  audioURL,
  getSingleEdr,
  getSingleConsultationNotesForDoctor,
  updateConsultaionNotesForDoctorRequest,
  updateConsultationNoteForDoctorRequest
} from "../../public/endpoins";

import useStylesForInput from "../../assets/jss/material-dashboard-react/inputStyle";
import styles from "./styles";
import Loader from "react-loader-spinner";
import {
  Checkbox, 
  FormLabel, 
  FormControl, 
  FormGroup, 
  FormControlLabel,
  RadioGroup, 
  Radio
} from '@material-ui/core';
import Icon from '@material-ui/core/Icon';
import CloseIcon from '@material-ui/icons/Close';
import MicIcon from "@material-ui/icons/Mic";
import StopIcon from "@material-ui/icons/Stop";
import MicRecorder from "mic-recorder-to-mp3";
import AccountCircle from "@material-ui/icons/SearchOutlined";
import Notification from "../../components/Snackbar/Notification.js";

import LabIcon from "../../assets/img/Lab.png";
import { displayInHumanDifference, replacePatientIdIfNull } from "../../utils/commonFunc";
import PatientDetailsQuick from "../../components/PatientDetails/PatientDetailsQuick";
import PatientDetails from "../../components/PatientDetails/PatientDetails";
import PatientClinicalInformation from "../../components/PatientDetails/PatientClinicalInformation";

export default function PatientsRequestsDetailPage(props) {
  
  const matches = useMediaQuery("(min-width:600px)");
  const classes = useStylesForInput();

  const mystyle = {
    color: "white",
    backgroundColor: "DodgerBlue",
    padding: "10px",
    fontFamily: "Arial"
  };

  const GreenCheckbox = withStyles({
    root: {
      color: '#AAAAAA',
      '&$checked': {
        color: '#AAAAAA',
      },
    },
    checked: {
      color: '#2C6DDD',
      '&$checked': {
        color: '#2C6DDD',
      },
    },
  })((props) => <Checkbox color="default" {...props} />);

  const GreenRadio = withStyles({
    root: {
      color: '#AAAAAA',
      '&$checked': {
        color: '#AAAAAA',
      },
    },
    checked: {
      color: '#2C6DDD',
      '&$checked': {
        color: '#2C6DDD',
      },
    },
  })((props) => <Radio color="default" {...props} />);

  const Mp3Recorder = new MicRecorder({ bitRate: 128 });  

  const initialState = {
    bulkTestSelected: false,
    resetedOn: "",
    defaultItems: {},
    seletedLabItems: [],
    labPriority: "",
    labTechnician: "",
    labComments: "",
    labTechniciansList: [],

    isBlocked: false,
    isRecording: false,
    blobURL: '',
    fileAudio: '',
    comments: "",
  };
  function reducer(state, { field, value }) {
    return {
      ...state,
      [field]: value,
    };
  }
  const [state, dispatch] = useReducer(reducer, initialState);
  const {
    bulkTestSelected,
    resetedOn,
    defaultItems,
    seletedLabItems,
    labPriority,
    labTechnician,
    labComments,
    labTechniciansList,

    isBlocked,
    isRecording,
    blobURL,
    fileAudio,
    comments,
  } = state;

  const handleCheckupChange = (event) => {
    dispatch({field: 'bulkTestSelected', value: event.target.value});
  };

  const handleDelivered = (status) => {
    axios
      .post(updateConsultaionNotesForDoctorRequest, {
        edrId: episodeId,
        requestId: consultancyRequestId,
        staffId: currentUser._id,
        status: status
      })
      .then((res) => {
        if (res.data.success) {
          setOpenNotification(true);
          setsuccessMsg(`The Pharma Request has been marked as delivered!`);
          window.location.reload();
        }
      })
      .catch((e) => {
        setOpenNotification(true);
        setErrorMsg("Unable to Update the Record");
      });
  }

  const uniqueCodeField = (name) => {
    name = name.toLowerCase();
    name = name.replace(/[^a-z0-9]+/g, '-');
    name = name.replace(/^-+|-+$/g, '');
    return name;
  }

  const [currentUser] = useState(cookie.load("current_user"));
  const [timer, setTimer] = useState(null);
  const [selectedLab, setSelectedLab] = useState("");
  const [categoriesData, setCategoriesData] = useState([]);
  const [mItems, setMItems] = useState([]);

  const [patientDetails, setPatientDetails] = useState("");
  const [selectedRecord, setSelectedRecord] = useState({});

  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setsuccessMsg] = useState("");
  const [openNotification, setOpenNotification] = useState(false);

  //edrId/details/:serviceId

  const [ episodeId, setEpisodeId ] = useState(props.match.params.id);
  const [ consultancyRequestId, setConsultancyRequestId ] = useState(props.match.params.requestId);
  const [ consultancyRequest, setConsultancyRequest ] = useState({});
  const [ pharmaServices, setPharmaServices ] = useState([]);
  const [ serviceType, setServiceType ] = useState(props.match.params.serviceType);
  const [ isVerbalRequest, setIsVerbalRequest ] = useState(false);
  const [ isRequestSubmitted, setIsRequestSubmitted ] = useState(false);



  const startRecording = () => {
    if (isBlocked) {
      setOpenNotification(true);
      setErrorMsg("The Record is Blocked by the Browser!");
    } else {
      setOpenNotification(true);
      setsuccessMsg("The Record has been started!");
      Mp3Recorder.start()
        .then(() => {
          dispatch({ field: "isRecording", value: true });
          console.log('handleView::rec::',true)
        })
        .catch((e) => console.error(e));
    }
  };

  const stopRecording = () => {
    Mp3Recorder.stop()
      .getMp3()
      .then(([buffer, blob]) => {

        const blobURLFinal = URL.createObjectURL(blob);
        dispatch({ field: "blobURL", value: blobURLFinal });

        dispatch({ field: "isRecording", value: false });
        var file = new File([blob], "first", { type: "audio/mp3" });

        dispatch({ field: "fileAudio", value: file });
        dispatch({ field: "recordingField", value: '' });
        console.log('handleView::rec::',false)
      })
      .catch((e) => console.log(e));
  };

  const onChangeNotesValue = (e) => {
    dispatch({
      field: 'comments',
      value: e
    });
  };

  if (openNotification) {
    setTimeout(() => {
      setOpenNotification(false);
      setErrorMsg("");
      setsuccessMsg("");
    }, 4000);
  }

  useEffect(() => {

    axios
      .get(getSingleEdr + "/" + episodeId)
      .then((res) => {
        console.log('handleView::', res.data.data);
        if (res.data.success) {

          let remoteRecord = replacePatientIdIfNull(res.data.data);

          let consultantNotes = remoteRecord.consultationNote.filter(obj => obj._id === consultancyRequestId);
          console.log('consultantNotes', consultantNotes);
          if (consultantNotes.length > 0){
            setConsultancyRequest(consultantNotes[0])
          }
        }
      })
      .catch((e) => {
        console.log("error after searching patient request", e);
      });


    /*
    axios
      .get(`${getSingleConsultationNotesForDoctor}/${currentUser._id}/${episodeId}/${consultancyRequestId}`)
      .then((res) => {
        if (res.data.success) {

          let remoteRecord = replacePatientIdIfNull(res.data.data);
          setSelectedRecord(remoteRecord);
          console.log('remoteRecord::', remoteRecord);

          const object = {
            '_id': remoteRecord._id,
            patientName: remoteRecord.patientId.name[0].given[0] + " " + remoteRecord.patientId.name[0].family,
            mrn: remoteRecord.requestNo,//remoteRecord.patientId.identifier[0].value,
            age: remoteRecord.patientId.age,
            gender: remoteRecord.patientId.gender,
            weight: remoteRecord.patientId.weight,
            department: remoteRecord.currentLocation,
          };
          setPatientDetails(object);

          let pharmaServicesX = remoteRecord.consultationNote
            
          setConsultancyRequest(pharmaServicesX)


          if (pharmaServicesX.consultantNotes!=null || pharmaServicesX.consultantVoiceNotes!=null){
            setIsRequestSubmitted(true)
          }
          // setPharmaServices(pharmaServicesX[0].item);
          

        }
      })
      .catch((e) => {
        console.log("error after searching patient request", e);
      });
    */
  }, []);



  const addSelectedLabItem = () => {
    // PharmaReq 
    dispatch({
      field: 'isFormSubmitted',
      value: true
    });

    if (
      1==1
    ){
      
      // setOpenNotification(true);
      // setsuccessMsg("Adding the Consulta Request");
      
      //SS
      let formData = new FormData();
      formData.append("addedBy", currentUser._id);
      formData.append("edrId", episodeId);
      formData.append("requestId", consultancyRequestId)
      formData.append("consultantNotes", comments);
      if ( fileAudio!=="" && fileAudio instanceof Blob )
      {
        formData.append("file", fileAudio, 'file');
      }

      axios
        .put(updateConsultationNoteForDoctorRequest, formData)//updateConsultationNoteForDoctorRequest
        .then((res) => {
          console.log( 'PharmaReq', res.data );
          if (res.data.success) {
            props.history.push({
              // pathname: "/dashboard/home/"+episodeId+"/consultation",
              pathname: `/dashboard/home/patientmanagement/consultations/${episodeId}/details/${consultancyRequestId}`
            });
          } else if (!res.data.success) {
            setOpenNotification(true);
            setErrorMsg("Error while adding the Parma Request");
          }
        })
        .catch((e) => {
          console.log('PharmaReq', "error after adding Parma Request", e);
          setOpenNotification(true);
          setErrorMsg("An error occurred while adding the Parma Request.");
        });
    }
    // else{
    //   setOpenNotification(true);
    //   setErrorMsg("Error while adding the Parma Request");
    // }
  };

  return (
    
    <div
      style={{
        backgroundColor: "rgb(19 213 159)",
        position: "fixed",
        display: "flex",
        width: "100%",
        height: "100%",
        flexDirection: "column",
        flex: 1,
        overflowY: "scroll",
      }}
    >
      <Header history={props.history} />

      <PatientDetailsQuick
        edrId={episodeId} />

      <div className="cPadding">

        <div className="subheader">
          <div>
            <img src={LabIcon} />
            <h4>{patientDetails.patientName}</h4>
          </div>
        </div>

        <PatientClinicalInformation
          edrId={episodeId}
          wrapClass="mt-3 mb-3" />

        {patientDetails ? (
          <div className="mb-3" style={{ marginLeft: "-15px", marginRight: "-15px" }}>
          </div>
        ) : (
          undefined
        )}
        <h4 className="h6 text-white mb-3"
          style={{
            fontWeight: '700'
          }}>{consultancyRequest?.consultationNo} Item</h4>

        <Paper className="mb-3">
          <Table size="small" stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>
                  <strong>Dr. {consultancyRequest.addedBy?.name[0].given[0]} {consultancyRequest.addedBy?.name[0].family}&nbsp;</strong>
                  {consultancyRequest.status?<span className={classNames(`badge`, `badge-`+ ( consultancyRequest.status.toLowerCase() === "completed"?'success':'danger' ) )}>{consultancyRequest.status.toLowerCase() === "decline"?'Not Available':(consultancyRequest.status.toLowerCase() === "completed"?'Available':'Pending')}</span>:undefined}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  <TextField
                    readonly
                    multiline="true"
                    rows="6"
                    name={"comments"}
                    value={consultancyRequest.notes}
                    className="textInputStyle"
                    variant="filled"
                    InputProps={{
                      disableUnderline: true,
                    }}
                  />
                  <audio
                    style={{
                      marginRight: 10,
                      width: matches ? 450 : 260,
                      marginTop: 6,
                    }}
                    src={`${audioURL}/${consultancyRequest.voiceNotes?.replace('\\', '/')}`}
                    controls="controls,nodownload"
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Paper>

        <Paper className="mb-3 mt-3">
          <Table size="small" stickyHeader>
            <TableBody>
              <TableRow>
                <TableCell>
                  <TextField
                    readonly
                    multiline="true"
                    rows="6"
                    name={"comments"}
                    value={consultancyRequest.consultantNotes}
                    className="textInputStyle"
                    variant="filled"
                    InputProps={{
                      disableUnderline: true,
                    }}
                  />
                  <audio
                    style={{
                      marginRight: 10,
                      width: matches ? 450 : 260,
                      marginTop: 6,
                    }}
                    src={`${audioURL}/${consultancyRequest.consultantVoiceNotes?.replace('\\', '/')}`}
                    controls="controls,nodownload"
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Paper>

        
        <div
          className="container-fluid"
          style={{ marginBottom: "25px", marginTop: "25px" }}
        >
          <div className="row">
            <img
              onClick={() => props.history.goBack()}
              src={Back}
              style={{ width: 45, height: 35, cursor: "pointer" }}
            />
          </div>
        </div>
      </div>


      <Notification
        msg={errorMsg}
        open={openNotification}
        success={successMsg}
      />
    </div>
  );
}