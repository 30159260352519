import React, { useEffect, useState, useReducer, useCallback } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { Grid } from '@material-ui/core'
import cookie from 'react-cookies'
import Header from '../../../components/Header/Header'
import Back from '../../../assets/img/Back_Arrow.png'
import '../../../assets/jss/material-dashboard-react/components/TextInputStyle.css'
import Notification from '../../../components/Snackbar/Notification.js'
import useStylesForInput from '../../../assets/jss/material-dashboard-react/inputStyle'
import LabIcon from '../../../assets/img/RL.png'
import RadIcon from '../../../assets/img/Rad.png'
import ImageViewer from 'react-simple-image-viewer'
import formatDate from '../../../utils/formatDate'
import { styles } from './styles'
import { uploadsUrl, audioURL } from '../../../public/endpoins'

export default function DetailedLabRad(props) {
  const matches = useMediaQuery('(min-width:600px)')
  const classes = useStylesForInput()

  const initialState = {
    mrn: '----',
    patientName: '-- --',
    gender: '--',
    age: '--',
    weight: '--',
    diagnosisArray: [],
    medicationArray: [],
    name: '',
    requestedAt: '',
    blobURL: '',
    image: [],
  }

  function reducer(state, { field, value }) {
    return {
      ...state,
      [field]: value,
    }
  }

  const [state, dispatch] = useReducer(reducer, initialState)

  const {
    mrn,
    patientName,
    gender,
    age,
    weight,
    diagnosisArray,
    medicationArray,
    name,
    requestedAt,
    blobURL,
    image,
  } = state

  const [currentUser] = useState(cookie.load('current_user'))
  const [errorMsg, setErrorMsg] = useState('')
  const [successMsg, setsuccessMsg] = useState('')
  const [openNotification, setOpenNotification] = useState(false)
  const [pathName, setPathName] = useState('')
  const [currentImage, setCurrentImage] = useState(0)
  const [isViewerOpen, setIsViewerOpen] = useState(false)
  const [imagesDisplay, setImagesDisplay] = useState([])

  useEffect(() => {
    let propsData = props.history.location.state

    console.log('Props Passed', propsData)
    setPathName(propsData.path)

    Object.entries(propsData.selectedPatient).map(([key, val]) => {
      if (key === 'patientId') {
        Object.entries(val).map(([key1, val1]) => {
          dispatch({ field: key1, value: val1 })
        })
      } else {
        dispatch({ field: key, value: val })
      }
    })

    Object.entries(propsData.selectedItem).map(([key, val]) => {
      if (key === 'voiceNotes' && val) {
        const url = `${audioURL}/${val}`
        dispatch({ field: 'blobURL', value: url })
      } else {
        dispatch({ field: key, value: val })
      }
    })
  }, [])

  if (openNotification) {
    setTimeout(() => {
      setOpenNotification(false)
      setErrorMsg('')
      setsuccessMsg('')
    }, 2000)
  }

  const openUploadedImageViewer = useCallback((index, val) => {
    let newImage = []

    val.map((item, i) => {
      if (item.includes('\\')) {
        newImage.push(uploadsUrl + item.split('\\')[1])
      } else if (item.includes('/')) {
        newImage.push(uploadsUrl + item)
      }
    })

    setImagesDisplay(newImage)
    setCurrentImage(index)
    setIsViewerOpen(true)
  }, [])

  const closeImageViewer = () => {
    setCurrentImage(0)
    setIsViewerOpen(false)
    setImagesDisplay([])
  }

  return (
    <div
      style={{
        backgroundColor: 'rgb(19 213 159)',
        position: 'fixed',
        display: 'flex',
        width: '100%',
        height: '100%',
        flexDirection: 'column',
        flex: 1,
        overflowY: 'scroll',
      }}
    >
      <Header history={props.history} />
      <div className='cPadding'>
        <div className='subheader'>
          {pathName === 'rad' ? (
            currentUser.staffType === 'Doctor' ? (
              currentUser.subType.includes('ED Doctor') ? (
                <div>
                  <img src={RadIcon} />
                  <h4>Radiology Report</h4>
                </div>
              ) : (
                undefined
              )
            ) : currentUser.staffType === 'Sensei' ? (
              <div>
                <img src={RadIcon} />
                <h4>Radiology Service Reports</h4>
              </div>
            ) : (
              <div>
                <img src={RadIcon} />
                <h4>Radiology Service Request</h4>
              </div>
            )
          ) : pathName === 'lab' ? (
            currentUser.staffType === 'Doctor' ? (
              currentUser.subType.includes('ED Doctor') ? (
                <div>
                  <img src={LabIcon} />
                  <h4>Lab Report</h4>
                </div>
              ) : (
                undefined
              )
            ) : currentUser.staffType === 'Sensei' ? (
              <div>
                <img src={LabIcon} />
                <h4>Lab Service Reports</h4>
              </div>
            ) : (
              <div>
                <img src={LabIcon} />
                <h4>Lab Service Request</h4>
              </div>
            )
          ) : (
            undefined
          )}
        </div>

        <div className='container-fluid'>
          <h5 style={{ fontWeight: 'bold', color: 'white', marginTop: 30 }}>
            Patient Details
          </h5>

          <div
            className='hide'
            style={{
              marginTop: 25,
              backgroundColor: 'white',
              borderRadius: 5,
              width: '100%',
              maxHeight: '300px',
              overflowY: 'scroll',
              overflowX: 'scroll',
            }}
          >
            <div
              className='row'
              style={{
                backgroundColor: '#2C6DDD',
                paddingLeft: 10,
                height: '30%',
                borderTopLeftRadius: 5,
                borderTopRightRadius: 5,
                paddingBottom: 10,
                paddingTop: 10,
                marginLeft: 0,
                marginRight: 0,
                minWidth: 600,
              }}
            >
              <div
                className={'col-md-3 col-sm-3 col-3'}
                style={styles.headerHeading}
              >
                <h6
                  className='pat-det-heading'
                  style={{ color: 'white', fontWeight: '700' }}
                >
                  Patient Info
                </h6>
              </div>
              <div
                className={'col-md-3 col-sm-3 col-3'}
                style={styles.headerHeading}
              >
                <h6
                  className='pat-det-heading'
                  style={{ color: 'white', fontWeight: '700' }}
                >
                  Allergy
                </h6>
              </div>
              <div
                className={'col-md-3 col-sm-3 col-3'}
                style={styles.headerHeading}
              >
                <h6
                  className='pat-det-heading'
                  style={{ color: 'white', fontWeight: '700' }}
                >
                  Medication
                </h6>
              </div>
              <div
                className={'col-md-3 col-sm-3 col-3'}
                style={styles.headerHeading}
              >
                <h6
                  className='pat-det-heading'
                  style={{ color: 'white', fontWeight: '700' }}
                >
                  Diagnosis
                </h6>
              </div>
            </div>

            <div
              className='row hide'
              style={{
                marginTop: 10,
                paddingLeft: 10,
                height: '80%',
                paddingBottom: 10,
                minWidth: 600,
                overflow: 'scroll',
              }}
            >
              <div
                className={'col-md-3 col-sm-3 col-3'}
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <span style={styles.headingStyles}>MRN</span>
                <span style={styles.textStyles} className='mrnUpperCase'>
                  {mrn}
                </span>

                <span style={styles.headingStyles}>Patient</span>
                <span style={styles.textStyles}>{patientName}</span>

                <span style={styles.headingStyles}>Gender</span>
                <span style={styles.textStyles}>{gender}</span>

                <span style={styles.headingStyles}>Age</span>
                <span style={styles.textStyles}>{age}</span>

                <span style={styles.headingStyles}>Weight</span>
                <span style={styles.textStyles}>{weight} kg</span>
              </div>

              <div
                className={'col-md-3 col-sm-3 col-3'}
                style={styles.textStyles}
              >
                {''}
              </div>

              <div
                className={'col-md-3 col-sm-3 col-3'}
                style={styles.textStyles}
              >
                {medicationArray
                  ? medicationArray.map((d, index) => {
                      return (
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                          <h6
                            style={{
                              ...styles.textStyles,
                            }}
                          >
                            {index + 1}
                            {'.'} &nbsp;
                          </h6>
                          <h6
                            style={{
                              ...styles.textStyles,
                            }}
                          >
                            {d}
                          </h6>
                        </div>
                      )
                    })
                  : ''}
              </div>

              <div
                className={'col-md-3 col-sm-3 col-3 '}
                style={{ ...styles.textStyles }}
              >
                {diagnosisArray
                  ? diagnosisArray.map((drug, index) => {
                      return (
                        <h6 style={styles.textStyles}>
                          {index + 1}. {drug}
                        </h6>
                      )
                    })
                  : ''}
              </div>
            </div>
          </div>
        </div>

        <div className='container-fluid'>
          {currentUser.staffType === 'Sensei' ||
          currentUser.staffType === 'Nurses' ? (
            <>
              <hr
                style={{
                  margin: '32px',
                  borderTop: '5px solid #4FDFB7',
                }}
              />

              <div className={`row ${classes.root}`}>
                <div
                  className='col-md-12 col-sm-12 col-12'
                  style={styles.textFieldPadding}
                >
                  <TextField
                    disabled
                    id='name'
                    label='Service Name'
                    name={'name'}
                    value={name}
                    type='text'
                    variant='filled'
                    className='textInputStyle'
                    InputProps={{
                      className: classes.input,
                      classes: { input: classes.input },
                      disableUnderline: true,
                    }}
                  />
                </div>

                <div
                  className='col-md-12 col-sm-12 col-12'
                  style={styles.textFieldPadding}
                >
                  <TextField
                    disabled
                    id='requestedAt'
                    label='Date / Time'
                    name={'requestedAt'}
                    value={formatDate(requestedAt)}
                    type='text'
                    variant='filled'
                    className='textInputStyle'
                    InputProps={{
                      className: classes.input,
                      classes: { input: classes.input },
                      disableUnderline: true,
                    }}
                  />
                </div>
              </div>
            </>
          ) : (
            <div style={{ height: '30px' }} />
          )}

          {image && image.length > 0 ? (
            <div className='row' style={styles.textFieldPadding}>
              <div className='col-md-12 col-sm-12 col-12'>
                <h4 style={{ color: 'white' }}>
                  {pathName === 'rad'
                    ? 'Radiology Reports'
                    : pathName === 'lab'
                    ? 'Lab Reports'
                    : undefined}
                </h4>
              </div>
              <div className='col-md-12 col-sm-12 col-12 imageContainer'>
                {image.map((item, index) => {
                  if (item.includes('\\')) {
                    return (
                      <div key={index}>
                        <img
                          alt=''
                          key={index}
                          onClick={() => openUploadedImageViewer(index, image)}
                          src={uploadsUrl + item.split('\\')[1]}
                          className='reportImage'
                        />
                      </div>
                    )
                  } else if (item.includes('/')) {
                    return (
                      <div key={index}>
                        <img
                          alt=''
                          key={index}
                          onClick={() => openUploadedImageViewer(index, image)}
                          src={uploadsUrl + item}
                          className='reportImage'
                        />
                      </div>
                    )
                  }
                })}
              </div>
            </div>
          ) : (
            undefined
          )}

          {blobURL && blobURL !== '' ? (
            <div className='row' style={styles.textFieldPadding}>
              <div className='col-md-12 col-sm-12 col-12'>
                <h4 style={{ color: 'white' }}>Voice Note</h4>
              </div>
              <div className='col-md-12 col-sm-12 col-12'>
                <Grid
                  style={{
                    backgroundColor: 'white',
                    borderRadius: 5,
                    padding: 10,
                  }}
                >
                  <audio
                    style={{
                      width: '100%',
                      marginTop: 5,
                      outline: 'none',
                    }}
                    src={blobURL}
                    controls='controls'
                  />
                </Grid>
              </div>
            </div>
          ) : (
            undefined
          )}
        </div>

        <div
          className='container-fluid'
          style={{ marginBottom: '25px', marginTop: '25px' }}
        >
          <div className='row'>
            <div className='col-md-12 col-sm-12 col-12'>
              <img
                onClick={() => props.history.goBack()}
                src={Back}
                style={{ width: 45, height: 35, cursor: 'pointer' }}
              />
            </div>
          </div>
        </div>

        {isViewerOpen && (
          <ImageViewer
            src={imagesDisplay}
            currentIndex={currentImage}
            onClose={closeImageViewer}
            backgroundStyle={{
              backgroundColor: 'rgba(0,0,0,0.9)',
            }}
          />
        )}

        <Notification
          msg={errorMsg}
          open={openNotification}
          success={successMsg}
        />
      </div>
    </div>
  )
}
