/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable array-callback-return */
/* eslint-disable react/jsx-indent */
import React, { useEffect, useState, useReducer } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import axios from 'axios'
import {
  getSingleIPRPatient,
} from '../../../public/endpoins'
import cookie from 'react-cookies'
import Header from '../../../components/Header/Header'
import PreApproval from '../../../assets/img/Pre-Approval.png'
import Back from '../../../assets/img/Back_Arrow.png'
import '../../../assets/jss/material-dashboard-react/components/TextInputStyle.css'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import CustomTable from '../../../components/Table/Table'
import Notification from '../../../components/Snackbar/Notification.js'
import Loader from 'react-loader-spinner'
import useMediaQuery from "@material-ui/core/useMediaQuery";

const tableHeadingForNeedApproval = [
  'Request No',
  'Request Type',
  'Item',
  'Total Cost (JD)',
  'Status',
  'Insurance',
  'Action',
]
const tableDataKeysForNeedApproval = [
  'requestId',
  'RequestType',
  'name',
  'totalCost',
  'status',
  'insurance',
]
const actions = { view: true }

const styles = {
  patientDetails: {
    backgroundColor: 'white',
    borderRadius: 5,
    padding: '20px',
  },
  headingStyles: {
    fontWeight: 'bold',
    color: 'grey',
    fontSize: 12,
  },
  textStyles: {
    fontWeight: '700',
    color: 'black',
    fontSize: 14,
  },
}

const useStylesForTabs = makeStyles({
  root: {
    justifyContent: 'center',
  },
  scroller: {
    flexGrow: '0',
  },
})

function ViewPreApproval(props) {
  const classesForTabs = useStylesForTabs()
  const matches = useMediaQuery("(min-width:600px)");

  const initialState = {
    diagnosisArray: [],
    medicationArray: [],
  }

  function reducer(state, { field, value }) {
    return {
      ...state,
      [field]: value,
    }
  }

  const [state, dispatch] = useReducer(reducer, initialState)

  const {
    diagnosisArray,
    medicationArray,
  } = state

  const [, setCurrentUser] = useState('')
  const [errorMsg, setErrorMsg] = useState('')
  const [openNotification, setOpenNotification] = useState(false)
  const [value, setValue] = useState(0)
  const [selectedItem, setSelectedItem] = useState('')
  const [selectedPatient, setSelectedPatient] = useState('')
  const [Id, setId] = useState('')
  const [needApprovalArray, setneedApprovalArray] = useState([
    // {
    //   id: '1',
    //   RequestType: 'Loreum',
    //   serviceName: 'Loreum',
    //   totalCost: 'Loreum',
    //   status: 'pending',
    //   insurance: 'Loreum',
    // }
  ])
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    setCurrentUser(cookie.load('current_user'))

    const selectedRec = props.history.location.state.selectedItem
    console.log('Record', selectedRec)

    setId(selectedRec._id)
    setSelectedPatient(selectedRec.patientId)
    setSelectedItem(selectedRec)

    if (selectedRec) {
      if (selectedRec.residentNotes) {
        let val = selectedRec.residentNotes
        if (val && val.length > 0) {
          dispatch({
            field: 'diagnosisArray',
            value: val.reverse()[0].code,
          })
        }
      }
      if (selectedRec.pharmacyRequest) {
        let data = []
        let val = selectedRec.pharmacyRequest
        val.map((d) => {
          d.item.map((item) => {
            let found = data.find((i) => i === item.itemId.name)
            if (!found) {
              data.push(item.itemId.name)
            }
          })
        })
        dispatch({ field: 'medicationArray', value: data })

        for (let i = 0; i < selectedRec.pharmacyRequest.length; i++) {
          let amount = 0
          let singlePR = selectedRec.pharmacyRequest[i]

          for (let j = 0; j < singlePR.item.length; j++) {
            amount =
              amount +
              singlePR.item[j].itemId.issueUnitCost *
              singlePR.item[j].requestedQty
          }

          selectedRec.pharmacyRequest[i] = {
            ...selectedRec.pharmacyRequest[i],
            totalCost: amount.toFixed(4),
            RequestType: 'PHR',
            name: 'Medical',
            insurance: 'Uncovered',
            requestId: selectedRec.pharmacyRequest[i].pharmacyRequestNo
          }
        }
      }
      if (selectedRec.labRequest) {
        selectedRec.labRequest.map(
          (d) => (
            (d.RequestType = 'LR'),
            (d.totalCost = d.price),
            (d.insurance = 'Uncovered')
          )
        )
      }
      if (selectedRec.radRequest) {
        selectedRec.radRequest.map(
          (d) => (
            (d.RequestType = 'RR'),
            (d.totalCost = d.price),
            (d.insurance = 'Uncovered')
          )
        )
      }
    }
    setneedApprovalArray(
      [].concat(
        selectedRec.labRequest.reverse(),
        selectedRec.radRequest.reverse(),
        selectedRec.pharmacyRequest.reverse()
      )
    )
  }, [])

  const handleChange = (e, newValue) => {
    setValue(newValue)
  }

  function viewNeedApproval(rec) {
    let path = `viewpreapproval/needapproval`
    props.history.push({
      pathname: path,
      state: {
        selectedItem: rec,
      },
    })
  }

  if (openNotification) {
    setTimeout(() => {
      setOpenNotification(false)
      setErrorMsg('')
    }, 2000)
  }

  return (
    <div
      style={{
        backgroundColor: 'rgb(19 213 159)',
        position: 'fixed',
        display: 'flex',
        width: '100%',
        height: '100%',
        flexDirection: 'column',
        flex: 1,
        overflowY: 'scroll',
      }}
    >
      <Header history={props.history} />

      <div className='cPadding'>
        <div className='subheader' style={{ marginLeft: '-6px' }}>
          <div>
            <img src={PreApproval} />
            <h4>Pre-Approval</h4>
          </div>
        </div>

        <div style={{ height: '30px' }} />

        <div className='container-fluid'>
          <h5 style={{ fontWeight: 'bold', color: 'white' }}>
            Patient Details
          </h5>
          <div
            style={{
              marginTop: 25,
              backgroundColor: 'white',
              borderRadius: 5,
              width: '100%',
              maxHeight: '300px'
            }}
          >
            <div className='row'
              style={{
                backgroundColor: '#2C6DDD',
                paddingLeft: 10,
                height: '30%',
                borderTopLeftRadius: 5,
                borderTopRightRadius: 5,
                paddingBottom: 10,
                paddingTop: 10,
                marginLeft: 0,
                marginRight: 0,
                minWidth: 600,
              }}
            >
              <div
                className={'col-md-3 col-sm-3 col-3'}
                style={styles.headerHeading}
              >
                <h6 style={{ color: 'white', fontWeight: '700' }}>
                  Patient Info
                </h6>
              </div>
              <div
                className={'col-md-3 col-sm-3 col-3'}
                style={styles.headerHeading}
              >
                <h6 style={{ color: 'white', fontWeight: '700' }}>Allergy</h6>
              </div>
              <div
                className={'col-md-3 col-sm-3 col-3'}
                style={styles.headerHeading}
              >
                <h6 style={{ color: 'white', fontWeight: '700' }}>
                  Medication
                </h6>
              </div>
              <div
                className={'col-md-3 col-sm-3 col-3'}
                style={styles.headerHeading}
              >
                <h6 style={{ color: 'white', fontWeight: '700' }}>Diagnosis</h6>
              </div>
            </div>

            <div className='row hide'
              style={{
                marginTop: 10,
                paddingLeft: 10,
                height: '80%',
                paddingBottom: 10,
                minWidth: 600,
                overflow: 'scroll',
              }}
            >
              <div
                className={'col-md-3 col-sm-3 col-3'}
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <span style={styles.headingStyles}>MRN</span>
                <span style={styles.textStyles}>
                  {selectedPatient.identifier ?
                    selectedPatient.identifier[0].value
                    : '-----'}
                </span>

                <span style={styles.headingStyles}>Patient</span>
                <span style={styles.textStyles}>
                  {selectedPatient.name ?
                    selectedPatient.name[0].given[0] + ' ' + selectedPatient.name[0].family
                    : '---- ----'}
                </span>

                <span style={styles.headingStyles}>Gender</span>
                <span style={styles.textStyles}>
                  {selectedPatient.gender ?
                    selectedPatient.gender
                    : '----'}
                </span>

                <span style={styles.headingStyles}>Age</span>
                <span style={styles.textStyles}>
                  {selectedPatient.age ?
                    selectedPatient.age
                    : '--'}
                </span>

                <span style={styles.headingStyles}>Weight</span>
                <span style={styles.textStyles}>
                  {selectedPatient.weight ?
                    selectedPatient.weight
                    : '--'} kg
                </span>
              </div>

              <div
                className={'col-md-3 col-sm-3 col-3'}
                style={styles.textStyles}
              >
                {''}
              </div>

              <div
                className={'col-md-3 col-sm-3 col-3'}
                style={styles.textStyles}
              >
                {medicationArray
                  ? medicationArray.map((d, index) => {
                    return (
                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <h6
                          style={{
                            ...styles.textStyles,
                          }}
                        >
                          {index + 1}
                          {'.'} &nbsp;
                          </h6>
                        <h6
                          style={{
                            ...styles.textStyles,
                          }}
                        >
                          {d}
                        </h6>
                      </div>
                    )
                  })
                  : ''}
              </div>

              <div
                className={'col-md-3 col-sm-3 col-3'}
                style={styles.textStyles}
              >
                {diagnosisArray
                  ? diagnosisArray.map((drug, index) => {
                    return (
                      <h6 style={styles.textStyles}>
                        {index + 1}. {drug}
                      </h6>
                    )
                  })
                  : ''}
              </div>
            </div>
          </div>
        </div>

        <div style={{ height: '20px' }} />

        <div className={classesForTabs.root}>
          <Tabs
            classes={{
              root: classesForTabs.root,
              scroller: classesForTabs.scroller,
            }}
            value={value}
            onChange={handleChange}
            textColor='primary'
            TabIndicatorProps={{ style: { background: '#12387a' } }}
            centered={false}
            variant='scrollable'
            fullWidth={true}
          >
            <Tab
              style={{
                color: 'white',
                borderRadius: 5,
                outline: 'none',
                color: value === 0 ? '#12387a' : '#3B988C',
              }}
              label='Need Approval'
            />
          </Tabs>
        </div>

        <div className='container-fluid'>

          {value === 0 ? (
            <div className='row' style={{ marginTop: matches ? 0 : 10 }}>
              {needApprovalArray && needApprovalArray.length > 0 ? (
                <div className='col-md-12 col-sm-12 col-12'>
                  <CustomTable
                    tableData={needApprovalArray}
                    tableDataKeys={tableDataKeysForNeedApproval}
                    tableHeading={tableHeadingForNeedApproval}
                    handleView={viewNeedApproval}
                    action={actions}
                    borderBottomColor={'#60d69f'}
                    borderBottomWidth={20}
                  />
                </div>
              ) : needApprovalArray && needApprovalArray.length === 0 ? (
                <div className='col-md-12 col-sm-12 col-12' style={{ marginTop: '20px' }}>
                  <h3
                    style={{
                      color: 'white',
                      textAlign: 'center'
                    }}
                  >
                    Opps!! No Data Found
                  </h3>
                </div>
              ) : (
                    <div className='LoaderStyle'>
                      <Loader
                        type='TailSpin'
                        color='red'
                        height={50}
                        width={50}
                      />
                    </div>
                  )}
            </div>
          ) : (
              undefined
            )}

          <div className='row' style={{ marginTop: '20px', marginBottom: '25px' }}>
            <div className='col-md-12 col-sm-12 col-12'>
              <img
                onClick={() => props.history.goBack()}
                src={Back}
                style={{ width: 45, height: 35, cursor: 'pointer' }}
              />
            </div>
          </div>
        </div>

        <Notification msg={errorMsg} open={openNotification} />
      </div>
    </div>
  )
}
export default ViewPreApproval