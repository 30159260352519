import React, { useState, useEffect, useReducer } from "react";
import Notification from "../../components/Snackbar/Notification.js";
import CustomTable from "../../components/Table/Table";
import axios from "axios";
import _ from "lodash";
import {
  availableRoomsURL,
  createProductionArea,
  updateProductionArea,
  deleteProductionArea,
  getProductionAreas,
  disableProductionArea,
  enableProductionArea,
  getProductionTeams,
  availableRoomsForAssignmentURL,
  allRoomsForAssignmentURL,
  getProductionArea,
} from "../../public/endpoins";
import Loader from "react-loader-spinner";
import Back from "../../assets/img/Back_Arrow.png";
import Header from "../../components/Header/Header";
import productionAreaIcon from "../../assets/img/Production Area Management.png";
import Input from '@material-ui/core/Input';
import TextField from "@material-ui/core/TextField";
import Select from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles, useTheme } from "@material-ui/core/styles";
import "../../assets/jss/material-dashboard-react/components/loaderStyle.css";
import Button from "@material-ui/core/Button";
import plus_icon from "../../assets/img/Plus.png";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from "@material-ui/core/DialogTitle";
import cookie from "react-cookies";
import EnableDisableModal from "../../components/EnableDisableModal/enableDisableModal";
import "./productionArea.css";
import ButtonField from "../../components/common/Button";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const tableHeading = [
  // "Production Area ID",
  "Production Area",
  "Short Name",
  "Available Rooms",
  "Team",
  // "Date/Time",
  "Action",
];
const tableDataKeys = [/*"paId", */"paName", "nameShort", "availableRooms", "team"/*, "updatedAt"*/];

const ChiefComplaintArray = [
  {
    key: "Chief Complaint 1",
    value: "Chief Complaint 1",
  },
  {
    key: "Chief Complaint 2",
    value: "Chief Complaint 2",
  },
  {
    key: "Chief Complaint 3",
    value: "Chief Complaint 3",
  },
];

const styles = {
  textFieldPadding: {
    paddingLeft: 5,
    paddingRight: 5,
  },
  stylesForButton: {
    color: "white",
    cursor: "pointer",
    borderRadius: 5,
    background: "#2c6ddd",
    width: "140px",
    height: "50px",
    outline: "none",
  },
  stylesForCancelButton: {
    color: "gray",
    cursor: "pointer",
    borderRadius: 5,
    width: "140px",
    height: "50px",
    background: "transparent",
    border: "1px solid gray",
  },
};

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(0),
  },
  input: {
    backgroundColor: "white",
    boxShadow: "none",
    borderRadius: 5,
    "&:after": {
      borderBottomColor: "black",
      boxShadow: "none",
    },
    "&:hover": {
      backgroundColor: "white",
      boxShadow: "none",
    },
    "&:focus": {
      backgroundColor: "white",
      boxShadow: "none",
      borderRadius: 5,
    },
  },
  multilineColor: {
    boxShadow: "none",
    backgroundColor: "white",
    borderRadius: 5,
    "&:hover": {
      backgroundColor: "white",
      boxShadow: "none",
    },
    "&:after": {
      borderBottomColor: "black",
      boxShadow: "none",
    },
    "&:focus": {
      boxShadow: "none",
    },
  },
  root: {
    "& .MuiTextField-root": {
      backgroundColor: "white",
    },
    "& .Mui-focused": {
      backgroundColor: "white",
      color: "black",
      boxShadow: "none",
    },
    "& .Mui-disabled": {
      backgroundColor: "white",
      color: "gray",
    },
    "&:focus": {
      backgroundColor: "white",
      boxShadow: "none",
    },
    "& .MuiFormLabel-root": {
      fontSize: "12px",
      padding: "2px",
    },
  },
}));

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

function findProductionTeamName(mArray, value){
  let item = mArray.find((item) => { return item.id === value; })
  return `${item.name} (${item.shortName})`
}

function findRoomName(mArray, value){
  let item = mArray.find((item) => { return item.id === value; })
  return `${item.name}`
}

const actions = { enableDisable: false, edit: true, delete: true };

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function ProductionArea(props) {
  const matches = useMediaQuery("(min-width:600px)");
  const theme = useTheme();
  const initialState = {
    productionName: "",
    productionShortName: "",
    productionTeamsId: [],
    productionbedCellsId: [],



    prodAreaName: "",
    selectedRooms: [],
    reason: "",
  };

  function reducer(state, { field, value }) {
    return {
      ...state,
      [field]: value,
    };
  }

  const [state, dispatch] = useReducer(reducer, initialState);

  const { prodAreaName, selectedRooms, reason, productionName, productionShortName, productionTeamsId, productionbedCellsId } = state;

  const onChangeValue = (e) => {
    dispatch({ field: e.target.name, value: e.target.value });
  };

  const classes = useStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
      maxWidth: 300,
    },
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    chip: {
      margin: 2,
    },
    noLabel: {
      marginTop: theme.spacing(3),
    },
  }));
  const [currentUser, setcurrentUser] = useState("");
  const [areaData, setAreaData] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setsuccessMsg] = useState("");
  const [openNotification, setOpenNotification] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [availableRooms, setAvailableRooms] = useState([]);
  const [openEnableDisabledModal, setOpenEnableDisabledModal] = useState(false);
  const [area, setArea] = useState("");

  // Models
  const [editId, setEditId] = useState("")
  const [productionTeamModelVisible, setProductionTeamModelVisible] = useState(false);
  const [productionTeams, setProductionTeams] = React.useState([]);
  const [productionTeamsList, setProductionTeamsList] = React.useState([]);
  const [bedCells, setBedCells] = React.useState([]);
  const [availableRoomsList, setAvailableRoomsList] = React.useState([]);
  const [allRoomsList, setAllRoomsList] = React.useState([]);
  const [productionEditModelVisible, setProductionEditModelVisible] = useState(false);


  if (openNotification) {
    setTimeout(() => {
      setOpenNotification(false);
      setErrorMsg("");
      setsuccessMsg("");
    }, 2000);
  }

  useEffect(() => {
    setcurrentUser(cookie.load("current_user"));
    getProductionAreaData();
    getProductionTeamsList();
    getAvailableRoomData();
  }, []);

  useEffect(()=>{

  },[modalVisible])

  function getAvailableRoomData() {
    axios
      .get(availableRoomsForAssignmentURL)
      .then((res) => {
        if (res.data.success) {
          let bedOptions = [];
          Object.entries(res.data.items).map(([index, value]) => {
            bedOptions.push({"id": value._id, "name": "Room #" + value.roomNo});
          })
          setAvailableRoomsList(bedOptions);
        } 
        return res;
      })
      .catch((e) => {
        console.log("error: ", e);
      });

    ///
    axios
      .get(allRoomsForAssignmentURL)
      .then((res) => {
        if (res.data.success) {
          let bedOptions = [];
          Object.entries(res.data.items).map(([index, value]) => {
            bedOptions.push({"id": value._id, "name": "Room #" + value.roomNo});
          })
          console.log("Add Production Area::", bedOptions)
          setAllRoomsList(bedOptions);
        } 
        return res;
      })
      .catch((e) => {
        console.log("error: ", e);
      });
  }

  function getProductionAreaData() {
    axios
      .get(getProductionAreas)
      .then((res) => {
        if (res.data.success) {
          console.log(res.data.data, "get Area Data");
          res.data.data.map(
            (d) => (
              (d.availableRooms = d.rooms.map((val, index) => {
                let x = "";
                if (index !== d.rooms.length - 1) {
                  x = ", ";
                }
                return "Room " + val.roomId.roomNo + x;
              })),
              (d.nameShort = d.paShortName),
              // (d.team = 'N/A')
              (d.team = d.productionTeams.map((val, index) => {
                let x = "";
                if (index !== d.productionTeams.length - 1) {
                  x = ", ";
                }
                return val.nameShort + x;
              }))
            )



            /*(d) => {
              (d.availableRooms = d.rooms.map((val, index) => {
                let x = "";
                if (index !== d.rooms.length - 1) {
                  x = ", ";
                }
                return "Room " + val.roomId.roomNo + x;
              })),
              (d.team = 'N/A')
            },*/
          );
          setAreaData(res.data.data);//.reverse()
        } else if (!res.data.success) {
          setErrorMsg(res.data.error);
          setOpenNotification(true);
        }
        return res;
      })
      .catch((e) => {
        console.log("error: ", e);
      });
  }

  function getProductionTeamsList() {
    axios
      .get(getProductionTeams)
      .then((res) => {
        if (res.data.success) {
          let productionTeamsOptions = [];
          Object.entries(res.data.items).map(([index, value]) => {
            productionTeamsOptions.push({"id": value._id, "name": value.name, "shortName": value.nameShort});
          })
          // console.log('productionTeamsOptions', productionTeamsOptions);
          setProductionTeamsList(productionTeamsOptions);
        }
        return res;
      })
      .catch((e) => {
        console.log("error: ", e);
      });
  }

  function enableDisableRecord(area) {
    setOpenEnableDisabledModal(true);
    setArea(area);
  }

  const handleAdd = () => {
    setProductionTeamModelVisible(true);
  }

  const handleEdit = (rec) => {
    console.log("Add Production Area::", rec._id);
    axios
      .get(getProductionArea + rec._id)
      .then((res) => {
        console.log("Add Production Area::", res);
        if (res.data && res.data.data) {
          setEditId(res.data.data._id);
          setProductionEditModelVisible(true);
          dispatch({ field: "productionName",       value: res.data.data.paName });
          dispatch({ field: "productionShortName",  value: res.data.data.paShortName });


          let fieldProductionTeam = [];
          Object.entries(res.data.data.productionTeams).map(([i,v])=>{
            fieldProductionTeam.push(v._id)
          });

          dispatch({ field: "productionTeamsId",  value: fieldProductionTeam });
          setProductionTeams(fieldProductionTeam);

          let fieldProductionBeds = [];
          Object.entries(res.data.data.rooms).map(([i,v])=>{
            fieldProductionBeds.push(v.roomId._id)
          });

          dispatch({ field: "productionbedCellsId",  value: fieldProductionBeds });
          setBedCells(fieldProductionBeds);
        }else{
          setOpenNotification(true);
          setErrorMsg("Error while editing the area");
        }
        return res;
      })
      .catch((e) => {
        setOpenNotification(true);
        setErrorMsg("Error while editing the area");
      });

    // setProductionTeamModelVisible(true);
  }

  const handleDelete = (rec) => {
    if ( window.confirm(`Are you sure to delete '${rec.paName}'?`) ){
      axios
        .delete(`${deleteProductionArea}/${rec._id}`)
        .then((res) => {
          if (res.data.success) {
            setOpenNotification(true);
            setsuccessMsg("Area Deleted Successfully");
            window.location.reload();
          } 
          return res;
        })
        .catch((e) => {
          console.log("error: ", e);
        });
    }
  }

  const handleProductionTeamModelClose = () => {
    setProductionTeamModelVisible(false);
    dispatch({field: 'productionName', value: ''});
    dispatch({field: 'productionShortName', value: ''});
    dispatch({field: 'productionTeamsId', value: ''});
    dispatch({field: 'productionbedCellsId', value: ''});
    setBedCells([]);
  }

  const handleProductionEditTeamModelClose = () => {
    setProductionEditModelVisible(false);
    dispatch({field: 'productionName', value: ''});
    dispatch({field: 'productionShortName', value: ''});
    dispatch({field: 'productionTeamsId', value: ''});
    dispatch({field: 'productionbedCellsId', value: ''});
    setBedCells([]);
  }

  const addNewArea = () => {
    setModalVisible(true);
  };

  const handleAddProductionArea = () => {
    let params = {
      paName: productionName,
      paShortName: productionShortName,
      productionTeams: productionTeamsId,
      rooms: productionbedCellsId,
    };
    console.log("Add Production Area", params);

    if (productionName && productionShortName && params.rooms.length > 0 && params.paName.length > 0) {
      axios
      .post(createProductionArea, params)
      .then((res) => {
        if (res.data && res.data.success) {
          setModalVisible(false);
          setOpenNotification(true);
          setsuccessMsg("Area Added Successfully");
          getProductionAreaData();
          window.location.reload();


          dispatch({ field: "selectedRooms", value: [] });
          dispatch({ field: "prodAreaName", value: "" });
        }
        console.log("Add Production Area:: -> error", res.success);
        if (res.hasOwnProperty("success") && res.success === false) {
          setModalVisible(false);
          setOpenNotification(true);
          setErrorMsg(res.error);
          console.log("Add Production Area:: -> error", res.error);
        }
        return res;
      })
      .catch((e) => {
        console.log("Add Production Area", e);
        setOpenNotification(true);
        setErrorMsg("Error while adding the area");
      });

    } else {
      setOpenNotification(true);
      setErrorMsg("Please fill up the form correctly.");
    }
  };

  const handleUpdateProductionArea = () => {
    let params = {
      editId: editId,
      paName: productionName,
      paShortName: productionShortName,
      productionTeams: productionTeamsId,
      rooms: productionbedCellsId,
    };
    console.log("Add Production Area::", params);
    if (productionName && productionShortName && params.rooms.length > 0 && params.paName.length > 0) {
      axios
      .put(updateProductionArea, params)
      .then((res) => {
        console.log('Add Production Area::', res);
        if (res.data && res.data.success) {
          
          handleProductionEditTeamModelClose();

          setOpenNotification(true);
          setsuccessMsg("Area Added Successfully");
          window.location.reload();
        }
        console.log("Add Production Area:: -> error", res.success);
        if (res.hasOwnProperty("success") && res.success === false) {
          setModalVisible(false);
          setOpenNotification(true);
          setErrorMsg(res.error);
          console.log("Add Production Area:: -> error", res.error);
        }
        return res;
      })
      .catch((e) => {
        setOpenNotification(true);
        setErrorMsg("Error while updating the area");
      });

    } else {
      setOpenNotification(true);
      setErrorMsg("Please fill up the form correctly.");
    }
  }

  const handleAddArea = () => {
    let params = {
      paName: prodAreaName,
      rooms: selectedRooms,
    };
    if (params.rooms.length > 0 && params.paName.length > 0) {
      axios
        .post(createProductionArea, params)
        .then((res) => {
          if (res.data.success) {
            setModalVisible(false);
            setOpenNotification(true);
            setsuccessMsg("Area Added Successfully");
            getProductionAreaData();
            window.location.reload();
          } else if (!res.data.success) {
            setModalVisible(false);
            setOpenNotification(true);
            setErrorMsg(res.data.msg);
          }
        })
        .catch((e) => {
          console.log("error after adding area", e);
          setOpenNotification(true);
          setErrorMsg("Error while adding the area");
        });

        dispatch({ field: "selectedRooms", value: [] });
        dispatch({ field: "prodAreaName", value: "" });

      } else {
      setOpenNotification(true);
      setErrorMsg("Please select Available Rooms");
    }
  };

  const onAvailableRoomsCheck = (e, value) => {
    var tempRooms = [...selectedRooms];

    var roomFound = false;
    for (let i = 0; i < tempRooms.length; i++) {
      if (tempRooms[i].roomId === value._id) {
        tempRooms.splice(i, 1);
        dispatch({ field: "selectedRooms", value: tempRooms });
        roomFound = true;
      }
    }
    if (!roomFound) {
      tempRooms = [...selectedRooms, { roomId: value._id }];
      dispatch({ field: "selectedRooms", value: tempRooms });
    }
  };

  const handleDisableArea = () => {
    const params = {
      staffId: currentUser._id,
      reason: reason,
    };
    let url = !area.disabled ? disableProductionArea : enableProductionArea;
    axios
      .put(url + "/" + area._id, params)
      .then((res) => {
        if (res.data.success) {
          setOpenNotification(true);
          setsuccessMsg(
            `Area ${!area.disabled ? "disabled" : "enabled"} successfully`
          );
          getProductionAreaData();
        } else if (!res.data.success) {
          setOpenNotification(true);
          setErrorMsg(res.data.data);
        }
      })
      .catch((e) => {
        console.log("error after updating area", e);
        setOpenNotification(true);
        setErrorMsg("Error while editing the area");
      });
    setOpenEnableDisabledModal(false);
  };

  const onModalHide = () => {
    dispatch({ field: "prodAreaName", value: "" });
    dispatch({ field: "selectedRooms", value: "" });
    setModalVisible(false);
  };

  const handleProductionTeamChange = (event) => {
    console.log("productionTeamsOptions::", event.target);
    setProductionTeams(event.target.value);
    dispatch({ field: "productionTeamsId", value: event.target.value });
  };

  const handleBedCellChange = (event) => {
    console.log("bedCellOptions::", event.target);
    dispatch({ field: "productionbedCellsId", value: event.target.value });
    setBedCells(event.target.value);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        position: "fixed",
        width: "100%",
        height: "100%",
        backgroundColor: "rgb(19 213 159)",
        overflowY: "scroll",
      }}
    >
      <Header history={props.history} />

      <div className="cPadding">
        <div className="subheader">
          <div>
            <img src={productionAreaIcon} />
            <h4>Production Area Management</h4>
          </div>
          <ButtonField onClick={handleAdd} name="add" />
        </div>

        <div
          style={{
            flex: 4,
            display: "flex",
            flexDirection: "column",
          }}
        >
          {areaData && areaData.length > 0 ? (
            <div>
              <div>
                <CustomTable
                  tableData={areaData}
                  tableDataKeys={tableDataKeys}
                  tableHeading={tableHeading}
                  action={actions}
                  handleEdit={handleEdit}
                  handleDelete={handleDelete}
                  /*enableDisableRecord={enableDisableRecord}*/
                  borderBottomColor={"#60d69f"}
                  borderBottomWidth={20}
                />
              </div>
              <div style={{ marginTop: 20, marginBottom: 20 }}>
                <img
                  onClick={() => props.history.goBack()}
                  src={Back}
                  style={{
                    width: 45,
                    height: 35,
                    cursor: "pointer",
                  }}
                />
              </div>
            </div>
          ) : areaData && areaData.length == 0 ? (
            <div className="row " style={{ marginTop: "25px" }}>
              <div className="col-md-12 col-sm-12 col-12">
                <h3
                  style={{
                    color: "white",
                    textAlign: "center",
                    width: "100%",
                    position: "absolute",
                  }}
                >
                  Oops! No Data Found
                </h3>
              </div>
              <div
                className="col-md-12 col-sm-12 col-12"
                style={{ marginTop: 45 }}
              >
                <img
                  onClick={() => props.history.goBack()}
                  src={Back}
                  style={{ maxWidth: "60%", height: "auto", cursor: "pointer" }}
                />
              </div>
            </div>
          ) : (
            <>
              <div className="row">
                <div className="LoaderStyle">
                  <Loader type="TailSpin" color="red" height={50} width={50} />
                </div>
              </div>
              <div className="row">
                <div
                  className="col-md-12 col-sm-12 col-12"
                  style={{ marginTop: 45 }}
                >
                  <img
                    onClick={() => props.history.goBack()}
                    src={Back}
                    style={{
                      maxWidth: "60%",
                      height: "auto",
                      cursor: "pointer",
                    }}
                  />
                </div>
              </div>
            </>
          )}
        </div>

      <Dialog open={productionTeamModelVisible} onClose={handleProductionTeamModelClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Add Production Area</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please fill up the following data.
          </DialogContentText>
          <div className="row">
            <div className="col-md-7">
              <TextField
                autoFocus
                required
                margin="dense"
                name="productionName"
                onChange={onChangeValue}
                label="Full Name"
                type="text"
                variant="outlined"
                fullWidth
              />
            </div>
            <div className="col-md-5">
              <TextField
                required
                margin="dense"
                name="productionShortName"
                onChange={onChangeValue}
                label="Short Name"
                type="text"
                variant="outlined"
                fullWidth
              />
            </div>
          </div>
          <FormControl className={classes.formControl} fullWidth style={{
            marginTop: 15
          }}>
            <InputLabel id="demo-mutiple-chip-label">Production Teams</InputLabel>
            <Select
              labelId="demo-mutiple-chip-label"
              id="demo-mutiple-chip"
              multiple
              fullWidth
              variant="outlined"
              value={productionTeams}
              onChange={handleProductionTeamChange}
              input={<Input id="select-multiple-chip"/>}
              label="Production Area (Short Name)"
              renderValue={(selected) => (
                <div className={classes.chips}>
                  {selected.map((value) => (
                    <Chip key={value} label={(findProductionTeamName(productionTeamsList, value))} className={classes.chip} />
                  ))}
                </div>
              )}
              MenuProps={MenuProps}
            >
              {productionTeamsList.map((field) => (
                <MenuItem key={field.id} value={field.id} style={getStyles(field, productionTeams, theme)}>
                  {field.name} ({field.shortName})
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl className={classes.formControl} fullWidth style={{
            marginTop: 15
          }}>
            <InputLabel id="bedsProductionsAdd">Beds/Cells</InputLabel>
            <Select
              labelId="bedsProductionsAdd"
              id="demo-mutiple-chip"
              multiple
              fullWidth
              variant="outlined"
              value={bedCells}
              onChange={handleBedCellChange}
              input={<Input id="select-multiple-chip"/>}
              label="Beds/Cells"
              renderValue={(selected) => (
                <div className={classes.chips}>
                  {selected.map((value) => (
                    <Chip key={value} label={(findRoomName(availableRoomsList, value))} className={classes.chip} />
                  ))}
                </div>
              )}
              MenuProps={MenuProps}
            >
              {
                availableRoomsList.length > 0 ? (
                  availableRoomsList.map((field) => (
                    <MenuItem key={field.id} value={field.id} style={getStyles(field, bedCells, theme)}>{field.name}</MenuItem>
                  ))
                  ) : <MenuItem key="nd" value="" disabled>No Beds/Cells are available.</MenuItem>
              }
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleProductionTeamModelClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleAddProductionArea} color="primary">
            Add Production Area
          </Button>
        </DialogActions>
      </Dialog>

      {productionEditModelVisible ?
        <Dialog open={productionEditModelVisible} onClose={handleProductionEditTeamModelClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Edit Production Area</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Please fill up the following data.
            </DialogContentText>
            <div className="row">
              <div className="col-md-7">
                <TextField
                  autoFocus
                  required
                  margin="dense"
                  name="productionName"
                  value={productionName}
                  onChange={onChangeValue}
                  label="Full Name"
                  type="text"
                  variant="outlined"
                  fullWidth
                />
              </div>
              <div className="col-md-5">
                <TextField
                  required
                  margin="dense"
                  name="productionShortName"
                  value={productionShortName}
                  onChange={onChangeValue}
                  label="Short Name"
                  type="text"
                  variant="outlined"
                  fullWidth
                />
              </div>
            </div>

            <FormControl className={classes.formControl} fullWidth style={{
              marginTop: 15
            }}>
              <InputLabel id="editProductionName">Production Teams</InputLabel>
              <Select
                labelId="editProductionName"
                id="demo-mutiple-chip"
                multiple
                fullWidth
                variant="outlined"
                value={productionTeams}
                onChange={handleProductionTeamChange}
                input={<Input id="select-multiple-chip"/>}
                label="Production Area (Short Name)"
                renderValue={(selected) => (
                  <div className={classes.chips}>
                    {selected.map((value) => (
                      <Chip key={value} label={(findProductionTeamName(productionTeamsList, value))} className={classes.chip} />
                    ))}
                  </div>
                )}
                MenuProps={MenuProps}
              >
                {productionTeamsList.map((field) => (
                  <MenuItem key={field.id} value={field.id} style={getStyles(field, productionTeams, theme)}>
                    {field.name} ({field.shortName})
                  </MenuItem>
                ))}
              </Select>
            </FormControl>


            <FormControl className={classes.formControl} fullWidth style={{
              marginTop: 15
            }}>
              <InputLabel id="bedsProductionsEdit">Beds/Cells</InputLabel>
              <Select
                labelId="bedsProductionsEdit"
                id="demo-mutiple-chip"
                multiple
                fullWidth
                variant="outlined"
                value={bedCells}
                onChange={handleBedCellChange}
                input={<Input id="select-multiple-chip"/>}
                label="Beds/Cells"
                renderValue={(selected) => (
                  <div className={classes.chips}>
                    {selected.map((value) => (
                      <Chip key={value} label={(findRoomName(allRoomsList, value))} className={classes.chip} />
                    ))}
                  </div>
                )}
                MenuProps={MenuProps}
              >
                {
                  allRoomsList.length > 0 ? (
                    allRoomsList.map((field) => (
                      <MenuItem key={field.id} value={field.id} style={getStyles(field, bedCells, theme)}>{field.name}</MenuItem>
                    ))
                    ) : <MenuItem key="nd" value="" disabled>No Beds/Cells are available.</MenuItem>
                }
              </Select>
            </FormControl>

          </DialogContent>
          <DialogActions>
            <Button onClick={handleProductionEditTeamModelClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleUpdateProductionArea} color="primary">
              Add Production Area
            </Button>
          </DialogActions>
        </Dialog>
      :undefined}

      {modalVisible ?
        <Dialog
          onClose={() => setModalVisible(false)}
          fullWidth={true}
          maxWidth={"lg"}
          bodyStyle={{ backgroundColor: "red" }}
          contentStyle={{ backgroundColor: "red" }}
          aria-labelledby="simple-dialog-title"
          open={modalVisible}
        >
          <DialogContent>
            <div className="container-fluid">
              <div className="row">
                <div className="d-flex justify-content-start">
                  <DialogTitle
                    id="simple-dialog-title"
                    style={{ marginLeft: matches ? "-12px" : -28 }}
                  >
                    <h4 style={{ fontSize: matches ? " " : 20 }}>
                      Add New Production Area
                    </h4>
                  </DialogTitle>
                </div>
              </div>

              <div className={`row ${classes.root}`}>
                <div className="col-md-12" style={styles.textFieldPadding}>
                  <TextField
                    label="Production Area Name"
                    name={"prodAreaName"}
                    value={prodAreaName}
                    onChange={onChangeValue}
                    className="textInputStyle"
                    variant="filled"
                    InputProps={{
                      className: classes.input,
                      classes: { input: classes.input },
                      disableUnderline: true,
                    }}
                  />
                </div>
              </div>

              <div className={`row ${classes.root}`}>
                <div className="col-md-12" style={styles.textFieldPadding}>
                  <span
                    style={{
                      paddingLeft: "15px",
                      color: "gray",
                    }}
                  >
                    Available Rooms
                  </span>
                </div>
              </div>

              <div className={`row ${classes.root}`}>
                {availableRooms.length > 0 ? (
                  availableRooms.map((value, index) => {
                    return (
                      <div
                        className="form-group col-md-4 col-sm-4 col-4"
                        style={{ fontSize: matches ? " " : 10 }}
                      >
                        <div className="radio">
                          <label className="radio-inline control-label">
                            <input
                              type="checkbox"
                              name="availableRooms"
                              value={value.roomId}
                              onChange={(e) => onAvailableRoomsCheck(e, value)}
                              // checked={generalAppearance === "Good"}
                            />
                            &nbsp;&nbsp;Room No. {value.roomNo}
                          </label>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div
                    className="col-md-4 col-sm-4 col-4"
                    style={styles.textFieldPadding}
                  >
                    <div className="radio">No Available Rooms :(</div>
                  </div>
                )}
              </div>

              <div style={{ display: "flex", justifyContent: "center" }}>
                <div
                  style={{
                    display: "flex",
                    marginTop: matches ? "2%" : "10%",
                    marginBottom: "2%",
                  }}
                >
                  <Button
                    style={{
                      ...styles.stylesForCancelButton,
                      width: matches ? 140 : 100,
                      height: matches ? 50 : 40,
                    }}
                    onClick={() => onModalHide()}
                    variant="contained"
                  >
                    Cancel
                  </Button>
                  &nbsp;&nbsp;
                  <Button
                    style={{
                      ...styles.stylesForButton,
                      width: matches ? 140 : 100,
                      height: matches ? 50 : 40,
                    }}
                    onClick={handleAddArea}
                    variant="contained"
                  >
                    Submit
                  </Button>
                </div>
              </div>
            </div>
          </DialogContent>
        </Dialog>
        :undefined}

        {openEnableDisabledModal && area ? (
          <EnableDisableModal
            handleClose={setOpenEnableDisabledModal}
            handleConfirmDisable={handleDisableArea}
            disabled={area.disabled}
          />
        ) : (
          undefined
        )}

        <Notification
          msg={errorMsg}
          success={successMsg}
          open={openNotification}
        />
      </div>
    </div>
  );
}
