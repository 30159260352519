import React, { useEffect, useState, useReducer } from "react";
import { withStyles } from '@material-ui/core/styles';
import Header from "../../components/Header/Header";
import classNames from "classnames";
import { 
  InputLabel,
  FormHelperText,
  FormControl,
  Button,
  Dialog,
  DialogContent,
  DialogTitle, 
  DialogActions,
  DialogContentText,
  Grid,
  InputAdornment,
  MenuItem,
  TextField,
  Select,
  Checkbox,
  FormControlLabel,
  useMediaQuery,
} from "@material-ui/core";
import cookie from "react-cookies";

import axios from "axios";
import {
  audioURL,
  getLabServiceCategory,
  addLabRequestBulk,
  getRadServiceCategory,

  getCCTransferTaskInfo,
  updateCCTransferTaskInfo
} from "../../public/endpoins";
import Back from '../../assets/img/Back_Arrow.png'

import useStylesForInput from "../../assets/jss/material-dashboard-react/inputStyle";
import { styles } from "./styles";
import Loader from "react-loader-spinner";
import Icon from '@material-ui/core/Icon';
import CloseIcon from '@material-ui/icons/Close';
import MicIcon from "@material-ui/icons/Mic";
import StopIcon from "@material-ui/icons/Stop";
import MicRecorder from "mic-recorder-to-mp3";
import AccountCircle from "@material-ui/icons/SearchOutlined";
import Notification from "../../components/Snackbar/Notification.js";

import LabIcon from "../../assets/img/Lab.png";
import { displayInHumanDifference, replacePatientIdIfNull } from "../../utils/commonFunc";
import PatientDetailsQuick from "../../components/PatientDetails/PatientDetailsQuick";
import PatientDetails from "../../components/PatientDetails/PatientDetails";
import PatientDetails2 from "../../components/PatientDetails/PatientDetailsDirect";

export default function ServiceDetail(props) {
  
  const matches = useMediaQuery("(min-width:600px)");
  const classes = useStylesForInput();

  const mystyle = {
    color: "white",
    backgroundColor: "DodgerBlue",
    padding: "10px",
    fontFamily: "Arial"
  };



  const GreenCheckbox = withStyles({
    root: {
      color: '#AAAAAA',
      '&$checked': {
        color: '#AAAAAA',
      },
    },
    checked: {
      color: '#2C6DDD',
      '&$checked': {
        color: '#2C6DDD',
      },
    },
  })((props) => <Checkbox color="default" {...props} />);

  


  const initialState = {
    bulkTestSelected: false,
    seletedRadItems: [],
    resetedOn: "",
    defaultItems: {},
    seletedLabItems: [],
    labPriority: "",
    labTechnician: "",
    labComments: "",
    labTechniciansList: [],
  };
  function reducer(state, { field, value }) {
    return {
      ...state,
      [field]: value,
    };
  }
  const [state, dispatch] = useReducer(reducer, initialState);
  const {
    bulkTestSelected,
    seletedRadItems,
    resetedOn,
    defaultItems,
    seletedLabItems,
    labPriority,
    labTechnician,
    labComments,
    labTechniciansList,
  } = state;

  const handleCheckupChange = (event) => {
    dispatch({field: 'bulkTestSelected', value: event.target.value});
  };

  const uniqueCodeField = (name) => {
    name = name.toLowerCase();
    name = name.replace(/[^a-z0-9]+/g, '-');
    name = name.replace(/^-+|-+$/g, '');
    return name;
  }



  const [arrivedOpen , setArrivedOpen] = React.useState(false);
  const [completeOpen, setCompleteOpen] = React.useState(false);

  const [currentUser] = useState(cookie.load("current_user"));
  const [timer, setTimer] = useState(null);
  const [selectedLab, setSelectedLab] = useState("");

  const [patientDetails, setPatientDetails] = useState("");
  const [selectedRecord, setSelectedRecord] = useState({});

  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setsuccessMsg] = useState("");
  const [openNotification, setOpenNotification] = useState(false);

  const [ episodeId, setEpisodeId ] = useState("");
  const [ taskId, setTaskId ] = useState(props.match.params.taskId);
  const [ status, setStatus ] = useState("");
  const [ taskRequest, setTaskRequest ] = useState("");

  
  if (openNotification) {
    setTimeout(() => {
      setOpenNotification(false);
      setErrorMsg("");
      setsuccessMsg("");
    }, 4000);
  }

  useEffect(() => {
    axios
      .get(`${getCCTransferTaskInfo}/${taskId}`)
      .then((res) => {
        console.log('remoteRecord::', res.data.data);
        if (res.data.success) {

          let mData = res.data.data;
          mData.patientId = mData.edrId.patientId;

          let remoteRecord = replacePatientIdIfNull(mData);
          console.log('remoteRecord::remoteRecord::', remoteRecord);
          const object = {
            '_id': remoteRecord._id,
            patientName: remoteRecord.patientId.name[0].given[0] + " " + remoteRecord.patientId.name[0].family,
            episodeNumber: remoteRecord.edrId.requestNo,//remoteRecord.patientId.identifier[0].value,
            mrn: remoteRecord.patientId.identifier[0].value,
            age: remoteRecord.patientId.age,
            gender: remoteRecord.patientId.gender,
            weight: remoteRecord.patientId.weight,
            department: remoteRecord.edrId.currentLocation,
            chiefComplaint: remoteRecord.edrId.chiefComplaint.length > 0 ? remoteRecord.edrId.chiefComplaint.pop().chiefComplaintId.short_desc : "N/A",
            chiefComplaintOthers: remoteRecord.edrId.chiefComplaintOthers,
          };
          setPatientDetails(object);

          setTaskRequest(mData);
          setStatus(mData.status);

        }
      })
      .catch((e) => {
        console.log("error after searching patient request", e);
      });
    /*getLabTechniciansCall( currentUser._id, dispatch, setOpenNotification, setErrorMsg );*/
  }, []);

  const handleChange = (event) => {
    setStatus(event.target.value);
  };

  const handleSave = (statusMode) => {
    axios
      .post(`${updateCCTransferTaskInfo}/${taskId}`, {
        status: statusMode,
      })
      .then((res) => {
        if (res.data.success) {
          setOpenNotification(true);
          setsuccessMsg(`The status has been changed to ${statusMode}!`);
          window.location.reload();
        }else if (res.data.success === false) {
          if ( statusMode === "arrived" ){
            setArrivedOpen(false);
          }else if ( statusMode === "completed" ){
            setCompleteOpen(false);
          }
          setOpenNotification(true);
          setErrorMsg(`There might be some problem while saving the record.`);
        }
      })
      .catch((e) => {
        setOpenNotification(true);
        setErrorMsg("Unable to Update the Record");
      });
  }

  // const handleClickOpen = () => {
  //   setOpen(true);
  // };

  const handleCompleteClose = () => {
    setCompleteOpen(false);
  };

  const handleArrivedClose = () => {
    setArrivedOpen(false);
  };

  return (
    
    <div
      style={{
        backgroundColor: "rgb(19 213 159)",
        position: "fixed",
        display: "flex",
        width: "100%",
        height: "100%",
        flexDirection: "column",
        flex: 1,
        overflowY: "scroll",
      }}
    >
      <Header history={props.history} />
      {
        taskRequest ? 
        <PatientDetailsQuick
          edrId={taskRequest.edrId._id} />
        :undefined
      }

      <div className="cPadding">

        {patientDetails && taskRequest ? (
          <div className="mb-3">
            <div className="card pt-3 pl-3 pr-3">
              <div className="row row-5">
                <div className="col-6 col-lg-3 card-detail-block block-xdiv mb-3"><strong className="lblX">Episode #</strong><span className="valX">{patientDetails.episodeNumber}</span></div>
                <div className="col-6 col-lg-3 card-detail-block block-xdiv mb-3"><strong className="lblX">MRN #</strong><span className="valX">{patientDetails.mrn}</span></div>
              </div>
            </div>
          </div>
        ) : (
          undefined
        )}

        <h4 className="h6 text-white mb-3"
          style={{
            fontWeight: '700'
          }}>Task Requests - {status}</h4>
        <div className="row row-5">
          {
            taskRequest ? 
              <div className="col-12">
                <div className="card card-detail-info mb-2">
                  <div className="row row-5">
                    <div className="col-sm-12 card-detail-block block-xdiv mb-2"><strong className="lblX">Task</strong><span className="valX">{taskRequest.to==="RD"?"Take to Radiology Department":"Take to Emergency Department"}</span></div>
                  </div>
                  <div className="card-status-action">
                    <span className={classNames(`badge`, ((taskRequest.status=="completed"||taskRequest.status=="arrived")?'badge-success':'status-pending'))}>{taskRequest.status}</span>
                  </div>
                </div>
              </div>
              :undefined
          }
          {
            taskRequest ? 
              <>
              <div className="col-12 col-md-6 mt-3 mt-lg-3 text-center">
                <Button
                  style={{
                    backgroundColor: "#AD6BBF",
                    color: "white",
                    cursor: "pointer",
                    borderRadius: 5,
                    backgroundColor: "rgb(173, 107, 191)",
                    height: matches ? 56 : 45,
                    outline: "none",
                    width: "100%",
                  }}
                  onClick={() => {
                    if (status==="pending"){
                      setArrivedOpen(true);
                    }else if ( status === "in_progress" || status === "arrived" || status === "Arrived" ){
                      setOpenNotification(true);
                      setsuccessMsg(`The patient is already arrived in Radiology Department.`);
                    }else if ( status === "completed" || status === "Completed" ){
                      setOpenNotification(true);
                      setErrorMsg(`The task is already completed.`);
                    }
                  }}
                  variant="contained"
                  color="primary"
                  fullWidth>Arrived</Button>
              </div>
              <div className="col-12 col-md-6 mt-3 mt-lg-3 text-center">
                <Button
                  style={{
                    backgroundColor: "#2C6DDD",
                    color: "white",
                    cursor: "pointer",
                    borderRadius: 5,
                    height: matches ? 56 : 45,
                    outline: "none",
                    width: "100%",
                  }}
                  onClick={() => {
                    if (status==="pending"){
                      setOpenNotification(true);
                      setErrorMsg(`You cannot perform this action because the patient doesn't arrived in Radiology Department.`);
                    }else if ( status === "in_progress" || status === "arrived" || status === "Arrived" ){
                      setCompleteOpen(true);
                    }else if (status==="completed" || status==="Completed"){
                      setOpenNotification(true);
                      setErrorMsg(`The task is already completed.`);
                    }
                  }}
                  variant="contained"
                  color="primary"
                  fullWidth>Completed</Button>
              </div>
              </>
            : undefined
          }
        </div>
        <div className='row mt-4 mb-4'>
          <div className='col-md-12 col-sm-12 col-12'>
            <img
              onClick={() => {
                let url = '/dashboard/home/taskslistforcustomercare';
                props.history.push({
                  pathname: url,
                });
              }}
              src={Back}
              style={{
                width: 45,
                height: 35,
                cursor: 'pointer',
              }}
            />
          </div>
        </div>
      </div>

      <Dialog
        open={arrivedOpen}
        onClose={handleArrivedClose}
        aria-labelledby="alert-dialog-arrived-title"
        aria-describedby="alert-dialog-arrived-description"
      >
        <DialogTitle id="alert-dialog-arrived-title">{"Arrived?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-arrived-description">
            This is a confirmation that <strong>the patient has been brought to the radiology department?</strong><br /><br />
            Do you want to confirm it?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleArrivedClose} color="primary">No</Button>
          <Button onClick={() => {
            handleSave('arrived');
          }} color="primary" autoFocus>Yes</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={completeOpen}
        onClose={handleCompleteClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Completed?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This is a confirmation that <strong>the patient has been brought back to the Production Area / Bed?</strong><br /><br />
            Do you want to confirm it?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCompleteClose} color="primary">No</Button>
          <Button onClick={() => {
            handleSave('completed');
          }} color="primary" autoFocus>Yes</Button>
        </DialogActions>
      </Dialog>

      <Notification
        msg={errorMsg}
        open={openNotification}
        success={successMsg}
      />
    </div>
  );
}