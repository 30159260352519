import React, { useEffect, useState, useReducer } from "react";
import { withStyles } from '@material-ui/core/styles';
import Header from "../../components/Header/Header";
import classNames from "classnames";
import Back from "../../assets/img/Back_Arrow.png";
import {medicineFrequencyArray, routeArrayNames} from "./configForNotesScreen"
import { 
  Button, 
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment,
  MenuItem,
  TextField,
  Select,
  useMediaQuery,
} from "@material-ui/core";
import cookie from "react-cookies";

import axios from "axios";
import {
  audioURL,
  updateEDNursePharmaAdministeredDetail,
  getSingleEdr
} from "../../public/endpoins";

import useStylesForInput from "../../assets/jss/material-dashboard-react/inputStyle";
import styles from "./styles";
import Loader from "react-loader-spinner";
import {
  Checkbox, 
  FormLabel, 
  FormControl, 
  FormGroup, 
  FormControlLabel,
  RadioGroup, 
  Radio
} from '@material-ui/core';
import {
  priorityArray,
  statusArray,
} from "./configForNotesScreen";
import Icon from '@material-ui/core/Icon';
import CloseIcon from '@material-ui/icons/Close';
import MicIcon from "@material-ui/icons/Mic";
import StopIcon from "@material-ui/icons/Stop";
import MicRecorder from "mic-recorder-to-mp3";
import AccountCircle from "@material-ui/icons/SearchOutlined";
import Notification from "../../components/Snackbar/Notification.js";

import LabIcon from "../../assets/img/Lab.png";
import { displayInHumanDifference, replacePatientIdIfNull, displayInHumanClassic } from "../../utils/commonFunc";
import PatientDetailsQuick from "../../components/PatientDetails/PatientDetailsQuick";
import PatientDetails from "../../components/PatientDetails/PatientDetails";
import PatientClinicalInformation from "../../components/PatientDetails/PatientClinicalInformation";

export default function PharmacyHistoryMedicationPage(props) {
  
  const matches = useMediaQuery("(min-width:600px)");
  const classes = useStylesForInput();

  const mystyle = {
    color: "white",
    backgroundColor: "DodgerBlue",
    padding: "10px",
    fontFamily: "Arial"
  };

  const GreenCheckbox = withStyles({
    root: {
      color: '#AAAAAA',
      '&$checked': {
        color: '#AAAAAA',
      },
    },
    checked: {
      color: '#2C6DDD',
      '&$checked': {
        color: '#2C6DDD',
      },
    },
  })((props) => <Checkbox color="default" {...props} />);

  const GreenRadio = withStyles({
    root: {
      color: '#AAAAAA',
      '&$checked': {
        color: '#AAAAAA',
      },
    },
    checked: {
      color: '#2C6DDD',
      '&$checked': {
        color: '#2C6DDD',
      },
    },
  })((props) => <Radio color="default" {...props} />);

  


  const initialState = {
    refreshedOn: 0,
    bulkTestSelected: false,
    resetedOn: "",
    defaultItems: {},
    seletedLabItems: [],
    labPriority: "",
    labTechnician: "",
    labComments: "",
    labTechniciansList: [],
  };
  function reducer(state, { field, value }) {
    return {
      ...state,
      [field]: value,
    };
  }
  const [state, dispatch] = useReducer(reducer, initialState);
  const {
    refreshedOn,
    bulkTestSelected,
    resetedOn,
    defaultItems,
    seletedLabItems,
    labPriority,
    labTechnician,
    labComments,
    labTechniciansList,
  } = state;

  const handleCheckupChange = (event) => {
    dispatch({field: 'bulkTestSelected', value: event.target.value});
  };

  const uniqueCodeField = (name) => {
    name = name.toLowerCase();
    name = name.replace(/[^a-z0-9]+/g, '-');
    name = name.replace(/^-+|-+$/g, '');
    return name;
  }

  const handleCastTrigger = (parentId, subId) => {
    let urlX = `/dashboard/home/${episodeId}/pharmacy/listMedication/${parentId}/details/${subId}`
    console.log('remoteRecord.pharmacyRequest::click', urlX)
    axios
        .post(updateEDNursePharmaAdministeredDetail, {
          edrId: episodeId,
          requestId: parentId,
          subRequestId: subId,
          staffId: currentUser._id,
        })
        .then((res) => {
          if (res.data.success) {
            setOpenNotification(true)
            setsuccessMsg(`The Administered has been completed!`)
            // window.location.reload()
            getRefreshedItem()
            dispatch({field: 'refreshedOn', value: 1})
          }
        })
        .catch((e) => {
          setOpenNotification(true)
          setErrorMsg("Unable to Update the Record")
        });
  }

  const handlePharmaHistory = () => {
    let url = `/dashboard/home/${episodeId}/pharmacy/medication-history`
    props.history.push({
      pathname: url,
    });
    // props.history.go();
  }

  const viewAdministedDetails = (parentId, subId) => {
    props.history.push({
      pathname: `/dashboard/home/${episodeId}/pharmacy/listMedication/${parentId}/details/${subId}`
    });
  }

  const [currentUser] = useState(cookie.load("current_user"));
  const [timer, setTimer] = useState(null);
  const [selectedLab, setSelectedLab] = useState("");
  const [mItems, setMItems] = useState([]);

  const [patientDetails, setPatientDetails] = useState("");
  const [selectedRecord, setSelectedRecord] = useState({});

  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setsuccessMsg] = useState("");
  const [openNotification, setOpenNotification] = useState(false);

  const [ episodeId, setEpisodeId ] = useState(props.match.params.id);
  const [ pharmaRequestId, setPharmaRequestId ] = useState(props.match.params.requestId);
  const [ pharmaRequests, setPharmaRequests ] = useState([]);
  const [ serviceType, setServiceType ] = useState(props.match.params.serviceType);

  if (openNotification) {
    setTimeout(() => {
      setOpenNotification(false);
      setErrorMsg("");
      setsuccessMsg("");
    }, 4000);
  }

  const getRefreshedItem = () => {
    axios
      .get(getSingleEdr + "/" + episodeId)
      .then((res) => {
        console.log('handleView::', res.data.data);
        if (res.data.success) {

          let remoteRecord = replacePatientIdIfNull(res.data.data);
          setSelectedRecord(remoteRecord);

          const object = {
            '_id': remoteRecord._id,
            patientName: remoteRecord.patientId.name[0].given[0] + " " + remoteRecord.patientId.name[0].family,
            mrn: remoteRecord.requestNo,//remoteRecord.patientId.identifier[0].value,
            age: remoteRecord.patientId.age,
            gender: remoteRecord.patientId.gender,
            weight: remoteRecord.patientId.weight,
            department: remoteRecord.currentLocation,
            chiefComplaint: remoteRecord.chiefComplaint.length > 0 ? remoteRecord.chiefComplaint.pop().chiefComplaintId.short_desc : "N/A",
            chiefComplaintOthers: remoteRecord.chiefComplaintOthers,
          };
          setPatientDetails(object);

          let pharmaServicesX = remoteRecord.pharmacyRequest;
          if (pharmaServicesX.length > 0){
            console.log('remoteRecord.pharmacyRequest::fintered', pharmaServicesX);

            let pharmaAdministrationItems = [];

            Object.entries(pharmaServicesX).map((obj, entry) => {
              let entryIdIndex = 1;
              Object.entries(obj[1].item).map((pharmaRequest, entryIndex) => {
                let nItem = pharmaRequest[1]
                nItem.parentId = obj[1]._id
                nItem.pharmacyRequestNo = obj[1].pharmacyRequestNo
                nItem.entry_index_id = entryIdIndex;
                pharmaAdministrationItems.push(nItem)
                entryIdIndex++;
              })
            })
            setPharmaRequests( pharmaAdministrationItems )
            console.log('remoteRecord.pharmacyRequest::pharmaAdministrationItems', pharmaAdministrationItems)

          }
          

        }
      })
      .catch((e) => {
        console.log("error after searching patient request", e);
      });
  }

  useEffect(() => {
    getRefreshedItem()
  }, []);

  return (
    
    <div
      style={{
        backgroundColor: "rgb(19 213 159)",
        position: "fixed",
        display: "flex",
        width: "100%",
        height: "100%",
        flexDirection: "column",
        flex: 1,
        overflowY: "scroll",
      }}
    >
      <Header history={props.history} />

      <PatientDetailsQuick
        edrId={episodeId} />

      <div className="cPadding">
        <div className="subheader">
          <div>
            <img src={LabIcon} />
            <h4>{patientDetails.patientName}</h4>
          </div>
        </div>

        <PatientClinicalInformation
          edrId={episodeId}
          wrapClass="mt-3 mb-3" />

        <h4 className="h6 text-white mb-3"
          style={{
            fontWeight: '700'
          }}>List of Items (History):</h4>
        <div className="row row-5">
          <div className="col-12 col-md-12 col-lg-12 col-xl-12">
            <div className="card card-detail-info skip-top-padding mb-2">
              {
                pharmaRequests ? 
                Object.entries(pharmaRequests.filter((item) => {
                  return item.itemType === (serviceType==="non-pharmacy"?"non_pharmaceutical":"pharmaceutical");
                })).map((obj, entry) => {
                  // if ( 
                  //   obj[1].administeredDetails.length >=
                  //   (
                  //     (Math.ceil(obj[1].frequency)>=11 || Math.ceil(obj[1].frequency)===0)?
                  //     obj[1].requestedQty:obj[1].dosage*obj[1].duration*obj[1].frequency 
                  //   ) 
                  // ){
                    return (
                      <div className="row row-5 medical-list">
                        <div className="col-6 card-detail-block block-xdiv"><strong className="lblX">Request ID:</strong> <span className="valX">{obj[1].pharmacyRequestNo}-{obj[1].entry_index_id}</span></div>
                        <div className="col-6 card-detail-block block-xdiv d-flex justify-content-end"></div>
                        <div className="col-sm-12 card-detail-block block-xdiv mt-3 mt-md-2"><strong className="lblX">Item Name</strong><span className="valX"><strong className="text-capitalize">{obj[1].itemName}</strong></span></div>
                        <div className="col card-detail-block block-xdiv mt-3 mt-md-2"><strong className="lblX">Dosage</strong><span className="valX">{obj[1].dosage}{obj[1].dosageType??undefined}</span></div>
                        <div className="col card-detail-block block-xdiv mt-3 mt-md-2"><strong className="lblX">Duration</strong><span className="valX">{obj[1].duration}</span></div>
                        <div className="col card-detail-block block-xdiv mt-3 mt-md-2"><strong className="lblX">Frequency</strong><span className="valX">{medicineFrequencyArray[obj[1].frequency]}</span></div>
                        <div className="col card-detail-block block-xdiv mt-3 mt-md-2"><strong className="lblX">Route</strong><span className="valX">{routeArrayNames[obj[1].route]}</span></div>
                        <div className="col card-detail-block block-xdiv mt-3 mt-md-2"><strong className="lblX">{matches?"Requested Qty":"Req. Qty"}</strong><span className="valX">{(Math.ceil(obj[1].frequency)>=11 || Math.ceil(obj[1].frequency)===0)?obj[1].requestedQty:obj[1].dosage*obj[1].duration*obj[1].frequency}</span></div>
                        <div className="col-12"></div>
                        <div className="col-5 col-md-3 card-detail-block block-xdiv mt-3 mt-md-2"><strong className="lblX"># Administered</strong><span className="valX">{obj[1].administeredDetails.length}/{(Math.ceil(obj[1].frequency)>=11 || Math.ceil(obj[1].frequency)===0)?obj[1].requestedQty:obj[1].dosage*obj[1].duration*obj[1].frequency}</span></div>
                        <div className="col-5 col-md-3 card-detail-block block-xdiv mt-3 mt-md-2"><strong className="lblX">Last Administered</strong><span className="valX">{obj[1].administeredDetails.length>0?displayInHumanClassic(obj[1].administeredDetails[obj[1].administeredDetails.length-1].completedAt):'N/A'}</span></div>
                        <div className="col-2 col-md-6 card-detail-block block-xdiv mt-3 mt-md-2 text-right">
                          <strong className="lblX">&nbsp;</strong>
                          {
                            obj[1].administeredDetails.length < 
                            (
                              (Math.ceil(obj[1].frequency)>=11 || Math.ceil(obj[1].frequency)===0)?
                              obj[1].requestedQty:obj[1].dosage*obj[1].duration*obj[1].frequency 
                            ) ? <button className="btn btn-danger btn-sm" onClick={() => handleCastTrigger(obj[1].parentId, obj[1]._id)}>+</button> : undefined
                          }
                        </div>
                        <div className="col-12 card-detail-block block-xdiv mt-2 mt-md-2"><strong className="lblX">Notes</strong><span className="valX">{obj[1].additionalNotes===""?"N/A":obj[1].additionalNotes}</span></div>

                        {
                          obj[1].administeredDetails.length > 0?
                          Object.entries(obj[1].administeredDetails).map((objx, entryx) => {
                            return (
                              <div class="col-sm-12 mt-4"><strong>#{entryx+1}:</strong> Administered Item <small className="text-primary">({displayInHumanClassic(objx[1].completedAt)})</small></div>
                            )
                          }):undefined
                        }
                      </div>
                    );
                  // }
                }):undefined
              }
            </div>
          </div>
        </div>
        
        <div
          className="container-fluid"
          style={{ marginBottom: "25px", marginTop: "25px" }}
        >
          <div className="row">
            <img
              onClick={() => props.history.goBack()}
              src={Back}
              style={{ width: 45, height: 35, cursor: "pointer" }}
            />
          </div>
        </div>
      </div>


      <Notification
        msg={errorMsg}
        open={openNotification}
        success={successMsg}
      />
    </div>
  );
}