import React, { useState, useEffect, useReducer } from "react";
import Notification from "../../components/Snackbar/Notification.js";
import CustomTable from "../../components/Table/Table";
import _ from "lodash";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Back from "../../assets/img/Back_Arrow.png";
import Header from "../../components/Header/Header";
import ED_Icon from "../../assets/img/Triage & Assessment Icon White.png";
import ButtonField from "../../components/common/Button";
import "../../assets/jss/material-dashboard-react/components/loaderStyle.css";
import cookie from "react-cookies";

const tableHeading = [
  "Date/Time",
  // 'Doctor/Staff',
  "Version",
  "Action",
];
const tableDataKeys = [
  "updatedAt",
  //  'doctor',
  "versionNo",
];

const actions = { view: true };

const useStylesForTabs = makeStyles({
  root: {
    justifyContent: "center",
  },
  scroller: {
    flexGrow: "0",
  },
});

export default function TriageAssessment(props) {
  const classesForTabs = useStylesForTabs();

  const [currentUser] = useState(cookie.load("current_user"));
  const [EDData, setEDData] = useState([]);
  const [errorMsg, setErrorMsg] = useState("");
  const [openNotification, setOpenNotification] = useState(false);
  const [value] = useState(0);
  const [pathname, setPathname] = useState("");
  const [patientData, setpatientData] = useState("");

  useEffect(() => {
    console.log("props passed , ", props);

    setpatientData(props.history.location.state);

    Object.entries(props.history.location.state).map(([key, val]) => {
      if (val && typeof val === "object") {
        if (key === "dcdForm") {
          // val.map(
          //   (v) => (
          //     (v.doctor =
          //       currentUser.name[0].given[0] +
          //       ' ' +
          //       currentUser.name[0].family),
          //     (v.updatedAt = v.updatedAt)
          //   )
          // )
          setEDData(val);
        }
      }
    });
    setPathname(props.location.pathname);
  }, []);

  useEffect(() => {
    if (props.selectedPatientDetails) {
      let copiedObj = JSON.parse(JSON.stringify(props.selectedPatientDetails));
      setpatientData(copiedObj);
    }
  }, [props.selectedPatientDetails]);

  console.log("edData", EDData);

  const handleDCDform = () => {
    props.history.push({
      pathname: "/dashboard/home/dcddashboard",
      state: props.history.location.state,
    });
  };

  if (openNotification) {
    setTimeout(() => {
      setOpenNotification(false);
      setErrorMsg("");
    }, 2000);
  }

  function handleView(rec) {
    console.log("record passed ", rec);
    let path = `viewDCD`;
    props.history.push({
      pathname: path,
      state: {
        dcdForm: rec,
        selectedItem: patientData,
      },
    });
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        position: "fixed",
        width: "100%",
        height: "100%",
        backgroundColor: "rgb(19 213 159)",
        overflowY: "scroll",
      }}
    >
      <Header history={props.history} />

      <div className="cPadding">
        <div className="subheader">
          <div style={{ marginLeft: -14 }}>
            <img src={ED_Icon} />
            <h4>DCD History</h4>
          </div>

          {currentUser.subType.includes("Internal") ||
          currentUser.subType.includes("External") ||
          currentUser.staffType === "Sensei" ? (
            undefined
          ) : (
            <div style={{ marginRight: 5 }}>
              <ButtonField onClick={handleDCDform} name="dcd" />
            </div>
          )}
        </div>

        <div className={classesForTabs.root}>
          <Tabs
            classes={{
              root: classesForTabs.root,
              scroller: classesForTabs.scroller,
            }}
            value={value}
            textColor="primary"
            TabIndicatorProps={{ style: { background: "#12387a" } }}
            centered={false}
            variant="scrollable"
            fullWidth={true}
          >
            <Tab
              style={{
                color: "white",
                borderRadius: 5,
                outline: "none",
                color: value === 0 ? "#12387a" : "#3B988C",
              }}
              label="History"
            />
          </Tabs>
        </div>

        <div
          style={{
            flex: 4,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div>
            <div>
              <div>
                <CustomTable
                  tableData={EDData}
                  tableDataKeys={tableDataKeys}
                  tableHeading={tableHeading}
                  action={actions}
                  handleView={handleView}
                  borderBottomColor={"#60d69f"}
                  borderBottomWidth={20}
                />
              </div>
              <div style={{ marginTop: 20, marginBottom: 20 }}>
                <img
                  onClick={() => props.history.goBack()}
                  src={Back}
                  style={{
                    width: 45,
                    height: 35,
                    cursor: "pointer",
                  }}
                />
              </div>
              <Notification msg={errorMsg} open={openNotification} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
