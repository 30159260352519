/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable array-callback-return */
/* eslint-disable react/jsx-indent */
import React, { useEffect, useState, useReducer } from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Notification from "../../../components/Snackbar/Notification.js";
import cookie from "react-cookies";
import Header from "../../../components/Header/Header";
import shiftsIcon from "../../../assets/img/ED RoomsHistory.png";
import Back from '../../../assets/img/Back_Arrow.png'
import DateFnsUtils from "@date-io/date-fns";
import moment from 'moment'
import { styles, useStylesForInput } from './styles.js'
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker
} from "@material-ui/pickers";
import { addNewShift, editShift } from "./networkCalls.js";

function AddEditShifts(props) {
  const classes = useStylesForInput();

  const [currentUser, setCurrentUser] = useState(cookie.load("current_user"));
  const [comingFor, setcomingFor] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [openNotification, setOpenNotification] = useState(false);
  const [shiftId, setShiftId] = useState("");

  const initialState = {
    name: '',
    addedBy: currentUser.name[0].given[0] + ' ' + currentUser.name[0].family,
    startTime: null,
    endTime: null,
    reason: ''
  };

  function reducer(state, { field, value }) {
    return {
      ...state,
      [field]: value,
    };
  }

  const [state, dispatch] = useReducer(reducer, initialState);

  const {
    name,
    addedBy,
    startTime,
    endTime,
    reason
  } = state;

  useEffect(() => {
    let propsPassed = props.history.location.state
    setcomingFor(propsPassed.comingFor)

    if (propsPassed.comingFor === 'edit') {
      let selectedItem = propsPassed.selectedItem

      console.log("Props passed : ", selectedItem)
      setShiftId(selectedItem._id)
      Object.entries(selectedItem).map(([key, val]) => {
        dispatch({ field: key, value: val })
      })
    }
  }, []);

  function validateForm() {
    if (comingFor === 'add') {
      return (
        name && name.length > 0 &&
        startTime &&
        endTime
      )
    }
    else if (comingFor === 'edit') {
      return (
        name && name.length > 0 &&
        startTime &&
        endTime &&
        reason && reason.length > 0
      )
    }
  }

  function validateShiftTime() {
    const start = moment(startTime)
    const end = moment(endTime)
    const difference = moment(end.diff(start)).utcOffset(0).format('HH:mm:ss')
    const duration = moment.duration(difference)

    // console.log("Start Shift", start)
    // console.log("End Shift", end)
    // console.log("difference", difference)
    // console.log("duration in hours", duration.hours())
    // console.log("duration in minutes", duration.minutes())

    if (duration.hours() >= 8) {
      return true
    }
    else {
      return false
    }
  }

  const handleAdd = () => {
    const params = {
      name,
      addedBy: currentUser._id,
      startTime,
      endTime
    };
    if (validateShiftTime()) {
      addNewShift(
        params,
        props,
        setOpenNotification,
        setErrorMsg
      )
    }
    else {
      setErrorMsg("Shift should complete atleast 8 hours")
      setOpenNotification(true)
    }
  };

  const handleEdit = () => {
    const params = {
      shiftId,
      reason,
      updatedBy: currentUser._id,
      startTime,
      endTime
    };
    if (validateShiftTime()) {
      editShift(
        props,
        params,
        setOpenNotification,
        setErrorMsg
      )
    }
    else {
      setErrorMsg("Shift should complete atleast 8 hours")
      setOpenNotification(true)
    }
  };

  const onChangeValue = (e) => {
    dispatch({ field: e.target.name, value: e.target.value });
  };

  const handleDateChange = (e, name) => {
    dispatch({ field: name, value: e })
  }

  if (openNotification) {
    setTimeout(() => {
      setOpenNotification(false);
      setErrorMsg("");
    }, 2000);
  }

  return (
    <div
      style={{
        backgroundColor: "#60d69f",
        position: "fixed",
        display: "flex",
        width: "100%",
        height: "100%",
        flexDirection: "column",
        flex: 1,
        overflowY: "scroll",
      }}
    >
      <Header history={props.history} />

      <div className='cPadding'>
        <div className='subheader'>
          <div >
            <img src={shiftsIcon} />
            <h4>Shifts Management</h4>
          </div>
        </div>

        <div className="container-fluid">

          <div className='row mt-3'>
            <div className={`col-sm-6 col-md-6 col-12 ${classes.root}`}
              style={{
                ...styles.inputContainerForTextField,
                ...styles.textFieldPadding,
                paddingLeft: '15px'
              }}
            >
              <TextField
                disabled={comingFor === "edit" ? true : false}
                label="Shift Name"
                name={"name"}
                value={name}
                onChange={onChangeValue}
                className="textInputStyle"
                variant="filled"
                InputProps={{
                  className: classes.input,
                  classes: { input: classes.input },
                  disableUnderline: true
                }}
                inputProps={{ maxLength: 40 }}
              />
            </div>
            <div className={`col-sm-6 col-md-6 col-12 ${classes.root}`}
              style={{
                ...styles.inputContainerForTextField,
                ...styles.textFieldPadding,
                paddingRight: '15px'
              }}
            >
              <TextField
                disabled
                label="Added By"
                name={"addedBy"}
                value={addedBy}
                onChange={onChangeValue}
                className="textInputStyle"
                variant="filled"
                InputProps={{
                  className: classes.input,
                  classes: { input: classes.input },
                  disableUnderline: true
                }}
                inputProps={{ maxLength: 40 }}
              />
            </div>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <div className={`col-sm-6 col-md-6 col-12 ${classes.rootForTimeField}`}
                style={{
                  ...styles.inputContainerForTextField,
                  ...styles.textFieldPadding,
                  paddingLeft: '15px'
                }}
              >
                <KeyboardTimePicker
                  fullWidth
                  id="time-picker"
                  label="Start Time"
                  name={'startTime'}
                  value={startTime}
                  emptyLabel='00:00'
                  onChange={(e) => handleDateChange(e, 'startTime')}
                  variant="inline"
                  margin="normal"
                  className="textInputStyle"
                  KeyboardButtonProps={{
                    'aria-label': 'change time',
                  }}
                  InputProps={{
                    className: classes.input,
                    classes: { input: classes.input },
                    disableUnderline: true,
                  }}
                />
              </div>
              <div className={`col-sm-6 col-md-6 col-12 ${classes.rootForTimeField}`}
                style={{
                  ...styles.inputContainerForTextField,
                  ...styles.textFieldPadding,
                  paddingRight: '15px'
                }}
              >
                <KeyboardTimePicker
                  fullWidth
                  id="time-picker"
                  label="End Time"
                  name={'endTime'}
                  value={endTime}
                  emptyLabel='00:00'
                  onChange={(e) => handleDateChange(e, 'endTime')}
                  variant="inline"
                  margin="normal"
                  className="textInputStyle"
                  KeyboardButtonProps={{
                    'aria-label': 'change time',
                  }}
                  InputProps={{
                    className: classes.input,
                    classes: { input: classes.input },
                    disableUnderline: true,
                  }}
                />
              </div>
            </MuiPickersUtilsProvider>
            {comingFor === "edit" ?
              <div
                className={`col-sm-12 col-md-12 col-12 ${classes.root}`}
                style={{ marginTop: '20px' }}
              >
                <TextField
                  multiline
                  rows={4}
                  label="Reason For Update"
                  name={"reason"}
                  value={reason}
                  onChange={onChangeValue}
                  className="textInputStyle"
                  variant="filled"
                  InputProps={{
                    className: classes.input,
                    classes: { input: classes.input },
                    disableUnderline: true
                  }}
                />
              </div>
              :
              undefined
            }
          </div>

          <div className='row mt-4'>
            <div className='col-6 pl-4'>
              <img
                onClick={() => props.history.goBack()}
                src={Back}
                style={{
                  width: 45,
                  height: 35,
                  cursor: 'pointer',
                }}
              />
            </div>
            <div className='col-6 d-flex justify-content-end'>
              {comingFor === "add" ? (
                <Button
                  style={{ ...styles.stylesForButton, width: '140px' }}
                  disabled={!validateForm()}
                  onClick={handleAdd}
                  variant="contained"
                  color="primary"
                >
                  Add Shift
                </Button>
              ) : (
                <Button
                  style={{ ...styles.stylesForButton, width: '140px' }}
                  disabled={!validateForm()}
                  onClick={handleEdit}
                  variant="contained"
                  color="primary"
                >
                  Update Shift
                </Button>
              )}
            </div>
          </div>

          <Notification msg={errorMsg} open={openNotification} />
        </div>
      </div>
    </div>
  );
}
export default AddEditShifts;