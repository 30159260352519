/*eslint-disable*/
import React, { useEffect, useState } from "react";
import {
  useMediaQuery,
} from "@material-ui/core";
import MicIcon from "@material-ui/icons/Mic";
import StopIcon from "@material-ui/icons/Stop";
import MicRecorder from "mic-recorder-to-mp3";
import Loader from "react-loader-spinner";

const Mp3Recorder = new MicRecorder({ bitRate: 128 });

export default function VoiceComponent(props) {
  const matches = useMediaQuery("(min-width: 600)");
  const [isBlocked, setIsBlocked] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [audioUrl, setAudioUrl] = useState(false);

  const setAudioFile = (file) => {
    console.log("VoiceComponent", props);
    props.setAudioFile(file)
  }

  useEffect(() => {
    // console.log(props, "VoiceComponent");

    // Navigator for MP3
    navigator.mediaDevices &&
      navigator
        .mediaDevices.getUserMedia({ audio: true })
        .then( stream => {
          setIsBlocked(false)
        })
        .catch( error => {
          setIsBlocked(true)
        });

  }, []);

  return (
    !isBlocked ? (
      <div className="d-flex align-items-center">
        <div
          style={{
            marginRight: matches ? 15 : 7,
            backgroundColor: (isRecording) ? "#DC004E" : "#2C6DDD",
            height: matches ? 55 : 50,
            borderRadius: matches ? 55 : 50,
            width: matches ? 55 : 50,
            cursor: 'pointer',
            textAlign: 'center',
            flex: "0 0 "+(matches ? 55 : 50)+"px"
          }}
        >

          {isRecording ? (
            <StopIcon
              style={{ marginTop: matches ? 11 : 9, color: "white" }}
              fontSize="large"
              onClick={(name, field) => {
                console.log('VoiceComponent', 'Clicked Stop');
                Mp3Recorder.stop()
                  .getMp3()
                  .then(([buffer, blob]) => {
                    const blobURLFinal = URL.createObjectURL(blob);
                    setAudioUrl(blobURLFinal);


                    var file = new File([blob], "first", { type: "audio/mp3" });
                    setIsRecording(false);
                    setAudioFile(file)
                  })
                  .catch((e) => console.log("VoiceComponent", e));
              }}
              disabled={!isRecording}
              color="secondary"
            />
          ) : (
            <MicIcon
              style={{ marginTop: matches ? 12 : 10, color: "white" }}
              fontSize="large"
              onClick={(e) => {
                if (isBlocked) {
                  alert("Your browser doesn't have permission to record Audio!");
                } else {
                  Mp3Recorder.start()
                    .then(() => {
                      setIsRecording(true);
                    })
                    .catch((e) => console.error(e));
                }
              }}
              disabled={isRecording}
              color="primary"
            />
          )}
        </div>


        {isRecording ? (
          <div
            style={{
              marginLeft: matches ? 110 : 18,
              marginRight: matches ? 110 : 75,
              width: matches ? 550 : 100,
            }}
          >
            <Loader
              type="ThreeDots"
              color="blue"
              height={40}
              width={40}
            />
          </div>
        ) : (
          <audio
            style={{
              marginRight: 10,
              width: matches ? 450 : 260,
              marginTop: 6,
            }}
            src={audioUrl ? audioUrl:undefined}
            controls="controls"
          />
        )}
      </div>
    ): (
      <div className="alert alert-info">Your browser doesn't support audio recording...</div>
    )
  );
}
