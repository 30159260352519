import React, { useState } from "react";
import { Button } from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import LineChart from "./LineChart";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: "8px",
    height: "40px",
    backgroundColor: "#2873CF",
  },
  root: {
    "& .MuiButton-label": {
      fontSize: "0.675rem",
    },
  },
}));

function ButtonCentered(props) {
  const classes = useStyles();
  const matches = useMediaQuery("(min-width:600px)");
  const minimumWidthForTab = useMediaQuery("(min-width:601px)");
  const maximumWidthForTab = useMediaQuery("(max-width:768px)");

  const [change, setChange] = useState(true);

  const flipIt = () => {
    setChange(false);
  };

  return (
    <div>
      {change ? (
        <div style={{ display: "flex" }}>
          <div
            style={{
              width: "100%",
              backgroundColor: "white",
              paddingTop: matches ? 20 : "",
              height: matches ? 270 : 190,
              borderTopLeftRadius: "5px",
              borderBottomLeftRadius: "5px",
            }}
          >
            <div
              style={{
                display: "flex",
                height: "40%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <span
                style={{
                  fontSize: matches ? 14 : 11,
                  fontWeight: "800",
                  opacity: matches ? "70%" : "80%",
                  textAlign: "center",
                }}
              >
                {props.TimerHeading}
              </span>
            </div>
            <div
              className="col-12"
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                paddingLeft: 0,
                paddingRight: 0,
                alignItems: "center",
              }}
            >
              <span
                style={{
                  color: props.timeColor,
                  fontSize: matches ? 50 : 32,
                  fontWeight: "800",
                  position: "absolute",
                  marginTop: matches ? -20 : 10,
                }}
              >
                {props.time}
              </span>
            </div>
          </div>
          <div
            style={{
              width: "100%",
              backgroundColor: "white",
              paddingTop: 20,
              height: matches ? 270 : 190,
            }}
          >
            <div
              style={{
                display: "flex",
                height: "38%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <span
                style={{
                  fontSize: matches ? 14 : 11,
                  fontWeight: "800",
                  opacity: matches ? "70%" : "80%",
                  textAlign: "center",
                }}
              >
                {props.buttonHeading}
              </span>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                size="medium"
                className={[classes.button, matches ? " " : classes.root]}
                startIcon={<VisibilityIcon />}
                onClick={flipIt}
              >
                View Details
              </Button>
            </div>
          </div>
          <div
            style={{
              width: "100%",
              backgroundColor: "white",
              paddingTop: matches ? 20 : "",
              height: matches ? 270 : 190,
              borderTopRightRadius: "5px",
              borderBottomRightRadius: "5px",
            }}
          >
            <div
              style={{
                display: "flex",
                height: "40%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <span
                style={{
                  fontSize: matches ? 14 : 11,
                  fontWeight: "800",
                  opacity: matches ? "70%" : "80%",
                  textAlign: "center",
                }}
              >
                {props.counterHeading}
              </span>
            </div>
            <div
              className="col-12"
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                paddingLeft: 0,
                paddingRight: 0,
                alignItems: "center",
              }}
            >
              <span
                style={{
                  color: props.timeColor,
                  fontSize: matches ? 50 : 32,
                  fontWeight: "800",
                  position: "absolute",
                  marginTop: matches ? -20 : 10,
                }}
              >
                {props.counter}
              </span>
            </div>
          </div>
        </div>
      ) : (
        <div
          style={{
            width: "100%",
            height: 270,
            backgroundColor: "white",
            borderRadius: 5,
          }}
          onClick={() => (setChange ? setChange(true) : {})}
        >
          <div
            style={{
              display: "flex",
              height: "10%",
              justifyContent: "center",
              alignItems: "flex-end",
            }}
          >
            <span
              style={{
                fontSize: 13,
                fontWeight: "700",
                color: "grey",
              }}
            >
              Last 24 Hours
            </span>
          </div>
          <div
            style={{
              display: "flex",
              height: "12%",
              justifyContent: "center",
              alignItems: "center",
              marginLeft: minimumWidthForTab && maximumWidthForTab ? 10 : "",
            }}
          >
            <span
              style={{
                fontSize: minimumWidthForTab && maximumWidthForTab ? 14 : 12,
                fontWeight: "bold",
                opacity: "60%",
              }}
            >
              {props.buttonHeading}
            </span>
          </div>
          <div
            className="container"
            style={{
              display: "flex",
              height: "70%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              className="row"
              style={
                matches ? { overflowX: "visible" } : { overflowX: "scroll" }
              }
            >
              <div className="col-sm-12" style={{ paddingLeft: 0 }}>
                {props.data ? (
                  <LineChart
                    data={matches ? props.data : props.data}
                    width={matches ? window.innerWidth - 65 : 1200}
                    height={170}
                    scaleWidth={matches ? window.innerWidth - 65 : 1200}
                    scaleHeight={130}
                    idForGraph={"peakTimeGraph"}
                  />
                ) : (
                  undefined
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
export default ButtonCentered;
