import axios from "axios";
import { getUsersFromRoleUrl, getAllStaff } from "../../../src/public/endpoins";

export function getUsersFromRole(
  setStaffData,
  setGeneralArray,
  setOpenNotification,
  setErrorMsg,
  staffType
) {
  axios
    .get(getUsersFromRoleUrl + "/" + staffType)
    .then((res) => {
      if (res.data.success) {
        console.log("all", res.data.data);

        let senseiData = [];
        for (let i = 0; i < res.data.data.length; i++) {
          let d = res.data.data[i];
          let obj = {
            ...d,
            stafName: d.name[0].given[0] + " " + d.name[0].family,
            // id: d.identifier[0].value,
            subType: d.subType.toString(),
            specialty: d.specialty.toString(),
          };
          senseiData.push(obj);
        }

        setGeneralArray([...senseiData]);
      } else if (!res.data.success) {
        setErrorMsg(res.data.error);
        setOpenNotification(true);
      }
      return res;
    })
    .catch((e) => {
      console.log("error: ", e);
    });
}

export function getAllUsers(
  setStaffData,
  setGeneralArray,
  setOpenNotification,
  setErrorMsg
) {
  axios
    .get(getAllStaff)
    .then((res) => {
      if (res.data.success) {
        console.log(res.data.data.docs);
        let senseiData = [];
        for (let i = 0; i < res.data.data.docs.length; i++) {
          let d = res.data.data.docs[i];
          let obj = {
            ...d,
            stafName: d.name[0].given[0] + " " + d.name[0].family,
            id: d.identifier[0].value,
            subType: d.subType.toString(),
            specialty: d.specialty.toString(),
          };
          senseiData.push(obj);
        }
        console.log("sensei data", senseiData);

        setGeneralArray([...senseiData]);
      } else if (!res.data.success) {
        setErrorMsg(res.data.error);
        setOpenNotification(true);
      }
      return res;
    })
    .catch((e) => {
      console.log("error: ", e);
    });
}
