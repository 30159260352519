import React from "react";
import { Route, Switch } from "react-router-dom";
import NotFound from "../components/NotFound/NotFound";
import Discharge from "../views/Discharge/Discharge";
import addDischargeMed from "../views/Discharge/addDischargeMed";
import dischargedPatients from "../views/DischargedPatients/dischargedPatients";
import SuccessScreen from "../components/SuccessScreen/SuccessScreen";

class DischargeRoutes extends React.PureComponent {
  render() {
    return (
      <Switch>
        <Route exact path={`${this.props.match.url}`} component={Discharge} />

        <Route
          exact
          path={`${this.props.match.url}/addmedication`}
          component={addDischargeMed}
        />

        <Route
          exact
          path={`${this.props.match.url}/dischargedpatients`}
          component={dischargedPatients}
        />

        <Route
          exact
          path={`${this.props.match.url}/success`}
          component={SuccessScreen}
        />

        <Route path={`${this.props.match.url}/notfound`} component={NotFound} />

        <Route path="*" component={NotFound} />
      </Switch>
    );
  }
}
export default DischargeRoutes;
