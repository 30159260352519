import React from "react";
import { Redirect } from "react-router-dom";
import cookie from "react-cookies";
import Back from "../../../../assets/img/Back_Arrow.png";
import MenuTree from "../../../../components/MenuTree/MenuTree";
import Header from "../../../../components/Header/Header";
// Sensei Icons
import PatientManagementIcon from "../../../../assets/img/PatientManagement.png";
import ViewRequests from "../../../../assets/img/View Requests- Reports.png";
import PatientRegistration from "../../../../assets/img/PatientRegistration.png";
import AssignDCD from "../../../../assets/img/AssignDCD Form.png";
import AssignCustomerCare from "../../../../assets/img/Assign CustomerCare.png";
import PenginPatients from "../../../../assets/img/PendingPatients.png";
// ED Doc Icons
import CareStreamsIcon from "../../../../assets/img/CareStreams Management.png";
import ConsultationIcon from "../../../../assets/img/External Consultants.png";
import TransferCareIcon from "../../../../assets/img/Transfer of care.png";
import PatientTransfersIcon from "../../../../assets/img/PatientTransfers.png";
import ReconciliationIcon from "../../../../assets/img/Reconciliation.png";
import ManagePatientsIcon from "../../../../assets/img/Manage_Patiens.png";
import ManageDCDIcon from "../../../../assets/img/Manage_DCD.png";
// ED nurse Icons
import CareStreamInProgressIcon from "../../../../assets/img/CareStream in Progress.png";
import HousekeepingIcon from "../../../../assets/img/Housekeeping.png";

const SenseiPanel = [
  { img: PatientManagementIcon, text: "Patient Management" },
  {
    img: ViewRequests,
    text: "View Requests/ Reports",
    path: "/dashboard/home/patientmanagement/viewrequests",
  },
  {
    img: PatientRegistration,
    text: "New Episode",
    path: "/dashboard/home/patientmanagement/episoderegistration",
  },
  {
    img: AssignDCD,
    text: "Assign DCD Form",
    path: "/dashboard/home/patientmanagement/assignDCDForm",
  },
  {
    img: PatientTransfersIcon,
    text: "Patient Transfers",
    path: "patientmanagement/patientTransfers",
  },
  {
    img: AssignCustomerCare,
    text: "Assign Customer Experience",
    path: "/dashboard/home/patientmanagement/customerCare",
  },
  {
    img: PenginPatients,
    text: "Pending Patients",
    path: "/dashboard/home/patientmanagement/pendingpatients",
  },
  {
    img: PatientRegistration,
    text: "Patient List",
    path: "/dashboard/home/patientmanagement/allpatients",
  },
  // {
  //   text: 'Code Blue Team',
  //   path: '/dashboard/home/codeblueteam',
  // },
];

const EDdocPanel = [
  { img: PatientManagementIcon, text: "Patient Management" },
  {
    img: CareStreamsIcon,
    text: "Assign CareStream",
    // path: "patientmanagement/careStreamPatients",
    path: "patientmanagement/CareStream",
  },
  {
    img: PatientTransfersIcon,
    text: "Patient Transfers",
    path: "patientmanagement/patientTransfers",
  },
  {
    img: TransferCareIcon,
    text: "Transfer Of Care",
    path: "/dashboard/underprogress",
  },
  {
    img: ConsultationIcon,
    text: "Consultation Requests",
    path: "patientmanagement/consultations",
    // path: '/dashboard/underprogress',
  },
  {
    img: ReconciliationIcon,
    text: "Reconciliation Requests",
    path: "/dashboard/home/reconciliationrequest",
  },
  {
    img: ManagePatientsIcon,
    text: "Manage Patients",
    path: "/dashboard/home/patientmanagement/patientlist",
  },
  {
    img: ManageDCDIcon,
    text: "Manage DCD Form",
    path: "/dashboard/home/patientmanagement/patientlist",
    //path: "/dashboard/home/patientmanagement/assignDCDForm",
  },
];

const Panel = [
  { img: PatientManagementIcon, text: "Patient Management" },
  {
    text: "",
    path: "",
  },
];

const EDnursePanel = [
  { img: PatientManagementIcon, text: "Patient Management" },
  {
    img: ManagePatientsIcon,
    text: "Manage Patients",
    path: "/dashboard/home/patientlist",
  },
  {
    img: CareStreamInProgressIcon,
    text: "CareStream In Progress",
    path: "patientmanagement/CareStreamInProgress",
    // path: "/dashboard/underprogress",
  },
  {
    img: ViewRequests,
    text: "View Requests/ Reports",
    path: "patientmanagement/viewrequests",
  },
  {
    img: CareStreamsIcon,
    text: "Assign CareStream",
    path: "patientmanagement/careStreamPatients",
  },
  {
    img: HousekeepingIcon,
    text: "Assign Housekeeping",
    path: "/dashboard/home/assignHousekeeping",
  },
];

const EOUnursePanel = [
  { img: PatientManagementIcon, text: "Patient Management" },
  {
    img: ManagePatientsIcon,
    text: "Manage Patients",
    path: "/dashboard/home/patientlist",
  },
];

class PatientManagement extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      openApps: false,
      goBack: false,
      currentUser: "",
    };
  }

  componentDidMount() {
    this.setState({ currentUser: cookie.load("current_user") });
  }

  render() {
    const userType = this.state.currentUser;
    if (this.state.goBack) {
      return <Redirect to={"/management"} />;
    }

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          position: "fixed",
          width: "100%",
          height: "100%",
          background:
            "linear-gradient(25deg, rgba(101,228,193,1) 0%, rgba(58,219,175,1) 33%, rgba(15,206,147,1) 66%, rgba(6,142,103,1) 100%)",
          backgroundSize: "100%",
        }}
      >
        <Header history={this.props.history} />

        <MenuTree
          history={this.props.history}
          options={
            userType.staffType === "Doctor"
              ? userType.subType.includes("ED Doctor")
                ? EDdocPanel
                : Panel
              : userType.staffType === "Sensei"
              ? SenseiPanel
              : userType.staffType === "Nurses"
              ? userType.subType.includes("ED Nurse")
                ? EDnursePanel
                : EOUnursePanel
              : Panel
          }
        />

        <div
          style={{
            position: "fixed",
            width: "100%",
            height: "10%",
            top: "90%",
          }}
        >
          <img
            alt="Back"
            onClick={() => this.props.history.goBack()}
            src={Back}
            style={{
              width: 45,
              height: 35,
              marginLeft: "5%",
              cursor: "pointer",
            }}
          />
        </div>
      </div>
    );
  }
}
export default PatientManagement;
