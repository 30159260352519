import React from 'react'
import ReactDOM from 'react-dom'
import { createBrowserHistory } from 'history'
import { Router, Route, Redirect } from 'react-router-dom'
// core components
import { ConnectedRouter } from 'connected-react-router'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import axios from 'axios'
import cookie from 'react-cookies'
import { ToastsContainer, ToastsStore } from 'react-toasts'
import * as serviceWorker from './serviceWorker'
import Login from './layouts/Login'
import QRView from './views/PatientList/PatientQR'
import QRBracelets from './views/PatientList/QRBracelets'
import ForgetPassword from './layouts/ForgetPassword'
import ResetPasswordStatus from './layouts/ResetPasswordStatus'
import configureStore, { history } from './store'
import HomeRoutes from '../src/subRoutes/home'
import './index.css'
export const { persistor, store } = configureStore()
const hist = createBrowserHistory()

function SecuredRoute(props) {
  const { component: Component, path } = props
  return (
    <Router history={hist}>
      <Route
        path={path}
        render={() => {
          // document.cookie = 'loggedInUser=';
          // console.log(document.cookie.split('=')[1]);
          const token = cookie.load('token') || ''

          if (token) {
            return <Component />
          } else {
            return <Redirect to='/login' />
          }
        }}
      />
    </Router>
  )
}

function interceptor() {
  axios.interceptors.request.use(
    function (config) {
      const token = cookie.load('token') || ''
      const currentUser = cookie.load('current_user') || ''
      console.log('token: ', token)
      // Do something before request is sent
      if (token) {
        config.headers.Authorization = `Bearer ${token}`
        if (currentUser && currentUser.staffTypeId) {
          config.headers.role = currentUser.staffTypeId.type
        }
      }
      return config
    },
    function (error) {
      // Do something with request error
      return Promise.reject(error)
    }
  )
  axios.interceptors.response.use(
    function (response) {
      return response
    },
    function (error) {
      if (error.response) {
        console.log('error 1 : ', error.response.data)
        return error.response.data
      } else {
        console.log('error 2 : ', error)
        return error
      }
    }
  )
}

const MainApp = () => {
  interceptor()
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ConnectedRouter history={history}>
          <Router history={hist}>
            <SecuredRoute exact path='/dashboard' component={HomeRoutes} />
            <Route path='/generateQRView' component={QRView} />
            <Route path='/printQRBracelets' component={QRBracelets} />
            <Route path='/login' component={Login} />
            <Route exact path='/' component={Login} />
            <Route exact path='/forgetpassword' component={ForgetPassword} />
            <Route
              exact
              path='/emailsendstatus'
              component={ResetPasswordStatus}
            />
          </Router>
          <ToastsContainer store={ToastsStore} />
        </ConnectedRouter>
      </PersistGate>
    </Provider>
  )
}
ReactDOM.render(<MainApp />, document.getElementById('root'))
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register()
