import React, { useState, useEffect } from "react";
import Notification from "../../components/Snackbar/Notification.js";
import CustomTable from "../../components/Table/Table";
import axios from "axios";
import _ from "lodash";
import Button from "@material-ui/core/Button";

import Loader from "react-loader-spinner";
import Back from "../../assets/img/Back_Arrow.png";
import Header from "../../components/Header/Header";
import Lab_OPR from "../../assets/img/carestream/CareStream.png";
import ButtonField from "../../components/common/Button";
import Rad_OPR from "../../assets/img/RR.png";
import Pharmacist_OPR from "../../assets/img/PHR.png";
import Fingerprint from "../../assets/img/fingerprint.png";
import AccountCircle from "@material-ui/icons/SearchOutlined";
import InputAdornment from "@material-ui/core/InputAdornment";
import BarCode from "../../assets/img/Bar Code.png";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import "../../assets/jss/material-dashboard-react/components/loaderStyle.css";
import socketIOClient from "socket.io-client";
import cookie from "react-cookies";
import { assignStyles } from "./styles";
import {
  getPendingParamedicsEdr,
  searchPendingEdrPatients,
} from "./NetworkCall";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import QRCodeScannerComponent from "../../components/QRCodeScanner/QRCodeScanner";

const tableHeading = [
  "ITD",
  "IT Name",
  "Shift",
  "Test Type",
  "No Of Tests",
  " ",
];
const tableDataKeys = ["ITD", "ITDName", "shift", "testName", "noOfTests"];

const styles = {
  textFieldPadding: {
    paddingLeft: 5,
    paddingRight: 5,
  },
};

const actions = { edit: true };
const action = { assign: true };

export default function EDR(props) {
  const classes = assignStyles();
  const matches = useMediaQuery("(min-width:600px)");
  const [labInPatient, setlabInPatient] = useState([]);
  const [radInPatient, setradInPatient] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [openNotification, setOpenNotification] = useState(false);
  const [searchPatientQuery, setSearchPatientQuery] = useState("");
  const [completedSenseiArray, setCompletedSenseiArray] = useState([]);
  const [currentUser, setCurrentUser] = useState(cookie.load("current_user"));
  const [QRCodeScanner, setQRCodeScanner] = useState(false);

  if (openNotification) {
    setTimeout(() => {
      setOpenNotification(false);
      setErrorMsg("");
    }, 2000);
  }

  useEffect(() => {
    getData();
  }, []);

  function getData() {
    getPendingParamedicsEdr(setlabInPatient, setErrorMsg, setOpenNotification);
  }

  const handlePatientSearch = (e) => {
    const a = e.target.value.replace(/[^\w\s]/gi, "");
    setSearchPatientQuery(a);
    if (a.length >= 3) {
      searchPendingEdrPatients(
        a,
        setlabInPatient,
        setErrorMsg,
        setOpenNotification
      );
    } else if (a.length == 0) {
      getData();
    }
  };

  function scanQRCode() {
    setQRCodeScanner((pervState) => !pervState);
  }

  function handleScanQR(data) {
    setQRCodeScanner(false);
    console.log("json", JSON.parse(data));
    if (JSON.parse(data).profileNo) {
      handlePatientSearch({
        target: {
          value: JSON.parse(data).profileNo,
          type: "text",
        },
      });
    }
  }

  if (QRCodeScanner) {
    return (
      <div>
        {QRCodeScanner ? (
          <QRCodeScannerComponent
            handleScanQR={handleScanQR}
            scanQRCode={scanQRCode}
          />
        ) : (
          undefined
        )}
      </div>
    );
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        position: "fixed",
        width: "100%",
        height: "100%",
        backgroundColor: "rgb(19 213 159)",
        overflowY: "scroll",
      }}
    >
      <Header history={props.history} />

      <div className="cPadding">
        <div className="subheader">
          <div>
            <img src={Lab_OPR} />
            <h4>Imaging Technician Performed Tests</h4>
          </div>
        </div>

        <div
          className={`${"container-fluid"} ${classes.root}`}
          style={{
            marginTop: "25px",
            paddingLeft: "10px",
            paddingRight: "10px",
          }}
        >
          <div className="row">
            <div
              className="col-md-12 col-sm-9 col-8"
              style={styles.textFieldPadding}
            >
              <TextField
                className="textInputStyle"
                id="searchPatientQuery"
                type="text"
                variant="filled"
                label="Search Imaging Technician by Name / ID"
                name={"searchPatientQuery"}
                value={searchPatientQuery}
                onChange={handlePatientSearch}
                InputLabelProps={{
                  classes: {
                    root: classes.label,
                    focused: classes.focusedLabel,
                    error: classes.erroredLabel,
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <AccountCircle />
                    </InputAdornment>
                  ),
                  className: classes.input,
                  classes: { input: classes.input },
                  disableUnderline: true,
                }}
              />
            </div>
          </div>
        </div>

        <div
          style={{
            flex: 4,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div>
            {labInPatient !== " " ? (
              <div>
                <div>
                  <CustomTable
                    tableData={labInPatient}
                    tableDataKeys={tableDataKeys}
                    tableHeading={tableHeading}
                    borderBottomColor={"#60d69f"}
                    borderBottomWidth={20}
                  />
                </div>
                <div style={{ marginTop: 20, marginBottom: 20 }}>
                  <img
                    onClick={() => props.history.goBack()}
                    src={Back}
                    style={{
                      width: 45,
                      height: 35,
                      cursor: "pointer",
                    }}
                  />
                </div>
                <Notification msg={errorMsg} open={openNotification} />
              </div>
            ) : (
              <div className="row " style={{ marginTop: "25px" }}>
                <div className="col-11">
                  <h3
                    style={{
                      color: "white",
                      textAlign: "center",
                      width: "100%",
                      position: "absolute",
                    }}
                  >
                    Opps...No Data Found
                  </h3>
                </div>
                <div className="col-1" style={{ marginTop: 45 }}>
                  <img
                    onClick={() => props.history.goBack()}
                    src={Back}
                    style={{
                      maxWidth: "60%",
                      height: "auto",
                      cursor: "pointer",
                    }}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
