import React, { useEffect, useState } from "react";
import CancelIcon from "@material-ui/icons/Cancel";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import AddIcon from "@material-ui/icons/Add";
import { containerCSStyles } from "./styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { getSearchedPharmaceuticalItemsUrl } from "../../public/endpoins";
import axios from "axios";

import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Paper from "@material-ui/core/Paper";
import Loader from "react-loader-spinner";

import {
  TextField,
  Grid,
  Button,
  Typography,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
  Checkbox,
} from "@material-ui/core";

export default function Assign(props) {
  const matches = useMediaQuery("(min-width:600px)");
  const classes = containerCSStyles();
  const [inputList1, setInputList1] = useState([]);
  const [input1, setInput1] = useState("");
  const [subInputList1, setSubInputList1] = useState([]);
  const [subInput1, setSubInput1] = useState("");
  const [error, setError] = useState("");
  const [subError, setSubError] = useState("");
  const [selectedRecordToAdd, setSelectedRecordToAdd] = useState("");
  const [selectedRecordToAddIndex, setSelectedRecordToAddIndex] = useState("");

  const [searchQuery, setSearchQuery] = useState("");
  const [itemFound, setItemFound] = useState("");
  const [itemFoundSuccessfull, setItemFoundSuccessfully] = useState(false);
  const [timer, setTimer] = useState(null);
  const [loadSearchedData, setLoadSearchedData] = useState(false);

  const [open, setOpen] = React.useState(false);

  const removeList1 = (i) => {
    const list = [...inputList1];
    list.splice(i, 1);
    setInputList1(list);
  };

  const addList1 = () => {
    if (input1 === "") {
      setError("Please enter input");
    } else if (input1.length < 3) {
      setError("Length cannot be les than three");
    } else {
      let arr = [...inputList1, { name: input1, subType: [] }];
      setInputList1(arr);
      setError("");
      setInput1("");
    }
  };

  const subRemoveList1 = (i) => {
    const list = [...subInputList1];
    list.splice(i, 1);
    setSubInputList1(list);
  };

  const subAddList1 = () => {
    if (subInput1 === "") {
      setSubError("Please enter subinput");
    } else if (subInput1.length < 3) {
      setSubError("Length cannot be les than three");
    } else {
      setSubInputList1([]);
      let arr = [...subInputList1, subInput1];
      setSubInputList1(arr);
      setSubError("");
      setSubInput1("");
    }
  };

  const selectedRecord = (item, i) => {
    setSelectedRecordToAdd(item);
    setSelectedRecordToAddIndex(i);
    handleClickOpen();
  };

  const finalAddToOuterField = () => {
    setOpen(false);

    let obj;
    if (selectedRecordToAdd.subType) {
      obj = {
        ...selectedRecordToAdd,
        subType: [...selectedRecordToAdd.subType, ...subInputList1],
      };
    } else {
      obj = {
        ...selectedRecordToAdd,
        subType: [...subInputList1],
      };
    }
    let newList = [...inputList1];
    newList[selectedRecordToAddIndex] = obj;
    setSubInputList1([]);
    setInputList1(newList);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  props.getData(inputList1);

  const handlePauseMedSearch = (e) => {
    setLoadSearchedData(true);
    clearTimeout(timer);

    const a = e.target.value.replace(/[^\w\s]/gi, "");
    setSearchQuery(a);

    setTimer(
      setTimeout(() => {
        handleSearch(a);
      }, 600)
    );
  };

  const handleSearch = (e) => {
    if (e.length >= 1) {
      axios
        .get(getSearchedPharmaceuticalItemsUrl + "/" + e)
        .then((res) => {
          if (res.data.success) {
            if (res.data.data.items.length > 0) {
              setItemFoundSuccessfully(true);
              setItemFound(res.data.data.items);
              setLoadSearchedData(false);
            } else {
              setItemFoundSuccessfully(false);
              setItemFound("");
              setLoadSearchedData(false);
            }
          }
        })
        .catch((e) => {
          console.log("error while searching medicine", e);
        });
    }
  };

  function handleAddItem(i) {
    // console.log("selected med", i);

    // dispatch({ field: "itemId", value: i._id });
    // dispatch({ field: "itemName", value: i.name });
    // dispatch({ field: "unitPrice", value: i.issueUnitCost });
    // dispatch({ field: 'totalPrice', value: i.purchasePrice + i.tax })
    // setInput1('ssdds');
    let arr = [...inputList1, { name: i.name, subType: [] }];
    setInputList1(arr);

    setSearchQuery("");
  }

  return (
    <div className={classes.root}>
      <Grid style={{ backgroundColor: "white" }}>
        <Grid container>
          <Grid item xs={12} sm container style={{ margin: 20 }}>
            <Grid item xs container direction="column">
              <Typography
                style={{ fontSize: matches ? "" : 11 }}
                color={"textSecondary"}
              >
                {props.heading}
              </Typography>
            </Grid>
            <Grid item>
              {props.heading !== "Medication" ? (
                <Button
                  className={classes.viewButtonLabel}
                  variant="contained"
                  startIcon={<AddIcon color="inherit" />}
                  size="small"
                  style={{
                    fontSize: matches ? "" : 10,
                    backgroundColor: "#2962CC",
                  }}
                  onClick={addList1}
                >
                  {props.buttonHeading}
                </Button>
              ) : (
                undefined
              )}
            </Grid>
          </Grid>
        </Grid>

        <Grid container>
          {inputList1.map((item, i) => (
            <Grid
              key={i}
              container
              wrap="nowrap"
              style={{
                marginTop: 0,
                marginBottom: 10,
                padding: 12,
                fontSize: matches ? "" : 10,
                backgroundColor: "#FBFBFB",
              }}
            >
              <Grid
                item
                style={{
                  width:
                    props.heading === "Treatment Order" || "MD Notification"
                      ? matches
                        ? "95.45%"
                        : "83%"
                      : matches
                      ? "97.45%"
                      : "89%",
                }}
              >
                <Grid container>
                  <Checkbox
                    disabled
                    inputProps={{ "aria-label": "disabled checkbox" }}
                  />

                  <Typography style={{ marginLeft: 12 }}>
                    {item.name}
                  </Typography>

                  <Grid
                    style={{
                      marginLeft: 20,
                      width: "100%",
                    }}
                    item
                  >
                    {item.subType.length === 0 ? (
                      undefined
                    ) : (
                      <div>
                        {item.subType.map((subType, j) => {
                          return (
                            <Grid container>
                              <Checkbox
                                disabled
                                inputProps={{
                                  "aria-label": "disabled checkbox",
                                }}
                              />
                              <Typography
                                key={j}
                                style={{
                                  borderRadius: 2,
                                  paddingLeft: 20,
                                }}
                              >
                                {subType}
                              </Typography>
                            </Grid>
                          );
                        })}
                      </div>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs zeroMinWidth>
                {props.subType === true ? (
                  <AddCircleIcon
                    color="primary"
                    style={{ marginLeft: matches ? " " : -6 }}
                    onClick={() => selectedRecord(item, i)}
                  />
                ) : (
                  undefined
                )}
                <CancelIcon color="error" onClick={() => removeList1(i)} />
              </Grid>
            </Grid>
          ))}
        </Grid>

        {props.heading === "Medication" ? (
          <>
            <Grid container>
              <Grid item xs={12} sm container style={{ margin: 20 }}>
                {/* <TextField
                style={{ width: "100%" }}
                value={input1}
                helperText={error}
                InputProps={{
                  className: classes.input,
                  classes: { input: classes.input },
                }}
                inputProps={{ maxLength: 40 }}
                className="textInputStyle"
                placeholder={`Please Add ${props.heading} here`}
                onChange={(e) => {
                  setInput1(e.target.value);
                }}
              /> */}

                <TextField
                  type="text"
                  label="Medicine Name"
                  name={"searchQuery"}
                  value={searchQuery}
                  onChange={handlePauseMedSearch}
                  className="textInputStyle"
                  variant="filled"
                  InputProps={{
                    className: classes.input,
                    classes: { input: classes.input },
                  }}
                />
              </Grid>
            </Grid>

            {searchQuery ? (
              <div
                style={{
                  zIndex: 3,
                  marginTop: 10,
                  width: "100%",
                }}
              >
                <Paper style={{ maxHeight: 200, overflow: "auto" }}>
                  {itemFoundSuccessfull && itemFound !== "" ? (
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell>Medicine Name</TableCell>
                          <TableCell>Scientific Name</TableCell>
                          <TableCell>Item Code</TableCell>
                          <TableCell>Unit Price (JD)</TableCell>
                          {/* <TableCell>Total Price</TableCell> */}
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {itemFound.map((i) => {
                          return (
                            <TableRow
                              key={i.itemCode}
                              onClick={() => handleAddItem(i)}
                              style={{ cursor: "pointer" }}
                            >
                              <TableCell>{i.tradeName}</TableCell>
                              <TableCell>{i.scientificName}</TableCell>
                              <TableCell>{i.itemCode}</TableCell>
                              <TableCell>
                                {i.issueUnitCost.toFixed(4)}
                              </TableCell>
                              {/* <TableCell>
                      {i.purchasePrice + i.tax}
                    </TableCell> */}
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  ) : loadSearchedData ? (
                    <div style={{ textAlign: "center" }}>
                      <Loader
                        type="TailSpin"
                        color="#2c6ddd"
                        height={25}
                        width={25}
                        style={{
                          display: "inline-block",
                          padding: "10px",
                        }}
                      />
                      <span
                        style={{
                          display: "inline-block",
                          padding: "10px",
                        }}
                      >
                        <h4> Searching Medicine...</h4>
                      </span>
                    </div>
                  ) : searchQuery && !itemFoundSuccessfull ? (
                    <div style={{ textAlign: "center", padding: "10px" }}>
                      <h4>No Medicine Found !</h4>
                    </div>
                  ) : (
                    undefined
                  )}
                </Paper>
              </div>
            ) : (
              undefined
            )}
          </>
        ) : (
          <Grid container>
            <Grid item xs={12} sm container style={{ margin: 20 }}>
              <TextField
                style={{ width: "100%" }}
                value={input1}
                helperText={error}
                InputProps={{
                  className: classes.input,
                  classes: { input: classes.input },
                }}
                inputProps={{ maxLength: 40 }}
                className="textInputStyle"
                placeholder={
                  props.heading === "Inclusion Criteria" ||
                  props.heading === "Exclusion Criteria"
                    ? `Please Add ${props.heading} Details here`
                    : `Please Add ${props.heading} here`
                }
                onChange={(e) => {
                  setInput1(e.target.value);
                }}
              />
            </Grid>
          </Grid>
        )}

        <Grid container>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">Add subfields</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Please add subfield to the array
              </DialogContentText>
              <Button
                className={classes.viewButtonLabel}
                variant="contained"
                startIcon={<AddIcon color="inherit" />}
                size="small"
                style={{ backgroundColor: "#2962CC" }}
                onClick={subAddList1}
              >
                Add subfields
              </Button>

              <TextField
                style={{ width: "100%" }}
                value={subInput1}
                helperText={subError}
                InputProps={{
                  className: classes.input,
                  classes: { input: classes.input },
                }}
                inputProps={{ maxLength: 40 }}
                className="textInputStyle"
                placeholder={"Please add subfields"}
                onChange={(e) => {
                  setSubInput1(e.target.value);
                }}
              />

              {subInputList1.map((item, i) => (
                <Grid
                  key={i}
                  container
                  wrap="nowrap"
                  style={{
                    marginLeft: 0,
                    marginTop: 2,
                    marginBottom: 10,
                    width: "100%",
                    height: 50,
                    padding: 12,
                    backgroundColor: "#F4F4F4",
                  }}
                >
                  <Grid item style={{ width: "96%" }}>
                    <Typography>{item}</Typography>
                  </Grid>
                  <Grid item xs zeroMinWidth>
                    <CancelIcon
                      color="error"
                      onClick={() => subRemoveList1(i)}
                    />
                  </Grid>
                </Grid>
              ))}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
              <Button onClick={finalAddToOuterField} color="primary">
                Add
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>
      </Grid>
    </div>
  );
}
