/* eslint-disable react/jsx-indent */
/* eslint-disable array-callback-return */
import React, { useEffect, useState, useReducer } from "react";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
// import { makeStyles } from '@material-ui/core/styles';
import Button from "@material-ui/core/Button";
import { ToastsStore } from "react-toasts";

import { Redirect } from "react-router-dom";

import Header from "../Header/Header";

import Back from "../../assets/img/Back.png";
import Home from "../../assets/img/Home.png";

import ThankYou from "../../assets/img/ThankYou.png";

import { connect } from "react-redux";
import {
  funForSaga,
  funForReducer,
  setPatientDetailsForReducer,
} from "../../actions/Checking";

var boldSentence = " ";

function AddBusinessUnit(props) {
  const [goToHome, setGoToHome] = useState(false);
  const [goBackScreen, setGoBackScreen] = useState(false);

  useEffect(() => {
    console.log(props.history.location.state.patientDetails);
    console.log(props.history.location.state.message1, "message1");
    console.log(props.history.location.state.message2, "message2");
    console.log(props.history.location.state.code, "code");
    // var str = props.history.location.state.message;
    // var a = str.split(" ");
    // var mrn = a.find(checkMRN);
    // console.log(a.find(checkMRN), "aftr split");
    // boldSentence = " ";
    // boldSentence = str.replace(`/${mrn}/gi`, `<strong>${mrn}</strong>`);

    // console.log(boldSentence, "bold Sentance");

    if (
      props.history.location.state &&
      props.history.location.state.patientDetails
    ) {
      props.setPatientDetailsForReducer(
        props.history.location.state.patientDetails
      );
    }
  }, []);

  function checkMRN(details) {
    return details.includes("KHMC");
  }

  if (goToHome) {
    // props.history.replace('', null);
    console.log(boldSentence, "bold Sentance 2");
    props.setPatientDetailsForReducer("");
    return <Redirect to="/dashboard" />;
  }

  return (
    <div
      style={{
        position: "fixed",
        display: "flex",
        width: "100%",
        height: "100%",
        flexDirection: "column",
        flex: 1,
        // backgroundImage: `url("${ThankYou}")`,
        backgroundColor: "pink",
        backgroundSize: "100%",
      }}
    >
      {/* <div style={{ alignItems: 'center', flex: 1, display: 'flex' }}> */}
      <Header history={props.history} />
      {/* </div> */}

      <div
        className="thankyou"
        style={{
          alignItems: "center",
          flex: 4,
          display: "flex",
          marginTop: -600,
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <h1
          style={{
            color: "white",
            fontWeight: "bold",
            fontSize: 55,
            marginTop: 400,
          }}
        >
          Thank You!
        </h1>
        <p
          style={{
            color: "white",
            fontSize: 24,
            maxWidth: "83%",
            textAlign: "center",
          }}
        >
          {props.history.location.state.message1 &&
          props.history.location.state.message1
            ? props.history.location.state.message1
            : undefined}
          {props.history.location.state.reqCode &&
          props.history.location.state.reqCode ? (
            <span
              style={{
                color: "white",
                fontWeight: "bold",
                paddingLeft: 2,
                paddingRight: 2,
                fontSize: 25,
              }}
            >
              {props.history.location.state.reqCode}
            </span>
          ) : (
            undefined
          )}

          <span
            style={{
              color: "white",
              fontSize: 24,
              maxWidth: "83%",
              textAlign: "center",
            }}
          >
            {props.history.location.state.message3 &&
            props.history.location.state.message3
              ?   <span className="post__content" dangerouslySetInnerHTML={{__html: props.history.location.state.message3}}></span>
              : undefined}
          </span>

          {props.history.location.state.code &&
          props.history.location.state.code ? (
            <span
              style={{
                color: "white",
                fontWeight: "bold",
                paddingLeft: 2,
                paddingRight: 2,
                fontSize: 25,
              }}
            >
              {props.history.location.state.code}
            </span>
          ) : (
            undefined
          )}

          <span
            style={{
              color: "white",
              fontSize: 24,
              maxWidth: "83%",
              textAlign: "center",
            }}
          >
            {props.history.location.state.message2 &&
            props.history.location.state.message2
              ? props.history.location.state.message2
              : undefined}
          </span>
        </p>

        {
          props.history.location.state.printBracelet ? (
            <div className="row">
              <div className="col-sm-12 text-center">
                <div className="form-group">
                  <Button
                    fullWidth
                    variant="contained"
                    color="success"
                    style={{
                      backgroundColor: "#2973CF",
                      borderRadius: 10,
                      textTransform: "none",
                    }}
                  >
                    <a
                      style={{ color: "white" }}
                      href={`/printQRBracelets?edrId=${props.history.location.state.edrId}`}
                      target="_blank"
                    >Print Bracelet</a>
                  </Button>
                </div>
              </div>
              <div className="col-sm-12 text-center">
                <div className="form-group">
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    style={{
                      backgroundColor: "#2973CF",
                      borderRadius: 10,
                      textTransform: "none",
                    }}
                    onClick={(e) => {
                        props.history.push({
                          pathname: "/dashboard/home/patientmanagement/assignDCDForm/assignCC",
                          state: {
                            selectedItem: props.history.location.state.selectedItem,
                            cameFrom: 'successPage'
                          },
                        });
                    }}
                  >
                    Assign Chief Complaint
                  </Button>
                </div>
              </div>
              <div className="col-sm-12 text-center">
                <div className="form-group">
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    style={{
                      backgroundColor: "#2973CF",
                      borderRadius: 10,
                      textTransform: "none",
                    }}
                    onClick={(e) => {
                        props.history.push({
                          pathname: "/dashboard/home/departmentmanagement/productionarea/assignpa/assignpaview",
                          state: {
                            selectedItem: props.history.location.state.selectedItem,
                          },
                        });
                    }}
                  >
                    Assign Bed / Production Team
                  </Button>
                </div>
              </div>
            </div>
          ) : undefined
        }

        {props.history.location.state.qrsx ? (
          <Button
            variant="contained"
            color="primary"
            style={{
              backgroundColor: "#2973CF",
              borderRadius: 10,
              textTransform: "none",
            }}
          >
            <a
              style={{ color: "white" }}
              href={props.history.location.state.qr}
              target="_blank"
            >
              Print Label
            </a>
          </Button>
        ) : (
          undefined
        )}

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            onClick={() => setGoToHome(true)}
            src={Home}
            style={{ maxWidth: "40%", cursor: "pointer" }}
          />

          <img
            onClick={() =>
              props.history.location.comingFor &&
              props.history.location.comingFor === "Triage"
                ? props.history.go(-2)
                : props.history.goBack()
            }
            src={Back}
            style={{ maxWidth: "40%", cursor: "pointer" }}
          />
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = ({ CheckingReducer }) => {
  const { count, patientDetails } = CheckingReducer;
  return { count, patientDetails };
};
export default connect(mapStateToProps, {
  funForReducer,
  setPatientDetailsForReducer,
})(AddBusinessUnit);
