import React, { useState, useEffect, useReducer } from "react";
import { uploadsUrl } from "../../public/endpoins";

function PatientQR(props) {

  useEffect(() => {
    console.log("Props passed ", props.history.location.state.selectedItem);

    const { selectedItem } = props.history.location.state;
    
  }, []);

  return (
    <div
      style={{
        position: "fixed",
        display: "flex",
        width: "100%",
        height: "100%",
        flexDirection: "column",
        flex: 1,
        overflowY: "scroll",
        paddingBottom: 50,
      }}
    >
      <div className="d-flex align-items-center">
      <img src={uploadsUrl + props.history.location.state.selectedItem.QR.replace('/uploads', '')} width="80" />
      <div><strong>MRN:</strong> {props.history.location.state.selectedItem.identifier[0].value}</div>
      </div>
    </div>
  );
}
export default PatientQR;
