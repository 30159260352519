import axios from "axios";
import {
  updateLabTechRequest,
  // getAllCommunicationRequests,
} from "../../public/endpoins";
import _ from "lodash";

export const addLabTechRequestCall = (
  props,
  requestId,
  formData,
  setOpenNotification,
  setErrorMsg
) => {
  axios
    .put(updateLabTechRequest, formData)
    .then((res) => {
      if (res.data.success) {
        props.history.push({
          pathname: "/dashboard/home/success",
          state: {
            reqCode: `${requestId}`,
            message3: ` has been generated successfully`,
          },
        });
      } else if (!res.data.success) {
        setOpenNotification(true);
      }
    })
    .catch((e) => {
      setOpenNotification(true);
      setErrorMsg("Error while submit Lab Service request");
    });
};

// export const getAllCommunicationRequestsCall = (
//   currentUser,
//   setCommunicationReqData,
//   setOpenNotification,
//   setErrorMsg
// ) => {
//   axios
//     .get(getAllCommunicationRequests)
//     .then((res) => {
//       if (res.data.success) {
//         const filteredRequests = res.data.data.filter(
//           (communication) => communication.generatedBy === currentUser._id
//         );
//         var sortedObjs = _.sortBy(filteredRequests, "createdAt").reverse();

//         // console.log("filteredRequests", filteredRequests)
//         // setCommunicationReqData(filteredRequests.reverse())
//         setCommunicationReqData(sortedObjs);
//       } else if (!res.data.success) {
//         setOpenNotification(true);
//       }
//     })
//     .catch((e) => {
//       setOpenNotification(true);
//       setErrorMsg("Error while getting Communciation");
//     });
// };
