import React from 'react'
import Back from '../../../assets/img/Back_Arrow.png'
import { Redirect } from 'react-router-dom'
import cookie from 'react-cookies'
import Header from '../../../components/Header/Header'
import MenuTree from '../../../components/MenuTree/MenuTree'
// Icons
import AssistanceIcon from '../../../assets/img/Assistance.png'
import AssistanceRequestedIcon from '../../../assets/img/AssistanceRequested.png'
import AssistanceBySenseiIcon from '../../../assets/img/AssistanceBy Sensei.png'

const Panel = [{ img: AssistanceIcon, text: 'Assistance' }]

const EDnursePanel = [
  { img: AssistanceIcon, text: 'Assistance' },
  {
    img: AssistanceRequestedIcon,
    text: 'Assistance Requested',
    path: 'assistance/requests',
  },
  {
    img: AssistanceBySenseiIcon,
    text: 'Assistance By Sensei',
    path: 'assistance/bySensei',
  },
]

const SenseiPanel = [
  { img: AssistanceIcon, text: 'Assistance' },
  {
    img: AssistanceRequestedIcon,
    text: 'Assistance Requested',
    path: 'assistance/requests',
  },
]

class Assistance extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      openApps: false,
      goBack: false,
      currentUser: '',
    }
  }

  componentDidMount() {
    this.setState({ currentUser: cookie.load('current_user') })
  }

  render() {
    const userType = this.state.currentUser
    if (this.state.goBack) {
      return <Redirect to={'/home'} />
    }

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          position: 'fixed',
          width: '100%',
          height: '100%',
          background:
            'linear-gradient(25deg, rgba(101,228,193,1) 0%, rgba(58,219,175,1) 33%, rgba(15,206,147,1) 66%, rgba(6,142,103,1) 100%)',
          backgroundSize: '100%',
        }}
      >
        <Header history={this.props.history} />

        <MenuTree
          history={this.props.history}
          options={
            userType.staffType === 'Nurses'
              ? userType.subType.includes('ED Nurse')
                ? EDnursePanel
                : Panel
              : userType.staffType === 'Sensei'
              ? SenseiPanel
              : Panel
          }
        />

        <div
          style={{
            position: 'fixed',
            width: '100%',
            height: '10%',
            top: '90%',
          }}
        >
          <img
            alt='Back'
            onClick={() => this.props.history.goBack()}
            src={Back}
            style={{
              width: 45,
              height: 35,
              marginLeft: '5%',
              cursor: 'pointer',
            }}
          />
        </div>
      </div>
    )
  }
}
export default Assistance
