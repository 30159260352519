import React, { useState, useEffect, useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Avatar from '@material-ui/core/Avatar'
import Tabs from '@material-ui/core/Tabs'
import MicRecorder from 'mic-recorder-to-mp3'
import MicIcon from '@material-ui/icons/Mic'
import StopIcon from '@material-ui/icons/Stop'
import InputAdornment from '@material-ui/core/InputAdornment'
import Tab from '@material-ui/core/Tab'
import Button from '@material-ui/core/Button'

import Loader from 'react-loader-spinner'
import '../../assets/jss/material-dashboard-react/components/loaderStyle.css'

import SearchIcon from '../../assets/img/Search (2).png'
import ChatIcon from '../../assets/img/Chat.png'
import Emoji from '../../assets/img/Emoji.png'
// import AttachFileIcon from "../../assets/img/Attach.png";
// import CameraAltIcon from "../../assets/img/Camera.png";
import Header from '../../components/Header/Header'
import Back_Arrow from '../../assets/img/Back_Arrow.png'
import Badge from '@material-ui/core/Badge'
import io from 'socket.io-client'
import WebRTC from '../WebRTC/webRTC'
import {
  createChat,
  uploadsUrl,
  deleteChat,
  uploadChatFile,
  socketUrl,
  socketUrlForWebRTC,
} from '../../public/endpoins'
import User from '../../components/Chat/User'
import Reciever from '../../components/Chat/Reciever'
import Sender from '../../components/Chat/Sender'
import axios from 'axios'
import cookie from 'react-cookies'
import Input from '@material-ui/core/Input'
import { DropzoneDialog } from 'material-ui-dropzone'
import { Picker } from 'emoji-mart'
import 'emoji-mart/css/emoji-mart.css'
import './chat.css'
import { setUsrRoles } from './config'

import { getUsersFromRole, getAllUsers } from './networkCallsForChat'

import CameraAltIcon from '@material-ui/icons/CameraAlt'
import AttachFileIcon from '@material-ui/icons/AttachFile'
import InsertEmoticonIcon from '@material-ui/icons/InsertEmoticon'
import SendIcon from '@material-ui/icons/Send'
import CancelIcon from '@material-ui/icons/Cancel'

import ClickPicture from '../../components/ClickPicture/clickPicture'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },

  avatar: {
    height: 60,
    width: 60,
    backgroundColor: '#F35528',
  },
  shape: {
    backgroundColor: theme.palette.primary.main,
    width: 40,
    height: 40,
  },
  shapeCircle: {
    borderRadius: '50%',
  },
  badge: {
    backgroundColor: '#FF0C0C',
    color: 'white',
    top: '13px',
    right: '-15px',
  },
  badgeImage: {
    backgroundColor: '#FF0C0C',
    color: 'white',
  },
}))

const styles = {
  styleForTabs: {
    color: 'white',
    borderRadius: 5,
    outline: 'none',
    // fontSize: "large",
    textTransform: 'none',
    width: 180,
  },
}

const Mp3Recorder = new MicRecorder({ bitRate: 128 })

export default function Chat(props) {
  const [chat, setChat] = useState([])
  const [isRecording, setIsRecording] = useState(false)
  const [blobURL, setblobURL] = useState('')
  const [message, setMessage] = useState('')
  const [isBlocked, setIsBlocked] = useState(false)
  const [sendButton, setSendButton] = useState(false)
  const [open, setOpen] = useState(false)
  const [files, setFiles] = useState([])
  const [chatId, setChatId] = useState('')
  const [search, setSearch] = useState('')
  const [emojiStatus, setEmojiStatus] = useState(false)
  const [currentUser] = useState(cookie.load('current_user'))
  const [div, setDiv] = useState(false)
  const [video, setVideo] = useState(false)
  const [value, setValue] = React.useState('')

  const [allowedUserRoles, setAllowedUserRoles] = useState([])

  const [errorMsg, setErrorMsg] = useState('')
  const [openNotification, setOpenNotification] = useState(false)

  const [staffData, setStaffData] = useState([])
  const [generalArray, setGeneralArray] = useState([])

  const [selectedUser, setSelectedUser] = useState('')
  const [selectedUserId, setSelectedUserId] = useState('')

  const [connectedUsers, setConnectedUsers] = useState('')

  const [cameraOpen, setCameraOpen] = useState(false)

  const classes = useStyles()

  var socket = io(socketUrl)

  useEffect(() => {
    socket.emit('connected', currentUser._id)
    socket.on('getConnectedUsers', (connectedUsers) => {
      setConnectedUsers(connectedUsers)
    })

    socket.on('chat_receive', (message) => {
      // console.log("message before", message.message.sentAt);
      // message.message.sentAt = convertTime();
      // console.log("message after", message);
      var audioId = document.getElementById('myAudio')
      if (audioId && message.message.receiver === currentUser._id) {
        audioId.play()
      }

      setChat((pervState) => [...pervState, message.message])
    })

    if (currentUser && currentUser.staffType) {
      const x = setUsrRoles(currentUser)
      setAllowedUserRoles(x)
      setValue(x[0].tabValue)
    }

    navigator.mediaDevices &&
      navigator.mediaDevices.getUserMedia(
        { audio: true },
        () => {
          console.log('Permission Granted')
          setIsBlocked(false)
        },
        () => {
          console.log('Permission Denied')
          setIsBlocked(true)
        }
      )

    return () => {
      socket.emit('disconnected', currentUser._id)
      socket.disconnect()
    }
  }, [])

  useEffect(() => {
    getUsersFromRole(
      setStaffData,
      setGeneralArray,
      setOpenNotification,
      setErrorMsg,
      'all'
    )
  }, [allowedUserRoles])

  console.log('generalArray', generalArray)
  console.log('allowedUserRoles', allowedUserRoles)

  function filerSingleRoleUsers(role) {
    let allSelectedRoleUsers = []

    if (role.key.split('-').length > 1) {
      generalArray.find((r) => {
        if (r.subType.includes(role.key.split('-')[1])) {
          allSelectedRoleUsers.push(r)
        }
      })
    } else {
      generalArray.find((r) => {
        if (r.staffType === role.key) {
          allSelectedRoleUsers.push(r)
        }
      })
    }
    setStaffData([...allSelectedRoleUsers])
  }

  useEffect(() => {
    if (allowedUserRoles[0]) {
      filerSingleRoleUsers(allowedUserRoles[0])
    }
  }, [generalArray])

  const messagesEndRef = useRef(null)

  const scrollToBottom = () => {
    {
      div === true &&
        messagesEndRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }

  useEffect(
    scrollToBottom,

    [chat]
  )

  const handleChange = (event, newValue) => {
    filerSingleRoleUsers(allowedUserRoles[newValue])
    setValue(newValue)
  }

  function convertTime() {
    var currentDate = new Date()
    currentDate.toLocaleTimeString(undefined, {
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    })
    var convertedDate = `${currentDate.getHours()}:${currentDate.getMinutes()}`
    return convertedDate
  }

  const sendMessage = () => {
    if (message != '') {
      let objectSendToBackend = {}
      objectSendToBackend.message = message

      objectSendToBackend.sender = currentUser._id
      objectSendToBackend.receiver = selectedUser._id
      objectSendToBackend.sentAt = new Date()
      let objectSendToBackend1 = {}
      objectSendToBackend1.chatId = chatId
      let params = {
        obj1: objectSendToBackend,
        obj2: objectSendToBackend1,
      }
      console.log('objectSendToBackend', params)
      socket.emit('chat_sent', params)
      setMessage('')
      setEmojiStatus(false)
      setSendButton(false)
    }
  }

  const camera = () => {
    setCameraOpen(true)
    // props.history.push("/dashboard/home/chat/clickpicture");
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleSave = (file) => {
    setFiles(file)
    console.log('file.type', file[0].type)
    const data = new FormData()
    data.append('file', file[0])
    axios
      .post(uploadChatFile, data)
      .then((res) => {
        console.log('RES', res.data.filename)
        // sendingChatFile(
        //   `${uploadsUrl}chats/${res.data.filename}`,
        //   file[0].type
        // );

        if (uploadsUrl.includes('/uploads')) {
          sendingChatFile(
            `${uploadsUrl}chats/${res.data.filename}`,
            file[0].type
          )
        } else {
          sendingChatFile(
            `${uploadsUrl}uploads/chats/${res.data.filename}`,
            file[0].type
          )
        }
      })
      .catch((error) => {
        console.log('ERROR', error)
      })
    console.log('files', file)
    setOpen(false)
  }

  const sendingChatFile = (fileUrl, type) => {
    let objectSendToBackend = {}
    objectSendToBackend.message = fileUrl
    objectSendToBackend.sentAt = new Date()
    objectSendToBackend.msgType = type

    objectSendToBackend.sender = currentUser._id
    objectSendToBackend.receiver = selectedUser._id
    let objectSendToBackend1 = {}
    objectSendToBackend1.chatId = chatId
    let params = {
      obj1: objectSendToBackend,
      obj2: objectSendToBackend1,
    }
    console.log('objectSendToBackend', params)
    socket.emit('chat_sent', params)
  }

  const handleOpen = () => {
    setOpen(true)
  }

  const addEmoji = (e) => {
    let sym = e.unified.split('-')
    let codesArray = []
    sym.forEach((el) => codesArray.push('0x' + el))
    let emoji = String.fromCodePoint(...codesArray)
    setMessage(message + emoji)
    setSendButton(true)
    console.log(e)
  }

  const changeEmojiStatus = () => {
    setEmojiStatus((value) => !value)
    console.log('emojiStatus', emojiStatus)
  }

  const onMessageSend = (e) => {
    setMessage(e.target.value)
    if (e.target.value === '') {
      setSendButton(false)
    } else {
      setSendButton(true)
    }
  }

  const searchOnlineUser = (e) => {
    setSearch(e.target.value)
  }

  const RenderUser = (user) => {
    if (
      search !== '' &&
      user.stafName.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) ===
        -1
    ) {
      return null
    } else {
      // if(user.name.length > 5 && search === ""){
      return (
        <User
          name={user.stafName}
          selectedUser={user}
          online={true}
          getChatHandler={onGetChatHandler}
          connectedUsers={connectedUsers}
        />
      )
    }
  }

  const onGetChatHandler = (selectedUser) => {
    setSelectedUser(selectedUser)
    setSelectedUserId(selectedUser._id)

    var obj = {}
    obj.sender = currentUser._id
    obj.receiver = selectedUser._id

    axios
      .post(createChat, obj)
      .then((res) => {
        console.log('res', res.data.data)
        setChatId(res.data.data._id)
        setChat(res.data.data.chat)
      })
      .catch((error) => {
        console.log(error)
      })
    setDiv(true)
  }

  const videoCall = () => {
    props.history.push({
      pathname: '/home/webRTC',
      state: {
        userId: '5f4ffff4277ba8b380f2ef3d',
      },
    })
  }

  const startVoice = () => {
    if (isBlocked) {
      console.log('Permission Denied')
    } else {
      Mp3Recorder.start()
        .then(() => {
          setIsRecording(true)
        })
        .catch((e) => console.error(e))
    }
  }

  const handleSaveAudio = (file) => {
    const data = new FormData()
    data.append('file', file)
    axios
      .post(uploadChatFile, data)
      .then((res) => {
        console.log('RES', res.data.filename)

        if (uploadsUrl.includes('/uploads')) {
          sendingChatFile(`${uploadsUrl}chats/${res.data.filename}`, file.type)
        } else {
          sendingChatFile(
            `${uploadsUrl}uploads/chats/${res.data.filename}`,
            file.type
          )
        }
      })
      .catch((error) => {
        console.log('ERROR', error)
      })
    console.log('files', file)
    setOpen(false)
  }

  const handlePicSend = (file) => {
    setCameraOpen(false)

    const data = new FormData()
    data.append('file', file)
    axios
      .post(uploadChatFile, data)
      .then((res) => {
        console.log('RES', res.data.filename)
        // sendingChatFile(`${uploadsUrl}chats/${res.data.filename}`, file.type);
        if (uploadsUrl.includes('/uploads')) {
          sendingChatFile(`${uploadsUrl}chats/${res.data.filename}`, file.type)
        } else {
          sendingChatFile(
            `${uploadsUrl}uploads/chats/${res.data.filename}`,
            file.type
          )
        }
      })
      .catch((error) => {
        console.log('ERROR', error)
      })
    console.log('files', file)
    setOpen(false)
  }

  const stopVoice = () => {
    Mp3Recorder.stop()
      .getMp3()
      .then(([buffer, blob]) => {
        console.log('blob', blob)
        const blobURL = URL.createObjectURL(blob)
        console.log('blob after', blobURL)
        setblobURL(blobURL)
        setIsRecording(false)
        var file = new File([blob], 'first', { type: 'audio/mp3' })
        handleSaveAudio(file)
      })
      .catch((e) => console.log(e))
  }

  const cancelVoiceNote = () => {
    Mp3Recorder.stop()
      .getMp3()
      .then(([buffer, blob]) => {
        console.log('cancelled')
        setIsRecording(false)
      })
      .catch((e) => console.log(e))
  }

  const handleKeySendMessage = (e) => {
    if (e.key === 'Enter') {
      console.log('button pressed inside')
      sendMessage()
    }
  }

  if (!cameraOpen) {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          position: 'fixed',
          width: '100%',
          height: '100%',
          backgroundColor: 'rgb(19 213 159)',
          overflowY: 'scroll',
        }}
      >
        <Header history={props.history} />

        <WebRTC userId={''} />

        <audio style={{ display: 'none' }} id='myAudio'>
          <source
            src={
              'https://notificationsounds.com/storage/sounds/file-sounds-1153-piece-of-cake.mp3'
            }
            type='audio/mpeg'
          />
        </audio>

        {generalArray.length > 0 ? (
          <>
            <div style={{ marginLeft: '26px', paddingRight: 25 }}>
              <div className='subheader' style={{ marginLeft: '-10px' }}>
                <div>
                  <img src={ChatIcon} />
                  <div
                    style={{
                      flex: 4,
                      display: 'flex',
                      alignItems: 'center',
                      marginTop: -30,
                    }}
                  >
                    <h4 style={{ color: 'white', fontWeight: '700' }}>Chat</h4>
                  </div>
                </div>
              </div>

              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Tabs
                  value={value}
                  onChange={handleChange}
                  textColor='primary'
                  TabIndicatorProps={{ style: { background: '#12387a' } }}
                  // centered
                  variant='scrollable'
                  // scrollButtons="auto"
                >
                  {allowedUserRoles &&
                    allowedUserRoles.map((role) => {
                      return (
                        <Tab
                          style={{
                            ...styles.styleForTabs,
                            color:
                              value === role.tabValue ? '#12387a' : '#3B988C',
                            width: 200,
                          }}
                          value={role.tabValue}
                          label={
                            // <Badge
                            //   color="primary"
                            //   badgeContent="2"
                            //   classes={{ badge: classes.badge }}
                            // >
                            //   {role.value}
                            // </Badge>

                            role.value
                          }
                        />
                      )
                    })}
                </Tabs>
              </div>
              <div
                className='container-fluid'
                style={{
                  backgroundColor: 'white',
                  marginTop: 15,
                  borderRadius: 5,
                }}
              >
                <div
                  className='row'
                  style={{
                    paddingTop: 20,
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <div className='col-lg-9 col-md-7 col-12' style={{}}>
                    <h6 style={{ fontWeight: 'bold' }}>All Messages</h6>
                  </div>

                  <div
                    className='col-lg-3 col-md-5 col-12'
                    style={{ display: 'flex', width: '100%' }}
                  >
                    {/* <img
                    style={{ height: 35, width: 35, marginTop: 8 }}
                    src={SearchIcon}
                  /> */}
                    <input
                      placeholder='Search User'
                      style={{
                        border: 'none',
                        width: '100%',
                        borderRadius: 5,
                        padding: 10,
                        backgroundColor: '#f1f1f1',
                      }}
                      onChange={searchOnlineUser}
                    />
                  </div>
                </div>

                <div
                  style={{
                    width: '100%',
                    overflowX: 'scroll',
                    display: 'flex',
                    paddingTop: 10,
                  }}
                >
                  {staffData.length > 0 &&
                    staffData.map((user) => {
                      return RenderUser(user)
                    })}
                </div>
              </div>

              {div ? (
                <div>
                  <div
                    style={{
                      backgroundColor: 'white',
                      marginTop: 20,
                      borderRadius: 5,
                      // padding: 20,
                      // borderTopLeftRadius: 5,
                      // borderTopRightRadius: 5,
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        padding: 10,
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'center',
                          marginTop: 5,
                        }}
                      >
                        <Avatar alt='Remy Sharp' className={classes.avatar}>
                          {selectedUser.stafName[0]}
                        </Avatar>

                        <div style={{ marginLeft: 20 }}>
                          <span style={{ marginTop: 15, fontWeight: 'bold' }}>
                            {selectedUser.stafName}
                          </span>
                          <br />
                          <span
                            style={{
                              color: '#2962CC',
                              marginTop: 0,
                              fontSize: 14,
                            }}
                          >
                            {selectedUser.staffType}
                          </span>
                        </div>
                      </div>

                      <div>
                        {/* {div ? <WebRTC userId={selectedUser._id} /> : undefined} */}
                        <WebRTC userId={selectedUserId} />
                      </div>
                    </div>

                    <hr />

                    <div id='wrapper'>
                      <div id='style-vertical'>
                        <div className='force-overflow' id={'chat_div'}>
                          {/* <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <Button
                            variant="contained"
                            color="black"
                            style={{
                              backgroundColor: "#edf3f8",
                              borderRadius: 20,
                              textTransform: "none",
                            }}
                          >
                            <span style={{ fontSize: 10 }}>Yesterday</span>
                          </Button>
                        </div> */}

                          {chat.map((msg) => {
                            return (
                              <div key={msg._id}>
                                {msg.sender === currentUser._id ? (
                                  <Sender
                                    send={msg.message}
                                    type={msg.msgType ? msg.msgType : ''}
                                    time={msg.sentAt}
                                    for={'sender'}
                                  />
                                ) : (
                                  <Sender
                                    // recieve={msg.message}
                                    send={msg.message}
                                    type={msg.msgType ? msg.msgType : ''}
                                    time={msg.sentAt}
                                    for={'receiver'}
                                  />
                                )}
                              </div>
                            )
                          })}

                          <div ref={messagesEndRef} />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    style={{
                      position: 'absolute',
                      // marginLeft:20,
                      marginTop: -432,
                      right: 21,
                    }}
                  >
                    {emojiStatus === true ? (
                      <Picker
                        onSelect={addEmoji}
                        style={{ width: 250 }}
                        showPreview={false}
                        title={''}
                      />
                    ) : (
                      undefined
                    )}
                  </div>

                  <div
                    className={`col-md-12`}
                    style={{
                      display: 'flex',
                      marginTop: 5,
                      backgroundColor: '#e8f0f6',
                      borderRadius: 5,
                      paddingTop: 15,
                      paddingBottom: 15,
                    }}
                  >
                    {!isRecording ? (
                      <Input
                        disableUnderline={true}
                        placeholder='Type a message'
                        inputProps={{ 'aria-label': 'description' }}
                        style={{
                          padding: 10,
                          height: 45,
                          backgroundColor: 'white',
                          borderRadius: 15,
                          outline: 'none',
                          width: '100%',
                        }}
                        className='InputForMessage'
                        value={message}
                        onKeyPress={handleKeySendMessage}
                        onChange={onMessageSend}
                        endAdornment={
                          <InputAdornment position='start'>
                            <CameraAltIcon
                              onClick={camera}
                              style={{
                                cursor: 'pointer',
                                color: '#13D59F',
                                fontSize: 25,
                              }}
                            />
                            <AttachFileIcon
                              onClick={handleOpen}
                              style={{
                                cursor: 'pointer',
                                color: '#13D59F',
                                fontSize: 25,
                              }}
                            />
                            <InsertEmoticonIcon
                              onClick={changeEmojiStatus}
                              style={{
                                cursor: 'pointer',
                                color: '#13D59F',
                                fontSize: 25,
                              }}
                            />
                          </InputAdornment>
                        }
                      />
                    ) : (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          // padding: 10,
                          height: 40,
                          backgroundColor: 'white',
                          borderRadius: 15,
                          outline: 'none',
                          width: '100%',
                        }}
                      >
                        <Loader
                          type='ThreeDots'
                          color='#13D59F'
                          height={40}
                          width={20}
                          // style={{ marginTop: -9 }}
                        />

                        <CancelIcon
                          style={{
                            cursor: 'pointer',
                            color: '#13D59F',
                            fontSize: 30,
                          }}
                          onClick={cancelVoiceNote}
                        />
                      </div>
                    )}

                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        paddingLeft: 5,
                      }}
                    >
                      {sendButton ? (
                        <SendIcon
                          style={{
                            color: '#13D59F',
                            fontSize: 30,
                          }}
                          onClick={sendMessage}
                        />
                      ) : isRecording ? (
                        <StopIcon
                          style={{
                            fontSize: 30,
                            color: '#13D59F',
                          }}
                          onClick={stopVoice}
                        />
                      ) : (
                        <MicIcon
                          style={{
                            color: '#13D59F',
                            fontSize: 30,
                          }}
                          onClick={startVoice}
                        />
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                undefined
              )}
            </div>

            <div className='col-1' style={{ marginTop: 45, marginLeft: 16 }}>
              <img
                onClick={() => props.history.goBack()}
                src={Back_Arrow}
                style={{ maxWidth: '60%', height: 'auto', cursor: 'pointer' }}
              />
            </div>
          </>
        ) : (
          <div className='LoaderStyle'>
            <Loader type='TailSpin' color='red' height={50} width={50} />
          </div>
        )}

        <DropzoneDialog
          open={open}
          filesLimit={1}
          onSave={handleSave}
          acceptedFiles={[
            'image/jpeg',
            'image/png',
            'image/bmp',
            'application/pdf',
            'application/msword',
            'audio/mpeg',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            'video/mp4',
            'application/vnd.ms-powerpoint',
            'application/vnd.openxmlformats-officedocument.presentationml.presentation',
            'application/vnd.ms-excel',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          ]}
          showPreviews={true}
          maxFileSize={10000000}
          onClose={handleClose}
        />
      </div>
    )
  } else {
    return (
      <ClickPicture
        handlePicSend={handlePicSend}
        setCameraOpen={setCameraOpen}
      />
    )
  }
}
