import React, { useState, useEffect, useReducer } from "react";
import Notification from "../../components/Snackbar/Notification.js";
import CustomTable from "../../components/Table/Table";
import axios from "axios";
import EnableDisableModal from "../../components/EnableDisableModal/enableDisableModal";
import ButtonField from "../../components/common/Button";
import {
  createNewRoom,
  getRooms,
  disableRoom,
  enableRoom,
} from "../../public/endpoins";
import Loader from "react-loader-spinner";
import Back from "../../assets/img/Back_Arrow.png";
import Header from "../../components/Header/Header";
import roomMgmtIcon from "../../assets/img/Room Management.png";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import "../../assets/jss/material-dashboard-react/components/loaderStyle.css";
import Button from "@material-ui/core/Button";
import plus_icon from "../../assets/img/Plus.png";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import cookie from "react-cookies";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const tableHeading = [
  "Room ID",
  "Room No.",
  "No. Of Beds",
  "Date/Time",
  "Action",
];
const tableDataKeys = ["roomId", "roomNo", "noOfBeds", "updatedAt"];

const styles = {
  textFieldPadding: {
    paddingLeft: 5,
    paddingRight: 5,
  },
  stylesForButton: {
    color: "white",
    cursor: "pointer",
    borderRadius: 5,
    background: "#2c6ddd",
    width: "140px",
    height: "50px",
    outline: "none",
  },
  stylesForCancelButton: {
    color: "gray",
    cursor: "pointer",
    borderRadius: 5,
    width: "140px",
    height: "50px",
    background: "transparent",
    border: "1px solid gray",
  },
};

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(0),
  },
  input: {
    backgroundColor: "white",
    boxShadow: "none",
    borderRadius: 5,
    "&:after": {
      borderBottomColor: "black",
      boxShadow: "none",
    },
    "&:hover": {
      backgroundColor: "white",
      boxShadow: "none",
    },
    "&:focus": {
      backgroundColor: "white",
      boxShadow: "none",
      borderRadius: 5,
    },
  },
  multilineColor: {
    boxShadow: "none",
    backgroundColor: "white",
    borderRadius: 5,
    "&:hover": {
      backgroundColor: "white",
      boxShadow: "none",
    },
    "&:after": {
      borderBottomColor: "black",
      boxShadow: "none",
    },
    "&:focus": {
      boxShadow: "none",
    },
  },
  root: {
    "& .MuiTextField-root": {
      backgroundColor: "white",
    },
    "& .Mui-focused": {
      backgroundColor: "white",
      color: "black",
      boxShadow: "none",
    },
    "& .Mui-disabled": {
      backgroundColor: "white",
      color: "gray",
    },
    "&:focus": {
      backgroundColor: "white",
      boxShadow: "none",
    },
    "& .MuiFormLabel-root": {
      fontSize: "12px",
      paddingRight: "50px",
    },
  },
}));

const actions = { enableDisable: true };

export default function RoomManagement(props) {
  const matches = useMediaQuery("(min-width:600px)");
  const initialState = {
    noOfBeds: "",
    reason: "",
  };

  function reducer(state, { field, value }) {
    return {
      ...state,
      [field]: value,
    };
  }

  const [state, dispatch] = useReducer(reducer, initialState);

  const { noOfBeds, reason } = state;

  const onChangeValue = (e) => {
    dispatch({ field: e.target.name, value: e.target.value });
  };

  const classes = useStyles();
  const [currentUser, setcurrentUser] = useState("");
  const [roomData, setroomData] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setsuccessMsg] = useState("");
  const [openNotification, setOpenNotification] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [openEnableDisabledModal, setOpenEnableDisabledModal] = useState(false);
  const [room, setRoom] = useState("");

  if (openNotification) {
    setTimeout(() => {
      setOpenNotification(false);
      setErrorMsg("");
      setsuccessMsg("");
    }, 2000);
  }

  useEffect(() => {
    setcurrentUser(cookie.load("current_user"));
    getRoomData();
  }, []);

  function getRoomData() {
    axios
      .get(getRooms)
      .then((res) => {
        if (res.data.success) {
          console.log(res.data.data, "get rooms");
          setroomData(res.data.data.reverse());
        } else if (!res.data.success) {
          setErrorMsg(res.data.error);
          setOpenNotification(true);
        }
        return res;
      })
      .catch((e) => {
        console.log("error: ", e);
      });
  }

  function enableDisableRecord(room) {
    setOpenEnableDisabledModal(true);
    setRoom(room);
  }

  const handleDisableRoom = () => {
    const params = {
      staffId: currentUser._id,
      reason: reason,
    };
    let url = !room.disabled ? disableRoom : enableRoom;
    axios
      .put(url + "/" + room._id, params)
      .then((res) => {
        if (res.data.success) {
          setOpenNotification(true);
          setsuccessMsg(
            `Room ${!room.disabled ? "disabled" : "enabled"} successfully`
          );
          getRoomData();
        } else if (!res.data.success) {
          setOpenNotification(true);
          setErrorMsg(res.data.data);
        }
      })
      .catch((e) => {
        console.log("error after updating access level", e);
        setOpenNotification(true);
        setErrorMsg("Error while editing the access level");
      });
    setOpenEnableDisabledModal(false);
  };

  const addNewRoom = () => {
    setModalVisible(true);
  };

  const handleAddRoom = () => {
    if (noOfBeds !== '1') {
      setModalVisible(false);
      setOpenNotification(true);
      setErrorMsg("One bed can be added in one room");
      return;
    }

    let params = {
      noOfBeds,
    };
    axios
      .post(createNewRoom, params)
      .then((res) => {
        if (res.data.success) {
          setModalVisible(false);
          console.log("Response ", res.data.data);
          setOpenNotification(true);
          setsuccessMsg("Bed Added Successfully");
          getRoomData();
        } else if (!res.data.success) {
          setModalVisible(false);
          setOpenNotification(true);
          setErrorMsg("Cannot Add Bed");
        }
      })
      .catch((e) => {
        console.log("error after adding Bed", e);
        setOpenNotification(true);
        setErrorMsg('ED beds cannot be created more than 14');
      });
  };

  const onModalHide = () => {
    dispatch({ field: 'noOfBeds', value: '' })
    setModalVisible(false)
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        position: "fixed",
        width: "100%",
        height: "100%",
        backgroundColor: "rgb(19 213 159)",
        overflowY: "scroll",
      }}
    >
      <Header history={props.history} />

      <div className="cPadding">
        <div className="subheader">
          <div>
            <img alt="Room Icon" src={roomMgmtIcon} />
            <h4>Room Management</h4>
          </div>
          <ButtonField onClick={addNewRoom} name="add" />
        </div>

        <div
          // className="container-fluid"
          style={{
            flex: 4,
            display: "flex",
            flexDirection: "column",
          }}
        >
          {roomData && roomData.length > 0 ? (
            <div>
              <div>
                <CustomTable
                  tableData={roomData}
                  tableDataKeys={tableDataKeys}
                  tableHeading={tableHeading}
                  action={actions}
                  enableDisableRecord={enableDisableRecord}
                  borderBottomColor={"#60d69f"}
                  borderBottomWidth={20}
                />
              </div>
              <div style={{ marginTop: 20, marginBottom: 20 }}>
                <img
                  alt="Back Icon"
                  onClick={() => props.history.goBack()}
                  src={Back}
                  style={{
                    width: 45,
                    height: 35,
                    cursor: "pointer",
                  }}
                />
              </div>
            </div>
          ) : roomData && roomData.length === 0 ? (
            <div className="row " style={{ marginTop: "25px" }}>
              <div className="col-md-12 col-sm-12 col-12">
                <h3
                  style={{
                    color: "white",
                    textAlign: "center",
                    width: "100%",
                    position: "absolute",
                  }}
                >
                  Oops! No Rooms Found
                </h3>
              </div>
              <div
                className="col-md-12 col-sm-12 col-12"
                style={{ marginTop: 45 }}
              >
                <img
                  alt="Back Icon"
                  onClick={() => props.history.goBack()}
                  src={Back}
                  style={{ maxWidth: "60%", height: "auto", cursor: "pointer" }}
                />
              </div>
            </div>
          ) : (
                <>
                  <div className="row">
                    <div className="LoaderStyle">
                      <Loader type="TailSpin" color="red" height={50} width={50} />
                    </div>
                  </div>
                  <div className="row">
                    <div
                      className="col-md-12 col-sm-12 col-12"
                      style={{ marginTop: 45 }}
                    >
                      <img
                        alt="Back Icon"
                        onClick={() => props.history.goBack()}
                        src={Back}
                        style={{
                          maxWidth: "60%",
                          height: "auto",
                          cursor: "pointer",
                        }}
                      />
                    </div>
                  </div>
                </>
              )}
        </div>

        <Dialog
          onClose={() => setModalVisible(false)}
          fullWidth={true}
          maxWidth={"lg"}
          bodyStyle={{ backgroundColor: "red" }}
          contentStyle={{ backgroundColor: "red" }}
          aria-labelledby="simple-dialog-title"
          open={modalVisible}
        >
          <DialogContent>
            <div className="container-fluid">
              <div className="row">
                <div className="d-flex justify-content-start">
                  <DialogTitle
                    id="simple-dialog-title"
                    style={{ marginLeft: "-12px" }}
                  >
                    <h4 style={{ fontSize: matches ? " " : 20 }}>
                      Add New Room
                    </h4>
                  </DialogTitle>
                </div>
              </div>

              <div className={`row ${classes.root}`}>
                <div
                  className="col-md-12"
                  style={{
                    ...styles.inputContainerForTextField,
                    ...styles.textFieldPadding,
                  }}
                >
                  <TextField
                    required
                    type="number"
                    label="No. Of Total Beds"
                    name={"noOfBeds"}
                    value={noOfBeds}
                    onChange={onChangeValue}
                    className="textInputStyle"
                    variant="filled"
                    InputProps={{
                      className: classes.input,
                      classes: { input: classes.input },
                      disableUnderline: true,
                    }}
                  />
                </div>
              </div>

              <div style={{ display: "flex", justifyContent: "center" }}>
                <div
                  style={{
                    display: "flex",
                    marginTop: "2%",
                    marginBottom: "2%",
                  }}
                >
                  <Button
                    style={{
                      ...styles.stylesForCancelButton,
                      width: matches ? 140 : 100,
                      height: matches ? 50 : 40,
                    }}
                    onClick={() => onModalHide()}
                    variant="contained"
                  >
                    Cancel
                  </Button>
                  &nbsp;&nbsp;
                  <Button
                    style={{
                      ...styles.stylesForButton,
                      width: matches ? 140 : 100,
                      height: matches ? 50 : 40,
                    }}
                    onClick={handleAddRoom}
                    variant="contained"
                  >
                    Submit
                  </Button>
                </div>
              </div>
            </div>
          </DialogContent>

          <Notification
          msg={errorMsg}
          success={successMsg}
          open={openNotification}
        />
        </Dialog>

        {openEnableDisabledModal && room ? (
          <EnableDisableModal
            handleClose={setOpenEnableDisabledModal}
            handleConfirmDisable={handleDisableRoom}
            disabled={room.disabled}
          />
        ) : (
            undefined
          )}

<Notification
          msg={errorMsg}
          success={successMsg}
          open={openNotification}
        />
      </div>
    </div>
  );
}
