/* eslint-disable react/jsx-indent */
/* eslint-disable array-callback-return */
import Notification from "../../components/Snackbar/Notification.js";
import React, { useEffect, useState, useReducer } from "react";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
// import { makeStyles } from '@material-ui/core/styles';
import Button from "@material-ui/core/Button";
import { ToastsStore } from "react-toasts";

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';


import { Redirect } from "react-router-dom";

import Header from "../../components/Header/Header";

import Back from "../../assets/img/Back.png";
import Home from "../../assets/img/Home.png";

import ThankYou from "../../assets/img/ThankYou.png";

import { connect } from "react-redux";
import {
  funForSaga,
  funForReducer,
  setPatientDetailsForReducer,
} from "../../actions/Checking";

import { getSingleEdr } from '../../public/endpoins'
import axios from 'axios'
import { displayInHumanDifference, replacePatientIdIfNull } from "../../utils/commonFunc";

var boldSentence = " ";

function EpisodeRegistrationSuccess(props) {
  const [goToHome, setGoToHome] = useState(false);
  const [goBackScreen, setGoBackScreen] = useState(false);
  const [edrId, setEdrId] = useState("");
  const [edrData, setEdrData] = useState(null);

  const [errorMsg, setErrorMsg] = useState("")
  const [openNotification, setOpenNotification] = useState(false)

  if (openNotification) {
    setTimeout(() => {
      setOpenNotification(false)
      setErrorMsg("")
    }, 2000)
  }

  useEffect(() => {
    // EDR ID

    let edrIds = props.history.location.state.edrId;
    setEdrId(edrIds);


    console.log(props.history.location.state.patientDetails);
    console.log(props.history.location.state.message1, "message1");
    console.log(props.history.location.state.message2, "message2");
    console.log(props.history.location.state.code, "code");
    // var str = props.history.location.state.message;
    // var a = str.split(" ");
    // var mrn = a.find(checkMRN);
    // console.log(a.find(checkMRN), "aftr split");
    // boldSentence = " ";
    // boldSentence = str.replace(`/${mrn}/gi`, `<strong>${mrn}</strong>`);

    // console.log(boldSentence, "bold Sentance");

    if (
      props.history.location.state &&
      props.history.location.state.patientDetails
    ) {
      props.setPatientDetailsForReducer(
        props.history.location.state.patientDetails
      );
    }
  }, []);

  useEffect(() => {
    if (edrId){
      axios
      .get(`${getSingleEdr}/${edrId}`)
      .then((res) => {
        let dataReturn = replacePatientIdIfNull(res.data.data);
        console.log('singleEdr', dataReturn);
        setEdrData(dataReturn);
        return res;
      })
      .catch((e) => {
        console.log("getPatientEdrDetails:: error: ", e);
      });
    }
  }, [edrId])

  function checkMRN(details) {
    return details.includes("KHMC");
  }

  if (goToHome) {
    // props.history.replace('', null);
    console.log(boldSentence, "bold Sentance 2");
    props.setPatientDetailsForReducer("");
    return <Redirect to="/dashboard" />;
  }

  return (
    <div
      style={{
        position: "fixed",
        display: "flex",
        width: "100%",
        height: "100%",
        flexDirection: "column",
        flex: 1,
        // backgroundImage: `url("${ThankYou}")`,
        backgroundColor: "pink",
        backgroundSize: "100%",
      }}
    >
      {/* <div style={{ alignItems: 'center', flex: 1, display: 'flex' }}> */}
      <Header history={props.history} />
      {/* </div> */}

      <div
        className="thankyou"
        style={{
          alignItems: "center",
          flex: 4,
          display: "flex",
          marginTop: -600,
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <h1
          style={{
            color: "white",
            fontWeight: "bold",
            fontSize: 55,
            marginTop: 400,
          }}
        >Thank You!</h1>
        <p
          style={{
            color: "white",
            fontSize: 24,
            maxWidth: "83%",
            textAlign: "center",
          }}
        >{
          props.history.location.state.mode === "chiefComplaint" ?
            "Chief Complaint for Patient has successfully assigned." :
            props.history.location.state.mode === "bedRoomAssigned" ?
              "Bed and Production Team assigned to patient.":
              props.history.location.state.mode === "ccAssigned" ?
                "Customer Care Officer has successfully assigned.":
                "Episode Number for Patient has been generated successfully."
        }</p>
        <div className="row">
          <div className="col-md-6 offset-md-3 col-lg-8 offset-lg-2">
            <div className="card">
              <div className="card-body" style={{
                color: "black",
                fontSize: 24,
                textAlign: "center",
              }}><strong>Episode #:</strong><br />{props.history.location.state.code}
              </div>
              
              <div className="card-body pt-0">
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  style={{
                    backgroundColor: "#2973CF",
                    borderRadius: 10,
                    textTransform: "none",
                  }}
                  onClick={() => {
                    // 👇 Open link in new tab programmatically
                    window.open(`/printQRBracelets?edrId=${props.history.location.state.edrId}`, '_blank', 'noreferrer');
                  }}
                >Print Bracelet</Button>
                {
                edrData ? 
                <div>
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    style={{
                      backgroundColor: "#2973CF",
                      borderRadius: 10,
                      textTransform: "none",
                    }}
                    className="mt-2"
                    startIcon={edrData && edrData.chiefComplaint.length > 0 ? <CheckCircleIcon />: <RadioButtonUncheckedIcon />}
                    // disabled={edrData && edrData.chiefComplaint.length > 0}
                    onClick={(e) => {
                      if (edrData.chiefComplaint.length > 0){
                        setOpenNotification(true)
                        setErrorMsg('Chief Complaint is already assigned.');
                      }else{
                        props.history.push({
                          pathname: "/dashboard/home/patientmanagement/assignDCDForm/assignCC",
                          state: {
                            selectedItem: props.history.location.state.selectedItem,
                            cameFrom: 'successPage'
                          },
                        });
                      }
                    }}
                  >Assign Chief Complaint</Button>
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    style={{
                      backgroundColor: "#2973CF",
                      borderRadius: 10,
                      textTransform: "none",
                    }}
                    // disabled={edrData && edrData.productionTeamId && edrData.productionTeamId.length > 0}
                    startIcon={edrData && edrData.productionTeamId && edrData.productionTeamId.length > 0 ? <CheckCircleIcon />: <RadioButtonUncheckedIcon />}
                    onClick={(e) => {
                      if (edrData.productionTeamId && edrData.productionTeamId.length > 0){
                        setOpenNotification(true)
                        setErrorMsg('Production Bed / Team is already assigned.');
                      }else{
                        if (edrData.chiefComplaint.length <= 0){
                          setOpenNotification(true)
                          setErrorMsg('Please assign the Chief Complaint first.');
                        }else{
                          console.log('propsViewAssignPA', {
                            selectedItem: props.history.location.state.selectedItem,
                            cameFrom: 'successPage'
                          })
                          props.history.push({
                            pathname: "/dashboard/home/departmentmanagement/productionarea/assignpa/assignpaview",
                            state: {
                              selectedItem: props.history.location.state.selectedItem,
                              cameFrom: 'successPage'
                            },
                          });
                        }
                      }
                    }}
                    className="mt-2"
                  >Assign Bed / Production Team</Button>
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    style={{
                      backgroundColor: "#2973CF",
                      borderRadius: 10,
                      textTransform: "none",
                    }}
                    startIcon={edrData && edrData.customerCare && edrData.customerCare.length > 0 ? <CheckCircleIcon />: <RadioButtonUncheckedIcon />}
                    onClick={(e) => {
                      if (edrData.customerCare.length > 0){
                        setOpenNotification(true)
                        setErrorMsg('Customer Care is already assigned.');
                      }else{

                        if (edrData.chiefComplaint.length <= 0){
                          setOpenNotification(true)
                          setErrorMsg('Please assign the Chief Complaint first.');
                        }else{

                          if (!edrData.productionTeamId || ( edrData.productionTeamId && edrData.productionTeamId.length <= 0)){
                            setOpenNotification(true)
                            setErrorMsg('Please assign the Production Bed / Team first.');
                          }else{
                            console.log('propsViewAssignPA', {
                              selectedItem: props.history.location.state.selectedItem,
                              cameFrom: 'successPage'
                            })

                            props.history.push({
                              pathname: "/dashboard/home/patientmanagement/customerCare/assignCustomerC",
                              state: {
                                selectedItem: props.history.location.state.selectedItem,
                                cameFrom: 'successPage'
                              },
                            });
                          }
                        }
                      }
                    }}
                    className="mt-2"
                  >Assign Customer Experience Officer</Button>
                </div>
                :undefined
                }
              </div> 
            </div>
          </div>
        </div>

          

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            onClick={() => setGoToHome(true)}
            src={Home}
            style={{ maxWidth: "40%", cursor: "pointer" }}
          />
        </div>


        <Notification msg={errorMsg} open={openNotification} />
      </div>
    </div>
  );
}

const mapStateToProps = ({ CheckingReducer }) => {
  const { count, patientDetails } = CheckingReducer;
  return { count, patientDetails };
};
export default connect(mapStateToProps, {
  funForReducer,
  setPatientDetailsForReducer,
})(EpisodeRegistrationSuccess);
