import axios from "axios";
import _ from "lodash";
import {
  getExternalConsultatntsCases,
  getSearchExternalConsultatntsCases,
} from "../../public/endpoins";

export const getExternalConsultantData = (
  setOriginalExternalConsultantData,
  setDoctorTypes
) => {
  axios
    .get(getExternalConsultatntsCases)
    .then((res) => {
      const { data } = res.data;
      const mappedData = toMapData(data);
      setOriginalExternalConsultantData(mappedData);
      const dropdownItems = mappedData.map(({ Speciality }) => Speciality);
      const uniqueDoctorSpecialities = [...new Set(["None", ...dropdownItems])];
      setDoctorTypes(uniqueDoctorSpecialities);
    })
    .catch((e) => {
      console.log("error: ", e);
    });
};

export const getSearchedConsultant = (
  a,
  setOriginalExternalConsultantData,
  setDoctorSpecialities
) => {
  axios
    .get(getSearchExternalConsultatntsCases + "/" + a)
    .then((res) => {
      const { data } = res.data;
      const mappedData = toMapData(data);
      setOriginalExternalConsultantData(mappedData);
      setOriginalExternalConsultantData(mappedData);
    })
    .catch((err) => {
      console.log("Error after searching patient request", err);
    });
};

const toMapData = (data) => {
  return data.map(
    (
      {
        chiefComplaint: [
          {
            chiefComplaintId: {
              productionArea: [
                {
                  productionAreaId: { paName },
                },
              ],
            },
          },
        ],
        consultationNote: {
          consultant: {
            identifier: [{ value }],
            name: [{ given, family }],
          },
          consultationNo,
          speciality,
          notes,
        },
      },
      index
    ) => ({
      "Doctor Id": value,
      Speciality: speciality,
      "Doctor Name": given[0] + " " + family,
      "Production Area": paName,
      "Consultation No": consultationNo,
      Action: notes,
    })
  );
};
