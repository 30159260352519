import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import TextField from "@material-ui/core/TextField";

export default function EnableDisableModal(props) {
  const [reason, setReason] = useState("");
  return (
    <div>
      <Dialog
        open={true}
        onClose={props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth={"md"}
      >
        <DialogTitle id="alert-dialog-title">
          {"Block/Disabled Record"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do you want to block or disabled the record?
          </DialogContentText>
          <TextField
            required
            fullWidth
            label="Reason"
            name={"given"}
            value={reason}
            onChange={(e) => setReason(e.target.value)}
            variant="filled"
          />{" "}
        </DialogContent>
        <DialogActions style={{ display: "flex", justifyContent: "center" }}>
          <Button
            style={{ opacity: "70%" }}
            variant="outlined"
            onClick={() => props.handleClose()}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color={"primary"}
            onClick={() => props.handleConfirmDisable(reason)}
            color="primary"
            autoFocus
            disabled={reason ? false : true}
          >
            {!props.disabled ? "Block" : "Unblock"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
