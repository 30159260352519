import React from "react";
import PropTypes from "prop-types";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import VideoCallIcon from "@material-ui/icons/VideoCall";
import CallIcon from "@material-ui/icons/Call";
import CallEndIcon from "@material-ui/icons/CallEnd";

function CallModal({ status, callFrom, startCall, rejectCall, config }) {
  const acceptWithVideo = (video) => {
    const config = { audio: true, video };
    return () => startCall(false, callFrom, config);
  };

  return (
    <>
      <Dialog
        open={status}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="Incoming Call">{`${callFrom} is calling`}</DialogTitle>

        <DialogActions style={{ display: "flex", justifyContent: "center" }}>
          {/* {config && config.video ? ( */}
            <VideoCallIcon
              onClick={acceptWithVideo(true)}
              color="primary"
              style={{ fontSize: 40 }}
            />
          {/* ) : (
            undefined
          )} */}

            <CallIcon
              onClick={acceptWithVideo(false)}
              color="primary"
              style={{ fontSize: 40 }}
            />
      

          <CallEndIcon
            onClick={rejectCall}
            color="secondary"
            style={{ fontSize: 40 }}
          />
        </DialogActions>
      </Dialog>
    </>
  );
}

CallModal.propTypes = {
  status: PropTypes.string.isRequired,
  callFrom: PropTypes.string.isRequired,
  startCall: PropTypes.func.isRequired,
  rejectCall: PropTypes.func.isRequired,
};

export default CallModal;
