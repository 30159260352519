import axios from "axios";
import {
  reportsHKDashboardURL,
  reportsHKGraphsDashboardURL,
} from "../../../public/endpoins";

export function getGraphsHousekeeping(
  setGraphDataHouseKeeping,
  setErrorMsg,
  setOpenNotification
) {
  axios
    .get(reportsHKGraphsDashboardURL)
    .then((res) => {
      if (res.data.success) {
        console.log("Graph Data of House keeping", res.data);
        setGraphDataHouseKeeping(res.data.cleaningPerHour);
        console.log(
          "Graph Data of House keeping",
          setGraphDataHouseKeeping(res.data.cleaningPerHour)
        );
      } else if (!res.data.success) {
        setErrorMsg(res.data.error);
        setOpenNotification(true);
      }
      return res;
    })
    .catch((e) => {
      console.log("error while graph data", e);
    });
}

export function getHKReports(
  setPendingCellsCleaning,
  setTimePendingCellsCleaning,
  setTimePendingCellsCleaningColor,
  setAverageNoofBeds,
  setTimeAverageNoofBeds,
  setCumulativeTotalBedsCleaned,
  setPendingCellsCleaningColor,
  setCumulativeTotalBedsCleanedColor,
  setAverageNoofBedsColor,
  setOpenNotification,
  setErrorMsg
) {
  axios
    .get(reportsHKDashboardURL)
    .then((res) => {
      if (res.data.success) {
        console.log("House keeping data", res.data);
        if (
          res.data.pendingRoom.totalPending >= 0 &&
          res.data.pendingRoom.totalPending <= 4
        ) {
          setPendingCellsCleaning(res.data.pendingRoom.totalPending);
          setPendingCellsCleaningColor("#13D59F");
        } else if (
          res.data.pendingRoom.totalPending >= 5 &&
          res.data.pendingRoom.totalPending <= 9
        ) {
          setPendingCellsCleaning(res.data.pendingRoom.totalPending);
          setPendingCellsCleaningColor("#FFBC28");
        } else if (
          res.data.pendingRoom.totalPending >= 10 &&
          res.data.pendingRoom.totalPending <= 14
        ) {
          setPendingCellsCleaning(res.data.pendingRoom.totalPending);
          setPendingCellsCleaningColor("#FF0000");
        } else {
          setPendingCellsCleaning(res.data.pendingRoom.totalPending);
        }
        //set color for TAT for  PendingCellsCleaning
        if (
          res.data.pendingRoom.averageRoomTAT >= 0 &&
          res.data.pendingRoom.averageRoomTAT <= 5
        ) {
          setTimePendingCellsCleaning(res.data.pendingRoom.averageRoomTAT);
          setTimePendingCellsCleaningColor("#13D59F");
        } else if (
          res.data.pendingRoom.averageRoomTAT >= 6 &&
          res.data.pendingRoom.averageRoomTAT <= 10
        ) {
          setTimePendingCellsCleaning(res.data.pendingRoom.averageRoomTAT);
          setTimePendingCellsCleaningColor("#FFBC28");
        } else if (res.data.pendingRoom.averageRoomTAT >= 11) {
          setTimePendingCellsCleaning(res.data.pendingRoom.averageRoomTAT);
          setTimePendingCellsCleaningColor("#FF0000");
        } else {
          setTimePendingCellsCleaning(res.data.pendingRoom.averageRoomTAT);
        }
        //setTimePendingCellsCleaning(res.data.pendingRoom.averageRoomTAT);
        if (
          res.data.completeRoom.totalCompleted >= 0 &&
          res.data.completeRoom.totalCompleted <= 2
        ) {
          setAverageNoofBeds(res.data.completeRoom.totalCompleted);
          setAverageNoofBedsColor("#13D59F");
        } else if (
          res.data.completeRoom.totalCompleted >= 3 &&
          res.data.completeRoom.totalCompleted <= 4
        ) {
          setAverageNoofBeds(res.data.completeRoom.totalCompleted);
          setAverageNoofBedsColor("#FFBC28");
        } else if (res.data.completeRoom.totalCompleted >= 5) {
          setAverageNoofBeds(res.data.completeRoom.totalCompleted);
          setAverageNoofBedsColor("#FF0000");
        } else {
          setAverageNoofBeds(res.data.completeRoom.totalCompleted);
        }
        //setAverageNoofBeds(res.data.completeRoom.totalCompleted);

        setTimeAverageNoofBeds(res.data.completeRoom.averageCompleteRoomTAT);

        if (
          res.data.pendingRoom.totalPending >= 0 &&
          res.data.pendingRoom.totalPending <= 40
        ) {
          setCumulativeTotalBedsCleaned(res.data.totalCleanedBeds);
          setCumulativeTotalBedsCleanedColor("#13D59F");
        } else if (
          res.data.pendingRoom.totalPending >= 41 &&
          res.data.pendingRoom.totalPending <= 60
        ) {
          setCumulativeTotalBedsCleaned(res.data.totalCleanedBeds);
          setCumulativeTotalBedsCleanedColor("#FFBC28");
        } else if (res.data.pendingRoom.totalPending >= 61) {
          setCumulativeTotalBedsCleaned(res.data.totalCleanedBeds);
          setCumulativeTotalBedsCleanedColor("#FF0000");
        } else {
          setCumulativeTotalBedsCleaned(res.data.totalCleanedBeds);
        }

        // setCumulativeTotalBedsCleaned(res.data.totalCleanedBeds);
      } else if (!res.data.success) {
        setErrorMsg(res.data.error);
        setOpenNotification(true);
      }
    })
    .catch((e) => {
      console.log("error: ", e);
    });
}
