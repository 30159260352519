import React, { useEffect, useState, useReducer, forwardRef } from "react";
import Notification from "../../components/Snackbar/Notification.js"
import CustomTable from "../../components/Table/Table"
import axios from "axios"
import _ from "lodash"
import ButtonField from "../../components/common/Button";
import Button from "@material-ui/core/Button"
import { getCCPatients, SearchEDRPatients, createProductionTeam, updateProductionTeam } from "../../public/endpoins"
import Back from "../../assets/img/Back_Arrow.png"
import Header from "../../components/Header/Header"
import Pending_Patients from "../../assets/img/Production Area.png";
import Fingerprint from "../../assets/img/fingerprint.png";
import AccountCircle from "@material-ui/icons/SearchOutlined";
import InputAdornment from "@material-ui/core/InputAdornment";
import BarCode from "../../assets/img/Bar Code.png";
import TextField from "@material-ui/core/TextField";
import "../../assets/jss/material-dashboard-react/components/loaderStyle.css";
import socketIOClient from "socket.io-client";
import useStylesForInput from "../../assets/jss/material-dashboard-react/inputStyle";
import cookie from "react-cookies";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Autocomplete from "@material-ui/lab/Autocomplete";

import ErrorMessage from "../../components/ErrorMessage/errorMessage";

import { getProductionTeam, getAllProductionTeams, getAllProductionStaff } from "./NetworkCall";
import styles from "./styles";
const tableHeading = [
  "Team Name",
  "No of Doctors",
  "No of Nurses",
  "Number of Nurse Technician",
  "Action",
];
const tableDataKeys = ["name", "noDoctors", "noNurses", "noNurseTechnicians"];

const actions = { edit: true };

export default function CreateUpdateProductionTeam(props) {
  const matches = useMediaQuery("(min-width:600px)");
  const classes = useStylesForInput();

  const initialState = {
    teamName: "",
    doctors: [],
    nurses: [],
    nurseTechnicians: [],
    generatedBy: null,
    updatedBy: null,
    // errorMsg: "",
    successMsg: "",
  };

  function reducer(state, { field, value }) {
    return {
      ...state,
      [field]: value,
    };
  }

  const [state, dispatch] = useReducer(reducer, initialState);

  const {
    teamName,
    doctors,
    nurses,
    nurseTechnicians,
    generatedBy,
    updatedBy,
    // errorMsg,
    successMsg,
  } = state;


  const [currentUser] = useState(cookie.load('current_user'))
  const [data, setData] = useState([]);
  const [errorMsg, setErrorMsg] = useState("");
  const [openNotification, setOpenNotification] = useState(false);
  const [searchPatientQuery, setSearchPatientQuery] = useState("");
  const [detailsForm, setDetailsForm] = useState(false);
  const [doctorsList, setDoctorsList] = useState([]);
  const [nursesList, setNursesList] = useState([]);
  const [nursesTechniciansList, setNurseTechniciansList] = useState([]);
  const [doctorsSelected, setDoctorsSelected] = useState([]);
  const [nursesSelected, setNursesSelected] = useState([]);
  const [nurseTechniciansSelected, setNurseTechniciansSelected] = useState([]);
  const [teamId, setTeamEdit] = useState("");
  const [dataMode, setDataMode] = useState("add");

  if (openNotification) {
    setTimeout(() => {
      setOpenNotification(false);
      setErrorMsg("");
    }, 2000);
  }

  useEffect(() => {
    if (props.location.state.comingFor=="edit"){
      console.log("getProductionTeamItem", props.location.state.editId);
      setTeamEdit(props.location.state.editId);
      getProductionTeam(props.location.state.editId, setDoctorsSelected, setNursesSelected, setNurseTechniciansSelected, dispatch);
      setDataMode('edit');
      dispatch({
        field: 'updatedBy',
        value: currentUser._id,
      });
    }

    getProductionStaffsDropdown();

    console.log(data);
    if (props.location.state.comingFor=="add"){
      dispatch({
        field: 'generatedBy',
        value: currentUser._id,
      });
    }
  }, []);

  useEffect(() => {
    console.log("teamEdit", "Selected");
  }, [doctorsSelected, nursesSelected, nurseTechniciansSelected]);

  function getProductionStaffsDropdown() {
    // getAllProductionTeams(setData, setErrorMsg, setOpenNotification);
    getAllProductionStaff(setDoctorsList, setNursesList, setNurseTechniciansList, setErrorMsg, setOpenNotification);
  }

  const onChangeValue = (e) => {
    let value = e.target.value;
    if (e.target.name === "name") {
      value = value.replace(/\s+/g, ' ').trim();
    } 
    dispatch({
      field: e.target.name,
      value: value,
    });
  };

  const onUpdate = () => {
    const data = {
      teamName,
      doctors,
      nurses,
      nurseTechnicians,
      updatedBy,
    }
    console.log("teamEdit", data);
    console.log("teamEdit", `${updateProductionTeam}/${teamId}`);
    axios
        .put(`${updateProductionTeam}/${teamId}`, data)
        .then((response) => {
          console.log("teamEdit::", response);
          if (response.data && response.data.success){
            console.log('production', response.data.data);
            // goToDashboard();
            props.history.push({
              pathname: "/dashboard/home/departmentmanagement/production-teams",
            });
          }else{
            dispatch({
              field: "errorMsg",
              value: response.error,
            });
            setErrorMsg(response.error);
            setOpenNotification(true);
          }
        })
        .catch((error) => {
          console.log("Error", error);
        });
  };

  const onSubmit = () => {
    const data = {
      teamName,
      doctors,
      nurses,
      nurseTechnicians,
      generatedBy,
    }
    axios
        .post(createProductionTeam, data)
        .then((response) => {
          console.log("handleAutoCompleteComposition", response);
          if (response.data && response.data.success){
            console.log('production', response.data.data);
            // goToDashboard();
            props.history.push({
              pathname: "/dashboard/home/departmentmanagement/production-teams",
            });
          }else{
            dispatch({
              field: "errorMsg",
              value: response.error,
            });
            setErrorMsg(response.error);
            setOpenNotification(true);
          }
        })
        .catch((error) => {
          console.log("Error", error);
        });
  };


  const handleAutoCompleteComposition = (val, field) => {
    console.log('teamEdit:: ' + field, field, val);

    let finalData = [];
    let newData = [];
    Object.entries(val).map((v, i) => {
      console.log('handleAutoCompleteComposition', v, i);
      finalData.push(v[1].id);
      newData.push(v[1]);
    });
    console.log('teamEdit:: -> finalData', finalData);
    console.log('teamEdit:: -> newData', newData);
    dispatch({
      field: field,
      value: finalData,
    });

    if (field === "doctors"){
      setDoctorsSelected(newData);
    }

    if (field === "nurses"){
      setNursesSelected(newData);
    }

    if (field === "nurseTechnicians"){
      setNurseTechniciansSelected(newData);
    }

    // if (field == "doctors"){
    //   let filterData = [];
    //   Object.entries(doctorsSelected).map((v, i) => {
    //     console.log('teamEdit', v[1].id);
    //     if (finalData.includes(v[1].id)){
    //       console.log('teamEdit:: doctors -> included', v[1].id);
    //     }else{
    //       console.log('teamEdit:: doctors -> not included', v[1].id);
    //       filterData.push(v[1]); 
    //     }
    //   });
    //   console.log('teamEdit:: doctors -> filterData', filterData);
    //   setDoctorsSelected(filterData);
    //   /*nursesSelected
    //   nurseTechniciansSelected*/
    // }


  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        position: "fixed",
        width: "100%",
        height: "100%",
        backgroundColor: "rgb(19 213 159)",
        overflowY: "scroll",
      }}
    >
      <Header history={props.history} />
      <div className="cPadding">
        <div className="subheader">
          <div>
            <img src={Pending_Patients} />
            <h4>{
              props.location.state.comingFor==="add"?"Create":"Update"
            } Production Team</h4>
          </div>
        </div>

        <div
          style={{
            flex: 4,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div className="row row-5 mt-3">
            <div className="col-md-12 col-sm-12">
              <div className="form-group">
                <TextField
                  required
                  type="text"
                  label="Team Name"
                  name={"teamName"} // now Identity
                  value={teamName}
                  error={teamName === "" && detailsForm}
                  onChange={onChangeValue}
                  className="textInputStyle"
                  variant="filled"
                  InputProps={{
                    className: classes.input,
                    classes: { input: classes.input },
                  }}
                />
                <ErrorMessage
                  name={teamName}
                  type="nationalId"
                  isFormSubmitted={detailsForm}
                />
              </div>
            </div>
            <div className="col-md-12 col-sm-12">
              <div className="card">
                <div className="card-body">
                  <div className="form-group">
                    <Autocomplete
                        multiple
                        id="doctors-list"
                        options={doctorsList}
                        name={"doctorsItems"}
                        getOptionLabel={(option) =>
                          option.fullName
                        }
                        defaultValue={[]}
                        value={doctorsSelected}
                        onChange={(e, value) =>{
                          handleAutoCompleteComposition(value, 'doctors');
                        }}
                        InputProps={{
                          className: classes.input,
                          classes: { input: classes.input },
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label="Choose Doctors"
                            placeholder="Type Doctors"
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: 'new-password', // disable autocomplete and autofill
                            }}
                          />
                        )}
                        />
                  </div>

                  <div className="form-group">
                    <Autocomplete
                        multiple
                        id="n-list"
                        options={nursesList}
                        getOptionLabel={(option) =>
                          option.fullName
                        }
                        defaultValue={[]}
                        value={nursesSelected}
                        onChange={(e, value) =>{
                          handleAutoCompleteComposition(value, 'nurses');
                        }}
                        InputProps={{
                          className: classes.input,
                          classes: { input: classes.input },
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label="Choose Nurses"
                            placeholder="Type Nurses"
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: 'new-password', // disable autocomplete and autofill
                            }}
                          />
                        )}
                        />
                  </div>

                  <div className="">
                    <Autocomplete
                        multiple
                        id="nt-list"
                        options={nursesTechniciansList}
                        getOptionLabel={(option) =>
                          option.fullName
                        }
                        defaultValue={[]}
                        value={nurseTechniciansSelected}
                        onChange={(e, value) =>{
                          handleAutoCompleteComposition(value, 'nurseTechnicians');
                        }}
                        InputProps={{
                          className: classes.input,
                          classes: { input: classes.input },
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label="Choose Nurse Technician"
                            placeholder="Type Nurse Technician"
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: 'new-password', // disable autocomplete and autofill
                            }}
                          />
                        )}
                        />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row" style={{ marginTop: "10px", marginBottom: "40px" }}>
            <div className="col-md-6 col-sm-6 col-6">
              <img
                onClick={() => props.history.goBack()}
                src={Back}
                style={{
                  width: 45,
                  height: 35,
                  cursor: "pointer",
                  marginTop: "15px",
                }}
              />
            </div>
            <div className="col-md-6 col-sm-6 col-6 d-flex justify-content-end">
              <Button
                className={classes.stylesForButton}
                style={{ marginTop: 10 }}
                align="right"
                variant="contained"
                onClick={dataMode==="add"?onSubmit:onUpdate}
                raised
                disabled={teamName ? false : true}
                color="primary"
              >
                Submit
              </Button>
            </div>
          </div>
        </div>
      </div>


      <Notification
        msg={errorMsg}
        open={openNotification}
        success={successMsg}
      />
    </div>
  );
}
