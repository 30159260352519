import React, { useEffect, useState } from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Flip from "../../../components/Dial/Flip";
import TwoValue from "../../../components/Dial/TwoValue";
import OneValue from "../../../components/Dial/OneValue";
import { edDoctorDashboard } from "../../../public/endpoins";
import { colorLabelsForRegistration } from "../../../utils/Constants";
import Loader from "react-loader-spinner";
import {
  getDialerGraphCall,
  getValuesCardCalls,
  getResponse,
} from "./NetworkCalls";
import Notification from "../../../components/Snackbar/Notification.js";
import {
  socketUrl
} from '../../../public/endpoins'
import socketIOClient from 'socket.io-client'

export default function EDdoctor() {
  const matches = useMediaQuery("(min-width:600px)");
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setsuccessMsg] = useState("");
  const [openNotification, setOpenNotification] = useState(false);
  const [isLoading, setisLoading] = useState(true);
  const [responseData, setResponseData] = useState([]);

  // Patient Diagnosis Pending
  const [diagnosisPending, setDiagnosisPending] = useState("");
  const [diagnosisPendingColor, setDiagnosisPendingColor] = useState("");
  const [diagnosisPendingTAT, setDiagnosisPendingTAT] = useState("");
  const [diagnosisPendingTATColor, setDiagnosisPendingTATColor] = useState("");
  const [diagnosisPendingGraphData, setDiagnosisPendingGraphData] = useState(
    ""
  );

  // Patient Diagnosis Pending
  const [decisionPending, setDecisionPending] = useState("");
  const [decisionPendingColor, setDecisionPendingColor] = useState("");
  const [decisionPendingTAT, setDecisionPendingTAT] = useState("");
  const [decisionPendingTATColor, setDecisionPendingTATColor] = useState("");
  const [decisionPendingGraphData, setDecisionPendingGraphData] = useState("");

  // Patient Discharges Pending
  const [dischargesPending, setDischargesPending] = useState("");
  const [dischargesPendingColor, setDischargesPendingColor] = useState("");
  const [dischargesPendingTAT, setDischargesPendingTAT] = useState("");
  const [dischargesPendingTATColor, setDischargesPendingTATColor] = useState(
    ""
  );
  const [dischargesPendingGraphData, setDischargesPendingGraphData] = useState(
    ""
  );

  // Consultant Notes Pending
  const [consultantPending, setConsultantPending] = useState("");
  const [consultantPendingColor, setConsultantPendingColor] = useState("");
  const [consultationOrderTAT, setConsultationOrderTAT] = useState("");
  const [consultationOrderTATColor, setConsultationOrderTATColor] = useState(
    ""
  );
  const [consultantNotesGraphData, setConsultantNotesGraphData] = useState("");

  // Lab Pending
  const [labPending, setLabPending] = useState("");
  const [labPendingColor, setLabPendingColor] = useState("");
  const [labTAT, setLabTAT] = useState("");
  const [labTATColor, setLabTATColor] = useState("");
  const [labPendingGraphData, setLabPendingGraphData] = useState("");

  // Rad Pending
  const [radPending, setRadPending] = useState("");
  const [radPendingColor, setRadPendingColor] = useState("");
  const [radTAT, setRadTAT] = useState("");
  const [radTATColor, setRadTATColor] = useState("");
  const [radPendingGraphData, setRadPendingGraphData] = useState("");

  // Order Pending
  const [orderPending, setOrderPending] = useState("");
  const [orderPendingColor, setOrderPendingColor] = useState("");
  const [orderTAT, setOrderTAT] = useState("");
  const [orderTATColor, setOrderTATColor] = useState("");
  const [orderPendingGraphData, setOrderPendingGraphData] = useState("");

  // Values cards
  const [diagnosedPerHour, setDiagnosedPerHour] = useState("");
  const [diagnosedPerHourColor, setDiagnosedPerHourColor] = useState("");

  const [comulativeTotal, setComulativeTotal] = useState("");
  const [comulativeTotalColor, setComulativeTotalColor] = useState("");
  const [flag1Count, setFlag1Count] = useState(0)
  const [flag2Count, setFlag2Count] = useState(0)
  const [flag3Count, setFlag3Count] = useState(0)
  const [flag4Count, setFlag4Count] = useState(0)
  const [flag5Count, setFlag5Count] = useState(0)
  const [flag6Count, setFlag6Count] = useState(0)
  const [flag7Count, setFlag7Count] = useState(0)

  const [flip, setFlip] = useState(false);

  useEffect(() => {
    getResponse(
      edDoctorDashboard,
      setResponseData,
      setOpenNotification,
      setErrorMsg,
      setisLoading
    );
    console.log("*in getResponse edDoctor");

    const flagCount = [{value: '1st', setFlagCount: (value) => setFlag1Count(value)},
    {value: '2nd', setFlagCount: (value) => setFlag2Count(value)},
    {value: '3rd', setFlagCount: (value) => setFlag3Count(value)},
    {value: '4th', setFlagCount: (value) => setFlag4Count(value)},
    {value: '5th', setFlagCount: (value) => setFlag5Count(value)},
    {value: '6th', setFlagCount: (value) => setFlag6Count(value)},
    {value: '7th', setFlagCount: (value) => setFlag7Count(value)}];

    const socket = socketIOClient(socketUrl)
      socket.emit('edDoctor_flags')
      socket.on('pendingDoctor', (data) => {
        console.log('response coming through socket for ED doctor Flags', data)
        flagCount.forEach(({value, setFlagCount}) => {
          const cardCount = data.filter(item => item.card === value).length;
          cardCount && setFlagCount(cardCount);
        })

        console.log('data', data)
        
      })

  }, []);

  if (openNotification) {
    setTimeout(() => {
      setOpenNotification(false);
      setErrorMsg("");
      setsuccessMsg("");
    }, 2000);
  }

  useEffect(() => {
    let arr = [];

    Object.entries(responseData).map(([key, val]) => {
      if (key !== "success") {
        console.log("key of data", key, "val of data", val);
        arr.push({ [key]: val });
      }
    });

    console.log("arr::", arr);
    if (arr && arr.length > 0) {
      getDialerGraphCall(
        3,
        6,
        5,
        10,
        arr[0].firstCard,
        setDiagnosisPending,
        setDiagnosisPendingColor,
        setDiagnosisPendingTAT,
        setDiagnosisPendingTATColor,
        setDiagnosisPendingGraphData
      );
      getDialerGraphCall(
        3,
        6,
        15,
        30,
        arr[1].secondCard,
        setDecisionPending,
        setDecisionPendingColor,
        setDecisionPendingTAT,
        setDecisionPendingTATColor,
        setDecisionPendingGraphData
      );
      getDialerGraphCall(
        2,
        4,
        20,
        30,
        arr[2].thirdCard,
        setDischargesPending,
        setDischargesPendingColor,
        setDischargesPendingTAT,
        setDischargesPendingTATColor,
        setDischargesPendingGraphData
      );
      getDialerGraphCall(
        3,
        6,
        30,
        45,
        arr[3].fourthCard,
        setConsultantPending,
        setConsultantPendingColor,
        setConsultationOrderTAT,
        setConsultationOrderTATColor,
        setConsultantNotesGraphData
      );
      getDialerGraphCall(
        3,
        6,
        30,
        45,
        arr[4].fifthCard,
        setLabPending,
        setLabPendingColor,
        setLabTAT,
        setLabTATColor,
        setLabPendingGraphData
      );
      getDialerGraphCall(
        3,
        6,
        60,
        180,
        arr[5].sixthCard,
        setRadPending,
        setRadPendingColor,
        setRadTAT,
        setRadTATColor,
        setRadPendingGraphData
      );
      getDialerGraphCall(
        2,
        4,
        45,
        60,
        arr[6].seventhCard,
        setOrderPending,
        setOrderPendingColor,
        setOrderTAT,
        setOrderTATColor,
        setOrderPendingGraphData
      );
      getValuesCardCalls(
        6,
        10,
        arr[7].diagnosedPerHour,
        setDiagnosedPerHour,
        setDiagnosedPerHourColor
      );
      getValuesCardCalls(
        40,
        60,
        arr[8].cumulativePatient,
        setComulativeTotal,
        setComulativeTotalColor
      );
    }
  }, [responseData]);

  console.log("diagnosisPending", diagnosisPending);

  const flipIt = () => {
    setFlip(true);
  };
  return (
    <div className="container-fluid" style={{ marginBottom: 10 }}>
      <div className="row">
        <div
          className="col-md-4 col-6"
          style={{ paddingLeft: 4, paddingRight: 4, marginTop: 10 }}
        >
          <div>
            <Flip
              flipped={!flip}
              flipHandler={flipIt}
              mainHeading={"Patient Diagnosis Pending"}
              value={
                isLoading ? (
                  <Loader
                    type="TailSpin"
                    color="green"
                    height={35}
                    width={35}
                  />
                ) : diagnosisPending <= 99 ? (
                  Number.parseFloat(diagnosisPending).toFixed(1)
                ) : (
                  Number.parseFloat(diagnosisPending).toPrecision(3)
                )
              }
              flagsCount={flag1Count}
              mainHeadingForGraph="Patient Diagnosis Pending"
              color={diagnosisPendingColor}
              subHeading={"TAT"}
              childHeading={"Assessment / Triage to Diagnosis"}
              time={
                isLoading ? (
                  <Loader
                    type="TailSpin"
                    color="green"
                    height={35}
                    width={35}
                  />
                ) : diagnosisPendingTAT === null ? (
                  0
                ) : (
                  Number.parseFloat(diagnosisPendingTAT).toPrecision(3)
                )
              }
              timecolor={diagnosisPendingTATColor}
              idForGraph={"container1"}
              data={diagnosisPendingGraphData}
            />
          </div>
        </div>

        <div
          className="col-md-4 col-6"
          style={{ paddingLeft: 4, paddingRight: 4, marginTop: 10 }}
        >
          <div>
            <Flip
              flipped={!flip}
              flipHandler={flipIt}
              mainHeading={"Patient Decision Pending"}
              value={
                isLoading ? (
                  <Loader
                    type="TailSpin"
                    color="green"
                    height={35}
                    width={35}
                  />
                ) : decisionPending <= 99 ? (
                  Number.parseFloat(decisionPending).toFixed(1)
                ) : (
                  Number.parseFloat(decisionPending).toPrecision(3)
                )
              }
              flagsCount={flag2Count}
              mainHeadingForGraph="Patient Decision Pending"
              color={decisionPendingColor}
              subHeading={"TAT"}
              childHeading={"Diagnosis to Decision"}
              time={
                isLoading ? (
                  <Loader
                    type="TailSpin"
                    color="green"
                    height={35}
                    width={35}
                  />
                ) : decisionPendingTAT === null ? (
                  0
                ) : (
                  Number.parseFloat(decisionPendingTAT).toPrecision(3)
                )
              }
              timecolor={decisionPendingTATColor}
              idForGraph={"container2"}
              data={decisionPendingGraphData}
            />
          </div>
        </div>

        <div
          className="col-md-4 col-6"
          style={{ paddingLeft: 4, paddingRight: 4, marginTop: 10 }}
        >
          <div>
            <Flip
              flipped={!flip}
              flipHandler={flipIt}
              mainHeading={"Patient Discharges/Dispositions Pending"}
              value={
                isLoading ? (
                  <Loader
                    type="TailSpin"
                    color="green"
                    height={35}
                    width={35}
                  />
                ) : dischargesPending <= 99 ? (
                  Number.parseFloat(dischargesPending).toFixed(1)
                ) : (
                  Number.parseFloat(dischargesPending).toPrecision(3)
                )
              }
              flagsCount={flag3Count}
              mainHeadingForGraph="Patient Discharges/Dispositions Pending"
              color={dischargesPendingColor}
              subHeading={"TAT"}
              childHeading={"Decision to Discharge/Disposition"}
              time={
                isLoading ? (
                  <Loader
                    type="TailSpin"
                    color="green"
                    height={35}
                    width={35}
                  />
                ) : dischargesPendingTAT === null ? (
                  0
                ) : (
                  Number.parseFloat(dischargesPendingTAT).toPrecision(3)
                )
              }
              timecolor={dischargesPendingTATColor}
              idForGraph={"container3"}
              data={dischargesPendingGraphData}
            />
          </div>
        </div>
        {/* </div> */}

        {/* <div className='row'> */}
        <div
          className="col-md-4 col-6"
          style={{ paddingLeft: 4, paddingRight: 4, marginTop: 10 }}
        >
          <div>
            <Flip
              flipped={!flip}
              flipHandler={flipIt}
              mainHeading={"Consultant/Specialist Notes Pending"}
              value={
                isLoading ? (
                  <Loader
                    type="TailSpin"
                    color="green"
                    height={35}
                    width={35}
                  />
                ) : consultantPending <= 99 ? (
                  Number.parseFloat(consultantPending).toFixed(1)
                ) : (
                  Number.parseFloat(consultantPending).toPrecision(3)
                )
              }
              flagsCount={flag4Count}
              mainHeadingForGraph="Consultant/Specialist Notes Pending"
              color={consultantPendingColor}
              subHeading={"TAT"}
              childHeading={"Consultation Order to Receipt of Notes"}
              time={
                isLoading ? (
                  <Loader
                    type="TailSpin"
                    color="green"
                    height={35}
                    width={35}
                  />
                ) : consultationOrderTAT === null ? (
                  0
                ) : (
                  Number.parseFloat(consultationOrderTAT).toPrecision(3)
                )
              }
              timecolor={consultationOrderTATColor}
              idForGraph={"container4"}
              data={consultantNotesGraphData}
            />
          </div>
        </div>

        <div
          className="col-md-4 col-6"
          style={{ paddingLeft: 4, paddingRight: 4, marginTop: 10 }}
        >
          <div>
            <Flip
              flipped={!flip}
              flipHandler={flipIt}
              mainHeading={"Patient Lab Results Pending"}
              value={
                isLoading ? (
                  <Loader
                    type="TailSpin"
                    color="green"
                    height={35}
                    width={35}
                  />
                ) : labPending <= 99 ? (
                  Number.parseFloat(labPending).toFixed(1)
                ) : (
                  Number.parseFloat(labPending).toPrecision(3)
                )
              }
              // flagsCount={flag5Count}
              mainHeadingForGraph="Patient Lab Results Pending"
              color={labPendingColor}
              subHeading={"TAT"}
              childHeading={"Order to Result"}
              flagsCount={flag5Count}
              time={
                isLoading ? (
                  <Loader
                    type="TailSpin"
                    color="green"
                    height={35}
                    width={35}
                  />
                ) : labTAT === null ? (
                  0
                ) : (
                  Number.parseFloat(labTAT).toPrecision(3)
                )
              }
              timecolor={labTATColor}
              idForGraph={"container5"}
              data={labPendingGraphData}
            />
          </div>
        </div>

        <div
          className="col-md-4 col-6"
          style={{ paddingLeft: 4, paddingRight: 4, marginTop: 10 }}
        >
          <div>
            <Flip
              flipped={!flip}
              flipHandler={flipIt}
              mainHeading={"Patient Rad Consultation Notes Pending"}
              value={
                isLoading ? (
                  <Loader
                    type="TailSpin"
                    color="green"
                    height={35}
                    width={35}
                  />
                ) : radPending <= 99 ? (
                  Number.parseFloat(radPending).toFixed(1)
                ) : (
                  Number.parseFloat(radPending).toPrecision(3)
                )
              }
              mainHeadingForGraph="Patient Rad Consultation Notes Pending"
              color={radPendingColor}
              subHeading={"TAT"}
              childHeading={"Order to Consultation Notes"}
              flagsCount={flag6Count}
              time={
                isLoading ? (
                  <Loader
                    type="TailSpin"
                    color="green"
                    height={35}
                    width={35}
                  />
                ) : radTAT === null ? (
                  0
                ) : (
                  Number.parseFloat(radTAT).toPrecision(3)
                )
              }
              timecolor={radTATColor}
              idForGraph={"container6"}
              data={radPendingGraphData}
            />
          </div>
        </div>
        {/* </div> */}

        {/* <div className='row'> */}
        <div
          className="col-md-4 col-6"
          style={{ paddingLeft: 4, paddingRight: 4, marginTop: 10 }}
        >
          <div>
            <Flip
              flipped={!flip}
              flipHandler={flipIt}
              mainHeading={"Orders Pending"}
              value={
                isLoading ? (
                  <Loader
                    type="TailSpin"
                    color="green"
                    height={35}
                    width={35}
                  />
                ) : orderPending <= 99 ? (
                  Number.parseFloat(orderPending).toFixed(1)
                ) : (
                  Number.parseFloat(orderPending).toPrecision(3)
                )
              }
              flagsCount={flag7Count}
              mainHeadingForGraph="Orders Pending"
              color={orderPendingColor}
              subHeading={"TAT"}
              childHeading={"Order Request to Delivery"}
              time={
                isLoading ? (
                  <Loader
                    type="TailSpin"
                    color="green"
                    height={35}
                    width={35}
                  />
                ) : orderTAT === null ? (
                  0
                ) : (
                  Number.parseFloat(orderTAT).toPrecision(3)
                )
              }
              timecolor={orderTATColor}
              idForGraph={"container7"}
              data={orderPendingGraphData}
            />
          </div>
        </div>

        <div
          className="col-md-4 col-6"
          style={{ paddingLeft: 4, paddingRight: 4, marginTop: 10 }}
        >
          <div>
            <OneValue
              time={
                isLoading ? (
                  <Loader
                    type="TailSpin"
                    color="green"
                    height={35}
                    width={35}
                  />
                ) : (
                  37
                )
              }
              colorTime={"#13D59F"}
              heading={"Current No. of Patients Per Doctor"}
            />
          </div>
        </div>

        <div
          className="col-md-4 col-6"
          style={{ paddingLeft: 4, paddingRight: 4, marginTop: 10 }}
        >
          <div>
            <OneValue
              time={
                isLoading ? (
                  <Loader
                    type="TailSpin"
                    color="green"
                    height={35}
                    width={35}
                  />
                ) : (
                  comulativeTotal
                )
              }
              colorTime={comulativeTotalColor}
              heading={"No. of Patients Cumulative Total"}
            />
          </div>
        </div>
        {/* </div> */}

        {/* <div className='row'> */}
        <div
          className="col-md-4 col-6"
          style={{ paddingLeft: 4, paddingRight: 4, marginTop: 10 }}
        >
          <div>
            <OneValue
              time={
                isLoading ? (
                  <Loader
                    type="TailSpin"
                    color="green"
                    height={35}
                    width={35}
                  />
                ) : (
                  diagnosedPerHour
                )
              }
              colorTime={diagnosedPerHourColor}
              heading={"Average No. of Patients Diagnosed Per Hour"}
            />
          </div>
        </div>
        <div
          className="col-md-4 col-6"
          style={{ paddingLeft: 4, paddingRight: 4, marginTop: 10 }}
        >
          <div>
            <OneValue
              time={
                isLoading ? (
                  <Loader
                    type="TailSpin"
                    color="green"
                    height={35}
                    width={35}
                  />
                ) : (
                  30
                )
              }
              colorTime={"#13D59F"}
              heading={"Compliance/Variance Report"}
            />
          </div>
        </div>
      </div>

      <Notification
        msg={errorMsg}
        open={openNotification}
        success={successMsg}
      />
    </div>
  );
}
