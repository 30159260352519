import React, { useEffect, useState, useReducer } from "react";

import DoneIcon from "@material-ui/icons/Done";
import AddIcon from "@material-ui/icons/Add";
import eye_icon from "../../assets/img/Eye.png";
import {
  Chip,
  MenuItem,
  TextField,
  TextArea,
  Input,
  Select,
  InputAdornment,
  Button,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Radio,
  RadioGroup,
  FormControl,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  useMediaQuery,
  makeStyles,
} from "@material-ui/core";

import tableStyles from "../../assets/jss/material-dashboard-react/components/tableStyle.js";
import axios from "axios";
import useStylesForInput from "../../assets/jss/material-dashboard-react/inputStyle";
import styles from "./styles";
import TaskRequestModel from "../../components/TaskRequestModel/TaskRequestModel";
import {
  audioURL,
  getEDRFromPatientIdForDischarge,
  updateEdr,
  getSingleEdr
} from "../../public/endpoins";

import {medicineFrequencyArray} from "../NotesScreen/configForNotesScreen"
import classNames from "classnames";
import cookie from "react-cookies";
import Header from "../../components/Header/Header";
import AssessIcon from "../../assets/img/Assessment & Diagnosis.png";
import EdDoctor from "../../assets/img/Doctor - Discharge.png";
import Back from "../../assets/img/Back_Arrow.png";
import "../../assets/jss/material-dashboard-react/components/TextInputStyle.css";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import CustomTable from "../../components/Table/Table";
import plus_icon from "../../assets/img/Plus.png";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Notification from "../../components/Snackbar/Notification.js";
import Fingerprint from "../../assets/img/fingerprint.png";
import AccountCircle from "@material-ui/icons/SearchOutlined";
import BarCode from "../../assets/img/Bar Code.png";
import ViewSingleRequest from "../../components/ViewRequest/ViewRequest";
import Loader from "react-loader-spinner";
import { displayInHumanDifference, replacePatientIdIfNull } from "../../utils/commonFunc";
import PatientClinicalInformation from "../../components/PatientDetails/PatientClinicalInformation";
import PatientDetailsQuick from "../../components/PatientDetails/PatientDetailsQuick";
// import PatientDetails from "../../components/PatientDetails/PatientDetails";

import { connect } from "react-redux";
import {
  funForReducer,
  setPatientDetailsForReducer,
} from "../../actions/Checking";

import { handleSearchPatient } from "./networkCallsForDischarge";

const tableHeadingForDischargeMed = [
  "Request ID",
  "Date/Time",
  "Status",
  "Action",
];
const tableDataKeysForDischargeMed = [
  ["requester", "identificationNumber"],
  "date",
  "status",
];
const actions = { view: true };
const actions1 = { edit: true };

const useStylesForTabs = makeStyles({
  root: {
    justifyContent: "center",
  },
  scroller: {
    flexGrow: "0",
  },
});

const stylesx = {
  ROSRowPadding: {
    marginLeft: 0,
    marginRight: 0,
    backgroundColor: "white",
    marginTop: 10,
    padding: "10px 0px 10px 0px",
    borderRadius: 5,
  },
  chipAlignment: {
    display: "inline-block",
    padding: "5px",
  },
  save: {
    color: "white",
    cursor: "pointer",
    borderRadius: 5,
    backgroundColor: "#2c6ddd",
    width: "115px",
    height: "40px",
    outline: "none",
  },
};

const useStyles = makeStyles((theme) => ({
  input: {
    backgroundColor: "#F5F5F5",
    boxShadow: "none",
    borderRadius: 5,
    "&:after": {
      borderBottomColor: "black",
      boxShadow: "none",
    },
    "&:hover": {
      backgroundColor: "#F5F5F5",
      boxShadow: "none",
    },
    "&:focus": {
      backgroundColor: "#F5F5F5",
      boxShadow: "none",
      borderRadius: 5,
    },
  },
  root: {
    "& .MuiTextField-root": {
      backgroundColor: "white",
    },
    "& .Mui-focused": {
      backgroundColor: "white",
      color: "black",
      boxShadow: "none",
    },
    "& .Mui-disabled": {
      backgroundColor: "white",
      color: "gray",
    },
    "& .MuiFormLabel-root": {
      fontSize: "12px",
      color: "black",
      paddingRight: "15px",
    },
    "& .MuiInputLabel-filled": {
      color: "gray",
    },
    "& .MuiFilledInput-adornedEnd": {
      backgroundColor: "#F5F5F5",
      paddingRight: "20px",
    },
  },
}));

function LabRadRequest(props) {
  const matches = useMediaQuery("(min-width:600px)");

  const classesForTabs = useStylesForTabs();
  const classes = useStylesForInput();

  const initialState = {
    EDDoctorNotesArray: "",
    consultationNo: "",
    date: new Date(),
    description: "",
    consultationNotes: "",
    doctorconsultationNotes: "",

    requester: cookie.load("current_user").name,
    speciality: "",
    specialist: "",

    residentNoteArray: "",
    rdescription: "",
    note: "",
    doctor: cookie.load("current_user").name,

    pharmacyRequestArray: "",
    requestType: "",
    section: "",
    code: [],
    patientId: "",
    diagnosisArray: "",
    medicationArray: "",

    price: "",

    dischargeMedArray: "",

    dischargeNotes: "",
    followUpInstruction: "",
    dischargeIsReadOnly: false,

    dischargeDisposition: "",
    dischargeDispositionStatus: "",
    dischargeDispositionRemarks: "",
    dischargeDispositionFollowup: "",
    dischargeDispositionVoiceNotes: "",
    dischargeDispositionType: "",
  };

  function reducer(state, { field, value }) {
    return {
      ...state,
      [field]: value,
    };
  }

  const [state, dispatch] = useReducer(reducer, initialState);

  const {
    EDDoctorNotesArray,
    date = new Date(),
    description,
    consultationNotes,
    doctorconsultationNotes,

    requester = cookie.load("current_user").name,
    speciality,
    specialist,

    pharmacyRequestArray,

    residentNoteArray,
    rdescription,
    note,
    requestType,
    section,
    code,
    patientId,
    diagnosisArray,
    medicationArray,

    price,

    dischargeMedArray,

    dischargeNotes,
    followUpInstruction,
    dischargeIsReadOnly,


    dischargeDisposition,
    dischargeDispositionStatus,
    dischargeDispositionRemarks,
    dischargeDispositionFollowup,
    dischargeDispositionVoiceNotes,
    dischargeDispositionType,
  } = state;

  useEffect(() => {
    // const {edrId, selectedItem} = props.history.location.state;
    // console.log('props', props.history.location.state);
    // console.log('props params', props.match);
    // setSelectedItem(selectedItem);


    axios
      .get(getEDRFromPatientIdForDischarge + "/" + episodeId)
      .then((res) => {
        console.log('handleView::', res.data.data);
        if (res.data.success) {

          let remoteRecord = replacePatientIdIfNull(res.data.data);
          setSelectedItem(remoteRecord);

          const object = {
            '_id': remoteRecord._id,
            patientName: remoteRecord.patientId.name[0].given[0] + " " + remoteRecord.patientId.name[0].family,
            mrn: remoteRecord.requestNo,//remoteRecord.patientId.identifier[0].value,
            age: remoteRecord.patientId.age,
            gender: remoteRecord.patientId.gender,
            weight: remoteRecord.patientId.weight,
            department: remoteRecord.currentLocation,
            chiefComplaint: remoteRecord.chiefComplaint.length > 0 ? remoteRecord.chiefComplaint.pop().chiefComplaintId.short_desc : "N/A",
            chiefComplaintOthers: remoteRecord.chiefComplaintOthers,
          };
          setPatientDetails(object);

          /*
          if (serviceType === "lab"){
            let labServicesX = remoteRecord.labRequest.filter(obj => obj.requestId === labRequestId);
            console.log('labServicesX', labServicesX);
            if (labServicesX.length > 0){
              setIsUrgent(labServicesX[0].urgent==="Yes"?true:false)
              setLabRequest(labServicesX[0])
              setLabServices(labServicesX[0].services);
            }
          }else{
            let labServicesX = remoteRecord.radRequest.filter(obj => obj.requestId === labRequestId);
            console.log('labServicesX', labServicesX);
            if (labServicesX.length > 0){
              setIsUrgent(labServicesX[0].urgent==="Yes"?true:false)
              setLabRequest(labServicesX[0])
              setLabServices(labServicesX[0].services);
            }
          }
          */

          if (remoteRecord.dischargeRequest) {
            if (remoteRecord.dischargeRequest.status === "completed"){
              console.log('remoteRecord.dischargeRequest', remoteRecord.dischargeRequest)
              if (remoteRecord.dischargeRequest.pharmacyRequestNo && remoteRecord.dischargeRequest.pharmacyRequestNo.trim() != ""){
                dispatch({ field: "dischargeDispositionStatus", value: 'yes'});
                dispatch({ field: "dischargeDisposition", value: remoteRecord.dischargeRequest.dischargeSummary.edrCompletionReason}); //
                dispatch({ field: "dischargeDispositionType", value: remoteRecord.dischargeRequest.dischargeSummary.edrAdmissionType})
                dispatch({ field: "dischargeDispositionRemarks", value: remoteRecord.dischargeRequest.dischargeSummary.dischargeNotes});
                dispatch({ field: "dischargeDispositionFollowup", value: remoteRecord.dischargeRequest.dischargeSummary.followUpInstruction});
                
                dispatch({ field: "dischargeIsReadOnly", value: true });
              }
            }
            Object.entries(remoteRecord.dischargeRequest).map(([key1, val1]) => {
              if (key1 === "dischargeSummary") {
                dispatch({ field: "dischargeSummary", value: val1 });
                dispatch({
                  field: "dischargeNotes",
                  value: val1.dischargeNotes,
                });
                dispatch({
                  field: "followUpInstruction",
                  value: val1.followUpInstruction,
                });

                setEdrCompletionReason(val1.edrCompletionReason);
                setEdrCompletionRequirement(val1.edrCompletionRequirement);
                setEdrAdmissionType(val1.edrAdmissionType);
              } else if (key1 === "dischargeMedication") {
                dispatch({ field: "dischargeMedArray", value: val1 });
                dispatch({
                  field: "dischargeReportArray",
                  value: val1.medicine ? val1.medicine : "",
                });
              }
            });
            dispatch({ field: "dischargeRequest", value: remoteRecord.dischargeRequest });
          } 
            /*
            if (remoteRecord.doctorNotes) {
              if (val && val.length > 0) {
                let data = [];
                val.map((d) => {
                  d.code.map((singleCode) => {
                    let found = data.find((i) => i === singleCode);
                    if (!found) {
                      data.push(singleCode);
                    }
                  });
                });
                dispatch({
                  field: "diagnosisArray",
                  value: data,
                });
              }
            } 
            if (remoteRecord.pharmacyRequest) {
              let data = [];
              val.map((d) => {
                d.item.map((item) => {
                  let found = data.find((i) => i === item.itemId.name);
                  if (!found) {
                    data.push(item.itemId.name);
                  }
                });
              });
              dispatch({ field: "medicationArray", value: data });
            }
            */
          

        }
      })
      .catch((e) => {
        console.log("error after searching patient request", e);
      });
  }, []);

  const onChangeValue = (e) => {
    if (e.target.name === "specialist") {
      dispatch({
        field: e.target.name,
        value: e.target.value,
      });
    } else {
      dispatch({
        field: e.target.name,
        value: e.target.value.replace(/[^\w\s]/gi, ""),
      });
    }
  };

  const [currentUser] = useState(cookie.load("current_user"));
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setsuccessMsg] = useState("");
  const [openNotification, setOpenNotification] = useState(false);

  const [value, setValue] = useState(0);
  const [selectedItem, setSelectedItem] = useState("");

  const [patientDetails, setPatientDetails] = useState("");
  const [, setSelectedPatientArray] = useState([]);
  const [, openPatientDetailsDialog] = useState(false);
  const [enableForm, setenableForm] = useState(true);
  const [openItemDialog, setOpenItemDialog] = useState(false);
  const [openAddConsultDialog, setOpenAddConsultDialog] = useState(false);
  const [openAddResidentDialog, setOpenAddResidentDialog] = useState(false);
  const [item, setItem] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [itemFound, setItemFound] = useState("");
  const [itemFoundSuccessfull, setItemFoundSuccessfully] = useState(false);
  const [isFormSubmitted] = useState(false);
  const [id, setId] = useState("");

  const [, setIsLoading] = useState(true);

  const [timer, setTimer] = useState(null);

  const [loadSearchedData, setLoadSearchedData] = useState(false);

  const [edrCompletionReason, setEdrCompletionReason] = useState("discharged");
  const [edrAdmissionType   , setEdrAdmissionType] = useState("discharged");
  const [edrCompletionRequirement, setEdrCompletionRequirement] = useState(
    "withCare"
  );

  const [ episodeId, setEpisodeId ] = useState(props.match.params.id);
  // const [ labRequestId, setLabRequestId ] = useState(props.match.params.requestId);

  const validateForm = () => {
    return (
      edrCompletionReason !== "" &&
      edrCompletionRequirement !== "" &&
      dischargeNotes !== "" &&
      followUpInstruction !== ""
    );
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function viewItem(item) {
    if (item !== "") {
      setOpenItemDialog(true);
      setItem(item);
    } else {
      setOpenItemDialog(false);
      setItem("");
    }
  }

  const onChangeDisposition = (e) => {
    if ("deceased" === e.target.value){
      setEdrCompletionRequirement("withoutCare");
    }
    setEdrCompletionReason(e.target.value);
  };

  const onChangeAdmissionType = (e) => {
    setEdrAdmissionType(e.target.value);
  };

  const onChangeRequirements = (e) => {
    setEdrCompletionRequirement(e.target.value);
  };

  function handleAddPatient(i) {
    dispatch({ field: "diagnosisArray", value: "" });
    dispatch({ field: "medicationArray", value: "" });
    console.log("selected banda : ", i);
    props.setPatientDetailsForReducer(i);
    setPatientDetails(i);
    getPatientByInfo(i._id);
    openPatientDetailsDialog(true);

    const obj = {
      itemCode: i.itemCode,
    };

    setSelectedPatientArray((pervState) => [...pervState, obj]);
  }

  const getPatientByInfo = (id) => {
    console.log("getPatientByInfo::", getEDRFromPatientIdForDischarge + "/" + id)
    axios
      .get(getEDRFromPatientIdForDischarge + "/" + id)
      .then((res) => {
        if (res.data.success) {
          if (res.data.data) {
            console.log("Response after getting EDR data : ", res.data.data);

            setIsLoading(false);
            setSelectedItem(res.data.data);
            setId(res.data.data._id);
            setenableForm(false);

            Object.entries(res.data.data).map(([key, val]) => {
              if (val && typeof val === "object") {
                console.log(val, key);
                if (key === "patientId") {
                  dispatch({ field: "patientId", value: val._id });
                  Object.entries(val).map(([key1, val1]) => {
                    dispatch({ field: key1, value: val1 });
                  });
                } else if (key === "dischargeRequest") {
                  Object.entries(val).map(([key1, val1]) => {
                    if (key1 === "dischargeSummary") {
                      dispatch({ field: "dischargeSummary", value: val1 });
                      dispatch({
                        field: "dischargeNotes",
                        value: val1.dischargeNotes,
                      });
                      dispatch({
                        field: "followUpInstruction",
                        value: val1.followUpInstruction,
                      });

                      setEdrCompletionReason(val1.edrCompletionReason);
                      setEdrCompletionRequirement(
                        val1.edrCompletionRequirement
                      );
                      setEdrAdmissionType(val1.edrAdmissionType);
                    } else if (key1 === "dischargeMedication") {
                      dispatch({ field: "dischargeMedArray", value: [val1] });
                      dispatch({
                        field: "dischargeReportArray",
                        value: val1.medicine ? val1.medicine : "",
                      });
                    }
                  });
                  dispatch({ field: "dischargeRequest", value: val });
                } else if (key === "doctorNotes") {
                  if (val && val.length > 0) {
                    let data = [];
                    val.map((d) => {
                      d.code.map((singleCode) => {
                        let found = data.find((i) => i === singleCode);
                        if (!found) {
                          data.push(singleCode);
                        }
                      });
                    });
                    dispatch({
                      field: "diagnosisArray",
                      value: data,
                    });
                  }
                } else if (key === "pharmacyRequest") {
                  let data = [];
                  val.map((d) => {
                    d.item.map((item) => {
                      let found = data.find((i) => i === item.itemId.name);
                      if (!found) {
                        data.push(item.itemId.name);
                      }
                    });
                  });
                  dispatch({ field: "medicationArray", value: data });
                }
              } else {
                dispatch({ field: key, value: val });
              }
            });
          }
        } else {
          setOpenNotification(true);
          setErrorMsg("EDR/IPR not generated for patient");
        }
      })
      .catch((e) => {
        setOpenNotification(true);
        setErrorMsg(e);
      });
  };

  if (openNotification) {
    setTimeout(() => {
      setOpenNotification(false);
      setErrorMsg("");
      setsuccessMsg("");
    }, 2000);
  }

  const showAlert = () => {
    setErrorMsg("Please Search Patient First ");
    setOpenNotification(true);
  };

  const onClick = () => {
    if (selectedItem) {
      setValue(value + 1);
    } else {
      showAlert();
    }
  };

  function addPharmaRequest() {
    let path = `/dashboard/home/${episodeId}/discharge/addmedication`;
    props.history.push({
      pathname: path,
      state: {
        comingFor: "add",
        selectedItem: selectedItem,
        dischargeMedArray,
        followUpInstruction,
        dischargeNotes,
        edrCompletionReason,
        edrCompletionRequirement,
        edrAdmissionType,
      },
    });
  }

  const DCDHX = () => {
    // console.log(DCDFormData);
    window.open(`/dashboard/home/${episodeId}/dcd-history/`, '_blank', 'noreferrer');
  }

  return (
    <div
      style={{
        backgroundColor: "rgb(19 213 159)",
        position: "fixed",
        display: "flex",
        width: "100%",
        height: "100%",
        flexDirection: "column",
        flex: 1,
        overflowY: "scroll",
        // overflowX:'hidden'
      }}
    >
      <Header history={props.history} />

      <PatientDetailsQuick
        edrId={episodeId} />

      <div className="cPadding">
        <div className="subheader" style={{ marginLeft: "-10px" }}>
          <div>
            <img src={EdDoctor} />
            <h4>Discharge</h4>
          </div>
        </div>

        {patientDetails ? (
          <PatientClinicalInformation
            edrId={episodeId}
          />
        ) : (
          undefined
        )}
        
        {
          dischargeIsReadOnly === true?
          <>
            <div className="row" style={stylesx.ROSRowPadding}>
              <div className="col-sm-12 mb-2">
                <Button
                  onClick={DCDHX}
                  style={{
                    fontSize: matches ? 12 : 8,
                  }}
                  variant="contained"
                  color="primary"
                ><img src={eye_icon} style={{ marginRight: 10 }} className="icon-view" />&nbsp;&nbsp;<strong>DCD HX</strong></Button>
              </div>
              <div className="col-md-12 col-sm-12 col-12">
                <div style={{ fontWeight: "400", color: "#939393" }}>Decision to Discharge/Disposition:</div>
                <div className="row mt-2 align-items-center">
                  <div className="col-sm-5"><strong className="">Discharge/Disposition</strong></div>
                  <div className="col-sm-7">
                    <div className="form-group">
                      <span style={stylesx.chipAlignment}>
                        <Chip
                          label="Yes"
                          icon={dischargeDispositionStatus=="yes" ? <DoneIcon style={{ color: "white" }} />:undefined}
                          style={dischargeDispositionStatus=="yes" ? {color: "white",backgroundColor: "rgb(19 213 159)"} : { color: "#070707", backgroundColor: "#F5F5F5"}}
                        />
                      </span>
                      <span style={stylesx.chipAlignment}>
                        <Chip
                          label="No"
                          icon={dischargeDispositionStatus=="no" ? <DoneIcon style={{ color: "white" }} />:undefined}
                          style={dischargeDispositionStatus=="no" ? {color: "white",backgroundColor: "rgb(19 213 159)"} : { color: "#070707", backgroundColor: "#F5F5F5"}}
                        />
                      </span>
                    </div>
                  </div>
                </div>
                <div className="row align-items-center">
                  <div className="col-sm-5"><strong>Discharge/Disposition Type</strong></div>
                  <div className="col-sm-7">
                    <div className="form-group">
                      <span style={stylesx.chipAlignment}>
                        <Chip
                          label="Discharge to Home"
                          icon={dischargeDisposition=="discharged" ? <DoneIcon style={{ color: "white" }} />:undefined}
                          style={dischargeDisposition=="discharged" ? {color: "white",backgroundColor: "rgb(19 213 159)"} : { color: "#070707", backgroundColor: "#F5F5F5"}}
                        />
                      </span>
                      <span style={stylesx.chipAlignment}>
                        <Chip
                          label="Admission"
                          icon={dischargeDisposition=="admitted" ? <DoneIcon style={{ color: "white" }} />:undefined}
                          style={dischargeDisposition=="admitted" ? {color: "white",backgroundColor: "rgb(19 213 159)"} : { color: "#070707", backgroundColor: "#F5F5F5"}}
                        />
                      </span>
                      <span style={stylesx.chipAlignment}>
                        <Chip
                          label="Transfer to another facility"
                          icon={dischargeDisposition=="transferred" ? <DoneIcon style={{ color: "white" }} />:undefined}
                          style={dischargeDisposition=="transferred" ? {color: "white",backgroundColor: "rgb(19 213 159)"} : { color: "#070707", backgroundColor: "#F5F5F5"}}
                        />
                      </span>
                      <span style={stylesx.chipAlignment}>
                        <Chip
                          label="Deceased"
                          icon={dischargeDisposition=="deceased" ? <DoneIcon style={{ color: "white" }} />:undefined}
                          style={dischargeDisposition=="deceased" ? {color: "white",backgroundColor: "rgb(19 213 159)"} : { color: "#070707", backgroundColor: "#F5F5F5"}}
                        />
                      </span>
                    </div>
                  </div>
                </div>
                {
                  dischargeDisposition === "admitted"?
                  <div className="row align-items-center">
                    <div className="col-sm-5"><strong>Admission Type</strong></div>
                    <div className="col-sm-7">
                      <div className="form-group">
                        <span style={stylesx.chipAlignment}>
                          <Chip
                            label="OR (Operation Room)"
                            icon={dischargeDispositionType=="operation-room" ? <DoneIcon style={{ color: "white" }} />:undefined}
                            style={dischargeDispositionType=="operation-room" ? {color: "white",backgroundColor: "rgb(19 213 159)"} : { color: "#070707", backgroundColor: "#F5F5F5"}}
                          />
                        </span>
                        <span style={stylesx.chipAlignment}>
                          <Chip
                            label="Non-Monitored Ward"
                            icon={dischargeDispositionType=="non-monitored-ward" ? <DoneIcon style={{ color: "white" }} />:undefined}
                            style={dischargeDispositionType=="non-monitored-ward" ? {color: "white",backgroundColor: "rgb(19 213 159)"} : { color: "#070707", backgroundColor: "#F5F5F5"}}
                          />
                        </span>
                        <span style={stylesx.chipAlignment}>
                          <Chip
                            label="Monitored Ward"
                            icon={dischargeDispositionType=="monitored-ward" ? <DoneIcon style={{ color: "white" }} />:undefined}
                            style={dischargeDispositionType=="monitored-ward" ? {color: "white",backgroundColor: "rgb(19 213 159)"} : { color: "#070707", backgroundColor: "#F5F5F5"}}
                          />
                        </span>
                        <span style={stylesx.chipAlignment}>
                          <Chip
                            label="Step-Down Unit"
                            icon={dischargeDispositionType=="stepdown-unit" ? <DoneIcon style={{ color: "white" }} />:undefined}
                            style={dischargeDispositionType=="stepdown-unit" ? {color: "white",backgroundColor: "rgb(19 213 159)"} : { color: "#070707", backgroundColor: "#F5F5F5"}}
                          />
                        </span>
                        <span style={stylesx.chipAlignment}>
                          <Chip
                            label="Intensive Care Unit (ICU)"
                            icon={dischargeDispositionType=="icu" ? <DoneIcon style={{ color: "white" }} />:undefined}
                            style={dischargeDispositionType=="icu" ? {color: "white",backgroundColor: "rgb(19 213 159)"} : { color: "#070707", backgroundColor: "#F5F5F5"}}
                          />
                        </span>
                        <span style={stylesx.chipAlignment}>
                          <Chip
                            label="Cardiac Care Unit (CCU)"
                            icon={dischargeDispositionType=="ccu" ? <DoneIcon style={{ color: "white" }} />:undefined}
                            style={dischargeDispositionType=="ccu" ? {color: "white",backgroundColor: "rgb(19 213 159)"} : { color: "#070707", backgroundColor: "#F5F5F5"}}
                          />
                        </span>
                        <span style={stylesx.chipAlignment}>
                          <Chip
                            label="Stroke Unit"
                            icon={dischargeDispositionType=="stroke-unit" ? <DoneIcon style={{ color: "white" }} />:undefined}
                            style={dischargeDispositionType=="stroke-unit" ? {color: "white",backgroundColor: "rgb(19 213 159)"} : { color: "#070707", backgroundColor: "#F5F5F5"}}
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                  :undefined
                }

                {
                  dischargeDisposition!=="deceased"?
                  undefined:
                  <div className="row mt-2">
                    <div className="col-sm-12 mb-2">
                      <Button
                        onClick={DCDHX}
                        style={{
                          fontSize: matches ? 12 : 8,
                        }}
                        variant="contained"
                        color="primary"
                      ><img src={eye_icon} style={{ marginRight: 10 }} className="icon-view" />&nbsp;&nbsp;<strong>DCD HX</strong></Button>
                    </div>
                    <div className="col-sm-12">
                      <div className="form-group">
                        <TextField
                          multiline
                          label="Discharge/Disposition Treatment Plan"
                          value={dischargeDispositionRemarks}
                          rows={5}
                          className="textInputStyle"
                          variant="filled"
                          InputProps={{
                            className: classes.input,
                            classes: {
                              input: classes.input,
                            },
                            disableUnderline: true,
                            rows: 5
                          }}
                          />
                      </div>
                    </div>
                  </div>
                }


                <div className="row mt-2">
                  <div className="col-sm-12">
                    <div className="form-group">
                      <TextField
                        multiline
                        label="Follow Up Instruction/Appointment"
                        value={dischargeDispositionFollowup}
                        rows={5}
                        className="textInputStyle"
                        variant="filled"
                        InputProps={{
                          className: classes.input,
                          classes: {
                            input: classes.input,
                          },
                          disableUnderline: true,
                          rows: 5
                        }}
                        />
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </>:

          <div>
            <div
              style={{
                height: "20px",
              }}
            />
            <div className={classesForTabs.root}>
              <Tabs
                classes={{
                  root: classesForTabs.root,
                  scroller: classesForTabs.scroller,
                }}
                value={value}
                onChange={handleChange}
                textColor="primary"
                TabIndicatorProps={{ style: { background: "#12387a" } }}
                centered={false}
                variant="scrollable"
                fullwidth
              >
                <Tab
                  style={{
                    color: "white",
                    borderRadius: 15,
                    outline: "none",
                    color: value === 0 ? "#12387a" : "#3B988C",
                  }}
                  label="Discharge Summary"
                />
                <Tab
                  disabled={true}
                  style={{
                    color: "white",
                    borderRadius: 15,
                    outline: "none",
                    color: value === 1 ? "#12387a" : "#3B988C",
                  }}
                  label="Discharge Medication"
                />
              </Tabs>
            </div>

            {value === 0 ? (
              <div
                style={{ flex: 4, display: "flex", flexDirection: "column" }}
                // className='container-fluid'
              >
                <div
                  style={{
                    backgroundColor: "white",
                    marginTop: "20px",
                    borderRadius: 5,
                  }}
                >
                  <h6 style={{ color: "grey", padding: 10, fontWeight: "bold" }}>
                    Discharge/Disposition
                  </h6>

                  <div className="row" style={{ marginBottom: 20 }}>
                    <div className="col-md-12" style={styles.form}>
                      <FormControl>
                        <RadioGroup
                          row
                          aria-label="payMethod"
                          name="edrCompletionReason"
                          onChange={onChangeDisposition}
                          value={edrCompletionReason}
                        >

                          
                          <FormControlLabel
                            value="discharged"
                            control={<Radio />}
                            color="default"
                            label="Discharge to Home" />
                          
                          <FormControlLabel
                            value="admitted"
                            control={<Radio />}
                            color="default"
                            label="Admission" />
                          
                          <FormControlLabel
                            value="transferred"
                            control={<Radio />}
                            color="default"
                            label="Transfer to another facility" />
                          
                          <FormControlLabel
                            value="deceased"
                            control={<Radio />}
                            color="default"
                            label="Deceased" />

                          {/*

                          <FormControlLabel
                            value="admitted"
                            control={<Radio />}
                            color="default"
                            label="Admitted"
                          />

                          <FormControlLabel
                            value="discharged"
                            control={<Radio />}
                            color="default"
                            label="Discharged"
                          />

                          <FormControlLabel
                            value="transferred"
                            control={<Radio />}
                            color="default"
                            label="Transferred"
                          />

                          <FormControlLabel
                            value={"deceased"}
                            control={<Radio />}
                            color="default"
                            label="Deceased"
                          />
                          */}
                        </RadioGroup>
                      </FormControl>
                    </div>
                  </div>
                </div>
                {
                  edrCompletionReason==="admitted"?
                  <div
                    style={{
                      backgroundColor: "white",
                      marginTop: "20px",
                      borderRadius: 5,
                    }}
                  >
                    <h6 style={{ color: "grey", padding: 10, fontWeight: "bold" }}>Admission Type:</h6>

                    <div className="row" style={{ marginBottom: 20 }}>
                      <div className="col-md-12" style={styles.form}>
                        <FormControl>
                          <RadioGroup
                            row
                            aria-label="payMethod"
                            name="edrAdmissionType"
                            onChange={onChangeAdmissionType}
                            value={edrAdmissionType}
                          >
                            
                            <FormControlLabel
                              value="operation-room"
                              control={<Radio />}
                              color="default"
                              label="Operation Room" />
                            
                            <FormControlLabel
                              value="non-monitored-ward"
                              control={<Radio />}
                              color="default"
                              label="Non-Monitored Ward" />
                            
                            <FormControlLabel
                              value="monitored-ward"
                              control={<Radio />}
                              color="default"
                              label="Monitored Ward" />
                            
                            <FormControlLabel
                              value="stepdown-unit"
                              control={<Radio />}
                              color="default"
                              label="Step-Down Unit" />
                            
                            <FormControlLabel
                              value="icu"
                              control={<Radio />}
                              color="default"
                              label="Intensive Care Unit (ICU)" />
                            
                            <FormControlLabel
                              value="ccu"
                              control={<Radio />}
                              color="default"
                              label="Cardiac Care Unit (CCU)" />
                            
                            <FormControlLabel
                              value="stroke-unit"
                              control={<Radio />}
                              color="default"
                              label="Stroke Unit" />
                          </RadioGroup>
                        </FormControl>
                      </div>
                    </div>
                  </div>:undefined
                }

                {
                  edrCompletionReason==="deceased"?
                  undefined:
                  <div
                    style={{
                      backgroundColor: "white",
                      marginTop: "20px",
                      borderRadius: 5,
                    }}
                  >
                    <h6 style={{ color: "grey", padding: 10, fontWeight: "bold" }}>
                      Discharge Requirements
                    </h6>

                    <div className="row" style={{ marginBottom: 20 }}>
                      <div className="col-md-12" style={styles.form}>
                        <FormControl>
                          <RadioGroup
                            row
                            aria-label="payMethod"
                            name="edrCompletionRequirement"
                            onChange={onChangeRequirements}
                            value={edrCompletionRequirement}
                          >
                            <FormControlLabel
                              value="withCare"
                              control={<Radio />}
                              label="With Customer Care"
                            />
                            <FormControlLabel
                              value="withoutCare"
                              control={<Radio />}
                              label="Without Customer Care"
                            />
                          </RadioGroup>
                        </FormControl>
                      </div>
                    </div>
                  </div>
                }

                {
                  edrCompletionReason==="deceased"?
                  undefined:
                  <div style={{ marginTop: "20px" }} className="row">
                    <div className="col-sm-12 mb-2">
                      <Button
                        onClick={DCDHX}
                        style={{
                          fontSize: matches ? 12 : 8,
                        }}
                        variant="contained"
                        color="primary"
                      ><img src={eye_icon} style={{ marginRight: 10 }} className="icon-view" />&nbsp;&nbsp;<strong>DCD HX</strong></Button>
                    </div>
                    <div className="col-md-12 col-sm-12 col-12">
                      <TextField
                        multiline
                        label="Discharge/Disposition Treatment Plan"
                        name={"dischargeNotes"}
                        value={dischargeNotes}
                        // error={dischargeNotes === '' && detailsForm}
                        onChange={onChangeValue}
                        rows={4}
                        className="textInputStyle"
                        variant="filled"
                        InputProps={{
                          className: classes.multilineColor,
                          classes: {
                            input: classes.multilineColor,
                          },
                        }}
                        // inputProps={{ maxLength: 12 }}
                      />
                    </div>
                  </div>
                }

                <div style={{ marginTop: "20px" }} className="row">
                  <div className="col-md-12 col-sm-12 col-12">
                    <TextField
                      multiline
                      label="Follow Up Instruction/Appointment"
                      name={"followUpInstruction"}
                      value={followUpInstruction}
                      // error={FollowUpInstruction === '' && detailsForm}
                      onChange={onChangeValue}
                      rows={4}
                      className="textInputStyle"
                      variant="filled"
                      InputProps={{
                        className: classes.multilineColor,
                        classes: {
                          input: classes.multilineColor,
                        },
                      }}
                      // inputProps={{ maxLength: 12 }}
                    />
                  </div>
                </div>

                <div
                  className="row d-flex"
                  style={{ marginBottom: "80px", marginTop: "25px" }}
                >
                  <div className="mr-auto p-2">
                    <img
                      onClick={() => props.history.goBack()}
                      src={Back}
                      style={{
                        width: 45,
                        height: 35,
                        marginTop: "7px",
                        cursor: "pointer",
                      }}
                    />
                  </div>
                  <div className="p-2">
                    <Button
                      style={styles.stylesForButton}
                      //disabled={!validateFormType1()}
                      onClick={onClick}
                      variant="contained"
                      color="primary"
                    >
                      Next &raquo;
                    </Button>
                  </div>
                  {/* <div className="p-2">
                    <Button
                      // onClick={submitDischargeSummary}
                      style={styles.stylesForButton}
                      variant="contained"
                      color="primary"
                    >
                      <strong style={{ fontSize: "12px" }}>Submit</strong>
                    </Button>
                  </div> */}
                </div>
              </div>
            ) : value === 1 ? (
              <>
                <div className="row row-5 mt-3">
                  {
                    dischargeMedArray.medicine && dischargeMedArray.medicine.length > 0 ?
                    Object.entries(dischargeMedArray.medicine).map((obj, entry) => {
                      return (
                        <div className="col-12 col-md-6 col-lg-6 col-xl-6">
                          <div className="card card-detail-info card-detail-small mt-2">
                            <div className="row row-5">
                              <div className="col-sm-12 card-detail-block block-xdiv mt-1"><strong className="lblX">Item Name</strong><span className="valX"><strong className="text-capitalize">{obj[1].itemId.tradeName.toLowerCase()} ({obj[1].itemId.issueUnit})</strong></span></div>
                              <div className="col card-detail-block block-xdiv mt-1"><strong className="lblX">Dosage</strong><span className="valX">{obj[1].dosage}</span></div>
                              <div className="col card-detail-block block-xdiv mt-1"><strong className="lblX">Duration</strong><span className="valX">{obj[1].duration}</span></div>
                              <div className="col card-detail-block block-xdiv mt-1"><strong className="lblX">Frequency</strong><span className="valX">{medicineFrequencyArray[obj[1].frequency]}</span></div>
                              <div className="col card-detail-block block-xdiv mt-1"><strong className="lblX">Route</strong><span className="valX">{obj[1].route}</span></div>
                              <div className="col card-detail-block block-xdiv mt-1"><strong className="lblX">{matches ? "Requested Qty" : "Req. Qty"}</strong><span className="valX">{(Math.ceil(obj[1].frequency)>=11 || Math.ceil(obj[1].frequency)===0)?obj[1].requestedQty:obj[1].dosage*obj[1].duration*obj[1].frequency}</span></div>
                            </div>
                          </div>
                        </div>
                      )
                    })
                    :<div className='col-12'><div className='alert alert-info'>There is no discharge medication request at the moment.</div></div>
                  }
                </div>
                <div
                  className="container-fluid mt-4"
                >

                  <div className="row" style={{ marginBottom: "25px" }}>
                    <div className="col-12">
                    </div>
                    <div className="col-4" style={{ paddingLeft: 0 }}>
                      <img
                        onClick={() => props.history.goBack()}
                        src={Back}
                        style={{ width: 45, height: 35, cursor: "pointer" }}
                      />
                    </div>
                    <div
                      className="col-8 d-flex justify-content-end"
                      style={{ paddingRight: 0 }}
                    >
                      <Button
                        onClick={addPharmaRequest}
                        style={styles.stylesForButton}
                        variant="contained"
                        color="primary"
                      >
                        <img className="icon-style" src={plus_icon} />
                        &nbsp;&nbsp;
                        <strong style={{ fontSize: "12px" }}>
                          Pharmacy Request
                        </strong>
                      </Button>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <div
                style={{ flex: 4, display: "flex", flexDirection: "column" }}
                className="container"
              ></div>
            )
            }

            {openItemDialog ? (
              <ViewSingleRequest
                item={item}
                openItemDialog={openItemDialog}
                viewItem={viewItem}
              />
            ) : (
              undefined
            )}
          </div>
        }
        <Notification
          msg={errorMsg}
          open={openNotification}
          success={successMsg}
        />
      </div>
    </div>
  );
}

const mapStateToProps = ({ CheckingReducer }) => {
  const { count, patientDetails } = CheckingReducer;
  return { count, patientDetails };
};
export default connect(mapStateToProps, {
  funForReducer,
  setPatientDetailsForReducer,
})(LabRadRequest);
