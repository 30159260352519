import React, { useState, useEffect, useReducer } from "react";
import cookie from "react-cookies";
import Header from "../../components/Header/Header";
import PatientDetails from "../../components/PatientDetails/PatientDetailsDirect";
import FormatDate from "../../utils/formatDateTime";
import PharmaIcon from "../../assets/img/Pharma.png";
import CustomTable from "../../components/Table/Table";
import Button from "@material-ui/core/Button";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import {Radio, RadioGroup, FormControlLabel, FormLabel, TextField} from "@material-ui/core";
import Loader from "react-loader-spinner";
import Notification from "../../components/Snackbar/Notification.js";

import PatientDetailsQuick from "../../components/PatientDetails/PatientDetailsQuick";
import PatientClinicalInformation from "../../components/PatientDetails/PatientClinicalInformation";
// import PatientDetails2 from "../../components/PatientDetails/PatientDetailsDirect";
import {medicineFrequencyArray} from "../NotesScreen/configForNotesScreen"
import moment from 'moment';
import classNames from "classnames";

import Icon from '@material-ui/core/Icon';
import CloseIcon from '@material-ui/icons/Close';
import MicIcon from "@material-ui/icons/Mic";
import StopIcon from "@material-ui/icons/Stop";
import MicRecorder from "mic-recorder-to-mp3";

import axios from "axios";

import {
  audioURL,
  updateConflictRequestByClinicalPharma,
} from "../../public/endpoins";

const Mp3Recorder = new MicRecorder({ bitRate: 128 });

export const tableHeadingForPharma = [
  "Request ID",
  "Date/Time",
  "Requester",
  "Status",
  "Action",
];
export const tableDataKeysForPharma = [
  "pharmacyRequestNo",
  "createdAt",
  "requesterName",
  "status",
];

const actions = { view: false, edit: true };

const styles = {
  stylesForButton: {
    color: "white",
    cursor: "pointer",
    borderRadius: 5,
    backgroundColor: "#2c6ddd",
    width: "130px",
    height: "45px",
    outline: "none",
  },
  inputContainerForTextField: {
    // marginTop: 10,
  },
  inputContainerForDropDown: {
    marginTop: 10,
  },
  textFieldPadding: {
    // paddingLeft: 5,
    // paddingRight: 5,
  },
  save: {
    color: "white",
    cursor: "pointer",
    borderRadius: 5,
    backgroundColor: "#ba55d3",
    width: "130px",
    height: "45px",
    outline: "none",
  },
  generate: {
    color: "white",
    cursor: "pointer",
    borderRadius: 5,
    backgroundColor: "#e877a1",
    height: "45px",
    width: "190px",
    outline: "none",
  },
  None: {
    display: "none",
  },
  form: {
    backgroundColor: "white",
    borderRadius: "5px",
    marginTop: "10px",
    padding: "10px",
    textAlign: "center",
  },
  form2: {
    backgroundColor: "white",
    borderRadius: "5px",
    padding: "5px 15px",
    marginTop: '15px',
  },
  form3: {
    backgroundColor: "white",
    borderRadius: "5px",
    padding: "0px 15px",
  },
};

function PatientDetailsWithPharamReq(props) {

  const matches = useMediaQuery("(min-width:600px)");

  const initialState = {
    doctorNotesArray: "",
    consultationNo: "",
    date: new Date(),
    description: "",

    residentNoteArray: "",
    doctorNotes: "",
    note: "",
    doctor: cookie.load("current_user").name,

    pharmacyRequestArray: "",
    pharmacyConflictRequestArray: "",
    dataPrugeCompare: "",
    requestType: "",
    section: "",
    code: [],
    patientId: "",
    diagnosisArray: "",
    medicationArray: "",
    dischargeMedication: {},

    price: "",

    isBlocked: false,
    isRecording: false,
    isFormSubmitted: false,

    isRecordingForConsultant: false,

    blobURL: "",
    fileAudio: null,
    fileAudioForConsultant: null,
    audioUrl: "",
    hasConflict: "",
  };

  function reducer(state, { field, value }) {
    return {
      ...state,
      [field]: value,
    };
  }

  const [state, dispatch] = useReducer(reducer, initialState);

  const {
    pharmacyRequestArray,
    pharmacyConflictRequestArray,
    dataPrugeCompare,
    code,
    note,
    section,
    diagnosisArray,
    medicationArray,
    dischargeMedication,

    doctorNotesArray,
    doctorNotes,

    isBlocked,
    isRecording,
    isFormSubmitted,

    isRecordingForConsultant,
    blobURL,
    fileAudio,
    fileAudioForConsultant,

    hasConflict,
  } = state;

  const [currentUser] = useState(cookie.load("current_user"));
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setsuccessMsg] = useState("");
  const [openNotification, setOpenNotification] = useState(false);

  const [patientDetails, setPatientDetails] = useState("");
  const [openItemDialog, setOpenItemDialog] = useState(false);
  const [openRequestDialog, setOpenRequestDialog] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const [item, setItem] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [itemFound, setItemFound] = useState("");
  const [itemFoundSuccessfull, setItemFoundSuccessfully] = useState(false);
  const [doctorForm, setDoctorForm] = useState(false);
  const [dialogForm, setDialogForm] = useState(false);

  const [labForm, setLabForm] = useState(false);
  const [editForm, setEditForm] = useState(false);
  const [radForm, setRadForm] = useState(false);

  const [id, setId] = useState("");
  const [searchRadioQuery, setSearchRadioQuery] = useState("");

  const [, setIsLoading] = useState(true);
  const [selectedNoteToEdit, setSelectedNoteToEdit] = useState("");
  const [viewRecord, setViewRecord] = useState(false);

  const [showConfirmationModel, setShowConfirmationModel] = useState(false);

  const [selectedEdr, setSelectedEdr] = useState("");


  if (openNotification) {
    setTimeout(() => {
      setOpenNotification(false);
      setErrorMsg("");
      setsuccessMsg("");
    }, 4000);
  }

  useEffect(() => {
    console.log("props.history.location.state", props.history.location.state);
    const { selectedRecord } = props.history.location.state;
    setSelectedEdr(selectedRecord);

    const object = {
      patientName:
        selectedRecord.patientId.name[0].given[0] +
        " " +
        selectedRecord.patientId.name[0].family,
      mrn: selectedRecord.patientId.identifier[0].value,
      age: selectedRecord.patientId.age,
      gender: selectedRecord.patientId.gender,
      weight: selectedRecord.patientId.weight,
    };
    setPatientDetails(object);

    Object.entries(selectedRecord).map(([key, val]) => {
      if (val && typeof val === "object") {
        if (key === "doctorNotes") {
          val.map(
            (d) => (
              (d.assignedTime = d.assignedTime
                ? FormatDate(d.assignedTime)
                : ""),
              (d.doctor =
                d.addedBy.name[0].given[0] + " " + d.addedBy.name[0].family)
            )
          );

          dispatch({
            field: "doctorNotesArray",
            value: val.reverse(),
          });

          if (val && val.length > 0) {
            let data = [];
            val.map((d) => {
              d.code.map((singleCode) => {
                let found = data.find((i) => i === singleCode);
                if (!found) {
                  data.push(singleCode);
                }
              });
            });
            console.log("diagnosis aray", data);
            dispatch({ field: "diagnosisArray", value: data });
          }
        } else if (key === "pharmacyRequest") {
          val.map(
            (d) =>
              (d.requesterName =
                d.requestedBy.name[0].given[0] +
                " " +
                d.requestedBy.name[0].family)
          );

          if (val && val.length > 0) {
            let data = [];
            val.map((d) => {
              d.item.map((singleCode) => {
                let found = data.find((i) => i === singleCode.itemName);
                if (!found) {
                  data.push(singleCode.itemName);
                }
              });
            });
            dispatch({
              field: "medicationArray",
              value: data.reverse(),
            });

            dispatch({
              field: "pharmacyRequestArray",
              value: val.reverse(),
            });
          }
        }
      } else {
        dispatch({ field: key, value: val });
      }
    });

    if(props.history.location.state.tabName === "Discharge Medication Request")
    {

      console.log("Discharge Medication Request", selectedRecord.dischargeRequest.dischargeMedication)

      if (selectedRecord.dischargeRequest.dischargeMedication.conflictby && selectedRecord.dischargeRequest.dischargeMedication.conflictmedicine){
        dispatch({field: 'hasConflict', value: "yes"})
      }

      if (selectedRecord.dischargeRequest.dischargeMedication.conflictnotes){
        dispatch({field: 'note', value: selectedRecord.dischargeRequest.dischargeMedication.conflictnotes})
      }

      if (selectedRecord.dischargeRequest.dischargeMedication.conflictaudio){
        dispatch({field: 'blobURL', value: audioURL + "/" +selectedRecord.dischargeRequest.dischargeMedication.conflictaudio.replace('\\', '/')})
      }

      dispatch({
        field: 'dischargeMedication',
        value: selectedRecord.dischargeRequest.dischargeMedication
      })

      if (selectedRecord.dischargeRequest.dischargeMedication.medicine){
        dispatch({
          field: "medicationArray",
          value: selectedRecord.dischargeRequest.dischargeMedication.medicine,
        });



        dispatch({
          field: "pharmacyRequestArray",
          value: selectedRecord.dischargeRequest.dischargeMedication.medicine,
        });


        let dbConf = selectedRecord.dischargeRequest.dischargeMedication.conflictmedicine;
        Object.entries(dbConf).map(([ixi, ixv]) => {
          let itemExists = selectedRecord.dischargeRequest.dischargeMedication.medicine.filter((itemx) => {
            console.log('itemx', itemx.itemId._id, ixv.itemId._id);
            return itemx.itemId._id == ixv.itemId._id;
          });
          ixv.itemExists = itemExists.length > 0?'yes':'no';
        })


        console.log('MU::', selectedRecord.dischargeRequest.dischargeMedication)

        dispatch({
          field: "pharmacyConflictRequestArray",
          value: dbConf,
        });
      }
    }


  }, []);

  const handleViewPharmacy = (obj) => {
    console.log(obj);
    props.history.push({
      pathname: "requestWithPatientDetails/completerequest",
      state: {
        selectedItem: obj,
        comingFor: "view",
      },
    });
  };

  const handleEditPharmacy = (obj) => {
    console.log(obj);
    props.history.push({
      pathname: "requestWithPatientDetails/completerequest",
      state: {
        selectedItem: {
          ...obj,
          edrId: props.history.location.state.selectedRecord._id,
        },
        comingFor: "edit",
      },
    });
  };




  const startRecording = () => {
    if (isBlocked) {
      setOpenNotification(true);
      setErrorMsg("The Record is Blocked by the Browser!");
    } else {

      setOpenNotification(true);
      setsuccessMsg("The Record has been started!");
      Mp3Recorder.start()
        .then(() => {
          dispatch({ field: "isRecording", value: true });
          console.log('handleView::rec::',true)
        })
        .catch((e) => console.error(e));
    }
  };

  const stopRecording = () => {
    Mp3Recorder.stop()
      .getMp3()
      .then(([buffer, blob]) => {

        const blobURLFinal = URL.createObjectURL(blob);
        dispatch({ field: "blobURL", value: blobURLFinal });

        dispatch({ field: "isRecording", value: false });
        var file = new File([blob], "first", { type: "audio/mp3" });

        dispatch({ field: "fileAudio", value: file });
        console.log('handleView::rec::',false)
      })
      .catch((e) => console.log(e));
  };



  const validateFields = () => {

    let panadTS = true;
    let noticesHtml = [];

    if ( hasConflict.trim().length<=0 ){
      panadTS = false;
    }
    if ( (note.trim().length<=0 && (fileAudio === null || fileAudio === "" )) && hasConflict === 'yes' ){
      panadTS = false;
    }
    

    if (panadTS === false){
      setOpenNotification(true);
      setErrorMsg("There are some missing data for this submission.");
    }

    console.log('validateFields::', panadTS);
    return panadTS;
  }


  const addConflictReport = () => {
    // PharmaReq 
    dispatch({
      field: 'isFormSubmitted',
      value: true
    });

    if (
      validateFields()
    ){
      
      setOpenNotification(true);
      setsuccessMsg("We are sending ");
      
      //SS
      let formData = new FormData();
      formData.append("requestedBy", currentUser._id);
      formData.append("edrId", selectedEdr._id);
      formData.append("notes", note);
      formData.append("isConflicted", hasConflict);

      console.log('validateFields::', fileAudio !== "" && fileAudio instanceof Blob, fileAudio !== "", fileAudio instanceof Blob, fileAudio)

      if ( fileAudio !== "" && fileAudio instanceof Blob )
      {
        formData.append("AudioFile", fileAudio, 'conflict-vn.mp3');
      }

      axios
        .put(updateConflictRequestByClinicalPharma, formData)//updateConflictRequestByClinicalPharma
        .then((res) => {
          console.log( 'PharmaReq', res.data );
          if (res.data.success) {
            props.history.push({
              pathname: "/dashboard/home/pharmanotes",
            });
          } else if (!res.data.success) {
            setOpenNotification(true);
            setErrorMsg("Error while adding the Parma Request");
          }
        })
        .catch((e) => {
          console.log('PharmaReq', "error after adding Parma Request", e);
          setOpenNotification(true);
          setErrorMsg("An error occurred while adding the Parma Request.");
        });
        
    }
    // else{
    //   setOpenNotification(true);
    //   setErrorMsg("Error while adding the Parma Request");
    // }
  };




  return (
    <div
      style={{
        backgroundColor: "rgb(19 213 159)",
        position: "fixed",
        display: "flex",
        width: "100%",
        height: "100%",
        flexDirection: "column",
        flex: 1,
        overflowY: "scroll",
      }}
    >
      <Header history={props.history} />

      <PatientDetailsQuick
        edrId={selectedEdr._id} />

      <div className="cPadding">
        <div className="subheader" style={{ marginLeft: "-10px" }}>
          <div>
            <img src={PharmaIcon} />

            <h4>Medications</h4>
          </div>
        </div>

        {patientDetails ? (
          <PatientClinicalInformation
            edrId={selectedEdr._id}
          />
        ) : (
          undefined
        )}


        {
          props.history.location.state.tabName && props.history.location.state.tabName === "Discharge Medication Request"?
          <>
            <div className="row row-5 mt-3">
              <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                <div className="card card-detail-info card-detail-small mt-2">
                  <div className="row row-5">
                    <div className="col-5 card-detail-block block-xdiv mb-2 mb-md-3"><strong className="lblX">Request ID:</strong> <span className="valX">{(selectedEdr.requestNo + " ").replace('KHMC', 'DPM-')}</span></div>
                    <div className="col-7 card-detail-block block-xdiv mb-2 mb-md-3"><strong className="lblX">Date/Time</strong> <span className="valX">{moment(dischargeMedication.date).format('MMM DD, YYYY LT')}</span></div>
                    {
                      pharmacyRequestArray && pharmacyRequestArray.length > 0 ?
                      Object.entries(pharmacyRequestArray).map((obj, entry) => {
                        return (
                          <>
                            {
                              entry === 0?undefined:<div className="col-12"><hr /></div>
                            }
                            <div className="col-sm-12 card-detail-block block-xdiv mb-2"><strong className="lblX">Item Name</strong><span className="valX"><strong className="text-capitalize">{obj[1].itemId.tradeName.toLowerCase()} ({obj[1].itemId.issueUnit})</strong></span></div>
                            <div className="col card-detail-block block-xdiv mt-1"><strong className="lblX">Dosage</strong><span className="valX">{obj[1].dosage}</span></div>
                            <div className="col card-detail-block block-xdiv mt-1"><strong className="lblX">Duration</strong><span className="valX">{obj[1].duration}</span></div>
                            <div className="col card-detail-block block-xdiv mt-1"><strong className="lblX">Frequency</strong><span className="valX">{medicineFrequencyArray[obj[1].frequency]}</span></div>
                            <div className="col card-detail-block block-xdiv mt-1"><strong className="lblX">Route</strong><span className="valX">{obj[1].route}</span></div>
                            <div className="col card-detail-block block-xdiv mt-1"><strong className="lblX">{matches ? "Requested Qty" : "Req. Qty"}</strong><span className="valX">{(Math.ceil(obj[1].frequency)>=11 || Math.ceil(obj[1].frequency)===0)?obj[1].requestedQty:obj[1].dosage*obj[1].duration*obj[1].frequency}</span></div>
                            <div className="col card-detail-block block-xdiv mt-1"><strong className="lblX">Refill</strong><span className="valX">{Math.ceil(obj[1].refill)}</span></div>
                            <div className="col-12 card-detail-block block-xdiv mt-1"><strong className="lblX">Notes</strong><span className="valX">{(obj[1].notes)}</span></div>
                            {
                              obj[1].audio && obj[1].audio.includes('uploads/')?
                              <div className="col-12">
                                <audio
                                  style={{
                                    marginRight: 10,
                                    width: matches ? 450 : 260,
                                    marginTop: 6,
                                  }}
                                  src={obj[1].audio}
                                  controls="controls"
                                />
                              </div>:undefined
                            }
                          </>
                        )
                      })
                      :<div className='alert alert-info'>There is no discharge medication request at the moment.</div>
                    }
                  </div>
                  <div className="card-status-action">
                    {
                      (
                        selectedEdr.status==="completed"?
                        <span className={classNames(`badge`, `status-completed`)}>Delivered</span>:
                        <span className={classNames(`badge`, `status-pending`)}>Pending</span>
                      )
                    }
                  </div>

                </div>
              </div>



              <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                <div className="card bg-light card-detail-info card-detail-small mt-2">
                  <div className="row row-5">
                    <div className="col-5 card-detail-block block-xdiv mb-2 mb-md-3"><strong className="lblX">Request ID:</strong> <span className="valX">{(selectedEdr.requestNo + " ").replace('KHMC', 'DPM-')}</span></div>
                    <div className="col-7 card-detail-block block-xdiv mb-2 mb-md-3"><strong className="lblX">Date/Time</strong> <span className="valX">{moment(dischargeMedication.date).format('MMM DD, YYYY LT')}</span></div>
                    {
                      pharmacyConflictRequestArray && pharmacyConflictRequestArray.length > 0 ?
                      Object.entries(pharmacyConflictRequestArray).map((obj, entry) => {

                        let xOldItem = pharmacyRequestArray.filter((itemx) => {
                          return itemx.itemId._id == obj[1].itemId._id;
                        });

                        if (xOldItem.length>0){
                          xOldItem = xOldItem[0];
                        }


                        return (
                          <>
                            {
                              entry === 0?undefined:<div className="col-12"><hr /></div>
                            }
                            <div className="col-sm-12 card-detail-block block-xdiv mb-2"><strong className="lblX">Item Name</strong><span className="valX"><strong className="text-capitalize">{obj[1].itemExists==="no"?<span className="text-danger">Removed: </span>:undefined}{obj[1].itemId.tradeName.toLowerCase()} ({obj[1].itemId.issueUnit})</strong></span></div>
                            <div className="col card-detail-block block-xdiv mt-1"><strong className={classNames('lblX', (xOldItem.dosage != obj[1].dosage?'text-danger':''))}>Dosage</strong><span className="valX">{obj[1].dosage}</span></div>
                            <div className="col card-detail-block block-xdiv mt-1"><strong className={classNames('lblX', (xOldItem.duration != obj[1].duration?'text-danger':''))}>Duration</strong><span className="valX">{obj[1].duration}</span></div>
                            <div className="col card-detail-block block-xdiv mt-1"><strong className={classNames('lblX', (xOldItem.frequency != obj[1].frequency?'text-danger':''))}>Frequency</strong><span className="valX">{medicineFrequencyArray[obj[1].frequency]}</span></div>
                            <div className="col card-detail-block block-xdiv mt-1"><strong className={classNames('lblX', (xOldItem.route != obj[1].route?'text-danger':''))}>Route</strong><span className="valX">{obj[1].route}</span></div>
                            <div className="col card-detail-block block-xdiv mt-1"><strong className={classNames('lblX', (xOldItem.requestedQty != obj[1].requestedQty?'text-danger':''))}>{matches ? "Requested Qty" : "Req. Qty"}</strong><span className="valX">{(Math.ceil(obj[1].frequency)>=11 || Math.ceil(obj[1].frequency)===0)?obj[1].requestedQty:obj[1].dosage*obj[1].duration*obj[1].frequency}</span></div>
                            <div className="col card-detail-block block-xdiv mt-1"><strong className={classNames('lblX', (xOldItem.refill != obj[1].refill?'text-danger':''))}>Refill</strong><span className="valX">{Math.ceil(obj[1].refill)}</span></div>
                            <div className="col-12 card-detail-block block-xdiv mt-1"><strong className="lblX">Notes</strong><span className="valX">{(obj[1].notes)}</span></div>
                            {
                              obj[1].audio && obj[1].audio.includes('uploads/')?
                              <div className="col-12">
                                <audio
                                  style={{
                                    marginRight: 10,
                                    width: matches ? 450 : 260,
                                    marginTop: 6,
                                  }}
                                  src={obj[1].audio}
                                  controls="controls"
                                />
                              </div>:undefined
                            }
                          </>
                        )
                      })
                      :<div className='alert alert-info'>There is no discharge medication request at the moment.</div>
                    }
                  </div>
                  <div className="card-status-action">
                    <span className={classNames(`badge`, `status-pending`)}>Conflict</span>
                  </div>

                </div>
              </div>
            </div>






            <div className="form-group" style={styles.form2}>
              <div className="row align-items-center">
                <div className="col-md-4 text-left">Any Conflict?</div>
                <div className="col-md-8">
                  <RadioGroup
                    row
                    aria-label="hasConflict"
                    name="hasConflict"
                    value={hasConflict}
                    onChange={(e) => dispatch({field: 'hasConflict', value: e.target.value})}
                  >
                    <FormControlLabel
                      value="yes"
                      control={<Radio style={{marginRight: 0}} />}
                      label="Yes"
                      style={{marginBottom: 0}}
                    />
                    <FormControlLabel
                      value="no"
                      control={<Radio />}
                      label="No"
                      style={{marginBottom: 0}}
                    />
                  </RadioGroup>
                  {
                    hasConflict.trim().length<=0 && isFormSubmitted ? <div className="text-danger mt-2">Please select one option!</div>:undefined
                  }
                </div>
              </div>
            </div>

            <div className="form-group" style={styles.form2}>
              <div className="serviceNotes">
                <div className="micControl">
                  <div className="d-flex align-items-center">
                    <div
                      style={{
                        backgroundColor: "#2C6DDD",
                        height: 42,
                        borderRadius: 42,
                        width: 42,
                        paddingLeft: 10,
                        cursor: 'pointer'
                      }}
                    >
                      {isRecording ? (
                        <StopIcon
                          style={{ marginTop: 10, color: "white" }}
                          fontSize="16px"
                          onClick={stopRecording}
                          disabled={!isRecording}
                          color="secondary"
                        />
                      ) : (
                        <MicIcon
                          style={{ marginTop: 10, color: "white" }}
                          fontSize="16px"
                          onClick={() => {
                            startRecording()
                          }}
                          disabled={isRecording}
                          color="primary"
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div className="notesDiv">
                  <TextField
                    // required
                    multiline="true"
                    rows="3"
                    // disabled={enableForm}
                    label="Any Comments / Notes? (Optional)"
                    name={"labComments"}
                    value={note}
                    // error={labComments === ""}
                    onChange={(e) => dispatch({field: 'note', value: e.target.value})}
                    className="textInputStyle"
                    variant="filled"
                    InputProps={{
                      disableUnderline: true,
                    }}
                  />
                  {
                    (note.trim().length<=0 && (fileAudio === null || fileAudio === "" )) && hasConflict === 'yes' && isFormSubmitted ? <div className="text-danger mt-2">Please enter a conflict for ED Doctor to Review!</div>:undefined
                  }
                  {isRecording ? (
                    <div
                      style={{
                        marginLeft: matches ? 110 : 18,
                        marginRight: matches ? 110 : 75,
                        width: matches ? 550 : 100,
                      }}
                    >
                      <Loader
                        type="ThreeDots"
                        color="blue"
                        height={40}
                        width={40}
                      />
                    </div>
                  ) : (
                    <audio
                      style={{
                        marginRight: 10,
                        width: matches ? 450 : 260,
                        marginTop: 6,
                      }}
                      src={blobURL}
                      controls="controls"
                    />
                  )}
                </div>
              </div>
            </div>

            <div className="row row-5">
              <div className="col-12 col-md-4 offset-md-4">
                <Button
                  // className="addButton"
                  style={{
                    ...styles.stylesForButton,
                    backgroundColor: "#AD6BBF",
                    color: "white",
                    cursor: "pointer",
                    borderRadius: 5,
                    backgroundColor: "rgb(173, 107, 191)",
                    height: matches ? 56 : 45,
                    outline: "none",
                    width: "100%",
                  }}
                  // disabled={!addLabRequest}
                  onClick={addConflictReport}
                  variant="contained"
                  color="primary"
                  fullWidth
                >Submit</Button>
              </div>
            </div>


          </>:
          <div style={{ flex: 4, display: "flex", flexDirection: "column" }} className="container-fluid">




            <div className="row">
              {pharmacyRequestArray !== 0 ? (
                <CustomTable
                  tableData={pharmacyRequestArray}
                  tableDataKeys={tableDataKeysForPharma}
                  tableHeading={tableHeadingForPharma}
                  handleView={handleViewPharmacy}
                  handleEdit={handleEditPharmacy}
                  action={actions}
                  borderBottomColor={"#60d69f"}
                  borderBottomWidth={20}
                />
              ) : (
                undefined
              )}
            </div>
          </div>
        }
      </div>
      <Notification
          msg={errorMsg}
          open={openNotification}
          success={successMsg}
        />
    </div>
  );
}

export default PatientDetailsWithPharamReq;
