import React from "react"
import ViewContainerCS from "../../../components/CareStream/ViewContainerCS"
import { viewMDNotificationsStyles } from "../styles"

export default function Assign(props) {
  const classes = viewMDNotificationsStyles()

  return (
    <div className={classes.root}>
      <div className="subheader" style={{ marginLeft: "-10px" }}>
        <div style={{ flex: 4, display: "flex", alignItems: "center" }}>
          <h4 style={{ color: "white", fontWeight: "700" }}>
            MD Notifications
          </h4>
        </div>
      </div>

      <ViewContainerCS
        heading="MD Notification"
        buttonHeading="MD Notification"
        arrayContainerCS={props.mDNotificationsArray}
      />
    </div>
  )
}
