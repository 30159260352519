import axios from "axios";
import { colorForDashboard } from "../../../utils/Constants";

export function getResponse(
  endpoint,
  setResponseData,
  setOpenNotification,
  setErrorMsg,
  setisLoading
) {
  axios
    .get(endpoint)
    .then((res) => {
      if (res.data.success) {
        console.log("res.data: ", res.data);
        setResponseData(res.data);
        setisLoading(false);
      } else if (!res.data.success) {
        setErrorMsg(res.data.error);
        setOpenNotification(true);
      }
      return res;
    })
    .catch((e) => {
      console.log("error: ", e);
    });
}

export function getDialerGraphCall(
  firstDialerComparisonNumber,
  secondDialerComparisonNumber,
  firstTatComparisonNumber,
  secondTatComparisonNumber,
  cardObj,
  setDialorData,
  setDialorColor,
  setTAT,
  setTATColor,
  setGraphData,
  pendingValue
) {
  const { TAT, perHour, totalPending } = cardObj;
  setGraphData(perHour);
  if (
    cardObj[pendingValue] >= 0 &&
    cardObj[pendingValue] <= firstDialerComparisonNumber
  ) {
    setDialorColor(colorForDashboard.first);
  } else if (
    cardObj[pendingValue] >= firstDialerComparisonNumber + 1 &&
    cardObj[pendingValue] <= secondDialerComparisonNumber
  ) {
    setDialorColor(colorForDashboard.second);
  } else if (cardObj[pendingValue] >= secondDialerComparisonNumber + 1) {
    setDialorColor(colorForDashboard.third);
  }

  console.log("****");
  if (TAT >= 0 && TAT <= firstTatComparisonNumber) {
    setTATColor(colorForDashboard.first);
  } else if (
    TAT >= firstTatComparisonNumber + 1 &&
    TAT <= secondTatComparisonNumber
  ) {
    setTATColor(colorForDashboard.second);
  } else if (TAT >= secondTatComparisonNumber + 1) {
    setTATColor(colorForDashboard.third);
  }

  setDialorData(cardObj[pendingValue]);
  setTAT(TAT);
}

export function getValuesCardCalls(
  firstTatComparisonNumber,
  secondTatComparisonNumber,
  data,
  valueCard,
  valueCardColor
) {
  console.log("response after fetch**", data);
  valueCard(data);
  if (data >= 0 && data <= firstTatComparisonNumber) {
    valueCardColor(colorForDashboard.first);
  } else if (
    data >= firstTatComparisonNumber + 1 &&
    data <= secondTatComparisonNumber
  ) {
    valueCardColor(colorForDashboard.second);
  } else if (data >= secondTatComparisonNumber + 1) {
    valueCardColor(colorForDashboard.third);
  }
}

// export function getReportsExternal(
//   setTotalPending,
//   setTotalPendingColor,
//   setPendingTat,
//   setPendingTATColor,
//   setGraphPending,
//   setFollowUp,
//   setFollowUpColor,
//   setFollowUpTat,
//   setFollowUpTatColor,
//   setGraphFollowUp,
//   setConsultedPerHour,
//   setConsultedPerHourColor,
//   setCumulativePatientSeen,
//   setCumulativePatientSeenColor,
//   currentUser
// ) {
//   let apiCall;
//   if (currentUser.subType.includes("External")) {
//     apiCall = reportsExternalConsultantDBURL;
//   } else if (currentUser.subType.includes("Internal")) {
//     apiCall = reportsInternalConsultantDBURL;
//   }
//   axios.get(apiCall).then((res) => {
//     if (res.data.success) {
//       console.log(currentUser, "current User");
//       console.log("External/Internal data", res.data);
//       if (
//         res.data.firstCard.totalPending >= 0 &&
//         res.data.firstCard.totalPending <= 2
//       ) {
//         setTotalPending(res.data.firstCard.totalPending);
//         setTotalPendingColor("#13D59F");
//       } else if (
//         res.data.firstCard.totalPending >= 3 &&
//         res.data.firstCard.totalPending <= 4
//       ) {
//         setTotalPending(res.data.firstCard.totalPending);
//         setTotalPendingColor("#FFBC28");
//       } else if (res.data.firstCard.totalPending >= 5) {
//         setTotalPending(res.data.firstCard.totalPending);
//         setTotalPendingColor("#FF0000");
//       } else {
//         setTotalPending(res.data.firstCard.totalPending);
//       }
//       //setTotalPending(res.data.firstCard.totalPending)
//       //setTotalPendingColor("#FF0000")
//       if (res.data.firstCard.TAT >= 0 && res.data.firstCard.TAT <= 45) {
//         setPendingTat(res.data.firstCard.TAT);
//         setPendingTATColor("#13D59F");
//       } else if (res.data.firstCard.TAT >= 46 && res.data.firstCard.TAT <= 60) {
//         setPendingTat(res.data.firstCard.TAT);
//         setPendingTATColor("#FFBC28");
//       } else if (res.data.firstCard.TAT >= 61) {
//         setPendingTat(res.data.firstCard.TAT);
//         setPendingTATColor("#FF0000");
//       } else {
//         setPendingTat(res.data.firstCard.TAT);
//       }
//       //setPendingTat(res.data.firstCard.TAT);
//       //setPendingTATColor()
//       setGraphPending(res.data.firstCard.perHour);

//       //   // For 2nd Card Requested In ED

//       if (
//         res.data.secondCard.totalFollowUps >= 0 &&
//         res.data.secondCard.totalFollowUps <= 2
//       ) {
//         setFollowUp(res.data.secondCard.totalFollowUps);
//         setFollowUpColor("#13D59F");
//       } else if (
//         res.data.secondCard.totalFollowUps >= 3 &&
//         res.data.secondCard.totalFollowUps <= 4
//       ) {
//         setFollowUp(res.data.secondCard.totalFollowUps);
//         setFollowUpColor("#FFBC28");
//       } else if (res.data.secondCard.totalFollowUps >= 5) {
//         setFollowUp(res.data.secondCard.totalFollowUps);
//         setFollowUpColor("#FF0000");
//       } else {
//         setFollowUp(res.data.secondCard.totalFollowUps);
//       }
//       // setFollowUp(res.data.firstCard.totalPending)
//       //setFollowUpColor()

//       if (res.data.secondCard.TAT >= 0 && res.data.secondCard.TAT <= 30) {
//         setFollowUpTat(res.data.secondCard.TAT);
//         setFollowUpTatColor("#13D59F");
//       } else if (
//         res.data.secondCard.TAT >= 31 &&
//         res.data.secondCard.TAT <= 45
//       ) {
//         setFollowUpTat(res.data.secondCard.TAT);
//         setFollowUpTatColor("#FFBC28");
//       } else if (res.data.secondCard.TAT >= 46) {
//         setFollowUpTat(res.data.secondCard.TAT);
//         setFollowUpTatColor("#FF0000");
//       } else {
//         setFollowUpTat(res.data.secondCard.TAT);
//       }

//       // setFollowUpTat()
//       // setFollowUpTatColor()

//       setGraphFollowUp(res.data.secondCard.perHour);

//       // For 3nd Card
//       //consulted Per Hour

//       if (res.data.consultedPerHour >= 0 && res.data.consultedPerHour <= 2) {
//         setConsultedPerHour(res.data.consultedPerHour);
//         setConsultedPerHourColor("#13D59F");
//       } else if (
//         res.data.consultedPerHour >= 3 &&
//         res.data.consultedPerHour <= 4
//       ) {
//         setConsultedPerHour(res.data.consultedPerHour);
//         setConsultedPerHourColor("#FFBC28");
//       } else if (res.data.consultedPerHour >= 5) {
//         setConsultedPerHour(res.data.consultedPerHour);
//         setConsultedPerHourColor("#FF0000");
//       } else {
//         setConsultedPerHour(res.data.consultedPerHour);
//       }
//       //setConsultedPerHour(res.data.consultedPerHour);
//       // For 4td Card
//       //commulative  Per Hour

//       if (currentUser.subType.includes("External")) {
//         if (
//           res.data.cumulativePatientSeen >= 0 &&
//           res.data.cumulativePatientSeen <= 5
//         ) {
//           setCumulativePatientSeen(res.data.cumulativePatientSeen);
//           setCumulativePatientSeenColor("#13D59F");
//         } else if (
//           res.data.cumulativePatientSeen >= 6 &&
//           res.data.cumulativePatientSeen <= 10
//         ) {
//           setCumulativePatientSeen(res.data.cumulativePatientSeen);
//           setCumulativePatientSeenColor("#FFBC28");
//         } else if (res.data.cumulativePatientSeen >= 11) {
//           setCumulativePatientSeen(res.data.cumulativePatientSeen);
//           setCumulativePatientSeenColor("#FF0000");
//         } else {
//           setCumulativePatientSeen(res.data.cumulativePatientSeen);
//         }
//       } else if (currentUser.subType.includes("Internal")) {
//         if (
//           res.data.cumulativePatientSeen >= 0 &&
//           res.data.cumulativePatientSeen <= 16
//         ) {
//           setCumulativePatientSeen(res.data.cumulativePatientSeen);
//           setCumulativePatientSeenColor("#13D59F");
//         } else if (
//           res.data.cumulativePatientSeen >= 17 &&
//           res.data.cumulativePatientSeen <= 32
//         ) {
//           setCumulativePatientSeen(res.data.cumulativePatientSeen);
//           setCumulativePatientSeenColor("#FFBC28");
//         } else if (res.data.cumulativePatientSeen >= 33) {
//           setCumulativePatientSeen(res.data.cumulativePatientSeen);
//           setCumulativePatientSeenColor("#FF0000");
//         } else {
//           setCumulativePatientSeen(res.data.cumulativePatientSeen);
//         }
//       }
//     }
//   });
// }
