/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable array-callback-return */
/* eslint-disable react/jsx-indent */
import React, { useEffect, useState, useReducer } from 'react'
import { makeStyles, Button, Dialog, DialogContent, DialogTitle, MenuItem, FormControl, Select, TextField, FormLabel, RadioGroup, Radio, FormControlLabel, InputAdornment } from '@material-ui/core'
import axios from 'axios'
import {
  audioURL,
  getSingleIPRPatient,
  getLabRequest,
  getRadEdrRequest,
  updateLabSampleInsuranceRequest,
  updateRadSampleInsuranceRequest
} from '../../../public/endpoins'
import classNames from "classnames";
import cookie from 'react-cookies'
import Header from '../../../components/Header/Header'
import PreApproval from '../../../assets/img/Pre-Approval.png'
import Back from '../../../assets/img/Back_Arrow.png'
import '../../../assets/jss/material-dashboard-react/components/TextInputStyle.css'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import CustomTable from '../../../components/Table/Table'
import Notification from '../../../components/Snackbar/Notification.js'
import Loader from 'react-loader-spinner'
import useMediaQuery from "@material-ui/core/useMediaQuery";
import PatientDetailsQuick from "../../../components/PatientDetails/PatientDetailsQuick";
import PatientDetails from "../../../components/PatientDetails/PatientDetails";
import PatientDetails2 from "../../../components/PatientDetails/PatientDetailsDirect";
import { displayInHumanDifference, replacePatientIdIfNull } from "../../../utils/commonFunc";
import moment from 'moment';
import { FaUpload } from "react-icons/fa";
import AccountCircle from "@material-ui/icons/Person";

const tableHeadingForNeedApproval = [
  'Request No',
  'Request Type',
  'Item',
  'Total Cost (JD)',
  'Status',
  'Insurance',
  'Action',
]
const tableDataKeysForNeedApproval = [
  'requestId',
  'RequestType',
  'name',
  'totalCost',
  'status',
  'insurance',
]
const actions = { view: true }

const styles = {
  patientDetails: {
    backgroundColor: 'white',
    borderRadius: 5,
    padding: '20px',
  },
  headingStyles: {
    fontWeight: 'bold',
    color: 'grey',
    fontSize: 12,
  },
  textStyles: {
    fontWeight: '700',
    color: 'black',
    fontSize: 14,
  },


  stylesForButton: {
    color: "white",
    cursor: "pointer",
    borderRadius: 5,
    backgroundColor: "#2c6ddd",
    width: "130px",
    height: "45px",
    outline: "none",
  },
  inputContainerForTextField: {
    // marginTop: 10,
  },
  inputContainerForDropDown: {
    marginTop: 10,
  },
  textFieldPadding: {
    // paddingLeft: 5,
    // paddingRight: 5,
  },
  save: {
    color: "white",
    cursor: "pointer",
    borderRadius: 5,
    backgroundColor: "#ba55d3",
    width: "130px",
    height: "45px",
    outline: "none",
  },
  generate: {
    color: "white",
    cursor: "pointer",
    borderRadius: 5,
    backgroundColor: "#e877a1",
    height: "45px",
    width: "190px",
    outline: "none",
  },
  None: {
    display: "none",
  },
  form: {
    backgroundColor: "white",
    borderRadius: "5px",
    marginTop: "10px",
    padding: "10px",
    textAlign: "center",
  },
  form2: {
    backgroundColor: "white",
    borderRadius: "5px",
    padding: "5px 15px",
  },
  form3: {
    backgroundColor: "white",
    borderRadius: "5px",
    padding: "0px 15px",
  },
  upload: {
    backgroundColor: "#E2E2E2",
    border: "0px solid #ccc",
    borderRadius: "5px",
    color: "gray",
    width: "100%",
    height: "60px",
    cursor: "pointer",
    padding: "15px",
  },
  input: {
    display: "none",
  },


}

const useStylesForTabs = makeStyles({
  root: {
    justifyContent: 'center',
  },
  scroller: {
    flexGrow: '0',
  },
})

function ViewPreApprovalDetail(props) {
  const classesForTabs = useStylesForTabs()
  const matches = useMediaQuery("(min-width:600px)");

  const initialState = {
    diagnosisArray: [],
    medicationArray: [],
  }

  function reducer(state, { field, value }) {
    return {
      ...state,
      [field]: value,
    }
  }

  const [state, dispatch] = useReducer(reducer, initialState)

  const {
    diagnosisArray,
    medicationArray,
  } = state

  const [currentUser, setCurrentUser] = useState(cookie.load("current_user"));
  const [errorMsg, setErrorMsg] = useState('')
  const [successMsg, setsuccessMsg] = useState("");
  const [openNotification, setOpenNotification] = useState(false)
  const [value, setValue] = useState(0)
  const [selectedItem, setSelectedItem] = useState('')
  const [selectedPatient, setSelectedPatient] = useState('')
  const [Id, setId] = useState('')
  const [labServiceStatus, setLabServiceStatus] = useState('')
  const [xLabServiceStatus, setXLabServiceStatus] = useState('')

  const [openConsentDialog, setOpenConsentDialog] = useState(false);

  const [patientDetails, setPatientDetails] = useState("");
  const [selectedRecord, setSelectedRecord] = useState({});

  const [needApprovalArray, setneedApprovalArray] = useState([
    // {
    //   id: '1',
    //   RequestType: 'Loreum',
    //   serviceName: 'Loreum',
    //   totalCost: 'Loreum',
    //   status: 'pending',
    //   insurance: 'Loreum',
    // }
  ])
  const [isLoading, setIsLoading] = useState(true)
  const [ episodeId, setEpisodeId ] = useState(props.match.params.id);
  const [ preApprovalType, setPreApprovalType ] = useState(props.match.params.preApprovalType);
  const [ labRequestId, setLabRequestId ] = useState(props.match.params.labRadId);
  const [ serviceId, setServiceId ] = useState(props.match.params.serviceId);
  const [ labServices, setLabServices ] = useState([]);
  const [ labRequest, setLabRequest ] = useState({});

  const [ allergies, setAllergies ] = useState("");
  const [ dialogBoxInsurance, setDialogBoxInsurance ] = useState(false);

  const [ insuranceApprovalNo, setInsuranceApprovalNo ] = useState("");
  const [ insuranceRequestApproved, setInsuranceRequestApproved ] = useState("no");
  const [ errorFileConsentMsg, setErrorFileConsentMsg ] = useState("");
  const [ selfConsentFileObject, setSelfConsentFileObject ] = useState({
    title: 'Consent to Upload',
  });

  const [ selfConsentFile, setSelfConsentFile ] = useState("");
  const [ disableButtonIfSelected, setDisableButtonIfSelected ] = useState(true);
  const [ approvalDialogSubmit, setApprovalDialogSubmit ] = useState(false);


  const onSlipUpload = (event) => {
    // event.preventDefault()
    console.log("event::", event.target, event.target.name, event.target.files);
    var file = event.target.files[0];
    var fileType = file.name.slice(file.name.length - 3);
    let file_size = event.target.files[0].size;

    setDisableButtonIfSelected(true);

    if (event.target.name === "consentSelfpay") {
      
      setErrorFileConsentMsg("");

      let obj = {};
      obj.contentType = fileType;
      obj.title = "Consent to Upload - " + file.name;
      obj.url = file.name;
      obj.size = file.size.toString();
      // obj.creation = file.lastModifiedDate.toISOString().substr(0, 10);
      console.log('file::file::', obj);
      setSelfConsentFileObject(obj);

      // setProgress(true)
      var reader = new FileReader();
      var url = reader.readAsDataURL(file);

      reader.onloadend = function() {
        if (file_size <= 10500000) {
          if (
            fileType === "png" || fileType === "PNG" || 
            fileType === "peg" || fileType === "PEG" || 
            fileType === "jpg" || fileType === "JPG" || 
            fileType === "PDF" || fileType === "pdf"
          ) {
            // setImagePreview([reader.result]);
            setDisableButtonIfSelected(false);
            setSelfConsentFile(file);
            console.log('file::file::', file);
          } else {
            setErrorFileConsentMsg("Only jpg, jpeg and png are be allowed");
          }
        } else {
          setErrorFileConsentMsg("Files size should be less Than or Equal to 10MB");
        }
      };
    }
  };



  // getLabRequest

  useEffect(() => {
    // setCurrentUser(cookie.load('current_user'))

    // const selectedRec = props.history.location.state.selectedItem
    console.log('Record::', episodeId, labRequestId);

    setId(episodeId)

    if (labRequestId !== ""){


      let reqNewURL = `${getLabRequest}/${labRequestId}`;
      if ( preApprovalType === "radpreapproval" ){
        reqNewURL = `${getRadEdrRequest}/${labRequestId}`;
      }

      axios
      .get( reqNewURL )
      .then((res) => {
        if (res.data.success) {
          if (res.data.data) {

            let dataR = res.data.data[0];

            let remoteRecord = replacePatientIdIfNull(dataR);
            setSelectedRecord(remoteRecord);
            console.log('Record::data::remoteRecord::', remoteRecord);

            const object = {
              '_id': remoteRecord._id,
              patientName: remoteRecord.patientId.name[0].given[0] + " " + remoteRecord.patientId.name[0].family,
              episodeNumber: remoteRecord.requestNo,//remoteRecord.patientId.identifier[0].value,
              mrn: remoteRecord.patientId.identifier[0].value,
              age: remoteRecord.patientId.age,
              gender: remoteRecord.patientId.gender,
              weight: remoteRecord.patientId.weight,
              department: remoteRecord.currentLocation,
              // chiefComplaint: remoteRecord.chiefComplaint.length > 0 ? remoteRecord.chiefComplaint.pop().chiefComplaintId.short_desc : "N/A",
              // chiefComplaintOthers: remoteRecord.chiefComplaintOthers,
            };
            setPatientDetails(object);

            if (remoteRecord.dcdForm.pastMedicalHistory){
              if (remoteRecord.dcdForm.pastMedicalHistory.details){
                let det = remoteRecord.dcdForm.pastMedicalHistory.details.filter(obj => obj.name === "Allergies");
                if (det[0].values){
                  let objx = [];
                  Object.entries(det[0].values).map((obj, entry) => {
                    objx.push( obj[1].name );
                    // console.log("pastMedicalHistory::details", obj[1].name);
                  });
                  setAllergies(objx.join(', '));
                }
              }
            }



            if ( preApprovalType === "radpreapproval" ) 
            {
              
              dataR.radRequest.requestedName = dataR.radRequest.requestedBy.name[0].given[0] + " " + dataR.radRequest.requestedBy.name[0].family;
              setLabRequest(dataR.radRequest);
              
              res.data.data.map((val) => {
                // Services
                let servicesNames = [];
                Object.entries(val.radRequest.services).map(([key, val]) => {
                  servicesNames.push(val.name)
                });
                val.serviceName = servicesNames.join(',');
              });
              if (dataR.radRequest){
                setLabServices(dataR.radRequest.services);
                Object.entries(dataR.radRequest.services).map((obj, entry) => {
                  if (obj[1]._id===serviceId){
                    setLabServiceStatus(obj[1].insuranceStatus);
                    setXLabServiceStatus(obj[1].insuranceStatus);
                  }
                });
              }

            }else{
              
              dataR.labRequest.requestedName = dataR.labRequest.requestedBy.name[0].given[0] + " " + dataR.labRequest.requestedBy.name[0].family;
              setLabRequest(dataR.labRequest);
              
              res.data.data.map((val) => {
                // Services
                let servicesNames = [];
                Object.entries(val.labRequest.services).map(([key, val]) => {
                  servicesNames.push(val.name)
                });
                val.serviceName = servicesNames.join(',');
              });
              if (dataR.labRequest){
                setLabServices(dataR.labRequest.services);
                Object.entries(dataR.labRequest.services).map((obj, entry) => {
                  if (obj[1]._id===serviceId){
                    setLabServiceStatus(obj[1].insuranceStatus);
                    setXLabServiceStatus(obj[1].insuranceStatus);
                  }
                });
              }

            }

          }
          // var sortedObjs = _.sortBy(res.data.data, "date");
          // setpreApproval(sortedObjs);
        } else if (!res.data.success) {
          setErrorMsg(res.data.error);
          setOpenNotification(true);
        }
        return res;
      })
      .catch((e) => {
        console.log("error: ", e);
      });
    }

    // setSelectedPatient(selectedRec.patientId)
    // setSelectedItem(selectedRec)
    /*
    if (selectedRec) {
      if (selectedRec.residentNotes) {
        let val = selectedRec.residentNotes
        if (val && val.length > 0) {
          dispatch({
            field: 'diagnosisArray',
            value: val.reverse()[0].code,
          })
        }
      }
      if (selectedRec.pharmacyRequest) {
        let data = []
        let val = selectedRec.pharmacyRequest
        val.map((d) => {
          d.item.map((item) => {
            let found = data.find((i) => i === item.itemId.name)
            if (!found) {
              data.push(item.itemId.name)
            }
          })
        })
        dispatch({ field: 'medicationArray', value: data })

        for (let i = 0; i < selectedRec.pharmacyRequest.length; i++) {
          let amount = 0
          let singlePR = selectedRec.pharmacyRequest[i]

          for (let j = 0; j < singlePR.item.length; j++) {
            amount =
              amount +
              singlePR.item[j].itemId.issueUnitCost *
              singlePR.item[j].requestedQty
          }

          selectedRec.pharmacyRequest[i] = {
            ...selectedRec.pharmacyRequest[i],
            totalCost: amount.toFixed(4),
            RequestType: 'PHR',
            name: 'Medical',
            insurance: 'Uncovered',
            requestId: selectedRec.pharmacyRequest[i].pharmacyRequestNo
          }
        }
      }
      if (selectedRec.labRequest) {
        selectedRec.labRequest.map(
          (d) => (
            (d.RequestType = 'LR'),
            (d.totalCost = d.price),
            (d.insurance = 'Uncovered')
          )
        )
      }
      if (selectedRec.radRequest) {
        selectedRec.radRequest.map(
          (d) => (
            (d.RequestType = 'RR'),
            (d.totalCost = d.price),
            (d.insurance = 'Uncovered')
          )
        )
      }
    }
    setneedApprovalArray(
      [].concat(
        selectedRec.labRequest.reverse(),
        selectedRec.radRequest.reverse(),
        selectedRec.pharmacyRequest.reverse()
      )
    )*/
  }, [])

  const handleChange = (e, newValue) => {
    setValue(newValue)
  }

  const handleView = (rec) => {
    let url = '/dashboard/home/insurance/preapproval/'+episodeId+'/'+labRequestId+'/view/'+rec._id+'';
    window.alert(url);
    // props.history.push({
    //   pathname: url,
    // });
  }

  const handleSaveApprove = () => {
    setApprovalDialogSubmit(true);
    if (insuranceApprovalNo.trim !== ''){
      handleSave();
    }
  }

  const handleSave = () => {
    let formData = new FormData();
    if (selfConsentFile) {
      formData.append("consentFile", selfConsentFile, selfConsentFile.name);
    }

    formData.append( "edrId",         episodeId );
    formData.append( "staffId",       currentUser._id );
    formData.append( "requestId",     labRequestId );
    formData.append( "serviceId",     serviceId );
    formData.append( "requestStatus", labServiceStatus );
    formData.append( "completeType",  'individual' );

    if (labServiceStatus === 'Approved'){
      formData.append( "insuranceApprovalNo", insuranceApprovalNo );
      formData.append( "insuranceRequestApproved", 'yes' );
    }else{
      formData.append( "insuranceApprovalNo", "" );
      formData.append( "insuranceRequestApproved", "no" );
    }

    console.log('file::file::', formData);

    let finalURL = updateLabSampleInsuranceRequest;
    if ( preApprovalType === "radpreapproval" )
    {
      finalURL = updateRadSampleInsuranceRequest;
    }
    
    axios
      .put(finalURL, formData)
      .then((res) => {
        if (res.data.success) {
          setOpenNotification(true);
          setsuccessMsg(`The Request has been marked as ${labServiceStatus}!`);
          window.location.reload();
        }
      })
      .catch((e) => {
        setOpenNotification(true);
        setErrorMsg("Unable to Update the Record");
      });
  }

  function viewNeedApproval(rec) {
    let path = `viewpreapproval/needapproval`
    props.history.push({
      pathname: path,
      state: {
        selectedItem: rec,
      },
    })
  }

  if (openNotification) {
    setTimeout(() => {
      setOpenNotification(false)
      setErrorMsg('')
    }, 2000)
  }

  return (
    <div
      style={{
        backgroundColor: 'rgb(19 213 159)',
        position: 'fixed',
        display: 'flex',
        width: '100%',
        height: '100%',
        flexDirection: 'column',
        flex: 1,
        overflowY: 'scroll',
      }}
    >
      <Header history={props.history} />

      <PatientDetailsQuick
        edrId={episodeId} />

      <div className='cPadding'>
        <div className='subheader' style={{ marginLeft: '-6px' }}>
          <div>
            <img src={PreApproval} />
            <h4>Pre-Approval</h4>
          </div>
        </div>
        {/*
        <PatientDetails2
            edrId={episodeId}
            showChiefComplaint={true}
            showTriageLevel={true}
            showCareStream={true}
            showDiagnosis={true} />
        */}
        {patientDetails && labRequest ? (
          <div className="mb-3 mt-3 container-fluid">
            <div className="card border-0">
              <div className="card-header bg-primary text-white"><strong>Patient Clinical Information</strong></div>
              <div className="card-body">
                <div className="row row-10">
                  <div className="col-6 col-lg-1 card-detail-block block-xdiv mb-lg-2 mb-4"><strong className="lblX">Triage Level</strong><span className="valX">{selectedRecord.dcdForm.triageAssessment?selectedRecord.dcdForm.triageAssessment.triageLevel[0]:'-'}</span></div>
                  <div className="col-6 col-lg-2 card-detail-block block-xdiv mb-lg-2 mb-4"><strong className="lblX">Chief Complaint</strong><span className="valX">{selectedRecord.chiefComplaint.chiefComplaintId.short_name}</span></div>
                  <div className="col-12 col-lg-3 card-detail-block block-xdiv mb-lg-2 mb-4"><strong className="lblX">Allergies</strong><span className="valX">{allergies}</span></div>
                  <div className="col-6 col-lg-3 card-detail-block block-xdiv mb-lg-2 mb-4"><strong className="lblX">Medication</strong><span className="valX">0 Medications</span></div>
                  <div className="col-6 col-lg-3 card-detail-block block-xdiv mb-lg-2 mb-4"><strong className="lblX">Diagnosis</strong><span className="valX">0 Diagnosis</span></div>
                </div>
              </div>
            </div>
            <div className="mt-3 card p-3">
              <div className="row row-5">
                <div className="col-6 col-lg-3 card-detail-block block-xdiv mb-2"><strong className="lblX">Episode #</strong><span className="valX">{patientDetails.episodeNumber}</span></div>
                <div className="col-6 col-lg-3 card-detail-block block-xdiv mb-2"><strong className="lblX">MRN #</strong><span className="valX">{patientDetails.mrn}</span></div>
                <div className="col-6 col-lg-3 card-detail-block block-xdiv mb-2"><strong className="lblX">Requester</strong><span className="valX">{labRequest.requestedName}</span></div>
                <div className="col-6 col-lg-3 card-detail-block block-xdiv mb-2"><strong className="lblX">Date and Time</strong><span className="valX">{displayInHumanDifference(labRequest.requestedAt)}</span></div>
              </div>
            </div>

            <h4 className="h6 text-white mt-3" style={{ fontWeight: '700' }}>Insurance Details</h4>
            <div className="card border-0">
            {
              selectedRecord.patientId.paymentMode==="Insured"?
              <div className="card-body pb-0">
                <div className="row row-10">
                  <div className="col-4 col-lg-2 card-detail-block block-xdiv mb-lg-2 mb-4"><strong className="lblX">Insurance Vendor</strong><span className="valX">{selectedRecord.patientId.insuranceVendor}</span></div>
                  <div className="col-4 col-lg-2 card-detail-block block-xdiv mb-lg-2 mb-4"><strong className="lblX">Policy Number</strong><span className="valX">{selectedRecord.patientId.insurancePolicyNumber}</span></div>
                  <div className="col-4 col-lg-2 card-detail-block block-xdiv mb-lg-2 mb-4"><strong className="lblX">Network/Plan</strong><span className="valX">{selectedRecord.patientId.insurancePlanNetwork}</span></div>
                  <div className="col-4 col-lg-2 card-detail-block block-xdiv mb-lg-2 mb-4"><strong className="lblX">Effective From</strong><span className="valX">{moment(selectedRecord.patientId.insuranceFrom).format('MMM DD, YYYY')}</span></div>
                  <div className="col-4 col-lg-2 card-detail-block block-xdiv mb-lg-2 mb-4"><strong className="lblX">Expiry Date</strong><span className="valX">{moment(selectedRecord.patientId.insuranceTo).format('MMM DD, YYYY')}</span></div>
                  <div className="col-4 col-lg-2 card-detail-block block-xdiv mb-lg-2 mb-4"><strong className="lblX">Relation</strong><span className="valX">{selectedRecord.patientId.coveredFamilyMember[0].typeOfRelation}</span></div>
                </div>
                <div className="p-3 bg-light mt-3" style={{ marginLeft: '-18px', marginRight: '-18px' }}>
                  <Button
                    color="primary"
                    size="small"
                    onClick={() => setDialogBoxInsurance(true)}
                    variant="contained"
                    fullWidth="true"
                  >+ View Details</Button>
                </div>
              </div>:
              <div className="card-body">
                No Information Found. The Payment mode of this patient is <strong>SelfPay</strong>.
              </div>              
            }
            </div>
          </div>
        ) : (
          undefined
        )}

        <div className='container-fluid mt-4'>

          <div className='row row-5'>
            {labServices && labServices.length > 0 ? (
              Object.entries(labServices).map((obj, entry) => {
                if (obj[1]._id===serviceId){
                  return (
                    <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                      <div className="card card-detail-info mb-2">
                        <div className="row row-5">
                          <div className="col-sm-6 card-detail-block block-xdiv mb-2"><strong className="lblX">Request ID</strong><span className="valX">{obj[1].requestId}</span></div>
                          <div className="col-sm-6">&nbsp;</div>
                          <div className="col-sm-12 card-detail-block block-xdiv mb-2"><strong className="lblX">Profile/Test</strong><span className="valX">{obj[1].category}: <span>{obj[1].name}</span></span></div>
                          <div className="col-sm-12 card-detail-block block-xdiv mb-2">
                            <strong className="lblX">Notes</strong>
                            <span className="valX">
                              <span>{obj[1].notes ?? "- Not Available -"}</span>
                              
                            </span>
                            {
                              obj[1].audio ?
                              <audio
                                style={{
                                  marginRight: 10,
                                  width: matches ? 450 : 260,
                                  marginTop: 6,
                                }}
                                src={ `${audioURL}/${obj[1].audio}` }
                                controlsList="nodownload"
                                controls="controls"
                                />:undefined
                            }
                          </div>
                        </div>
                        <div className="card-status-action">
                          {
                            (
                            obj[1].insuranceConsentFile.trim()==="" ||
                            obj[1].insuranceConsentFile.trim()===null
                            )?undefined:<i className="ml-attachment ml-10 zmdi zmdi-attachment zmdi-hc-2x" onClick={() => {
                              // 👇 Open link in new tab programmatically
                              window.open(`${audioURL}/${obj[1].insuranceConsentFile.trim().replace('\\','/')}`, '_blank', 'noreferrer');
                            }} />
                          }
                          { 
                            obj[1].urgent==="Yes"?<span className="ml-info">!</span>:undefined
                          }
                          {obj[1].priority==="High"?<span className="badge badge-danger">High</span>:undefined}
                          {
                            obj[1].insuranceStatus=="Pending"?
                            <FormControl variant="filled" className="mServiceDropdown ml-2" InputProps={{ disableUnderline: true }}>
                              <Select
                                name={"labServiceStatus"}
                                value={labServiceStatus}
                                InputProps={{ disableUnderline: true }}
                                onChange={(e) => {
                                  setLabServiceStatus(e.target.value);
                                  if (
                                    e.target.value === "Approved" ||
                                    e.target.value === "Selfpay" ||
                                    e.target.value === "Not Approved"
                                  ){
                                    setOpenConsentDialog(true);
                                  }
                                }}
                              >
                                {selectedRecord.patientId.paymentMode==="Insured"?<MenuItem value="Covered" key="Covered">Covered</MenuItem>:undefined}
                                {selectedRecord.patientId.paymentMode==="Insured"?<MenuItem value="Approved" key="Approved">Approved</MenuItem>:undefined}
                                <MenuItem value="Selfpay" key="Selfpay">Selfpay</MenuItem>
                                <MenuItem value="Not Approved" key="Not Approved">Not Approved</MenuItem>
                                <MenuItem value="Pending" key="Pending">Pending</MenuItem>
                              </Select>
                            </FormControl>:
                            <span className={classNames(`badge`, ((obj[1].insuranceStatus=="Approved" || obj[1].insuranceStatus=="Selfpay" || obj[1].insuranceStatus=="Covered")?'badge-success':(obj[1].insuranceStatus=="Not Approved"?'badge-warning':'status-pending')))}>{obj[1].insuranceStatus}</span>
                          }
                          <i className="ml-view ml-10 zmdi zmdi-eye zmdi-hc-2x" onClick={() => {
                            handleView(obj[1]);
                          }} />
                        </div>
                      </div>
                    </div>
                  );
                }
              })
            ) : labServices && labServices.length === 0 ? (
              <div className='col-md-12 col-sm-12 col-12' style={{ marginTop: '20px' }}>
                <h3
                  style={{
                    color: 'white',
                    textAlign: 'center'
                  }}
                >
                  Opps!! No Data Found
                </h3>
              </div>
            ) : (
                  <div className='LoaderStyle'>
                    <Loader
                      type='TailSpin'
                      color='red'
                      height={50}
                      width={50}
                    />
                  </div>
                )}
          </div>
          {
            xLabServiceStatus === "Pending"?
          <Button
            style={{
              backgroundColor: "#AD6BBF",
              color: "white",
              cursor: "pointer",
              borderRadius: 5,
              backgroundColor: "rgb(173, 107, 191)",
              height: matches ? 56 : 45,
              outline: "none",
              width: "100%",
            }}
            className="mt-4"
            onClick={handleSave}
            variant="contained"
            color="primary"
            fullWidth>Submit</Button>
            :undefined
          }
          <div className='row' style={{ marginTop: '20px', marginBottom: '25px' }}>
            <div className='col-md-12 col-sm-12 col-12'>
              <img
                onClick={() => props.history.goBack()}
                src={Back}
                style={{ width: 45, height: 35, cursor: 'pointer' }}
              />
            </div>
          </div>
        </div>


        <Dialog
            aria-labelledby="form-dialog-title"
            open={openConsentDialog && labServiceStatus === "Selfpay"}
            maxWidth="md"
          >
          <DialogTitle
              id="simple-dialog-title"
              className=""
            ><strong>SelfPay</strong>: Upload a Consent</DialogTitle>
          <DialogContent>

            <label
              style={{
                ...styles.upload,
                // fontSize: matches ? " " : 12
                fontSize: matches ? 15 : 12,
                height: 55,
              }}
            >
              <TextField
                required
                type="file"
                accept="image/*,application/pdf"
                style={styles.input}
                onChange={onSlipUpload}
                onClick={(event) => {
                  event.target.value = null;
                }}
                name="consentSelfpay"
                Error={errorFileConsentMsg}
              />
              <FaUpload />
              {matches ? <>&nbsp;&nbsp;</> : ""} {selfConsentFileObject.title}
            </label>


            <Button
              style={{
                backgroundColor: "#AD6BBF",
                color: "white",
                cursor: "pointer",
                borderRadius: 5,
                backgroundColor: "rgb(173, 107, 191)",
                height: matches ? 56 : 45,
                outline: "none",
                width: "100%",
              }}
              className="mt-2 mb-3"
              onClick={handleSave}
              variant="contained"
              color="primary"
              disabled={disableButtonIfSelected}
              fullWidth>Upload &amp; Submit</Button>


          </DialogContent>
        </Dialog>

        <Dialog
            aria-labelledby="form-dialog-title"
            open={openConsentDialog && labServiceStatus === "Not Approved"}
            maxWidth="md"
          >
          <DialogTitle
              id="simple-dialog-title"
              className=""
            ><strong>Not Approved</strong>: Upload AMA</DialogTitle>
          <DialogContent>

            <label
              style={{
                ...styles.upload,
                // fontSize: matches ? " " : 12
                fontSize: matches ? 15 : 12,
                height: 55,
              }}
            >
              <TextField
                required
                type="file"
                accept="image/*,application/pdf"
                style={styles.input}
                onChange={onSlipUpload}
                onClick={(event) => {
                  event.target.value = null;
                }}
                name="consentSelfpay"
                Error={errorFileConsentMsg}
              />
              <FaUpload />
              {matches ? <>&nbsp;&nbsp;</> : ""} Upload AMA
            </label>


            <Button
              style={{
                backgroundColor: "#AD6BBF",
                color: "white",
                cursor: "pointer",
                borderRadius: 5,
                backgroundColor: "rgb(173, 107, 191)",
                height: matches ? 56 : 45,
                outline: "none",
                width: "100%",
              }}
              className="mt-2 mb-3"
              onClick={handleSave}
              variant="contained"
              color="primary"
              disabled={disableButtonIfSelected}
              fullWidth>Upload &amp; Submit</Button>


          </DialogContent>
        </Dialog>

        <Dialog
            aria-labelledby="form-dialog-title"
            open={openConsentDialog && labServiceStatus === "Approved"}
            maxWidth="md"
          >
          <DialogTitle
              id="simple-dialog-title"
              className=""
            ><strong>Approved</strong>: Request</DialogTitle>
          <DialogContent>

            <FormControl component="fieldset" className="d-none">
              <FormLabel component="legend">Is the request approved?</FormLabel>
              <RadioGroup row aria-label="insuranceRequestApproved" name="insuranceRequestApproved" value={insuranceRequestApproved} onChange={(event) =>{
                setInsuranceRequestApproved(event.target.value)
              }}>
                <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="no" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
            <TextField
              required

              className="textInputStyle mb-3"
              id="insuranceApprovalNo"
              type="text"
              variant="filled"
              label="Approval Number"
              name={"insuranceApprovalNo"}
              value={insuranceApprovalNo}
              onChange={(event) => {
                setInsuranceApprovalNo( event.target.value );
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <AccountCircle />
                  </InputAdornment>
                ),
                
                disableUnderline: true,
              }}
              error={insuranceRequestApproved === 'yes' && insuranceApprovalNo.trim() === "" && approvalDialogSubmit}
              helperText={insuranceRequestApproved === 'yes' && insuranceApprovalNo.trim() === "" && approvalDialogSubmit ? 'Required':undefined}
            />
            <label
              style={{
                ...styles.upload,
                fontSize: matches ? 15 : 12,
                height: 55,
              }}
            >
              <TextField
                required
                type="file"
                accept="image/*,application/pdf"
                style={styles.input}
                onChange={onSlipUpload}
                onClick={(event) => {
                  event.target.value = null;
                }}
                name="consentSelfpay"
                Error={errorFileConsentMsg}
              />
              <FaUpload />
              {matches ? <>&nbsp;&nbsp;</> : ""} Upload associated documents (if any)
            </label>

            <Button
              style={{
                backgroundColor: "#AD6BBF",
                color: "white",
                cursor: "pointer",
                borderRadius: 5,
                backgroundColor: "rgb(173, 107, 191)",
                height: matches ? 56 : 45,
                outline: "none",
                width: "100%",
              }}
              className="mt-2 mb-3"
              onClick={handleSaveApprove}
              variant="contained"
              color="primary"
              fullWidth>Submit</Button>
          </DialogContent>
        </Dialog>

        {
          dialogBoxInsurance ?
          <Dialog
            aria-labelledby="form-dialog-title"
            open={dialogBoxInsurance}
            onClose={() => setDialogBoxInsurance(false)}
            maxWidth="md"
          >
            <DialogTitle
              id="simple-dialog-title"
              className=""
            >Insurance Details</DialogTitle>
            <DialogContent>
              <div className="row row-10">
                <div className="col-4 card-detail-block block-xdiv"><strong className="lblX">Insurance Vendor</strong><span className="valX">{selectedRecord.patientId.insuranceVendor}</span></div>
                <div className="col-4 card-detail-block block-xdiv"><strong className="lblX">Policy Number</strong><span className="valX">{selectedRecord.patientId.insurancePolicyNumber}</span></div>
                <div className="col-4 card-detail-block block-xdiv"><strong className="lblX">Network/Plan</strong><span className="valX">{selectedRecord.patientId.insurancePlanNetwork}</span></div>
                <div className="col-4 card-detail-block block-xdiv mt-lg-3 mt-md-3 mt-2"><strong className="lblX">Effective From</strong><span className="valX">{moment(selectedRecord.patientId.insuranceFrom).format('MMM DD, YYYY')}</span></div>
                <div className="col-4 card-detail-block block-xdiv mt-lg-3 mt-md-3 mt-2"><strong className="lblX">Expiry Date</strong><span className="valX">{moment(selectedRecord.patientId.insuranceTo).format('MMM DD, YYYY')}</span></div>
                <div className="col-4 card-detail-block block-xdiv mt-lg-3 mt-md-3 mt-2"><strong className="lblX">Relation</strong><span className="valX">{selectedRecord.patientId.coveredFamilyMember[0].typeOfRelation}</span></div>

                <div className="col-4 card-detail-block block-xdiv mt-lg-3 mt-md-3 mt-2"><strong className="lblX">Coverage Terms</strong><span className="valX">{selectedRecord.patientId.coverageTerms} ({selectedRecord.patientId.coPayment}%)</span></div>
                <div className="col-8 card-detail-block block-xdiv mt-lg-3 mt-md-3 mt-2"><strong className="lblX">Eligibility</strong><span className="valX">{selectedRecord.patientId.insuranceEligibility}</span></div>
                {
                  selectedRecord.patientId.insuranceItemsInfo?
                  <>
                      <div className="col-4 card-detail-block block-xdiv mt-lg-3 mt-md-3 mt-2"><strong className="lblX">Consultation</strong><span className="valX">{selectedRecord.patientId.insuranceItemsInfo[0].consultationJod ?? "-"}</span></div>
                      <div className="col-4 card-detail-block block-xdiv mt-lg-3 mt-md-3 mt-2"><strong className="lblX">Min</strong><span className="valX">JD {selectedRecord.patientId.insuranceItemsInfo[0].consultationMin ?? "-"}</span></div>
                      <div className="col-4 card-detail-block block-xdiv mt-lg-3 mt-md-3 mt-2"><strong className="lblX">Max</strong><span className="valX">JD {selectedRecord.patientId.insuranceItemsInfo[0].consultationMax ?? "-"}</span></div>
                      
                      <div className="col-4 card-detail-block block-xdiv mt-lg-3 mt-md-3 mt-2"><strong className="lblX">Lab</strong><span className="valX">{selectedRecord.patientId.insuranceItemsInfo[0].labJod ?? "-"}</span></div>
                      <div className="col-4 card-detail-block block-xdiv mt-lg-3 mt-md-3 mt-2"><strong className="lblX">Min</strong><span className="valX">JD {selectedRecord.patientId.insuranceItemsInfo[0].labMin ?? "-"}</span></div>
                      <div className="col-4 card-detail-block block-xdiv mt-lg-3 mt-md-3 mt-2"><strong className="lblX">Max</strong><span className="valX">JD {selectedRecord.patientId.insuranceItemsInfo[0].labMax ?? "-"}</span></div>
                      
                      <div className="col-4 card-detail-block block-xdiv mt-lg-3 mt-md-3 mt-2"><strong className="lblX">Radiology</strong><span className="valX">{selectedRecord.patientId.insuranceItemsInfo[0].radiologyJod ?? "-"}</span></div>
                      <div className="col-4 card-detail-block block-xdiv mt-lg-3 mt-md-3 mt-2"><strong className="lblX">Min</strong><span className="valX">JD {selectedRecord.patientId.insuranceItemsInfo[0].radiologyMin ?? "-"}</span></div>
                      <div className="col-4 card-detail-block block-xdiv mt-lg-3 mt-md-3 mt-2"><strong className="lblX">Max</strong><span className="valX">JD {selectedRecord.patientId.insuranceItemsInfo[0].radiologyMax ?? "-"}</span></div>
                      
                      <div className="col-4 card-detail-block block-xdiv mt-lg-3 mt-md-3 mt-2"><strong className="lblX">Treatment</strong><span className="valX">{selectedRecord.patientId.insuranceItemsInfo[0].treatmentJod ?? "-"}</span></div>
                      <div className="col-4 card-detail-block block-xdiv mt-lg-3 mt-md-3 mt-2"><strong className="lblX">Min</strong><span className="valX">JD {selectedRecord.patientId.insuranceItemsInfo[0].treatmentMin ?? "-"}</span></div>
                      <div className="col-4 card-detail-block block-xdiv mt-lg-3 mt-md-3 mt-2"><strong className="lblX">Max</strong><span className="valX">JD {selectedRecord.patientId.insuranceItemsInfo[0].treatmentMax ?? "-"}</span></div>
                      
                      <div className="col-4 card-detail-block block-xdiv mt-lg-3 mt-md-3 mt-2"><strong className="lblX">Medicine</strong><span className="valX">{selectedRecord.patientId.insuranceItemsInfo[0].medicineJod ?? "-"}</span></div>
                      <div className="col-4 card-detail-block block-xdiv mt-lg-3 mt-md-3 mt-2"><strong className="lblX">Min</strong><span className="valX">JD {selectedRecord.patientId.insuranceItemsInfo[0].medicineMin ?? "-"}</span></div>
                      <div className="col-4 card-detail-block block-xdiv mt-lg-3 mt-md-3 mt-2"><strong className="lblX">Max</strong><span className="valX">JD {selectedRecord.patientId.insuranceItemsInfo[0].medicineMax ?? "-"}</span></div>
                      
                      <div className="col-4 card-detail-block block-xdiv mt-lg-3 mt-md-3 mt-2"><strong className="lblX">Dental</strong><span className="valX">{selectedRecord.patientId.insuranceItemsInfo[0].dentalJod ?? "-"}</span></div>
                      <div className="col-4 card-detail-block block-xdiv mt-lg-3 mt-md-3 mt-2"><strong className="lblX">Min</strong><span className="valX">JD {selectedRecord.patientId.insuranceItemsInfo[0].dentalMin ?? "-"}</span></div>
                      <div className="col-4 card-detail-block block-xdiv mt-lg-3 mt-md-3 mt-2"><strong className="lblX">Max</strong><span className="valX">JD {selectedRecord.patientId.insuranceItemsInfo[0].dentalMax ?? "-"}</span></div>
                      
                    <div className="col-4 card-detail-block block-xdiv mt-lg-3 mt-md-3 mt-2"><strong className="lblX">Invoice - Max</strong><span className="valX">{selectedRecord.patientId.insuranceItemsInfo[0].invoiceMax ?? "-"}</span></div>
                    <div className="col-4 card-detail-block block-xdiv mt-lg-3 mt-md-3 mt-2"><strong className="lblX">Preapproval Limit</strong><span className="valX">{selectedRecord.patientId.insuranceItemsInfo[0].preApprovalLimit ?? "-"}</span></div>
                    <div className="col-4 card-detail-block block-xdiv mt-lg-3 mt-md-3 mt-2"><strong className="lblX">Invoice Max Co-Pay</strong><span className="valX">{selectedRecord.patientId.insuranceItemsInfo[0].invoiceMaxCopay ?? "-"}</span></div>

                    <div className="col-4 card-detail-block block-xdiv mt-lg-3 mt-md-3 mt-2"><strong className="lblX">Out-Patient (Co-Pay)</strong><span className="valX">{selectedRecord.patientId.insuranceItemsInfo[0].outPatientCopay ?? "-"}</span></div>
                    <div className="col-8 card-detail-block block-xdiv mt-lg-3 mt-md-3 mt-2"><strong className="lblX">In-Patient (Co-Pay)</strong><span className="valX">{selectedRecord.patientId.insuranceItemsInfo[0].inPatientCopay ?? "-"}</span></div>
                  </>:undefined
                }

                <div className="col-4 card-detail-block block-xdiv mt-lg-2 mt-md-3 mt-2"><strong className="lblX">Pre-Approval Telephone</strong><span className="valX">{selectedRecord.patientId.insuranceVendorId.phoneNumber ?? "-"}</span></div>
                <div className="col-8 card-detail-block block-xdiv mt-lg-2 mt-md-3 mt-2"><strong className="lblX">Pre-Approval Fax</strong><span className="valX">{selectedRecord.patientId.insuranceVendorId.faxNumber ?? "-"}</span></div>
                <div className="col-12 card-detail-block block-xdiv mt-lg-2 mt-md-3 mt-2"><strong className="lblX">Pre-Approval Email</strong><span className="valX">{selectedRecord.patientId.insuranceVendorId.preapprovalsEmail ?? "-"}</span></div>
                <div className="col-12 card-detail-block block-xdiv mt-lg-2 mt-md-3 mt-2"><strong className="lblX">Eligibility Email</strong><span className="valX">{selectedRecord.patientId.insuranceVendorId.eligibilityEmail ?? "-"}</span></div>
                <div className="col-12 card-detail-block block-xdiv mt-lg-2 mt-md-3 mt-2"><strong className="lblX">Claim Email</strong><span className="valX">{selectedRecord.patientId.insuranceVendorId.claimsEmail ?? "-"}</span></div>



              </div>
            </DialogContent>
          </Dialog>:undefined
        }

        <Notification msg={errorMsg} open={openNotification}
        success={successMsg} />
      </div>
    </div>
  )
}
export default ViewPreApprovalDetail