/*eslint-disable*/
import React, { useState, useEffect, useReducer } from "react";
import Button from "@material-ui/core/Button";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Notification from "../../components/Snackbar/Notification.js";
import CustomTable from "../../components/Table/Table";
import TextField from "@material-ui/core/TextField";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Loader from "react-loader-spinner";
import Header from "../../components/Header/Header";
import PatientDetails from "../../assets/img/PatientDetails.png";
import eye_icon from "../../assets/img/Eye.png";
import Back from "../../assets/img/Back_Arrow.png";
import "../../assets/jss/material-dashboard-react/components/loaderStyle.css";
import cookie from "react-cookies";
import { styles, useStyles, useStylesForTabs } from "./styles.js";
import {
  getEDRsWithPharmacyRequest,
  getCompletedPharamRequests,
} from "./networkCallsForPharma";

const tableHeadingForImgTechnician = [
  "Room No",
  "Production Area",
  "Date/Time",
  "Status",
  "Action",
];
const tableDataKeysForImgTechnician = [
  "roomNo",
  "productionArea",
  "assignedTime",
  "task",
];

const tableHeading = [
  "MRN",
  "Patient Name",
  "Production Area",
  "Room No",
  // 'Care Stream',
  "Date/Time",
  "Status",
  "Action",
];
const tableDataKeys = [
  "mrn",
  "patientName",
  "productionArea",
  "roomNo",
  // 'careStream',
  "createdAt",
  "pharmaRequestStatus",
];

const actions = { edit: true };

export default function FlagMgmt(props) {
  const classesForTabs = useStylesForTabs();
  const classes = useStyles();
  const matches = useMediaQuery("(min-width:600px)");
  const [currentUser, setCurrentUser] = useState(cookie.load("current_user"));

  const [errorMsg, setErrorMsg] = useState("");
  const [openNotification, setOpenNotification] = useState(false);
  const [tabName, setTabName] = useState('Discharge Medication Request');//useState("Medication Request");
  const [buttonValue, setButtonValue] = useState("pending");
  const [pharmaRequests, setPharmaRequests] = useState("");

  if (openNotification) {
    setTimeout(() => {
      setOpenNotification(false);
      setErrorMsg("");
    }, 2000);
  }

  useEffect(() => {
    // getEDRsWithPharmacyRequest(
    //   setPharmaRequests,
    //   setOpenNotification,
    //   setErrorMsg,
    //   "Medication Request"
    // );
    triggerActions();
  }, []);


  const triggerActions = () => {
    if (tabName === "Medication Request") {
      if (buttonValue === "completed") {
        getCompletedPharamRequests(
          setPharmaRequests,
          setErrorMsg,
          setOpenNotification,
          "Medication Request"
        );
      } else if (buttonValue === "pending") {
        getEDRsWithPharmacyRequest(
          setPharmaRequests,
          setOpenNotification,
          setErrorMsg,
          "Medication Request"
        );
      }
    } else if (tabName === "Discharge Medication Request") {
      if (buttonValue === "completed") {
        getCompletedPharamRequests(
          setPharmaRequests,
          setErrorMsg,
          setOpenNotification,
          "Discharge Medication Request"
        );
      } else if (buttonValue === "pending") {
        getEDRsWithPharmacyRequest(
          setPharmaRequests,
          setOpenNotification,
          setErrorMsg,
          "Discharge Medication Request"
        );
      }
    } else {
      if (buttonValue === "completed") {
        getCompletedPharamRequests(
          setPharmaRequests,
          setErrorMsg,
          setOpenNotification,
          "CareStream Request"
        );
      } else if (buttonValue === "pending") {
        getEDRsWithPharmacyRequest(
          setPharmaRequests,
          setOpenNotification,
          setErrorMsg,
          "CareStream Request"
        );
      }
    }
  }

  const handleButtonChange = (buttonValue) => {
    setButtonValue(buttonValue);
    setPharmaRequests("");
    triggerActions();
  };

  const handleTabChange = (e, tabName) => {
    setTabName(tabName);
    setPharmaRequests("");
    triggerActions();
  };

  function handleEditPharma(rec) {
    console.log("rec", rec);

    if (
      rec.chiefComplaint.length > 0 &&
      rec.chiefComplaint &&
      rec.chiefComplaint[rec.chiefComplaint.length - 1].chiefComplaintId
    ) {
      rec.patientId.chiefComplaint =
        rec.chiefComplaint[rec.chiefComplaint.length - 1].chiefComplaintId.name;
    }

    props.history.push({
      pathname: "pharmanotes/requestWithPatientDetails",
      state: {
        tabName,
        selectedRecord: rec,
      },
    });
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        position: "fixed",
        width: "100%",
        height: "100%",
        backgroundColor: "#60d69f",
        overflowY: "scroll",
      }}
    >
      <Header history={props.history} />

      <div className="cPadding">
        <div className="subheader">
          <div>
            <img src={PatientDetails} style={{ marginLeft: "0px" }} />
            <h4>Patients List</h4>
          </div>

          {buttonValue === "pending" ? (
            <Button
              onClick={() => handleButtonChange("completed")}
              style={{
                ...styles.stylesForButton,
                fontSize: matches ? " " : 10,
              }}
              variant="contained"
              color="primary"
            >
              <img src={eye_icon} className="icon-view" />
              &nbsp;&nbsp;
              <strong>Completed Tasks</strong>
            </Button>
          ) : (
            <Button
              onClick={() => handleButtonChange("pending")}
              style={{
                ...styles.stylesForButton,
                fontSize: matches ? " " : 10,
              }}
              variant="contained"
              color="primary"
            >
              <img src={eye_icon} className="icon-view" />
              &nbsp;&nbsp;
              <strong>Pending Tasks</strong>
            </Button>
          )}
        </div>

        <div
          className="container-fluid"
          style={{ paddingLeft: 0, paddingRight: 0 }}
        >
          <div className={classesForTabs.root}>
            <Tabs
              classes={{
                root: classesForTabs.root,
                scroller: classesForTabs.scroller,
              }}
              value={tabName}
              onChange={handleTabChange}
              textColor="primary"
              TabIndicatorProps={{ style: { background: "#12387a" } }}
              centered
            >
              <Tab
                style={{
                  color: "white",
                  borderRadius: 5,
                  outline: "none",
                  color:
                    tabName === "Medication Request" ? "#12387a" : "#3B988C",
                }}
                label="Medication Request"
                value="Medication Request"
              />

              <Tab
                style={{
                  color: "white",
                  borderRadius: 5,
                  outline: "none",
                  color:
                    tabName === "Discharge Medication Request" ? "#12387a" : "#3B988C",
                }}
                label="Discharge Medication Request"
                value="Discharge Medication Request"
              />

              <Tab
                style={{
                  color: "white",
                  borderRadius: 5,
                  outline: "none",
                  color:
                    tabName === "CareStream Request" ? "#12387a" : "#3B988C",
                }}
                label="CareStream Request"
                value="CareStream Request"
              />
            </Tabs>
          </div>

          <div className="row">
            {pharmaRequests ? (
              <div className="col-12">
                <CustomTable
                  tableData={pharmaRequests}
                  tableDataKeys={tableDataKeys}
                  tableHeading={tableHeading}
                  action={actions}
                  handleEdit={handleEditPharma}
                  borderBottomColor={"#60d69f"}
                  borderBottomWidth={20}
                />
              </div>
            ) : (
              <div className="LoaderStyle">
                <Loader type="TailSpin" color="red" height={50} width={50} />
              </div>
            )}
          </div>

          <div className="row" style={{ marginBottom: 20 }}>
            <div className="col-md-12 col-sm-12 col-12">
              <img
                onClick={() => props.history.goBack()}
                src={Back}
                style={{
                  width: 45,
                  height: 35,
                  cursor: "pointer",
                }}
              />
            </div>
          </div>
        </div>

        <Notification msg={errorMsg} open={openNotification} />
      </div>
    </div>
  );
}
