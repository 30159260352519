import { TextField, Grid, makeStyles } from "@material-ui/core";
import React from "react";
import CareStream from "../../../assets/img/carestream/CareStream.png";
import ViewContainerCS from "../../../components/CareStream/ViewContainerCS";
import { viewAssignStyles } from "../styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

export default function Assign(props) {
  const matches = useMediaQuery("(min-width:600px)");
  const matchestablet = useMediaQuery("(min-width:768px)");
  const classes = viewAssignStyles();

  return (
    <div className={classes.root}>
      <div className="subheader" style={{ marginLeft: "-16px" }}>
        <img src={CareStream} className={classes.image} />
        <div style={{ flex: 4, display: "flex", alignItems: "center" }}>
          <h4 style={{ color: "white", fontWeight: "700" }}>
            CareStream Details
          </h4>
        </div>
      </div>

      <Grid
        style={{
          width: "98.5%",
          backgroundColor: "white",
          marginBottom: 15,

          paddingRight: matches ? 0 : 15,
          marginLeft: 10,
        }}
      >
        <TextField
          style={{ width: "100%" }}
          value={props.name ? props.name : "None"}
          placeholder="Please enter carestream name"
          label="CareStream Naem"
          disabled={props.comingFrom === "add" ? true : false}
        />
      </Grid>
      <ViewContainerCS
        arrayContainerCS={props.inclusionCriteria}
        heading="Inclusion Criteria"
        buttonHeading="Inclusion Criteria"
      />
      <ViewContainerCS
        arrayContainerCS={props.exclusionCriteria}
        heading="Exclusion Criteria"
        buttonHeading="Exclusion Criteria"
      />
    </div>
  );
}
