import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import NotFound from '../components/NotFound/NotFound'

import SecondaryRoles from '../views/Home/AdminSubMenus/SecondaryRoles/secondaryRoles'
import AnesthesiologistsSubMenu from '../views/Home/AdminSubMenus/SecondaryRoles/anesthesioloSubMenu'
import AnesthesiologistsList from '../views/SecondryRoles/Anesthesiologists/AnesthesiologistsList'
// import AnesthesiologistsShiftList from "../views/Anesthesiologists/anesthesiologistsShiftList";
import customerCareSubMenu from '../views/Home/AdminSubMenus/SecondaryRoles/customerCareSubMenu'
import secondryCustomerCare from '../views/SecondryRoles/CustomerCareServices/customerCareServices'
// import SecondaryRoles from '../views/Home/AdminSubMenus/SecondaryRoles/secondaryRoles'
import imagingTechnician from '../views/Home/AdminSubMenus/SecondaryRoles/imagingTech'
import SocialWorkers from '../views/SocialWorkerList/SocialWorkers'
import externalConsultantsRoutes from "./externalConsultantsRoutes";

import imagingTechnicianTests from '../views/ImagingTechTests/ImagingTechTests'

class HomeScreenRoutes extends React.PureComponent {
  render() {
    return (
      <Switch>
        <Route
          exact
          path={`${this.props.match.url}`}
          component={SecondaryRoles}
        />
        <Route
          exact
          path={`${this.props.match.url}/socialworkers`}
          component={SocialWorkers}
        />
        <Route
          exact
          path={`${this.props.match.url}/imagingTechnician`}
          component={imagingTechnician}
        />

        <Route
          exact
          path={`${this.props.match.url}/imagingTechnician/imagingTechnicianTests`}
          component={imagingTechnicianTests}
        />

        <Route
          exact
          path={`${this.props.match.url}/anesthesiologists`}
          component={AnesthesiologistsSubMenu}
        />

        <Route
          exact
          path={`${this.props.match.url}/anesthesiologists/list`}
          component={AnesthesiologistsList}
        />
        <Route
          exact
          path={`${this.props.match.url}/anesthesiologists/shiftList`}
          component={AnesthesiologistsList}
        />

        <Route
          exact
          path={`${this.props.match.url}/customercare`}
          component={customerCareSubMenu}
        />
        <Route
          exact
          path={`${this.props.match.url}/customercare/team`}
          component={secondryCustomerCare}
        />
        <Route
          exact
          path={`${this.props.match.url}/customercare/workdone`}
          component={secondryCustomerCare}
        />

        {/* <Route
          exact
          path={`${this.props.match.url}/sensei`}
          component={SenseiSubMenu}
        /> */}

        <Route
          path={`${this.props.match.url}/externalconsultants`}
          component={externalConsultantsRoutes}
        />

        <Route path="*" component={NotFound} />
      </Switch>
    )
  }
}
export default HomeScreenRoutes
