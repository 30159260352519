import React, { useState, useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";

import Header from "../../../components/Header/Header";
// import PhysicalExamIcon from "../../../assets/img/Physical Exam Icon.png"
// import DownloadIcon from "../../../src/assets/img/Download Icon.png"
// import PrintIcon from "../../../src/assets/img/Print Icon.png"

import Button from "@material-ui/core/Button";
import ViewCareStream from "../../../components/CareStream/ViewCareStream";

import _ from "lodash";

const styles = {
  textFieldPadding: {
    paddingLeft: 5,
    paddingRight: 5,
  },
  save: {
    cursor: "pointer",
    borderRadius: 5,
    backgroundColor: "#2973CF",
    width: "180px",
    height: "50px",
    outline: "none",
    color: "white",
  },
  Edit: {
    cursor: "pointer",
    borderRadius: 5,
    width: "180px",
    height: "50px",
    outline: "none",
    border: "2px solid gray",
    backgroundColor: "#FEFEFE",
  },
};
const detailsBlockArray = [
  {
    heading: "General Apperence",
    subArray: [
      {
        subheading: "Distress",
        description: "No Acute",
      },
    ],
  },
  {
    heading: "HEENT",
    subArray: [
      {
        subheading: "Scleral Icterus / Pale Conjunctivae",
        description:
          "A commonly used medicale term to describe jaundice presents in the eyes",
      },
      {
        subheading: "Scleral Icterus / Pale Conjunctivae",
        description:
          "A commonly used medicale term to describe jaundice presents in the eyes",
      },
    ],
  },
  {
    heading: "Neck",
    subArray: [
      {
        subheading: " Scleral Icterus",
        description:
          "A commonly used medicale term to describe jaundice presents in the eyes",
      },
      {
        subheading: " Thyromegaly",
        description:
          "A commonly used medicale term to describe jaundice presents in the eyes",
      },
    ],
  },
  {
    heading: "Respiratory",
    subArray: [
      {
        subheading: " Diagram",
        description:
          "Respiratory System is the network of organs that hepls you breath",
      },
      {
        subheading: " Wheezing",
        description:
          "A commonly used medicale term to describe jaundice presents in the eyes",
      },
      {
        subheading: " Thyromegaly",
        description:
          "A commonly used medicale term to describe jaundice presents in the eyes",
      },
      {
        subheading: " Thyromegaly",
        description:
          "A commonly used medicale term to describe jaundice presents in the eyes",
      },
    ],
  },
];
const useStylesForInput = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(0),
  },
  input: {
    backgroundColor: "white",
    boxShadow: "none",
    borderRadius: 5,
    "&:after": {
      borderBottomColor: "black",
      boxShadow: "none",
    },
    "&:hover": {
      backgroundColor: "white",
      boxShadow: "none",
    },
    "&:focus": {
      backgroundColor: "white",
      boxShadow: "none",
      borderRadius: 5,
    },
  },
  multilineColor: {
    boxShadow: "none",
    backgroundColor: "white",
    borderRadius: 5,
    "&:hover": {
      backgroundColor: "white",
      boxShadow: "none",
    },
    "&:after": {
      borderBottomColor: "black",
      boxShadow: "none",
    },
    "&:focus": {
      boxShadow: "none",
    },
  },
  root: {
    "& .MuiTextField-root": {
      backgroundColor: "white",
    },
    "& .Mui-focused": {
      backgroundColor: "white",
      color: "black",
      boxShadow: "none",
    },
    "& .Mui-disabled": {
      backgroundColor: "white",
      color: "gray",
    },
    "&:focus": {
      backgroundColor: "white",
      boxShadow: "none",
    },
    "& .MuiFormLabel-root": {
      fontSize: "12px",
      paddingRight: "15px",
    },
  },
  label: {
    "&$focusedLabel": {
      color: "red",
      display: "none",
    },
    // "&$erroredLabel": {
    //   color: "orange"
    // }
  },
  focusedLabel: {},
}));
const actions = { view: true };
export default function PrintCareStream(props) {
  const classes = useStylesForInput();
  useEffect(() => {}, []);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        position: "fixed",
        width: "100%",
        height: "100%",
        // backgroundColor: "rgb(19 213 159)",
        overflowY: "scroll",
        border: "1p",
      }}
    >
      <Header history={props.history} />
      <div className="cPadding">
        <div className="subheader" style={{ marginBottom: 45 }}>
          <div className="col-md-6 col-6">
            {/* <img src={PhysicalExamIcon} /> */}
            <h4
              style={{
                color: "#000000",
                fontSize: "40px",
                fontWeight: "bolder",
              }}
            >
              Summary
            </h4>
          </div>
          <div className="col-md-6 col-6">
            {/* <img
              style={{
                width: 35,
                height: "inherit",
                marginRight: 10,
              }}
              src={DownloadIcon}
            />
            <img style={{ width: 35, height: "inherit" }} src={PrintIcon} /> */}
          </div>
        </div>
        <div
          style={{
            flex: 4,
            display: "flex",
            flexDirection: "column",
          }}
          className={`${"container-fluid"} ${classes.root}`}
        >
          <div>
            <h4
              style={{
                color: "#2F77D1",
                fontSize: "xx-large",
                fontWeight: "bold",
              }}
            >
              Time
            </h4>
          </div>
          <div className="row">
            <div className="col-md-3">
              <span style={{ color: "#939393", fontWeight: 600 }}>
                Start Time
              </span>
            </div>
            <div className="col-md-3">
              <span style={{ color: "#939393", fontWeight: 600 }}>
                Pain Scale
              </span>
            </div>
            {/* <div className="col-md-3">
              <span style={{ color: "#939393" }}>Version Number</span>
            </div> */}
          </div>
          <div className="row">
            <div className="col-md-3">
              <span
                style={{
                  fontWeight: "bold",
                  fontSize: "larger",
                }}
              >
                12:00
              </span>
            </div>
            <div className="col-md-3">
              <span
                style={{
                  fontWeight: "bold",
                  fontSize: "larger",
                }}
              >
                Severe (8)
              </span>
            </div>
          </div>
          <hr />

          {detailsBlockArray.map((arr) => {
            return (
              <ViewCareStream heading={arr.heading} subArray={arr.subArray} />
            );
          })}

          <div>
            <div
              style={{
                display: "flex",
                // flex: 1,
                justifyContent: "flex-end",
                marginTop: "30px",
                marginBottom: "2%",
                // paddingRight: !matches ? 20 : 0,
              }}
              className="row"
            >
              <>
                <Button style={styles.Edit} variant="contained" color="default">
                  Edit
                </Button>
                &nbsp;&nbsp;
                <Button style={styles.save} variant="contained" color="default">
                  Save
                </Button>
              </>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
