import React from "react";
import { displayInHumanDifference, replacePatientIdIfNull } from "../../utils/commonFunc";

export default function CardData(props) {
  // console.log('props', props.patient)
  console.log("propsssx", props.patient);
  if (props.patient.patient) {
    console.log("propsss", props.patient.patient.patientId);
  }
  let patient = replacePatientIdIfNull(props.patient.patient);
  console.log('patient', patient);
  return (
    <div>
      {props.patient ? (
        <div className="row">
          {props.patient.patient ? (
            <div className="col-md-3">
              <span
                style={{
                  color: "#939393",
                  fontSize: 14,
                  marginLeft: 15,
                }}
              >
                {"Patient Name"}
              </span>
              <div>
                <span style={{ fontSize: 18, marginLeft: 15 }}>
                  {props.patient.patient.patientId.name[0].given[0] +
                    " " +
                    props.patient.patient.patientId.name[0].family}
                </span>
              </div>
            </div>
          ) : (
            undefined
          )}

          {props.patient.roPatient ? (
            <div className="col-md-3">
              <span
                style={{
                  color: "#939393",
                  fontSize: 14,
                  marginLeft: 15,
                }}
              >
                {"Patient Name"}
              </span>
              <div>
                <span style={{ fontSize: 18, marginLeft: 15 }}>
                  {props.patient.roPatient.name[0].given[0] +
                    " " +
                    props.patient.roPatient.name[0].family}
                </span>
              </div>
            </div>
          ) : (
            undefined
          )}

          {props.patient.roPatient ? (
            <div className="col-md-3">
              <span
                style={{
                  color: "#939393",
                  fontSize: 14,
                  marginLeft: 15,
                }}
              >
                {"Patient MRN"}
              </span>
              <div>
                <span style={{ fontSize: 18, marginLeft: 15 }}>
                  {props.patient.roPatient.identifier[0].value}
                </span>
              </div>
            </div>
          ) : (
            undefined
          )}

          {props.patient.roPatient ? (
            <div className="col-md-3">
              <span
                style={{
                  color: "#939393",
                  fontSize: 14,
                  marginLeft: 15,
                }}
              >
                {"Insurance No"}
              </span>
              <div>
                <span style={{ fontSize: 18, marginLeft: 15 }}>
                  {props.patient.roPatient.insuranceNumber
                    ? props.patient.roPatient.insuranceNumber
                    : "N/A"}
                </span>
              </div>
            </div>
          ) : (
            undefined
          )}

          {props.patient.patient && props.patient.patient.requestNo ? (
            <div className="col-md-3">
              <span
                style={{
                  color: "#939393",
                  fontSize: 14,
                  marginLeft: 15,
                }}
              >
                {"Episode Number"}
              </span>
              <div>
                <span style={{ fontSize: 18, marginLeft: 15 }}>
                  {props.patient.patient.requestNo}
                </span>
              </div>
            </div>
          ) : (
            undefined
          )}

          {/*props.patient.patient && props.patient.patient.chiefComplaint && props.patient.patient.chiefComplaint.length > 0 ? (
            <div className="col-md-3">
              <span
                style={{
                  color: "#939393",
                  fontSize: 14,
                  marginLeft: 15,
                }}
              >
                {"Chief Complaint"}
              </span>
              <div>
                <span style={{ fontSize: 18, marginLeft: 15 }}>
                  {props.patient.patient.chiefComplaint[0].chiefComplaintId.short_name ?? ""}
                </span>
              </div>
            </div>
          ) : (
            undefined
          )*/}

          {props.patient.patient && props.patient.patient.room ? (
            <div className="col-md-3">
              <span
                style={{
                  color: "#939393",
                  fontSize: 14,
                  marginLeft: 15,
                }}
              >
                {"Production Area"}
              </span>
              <div>
                <span style={{ fontSize: 18, marginLeft: 15 }}>
                  {props.patient.patient.room.length>0 && props.patient.patient.room[props.patient.patient.room.length - 1].productionRoomId ? props.patient.patient.room[props.patient.patient.room.length - 1].productionRoomId.paName : "N/A"}
                </span>
              </div>
            </div>
          ) : (
            undefined
          )}

          {props.patient.patient ? (
            <div className="col-md-3">
              <span
                style={{
                  color: "#939393",
                  fontSize: 14,
                  marginLeft: 15,
                }}
              >
                {"Room/Bed No"}
              </span>
              <div>
                <span style={{ fontSize: 18, marginLeft: 15 }}>
                  {props.patient.patient.room &&
                  props.patient.patient.room.length > 0 &&
                  props.patient.patient.room[
                    props.patient.patient.room.length - 1
                  ].roomId.roomNo
                    ? props.patient.patient.room[
                        props.patient.patient.room.length - 1
                      ].roomId.roomNo
                    : "N/A"}
                </span>
              </div>
            </div>
          ) : (
            undefined
          )}
        </div>
      ) : (
        undefined
      )}
    </div>
  );
}
