import axios from "axios";
import {
  //  ED Room history urls
  geteouTimeInterval,
  getTransferToEOU,
  // Current Activity urls
  geteouTransferRequest,
  getdoctorResponseTime,
} from "../../public/endpoins";
import _ from "lodash";

export function getRoomHistoryTableData(
  tabName,
  setTableData,
  setisLoading,
  setOpenNotification,
  setErrorMsg
) {
  let url;
  if (tabName === "Transferred to EOU") {
    url = getTransferToEOU;
  } else if (tabName === "Time Interval") {
    url = geteouTimeInterval;
  } else if (tabName === "CareStream Time Interval") {
  }
  axios
    .get(url)
    .then((res) => {
      if (res.data.success) {
        console.log("Response From API", tabName, res.data.data);
        if (tabName === "Transferred to EOU") {
          res.data.data.map(
            (d) => (
              (d.mrn = d.edrId.patientId.identifier[0].value),
              (d.patientName =
                d.edrId.patientId.name[0].given[0] +
                " " +
                d.edrId.patientId.name[0].family),
              (d.bedCell = d.edrId.room[d.edrId.room.length - 1].roomId.roomNo),
              (d.productionArea =
                d.edrId.chiefComplaint[
                  d.edrId.chiefComplaint.length - 1
                ].chiefComplaintId.productionArea[
                  d.edrId.chiefComplaint[d.edrId.chiefComplaint.length - 1]
                    .chiefComplaintId.productionArea.length - 1
                ].productionAreaId.paName)
            )
          );
        } else if (tabName === "Time Interval") {
          res.data.data.map(
            (d) => (
              (d.mrn = d.patientId.identifier[0].value),
              (d.patientName =
                d.patientId.name[0].given[0] +
                " " +
                d.patientId.name[0].family),
              (d.timeInterval = d.days + " Days " + d.hours + " Hrs")
            )
          );
        } else if (tabName === "CareStream Time Interval") {
        }
        setTableData(res.data.data);
        setisLoading(false);
      } else if (!res.data.success) {
        setErrorMsg(res.data.error);
        setOpenNotification(true);
      }
      return res;
    })
    .catch((e) => {
      console.log("error: ", e);
    });
}

export function getCurrentActivityTableData(
  tabName,
  setTableData,
  setisLoading,
  setOpenNotification,
  setErrorMsg
) {
  let url;
  if (tabName === "EOU Transfer Requests") {
    url = geteouTransferRequest;
  } else if (tabName === "CareStreams") {
    // url =
  } else if (tabName === "Available Beds") {
    // url =
  } else if (tabName === "Occupied Beds") {
    // url =
  } else if (tabName === "Flags Raised") {
    // url =
  } else if (tabName === "Doctor Response Time") {
    url = getdoctorResponseTime;
  }
  axios
    .get(url)
    .then((res) => {
      if (res.data.success) {
        console.log("Response From API", tabName, res.data.data);
        if (tabName === "EOU Transfer Requests") {
          res.data.data.map(
            (d) => (
              (d.mrn =
                d.edrId && d.edrId.patientId
                  ? d.edrId.patientId.identifier[0].value
                  : "N/A"),
              (d.patientName =
                d.edrId && d.edrId.patientId
                  ? d.edrId.patientId.name[0].given[0] +
                    " " +
                    d.edrId.patientId.name[0].family
                  : "N/A"),
              (d.bedCell = d.edrId
                ? d.edrId.room[d.edrId.room.length - 1].roomId.roomNo
                : "No Edr"),
              (d.productionArea = d.edrId
                ? d.edrId.chiefComplaint[d.edrId.chiefComplaint.length - 1]
                    .chiefComplaintId.productionArea[
                    d.edrId.chiefComplaint[d.edrId.chiefComplaint.length - 1]
                      .chiefComplaintId.productionArea.length - 1
                  ].productionAreaId.paName
                : "No Edr")
            )
          );
        } else if (tabName === "CareStreams") {
          // res.data.data.map((d) => (
          //     d.mrn = d.patientId.identifier[0].value,
          //     d.patientName = d.patientId.name[0].given[0] + ' ' + d.patientId.name[0].family,
          //     d.productionArea = d.chiefComplaint.length > 0 ? d.chiefComplaint[d.chiefComplaint.length - 1].chiefComplaintId.productionArea[d.chiefComplaint[d.chiefComplaint.length - 1].chiefComplaintId.productionArea.length - 1].productionAreaId.paName : 'No Area Assigned'
          // ))
        } else if (tabName === "Available Beds") {
          // res.data.data.map((d) => (
          //     d.mrn = d.patientId.identifier[0].value,
          //     d.patientName = d.patientId.name[0].given[0] + ' ' + d.patientId.name[0].family,
          //     d.productionArea = d.chiefComplaint.length > 0 ? d.chiefComplaint[d.chiefComplaint.length - 1].chiefComplaintId.productionArea[d.chiefComplaint[d.chiefComplaint.length - 1].chiefComplaintId.productionArea.length - 1].productionAreaId.paName : 'No Area Assigned'
          // ))
        } else if (tabName === "Occupied Beds") {
          // res.data.data.map((d) => (
          //     d.mrn = d.patientId.identifier[0].value,
          //     d.patientName = d.patientId.name[0].given[0] + ' ' + d.patientId.name[0].family,
          //     d.productionArea = d.chiefComplaint.length > 0 ? d.chiefComplaint[d.chiefComplaint.length - 1].chiefComplaintId.productionArea[d.chiefComplaint[d.chiefComplaint.length - 1].chiefComplaintId.productionArea.length - 1].productionAreaId.paName : 'No Area Assigned'
          // ))
        } else if (tabName === "Flags Raised") {
          // res.data.data.map((d) => (
          //     d.mrn = d.patientId.identifier[0].value,
          //     d.patientName = d.patientId.name[0].given[0] + ' ' + d.patientId.name[0].family,
          //     d.productionArea = d.chiefComplaint.length > 0 ? d.chiefComplaint[d.chiefComplaint.length - 1].chiefComplaintId.productionArea[d.chiefComplaint[d.chiefComplaint.length - 1].chiefComplaintId.productionArea.length - 1].productionAreaId.paName : 'No Area Assigned'
          // ))
        } else if (tabName === "Doctor Response Time") {
          res.data.data.map(
            (d) => (
              (d.mrn = d.patientId.identifier[0].value),
              (d.patientName =
                d.patientId.name[0].given[0] +
                " " +
                d.patientId.name[0].family),
              (d.productionArea =
                d.chiefComplaint.length > 0
                  ? d.chiefComplaint[d.chiefComplaint.length - 1]
                      .chiefComplaintId.productionArea[
                      d.chiefComplaint[d.chiefComplaint.length - 1]
                        .chiefComplaintId.productionArea.length - 1
                    ].productionAreaId.paName
                  : "No Area Assigned"),
              (d.doctorName =
                d.consultationNote.consultant.name[0].given[0] +
                " " +
                d.consultationNote.consultant.name[0].family),
              (d.consultantType = d.consultationNote.consultant.subType[0]),
              (d.responseTime = d.days + " Days " + d.hours + " Hrs")
            )
          );
        }
        setTableData(res.data.data);
        setisLoading(false);
      } else if (!res.data.success) {
        setErrorMsg(res.data.error);
        setOpenNotification(true);
      }
      return res;
    })
    .catch((e) => {
      console.log("error: ", e);
    });
}
