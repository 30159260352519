// import React, { useEffect, useState } from "react";
// import useMediaQuery from "@material-ui/core/useMediaQuery";
// import Dialer from "../../components/Dial/dialer";
// import OneValue from "../../components/Dial/OneValue";
// import axios from "axios";
// import { cashier } from "../../public/endpoins";
// export default function CommitteeMemberDashboard() {
//   const matches = useMediaQuery("(min-width:600px)");
//   const [dischargePending, setDischargePending] = useState("");
//   const [payment, setPayment] = useState("");
//   const [dischargePendingColor, setDischargePendingColor] = useState("");
//   const [paymentColor, setPaymentColor] = useState("");
//   const [tat, setTatForDischarge] = useState("");

//   useEffect(() => {
//     axios
//       .get(cashier)
//       .then((res) => {
//         console.log(res.data);
//         if (res.data.success) {
//           if (
//             res.data.dischargePending >= 0 &&
//             res.data.dischargePending <= 39
//           ) {
//             setDischargePendingColor("#60D69F");
//           } else if (
//             res.data.dischargePending >= 40 &&
//             res.data.dischargePending <= 79
//           ) {
//             setDischargePendingColor("#FFBC28");
//           } else if (res.data.dischargePending >= 80) {
//             setDischargePendingColor("#FF0000");
//           }
//           if (res.data.payment >= 0 && res.data.payment <= 39) {
//             setPaymentColor("#60D69F");
//           } else if (res.data.payment >= 40 && res.data.payment <= 79) {
//             setPaymentColor("#FFBC28");
//           } else if (res.data.payment >= 80 && res.data.payment <= 100) {
//             setPaymentColor("#FF0000");
//           }
//           setDischargePending(res.data.dischargePending);
//           setPayment(res.data.payment);

//           setTatForDischarge(res.data.tat);
//         }
//       })
//       .catch((error) => {
//         console.log(error);
//       });
//   }, []);

//   return (
//     <div className="container-fluid" style={{ marginBottom: 10 }}>
//       <div className="row" style={{ marginBottom: matches ? "" : 20 }}>
//         <div
//           className="col-md-4"
//           style={{ paddingLeft: 4, paddingRight: 4, marginTop: 10 }}
//         >
//           <div style={{}}>
//             <Dialer
//               mainHeading={"Discharge / Disposition Requests Pending"}
//               value={dischargePending}
//               color={dischargePendingColor}
//               subHeading={"TAT"}
//               childHeading={"Receipt of Request to Completion"}
//               time={tat ? tat : "00"}
//             />
//           </div>
//         </div>

//         <div
//           className="col-md-4"
//           style={{
//             paddingLeft: 4,
//             paddingRight: 4,
//             marginTop: 10,
//             marginBottom: matches ? " " : 20,
//           }}
//         >
//           <div style={{ height: 250, width: "100%" }}>
//             <OneValue
//               heading={"Amount Collected"}
//               time={payment}
//               colorTime={paymentColor}
//             />
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }
import React, { useEffect, useState } from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Flip from "../../components/Dial/Flip";
import TwoValue from "../../components/Dial/TwoValue";
import OneValue from "../../components/Dial/OneValue";
import IPBeds from "../../assets/img/ipbeds.png";
import EDBeds from "../../assets/img/edbeds.png";

export default function EDdoctor() {
  const matches = useMediaQuery("(min-width:600px)");
  const [parPending, setParPending] = useState("");
  const [totalInsured, setTotalInsured] = useState("");
  const [totalUnInsured, setTotalUnInsured] = useState("");
  const [patient, setPatient] = useState("");
  const [perHour, setPerHour] = useState("");

  // colors
  const [parPendingColor, setParPendingColor] = useState("");
  const [totalInsuredColor, setTotalInsuredColor] = useState("");
  const [totalUnInsuredColor, setTotalUnInsuredColor] = useState("");
  const [patientColor, setPatientColor] = useState("");
  const [perHourColor, setPerHourColor] = useState("");

  const [flip, setFlip] = useState(false);
  const [dummyData, setDummyData] = useState("");

  useEffect(() => {
    const dummyData = [
      { value: 10, label: new Date("2021-02-16T07:48:26.948Z") },
      { value: 100, label: new Date("2021-02-16T12:48:26.948Z") },
      { value: 80, label: new Date("2021-02-16T11:48:26.948Z") },
      { value: 70, label: new Date("2021-02-16T10:48:26.948Z") },
      { value: 60, label: new Date("2021-02-16T09:48:26.948Z") },
      { value: 50, label: new Date("2021-02-16T08:48:26.948Z") },
    ];

    setDummyData(dummyData);
    // axios
    //   .get(registrationOfficer)
    //   .then((res) => {
    //     console.log(res.data)
    //     if (res.data.success) {
    //       if (res.data.parPending >= 0 && res.data.parPending <= 39) {
    //         setParPendingColor('#60D69F')
    //       } else if (res.data.parPending >= 40 && res.data.parPending <= 79) {
    //         setParPendingColor('#FFBC28')
    //       } else if (res.data.parPending >= 80 && res.data.parPending <= 100) {
    //         setParPendingColor('#FF0000')
    //       }
    //       if (res.data.totalInsured >= 0 && res.data.totalInsured <= 39) {
    //         setTotalInsuredColor('#60D69F')
    //       } else if (
    //         res.data.totalInsured >= 40 &&
    //         res.data.totalInsured <= 70
    //       ) {
    //         setTotalInsuredColor('#FFBC28')
    //       } else if (
    //         res.data.totalInsured >= 80 &&
    //         res.data.totalInsured <= 100
    //       ) {
    //         setTotalInsuredColor('#FF0000')
    //       }
    //       if (res.data.totalUnInsured >= 0 && res.data.totalUnInsured <= 39) {
    //         setTotalUnInsuredColor('#60D69F')
    //       } else if (
    //         res.data.totalUnInsured >= 40 &&
    //         res.data.totalUnInsured <= 70
    //       ) {
    //         setTotalUnInsuredColor('#FFBC28')
    //       } else if (
    //         res.data.totalUnInsured >= 80 &&
    //         res.data.totalUnInsured <= 100
    //       ) {
    //         setTotalUnInsuredColor('#FF0000')
    //       }
    //       if (res.data.patient >= 0 && res.data.patient <= 39) {
    //         setPatientColor('#60D69F')
    //       } else if (res.data.patient >= 40 && res.data.patient <= 70) {
    //         setPatientColor('#FFBC28')
    //       } else if (res.data.patient >= 80 && res.data.patient <= 100) {
    //         setPatientColor('#FF0000')
    //       }
    //       if (res.data.perHour >= 0 && res.data.perHour <= 39) {
    //         setPerHourColor('#60D69F')
    //       } else if (res.data.perHour >= 40 && res.data.perHour <= 70) {
    //         setPerHourColor('#FFBC28')
    //       } else if (res.data.perHour >= 80 && res.data.perHour <= 100) {
    //         setPerHourColor('#FF0000')
    //       }
    //       setParPending(res.data.parPending)
    //       setTotalInsured(res.data.totalInsured)
    //       setTotalUnInsured(res.data.totalUnInsured)
    //       setPatient(res.data.patient)
    //       setPerHour(res.data.perHour)
    //     }
    //   })
    //   .catch((error) => {
    //     console.log(error)
    //   })
  }, []);

  const flipIt = () => {
    setFlip(true);
  };
  return (
    <div className="container-fluid" style={{ marginBottom: 10 }}>
      <div className="row">
        <div
          className="col-md-4 col-6"
          style={{ paddingLeft: 4, paddingRight: 4, marginTop: 10 }}
        >
          <div>
            <Flip
              flipped={!flip}
              flipHandler={flipIt}
              mainHeading={"Patients Awaiting Insurance Verification"}
              value={70}
              mainHeadingForGraph="Patient Consultations Pending"
              color="#FFBC28"
              subHeading={"TAT"}
              childHeading={"Registration Initiated to Insurance Verified"}
              time={"30"}
              idForGraph={"container1"}
              data={dummyData}
            />
          </div>
        </div>

        <div
          className="col-md-4 col-6"
          style={{ paddingLeft: 4, paddingRight: 4, marginTop: 10 }}
        >
          <div>
            <Flip
              flipped={!flip}
              flipHandler={flipIt}
              mainHeading={"Patients Awaiting Insurance Pre-Approved"}
              value={30}
              mainHeadingForGraph="Patients Awaiting Insurance Pre-Approved"
              color="#13D59F"
              subHeading={"TAT"}
              childHeading={"Registration Initiated to Pre-Approval"}
              time={"25"}
              idForGraph={"container2"}
              data={dummyData}
            />
          </div>
        </div>
        <div
          className="col-md-4 col-6"
          style={{ paddingLeft: 4, paddingRight: 4, marginTop: 10 }}
        >
          <div>
            <Flip
              flipped={!flip}
              flipHandler={flipIt}
              mainHeading={"Incomplete RCM Invoices"}
              value={80}
              mainHeadingForGraph="Incomplete RCM Invoices"
              color="#FF0000"
              subHeading={"TAT"}
              // flagsCount={10}
              childHeading={"Patients Disposed to Invoice Created"}
              time={"15"}
              idForGraph={"container2"}
              data={dummyData}
            />
          </div>
        </div>
        <div
          className="col-md-4 col-6"
          style={{ paddingLeft: 4, paddingRight: 4, marginTop: 10 }}
        >
          <div>
            <Flip
              flipped={!flip}
              flipHandler={flipIt}
              mainHeading={"Discharge / Disposition Requests Pending"}
              value={40}
              mainHeadingForGraph="Discharge / Disposition Requests Pending"
              color="#13D59F"
              subHeading={"TAT"}
              childHeading={"Receipt of Request to Completion"}
              time={"55"}
              idForGraph={"container2"}
              data={dummyData}
            />
          </div>
        </div>
        {/* 
        <div
          className="col-md-4 col-6"
          style={{ paddingLeft: 4, paddingRight: 4, marginTop: 10 }}
        >
          <div>
            <TwoValue
              heading1={"Compliance/Variance Report"}
              time1={"106"}
              time1Color={"#FFBC28"}
              time2Color={"#13D59F"}
              heading2={"Patients Consulted/Hour"}
              time2={"35"}
            />
          </div>
        </div> */}

        <div
          className="col-md-4 col-6"
          style={{ paddingLeft: 4, paddingRight: 4, marginTop: 10 }}
        >
          <div>
            <OneValue
              time={523}
              colorTime={"#13D59F"}
              heading={"Amount Collected"}
            />
          </div>
        </div>
        <div
          className="col-md-4 col-6"
          style={{ paddingLeft: 4, paddingRight: 4, marginTop: 10 }}
        >
          <div>
            <OneValue
              time={964}
              colorTime={"#13D59F"}
              heading={"Amount Collected"}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
