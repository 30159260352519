import {
    SET_PATIENT_DETAILS
  } from "../constants/ActionTypes";
  
  const INIT_STATE = {
    selectedPatientDetails: "",
  };
  
  const PatientReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
      case SET_PATIENT_DETAILS:
        return {
          ...state,
          selectedPatientDetails: {...action.payload},
        };
  
      default:
        return state;
    }
  };
  export default PatientReducer;
  