import React, { useEffect, useState, useReducer, forwardRef } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import { FaUpload } from "react-icons/fa";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import DateFnsUtils from "@date-io/date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { DateTimePicker } from "@material-ui/pickers";
import { MdRemoveCircle } from "react-icons/md";
import Fingerprint from "../../assets/img/fingerprint.png";
import CloseIcon from "../../assets/img/CloseIconPng.png";
// import CloseIcon from "../../assets/img/close-1.png";
import Avatar from "@material-ui/core/Avatar";
import cookie from "react-cookies";
import BarCode from "../../assets/img/Bar Code.png";
import ErrorMessage from "../../components/ErrorMessage/errorMessage";
import validateEmail from "../../public/emailValidator";
import validateFirstName from "../../public/inputValidator";
import validateLastName from "../../public/inputValidatorLName";
import validateEmergencyName from "../../public/inputValidator";
import validateInsuranceVendor from "../../public/inputValidator";
import validateNationalId from "../../public/inputValidatorLName";
import validateinsuranceNumber from "../../public/insuranceValidator";
import validateTextField from "../../public/inputValidatorLName";
import MuiPhoneNumber from "material-ui-phone-number";
import validatePhone from "../../public/validatePhone";
import validatePhone2 from "../../public/validatePhone2";
import CurrencyTextField from "@unicef/material-ui-currency-textfield";
import validateCountryCity from "../../public/countryCityValidator";
import validateGender from "../../public/genderValidator";
import validateRelation from "../../public/relationValidator";
import validateAddress from "../../public/addressValidator";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { DropzoneDialog } from "material-ui-dropzone";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import MCloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import { patientSearch } from "./NetworkCall";
import {
  uploadsUrl,
  updatePatientUrl,
  patientRegistration,
  // generateEDR,
  getVendorApproval,
  SearchPatients,
  getPatientByIdUrl,
  audioURL,
  AssignEdrToPatient,
  SearchInRegistration
} from "../../public/endpoins";
import Autocomplete from "@material-ui/lab/Autocomplete";
import axios from "axios";
import Notification from "../../components/Snackbar/Notification.js";
import ButtonField from "../../components/common/Button";
import Header from "../../components/Header/Header";
import patientRegister from "../../assets/img/PatientRegistration.png";
import "../../assets/jss/material-dashboard-react/components/TextInputStyle.css";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import FormData from "form-data";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import AccountCircle from "@material-ui/icons/SearchOutlined";
import InputAdornment from "@material-ui/core/InputAdornment";
import Loader from "react-loader-spinner";

import QRCodeScannerComponent from "../../components/QRCodeScanner/QRCodeScanner";

// import validatePhone from '../public/validatePhone'

let countriesList = require("../../assets/countries.json");
let matches;

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
/*
...styles.inputContainerForTextField,
...styles.textFieldPadding,
*/
const styles = {
  stylesForButton: {
    color: "white",
    cursor: "pointer",
    borderRadius: 5,
    backgroundColor: "#2c6ddd",
    width: "130px",
    height: "45px",
    outline: "none",
  },
  inputContainerForTextField: {
    // marginTop: 10,
  },
  inputContainerForDropDown: {
    marginTop: 10,
  },
  textFieldPadding: {
    // paddingLeft: 5,
    // paddingRight: 5,
  },
  save: {
    color: "white",
    cursor: "pointer",
    borderRadius: 5,
    backgroundColor: "#ba55d3",
    width: "130px",
    height: "45px",
    outline: "none",
  },
  generate: {
    color: "white",
    cursor: "pointer",
    borderRadius: 5,
    backgroundColor: "#e877a1",
    height: "45px",
    width: "190px",
    outline: "none",
  },
  None: {
    display: "none",
  },
  form: {
    backgroundColor: "white",
    borderRadius: "5px",
    marginTop: "10px",
    padding: "10px",
    textAlign: "center",
  },
  form2: {
    backgroundColor: "white",
    borderRadius: "5px",
    padding: "5px 15px",
  },
  form3: {
    backgroundColor: "white",
    borderRadius: "5px",
    padding: "0px 15px",
  },
  upload: {
    backgroundColor: "white",
    border: "0px solid #ccc",
    borderRadius: "5px",
    color: "gray",
    width: "100%",
    height: "60px",
    cursor: "pointer",
    padding: "15px",
  },
  input: {
    display: "none",
  },
};

const useStylesForTabs = makeStyles((theme) => ({
  root: {
    justifyContent: "center",
  },
  scroller: {
    flexGrow: "0",
  },
  dialogAppBar: {
    position: 'relative',
  },
  dialogTitle: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const titles = [
  {
    key: "mr" || "Mr",
    value: "Mr",
  },
  {
    key: "miss" || "Miss",
    value: "Miss",
  },
  {
    key: "mrs" || "Mrs",
    value: "Mrs",
  },
  {
    key: "ms" || "Ms",
    value: "Ms",
  },
];

const genderArray = [
  {
    key: "Male",
    value: "Male",
  },
  {
    key: "Female",
    value: "Female",
  },
  /*{
    key: "Others",
    value: "Others",
  },*/
];

const relationArray = [
  {
    key: "spouse",
    value: "Spouse",
  },
  {
    key: "father",
    value: "Father",
  },
  {
    key: "mother",
    value: "Mother",
  },
  {
    key: "brother",
    value: "Brother",
  },
  {
    key: "sister",
    value: "Sister",
  },
  {
    key: "son",
    value: "Son",
  },
  {
    key: "daughter",
    value: "Daughter",
  },
  {
    key: "other",
    value: "Other",
  },

  //Additionally, the order has to be fixed:  1. Spouse, 2. Father, 3. Mother, 4. Brother, 5. Sister, 6. Son, 7. Daughter
];

const coverageTermsArr = [
  {
    key: "Co Payment",
    value: "Co-Payment",
  },
  {
    key: "Full Payment",
    value: "Full Payment",
  },
];

const coveredFamilyArray = [
  {
    key: "self",
    value: "Self",
  },
  {
    key: "father",
    value: "Father",
  },
  {
    key: "mother",
    value: "Mother",
  },
  {
    key: "son",
    value: "Son",
  },
  {
    key: "daughter",
    value: "Daughter",
  },
  {
    key: "spouse",
    value: "Spouse",
  },
];

const bloodGroups = [
  {
    key: "A+",
    value: "A+",
  },
  {
    key: "A-",
    value: "A-",
  },
  {
    key: "B+",
    value: "B+",
  },
  {
    key: "B-",
    value: "B-",
  },
  {
    key: "O+",
    value: "O+",
  },
  {
    key: "O-",
    value: "O-",
  },
  {
    key: "AB+",
    value: "AB+",
  },
  {
    key: "AB-",
    value: "AB-",
  },
];

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(0),
  },
  input: {
    backgroundColor: "white",
    boxShadow: "none",
    borderRadius: 5,
    "&:after": {
      borderBottomColor: "black",
      boxShadow: "none",
    },
    "&:hover": {
      backgroundColor: "white",
      boxShadow: "none",
    },
    "&:focus": {
      backgroundColor: "white",
      boxShadow: "none",
      borderRadius: 5,
    },
  },
  multilineColor: {
    boxShadow: "none",
    backgroundColor: "white",
    borderRadius: 5,
    "&:hover": {
      backgroundColor: "white",
      boxShadow: "none",
    },
    "&:after": {
      borderBottomColor: "black",
      boxShadow: "none",
    },
    "&:focus": {
      boxShadow: "none",
    },
  },
  root: {
    "& .MuiTextField-root": {
      backgroundColor: "white",
    },
    "& .Mui-focused": {
      backgroundColor: "white",
      color: "black",
      boxShadow: "none",
    },
    "& .Mui-disabled": {
      backgroundColor: "white",
      color: "gray",
    },
    "&:focus": {
      backgroundColor: "white",
      boxShadow: "none",
      // display: matches ? " " : "none",
    },
    "& .MuiFormLabel-root": {
      fontSize: "15px",
      paddingRight: "45px",
    },
  },
  label: {
    "&$focusedLabel": {
      color: "red",
      display: "none",
    },
  },

  square: {
    // color: theme.palette.getContrastText(deepOrange[500]),
    // backgroundColor: deepOrange[500],
    width: theme.spacing(35),
    height: theme.spacing(25),
  },
  large: {
    width: theme.spacing(20),
    height: theme.spacing(20),
  },
}));

function PatientRegistration(props) {
  matches = useMediaQuery("(min-width:600px)");
  const classes = useStyles();

  const initialState = {
    identifier: [{ value: "" }],
    nationalID: "",
    nationality: "Jordan",
    isJohnDoe: false,
    identityType: "national-id",
    gender: "",
    isChild: false,
    emergencyFormUndisclosed: false,
    age: "0",
    blood: "",
    birthDate: new Date(),
    otherDetails: "",
    paymentMode: "Selfpay",
    insuranceNumber: "",
    insurancePolicyNumber: "",
    insurancePlanNetwork: "",
    insuranceFrom: null,
    insuranceTo: null,
    coverageDetails: "",
    coverageTerms: "",
    coPayment: "",
    insuranceVendorId: "",
    insuranceVendor: "",
    insureeName: "",
    insuranceDetails: "",
    insuranceEligibility: "No",
    registrationStatus: "completed",
    // insurerId,
  };

  function reducer(state, { field, value }) {
    return {
      ...state,
      [field]: value,
    };
  }

  const [state, dispatch] = useReducer(reducer, initialState);

  const {
    identifier,
    nationalID,
    isJohnDoe,
    identityType,
    nationality,
    gender,
    isChild,
    emergencyFormUndisclosed,
    age,
    blood,
    birthDate,
    otherDetails,
    paymentMode,
    insuranceNumber,
    coverageDetails,
    coverageTerms,
    coPayment,
    insuranceVendorId,
    insuranceVendor,
    insureeName,
    insuranceDetails,
    insuranceEligibility,
    insurancePolicyNumber,
    insurancePlanNetwork,
    insuranceFrom,
    insuranceTo,

    // Insurance Dialog Fields
    insuranceExclusions,
    maxInsuranceLiability,
    preApprovalLimit,
    invoiceMaxCopay,
    outPatientCopay,
    inPatientCopay,
    consultationJod,
    consultationCopay,
    consultationMin,
    consultationMax,
    labJod,
    labCoverageType,
    labCopay,
    labMin,
    labMax,
    radiologyJod,
    radiologyCoverageType,
    radiologyCopay,
    radiologyMin,
    radiologyMax,
    treatmentJod,
    treatmentCoverageType,
    treatmentCopay,
    treatmentMin,
    treatmentMax,
    medicineJod,
    medicineCoverageType,
    medicineCopay,
    medicineMin,
    medicineMax,
    dentalJod,
    dentalCoverageType,
    dentalCopay,
    dentalMin,
    dentalMax,
    maternityCopay,
    maternityMin,
    maternityMax,

    registrationStatus,
    // insurerId,
  } = state;

  const classesForTabs = useStylesForTabs();

  const [insuranceDialogOpen, setInsuranceDialogOpen] = useState(false);
  const [comingFor, setcomingFor] = useState("");
  const [currentUser] = useState(cookie.load("current_user"));
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setsuccessMsg] = useState("");
  const [openNotification, setOpenNotification] = useState(false);
  const [countries, setCountries] = useState("");
  const [cities, setCities] = useState("");
  const [value, setValue] = React.useState(0);
  const [slipUpload, setSlipUpload] = useState("");
  const [frontUpload, setFrontUpload] = useState("");
  const [backUpload, setBackUpload] = useState("");
  const [insuranceCardUpload, setInsuranceCardUpload] = useState("");
  const [imagePreview, setImagePreview] = useState("");
  const [frontPreview, setFrontPreview] = useState("");
  const [backPreview, setBackPreview] = useState("");
  const [insuranceCardPreview, setInsuranceCardPreview] = useState("");
  const [patientId, setPatientId] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [itemFound, setItemFound] = useState("");
  const [itemFoundSuccessfull, setItemFoundSuccessfully] = useState(false);
  const [searchActivated, setsearchActivated] = useState(false);
  const [MRN, setMRN] = useState("");
  const [isPatientSubmitted, setIsPatientSubmitted] = useState(false);
  const [enableForm, setenableForm] = useState(true);
  const [enableNext, setenableNext] = useState(true);
  const [coPaymentField, setCoPaymentField] = useState(false);
  const [detailsForm, setDetailsForm] = useState(false);
  const [emergencyForm, setEmergencyForm] = useState(false);
  const [paymentForm, setPaymentForm] = useState(false);
  const [insuranceForm, setinsuranceForm] = useState(false);
  const [insuranceBoolean, setInsuranceBoolean] = useState(false);// 22-06-2022
  const [covTer, setCovTer] = useState("");
  const [timer, setTimer] = useState(null);
  const [cityBoolean, setCityBoolean] = useState(false);
  const [loadSearchedData, setLoadSearchedData] = useState(false);
  const [photo, setphoto] = useState([]);
  const [idCardFront, setIdCardFront] = useState("");
  const [idCardBack, setIdCardBack] = useState("");
  const [insuranceCard, setInsuranceCard] = useState("");
  const [assignMode, setAssignMode] = useState(false);
  const [episodeNumber, setEpisodeNumber] = useState("");
  const [edrId, setEdrId] = useState("");
  const [name, setName] = useState([
    { use: "official", family: "", given: [""], prefix: "" },
  ]);

  const [insuranceItemsInfo, setInsuranceItemsInfo] = useState([{
    insuranceExclusions: "",
    maxInsuranceLiability: "",
    preApprovalLimit: "",
    invoiceMaxCopay: "",
    outPatientCopay: "",
    inPatientCopay: "",
    consultationJod: "",
    consultationCopay: "",
    consultationMin: "",
    consultationMax: "",
    labJod: "",
    labCoverageType: "Each",
    labCopay: "",
    labMin: "",
    labMax: "",
    radiologyJod: "",
    radiologyCoverageType: "Each",
    radiologyCopay: "",
    radiologyMin: "",
    radiologyMax: "",
    treatmentJod: "",
    treatmentCoverageType: "Each",
    treatmentCopay: "",
    treatmentMin: "",
    treatmentMax: "",
    medicineJod: "",
    medicineCoverageType: "Each",
    medicineCopay: "",
    medicineMin: "",
    medicineMax: "",
    dentalJod: "",
    dentalCoverageType: "Each",
    dentalCopay: "",
    dentalMin: "",
    dentalMax: "",
    maternityCopay: "",
    maternityMin: "",
    maternityMax: "",
  }]);

  //
  // const [labCoverageType, setLabCoverageType] = useState("");

  const handleInsuranceDialogFields = (e, index) => {

    const list = [...insuranceItemsInfo];
    list[0][e.target.name] = e.target.value.replace(/[^\w'\s]/gi, "");
    setInsuranceItemsInfo(list);

    console.log({
      field: e.target.name,
      value: e.target.value.replace(/[^\w.'-\s]/gi, ""),
    });
    console.log(list);
    // dispatch({
    //   field: e.target.name,
    //   value: e.target.value.replace(/[^\w.'-\s]/gi, ""),
    // });
  };
  //

  const [qRCodeForPrint, setQRCodeForPrint] = useState("");

  const [QRCodeScanner, setQRCodeScanner] = useState(false);

  const [time, setTime] = useState([{ processStartTime: new Date() }]);

  const [telecom, setTelecom] = useState([
    { value: "", system: "email" , countryCode:"" },
    { value: "", system: "mobile", countryCode:"" },
    { value: "", system: "phone" , countryCode:"" },
  ]);
  const [address, setAddress] = useState([
    {
      use: "home",
      type: "postal",
      text: "",
      city: "",
      country: "",
    },
  ]);
  const [contact, setContact] = useState([
    {
      name: "",
      telecom: [{ value: "", system: "mobile", countryCode: "" }],
      typeOfRelation: "",
      otherRelation: "",
    },
  ]);
  const [coveredFamilyMember, setCoveredFamilyMember] = useState([
    {
      name: "",
      telecom: [{ system: "mobile", value: "" }],
      typeOfRelation: "",
    },
  ]);

  useEffect(() => {
    setCountries(Object.keys(countriesList[0]));
    console.log('handleAssign::', props.location.state);
    if (
      props.location.state !== undefined &&
      props.location.state.comingFrom === "notification"
    ) {
      setSearchQuery(props.location.state.edrId);
      patientSearch(
        props.location.state.edrId,
        SearchPatients,
        setItemFoundSuccessfully,
        setItemFound,
        setLoadSearchedData,
        setPatientId,
        setName,
        setTelecom,
        setCities,
        setAddress,
        setphoto,
        setIdCardFront,
        setIdCardBack,
        setContact,
        setCoveredFamilyMember,
        setInsuranceCard,
        dispatch,
        setenableForm,
        setinsuranceForm,
        setenableNext,
        setcomingFor
      );
    } else if (
      props.location.state !== undefined &&
      props.location.state.comingFor === "edrAssign"
    ) {
      setAssignMode(true);
      setEpisodeNumber(props.location.state.episodeNumber);
      setEdrId(props.location.state.edrId);
      setcomingFor('add');
      
    } else {
      const patientSelected = props.history.location.state;
      setcomingFor(
        patientSelected && patientSelected.comingFor
          ? patientSelected.comingFor
          : "add"
      );

      const selectedRec = patientSelected && patientSelected.selectedItem;

      if (selectedRec) {
        let cities = Object.entries(countriesList[0]);

        setPatientId(selectedRec._id);

        Object.entries(selectedRec).map(([key, val]) => {
          // console.log('key', key, val, typeof(val));
          if (key === "name") {
            setName(val);
          } else if (key === "telecom") {
            setTelecom(val);
          } else if (key === "address") {
            for (var x in cities) {
              let arr = cities[x];
              if (arr[0] === val[0].country) {
                setCities(arr[1]);
              }
            }
            setAddress(val);
          } else if (key === "photo") {
            setphoto(val);
          } else if (key === "idCardFront") {
            setIdCardFront(val);
          } else if (key === "idCardBack") {
            setIdCardBack(val);
          } else if (key === "contact") {
            setContact(val);
          } else if (key === "coveredFamilyMember") {
            setCoveredFamilyMember(val);
          } else if (key === "insuranceCard") {
            setInsuranceCard(val);
          } else if (key === "insuranceVendorId") {
            setInsuranceVendorData(val);
            dispatch({ field: key, value: val });
          } else if (key === "insuranceItemsInfo") {
            console.log("insuranceItemsInfo", val);
            if (val && val.length > 0){
              setInsuranceItemsInfo(val);
            }
          } else {
            dispatch({ field: key, value: val });
          }
        });
      }
      if (
        props.history.location.state &&
        props.history.location.state.comingFor === "edit"
      ) {
        if (selectedRec.paymentMode === "Insured") {
          setenableForm(false);
          setinsuranceForm(true);
          setenableNext(false);
        }
      }
    }
  }, []);

  //
  const [insuranceVendorSelect, setInsuranceVendorSelect] = useState({
    
  });
  const [insuranceVendorList, setInsuranceVendorList] = useState([]);
  const [insuranceVendorKeyword, setInsuranceVendorKeyword] = useState("");

  useEffect(() => {
    const getInsuranceVendorList = () => {
      axios
        .get(getVendorApproval, {
          params: {
            keyword: insuranceVendorKeyword
          }
        })
        .then((response) => {
          console.log("insuranceVendorDropdown", response.data.items);
          let insuranceVendorDropdown = [];
          Object.entries(response.data.items).map(([key, val]) => {
            insuranceVendorDropdown.push(val);
            console.log(key);
            console.log(val);
          });
          console.log("insuranceVendorDropdown", insuranceVendorDropdown);
          setInsuranceVendorList(insuranceVendorDropdown);
        })
        .catch((error) => {
          console.log("Error", error);
        });
    };
    insuranceVendorKeyword && getInsuranceVendorList();
  }, [insuranceVendorKeyword]);

  const setInsuranceVendorData  = (value) => {
    axios
      .get(getVendorApproval + '/' + value)
      .then((response) => {
        console.log("insuranceVendorPulledData", response.data.item);
        setInsuranceVendorSelect(response.data.item);
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };

  const handleInsuranceCompanySelect = (
    e, 
    v
  ) => {
    setInsuranceVendorSelect(v);
    if (v){
      // setInsuranceBoolean(false); // 22-06-2022
      dispatch({
        field: "coverageTerms",
        value: "Full Payment",
      });
      dispatch({ field: "insuranceVendorId", value: v._id });
      dispatch({ field: "insuranceVendor", value: v.companyName });
      dispatch({ field: "insurerId", value: v.insurerId });
      // setCovTer(v.coverageDetail);
      console.log("insuranceVendorSelect", v, e);
    }
  };

  // handle input change
  const handleNameChange = (e, index) => {
    const list = [...name];
    if (/^[a-zA-Z' ]*$/.test(e.target.value) === false) {
      return;
    } else {
      if (e.target.name === "given") {
        list[index][e.target.name] = [e.target.value.replace(/[^\w'\s]/gi, "")];
      } else {
        list[index][e.target.name] = e.target.value.replace(/[^\w'\s]/gi, "");
      }
    }
    //.replace(/\s+/g, ' ').trim()

    setName(list);
  };

  const handleTelecomChange = (e, field, countryCode) => {
    console.log('handleTelecomChange::', e, field);
    let tempTelecom = [...telecom];
    for (let i = 0; i < tempTelecom.length; i++) {
      if (e.target) {
        if (tempTelecom[i].system === e.target.name) {
          tempTelecom[i].value = e.target.value.replace(/[^\w@.\s]/gi, "");
          if (e.target.name === "mobile" || e.target.name === "phone")
          {
            tempTelecom[i].countryCode = countryCode.toUpperCase();
          }
        }
      } else {
        if (tempTelecom[i].system === field) {
          tempTelecom[i].value = e;
          if (field === "mobile" || field === "phone")
          {
            tempTelecom[i].countryCode = countryCode.toUpperCase();
          }
        }
      }
    }
    console.log('handleTelecomChange::', tempTelecom);
    setTelecom(tempTelecom);
  };

  const handleAddressChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...address];

    // if (name === "city") {
    //   if (value === "Other") {
    //     setCityBoolean(true);
    //     list[index][name] = "Other";
    //   } else {
    //     setCityBoolean(false);
    //   }
    // }

    list[index][name] = value;

    let cities = Object.entries(countriesList[0]);
    for (var x in cities) {
      let arr = cities[x];
      if (arr[0] === e.target.value) {
        setCities(arr[1].sort());
      }
    }
    setAddress(list);
  };

  const handleContactChange = (e, index) => {
    const { name, value } = e.target;

    const list = [...contact];
    if (name === "name") {
      if (/^[a-zA-Z' ]*$/.test(value) === false) {
        return;
      } else {
        list[index][name] = value.replace(/[^\w'\s]/gi, "");
      }
    } else {
      list[index][name] = value;
    }
    // list[index]['countryCode'] = "";
    setContact(list);
  };

  const handleContactTelecomChange = (e, index, countryCode) => {
    let list = [...contact];

    list[0].telecom[index].value = e;
    list[0].telecom[index].countryCode = countryCode;

    setContact(list);
  };

  const handleInsuranceEligibilityChange = (e, index) => {
    dispatch({
      field: e.target.name,
      value: e.target.value.replace(/[^\w.'-\s]/gi, ""),
    });

    if (e.target.value){
      // handleInsuranceEligibilityChange
    }
  };

  const handlePaymentModeChange = (e, index) => {
    const list = []; //...paymentMethod

    dispatch({ field: e.target.name, value: e.target.value });



    if (e.target.value === "Selfpay") {
      setinsuranceForm(false);
      setenableForm(true);
      setenableNext(true);
      dispatch({ field: "insuranceNumber", value: "" });
      dispatch({ field: "insuranceVendorId", value: null });
      dispatch({ field: "insuranceVendor", value: "" });
      dispatch({ field: "insureeName", value: "" });
      dispatch({ field: "coverageTerms", value: "" });
      dispatch({ field: "coPayment", value: "" });
      dispatch({ field: "coverageDetails", value: "" });
      dispatch({ field: "insuranceDetails", value: "" });
      dispatch({ field: "insuranceEligibility", value: "No" });

      const list = [...coveredFamilyMember];
      list[0]["typeOfRelation"] = "";
      setCoveredFamilyMember(list);
      setInsuranceCardUpload("");
      setInsuranceCard("");
      setInsuranceCardPreview("");
    } else if (e.target.value === "Insured") {
      setinsuranceForm(true);
      setenableForm(false);
      setenableNext(false);
    }
  };

  const handleCoveredFamilyMemChange = (e, index) => {
    const list = [...coveredFamilyMember];
    list[index][e.target.name] = e.target.value;
    setCoveredFamilyMember(list);
  };

  const onSlipUpload = (event) => {
    // event.preventDefault()
    console.log("event::", event.target, event.target.name, event.target.files);
    var file = event.target.files[0];
    var fileType = file.name.slice(file.name.length - 3);
    let file_size = event.target.files[0].size;

    if (event.target.name === "photo") {
      let obj = {};
      obj.contentType = fileType;
      obj.title = "Patient Image";
      obj.url = file.name;
      obj.size = file.size.toString();
      // obj.creation = file.lastModifiedDate.toISOString().substr(0, 10);
      setphoto([obj]);

      setSlipUpload(file);

      // setProgress(true)
      var reader = new FileReader();
      var url = reader.readAsDataURL(file);

      reader.onloadend = function() {
        if (file_size <= 1500000) {
          if (fileType === "png") {
            setImagePreview([reader.result]);
          } else if (fileType === "PNG") {
            setImagePreview([reader.result]);
          } else if (fileType === "peg") {
            setImagePreview([reader.result]);
          } else if (fileType === "PEG") {
            setImagePreview([reader.result]);
          } else if (fileType === "jpg") {
            setImagePreview([reader.result]);
          } else if (fileType === "JPG") {
            setImagePreview([reader.result]);
          } else {
            setErrorMsg("Only jpg, jpeg and png are be allowed");
            setOpenNotification(true);
          }
        } else {
          setErrorMsg("Files size should be less Than or Equal to 1.5MB");
          setOpenNotification(true);
        }
      };
    }
    if (event.target.name === "idCardFront") {
      setIdCardFront(file.name);
      setFrontUpload(file);
      var frontReader = new FileReader();
      var url = frontReader.readAsDataURL(file);

      frontReader.onloadend = function() {
        if (file_size <= 1500000) {
          if (fileType === "png") {
            setFrontPreview([frontReader.result]);
          } else if (fileType === "PNG") {
            setFrontPreview([frontReader.result]);
          } else if (fileType === "peg") {
            setFrontPreview([frontReader.result]);
          } else if (fileType === "PEG") {
            setFrontPreview([frontReader.result]);
          } else if (fileType === "jpg") {
            setFrontPreview([frontReader.result]);
          } else if (fileType === "JPG") {
            setFrontPreview([frontReader.result]);
          } else {
            setErrorMsg("Only jpg, jpeg and png are be allowed");
            setOpenNotification(true);
          }
        } else {
          setErrorMsg("Files size should be less Than or Equal to 1.5MB");
          setOpenNotification(true);
        }
      };
    }

    if (event.target.name === "idCardBack") {
      setIdCardBack(file.name);
      setBackUpload(file);
      var backReader = new FileReader();
      var url = backReader.readAsDataURL(file);

      backReader.onloadend = function() {
        if (file_size <= 1500000) {
          if (fileType === "png") {
            setBackPreview([backReader.result]);
          } else if (fileType === "PNG") {
            setBackPreview([backReader.result]);
          } else if (fileType === "peg") {
            setBackPreview([backReader.result]);
          } else if (fileType === "PEG") {
            setBackPreview([backReader.result]);
          } else if (fileType === "jpg") {
            setBackPreview([backReader.result]);
          } else if (fileType === "JPG") {
            setBackPreview([backReader.result]);
          } else {
            setErrorMsg("Only jpg, jpeg and png are be allowed");
            setOpenNotification(true);
          }
        } else {
          setErrorMsg("Files size should be less Than or Equal to 1.5MB");
          setOpenNotification(true);
        }
      };
    }

    if (event.target.name === "insuranceCard") {
      setInsuranceCard(file.name);
      setInsuranceCardUpload(file);
      var insuranceReader = new FileReader();
      var url = insuranceReader.readAsDataURL(file);

      insuranceReader.onloadend = function() {
        if (file_size <= 1500000) {
          if (fileType === "png") {
            setInsuranceCardPreview([insuranceReader.result]);
          } else if (fileType === "PNG") {
            setInsuranceCardPreview([insuranceReader.result]);
          } else if (fileType === "peg") {
            setInsuranceCardPreview([insuranceReader.result]);
          } else if (fileType === "PEG") {
            setInsuranceCardPreview([insuranceReader.result]);
          } else if (fileType === "jpg") {
            setInsuranceCardPreview([insuranceReader.result]);
          } else if (fileType === "JPG") {
            setInsuranceCardPreview([insuranceReader.result]);
          } else {
            setErrorMsg("Only jpg, jpeg and png are be allowed");
            setOpenNotification(true);
          }
        } else {
          setErrorMsg("Files size should be less Than or Equal to 1.5MB");
          setOpenNotification(true);
        }
      };
    }
  };

  const removeUploadedSlip = (e) => {
    if (e === "photo") {
      var fileType = slipUpload.name.slice(slipUpload.name.length - 3);
      setSlipUpload("");
      setphoto([]);

      if (fileType === "png") {
        setImagePreview("");
      } else if (fileType === "PNG") {
        setImagePreview("");
      } else if (fileType === "peg") {
        setImagePreview("");
      } else if (fileType === "PEG") {
        setImagePreview("");
      } else if (fileType === "jpg") {
        setImagePreview("");
      } else if (fileType === "JPG") {
        setImagePreview("");
      } else {
        setErrorMsg("Cannot remove file");
        setOpenNotification(true);
      }
    }
    if (e === "idCardFront") {
      var frontFileType = frontUpload.name.slice(frontUpload.name.length - 3);
      setFrontUpload("");
      setIdCardFront("");
      if (frontFileType === "png") {
        setFrontPreview("");
      } else if (frontFileType === "PNG") {
        setFrontPreview("");
      } else if (frontFileType === "peg") {
        setFrontPreview("");
      } else if (frontFileType === "PEG") {
        setFrontPreview("");
      } else if (frontFileType === "jpg") {
        setFrontPreview("");
      } else if (frontFileType === "JPG") {
        setFrontPreview("");
      } else {
        setErrorMsg("Cannot remove file");
        setOpenNotification(true);
      }
    }
    if (e === "idCardBack") {
      var backFileType = backUpload.name.slice(backUpload.name.length - 3);
      setBackUpload("");
      setIdCardBack("");
      if (backFileType === "png") {
        setBackPreview("");
      } else if (backFileType === "PNG") {
        setBackPreview("");
      } else if (backFileType === "peg") {
        setBackPreview("");
      } else if (backFileType === "PEG") {
        setBackPreview("");
      } else if (backFileType === "jpg") {
        setBackPreview("");
      } else if (backFileType === "JPG") {
        setBackPreview("");
      } else {
        setErrorMsg("Cannot remove file");
        setOpenNotification(true);
      }
    }
    if (e === "insuranceCard") {
      var insuranceCardFileType = insuranceCardUpload.name.slice(
        insuranceCardUpload.name.length - 3
      );
      setInsuranceCardUpload("");
      setInsuranceCard("");
      if (insuranceCardFileType === "png") {
        setInsuranceCardPreview("");
      } else if (insuranceCardFileType === "PNG") {
        setInsuranceCardPreview("");
      } else if (insuranceCardFileType === "peg") {
        setInsuranceCardPreview("");
      } else if (insuranceCardFileType === "PEG") {
        setInsuranceCardPreview("");
      } else if (insuranceCardFileType === "jpg") {
        setInsuranceCardPreview("");
      } else if (insuranceCardFileType === "JPG") {
        setInsuranceCardPreview("");
      } else {
        setErrorMsg("Cannot remove file");
        setOpenNotification(true);
      }
    }
  };

  function validatePatientJohnDoeForm() {
    console.log('johnDoeCheck :: johnDoe', isJohnDoe);
    console.log('johnDoeCheck :: validation', name[0].given + " && " + //John
      name[0].given[0].length + " > 0 && " + // 4 > 0
      validateFirstName(name[0].given)+ " && " + // true
      name[0].family+ " && " + // Doe
      name[0].family.length+ " > 0 && " + // 17 > 0
      validateLastName(name[0].family)+ " && " + // true
      gender+ " && " + // Male
      gender.length + " > 0 && " + // 4 > 0
      validateGender(gender)
      // + // true
      // name[0].prefix + " && " + // mr
      // name[0].prefix.length + " > 0 && " + // 2 > 0
      // validateGender(name[0].prefix) // true
    );

    return (
      name[0].given &&
      name[0].given[0].length > 0 &&
      validateFirstName(name[0].given) &&
      name[0].family &&
      name[0].family.length > 0 &&
      validateLastName(name[0].family) &&
      gender &&
      gender.length > 0 &&
      validateGender(gender) //&&
      // name[0].prefix &&
      // name[0].prefix.length > 0 &&
      // validateGender(name[0].prefix)
    );
  }

  function validatePatientForm() {

    console.log(
      "handleTelecomChange::",
      nationalID + "&& " +
      nationalID.length + " > 0 && " +
      validateNationalId(nationalID) + " && " +
      name[0].given + " && " +
      name[0].given[0].length + " > 0 && " +
      validateFirstName(name[0].given) + " && " +
      name[0].family + " && " +
      name[0].family.length + " > 0 && " +
      validateLastName(name[0].family) + " && " +
      gender + " && " +
      gender.length + " > 0 && " +
      validateGender(gender) + " && " +
      validateEmail(telecomValue("email")) + " && " +
      telecomValue("mobile") + " && " +
      telecomValue("mobile").length + " > 0 && " +
      !validatePhone2(telecomValue("mobile"), telecomCountryCode("mobile")) + " && " +
      address[0].country + " && " +
      address[0].country.length + " > 0 && " +
      validateCountryCity(address[0].country) + " && " +
      address[0].city + " && " +
      address[0].city.length + " > 0 && " +
      validateCountryCity(address[0].city) + " && " +
      address[0].text + " && " +
      address[0].text.length + " > 0 && " +
      validateAddress(address[0].text)
    );


    return (
      nationalID &&
      nationalID.length > 0 &&
      validateNationalId(nationalID) &&
      name[0].given &&
      name[0].given[0].length > 0 &&
      validateFirstName(name[0].given) &&
      name[0].family &&
      name[0].family.length > 0 &&
      validateLastName(name[0].family) &&
      gender &&
      gender.length > 0 &&
      validateGender(gender) &&
      validateEmail(telecomValue("email")) &&
      telecomValue("mobile") &&
      telecomValue("mobile").length > 0 &&
      !validatePhone2(telecomValue("mobile"), telecomCountryCode("mobile")) && 
      address[0].country &&
      address[0].country.length > 0 &&
      validateCountryCity(address[0].country) &&
      address[0].city &&
      address[0].city.length > 0 &&
      validateCountryCity(address[0].city) &&
      address[0].text &&
      address[0].text.length > 0 &&
      validateAddress(address[0].text)
    );
  }

  // console.log("validate mobile no::", validatePhone(telecomValue("mobile")));

  function validateEmergencyForm() {

    if (emergencyFormUndisclosed === true){
      return true;
    }else if ( emergencyFormUndisclosed === false && (contact[0].name && contact[0].name.length > 0) || (contact[0].typeOfRelation && contact[0].typeOfRelation.length > 0) ) {
      return (
        contact[0].name &&
        contact[0].name.length > 0 &&
        validateEmergencyName(contact[0].name) &&
        contact[0].typeOfRelation &&
        contact[0].typeOfRelation.length > 0 &&
        validateRelation(contact[0].typeOfRelation) &&
        contact[0].telecom[0].value.length > 5

      );
    }else{
      return false;
    }
  }

  function validateSelfpayForm() {
    if (paymentMode === "Selfpay") {
      return true;
    }
  }

  function validateInsuranceForm() {
    console.log("mDebug::paumentMode:", paymentMode);
    if (paymentMode === "Insured") {

      console.log(
        "mDebug:: " + 
        insuranceNumber + " && " + 
        insuranceNumber.length + " > 0 && " + 
        validateTextField(insuranceNumber) + "&& " + 

        // Insurance Number
        insureeName + " && " + 
        insureeName.length + " > 0 && " + 
        validateTextField(insureeName) + " && " + 

        // Policy Number
        insurancePolicyNumber + " && " + 
        insurancePolicyNumber.length + " > 0 && " + 
        validateTextField(insurancePolicyNumber) + " && " + 

        // Network Plan
        insurancePlanNetwork + " && " + 
        insurancePlanNetwork.length + " > 0 && " + 
        validateTextField(insurancePlanNetwork)
      )


      return (
        // Insurance Number
        insuranceNumber &&
        insuranceNumber.length > 0 &&
        validateTextField(insuranceNumber) && 

        // Insurance Number
        insureeName &&
        insureeName.length > 0 &&
        validateTextField(insureeName) && 

        // Policy Number
        insurancePolicyNumber &&
        insurancePolicyNumber.length > 0 &&
        validateTextField(insurancePolicyNumber) && 

        // Network Plan
        insurancePlanNetwork &&
        insurancePlanNetwork.length > 0 &&
        validateTextField(insurancePlanNetwork)

        // Effective From
        // insuranceFrom &&

        // Effective To
        // insuranceTo &&



        // &&
        // insuranceVendor &&
        // insuranceVendor.length > 0 &&
        // validateInsuranceVendor(insuranceVendor)
      );
    }
  }

  const handleChangeDate = (value) => {
    dispatch({ field: "birthDate", value: value.toISOString().substr(0, 10) });
    calculate_age(value.toISOString().substr(0, 10));
  };

  const handleChangeDates = (value, field) => {
    dispatch({ field: field, value: value.toISOString().substr(0, 10) });
  };

  const handleAdd = () => {
    let formData = new FormData();
    if (slipUpload) {
      formData.append("file", slipUpload, slipUpload.name);
    }
    if (frontUpload) {
      formData.append("front", frontUpload, frontUpload.name);
    }
    if (backUpload) {
      formData.append("back", backUpload, backUpload.name);
    }
    if (insuranceCardUpload) {
      formData.append(
        "insuranceCard",
        insuranceCardUpload,
        insuranceCardUpload.name
      );
    }

    const temp = [...time];
    temp[0].processEndTime = new Date();
    temp[0].processName = "Registration Officer";
    temp[0].staffId = currentUser._id;
    setTime(temp);

    let validateValue;

    if( isJohnDoe === true ){
      console.log("mDebug::validatePatientJohnDoeForm:", validatePatientJohnDoeForm());
      console.log("mDebug::insuranceForm:", insuranceForm);
      console.log("mDebug::validateInsuranceForm:", validateInsuranceForm());
      validateValue = validatePatientJohnDoeForm() && (insuranceForm===true?validateInsuranceForm():true);
      console.log("mDebug::validateValue", validateValue);
    }else{
      validateValue = validatePatientForm() &&
        (validateSelfpayForm() || validateInsuranceForm()) &&
        (emergencyFormUndisclosed === false ? validateEmergencyForm():true);
    }
    console.log(
      'Registration::Status::Validation::', 'validatePatientForm', 
      validatePatientForm(), (validateSelfpayForm() || validateInsuranceForm()), 
      'emergencyFormUndisclosed', emergencyFormUndisclosed, 
      'validateEmergencyForm', validateEmergencyForm(),
      'validateEmergencyFormF', validateEmergencyForm()
    );
    console.log('Registration::Status::Validation::', (emergencyFormUndisclosed === false ? validateEmergencyForm():true));

    if ( validateValue ) {
      const params = {
        isJohnDoe,
        isChild,
        identityType,
        nationalID,
        name,
        gender,
        birthDate,
        age,
        telecom,
        nationality,
        address,
        blood,
        otherDetails,
        photo,
        idCardFront,
        idCardBack,
        emergencyFormUndisclosed,
        contact,
        paymentMode,
        insureeName,
        insuranceNumber,

        // New Insurance Field 22-06-2022
        insurancePolicyNumber,
        insurancePlanNetwork,
        insuranceFrom,
        insuranceTo,

        insuranceVendorId,
        insuranceVendor,
        coverageTerms,
        coPayment,
        coveredFamilyMember,
        coverageDetails,
        insuranceCard,
        insuranceDetails,
        insuranceEligibility,
        
        // Insurance Dialog Fields
        insuranceItemsInfo,

        time,
        registrationStatus,
      };
      formData.append("data", JSON.stringify(params));
      console.log('PatientRegistrationMsg', params);
      
      axios
        .post(patientRegistration, formData, {
          headers: {
            accept: "application/json",
            "Accept-Language": "en-US,en;q=0.8",
            "content-type": "multipart/form-data",
          },
        })
        .then((res) => {
          if (res.data.success) {
            setPatientId(res.data.data._id);
            setIsPatientSubmitted(true);
            setOpenNotification(true);
            // setsuccessMsg(
            //   'Patient details saved successfully, Generate IPR/EDR now'
            // )

            let QR;
            if (uploadsUrl.includes("/uploads")) {
              let q = res.data.data.QR.split("uploads")[1];
              QR = `${uploadsUrl}${q}`;
            } else {
              let q = res.data.data.QR.split("/uploads")[1];
              QR = `${uploadsUrl}uploads${q}`;
            }

            if (assignMode){
              // Assigning edrId to mrn
              axios
              .post(AssignEdrToPatient, {
                'edrId': edrId,
                'patientId': res.data.data._id
              })
              .then((res) => {
                if (res.data.success) {
                  console.log("res::", res);
                  props.history.push({
                    pathname: "/dashboard/home/pendingepisode/success",
                    state: {
                      code: `${res.data.message}`,
                      edrId: edrId,
                      selectedItem: res.data.data
                    },
                  });
                }
              })
              .catch((e) => {
                console.log("error after searching patient request", e);
              });
            }else{
              props.history.push({
                pathname: "success",
                state: {
                  message1: `Details of Patient with `,
                  code: `MRN: ${res.data.data.identifier[0].value.toUpperCase()}`,
                  message2: ` Saved Successfully`,
                  qr: QR,
                },
              });
            }
          } else if (!res.data.success) {
            setOpenNotification(true);
            setErrorMsg("There is some error in the API Endpoint.");
          }
        })
        .catch((e) => {
          setOpenNotification(true);
          setErrorMsg("Error while adding patient details");
        });
    } else {
      setOpenNotification(true);
      setErrorMsg(
        "Please fill up the empty fields with a valid data."
      );
    }
    setDetailsForm(true);
    setEmergencyForm(true);
    if (paymentMode === "Selfpay") {
      setPaymentForm(true);
    } else if (paymentMode === "Insured") {
      setinsuranceForm(true);
    }
    // setIsFormSubmitted(true)
  };

  const handleEdit = () => {
    let formData = new FormData();
    if (slipUpload) {
      formData.append("file", slipUpload, slipUpload.name);
    }
    if (frontUpload) {
      formData.append("front", frontUpload, frontUpload.name);
    }
    if (backUpload) {
      formData.append("back", backUpload, backUpload.name);
    }
    if (insuranceCardUpload) {
      formData.append(
        "insuranceCard",
        insuranceCardUpload,
        insuranceCardUpload.name
      );
    }

    const temp = [...time];
    temp[0].processEndTime = new Date();
    temp[0].processName = "Registration Officer";
    temp[0].staffId = currentUser._id;
    setTime(temp);

    let validateValue;

    if( isJohnDoe === true ){
      validateValue = validatePatientJohnDoeForm();
    }else{
      validateValue = validatePatientForm() &&
        (validateSelfpayForm() || validateInsuranceForm()) &&
        validateEmergencyForm();
    }

    if ( validateValue ) {
      const params = {
        _id: patientId,
        isJohnDoe,
        identityType,
        nationalID,
        name,
        gender,
        birthDate,
        age,
        telecom,
        nationality,
        address,
        blood,
        otherDetails,
        photo,
        idCardFront,
        idCardBack,
        emergencyFormUndisclosed,
        contact,
        paymentMode,
        insureeName,
        insuranceNumber,

        // New Insurance Field 22-06-2022
        insurancePolicyNumber,
        insurancePlanNetwork,
        insuranceFrom,
        insuranceTo,


        insuranceVendorId,
        insuranceVendor,
        coverageTerms,
        coPayment,
        coveredFamilyMember,
        insuranceDetails,
        insuranceEligibility,
        coverageDetails,
        insuranceCard,

        // Insurance Dialog Fields
        insuranceItemsInfo,

        time,
        registrationStatus: "completed",
      };
      formData.append("data", JSON.stringify(params));
      axios
        .put(updatePatientUrl, formData)
        .then((res) => {
          if (res.data.success) {
            setPatientId(res.data.data._id);
            setMRN(res.data.data.identifier);
            setInsuranceItemsInfo(res.data.data.insuranceItemsInfo);
            // setOpenNotification(true)
            // setsuccessMsg('Patient Updated Successfully')

            let QR;
            if (uploadsUrl.includes("/uploads")) {
              let q = res.data.data.QR.split("uploads")[1];
              QR = `${uploadsUrl}${q}`;
            } else {
              let q = res.data.data.QR.split("/uploads")[1];
              QR = `${uploadsUrl}uploads${q}`;
            }
            console.log('assignMode::', assignMode);
            if (assignMode){
              // Assigning edrId to mrn
              axios
              .post(AssignEdrToPatient, {
                'edrId': edrId,
                'patientId': res.data.data._id
              })
              .then((res) => {
                if (res.data.success) {
                  console.log("res::", res);
                  props.history.push({
                    pathname: "/dashboard/home/pendingepisode/success",
                    state: {
                      code: `${res.data.message}`,
                      edrId: edrId,
                      selectedItem: res.data.data
                    },
                  });
                }
              })
              .catch((e) => {
                console.log("error after searching patient request", e);
              });
            }else{

              props.history.push({
                pathname: "success",
                state: {
                  message1: `Details of Patient with `,
                  code: `MRN: ${res.data.data.identifier[0].value.toUpperCase()}`,
                  message2: ` Updated Successfully`,
                  qr: QR,
                },
              });
            }
          } else if (!res.data.success) {
            setOpenNotification(true);
            setErrorMsg("Error");
          }
        })
        .catch((e) => {
          setOpenNotification(true);
          setErrorMsg("Patient with same MRN already exists");
        });
    } else {
      setOpenNotification(true);
      setErrorMsg(
        "Please Fill the the empty fields with valid data / Please add payment method"
      );
    }
    setDetailsForm(true);
    setEmergencyForm(true);
    if (paymentMode === "Selfpay") {
      setPaymentForm(true);
    } else if (paymentMode === "Insured") {
      setinsuranceForm(true);
    }
    // setIsFormSubmitted(true)
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const DetailsOnClick = () => {
    console.log('key', isJohnDoe === true ? 'validatePatientJohnDoeForm':'validatePatientForm');
    if ( 
      (
        isJohnDoe === true ? validatePatientJohnDoeForm():validatePatientForm()
      ) 
    ) {
      setValue(value + 1);
    } else {
      setErrorMsg("Please Check the form for errors");
      setOpenNotification(true);
    }
    setDetailsForm(true);
  };

  const onClick = () => {
    setValue(value + 1);
  };

  // console.log("countries::", countries, countriesList, cities);

  const EmergencyOnClick = () => {
    if (
      (
        isJohnDoe === true ? true:validateEmergencyForm()
      ) 
    ) {
      setValue(value + 1);
    } else {
      setErrorMsg("Please Check the form for errors");
      setOpenNotification(true);
    }
    setEmergencyForm(true);
  };

  const onTabNavigation = () => {
    value === 1
      ? setValue(0)
      : value === 2
      ? setValue(1)
      : props.history.goBack();
    // setValue(tabIndex);
  };

  const handleInsuranceDialogOpen = () => {
    setInsuranceDialogOpen(true);
  };

  const handleInsuranceDialogClose = () => {
    setInsuranceDialogOpen(false);
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      triggerChange();
    }
  };

  const triggerChange = (a) => {
    handleSearch(a);
  };

  const handlePauseSearch = (e) => {
    setLoadSearchedData(true);
    clearTimeout(timer);

    const a = e.target.value.replace(/[^\w+\s]/gi, "");
    setSearchQuery(a);

    setTimer(
      setTimeout(() => {
        triggerChange(a);
      }, 600)
    );
  };

  const handleSearch = (e) => {
    console.log('PatientRegistration::Oops::', e)
    if (e !== undefined && e.length >= 3) {
      axios
        .get(SearchInRegistration + "/?searchIn=" + e)
        .then((res) => {
          if (res.data.success) {
            if (res.data.data.length > 0) {
              setItemFoundSuccessfully(true);
              setItemFound(res.data.data);
              // setLoadSearchedData(false)
            } else {
              setItemFoundSuccessfully(false);
              setItemFound("");
              setLoadSearchedData(false);
            }
          }
        })
        .catch((e) => {
          console.log("error while searching patient", e);
        });
    }
  };

  function handleAddItem(item) {
    axios
      .get(getPatientByIdUrl + "/" + item._id)
      .then((res) => {
        if (res.data.success) {
          let i = res.data.data;

          setPatientId(i._id);
          setName(i.name);
          dispatch({ field: "identifier", value: i.identifier });
          dispatch({ field: "gender", value: i.gender });
          dispatch({ field: "age", value: i.age });
          dispatch({ field: "birthDate", value: i.birthDate });
          dispatch({ field: "nationalID", value: i.nationalID });
          dispatch({ field: "blood", value: i.blood });
          dispatch({ field: "nationality", value: i.nationality });
          dispatch({ field: "otherDetails", value: i.otherDetails });
          dispatch({
            field: "registrationStatus",
            value: i.registrationStatus,
          });
          setphoto(i.photo);
          setIdCardBack(i.idCardBack ? i.idCardBack : "");
          setIdCardFront(i.idCardFront ? i.idCardFront : "");
          let cities = Object.entries(countriesList[0]);
          for (var x in cities) {
            let arr = cities[x];
            if (arr[0] === i.address[0].country) {
              setCities(arr[1]);
            }
          }
          setAddress(i.address);
          setTelecom(i.telecom);
          setContact(i.contact);
          ////////// setpaymentMethod(i.paymentMethod);

          dispatch({ field: "insuranceVendorId", value: i.insuranceVendorId })
          dispatch({ field: "insuranceNumber", value: i.insuranceNumber });
          dispatch({ field: "insuranceVendor", value: i.insuranceVendor });
          dispatch({ field: "insureeName", value: i.insureeName });
          dispatch({ field: "coverageTerms", value: i.coverageTerms });
          dispatch({ field: "coPayment", value: i.coPayment });
          dispatch({ field: "coverageDetails", value: i.coverageDetails });
          dispatch({ field: "emergencyFormUndisclosed", value: i.emergencyFormUndisclosed });
          dispatch({
            field: "insuranceDetails",
            value: i.insuranceDetails,
          });
          dispatch({
            field: "insuranceEligibility",
            value: i.insuranceEligibility,
          });
          setCoveredFamilyMember(i.coveredFamilyMember);
          setInsuranceCard(i.insuranceCard ? i.insuranceCard : "");

          if (i.paymentMode === "Insured") {
            setenableForm(false);
            setinsuranceForm(false);
            setenableNext(false);
          }
          if (i.paymentMode === "Selfpay") {
            setenableForm(true);
            setenableNext(true);
          }
          setSearchQuery("");
          setsearchActivated(true);
        }
      })
      .catch((e) => {
        setOpenNotification(true);
        setErrorMsg("Error while fetching details of patient");
      });
  }

  const onChangeBloodGroup = (e) => {
    dispatch({
      field: e.target.name,
      value: e.target.value,
    });
  };

  const onChangeIsChildValue = (e) => {
    dispatch({
      field: e.target.name,
      value: e.target.checked,
    });
    console.log({
      field: e.target.name,
      value: e.target.checked,
    })
  };

  const onChangeFieldData = (e) => {
    dispatch({ field: e.target.name, value: e.target.value });
  }  

  const onChangeValue = (e) => {
    if (e.target.name === "isJohnDoe"){
      if (e.target.value === "true"){
        const list = [...name];
        list[0]['given'] = ["John"];
        list[0]['family'] = "Doe";// + Date.now()
        setName(list);
        dispatch({
          field: "isJohnDoe",
          value: true,
        });
      }else{
        dispatch({
          field: "isJohnDoe",
          value: false,
        });
      }
    }else{
      /*
      var heightWeightPattern = /^[0-9. ]*$/;
      if (e.target.name === "height" || e.target.name === "weight") {
        if (heightWeightPattern.test(e.target.value) === false) {
          return;
        }
      }
      */
      if (e.target.name === "birthDate") {
        dispatch({
          field: e.target.name,
          value: e.target.value,
        });
      } else if (e.target.name === "insuranceVendor") {
        if (/^[a-zA-Z' ]*$/.test(e.target.value) === false) {
          return;
        } else {
          dispatch({
            field: e.target.name,
            value: e.target.value.replace(/[^\w'\s]/gi, ""),
          });
        }
      } else {
        dispatch({
          field: e.target.name,
          value: e.target.value.replace(/[^\w.'-\s]/gi, ""),
        });
      }
      if (e.target.name === "coverageTerms" && e.target.value === "Co Payment") {
        setCoPaymentField(true);
      }
      if (
        e.target.name === "coverageTerms" &&
        e.target.value === "Full Payment"
      ) {
        dispatch({ field: "coPayment", value: "" });
        setCoPaymentField(false);
      }

      if (e.target.name === "birthDate") {
        calculate_age(e.target.value);
      }
    }
  };

  const calculate_age = (birthDate) => {
    var today = new Date();
    var birthDate = new Date(birthDate);
    var age_now = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age_now--;
    }
    dispatch({ field: "age", value: age_now });
  };

  if (openNotification) {
    setTimeout(() => {
      setOpenNotification(false);
      setErrorMsg("");
      setsuccessMsg("");
    }, 2000);
  }

  function telecomValue(toBeDoneFor) {
    for (let i = 0; i < telecom.length; i++) {
      let v = telecom[i];
      if (v.system === toBeDoneFor) {
        if (v.value !== "") {
          return v.value;
        } else {
          return "";
        }
      }
    }
  }

  function telecomCountryCode(toBeDoneFor) {
    for (let i = 0; i < telecom.length; i++) {
      let v = telecom[i];
      if (v.system === toBeDoneFor) {
        if (v.countryCode !== "") {
          return v.countryCode;
        } else {
          return "";
        }
      }
    }
  }

  function scanQRCode() {
    setQRCodeScanner((pervState) => !pervState);
  }

  function handleScanQR(data) {
    setQRCodeScanner(false);
    console.log(JSON.parse(data));
    if (JSON.parse(data).profileNo) {
      handlePauseSearch({
        target: {
          value: JSON.parse(data).profileNo,
          type: "text",
        },
      });
    } else if (JSON.parse(data).insuranceCardNumber) {
      dispatch({
        field: "insuranceNo",
        value: JSON.parse(data).insuranceCardNumber,
      });
    }
  }

  if (QRCodeScanner) {
    return (
      <div>
        {QRCodeScanner ? (
          <QRCodeScannerComponent
            handleScanQR={handleScanQR}
            scanQRCode={scanQRCode}
          />
        ) : (
          undefined
        )}
      </div>
    );
  }

  return (
    <div
      style={{
        backgroundColor: "rgb(19 213 159)",
        position: "fixed",
        display: "flex",
        width: "100%",
        height: "100%",
        flexDirection: "column",
        flex: 1,
        overflowY: "scroll",
        paddingBottom: 30,
      }}
    >
      <Header history={props.history} />
      <div className="cPadding">
        <div className="subheader" style={{ marginLeft: "-10px" }}>
          <div>
            <img src={patientRegister} />
            <div style={{ flex: 4, display: "flex", alignItems: "center" }}>
              <h4 style={{ color: "white", fontWeight: "700" }}>
                {assignMode?
                  episodeNumber+"'s Registration":
                  (
                    comingFor === "add"
                  ? "Patient Registration": // RO Patient Registration
                    name[0].given[0]+"'s Registration (Edit)"
                  )
                }
              </h4>
            </div>
          </div>
          <div style={{ marginRight: "-5px", width: "auto" }}>
            <ButtonField
              onClick={() =>
                props.history.push("/dashboard/home/completedregistration")
              }
              name="viewAll"
            />
          </div>
        </div>

        <div style={{ width: "auto", height: "20px" }} />
        <div className={classesForTabs.root}>
          <Tabs
            classes={{
              root: classesForTabs.root,
              scroller: classesForTabs.scroller,
            }}
            value={value}
            onChange={handleChange}
            textColor="primary"
            variant="scrollable"
            TabIndicatorProps={{ style: { background: "#12387a" } }}
            // centered
          >
            <Tab
              style={{
                color: "white",
                borderRadius: 5,
                outline: "none",
                color: value === 0 ? "#12387a" : "#3B988C",
              }}
              label="Patient Details"
            />
            <Tab
              style={{
                color: "white",
                borderRadius: 5,
                outline: "none",
                color: value === 1 ? "#12387a" : "#3B988C",
              }}
              label="Emergency Contact"
            />
            <Tab
              style={{
                color: "white",
                borderRadius: 5,
                outline: "none",
                color: value === 2 ? "#12387a" : "#3B988C",
              }}
              label="Payment Method"
            />
            <Tab
              style={{
                color: "white",
                borderRadius: 5,
                outline: "none",
                color: value === 3 ? "#12387a" : "#3B988C",
              }}
              label="Insurance Details"
              disabled={enableForm}
            />
          </Tabs>
        </div>
        <div style={{ width: "auto", height: "20px" }} />
        {value === 0 ? (
          <div
            style={{ flex: 4, display: "flex", flexDirection: "column" }}
            className={`${"container-fluid"} ${classes.root}`}
          >

            <div
              className="row row-5"
              style={{
                marginBottom: 16,
                marginTop: 10,
              }}
            >
              <div
                className="col-md-10 col-sm-9 col-8"
                style={{
                  ...styles.textFieldPadding,
                  paddingRight: matches ? 5 : 0,
                }}
              >
                <TextField
                  type="text"
                  label={
                    matches
                      ? "Search Patient by Name / MRN / National ID / Passport # / Mobile Number / Email Address"
                      : "Search Patient"
                  }
                  name={"searchQuery"}
                  value={searchQuery}
                  onChange={handlePauseSearch}
                  onKeyDown={handleKeyDown}
                  className="textInputStyle"
                  variant="filled"
                  InputLabelProps={{
                    classes: {
                      root: classes.label,
                      focused: classes.focusedLabel,
                      error: classes.erroredLabel,
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <AccountCircle />
                      </InputAdornment>
                    ),

                    className: classes.input,
                    classes: { input: classes.input },
                  }}
                  // InputLabelProps={{
                  //   className: classes.label,
                  //   classes: { label: classes.label },
                  // }}
                />
              </div>

              <div
                className="col-md-1 col-sm-2 col-2"
                style={{
                  ...styles.textFieldPadding,
                  // paddingLeft: matches ? 5 : 2,
                  paddingRight: matches ? 5 : 3,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: 55,
                    backgroundColor: "white",
                    borderRadius: 5,
                    // width: 100,
                  }}
                >
                  <img
                    src={BarCode}
                    onClick={scanQRCode}
                    style={{
                      width: matches ? 70 : 50,
                      height: matches ? 60 : 55,
                    }}
                  />
                </div>
              </div>

              <div
                className="col-md-1 col-sm-2 col-2"
                style={{
                  ...styles.textFieldPadding,
                  paddingLeft: matches ? 5 : 2,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "white",
                    borderRadius: 5,
                    height: 55,
                  }}
                >
                  <img
                    src={Fingerprint}
                    style={{ maxWidth: 43, height: 43 }}
                  />
                </div>
              </div>
            </div>

            <div className="row row-5">
              <div
                className="col-md-10 col-sm-11 col-10"
                style={{
                  ...styles.textFieldPadding,
                }}
              >
                {searchQuery ? (
                  <div
                    style={{
                      zIndex: 3,
                      position: "absolute",
                      width: "99%",
                    }}
                  >
                    <Paper style={{ maxHeight: 300, overflow: "auto" }}>
                      {itemFoundSuccessfull && itemFound !== "" ? (
                        <Table size="small">
                          <TableHead>
                            <TableRow>
                              <TableCell>MRN</TableCell>
                              <TableCell>Patient Name</TableCell>
                              <TableCell>Gender</TableCell>
                              <TableCell>Age</TableCell>
                            </TableRow>
                          </TableHead>

                          <TableBody>
                            {itemFound.map((i) => {
                              return (
                                <TableRow
                                  key={i._id}
                                  onClick={() => handleAddItem(i)}
                                  style={{ cursor: "pointer" }}
                                >
                                  <TableCell>
                                    {i.identifier[0].value}
                                  </TableCell>
                                  <TableCell>
                                    {i.name[0].given[0] +
                                      " " +
                                      i.name[0].family}
                                  </TableCell>
                                  <TableCell>{i.gender}</TableCell>
                                  <TableCell>{i.age}</TableCell>
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      ) : loadSearchedData ? (
                        <div style={{ textAlign: "center" }}>
                          <Loader
                            type="TailSpin"
                            color="#2c6ddd"
                            height={25}
                            width={25}
                            style={{
                              display: "inline-block",
                              padding: "10px",
                            }}
                          />
                          <span
                            style={{
                              display: "inline-block",
                              padding: "10px",
                            }}
                          >
                            <h4> Searching Patient...</h4>
                          </span>
                        </div>
                      ) : searchQuery && !itemFoundSuccessfull ? (
                        <div
                          style={{ textAlign: "center", padding: "10px" }}
                        >
                          <h4>No Patient Found !</h4>
                        </div>
                      ) : (
                        undefined
                      )}
                    </Paper>
                  </div>
                ) : (
                  undefined
                )}
              </div>
            </div>

            <div className="row row-5">
              <div className="col-md-6 col-lg-3 col-sm-3">
                <div className="form-group">
                  <TextField
                    required
                    label="Patient MRN (Auto Generated)"
                    name={"identifier"}
                    value={identifier[0].value}
                    disabled={true}
                    onChange={onChangeValue}
                    className="textInputStyle"
                    variant="filled"
                    // error={identifier === '' && isFormSubmitted}
                    InputProps={{
                      className: classes.input,
                      classes: { input: classes.input },
                    }}
                    InputLabelProps={{
                      className: classes.label,
                      classes: { label: classes.label },
                    }}
                  />
                  {/* <ErrorMessage
                    name={identifier}
                    isFormSubmitted={isFormSubmitted}
                  /> */}
                </div>
              </div>
              <div className="col-md-6 col-lg-2 col-sm-2 col-6 d-none">
                <div className="form-group">
                  <TextField
                    select
                    fullWidth
                    id="isJohnDoe"
                    name="isJohnDoe"
                    value={isJohnDoe}
                    onChange={onChangeValue}
                    label="John Doe Registration?"
                    variant="filled"
                    className="dropDownStyle"
                    InputProps={{
                      className: classes.input,
                      classes: { input: classes.input },
                    }}
                  >
                    <MenuItem key="true" value="true">Yes</MenuItem>
                    <MenuItem key="false" value="false">No</MenuItem>
                  </TextField>
                </div>
              </div>
              <div className="col-md-6 col-lg-3 col-sm-2 col-6">
                <div className="form-group">
                  <TextField
                    select
                    fullWidth
                    id="identityType"
                    name="identityType"
                    value={identityType}
                    onChange={onChangeValue}
                    label="ID Document"
                    variant="filled"
                    className="dropDownStyle"
                    InputProps={{
                      className: classes.input,
                      classes: { input: classes.input },
                    }}
                    disabled={ isJohnDoe }
                  >
                    <MenuItem key="national-id" value="national-id">National ID</MenuItem>
                    <MenuItem key="passport" value="passport">Passport</MenuItem>
                    <MenuItem key="driving-license" value="driving-license">Driving License</MenuItem>
                    <MenuItem key="refugee" value="refugee">Refugee #</MenuItem>
                  </TextField>
                </div>
              </div>
              <div className="col-md-6 col-lg-6 col-sm-5">
                <div className="form-group">
                  <TextField
                    required
                    label={
                        identityType=="passport"?
                          "Passport Number" : (
                            identityType=="national-id" ? 
                              "National ID Number" : 
                              identityType=="driving-license" ? 
                              "Driving License Number" :
                                identityType=="refugee" ? 
                                  "Refugee #" :
                                  "ID Document Number"
                          )}
                    name={"nationalID"} // now Identity
                    value={nationalID}
                    error={nationalID === "" && detailsForm && isJohnDoe === false}
                    onChange={onChangeValue}
                    className="textInputStyle"
                    variant="filled"
                    InputProps={{
                      className: classes.input,
                      classes: { input: classes.input },
                    }}
                    disabled={ isJohnDoe }
                  />
                  <ErrorMessage
                    name={nationalID}
                    type="nationalId"
                    isFormSubmitted={detailsForm && isJohnDoe === false}
                  />
                </div>
              </div>
            </div>
            {name.map((x, i) => {
              return (
                <div className="row row-5">
                  <div className="col-md-2 col-4">
                    <div className="form-group">
                      <TextField
                        // required
                        select
                        fullWidth
                        // id='title'
                        // name='title'
                        // value={title}
                        name="prefix"
                        label="Prefix"
                        value={x.prefix}
                        // error={title === '' && detailsForm}
                        // onChange={onChangeValue}
                        onChange={(e) => handleNameChange(e, i)}
                        label="Title"
                        variant="filled"
                        className="textInputStyle"
                        InputProps={{
                          className: classes.input,
                          classes: { input: classes.input },
                        }}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>

                        {titles.map((val) => {
                          return (
                            <MenuItem key={val.key} value={val.key}>
                              {val.value}
                            </MenuItem>
                          );
                        })}
                      </TextField>
                      {/* <ErrorMessage name={title} isFormSubmitted={detailsForm} /> */}
                    </div>
                  </div>
                  <div className="col-md-5 col-8">
                    <div className="form-group">
                      <TextField
                        required
                        label="First Name"
                        name={"given"}
                        value={name[i].given[0]}
                        onChange={(e) => handleNameChange(e, i)}
                        error={name[i].given[0] === "" && detailsForm}
                        className="textInputStyle"
                        variant="filled"
                        InputProps={{
                          className: classes.input,
                          classes: { input: classes.input },
                        }}
                        inputProps={{ maxLength: 40 }}
                      />
                      <ErrorMessage
                        name={name[i].given[0]}
                        type="firstName"
                        isFormSubmitted={detailsForm}
                      />
                    </div>
                  </div>
                  <div className="col-md-5 col-12">
                    <div className="form-group">
                      <TextField
                        required
                        label="Last Name"
                        name={"family"}
                        value={x.family}
                        onChange={(e) => handleNameChange(e, i)}
                        error={x.family === "" && detailsForm}
                        className="textInputStyle"
                        variant="filled"
                        InputProps={{
                          className: classes.input,
                          classes: { input: classes.input },
                        }}
                        inputProps={{ maxLength: 40 }}
                      />
                      <ErrorMessage
                        name={x.family}
                        type="lastName"
                        isFormSubmitted={detailsForm}
                      />
                    </div>
                  </div>
                </div>
              );
            })}

            <div className="row row-5">
              <div className="col-md-3 col-lg-2 col-sm-2 col-6">
                <div className="form-group">
                  <TextField
                    required
                    select
                    fullWidth
                    id="gender"
                    name="gender"
                    value={gender}
                    error={gender === "" && detailsForm}
                    onChange={onChangeValue}
                    label="Gender"
                    variant="filled"
                    className="dropDownStyle"
                    InputProps={{
                      className: classes.input,
                      classes: { input: classes.input },
                    }}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>

                    {genderArray.map((val) => {
                      return (
                        <MenuItem
                          key={
                            val.key.charAt(0).toUpperCase() + val.value.slice(1)
                          }
                          value={
                            val.key.charAt(0).toUpperCase() + val.value.slice(1)
                          }
                        >
                          {val.value.charAt(0).toUpperCase() + val.value.slice(1)}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                  <ErrorMessage
                    name={gender}
                    type="gender"
                    isFormSubmitted={detailsForm}
                  />
                </div>
              </div>


              <div
                className="col-md-2 col-sm-2 col-6"
                style={{
                  ...styles.inputContainerForTextField,
                  ...styles.textFieldPadding,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    justifyContent: "flex-start",
                    alignItems: 'center',
                    backgroundColor: '#FFF',
                    borderRadius: '5px',
                    padding: '15px 10px 6px',
                  }}
                >
                  <Checkbox
                    name="isChild"
                    checked={isChild}
                    value={true}
                    onChange={(e) => onChangeIsChildValue(e)}
                    color="default"
                    style={{ marginTop: -7 }}
                    inputProps={{
                      "aria-label": "checkbox with default color",
                    }}
                  />
                  <h6 style={{ fontWeight: "bold", color: "grey", marginTop: 0 }}>
                    Child
                  </h6>
                </div>
              </div>

              <div className="col-md-3 col-lg-2 col-12">
                <div className="form-group">
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                      // required
                      inputVariant="filled"
                      fullWidth={true}
                      label="Date of birth"
                      format="dd - MM - yyyy"
                      maxDate={new Date()}
                      // error={birthDate === '' && detailsForm}
                      onChange={(val) => handleChangeDate(val, "birthDate")}
                      InputProps={{
                        className: classes.input,
                        classes: { input: classes.input },
                      }}
                      style={{ borderRadius: "10px" }}
                      value={birthDate}
                    />
                  </MuiPickersUtilsProvider>

                  {/* <ErrorMessage name={birthDate} isFormSubmitted={detailsForm} /> */}
                </div>
              </div>

              <div className="col-md-4 col-sm-4 col-12">
                <div className="form-group">
                  <TextField
                    // required
                    type="text"
                    select
                    label="Nationality"
                    name={"nationality"}
                    value={!nationality ? "Jordan" : nationality}
                    // error={nationality === '' && detailsForm}
                    onChange={(e) => onChangeValue(e)}
                    className="textInputStyle"
                    variant="filled"
                    InputProps={{
                      className: classes.input,
                      classes: { input: classes.input },
                    }}
                  >
                    <MenuItem value="Jordan">
                      <em>None</em>
                    </MenuItem>

                    {countries &&
                      countries.map((val) => {
                        return (
                          <MenuItem key={val} value={val}>
                            {val}
                          </MenuItem>
                        );
                      })}
                  </TextField>

                  {/* <ErrorMessage
                    name={nationality}
                    // type='nationName'
                    isFormSubmitted={detailsForm}
                  /> */}
                </div>
              </div>

              <div className="col-md-2 col-6">
                <div className="form-group">
                  <TextField
                    // type='number'
                    disabled
                    label="Age"
                    name={"age"}
                    value={age}
                    onChange={onChangeValue}
                    // error={age === '' && detailsForm}
                    className="textInputStyle"
                    variant="filled"
                    InputProps={{
                      className: classes.input,
                      classes: { input: classes.input },
                    }}
                  />
                  {/* <ErrorMessage
                    name={age}
                    // type='numbers'
                    isFormSubmitted={detailsForm}
                  /> */}
                </div>
              </div>
              <div className="col-md-3 col-lg-3 col-6">
                <div className="form-group">
                  <TextField
                    select
                    fullWidth
                    id="blood"
                    name="blood"
                    value={blood}
                    onChange={onChangeBloodGroup}
                    // error={blood === '' && isFormSubmitted}
                    label="Blood Group"
                    variant="filled"
                    className="dropDownStyle"
                    InputProps={{
                      className: classes.input,
                      classes: { input: classes.input },
                    }}
                  >
                    <MenuItem value={blood}>{blood}</MenuItem>

                    {bloodGroups.map((val) => {
                      return (
                        <MenuItem key={val.key} value={val.key}>
                          {val.value}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                  {/* <ErrorMessage
                    name={blood}
                    isFormSubmitted={isFormSubmitted}
                  /> */}
                </div>
              </div>

              <div className="col-md-7 col-lg-3 col-sm-4">
                <div className="form-group">
                  <TextField
                    // required
                    label="Email"
                    name="email"
                    value={telecomValue("email")}
                    // error={telecomValue('email') === '' && detailsForm}
                    onChange={(e) => handleTelecomChange(e)}
                    className="textInputStyle"
                    variant="filled"
                    InputProps={{
                      className: classes.input,
                      classes: { input: classes.input },
                    }}
                  />
                  <ErrorMessage
                    name={telecomValue("email")}
                    type="email"
                    isFormSubmitted={detailsForm}
                  />
                </div>
              </div>

              <div className="col-md-6 col-lg-3 col-sm-4">
                <div className="form-group">
                  <MuiPhoneNumber
                    required
                    label="Mobile Number"
                    name="mobile"
                    value={telecomValue("mobile")}
                    error={telecomValue("mobile") === "" && detailsForm && isJohnDoe === false}
                    defaultCountry={"jo"}
                    disableAreaCodes={true}
                    onChange={(e, country) => {
                      handleTelecomChange(e, "mobile", country.countryCode);
                    }}
                    className="textInputStyle"
                    variant="filled"
                    InputProps={{
                      className: classes.input,
                      classes: { input: classes.input },
                    }}
                    InputLabelProps={{
                      className: classes.label,
                      classes: { label: classes.label },
                    }}
                  />
                  {telecomValue("mobile") &&
                  !validatePhone2(telecomValue("mobile"), telecomCountryCode("mobile")) ? (
                    undefined
                  ) : (
                    <ErrorMessage
                      name={telecomValue("mobile")}
                      type="phone2"
                      countryCode={telecomCountryCode("mobile")}
                      isFormSubmitted={detailsForm && isJohnDoe === false}
                    />
                  )}
                </div>
              </div>

              <div className="col-md-6 col-lg-3 col-sm-4">
                <div className="form-group">
                  <MuiPhoneNumber
                    // required
                    label="Other Number"
                    name="value"
                    value={telecomValue("phone")}
                    // hyperText='Telephone format +962xxxxxxxx'
                    // error={phoneNumber === '' && detailsForm && isJohnDoe === false}
                    defaultCountry={"jo"}
                    onChange={(e, country) => {
                      console.log('handleTelecomChange::', e, country)
                      handleTelecomChange(e, "phone", country.countryCode);
                    }}
                    className="textInputStyle"
                    variant="filled"
                    InputProps={{
                      className: classes.input,
                      classes: { input: classes.input },
                    }}
                    InputLabelProps={{
                      className: classes.label,
                      classes: { label: classes.label },
                    }}
                  />
                  {telecomValue("phone") &&
                  !validatePhone2(telecomValue("phone"), telecomCountryCode("phone")) ? (
                    undefined
                  ) : (
                    <ErrorMessage
                      name={telecomValue("phone")}
                      type="phone2"
                      countryCode={telecomCountryCode("phone")}
                      isFormSubmitted={detailsForm && isJohnDoe === false}
                    />
                  )}
                </div>
              </div>
            </div>

            {address.map((x, i) => {
              return (
                <>
                  <div className="row row-5">
                    <div className="col-md-4">
                      <div className="form-group">
                        <TextField
                          required
                          label="Address"
                          name={"text"}
                          value={x.text}
                          error={x.text === "" && detailsForm && isJohnDoe === false}
                          onChange={(e) => handleAddressChange(e, i)}
                          className="textInputStyle"
                          variant="filled"
                          InputProps={{
                            className: classes.input,
                            classes: { input: classes.input },
                          }}
                          InputLabelProps={{
                            className: classes.label,
                            classes: { label: classes.label },
                          }}
                        />
                        <ErrorMessage
                          name={x.text}
                          type="address"
                          isFormSubmitted={detailsForm && isJohnDoe === false}
                        />
                      </div>
                    </div>

                    <div className="col-md-4 col-6">
                      <div className="form-group">
                        <TextField
                          required
                          select
                          fullWidth
                          id="country"
                          name="country"
                          value={x.country}
                          error={x.country === "" && detailsForm && isJohnDoe === false}
                          onChange={(e) => handleAddressChange(e, i)}
                          label="Country"
                          variant="filled"
                          className="dropDownStyle"
                          InputProps={{
                            className: classes.input,
                            classes: { input: classes.input },
                          }}
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>

                          {countries &&
                            countries.map((val) => {
                              return (
                                <MenuItem key={val} value={val}>
                                  {val}
                                </MenuItem>
                              );
                            })}
                        </TextField>
                        <ErrorMessage
                          name={x.country}
                          type="country"
                          isFormSubmitted={detailsForm && isJohnDoe === false}
                        />
                      </div>
                    </div>

                    <div className="col-md-4 col-6">
                      <div className="form-group">
                        <TextField
                          required
                          select
                          fullWidth
                          id="city"
                          name="city"
                          value={x.city}
                          onChange={(e) => handleAddressChange(e, i)}
                          label="City"
                          error={x.city === "" && detailsForm && isJohnDoe === false}
                          variant="filled"
                          className="dropDownStyle"
                          InputProps={{
                            className: classes.input,
                            classes: { input: classes.input },
                          }}
                        >
                          {cities.length === 0 ? (
                            <MenuItem value="">
                              <em>Select Country First</em>
                            </MenuItem>
                          ) : (
                            undefined
                          )}

                          {cities &&
                            cities.map((val) => {
                              return (
                                <MenuItem key={val} value={val}>
                                  {val}
                                </MenuItem>
                              );
                            })}
                          {/* <MenuItem value="Other">Other</MenuItem> */}
                        </TextField>
                        <ErrorMessage
                          name={x.city}
                          type="city"
                          isFormSubmitted={detailsForm && isJohnDoe === false}
                        />
                      </div>
                    </div>
                  </div>

                  {/* {cityBoolean ? (
                    <div className="row row-5">
                      <div className="col-md-12 col-sm-12">
                        <div className="form-group">
                        <TextField
                          fullWidth
                          id="otherCity"
                          name="type"
                          value={x.type}
                          onChange={(e) => handleAddressChange(e, i)}
                          label="Other City/ Village/ Town"
                          variant="filled"
                          className="dropDownStyle"
                          InputProps={{
                            className: classes.input,
                            classes: { input: classes.input },
                          }}
                        />
                        </div>
                      </div>
                    </div>
                  ) : (
                    undefined
                  )} */}
                </>
              );
            })}

            <div className={`${"row row-5"} ${classes.root}`}>
              <div className="col-md-4 col-sm-4 col-12">
                <div className="form-group">
                  <label
                    style={{
                      ...styles.upload,
                      // fontSize: matches ? " " : 12
                      fontSize: matches ? 15 : 12,
                      height: 55,
                    }}
                  >
                    <TextField
                      required
                      type="file"
                      style={styles.input}
                      onChange={onSlipUpload}
                      onClick={(event) => {
                        event.target.value = null;
                      }}
                      name="photo"
                      Error={errorMsg}
                    />
                    <FaUpload />
                    {matches ? <>&nbsp;&nbsp;</> : ""} Patient Image
                  </label>
                </div>
              </div>

              <div className="col-md-4 col-sm-4 col-12">
                <div className="form-group">
                  <label
                    style={{
                      ...styles.upload,
                      // fontSize: matches ? " " : 12
                      fontSize: matches ? 15 : 12,
                      height: 55,
                    }}
                  >
                    <TextField
                      required
                      type="file"
                      label="ID Card (Front)"
                      style={styles.input}
                      onChange={onSlipUpload}
                      onClick={(event) => {
                        event.target.value = null;
                      }}
                      fullWidth
                      variant="filled"
                      className="dropDownStyle"
                      name="idCardFront"
                      Error={errorMsg}
                      InputProps={{
                        className: classes.input,
                        classes: { input: classes.input },
                        //   disableUnderline: true,
                      }}
                    />
                    <FaUpload />
                    {matches ? <>&nbsp;&nbsp;</> : ""} ID Card (Front)
                  </label>
                </div>
              </div>

              <div className="col-md-4 col-sm-4 col-12">
                <div className="form-group">
                  <label
                    style={{
                      ...styles.upload,
                      // fontSize: matches ? " " : 12,
                      fontSize: matches ? 15 : 12,
                      height: 55,
                    }}
                  >
                    <TextField
                      required
                      type="file"
                      style={styles.input}
                      onChange={onSlipUpload}
                      onClick={(event) => {
                        event.target.value = null;
                      }}
                      name="idCardBack"
                      Error={errorMsg}
                      InputProps={{
                        className: classes.input,
                        classes: { input: classes.input },
                        //   disableUnderline: true,
                      }}
                    />
                    <FaUpload />
                    {matches ? <>&nbsp;&nbsp;</> : ""} ID Card (Back)
                  </label>
                </div>
              </div>
            </div>

            <div className="row row-5">
              {photo && photo.length > 0 && photo[0].url.includes("\\") ? (
                <>
                  {photo.length > 0 &&
                  photo[0].url.slice(photo[0].url.length - 3) !== "pdf" ? (
                    <div
                      className="col-md-4 col-sm-4 col-4"
                      style={{
                        ...styles.inputContainerForTextField,
                        ...styles.textFieldPadding,
                      }}
                    >
                      <img
                        src={uploadsUrl + photo[0].url.replace('uploads\\', '').replace('uploads/', '')}
                        className="img-fluid depositSlipImg"
                      />
                      <div className="row row-5">
                        <div className="col-md-4 col-sm-5 col-12">
                          <img
                            src={CloseIcon}
                            onClick={() => setphoto([])}
                            //  className="preview-image depositSlipImg"
                            style={{
                              width: matches ? undefined : 20,
                              height: matches ? undefined : 20,
                              marginTop: matches ? -404 : -207,
                              marginLeft: matches ? 250 : 72,
                            }}
                          />
                        </div>
                        {photo !== "" ? (
                          <div
                            className="col-md-4 col-sm-5 col-12"
                            style={{
                              marginTop: matches ? "10px" : -54,
                              fontSize: matches ? " " : 10,
                              fontWeight: "500",
                              color: "gray",
                              textAlign: "center",
                            }}
                          >
                            {/*Patient Image*/}
                          </div>
                        ) : (
                          undefined
                        )}
                      </div>
                    </div>
                  ) : (
                    undefined
                  )}
                </>
              ) : photo && photo.length > 0 && photo[0].url.includes("/") ? (
                <>
                  {photo.length > 0 &&
                  photo[0].url.slice(photo[0].url.length - 3) !== "pdf" ? (
                    <div
                      className="col-md-4 col-sm-4 col-4"
                      style={{
                        ...styles.inputContainerForTextField,
                        ...styles.textFieldPadding,
                      }}
                    >
                      <img
                        src={uploadsUrl + photo[0].url.replace('uploads\\', '').replace('uploads/', '')}
                        className="img-fluid depositSlipImg"
                      />
                      <div className="row row-5">
                        <div className="col-md-4 col-sm-5 col-12">
                          <img
                            src={CloseIcon}
                            onClick={() => setphoto([])}
                            style={{
                              width: matches ? undefined : 20,
                              height: matches ? undefined : 20,
                              marginTop: matches ? -404 : -207,
                              marginLeft: matches ? 250 : 72,
                            }}
                          />
                        </div>
                        {photo !== "" ? (
                          <div
                            className="col-md-4 col-sm-5 col-12"
                            style={{
                              marginTop: matches ? "10px" : -54,
                              fontSize: matches ? " " : 10,
                              fontWeight: "500",
                              color: "gray",
                              textAlign: "center",
                            }}
                          >
                            {/*Patient Image*/}
                          </div>
                        ) : (
                          undefined
                        )}
                      </div>
                    </div>
                  ) : (
                    undefined
                  )}
                </>
              ) : (
                undefined
              )}

              {imagePreview !== "" ? (
                <div
                  className="col-md-4 col-sm-4 col-4"
                  style={{
                    ...styles.inputContainerForTextField,
                    ...styles.textFieldPadding,
                  }}
                >
                  <img
                    src={imagePreview}
                    className="img-fluid preview-image depositSlipImg"
                  />
                  <div className="row row-5">
                    <div className="col-md-4 col-sm-5 col-12">
                      <img
                        src={CloseIcon}
                        onClick={() => removeUploadedSlip("photo")}
                        //  className="preview-image depositSlipImg"
                        style={{
                          width: matches ? undefined : 20,
                          height: matches ? undefined : 20,
                          marginTop: matches ? -404 : -207,
                          marginLeft: matches ? 250 : 72,
                        }}
                      />
                    </div>
                    {photo !== "" ? (
                      <div
                        className="col-md-4 col-sm-5 col-12"
                        style={{
                          marginTop: matches ? "10px" : -54,
                          fontSize: matches ? " " : 10,
                          fontWeight: "500",
                          color: "gray",
                        }}
                      >
                        {/*New Patient Image*/}
                      </div>
                    ) : (
                      undefined
                    )}
                  </div>
                </div>
              ) : photo && photo.length > 0 && photo[0].url.includes("/") ? (
                undefined
              ) : (
                <div className="col-md-4 col-sm-4 col-4"></div>
              )}

              {idCardFront &&
              idCardFront.length > 0 &&
              idCardFront.includes("\\") ? (
                <>
                  {idCardFront.length > 0 &&
                  idCardFront.slice(idCardFront.length - 3) !== "pdf" ? (
                    <div
                      className="col-md-4 col-sm-4 col-4"
                      style={{
                        ...styles.inputContainerForTextField,
                        ...styles.textFieldPadding,
                      }}
                    >
                      <img
                        src={uploadsUrl + idCardFront.replace('uploads\\', '').replace('uploads/', '')}
                        className="img-fluid depositSlipImg"
                      />
                      <div className="row row-5">
                        <div className="col-md-4 col-sm-4 col-12">
                          <img
                            src={CloseIcon}
                            onClick={() => setIdCardFront("")}
                            //  className="preview-image depositSlipImg"
                            style={{
                              width: matches ? undefined : 20,
                              height: matches ? undefined : 20,
                              marginTop: matches ? -404 : -207,
                              marginLeft: matches ? 250 : 72,
                            }}
                          />
                        </div>
                        <div
                          className="col-md-4 col-sm-4 col-12"
                          style={{
                            marginTop: matches ? "10px" : -54,
                            fontSize: matches ? " " : 10,
                            fontWeight: "500",
                            color: "gray",
                            textAlign: "center",
                          }}
                        >
                          {/*ID Card (Front)*/}
                        </div>
                      </div>
                    </div>
                  ) : (
                    undefined
                  )}
                </>
              ) : idCardFront &&
                idCardFront.length > 0 &&
                idCardFront.includes("/") ? (
                <>
                  {idCardFront.length > 0 &&
                  idCardFront.slice(idCardFront.length - 3) !== "pdf" ? (
                    <div
                      className="col-md-4 col-sm-4 col-4"
                      style={{
                        ...styles.inputContainerForTextField,
                        ...styles.textFieldPadding,
                      }}
                    >
                      <img
                        src={uploadsUrl + idCardFront.replace('uploads\\', '').replace('uploads/', '')}
                        className="img-fluid depositSlipImg"
                      />
                      <div className="row row-5">
                        <div className="col-md-4 col-sm-4 col-12">
                          <img
                            src={CloseIcon}
                            onClick={() => setIdCardFront("")}
                            style={{
                              width: matches ? undefined : 20,
                              height: matches ? undefined : 20,
                              marginTop: matches ? -404 : -207,
                              marginLeft: matches ? 250 : 72,
                            }}
                          />
                        </div>
                        <div
                          className="col-md-4 col-sm-4 col-12"
                          style={{
                            marginTop: matches ? "10px" : -54,
                            fontSize: matches ? " " : 10,
                            fontWeight: "500",
                            color: "gray",
                            textAlign: "center",
                          }}
                        >
                          {/*ID Card (Front)*/}
                        </div>
                      </div>
                    </div>
                  ) : (
                    undefined
                  )}
                </>
              ) : (
                undefined
              )}

              {frontPreview !== "" ? (
                <div
                  className="col-md-4 col-sm-4 col-4"
                  style={{
                    ...styles.inputContainerForTextField,
                    ...styles.textFieldPadding,
                  }}
                >
                  <img
                    src={frontPreview}
                    className="img-fluid preview-image depositSlipImg"
                  />
                  <div className="row row-5">
                    <div className="col-md-4 col-sm-4 col-12">
                      <img
                        src={CloseIcon}
                        onClick={() => removeUploadedSlip("idCardFront")}
                        //  className="preview-image depositSlipImg"
                        style={{
                          width: matches ? undefined : 20,
                          height: matches ? undefined : 20,
                          marginTop: matches ? -404 : -207,
                          marginLeft: matches ? 250 : 72,
                        }}
                      />
                    </div>
                    {idCardFront !== "" ? (
                      <div
                        className="col-md-4 col-sm-4 col-12"
                        style={{
                          marginTop: matches ? "10px" : -54,
                          fontSize: matches ? " " : 10,
                          fontWeight: "500",
                          color: "gray",
                        }}
                      >
                        Front Side
                      </div>
                    ) : (
                      undefined
                    )}
                  </div>
                </div>
              ) : (idCardFront &&
                  idCardFront.length > 0 &&
                  idCardFront.includes("/")) ||
                (idCardFront &&
                  idCardFront.length > 0 &&
                  idCardFront.includes("\\")) ? (
                undefined
              ) : (
                <div className="col-md-4 col-sm-4 col-4"></div>
              )}

              {idCardBack &&
              idCardBack.length > 0 &&
              idCardBack.includes("\\") ? (
                <>
                  {idCardBack.length > 0 &&
                  idCardBack.slice(idCardBack.length - 3) !== "pdf" ? (
                    <div
                      className="col-md-4 col-sm-4 col-4"
                      style={{
                        ...styles.inputContainerForTextField,
                        ...styles.textFieldPadding,
                      }}
                    >
                      <img
                        src={uploadsUrl + idCardBack.replace('uploads\\', '').replace('uploads/', '')}
                        className="img-fluid depositSlipImg"
                      />
                      <div className="row row-5">
                        <div className="col-md-4 col-sm-4 col-12">
                          <img
                            src={CloseIcon}
                            onClick={() => setIdCardBack("")}
                            //  className="preview-image depositSlipImg"
                            style={{
                              width: matches ? undefined : 20,
                              height: matches ? undefined : 20,
                              marginTop: matches ? -404 : -207,
                              marginLeft: matches ? 250 : 72,
                            }}
                          />
                        </div>
                        {idCardBack !== "" ? (
                          <div
                            className="col-md-4 col-sm-4 col-12"
                            style={{
                              marginTop: matches ? "10px" : -54,
                              fontSize: matches ? " " : 10,
                              fontWeight: "500",
                              color: "gray",
                              textAlign: "center",
                            }}
                          >
                            {/*ID Card (Back)*/}
                          </div>
                        ) : (
                          undefined
                        )}
                      </div>
                    </div>
                  ) : (
                    undefined
                  )}
                </>
              ) : idCardBack &&
                idCardBack.length > 0 &&
                idCardBack.includes("/") ? (
                <>
                  {idCardBack.length > 0 &&
                  idCardBack.slice(idCardBack.length - 3) !== "pdf" ? (
                    <div
                      className="col-md-4 col-sm-4 col-4"
                      style={{
                        ...styles.inputContainerForTextField,
                        ...styles.textFieldPadding,
                      }}
                    >
                      <img
                        src={uploadsUrl + idCardBack.replace('uploads\\', '').replace('uploads/', '')}
                        className="img-fluid depositSlipImg"
                      />
                      <div className="row row-5">
                        <div className="col-md-4 col-sm-4 col-12">
                          <img
                            src={CloseIcon}
                            onClick={() => setIdCardBack("")}
                            //  className="preview-image depositSlipImg"
                            style={{
                              width: matches ? undefined : 20,
                              height: matches ? undefined : 20,
                              marginTop: matches ? -404 : -207,
                              marginLeft: matches ? 250 : 72,
                            }}
                          />
                        </div>
                        {idCardBack !== "" ? (
                          <div
                            className="col-md-4 col-sm-4 col-12"
                            style={{
                              marginTop: matches ? "10px" : -54,
                              fontSize: matches ? " " : 10,
                              fontWeight: "500",
                              color: "gray",
                              textAlign: "center",
                            }}
                          >
                            {/*ID Card (Back)*/}
                          </div>
                        ) : (
                          undefined
                        )}
                      </div>
                    </div>
                  ) : (
                    undefined
                  )}
                </>
              ) : (
                undefined
              )}

              {backPreview !== "" ? (
                <div
                  className="col-md-4 col-sm-4 col-4"
                  style={{
                    ...styles.inputContainerForTextField,
                    ...styles.textFieldPadding,
                  }}
                >
                  <img
                    src={backPreview}
                    className="preview-image depositSlipImg"
                  />
                  <div className="row row-5">
                    <div className="col-md-4 col-sm-4 col-12">
                      <img
                        src={CloseIcon}
                        onClick={() => removeUploadedSlip("idCardBack")}
                        //  className="preview-image depositSlipImg"
                        style={{
                          
                              width: matches ? undefined : 20,
                              height: matches ? undefined : 20,
                              marginTop: matches ? -404 : -207,
                              marginLeft: matches ? 250 : 72,
                        }}
                      />
                    </div>
                    {idCardBack !== "" ? (
                      <div
                        className="col-md-4 col-sm-4 col-12"
                        style={{
                          marginTop: matches ? "10px" : -54,
                          fontSize: matches ? " " : 10,
                          fontWeight: "500",
                          color: "gray",
                        }}
                      >
                        Back Side
                      </div>
                    ) : (
                      undefined
                    )}
                  </div>
                </div>
              ) : (idCardBack &&
                  idCardBack.length > 0 &&
                  idCardBack.includes("\\")) ||
                (idCardBack &&
                  idCardBack.length > 0 &&
                  idCardBack.includes("/")) ? (
                undefined
              ) : (
                <div className="col-md-4 col-sm-4 col-4"></div>
              )}
            </div>

            <div className="row row-5">
              <div className="col-md-12">
                <TextField
                  multiline
                  label="Other Details"
                  name={"otherDetails"}
                  value={otherDetails}
                  // error={otherDetails === '' && detailsForm}
                  onChange={onChangeValue}
                  rows={4}
                  className="textInputStyle"
                  variant="filled"
                  InputProps={{
                    className: classes.multilineColor,
                    classes: {
                      input: classes.multilineColor,
                    },
                  }}
                  // inputProps={{ maxLength: 12 }}
                />
                {/* <ErrorMessage
                  name={otherDetails}
                  // type='text'
                  isFormSubmitted={detailsForm}
                /> */}
              </div>
            </div>

            <div
              className="row row-5"
              style={{
                display: "flex",
                flex: 1,
                justifyContent: "center",
                paddingLeft: 6,
                paddingRight: 6,
                marginTop: matches ? undefined : 10,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  justifyContent: "flex",
                  marginTop: "2%",
                  marginBottom: "2%",
                }}
              >
                <Button
                  style={{ ...styles.stylesForButton }}
                  onClick={onTabNavigation}
                  variant="contained"
                  color="default"
                >
                  Cancel
                </Button>
              </div>
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  justifyContent: "flex-end",
                  marginTop: "2%",
                  marginBottom: "2%",
                }}
              >
                <Button
                  style={styles.stylesForButton}
                  //disabled={!validateFormType1()}
                  onClick={DetailsOnClick}
                  variant="contained"
                  color="primary"
                >
                  Next
                </Button>
              </div>
            </div>
          </div>
        ) : value === 1 ? (
          <div
            style={{
              flex: 4,
              display: "flex",
              flexDirection: "column",
            }}
            className={`${"container-fluid"} ${classes.root}`}
          >
            {contact.map((x, i) => {
              return (
                <>
                  <div className="row row-5">
                    <div className="col-md-12">
                      <div className="form-group">
                        <TextField
                          required
                          label="Name"
                          name="name"
                          value={x.name}
                          onChange={(e) => handleContactChange(e, i)}
                          error={x.name === "" && emergencyForm && isJohnDoe === false}
                          className="textInputStyle"
                          variant="filled"
                          InputProps={{
                            className: classes.input,
                            classes: { input: classes.input },
                          }}
                          inputProps={{ maxLength: 80 }}
                          disabled={emergencyFormUndisclosed}
                        />
                        <ErrorMessage
                          name={x.name}
                          type="emergencyName"
                          isFormSubmitted={emergencyFormUndisclosed === false && emergencyForm && isJohnDoe === false}
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <MuiPhoneNumber
                          required
                          label="Contact"
                          name="mobile"
                          value={contact[i].telecom[0].value}
                          // value={contact[i].telecomValue('mobile')}
                          defaultCountry={"jo"}
                          onChange={(e, country) => handleContactTelecomChange(e, 0, country.countryCode)}
                          // onChange={(e) =>
                          //   handleContactTelecomChange(e, 'mobile')
                          // }
                          className="textInputStyle"
                          variant="filled"
                          InputProps={{
                            className: classes.input,
                            classes: { input: classes.input },
                          }}
                          InputLabelProps={{
                            className: classes.label,
                            classes: { label: classes.label },
                          }}
                          disabled={emergencyFormUndisclosed}
                        />
                        {x.name !== ""?
                        <ErrorMessage
                          name={x.telecom[0].value}
                          type="require"
                          isFormSubmitted={emergencyFormUndisclosed === false && emergencyForm && isJohnDoe === false}
                        />:undefined
                        }


                        {x.telecom[0].value &&
                        !validatePhone2(x.telecom[0].value, x.telecom[0].countryCode) ? (
                          undefined
                        ) : (
                          <ErrorMessage
                            name={x.telecom[0].value}
                            type="phone2"
                            countryCode={x.telecom[0].countryCode}
                            isFormSubmitted={emergencyFormUndisclosed === false && emergencyForm && isJohnDoe === false}
                          />
                        )}
                      </div>
                    </div>

                    <div className={ x.typeOfRelation === "other" ? "col-md-3":"col-md-6" }>
                      <div className="form-group">
                        <TextField
                          required
                          select
                          label="Relation"
                          name="typeOfRelation"
                          value={x.typeOfRelation}
                          onChange={(e) => handleContactChange(e, i)}
                          error={x.typeOfRelation === "" && emergencyForm && isJohnDoe === false}
                          className="textInputStyle"
                          variant="filled"
                          InputProps={{
                            className: classes.input,
                            classes: { input: classes.input },
                          }}
                          disabled={emergencyFormUndisclosed}
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>

                          {relationArray.map((val) => {
                            return (
                              <MenuItem key={val.key} value={val.key}>
                                {val.value}
                              </MenuItem>
                            );
                          })}
                        </TextField>
                        <ErrorMessage
                          name={x.typeOfRelation}
                          type="relation"
                          isFormSubmitted={emergencyFormUndisclosed === false && emergencyForm && isJohnDoe === false}
                        />
                      </div>
                    </div>

                    {
                      x.typeOfRelation === "other" ?
                      (
                      <div className="col-md-3">
                        <div className="form-group">
                          <TextField
                            required
                            label="Type Other Relation"
                            name="otherRelation"
                            value={x.otherRelation}
                            onChange={(e) => handleContactChange(e, i)}
                            error={x.otherRelation === "" && emergencyForm}
                            className="textInputStyle"
                            variant="filled"
                            InputProps={{
                              className: classes.input,
                              classes: { input: classes.input },
                            }}
                            inputProps={{ maxLength: 80 }}
                            disabled={emergencyFormUndisclosed}
                          />
                          <ErrorMessage
                            name={x.otherRelation}
                            type="otherRelation"
                            isFormSubmitted={emergencyFormUndisclosed === false && emergencyForm && isJohnDoe === false}
                          />
                        </div>
                      </div>
                      ):undefined
                    }

                    <div className="col-md-12">
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="emergencyFormUndisclosed"
                            checked={emergencyFormUndisclosed}
                            value={true}
                            onChange={(e) => {
                              console.log({ field: e.target.name, value: e.target.checked })
                              dispatch({ field: e.target.name, value: e.target.checked });
                            }}
                            color="default"
                            style={{ marginTop: -7 }}
                            inputProps={{
                              "aria-label": "checkbox with default color",
                            }}
                          />
                        }
                        label="Patient not want to disclose the Emergency Contact Information."
                      />


                    </div>

                  </div>
                </>
              );
            })}

            <div
              style={{ display: "flex", flex: 1, justifyContent: "center" }}
              className="row row-5"
            >
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  paddingLeft: 6,
                  justifyContent: "flex",
                  marginTop: "1%",
                  marginBottom: "2%",
                }}
              >
                <Button
                  style={styles.stylesForButton}
                  onClick={onTabNavigation}
                  variant="contained"
                  color="default"
                >
                  Cancel
                </Button>
              </div>
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  justifyContent: "flex-end",
                  marginTop: "1%",
                  marginBottom: "2%",
                  marginRight: -5,
                }}
              >
                <Button
                  style={styles.stylesForButton}
                  onClick={EmergencyOnClick}
                  variant="contained"
                  color="primary"
                >
                  Next
                </Button>
                <div
                  style={{
                    width: "10px",
                    height: "auto",
                    display: "inline-block",
                  }}
                />

                {/* {comingFor === 'add' ? (
                    <>
                      <Button
                        style={styles.save}
                        // disabled={
                        //   !(validatePatientForm() && validatePaymentForm())
                        // }
                        onClick={searchActivated ? handleEdit : handleAdd}
                        variant="contained"
                        color="default"
                      >
                        Save
                      </Button>
                      <div
                        style={{
                          width: "10px",
                          height: "auto",
                          display: "inline-block",
                        }}
                      />
                    </>
                  ) : (
                    <></>
                  )} */}

                {/* {currentUser.staffTypeId.type === 'EDR Receptionist' ? (
                  <Button
                    style={styles.generate}
                    // disabled={comingFor === 'add' ? !isFormSubmitted : false}
                    disabled={
                      comingFor === "add"
                        ? !(
                            validatePatientForm() &&
                            (emergencyFormUndisclosed === false && validateEmergencyForm()) &&
                            (validateInsuranceForm() || validateSelfpayForm()) &&
                            isPatientSubmitted
                          )
                        : false
                    }
                    onClick={
                      comingFor === "add" ? handleGenerateEDR : handleEdit
                    }
                    variant="contained"
                    color="primary"
                  >
                    {comingFor === "add" ? "Generate ED Record" : "Update"}
                  </Button>
                ) : (
                  undefined
                )} */}

                {/* {currentUser.staffTypeId.type === 'IPR Receptionist' ? (
                  <Button
                    style={styles.generate}
                    // disabled={comingFor === 'add' ? !isFormSubmitted : false}
                    disabled={
                      comingFor === "add"
                        ? !(
                            validatePatientForm() &&
                            (emergencyFormUndisclosed === false && validateEmergencyForm()) &&
                            (validateInsuranceForm() || validateSelfpayForm()) &&
                            isPatientSubmitted
                          )
                        : false
                    }
                    onClick={
                      comingFor === "add" ? handleGenerateIPR : handleEdit
                    }
                    variant="contained"
                    color="primary"
                  >
                    {comingFor === "add" ? "Generate IP Record" : "Update"}
                  </Button>
                ) : (
                  undefined
                )} */}
              </div>
            </div>
          </div>
        ) : value === 2 ? (
          <div
            style={{
              flex: 4,
              display: "flex",
              flexDirection: "column",
            }}
            className={`${"container-fluid"} ${classes.root}`}
          >
            <div className="row row-5">
              <div className="col-md-12">
                <div className="form-group" style={styles.form}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Payment Method</FormLabel>
                    <RadioGroup
                      row
                      aria-label="payMethod"
                      name="paymentMode"
                      value={paymentMode}
                      onChange={(e) => handlePaymentModeChange(e, 0)}
                    >
                      <FormControlLabel
                        value="Selfpay"
                        control={<Radio />}
                        label="Selfpay"
                      />
                      <FormControlLabel
                        value="Insured"
                        control={<Radio />}
                        label="Insured"
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
              </div>
            </div>

            <div className="row row-5">
              <div
                style={{
                  marginTop: "10px",
                  marginBottom: "1%",
                  ...styles.inputContainerForTextField,
                  ...styles.textFieldPadding,
                  paddingRight: matches ? " " : 0,
                  marginLeft: -3,
                }}
                className="col-md-6 col-12"
              >
                <Button
                  style={{
                    ...styles.stylesForButton,
                    width: matches
                      ? 130
                      : paymentMode === "Selfpay"
                      ? "100%"
                      : 130,
                    height: matches ? 45 : 40,
                  }}
                  onClick={onTabNavigation}
                  variant="contained"
                  color="default"
                >
                  Cancel
                </Button>
              </div>

              <div
                style={{
                  display: "flex",
                  // flex: 1,
                  justifyContent: "flex-end",
                  marginTop: "10px",
                  marginBottom: "1%",
                  marginLeft: !matches ? -3 : 0,
                  ...styles.inputContainerForTextField,
                  ...styles.textFieldPadding,
                  paddingRight: 0,
                }}
                className="col-md-6 col-12"
              >
                {paymentMode === "Insured" ? (
                  <Button
                    disabled={enableNext}
                    style={{
                      ...styles.stylesForButton,
                      marginTop: matches ? undefined : -54,
                      height: matches ? 45 : 40,
                    }}
                    onClick={onClick}
                    variant="contained"
                    color="primary"
                  >
                    next
                  </Button>
                ) : (
                  undefined
                )}

                {paymentMode === "Selfpay" ? (
                  <Button
                    style={{
                      ...styles.save,
                      width: matches ? 130 : "100%",
                      height: matches ? 45 : 40,
                    }}
                    // disabled={
                    //   !(validatePatientForm() && validatePaymentForm())
                    // }
                    onClick={
                      searchActivated || comingFor === "edit"
                        ? handleEdit
                        : handleAdd
                    }
                    variant="contained"
                    color="default"
                  >
                    {comingFor === "edit" ? "Update" : "Save"}
                  </Button>
                ) : (
                  undefined
                )}
              </div>
            </div>
          </div>
        ) : (
          <div>
            <div
              style={{ flex: 4, display: "flex", flexDirection: "column" }}
              className={`${"container-fluid"} ${classes.root}`}
            >
              <div className="row">
                <div className="col-sm-8 col-lg-9">
                  <div className="row row-5">
                    <div
                      className="col-12"
                      style={{
                        ...styles.inputContainerForTextField,
                        ...styles.textFieldPadding,
                      }}
                    >
                      <Autocomplete
                          id="insuranceVendorDropdown"
                          options={insuranceVendorList}
                          getOptionLabel={(option) =>
                            option.companyName || ""
                          }
                          defaultValue=""
                          onChange={(e, value) => {
                            handleInsuranceCompanySelect(
                              e, value
                            )
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              className="textInputStyle"
                              variant="filled"
                              label="Choose Insurance Vendor"
                              placeholder="Type your Insurance Vendor Name"
                              onChange={(event) => {
                                setInsuranceVendorKeyword(
                                  event.target.value
                                );
                              }}
                            />
                          )}
                        />
                      <hr />
                    </div>
                  </div>

                  <div className="row row-5">

                    <div className="col-md-4">
                      <div className="form-group">
                        <TextField
                          required
                          label="Insurance Card Number"
                          type="text"
                          name={"insuranceNumber"}
                          value={insuranceNumber}
                          onChange={onChangeValue}
                          error={insuranceNumber === "" && insuranceForm}
                          className="textInputStyle"
                          disabled={insuranceBoolean}
                          variant="filled"
                          InputProps={{
                            className: classes.input,
                            classes: { input: classes.input },
                          }}
                        />
                        <ErrorMessage
                          name={insuranceNumber}
                          type="textNumeric"
                          isFormSubmitted={insuranceForm}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <TextField
                          required
                          label="Insurance Vendor"
                          name={"insuranceVendor"}
                          value={insuranceVendor}
                          disabled={insuranceBoolean}
                          onChange={onChangeValue}
                          error={insuranceVendor === '' && insuranceForm}
                          className="textInputStyle"
                          variant="filled"
                          InputProps={{
                            className: classes.input,
                            classes: { input: classes.input },
                          }}
                          inputProps={{ maxLength: 80 }}
                        />
                        {/* <ErrorMessage
                          name={insuranceVendor}
                          type='vendor'
                          isFormSubmitted={insuranceForm}
                        /> */}
                        <ErrorMessage
                          name={insuranceVendor}
                          type="allowAll"
                          isFormSubmitted={insuranceForm}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <TextField
                          required
                          label="Policy Holder's Name"
                          name={"insureeName"}
                          value={insureeName}
                          disabled={insuranceBoolean}
                          onChange={onChangeValue}
                          error={insureeName === '' && insuranceForm}
                          className="textInputStyle"
                          variant="filled"
                          InputProps={{
                            className: classes.input,
                            classes: { input: classes.input },
                          }}
                          inputProps={{ maxLength: 80 }}
                        />
                        <ErrorMessage
                          name={insureeName}
                          type='textNumericSpace'
                          isFormSubmitted={insuranceForm}
                        />
                      </div>
                    </div>


                    <div className="col-md-3">
                      <div className="form-group">
                        <TextField
                          required
                          label="Policy No"
                          name={"insurancePolicyNumber"}
                          value={insurancePolicyNumber}
                          type="text"
                          className="textInputStyle"
                          disabled={insuranceBoolean}
                          variant="filled"
                          InputProps={{
                            className: classes.input,
                            classes: { input: classes.input },
                          }}
                          onChange={onChangeFieldData}
                        />
                        <ErrorMessage
                          name={insurancePolicyNumber}
                          type='allowAll'
                          isFormSubmitted={insuranceForm}
                        />
                      </div>
                    </div>
                    
                    <div className="col-md-3">
                      <div className="form-group">
                        <TextField
                          required
                          label="Network/Plan Type"
                          name={"insurancePlanNetwork"}
                          value={insurancePlanNetwork}
                          type="text"
                          className="textInputStyle"
                          disabled={insuranceBoolean}
                          variant="filled"
                          InputProps={{
                            className: classes.input,
                            classes: { input: classes.input },
                          }}
                          onChange={onChangeFieldData}
                        />
                        <ErrorMessage
                          name={insurancePlanNetwork}
                          type='allowAll'
                          isFormSubmitted={insuranceForm}
                        />
                      </div>
                    </div>
                    
                    <div className="col-md-3">
                      <div className="form-group">
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <DatePicker
                            // required
                            inputVariant="filled"
                            fullWidth={true}
                            label="Effective From"
                            format="dd - MM - yyyy"
                            InputProps={{
                              className: classes.input,
                              classes: { input: classes.input },
                            }}
                            maxDate={insuranceTo}
                            style={{ borderRadius: "10px" }}
                            value={insuranceFrom}
                            onChange={(val) => handleChangeDates(val, 'insuranceFrom')}
                          />
                        </MuiPickersUtilsProvider>
                      </div>
                    </div>
                    
                    <div className="col-md-3">
                      <div className="form-group">
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <DatePicker
                            // required
                            inputVariant="filled"
                            fullWidth={true}
                            label="Expiry Date"
                            format="dd - MM - yyyy"
                            minDate={insuranceFrom}
                            InputProps={{
                              className: classes.input,
                              classes: { input: classes.input },
                            }}
                            style={{ borderRadius: "10px" }}
                            value={insuranceTo}
                            onChange={(val) => handleChangeDates(val, 'insuranceTo')}
                          />
                        </MuiPickersUtilsProvider>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <TextField
                          select
                          fullWidth
                          disabled={insuranceBoolean}
                          id="coverageTerms"
                          name="coverageTerms"
                          value={coverageTerms || covTer}
                          onChange={onChangeValue}
                          // error={coverageTerms === '' && insuranceForm}
                          label="Coverage Terms"
                          variant="filled"
                          className="dropDownStyle"
                          InputProps={{
                            className: classes.input,
                            classes: { input: classes.input },
                          }}
                        >
                          <MenuItem value="">None</MenuItem>

                          {coverageTermsArr.map((val) => {
                            return (
                              <MenuItem key={val.key} value={val.key}>
                                {val.value}
                              </MenuItem>
                            );
                          })}
                        </TextField>
                        {/* <ErrorMessage
                          name={coverageTerms}
                          isFormSubmitted={insuranceForm}
                        /> */}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <TextField
                          label="Co-Payment %"
                          name={"coPayment"}
                          value={coPayment}
                          disabled={insuranceBoolean || !coPaymentField}
                          onChange={onChangeValue}
                          // error={coPayment === '' && isFormSubmitted}
                          type="number"
                          className="textInputStyle"
                          variant="filled"
                          InputProps={{
                            className: classes.input,
                            classes: { input: classes.input },
                            inputProps: { min: 0, max: 100 },
                          }}
                        />
                        {/* <ErrorMessage
                          name={coPayment}
                          type="coPayment"
                          isFormSubmitted={isFormSubmitted}
                        /> */}
                      </div>
                    </div>


                    {coveredFamilyMember.map((x, i) => {
                      return (
                          <div
                            className="col-md-6"
                            style={{
                              ...styles.inputContainerForTextField,
                              ...styles.textFieldPadding,
                            }}
                          >
                            <div className="form-group">
                              <TextField
                                // required
                                select
                                fullWidth
                                disabled={insuranceBoolean}
                                id="coveredFamilyMembers"
                                name="typeOfRelation"
                                value={x.typeOfRelation}
                                onChange={(e) => handleCoveredFamilyMemChange(e, i)}
                                // error={coveredFamilyMembers === '' && insuranceForm}
                                label="Relationship with Policy Holder"
                                variant="filled"
                                className="dropDownStyle"
                                InputProps={{
                                  className: classes.input,
                                  classes: { input: classes.input },
                                }}
                              >

                                {coveredFamilyArray.map((val) => {
                                  return (
                                    <MenuItem key={val.key} value={val.key}>
                                      {val.value}
                                    </MenuItem>
                                  );
                                })}
                              </TextField>
                              {/* <ErrorMessage
                            name={coveredFamilyMembers}
                            // type='text'
                            isFormSubmitted={insuranceForm}
                          /> */}
                            </div>
                          </div>
                      );
                    })}
                    <div className="col-sm-6">
                      <div className="form-group" style={styles.form2}>
                      <div className="row align-items-center">
                        <div className="col-md-4 text-left">Eligibility</div>
                        <div className="col-md-8">
                          <RadioGroup
                            row
                            aria-label="insuranceEligibility"
                            name="insuranceEligibility"
                            value={insuranceEligibility}
                            onChange={(e) => handleInsuranceEligibilityChange(e, 0)}
                          >
                            <FormControlLabel
                              value="Yes"
                              control={<Radio style={{marginRight: 0}} />}
                              label="Yes"
                              style={{marginBottom: 0}}
                            />
                            <FormControlLabel
                              value="No"
                              control={<Radio />}
                              label="No"
                              style={{marginBottom: 0}}
                            />
                          </RadioGroup>
                        </div>
                      </div>
                      </div>
                    </div>


                    <div className="col-md-12">
                      <div className="form-group">
                        <TextField
                          // required
                          multiline
                          type="text"
                          disabled={insuranceBoolean}
                          // error={coverageDetails === '' && insuranceForm}
                          label="Coverage Details"
                          name={"coverageDetails"}
                          value={coverageDetails}
                          onChange={onChangeValue}
                          rows={4}
                          className="textInputStyle"
                          variant="filled"
                          InputProps={{
                            className: classes.input,
                            classes: { input: classes.input },
                          }}
                        />
                        {/* <ErrorMessage
                          name={coverageDetails}
                          // type='text'
                          isFormSubmitted={insuranceForm}
                        /> */}
                      </div>
                    </div>




                    <div className="col-md-12">
                      <div className="form-group">
                        <TextField
                          // required
                          multiline
                          type="text"
                          disabled={insuranceBoolean}
                          // error={insuranceDetails === '' && insuranceForm}
                          label="Other Details"
                          name={"insuranceDetails"}
                          value={insuranceDetails}
                          onChange={onChangeValue}
                          rows={4}
                          className="textInputStyle"
                          variant="filled"
                          InputProps={{
                            className: classes.input,
                            classes: { input: classes.input },
                          }}
                        />
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="form-group">
                      <label style={styles.upload}>
                        <TextField
                          required
                          type="file"
                          style={styles.input}
                          onChange={onSlipUpload}
                          onClick={(event) => {
                            event.target.value = null;
                          }}
                          name="insuranceCard"
                          Error={errorMsg}
                        />
                        <FaUpload /> Upload Insurance Card Image
                      </label>
                      </div>
                    </div>
                  </div>

                  <div className="row row-5">
                    {insuranceCard &&
                    insuranceCard.length > 0 &&
                    insuranceCard.includes("\\") ? (
                      <>
                        {insuranceCard.length > 0 &&
                        insuranceCard.slice(insuranceCard.length - 3) !== "pdf" ? (
                          <div
                            className="col-md-4 col-sm-4 col-12"
                            style={{
                              ...styles.inputContainerForTextField,
                              ...styles.textFieldPadding,
                            }}
                          >
                            <img
                              src={uploadsUrl + insuranceCard.replace('uploads\\', '').replace('uploads/', '')}
                              className="depositSlipImg"
                            />
                          </div>
                        ) : (
                          undefined
                        )}
                      </>
                    ) : insuranceCard &&
                      insuranceCard.length > 0 &&
                      insuranceCard.includes("/") ? (
                      <>
                        {insuranceCard.length > 0 &&
                        insuranceCard.slice(insuranceCard.length - 3) !== "pdf" ? (
                          <div
                            className="col-md-4 col-sm-4 col-12"
                            style={{
                              ...styles.inputContainerForTextField,
                              ...styles.textFieldPadding,
                            }}
                          >
                            <img
                              src={uploadsUrl + insuranceCard.replace('uploads\\', '').replace('uploads/', '')}
                              className="depositSlipImg"
                            />
                          </div>
                        ) : (
                          undefined
                        )}
                      </>
                    ) : (
                      undefined
                    )}

                    {insuranceCardPreview !== "" ? (
                      <div
                        className="col-md-4 col-sm-4 col-12"
                        style={{
                          ...styles.inputContainerForTextField,
                          ...styles.textFieldPadding,
                        }}
                      >
                        <img
                          src={insuranceCardPreview}
                          className="depositSlipImg"
                        />
                        <div className="row row-5">
                          <div className="col-md-12 col-sm-5 col-5">

                            <img
                              src={CloseIcon}
                              onClick={() => removeUploadedSlip("insuranceCard")}
                              //  className="preview-image depositSlipImg"
                              style={{
                                
                                    width: matches ? undefined : 20,
                                    height: matches ? undefined : 20,
                                    marginTop: matches ? -404 : -207,
                                    marginLeft: matches ? 250 : 72,
                              }}
                            />
                          </div>
                          {insuranceCard !== "" ? (
                            <div
                              className="col-md-12 col-sm-5 col-7"
                              style={{
                                marginTop: "10px",
                                fontWeight: "500",
                                color: "gray",
                                textAlign: "center",
                              }}
                            >
                              New insurance Card
                            </div>
                          ) : (
                            undefined
                          )}
                        </div>
                      </div>
                    ) : (
                      undefined
                    )}
                  </div>
                </div>
                <div className="col-sm-4 col-lg-3">
                  <div className="card">
                    {(
                      insuranceVendorSelect && insuranceVendorSelect._id ? (
                        <div className="card-body">
                          <h5 className="card-title">{insuranceVendorSelect.companyName}</h5>
                          <p className="card-text">
                            <strong>Payment Terms:</strong><br />
                            {insuranceVendorSelect.paymentTerms || "N/A"}
                          </p>
                          
                          <p className="card-text">
                            <strong>Phone Number:</strong>&nbsp;
                            {insuranceVendorSelect.phoneNumber || "N/A"}
                          </p>
                          <p className="card-text">
                            <strong>Fax Number:</strong>&nbsp;
                            {insuranceVendorSelect.faxNumber || "N/A"}
                          </p>
                          <p className="card-text">
                            <strong>Eligibility Email:</strong><br />
                            {insuranceVendorSelect.eligibilityEmail || "N/A"}
                          </p>
                          <p className="card-text">
                            <strong>Preapprovals Email:</strong><br />
                            {insuranceVendorSelect.preapprovalsEmail || "N/A"}
                          </p>
                          <p className="card-text">
                            <strong>Claims Email:</strong><br />
                            {insuranceVendorSelect.claimsEmail || "N/A"}
                          </p>
                          <Button
                            style={{
                              ...styles.stylesForButton,
                              width: "100%",
                              height: 40,
                            }}
                            onClick={handleInsuranceDialogOpen}
                            variant="contained"
                            color="default"
                          >Add Insurance Information</Button>
                        </div>
                      ) : (
                        <div className="card-body">
                          <h5 className="card-title">Please select the insurance company</h5>
                          <p className="card-text">You have not selected any insurance company yet!</p>
                        </div>
                      )
                    )}
                  </div>
                </div>
              </div>
            </div>

            <Dialog fullScreen open={insuranceDialogOpen} onClose={handleInsuranceDialogClose} TransitionComponent={Transition}>
              <AppBar className={classes.dialogAppBar}>
                <Toolbar>
                  <IconButton edge="start" color="inherit" onClick={handleInsuranceDialogClose} aria-label="close">
                    <MCloseIcon />
                  </IconButton>
                  <Typography variant="h6" className={classes.dialogTitle}>Enter Insurance Details</Typography>
                </Toolbar>
              </AppBar>
              <DialogContent
                style={{
                  backgroundColor: "rgb(19 213 159)",
                }}>
                <DialogContentText><br /><br /></DialogContentText>
                
                <div className="row row-5" style={{marginTop: '15px'}}>
                  <div className="col-12"><strong>Consultation</strong></div>
                  <div className="col-12 col-md-6 col-xl-5">
                    <TextField
                      margin="dense"
                      name="consultationJod"
                      value={insuranceItemsInfo[0].consultationJod}
                      onChange={(e) => handleInsuranceDialogFields(e, 0)}
                      label="Deductible (JOD)"
                      type="text"
                      fullWidth
                      className="textInputStyle"
                      variant="filled"
                      InputProps={{
                        className: classes.input,
                        classes: { input: classes.input },
                      }}
                      InputLabelProps={{
                        className: classes.label,
                        classes: { label: classes.label },
                      }}
                    />
                  </div>
                  <div className="col-12 col-md-6 col-xl-4">
                    <TextField
                      margin="dense"
                      name="consultationCopay"
                      value={insuranceItemsInfo[0].consultationCopay}
                      onChange={(e) => handleInsuranceDialogFields(e, 0)}
                      label="Co-Pay %"
                      type="number"
                      fullWidth
                      className="textInputStyle"
                      variant="filled"
                      InputProps={{
                        className: classes.input,
                        classes: { input: classes.input },
                        inputProps: { min: 0, max: 100 }
                      }}
                      InputLabelProps={{
                        className: classes.label,
                        classes: { label: classes.label },
                      }}
                      error={insuranceItemsInfo[0].consultationCopay > 100 || insuranceItemsInfo[0].consultationCopay<0}
                    />
                  </div>
                  <div className="col-12 col-md-6 col-xl-3">
                    <div className="row row-5">
                      <div className="col-6">
                        <TextField
                          margin="dense"
                          name="consultationMin"
                          value={insuranceItemsInfo[0].consultationMin}
                          onChange={(e) => handleInsuranceDialogFields(e, 0)}
                          label="Min"
                          type="number"
                          fullWidth
                      className="textInputStyle"
                      variant="filled"
                      InputProps={{
                        className: classes.input,
                        classes: { input: classes.input },
                      }}
                      InputLabelProps={{
                        className: classes.label,
                        classes: { label: classes.label },
                      }}
                        />
                      </div>
                      <div className="col-6">
                        <TextField
                          margin="dense"
                          name="consultationMax"
                          value={insuranceItemsInfo[0].consultationMax}
                          onChange={(e) => handleInsuranceDialogFields(e, 0)}
                          label="Max"
                          type="number"
                          fullWidth
                      className="textInputStyle"
                      variant="filled"
                      InputProps={{
                        className: classes.input,
                        classes: { input: classes.input },
                      }}
                      InputLabelProps={{
                        className: classes.label,
                        classes: { label: classes.label },
                      }}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row row-5" style={{marginTop: '15px'}}>
                  <div className="col-12"><strong>Lab</strong></div>
                  <div className="col-12 col-md-6 col-xl-5">
                    <TextField
                      margin="dense"
                      name="labJod"
                      value={insuranceItemsInfo[0].labJod}
                      onChange={(e) => handleInsuranceDialogFields(e, 0)}
                      label="Deductible (JOD)"
                      type="text"
                      fullWidth
                      className="textInputStyle"
                      variant="filled"
                      InputProps={{
                        className: classes.input,
                        classes: { input: classes.input },
                      }}
                      InputLabelProps={{
                        className: classes.label,
                        classes: { label: classes.label },
                      }}
                    />
                  </div>
                  <div className="col-12 col-md-6 col-xl-4">
                    <div class="row row-5 align-items-center">
                      <div className="col-12 col-md-6 col-xl-6">
                        <TextField
                          margin="dense"
                          name="labCopay"
                          value={insuranceItemsInfo[0].labCopay}
                          onChange={(e) => handleInsuranceDialogFields(e, 0)}
                          label="Co-Pay %"
                          type="number"
                          fullWidth
                          className="textInputStyle"
                          variant="filled"
                          InputProps={{
                            className: classes.input,
                            classes: { input: classes.input },
                            inputProps: { min: 0, max: 100 }
                          }}
                          InputLabelProps={{
                            className: classes.label,
                            classes: { label: classes.label },
                          }}
                          error={insuranceItemsInfo[0].labCopay > 100 || insuranceItemsInfo[0].labCopay<0}
                        />
                      </div>
                      <div className="col-12 col-md-6 col-xl-6">
                        <RadioGroup style={styles.form3}
                          row
                          aria-label="labCoverageType"
                          name="labCoverageType"
                          value={insuranceItemsInfo[0].labCoverageType}
                          onChange={(e) => handleInsuranceDialogFields(e, 0)}
                        >
                          <FormControlLabel
                            value="All"
                            control={<Radio />}
                            label="For All"
                          />
                          <FormControlLabel
                            value="Each"
                            control={<Radio />}
                            label="For Each"
                          />
                        </RadioGroup>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-xl-3">
                    <div className="row row-5">
                      <div className="col-6">
                        <TextField
                          margin="dense"
                          name="labMin"
                          value={insuranceItemsInfo[0].labMin}
                          onChange={(e) => handleInsuranceDialogFields(e, 0)}
                          label="Min"
                          type="number"
                          fullWidth
                      className="textInputStyle"
                      variant="filled"
                      InputProps={{
                        className: classes.input,
                        classes: { input: classes.input },
                      }}
                      InputLabelProps={{
                        className: classes.label,
                        classes: { label: classes.label },
                      }}
                        />
                      </div>
                      <div className="col-6">
                        <TextField
                          margin="dense"
                          name="labMax"
                          value={insuranceItemsInfo[0].labMax}
                          onChange={(e) => handleInsuranceDialogFields(e, 0)}
                          label="Max"
                          type="number"
                          fullWidth
                      className="textInputStyle"
                      variant="filled"
                      InputProps={{
                        className: classes.input,
                        classes: { input: classes.input },
                      }}
                      InputLabelProps={{
                        className: classes.label,
                        classes: { label: classes.label },
                      }}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row row-5" style={{marginTop: '15px'}}>
                  <div className="col-12"><strong>Radiology</strong></div>
                  <div className="col-12 col-md-6 col-xl-5">
                    <TextField
                      margin="dense"
                      name="radiologyJod"
                      value={insuranceItemsInfo[0].radiologyJod}
                      onChange={(e) => handleInsuranceDialogFields(e, 0)}
                      label="Deductible (JOD)"
                      type="text"
                      fullWidth
                      className="textInputStyle"
                      variant="filled"
                      InputProps={{
                        className: classes.input,
                        classes: { input: classes.input },
                      }}
                      InputLabelProps={{
                        className: classes.label,
                        classes: { label: classes.label },
                      }}
                    />
                  </div>
                  <div className="col-12 col-md-6 col-xl-4">
                    <div class="row row-5 align-items-center">
                      <div className="col-12 col-md-6 col-xl-6">
                        <TextField
                          margin="dense"
                          name="radiologyCopay"
                          value={insuranceItemsInfo[0].radiologyCopay}
                          onChange={(e) => handleInsuranceDialogFields(e, 0)}
                          label="Co-Pay %"
                          type="number"
                          fullWidth
                          className="textInputStyle"
                          variant="filled"
                          InputProps={{
                            className: classes.input,
                            classes: { input: classes.input },
                            inputProps: { min: 0, max: 100 }
                          }}
                          InputLabelProps={{
                            className: classes.label,
                            classes: { label: classes.label },
                          }}
                          error={insuranceItemsInfo[0].radiologyCopay > 100 || insuranceItemsInfo[0].radiologyCopay<0}
                        />
                      </div>
                      <div className="col-12 col-md-6 col-xl-6">
                        <RadioGroup style={styles.form3}
                          row
                          aria-label="radiologyCoverageType"
                          name="radiologyCoverageType"
                          value={insuranceItemsInfo[0].radiologyCoverageType}
                          onChange={(e) => handleInsuranceDialogFields(e, 0)}
                        >
                          <FormControlLabel
                            value="All"
                            control={<Radio />}
                            label="For All"
                          />
                          <FormControlLabel
                            value="Each"
                            control={<Radio />}
                            label="For Each"
                          />
                        </RadioGroup>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-xl-3">
                    <div className="row row-5">
                      <div className="col-6">
                        <TextField
                          margin="dense"
                          name="radiologyMin"
                          value={insuranceItemsInfo[0].radiologyMin}
                          onChange={(e) => handleInsuranceDialogFields(e, 0)}
                          label="Min"
                          type="number"
                          fullWidth
                      className="textInputStyle"
                      variant="filled"
                      InputProps={{
                        className: classes.input,
                        classes: { input: classes.input },
                      }}
                      InputLabelProps={{
                        className: classes.label,
                        classes: { label: classes.label },
                      }}
                        />
                      </div>
                      <div className="col-6">
                        <TextField
                          margin="dense"
                          name="radiologyMax"
                          value={insuranceItemsInfo[0].radiologyMax}
                          onChange={(e) => handleInsuranceDialogFields(e, 0)}
                          label="Max"
                          type="number"
                          fullWidth
                      className="textInputStyle"
                      variant="filled"
                      InputProps={{
                        className: classes.input,
                        classes: { input: classes.input },
                      }}
                      InputLabelProps={{
                        className: classes.label,
                        classes: { label: classes.label },
                      }}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row row-5" style={{marginTop: '15px'}}>
                  <div className="col-12"><strong>Treatment</strong></div>
                  <div className="col-12 col-md-6 col-xl-5">
                    <TextField
                      margin="dense"
                      name="treatmentJod"
                      value={insuranceItemsInfo[0].treatmentJod}
                      onChange={(e) => handleInsuranceDialogFields(e, 0)}
                      label="Deductible (JOD)"
                      type="text"
                      fullWidth
                      className="textInputStyle"
                      variant="filled"
                      InputProps={{
                        className: classes.input,
                        classes: { input: classes.input },
                      }}
                      InputLabelProps={{
                        className: classes.label,
                        classes: { label: classes.label },
                      }}
                    />
                  </div>
                  <div className="col-12 col-md-6 col-xl-4">
                    <div class="row row-5 align-items-center">
                      <div className="col-12 col-md-6 col-xl-6">
                        <TextField
                          margin="dense"
                          name="treatmentCopay"
                          value={insuranceItemsInfo[0].treatmentCopay}
                          onChange={(e) => handleInsuranceDialogFields(e, 0)}
                          label="Co-Pay %"
                          type="number"
                          fullWidth
                          className="textInputStyle"
                          variant="filled"
                          InputProps={{
                            className: classes.input,
                            classes: { input: classes.input },
                            inputProps: { min: 0, max: 100 }
                          }}
                          InputLabelProps={{
                            className: classes.label,
                            classes: { label: classes.label },
                          }}
                          error={insuranceItemsInfo[0].treatmentCopay > 100 || insuranceItemsInfo[0].treatmentCopay<0}
                        />
                      </div>
                      <div className="col-12 col-md-6 col-xl-6">
                        <RadioGroup style={styles.form3}
                          row
                          aria-label="treatmentCoverageType"
                          name="treatmentCoverageType"
                          value={insuranceItemsInfo[0].treatmentCoverageType}
                          onChange={(e) => handleInsuranceDialogFields(e, 0)}
                        >
                          <FormControlLabel
                            value="All"
                            control={<Radio />}
                            label="For All"
                          />
                          <FormControlLabel
                            value="Each"
                            control={<Radio />}
                            label="For Each"
                          />
                        </RadioGroup>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-xl-3">
                    <div className="row row-5">
                      <div className="col-6">
                        <TextField
                          margin="dense"
                          name="treatmentMin"
                          value={insuranceItemsInfo[0].treatmentMin}
                          onChange={(e) => handleInsuranceDialogFields(e, 0)}
                          label="Min"
                          type="number"
                          fullWidth
                      className="textInputStyle"
                      variant="filled"
                      InputProps={{
                        className: classes.input,
                        classes: { input: classes.input },
                      }}
                      InputLabelProps={{
                        className: classes.label,
                        classes: { label: classes.label },
                      }}
                        />
                      </div>
                      <div className="col-6">
                        <TextField
                          margin="dense"
                          name="treatmentMax"
                          value={insuranceItemsInfo[0].treatmentMax}
                          onChange={(e) => handleInsuranceDialogFields(e, 0)}
                          label="Max"
                          type="number"
                          fullWidth
                      className="textInputStyle"
                      variant="filled"
                      InputProps={{
                        className: classes.input,
                        classes: { input: classes.input },
                      }}
                      InputLabelProps={{
                        className: classes.label,
                        classes: { label: classes.label },
                      }}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row row-5" style={{marginTop: '15px'}}>
                  <div className="col-12"><strong>Medicine</strong></div>
                  <div className="col-12 col-md-6 col-xl-5">
                    <TextField
                      margin="dense"
                      name="medicineJod"
                      value={insuranceItemsInfo[0].medicineJod}
                      onChange={(e) => handleInsuranceDialogFields(e, 0)}
                      label="Deductible (JOD)"
                      type="text"
                      fullWidth
                      className="textInputStyle"
                      variant="filled"
                      InputProps={{
                        className: classes.input,
                        classes: { input: classes.input },
                      }}
                      InputLabelProps={{
                        className: classes.label,
                        classes: { label: classes.label },
                      }}
                    />
                  </div>
                  <div className="col-12 col-md-6 col-xl-4">
                    <div class="row row-5 align-items-center">
                      <div className="col-12 col-md-6 col-xl-6">
                        <TextField
                          margin="dense"
                          name="medicineCopay"
                          value={insuranceItemsInfo[0].medicineCopay}
                          onChange={(e) => handleInsuranceDialogFields(e, 0)}
                          label="Co-Pay %"
                          type="number"
                          fullWidth
                          className="textInputStyle"
                          variant="filled"
                          InputProps={{
                            className: classes.input,
                            classes: { input: classes.input },
                            inputProps: { min: 0, max: 100 }
                          }}
                          InputLabelProps={{
                            className: classes.label,
                            classes: { label: classes.label },
                          }}
                          error={insuranceItemsInfo[0].medicineCopay > 100 || insuranceItemsInfo[0].medicineCopay<0}
                        />
                      </div>
                      <div className="col-12 col-md-6 col-xl-6">
                        <RadioGroup style={styles.form3}
                          row
                          aria-label="medicineCoverageType"
                          name="medicineCoverageType"
                          value={insuranceItemsInfo[0].medicineCoverageType}
                          onChange={(e) => handleInsuranceDialogFields(e, 0)}
                        >
                          <FormControlLabel
                            value="All"
                            control={<Radio />}
                            label="For All"
                          />
                          <FormControlLabel
                            value="Each"
                            control={<Radio />}
                            label="For Each"
                          />
                        </RadioGroup>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-xl-3">
                    <div className="row row-5">
                      <div className="col-6">
                        <TextField
                          margin="dense"
                          name="medicineMin"
                          value={insuranceItemsInfo[0].medicineMin}
                          onChange={(e) => handleInsuranceDialogFields(e, 0)}
                          label="Min"
                          type="number"
                          fullWidth
                          className="textInputStyle"
                          variant="filled"
                          InputProps={{
                            className: classes.input,
                            classes: { input: classes.input },
                          }}
                          InputLabelProps={{
                            className: classes.label,
                            classes: { label: classes.label },
                          }}
                        />
                      </div>
                      <div className="col-6">
                        <TextField
                          margin="dense"
                          name="medicineMax"
                          value={insuranceItemsInfo[0].medicineMax}
                          onChange={(e) => handleInsuranceDialogFields(e, 0)}
                          label="Max"
                          type="number"
                          fullWidth
                          className="textInputStyle"
                          variant="filled"
                          InputProps={{
                            className: classes.input,
                            classes: { input: classes.input },
                          }}
                          InputLabelProps={{
                            className: classes.label,
                            classes: { label: classes.label },
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row row-5" style={{marginTop: '15px'}}>
                  <div className="col-12"><strong>Dental</strong></div>
                  <div className="col-12 col-md-6 col-xl-5">
                    <TextField
                      margin="dense"
                      name="dentalJod"
                      value={insuranceItemsInfo[0].dentalJod}
                      onChange={(e) => handleInsuranceDialogFields(e, 0)}
                      label="Deductible (JOD)"
                      type="text"
                      fullWidth
                      className="textInputStyle"
                      variant="filled"
                      InputProps={{
                        className: classes.input,
                        classes: { input: classes.input },
                      }}
                      InputLabelProps={{
                        className: classes.label,
                        classes: { label: classes.label },
                      }}
                    />
                  </div>
                  <div className="col-12 col-md-6 col-xl-4">
                    <div class="row row-5 align-items-center">
                      <div className="col-12 col-md-6 col-xl-6">
                        <TextField
                          margin="dense"
                          name="dentalCopay"
                          value={insuranceItemsInfo[0].dentalCopay}
                          onChange={(e) => handleInsuranceDialogFields(e, 0)}
                          label="Co-Pay %"
                          type="number"
                          fullWidth
                          className="textInputStyle"
                          variant="filled"
                          InputProps={{
                            className: classes.input,
                            classes: { input: classes.input },
                            inputProps: { min: 0, max: 100 }
                          }}
                          InputLabelProps={{
                            className: classes.label,
                            classes: { label: classes.label },
                          }}
                          error={insuranceItemsInfo[0].dentalCopay > 100 || insuranceItemsInfo[0].dentalCopay<0}
                        />
                      </div>
                      <div className="col-12 col-md-6 col-xl-6">
                        <RadioGroup style={styles.form3}
                          row
                          aria-label="dentalCoverageType"
                          name="dentalCoverageType"
                          value={insuranceItemsInfo[0].dentalCoverageType}
                          onChange={(e) => handleInsuranceDialogFields(e, 0)}
                        >
                          <FormControlLabel
                            value="All"
                            control={<Radio />}
                            label="For All"
                          />
                          <FormControlLabel
                            value="Each"
                            control={<Radio />}
                            label="For Each"
                          />
                        </RadioGroup>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-xl-3">
                    <div className="row row-5">
                      <div className="col-6">
                        <TextField
                          margin="dense"
                          name="dentalMin"
                          value={insuranceItemsInfo[0].dentalMin}
                          onChange={(e) => handleInsuranceDialogFields(e, 0)}
                          label="Min"
                          type="number"
                          fullWidth
                          className="textInputStyle"
                          variant="filled"
                          InputProps={{
                            className: classes.input,
                            classes: { input: classes.input },
                          }}
                          InputLabelProps={{
                            className: classes.label,
                            classes: { label: classes.label },
                          }}
                        />
                      </div>
                      <div className="col-6">
                        <TextField
                          margin="dense"
                          name="dentalMax"
                          value={insuranceItemsInfo[0].dentalMax}
                          onChange={(e) => handleInsuranceDialogFields(e, 0)}
                          label="Max"
                          type="number"
                          fullWidth
                      className="textInputStyle"
                      variant="filled"
                      InputProps={{
                        className: classes.input,
                        classes: { input: classes.input },
                      }}
                      InputLabelProps={{
                        className: classes.label,
                        classes: { label: classes.label },
                      }}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row row-5" style={{marginTop: '15px'}}>
                  <div className="col-12"><strong>Maternity</strong></div>
                  <div className="col-12 col-md-6 col-xl-5">&nbsp;</div>
                  <div className="col-12 col-md-6 col-xl-4">
                    <TextField
                      margin="dense"
                      name="maternityCopay"
                      label="Co-Pay %"
                      value={insuranceItemsInfo[0].maternityCopay}
                      onChange={(e) => handleInsuranceDialogFields(e, 0)}
                      type="number"
                      fullWidth
                      className="textInputStyle"
                      variant="filled"
                      InputProps={{
                        className: classes.input,
                        classes: { input: classes.input },
                        inputProps: { min: 0, max: 100 }
                      }}
                      InputLabelProps={{
                        className: classes.label,
                        classes: { label: classes.label },
                      }}
                      error={insuranceItemsInfo[0].maternityCopay > 100 || insuranceItemsInfo[0].maternityCopay<0}
                    />
                  </div>
                  <div className="col-12 col-md-6 col-xl-3">
                    <div className="row row-5">
                      <div className="col-6">
                        <TextField
                          margin="dense"
                          name="maternityMin"
                          value={insuranceItemsInfo[0].maternityMin}
                          onChange={(e) => handleInsuranceDialogFields(e, 0)}
                          label="Min"
                          type="number"
                          fullWidth
                      className="textInputStyle"
                      variant="filled"
                      InputProps={{
                        className: classes.input,
                        classes: { input: classes.input },
                      }}
                      InputLabelProps={{
                        className: classes.label,
                        classes: { label: classes.label },
                      }}
                        />
                      </div>
                      <div className="col-6">
                        <TextField
                          margin="dense"
                          name="maternityMax"
                          value={insuranceItemsInfo[0].maternityMax}
                          onChange={(e) => handleInsuranceDialogFields(e, 0)}
                          label="Max"
                          type="number"
                          fullWidth
                      className="textInputStyle"
                      variant="filled"
                      InputProps={{
                        className: classes.input,
                        classes: { input: classes.input },
                      }}
                      InputLabelProps={{
                        className: classes.label,
                        classes: { label: classes.label },
                      }}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row row-5">
                  <div className="col-12 col-md-6 col-xl-3">
                    <TextField
                      margin="dense"
                      name="maxInsuranceLiability"
                      value={insuranceItemsInfo[0].maxInsuranceLiability}
                      onChange={(e) => handleInsuranceDialogFields(e, 0)}
                      label="Invoice - Max Insurance Liability"
                      type="number"
                      InputProps={{ inputProps: { min: 0 } }}
                      fullWidth
                      className="textInputStyle"
                      variant="filled"
                      InputProps={{
                        className: classes.input,
                        classes: { input: classes.input },
                      }}
                      InputLabelProps={{
                        className: classes.label,
                        classes: { label: classes.label },
                      }}
                    />
                    <TextField
                      margin="dense"
                      name="preApprovalLimit"
                      value={insuranceItemsInfo[0].preApprovalLimit}
                      onChange={(e) => handleInsuranceDialogFields(e, 0)}
                      label="Pre-Approval Limit"
                      type="number"
                      InputProps={{ inputProps: { min: 0 } }}
                      fullWidth
                      className="textInputStyle"
                      variant="filled"
                      InputProps={{
                        className: classes.input,
                        classes: { input: classes.input },
                      }}
                      InputLabelProps={{
                        className: classes.label,
                        classes: { label: classes.label },
                      }}
                    />
                    <TextField
                      margin="dense"
                      name="invoiceMaxCopay"
                      value={insuranceItemsInfo[0].invoiceMaxCopay}
                      onChange={(e) => handleInsuranceDialogFields(e, 0)}
                      label="Invoice Max Co-Pay"
                      type="number"
                      InputProps={{ inputProps: { min: 0 } }}
                      fullWidth
                      className="textInputStyle"
                      variant="filled"
                      InputProps={{
                        className: classes.input,
                        classes: { input: classes.input },
                      }}
                      InputLabelProps={{
                        className: classes.label,
                        classes: { label: classes.label },
                      }}
                    />
                    <TextField
                      margin="dense"
                      name="outPatientCopay"
                      value={insuranceItemsInfo[0].outPatientCopay}
                      onChange={(e) => handleInsuranceDialogFields(e, 0)}
                      label="Out-Patient (Co-Pay %)"
                      type="number"
                      fullWidth
                      className="textInputStyle"
                      variant="filled"
                      InputProps={{
                        className: classes.input,
                        classes: { input: classes.input },
                        inputProps: { min: 0, max: 100 }
                      }}
                      InputLabelProps={{
                        className: classes.label,
                        classes: { label: classes.label },
                      }}
                      error={insuranceItemsInfo[0].outPatientCopay > 100 || insuranceItemsInfo[0].outPatientCopay<0}
                    />
                    <TextField
                      margin="dense"
                      name="inPatientCopay"
                      value={insuranceItemsInfo[0].inPatientCopay}
                      onChange={(e) => handleInsuranceDialogFields(e, 0)}
                      label="In-Patient (Co-Pay %)"
                      type="number"
                      fullWidth
                      className="textInputStyle"
                      variant="filled"
                      InputProps={{
                        className: classes.input,
                        classes: { input: classes.input },
                        inputProps: { min: 0, max: 100 }
                      }}
                      InputLabelProps={{
                        className: classes.label,
                        classes: { label: classes.label },
                      }}
                      error={insuranceItemsInfo[0].inPatientCopay > 100 || insuranceItemsInfo[0].inPatientCopay<0}
                    />
                  </div>
                  <div className="col-12 col-md-6 col-xl-6">
                    <TextField
                      multiline
                      rows="10"
                      margin="dense"
                      name={"insuranceExclusions"}
                      label="Exclusions"
                      type="number"
                      value={insuranceItemsInfo[0].insuranceExclusions}
                      onChange={(e) => handleInsuranceDialogFields(e, 0)}
                      className="textInputStyle"
                      variant="filled"
                      InputProps={{
                        className: classes.multilineColor,
                        classes: {
                          input: classes.multilineColor,
                        },
                      }}
                      fullWidth
                    />
                  </div>
                </div>
              </DialogContent>
            </Dialog>

            <div className="row row-5">
              <div
                style={{
                  marginTop: "30px",
                  marginBottom: "1%",
                  paddingLeft: 20,
                  paddingRight: 20,
                }}
                className="col-md-6"
              >
                <Button
                  style={{
                    ...styles.stylesForButton,
                    width: matches ? 130 : "100%",
                    height: matches ? 45 : 40,
                  }}
                  onClick={onTabNavigation}
                  variant="contained"
                  color="default"
                >
                  Cancel
                </Button>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: matches ? "flex-end" : "flex-start",
                  marginTop: matches ? "30px" : 10,
                  marginBottom: "2%",
                  padding: matches ? undefined : "0px 20px",
                }}
                className="col-md-6"
              >
                <Button
                  style={{
                    ...styles.save,
                    width: matches ? 130 : "100%",
                    height: matches ? 45 : 40,
                  }}
                  // disabled={
                  //   !(validatePatientForm() && validatePaymentForm())
                  // }
                  onClick={
                    searchActivated || comingFor === "edit"
                      ? handleEdit
                      : handleAdd
                  }
                  variant="contained"
                  color="default"
                >
                  {comingFor === "edit" ? "Update" : "Save"}
                </Button>
              </div>
            </div>
          </div>
        )}
        <Notification
          msg={errorMsg}
          open={openNotification}
          success={successMsg}
        />
      </div>
    </div>
  );
}
export default PatientRegistration;
