/*eslint-disable*/
import React, { useState, useEffect, useReducer } from 'react'
import Button from '@material-ui/core/Button'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Notification from '../../components/Snackbar/Notification.js'
import CustomTable from '../../components/Table/Table'
import TextField from '@material-ui/core/TextField'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Loader from 'react-loader-spinner'
import AccountCircle from '@material-ui/icons/SearchOutlined'
import InputAdornment from '@material-ui/core/InputAdornment'
import MenuItem from '@material-ui/core/MenuItem'
import Fingerprint from '../../assets/img/fingerprint.png'
import BarCode from '../../assets/img/Bar Code.png'
import Header from '../../components/Header/Header'
import FlagManagementIcon from '../../assets/img/Tasks List.png'
import eye_icon from '../../assets/img/Eye.png'
import Back from '../../assets/img/Back_Arrow.png'
import '../../assets/jss/material-dashboard-react/components/loaderStyle.css'
import cookie from 'react-cookies'
import { styles, useStyles, useStylesForTabs } from './styles.js'
import {
  getAdmittedCall,
  getDischargedCall,
  getTransferredCall,
  getDeceasedCall,
} from './networkCallsTasks'

const tableHeadingsForAdmitted = [
  'MRN',
  'Room No',
  'Production Area',
  'Status',
  'Action',
]
const tableDataKeysForAdmitted = [
  'mrn',
  'roomNo',
  'productionArea',
  'socialWorkerStatus',
]

const tableHeadingsForDischarged = [
  'MRN',
  'Room No',
  'Production Area',
  'Status',
  'Action',
]
const tableDataKeysForDischarged = [
  'mrn',
  'roomNo',
  'productionArea',
  'socialWorkerStatus',
]

const tableHeadingsForTransferred = [
  'MRN',
  'Room No',
  'Production Area',
  'Status',
  'Action',
]
const tableDataKeysForTransferred = [
  'mrn',
  'roomNo',
  'productionArea',
  'socialWorkerStatus',
]

const tableHeadingsForDeceased = [
  'MRN',
  'Room No',
  'Production Area',
  'Status',
  'Action',
]
const tableDataKeysForDeceased = [
  'mrn',
  'roomNo',
  'productionArea',
  'socialWorkerStatus',
]

const actions = { edit: true }
const action = { view: true }

export default function SocialWorkerTasks(props) {
  const classesForTabs = useStylesForTabs()
  const classes = useStyles()
  const matches = useMediaQuery('(min-width:600px)')
  const [currentUser, setCurrentUser] = useState(cookie.load('current_user'))
  const [admittedArray, setAdmittedArray] = useState([])
  const [dischargedArray, setDischargedArray] = useState([])
  const [transferredArray, setTransferredArray] = useState([])
  const [deceasedArray, setDeceasedArray] = useState([])
  const [status, setStatus] = useState('pending')

  const [loading, setLoading] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')
  const [openNotification, setOpenNotification] = useState(false)
  const [tabName, setTabName] = useState('admitted')
  const [buttonValue, setButtonValue] = useState('pending')
  const [cookies, setCookies] = useState(cookie.load('current_user'))

  if (openNotification) {
    setTimeout(() => {
      setOpenNotification(false)
      setErrorMsg('')
    }, 2000)
  }

  useEffect(() => {
    console.log('props', props)
    getAdmittedCall(
      buttonValue,
      setAdmittedArray,
      setErrorMsg,
      setOpenNotification
    )
  }, [])

  const handleButtonChange = (buttonValue) => {
    console.log('buttonValue', buttonValue)
    setButtonValue(buttonValue)
    if (tabName === 'admitted') {
      getAdmittedCall(
        buttonValue,
        setAdmittedArray,
        setErrorMsg,
        setOpenNotification
      )
    } else if (tabName === 'discharged') {
      getDischargedCall(
        buttonValue,
        setDischargedArray,
        setErrorMsg,
        setOpenNotification
      )
    } else if (tabName === 'transferred') {
      getTransferredCall(
        buttonValue,
        setTransferredArray,
        setErrorMsg,
        setOpenNotification
      )
    } else if (tabName === 'deceased') {
      getDeceasedCall(
        buttonValue,
        setDeceasedArray,
        setErrorMsg,
        setOpenNotification
      )
    }
  }

  const handleTabChange = (e, tabName) => {
    console.log('tabName', tabName)
    setTabName(tabName)
    if (tabName === 'admitted') {
      getAdmittedCall(
        buttonValue,
        setAdmittedArray,
        setErrorMsg,
        setOpenNotification
      )
    } else if (tabName === 'discharged') {
      getDischargedCall(
        buttonValue,
        setDischargedArray,
        setErrorMsg,
        setOpenNotification
      )
    } else if (tabName === 'transferred') {
      getTransferredCall(
        buttonValue,
        setTransferredArray,
        setErrorMsg,
        setOpenNotification
      )
    } else if (tabName === 'deceased') {
      getDeceasedCall(
        buttonValue,
        setDeceasedArray,
        setErrorMsg,
        setOpenNotification
      )
    }
  }

  const handleAddSurvey = (rec) => {
    console.log('rec', rec)

    props.history.push({
      pathname: 'customerList/customersatisfactionform',
      state: {
        selectedRecord: rec,
      },
    })
  }

  const handleViewDetails = (rec) => {
    console.log('view data', rec)
    let record = rec.survey[rec.survey.length - 1]
    console.log('record', record)
    props.history.push({
      pathname: 'viewsurvey',
      state: {
        selectedRecord: record,
      },
    })
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        position: 'fixed',
        width: '100%',
        height: '100%',
        backgroundColor: '#60d69f',
        overflowY: 'scroll',
      }}
    >
      <Header history={props.history} />

      <div className='cPadding'>
        <div className='subheader'>
          <div>
            <img src={FlagManagementIcon} style={{ marginLeft: '0px' }} />
            <h4>Tasks List</h4>
          </div>

          {buttonValue === 'pending' ? (
            <Button
              onClick={() => handleButtonChange('completed')}
              style={{
                ...styles.stylesForButton,
                fontSize: matches ? ' ' : 10,
              }}
              variant='contained'
              color='primary'
            >
              <img src={eye_icon} className='icon-view' />
              &nbsp;&nbsp;
              <strong>Completed Tasks</strong>
            </Button>
          ) : (
            <Button
              onClick={() => handleButtonChange('pending')}
              style={{
                ...styles.stylesForButton,
                fontSize: matches ? ' ' : 10,
              }}
              variant='contained'
              color='primary'
            >
              <img src={eye_icon} className='icon-view' />
              &nbsp;&nbsp;
              <strong>Pending Tasks</strong>
            </Button>
          )}
        </div>

        <div
          className='container-fluid'
          style={{ paddingLeft: 0, paddingRight: 0 }}
        >
          <div className={classesForTabs.root}>
            <Tabs
              classes={{
                root: classesForTabs.root,
                scroller: classesForTabs.scroller,
              }}
              value={tabName}
              onChange={handleTabChange}
              textColor='primary'
              TabIndicatorProps={{ style: { background: '#12387a' } }}
              centered
            >
              <Tab
                style={{
                  color: 'white',
                  borderRadius: 5,
                  outline: 'none',
                  color: tabName === 'admitted' ? '#12387a' : '#3B988C',
                }}
                label='Admitted'
                value='admitted'
              />
              <Tab
                style={{
                  color: 'white',
                  borderRadius: 5,
                  outline: 'none',
                  color: tabName === 'discharged' ? '#12387a' : '#3B988C',
                }}
                label='Discharged'
                value='discharged'
              />
              <Tab
                style={{
                  color: 'white',
                  borderRadius: 5,
                  outline: 'none',
                  color: tabName === 'transferred' ? '#12387a' : '#3B988C',
                }}
                label='Transferred'
                value='transferred'
              />
              <Tab
                style={{
                  color: 'white',
                  borderRadius: 5,
                  outline: 'none',
                  color: tabName === 'deceased' ? '#12387a' : '#3B988C',
                }}
                label='Deceased'
                value='deceased'
              />
            </Tabs>
          </div>

          <div className='row'>
            {admittedArray && tabName === 'admitted' ? (
              <div className='col-md-12 col-sm-12 col-12'>
                {buttonValue === 'pending' ? (
                  <CustomTable
                    tableData={admittedArray}
                    handleEdit={handleAddSurvey}
                    tableDataKeys={tableDataKeysForAdmitted}
                    tableHeading={tableHeadingsForAdmitted}
                    action={actions}
                    borderBottomColor={'#60d69f'}
                    borderBottomWidth={20}
                  />
                ) : buttonValue === 'completed' ? (
                  <CustomTable
                    tableData={admittedArray}
                    handleView={handleViewDetails}
                    tableDataKeys={tableDataKeysForAdmitted}
                    tableHeading={tableHeadingsForAdmitted}
                    action={action}
                    borderBottomColor={'#60d69f'}
                    borderBottomWidth={20}
                  />
                ) : loading ? (
                  <div style={{ margin: 'auto', width: '0%', padding: 10 }}>
                    <Loader
                      type='TailSpin'
                      color='blue'
                      height={50}
                      width={50}
                    />
                  </div>
                ) : (
                  undefined
                )}
              </div>
            ) : dischargedArray && tabName === 'discharged' ? (
              <div className='col-md-12 col-sm-12 col-12'>
                {loading ? (
                  <div style={{ margin: 'auto', width: '0%', padding: 10 }}>
                    <Loader
                      type='TailSpin'
                      color='blue'
                      height={50}
                      width={50}
                    />
                  </div>
                ) : buttonValue === 'pending' ? (
                  <CustomTable
                    tableData={dischargedArray}
                    tableDataKeys={tableDataKeysForDischarged}
                    tableHeading={tableHeadingsForDischarged}
                    action={actions}
                    handleEdit={handleAddSurvey}
                    borderBottomColor={'#60d69f'}
                    borderBottomWidth={20}
                  />
                ) : buttonValue === 'completed' ? (
                  <CustomTable
                    tableData={dischargedArray}
                    tableDataKeys={tableDataKeysForDischarged}
                    tableHeading={tableHeadingsForDischarged}
                    action={action}
                    handleView={handleViewDetails}
                    borderBottomColor={'#60d69f'}
                    borderBottomWidth={20}
                  />
                ) : (
                  undefined
                )}
              </div>
            ) : transferredArray && tabName === 'transferred' ? (
              <div className='col-md-12 col-sm-12 col-12'>
                {loading ? (
                  <div style={{ margin: 'auto', width: '0%', padding: 10 }}>
                    <Loader
                      type='TailSpin'
                      color='blue'
                      height={50}
                      width={50}
                    />
                  </div>
                ) : buttonValue === 'pending' ? (
                  <CustomTable
                    tableData={transferredArray}
                    tableDataKeys={tableDataKeysForTransferred}
                    tableHeading={tableHeadingsForTransferred}
                    action={actions}
                    handleEdit={handleAddSurvey}
                    borderBottomColor={'#60d69f'}
                    borderBottomWidth={20}
                  />
                ) : buttonValue === 'completed' ? (
                  <CustomTable
                    tableData={transferredArray}
                    tableDataKeys={tableDataKeysForTransferred}
                    tableHeading={tableHeadingsForTransferred}
                    action={action}
                    handleView={handleViewDetails}
                    borderBottomColor={'#60d69f'}
                    borderBottomWidth={20}
                  />
                ) : (
                  undefined
                )}
              </div>
            ) : deceasedArray && tabName === 'deceased' ? (
              <div className='col-md-12 col-sm-12 col-12'>
                {loading ? (
                  <div style={{ margin: 'auto', width: '0%', padding: 10 }}>
                    <Loader
                      type='TailSpin'
                      color='blue'
                      height={50}
                      width={50}
                    />
                  </div>
                ) : buttonValue === 'pending' ? (
                  <CustomTable
                    tableData={deceasedArray}
                    tableDataKeys={tableDataKeysForDeceased}
                    tableHeading={tableHeadingsForDeceased}
                    action={actions}
                    handleEdit={handleAddSurvey}
                    borderBottomColor={'#60d69f'}
                    borderBottomWidth={20}
                  />
                ) : buttonValue === 'completed' ? (
                  <CustomTable
                    tableData={deceasedArray}
                    tableDataKeys={tableDataKeysForDeceased}
                    tableHeading={tableHeadingsForDeceased}
                    action={action}
                    handleView={handleViewDetails}
                    borderBottomColor={'#60d69f'}
                    borderBottomWidth={20}
                  />
                ) : (
                  undefined
                )}
              </div>
            ) : (
              <div className='LoaderStyle'>
                <Loader type='TailSpin' color='red' height={50} width={50} />
              </div>
            )}
          </div>

          {/* <h4 style={{ textAlign: 'center' }}>
            Module is under development for now
          </h4> */}

          <div className='row' style={{ marginBottom: 20 }}>
            <div className='col-md-12 col-sm-12 col-12'>
              <img
                onClick={() => props.history.goBack()}
                src={Back}
                style={{
                  width: 45,
                  height: 35,
                  cursor: 'pointer',
                }}
              />
            </div>
          </div>
        </div>

        <Notification msg={errorMsg} open={openNotification} />
      </div>
    </div>
  )
}
