import React, { useEffect, useState } from 'react'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Flip from '../../../components/Dial/Flip'
import TwoValue from '../../../components/Dial/TwoValue'
import OneValue from '../../../components/Dial/OneValue'
import { customerCareDashboard } from '../../../public/endpoins'
import {
  getDialerGraphCall,
  getValuesCardCalls,
  getResponse,
} from './NetworkCalls'
import Notification from '../../../components/Snackbar/Notification.js'
import Loader from 'react-loader-spinner'
import {
  socketUrl
} from '../../../public/endpoins'
import socketIOClient from 'socket.io-client'

export default function CustomerCare() {
  const matches = useMediaQuery('(min-width:600px)')
  const [errorMsg, setErrorMsg] = useState('')
  const [successMsg, setsuccessMsg] = useState('')
  const [openNotification, setOpenNotification] = useState(false)

  const [responseData, setResponseData] = useState([])
  const [isLoading, setisLoading] = useState(true)

  // Patient Transfer from ED to EOU
  const [patientEDToEOU, setPatientEDToEOU] = useState('')
  const [patientEDToEOUColor, setPatientEDToEOUColor] = useState('')
  const [patientEDToEOUTAT, setPatientEDToEOUTAT] = useState('')
  const [patientEDToEOUTATColor, setPatientEDToEOUTATColor] = useState('')
  const [patientEDToEOUGraphData, setPatientEDToEOUGraphData] = useState('')

  // Patient Transfer from Ambulance to Bed
  const [ambulanceToBed, setAmbulanceToBed] = useState('')
  const [ambulanceToBedColor, setAmbulanceToBedColor] = useState('')
  const [ambulanceToBedTAT, setAmbulanceToBedTAT] = useState('')
  const [ambulanceToBedTATColor, setAmbulanceToBedTATColor] = useState('')
  const [ambulanceToBedGraphData, setAmbulanceToBedGraphData] = useState('')

  // Pharma Transfer to ED EOU
  const [pharmaToEDEOU, setPharmaToEDEOU] = useState('')
  const [pharmaToEDEOUColor, setPharmaToEDEOUColor] = useState('')
  const [pharmaToEDEOUTAT, setPharmaToEDEOUTAT] = useState('')
  const [pharmaToEDEOUTATColor, setPharmaToEDEOUTATColor] = useState('')
  const [pharmaToEDEOUGraphData, setPharmaToEDEOUGraphData] = useState('')

  // Discharge trasnfer pending
  const [dischargesTransfer, setDischargesTransfer] = useState('')
  const [dischargesTransferColor, setDischargesTransferColor] = useState('')
  const [dischargesTransferTAT, setDischargesTransferTAT] = useState('')
  const [dischargesTransferTATColor, setDischargesTransferTATColor] = useState(
    ''
  )
  const [
    dischargesTransferGraphData,
    setDischargesTransferGraphData,
  ] = useState('')


  // Average No. of tasks
  const [averageTasks, setAverageTasks] = useState('')
  const [averageTasksColor, setAverageTasksColor] = useState('')
  const [averageTasksTAT, setAverageTasksTAT] = useState('')
  const [averageTasksTATColor, setAverageTasksTATColor] = useState('')
  const [averageTasksGraphData, setAverageTasksGraphData] = useState('')

  // Values cards
  const [comulativeTasks, setComulativeTasks] = useState('')
  const [comulativeTasksColor, setComulativeTasksColor] = useState('')

  const [flip, setFlip] = useState(false)
  const [dummyData, setDummyData] = useState('')

  const [flag2Count, setFlag2Count] = useState(0)
  const [flag3Count, setFlag3Count] = useState(0)
  const [flag4Count, setFlag4Count] = useState(0)
  const [flag5Count, setFlag5Count] = useState(0)

  useEffect(() => {
    getResponse(
      customerCareDashboard,
      setResponseData,
      setOpenNotification,
      setErrorMsg,
      setisLoading
    )
    console.log('*in getResponse edDoctor')

    const flagCount = [
    { value: '2nd', setFlagCount: (value) => setFlag2Count(value) },
    { value: '3rd', setFlagCount: (value) => setFlag3Count(value) },
    { value: '4th', setFlagCount: (value) => setFlag4Count(value) },
    { value: '5th', setFlagCount: (value) => setFlag5Count(value) }]


    const socket = socketIOClient(socketUrl)
    socket.emit('cc_flags')
    socket.on('ccPending', (data) => {
      console.log('response coming through socket for Customer Care Flags', data)
      flagCount.forEach(({ value, setFlagCount }) => {
        const cardCount = data.filter(item => item.card === value).length;
        cardCount && setFlagCount(cardCount);
      })

      console.log('data', data)

    })
  }, [])

  if (openNotification) {
    setTimeout(() => {
      setOpenNotification(false)
      setErrorMsg('')
      setsuccessMsg('')
    }, 2000)
  }

  useEffect(() => {
    let arr = []

    Object.entries(responseData).map(([key, val]) => {
      if (key !== 'success') {
        console.log('key of data', key, 'val of data', val)
        arr.push({ [key]: val })
      }
    })

    console.log('arr::', arr)
    if (arr && arr.length > 0) {
      getDialerGraphCall(
        35,
        65,
        35,
        65,
        arr[0].firstCard,
        setPatientEDToEOU,
        setPatientEDToEOUColor,
        setPatientEDToEOUTAT,
        setPatientEDToEOUTATColor,
        setPatientEDToEOUGraphData
      )
      getDialerGraphCall(
        2,
        4,
        3,
        5,
        arr[1].secondCard,
        setAmbulanceToBed,
        setAmbulanceToBedColor,
        setAmbulanceToBedTAT,
        setAmbulanceToBedTATColor,
        setAmbulanceToBedGraphData
      )
      getDialerGraphCall(
        2,
        5,
        5,
        8,
        arr[2].thirdCard,
        setPharmaToEDEOU,
        setPharmaToEDEOUColor,
        setPharmaToEDEOUTAT,
        setPharmaToEDEOUTATColor,
        setPharmaToEDEOUGraphData
      )
      getDialerGraphCall(
        2,
        4,
        10,
        20,
        arr[3].fifthCard,
        setDischargesTransfer,
        setDischargesTransferColor,
        setDischargesTransferTAT,
        setDischargesTransferTATColor,
        setDischargesTransferGraphData
      )
      getDialerGraphCall(
        20,
        40,
        35,
        65,
        arr[4].seventhCard,
        setAverageTasks,
        setAverageTasksColor,
        setAverageTasksTAT,
        setAverageTasksTATColor,
        setAverageTasksGraphData
      )

      getValuesCardCalls(
        150,
        260,
        arr[5].cumulativeTasks,
        setComulativeTasks,
        setComulativeTasksColor
      )
    }
  }, [responseData])

  useEffect(() => {
    let temp = [
      { label: '2021-02-16T12:48:26.948Z', value: 5 },
      { label: '2021-02-16T11:48:26.948Z', value: 4 },
      { label: '2021-02-16T10:48:26.948Z', value: 10 },
      { label: '2021-02-16T09:48:26.948Z', value: 12 },
      { label: '2021-02-16T08:48:26.948Z', value: 9 },
      { label: '2021-02-16T07:48:26.948Z', value: 1 },
    ]

    setDummyData(temp)
  }, [])

  const flipIt = () => {
    setFlip(true)
  }
  return (
    <div className='container-fluid' style={{ marginBottom: 10 }}>
      <div className='row'>
        <div
          className='col-md-4 col-6'
          style={{ paddingLeft: 4, paddingRight: 4, marginTop: 10 }}
        >
          <div>
            <Flip
              flipped={!flip}
              flipHandler={flipIt}
              mainHeading={'Patient Transfer from ED to EOU Pending'}
              value={
                isLoading ? (
                  <Loader
                    type='TailSpin'
                    color='green'
                    height={35}
                    width={35}
                  />
                ) : (
                  patientEDToEOU
                )
              }
              mainHeadingForGraph='Patient Transfer from ED to EOU Pending'
              color={patientEDToEOUColor}
              subHeading={'TAT'}
              childHeading={'Request to Completion'}
              time={
                isLoading ? (
                  <Loader
                    type='TailSpin'
                    color='green'
                    height={35}
                    width={35}
                  />
                ) : patientEDToEOUTAT === null ? (
                  0
                ) : (
                  patientEDToEOUTAT
                )
              }
              timeColor={patientEDToEOUTATColor}
              idForGraph={'container1'}
              data={patientEDToEOUGraphData}
            />
          </div>
        </div>

        <div
          className='col-md-4 col-6'
          style={{ paddingLeft: 4, paddingRight: 4, marginTop: 10 }}
        >
          <div>
            <Flip
              flipped={!flip}
              flipHandler={flipIt}
              mainHeading={'Patient Transfers from Ambulance to Bed'}
              value={
                isLoading ? (
                  <Loader
                    type='TailSpin'
                    color='green'
                    height={35}
                    width={35}
                  />
                ) : (
                  ambulanceToBed
                )
              }
              flagsCount={flag2Count}
              mainHeadingForGraph='Patient Transfers from Ambulance to Bed'
              color={ambulanceToBedColor}
              subHeading={'TAT'}
              childHeading={'Request to Completion'}
              time={
                isLoading ? (
                  <Loader
                    type='TailSpin'
                    color='green'
                    height={35}
                    width={35}
                  />
                ) : ambulanceToBedTAT === null ? (
                  0
                ) : (
                  ambulanceToBedTAT
                )
              }
              timeColor={ambulanceToBedTATColor}
              idForGraph={'container2'}
              data={ambulanceToBedGraphData}
            />
          </div>
        </div>

        <div
          className='col-md-4 col-6'
          style={{ paddingLeft: 4, paddingRight: 4, marginTop: 10 }}
        >
          <div>
            <Flip
              flipped={!flip}
              flipHandler={flipIt}
              mainHeading={'Pharma Transfers to ED/EOU Pending'}
              value={
                isLoading ? (
                  <Loader
                    type='TailSpin'
                    color='green'
                    height={35}
                    width={35}
                  />
                ) : (
                  pharmaToEDEOU
                )
              }
              flagsCount={flag3Count}
              mainHeadingForGraph='Pharma Transfers to ED/EOU Pending'
              color={pharmaToEDEOUColor}
              subHeading={'TAT'}
              childHeading={'Request to Completion'}
              time={
                isLoading ? (
                  <Loader
                    type='TailSpin'
                    color='green'
                    height={35}
                    width={35}
                  />
                ) : pharmaToEDEOUTAT === null ? (
                  0
                ) : (
                  pharmaToEDEOUTAT
                )
              }
              timeColor={pharmaToEDEOUTATColor}
              idForGraph={'container3'}
              data={pharmaToEDEOUGraphData}
            />
          </div>
        </div>
        {/* </div> */}

        {/* <div className='row'> */}
        <div
          className='col-md-4 col-6'
          style={{ paddingLeft: 4, paddingRight: 4, marginTop: 10 }}
        >
          <div>
            <Flip
              flipped={!flip}
              flipHandler={flipIt}
              mainHeading={'Patient Transfers from ED to IP Pending'}
              value={
                isLoading ? (
                  <Loader
                    type='TailSpin'
                    color='green'
                    height={35}
                    width={35}
                  />
                ) : (
                  80
                )
              }
              flagsCount={flag4Count}
              mainHeadingForGraph='Patient Transfers from ED to IP Pending'
              color='#FF0000'
              subHeading={'TAT'}
              childHeading={'Request to Completion'}
              time={
                isLoading ? (
                  <Loader
                    type='TailSpin'
                    color='green'
                    height={35}
                    width={35}
                  />
                ) : (
                  '15'
                )
              }
              idForGraph={'container4'}
              data={dummyData}
            />
          </div>
        </div>

        <div
          className='col-md-4 col-6'
          style={{ paddingLeft: 4, paddingRight: 4, marginTop: 10 }}
        >
          <div>
            <Flip
              flipped={!flip}
              flipHandler={flipIt}
              mainHeading={'Discharge/Disposition Transfers Pending'}
              value={
                isLoading ? (
                  <Loader
                    type='TailSpin'
                    color='green'
                    height={35}
                    width={35}
                  />
                ) : (
                  dischargesTransfer
                )
              }
              flagsCount={flag5Count}
              mainHeadingForGraph='Discharge/Disposition Transfers Pending'
              color={dischargesTransferColor}
              subHeading={'TAT'}
              childHeading={'Request to Completion'}
              time={
                isLoading ? (
                  <Loader
                    type='TailSpin'
                    color='green'
                    height={35}
                    width={35}
                  />
                ) : dischargesTransferTAT === null ? (
                  0
                ) : (
                  dischargesTransferTAT
                )
              }
              timeColor={dischargesTransferTATColor}
              idForGraph={'container5'}
              data={dischargesTransferGraphData}
            />
          </div>
        </div>

        <div
          className='col-md-4 col-6'
          style={{ paddingLeft: 4, paddingRight: 4, marginTop: 10 }}
        >
          <div>
            <Flip
              flipped={!flip}
              flipHandler={flipIt}
              mainHeading={'Patient Surveys Pending'}
              value={
                isLoading ? (
                  <Loader
                    type='TailSpin'
                    color='green'
                    height={35}
                    width={35}
                  />
                ) : (
                  80
                )
              }
              mainHeadingForGraph='Patient Surveys Pending'
              color='#FF0000'
              subHeading={'TAT'}
              childHeading={'Available to Completion'}
              time={
                isLoading ? (
                  <Loader
                    type='TailSpin'
                    color='green'
                    height={35}
                    width={35}
                  />
                ) : (
                  '15'
                )
              }
              idForGraph={'container6'}
              data={dummyData}
            />
          </div>
        </div>
        {/* </div> */}

        {/* <div className='row'> */}
        <div
          className='col-md-4 col-6'
          style={{ paddingLeft: 4, paddingRight: 4, marginTop: 10 }}
        >
          <div>
            <Flip
              flipped={!flip}
              flipHandler={flipIt}
              mainHeading={'Average No. of Tasks/Hour'}
              value={
                isLoading ? (
                  <Loader
                    type='TailSpin'
                    color='green'
                    height={35}
                    width={35}
                  />
                ) : (
                  averageTasks
                )
              }
              mainHeadingForGraph='Average No. of Tasks/Hour'
              color={averageTasksColor}
              subHeading={'TAT'}
              childHeading={'Per Task'}
              time={
                isLoading ? (
                  <Loader
                    type='TailSpin'
                    color='green'
                    height={35}
                    width={35}
                  />
                ) : averageTasksTAT === null ? (
                  0
                ) : (
                  averageTasksTAT
                )
              }
              timeColor={averageTasksTATColor}
              idForGraph={'container8'}
              data={averageTasksGraphData}
            />
          </div>
        </div>

        <div
          className='col-md-4 col-6'
          style={{ paddingLeft: 4, paddingRight: 4, marginTop: 10 }}
        >
          <div>
            <OneValue
              time={
                isLoading ? (
                  <Loader
                    type='TailSpin'
                    color='green'
                    height={35}
                    width={35}
                  />
                ) : (
                  comulativeTasks
                )
              }
              colorTime={comulativeTasksColor}
              heading={'Cumulative total Tests'}
            />
          </div>
        </div>

        <div
          className='col-md-4 col-6'
          style={{ paddingLeft: 4, paddingRight: 4, marginTop: 10 }}
        >
          <div>
            <OneValue
              time={
                isLoading ? (
                  <Loader
                    type='TailSpin'
                    color='green'
                    height={35}
                    width={35}
                  />
                ) : (
                  20 + '%'
                )
              }
              colorTime={'#13D59F'}
              heading={'Customer Satisfaction Rating'}
            />
          </div>
        </div>
      </div>

      <Notification
        msg={errorMsg}
        open={openNotification}
        success={successMsg}
      />
    </div>
  )
}
