import React, { useEffect, useState, useReducer } from "react";
import cookie from "react-cookies";
import Button from "@material-ui/core/Button";
import Loader from "react-loader-spinner";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import Header from "../../components/Header/Header";
import Back from "../../assets/img/Back_Arrow.png";
import "../../assets/jss/material-dashboard-react/components/TextInputStyle.css";
import CustomTable from "../../components/Table/Table";
import Notification from "../../components/Snackbar/Notification.js";
import LabIcon from "../../assets/img/RL.png";
import RadIcon from "../../assets/img/Rad.png";
import PharmIcon from "../../assets/img/Pharma.png";
import useStylesForInput from "../../assets/jss/material-dashboard-react/inputStyle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { styles } from "./styles.js";
// import { updatePharmRequest } from "./networkCalls";
import {
  searchCompletedLabEdr,
  searchEdrWithCareStream,
  searchCompletedRadEdr,
  searchGetPharmacy,
  searchDischargedEDR,
} from "../../public/endpoins";
import axios from "axios";

const tableHeadingForRequest = [
  "Medicine Name",
  "Date / Time",
  //   "Test Name",
  "Item Type",
  "Total Price",
  "Status",
  " ",
  //   "Action",
];
const tableDataKeysForRequest = [
  "medicineName",
  "date",
  //   "name",
  "itemType",
  "totalPrice",
  "status",
];
const tableHeadingForPharmRequest = [
  "Request ID",
  "Date / Time",
  "Status",
  "Action",
];
const tableDataKeysForPharmRequest = [
  "pharmacyRequestNo",
  "createdAt",
  "status",
];
const actions = { view: true };
const actionsForPharma = { view: true, edit: true };

export const statusArray = [
  {
    key: "Delivery In Progress",
    value: "delivery_in_progress",
  },
  {
    key: "Delivered",
    value: "delivered",
  },
  {
    key: "Closed",
    value: "closed",
  },
];

export default function ViewServicesRequests(props) {
  const classes = useStylesForInput();
  const matches = useMediaQuery("(min-width:600px)");

  const initialState = {
    mrn: "----",
    patientName: "-- --",
    gender: "--",
    age: "--",
    weight: "--",
    diagnosisArray: [],
    medicationArray: [],
    requestStatus: "",
  };

  function reducer(state, { field, value }) {
    return {
      ...state,
      [field]: value,
    };
  }

  const [state, dispatch] = useReducer(reducer, initialState);

  const {
    mrn,
    patientName,
    gender,
    age,
    weight,
    diagnosisArray,
    medicationArray,
    requestStatus,
  } = state;

  const onChangeValue = (e) => {
    dispatch({
      field: e.target.name,
      value: e.target.value,
    });
  };

  const [currentUser] = useState(cookie.load("current_user"));
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setsuccessMsg] = useState("");
  const [openNotification, setOpenNotification] = useState(false);
  const [requestData, setRequestData] = useState([]);
  const [pathName, setPathName] = useState("");
  const [patientData, setPatientData] = useState({});
  const [openDialog, setOpenDialog] = useState("");
  const [selectedReqToEdit, setSelectedReqToEdit] = useState("");
  const [edrId, setedrId] = useState("");
  const [updateStatus, setUpdateStatus] = useState("");

  useEffect(() => {
    console.log("Props::", props);
    if (props.location.state.comingFrom === "notification") {
      axios
        .get(searchDischargedEDR + "/" + props.location.state.edrId)
        .then((res) => {
          if (res.data.success) {
            if (res.data.data[0]) {
              res.data.data[0].dischargeRequest.dischargeMedication.medicine.map(
                (item) => (
                  (res.data.data[0].dischargeRequest.dischargeMedication.medicineName =
                    item.medicineName),
                  (res.data.data[0].dischargeRequest.dischargeMedication.itemType =
                    item.itemType),
                  (res.data.data[0].dischargeRequest.dischargeMedication.totalPrice =
                    item.totalPrice)
                )
              );
              setRequestData([
                res.data.data[0].dischargeRequest.dischargeMedication,
              ]);

              Object.entries(res.data.data[0]).map(([key, val]) => {
                if (key === "patientId") {
                  Object.entries(val).map(([key1, val1]) => {
                    if (key1 === "name") {
                      dispatch({ field: "patientName", value: val1[0].family });
                    } else if (key1 === "identifier") {
                      dispatch({
                        field: "mrn",
                        value: val1[0].value,
                      });
                    }
                    dispatch({ field: key1, value: val1 });
                  });
                }
              });
              res.data.data[0].patientId.patientName =
                res.data.data[0].patientId.name[0].family;
              res.data.data[0].patientId.mrn =
                res.data.data[0].patientId.identifier[0].value;
              setPatientData(res.data.data[0].patientId);
              setedrId(res.data.data[0]._id);
            }
          } else if (!res.data.success) {
            setOpenNotification(true);
          }
        })
        .catch((e) => {
          setOpenNotification(true);
          setErrorMsg("Error while submit Lab Service request");
        });
    } else {
      let propData = props.history.location.state;

      setPathName(propData.path);
      setPatientData(propData.selectedRecord);
      setedrId(propData.selectedRecord._id);

      Object.entries(propData.selectedRecord).map(([key, val]) => {
        if (key === "patientId") {
          Object.entries(val).map(([key1, val1]) => {
            dispatch({ field: key1, value: val1 });
          });
        } else if (key === "dischargeRequest") {
          val.dischargeMedication.medicine.map(
            (item) => (
              (val.dischargeMedication.medicineName = item.medicineName),
              (val.dischargeMedication.itemType = item.itemType),
              (val.dischargeMedication.totalPrice = item.totalPrice)
            )
          );
          console.log("vall:::");
          setRequestData([val.dischargeMedication]);
        } else {
          dispatch({ field: key, value: val });
        }
      });
    }
  }, []);

  console.log("setRequestData:", requestData);

  const handleView = (rec) => {
    console.log("rec::", rec);
    let path;
    if (pathName === "pharma") {
      path = "viewpharma/detailedpharma";
    } else if (
      pathName === "lab" ||
      props.history.location.pathname ===
        "/dashboard/home/patientmanagement/viewrequests/lab/viewlab"
    ) {
      path = "viewlab/detailedlab";
    } else if (
      pathName === "rad" ||
      props.history.location.pathname ===
        "/dashboard/home/patientmanagement/viewrequests/rad/viewrad"
    ) {
      path = "viewrad/detailedrad";
    }
    props.history.push({
      pathname: path,
      state: {
        selectedItem: rec,
        selectedPatient: patientData,
        path: pathName,
      },
    });
  };

  const handleEdit = (rec) => {
    if (rec.status === "pending" || rec.status === "in_progress") {
      setOpenNotification(true);
      setErrorMsg("You cannot edit this request");
    } else if (rec.status === "closed") {
      setOpenNotification(true);
      setErrorMsg("Cannot edit a closed request");
    } else {
      console.log("record to edit : ", rec);
      setOpenDialog(true);
      setSelectedReqToEdit(rec);
      setUpdateStatus(rec.status);
      dispatch({ field: "requestStatus", value: rec.status });
    }
  };

  function hideDialog() {
    setOpenDialog(false);
    setSelectedReqToEdit("");
  }

  const handleEditPharmReq = () => {
    let object = {
      edrId,
      requestId: selectedReqToEdit._id,
      status: requestStatus,
    };
    console.log("Params for edit : ", object);
    // updatePharmRequest(
    //   object,
    //   hideDialog,
    //   props,
    //   setOpenNotification,
    //   setErrorMsg
    // );
  };

  function validateStatusChange() {
    if (requestStatus === "" || requestStatus === updateStatus) {
      return true;
    }
  }

  if (openNotification) {
    setTimeout(() => {
      setOpenNotification(false);
      setErrorMsg("");
      setsuccessMsg("");
    }, 2000);
  }

  return (
    <div
      style={{
        backgroundColor: "rgb(19 213 159)",
        position: "fixed",
        display: "flex",
        width: "100%",
        height: "100%",
        flexDirection: "column",
        flex: 1,
        overflowY: "scroll",
      }}
    >
      <Header history={props.history} />
      <div className="cPadding">
        <div className="subheader">
          {pathName === "rad" ? (
            <div>
              <img src={RadIcon} />
              <h4>Radiology Service Requests</h4>
            </div>
          ) : pathName === "lab" ? (
            <div>
              <img src={LabIcon} />
              <h4>Lab Service Requests</h4>
            </div>
          ) : pathName === "pharma" ? (
            <div>
              <img src={PharmIcon} />
              <h4>Pharmacy Service Requests</h4>
            </div>
          ) : (
            undefined
          )}
        </div>

        <div className="container-fluid">
          <h5 style={{ fontWeight: "bold", color: "white", marginTop: 25 }}>
            Patient Details
          </h5>

          <div
            style={{
              marginTop: 25,
              backgroundColor: "white",
              borderRadius: 5,
              width: "100%",
              maxHeight: "300px",
            }}
          >
            <div
              className="row"
              style={{
                backgroundColor: "#2C6DDD",
                paddingLeft: 10,
                height: "30%",
                borderTopLeftRadius: 5,
                borderTopRightRadius: 5,
                paddingBottom: 10,
                paddingTop: 10,
                marginLeft: 0,
                marginRight: 0,
                minWidth: 600,
              }}
            >
              <div
                className={"col-md-3 col-sm-3 col-3"}
                style={styles.headerHeading}
              >
                <h6
                  className="pat-det-heading"
                  style={{ color: "white", fontWeight: "700" }}
                >
                  Patient Info
                </h6>
              </div>
              <div
                className={"col-md-3 col-sm-3 col-3"}
                style={styles.headerHeading}
              >
                <h6
                  className="pat-det-heading"
                  style={{ color: "white", fontWeight: "700" }}
                >
                  Allergy
                </h6>
              </div>
              <div
                className={"col-md-3 col-sm-3 col-3"}
                style={styles.headerHeading}
              >
                <h6
                  className="pat-det-heading"
                  style={{ color: "white", fontWeight: "700" }}
                >
                  Medication
                </h6>
              </div>
              <div
                className={"col-md-3 col-sm-3 col-3"}
                style={styles.headerHeading}
              >
                <h6
                  className="pat-det-heading"
                  style={{ color: "white", fontWeight: "700" }}
                >
                  Diagnosis
                </h6>
              </div>
            </div>

            <div
              className="row hide"
              style={{
                marginTop: 10,
                paddingLeft: 10,
                height: "80%",
                paddingBottom: 10,
                minWidth: 600,
                overflow: "scroll",
              }}
            >
              <div
                className={"col-md-3 col-sm-3 col-3"}
                style={{ display: "flex", flexDirection: "column" }}
              >
                <span style={styles.headingStyles}>MRN</span>
                <span style={styles.textStyles} className="mrnUpperCase">
                  {mrn}
                </span>

                <span style={styles.headingStyles}>Patient</span>
                <span style={styles.textStyles}>{patientName}</span>

                <span style={styles.headingStyles}>Gender</span>
                <span style={styles.textStyles}>{gender}</span>

                <span style={styles.headingStyles}>Age</span>
                <span style={styles.textStyles}>{age}</span>

                <span style={styles.headingStyles}>Weight</span>
                <span style={styles.textStyles}>{weight} kg</span>
              </div>

              <div
                className={"col-md-3 col-sm-3 col-3"}
                style={styles.textStyles}
              >
                {""}
              </div>

              <div
                className={"col-md-3 col-sm-3 col-3"}
                style={styles.textStyles}
              >
                {medicationArray
                  ? medicationArray.map((d, index) => {
                      return (
                        <div style={{ display: "flex", flexDirection: "row" }}>
                          <h6
                            style={{
                              ...styles.textStyles,
                            }}
                          >
                            {index + 1}
                            {"."} &nbsp;
                          </h6>
                          <h6
                            style={{
                              ...styles.textStyles,
                            }}
                          >
                            {d}
                          </h6>
                        </div>
                      );
                    })
                  : ""}
              </div>

              <div
                className={"col-md-3 col-sm-3 col-3 "}
                style={{ ...styles.textStyles }}
              >
                {diagnosisArray
                  ? diagnosisArray.map((drug, index) => {
                      return (
                        <h6 style={styles.textStyles}>
                          {index + 1}. {drug}
                        </h6>
                      );
                    })
                  : ""}
              </div>
            </div>
          </div>
        </div>

        <hr
          style={{
            margin: "50px 15px 26px 15px",
            borderTop: "5px solid #4FDFB7",
          }}
        />

        <div className="container-fluid">
          <div className="row">
            {requestData.length > 0 ? (
              <div className="col-md-12 col-sm-12 col-12">
                <CustomTable
                  tableData={requestData}
                  tableDataKeys={tableDataKeysForRequest}
                  tableHeading={tableHeadingForRequest}
                  //   action={
                  //     pathName === "pharma" &&
                  //     currentUser.subType.includes("ED Nurse")
                  //       ? actionsForPharma
                  //       : actions
                  //   }
                  //   handleView={handleView}
                  //   handleEdit={handleEdit}
                  borderBottomColor={"#60d69f"}
                  borderBottomWidth={20}
                />
              </div>
            ) : requestData.length === 0 ? (
              <div
                className="col-md-12 col-sm-12 col-12"
                style={{ marginTop: "25px" }}
              >
                <h3
                  style={{
                    color: "white",
                    textAlign: "center",
                    width: "100%",
                    position: "absolute",
                  }}
                >
                  Opps! No Data Found
                </h3>
              </div>
            ) : (
              <div style={{ margin: "auto", width: "0%", padding: 10 }}>
                <Loader type="TailSpin" color="blue" height={50} width={50} />
              </div>
            )}
          </div>

          <div
            className="row"
            style={{ marginBottom: "25px", marginTop: "25px" }}
          >
            <div className="col-md-12 col-sm-12 col-12">
              <img
                onClick={() => props.history.goBack()}
                src={Back}
                style={{ width: 45, height: 35, cursor: "pointer" }}
              />
            </div>
          </div>
        </div>

        {/* <Dialog
          fullScreen
          aria-labelledby="form-dialog-title"
          open={openDialog}
          maxWidth="xl"
          fullWidth={true}
        >
          <DialogContent style={{ backgroundColor: "rgb(19 213 159)" }}>
            <DialogTitle
              id="simple-dialog-title"
              style={{ color: "white", marginLeft: -9 }}
            >
              Update Status
            </DialogTitle>
            <div className={`container-fluid ${classes.root}`}>
              <div className="row">
                <div
                  className="col-12"
                  style={{
                    ...styles.inputContainerForTextField,
                    ...styles.textFieldPadding,
                    padding: matches ? "" : "0px",
                  }}
                >
                  <TextField
                    required
                    select
                    label="Status"
                    name={"requestStatus"}
                    value={requestStatus}
                    onChange={onChangeValue}
                    className="textInputStyle"
                    variant="filled"
                    InputProps={{
                      className: classes.input,
                      classes: { input: classes.input },
                      disableUnderline: true,
                    }}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>

                    {statusArray.map((val) => {
                      return (
                        <MenuItem
                          key={val.key}
                          value={val.value}
                          disabled={
                            (updateStatus === "delivery_in_progress" &&
                              val.value === "closed") ||
                            (updateStatus === "delivered" &&
                              val.value === "delivery_in_progress")
                              ? true
                              : false
                          }
                        >
                          {val.key}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingLeft: 5,
                  paddingRight: 5,
                  marginTop: 20,
                }}
              >
                <div style={{ marginTop: "2%", marginBottom: "2%" }}>
                  <Button
                    onClick={() => hideDialog()}
                    style={{
                      ...styles.stylesForButton,
                      backgroundColor: "white",
                      color: "grey",
                      width: matches ? " " : 150,
                    }}
                    variant="contained"
                  >
                    <strong>Cancel</strong>
                  </Button>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: "2%",
                    marginBottom: "2%",
                  }}
                >
                  <Button
                    disabled={validateStatusChange()}
                    style={styles.stylesForButton}
                    onClick={handleEditPharmReq}
                    variant="contained"
                    color="primary"
                  >
                    Submit
                  </Button>
                </div>
              </div>
            </div>
          </DialogContent>
        </Dialog> */}

        <Notification
          msg={errorMsg}
          open={openNotification}
          success={successMsg}
        />
      </div>
    </div>
  );
}
