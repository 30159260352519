import React, { useState, useEffect } from "react";
import Notification from "../../components/Snackbar/Notification.js";
import CustomTable from "../../components/Table/Table";
import { styles } from "./styles";
import useStyles from "../../assets/jss/material-dashboard-react/greyInputStyle";
import externalConsultant from "../../assets/img/External Consultants.png";
import Loader from "react-loader-spinner";
import Back from "../../assets/img/Back_Arrow.png";
import Header from "../../components/Header/Header";
import "../../assets/jss/material-dashboard-react/components/loaderStyle.css";
import AccountCircle from "@material-ui/icons/SearchOutlined";
import Filter from "../../assets/img/Filter.png";

import {
  getExternalConsultantData,
  getSearchedConsultant,
} from "./NetworkCall";

import {
  Button,
  useMediaQuery,
  DialogTitle,
  Dialog,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  InputAdornment,
} from "@material-ui/core";

const tableHeading = [
  "Doctor Id",
  "Doctor Name",
  "Speciality",
  "Production Area",
  "Experience",
  " ",
];
const tableDataKeys = [
  "Doctor Id",
  "Doctor Name",
  "Speciality",
  "Production Area",
  "Experience",
];

export default function ExternalConsultants(props) {
  const classes = useStyles();
  const matches = useMediaQuery("(min-width:600px)");
  const [errorMsg, setErrorMsg] = useState("");
  const [openNotification, setOpenNotification] = useState(false);
  const [doctorSpeciality, setDoctorSpeciality] = useState("");
  const [doctorSpecialities, setDoctorSpecialities] = useState([]);
  const [filterDialog, setFilterDialog] = useState(false);

  const [filteredccData] = useState([]);
  const [successMsg, setsuccessMsg] = useState("");
  const [searchPatientQuery, setSearchPatientQuery] = useState("");
  const [externalConsultantData, setExternalConsultantData] = useState([]);
  const [
    originalExternalConsultantData,
    setOriginalExternalConsultantData,
  ] = useState([]);

  if (openNotification) {
    setTimeout(() => {
      setOpenNotification(false);
      setErrorMsg("");
      setsuccessMsg("");
    }, 2000);
  }

  useEffect(() => {
    getData();
  }, []);

  function getData() {
    getExternalConsultantData(
      setOriginalExternalConsultantData,
      setDoctorSpecialities
    );
  }

  const setDialogOpen = () => {
    setFilterDialog(true);
  };

  const handleClose = () => {
    setFilterDialog(false);
  };

  const handleApplyFilters = () => {
    setFilterDialog(false);
    let filteredConsultantData = [];
    originalExternalConsultantData.forEach((item) => {
      if (doctorSpeciality !== "None") {
        const { Speciality } = item;
        if (
          Speciality &&
          Speciality.length === 1 &&
          Speciality === doctorSpeciality
        ) {
          filteredConsultantData.push(item);
        }
        if (
          Speciality &&
          Speciality.length > 1 &&
          Speciality.includes(doctorSpeciality)
        ) {
          filteredConsultantData.push(item);
        }
      }
    });
    if (doctorSpeciality !== "None") {
      setExternalConsultantData(filteredConsultantData);
    }
  };

  const handleConsultantSearch = (e) => {
    const a = e.target.value.replace(/[^\w\s]/gi, "");
    setSearchPatientQuery(a);
    if (a.length >= 3) {
      getSearchedConsultant(
        a,
        setOriginalExternalConsultantData,
        setDoctorSpecialities
      );
    } else if (!a.length) {
      getExternalConsultantData(
        setOriginalExternalConsultantData,
        setDoctorSpecialities
      );
    }
  };

  useEffect(() => {
    setExternalConsultantData(originalExternalConsultantData);
  }, [originalExternalConsultantData]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        position: "fixed",
        width: "100%",
        height: "100%",
        backgroundColor: "rgb(19 213 159)",
        overflowY: "scroll",
      }}
    >
      <Header history={props.history} />

      <div className="cPadding">
        <div className="subheader">
          <div>
            <img src={externalConsultant} alt="Alternate" />
            <h4>External Consultants</h4>
          </div>
          <div>
            <Button
              onClick={setDialogOpen}
              style={{ ...styles.stylesForButton, height: matches ? " " : 30 }}
              variant="contained"
              color="primary"
            >
              <img
                src={Filter}
                style={{ width: matches ? 20 : 10, height: matches ? " " : 10 }}
                alt="Alternate"
              />
              <strong style={{ fontSize: matches ? " " : 10 }}>Filter</strong>
            </Button>
          </div>
        </div>

        <div
          className={`row  ${classes.root} `}
          style={{ marginTop: "25px", marginRight: 0.7 }}
        >
          <div
            className="col-md-12  col-12"
            style={{
              paddingRight: matches ? 5 : 0,
            }}
          >
            <TextField
              className="textInputStyle"
              id="searchPatientQuery"
              type="text"
              variant="filled"
              label={"Search Doctor by Name/Id"}
              name={"searchPatientQuery"}
              value={searchPatientQuery}
              onChange={handleConsultantSearch}
              InputLabelProps={{
                classes: {
                  root: classes.label,
                  focused: classes.focusedLabel,
                  error: classes.erroredLabel,
                },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <AccountCircle />
                  </InputAdornment>
                ),
                className: classes.input,
                classes: { input: classes.input },
                disableUnderline: true,
              }}
            />
          </div>
        </div>

        <div
          style={{
            flex: 4,
            display: "flex",
            flexDirection: "column",
          }}
        >
          {externalConsultantData && externalConsultantData.length ? (
            <div className="container-fluid">
              <div className="row">
                <CustomTable
                  tableData={externalConsultantData}
                  tableDataKeys={tableDataKeys}
                  tableHeading={tableHeading}
                  action={{ action: "" }}
                  borderBottomColor={"#60d69f"}
                  borderBottomWidth={20}
                />
                <Notification msg={errorMsg} open={openNotification} />
              </div>
            </div>
          ) : filteredccData && filteredccData.length === 0 ? (
            <div className="row " style={{ marginTop: "25px" }}>
              <div className="col-md-12 col-sm-12 col-12">
                <h3
                  style={{
                    color: "white",
                    textAlign: "center",
                  }}
                >
                  Oops! No Data Found
                </h3>
              </div>
            </div>
          ) : (
            <div className="row">
              <div className="LoaderStyle">
                <Loader type="TailSpin" color="red" height={50} width={50} />
              </div>
            </div>
          )}

          <div className="row">
            <div className="col-md-12 col-sm-12 col-12">
              <img
                onClick={() => props.history.goBack()}
                src={Back}
                style={{
                  maxWidth: "45px",
                  height: "auto",
                  cursor: "pointer",
                }}
                alt="Alternate"
              />
            </div>
          </div>
        </div>

        <Dialog
          maxWidth={"lg"}
          fullWidth
          open={filterDialog}
          onClose={handleClose}
        >
          <DialogTitle>Search Filters</DialogTitle>
          <DialogContent>
            <div>
              <div>
                <div>
                  <FormControl variant="filled" style={{ width: "100%" }}>
                    <InputLabel id="demo-simple-select-filled-label">
                      Select Doctor Speciality
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-filled-label"
                      id="demo-simple-select-filled"
                      value={doctorSpeciality}
                      onChange={(event) =>
                        setDoctorSpeciality(event.target.value)
                      }
                    >
                      {doctorSpecialities &&
                        doctorSpecialities.length &&
                        doctorSpecialities.map((type) => (
                          <MenuItem key={type} value={type}>
                            {type}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </div>
              </div>
            </div>

            <div style={{ marginTop: 10 }}></div>
          </DialogContent>

          <DialogActions
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button onClick={handleClose} variant="outlined">
              Cancel
            </Button>

            <Button
              onClick={handleApplyFilters}
              variant="contained"
              color="primary"
            >
              Apply Filters
            </Button>
          </DialogActions>
        </Dialog>

        <Notification
          msg={errorMsg}
          success={successMsg}
          open={openNotification}
        />
      </div>
    </div>
  );
}
