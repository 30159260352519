import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Header from '../../components/Header/Header'
import DownloadIcon from '../../assets/img/Download Icon.png'
import PrintIcon from '../../assets/img/Print Icon.png'
import SummaryQR from '../../assets/img/Summary_QR.png'
import Button from '@material-ui/core/Button'
import DetailBlock from '../../components/DHR/DCD/ViewScreen/detailBlock'
import Back from '../../assets/img/Back_Arrow_black.png'
import { uploadsUrl } from '../../public/endpoins'
import _ from 'lodash'
import '../../views/Registration/registrationOfficer.css'
import cookie from 'react-cookies'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Avatar from '@material-ui/core/Avatar'

const useStylesForInput = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(0),
  },
  input: {
    backgroundColor: 'white',
    boxShadow: 'none',
    borderRadius: 5,
    '&:after': {
      borderBottomColor: 'black',
      boxShadow: 'none',
    },
    '&:hover': {
      backgroundColor: 'white',
      boxShadow: 'none',
    },
    '&:focus': {
      backgroundColor: 'white',
      boxShadow: 'none',
      borderRadius: 5,
    },
  },
  root: {
    '& .MuiTextField-root': {
      backgroundColor: 'white',
    },
    '& .Mui-focused': {
      backgroundColor: 'white',
      color: 'black',
      boxShadow: 'none',
    },
    '& .Mui-disabled': {
      backgroundColor: 'white',
      color: 'gray',
    },
    '&:focus': {
      backgroundColor: 'white',
      boxShadow: 'none',
    },
    '& .MuiFormLabel-root': {
      fontSize: '12px',
      paddingRight: '15px',
    },
  },
  label: {
    '&$focusedLabel': {
      color: 'red',
      display: 'none',
    },
  },
}))

export default function ViewPhysicalExam(props) {
  const matches = useMediaQuery('(min-width:600px)')
  const classes = useStylesForInput()

  const [selectedPatient, setSelectedPatient] = useState([])
  const [currentUser, setCurrentUser] = useState(cookie.load('current_user'))

  console.log('props', props)

  useEffect(() => {
    let selectedItem = props.history.location.state.selectedItem
    console.log('patient data passed', selectedItem)

    let objforStep0 = {
      heading: props.location.state.selectedRecord.data[0].key,
      columnSize: 1,
      // mobilecolumnSize: 2,
      subArray: [
        {
          subheading: props.location.state.selectedRecord.data[0].value[0].name,
          description:
            props.location.state.selectedRecord.data[0].value[0].value,
        },
      ],
    }

    let objforStep1 = {
      heading: props.location.state.selectedRecord.data[1].key,
      columnSize: 2,
      // mobilecolumnSize: 2,
      subArray: [
        {
          subheading:
            props.location.state.selectedRecord.data[1].value[0] &&
            props.location.state.selectedRecord.data[1].value[0].name,
          description:
            props.location.state.selectedRecord.data[1].value[0] &&
            props.location.state.selectedRecord.data[1].value[0].value,
        },
        {
          subheading:
            props.location.state.selectedRecord.data[1].value[1] &&
            props.location.state.selectedRecord.data[1].value[1].name,
          description:
            props.location.state.selectedRecord.data[1].value[1] &&
            props.location.state.selectedRecord.data[1].value[1].value,
        },
        {
          subheading:
            props.location.state.selectedRecord.data[1].value[2] &&
            props.location.state.selectedRecord.data[1].value[2].name,
          description:
            props.location.state.selectedRecord.data[1].value[2] &&
            props.location.state.selectedRecord.data[1].value[2].value,
        },
        {
          subheading:
            props.location.state.selectedRecord.data[1].value[3] &&
            props.location.state.selectedRecord.data[1].value[3].name,
          description:
            props.location.state.selectedRecord.data[1].value[3] &&
            props.location.state.selectedRecord.data[1].value[3].value,
        },
        {
          subheading:
            props.location.state.selectedRecord.data[1].value[4] &&
            props.location.state.selectedRecord.data[1].value[4].name,
          description:
            props.location.state.selectedRecord.data[1].value[4] &&
            props.location.state.selectedRecord.data[1].value[4].value,
        },
        {
          subheading:
            props.location.state.selectedRecord.data[1].value[5] &&
            props.location.state.selectedRecord.data[1].value[5].name,
          description:
            props.location.state.selectedRecord.data[1].value[6] &&
            props.location.state.selectedRecord.data[1].value[6].value,
        },
      ],
    }
    let objforStep2 = {
      heading: props.location.state.selectedRecord.data[2].key,
      columnSize: 2,
      // mobilecolumnSize: 2,
      subArray: [
        {
          subheading:
            props.location.state.selectedRecord.data[2].value[0] &&
            props.location.state.selectedRecord.data[2].value[0].name,
          description:
            props.location.state.selectedRecord.data[2].value[0] &&
            props.location.state.selectedRecord.data[2].value[0].value,
        },
        {
          subheading:
            props.location.state.selectedRecord.data[2].value[1] &&
            props.location.state.selectedRecord.data[2].value[1].name,
          description:
            props.location.state.selectedRecord.data[2].value[1] &&
            props.location.state.selectedRecord.data[2].value[1].value,
        },
        {
          subheading:
            props.location.state.selectedRecord.data[2].value[2] &&
            props.location.state.selectedRecord.data[2].value[2].name,
          description:
            props.location.state.selectedRecord.data[2].value[2] &&
            props.location.state.selectedRecord.data[2].value[2].value,
        },
      ],
    }
    let objforStep3 = {
      heading: props.location.state.selectedRecord.data[3].key,
      columnSize: 2,
      // mobilecolumnSize: 2,
      subArray: [
        {
          subheading:
            props.location.state.selectedRecord.data[3].value[0] &&
            props.location.state.selectedRecord.data[3].value[0].name,
          description:
            props.location.state.selectedRecord.data[3].value[0] &&
            props.location.state.selectedRecord.data[3].value[0].value,
        },
        {
          subheading:
            props.location.state.selectedRecord.data[3].value[1] &&
            props.location.state.selectedRecord.data[3].value[1].name,
          description:
            props.location.state.selectedRecord.data[3].value[1] &&
            props.location.state.selectedRecord.data[3].value[1].value,
        },
        {
          subheading:
            props.location.state.selectedRecord.data[3].value[2] &&
            props.location.state.selectedRecord.data[3].value[2].name,
          description:
            props.location.state.selectedRecord.data[3].value[2] &&
            props.location.state.selectedRecord.data[3].value[2].value,
        },
      ],
    }
    let objforStep4 = {
      heading: props.location.state.selectedRecord.data[4].key,
      columnSize: 2,
      // mobilecolumnSize: 2,
      subArray: [
        {
          subheading:
            props.location.state.selectedRecord.data[4].value[0] &&
            props.location.state.selectedRecord.data[4].value[0].name,
          description:
            props.location.state.selectedRecord.data[4].value[0] &&
            props.location.state.selectedRecord.data[4].value[0].value,
        },
        {
          subheading:
            props.location.state.selectedRecord.data[4].value[1] &&
            props.location.state.selectedRecord.data[4].value[1].name,
          description:
            props.location.state.selectedRecord.data[4].value[1] &&
            props.location.state.selectedRecord.data[4].value[1].value,
        },
        {
          subheading:
            props.location.state.selectedRecord.data[4].value[2] &&
            props.location.state.selectedRecord.data[4].value[2].name,
          description:
            props.location.state.selectedRecord.data[4].value[2] &&
            props.location.state.selectedRecord.data[4].value[2].value,
        },
        {
          subheading:
            props.location.state.selectedRecord.data[4].value[3] &&
            props.location.state.selectedRecord.data[4].value[3].name,
          description:
            props.location.state.selectedRecord.data[4].value[3] &&
            props.location.state.selectedRecord.data[4].value[3].value,
        },
        {
          subheading:
            props.location.state.selectedRecord.data[4].value[4] &&
            props.location.state.selectedRecord.data[4].value[4].name,
          description:
            props.location.state.selectedRecord.data[4].value[4] &&
            props.location.state.selectedRecord.data[4].value[4].value,
        },
      ],
    }
    let objforStep5 = {
      heading: props.location.state.selectedRecord.data[5].key,
      columnSize: 2,
      // mobilecolumnSize: 2,
      subArray: [
        {
          subheading:
            props.location.state.selectedRecord.data[5].value[0] &&
            props.location.state.selectedRecord.data[5].value[0].name,
          description:
            props.location.state.selectedRecord.data[5].value[0] &&
            props.location.state.selectedRecord.data[5].value[0].value,
        },
        {
          subheading:
            props.location.state.selectedRecord.data[5].value[1] &&
            props.location.state.selectedRecord.data[5].value[1].name,
          description:
            props.location.state.selectedRecord.data[5].value[1] &&
            props.location.state.selectedRecord.data[5].value[1].value,
        },
        {
          subheading:
            props.location.state.selectedRecord.data[5].value[2] &&
            props.location.state.selectedRecord.data[5].value[2].name,
          description:
            props.location.state.selectedRecord.data[5].value[2] &&
            props.location.state.selectedRecord.data[5].value[2].value,
        },
        {
          subheading:
            props.location.state.selectedRecord.data[5].value[3] &&
            props.location.state.selectedRecord.data[5].value[3].name,
          description:
            props.location.state.selectedRecord.data[5].value[3] &&
            props.location.state.selectedRecord.data[5].value[3].value,
        },
      ],
    }
    let objforStep6 = {
      heading: props.location.state.selectedRecord.data[6].key,
      columnSize: 2,
      // mobilecolumnSize: 2,
      subArray: [
        {
          subheading:
            props.location.state.selectedRecord.data[6].value[0] &&
            props.location.state.selectedRecord.data[6].value[0].name,
          description:
            props.location.state.selectedRecord.data[6].value[0] &&
            props.location.state.selectedRecord.data[6].value[0].value,
        },
        {
          subheading:
            props.location.state.selectedRecord.data[6].value[1] &&
            props.location.state.selectedRecord.data[6].value[1].name,
          description:
            props.location.state.selectedRecord.data[6].value[1] &&
            props.location.state.selectedRecord.data[6].value[1].value,
        },
        {
          subheading:
            props.location.state.selectedRecord.data[6].value[2] &&
            props.location.state.selectedRecord.data[6].value[2].name,
          description:
            props.location.state.selectedRecord.data[6].value[2] &&
            props.location.state.selectedRecord.data[6].value[2].value,
        },
      ],
    }

    let objforStep7 = {
      heading: props.location.state.selectedRecord.data[7].key,
      columnSize: 2,
      // mobilecolumnSize: 2,
      subArray: [
        {
          subheading:
            props.location.state.selectedRecord.data[7].value[0] &&
            props.location.state.selectedRecord.data[7].value[0].name,
          description:
            props.location.state.selectedRecord.data[7].value[0] &&
            props.location.state.selectedRecord.data[7].value[0].value,
        },
        {
          subheading:
            props.location.state.selectedRecord.data[7].value[1] &&
            props.location.state.selectedRecord.data[7].value[1].name,
          description:
            props.location.state.selectedRecord.data[7].value[1] &&
            props.location.state.selectedRecord.data[7].value[1].value,
        },
        {
          subheading:
            props.location.state.selectedRecord.data[7].value[2] &&
            props.location.state.selectedRecord.data[7].value[2].name,
          description:
            props.location.state.selectedRecord.data[7].value[2] &&
            props.location.state.selectedRecord.data[7].value[2].value,
        },
        {
          subheading:
            props.location.state.selectedRecord.data[7].value[3] &&
            props.location.state.selectedRecord.data[7].value[3].name,
          description:
            props.location.state.selectedRecord.data[7].value[3] &&
            props.location.state.selectedRecord.data[7].value[3].value,
        },
      ],
    }
    let objforStep8 = {
      heading: props.location.state.selectedRecord.data[8].key,
      columnSize: 2,
      // mobilecolumnSize: 2,
      subArray: [
        {
          subheading:
            props.location.state.selectedRecord.data[8].value[0] &&
            props.location.state.selectedRecord.data[8].value[0].name,
          description:
            props.location.state.selectedRecord.data[8].value[0] &&
            props.location.state.selectedRecord.data[8].value[0].value,
        },
        {
          subheading:
            props.location.state.selectedRecord.data[8].value[1] &&
            props.location.state.selectedRecord.data[8].value[1].name,
          description:
            props.location.state.selectedRecord.data[8].value[1] &&
            props.location.state.selectedRecord.data[8].value[1].value,
        },
        {
          subheading:
            props.location.state.selectedRecord.data[8].value[2] &&
            props.location.state.selectedRecord.data[8].value[2].name,
          description:
            props.location.state.selectedRecord.data[8].value[2] &&
            props.location.state.selectedRecord.data[8].value[2].value,
        },
        {
          subheading:
            props.location.state.selectedRecord.data[8].value[3] &&
            props.location.state.selectedRecord.data[8].value[3].name,
          description:
            props.location.state.selectedRecord.data[8].value[3] &&
            props.location.state.selectedRecord.data[8].value[3].value,
        },
      ],
    }
    let objforStep9 = {
      heading: props.location.state.selectedRecord.data[9].key,
      columnSize: 2,
      // mobilecolumnSize: 2,
      subArray: [
        {
          subheading:
            props.location.state.selectedRecord.data[9].value[0] &&
            props.location.state.selectedRecord.data[9].value[0].name,
          description:
            props.location.state.selectedRecord.data[9].value[0] &&
            props.location.state.selectedRecord.data[9].value[0].value,
        },
        {
          subheading:
            props.location.state.selectedRecord.data[9].value[1] &&
            props.location.state.selectedRecord.data[9].value[1].name,
          description:
            props.location.state.selectedRecord.data[9].value[1] &&
            props.location.state.selectedRecord.data[9].value[1].value,
        },
        {
          subheading:
            props.location.state.selectedRecord.data[9].value[2] &&
            props.location.state.selectedRecord.data[9].value[2].name,
          description:
            props.location.state.selectedRecord.data[9].value[2] &&
            props.location.state.selectedRecord.data[9].value[2].value,
        },
        {
          subheading:
            props.location.state.selectedRecord.data[9].value[3] &&
            props.location.state.selectedRecord.data[9].value[3].name,
          description:
            props.location.state.selectedRecord.data[9].value[3] &&
            props.location.state.selectedRecord.data[9].value[3].value,
        },
      ],
    }
    let objforStep10 = {
      heading: props.location.state.selectedRecord.data[10].key,
      columnSize: 2,
      // mobilecolumnSize: 2,
      subArray: [
        {
          subheading:
            props.location.state.selectedRecord.data[10].value[0] &&
            props.location.state.selectedRecord.data[10].value[0].name,
          description:
            props.location.state.selectedRecord.data[10].value[0] &&
            props.location.state.selectedRecord.data[10].value[0].value,
        },
        {
          subheading:
            props.location.state.selectedRecord.data[10].value[1] &&
            props.location.state.selectedRecord.data[10].value[1].name,
          description:
            props.location.state.selectedRecord.data[10].value[1] &&
            props.location.state.selectedRecord.data[10].value[1].value,
        },
        {
          subheading:
            props.location.state.selectedRecord.data[10].value[2] &&
            props.location.state.selectedRecord.data[10].value[2].name,
          description:
            props.location.state.selectedRecord.data[10].value[2] &&
            props.location.state.selectedRecord.data[10].value[2].value,
        },
        {
          subheading:
            props.location.state.selectedRecord.data[10].value[3] &&
            props.location.state.selectedRecord.data[10].value[3].name,
          description:
            props.location.state.selectedRecord.data[10].value[3] &&
            props.location.state.selectedRecord.data[10].value[3].value,
        },
      ],
    }
    let objforStep11 = {
      heading: props.location.state.selectedRecord.data[11].key,
      columnSize: 2,
      // mobilecolumnSize: 2,
      subArray: [
        {
          subheading: '',
          description:
            props.location.state.selectedRecord.data[11] &&
            props.location.state.selectedRecord.data[11].text,
        },
      ],
    }

    let arr = []
    arr.push(objforStep0)
    arr.push(objforStep1)
    arr.push(objforStep2)
    arr.push(objforStep3)
    arr.push(objforStep4)
    arr.push(objforStep5)
    arr.push(objforStep6)
    arr.push(objforStep7)
    arr.push(objforStep8)
    arr.push(objforStep9)
    arr.push(objforStep10)
    arr.push(objforStep11)

    console.log('arr', arr)

    setSelectedPatient(arr)
  }, [])

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        position: 'fixed',
        width: '100%',
        height: '100%',
        overflowY: 'scroll',
        border: '1p',
      }}
    >
      <Header history={props.history} />
      <div className='cPadding'>
        <div className='patientDetailsHeader' style={{ marginBottom: 45 }}>
          <div className='col-md-6 col-6'>
            <h5 className='PatientName'>Survey Data</h5>
          </div>
        </div>

        <div
          style={{ flex: 4, display: 'flex', flexDirection: 'column' }}
          className={`${'container-fluid'} ${classes.root}`}
        >
          {selectedPatient.map((arr) => {
            return (
              <DetailBlock
                heading={arr.heading}
                subArray={arr.subArray}
                columnSize={arr.columnSize}
                // mobilecolumnSize={arr.mobilecolumnSize}
                viewSurvey={'viewSurvey'}
              />
            )
          })}

          <div
            className='row'
            style={{ marginTop: '20px', marginBottom: '20px' }}
          >
            <div className='col-md-6 col-sm-6 col-6'>
              <img
                onClick={() => props.history.goBack()}
                src={Back}
                style={{
                  maxWidth: matches ? '60%' : 45,
                  height: matches ? 'auto' : 30,
                  cursor: 'pointer',
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
