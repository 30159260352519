import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Header from "../../components/Header/HeaderGrey";
import DownloadIcon from "../../assets/img/Download Icon.png";
import PrintIcon from "../../assets/img/Print Icon.png";
import SummaryQR from "../../assets/img/Summary_QR.png";
import Button from "@material-ui/core/Button";
import DetailBlock from "../../components/DHR/DCD/ViewScreen/detailBlock";
import Back from "../../assets/img/Back_Arrow_black.png";
import { uploadsUrl } from "../../public/endpoins";
import _ from "lodash";
import cookie from "react-cookies";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Avatar from "@material-ui/core/Avatar";
import formatDate from "../../utils/formatDateTime";

const useStylesForInput = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(0),
  },
  input: {
    backgroundColor: "white",
    boxShadow: "none",
    borderRadius: 5,
    "&:after": {
      borderBottomColor: "black",
      boxShadow: "none",
    },
    "&:hover": {
      backgroundColor: "white",
      boxShadow: "none",
    },
    "&:focus": {
      backgroundColor: "white",
      boxShadow: "none",
      borderRadius: 5,
    },
  },
  root: {
    "& .MuiTextField-root": {
      backgroundColor: "white",
    },
    "& .Mui-focused": {
      backgroundColor: "white",
      color: "black",
      boxShadow: "none",
    },
    "& .Mui-disabled": {
      backgroundColor: "white",
      color: "gray",
    },
    "&:focus": {
      backgroundColor: "white",
      boxShadow: "none",
    },
    "& .MuiFormLabel-root": {
      fontSize: "12px",
      paddingRight: "15px",
    },
  },
  label: {
    "&$focusedLabel": {
      color: "red",
      display: "none",
    },
  },
}));

function ViewTransactionReport(props) {
  const matches = useMediaQuery("(min-width:600px)");
  const [selectedPatient, setSelectedPatient] = useState([]);
  const classes = useStylesForInput();

  function setRequestsData() {
    let selectedItem = props.history.location.state.selectedItem.edrId;
    let lab = [];
    if (selectedItem.labRequest && selectedItem.labRequest.length > 0) {
      for (let i = 0; i < selectedItem.labRequest.length; i++) {
        const id = {
          subheading: "Test Id",
          description: selectedItem.labRequest[i].requestId,
        };
        const name = {
          subheading: "Test Name",
          description: selectedItem.labRequest[i].name,
        };
        const date = {
          subheading: "Date/Time",
          description: formatDate(selectedItem.labRequest[i].requestedAt),
        };

        const price = {
          subheading: "Price",
          description: selectedItem.labRequest[i].price + " " + "JD",
        };
        lab.push(id);
        lab.push(name);
        lab.push(date);
        lab.push(price);
      }
    }

    let rad = [];
    if (selectedItem.radRequest && selectedItem.radRequest.length > 0) {
      for (let i = 0; i < selectedItem.radRequest.length; i++) {
        const id = {
          subheading: "Test Id",
          description: selectedItem.radRequest[i].requestId,
        };
        const name = {
          subheading: "Test Name",
          description: selectedItem.radRequest[i].name,
        };
        const date = {
          subheading: "Date/Time",
          description: formatDate(selectedItem.radRequest[i].requestedAt),
        };
        const price = {
          subheading: "Price",
          description: selectedItem.radRequest[i].price + " " + "JD",
        };
        rad.push(id);
        rad.push(name);
        rad.push(date);
        rad.push(price);
      }
    }

    let pharmacy = [];
    if (
      selectedItem.pharmacyRequest &&
      selectedItem.pharmacyRequest.length > 0
    ) {
      for (let i = 0; i < selectedItem.pharmacyRequest.length; i++) {
        for (let j = 0; j < selectedItem.pharmacyRequest[i].item.length; j++) {
          const name = {
            subheading: "Medicine Name",
            description: selectedItem.pharmacyRequest[i].item[j].itemName,
          };
          const dosage = {
            subheading: "Dosage",
            description: selectedItem.pharmacyRequest[i].item[j].dosage,
          };
          const duration = {
            subheading: "Duration",
            description: selectedItem.pharmacyRequest[i].item[j].duration,
          };
          const price = {
            subheading: "Price",
            description:
              selectedItem.pharmacyRequest[i].item[j].price + " " + "JD",
          };
          pharmacy.push(name);
          pharmacy.push(dosage);
          pharmacy.push(duration);
          pharmacy.push(price);
        }
      }
    }

    let rooms = [];
    let roomIdsUsed = [];
    let roomNosUsed = [];
    let bedsIdsUsed = [];
    if (selectedItem.room && selectedItem.room.length > 0) {
      for (let i = 0; i < selectedItem.room.length; i++) {
        roomIdsUsed.push(selectedItem.room[i].roomId.roomId);
        roomNosUsed.push(selectedItem.room[i].roomId.roomNo);
        bedsIdsUsed.push(selectedItem.room[i].roomId.beds[0].bedId);
      }

      const roomId = {
        subheading: "Room Id",
        description: roomIdsUsed.join(","),
      };
      const roomNo = {
        subheading: "Room No",
        description: roomNosUsed.join(","),
      };

      const bedId = {
        subheading: "Bed Id",
        description: bedsIdsUsed.join(","),
      };

      const price = {
        subheading: "Total Fee",
        description:
          props.history.location.state.selectedItem.roomServicesFee +
          " " +
          "JD",
      };
      rooms.push(roomId);
      rooms.push(roomNo);
      rooms.push(bedId);
      rooms.push(price);
    }
    return {
      lab: [...lab],
      rad: [...rad],
      pharmacy: [...pharmacy],
      rooms: [...rooms],
    };
  }

  useEffect(() => {
    let selectedItem = props.history.location.state.selectedItem;
    console.log("patient data passed", selectedItem);

    var email, mobileNumber, telephoneNumber, contactNumber;

    selectedItem.patientId.telecom &&
      selectedItem.patientId.telecom.map((v) => {
        if (v.system === "email") {
          email = v.value;
        }
        if (v.system === "mobile") {
          mobileNumber = v.value;
        }
        if (v.system === "phone") {
          telephoneNumber = v.value;
        }
      });
    selectedItem.patientId.contact &&
      selectedItem.patientId.contact[0].telecom.map((v) => {
        if (v.system === "phone" || v.system === "mobile") {
          contactNumber = v.value;
        }
      });

    let objForPatientDetails = {
      heading: "Patient Details",
      columnSize: 4,
      mobilecolumnSize: 2,
      subArray: [
        {
          subheading: "Patient MRN",
          description:
            selectedItem.patientId.identifier &&
            selectedItem.patientId.identifier[0].value,
        },
        {
          subheading: "National ID",
          description:
            selectedItem.patientId.nationalID &&
            selectedItem.patientId.nationalID,
        },
        {
          subheading: "Gender",
          description:
            selectedItem.patientId.gender && selectedItem.patientId.gender,
        },
        {
          subheading: "Age",
          description: selectedItem.patientId.age && selectedItem.patientId.age,
        },
        {
          subheading: "Date of Birth",
          description:
            selectedItem.patientId.birthDate &&
            formatDateWithoutTime(selectedItem.patientId.birthDate),
        },
        {
          subheading: "Email",
          description: email && email,
        },
        {
          subheading: "Height (ft)",
          description:
            selectedItem.patientId.height && selectedItem.patientId.height,
        },
        {
          subheading: "Weight (Kg)",
          description:
            selectedItem.patientId.weight && selectedItem.patientId.weight,
        },
        {
          subheading: "Mobile Number",
          description: mobileNumber && mobileNumber,
        },
        {
          subheading: "Telephone Number",
          description: telephoneNumber && telephoneNumber,
        },
        {
          subheading: "Country",
          description:
            selectedItem.patientId.address &&
            selectedItem.patientId.address[0].country,
        },
        {
          subheading: "City",
          description:
            selectedItem.patientId.address &&
            selectedItem.patientId.address[0].city,
        },
        {
          subheading: "Nationality",
          description:
            selectedItem.patientId.nationality &&
            selectedItem.patientId.nationality,
        },
        {
          subheading: "Address",
          description:
            selectedItem.patientId.address &&
            selectedItem.patientId.address[0].text,
        },
        {
          subheading: "ID Card (Front)",
          description:
            selectedItem.patientId.idCardFront &&
            selectedItem.patientId.idCardFront,
        },
        {
          subheading: "ID Card (Back)",
          description:
            selectedItem.patientId.idCardBack &&
            selectedItem.patientId.idCardBack,
        },
      ],
    };

    let objForLabTests = {
      heading: "Lab Tests",
      columnSize: 4,
      subArray: setRequestsData().lab,
    };

    let objForRadTests = {
      heading: "Radiology Tests",
      columnSize: 4,
      subArray: setRequestsData().rad,
    };

    let objForMedication = {
      heading: "Medicines",
      columnSize: 4,
      subArray: setRequestsData().pharmacy,
    };

    let objForRooms = {
      heading: "Rooms",
      columnSize: 4,
      subArray: setRequestsData().rooms,
    };

    let arr = [];

    arr.push(objForPatientDetails);
    arr.push(objForMedication);
    arr.push(objForLabTests);
    arr.push(objForRadTests);
    arr.push(objForRooms);
    setSelectedPatient(arr);
  }, []);

  const formatDateWithoutTime = (date) => {
    const d = new Date(date);
    let minutes = "";
    if (d.getHours().toString().length === 1) {
      minutes = "0" + d.getHours();
    } else {
      minutes = d.getHours();
    }
    return (
      d
        .getDate()
        .toString()
        .padStart(2, "0") +
      " - " +
      (d.getMonth() + 1).toString().padStart(2, "0") +
      " - " +
      d.getFullYear()
    );
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        position: "fixed",
        width: "100%",
        height: "100%",
        overflowY: "scroll",
        border: "1p",
      }}
    >
      <Header history={props.history} />
      <div className="cPadding">
        <div className="patientDetailsHeader" style={{ marginBottom: 45 }}>
          <div className="col-md-6 col-6">
            {props.history.location.state.selectedItem.photo &&
            props.history.location.state.selectedItem.photo.length > 0 &&
            props.history.location.state.selectedItem.photo[0].url.includes(
              "\\"
            ) ? (
              // <>
              //   {props.history.location.state.selectedItem.photo.length > 0 &&
              //   props.history.location.state.selectedItem.photo[0].url.slice(
              //     props.history.location.state.selectedItem.photo[0].url
              //       .length - 3
              //   ) !== 'pdf' ? (
              <div>
                <img
                  src={
                    uploadsUrl +
                    props.history.location.state.selectedItem.patientId.photo[0].url.split(
                      "\\"
                    )[1]
                  }
                  className="profilePicture"
                />
              </div>
            ) : //   ) : (
            //     undefined
            //   )}
            // </>
            props.history.location.state.selectedItem.patientId.photo &&
              props.history.location.state.selectedItem.patientId.photo.length >
                0 &&
              props.history.location.state.selectedItem.patientId.photo[0].url.includes(
                "/"
              ) ? (
              <div>
                <img
                  src={
                    uploadsUrl +
                    props.history.location.state.selectedItem.patientId.photo[0]
                      .url
                  }
                  className="profilePicture"
                />
              </div>
            ) : (
              //   ) : (
              //     undefined
              //   )}
              // </>
              <Avatar src="/broken-image.jpg" />
            )}

            <h4 className="PatientName">
              {props.history.location.state.selectedItem.patientId.name[0]
                .given[0] +
                " " +
                props.history.location.state.selectedItem.patientId.name[0]
                  .family}
            </h4>
          </div>

          <div className="col-md-6 col-6 d-flex justify-content-end">
            <img className="patientDetailIcons" src={DownloadIcon} />
            <img className="patientDetailIcons" src={PrintIcon} />
            <img className="patientDetailIcons" src={SummaryQR} />
          </div>
        </div>

        <div
          style={{ flex: 4, display: "flex", flexDirection: "column" }}
          className={`${"container-fluid"} ${classes.root}`}
        >
          {selectedPatient.map((arr) => {
            return (
              <DetailBlock
                heading={arr.heading}
                subArray={arr.subArray}
                columnSize={arr.columnSize}
                mobilecolumnSize={arr.mobilecolumnSize}
              />
            );
          })}

          <div
            className="row"
            style={{ marginTop: "20px", marginBottom: "20px" }}
          >
            <div className="col-md-6 col-sm-6 col-6">
              <img
                onClick={() => props.history.goBack()}
                src={Back}
                style={{
                  maxWidth: matches ? "60%" : 45,
                  height: matches ? "auto" : 30,
                  cursor: "pointer",
                }}
              />
            </div>
            <div className="col-md-6 col-sm-6 col-6 d-flex justify-content-end"></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewTransactionReport;
