/*eslint-disable*/
import React, { useState, useEffect, useReducer, Fragment } from "react";
// import "./staffStyles.js";
import Button from "@material-ui/core/Button";
import Notification from "../../components/Snackbar/Notification.js";
import CustomTable from "../../components/Table/Table";
import Loader from "react-loader-spinner";
import Header from "../../components/Header/Header";
import business_Unit from "../../assets/img/Patients.png";
import plus_icon from "../../assets/img/Plus.png";
import Back from "../../assets/img/Back_Arrow.png";
import "../../assets/jss/material-dashboard-react/components/loaderStyle.css";
import cookie from "react-cookies";

import getTimeFromDate from "../../utils/extractTimeFromDate";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import TransactionReport from "../../assets/img/Transaction Reports.png";

import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { makeStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";

import {
  getClearedPatients,
  searchEdrPatientsForCC,
  searchClearedPatients,
} from "./networkCalls";

import TextField from "@material-ui/core/TextField";

import { assignPatientStyles } from "../CustomerCare/styles";

import InputAdornment from "@material-ui/core/InputAdornment";
import AccountCircle from "@material-ui/icons/SearchOutlined";

import BarCode from "../../assets/img/Bar Code.png";

import Fingerprint from "../../assets/img/fingerprint.png";
import Filter from "../../assets/img/Filter.png";
import QRCodeScannerComponent from "../../components/QRCodeScanner/QRCodeScanner";
import DateFnsUtils from "@date-io/date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

const styles = {
  stylesForButton: {
    color: "white",
    cursor: "pointer",
    // borderRadius: 5,
    background: "#2c6ddd",
    // width: "140px",
    height: "50px",
    outline: "none",
  },
};
const useStyles = makeStyles((theme) => ({
  input: {
    "&:focus": {
      backgroundColor: "white",
      boxShadow: "none",
      borderRadius: 5,
    },
  },
  root: {
    "& .Mui-focused": {
      backgroundColor: "white",
      color: "black",
      boxShadow: "none",
    },
    "&:focus": {
      backgroundColor: "white",
      boxShadow: "none",
    },
    "& .MuiFormLabel-root": {
      fontSize: "10px",
      paddingRight: "45px",
    },
  },
}));

const tableHeadingForDoctors = [
  "Request No",
  "MRN",
  "Patient Name",
  "Date/Time",
  "Total Amount(JD)",
  "Action",
];
const tableDataKeysForDoctors = [
  ["edrId", "requestNo"],
  "mrn",
  "name",
  "createdAt",
  "total",
];

const actions = { view: true };

export default function Staff(props) {
  const matches = useMediaQuery("(min-width:600px)");
  const classes = assignPatientStyles();
  const classes1 = useStyles();

  const [staffData, setStaffData] = useState("");
  const [generalArray, setGeneralArray] = useState([]);
  const [filteredArray, setFilteredArray] = useState([]);
  const [doctorSpeciality, setDoctorSpeciality] = useState([]);

  const [filterDialog, setFilterDialog] = useState(false);
  const [shiftStartTime, setShiftStartTime] = useState(null);
  const [shiftEndTime, setShiftEndTime] = useState(null);

  const [errorMsg, setErrorMsg] = useState("");
  const [openNotification, setOpenNotification] = useState(false);

  const [searchPatientQuery, setSearchPatientQuery] = useState("");
  const [QRCodeScanner, setQRCodeScanner] = useState(false);

  if (openNotification) {
    setTimeout(() => {
      setOpenNotification(false);
      setErrorMsg("");
    }, 2000);
  }

  useEffect(() => {
    getClearedPatients(
      setStaffData,
      setGeneralArray,
      setOpenNotification,
      setErrorMsg
    );
  }, []);

  const setDialogOpen = () => {
    setFilterDialog(true);
  };

  const handleClose = () => {
    setFilterDialog(false);
  };

  function setValuesInData(generalArray) {
    let senseiData = generalArray.map((d) => {
      let obj = {
        ...d,
        mrn:
          d.edrId.patientId.identifier[d.edrId.patientId.identifier.length - 1]
            .value,
        name:
          d.edrId.patientId.name[d.edrId.patientId.name.length - 1].given[0] +
          " " +
          d.edrId.patientId.name[d.edrId.patientId.name.length - 1].family,
        chiefComplaint:
          d.edrId.chiefComplaint.length > 0
            ? d.edrId.chiefComplaint[d.edrId.chiefComplaint.length - 1]
                .chiefComplaintId.name
            : "N/A",
        careStream:
          d.edrId.careStream.length > 0
            ? d.edrId.careStream[d.edrId.careStream.length - 1].name
            : "N/A",
        roomNo:
          d.edrId.room.length > 0
            ? d.edrId.room[d.edrId.room.length - 1].roomId.roomNo
            : "N/A",
      };
      return obj;
    });

    setStaffData([...senseiData.reverse()]);
  }

  useEffect(() => {
    setValuesInData(generalArray);
  }, [generalArray]);

  function handleViewSinglePatient(patientObj) {
    console.log(patientObj);
    props.history.push({
      pathname: "transactionreports/view",
      state: {
        selectedItem: patientObj,
      },
    });
  }

  const handlePatientSearch = (e) => {
    const a = e.target.value.replace(/[^\w\s]/gi, "");
    setSearchPatientQuery(a);
    if (a.length >= 3) {
      searchClearedPatients(
        a,
        setGeneralArray,
        setErrorMsg,
        setOpenNotification
      );
    } else if (a.length == 0) {
      getClearedPatients(
        setStaffData,
        setGeneralArray,
        setOpenNotification,
        setErrorMsg
      );
    }
  };

  function scanQRCode() {
    setQRCodeScanner((pervState) => !pervState);
  }

  function handleScanQR(data) {
    setQRCodeScanner(false);
    console.log(JSON.parse(data));
    if (JSON.parse(data).profileNo) {
      handlePatientSearch({
        target: {
          value: JSON.parse(data).profileNo,
          type: "text",
        },
      });
    }
  }

  function handleApplyFilters() {
    let res = [...generalArray];
    let resForConsultant = [];

    for (let j = 0; j < res.length; j++) {
      if (shiftStartTime && shiftEndTime) {
        if (
          new Date(res[j].createdAt).toISOString() >=
            new Date(shiftStartTime).toISOString() &&
          new Date(res[j].createdAt).toISOString() <=
            new Date(shiftEndTime).toISOString()
        ) {
          resForConsultant.push(res[j]);
        }
      } else if (
        shiftStartTime &&
        new Date(res[j].createdAt).toISOString() >=
          new Date(shiftStartTime).toISOString()
      ) {
        resForConsultant.push(res[j]);
      } else if (
        shiftEndTime &&
        new Date(res[j].createdAt).toISOString() <=
          new Date(shiftEndTime).toISOString()
      ) {
        resForConsultant.push(res[j]);
      }
    }

    res = shiftStartTime || shiftEndTime ? resForConsultant : res;
    setValuesInData(res);
    setFilterDialog(false);
  }

  if (QRCodeScanner) {
    return (
      <div>
        {QRCodeScanner ? (
          <QRCodeScannerComponent
            handleScanQR={handleScanQR}
            scanQRCode={scanQRCode}
          />
        ) : (
          undefined
        )}
      </div>
    );
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        position: "fixed",
        width: "100%",
        height: "100%",
        backgroundColor: "#60d69f",
        overflowY: "scroll",
      }}
    >
      <Header history={props.history} />

      <div className="cPadding">
        <div className="subheader">
          <div
            style={{
              display: "flex",
              flex: 1,
            }}
          >
            <img src={TransactionReport} />
            <h4>Transaction Reports</h4>
          </div>

          <Button
            onClick={setDialogOpen}
            style={{ ...styles.stylesForButton, height: matches ? 50 : 40 }}
            variant="contained"
            color="primary"
          >
            <img
              src={Filter}
              style={{ width: 20, height: 20, marginRight: 10 }}
            />{" "}
            <strong>Filter</strong>
          </Button>
        </div>

        <div
          style={{
            flex: 4,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            className={`${"container-fluid"}`}
            style={{
              marginTop: "25px",
              paddingLeft: "10px",
              paddingRight: "10px",
            }}
          >
            <div className={`row  ${classes1.root} ${classes1.input} `}>
              <div
                className="col-md-10  col-8"
                style={{ paddingTop: 5, paddingLeft: 3, paddingRight: 3 }}
              >
                <TextField
                  className="textInputStyle"
                  id="searchPatientQuery"
                  type="text"
                  variant="filled"
                  label={
                    matches
                      ? "Search Patient by Name/MRN/National ID/Mobile No"
                      : "Search Patient by Name/MRN "
                  }
                  name={"searchPatientQuery"}
                  value={searchPatientQuery}
                  onChange={handlePatientSearch}
                  InputLabelProps={{
                    classes: {
                      root: classes.label,
                      focused: classes.focusedLabel,
                      error: classes.erroredLabel,
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <AccountCircle />
                      </InputAdornment>
                    ),
                    className: classes.input,
                    classes: { input: classes.input },
                    disableUnderline: true,
                  }}
                />
              </div>

              <div
                className="col-md-1 col-sm-2 col-2"
                style={{
                  ...styles.textFieldPadding,
                  paddingTop: 5,
                  paddingLeft: 3,
                  paddingRight: 3,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "white",
                    borderRadius: 5,
                    height: 55,
                  }}
                >
                  <img
                    src={BarCode}
                    onClick={scanQRCode}
                    style={{ width: 70, height: 60 }}
                  />
                </div>
              </div>

              <div
                className="col-md-1 col-sm-1 col-2"
                style={{
                  ...styles.textFieldPadding,
                  paddingTop: 5,
                  paddingLeft: 3,
                  paddingRight: 3,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "white",
                    borderRadius: 5,
                    height: 55,
                  }}
                >
                  <img src={Fingerprint} style={{ maxWidth: 43, height: 43 }} />
                </div>
              </div>
            </div>
          </div>

          {staffData.length > 0 ? (
            <div className="container-fluid">
              <div className="row">
                <CustomTable
                  tableData={staffData}
                  tableDataKeys={tableDataKeysForDoctors}
                  tableHeading={tableHeadingForDoctors}
                  action={actions}
                  borderBottomColor={"#60d69f"}
                  borderBottomWidth={20}
                  handleView={handleViewSinglePatient}
                />
              </div>
            </div>
          ) : Array.isArray(staffData) && staffData.length === 0 ? (
            <h5 style={{ color: "white" }}>No data found</h5>
          ) : (
            <div className="LoaderStyle">
              <Loader type="TailSpin" color="red" height={50} width={50} />
            </div>
          )}

          <div className="container-fluid">
            <div className="row" style={{ marginBottom: 20 }}>
              <img
                onClick={() => props.history.goBack()}
                src={Back}
                style={{
                  width: 45,
                  height: 35,
                  cursor: "pointer",
                }}
              />
            </div>
          </div>
        </div>

        <Dialog
          maxWidth={"lg"}
          fullWidth
          open={filterDialog}
          onClose={handleClose}
        >
          <DialogTitle>Search Filters</DialogTitle>
          <DialogContent>
            <div style={{ marginTop: 5 }}>
              <div className="row">
                <div className="col-md-6">
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                      autoOk
                      fullWidth
                      clearable
                      format="dd/MM/yyyy"
                      label="Start Date"
                      value={shiftStartTime}
                      onChange={setShiftStartTime}
                    />
                  </MuiPickersUtilsProvider>
                </div>

                <div className="col-md-6">
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                      autoOk
                      fullWidth
                      clearable
                      format="dd/MM/yyyy"
                      label="End Date"
                      value={shiftEndTime}
                      onChange={setShiftEndTime}
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </div>
            </div>
          </DialogContent>

          <DialogActions
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button onClick={handleClose} variant="outlined">
              Cancel
            </Button>

            <Button
              onClick={handleApplyFilters}
              variant="contained"
              color="primary"
            >
              Apply Filters
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <Notification msg={errorMsg} open={openNotification} />
    </div>
  );
}
