import React from "react";
import cookie from "react-cookies";
import { Route, Switch, Router, Redirect } from "react-router-dom";
import { createBrowserHistory } from "history";
import NotFound from "../components/NotFound/NotFound";

import SuccessScreen from "../components/SuccessScreen/SuccessScreen";

import regPharmaRequestList from "../views/PharmaNotes/RegisteredPharmaRequestList";
import regPharmaRequestDetail from "../views/PharmaNotes/RegisteredPharmaRequestDetail";
import regPharmaRequestIndividual from "../views/PharmaNotes/RegisteredPharmaRequestIndividual";
import regPharmaRequestQRAll from "../views/PharmaNotes/RegisteredPharmaRequestQRAll";

import addpharmacyRequest from "../views/PharmaNotes/addpharmacyRequest";
import completePharmaRequest from "../views/PharmaNotes/completePharmaRequest";
import patientDetailsWithPharamRequest from "../views/PharmaNotes/patientDetailsWithPharamRequest";
import ViewNotes from "../views/PharmaNotes/viewNotes"

const PrivateRoute = ({ component: Component, ...rest }) => {
  const [currentUser, setCurrentUser] = React.useState(
    cookie.load("current_user")
  );

  return (
    <Route
      {...rest}
      render={(props) =>
        currentUser.staffTypeId.type === "admin" ||
          currentUser.staffTypeId.type === "Committe Member" ||
          currentUser.staffTypeId.type === "Accounts Member" ||
          currentUser.staffTypeId.type === "Warehouse Member" ? (
            <Component {...props} />
          ) : (
            <Redirect to="notfound" />
          )
      }
    />
  );
};

class RPRoutes extends React.PureComponent {
  render() {
    return (
      <Switch>
        {/* <PrivateRoute
          path={`${this.props.match.url}/staff`}
          component={StaffRoutes}
        /> */}

        <Route
          exact
          path={`${this.props.match.url}`}
          component={regPharmaRequestList}
        />

        <Route
          exact
          path={`${this.props.match.url}/:listType(completed|pending)`}
          component={regPharmaRequestList}
        />

        <Route
          exact
          path={`${this.props.match.url}/details/:edrId/:serviceId/view/:subServiceId`}
          component={regPharmaRequestIndividual}
        />

        <Route
          exact
          path={`${this.props.match.url}/details/:edrId/:serviceId`}
          component={regPharmaRequestDetail}
        />

        <Route
          exact
          path={`${this.props.match.url}/details/:edrId/:serviceId/print`}
          component={regPharmaRequestQRAll}
        />

        <Route
          exact
          path={`${this.props.match.url}/details/:edrId/:serviceId/:subServiceId/print`}
          component={regPharmaRequestQRAll}
        />

        <Route
          exact
          path={`${this.props.match.url}/add`}
          component={addpharmacyRequest}
        />

        <Route
          exact
          path={`${this.props.match.url}/requestWithPatientDetails/completerequest`}
          component={completePharmaRequest}
        />

        <Route
          exact
          path={`${this.props.match.url}/success`}
          component={SuccessScreen}
        />

        <Route path={`${this.props.match.url}/notfound`} component={NotFound} />
        <Route path="*" component={NotFound} />
      </Switch>
    );
  }
}

export default RPRoutes;
