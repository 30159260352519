import React, { useEffect, useState } from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Flip from "../../components/Dial/Flip";
import TwoValue from "../../components/Dial/TwoValue";
import OneValue from "../../components/Dial/OneValue";
import IPBeds from "../../assets/img/ipbeds.png";
import EDBeds from "../../assets/img/edbeds.png";
// import axios from 'axios'
// import { registrationOfficer } from '../../public/endpoins'

export default function CommitteeMemberDashboard() {
  const matches = useMediaQuery("(min-width:600px)");
  const [parPending, setParPending] = useState("");
  const [totalInsured, setTotalInsured] = useState("");
  const [totalUnInsured, setTotalUnInsured] = useState("");
  const [patient, setPatient] = useState("");
  const [perHour, setPerHour] = useState("");

  // colors
  const [parPendingColor, setParPendingColor] = useState("");
  const [totalInsuredColor, setTotalInsuredColor] = useState("");
  const [totalUnInsuredColor, setTotalUnInsuredColor] = useState("");
  const [patientColor, setPatientColor] = useState("");
  const [perHourColor, setPerHourColor] = useState("");

  const [flip, setFlip] = useState(false);
  const [dummyData, setDummyData] = useState("");

  useEffect(() => {
    const dummyData = [
      { value: 10, label: new Date("2021-02-16T07:48:26.948Z") },
      { value: 100, label: new Date("2021-02-16T12:48:26.948Z") },
      { value: 80, label: new Date("2021-02-16T11:48:26.948Z") },
      { value: 70, label: new Date("2021-02-16T10:48:26.948Z") },
      { value: 60, label: new Date("2021-02-16T09:48:26.948Z") },
      { value: 50, label: new Date("2021-02-16T08:48:26.948Z") },
    ];

    setDummyData(dummyData);
    // axios
    //   .get(registrationOfficer)
    //   .then((res) => {
    //     console.log(res.data)
    //     if (res.data.success) {
    //       if (res.data.parPending >= 0 && res.data.parPending <= 39) {
    //         setParPendingColor('#60D69F')
    //       } else if (res.data.parPending >= 40 && res.data.parPending <= 79) {
    //         setParPendingColor('#FFBC28')
    //       } else if (res.data.parPending >= 80 && res.data.parPending <= 100) {
    //         setParPendingColor('#FF0000')
    //       }
    //       if (res.data.totalInsured >= 0 && res.data.totalInsured <= 39) {
    //         setTotalInsuredColor('#60D69F')
    //       } else if (
    //         res.data.totalInsured >= 40 &&
    //         res.data.totalInsured <= 70
    //       ) {
    //         setTotalInsuredColor('#FFBC28')
    //       } else if (
    //         res.data.totalInsured >= 80 &&
    //         res.data.totalInsured <= 100
    //       ) {
    //         setTotalInsuredColor('#FF0000')
    //       }
    //       if (res.data.totalUnInsured >= 0 && res.data.totalUnInsured <= 39) {
    //         setTotalUnInsuredColor('#60D69F')
    //       } else if (
    //         res.data.totalUnInsured >= 40 &&
    //         res.data.totalUnInsured <= 70
    //       ) {
    //         setTotalUnInsuredColor('#FFBC28')
    //       } else if (
    //         res.data.totalUnInsured >= 80 &&
    //         res.data.totalUnInsured <= 100
    //       ) {
    //         setTotalUnInsuredColor('#FF0000')
    //       }
    //       if (res.data.patient >= 0 && res.data.patient <= 39) {
    //         setPatientColor('#60D69F')
    //       } else if (res.data.patient >= 40 && res.data.patient <= 70) {
    //         setPatientColor('#FFBC28')
    //       } else if (res.data.patient >= 80 && res.data.patient <= 100) {
    //         setPatientColor('#FF0000')
    //       }
    //       if (res.data.perHour >= 0 && res.data.perHour <= 39) {
    //         setPerHourColor('#60D69F')
    //       } else if (res.data.perHour >= 40 && res.data.perHour <= 70) {
    //         setPerHourColor('#FFBC28')
    //       } else if (res.data.perHour >= 80 && res.data.perHour <= 100) {
    //         setPerHourColor('#FF0000')
    //       }
    //       setParPending(res.data.parPending)
    //       setTotalInsured(res.data.totalInsured)
    //       setTotalUnInsured(res.data.totalUnInsured)
    //       setPatient(res.data.patient)
    //       setPerHour(res.data.perHour)
    //     }
    //   })
    //   .catch((error) => {
    //     console.log(error)
    //   })
  }, []);

  const flipIt = () => {
    setFlip(true);
  };
  return (
    <div className="container-fluid" style={{ marginBottom: 10 }}>
      <div className="row">
        <div
          className="col-md-4"
          style={{ paddingLeft: 4, paddingRight: 4, marginTop: 10 }}
        >
          <div>
            <Flip
              flipped={!flip}
              flipHandler={flipIt}
              mainHeading={"Insurance Pre-Approvals Processing Pending"}
              value={70}
              mainHeadingForGraph="Insurance Pre-Approvals Processing Pending"
              color="#FFBC28"
              subHeading={"TAT"}
              childHeading={"Request to Processed"}
              time={"30"}
              data={dummyData}
            />
          </div>
        </div>

        <div
          className="col-md-4"
          style={{ paddingLeft: 4, paddingRight: 4, marginTop: 10 }}
        >
          <div>
            <Flip
              flipped={!flip}
              flipHandler={flipIt}
              mainHeading={"Insurance Bills Processing Pending"}
              mainHeadingForGraph={"Insurance Bills Processing Pending"}
              value={40}
              color="#60D69F"
              subHeading={"TAT"}
              childHeading={"Request to Processed"}
              time={"55"}
              timecolor="#FFBC28"
              data={dummyData}
            />
          </div>
        </div>

        <div
          className="col-md-4"
          style={{ paddingLeft: 4, paddingRight: 4, marginTop: 10 }}
        >
          <div>
            <Flip
              flipped={!flip}
              flipHandler={flipIt}
              mainHeading={"No. of Pre-Approvals Per Hour"}
              mainHeadingForGraph={"No. of Pre-Approvals Per Hour"}
              value={20}
              color="#60D69F"
              subHeading={"TAT"}
              childHeading={"Per Pre-Approval"}
              time={"60"}
              timecolor="#FFBC28"
              data={dummyData}
            />
          </div>
        </div>
      </div>

      <div className="row">
        <div
          className="col-md-4"
          style={{ paddingLeft: 4, paddingRight: 4, marginTop: 10 }}
        >
          <div>
            <Flip
              flipped={!flip}
              flipHandler={flipIt}
              mainHeading={"No. of Bills Processed Per Hour"}
              mainHeadingForGraph={"No. of Bills Processed Per Hour"}
              value={30}
              color="#60D69F"
              subHeading={"TAT"}
              childHeading={"Per Pre-Approval"}
              time={"25"}
              data={dummyData}
            />
          </div>
        </div>

        <div
          className="col-md-4"
          style={{ paddingLeft: 4, paddingRight: 4, marginTop: 10 }}
        >
          <div>
            <div>
              <OneValue
                time={15}
                colorTime="#60D69F"
                heading={"Cumulative total number of Pre-Approvals Processed"}
              />
            </div>
          </div>
        </div>

        <div
          className="col-md-4"
          style={{ paddingLeft: 4, paddingRight: 4, marginTop: 10 }}
        >
          <div>
            <div>
              <OneValue
                time={60}
                colorTime={"#FFBC28"}
                heading={"Cumulative total number of Bills Processed"}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div
          className="col-md-4"
          style={{ paddingLeft: 4, paddingRight: 4, marginTop: 10 }}
        >
          <div>
            <OneValue
              time={41}
              colorTime="#60D69F"
              heading={"Total Pre-Approvals Approved"}
            />
          </div>
        </div>
        <div
          className="col-md-4"
          style={{ paddingLeft: 4, paddingRight: 4, marginTop: 10 }}
        >
          <div>
            <OneValue
              time={102}
              colorTime={"#FFBC28"}
              heading={"Total Pre-Approvals Rejected"}
            />
          </div>
        </div>
        <div
          className="col-md-4"
          style={{ paddingLeft: 4, paddingRight: 4, marginTop: 10 }}
        >
          <div>
            <OneValue
              time={524}
              colorTime={"#FF0000"}
              heading={"Total Pre-Approvals Denied"}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
