import React, { useEffect, useState, useReducer } from "react";
import { withStyles } from '@material-ui/core/styles';
import Header from "../../components/Header/Header";
import classNames from "classnames";
import { FaUpload } from "react-icons/fa";
import { 
  InputLabel,
  FormHelperText,
  FormControl,
  FormLabel,
  RadioGroup,
  Button, 
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment,
  MenuItem,
  TextField,
  Select,
  Checkbox,
  Radio,
  FormControlLabel,
  useMediaQuery,
} from "@material-ui/core";
import cookie from "react-cookies";

import axios from "axios";
import {
  audioURL,
  getLabServiceCategory,
  addLabRequestBulk,
  getSingleRRRSample,
  updateSingleRRRP2Status,
  getRadServiceCategory,
  updateRRRP2ConsentFile,
} from "../../public/endpoins";
import Back from '../../assets/img/Back_Arrow.png'

import useStylesForInput from "../../assets/jss/material-dashboard-react/inputStyle";
import { styles } from "./styles";
import Loader from "react-loader-spinner";
import Icon from '@material-ui/core/Icon';
import CloseIcon from '@material-ui/icons/Close';
import MicIcon from "@material-ui/icons/Mic";
import StopIcon from "@material-ui/icons/Stop";
import MicRecorder from "mic-recorder-to-mp3";
import AccountCircle from "@material-ui/icons/SearchOutlined";
import Notification from "../../components/Snackbar/Notification.js";

import LabIcon from "../../assets/img/Lab.png";
import { displayInHumanDifference, replacePatientIdIfNull } from "../../utils/commonFunc";
import PatientDetailsQuick from "../../components/PatientDetails/PatientDetailsQuick";
import PatientDetails from "../../components/PatientDetails/PatientDetails";
import PatientDetails2 from "../../components/PatientDetails/PatientDetailsDirect";

export default function RadReqReceiverDetailsWorkIndividual(props) {
  
  const matches = useMediaQuery("(min-width:600px)");
  const classes = useStylesForInput();

  const mystyle = {
    color: "white",
    backgroundColor: "DodgerBlue",
    padding: "10px",
    fontFamily: "Arial"
  };


  const styles = {
    patientDetails: {
      backgroundColor: 'white',
      borderRadius: 5,
      padding: '20px',
    },
    headingStyles: {
      fontWeight: 'bold',
      color: 'grey',
      fontSize: 12,
    },
    textStyles: {
      fontWeight: '700',
      color: 'black',
      fontSize: 14,
    },


    stylesForButton: {
      color: "white",
      cursor: "pointer",
      borderRadius: 5,
      backgroundColor: "#2c6ddd",
      width: "130px",
      height: "45px",
      outline: "none",
    },
    inputContainerForTextField: {
      // marginTop: 10,
    },
    inputContainerForDropDown: {
      marginTop: 10,
    },
    textFieldPadding: {
      // paddingLeft: 5,
      // paddingRight: 5,
    },
    save: {
      color: "white",
      cursor: "pointer",
      borderRadius: 5,
      backgroundColor: "#ba55d3",
      width: "130px",
      height: "45px",
      outline: "none",
    },
    generate: {
      color: "white",
      cursor: "pointer",
      borderRadius: 5,
      backgroundColor: "#e877a1",
      height: "45px",
      width: "190px",
      outline: "none",
    },
    None: {
      display: "none",
    },
    form: {
      backgroundColor: "white",
      borderRadius: "5px",
      marginTop: "10px",
      padding: "10px",
      textAlign: "center",
    },
    form2: {
      backgroundColor: "white",
      borderRadius: "5px",
      padding: "5px 15px",
    },
    form3: {
      backgroundColor: "white",
      borderRadius: "5px",
      padding: "0px 15px",
    },
    upload: {
      backgroundColor: "#E2E2E2",
      border: "0px solid #ccc",
      borderRadius: "5px",
      color: "gray",
      width: "100%",
      height: "60px",
      cursor: "pointer",
      padding: "15px",
    },
    input: {
      display: "none",
    },


  }



  const GreenCheckbox = withStyles({
    root: {
      color: '#AAAAAA',
      '&$checked': {
        color: '#AAAAAA',
      },
    },
    checked: {
      color: '#2C6DDD',
      '&$checked': {
        color: '#2C6DDD',
      },
    },
  })((props) => <Checkbox color="default" {...props} />);

  


  const initialState = {
    bulkTestSelected: false,
    seletedRadItems: [],
    resetedOn: "",
    defaultItems: {},
    seletedLabItems: [],
    labPriority: "",
    labTechnician: "",
    labComments: "",
    labTechniciansList: [],
    reportStatus: "no",
    reportedRadiologyDoctor: "no",
  };
  function reducer(state, { field, value }) {
    return {
      ...state,
      [field]: value,
    };
  }
  const [state, dispatch] = useReducer(reducer, initialState);
  const {
    bulkTestSelected,
    seletedRadItems,
    resetedOn,
    defaultItems,
    seletedLabItems,
    labPriority,
    labTechnician,
    labComments,
    labTechniciansList,
    reportStatus,
    reportedRadiologyDoctor,
  } = state;

  const handleCheckupChange = (event) => {
    dispatch({field: 'bulkTestSelected', value: event.target.value});
  };

  const uniqueCodeField = (name) => {
    name = name.toLowerCase();
    name = name.replace(/[^a-z0-9]+/g, '-');
    name = name.replace(/^-+|-+$/g, '');
    return name;
  }


  const [currentUser] = useState(cookie.load("current_user"));
  const [timer, setTimer] = useState(null);
  const [selectedLab, setSelectedLab] = useState("");
  const [categoriesData, setCategoriesData] = useState([]);
  const [mItems, setMItems] = useState([]);
  const [myDefaultItems, setMyDefaultItems] = useState([]);

  const [patientDetails, setPatientDetails] = useState("");
  const [selectedRecord, setSelectedRecord] = useState({});

  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setsuccessMsg] = useState("");
  const [openNotification, setOpenNotification] = useState(false);
  const [viewSafetyDialog, setViewSafetyDialog] = useState(false);

  const [ episodeId, setEpisodeId ] = useState(props.match.params.id);
  const [ radRequestId, setRadRequestId ] = useState(props.match.params.subRequestId);
  const [ radServiceId, setRadServiceId ] = useState(props.match.params.serviceId);
  const [ radRequest, setRadRequest ] = useState({});
  const [ radServices, setRadServices ] = useState([]);
  const [ radService, setRadService ] = useState({});
  const [ status, setStatus ] = useState("");
  const [ mriChecklist, setMRIChecklist ] = useState({});

  const [ allergies, setAllergies ] = useState("");
  const [openConsentDialog, setOpenConsentDialog] = useState(false);
  const [modeDialog, setModeDialog] = useState("");

  
  if (openNotification) {
    setTimeout(() => {
      setOpenNotification(false);
      setErrorMsg("");
      setsuccessMsg("");
    }, 4000);
  }

  useEffect(() => {
    axios
      .get(`${getSingleRRRSample}/${episodeId}/${radRequestId}`)
      .then((res) => {
        console.log('handleView::', res.data.data);
        if (res.data.success) {

          let mData = res.data.data[0];
          let remoteRecord = replacePatientIdIfNull(mData);
          setSelectedRecord(remoteRecord);

          const object = {
            '_id': remoteRecord._id,
            patientName: remoteRecord.patientId.name[0].given[0] + " " + remoteRecord.patientId.name[0].family,
            episodeNumber: remoteRecord.requestNo,//remoteRecord.patientId.identifier[0].value,
            mrn: remoteRecord.patientId.identifier[0].value,
            age: remoteRecord.patientId.age,
            gender: remoteRecord.patientId.gender,
            weight: remoteRecord.patientId.weight,
            department: remoteRecord.currentLocation,
            chiefComplaint: remoteRecord.chiefComplaint.length > 0 ? remoteRecord.chiefComplaint.pop().chiefComplaintId.short_desc : "N/A",
            chiefComplaintOthers: remoteRecord.chiefComplaintOthers,
          };
          setPatientDetails(object);


          if (remoteRecord.dcdForm.pastMedicalHistory){
            if (remoteRecord.dcdForm.pastMedicalHistory.details){
              let det = remoteRecord.dcdForm.pastMedicalHistory.details.filter(obj => obj.name === "Allergies");
              if (det[0].values){
                let objx = [];
                Object.entries(det[0].values).map((obj, entry) => {
                  objx.push( obj[1].name );
                  // console.log("pastMedicalHistory::details", obj[1].name);
                });
                setAllergies(objx.join(', '));
              }
            }
          }

          let radServicesX = remoteRecord.radRequest;
          if (radServicesX){
            let labXN = radServicesX;
            labXN.requestedName = labXN.requestedBy.name[0].given[0] + " " + labXN.requestedBy.name[0].family;
            console.log('radServicesX', labXN.requestedBy.name);
            console.log('radServicesX', labXN);

            setMRIChecklist(labXN.checklist);

            setRadRequest(labXN)
            // setRadRequest(radServicesX[0])
            setRadServices(radServicesX.services);

            let radServiceX = labXN.services.filter(obj => obj.requestId === radServiceId);
            setRadService(radServiceX[0]);
            console.log('labXN::', radServiceX[0]);
            loadAsyncData( radServiceX[0].category, radServiceX[0] )
            setStatus(radServiceX[0].porterStatus);

            console.log('radServicesX::radServiceX', radServiceX[0].cServiceId);


            dispatch({
              field: 'reportStatus',
              value: (radServiceX[0].status==="completed"?"yes":"no")
            });

            dispatch({
              field: 'reportedRadiologyDoctor',
              value: (radServiceX[0].radiologyDoctorStatus==="completed"?"yes":"no")
            });

          }

        }
      })
      .catch((e) => {
        console.log("error after searching patient request", e);
      });
    /*getLabTechniciansCall( currentUser._id, dispatch, setOpenNotification, setErrorMsg );*/
  }, []);

  const loadAsyncData = (category, radServiceDB) => {
    // labServiceCategoryCall(setCategoriesData);
    axios
      .get(`${getRadServiceCategory}?filterServices=${category}`)
      .then((res) => {
        if (res.data.success) {
          // console.log('labServiceCategoryCall', res.data.data);
          let remoteData = res.data.data;
          setCategoriesData(remoteData);

          let itemsInList = [];
          let defaultItemsInList = [];
          let newDefaultItemsInList = [];

          // Items Extractor
          remoteData.map((d, index) => {
            let slug = uniqueCodeField(d._id);
            let values = [];

            if (d.items.length > 0){
              d.items.map((nd, nindex) => {
                // values.push(nd);
                itemsInList.push(nd);

                if (nd.checked){
                  values.push(nd._id);
                }

              });
            }
            newDefaultItemsInList[ `id-${slug}` ] = {
              'id': slug,
              'name': d._id,
              'items': values,
            };
          });

          dispatch({ field: 'resetedOn', value: 'now' });

          setMyDefaultItems(newDefaultItemsInList);
          dispatch({
            field: 'defaultItems',
            value: newDefaultItemsInList
          });
          setMItems(itemsInList);
          // setCategoriesData([
          //   {_id: "Hello"}
          // ])



          console.log('radService::', radServiceDB);

          let alreadyAddedInDB = [];
          if ( radServiceDB.cServiceId.length >= 0 ){
            Object.entries(radServiceDB.cServiceId).map((obj, entry) => {
              console.log('radServicesX::radServiceX::cServiceId', obj[1]);
              alreadyAddedInDB.push({
                id: obj[1].mRef._id,
                priority: 'Normal',
                xUrgent : 'No',
                notes: {
                  text: '',
                  audio: null,
                }
              });
            })
          }

          console.log('radServicesX::radServiceX::alreadyAddedInDB::', alreadyAddedInDB);
          dispatch({
            field: 'seletedRadItems',
            value: alreadyAddedInDB
          });
        }
      })
      .catch((e) => {
        console.log("error while searching req", e);
      });

  }

  const itemsExistInDefaultSelection = (slug, item) => {
    if ( `id-`+slug in defaultItems ){
      let defaultSelected = defaultItems[ `id-`+slug ].items;
      if ( defaultSelected.includes( item ) )
      { // Already Exists Item and Remove it
        return true;
      }
    }

    return false;
  };
  const associateSelectedItems = (slug) => {
    
    let defaultSelected = [];
    let currentIndex = -1;
    let sPriority = 'Normal';
    let sUrgent   = 'No';

    console.log( 'RadReq::addSelectedItems::', slug )

    if ( `id-${slug}` in defaultItems )
    {
      let d = defaultItems[ `id-${slug}` ];
      console.log( 'RadReq::addSelectedItems::', d );

      sPriority = d.priority;
      sUrgent = d.xUrgent;
      defaultSelected = d.items;
      d.menuOpened = false;

    }
 
    // Selected Items
    let readyToAdd = seletedRadItems;
    console.log( 'RadReq::ConsoleRadRequest::readyToAdd', readyToAdd );

    if ( defaultSelected.length > 0 ) {
      defaultSelected.map((dx, dindex) => {
        console.log( 'RadReq::ConsoleRadRequest::dx::dindex::', dx, dindex );

        let itemExists = readyToAdd.filter((element, index, array) => {
            // Return true or false
            return element.id === dx;
        });

        if ( itemExists.length<=0 ){
          readyToAdd.push({
            id: dx,
            priority: sPriority,
            xUrgent : sUrgent,
            notes: {
              text: '',
              audio: null,
            }
          });
        }
      });

      dispatch({
        field: 'seletedRadItems',
        value: readyToAdd
      });
    }else{
      setOpenNotification(true);
      setErrorMsg('Please select an item from the above list.');
    }

  };

  const handleView = (rec) => {
    let url = '/dashboard/home/rrrp2/details/'+episodeId+'/'+radRequestId+'/view/'+rec.requestId+'';
    props.history.push({
      pathname: url,
    });
  }

  const handleChange = (event) => {
    setStatus(event.target.value);
  };

  const handleSave = () => {
    if (radService){
      if (radService.status !== "completed" || radService.radiologyDoctorStatus !== "completed"){

        let reqStatus = reportStatus === "yes"?'completed':'pending';
        let repStatus = reportedRadiologyDoctor === "yes"?'completed':'pending';


        axios
          .put(updateSingleRRRP2Status, {
            edrId: episodeId,
            requestId: radRequestId,
            serviceId: radServiceId,
            requestStatus: reqStatus,
            completeType: 'individual',
            reportStatus: repStatus,
            staffId: currentUser._id,
          })
          .then((res) => {
            if (res.data.success) {
              setOpenNotification(true);
              setsuccessMsg(`The Rad Request Status has been changed!`);
              window.location.reload();
            }
          })
          .catch((e) => {
            setOpenNotification(true);
            setErrorMsg("Unable to Update the Record");
          });
      }else{
        setOpenNotification(true);
        setErrorMsg("You cannot do this operation because this task has already completed!");
      }
    }else{
      setOpenNotification(true);
      setErrorMsg("An error occurred while saving the record!");
    }
  }



  const handleUploadFile = (filetype) => {
    let formData = new FormData();
    if (selfConsentFile) {
      formData.append("consentFile", selfConsentFile, selfConsentFile.name);
    }

    formData.append( "edrId",         episodeId );
    formData.append( "staffId",       currentUser._id );
    formData.append( "requestId",     radRequestId );
    formData.append( "serviceId",     radServiceId );
    formData.append( "fileField",     filetype );
    formData.append( "completeType",  'individual' );

    console.log('FormData::', formData.entries());

    let finalURL = updateRRRP2ConsentFile;
    // let finalURL="https://fsdlkfjlasfjlsdlaf.com";

    axios
      .put(finalURL, formData)
      .then((res) => {
        if (res.data.success) {
          setOpenNotification(true);
          setsuccessMsg(`The consent file has been uploaded for ${filetype}!`);
          window.location.reload();
        }
      })
      .catch((e) => {
        setOpenNotification(true);
        setErrorMsg("Unable to Update the Record");
      });
  }

  const handleAssociateChange = (event) => {

    let nSlug = event.target.name;
    console.log( 'RadReq::handleChange::', event.target.checked, event.target.name, event.target.value, `id-`+nSlug, defaultItems );
    if ( `id-`+nSlug in defaultItems ){
      let defaultSelected = defaultItems[ `id-`+nSlug ].items;

      console.log( 'RadReq::handleChange::', defaultSelected );

      if (defaultSelected.includes(event.target.value)){ // Already Exists Item and Remove it
        let indexOfItem = defaultSelected.indexOf( event.target.value );
        defaultSelected.splice( indexOfItem, 1 );
      }else{ // Adding Item
        defaultSelected.push( event.target.value );
      }

      let nItems = defaultItems;
      nItems[ `id-`+nSlug ].items = defaultSelected;
      console.log('labXN::nItems', nItems);
      dispatch({
        field: 'defaultItems',
        value: nItems
      });
    }
  }


  const [ insuranceApprovalNo, setInsuranceApprovalNo ] = useState("");
  const [ insuranceRequestApproved, setInsuranceRequestApproved ] = useState("no");
  const [ errorFileConsentMsg, setErrorFileConsentMsg ] = useState("");
  const [ selfConsentFileObject, setSelfConsentFileObject ] = useState({
    title: 'Consent to Upload',
  });

  const [ selfConsentFile, setSelfConsentFile ] = useState("");
  const [ disableButtonIfSelected, setDisableButtonIfSelected ] = useState(true);
  const [ approvalDialogSubmit, setApprovalDialogSubmit ] = useState(false);  

  const onSlipUpload = (event) => {
    // event.preventDefault()
    console.log("FormData::upload::", event.target, event.target.name, event.target.files);
    var file = event.target.files[0];
    var fileType = file.name.slice(file.name.length - 3);
    let file_size = event.target.files[0].size;


    console.log("FormData::upload::", file, fileType, file_size);

    setDisableButtonIfSelected(true);

    if (
      event.target.name === "consentSelfpay" || 
      event.target.name === "consentSelfpay2"
    ) {
      
      setErrorFileConsentMsg("");

      let obj = {};
      obj.contentType = fileType;
      obj.title = "Consent to Upload - " + file.name;
      obj.url = file.name;
      obj.size = file.size.toString();
      // obj.creation = file.lastModifiedDate.toISOString().substr(0, 10);
      console.log("FormData::upload::object::", 'file::file::', obj);
      setSelfConsentFileObject(obj);

      // setProgress(true)
      var reader = new FileReader();
      var url = reader.readAsDataURL(file);
      console.log("FormData::upload::fileReader::", url);

      reader.onloadend = function() {
        if (file_size <= 10500000) {
          if (
            fileType === "png" || fileType === "PNG" || 
            fileType === "peg" || fileType === "PEG" || 
            fileType === "jpg" || fileType === "JPG" || 
            fileType === "jpeg" || fileType === "JPEG" || 
            fileType === "pdf" || fileType === "PDF"
          ) {
            // setImagePreview([reader.result]);
            setDisableButtonIfSelected(false);
            setSelfConsentFile(file);
            console.log("FormData::upload::fill::", file);
          } else {
            setErrorFileConsentMsg("Only jpg, jpeg and png are be allowed");
          }
        } else {
          setErrorFileConsentMsg("Files size should be less Than or Equal to 10MB");
        }
      };
    }
  };


  const handleRadioFormChange = (event) => {
    dispatch({field: event.target.name, value: event.target.value});
  };

  return (
    
    <div
      style={{
        backgroundColor: "rgb(19 213 159)",
        position: "fixed",
        display: "flex",
        width: "100%",
        height: "100%",
        flexDirection: "column",
        flex: 1,
        overflowY: "scroll",
      }}
    >
      <Header history={props.history} />

      <PatientDetailsQuick
        edrId={episodeId} />

      <div className="cPadding">

        {patientDetails && radRequest ? (
          <div className="mb-3">

            <div className="card border-0">
              <div className="card-header bg-primary text-white"><strong>Patient Clinical Information</strong></div>
              <div className="card-body">
                <div className="row row-10">
                  <div className="col-6 col-lg-1 card-detail-block block-xdiv mb-lg-2 mb-4"><strong className="lblX">Triage Level</strong><span className="valX">-</span></div>
                  <div className="col-6 col-lg-2 card-detail-block block-xdiv mb-lg-2 mb-4"><strong className="lblX">Chief Complaint</strong><span className="valX">{selectedRecord.chiefComplaint.chiefComplaintId.short_name}</span></div>
                  <div className="col-12 col-lg-3 card-detail-block block-xdiv mb-lg-2 mb-4"><strong className="lblX">Allergies</strong><span className="valX">{allergies}</span></div>
                  <div className="col-6 col-lg-3 card-detail-block block-xdiv mb-lg-2 mb-4"><strong className="lblX">Medication</strong><span className="valX">0 Medications</span></div>
                  <div className="col-6 col-lg-3 card-detail-block block-xdiv mb-lg-2 mb-4"><strong className="lblX">Diagnosis</strong><span className="valX">0 Diagnosis</span></div>
                </div>
              </div>
            </div>
            <div className="mt-3 mb-0 card p-3">
              <div className="row row-5">
                <div className="col-6 col-lg-3 card-detail-block block-xdiv mb-2"><strong className="lblX">Episode #</strong><span className="valX">{patientDetails.episodeNumber}</span></div>
                <div className="col-6 col-lg-3 card-detail-block block-xdiv mb-2"><strong className="lblX">MRN #</strong><span className="valX">{patientDetails.mrn}</span></div>
                <div className="col-6 col-lg-3 card-detail-block block-xdiv mb-2"><strong className="lblX">Requester</strong><span className="valX">{radService.name}</span></div>
                <div className="col-6 col-lg-3 card-detail-block block-xdiv mb-2"><strong className="lblX">Date and Time</strong><span className="valX">{displayInHumanDifference(radService.requestedAt)}</span></div>
              </div>
            </div>
          </div>
        ) : (
          undefined
        )}

        <h4 className="h6 text-white mb-3"
          style={{
            fontWeight: '700'
          }}>{ radService.requestId } - Profiles/Radiology Requests</h4>
        {
        patientDetails && radService ?
        <>
        
        </>:undefined
        }
        <div className="row row-5">
          {
            radService ? 
              <div className="col-12">
                <div className="card card-detail-info mb-2">
                  <div className="row row-5">
                    <div className="col-sm-6 card-detail-block block-xdiv mb-2"><strong className="lblX">Request ID</strong><span className="valX">{radService.requestId}</span></div>
                    <div className="col-sm-6">&nbsp;</div>
                    <div className="col-sm-12 card-detail-block block-xdiv mb-2"><strong className="lblX">Profile/Test</strong><span className="valX">{radService.category}: <span>{radService.name}</span></span></div>
                    <div className="col-sm-12 card-detail-block block-xdiv">
                      <strong className="lblX">Notes</strong><span className="valX"><span>{ radService.notes }</span></span>
                      <audio
                        style={{
                          marginRight: 10,
                          width: matches ? 450 : 260,
                          marginTop: 6,
                        }}
                        src={ `${audioURL}/${radService.audio}` }
                        controlsList="nodownload"
                        controls="controls"
                        />
                    </div>
                    <div className="col-12 text-right">
                      <span onClick={() => {
                        setViewSafetyDialog(true);
                      }}><i className="fa fa-angle-right"></i> View Safety / MRI Con</span>
                    </div>
                    <div className="col-12">
                      <hr />
                      <strong>Associated Procedure(s)</strong>
                      {seletedRadItems.length > 0 ? (
                        
                        seletedRadItems.map((i, itemIx) => {
                          let item = mItems.filter(function callback(element, index, array) {
                              // Return true or false
                              return element._id === i.id;
                          });
                          return (
                            <div className="serviceOffers pb-0">
                              <div className="serviceHeader">
                                <div className="serviceDesc-Name">{i.xUrgent==='Yes'?<i className="fa fa-info-circle"></i>:<i className="fa fa-caret-right"></i>}<span className="ml-2"><strong>{item[0].category}</strong>: {item[0].name}</span></div>
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <p>No Procedures Associated at the moment.</p>
                      )} 
                    </div>
                  </div>
                  <div className="card-status-action">
                    <span className={classNames(`badge`, (radService.porterStatus=="Collected"?'badge-success':'status-pending'))}>{radService.porterStatus}</span>
                    { 
                      radService.urgent==="Yes"?<span className="ml-info">!</span>:undefined
                    }
                  </div>
                </div>
              </div>
              :undefined
          }
          {
            radServices ? 
              <>
                <div className="col-12 mt-2"></div>
                <div className={"col-" + (mriChecklist.pregnantCheck=="yes"?'6':'12')}>
                  <Button
                    style={{
                      backgroundColor: "#845DC2",
                      color: "white",
                      cursor: "pointer",
                      borderRadius: 5,
                      height: matches ? 56 : 45,
                      outline: "none",
                      width: "100%",
                    }}
                    onClick={(e) => {
                      if (radService.consentMediaFile === "" || radService.consentMediaFile === null || radService.consentMediaFile === undefined){
                        setOpenConsentDialog(true);
                        setModeDialog("Consent")
                      }else{
                        window.open(`${audioURL}/${radService.consentMediaFile.replace('\\','/')}`, '_blank', 'noreferrer');
                        //console.log('FormData::', radService.consentMediaFile)
                      }
                    }}
                    variant="contained"
                    color="primary"
                    fullWidth>{(radService.consentMediaFile === "" || radService.consentMediaFile === null || radService.consentMediaFile === undefined)?"Upload":"View"} Contrast Media Consent{(radService.consentMediaFile === "" || radService.consentMediaFile === null || radService.consentMediaFile === undefined)?", If required":""}</Button>
                </div>
                {
                  mriChecklist.pregnantCheck=="yes"?
                  <div className="col-6">
                    <Button
                      style={{
                        backgroundColor: "#845DC2",
                        color: "white",
                        cursor: "pointer",
                        borderRadius: 5,
                        height: matches ? 56 : 45,
                        outline: "none",
                        width: "100%",
                      }}
                      onClick={(e) => {
                        if (radService.pregnancyMediaFile === "" || radService.pregnancyMediaFile === null || radService.pregnancyMediaFile === undefined){
                          setOpenConsentDialog(true);
                          setModeDialog("Pregnancy")
                        }else{
                          window.open(`${audioURL}/${radService.pregnancyMediaFile.replace('\\','/')}`, '_blank', 'noreferrer');
                          //console.log('FormData::', radService.pregnancyMediaFile)
                        }
                      }}
                      variant="contained"
                      color="primary"
                      fullWidth>{(radService.pregnancyMediaFile === "" || radService.pregnancyMediaFile === null || radService.pregnancyMediaFile === undefined)?"Upload":"View"} Pregnancy Consent</Button>
                  </div>:undefined
                }
                <div className="col-12">
                  <div className="card card-body mt-4">
                    <div className="row align-items-center-md">
                      <div className="col-8"><FormLabel component="legend" className="mb-0">Completed</FormLabel></div>
                      <div className="col-4">
                        <RadioGroup row aria-label="reportStatus" name="reportStatus" value={reportStatus} onChange={handleRadioFormChange}>
                          <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                          <FormControlLabel value="no" control={<Radio />} label="No" />
                        </RadioGroup>
                      </div>
                    </div>
                    <div className="row align-items-center-md">
                      <div className="col-8"><FormLabel component="legend" className="mb-0">Reported by <br />Radiology Doctor</FormLabel></div>
                      <div className="col-4">
                        <RadioGroup row aria-label="reportedRadiologyDoctor" name="reportedRadiologyDoctor" value={reportedRadiologyDoctor} onChange={handleRadioFormChange}>
                          <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                          <FormControlLabel value="no" control={<Radio />} label="No" />
                        </RadioGroup>
                      </div>
                    </div>
                  </div>
                </div>
                {
                  radService.status==="completed" && radService.radiologyDoctorStatus==="completed"?undefined:
                  <div className="col-12 mt-3 mt-lg-3 text-center">
                    <Button
                      style={{
                        backgroundColor: "#AD6BBF",
                        color: "white",
                        cursor: "pointer",
                        borderRadius: 5,
                        backgroundColor: "rgb(173, 107, 191)",
                        height: matches ? 56 : 45,
                        outline: "none",
                        width: "100%",
                      }}
                      onClick={handleSave}
                      variant="contained"
                      color="primary"
                      fullWidth>Submit</Button>
                  </div>

                }
              </>
            : undefined
          }
        </div>
        <div className='row mt-4 mb-4'>
          <div className='col-md-12 col-sm-12 col-12'>
            <img
              onClick={() => {
                let url = '/dashboard/home/rrrp2/details/'+episodeId+'/'+radRequestId;
                props.history.push({
                  pathname: url,
                });
              }}
              src={Back}
              style={{
                width: 45,
                height: 35,
                cursor: 'pointer',
              }}
            />
          </div>
        </div>
      </div>



        <Dialog
            aria-labelledby="form-dialog-title"
            open={openConsentDialog && modeDialog === "Pregnancy"}
            maxWidth="md"
          >
          <DialogTitle
              id="simple-dialog-title"
              className=""
            ><strong>Pregnancy</strong>: Upload a Consent</DialogTitle>
          <DialogContent>

            <label
              style={{
                ...styles.upload,
                // fontSize: matches ? " " : 12
                fontSize: matches ? 15 : 12,
                height: 55,
              }}
            >
              <TextField
                required
                type="file"
                accept="image/*,application/pdf"
                style={styles.input}
                onChange={onSlipUpload}
                onClick={(event) => {
                  event.target.value = null;
                }}
                name="consentSelfpay2"
                Error={errorFileConsentMsg}
              />
              <FaUpload />
              {matches ? <>&nbsp;&nbsp;</> : ""} {selfConsentFileObject.title}
            </label>


            <Button
              style={{
                backgroundColor: "#AD6BBF",
                color: "white",
                cursor: "pointer",
                borderRadius: 5,
                backgroundColor: "rgb(173, 107, 191)",
                height: matches ? 56 : 45,
                outline: "none",
                width: "100%",
              }}
              className="mt-2 mb-3"
              onClick={() => handleUploadFile("pregnancy")}
              variant="contained"
              color="primary"
              disabled={disableButtonIfSelected}
              fullWidth>Upload &amp; Submit</Button>


          </DialogContent>
        </Dialog>

        <Dialog
            aria-labelledby="form-dialog-title"
            open={openConsentDialog && modeDialog === "Consent"}
            maxWidth="md"
          >
          <DialogTitle
              id="simple-dialog-title"
              className=""
            ><strong>Consent</strong>: Contrast Media Consent</DialogTitle>
          <DialogContent>

            <label
              style={{
                ...styles.upload,
                // fontSize: matches ? " " : 12
                fontSize: matches ? 15 : 12,
                height: 55,
              }}
            >
              <TextField
                required
                type="file"
                accept="image/*,application/pdf"
                style={styles.input}
                onChange={onSlipUpload}
                onClick={(event) => {
                  event.target.value = null;
                }}
                name="consentSelfpay"
                Error={errorFileConsentMsg}
              />
              <FaUpload />
              {matches ? <>&nbsp;&nbsp;</> : ""} Upload Contrast Media Consent
            </label>


            <Button
              style={{
                backgroundColor: "#AD6BBF",
                color: "white",
                cursor: "pointer",
                borderRadius: 5,
                backgroundColor: "rgb(173, 107, 191)",
                height: matches ? 56 : 45,
                outline: "none",
                width: "100%",
              }}
              className="mt-2 mb-3"
              onClick={() => handleUploadFile("consent")}
              variant="contained"
              color="primary"
              disabled={disableButtonIfSelected}
              fullWidth>Upload Consent</Button>


          </DialogContent>
        </Dialog>

        <Dialog
            aria-labelledby="form-dialog-title"
            open={viewSafetyDialog}
            onClose={() => {
              setViewSafetyDialog(false)
            }}
            maxWidth="md"
          >
          <DialogTitle
              id="simple-dialog-title"
              className=""
            ><strong>Safety</strong> / MRI Con</DialogTitle>
          <DialogContent>

            
            <div className="row mb-2">
              <div className="col-10">Assistant Required?</div>
              <div className="col-2 text-right">{mriChecklist.assistantRequired=="yes"?<i className="fa fa-check text-success"></i>:<i className="fa fa-close text-danger"></i>}</div>
            </div>

            <h4 className="h6 mt-4 mb-3"><strong>Safety Check</strong></h4>
            <div className="row mb-2">
              <div className="col-10">Could the patient be pregnant?</div>
              <div className="col-2 text-right">{mriChecklist.pregnantCheck=="yes"?<i className="fa fa-check text-success"></i>:<i className="fa fa-close text-danger"></i>}</div>
            </div>
            <div className="row mb-2">
              <div className="col-10">Is the patient breast feeding?</div>
              <div className="col-2 text-right">{mriChecklist.breastFeeding=="yes"?<i className="fa fa-check text-success"></i>:<i className="fa fa-close text-danger"></i>}</div>
            </div>
            <div className="row mb-2">
              <div className="col-10">Is the patient a high infection risk?</div>
              <div className="col-2 text-right">{mriChecklist.highInfectionRisk=="yes"?<i className="fa fa-check text-success"></i>:<i className="fa fa-close text-danger"></i>}</div>
            </div>
            <div className="row mb-2">
              <div className="col-10">Is the patient diabetic?</div>
              <div className="col-2 text-right">{mriChecklist.patientDiabetic=="yes"?<i className="fa fa-check text-success"></i>:<i className="fa fa-close text-danger"></i>}</div>
            </div>
            <div className="row mb-2">
              <div className="col-9">Is the diabetes controlled:</div>
              <div className="col-3 text-right">{mriChecklist.diabetesControlled}</div>
            </div>
            <div className="row mb-2">
              <div className="col-10">Does the patient have any allergies?{mriChecklist.patientAllergies=="yes"?<><br /><small>{mriChecklist.patientAllergiesDesc}</small></>:undefined}</div>
              <div className="col-2 text-right">{mriChecklist.patientAllergies=="yes"?<i className="fa fa-check text-success"></i>:<i className="fa fa-close text-danger"></i>}</div>
            </div>
            
            <h4 className="h6 mb-2 mt-4"><strong>MRI Contraindications</strong></h4>
            <div className="row mb-2">
              <div className="col-10">A pacemaker?</div>
              <div className="col-2 text-right">{mriChecklist.pacemaker=="yes"?<i className="fa fa-check text-success"></i>:<i className="fa fa-close text-danger"></i>}</div>
            </div>
            <div className="row mb-2">
              <div className="col-10">A cerebral aneurysm clip?</div>
              <div className="col-2 text-right">{mriChecklist.cerebralAneurysmClip=="yes"?<i className="fa fa-check text-success"></i>:<i className="fa fa-close text-danger"></i>}</div>
            </div>
            <div className="row mb-2">
              <div className="col-10">Cochlear implants?</div>
              <div className="col-2 text-right">{mriChecklist.cochlearImplants=="yes"?<i className="fa fa-check text-success"></i>:<i className="fa fa-close text-danger"></i>}</div>
            </div>
            <div className="row mb-2">
              <div className="col-10">Neuro stimulators?</div>
              <div className="col-2 text-right">{mriChecklist.neuroStimulators=="yes"?<i className="fa fa-check text-success"></i>:<i className="fa fa-close text-danger"></i>}</div>
            </div>
            <div className="row mb-2">
              <div className="col-10">Programmable hydrocephalus shunt?</div>
              <div className="col-2 text-right">{mriChecklist.programmableHydrocephalusShunt=="yes"?<i className="fa fa-check text-success"></i>:<i className="fa fa-close text-danger"></i>}</div>
            </div>
            <div className="row mb-2">
              <div className="col-10">Metallic foreign body in eye?</div>
              <div className="col-2 text-right">{mriChecklist.metallicForeignBodyInEye=="yes"?<i className="fa fa-check text-success"></i>:<i className="fa fa-close text-danger"></i>}</div>
            </div>
            <div className="row mb-2">
              <div className="col-10">Other metallic implants?</div>
              <div className="col-2 text-right">{mriChecklist.otherMetallicImplants=="yes"?<i className="fa fa-check text-success"></i>:<i className="fa fa-close text-danger"></i>}</div>
              <div className="col-12">{mriChecklist.otherMetallicImplantsDesc}</div>
            </div>
          </DialogContent>
        </Dialog>

      <Notification
        msg={errorMsg}
        open={openNotification}
        success={successMsg}
      />
    </div>
  );
}