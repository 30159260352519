import axios from 'axios'
import { getEDNurses, searchEDNurses } from '../../public/endpoins'
export function getAllEDNurses(
  setStaffData,
  setGeneralArray,
  setOpenNotification,
  setErrorMsg
) {
  axios
    .get(getEDNurses)
    .then((res) => {
      if (res.data.success) {
        console.log(res.data.data)
        // res.data.data.map(
        //   (val) => (
        //     (val.nurseName = val.name[0].given[0] + ' ' + val.name[0].family),
        //     (val.nurseId = val.identifier[0].value)
        //   )
        // )
        setGeneralArray(res.data.data)
      } else if (!res.data.success) {
        setErrorMsg(res.data.error)
        setOpenNotification(true)
      }
      return res
    })
    .catch((e) => {
      console.log('error: ', e)
    })
}

export const getSearchEDNurses = (
  a,
  setGeneralArray,
  setOpenNotification,
  setErrorMsg
) => {
  axios
    .get(searchEDNurses + '/' + a)
    .then((res) => {
      console.log('res', res)
      if (res.data.success) {
        if (res.data.data) {
          console.log('Search Sensei ', res.data.data)
          setGeneralArray(res.data.data)
          // res.data.data.map(
          //   (d) =>
          //     (d.patientName =
          //       d.patientId.name[0].given[0] + " " + d.patientId.name[0].family)
          // );
          console.log(
            'after map Search Sensei '
            // res.data.data.map((patInfo) => {
            //   console.log(
            //     ("" ,patInfo.patientName = patInfo.patientId.name[0].given[0])
            //   );
            // })
          )
        } else {
          console.log('Your  Sensei Not Found')
          setGeneralArray(' ')
        }
      } else if (!res.data.success) {
        setErrorMsg(res.data.error)
        setOpenNotification(true)
      }
    })
    .catch((e) => {
      console.log('error after searching patient request', e)
    })
}
