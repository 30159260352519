/* eslint-disable react/jsx-indent */
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import NotFound from '../components/NotFound/NotFound';
import AddEditShifts from '../views/UserManagement/shifts/addEditShifts'
import Shifts from '../views/UserManagement/shifts/shifts'

class ShiftsRoutes extends React.PureComponent {
  render() {
    return (
      <Switch>
        <Route
          exact
          path={`${this.props.match.url}`}
          component={Shifts}
        />
        <Route
          path={`${this.props.match.url}/add`}
          component={AddEditShifts}
        />
        <Route
          path={`${this.props.match.url}/edit`}
          component={AddEditShifts}
        />
        <Route path="*" component={NotFound} />
      </Switch>
    );
  }
}
export default ShiftsRoutes;