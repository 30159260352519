import React, { Component } from "react";
import _ from "lodash";
import socket from "./js/socket";
import PeerConnection from "./js/PeerConnection";
import MainWindow from "./js/MainWindow";
import CallWindow from "./js/CallWindow";
import CallModal from "./js/CallModal";
import "./css/app.css";

import cookie from "react-cookies";

class App extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      clientId: "",
      callWindow: "",
      callModal: "",
      callFrom: "",
      localSrc: null,
      peerSrc: null,
      currentUser: cookie.load("current_user"),

      callTo: "21",

      userId: "",
    };
    this.pc = {};
    this.config = null;
    this.startCallHandler = this.startCall.bind(this);
    this.endCallHandler = this.endCall.bind(this);
    this.rejectCallHandler = this.rejectCall.bind(this);
  }

  componentDidMount() {
    this.setState({ userId: this.props.userId }, () =>
      console.log(this.state.userId)
    );

    // console.log("friend ID", this.props.history.location.state.userId);
    socket.on("init", ({ id: clientId }) => {
      console.log("init called", clientId);
      this.setState({ clientId });
    });
    // .on("request", ({ from: callFrom }) => {
    //   this.setState({ callModal: "active", callFrom });
    // })

    if (this.props.userId !== "") {
      socket
        .on("request", ({ from: callFrom, to: callTo }) => {
          console.log("request called call from", callFrom);
          console.log("request called call to", callTo);
          // if (callTo === this.state.currentUser._id) {
          this.setState({ callModal: "active", callFrom });
          var audioId = document.getElementById("myAudioForRingTone");
          if (audioId && callTo === this.state.currentUser._id) {
            audioId.play();
          }
          // }
        })

        .on("call", (data) => {
          var audioId = document.getElementById("myAudioForRingTone");
          if (audioId) {
            audioId.pause();
            audioId.currentTime = 0;
          }
          if (data.sdp) {
            this.pc.setRemoteDescription(data.sdp);
            if (data.sdp.type === "offer") this.pc.createAnswer();
          } else this.pc.addIceCandidate(data.candidate);
        })
        .on("end", this.endCall.bind(this, false))
        .emit("init", this.state.currentUser._id);
    }
  }

  startCall(isCaller, friendID, config) {
    console.log("start call function called");
    this.config = config;
    this.pc = new PeerConnection(friendID)
      .on("localStream", (src) => {
        const newState = { callWindow: "active", localSrc: src };
        if (!isCaller) newState.callModal = "";
        this.setState(newState);
      })
      .on("peerStream", (src) => this.setState({ peerSrc: src }))
      // .start(isCaller, config);
      .start(isCaller, config, this.state.clientId);
  }

  rejectCall() {
    const { callFrom } = this.state;
    socket.emit("end", { to: callFrom });
    var audioId = document.getElementById("myAudioForRingTone");
    if (audioId) {
      audioId.pause();
      audioId.currentTime = 0;
    }
    this.setState({ callModal: "" });
  }

  endCall(isStarter) {
    if (_.isFunction(this.pc.stop)) {
      this.pc.stop(isStarter);
    }
    var audioId = document.getElementById("myAudioForRingTone");
    if (audioId) {
      audioId.pause();
      audioId.currentTime = 0;
    }
    this.pc = {};
    this.config = null;
    this.setState({
      callWindow: "",
      callModal: "",
      localSrc: null,
      peerSrc: null,
    });
  }

  // componentWillUnmount() {
  //   socket.on("getConnectedUsersForCall", ({ connectedUsers }) => {
  //     console.log("getConnectedUsersForCall", connectedUsers);
  //     this.setState({ connectedUsers });
  //   });
  //   socket.disconnect();
  // }

  render() {
    // console.log(!_.isEmpty(this.config));

    const {
      clientId,
      callFrom,
      callModal,
      callWindow,
      localSrc,
      peerSrc,
    } = this.state;
    // console.log("userId", this.props.history.location.state.userId);
    return (
      <>
        {this.props.userId !== "" ? (
          <div>
            <audio style={{ display: "none" }} id="myAudioForRingTone">
              <source
                src={
                  "https://ring.techbigsdl.com/files/best-ringtone-for-iphone-x.mp3"
                }
                type="audio/mpeg"
              />
            </audio>
            <MainWindow
              clientId={clientId}
              startCall={this.startCallHandler}
              callTo={this.props.userId ? this.props.userId : "No Id Found"}
              // connectedUsers={this.state.connectedUsers}
            />

            {!_.isEmpty(this.config) && (
              <CallWindow
                status={callWindow}
                localSrc={localSrc}
                peerSrc={peerSrc}
                config={this.config}
                mediaDevice={this.pc.mediaDevice}
                endCall={this.endCallHandler}
              />
            )}
            <CallModal
              status={callModal}
              startCall={this.startCallHandler}
              rejectCall={this.rejectCallHandler}
              callFrom={callFrom}
              config={this.config}
            />
          </div>
        ) : (
          undefined
        )}
      </>
    );
  }
}

export default App;
