/*eslint-disable*/
import React, { useState, useEffect, useReducer } from "react";
import Button from "@material-ui/core/Button";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Notification from "../../components/Snackbar/Notification.js";
import CustomTable from "../../components/Table/Table";
import TextField from "@material-ui/core/TextField";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Loader from "react-loader-spinner";
import Header from "../../components/Header/Header";
import CustomerCareIcon from "../../assets/img/Customer Care.png";
import Back from "../../assets/img/Back_Arrow.png";
import "../../assets/jss/material-dashboard-react/components/loaderStyle.css";
import { getPendingEDRscall } from "./networkCallsForBlueTeam";

const tableHeadingsForSurvey = [
  "MRN",
  "Production Area",
  "Room No",
  "Patient Name",
  "Action",
];
const tableDataKeysForSurvey = [
  "patientMRN",
  "productionAreaName",
  "roomNo",
  "patientName",
];

const action = { view: true };

export default function SocialWorkerTasks(props) {
  const [errorMsg, setErrorMsg] = useState("");
  const [openNotification, setOpenNotification] = useState(false);
  const [patientList, setPatientList] = useState([]);
  const [isLoading, setisLoading] = useState(true);

  if (openNotification) {
    setTimeout(() => {
      setOpenNotification(false);
      setErrorMsg("");
    }, 2000);
  }

  useEffect(() => {
    console.log("props", props);
    getPendingEDRscall(
      setPatientList,
      setErrorMsg,
      setOpenNotification,
      setisLoading
    );
  }, []);

  const handleView = (rec) => {
    console.log("view data", rec);
    props.history.push({
      pathname: "codeblue/AssignCodeBlueTeam",
      state: {
        selectedRecord: rec,
      },
    });
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        position: "fixed",
        width: "100%",
        height: "100%",
        backgroundColor: "#60d69f",
        overflowY: "scroll",
      }}
    >
      <Header history={props.history} />

      <div className="cPadding">
        <div className="subheader">
          <div>
            <img src={CustomerCareIcon} style={{ marginLeft: "0px" }} />
            <h4>Patient List</h4>
          </div>
        </div>

        <div
          className="container-fluid"
          style={{ paddingLeft: 0, paddingRight: 0 }}
        >
          <div className="row">
            {isLoading ? (
              <div className="LoaderStyle">
                <Loader type="TailSpin" color="red" height={50} width={50} />
              </div>
            ) : patientList ? (
              <div className="col-md-12 col-sm-12 col-12">
                <CustomTable
                  tableData={patientList}
                  tableDataKeys={tableDataKeysForSurvey}
                  tableHeading={tableHeadingsForSurvey}
                  action={action}
                  handleView={handleView}
                  borderBottomColor={"#60d69f"}
                  borderBottomWidth={20}
                />
              </div>
            ) : patientList.length === 0 ? (
              <div style={{ marginTop: "20px" }}>
                <h3
                  style={{
                    color: "white",
                    textAlign: "center",
                  }}
                >
                  Oops! No Data Found
                </h3>
              </div>
            ) : (
              undefined
            )}
          </div>

          <div className="row" style={{ marginBottom: 20 }}>
            <div className="col-md-12 col-sm-12 col-12">
              <img
                onClick={() => props.history.goBack()}
                src={Back}
                style={{
                  width: 45,
                  height: 35,
                  cursor: "pointer",
                }}
              />
            </div>
          </div>
        </div>

        <Notification msg={errorMsg} open={openNotification} />
      </div>
    </div>
  );
}
