export const CardObj = [
    {
      cardValue: 0,
      key: "First Card",
      value: "First Card",
    },
    {
        cardValue: 1,
        key: "Second Card",
        value: "Second Card",
      },
  ];