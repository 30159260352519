// import React from "react";
// import Header from "../Header/Header";
// import "../../assets/jss/material-dashboard-react/components/loaderStyle.css";
// import MyApps from "../../assets/img/Dashboard Icon.png";
// import useMediaQuery from "@material-ui/core/useMediaQuery";

// const styles = {
//   underlineStyle: {
//     width: "100%",
//     height: 0.3,
//     backgroundColor: "#E2E2E2",
//     marginTop: 3,
//   },
//   updateTextStyle: {
//     color: "white",
//     fontSize: 12,
//     marginTop: 8,
//     marginRight: 15,
//   },
//   styleForCards: {
//     width: "100%",
//     backgroundColor: "white",
//     height: 200,
//     borderRadius: 5,
//     padding: 15,
//   },
//   styleForNotificationTitle: {
//     fontSize: 17,
//     fontWeight: "500",
//     marginLeft: 10,
//   },
// };

// function Dashboard(props) {
//   const matches = useMediaQuery("(min-width:600px)");
//   return (
//     <div
//       style={{
//         display: "flex",
//         flexDirection: "column",
//         flex: 1,
//         position: "fixed",
//         width: "100%",
//         height: "100%",
//         backgroundColor: "#2962CC",
//         overflowY: "scroll",
//         overflowX: "hidden",
//       }}
//     >
//       <Header history={props.history} />

//       <div className="cPadding">
//         <div className="subheader">
//           <div style={{ marginLeft: "-5px" }}>
//             <img src={props.headingIcon} />
//             <h4>{props.headingTitle}</h4>
//           </div>

//           <div
//             style={{
//               display: "flex",
//               flexDirection: "row",
//               alignItems: "center",
//             }}
//           >
//             <span
//               style={{
//                 ...styles.updateTextStyle,
//               }}
//             >
//               UPDATED LAST 3 MINUTES
//             </span>

//             <i
//               class="zmdi zmdi-replay zmdi-hc-3x"
//               style={{ color: "white", cursor: "pointer" }}
//             />
//           </div>
//         </div>

//         <div className="container-fluid">
//           <div className="row">
//             <div
//               className="col-md-4 col-5"
//               style={{ marginTop: 10, paddingLeft: 4, paddingRight: 4 }}
//             >
//               <div
//                 style={{
//                   ...styles.styleForCards,
//                   height: matches ? 200 : 150,
//                 }}
//               >
//                 <div style={{ height: "10%" }}>
//                   <span style={{ fontWeight: "900" }}>My Apps</span>
//                 </div>

//                 <div
//                   style={{
//                     height: "90%",
//                     display: "flex",
//                     justifyContent: "center",
//                     alignItems: "center",
//                   }}
//                 >
//                   <img
//                     onClick={() => props.openApps()}
//                     src={MyApps}
//                     style={{
//                       width: matches ? 90 : 45,
//                       height: matches ? 90 : 45,
//                       cursor: "pointer",
//                     }}
//                   />
//                 </div>
//               </div>
//             </div>

//             <div
//               className="col-md-8 col-7"
//               style={{ marginTop: 10, paddingRight: 4, paddingLeft: 4 }}
//             >
//               <div
//                 style={{
//                   ...styles.styleForCards,
//                   height: matches ? 200 : 150,
//                 }}
//               >
//                 <span style={{ fontWeight: "900" }}>Notifications</span>
//                 {[
//                   "Notification 1",
//                   "Notification 2",
//                   "Notification 3",
//                   "Notification 4",
//                 ].map((notification) => {
//                   return (
//                     <h5 style={{ color: "grey", fontSize: matches ? " " : 13 }}>
//                       {notification}
//                     </h5>
//                   );
//                 })}
//                 {/* {props.notificationArray.map((notification) => {
//                   return (
//                     <div>
//                       <div
//                         style={{
//                           display: 'flex',
//                           flexDirection: 'row',
//                           justifyContent: 'space-between',
//                           alignItems: 'center',
//                           // backgroundColor:"red"
//                         }}
//                       >
//                         <div style={{ marginTop: 15 }}>
//                           <img
//                             src={notification.icon}
//                             style={{ width: 20, height: 20 }}
//                           />
//                           <span
//                             style={{
//                               ...styles.styleForNotificationTitle,
//                             }}
//                           >
//                             {notification.title}
//                           </span>
//                         </div>

//                         <i
//                           style={{ color: 'blue' }}
//                           class='zmdi zmdi-chevron-right zmdi-hc-1x'
//                         ></i>
//                       </div>

//                       <div style={{ ...styles.underlineStyle }} />
//                     </div>
//                   )
//                 })} */}
//               </div>
//             </div>
//           </div>
//         </div>
//         {props.children}
//       </div>
//     </div>
//   );
// }
// export default Dashboard;

import Paper from '@material-ui/core/Paper'
import ConfirmationModal from '../Modal/confirmationModal'
import plus_icon from '../../assets/img/Plus.png'
import axios from 'axios'

import React, { useState, useEffect } from 'react'
import Button from '@material-ui/core/Button'
import Notification from '../Snackbar/Notification.js'
import CustomTable from '../Table/Table'
import {
  stockLevelsWarehouse,
  getFunctionalUnitUrl,
  stockLevelsFU,
} from '../../public/endpoins'
import Loader from 'react-loader-spinner'
import Header from '../Header/Header'
// import purchase_order from "../../assets/img/Purchase Order.png";
import Back from '../../assets/img/Back_Arrow.png'
import cookie from 'react-cookies'
import { recordLogout } from '../../public/endpoins'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core/styles'
import '../../assets/jss/material-dashboard-react/components/loaderStyle.css'
import {
  DateTimePicker,
  MuiPickersUtilsProvider,
  TimePicker,
  DatePicker,
} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'

import dateTimeFormat from '../../constants/dateTimeFormat.js'
import dateFormat from '../../constants/dateFormat.js'

import business_Unit from '../../assets/img/Functional Unit.png'
import MyApps from '../../assets/img/MyAppsIcon.png'
import Logout from '../../assets/img/Logout.png'
import RefreshIcon from '../../assets/img/Refresh.png'
import useMediaQuery from '@material-ui/core/useMediaQuery'

const styles = {
  underlineStyle: {
    width: '100%',
    height: 0.3,
    backgroundColor: '#E2E2E2',
    marginTop: 3,
  },

  updateTextStyle: {
    color: 'white',
    fontSize: 12,
    marginTop: 8,
    marginRight: 15,
  },

  styleForCards: {
    width: '100%',
    backgroundColor: 'white',
    height: 200,
    borderRadius: 5,
    padding: 15,
  },

  styleForNotificationTitle: {
    fontSize: 17,
    fontWeight: '500',
    marginLeft: 10,
  },
}

function Dashboard(props) {
  const matches = useMediaQuery('(min-width:600px)')

  const [currentUser, setCurrentUser] = useState(cookie.load('current_user'))
  const [idleTimer, setIdleTimer] = useState('')

  const handleOnIdle = (event) => {
    console.log('last active', new Date(this.idleTimer.getLastActiveTime()))
    logoutUser()
  }

  const recordLogout = () => {
    const loggedUser = cookie.load('current_user')
    const token = cookie.load('token')

    const params = {
      token: token,
      userId: loggedUser._id,
    }
    axios
      .post(recordLogout, params)
      .then((res) => {
        if (res.data.success) {
          console.log('response after recording the logout', res.data)
        }
      })
      .catch((e) => {
        console.log('error is ', e)
      })
  }

  const logoutUser = () => {
    // this.recordLogout()
    cookie.remove('token', { path: '/' })
    cookie.remove('current_user', { path: '/' })
    cookie.remove('user_staff', { path: '/' })
    window.location.reload()
  }

  const handleClickNotification = (title) => {
    console.log('Title clicked : ', title)
    if (title === 'Messages') {
      props.history.push({
        pathname: '/dashboard/home/chat',
      })
    } else if (title === 'Notifications') {
      props.history.push({
        pathname: '/dashboard/notification',
      })
    }
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        position: 'fixed',
        width: '100%',
        height: '100%',
        backgroundColor: '#2962CC',
        overflowY: 'scroll',
        overflowX: 'hidden',
      }}
    >
      <Header history={props.history} />

      <div className='cPadding'>
        <div className='subheader'>
          <div style={{ marginLeft: '-5px' }}>
            <img src={props.headingIcon} />
            <h4>{props.headingTitle}</h4>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <span
              style={{
                ...styles.updateTextStyle,
                fontSize: matches ? 12 : 6,
              }}
            >
              UPDATED LAST 3 MINUTES
            </span>
            <img
              // onClick={() => props.openApps()}
              src={RefreshIcon}
              style={{
                // marginTop: matches ? undefined : 10,
                width: matches ? 45 : 24,
                height: matches ? 'auto' : 25,
                cursor: 'pointer',
              }}
            />

            {/* <i
              class="zmdi zmdi-replay zmdi-hc-2x"
              style={{ color: "white", cursor: "pointer" }}
            /> */}

            <span
              style={{
                paddingLeft: matches ? 10 : 3,
                paddingRight: matches ? 5 : 2,
                color: '#fff',
              }}
            >
              {' '}
              |{' '}
            </span>

            <img
              onClick={logoutUser}
              src={Logout}
              style={{
                // marginTop: matches ? undefined : 10,
                width: matches ? 45 : 30,
                height: matches ? 'auto' : 26,
                cursor: 'pointer',
              }}
            />
            {/* <img src="/img/Logout.png"> </img> */}
          </div>
        </div>

        <div className='container-fluid'>
          <div className='row'>
            <div
              className='col-md-4 col-4'
              style={{ marginTop: 10, paddingLeft: 4, paddingRight: 4 }}
            >
              <div
                style={{
                  ...styles.styleForCards,
                  height: matches ? 200 : 120,
                }}
              >
                <div style={{ height: '10%' }}>
                  <span
                    style={{
                      fontSize: matches ? undefined : 12,
                      fontWeight: '900',
                    }}
                  >
                    My Apps
                  </span>
                </div>

                <div
                  style={{
                    height: '90%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <img
                    onClick={() => props.openApps()}
                    src={MyApps}
                    style={{
                      marginTop: matches ? undefined : 10,
                      width: matches ? 90 : 35,
                      height: matches ? 90 : 35,
                      cursor: 'pointer',
                    }}
                  />
                </div>
              </div>
            </div>

            <div
              className='col-md-8 col-8'
              style={{ marginTop: 10, paddingRight: 4, paddingLeft: 4 }}
            >
              <div
                style={{
                  ...styles.styleForCards,
                  height: matches ? 200 : 120,
                }}
              >
                <span
                  style={{
                    fontSize: matches ? undefined : 12,
                    fontWeight: '900',
                  }}
                >
                  Notifications
                </span>

                {props.notificationArray.map((notification) => {
                  return (
                    <div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        <div
                          onClick={() =>
                            handleClickNotification(notification.title)
                          }
                          style={{
                            cursor: 'pointer',
                            marginTop: matches ? 15 : undefined,
                          }}
                        >
                          <img
                            src={notification.icon}
                            style={{ width: 20, height: 20 }}
                          />
                          <span
                            style={{
                              ...styles.styleForNotificationTitle,
                              marginLeft: matches ? 10 : 0,
                              fontSize: matches ? 17 : 12,
                              fontSize: matches ? 17 : 10,
                            }}
                          >
                            {notification.title}
                          </span>
                        </div>

                        <i
                          style={{
                            marginTop: matches ? 20 : 8,
                            color: 'blue',
                          }}
                          class='zmdi zmdi-chevron-right zmdi-hc-1x'
                        ></i>
                      </div>

                      <div style={{ ...styles.underlineStyle }} />
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>

        {props.children}
      </div>
    </div>
  )
}

export default Dashboard
