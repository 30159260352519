import React from 'react'

class NotFound extends React.Component {

    render() {
        return (
            <h1 style={{ textAlign: 'center' }}>Error 404. Not Found</h1>
        )
    }
}

export default NotFound