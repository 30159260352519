import React from 'react'
import { Route, Switch } from 'react-router-dom'
import NotFound from '../components/NotFound/NotFound'
import PreApproval from '../views/Insurance/PreApproval/PreApproval'
import ViewPreApproval from '../views/Insurance/PreApproval/ViewPreApproval'
import ViewPreApprovalList from '../views/Insurance/PreApproval/ViewPreApprovalList'
import ViewPreApprovalDetail from '../views/Insurance/PreApproval/ViewPreApprovalDetail'
import NeedApproval from '../views/Insurance/PreApproval/NeedApproval'
import ClaimsReview from '../views/Insurance/ClaimsReview/claimReview'
import SubmitClaim from '../views/Insurance/ClaimsReview/submitClaim'

class InsuranceRoutes extends React.PureComponent {
  render() {
    return (
      <Switch>
        <Route
          exact
          path={`${this.props.match.url}/:preApprovalType(preapproval|radpreapproval)/:id/:labRadId/view/:serviceId`}
          component={ViewPreApprovalDetail}
        />
        <Route
          exact
          path={`${this.props.match.url}/:preApprovalType(preapproval|radpreapproval)/:id/:labRadId`}
          component={ViewPreApprovalList}
        />
        <Route
          exact
          path={`${this.props.match.url}/:preApprovalType(preapproval|radpreapproval)`}
          component={PreApproval}
        />
        <Route
          exact
          path={`${this.props.match.url}/:preApprovalType(preapproval|radpreapproval)/viewpreapproval`}
          component={ViewPreApproval}
        />
        <Route
          exact
          path={`${this.props.match.url}/:preApprovalType(preapproval|radpreapproval)/viewpreapproval/needapproval`}
          component={NeedApproval}
        />
        <Route
          exact
          path={`${this.props.match.url}/claimsreview`}
          component={ClaimsReview}
        />
        <Route
          exact
          path={`${this.props.match.url}/submitClaim`}
          component={SubmitClaim}
        />

        <Route path={`${this.props.match.url}/notfound`} component={NotFound} />

        <Route path='*' component={NotFound} />
      </Switch>
    )
  }
}
export default InsuranceRoutes