import React, { useState, useEffect, useReducer } from "react";
import Chip from "@material-ui/core/Chip";
import DoneIcon from "@material-ui/icons/Done";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import AddIcon from "@material-ui/icons/Add";
import Back from "../../../../assets/img/Back_Arrow.png";
import SliderComponent from "../../../../components/SliderComponent/sliderComponent";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import "./formRow.css";
import moment from "moment";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Notification from "../../../../components/Snackbar/Notification.js";
import axios from "axios";
import {
  addPatientDetails,
  addPastHistory,
  addInvestigation,
  addRos,
  addPhysicalExam,
  addActionPlan,
  addCourseOfVisit,
  getAllChiefComplaints,
  getIcdForDropdown,
  getIcdbyFeaturedGroup,
} from "../../../../public/endpoins";


import MicIcon from "@material-ui/icons/Mic";
import StopIcon from "@material-ui/icons/Stop";
import MicRecorder from "mic-recorder-to-mp3";
import Loader from "react-loader-spinner";

const Mp3Recorder = new MicRecorder({ bitRate: 128 });

const styles = {
  ROSRowPadding: {
    marginLeft: 0,
    marginRight: 0,
    backgroundColor: "white",
    marginTop: 10,
    padding: "6px 0px 6px 0px",
    borderRadius: 5,
  },
  chipAlignment: {
    display: "inline-block",
    padding: "5px",
  },
  save: {
    color: "white",
    cursor: "pointer",
    borderRadius: 5,
    backgroundColor: "#2c6ddd",
    width: "115px",
    height: "40px",
    outline: "none",
  },
};

const useStyles = makeStyles(() => ({
  input: {
    backgroundColor: "#F5F5F5",
    boxShadow: "none",
    borderRadius: 5,
    "&:after": {
      borderBottomColor: "black",
      boxShadow: "none",
    },
    "&:hover": {
      backgroundColor: "#F5F5F5",
      boxShadow: "none",
    },
    "&:focus": {
      backgroundColor: "#F5F5F5",
      boxShadow: "none",
      borderRadius: 5,
    },
  },
  root: {
    "& .MuiTextField-root": {
      backgroundColor: "white",
      borderRadius: 5,
    },
    "& .Mui-focused": {
      backgroundColor: "white",
      color: "black",
      boxShadow: "none",
    },
    "& .Mui-disabled": {
      backgroundColor: "white",
      color: "gray",
    },
    "&:focus": {
      backgroundColor: "white",
      boxShadow: "none",
    },
    "& .MuiFormLabel-root": {
      fontSize: "12px",
      color: "black",
      paddingRight: "15px",
    },
    "& .MuiInputLabel-filled": {
      color: "gray",
      backgroundColor: "#F5F5F5",
      background: "#F5F5F5",
    },
  },
}));

export default function PatientDetailsFormRow(props) {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery("(min-width:600px)");
  const matchesForTablet = useMediaQuery(theme.breakpoints.up("md"));

  const initialState = {
    investigation: [],
    timeSeen: new Date().getHours() + ":" + new Date().getMinutes(),
    room: "",
    age: 0,
    gender: "",
    nationality: "",
    chiefComplaint: "",
    chiefComplaintsListed: "",
    careStream: "",

    isBlocked: true,
    isRecording: false,
    blobURL: [],
    recordingMode: ""
  };

  function reducer(state, { field, value }) {
    return {
      ...state,
      [field]: value,
    };
  }

  const [state, dispatch] = useReducer(reducer, initialState);

  const {
    investigation,
    timeSeen,
    room,
    age,
    gender,
    nationality,
    chiefComplaint,
    chiefComplaintsListed,
    careStream, isBlocked, isRecording, blobURL, recordingMode
  } = state;

  const [clickedRow, setClickedRow] = useState([]);
  const [severity, setSeverity] = useState(0);
  const [minutes, setMinutes] = useState("");
  const [hours, setHours] = useState("");
  const [days, setDays] = useState("");
  const [week, setWeek] = useState("");
  const [month, setMonth] = useState("");

  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setsuccessMsg] = useState("");
  const [openNotification, setOpenNotification] = useState(false);

  useEffect(() => {
    console.log("Form data passed ", props.form);

    let modePage = 'add';

    let selectedItem = props.history.location.state.selectedItem;
    let patientEdrForm = props.history.location.state.patientEdrForm;
    console.log("selectedItem: ", selectedItem);
    console.log("selectedItem.state ", props.history.location.state);
    var createdDate = selectedItem.createdTimeStamp;
    Object.entries(selectedItem.patientId).map(([key, value]) => {
      dispatch({ field: key, value: value });
      // if (key === "createdAt") {
      //   createdDate = value;
      // }
    });

    if (patientEdrForm){
      console.log("selectedItem.patientEdrForm: ", patientEdrForm);
      console.log("selectedItem.chiefComplaint: ", patientEdrForm.chiefComplaint);

      // chiefComplaintsListed

      if (patientEdrForm.chiefComplaint){
        let chiefComp = patientEdrForm.chiefComplaint[ patientEdrForm.chiefComplaint.length -1 ];
        console.log("chiefComp::", chiefComp);
        // let cheifCompSet = [];
        // console.log('chiefComp:', chiefComp);
        // chiefComp.chiefComplaintIds.map((obj)=> {
        //     console.log('chiefComp:', obj);
        //     cheifCompSet.push (obj.code + ": " + obj.name);
        // });
        dispatch({
          field: "chiefComplaintsListed",
          value: chiefComp.chiefComplaintId.short_desc ,//cheifCompSet.join(', ') //chiefComp.chiefComplaintId.code + ": " + 
        });
      }
    }

    dispatch({
      field: "chiefComplaint",
      value:
        selectedItem &&
        selectedItem.chiefComplaint &&
        selectedItem.chiefComplaint.length
          ? selectedItem.chiefComplaint[selectedItem.chiefComplaint.length - 1]
              .chiefComplaintId.name ||
            selectedItem.chiefComplaint[selectedItem.chiefComplaint.length - 1]
              .name
          : // selectedItem.chiefComplaintName || selectedItem.chiefComplaint.length > 0
            //   ? selectedItem.chiefComplaint[selectedItem.chiefComplaint.length - 1]
            //       .chiefComplaintId.name
            "Not Selected",
    });
    dispatch({
      field: "careStream",
      value:
        selectedItem &&
        selectedItem.careStream &&
        selectedItem.careStream.length
          ? selectedItem.careStream[selectedItem.careStream.length - 1].name
          : // selectedItem.chiefComplaintName || selectedItem.chiefComplaint.length > 0
            //   ? selectedItem.chiefComplaint[selectedItem.chiefComplaint.length - 1]
            //       .chiefComplaintId.name
            "Not Selected",
    });
    dispatch({
      field: "room",
      value:
        selectedItem.room && selectedItem.room.length > 0
          ? selectedItem.room[selectedItem.room.length - 1].roomId.roomNo
          : "Not Selected",
    });

    console.log('createdDate', createdDate, calculateDuration);
    calculateDuration(createdDate);

    console.log("currentFields::", investigation);
    if (props.history.location.state.currentFields && props.history.location.state.comingFrom === "edit"){
      modePage = 'edit';
      console.log("currentFields::", props.history.location.state.currentFields);

      let nData = [];
      Object.entries(props.history.location.state.currentFields.details).map(([key, val]) => {
        if (val.name === "Pain Scale"){
          setSeverity(val.value);
        }
        nData.push(val.name);
      });
      if (nData.length > 0){
        setClickedRow(nData);
      }

      /*Object.entries(props.history.location.state.currentFields.details).map(([key, val]) => {
        
        dispatch({
          field: "investigation",
          value: [
            ...investigation,
            val
          ],
          // value: props.history.location.state.currentFields.details,
        });



        dispatch({
          field: "investigation",
          value: [
            ...investigation,
            val,
          ],
        });

        console.log('currentFields::click::form:', val);
      });*/

      dispatch({
          field: "investigation",
          value: props.history.location.state.currentFields.details
      });


      console.log("currentFields::", investigation);
    }





    // Set up Default
    let nInvestigations = []
    if (investigation.length <= 0 && modePage === 'add') {
      Object.entries(props.form).map(([key, val]) => {
        if (val.name !=undefined ){
          let obj = {
            name: val.name,
            sortNumber: val.sortNumber,
            value: '',
            chips: [],
            Texts: [],
            DropDowns: [],
            voiceNotes: {
              audio: '',
              notes: ''
            }
          };
          nInvestigations.push(obj);
        }
        //console.log('FormRow::', obj);
      });
      console.log('FormRow::', nInvestigations);
    }
    if (nInvestigations.length > 0){
      dispatch({
        field: 'investigation', 
        value: nInvestigations
      });
    }

    // Navigator for MP3
    navigator.mediaDevices &&
      navigator
        .mediaDevices.getUserMedia({ audio: true })
        .then( stream => {
          dispatch({ field: "isBlocked", value: false });
        })
        .catch( error => {
          dispatch({ field: "isBlocked", value: true });
        });
  }, []);

  useEffect(() => {
    console.log('investigation:: currentFields:: ', investigation);
  }, [investigation])

  const calculateDuration = (createdDate) => {
    const startDate = moment(createdDate);
    const timeEnd = moment(new Date());
    const diff = timeEnd.diff(startDate);
    const diffDuration = moment.duration(diff);

    setHours(diffDuration.hours());
    setMinutes(diffDuration.minutes());
    setDays(diffDuration.days());
    setMonth(diffDuration.months());
    setWeek(diffDuration.weeks());
  };

  console.log("propsDataFormData::Form Array", investigation);

  const onRowClick = (row) => {
    // console.log('rowselected:: currentFields::', row);
    if (!clickedRow.includes(row)) {
      setClickedRow([...clickedRow, row]);
    }
    props.rowsSelected(true);
  };

  const handleChipClick = (e, row, val) => {
    // console.log("Clicked chip ", e, row, val)
    let tempVal = val;

    if (investigation.length > 0) {
      var rowFound = false;
      for (let i = 0; i < investigation.length; i++) {
        if (!rowFound) {
          if (investigation[i].name === row) {
            rowFound = true;
            let chip = investigation[i].chips;
            var chipFound = false;
            for (let j = 0; j < chip.length; j++) {
              if (!chipFound) {
                if (chip[j].name === val.name) {
                  chip.splice(j, 1);
                  dispatch({
                    field: "investigation",
                    value: investigation,
                  });
                  chipFound = true;
                  j--;
                }
              }
            }
            if (!chipFound) {
              chip.push(tempVal);
              dispatch({
                field: "investigation",
                value: investigation,
              });
            }
          }
        }
      }
      if (!rowFound) {
        dispatch({
          field: "investigation",
          value: [
            ...investigation,
            {
              name: row,
              chips: [tempVal],
              Texts: [],
              voiceNotes: {
                audio: '',
                notes: ''
              }
            },
          ],
        });
      }
    } else {
      let obj = {};
      let chipsArray = [];
      obj.name = row;
      chipsArray.push(tempVal);
      obj.chips = chipsArray;
      obj.Texts = [];
      obj.voiceNotes = {
        audio: '',
        notes: ''
      };

      dispatch({
        field: "investigation",
        value: [obj],
      });
    }
  };

  const handleRadioClick = (e, row, val) => {
    
    let tempVal = val;

    if (investigation.length > 0) {
      var rowFound = false;
      for (let i = 0; i < investigation.length; i++) {
        if (!rowFound) {
          if (investigation[i].name === row) {
            rowFound = true;
            investigation[i].value = e;
            dispatch({
              field: "investigation",
              value: investigation,
            });
          }
        }
      }
      if (!rowFound) {
        dispatch({
          field: "investigation",
          value: [
            ...investigation,
            {
              name: row,
              value: e,
              chips: [],
              Texts: [],
              voiceNotes: {
                audio: '',
                notes: ''
              }
            },
          ],
        });
      }
    } else {
      dispatch({
        field: "investigation",
        value: [
          {
            name: row,
            value: e,
            chips: [],
            Texts: [],
            voiceNotes: {
              audio: '',
              notes: '',
            }
          },
        ],
      });
    }
  };

  const onChipText = (e, row, chipText) => {
    for (let i = 0; i < investigation.length; i++) {
      if (investigation[i].name === row) {
        let chip = investigation[i].chips;
        for (let j = 0; j < chip.length; j++) {
          if (chip[j].name === chipText.name) {
            chip[j].detail = e.target.value;
            dispatch({
              field: "investigation",
              value: investigation,
            });
          }
        }
      }
    }
  };

  const onDropDownChange = (e, row, val) => {
    let tempVal = val;
    tempVal.value = e.target.value;

    if (investigation.length > 0) {
      var rowFound = false;
      for (let i = 0; i < investigation.length; i++) {
        if (!rowFound) {
          if (investigation[i].name === row) {
            rowFound = true;
            let chip = investigation[i].DropDowns;
            var chipFound = false;
            for (let j = 0; j < chip.length; j++) {
              if (!chipFound) {
                if (chip[j].name === val.name) {
                  chip.splice(j, 1);
                  dispatch({
                    field: "investigation",
                    value: investigation,
                  });
                  chipFound = true;
                  j--;
                }
              }
            }
            if (!chipFound) {
              chip.push(tempVal);
              dispatch({
                field: "investigation",
                value: investigation,
              });
            }
          }
        }
      }
      if (!rowFound) {
        dispatch({
          field: "investigation",
          value: [
            ...investigation,
            {
              name: row,
              DropDowns: [tempVal],
              chips: [],
              Texts: [],
              voiceNotes: {
                audio: '',
                notes: ''
              }
            },
          ],
        });
      }
    } else {
      let obj = {};
      let chipsArray = [];
      obj.name = row;
      chipsArray.push(tempVal);
      obj.DropDowns = chipsArray;
      obj.chips = [];
      obj.Texts = [];
      obj.voiceNotes = {
        audio: '',
        notes: ''
      };

      dispatch({
        field: "investigation",
        value: [obj],
      });
    }
  };

  const onTextChange = (e, row, text) => {
    text.value = e.target.value;
    if (investigation.length > 0) {
      var rowFound = false;
      for (let i = 0; i < investigation.length; i++) {
        if (!rowFound) {
          if (investigation[i].name === row) {
            rowFound = true;
            let texts = investigation[i].Texts;
            var fieldFound = false;
            for (let j = 0; j < texts.length; j++) {
              if (!fieldFound) {
                if (texts[j].name === text.name) {
                  texts[j].value = e.target.value;

                  dispatch({
                    field: "investigation",
                    value: investigation,
                  });
                  fieldFound = true;
                }
              }
            }
            if (!fieldFound) {
              texts.push(text);
              dispatch({
                field: "investigation",
                value: investigation,
              });
            }
          }
        }
      }
      if (!rowFound) {
        dispatch({
          field: "investigation",
          value: [
            ...investigation,
            {
              name: row,
              Texts: [text],
              chips: [],
              voiceNotes: {
                audio: '',
                notes: ''
              }
            },
          ],
        });
      }
    } else {
      let obj = {};
      let textsArray = [];
      obj.name = row;
      textsArray.push(text);
      obj.Texts = textsArray;
      obj.chips = [];
      obj.voiceNotes = {
        audio: '',
        notes: ''
      };

      dispatch({
        field: "investigation",
        value: [obj],
      });
    }
  };

  const onSeverity = (e) => {
    setSeverity(e);

    if (investigation.length > 0) {
      var rowFound = false;
      for (let i = 0; i < investigation.length; i++) {
        if (!rowFound) {
          if (investigation[i].name === "Pain Scale") {
            rowFound = true;
            investigation[i].value = e;
            dispatch({
              field: "investigation",
              value: investigation,
            });
          }
        }
      }
      if (!rowFound) {
        dispatch({
          field: "investigation",
          value: [
            ...investigation,
            {
              name: "Pain Scale",
              value: e,
            },
          ],
        });
      }
    } else {
      dispatch({
        field: "investigation",
        value: [
          {
            name: "Pain Scale",
            value: e,
          },
        ],
      });
    }
    console.log("props.form: ", props.form);
  };

  const handleChangeAjaxBar = (
    setter,
    value
  ) => {
    setter(value)
  };

  const [Icd10CodesList, setIcd10CodesList] = useState([]);
  const [icd10SearchedCodes, setIcd10SearchedCodes] = useState("");

  const setAjaxSearchCode = (
    setter,
    value
  ) => {
    console.log("setAjaxSearchCode", value, setter);
    setter(value)
  };





  const onRecordAudioFinish = (file, chipName, rowName, val) => {
    console.log("ST+", file, chipName, rowName, val);
    val.audio = file;
    console.log("ST+", val);
    setAudioFileToChips(chipName, rowName, val);
  };


  const onRecordAudioFinishGlobal = (file, chipName, rowName, val) => {
    console.log("ST+", file, chipName, rowName, val);
    val.voiceNotes.audio = file;
    console.log("ST+", val);
    setAudioFileToNotes(chipName, rowName, val);
  };

  const setAudioFileToNotes = (e, row, val) => {
    let tempVal = val;
    if (investigation.length > 0) {
      var rowFound = false;
      for (let i = 0; i < investigation.length; i++) {
        if (!rowFound) {
          if (investigation[i].name === row) {
            rowFound = true;
            console.log("INVESTIGATION GLOBAL: ", investigation[i]);
            investigation[i].voiceNotes.audio = val.voiceNotes.audio;
            console.log("INVESTIGATION GLOBAL: FINISH", investigation[i]);
            dispatch({
              field: "investigation",
              value: investigation,
            });
          }
        }
      }
      if (!rowFound) {
        dispatch({
          field: "investigation",
          value: [
            ...investigation,
            {
              name: row,
              chips: [],
              Texts: [],
              voiceNotes: {
                audio: val.voiceNotes.audio,
                notes: ''
              }
            },
          ],
        });
      }
    } else {
      let obj = {};
      let chipsArray = [];
      obj.name = row;
      obj.chips = chipsArray;
      obj.Texts = [];
      obj.voiceNotes = {
        audio: val.voiceNotes.audio,
        notes: ''
      };

      dispatch({
        field: "investigation",
        value: [obj],
      });
    }
  };

  const setAudioFileToChips = (e, row, val) => {
    let tempVal = val;
    if (investigation.length > 0) {
      var rowFound = false;
      for (let i = 0; i < investigation.length; i++) {
        if (!rowFound) {
          if (investigation[i].name === row) {
            rowFound = true;
            let chip = investigation[i].chips;
            var chipFound = false;
            for (let j = 0; j < chip.length; j++) {
              if (!chipFound) {
                if (chip[j].name === val.name) {
                  if (chip[j].audio!=""){
                    chip.splice(j, 1);
                    chip.push(tempVal);
                    dispatch({
                      field: "investigation",
                      value: investigation,
                    });
                    chipFound = true;
                  }
                }
              }
            }
            if (!chipFound) {
              chip.push(tempVal);
              dispatch({
                field: "investigation",
                value: investigation,
              });
            }
          }
        }
      }
    } else {
      let obj = {};
      let chipsArray = [];
      obj.name = row;
      chipsArray.push(tempVal);
      obj.chips = chipsArray;
      obj.Texts = [];
      obj.voiceNotes = {
        audio: '',
        notes: ''
      };

      dispatch({
        field: "investigation",
        value: [obj],
      });
    }
  };

  const checkTextValue = (codeName) => {
    let codeValue = investigation.filter((row1) => {
      return row1.name == codeName
    });
    let output = "";
    if (codeValue.length > 0){
      if ( codeValue[0].voiceNotes ){
        // console.log("codeValue", codeValue[0].voiceNotes);
        output = codeValue[0].voiceNotes  ? codeValue[0].voiceNotes.notes : ""
      }
    }
    return output;
  }

  const onGlobalChipText = (e, row, chipText) => {

    if (investigation.length > 0) {
      var rowFound = false;
      for (let i = 0; i < investigation.length; i++) {
        if (!rowFound) {
          if (investigation[i].name === row) {
            rowFound = true;
            console.log('INV===>', investigation[i])
            investigation[i].voiceNotes.notes = e.target.value;
            dispatch({
              field: "investigation",
              value: investigation,
            });
          }
        }
      }
      if (!rowFound) {
        dispatch({
          field: "investigation",
          value: [
            ...investigation,
            {
              name: row,
              Texts: [],
              chips: [],
              voiceNotes: {
                audio: '',
                notes: e.target.value
              }
            },
          ],
        });
      }
    } else {
      let obj = {
        name: row,
        Texts: [],
        chips: [],
        voiceNotes: {
          audio: '',
          notes: e.target.value
        },
      };
      dispatch({
        field: "investigation",
        value: [obj],
      });
    }



    /*if (investigation.length > 0) {
      for (let i = 0; i < investigation.length; i++) {
        if (investigation[i].name === row) {
          investigation[i].voiceNotes.notes = e.target.value;
          dispatch({
            field: "investigation",
            value: investigation,
          });
        }
      }
    }else{
      let obj = {
        name: row,
        chips: [],
        Texts: [],
        voiceNotes: {
          audio: "",
          notes: e.target.value
        },
      };
      dispatch({
        field: "investigation",
        value: [obj],
      });
    }*/
  };



  useEffect(() => {
    console.log("icd10CodesDropdown", icd10SearchedCodes);
    const getSearchedIcd10KeywordList = () => {
      console.log("icd10CodesDropdownStart", icd10SearchedCodes, getIcdForDropdown);
      axios
        // .get(getSearchedPharmaceuticalItemsUrl + "/" + icd10SearchedCodes)
        .get(getIcdForDropdown, {
          params: {
            foo: 'bar',
            keyword: icd10SearchedCodes
          }
        })
        .then((response) => {
          console.log("icd10CodesDropdown", response.data.items);
          let icd10CodesDropdown = [];
          Object.entries(response.data.items).map(([key, val]) => {
            icd10CodesDropdown.push({
              name: val.short_desc,
              code: val.code,
            });
            console.log(key);
            console.log(val);
          });
          console.log("icd10CodesDropdown", icd10CodesDropdown);
          setIcd10CodesList(icd10CodesDropdown);
        })
        .catch((error) => {
          console.log("Error", error);
        });
    };
    icd10SearchedCodes && getSearchedIcd10KeywordList();
  }, [icd10SearchedCodes]);
  console.log("XXXX", props);
  return (
    <>
      {props.form.map((row) => {
        if (row.name) {
          return (
            <div
              className="row"
              style={styles.ROSRowPadding}
              onClick={() => onRowClick(row.name)}
            >
              <div className="col-md-12 col-sm-12 col-12">
                {clickedRow.includes(row.name) ? (
                  <>
                    <div
                      style={{
                        fontWeight: "400",
                        color: "#939393",
                      }}
                    >
                      {row.name}
                    </div>

                    {row.name === "Pain Scale" ? (
                      <div className="row">
                        <div className="col-md-5 col-sm-6 col-6 p-4">
                          <div className="row mt-2 pl-2">
                            <span className="severityMarks mild" />
                            <span>Mild</span>
                          </div>

                          <div className="row mt-4 pl-2">
                            <span className="severityMarks severe" />
                            <span>Moderate</span>
                          </div>
                          <div className="row mt-4 pl-2">
                            <span className="severityMarks danger" />
                            <span>Severe</span>
                          </div>
                        </div>
                        <div className="col-md-7 col-sm-6 col-12">
                          <SliderComponent
                            scale={10}
                            top={60}
                            bottom={50}
                            left={
                              matches && matchesForTablet
                                ? 0
                                : matches && !matchesForTablet
                                ? 0
                                : 0
                            }
                            right={0}
                            onSlide={onSeverity}
                            progressValue={severity}
                          />
                        </div>
                      </div>
                    ) : (
                      undefined
                    )}

                    {row.chips && row.chips.length > 0 ? (
                      <div style={{ marginTop: 10 }}>
                        {row.chips.map((val) => {
                          let Icon;
                          let Style = {
                            color: "#070707",
                            backgroundColor: "#F5F5F5",
                          };
                          let chipIncluded = false;

                          if (val.name) {
                            if (val.name.includes("Add")) {
                              Icon = <AddIcon style={{ color: "#FF6F91" }} />;
                              Style = {
                                color: "#FF6F91",
                                backgroundColor: "#FFE2E9",
                              };
                            }

                            investigation.map((row1) => {
                              if (row1.name === row.name) {
                                if (row1.chips && row1.chips.length > 0) {
                                  row1.chips.map((chip) => {
                                    if (chip.name === val.name) {
                                      if (val.name.includes("Add")) {
                                        Icon = (
                                          <AddIcon style={{ color: "white" }} />
                                        );
                                        Style = {
                                          color: "white",
                                          backgroundColor: "#FF6F91",
                                        };
                                      } else {
                                        Icon = (
                                          <DoneIcon
                                            style={{ color: "white" }}
                                          />
                                        );
                                        Style = {
                                          color: "white",
                                          backgroundColor: "rgb(19 213 159)",
                                        };
                                        chipIncluded = true;
                                      }
                                    }
                                  });
                                }
                              }
                            });
                          }
                          return (
                            <>
                              {chipIncluded ? (
                                <>
                                  {val.detail === "" ||
                                  val.subChipsItems && val.subChipsItems.length > 0 ||
                                  val.autocompleteAjax !== "" ||
                                  (val.detail && val.detail.length > 0) ? (
                                    <hr />
                                  ) : (
                                    undefined
                                  )}
                                </>
                              ) : (
                                undefined
                              )}

                              <span style={styles.chipAlignment}>
                                <Chip
                                  label={val.name}
                                  onClick={() =>
                                    handleChipClick(val.name, row.name, val)
                                  }
                                  icon={Icon}
                                  style={Style}
                                />
                              </span>

                              {chipIncluded ? (
                                <>
                                  {val.detail === "" ||
                                  (val.detail && val.detail.length > 0) ? (
                                    <>
                                      <div
                                        className={`${"row"} ${classes.root}`}
                                        style={{
                                          marginTop: 15,
                                          marginBottom: 15,
                                        }}
                                      >
                                        <div className="col-md-3">
                                          <div className="d-flex align-items-center">
                                            <div
                                              className="text-center"
                                              style={{
                                                marginRight: matches ? 15 : 7,
                                                backgroundColor: (isRecording && (recordingMode === (row.name + "_" + val.name))) ? "#DC004E" : "#2C6DDD",
                                                height: 55,
                                                borderRadius: 30,
                                                width: 55,
                                                flex: "0 0 55px",
                                                cursor: 'pointer'
                                              }}
                                            >

                                              {isRecording && (recordingMode === (row.name + "_" + val.name)) ? (
                                                <StopIcon
                                                  style={{ marginTop: matches ? 11 : 9, color: "white" }}
                                                  fontSize="large"
                                                  onClick={(name, field) => {
                                                    console.log("Recording: stop", row.name, val.name);
                                                    Mp3Recorder.stop()
                                                      .getMp3()
                                                      .then(([buffer, blob]) => {
                                                        const blobURLFinal = URL.createObjectURL(blob);
                                                        let blobURLx = blobURL;
                                                        blobURLx[(row.name + "_" + val.name)] = blobURLFinal;
                                                        dispatch({ field: "blobURL", value: blobURLx });
                                                        dispatch({ field: "isRecording", value: false });
                                                        dispatch({ field: "recordingMode", value: "" });
                                                        var file = new File([blob], "first", { type: "audio/mp3" });
                                                        // dispatch({ field: "fileAudio", value: file });
                                                        onRecordAudioFinish(file, val.name, row.name, val)
                                                      })
                                                      .catch((e) => console.log(e));
                                                  }}
                                                  disabled={!isRecording}
                                                  color="secondary"
                                                />
                                              ) : (
                                                <MicIcon
                                                  style={{ marginTop: matches ? 12 : 10, color: "white" }}
                                                  fontSize="large"
                                                  onClick={(e) => {
                                                    if (isBlocked) {
                                                      alert("Your browser doesn't have permission to record Audio!");
                                                    } else {
                                                      console.log("Recording: start", row.name, val.name);
                                                      Mp3Recorder.start()
                                                        .then(() => {
                                                          dispatch({ field: "isRecording", value: true });
                                                          dispatch({ field: "recordingMode", value: `${row.name}_${val.name}` });
                                                        })
                                                        .catch((e) => console.error(e));
                                                    }
                                                  }}
                                                  disabled={isRecording && recordingMode === `${row.name}_${val.name}`}
                                                  color="primary"
                                                />
                                              )}
                                            </div>


                                            {isRecording && (recordingMode === (row.name + "_" + val.name)) ? (
                                              <div
                                                style={{
                                                  marginLeft: matches ? 110 : 18,
                                                  marginRight: matches ? 110 : 75,
                                                  width: matches ? 550 : 100,
                                                }}
                                              >
                                                <Loader
                                                  type="ThreeDots"
                                                  color="blue"
                                                  height={40}
                                                  width={40}
                                                />
                                              </div>
                                            ) : (
                                              <audio
                                                style={{
                                                  marginRight: 10,
                                                  width: matches ? 450 : 260,
                                                  marginTop: 6,
                                                }}
                                                src={blobURL[(row.name + "_" + val.name)]}
                                                controls="controls"
                                              />
                                            )}
                                          </div>
                                        </div>
                                        <div className="col-md-9">
                                          <TextField
                                            type="text"
                                            label="Text.."
                                            value={val.detail}
                                            onChange={(e) =>
                                              onChipText(e, row.name, val)
                                            }
                                            rows={4}
                                            className="textInputStyle"
                                            variant="filled"
                                            InputProps={{
                                              className: classes.input,
                                              classes: { input: classes.input },
                                              disableUnderline: true,
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </>
                                  ) : // Ajax Autocomplete
                                  (val.autocompleteAjax) ? (
                                    <>
                                      <span
                                        className={`${"row"} ${classes.root}`}
                                        style={{
                                          marginTop: 15,
                                          marginBottom: 15,
                                        }}
                                      >
                                        <span className="col-md-12 col-sm-12 col-12">
                                          {(
                                            val.autocompleteAjax === "icd10Codes" ?
                                            (
                                              <Autocomplete
                                                multiple
                                                id="{val.autocompleteAjax}-standard"
                                                options={Icd10CodesList}
                                                getOptionLabel={(option) =>
                                                  option.code + ": " + option.name
                                                }
                                                defaultValue={[]}
                                                onChange={(e) =>
                                                  handleChangeAjaxBar(
                                                    val.setter,
                                                    e.target.value
                                                  )
                                                }
                                                renderInput={(params) => (
                                                  <TextField
                                                    {...params}
                                                    variant="standard"
                                                    label="Choose Other Chief Complaints"
                                                    placeholder="Select Chief Complaints"
                                                    onChange={(event) => {
                                                      setIcd10SearchedCodes(
                                                        event.target.value
                                                      );
                                                    }}
                                                  />
                                                )}
                                              />
                                            ): (
                                              undefined
                                            )
                                          )}
                                          
                                        </span>
                                      </span>
                                      <hr />
                                    </>
                                  ) : 
                                  row.autocompleteAjaxs && val.autocompleteAjaxs.length > 0 ? (
                                    <div>
                                      Hello Hellos
                                      <hr />
                                    </div>
                                  ): (
                                    undefined
                                  )}
                                </>
                              ) : (
                                undefined
                              )}
                            </>
                          );
                        })}
                      </div>
                    ) : (
                      undefined
                    )}




                    {row.radios && row.radios.length > 0 ? (
                      <div style={{ marginTop: 10 }}>
                        {row.radios.map((val) => {
                          let Icon;
                          let Style = {
                            color: "#070707",
                            backgroundColor: "#F5F5F5",
                          };
                          let chipIncluded = false;

                          if (val.name) {
                            investigation.map((row1) => {
                              if (row1.name === row.name) {
                                if (row1.value === val.code) {
                                  Icon = (
                                    <DoneIcon
                                      style={{ color: "white" }}
                                    />
                                  );
                                  Style = {
                                    color: "white",
                                    backgroundColor: "rgb(19 213 159)",
                                  };
                                  chipIncluded = true;
                                }
                              }
                            });
                          }

                          return (
                            <>

                              <span style={styles.chipAlignment}>
                                <Chip
                                  label={val.name}
                                  onClick={() =>
                                    handleRadioClick(val.code, row.name, val)
                                  }
                                  icon={Icon}
                                  style={Style}
                                />
                              </span>
                            </>
                          );
                        })}
                      </div>
                    ) : (
                      undefined
                    )}

                    {row.DropDowns && row.DropDowns.length > 0 ? (
                      <div
                        className={`${"row"} ${classes.root}`}
                        style={{ marginTop: 10 }}
                      >
                        {row.DropDowns.map((dropVal) => {
                          console.log('currentFields:: dropVal,', dropVal);
                          return (
                            <div
                              className="col-md-6 col-sm-6 col-6"
                              style={{ padding: "5px 0px 5px 15px" }}
                            >
                              <TextField
                                select
                                fullWidth
                                id={dropVal.name}
                                name={dropVal.name}
                                value={dropVal.value}
                                onChange={(e) =>
                                  onDropDownChange(e, row.name, dropVal)
                                }
                                label={dropVal.name}
                                variant="filled"
                                className="dropDownStyle"
                                InputProps={{
                                  className: classes.input,
                                  classes: { input: classes.input },
                                  disableUnderline: true,
                                }}
                              >
                                {/*
                                <MenuItem
                                  value={
                                    dropVal.name === "Minutes"
                                      ? minutes
                                      : dropVal.name === "Hours"
                                      ? hours
                                      : dropVal.name === "Days"
                                      ? days
                                      : dropVal.name === "Week"
                                      ? week
                                      : dropVal.name === "Month"
                                      ? month
                                      : ""
                                  }
                                >
                                  {dropVal.name === "Minutes"
                                    ? minutes
                                    : dropVal.name === "Hours"
                                    ? hours
                                    : dropVal.name === "Days"
                                    ? days
                                    : dropVal.name === "Week"
                                    ? week
                                    : dropVal.name === "Month"
                                    ? month
                                    : ""}
                                </MenuItem>
                                */}
                                {
                                  dropVal.options ?
                                  dropVal.options.map((opt)=>{
                                    return <MenuItem value={opt}>{opt}</MenuItem>;
                                  }):undefined
                                }
                              </TextField>
                            </div>
                          );
                        })}
                      </div>
                    ) : (
                      undefined
                    )}

                    {row.Texts && row.Texts.length > 0 ? (
                      <div
                        className={`${"row"} ${classes.root}`}
                        style={{ marginTop: 10 }}
                      >
                        {row.Texts.map((textVal) => {
                          return (
                            <div
                              className={
                                row.Texts.length > 1
                                  ? "col-md-6 col-sm-6 col-6"
                                  : "col-md-12 col-sm-12 col-12"
                              }
                              style={
                                row.Texts.length > 1
                                  ? { padding: "5px 0px 5px 15px" }
                                  : { padding: "5px 15px 5px 15px" }
                              }
                            >
                              <TextField
                                type="text"
                                label={textVal.name}
                                value={textVal.value}
                                onChange={(e) =>
                                  onTextChange(e, row.name, textVal)
                                }
                                rows={4}
                                className="textInputStyle"
                                variant="filled"
                                InputProps={{
                                  className: classes.input,
                                  classes: { input: classes.input },
                                  disableUnderline: true,
                                }}
                              />
                            </div>
                          );
                        })}
                      </div>
                    ) : (
                      undefined
                    )}


                    {
                      row.voiceNotes ? (
                        <div className="row mt-4">
                          <div className="col-md-3">
                            <div className="d-flex align-items-center">
                              <div
                                className="text-center"
                                style={{
                                  marginRight: matches ? 15 : 7,
                                  backgroundColor: (isRecording && (recordingMode === (row.name + "_" + "voiceNotes"))) ? "#DC004E" : "#2C6DDD",
                                  height: 50,
                                  borderRadius: 30,
                                  width: 50,
                                  cursor: 'pointer',
                                  flex: '0 0 50px'
                                }}
                              >

                                {isRecording && (recordingMode === (row.name + "_" + "voiceNotes")) ? (
                                  <StopIcon
                                    style={{ marginTop: matches ? 11 : 9, color: "white" }}
                                    fontSize="large"
                                    onClick={(name, field) => {
                                      console.log("Recording: stop", row.name, "voiceNotes");
                                      Mp3Recorder.stop()
                                        .getMp3()
                                        .then(([buffer, blob]) => {
                                          const blobURLFinal = URL.createObjectURL(blob);
                                          let blobURLx = blobURL;
                                          blobURLx[(row.name + "_" + "voiceNotes")] = blobURLFinal;
                                          dispatch({ field: "blobURL", value: blobURLx });
                                          dispatch({ field: "isRecording", value: false });
                                          dispatch({ field: "recordingMode", value: "" });
                                          var file = new File([blob], "first", { type: "audio/mp3" });
                                          // dispatch({ field: "fileAudio", value: file });
                                          onRecordAudioFinishGlobal(file, "voiceNotes", row.name, row)
                                        })
                                        .catch((e) => console.log(e));
                                    }}
                                    disabled={!isRecording}
                                    color="secondary"
                                  />
                                ) : (
                                  <MicIcon
                                    style={{ marginTop: matches ? 12 : 10, color: "white" }}
                                    fontSize="large"
                                    onClick={(e) => {
                                      if (isBlocked) {
                                        alert("Your browser doesn't have permission to record Audio!");
                                      } else {
                                        console.log("Recording: start", row.name, "voiceNotes");
                                        Mp3Recorder.start()
                                          .then(() => {
                                            dispatch({ field: "isRecording", value: true });
                                            dispatch({ field: "recordingMode", value: `${row.name}_voiceNotes` });
                                          })
                                          .catch((e) => console.error(e));
                                      }
                                    }}
                                    disabled={isRecording && recordingMode === `${row.name}_voiceNotes`}
                                    color="primary"
                                  />
                                )}
                              </div>


                              {isRecording && (recordingMode === (row.name + "_" + "voiceNotes")) ? (
                                <div
                                  style={{
                                    marginLeft: matches ? 110 : 18,
                                    marginRight: matches ? 110 : 75,
                                    width: matches ? 550 : 100,
                                  }}
                                >
                                  <Loader
                                    type="ThreeDots"
                                    color="blue"
                                    height={40}
                                    width={40}
                                  />
                                </div>
                              ) : (
                                <audio
                                  style={{
                                    marginRight: 10,
                                    width: matches ? 450 : 260,
                                    marginTop: 6,
                                  }}
                                  src={blobURL[(row.name + "_" + "voiceNotes")]}
                                  controls="controls"
                                />
                              )}
                            </div>
                          </div>
                          <div className="col-md-9">
                            {
                              <TextField
                                type="text"
                                label="Notes ..."
                                value={ checkTextValue(row.name) }
                                onChange={(e) =>
                                  onGlobalChipText(e, row.name, row.voiceNotes.notes)
                                }
                                rows={4}
                                className="textInputStyle"
                                variant="filled"
                                InputProps={{
                                  className: classes.input,
                                  classes: {
                                    input: classes.input,
                                    adornedEnd: classes.root,
                                  },
                                  disableUnderline: true,
                                }}
                              />
                            }
                          </div>
                        </div>
                      ) : undefined
                    }
                  </>
                ) : (
                  <div
                    style={{
                      paddingTop: 10,
                      paddingBottom: 10,
                      cursor: "pointer",
                    }}
                  >
                    <span style={{ fontWeight: "bold", color: "#939393" }}>
                      {row.name}
                    </span>
                  </div>
                )}
              </div>
            </div>
          );
        } else if (row.Texts && row.Texts.length > 0) {
          let column = 12 / row.Texts.length;
          return (
            <div
              className={`row ${classes.root}`}
              // style={{ marginTop: "10px" }}
            >
              {row.Texts.map((field) => {
                return (
                  <div
                    className={`${"col-md-" + column} ${"col-sm-" + column}`}
                    style={{ marginTop: "10px" }}
                  >
                    <TextField
                      disabled
                      type="text"
                      label={field.name}
                      value={
                        field.name === "Time Seen"
                          ? timeSeen
                          : field.name === "Room"
                          ? room === ""
                            ? "Room Not Assigned"
                            : room
                          : field.name === "Age"
                          ? age === 0
                            ? "Age Not Entered"
                            : age
                          : field.name === "Gender"
                          ? gender === ""
                            ? "Data Not Entered"
                            : gender
                          : field.name === "Nationality"
                          ? nationality === ""
                            ? "Not Selected"
                            : nationality
                          : field.name === "Chief Complaint"
                          ? chiefComplaintsListed === ""
                            ? "Not Selected"
                            : chiefComplaintsListed
                          : ""
                      }
                      rows={4}
                      className="textInputStyle"
                      variant="filled"
                      InputProps={{
                        className: classes.input,
                        classes: { input: classes.input },
                        disableUnderline: true,
                      }}
                    />
                  </div>
                );
              })}
            </div>
          );
        } else if (row.DropDowns && row.DropDowns.length > 0) {
          let column = 12 / row.DropDowns.length;
          return (
            <div
              className={`row ${classes.root}`}
              style={{ marginTop: "10px" }}
            >
              {row.DropDowns.map((field) => {
                return (
                  <div
                    className={`${"col-md-" + column} ${"col-sm-" + column}`}
                  >
                    <TextField
                      select
                      disabled
                      fullWidth
                      id={field.name}
                      name={field.name}
                      value={
                        field.name === "Chief Complaint"
                          ? chiefComplaint !== ""
                            ? chiefComplaint
                            : careStream !== ""
                            ? careStream
                            : "Not Selected"
                          : careStream !== ""
                          ? careStream
                          : "Not Selected"
                      }
                      label={field.name}
                      variant="filled"
                      className="dropDownStyle"
                      InputProps={{
                        className: classes.input,
                        classes: { input: classes.input },
                        disableUnderline: true,
                      }}
                    >
                      <MenuItem
                        value={
                          field.name === "Chief Complaint"
                            ? chiefComplaint !== ""
                              ? chiefComplaint
                              : careStream !== ""
                              ? careStream
                              : "Not Selected"
                            : careStream !== ""
                            ? careStream
                            : "Not Selected"
                        }
                      >
                        {field.name === "Chief Complaint"
                          ? chiefComplaint !== ""
                            ? chiefComplaint
                            : careStream !== ""
                            ? careStream
                            : "Not Selected"
                          : careStream !== ""
                          ? careStream
                          : "Not Selected"}
                      </MenuItem>
                    </TextField>
                  </div>
                );
              })}
            </div>
          );
        }
      })}

      <div
        className="row"
        style={{
          marginTop: "30px",
          marginBottom: "2%",
        }}
      >
        <div className="col-md-6 col-sm-6 col-6">
          <img
            onClick={() => props.history.goBack()}
            src={Back}
            style={{
              width: 45,
              height: 35,
              cursor: "pointer",
            }}
          />
        </div>
        <div className="col-md-6 col-sm-6 col-6 d-flex justify-content-end">
          <Button
            disabled={investigation.length > 0 ? false : true}
            onClick={() => {
              console.log('Submitting::investigation::', investigation);
              
              let countError = 0;
              Object.entries(investigation).map(([key, val]) => {
                if (val.name !=undefined ){
                  if (val.name === "Historian" && val.chips.length <= 0){
                    console.log("Submitting", "Historian")
                    countError++;
                  }
                  if (val.name === "History Limited By" && val.chips.length <= 0){
                    console.log("Submitting", "History Limited By")
                    countError++;
                  }
                  if (val.name === "Duration / Started" && val.DropDowns.length <= 0){
                    console.log("Submitting", "Duration / Started")
                    countError++;
                  }
                  if (val.name === "Current Condition / Status" && val.value === ""){
                    console.log("Submitting", "Current Condition / Status")
                    countError++;
                  }
                  if (val.name === "Modifying Factors" && val.chips.length <= 0){
                    console.log("Submitting", "Modifying Factors")
                    countError++;
                  }
                  if (val.name === "Pain Scale" && val.value === ""){
                    console.log("Submitting", "Pain Scale")
                    countError++;
                  }
                  if (val.name === "Similar Symptoms Previously" && val.value === ""){
                    console.log("Submitting", "Similar Symptoms Previously")
                    countError++;
                  }
                  if (val.name === "Recently Seen / Treated By Doctor" && val.value === ""){
                    console.log("Submitting", "Recently Seen / Treated By Doctor")
                    countError++;
                  }
                }
                //console.log('FormRow::', obj);
              });
              if (countError > 0){
                setErrorMsg(`Please fill up the complete form in order to submit. ${countError} fields are to fill.`);
                setOpenNotification(true);
              }else{
                props.handleSave(investigation)
              }
            }}
            style={
              clickedRow.length > 0
                ? styles.save
                : { ...styles.save, backgroundColor: "#13D59F" }
            }
            variant="contained"
            color="default"
          >
            Save
          </Button>
        </div>
      </div>


      <Notification
        msg={errorMsg}
        success={successMsg}
        open={openNotification}
      />
    </>
  );
}
