/*eslint-disable*/
import React, { useState, useEffect, useReducer } from "react";
import axios from "axios";
import {Button, Checkbox, TextField, MenuItem} from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Notification from "../../components/Snackbar/Notification.js";
import CustomTable from "../../components/Table/Table";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Loader from "react-loader-spinner";
import Header from "../../components/Header/Header";
import AnesthIcon from "../../assets/img/Anesthesiologists.png";
import eye_icon from "../../assets/img/Eye.png";
import Back from "../../assets/img/Back_Arrow.png";
import "../../assets/jss/material-dashboard-react/components/loaderStyle.css";
import cookie from "react-cookies";
import { styles, useStyles, useStylesForTabs } from "./styles.js";
import classNames from "classnames";
import {medicineFrequencyArray, routeArrayNames} from "../NotesScreen/configForNotesScreen";

import { displayInHumanClassic, replacePatientIdIfNull } from "../../utils/commonFunc";
import PatientDetailsQuick from "../../components/PatientDetails/PatientDetailsQuick";
import PatientClinicalInformation from "../../components/PatientDetails/PatientClinicalInformation";
import PatientDetails from "../../components/PatientDetails/PatientDetails";
import PatientDetails2 from "../../components/PatientDetails/PatientDetailsDirect";

import {
  getRegisteredPharmaReqDetail,
  updateRegisteredPharmaReqDetail,
  updateRegisteredPharmaReqEducation
} from "../../public/endpoins";

export default function RegisteredPharmaRequestDetail(props) {
  const classesForTabs = useStylesForTabs();
  const classes = useStyles();
  const matches = useMediaQuery("(min-width:600px)");
  const [currentUser, setCurrentUser] = useState(cookie.load("current_user"));

  const [ episodeId, setEpisodeId ] = useState(props.match.params.edrId);
  const [ pharmaServiceId, setPharmaServiceId ] = useState(props.match.params.serviceId);

  const capitalizeWord = s => s && s[0].toUpperCase() + s.slice(1)

  const [isVerbalRequest, setIsVerbalRequest] = useState(false);
  const [isDispensed, setDispensed] = useState(false);
  const [isEducated, setEducated] = useState("");
  const [isDBEducated, setDBEducated] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setsuccessMsg] = useState("");
  const [openNotification, setOpenNotification] = useState(false);
  const [tabName, setTabName] = useState("Medication Request");
  const [buttonValue, setButtonValue] = useState("pending");
  const [originalRequesterName, setOriginalRequesterName] = useState("");
  const [doctorStatus, setDoctorStatus] = useState("");
  const [doctorStatusNotes, setDoctorStatusNotes] = useState("");

  const [patientDetails, setPatientDetails] = useState("");
  const [ pharmaRequestDisplayId, setPharmaRequestDisplayId ] = useState("");
  const [ pharmaRequest, setPharmaRequest ] = useState({});
  const [ pharmaServices, setPharmaServices ] = useState([]);
  const [ DCDFormData, setDCDFormData ] = useState({});
  const [ Record, setRecord ] = useState({});

  if (openNotification) {
    setTimeout(() => {
      setOpenNotification(false);
      setErrorMsg("");
    }, 2000);
  }

  const getPatientRemotePharmaRequest = (sQuery) => {

    let searchURL = `${getRegisteredPharmaReqDetail}/${episodeId}/${pharmaServiceId}`; // + '/'+ currentUser._id;

    console.log('searchURL::', searchURL);

    axios
      .get(searchURL)
      .then((res) => {
        console.log('Pending task', res)
        if (res.data.success) {

          let mData = res.data.data[0];
          if (mData){
            mData = replacePatientIdIfNull(mData);

            const object = {
              '_id': mData._id,
              patientName: mData.patientId.name[0].given[0] + " " + mData.patientId.name[0].family,
              episodeNumber: mData.requestNo,//mData.patientId.identifier[0].value,
              mrn: mData.patientId.identifier[0].value,
              age: mData.patientId.age,
              gender: mData.patientId.gender,
              weight: mData.patientId.weight,
              department: mData.currentLocation,
              chiefComplaint: mData.chiefComplaint.length > 0 ? mData.chiefComplaint.pop().chiefComplaintId.short_desc : "N/A",
              chiefComplaintOthers: mData.chiefComplaintOthers,
              requestedName: `${mData.pharmacyRequest.requestedBy.name[0].given[0]} ${mData.pharmacyRequest.requestedBy.name[0].family} ( ${mData.pharmacyRequest.requestedBy.staffType==="Nurses"?mData.pharmacyRequest.requestedBy.subType.join(', '):mData.pharmacyRequest.requestedBy.staffType} )`
            };
            setPatientDetails(object);

            setRecord(mData);
            setDCDFormData(mData.dcdForm);
            setDispensed(mData.pharmacyRequest.status === "completed");
            if (mData.pharmacyRequest.educated){
              setEducated(mData.pharmacyRequest.educated);
              setDBEducated(mData.pharmacyRequest.educated);
            }

            console.log('RegisteredPharmaRequestDetail::pharmacyRequest', mData.pharmacyRequest)
            setPharmaRequestDisplayId(mData.pharmacyRequest.pharmacyRequestNo);
            setPharmaRequest(mData.pharmacyRequest)


            // let pisVerbalRequest = false;

            if (mData.pharmacyRequest.currentRequester && mData.pharmacyRequest.requestedBy){
              if (mData.pharmacyRequest.currentRequester._id !== mData.pharmacyRequest.requestedBy._id){
                setIsVerbalRequest(true);

                setDoctorStatus(mData.pharmacyRequest.doctorStatus);
                setDoctorStatusNotes(mData.pharmacyRequest.doctorStatusNotes);

                if (mData.pharmacyRequest.currentRequester.name){
                  let requesterName2 = mData.pharmacyRequest.currentRequester.name[0].given[0] + " " + mData.pharmacyRequest.currentRequester.name[0].family;
                  setOriginalRequesterName(requesterName2);
                }
              }
            }




            setPharmaServices(mData.pharmacyRequest.item)
          }
        } else if (!res.data.success) {
          setErrorMsg(res.data.error)
          setOpenNotification(true)
        }
        return res
      })
      .catch((e) => {
        console.log('error: ', e)
      })
  }

  useEffect(() => {
    getPatientRemotePharmaRequest('');
  }, []);

  const RxHX = () => {
    // console.log(DCDFormData);
    props.history.push({
      pathname: "/dashboard/home/"+Record._id+"/pharmacy/listMedication/medication-history",
      state: {
        hideMedicationAdministration: true,
      },
    });
  }

  const DCDHX = () => {
    // console.log(DCDFormData);
    props.history.push({
      // pathname: '/dashboard/home/viewDCD',
      pathname: `/dashboard/home/${Record._id}/dcd-history/`,
      state: {
        dcdForm: DCDFormData,
        selectedItem: Record,
      },
    });
  }
  

  const handleView = (rec) => {
    // let url = '/dashboard/home/rplist/details/'+rec._id+'/'+rec.pharmacyRequest._id;
    let url = `/dashboard/home/rplist/details/${episodeId}/${pharmaServiceId}/view/${rec._id}`;
    props.history.push({
      pathname: url,
    });
  }

  const handlePrintQr = (rec) => {
    // 👇 Open link in new tab programmatically
    let url = `/dashboard/home/rplist/details/${episodeId}/${pharmaServiceId}/print`;
    window.open(url, '_blank', 'noreferrer');
  }

  const handlePrintQr1PerMed = (rec) => {
    // 👇 Open link in new tab programmatically
    let url = `/dashboard/home/rplist/details/${episodeId}/${pharmaServiceId}/print?onelabel=yes`;
    window.open(url, '_blank', 'noreferrer');
  }

  const handleSubmitEducated = () => {
    if ( pharmaRequest.status==='completed' ){

      if (isEducated){
        if (isDBEducated === false || isDBEducated === "" || isDBEducated === "no"){
          axios
            .post(updateRegisteredPharmaReqEducation, {
              edrId: episodeId,
              requestId: pharmaServiceId,
              educated: isEducated,
            })
            .then((res) => {
              if (res.data.success) {
                setOpenNotification(true);
                setsuccessMsg(`The Pharma Request has been marked as Completed!`);
                window.location.reload();
              }
            })
            .catch((e) => {
              setOpenNotification(true);
              setErrorMsg("Unable to Update the Record");
            });
        }else{
          setOpenNotification(true)
          setErrorMsg('The patient is already educated!');
        }
      }else{
        setOpenNotification(true)
        setErrorMsg('Please tick the box of Patient Education first.')
      }

    }else{
      setOpenNotification(true)
      setErrorMsg('Please complete the medication first.')
    }
  }

  const handleComplete = () => {

    if ( pharmaRequest.status==='completed' ){
      setOpenNotification(true)
      setErrorMsg('You cannot perform this operation because the request is already completed.')
    }else{

      axios
          .post(updateRegisteredPharmaReqDetail, {
            staffId: currentUser._id,
            edrId: episodeId,
            requestId: pharmaServiceId,
            subRequestId: '',
            requestStatus: 'Completed',
            completeType: 'all',
            completionType: (pharmaRequest && pharmaRequest.generatedFrom === 'Discharged Request'?'discharge':'regular')
          })
          .then((res) => {
            if (res.data.success) {
              setOpenNotification(true);
              setsuccessMsg(`The Pharma Request has been marked as Completed!`);
              window.location.reload();
            }
          })
          .catch((e) => {
            setOpenNotification(true);
            setErrorMsg("Unable to Update the Record");
          });
    }
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        position: "fixed",
        width: "100%",
        height: "100%",
        backgroundColor: "#60d69f",
        overflowY: "scroll",
      }}
    >
      <Header history={props.history} />

      <PatientDetailsQuick
        edrId={episodeId} />

      <div className="cPadding">
        <div className="subheader">
          <div>
            <img src={AnesthIcon} style={{ marginLeft: "0px" }} />
            <h4>{pharmaRequestDisplayId} Pharmacist</h4>
          </div>
        </div>

        <PatientClinicalInformation
          edrId={episodeId}
          wrapClass="mt-3 mb-3" />

        {patientDetails && pharmaRequest ? (
          <div className="mb-3">
            <div className="card p-3">
              <div className="row row-5">
                <div className="col-6 col-lg-3 card-detail-block block-xdiv mb-2"><strong className="lblX">Episode #</strong><span className="valX">{patientDetails.episodeNumber}</span></div>
                <div className="col-6 col-lg-3 card-detail-block block-xdiv mb-2"><strong className="lblX">MRN #</strong><span className="valX">{patientDetails.mrn}</span></div>
                <div className="col-6 col-lg-3 card-detail-block block-xdiv mb-2"><strong className="lblX">Requester</strong><span className="valX">{patientDetails.requestedName}</span></div>
                <div className="col-6 col-lg-3 card-detail-block block-xdiv mb-2"><strong className="lblX">Date and Time</strong><span className="valX">{displayInHumanClassic(pharmaRequest.createdAt)}</span></div>
              </div>
            </div>
          </div>
        ) : (
          undefined
        )}

        <div className="d-flex justify-content-between">

          <Button
            onClick={DCDHX}
            style={{
              ...styles.stylesForButton,
              fontSize: matches ? 12 : 8,
            }}
            variant="contained"
            color="primary"
          ><img src={eye_icon} style={{ marginRight: 10 }} className="icon-view" />&nbsp;&nbsp;<strong>DCD HX</strong></Button>

          <Button
            onClick={RxHX}
            style={{
              backgroundColor: "#AD6BBF",
              color: "white",
              cursor: "pointer",
              borderRadius: 5,
              backgroundColor: "rgb(173, 107, 191)",
              outline: "none",
              fontSize: matches ? 12 : 8,
            }}
            variant="contained"
            color="primary"
          ><img src={eye_icon} style={{ marginRight: 10 }} className="icon-view" />&nbsp;&nbsp;<strong>Pharma HX</strong></Button>
        </div>


        <h4 className="h5 text-white mt-3"
          style={{
            fontWeight: '700'
          }}>{pharmaRequest && pharmaRequest.generatedFrom === 'Discharged Request'?'Discharge Medications Requested':'Medications Requested'}</h4>

        <div
          className="container-fluid"
          style={{ paddingLeft: 0, paddingRight: 0 }}
        >
          <div className="row row-5">
          { pharmaRequest ? (
            <>
              {
                Object.entries(pharmaServices).map((obj, entry) => {
                  return (
                    <div className="col-12 col-md-6 col-lg-6 col-xl-6">
                      <div className="card card-detail-info card-detail-small mt-2">
                        <div className="row row-5">
                          <div className="col-12 col-md-5  card-detail-block block-xdiv mb-2 mb-md-2"><strong className="lblX">Request ID:</strong> <span className="valX">{pharmaRequest.pharmacyRequestNo}-{entry+1}</span></div>
                          <div className="col-12 col-md-7  card-detail-block block-xdiv mb-2 mb-md-2"><strong className="lblX">Date/Time</strong> <span className="valX">{displayInHumanClassic( obj[1].createdAt )}</span></div>
                          <div className="col-sm-12 card-detail-block block-xdiv"><strong className="lblX">Item Name</strong><span className="valX"><strong className="text-capitalize">{obj[1].itemName.toLowerCase()}</strong></span></div>
                          <div className="col card-detail-block block-xdiv mt-2"><strong className="lblX">Dosage</strong><span className="valX">{obj[1].dosage}</span></div>
                          <div className="col card-detail-block block-xdiv mt-2"><strong className="lblX">Duration</strong><span className="valX">{obj[1].duration}</span></div>
                          <div className="col card-detail-block block-xdiv mt-2"><strong className="lblX">Frequency</strong><span className="valX">{medicineFrequencyArray[obj[1].frequency]}</span></div>
                          <div className="col card-detail-block block-xdiv mt-2"><strong className="lblX">Route</strong><span className="valX">{routeArrayNames[obj[1].route]??obj[1].route}</span></div>
                          <div className="col card-detail-block block-xdiv mt-2"><strong className="lblX">Requested Qty</strong><span className="valX">{(Math.ceil(obj[1].frequency)>=11 || Math.ceil(obj[1].frequency)===0)?obj[1].requestedQty:obj[1].dosage*obj[1].duration*obj[1].frequency}</span></div>
                          {pharmaRequest && pharmaRequest.generatedFrom === 'Discharged Request'?<div className="col card-detail-block block-xdiv mt-2"><strong className="lblX">Refill</strong><span className="valX">0</span></div>:undefined}
                          {
                            isVerbalRequest ?
                            <div className="col-12 mt-2 mb-md-2">
                              <div className="row row-5">
                                {
                                  originalRequesterName?
                                  <div className="col-12 col-md-7  card-detail-block block-xdiv mb-2 mb-md-2"><strong className="lblX">Verbal request of</strong> <span className="valX">{originalRequesterName}</span></div>:undefined
                                }
                                
                                <div className="col-12 col-md-5 card-detail-block block-xdiv"><strong className="lblX">Doctor Status</strong> <span className="valX">{
                                  doctorStatus==="nonconfirm"?'Doctor Flagged':(doctorStatus==="confirm"?'Request Confirm':'Pending')
                                }</span></div>

                                <div className="col-12 col-md-12 card-detail-block block-xdiv mt-2"><strong className="lblX">Doctor Notes</strong> <span className="valX">{doctorStatusNotes}</span></div>
                              </div>
                            </div>:undefined
                          }
                        </div>
                        <div className="card-status-action">
                          {
                            isVerbalRequest?
                            <i className="ml-10 zmdi zmdi-volume-up text-success"/>:undefined
                          }
                          {pharmaRequest && pharmaRequest.generatedFrom === 'Discharged Request'?<span className="badge badge-info">Discharge</span>:undefined}
                          <span className={classNames(`badge`, `status-${(obj[1].status)?obj[1].status.toLowerCase():''}`)}>{ obj[1].status }</span>
                          { 
                            obj[1].priority.toLowerCase()==='high'?<span className={classNames(`badge`, `badge-danger`)}>High</span>:undefined
                          }
                          {
                            pharmaRequest && pharmaRequest.generatedFrom === 'Discharged Request'?undefined:<i className="ml-view ml-10 zmdi zmdi-eye zmdi-hc-2x" onClick={() => {
                              handleView(obj[1]);
                            }} />
                          }
                        </div>
                      </div>
                    </div>
                  )
                })
              }
              {
                pharmaRequest && pharmaRequest.generatedFrom === 'Discharged Request'?
                <div className="col-12">
                  <div className="card p-2 mt-3">
                    <div className="form-group" style={styles.form2}>
                      <div className="row align-items-center">
                        <div className="col-md-4 text-left">Medicine Dispensed</div>
                        <div className="col-md-8 text-right">
                          <Checkbox
                            disabled={true}
                            name="isDispensed"
                            checked={isDispensed}
                            value={true}
                            // onChange={(e) => onChangeIsChildValue(e)}
                            color="default"
                            style={{ marginTop: -7 }}
                            inputProps={{
                              "aria-label": "checkbox with default color",
                            }}
                          />
                        </div>
                      </div>
                      <hr />
                      <div className="row align-items-center">
                        <div className="col-md-8 text-left">Patient Educated</div>
                        <div className="col-md-4 text-right">
                          <TextField
                            required
                            select
                            disabled={props.disableFields ? true : false}
                            name={"isEducated"}
                            value={isEducated}
                            onChange={(e) => {
                              setEducated(e.target.value);
                            }}
                            className="textInputStyle text-center"
                            InputProps={{
                              // shrink: true,
                              className: classes.input,
                              classes: { input: classes.input },
                            }}
                          >
                            <MenuItem key="" value=""></MenuItem>
                            <MenuItem key="education-provided" value="education-provided">Education Provided</MenuItem>
                            <MenuItem key="patient-already-knows" value="patient-already-knows">Patient Already Knows</MenuItem>
                            <MenuItem key="patient-declines" value="patient-declines">Patient Declines</MenuItem>
                          </TextField>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>: undefined
              }
              <div class="col-12">
                <div className="row row-5">
                  <div className="col-12 col-md-6 mt-3 mt-lg-3 text-center">
                    <Button
                      style={{
                        backgroundColor: "#AD6BBF",
                        color: "white",
                        cursor: "pointer",
                        borderRadius: 5,
                        backgroundColor: "rgb(173, 107, 191)",
                        outline: "none",
                        width: "100%",
                      }}
                      onClick={handleComplete}
                      variant="contained"
                      color="primary"
                      disabled={pharmaRequest.status==='completed'?true:false}
                      fullWidth>{pharmaRequest.status==='completed'?'Completed':'Complete All'}</Button>
                  </div>
                  {
                    pharmaRequest && pharmaRequest.generatedFrom === 'Discharged Request'?
                    <>
                      <div className="col-12 col-md-3 mt-3 text-center">
                        <Button
                          disabled={pharmaRequest.status==='completed' && isEducated ?false:true}
                          style={{
                            color: "white",
                            cursor: "pointer",
                            borderRadius: 5,
                            outline: "none",
                            width: "100%",
                          }}
                          onClick={handleSubmitEducated}
                          variant="contained"
                          color="primary"
                          fullWidth
                        >Submit Education</Button>
                      </div>
                      <div className="col-12 col-md-3 mt-3 text-center">
                        <Button
                          // className="addButton"
                          style={{
                            color: "white",
                            cursor: "pointer",
                            borderRadius: 5,
                            outline: "none",
                            width: "100%",
                          }}
                          onClick={handlePrintQr1PerMed}
                          variant="contained"
                          color="primary"
                          fullWidth
                        >Print QR</Button>
                      </div>
                    </>:
                    <div className="col-12 col-md-6 mt-3 text-center">
                      <Button
                        // className="addButton"
                        style={{
                          color: "white",
                          cursor: "pointer",
                          borderRadius: 5,
                          outline: "none",
                          width: "100%",
                        }}
                        onClick={handlePrintQr}
                        variant="contained"
                        color="primary"
                        fullWidth
                      >Print QR</Button>
                    </div>
                  }
                </div>
              </div>

            </>
          ) : (
            // <div className='LoaderStyle'>
            //   <Loader type='TailSpin' color='red' height={50} width={50} />
            // </div>
            <div className='row ' style={{ marginTop: '25px' }}>
              <div className='col-11'>
                <h3
                  style={{
                    color: 'white',
                    textAlign: 'center',
                    width: '100%',
                    position: 'absolute',
                  }}
                >
                  Opps...No Data Found
                </h3>
              </div>
              <div className='col-1' style={{ marginTop: 45 }}>
                {/* <img
                  onClick={() => props.history.goBack()}
                  src={Back_Arrow}
                  style={{
                    maxWidth: '60%',
                    height: 'auto',
                    cursor: 'pointer',
                  }}
                /> */}
              </div>
            </div>
          )}
          </div>

          <div className="row mt-3">
            <div className="col-md-12 col-sm-12 col-12">
              <img
                onClick={() => props.history.goBack()}
                src={Back}
                style={{
                  width: 45,
                  height: 35,
                  cursor: "pointer",
                }}
              />
            </div>
          </div>
        </div>

        <Notification msg={errorMsg} success={successMsg} open={openNotification} />
      </div>
    </div>
  );
}
