import axios from 'axios'
import formatDateTime from '../../utils/formatDate'
import {
    getMostSelectedCCURL,
    getMostSelectedCSURL,
    getMostRequestedlabTestsURL,
    getMostRequestedRadTestsURL,
    getMostRequestedMedicationURL
} from '../../public/endpoins'

export function MostSelectedData(
    comingFrom,
    setTableDataArray,
    setLoading,
    setOpenNotification,
    setErrorMsg
) {
    let url;
    if (comingFrom === "Most Selected Chief-Complaints") {
        url = getMostSelectedCCURL
    } else if (comingFrom === "Most Selected CareStreams") {
        url = getMostSelectedCSURL
    } else if (comingFrom === "Most Requested Lab Tests") {
        url = getMostRequestedlabTestsURL
    } else if (comingFrom === "Most Requested Radiology Exams") {
        url = getMostRequestedRadTestsURL
    } else if (comingFrom === "Most Requested Medication") {
        url = getMostRequestedMedicationURL
    }
    axios.get(url)
        .then((res) => {
            console.log("respose of most selected : ", res.data)
            if (res.data.success) {
                setLoading(false)
                if (comingFrom === "Most Selected CareStreams") {
                    res.data.data.map((d) => (
                        d.identifier = d.identifier[0].value
                    ))
                }
                else if (comingFrom === "Most Requested Lab Tests" || comingFrom === "Most Requested Radiology Exams") {
                    res.data.data.map((d) => (
                        d.identifier = d.identifier[0].value,
                        d.lastRequested = d.lastRequested === "" ? 'Not Requested yet' : formatDateTime(d.lastRequested)
                    ))
                }
                setTableDataArray(res.data.data.sort((a, b) => b.selected - a.selected))
            }
            else {
                setErrorMsg("Cannot get data at the moment")
                setOpenNotification(true)
            }
        })
        .catch((e) => {
            console.log("error", e)
        })
}