import React from "react";
import { Route, Switch } from "react-router-dom";
import NotFound from "../components/NotFound/NotFound";
import ViewRequests from "../views/Home/ViewRequests/ViewRequests";
import ServicesRequests from "../views/ViewRequests/PharmaRadLab/ServicesRequests";
import ICRECRRequests from "../views/ViewRequests/ICRECR/ConsultantRequest";
import ViewServicesRequests from "../views/ViewRequests/PharmaRadLab/ViewServicesRequests";
import DetailedPharma from "../views/ViewRequests/PharmaRadLab/DetailedPharma";
import DetailedLabRad from "../views/ViewRequests/PharmaRadLab/DetailedLabRad";
import UnderDevelopment from "../components/UnderDevelopment/underDevelopment";

class ViewRequestsRoutes extends React.PureComponent {
  render() {
    console.log("rendered");

    return (
      <Switch>
        <Route
          exact
          path={`${this.props.match.url}`}
          component={ViewRequests}
        />

        <Route
          exact
          path={`${this.props.match.url}/pharma`}
          // Under development
          // component={UnderDevelopment}
          component={ServicesRequests}
        />

        <Route
          exact
          path={`${this.props.match.url}/rad`}
          component={ServicesRequests}
        />

        <Route
          exact
          path={`${this.props.match.url}/lab`}
          component={ServicesRequests}
        />

        <Route
          exact
          path={`${this.props.match.url}/icr`}
          component={ICRECRRequests}
        />

        <Route
          exact
          path={`${this.props.match.url}/ecr`}
          component={ICRECRRequests}
        />

        <Route
          exact
          path={`${this.props.match.url}/lab/viewlab`}
          component={ViewServicesRequests}
        />

        <Route
          exact
          path={`${this.props.match.url}/rad/viewrad`}
          component={ViewServicesRequests}
        />

        <Route
          exact
          path={`${this.props.match.url}/pharma/viewpharma`}
          component={ViewServicesRequests}
        />

        <Route
          exact
          path={`${this.props.match.url}/pharma/viewpharma/detailedpharma`}
          component={DetailedPharma}
          // component={UnderDevelopment}
        />

        <Route
          exact
          path={`${this.props.match.url}/lab/viewlab/detailedlab`}
          component={DetailedLabRad}
        />

        <Route
          exact
          path={`${this.props.match.url}/rad/viewrad/detailedrad`}
          component={DetailedLabRad}
        />

        <Route path={`${this.props.match.url}/notfound`} component={NotFound} />

        <Route path="*" component={NotFound} />
      </Switch>
    );
  }
}
export default ViewRequestsRoutes;
