import React, { useState } from "react";
import PropTypes from "prop-types";
import CallIcon from "../../../assets/img/Call.png";
import VideoCallIcon from "../../../assets/img/Video Call.png";

function MainWindow({ startCall, clientId, callTo }) {
  const [friendID, setFriendID] = useState(callTo);

  /**
   * Start the call with or without video
   * @param {Boolean} video
   */
  const callWithVideo = (video) => {
    const config = { audio: true, video };

    if (friendID) {
      startCall(true, friendID, config);
    }
    // return () => friendID && startCall(true, friendID, config);
  };

  return (
    <div style={{display:"flex", alignItems:'center', justifyContent:'center', flexDirection:'column'}}>
        <img
          style={{
            height: 30,
            width: 30,
            cursor: "pointer",
            marginRight: 12,
          }}
          src={CallIcon}
          onClick={() => callWithVideo(false)}
        />
        <img
          style={{ height: 35, width: 35, cursor: "pointer" }}
          src={VideoCallIcon}
          onClick={() => callWithVideo(true)}
        />
        {/* <button
            type="button"
            className="btn-action fa fa-video-camera"
            onClick={callWithVideo(true)}
          />
          <button
            type="button"
            className="btn-action fa fa-phone"
            onClick={callWithVideo(false)}
          /> */}
    </div>
  );
}

MainWindow.propTypes = {
  clientId: PropTypes.string.isRequired,
  startCall: PropTypes.func.isRequired,
};

export default MainWindow;
