import { makeStyles } from "@material-ui/core/styles";

export const styles = {
  stylesForButton: {
    color: "white",
    cursor: "pointer",
    borderRadius: 5,
    backgroundColor: "#2c6ddd",
    height: "45px",
    outline: "none",
  },
  stylesForCancelButton: {
    color: "gray",
    cursor: "pointer",
    borderRadius: 5,
    width: "140px",
    height: "45px",
    background: "transparent",
    border: "1px solid gray",
  },
  inputFieldHeight:{
    marginTop:'10px',
    marginBottom:'10px'
  }
};

export const useStylesForTabs = makeStyles(() => ({
  root: {
    justifyContent: "center",
  },
  scroller: {
    flexGrow: "0",
  },
}));

export const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(0),
  },
  input: {
    backgroundColor: "#ECECEC",
    boxShadow: "none",
    borderRadius: 5,
    "&:after": {
      borderBottomColor: "black",
      boxShadow: "none",
    },
    "&:hover": {
      backgroundColor: "#ECECEC",
      boxShadow: "none",
    },
    "&:focus": {
      backgroundColor: "#ECECEC",
      boxShadow: "none",
      borderRadius: 5,
    },
  },
  root: {
    "& .MuiTextField-root": {
      backgroundColor: "#ECECEC",
    },
    "& .Mui-focused": {
      backgroundColor: "#ECECEC",
      color: "black",
      boxShadow: "none",
    },
    "& .Mui-disabled": {
      backgroundColor: "lightgray",
      color: "gray",
    },
    "& .MuiFormLabel-root": {
      fontSize: "13px",
      paddingRight: "45px",
    },
  },
}));