import React, { useEffect, useState } from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import axios from "axios";
import Loader from "react-loader-spinner";
import ButtonValue from "../../components/Dial/ButtonValue";
import ButtonValueCombined from "../../components/Dial/ButtonValueCombined";
import ButtonCentered from "../../components/Dial/ButtonCentered";
import { getAdminDashboardData } from "../../public/endpoins";
import formatTime from "../../utils/extractTimeFromDate";

export default function AdminPanel(props) {
  const matches = useMediaQuery("(min-width:600px)");
  const [totalCC, setTotalCC] = useState(0);
  const [totalCS, setTotalCS] = useState(0);
  const [totalLabServices, setTotalLabServices] = useState(0);
  const [totalMedication, setTotalMedication] = useState(0);
  const [totalRadServices, setTotalRadServices] = useState(0);
  const [doctorsInEvening, setdoctorsInEvening] = useState(0);
  const [doctorsInMorning, setdoctorsInMorning] = useState(0);
  const [doctorsInNight, setdoctorsInNight] = useState(0);
  const [nursesInEvening, setnursesInEvening] = useState(0);
  const [nursesInMorning, setnursesInMorning] = useState(0);
  const [nursesInNight, setnursesInNight] = useState(0);
  const [peakPatients, setPeakPatients] = useState(0);
  const [careStreamInProgress, setCareStreamInProgress] = useState(0);
  const [peakTime, setPeakTime] = useState("");
  const [peakPatientsGraphData, setpeakPatientsGraphData] = useState([]);
  const [isLoading, setisLoading] = useState(true);

  useEffect(() => {
    axios
      .get(getAdminDashboardData)
      .then((res) => {
        if (res.data.success) {
          console.log("response on admin panel : ", res.data);
          setCareStreamInProgress(res.data.data.careStreamInProgress);
          setTotalCC(res.data.data.totalCC);
          setTotalCS(res.data.data.totalCS);
          setTotalLabServices(res.data.data.totalLabServices);
          setTotalMedication(res.data.data.totalMedication);
          setTotalRadServices(res.data.data.totalRadServices);
          setdoctorsInEvening(res.data.data.DoctorsInEvening);
          setdoctorsInMorning(res.data.data.DoctorsInMorning);
          setdoctorsInNight(res.data.data.DoctorsInNight);
          setnursesInEvening(res.data.data.NursesInEvening);
          setnursesInMorning(res.data.data.NursesInMorning);
          setnursesInNight(res.data.data.NursesInNight);

          // Peak Time Data (Last Card)
          setpeakPatientsGraphData(res.data.data.peakTimeForPatients.perHour);
          setPeakPatients(res.data.data.peakTimeForPatients.totalPatients);
          setPeakTime(res.data.data.peakTimeForPatients.peakTime);

          setisLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleClick = (e) => {
    console.log("e : ", e);
    if (e.includes("Most ")) {
      props.history.push({
        pathname: "/dashboard/home/adminDashboard/mostSelected",
        state: {
          comingFrom: e,
        },
      });
    } else if (e.includes("Shift")) {
      props.history.push({
        pathname: "/dashboard/home/adminDashboard/staffInShifts",
        state: {
          comingFrom: e,
        },
      });
    } else if (e.includes("CareStreams ")) {
      props.history.push({
        pathname: "/dashboard/home/management/carestreaminprogress",
        state: {
          dashboardCare: false,
          comingFrom: e,
        },
      });
    }
  };

  return (
    <div className="container-fluid" style={{ marginBottom: 10 }}>
      <div className="row">
        <div
          className="col-md-4 col-6"
          style={{ paddingLeft: 4, paddingRight: 4, marginTop: 10 }}
        >
          <div>
            <ButtonValue
              heading={"CareStreams Currently in Progress"}
              time={
                isLoading ? (
                  <Loader
                    type="TailSpin"
                    color="green"
                    height={35}
                    width={35}
                  />
                ) : (
                  careStreamInProgress
                )
              }
              timeColor="#26D8A6"
              onClick={handleClick}
            />
          </div>
        </div>
        <div
          className="col-md-4 col-6"
          style={{ paddingLeft: 4, paddingRight: 4, marginTop: 10 }}
        >
          <div>
            <ButtonValue
              heading={"Most Selected Chief-Complaints"}
              time={
                isLoading ? (
                  <Loader
                    type="TailSpin"
                    color="green"
                    height={35}
                    width={35}
                  />
                ) : (
                  totalCC
                )
              }
              timeColor="#FFBC28"
              onClick={handleClick}
            />
          </div>
        </div>
        <div
          className="col-md-4 col-6"
          style={{ paddingLeft: 4, paddingRight: 4, marginTop: 10 }}
        >
          <div>
            <ButtonValue
              heading={"Most Selected CareStreams"}
              time={
                isLoading ? (
                  <Loader
                    type="TailSpin"
                    color="green"
                    height={35}
                    width={35}
                  />
                ) : (
                  totalCS
                )
              }
              timeColor="#845DC2"
              onClick={handleClick}
            />
          </div>
        </div>
        <div
          className="col-md-12 col-12"
          style={{ paddingLeft: 4, paddingRight: 4, marginTop: 10 }}
        >
          <div>
            <ButtonValueCombined
              heading1={"Doctors Assigned in Morning Shift"}
              time1={
                isLoading ? (
                  <Loader
                    type="TailSpin"
                    color="green"
                    height={35}
                    width={35}
                  />
                ) : (
                  doctorsInMorning
                )
              }
              onClick1={handleClick}
              timeColor1="#FF6F91"
              heading2={"Doctors Assigned in Evening Shift"}
              time2={
                isLoading ? (
                  <Loader
                    type="TailSpin"
                    color="green"
                    height={35}
                    width={35}
                  />
                ) : (
                  doctorsInEvening
                )
              }
              onClick2={handleClick}
              timeColor2="#FFBC28"
              heading3={"Doctors Assigned in Night Shift"}
              time3={
                isLoading ? (
                  <Loader
                    type="TailSpin"
                    color="green"
                    height={35}
                    width={35}
                  />
                ) : (
                  doctorsInNight
                )
              }
              onClick3={handleClick}
              timeColor3="#26D8A6"
            />
          </div>
        </div>
        <div
          className="col-md-12 col-12"
          style={{ paddingLeft: 4, paddingRight: 4, marginTop: 10 }}
        >
          <div>
            <ButtonValueCombined
              heading1={"Nurses Assigned in Morning Shift"}
              time1={
                isLoading ? (
                  <Loader
                    type="TailSpin"
                    color="green"
                    height={35}
                    width={35}
                  />
                ) : (
                  nursesInMorning
                )
              }
              onClick1={handleClick}
              timeColor1="#ffbc28"
              heading2={"Nurses Assigned in Evening Shift"}
              time2={
                isLoading ? (
                  <Loader
                    type="TailSpin"
                    color="green"
                    height={35}
                    width={35}
                  />
                ) : (
                  nursesInEvening
                )
              }
              onClick2={handleClick}
              timeColor2="#F26D91"
              heading3={"Nurses Assigned in Night Shift"}
              time3={
                isLoading ? (
                  <Loader
                    type="TailSpin"
                    color="green"
                    height={35}
                    width={35}
                  />
                ) : (
                  nursesInNight
                )
              }
              onClick3={handleClick}
              timeColor3="#26D8A6"
            />
          </div>
        </div>
        <div
          className="col-md-4 col-6"
          style={{ paddingLeft: 4, paddingRight: 4, marginTop: 10 }}
        >
          <div>
            <ButtonValue
              heading={"Most Requested Medication"}
              time={
                isLoading ? (
                  <Loader
                    type="TailSpin"
                    color="green"
                    height={35}
                    width={35}
                  />
                ) : (
                  totalMedication
                )
              }
              timeColor="#FFBC28"
              onClick={handleClick}
            />
          </div>
        </div>
        <div
          className="col-md-4 col-6"
          style={{ paddingLeft: 4, paddingRight: 4, marginTop: 10 }}
        >
          <div>
            <ButtonValue
              heading={"Most Requested Lab Tests"}
              time={
                isLoading ? (
                  <Loader
                    type="TailSpin"
                    color="green"
                    height={35}
                    width={35}
                  />
                ) : (
                  totalLabServices
                )
              }
              timeColor="#845DC2"
              onClick={handleClick}
            />
          </div>
        </div>
        <div
          className="col-md-4 col-6"
          style={{ paddingLeft: 4, paddingRight: 4, marginTop: 10 }}
        >
          <div>
            <ButtonValue
              heading={"Most Requested Radiology Exams"}
              time={
                isLoading ? (
                  <Loader
                    type="TailSpin"
                    color="green"
                    height={35}
                    width={35}
                  />
                ) : (
                  totalRadServices
                )
              }
              timeColor="#FF6F91"
              onClick={handleClick}
            />
          </div>
        </div>

        <div
          className="col-md-12 col-12"
          style={{ paddingLeft: 4, paddingRight: 4, marginTop: 10 }}
        >
          <div>
            <ButtonCentered
              TimerHeading={"Peak Time"}
              time={
                isLoading ? (
                  <Loader
                    type="TailSpin"
                    color="green"
                    height={35}
                    width={35}
                  />
                ) : (
                  formatTime(peakTime)
                )
              }
              timeColor="#26D8A6"
              buttonHeading={"Peak Time For Most Patients (Last 24 Hours)"}
              counterHeading={"Patients"}
              counter={
                isLoading ? (
                  <Loader
                    type="TailSpin"
                    color="green"
                    height={35}
                    width={35}
                  />
                ) : (
                  peakPatients
                )
              }
              data={peakPatientsGraphData}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
