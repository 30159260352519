import React, { useState, useCallback } from "react";
import { uploadsUrl } from "../../../../public/endpoins";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import ImageViewer from "react-simple-image-viewer";
import Diagram from "../../../../assets/img/diagram.png";
import ReportModel from "../../../../components/ReportModel/ReportModel";
import "./detailBlock.css";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ImageMarker, { Marker } from "react-image-marker";

export default function DetailBlock(props) {
  const matches = useMediaQuery("(min-width:600px)");

  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [imagesDisplay, setImagesDisplay] = useState([]);
  const [openViewReportsModal, setOpenViewReportsModal] = useState(false);
  const [reportData, setReportData] = useState("");
  const [open, setOpen] = useState(true);

  console.log("props::", props);

  const openImageViewer = useCallback((index, val) => {
    let newImage = [];

    val.map((item, i) => {
      if (item.includes("data:") && item.slice(0,5) === "data:") {
        newImage.push(item);
      } else if (item.includes("\\")) {
        newImage.push((uploadsUrl + item.split("\\")[1]).replace('uploads/uploads', 'uploads'));
      } else if (item.includes("/")) {
        newImage.push((uploadsUrl + item).replace('uploads/uploads', 'uploads'));
      }
    });
    setImagesDisplay(newImage);
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
    setImagesDisplay([]);
  };

  let i = 0;

  function MapDataToView(arr, isPDFGeneration) {
    console.log({ arr });
    return (
      <div className="row">
        {arr.map((e, index) => {
          console.log('detailBlockForDCD::', e);
          return (
            <div
              className={`col-md-${12 / props.columnSize} col-sm-${12 /
                props.columnSize} col-${12 / props.mobilecolumnSize}`}
              style={{ marginTop: 0 }}
              key={index}
            >
              <span style={{ color: "#848484", fontSize: matches ? 13 : 11 }}>
                {e.subheading}{" "}
                {props.formName === "Patient Details" &&
                  e.description !== undefined &&
                  ": " + e.description}
                {props.formName === "Past Medical History" &&
                  e.description !== undefined &&
                  ": " + e.description}
                {e.details && ": " + e.details}{" "}
                {props.formName === "Physical Exam" &&
                  e.description !== "" &&
                  e.description !== " " &&
                  ": " + e.description}
                {e.subheadingType ? " (" + e.subheadingType + ")" : ""}
              </span>
              <br />
              <span style={{ fontWeight: "900", fontSize: 15 }}>
                {e.subheading === "ID Card (Front)" ||
                e.subheading === "ID Card (Back)" ||
                e.subheading === "Insurance Card" ? (
                  <>
                    {e.description &&
                    e.description !== "" &&
                    e.description.includes("\\") ? (
                      <>
                        {e.description !== "" &&
                        e.description.slice(e.description.length - 3) !==
                          "pdf" ? (
                          <div
                            style={{
                              height:
                                e.subheading === "Insurance Card" ? "60px" : "",
                            }}
                          >
                            <img
                              alt=""
                              src={uploadsUrl + e.description.split("\\")[1]}
                              className="detailBlockImages"
                              // onClick={() =>
                              //   openImageViewer(0, e.description)
                              // }
                            />
                          </div>
                        ) : (
                          undefined
                        )}
                      </>
                    ) : e.description &&
                      e.description !== "" &&
                      e.description.includes("/") ? (
                      <>
                        {e.description !== "" &&
                        e.description.slice(e.description.length - 3) !==
                          "pdf" ? (
                          <div
                            style={{
                              height:
                                e.subheading === "Insurance Card" ? "60px" : "",
                            }}
                          >
                            <img
                              alt=""
                              src={uploadsUrl + e.description}
                              className="detailBlockImages"
                              // onClick={() =>
                              //   openImageViewer(0, e.description)
                              // }
                            />
                          </div>
                        ) : (
                          undefined
                        )}
                      </>
                    ) : (
                      "No Image"
                    )}
                  </>
                ) : e.subheading === "Lab Reports" ||
                  e.subheading === "Rad Reports" ? (
                  <div className="row">
                    <div
                      style={{
                        marginTop: "10px",
                        marginBottom: "1%",
                        paddingLeft: 20,
                      }}
                      className="col-md-6"
                    >
                      <Button
                        // style={{
                        //   ...styles.stylesForButton,
                        //   width: matches ? 130 : 321,
                        //   height: matches ? 45 : 40,
                        // }}
                        onClick={() => viewReports(e)}
                        variant="contained"
                        color="default"
                      >
                        View Reports
                      </Button>
                    </div>
                  </div>
                ) : // e.description !== "" &&
                //   e.description !== null ? (
                //   <span style={{ fontWeight: "400", fontSize: 15 }}>
                //     {e.detail && e.detail !== "" && e.detail !== null
                //       ? e.description +
                //         (e.detail.filter((d) => d !== undefined && d).length
                //           ? "(" +
                //             e.detail.filter((d) => d !== undefined && d) +
                //             ")"
                //           : "")
                //       : e.description}
                //   </span>
                // ) :
                e.audio && e.audio.length > 0 ? (
                  <div style={{ marginTop: "-25px" }}>
                    <div
                      style={{
                        fontWeight: "500",
                        color: "#939393",
                        fontSize: 16,
                      }}
                    >
                      {
                        isPDFGeneration?<a href={e.audio}>Download Audio</a>:
                        <audio
                          style={{
                            marginRight: 10,
                            width: matches ? 450 : 260,
                            marginTop: 6,
                          }}
                          src={ e.audio }
                          controls="controls"
                          controlsList="nodownload"
                        />  
                      }
                      
                    </div>
                  </div>
                ) :
                e.images && e.images.length > 0 ? (
                  <div style={{ marginTop: "-25px" }}>
                    <div
                      style={{
                        fontWeight: "500",
                        color: "#939393",
                        fontSize: 16,
                      }}
                    >
                      Images / Reports
                    </div>
                    {
                      false === true?
                      <div>
                        {e.images.map((item, index) => {
                          if (item.includes("data:")) {
                            if (item.slice(0,5) === "data:") {
                              return (
                                <div key={index}>{item}</div>
                              );
                            }else{
                              return (<div key={index}>Unknown7 - {item.slice(0,5)}</div>)
                            }
                          } else if (item.includes("\\")) {
                            if (item.slice(item.length - 3) !== "pdf") {
                              return (
                                <div key={index}>
                                  {(uploadsUrl + item.split("\\")[1]).replace('uploads/uploads', 'uploads')}
                                </div>
                              );
                            }
                          } else if (item.includes("/")) {
                            if (item.slice(item.length - 3) !== "pdf") {
                              return (
                                <div key={index}>
                                  {(uploadsUrl + item).replace('uploads/uploads', 'uploads')}
                                </div>
                              );
                            }
                          }
                        })}
                      </div>
                      :
                      <div className="imageContainer">
                        {e.images.map((item, index) => {
                          if (item.includes("data:")) {
                            if (item.slice(0,5) === "data:") {
                              return (
                                <div key={index}>
                                  <img
                                    alt=""
                                    key={index}
                                    src={item}
                                    onClick={() =>
                                      openImageViewer(index, e.images)
                                    }
                                    className="reportImage"
                                    crossorigin='anonymous'
                                  />
                                </div>
                              );
                            }else{
                              return (<div key={index}>Unknown7 - {item.slice(0,5)}</div>)
                            }
                          } else if (item.includes("\\")) {
                            if (item.slice(item.length - 3) !== "pdf") {
                              return (
                                <div key={index}>
                                  <img
                                    alt=""
                                    width="300"
                                    height="100"
                                    key={index}
                                    src={(uploadsUrl + item.split("\\")[1]).replace('uploads/uploads', 'uploads')}
                                    onClick={() =>
                                      openImageViewer(index, e.images)
                                    }
                                    id={'img-'+Date.now()}
                                    className="reportImage" crossorigin='anonymous'
                                  />
                                </div>
                              );
                            }
                          } else if (item.includes("/")) {
                            if (item.slice(item.length - 3) !== "pdf") {
                              return (
                                <div key={index}>
                                  <img
                                    alt=""
                                    width="300"
                                    height="100"
                                    key={index}
                                    src={(uploadsUrl + item).replace('uploads/uploads', 'uploads')}
                                    onClick={() =>
                                      openImageViewer(index, e.images)
                                    }
                                    className="reportImage"
                                  />
                                </div>
                              );
                            }
                          }
                        })}
                      </div>
                    }
                  </div>
                ) : (
                  undefined
                )
                // props.viewSurvey ? (
                //   ''
                // ) : (
                //   'N/A')
                }
              </span>
              <br />
              {e.detailsL && e.detailsL.length > 0 ? (
                <div className="row" style={{ marginTop: "10px" }}>
                  <div className="col-1">
                    <span style={{ color: "#939393", fontSize: 14 }}>L</span>
                  </div>
                  {e.detailsL.map((item, i) => {
                    return (
                      <div className="col">
                        <div className="row">
                          <div className="col">
                            <span style={{ color: "#939393", fontSize: 14 }}>
                              {item.name}
                            </span>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col">
                            <span style={{ fontWeight: "900", fontSize: 15 }}>
                              {item.value}
                            </span>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : (
                undefined
              )}
              {e.detailsR && e.detailsR.length > 0 ? (
                <div className="row" style={{ marginTop: "10px" }}>
                  <div className="col-1">
                    <span style={{ color: "#939393", fontSize: 14 }}>R</span>
                  </div>
                  {e.detailsR.map((item, i) => {
                    return (
                      <div className="col">
                        <div className="row">
                          <div className="col">
                            <span style={{ color: "#939393", fontSize: 14 }}>
                              {item.name}
                            </span>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col">
                            <span style={{ fontWeight: "900", fontSize: 15 }}>
                              {item.value}
                            </span>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : (
                undefined
              )}
              {(e.subheading && e.subheading === "See Diagram") ||
              e.subheading === "Decreased Pulse(s)" ? (
                <div className="col-md-12 col-sm-12 col-12 d-flex justify-content-center">
                  {/* <img alt="" src={Diagram} className="diagram" /> */}
                  <ImageMarker src={Diagram} markers={e.detailsImageMarkers} />
                </div>
              ) : (
                undefined
              )}
              {/* <span style={{ fontWeight: '400', fontSize: 15 }}>
                {e.detail && e.detail !== '' && e.detail !== null
                  ? e.detail
                  : undefined}
              </span> */}
            </div>
          );
        })}
      </div>
    );
  }

  const viewReports = (report) => {
    setReportData(report);
    setOpenViewReportsModal(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  let isPDFGeneration = false;
  if(props.showForPDF === undefined){
    isPDFGeneration = false
  }else{
    isPDFGeneration = props.showForPDF
  }

  return (
    <div>
      <div className="row">
        <div className="col-md-12 col-sm-12"
            style={{
              marginBottom: 15,
            }}>
          <span
            style={{
              color: "#2F77D1",
              fontWeight: "700",
              fontSize: matches ? 16 : 14,
            }}
          >
            {props.heading}
          </span>
        </div>
      </div>
      {props.subArray.map((e, index) => {
        if (i < props.subArray.length) {
          let arr = props.subArray.slice(i, props.columnSize + i);
          i = i + props.columnSize;
          return MapDataToView(arr, isPDFGeneration);
        }
      })}
      <hr width={"100%"} />

      {isViewerOpen && (
        <ImageViewer
          src={imagesDisplay}
          currentIndex={currentImage}
          onClose={closeImageViewer}
          backgroundStyle={{
            backgroundColor: "rgba(0,0,0,0.9)",
          }}
        />
      )}

      {openViewReportsModal && reportData ? (
        <ReportModel
          reportData={reportData}
          openViewReportsModal={openViewReportsModal}
          handleClose={setOpenViewReportsModal}
        />
      ) : (
        undefined
      )}
    </div>
  );
}
