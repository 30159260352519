import React from "react";
import { Route, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";
import NotFound from "../components/NotFound/NotFound";
// Routes

import ManagementRoutes from "./managementRoutes";

import patientMgmt from "../views/Home/AdminSubMenus/PatientMgmt/patientMgmt";
import patientList from "../views/Home/AdminSubMenus/PatientMgmt/patientList";
import patientBillSummary from "../views/Home/AdminSubMenus/PatientMgmt/patientBillSummary";

// import HandlePatientBilling from "../views/PatientClearence/handlePatientBilling";
// import ViewClearedPatients from "../views/PatientClearence/viewClearedPatients";
// import pendingPatientsToClear from "../views/PatientClearence/pendingPatientsToClear";

class HomeScreenRoutes extends React.PureComponent {
  render() {
    return (
      <Switch>
        <Route exact path={`${this.props.match.url}`} component={patientMgmt} />
        <Route
          exact
          path={`${this.props.match.url}/patientlist`}
          component={patientList}
        />
        <Route
          exact
          path={`${this.props.match.url}/patienthistory`}
          component={patientList}
        />

        <Route
          exact
          path={`${this.props.match.url}/billsummary`}
          component={patientBillSummary}
        />

        {/* <Route
          exact
          path={`${this.props.match.url}`}
          component={pendingPatientsToClear}
        />

        <Route
          exact
          path={`${this.props.match.url}/view/singlepatientbill`}
          component={HandlePatientBilling}
        /> */}

        {/* <Route
          exact
          path={`${this.props.match.url}/billsummary`}
          component={ViewClearedPatients}
        />

        <Route
          path={"/dashboard/home/management"}
          component={ManagementRoutes}
        /> */}

        <Route path="*" component={NotFound} />
      </Switch>
    );
  }
}
export default HomeScreenRoutes;
