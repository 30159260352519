import React from "react";
import { Route, Switch } from "react-router-dom";
import NotFound from "../components/NotFound/NotFound";
import Discharge from "../views/DischargeByEpisode/Discharge";
import addDischargeMed from "../views/DischargeByEpisode/addDischargeMed";
import DischargeMedConflict from "../views/DischargeByEpisode/DischargeMedConflict";
import dischargedPatients from "../views/DischargedPatients/dischargedPatients";
import SuccessScreen from "../components/SuccessScreen/SuccessScreen";

export default class NewDischargeRoutes extends React.PureComponent {
  render() {
    console.log(`Fucked:: /dashboard/home/:id/discharge/addmedication`);
    return (
      <Switch>

        <Route
          exact
          path={`/dashboard/home/:id/discharge/medicalConflict`}
          component={DischargeMedConflict}
        />

        <Route
          exact
          path={`/dashboard/home/:id/discharge/addmedication`}
          component={addDischargeMed}
        />

        <Route
          exact
          path={`/dashboard/home/:id/discharge/dischargedpatients`}
          component={dischargedPatients}
        />

        <Route
          exact
          path={`/dashboard/home/:id/discharge/success`}
          component={SuccessScreen}
        />

        <Route exact path='' component={Discharge} />

        <Route path={`/notfound`} component={NotFound} />

        <Route path="*" component={NotFound} />
      </Switch>
    );
  }
}
