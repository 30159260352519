import React from "react";
import MenuTree from "../../../../components/MenuTree/MenuTree";
import cookie from "react-cookies";
import Header from "../../../../components/Header/Header";
import { connect } from "react-redux";
import {
  funForReducer,
  setPatientDetailsForReducer,
} from "../../../../actions/Checking";

// Icons
import Back from "../../../../assets/img/Back_Arrow.png";
import MyDashboardIcon from "../../../../assets/img/My Dashboard.png";

// Insurance Icons
import RegisterOfficerIcon from "../../../../assets/img/PatientRegistration.png";

import Patients from "../../../../assets/img/Patients.png";

const adminPanel = [
  { img: Patients, text: "Patients" },
  {
    img: Patients,
    text: "Patients List",
    path: "patientmgmt/patientlist",
  },

  {
    img: Patients,
    text: "Patients History",
    path: "patientmgmt/patienthistory",
  },

  {
    img: Patients,
    text: "Patient Bill Summary",
    path: "patientmgmt/billsummary",
  },
];

class Dashboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      openApps: false,
      currentUser: cookie.load("current_user"),
      userStaff: "",

      options: "",

      openHome: false,

      notificationArray: [],

      headingIcon: "",
    };
  }

  componentWillMount() {
    this.setState({
      currentUser: cookie.load("current_user"),
      userStaff: cookie.load("user_staff"),
    });
  }

  componentDidMount() {
    // if (this.state.userStaff !== "" && this.state.userStaff !== "undefined") {
    //   this.setOptions();
    // }

    this.props.setPatientDetailsForReducer("");

    if (this.state.openHome)
      setTimeout(() => {
        document.getElementById("menu-open").checked = true;
      }, 120);

    if (this.state.currentUser.email === "admin@dhr.com") {
      this.setState({
        notificationArray: [
          {
            icon: MyDashboardIcon,
            title: "New Registrations Arriving",
          },
        ],
        headingIcon: RegisterOfficerIcon,
        headingTitle: "Admin Panel",
      });
    }

    // if (true) {
    //   this.setState({
    //     notificationArray: [
    //       {
    //         icon: MyDashboardIcon,
    //         title: 'New Registrations Arriving',
    //       },
    //     ],
    //     headingIcon: RegisterOfficerIcon,
    //     headingTitle: 'Insurance Claims Manager',
    //   })
    // }
    else {
      this.setState({
        notificationArray: [
          {
            icon: MyDashboardIcon,
            title: "New Registrations Arriving",
          },
        ],
        headingIcon: RegisterOfficerIcon,
        headingTitle: "Registration Officer",
      });
    }
  }

  render() {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          position: "fixed",
          width: "100%",
          height: "100%",
          // backgroundColor: "#2962CC",
          background:
            "linear-gradient(25deg, rgba(101,228,193,1) 0%, rgba(58,219,175,1) 33%, rgba(15,206,147,1) 66%, rgba(6,142,103,1) 100%)",
          backgroundSize: "100%",
          // overflow: "auto",
        }}
      >
        <Header history={this.props.history} />

        <MenuTree
          history={this.props.history}
          options={
            this.state.currentUser &&
            this.state.currentUser.staffType === "Admin"
              ? adminPanel
              : ""
          }
        />

        <div
          style={{
            position: "fixed",
            width: "100%",
            height: "10%",
            top: "90%",
          }}
        >
          <img
            onClick={() => this.props.history.goBack()}
            src={Back}
            style={{
              width: 45,
              height: 35,
              marginLeft: "5%",
              cursor: "pointer",
            }}
          />
        </div>
      </div>
    );
  }
}
const mapStateToProps = ({ CheckingReducer }) => {
  const { count, patientDetails } = CheckingReducer;
  return { count, patientDetails };
};
export default connect(mapStateToProps, {
  funForReducer,
  setPatientDetailsForReducer,
})(Dashboard);
