import axios from "axios";
import {
  getClearedPatientsURL,
  searchClearedPatientsURL,
} from "../../public/endpoins";

export function getClearedPatients(
  setStaffData,
  setGeneralArray,
  setOpenNotification,
  setErrorMsg
) {
  axios
    .get(getClearedPatientsURL)
    .then((res) => {
      if (res.data.success) {
        console.log(res.data.data);
        setGeneralArray(res.data.data);
      } else if (!res.data.success) {
        setErrorMsg(res.data.error);
        setOpenNotification(true);
      }
      return res;
    })
    .catch((e) => {
      console.log("error: ", e);
    });
}

export const searchClearedPatients = (
  a,
  setGeneralArray,
  setErrorMsg,
  setOpenNotification
) => {
  console.log("A", a);
  axios
    .get(searchClearedPatientsURL + "/" + a)
    .then((res) => {
      console.log("res", res);
      if (res.data.success) {
        console.log(res.data.data, "patient data");
        setGeneralArray(res.data.data);
      } else if (!res.data.success) {
        setErrorMsg(res.data.error);
        setOpenNotification(true);
      }
    })
    .catch((e) => {
      console.log("error after searching patient request", e);
    });
};
