/*eslint-disable*/
import React, { useState, useEffect, useReducer } from "react";
import Button from "@material-ui/core/Button";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Notification from "../../components/Snackbar/Notification.js";
import CustomTable from "../../components/Table/Table";
import TextField from "@material-ui/core/TextField";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Loader from "react-loader-spinner";
import AccountCircle from "@material-ui/icons/SearchOutlined";
import InputAdornment from "@material-ui/core/InputAdornment";
import MenuItem from "@material-ui/core/MenuItem";
import Fingerprint from "../../assets/img/fingerprint.png";
import BarCode from "../../assets/img/Bar Code.png";
import Header from "../../components/Header/Header";
import FlagManagementIcon from "../../assets/img/Flags.png";
import eye_icon from "../../assets/img/Eye.png";
import Back from "../../assets/img/Back_Arrow.png";
import "../../assets/jss/material-dashboard-react/components/loaderStyle.css";
import cookie from "react-cookies";
import { styles, useStyles, useStylesForTabs } from "./styles.js";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import axios from "axios";
import {
  getAllPendingFlagCall,
  getFlagCountCall,
  getFlagPatientByKeywordCall,
  getAllCompletedFlagCall,
  updateFlagCall,
} from "./networkCallsForFlagMgmt";

const sortBy = [
  {
    key: "ED Doctor",
    value: "ED Doctor",
    label: "ED ",
  },
  {
    key: "EOU",
    value: "EOU",
    label: "EOU ",
  },
  {
    key: "Most Flags Raised",
    value: "Most Flags Raised",
    label: "Most Flags Raised",
  },
];

const droptDownArrayForRadDoctor = [
  {
    key: "Imaging Technician",
    value: "Imaging Technician",
  },
];

const droptDownArray = [
  {
    key: "Registration Officer",
    value: "Registration Officer",
  },
  {
    key: "Sensei",
    value: "Sensei",
  },
  {
    key: "Imaging Technician",
    value: "Imaging Technician",
  },
  {
    key: "House Keeping",
    value: "House Keeping",
  },
  {
    key: "ED Nurse",
    value: "ED Nurse",
  },
  {
    key: "Lab Technician",
    value: "Lab Technician",
  },
  // {
  //   key: "ED Doctor",
  //   value: "ED Doctor",
  // },
  // {
  //   key: "Customer Care",
  //   value: "Customer Care",
  // },

  // {
  //   key: "By Rooms",
  //   value: "By Rooms",
  // },
  // {
  //   key: "ED",
  //   value: "ED",
  // },
  // {
  //   key: "EOU",
  //   value: "EOU",
  // },
];

const statusArray = [
  { key: "Pending", value: "pending" },
  { key: "Completed", value: "completed" },
];

const tableHeading = [
  "MRN No",
  "Patient Name",
  "Nurse ID",
  "Reason",
  "Date/Time",
  "Status",
  "Action",
];
const tableDataKeys = ["mrn", "name", "staff", "reason", "updatedAt", "status"];

const tableHeadingForView = [
  "MRN No",
  "Patient Name",
  // "Nurse ID",
  "Generated From",
  "Reason",
  "Date/Time",
  "Status",
  "Action",
];

const tableHeadingForSensei = [
  "MRN No",
  "Patient Name",
  // "Nurse ID",
  "Generated From",
  "Reason",
  "Date/Time",
  "Status",
  "Action",
];
const tableDataKeysForSensei = [
  "mrn",
  "name",
  "generatedFrom",
  "reason",
  "updatedAt",
  "status",
];

const actions = { assign: true };

export default function FlagMgmt(props) {
  const classesForTabs = useStylesForTabs();
  const classes = useStyles();
  const matches = useMediaQuery("(min-width:600px)");
  const [currentUser, setCurrentUser] = useState(cookie.load("current_user"));
  const [staffData, setStaffData] = useState([]);
  const [allFlagData, setAllFlagData] = useState([]);
  const [pendingGeneralArray, setPendingGeneralArray] = useState([]);
  const [completedGeneralArray, setCompletedGeneralArray] = useState([]);
  const [filteredArray, setFilteredArray] = useState([]);
  const [pendingFilteredArray, setPendingFilteredArray] = useState([]);
  const [completedFilteredArray, setCompletedFilteredArray] = useState([]);
  const [flagCount, setFlagCount] = useState("");
  const [doctorSpeciality, setDoctorSpeciality] = useState([]);
  const [nursesSpeciality, setNursesSpeciality] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [openNotification, setOpenNotification] = useState(false);
  const [tabName, setTabName] = useState("Pending");
  const [specialist, setSpecialist] = useState("");
  const [searchPatientQuery, setSearchPatientQuery] = useState("");
  const [cookies, setCookies] = useState(cookie.load("current_user"));
  const [open, setOpen] = React.useState(false);
  const [status, setStatus] = React.useState("pending");
  const [selectedFlag, setSelectedFlag] = useState("");

  const onChangeSortBy = (e) => {
    setSpecialist(e.target.value);
    if (tabName === "Pending") {
      // const params = {
      //   staff:
      //     cookies.subType.length > 0 ? cookies.subType[0] : cookies.staffType,
      //   role: cookies.additionalRole ? cookies.additionalRole : "",
      //   // generatedFrom: e.target.value,
      // };
      if (e.target.value) {
        const temp = pendingGeneralArray.filter(
          (general) => general.generatedFrom === e.target.value
        );
        setPendingFilteredArray(temp);
        // getAllPendingFlagCall(
        //   params,
        //   setPendingGeneralArray,
        //   setErrorMsg,
        //   setOpenNotification
        // );
      }
    } else {
      // const params = {
      //   staff:
      //     cookies.subType.length > 0 ? cookies.subType[0] : cookies.staffType,
      //   role: cookies.additionalRole ? cookies.additionalRole : "",
      //   // generatedFrom: e.target.value,
      // };
      if (e.target.value) {
        const temp = completedGeneralArray.filter(
          (general) => general.generatedFrom === e.target.value
        );
        setCompletedFilteredArray(temp);
        // getAllCompletedFlagCall(
        //   params,
        //   setCompletedGeneralArray,
        //   setErrorMsg,
        //   setOpenNotification
        // );
      }
    }
  };

  if (openNotification) {
    setTimeout(() => {
      setOpenNotification(false);
      setErrorMsg("");
    }, 2000);
  }

  console.log("pendingGeneralArray", pendingGeneralArray);

  useEffect(() => {
    const params = {
      staff:
        cookies.subType.length > 0 ? cookies.subType[0] : cookies.staffType,
      role: cookies.additionalRole ? cookies.additionalRole : "",
    };
    getAllPendingFlagCall(
      params,
      setPendingGeneralArray,
      setErrorMsg,
      setOpenNotification
    );

    // getFlagCountCall(setFlagCount, setErrorMsg, setOpenNotification);
  }, []);

  useEffect(() => {
    // let tabTableData = [];
    // tabTableData = staffData.filter(
    //   (d) => d.staffType !== "" && d.staffType === tabName
    // );
    // // console.log(tabTableData);
    // tabTableData.map((d) => {
    //   return (
    //     (d.stafName = d.name[0].given[0] + " " + d.name[0].family),
    //     (d.id = d.identifier[0].value),
    //     (d.addedByName =
    //       d.addedBy.name[0].given[0] + " " + d.addedBy.name[0].family)
    //   );
    // });
    // setGeneralArray(tabTableData);
  }, [tabName]);

  const handleChange = (e, tabValue) => {
    setTabName(tabValue);
    if (tabValue === "Completed") {
      const params = {
        staff:
          cookies.subType.length > 0 ? cookies.subType[0] : cookies.staffType,
        role: cookies.additionalRole ? cookies.additionalRole : "",
        // generatedFrom: e.target.value
      };
      getAllCompletedFlagCall(
        params,
        setCompletedGeneralArray,
        setErrorMsg,
        setOpenNotification
      );
      setSpecialist("");
    } else {
      const params = {
        staff:
          cookies.subType.length > 0 ? cookies.subType[0] : cookies.staffType,
        role: cookies.additionalRole ? cookies.additionalRole : "",
      };
      getAllPendingFlagCall(
        params,
        setPendingGeneralArray,
        setErrorMsg,
        setOpenNotification
      );
      setSpecialist("");
    }
  };

  function handleView(rec) {}

  function handleAssign(rec) {
    console.log("rec", rec);
    if (rec.status === "pending") {
      setOpen(true);
      setSelectedFlag(rec);
      setStatus(rec.status);
    } else {
      setOpenNotification(true);
      setErrorMsg("Flag status is already completed");
    }
  }

  function handleStatusUpdate() {
    const apiBody = {
      flagId: selectedFlag._id,
      staffId: currentUser._id,
      status: status,
    };
    updateFlagCall(apiBody);
  }

  const handleClose = () => {
    setOpen(false);
  };

  const onChangeStatus = (e) => {
    setStatus(e.target.value);
  };

  //for search patient
  const handlePatientSearch = (e) => {
    const a = e.target.value.replace(/[^\w\s]/gi, "");
    setSearchPatientQuery(a);
    if (a.length >= 3) {
      getFlagPatientByKeywordCall(
        a,
        setCompletedGeneralArray,
        setPendingGeneralArray,
        setErrorMsg,
        setOpenNotification
      );
    } else if (a.length == 0) {
      getAllPendingFlagCall(
        setPendingGeneralArray,
        setErrorMsg,
        setOpenNotification
      );
      getAllCompletedFlagCall(
        setCompletedGeneralArray,
        setErrorMsg,
        setOpenNotification
      );
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        position: "fixed",
        width: "100%",
        height: "100%",
        backgroundColor: "#60d69f",
        overflowY: "scroll",
      }}
    >
      <Header history={props.history} />

      <div className="cPadding">
        <div className="subheader">
          <div>
            <img src={FlagManagementIcon} style={{ marginLeft: "0px" }} />
            <h4>Flag Management</h4>
          </div>

          {currentUser.additionalRole === "" &&
          currentUser.staffType !== "Sensei" &&
          currentUser.staffType !== "Registration Officer" &&
          currentUser.subType[0] !== "Rad Doctor" &&
          currentUser.subType[0] !== "ED Doctor" &&
          currentUser.staffType !== "Clinical Pharmacist" ? (
            undefined
          ) : (
            <>
              {tabName === "Pending" ? (
                <Button
                  onClick={(e) => handleChange(e, "Completed")}
                  style={{
                    ...styles.stylesForButton,
                    fontSize: matches ? " " : 10,
                  }}
                  variant="contained"
                  color="primary"
                >
                  <img src={eye_icon} className="icon-view" />
                  &nbsp;&nbsp;
                  <strong>Completed Tasks</strong>
                </Button>
              ) : (
                <Button
                  onClick={(e) => handleChange(e, "Pending")}
                  style={{
                    ...styles.stylesForButton,
                    fontSize: matches ? " " : 10,
                  }}
                  variant="contained"
                  color="primary"
                >
                  <img src={eye_icon} className="icon-view" />
                  &nbsp;&nbsp;
                  <strong>Pending Tasks</strong>
                </Button>
              )}
            </>
          )}
        </div>

        {/* {currentUser.additionalRole === "" &&
        currentUser.subType[0] === "Rad Doctor" ? (
          <div
            className="container-fluid"
            style={{ paddingLeft: 0, paddingRight: 0 }}
          >
            <h4 style={{ textAlign: "center", color: "white", marginTop: 35 }}>
              You are not authorized to see the flags
            </h4>
          </div>
        ) : (
          undefined
        )} */}

        {/* {pendingGeneralArray &&
          pendingGeneralArray.map((flag) =>
            flag.generatedFor.includes(currentUser.additionalRole) ? ( */}

        {currentUser.additionalRole !== "" ||
        currentUser.staffType === "Sensei" ||
        currentUser.staffType === "Registration Officer" ||
        currentUser.staffType === "Clinical Pharmacist" ||
        currentUser.subType[0] === "Rad Doctor" ||
        currentUser.subType[0] === "ED Doctor" ? (
          <div
            className="container-fluid"
            style={{ paddingLeft: 0, paddingRight: 0 }}
          >
            {/* {currentUser.staffType === "Doctor" ? (
            <div
              className={`row ${classes.root}`}
              style={{ marginTop: "20px", marginBottom: "20px" }}
            >
              <div
                className="col-md-7 col-sm-5 col-12"
                style={{
                  ...styles.textFieldPadding,
                  paddingLeft: 15,
                  paddingRight: matches ? 0 : 15,
                }}
              >
                <TextField
                  className="textInputStyle"
                  id="searchPatientQuery"
                  type="text"
                  variant="filled"
                  label="Search Patient by Name / MRN"
                  name={"searchPatientQuery"}
                  value={searchPatientQuery}
                  onChange={handlePatientSearch}
                  InputLabelProps={{
                    classes: {
                      root: classes.label,
                      focused: classes.focusedLabel,
                      error: classes.erroredLabel,
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <AccountCircle />
                      </InputAdornment>
                    ),
                    className: classes.input,
                    classes: { input: classes.input },
                    disableUnderline: true,
                  }}
                />
              </div>

              <div
                className="col-md-3 col-sm-3 col-8"
                style={{
                  ...styles.textFieldPadding,
                  marginTop: matches ? undefined : 10,
                  paddingLeft: matches ? 5 : 15,
                  paddingRight: 0,
                }}
              >
                <TextField
                  select
                  fullWidth
                  label="Sort By"
                  id="sortBy"
                  name="sortBy"
                  // value={experience}
                  onChange={onChangeSortBy}
                  variant="filled"
                  className="dropDownStyle"
                  InputProps={{
                    className: classes.input,
                    classes: { input: classes.input },
                    disableUnderline: true,
                  }}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>

                  {sortBy.map((val) => {
                    return (
                      <MenuItem key={val.key} value={val.value}>
                        {val.label}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </div>

              <div
                className="col-md-1 col-sm-2 col-2"
                style={{
                  ...styles.textFieldPadding,
                  marginTop: matches ? undefined : 10,
                  paddingLeft: 5,
                  paddingRight: 5,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "white",
                    borderRadius: 5,
                    height: 55,
                  }}
                >
                  <img
                    src={BarCode}
                    style={{
                      width: matches ? 70 : 60,
                      height: matches ? 60 : 55,
                    }}
                  />
                </div>
              </div>

              <div
                className="col-md-1 col-sm-2 col-2"
                style={{
                  ...styles.textFieldPadding,
                  marginTop: matches ? undefined : 10,
                  paddingLeft: 0,
                  paddingRight: 12,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "white",
                    borderRadius: 5,
                    height: 55,
                  }}
                >
                  <img src={Fingerprint} style={{ maxWidth: 43, height: 43 }} />
                </div>
              </div>
            </div>
          ) : (
            undefined
          )} */}

            <div className={classesForTabs.root}>
              <Tabs
                classes={{
                  root: classesForTabs.root,
                  scroller: classesForTabs.scroller,
                }}
                value={tabName}
                onChange={handleChange}
                textColor="primary"
                TabIndicatorProps={{ style: { background: "#12387a" } }}
                centered
              >
                <Tab
                  style={{
                    color: "white",
                    borderRadius: 5,
                    outline: "none",
                    color: tabName === "Pending" ? "#12387a" : "#3B988C",
                  }}
                  label="Pending"
                  value="Pending"
                />
                <Tab
                  style={{
                    color: "white",
                    borderRadius: 5,
                    outline: "none",
                    color: tabName === "Completed" ? "#12387a" : "#3B988C",
                  }}
                  label="Completed"
                  value="Completed"
                />
              </Tabs>
            </div>

            {cookies.staffType === "Sensei" ? (
              <div
                className={`row ${classes.root}`}
                style={{ marginTop: "20px", marginBottom: "20px" }}
              >
                <div
                  className="col-md-9 col-sm-9 col-6"
                  style={{
                    ...styles.textFieldPadding,
                    marginTop: matches ? undefined : 15,
                  }}
                >
                  <h4
                    style={{
                      fontWeight: "bold",
                      color: "white",
                      fontSize: matches ? " " : 20,
                    }}
                  >
                    Flags Raised :{" "}
                    {tabName === "Pending" && specialist
                      ? pendingFilteredArray.length
                      : tabName === "Pending" && !specialist
                      ? pendingGeneralArray.length
                      : tabName === "Completed" && specialist
                      ? completedFilteredArray.length
                      : completedGeneralArray.length}
                  </h4>
                </div>

                <div
                  className="col-md-3 col-sm-3 col-6"
                  style={styles.textFieldPadding}
                >
                  <TextField
                    select
                    fullWidth
                    // label="Staff Type"
                    label="Generated From"
                    id="dropDown"
                    name="dropDown"
                    value={specialist}
                    onChange={onChangeSortBy}
                    variant="filled"
                    className="dropDownStyle"
                    InputProps={{
                      className: classes.input,
                      classes: { input: classes.input },
                      disableUnderline: true,
                    }}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {/* {currentUser.subType[0] === "Rad Doctor"
                  ? droptDownArrayForRadDoctor.map((val) => {
                      return (
                        <MenuItem key={val.key} value={val.value}>
                          {val.key}
                        </MenuItem>
                      );
                    })
                  : undefined} */}

                    {currentUser.staffType === "Sensei"
                      ? // || currentUser.staffType === "Registration Officer"
                        droptDownArray.map((val) => {
                          return (
                            <MenuItem key={val.key} value={val.value}>
                              {val.key}
                            </MenuItem>
                          );
                        })
                      : undefined}
                  </TextField>
                </div>
              </div>
            ) : (
              undefined
            )}

            <div className="row">
              {pendingGeneralArray && tabName === "Pending" ? (
                <div className="col-md-12 col-sm-12 col-12">
                  {loading ? (
                    <div style={{ margin: "auto", width: "0%", padding: 10 }}>
                      <Loader
                        type="TailSpin"
                        color="blue"
                        height={50}
                        width={50}
                      />
                    </div>
                  ) : (
                    <CustomTable
                      tableData={
                        specialist ? pendingFilteredArray : pendingGeneralArray
                      }
                      handleAssign={handleAssign}
                      tableDataKeys={tableDataKeysForSensei}
                      tableHeading={
                        // currentUser.staffType === "Sensei" ||
                        // currentUser.subType[0] === "ED Doctor" ||
                        // currentUser.subType[0] === "Rad Doctor" ||
                        // currentUser.staffType === "Registration Officer"
                        //   ? tableHeadingForSensei
                        //   :
                        tableHeadingForView
                      }
                      action={
                        // currentUser.staffType === "Sensei" ||
                        // currentUser.subType[0] === "ED Doctor" ||
                        // currentUser.subType[0] === "Rad Doctor" ||
                        // currentUser.staffType === "Registration Officer"
                        //   ?
                        actions
                        // : ""
                      }
                      handleView={handleView}
                      borderBottomColor={"#60d69f"}
                      borderBottomWidth={20}
                    />
                  )}
                </div>
              ) : completedGeneralArray && tabName === "Completed" ? (
                <div className="col-md-12 col-sm-12 col-12">
                  {loading ? (
                    <div style={{ margin: "auto", width: "0%", padding: 10 }}>
                      <Loader
                        type="TailSpin"
                        color="blue"
                        height={50}
                        width={50}
                      />
                    </div>
                  ) : (
                    <CustomTable
                      tableData={
                        specialist
                          ? completedFilteredArray
                          : completedGeneralArray
                      }
                      tableDataKeys={tableDataKeysForSensei}
                      tableHeading={
                        // currentUser.staffType === "Sensei" ||
                        // currentUser.subType[0] === "ED Doctor"
                        //   ? tableHeadingForSensei
                        //   :
                        tableHeadingForView
                      }
                      action={
                        // currentUser.staffType === "Sensei" ||
                        // currentUser.subType[0] === "ED Doctor"
                        //   ?
                        actions
                        // : ""
                      }
                      handleAssign={handleAssign}
                      borderBottomColor={"#60d69f"}
                      borderBottomWidth={20}
                    />
                  )}
                </div>
              ) : (
                <div className="LoaderStyle">
                  <Loader type="TailSpin" color="red" height={50} width={50} />
                </div>
              )}
            </div>

            {/* <h4 style={{ textAlign: "center" }}>
            Module is under development for now
          </h4> */}

            <div className="row" style={{ marginBottom: 20 }}>
              <div className="col-md-12 col-sm-12 col-12">
                <img
                  onClick={() => props.history.goBack()}
                  src={Back}
                  style={{
                    width: 45,
                    height: 35,
                    cursor: "pointer",
                  }}
                />
              </div>
            </div>
          </div>
        ) : (
          <div
            className="container-fluid"
            style={{ paddingLeft: 0, paddingRight: 0 }}
          >
            <h4 style={{ textAlign: "center", color: "white", marginTop: 35 }}>
              You are not authorized to see the flags
            </h4>
          </div>
        )}
        {/* ) : (
              <h4>You are not authorized to see flags</h4>
            )
          )} */}

        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth={true}
        >
          <DialogTitle id="alert-dialog-title">{"Select Status"}</DialogTitle>
          <DialogContent>
            <div className="col-md-12" style={styles.textFieldPadding}>
              <TextField
                select
                fullWidth
                label="Status"
                id="dropDown"
                name="dropDown"
                value={status}
                onChange={onChangeStatus}
                variant="filled"
                className="dropDownStyle"
                // InputProps={{
                //   className: classes.input,
                //   classes: { input: classes.input },
                //   disableUnderline: true,
                // }}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>

                {statusArray.map((val) => {
                  return (
                    <MenuItem key={val.key} value={val.value}>
                      {val.key}
                    </MenuItem>
                  );
                })}
              </TextField>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleStatusUpdate} color="primary" autoFocus>
              Update Status
            </Button>
          </DialogActions>
        </Dialog>

        <Notification msg={errorMsg} open={openNotification} />
      </div>
    </div>
  );
}
