import React, { useEffect, useState } from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Flip from "../../../components/Dial/Flip";
import TwoValue from "../../../components/Dial/TwoValue";
import OneValue from "../../../components/Dial/OneValue";
import IPBeds from "../../../assets/img/ipbeds.png";
import EDBeds from "../../../assets/img/edbeds.png";
import { getReportsAnesthesiologist } from "./NetworkCallsForAnesthesiologist";
import { socketUrl } from "../../../public/endpoins";
import socketIOClient from "socket.io-client";

export default function EDdoctor() {
  const matches = useMediaQuery("(min-width:600px)");
  const [totalRequestPending, setTotalRequestPending] = useState("");
  const [pendingTat, setPendingTat] = useState("");
  const [totalRequestPendingColor, setTotalRequestPendingColor] = useState("");
  const [pendingTATColor, setPendingTATColor] = useState("");
  const [graphAnesthesiologist, setGraphAnesthesiologist] = useState("");
  //Hooke for Requests In ED
  const [totalED, setTotalED] = useState("");
  const [totalEDColor, setTotalEDColor] = useState("");
  const [edTat, setEDTat] = useState("");
  const [edTatColor, setEDTatColor] = useState("");
  const [graphED, setGraphED] = useState("");
  //Hooks for Request in EOU
  const [totalEOU, setTotalEOU] = useState("");
  const [totalEOUColor, setTotalEOUColor] = useState("");
  const [eouTat, setEOUTat] = useState("");
  const [eouTatColor, setEOUTatColor] = useState("");
  const [graphEOU, setGraphEOU] = useState("");
  //Hooks for Tasks Completions
  const [completedTask, setCompletedTask] = useState("");
  const [CompletedTaskTat, setCompletedTaskTat] = useState("");
  const [completedTaskColor, setCompletedTaskColor] = useState("");
  const [completedTATColor, setCompletedTATColor] = useState("");
  const [graphCompletedTask, setGraphCompletedTask] = useState("");

  //setCommulativeTotal
  const [commulativeTotal, setCommulativeTotal] = useState("");
  const [commulativeTotalColor, setCommulativeTotalColor] = useState("");

  const [flag1Count, setFlag1Count] = useState(0);
  const [flag2Count, setFlag2Count] = useState(0);

  const [flip, setFlip] = useState(false);

  useEffect(() => {
    getReportsAnesthesiologist(
      setTotalRequestPending,
      setTotalRequestPendingColor,
      setPendingTat,
      setPendingTATColor,
      setGraphAnesthesiologist,
      setTotalED,
      setTotalEDColor,
      setEDTat,
      setEDTatColor,
      setGraphED,
      setTotalEOU,
      setTotalEOUColor,
      setEOUTat,
      setEOUTatColor,
      setGraphEOU,
      setCompletedTask,
      setCompletedTaskTat,
      setCompletedTaskColor,
      setCompletedTATColor,
      setGraphCompletedTask,
      setCommulativeTotal,
      setCommulativeTotalColor
    );

    const flagCount = [
      { value: "1st", setFlagCount: (value) => setFlag1Count(value) },
      { value: "2nd", setFlagCount: (value) => setFlag2Count(value) },
    ];

    const socket = socketIOClient(socketUrl);
    socket.emit("anesthesia_flags");
    socket.on("anesthesiaPending", (data) => {
      console.log(
        "response coming through socket for anesthesiologist Flags",
        data
      );
      flagCount.forEach(({ value, setFlagCount }) => {
        const cardCount = data.filter((item) => item.card === value).length;
        cardCount && setFlagCount(cardCount);
      });

      console.log("data", data);
    });
  }, []);

  const flipIt = () => {
    setFlip(true);
  };
  return (
    <div className="container-fluid" style={{ marginBottom: 10 }}>
      <div className="row">
        <div
          className="col-md-4 col-6"
          style={{ paddingLeft: 4, paddingRight: 4, marginTop: 10 }}
        >
          <div>
            <Flip
              flipped={!flip}
              flipHandler={flipIt}
              mainHeading={"Total Requests Pending"}
              value={
                totalRequestPending === 0 || null ? "0" : totalRequestPending
              }
              flagsCount={flag1Count}
              mainHeadingForGraph="Total Requests Pending"
              color={totalRequestPendingColor}
              subHeading={"TAT"}
              childHeading={"Request To Completion"}
              time={pendingTat === 0 || pendingTat === null ? "0" : pendingTat}
              idForGraph={"container1"}
              data={graphAnesthesiologist}
              timecolor={pendingTATColor}
            />
          </div>
        </div>

        <div
          className="col-md-4 col-6"
          style={{ paddingLeft: 4, paddingRight: 4, marginTop: 10 }}
        >
          <div>
            <Flip
              flipped={!flip}
              flipHandler={flipIt}
              mainHeading={"Requests In ED"}
              value={totalED === 0 || totalED === null ? "0" : totalED}
              mainHeadingForGraph="Requests In ED"
              color={totalEDColor}
              subHeading={"TAT"}
              flagsCount={flag2Count}
              childHeading={"Request To Completion"}
              time={edTat === null || edTat === 0 ? "0" : edTat}
              idForGraph={"container2"}
              data={graphED}
              timecolor={edTatColor}
            />
          </div>
        </div>

        <div
          className="col-md-4 col-6"
          style={{ paddingLeft: 4, paddingRight: 4, marginTop: 10 }}
        >
          <div>
            <Flip
              flipped={!flip}
              flipHandler={flipIt}
              mainHeading={"Requests In EOU"}
              value={totalEOU === 0 || totalEOU === null ? "0" : totalEOU}
              mainHeadingForGraph="Requests In EOU"
              color={totalEOUColor}
              subHeading={"TAT"}
              childHeading={"Request To Completion"}
              time={eouTat === 0 || eouTat === null ? "0" : eouTat}
              idForGraph={"container3"}
              data={graphEOU}
              timecolor={eouTatColor}
            />
          </div>
        </div>

        <div
          className="col-md-4 col-6"
          style={{ paddingLeft: 4, paddingRight: 4, marginTop: 10 }}
        >
          <div>
            <Flip
              flipped={!flip}
              flipHandler={flipIt}
              mainHeading={"Average No of Tasks Completion/Hour"}
              value={
                completedTask === 0 || completedTask === null
                  ? "0"
                  : completedTask
              }
              mainHeadingForGraph="Average No of Tasks Completion/Hour"
              color={completedTaskColor}
              subHeading={"TAT"}
              childHeading={"Decision to Disposition/ Discharge"}
              time={
                CompletedTaskTat === 0 || CompletedTaskTat === null
                  ? "0"
                  : CompletedTaskTat
              }
              idForGraph={"container4"}
              data={graphCompletedTask}
            />
          </div>
        </div>

        <div
          className="col-md-4 col-6"
          style={{ paddingLeft: 4, paddingRight: 4, marginTop: 10 }}
        >
          <div>
            <OneValue
              time={
                commulativeTotal === 0 || commulativeTotal === null
                  ? "0"
                  : commulativeTotal
              }
              colorTime={commulativeTotalColor}
              heading={"Commulative total Requests Completed"}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
