import { makeStyles } from "@material-ui/core/styles";

export const styles = {
    stylesForButton: {
        color: "white",
        cursor: "pointer",
        borderRadius: 5,
        backgroundColor: "#2c6ddd",
        height: "40px",
        outline: "none",
    },
    stylesForCloseButton: {
        color: "gray",
        cursor: "pointer",
        borderRadius: 5,
        border: '1px solid gray',
        backgroundColor: "transparent",
        height: "40px",
        outline: "none",
    }
}

export const useStylesForTabs = makeStyles(() => ({
    root: {
        justifyContent: "center",
    },
    scroller: {
        flexGrow: "0",
    },
}));