import React, { useState, useEffect, useReducer } from "react";
import Notification from "../../components/Snackbar/Notification.js";
import CustomTable from "../../components/Table/Table";
import axios from "axios";
import _ from "lodash";
import Button from "@material-ui/core/Button";
import {
  getLabRequest,
  searchCompletedLabEdr,
  searchEdrWithCareStream,
  completeLabTechnicianServiceRequest,
  porterReceiptNotificationLabTechnician,
} from "../../public/endpoins";
import Back from "../../assets/img/Back_Arrow.png";
import Header from "../../components/Header/Header";
import Lab_OPR from "../../assets/img/Pending Tasks.png";
import LabRequestIcon from "../../assets/img/Lab Requests.png";
import ButtonField from "../../components/common/Button";
import Fingerprint from "../../assets/img/fingerprint.png";
import AccountCircle from "@material-ui/icons/SearchOutlined";
import InputAdornment from "@material-ui/core/InputAdornment";
import BarCode from "../../assets/img/Bar Code.png";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import "../../assets/jss/material-dashboard-react/components/loaderStyle.css";
import socketIOClient from "socket.io-client";
import cookie from "react-cookies";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import PatientDetailsQuick from "../../components/PatientDetails/PatientDetailsQuick";
import PatientDetails2 from "../../components/PatientDetails/PatientDetailsDirect";
import moment from 'moment';
import classNames from "classnames";
import Loader from 'react-loader-spinner'
import { displayInHumanDifference, displayInHumanClassic, replacePatientIdIfNull } from "../../utils/commonFunc";

const tableHeading = [
  "Date/Time",
  "Request ID",
  "Lab Test",
  "Request By",
  "Priority",
  "Status",
  "Action",
];
const tableDataKeys = ["requestAt", "requestId", "serviceName", "requestBy", "priority", "status"];

// const tableDataKeys = [
//   "mrn",
//   "patientName",
//   "productionArea",
//   "careStream",
//   "createdAt",
// ];
// const tableDataKeys = [
//   "mrn",
//   "patientName",
//   "productionArea",
//   "careStream",
//   "createdAt",
// ];

const styles = {
  textFieldPadding: {
    paddingLeft: 5,
    paddingRight: 5,
  },
};

const useStylesForInput = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(0),
  },
  input: {
    backgroundColor: "white",
    borderRadius: 5,
    "&:after": {
      borderBottomColor: "black",
    },
    "&:hover": {
      backgroundColor: "white",
    },
    "&:focus": {
      boxShadow: "none",
      borderRadius: 5,
    },
  },
  multilineColor: {
    backgroundColor: "white",
    borderRadius: 5,
    "&:hover": {
      backgroundColor: "white",
    },
    "&:after": {
      borderBottomColor: "black",
    },
    "&:focus": {
      backgroundColor: "white",
      boxShadow: "none",
    },
  },
  root: {
    "& .MuiTextField-root": {
      backgroundColor: "white",
    },
    "& .Mui-focused": {
      backgroundColor: "white",
      color: "black",
    },
    "& .Mui-disabled": {
      backgroundColor: "white",
      color: "gray",
    },
    "&:focus": {
      backgroundColor: "white",
      boxShadow: "none",
    },
    "& .MuiFormLabel-root": {
      fontSize: "11px",

      paddingRight: "45px",
    },
  },
  label: {
    "&$focusedLabel": {
      color: "red",
      display: "none",
    },
    // "&$erroredLabel": {
    //   color: "orange"
    // }
  },
  focusedLabel: {},
}));

const actions = { view: true };

export default function PatientDetail(props) {
  const matches = useMediaQuery("(min-width:600px)");
  const classes = useStylesForInput();

  const initialState = {
    labRequestArray: [],
    chiefComplaint: "",
    comments: "",
    // isBlocked: false,
    // isRecording: false,
    // blobURL: "",
    // fileAudio: null,
    // errorMsg: "",
    // chiefComplaintList: [],
    currentUser: cookie.load("current_user"),
    // successMsg: "",
    // openNotification: false,
    // patientDetails: {}
  };
  const [labInPatient, setlabInPatient] = useState([]);
  const [patientlabRequests, setPatientlabRequests] = useState([]);
  const [patientDetails, setPatientDetails] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setsuccessMsg] = useState("");
  const [openNotification, setOpenNotification] = useState(false);
  const [searchPatientQuery, setSearchPatientQuery] = useState("");
  const [selectedRecord, setSelectedRecord] = useState({});

  function reducer(state, { field, value }) {
    return {
      ...state,
      [field]: value,
    };
  }

  const [state, dispatch] = useReducer(reducer, initialState);
  const [patientDetailsDialog, openPatientDetailsDialog] = useState(false);
  const [selectedLabReport, setSelectedLabReport] = useState("");

  const [ labServices, setLabServices ] = useState([]);
  const [ labRequest, setLabRequest ] = useState({});

  const {
    chiefComplaint,
    comments,
    isBlocked,
    isRecording,
    blobURL,
    fileAudio,
    currentUser,
    chiefComplaintList,
    // errorMsg,
    // successMsg,
    // openNotification,
    // patientDetails,
    labRequestArray,
  } = state;

  if (openNotification) {
    setTimeout(() => {
      setOpenNotification(false);
      setErrorMsg("");
    }, 9000);
  }

  useEffect(() => {
    // getPatientData();
    if (props.location.state.comingFrom === "notification") {
      axios
        .get(searchCompletedLabEdr + "/" + props.location.state.title.slice(15))
        .then((res) => {
          if (res.data.success) {
            if (res.data.data[0]) {
              console.log("lab data::", res.data.data[0]);
              setSelectedLabReport(res.data.data[0]);
              setlabInPatient([res.data.data[0].labRequest]);

              const object = {
                patientName:
                  res.data.data[0].patientId.name[0].given[0] +
                  " " +
                  res.data.data[0].patientId.name[0].family,
                mrn: res.data.data[0].patientId.identifier[0].value,
                age: res.data.data[0].patientId.age,
                gender: res.data.data[0].patientId.gender,
                weight: res.data.data[0].patientId.weight,
                // chiefComplaint:
              };
              setPatientDetails(object);
              console.log("response::", res);
            }
          } else if (!res.data.success) {
            setOpenNotification(true);
          }
        })
        .catch((e) => {
          setOpenNotification(true);
          setErrorMsg("Error while submit Lab Service request");
        });
    } else {
      const { selectedItem } = props.history.location.state;
      Object.entries(selectedItem).map(([key, val]) => {
        if (val && typeof val === "object") {
          if (key === "labRequest") {
            // dispatch({ field: "labRequestArray", value: val });

            console.log("LABREQUESTDATA", val);
            val.requestAt = moment(val.requestedAt).format('MMM DD, YYYY LT');
            val.serviceName = (
              val.serviceId ? (
                val.serviceId.category + ': ' + val.serviceId.name
              ): null
            );
            val.requestBy = (
              val.requestedBy ? (
                val.requestedBy.name[0].given[0] + ' ' + val.requestedBy.name[0].family
              ): null
            );
            console.log("LABREQUESTDATA", val);


            // setlabInPatient([val]);
          }
        }
      });

      console.log('LABTECH::DB::', getLabRequest + "/" + selectedItem.labRequest._id);

      axios
        .get(getLabRequest + "/" + selectedItem.labRequest._id)
        .then((res) => {
          if (res.data.success) {
            if (res.data.data[0]) {


              let dataR = res.data.data[0];

              let remoteRecord = replacePatientIdIfNull(dataR);
              setSelectedRecord(remoteRecord);
              console.log('Record::data::remoteRecord::', remoteRecord);

              const object = {
                '_id': remoteRecord._id,
                patientName: remoteRecord.patientId.name[0].given[0] + " " + remoteRecord.patientId.name[0].family,
                episodeNumber: remoteRecord.requestNo,//remoteRecord.patientId.identifier[0].value,
                mrn: remoteRecord.patientId.identifier[0].value,
                age: remoteRecord.patientId.age,
                gender: remoteRecord.patientId.gender,
                weight: remoteRecord.patientId.weight,
                department: remoteRecord.currentLocation,
                // chiefComplaint: remoteRecord.chiefComplaint.length > 0 ? remoteRecord.chiefComplaint.pop().chiefComplaintId.short_desc : "N/A",
                // chiefComplaintOthers: remoteRecord.chiefComplaintOthers,
              };
              setPatientDetails(object);

              dataR.labRequest.requestedName = dataR.labRequest.requestedBy.name[0].given[0] + " " + dataR.labRequest.requestedBy.name[0].family;
              setLabRequest(dataR.labRequest);
              console.log('dataR.labRequest', dataR.labRequest);

              console.log(res.data.data, "data");
              res.data.data.map((val) => {
                // Services
                let servicesNames = [];
                Object.entries(val.labRequest.services).map(([key, val]) => {
                  servicesNames.push(val.name)
                });
                val.serviceName = servicesNames.join(',');
              });
              if (dataR.labRequest){
                console.log('Record::data::join::', dataR.labRequest.services);
                setLabServices(dataR.labRequest.services);


                // let collected - labServices.filter((obj) => { return obj.nurseTechnicianStatus === 'Collected' && obj.porterStatus === 'Delivered' && obj.status === 'pending' }).length;

              }

              let labRequest = res.data.data[0].labRequest;
              labRequest.requestAt = moment(labRequest.requestedAt).format('MMM DD, YYYY LT');
              labRequest.serviceName = (
                labRequest.serviceId ? (
                  labRequest.serviceId.category + ': ' + labRequest.serviceId.name
                ): '-'
              );
              labRequest.requestBy = (
                labRequest.requestedBy ? (
                  labRequest.requestedBy.name[0].given[0] + ' ' + labRequest.requestedBy.name[0].family + ' (' + labRequest.requestedBy.staffType + ')'
                ): '-'
              );
              setlabInPatient([labRequest]);
            }
          }
        });
      //setlabInPatient([val]);

      console.log("selected patient ID", 'LABREQUESTDATA', selectedItem.labRequest._id,
        getLabRequest + "/" + selectedItem.labRequest._id);
    }

    // return () => socket.disconnect();
  }, []);

  console.log("selectedLabReport::", selectedLabReport);

  const handleView = (rec) => {
    console.log('rec::rec::', rec);
    if (
      rec.nurseTechnicianStatus === "Collected" && 
      rec.porterStatus === "Delivered"
    ) {
      let url = '/dashboard/labtechnicianpatientdetail/details/'+labRequest._id+'/'+rec._id;
      props.history.push({
        pathname: url,
      });
    }else{
      // window.alert(`The task for ${rec.category}: ${rec.name} is not completed by Nurse Technician or Porter!`);

      setErrorMsg(`The Sample for ${rec.category}: ${rec.name} is not available!`)
      setOpenNotification(true);
    }
  };

  function handleViewDetails(rec) {
    // if (
    //   rec.chiefComplaint.length > 0 &&
    //   rec.chiefComplaint &&
    //   rec.chiefComplaint[rec.chiefComplaint.length - 1].chiefComplaintId
    // ) {
    //   rec.patientId.chiefComplaint =
    //     rec.chiefComplaint[rec.chiefComplaint.length - 1].chiefComplaintId.name;
    // }

    props.history.push({
      pathname: "/dashboard/labtechnicianForm/",
      state: {
        selectedRecord:
          props.location.state.comingFrom === "notification"
            ? selectedLabReport.labRequest
            : rec,
        selectedItem:
          props.location.state.comingFrom === "notification"
            ? selectedLabReport
            : props.history.location.state,
      },
    });
  }

  function showPatientDetails() {
    openPatientDetailsDialog(false);
  }

  const handleSavePorter = () => {
    axios
      .post(porterReceiptNotificationLabTechnician, {
        edrId: selectedRecord._id,
        requestId: labRequest._id,
        requestStatus: 'completed',
        completeType: 'all'
      })
      .then((res) => {
        if (res.data.success) {
          setOpenNotification(true);
          setsuccessMsg(`The Receipt has been sent to Porter!`);
          window.location.reload();
        }
      })
      .catch((e) => {
        setOpenNotification(true);
        setErrorMsg("Unable to Send Receipt to Porter");
      });
  }

  const handleCompleteAll = () => {
    axios
        .put(completeLabTechnicianServiceRequest, {
          edrId: selectedRecord._id,
          requestId: labRequest.requestId,
          requestStatus: 'Collected',
          completeType: 'all'
        })
        .then((res) => {
          if (res.data.success) {
            setOpenNotification(true);
            setsuccessMsg(`The Lab Request has been marked as Completed!`);
            window.location.reload();
          }
        })
        .catch((e) => {
          setOpenNotification(true);
          setErrorMsg("Unable to Update the Record");
        });
  }

  // const addNewItem = () => {
  //   props.history.push({
  //     pathname: 'patientregistration',
  //     state: { comingFor: 'add' },
  //   })
  // }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        position: "fixed",
        width: "100%",
        height: "100%",
        backgroundColor: "rgb(19 213 159)",
        overflowY: "scroll",
      }}
    >
      <Header history={props.history} />

      <PatientDetailsQuick
        edrId={patientDetails._id} />

      <div className="cPadding">
        <div className="subheader">
          <div>
            <img src={LabRequestIcon} />
            <h4>Lab Service Requests</h4>
          </div>
          {/* <div>
            <ButtonField onClick={addNewItem} name='add' />
          </div> */}
        </div>

        <div
          className={`${"container-fluid"} ${classes.root}`}
          style={{
            marginTop: "25px",
            paddingLeft: "10px",
            paddingRight: "10px",
          }}
        >
          {/* <div className="row">
            <div
              className="col-md-10 col-sm-9 col-8"
              style={styles.textFieldPadding}
            >
              <TextField
                className="textInputStyle"
                id="searchPatientQuery"
                type="text"
                variant="filled"
                label="Search Patient by Name / MRN / National ID / Mobile Number"
                name={"searchPatientQuery"}
                value={searchPatientQuery}
                onChange={handlePatientSearch}
                InputLabelProps={{
                  classes: {
                    root: classes.label,
                    focused: classes.focusedLabel,
                    error: classes.erroredLabel,
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <AccountCircle />
                    </InputAdornment>
                  ),
                  className: classes.input,
                  classes: { input: classes.input },
                  disableUnderline: true,
                }}
              />
            </div>

            <div
              className="col-md-1 col-sm-2 col-2"
              style={{
                ...styles.textFieldPadding,
                paddingLeft: matches ? 5 : 0,
                paddingRight: matches ? 5 : 3,
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "white",
                  borderRadius: 5,
                  height: 55,
                }}
              >
                <img src={BarCode} style={{ width: 70, height: 60 }} />
              </div>
            </div>

            <div
              className="col-md-1 col-sm-1 col-2"
              style={{
                ...styles.textFieldPadding,
                paddingLeft: matches ? 5 : 2,
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "white",
                  borderRadius: 5,
                  height: 55,
                }}
              >
                <img src={Fingerprint} style={{ maxWidth: 43, height: 43 }} />
              </div>
            </div>
          </div> */}
        </div>

        {/* <div
          className="row container-fluid"
          style={{
            color: "#fff",
            marginTop: 30,
            fontWeight: "900",
            fontFamily: " Open Sans",
          }}
        >
          <h3>Patient Details</h3>
        </div> */}

        {patientDetails && labRequest ? (
          <div className="mb-3 container-fluid">
            <div className="card p-3">
              <div className="row row-5">
                <div className="col-6 col-lg-3 card-detail-block block-xdiv mb-2"><strong className="lblX">Episode #</strong><span className="valX">{patientDetails.episodeNumber}</span></div>
                <div className="col-6 col-lg-3 card-detail-block block-xdiv mb-2"><strong className="lblX">MRN #</strong><span className="valX">{patientDetails.mrn}</span></div>
                <div className="col-6 col-lg-3 card-detail-block block-xdiv mb-2"><strong className="lblX">Requester</strong><span className="valX">{labRequest.requestedName}</span></div>
                <div className="col-6 col-lg-3 card-detail-block block-xdiv mb-2"><strong className="lblX">Turn Around Time</strong><span className="valX">{displayInHumanDifference(labRequest.requestedAt)}</span></div>
              </div>
            </div>
          </div>
        ) : (
          undefined
        )}

        <div className="container-fluid">
          <div className="row row-5">
          {labServices && labServices.length > 0 ? (
            Object.entries(labServices).map((obj, entry) => {
              return (
                <div className="col-12 col-md-6 col-lg-6 col-xl-6">
                  <div className="card card-detail-info mb-2">
                    <div className="row row-5">
                      <div className="col-sm-6 card-detail-block block-xdiv mb-2"><strong className="lblX">Request ID</strong><span className="valX">{obj[1].requestId}</span></div>
                      <div className="col-sm-6">&nbsp;</div>
                      <div className="col-sm-12 card-detail-block block-xdiv"><strong className="lblX">Profile/Test</strong><span className="valX">{obj[1].category}: <span>{obj[1].name}</span></span></div>
                    </div>
                    <div className="card-status-action">
                      <span className={classNames(`badge`, ((obj[1].insuranceStatus=="Approved" || obj[1].insuranceStatus=="Covered" || obj[1].insuranceStatus=="Selfpay")?'badge-success':'status-pending'))}>{(obj[1].insuranceStatus=="Approved" || obj[1].insuranceStatus=="Covered" || obj[1].insuranceStatus=="Selfpay")?"RCM Approved":`RCM ${obj[1].insuranceStatus}`}</span>
                      <span title="Task Status" className={classNames(`badge`, (obj[1].status=="completed"?'badge-success':'status-pending'))}>{obj[1].status=="completed"?"Completed":obj[1].status}</span>
                      <span title="Nurse Technician / Porter Status" className={classNames(`badge`, (obj[1].nurseTechnicianStatus=="Collected"?'badge-success':'status-pending'))}>{obj[1].nurseTechnicianStatus}</span>
                      { 
                        obj[1].urgent==="Yes"?<span className="ml-info">!</span>:undefined
                      }
                      <i className="ml-view ml-10 zmdi zmdi-eye zmdi-hc-2x" onClick={() => {
                        handleView(obj[1]);
                      }} />
                    </div>
                  </div>
                </div>
              );
            })
          ) : labServices && labServices.length === 0 ? (
            <div className='col-md-12 col-sm-12 col-12' style={{ marginTop: '20px' }}>
              <h3
                style={{
                  color: 'white',
                  textAlign: 'center'
                }}
              >
                Opps!! No Data Found
              </h3>
            </div>
          ) : (
            <div className='LoaderStyle'>
              <Loader
                type='TailSpin'
                color='red'
                height={50}
                width={50}
              />
            </div>
          )}

          <div className="col-12 mt-3 text-center">
            <p>Click on any test above to<br /><strong>Confirm the Completion by Test</strong></p>
          </div>
          {
            labRequest ? (
              labRequest.porterNotified !== "yes" ? (
                <div className="col-12 col-md-6 mt-3 mt-lg-3 text-center">
                  <Button
                    style={{
                      backgroundColor: "#AD6BBF",
                      color: "white",
                      cursor: "pointer",
                      borderRadius: 5,
                      backgroundColor: "rgb(173, 107, 191)",
                      height: matches ? 56 : 45,
                      outline: "none",
                      width: "100%",
                    }}
                    onClick={handleSavePorter}
                    variant="contained"
                    color="primary"
                    fullWidth>Send Receipt to Porter</Button>
                </div>
              ):undefined
            ):undefined
          }

          {
            labServices ? 
              <div className="col-12 col-md-6 mt-3 text-center">
                {
                  labServices.filter((obj) => {
                    return obj.nurseTechnicianStatus === 'Collected' && obj.porterStatus === 'Delivered' && obj.status === 'pending'
                  }).length>0?
                  <Button
                    // className="addButton"
                    style={{
                      backgroundColor: "#AD6BBF",
                      color: "white",
                      cursor: "pointer",
                      borderRadius: 5,
                      backgroundColor: "rgb(173, 107, 191)",
                      height: matches ? 56 : 45,
                      outline: "none",
                      width: "100%",
                    }}
                    onClick={handleCompleteAll}
                    variant="contained"
                    color="primary"
                    fullWidth
                  >Complete All</Button>:undefined
                }
              </div>
            : undefined
          }

          </div>

          <div style={{ marginTop: 20, marginBottom: 20 }}>
            <img
              onClick={() => props.history.goBack()}
              src={Back}
              style={{
                width: 45,
                height: 35,
                cursor: "pointer",
              }}
            />
          </div>
          <Notification msg={errorMsg} success={successMsg} open={openNotification} />
        </div>
      </div>
    </div>
  );
}
