import axios from "axios";
let countriesList = require("../../assets/countries.json");
export function patientSearch(
  e,
  SearchPatients,
  setItemFoundSuccessfully,
  setItemFound,
  setLoadSearchedData,
  setPatientId,
  setName,
  setTelecom,
  setCities,
  setAddress,
  setphoto,
  setIdCardFront,
  setIdCardBack,
  setContact,
  setCoveredFamilyMember,
  setInsuranceCard,
  dispatch,
  setenableForm,
  setInsuranceForm,
  setenableNext,
  setComingFor
) {
  axios
    .get(SearchPatients + "/" + e)
    .then((res) => {
      if (res.data.success) {
        if (res.data.data.length > 0) {
          console.log("patients search data ", res.data.data);
          if (res.data.data[0]) {
            let cities = Object.entries(countriesList[0]);

            setPatientId(res.data.data[0]._id);

            Object.entries(res.data.data[0]).map(([key, val]) => {
              if (key === "name") {
                setName(val);
              } else if (key === "telecom") {
                setTelecom(val);
              } else if (key === "address") {
                for (var x in cities) {
                  let arr = cities[x];
                  if (arr[0] === val[0].country) {
                    setCities(arr[1]);
                  }
                }
                setAddress(val);
              } else if (key === "photo") {
                setphoto(val);
              } else if (key === "idCardFront") {
                setIdCardFront(val);
              } else if (key === "idCardBack") {
                setIdCardBack(val);
              } else if (key === "contact") {
                setContact(val);
              } else if (key === "paymentMode") {
                console.log("val::", val);
                if (val === "Insured") {
                  setenableForm(false);
                  setInsuranceForm(false);
                  setenableNext(false);
                }
              } else if (key === "coveredFamilyMember") {
                setCoveredFamilyMember(val);
              } else if (key === "insuranceCard") {
                setInsuranceCard(val);
              } else {
                console.log('d-props', key, val);
                dispatch({ field: key, value: val });
              }
            });

            setComingFor('edit');
          }
        } else {
          setItemFoundSuccessfully(false);
          setItemFound("");
          setLoadSearchedData(false);
        }
      }
    })
    .catch((e) => {
      console.log("error while searching patient", e);
    });
}
