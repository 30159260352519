/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable array-callback-return */
/* eslint-disable react/jsx-indent */
import React, { useEffect, useState, useReducer } from "react";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import tableStyles from "../../../assets/jss/material-dashboard-react/components/tableStyle.js";
import axios from "axios";
import CurrencyTextField from "@unicef/material-ui-currency-textfield";
import Notification from "../../../components/Snackbar/Notification.js";
import { updateEDR, addPreApproval } from "../../../public/endpoins";
import InputLabelComponent from "../../../components/InputLabel/inputLabel";
import ErrorMessage from "../../../components/ErrorMessage/errorMessage";
import cookie from "react-cookies";
import Header from "../../../components/Header/Header";
import PreApproval from "../../../assets/img/Pre-Approval.png";
import Back from "../../../assets/img/Back_Arrow.png";
import "../../../assets/jss/material-dashboard-react/components/TextInputStyle.css";
import CustomTable from "../../../components/Table/Table";
import TextArea from "../../../components/common/TextArea";

const statusArray = [
  { key: "Pending", value: "pending" },
  { key: "Approved", value: "approved" },
];

const tableHeadingForNeedApprovalMeds = [
  "Medicine Name",
  "Quantity",
  "Unit Price",
  "Total Price",
  " ",
];
const tableDataKeysForNeedApprovalMeds = [
  ["itemId", "name"],
  "requestedQty",
  "unitPrice",
  "totalPrice",
];

const styles = {
  stylesForButton: {
    color: "white",
    cursor: "pointer",
    borderRadius: 3,
    backgroundColor: "#2c6ddd",
    width: "125px",
    height: "40px",
    outline: "none",
  },
  textFieldPadding: {
    paddingLeft: 5,
    paddingRight: 5,
  },
  fieldHeight: {
    marginTop: 10,
  },
};

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(0),
  },
  input: {
    backgroundColor: "white",
    borderRadius: 3,
    "&:after": {
      borderBottomColor: "black",
    },
    "&:hover": {
      backgroundColor: "white",
    },
    "&:disabled": {
      color: "gray",
    },
  },
  root: {
    "& .MuiTextField-root": {
      backgroundColor: "white",
    },
    "& .Mui-focused": {
      backgroundColor: "white",
      color: "black",
    },
    "& .Mui-disabled": {
      backgroundColor: "white",
      boxShadow: "none",
    },
    "& .MuiFormLabel-root": {
      fontSize: "12px",
    },
  },
}));

function NeedApproval(props) {
  const classes = useStyles();
  const initialState = {
    testName: "",
    price: "",
    description: "",
    approvalNumber: "",
    approvalPerson:
      cookie.load("current_user").name[0].given[0] +
      " " +
      cookie.load("current_user").name[0].family,
    status: "",
    coPayment: "",
    netPayment: "",
    comments: "",
  };

  function reducer(state, { field, value }) {
    return {
      ...state,
      [field]: value,
    };
  }

  const [state, dispatch] = useReducer(reducer, initialState);

  const {
    testName,
    price,
    description,
    approvalNumber,
    approvalPerson,
    status,
    coPayment,
    netPayment,
    comments,
  } = state;

  const onChangeValue = (e) => {
    dispatch({
      field: e.target.name,
      value: e.target.value.replace(/[^\w.\s]/gi, ""),
    });
  };

  const [, setcomingFor] = useState("");
  const [currentUser, setCurrentUser] = useState("");
  const [isFormSubmitted] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [openNotification, setOpenNotification] = useState(false);
  const [id, setId] = useState("");
  const [, setrequestNo] = useState("");
  const [medicineDataArray, setmedicineDataArray] = useState([]);

  useEffect(() => {
    setCurrentUser(cookie.load("current_user"));

    const selectedRec = props.history.location.state.selectedItem;

    if (selectedRec.RequestType === "LR" || selectedRec.RequestType === "RR") {
      dispatch({
        field: "price",
        value: selectedRec.price,
      });
      dispatch({
        field: "testName",
        value: selectedRec.name,
      });
      dispatch({
        field: "description",
        value: selectedRec.notes,
      });
      dispatch({
        field: "status",
        value: selectedRec.status,
      });
    }

    if (selectedRec.item) {
      selectedRec.item.map(
        (d) => (
          (d.totalPrice =
            (d.requestedQty * d.itemId.issueUnitCost).toFixed(4) + " JD"),
          (d.unitPrice = d.itemId.issueUnitCost.toFixed(4) + " JD")
        )
      );
      dispatch({
        field: "status",
        value: selectedRec.status,
      });
      setmedicineDataArray(selectedRec.item);
    }

    setId(selectedRec._id);
    setrequestNo(selectedRec.requestId);
  }, []);

  function validateForm() {
    return (
      approvalNumber &&
      approvalNumber.length > 0 &&
      coPayment &&
      coPayment.length > 0 &&
      status &&
      status.length > 0 &&
      comments &&
      comments.length > 0
    );
  }

  const handleSubmit = () => {
    if (validateForm()) {
      const params = {
        approvalNo: approvalNumber,
        approvalPerson: currentUser._id,
        comments,
        coPayment,
        netPayment,
        status,
      };
      axios
        .post(addPreApproval, params)
        .then((res) => {
          if (res.data.success) {
            props.history.push({
              pathname: "/dashboard/home/success",
              state: {
                message1: `Request No`,
                reqCode: `${res.data.data.requestNo}`,
                message3: `Submitted Successfully`,
              },
            });
          } else if (!res.data.success) {
            setOpenNotification(true);
            setErrorMsg("Error while adding the request");
          }
        })
        .catch((e) => {
          setOpenNotification(true);
          setErrorMsg("Error after adding the Approval request");
        });
    } else {
      setOpenNotification(true);
      setErrorMsg("Please fill the complete form");
    }
  };

  if (openNotification) {
    setTimeout(() => {
      setOpenNotification(false);
      setErrorMsg("");
    }, 2000);
  }

  return (
    <div
      style={{
        backgroundColor: "rgb(19 213 159)",
        position: "fixed",
        display: "flex",
        width: "100%",
        height: "100%",
        flexDirection: "column",
        flex: 1,
        overflowY: "scroll",
      }}
    >
      <Header history={props.history} />
      <div className="cPadding">
        <div className="subheader" style={{ marginLeft: "-20px" }}>
          <div>
            <img src={PreApproval} />
            <h4>Pre-Approval</h4>
          </div>
        </div>

        <div style={{ height: "20px" }} />

        <div className="container-fluid">
          {medicineDataArray && medicineDataArray.length > 0 ? (
            <div className="row">
              <div className="col-md-12 col-sm-12 col-12">
                <CustomTable
                  tableData={medicineDataArray}
                  tableDataKeys={tableDataKeysForNeedApprovalMeds}
                  tableHeading={tableHeadingForNeedApprovalMeds}
                  borderBottomColor={"#60d69f"}
                  borderBottomWidth={20}
                />
              </div>
              <hr />
            </div>
          ) : (
            undefined
          )}
        </div>

        {props.history.location.state.selectedItem.RequestType === "LR" ||
        props.history.location.state.selectedItem.RequestType === "RR" ? (
          <div className={`container-fluid ${classes.root}`}>
            <div className="row">
              <div
                className="col-md-6 col-sm-6 col-6"
                style={styles.textFieldPadding}
              >
                <TextField
                  disabled={true}
                  label="Test Name"
                  name={"testName"}
                  value={testName}
                  className="textInputStyle"
                  variant="filled"
                  InputProps={{
                    className: classes.input,
                    classes: { input: classes.input },
                    disableUnderline: true,
                  }}
                />
              </div>
              <div
                className="col-md-6 col-sm-6 col-6"
                style={styles.textFieldPadding}
              >
                <CurrencyTextField
                  disabled={true}
                  label="Price"
                  name={"price"}
                  value={price}
                  className="textInputStyle"
                  variant="filled"
                  textAlign="left"
                  InputProps={{
                    className: classes.input,
                    classes: { input: classes.input },
                    disableUnderline: true,
                  }}
                  currencySymbol="JD"
                  outputFormat="number"
                />
              </div>
            </div>

            <div className="row" style={styles.fieldHeight}>
              <div
                className="col-md-12 col-sm-12 col-12"
                style={styles.textFieldPadding}
              >
                <TextField
                  disabled={true}
                  multiline
                  type="text"
                  error={description === "" && isFormSubmitted}
                  label="Description"
                  name={"description"}
                  value={description}
                  onChange={onChangeValue}
                  rows={4}
                  className="textInputStyle"
                  variant="filled"
                  InputProps={{
                    className: classes.input,
                    classes: { input: classes.input },
                    disableUnderline: true,
                  }}
                />
              </div>
            </div>
          </div>
        ) : (
          undefined
        )}

        <div className={`container-fluid ${classes.root}`}>
          <div className="row" style={styles.fieldHeight}>
            <div
              className="col-md-6 col-sm-6 col-6"
              style={styles.textFieldPadding}
            >
              <TextField
                label="Approval Number"
                name={"approvalNumber"}
                value={approvalNumber}
                error={approvalNumber === "" && isFormSubmitted}
                onChange={(e) => onChangeValue(e)}
                className="textInputStyle"
                variant="filled"
                InputProps={{
                  className: classes.input,
                  classes: { input: classes.input },
                  disableUnderline: true,
                }}
              />
            </div>
            <div
              className="col-md-6 col-sm-6 col-6"
              style={styles.textFieldPadding}
            >
              <TextField
                disabled
                label="Approval Person"
                name={"approvalPerson"}
                value={approvalPerson}
                error={approvalPerson === "" && isFormSubmitted}
                onChange={onChangeValue}
                className="textInputStyle"
                variant="filled"
                InputProps={{
                  className: classes.input,
                  classes: { input: classes.input },
                  disableUnderline: true,
                }}
              />
            </div>
          </div>

          <div className="row" style={styles.fieldHeight}>
            <div
              className="col-md-6 col-sm-6 col-6"
              style={styles.textFieldPadding}
            >
              <TextField
                label="Co-Payment"
                name={"coPayment"}
                value={coPayment}
                error={coPayment === "" && isFormSubmitted}
                onChange={onChangeValue}
                className="textInputStyle"
                variant="filled"
                InputProps={{
                  className: classes.input,
                  classes: { input: classes.input },
                  disableUnderline: true,
                }}
              />
            </div>
            <div
              className="col-md-6 col-sm-6 col-6"
              style={styles.textFieldPadding}
            >
              <TextField
                select
                fullWidth
                id="status"
                name="status"
                value={status}
                error={status === "" && isFormSubmitted}
                onChange={onChangeValue}
                label="Status"
                variant="filled"
                className="dropDownStyle"
                InputProps={{
                  className: classes.input,
                  classes: { input: classes.input },
                  disableUnderline: true,
                }}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {statusArray.map((val) => {
                  return (
                    <MenuItem key={val.key} value={val.value}>
                      {val.value}
                    </MenuItem>
                  );
                })}
              </TextField>
            </div>
          </div>

          <div className="row" style={styles.fieldHeight}>
            <div
              className="col-md-12 col-sm-12 col-12"
              style={styles.textFieldPadding}
            >
              <TextField
                multiline
                type="text"
                error={comments === "" && isFormSubmitted}
                label="Comments"
                name={"comments"}
                value={comments}
                onChange={onChangeValue}
                rows={4}
                className="textInputStyle"
                variant="filled"
                InputProps={{
                  className: classes.input,
                  classes: { input: classes.input },
                  disableUnderline: true,
                }}
              />
            </div>
          </div>

          <div className="row" style={{ margin: "30px -25px 20px -20px" }}>
            <div className="col-md-6 col-sm-6 col-6">
              <img
                onClick={() => props.history.goBack()}
                src={Back}
                style={{
                  width: 45,
                  height: 35,
                  cursor: "pointer",
                }}
              />
            </div>
            <div className="col-md-6 col-sm-6 col-6 d-flex justify-content-end">
              <Button
                style={styles.stylesForButton}
                onClick={handleSubmit}
                variant="contained"
                color="primary"
              >
                <strong style={{ fontSize: "12px" }}>Submit</strong>
              </Button>
            </div>
          </div>

          <Notification msg={errorMsg} open={openNotification} />
        </div>
      </div>
    </div>
  );
}
export default NeedApproval;
