import axios from "axios";
import {
  getDoctorSpecialty,
  getAnesthesiologist,
  getNursesSpecialty,
  searchLabService,
  getLabServiceCategory,
  addLabRequest,
  updateLabRequest,
  searchRadService,
  addRadRequest,
  updateRadRequest,
  addDoctorNotes,
  updateDoctorNotes,
  addEOUNurseRequest,
  getEOUNurse,
  updateEOUNurseRequest,
  addNurseTechnicianRequest,
  getNurseTechnician,
  updateNurseTechnicianRequest,
  addEDNurseRequest,
  getSpecialityNurse,
  updateEDNurseRequest,
  addAnesthesiologistNote,
  updateAnesthesiologistNote,
  addConsultationNotes,
  getSpecialityDoctor,
  updateConsultationNote,
  completeConsultationNotesURL,
  getLabTechnician,
  getRadTechnician,
} from "../../public/endpoins";
import _ from "lodash";

export function getDoctorSpecialtyCall(
  setConsultationSpecialityList,
  dispatch,
  setOpenNotification,
  setErrorMsg
) {
  axios
    .get(getDoctorSpecialty)
    .then((res) => {
      if (res.data.success) {
        console.log("response", res.data.data);
        setConsultationSpecialityList(res.data.data);
        // dispatch({
        //   field: 'speciality',
        //   value: response.data.data,
        // })
        // console.log("doctor sub data", res.data);
      } else if (!res.data.success) {
        setOpenNotification(true);
      }
    })
    .catch((e) => {
      console.log("Error while adding staff", e);
      setOpenNotification(true);
      setErrorMsg("Error while adding staff");
    });
}

export function getOnChangeSpecialityDoctorCall(dispatch, e, staffId) {
  if (e.target.value.length >= 1) {
    axios
      .get(getSpecialityDoctor + "/" + e.target.value + "/" + staffId)
      .then((res) => {
        console.log("res", res);
        if (res.data.success) {
          console.log(res.data.data);
          if (res.data.data.length > 0) {
            dispatch({
              field: "consultantArray",
              value: res.data.data,
            });
          } else {
            dispatch({
              field: "consultantArray",
              value: [],
            });
            dispatch({
              field: "consultant",
              value: "",
            });
          }
        }
      })
      .catch((e) => {
        console.log("error while searching req", e);
      });
  }
}

export function getOnChangeSpecialityNurseCall(value, dispatch, e, staffId) {
  let apiCall;
  var idForStaff = staffId;
  if (value === 1) {
    apiCall = getSpecialityNurse;
  } else if (value === 2) {
    apiCall = getEOUNurse;
  } else if (value === 3) {
    apiCall = getNurseTechnician;
  }
  if (e.target.value.length >= 1) {
    axios
      .get(apiCall + "/" + e.target.value + "/" + idForStaff)
      .then((res) => {
        console.log("res", res);
        // console.log("current doctor id", params);
        if (res.data.success) {
          // console.log(res.data.data)
          if (res.data.data.length > 0) {
            dispatch({
              field: "edNurseList",
              value: res.data.data,
            });
          } else {
            dispatch({
              field: "edNurseList",
              value: [],
            });
            dispatch({
              field: "edNurse",
              value: "",
            });
          }
        }
      })
      .catch((e) => {
        console.log("error while searching req", e);
      });
  }
}

export function getAnesthesiologistsCall(
  dispatch,
  setOpenNotification,
  setErrorMsg
) {
  axios
    .get(getAnesthesiologist)
    .then((res) => {
      console.log("response", res.data);
      if (res.data.success) {
        console.log("response", res.data.data);
        dispatch({
          field: "anesthesiologistList",
          value: res.data.data,
        });
      } else if (!res.data.success) {
        setOpenNotification(true);
      }
    })
    .catch((e) => {
      console.log("Error while adding staff", e);
      setOpenNotification(true);
      setErrorMsg("Error while adding staff");
    });
}

export function getLabTechniciansCall(
  staffId,
  dispatch,
  setOpenNotification,
  setErrorMsg
) {
  axios
    .get(getLabTechnician + "/" + staffId)
    .then((res) => {
      console.log("lab technicians", res.data);
      if (res.data.success) {
        console.log("response", res.data.data);
        dispatch({
          field: "labTechniciansList",
          value: res.data.data,
        });
      } else if (!res.data.success) {
        setOpenNotification(true);
      }
    })
    .catch((e) => {
      console.log("Error while adding staff", e);
      setOpenNotification(true);
      setErrorMsg("Error while adding staff");
    });
}

export function getRadTechniciansCall(
  staffId,
  dispatch,
  setOpenNotification,
  setErrorMsg
) {
  axios
    .get(getRadTechnician + "/" + staffId)
    .then((res) => {
      console.log("rad technicians", res.data);
      if (res.data.success) {
        console.log("response", res.data.data);
        dispatch({
          field: "radTechniciansList",
          value: res.data.data,
        });
      } else if (!res.data.success) {
        setOpenNotification(true);
      }
    })
    .catch((e) => {
      console.log("Error while adding staff", e);
      setOpenNotification(true);
      setErrorMsg("Error while adding staff");
    });
}

export function getNurseSpecialityCall(
  dispatch,
  setOpenNotification,
  setErrorMsg
) {
  axios
    .get(getNursesSpecialty)
    .then((res) => {
      console.log("response", res.data);
      if (res.data.success) {
        console.log("response", res.data.data);
        dispatch({
          field: "edNurseSpecialityList",
          value: res.data.data,
        });
      } else if (!res.data.success) {
        setOpenNotification(true);
      }
    })
    .catch((e) => {
      console.log("Error while adding staff", e);
      setOpenNotification(true);
      setErrorMsg("Error while adding staff");
    });
}

export function labServiceSearchCall(
  e,
  setItemFoundSuccessfully,
  setItemFound,
  setLoadSearchedData
) {
  if (e.length >= 1) {
    axios
      .get(searchLabService + "/" + e)
      .then((res) => {
        if (res.data.success) {
          if (res.data.data.length > 0) {
            console.log(res.data.data);
            setItemFoundSuccessfully(true);
            setItemFound(res.data.data);
            setLoadSearchedData(false);
          } else {
            setItemFoundSuccessfully(false);
            setItemFound("");
            setLoadSearchedData(false);
          }
        }
      })
      .catch((e) => {
        console.log("error while searching req", e);
      });
  }
}

export function labServiceCategoryCall(
  setCategoriesData
) {
  axios
    .get(getLabServiceCategory)
    .then((res) => {
      if (res.data.success) {
        console.log('labServiceCategoryCall', res.data.data);
        setCategoriesData(res.data.data);
        /*
        if (res.data.data.length > 0) {
          console.log(res.data.data);
          setItemFoundSuccessfully(true);
          setItemFound(res.data.data);
          setLoadSearchedData(false);
        } else {
          setItemFoundSuccessfully(false);
          setItemFound("");
          setLoadSearchedData(false);
        }
        */
      }
    })
    .catch((e) => {
      console.log("error while searching req", e);
    });
}

export function saveLabRequestCall(
  params,
  setOpenNotification,
  setErrorMsg,
  props,
  dispatch
) {
  axios
    .put(addLabRequest, params)
    .then((res) => {
      if (res.data.success) {
        // console.log("response after adding Lab Request", res.data);
        props.setSelectedPatientForReducer(res.data.data);
        dispatch({
          field: "labComments",
          value: "",
        });
        dispatch({
          field: "labPriority",
          value: "",
        });
        dispatch({
          field: "labServiceName",
          value: "",
        });
        dispatch({
          field: "labTechnician",
          value: "",
        });
      } else if (!res.data.success) {
        setOpenNotification(true);
        setErrorMsg("Error while adding the Lab Request");
      }
    })
    .catch((e) => {
      console.log("error after adding Lab Request", e);
      setOpenNotification(true);
      setErrorMsg("Error while adding the Lab Request");
    });
}

export function editLabRequestCall(
  params,
  setOpenEditLabRequestDialog,
  setOpenNotification,
  setErrorMsg,
  props,
  dispatch
) {
  axios
    .put(updateLabRequest, params)
    .then((res) => {
      if (res.data.success) {
        console.log("response", res.data.data);
        setOpenEditLabRequestDialog(false);
        // props.history.goBack();
        props.setSelectedPatientForReducer(res.data.data);
      } else if (!res.data.success) {
        setOpenNotification(true);
        setErrorMsg("Error while updating the Lab Request");
      }
    })
    .catch((e) => {
      // console.log('error after adding Lab Request', e)
      setOpenNotification(true);
      setErrorMsg("Error while updating the Lab Request");
    });
}

export function radServiceSearchCall(
  e,
  setRadioItemFoundSuccessfully,
  setRadioItemFound,
  setLoadSearchedData
) {
  if (e.length >= 1) {
    axios
      .get(searchRadService + "/" + e)
      .then((res) => {
        if (res.data.success) {
          if (res.data.data.length > 0) {
            setRadioItemFoundSuccessfully(true);
            setRadioItemFound(res.data.data);
            setLoadSearchedData(false);
          } else {
            setRadioItemFoundSuccessfully(false);
            setRadioItemFound("");
            setLoadSearchedData(false);
          }
        }
      })
      .catch((e) => {
        console.log("error while searching req", e);
      });
  }
}

export function saveRadRequestCall(
  params,
  setOpenNotification,
  setErrorMsg,
  props,
  dispatch
) {
  axios
    .put(addRadRequest, params)
    .then((res) => {
      if (res.data.success) {
        dispatch({
          field: "radComments",
          value: "",
        });
        dispatch({
          field: "radPriority",
          value: "",
        });
        dispatch({
          field: "radServiceName",
          value: "",
        });
        dispatch({
          field: "radTechnician",
          value: "",
        });
        props.setSelectedPatientForReducer(res.data.data);
      } else if (!res.data.success) {
        setOpenNotification(true);
        setErrorMsg("Error while adding the Rad Request");
      }
    })
    .catch((e) => {
      console.log("error after adding Rad Request", e);
      setOpenNotification(true);
      setErrorMsg("Error while adding the Rad Request");
    });
}

export function editRadRequestCall(
  params,
  setOpenEditRadRequestDialog,
  setOpenNotification,
  setErrorMsg,
  props,
  dispatch
) {
  axios
    .put(updateRadRequest, params)
    .then((res) => {
      if (res.data.success) {
        console.log("response after updating rad Request", res.data);
        setOpenEditRadRequestDialog(false);
        props.setSelectedPatientForReducer(res.data.data);
        // props.history.goBack();
      } else if (!res.data.success) {
        setOpenNotification(true);
        setErrorMsg("Error while adding the Rad Request");
      }
    })
    .catch((e) => {
      // console.log('error after adding Lab Request', e)
      setOpenNotification(true);
      setErrorMsg("Error while adding the Rad Request");
    });
}

export function saveDoctorNotesCall(
  setOpenAddDoctorNoteDialog,
  data,
  props,
  dispatch,
  setIcdArr,
  refreshOn
) {
  axios
    .put(addDoctorNotes, data)
    .then((response) => {
      console.log("response after adding note", response.data.data);
      setOpenAddDoctorNoteDialog(false);
      props.setSelectedPatientForReducer(response.data.data);
      // window.location.reload(false);
      // props.history.goBack();
      dispatch({ field: "doctorNotesAdditional", value: "" });
      dispatch({ field: "doctorNotes", value: "" });
      dispatch({ field: "section", value: "" });
      dispatch({ field: "blobURL", value: "" });
      dispatch({ field: "fileAudio", value: "" });
      setIcdArr([]);
      refreshOn();
    })
    .catch((error) => {
      console.log("Error");
    });
}

export function editDoctorNotesCall(
  setOpenAddDoctorNoteDialog,
  data,
  props,
  dispatch,
  setIcdArr
) {
  axios
    .put(updateDoctorNotes, data)
    .then((response) => {
      console.log("Hello", response);
      setOpenAddDoctorNoteDialog(false);
      props.setSelectedPatientForReducer(response.data.data);
      // props.history.goBack();
      dispatch({ field: "doctorNotesAdditional", value: "" });
      dispatch({ field: "doctorNotes", value: "" });
      dispatch({ field: "section", value: "" });
      dispatch({ field: "blobURL", value: "" });
      dispatch({ field: "fileAudio", value: "" });
      setIcdArr([]);
    })
    .catch((error) => {
      console.log("Error");
    });
}

export function saveEOUNurseCall(setOpenAddAnesthesiologistNoteDialog, data) {
  axios
    .put(addEOUNurseRequest, data)
    .then((response) => {
      console.log("Hello", response);
      setOpenAddAnesthesiologistNoteDialog(false);
      // props.history.goBack()
    })
    .catch((error) => {
      console.log("Error");
    });
}

export function getSelectedEOUNurseCall(
  rec,
  url,
  dispatch,
  setOpenDialog,
  staffId
) {
  axios
    .get(getEOUNurse + "/" + rec.speciality + "/" + staffId)
    .then((res) => {
      console.log("res", res);
      if (res.data.success) {
        console.log(res.data.data);
        dispatch({
          field: "eouNurseList",
          value: res.data.data,
        });
        dispatch({ field: "blobURL", value: url });
        dispatch({ field: "requestDescription", value: rec.notes });
        dispatch({ field: "eouNurseSpeciality", value: rec.speciality });
        dispatch({ field: "eouNurse", value: rec.eouNurseId });

        setOpenDialog(true);
      }
    })
    .catch((e) => {
      console.log("error while searching req", e);
    });
}

export function editEOUNurseCall(setOpenAddAnesthesiologistNoteDialog, data) {
  axios
    .put(updateEOUNurseRequest, data)
    .then((response) => {
      console.log("Hello", response);
      setOpenAddAnesthesiologistNoteDialog(false);
      // props.history.goBack()
    })
    .catch((error) => {
      console.log("Error");
    });
}

export function saveNurseTechnicianCall(
  setOpenAddAnesthesiologistNoteDialog,
  data
) {
  axios
    .put(addNurseTechnicianRequest, data)
    .then((response) => {
      console.log("Hello", response);
      setOpenAddAnesthesiologistNoteDialog(false);
      // props.history.goBack()
    })
    .catch((error) => {
      console.log("Error");
    });
}

export function getSelectedNurseTechnicianCall(
  rec,
  url,
  dispatch,
  setOpenDialog
) {
  axios
    .get(getNurseTechnician + "/" + rec.speciality)
    .then((res) => {
      console.log("res", res);
      if (res.data.success) {
        console.log(res.data.data);
        dispatch({
          field: "nurseTechnicianList",
          value: res.data.data,
        });
        dispatch({ field: "blobURL", value: url });
        dispatch({ field: "requestDescription", value: rec.notes });
        dispatch({
          field: "nurseTechnicianSpeciality",
          value: rec.speciality,
        });
        dispatch({ field: "nurseTechnician", value: rec.nurseTechnicianId });

        setOpenDialog(true);
      }
    })
    .catch((e) => {
      console.log("error while searching req", e);
    });
}

export function editNurseTechnicianCall(
  setOpenAddAnesthesiologistNoteDialog,
  data
) {
  axios
    .put(updateNurseTechnicianRequest, data)
    .then((response) => {
      console.log("Hello", response);
      setOpenAddAnesthesiologistNoteDialog(false);
      // props.history.goBack()
    })
    .catch((error) => {
      console.log("Error");
    });
}

export function saveEDNurseCall(value, setOpenDialog, data, props, dispatch) {
  let apiCall;
  if (value === 1) {
    apiCall = addEDNurseRequest;
  } else if (value === 2) {
    apiCall = addEOUNurseRequest;
  } else if (value === 3) {
    apiCall = addNurseTechnicianRequest;
  }
  axios
    .put(apiCall, data)
    .then((response) => {
      console.log("Hello", response);
      setOpenDialog(false);
      props.setSelectedPatientForReducer(response.data.data);
      // props.history.goBack();
      dispatch({ field: "edNurseSpeciality", value: "" });
      dispatch({ field: "edNurse", value: "" });
      dispatch({ field: "requestDescription", value: "" });
    })
    .catch((error) => {
      console.log("Error");
    });
}

export function getEDNurseCall(
  value,
  rec,
  url,
  dispatch,
  setOpenDialog,
  staffId
) {
  let apiCall;
  if (value === 1) {
    apiCall = getSpecialityNurse;
  } else if (value === 2) {
    apiCall = getEOUNurse;
  } else if (value === 3) {
    apiCall = getNurseTechnician;
  }
  axios
    .get(apiCall + "/" + rec.speciality + "/" + staffId)
    .then((res) => {
      console.log("res", res);
      if (res.data.success) {
        console.log(res.data.data);
        dispatch({
          field: "edNurseList",
          value: res.data.data,
        });
        dispatch({ field: "blobURL", value: url });
        dispatch({ field: "requestDescription", value: rec.notes });
        dispatch({ field: "edNurseSpeciality", value: rec.speciality });
        dispatch({ field: "requestStatus", value: rec.status });
        if (value === 1) {
          dispatch({
            field: "edNurse",
            value: rec.edNurseId,
          });
        } else if (value === 2) {
          dispatch({
            field: "edNurse",
            value: rec.eouNurseId,
          });
        } else if (value === 3) {
          dispatch({
            field: "edNurse",
            value: rec.nurseTechnicianId,
          });
        }

        setOpenDialog(true);
      }
    })
    .catch((e) => {
      console.log("error while searching req", e);
    });
}

export function editEDNurseCall(value, data, setOpenDialog, props, dispatch) {
  let apiCall;
  if (value === 1) {
    apiCall = updateEDNurseRequest;
  } else if (value === 2) {
    apiCall = updateEOUNurseRequest;
  } else if (value === 3) {
    apiCall = updateNurseTechnicianRequest;
  }
  axios
    .put(apiCall, data)
    .then((response) => {
      console.log("Hello", response);
      setOpenDialog(false);
      props.setSelectedPatientForReducer(response.data.data);
      dispatch({ field: "edNurseSpeciality", value: "" });
      dispatch({ field: "edNurse", value: "" });
      dispatch({ field: "requestDescription", value: "" });
      // props.history.goBack();
    })
    .catch((error) => {
      console.log("Error");
    });
}

export function saveAnesthesiologistCall(
  setOpenAddAnesthesiologistNoteDialog,
  data,
  props,
  dispatch
) {
  axios
    .put(addAnesthesiologistNote, data)
    .then((response) => {
      console.log("Hello", response);
      setOpenAddAnesthesiologistNoteDialog(false);
      // props.history.goBack();
      props.setSelectedPatientForReducer(response.data.data);
      dispatch({ field: "anesthesiologistNotes", value: "" });
      dispatch({ field: "anesthesiologist", value: "" });
      dispatch({ field: "requestDescription", value: "" });
      dispatch({ field: "section", value: "" });
      dispatch({ field: "blobURL", value: "" });
    })
    .catch((error) => {
      console.log("Error");
    });
}

export function getSelectedAnesthesiologistCall(
  rec,
  url,
  dispatch,
  setOpenDialog
) {
  axios
    .get(getAnesthesiologist)
    .then((res) => {
      console.log("res", res);
      if (res.data.success) {
        console.log(res.data.data);
        dispatch({
          field: "anesthesiologistList",
          value: res.data.data,
        });
        dispatch({ field: "blobURL", value: url });
        dispatch({ field: "requestDescription", value: rec.notes });
        dispatch({ field: "anesthesiologist", value: rec.anesthesiologist });

        setOpenDialog(true);
      }
    })
    .catch((e) => {
      console.log("error while searching req", e);
    });
}

export function editAnesthesiologistCall(
  setOpenAddAnesthesiologistNoteDialog,
  data,
  props,
  dispatch
) {
  axios
    .put(updateAnesthesiologistNote, data)
    .then((response) => {
      console.log("Hello", response);
      setOpenAddAnesthesiologistNoteDialog(false);
      // props.history.goBack();
      props.setSelectedPatientForReducer(response.data.data);
      dispatch({ field: "anesthesiologistNotes", value: "" });
      dispatch({ field: "anesthesiologist", value: "" });
      dispatch({ field: "requestDescription", value: "" });
      dispatch({ field: "section", value: "" });
      dispatch({ field: "blobURL", value: "" });
    })
    .catch((error) => {
      console.log("Error");
    });
}

export function saveConsultationNotesCall(
  setOpenAddConsultDialog,
  data,
  props,
  dispatch
) {
  axios
    .put(addConsultationNotes, data)
    .then((response) => {
      console.log("response after adding consultation note", response);
      setOpenAddConsultDialog(false);
      props.setSelectedPatientForReducer(response.data.data);
      // dispatch({ field: "consultantNotes", value: "" });
      dispatch({ field: "fileAudioForConsultant", value: "" });
      dispatch({ field: "blobURL", value: "" });
      dispatch({ field: "requestDescription", value: "" });
      dispatch({ field: "consultationSpeciality", value: "" });
      dispatch({ field: "consultant", value: "" });
      dispatch({ field: "consultationType", value: "" });

      // props.setSelectedPatientForReducer(response.data.data);
      // props.history.goBack();
    })
    .catch((error) => {
      console.log("Error");
    });
}

export function getSelectedConsultationNoteCall(
  rec,
  url,
  consultantVoiceNotes,
  dispatch,
  setOpenAddConsultDialog,
  staffId
) {
  axios
    .get(getSpecialityDoctor + "/" + rec.speciality + "/" + staffId)
    .then((res) => {
      console.log("res", res);
      if (res.data.success) {
        console.log(res.data.data);
        dispatch({
          field: "consultantArray",
          value: res.data.data,
        });
        dispatch({ field: "blobURL", value: url });
        dispatch({
          field: "consultantVoiceNotes",
          value: consultantVoiceNotes,
        });
        dispatch({ field: "consultantNotes", value: rec.consultantNotes });
        dispatch({ field: "requestDescription", value: rec.notes });
        dispatch({ field: "consultationType", value: rec.consultationType });
        dispatch({ field: "consultationSpeciality", value: rec.speciality });
        dispatch({ field: "consultant", value: rec.consultant });

        setOpenAddConsultDialog(true);
      }
    })
    .catch((e) => {
      console.log("error while searching req", e);
    });
}

export function editConsultationNotesCall(
  setOpenAddConsultDialog,
  data,
  props
) {
  axios
    .put(updateConsultationNote, data)
    .then((response) => {
      console.log("Hello", response);
      setOpenAddConsultDialog(false);
      props.history.goBack();
    })
    .catch((error) => {
      console.log("Error");
    });
}

export function completeConsultationNote(setOpenAddConsultDialog, data, props) {
  axios
    .put(completeConsultationNotesURL, data)
    .then((response) => {
      console.log("res after completion of consultation note", response);
      setOpenAddConsultDialog(false);
      props.history.goBack();
    })
    .catch((error) => {
      console.log("Error");
    });
}
