import React from "react";
import "./Header.css";
import KHMC_White from "../../assets/img/bird.png";
// import KHMC_White from "../../assets/img/KHMC Header LOGO.png";
import Influence_white from "../../assets/img/bird.png";
import ExclaimatorySign from "../../assets/img/Exclamatory Sign.png";
import { Redirect } from "react-router-dom";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Fade from "@material-ui/core/Fade";
import cookie from "react-cookies";
import Fab from "@material-ui/core/Fab";
import Button from "@material-ui/core/Button";
import NotifyMe from "./NotificationTray";
import {
  notificationCount,
  socketUrl,
  getNotifications,
  recordLogout,
  authLogout,
} from "../../public/endpoins";
import socketIOClient from "socket.io-client";
import axios from "axios";
import IdleTimer from "react-idle-timer";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.idleTimer = null;
    this.handleOnIdle = this.handleOnIdle.bind(this);
    this.handleCodeBlueTeam = this.handleCodeBlueTeam.bind(this);

    this.state = {
      goBack: false,
      open: false,
      currentUser: "",
      data: [],
    };
  }

  componentDidMount() {
    const loggedUser = cookie.load("current_user");
    console.log("componet ddi mount called", this.props);
    if (loggedUser) {
      this.setState({ currentUser: loggedUser });

      const socket = socketIOClient(socketUrl);
      socket.emit("get_count", loggedUser._id);
      socket.on("count", (data) => {
        if (data.user === loggedUser._id) {
          console.log("response coming through socket for count", data);
          this.setState({ data: data.count });
        }
      });
    }
  }

  handleOnIdle(event) {
    this.logoutUser();
  }

  handleCodeBlueTeam() {
    this.props.history.push({
      pathname: "/dashboard/home/codeblue",
      // state: { comingFor: "add" },
    });
  }

  recordLogout() {
    const loggedUser = cookie.load("current_user");
    const token = cookie.load("token");

    const params = {
      token: token,
      userId: loggedUser._id,
    };
    axios
      .post(authLogout, params)
      .then((res) => {
        if (res.data.success) {
          // console.log("response after recording the logout", res.data);
        }
      })
      .catch((e) => {
        console.log("error is ", e);
      });
  }

  logoutUser() {
    this.recordLogout();
    cookie.remove("token", { path: "/" });
    cookie.remove("current_user", { path: "/" });
    cookie.remove("user_staff", { path: "/" });
    window.location.reload();
  }

  render() {
    const { history } = this.props;

    if (this.state.goBack) {
      return <Redirect to="/dashboard" />;
    }

    return (
      <div className="header">
        <img
          style={{ maxWidth: 150, height: 90, marginTop: -18 }}
          src={KHMC_White}
          className="header1-style mr-auto p-2"
          onClick={() => {
            console.log(" PATH", this.props.history.location.pathname);
            if (history.location.pathname !== "/dashboard")
              this.setState({ goBack: true });
          }}
          alt="Alternate"
        />
        {/* {this.state.currentUser 
        &&  this.props.history.location.pathname === '/dashboard/home/notification' ? (undefined) : this.state.currentUser &&  this.props.history.location.pathname === '/dashboard/notification' ? (undefined) :(
          // && this.props.history.location !== '/dashboard/notification'
          <NotifyMe
            data={this.state.data}
            onNotificationIconClick={() =>
              history.push({
                pathname: '/dashboard/notification',
                state: {
                  notificationData: this.state.data,
                },
              })
            }
            storageKey='notific_key'
            notific_key='timestamp'
            sortedByKey={false}
            showDate={true}
            color='white'
          />
        ) } */}

        {this.state.currentUser &&
        this.state.currentUser.staffType !== "Paramedics" ? (
          <NotifyMe
            data={this.state.data}
            onNotificationIconClick={() =>
              history.push({
                pathname: "/dashboard/notification",
                state: {
                  notificationData: this.state.data,
                },
              })
            }
            storageKey="notific_key"
            notific_key="timestamp"
            sortedByKey={false}
            showDate={true}
            color="white"
          />
        ) : (
          undefined
        )}

        {!this.state.currentUser ? (
          <img src={Influence_white} className="header2-style" />
        ) : (
          <img
            style={{ maxWidth: 160, height: 37, cursor: "pointer" }}
            src={Influence_white}
            className="header2-style"
            onClick={() => this.setState({ open: !this.state.open })}
          />
        )}

        {this.state.open ? (
          <div className="userDetailCard">
            <Fade in={this.state.open} timeout={1000}>
              <Card>
                <CardContent>
                  <Typography
                    color="textPrimary"
                    gutterBottom
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    {this.state.currentUser &&
                      this.state.currentUser.name[0].given[0] +
                        " " +
                        this.state.currentUser.name[0].family}
                  </Typography>
                  <hr />
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    {this.state.currentUser && this.state.currentUser.staffType}
                    {this.state.currentUser.subType.length > 0
                      ? " (" + this.state.currentUser.subType.toString() + ")"
                      : undefined}
                  </Typography>

                  <Typography
                    color="textSecondary"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      fontSize: 13,
                    }}
                  >
                    {this.state.currentUser && this.state.currentUser.email}
                  </Typography>
                </CardContent>
              </Card>
            </Fade>
          </div>
        ) : (
          undefined
        )}

        {this.state.currentUser ? (
          <div style={{ position: "fixed", right: 50, bottom: 10, zIndex: 10 }}>
            {this.state.currentUser.staffType === "Sensei" ||
            this.state.currentUser.staffType === "Doctor" ||
            this.state.currentUser.staffType === "Nurses" ? (
              <img
                src={ExclaimatorySign}
                onClick={() => this.handleCodeBlueTeam()}
                style={{
                  height: 50,
                  width: 50,
                  cursor: "pointer",
                }}
              />
            ) : (
              undefined
            )}
            <Fab
              aria-label="add"
              onClick={() => this.logoutUser()}
              style={{
                backgroundColor: "#ba02ed",
                outline: "none",
                width: 39,
                height: 40,
              }}
            >
              <i
                className="zmdi zmdi-power zmdi-hc-2x"
                style={{ color: "white" }}
              ></i>
            </Fab>
          </div>
        ) : (
          undefined
        )}

        {this.state.currentUser ? (
          <IdleTimer
            ref={(ref) => {
              this.idleTimer = ref;
            }}
            timeout={1000 * 60 * 100}
            onIdle={this.handleOnIdle}
            debounce={250}
          />
        ) : (
          undefined
        )}
      </div>
    );
  }
}
export default Header;
