/*eslint-disable*/
import React, { useState, useEffect, useReducer } from "react";
import axios from "axios";
import Button from "@material-ui/core/Button";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Notification from "../../components/Snackbar/Notification.js";
import CustomTable from "../../components/Table/Table";
import TextField from "@material-ui/core/TextField";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Loader from "react-loader-spinner";
import AccountCircle from "@material-ui/icons/SearchOutlined";
import InputAdornment from "@material-ui/core/InputAdornment";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import MenuItem from "@material-ui/core/MenuItem";
import Fingerprint from "../../assets/img/fingerprint.png";
import BarCode from "../../assets/img/Bar Code.png";
import Header from "../../components/Header/Header";
import FlagManagementIcon from "../../assets/img/Housekeeping.png";
import eye_icon from "../../assets/img/Eye.png";
import Back from "../../assets/img/Back_Arrow.png";
import "../../assets/jss/material-dashboard-react/components/loaderStyle.css";
import cookie from "react-cookies";
import { styles, useStyles, useStylesForTabs } from "./styles.js";
import {
  getAdmittedCall,
  getDischargedCall,
  getTransferredCall,
  getPsychiatristCall,
  getMentalCareCall,
  getAdvocateCall,
} from "./networkCallsTasks";
import { sendEmail } from "../../public/endpoins";

const tableHeadingsForAdmitted = ["Name", "Phone Number", "Email", "Action"];
const tableDataKeysForAdmitted = ["name", "phone", "email"];

const tableHeadingsForDischarged = ["Name", "Phone Number", "Email", "Action"];
const tableDataKeysForDischarged = ["name", "phone", "email"];

const tableHeadingsForTransferred = ["Name", "Phone Number", "Email", "Action"];
const tableDataKeysForTransferred = ["name", "phone", "email"];

const actions = { assign: true };

export default function DischargeSubTasks(props) {
  const classesForTabs = useStylesForTabs();
  const classes = useStyles();
  const matches = useMediaQuery("(min-width:600px)");
  const [currentUser, setCurrentUser] = useState(cookie.load("current_user"));
  const [psychiatristArray, setPsychiatristArray] = useState([]);
  const [mentalCareArray, setMentalCareArray] = useState([]);
  const [advocateArray, setAdvocateArray] = useState([]);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [openNotification, setOpenNotification] = useState(false);
  const [tabName, setTabName] = useState("psychiatrist");
  const [buttonValue, setButtonValue] = useState("pending");
  const [cookies, setCookies] = useState(cookie.load("current_user"));
  const [selectedRecord, setSelectedRecord] = useState("");
  const [reason, setReason] = useState("");

  if (openNotification) {
    setTimeout(() => {
      setOpenNotification(false);
      setErrorMsg("");
    }, 2000);
  }

  useEffect(() => {
    console.log("props", props);

    getPsychiatristCall(setPsychiatristArray, setErrorMsg, setOpenNotification);
  }, []);

  const handleTabChange = (e, tabName) => {
    console.log("tabName", tabName);
    setTabName(tabName);
    if (tabName === "psychiatrist") {
      getPsychiatristCall(
        setPsychiatristArray,
        setErrorMsg,
        setOpenNotification
      );
    } else if (tabName === "mentalCare") {
      getMentalCareCall(setMentalCareArray, setErrorMsg, setOpenNotification);
    } else if (tabName === "advocate") {
      getAdvocateCall(setAdvocateArray, setErrorMsg, setOpenNotification);
    }
  };

  const handleOpen = (rec) => {
    setOpen(true);
    setSelectedRecord(rec);
  };

  const onChangeValue = (e) => {
    setReason(e.target.value);
  };

  const handleClose = () => {
    setReason("");
    setOpen(false);
  };

  console.log("psychiatristArray", psychiatristArray);

  const handleAssign = () => {
    let staffEmail;
    currentUser.telecom.map((d) => {
      if (d.system === "email") {
        return (staffEmail = d.value);
      }
    });

    console.log("staffEmail", staffEmail);
    console.log("currentUser", currentUser);

    const params = {
      sender: staffEmail,
      receiver: selectedRecord.email,
      subject: reason,
      requestedTo: selectedRecord._id,
      edrId: props.location.state.selectedItem._id,
      requestedBy: currentUser._id,
      requiredAssistance: tabName,
      body: {
        mrn: props.location.state.selectedItem.mrn,
        productionArea: props.location.state.selectedItem.productionArea,
        chiefComplaint:
          props.location.state.selectedItem.chiefComplaint[
            props.location.state.selectedItem.chiefComplaint.length - 1
          ].chiefComplaintId.chiefComplaintId,
      },
    };

    console.log("params", params);

    // axios
    //   .put(sendEmail, params)
    //   .then((res) => {
    //     if (res.data.success) {
    //       console.log(res.data.data, 'response')
    //       props.history.push({
    //         pathname: '/dashboard/home/success',
    //         state: {
    //           message: res.data.data,
    //         },
    //       })
    //     }
    //   })
    //   .catch((e) => {
    //     console.log('error ', e)
    //     setOpenNotification(true)
    //     setErrorMsg('Error')
    //   })
  };

  const Cleared = () => {
    props.history.push({
      pathname: "/dashboard/home/success",
      state: {
        message1: "Cleared without any help",
      },
    });
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        position: "fixed",
        width: "100%",
        height: "100%",
        backgroundColor: "#60d69f",
        overflowY: "scroll",
      }}
    >
      <Header history={props.history} />

      <div className="cPadding">
        <div className="subheader">
          <div>
            <img src={FlagManagementIcon} style={{ marginLeft: "0px" }} />
            <h4>
              {tabName === "psychiatrist"
                ? "Psychiatrist"
                : tabName === "mentalCare"
                ? "MentalCare"
                : "Advocate"}
            </h4>
          </div>
        </div>

        <div
          className="container-fluid"
          style={{ paddingLeft: 0, paddingRight: 0 }}
        >
          <div className={classesForTabs.root}>
            <Tabs
              classes={{
                root: classesForTabs.root,
                scroller: classesForTabs.scroller,
              }}
              value={tabName}
              onChange={handleTabChange}
              textColor="primary"
              TabIndicatorProps={{ style: { background: "#12387a" } }}
              centered
            >
              <Tab
                style={{
                  color: "white",
                  borderRadius: 5,
                  outline: "none",
                  color: tabName === "psychiatrist" ? "#12387a" : "#3B988C",
                }}
                label="Psychiatrist"
                value="psychiatrist"
              />
              <Tab
                style={{
                  color: "white",
                  borderRadius: 5,
                  outline: "none",
                  color: tabName === "mentalCare" ? "#12387a" : "#3B988C",
                }}
                label="Mental Care"
                value="mentalCare"
              />
              <Tab
                style={{
                  color: "white",
                  borderRadius: 5,
                  outline: "none",
                  color: tabName === "advocate" ? "#12387a" : "#3B988C",
                }}
                label="Advocate"
                value="advocate"
              />
            </Tabs>
          </div>

          <div className="row">
            {psychiatristArray && tabName === "psychiatrist" ? (
              <div className="col-md-12 col-sm-12 col-12">
                {loading ? (
                  <div style={{ margin: "auto", width: "0%", padding: 10 }}>
                    <Loader
                      type="TailSpin"
                      color="blue"
                      height={50}
                      width={50}
                    />
                  </div>
                ) : (
                  <CustomTable
                    tableData={psychiatristArray}
                    tableDataKeys={tableDataKeysForAdmitted}
                    tableHeading={tableHeadingsForAdmitted}
                    action={actions}
                    handleAssign={handleOpen}
                    borderBottomColor={"#60d69f"}
                    borderBottomWidth={20}
                  />
                )}
              </div>
            ) : mentalCareArray && tabName === "mentalCare" ? (
              <div className="col-md-12 col-sm-12 col-12">
                {loading ? (
                  <div style={{ margin: "auto", width: "0%", padding: 10 }}>
                    <Loader
                      type="TailSpin"
                      color="blue"
                      height={50}
                      width={50}
                    />
                  </div>
                ) : (
                  <CustomTable
                    tableData={mentalCareArray}
                    tableDataKeys={tableDataKeysForDischarged}
                    tableHeading={tableHeadingsForDischarged}
                    action={actions}
                    handleAssign={handleOpen}
                    borderBottomColor={"#60d69f"}
                    borderBottomWidth={20}
                  />
                )}
              </div>
            ) : advocateArray && tabName === "advocate" ? (
              <div className="col-md-12 col-sm-12 col-12">
                {loading ? (
                  <div style={{ margin: "auto", width: "0%", padding: 10 }}>
                    <Loader
                      type="TailSpin"
                      color="blue"
                      height={50}
                      width={50}
                    />
                  </div>
                ) : (
                  <CustomTable
                    tableData={advocateArray}
                    tableDataKeys={tableDataKeysForTransferred}
                    tableHeading={tableHeadingsForTransferred}
                    action={actions}
                    handleAssign={handleOpen}
                    borderBottomColor={"#60d69f"}
                    borderBottomWidth={20}
                  />
                )}
              </div>
            ) : (
              <div className="LoaderStyle">
                <Loader type="TailSpin" color="red" height={50} width={50} />
              </div>
            )}
          </div>

          <div
            // class='row'
            className="container-fluid"
            style={{
              display: "flex",
              flex: 1,
              justifyContent: "center",
              paddingLeft: 6,
              paddingRight: 6,
            }}
          >
            <div
              style={{
                display: "flex",
                flex: 1,
                justifyContent: "flex",
                marginTop: "2%",
                marginBottom: "2%",
                marginLeft: "-8px",
              }}
            >
              <img
                onClick={() => props.history.goBack()}
                src={Back}
                style={{
                  width: 45,
                  height: 35,
                  cursor: "pointer",
                }}
              />
            </div>

            <div
              style={{
                display: "flex",
                flex: 1,
                justifyContent: "flex-end",
                marginTop: "2%",
                marginBottom: "2%",
                marginRight: "-6px",
              }}
            >
              <Button
                onClick={Cleared}
                style={{
                  ...styles.stylesForButton,
                  fontSize: matches ? " " : 10,
                }}
                variant="contained"
                color="primary"
              >
                <strong>Clear without help</strong>
              </Button>
            </div>
          </div>

          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth={"md"}
            fullWidth={true}
          >
            <DialogContent>
              <div
                className={`row ${classes.root}`}
                style={{
                  marginTop: "15px",
                  marginRight: "-20px",
                  marginLeft: "-20px",
                }}
              >
                <div
                  className="col-md-12 col-sm-12"
                  style={{
                    ...styles.inputContainerForTextField,
                    ...styles.textFieldPadding,
                    marginTop: "20px",
                    paddingLeft: 20,
                    paddingRight: 20,
                  }}
                >
                  <TextField
                    required
                    fullWidth
                    name="reason"
                    label="Reason"
                    value={reason}
                    onChange={onChangeValue}
                    // error={reason === '' && detailsForm}
                    variant="filled"
                    className="textInputStyle"
                    InputProps={{
                      className: classes.input,
                      classes: { input: classes.input },
                    }}
                  />
                </div>
              </div>
            </DialogContent>
            <DialogActions style={{ justifyContent: "center" }}>
              <Button
                onClick={handleClose}
                color="primary"
                style={styles.stylesForButton}
              >
                Cancel
              </Button>
              <Button
                onClick={handleAssign}
                color="primary"
                style={styles.stylesSubmitButton}
              >
                Assign
              </Button>
            </DialogActions>
          </Dialog>
        </div>

        <Notification msg={errorMsg} open={openNotification} />
      </div>
    </div>
  );
}
