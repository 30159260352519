import axios from "axios";
import {
  getPendingRRRSample,
  getCompletedRRRSample,
  updateNurseLabSample,
  getCompletedRadHKRequests,
  getPendingSenseiHKRequests,
  updateRadHKRequestStatus,
  updateSenseiStatus,
  addNurseReporturl,
} from "../../public/endpoins";
import FormatDate from "../../utils/formatDate";

export const getPendingRRRCall = (
  currentNurseId,
  setPendingImgTechnicianArray,
  setErrorMsg,
  setOpenNotification
) => {
  axios
    .get(getPendingRRRSample + "/" + currentNurseId)
    .then((response) => {
      console.log("Response Pending Lab", response.data.data);
      response.data.data.map((val) => {
          val.patientName = val.patientId.name[0].given[0] + " " + val.patientId.name[0].family;
          val.roomNo = val.room[0].roomId.roomNo;
          val.status = val.labRequest.nurseTechnicianStatus;
          val.labRequestId = val.labRequest.requestId;
          // (val.serviceName = val.labRequest.serviceId.category + ': ' + val.labRequest.serviceId.name;
          val.serviceRequestedBy = val.labRequest.requestedBy.name[0].given[0] + ' ' + val.labRequest.requestedBy.name[0].family;
          val.mrn = val.patientId.identifier[0].value;
          val.episodeNo = val.requestNo;

          // Age
          var today = new Date();
          var birthDate = new Date(val.patientId.birthDate);
          var age_now = today.getFullYear() - birthDate.getFullYear();
          var m = today.getMonth() - birthDate.getMonth();
          if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age_now--;
          }
          val.ageNow = age_now;

          // Services
          let servicesNames = [];
          Object.entries(val.labRequest.services).map(([key, val]) => {
            servicesNames.push(val.name)
          });
          val.serviceName = servicesNames.join(',');


        }
      );
      setPendingImgTechnicianArray(response.data.data);
    })
    .catch((error) => {
      console.log("Error", error);
      setOpenNotification(true);
      setErrorMsg(error);
    });
};

export const getCompletedRRRCall = (
  currentNurseId,
  setCompletedImgTechnicianArray,
  setErrorMsg,
  setOpenNotification
) => {
  axios
    .get(getCompletedRRRSample + "/" + currentNurseId)
    .then((response) => {
      console.log("Response Completed", response.data);
      response.data.data.map((val) => {
          val.patientName = val.patientId.name[0].given[0] + " " + val.patientId.name[0].family;
          val.roomNo = val.room[0].roomId.roomNo;
          val.status = val.labRequest.nurseTechnicianStatus;
          val.labRequestId = val.labRequest.requestId;
          // (val.serviceName = val.labRequest.serviceId.category + ': ' + val.labRequest.serviceId.name;
          val.serviceRequestedBy = val.labRequest.requestedBy.name[0].given[0] + ' ' + val.labRequest.requestedBy.name[0].family;
          val.mrn = val.patientId.identifier[0].value;
          val.episodeNo = val.requestNo;

          // Age
          var today = new Date();
          var birthDate = new Date(val.patientId.birthDate);
          var age_now = today.getFullYear() - birthDate.getFullYear();
          var m = today.getMonth() - birthDate.getMonth();
          if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age_now--;
          }
          val.ageNow = age_now;

          // Services
          let servicesNames = [];
          Object.entries(val.labRequest.services).map(([key, val]) => {
            servicesNames.push(val.name)
          });
          val.serviceName = servicesNames.join(',');


        }
      );
      setCompletedImgTechnicianArray(response.data.data);
    })
    .catch((error) => {
      console.log("Error", error);
      setOpenNotification(true);
      setErrorMsg(error);
    });
};

export function updateStatus(props, params, setOpenNotification, setErrorMsg) {
  console.log("propss", props);
  axios
    .put(updateNurseLabSample, params)
    .then((res) => {
      if (res.data.success) {
        console.log("response after", res.data);
        // res.data.data.map(
        //   (val) => (val.mrn = val.patientId.identifier[0].value)
        // );

        // res.data.data.map((val.mrn = val.patientId.identifier[0].value));
        props.history.push({
          pathname: "success",
          state: {
            reqCode: `${
              res.data.data.labRequest[res.data.data.labRequest.length - 1]
                .requestId
            } `,
            message3: `Lab Sample collected successfully for Patient <br />`,
            // code: `MRN: ${res.data.data.patientId.identifier[0].value}`,
            code: `MRN: ${res.data.data.requestNo}`,
          },
        });
      } else if (!res.data.success) {
        setOpenNotification(true);
        setErrorMsg("Error while updating the status");
      }
    })
    .catch((e) => {
      setOpenNotification(true);
      setErrorMsg("Error while updating the status");
    });
}

export function addNurseReport(
  props,
  params,
  setOpenNotification,
  setErrorMsg
) {
  console.log("propss", props);
  axios
    .put(addNurseReporturl, params)
    .then((res) => {
      if (res.data.success) {
        console.log("response after", res.data);
        props.history.push({
          pathname: "success",
          state: {
            reqCode: `${res.data.data.requestNo}`,
            message3: `Patient vital saved successfully for Patient `,
            code: `MRN: ${res.data.data.mrn}`,
          },
        });
      } else if (!res.data.success) {
        setOpenNotification(true);
        setErrorMsg("Error while updating the status");
      }
    })
    .catch((e) => {
      setOpenNotification(true);
      setErrorMsg("Error while updating the status");
    });
}
