import axios from "axios";
import { colorForDashboard } from "../../../utils/Constants";

export function getResponse(
  endpoint,
  setResponseData,
  setOpenNotification,
  setErrorMsg,
  setisLoading
) {
  axios
    .get(endpoint)
    .then((res) => {
      if (res.data.success) {
        console.log("res.data: ", res.data);
        setResponseData(res.data);
        setisLoading(false);
      } else if (!res.data.success) {
        setErrorMsg(res.data.error);
        setOpenNotification(true);
      }
      return res;
    })
    .catch((e) => {
      console.log("error: ", e);
    });
}

export function getDialerGraphCall(
  firstDialerComparisonNumber,
  secondDialerComparisonNumber,
  firstTatComparisonNumber,
  secondTatComparisonNumber,
  cardObj,
  setDialorData,
  setDialorColor,
  setTAT,
  setTATColor,
  setGraphData
) {
  const { TAT, perHour, totalPending } = cardObj;
  setGraphData(perHour);
  if (totalPending >= 0 && totalPending <= firstDialerComparisonNumber) {
    setDialorColor(colorForDashboard.first);
  } else if (
    totalPending >= firstDialerComparisonNumber + 1 &&
    totalPending <= secondDialerComparisonNumber
  ) {
    setDialorColor(colorForDashboard.second);
  } else if (totalPending >= secondDialerComparisonNumber + 1) {
    setDialorColor(colorForDashboard.third);
  }

  console.log("****");
  if (TAT >= 0 && TAT <= firstTatComparisonNumber) {
    setTATColor(colorForDashboard.first);
  } else if (
    TAT >= firstTatComparisonNumber + 1 &&
    TAT <= secondTatComparisonNumber
  ) {
    setTATColor(colorForDashboard.second);
  } else if (TAT >= secondTatComparisonNumber + 1) {
    setTATColor(colorForDashboard.third);
  }

  setDialorData(totalPending);
  setTAT(TAT);
}

export function getValuesCardCalls(
  firstTatComparisonNumber,
  secondTatComparisonNumber,
  data,
  valueCard,
  valueCardColor
) {
  console.log("response after fetch**", data);
  valueCard(data);
  if (data >= 0 && data <= firstTatComparisonNumber) {
    valueCardColor(colorForDashboard.first);
  } else if (
    data >= firstTatComparisonNumber + 1 &&
    data <= secondTatComparisonNumber
  ) {
    valueCardColor(colorForDashboard.second);
  } else if (data >= secondTatComparisonNumber + 1) {
    valueCardColor(colorForDashboard.third);
  }
}

// export function getReportsLabTech(
//   set1stTotalPending,
//   set1stTotalPendingColor,
//   set1stPendingTat,
//   set1stPendingTATColor,
//   set1stGraphPending,
//   set2ndTotalPending,
//   set2ndTotalPendingColor,
//   set2ndPendingTat,
//   set2ndPendingTATColor,
//   set2ndGraphPending,
//   set3rdTotalPending,
//   set3rdTotalPendingColor,
//   set3rdPendingTat,
//   set3rdPendingTATColor,
//   set3rdGraphPending,

//   set4thTotalPending,
//   set4thTotalPendingColor,
//   set4thPendingTat,
//   set4thPendingTATColor,
//   set4thGraphPending,

//   set5thTotalPending,
//   set5thTotalPendingColor,
//   set5thPendingTat,
//   set5thPendingTATColor,
//   set5thGraphPending,

//   set6thTotalPending,
//   set6thTotalPendingColor,
//   set6thPendingTat,
//   set6thPendingTATColor,
//   set6thGraphPending,

//   setCumulativeTests,
//   setCumulativeTestsColor,

//   setCumulativeBloodTest,
//   setCumulativeBloodTestColor
// ) {
//   axios.get(reportsLTDashboard).then((res) => {
//     if (res.data.success) {
//       console.log("Lab technician Dashboard Data", res.data);

//       //1st Card

//       if (
//         res.data.firstCard.totalPending >= 0 &&
//         res.data.firstCard.totalPending <= 3
//       ) {
//         set1stTotalPending(res.data.firstCard.totalPending);
//         set1stTotalPendingColor("#13D59F");
//       } else if (
//         res.data.firstCard.totalPending >= 4 &&
//         res.data.firstCard.totalPending <= 6
//       ) {
//         set1stTotalPending(res.data.firstCard.totalPending);
//         set1stTotalPendingColor("#FFBC28");
//       } else if (res.data.firstCard.totalPending >= 7) {
//         set1stTotalPending(res.data.firstCard.totalPending);
//         set1stTotalPendingColor("#FF0000");
//       } else {
//         set1stTotalPending(res.data.firstCard.totalPending);
//       }
//       //setTotalPending(res.data.firstCard.totalPending)
//       //setTotalPendingColor("#FF0000")
//       if (res.data.firstCard.TAT >= 0 && res.data.firstCard.TAT <= 5) {
//         set1stPendingTat(res.data.firstCard.TAT);
//         set1stPendingTATColor("#13D59F");
//       } else if (res.data.firstCard.TAT >= 6 && res.data.firstCard.TAT <= 10) {
//         set1stPendingTat(res.data.firstCard.TAT);
//         set1stPendingTATColor("#FFBC28");
//       } else if (res.data.firstCard.TAT >= 11) {
//         set1stPendingTat(res.data.firstCard.TAT);
//         set1stPendingTATColor("#FF0000");
//       } else {
//         set1stPendingTat(res.data.firstCard.TAT);
//       }
//       // //setPendingTat(res.data.firstCard.TAT);
//       // //setPendingTATColor()
//       set1stGraphPending(res.data.firstCard.perHour);

//       //2nd Card

//       if (
//         res.data.secondCard.totalPending >= 0 &&
//         res.data.secondCard.totalPending <= 2
//       ) {
//         set2ndTotalPending(res.data.secondCard.totalPending);
//         set2ndTotalPendingColor("#13D59F");
//       } else if (
//         res.data.secondCard.totalPending >= 3 &&
//         res.data.secondCard.totalPending <= 5
//       ) {
//         set2ndTotalPending(res.data.secondCard.totalPending);
//         set2ndTotalPendingColor("#FFBC28");
//       } else if (res.data.secondCard.totalPending >= 6) {
//         set2ndTotalPending(res.data.secondCard.totalPending);
//         set2ndTotalPendingColor("#FF0000");
//       } else {
//         set2ndTotalPending(res.data.secondCard.totalPending);
//       }
//       //setTotalPending(res.data.secondCard.totalPending)
//       //setTotalPendingColor("#FF0000")
//       if (res.data.secondCard.TAT >= 0 && res.data.secondCard.TAT <= 10) {
//         set2ndPendingTat(res.data.secondCard.TAT);
//         set2ndPendingTATColor("#13D59F");
//       } else if (
//         res.data.secondCard.TAT >= 11 &&
//         res.data.secondCard.TAT <= 20
//       ) {
//         set2ndPendingTat(res.data.secondCard.TAT);
//         set2ndPendingTATColor("#FFBC28");
//       } else if (res.data.secondCard.TAT >= 21) {
//         set2ndPendingTat(res.data.secondCard.TAT);
//         set2ndPendingTATColor("#FF0000");
//       } else {
//         set2ndPendingTat(res.data.secondCard.TAT);
//       }
//       // //setPendingTat(res.data.secondCard.TAT);
//       // //setPendingTATColor()
//       set2ndGraphPending(res.data.secondCard.perHour);

//       //  For 3rd Card

//       if (
//         res.data.thirdCard.totalPending >= 0 &&
//         res.data.thirdCard.totalPending <= 4
//       ) {
//         set3rdTotalPending(res.data.thirdCard.totalPending);
//         set3rdTotalPendingColor("#13D59F");
//       } else if (
//         res.data.thirdCard.totalPending >= 5 &&
//         res.data.thirdCard.totalPending <= 9
//       ) {
//         set3rdTotalPending(res.data.thirdCard.totalPending);
//         set3rdTotalPendingColor("#FFBC28");
//       } else if (res.data.thirdCard.totalPending >= 10) {
//         set3rdTotalPending(res.data.thirdCard.totalPending);
//         set3rdTotalPendingColor("#FF0000");
//       } else {
//         set3rdTotalPending(res.data.thirdCard.totalPending);
//       }
//       //setTotalPending(res.data.thirdCard.totalPending)
//       //setTotalPendingColor("#FF0000")
//       if (res.data.thirdCard.TAT >= 0 && res.data.thirdCard.TAT <= 30) {
//         set3rdPendingTat(res.data.thirdCard.TAT);
//         set3rdPendingTATColor("#13D59F");
//       } else if (res.data.thirdCard.TAT >= 31 && res.data.thirdCard.TAT <= 45) {
//         set3rdPendingTat(res.data.thirdCard.TAT);
//         set3rdPendingTATColor("#FFBC28");
//       } else if (res.data.thirdCard.TAT >= 46) {
//         set3rdPendingTat(res.data.thirdCard.TAT);
//         set3rdPendingTATColor("#FF0000");
//       } else {
//         set3rdPendingTat(res.data.thirdCard.TAT);
//       }
//       // //setPendingTat(res.data.thirdCard.TAT);
//       // //setPendingTATColor()
//       set3rdGraphPending(res.data.thirdCard.perHour);
//       //  For 4rd Card

//       if (
//         res.data.fourthCard.totalPending >= 0 &&
//         res.data.fourthCard.totalPending <= 4
//       ) {
//         set4thTotalPending(res.data.fourthCard.totalPending);
//         set4thTotalPendingColor("#13D59F");
//       } else if (
//         res.data.fourthCard.totalPending >= 5 &&
//         res.data.fourthCard.totalPending <= 10
//       ) {
//         set4thTotalPending(res.data.fourthCard.totalPending);
//         set4thTotalPendingColor("#FFBC28");
//       } else if (res.data.fourthCard.totalPending >= 11) {
//         set4thTotalPending(res.data.fourthCard.totalPending);
//         set4thTotalPendingColor("#FF0000");
//       } else {
//         set4thTotalPending(res.data.fourthCard.totalPending);
//       }
//       //setTotalPending(res.data.fourthCard.totalPending)
//       //setTotalPendingColor("#FF0000")
//       if (res.data.fourthCard.TAT >= 0 && res.data.fourthCard.TAT <= 35) {
//         set4thPendingTat(res.data.fourthCard.TAT);
//         set4thPendingTATColor("#13D59F");
//       } else if (
//         res.data.fourthCard.TAT >= 36 &&
//         res.data.fourthCard.TAT <= 45
//       ) {
//         set4thPendingTat(res.data.fourthCard.TAT);
//         set4thPendingTATColor("#FFBC28");
//       } else if (res.data.fourthCard.TAT >= 46) {
//         set4thPendingTat(res.data.fourthCard.TAT);
//         set4thPendingTATColor("#FF0000");
//       } else {
//         set4thPendingTat(res.data.fourthCard.TAT);
//       }
//       // //setPendingTat(res.data.fourthCard.TAT);
//       // //setPendingTATColor()
//       set4thGraphPending(res.data.fourthCard.perHour);
//       //  For 5th Card

//       if (
//         res.data.fifthCard.totalPending >= 0 &&
//         res.data.fifthCard.totalPending <= 9
//       ) {
//         set5thTotalPending(res.data.fifthCard.totalPending);
//         set5thTotalPendingColor("#13D59F");
//       } else if (
//         res.data.fifthCard.totalPending >= 10 &&
//         res.data.fifthCard.totalPending <= 14
//       ) {
//         set5thTotalPending(res.data.fifthCard.totalPending);
//         set5thTotalPendingColor("#FFBC28");
//       } else if (res.data.fifthCard.totalPending >= 15) {
//         set5thTotalPending(res.data.fifthCard.totalPending);
//         set5thTotalPendingColor("#FF0000");
//       } else {
//         set5thTotalPending(res.data.fifthCard.totalPending);
//       }
//       //setTotalPending(res.data.fifthCard.totalPending)
//       //setTotalPendingColor("#FF0000")
//       if (res.data.fifthCard.TAT >= 0 && res.data.fifthCard.TAT <= 4) {
//         set5thPendingTat(res.data.fifthCard.TAT);
//         set5thPendingTATColor("#FF0000");
//       } else if (res.data.fifthCard.TAT >= 5 && res.data.fifthCard.TAT <= 7) {
//         set5thPendingTat(res.data.fifthCard.TAT);
//         set5thPendingTATColor("#FF0000");
//       } else if (res.data.fifthCard.TAT >= 8) {
//         set5thPendingTat(res.data.fifthCard.TAT);
//         set5thPendingTATColor("#FF0000");
//       } else {
//         set5thPendingTat(res.data.fifthCard.TAT);
//       }
//       // //setPendingTat(res.data.fifthCard.TAT);
//       // //setPendingTATColor()
//       set5thGraphPending(res.data.fifthCard.perHour);

//       //  For 6th Card

//       if (
//         res.data.sixthCard.totalPending >= 0 &&
//         res.data.sixthCard.totalPending <= 30
//       ) {
//         set6thTotalPending(res.data.sixthCard.totalPending);
//         set6thTotalPendingColor("#13D59F");
//       } else if (
//         res.data.sixthCard.totalPending >= 31 &&
//         res.data.sixthCard.totalPending <= 50
//       ) {
//         set6thTotalPending(res.data.sixthCard.totalPending);
//         set6thTotalPendingColor("#FFBC28");
//       } else if (res.data.sixthCard.totalPending >= 51) {
//         set6thTotalPending(res.data.sixthCard.totalPending);
//         set6thTotalPendingColor("#FF0000");
//       } else {
//         set6thTotalPending(res.data.sixthCard.totalPending);
//       }
//       //setTotalPending(res.data.sixthCard.totalPending)
//       //setTotalPendingColor("#FF0000")
//       if (res.data.sixthCard.TAT >= 0 && res.data.sixthCard.TAT <= 4) {
//         set6thPendingTat(res.data.sixthCard.TAT);
//         set6thPendingTATColor("#FF0000");
//       } else if (res.data.sixthCard.TAT >= 5 && res.data.sixthCard.TAT <= 7) {
//         set6thPendingTat(res.data.sixthCard.TAT);
//         set6thPendingTATColor("#FF0000");
//       } else if (res.data.sixthCard.TAT >= 8) {
//         set6thPendingTat(res.data.sixthCard.TAT);
//         set6thPendingTATColor("#FF0000");
//       } else {
//         set6thPendingTat(res.data.sixthCard.TAT);
//       }
//       // //setPendingTat(res.data.thirdCard.TAT);
//       // //setPendingTATColor()
//       set6thGraphPending(res.data.sixthCard.perHour);

//       // // // For 7th Card

//       if (res.data.cumulativeTests >= 0 && res.data.cumulativeTests <= 15) {
//         setCumulativeTests(res.data.cumulativeTests);
//         setCumulativeTestsColor("#13D59F");
//       } else if (
//         res.data.cumulativeTests >= 15 &&
//         res.data.cumulativeTests <= 20
//       ) {
//         setCumulativeTests(res.data.cumulativeTests);
//         setCumulativeTestsColor("#FFBC28");
//       } else if (res.data.cumulativeTests >= 20) {
//         setCumulativeTests(res.data.setcumulativeTests);
//         setCumulativeTestsColor("#FF0000");
//       } else {
//         setCumulativeTests(res.data.cumulativeTests);
//       }
//       // For 8th Card

//       if (
//         res.data.cumulativeBloodTest >= 0 &&
//         res.data.cumulativeBloodTest <= 25
//       ) {
//         setCumulativeBloodTest(res.data.cumulativeBloodTest);
//         setCumulativeBloodTestColor("#13D59F");
//       } else if (
//         res.data.cumulativeBloodTest >= 25 &&
//         res.data.cumulativeBloodTest <= 30
//       ) {
//         setCumulativeBloodTest(res.data.cumulativeBloodTest);
//         setCumulativeBloodTestColor("#13D59F");
//       } else if (res.data.cumulativeBloodTest >= 30) {
//         setCumulativeBloodTest(res.data.setcumulativeBloodTest);
//         setCumulativeBloodTestColor("#13D59F");
//       } else {
//         setCumulativeBloodTest(res.data.cumulativeBloodTest);
//       }
//     }
//   });
// }
