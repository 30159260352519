import axios from "axios";
import {
  pendingDoctorNotes,
  inprogressDoctorNotes,
  completedDoctorNotes,
} from "../../public/endpoins";
import formatDate from "../../utils/formatDateTime";

export const getPendingSenseiCall = (
  setPendingSenseiArray,
  setErrorMsg,
  setOpenNotification
) => {
  axios
    .get(pendingDoctorNotes)
    .then((response) => {
      console.log("Response Pending", response.data);
      response.data.data.map(
        (d) => (
          (d.patientName =
            d.patientId.name[0].given[0] + " " + d.patientId.name[0].family),
          (d.dateTime = formatDate(d.createdTimeStamp))
        )
      );
      response.data.data.map((d) => (d.mrn = d.patientId.identifier[0].value));
      response.data.data.map(
        (d) =>
          (d.careStreamName = d.careStream[d.careStream.length - 1]
            ? d.careStream[d.careStream.length - 1].name
            : "N/A")
      );

      response.data.data.map(
        (val) =>
          (val.productionArea =
            val.chiefComplaint[val.chiefComplaint.length - 1] &&
            val.chiefComplaint[val.chiefComplaint.length - 1].chiefComplaintId  &&val.chiefComplaint[val.chiefComplaint.length - 1].chiefComplaintId.productionArea.length > 0 
              ? val.chiefComplaint[val.chiefComplaint.length - 1]
                  .chiefComplaintId.productionArea[
                  val.chiefComplaint[val.chiefComplaint.length - 1]
                    .chiefComplaintId.productionArea.length - 1
                ].productionAreaId.paName
              : "N/A")
      );
      console.log("Response Pending", response.data.data);
      setPendingSenseiArray(response.data.data);
    })
    .catch((error) => {
      console.log("Error", error);
      setOpenNotification(true);
      setErrorMsg(error);
    });
};

export const getPendingImgTechnicianCall = (
  setPendingImgTechnicianArray,
  setErrorMsg,
  setOpenNotification
) => {
  axios
    .get(completedDoctorNotes)
    .then((response) => {
      console.log("Response Pending", response.data.data);
      response.data.data.map(
        (d) => (
          (d.patientName =
            d.patientId.name[0].given[0] + " " + d.patientId.name[0].family),
          (d.dateTime = formatDate(d.createdTimeStamp))
        )
      );
      response.data.data.map((d) => (d.mrn = d.patientId.identifier[0].value));
      response.data.data.map(
        (d) =>
          (d.careStreamName = d.careStream[d.careStream.length - 1]
            ? d.careStream[d.careStream.length - 1].name
            : "N/A")
      );
      response.data.data.map(
        (val) =>
          (val.productionArea =
            val.chiefComplaint[val.chiefComplaint.length - 1] &&
            val.chiefComplaint[val.chiefComplaint.length - 1].chiefComplaintId
              ? val.chiefComplaint[val.chiefComplaint.length - 1]
                  .chiefComplaintId.productionArea[
                  val.chiefComplaint[val.chiefComplaint.length - 1]
                    .chiefComplaintId.productionArea.length - 1
                ].productionAreaId.paName
              : "N/A")
      );
      setPendingImgTechnicianArray(response.data.data);
    })
    .catch((error) => {
      console.log("Error", error);
      setOpenNotification(true);
      setErrorMsg(error);
    });
};

export const getEDNurseCall = (
  setEdNurseArray,
  setErrorMsg,
  setOpenNotification
) => {
  axios
    .get(inprogressDoctorNotes)
    .then((response) => {
      console.log("Response Completed", response.data);
      response.data.data.map(
        (d) => (
          (d.patientName =
            d.patientId.name[0].given[0] + " " + d.patientId.name[0].family),
          (d.dateTime = formatDate(d.createdTimeStamp))
        )
      );
      response.data.data.map((d) => (d.mrn = d.patientId.identifier[0].value));
      response.data.data.map(
        (d) =>
          (d.careStreamName = d.careStream[d.careStream.length - 1]
            ? d.careStream[d.careStream.length - 1].name
            : "N/A")
      );
      response.data.data.map(
        (val) =>
          (val.productionArea =
            val.chiefComplaint[val.chiefComplaint.length - 1] &&
            val.chiefComplaint[val.chiefComplaint.length - 1].chiefComplaintId
              ? val.chiefComplaint[val.chiefComplaint.length - 1]
                  .chiefComplaintId.productionArea[
                  val.chiefComplaint[val.chiefComplaint.length - 1]
                    .chiefComplaintId.productionArea.length - 1
                ].productionAreaId.paName
              : "N/A")
      );
      setEdNurseArray(response.data.data);
    })
    .catch((error) => {
      console.log("Error", error);
      setOpenNotification(true);
      setErrorMsg(error);
    });
};
