import React, { useEffect, useState, useReducer } from "react";
import cookie from "react-cookies";
import Header from "../../components/Header/Header";
import Button from "@material-ui/core/Button";
import DcdIcon from "../../assets/img/DCD Form Icon.png";
import TriageBoxIcon from "../../assets/img/Triage & Assessment.png";
import PatientDetailBoxIcon from "../../assets/img/Patient Details.png";
import PastMedicalBoxIcon from "../../assets/img/Past Medical History.png";
import RosBoxIcon from "../../assets/img/ROS.png";
import PhysicalBoxIcon from "../../assets/img/physical-exams-icon.png";
import InvestigationsBoxIcon from "../../assets/img/Investigations.png";
import ActionPlanBoxIcon from "../../assets/img/Action & Plan.png";
import CourseVisitBoxIcon from "../../assets/img/Course of Visit.png";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Box from "../../components/DHR/DCD/Box";
import Back from "../../assets/img/Back_Arrow.png";
import "./dashboardDCD.css";
import Notification from "../../components/Snackbar/Notification.js";
import PatientDetailsQuick from "../../components/PatientDetails/PatientDetailsQuick";
import { getDcdForm, updateDCDformStatus, getPatientEdrForm } from "./NetworkCallForDCD";
import moment from "moment";

const styles = {
  stylesForSave: {
    color: "#fff",
    cursor: "pointer",
    borderRadius: 5,
    backgroundColor: "#13D59F",
    width: "150px",
    height: "50px",
    outline: "none",
  },
};

function DashboardDCD(props) {
  const matches = useMediaQuery("(min-width:600px)");

  const initialState = {
    currentUser: cookie.load("current_user"),
    openNotification: false,
    error: "",
    successMsg: "",
  };

  function reducer(state, { field, value }) {
    return {
      ...state,
      [field]: value,
    };
  }
  const [state, dispatch] = useReducer(reducer, initialState);

  const { currentUser, openNotification, error, successMsg } = state;

  const [dcdForm, setDcdForm] = useState([]);
  const [edrData, setEDRdata] = useState("");
  const [patientEdrForm, setPatientEdrForm] = useState("");

  useEffect(() => {
    const propsData = props.history.location.state;
    console.log("props passed in dashboard", propsData);
    setEDRdata(propsData);
    getDcdForm(propsData._id, setDcdForm);
    getPatientEdrForm(propsData._id, setPatientEdrForm);
    sessionStorage.removeItem("goBack");
  }, []);

  const onBoxClick = (title, data) => {
    let dataAlreadyFilled = false;
    let accessLevel =
      title === "Triage & Assessment" ||
      title === "Patient Details" ||
      title === "Past Medical History";

    let typeOfUser = currentUser.staffType;
    let path;
    if (
      (typeOfUser === "Sensei" ||
        typeOfUser === "Nurses" ||
        typeOfUser === "Paramedics") &&
      accessLevel
    ) {
      if (data > 0) {
        // path = "dcddashboard/viewForm";
        if (title === "Triage & Assessment") {
          path = "dcddashboard/triage-history";
        } else {
          path = "dcddashboard/view-history";
        }
        dataAlreadyFilled = true;
        sessionStorage.setItem("goBack", "-3");
      } else {
        if (title === "Triage & Assessment") {
          path = "dcddashboard/triageForm";
        } else {
          path = "dcddashboard/Form";
        }
      }
      console.log('patientEdrForm', patientEdrForm);
      props.history.push({
        pathname: path,
        state: {
          comingFor: title,
          selectedItem: props.history.location.state,
          dcdForm: dcdForm,
          patientEdrForm: patientEdrForm,
          careStream: "",
          dataAlreadyFilled,
        },
      });
    } else if (typeOfUser === "Doctor") {
      if (data > 0) {
        if (title === "Triage & Assessment") {
          path = "dcddashboard/triage-history";
        } else {
          path = "dcddashboard/view-history";
        }
        dataAlreadyFilled = true;
        sessionStorage.setItem("goBack", "-3");
      } else {
        if (title === "Triage & Assessment") {
          path = "dcddashboard/triage-history";
        } else {
          path = "dcddashboard/Form";
        }
      }
      
      /*if (
        (
          title === "Investigations" ||
          title === "Action & Plan" ||
          title === "Course of Visit"
        ) && edrData.careStream.length == 0
      ){
        alert('No CareStream is assigned. Please assigned it first!')
        // if (window.confirm('No CareStream is assigned. Do you want to Assign it?')){
        //   console.log("edrData", edrData);
        // }
      }else{*/
        console.log('patientEdrForm', patientEdrForm);
        props.history.push({
          pathname: path,
          state: {
            comingFor: title,
            selectedItem: props.history.location.state,
            dcdForm: dcdForm,
            patientEdrForm: patientEdrForm,
            dataAlreadyFilled,
          },
        });
      // }
    } else {
      dispatch({ field: "openNotification", value: true });
      dispatch({ field: "error", value: "You are not allowed the access" });
    }
    if (!dataAlreadyFilled) {
      sessionStorage.setItem("goBack", "-2");
    }
  };

  const submitDCDform = () => {
    if (edrData.dcdFormStatus === "completed") {
      dispatch({ field: "openNotification", value: true });
      dispatch({
        field: "successMsg",
        value: "Form has already been submitted",
      });
    } else {
      if (dcdForm && dcdForm.length > 0) {
        if (
          dcdForm[0].triageAssessment.length > 0 &&
          dcdForm[0].patientDetails.length > 0 &&
          dcdForm[0].pastMedicalHistory.length > 0 &&
          dcdForm[0].ROS.length > 0 &&
          dcdForm[0].investigation.length > 0 &&
          dcdForm[0].actionPlan.length > 0 &&
          dcdForm[0].courseOfVisit.length > 0 &&
          dcdForm[0].physicalExam.length > 0
        ) {
          const params = {
            edrId: edrData._id,
          };
          updateDCDformStatus(params, dispatch);
        } else {
          dispatch({ field: "openNotification", value: true });
          dispatch({ field: "error", value: "Complete the form to submit" });
        }
      }
    }
  };

  if (openNotification) {
    setTimeout(() => {
      dispatch({ field: "openNotification", value: false });
      dispatch({ field: "error", value: "" });
      dispatch({ field: "successMsg", value: "" });
    }, 2000);
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        position: "fixed",
        width: "100%",
        height: "100%",
        backgroundColor: "#2973CF",
        overflow: "auto",
        paddingBottom: "60px",
      }}
    >
      <Header history={props.history} />
      <PatientDetailsQuick
        edrId={edrData._id} />

      <div className="cPadding">
        <div className="subheader">
          <div style={{ marginBottom: "20px", marginTop: "20px" }}>
            <img src={DcdIcon} />
            <h4>DCD Form</h4>
          </div>
        </div>

        <div className="container-fluid">
          <div className="row first-chunk">
            <div
              className="dashboard-chunk col-md-7"
              style={{ marginBottom: matches ? " " : 10 }}
            >
              <Box
                handleClick={onBoxClick}
                backgroundColor={"#0011ff"}
                title="Triage & Assessment"
                detail="Add Patient triage and assessment details"
                tickIcon={
                  dcdForm[0] && dcdForm[0].triageAssessment.length > 0
                    ? true
                    : false
                }
                dataCount={
                  dcdForm[0] ? dcdForm[0].triageAssessment.length : "0"
                }
                numberCount="1"
                dataAmount={
                  dcdForm[0] ? dcdForm[0].triageAssessment.length : "0"
                }
                boxIcon={TriageBoxIcon}
              />
            </div>
            <div className="dashboard-chunk col-md-5">
              <div>
                <Box
                  handleClick={onBoxClick}
                  backgroundColor={"#fa0223"}
                  title="Patient Details"
                  detail="Add patient details"
                  tickIcon={
                    dcdForm[0] && dcdForm[0].patientDetails.length > 0
                      ? true
                      : false
                  }
                  dataCount={
                    dcdForm[0] ? dcdForm[0].patientDetails.length : "0"
                  }
                  numberCount="2"
                  dataAmount={
                    dcdForm[0] ? dcdForm[0].patientDetails.length : "0"
                  }
                  boxIcon={PatientDetailBoxIcon}
                />
              </div>
            </div>
          </div>

          <div className="row first-chunk">
            <div className="dashboard-chunk col-md-9">
              <div>
                <Box
                  handleClick={onBoxClick}
                  backgroundColor={" #57bd5a"}
                  title="Past Medical History"
                  detail="Bio data & History of Present Illness-HP1"
                  tickIcon={
                    dcdForm[0] && dcdForm[0].pastMedicalHistory.length > 0
                      ? true
                      : false
                  }
                  dataCount={
                    dcdForm[0] ? dcdForm[0].pastMedicalHistory.length : "0"
                  }
                  dataAmount={
                    dcdForm[0] ? dcdForm[0].pastMedicalHistory.length : "0"
                  }
                  numberCount="3"
                  boxIcon={PastMedicalBoxIcon}
                  display={"flex"}
                  justifyContent={"flex-start"}
                  marginTop={0}
                  bottom={matches ? 220 : 230}
                  bottom4Icon={matches ? 232 : 240}
                />
              </div>
              <div className="row first-chunk">
                <div
                  className="dashboard-chunk col-md-6"
                  style={{
                    paddingLeft: 16,
                    paddingRight: matches ? "" : 12,
                    marginBottom: matches ? " " : 10,
                  }}
                >
                  <Box
                    handleClick={onBoxClick}
                    backgroundColor={"#e65120"}
                    title="ROS"
                    detail="Add ROS details"
                    numberCount="4"
                    boxIcon={RosBoxIcon}
                    right1={17}
                    tickIcon={
                      dcdForm[0] && dcdForm[0].ROS.length > 0 ? true : false
                    }
                    dataCount={dcdForm[0] ? dcdForm[0].ROS.length : "0"}
                    dataAmount={
                      dcdForm[0] ? dcdForm[0].ROS.length : "0"
                    }
                  />
                </div>

                <div
                  className="dashboard-chunk col-md-6"
                  style={{ paddingRight: 15, paddingLeft: matches ? 10 : 16 }}
                >
                  <Box
                    handleClick={onBoxClick}
                    backgroundColor={"#fbce49"}
                    title="Physical Exam"
                    detail="Add Patient Physical exam details"
                    numberCount="5"
                    boxIcon={PhysicalBoxIcon}
                    right1={17}
                    right4Icon={7}
                    tickIcon={
                      dcdForm[0] && dcdForm[0].physicalExam.length > 0
                        ? true
                        : false
                    }
                    dataCount={
                      dcdForm[0] ? dcdForm[0].physicalExam.length : "0"
                    }
                    dataAmount={
                      dcdForm[0] ? dcdForm[0].physicalExam.length : "0"
                    }
                  />
                </div>
              </div>
            </div>
            <div className="dashboard-chunk col-md-3">
              <Box
                handleClick={onBoxClick}
                title="Investigations"
                detail="Investigate the patient"
                numberCount="6"
                boxIcon={InvestigationsBoxIcon}
                backgroundColor={"#3893FC"}
                height1={matches ? 410 : 100}
                tickIcon={
                  dcdForm[0] && dcdForm[0].investigation.length > 0
                    ? true
                    : false
                }
                dataCount={dcdForm[0] ? dcdForm[0].investigation.length : "0"}
                dataAmount={
                  dcdForm[0] ? dcdForm[0].investigation.length : "0"
                }
              />
            </div>
          </div>
          <div
            className="row first-chunk"
            style={{
              marginTop: matches ? " " : 10,
              marginTop: matches ? "-10px" : "10px",
            }}
          >
            <div
              className="dashboard-chunk col-md-7"
              style={{ marginBottom: matches ? " " : 10 }}
            >
              <Box
                handleClick={onBoxClick}
                backgroundColor={"#0011ff"}
                title="Action & Plan"
                detail="Action & Plan agianst the patient"
                numberCount="7"
                boxIcon={ActionPlanBoxIcon}
                tickIcon={
                  dcdForm[0] && dcdForm[0].actionPlan.length > 0 ? true : false
                }
                dataCount={dcdForm[0] ? dcdForm[0].actionPlan.length : "0"}
                dataAmount={
                  dcdForm[0] ? dcdForm[0].actionPlan.length : "0"
                }
              />
            </div>
            <div className="dashboard-chunk col-md-5">
              <div>
                <Box
                  handleClick={onBoxClick}
                  backgroundColor={"#fa0223"}
                  title="Course of Visit"
                  detail="Patient course of visit"
                  numberCount="8"
                  boxIcon={CourseVisitBoxIcon}
                  tickIcon={
                    dcdForm[0] && dcdForm[0].courseOfVisit.length > 0
                      ? true
                      : false
                  }
                  dataCount={dcdForm[0] ? dcdForm[0].courseOfVisit.length : "0"}
                  dataAmount={
                    dcdForm[0] ? dcdForm[0].courseOfVisit.length : "0"
                  }
                />
              </div>
            </div>
          </div>
          {!matches ? (
            <div className="row" style={{ marginTop: 50 }}>
              <div
                className="col-md-6 col-sm-6 col-12 d-flex justify-content-end"
                style={{
                  paddingRight: "0px",
                }}
              >
                <Button
                  onClick={submitDCDform}
                  style={{
                    ...styles.stylesForSave,
                    width: "100%",
                    height: 40,
                    fontSize: matches ? 12 : 12,
                  }}
                  variant="contained"
                >
                  Submit
                </Button>
              </div>
            </div>
          ) : (
            <div className="row" style={{ marginTop: 20, marginBottom: 60 }}>
              <div className="col-md-6 col-sm-6 col-6">
                <img
                  onClick={() => props.history.goBack()}
                  src={Back}
                  style={{ width: 45, height: 40, cursor: "pointer" }}
                />
              </div>

              <div
                className="col-md-6 col-sm-6 col-6 d-flex justify-content-end"
                style={{
                  paddingRight: "0px",
                }}
              >
                <Button
                  onClick={submitDCDform}
                  style={{
                    ...styles.stylesForSave,
                    width: "170px",
                    fontSize: matches ? 12 : 12,
                  }}
                  variant="contained"
                >
                  Submit
                </Button>
              </div>
            </div>
          )}
        </div>
        <Notification
          success={successMsg}
          msg={error}
          open={openNotification}
        />
      </div>
    </div>
  );
}
export default DashboardDCD;
