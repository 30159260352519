import { makeStyles } from "@material-ui/core/styles";

export const styles = {
    stylesForButton: {
        color: "white",
        cursor: "pointer",
        borderRadius: 5,
        backgroundColor: "#2c6ddd",
        height: "40px",
        outline: "none",
    },
    inputContainerForTextField: {
        marginTop: 10,
    },
    textFieldPadding: {
        paddingLeft: 10,
        paddingRight: 10,
    },
};

export const useStylesForInput = makeStyles((theme) => ({
    input: {
        backgroundColor: "white",
        boxShadow: "none",
        borderRadius: 5,
        "&:after": {
            borderBottomColor: "black",
            boxShadow: "none",
        },
        "&:hover": {
            backgroundColor: "white",
            boxShadow: "none",
        },
        "&:focus": {
            backgroundColor: "white",
            boxShadow: "none",
            borderRadius: 5,
        }
    },
    root: {
        "& .MuiTextField-root": {
            backgroundColor: "white",
            borderRadius:5
        },
        "& .Mui-focused": {
            backgroundColor: "white",
            color: "black",
            boxShadow: "none",
        },
        "& .Mui-disabled": {
            backgroundColor: "white",
            color: "gray",
        },
        "& .MuiFormLabel-root": {
            fontSize: "13px",
            paddingRight: "50px",
        },
    },
    rootForTimeField: {
        "& .MuiTextField-root": {
            backgroundColor: "white",
            padding: '0px 0px 5px 10px',
            borderRadius:5
        },
        "& .Mui-focused": {
            backgroundColor: "white",
            color: "black",
            borderRadius:5
        },
        "& .Mui-disabled": {
            backgroundColor: "white",
            color: "gray",
        },
        '& .MuiFormLabel-root': {
            padding: '5px 0px 0px 10px',
        },
    }
}));