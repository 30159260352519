import React, { useEffect } from "react";
import MenuTree from "../../components/MenuTree/MenuTree";
import cookie from "react-cookies";
import Header from "../../components/Header/Header";
import { connect } from "react-redux";
import {
  funForReducer,
  setPatientDetailsForReducer,
} from "../../actions/Checking";
import { socketUrl, getNotifications } from "../../public/endpoins";
import socketIOClient from "socket.io-client";
import axios from "axios";
// Dashboards
import GenericDashboad from "../../components/GenericDashboad/GenericDashboard";
import RegistrationDashboard from "../UsersDashboards/RegistrationDashboard/Registration";
import SenseiDashboard from "../UsersDashboards/SenseiDashboard/Sensei";
import LabTechnicianDashboard from "../UsersDashboards/LabTechnicianDashboard/labTechnician";
import NurseTechnicianDashboard from "../UsersDashboards/NurseTechnicianDashboard/NurseTechnician";
import ParamedicsDashboard from "../UsersDashboards/ParamedicsDashboard/Paramedics";
import CustomerCareDashboard from "../UsersDashboards/CustomerCareDashboard/CustomerCare";
import SocialWorkerDashboard from "../UsersDashboards/SocialWorkerDashboard/SocialWorker";
import HousekeepingDashboard from "../UsersDashboards/HouseKeepingDashboard/Housekeeping";
import InsuranceDashboard from "../UsersDashboards/Insurance";
import AdminPanel from "../UsersDashboards/AdminPanel";
import PharmacistDashboard from "../UsersDashboards/PharmacistDashboard/pharmacistDashboard";
import EDdoctor from "../UsersDashboards/EDDoctorDashboard/EDdoctor";
import AnesthesiologistDashboard from "../UsersDashboards/AnesthesiologistDashboard/Anesthesiologist";
import InternalConsultantDashboard from "../UsersDashboards/InternalDashboard/internalConsultantDashboard";
import ExternalConsultantDashboard from "../UsersDashboards/ExternalDashboard/externalConsultantDashboard";
import InsuranceDepartment from "../UsersDashboards/InsuranceDepartment";
import CashierDashboard from "../UsersDashboards/Cashier";
import ImagingTechDashboard from "../UsersDashboards/ImagingTechncianDashboard/radTechnician";
import RadDoctor from "../UsersDashboards/RadDoctorDashboard/radDoctor";
import ED_EOUNurseDashboard from "../UsersDashboards/ED_EOUNurseDashboard/nurseDashboard";
// Icons
import Back from "../../assets/img/Back_Arrow.png";
import MyDashboardIcon from "../../assets/img/My Dashboard.png";
import MyAppIcon from "../../assets/img/My App.png";
import ReportsIcon from "../../assets/img/Reports_blue.png";
import PatientLogIcon from "../../assets/img/Patient Log Blue.png";
// Registration Icons
import PatientRegistrationIcon from "../../assets/img/PatientRegistration.png";
import PendingIcon from "../../assets/img/Pending Tasks.png";
import ActionAndPlan from "../../assets/img/Action and Plan.png";
import CompletedRegistrationIcon from "../../assets/img/Completed Tasks.png";
import NotificationIcon from "../../assets/img/Notification.png";
import NotificationIconBlue from "../../assets/img/Notifications.png";
import MessagesIcon from "../../assets/img/Messages.png";
import ChatIcon from "../../assets/img/Chat.png";
// Insurance Icons
import RegisterOfficerIcon from "../../assets/img/PatientRegistration.png";
import InsuranceManagerIcon from "../../assets/img/Insurance Claims Manager.png";
import claimsReview from "../../assets/img/ClaimsReview.png";
import PreApproval from "../../assets/img/Pre-Approval.png";
// Admin Panel Icons
import Patients from "../../assets/img/Patients.png";
import Management from "../../assets/img/Management.png";
import TransactionReport from "../../assets/img/Transaction Reports.png";
import SecondaryRoles from "../../assets/img/Secondary Roles.png";
import PrimaryRoles from "../../assets/img/Primary Roles.png";
// Sensei Icons
import SenseiIcon from "../../assets/img/Sensei.png";
import PatientManagement from "../../assets/img/PatientManagement.png";
import PatientLog from "../../assets/img/Patient Log.png";
import FlagManagement from "../../assets/img/Flags.png";
import DepartmentManagement from "../../assets/img/DepartmentManagement.png";
// ED Doctor Icons
import PendingPatientsIcon from "../../assets/img/Patients.png";
import EDdocIcon from "../../assets/img/ED Doctors - Physicians.png";
import CommunicationMgmtIcon from "../../assets/img/CommunicationManagement.png";
import AnesthesiologistNotesIcon from "../../assets/img/Anesthesiologist Notes.png";
import ConsultantsIcon from "../../assets/img/External Consultants.png";
import ConsultationNotes from "../../assets/img/Consultation Notes.png";
//Lab Technician Icons
import LabTechnIcon from "../../assets/img/Lab Technician.png";
import LabReqIcon from "../../assets/img/Lab Requests.png";
import TaskListIcon from "../../assets/img/Tasks List.png";
import CompletedTaskIcon from "../../assets/img/CompletedTasks.png";
import LabFlagIcon from "../../assets/img/LabTechFlags.png";
//Nurse Technician Icons
import NurseTechnicianIcon from "../../assets/img/Nurse Technician.png";
//Customer Care
import CustomerCareIcon from "../../assets/img/Customer Care.png";
// Rad Tech Icons
import radTechIcon from "../../assets/img/Rad Technician.png";
// House Keeping
import HousekeepingIcon from "../../assets/img/Housekeeping.png";
// Cashier
import CashierIcon from "../../assets/img/Cashier.png";
// Rad Doctor Icons
import RaddocIcon from "../../assets/img/Rad Doctor.png";
// ED Nurse Icons
import EDnurseIcon from "../../assets/img/ED Nurses.png";
import PendingPrescriptionsIcon from "../../assets/img/PendingPrescriptions.png";
import AssistanceIcon from "../../assets/img/Assistance.png";
// EOU Nurse Icons
import EOUnurseIcon from "../../assets/img/Emergency Observation Unit.png";
// Social worker Icons
import CustomerSatisIcon from "../../assets/img/CustomerSatisfactionForm.png";
import SocialworkerIcon from "../../assets/img/Social Workers.png";

// Pharmacist Icons
import PharmacistIcon from "../../assets/img/Pharmacist  Out-Patient.png";

// Pharmacist Icons
import ParamedicsIcon from "../../assets/img/Paramedics.png";

const Registration = [
  { img: MyAppIcon, text: "My Dashboard" },
  {
    img: NotificationIcon,
    text: "Notification",
    path: "/dashboard/home/notification",
  },
  {
    img: ChatIcon,
    text: "Chat",
    path: "/dashboard/home/chat",
  },
  {
    img: PatientRegistrationIcon,
    text: "Patient Registration",
    path: "/dashboard/home/patientregistration",
  },
  {
    img: PendingIcon,
    text: "Pending Episode Registration",
    path: "/dashboard/home/pendingepisode",
  },
  {
    img: CompletedRegistrationIcon,
    text: "Completed Registration",
    path: "/dashboard/home/completedregistration",
  },
  {
    img: FlagManagement,
    text: "Flag Management",
    path: "/dashboard/home/flagMgmt",
    // path: "/dashboard/underprogress",
  },
];

const InsuranceClaims = [
  { img: MyAppIcon, text: "My Dashboard" },
  {
    img: NotificationIcon,
    text: "Notification",
    path: "/dashboard/home/notification",
  },
  {
    img: PreApproval,
    text: "Pre Approval",
    path: "home/insurance/preapproval",
  },
  {
    img: claimsReview,
    text: "Claims Review",
    path: "home/insurance/claimsreview",
  },
  {
    img: PendingIcon,
    text: "New Patients",
    path: "home/newpatients",
  },
  {
    img: CompletedRegistrationIcon,
    text: "Discharged Patients",
    path: "home/dischargedpatients",
  },
];

const adminPanel = [
  { img: MyAppIcon, text: "My Dashboard" },
  {
    img: NotificationIcon,
    text: "Notification",
    path: "/dashboard/home/notification",
  },
  {
    img: ChatIcon,
    text: "Chat",
    path: "/dashboard/home/chat",
  },
  {
    img: Patients,
    text: "Patients",
    path: "/dashboard/home/patientmgmt",
  },
  {
    img: Management,
    text: "Management",
    path: "/dashboard/home/management",
  },
  {
    img: TransactionReport,
    text: "Transaction Reports",
    path: "/dashboard/home/transactionreports",
  },

  {
    img: PrimaryRoles,
    text: "Primary Roles",
    path: "home/primaryroles",
  },
  {
    img: SecondaryRoles,
    text: "Secondary Roles",
    path: "home/secondaryroles",
  },
];

const LabTechnician = [
  { img: MyAppIcon, text: "My Dashboard" },
  {
    img: NotificationIcon,
    text: "Notification",
    path: "/dashboard/home/notification",
  },

  {
    img: ChatIcon,
    text: "Chat",
    path: "/dashboard/home/chat",
  },
  {
    img: TaskListIcon,
    text: "Tasks List",
    path: "/dashboard/taskslist",
  },
  {
    img: CompletedTaskIcon,
    text: "Completed Tasks",
    path: "/dashboard/completedtasks",
  },
  {
    img: LabFlagIcon,
    text: "Flag Management",
    // path: "/dashboard/labtechnician/flagmanagement",
    path: "/dashboard/home/flagMgmt",
    // path: "/dashboard/underprogress",
  },
];

const NurseTechnician = [
  { img: MyAppIcon, text: "My Dashboard" },
  {
    img: NotificationIcon,
    text: "Notification",
    path: "/dashboard/home/notification",
  },

  {
    img: ChatIcon,
    text: "Chat",
    path: "/dashboard/home/chat",
  },
  {
    img: TaskListIcon,
    text: "Tasks List",
    path: "/dashboard/home/nursetechnician",
    // path: "/dashboard/underprogress",
  },
  {
    img: CompletedTaskIcon,
    text: "Completed Tasks",
    path: "/dashboard/home/nursetechniciancompleted",
    //path: "/dashboard/underprogress",
  },
  {
    img: AssistanceIcon,
    text: "Assistance By ED Nurse",
    path: "/dashboard/home/assistanceByEDNurse",
  },
];

const PorterMenu = [
  { img: MyAppIcon, text: "My Dashboard" },
  {
    img: NotificationIcon,
    text: "Notification",
    path: "/dashboard/home/notification",
  },

  {
    img: ChatIcon,
    text: "Chat",
    path: "/dashboard/home/chat",
  },
  {
    img: TaskListIcon,
    text: "Tasks List",
    path: "/dashboard/home/porter",
    // path: "/dashboard/underprogress",
  },
  {
    img: CompletedTaskIcon,
    text: "Completed Tasks",
    path: "/dashboard/home/portercompleted",
    //path: "/dashboard/underprogress",
  },
];

const RadiologyRequestReceiverMenu = [
  { img: MyAppIcon, text: "My Dashboard" },
  {
    img: NotificationIcon,
    text: "Notification",
    path: "/dashboard/home/notification",
  },

  {
    img: ChatIcon,
    text: "Chat",
    path: "/dashboard/home/chat",
  },
  {
    img: TaskListIcon,
    text: "Tasks List",
    path: "/dashboard/home/rrr",
  },
  {
    img: TaskListIcon,
    text: "Work List",
    path: "/dashboard/home/rrrp2",
  },
  {
    img: CompletedTaskIcon,
    text: "Completed Tasks",
    path: "/dashboard/home/rrrcompleted",
  },
];

const RegisteredPharmacistMenu = [
  { img: MyAppIcon, text: "My Dashboard" },
  {
    img: NotificationIcon,
    text: "Notification",
    path: "/dashboard/home/notification",
  },

  {
    img: ChatIcon,
    text: "Chat",
    path: "/dashboard/home/chat",
  },
  {
    img: TaskListIcon,
    text: "Tasks List",
    path: "/dashboard/home/rplist/pending",
  },
  /* {
    img: TaskListIcon,
    text: "Work List",
    path: "/dashboard/home/rrrp2",
  },*/
  {
    img: CompletedTaskIcon,
    text: "Completed Tasks",
    path: "/dashboard/home/rplist/completed",
  },
]

const CustomerCare = [
  { img: MyAppIcon, text: "My Dashboard" },
  {
    img: NotificationIcon,
    text: "Notification",
    path: "/dashboard/home/notification",
  },

  {
    img: ChatIcon,
    text: "Chat",
    path: "/dashboard/home/chat",
    // path: "/dashboard/underprogress",
  },
  {
    img: TaskListIcon,
    text: "Tasks List",
    path: "/dashboard/home/taskslistforcustomercare",
  },
  {
    img: CompletedTaskIcon,
    text: "Completed Tasks",
    path: "/dashboard/home/completedtasksforcustomercare",
  },
  {
    img: FlagManagement,
    text: "Flag Management",
    path: "/dashboard/home/flagMgmt",
    // path: "/dashboard/underprogress",
  },
  {
    img: CustomerSatisIcon,
    text: "Customer Satisfaction Form",
    path: "/dashboard/home/customercareList",
  },
  {
    img: AssistanceIcon,
    text: "Assistance By ED Nurse",
    path: "/dashboard/home/assistanceByEDNurse",
  },
];

const Housekeeping = [
  { img: MyAppIcon, text: "My Dashboard" },
  {
    img: NotificationIcon,
    text: "Notification",
    path: "/dashboard/home/notification",
  },

  {
    img: ChatIcon,
    text: "Chat",
    path: "/dashboard/home/chat",
  },
  {
    img: TaskListIcon,
    text: "Tasks List",
    path: "/dashboard/home/housekeepingrequests",
  },
  {
    img: CompletedTaskIcon,
    text: "Completed Tasks",
    path: "/dashboard/home/housekeepingrequest",
  },
  {
    img: FlagManagement,
    text: "Flag Management",
    path: "/dashboard/home/flagMgmt",
    // path: "/dashboard/underprogress",
  },
  {
    img: AssistanceIcon,
    text: "Assistance By ED Nurse",
    path: "/dashboard/home/assistanceByEDNurse",
  },
];

const SocialWorker = [
  { img: MyAppIcon, text: "My Dashboard" },
  {
    img: NotificationIcon,
    text: "Notification",
    path: "/dashboard/home/notification",
  },
  {
    img: TaskListIcon,
    text: "Tasks List",
    path: "/dashboard/home/taskslistforsocialworker",
  },
  {
    img: CompletedTaskIcon,
    text: "Completed Tasks",
    path: "/dashboard/home/completedtasksforsocialworker",
  },
  {
    img: CustomerSatisIcon,
    text: "Customer Satisfaction Form",
    path: "/dashboard/home/customerList",
  },
];

const Sensei = [
  { img: MyAppIcon, text: "My Dashboard" },
  {
    img: NotificationIcon,
    text: "Notification",
    path: "/dashboard/home/notification",
  },
  {
    img: ChatIcon,
    text: "Chat",
    path: "/dashboard/home/chat",
  },
  {
    img: PatientManagement,
    text: "Patient Management",
    path: "/dashboard/home/patientmanagement",
  },
  {
    img: PatientLog,
    text: "Patient Log",
    path: "/dashboard/home/patientlog",
  },
  {
    img: FlagManagement,
    text: "Flag Management",
    path: "/dashboard/home/flagMgmt",
    // path: "/dashboard/underprogress",
  },
  {
    img: DepartmentManagement,
    text: "Department Management",
    path: "/dashboard/home/departmentmanagement",
  },
  {
    img: DepartmentManagement,
    text: "Assistance",
    path: "/dashboard/home/assistance",
  },
];

const Paramedics = [
  { img: MyAppIcon, text: "My Dashboard" },
  // {
  //   img: NotificationIcon,
  //   text: 'Notification',
  //   path: '/dashboard/underprogress',
  // },
  {
    img: ChatIcon,
    text: "Chat",
    path: "/dashboard/home/chat",
  },
  {
    img: PatientRegistrationIcon,
    text: "Patient Registration",
    path: "/dashboard/home/PatientRegistrationFromParamedics",
  },
  {
    img: PatientLog,
    text: "Assign DCD Form",
    path: "/dashboard/home/assignDCDForm",
  },
  {
    img: FlagManagement,
    text: "Assign CareStream",
    path: "/dashboard/home/careStreamPatients",
  },
  {
    img: DepartmentManagement,
    text: "Transfer Pending",
    path: "/dashboard/home/transferpending",
  },
  {
    img: DepartmentManagement,
    text: "Transfer Completed",
    path: "/dashboard/home/transfercompleted",
  },
];

const EDdocPanel = [
  { img: MyAppIcon, text: "My Dashboard" },
  {
    img: NotificationIcon,
    text: "Notification",
    path: "/dashboard/home/notification",
  },
  {
    img: ChatIcon,
    text: "Chat",
    path: "/dashboard/home/chat",
  },
  {
    img: PatientRegistrationIcon,
    text: "Patient Management",
    path: "home/patientmanagement",
  },
  {
    img: PendingPatientsIcon,
    text: "Pending Patients",
    path: "/dashboard/home/patientlist",
  },
  {
    img: FlagManagement,
    text: "Flag Management",
    path: "home/flagMgmt",
    // path: "/dashboard/underprogress",
  },
  {
    img: PatientLog,
    text: "View Requests / Reports",
    path: "home/patientmanagement/viewrequests",
  },
  {
    img: CommunicationMgmtIcon,
    text: "Communication Management",
    path: "home/communicationMgmt",
  },
];

const CashierMenu = [
  { img: MyAppIcon, text: "My Dashboard" },
  {
    img: NotificationIcon,
    text: "Notification",
    path: "/dashboard/home/notification",
  },
  // {
  //   img: ChatIcon,
  //   text: "Chat",
  //   path: "/dashboard/home/chat",
  // },
  {
    img: ActionAndPlan,
    text: "TasksList",
    path: "/dashboard/home/patientclearence",
  },
  {
    img: CompletedRegistrationIcon,
    text: "Completed Tasks",
    path: "/dashboard/home/patientclearence/view",
  },
];

const ImagingTechnician = [
  { img: MyAppIcon, text: "My Dashboard" },
  {
    img: NotificationIcon,
    text: "Notification",
    path: "/dashboard/home/notification",
  },
  {
    img: ChatIcon,
    text: "Chat",
    path: "/dashboard/home/chat",
  },
  {
    img: PendingIcon,
    text: "Tasks List",
    path: "home/radiologyTasks",
  },
  {
    img: CompletedRegistrationIcon,
    text: "Completed Tasks",
    path: "home/radiologyTasksCompleted",
  },
  {
    img: FlagManagement,
    text: "Flag Management",
    // path: "/dashboard/home/flagMgmt",
    path: "/dashboard/underprogress",
  },
];

const AnesthesiologistMenu = [
  { img: MyAppIcon, text: "My Dashboard" },
  {
    img: NotificationIcon,
    text: "Notification",
    path: "/dashboard/home/notification",
  },
  {
    img: ChatIcon,
    text: "Chat",
    path: "/dashboard/home/chat",
  },
  {
    img: ActionAndPlan,
    text: "TasksList",
    path: "/dashboard/home/anesthesiarequests",
  },
  {
    img: CompletedRegistrationIcon,
    text: "Completed Tasks",
    path: "/dashboard/home/anesthesiarequests/completed",
  },
  {
    img: FlagManagement,
    text: "Flag Management",
    path: "/dashboard/home/flagMgmt",
    // path: "/dashboard/underprogress",
  },
];

const RadDocPanel = [
  { img: MyAppIcon, text: "My Dashboard" },
  {
    img: ChatIcon,
    text: "Chat",
    path: "/dashboard/home/chat",
  },
  {
    img: PendingIcon,
    text: "Tasks List",
    path: "home/radiologyTasks",
  },
  {
    img: CompletedRegistrationIcon,
    text: "Completed Tasks",
    path: "home/radiologyTasksCompleted",
  },
  {
    img: FlagManagement,
    text: "Flag Management",
    path: "/dashboard/home/flagMgmt",
    // path: "/dashboard/underprogress",
  },
];

const internalConsultantMenu = [
  { img: MyAppIcon, text: "My Dashboard" },
  {
    img: NotificationIcon,
    text: "Notification",
    path: "/dashboard/home/notification",
  },
  {
    img: ChatIcon,
    text: "Chat",
    path: "/dashboard/home/chat",
  },
  {
    img: ConsultationNotes,
    text: "Pending Consultations",
    path: "/dashboard/home/patientlist",
  },
  {
    img: ConsultationNotes,
    text: "Completed Consultations",
    path: "/dashboard/home/patientmanagement/consultations",
  },
  // {
  //   img: FlagManagement,
  //   text: "Flag Management",
  //   path: "home/flagMgmt",
  //   // path: "/dashboard/underprogress",
  // },
];

const externalConsultantMenu = [
  { img: MyAppIcon, text: "My Dashboard" },
  {
    img: NotificationIcon,
    text: "Notification",
    path: "/dashboard/home/notification",
  },
  {
    img: ChatIcon,
    text: "Chat",
    path: "/dashboard/home/chat",
  },
  {
    img: ConsultationNotes,
    text: "Pending Consultations",
    path: "/dashboard/home/patientmanagement/consultations",
  },
  {
    img: ConsultationNotes,
    text: "Completed Consultations",
    path: "/dashboard/home/patientmanagement/consultations",
  },
  // {
  //   img: FlagManagement,
  //   text: "Flag Management",
  //   path: "home/flagMgmt",
  //   // path: "/dashboard/underprogress",
  // },
];
const EDNursePanel = [
  { img: MyAppIcon, text: "My Dashboard" },
  {
    img: NotificationIcon,
    text: "Notification",
    path: "/dashboard/home/notification",
  },
  {
    img: ChatIcon,
    text: "Chat",
    path: "/dashboard/home/chat",
  },
  {
    img: PatientManagement,
    text: "Patient Management",
    path: "/dashboard/home/patientmanagement",
  },
  {
    img: PendingIcon,
    text: "Tasks List",
    path: "home/nurseTasks",
  },
  {
    img: FlagManagement,
    text: "Flag Management",
    path: "/dashboard/home/flagMgmt",
    // path: "/dashboard/underprogress",
  },
  // {
  //   img: PendingPrescriptionsIcon,
  //   text: "Pending Prescriptions",
  //   path: "/dashboard/underprogress",
  // },
  {
    img: AssistanceIcon,
    text: "Assistance",
    path: "home/assistance",
  },
];

const EOUNursePanel = [
  { img: MyAppIcon, text: "My Dashboard" },
  {
    img: NotificationIcon,
    text: "Notification",
    path: "/dashboard/home/notification",
  },
  {
    img: ChatIcon,
    text: "Chat",
    path: "/dashboard/home/chat",
  },
  {
    img: PatientManagement,
    text: "Patient Management",
    path: "/dashboard/home/patientmanagement",
  },
  {
    img: PendingIcon,
    text: "Tasks List",
    path: "home/nurseTasks",
  },
  {
    img: FlagManagement,
    text: "Flag Management",
    // path: "/dashboard/home/flagMgmt",
    path: "/dashboard/underprogress",
  },
];

const pharmacistPanel = [
  { img: MyAppIcon, text: "My Dashboard" },

  {
    img: NotificationIcon,
    text: "Notification",
    path: "/dashboard/home/notification",
  },

  {
    img: ChatIcon,
    text: "Chat",
    path: "/dashboard/home/chat",
  },
  {
    img: PendingIcon,
    text: "Tasks List",
    path: "/dashboard/home/pharmanotes",
  },
  {
    img: FlagManagement,
    text: "Flag Management",
    path: "/dashboard/home/flagMgmt",
    // path: "/dashboard/underprogress",
  },
  // {
  //   img: CompletedRegistrationIcon,
  //   text: "Completed Tasks",
  //   path: "/dashboard/home/pharmanotes",
  // },
];

class Dashboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      openApps: false,
      currentUser: cookie.load("current_user"),
      userStaff: cookie.load("user_staff"),
      options: "",

      openHome: false,

      notificationArray: [],

      headingIcon: "",

      headingTitle: "",

      data: [],
      notificationsList: [],
    };
  }

  // setOptions() {
  //   let routeAccess = this.state.userStaff.routeAccess

  //   let options = []

  //   for (let i = 0; i < routeAccess.length; i++) {
  //     let routeObj = routeAccess[i]
  //     let splitedModulesArray = routeObj.route.split("/")

  //     for (let j = 0; j < splitedModulesArray.length; j++) {
  //       let singleModule = splitedModulesArray[j]
  //       let temp = admin.find((r) => r.text === singleModule)
  //       if (temp) {
  //         console.log(temp.text)
  //         let alreadyFound =
  //           options && options.find((r) => r.text === temp.text)
  //         if (!alreadyFound) {
  //           options.push(temp)
  //         }
  //       }
  //     }
  //   }

  //   console.log(options)
  //   this.setState({ options: [admin[0], ...options] })
  // }

  componentDidMount() {
    // if (this.state.userStaff !== "" && this.state.userStaff !== "undefined") {
    //   this.setOptions();
    // }

    // const loggedUser = cookie.load('current_user')
    // this.setState({ currentUser: loggedUser })

    // axios
    //   .get(getNotifications + '/' + loggedUser._id)
    //   .then((res) => {
    //     console.log('helooo')
    //     if (res.data.success) {
    //       console.log('Load Notifications', res.data.data)

    //       let notifyData = []
    //       for (let i = 0; i < res.data.data.length; i++) {
    //         var checkId = res.data.data[i].sendTo
    //         for (let j = 0; j < checkId.length; j++) {
    //           if (checkId[j].userId._id === loggedUser._id) {
    //             notifyData.push(res.data.data[i])
    //           }
    //         }
    //       }
    //       // console.log("After checking User's Notifications", notifyData)
    //       // this.setState({ data: notifyData })
    //       Sensei[1].notificationData = notifyData
    //       // Registration[1].notificationData = notifyData
    //     }
    //   })
    //   .catch((e) => {
    //     console.log('Cannot get Notifications', e)
    //   })

    // const socket = socketIOClient(socketUrl)

    // socket.on('get_data', (data) => {
    //   console.log('response coming through socket', data)

    //   for (let i = 0; i < data.length; i++) {
    //     var checkId = data[i].sendTo
    //     if (data[i].sendTo) {
    //       for (let j = 0; j < checkId.length; j++) {
    //         if (checkId[j].userId._id === loggedUser._id) {
    //           var newData = [].concat(data[i], this.state.data)
    //           this.setState({ data: newData })
    //         }
    //       }
    //     }
    //   }
    // })

    this.props.setPatientDetailsForReducer("");

    if (this.state.openHome)
      setTimeout(() => {
        document.getElementById("menu-open").checked = true;
      }, 120);

    if (this.state.currentUser.staffType === "Admin") {
      this.setState({
        notificationArray: [
          {
            icon: MessagesIcon,
            title: "Messages",
          },
          {
            icon: NotificationIconBlue,
            title: "Notifications",
          },
        ],
        headingIcon: RegisterOfficerIcon,
        headingTitle: "Admin Panel",
      });
    } else if (this.state.currentUser.email === "lab@dhr.com") {
      this.setState({
        notificationArray: [
          {
            icon: MyDashboardIcon,
            title: "New Registrations Arriving",
          },
        ],
        headingIcon: RegisterOfficerIcon,
        headingTitle: "Lab Technician",
      });
    } else if (this.state.currentUser.staffType === "Sensei") {
      this.setState({
        notificationArray: [
          {
            icon: MessagesIcon,
            title: "Messages",
          },
          // {
          //   icon: PatientLogIcon,
          //   title: "New Patients Arriving",
          // },
          // {
          //   icon: ReportsIcon,
          //   title: "Report",
          // },
        ],
        headingIcon: RegisterOfficerIcon,
        headingTitle: "Sensei",
      });
    } else if (this.state.currentUser.staffType === "Cashier") {
      this.setState({
        notificationArray: [
          {
            icon: NotificationIconBlue,
            title: "Notifications",
          },
          {
            icon: ReportsIcon,
            title: "Report",
          },
        ],
        headingIcon: CashierIcon,
        headingTitle: "Cashier",
      });
    } else if (this.state.currentUser.staffType === "Doctor") {
      if (this.state.currentUser.subType.includes("ED Doctor")) {
        this.setState({
          notificationArray: [
            {
              icon: MessagesIcon,
              title: "Messages",
            },
            {
              icon: NotificationIconBlue,
              title: "Notifications",
            },
          ],
          headingIcon: EDdocIcon,
          headingTitle: "ED Doctor",
        });
      } else if (this.state.currentUser.subType.includes("Anesthesiologist")) {
        this.setState({
          notificationArray: [
            {
              icon: MessagesIcon,
              title: "Messages",
            },
            {
              icon: NotificationIconBlue,
              title: "Notifications",
            },
          ],
          headingIcon: AnesthesiologistNotesIcon,
          headingTitle: "Anesthesiologist",
        });
      } else if (this.state.currentUser.subType.includes("Rad Doctor")) {
        this.setState({
          notificationArray: [
            {
              icon: MessagesIcon,
              title: "Messages",
            },
            {
              icon: NotificationIconBlue,
              title: "Notifications",
            },
          ],
          headingIcon: RaddocIcon,
          headingTitle: "Rad Doctor",
        });
      } else if (this.state.currentUser.subType.includes("Internal")) {
        this.setState({
          notificationArray: [
            {
              icon: MessagesIcon,
              title: "Messages",
            },
            {
              icon: NotificationIconBlue,
              title: "Notifications",
            },
            // {
            //   icon: ReportsIcon,
            //   title: "Report",
            // },
          ],
          headingIcon: ConsultantsIcon,
          headingTitle: "Hospital Consultant",
        });
      } else if (this.state.currentUser.subType.includes("External")) {
        this.setState({
          notificationArray: [
            {
              icon: MessagesIcon,
              title: "Messages",
            },
            {
              icon: NotificationIconBlue,
              title: "Notifications",
            },
            // {
            //   icon: ReportsIcon,
            //   title: "Report",
            // },
          ],
          headingIcon: ConsultantsIcon,
          headingTitle: "External Consultant",
        });
      }
    } else if (this.state.currentUser.staffType === "Lab Technician") {
      this.setState({
        notificationArray: [
          {
            icon: MessagesIcon,
            title: "Messages",
          },
          // {
          //   icon: LabReqIcon,
          //   title: "Lab Requests",
          // },
        ],
        headingIcon: LabTechnIcon,
        headingTitle: "Lab Request Receiver",
      });
    }  else if (this.state.currentUser.staffType === "Radiology Request Receiver") {
      this.setState({
        notificationArray: [
          {
            icon: MessagesIcon,
            title: "Messages",
          },
          // {
          //   icon: LabReqIcon,
          //   title: "Lab Requests",
          // },
        ],
        headingIcon: LabTechnIcon,
        headingTitle: "Radiology Request Receiver",
      });
    }  else if (this.state.currentUser.staffType === "Registered Pharmacist") {
      this.setState({
        notificationArray: [
          {
            icon: MessagesIcon,
            title: "Messages",
          },
        ],
        headingIcon: LabTechnIcon,
        headingTitle: "Registered Pharmacist",
      });
    } else if (this.state.currentUser.staffType === "Porter") {
      this.setState({
        notificationArray: [
          {
            icon: MessagesIcon,
            title: "Messages",
          },
          // {
          //   icon: LabReqIcon,
          //   title: "Lab Requests",
          // },
        ],
        headingIcon: LabTechnIcon,
        headingTitle: "Porter",
      });
    } else if (this.state.currentUser.staffType === "House Keeping") {
      this.setState({
        notificationArray: [
          {
            icon: MessagesIcon,
            title: "Messages",
          },
          {
            icon: NotificationIconBlue,
            title: "Notifications",
          },
        ],
        headingIcon: HousekeepingIcon,
        headingTitle: "Housekeeping",
      });
    } else if (this.state.currentUser.staffType === "Social Worker") {
      this.setState({
        notificationArray: [
          {
            icon: NotificationIconBlue,
            title: "Notifications",
          },
        ],
        headingIcon: SocialworkerIcon,
        headingTitle: "Social Worker",
      });
    } else if (this.state.currentUser.staffType.includes("Paramedics")) {
      this.setState({
        notificationArray: [
          {
            icon: MessagesIcon,
            title: "Messages",
          },
          {
            icon: NotificationIconBlue,
            title: "New Patients Arriving",
          },
        ],
        headingIcon: ParamedicsIcon,
        headingTitle: "Paramedics",
      });
    } else if (
      this.state.currentUser.staffType === "Insurance Claims Manager"
    ) {
      this.setState({
        notificationArray: [
          {
            icon: NotificationIconBlue,
            title: "Notifications",
          },
        ],
        headingIcon: InsuranceManagerIcon,
        headingTitle: "RCM Officer",
      });
    } else if (this.state.currentUser.staffType === "Imaging Technician") {
      this.setState({
        notificationArray: [
          {
            icon: MessagesIcon,
            title: "Messages",
          },
          {
            icon: NotificationIconBlue,
            title: "Notifications",
          },
        ],
        headingIcon: radTechIcon,
        headingTitle: "Rad Technician",
      });
    } else if (this.state.currentUser.staffType === "Nurses") {
      if (this.state.currentUser.subType.includes("ED Nurse")) {
        this.setState({
          notificationArray: [
            {
              icon: MessagesIcon,
              title: "Messages",
            },
            {
              icon: NotificationIconBlue,
              title: "Notifications",
            },
          ],
          headingIcon: EDnurseIcon,
          headingTitle: "ED Nurse",
        });
      } else if (this.state.currentUser.subType.includes("Nurse Technician")) {
        this.setState({
          notificationArray: [
            {
              icon: MessagesIcon,
              title: "Messages",
            },
            {
              icon: NotificationIconBlue,
              title: "Notifications",
            },
          ],
          headingIcon: NurseTechnicianIcon,
          headingTitle: "Nurse Technician",
        });
      } else if (this.state.currentUser.subType.includes("EOU Nurse")) {
        this.setState({
          notificationArray: [
            {
              icon: MessagesIcon,
              title: "Messages",
            },
            {
              icon: NotificationIconBlue,
              title: "Notifications",
            },
          ],
          headingIcon: EOUnurseIcon,
          headingTitle: "EOU Nurse",
        });
      }
    } else if (this.state.currentUser.staffType === "Customer Care") {
      this.setState({
        notificationArray: [
          {
            icon: NotificationIconBlue,
            title: "Notifications",
          },
        ],
        headingIcon: CustomerCareIcon,
        headingTitle: "Customer Experience Officer",
      });
    } else if (this.state.currentUser.staffType === "Clinical Pharmacist") {
      this.setState({
        notificationArray: [
          {
            icon: MessagesIcon,
            title: "Messages",
          },
          {
            icon: NotificationIconBlue,
            title: "Notifications",
          },
        ],
        headingIcon: PharmacistIcon,
        headingTitle: "Clinical Pharmacist",
      });
    } else {
      this.setState({
        notificationArray: [
          {
            icon: MessagesIcon,
            title: "Messages",
          },
        ],
        headingIcon: RegisterOfficerIcon,
        headingTitle: "Registration Officer",
      });
    }
  }

  openHomeApps() {
    // this.setState({ openHome: true })
    // this.props.history.push('/home');

    let path = `/dashboard/home`;
    this.props.history.push({
      pathname: path,
    });
  }

  // handleNotificationsData = (notifications) => {
  //   Sensei[1].notificationData = notifications
  //   this.setState({
  //     notificationsList: notifications,
  //   })
  // }

  render() {
    const userType = this.state.currentUser;
    console.log("User logged in", this.state.currentUser);

    if (this.props.history.location.pathname === "/dashboard/home") {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            position: "fixed",
            width: "100%",
            height: "100%",
            backgroundColor: "#2962CC",
          }}
        >
          <Header
            history={this.props.history}
            // getNotificationsData={(notifications) =>
            //   this.handleNotificationsData(notifications)
            // }
          />

          <MenuTree
            history={this.props.history}
            options={
              userType.staffType === "Admin"
                ? adminPanel
                : userType.staffType === "Cashier"
                ? CashierMenu
                : userType.staffType === "Sensei"
                ? Sensei
                : userType.staffType === "Paramedics"
                ? Paramedics
                : userType.staffType === "Nurses"
                ? userType.subType.includes("Nurse Technician")
                  ? NurseTechnician
                  : userType.subType.includes("ED Nurse")
                  ? EDNursePanel
                  : EOUNursePanel
                : userType.staffType === "Lab Technician"
                ? LabTechnician
                : userType.staffType === "Nurse Technician"
                ? NurseTechnician
                : userType.staffType === "Porter"
                ? PorterMenu
                : userType.staffType === "Radiology Request Receiver"
                ? RadiologyRequestReceiverMenu
                : userType.staffType === "Registered Pharmacist"
                ? RegisteredPharmacistMenu
                : userType.staffType === "Customer Care"
                ? CustomerCare
                : userType.staffType === "House Keeping"
                ? Housekeeping
                : userType.staffType === "Social Worker"
                ? SocialWorker
                : userType.staffType === "Doctor"
                ? userType.subType.includes("ED Doctor")
                  ? EDdocPanel
                  : userType.subType.includes("Anesthesiologist")
                  ? AnesthesiologistMenu
                  : userType.subType.includes("Internal")
                  ? internalConsultantMenu
                  : userType.subType.includes("External")
                  ? externalConsultantMenu
                  : RadDocPanel
                : userType.staffType === "Insurance Claims Manager"
                ? InsuranceClaims
                : userType.staffType === "Clinical Pharmacist"
                ? pharmacistPanel
                : userType.staffType === "Imaging Technician"
                ? ImagingTechnician
                : Registration
            }
          />

          <div
            style={{
              position: "fixed",
              width: "100%",
              height: "10%",
              top: "90%",
            }}
          >
            <img
              onClick={() => this.props.history.goBack()}
              src={Back}
              style={{
                width: 45,
                height: 35,
                marginLeft: "5%",
                cursor: "pointer",
              }}
            />
          </div>
        </div>
      );
    } else {
      return (
        <GenericDashboad
          notificationArray={this.state.notificationArray}
          headingIcon={this.state.headingIcon}
          headingTitle={this.state.headingTitle}
          openApps={this.openHomeApps.bind(this)}
          history={this.props.history}
        >
          {userType.staffType === "Admin" ? (
            <AdminPanel history={this.props.history} />
          ) : userType.staffType === "Cashier" ? (
            <CashierDashboard />
          ) : userType.staffType === "Nurses" ? (
            userType.subType.includes("Nurse Technician") ? (
              <NurseTechnicianDashboard />
            ) : userType.subType.includes("ED Nurse") ? (
              <ED_EOUNurseDashboard />
            ) : (
              <ED_EOUNurseDashboard />
            )
          ) : userType.staffType === "Lab Technician" ? (
            <LabTechnicianDashboard />
          ) : userType.staffType === "Nurse Technician" ? (
            <NurseTechnicianDashboard />
          ) : userType.staffType === "Customer Care" ? (
            <CustomerCareDashboard />
          ) : userType.staffType === "House Keeping" ? (
            <HousekeepingDashboard />
          ) : userType.staffType === "Sensei" ? (
            <SenseiDashboard />
          ) : userType.staffType === "Paramedics" ? (
            <ParamedicsDashboard />
          ) : userType.staffType === "Social Worker" ? (
            <SocialWorkerDashboard />
          ) : userType.staffType === "Doctor" ? (
            userType.subType.includes("ED Doctor") ? (
              <EDdoctor />
            ) : userType.subType.includes("Anesthesiologist") ? (
              <AnesthesiologistDashboard />
            ) : userType.subType.includes("Internal") ? (
              <InternalConsultantDashboard />
            ) : userType.subType.includes("External") ? (
              <ExternalConsultantDashboard />
            ) : (
              <RadDoctor />
            )
          ) : userType.staffType === "Insurance Claims Manager" ? (
            <InsuranceDashboard />
          ) : userType.staffType === "Imaging Technician" ? (
            <ImagingTechDashboard />
          ) : userType.staffType === "Clinical Pharmacist" ? (
            <PharmacistDashboard />
          ) : userType.staffType === "Porter" ? (
            undefined
          ) : (
            <RegistrationDashboard />
          )}
        </GenericDashboad>
      );
    }
  }
}
const mapStateToProps = ({ CheckingReducer }) => {
  const { count, patientDetails } = CheckingReducer;
  return { count, patientDetails };
};
export default connect(mapStateToProps, {
  funForReducer,
  setPatientDetailsForReducer,
})(Dashboard);
