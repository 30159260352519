import axios from "axios";

export const getParamedics = async (endpoint) => {
  return new Promise((resolve, reject) => {
    axios
      .get(endpoint)
      .then(({ data: dataObj }) => {
        if (dataObj.success) {
          const { success, ...data } = dataObj;
          resolve(data);
        }
      })
      .catch((err) => console.log("error: ", err));
  });
};
