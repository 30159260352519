/*eslint-disable*/
import React, { useState, useEffect, useReducer } from "react";
import Button from "@material-ui/core/Button";
import Notification from "../../components/Snackbar/Notification.js";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Header from "../../components/Header/Header";
import CommunicationManagementIcon from "../../assets/img/CommunicationManagement.png";
import eye_icon from "../../assets/img/Eye.png";
import Back from "../../assets/img/Back_Arrow.png";
import "../../assets/jss/material-dashboard-react/components/loaderStyle.css";
import cookie from "react-cookies";
import { styles, useStyles } from "./styles.js";
import { addCommunicationRequestCall } from "./networkCallsForCommMgmt";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import SliderComponent from "../../components/SliderComponent/sliderComponent";

import MultiSlider, { Progress, Dot } from "react-multi-bar-slider";

const reasonArray = [
  {
    key: "Test 1",
    value: "Test 1",
  },
  {
    key: "Test 2",
    value: "Test 2",
  },
  {
    key: "Test 3",
    value: "Test 3",
  },
];

export default function CommunicationMgmt(props) {
  const matches = useMediaQuery("(min-width:600px)");
  const initialState = {
    reason: "",
    others: "",
  };

  function reducer(state, { field, value }) {
    return {
      ...state,
      [field]: value,
    };
  }

  const [state, dispatch] = useReducer(reducer, initialState);

  const { reason, others } = state;

  const classes = useStyles();

  const [currentUser] = useState(cookie.load("current_user"));
  const [errorMsg, setErrorMsg] = useState("");
  const [openNotification, setOpenNotification] = useState(false);

  if (openNotification) {
    setTimeout(() => {
      setOpenNotification(false);
      setErrorMsg("");
    }, 2000);
  }

  useEffect(() => {}, []);

  function handleViewRequests() {
    let path = `communicationMgmt/viewRequests`;
    props.history.push({
      pathname: path,
      state: {
        comingFor: "view",
      },
    });
  }

  const handleSubmit = () => {
    const object = {
      reason,
      others,
      generatedById: currentUser._id,
    };
    console.log("Object", object);
    addCommunicationRequestCall(
      props,
      object,
      setOpenNotification,
      setErrorMsg
    );
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        position: "fixed",
        width: "100%",
        height: "100%",
        backgroundColor: "#60d69f",
        overflowY: "scroll",
      }}
    >
      <Header history={props.history} />

      <div className="cPadding">
        <div className="subheader">
          <div>
            <img src={CommunicationManagementIcon} />
            <h4>Communication To Management</h4>
          </div>

          <Button
            onClick={handleViewRequests}
            style={{ ...styles.stylesForButton, height: matches ? 45 : 40 }}
            variant="contained"
            color="primary"
          >
            <img
              style={{ marginRight: matches ? 10 : "" }}
              src={eye_icon}
              className="icon-view"
            />

            <strong style={{ fontSize: matches ? " " : 10 }}>
              {matches ? "View All Requests" : "All Requests"}
            </strong>
          </Button>
        </div>

        <div style={{ height: "20px" }} />

        <div className={`container-fluid ${classes.root}`}>
          <div className="row" style={styles.textFieldPadding}>
            <div className="col-md-12 col-sm-12 col-12">
              <TextField
                // select
                fullWidth
                label="Reason to communicate with management"
                id="reason"
                name="reason"
                value={reason}
                onChange={(e) =>
                  dispatch({ field: "reason", value: e.target.value })
                }
                variant="filled"
                className="dropDownStyle"
                InputProps={{
                  className: classes.input,
                  classes: { input: classes.input },
                  disableUnderline: true,
                }}
              />
              {/* <MenuItem value="">
                  <em>None</em>
                </MenuItem>

                {reasonArray.map((val) => {
                  return (
                    <MenuItem key={val.key} value={val.value}>
                      {val.value}
                    </MenuItem>
                  )
                })}
              </TextField> */}
            </div>
          </div>

          <div className="row" style={styles.textFieldPadding}>
            <div className="col-md-12 col-sm-12 col-12">
              <TextField
                required
                multiline
                label="Others"
                name={"others"}
                value={others}
                onChange={(e) =>
                  dispatch({ field: "others", value: e.target.value })
                }
                className="textInputStyle"
                rows={4}
                variant="filled"
                InputProps={{
                  className: classes.input,
                  classes: { input: classes.input },
                  disableUnderline: true,
                }}
              />
            </div>
          </div>

          <div className="row" style={{ marginTop: 30 }}>
            {/* <div className="col-md-6 col-sm-6 col-6">
              <img
                onClick={() => props.history.goBack()}
                src={Back}
                style={{
                  width: 45,
                  height: 35,
                  cursor: "pointer",
                }}
              />
            </div> */}

            <div
              className="col-md-12  col-12 d-flex justify-content-end"
              style={{ paddingRight: 0 }}
            >
              <Button
                onClick={handleSubmit}
                style={{
                  ...styles.stylesForButton,
                  width: matches ? 150 : 302,
                  height: matches ? 45 : 40,
                }}
                variant="contained"
                color="primary"
                disabled={reason && others ? false : true}
              >
                <strong>Submit</strong>
              </Button>
            </div>
          </div>

          <div className="row" style={{ marginTop: 20 }}>
            <div className="col-md-6 col-sm-6 col-12">
              <img
                onClick={() => props.history.goBack()}
                src={Back}
                style={{
                  width: 45,
                  height: 35,
                  cursor: "pointer",
                }}
              />
            </div>
          </div>
        </div>

        <Notification msg={errorMsg} open={openNotification} />
      </div>
    </div>
  );
}
