import moment from "moment";

export const displayInHumanDifference = (createdDate) => {
  const startDate = moment(createdDate);
  const timeEnd = moment(new Date());
  const diff = timeEnd.diff(startDate);
  const diffDuration = moment.duration(diff);

  let date = [];

  if (diffDuration.months() > 0){
    date.push( diffDuration.months() + " month" );
  }
  if (diffDuration.days() > 0){
    date.push( diffDuration.days() + " days" );
  }
  if (diffDuration.hours() > 0){
    date.push( diffDuration.hours() + " hours" );
  }
  if (diffDuration.minutes() > 0){
    date.push( diffDuration.minutes() + " minutes" );
  }

  /*setHours(diffDuration.hours());
  setMinutes(diffDuration.minutes());
  setDays(diffDuration.days());
  setMonth(diffDuration.months());
  setWeek(diffDuration.weeks());*/
  return date.join(", ");
};

export const displayInHumanClassic = (createdDate) => {
  const startDate = moment(createdDate).format('DD-MMM-YYYY h:mm a');
  return startDate;
};

export const replacePatientIdIfNull = (data) => {
  // console.log('replacePatientIdIfNull', data);

  let nullPatientId = {
    _id: 'unknown',
    name: [
      {
        "given": [
          "John"
        ],
        "suffix": [],
        "_id": "",
        "use": "official",
        "family": "Doe",
        "prefix": ""
      }
    ],
    identifier: [{value: '-'}],
    gender: "Unknown",
    age: 0,
    paymentMode: "Selfpay"
  };

  if (data === null || data === "null" || typeof data == 'undefined' ){
    data = {
      patientId: nullPatientId
    };
  }
  if (typeof data.patientId == 'undefined'){
    data.patientId = nullPatientId;
  }
  if (data.patientId === null || data.patientId === "null" || !data.patientId){
    data.patientId = nullPatientId;
  }

  return data;
}