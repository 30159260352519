import React, { useEffect, useState, useReducer } from "react";
import { withStyles } from '@material-ui/core/styles';
import Header from "../../components/Header/Header";
import classNames from "classnames";
import Back from "../../assets/img/Back_Arrow.png";
import {medicineFrequencyArray, routeArrayNames} from "./configForNotesScreen"
import { 
  Button, 
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment,
  MenuItem,
  TextField,
  Select,
  useMediaQuery,

  Paper,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
} from "@material-ui/core";
import cookie from "react-cookies";

import axios from "axios";
import {
  audioURL,
  getLabServiceCategory,
  addLabRequestBulk,
  getSingleEdr,
  updatePorterPharmaDeliveryDetail,
  updateReceiptNonPharmaDetail,
  updateReceiptDoctorDetail,
} from "../../public/endpoins";

import useStylesForInput from "../../assets/jss/material-dashboard-react/inputStyle";
import styles from "./styles";
import Loader from "react-loader-spinner";
import {
  Checkbox, 
  FormLabel, 
  FormControl, 
  FormGroup, 
  FormControlLabel,
  RadioGroup, 
  Radio
} from '@material-ui/core';
import {
  priorityArray,
  statusArray,
} from "./configForNotesScreen";
import Icon from '@material-ui/core/Icon';
import CloseIcon from '@material-ui/icons/Close';
import MicIcon from "@material-ui/icons/Mic";
import StopIcon from "@material-ui/icons/Stop";
import MicRecorder from "mic-recorder-to-mp3";
import AccountCircle from "@material-ui/icons/SearchOutlined";
import Notification from "../../components/Snackbar/Notification.js";

import LabIcon from "../../assets/img/Lab.png";
import { displayInHumanDifference, replacePatientIdIfNull } from "../../utils/commonFunc";
import PatientDetailsQuick from "../../components/PatientDetails/PatientDetailsQuick";
import PatientDetails from "../../components/PatientDetails/PatientDetails";
import PatientDetails2 from "../../components/PatientDetails/PatientDetailsDirect";

export default function PharmacyRequestsDetailPage(props) {
  
  const matches = useMediaQuery("(min-width:600px)");
  const classes = useStylesForInput();

  const mystyle = {
    color: "white",
    backgroundColor: "DodgerBlue",
    padding: "10px",
    fontFamily: "Arial"
  };

  const GreenCheckbox = withStyles({
    root: {
      color: '#AAAAAA',
      '&$checked': {
        color: '#AAAAAA',
      },
    },
    checked: {
      color: '#2C6DDD',
      '&$checked': {
        color: '#2C6DDD',
      },
    },
  })((props) => <Checkbox color="default" {...props} />);

  const GreenRadio = withStyles({
    root: {
      color: '#AAAAAA',
      '&$checked': {
        color: '#AAAAAA',
      },
    },
    checked: {
      color: '#2C6DDD',
      '&$checked': {
        color: '#2C6DDD',
      },
    },
  })((props) => <Radio color="default" {...props} />);

  


  const initialState = {
    bulkTestSelected: false,
    resetedOn: "",
    defaultItems: {},
    seletedLabItems: [],
    labPriority: "",
    labTechnician: "",
    labComments: "",
    labTechniciansList: [],
    comments: '',
    doctorStatus: '',
  };
  function reducer(state, { field, value }) {
    return {
      ...state,
      [field]: value,
    };
  }
  const [state, dispatch] = useReducer(reducer, initialState);
  const {
    bulkTestSelected,
    resetedOn,
    defaultItems,
    seletedLabItems,
    labPriority,
    labTechnician,
    labComments,
    labTechniciansList,
    comments,
    doctorStatus,
  } = state;

  const handleCheckupChange = (event) => {
    dispatch({field: 'bulkTestSelected', value: event.target.value});
  };

  const handleConfirmReceiptDoctor = ( receipt ) => {
    axios
      .post(updateReceiptDoctorDetail, {
        edrId: episodeId,
        requestId: pharmaRequestId,
        staffId: currentUser._id,
        receiptType: receipt,
        comments: comments
      })
      .then((res) => {
        if (res.data.success) {
          setOpenNotification(true);
          setsuccessMsg(`The Details has been Submitted`);
          window.location.reload();
        }
      })
      .catch((e) => {
        setOpenNotification(true);
        setErrorMsg("Unable to Update the Record");
      });
  }

  const handleConfirmReceipt = () => {
    axios
      .post(updateReceiptNonPharmaDetail, {
        edrId: episodeId,
        requestId: pharmaRequestId,
        staffId: currentUser._id,
      })
      .then((res) => {
        if (res.data.success) {
          setOpenNotification(true);
          setsuccessMsg(`The Request has been marked as delivered!`);
          window.location.reload();
        }
      })
      .catch((e) => {
        setOpenNotification(true);
        setErrorMsg("Unable to Update the Record");
      });
  }

  const handleDelivered = () => {
    axios
      .post(updatePorterPharmaDeliveryDetail, {
        edrId: episodeId,
        requestId: pharmaRequestId,
        staffId: currentUser._id,
      })
      .then((res) => {
        if (res.data.success) {
          setOpenNotification(true);
          setsuccessMsg(`The Request has been marked as delivered!`);
          window.location.reload();
        }
      })
      .catch((e) => {
        setOpenNotification(true);
        setErrorMsg("Unable to Update the Record");
      });
  }

  const uniqueCodeField = (name) => {
    name = name.toLowerCase();
    name = name.replace(/[^a-z0-9]+/g, '-');
    name = name.replace(/^-+|-+$/g, '');
    return name;
  }

  const [currentUser] = useState(cookie.load("current_user"));
  const [timer, setTimer] = useState(null);
  const [selectedLab, setSelectedLab] = useState("");
  const [categoriesData, setCategoriesData] = useState([]);
  const [mItems, setMItems] = useState([]);

  const [patientDetails, setPatientDetails] = useState("");
  const [selectedRecord, setSelectedRecord] = useState({});

  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setsuccessMsg] = useState("");
  const [openNotification, setOpenNotification] = useState(false);

  const [ episodeId, setEpisodeId ] = useState(props.match.params.id);
  const [ pharmaRequestId, setPharmaRequestId ] = useState(props.match.params.requestId);
  const [ pharmaRequest, setPharmaRequest ] = useState({});
  const [ pharmaServices, setPharmaServices ] = useState([]);
  const [ serviceType, setServiceType ] = useState(props.match.params.serviceType);
  const [ isVerbalRequest, setIsVerbalRequest ] = useState(false);
  const [ isOxygenRequest, setIsOxygenRequest ] = useState(false);

  if (openNotification) {
    setTimeout(() => {
      setOpenNotification(false);
      setErrorMsg("");
      setsuccessMsg("");
    }, 4000);
  }

  useEffect(() => {
    axios
      .get(getSingleEdr + "/" + episodeId)
      .then((res) => {
        console.log('handleView::', res.data.data);
        if (res.data.success) {

          let remoteRecord = replacePatientIdIfNull(res.data.data);
          setSelectedRecord(remoteRecord);

          const object = {
            '_id': remoteRecord._id,
            patientName: remoteRecord.patientId.name[0].given[0] + " " + remoteRecord.patientId.name[0].family,
            mrn: remoteRecord.requestNo,//remoteRecord.patientId.identifier[0].value,
            age: remoteRecord.patientId.age,
            gender: remoteRecord.patientId.gender,
            weight: remoteRecord.patientId.weight,
            department: remoteRecord.currentLocation,
            chiefComplaint: remoteRecord.chiefComplaint.length > 0 ? remoteRecord.chiefComplaint.pop().chiefComplaintId.short_desc : "N/A",
            chiefComplaintOthers: remoteRecord.chiefComplaintOthers,
          };
          setPatientDetails(object);

          let pharmaServicesX = remoteRecord.pharmacyRequest.filter(obj => obj._id === pharmaRequestId);
          console.log('remoteRecord.pharmacyRequest', remoteRecord.pharmacyRequest, pharmaServicesX);
          if (pharmaServicesX.length > 0){
            // setIsUrgent(pharmaServicesX[0].urgent==="Yes"?true:false)

            if (pharmaServicesX[0].currentRequester && pharmaServicesX[0].requestedBy){
              if (pharmaServicesX[0].currentRequester._id !== pharmaServicesX[0].requestedBy._id){
                setIsVerbalRequest(true)
              }
            }

            let hasOxygenRequestConfirm = false;
            if (pharmaServicesX[0].item){
              let hasOxygenRequest = pharmaServicesX[0].item.filter(obj => obj.itemName.toLowerCase() === "oxygen");
              if (pharmaServicesX.length > 0){
                hasOxygenRequestConfirm = true;
                console.log("JJSJJA::", "Triggered :)");
              }
            }
            pharmaServicesX[0].hasOxygenRequestConfirm = hasOxygenRequestConfirm;

            setIsOxygenRequest(hasOxygenRequestConfirm);

            dispatch({field: 'comments', value: (pharmaServicesX[0].doctorStatusNotes)})
            dispatch({field: 'doctorStatus', value: (pharmaServicesX[0].doctorStatus)})
            console.log('pharmaServicesX[0]', pharmaServicesX[0]);
            setPharmaRequest(pharmaServicesX[0])
            setPharmaServices(pharmaServicesX[0].item);
          }
          

        }
      })
      .catch((e) => {
        console.log("error after searching patient request", e);
      });
    /*getLabTechniciansCall( currentUser._id, dispatch, setOpenNotification, setErrorMsg );*/
  }, []);

  return (
    
    <div
      style={{
        backgroundColor: "rgb(19 213 159)",
        position: "fixed",
        display: "flex",
        width: "100%",
        height: "100%",
        flexDirection: "column",
        flex: 1,
        overflowY: "scroll",
      }}
    >
      <Header history={props.history} />

      <PatientDetailsQuick
        edrId={episodeId} />

      <div className="cPadding">
        <div className="subheader">
          <div>
            <img src={LabIcon} />
            <h4>{patientDetails.patientName}</h4>
          </div>
        </div>

        {patientDetails ? (
          <div className="mb-3" style={{ marginLeft: "-15px", marginRight: "-15px" }}>
          <PatientDetails2
            edrId={patientDetails._id}
            showChiefComplaint={true}
            showTriageLevel={true}
            showCareStream={true}
            showDiagnosis={true} />
          </div>
        ) : (
          undefined
        )}

        <h4 className="h6 text-white mb-3"
          style={{
            fontWeight: '700'
          }}>{pharmaRequest?.pharmacyRequestNo} Item
          { 
            isVerbalRequest?
            <>
              <span className="badge badge-info pull-right ml-2">Verbal Request</span>
              {
                doctorStatus === ''?undefined:
                (doctorStatus==='nonconfirm'?<span className="badge badge-danger pull-right">Doctor Flaged</span>:<span className="badge badge-success pull-right">Doctor Confirmed</span>)
              }
            </>:undefined
          }
        </h4>
        <div className="row row-5">
          {
            pharmaServices ? 
            Object.entries(pharmaServices).map((obj, entry) => {
              return (
                <div className="col-12 col-md-6 col-lg-6 col-xl-6">
                {
                  pharmaRequest.generatedFor === "non_pharmaceutical"?
                  <div className="card card-detail-info mb-2">
                    <div className="row row-5">
                      <div className="col-9 col-md-5 card-detail-block block-xdiv mt-2"><strong className="lblX">Request ID:</strong> <span className="valX">{pharmaRequest?.pharmacyRequestNo}-{entry+1}</span></div>
                      <div className="col-3 col-md-7 card-detail-block block-xdiv mt-2">&nbsp;</div>
                      <div className="col-sm-12 card-detail-block block-xdiv mt-1"><strong className="lblX">Item Name</strong><span className="valX"><strong className="text-capitalize">{obj[1].itemId.name && obj[1].itemId.name === ""?obj[1].itemId.name.toLowerCase():obj[1].itemId.description.toLowerCase()}</strong></span></div>
                      <div className="col-12 card-detail-block block-xdiv mt-1"><strong className="lblX">Notes</strong><span className="valX"><small>{obj[1].additionalNotes===""?"N/A":obj[1].additionalNotes}</small></span></div>
                    </div>
                    <div className="card-status-action">
                      {obj[1].priority==="High"?<span className="badge badge-danger">High</span>:undefined}
                      <span className={classNames(`badge`, `status-`+obj[1].status)}>{obj[1].status}</span>
                    </div>
                  </div>:
                  <div className="card card-detail-info mb-2">
                    <div className="row row-5">
                      <div className="col-9 col-md-5 card-detail-block block-xdiv mt-2"><strong className="lblX">Request ID:</strong> <span className="valX">{pharmaRequest?.pharmacyRequestNo}-{entry+1}</span></div>
                      <div className="col-3 col-md-7 card-detail-block block-xdiv mt-2">&nbsp;</div>
                      <div className="col-sm-12 card-detail-block block-xdiv mt-1"><strong className="lblX">Item Name</strong><span className="valX"><strong className="text-capitalize">{obj[1].itemName.toLowerCase()}</strong></span></div>
                      <div className="col card-detail-block block-xdiv mt-1"><strong className="lblX">Dosage</strong><span className="valX">{obj[1].dosage}{obj[1].dosageType??undefined}</span></div>
                      <div className="col card-detail-block block-xdiv mt-1"><strong className="lblX">Duration</strong><span className="valX">{obj[1].duration}</span></div>
                      <div className="col card-detail-block block-xdiv mt-1"><strong className="lblX">Frequency</strong><span className="valX">{medicineFrequencyArray[obj[1].frequency]}</span></div>
                      <div className="col card-detail-block block-xdiv mt-1"><strong className="lblX">Route</strong><span className="valX">{routeArrayNames[obj[1].route]}</span></div>
                      <div className="col card-detail-block block-xdiv mt-1"><strong className="lblX">Req. Qty</strong><span className="valX">{(Math.ceil(obj[1].frequency)>=11 || Math.ceil(obj[1].frequency)===0)?obj[1].requestedQty:obj[1].dosage*obj[1].duration*obj[1].frequency}</span></div>
                      <div className="col card-detail-block block-xdiv mt-1"><strong className="lblX"># Admin{matches ? "istered" : ""}</strong><span className="valX">{obj[1].administeredDetails.length}</span></div>
                      <div className="col-12 card-detail-block block-xdiv mt-1"><strong className="lblX">Notes</strong><span className="valX"><small>{obj[1].additionalNotes===""?"N/A":obj[1].additionalNotes}</small></span></div>
                    </div>
                    <div className="card-status-action">
                      {
                        serviceType === "rad"?
                        <span className="badge badge-light">RCM {(obj[1].insuranceStatus==="Selfpay" || obj[1].insuranceStatus==="Covered" || obj[1].insuranceStatus==="Approved")?"Approved":obj[1].insuranceStatus}</span>:undefined
                      }
                      {obj[1].priority==="High"?<span className="badge badge-danger">High</span>:undefined}
                      { isOxygenRequest ? undefined : <span className={classNames(`badge`, `status-`+obj[1].status)}>{obj[1].status}</span> }
                      {/* 
                        obj[1].urgent==="Yes"?<span className="ml-info">!</span>:undefined
                        <i className="ml-view ml-10 zmdi zmdi-eye zmdi-hc-2x" onClick={() => {
                          window.alert(JSON.stringify(obj[1]));
                        }} />
                      */}
                      {
                        serviceType === "rad"?
                        <span className={classNames(`badge`, `status-`+obj[1].radiologyDoctorStatus)}>{obj[1].radiologyDoctorStatus==='completed'?'RAD Doctor Reported':'Pending Doctor Report'}</span>:undefined
                      }
                    </div>
                  </div>
                }
                </div>
              );
            }):undefined
          }

          {
            currentUser.staffType === "Nurses" &&
            currentUser.subType.includes("ED Nurse")?
            <>
            <div className="col-md-12"></div>
            <div className="col-md-6 offset-md-3 mt-3 mb-4 text-center">
              {
                ( pharmaRequest.generatedFor === "non_pharmaceutical" || isOxygenRequest )?
                <Button
                  // className="addButton"
                  style={{
                    backgroundColor: "#AD6BBF",
                    color: "white",
                    cursor: "pointer",
                    borderRadius: 5,
                    // backgroundColor: "rgb(173, 107, 191)",
                    height: matches ? 56 : 45,
                    outline: "none",
                    width: "100%",
                  }}
                  onClick={handleConfirmReceipt}
                  variant="contained"
                  color="primary"
                  disabled={pharmaRequest.deliveryStatus === 'pending'?false:true}
                  fullWidth
                >{pharmaRequest.deliveryStatus === 'pending'?"Pending Acceptance":"Accepted"}</Button>:
                (
                  pharmaRequest.status === "completed" && pharmaRequest.deliveryStatus === "pending"?
                  <Button
                    // className="addButton"
                    style={{
                      backgroundColor: "#AD6BBF",
                      color: "white",
                      cursor: "pointer",
                      borderRadius: 5,
                      // backgroundColor: "rgb(173, 107, 191)",
                      height: matches ? 56 : 45,
                      outline: "none",
                      width: "100%",
                    }}
                    onClick={handleDelivered}
                    variant="contained"
                    color="primary"
                    disabled={pharmaRequest.deliveryStatus === 'pending'?false:true}
                    fullWidth
                  >{pharmaRequest.deliveryStatus === 'pending'?"Pending Delivery":"Delivered"}</Button>:
                  undefined
                )
              }
            </div>
            </>:""
          
          }


        </div>

        { 
          isVerbalRequest && currentUser.staffType === "Doctor"?
          <>
            <Paper className="mb-3 mt-3">
              <Table size="small" stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <strong>Verbal Confirmation - {currentUser.staffType}</strong>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <div className="serviceNotesx py-2">
                        <div className="notesDiv">
                          <TextField
                            // required
                            multiline="true"
                            rows="3"
                            // disabled={enableForm}
                            label="Any Comments / Notes? (Optional)"
                            name={"comments"}
                            value={comments}
                            // error={comments === ""}
                            onChange={(e) => {dispatch({field: 'comments', value: e.target.value})}}
                            className="textInputStyle"
                            variant="filled"
                            InputProps={{
                              disableUnderline: true,
                            }}
                          />
                        </div>
                      </div>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Paper>
            <div className="row">
              <div class="col-md-6">
                <Button
                  // className="addButton"
                  style={{
                    backgroundColor: "#AD6BBF",
                    color: "white",
                    cursor: "pointer",
                    borderRadius: 5,
                    backgroundColor: "rgb(173, 107, 191)",
                    height: matches ? 56 : 45,
                    outline: "none",
                    width: "100%",
                  }}
                  // disabled={!addLabRequest}
                  onClick={() => { handleConfirmReceiptDoctor('confirm') } }
                  variant="contained"
                  color="primary"
                  fullWidth
                >Confirm</Button>
              </div>
              <div class="col-md-6">
                <Button
                  // className="addButton"
                  style={{
                    backgroundColor: "#FF0000",
                    color: "white",
                    cursor: "pointer",
                    borderRadius: 5,
                    // backgroundColor: "rgb(173, 107, 191)",
                    height: matches ? 56 : 45,
                    outline: "none",
                    width: "100%",
                  }}
                  // disabled={!addLabRequest}
                  onClick={() => { handleConfirmReceiptDoctor('nonconfirm') } }
                  variant="contained"
                  color="primary"
                  fullWidth
                >Mark Flag</Button>
              </div>
            </div>
          </>:undefined
        }


        <div
          className="container-fluid"
          style={{ marginBottom: "25px", marginTop: "25px" }}
        >
          <div className="row">
            <img
              onClick={() => props.history.goBack()}
              src={Back}
              style={{ width: 45, height: 35, cursor: "pointer" }}
            />
          </div>
        </div>
      </div>


      <Notification
        msg={errorMsg}
        open={openNotification}
        success={successMsg}
      />
    </div>
  );
}