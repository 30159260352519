import React, { useState, useEffect, useReducer } from "react";
import Notification from "../../components/Snackbar/Notification.js";
import CustomTable from "../../components/Table/Table";
import axios from "axios";
import _ from "lodash";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import eye_icon from "../../assets/img/Eye.png";
import {
  addConsultationNote,
  audioURL,
  completeConsultationNote,
  getAllConsultationNotesForDoctor,
} from "../../public/endpoins";
import MicIcon from "@material-ui/icons/Mic";
import StopIcon from "@material-ui/icons/Stop";
import MicRecorder from "mic-recorder-to-mp3";
import Loader from "react-loader-spinner";
import Back from "../../assets/img/Back_Arrow.png";
import Header from "../../components/Header/Header";
import Lab_OPR from "../../assets/img/External Consultants.png";
import ButtonField from "../../components/common/Button";
import Rad_OPR from "../../assets/img/RR.png";
import Pharmacist_OPR from "../../assets/img/PHR.png";
import Fingerprint from "../../assets/img/fingerprint.png";
import AccountCircle from "@material-ui/icons/SearchOutlined";
import InputAdornment from "@material-ui/core/InputAdornment";
import BarCode from "../../assets/img/Bar Code.png";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import "../../assets/jss/material-dashboard-react/components/loaderStyle.css";
import socketIOClient from "socket.io-client";
import cookie from "react-cookies";
import { assignPatientStyles } from "./styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import {
  getEdrPatientsForCC,
  searchEdrPatientsForCC,
  getAllCompletedConsultationRequest,
} from "./NetworkCallsPatient.js";
import { displayInHumanDifference, replacePatientIdIfNull } from "../../utils/commonFunc";
import classNames from "classnames";
import moment from 'moment';
import useMediaQuery from "@material-ui/core/useMediaQuery";
const Mp3Recorder = new MicRecorder({ bitRate: 128 });

const styles = {
  textFieldPadding: {
    paddingLeft: 5,
    paddingRight: 5,
  },
};

const actions = { assign: true };

export default function EDR(props) {
  const classes = assignPatientStyles();
  const matches = useMediaQuery("(min-width:600px)");
  const [labInPatient, setlabInPatient] = useState([]);
  const [currentUserId] = useState(cookie.load("current_user"));

  const [allRequests, setAllRequests] = useState([]);

  const [pendings, setPendings] = useState([]);
  const [completed, setCompleted] = useState([]);
  const [radInPatient, setradInPatient] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [openNotification, setOpenNotification] = useState(false);
  const [searchPatientQuery, setSearchPatientQuery] = useState("");
  const [selectedData, setSelectedData] = useState("");
  const [consultantNotes, setNotes] = useState("");
  const [isLoading, setisLoading] = useState(true);

  const [selectedStatus, setSelectedStatus] = useState("complete");

  const initialState = {
    isBlocked: false,
    isRecording: false,
    blobURL: "",
    fileAudio: null,
  };

  function reducer(state, { field, value }) {
    return {
      ...state,
      [field]: value,
    };
  }
  const [state, dispatch] = useReducer(reducer, initialState);

  const { isBlocked, isRecording, blobURL, fileAudio } = state;

  if (openNotification) {
    setTimeout(() => {
      setOpenNotification(false);
      setErrorMsg("");
    }, 2000);
  }

  useEffect(() => {
    getPatientData('');
    navigator.mediaDevices &&
      navigator.mediaDevices.getUserMedia(
        { audio: true },
        () => {
          console.log("Permission Granted");
          dispatch({ field: "isBlocked", value: false });
        },
        () => {
          console.log("Permission Denied");
          dispatch({ field: "isBlocked", value: true });
        }
      );
    // return () => socket.disconnect();
  }, []);

  function getPatientData(searchQuery) {
    // Getting Realtime Data from Screen
    let statusCodes = props.location.pathname === '/dashboard/home/patientmanagement/consultations/completed'?"status=completed&":""
    axios
      .get(getAllConsultationNotesForDoctor + "/" + currentUserId._id + '?' + (searchQuery?'searchKeyword='+searchQuery+'&':'') + (statusCodes))
      .then((res) => {
        if (res.data.success) {

          Object.entries(res.data.data).map(([o, i]) => {

            let d = replacePatientIdIfNull(res.data.data[o])
            d.requesterName = 'Dr. ' + (d.consultationNote.addedBy && d.consultationNote.addedBy.name[0].given[0] + " " + d.consultationNote.addedBy.name[0].family);
            d.consultantName = 'Dr. ' + (d.consultationNote.consultant && d.consultationNote.consultant.name[0].given[0] + " " + d.consultationNote.consultant.name[0].family);
            d.requestDate = moment(d.consultationNote.requestedAt).format('MMM DD, YYYY LT');
            d.requestId = d.consultationNote.consultationNo;
            d.status = d.consultationNote.status;
            d.speciality = d.consultationNote.speciality
            // Age
            var today = new Date();
            var birthDate = new Date(d.patientId.birthDate);
            var age_now = today.getFullYear() - birthDate.getFullYear();
            var m = today.getMonth() - birthDate.getMonth();
            if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
              age_now--;
            }
            d.ageNow = age_now;

            d.patientName = d.patientId.name[0].given[0] + " " + d.patientId.name[0].family;
            d.roomNo = d.room[0].roomId.roomNo;
            d.mrn = d.patientId.identifier[0].value;
            d.episodeNo = d.requestNo;

            res.data.data[o] = d
            // console.log('handleView::', o, i)
          })
          setAllRequests(res.data.data)
          setisLoading(false)
        }
      })
      .catch((e) => {
        console.log("error after searching patient request", e);
      });
  }

  function handleAssign(rec) 
  {
    console.log('REC::',rec);
    props.history.push({
      pathname: `/dashboard/home/patientmanagement/consultations/${rec._id}/details/${rec.consultationNote._id}`,
    });
  }

  const handlePatientSearch = (e) => {
    const a = e.target.value.replace(/[^\w\s]/gi, "");
    setSearchPatientQuery(a);
    if (a.length >= 3) {
      getPatientData(a)
    } else if (a.length == 0) {
      getPatientData('');
    }
  };

  const callCompleted = (buttonValue) => {
    console.log(buttonValue);
    // setSelectedStatus(status);
    let pathName = '/dashboard/home/patientmanagement/consultations';
    if (buttonValue === 'complete') {
      pathName = `/dashboard/home/patientmanagement/consultations/completed`
    }
    props.history.push({
      pathname: pathName,
    });
    props.history.go();
  };

  useEffect(() => {
    if (allRequests.length > 0) {
      // let requests = allRequests.filter((con) => con.status === selectedStatus);
      // setPendings(requests);
    }
  }, [selectedStatus, allRequests]);

  const addNewItem = () => {
    props.history.push({
      pathname: "patientregistration",
      state: { comingFor: "add" },
    });
  };

  const handleSubmit = () => {
    if (!consultantNotes) {
      setOpenNotification(true);
      setErrorMsg("Please add your notes first.");
      return;
    }

    let data = new FormData();

    const object = {
      _id: selectedData.consultationNote._id,
      consultantNotes,
      status: "complete",
      edrId: selectedData.patientData._id,
      completionDate: new Date(),
    };

    if (fileAudio) {
      data.append("file", fileAudio);
      data.append("data", JSON.stringify(object));
    } else {
      data = object;
    }

    console.log("Object", data);

    if (isRecording) {
      setOpenNotification(true);
      setErrorMsg("Please record voice then submit");
    } else {
      axios
        .put(completeConsultationNote, data)
        .then((response) => {
          console.log("Response", response);
          window.location.reload();
        })
        .catch((error) => {
          console.log("Error", error);
        });
    }
  };

  const start = () => {
    if (isBlocked) {
      console.log("Permission Denied");
    } else {
      Mp3Recorder.start()
        .then(() => {
          dispatch({ field: "isRecording", value: true });
        })
        .catch((e) => console.error(e));
    }
  };

  const stop = () => {
    Mp3Recorder.stop()
      .getMp3()
      .then(([buffer, blob]) => {
        const blobURL = URL.createObjectURL(blob);
        dispatch({ field: "blobURL", value: blobURL });
        dispatch({ field: "isRecording", value: false });
        var file = new File([blob], "first", { type: "audio/mp3" });
        dispatch({ field: "fileAudio", value: file });
      })
      .catch((e) => console.log(e));
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        position: "fixed",
        width: "100%",
        height: "100%",
        backgroundColor: "rgb(19 213 159)",
        overflowY: "scroll",
      }}
    >
      <Header history={props.history} />

      <div className="cPadding">
        <div className="subheader">
          <div>
            <img src={Lab_OPR} />
            <h4>Consultation Requests</h4>
          </div>

          {props.location.pathname === '/dashboard/home/patientmanagement/consultations' ? (
            <Button
              className={classes.stylesForButtonPatient}
              variant="contained"
              color="primary"
              onClick={() => callCompleted("complete")}
            >
              <img src={eye_icon} className="icon-view" />
              &nbsp;&nbsp;
              <strong style={{ fontSize: matches ? "" : 9 }}>
                Completed Requests
              </strong>
            </Button>
          ) : (
            <Button
              className={classes.stylesForButtonPatient}
              variant="contained"
              color="primary"
              onClick={() => callCompleted("pending")}
            >
              <img src={eye_icon} className="icon-view" />
              &nbsp;&nbsp;
              <strong style={{ fontSize: matches ? "" : 9 }}>
                Pending Requests
              </strong>
            </Button>
          )}
        </div>

        <div
          className={`${"container-fluid"} ${classes.root}`}
          style={{
            marginTop: "25px",
            paddingLeft: "10px",
            paddingRight: "10px",
          }}
        >
          <div className="row">
            <div
              className="col-md-10 col-sm-9 col-8"
              style={styles.textFieldPadding}
            >
              <TextField
                className="textInputStyle"
                id="searchPatientQuery"
                type="text"
                variant="filled"
                label="Patient MRN/Episode/Request Number or Name"
                name={"searchPatientQuery"}
                value={searchPatientQuery}
                onChange={handlePatientSearch}
                InputLabelProps={{
                  classes: {
                    root: classes.label,
                    focused: classes.focusedLabel,
                    error: classes.erroredLabel,
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <AccountCircle />
                    </InputAdornment>
                  ),
                  className: classes.input,
                  classes: { input: classes.input },
                  disableUnderline: true,
                }}
              />
            </div>

            <div
              className="col-md-1 col-sm-2 col-2"
              style={{
                ...styles.textFieldPadding,
                paddingLeft: matches ? 5 : 0,
                paddingRight: matches ? 5 : 3,
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "white",
                  borderRadius: 5,
                  height: 55,
                }}
              >
                <img src={BarCode} style={{ width: 70, height: 60 }} />
              </div>
            </div>

            <div
              className="col-md-1 col-sm-1 col-2"
              style={{
                ...styles.textFieldPadding,
                paddingLeft: matches ? 5 : 2,
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "white",
                  borderRadius: 5,
                  height: 55,
                }}
              >
                <img src={Fingerprint} style={{ maxWidth: 43, height: 43 }} />
              </div>
            </div>
          </div>
        </div>

        <div
          style={{
            flex: 4,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div>
            {isLoading ? (
              <div style={{ margin: "auto", width: "0%", padding: 10 }}>
                <Loader type="TailSpin" color="red" height={50} width={50} />
              </div>
            ) : labInPatient !== " " ? (
              <>
                <div className="row row-5 mt-3">
                  {
                    allRequests && allRequests.length > 0 ?
                    Object.entries(allRequests).map((obj, entry) => {
                      return (
                        <div className="col-12 col-md-6 col-lg-6 col-xl-6">
                          <div className="card card-detail-info card-detail-small mt-2">
                            <div className="row row-5">
                              <div className="col-sm-12"><strong>Request #:</strong> <span>{obj[1].requestId}</span> &nbsp; <strong>PA/Bed#:</strong> <span>{obj[1].roomNo}</span></div>
                              <div className="col-sm-6  mt-3"><strong>Epi.#:</strong> <span>{obj[1].episodeNo}</span></div>
                              <div className="col-sm-6  mt-3"><strong>MRN:</strong> <span>{obj[1].mrn}</span></div>
                              <div className="col-sm-10 mt-3"><strong>Patient:</strong> <span>{obj[1].patientName} / {obj[1].gender==="Female"?"F":"M"} / {obj[1].ageNow}y</span></div>
                              <div className="col-sm-8 mt-3"><strong>Doctor Name:</strong> <span>{obj[1].requesterName}</span></div>
                              <div className="col-sm-4 mt-3"><strong>Date/Time:</strong> <span>{obj[1].requestDate}</span></div>
                            </div>
                            <div className="card-status-action">
                              <span className={classNames(`badge`, `status-`+obj[1].status.toLowerCase())}>{obj[1].status}</span>
                              { 
                                obj[1].urgent==="Yes"?<span className="ml-info">!</span>:undefined
                              }
                              <i className="ml-view ml-10 zmdi zmdi-eye zmdi-hc-2x" onClick={() => {
                                handleAssign(obj[1]);
                              }} />
                            </div>
                          </div>
                        </div>
                      )
                    })
                    :<div className='col-12'><div className='alert alert-info'>There is no consultant request at the moment.</div></div>
                  }
                </div>
                <div className="mt-4 mb-4">
                  <img
                    onClick={() => props.history.goBack()}
                    src={Back}
                    style={{
                      width: 45,
                      height: 35,
                      cursor: "pointer",
                    }}
                  />
                </div>
                <Notification msg={errorMsg} open={openNotification} />
              </>
            ) : (
              <div className="row " style={{ marginTop: "25px" }}>
                <div className="col-11">
                  <h3
                    style={{
                      color: "white",
                      textAlign: "center",
                      width: "100%",
                      position: "absolute",
                    }}
                  >
                    Opps...No Data Found
                  </h3>
                </div>
                <div className="col-1" style={{ marginTop: 45 }}>
                  <img
                    onClick={() => props.history.goBack()}
                    src={Back}
                    style={{
                      maxWidth: "60%",
                      height: "auto",
                      cursor: "pointer",
                    }}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
