import React from "react"
import { Route, Switch } from "react-router-dom"
import NotFound from "../components/NotFound/NotFound"
import UnderDevelopment from "../components/UnderDevelopment/underDevelopment"
import PatientLog from "../views/Home/SenseiSubMenus/PatientLog/PatientLog"
import EDEEOU from "../views/EDEOU/EDEOU"
// import ViewEDEEOU from "../views/DetailsScreen/detailsScreen"
import TriageAssessment from "../views/TriageAssessment/TriageAssessment"

class PatientLogRoutes extends React.PureComponent {
  render() {
    return (
      <Switch>
        <Route exact path={`${this.props.match.url}`} component={PatientLog} />
        <Route
          exact
          path={`${this.props.match.url}/ED`}
          // component={UnderDevelopment}
          component={EDEEOU}
        />
        <Route
          exact
          path={`${this.props.match.url}/ED/viewED`}
          // component={ViewEDEEOU}
          component={UnderDevelopment}
        />
        <Route
          exact
          path={`${this.props.match.url}/ED/viewED/triageassessment`}
          component={TriageAssessment}
        />
        <Route exact path={`${this.props.match.url}/EOU`} component={EDEEOU} />
        <Route
          exact
          path={`${this.props.match.url}/EOU/viewEOU`}
          // component={ViewEDEEOU}
          component={UnderDevelopment}
        />

        <Route path={`${this.props.match.url}/notfound`} component={NotFound} />

        <Route path="*" component={NotFound} />
      </Switch>
    )
  }
}
export default PatientLogRoutes
