import { makeStyles } from "@material-ui/core/styles";

export const careStreamMainStyles = makeStyles(() => ({
  mobileStepper: {
    backgroundColor: "transparent",
    "& .MuiButtonBase-root": {
      backgroundColor: "#2973CF",
      color: "#FFFFFF",
      padding: 10,
    },
    "& .MuiMobileStepper-dots": {
      // display: "none",
    },
  },
  viewButtonLabel: { textTransform: "none", color: "white", width: "15%" },
}));

export const careStreamTableStyles = makeStyles(() => ({
  root: {
    justifyContent: "center",
  },
  scroller: {
    flexGrow: "0",
  },
  careStream: {
    color: "white",
    cursor: "pointer",
    borderRadius: 5,
    background: "#845DC2",
    // width: "28%",
    height: "45px",
    textTransform: "none",
    outline: "none",
  },
  careStreamMedicine: {
    color: "white",
    cursor: "pointer",
    borderRadius: 5,
    background: "#2c6ddd",
    marginLeft: 30,
    // width: "28%",
    textTransform: "none",
    height: "45px",
    outline: "none",
    marginRight: 0,
    // fontSize: 9,
  },
}));

export const careStreamInprogressStyles = makeStyles(() => ({
  careStream: {
    color: "white",
    cursor: "pointer",
    borderRadius: 5,
    background: "#2c6ddd",
    width: "30%",
    height: "45px",
    outline: "none",
    textTransform: "none",
  },
}));

export const viewTreatmentNFluidsStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    "& .MuiInputBase-root": {
      borderBottom: "0px solid transparent",
    },
  },
  image: {
    width: 50,
    height: 50,
    marginLeft: 15,
  },
}));

export const viewPrecautionsStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    "& .MuiInputBase-root": {
      borderBottom: "0px solid transparent",
    },
  },
  image: {
    width: 50,
    height: 50,
    marginLeft: 15,
  },
}));

export const viewMedicationsStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    "& .MuiInputBase-root": {
      borderBottom: "0px solid transparent",
    },
  },
  image: {
    width: 50,
    height: 50,
    marginLeft: 15,
  },
}));

export const viewMDNotificationsStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    "& .MuiInputBase-root": {
      borderBottom: "0px solid transparent",
    },
  },
  image: {
    width: 50,
    height: 50,
    marginLeft: 15,
  },
}));

export const viewInvestigationsStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    "& .MuiInputBase-root": {
      borderBottom: "0px solid transparent",
    },
  },
  image: {
    width: 50,
    height: 50,
    marginLeft: 15,
  },
}));

export const viewAssignStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    "& .MuiFormLabel-root.Mui-disabled": {
      paddingLeft: "25px !important",
      paddingTop: "10px !important",
    },
    "& .MuiInputBase-input.Mui-disabled": {
      paddingLeft: "20px !important",
      paddingTop: "10px !important",
    },
  },
  image: {
    width: 50,
    height: 50,
    marginLeft: 15,
  },
  input: {
    backgroundColor: "white",
    boxShadow: "none",
    "&:after": {
      borderBottomColor: "black",
      boxShadow: "none",
    },
    "&:hover": {
      backgroundColor: "white",
      boxShadow: "none",
    },
    "&:focus": {
      backgroundColor: "white",
      boxShadow: "none",
    },
  },
}));

export const treatmentNFluidsStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    "& .MuiInputBase-root": {
      borderBottom: "0px solid transparent",
    },
  },
  image: {
    width: 50,
    height: 50,
    marginLeft: 15,
  },
}));

export const assignStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    "& .MuiFormLabel-root": {
      fontSize: 11,
    },
    "& .MuiInputBase-input": {
      fontSize: 12,
    },
  },
  image: {
    width: 50,
    height: 50,
    marginLeft: 15,
  },
  input: {
    backgroundColor: "white",
    boxShadow: "none",
    "&:after": {
      borderBottomColor: "black",
      boxShadow: "none",
    },
    "&:hover": {
      backgroundColor: "white",
      boxShadow: "none",
    },
    "&:focus": {
      backgroundColor: "white",
      boxShadow: "none",
    },
  },
}));

export const investigationStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    "& .MuiInputBase-root": {
      borderBottom: "0px solid transparent",
    },
  },
  image: {
    width: 50,
    height: 50,
    marginLeft: 15,
  },
}));

export const mdNotificationStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    "& .MuiInputBase-root": {
      borderBottom: "0px solid transparent",
    },
  },
  image: {
    width: 50,
    height: 50,
    marginLeft: 15,
  },
}));

export const medicationStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    "& .MuiInputBase-root": {
      borderBottom: "0px solid transparent",
    },
  },
  image: {
    width: 50,
    height: 50,
    marginLeft: 15,
  },
}));

export const precautionsStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    "& .MuiInputBase-root": {
      borderBottom: "0px solid transparent",
    },
  },
  image: {
    width: 50,
    height: 50,
    marginLeft: 15,
  },
}));
