/*eslint-disable*/
import React, { useState, useEffect, useReducer } from "react";
// import "./staffStyles.js";
import Button from "@material-ui/core/Button";
import Notification from "../../../components/Snackbar/Notification.js";
import CustomTable from "../../../components/Table/Table";
import Loader from "react-loader-spinner";
import Header from "../../../components/Header/Header";
import anesIcon from "../../../assets/img/Anesthesiologists.png";
import Back from "../../../assets/img/Back_Arrow.png";
import { makeStyles } from "@material-ui/core/styles";
import { assignPatientStyles } from "../../CustomerCare/styles";
import "../../../assets/jss/material-dashboard-react/components/loaderStyle.css";
import cookie from "react-cookies";

// import getTimeFromDate from "../../utils/extractTimeFromDate";

import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

import useMediaQuery from "@material-ui/core/useMediaQuery";
import AccountCircle from "@material-ui/icons/SearchOutlined";

import Chip from "@material-ui/core/Chip";
import InputAdornment from "@material-ui/core/InputAdornment";

import DateFnsUtils from "@date-io/date-fns";
import {
  DatePicker,
  MuiPickersUtilsProvider,
  TimePicker,
} from "@material-ui/pickers";
import BarCode from "../../../assets/img/Bar Code.png";

import Fingerprint from "../../../assets/img/fingerprint.png";
// import Filter from "../../../../assets/img/Filter.png";

import {
  getListAnesthesiologist,
  getSearchAnesthesiologist,
} from "../../UserManagement/staff/networkCallsForStaff";

import TextField from "@material-ui/core/TextField";
const useStyles = makeStyles((theme) => ({
  input: {
    "&:focus": {
      backgroundColor: "white",
      boxShadow: "none",
      borderRadius: 5,
    },
  },
  root: {
    "& .Mui-focused": {
      backgroundColor: "white",
      color: "black",
      boxShadow: "none",
    },
    "&:focus": {
      backgroundColor: "white",
      boxShadow: "none",
    },
    "& .MuiFormLabel-root": {
      fontSize: "10px",
      paddingRight: "45px",
    },
  },
}));

const styles = {
  stylesForButton: {
    color: "white",
    cursor: "pointer",
    // borderRadius: 5,
    background: "#2c6ddd",
    // width: "140px",
    height: "50px",
    outline: "none",
  },
};

const tableHeadingForDoctors = [
  "Anesthesiologists ID",
  "Anesthesiologists Name",
  "Production Area",
];
const tableHeadingForShift = [
  "Anesthesiologists ID",
  "Anesthesiologists Name",
  "Shift",
  "Production Area",
];
const tableDataKeysForShift = ["Id", "name", "shift", "productionArea"];
const tableDataKeysForDoctors = ["Id", "name", "productionArea"];

const actions = { view: false };

export default function Staff(props) {
  const classes1 = useStyles();
  const classes = assignPatientStyles();
  const matches = useMediaQuery("(min-width:600px)");
  const [staffData, setStaffData] = useState([]);
  const [generalArray, setGeneralArray] = useState([]);
  const [filteredArray, setFilteredArray] = useState([]);

  const [changeKeyHeading, setChangeKeyHeading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [openNotification, setOpenNotification] = useState(false);

  const [searchPatientQuery, setSearchPatientQuery] = useState("");

  if (openNotification) {
    setTimeout(() => {
      setOpenNotification(false);
      setErrorMsg("");
    }, 2000);
  }

  useEffect(() => {
    if (
      props.history.location.pathname ===
      "/dashboard/home/secondaryroles/anesthesiologists/shiftList"
    ) {
      setChangeKeyHeading(true);
    }

    getListAnesthesiologist(
      setStaffData,
      setGeneralArray,
      setOpenNotification,
      setErrorMsg
    );
  }, []);

  // const handlePatientSearch = (e) => {
  //   const a = e.target.value.replace(/[^\w\s]/gi, "");
  //   setSearchPatientQuery(a);
  //   if (a.length >= 3) {
  //     axios
  //       .get(searchEdrWithCareStream + "/" + a)
  //       .then((res) => {
  //         console.log("res", res);
  //         if (res.data.success) {
  //           if (res.data.data) {
  //             res.data.data.map(
  //               (d) =>
  //                 (d.patientName =
  //                   d.patientId.name[0].given[0] +
  //                   " " +
  //                   d.patientId.name[0].family)
  //             );
  //             res.data.data.map((d) => (d.createdAt = d.patientId.createdAt));
  //             res.data.data.map(
  //               (d) => (d.mrn = d.patientId.identifier[0].value)
  //             );
  //             res.data.data.map(
  //               (d) =>
  //                 (d.productionArea =
  //                   d.chiefComplaint[
  //                     d.chiefComplaint.length - 1
  //                   ].chiefComplaintId.productionArea[
  //                     d.chiefComplaint[d.chiefComplaint.length - 1]
  //                       .chiefComplaintId.productionArea.length - 1
  //                   ].productionAreaId.paName)
  //             );
  //             // res.data.data.map(
  //             //   (v) =>
  //             //     (v.careStream =
  //             //       v.careStream &&
  //             //       v.careStream[v.careStream.length - 1].careStreamId &&
  //             //       v.careStream[v.careStream.length - 1].careStreamId.name)
  //             // )
  //             res.data.data.map(
  //               (v) =>
  //                 (v.careStream =
  //                   v.careStream &&
  //                   v.careStream.length > 0 &&
  //                   v.careStream[v.careStream.length - 1].careStreamId.name)
  //             );
  //             var sortedObjs = _.sortBy(res.data.data, "createdAt").reverse();
  //             setlabInPatient(sortedObjs);
  //             // var sortedObjs = _.sortBy(res.data.data, 'date').reverse()
  //             // setlabInPatient(res.data.data)
  //           } else {
  //             setlabInPatient(" ");
  //           }
  //         }
  //       })
  //       .catch((e) => {
  //         console.log("error after searching patient request", e);
  //       });
  //   } else if (a.length == 0) {
  //     getPatientData();
  //   }
  // };

  // function setValuesInData(generalArray) {
  //   let anesData = generalArray.map((d) => {
  //     let obj = {
  //       ...d,
  //       productionArea: d.chiefComplaint[d.chiefComplaint.length - 1]
  //         ? d.chiefComplaint[d.chiefComplaint.length - 1].chiefComplaintId
  //             .productionArea[0].productionAreaId.paName
  //         : "N/A",
  //       Id: d.identifier[0].value,
  //       name: d.name[0].given[0] + " " + d.name[0].family,
  //     };
  //     return obj;
  //   });

  //   setStaffData([...anesData.reverse()]);
  // }

  // useEffect(() => {
  //   setValuesInData(generalArray);
  // }, [generalArray]);

  const handlePatientSearch = (e) => {
    const a = e.target.value.replace(/[^\w\s]/gi, "");
    setSearchPatientQuery(a);
    if (a.length >= 3) {
      getSearchAnesthesiologist(
        a,
        setStaffData,
        setErrorMsg,
        setOpenNotification
      );
    } else if (a.length == 0) {
      getListAnesthesiologist(
        setStaffData,
        setGeneralArray,
        setOpenNotification,
        setErrorMsg
      );
      // <h6>Not Found your search</h6>;
    }
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        position: "fixed",
        width: "100%",
        height: "100%",
        backgroundColor: "#60d69f",
        overflowY: "scroll",
      }}
    >
      <Header history={props.history} />

      <div className="cPadding">
        <div className="subheader">
          <div
            style={{
              display: "flex",
              flex: 1,
            }}
          >
            <img src={anesIcon} />
            {!changeKeyHeading ? (
              <h4>Anesthesiologists List</h4>
            ) : (
              <h4>Anesthesiologists Shift List</h4>
            )}
          </div>
        </div>

        <div
          style={{
            flex: 4,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            className={`${"container-fluid"}`}
            style={{
              marginTop: "25px",
              paddingLeft: "10px",
              paddingRight: "10px",
            }}
          >
            <div className={`row  ${classes1.root} ${classes1.input} `}>
              <div
                className="col-md-12 "
                style={{ paddingTop: 5, paddingLeft: 3, paddingRight: 3 }}
              >
                <TextField
                  className="textInputStyle"
                  id="searchPatientQuery"
                  type="text"
                  variant="filled"
                  label={
                    matches
                      ? "Search Anesthesiologist by Name / MRN / National ID / Mobile No"
                      : "Search Anesthesiologist by Name / MRN"
                  }
                  name={"searchPatientQuery"}
                  value={searchPatientQuery}
                  onChange={handlePatientSearch}
                  InputLabelProps={{
                    classes: {
                      root: classes.label,
                      focused: classes.focusedLabel,
                      error: classes.erroredLabel,
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <AccountCircle />
                      </InputAdornment>
                    ),
                    className: classes.input,
                    classes: { input: classes.input },
                    disableUnderline: true,
                  }}
                />
              </div>

              {/* <div
                className="col-md-1 col-sm-1 col-2"
                style={{
                  ...styles.textFieldPadding,
                  paddingTop: 5,
                  paddingLeft: 3,
                  paddingRight: 3,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "white",
                    borderRadius: 5,
                    height: 55,
                  }}
                >
                  <img src={BarCode} style={{ width: 70, height: 60 }} />
                </div>
              </div> */}

              {/* <div
                className="col-md-1 col-sm-1 col-2"
                style={{
                  ...styles.textFieldPadding,
                  paddingTop: 5,
                  paddingLeft: 3,
                  paddingRight: 3,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "white",
                    borderRadius: 5,
                    height: 55,
                  }}
                >
                  <img src={Fingerprint} style={{ maxWidth: 43, height: 43 }} />
                </div>
              </div> */}
            </div>
          </div>
          {staffData && !changeKeyHeading ? (
            <div className="container-fluid">
              <div className="row">
                <CustomTable
                  tableData={staffData}
                  tableDataKeys={tableDataKeysForDoctors}
                  tableHeading={tableHeadingForDoctors}
                  action={""}
                  borderBottomColor={"#60d69f"}
                  borderBottomWidth={20}
                />

                <Notification msg={errorMsg} open={openNotification} />
              </div>
            </div>
          ) : staffData && changeKeyHeading ? (
            <div className="container-fluid">
              <div className="row">
                <CustomTable
                  tableData={staffData}
                  tableDataKeys={tableDataKeysForShift}
                  tableHeading={tableHeadingForShift}
                  action={""}
                  borderBottomColor={"#60d69f"}
                  borderBottomWidth={20}
                />

                <Notification msg={errorMsg} open={openNotification} />
              </div>
            </div>
          ) : (
            <div className="LoaderStyle">
              <Loader type="TailSpin" color="red" height={50} width={50} />
            </div>
          )}

          <div className="container-fluid">
            <div className="row" style={{ marginBottom: 20 }}>
              <img
                onClick={() => props.history.goBack()}
                src={Back}
                style={{
                  width: 45,
                  height: 35,
                  cursor: "pointer",
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
