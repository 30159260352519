import React, { useState, useEffect, useReducer } from "react";
import axios from "axios";
import {
  getSingleEdr, nurseTechnicianQRView, uploadsUrl
} from "../../public/endpoins";
import moment from 'moment';
import { displayInHumanDifference, replacePatientIdIfNull } from "../../utils/commonFunc";
import cookie from "react-cookies";

function PrintQRAll(props) {

  const [nameDisplay, setNameDisplay] = useState(true);

  const [currentUser] = useState(cookie.load("current_user"));
  const [patientDetails, setPatientDetails] = useState("");
  const [selectedRecord, setSelectedRecord] = useState({});

  const [ episodeId, setEpisodeId ] = useState(props.match.params.id);
  const [ labRequestId, setLabRequestId ] = useState(props.match.params.requestId);
  const [ labServiceId, setLabServiceId ] = useState(props.match.params.serviceId);
  const [ labRequest, setLabRequest ] = useState({});
  const [ labServices, setLabServices ] = useState([]);
  const [ labService, setLabService ] = useState({});
  const [ status, setStatus ] = useState("");
  const [ serviceIds, setServiceIds ] = useState({});

  const initialState = {
    qrData: {},
  };
  function reducer(state, { field, value }) {
    return {
      ...state,
      [field]: value,
    };
  }
  const [state, dispatch] = useReducer(reducer, initialState);
  const {
    qrData,
  } = state;

  

  useEffect(() => {
    // console.log("Props passed ", props.history.location.state.selectedItem);
    let edrId = "";

    axios
      .get(getSingleEdr + "/" + episodeId)
      .then((res) => {
        console.log('handleView::', res.data.data);
        if (res.data.success) {

          let remoteRecord = replacePatientIdIfNull(res.data.data);
          setSelectedRecord(remoteRecord);

          const object = {
            '_id': remoteRecord._id,
            patientName: remoteRecord.patientId.name[0].given[0] + " " + remoteRecord.patientId.name[0].family,
            episodeNumber: remoteRecord.requestNo,//remoteRecord.patientId.identifier[0].value,
            mrn: remoteRecord.patientId.identifier[0].value,
            birthDate: remoteRecord.patientId.birthDate,
            age: remoteRecord.patientId.age,
            gender: remoteRecord.patientId.gender,
            weight: remoteRecord.patientId.weight,
            department: remoteRecord.currentLocation,
            chiefComplaint: remoteRecord.chiefComplaint.length > 0 ? remoteRecord.chiefComplaint.pop().chiefComplaintId.short_desc : "N/A",
            chiefComplaintOthers: remoteRecord.chiefComplaintOthers,
          };
          setPatientDetails(object);

          let labServicesX = remoteRecord.labRequest.filter(obj => obj.requestId === labRequestId);
          console.log('labServicesX', labServicesX, labServicesX.length);
          if (labServicesX.length > 0){
            let labXN = labServicesX[0];
            labXN.requestedName = labXN.requestedBy.name[0].given[0] + " " + labXN.requestedBy.name[0].family;
            console.log('labServicesX', labXN.requestedBy.name);
            console.log('labServicesX', labXN);
            setLabRequest(labXN)
            // setLabRequest(labServicesX[0])
            setLabServices(labServicesX[0].services);


            // 
            Object.entries(labServicesX[0].services).map(([ix, iv]) => {
              //
              console.log('Bothiya::QR', iv);
              
              axios
                .get(`${nurseTechnicianQRView}/?labRequest=${iv.requestId}`, {
                  edrId: edrId
                })
                .then((res) => {
                  let qrCodes = qrData;
                  qrCodes[res.data.labRequestId.replace('-','')] = res.data.qr
                  dispatch({ field: 'qrData', value: qrCodes });
                })
                .catch((e) => {
                  console.log("QRP error: ", e);
                });
            });

            console.log('Bothiya::QR', qrData);



            /*
            let labServiceX = labXN.services.filter(obj => obj.requestId === labServiceId);
            labServiceX= JSON.parse(JSON.stringify(labServiceX[0]));

            setLabService(labServiceX);
            setStatus(labServiceX.nurseTechnicianStatus);
            console.log(`${nurseTechnicianQRView}/?labRequest=${labServiceId}`, `${labServiceX.serviceId.colorCode}`);
            setServiceIds(labServiceX.serviceId);

            axios
              .get(`${nurseTechnicianQRView}/?labRequest=${labServiceId}`, {
                edrId: edrId
              })
              .then((res) => {
                console.log("QRP", res.data.qr);
                setQrData(res.data.qr);
              })
              .catch((e) => {
                console.log("QRP error: ", e);
              });

            console.log('labServicesX::labServiceX', labServiceX[0]);
            */
          }

        }
      })
      .catch((e) => {
        console.log("error after searching patient request", e);
      });


    if (edrId){
      axios
        .get(`${getSingleEdr}/${edrId}`)
        .then((res) => {
          let dataReturn = replacePatientIdIfNull(res.data.data);
          setPatientDetails(dataReturn);
          return res;
        })
        .catch((e) => {
          console.log("QRP error: ", e);
        });
    }



    // const { selectedItem } = props.history.location.state;
    
  }, []);

  return (
    <div
      style={{
        position: "fixed",
        display: "flex",
        width: "100%",
        height: "100%",
        flexDirection: "column",
        flex: 1,
        overflowY: "scroll",
        paddingBottom: 50,
      }}
    >

      {
        patientDetails ? (
          Object.entries(labServices).map(([ix, iv]) => {
            return ix == 0 ? <>
              <div className="d-flex align-items-center">
                <img src={qrData[iv.requestId.replace('-','')]} width="120" />
                <div>
                  {
                    nameDisplay?
                    <>
                      <strong>Name:</strong> { patientDetails.patientName }<br />
                      <strong>Date of Birth:</strong> { moment(patientDetails.birthDate).format('MMM DD, YYYY') }<br />
                      <strong>MRN:</strong> { patientDetails.mrn }<br />
                    </>:undefined
                  }
                  <strong>Episode:</strong> {patientDetails.episodeNumber.toString()}
                </div>
              </div>
              {/*
              <div style={{
                paddingLeft: 20
              }}>
                Request # { labRequestId }
                <br />
                <strong>{ iv.category }: { iv.name }</strong>
              </div>
              */}
              <hr />
            </>:undefined
          })
        ):undefined
      }
    </div>
  );
}
export default PrintQRAll;
