import React from "react";
import { Route, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";
import NotFound from "../components/NotFound/NotFound";
// Routes

import paramedicsSubMenu from "../views/Home/AdminSubMenus/PrimaryRoles/paramedicsSubMenu";

import paramedicsListing from "../views/ParamedicsListing/paramedicsListing";
import paramedicsPatients from "../views/ParamedicsListing/paramedicsPatients";
import paramedicsCriticalCases from "../views/ParamedicsListing/paramedicsCriticalCases";

class HomeScreenRoutes extends React.PureComponent {
  render() {
    return (
      <Switch>
        <Route
          exact
          path={`${this.props.match.url}`}
          component={paramedicsSubMenu}
        />

        <Route
          exact
          path={`${this.props.match.url}/all`}
          component={paramedicsListing}
        />

        <Route
          exact
          path={`${this.props.match.url}/paramedicspatients`}
          component={paramedicsPatients}
        />

        <Route
          exact
          path={`${this.props.match.url}/criticalcases`}
          component={paramedicsCriticalCases}
        />

        <Route path="*" component={NotFound} />
      </Switch>
    );
  }
}
export default HomeScreenRoutes;
