import React from 'react'
import { Route, Switch } from 'react-router-dom'
import NotFound from '../components/NotFound/NotFound'
import ChiefComplaints from '../views/Home/AdminSubMenus/ChiefComplaints/chiefComplaints'
import Categories from '../views/ChiefComplaints/categories'
import CCproductionArea from '../views/ChiefComplaints/assignPAtoCC'
import CCdoctors from '../views/ChiefComplaints/assignCCtoDoctors'
import CCNurses from '../views/ChiefComplaints/assignCCtoNurses'

class ChiefComplaintsRoutes extends React.PureComponent {
  render() {
    return (
      <Switch>
        <Route
          exact
          path={`${this.props.match.url}`}
          component={ChiefComplaints}
        />

        <Route
          exact
          path={`${this.props.match.url}/categories`}
          component={Categories}
        />

        <Route
          exact
          path={`${this.props.match.url}/ccProductionArea`}
          component={CCproductionArea}
        />

        <Route path={`${this.props.match.url}/notfound`} component={NotFound} />

        <Route path='*' component={NotFound} />
      </Switch>
    )
  }
}
export default ChiefComplaintsRoutes
