import { getSingleEdr, submitDCDformURL, getEdrsByPatient } from "../../public/endpoins"
import axios from "axios"

export const getDcdForm = (edrId, setDcdForm) => {
  axios
    .get(`${getSingleEdr}/${edrId}`)
    .then((response) => {
      console.log("Response DCD", response.data.data.dcdForm)
      setDcdForm(response.data.data.dcdForm)
    })
    .catch((error) => {
      console.log("Error", error)
    })
}

export const getPatientEdrForm = (edrId, setPatientEdrForm) => {
  console.log("patientEdrForm", `${getSingleEdr}/${edrId}`)
  axios
    .get(`${getSingleEdr}/${edrId}`)
    .then((response) => {
      console.log("Response DCD", response.data.data)
      setPatientEdrForm(response.data.data)
    })
    .catch((error) => {
      console.log("Error", error)
    })
}

export const updateDCDformStatus = (params, dispatch) => {
  axios
    .put(submitDCDformURL, params)
    .then((response) => {
      console.log("response", response);
      if (response.data.success) {
        dispatch({ field: 'openNotification', value: true })
        dispatch({ field: 'successMsg', value: 'Form Submitted Successfully' })
      }
    })
    .catch((e) => {
      console.log("Error", e);
      dispatch({ field: 'openNotification', value: true })
      dispatch({ field: 'successMsg', value: 'Cannot submit the form at the moment' })
    });
}
