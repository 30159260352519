import React, { useEffect, useState, useReducer } from "react";
import { withStyles } from '@material-ui/core/styles';
import Header from "../../components/Header/Header";
import classNames from "classnames";
import Back from "../../assets/img/Back_Arrow.png";
import { 
  Button, 
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment,
  MenuItem,
  TextField,
  Select,
  useMediaQuery,
} from "@material-ui/core";
import cookie from "react-cookies";

import axios from "axios";
import {
  audioURL,
  getLabServiceCategory,
  addLabRequestBulk,
  getSingleEdr
} from "../../public/endpoins";

import useStylesForInput from "../../assets/jss/material-dashboard-react/inputStyle";
import styles from "./styles";
import Loader from "react-loader-spinner";
import {
  Checkbox, 
  FormLabel, 
  FormControl, 
  FormGroup, 
  FormControlLabel,
  RadioGroup, 
  Radio
} from '@material-ui/core';
import {
  priorityArray,
  statusArray,
} from "./configForNotesScreen";
import Icon from '@material-ui/core/Icon';
import CloseIcon from '@material-ui/icons/Close';
import MicIcon from "@material-ui/icons/Mic";
import StopIcon from "@material-ui/icons/Stop";
import MicRecorder from "mic-recorder-to-mp3";
import AccountCircle from "@material-ui/icons/SearchOutlined";
import Notification from "../../components/Snackbar/Notification.js";

import LabIcon from "../../assets/img/Lab.png";
import { displayInHumanDifference, replacePatientIdIfNull } from "../../utils/commonFunc";
import PatientDetailsQuick from "../../components/PatientDetails/PatientDetailsQuick";
import PatientDetails from "../../components/PatientDetails/PatientDetails";
import PatientDetails2 from "../../components/PatientDetails/PatientDetailsDirect";

export default function LabRequestsDetailPage(props) {
  
  const matches = useMediaQuery("(min-width:600px)");
  const classes = useStylesForInput();

  const mystyle = {
    color: "white",
    backgroundColor: "DodgerBlue",
    padding: "10px",
    fontFamily: "Arial"
  };

  const GreenCheckbox = withStyles({
    root: {
      color: '#AAAAAA',
      '&$checked': {
        color: '#AAAAAA',
      },
    },
    checked: {
      color: '#2C6DDD',
      '&$checked': {
        color: '#2C6DDD',
      },
    },
  })((props) => <Checkbox color="default" {...props} />);

  const GreenRadio = withStyles({
    root: {
      color: '#AAAAAA',
      '&$checked': {
        color: '#AAAAAA',
      },
    },
    checked: {
      color: '#2C6DDD',
      '&$checked': {
        color: '#2C6DDD',
      },
    },
  })((props) => <Radio color="default" {...props} />);

  


  const initialState = {
    bulkTestSelected: false,
    resetedOn: "",
    defaultItems: {},
    seletedLabItems: [],
    labPriority: "",
    labTechnician: "",
    labComments: "",
    labTechniciansList: [],
  };
  function reducer(state, { field, value }) {
    return {
      ...state,
      [field]: value,
    };
  }
  const [state, dispatch] = useReducer(reducer, initialState);
  const {
    bulkTestSelected,
    resetedOn,
    defaultItems,
    seletedLabItems,
    labPriority,
    labTechnician,
    labComments,
    labTechniciansList,
  } = state;

  const handleCheckupChange = (event) => {
    dispatch({field: 'bulkTestSelected', value: event.target.value});
  };

  const uniqueCodeField = (name) => {
    name = name.toLowerCase();
    name = name.replace(/[^a-z0-9]+/g, '-');
    name = name.replace(/^-+|-+$/g, '');
    return name;
  }

  const [currentUser] = useState(cookie.load("current_user"));
  const [timer, setTimer] = useState(null);
  const [selectedLab, setSelectedLab] = useState("");
  const [categoriesData, setCategoriesData] = useState([]);
  const [mItems, setMItems] = useState([]);

  const [patientDetails, setPatientDetails] = useState("");
  const [selectedRecord, setSelectedRecord] = useState({});

  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setsuccessMsg] = useState("");
  const [openNotification, setOpenNotification] = useState(false);

  const [ episodeId, setEpisodeId ] = useState(props.match.params.id);
  const [ labRequestId, setLabRequestId ] = useState(props.match.params.requestId);
  const [ labRequest, setLabRequest ] = useState({});
  const [ labServices, setLabServices ] = useState([]);
  const [ serviceType, setServiceType ] = useState(props.match.params.serviceType);
  const [ isUrgent, setIsUrgent ] = useState(false);

  if (openNotification) {
    setTimeout(() => {
      setOpenNotification(false);
      setErrorMsg("");
      setsuccessMsg("");
    }, 4000);
  }

  useEffect(() => {
    axios
      .get(getSingleEdr + "/" + episodeId)
      .then((res) => {
        console.log('handleView::', res.data.data);
        if (res.data.success) {

          let remoteRecord = replacePatientIdIfNull(res.data.data);
          setSelectedRecord(remoteRecord);

          const object = {
            '_id': remoteRecord._id,
            patientName: remoteRecord.patientId.name[0].given[0] + " " + remoteRecord.patientId.name[0].family,
            mrn: remoteRecord.requestNo,//remoteRecord.patientId.identifier[0].value,
            age: remoteRecord.patientId.age,
            gender: remoteRecord.patientId.gender,
            weight: remoteRecord.patientId.weight,
            department: remoteRecord.currentLocation,
            chiefComplaint: remoteRecord.chiefComplaint.length > 0 ? remoteRecord.chiefComplaint.pop().chiefComplaintId.short_desc : "N/A",
            chiefComplaintOthers: remoteRecord.chiefComplaintOthers,
          };
          setPatientDetails(object);

          if (serviceType === "lab"){
            let labServicesX = remoteRecord.labRequest.filter(obj => obj.requestId === labRequestId);
            console.log('labServicesX', labServicesX);
            if (labServicesX.length > 0){
              setIsUrgent(labServicesX[0].urgent==="Yes"?true:false)
              setLabRequest(labServicesX[0])
              setLabServices(labServicesX[0].services);
            }
          }else{
            let labServicesX = remoteRecord.radRequest.filter(obj => obj.requestId === labRequestId);
            console.log('labServicesX', labServicesX);
            if (labServicesX.length > 0){
              setIsUrgent(labServicesX[0].urgent==="Yes"?true:false)
              setLabRequest(labServicesX[0])
              setLabServices(labServicesX[0].services);
            }
          }
          

        }
      })
      .catch((e) => {
        console.log("error after searching patient request", e);
      });
    /*getLabTechniciansCall( currentUser._id, dispatch, setOpenNotification, setErrorMsg );*/
  }, []);

  return (
    
    <div
      style={{
        backgroundColor: "rgb(19 213 159)",
        position: "fixed",
        display: "flex",
        width: "100%",
        height: "100%",
        flexDirection: "column",
        flex: 1,
        overflowY: "scroll",
      }}
    >
      <Header history={props.history} />

      <PatientDetailsQuick
        edrId={episodeId} />

      <div className="cPadding">
        <div className="subheader">
          <div>
            <img src={LabIcon} />
            <h4>{patientDetails.patientName}</h4>
          </div>
        </div>

        {patientDetails ? (
          <div className="mb-3" style={{ marginLeft: "-15px", marginRight: "-15px" }}>
          <PatientDetails2
            edrId={patientDetails._id}
            showChiefComplaint={true}
            showTriageLevel={true}
            showCareStream={true}
            showDiagnosis={true} />
          </div>
        ) : (
          undefined
        )}

        <h4 className="h6 text-white mb-3"
          style={{
            fontWeight: '700'
          }}>{labRequestId} Service Details
          { 
            isUrgent?<span className="badge badge-warning pull-right">URGENT</span>:undefined
          }
        </h4>
        <div className="row row-5">
          {
            labServices ? 
            Object.entries(labServices).map((obj, entry) => {
              return (
                <div className="col-12 col-md-6 col-lg-6 col-xl-4">
                  <div className="card card-detail-info mb-2">
                    <div className="row row-5">
                      {/*
                      <div className="col-sm-6 card-detail-block block-xdiv mb-2"><strong className="lblX">Request ID</strong><span className="valX">{obj[1].requestId}</span></div>
                      */}
                      <div className="col-sm-12">&nbsp;</div>
                      <div className="col-sm-12 card-detail-block block-xdiv"><strong className="lblX">Profile/Test</strong><span className="valX">{obj[1].category}: <span>{obj[1].name}</span></span></div>
                      <div className="col-sm-12 card-detail-block block-xdiv mt-1"><strong className="lblX">Notes</strong><span className="valX"><span>{(obj[1].notes !== "")?obj[1].notes:'N/A'}</span></span></div>
                      <div className="col-sm-12">
                        <audio
                          style={{
                            marginRight: 10,
                            width: matches ? '100%' : 260,
                            marginTop: 6,
                          }}
                          src={ `${audioURL}/${obj[1].audio}` }
                          controlsList="nodownload"
                          controls="controls"
                          />
                      </div>
                    </div>
                    <div className="card-status-action">
                      {/*
                        serviceType === "rad"?
                        <span className="badge badge-light">RCM {(obj[1].insuranceStatus==="Selfpay" || obj[1].insuranceStatus==="Covered" || obj[1].insuranceStatus==="Approved")?"Approved":obj[1].insuranceStatus}</span>:undefined
                      */}
                      <span className={classNames(`badge`, ((obj[1].insuranceStatus=="Approved" || obj[1].insuranceStatus=="Covered" || obj[1].insuranceStatus=="Selfpay")?'badge-success':'status-pending'))}>{(obj[1].insuranceStatus=="Approved" || obj[1].insuranceStatus=="Covered" || obj[1].insuranceStatus=="Selfpay")?"RCM Approved":`RCM ${obj[1].insuranceStatus}`}</span>
                      {obj[1].priority==="High"?<span className="badge badge-danger">High</span>:undefined}
                      <span className={classNames(`badge`, `status-`+obj[1].status)}>{obj[1].status}</span>
                      {/* 
                        obj[1].urgent==="Yes"?<span className="ml-info">!</span>:undefined
                        <i className="ml-view ml-10 zmdi zmdi-eye zmdi-hc-2x" onClick={() => {
                          window.alert(JSON.stringify(obj[1]));
                        }} />
                      */}
                      {
                        serviceType === "rad"?
                        <span className={classNames(`badge`, `status-`+obj[1].radiologyDoctorStatus)}>{obj[1].radiologyDoctorStatus==='completed'?'RAD Doctor Reported':'Pending Doctor Report'}</span>:undefined
                      }
                    </div>
                  </div>
                </div>
              );
            }):undefined
          }
        </div>
        <div className="row row-5">
          {
            categoriesData ? 
              categoriesData.map((d, index) => {
                return (
                  <div className="col-12 col-md-3 text-center">
                    <div className="mb-3">
                      <button className="pl-2 pr-2 btn btn-block bg-white dropdown-toggle" 
                        type="button" id={ "dropdownMenuButton-" + (index + 1) } 
                        style={{ height: 65 }}
                        aria-haspopup="true" aria-expanded="false">
                        <span style={{ ...styles.textStyles, "white-space": "initial" }}>{ d._id }</span>
                      </button>
                      <div aria-labelledby={ "dropdownMenuButton-" + (index + 1) }>
                        <div style={{ height: 160, overflowY: "scroll"}}>
                        {
                         d.items.length > 0 ? 
                            d.items.map((nd, nindex) => {
                              return (
                                <div className="dropdown-items pl-3 pr-3 d-flex align-items-center justify-content-between">
                                  <FormControlLabel
                                    control={<GreenCheckbox value={ nd._id } name={ uniqueCodeField(d._id) } />}
                                    label={nd.name}
                                    style={{
                                      margin: 0,
                                    }}
                                  />
                                  
                                </div>
                              )
                            }) : ''
                        }
                        </div>
                        <div className="p-3">
                          <div className="row row-5">
                            <div className="col-6">
                              <strong>Priority:</strong> 
                              <FormControl variant="filled" className="mServiceDropdown" InputProps={{ disableUnderline: true }}>
                                <Select
                                  name={"labPriority"}
                                  InputProps={{ disableUnderline: true }}
                                  onChange={(e) => {
                                    
                                  }}
                                >
                                  <MenuItem value=""><em>None</em></MenuItem>
                                  {priorityArray.map((val) => {
                                    return (
                                      <MenuItem key={val.key} value={val.key}>
                                        {val.value}
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                              </FormControl>
                            </div>
                            <div className="col-6 text-end text-right">
                              <strong>X-Urgent:</strong> 
                              <FormControl variant="filled" className="mServiceDropdown" InputProps={{ disableUnderline: true }}>
                                <Select
                                  name={"labPriority"}
                                  InputProps={{ disableUnderline: true }}
                                  onChange={(e) => {
                                  }}
                                >
                                  <MenuItem value="No" key="No">No</MenuItem>
                                  <MenuItem value="Yes" key="Yes">Yes</MenuItem>
                                </Select>
                              </FormControl>
                            </div>
                          </div>
                        </div>
                        <div className="p-2 bg-light">
                          <Button
                            color="primary"
                            size="small"
                            variant="contained"
                            fullWidth="true"
                          >Add Selected</Button>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })
              : ''
          }
        </div>
        <div
          className="container-fluid"
          style={{ marginBottom: "25px", marginTop: "25px" }}
        >
          <div className="row">
            <img
              onClick={() => props.history.goBack()}
              src={Back}
              style={{ width: 45, height: 35, cursor: "pointer" }}
            />
          </div>
        </div>
      </div>


      <Notification
        msg={errorMsg}
        open={openNotification}
        success={successMsg}
      />
    </div>
  );
}