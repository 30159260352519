import React, { useEffect, useState } from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Flip from "../../../components/Dial/Flip";
import TwoValue from "../../../components/Dial/TwoValue";
import OneValue from "../../../components/Dial/OneValue";
import { getReportsRadDoctor } from "./NetworkCallForRad";
import { socketUrl } from "../../../public/endpoins";
import socketIOClient from "socket.io-client";

export default function RadTechnician() {
  const matches = useMediaQuery("(min-width:600px)");
  const [flip, setFlip] = useState(false);

  //First Card Hooks
  const [totalPending, setTotalPending] = useState("");
  const [totalPendingColor, setTotalPendingColor] = useState("");
  const [pendingTat, setPendingTat] = useState("");
  const [pendingTATColor, setPendingTATColor] = useState("");
  const [graphPending, setGraphPending] = useState([]);
  //2nd Card
  const [secondTotalPending, set2ndTotalPending] = useState("");
  const [secondTotalPendingColor, set2ndTotalPendingColor] = useState("");
  const [secondPendingTat, set2ndPendingTat] = useState("");
  const [secondPendingTATColor, set2ndPendingTATColor] = useState("");
  const [secondGraphPending, set2ndGraphPending] = useState([]);
  //3rd Card
  const [cumulativeNotes, setCumulativeNotes] = useState("");
  const [cumulativeNotesColor, setCumulativeNotesColor] = useState("");

  const [flag1Count, setFlag1Count] = useState(0);
  const [flag2Count, setFlag2Count] = useState(0);

  useEffect(() => {
    getReportsRadDoctor(
      setTotalPending,
      setTotalPendingColor,
      setPendingTat,
      setPendingTATColor,
      setGraphPending,
      set2ndTotalPending,
      set2ndTotalPendingColor,
      set2ndPendingTat,
      set2ndPendingTATColor,
      set2ndGraphPending,
      setCumulativeNotes,
      setCumulativeNotesColor
    );

    const flagCount = [
      { value: "1st", setFlagCount: (value) => setFlag1Count(value) },
    ];

    const socket = socketIOClient(socketUrl);
    socket.emit("radDoctor_flags");
    socket.on("radDoctorPending", (data) => {
      console.log("response coming through socket for Rad Doctor Flags", data);
      flagCount.forEach(({ value, setFlagCount }) => {
        const cardCount = data.filter((item) => item.card === value).length;
        cardCount && setFlagCount(cardCount);
      });

      console.log("data", data);
    });
  }, []);

  const flipIt = () => {
    setFlip(true);
  };
  return (
    <div className="container-fluid" style={{ marginBottom: 10 }}>
      <div className="row">
        <div
          className="col-md-4"
          style={{ paddingLeft: 4, paddingRight: 4, marginTop: 10 }}
        >
          <div>
            <Flip
              flipped={!flip}
              flipHandler={flipIt}
              mainHeading={"Rad Consultation Notes Pending"}
              value={
                totalPending === 0 || totalPending === null ? "0" : totalPending
              }
              flagsCount={flag1Count}
              mainHeadingForGraph="Rad Consultation Notes Pending"
              color={totalPendingColor}
              subHeading={"TAT"}
              childHeading={"Rad Imaging results to Consultation Notes"}
              time={pendingTat === null || pendingTat === 0 ? "0" : pendingTat}
              data={graphPending}
              timecolor={pendingTATColor}
            />
          </div>
        </div>

        <div
          className="col-md-4"
          style={{ paddingLeft: 4, paddingRight: 4, marginTop: 10 }}
        >
          <div>
            <Flip
              flipped={!flip}
              flipHandler={flipIt}
              mainHeading={"No. of Consultation Notes Per Hour"}
              mainHeadingForGraph={"No. of Consultation Notes Per Hour"}
              value={
                secondTotalPending === 0 || secondTotalPending === null
                  ? "0"
                  : secondTotalPending
              }
              color={secondTotalPendingColor}
              subHeading={"TAT"}
              childHeading={"Per Consultation Note"}
              time={
                secondPendingTat === null || secondPendingTat === 0
                  ? "0"
                  : secondPendingTat
              }
              data={secondGraphPending}
              timecolor={secondPendingTATColor}
            />
          </div>
        </div>

        <div
          className="col-md-4"
          style={{ paddingLeft: 4, paddingRight: 4, marginTop: 10 }}
        >
          <div>
            <OneValue
              time={
                cumulativeNotes === null || cumulativeNotes === 0
                  ? "0"
                  : cumulativeNotes
              }
              colorTime={cumulativeNotesColor}
              heading={"Cumulative total number of Consultation Notes"}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
