import axios from 'axios'
import {
  getPendingRadRequests,
  getCompletedRadRequests,
  getPendingRadRequestsForED,
  getCompletedRadRequestsForED,
} from '../../public/endpoins'

export const getPendingLabReports = (
  staffId,
  setPendingTasks,
  setOpenNotification,
  setErrorMsg
) => {
  axios
    .get(getPendingRadRequests+'/'+staffId)
    .then((res) => {
      if (res.data.success) {
        console.log('Pending Requests , ', res.data.data)
        res.data.data.map(
          (d) => (
            (d.mrn = d.patientId.identifier[0].value),
            (d.patientName =
              d.patientId.name[0].given[0] + ' ' + d.patientId.name[0].family),
            (d.testName = d.radRequest.name),
            (d.status = d.radRequest.status),
            (d.prodArea =
              d.chiefComplaint[
                d.chiefComplaint.length - 1
              ].chiefComplaintId.productionArea[
                d.chiefComplaint[d.chiefComplaint.length - 1].chiefComplaintId
                  .productionArea.length - 1
              ].productionAreaId.paName),
            (d.bedCell =
              d.room &&
              d.room.length > 0 &&
              d.room[d.room.length - 1].roomId.roomNo)
          )
        )
        setPendingTasks(res.data.data.reverse())
      } else if (!res.data.success) {
        setOpenNotification(true)
        setErrorMsg('Error while getting records')
      }
    })
    .catch((e) => {
      setOpenNotification(true)
      setErrorMsg('Error while getting records')
    })
}

export const getCompletedLabReports = (
  staffId,
  setCompletedTasks,
  setOpenNotification,
  setErrorMsg
) => {
  axios
    .get(getCompletedRadRequests + '/' + staffId)
    .then((res) => {
      if (res.data.success) {
        console.log('Completed Requests , ', res.data.data)
        res.data.data.map(
          (d) => (
            (d.mrn = d.patientId.identifier[0].value),
            (d.patientName =
              d.patientId.name[0].given[0] + ' ' + d.patientId.name[0].family),
            (d.testName = d.radRequest.name),
            (d.status = d.radRequest.status),
            (d.prodArea =
              d.chiefComplaint[
                d.chiefComplaint.length - 1
              ].chiefComplaintId.productionArea[
                d.chiefComplaint[d.chiefComplaint.length - 1].chiefComplaintId
                  .productionArea.length - 1
              ].productionAreaId.paName),
            (d.bedCell = d.room[d.room.length - 1].roomId.roomNo)
          )
        )
        setCompletedTasks(res.data.data.reverse())
      } else if (!res.data.success) {
        setOpenNotification(true)
        setErrorMsg('Error while getting records')
      }
    })
    .catch((e) => {
      setOpenNotification(true)
      setErrorMsg('Error while getting records')
    })
}

export const getPendingLabReportsForED = (
  staffId,
  setPendingTasks,
  setOpenNotification,
  setErrorMsg
) => {
  axios
    .get(getPendingRadRequestsForED + '/' + staffId)
    .then((res) => {
      if (res.data.success) {
        console.log('Pending Requests , ', res.data.data)
        res.data.data.map(
          (d) => (
            (d.mrn = d.patientId.identifier[0].value),
            (d.patientName =
              d.patientId.name[0].given[0] + ' ' + d.patientId.name[0].family),
            (d.testName = d.radRequest.name),
            (d.status =
              d.radRequest.status === 'pending approval'
                ? 'pending'
                : d.radRequest.status),
            (d.prodArea =
              d.chiefComplaint[
                d.chiefComplaint.length - 1
              ].chiefComplaintId.productionArea[
                d.chiefComplaint[d.chiefComplaint.length - 1].chiefComplaintId
                  .productionArea.length - 1
              ].productionAreaId.paName),
            (d.bedCell = d.room[d.room.length - 1].roomId.roomNo)
          )
        )
        setPendingTasks(res.data.data.reverse())
      } else if (!res.data.success) {
        setOpenNotification(true)
        setErrorMsg('Error while getting records')
      }
    })
    .catch((e) => {
      setOpenNotification(true)
      setErrorMsg('Error while getting records')
    })
}

export const getCompletedLabReportsForED = (
  staffId,
  setCompletedTasks,
  setOpenNotification,
  setErrorMsg
) => {
  axios
    .get(getCompletedRadRequestsForED + '/' + staffId)
    .then((res) => {
      if (res.data.success) {
        console.log('Completed Requests , ', res.data.data)
        res.data.data.map(
          (d) => (
            (d.mrn = d.patientId.identifier[0].value),
            (d.patientName =
              d.patientId.name[0].given[0] + ' ' + d.patientId.name[0].family),
            (d.testName = d.radRequest.name),
            (d.status = d.radRequest.status),
            (d.prodArea =
              d.chiefComplaint[
                d.chiefComplaint.length - 1
              ].chiefComplaintId.productionArea[
                d.chiefComplaint[d.chiefComplaint.length - 1].chiefComplaintId
                  .productionArea.length - 1
              ].productionAreaId.paName),
            (d.bedCell = d.room[d.room.length - 1].roomId.roomNo)
          )
        )
        setCompletedTasks(res.data.data.reverse())
      } else if (!res.data.success) {
        setOpenNotification(true)
        setErrorMsg('Error while getting records')
      }
    })
    .catch((e) => {
      setOpenNotification(true)
      setErrorMsg('Error while getting records')
    })
}
