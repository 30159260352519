import React from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
// import Notification from 'components/Snackbar/Notification.js';
import AddAlert from "@material-ui/icons/AddAlert";
import { Redirect } from "react-router-dom";
import axios from "axios";
import cookie from "react-cookies";
import { login, getStaffUrl, recordLogin } from "../public/endpoins";
import KHMC_White from "../assets/img/bird.png";
// import KHMC_White from "../assets/img/KHMC Header LOGO.png";
import Influence_white from "../assets/img/bird.png";
import Splash from "./Splash";
import "../assets/jss/material-dashboard-react/components/TextInputStyle.css";
import Loader from "react-loader-spinner";
import Header from "../components/Header/Header";
import { subscribeUser } from "../subscription";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Notification from "../components/Snackbar/Notification";

class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      null_email: false,
      password: "L@ptop123",
      null_password: "",
      tr: false,
      verifiedUser: false,
      msg: "",
      forgetPassword: false,
      splash: true,
      buttonPressed: false,
      current_user: "",
      systemAdmin: "",
      staffType: "",
      staff: "",
      staffUser: "",
      errorMsg: "",
      successMsg: "",
      openNotification: false,
    };
  }

  getStaffTypes(user) {
    axios
      .get(getStaffUrl)
      .then((res) => {
        if (res.data.success) {
          console.log("all staff", res.data.data);

          let userStaff = res.data.data.staff.filter(
            (s) => s._id === user.staffId
          );

          console.log("user staff", userStaff[0]);

          cookie.save("user_staff", userStaff[0], { path: "/" });

          this.setState({
            verifiedUser: true,
            buttonPressed: false,
            current_user: user,
          });
        } else if (!res.data.success) {
          console.log("error", res);
          // setErrorMsg(res.data.error);
          // setOpenNotification(true);
        }
        return res;
      })
      .catch((e) => {
        console.log("error: ", e);
      });
  }

  handleInput(e, key) {
    this.setState({ [key]: e.target.value });
  }

  recordLogin(response) {
    console.log("Handle login record ", response);

    const params = {
      token: response.token,
      userId: response.user._id,
    };
    axios
      .post(recordLogin, params)
      .then((res) => {
        if (res.data.success) {
          console.log("response after recording the login", res.data);
        }
      })
      .catch((e) => {
        console.log("error is ", e);
      });
  }

  handleLogin(e) {
    e.preventDefault();
    if (this.state.email === "" && this.state.password === "") {
      this.setState({ null_email: true, null_password: true });
      this.setState({
        openNotification: true,
        errorMsg: "Enter the email address and password",
      });
    } else if (this.state.email === "") {
      this.setState({ null_email: true });
      this.setState({
        openNotification: true,
        errorMsg: "Enter the valid email address",
      });
    } else if (this.state.password === "") {
      this.setState({ null_password: true });
      this.setState({
        openNotification: true,
        errorMsg: "Enter the password",
      });
    } else {
      var re = /\S+@\S+\.\S+/;

      if (!re.test(this.state.email)) {
        this.setState({ tr: true, msg: "Enter the valid email address" });
      } else {
        const params = {
          email: this.state.email,
          password: this.state.password,
        };

        this.setState({ buttonPressed: true });
        axios
          .post(login, params)
          .then((res) => {
            if (res.data.success) {
              console.log("full response", res.data.data.user);
              // this.getStaffTypes(res.data.data.user)

              this.setState(
                {
                  verifiedUser: true,
                  buttonPressed: false,
                  current_user: res.data.data.user,
                },
                () => {
                  console.log(this.state.current_user);
                  subscribeUser(res.data.data.user);

                  let obj = {
                    // email: this.state.current_user.email,
                    // _id: this.state.current_user._id,
                    // staffType: this.state.current_user.staffType,
                    // name: this.state.current_user.name,
                    // subType: this.state.current_user.subType,
                    // specialty: this.state.current_user.specialty,
                    // identifier: this.state.current_user.identifier,
                    ...this.state.current_user,
                    productionArea: [],
                    chiefComplaint: [],
                    experience: [],
                    education: [],
                  };
                  cookie.save("token", res.data.data.token, { path: "/" });
                  cookie.save("current_user", obj, {
                    path: "/",
                  });
                }
              );
              // subscribeUser(res.data.data.user)
              // this.recordLogin(res.data.data)
            } else if (!res.data.success) {
              this.setState({
                openNotification: true,
                errorMsg: "Login failed",
              });
            }
          })
          .catch((e) => {
            console.log("error is ", e);
            this.setState({
              tr: true,
              msg: "Login failed",
              buttonPressed: false,
              openNotification: true,
              errorMsg: "Login failed",
            });
          });
      }
    }
  }

  handleNameChange(name) {
    this.setState({ name });
  }

  handleForgetPassword() {
    this.props.history.push("/forgetpassword");
  }

  hideSplash() {
    this.setState({ splash: false });

    if (cookie.load("current_user")) {
      this.props.history.push("dashboard");
    }
  }

  render() {
    if (this.state.tr) {
      setTimeout(() => {
        this.setState({
          tr: false,
          msg: "",
          errorMsg: "",
          openNotification: false,
        });
      }, 2000);
    }
    if (this.state.verifiedUser) {
      return <Redirect to="dashboard" />;
    }

    if (this.state.splash) {
      return <Splash hideSplash={this.hideSplash.bind(this)} />;
    }

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          position: "fixed",
          width: "100%",
          height: "100%",
          backgroundColor: "#0154E8",
        }}
      >
        <div className="header">
          <img
            style={{ maxWidth: 150, height: 80, marginTop: -14 }}
            src={KHMC_White}
            className="header1-style"
          />{" "}
          <img
            style={{
              maxWidth: 160,
              height: 40,
              cursor: "pointer",
              marginLeft: 10,
            }}
            src={Influence_white}
            className="header2-style"
            style={{
              cursor: "pointer",
            }}
          />
        </div>

        <div
          style={{
            // flex: 7,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            height: "100%",
            position: "absolute",
            width: "100%",
          }}
        >
          <div
            style={{
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <h1
              style={{
                textAlign: "center",
                color: "white",
                fontWeight: "700",
                // fontFamily:"Open Sans"
              }}
            >
              Login
            </h1>
            <h6
              style={{
                textAlign: "center",
                maxWidth: "60%",
                minWidth: "40%",
                color: "white",
              }}
            >
              Please enter email and password
            </h6>
          </div>

          <div style={{ marginLeft: "1%", marginRight: "1%" }}>
            <form onSubmit={(e) => this.handleLogin(e)}>
              {/* <Notification msg={this.state.msg} open={this.state.tr} /> */}

              <div className="container">
                <div
                  className="col-sm-12"
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <div
                    className="row inputForLogin"
                    style={{
                      marginTop: 20,
                      width: "55%",
                    }}
                  >
                    <input
                      type="email"
                      placeholder="Email"
                      name={"email"}
                      value={this.state.email}
                      onChange={(e) => this.handleInput(e, "email")}
                      className="textInputStyle"
                      style={{
                        borderColor:
                          !this.state.email && this.state.null_email
                            ? "red"
                            : "white",
                      }}
                    />
                  </div>
                </div>

                <div
                  className="col-sm-12"
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <div
                    className="row inputForLogin"
                    style={{
                      marginTop: 25,
                      width: "55%",
                    }}
                  >
                    <input
                      type="password"
                      placeholder="Password"
                      name={"password"}
                      value={this.state.password}
                      onChange={(e) => this.handleInput(e, "password")}
                      className="textInputStyle"
                      style={{
                        borderColor:
                          !this.state.password && this.state.null_password
                            ? "red"
                            : "white",
                      }}
                    />

                    <div className="Button" />
                  </div>
                </div>

                <div className="row">
                  <div
                    className="col-sm-12"
                    style={{
                      display: "flex",
                      width: "100%",
                    }}
                  >
                    {!this.state.buttonPressed ? (
                      <div
                        style={{
                          marginTop: 25,
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <Button
                          style={{
                            width: "25%",
                            paddingTop: 12,
                            paddingBottom: 12,
                            backgroundColor: "#002164",
                            borderRadius: 10,
                          }}
                          //onClick={() => this.handleLogin()}
                          type="submit"
                          variant="contained"
                          color="primary"
                          className="Loginbtn"
                        >
                          Login
                        </Button>
                      </div>
                    ) : (
                      <div
                        className="row"
                        style={{
                          marginTop: 25,
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <Loader
                          type="TailSpin"
                          color="white"
                          height={50}
                          width={50}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div style={{}}>
                <h6
                  style={{
                    cursor: "pointer",
                    marginTop: "2%",
                    color: "white",
                    textAlign: "center",
                    // fontWeight: "500",
                  }}
                  onClick={() => this.handleForgetPassword()}
                >
                  Forgot Password?
                </h6>
              </div>
            </form>
          </div>
        </div>
        <Notification
          msg={this.state.errorMsg}
          open={this.state.openNotification}
          success={this.state.successMsg}
        />
      </div>
    );
  }
}

export default Login;
