import React from "react";
import { Route, Switch } from "react-router-dom";
import NotFound from "../components/NotFound/NotFound";
// Routes
import CommunicationMgmt from "../views/CommunicationMgmt/communicationMgmt"
import ViewCommunicationRequests from "../views/CommunicationMgmt/viewCommunicationRequests"

class CommunicationMgmtRoutes extends React.PureComponent {
  render() {
    return (
      <Switch>
        <Route exact path={`${this.props.match.url}`} component={CommunicationMgmt} />

        <Route
          path={`${this.props.match.url}/viewRequests`}
          component={ViewCommunicationRequests}
        />

        <Route path="*" component={NotFound} />
      </Switch>
    );
  }
}
export default CommunicationMgmtRoutes;