import React, { Component } from "react";
import Camera from "react-camera";

import SendIcon from "@material-ui/icons/Send";
import CancelIcon from "@material-ui/icons/Cancel";

export default class App extends Component {
  constructor(props) {
    super(props);
    this.takePicture = this.takePicture.bind(this);
    this.state = {
      img: "",
    };
  }

  takePicture() {
    this.camera.capture().then((blob) => {
      const blobURL = URL.createObjectURL(blob);
      var file = new File([blob], "pic", { type: "image/png" });
      this.setState({ img: file });
      this.img.src = blobURL;

      //   this.img.onload = () => {
      //     URL.revokeObjectURL(this.src);
      //   };
    });
  }

  sendPicture() {
    this.props.handlePicSend(this.state.img);
  }

  render() {
    return (
      <div style={style.container}>
        {this.state.img ? (
          <>
            <img
              style={style.captureImage}
              ref={(img) => {
                this.img = img;
              }}
            />
            <div style={style.sendButton} onClick={this.sendPicture.bind(this)}>
              <SendIcon
                style={{
                  color: "blue",
                  fontSize: 30,
                }}
              />
            </div>

            <div
              style={style.retakeButton}
              onClick={() => this.setState({ img: "" })}
            >
              <CancelIcon
                style={{
                  color: "red",
                  fontSize: 30,
                }}
              />
            </div>
          </>
        ) : (
          <Camera
            style={style.preview}
            ref={(cam) => {
              this.camera = cam;
            }}
          >
            <div style={style.captureContainer}>
              <div style={style.captureButton} onClick={this.takePicture} />
              <div
                style={style.cancelButton}
                onClick={() => this.props.setCameraOpen(false)}
              >
                <CancelIcon
                  style={{
                    color: "#13D59F",
                    fontSize: 30,
                  }}
                />
              </div>
            </div>
          </Camera>
        )}
      </div>
    );
  }
}

const style = {
  preview: {
    position: "relative",
  },
  captureContainer: {
    display: "flex",
    position: "absolute",
    justifyContent: "center",
    zIndex: 1,
    bottom: 0,
    width: "100%",
  },
  captureButton: {
    backgroundColor: "#fff",
    borderRadius: "50%",
    height: 56,
    width: 56,
    color: "#000",
    margin: 20,
  },

  sendButton: {
    backgroundColor: "white",
    borderRadius: "50%",
    height: 56,
    width: 56,
    color: "#000",
    margin: 20,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "fixed",
    zIndex: 5,
    bottom: 0,
    right: 0,
  },

  retakeButton: {
    backgroundColor: "#6d90d0",
    borderRadius: "50%",
    height: 56,
    width: 56,
    color: "#000",
    margin: 20,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "fixed",
    zIndex: 5,
    bottom: 0,
    left: 0,
  },

  cancelButton: {
    backgroundColor: "white",
    borderRadius: "50%",
    height: 56,
    width: 56,
    color: "#000",
    margin: 20,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    float: "right",
  },

  captureImage: {
    width: "100%",
  },
};
