import React, { useEffect, useState, useReducer } from "react";
import Loader from "react-loader-spinner";
import moment from "moment";
import { makeStyles } from '@material-ui/core/styles';
import { useTheme, Slide, Dialog, DialogTitle, DialogContent, Backdrop, Fade } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import axios from "axios";
import { getSingleEdr } from "../../public/endpoins";
import FormatDate from "../../utils/formatDateTime";
import _ from "lodash";
import { displayInHumanDifference, replacePatientIdIfNull } from "../../utils/commonFunc";
import classNames from "classnames";

let matches = false;

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function PatientClinicalInformation (props) {
  const theme = useTheme();
  matches = useMediaQuery(theme.breakpoints.up("sm"));
  const classes = useStyles();

  const [ patientDetails, setPatientDetails ] = useState("");
  const [ allergies, setAllergies ] = useState("-");
  const [ triageAssessment, setTriageAssessment ] = useState("-");
  const [ allPharmaReports, setAllPharmaReports ] = useState([]);
  const [ value, setValue ] = useState(0);
  const [ openMedicationHistory, setOpenMedicationHistory ] = useState(false);
  const [ openNonPharmaHistory, setOpenNonPharmaHistory ] = useState(false);
  const [ allNonPharmaReports, setAllNonPharmaReports ] = useState([]);

  const handleOpenMedicationHistory = () => {
    setOpenMedicationHistory(true);
  };

  const closeMedicationHistory = () => {
    setOpenMedicationHistory(false);
  };

  const handleOpenNonPharmaHistory = () => {
    setOpenNonPharmaHistory(true);
  };

  const closeNonPharmaHistory = () => {
    setOpenNonPharmaHistory(false);
  };

  useEffect(() => {
    console.log("PatientClinicalInformation::", props);
    if (props.edrId){
      axios
        .get(`${getSingleEdr}/${props.edrId}`)
        .then((res) => {

          let dataReturn = replacePatientIdIfNull(res.data.data);
          console.log("PatientClinicalInformation::", dataReturn.chiefComplaint.length);
          
          dataReturn.chiefComplaintName = dataReturn.chiefComplaint.length > 0 ? dataReturn.chiefComplaint[ dataReturn.chiefComplaint.length - 1 ].chiefComplaintId.short_desc : "N/A";
          dataReturn.chiefComplaintOthersName = dataReturn.chiefComplaintOthers

          if (dataReturn.dcdForm){
            let dcdFormData = dataReturn.dcdForm[ dataReturn.dcdForm.length-1 ];
            if (dcdFormData.triageAssessment){
              let dataReturnTriageAssesment = dcdFormData.triageAssessment.length > 0 ? dcdFormData.triageAssessment[ dcdFormData.triageAssessment.length - 1 ] : {}
              if (dataReturnTriageAssesment && dataReturnTriageAssesment.triageLevel){
                setTriageAssessment(dataReturnTriageAssesment.triageLevel[0])
              }
            }

            if (dcdFormData.pastMedicalHistory){
              let dataReturnPastMedicalHistory = dcdFormData.pastMedicalHistory.length > 0 ? dcdFormData.pastMedicalHistory[ dcdFormData.pastMedicalHistory.length - 1 ] : {}
              console.log('patient name', dataReturnPastMedicalHistory);
              if (dataReturnPastMedicalHistory.details){
                let det = dataReturnPastMedicalHistory.details.filter(obj => obj.name === "Allergies");
                if (det[0].values){
                  let objx = [];
                  Object.entries(det[0].values).map((obj, entry) => {
                    objx.push( obj[1].name );
                    // console.log("pastMedicalHistory::details", obj[1].name);
                  });
                  setAllergies(objx.join(', '));
                }
              }
            }
            /*
            */
          }

          let itemsCount = 0
          let itemsNonCount = 0
              // console.log('BC::', dataReturn);
          if ( dataReturn.pharmacyRequest.length > 0 ){
            Object.entries(dataReturn.pharmacyRequest).map(([i, d]) =>{
              // console.log('BC::', d.item);
              if (d.generatedFor==="pharmaceutical"){
                itemsCount += d.item.length;
              }else if (d.generatedFor==="non_pharmaceutical"){
                itemsNonCount += d.item.length;
              }
            })
          }
          dataReturn.pharmacyRequestsCount = itemsCount
          dataReturn.nonPharmacyRequestsCount = itemsNonCount
          console.log("PatientClinicalInformation::", dataReturn);


          let finalLabRequests = [];
          let finalRadRequests = [];
          let finalPharmaRequests = [];
          let finalNonPharmaRequests = [];
          let finalDischargePharmaRequests = [];

          // 
          if (dataReturn.pharmacyRequest && dataReturn.pharmacyRequest.length > 0){
            let pharmacyRequestsArray = dataReturn.pharmacyRequest;
            Object.entries(pharmacyRequestsArray).map(([did, d]) => {
              let finalPharmaRequest = d;
              finalPharmaRequest.requester = finalPharmaRequest.requestedBy && finalPharmaRequest.requestedBy.name[0].given[0] + " " + finalPharmaRequest.requestedBy.name[0].family;
              finalPharmaRequest.requestDate = moment(finalPharmaRequest.createdAt).format('MMM DD, YYYY LT');
              Object.entries(finalPharmaRequest.item).map(([key, val]) => {
                if (finalPharmaRequest.generatedFor==="pharmaceutical"){
                  finalPharmaRequests.push({
                    parentRequest: finalPharmaRequest,
                    subRequest: val
                  });
                }else if (finalPharmaRequest.generatedFor==="non_pharmaceutical"){
                  finalNonPharmaRequests.push({
                    parentRequest: finalPharmaRequest,
                    subRequest: val
                  });
                }else{
                  finalDischargePharmaRequests.push({
                    parentRequest: finalPharmaRequest,
                    subRequest: val
                  });
                }
              });
            });
            setAllPharmaReports(finalPharmaRequests);
            setAllNonPharmaReports(finalNonPharmaRequests);
            // setAllDischargeReports(finalDischargePharmaRequests);
          }


          setPatientDetails(dataReturn);
          return res;
        })
        .catch((e) => {
          console.log("PatientClinicalInformation:: error: ", e);
        });
    }
  }, [])

  return (
    <>
        {patientDetails ? (
        <div>
          <div className={classNames('card', 'border-0', props.wrapClass)}>
            <div className="card-header bg-primary text-white"><strong>Patient Clinical Information</strong></div>
            <div className="card-body">
              <div className="row row-10">
                <div className="col-6 col-lg-2 card-detail-block block-xdiv mb-lg-2   mb-2"><strong className="lblX">Triage Level</strong><span className="valX">{triageAssessment}</span></div>
                <div className="col-6 col-lg-2 card-detail-block block-xdiv mb-lg-2   mb-2"><strong className="lblX">Chief Complaint</strong><span className="valX">{patientDetails.chiefComplaintName} {patientDetails.chiefComplaintOthers?patientDetails.chiefComplaintOthers:''}</span></div>
                <div className="col-12 col-lg-4 card-detail-block block-xdiv mb-lg-2  mb-2"><strong className="lblX">Allergies</strong><span className="valX">{allergies}</span></div>
                <div className="col-6 col-lg-2 card-detail-block block-xdiv mb-lg-2   mb-2">
                  <strong className="lblX">Medication/Non-Pharma</strong>
                  <span className="valX" style={{cursor: 'pointer'}} onClick={handleOpenMedicationHistory}>{patientDetails.pharmacyRequestsCount} Medications</span>
                  <span className="valX" style={{cursor: 'pointer'}} onClick={handleOpenNonPharmaHistory}>{patientDetails.nonPharmacyRequestsCount} Non-Pharma</span>
                  {/*
                    props.medicationURL?
                    <a href={props.medicationURL} className="valX"><strong>{patientDetails.pharmacyRequestsCount} Medications</strong></a>:
                    <span className="valX">{patientDetails.pharmacyRequestsCount} Medications</span>
                  */}
                </div>
                <div className="col-6 col-lg-2 card-detail-block block-xdiv mb-lg-2   mb-2"><strong className="lblX">Diagnosis</strong><span className="valX">0 Diagnosis</span></div>
              </div>
            </div>
          </div>
          <Dialog
            aria-labelledby="medication-modal-title"
            aria-describedby="medication-modal-description"
            maxWidth="lg"
            open={openMedicationHistory}
            onClose={closeMedicationHistory}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={openMedicationHistory}>
              <div className={classes.paper}>
                <DialogTitle id="medication-modal-title">Pharma Items</DialogTitle>
                <DialogContent>
                  <div className="row">
                    <div className="col-md-12 col-sm-12">
                      <table className="table table-sm table-bordered table-stripped">
                        <tr>
                          <th width="15%" className="text-left bg-light">Date &amp; Time</th>
                          <th width="15%" className="text-left bg-light">Request ID</th>
                          <th className="text-left bg-light">Item</th>
                          <th width="25%" className="text-center bg-light">Request by</th>
                        </tr>
                        {
                          allPharmaReports && allPharmaReports.length>0?
                          Object.entries(allPharmaReports).map(([ai,av]) => {
                            return (
                              <tr>
                                <td>{av.parentRequest.requestDate}</td>
                                <td>{av.parentRequest.pharmacyRequestNo}</td>
                                <td><strong>{av.subRequest.itemId.itemCode}:</strong> {av.subRequest.itemId.name}</td>
                                <td>{av.parentRequest.requester}</td>
                              </tr>
                            )
                          })
                          :<tr><td className="text-center" colspan="6">No Pharma Request!</td></tr>
                        }
                      </table>
                    </div>
                  </div>
                </DialogContent>
              </div>
            </Fade>
          </Dialog>


          <Dialog
            aria-labelledby="nonpharma-modal-title"
            aria-describedby="nonpharma-modal-description"
            maxWidth="lg"
            open={openNonPharmaHistory}
            onClose={closeNonPharmaHistory}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={openNonPharmaHistory}>
              <div className={classes.paper}>
                <DialogTitle id="nonpharma-modal-title">Non-Pharma Items</DialogTitle>
                <DialogContent>
                  <div className="row">
                    <div className="col-md-12 col-sm-12">
                      <table className="table table-sm table-bordered table-stripped">
                        <tr>
                          <th width="15%" className="text-left bg-light">Date &amp; Time</th>
                          <th width="15%" className="text-left bg-light">Request ID</th>
                          <th className="text-left bg-light">Item</th>
                          <th width="25%" className="text-center bg-light">Request by</th>
                        </tr>
                        {
                          allNonPharmaReports && allNonPharmaReports.length>0?
                          Object.entries(allNonPharmaReports).map(([ai,av]) => {
                            return (
                              <tr>
                                <td>{av.parentRequest.requestDate}</td>
                                <td>{av.parentRequest.pharmacyRequestNo}</td>
                                <td><strong>{av.subRequest.itemId.itemCode}:</strong> {av.subRequest.itemId.name||av.subRequest.itemId.description}</td>
                                <td>{av.parentRequest.requester}</td>
                              </tr>
                            )
                          })
                          :<tr><td className="text-center" colspan="6">No Non-Pharma Request!</td></tr>
                        }
                      </table>
                    </div>
                  </div>
                </DialogContent>
              </div>
            </Fade>
          </Dialog>
        </div>
        ) : <div className={classNames('card', 'border-0', props.wrapClass)} style={{
            backgroundColor: (props.backgroundColor ? props.backgroundColor : '#fff'),
          }}>Loading Patient Details</div>}
    </>
  );
}
export default PatientClinicalInformation ;
