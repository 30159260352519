/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable array-callback-return */
/* eslint-disable react/jsx-indent */
import React, { useEffect, useState, useReducer } from "react";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import axios from "axios";
import Notification from "../../components/Snackbar/Notification.js";
import AccountCircle from "@material-ui/icons/SearchOutlined";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import {
  updateIPR,
  notifyDischarge,
  updateEdr,
  getSearchedPharmaceuticalItemsUrl,
  getSearchedItemsNonPharmaceuticalUrl,
} from "../../public/endpoins";
import InputLabelComponent from "../../components/InputLabel/inputLabel";
import ErrorMessage from "../../components/ErrorMessage/errorMessage";
import Paper from "@material-ui/core/Paper";
import cookie from "react-cookies";
import Dialog from "@material-ui/core/Dialog";
import Header from "../../components/Header/Header";
import plus_icon from "../../assets/img/Plus.png";
import purchase_request from "../../assets/img/Doctor - Discharge.png";
import Back from "../../assets/img/Back_Arrow.png";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import "../../assets/jss/material-dashboard-react/components/TextInputStyle.css";
import CustomTable from "../../components/Table/Table";
import Loader from "react-loader-spinner";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import stylesForPaper from "../../assets/jss/material-dashboard-react/components/paper.js";
import ConfirmationDialog from "../../components/confirmationDialog/confirmationDialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

const scheduleArray = [
  { key: "Now", value: "Now/Immediate" },
  // { key: "Immediate", value: "Immediate" },
];
const priorityArray = [
  { key: "Emergency", value: "Emergency" },
  { key: "Regular", value: "Regular" },
  { key: "PRN", value: "PRN" },
];

const sizeArray = [
  { key: "Small", value: "Small" },
  { key: "Medium", value: "Medium" },
  { key: "Large", value: "Large" },
  { key: "Extra Large", value: "Extra Large" },
];

const modalArray = [
  { key: "Old", value: "Old" },
  { key: "New", value: "New" },
];

const actions = { edit: true };
const styles = {
  inputContainer: {
    marginTop: 10,
    backgroundColor: "white",
    borderRadius: 5,
    paddingTop: 5,
    paddingBottom: 5,
    marginLeft: 5,
    marginRight: 5,
  },
  stylesForButton: {
    color: "grey",
    cursor: "pointer",
    backgroundColor: "white",
    borderColor: "grey",
    marginRight: 4,
  },

  inputField: {
    outline: "none",
  },
  inputContainerForTextField: {
    marginTop: 10,
  },
  textFieldPadding: {
    paddingLeft: 5,
    paddingRight: 5,
  },
  inputContainerForDropDown: {
    marginTop: 25,
  },
  buttonContainer: {
    marginTop: 25,
  },
};
// const useStyles = makeStyles(tableStyles)

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(0),
  },
  input: {
    backgroundColor: "white",
    boxShadow: "none",
    borderRadius: 5,
    "&:after": {
      borderBottomColor: "black",
      boxShadow: "none",
    },
    "&:hover": {
      backgroundColor: "white",
      boxShadow: "none",
    },
    "&:focus": {
      backgroundColor: "white",
      boxShadow: "none",
    },
  },
  multilineColor: {
    boxShadow: "none",
    backgroundColor: "white",
    borderRadius: 5,
    "&:hover": {
      backgroundColor: "white",
      boxShadow: "none",
    },
    "&:after": {
      borderBottomColor: "black",
      boxShadow: "none",
    },
    "&:focus": {
      boxShadow: "none",
    },
  },
  root: {
    "& .MuiTextField-root": {
      backgroundColor: "white",
    },
    "& .Mui-focused": {
      backgroundColor: "white",
      color: "black",
      boxShadow: "none",
    },
    "& .Mui-disabled": {
      backgroundColor: "white",
      color: "gray",
    },
    "&:focus": {
      backgroundColor: "white",
      boxShadow: "none",
    },
  },
}));

function AddEditEDR(props) {
  const matches = useMediaQuery("(min-width:600px)");
  const classes = useStyles();
  const initialState = {
    date: new Date(),
    status: "pending",
    requester: "",
    itemId: "",
    itemName: "",
    priority: "",
    schedule: "",
    dosage: "",
    issueUnit: "",
    frequency: "",
    duration: "",
    requestedQty: "",
    unitPrice: "",
    totalPrice: "",
    dischargeMedicines: "",
    dischargeRequest: "",
    requestType: "",

    make_model: "",
    size: "",

    selectedItemToSearch: "pharmaceutical",

    additionalNotes: "",
  };

  function reducer(state, { field, value }) {
    return {
      ...state,
      [field]: value,
    };
  }

  const [state, dispatch] = useReducer(reducer, initialState);

  const {
    date = new Date(),
    status = "pending",
    itemId,
    itemName,
    priority,
    schedule,
    dosage,
    issueUnit,
    frequency,
    duration,
    requestedQty,
    unitPrice,
    totalPrice,
    dischargeMedicines,
    dischargeRequest,
    requestType,

    selectedItemToSearch,

    make_model,
    size,

    additionalNotes,
  } = state;

  const onChangeValue = (e) => {
    var pattern = /^[0-9]*$/;
    if (
      e.target.name === "frequency" ||
      e.target.name === "dosage" ||
      e.target.name === "duration"
    ) {
      if (pattern.test(e.target.value) === false) {
        return;
      }
    }
    dispatch({
      field: e.target.name,
      value: e.target.value.replace(/[^\w.\s]/gi, ""),
    });
  };

  const [comingFor, setcomingFor] = useState("");
  const [currentUser, setCurrentUser] = useState(cookie.load("current_user"));
  const [isFormSubmitted] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [openNotification, setOpenNotification] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [, setSelectedItem] = useState("");
  const [selectItemToEditId, setSelectItemToEditId] = useState("");
  const [id, setId] = useState("");
  const [, setrequestNo] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [itemFound, setItemFound] = useState("");
  const [itemFoundSuccessfull, setItemFoundSuccessfully] = useState(false);
  const [patientId, setpatientId] = useState("");
  const [enableSave, setEnableSave] = useState(false);
  const [dischargeNotes, setdischargeNotes] = useState("");
  const [followUpInstruction, setFollowUpInstructions] = useState("");

  const [edrCompletionReason, setEdrCompletionReason] = useState("");
  const [edrCompletionRequirement, setEdrCompletionRequirement] = useState("");

  const [timer, setTimer] = useState(null);
  const [loadSearchedData, setLoadSearchedData] = useState(false);

  const [showConfirmationModel, setShowConfirmationModel] = useState(false);

  useEffect(() => {
    // setCurrentUser(cookie.load('current_user'))
    // setcomingFor(props.history.location.state.comingFor)
    // const selectedRec = props.history.location.state.selectedItem
    // console.log('Other... ', props.history.location.state.followUpInstruction)
    // console.log('Discharge... ', props.history.location.state.dischargeNotes)
    // setpatientId(selectedRec.patientId._id)
    // setFollowUpInstructions(props.history.location.state.followUpInstruction)
    // setdischargeNotes(props.history.location.state.dischargeNotes)
    // setEdrCompletionReason(props.history.location.state.edrCompletionReason)
    // setEdrCompletionRequirement(
    //   props.history.location.state.edrCompletionRequirement
    // )
    // setId(selectedRec._id)
    // setrequestNo(selectedRec.requestNo)
    // if (selectedRec) {
    //   Object.entries(selectedRec).map(([key, val]) => {
    //     if (val && typeof val === 'object') {
    //       if (key === 'dischargeRequest') {
    //         Object.entries(val).map(([key1, val1]) => {
    //           if (key1 === 'dischargeMedication') {
    //             Object.entries(val1).map(([key2, val2]) => {
    //               if (key2 === 'medicine') {
    //                 val2.map(
    //                   (d) => (
    //                     (d.unitPrice = d.unitPrice.toFixed(4)),
    //                     (d.totalPrice = d.totalPrice.toFixed(4))
    //                   )
    //                 )
    //                 dispatch({ field: 'dischargeMedicines', value: val2 })
    //               }
    //             })
    //           }
    //         })
    //         dispatch({ field: 'dischargeRequest', value: val })
    //       }
    //     } else {
    //       dispatch({ field: key, value: val })
    //     }
    //   })
    // }

    if (
      currentUser.staffType === "Nurses" &&
      currentUser.subType.includes("ED Nurse")
    ) {
      dispatch({ field: "selectedItemToSearch", value: "non_pharmaceutical" });
    }
    if (props.selectedMedicineToEdit) {
      handleRequestedItemEdit(props.selectedMedicineToEdit);
    }
  }, []);

  const handleAdd = () => {
    var now = new Date();
    var start = new Date(now.getFullYear(), 0, 0);
    var diff =
      now -
      start +
      (start.getTimezoneOffset() - now.getTimezoneOffset()) * 60 * 1000;
    var oneDay = 1000 * 60 * 60 * 24;
    var day = Math.floor(diff / oneDay);

    var dateNow = new Date();
    var YYYY = dateNow
      .getFullYear()
      .toString()
      .substr(-2);
    var HH = dateNow.getHours();
    var mm = dateNow.getMinutes();
    let ss = dateNow.getSeconds();

    const DischargeNo = "DCN" + day + YYYY + HH + mm + ss;
    // if (!validateForm()) {
    //   setIsFormSubmitted(true);
    //   setOpenNotification(true);
    //   setErrorMsg("Please fill the fields properly");
    // } else {
    // if (validateForm()) {

    let medicineData = [];

    for (let i = 0; i < dischargeMedicines.length; i++) {
      medicineData = [
        ...medicineData,
        {
          itemId: dischargeMedicines[i].itemId,
          itemName: dischargeMedicines[i].itemName,
          duration: dischargeMedicines[i].duration,
          dosage: dischargeMedicines[i].dosage,
          priority: dischargeMedicines[i].priority,
          schedule: dischargeMedicines[i].schedule,
          frequency: dischargeMedicines[i].frequency,
          requestedQty: dischargeMedicines[i].requestedQty,
          unitPrice: dischargeMedicines[i].unitPrice,
          totalPrice: dischargeMedicines[i].totalPrice,
          itemType: dischargeMedicines[i].itemType,
          make_model: dischargeMedicines[i].make_model,
          size: dischargeMedicines[i].size,
          // totalPrice: dischargeMedicines[i].totalPrice,
        },
      ];
    }

    let dischargeMedicationObject = {
      date: date,
      status: status,
      requester: currentUser._id,
      medicine: medicineData,
    };

    const params = {
      _id: id,
      requestType,
      status: "Discharged",
      dischargeRequest: {
        dischargeSummary: {
          followUpInstruction,
          dischargeNotes,
          edrCompletionRequirement,
          edrCompletionReason,
        },
        dischargeMedication: dischargeMedicationObject,
      },
    };
    console.log("params", params);
    axios
      .put(updateEdr, params)
      .then((res) => {
        if (res.data.success) {
          console.log("response while adding Medicine Req", res.data);
          // notifyForDischarge(patientId);
          props.history.push({
            pathname: "success",
            state: {
              // request #
              message1: `Pharmacy Request for patient`,
              code: `MRN: ${res.data.data.patientId.identifier[0].value.toUpperCase()}`,
              message2: `added successfully`,
            },
          });
        } else if (!res.data.success) {
          setOpenNotification(true);
          setErrorMsg("Error while adding the Medicine request");
        }
      })
      .catch((e) => {
        console.log("error after adding Medicine request", e);
        setOpenNotification(true);
        setErrorMsg("Error after adding the medicine request");
      });
  };
  // }
  // };

  const notifyForDischarge = (id) => {
    axios
      .get(notifyDischarge + "/" + id)
      .then((res) => {
        console.log(res);
      })
      .catch((e) => {
        console.log("error after notify", e);
        setOpenNotification(true);
        setErrorMsg(e);
      });
  };

  if (openNotification) {
    setTimeout(() => {
      setOpenNotification(false);
      setErrorMsg("");
    }, 2000);
  }

  function clearFields() {
    setDialogOpen(false);
    setSelectedItem("");
    setSelectItemToEditId("");

    dispatch({ field: "itemId", value: "" });
    dispatch({ field: "priority", value: "" });
    dispatch({ field: "schedule", value: "" });
    dispatch({ field: "dosage", value: "" });
    dispatch({ field: "frequency", value: "" });
    dispatch({ field: "duration", value: "" });
    dispatch({ field: "requestedQty", value: "" });
    dispatch({ field: "itemName", value: "" });
    dispatch({ field: "unitPrice", value: "" });
    dispatch({ field: "totalPrice", value: "" });
    dispatch({ field: "make_model", value: "" });
    dispatch({ field: "size", value: "" });
    dispatch({ field: "selectedItemToSearch", value: "pharmaceutical" });
    dispatch({ field: "additionalNotes", value: "" });
    setEnableSave(false);
  }

  function validateItemsForm() {
    // return (
    //   itemId &&
    //   itemId.length > 0 &&
    //   itemName &&
    //   itemName.length > 0 &&
    //   priority &&
    //   priority.length > 0 &&
    //   schedule &&
    //   schedule.length > 0 &&
    //   duration &&
    //   duration.length > 0 &&
    //   frequency &&
    //   frequency.length > 0 &&
    //   dosage &&
    //   dosage.length > 0
    // );

    let checkForpharma = true;
    let checkForNonpharma = true;

    if (selectedItemToSearch === "non_pharmaceutical") {
      checkForpharma =
        requestedQty !== "" &&
        requestedQty !== 0 &&
        size !== "" &&
        make_model !== "";
    }

    if (selectedItemToSearch === "pharmaceutical") {
      checkForpharma =
        dosage !== "" &&
        frequency !== "" &&
        duration !== "" &&
        dosage !== "0" &&
        frequency !== "0" &&
        duration !== "0";
      // && schedule !== "" &&
      // priority !== "" &&
      // form !== "";
    }

    return (
      itemId &&
      itemId.length > 0 &&
      itemName &&
      itemName.length > 0 &&
      // maximumLevel >= requestedQty &&
      checkForpharma &&
      checkForNonpharma
    );
  }

  useEffect(() => {
    if (dischargeMedicines) {
      props.handleAddMedicine(dischargeMedicines);
    }
  }, [dischargeMedicines]);

  const addSelectedItem = () => {
    // setIsFormSubmitted(true);
    if (validateItemsForm()) {
      dispatch({
        field: "dischargeMedicines",
        value: [
          ...dischargeMedicines,
          {
            itemId,
            priority,
            schedule,
            dosage,
            frequency,
            duration,
            requestedQty:
              selectedItemToSearch === "pharmaceutical"
                ? frequency * dosage * duration
                : requestedQty,
            itemName,
            itemType: selectedItemToSearch,
            make_model,
            size,
            // unitPrice: unitPrice,
            unitPrice: parseFloat(unitPrice).toFixed(4),
            // totalPrice: unitPrice * frequency * dosage * duration,
            totalPrice: parseFloat(
              unitPrice * frequency * dosage * duration
            ).toFixed(4),
            additionalNotes,
          },
        ],
      });
      clearFields();
    }
  };

  const editSelectedItem = () => {
    if (validateItemsForm()) {
      let obj = {
        itemId,
        priority,
        schedule,
        dosage,
        frequency,
        duration,
        requestedQty:
          selectedItemToSearch === "pharmaceutical"
            ? frequency * dosage * duration
            : requestedQty,
        itemName,
        itemType: selectedItemToSearch,
        make_model,
        size,
        // unitPrice: unitPrice,
        unitPrice: parseFloat(unitPrice).toFixed(4),
        // totalPrice: unitPrice * frequency * dosage * duration,
        totalPrice: parseFloat(
          unitPrice * frequency * dosage * duration
        ).toFixed(4),
        additionalNotes,
      };

      props.handleUpdate(obj);
    }
    clearFields();
  };

  function handleRequestedItemEdit(i) {
    console.log(i);
    // if (i.status === "pending") {
    setDialogOpen(true);
    setSelectedItem(i.itemId);
    setSelectItemToEditId(i.itemId);

    dispatch({ field: "itemId", value: i.itemId });
    dispatch({ field: "priority", value: i.priority });
    dispatch({ field: "schedule", value: i.schedule });
    dispatch({ field: "dosage", value: i.dosage });
    dispatch({ field: "frequency", value: i.frequency });
    dispatch({ field: "duration", value: i.duration });
    dispatch({ field: "requestedQty", value: i.requestedQty });
    dispatch({ field: "itemName", value: i.itemName });
    dispatch({ field: "unitPrice", value: i.unitPrice });
    dispatch({ field: "totalPrice", value: i.totalPrice });
    dispatch({ field: "make_model", value: i.make_model });
    dispatch({ field: "size", value: i.size });
    dispatch({ field: "selectedItemToSearch", value: i.itemType });
    dispatch({ field: "additionalNotes", value: i.additionalNotes });

    // } else {
    //   setOpenNotification(true);
    //   setErrorMsg("Item can not be updated once it is in progess");
    // }
  }

  const triggerMedChange = (a) => {
    handleSearch(a);
  };

  const handlePauseMedSearch = (e) => {
    setLoadSearchedData(true);
    clearTimeout(timer);

    const a = e.target.value.replace(/[^\w\s]/gi, "");
    setSearchQuery(a);

    setTimer(
      setTimeout(() => {
        triggerMedChange(a);
      }, 600)
    );
  };

  const handleSearch = (e) => {
    if (e.length >= 1) {
      let url = "";
      if (selectedItemToSearch === "pharmaceutical") {
        url = getSearchedPharmaceuticalItemsUrl;
      } else {
        url = getSearchedItemsNonPharmaceuticalUrl;
      }
      axios
        .get(url + "/" + e)
        .then((res) => {
          if (res.data.success) {
            if (res.data.data.items.length > 0) {
              console.log("price data", res.data.data);
              setItemFoundSuccessfully(true);
              setItemFound(res.data.data.items);
              setLoadSearchedData(false);
            } else {
              setItemFoundSuccessfully(false);
              setItemFound("");
              setLoadSearchedData(false);
            }
          }
        })
        .catch((e) => {
          console.log("error while searching medicine", e);
        });
    }
  };

  function handleAddItem(i) {
    console.log("selected med", i);

    dispatch({ field: "itemId", value: i._id });
    dispatch({ field: "itemName", value: i.name });
    dispatch({ field: "unitPrice", value: i.issueUnitCost });
    dispatch({ field: "issueUnit", value: i.issueUnit });
    // dispatch({ field: 'totalPrice', value: i.purchasePrice + i.tax })

    setSearchQuery("");
  }

  const onChangeRadio = (e) => {
    dispatch({ field: "selectedItemToSearch", value: e.target.name });
    setItemFoundSuccessfully(false);
    setItemFound("");
  };

  function handleDeliverPharmacy() {
    setShowConfirmationModel(true);
  }

  function onConfirmDeliver() {
    props.handleDeliverPharmacyRequest();
  }

  return (
    <div>
      {/* <div className='row' style={{ marginTop: '10px' }}>
        {dischargeMedicines !== 0 ? (
          <CustomTable
            tableData={dischargeMedicines}
            tableDataKeys={tableDataKeysForPharmacyReq}
            tableHeading={tableHeadingForPharmacyReq}
            action={actions}
            handleEdit={handleRequestedItemEdit}
            borderBottomColor={'#60d69f'}
            borderBottomWidth={20}
          />
        ) : (
          undefined
        )}
      </div> */}

      <Dialog
        aria-labelledby="form-dialog-title"
        open={true}
        onClose={() => props.handleClose(false)}
        // maxWidth="xl"
        // fullWidth={true}
        onBackdropClick={() => {}}
        fullScreen
      >
        <DialogContent style={{ backgroundColor: "white" }}>
          <AppBar>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => props.handleClose(false)}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </AppBar>

          <div
            className={`${"container-fluid"} ${classes.root}`}
            style={{ marginTop: 40 }}
          >
            {!(
              currentUser.staffType === "Nurses" &&
              currentUser.subType.includes("ED Nurse")
            ) ? (
              <div
                className="container-fluid"
                style={{
                  ...styles.inputContainerForTextField,
                  ...styles.textFieldPadding,
                }}
              >
                <div
                  className="row"
                  style={{
                    backgroundColor: "white",
                    height: matches ? 55 : 155,
                    paddingLeft: matches ? 0 : 10,
                    display: "flex",
                    alignItems: "center",
                    borderRadius: 5,
                    paddingTop: 8,
                  }}
                >
                  <h6 className="col-md-4" style={{ verticalAlign: "center" }}>
                    Item Type
                  </h6>

                  <FormControl className="col-md-8" component="fieldset">
                    <RadioGroup
                      row
                      aria-label="position"
                      name="position"
                      // defaultValue="top"
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <FormControlLabel
                        // value={selectedItemToSearch}
                        name={"pharmaceutical"}
                        control={<Radio color="primary" />}
                        label="Pharmaceutical"
                        onChange={onChangeRadio}
                        checked={
                          selectedItemToSearch === "pharmaceutical"
                            ? true
                            : false
                        }
                        disabled={selectItemToEditId ? true : false}
                      />

                      <FormControlLabel
                        // value={selectedItemToSearch}
                        name={"non_pharmaceutical"}
                        control={<Radio color="primary" />}
                        label="Non-Pharmaceutical"
                        onChange={onChangeRadio}
                        checked={
                          selectedItemToSearch === "non_pharmaceutical"
                            ? true
                            : false
                        }
                        disabled={selectItemToEditId ? true : false}
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
              </div>
            ) : (
              undefined
            )}

            {selectedItemToSearch === "pharmaceutical" ? (
              <div>
                <>
                  {selectItemToEditId === "" ? (
                    <div className="row" style={{ marginTop: 15 }}>
                      <div
                        className="col-md-12 col-sm-12 col-12"
                        style={{
                          ...styles.textFieldPadding,
                          ...styles.inputContainerForTextField,
                        }}
                      >
                        <TextField
                          required
                          type="text"
                          label="Medicine Name"
                          name={"searchQuery"}
                          value={searchQuery}
                          onChange={handlePauseMedSearch}
                          className="textInputStyle"
                          variant="filled"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <AccountCircle />
                              </InputAdornment>
                            ),
                            className: classes.input,
                            classes: { input: classes.input },
                          }}
                          InputLabelProps={{
                            className: classes.label,
                            classes: { label: classes.label },
                          }}
                        />
                      </div>
                    </div>
                  ) : (
                    undefined
                  )}
                </>

                {searchQuery ? (
                  <div
                    style={{
                      zIndex: 3,
                      marginTop: 5,
                      marginLeft: -8,
                      width: "101.5%",
                    }}
                  >
                    <Paper style={{ maxHeight: 200, overflow: "auto" }}>
                      {itemFoundSuccessfull && itemFound !== "" ? (
                        <Table size="small">
                          <TableHead>
                            <TableRow>
                              <TableCell>Medicine Name</TableCell>
                              <TableCell>Scientific Name</TableCell>
                              <TableCell>Item Code</TableCell>
                              <TableCell>Unit Price (JD)</TableCell>
                              {/* <TableCell>Total Price</TableCell> */}
                            </TableRow>
                          </TableHead>

                          <TableBody>
                            {itemFound.map((i) => {
                              return (
                                <TableRow
                                  key={i.itemCode}
                                  onClick={() => handleAddItem(i)}
                                  style={{ cursor: "pointer" }}
                                >
                                  <TableCell>{i.tradeName}</TableCell>
                                  <TableCell>{i.scientificName}</TableCell>
                                  <TableCell>{i.itemCode}</TableCell>
                                  <TableCell>
                                    {i.issueUnitCost.toFixed(4)}
                                  </TableCell>
                                  {/* <TableCell>
                                      {i.purchasePrice + i.tax}
                                    </TableCell> */}
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      ) : loadSearchedData ? (
                        <div style={{ textAlign: "center" }}>
                          <Loader
                            type="TailSpin"
                            color="#2c6ddd"
                            height={25}
                            width={25}
                            style={{
                              display: "inline-block",
                              padding: "10px",
                            }}
                          />
                          <span
                            style={{
                              display: "inline-block",
                              padding: "10px",
                            }}
                          >
                            <h4> Searching Medicine...</h4>
                          </span>
                        </div>
                      ) : searchQuery && !itemFoundSuccessfull ? (
                        <div style={{ textAlign: "center", padding: "10px" }}>
                          <h4>No Medicine Found !</h4>
                        </div>
                      ) : (
                        undefined
                      )}
                    </Paper>
                  </div>
                ) : (
                  undefined
                )}

                <div className="row">
                  <div
                    className="col-md-4 col-sm-4 col-12"
                    style={{
                      ...styles.inputContainerForTextField,
                      ...styles.textFieldPadding,
                    }}
                  >
                    <TextField
                      required
                      label="Item Name"
                      name={"itemName"}
                      value={itemName}
                      disabled
                      className="textInputStyle"
                      variant="filled"
                      InputProps={{
                        className: classes.input,
                        classes: { input: classes.input },
                      }}
                    />
                  </div>
                  <div
                    className="col-md-4 col-sm-4 col-12"
                    style={{
                      ...styles.inputContainerForTextField,
                      ...styles.textFieldPadding,
                    }}
                  >
                    <TextField
                      required
                      select
                      disabled={props.disableFields ? true : false}
                      label="Schedule"
                      name={"schedule"}
                      value={schedule}
                      onChange={onChangeValue}
                      className="textInputStyle"
                      variant="filled"
                      InputProps={{
                        className: classes.input,
                        classes: { input: classes.input },
                      }}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {scheduleArray.map((val) => {
                        return (
                          <MenuItem key={val.key} value={val.key}>
                            {val.value}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                    {/* <ErrorMessage
                          name={schedule}
                          isFormSubmitted={isFormSubmitted}
                        /> */}
                  </div>

                  <div
                    className="col-md-4 col-sm-4 col-12"
                    style={{
                      ...styles.inputContainerForTextField,
                      ...styles.textFieldPadding,
                    }}
                  >
                    <TextField
                      required
                      select
                      fullWidth
                      disabled={props.disableFields ? true : false}
                      id="priority"
                      name="priority"
                      value={priority}
                      onChange={onChangeValue}
                      label="Priority"
                      variant="filled"
                      className="dropDownStyle"
                      InputProps={{
                        className: classes.input,
                        classes: { input: classes.input },
                      }}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {priorityArray.map((val) => {
                        return (
                          <MenuItem key={val.key} value={val.key}>
                            {val.value}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                    {/* <ErrorMessage
                          name={priority}
                          isFormSubmitted={isFormSubmitted}
                        /> */}
                    {/* <InputLabelComponent>Priority*</InputLabelComponent>
                    <Select
                      fullWidth
                      id='priority'
                      name='priority'
                      value={priority}
                      onChange={onChangeValue}
                      label='Priority'
                      className='dropDownStyle'
                      input={<BootstrapInput />}
                    >
                      <MenuItem value=''>
                        <em>None</em>
                      </MenuItem>
                      {priorityArray.map((val) => {
                        return (
                          <MenuItem key={val.key} value={val.key}>
                            {val.value}
                          </MenuItem>
                        )
                      })}
                    </Select>
                    <ErrorMessage
                      name={priority}
                      isFormSubmitted={isFormSubmitted}
                    /> */}
                  </div>
                </div>

                <div className="row">
                  <div
                    className="col-md-3"
                    style={{
                      ...styles.inputContainerForTextField,
                      ...styles.textFieldPadding,
                    }}
                  >
                    <TextField
                      required
                      disabled={true}
                      label="Issue Unit"
                      name={"issueUnit"}
                      value={issueUnit}
                      onChange={onChangeValue}
                      className="textInputStyle"
                      variant="filled"
                      InputProps={{
                        className: classes.input,
                        classes: { input: classes.input },
                      }}
                    />
                    {/* <ErrorMessage
                          name={dosage}
                          isFormSubmitted={isFormSubmitted}
                        /> */}
                  </div>
                  <div
                    className="col-md-3"
                    style={{
                      ...styles.inputContainerForTextField,
                      ...styles.textFieldPadding,
                    }}
                  >
                    <TextField
                      required
                      disabled={props.disableFields ? true : false}
                      label="Dosage"
                      name={"dosage"}
                      value={dosage}
                      onChange={onChangeValue}
                      className="textInputStyle"
                      variant="filled"
                      InputProps={{
                        className: classes.input,
                        classes: { input: classes.input },
                      }}
                    />
                    {/* <ErrorMessage
                          name={dosage}
                          isFormSubmitted={isFormSubmitted}
                        /> */}
                  </div>

                  <div
                    className="col-md-3"
                    style={{
                      ...styles.inputContainerForTextField,
                      ...styles.textFieldPadding,
                    }}
                  >
                    <TextField
                      required
                      label="Frequency"
                      disabled={props.disableFields ? true : false}
                      name={"frequency"}
                      value={frequency}
                      onChange={onChangeValue}
                      className="textInputStyle"
                      variant="filled"
                      InputProps={{
                        className: classes.input,
                        classes: { input: classes.input },
                      }}
                    />
                  </div>

                  <div
                    className="col-md-3"
                    style={{
                      ...styles.inputContainerForTextField,
                      ...styles.textFieldPadding,
                    }}
                  >
                    <TextField
                      required
                      disabled={props.disableFields ? true : false}
                      label="Duration"
                      name={"duration"}
                      value={duration}
                      onChange={onChangeValue}
                      className="textInputStyle"
                      variant="filled"
                      InputProps={{
                        className: classes.input,
                        classes: { input: classes.input },
                      }}
                    />

                    {/* <ErrorMessage
                          name={duration}
                          isFormSubmitted={isFormSubmitted}
                        /> */}
                  </div>

                  <div
                    className="col-md-12"
                    style={{
                      ...styles.inputContainerForTextField,
                      ...styles.textFieldPadding,
                    }}
                  >
                    <TextField
                      required
                      disabled
                      label="Requested Quantity"
                      name={"requestedQty"}
                      value={dosage * duration * frequency}
                      onChange={onChangeValue}
                      className="textInputStyle"
                      variant="filled"
                      InputProps={{
                        className: classes.input,
                        classes: { input: classes.input },
                      }}
                    />

                    {/* <ErrorMessage
                          name={requestedQty}
                          isFormSubmitted={isFormSubmitted}
                        /> */}
                  </div>
                </div>
              </div>
            ) : (
              <div>
                <div className="row">
                  {selectItemToEditId === "" ? (
                    <>
                      <div
                        className="col-md-12"
                        style={{
                          ...styles.inputContainerForTextField,
                          ...styles.textFieldPadding,
                        }}
                      >
                        <TextField
                          type="text"
                          label="Medicine Name"
                          name={"searchQuery"}
                          value={searchQuery}
                          onChange={handlePauseMedSearch}
                          className={classes.margin}
                          variant="filled"
                          InputProps={{
                            className: classes.input,
                            classes: { input: classes.input },
                          }}
                          className="textInputStyle"
                        />
                        {/* </div> */}
                      </div>
                    </>
                  ) : (
                    undefined
                  )}
                </div>

                {searchQuery ? (
                  <div
                    style={{
                      zIndex: 3,
                      marginTop: 5,
                      marginLeft: -8,
                      width: "101.5%",
                    }}
                  >
                    <Paper style={{ maxHeight: 200, overflow: "auto" }}>
                      {itemFoundSuccessfull && itemFound !== "" ? (
                        <Table size="small">
                          <TableHead>
                            <TableRow>
                              <TableCell
                                align="center"
                                style={styles.forTableCell}
                              >
                                Trade Name
                              </TableCell>
                              <TableCell
                                align="center"
                                style={styles.forTableCell}
                              >
                                Scientific Name
                              </TableCell>

                              {/* <TableCell
                                  align="center"
                                  style={styles.forTableCell}
                                >
                                  Form
                                </TableCell> */}

                              <TableCell
                                style={styles.forTableCell}
                                align="center"
                              >
                                Description
                              </TableCell>
                            </TableRow>
                          </TableHead>

                          <TableBody>
                            {itemFound.map((i, index) => {
                              return (
                                <TableRow
                                  key={i.itemCode}
                                  onClick={() => handleAddItem(i)}
                                  style={{ cursor: "pointer" }}
                                >
                                  <TableCell align="center">
                                    {i.tradeName}
                                  </TableCell>
                                  <TableCell align="center">
                                    {i.scientificName}
                                  </TableCell>

                                  {/* <TableCell align="center">
                                      {i.form}
                                    </TableCell> */}

                                  <TableCell align="center">
                                    {i.description}
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      ) : loadSearchedData ? (
                        <div style={{ textAlign: "center" }}>
                          <Loader
                            type="TailSpin"
                            color="#2c6ddd"
                            height={25}
                            width={25}
                            style={{
                              display: "inline-block",
                              padding: "10px",
                            }}
                          />
                          <span
                            style={{
                              display: "inline-block",
                              padding: "10px",
                            }}
                          >
                            <h4> Searching Medicine...</h4>
                          </span>
                        </div>
                      ) : searchQuery && !itemFoundSuccessfull ? (
                        <div style={{ textAlign: "center", padding: "10px" }}>
                          <h4>No Medicine Found !</h4>
                        </div>
                      ) : (
                        undefined
                      )}
                    </Paper>
                  </div>
                ) : (
                  undefined
                )}

                <div className="row">
                  <div
                    className="col-md-3"
                    style={{
                      ...styles.inputContainerForTextField,
                      ...styles.textFieldPadding,
                    }}
                  >
                    <TextField
                      required
                      id="itemName"
                      label="Item Name"
                      name={"itemName"}
                      disabled={true}
                      type="text"
                      value={itemName}
                      onChange={onChangeValue}
                      variant="filled"
                      className="textInputStyle"
                      InputProps={{
                        className: classes.input,
                        classes: { input: classes.input },
                      }}
                      error={itemName === "" && isFormSubmitted}
                    />
                  </div>

                  <div
                    className="col-md-3"
                    style={{
                      ...styles.inputContainerForTextField,
                      ...styles.textFieldPadding,
                    }}
                  >
                    <TextField
                      disabled={props.disableFields ? true : false}
                      select
                      required
                      fullWidth
                      id="make_model"
                      name="make_model"
                      value={make_model}
                      onChange={onChangeValue}
                      label="Make/Model"
                      variant="filled"
                      InputProps={{
                        className: classes.input,
                        classes: { input: classes.input },
                      }}
                      error={make_model === "" && isFormSubmitted}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>

                      {modalArray.map((val) => {
                        return (
                          <MenuItem key={val.key} value={val.key}>
                            {val.value}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                  </div>

                  <div
                    className="col-md-3"
                    style={{
                      ...styles.inputContainerForTextField,
                      ...styles.textFieldPadding,
                    }}
                  >
                    <TextField
                      disabled={props.disableFields ? true : false}
                      select
                      required
                      fullWidth
                      id="size"
                      name="size"
                      value={size}
                      onChange={onChangeValue}
                      label="Size"
                      variant="filled"
                      InputProps={{
                        className: classes.input,
                        classes: { input: classes.input },
                      }}
                      error={size === "" && isFormSubmitted}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>

                      {sizeArray.map((val) => {
                        return (
                          <MenuItem key={val.key} value={val.key}>
                            {val.value}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                  </div>

                  <div
                    className="col-md-3"
                    style={{
                      ...styles.inputContainerForTextField,
                      ...styles.textFieldPadding,
                    }}
                  >
                    <TextField
                      disabled={props.disableFields ? true : false}
                      required
                      id="requestedQty"
                      label="Quantity"
                      name={"requestedQty"}
                      type="number"
                      value={requestedQty}
                      onChange={onChangeValue}
                      className="textInputStyle"
                      variant="filled"
                      onKeyDown={(evt) => {
                        (evt.key === "e" ||
                          evt.key === "E" ||
                          evt.key === "-" ||
                          evt.key === "+") &&
                          evt.preventDefault();
                      }}
                      InputProps={{
                        className: classes.input,
                        classes: { input: classes.input },
                      }}
                      error={requestedQty === "" && isFormSubmitted}
                    />
                  </div>
                </div>
              </div>
            )}

            <div className="row">
              <div
                className="col-12"
                style={{
                  ...styles.inputContainerForTextField,
                  ...styles.textFieldPadding,
                }}
              >
                <TextField
                  disabled={props.disableFields ? true : false}
                  label="Additional Notes"
                  name={"additionalNotes"}
                  value={additionalNotes}
                  className="textInputStyle"
                  type={"text"}
                  variant="filled"
                  InputProps={{
                    className: classes.input,
                    classes: { input: classes.input },
                  }}
                  onChange={onChangeValue}
                />
              </div>
            </div>

            <div
              className="row"
              style={{
                display: "flex",
                justifyContent: "flex-end",
                paddingLeft: 2,
                paddingRight: 2,
              }}
            >
              <div style={{ marginTop: "2%", marginBottom: "2%" }}>
                <Button
                  onClick={() => props.handleClose()}
                  style={{
                    ...styles.stylesForButton,
                  }}
                  variant="outlined"
                >
                  <strong>Cancel</strong>
                </Button>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: "2%",
                  marginBottom: "2%",
                }}
              >
                {currentUser.staffType !== "Clinical Pharmacist" ? (
                  <Button
                    style={{
                      color: "white",
                      cursor: "pointer",
                      borderRadius: 5,
                      backgroundColor: "#2c6ddd",
                      paddingLeft: 30,
                      paddingRight: 30,
                    }}
                    disabled={
                      selectItemToEditId === "" ? !validateItemsForm() : false
                    }
                    onClick={
                      selectItemToEditId === ""
                        ? addSelectedItem
                        : editSelectedItem
                    }
                    variant="contained"
                  >
                    Submit
                  </Button>
                ) : (
                  <Button
                    style={{
                      color: "white",
                      cursor: "pointer",
                      borderRadius: 5,
                      backgroundColor: "#2c6ddd",
                      paddingLeft: 30,
                      paddingRight: 30,
                    }}
                    onClick={handleDeliverPharmacy}
                    variant="contained"
                  >
                    Deliver
                  </Button>
                )}
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>

      {showConfirmationModel ? (
        <ConfirmationDialog
          dialogMessage={
            "You will not be able to update the request after setting it to in progress. Are you sure want to set it into in progress?"
          }
          dialogHeading={"Deliver Medicine"}
          onClickDone={onConfirmDeliver}
          onClickCancel={setShowConfirmationModel}
        />
      ) : (
        undefined
      )}
    </div>
  );
}
export default AddEditEDR;
