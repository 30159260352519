/*eslint-disable*/
import React, { useState, useEffect, useReducer } from 'react'
import CustomTable from '../../components/Table/Table'
import Header from '../../components/Header/Header'
import pharmaIcon from '../../assets/img/Pharma.png'
import '../../assets/jss/material-dashboard-react/components/loaderStyle.css'
import cookie from 'react-cookies'
import Back from '../../assets/img/Back_Arrow.png'
import Plus from '../../assets/img/Plus.png'
import Eye from '../../assets/img/Eye.png'
import AddMedicationModel from '../../components/AddDischargeMedicationModel/addMedication'
import Button from '@material-ui/core/Button'
import { styles } from './styles'
import Notification from '../../components/Snackbar/Notification.js'

import {
  addPharmacyRequest,
  updatePharmacyRequest,
  deliverPharmcayRequest,
} from '../../public/endpoins'
import axios from 'axios'

import {
  setSelectedPatientForReducer
 } from "../../actions/patientDetails";
 import {connect} from 'react-redux'

const tableHeadingForPharmacyReq = [
  'Item Name',
  'Dosage',
  'Duration',
  'Frequency',
  'Requested Qty',
  'Action',
]

const tableHeadingForPharmacyReqForView = [
  'Item Name',
  'Dosage',
  'Duration',
  'Frequency',
  'Requested Qty',
]

const tableDataKeysForPharmacyReq = [
  'itemName',
  'dosage',
  'duration',
  'frequency',
  'requestedQty',
]

const tableHeadingForNotes = [
  'Date/Time',
  // "Description/Notes",
  'Clinical Pharmacist Ref',
  'Status',
  'Action',
]
const tableDataKeysForNotes = ['createdAt', 'pharmacist', 'status']

const actions = { edit: true, delete: true }
const action = { view: true }

 function PharmaNotes(props) {
  const [currentUser] = useState(cookie.load('current_user'))
  // const [reconciliationNotes, setReconciliationNotes] = useState([]);
  const [errorMsg, setErrorMsg] = useState('')
  const [openNotification, setOpenNotification] = useState(false)
  // const [item, setMedicineData] = useState([]);
  const [notesData, setNotesData] = useState([])
  const [openMedicationModel, setOpenMedicationModel] = useState(false)
  const [comingFor, setComingFor] = useState('')

  const [selectedMedicineToEdit, setSelectedMedicineToEdit] = useState('')
  const [selectedPharmacyRequest, setSelectedPharmacyRequest] = useState('')

  const initialState = {
    _id: '',
    requestedBy: '',
    reconciliationNotes: [],
    item: [],
    status: '',
    secondStatus: '',
    pharmacyRequestNo: '',
  }

  function reducer(state, { field, value }) {
    return {
      ...state,
      [field]: value,
    }
  }

  const [state, dispatch] = useReducer(reducer, initialState)

  const {
    _id,
    requestedBy,
    reconciliationNotes,
    item,
    status,
    secondStatus,
    pharmacyRequestNo,
  } = state

  if (openNotification) {
    setTimeout(() => {
      setOpenNotification(false)
      setErrorMsg('')
    }, 2000)
  }

  useEffect(() => {
    console.log('in add pharam request', props.history.location.state)
    setSelectedPharmacyRequest(props.history.location.state.selectedItem)
    setComingFor(props.history.location.state.comingFor)

    if (
      props.history.location.state.comingFor === 'view' ||
      props.history.location.state.comingFor === 'edit'
    ) {
      const selectedItem = props.history.location.state.selectedItem

      Object.entries(selectedItem).map(([key, val]) => {
        if (val && typeof val === 'object') {
          if (key === 'item') {
            val.map((i) => (i.itemId = i.itemId._id))
            dispatch({ field: key, value: val })
          } else if (key === 'reconciliationNotes') {
            val.map(
              (recon) =>
                (recon.pharmacist =
                  recon.addedBy.name[0].given[0] +
                  ' ' +
                  recon.addedBy.name[0].family)
            )
            dispatch({ field: key, value: val.reverse() })
          } else {
            dispatch({ field: key, value: val })
          }
        } else {
          dispatch({ field: key, value: val })
        }
      })
    }
  }, [])

  const handleOrderModelClose = () => {
    setOpenMedicationModel(false)
    setSelectedMedicineToEdit('')
  }

  const handleAddMedicine = (medicine) => {
    let x = item.find((med) => med.itemId === medicine[0].itemId)
    if (x) {
      setOpenNotification(true)
      setErrorMsg('Medicine has already been added.')
      setOpenMedicationModel(false)
      return
    }

    dispatch({ field: 'item', value: [...item, { ...medicine[0] }] })
    setOpenMedicationModel(false)
  }

  const handleDeleteMedicine = (obj) => {
    let x = item.filter((med) => med.itemId !== obj.itemId)
    dispatch({ field: 'item', value: [...x] })
  }

  const handleEditMedicine = (obj) => {
    setOpenMedicationModel(true)
    setSelectedMedicineToEdit(obj)
  }

  const handleUpdate = (medicine) => {
    for (let i = 0; i < item.length; i++) {
      if (item[i].itemId === medicine.itemId) {
        let temp = [...item]
        temp[i] = medicine
        dispatch({ field: 'item', value: [...temp] })
        setOpenMedicationModel(false)
        setSelectedMedicineToEdit('')
        break
      }
    }
  }

  const handleSubmitPharma = () => {
    if (item.length === 0) {
      setOpenNotification(true)
      setErrorMsg('Please add some medicines first')
      return
    } else {
      let obj = {
        requestedBy: currentUser._id,
        reconciliationNotes: [],
        item: [...item],
        status: 'pending',
        secondStatus: 'pending',
        edrId: props.history.location.state.edrId,
      }

      axios
        .put(addPharmacyRequest, obj)
        .then((res) => {
          if (res.data.success) {
            console.log('response after adding pharmacy Request', res.data)
      props.setSelectedPatientForReducer(res.data.data);
            props.history.go(-1)
          } else if (!res.data.success) {
            setOpenNotification(true)
            setErrorMsg('Error while adding the pharmacy Request')
          }
        })
        .catch((e) => {
          console.log('error after adding pharmacy Request', e)
          setOpenNotification(true)
          setErrorMsg('Error while adding the pharmacy Request')
        })
    }
  }

  const handleViewReconsiliation = (obj) => {
    props.history.push({
      pathname: '/dashboard/home/reconciliationrequest/add',
      state: {
        pharmacyRequestId: _id,
        edrId: props.history.location.state.edrId,
        selectedItem: obj,
        comingFor: 'edit',
        selectedPharmacyReqObj: selectedPharmacyRequest,
      },
    })
  }

  const handleEditPharma = () => {
    if (item.length === 0) {
      setOpenNotification(true)
      setErrorMsg('Please add some medicines first')
      return
    } else {
      let obj = {
        _id,
        requestedBy: requestedBy._id,
        reconciliationNotes,
        item,
        status,
        secondStatus,
        edrId: props.history.location.state.edrId,
      }

      console.log(obj)

      axios
        .put(updatePharmacyRequest, obj)
        .then((res) => {
          if (res.data.success) {
            console.log('response after adding pharmacy Request', res.data)
            props.history.go(-2)
          } else if (!res.data.success) {
            setOpenNotification(true)
            setErrorMsg('Error while adding the pharmacy Request')
          }
        })
        .catch((e) => {
          console.log('error after adding pharmacy Request', e)
          setOpenNotification(true)
          setErrorMsg('Error while adding the pharmacy Request')
        })
    }
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        position: 'fixed',
        width: '100%',
        height: '100%',
        backgroundColor: '#60d69f',
        overflowY: 'scroll',
      }}
    >
      <Header history={props.history} />

      <div className='cPadding'>
        <div className='subheader' style={{ paddingRight: '15px' }}>
          <div>
            <img src={pharmaIcon} />
            <h4>Pharma Request</h4>
          </div>
          <div style={{ marginRight: '-14px' }}>
            <Button
              onClick={() =>
                props.history.push('/dashboard/home/reconciliationrequest')
              }
              style={{
                ...styles.stylesForButton,
                backgroundColor: '#845dc2',
                marginRight: 10,
              }}
              variant='contained'
            >
              <img className='icon-style' src={Eye} />
              &nbsp;&nbsp;
              <strong style={{ fontSize: '12px' }}>View All Requests</strong>
            </Button>

            {comingFor !== 'view' ? (
              <Button
                onClick={() => setOpenMedicationModel(true)}
                style={styles.stylesForButton}
                variant='contained'
                color='primary'
              >
                <img className='icon-style' src={Plus} />
                &nbsp;&nbsp;
                <strong style={{ fontSize: '12px' }}>Add New</strong>
              </Button>
            ) : (
              undefined
            )}
          </div>
        </div>

        <div className='row' style={{ marginTop: '10px' }}>
          <div className='col-md-12 col-sm-12 col-12'>
            {item ? (
              <CustomTable
                tableData={item}
                tableDataKeys={tableDataKeysForPharmacyReq}
                tableHeading={
                  comingFor !== 'view'
                    ? tableHeadingForPharmacyReq
                    : tableHeadingForPharmacyReqForView
                }
                action={comingFor !== 'view' ? actions : ''}
                handleEdit={handleEditMedicine}
                handleDelete={handleDeleteMedicine}
                borderBottomColor={'#60d69f'}
                borderBottomWidth={20}
              />
            ) : (
              undefined
            )}
          </div>
        </div>

        {comingFor !== 'view' ? (
          <div className='row' style={{ marginBottom: 20 }}>
            <div
              className='col-12'
              style={{ display: 'flex', justifyContent: 'flex-end' }}
            >
              <Button
                onClick={
                  comingFor === 'add' ? handleSubmitPharma : handleEditPharma
                }
                style={styles.stylesForButton}
                variant='contained'
                color='primary'
              >
                <strong style={{ fontSize: '12px' }}>Submit</strong>
              </Button>
            </div>
          </div>
        ) : (
          undefined
        )}

        <div className='row'>
          <div className='col-md-12 col-sm-12 col-12'>
            <CustomTable
              tableData={reconciliationNotes}
              tableDataKeys={tableDataKeysForNotes}
              tableHeading={tableHeadingForNotes}
              action={action}
              handleView={handleViewReconsiliation}
              borderBottomColor={'#60d69f'}
              borderBottomWidth={20}
            />
          </div>
        </div>

        <div className='row' style={{ marginBottom: 20 }}>
          <div className='col-md-12 col-sm-12 col-12'>
            <img
              onClick={() => props.history.goBack()}
              src={Back}
              style={{
                width: 45,
                height: 35,
                cursor: 'pointer',
              }}
            />
          </div>
        </div>

        {openMedicationModel ? (
          <AddMedicationModel
            modelOpen={openMedicationModel}
            handleClose={handleOrderModelClose}
            handleAddMedicine={handleAddMedicine}
            selectedMedicineToEdit={selectedMedicineToEdit}
            handleUpdate={handleUpdate}
            // handleConfirmDisable={handleConfirmDisable}
            // disabled={selectedRecord.disabled}
          />
        ) : (
          undefined
        )}
      </div>

      <Notification msg={errorMsg} open={openNotification} />
    </div>
  )
}


const mapStateToProps = ({ PatientReducer }) => {
  const { selectedPatientDetails} = PatientReducer;
  return { selectedPatientDetails };
};
export default connect(mapStateToProps, {
  setSelectedPatientForReducer,
})(PharmaNotes);