export default function validatePhone2(phone, code) {
  // console.log('handleTelecomChange::', phone)
  // console.log('handleTelecomChange::', code)
  if (phone === "" || phone === "+" || code === ""){
    return false;
  }else{
    const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();
    try {
      // Parse number with country code and keep raw input.
      const number = phoneUtil.parseAndKeepRawInput(phone, code);  
      console.log('handleTelecomChange::', number)
      console.log('handleTelecomChange::', phoneUtil.isValidNumber(number))
      return phoneUtil.isValidNumber(number)===false
    } catch(e){
      return true;
    }
  }
  return true;
}
