/*eslint-disable*/
import React, { useState, useEffect, useReducer } from "react";
import Button from "@material-ui/core/Button";
import Notification from "../../components/Snackbar/Notification.js";
import CustomTable from "../../components/Table/Table";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Loader from "react-loader-spinner";
import TextField from "@material-ui/core/TextField";
import Header from "../../components/Header/Header";
import CurrentActivityIcon from "../../assets/img/ED RoomsCurrent Activity.png";
import Back from "../../assets/img/Back_Arrow.png";
import "../../assets/jss/material-dashboard-react/components/loaderStyle.css";
import cookie from "react-cookies";
import useStylesForInput from "../../assets/jss/material-dashboard-react/inputStyle"
import { styles, useStylesForTabs } from "./styles.js"
import {
    tableHeadingForEDBeds,
    tableDataKeysForEDBeds,
    tableHeadingForCC,
    tableDataKeysForCC,
    tableHeadingForCSinProcess,
    tableDataKeysForCSinProcess,
    tableHeadingForPatientTreatment,
    tableDataKeysForPatientTreatment,
    tableHeadingForMedReconciliation,
    tableDataKeysForMedReconciliation,
    tableHeadingForFlagsMgmt,
    tableDataKeysForFlagsMgmt,
    tableHeadingForTransferOfCare,
    tableDataKeysForTransferOfCare,
    tableHeadingForEDtoEOU,
    tableDataKeysForEDtoEOU,
    tableHeadingForTimeInterval,
    tableDataKeysForTimeInterval,
    tableHeadingForLabTests,
    tableDataKeysForLabTests,
    tableHeadingForRadiologyTests,
    tableDataKeysForRadiologyTests,
    tableHeadingForCriticalLab,
    tableDataKeysForCriticalLab,
    tableHeadingForDischargeReq,
    tableDataKeysForDischargeReq,
    tableHeadingForCriticalFunc,
    tableDataKeysForCriticalFunc,
    tableHeadingForCStimeInterval,
    tableDataKeysForCStimeInterval
} from "./currentActivityTables"
import {
    getCurrentActivityTableData
} from "./networkCallForEDMgmt"

const actionsForView = { view: true };

export default function CurrentActivity(props) {
    const classesForTabs = useStylesForTabs();
    const classes = useStylesForInput();

    const [currentUser] = useState(cookie.load("current_user"));
    const [errorMsg, setErrorMsg] = useState("");
    const [openNotification, setOpenNotification] = useState(false);
    const [openFilterModal, setOpenFilterModal] = useState(false);
    const [tabName, setTabName] = useState("Available ED Beds");
    const [tableData, setTableData] = useState([]);
    const [isLoading, setisLoading] = useState(true);

    if (openNotification) {
        setTimeout(() => {
            setOpenNotification(false);
            setErrorMsg("");
        }, 2000);
    }

    useEffect(() => {
        setTableData([])
        setisLoading(true)
        getCurrentActivityTableData(
            tabName,
            setTableData,
            setisLoading,
            setOpenNotification,
            setErrorMsg
        );
    }, [tabName]);

    const handleChange = (event, tabValue) => {
        setTabName(tabValue);
    };

    const handleFilteredTimeInterval = () => {
    }

    const handleViewMedRec = (e) => {
        let path = '/dashboard/home/pharmanotes/viewNotes'
        props.history.push({
            pathname: path,
            state: {
                selectedRecord: e.pharmacyRequest
            }
        })
    }

    const handleViewLabRad = (e) => {
        console.log(e)
        let pathName, path
        if (tabName === "Lab Tests") {
            pathName = '/dashboard/home/patientmanagement/viewrequests/lab/viewlab'
            path = 'lab'
        }
        else if (tabName === "Radiology Tests") {
            pathName = '/dashboard/home/patientmanagement/viewrequests/rad/viewrad'
            path = 'rad'
        }
        props.history.push({
            pathname: pathName,
            state: {
                selectedItem: e,
                path: path
            }
        })
    }

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                flex: 1,
                position: "fixed",
                width: "100%",
                height: "100%",
                backgroundColor: "#60d69f",
                overflowY: "scroll",
            }}
        >
            <Header history={props.history} />

            <div className="cPadding">
                <div className="subheader" style={{ paddingRight: '15px' }}>
                    <div>
                        <img src={CurrentActivityIcon} />
                        <h4>ED Rooms Current Activity</h4>
                    </div>
                </div>

                <div style={{ height: '20px' }} />

                <div className={classesForTabs.root}>
                    <Tabs
                        classes={{
                            root: classesForTabs.root,
                            scroller: classesForTabs.scroller,
                        }}
                        value={tabName}
                        onChange={handleChange}
                        textColor="primary"
                        variant="scrollable"
                        TabIndicatorProps={{ style: { background: "#12387a" } }}
                    >
                        <Tab
                            style={{
                                color: "white",
                                borderRadius: 5,
                                outline: "none",
                                color: tabName === "Available ED Beds" ? "#12387a" : "#3B988C",
                            }}
                            label="Available ED Beds"
                            value="Available ED Beds"
                        />
                        <Tab
                            style={{
                                color: "white",
                                borderRadius: 5,
                                outline: "none",
                                color: tabName === "Chief Complaint" ? "#12387a" : "#3B988C",
                            }}
                            label="Chief Complaint"
                            value="Chief Complaint"
                        />
                        <Tab
                            style={{
                                color: "white",
                                borderRadius: 5,
                                outline: "none",
                                color: tabName === "CareStream In Process" ? "#12387a" : "#3B988C",
                            }}
                            label="CareStream In Process"
                            value="CareStream In Process"
                        />

                        <Tab
                            style={{
                                color: "white",
                                borderRadius: 5,
                                outline: "none",
                                color:
                                    tabName === "Patient Treatment" ? "#12387a" : "#3B988C",
                            }}
                            label="Patient Treatment"
                            value="Patient Treatment"
                        />
                        <Tab
                            style={{
                                color: "white",
                                borderRadius: 5,
                                outline: "none",
                                color:
                                    tabName === "Medication Reconciliation"
                                        ? "#12387a"
                                        : "#3B988C",
                            }}
                            label="Medication Reconciliation"
                            value="Medication Reconciliation"
                        />
                        <Tab
                            style={{
                                color: "white",
                                borderRadius: 5,
                                outline: "none",
                                color: tabName === "Flags Management" ? "#12387a" : "#3B988C",
                            }}
                            label="Flags Management"
                            value="Flags Management"
                        />
                        <Tab
                            style={{
                                color: "white",
                                borderRadius: 5,
                                outline: "none",
                                color: tabName === "Transfer of Care" ? "#12387a" : "#3B988C",
                            }}
                            label="Transfer of Care"
                            value="Transfer of Care"
                        />
                        <Tab
                            style={{
                                color: "white",
                                borderRadius: 5,
                                outline: "none",
                                color: tabName === "ED to EOU Room Allocation" ? "#12387a" : "#3B988C",
                            }}
                            label="ED to EOU Room Allocation"
                            value="ED to EOU Room Allocation"
                        />
                        <Tab
                            style={{
                                color: "white",
                                borderRadius: 5,
                                outline: "none",
                                color: tabName === "Time Interval" ? "#12387a" : "#3B988C",
                            }}
                            label="Time Interval"
                            value="Time Interval"
                        />
                        <Tab
                            style={{
                                color: "white",
                                borderRadius: 5,
                                outline: "none",
                                color: tabName === "Lab Tests" ? "#12387a" : "#3B988C",
                            }}
                            label="Lab Tests"
                            value="Lab Tests"
                        />
                        <Tab
                            style={{
                                color: "white",
                                borderRadius: 5,
                                outline: "none",
                                color: tabName === "Radiology Tests" ? "#12387a" : "#3B988C",
                            }}
                            label="Radiology Tests"
                            value="Radiology Tests"
                        />
                        <Tab
                            style={{
                                color: "white",
                                borderRadius: 5,
                                outline: "none",
                                color: tabName === "Critical Lab Tests" ? "#12387a" : "#3B988C",
                            }}
                            label="Critical Lab Tests"
                            value="Critical Lab Tests"
                        />
                        <Tab
                            style={{
                                color: "white",
                                borderRadius: 5,
                                outline: "none",
                                color: tabName === "Discharge Requirements" ? "#12387a" : "#3B988C",
                            }}
                            label="Discharge Requirements"
                            value="Discharge Requirements"
                        />
                        <Tab
                            style={{
                                color: "white",
                                borderRadius: 5,
                                outline: "none",
                                color: tabName === "Critical Functional Calling" ? "#12387a" : "#3B988C",
                            }}
                            label="Critical Functional Calling"
                            value="Critical Functional Calling"
                        />
                        <Tab
                            style={{
                                color: "white",
                                borderRadius: 5,
                                outline: "none",
                                color: tabName === "CareStream Time Interval" ? "#12387a" : "#3B988C",
                            }}
                            label="CareStream Time Interval"
                            value="CareStream Time Interval"
                        />
                    </Tabs>
                </div>

                <div className='container-fluid'>

                    {tabName === "Available ED Beds" ? (
                        <div className="row">
                            {tableData.length > 0 && !isLoading ? (
                                <div className='col-md-12 col-sm-12 col-12'>
                                    <CustomTable
                                        tableData={tableData}
                                        tableDataKeys={tableDataKeysForEDBeds}
                                        tableHeading={tableHeadingForEDBeds}
                                        borderBottomColor={"#60d69f"}
                                        borderBottomWidth={20}
                                    />
                                </div>
                            ) : isLoading ? (
                                <div style={{ margin: "auto", width: "0%", padding: 10 }}>
                                    <Loader
                                        type="TailSpin"
                                        color="blue"
                                        height={50}
                                        width={50}
                                    />
                                </div>
                            ) : (
                                        <div className='col-md-12 col-sm-12 col-12'>
                                            <h3
                                                style={{
                                                    color: "white",
                                                    textAlign: "center",
                                                    margin: 'revert'
                                                }}
                                            >
                                                Opps! No Data Found
                                            </h3>
                                        </div>
                                    )}
                        </div>
                    ) : tabName === "Chief Complaint" ? (
                        <div className="row">
                            {tableData.length > 0 && !isLoading ? (
                                <div className='col-md-12 col-sm-12 col-12'>
                                    <CustomTable
                                        tableData={tableData}
                                        tableDataKeys={tableDataKeysForCC}
                                        tableHeading={tableHeadingForCC}
                                        borderBottomColor={"#60d69f"}
                                        borderBottomWidth={20}
                                    />
                                </div>
                            ) : isLoading ? (
                                <div style={{ margin: "auto", width: "0%", padding: 10 }}>
                                    <Loader
                                        type="TailSpin"
                                        color="blue"
                                        height={50}
                                        width={50}
                                    />
                                </div>
                            ) : (
                                        <div className='col-md-12 col-sm-12 col-12'>
                                            <h3
                                                style={{
                                                    color: "white",
                                                    textAlign: "center",
                                                    margin: 'revert'
                                                }}
                                            >
                                                Opps! No Data Found
                                                </h3>
                                        </div>
                                    )}
                        </div>
                    ) : tabName === "Patient Treatment" ? (
                        <div className="row">
                            {tableData.length > 0 && !isLoading ? (
                                <div className='col-md-12 col-sm-12 col-12'>
                                    <CustomTable
                                        tableData={tableData}
                                        tableDataKeys={tableDataKeysForPatientTreatment}
                                        tableHeading={tableHeadingForPatientTreatment}
                                        borderBottomColor={"#60d69f"}
                                        borderBottomWidth={20}
                                    />
                                </div>
                            ) : isLoading ? (
                                <div style={{ margin: "auto", width: "0%", padding: 10 }}>
                                    <Loader
                                        type="TailSpin"
                                        color="blue"
                                        height={50}
                                        width={50}
                                    />
                                </div>
                            ) : (
                                        <div className='col-md-12 col-sm-12 col-12'>
                                            <h3
                                                style={{
                                                    color: "white",
                                                    textAlign: "center",
                                                    margin: 'revert'
                                                }}
                                            >
                                                Opps! No Data Found
                                                </h3>
                                        </div>
                                    )}
                        </div>
                    ) : tabName === "CareStream In Process" ? (
                        <div className="row">
                            <div className='col-md-12 col-sm-12 col-12'>
                                <h3
                                    style={{
                                        color: "white",
                                        textAlign: "center",
                                        margin: 'revert'
                                    }}
                                >
                                    CareStream under development !!
                            </h3>
                            </div>
                            {/* {tableData.length > 0 && !isLoading ? (
                                <div className='col-md-12 col-sm-12 col-12'>
                                    <CustomTable
                                        tableData={tableData}
                                        tableDataKeys={tableDataKeysForCSinProcess}
                                        tableHeading={tableHeadingForCSinProcess}
                                        borderBottomColor={"#60d69f"}
                                        borderBottomWidth={20}
                                    />
                                </div>
                            ) : isLoading ? (
                                <div style={{ margin: "auto", width: "0%", padding: 10 }}>
                                    <Loader
                                        type="TailSpin"
                                        color="blue"
                                        height={50}
                                        width={50}
                                    />
                                </div>
                            ) : (
                                        <div className='col-md-12 col-sm-12 col-12'>
                                            <h3
                                                style={{
                                                    color: "white",
                                                    textAlign: "center",
                                                    margin: 'revert'
                                                }}
                                            >
                                                Opps! No Data Found
                                                </h3>
                                        </div>
                                    )} */}
                        </div>
                    ) : tabName === "Medication Reconciliation" ? (
                        <div className="row">
                            {tableData.length > 0 && !isLoading ? (
                                <div className='col-md-12 col-sm-12 col-12'>
                                    <CustomTable
                                        tableData={tableData}
                                        tableDataKeys={tableDataKeysForMedReconciliation}
                                        tableHeading={tableHeadingForMedReconciliation}
                                        action={actionsForView}
                                        handleView={handleViewMedRec}
                                        borderBottomColor={"#60d69f"}
                                        borderBottomWidth={20}
                                    />
                                </div>
                            ) : isLoading ? (
                                <div style={{ margin: "auto", width: "0%", padding: 10 }}>
                                    <Loader
                                        type="TailSpin"
                                        color="blue"
                                        height={50}
                                        width={50}
                                    />
                                </div>
                            ) : (
                                        <div className='col-md-12 col-sm-12 col-12'>
                                            <h3
                                                style={{
                                                    color: "white",
                                                    textAlign: "center",
                                                    margin: 'revert'
                                                }}
                                            >
                                                Opps! No Data Found
                                                </h3>
                                        </div>
                                    )}
                        </div>
                    ) : tabName === "Flags Management" ? (
                        <div className="row">
                            <div className='col-md-12 col-sm-12 col-12'>
                                <h3
                                    style={{
                                        color: "white",
                                        textAlign: "center",
                                        margin: 'revert'
                                    }}
                                >
                                    Flag Management under development !!
                            </h3>
                            </div>
                            {/* {tableData.length > 0 && !isLoading ? (
                                <div className='col-md-12 col-sm-12 col-12'>
                                    <CustomTable
                                        tableData={tableData}
                                        tableDataKeys={tableDataKeysForFlagsMgmt}
                                        tableHeading={tableHeadingForFlagsMgmt}
                                        borderBottomColor={"#60d69f"}
                                        borderBottomWidth={20}
                                    />
                                </div>
                            ) : isLoading ? (
                                <div style={{ margin: "auto", width: "0%", padding: 10 }}>
                                    <Loader
                                        type="TailSpin"
                                        color="blue"
                                        height={50}
                                        width={50}
                                    />
                                </div>
                            ) : (
                                        <div className='col-md-12 col-sm-12 col-12'>
                                            <h3
                                                style={{
                                                    color: "white",
                                                    textAlign: "center",
                                                    margin: 'revert'
                                                }}
                                            >
                                                Opps! No Data Found
                                                </h3>
                                        </div>
                                    )} */}
                        </div>
                    ) : tabName === "Transfer of Care" ? (
                        <div className="row">
                            <div className='col-md-12 col-sm-12 col-12'>
                                <h3
                                    style={{
                                        color: "white",
                                        textAlign: "center",
                                        margin: 'revert'
                                    }}
                                >
                                    Transfer Of Care under development !!
                            </h3>
                            </div>
                            {/* {tableData.length > 0 && !isLoading ? (
                                <div className='col-md-12 col-sm-12 col-12'>
                                    <CustomTable
                                        tableData={tableData}
                                        tableDataKeys={tableDataKeysForTransferOfCare}
                                        tableHeading={tableHeadingForTransferOfCare}
                                        borderBottomColor={"#60d69f"}
                                        borderBottomWidth={20}
                                    />
                                </div>
                            ) : isLoading ? (
                                <div style={{ margin: "auto", width: "0%", padding: 10 }}>
                                    <Loader
                                        type="TailSpin"
                                        color="blue"
                                        height={50}
                                        width={50}
                                    />
                                </div>
                            ) : (
                                        <div className='col-md-12 col-sm-12 col-12'>
                                            <h3
                                                style={{
                                                    color: "white",
                                                    textAlign: "center",
                                                    margin: 'revert'
                                                }}
                                            >
                                                Opps! No Data Found
                                                </h3>
                                        </div>
                                    )} */}
                        </div>
                    ) : tabName === "ED to EOU Room Allocation" ? (
                        <div className="row">
                            <div className='col-md-12 col-sm-12 col-12'>
                                <h3
                                    style={{
                                        color: "white",
                                        textAlign: "center",
                                        margin: 'revert'
                                    }}
                                >
                                    Room Allocation under development !!
                            </h3>
                            </div>
                            {/* {tableData.length > 0 && !isLoading ? (
                                <div className='col-md-12 col-sm-12 col-12'>
                                    <CustomTable
                                        tableData={tableData}
                                        tableDataKeys={tableDataKeysForEDtoEOU}
                                        tableHeading={tableHeadingForEDtoEOU}
                                        action={actionsForEdit}
                                        handleEdit={handleEditEDEOU}
                                        borderBottomColor={"#60d69f"}
                                        borderBottomWidth={20}
                                    />
                                </div>
                            ) : isLoading ? (
                                <div style={{ margin: "auto", width: "0%", padding: 10 }}>
                                    <Loader
                                        type="TailSpin"
                                        color="blue"
                                        height={50}
                                        width={50}
                                    />
                                </div>
                            ) : (
                                        <div className='col-md-12 col-sm-12 col-12'>
                                            <h3
                                                style={{
                                                    color: "white",
                                                    textAlign: "center",
                                                    margin: 'revert'
                                                }}
                                            >
                                                Opps! No Data Found
                                            </h3>
                                        </div>
                                    )} */}
                        </div>
                    ) : tabName === "Time Interval" ? (
                        <div className="row">
                            {tableData.length > 0 && !isLoading ? (
                                <div className='col-md-12 col-sm-12 col-12'>
                                    <CustomTable
                                        tableData={tableData}
                                        tableDataKeys={tableDataKeysForTimeInterval}
                                        tableHeading={tableHeadingForTimeInterval}
                                        borderBottomColor={"#60d69f"}
                                        borderBottomWidth={20}
                                    />
                                </div>
                            ) : isLoading ? (
                                <div style={{ margin: "auto", width: "0%", padding: 10 }}>
                                    <Loader
                                        type="TailSpin"
                                        color="blue"
                                        height={50}
                                        width={50}
                                    />
                                </div>
                            ) : (
                                        <div className='col-md-12 col-sm-12 col-12'>
                                            <h3
                                                style={{
                                                    color: "white",
                                                    textAlign: "center",
                                                    margin: 'revert'
                                                }}
                                            >
                                                Opps! No Data Found
                                                </h3>
                                        </div>
                                    )}
                        </div>
                    ) : tabName === "Lab Tests" ? (
                        <div className="row">
                            {tableData.length > 0 && !isLoading ? (
                                <div className='col-md-12 col-sm-12 col-12'>
                                    <CustomTable
                                        tableData={tableData}
                                        tableDataKeys={tableDataKeysForLabTests}
                                        tableHeading={tableHeadingForLabTests}
                                        action={actionsForView}
                                        handleView={handleViewLabRad}
                                        borderBottomColor={"#60d69f"}
                                        borderBottomWidth={20}
                                    />
                                </div>
                            ) : isLoading ? (
                                <div style={{ margin: "auto", width: "0%", padding: 10 }}>
                                    <Loader
                                        type="TailSpin"
                                        color="blue"
                                        height={50}
                                        width={50}
                                    />
                                </div>
                            ) : (
                                        <div className='col-md-12 col-sm-12 col-12'>
                                            <h3
                                                style={{
                                                    color: "white",
                                                    textAlign: "center",
                                                    margin: 'revert'
                                                }}
                                            >
                                                Opps! No Data Found
                                                </h3>
                                        </div>
                                    )}
                        </div>
                    ) : tabName === "Radiology Tests" ? (
                        <div className="row">
                            {tableData.length > 0 && !isLoading ? (
                                <div className='col-md-12 col-sm-12 col-12'>
                                    <CustomTable
                                        tableData={tableData}
                                        tableDataKeys={tableDataKeysForRadiologyTests}
                                        tableHeading={tableHeadingForRadiologyTests}
                                        action={actionsForView}
                                        handleView={handleViewLabRad}
                                        borderBottomColor={"#60d69f"}
                                        borderBottomWidth={20}
                                    />
                                </div>
                            ) : isLoading ? (
                                <div style={{ margin: "auto", width: "0%", padding: 10 }}>
                                    <Loader
                                        type="TailSpin"
                                        color="blue"
                                        height={50}
                                        width={50}
                                    />
                                </div>
                            ) : (
                                        <div className='col-md-12 col-sm-12 col-12'>
                                            <h3
                                                style={{
                                                    color: "white",
                                                    textAlign: "center",
                                                    margin: 'revert'
                                                }}
                                            >
                                                Opps! No Data Found
                                                </h3>
                                        </div>
                                    )}
                        </div>
                    ) : tabName === "Critical Lab Tests" ? (
                        <div className="row">
                            {tableData.length > 0 && !isLoading ? (
                                <div className='col-md-12 col-sm-12 col-12'>
                                    <CustomTable
                                        tableData={tableData}
                                        tableDataKeys={tableDataKeysForCriticalLab}
                                        tableHeading={tableHeadingForCriticalLab}
                                        borderBottomColor={"#60d69f"}
                                        borderBottomWidth={20}
                                    />
                                </div>
                            ) : isLoading ? (
                                <div style={{ margin: "auto", width: "0%", padding: 10 }}>
                                    <Loader
                                        type="TailSpin"
                                        color="blue"
                                        height={50}
                                        width={50}
                                    />
                                </div>
                            ) : (
                                        <div className='col-md-12 col-sm-12 col-12'>
                                            <h3
                                                style={{
                                                    color: "white",
                                                    textAlign: "center",
                                                    margin: 'revert'
                                                }}
                                            >
                                                Opps! No Data Found
                                                </h3>
                                        </div>
                                    )}
                        </div>
                    ) : tabName === "Discharge Requirements" ? (
                        <div className="row">
                            {tableData.length > 0 && !isLoading ? (
                                <div className='col-md-12 col-sm-12 col-12'>
                                    <CustomTable
                                        tableData={tableData}
                                        tableDataKeys={tableDataKeysForDischargeReq}
                                        tableHeading={tableHeadingForDischargeReq}
                                        borderBottomColor={"#60d69f"}
                                        borderBottomWidth={20}
                                    />
                                </div>
                            ) : isLoading ? (
                                <div style={{ margin: "auto", width: "0%", padding: 10 }}>
                                    <Loader
                                        type="TailSpin"
                                        color="blue"
                                        height={50}
                                        width={50}
                                    />
                                </div>
                            ) : (
                                        <div className='col-md-12 col-sm-12 col-12'>
                                            <h3
                                                style={{
                                                    color: "white",
                                                    textAlign: "center",
                                                    margin: 'revert'
                                                }}
                                            >
                                                Opps! No Data Found
                                                </h3>
                                        </div>
                                    )}
                        </div>
                    ) : tabName === "Critical Functional Calling" ? (
                        <div className="row">
                            <div className='col-md-12 col-sm-12 col-12'>
                                <h3
                                    style={{
                                        color: "white",
                                        textAlign: "center",
                                        margin: 'revert'
                                    }}
                                >
                                    Critical function calling under development !!
                            </h3>
                            </div>
                            {/* {tableData.length > 0 && !isLoading ? (
                                <div className='col-md-12 col-sm-12 col-12'>
                                    <CustomTable
                                        tableData={tableData}
                                        tableDataKeys={tableDataKeysForCriticalFunc}
                                        tableHeading={tableHeadingForCriticalFunc}
                                        borderBottomColor={"#60d69f"}
                                        borderBottomWidth={20}
                                    />
                                </div>
                            ) : isLoading ? (
                                <div style={{ margin: "auto", width: "0%", padding: 10 }}>
                                    <Loader
                                        type="TailSpin"
                                        color="blue"
                                        height={50}
                                        width={50}
                                    />
                                </div>
                            ) : (
                                        <div className='col-md-12 col-sm-12 col-12'>
                                            <h3
                                                style={{
                                                    color: "white",
                                                    textAlign: "center",
                                                    margin: 'revert'
                                                }}
                                            >
                                                Opps! No Data Found
                                                </h3>
                                        </div>
                                    )} */}
                        </div>
                    ) : tabName === "CareStream Time Interval" ? (
                        <div className="row">
                            <div className='col-md-12 col-sm-12 col-12'>
                                <h3
                                    style={{
                                        color: "white",
                                        textAlign: "center",
                                        margin: 'revert'
                                    }}
                                >
                                    CareStream under development !!
                            </h3>
                            </div>
                            {/* {tableData.length > 0 && !isLoading ? (
                                <div className='col-md-12 col-sm-12 col-12'>
                                    <CustomTable
                                        tableData={tableData}
                                        tableDataKeys={tableDataKeysForCStimeInterval}
                                        tableHeading={tableHeadingForCStimeInterval}
                                        borderBottomColor={"#60d69f"}
                                        borderBottomWidth={20}
                                    />
                                </div>
                            ) : isLoading ? (
                                <div style={{ margin: "auto", width: "0%", padding: 10 }}>
                                    <Loader
                                        type="TailSpin"
                                        color="blue"
                                        height={50}
                                        width={50}
                                    />
                                </div>
                            ) : (
                                        <div className='col-md-12 col-sm-12 col-12'>
                                            <h3
                                                style={{
                                                    color: "white",
                                                    textAlign: "center",
                                                    margin: 'revert'
                                                }}
                                            >
                                                Opps! No Data Found
                                                </h3>
                                        </div>
                                    )} */}
                        </div>
                    ) : (
                                                                                    <div className="LoaderStyle">
                                                                                        <Loader type="TailSpin" color="red" height={50} width={50} />
                                                                                    </div>
                                                                                )}

                    <div className="row" style={{ marginBottom: 20 }}>
                        <div className='col-md-12 col-sm-12 col-12'>
                            <img
                                onClick={() => props.history.goBack()}
                                src={Back}
                                style={{
                                    width: 45,
                                    height: 35,
                                    cursor: "pointer",
                                }}
                            />
                        </div>
                    </div>
                    <Notification msg={errorMsg} open={openNotification} />
                </div>

                <Dialog
                    onClose={() => setOpenFilterModal(false)}
                    fullWidth={true}
                    maxWidth={'lg'}
                    
                    
                    aria-labelledby='simple-dialog-title'
                    open={openFilterModal}
                >
                    <DialogTitle id='simple-dialog-title'>
                        <h4>Select Time Interval</h4>
                    </DialogTitle>
                    <DialogContent>
                        <div className={`container-fluid ${classes.root}`}>
                            <div className='row'>
                                <div className='col-md-6 col-sm-6 col-6'>
                                    <TextField
                                        select
                                        fullWidth
                                        label="Start Date"
                                        name={"startDate"}
                                        value={"startDate"}
                                        // onChange={dropDownHandler}
                                        variant="filled"
                                        className='dropDownStyle'
                                        InputProps={{
                                            className: classes.input,
                                            classes: { input: classes.input },
                                            disableUnderline: true
                                        }}
                                    >
                                        {/* {props.dropDownArray.map((array) => {
                                            return (
                                                <MenuItem key={array.key} value={array.value}>
                                                    {array.value}
                                                </MenuItem>
                                            );
                                        })} */}
                                    </TextField>
                                </div>
                                <div className='col-md-6 col-sm-6 col-6'>
                                    <TextField
                                        select
                                        fullWidth
                                        label="End Date"
                                        name={"endDate"}
                                        // value={"endDate"}
                                        // onChange={dropDownHandler}
                                        variant="filled"
                                        className='dropDownStyle'
                                        InputProps={{
                                            className: classes.input,
                                            classes: { input: classes.input },
                                            disableUnderline: true
                                        }}
                                    >
                                        {/* {props.dropDownArray.map((array) => {
                                            return (
                                                <MenuItem key={array.key} value={array.value}>
                                                    {array.value}
                                                </MenuItem>
                                            );
                                        })} */}
                                    </TextField>
                                </div>
                            </div>
                            <div className='row' style={{ marginTop: '10px' }}>
                                <div className='col-md-6 col-sm-6 col-6'>
                                    <TextField
                                        select
                                        fullWidth
                                        label="Start Time"
                                        name={"startTime"}
                                        // value={"startTime"}
                                        // onChange={dropDownHandler}
                                        variant="filled"
                                        className='dropDownStyle'
                                        InputProps={{
                                            className: classes.input,
                                            classes: { input: classes.input },
                                            disableUnderline: true
                                        }}
                                    >
                                        {/* {props.dropDownArray.map((array) => {
                                            return (
                                                <MenuItem key={array.key} value={array.value}>
                                                    {array.value}
                                                </MenuItem>
                                            );
                                        })} */}
                                    </TextField>
                                </div>
                                <div className='col-md-6 col-sm-6 col-6'>
                                    <TextField
                                        select
                                        fullWidth
                                        label="End Time"
                                        name={"endTime"}
                                        // value={"endTime"}
                                        // onChange={dropDownHandler}
                                        variant="filled"
                                        className='dropDownStyle'
                                        InputProps={{
                                            className: classes.input,
                                            classes: { input: classes.input },
                                            disableUnderline: true
                                        }}
                                    >
                                        {/* {props.dropDownArray.map((array) => {
                                            return (
                                                <MenuItem key={array.key} value={array.value}>
                                                    {array.value}
                                                </MenuItem>
                                            );
                                        })} */}
                                    </TextField>
                                </div>
                            </div>
                        </div>
                    </DialogContent>

                    <DialogActions style={{ display: 'flex', justifyContent: 'center' }}>
                        <Button
                            style={styles.stylesForCloseButton}
                            onClick={() => setOpenFilterModal(false)}
                            variant='contained'
                        >
                            Close
                        </Button>
                        &nbsp;
                        <Button
                            style={styles.stylesForButton}
                            onClick={handleFilteredTimeInterval}
                            variant='contained'
                        >
                            Done
                        </Button>
                    </DialogActions>
                </Dialog>

            </div>
        </div>
    );
}