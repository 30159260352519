import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Header from "../../../../components/Header/HeaderGrey";
import {
  addTriageAssesment,
  asignCareStream,
} from "../../../../public/endpoins";
import TriageAndAssessmentIcon from "../../../../assets/img/Triage & Assessment Icon.png";
import DownloadIcon from "../../../../assets/img/Download Icon.png";
import PrintIcon from "../../../../assets/img/Print Icon.png";
import Button from "@material-ui/core/Button";
import DetailBlock from "../../../../components/DHR/DCD/ViewScreen/detailBlock";
import axios from "axios";
import _, { lastIndexOf } from "lodash";


const styles = {
  textFieldPadding: {
    paddingLeft: 5,
    paddingRight: 5,
  },
  save: {
    cursor: "pointer",
    borderRadius: 5,
    backgroundColor: "#2973CF",
    width: "180px",
    height: "50px",
    outline: "none",
    color: "white",
  },
  Edit: {
    cursor: "pointer",
    borderRadius: 5,
    width: "180px",
    height: "50px",
    outline: "none",
    border: "2px solid gray",
    backgroundColor: "#FEFEFE",
  },
};
const detailsBlockArray = [
  {
    heading: "Clinical Impression",
    subArray: [
      {
        subheading: "Template Complete",
        description: "Lorem Ipsum Text.",
      },
    ],
  },
];
const useStylesForInput = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(0),
  },
  input: {
    backgroundColor: "white",
    boxShadow: "none",
    borderRadius: 5,
    "&:after": {
      borderBottomColor: "black",
      boxShadow: "none",
    },
    "&:hover": {
      backgroundColor: "white",
      boxShadow: "none",
    },
    "&:focus": {
      backgroundColor: "white",
      boxShadow: "none",
      borderRadius: 5,
    },
  },
  root: {
    "& .MuiTextField-root": {
      backgroundColor: "white",
    },
    "& .Mui-focused": {
      backgroundColor: "white",
      color: "black",
      boxShadow: "none",
    },
    "& .Mui-disabled": {
      backgroundColor: "white",
      color: "gray",
    },
    "&:focus": {
      backgroundColor: "white",
      boxShadow: "none",
    },
    "& .MuiFormLabel-root": {
      fontSize: "12px",
      paddingRight: "15px",
    },
  },
  label: {
    "&$focusedLabel": {
      color: "red",
      display: "none",
    },
  },
}));
const actions = { view: true };
export default function ViewPhysicalExam(props) {
  const classes = useStylesForInput();
  const [openNotification, setOpenNotification] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  useEffect(() => {}, []);

  console.log("PROPS IN VIEW", props);
  const handleClick = () => {
    console.log("Clicked", props);
    // console.log("Clicked", addTriageAssesment, { data: props.propsData.selectedTriage });
    axios
      .put(addTriageAssesment, { data: props.propsData.selectedTriage })
      .then((res) => {
        if (res.data.success) {
          console.log("Response Triage n Assesment", res.data);
          // window.location.reload();
          console.log("session storage", sessionStorage.getItem("goBack"));
          props.allProps.propsData.history.go(
            JSON.parse(sessionStorage.getItem("goBack"))
          );
          // propsp.allPros.propsData.history.location.push({
          //   pathname: "/dashboard/home/dcddashboard",
          //   // propsData: props.allProps.propsData,
          // });
        } else if (!res.data.success) {
          setOpenNotification(true);
          setErrorMsg("Error in Submitting Assessment");
        }
      })
      .catch((e) => {
        console.log("error after submitting Assessment", e);
        setOpenNotification(true);
        setErrorMsg("Error while submitting Assessment");
      });
  };

  const handleEdit = () => {
    props.allProps.propsData.history.goBack();
  };

  const handleEditCareStream = () => {
    props.allProps.propsData.history.goBack();
  };

  const handleAddCareStream = () => {
    console.log("PROPS", props.allProps.propsData.location.state);

    console.log("what is this", props.propsData.careStream);
    axios
      .put(asignCareStream, {
        data: {
          ...props.propsData.careStream,
          medications: props.propsData.careStream.medicationListWithObj,
        },
      })
      .then((response) => {
        console.log(
          response,
          "Response",
          response.data.data.careStream[
            response.data.data.careStream.length - 1
          ].careStreamId.identifier[0].value
        );
        props.allProps.propsData.history.push({
          pathname: "/dashboard/home/success",
          state: {
            message1: `CareStream `,
            reqCode: `${
              response.data.data.careStream[
                response.data.data.careStream.length - 1
              ].careStreamId.identifier[0].value
            }`,
            message3: ` has been submitted successfully for patient `,
            code: `MRN: ${props.allProps.propsData.location.state.patientMRN}`,
          },
        });
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };

  console.log("selectedSingleTriage", props.selectedSingleTriage);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        position: "fixed",
        width: "100%",
        height: "100%",
        overflowY: "scroll",
        border: "1p",
      }}
    >
      <Header history={props.history} />
      <div className="cPadding">
        <div className="subheader" style={{ marginBottom: 45 }}>
          <div className="col-md-6 col-6">
            <img src={TriageAndAssessmentIcon} />
            <h4
              style={{
                color: "#000000",
                fontSize: "40px",
                fontWeight: "bolder",
              }}
            >
              {props.heading}
            </h4>
          </div>
          <div className="col-md-6 col-6">
            
          </div>
        </div>
        <div
          style={{ flex: 4, display: "flex", flexDirection: "column" }}
          className={`${"container-fluid"} ${classes.root}`}
        >
          {props.selectedSingleTriage &&
            props.selectedSingleTriage.map((arr) => {
              return (
                <DetailBlock
                  heading={arr.heading}
                  subArray={arr.subArray}
                  columnSize={arr.columnSize}
                />
              );
            })}

          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "30px",
                marginBottom: "2%",
              }}
              className="row"
            >
              <>
                <Button
                  style={styles.Edit}
                  variant="contained"
                  color="default"
                  onClick={
                    props.heading === "Summary"
                      ? handleEditCareStream
                      : handleEdit
                  }
                >
                  Edit
                </Button>
                &nbsp;&nbsp;
                <Button
                  style={styles.save}
                  variant="contained"
                  color="default"
                  onClick={
                    props.heading === "Summary"
                      ? handleAddCareStream
                      : handleClick
                  }
                >
                  Save
                </Button>
              </>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
