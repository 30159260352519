import axios from "axios";
import {
  getEDRsWithPharmacyRequestURL,
  getAllDeliverInProgessPharmaRequestURL,
} from "../../public/endpoins";
import FormatDate from "../../utils/formatDate";

export function getEDRsWithPharmacyRequest(
  setlabInPatient,
  setOpenNotification,
  setErrorMsg,
  requestType
) {
  console.log(requestType);
  axios
    .get(getEDRsWithPharmacyRequestURL + "/" + requestType)
    .then((res) => {
      if (res.data.success) {
        res.data.data.map((d) => {
          d.patientName = d.patientId.name[0].given[0] + " " + d.patientId.name[0].family;
          d.createdAt = d.createdTimeStamp;
          if (requestType === 'Discharge Medication Request'){
            d.mrn                   = d.requestNo;
            d.roomNo                = d.room[d.room.length - 1].roomId.roomNo;
            d.age                   = d.patientId.age;
            d.gender                = d.patientId.gender;
            d.weight                = d.patientId.weight;
            d.department            = d.currentLocation;
            d.chiefComplaint        = d.chiefComplaint.length > 0 ? d.chiefComplaint.pop().chiefComplaintId.short_desc : "N/A";
            d.chiefComplaintOthers  = d.chiefComplaintOthers;
          }else{
            (d.mrn = d.patientId.identifier[0].value);
            (d.roomNo = d.room[d.room.length - 1].roomId.roomNo);
            (d.productionArea =
              d.chiefComplaint[
                d.chiefComplaint.length - 1
              ].chiefComplaintId.productionArea[
                d.chiefComplaint[d.chiefComplaint.length - 1].chiefComplaintId
                  .productionArea.length - 1
              ].productionAreaId.paName);
            (d.pharmaRequestStatus = d.pharmacyRequest[0].status);
            // (d.createdAt = d.pharmacyRequest[0].createdAt);

            // (d.careStream =
            //   d.careStream &&
            //   d.careStream.length > 0 &&
            //   d.careStream[d.careStream.length - 1].careStreamId.name)
          }
        });
        console.log("response", res.data.data);
        setlabInPatient(res.data.data.reverse());
      } else if (!res.data.success) {
        setErrorMsg(res.data.error);
        setOpenNotification(true);
      }
      return res;
    })
    .catch((e) => {
      console.log("error: ", e);
    });
}

export function getCompletedPharamRequests(
  setlabInPatient,
  setOpenNotification,
  setErrorMsg,
  requestType
) {
  axios
    .get(getAllDeliverInProgessPharmaRequestURL + "/" + requestType)
    .then((res) => {
      if (res.data.success) {
        res.data.data.map(
          (d) => (
            (d.patientName =
              d.patientId.name[0].given[0] + " " + d.patientId.name[0].family),
            (d.createdAt = d.patientId.createdAt),
            (d.mrn = d.patientId.identifier[0].value),
            (d.roomNo = d.room[d.room.length - 1].roomId.roomNo),
            (d.productionArea =
              d.chiefComplaint[
                d.chiefComplaint.length - 1
              ].chiefComplaintId.productionArea[
                d.chiefComplaint[d.chiefComplaint.length - 1].chiefComplaintId
                  .productionArea.length - 1
              ].productionAreaId.paName),
            (d.pharmaRequestStatus = d.pharmacyRequest[0].status),
            (d.createdAt = d.pharmacyRequest[0].createdAt)

            // (d.careStream =
            //   d.careStream &&
            //   d.careStream.length > 0 &&
            //   d.careStream[d.careStream.length - 1].careStreamId.name)
          )
        );
        console.log("response", res.data.data);
        setlabInPatient(res.data.data.reverse());
      } else if (!res.data.success) {
        setErrorMsg(res.data.error);
        setOpenNotification(true);
      }
      return res;
    })
    .catch((e) => {
      console.log("error: ", e);
    });
}
