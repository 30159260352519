/*eslint-disable*/
import React, { useState, useEffect, useReducer } from 'react'
// import "./staffStyles.js";
import Button from '@material-ui/core/Button'
import Notification from '../../components/Snackbar/Notification.js'
import CustomTable from '../../components/Table/Table'
import Loader from 'react-loader-spinner'
import Header from '../../components/Header/Header'
import business_Unit from '../../assets/img/Sensei.png'
import plus_icon from '../../assets/img/Plus.png'
import Back from '../../assets/img/Back_Arrow.png'
import { makeStyles } from '@material-ui/core/styles'
import { assignPatientStyles } from '../../views/CustomerCare/styles'
import '../../assets/jss/material-dashboard-react/components/loaderStyle.css'
import cookie from 'react-cookies'

import getTimeFromDate from '../../utils/extractTimeFromDate'

import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Filter from '../../assets/img/Filter.png'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import AccountCircle from '@material-ui/icons/SearchOutlined'

import Chip from '@material-ui/core/Chip'
import InputAdornment from '@material-ui/core/InputAdornment'

import DateFnsUtils from '@date-io/date-fns'
import {
  DatePicker,
  MuiPickersUtilsProvider,
  TimePicker,
} from '@material-ui/pickers'
import BarCode from '../../assets/img/Bar Code.png'

import Fingerprint from '../../assets/img/fingerprint.png'
// import Filter from "../../../../assets/img/Filter.png";

import { getAllEDNurses, getSearchEDNurses } from './NetworkCall'
import { getNursesSpecialtyFunc } from '../UserManagement/staff/networkCallsForStaff'

import TextField from '@material-ui/core/TextField'
const useStyles = makeStyles((theme) => ({
  input: {
    '&:focus': {
      backgroundColor: 'white',
      boxShadow: 'none',
      borderRadius: 5,
    },
  },
  root: {
    '& .Mui-focused': {
      backgroundColor: 'white',
      color: 'black',
      boxShadow: 'none',
    },
    '&:focus': {
      backgroundColor: 'white',
      boxShadow: 'none',
    },
    '& .MuiFormLabel-root': {
      fontSize: '10px',
      paddingRight: '45px',
    },
  },
}))

const styles = {
  stylesForButton: {
    color: 'white',
    cursor: 'pointer',
    // borderRadius: 5,
    background: '#2c6ddd',
    // width: "140px",
    height: '50px',
    outline: 'none',
  },
}

const tableHeadingForDoctors = [
  'Nurse ID',
  'Nurse Name',
  'Speciality',
  //   "Date/Time",
  'Shift Start Time',
  'Shift End Time',
]
const tableDataKeysForDoctors = [
  'nurseId',
  'nurseName',
  'specialty',
  //   "createdAt",
  'shiftStartTime',
  'shiftEndTime',
]

const actions = { view: false }

export default function Staff(props) {
  const classes1 = useStyles()
  const classes = assignPatientStyles()
  const matches = useMediaQuery('(min-width:600px)')
  const [staffData, setStaffData] = useState([])
  const [generalArray, setGeneralArray] = useState([])
  const [filteredArray, setFilteredArray] = useState([])

  const [errorMsg, setErrorMsg] = useState('')
  const [openNotification, setOpenNotification] = useState(false)
  const [nursesSpeciality, setNursesSpeciality] = useState([])
  const [filterDialog, setFilterDialog] = useState(false)
  const [shiftStartTime, setShiftStartTime] = useState('')
  const [shiftEndTime, setShiftEndTime] = useState('')
  const [speciality, setSpeciality] = useState([])
  const [shift, setShift] = useState('')
  const [searchPatientQuery, setSearchPatientQuery] = useState('')

  if (openNotification) {
    setTimeout(() => {
      setOpenNotification(false)
      setErrorMsg('')
    }, 2000)
  }

  useEffect(() => {
    getAllEDNurses(
      setStaffData,
      setGeneralArray,
      setOpenNotification,
      setErrorMsg
    )
    getNursesSpecialtyFunc(
      setNursesSpeciality,
      setOpenNotification,
      setErrorMsg
    )
  }, [])

  // useEffect(() => {
  //   setValuesInData(generalArray)
  // }, [generalArray])

  function setValuesInData(generalArray) {
    let EDNursesData = generalArray.map((d) => {
      let obj = {
        ...d,
        // res.data.data.map(
        //   (val) => (
        //     (val.nurseName = val.name[0].given[0] + ' ' + val.name[0].family),
        //     (val.nurseId = val.identifier[0].value)
        //   )
        // )
        nurseName: d.name[0].given[0] + ' ' + d.name[0].family,
        nurseId: d.identifier[0].value,
        // addedByName:
        //   d.addedBy.name[0] &&
        //   d.addedBy.name[0].given[0] + ' ' + d.addedBy.name[0].family,
        shiftStartTime: d.shift
          ? getTimeFromDate(d.shift.startTime)
          : 'N/A',
        shiftEndTime: d.shift ? getTimeFromDate(d.shift.endTime) : 'N/A',
        // subType: d.subType.toString(),
        specialty: d.specialty.toString(),
        // experience:
        //   d.experience.length > 0
        //     ? d.experience[d.experience.length - 1].experience
        //     : 'N/A',
        // availability: d.availability ? 'Yes' : 'No',
      }
      return obj
    })

    setStaffData([...EDNursesData.reverse()])
  }

  useEffect(() => {
    setValuesInData(generalArray)
  }, [generalArray])

  const setDialogOpen = () => {
    setFilterDialog(true)
  }

  const handleClose = () => {
    setFilterDialog(false)
  }

  const handleChangeDate = (value, field) => {
    if (field === 'shiftStartTime') {
      setShiftStartTime(value ? value.toISOString() : '')
    } else setShiftEndTime(value ? value.toISOString() : '')
  }

  const handleRemove = (val, type) => {
    if (type === 'speciality') {
      let spec = speciality.filter((s) => s !== val)
      setSpeciality(spec)
    } else {
      let spec = consultantType.filter((s) => s !== val)
      setConsultantType(spec)
    }
  }

  const checkValueAdded = (val, type) => {
    if (type === 'speciality') {
      let spec = speciality.find((s) => s === val)
      return spec
    }
  }

  const handleAddedValue = (val, type) => {
    if (type === 'speciality') {
      let spec = speciality.find((s) => s === val)
      if (!spec) {
        setSpeciality((pervState) => [...pervState, val])
      }
    }
  }

  function handleApplyFilters() {
    let res = [...generalArray]
    let resForSpeciality = []
    let resForTimeStart = []
    let resForTimeEnd = []

    if (speciality.length > 0) {
      for (let j = 0; j < res.length; j++) {
        for (let i = 0; i < speciality.length; i++) {
          if (res[j].specialty.includes(speciality[i])) {
            if (!resForSpeciality.find((i) => i._id === res[j]._id)) {
              resForSpeciality.push(res[j])
            }
          }
        }
      }
      res = resForSpeciality
    }

    if (shiftStartTime) {
      for (let i = 0; i < res.length; i++) {
        if (res[i].shift) {
          let sHForDb = new Date(res[i].shift.startTime)
          let sHForFilter = new Date(shiftStartTime)

          sHForDb.setSeconds(0, 0)
          sHForFilter.setSeconds(0, 0)

          let startHoursForDb = sHForDb.toISOString()
          let startHoursForFilter = sHForFilter.toISOString()

          if (
            startHoursForDb.split('T')[1] === startHoursForFilter.split('T')[1]
          ) {
            if (!resForTimeStart.find((i) => i._id === res[i]._id)) {
              resForTimeStart.push(res[i])
            }
          }
        }
      }

      res = resForTimeStart
    }

    if (shiftEndTime) {
      for (let i = 0; i < res.length; i++) {
        if (res[i].shift) {
          let sHForDb = new Date(res[i].shift.endTime)
          let sHForFilter = new Date(shiftEndTime)

          sHForDb.setSeconds(0, 0)
          sHForFilter.setSeconds(0, 0)

          let startHoursForDb = sHForDb.toISOString()
          let startHoursForFilter = sHForFilter.toISOString()

          if (
            startHoursForDb.split('T')[1] === startHoursForFilter.split('T')[1]
          ) {
            if (!resForTimeEnd.find((i) => i._id === res[i]._id)) {
              resForTimeEnd.push(res[i])
            }
          }
        }
      }

      res = resForTimeEnd
    }

    setValuesInData(res)
    setFilterDialog(false)
  }
  const handlePatientSearch = (e) => {
    const a = e.target.value.replace(/[^\w\s]/gi, '')
    setSearchPatientQuery(a)
    if (a.length >= 3) {
      getSearchEDNurses(a, setGeneralArray, setErrorMsg, setOpenNotification)
    } else if (a.length == 0) {
      getAllEDNurses(
        setStaffData,
        setGeneralArray,
        setOpenNotification,
        setErrorMsg
      )
    }
  }
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        position: 'fixed',
        width: '100%',
        height: '100%',
        backgroundColor: '#60d69f',
        overflowY: 'scroll',
      }}
    >
      <Header history={props.history} />

      <div className='cPadding'>
        <div className='subheader'>
          <div
            style={{
              display: 'flex',
              flex: 1,
            }}
          >
            <img src={business_Unit} />
            <h4>ED Nurses List</h4>
          </div>

          <Button
            onClick={setDialogOpen}
            style={{ ...styles.stylesForButton, height: matches ? ' ' : 30 }}
            variant='contained'
            color='primary'
          >
            <img
              src={Filter}
              style={{ width: matches ? 20 : 10, height: matches ? ' ' : 10 }}
            />
            <strong style={{ fontSize: matches ? ' ' : 10 }}>Filter</strong>
          </Button>
        </div>

        <div
          style={{
            flex: 4,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <div
            className={`${'container-fluid'}`}
            style={{
              marginTop: '25px',
              paddingLeft: '10px',
              paddingRight: '10px',
            }}
          >
            <div className={`row  ${classes1.root} ${classes1.input} `}>
              <div
                className='col-md-12  col-12'
                style={{ paddingTop: 5, paddingLeft: 3, paddingRight: 3 }}
              >
                <TextField
                  className='textInputStyle'
                  id='searchPatientQuery'
                  type='text'
                  variant='filled'
                  label={'Search Nurses by Name/ID'}
                  name={'searchPatientQuery'}
                  value={searchPatientQuery}
                  onChange={handlePatientSearch}
                  InputLabelProps={{
                    classes: {
                      root: classes.label,
                      focused: classes.focusedLabel,
                      error: classes.erroredLabel,
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <AccountCircle />
                      </InputAdornment>
                    ),
                    className: classes.input,
                    classes: { input: classes.input },
                    disableUnderline: true,
                  }}
                />
              </div>
            </div>
          </div>
          {staffData ? (
            <div className='container-fluid'>
              <div className='row'>
                <CustomTable
                  tableData={staffData}
                  tableDataKeys={tableDataKeysForDoctors}
                  tableHeading={tableHeadingForDoctors}
                  action={''}
                  borderBottomColor={'#60d69f'}
                  borderBottomWidth={20}
                />

                <Notification msg={errorMsg} open={openNotification} />
              </div>
            </div>
          ) : (
              <div className='LoaderStyle'>
                <Loader type='TailSpin' color='red' height={50} width={50} />
              </div>
            )}

          <div className='container-fluid'>
            <div className='row' style={{ marginBottom: 20 }}>
              <img
                onClick={() => props.history.goBack()}
                src={Back}
                style={{
                  width: 45,
                  height: 35,
                  cursor: 'pointer',
                }}
              />
            </div>
          </div>
        </div>
      </div>

      <Dialog
        maxWidth={'lg'}
        fullWidth
        open={filterDialog}
        onClose={handleClose}
      >
        <DialogTitle>Search Filters</DialogTitle>
        <DialogContent>
          <div>
            <div style={{}}>
              <span style={{ color: 'grey' }}>Categories</span>
              <div style={{}}>
                {nursesSpeciality &&
                  nursesSpeciality.map((type) => {
                    return (
                      <Chip
                        key={type}
                        label={type}
                        onDelete={
                          checkValueAdded(type, 'speciality')
                            ? () => handleRemove(type, 'speciality')
                            : undefined
                        }
                        style={{
                          marginRight: 5,
                          marginTop: 10,
                          backgroundColor: checkValueAdded(type, 'speciality')
                            ? '#60D69F'
                            : undefined,
                          color: checkValueAdded(type, 'speciality')
                            ? 'white'
                            : undefined,
                        }}
                        clickable
                        onClick={() => handleAddedValue(type, 'speciality')}
                      />
                    )
                  })}
              </div>
            </div>
          </div>

          <div style={{ marginTop: 10 }}>
            <span style={{ color: 'grey' }}>Shifts</span>
            <div className={`row`}>
              <div
                className='col-md-6 col-sm-6'
                style={{
                  marginTop: 10,
                }}
              >
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <TimePicker
                    clearable
                    label='Shift Start Time'
                    inputVariant='filled'
                    fullWidth={true}
                    ampm={false}
                    onChange={(val) => handleChangeDate(val, 'shiftStartTime')}
                    // InputProps={{
                    //   className: classes.input,
                    //   classes: { input: classes.input },
                    // }}
                    style={{ borderRadius: '10px' }}
                    value={shiftStartTime ? shiftStartTime : null}
                  />
                </MuiPickersUtilsProvider>
              </div>

              <div
                className='col-md-6 col-sm-6'
                style={{
                  marginTop: 10,
                }}
              >
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <TimePicker
                    clearable
                    inputVariant='filled'
                    fullWidth={true}
                    label='End Time'
                    ampm={false}
                    onChange={(val) => handleChangeDate(val, 'shiftEndTime')}
                    // InputProps={{
                    //   className: classes.input,
                    //   classes: { input: classes.input },
                    // }}
                    style={{ borderRadius: '10px' }}
                    value={shiftEndTime ? shiftEndTime : null}
                  />
                </MuiPickersUtilsProvider>
              </div>
            </div>
          </div>
        </DialogContent>

        <DialogActions
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Button onClick={handleClose} variant='outlined'>
            Cancel
          </Button>

          <Button
            onClick={handleApplyFilters}
            variant='contained'
            color='primary'
          >
            Apply Filters
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
