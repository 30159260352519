import axios from "axios";
import {
  reportsHKDashboardURL,
  reportsAnesthesiologistDBURL,
} from "../../../public/endpoins";

// export function getGraphsHousekeeping(
//   setGraphDataHouseKeeping,
//   setErrorMsg,
//   setOpenNotification
// ) {
//   axios
//     .get(reportsHKGraphsDashboardURL)
//     .then((res) => {
//       if (res.data.success) {
//         console.log("Graph Data of House keeping", res.data);
//         setGraphDataHouseKeeping(res.data.cleaningPerHour);
//       } else if (!res.data.success) {
//         setErrorMsg(res.data.error);
//         setOpenNotification(true);
//       }
//     })
//     .catch((e) => {
//       console.log("error while graph data", e);
//     });
// }

export function getReportsAnesthesiologist(
  setTotalRequestPending,
  setTotalRequestPendingColor,
  setPendingTat,
  setPendingTATColor,
  setGraphAnesthesiologist,
  setTotalED,
  setTotalEDColor,
  setEDTat,
  setEDTatColor,
  setGraphED,
  setTotalEOU,
  setTotalEOUColor,
  setEOUTat,
  setEOUTatColor,
  setGraphEOU,
  setCompletedTask,
  setCompletedTaskTat,
  setCompletedTaskColor,
  setCompletedTATColor,
  setGraphCompletedTask,
  setCommulativeTotal,
  setCommulativeTotalColor
) {
  axios.get(reportsAnesthesiologistDBURL).then((res) => {
    if (res.data.success) {
      console.log("Anesthesiologist data", res.data);
      if (
        res.data.totalRequests.totalPending >= 0 &&
        res.data.totalRequests.totalPending <= 2
      ) {
        setTotalRequestPending(res.data.totalRequests.totalPending);
        setTotalRequestPendingColor("#13D59F");
      } else if (
        res.data.totalRequests.totalPending >= 3 &&
        res.data.totalRequests.totalPending <= 4
      ) {
        setTotalRequestPending(res.data.totalRequests.totalPending);
        setTotalRequestPendingColor("#FFBC28");
      } else if (res.data.pendingRoom.totalPending >= 5) {
        setTotalRequestPending(res.data.totalRequests.totalPending);
        setTotalRequestPendingColor("#FF0000");
      } else {
        setTotalRequestPending(res.data.totalRequests.totalPending);
      }
      // setTotalRequestPending(res.data.totalRequests.totalPending);
      if (
        res.data.totalRequests.pendingTat >= 0 &&
        res.data.totalRequests.pendingTat <= 10
      ) {
        setPendingTat(res.data.totalRequests.pendingTat);
        setPendingTATColor("#13D59F");
      } else if (
        res.data.totalRequests.pendingTat >= 11 &&
        res.data.totalRequests.pendingTat <= 15
      ) {
        setPendingTat(res.data.totalRequests.pendingTat);
        setPendingTATColor("#FFBC28");
      } else if (res.data.totalRequests.pendingTat >= 16) {
        setPendingTat(res.data.totalRequests.pendingTat);
        setPendingTATColor("#FF0000");
      } else {
        setPendingTat(res.data.totalRequests.pendingTat);
      }
      setGraphAnesthesiologist(res.data.totalRequests.totalRequestPerHour);

      // For 2nd Card Requested In ED
      if (
        res.data.requestsInED.totalED >= 0 &&
        res.data.requestsInED.totalED <= 2
      ) {
        setTotalED(res.data.requestsInED.totalED);
        setTotalEDColor("#13D59F");
      } else if (
        res.data.requestsInED.totalED >= 3 &&
        res.data.requestsInED.totalED <= 4
      ) {
        setTotalED(res.data.requestsInED.totalED);
        setTotalEDColor("#FFBC28");
      } else if (res.data.requestsInED.totalED >= 5) {
        setTotalED(res.data.requestsInED.totalED);
        setTotalEDColor("#FF0000");
      } else {
        setTotalED(res.data.requestsInED.totalED);
      }

      //setTotalED(res.data.requestsInED.totalED)
      //setTotalEDColor()
      if (
        res.data.requestsInED.requestToCompletion >= 0 &&
        res.data.requestsInED.requestToCompletion <= 10
      ) {
        setEDTat(res.data.requestsInED.requestToCompletion);
        setEDTatColor("#13D59F");
      } else if (
        res.data.requestsInED.requestToCompletion >= 11 &&
        res.data.requestsInED.requestToCompletion <= 15
      ) {
        setEDTat(res.data.requestsInED.requestToCompletion);
        setEDTatColor("#FFBC28");
      } else if (res.data.requestsInED.requestToCompletion >= 16) {
        setEDTat(res.data.requestsInED.requestToCompletion);
        setEDTatColor("#FF0000");
      } else {
        setEDTat(res.data.requestsInED.requestToCompletion);
      }

      //setEDTat(res.data.requestsInED.requestToCompletion)
      //setEDTatColor()

      setGraphED(res.data.requestsInED.requestsPerHour);

      // For 3nd Card Requested In EOU

      if (
        res.data.requestsInEOU.totalED >= 0 &&
        res.data.requestsInEOU.totalED <= 2
      ) {
        setTotalEOU(res.data.requestsInEOU.totalED);
        setTotalEOUColor("#13D59F");
      } else if (
        res.data.requestsInEOU.totalED >= 3 &&
        res.data.requestsInEOU.totalED <= 4
      ) {
        setTotalEOU(res.data.requestsInEOU.totalED);
        setTotalEOUColor("#FFBC28");
      } else if (res.data.requestsInEOU.totalED >= 5) {
        setTotalEOU(res.data.requestsInEOU.totalED);
        setTotalEOUColor("#FF0000");
      } else {
        setTotalEOU(res.data.requestsInEOU.totalED);
      }

      //setTotalED(res.data.requestsInED.totalED)
      //setTotalEDColor()
      if (
        res.data.requestsInEOU.requestToCompletion >= 0 &&
        res.data.requestsInEOU.requestToCompletion <= 10
      ) {
        setEOUTat(res.data.requestsInEOU.requestToCompletion);
        setEOUTatColor("#13D59F");
      } else if (
        res.data.requestsInEOU.requestToCompletion >= 11 &&
        res.data.requestsInEOU.requestToCompletion <= 15
      ) {
        setEOUTat(res.data.requestsInEOU.requestToCompletion);
        setEOUTatColor("#FFBC28");
      } else if (res.data.requestsInEOU.requestToCompletion >= 16) {
        setEOUTat(res.data.requestsInEOU.requestToCompletion);
        setEOUTatColor("#FF0000");
      } else {
        setEOUTat(res.data.requestsInEOU.requestToCompletion);
      }

      //setEDTat(res.data.requestsInED.requestToCompletion)
      //setEDTatColor()

      setGraphEOU(res.data.requestsInED.requestsPerHour);

      //For 4th Cards

      if (
        res.data.completedTask.totalCompleted >= 0 &&
        res.data.completedTask.totalCompleted <= 2
      ) {
        setCompletedTask(res.data.completedTask.totalCompleted);
        setCompletedTaskColor("#13D59F");
      } else if (
        res.data.completedTask.totalCompleted >= 3 &&
        res.data.completedTask.totalCompleted <= 4
      ) {
        setCompletedTask(res.data.completedTask.totalCompleted);
        setCompletedTaskColor("#FFBC28");
      } else if (res.data.completedTask.totalCompleted >= 5) {
        setCompletedTask(res.data.completedTask.totalCompleted);
        setCompletedTaskColor("#FF0000");
      } else {
        setCompletedTask(res.data.requestsInEOU.totalED);
      }
      //setCompletedTask(res.data.completedTask.totalCompleted);
      //setCompletedTaskColor()

      setCompletedTaskTat(res.data.completedTask.requestToCompletion);
      // setCompletedTATColor()
      if (
        res.data.cumulativeRequestsCompleted >= 0 &&
        res.data.cumulativeRequestsCompleted <= 5
      ) {
        setCommulativeTotal(res.data.cumulativeRequestsCompleted);
        setCommulativeTotalColor("#13D59F");
      } else if (
        res.data.cumulativeRequestsCompleted >= 6 &&
        res.data.cumulativeRequestsCompleted <= 8
      ) {
        setCommulativeTotal(res.data.cumulativeRequestsCompleted);
        setCommulativeTotalColor("#FFBC28");
      } else if (res.data.cumulativeRequestsCompleted >= 9) {
        setCommulativeTotal(res.data.cumulativeRequestsCompleted);
        setCommulativeTotalColor("#FF0000");
      } else {
        setCommulativeTotal(res.data.cumulativeRequestsCompleted);
      }
      // commulativeTotal(res.data.cumulativeRequestsCompleted)
      //commulativeTotalColor()

      setGraphCompletedTask(res.data.completedTask.requestsPerHour);
    }
  });
}
