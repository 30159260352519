import React, { useState, useEffect } from "react";
import Notification from "../../../components/Snackbar/Notification.js";
import CustomTable from "../../../components/Table/Table";
import ButtonField from "../../../components/common/Button";
import ICR from "../../../assets/img/ICR.png";
import ECR from "../../../assets/img/ECR.png";
import { makeStyles } from "@material-ui/core/styles";
import Header from "../../../components/Header/Header";
import _ from "lodash";
import Back_Arrow from "../../../assets/img/Back_Arrow.png";
import { getInternalConsultationRequestsCall } from "./NetworkCalls";
import { setSelectedPatientForReducer } from "../../../actions/patientDetails";
import { connect } from "react-redux";

const styles = {
  textFieldPadding: {
    paddingLeft: 5,
    paddingRight: 5,
  },
};

const useStylesForInput = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(0),
  },
  input: {
    backgroundColor: "white",
    boxShadow: "none",
    borderRadius: 5,
    "&:after": {
      borderBottomColor: "black",
      boxShadow: "none",
    },
    "&:hover": {
      backgroundColor: "white",
      boxShadow: "none",
    },
    "&:focus": {
      backgroundColor: "white",
      boxShadow: "none",
      borderRadius: 5,
    },
  },
  multilineColor: {
    boxShadow: "none",
    backgroundColor: "white",
    borderRadius: 5,
    "&:hover": {
      backgroundColor: "white",
      boxShadow: "none",
    },
    "&:after": {
      borderBottomColor: "black",
      boxShadow: "none",
    },
    "&:focus": {
      boxShadow: "none",
    },
  },
  root: {
    "& .MuiTextField-root": {
      backgroundColor: "white",
    },
    "& .Mui-focused": {
      backgroundColor: "white",
      color: "black",
      boxShadow: "none",
    },
    "& .Mui-disabled": {
      backgroundColor: "white",
      color: "gray",
    },
    "&:focus": {
      backgroundColor: "white",
      boxShadow: "none",
    },
    "& .MuiFormLabel-root": {
      fontSize: "12px",

      paddingRight: "15px",
    },
  },
  label: {
    "&$focusedLabel": {
      color: "red",
      display: "none",
    },
    // "&$erroredLabel": {
    //   color: "orange"
    // }
  },
  focusedLabel: {},
}));

const tableHeading = ["MRN", "Patient Name", "Date/Time", "Action"];
const tableDataKeys = ["mrn", "patientName", "date"];

const actions = { view: true };

function PatientListing(props) {
  const classes = useStylesForInput();

  const [errorMsg, setErrorMsg] = useState("");
  const [openNotification, setOpenNotification] = useState(false);
  const [labInPatient, setlabInPatient] = useState([]);

  useEffect(() => {
    console.log("props", props);
    getInternalConsultationRequestsCall(
      setlabInPatient,
      setErrorMsg,
      setOpenNotification
    );
  }, []);

  if (openNotification) {
    setTimeout(() => {
      setOpenNotification(false);
      setErrorMsg("");
    }, 2000);
  }

  // let path
  // if (
  //   props.location.pathname ===
  //   '/dashboard/dhr/sensei/patientmanagement/viewrequest/internalconsultantrequest'
  // ) {
  //   path = `internalconsultantrequest/viewInternalconsultantrequest`
  // }
  // if (
  //   props.location.pathname ===
  //   '/dashboard/dhr/sensei/patientmanagement/viewrequest/externalconsultantrequest'
  // ) {
  //   path = `externalconsultantrequest/viewexternalconsultantrequest`
  // }

  // function handleView(rec) {
  //   props.history.push({
  //     pathname: path,
  //     state: {
  //       comingFor: 'edit',
  //       selectedItem: rec,
  //     },
  //   })
  // }\

  function handleView(rec) {
    props.setSelectedPatientForReducer(rec);
    console.log("rec", rec);
    props.history.push({
      pathname: "/dashboard/home/notes",
      state: {
        selectedRecord: rec,
      },
    });
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        position: "fixed",
        width: "100%",
        height: "100%",
        backgroundColor: "rgb(19 213 159)",
        overflowY: "scroll",
      }}
    >
      <Header history={props.history} />
      <div className="cPadding">
        <div className="subheader" style={{ marginLeft: "-10px" }}>
          <div>
            <img
              src={
                props.location.pathname ===
                "/dashboard/home/patientmanagement/viewrequests/icr"
                  ? ICR
                  : ECR
              }
            />

            <h4>
              {props.location.pathname ===
              "/dashboard/home/patientmanagement/viewrequests/icr"
                ? "Internal Consultant Request"
                : "External Consultant Request"}
            </h4>
          </div>
        </div>

        <div
          style={{
            flex: 4,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div>
            {labInPatient ? (
              <div>
                <div>
                  <CustomTable
                    tableData={labInPatient}
                    tableDataKeys={tableDataKeys}
                    tableHeading={tableHeading}
                    action={actions}
                    borderBottomColor={"#60d69f"}
                    handleView={handleView}
                    borderBottomWidth={20}
                  />
                </div>

                <Notification msg={errorMsg} open={openNotification} />

                <div style={{ marginBottom: 20, marginTop: 50 }}>
                  <img
                    onClick={() => props.history.goBack()}
                    src={Back_Arrow}
                    style={{ width: 45, height: 35, cursor: "pointer" }}
                  />
                </div>
              </div>
            ) : (
              // <div className='LoaderStyle'>
              //   <Loader type='TailSpin' color='red' height={50} width={50} />
              // </div>
              <div className="row " style={{ marginTop: "25px" }}>
                <div className="col-11">
                  <h3
                    style={{
                      color: "white",
                      textAlign: "center",
                      width: "100%",
                      position: "absolute",
                    }}
                  >
                    Opps...No Data Found
                  </h3>
                </div>
                <div className="col-1" style={{ marginTop: 45 }}>
                  <img
                    onClick={() => props.history.goBack()}
                    src={Back_Arrow}
                    style={{
                      maxWidth: "60%",
                      height: "auto",
                      cursor: "pointer",
                    }}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = ({ PatientReducer }) => {
  const { selectedPatientDetails } = PatientReducer;
  return { selectedPatientDetails };
};
export default connect(mapStateToProps, {
  setSelectedPatientForReducer,
})(PatientListing);
