import React, { useState, useEffect } from "react";
import Notification from "../../components/Snackbar/Notification.js";
import CustomTable from "../../components/Table/Table";
import axios from "axios";
import _ from "lodash";
import {
  getDischargedInsurededrs,
  getSearchDischargedPatient,
} from "../../public/endpoins";
import Loader from "react-loader-spinner";
import Back from "../../assets/img/Back_Arrow.png";
import Header from "../../components/Header/Header";
import patientIcon from "../../assets/img/Patients.png";
import Fingerprint from "../../assets/img/fingerprint.png";
import AccountCircle from "@material-ui/icons/SearchOutlined";
import InputAdornment from "@material-ui/core/InputAdornment";
import BarCode from "../../assets/img/Bar Code.png";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import "../../assets/jss/material-dashboard-react/components/loaderStyle.css";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const tableHeading = [
  "MRN",
  "Patient Name",
  "Gender",
  "Age",
  "Phone",
  "Date/Time",
  "Action",
];
const tableDataKeys = [
  "mrn",
  "name",
  ["patientId", "gender"],
  ["patientId", "age"],
  "phone",
  "date",
];

const styles = {
  textFieldPadding: {
    paddingLeft: 5,
    paddingRight: 5,
  },
};

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(0),
  },
  input: {
    backgroundColor: "white",
    boxShadow: "none",
    borderRadius: 5,
    "&:after": {
      borderBottomColor: "black",
      boxShadow: "none",
    },
    "&:hover": {
      backgroundColor: "white",
      boxShadow: "none",
    },
    "&:focus": {
      backgroundColor: "white",
      boxShadow: "none",
      borderRadius: 5,
    },
  },
  root: {
    "& .MuiTextField-root": {
      backgroundColor: "white",
    },
    "& .Mui-focused": {
      backgroundColor: "white",
      color: "black",
      boxShadow: "none",
    },
    "& .Mui-disabled": {
      backgroundColor: "white",
      color: "gray",
    },
    "&:focus": {
      backgroundColor: "white",
      boxShadow: "none",
    },
    "& .MuiFormLabel-root": {
      fontSize: "11px",
      paddingRight: "50px",
    },
  },
}));

const actions = { view: true };

export default function DischargedPatients(props) {
  const classes = useStyles();
  const matches = useMediaQuery("(min-width:600px)");

  const [patientData, setpatientData] = useState([]);
  const [errorMsg, setErrorMsg] = useState("");
  const [openNotification, setOpenNotification] = useState(false);
  const [searchPatientQuery, setSearchPatientQuery] = useState("");

  if (openNotification) {
    setTimeout(() => {
      setOpenNotification(false);
      setErrorMsg("");
    }, 2000);
  }

  useEffect(() => {
    console.log("props::", props);
    if (
      props.history.location.state &&
      props.history.location.state.comingFrom === "notification"
    ) {
      setSearchPatientQuery(props.history.location.state.edrId);
      if (props.history.location.state.edrId.length >= 3) {
        axios
          .get(
            getSearchDischargedPatient +
              "/" +
              props.history.location.state.edrId
          )
          .then((res) => {
            if (res.data.success) {
              if (res.data.data.length > 0) {
                console.log(res.data.data, "get insurance patients");
                res.data.data.map(
                  (d) => (
                    (d.name =
                      d.patientId.name[0].given[0] +
                      " " +
                      d.patientId.name[0].family),
                    (d.mrn = d.patientId.identifier[0].value),
                    (d.phone = d.patientId.telecom.map((d) =>
                      d.system === "phone" ? d.value : ""
                    )),
                    (d.date = d.patientId.createdAt)
                  )
                );
                setpatientData(res.data.data.reverse());
              } else {
                setpatientData([]);
              }
            }
          })
          .catch((e) => {
            console.log("error after searching patient request", e);
          });
      } else if (props.history.location.state.edrId.length == 0) {
        console.log("less");
        getDischargedPatientsData();
      }
    } else {
      getDischargedPatientsData();
    }
  }, []);

  function getDischargedPatientsData() {
    axios
      .get(getDischargedInsurededrs)
      .then((res) => {
        if (res.data.success) {
          console.log(res.data.data, "get insurance patients");
          res.data.data.map(
            (d) => (
              (d.name =
                d.patientId.name[0].given[0] +
                " " +
                d.patientId.name[0].family),
              (d.mrn = d.patientId.identifier[0].value),
              (d.phone = d.patientId.telecom.map((d) =>
                d.system === "phone" ? d.value : ""
              )),
              (d.date = d.patientId.createdAt)
            )
          );
          setpatientData(res.data.data.reverse());
        } else if (!res.data.success) {
          setErrorMsg(res.data.error);
          setOpenNotification(true);
        }
        return res;
      })
      .catch((e) => {
        console.log("error: ", e);
      });
  }

  function handleView(patient) {
    let path = `/dashboard/home/patientmanagement/pendingpatients/viewpatients`;
    props.history.push({
      pathname: path,
      state: {
        selectedItem: patient.patientId,
      },
    });
  }

  const handlePatientSearch = (e) => {
    const a = e.target.value.replace(/[^\w+\s]/gi, "");
    setSearchPatientQuery(a);
    if (a.length >= 3) {
      axios
        .get(getSearchDischargedPatient + "/" + a)
        .then((res) => {
          if (res.data.success) {
            if (res.data.data.length > 0) {
              console.log(res.data.data, "get insurance patients");
              res.data.data.map(
                (d) => (
                  (d.name =
                    d.patientId.name[0].given[0] +
                    " " +
                    d.patientId.name[0].family),
                  (d.mrn = d.patientId.identifier[0].value),
                  (d.phone = d.patientId.telecom.map((d) =>
                    d.system === "phone" ? d.value : ""
                  )),
                  (d.date = d.patientId.createdAt)
                )
              );
              setpatientData(res.data.data.reverse());
            } else {
              setpatientData([]);
            }
          }
        })
        .catch((e) => {
          console.log("error after searching patient request", e);
        });
    } else if (a.length == 0) {
      console.log("less");
      getDischargedPatientsData();
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        position: "fixed",
        width: "100%",
        height: "100%",
        backgroundColor: "rgb(19 213 159)",
        overflowY: "scroll",
      }}
    >
      <Header history={props.history} />

      <div className="cPadding">
        <div className="subheader">
          <div>
            <img src={patientIcon} />
            <h4>Discharged Patients</h4>
          </div>
        </div>

        <div
          className={`container-fluid ${classes.root}`}
          style={{
            marginTop: "25px",
          }}
        >
          <div className="row">
            <div
              className="col-md-10 col-sm-9 col-8"
              style={{ paddingRight: matches ? 5 : 0, paddingLeft: "15px" }}
            >
              <TextField
                className="textInputStyle"
                id="searchPatientQuery"
                type="text"
                variant="filled"
                label="Search By MRN / Request No"
                name={"searchPatientQuery"}
                value={searchPatientQuery}
                onChange={handlePatientSearch}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <AccountCircle />
                    </InputAdornment>
                  ),
                  className: classes.input,
                  classes: { input: classes.input },
                  disableUnderline: true,
                }}
              />
            </div>
            <div
              className="col-md-1 col-sm-2 col-2"
              style={styles.textFieldPadding}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "white",
                  borderRadius: 5,
                  height: 55,
                }}
              >
                <img
                  src={BarCode}
                  style={{
                    width: matches ? 70 : 52,
                    height: matches ? 60 : 58,
                  }}
                />
              </div>
            </div>
            <div
              className="col-md-1 col-sm-1 col-2"
              style={{ paddingRight: "15px", paddingLeft: matches ? 5 : 0 }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "white",
                  borderRadius: 5,
                  height: 55,
                }}
              >
                <img src={Fingerprint} style={{ maxWidth: 43, height: 43 }} />
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid">
          {patientData && patientData.length > 0 ? (
            <div className="row">
              <div className="col-md-12 col-sm-12 col-12">
                <CustomTable
                  tableData={patientData}
                  tableDataKeys={tableDataKeys}
                  tableHeading={tableHeading}
                  action={actions}
                  handleView={handleView}
                  borderBottomColor={"#60d69f"}
                  borderBottomWidth={20}
                />
              </div>
            </div>
          ) : patientData && patientData.length === 0 ? (
            <div className="row " style={{ marginTop: "25px" }}>
              <div className="col-md-12 col-sm-12 col-12">
                <h3
                  style={{
                    color: "white",
                    textAlign: "center",
                  }}
                >
                  Oops! No Patient Found
                </h3>
              </div>
            </div>
          ) : (
            <div className="row">
              <div className="LoaderStyle">
                <Loader type="TailSpin" color="red" height={50} width={50} />
              </div>
            </div>
          )}

          <div className="row">
            <div
              className="col-md-12 col-sm-12 col-12"
              style={{ marginTop: 45 }}
            >
              <img
                onClick={() => props.history.goBack()}
                src={Back}
                style={{ maxWidth: "50px", height: "auto", cursor: "pointer" }}
              />
            </div>
          </div>

          <Notification msg={errorMsg} open={openNotification} />
        </div>
      </div>
    </div>
  );
}
