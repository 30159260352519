import React, { useState, useEffect, useReducer } from "react";
import cookie from "react-cookies";
import Header from "../../components/Header/Header";
import PatientDetails from "../../components/PatientDetails/PatientDetailsDirect";
import FormatDate from "../../utils/formatDateTime";
import PharmaIcon from "../../assets/img/Pharma.png";
import CustomTable from "../../components/Table/Table";
import Button from "@material-ui/core/Button";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import {Radio, RadioGroup, FormControlLabel, FormLabel, TextField} from "@material-ui/core";
import Loader from "react-loader-spinner";
import Notification from "../../components/Snackbar/Notification.js";
import { displayInHumanDifference, replacePatientIdIfNull } from "../../utils/commonFunc";

import PatientDetailsQuick from "../../components/PatientDetails/PatientDetailsQuick";
import PatientClinicalInformation from "../../components/PatientDetails/PatientClinicalInformation";
// import PatientDetails2 from "../../components/PatientDetails/PatientDetailsDirect";
import {medicineFrequencyArray} from "../NotesScreen/configForNotesScreen"
import moment from 'moment';
import classNames from "classnames";

import AccountCircle from "@material-ui/icons/SearchOutlined";
import InputAdornment from "@material-ui/core/InputAdornment";
import { makeStyles } from "@material-ui/core/styles";
import { Paper, Table, TableHead, TableRow, TableBody, TableCell, MenuItem } from "@material-ui/core";

import Icon from '@material-ui/core/Icon';
import CloseIcon from '@material-ui/icons/Close';
import MicIcon from "@material-ui/icons/Mic";
import StopIcon from "@material-ui/icons/Stop";
import MicRecorder from "mic-recorder-to-mp3";

import axios from "axios";

import {
  audioURL,
  getSingleEdr,
  updateConflictRequestByEdDoctor,

  getSearchedPharmaceuticalItemsUrl,
  getSearchedItemsNonPharmaceuticalUrl
} from "../../public/endpoins";

const Mp3Recorder = new MicRecorder({ bitRate: 128 });

export const tableHeadingForPharma = [
  "Request ID",
  "Date/Time",
  "Requester",
  "Status",
  "Action",
];
export const tableDataKeysForPharma = [
  "pharmacyRequestNo",
  "createdAt",
  "requesterName",
  "status",
];

const frequencyArray = [

  { key: 1,  value: "Q.H - Each Hour" },
  { key: 11, value: "Q.D - Every day" },
  { key: 2,  value: "B.I.D - Two times each day" },
  { key: 3,  value: "T.I.D - Three times each day" },
  { key: 4,  value: "Q.I.D - Four times each day" },

  { key: 20,  value: "Q.S - As much as su" },
  { key: 21,  value: "Q.A.C - Before every meal" },
  { key: 22,  value: "Q.O.D - Every other day" },
  { key: 23,  value: "Q.I - As much as you like" },
  { key: 24,  value: "Q.H.S - Every bedtime" },
  { key: 0,  value: "PRN When needed" },
  { key: 25,  value: "Other" },
];

const routeArray = [
  { key: "ROA000", value: "N/A" },
  { key: "ROA001", value: "DENTAL" },
  { key: "ROA002", value: "DENTAL INFILTRATION INJ. DENTAL BLOCK" },
  { key: "ROA003", value: "DENTAL INJECTION" },
  { key: "ROA007", value: "EPIDURAL" },
  { key: "ROA009", value: "CAUDAL" },
  { key: "ROA010", value: "EXTERNAL" },
  { key: "ROA011", value: "GENITAL" },
  { key: "ROA012", value: "IM" },
  { key: "ROA032", value: "IMPLANT" },
  { key: "ROA033", value: "INFILTRATION DENTAL BLOCK" },
  { key: "ROA034", value: "INHALATION" },
  { key: "ROA036", value: "INTRA-AMNIOTIC" },
  { key: "ROA037", value: "INTRA-ARTICULAR" },
  { key: "ROA039", value: "INTRACAVERNOSAL" },
  { key: "ROA040", value: "INTRACERVICAL" },
  { key: "ROA041", value: "INTRAGLUTEALLY" },
  { key: "ROA042", value: "INTRA-MASTITIS" },
  { key: "ROA043", value: "INTRAMMAMARY" },
  { key: "ROA045", value: "INTRA-PERIODONTAL POCKET" },
  { key: "ROA046", value: "INTRA-PERITONEAL" },
  { key: "ROA047", value: "INTRATRACHEAL" },
  { key: "ROA048", value: "INTRAUTERIN" },
  { key: "ROA049", value: "INTRAVAGINAL" },
  { key: "ROA050", value: "INTRA-VESICALLY" },
  { key: "ROA051", value: "IV" },
  { key: "ROA052", value: "IV CATHETER" },
  { key: "ROA053", value: "IV INFUSION" },
  { key: "ROA064", value: "LOCAL ORAL" },
  { key: "ROA065", value: "LOCAL ORAL DENTAL" },
  { key: "ROA066", value: "ORAL RINSE" },
  { key: "ROA067", value: "NASAL" },
  { key: "ROA071", value: "NEBULISATION" },
  { key: "ROA072", value: "OCULAR" },
  { key: "ROA074", value: "ORAL" },
  { key: "ROA075", value: "ORAL INHALATION" },
  { key: "ROA083", value: "ORALLY TUBE FEEDING" },
  { key: "ROA084", value: "OTIC" },
  { key: "ROA085", value: "PARENTERAL" },
  { key: "ROA087", value: "PERITONEAL DIALYSIS" },
  { key: "ROA088", value: "RECTAL" },
  { key: "ROA089", value: "SC" },
  { key: "ROA090", value: "SPINAL" },
  { key: "ROA091", value: "SUBLINGUAL" },
  { key: "ROA092", value: "TOPICAL" },
  { key: "ROA093", value: "TOPICAL ANAL" },
  { key: "ROA094", value: "TOPICAL- SCALP" },
  { key: "ROA095", value: "TRANSDERMAL" },
  { key: "ROA096", value: "TRANSMUCOSAL" },
  { key: "ROA097", value: "VAGINAL" },
  { key: "ROA100", value: "URETHRAL" },
  { key: "ROA101", value: "MISCELLANEOUS" },
  { key: "ROA103", value: "SUBARACHNOID" },
  { key: "ROA107", value: "INTRATHECAL" },
  { key: "ROA108", value: "INTRAOCULAR" },
  { key: "ROA109", value: "INTRACARDIAC" },
  { key: "ROA112", value: "INTRADERMAL" },
  { key: "ROA114", value: "INTRAVENTRICULAR" },
  { key: "ROA115", value: "SUBMUCOSAL" },
  { key: "ROA123", value: "INTRAPLEURAL" },
  { key: "ROA133", value: "INFILTRATION" },
  { key: "ROA141", value: "IRRIGATION" },
  { key: "ROA147", value: "SUBDERMAL" },
  { key: "ROA148", value: "BUCCAL" },
  { key: "ROA159", value: "ARTERIOVENOUS" },
  { key: "ROA160", value: "CENTRAL NERVE BLOCK" },
  { key: "ROA161", value: "DEEP SC" },
  { key: "ROA162", value: "DERMAL" },
  { key: "ROA163", value: "ENDOTRACHEAL" },
  { key: "ROA164", value: "ILIOHYPOGASTRIC (NERVE BLOCK)" },
  { key: "ROA165", value: "ILIONGUINAL" },
  { key: "ROA166", value: "INTRA-ARTERIAL" },
  { key: "ROA167", value: "INTRABURSAL" },
  { key: "ROA168", value: "INTRACAVITARY" },
  { key: "ROA169", value: "INTRALESIONAL" },
  { key: "ROA170", value: "INTRAMEDULLARILY" },
  { key: "ROA171", value: "INTRAOSSEOUS" },
  { key: "ROA172", value: "INTRA-PULMONARY" },
  { key: "ROA173", value: "INTRASPINAL" },
  { key: "ROA174", value: "INTRASUBLESIONAL" },
  { key: "ROA175", value: "INTRASYNOVIAL" },
  { key: "ROA176", value: "IV BOLUS" },
  { key: "ROA177", value: "OROFACIAL" },
  { key: "ROA178", value: "OROPHARYNGEAL" },
  { key: "ROA179", value: "PERIANAL" },
  { key: "ROA180", value: "PERIARTICULAR" },
  { key: "ROA181", value: "PERIBULBAR" },
  { key: "ROA182", value: "PERIPHERAL NERVE BLOCK" },
  { key: "ROA183", value: "SUBCONJUNCTIVAL" },
  { key: "ROA184", value: "SYMPATHETIC NERVE BLOCK" },
  { key: "ROA185", value: "TRANSCERVICAL" },
  { key: "ROA187", value: "SC INFUSION" },
  { key: "ROA191", value: "INTRAVITREAL" },
  { key: "ROA192", value: "CARDIAC PERFUSION" },
  { key: "ROA202", value: "INTRACAMERAL" },
  { key: "ROA206", value: "INTESTINAL INFUSION" },
  { key: "ROA207", value: "EPILESIONAL" },
  { key: "ROA209", value: "INTRAURETERIC" },
  { key: "ROA210", value: "INTRA-LYMPHATIC" },
  { key: "ROA222", value: "PERINEURAL" },
];

const actions = { view: false, edit: true };

const styles = {
  stylesForButton: {
    color: "white",
    cursor: "pointer",
    borderRadius: 5,
    backgroundColor: "#2c6ddd",
    width: "130px",
    height: "45px",
    outline: "none",
  },
  inputContainerForTextField: {
    // marginTop: 10,
  },
  inputContainerForDropDown: {
    marginTop: 10,
  },
  textFieldPadding: {
    // paddingLeft: 5,
    // paddingRight: 5,
  },
  save: {
    color: "white",
    cursor: "pointer",
    borderRadius: 5,
    backgroundColor: "#ba55d3",
    width: "130px",
    height: "45px",
    outline: "none",
  },
  generate: {
    color: "white",
    cursor: "pointer",
    borderRadius: 5,
    backgroundColor: "#e877a1",
    height: "45px",
    width: "190px",
    outline: "none",
  },
  None: {
    display: "none",
  },
  form: {
    backgroundColor: "white",
    borderRadius: "5px",
    marginTop: "10px",
    padding: "10px",
    textAlign: "center",
  },
  form2: {
    backgroundColor: "white",
    borderRadius: "5px",
    padding: "5px 15px",
    marginTop: '15px',
  },
  form3: {
    backgroundColor: "white",
    borderRadius: "5px",
    padding: "0px 15px",
  },
};



const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(0),
  },
  input: {
    backgroundColor: "white",
    boxShadow: "none",
    borderRadius: 5,
    "&:after": {
      borderBottomColor: "black",
      boxShadow: "none",
    },
    "&:hover": {
      backgroundColor: "white",
      boxShadow: "none",
    },
    "&:focus": {
      backgroundColor: "white",
      boxShadow: "none",
    },
  },
  multilineColor: {
    boxShadow: "none",
    backgroundColor: "white",
    borderRadius: 5,
    "&:hover": {
      backgroundColor: "white",
      boxShadow: "none",
    },
    "&:after": {
      borderBottomColor: "black",
      boxShadow: "none",
    },
    "&:focus": {
      boxShadow: "none",
    },
  },
  root: {
    "& .MuiTextField-root": {
      backgroundColor: "white",
    },
    "& .Mui-focused": {
      backgroundColor: "white",
      color: "black",
      boxShadow: "none",
    },
    "& .Mui-disabled": {
      backgroundColor: "white",
      color: "gray",
    },
    "&:focus": {
      backgroundColor: "white",
      boxShadow: "none",
    },
  },
}));

export default function PatientDetailsWithPharamReq(props) {

  const matches = useMediaQuery("(min-width:600px)");
  const classes = useStyles();

  const initialState = {
    doctorNotesArray: "",
    consultationNo: "",
    date: new Date(),
    description: "",

    residentNoteArray: "",
    doctorNotes: "",
    note: "",
    doctor: cookie.load("current_user").name,

    status: "pending",

    pharmacyRequestArray: "",
    requestType: "",
    section: "",
    code: [],
    patientId: "",
    diagnosisArray: "",
    medicationArray: "",
    dischargeMedication: {},

    price: "",

    isBlocked: false,
    isRecording: false,
    isFormSubmitted: false,

    isRecordingForConsultant: false,
    recordingField: '',
    blobURL: [],

    fileAudio: null,
    fileAudioForConsultant: null,
    audioUrl: "",
    hasConflict: "",

    requestType: "",

    seletedMedItems: [],
    selectedItemToSearch: "pharmaceutical",
  };

  function reducer(state, { field, value }) {
    return {
      ...state,
      [field]: value,
    };
  }

  const [state, dispatch] = useReducer(reducer, initialState);

  const {
    pharmacyRequestArray,
    code,
    note,
    section,
    diagnosisArray,
    medicationArray,
    dischargeMedication,

    status,

    doctorNotesArray,
    doctorNotes,

    isBlocked,
    isRecording,
    isFormSubmitted,

    isRecordingForConsultant,
    blobURL,
    fileAudio,
    fileAudioForConsultant,
    recordingField,

    requestType,

    hasConflict,
    seletedMedItems,
    selectedItemToSearch
  } = state;

  const [currentUser] = useState(cookie.load("current_user"));
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setsuccessMsg] = useState("");
  const [openNotification, setOpenNotification] = useState(false);

  const [patientDetails, setPatientDetails] = useState("");
  const [openItemDialog, setOpenItemDialog] = useState(false);
  const [openRequestDialog, setOpenRequestDialog] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const [item, setItem] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [itemFound, setItemFound] = useState("");
  const [itemFoundSuccessfull, setItemFoundSuccessfully] = useState(false);
  const [doctorForm, setDoctorForm] = useState(false);
  const [dialogForm, setDialogForm] = useState(false);

  const [labForm, setLabForm] = useState(false);
  const [editForm, setEditForm] = useState(false);
  const [radForm, setRadForm] = useState(false);

  const [ episodeId, setEpisodeId ] = useState(props.match.params.id);
  const [searchRadioQuery, setSearchRadioQuery] = useState("");

  const [, setIsLoading] = useState(true);
  const [selectedNoteToEdit, setSelectedNoteToEdit] = useState("");
  const [viewRecord, setViewRecord] = useState(false);

  const [showConfirmationModel, setShowConfirmationModel] = useState(false);

  const [selectedEdr, setSelectedEdr] = useState("");



  const [timer, setTimer] = useState(null);
  const [loadSearchedData, setLoadSearchedData] = useState(false);


  if (openNotification) {
    setTimeout(() => {
      setOpenNotification(false);
      setErrorMsg("");
      setsuccessMsg("");
    }, 4000);
  }

  useEffect(() => {
    console.log("episodeId::", episodeId);


    // Getting Realtime Data from Screen
    axios
      .get(getSingleEdr + "/" + episodeId)
      .then((res) => {
        console.log('handleView::', res.data.success, res.data.data);
        if (res.data.success) {

          let remoteRecord = replacePatientIdIfNull(res.data.data);

          const object = {
            '_id': remoteRecord._id,
            patientName:
              remoteRecord.patientId.name[0].given[0] +
              " " +
              remoteRecord.patientId.name[0].family,
            mrn: remoteRecord.requestNo,//remoteRecord.patientId.identifier[0].value,
            age: remoteRecord.patientId.age,
            gender: remoteRecord.patientId.gender,
            weight: remoteRecord.patientId.weight,
            department: remoteRecord.currentLocation,
            chiefComplaint: remoteRecord.chiefComplaint.length > 0
                      ? remoteRecord.chiefComplaint.pop().chiefComplaintId.short_desc
                      : "N/A",
            chiefComplaintOthers: remoteRecord.chiefComplaintOthers,
          };
          console.log('handleView2::', object)

          console.log('dischargeRequest', remoteRecord.dischargeRequest.dischargeMedication)

          dispatch({field: 'dischargeMedication', value: remoteRecord.dischargeRequest.dischargeMedication})
          setPatientDetails(object);
          setSelectedEdr(remoteRecord);

        }
      })
      .catch((e) => {
        console.log("error after searching patient request", e);
      });


  }, []);

  const handleViewPharmacy = (obj) => {
    console.log(obj);
    props.history.push({
      pathname: "requestWithPatientDetails/completerequest",
      state: {
        selectedItem: obj,
        comingFor: "view",
      },
    });
  };

  const handleEditPharmacy = (obj) => {
    console.log(obj);
    props.history.push({
      pathname: "requestWithPatientDetails/completerequest",
      state: {
        selectedItem: {
          ...obj,
          edrId: props.history.location.state.selectedRecord._id,
        },
        comingFor: "edit",
      },
    });
  };

  const triggerMedChange = (a) => {
    handleSearch(a);
  };


  const handlePauseMedSearch = (e) => {
    setLoadSearchedData(true);
    clearTimeout(timer);

    const a = e.target.value.replace(/[^\w\s]/gi, "");
    setSearchQuery(a);

    setTimer(
      setTimeout(() => {
        triggerMedChange(a);
      }, 600)
    );
  };


  const handleSearch = (e) => {
    if (e.length >= 1) {
      let url = "";
      if (selectedItemToSearch === "pharmaceutical") {
        url = getSearchedPharmaceuticalItemsUrl;
      } else {
        url = getSearchedItemsNonPharmaceuticalUrl;
      }
      axios
        .get(url + "/" + e)
        .then((res) => {
          if (res.data.success) {
            if (res.data.data.items.length > 0) {
              console.log("price data", res.data.data);
              setItemFoundSuccessfully(true);
              setItemFound(res.data.data.items);
              setLoadSearchedData(false);
            } else {
              setItemFoundSuccessfully(false);
              setItemFound("");
              setLoadSearchedData(false);
            }
          }
        })
        .catch((e) => {
          console.log("error while searching medicine", e);
        });
    }
  };



  function handleAddItem(i) {
    // console.log("selected med", i);

    // dispatch({ field: "itemId", value: i._id });
    // dispatch({ field: "medicineName", value: i.name });
    // dispatch({ field: "unitPrice", value: i.issueUnitCost });
    // dispatch({ field: "issueUnit", value: i.issueUnit });
    // // dispatch({ field: 'totalPrice', value: i.purchasePrice + i.tax })

    // setSearchQuery("");
    console.log("selected item", i);

    // setSelectedLab(i);
    let readyToAdd = seletedMedItems;

    let itemExists = readyToAdd.filter((element, index, array) => {
        // Return true or false
        return element.itemId === i._id;
    });

    if ( itemExists.length<=0 ){
      readyToAdd.push({
        itemId: i._id,
        itemName: i.name,
        issueUnit: i.issueUnit,
        itemCode: i.itemCode,
        unitPrice: i.issueUnitCost,
        totalPrice: (1*1*1*i.issueUnitCost),
        issueUnit: i.issueUnit,
        schedule: 'Now',
        priority: '',
        dosage: 1,
        duration: 1,
        frequency: 1,
        requestedQty: 1,
        itemType: selectedItemToSearch,
        refill: 0,
        make_model: '',
        size: '',
        route: '',
        notes: '',
        audio: ''
      });
    }

    dispatch({
      field: 'seletedMedItems',
      value: readyToAdd
    });


    setSearchQuery("");
  }



  const onChangeRadio = (e) => {
    dispatch({ field: "selectedItemToSearch", value: e.target.name });
    setItemFoundSuccessfully(false);
    setItemFound("");
  };



  const onChangeNotesValue = (e, index, item) => {
    let newSelectedLabItems = seletedMedItems;
    newSelectedLabItems.map((d, index) => {
      if (d.itemId === item){
        d.notes = e;
      }
    });
    dispatch({
      field: 'seletedMedItems',
      value: newSelectedLabItems
    });
  };

  const removeSelectedItem = (index, id) => {
    if (window.confirm('Confirm Removal?')){

      seletedMedItems.map((d, index) => {
        if (d.itemId === id){
          console.log('LabReuqest::remove::', id, 'included');
          let indexOfItem = seletedMedItems.indexOf( d );
          console.log('LabReuqest::remove::', 'indexOfItem', indexOfItem);
          seletedMedItems.splice( indexOfItem, 1 );
          dispatch({
            field: 'seletedMedItems',
            value: seletedMedItems
          });
        }
      });


      console.log('LabReuqest::remove::', index, id);
      if (seletedMedItems.includes(id)){ // Already Exists Item

        
      }
    }
  };

  const onChangeValue = (e, xIndex, item) => {
    console.log("PharmaReq :: onChangePriorityValue", e, xIndex, item);

    let newSelectedLabItems = seletedMedItems;
    newSelectedLabItems.map((d, index) => {
      if (d.itemId === item){
        console.log("PharmaReq :: onChangePriorityValue :: d", d);
        let xPriority     = d['priority'];
        let xDosage       = d['dosage'];
        let xDuration     = d['duration'];
        let xFrequency    = d['frequency'];
        let xReqQty       = d['requestedQty'];

        if (xIndex === "dosage"){
          xDosage = e;
        }
        if (xIndex === "duration"){
          xDuration = e;
        }
        if (xIndex === "frequency"){
          xFrequency = e;
        }
        if (xIndex === "requestedQty"){
          xReqQty = e;
        }

        let finalReqQty = 0;
        if ( xFrequency === 0 || xFrequency>=11 ){
          finalReqQty = xReqQty;
        }else{
          finalReqQty = xDosage * xDuration * xFrequency;
        }

        // requestedQty: 1,


        d[`${xIndex}`] = e;
        d['requestedQty'] = finalReqQty;
        d['totalPrice'] = d['unitPrice']*finalReqQty;
      }
    });
    console.log("PharmaReq :: onChangePriorityValue :: nv", newSelectedLabItems);
    dispatch({
      field: 'seletedMedItems',
      value: newSelectedLabItems
    });
  };

  const onChangePriorityValue = (e, index, item) => {
    console.log("PharmaReq :: onChangePriorityValue", e, index, item);

    let newSelectedLabItems = seletedMedItems;
    newSelectedLabItems.map((d, index) => {
      if (d.itemId === item){
        d.priority = e;
      }
    });
    console.log("PharmaReq :: onChangePriorityValue :: nv", newSelectedLabItems);
    dispatch({
      field: 'seletedMedItems',
      value: newSelectedLabItems
    });
  };

  const startRecording = (index, item) => {
    console.log('handleView::ii::', index, item)
    if (isBlocked) {
      setOpenNotification(true);
      setErrorMsg("The Record is Blocked by the Browser!");
    } else {

      setOpenNotification(true);
      setsuccessMsg("The Record has been started!");
      Mp3Recorder.start()
        .then(() => {
          dispatch({ field: "isRecording", value: true });
          dispatch({ field: "recordingField", value: item });
          console.log('handleView::rec::',true)
        })
        .catch((e) => console.error(e));
    }
  };

  const stopRecording = () => {
    Mp3Recorder.stop()
      .getMp3()
      .then(([buffer, blob]) => {

        const blobURLFinal = URL.createObjectURL(blob);
        let blobURLx = blobURL;
        blobURLx[ recordingField ] = blobURLFinal;
        dispatch({ field: "blobURL", value: blobURLx });

        dispatch({ field: "isRecording", value: false });
        var file = new File([blob], "first", { type: "audio/mp3" });


        // Setting Up Audio File
        if (seletedMedItems.length > 0){
          seletedMedItems.map((i, itemIx) => {
            // let item = mItems.filter((element, index, array) => element._id === recordingField);
            console.log('handleView::rec::stop::', i, itemIx, recordingField)
            if (i.itemId == recordingField){
              i.audio = file;
            }
          });
        }


        dispatch({ field: "fileAudio", value: file });
        dispatch({ field: "recordingField", value: '' });
        console.log('handleView::rec::',false)
      })
      .catch((e) => console.log(e));
  };



  const validateFields = () => {

    let panadTS = true;

    if (seletedMedItems.length > 0){

      seletedMedItems.map((d, index) => {
        if ( d.dosage  <= 0 ){
          panadTS = false;
        }
        if ( d.duration  <= 0 ){
          panadTS = false;
        }
        if ( d.frequency === "" ){
          panadTS = false;
        }
        if ( d.requestedQty  <= 0 ){
          panadTS = false;
        }
        if ( d.route === "" ){
          panadTS = false;
        }
      });

      if (panadTS === false){
        setOpenNotification(true);
        setErrorMsg("There are some missing data for the medicines.");
      }

    }else{
      setOpenNotification(true);
      setErrorMsg("You must have to add some medicines.");
      panadTS = false;
    }

    return panadTS;
  }


  const addConflictReport = () => {
    // PharmaReq 
    dispatch({
      field: 'isFormSubmitted',
      value: true
    });

    if (
      validateFields()
    ){
      
      setOpenNotification(true);
      setsuccessMsg("We are sending ");

      let postedData = seletedMedItems;

      //SS
      let formData = new FormData();

      // Check Audio Notes
      for (let ixp = 0; ixp < postedData.length; ixp++) {
        if ( postedData[ixp].audio!=="" && postedData[ixp].audio instanceof Blob )
        {
          formData.append("AudioFile", postedData[ixp].audio, postedData[ixp].itemId + ".mp3");
          postedData[ixp].audio = '';
        }
      }

      let dischargeMedicationObject = {
        date: Date.now(),
        status: status,
        requester: currentUser._id,
        medicine: postedData,
      };

      formData.append("requestedBy", currentUser._id);
      formData.append("staffId", currentUser._id);
      formData.append("requestType", requestType);
      formData.append("dischargeMedication", JSON.stringify(dischargeMedicationObject));
      formData.append("dischargeSummary"   , JSON.stringify(selectedEdr.dischargeRequest.dischargeSummary));
      formData.append("edrId", episodeId);

      axios
        .put(updateConflictRequestByEdDoctor, formData)//updateConflictRequestByEdDoctor
        .then((res) => {
          console.log( 'PharmaReq', res.data );
          if (res.data.success) {
            props.history.push({
              pathname: "/dashboard/home/"+episodeId+"/pharmacy",
            });
          } else if (!res.data.success) {
            setOpenNotification(true);
            setErrorMsg("Error while adding the Parma Request");
          }
        })
        .catch((e) => {
          console.log('PharmaReq', "error after adding Parma Request", e);
          setOpenNotification(true);
          setErrorMsg("An error occurred while adding the Parma Request.");
        });
        
    }
    // else{
    //   setOpenNotification(true);
    //   setErrorMsg("Error while adding the Parma Request");
    // }
  };




  return (
    <div
      style={{
        backgroundColor: "rgb(19 213 159)",
        position: "fixed",
        display: "flex",
        width: "100%",
        height: "100%",
        flexDirection: "column",
        flex: 1,
        overflowY: "scroll",
      }}
    >
      <Header history={props.history} />

      <PatientDetailsQuick
        edrId={episodeId} />

      <div className="cPadding">
        <div className="subheader" style={{ marginLeft: "-10px" }}>
          <div>
            <img src={PharmaIcon} />

            <h4>Discharge Medications Conflict</h4>
          </div>
        </div>

        {patientDetails ? (
          <PatientClinicalInformation
            edrId={episodeId}
          />
        ) : (
          undefined
        )}

        {
          patientDetails && dischargeMedication ?
          <>
            {
              dischargeMedication.conflictmedicine && dischargeMedication.conflictmedicine.length > 0 ?
            <div className="row row-5 mt-3">
              <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                <div className="card card-detail-info card-detail-small mt-2">
                  <div className="row row-5">
                    <div className="col-5 card-detail-block block-xdiv mb-2 mb-md-3"><strong className="lblX">Request ID:</strong> <span className="valX">{(selectedEdr.requestNo + " ").replace('KHMC', 'DPM-')}</span></div>
                    {/*<div className="col-7 card-detail-block block-xdiv mb-2 mb-md-3"><strong className="lblX">Date/Time</strong> <span className="valX">{moment(dischargeMedication.date).format('MMM DD, YYYY LT')}</span></div>*/}
                    {
                      Object.entries(dischargeMedication.conflictmedicine).map((obj, entry) => {
                        return (
                          <>
                            <div className="col-sm-12 card-detail-block block-xdiv mb-2"><strong className="lblX">Item Name</strong><span className="valX text-capitalize"><strong>{obj[1].itemId.itemCode}:</strong> {obj[1].itemId.name?obj[1].itemId.name.toLowerCase():undefined} ({obj[1].itemId.issueUnit})</span></div>
                            <div className="col card-detail-block block-xdiv mt-1"><strong className="lblX">Dosage</strong><span className="valX">{obj[1].dosage}</span></div>
                            <div className="col card-detail-block block-xdiv mt-1"><strong className="lblX">Duration</strong><span className="valX">{obj[1].duration}</span></div>
                            <div className="col card-detail-block block-xdiv mt-1"><strong className="lblX">Frequency</strong><span className="valX">{medicineFrequencyArray[obj[1].frequency]}</span></div>
                            <div className="col card-detail-block block-xdiv mt-1"><strong className="lblX">Route</strong><span className="valX">{obj[1].route}</span></div>
                            <div className="col card-detail-block block-xdiv mt-1"><strong className="lblX">{matches ? "Requested Qty" : "Req. Qty"}</strong><span className="valX">{(Math.ceil(obj[1].frequency)>=11 || Math.ceil(obj[1].frequency)===0)?obj[1].requestedQty:obj[1].dosage*obj[1].duration*obj[1].frequency}</span></div>
                            <div className="col card-detail-block block-xdiv mt-1"><strong className="lblX">Refill</strong><span className="valX">{Math.ceil(obj[1].refill)}</span></div>
                            <div className="col-12"><hr /></div>
                          </>
                        )
                      })
                    }
                  </div>
                  <div className="card-status-action">
                    {
                      (
                        dischargeMedication.conflictmedicine.length>0?
                        <span className={classNames(`badge`, `status-pending`)}>Conflict</span>:undefined
                      )
                    }
                  </div>

                  <div className="serviceNotesx">
                    <div className="notesDiv">
                      <TextField
                        // required
                        multiline="true"
                        rows="5"
                        // disabled={enableForm}
                        label="Conflict Notes"
                        value={dischargeMedication.conflictnotes}
                        className="textInputStyle disabledText"
                        variant="filled"
                        InputProps={{
                          disableUnderline: true,
                          readonly: true,
                          focus: false,
                        }}
                      />
                      {
                        <audio
                          style={{
                            marginRight: 10,
                            width: matches ? 450 : 260,
                            marginTop: 6,
                          }}
                          src={`${audioURL}/${dischargeMedication.conflictaudio}`}
                          controls controlsList="nodownload"
                        />
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
            :undefined
            }
            {
              /*
              <div className="col-12"><div className='alert alert-info'>There is no discharge medication request at the moment.</div></div>
              */
            }

            <h4 className="text-white mt-4" style={{ fontWeight: 600 }}>New Medication Request</h4>

            <div
              className="form-group "
              style={{
                ...styles.inputContainerForTextField
              }}
            >
              <TextField
                required
                label="Search by Item Name"
                name={"searchQuery"}
                value={searchQuery}
                onChange={handlePauseMedSearch}
                className="textInputStyle"
                variant="filled"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <AccountCircle />
                    </InputAdornment>
                  ),
                  className: classes.input,
                  classes: { input: classes.input },
                  disableUnderline: true,
                }}
                InputLabelProps={{
                  className: classes.label,
                  classes: { label: classes.label },
                }}
              />
            </div>





            {searchQuery ? (
              <div
                style={{
                  zIndex: 10,
                }}
                className="mb-3 mb-3"
              >
                <Paper style={{ maxHeight: 280, overflow: "auto" }}>
                  {itemFoundSuccessfull && itemFound !== "" ? (
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell>Service Name</TableCell>
                          <TableCell align="right">Type</TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {itemFound.map((i) => {
                          return (
                            <TableRow
                              key={i.itemCode}
                              onClick={() => handleAddItem(i)}
                              style={{ cursor: "pointer" }}
                            >
                              <TableCell><i className="fa fa-caret-right"></i><span className="ml-2"><strong>{i.itemCode}</strong>: {i.tradeName}</span><br />{i.scientificName}</TableCell>
                              <TableCell align="right">{i.category}</TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  ) : loadSearchedData ? (
                    <div style={{ textAlign: "center" }}>
                      <Loader
                        type="TailSpin"
                        color="#2c6ddd"
                        height={25}
                        width={25}
                        style={{
                          display: "inline-block",
                          padding: "10px",
                        }}
                      />
                      <span
                        style={{
                          display: "inline-block",
                          padding: "10px",
                        }}
                      >
                        <h4> Searching Lab Test...</h4>
                      </span>
                    </div>
                  ) : searchQuery && !itemFoundSuccessfull ? (
                    <div style={{ textAlign: "center", padding: "10px" }}>
                      <h4>No Lab Test Found !</h4>
                    </div>
                  ) : (
                    undefined
                  )}
                </Paper>
              </div>
            ) : (
              undefined
            )}





            <Paper className="mb-3">
              <Table size="small" stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>Item Name</TableCell>
                  </TableRow>
                </TableHead>
                {seletedMedItems.length > 0 ? (
                  <TableBody>
                    {seletedMedItems.map((i, itemIx) => {
                      return (
                        <TableRow
                          key={i}
                          style={{ cursor: "pointer" }}
                          title="Click to Remove"
                        >
                          <TableCell>
                            <div className="serviceOffers">
                              <div className="serviceHeader">
                                <div className="serviceDesc-Name">{i.xUrgent==='Yes'?<i className="fa fa-info-circle text-market"></i>:<i className="fa fa-caret-right"></i>}
                                <span className="ml-2 text-capitalize">
                                  {
                                    typeof(i.itemId) === 'object'?
                                    <><strong>{i.itemId.itemCode}</strong>: {i.itemId.name} ( {i.itemId.issueUnit} )</>:
                                    <><strong>{i.itemCode}</strong>: {i.itemName} ( {i.issueUnit} )</>
                                  }
                                </span>
                                </div>
                                <div className="serviceOption text-right">
                                  <Icon className="fa fa-trash" color="secondary" onClick={() => removeSelectedItem(i, i.itemId)} />
                                </div>
                              </div>
                              {/*<pre>{JSON.stringify(i, undefined, 4)}</pre>*/}
                              <div className="container-fluid-x">

                              <div className="row">
                                <div
                                  className="col-md-2 col-sm-6 col-6"
                                  style={{
                                    ...styles.inputContainerForTextField,
                                  }}
                                >
                                  <div className="row row-5 align-items-center">
                                    <div className="col-6 col-md-6"><strong>Dosage:</strong>&nbsp;</div>
                                    <div className="col-6 col-md-6">
                                      <TextField
                                        required
                                        disabled={props.disableFields ? true : false}
                                        name={"dosage"}
                                        value={i.dosage}
                                        onChange={(e) => {
                                          onChangeValue(e.target.value, 'dosage', i.itemId)
                                        }}
                                        className="textInputStyle text-center"
                                        size="small"
                                        type="number"
                                        InputProps={{
                                          inputProps: { min: 1 },
                                          shrink: true,
                                          className: classes.input,
                                          classes: { input: classes.input },
                                        }}
                                      />
                                      {
                                        isFormSubmitted && (i.dosage === "" || i.dosage <= 0) ? <div class="mt-2 text-danger">Required</div>:undefined
                                      }
                                    </div>
                                  </div>
                                </div>

                                <div
                                  className="col-md-4 col-sm-6 col-6"
                                  style={{
                                    ...styles.inputContainerForTextField,
                                  }}
                                >
                                  <div className="row row-5 align-items-center">
                                    <div className="col-6 col-md-4"><strong>Frequency:</strong></div>
                                    <div className="col-6 col-md-8">
                                      <TextField
                                        required
                                        select
                                        fullWidth
                                        disabled={props.disableFields ? true : false}
                                        id="frequency"
                                        name="frequency"
                                        value={i.frequency}
                                        onChange={(e) => {
                                          onChangeValue(e.target.value, 'frequency', i.itemId)
                                        }}
                                        className="dropDownStyle"
                                        InputProps={{
                                          className: classes.input,
                                          classes: { input: classes.input },
                                        }}
                                      >
                                        <MenuItem value="">None</MenuItem>
                                        {frequencyArray.map((val) => {
                                          return (
                                            <MenuItem key={val.key} value={val.key}>
                                              {val.value}
                                            </MenuItem>
                                          );
                                        })}
                                      </TextField>
                                      {
                                        isFormSubmitted && (i.frequency === "" || i.frequency <= 0) ? <div class="mt-2 text-danger">Required</div>:undefined
                                      }
                                    </div>
                                  </div>
                                </div>

                                <div
                                  className="col-md-2 col-sm-6 col-6"
                                  style={{
                                    ...styles.inputContainerForTextField,
                                  }}
                                >
                                  <div className="row row-5 align-items-center">
                                    <div className="col-6 col-md-6"><strong>Duration:</strong></div>
                                    <div className="col-6 col-md-6">
                                      <TextField
                                        required
                                        disabled={props.disableFields ? true : false}
                                        name={"duration"}
                                        value={i.duration}
                                        onChange={(e) => {
                                          onChangeValue(e.target.value, 'duration', i.itemId)
                                        }}
                                        className="textInputStyle text-center"
                                        type="number"
                                        InputProps={{
                                          inputProps: { min: 1 },
                                          className: classes.input,
                                          classes: { input: classes.input },
                                        }}
                                      />
                                      {
                                        isFormSubmitted && (i.duration === "" || i.duration <= 0) ? <div class="mt-2 text-danger">Required</div>:undefined
                                      }
                                    </div>
                                  </div>

                                  {/* <ErrorMessage
                                        name={duration}
                                        isFormSubmitted={isFormSubmitted}
                                      /> */}
                                </div>

                                <div
                                  className="col-md-2 col-sm-6 col-6"
                                  style={{
                                    ...styles.inputContainerForTextField,
                                  }}
                                >
                                  <div className="row row-5 align-items-center">
                                    <div className="col-6 col-md-6"><strong>Dispensed:</strong></div>
                                    <div className="col-6 col-md-6">
                                      <TextField
                                        required
                                        disabled={i.frequency>0&&i.frequency<=10?true:false}
                                        name={"requestedQty"}
                                        value={(i.frequency>=11 || i.frequency===0)?i.dispensed:(i.dosage * i.duration * i.frequency)}
                                        onChange={(e) => {
                                          onChangeValue(e.target.value, 'requestedQty', i.itemId)
                                        }}
                                        className="textInputStyle text-center"
                                        type="number"
                                        InputProps={{
                                          className: classes.input,
                                          classes: { input: classes.input },
                                        }}
                                      />
                                    </div>
                                  </div>

                                  {/* <ErrorMessage
                                        name={requestedQty}
                                        isFormSubmitted={isFormSubmitted}
                                      /> */}
                                </div>

                                <div
                                  className="col-md-2 col-sm-6 col-6"
                                  style={{
                                    ...styles.inputContainerForTextField,
                                  }}
                                >
                                  <div className="row row-5 align-items-center">
                                    <div className="col-6 col-md-6"><strong>Refill:</strong></div>
                                    <div className="col-6 col-md-6">
                                      <TextField
                                        required
                                        disabled={props.disableFields ? true : false}
                                        name={"refill"}
                                        value={i.refill}
                                        onChange={(e) => {
                                          onChangeValue(e.target.value, 'refill', i.itemId)
                                        }}
                                        className="textInputStyle text-center"
                                        type="number"
                                        InputProps={{
                                          inputProps: { min: 0 },
                                          className: classes.input,
                                          classes: { input: classes.input },
                                        }}
                                      />
                                      {
                                        isFormSubmitted && (i.refill === "" || i.refill < 0) ? <div class="mt-2 text-danger">Required</div>:undefined
                                      }
                                    </div>
                                  </div>

                                  {/* <ErrorMessage
                                        name={refill}
                                        isFormSubmitted={isFormSubmitted}
                                      /> */}
                                </div>


                                <div
                                  className="col-md-12 col-sm-12 col-12"
                                  style={{
                                    ...styles.inputContainerForTextField,
                                  }}
                                >
                                  <div className="row row-5 align-items-center">
                                    <div className="col-4 col-md-3 col-lg-2"><strong>Route Taken:</strong></div>
                                    <div className="col-8 col-md-9 col-lg-10">
                                      <TextField
                                        required
                                        select
                                        fullWidth
                                        name="route"
                                        value={i.route}
                                        onChange={(e) => {
                                          onChangeValue(e.target.value, 'route', i.itemId)
                                        }}
                                        className="dropDownStyle"
                                        InputProps={{
                                          className: classes.input,
                                          classes: { input: classes.input },
                                        }}
                                      >
                                        {routeArray.map((val) => {
                                          return (
                                            <MenuItem key={val.key} value={val.key}>
                                              {val.value}
                                            </MenuItem>
                                          );
                                        })}
                                      </TextField>
                                      {
                                        isFormSubmitted && i.route === "" ? <div class="mt-2 text-danger">Required</div>:undefined
                                      }
                                      {/* <ErrorMessage
                                            name={route}
                                            isFormSubmitted={isFormSubmitted}
                                          /> */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              </div>



                              <div className="serviceNotes">
                                <div className="micControl">
                                  <div className="d-flex align-items-center">
                                    <div
                                      style={{
                                        backgroundColor: "#2C6DDD",
                                        height: 42,
                                        borderRadius: 42,
                                        width: 42,
                                        paddingLeft: 10,
                                        cursor: 'pointer'
                                      }}
                                    >
                                      {isRecording ? (
                                        recordingField===i.itemId?
                                        <StopIcon
                                          style={{ marginTop: 10, color: "white" }}
                                          fontSize="16px"
                                          onClick={stopRecording}
                                          disabled={!isRecording}
                                          color="secondary"
                                        />:
                                        <MicIcon
                                          style={{ marginTop: 10, color: "white" }}
                                          fontSize="16px"
                                          onClick={() => {
                                            startRecording(i, i.itemId)
                                          }}
                                          disabled={isRecording}
                                          color="primary"
                                        />
                                      ) : (
                                        <MicIcon
                                          style={{ marginTop: 10, color: "white" }}
                                          fontSize="16px"
                                          onClick={() => {
                                            startRecording(i, i.itemId)
                                          }}
                                          disabled={isRecording}
                                          color="primary"
                                        />
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div className="notesDiv">
                                  <TextField
                                    // required
                                    multiline="true"
                                    rows="3"
                                    // disabled={enableForm}
                                    label="Any Comments / Notes? (Optional)"
                                    name={"labComments"}
                                    // value={i.notes.text}
                                    // error={labComments === ""}
                                    onChange={(e) => {onChangeNotesValue(e.target.value, i, i.itemId)}}
                                    className="textInputStyle"
                                    variant="filled"
                                    InputProps={{
                                      disableUnderline: true,
                                    }}
                                  />
                                  {isRecording && (recordingField===i.itemId) ? (
                                    <div
                                      style={{
                                        marginLeft: matches ? 110 : 18,
                                        marginRight: matches ? 110 : 75,
                                        width: matches ? 550 : 100,
                                      }}
                                    >
                                      <Loader
                                        type="ThreeDots"
                                        color="blue"
                                        height={40}
                                        width={40}
                                      />
                                    </div>
                                  ) : (
                                    <audio
                                      style={{
                                        marginRight: 10,
                                        width: matches ? 450 : 260,
                                        marginTop: 6,
                                      }}
                                      src={blobURL[i.itemId]}
                                      controls="controls"
                                    />
                                  )}
                                </div>
                              </div>
                            </div>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                ) : (
                  <TableBody>
                    <TableRow key="0">
                      <TableCell align="center" colSpan={5}>No Item Selected</TableCell>
                    </TableRow>
                  </TableBody>
                )} 
              </Table>
            </Paper>
          </>:undefined
        }

        <div className="row row-5">
          <div className="col-12 col-md-4 offset-md-4">
            <Button
              // className="addButton"
              style={{
                ...styles.stylesForButton,
                backgroundColor: "#AD6BBF",
                color: "white",
                cursor: "pointer",
                borderRadius: 5,
                backgroundColor: "rgb(173, 107, 191)",
                height: matches ? 56 : 45,
                outline: "none",
                width: "100%",
              }}
              // disabled={!addLabRequest}
              onClick={addConflictReport}
              variant="contained"
              color="primary"
              fullWidth
            >Submit</Button>
          </div>
        </div>
      </div>
      <Notification
          msg={errorMsg}
          open={openNotification}
          success={successMsg}
        />
    </div>
  );
}