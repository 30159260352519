import React, { useState, useEffect, useReducer } from "react";
import Chip from "@material-ui/core/Chip";
import DoneIcon from "@material-ui/icons/Done";
import { TextField, Button, MenuItem, Grid } from "@material-ui/core";
import "jspdf-autotable";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Notification from "../../../components/Snackbar/Notification.js";
import Header from "../../../components/Header/Header";
import claimsReview from "../../../assets/img/AssignDCD Form.png";
import MicIcon from "@material-ui/icons/Mic";
import StopIcon from "@material-ui/icons/Stop";
import MicRecorder from "mic-recorder-to-mp3";
import Loader from "react-loader-spinner";
import { ccAssignStyles } from "./styles";
import axios from "axios";
import cookie from "react-cookies";
import PatientDetailsQuick from "../../../components/PatientDetails/PatientDetailsQuick";
import {
  assignCCtoPatient,
  getAllChiefComplaints,
  getIcdForDropdown,
  getIcdbyFeaturedGroup,
} from "../../../public/endpoins";
import PatientDetails from "../../../components/PatientDetails/PatientDetailsDirect";
import Back from "../../../assets/img/Back_Arrow.png";
import Autocomplete from "@material-ui/lab/Autocomplete";

const styles = {
  ROSRowPadding: {
    marginLeft: 0,
    marginRight: 0,
    backgroundColor: "white",
    marginTop: 10,
    padding: "6px 0px 6px 0px",
    borderRadius: 5,
  },
  chipAlignment: {
    display: "inline-block",
    padding: "5px",
  },
  save: {
    color: "white",
    cursor: "pointer",
    borderRadius: 5,
    backgroundColor: "#2c6ddd",
    width: "115px",
    height: "40px",
    outline: "none",
  },
};


const Mp3Recorder = new MicRecorder({ bitRate: 128 });
function AddEditPatientListing(props) {
  const matches = useMediaQuery("(min-width:600px)");
  const classes = ccAssignStyles();
  const initialState = {
    chiefComplaint: "",
    comments: "",
    isBlocked: false,
    isRecording: false,
    blobURL: "",
    fileAudio: null,
    errorMsg: "",
    chiefComplaintList: [],
    currentUser: cookie.load("current_user"),
    successMsg: "",
    openNotification: false,
    patientDetails: {},
  };

  function reducer(state, { field, value }) {
    return {
      ...state,
      [field]: value,
    };
  }

  const [state, dispatch] = useReducer(reducer, initialState);
  const [patientDetailsDialog, openPatientDetailsDialog] = useState(false);

  const [chiefComplaintInList, setChiefComplaintInList] = useState([]);
  const [chiefComplaintSelected, setChiefComplaintSelected] = useState([]);
  const [Icd10CodesList, setIcd10CodesList] = useState([]);
  const [icd10SearchedCodes, setIcd10SearchedCodes] = useState("");
  const [primaryChiefComplaint, setPrimaryChiefComplaint] = useState({
    "_id": "",
    "name": "",
    "code": "",
  });
  const [cameFromPage, setCameFromPage] = useState('');
  const [patientDCDForm, setPatientDCDForm] = useState('');
  const [otherChiefComplaintSelected, setOtherChiefComplaintSelected] = useState(false);

  const {
    chiefComplaint,
    comments,
    isBlocked,
    isRecording,
    blobURL,
    fileAudio,
    currentUser,
    chiefComplaintList,
    errorMsg,
    successMsg,
    openNotification,
    patientDetails,
  } = state;

  useEffect(() => {
    console.log("Props passed ", props.history.location.state.selectedItem);
    const { selectedItem, cameFrom } = props.history.location.state;
    setCameFromPage(cameFrom);
    setPatientDCDForm(selectedItem);
    console.log("Props passed ", props.history.location.state.cameFrom)

    const object = {
      edrId: selectedItem._id,
      patientName: selectedItem.patientName,
      mrn: selectedItem.mrn,
      age: selectedItem.age,
      gender: (selectedItem.patientId && selectedItem.patientId!=null ? selectedItem.patientId.gender:''),
      weight: (
        selectedItem.patientId && selectedItem.patientId!=null ?
        (
          selectedItem.patientId.weight === null
            ? "N/A"
            : selectedItem.patientId.weight
        ):''
      )
    };

    dispatch({ field: "patientDetails", value: object });

    // Getting List of All Chief Complaint ICD Codes
    console.log("Chief Complaints", getIcdbyFeaturedGroup);
    axios
      .get(getIcdbyFeaturedGroup+"ChiefComplaint")
      .then((response) => {
        console.log("chiefComplaintListX", response.data.data.items);
        let chiefComplaintListX = [];
        Object.entries(response.data.data.items).map(([key, val]) => {
          chiefComplaintListX.push({
            id: val._id,
            name: val.short_name,
            code: val.code,
          });
        });
        setChiefComplaintInList(chiefComplaintListX);
      })
      .catch((error) => {
        console.log("Error", error);
      });


    navigator.mediaDevices &&
      navigator.mediaDevices.getUserMedia(
        { audio: true },
        () => {
          console.log("Permission Granted");
          dispatch({ field: "isBlocked", value: false });
        },
        () => {
          console.log("Permission Denied");
          dispatch({ field: "isBlocked", value: true });
        }
      );
  }, []);

  
  useEffect(() => {
    console.log("icd10CodesDropdown", icd10SearchedCodes);
    const getSearchedIcd10KeywordList = () => {
      console.log("icd10CodesDropdownStart", icd10SearchedCodes, getIcdForDropdown);
      if (icd10SearchedCodes.length > 2){
      axios
        // .get(getSearchedPharmaceuticalItemsUrl + "/" + icd10SearchedCodes)
        .get(getIcdForDropdown, {
          params: {
            notIn: 'ChiefComplaint',
            keyword: icd10SearchedCodes
          }
        })
        .then((response) => {
          console.log("icd10CodesDropdown", response.data.items);
          let icd10CodesDropdown = [];
          Object.entries(response.data.items).map(([key, val]) => {
            icd10CodesDropdown.push({
              id: val._id,
              name: val.short_desc,
              code: val.code,
            });
          });
          console.log("icd10CodesDropdown", icd10CodesDropdown);
          setIcd10CodesList(icd10CodesDropdown);
        })
        .catch((error) => {
          console.log("Error", error);
        });
      }
    };
    icd10SearchedCodes && getSearchedIcd10KeywordList();
  }, [icd10SearchedCodes]);

  const changeHandler = (e) => {
    switch (e.target.name) {
      case "comments":
        return dispatch({
          field: "comments",
          value: e.target.value.replace(/[^\w.'-\s]/gi, ""),
        });
      case "chiefComplaint":
        // return dispatch({ field: "chiefComplaint", value: e.target.value });
      default:
        break;
    }
  };

  const onSubmit = () => {
    const data = new FormData();
    data.append("file", fileAudio);

    let otherChiefComplaintIds = [];
    chiefComplaintSelected.map((val) => {
      otherChiefComplaintIds.push(val.id);
    })

    console.log("Props", props.history.location.state.selectedItem);
    const object = {
      chiefComplaint,
      comments,
      otherComplaints: otherChiefComplaintIds,
      assignedBy: currentUser._id,
      patientid: props.history.location.state.selectedItem._id,
    };
    
    console.log("params", object)
    data.append("data", JSON.stringify(object));

    if (isRecording) {
      dispatch({ field: "openNotification", value: true });
      dispatch({
        field: "errorMsg",
        value: "Please record voice then submit.",
      });
    } else {
      axios
        .put(assignCCtoPatient, data)
        .then((response) => {
          console.log("Hello", response);
          if (response.data.success){
            if (cameFromPage === "successPage"){
              goToSuccessPage();
            }else{
              goToDCDDashboard();
            }
          }else{
            dispatch({
              field: "errorMsg",
              value: response.data.message,
            });
          }
        })
        .catch((error) => {
          console.log("Error", error);
        });
    }
  };

  const existsInChiefComplaintSelected = (val) => {
    var exists = false;
    chiefComplaintSelected.map((chip) => {
        if (chip.code == val.code){
          exists = true;
        }
    });
    console.log('handleChipClick', exists);
    return exists;
  }


  const otherIncludedinChiefComplaint = () => {
    var exists = false;
    chiefComplaintSelected.map((chip) => {
        if (chip.code == "OTHER"){
          exists = true;
        }
    });
    return exists;
  }

  const handleAutoCompleteCComp = (val) => {
    val.map((ival) => {
      if ( !(existsInChiefComplaintSelected(ival)) ){
        setChiefComplaintSelected([
          ...chiefComplaintSelected,
          ival,
        ]);
      }
      dispatch({
        field: "chiefComplaintOther",
        value: [
          ...chiefComplaintSelected,
        ],
      })
    });
  };

  const handleChipClick = (val) => {
    // let oChiefComplaintSelected = chiefComplaintSelected;
    console.log('handleChipClick', val);
    
    setOtherChiefComplaintSelected(false);

    if (val.code=="OTHER"){
      setOtherChiefComplaintSelected(true);
    }else{
      dispatch({
        field: "chiefComplaintOther",
        value: [],
      })
    }

    // if (val.code=="OTHER"){
    //   if (otherChiefComplaintSelected == true){
    //     setOtherChiefComplaintSelected(false);
    //   }else{
    //     setOtherChiefComplaintSelected(true);
    //   }
    //   dispatch({
    //     field: "otherComplaint",
    //     value: true,
    //   })
    // }else{
      setPrimaryChiefComplaint(val);
      console.log("cheifComplaint", {
        field: "chiefComplaint",
        value: val.id,
      });
      dispatch({
        field: "chiefComplaint",
        value: val.id,
      });
    // }
  };

  const goToSuccessPage = () => {
    console.log("patientDetails", patientDCDForm);
    props.history.push({
      pathname: "/dashboard/home/patientmanagement/success",
      state: {
        code: `${patientDCDForm.requestNo.toUpperCase()}`,
        edrId: patientDCDForm._id,
        selectedItem: patientDCDForm,
        mode: "chiefComplaint",
      },
    });
  }

  const goToDCDDashboard = () => {
    const selectedChiefComplaint = chiefComplaintList
      .map((c) => (c._id === chiefComplaint ? c : undefined))
      .filter(Boolean);
    console.log("selectedChiefComplaint: ", selectedChiefComplaint);
    const selectedItem = props.history.location.state.selectedItem;
    props.history.push({
      pathname: "/dashboard/home/dcddashboard",
      //     state: props.history.location.state.selectedItem,
      //   });
      // };
      state: { ...selectedItem, chiefComplaint: selectedChiefComplaint },
    });
  };
  const start = () => {
    if (isBlocked) {
      console.log("Permission Denied");
    } else {
      Mp3Recorder.start()
        .then(() => {
          dispatch({ field: "isRecording", value: true });
        })
        .catch((e) => console.error(e));
    }
  };

  const stop = () => {
    Mp3Recorder.stop()
      .getMp3()
      .then(([buffer, blob]) => {
        const blobURL = URL.createObjectURL(blob);
        dispatch({ field: "blobURL", value: blobURL });
        dispatch({ field: "isRecording", value: false });
        var file = new File([blob], "first", { type: "audio/mp3" });
        dispatch({ field: "fileAudio", value: file });
      })
      .catch((e) => console.log(e));
  };

  function showPatientDetails() {
    openPatientDetailsDialog(false);
  }

  if (openNotification) {
    setTimeout(() => {
      dispatch({ field: "openNotification", value: false });
      dispatch({ field: "error", value: "" });
    }, 2000);
  }

  return (
    <div
      style={{
        backgroundColor: "rgb(19 213 159)",
        position: "fixed",
        display: "flex",
        width: "100%",
        height: "100%",
        flexDirection: "column",
        flex: 1,
        overflowY: "scroll",
        paddingBottom: 50,
      }}
      className={classes.root}
    >
      <Header history={props.history} />

      <PatientDetailsQuick
        edrId={patientDetails.edrId} />

      <div className="cPadding">
        <div className="subheader" style={{ marginLeft: "-10px" }}>
          <div>
            <img src={claimsReview} />
            <div style={{ flex: 4, display: "flex", alignItems: "center" }}>
              <h4>Assign DCD Form</h4>
            </div>
          </div>
        </div>

        <PatientDetails
          edrId={patientDetails.edrId}
          showPatientDetails={showPatientDetails}
          showChiefComplaint={false}
          showTriageLevel={false}
          showCareStream={false}
          showDiagnosis={false}
          showMedication={false}
        />

        <div
          className={`container-fluid ${classes.root}`}
          style={{
            marginTop: "25px",
            marginBottom: 80,
          }}
        >
          <div className={classes.root}>

            <div className="card" style={{
              marginBottom: 10
            }}>

              <div className="card-header">Chief Complaint</div>
              <div className="card-body">
                {
                  chiefComplaintInList.map((val) => {
                    let Icon;
                    let Style = {
                      color: "#070707",
                      backgroundColor: "#F5F5F5",
                    };

                    // console.log("clicked", primaryChiefComplaint);
                    // console.log("clicked", otherChiefComplaintSelected);

                    if (primaryChiefComplaint.code == val.code){
                      Icon = (
                        <DoneIcon
                          style={{ color: "white" }}
                        />
                      );
                      Style = {
                        color: "white",
                        backgroundColor: "rgb(19 213 159)",
                      };
                    }

                    if (otherChiefComplaintSelected==true && val.code === "OTHER"){
                      Icon = (
                        <DoneIcon
                          style={{ color: "white" }}
                        />
                      );
                      Style = {
                        color: "white",
                        backgroundColor: "rgb(19 213 159)",
                      };
                    }

                    return (
                      <>
                        <span style={styles.chipAlignment}>
                          <Chip
                            label={val.name}
                            onClick={() =>{
                              handleChipClick(val)
                            }}
                            icon={Icon}
                            style={Style}
                          />
                        </span>
                      </>
                    );
                  })
                }
              </div>
              {
                otherChiefComplaintSelected? (
                  <>
                  <div className="card-header">Choose Other Complaints</div>
                  <div className="card-body" style={{
                    marginTop: 0
                  }}>
                    <Autocomplete
                      multiple
                      id="chiefcomplaint-list"
                      options={Icd10CodesList}
                      getOptionLabel={(option) =>
                        option.code + ": " + option.name
                      }
                      defaultValue={[]}
                      onChange={(e, value) =>{
                        handleAutoCompleteCComp(value)
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          label="Choose Other Complaints"
                          placeholder="Select Complaints"
                          onChange={(event) => {
                            setIcd10SearchedCodes(
                              event.target.value
                            );
                          }}
                        />
                      )}
                      />
                  </div>
                  </>
                ): undefined
              }
            </div>


            

            <Grid
              justify="flex-start" // Add it here :)
              container
              spacing={24}
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                backgroundColor: "white",
                marginLeft: 0,
                marginRight: 0,
                marginTop: 15,
                borderRadius: 5,
                height: 125,
                marginBottom: 15,
                paddingLeft: 10,
              }}
            >
              {matches ? (
                <label
                  style={{
                    color: "#878787",
                    paddingRight: 30,
                    // fontWeight: "bold",
                  }}
                >
                  Voice Note
                </label>
              ) : (
                undefined
              )}
              <div>
                {!isRecording ? (
                  <audio
                    style={{
                      marginRight: 10,
                      width: matches ? 450 : 260,
                      marginTop: 6,
                    }}
                    src={blobURL}
                    controls="controls"
                  />
                ) : (
                  <div
                    style={{
                      marginLeft: matches ? 110 : 18,
                      marginRight: matches ? 110 : 75,
                      width: matches ? 550 : 100,
                    }}
                  >
                    <Loader
                      type="ThreeDots"
                      color="blue"
                      height={40}
                      width={40}
                    />
                  </div>
                )}
              </div>
              {!matches ? (
                <label
                  style={{
                    color: "#878787",
                    paddingRight: 30,
                    // fontWeight: "bold",
                  }}
                >
                  Voice Note
                </label>
              ) : (
                undefined
              )}
              <div
                style={{
                  marginRight: matches ? 15 : 7,
                  backgroundColor: isRecording ? "#DC004E" : "#2C6DDD",
                  height: matches ? 55 : 50,
                  borderRadius: 30,
                  width: matches ? 55 : 50,
                  paddingLeft: matches ? 10 : 8,
                }}
              >
                {isRecording ? (
                  <StopIcon
                    style={{ marginTop: matches ? 11 : 9 }}
                    fontSize="large"
                    onClick={stop}
                    disabled={!isRecording}
                    color="secondary"
                  />
                ) : (
                  <MicIcon
                    style={{ marginTop: matches ? 12 : 10 }}
                    fontSize="large"
                    onClick={start}
                    disabled={isRecording}
                    color="primary"
                  />
                )}
              </div>
            </Grid>

            <TextField
              multiline
              label="Observational Notes / Comments"
              name={"comments"}
              className={classes.input}
              value={comments}
              onChange={changeHandler}
              className="textInputStyle"
              rows={4}
              variant="filled"
              InputProps={{
                className: classes.input,
                classes: { input: classes.input },
                disableUnderline: true,
              }}
            />

            <div className="row" style={{ marginTop: "10px" }}>
              <div className="col-md-6 col-sm-6 col-6">
                <img
                  onClick={() => props.history.goBack()}
                  src={Back}
                  style={{
                    width: 45,
                    height: 35,
                    cursor: "pointer",
                    marginTop: "15px",
                  }}
                />
              </div>
              <div className="col-md-6 col-sm-6 col-6 d-flex justify-content-end">
                <Button
                  className={classes.stylesForButton}
                  style={{ marginTop: 10 }}
                  align="right"
                  variant="contained"
                  onClick={onSubmit}
                  raised
                  disabled={(chiefComplaint || chiefComplaintSelected.length>0) ? false : true}
                  color="primary"
                >
                  Submit
                </Button>
              </div>
            </div>
          </div>
        </div>

        <Notification
          msg={errorMsg}
          open={openNotification}
          success={successMsg}
        />
      </div>
    </div>
  );
}
export default AddEditPatientListing;
