import React, { useEffect, useState, useReducer } from "react";
import PatientDetails from "../../components/PatientDetails/PatientDetails";
import Header from "../../components/Header/Header";
import cookie from "react-cookies";
import { uploadsUrl } from "../../public/endpoins";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import useStylesForInput from "../../assets/jss/material-dashboard-react/inputStyle";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Notification from "../../components/Snackbar/Notification.js";
import axios from "axios";

import { completeAnesthesiaRequestUrl } from "../../public/endpoins";

import ConfirmationDialog from "../../components/confirmationDialog/confirmationDialog";

const statusArray = [
  { key: "pending", value: "Pending" },
  { key: "complete", value: "Complete" },
  { key: "hold", value: "Hold" },
];

const styles = {
  inputContainerForTextField: {
    marginTop: 25,
  },
  inputContainerForDropDown: {
    marginTop: 25,
    backgroundColor: "white",
    borderRadius: 5,
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 2,
  },
  stylesForButton: {
    color: "white",
    cursor: "pointer",
    borderRadius: 5,
    backgroundColor: "#2c6ddd",
    height: "50px",
    outline: "none",
  },

  stylesForSave: {
    color: "white",
    cursor: "pointer",
    borderRadius: 5,
    backgroundColor: "#2c6ddd",
    width: "150px",
    height: "50px",
    outline: "none",
  },
};

function EditAnesthesiaRequest(props) {
  const initialState = {
    status: "",
    completionTime: "",
    suggessions: "",
    delayReason: "",
    _id: "",
    requestId: "",
    delayTime: "",
  };

  function reducer(state, { field, value }) {
    return {
      ...state,
      [field]: value,
    };
  }

  const [state, dispatch] = useReducer(reducer, initialState);

  const {
    status,
    completionTime,
    suggessions,
    delayReason,
    _id,
    requestId,
    delayTime,
  } = state;

  const matches = useMediaQuery("(min-width:600px)");

  const classes = useStylesForInput();

  const [patientDetails, setPatientDetails] = useState("");
  const [diagnosisArray, setDiagnosisArray] = useState("");
  const [medicationArray, setMedicationArray] = useState("");
  const [selectedRecord, setSelectedRec] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setsuccessMsg] = useState("");
  const [openNotification, setOpenNotification] = useState(false);
  const [currentUser] = useState(cookie.load("current_user"));
  const [showConfirmationModel, setShowConfirmationModel] = useState(false);

  useEffect(() => {
    console.log("props.history.location.state", props.history.location.state);

    const { selectedRecord } = props.history.location.state;
    setSelectedRec(selectedRecord);
    const object = {
      patientName:
        selectedRecord.patientId.name[0].given[0] +
        " " +
        selectedRecord.patientId.name[0].family,
      mrn: selectedRecord.patientId.identifier[0].value,
      age: selectedRecord.patientId.age,
      gender: selectedRecord.patientId.gender,
      weight: selectedRecord.patientId.weight,
    };
    setPatientDetails(object);
    Object.entries(selectedRecord).map(([key, val]) => {
      if (val && typeof val === "object") {
        if (key === "doctorNotes") {
          if (val && val.length > 0) {
            let data = [];
            val.map((d) => {
              d.code.map((singleCode) => {
                let found = data.find((i) => i === singleCode);
                if (!found) {
                  data.push(singleCode);
                }
              });
            });
            console.log("diagnosis aray", data);
            setDiagnosisArray(data);
          }
        } else if (key === "pharmacyRequest") {
          if (val && val.length > 0) {
            let data = [];
            val.map((d) => {
              d.item.map((singleCode) => {
                let found = data.find((i) => i === singleCode.itemName);
                if (!found) {
                  data.push(singleCode.itemName);
                }
              });
            });
            console.log("diagnosis aray", data);
            setMedicationArray(data);
          }
        } else if (key === "anesthesiologistNote") {
          console.log(val);
          dispatch({ field: "status", value: val.status });
          dispatch({ field: "requestId", value: val._id });
          dispatch({
            field: "completionTime",
            value: val.completionTime ? val.completionTime : "",
          });
          dispatch({
            field: "delayTime",
            value: val.delayTime ? val.delayTime : "",
          });
          dispatch({
            field: "delayReason",
            value: val.delayReason ? val.delayReason : "",
          });
          dispatch({
            field: "suggessions",
            value: val.suggessions ? val.suggessions : "",
          });
        }
      } else {
        dispatch({ field: key, value: val });
      }
    });
  }, []);

  const onChangeValue = (e) => {
    dispatch({
      field: e.target.name,
      value: e.target.value.replace(/[^\w\s]/gi, ""),
    });
  };

  const editRequst = () => {
    if (status === "complete") {
      setShowConfirmationModel(true);
    } else {
      handleSubmit();
    }
  };

  const handleSubmit = () => {
    if (!validateForm()) {
      setErrorMsg("Delay reason can not be empty");
      setOpenNotification(true);
      return;
    } else if (selectedRecord.anesthesiologistNote.status === "complete") {
      setErrorMsg("Request can not updated once completed.");
      setOpenNotification(true);
      return;
    } else {
      let obj = {
        edrId: _id,
        _id: requestId,
        suggessions,
        status,
        delayReason,
        completionTime: status === "complete" ? new Date() : "",
        delayTime: delayTime ? delayTime : status === "hold" ? new Date() : "",
      };
      console.log("anesthesia request", obj);

        axios
          .put(completeAnesthesiaRequestUrl, obj)
          .then((res) => {
            if (res.data.success) {
              console.log("response after adding Lab Request", res.data);
              props.history.goBack();
            } else if (!res.data.success) {
              setOpenNotification(true);
              setErrorMsg("Error while adding the Lab Request");
            }
          })
          .catch((e) => {
            console.log("error after adding Lab Request", e);
            setOpenNotification(true);
            setErrorMsg("Error while adding the Lab Request");
          });
    }
  };

  const validateForm = () => {
    if (status === "hold") {
      return delayReason !== "";
    }
    return true;
  };

  if (openNotification) {
    setTimeout(() => {
      setOpenNotification(false);
      setErrorMsg("");
      setsuccessMsg("");
    }, 2000);
  }

  return (
    <div
      style={{
        backgroundColor: "rgb(19 213 159)",
        position: "fixed",
        display: "flex",
        width: "100%",
        height: "100%",
        flexDirection: "column",
        flex: 1,
        overflowY: "scroll",
      }}
    >
      <Header history={props.history} />
      <div className="cPadding">
        {selectedRecord ? (
          <div>
            {patientDetails ? (
              <PatientDetails
                patientDetails={patientDetails}
                pharmacyRequest={medicationArray}
                diagnosisArray={diagnosisArray}
              />
            ) : (
              undefined
            )}

            <div style={{ marginTop: 20 }}>
              <h6 style={{ color: "white", fontWeight: "bold" }}>
                ED Doctor Notes
              </h6>

              <audio
                style={{
                  marginRight: 10,
                  width: matches ? "100%" : "100%",
                  marginTop: 6,
                }}
                src={`${uploadsUrl}${selectedRecord.anesthesiologistNote.voiceNotes}`}
                controls="controls"
              />
            </div>

            <div className="row">
              <div
                className="col-md-12"
                style={{
                  ...styles.inputContainerForTextField,
                  ...styles.textFieldPadding,
                }}
              >
                <TextField
                  required
                  select
                  fullWidth
                  label="Status"
                  name={"status"}
                  value={status}
                  onChange={onChangeValue}
                  variant="filled"
                  className="dropDownStyle"
                  InputProps={{
                    className: classes.input,
                    classes: { input: classes.input },
                    disableUnderline: true,
                  }}
                >
                  {statusArray.map((s) => {
                    return (
                      <MenuItem key={s.key} value={s.key}>
                        {s.value}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </div>
            </div>

            {status === "hold" ? (
              <div className="row">
                <div
                  className="col-md-12"
                  style={{
                    ...styles.inputContainerForTextField,
                    ...styles.textFieldPadding,
                  }}
                >
                  <TextField
                    required
                    fullWidth
                    label="Delay Reason"
                    name={"delayReason"}
                    value={delayReason}
                    onChange={onChangeValue}
                    variant="filled"
                    className="dropDownStyle"
                    InputProps={{
                      className: classes.input,
                      classes: { input: classes.input },
                      disableUnderline: true,
                    }}
                  ></TextField>
                </div>
              </div>
            ) : (
              undefined
            )}

            <div className="row">
              <div
                className="col-md-12"
                style={{
                  ...styles.inputContainerForTextField,
                  ...styles.textFieldPadding,
                }}
              >
                <TextField
                  fullWidth
                  label="Suggessions(If any)"
                  name={"suggessions"}
                  value={suggessions}
                  onChange={onChangeValue}
                  variant="filled"
                  className="dropDownStyle"
                  InputProps={{
                    className: classes.input,
                    classes: { input: classes.input },
                    disableUnderline: true,
                  }}
                ></TextField>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: 20,
                marginBottom: 20,
              }}
            >
              <Button
                style={{
                  color: "white",
                  cursor: "pointer",
                  borderRadius: 5,
                  backgroundColor: "#2c6ddd",
                  width: matches ? "140px" : "150px",
                  height: "50px",
                  outline: "none",
                  paddingLeft: 30,
                  paddingRight: 30,
                }}
                onClick={editRequst}
                variant="contained"
                color="primary"
              >
                Submit
              </Button>
            </div>
          </div>
        ) : (
          undefined
        )}
      </div>

      <Notification
        msg={errorMsg}
        open={openNotification}
        success={successMsg}
      />

      {showConfirmationModel ? (
        <ConfirmationDialog
          dialogMessage={
            "You will not be able to update the request after completing it. Are you sure want to complete it?"
          }
          dialogHeading={"Complete Request"}
          onClickDone={handleSubmit}
          onClickCancel={setShowConfirmationModel}
        />
      ) : (
        undefined
      )}
    </div>
  );
}

export default EditAnesthesiaRequest;
