import React, { useEffect, useState } from "react";
import Flip from "../../../components/Dial/Flip";
import OneValue from "../../../components/Dial/OneValue";
import { roDashboard } from "../../../public/endpoins";
import { getReportsPharmacist } from "./NetworkCallForPharmacist";
import { socketUrl } from "../../../public/endpoins";
import socketIOClient from "socket.io-client";

export default function EDdoctor() {
  // colors
  const [flip, setFlip] = useState(false);
  const [options, setOptions] = useState([]);

  //First Card Hooks
  const [totalPending, setTotalPending] = useState("");
  const [totalPendingColor, setTotalPendingColor] = useState("");
  const [pendingTat, setPendingTat] = useState("");
  const [pendingTATColor, setPendingTATColor] = useState("");
  const [graphPending, setGraphPending] = useState("");
  //3rd Card
  const [thirdTotalPending, set3rdTotalPending] = useState("");
  const [thirdTotalPendingColor, set3rdTotalPendingColor] = useState("");
  const [thirdPendingTat, set3rdPendingTat] = useState("");
  const [thirdPendingTATColor, set3rdPendingTATColor] = useState("");
  const [thirdGraphPending, set3rdGraphPending] = useState("");
  //4th Card
  const [cumulativeOrders, setCumulativeOrders] = useState("");
  const [cumulativeOrdersColor, setCumulativeOrdersColor] = useState("");

  const [flag1Count, setFlag1Count] = useState(0);

  useEffect(() => {
    const dummyData = [
      { value: 100, label: new Date("2021-02-16T12:48:26.948Z") },
      { value: 80, label: new Date("2021-02-16T11:48:26.948Z") },
      { value: 70, label: new Date("2021-02-16T10:48:26.948Z") },
      { value: 60, label: new Date("2021-02-16T09:48:26.948Z") },
      { value: 50, label: new Date("2021-02-16T08:48:26.948Z") },
      { value: 10, label: new Date("2021-02-16T07:48:26.948Z") },
    ];
    setOptions(dummyData);
  }, []);

  useEffect(() => {
    getReportsPharmacist(
      setTotalPending,
      setTotalPendingColor,
      setPendingTat,
      setPendingTATColor,
      setGraphPending,
      set3rdTotalPending,
      set3rdTotalPendingColor,
      set3rdPendingTat,
      set3rdPendingTATColor,
      set3rdGraphPending,
      setCumulativeOrders,
      setCumulativeOrdersColor
    );

    const flagCount = [
      { value: "1st", setFlagCount: (value) => setFlag1Count(value) },
    ];

    const socket = socketIOClient(socketUrl);
    socket.emit("clinicalPharm_flags");
    socket.on("cpPending", (data) => {
      console.log(
        "response coming through socket for clinical pharmacist Flags",
        data
      );
      flagCount.forEach(({ value, setFlagCount }) => {
        const cardCount = data.filter((item) => item.card === value).length;
        cardCount && setFlagCount(cardCount);
      });

      console.log("data", data);
    });
  }, []);

  const flipIt = () => {
    setFlip(true);
  };

  return (
    <div className="container-fluid" style={{ marginBottom: 10 }}>
      <div className="row">
        <div
          className="col-md-4 col-6"
          style={{ paddingLeft: 4, paddingRight: 4, marginTop: 10 }}
        >
          <div>
            <Flip
              flipped={!flip}
              flipHandler={flipIt}
              mainHeading={
                "Orders Pending(In-Patient, Out-Patient, Discharge/Disposition Patient)"
              }
              value={
                totalPending === 0 || totalPending === null ? "0" : totalPending
              }
              flagsCount={flag1Count}
              mainHeadingForGraph="Orders Pending(In-Patient, Out-Patient, Discharge/Disposition Patient)"
              color={totalPendingColor}
              subHeading={"TAT"}
              childHeading={"Order Receipt To Completion"}
              time={pendingTat === null || pendingTat === 0 ? "0" : pendingTat}
              idForGraph={"container1"}
              data={graphPending}
              timecolor={pendingTATColor}
            />
          </div>
        </div>

        <div
          className="col-md-4 col-6"
          style={{ paddingLeft: 4, paddingRight: 4, marginTop: 10 }}
        >
          <div>
            <Flip
              flipped={!flip}
              flipHandler={flipIt}
              mainHeading={"Replenishment Requests to Warehouse Pending"}
              value={40}
              mainHeadingForGraph="Replenishment Requests to Warehouse Pending"
              color="#13D59F"
              subHeading={"TAT"}
              childHeading={"Replenishment Requests sent to Completion"}
              time={"55"}
              idForGraph={"container2"}
              data={options}
            />
          </div>
        </div>

        <div
          className="col-md-4 col-6"
          style={{ paddingLeft: 4, paddingRight: 4, marginTop: 10 }}
        >
          <div>
            <Flip
              flipped={!flip}
              flipHandler={flipIt}
              mainHeading={"Average No. of Orders/Hour"}
              value={
                thirdTotalPending === 0 || thirdTotalPending === null
                  ? "0"
                  : thirdTotalPending
              }
              mainHeadingForGraph="Average No. of Orders/Hour"
              color={thirdTotalPendingColor}
              subHeading={"TAT"}
              childHeading={"Per Order"}
              time={
                thirdPendingTat === null || thirdPendingTat === 0
                  ? "0"
                  : thirdPendingTat
              }
              idForGraph={"container3"}
              data={thirdGraphPending}
              timecolor={thirdPendingTATColor}
            />
          </div>
        </div>

        <div
          className="col-md-4 col-6"
          style={{ paddingLeft: 4, paddingRight: 4, marginTop: 10 }}
        >
          <div>
            <OneValue
              time={
                cumulativeOrders === 0 || cumulativeOrders === null
                  ? "0"
                  : cumulativeOrders
              }
              colorTime={cumulativeOrdersColor}
              heading={"Commulative total number of Orders"}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
