// import React, { useEffect, useState, useReducer } from 'react'
// import { makeStyles, withStyles } from '@material-ui/core/styles'
// import eye_icon from '../../assets/img/Eye.png'
// import Button from '@material-ui/core/Button'
// import TextField from '@material-ui/core/TextField'
// import { jsPDF } from 'jspdf'
// import 'jspdf-autotable'
// import useMediaQuery from '@material-ui/core/useMediaQuery'
// import {
//   getInsuredPatients,
//   uploadsUrl,
//   audioURL,
//   getBillSummaryUrl,
//   addClaim,
//   updateClaim,
// } from '../../public/endpoins'
// import axios from 'axios'
// import Notification from '../../components/Snackbar/Notification.js'
// import cookie from 'react-cookies'
// import Header from '../../components/Header/Header'
// import Back_Arrow from '../../assets/img/Back_Arrow.png'
// import '../../assets/jss/material-dashboard-react/components/TextInputStyle.css'
// import FormData from 'form-data'
// import claimsReview from '../../assets/img/ClaimsReview.png'
// import logoInvoice from '../../assets/img/logoInvoice.png'
// import logoPatientSummaryInvoice from '../../assets/img/logoPatientSummaryInvoice.png'
// import Table from '@material-ui/core/Table'
// import TableHead from '@material-ui/core/TableHead'
// import TableRow from '@material-ui/core/TableRow'
// import TableBody from '@material-ui/core/TableBody'
// import TableCell from '@material-ui/core/TableCell'
// import InputLabel from '@material-ui/core/InputLabel'
// import Paper from '@material-ui/core/Paper'
// import CustomTable from '../../components/Table/Table'
// import MenuItem from '@material-ui/core/MenuItem'
// import Loader from 'react-loader-spinner'
// import AccountCircle from '@material-ui/icons/SearchOutlined'
// import InputAdornment from '@material-ui/core/InputAdornment'
// import BarCode from '../../assets/img/Bar Code.png'
// import Fingerprint from '../../assets/img/fingerprint.png'
// import ReactHTMLTableToExcel from 'react-html-table-to-excel'
// import { before, last, reject } from 'lodash'
// import PropTypes from 'prop-types'
// import Checkbox from '@material-ui/core/Checkbox'
// import tableStyles from '../../assets/jss/material-dashboard-react/components/tableStyle'
// import print from '../../assets/img/print.png'
// import Tooltip from '@material-ui/core/Tooltip'

// const tableHeadingForCodeBlueTeam = [
//   'MRN No',
//   'Patient Name',
//   'Production Area',
//   'Room No',
//   'Action',
// ]

// const tableDataForCodeBlueTeam = [
//   { mrn: '43243', name: 'Umair', productionArea: 'Kidney', roomNo: '21' },
//   { mrn: '55555', name: 'Ahmad', productionArea: 'Heart', roomNo: '11' },
// ]

// EnhancedTableHead.propTypes = {
//   classes: PropTypes.object.isRequired,
//   numSelected: PropTypes.number.isRequired,
//   onRequestSort: PropTypes.func.isRequired,
//   onSelectAllClick: PropTypes.func.isRequired,
//   order: PropTypes.oneOf(['asc', 'desc']).isRequired,
//   orderBy: PropTypes.string.isRequired,
//   rowCount: PropTypes.number.isRequired,
// }

// const styles = {
//   stylesForButton: {
//     color: 'white',
//     cursor: 'pointer',
//     borderRadius: 5,
//     backgroundColor: '#2c6ddd',
//     width: '130px',
//     height: '45px',
//     outline: 'none',
//   },
//   save: {
//     color: 'white',
//     cursor: 'pointer',
//     borderRadius: 5,
//     backgroundColor: '#ba55d3',
//     width: '130px',
//     height: '45px',
//     outline: 'none',
//   },
//   upload: {
//     backgroundColor: 'white',
//     border: '0px solid #ccc',
//     borderRadius: '5px',
//     color: 'gray',
//     width: '100%',
//     height: '48px',
//     cursor: 'pointer',
//     textAlign: 'center',
//     padding: '10px',
//   },
//   textFieldPadding: {
//     paddingLeft: 5,
//     paddingRight: 5,
//   },
//   inputContainerForTextField: {
//     marginTop: 10,
//   },
//   headingStyles: {
//     fontWeight: 'bold',
//     color: 'grey',
//     fontSize: 12,
//   },
//   textStyles: {
//     fontWeight: '700',
//     color: 'black',
//     fontSize: 14,
//   },
//   selectedDoc: {
//     backgroundColor: 'azure',
//     padding: '5px',
//     borderRadius: '5px',
//   },
// }

// const useStylesForTabs = makeStyles({
//   root: {
//     justifyContent: 'center',
//   },
//   scroller: {
//     flexGrow: '0',
//   },
// })
// const useStyles1 = makeStyles(tableStyles)
// const useStyles = makeStyles((theme) => ({
//   margin: {
//     margin: theme.spacing(0),
//   },
//   input: {
//     backgroundColor: 'white',
//     borderRadius: 3,
//     '&:after': {
//       borderBottomColor: 'black',
//     },
//     '&:hover': {
//       backgroundColor: 'white',
//     },
//     '&:disabled': {
//       color: 'gray',
//     },
//     '&:focus': {
//       backgroundColor: 'white',
//       boxShadow: 'none',
//     },
//   },
//   root: {
//     '& .MuiTextField-root': {
//       backgroundColor: 'white',
//       borderRadius: 3,
//     },
//     '& .Mui-focused': {
//       backgroundColor: 'white',
//       color: 'black',
//     },
//     '& .Mui-selected': {
//       backgroundColor: '#CCCCCC',
//       '&:hover': {
//         backgroundColor: '#CCCCCC',
//       },
//     },
//     '& .MuiFormLabel-root': {
//       fontSize: '11px',
//       paddingRight: '50px',
//     },
//   },
//   label: {
//     '&$focusedLabel': {
//       color: 'red',
//       display: 'none',
//     },
//   },
// }))

// const StyledTableRow = withStyles((theme) => ({
//   root: {
//     '&:nth-of-type(odd)': {
//       backgroundColor: '#f4f4f4',
//     },
//     '&:nth-of-type(even)': {
//       backgroundColor: '#FFFFFF',
//     },
//   },
// }))(TableRow)

// function EnhancedTableHead(props) {
//   const classes = useStyles1()
//   const { tableHeaderColor } = props
//   const { onSelectAllClick, numSelected, rowCount } = props

//   return (
//     <TableHead
//       className={classes[tableHeaderColor + 'TableHeader']}
//       style={{
//         backgroundColor: '#2873cf',
//       }}
//     >
//       <TableRow className={classes.tableHeadRow}>
//         {tableHeadingForCodeBlueTeam.map((headCell, index) => (
//           <TableCell
//             key={headCell}
//             className={classes.tableHeadCell}
//             style={{
//               color: 'white',
//               fontWeight: '700',
//               textAlign: 'center',
//               borderTopLeftRadius: index === 0 ? 5 : 0,
//               borderTopRightRadius:
//                 index === tableHeadingForCodeBlueTeam.length - 1 ? 5 : 0,
//             }}
//           >
//             {headCell}
//           </TableCell>
//         ))}
//       </TableRow>
//     </TableHead>
//   )
// }

// function AddEditPatientListing(props) {
//   const matches = useMediaQuery('(min-width:600px)')
//   const classes = useStyles()
//   const classes1 = useStyles1()

//   // const initialState = {
//   //   treatmentDetail: '',
//   // }

//   // function reducer(state, { field, value }) {
//   //   return {
//   //     ...state,
//   //     [field]: value,
//   //   }
//   // }

//   // const [state, dispatch] = useReducer(reducer, initialState)

//   // const {
//   //   treatmentDetail,
//   // } = state

//   const classesForTabs = useStylesForTabs()

//   const [comingFor, setcomingFor] = useState('')
//   const [isFormSubmitted, setIsFormSubmitted] = useState(false)
//   const [errorMsg, setErrorMsg] = useState('')
//   const [successMsg, setsuccessMsg] = useState('')
//   const [openNotification, setOpenNotification] = useState(false)
//   const [value, setValue] = React.useState(0)
//   const [searchQuery, setSearchQuery] = useState('')
//   const [itemFound, setItemFound] = useState('')
//   const [itemFoundSuccessfull, setItemFoundSuccessfully] = useState(false)
//   const [codeBlueTeamArray, setCodeBlueTeamArray] = useState(
//     tableDataForCodeBlueTeam
//   )
//   const [searchPatientQuery, setSearchPatientQuery] = useState('')
//   const [searched, setsearched] = useState(false)
//   const [selected, setSelected] = React.useState([])
//   const [timer, setTimer] = useState(null)
//   const [loadSearchedData, setLoadSearchedData] = useState(false)

//   useEffect(() => {}, [])

//   const onClick = () => {
//     setValue(value + 1)
//   }

//   if (openNotification) {
//     setTimeout(() => {
//       setOpenNotification(false)
//       setErrorMsg('')
//       setsuccessMsg('')
//     }, 2000)
//   }

//   // const handleKeyDown = (e) => {
//   //   if (e.keyCode === 13) {
//   //     triggerChange()
//   //   }
//   // }

//   // const triggerChange = (a) => {
//   //   handleSearch(a)
//   // }

//   // const handlePauseSearch = (e) => {
//   //   setLoadSearchedData(true)
//   //   clearTimeout(timer)

//   //   const a = e.target.value.replace(/[^\w\s]/gi, '')
//   //   setSearchQuery(a)

//   //   setTimer(
//   //     setTimeout(() => {
//   //       triggerChange(a)
//   //     }, 600)
//   //   )
//   // }

//   const handlePatientSearch = (e) => {
//     const a = e.target.value.replace(/[^\w\s]/gi, '')
//     setSearchPatientQuery(a)
//     if (a.length >= 3) {
//       // searchEdrPatientsForCC(
//       //   a,
//       //   setlabInPatient,
//       //   setErrorMsg,
//       //   setOpenNotification
//       // )
//     }
//     // else if (a.length == 0) {
//     //   getPatientData()
//     // }
//   }

//   const handleClick = (event, name) => {
//     const selectedIndex = selected.indexOf(name)
//     let newSelected = []

//     if (selectedIndex === -1) {
//       newSelected = newSelected.concat(selected, name)
//     } else if (selectedIndex === 0) {
//       newSelected = newSelected.concat(selected.slice(1))
//     } else if (selectedIndex === selected.length - 1) {
//       newSelected = newSelected.concat(selected.slice(0, -1))
//     } else if (selectedIndex > 0) {
//       newSelected = newSelected.concat(
//         selected.slice(0, selectedIndex),
//         selected.slice(selectedIndex + 1)
//       )
//     }
//     setSelected(newSelected)
//   }

//   console.log('selcted', selected)

//   const isSelected = (name) => selected.indexOf(name) !== -1

//   const handleChange = () => {
//     props.history.push({
//       pathname: 'codeblueteam/viewallrequests',
//       state: {},
//     })
//   }

//   return (
//     <div
//       style={{
//         backgroundColor: 'rgb(19 213 159)',
//         position: 'fixed',
//         display: 'flex',
//         width: '100%',
//         height: '100%',
//         flexDirection: 'column',
//         flex: 1,
//         overflowY: 'scroll',
//       }}
//     >
//       <Header history={props.history} />
//       <div className='cPadding'>
//         <div className='subheader'>
//           <div>
//             <h4>Call Code Blue Team</h4>
//           </div>

//           <Button
//             onClick={handleChange}
//             // style={styles.stylesForButton}
//             variant='contained'
//             color='primary'
//           >
//             <img src={eye_icon} className='icon-view' />
//             &nbsp;&nbsp;
//             <strong style={{ fontSize: matches ? ' ' : 10 }}>
//               View All Requests
//             </strong>
//           </Button>
//         </div>

//         <div style={{ height: '15px' }} />

//         <div
//           className={`${'container-fluid'} ${classes.root}`}
//           style={{
//             marginTop: '25px',
//             paddingLeft: '10px',
//             paddingRight: '10px',
//           }}
//         >
//           <div className='row'>
//             <div
//               className='col-md-10 col-sm-9 col-8'
//               style={styles.textFieldPadding}
//             >
//               <TextField
//                 className='textInputStyle'
//                 id='searchPatientQuery'
//                 type='text'
//                 variant='filled'
//                 label='Search Patient by Name / MRN / National ID / Mobile Number'
//                 name={'searchPatientQuery'}
//                 value={searchPatientQuery}
//                 onChange={handlePatientSearch}
//                 InputLabelProps={{
//                   classes: {
//                     root: classes.label,
//                     focused: classes.focusedLabel,
//                     error: classes.erroredLabel,
//                   },
//                 }}
//                 InputProps={{
//                   endAdornment: (
//                     <InputAdornment position='end'>
//                       <AccountCircle />
//                     </InputAdornment>
//                   ),
//                   className: classes.input,
//                   classes: { input: classes.input },
//                   disableUnderline: true,
//                 }}
//               />
//             </div>

//             <div
//               className='col-md-1 col-sm-2 col-2'
//               style={{
//                 ...styles.textFieldPadding,
//                 paddingLeft: matches ? 5 : 0,
//                 paddingRight: matches ? 5 : 3,
//               }}
//             >
//               <div
//                 style={{
//                   display: 'flex',
//                   justifyContent: 'center',
//                   alignItems: 'center',
//                   backgroundColor: 'white',
//                   borderRadius: 5,
//                   height: 55,
//                 }}
//               >
//                 <img
//                   src={BarCode}
//                   // onClick={scanQRCode}
//                   style={{ width: 70, height: 60 }}
//                 />
//               </div>
//             </div>

//             <div
//               className='col-md-1 col-sm-1 col-2'
//               style={{
//                 ...styles.textFieldPadding,
//                 paddingLeft: matches ? 5 : 2,
//               }}
//             >
//               <div
//                 style={{
//                   display: 'flex',
//                   justifyContent: 'center',
//                   alignItems: 'center',
//                   backgroundColor: 'white',
//                   borderRadius: 5,
//                   height: 55,
//                 }}
//               >
//                 <img src={Fingerprint} style={{ maxWidth: 43, height: 43 }} />
//               </div>
//             </div>
//           </div>
//         </div>

//         <div className='container-fluid hide'>
//           <div
//             className='row hide'
//             style={{
//               marginTop: '20px',
//               flex: 4,
//               display: 'flex',
//               flexDirection: 'column',
//               overflowX: 'scroll',
//             }}
//           >
//             {codeBlueTeamArray !== 0 ? (
//               <Table>
//                 <EnhancedTableHead
//                   style={{
//                     backgroundColor: '#2873cf',
//                   }}
//                   numSelected={selected.length}
//                 />
//                 <TableBody className={classes.root}>
//                   {codeBlueTeamArray &&
//                     codeBlueTeamArray.map((row, index) => {
//                       const isItemSelected = isSelected(row)
//                       const labelId = `enhanced-table-checkbox-${index}`

//                       return (
//                         <StyledTableRow
//                           onClick={(event) => handleClick(event, row)}
//                           role='checkbox'
//                           aria-checked={isItemSelected}
//                           tabIndex={-1}
//                           key={row}
//                           selected={isItemSelected}
//                         >
//                           <TableCell align='center'>{row.mrn}</TableCell>
//                           <TableCell align='center'>{row.name}</TableCell>
//                           <TableCell align='center'>
//                             {row.productionArea}
//                           </TableCell>
//                           <TableCell align='center'>{row.roomNo}</TableCell>
//                           <TableCell padding='checkbox'>
//                             <Checkbox
//                               checked={isItemSelected}
//                               inputProps={{ 'aria-labelledby': labelId }}
//                               style={{ color: '#2873CF' }}
//                             />
//                           </TableCell>
//                         </StyledTableRow>
//                       )
//                     })}
//                 </TableBody>
//               </Table>
//             ) : (
//               undefined
//             )}
//           </div>

//           <div
//             class='row'
//             style={{
//               display: 'flex',
//               flex: 1,
//               justifyContent: 'center',
//               marginTop: '2%',
//               marginBottom: '2%',
//             }}
//           >
//             <img
//               onClick={() => props.history.goBack()}
//               src={Back_Arrow}
//               style={{ width: 45, height: 35, cursor: 'pointer' }}
//             />
//             <div
//               style={{
//                 display: 'flex',
//                 flex: 1,
//                 justifyContent: 'flex-end',
//               }}
//             >
//               <Button
//                 style={styles.stylesForButton}
//                 disabled={!searched}
//                 onClick={onClick}
//                 variant='contained'
//                 color='primary'
//               >
//                 Submit
//               </Button>
//             </div>
//           </div>
//         </div>

//         <Notification
//           msg={errorMsg}
//           open={openNotification}
//           success={successMsg}
//         />
//       </div>
//     </div>
//   )
// }
// CustomTable.defaultProps = {
//   tableHeaderColor: 'gray',
// }
// CustomTable.propTypes = {
//   tableHeaderColor: PropTypes.oneOf([
//     'warning',
//     'primary',
//     'danger',
//     'success',
//     'info',
//     'rose',
//     'gray',
//   ]),
// }
// export default AddEditPatientListing
