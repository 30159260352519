/*eslint-disable*/
import React, { useState, useEffect } from 'react'
import Button from '@material-ui/core/Button'
import Notification from '../../components/Snackbar/Notification.js'
import CustomTable from '../../components/Table/Table'
import Loader from 'react-loader-spinner'
import Header from '../../components/Header/Header'
import PatientDetailsIcon from '../../assets/img/PatientDetails.png'
import eye_icon from '../../assets/img/Eye.png'
import Back from '../../assets/img/Back_Arrow.png'
import '../../assets/jss/material-dashboard-react/components/loaderStyle.css'
import cookie from 'react-cookies'
import { styles } from './styles.js'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import {
  getPendingLabReports,
  getCompletedLabReports,
  getPendingLabReportsForED,
  getCompletedLabReportsForED,
} from './networkCallsForTasksList'

const tableHeading = [
  'MRN',
  'Patient Name',
  'Test Name',
  'Production Area',
  'Bed Cell No',
  'Status',
  'Action',
]
const tableDataKeys = [
  'mrn',
  'patientName',
  'testName',
  'prodArea',
  'bedCell',
  'status',
]

const actionsForPending = { edit: true }
const actionsForCompleted = { assigned: true }

export default function TasksList(props) {
  const matches = useMediaQuery('(min-width:600px)')

  const [currentUser] = useState(cookie.load('current_user'))
  const [errorMsg, setErrorMsg] = useState('')
  const [openNotification, setOpenNotification] = useState(false)
  const [statusName, setStatusName] = useState('Pending')
  const [pendingTasks, setPendingTasks] = useState([])
  const [completedTasks, setCompletedTasks] = useState([])

  if (openNotification) {
    setTimeout(() => {
      setOpenNotification(false)
      setErrorMsg('')
    }, 2000)
  }

  useEffect(() => {
    if (
      props.history.location.pathname ===
      '/dashboard/home/radiologyTasksCompleted'
    ) {
      setStatusName('Completed')
    }
    if (currentUser.staffType === 'Doctor') {
      getPendingLabReportsForED(
        currentUser._id,
        setPendingTasks,
        setOpenNotification,
        setErrorMsg
      )
      getCompletedLabReportsForED(
        currentUser._id,
        setCompletedTasks,
        setOpenNotification,
        setErrorMsg
      )
    } else {
      getPendingLabReports(currentUser._id, setPendingTasks, setOpenNotification, setErrorMsg)
      getCompletedLabReports(
        currentUser._id,
        setCompletedTasks,
        setOpenNotification,
        setErrorMsg
      )
    }
  }, [])

  const handleChange = (e, tabValue) => {
    setStatusName(tabValue)
    if(tabValue === 'Completed') {
      getCompletedLabReports(
        currentUser._id,
        setCompletedTasks,
        setOpenNotification,
        setErrorMsg
      )
    }
    else {
      getPendingLabReports(currentUser._id, setPendingTasks, setOpenNotification, setErrorMsg)
    }
  }

  console.log('completedTasks::', completedTasks)

  const handleEdit = (item) => {
    props.history.push({
      pathname: 'radiologyReports',
      state: {
        selectedItem: item,
        comingFor: 'edit',
      },
    })
  }

  const handleView = (item) => {
    props.history.push({
      pathname: 'radiologyReports',
      state: {
        selectedItem: item,
        comingFor: 'view',
      },
    })
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        position: 'fixed',
        width: '100%',
        height: '100%',
        backgroundColor: '#60d69f',
        overflowY: 'scroll',
      }}
    >
      <Header history={props.history} />

      <div className='cPadding'>
        <div
          className='subheader'
          style={{ paddingRight: '10px', marginLeft: '-5px' }}
        >
          <div>
            <img src={PatientDetailsIcon} />
            <h4>Patient Detail List</h4>
          </div>

          {statusName === 'Pending' ? (
            <Button
              onClick={(e) => handleChange(e, 'Completed')}
              style={styles.stylesForButton}
              variant='contained'
              color='primary'
            >
              <img src={eye_icon} className='icon-view' />
              &nbsp;&nbsp;
              <strong style={{ fontSize: matches ? ' ' : 10 }}>
                Completed Tasks
              </strong>
            </Button>
          ) : (
            <Button
              onClick={(e) => handleChange(e, 'Pending')}
              style={styles.stylesForButton}
              variant='contained'
              color='primary'
            >
              <img src={eye_icon} className='icon-view' />
              &nbsp;&nbsp;
              <strong style={{ fontSize: matches ? ' ' : 10 }}>
                Pending Tasks
              </strong>
            </Button>
          )}
        </div>

        <div className='container-fluid'>
          {statusName === 'Pending' && pendingTasks.length > 0 ? (
            <div className='row'>
              <div className='col-md-12 col-sm-12 col-12'>
                <CustomTable
                  tableData={pendingTasks}
                  tableDataKeys={tableDataKeys}
                  tableHeading={tableHeading}
                  action={actionsForPending}
                  handleEdit={handleEdit}
                  borderBottomColor={'#60d69f'}
                  borderBottomWidth={20}
                />
              </div>
            </div>
          ) : statusName === 'Completed' && completedTasks.length > 0 ? (
            <div className='row'>
              <div className='col-md-12 col-sm-12 col-12'>
                <CustomTable
                  tableData={completedTasks}
                  tableDataKeys={tableDataKeys}
                  tableHeading={tableHeading}
                  action={actionsForCompleted}
                  handleView={handleView}
                  borderBottomColor={'#60d69f'}
                  borderBottomWidth={20}
                />
              </div>
            </div>
          ) : pendingTasks.length === 0 || completedTasks.length === 0 ? (
            <div className='row' style={{ marginTop: '25px' }}>
              <div className='col-md-12 col-sm-12 col-12'>
                <h3
                  style={{
                    color: 'white',
                    textAlign: 'center',
                  }}
                >
                  Opps! No Data Found
                </h3>
              </div>
            </div>
          ) : (
            <div className='LoaderStyle'>
              <Loader type='TailSpin' color='red' height={50} width={50} />
            </div>
          )}

          <div className='row' style={{ marginBottom: 20 }}>
            <div className='col-md-12 col-sm-12 col-12'>
              <img
                onClick={() => props.history.goBack()}
                src={Back}
                style={{
                  width: 45,
                  height: 35,
                  cursor: 'pointer',
                }}
              />
            </div>
          </div>
        </div>
        <Notification msg={errorMsg} open={openNotification} />
      </div>
    </div>
  )
}
