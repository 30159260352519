import axios from "axios";
import {
  addCommunicationRequest,
  getAllCommunicationRequests,
} from "../../public/endpoins";
import _ from "lodash";

export const addCommunicationRequestCall = (
  props,
  object,
  setOpenNotification,
  setErrorMsg
) => {
  axios
    .post(addCommunicationRequest, object)
    .then((res) => {
      if (res.data.success) {
        console.log("Response Communciation ", res.data);

        props.history.push({
          pathname: "/dashboard/home/success",
          state: {
            code: res.data.data.requestId,
            message2: `has been generated successfully`,
          },
        });
      } else if (!res.data.success) {
        setOpenNotification(true);
      }
    })
    .catch((e) => {
      setOpenNotification(true);
      setErrorMsg("Error while adding Communciation");
    });
};

export const getAllCommunicationRequestsCall = (
  currentUser,
  setCommunicationReqData,
  setOpenNotification,
  setErrorMsg
) => {
  axios
    .get(getAllCommunicationRequests)
    .then((res) => {
      if (res.data.success) {
        const filteredRequests = res.data.data.filter(
          (communication) => communication.generatedBy === currentUser._id
        );
        var sortedObjs = _.sortBy(filteredRequests, "createdAt").reverse();

        // console.log("filteredRequests", filteredRequests)
        // setCommunicationReqData(filteredRequests.reverse())
        setCommunicationReqData(sortedObjs);
      } else if (!res.data.success) {
        setOpenNotification(true);
      }
    })
    .catch((e) => {
      setOpenNotification(true);
      setErrorMsg("Error while getting Communciation");
    });
};
