/*eslint-disable*/
import React, { useState, useEffect, useReducer } from "react";
import Button from "@material-ui/core/Button";
import Notification from "../../components/Snackbar/Notification.js";
import CustomTable from "../../components/Table/Table";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Loader from "react-loader-spinner";
import Header from "../../components/Header/Header";
import historyIcon from "../../assets/img/ED RoomsHistory.png";
import FilterIcon from "../../assets/img/Filter.png";
import Back from "../../assets/img/Back_Arrow.png";
import "../../assets/jss/material-dashboard-react/components/loaderStyle.css";
import cookie from "react-cookies";
import moment from "moment";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { styles, useStylesForTabs, useStylesForInput } from "./styles.js";
import {
  tableHeadingForCC,
  tableDataKeysForCC,
  tableHeadingForCareStreams,
  tableDataKeysForCareStreams,
  tableHeadingForTimeInterval,
  tableDataKeysForTimeInterval,
  tableHeadingForTransferToEOU,
  tableDataKeysForTransferToEOU,
  tableHeadingForDischargedPatients,
  tableDataKeysForDischargedPatients,
  tableHeadingForLabTests,
  tableDataKeysForLabTests,
  tableHeadingForDeathsOccured,
  tableDataKeysForDeathsOccured,
  tableHeadingForCriticalFunctions,
  tableDataKeysForCriticalFunctions,
  tableHeadingForCareStreamTimeInterval,
  tableDataKeysForCareStreamTimeInterval,
} from "./historyTables";
import { getRoomHistoryTableData } from "./networkCallForEDMgmt";

export default function History(props) {
  const classesForTabs = useStylesForTabs();
  const classes = useStylesForInput();

  const initialState = {
    // startDate: new Date(),
    // endDate: new Date(),
    // startTime: new Date(),
    // endTime: new Date()

    startDate: null,
    endDate: null,
    startTime: null,
    endTime: null,
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  function reducer(state, { field, value }) {
    return {
      ...state,
      [field]: value,
    };
  }

  const { startDate, endDate, startTime, endTime } = state;

  const [currentUser] = useState(cookie.load("current_user"));
  const [errorMsg, setErrorMsg] = useState("");
  const [openNotification, setOpenNotification] = useState(false);
  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [tabName, setTabName] = useState("Chief Complaint");
  const [tableData, setTableData] = useState([]);
  const [isLoading, setisLoading] = useState(true);
  const [noOfPatients, setNoOfPatients] = useState(0);
  const [filteredTimeIntervalData, setfilteredTimeIntervalData] = useState([]);

  if (openNotification) {
    setTimeout(() => {
      setOpenNotification(false);
      setErrorMsg("");
    }, 2000);
  }

  useEffect(() => {
    setTableData([]);
    setisLoading(true);
    getRoomHistoryTableData(
      tabName,
      setTableData,
      setisLoading,
      setNoOfPatients,
      setOpenNotification,
      setErrorMsg
    );
  }, [tabName]);

  const handleChange = (event, tabValue) => {
    setTabName(tabValue);
  };

  const handleDateChange = (e, name) => {
    dispatch({ field: name, value: e });
  };

  const handleSelectTimeInterval = () => {
    setOpenFilterModal(true);
  };

  useEffect(() => {
    setFilterData(tableData);
  }, [tableData]);

  function setFilterData(filterdData) {
    setfilteredTimeIntervalData([...filterdData]);
  }

  function getTime(dateTime) {
    return moment({ h: dateTime.hours(), m: dateTime.minutes() });
  }

  const handleFilteredTimeInterval = () => {
    let filteredData = [...tableData];

    if (startDate) {
      let startDateFilter = moment(startDate);
      filteredData = filteredData.filter((date) =>
        moment(date.createdTimeStamp).isSameOrAfter(startDateFilter, "day")
      );
      console.log("startDate Parsed : ", startDateFilter);
    }
    if (endDate) {
      let endDateFilter = moment(endDate);
      filteredData = filteredData.filter((date) =>
        moment(date.createdTimeStamp).isSameOrBefore(endDateFilter, "day")
      );
      console.log("endDate Parsed : ", endDateFilter);
    }
    if (startTime && endTime) {
      const startTimeFiltered = getTime(moment(startTime));
      const endTimeFiltered = getTime(moment(endTime));

      filteredData = filteredData.filter((date) =>
        getTime(moment(date.createdTimeStamp)).isBetween(
          startTimeFiltered,
          endTimeFiltered,
          "minutes",
          "[]"
        )
      );
    } else {
      setErrorMsg("Please select start and end Time.");
      setOpenNotification(true);
    }
    setFilterData(filteredData);
    setOpenFilterModal(false);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        position: "fixed",
        width: "100%",
        height: "100%",
        backgroundColor: "#60d69f",
        overflowY: "scroll",
      }}
    >
      <Header history={props.history} />

      <div className="cPadding">
        <div className="subheader" style={{ paddingRight: "15px" }}>
          <div>
            <img src={historyIcon} />
            <h4>ED Rooms History</h4>
          </div>
          {tabName === "Time Interval" ? (
            <Button
              onClick={handleSelectTimeInterval}
              style={styles.stylesForButton}
              variant="contained"
              color="primary"
            >
              <img src={FilterIcon} className="icon-view" />
              &nbsp;&nbsp;Select Time Interval
            </Button>
          ) : tabName === "Transfer to EOU" ? (
            <div style={{ color: "white" }}>
              <h4>{noOfPatients} Patients</h4>
            </div>
          ) : tabName === "Deaths Occured" ? (
            <div style={{ color: "white" }}>
              <h4>{noOfPatients} Deaths</h4>
            </div>
          ) : (
            undefined
          )}
        </div>

        <div style={{ height: "20px" }} />

        <div className={classesForTabs.root}>
          <Tabs
            classes={{
              root: classesForTabs.root,
              scroller: classesForTabs.scroller,
            }}
            value={tabName}
            onChange={handleChange}
            textColor="primary"
            variant="scrollable"
            TabIndicatorProps={{ style: { background: "#12387a" } }}
          >
            <Tab
              style={{
                color: "white",
                borderRadius: 5,
                outline: "none",
                color: tabName === "Chief Complaint" ? "#12387a" : "#3B988C",
              }}
              label="Chief Complaint"
              value="Chief Complaint"
            />
            <Tab
              style={{
                color: "white",
                borderRadius: 5,
                outline: "none",
                color: tabName === "CareStream" ? "#12387a" : "#3B988C",
              }}
              label="CareStream"
              value="CareStream"
            />
            <Tab
              style={{
                color: "white",
                borderRadius: 5,
                outline: "none",
                color: tabName === "Time Interval" ? "#12387a" : "#3B988C",
              }}
              label="Time Interval"
              value="Time Interval"
            />

            <Tab
              style={{
                color: "white",
                borderRadius: 5,
                outline: "none",
                color: tabName === "Transfer to EOU" ? "#12387a" : "#3B988C",
              }}
              label="Transfer to EOU"
              value="Transfer to EOU"
            />
            <Tab
              style={{
                color: "white",
                borderRadius: 5,
                outline: "none",
                color:
                  tabName === "Discharged Patients" ? "#12387a" : "#3B988C",
              }}
              label="Discharged Patients"
              value="Discharged Patients"
            />
            <Tab
              style={{
                color: "white",
                borderRadius: 5,
                outline: "none",
                color: tabName === "Lab Tests" ? "#12387a" : "#3B988C",
              }}
              label="Lab Tests"
              value="Lab Tests"
            />
            <Tab
              style={{
                color: "white",
                borderRadius: 5,
                outline: "none",
                color: tabName === "Deaths Occured" ? "#12387a" : "#3B988C",
              }}
              label="Deaths Occured"
              value="Deaths Occured"
            />
            <Tab
              style={{
                color: "white",
                borderRadius: 5,
                outline: "none",
                color: tabName === "Critical Functions" ? "#12387a" : "#3B988C",
              }}
              label="Critical Functions"
              value="Critical Functions"
            />
            <Tab
              style={{
                color: "white",
                borderRadius: 5,
                outline: "none",
                color:
                  tabName === "CareStream Time Interval"
                    ? "#12387a"
                    : "#3B988C",
              }}
              label="CareStream Time Interval"
              value="CareStream Time Interval"
            />
          </Tabs>
        </div>

        <div className="container-fluid">
          {tabName === "Chief Complaint" ? (
            <div className="row">
              {tableData.length > 0 && !isLoading ? (
                <div className="col-md-12 col-sm-12 col-12">
                  <CustomTable
                    tableData={tableData}
                    tableDataKeys={tableDataKeysForCC}
                    tableHeading={tableHeadingForCC}
                    borderBottomColor={"#60d69f"}
                    borderBottomWidth={20}
                  />
                </div>
              ) : isLoading ? (
                <div style={{ margin: "auto", width: "0%", padding: 10 }}>
                  <Loader type="TailSpin" color="blue" height={50} width={50} />
                </div>
              ) : (
                <div className="col-md-12 col-sm-12 col-12">
                  <h3
                    style={{
                      color: "white",
                      textAlign: "center",
                      margin: "revert",
                    }}
                  >
                    Opps! No Data Found
                  </h3>
                </div>
              )}
            </div>
          ) : tabName === "CareStream" ? (
            <div className="row">
              <div className="col-md-12 col-sm-12 col-12">
                <h3
                  style={{
                    color: "white",
                    textAlign: "center",
                    margin: "revert",
                  }}
                >
                  CareStream under development !!
                </h3>
              </div>
              {/* {tableData.length > 0 && !isLoading ? (
                                <div className='col-md-12 col-sm-12 col-12'>
                                    <CustomTable
                                        tableData={tableData}
                                        tableDataKeys={tableDataKeysForCareStreams}
                                        tableHeading={tableHeadingForCareStreams}
                                        borderBottomColor={"#60d69f"}
                                        borderBottomWidth={20}
                                    />
                                </div>
                            ) : isLoading ? (
                                <div style={{ margin: "auto", width: "0%", padding: 10 }}>
                                    <Loader
                                        type="TailSpin"
                                        color="blue"
                                        height={50}
                                        width={50}
                                    />
                                </div>
                            ) : (
                                        <div className='col-md-12 col-sm-12 col-12'>
                                            <h3
                                                style={{
                                                    color: "white",
                                                    textAlign: "center",
                                                    margin: 'revert'
                                                }}
                                            >
                                                Opps! No Data Found
                                            </h3>
                                        </div>
                                    )} */}
            </div>
          ) : tabName === "Transfer to EOU" ? (
            <div className="row">
              {tableData.length > 0 && !isLoading ? (
                <div className="col-md-12 col-sm-12 col-12">
                  <CustomTable
                    tableData={tableData}
                    tableDataKeys={tableDataKeysForTransferToEOU}
                    tableHeading={tableHeadingForTransferToEOU}
                    borderBottomColor={"#60d69f"}
                    borderBottomWidth={20}
                  />
                </div>
              ) : isLoading ? (
                <div style={{ margin: "auto", width: "0%", padding: 10 }}>
                  <Loader type="TailSpin" color="blue" height={50} width={50} />
                </div>
              ) : (
                <div className="col-md-12 col-sm-12 col-12">
                  <h3
                    style={{
                      color: "white",
                      textAlign: "center",
                      margin: "revert",
                    }}
                  >
                    Opps! No Data Found
                  </h3>
                </div>
              )}
            </div>
          ) : tabName === "Time Interval" ? (
            <div className="row">
              {filteredTimeIntervalData.length > 0 && !isLoading ? (
                <div className="col-md-12 col-sm-12 col-12">
                  <CustomTable
                    tableData={filteredTimeIntervalData}
                    tableDataKeys={tableDataKeysForTimeInterval}
                    tableHeading={tableHeadingForTimeInterval}
                    borderBottomColor={"#60d69f"}
                    borderBottomWidth={20}
                  />
                </div>
              ) : isLoading ? (
                <div style={{ margin: "auto", width: "0%", padding: 10 }}>
                  <Loader type="TailSpin" color="blue" height={50} width={50} />
                </div>
              ) : (
                <div className="col-md-12 col-sm-12 col-12">
                  <h3
                    style={{
                      color: "white",
                      textAlign: "center",
                      margin: "revert",
                    }}
                  >
                    Opps! No Data Found
                  </h3>
                </div>
              )}
            </div>
          ) : tabName === "Discharged Patients" ? (
            <div className="row">
              {tableData.length > 0 && !isLoading ? (
                <div className="col-md-12 col-sm-12 col-12">
                  <CustomTable
                    tableData={tableData}
                    tableDataKeys={tableDataKeysForDischargedPatients}
                    tableHeading={tableHeadingForDischargedPatients}
                    borderBottomColor={"#60d69f"}
                    borderBottomWidth={20}
                  />
                </div>
              ) : isLoading ? (
                <div style={{ margin: "auto", width: "0%", padding: 10 }}>
                  <Loader type="TailSpin" color="blue" height={50} width={50} />
                </div>
              ) : (
                <div className="col-md-12 col-sm-12 col-12">
                  <h3
                    style={{
                      color: "white",
                      textAlign: "center",
                      margin: "revert",
                    }}
                  >
                    Opps! No Data Found
                  </h3>
                </div>
              )}
            </div>
          ) : tabName === "Lab Tests" ? (
            <div className="row">
              {tableData.length > 0 && !isLoading ? (
                <div className="col-md-12 col-sm-12 col-12">
                  <CustomTable
                    tableData={tableData}
                    tableDataKeys={tableDataKeysForLabTests}
                    tableHeading={tableHeadingForLabTests}
                    borderBottomColor={"#60d69f"}
                    borderBottomWidth={20}
                  />
                </div>
              ) : isLoading ? (
                <div style={{ margin: "auto", width: "0%", padding: 10 }}>
                  <Loader type="TailSpin" color="blue" height={50} width={50} />
                </div>
              ) : (
                <div className="col-md-12 col-sm-12 col-12">
                  <h3
                    style={{
                      color: "white",
                      textAlign: "center",
                      margin: "revert",
                    }}
                  >
                    Opps! No Data Found
                  </h3>
                </div>
              )}
            </div>
          ) : tabName === "Deaths Occured" ? (
            <div className="row">
              {tableData.length > 0 && !isLoading ? (
                <div className="col-md-12 col-sm-12 col-12">
                  <CustomTable
                    tableData={tableData}
                    tableDataKeys={tableDataKeysForDeathsOccured}
                    tableHeading={tableHeadingForDeathsOccured}
                    borderBottomColor={"#60d69f"}
                    borderBottomWidth={20}
                  />
                </div>
              ) : isLoading ? (
                <div style={{ margin: "auto", width: "0%", padding: 10 }}>
                  <Loader type="TailSpin" color="blue" height={50} width={50} />
                </div>
              ) : (
                <div className="col-md-12 col-sm-12 col-12">
                  <h3
                    style={{
                      color: "white",
                      textAlign: "center",
                      margin: "revert",
                    }}
                  >
                    Opps! No Data Found
                  </h3>
                </div>
              )}
            </div>
          ) : tabName === "Critical Functions" ? (
            <div className="row">
              <div className="col-md-12 col-sm-12 col-12">
                <h3
                  style={{
                    color: "white",
                    textAlign: "center",
                    margin: "revert",
                  }}
                >
                  Critical Functions under development !!
                </h3>
              </div>
              {/* {tableData.length > 0 && !isLoading ? (
                                <div className='col-md-12 col-sm-12 col-12'>
                                    <CustomTable
                                        tableData={tableData}
                                        tableDataKeys={tableDataKeysForCriticalFunctions}
                                        tableHeading={tableHeadingForCriticalFunctions}
                                        borderBottomColor={"#60d69f"}
                                        borderBottomWidth={20}
                                    />
                                </div>
                            ) : isLoading ? (
                                <div style={{ margin: "auto", width: "0%", padding: 10 }}>
                                    <Loader
                                        type="TailSpin"
                                        color="blue"
                                        height={50}
                                        width={50}
                                    />
                                </div>
                            ) : (
                                        <div className='col-md-12 col-sm-12 col-12'>
                                            <h3
                                                style={{
                                                    color: "white",
                                                    textAlign: "center",
                                                    margin: 'revert'
                                                }}
                                            >
                                                Opps! No Data Found
                                                    </h3>
                                        </div>
                                    )} */}
            </div>
          ) : tabName === "CareStream Time Interval" ? (
            <div className="row">
              <div className="col-md-12 col-sm-12 col-12">
                <h3
                  style={{
                    color: "white",
                    textAlign: "center",
                    margin: "revert",
                  }}
                >
                  CareStream Time Interval under development !!
                </h3>
              </div>
              {/* {tableData.length > 0 && !isLoading ? (
                                <div className='col-md-12 col-sm-12 col-12'>
                                    <CustomTable
                                        tableData={tableData}
                                        tableDataKeys={tableDataKeysForCareStreamTimeInterval}
                                        tableHeading={tableHeadingForCareStreamTimeInterval}
                                        borderBottomColor={"#60d69f"}
                                        borderBottomWidth={20}
                                    />
                                </div>
                            ) : isLoading ? (
                                <div style={{ margin: "auto", width: "0%", padding: 10 }}>
                                    <Loader
                                        type="TailSpin"
                                        color="blue"
                                        height={50}
                                        width={50}
                                    />
                                </div>
                            ) : (
                                        <div className='col-md-12 col-sm-12 col-12'>
                                            <h3
                                                style={{
                                                    color: "white",
                                                    textAlign: "center",
                                                    margin: 'revert'
                                                }}
                                            >
                                                Opps! No Data Found
                                                    </h3>
                                        </div>
                                    )} */}
            </div>
          ) : (
            <div className="LoaderStyle">
              <Loader type="TailSpin" color="red" height={50} width={50} />
            </div>
          )}

          <div className="row" style={{ marginBottom: 20 }}>
            <div className="col-md-12 col-sm-12 col-12">
              <img
                onClick={() => props.history.goBack()}
                src={Back}
                style={{
                  width: 45,
                  height: 35,
                  cursor: "pointer",
                }}
              />
            </div>
          </div>
          <Notification msg={errorMsg} open={openNotification} />
        </div>

        <Dialog
          onClose={() => setOpenFilterModal(false)}
          fullWidth={true}
          maxWidth={"lg"}
          aria-labelledby="simple-dialog-title"
          open={openFilterModal}
        >
          <DialogTitle id="simple-dialog-title">
            <h4>Select Time Interval</h4>
          </DialogTitle>
          <DialogContent>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <div className={`container-fluid ${classes.root}`}>
                <div className="row">
                  <div className="col-md-6 col-sm-6 col-6">
                    <KeyboardDatePicker
                      disableToolbar
                      disableFuture
                      fullWidth
                      id="date-picker-inline"
                      label="Start Date"
                      emptyLabel="MM/dd/yyyy"
                      name={"startDate"}
                      value={startDate}
                      onChange={(e) => handleDateChange(e, "startDate")}
                      variant="inline"
                      format="MM/dd/yyyy"
                      margin="normal"
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                      InputProps={{
                        className: classes.input,
                        classes: { input: classes.input },
                        disableUnderline: true,
                      }}
                    />
                  </div>
                  <div className="col-md-6 col-sm-6 col-6">
                    <KeyboardDatePicker
                      disableFuture
                      disableToolbar
                      fullWidth
                      id="date-picker-inline"
                      label="End Date"
                      emptyLabel="MM/dd/yyyy"
                      name={"endDate"}
                      value={endDate}
                      onChange={(e) => handleDateChange(e, "endDate")}
                      variant="inline"
                      format="MM/dd/yyyy"
                      margin="normal"
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                      InputProps={{
                        className: classes.input,
                        classes: { input: classes.input },
                        disableUnderline: true,
                      }}
                    />
                  </div>
                </div>
                <div className="row" style={{ marginTop: "10px" }}>
                  <div className="col-md-6 col-sm-6 col-6">
                    <KeyboardTimePicker
                      fullWidth
                      id="time-picker"
                      label="Start Time"
                      name={"startTime"}
                      value={startTime}
                      emptyLabel="00:00"
                      onChange={(e) => handleDateChange(e, "startTime")}
                      variant="inline"
                      margin="normal"
                      KeyboardButtonProps={{
                        "aria-label": "change time",
                      }}
                      InputProps={{
                        className: classes.input,
                        classes: { input: classes.input },
                        disableUnderline: true,
                      }}
                    />
                  </div>
                  <div className="col-md-6 col-sm-6 col-6">
                    <KeyboardTimePicker
                      fullWidth
                      id="time-picker"
                      label="End Time"
                      name={"endTime"}
                      value={endTime}
                      emptyLabel="00:00"
                      onChange={(e) => handleDateChange(e, "endTime")}
                      variant="inline"
                      margin="normal"
                      KeyboardButtonProps={{
                        "aria-label": "change time",
                      }}
                      InputProps={{
                        className: classes.input,
                        classes: { input: classes.input },
                        disableUnderline: true,
                      }}
                    />
                  </div>
                </div>
              </div>
            </MuiPickersUtilsProvider>
          </DialogContent>
          <DialogActions className="d-flex justify-content-center mb-2">
            <Button
              className="mr-1"
              style={styles.stylesForCloseButton}
              onClick={() => setOpenFilterModal(false)}
              variant="contained"
            >
              Close
            </Button>
            <Button
              style={styles.stylesForButton}
              onClick={handleFilteredTimeInterval}
              variant="contained"
            >
              Apply Filter
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
}
