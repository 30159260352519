/*eslint-disable*/
import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Notification from "../../components/Snackbar/Notification.js";
import CustomTable from "../../components/Table/Table";
import TransferModal from "../../components/PatientTransferDialog/PatientTransferDialog";
import Loader from "react-loader-spinner";
import Header from "../../components/Header/Header";
import TransferIcon from "../../assets/img/EOU.png";
import eye_icon from "../../assets/img/Eye.png";
import Back from "../../assets/img/Back_Arrow.png";
import "../../assets/jss/material-dashboard-react/components/loaderStyle.css";
import cookie from "react-cookies";
import { styles, useStyles } from "./styles.js";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { getPatientsInDept } from "./networkCallsForPatientTransfer";

const tableHeading = [
  "MRN No",
  "Patient Name",
  "Chief Complaint",
  "Room No",
  "Status",
  "Action",
];

const tableDataKeys = ["mrn", "name", "area", "room", "status"];

const actionsForPending = { edit: true };
const actionsForCompleted = { view: true };

export default function EOUfromED(props) {
  const matches = useMediaQuery("(min-width:600px)");
  const [currentUser] = useState(cookie.load("current_user"));
  const [generalArray, setGeneralArray] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [openNotification, setOpenNotification] = useState(false);
  const [statusName, setStatusName] = useState("Pending");
  const [openEditModal, setOpenEditModal] = useState(false);
  const [selectedPatient, setSelectedPatient] = useState("");
  const [patientsInDept, setPatientsInDept] = useState("");

  if (openNotification) {
    setTimeout(() => {
      setOpenNotification(false);
      setErrorMsg("");
    }, 2000);
  }

  useEffect(() => {
    let currentPlace = "EOU";
    if (
      props.history.location.pathname ===
      "/dashboard/home/patientmanagement/patientTransfers/EOUfromED"
    ) {
      currentPlace = "ED";
    }

    getPatientsInDept(
      setGeneralArray,
      setOpenNotification,
      setErrorMsg,
      currentPlace
    );
  }, []);

  const handleChange = (e, tabValue) => {
    setStatusName(tabValue);

    let path = "EDfromEOU/viewrequests";
    if (
      props.history.location.pathname ===
      "/dashboard/home/patientmanagement/patientTransfers/EOUfromED"
    ) {
      path = "EOUfromED/viewrequests";
    }

    props.history.push({
      pathname: path,
      state: {},
    });
  };

  function handleView(e) {
    console.log("View ", e);
  }

  const handleEdit = (obj) => {
    if (obj !== "") {
      if (obj.room === "N/A") {
        setOpenNotification(true);
        setErrorMsg("Patient has not been assigned a room.");
      } else {
        setOpenEditModal(true);
        setSelectedPatient(obj);
      }
    } else {
      setOpenEditModal(false);
      setSelectedPatient("");
    }
  };

  function handleInitiateTransfer(selected, selectedRoom) {
    console.log("selectedPatient", selectedPatient);

    let path = "EDfromEOU/customercarelist";
    if (
      props.history.location.pathname ===
      "/dashboard/home/patientmanagement/patientTransfers/EOUfromED"
    ) {
      path = "EOUfromED/customercarelist";
    }

    props.history.push({
      pathname: path,
      state: {
        selectedItem: selectedPatient,
        selectedRoom,
      },
    });
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        position: "fixed",
        width: "100%",
        height: "100%",
        backgroundColor: "#60d69f",
        overflowY: "scroll",
      }}
    >
      <Header history={props.history} />

      <div className="cPadding">
        <div className="subheader">
          <div>
            <img src={TransferIcon} style={{ marginLeft: "0px" }} />

            {props.history.location.pathname ===
            "/dashboard/home/patientmanagement/patientTransfers/EDfromEOU" ? (
              <h4>Transfer To ED From EOU</h4>
            ) : (
              <h4>Transfer To EOU From ED</h4>
            )}
          </div>

          {statusName === "Pending" ? (
            <Button
              onClick={(e) => handleChange(e, "Completed")}
              style={styles.stylesForButton}
              variant="contained"
              color="primary"
            >
              <img src={eye_icon} className="icon-view" />
              &nbsp;&nbsp;
              <strong style={{ fontSize: matches ? " " : 10 }}>
                View Transfers
              </strong>
            </Button>
          ) : (
            <Button
              onClick={(e) => handleChange(e, "Pending")}
              style={styles.stylesForButton}
              variant="contained"
              color="primary"
            >
              <img src={eye_icon} className="icon-view" />
              &nbsp;&nbsp;
              <strong style={{ fontSize: matches ? " " : 10 }}>
                Pending Transfers
              </strong>
            </Button>
          )}
        </div>

        <div className="container-fluid">
          <div className="row">
            {generalArray ? (
              <div
                className="col-md-12 col-sm-12 col-12"
                style={{ paddingLeft: 3, paddingRight: 0 }}
              >
                <CustomTable
                  tableData={generalArray}
                  tableDataKeys={tableDataKeys}
                  tableHeading={tableHeading}
                  action={actionsForPending}
                  handleEdit={handleEdit}
                  borderBottomColor={"#60d69f"}
                  borderBottomWidth={20}
                />
              </div>
            ) : (
              <div className="LoaderStyle">
                <Loader type="TailSpin" color="red" height={50} width={50} />
              </div>
            )}
          </div>

          <div className="row" style={{ marginBottom: 20 }}>
            <div className="col-md-12 col-sm-12 col-12">
              <img
                onClick={() => props.history.goBack()}
                src={Back}
                style={{
                  width: 45,
                  height: 35,
                  cursor: "pointer",
                }}
              />
            </div>
          </div>
        </div>

        {openEditModal ? (
          <TransferModal
            handleInitiateTransfer={handleInitiateTransfer}
            selectedPatient={selectedPatient}
            open={openEditModal}
            handleEdit={handleEdit}
            transferType={
              props.history.location.pathname ===
              "/dashboard/home/patientmanagement/patientTransfers/EDfromEOU"
                ? "ED from EOU"
                : "EOU from ED"
            }
          />
        ) : (
          undefined
        )}

        <Notification msg={errorMsg} open={openNotification} />
      </div>
    </div>
  );
}
