import React from "react";
import { Route, Switch } from "react-router-dom";
import NotFound from "../components/NotFound/NotFound";
import UnderDevelopment from "../components/UnderDevelopment/underDevelopment";

import PatientTransferMenu from "../views/Home/PatientTransferMenu/patientTransferMenu";
import TransferToEOUfromED from "../views/PatientTransfers/availablePatientsForTransferTbl";
// import TransferToEDfromEOU from "../views/PatientTransfers/TransferToEDfromEOU/EDfromEOU";
import CustomerCareListForTransfer from "../views/PatientTransfers/customerCareListForTransfer";
import ViewTransferRequests from "../views/PatientTransfers/viewTransferRequests";

class PatientTransfers extends React.PureComponent {
  render() {
    return (
      <Switch>
        <Route
          exact
          path={`${this.props.match.url}`}
          component={PatientTransferMenu}
        />
        <Route
          exact
          path={`${this.props.match.url}/EOUfromED`}
          component={TransferToEOUfromED}
        />

        <Route
          exact
          path={`${this.props.match.url}/EOUfromED/viewrequests`}
          component={ViewTransferRequests}
        />

        <Route
          exact
          path={`${this.props.match.url}/EOUfromED/customercarelist`}
          component={CustomerCareListForTransfer}
        />

        <Route
          exact
          path={`${this.props.match.url}/EDfromEOU`}
          component={TransferToEOUfromED}
        />

        <Route
          exact
          path={`${this.props.match.url}/EDfromEOU/viewrequests`}
          component={ViewTransferRequests}
        />

        <Route
          exact
          path={`${this.props.match.url}/EDfromEOU/customercarelist`}
          component={CustomerCareListForTransfer}
          // component={TransferToEOUfromED}
        />

        <Route path={`${this.props.match.url}/notfound`} component={NotFound} />

        <Route path="*" component={NotFound} />
      </Switch>
    );
  }
}
export default PatientTransfers;
