import axios from "axios";
import {
  getEDRWihtConsultationNote,
  SearchEDRPatients,
  getAllCompletedConsultationNotesURL,
} from "../../public/endpoins";

export const getEdrPatientsForCC = (
  currentUserId,
  setlabInPatient,
  setPendings,
  setErrorMsg,
  setOpenNotification
) => {
  // console.log("ID", currentUserId)
  // 5fedad16ce8b693dbe54db39
  axios
    // when data populates
    // .get(`${getEDRWihtConsultationNote}/${currentUserId}`)
    // following is added for time being
    // following is for testing
    .get(`${getEDRWihtConsultationNote}/${currentUserId}`)

    .then((res) => {
      if (res.data.success) {
        res.data.data.map(
          (d) => (
            (d.ID = d.patientData.patientId.identifier[0].value),
            (d.patientName =
              d.patientData.patientId.name[0].given[0] +
              " " +
              d.patientData.patientId.name[0].family),
            (d.productionArea =
              d.patientData.chiefComplaint[
                d.patientData.chiefComplaint.length - 1
              ].chiefComplaintId.productionArea[0].productionAreaId.paName),
            (d.consultant =
              d.consultationNotes.consultant.name[0].given[0] +
              " " +
              d.consultationNotes.consultant.name[0].family),
            (d.addedBy =
              d.consultationNotes.addedBy.name[0].given[0] +
              " " +
              d.consultationNotes.addedBy.name[0].family),
            (d.status = d.consultationNotes.status)
          )
        );

        // res.data.data.consultationNote.map(
        //   (d) => (
        //     (d.addedBy =
        //       d.addedBy.name[0].given[0] + " " + d.addedBy.name[0].family),
        //     (d.consultant =
        //       d.consultant.name[0].given[0] + " " + d.consultant.name[0].family)
        //   ),
        // )

        console.log(res.data.data, "patient data");
        setlabInPatient(res.data.data.reverse());
        setPendings(res.data.data.reverse());
      } else if (!res.data.success) {
        setErrorMsg(res.data.error);
        setOpenNotification(true);
      }
      return res;
    })
    .catch((e) => {
      console.log("error: ", e);
    });
};

export const getAllCompletedConsultationRequest = (
  currentUserId,
  setlabInPatient,
  setPendings,
  setErrorMsg,
  setOpenNotification,
  setisLoading
) => {
  // console.log("ID", currentUserId)
  // 5fedad16ce8b693dbe54db39
  axios
    // when data populates
    // .get(`${getEDRWihtConsultationNote}/${currentUserId}`)
    // following is added for time being
    // following is for testing
    .get(`${getAllCompletedConsultationNotesURL}/${currentUserId}`)

    .then((res) => {
      if (res.data.success) {
        res.data.data.map(
          (d) => (
            (d.ID = d.patientId.identifier[0].value),
            (d.patientName =
              d.patientId.name[0].given[0] + " " + d.patientId.name[0].family),
            (d.createdAt = d.consultationNote.noteTime)
            // (d.consultant =
            //   d.consultationNote.consultant.name[0].given[0] +
            //   " " +
            //   d.consultationNote.consultant.name[0].family),
            // (d.addedBy =
            //   d.consultationNote.addedBy.name[0].given[0] +
            //   " " +
            //   d.consultationNote.addedBy.name[0].family)
          )
        );

        // res.data.data.consultationNote.map(
        //   (d) => (
        //     (d.addedBy =
        //       d.addedBy.name[0].given[0] + " " + d.addedBy.name[0].family),
        //     (d.consultant =
        //       d.consultant.name[0].given[0] + " " + d.consultant.name[0].family)
        //   ),
        // )

        console.log(res.data.data, "patient data");
        setlabInPatient(res.data.data.reverse());
        setPendings(res.data.data.reverse());
        setisLoading(false);
      } else if (!res.data.success) {
        setErrorMsg(res.data.error);
        setOpenNotification(true);
      }
      return res;
    })
    .catch((e) => {
      console.log("error: ", e);
    });
};

export const searchEdrPatientsForCC = (
  a,
  setlabInPatient,
  setErrorMsg,
  setOpenNotification
) => {
  console.log("A", a);
  axios
    .get(SearchEDRPatients + "/" + a)
    .then((res) => {
      console.log("res", res);
      if (res.data.success) {
        res.data.data.map(
          (d) =>
            (d.patientName =
              d.patientId.name[0].given[0] + " " + d.patientId.name[0].family)
        );
        res.data.data.map((d) => (d.mrn = d.patientId.identifier[0].value));
        res.data.data.map((d) => (d.gender = d.patientId.gender));
        res.data.data.map((d) => (d.age = d.patientId.age));
        res.data.data.map((d) => (d.createdAt = d.patientId.createdAt));
        res.data.data.map((d) => (d.status = d.dcdFormStatus));

        console.log(res.data.data, "patient data");
        setlabInPatient(res.data.data);
      } else if (!res.data.success) {
        setErrorMsg(res.data.error);
        setOpenNotification(true);
      }
    })
    .catch((e) => {
      console.log("error after searching patient request", e);
    });
};
