import React, { useEffect, useState, useReducer } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import tableStyles from "../../assets/jss/material-dashboard-react/components/tableStyle.js";
import axios from "axios";
import TextField from "@material-ui/core/TextField";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import useStylesForInput from "../../assets/jss/material-dashboard-react/inputStyle";
import styles from "./styles";
import TextArea from "../../components/common/TextArea";
import TaskRequestModel from "../../components/TaskRequestModel/TaskRequestModel";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import {
  getEDRFromPatientIdForDischarge,
  updateEdr,
} from "../../public/endpoins";
import cookie from "react-cookies";
import Header from "../../components/Header/Header";
import AssessIcon from "../../assets/img/Assessment & Diagnosis.png";
import EdDoctor from "../../assets/img/Doctor - Discharge.png";
import Back from "../../assets/img/Back_Arrow.png";
import "../../assets/jss/material-dashboard-react/components/TextInputStyle.css";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import CustomTable from "../../components/Table/Table";
import plus_icon from "../../assets/img/Plus.png";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Notification from "../../components/Snackbar/Notification.js";
import Fingerprint from "../../assets/img/fingerprint.png";
import AccountCircle from "@material-ui/icons/SearchOutlined";
import InputAdornment from "@material-ui/core/InputAdornment";
import BarCode from "../../assets/img/Bar Code.png";
import ViewSingleRequest from "../../components/ViewRequest/ViewRequest";
import Loader from "react-loader-spinner";

import PatientDetails from "../../components/PatientDetails/PatientDetailsDirect";

import { connect } from "react-redux";
import {
  funForReducer,
  setPatientDetailsForReducer,
} from "../../actions/Checking";

import { handleSearchPatient } from "./networkCallsForDischarge";

const tableHeadingForDischargeMed = [
  "Request ID",
  "Date/Time",
  "Status",
  "Action",
];
const tableDataKeysForDischargeMed = [
  ["requester", "identificationNumber"],
  "date",
  "status",
];
const actions = { view: true };
const actions1 = { edit: true };

const useStylesForTabs = makeStyles({
  root: {
    justifyContent: "center",
  },
  scroller: {
    flexGrow: "0",
  },
});

function LabRadRequest(props) {
  const matches = useMediaQuery("(min-width:600px)");

  const classesForTabs = useStylesForTabs();
  const classes = useStylesForInput();

  const initialState = {
    EDDoctorNotesArray: "",
    consultationNo: "",
    date: new Date(),
    description: "",
    consultationNotes: "",
    doctorconsultationNotes: "",

    requester: cookie.load("current_user").name,
    speciality: "",
    specialist: "",

    residentNoteArray: "",
    rdescription: "",
    note: "",
    doctor: cookie.load("current_user").name,

    pharmacyRequestArray: "",
    requestType: "",
    section: "",
    code: [],
    patientId: "",
    diagnosisArray: "",
    medicationArray: "",

    price: "",

    dischargeMedArray: "",

    dischargeNotes: "",
    followUpInstruction: "",
  };

  function reducer(state, { field, value }) {
    return {
      ...state,
      [field]: value,
    };
  }

  const [state, dispatch] = useReducer(reducer, initialState);

  const {
    EDDoctorNotesArray,
    date = new Date(),
    description,
    consultationNotes,
    doctorconsultationNotes,

    requester = cookie.load("current_user").name,
    speciality,
    specialist,

    pharmacyRequestArray,

    residentNoteArray,
    rdescription,
    note,
    requestType,
    section,
    code,
    patientId,
    diagnosisArray,
    medicationArray,

    price,

    dischargeMedArray,

    dischargeNotes,
    followUpInstruction,
  } = state;

  useEffect(() => {
    const {edrId, selectedItem} = props.history.location.state;
    console.log('props', props.history.location.state);
    setSelectedItem(selectedItem);
  });

  const onChangeValue = (e) => {
    if (e.target.name === "specialist") {
      dispatch({
        field: e.target.name,
        value: e.target.value,
      });
    } else {
      dispatch({
        field: e.target.name,
        value: e.target.value.replace(/[^\w\s]/gi, ""),
      });
    }
  };

  const [currentUser] = useState(cookie.load("current_user"));
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setsuccessMsg] = useState("");
  const [openNotification, setOpenNotification] = useState(false);

  const [value, setValue] = useState(0);
  const [selectedItem, setSelectedItem] = useState("");
  const [searchPatientQuery, setSearchPatientQuery] = useState("");
  const [patientFoundSuccessfull, setpatientFoundSuccessfully] = useState(
    false
  );
  const [patientFound, setpatientFound] = useState("");
  const [patientDetails, setPatientDetails] = useState("");
  const [, setSelectedPatientArray] = useState([]);
  const [, openPatientDetailsDialog] = useState(false);
  const [enableForm, setenableForm] = useState(true);
  const [openItemDialog, setOpenItemDialog] = useState(false);
  const [openAddConsultDialog, setOpenAddConsultDialog] = useState(false);
  const [openAddResidentDialog, setOpenAddResidentDialog] = useState(false);
  const [item, setItem] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [itemFound, setItemFound] = useState("");
  const [itemFoundSuccessfull, setItemFoundSuccessfully] = useState(false);
  const [isFormSubmitted] = useState(false);
  const [id, setId] = useState("");

  const [, setIsLoading] = useState(true);

  const [timer, setTimer] = useState(null);

  const [loadSearchedData, setLoadSearchedData] = useState(false);

  const [edrCompletionReason, setEdrCompletionReason] = useState("discharged");
  const [edrCompletionRequirement, setEdrCompletionRequirement] = useState(
    "withoutCare"
  );

  const validateForm = () => {
    return (
      edrCompletionReason !== "" &&
      edrCompletionRequirement !== "" &&
      dischargeNotes !== "" &&
      followUpInstruction !== ""
    );
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function viewItem(item) {
    if (item !== "") {
      setOpenItemDialog(true);
      setItem(item);
    } else {
      setOpenItemDialog(false);
      setItem("");
    }
  }

  const onChangeDisposition = (e) => {
    setEdrCompletionReason(e.target.value);
  };

  const onChangeRequirements = (e) => {
    setEdrCompletionRequirement(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      triggerChange();
    }
  };

  const triggerChange = (a) => {
    handlePatientSearch(a);
  };

  const handlePauseSearch = (e) => {
    setLoadSearchedData(true);
    clearTimeout(timer);

    const a = e.target.value.replace(/[^\w\s]/gi, "");
    setSearchPatientQuery(a);

    setTimer(
      setTimeout(() => {
        triggerChange(a);
      }, 600)
    );
  };

  //for search patient
  const handlePatientSearch = (e) => {
    if (e.length >= 3) {
      handleSearchPatient(
        e,
        setpatientFoundSuccessfully,
        setpatientFound,
        setLoadSearchedData
      );
    }
  };

  function handleAddPatient(i) {
    dispatch({ field: "diagnosisArray", value: "" });
    dispatch({ field: "medicationArray", value: "" });
    console.log("selected banda : ", i);
    props.setPatientDetailsForReducer(i);
    setPatientDetails(i);
    getPatientByInfo(i._id);
    openPatientDetailsDialog(true);

    const obj = {
      itemCode: i.itemCode,
    };

    setSelectedPatientArray((pervState) => [...pervState, obj]);
    setSearchPatientQuery("");
  }

  const getPatientByInfo = (id) => {
    console.log("getPatientByInfo::", getEDRFromPatientIdForDischarge + "/" + id)
    axios
      .get(getEDRFromPatientIdForDischarge + "/" + id)
      .then((res) => {
        if (res.data.success) {
          if (res.data.data) {
            console.log("Response after getting EDR data : ", res.data.data);

            setIsLoading(false);
            setSelectedItem(res.data.data);
            setId(res.data.data._id);
            setenableForm(false);

            Object.entries(res.data.data).map(([key, val]) => {
              if (val && typeof val === "object") {
                if (key === "patientId") {
                  dispatch({ field: "patientId", value: val._id });
                  Object.entries(val).map(([key1, val1]) => {
                    dispatch({ field: key1, value: val1 });
                  });
                } else if (key === "dischargeRequest") {
                  Object.entries(val).map(([key1, val1]) => {
                    if (key1 === "dischargeSummary") {
                      dispatch({ field: "dischargeSummary", value: val1 });
                      dispatch({
                        field: "dischargeNotes",
                        value: val1.dischargeNotes,
                      });
                      dispatch({
                        field: "followUpInstruction",
                        value: val1.followUpInstruction,
                      });

                      setEdrCompletionReason(val1.edrCompletionReason);
                      setEdrCompletionRequirement(
                        val1.edrCompletionRequirement
                      );
                    } else if (key1 === "dischargeMedication") {
                      dispatch({ field: "dischargeMedArray", value: [val1] });
                      dispatch({
                        field: "dischargeReportArray",
                        value: val1.medicine ? val1.medicine : "",
                      });
                    }
                  });
                  dispatch({ field: "dischargeRequest", value: val });
                } else if (key === "doctorNotes") {
                  if (val && val.length > 0) {
                    let data = [];
                    val.map((d) => {
                      d.code.map((singleCode) => {
                        let found = data.find((i) => i === singleCode);
                        if (!found) {
                          data.push(singleCode);
                        }
                      });
                    });
                    dispatch({
                      field: "diagnosisArray",
                      value: data,
                    });
                  }
                } else if (key === "pharmacyRequest") {
                  let data = [];
                  val.map((d) => {
                    d.item.map((item) => {
                      let found = data.find((i) => i === item.itemId.name);
                      if (!found) {
                        data.push(item.itemId.name);
                      }
                    });
                  });
                  dispatch({ field: "medicationArray", value: data });
                }
              } else {
                dispatch({ field: key, value: val });
              }
            });
          }
        } else {
          setOpenNotification(true);
          setErrorMsg("EDR/IPR not generated for patient");
        }
      })
      .catch((e) => {
        setOpenNotification(true);
        setErrorMsg(e);
      });
  };

  if (openNotification) {
    setTimeout(() => {
      setOpenNotification(false);
      setErrorMsg("");
      setsuccessMsg("");
    }, 2000);
  }

  const showAlert = () => {
    setErrorMsg("Please Search Patient First ");
    setOpenNotification(true);
  };

  const onClick = () => {
    if (selectedItem) {
      setValue(value + 1);
    } else {
      showAlert();
    }
  };

  function addPharmaRequest() {
    let path = `discharge/addmedication`;
    props.history.push({
      pathname: path,
      state: {
        comingFor: "add",
        selectedItem: selectedItem,
        dischargeMedArray,
        followUpInstruction,
        dischargeNotes,
        edrCompletionReason,
        edrCompletionRequirement,
      },
    });
  }

  return (
    <div
      style={{
        backgroundColor: "rgb(19 213 159)",
        position: "fixed",
        display: "flex",
        width: "100%",
        height: "100%",
        flexDirection: "column",
        flex: 1,
        overflowY: "scroll",
        // overflowX:'hidden'
      }}
    >
      <Header history={props.history} />
      <div className="cPadding">
        <div className="subheader" style={{ marginLeft: "-10px" }}>
          <div>
            <img src={EdDoctor} />
            <h4>Discharge</h4>
          </div>
        </div>

        {props.location.pathname === "/dashboard/home/patientlog/ED/viewED" ? (
          undefined
        ) : (
          <div
            className={`${"container-fluid"} ${classes.root}`}
            style={{
              marginTop: "25px",
              paddingLeft: "10px",
              paddingRight: "10px",
            }}
          >
            <div className="row">
              <div
                className="col-md-10 col-sm-8 col-8"
                style={styles.textFieldPadding}
              >
                <TextField
                  className="textInputStyle"
                  id="searchPatientQuery"
                  type="text"
                  variant="filled"
                  label="Search Patient by Name / MRN / National ID / Mobile Number"
                  name={"searchPatientQuery"}
                  value={searchPatientQuery}
                  onChange={handlePauseSearch}
                  onKeyDown={handleKeyDown}
                  InputLabelProps={{
                    classes: {
                      root: classes.label,
                      focused: classes.focusedLabel,
                      error: classes.erroredLabel,
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <AccountCircle />
                      </InputAdornment>
                    ),
                    className: classes.input,
                    classes: { input: classes.input },
                    disableUnderline: true,
                  }}
                />
              </div>

              <div
                className="col-md-1 col-sm-2 col-2"
                style={{
                  ...styles.textFieldPadding,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "white",
                    borderRadius: 5,
                    height: 55,
                  }}
                >
                  <img src={BarCode} style={{ width: 70, height: 60 }} />
                </div>
              </div>

              <div
                className="col-md-1 col-sm-2 col-2"
                style={{
                  ...styles.textFieldPadding,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "white",
                    borderRadius: 5,
                    height: 55,
                  }}
                >
                  <img src={Fingerprint} style={{ maxWidth: 43, height: 43 }} />
                </div>
              </div>
            </div>

            <div className="row">
              <div
                className="col-md-12 col-sm-12 col-12"
                style={styles.textFieldPadding}
              >
                {searchPatientQuery ? (
                  <div
                    style={{
                      zIndex: 3,
                      position: "absolute",
                      width: "99%",
                      marginTop: 5,
                    }}
                  >
                    <Paper style={{ maxHeight: 300, overflow: "auto" }}>
                      {patientFoundSuccessfull && patientFound !== "" ? (
                        <Table size="small">
                          <TableHead>
                            <TableRow>
                              <TableCell>MRN</TableCell>
                              <TableCell>Patient Name</TableCell>
                              <TableCell>Gender</TableCell>
                              <TableCell>Age</TableCell>
                            </TableRow>
                          </TableHead>

                          <TableBody>
                            {patientFound.map((i) => {
                              return (
                                <TableRow
                                  key={i._id}
                                  onClick={() => handleAddPatient(i)}
                                  style={{ cursor: "pointer" }}
                                >
                                  <TableCell>{i.profileNo}</TableCell>
                                  <TableCell>
                                    {i.firstName + ` ` + i.lastName}
                                  </TableCell>
                                  <TableCell>{i.gender}</TableCell>
                                  <TableCell>{i.age}</TableCell>
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      ) : loadSearchedData ? (
                        <div style={{ textAlign: "center" }}>
                          <Loader
                            type="TailSpin"
                            color="#2c6ddd"
                            height={25}
                            width={25}
                            style={{ display: "inline-block", padding: "10px" }}
                          />
                          <span
                            style={{ display: "inline-block", padding: "10px" }}
                          >
                            <h4>Searching Patient...</h4>
                          </span>
                        </div>
                      ) : searchPatientQuery && !patientFoundSuccessfull ? (
                        <div style={{ textAlign: "center", padding: "10px" }}>
                          <h4> No Patient Found !</h4>
                        </div>
                      ) : (
                        undefined
                      )}
                    </Paper>
                  </div>
                ) : (
                  undefined
                )}
              </div>
            </div>
          </div>
        )}
        {patientDetails ? (
          <PatientDetails
            edrId={patientDetails._id}
            showCareStream={true}
            showChiefComplaint={true}
            showTriageLevel={true}
            showDiagnosis={true}
            showDiagnosis={true}
          />
        ) : (
          undefined
        )}

        <div>
          <div
            style={{
              height: "20px",
            }}
          />
          <div className={classesForTabs.root}>
            <Tabs
              classes={{
                root: classesForTabs.root,
                scroller: classesForTabs.scroller,
              }}
              value={value}
              onChange={handleChange}
              textColor="primary"
              TabIndicatorProps={{ style: { background: "#12387a" } }}
              centered={false}
              variant="scrollable"
              fullwidth
            >
              <Tab
                style={{
                  color: "white",
                  borderRadius: 15,
                  outline: "none",
                  color: value === 0 ? "#12387a" : "#3B988C",
                }}
                label="Discharge Summary"
              />
              <Tab
                disabled={true}
                style={{
                  color: "white",
                  borderRadius: 15,
                  outline: "none",
                  color: value === 1 ? "#12387a" : "#3B988C",
                }}
                label="Discharge Medication"
              />
            </Tabs>
          </div>

          {value === 0 ? (
            <div
              style={{ flex: 4, display: "flex", flexDirection: "column" }}
              // className='container-fluid'
            >
              <div
                style={{
                  backgroundColor: "white",
                  marginTop: "20px",
                  borderRadius: 5,
                }}
              >
                <h6 style={{ color: "grey", padding: 10, fontWeight: "bold" }}>
                  Discharge/Disposition
                </h6>

                <div className="row" style={{ marginBottom: 20 }}>
                  <div className="col-md-12" style={styles.form}>
                    <FormControl>
                      <RadioGroup
                        row
                        aria-label="payMethod"
                        name="edrCompletionReason"
                        onChange={onChangeDisposition}
                        value={edrCompletionReason}
                      >
                        <FormControlLabel
                          value="admitted"
                          control={<Radio />}
                          color="default"
                          label="Admitted"
                        />

                        <FormControlLabel
                          value="discharged"
                          control={<Radio />}
                          color="default"
                          label="Discharged"
                        />

                        <FormControlLabel
                          value="transferred"
                          control={<Radio />}
                          color="default"
                          label="Transferred"
                        />

                        <FormControlLabel
                          value={"deceased"}
                          control={<Radio />}
                          color="default"
                          label="Deceased"
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                </div>
              </div>

              <div
                style={{
                  backgroundColor: "white",
                  marginTop: "20px",
                  borderRadius: 5,
                }}
              >
                <h6 style={{ color: "grey", padding: 10, fontWeight: "bold" }}>
                  Discharge Requirements
                </h6>

                <div className="row" style={{ marginBottom: 20 }}>
                  <div className="col-md-12" style={styles.form}>
                    <FormControl>
                      <RadioGroup
                        row
                        aria-label="payMethod"
                        name="edrCompletionRequirement"
                        onChange={onChangeRequirements}
                        value={edrCompletionRequirement}
                      >
                        <FormControlLabel
                          value="withCare"
                          control={<Radio />}
                          label="With Customer Care"
                        />
                        <FormControlLabel
                          value="withoutCare"
                          control={<Radio />}
                          label="Without Customer Care"
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                </div>
              </div>

              <div style={{ marginTop: "20px" }} className="row">
                <div className="col-md-12 col-sm-12 col-12">
                  <TextField
                    multiline
                    label="Discharge Notes"
                    name={"dischargeNotes"}
                    value={dischargeNotes}
                    // error={dischargeNotes === '' && detailsForm}
                    onChange={onChangeValue}
                    rows={4}
                    className="textInputStyle"
                    variant="filled"
                    InputProps={{
                      className: classes.multilineColor,
                      classes: {
                        input: classes.multilineColor,
                      },
                    }}
                    // inputProps={{ maxLength: 12 }}
                  />
                </div>
              </div>

              <div style={{ marginTop: "20px" }} className="row">
                <div className="col-md-12 col-sm-12 col-12">
                  <TextField
                    multiline
                    label="Follow Up Instruction"
                    name={"followUpInstruction"}
                    value={followUpInstruction}
                    // error={FollowUpInstruction === '' && detailsForm}
                    onChange={onChangeValue}
                    rows={4}
                    className="textInputStyle"
                    variant="filled"
                    InputProps={{
                      className: classes.multilineColor,
                      classes: {
                        input: classes.multilineColor,
                      },
                    }}
                    // inputProps={{ maxLength: 12 }}
                  />
                </div>
              </div>

              <div
                className="row d-flex"
                style={{ marginBottom: "80px", marginTop: "25px" }}
              >
                <div className="mr-auto p-2">
                  <img
                    onClick={() => props.history.goBack()}
                    src={Back}
                    style={{
                      width: 45,
                      height: 35,
                      marginTop: "7px",
                      cursor: "pointer",
                    }}
                  />
                </div>
                <div className="p-2">
                  <Button
                    style={styles.stylesForButton}
                    //disabled={!validateFormType1()}
                    onClick={onClick}
                    variant="contained"
                    color="primary"
                  >
                    Next
                  </Button>
                </div>
                {/* <div className="p-2">
                  <Button
                    // onClick={submitDischargeSummary}
                    style={styles.stylesForButton}
                    variant="contained"
                    color="primary"
                  >
                    <strong style={{ fontSize: "12px" }}>Submit</strong>
                  </Button>
                </div> */}
              </div>
            </div>
          ) : value === 1 ? (
            <div
              style={{ flex: 4, display: "flex", flexDirection: "column" }}
              className="container-fluid"
            >
              <div className="row" style={{ marginTop: "20px" }}>
                {dischargeMedArray.medicine &&
                dischargeMedArray.medicine.length > 0 ? (
                  <CustomTable
                    tableData={dischargeMedArray}
                    tableDataKeys={tableDataKeysForDischargeMed}
                    tableHeading={tableHeadingForDischargeMed}
                    handleView={viewItem}
                    action={actions}
                    borderBottomColor={"#60d69f"}
                    borderBottomWidth={20}
                  />
                ) : (
                  undefined
                )}
              </div>

              <div className="row" style={{ marginBottom: "25px" }}>
                <div className="col-4" style={{ paddingLeft: 0 }}>
                  <img
                    onClick={() => props.history.goBack()}
                    src={Back}
                    style={{ width: 45, height: 35, cursor: "pointer" }}
                  />
                </div>
                <div
                  className="col-8 d-flex justify-content-end"
                  style={{ paddingRight: 0 }}
                >
                  <Button
                    onClick={addPharmaRequest}
                    style={styles.stylesForButton}
                    variant="contained"
                    color="primary"
                  >
                    <img className="icon-style" src={plus_icon} />
                    &nbsp;&nbsp;
                    <strong style={{ fontSize: "12px" }}>
                      Pharmacy Request
                    </strong>
                  </Button>
                </div>
              </div>
            </div>
          ) : (
            <div
              style={{ flex: 4, display: "flex", flexDirection: "column" }}
              className="container"
            ></div>
          )}

          {openItemDialog ? (
            <ViewSingleRequest
              item={item}
              openItemDialog={openItemDialog}
              viewItem={viewItem}
            />
          ) : (
            undefined
          )}
        </div>

        <Notification
          msg={errorMsg}
          open={openNotification}
          success={successMsg}
        />
      </div>
    </div>
  );
}

const mapStateToProps = ({ CheckingReducer }) => {
  const { count, patientDetails } = CheckingReducer;
  return { count, patientDetails };
};
export default connect(mapStateToProps, {
  funForReducer,
  setPatientDetailsForReducer,
})(LabRadRequest);
