import React, { useState, useEffect } from 'react'
import './notification.css'
import notificationIcon from '../../assets/img/Notification.png'
import Header from '../../components/Header/Header'
import moment from 'moment'
import { AlertTriangle } from 'react-feather'
import { Badge, Tabs, Tab } from '@material-ui/core'
import cookie from 'react-cookies'
import { markNotification } from '../../public/endpoins'
import axios from 'axios'
import Notification from '../../components/Snackbar/Notification.js';
import { setUsrRoles } from "./config";
import Card from '@material-ui/core/Card';

const styles = {
  styleForTabs: {
    color: "white",
    borderRadius: 5,
    outline: "none",
    // fontSize: "large",
    textTransform: "none",
    width: 180,
  },
};

export default function Notifications(props) {
  const [value, setValue] = useState("Tab Value");
  const [allowedUserRoles, setAllowedUserRoles] = useState([]);
  const [generalArray, setGeneralArray] = useState([]);
  const [staffData, setStaffData] = useState([]);
  const [errorMsg, setErrorMsg] = useState('')
  const [currentUser] = useState(cookie.load("current_user"));
  const [successMsg, setsuccessMsg] = useState('')
  const [openNotification, setOpenNotification] = useState(false)
  const [notificationData, setnotificationData] = useState('')

  useEffect(() => {
    if (
      props.history.location.state &&
      props.history.location.state.notificationData
    ) {
      setnotificationData(props.history.location.state.notificationData)
    }

    if (currentUser && currentUser.staffType) {
      const x = setUsrRoles(currentUser);
      setAllowedUserRoles(x);
      setValue(x[0].tabValue);
    }
  }, [
    notificationData,
    props.history.location.state,
    props.history.location.state.notificationData,
  ])

  const handleChange = (event, newValue) => {
    filerSingleRoleUsers(allowedUserRoles[newValue]);
    setValue(newValue);
  };

  useEffect(() => {
    if (allowedUserRoles[0]) {
      filerSingleRoleUsers(allowedUserRoles[0]);
    }
  }, [generalArray])

  function filerSingleRoleUsers(role) {
    let allSelectedRoleUsers = [];

    if (role.key.split("-").length > 1) {
      generalArray.find((r) => {
        if (r.subType.includes(role.key.split("-")[1])) {
          allSelectedRoleUsers.push(r);
        }
      });
    } else {
      generalArray.find((r) => {
        if (r.staffType === role.key) {
          allSelectedRoleUsers.push(r);
        }
      });
    }
    setStaffData([...allSelectedRoleUsers]);
  }

  const getWhen = (timestamp) => {
    // let when = `${moment(timestamp).format('DD-MM-YYYY')} ${moment(
    //   timestamp
    // ).format('LTS')}`
    // return when
  }

  const getContent = (message) => {
    if (message.indexOf('\n') >= 0) {
      let splitted = message.split('\n')
      let ret = '<ul>'

      for (let i = 0; i <= splitted.length - 1; i++) {
        if (splitted[i] !== '') {
          ret = ret + '<li>' + splitted[i] + '</li>'
        }
      }

      ret = ret + '</ul>'
      return {

        __html: ret,
      }
    }
    return {
      __html: `<ul><li>${message}</li></ul>`,
    }
  } // Get the notification message

  const handleViewNotification = (message, userId) => {
    var id = message._id

    const params = {
      read: true,
    }
    axios
      .get(markNotification + '/' + id + '/' + userId, params)
      .then((res) => {
        if (res.data.success) {
          // setOpenNotification(true)
          // setsuccessMsg('Read Notification Successfully')
          props.history.push({
            pathname: message.route,
            state: {},
          })
        }
      })
      .catch((e) => {
        console.log('Cannot read Notification', e)
        setOpenNotification(true)
        setErrorMsg('Cannot read Notification')
      })
  }

  if (openNotification) {
    setTimeout(() => {
      setOpenNotification(false)
      setErrorMsg('')
      setsuccessMsg('')
    }, 2000)
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        position: 'fixed',
        width: '100%',
        height: '100%',
        backgroundColor: 'rgb(19 213 159)',
        overflowY: 'scroll',
      }}
    >
      <Header history={props.history} />

      <div className='cPadding'>
        <div className='subheader'>
          <div>
            {/* <Badge badgeContent={props.history.location.state.badges} color="secondary"> */}
            <img src={notificationIcon} alt="Alternate" />
            {/* </Badge> */}
            <h4>Notifications</h4>
          </div>
        </div>

        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            textColor="primary"
            TabIndicatorProps={{ style: { background: "#12387a" } }}
            // centered
            variant="scrollable"
          // scrollButtons="auto"
          >
            {allowedUserRoles &&
              allowedUserRoles.map((role) => {
                return (
                  <Tab
                    style={{
                      ...styles.styleForTabs,
                      color:
                        value === role.tabValue ? "#12387a" : "#3B988C",
                      width: 200,
                    }}
                    value={role.tabValue}
                    label={
                      // <Badge
                      //   color="primary"
                      //   badgeContent="2"
                      //   classes={{ badge: classes.badge }}
                      // >
                      //   {role.value}
                      // </Badge>

                      role.value
                    }
                  />
                );
              })}
          </Tabs>
        </div>
      </div>

      {notificationData.length && notificationData.map((item, index) => (
        <Card style={{ minWidth: 205 }} variant="outlined" style={{ marginTop: 20, marginLeft: 30, marginRight: 30 }}>
          <div style={{ marginTop: "10px" }}>
            <div className="col-md-12">
              <h3 style={{ color: "#2973CF", fontSize: 22 }}>{item.title}</h3>
            </div>
            <div className="row">
              {item.columns.map((column, i) => {
                return (

                  <div className="col-md-4">
                    <span style={{ color: "#939393", fontSize: 14, marginLeft: 15 }}>
                      {column}
                    </span>
                  </div>
                );
              })}
              {item.rows.map((row, i) => {
                return (

                  <div className="col-md-4">
                    <span style={{ fontSize: 18, marginLeft: 15 }}>
                      {row}
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
        </Card>
      ))}
    </div>
  )
}
