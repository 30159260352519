import React, { useState, useEffect } from "react";
import Notification from "../../../components/Snackbar/Notification.js";
import CustomTable from "../../../components/Table/Table";
import axios from "axios";
import _ from "lodash";
import { getPreApproval, getRadPreApproval } from "../../../public/endpoins";
import Loader from "react-loader-spinner";
import Back from "../../../assets/img/Back_Arrow.png";
import Header from "../../../components/Header/Header";
import PreApproval from "../../../assets/img/Pre-Approval.png";
import Fingerprint from "../../../assets/img/fingerprint.png";
import AccountCircle from "@material-ui/icons/SearchOutlined";
import InputAdornment from "@material-ui/core/InputAdornment";
import BarCode from "../../../assets/img/Bar Code.png";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import "../../../assets/jss/material-dashboard-react/components/loaderStyle.css";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import classNames from "classnames";
import moment from 'moment';

const tableHeading = [
  "MRN",
  "Request Number",
  "Patient Name",
  "Date",
  "Status",
  "Action",
];
const tableDataKeys = ["mrn", "requestNo", "patientName", "date", "status"];

const styles = {
  textFieldPadding: {
    paddingLeft: 5,
    paddingRight: 5,
  },
  fieldHeight: {
    marginTop: 10,
    marginBottom: 10,
  },
};

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(0),
  },
  input: {
    backgroundColor: "white",
    boxShadow: "none",
    borderRadius: 5,
    "&:after": {
      borderBottomColor: "black",
      boxShadow: "none",
    },
    "&:hover": {
      backgroundColor: "white",
      boxShadow: "none",
    },
    "&:focus": {
      backgroundColor: "white",
      boxShadow: "none",
      borderRadius: 5,
    },
  },
  root: {
    "& .MuiTextField-root": {
      backgroundColor: "white",
    },
    "& .Mui-focused": {
      backgroundColor: "white",
      color: "black",
      boxShadow: "none",
    },
    "& .Mui-disabled": {
      backgroundColor: "white",
      color: "gray",
    },
    "&:focus": {
      backgroundColor: "white",
      boxShadow: "none",
    },
    "& .MuiFormLabel-root": {
      fontSize: "11px",
      paddingRight: "50px",
    },
  },
}));

const actions = { view: true };

export default function PreApprovalScreen(props) {
  const classes = useStyles();
  const matches = useMediaQuery("(min-width:600px)");

  const [preApproval, setpreApproval] = useState([]);
  const [errorMsg, setErrorMsg] = useState("");
  const [openNotification, setOpenNotification] = useState(false);
  const [searchPatientQuery, setSearchPatientQuery] = useState("");
  const [ preApprovalType, setPreApprovalType ] = useState(props.match.params.preApprovalType);

  if (openNotification) {
    setTimeout(() => {
      setOpenNotification(false);
      setErrorMsg("");
    }, 2000);
  }

  useEffect(() => {
    getPreApprovalData("");
  }, []);

  function getPreApprovalData(queryTerm) {
    axios
      .get( ( preApprovalType==="radpreapproval"?getRadPreApproval:getPreApproval) + (queryTerm?"/"+queryTerm:""))
      .then((res) => {
        if (res.data.success) {
          if (res.data.data) {
            console.log("data:: data:: ", res.data.data);
            res.data.data.map((val) => {
              
              val.patientName = val.patientId.name[0].given[0] + " " + val.patientId.name[0].family;
              val.roomNo = val.room[0].roomId.roomNo;
              val.mrn = val.patientId.identifier[0].value;
              val.episodeNo = val.requestNo;

              if ( preApprovalType === "radpreapproval" ){
                val.status = val.radRequest.nurseTechnicianStatus;
                val.labRequestId = val.radRequest.requestId;
                val.labRequestUID = val.radRequest._id;
                val.date = val.radRequest.requestedAt;
              }else{
                val.status = val.labRequest.nurseTechnicianStatus;
                val.labRequestId = val.labRequest.requestId;
                val.labRequestUID = val.labRequest._id;
                val.date = val.labRequest.requestedAt;
              }

              // Age
              var today = new Date();
              var birthDate = new Date(val.patientId.birthDate);
              var age_now = today.getFullYear() - birthDate.getFullYear();
              var m = today.getMonth() - birthDate.getMonth();
              if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                age_now--;
              }
              val.ageNow = age_now;

              let statusesCodes = 'Completed';
              let urgentCodes = 'No';
              let statusCodesData = {
                'Covered': 0,
                'Approved': 0,
                'Selfpay': 0,
                'Not Approved': 0,
                'Pending': 0,
              }

              // Services
              let servicesNames = [];

              
              if ( preApprovalType === "radpreapproval" )
              {

                if (val.radRequest){
                  if (val.radRequest.services){
                    Object.entries(val.radRequest.services).map(([key, val]) => {
                      servicesNames.push(val.name)

                      if (val.insuranceStatus === "Pending"){
                        statusesCodes = 'Pending';
                      }
                      if (val.urgent === "Yes"){
                        statusesCodes = 'Yes';
                      }

                      statusCodesData[ val.insuranceStatus ]++;
                    });
                  }
                }
              }else{
                if (val.labRequest){
                  Object.entries(val.labRequest.services).map(([key, val]) => {
                    servicesNames.push(val.name)

                    if (val.insuranceStatus === "Pending"){
                      statusesCodes = 'Pending';
                    }
                    if (val.urgent === "Yes"){
                      statusesCodes = 'Yes';
                    }

                    statusCodesData[ val.insuranceStatus ]++;
                  });
                }
              }
              

              val.serviceName   = servicesNames.join(',');
              val.statusesCodes = statusesCodes;
              val.urgentCodes   = urgentCodes;
              val.statusCodesData = statusCodesData;

            });
          }
          var sortedObjs = _.sortBy(res.data.data, "date").reverse();
          setpreApproval(sortedObjs);
        } else if (!res.data.success) {
          setErrorMsg(res.data.error);
          setOpenNotification(true);
        }
        return res;
      })
      .catch((e) => {
        console.log("error: ", e);
      });
  }

  function handleView(rec) {
    // let path = `preapproval/viewpreapproval/${rec._id}`;
    let path = `preapproval/${rec._id}/${rec.labRequestUID}`;

    if ( preApprovalType==="radpreapproval" ){
      path = `radpreapproval/${rec._id}/${rec.labRequestUID}`;
    }

    props.history.push({
      pathname: path,
      // state: {
      //   selectedItem: rec,
      // },
    });
    console.log(rec);
    console.log(path);
    /*props.history.push({
      pathname: path,
      state: {
        selectedItem: rec,
      },
    });*/
  }

  const handlePatientSearch = (e) => {
    const a = e.target.value.replace(/[^\w\s]/gi, "");
    setSearchPatientQuery(a);
    /*if (a.length >= 3) {
      axios
        .get(getPreApproval + "/" + a)
        .then((res) => {
          if (res.data.success) {
            if (res.data.data.length > 0) {
              if (res.data.data) {
                console.log(res.data.data, "data");
                res.data.data.map(
                  (d) => (
                    (d.mrn = d.patientId
                      ? d.patientId.identifier[0].value
                      : ""),
                    (d.patientName = d.patientId
                      ? d.patientId.name[0].given[0] +
                        " " +
                        d.patientId.name[0].family
                      : "")
                  )
                );
              }
              var sortedObjs = _.sortBy(res.data.data, "updatedAt");
              setpreApproval(sortedObjs);
            } else {
              setpreApproval([]);
            }
          }
        })
        .catch((e) => {
          console.log("error after searching patient request", e);
        });
    } else if (a.length == 0) {*/
    if (a.length >= 3){
      getPreApprovalData(a);
    } else if (a.length == 0) {
      getPreApprovalData("");
    }
    // }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        position: "fixed",
        width: "100%",
        height: "100%",
        backgroundColor: "rgb(19 213 159)",
        overflowY: "scroll",
      }}
    >
      <Header history={props.history} />

      <div className="cPadding">
        <div className="subheader" style={{ marginLeft: "-6px" }}>
          <div>
            <img src={PreApproval} />
            <h4>All {( preApprovalType === 'radpreapproval' )?'Rad':'Lab'} Pre-Approval Requests</h4>
          </div>
          <div>
            <div>
              <div class="dropdown">
                <button class="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{( props.history.location.pathname === '/dashboard/home/insurance/radpreapproval' )?'Rad Pre-Approval':'Lab Pre-Approval'}</button>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <a class="dropdown-item" href="/dashboard/home/insurance/preapproval">Switch to Lab Pre-Approval</a>
                  <a class="dropdown-item" href="/dashboard/home/insurance/radpreapproval">Switch to Rad Pre-Approval</a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div style={{ height: "25px" }} />

        <div className={`container-fluid ${classes.root}`}>
          <div className="row" style={styles.fieldHeight}>
            <div
              className="col-md-10 col-sm-9 col-8"
              style={{ paddingRight: matches ? 5 : 0, paddingLeft: "15px" }}
            >
              <TextField
                className="textInputStyle"
                id="searchPatientQuery"
                type="text"
                variant="filled"
                label="Patient MRN/Episode/Request Number or Name *"
                name={"searchPatientQuery"}
                value={searchPatientQuery}
                onChange={handlePatientSearch}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <AccountCircle />
                    </InputAdornment>
                  ),
                  className: classes.input,
                  classes: { input: classes.input },
                  disableUnderline: true,
                }}
              />
            </div>
            <div
              className="col-md-1 col-sm-2 col-2"
              style={styles.textFieldPadding}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "white",
                  borderRadius: 5,
                  height: 55,
                }}
              >
                <img
                  src={BarCode}
                  style={{
                    width: matches ? 70 : 52,
                    height: matches ? 60 : 58,
                  }}
                />
              </div>
            </div>
            <div
              className="col-md-1 col-sm-1 col-2"
              style={{ paddingRight: "15px", paddingLeft: matches ? 5 : 0 }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "white",
                  borderRadius: 5,
                  height: 55,
                }}
              >
                <img src={Fingerprint} style={{ maxWidth: 43, height: 43 }} />
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid">
          <div className="row row-5">
          {preApproval && preApproval.length > 0 ? (
            Object.entries(preApproval).map((obj, entry) => {
              return (
                <div className="col-12 col-md-6 col-lg-6 col-xl-6">
                  <div className="card card-detail-info card-detail-small mb-2 pt-lg-5">
                    <div className="row row-5">
                      <div className="col-sm-12"><strong>Request #:</strong> <span>{obj[1].labRequestId}</span> &nbsp; <strong>PA/Bed#:</strong> <span>{obj[1].roomNo}</span></div>
                      <div className="col-sm-6  mt-3"><strong>Epi.#:</strong> <span>{obj[1].episodeNo}</span></div>
                      <div className="col-sm-6  mt-3"><strong>MRN:</strong> <span>{obj[1].mrn}</span></div>
                      <div className="col-sm-10 mt-3"><strong>Patient:</strong> <span>{obj[1].patientName} / {obj[1].gender==="Female"?"F":"M"} / {obj[1].ageNow}y</span></div>
                      <div className="col-sm-2  mt-3 text-right" title={ moment(obj[1].date).format('LLL') }><strong>T:</strong> <span>0:00</span></div>
                    </div>
                    <div className="card-status-action">
                      <span className={classNames(`badge`, `status-`+obj[1].statusesCodes.toLowerCase())}>{obj[1].statusesCodes}</span>
                      { 
                        obj[1].urgentCodes==="Yes"?<span className="ml-info">!</span>:undefined
                      }
                      <i className="ml-view ml-10 zmdi zmdi-eye zmdi-hc-2x" onClick={() => {
                        handleView(obj[1]);
                      }} />
                    </div>
                  </div>
                </div>
              )
            })
          ) : preApproval && preApproval.length === 0 ? (
              <div className="col-md-12 col-sm-12 col-12">
                <h3
                  style={{
                    color: "white",
                    textAlign: "center",
                  }}
                >
                  Opps!! No Data Found
                </h3>
              </div>
          ) : (
              <div className="LoaderStyle">
                <Loader type="TailSpin" color="red" height={50} width={50} />
              </div>
          )}
          </div>

          <div className="row">
            <div
              className="col-md-12 col-sm-12 col-12"
              style={styles.fieldHeight}
            >
              <img
                onClick={() => props.history.goBack()}
                src={Back}
                style={{
                  width: 45,
                  height: 35,
                  cursor: "pointer",
                }}
              />
            </div>
          </div>

          <Notification msg={errorMsg} open={openNotification} />
        </div>
      </div>
    </div>
  );
}
