const mapDateToKeys = (val) => {
  if (typeof val === 'array') {
    if (val[0] && val[0] === 'poId' && val[1] === 'sentAt') {
      return true
    }
  } else if (
    val === 'date' ||
    val === 'createdAt' ||
    val === 'dateGenerated' ||
    val === 'updatedAt' ||
    val === 'dateReceived' ||
    val === 'createdTimeStamp'||
    val === 'dischargeTimestamp' || 
    val === 'currentTime'
    // val==='shiftStartTime' ||
    // val==='shiftEndTime'
  ) {
    return true
  }
}

export default mapDateToKeys
