import axios from 'axios'
import {
  getHKRequests,
  getNTRequests,
  getAllEDRByKeyword,
  getAllHouseKeepers,
  getNurseTechniciansURL,
  getCustomerCaresURL,
  submitAssistanceRequest,
  getEDNurses,
  addReqForNurse,
  getReqForNurse,
  completeReqForNurse,
  updateReqForNurse,
  updateSubmitRequestForHousekeeping,
  getHKRequestsInEDNurse,
  getNTRequestsinED,
  getCustomerCareRequests,
  getCustomerCareRequestsById,
} from '../../public/endpoins'

export const getHKRequestsData = (
  setHousekeepingData,
  setErrorMsg,
  setOpenNotification
) => {
  axios
    .get(getHKRequests)
    .then((response) => {
      console.log('getHKRequests', response.data.data)
      response.data.data.map(
        (d) => (
          (d.patientMRN = d.patientId.identifier[0].value),
          (d.patientName =
            d.patientId.name[0].given[0] + ' ' + d.patientId.name[0].family),
          (d.id = d.staffId.identifier[0].value),
          (d.name = d.staffId.name[0].given[0] + ' ' + d.staffId.name[0].family)
        )
      )
      setHousekeepingData(response.data.data)
    })
    .catch((error) => {
      console.log('Error', error)
      setOpenNotification(true)
      setErrorMsg('Cannot get Housekeeping requests at the moment')
    })
}

export const getHKRequestsDataForSpecificUser = (
  currentUser,
  setHousekeepingData,
  setErrorMsg,
  setOpenNotification
) => {
  axios
    .get(getHKRequestsInEDNurse + '/' + currentUser)
    .then((response) => {
      console.log('getHKRequests', response.data.data)
      response.data.data.map(
        (d) => (
          (d.patientMRN = d.patientId.identifier[0].value),
          (d.patientName =
            d.patientId.name[0].given[0] + ' ' + d.patientId.name[0].family),
          (d.id = d.staffId.identifier[0].value),
          (d.name = d.staffId.name[0].given[0] + ' ' + d.staffId.name[0].family)
        )
      )
      setHousekeepingData(response.data.data)
    })
    .catch((error) => {
      console.log('Error', error)
      setOpenNotification(true)
      setErrorMsg('Cannot get Housekeeping requests at the moment')
    })
}

export const getCustomerCareRequestsData = (
  setCustomerCareData,
  setErrorMsg,
  setOpenNotification
) => {
  axios
    .get(getCustomerCareRequests)
    .then((response) => {
      console.log('customer care', response.data.data)
      response.data.data.map(
        (d) => (
          (d.patientMRN = d.patientId.identifier[0].value),
          (d.patientName =
            d.patientId.name[0].given[0] + ' ' + d.patientId.name[0].family),
          (d.id = d.staffId.identifier[0].value),
          (d.name = d.staffId.name[0].given[0] + ' ' + d.staffId.name[0].family)
        )
      )
      setCustomerCareData(response.data.data)
    })
    .catch((error) => {
      console.log('Error', error)
      setOpenNotification(true)
      setErrorMsg('Cannot get Nurse Technician requests at the moment')
    })
}

export const getCustomerCareRequestsDataById = (
  currentUser,
  setCustomerCareData,
  setErrorMsg,
  setOpenNotification
) => {
  axios
    .get(getCustomerCareRequestsById + '/' + currentUser)
    .then((response) => {
      console.log('customer care', response.data.data)
      response.data.data.map(
        (d) => (
          (d.patientMRN = d.patientId.identifier[0].value),
          (d.patientName =
            d.patientId.name[0].given[0] + ' ' + d.patientId.name[0].family),
          (d.id = d.staffId.identifier[0].value),
          (d.name = d.staffId.name[0].given[0] + ' ' + d.staffId.name[0].family)
        )
      )
      setCustomerCareData(response.data.data)
    })
    .catch((error) => {
      console.log('Error', error)
      setOpenNotification(true)
      setErrorMsg('Cannot get Housekeeping requests at the moment')
    })
}
export const getNTRequestsData = (
  setNurseTechnicianData,
  setErrorMsg,
  setOpenNotification
) => {
  axios
    .get(getNTRequests)
    .then((response) => {
      console.log('getNTRequestsData', response.data.data)
      response.data.data.map(
        (d) => (
          (d.patientMRN = d.patientId.identifier[0].value),
          (d.patientName =
            d.patientId.name[0].given[0] + ' ' + d.patientId.name[0].family),
          (d.id = d.staffId.identifier[0].value),
          (d.name = d.staffId.name[0].given[0] + ' ' + d.staffId.name[0].family)
        )
      )
      setNurseTechnicianData(response.data.data)
    })
    .catch((error) => {
      console.log('Error', error)
      setOpenNotification(true)
      setErrorMsg('Cannot get Nurse Technician requests at the moment')
    })
}

export const getNTRequestsDataForSpecificUser = (
  currentUser,
  setNurseTechnicianData,
  setErrorMsg,
  setOpenNotification
) => {
  axios
    .get(getNTRequestsinED + '/' + currentUser)
    .then((response) => {
      console.log('getNTRequestsData', response.data.data)
      response.data.data.map(
        (d) => (
          (d.patientMRN = d.patientId.identifier[0].value),
          (d.patientName =
            d.patientId.name[0].given[0] + ' ' + d.patientId.name[0].family),
          (d.id = d.staffId.identifier[0].value),
          (d.name = d.staffId.name[0].given[0] + ' ' + d.staffId.name[0].family)
        )
      )
      setNurseTechnicianData(response.data.data)
    })
    .catch((error) => {
      console.log('Error', error)
      setOpenNotification(true)
      setErrorMsg('Cannot get Nurse Technician requests at the moment')
    })
}

export const getSenseiRequestsData = (
  currentUserId,
  tabName,
  setSenseiRequestData,
  setErrorMsg,
  setOpenNotification
) => {
  axios
    .get(getReqForNurse + '/' + currentUserId)
    .then((response) => {
      console.log('getSenseiData', response.data.data)
      response.data.data.map(
        (d) => (
          (d.patientMRN = d.edrId.patientId.identifier[0].value),
          (d.patientName =
            d.edrId.patientId.name[0].given[0] +
            ' ' +
            d.edrId.patientId.name[0].family),
          (d.name =
            d.assignedTo.name[0].given[0] + ' ' + d.assignedTo.name[0].family),
          (d.id = d.assignedTo.identifier[0].value)
          // (d.id = d.staffId.identifier[0].value),
          // (d.name =
          //   d.staffId.name[0].given[0] + ' ' + d.patientId.name[0].family)
        )
      )
      setSenseiRequestData(response.data.data)
    })
    .catch((error) => {
      console.log('Error', error)
      setOpenNotification(true)
      setErrorMsg('Cannot get Nurse Technician requests at the moment')
    })
}

export const handleCompleteRequestCall = (
  params,
  setOpenNotification,
  setErrorMsg
) => {
  axios
    .put(completeReqForNurse, params)
    .then((response) => {
      console.log('completed', response.data.data)
      window.location.reload(false)
    })
    .catch((error) => {
      console.log('Error', error)
      setOpenNotification(true)
      setErrorMsg('Cannot get Nurse Technician requests at the moment')
    })
}

export const handleCompleteRequestCallHousekeeping = (
  params,
  setOpenNotification,
  setErrorMsg
) => {
  axios
    .put(updateSubmitRequestForHousekeeping, params)
    .then((response) => {
      console.log('completed', response.data.data)
      window.location.reload(false)
    })
    .catch((error) => {
      console.log('Error', error)
      setOpenNotification(true)
      setErrorMsg('Cannot get Nurse Technician requests at the moment')
    })
}

export const getSearchPatients = (
  e,
  setpatientFoundSuccessfully,
  setpatientFound,
  setLoadSearchedData
) => {
  axios
    .get(getAllEDRByKeyword + '/' + e)
    .then((res) => {
      if (res.data.success) {
        if (res.data.data.length > 0) {
          console.log('Search Patients', res.data.data)
          setpatientFoundSuccessfully(true)
          setpatientFound(res.data.data)
          setLoadSearchedData(false)
        } else {
          setpatientFoundSuccessfully(false)
          setpatientFound('')
          setLoadSearchedData(false)
        }
      }
    })
    .catch((e) => {
      console.log('error after searching patient request', e)
    })
}

export const getAssistancesNames = (assistance, setAssistancesName) => {
  let url
  if (assistance === 'Housekeeping') {
    url = getAllHouseKeepers
  } else if (assistance === 'Customer Care') {
    url = getCustomerCaresURL
  } else if (assistance === 'Nurse Technician') {
    url = getNurseTechniciansURL
  }
  axios
    .get(url)
    .then((response) => {
      console.log('assisters names', response.data.data)
      response.data.data.map(
        (d) => (
          (d.identifier = d.identifier[0].value),
          (d.name = d.name[0].given[0] + ' ' + d.name[0].family)
        )
      )
      setAssistancesName(response.data.data)
    })
    .catch((error) => {
      console.log('Error', error)
    })
}

export const getAssistancesNamesForSensei = (setAssistancesNameForSensei) => {
  axios
    .get(getEDNurses)
    .then((response) => {
      console.log('assisters names', response.data.data)
      response.data.data.map(
        (d) => (
          (d.identifier = d.identifier[0].value),
          (d.name = d.name[0].given[0] + ' ' + d.name[0].family)
        )
      )
      setAssistancesNameForSensei(response.data.data)
    })
    .catch((error) => {
      console.log('Error', error)
    })
}

export function submitAssistanceReq(
  currentUser,
  props,
  params,
  setOpenNotification,
  setErrorMsg
) {
  let apiCall
  if (currentUser.staffType === 'Sensei') {
    apiCall = addReqForNurse
  } else {
    apiCall = submitAssistanceRequest
  }
  axios
    .post(apiCall, params)
    .then((res) => {
      if (res.data.success) {
        console.log('response', res.data)
        props.history.goBack()
      } else if (!res.data.success) {
        setOpenNotification(true)
        setErrorMsg('Cannot submit request at the moment')
      }
    })
    .catch((e) => {
      console.log('Error while adding staff', e)
      setOpenNotification(true)
      setErrorMsg('Cannot submit request at the moment')
    })
}

export function handleEditRequestCall(
  currentUser,
  params,
  setOpenNotification,
  setErrorMsg
) {
  let apiCall
  if (currentUser.staffType === 'Sensei') {
    apiCall = updateReqForNurse
  }
  axios
    .put(apiCall, params)
    .then((res) => {
      if (res.data.success) {
        console.log('response', res.data)
        // props.history.goBack()
      } else if (!res.data.success) {
        setOpenNotification(true)
        setErrorMsg('Cannot submit request at the moment')
      }
    })
    .catch((e) => {
      console.log('Error while adding staff', e)
      setOpenNotification(true)
      setErrorMsg('Cannot submit request at the moment')
    })
}
