import React, { useEffect, useState, useReducer } from "react";
import { makeStyles } from "@material-ui/core/styles";

import tableStyles from "../../assets/jss/material-dashboard-react/components/tableStyle.js";

import Loader from "react-loader-spinner";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import PatientDetailsModal from "./PatientDetailsDialogueDirect";
import axios from "axios";
import {
  getSingleEdr,
} from "../../public/endpoins";
import FormatDate from "../../utils/formatDateTime";
import _ from "lodash";
import { displayInHumanDifference, replacePatientIdIfNull } from "../../utils/commonFunc";

let matches = false;

const styles = {
  inputContainerForTextField: {
    marginTop: 25,
  },

  inputContainerForDropDown: {
    marginTop: 25,
  },

  stylesForLabel: {
    fontWeight: "700",
    color: "white",
  },

  stylesForButton: {
    color: "white",
    cursor: "pointer",
    borderRadius: 15,
    backgroundColor: "#2C6DDD",
    width: "140px",
    height: "50px",
    outline: "none",
  },

  stylesForPurchaseButton: {
    color: "white",
    cursor: "pointer",
    borderRadius: 15,
    backgroundColor: "#2C6DDD",
    width: "60%",
    height: "50px",
    outline: "none",
  },

  forTableCell: {
    color: "black",
    fontSize: 14,
  },

  stylesForPatientButton: {
    color: "white",
    cursor: "pointer",
    borderRadius: 10,
    backgroundColor: "#2C6DDD",
    width: "140px",
    height: "45px",
    // outline: "none",
    // alignSelf:'center'
    marginTop: 30,
  },

  headingStyles: {
    fontWeight: "bold",
    color: "grey",
  },

  root: {
    flexGrow: 1,
  },

  textStyles: {
    fontWeight: "700",
    color: "black",
    fontSize: 12,
    textOverflow: "ellipsis",
    width: "90%",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },

  headerHeading: {
    display: "flex",
    // justifyContent: "center",
    // alignItems: "center",
    // verticalAlign: "center",
    paddingTop: 9,
    paddingLeft: 0,
  },
  headerHeadingText: {
    fontWeight: "700",
    color: "white",
    fontSize: 12,
  },
};
const useStyles = makeStyles(tableStyles);

function PatientDetailsQuick (props) {
  const theme = useTheme();
  matches = useMediaQuery(theme.breakpoints.up("sm"));

  const classes = useStyles();

  const [patientDetails, setPatientDetails] = useState("");
  const [patientDetailsModal, setPatientDetailsModal] = useState(false);
  const [doctorNotesDiagnosis, setDoctorNotesDiagnosis] = useState([]);

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    console.log(newValue);
    setValue(newValue);
  };

  const filterDoctorNotesDiagnosis = (val) => {
    /*val.map(
      (d) => (
        (d.assignedTime = d.assignedTime ? FormatDate(d.assignedTime) : ""),
        (d.doctor = d.addedBy.name[0].given[0] + " " + d.addedBy.name[0].family),
        (d.diagnosis = d.code.length)
      )
    );

    const doctorNotesSorted = _.sortBy(val, "assignedTime").reverse();*/


    if (val && val.length > 0) {
      let data = [];
      let dataCodeName = [];
      val.map((d) => {
        d.code.map((codes) => {
          let singleCode = codes._id;
          console.log("singleCode::", singleCode);
          let found = data.find((i) => i === singleCode);
          if (!found) {
            data.push(singleCode);
            dataCodeName.push(codes.short_desc); //codes.code + ": " + 
          }
        });
      });

      setDoctorNotesDiagnosis(dataCodeName);
    }


  }

  useEffect(() => {
    // console.log("response single edr::", props);
    if (props.edrId){
      axios
        .get(`${getSingleEdr}/${props.edrId}`)
        .then((res) => {

          let dataReturn = replacePatientIdIfNull(res.data.data);
          // console.log("response single edr::", dataReturn);
          setPatientDetails(dataReturn);
          return res;
        })
        .catch((e) => {
          // console.log("response single edr:: error: ", e);
        });
    }
  }, [props.edrId])

  return (
    <>
        {patientDetails ? (
          <div className="patientCard-Top" style={{
            backgroundColor: (props.backgroundColor ? props.backgroundColor : '#fff'),
          }}>
            <ul>
              <li>
              <strong>Patient:</strong> { 
                patientDetails && patientDetails.patientId
                ? patientDetails.patientId.name[0].given[0] + " " + patientDetails.patientId.name[0].family
                : undefined
              }
              {
                patientDetails.patientId.isChild ? ' / CH': undefined
              }
              </li>
              <li>
              <strong>Length of Stay:</strong> { displayInHumanDifference(patientDetails.createdTimeStamp) }
              </li>
              {patientDetails.room && patientDetails.room.length > 0? 
                (
                  <li>
                  <strong>PA/Bed#:</strong> { patientDetails.room[0].productionRoomId.paName } / { patientDetails.room[0].roomId.roomNo }
                  </li>
                )
              : undefined }
            </ul>
            

          </div>
        ) : <div className="patientCard-Top" style={{
            backgroundColor: (props.backgroundColor ? props.backgroundColor : '#fff'),
          }}>Loading Patient Details</div>}
    </>
  );
}
export default PatientDetailsQuick ;
