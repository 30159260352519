import React, { Component } from "react";
import QrReader from "react-qr-scanner";
import Button from "@material-ui/core/Button";
import CancelIcon from "@material-ui/icons/Cancel";
import Fab from "@material-ui/core/Fab";

class Test extends Component {
  constructor(props) {
    super(props);
    this.state = {
      delay: 100,
      result: "No result",
    };

    this.handleScan = this.handleScan.bind(this);
  }
  handleScan(data) {
    if (data) {
      this.props.handleScanQR(data);
    }
  }
  handleError(err) {
    console.error(err);
  }
  render() {
    const previewStyle = {
      height: "100%",
      width: "100%",
      position: "fixed",
    };

    return (
      <div
        style={{
          // display: "flex",
          // flexDirection: "column",
          width: "100%",
          height: "100%",
          // position: "fixed",
        }}
      >
        <Fab
          style={{ position: "fixed", right: 20, top: 20, zIndex: 20 }}
          color="secondary"
          aria-label="add"
          onClick={() => this.props.scanQRCode()}
        >
          <CancelIcon />
        </Fab>
        <QrReader
          delay={this.state.delay}
          style={previewStyle}
          onError={this.handleError}
          onScan={this.handleScan}
        />
      </div>
    );
  }
}

export default Test;
