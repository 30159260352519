import React from "react"
import CareStream from "../../../assets/img/carestream/CareStream.png"
import ContainerCS from "../../../components/CareStream/ContainerCS"
import { medicationStyles } from "../styles"

export default function Medication(props) {
  const classes = medicationStyles()

  const getMedicationData = (data) => {
    props.getMedication(data)
  }

  return (
    <div className={classes.root}>
      <div className="subheader" style={{ marginLeft: "-10px" }}>
        <img src={CareStream} className={classes.image} />
        <div style={{ flex: 4, display: "flex", alignItems: "center" }}>
          <h4 style={{ color: "white", fontWeight: "700" }}>Medications</h4>
        </div>
      </div>

      <ContainerCS
        heading="Medication"
        buttonHeading="Medication"
        getData={getMedicationData}
      />
    </div>
  )
}
