import React, { useEffect, useState } from "react";
import cookie from "react-cookies";
import axios from "axios";
import Button from "@material-ui/core/Button";
import KHMC_White from "../../../assets/img/KHMC Header LOGO - Grey.png";
import DownloadIcon from "../../../assets/img/Download Icon.png";
import PrintIcon from "../../../assets/img/Print Icon.png";
import Back from "../../../assets/img/Back_Arrow_black.png";
import Header from "../../../components/Header/HeaderGrey";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import DetailBlockForDCD from "../../../components/DHR/DCD/ViewScreen/detailBlockForDCD";
import DetailBlock from "../../../components/DHR/DCD/ViewScreen/detailBlock";
import formatDate from "../../../utils/formatDate";
import PatientDetailsQuick from "../../../components/PatientDetails/PatientDetailsQuick";
// Icons
import PatientDetailIcon from "../../../assets/img/Patient Details Icon.png";
import PastMedicalIcon from "../../../assets/img/Past Medical History Icon.png";
import InvestigationIcon from "../../../assets/img/Investigations Icon.png";
import PhysicalExamIcon from "../../../assets/img/Physical Exam Icon.png";
import ROSIcon from "../../../assets/img/ROS Icon.png";
import ActionPlanIcon from "../../../assets/img/Action & Plan Icon.png";
import covIcon from "../../../assets/img/Course Of Visit Icon.png";
import TriageAndAssessmentIcon from "../../../assets/img/Triage & Assessment Icon.png";

import logoPatientInvoice from "../../../assets/img/logoPatientSummaryInvoice.png";
import jsPDF from "jspdf";
import "jspdf-autotable";
import html2PDF from "jspdf-html2canvas";

const styles = {
	save: {
		cursor: "pointer",
		borderRadius: 5,
		backgroundColor: "#2973CF",
		width: "180px",
		height: "50px",
		outline: "none",
		color: "white",
	},
	Edit: {
		cursor: "pointer",
		borderRadius: 5,
		width: "180px",
		height: "50px",
		outline: "none",
		border: "2px solid gray",
		backgroundColor: "#FEFEFE",
	},
};

function ViewTriageHistory(props) {
	const matches = useMediaQuery("(min-width:600px)");
	const [currentUser] = useState(cookie.load("current_user"));
	const [formData, setformData] = useState([]);
	const [formName, setFormName] = useState("");
	const [selectedItem, setSelectedItem] = useState("");
	const [priorECG, setPriorECG] = useState([]);
	const [priorXray, setpriorXray] = useState([]);
	const [isForPDF, setIsForPDF] = useState(false);
	const [isPrinting, setIsPrinting] = useState(false);

	useEffect(() => {
		const propsData = props.history.location.state;
		setFormName(propsData.comingFor);
		setSelectedItem(propsData.selectedItem);
		console.log("TriageHistory:: propsData:", propsData)
		if(propsData.dcdForm[0]){
			const FormData = propsData.dcdForm[0];
			console.log(currentUser, "current user");

			console.log("propsData::", propsData);

			
			const selectedTriage = propsData.triageData;
			console.log("SPTTS", selectedTriage);

		    let arr = [],
		      subArray = [];


		    let triageLevelCheck = [
		      {
		        subheading: "Triage Level (System)",
		        description: selectedTriage.triageLevel.toString(),
		      },
		    ];
		    if (selectedTriage.newTriageLevel && selectedTriage.newTriageLevel !== ""){
		      triageLevelCheck.push({
		        subheading: "Triage Level (Override)",
		        description: selectedTriage.newTriageLevel.toString(),
		      });
		      triageLevelCheck.push({
		        subheading: "Triage Level (Override) - Reason",
		        description: selectedTriage.reasonChangeTriageLevel.toString(),
		      });
		    }

	        let calcBMIText = (BMICalc) => {
	          let BMIRangeText = "Healthy";
	          if (BMICalc < 18.5){
	            BMIRangeText = "Underweight";
	          } else if (BMICalc >= 18.5 && BMICalc <= 24.9){
	            BMIRangeText = "Healthy";
	          } else if (BMICalc >= 25 && BMICalc <= 29.9){
	            BMIRangeText = "Overweight";
	          } else if (BMICalc >= 30){
	            BMIRangeText = "Obese";
	          }
	          return BMIRangeText;
	        }

			arr = [
				{
					heading: "Triage History",
					columnSize: 4,
					subArray: [
						{
							subheading: "Date/Time",
							description: formatDate(selectedTriage.triageTime),
						},
						/*{
							subheading: "Patient Name",
							description: propsData.selectedItem.patientName,
						},*/
						{
							subheading: "Triage No.",
							description: selectedTriage.triageRequestNo,
						},

					],
				},
				{
					heading: "Triage Level",
					columnSize: 1,
					subArray: triageLevelCheck,
				},
				{
					heading: "Patient Vitals",
					columnSize: 4,
					subArray: [
						{
							subheading: "Heart Rate",
							description:
								selectedTriage.heartRate !== "N/A"
									? selectedTriage.heartRate + " bpm"
									: "",
						},
						{
							subheading: "Blood Pressure Systolic",
							description:
								selectedTriage.bloodPressureSys !== "N/A"
									? selectedTriage.bloodPressureSys + " mmHg"
									: "",
						},
						{
							subheading: "Blood Pressure Diastolic",
							description:
								selectedTriage.bloodPressureDia !== "N/A"
									? selectedTriage.bloodPressureDia + " mmHg"
									: "",
						},
						{
							subheading: "Respiratory Rate",
							description:
								selectedTriage.respiratoryRate !== "N/A"
									? selectedTriage.respiratoryRate + " /min"
									: "",
						},
						{
							subheading: "Temperature",
							description:
								selectedTriage.temperature !== "N/A"
									? selectedTriage.temperature + " °C"
									: "",
						},
						{
							subheading: "Blood Sugar Level",
							description:
								selectedTriage.FSBS !== "N/A"
									? selectedTriage.FSBS + " mg/dL"
									: "",
						},
						{
							subheading: "Pain Scale",
							description:
								selectedTriage.painScale !== "N/A"
									? selectedTriage.painScale
									: "",
						},
						{
							subheading: "Pulse OX",
							description:
								selectedTriage.pulseOX !== "N/A"
									? selectedTriage.pulseOX + " SpO2"
									: "",
						},
						{
				            subheading: "Weight",
				            description:
				              selectedTriage.weight !== "N/A"
				                ? selectedTriage.weight + ""
				                : "",
				          },
				          {
				            subheading: "Size",
				            description:
				              selectedTriage.SIZE !== "N/A"
				                ? selectedTriage.SIZE + ""
				                : "",
				          },
				          {
				            subheading: "BMI",
				            description:
				              selectedTriage.BMI !== "N/A"
				                ? selectedTriage.BMI + ""
				                : "",
				          },
			              {
			                subheading: "Body Mass Index (BMI)",
			                description:
			                  selectedTriage.BMI !== "N/A"
			                    ? calcBMIText(selectedTriage.BMI) + ""
			                    : "",

			              },
				          {
				            subheading: "Intraocular Pressure (IOP) - Left",
				            description:
				              selectedTriage.IOP !== "N/A"
				                ? selectedTriage.IOP + ""
				                : "",
				          },
				          {
				            subheading: "Intraocular Pressure (IOP) - Right",
				            description:
				              selectedTriage.IOPRight !== "N/A"
				                ? selectedTriage.IOPRight + ""
				                : "",
				          },
					],
				},
				{
					heading: "Physical Examination",
					columnSize: 1,
					subArray: [
						{
							subheading: "General Appearance",
							description: selectedTriage.generalAppearance.toString(),
						},
						{
							subheading: "Respiratory",
							description: selectedTriage.respiratory.toString(),
						},
						{
							subheading: "Cardiac",
							description: selectedTriage.cardiac.toString(),
						},
						{
							subheading: "Abdomen",
							description: selectedTriage.abdomen.toString(),
						},
						{
							subheading: "Neurological",
							description: selectedTriage.neurological.toString(),
						},
						{
							subheading: "Glasgow Coma Scale/Score",
							description: selectedTriage.gcsScore.toString(),
						}
					],
				},
			];

			setformData(arr);
		}
	}, []);

	
	const printForm = () => {
		setIsPrinting(true);
		window.setTimeout(function(){
			window.print();
			setIsPrinting(false);
		}, 1000);
	};

	const downloadTriage = async () => {

		const selectedTriage = props.history.location.state.triageData;
		const data = await document.querySelector("#jstpdf");
		setIsForPDF(true);

		html2PDF(data, {
		    jsPDF: {
		      format: 'a4',
		      unit: 'px',
		    },
		    margin: {
				top: 10,
				right: 10,
				bottom: 10,
				left: 10,
			},
		    imageType: 'image/jpeg',
		    output: `Triage-${selectedTriage.triageRequestNo}.pdf`
		});
		setIsForPDF(false);


		/*var doc = new jsPDF("landscape", "pt", "a4");
		var logo = new Image();
		logo.src = logoPatientInvoice;
		// header
		doc.setFontSize(15);
		doc.addImage(logo, "PNG", 10, 10, 55, 30);
		doc.text(60, 15, "Al-Khalidi Hospital & Medical Center");
		doc.text(77, 22, "Triage & Assessment");
		doc.setFontSize(12);
		doc.text(170, 14, "Amman Jordan");

		const selectedTriage = props.history.location.state.triageData;

		doc.setFontSize(9);

		const data = await document.querySelector("#jstpdf");
		doc.html(data).then(() => {
			doc.save(`Triage-${selectedTriage.triageRequestNo}.pdf`);
		});*/
	};

	const handleEdit = () => {
		props.history.push({
			pathname: "/dashboard/home/dcddashboard/triageForm",
			state: {
				comingFor: formName,
				selectedItem: selectedItem,
			},
		});
	};

	console.log("props in viewTriageHistory", props);
	return (
		<div
			style={{
				display: "flex",
				flexDirection: "column",
				flex: 1,
				position: "fixed",
				width: "100%",
				height: "100%",
				overflowY: "scroll",
				border: "1p",
			}}
		>
		<div id="jstpdf">
		  <Header 
		  	history={props.history}
		  	showForPDF={isForPDF}
		  	/>


	      <PatientDetailsQuick
	        edrId={selectedItem._id}
	        backgroundColor="rgb(19 213 159)" />

			<div className="cPadding">
				<div
					className="subheader"
					style={{ marginLeft: "-15px", marginBottom: 45 }}
				>
					<div className="col-md-6 col-8">
						<img src={PatientDetailIcon} alt="PatientDetailIcon" />
						<h4
							style={{
								color: "#000000",
								fontSize: matches ? 34 : 15,
								fontWeight: "bolder",
								cursor: 'pointer'
							}}
						>
							{formName}
						</h4>
					</div>
					<div className="col-md-6 col-4">
						{isForPDF || isPrinting ? undefined:
						<>
						<img
							style={{ width: 35, height: "inherit", marginRight: 10, cursor: 'pointer' }}
							src={DownloadIcon}
							onClick={downloadTriage}
							alt="Alternate"
						/>
						<img
							style={{ width: 35, height: "inherit", cursor: 'pointer' }}
							src={PrintIcon}
							onClick={printForm}
							alt="Alternate"
						/>
						</>}
					</div>
				</div>

				<div className="container-fluid">
					<div>
					{formData && formData.length > 0
						? formData.map((arr) => {
								return (
									<div>
										{formName === "Triage & Assessment" ||
										formName === "Investigations" ||
										formName === "Action & Plan" ||
										formName === "Course of Visit" ? (
											<DetailBlock
												formName={formName}
												heading={arr.heading}
												subArray={arr.subArray}
												columnSize={arr.columnSize}
											/>
										) : (
											<DetailBlockForDCD
												formName={formName}
												heading={arr.heading}
												subArray={arr.subArray}
												columnSize={arr.columnSize}
											/>
										)}
									</div>
								);
							})
						: undefined}
					</div>
					{isPrinting || isForPDF ? undefined : 
					<div className="row" style={{ marginTop: "2%", marginBottom: "2%" }}>
						<div className="col-md-6 col-sm-6 col-6">
							<img
								onClick={() => props.history.goBack()}
								src={Back}
								style={{ width: 45, height: 40, cursor: "pointer" }}
								alt="Alternate"
							/>
						</div>
						<div className="col-md-6 col-sm-6 col-6 d-flex justify-content-end">
							<Button
								style={styles.Edit}
								variant="contained"
								color="default"
								onClick={handleEdit}
							>Add New</Button>
						</div>
					</div>}
				</div>
			</div>
		</div>
		</div>
	);
}
export default ViewTriageHistory;
