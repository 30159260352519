import FormatDate from "../../utils/formatDateTime";
import _ from "lodash";
import moment from 'moment';

const printDiagnosis = (v) => {
  let dataCode = [];
  Object.entries(v).map(([key, val]) => {
    console.log('FUCKED', val);
    dataCode.push(val.short_desc);
  });
  return dataCode.join(', ');
}

export function selectedDataCalled(selectedRecord, dispatch) {
  console.log("selectedRecord::selectedDataCalled::", selectedRecord);
  Object.entries(selectedRecord).map(([key, val]) => {
    if (val && typeof val === "object") {
      if (key === "doctorNotes") {
        val.map(
          (d) => (
            (d.assignedTime = d.assignedTime ? FormatDate(d.assignedTime) : ""),
            (d.doctor = d.addedBy.name[0].given[0] + " " + d.addedBy.name[0].family),
            (d.diagnosis = d.code.length),
            (d.diagnosisList = printDiagnosis(d.code)),
            (d.diagnosisAdditionalList = printDiagnosis(d.additionalCode))
          )
        );

        const doctorNotesSorted = _.sortBy(val, "assignedTime").reverse();
        console.log("doctorNotesSorted::", doctorNotesSorted);
        dispatch({
          field: "doctorNotesArray",
          value: doctorNotesSorted,
        });

        if (val && val.length > 0) {
          let data = [];
          let dataCodeName = [];
          val.map((d) => {
            d.code.map((codes) => {
              let singleCode = codes._id;
              console.log("singleCode::", singleCode);
              let found = data.find((i) => i === singleCode);
              if (!found) {
                data.push(singleCode);
                dataCodeName.push(codes.short_desc);//codes.code + ": " + 
              }
            });
          });
          console.log("diagnosis aray", data);
          dispatch({ field: "diagnosisArray", value: dataCodeName });
        }
      } else if (key === "edNurseRequest") {
        val.map(
          (d) => (
            (d.requestedAt = d.requestedAt ? FormatDate(d.requestedAt) : ""),
            (d.doctorName =
              d.addedBy.name[0].given[0] + " " + d.addedBy.name[0].family)
          )
        );

        const edNurseSorted = _.sortBy(val, "requestedAt").reverse();

        dispatch({
          field: "edNurseArray",
          value: edNurseSorted,
        });
      } else if (key === "eouNurseRequest") {
        val.map(
          (d) => (
            (d.requestedAt = d.requestedAt ? FormatDate(d.requestedAt) : ""),
            (d.doctorName =
              d.addedBy.name[0].given[0] + " " + d.addedBy.name[0].family)
          )
        );

        const eouNurseSorted = _.sortBy(val, "requestedAt").reverse();
        dispatch({
          field: "eouNurseArray",
          value: eouNurseSorted,
        });
      } else if (key === "nurseTechnicianRequest") {
        val.map(
          (d) => (
            (d.requestedAt = d.requestedAt ? FormatDate(d.requestedAt) : ""),
            (d.doctorName =
              d.addedBy.name[0].given[0] + " " + d.addedBy.name[0].family)
          )
        );

        const nurseTechSorted = _.sortBy(val, "requestedAt").reverse();
        dispatch({
          field: "nurseTechnicianArray",
          value: nurseTechSorted,
        });
      } else if (key === "anesthesiologistNote") {
        val.map(
          (d) => (
            (d.noteTime = d.noteTime ? FormatDate(d.noteTime) : ""),
            (d.anesthesiologistRef =
              d.addedBy.name[0].given[0] + " " + d.addedBy.name[0].family)
          )
        );
        const anesthSorted = _.sortBy(val, "noteTime").reverse();

        dispatch({
          field: "anesthesiologistArray",
          value: anesthSorted,
        });
      } else if (key === "consultationNote") {
        /*val.map(
          (d) => (
            (d.consultantName =
              d.consultant &&
              d.consultant.name &&
              d.consultant.name[0].given[0] +
                " " +
                d.consultant.name[0].family),
            (d.consultant = d.consultant && d.consultant._id),
            (d.noteTime = d.noteTime ? FormatDate(d.noteTime) : ""),
            (d.consultationType = d.consultationType),
            (d.addedByName =
              d.addedBy.name &&
              d.addedBy.name[0].given[0] + " " + d.addedBy.name[0].family)
          )
        );*/


        let xVal = val;
        xVal.map((d) => {
          d.requesterName = d.addedBy && d.addedBy.name[0].given[0] + " " + d.addedBy.name[0].family;
          d.consultantName = 'Dr. ' + (d.consultant && d.consultant.name[0].given[0] + " " + d.consultant.name[0].family);
          d.requestDate = moment(d.requestedAt).format('MMM DD, YYYY LT');
          d.requestId = d.consultationNo;
        });

        dispatch({
          field: "consultationNotesArray",
          value: xVal.reverse(),
        });
      } else if (key === "labRequest") {
        console.log('selectedRecord::labRequest', val);
        console.log('labRequest', val);
        val.map((d) => {
          d.requester = d.requestedBy && d.requestedBy.name[0].given[0] + " " + d.requestedBy.name[0].family;
          d.requestDate = moment(d.requestedAt).format('MMM DD, YYYY LT');

          let servicesNames = [];

          let statusesCodes = 'Completed';
          let urgentCodes = 'No';

          Object.entries(d.services).map(([key, val]) => {
            servicesNames.push(val.name)
            if (val.status === "pending"){
              statusesCodes = 'Pending';
            }
          });

          d.serviceName = servicesNames.join(',');
          d.statusesCodes = statusesCodes;
          d.urgentCodes = urgentCodes;
        });
        dispatch({
          field: "labRequestArray",
          value: val.reverse(),
        });
      } else if (key === "careStream") {
        val.map((d) => d.name);
        dispatch({
          field: "careStreamArray",
          value: val.reverse(),
        });
      } else if (key === "chiefComplaint") {
        dispatch({
          field: "chiefComplaintArray",
          value: val.reverse(),
        });
      } else if (key === "radRequest") {


        val.map((d) => {
          d.requester = d.requestedBy && d.requestedBy.name[0].given[0] + " " + d.requestedBy.name[0].family;
          d.requestDate = moment(d.requestedAt).format('MMM DD, YYYY LT');

          let servicesNames = [];

          let statusesCodes = 'Completed';
          let urgentCodes = 'No';

          Object.entries(d.services).map(([key, val]) => {
            servicesNames.push(val.name)
            if (val.status === "pending"){
              statusesCodes = 'Pending';
            }
            if (val.urgent === "Yes"){
              statusesCodes = 'Yes';
            }
          });

          d.serviceName = servicesNames.join(', ');
          d.statusesCodes = statusesCodes;
          d.urgentCodes = urgentCodes;
        });


        /*
        val.map(
          (d) => (
            (d.requester =
              d.requestedBy &&
              d.requestedBy.name[0].given[0] +
                " " +
                d.requestedBy.name[0].family),
            (d.serviceCode = d.serviceId && d.serviceId.identifier[0].value),
            (d.status = d.status)
          )
        );
        */
        dispatch({
          field: "radRequestArray",
          value: val.reverse(),
        });
      } else if (key === "pharmacyRequest") {


        val.map(
          (d) =>
            (d.requesterName = d.requestedBy
              ? d.requestedBy.name[0].given[0] +
                " " +
                d.requestedBy.name[0].family
              : "N/A")
        );

        if (val && val.length > 0) {
          let data = [];
          val.map((d) => {
            d.item.map((singleCode) => {
              let found = data.find((i) => i === singleCode.itemName);
              if (!found) {
                data.push(singleCode.itemName);
              }
            });
          });
          dispatch({
            field: "medicationArray",
            value: data.reverse(),
          });

          let xPharmacy = [];
          let xNonPharmacy = [];

          let xVal = val;
          xVal.map((d) => {

            d.isVerbalRequest = false;
            d.requesterName2 = d.requestedBy && d.requestedBy.name[0].given[0] + " " + d.requestedBy.name[0].family;

            if (d.currentRequester && d.requestedBy){
              if (d.currentRequester._id !== d.requestedBy._id){
                d.isVerbalRequest = true;
                if (d.currentRequester.name){
                  d.requesterName2 = d.currentRequester.name[0].given[0] + " " + d.currentRequester.name[0].family;
                }
              }
            }

            d.requesterName = d.requestedBy && d.requestedBy.name[0].given[0] + " " + d.requestedBy.name[0].family;
            d.requestDate = moment(d.createdAt).format('MMM DD, YYYY LT');
            d.requestId = d.pharmacyRequestNo;
            let servicesNames = [];

            let statusesCodes = 'Completed';
            let urgentCodes = 'No';
            let hasPendingAdministration = false;




            if(d.generatedFor && d.generatedFor === "non_pharmaceutical")
            {
              Object.entries(d.item).map(([key, val]) => {
                if (val.administeredDetails.length < val.requestedQty){
                  hasPendingAdministration = true;
                }
                servicesNames.push(val.itemId.name && val.itemId.name === ""?val.itemId.name.toLowerCase():val.itemId.description.toLowerCase())
              });
              d.itemNames = servicesNames.join(', ');
              d.pending_administration = hasPendingAdministration;
              xNonPharmacy.push(d);
            }else{
              Object.entries(d.item).map(([key, val]) => {
                if (val.administeredDetails.length < val.requestedQty){
                  hasPendingAdministration = true;
                }
                servicesNames.push(val.itemName.toLowerCase())
              });
              d.itemNames = servicesNames.join(', ');
              d.pending_administration = hasPendingAdministration;
              xPharmacy.push(d);
            }


          });

          dispatch({
            field: "pharmacyRequestArray",
            value: xPharmacy.reverse(),
          });

          dispatch({
            field: "nonpharmacyRequestArray",
            value: xNonPharmacy.reverse(),
          });
        }
      }
    } else {
      dispatch({ field: key, value: val });
    }
  });
}
