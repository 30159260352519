/*eslint-disable*/
import React, { useState, useEffect } from "react";
import CustomTable from "../../components/Table/Table";
import Header from "../../components/Header/Header";
import pharmaIcon from "../../assets/img/Pharma.png";
import "../../assets/jss/material-dashboard-react/components/loaderStyle.css";
import cookie from "react-cookies";
import Back from "../../assets/img/Back_Arrow.png";
import Notification from "../../components/Snackbar/Notification.js";

const tableHeadingForMedicines = [
    "Medicine Name",
    "Duration",
    "Dosage",
    "Additional Note",
    " "
];
const tableDataKeysForMedicines = [
    "itemName",
    "duration",
    "dosage",
    "additionalNotes"
];

const tableHeadingForNotes = [
    "Date/Time",
    "Description/Notes",
    "Clinical Pharmacist Ref",
    " "
];
const tableDataKeysForNotes = [
    "completedAt",
    "pharmacistComments",
    "addedBy"
];

export default function ViewNotes(props) {

    // const [] = useState(cookie.load("current_user"));
    const [errorMsg, setErrorMsg] = useState("");
    const [openNotification, setOpenNotification] = useState(false);
    const [notesData, setNotesData] = useState([]);
    const [medicineData, setMedicineData] = useState([]);

    if (openNotification) {
        setTimeout(() => {
            setOpenNotification(false);
            setErrorMsg("");
        }, 2000);
    }

    useEffect(() => {

        const selectedItem = props.history.location.state.selectedRecord
        console.log("Props Passed in notes : ", selectedItem)

        var medsArray = []
        var notesArray = []
        for (let i = 0; i < selectedItem.length; i++) {
            Array.prototype.push.apply(medsArray, selectedItem[i].item);
            Array.prototype.push.apply(notesArray, selectedItem[i].reconciliationNotes);
        }
        medsArray.map((d) => (
            d.dosage = d.dosage ? d.dosage : '--',
            d.duration = d.duration ? d.duration + " Days" : '--'
        ))  
        notesArray.map((d) => (
            d.addedBy =  d.addedBy.name[0].given[0] + ' ' + d.addedBy.name[0].family
        ))
        setMedicineData(medsArray)
        setNotesData(notesArray)

    }, []);

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                flex: 1,
                position: "fixed",
                width: "100%",
                height: "100%",
                backgroundColor: "#60d69f",
                overflowY: "scroll",
            }}
        >
            <Header history={props.history} />

            <div className="cPadding">
                <div className="subheader">
                    <div>
                        <img src={pharmaIcon} />
                        <h4>Pharma notes</h4>
                    </div>
                </div>

                <div className='container-fluid'>

                    <div className="row" style={{ marginTop: "10px" }}>
                        <div className="col-md-12 col-sm-12 col-12">
                            {medicineData.length > 0 ? (
                                <CustomTable
                                    tableData={medicineData}
                                    tableDataKeys={tableDataKeysForMedicines}
                                    tableHeading={tableHeadingForMedicines}
                                    borderBottomColor={"#60d69f"}
                                    borderBottomWidth={20}
                                />
                            ) : (
                                    <div className='col-md-12 col-sm-12 col-12'>
                                        <h3
                                            style={{
                                                color: "white",
                                                textAlign: "center",
                                                margin: 'revert'
                                            }}
                                        >
                                            Opps! No Medicine Data Found
                                        </h3>
                                    </div>
                                )}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12 col-sm-12 col-12">
                            {notesData.length > 0 ?
                                <CustomTable
                                    tableData={notesData}
                                    tableDataKeys={tableDataKeysForNotes}
                                    tableHeading={tableHeadingForNotes}
                                    borderBottomColor={"#60d69f"}
                                    borderBottomWidth={20}
                                />
                                :
                                <div className='col-md-12 col-sm-12 col-12'>
                                    <h3
                                        style={{
                                            color: "white",
                                            textAlign: "center",
                                            margin: 'revert'
                                        }}
                                    >
                                        Opps! No Notes Data Found
                                    </h3>
                                </div>
                            }
                        </div>
                    </div>

                    <div className="row" style={{ marginBottom: 20 }}>
                        <div className="col-md-12 col-sm-12 col-12">
                            <img
                                onClick={() => props.history.goBack()}
                                src={Back}
                                style={{
                                    width: 45,
                                    height: 35,
                                    cursor: "pointer",
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <Notification msg={errorMsg} open={openNotification} />
        </div>
    );
}