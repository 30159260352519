import React from "react";
import "./dashboardDCD.css";
import TickIcon from "../../../assets/img/Tick Icon.png";
import useMediaQuery from "@material-ui/core/useMediaQuery";

export default function Box(props) {
  const matches = useMediaQuery("(min-width: 600px)");
  return (
    <div
      onClick={() => props.handleClick(props.title, props.dataCount)}
      className="dashboard-chunk-area"
      style={{
        backgroundColor: props.backgroundColor ? props.backgroundColor : "",
        height: matches ? (props.height1 ? props.height1 : 200) : "100px",
      }}
    >
      <div className="row" style={{ height: "40%" }}>
        <div className="col-md-12">
          <h4 style={{ color: "#fff", fontSize: matches ? "21px" : "16px" }}>
            {props.title}
          </h4>
          <p style={{ fontSize: matches ? "" : "11px", color: "#fff" }}>
            {props.detail}
          </p>
          
          {props.dataAmount ? (
            <p style={{ fontSize: matches ? "" : "11px", color: "#fff" }}>Total: {props.dataAmount}</p>
          ) : (
            ""
          )}
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            position: "absolute",
            top: 13,
            right: props.right1 ? props.right1 : 7,
            alignItems: "flex-end",
          }}
        >
          {/* If tick icon based on number of triage  */}
          {props.tickIcon ? (
            <img style={{ width: 25, height: 25 }} src={TickIcon} />
          ) : (
            undefined
          )}

          {/* <img style={{ width: 25, height: 25 }} src={TickIcon} /> */}
        </div>
      </div>

      <div className="row" style={{ display: "flex", flexDirection: "row" }}>
        <div
          className="col-md-6 col-sm-6 col-6 box-number"
          style={{
            display: props.display ? props.display : " ",
            justifyContent: props.justifyContent ? props.justifyContent : " ",
            marginTop: props.marginTop ? props.marginTop : " ",
            bottom: props.bottom ? props.bottom : " ",
          }}
        >
          <h3 style={{ fontSize: matches ? " " : 14 }}>{props.numberCount}</h3>
        </div>

        <div
          className="col-md-6 col-sm-6 col-6 box-icon"
          style={{
            right: props.right4Icon ? props.right4Icon : " ",
            bottom: props.bottom4Icon ? props.bottom4Icon : " ",
          }}
        >
          <img
            style={{ width: matches ? 90 : 30, height: matches ? 90 : 30 }}
            src={props.boxIcon}
          />
        </div>
      </div>
    </div>
  );
}
