/*eslint-disable*/
import React, { useState, useEffect, useReducer } from 'react'
import Button from '@material-ui/core/Button'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Notification from '../../components/Snackbar/Notification.js'
import CustomTable from '../../components/Table/Table'
import TextField from '@material-ui/core/TextField'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Loader from 'react-loader-spinner'
import AccountCircle from '@material-ui/icons/SearchOutlined'
import InputAdornment from '@material-ui/core/InputAdornment'
import MenuItem from '@material-ui/core/MenuItem'
import Fingerprint from '../../assets/img/fingerprint.png'
import BarCode from '../../assets/img/Bar Code.png'
import Header from '../../components/Header/Header'
import FlagManagementIcon from '../../assets/img/Housekeeping.png'
import eye_icon from '../../assets/img/Eye.png'
import Back from '../../assets/img/Back_Arrow.png'
import NewPatientIcon from '../../assets/img/New Patient.png'
import '../../assets/jss/material-dashboard-react/components/loaderStyle.css'
import cookie from 'react-cookies'
import { styles, useStyles, useStylesForTabs } from './styles.js'
import classNames from "classnames";
import { displayInHumanDifference, replacePatientIdIfNull } from "../../utils/commonFunc";
import axios from "axios";
import {
  getPorterPendingLabReq,
  getPorterCompletedLabReq,
  getPorterPendingPharmaReq,
  getPorterCompletedPharmaReq,
} from "../../public/endpoins";

import { useLocation } from "react-router-dom";

import {
  getCompletedPorterCall,
  getPendingPorterCall,
  updateStatus,
  updateSenseiStatusCall,
} from './networkCalls'

const tableHeading = [
  'MRN No',
  'Patient Name',
  'Room No',
  'Status',
  'Action',
  '',
]
const tableDataKeys = ['mrn', 'patientName', 'roomNo', 'status']

const tableHeadingForPorter = [
  'MRN No',
  'Patient Name',
  'Request ID',
  'Bundle/Test',
  'Requested By',
  'Room No',
  'status',
  'Action',
  '',
]
const tableDataKeysForPorter = ['mrn', 'patientName', 'labRequestId', 'serviceName',  'serviceRequestedBy', 'roomNo', 'status']

const actions = { edit: true }
const actions2 = { assign: true }

export default function PorterScreen(props) {
  const classesForTabs = useStylesForTabs()
  const classes = useStyles()
  const matches = useMediaQuery('(min-width:600px)')
  const [currentUser, setCurrentUser] = useState(cookie.load('current_user'))
  const [pendingSenseiArray, setPendingSenseiArray] = useState([])
  const [completedSenseiArray, setCompletedSenseiArray] = useState([])
  const [pendingPorterArray, setPendingPorterArray] = useState([])
  const [aceType, setAceType] = useState("pharma")
  
  const [
    completedPorterArray,
    setCompletedPorterArray,
  ] = useState([])

  const [loading, setLoading] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')
  const [openNotification, setOpenNotification] = useState(false)
  const [tabName, setTabName] = useState('transferPatient')
  const [buttonValue, setButtonValue] = useState('pending')
  const [cookies, setCookies] = useState(cookie.load('current_user'))

  const [searchQuery, setSearchQuery] = useState("");
  const [timer, setTimer] = useState(null);
  const [labRequests, setLabRequests] = useState([])
  const [ labRequest, setLabRequest ] = useState({});

  if (openNotification) {
    setTimeout(() => {
      setOpenNotification(false)
      setErrorMsg('')
    }, 2000)
  }

  useEffect(() => {
    const type=new URLSearchParams(props.location.search).get("type");
    console.log('xProps', type)
    setAceType(type)
    // let currentNurseId = currentUser._id;
    console.log('current user', currentUser._id)

    getPatientRemoteRequest('');

  }, [])

  // useEffect(() => {}, [tabName])

  const handleButtonChange = (buttonValue) => {
    console.log('buttonValue', buttonValue)
    console.log('ID', currentUser._id)
    setButtonValue(buttonValue)
    
    if (buttonValue === 'completed') {
      props.history.push({
        pathname: `/dashboard/home/portercompleted`,
      });  
      props.history.go();
    }else if (buttonValue === 'pending') {
      props.history.push({
        pathname: `/dashboard/home/porter`,
      });  
      props.history.go();
    }
  }

  const handlePauseLabSearch = (e) => {
    // setLoadSearchedData(true);
    // clearTimeout(timer);

    const a = e.target.value.replace(/[^\w\s]/gi, "");
    setSearchQuery(a);

    setTimer(
      setTimeout(() => {
        getPatientRemoteRequest(a);
        /*labServiceSearchCall(
          a,
          setItemFoundSuccessfully,
          setItemFound,
          setLoadSearchedData
        );*/
      }, 600)
    );
  };


  const getPatientRemoteRequest = (sQuery) => {

    let searchURL = (aceType==="pharma"?getPorterPendingPharmaReq:getPorterPendingLabReq) + '/'+ currentUser._id;
    if ( props.history.location.pathname === '/dashboard/home/portercompleted' ) {
      searchURL = (aceType==="pharma"?getPorterCompletedPharmaReq:getPorterCompletedLabReq) + '/' + currentUser._id
    }
    if (sQuery){
      searchURL = searchURL + '?searchKeyword=' + sQuery;
    }

    axios
      .get(searchURL)
      .then((res) => {
        console.log('Pending task', res)
        if (res.data.success) {
          res.data.data.map((val) => {
            val.patientName = val.patientId.name[0].given[0] + " " + val.patientId.name[0].family;
            val.roomNo = val.room[0].roomId.roomNo;
            val.mrn = val.patientId.identifier[0].value;
            val.episodeNo = val.requestNo;

            let servicesNames = [];
            if (aceType === "pharma"){
              // Services
              Object.entries(val.pharmacyRequest.item).map(([key, val]) => {
                servicesNames.push(val.name)
              });
              val.status = val.pharmacyRequest.porterStatus;
              val.labRequestId = val.pharmacyRequest.pharmacyRequestNo;
              val.labRequestUID = val.pharmacyRequest._id;
              // (val.serviceName = val.labRequest.serviceId.category + ': ' + val.labRequest.serviceId.name;
              // val.serviceRequestedBy = val.labRequest.requestedBy.name[0].given[0] + ' ' + val.labRequest.requestedBy.name[0].family;
              // val.labRequestId = val.labRequest.requestNo;
            }else{
              Object.entries(val.labRequest.services).map(([key, val]) => {
                servicesNames.push(val.name)
              });
              val.status = val.labRequest.nurseTechnicianStatus;
              val.labRequestId = val.labRequest.requestId;
              val.labRequestUID = val.labRequest._id;
              // (val.serviceName = val.labRequest.serviceId.category + ': ' + val.labRequest.serviceId.name;
              // val.serviceRequestedBy = val.labRequest.requestedBy.name[0].given[0] + ' ' + val.labRequest.requestedBy.name[0].family;
              // val.labRequestId = val.labRequest.requestNo;
            }
            val.serviceName = servicesNames.join(',');




            // Age
            var today = new Date();
            var birthDate = new Date(val.patientId.birthDate);
            var age_now = today.getFullYear() - birthDate.getFullYear();
            var m = today.getMonth() - birthDate.getMonth();
            if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
              age_now--;
            }
            val.ageNow = age_now;
          });
          console.log('patient name', res.data.data)
          // var allLabSorted = _.sortBy(res.data.data, "createdAt").reverse();
          setLabRequests(res.data.data.reverse())
        } else if (!res.data.success) {
          setErrorMsg(res.data.error)
          setOpenNotification(true)
        }
        return res
      })
      .catch((e) => {
        console.log('error: ', e)
      })
  }

  function handletransferPatient(rec) {
    console.log('rec', rec)
    props.history.push({
      pathname: '/dashboard/home/patienttransfer',
      state: {
        selectedRecord: rec,
        //   selectedItem: props.history.location.state,
      },
    })
    // if (rec.task === "To Be Clean") {
    //   const params = {
    //     requestId: rec._id,
    //     task: rec.task === "To Be Clean" ? "Cleaned" : "",
    //     roomId: rec.roomId._id,
    //   };
    //   updateSenseiStatusCall(props, params, setOpenNotification, setErrorMsg);
    // } else {
    //   setOpenNotification(true);
    //   setErrorMsg("Status is already cleaned");
    // }
  }
  function handleUnderObserved() {
    setOpenNotification(true)
    setErrorMsg('Already Observed patient')
  }

  const handleView = (rec) => {
    let url = (aceType === "pharma"?`/dashboard/home/porter/pharma-details/${rec._id}/${rec.pharmacyRequest._id}`:`/dashboard/home/porter/details/${rec._id}/${rec.labRequest.requestId}`)
    props.history.push({
      pathname: url,
    });
  }

  function handleAssign(rec) {
    console.log('rec', rec)
    console.log('rec', rec.labRequest.nurseTechnicianStatus)

    if (rec.labRequest.nurseTechnicianStatus === 'Not Collected') {
      const params = {
        staffId: currentUser._id,
        edrId: rec._id,
        // task: rec.task === "To Be Clean" ? "Cleaned" : "",
      }
      updateStatus(props, params, setOpenNotification, setErrorMsg)
    } else {
      setOpenNotification(true)
      setErrorMsg('Already Collected Lab Sample....Thanks')
    }
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        position: 'fixed',
        width: '100%',
        height: '100%',
        backgroundColor: '#60d69f',
        overflowY: 'scroll',
      }}
    >
      <Header history={props.history} />

      <div className='cPadding'>
        <div className='subheader'>
          <div>
            <img src={NewPatientIcon} style={{ marginLeft: '0px' }} />
            <h4>Sample Delivery Requests</h4>
          </div>

          <div>
            <div className="mr-2">
              <div class="dropdown">
                <button class="btn btn-primary dropdown-toggle btn-lg" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{aceType==="pharma"?"Pharma Delivery":"Sample Delivery"}</button>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <a class="dropdown-item" href={props.history.location.pathname === '/dashboard/home/portercompleted'?'/dashboard/home/portercompleted?type=sample':'/dashboard/home/porter?type=sample'}>Switch to Sample Delivery</a>
                  <a class="dropdown-item" href={props.history.location.pathname === '/dashboard/home/portercompleted'?'/dashboard/home/portercompleted?type=pharma':'/dashboard/home/porter?type=pharma'}>Switch to Pharma Delivery</a>
                </div>
              </div>
            </div>
            {props.history.location.pathname === '/dashboard/home/porter' ? (
              <Button
                onClick={() => handleButtonChange('completed')}
                style={{
                  ...styles.stylesForButton,
                  fontSize: matches ? ' ' : 10,
                }}
                variant='contained'
                color='primary'
              >
                <img src={eye_icon} className='icon-view' />
                &nbsp;&nbsp;
                <strong>Completed Tasks</strong>
              </Button>
            ) : (
              <Button
                onClick={() => handleButtonChange('pending')}
                style={{
                  ...styles.stylesForButton,
                  fontSize: matches ? ' ' : 10,
                }}
                variant='contained'
                color='primary'
              >
                <img src={eye_icon} className='icon-view' />
                &nbsp;&nbsp;
                <strong>Pending Tasks</strong>
              </Button>
            )}
          </div>
        </div>

        <div
          className='container-fluid'
          style={{ paddingLeft: 0, paddingRight: 0 }}
        >

          <div className='row row-5'>

            <div className="col-12">
              <div className="form-group" style={{ ...styles.inputContainerForTextField, marginTop: '10px' }}>
                <TextField
                  required
                  label="Patient MRN/Episode/Request Number or Name"
                  name={"searchQuery"}
                  value={searchQuery}
                  onChange={handlePauseLabSearch}
                  className="textInputStyle"
                  variant="filled"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <AccountCircle />
                      </InputAdornment>
                    ),
                    className: classes.input,
                    classes: { input: classes.input },
                    disableUnderline: true,
                  }}
                  InputLabelProps={{
                    className: classes.label,
                    classes: { label: classes.label },
                  }}
                />
              </div>
            </div>


            {labRequests !== ' ' ? (
              <>
              {
                Object.entries(labRequests).map((obj, entry) => {
                  return (
                    <div className="col-12 col-md-6 col-lg-6 col-xl-6">
                      <div className="card card-detail-info card-detail-small mb-2">
                        <div className="row row-5">
                          <div className="col-sm-12"><strong>Request #:</strong> <span>{obj[1].labRequestId}</span> &nbsp; <strong>PA/Bed#:</strong> <span>{obj[1].roomNo}</span></div>
                          <div className="col-sm-6  mt-3"><strong>Epi.#:</strong> <span>{obj[1].episodeNo}</span></div>
                          <div className="col-sm-6  mt-3"><strong>MRN:</strong> <span>{obj[1].mrn}</span></div>
                          <div className="col-sm-10 mt-3"><strong>Patient:</strong> <span>{obj[1].patientName} / {obj[1].gender==="Female"?"F":"M"} / {obj[1].ageNow}y</span></div>
                          <div className="col-sm-2  mt-3 text-right"><strong>T:</strong> <span>0:00</span></div>
                          {
                            aceType === "pharma"?
                            <div className="col-sm-12 mt-3"><strong>Items:</strong> <span>{obj[1].serviceName}</span></div>:undefined
                          }
                        </div>
                        <div className="card-status-action">
                          <span className={classNames(`badge`, `status-`+obj[1].status)}>{obj[1].status}</span>
                          { 
                            obj[1].urgent==="Yes"?<span className="ml-info">!</span>:undefined
                          }
                          <i className="ml-view ml-10 zmdi zmdi-eye zmdi-hc-2x" onClick={() => {
                            handleView(obj[1]);
                          }} />
                        </div>
                      </div>
                    </div>
                  )
                })
              }
              <Notification msg={errorMsg} open={openNotification} />

              <div className="col-12" style={{ marginBottom: 20, marginTop: 50 }}>
                <img
                  onClick={() => props.history.goBack()}
                  src={Back}
                  style={{ width: 45, height: 35, cursor: 'pointer' }}
                />
              </div>
              </>
            ) : (
              // <div className='LoaderStyle'>
              //   <Loader type='TailSpin' color='red' height={50} width={50} />
              // </div>
              <div className='row ' style={{ marginTop: '25px' }}>
                <div className='col-11'>
                  <h3
                    style={{
                      color: 'white',
                      textAlign: 'center',
                      width: '100%',
                      position: 'absolute',
                    }}
                  >
                    Opps...No Data Found
                  </h3>
                </div>
                <div className='col-1' style={{ marginTop: 45 }}>
                  {/* <img
                    onClick={() => props.history.goBack()}
                    src={Back_Arrow}
                    style={{
                      maxWidth: '60%',
                      height: 'auto',
                      cursor: 'pointer',
                    }}
                  /> */}
                </div>
              </div>
            )}


            
          </div>
        </div>

        <Notification msg={errorMsg} open={openNotification} />
      </div>
    </div>
  )
}
