/*eslint-disable*/
import React, { useState, useEffect, useReducer } from "react";
// import "./staffStyles.js";
import Button from "@material-ui/core/Button";
import Notification from "../../components/Snackbar/Notification.js";
import CustomTable from "../../components/Table/Table";
import Loader from "react-loader-spinner";
import Header from "../../components/Header/Header";
import business_Unit from "../../assets/img/Patients.png";
import plus_icon from "../../assets/img/Plus.png";
import Back from "../../assets/img/Back_Arrow.png";
import "../../assets/jss/material-dashboard-react/components/loaderStyle.css";
import cookie from "react-cookies";

import getTimeFromDate from "../../utils/extractTimeFromDate";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { makeStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";

import {
  getAllPatients,
  getSearchParamedics,
} from "./networkCallsForParamedics";

import TextField from "@material-ui/core/TextField";

import { assignPatientStyles } from "./styles";

import InputAdornment from "@material-ui/core/InputAdornment";
import AccountCircle from "@material-ui/icons/SearchOutlined";

import BarCode from "../../assets/img/Bar Code.png";

import Fingerprint from "../../assets/img/fingerprint.png";
import Filter from "../../assets/img/Filter.png";
import QRCodeScannerComponent from "../../components/QRCodeScanner/QRCodeScanner";

const styles = {
  textFieldPadding: {
    paddingTop: 5,
    paddingLeft: 3,
    paddingRight: 3,
  },
};
const useStyles = makeStyles((theme) => ({
  input: {
    "&:focus": {
      backgroundColor: "white",
      boxShadow: "none",
      borderRadius: 5,
    },
  },
  root: {
    "& .Mui-focused": {
      backgroundColor: "white",
      color: "black",
      boxShadow: "none",
    },
    "&:focus": {
      backgroundColor: "white",
      boxShadow: "none",
    },
    "& .MuiFormLabel-root": {
      fontSize: "10px",
      paddingRight: "45px",
    },
  },
}));

const tableHeadingForDoctors = [
  "Request No",
  "MRN",
  "Patient Name",
  "Gender",
  "Age",
  "Phone",
  "Action",
];
const tableDataKeysForDoctors = [
  "requestNo",
  "mrn",
  "name",
  "gender",
  "age",
  "phone",
];

const actions = { view: true };

export default function Staff(props) {
  const matches = useMediaQuery("(min-width:600px)");
  const classes = assignPatientStyles();
  const classes1 = useStyles();

  const [staffData, setStaffData] = useState("");
  const [generalArray, setGeneralArray] = useState("");

  const [errorMsg, setErrorMsg] = useState("");
  const [openNotification, setOpenNotification] = useState(false);

  const [searchPatientQuery, setSearchPatientQuery] = useState("");

  const [QRCodeScanner, setQRCodeScanner] = useState(false);

  if (openNotification) {
    setTimeout(() => {
      setOpenNotification(false);
      setErrorMsg("");
    }, 2000);
  }

  useEffect(() => {
    getAllPatients(
      setStaffData,
      setGeneralArray,
      setOpenNotification,
      setErrorMsg
    );
  }, []);

  function setValuesInData(generalArray) {
    console.log("inside function", generalArray);
    let senseiData = generalArray.map((d) => {
      let obj = {
        ...d,
        mrn: d.patientId.identifier[d.patientId.identifier.length - 1].value,
        name:
          d.patientId.name[d.patientId.name.length - 1].given[0] +
          " " +
          d.patientId.name[d.patientId.name.length - 1].family,
        age: d.patientId.age,
        gender: d.patientId.gender,
        phone: d.patientId.telecom[2] ? d.patientId.telecom[2].value : "N/A",
      };
      return obj;
    });

    setStaffData([...senseiData.reverse()]);
  }

  useEffect(() => {
    if (generalArray) {
      setValuesInData(generalArray);
    }
  }, [generalArray]);

  const setDialogOpen = () => {
    setFilterDialog(true);
  };

  function handleViewSinglePatient(patientObj) {
    console.log(patientObj);
    props.history.push({
      pathname: "/dashboard/home/viewpatient",
      state: {
        selectedItem: {
          ...patientObj.patientId,
          chiefComplaint: patientObj.chiefComplaint,
          roomNo: patientObj.roomNo,
          labRequest: patientObj.labRequest,
          radiologyRequest: patientObj.radiologyRequest,
          careStream: patientObj.careStream,
        },

        comingFor:
          props.history.location.pathname ===
          "/dashboard/home/patientmgmt/patienthistory"
            ? "patient history"
            : "",
      },
    });
  }

  const handlePatientSearch = (e) => {
    const a = e.target.value.replace(/[^\w+\s]/gi, "");
    setSearchPatientQuery(a);
    if (a.length >= 3) {
      getSearchParamedics(a, setGeneralArray, setErrorMsg, setOpenNotification);
    } else if (a.length == 0) {
      getAllPatients(
        setStaffData,
        setGeneralArray,
        setOpenNotification,
        setErrorMsg
      );
    }
  };

  function scanQRCode() {
    setQRCodeScanner((pervState) => !pervState);
  }

  function handleScanQR(data) {
    setQRCodeScanner(false);
    console.log(JSON.parse(data));
    if (JSON.parse(data).profileNo) {
      handlePatientSearch({
        target: {
          value: JSON.parse(data).profileNo,
          type: "text",
        },
      });
    }
    //  else if (JSON.parse(data).insuranceCardNumber) {
    //   dispatch({
    //     field: "insuranceNo",
    //     value: JSON.parse(data).insuranceCardNumber,
    //   });
    // }
  }

  if (QRCodeScanner) {
    return (
      <div>
        {QRCodeScanner ? (
          <QRCodeScannerComponent
            handleScanQR={handleScanQR}
            scanQRCode={scanQRCode}
          />
        ) : (
          undefined
        )}
      </div>
    );
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        position: "fixed",
        width: "100%",
        height: "100%",
        backgroundColor: "#60d69f",
        overflowY: "scroll",
      }}
    >
      <Header history={props.history} />

      <div className="cPadding">
        <div className="subheader">
          <div
            style={{
              display: "flex",
              flex: 1,
            }}
          >
            <img src={business_Unit} />
            <h4>Paramedics Patients</h4>
          </div>
        </div>

        <div
          style={{
            flex: 4,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            className={`${"container-fluid"}`}
            style={{
              marginTop: "25px",
              paddingLeft: "10px",
              paddingRight: "10px",
            }}
          >
            <div className={`row  ${classes1.root} ${classes1.input} `}>
              <div
                className="col-md-10  col-8"
                style={{ paddingTop: 5, paddingLeft: 3, paddingRight: 3 }}
              >
                <TextField
                  className="textInputStyle"
                  id="searchPatientQuery"
                  type="text"
                  variant="filled"
                  label={
                    matches
                      ? "Search Patient by Name/MRN/National ID/Mobile No"
                      : "Search Patient by Name/MRN "
                  }
                  name={"searchPatientQuery"}
                  value={searchPatientQuery}
                  onChange={handlePatientSearch}
                  InputLabelProps={{
                    classes: {
                      root: classes.label,
                      focused: classes.focusedLabel,
                      error: classes.erroredLabel,
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <AccountCircle />
                      </InputAdornment>
                    ),
                    className: classes.input,
                    classes: { input: classes.input },
                    disableUnderline: true,
                  }}
                />
              </div>

              <div
                className="col-md-1 col-sm-2 col-2"
                style={{
                  ...styles.textFieldPadding,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "white",
                    borderRadius: 5,
                    height: 55,
                  }}
                >
                  <img
                    src={BarCode}
                    style={{ width: 70, height: 60 }}
                    onClick={scanQRCode}
                  />
                </div>
              </div>

              <div
                className="col-md-1 col-sm-1 col-2"
                style={{
                  ...styles.textFieldPadding,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "white",
                    borderRadius: 5,
                    height: 55,
                  }}
                >
                  <img src={Fingerprint} style={{ maxWidth: 43, height: 43 }} />
                </div>
              </div>
            </div>
          </div>

          {generalArray && staffData ? (
            <div className="container-fluid">
              <div className="row">
                <CustomTable
                  tableData={staffData}
                  tableDataKeys={tableDataKeysForDoctors}
                  tableHeading={tableHeadingForDoctors}
                  action={actions}
                  borderBottomColor={"#60d69f"}
                  borderBottomWidth={20}
                  handleView={handleViewSinglePatient}
                />

                <Notification msg={errorMsg} open={openNotification} />
              </div>
            </div>
          ) : (
            <div className="LoaderStyle">
              <Loader type="TailSpin" color="red" height={50} width={50} />
            </div>
          )}

          <div className="container-fluid">
            <div className="row" style={{ marginBottom: 20 }}>
              <img
                onClick={() => props.history.goBack()}
                src={Back}
                style={{
                  width: 45,
                  height: 35,
                  cursor: "pointer",
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
