import React, { useEffect, useState, useReducer } from "react";
import cookie from "react-cookies";
import Button from "@material-ui/core/Button";
import MicIcon from "@material-ui/icons/Mic";
import StopIcon from "@material-ui/icons/Stop";
import MicRecorder from "mic-recorder-to-mp3";
import { styles, useStyles } from "./styles";
import TextField from "@material-ui/core/TextField";
import { Grid } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import useStylesForInput from "../../assets/jss/material-dashboard-react/inputStyle";
import Loader from "react-loader-spinner";
import Back from "../../assets/img/Back_Arrow.png";
import axios from "axios";
import Notification from "../../components/Snackbar/Notification.js";
import ConfirmationDialog from "../../components/confirmationDialog/confirmationDialog";

import {
  addReconciliationNotesURL,
  audioURL,
  updateReconciliationNotesURL,
  completeReconciliationNotesURL,
} from "../../public/endpoins";

const Mp3Recorder = new MicRecorder({ bitRate: 128 });

const initialState = {
  _id: "",
  date: new Date(),
  status: "pending",
  pharmacistComments: "",
  requesterComments: "",

  isBlocked: false,
  isRecording: false,

  blobURL: "",
  fileAudio: null,
  audioUrl: "",

  addedBy: "",
  pharmacistAudioNotes: "",
};

export default function AddReconciliationRequest(props) {
  const matches = useMediaQuery("(min-width:600px)");
  const classes = useStyles();

  function reducer(state, { field, value }) {
    return {
      ...state,
      [field]: value,
    };
  }

  const [state, dispatch] = useReducer(reducer, initialState);

  const {
    _id,
    date,
    status,
    pharmacistComments,
    requesterComments,
    isBlocked,
    isRecording,
    blobURL,
    fileAudio,
    audioUrl,
    addedBy,
    pharmacistAudioNotes,
  } = state;

  const [viewRecord, setViewRecord] = useState(false);
  const [currentUser] = useState(cookie.load("current_user"));
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setsuccessMsg] = useState("");
  const [openNotification, setOpenNotification] = useState(false);
  const [comingFor, setComingFor] = useState("");
  const [showConfirmationModel, setShowConfirmationModel] = useState(false);
  const [selectedPharmacyRequest, setSelectedPharmacyRequest] = useState("");

  useEffect(() => {
    setComingFor(props.history.location.state.comingFor);
    setSelectedPharmacyRequest(
      props.history.location.state.selectedPharmacyReqObj
    );

    if (
      props.history.location.state.comingFor === "view" ||
      props.history.location.state.comingFor === "edit"
    ) {
      const selectedItem = props.history.location.state.selectedItem;
      console.log("medicines", selectedItem);
      // setMedicineData(selectedItem.item);
      // setReconciliationNotes(selectedItem.reconciliationNotes);

      Object.entries(selectedItem).map(([key, val]) => {
        if (val && typeof val === "object") {
          dispatch({ field: key, value: val });
        } else {
          if (key === "pharmacistAudioNotes" && key !== "") {
            dispatch({ field: "blobURL", value: audioURL + "/" + val });
            dispatch({ field: "pharmacistAudioNotes", value: val });
          } else {
            dispatch({ field: key, value: val });
          }
        }
      });
    }
  }, []);

  const onChangeValue = (e) => {
    dispatch({
      field: e.target.name,
      value: e.target.value.replace(/[^\w.\s]/gi, ""),
    });
  };

  const start = (comingFor) => {
    if (isBlocked) {
      console.log("Permission Denied");
    } else {
      Mp3Recorder.start()
        .then(() => {
          dispatch({ field: "isRecording", value: true });
        })
        .catch((e) => console.error(e));
    }
  };

  const stop = () => {
    Mp3Recorder.stop()
      .getMp3()
      .then(([buffer, blob]) => {
        const blobURL = URL.createObjectURL(blob);
        console.log("blobURL", blobURL);
        dispatch({ field: "blobURL", value: blobURL });
        dispatch({ field: "isRecording", value: false });
        var file = new File([blob], "first", { type: "audio/mp3" });
        dispatch({ field: "fileAudio", value: file });
      })
      .catch((e) => console.log(e));
  };

  function handleAddNote() {
    if (pharmacistComments !== "") {
      const data = new FormData();
      data.append("file", fileAudio);

      const object = {
        addedBy: currentUser._id,
        edrId: props.history.location.state.edrId,
        pharmacistComments,
        pharmacyRequestId: props.history.location.state.pharmacyRequestId,
        status: "pending",
      };

      console.log("Params", object);
      data.append("data", JSON.stringify(object));

      if (isRecording) {
        setOpenNotification(true);
        setErrorMsg("Please record voice then submit.");
      } else {
        axios
          .put(addReconciliationNotesURL, data)
          .then((res) => {
            if (res.data.success) {
              console.log("response after", res.data);
              props.history.go(-3);
            } else if (!res.data.success) {
              setOpenNotification(true);
              setErrorMsg("Error while updating the status");
            }
          })
          .catch((e) => {
            setOpenNotification(true);
            setErrorMsg("Error while updating the status");
          });
      }
    } else {
      setOpenNotification(true);
      setErrorMsg("Please add your comments.");
    }
  }

  function handleUpdateNote() {
    if (status === "complete") {
      setOpenNotification(true);
      setErrorMsg("Notes can not updated once completed");
      return;
    }

    if (pharmacistComments !== "") {
      const data = new FormData();
      data.append("file", fileAudio);

      const object = {
        _id,
        addedBy: addedBy._id,
        edrId: props.history.location.state.edrId,
        pharmacistComments,
        pharmacyRequestId: props.history.location.state.pharmacyRequestId,
        status,
        pharmacistAudioNotes,
      };

      console.log("Params", object);
      data.append("data", JSON.stringify(object));

      if (isRecording) {
        setOpenNotification(true);
        setErrorMsg("Please record voice then submit.");
      } else {
        axios
          .put(updateReconciliationNotesURL, data)
          .then((res) => {
            if (res.data.success) {
              console.log("response after", res.data);
              props.history.go(-3);
            } else if (!res.data.success) {
              setOpenNotification(true);
              setErrorMsg("Error while updating the status");
            }
          })
          .catch((e) => {
            setOpenNotification(true);
            setErrorMsg("Error while updating the status");
          });
      }
    } else {
      setOpenNotification(true);
      setErrorMsg("Please add your commnets.");
    }
  }

  function handleCompleteNote() {
    if (requesterComments !== "") {
      const data = new FormData();
      data.append("file", fileAudio);

      const object = {
        _id,
        edrId: props.history.location.state.edrId,
        requesterComments,
        pharmacyRequestId: props.history.location.state.pharmacyRequestId,
        status: "complete",
        completedAt: new Date(),
      };

      console.log("Params", object);
      data.append("data", JSON.stringify(object));

      if (isRecording) {
        setOpenNotification(true);
        setErrorMsg("Please record voice then submit.");
      } else {
        axios
          .put(completeReconciliationNotesURL, data)
          .then((res) => {
            if (res.data.success) {
              console.log("response after", res.data);
              props.history.go(-3);
            } else if (!res.data.success) {
              setOpenNotification(true);
              setErrorMsg("Error while completing the note.");
            }
          })
          .catch((e) => {
            setOpenNotification(true);
            setErrorMsg("Error while completing the note.");
          });
      }
    } else {
      setOpenNotification(true);
      setErrorMsg("Please add your commnets.");
    }
  }

  function onCompleteNote() {
    if (selectedPharmacyRequest.requestedBy._id !== currentUser._id) {
      setOpenNotification(true);
      setErrorMsg(
        "You can only complete notes for those pharmacy requests which are added by you."
      );
    } else if (status === "complete") {
      setOpenNotification(true);
      setErrorMsg("Notes can not updated once completed");
    } else if (requesterComments === "") {
      setOpenNotification(true);
      setErrorMsg("Please add your notes.");
    } else {
      setShowConfirmationModel(true);
    }
  }

  if (openNotification) {
    setTimeout(() => {
      setOpenNotification(false);
      setErrorMsg("");
      setsuccessMsg("");
    }, 3500);
  }

  return (
    <div style={{ padding: 15 }}>
      <div className="container-fluid">
        <h5>Add Reconciliation Note</h5>

        <div className="row">
          <div
            className="col-12"
            style={{
              ...styles.inputContainerForTextField,
              ...styles.textFieldPadding,
            }}
          >
            <TextField
              // disabled={props.disableFields ? true : false}
              label="Pharmacist Comments"
              name={"pharmacistComments"}
              value={pharmacistComments}
              className="textInputStyle"
              type={"text"}
              variant="filled"
              InputProps={{
                className: classes.input,
                classes: { input: classes.input },
              }}
              onChange={onChangeValue}
            />
          </div>
        </div>

        <div className="row">
          <div
            className="col-12"
            style={{
              ...styles.inputContainerForTextField,
              ...styles.textFieldPadding,
            }}
          >
            <TextField
              // disabled={props.disableFields ? true : false}
              label="Requester Comments"
              name={"requesterComments"}
              value={requesterComments}
              className="textInputStyle"
              type={"text"}
              variant="filled"
              InputProps={{
                className: classes.input,
                classes: { input: classes.input },
              }}
              onChange={onChangeValue}
            />
          </div>
        </div>

        <div className="row">
          <div
            className="col-md-12 col-sm-12 col-12"
            style={{
              ...styles.inputContainerForTextField,
              padding: matches ? "" : "0px",
            }}
          >
            <Grid
              justify="flex-start" // Add it here :)
              container
              // spacing={24}
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                // backgroundColor: "white",
                marginLeft: 0,
                marginRight: 0,
                marginTop: 15,
                borderRadius: 5,
                height: 125,
                marginBottom: 15,
                paddingLeft: 10,
                // justifyContent: "center",
              }}
            >
              <div>
                {!isRecording ? (
                  <audio
                    style={{
                      marginRight: 10,
                      width: matches ? 450 : 220,
                      marginTop: 6,
                    }}
                    src={blobURL}
                    controls="controls"
                  />
                ) : (
                  <div
                    style={{
                      marginLeft: matches ? 110 : 18,
                      marginRight: matches ? 110 : 75,
                      width: matches ? 550 : 100,
                    }}
                  >
                    <Loader
                      type="ThreeDots"
                      color="blue"
                      height={40}
                      width={40}
                      //3 secs
                    />
                  </div>
                )}
              </div>

              <div
                style={{
                  marginRight: matches ? 15 : 7,
                  backgroundColor: isRecording ? "#DC004E" : "#2C6DDD",
                  height: matches ? 55 : 50,
                  borderRadius: 30,
                  width: matches ? 55 : 50,
                  paddingLeft: matches ? 10 : 8,
                }}
              >
                {isRecording ? (
                  <StopIcon
                    style={{
                      marginTop: matches ? 11 : 9,
                      color: "white",
                      cursor: "pointer",
                    }}
                    fontSize="large"
                    onClick={!viewRecord ? () => stop("") : () => {}}
                  />
                ) : (
                  <MicIcon
                    style={{
                      marginTop: matches ? 12 : 10,
                      color: "white",
                      cursor: "pointer",
                    }}
                    fontSize="large"
                    onClick={!viewRecord ? () => start("") : () => {}}
                  />
                )}
              </div>
            </Grid>
          </div>
        </div>

        <div
          className="row"
          style={{
            display: "flex",
            justifyContent: "flex-end",
            paddingLeft: 2,
            paddingRight: 2,
          }}
        >
          <div style={{ marginTop: "2%", marginBottom: "2%" }}>
            <Button
              onClick={() => props.history.goBack()}
              style={
                {
                  // ...styles.stylesForButton,
                }
              }
              variant="outlined"
            >
              <strong>Cancel</strong>
            </Button>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "2%",
              marginBottom: "2%",
              marginLeft: 10,
            }}
          >
            <Button
              style={{
                ...styles.stylesForSubmitButton,
              }}
              onClick={
                currentUser.staffType === "Clinical Pharmacist"
                  ? comingFor === "add"
                    ? handleAddNote
                    : handleUpdateNote
                  : onCompleteNote
              }
              variant="contained"
            >
              Submit
            </Button>
          </div>
        </div>

        <div className="row" style={{ marginBottom: 20 }}>
          <div className="col-md-12 col-sm-12 col-12">
            <img
              onClick={() => props.history.goBack()}
              src={Back}
              style={{
                width: 45,
                height: 35,
                cursor: "pointer",
              }}
            />
          </div>
        </div>
      </div>

      {showConfirmationModel ? (
        <ConfirmationDialog
          dialogMessage={
            "You will not be able to update the notes after completing it. Are you sure want to complete it?"
          }
          dialogHeading={"Complete Request"}
          onClickDone={handleCompleteNote}
          onClickCancel={setShowConfirmationModel}
        />
      ) : (
        undefined
      )}
      <Notification msg={errorMsg} open={openNotification} />
    </div>
  );
}
