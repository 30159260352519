import React from "react";
import { Route, Switch } from "react-router-dom";
import NotFound from "../components/NotFound/NotFound";
import PatientManagement from "../views/Home/SenseiSubMenus/PatientManagement/PatientManagement";
import ViewRequestRoutes from "./ViewRequests";
import EpisodeRegistration from "../views/PatientRegistrationForSensei/EpisodeRegistration";
import EpisodeRegistrationSuccess from "../views/PatientRegistrationForSensei/EpisodeRegistrationSuccess";
import AssignPatients from "../views/DCD/Assign/AssignPatients";
import CCAssign from "../views/DCD/Assign/CCAssign";
import PatientList from "../views/CustomerCare/PatientList";
import PendingPatients from "../views/PendingPatients/PendingPatients";
import AllPatients from "../views/AllPatients/AllPatients";
import ViewPatient from "../views/DCD/ViewForm/ViewPatientDetails/ViewPatientDetails";
import AssignCheifComplaint from "../views/DCD/Assign/CCAssign";
import SuccessScreen from "../components/SuccessScreen/SuccessScreen";
import PatientTransfersRoutes from "./patientTransfers";
import CustomerCareList from "../views/CustomerCare/CustomerCareList";
import CareStreamTable from "../views/CareStreamAssign/CSTable";
import CareStreamMain from "../views/CareStreamAssign/CareStreamMain";
// import Consultants from '../views/Consultations/Consultants'
import Patients from "../views/Consultations/Patients";
import PatientsDetails from "../views/Consultations/PatientsRequestsDetailPage";
import PatientsDetailsPharmaNonPharma from "../views/Consultations/PharmacyHistoryMedicationPage";
import PatientListWithCareStream from "../views/PatientList/PatientList";
// import ReconciliationRequest from "../views/ReconsiliationRequests/reconciliationRequests";
import CareStreamInProgress from "../views/CareStreamInProgress/CSinProgress";
import CareStreamRoutes from "./careStream";

class PatientManagementRoutes extends React.PureComponent {
  render() {
    return (
      <Switch>
        <Route
          exact
          path={`${this.props.match.url}`}
          component={PatientManagement}
        />
        <Route
          path={`${this.props.match.url}/CareStreamInProgress`}
          component={CareStreamInProgress}
        />
        <Route
          path={`${this.props.match.url}/CareStream`}
          component={CareStreamRoutes}
        />
        <Route
          path={`${this.props.match.url}/viewrequests`}
          component={ViewRequestRoutes}
        />
        <Route
          path={`${this.props.match.url}/patientTransfers`}
          component={PatientTransfersRoutes}
        />
        <Route
          exact
          path={`${this.props.match.url}/episodeRegistration`}
          component={EpisodeRegistration}
        />

        <Route
          exact
          path={`${this.props.match.url}/success`}
          component={EpisodeRegistrationSuccess}
        />

        <Route
          exact
          path={`${this.props.match.url}/assignDCDForm`}
          component={AssignPatients}
        />

        <Route
          exact
          path={`${this.props.match.url}/assignDCDForm/assignCC`}
          component={CCAssign}
        />

        <Route
          exact
          path={`${this.props.match.url}/pendingpatients`}
          component={PendingPatients}
        />

        <Route
          exact
          path={`${this.props.match.url}/allpatients`}
          component={AllPatients}
        />

        {/* <Route
          exact
          path={`${this.props.match.url}/reconciliationrequest`}
          component={ReconciliationRequest}
        /> */}

        <Route
          exact
          path={`${this.props.match.url}/pendingpatients/viewpatients`}
          component={ViewPatient}
        />
        <Route
          exact
          path={`${this.props.match.url}/pendingpatients/viewpatients/assigncheifcomplaint`}
          component={AssignCheifComplaint}
        />
        <Route
          exact
          path={`${this.props.match.url}/customerCare`}
          component={PatientList}
        />

        <Route
          exact
          path={`${this.props.match.url}/customerCare/assignCustomerC`}
          component={CustomerCareList}
        />

        <Route
          exact
          path={`${this.props.match.url}/careStreamPatients`}
          component={CareStreamTable}
        />

        <Route
          exact
          path={`${this.props.match.url}/careStreamPatients/assign`}
          component={CareStreamMain}
        />

        <Route
          exact
          path={`${this.props.match.url}/careStreamPatients/assign/printAssignCS`}
          component={CareStreamMain}
        />

        <Route
          exact
          path={`${this.props.match.url}/consultations/:edrId/details/:serviceId/medication-history`}
          component={PatientsDetailsPharmaNonPharma}
        />

        <Route
          exact
          path={`${this.props.match.url}/consultations/:edrId/details/:serviceId`}
          component={PatientsDetails}
        />

        <Route
          exact
          path={`${this.props.match.url}/consultations/completed`}
          component={Patients}
        />

        <Route
          exact
          path={`${this.props.match.url}/consultations`}
          component={Patients}
        />

        <Route
          exact
          path={`${this.props.match.url}/consultations/patients`}
          component={Patients}
        />

        <Route
          exact
          path={`${this.props.match.url}/patientlist`}
          component={PatientListWithCareStream}
        />

        <Route path={`${this.props.match.url}/notfound`} component={NotFound} />

        <Route path="*" component={NotFound} />
      </Switch>
    );
  }
}
export default PatientManagementRoutes;
