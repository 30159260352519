import React, { useState, useEffect } from "react";
import Notification from "../../components/Snackbar/Notification.js";
import CustomTable from "../../components/Table/Table";
import axios from "axios";
import _ from "lodash";
import Button from "@material-ui/core/Button";

import Loader from "react-loader-spinner";
import Back from "../../assets/img/Back_Arrow.png";
import Header from "../../components/Header/Header";
import Lab_OPR from "../../assets/img/Customer Care Services.png";
import ButtonField from "../../components/common/Button";
import Rad_OPR from "../../assets/img/RR.png";
import Pharmacist_OPR from "../../assets/img/PHR.png";
import Fingerprint from "../../assets/img/fingerprint.png";
import AccountCircle from "@material-ui/icons/SearchOutlined";
import InputAdornment from "@material-ui/core/InputAdornment";
import BarCode from "../../assets/img/Bar Code.png";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import "../../assets/jss/material-dashboard-react/components/loaderStyle.css";
import socketIOClient from "socket.io-client";
import cookie from "react-cookies";
import { assignPatientStyles } from "../CustomerCare/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {
  getEdrPatientsForCC,
  searchEdrPatientsForCC,
  assignCustomerCareCC,
  assignRoomToPatientForTransfer,
} from "./networkCallsForPatientTransfer";

const tableHeading = [
  "CCID",
  "Customer Care Name",
  "Gender",
  // "Age",
  // "Date/Time",
  "Status",
  "Action",
];
const tableDataKeys = [
  "mrn",
  "patientName",
  "gender",
  // "age",
  // "createdAt",
  "availability",
];

const styles = {
  textFieldPadding: {
    paddingLeft: 5,
    paddingRight: 5,
  },
};

const actions = { assigned: true };

export default function EDR(props) {
  const matches = useMediaQuery("(min-width:600px)");
  const classes = assignPatientStyles();

  const [labInPatient, setlabInPatient] = useState([]);
  const [currentUser, setCurrentUser] = useState(cookie.load("current_user"));
  const [errorMsg, setErrorMsg] = useState("");
  const [openNotification, setOpenNotification] = useState(false);
  const [searchPatientQuery, setSearchPatientQuery] = useState("");

  if (openNotification) {
    setTimeout(() => {
      setOpenNotification(false);
      setErrorMsg("");
    }, 2000);
  }

  useEffect(() => {
    getPatientData();
  }, []);

  function getPatientData() {
    getEdrPatientsForCC(setlabInPatient, setErrorMsg, setOpenNotification);
  }

  // console.log("PropsProps", props.history.location.state.selectedItem);
  function handleView(rec) {
    console.log("REC Customer Care", rec.availability);

    let reason = "The patient needs to be transfered from ED to EOU.";
    let to = "EOU";
    let from = "ED";

    if (
      props.history.location.pathname ===
      "/dashboard/home/patientmanagement/patientTransfers/EDfromEOU/customercarelist"
    ) {
      reason = "The patient needs to be transfered from EOU to ED.";
      to = "ED";
      from = "EOU";
    }

    var object = {
      assignedBy: currentUser._id,
      staffId: rec._id,
      edrId: props.history.location.state.selectedItem._id,
      reason: reason,
      to: to,
      from: from,
    };
    if (rec.availability == "Assigned") {
      setOpenNotification(true);
      setErrorMsg(
        `${rec.name[0].given + " " + rec.name[0].family} is already assigned`
      );
    } else {
      if (from === "EOU") {
        let params = {
          edrId: props.history.location.state.selectedItem._id,
          roomId: props.history.location.state.selectedRoom,
          assignedBy: currentUser._id,
          reason: reason,
        };
        assignRoomToPatientForTransfer(
          params,
          setlabInPatient,
          setErrorMsg,
          setOpenNotification
        );
      }
      assignCustomerCareCC(
        object,
        setlabInPatient,
        setErrorMsg,
        setOpenNotification
      );
    }
  }

  const handlePatientSearch = (e) => {
    const a = e.target.value.replace(/[^\w\s]/gi, "");
    setSearchPatientQuery(a);
    if (a.length >= 3) {
      searchEdrPatientsForCC(
        a,
        setlabInPatient,
        setErrorMsg,
        setOpenNotification
      );
    } else if (a.length == 0) {
      getPatientData();
    }
  };

  const addNewItem = () => {
    props.history.push({
      pathname: "patientregistration",
      state: { comingFor: "add" },
    });
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        position: "fixed",
        width: "100%",
        height: "100%",
        backgroundColor: "rgb(19 213 159)",
        overflowY: "scroll",
      }}
    >
      <Header history={props.history} />

      <div className="cPadding">
        <div className="subheader">
          <div>
            <img src={Lab_OPR} />
            <h4>Customer Care</h4>
          </div>
        </div>

        <div
          className={`${"container-fluid"} ${classes.root}`}
          style={{
            marginTop: "25px",
            paddingLeft: "10px",
            paddingRight: "10px",
          }}
        >
          <div className="row">
            <div className="col-12" style={styles.textFieldPadding}>
              <TextField
                className="textInputStyle"
                id="searchPatientQuery"
                type="text"
                variant="filled"
                label="Search Customer Care by Name / ID "
                name={"searchPatientQuery"}
                value={searchPatientQuery}
                onChange={handlePatientSearch}
                InputLabelProps={{
                  classes: {
                    root: classes.label,
                    focused: classes.focusedLabel,
                    error: classes.erroredLabel,
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <AccountCircle />
                    </InputAdornment>
                  ),
                  className: classes.input,
                  classes: { input: classes.input },
                  disableUnderline: true,
                }}
              />
            </div>
          </div>
        </div>

        <div
          style={{
            flex: 4,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div>
            {labInPatient !== " " ? (
              <div>
                <div>
                  <CustomTable
                    tableData={labInPatient}
                    tableDataKeys={tableDataKeys}
                    tableHeading={tableHeading}
                    action={actions}
                    handleView={handleView}
                    borderBottomColor={"#60d69f"}
                    borderBottomWidth={20}
                  />
                </div>
                <div style={{ marginTop: 20, marginBottom: 20 }}>
                  <img
                    onClick={() => props.history.goBack()}
                    src={Back}
                    style={{
                      width: 45,
                      height: 35,
                      cursor: "pointer",
                    }}
                  />
                </div>
                <Notification msg={errorMsg} open={openNotification} />
              </div>
            ) : (
              <div className="row " style={{ marginTop: "25px" }}>
                <div className="col-11">
                  <h3
                    style={{
                      color: "white",
                      textAlign: "center",
                      width: "100%",
                      position: "absolute",
                    }}
                  >
                    Opps...No Data Found
                  </h3>
                </div>
                <div className="col-1" style={{ marginTop: 45 }}>
                  <img
                    onClick={() => props.history.goBack()}
                    src={Back}
                    style={{
                      maxWidth: "60%",
                      height: "auto",
                      cursor: "pointer",
                    }}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
