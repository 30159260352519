/*eslint-disable*/
import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Notification from "../../components/Snackbar/Notification.js";
import CustomTable from "../../components/Table/Table";
import Loader from "react-loader-spinner";
import Header from "../../components/Header/Header";
import TransferIcon from "../../assets/img/EOU.png";
import eye_icon from "../../assets/img/Eye.png";
import Back from "../../assets/img/Back_Arrow.png";
import "../../assets/jss/material-dashboard-react/components/loaderStyle.css";
import cookie from "react-cookies";
import { styles, useStyles } from "./styles.js";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import {
  getPatientsInDept,
  getAllTransferRequests,
} from "./networkCallsForPatientTransfer";

const tableHeading = [
  "MRN No",
  "Patient Name",
  "Chief Complaint",
  "Room No",
  "Customer Care",
  "Status",
  "",
];

const tableDataKeys = ["mrn", "name", "area", "room", "customerCare", "status"];

const actionsForPending = { edit: false };
const actionsForCompleted = { view: false };

export default function EOUfromED(props) {
  const matches = useMediaQuery("(min-width:600px)");
  const [currentUser] = useState(cookie.load("current_user"));
  const [generalArray, setGeneralArray] = useState([]);
  const [filteredResponse, setFilteredResponse] = useState("");

  const [errorMsg, setErrorMsg] = useState("");
  const [openNotification, setOpenNotification] = useState(false);
  const [statusName, setStatusName] = useState("Pending");
  const [openEditModal, setOpenEditModal] = useState(false);
  const [selectedPatient, setSelectedPatient] = useState("");
  const [patientsInDept, setPatientsInDept] = useState("");

  if (openNotification) {
    setTimeout(() => {
      setOpenNotification(false);
      setErrorMsg("");
    }, 2000);
  }

  useEffect(() => {
    getAllTransferRequests(
      setGeneralArray,
      setOpenNotification,
      setErrorMsg,
      currentUser._id
    );
  }, []);

  useEffect(() => {
    let to = "EOU";

    if (
      props.history.location.pathname ===
      "/dashboard/home/patientmanagement/patientTransfers/EDfromEOU/viewrequests"
    ) {
      to = "ED";
    }

    let res = generalArray.filter(
      (req) => req.status !== "completed" && req.to === to
    );
    setFilteredResponse(res);
  }, [generalArray]);

  const handleChange = (e, tabValue) => {
    setStatusName(tabValue);
    let to = "EOU";

    if (
      props.history.location.pathname ===
      "/dashboard/home/patientmanagement/patientTransfers/EDfromEOU/viewrequests"
    ) {
      to = "ED";
    }

    let res = [];
    if (tabValue === "Pending") {
      res = generalArray.filter(
        (req) => req.status !== "completed" && req.to === to
      );
    } else {
      res = generalArray.filter(
        (req) => req.status === "completed" && req.to === to
      );
      console.log("res", res);
    }
    setFilteredResponse(res);
  };

  function handleView(e) {
    console.log("View ", e);
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        position: "fixed",
        width: "100%",
        height: "100%",
        backgroundColor: "#60d69f",
        overflowY: "scroll",
      }}
    >
      <Header history={props.history} />

      <div className="cPadding">
        <div className="subheader">
          <div>
            <img src={TransferIcon} style={{ marginLeft: "0px" }} />

            {props.history.location.pathname ===
            "/dashboard/home/patientmanagement/patientTransfers/EDfromEOU/viewrequests" ? (
              <h4>Transfer Requests for ED</h4>
            ) : (
              <h4>Transfer Requests for EOU</h4>
            )}
          </div>

          {statusName === "Pending" ? (
            <Button
              onClick={(e) => handleChange(e, "Completed")}
              style={styles.stylesForButton}
              variant="contained"
              color="primary"
            >
              <img src={eye_icon} className="icon-view" />
              &nbsp;&nbsp;
              <strong style={{ fontSize: matches ? " " : 10 }}>
                View Completed
              </strong>
            </Button>
          ) : (
            <Button
              onClick={(e) => handleChange(e, "Pending")}
              style={styles.stylesForButton}
              variant="contained"
              color="primary"
            >
              <img src={eye_icon} className="icon-view" />
              &nbsp;&nbsp;
              <strong style={{ fontSize: matches ? " " : 10 }}>
                Pending Transfers
              </strong>
            </Button>
          )}
        </div>

        <div className="container-fluid">
          <div className="row">
            {filteredResponse ? (
              <div
                className="col-md-12 col-sm-12 col-12"
                style={{ paddingLeft: 3, paddingRight: 0 }}
              >
                <CustomTable
                  tableData={filteredResponse}
                  tableDataKeys={tableDataKeys}
                  tableHeading={tableHeading}
                  action={""}
                  borderBottomColor={"#60d69f"}
                  borderBottomWidth={20}
                />
              </div>
            ) : (
              <div className="LoaderStyle">
                <Loader type="TailSpin" color="red" height={50} width={50} />
              </div>
            )}
          </div>

          <div className="row" style={{ marginBottom: 20 }}>
            <div className="col-md-12 col-sm-12 col-12">
              <img
                onClick={() => props.history.goBack()}
                src={Back}
                style={{
                  width: 45,
                  height: 35,
                  cursor: "pointer",
                }}
              />
            </div>
          </div>
        </div>

        <Notification msg={errorMsg} open={openNotification} />
      </div>
    </div>
  );
}
