import React, { useEffect, useState } from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Flip from "../../../components/Dial/Flip";
import TwoValue from "../../../components/Dial/TwoValue";
import OneValue from "../../../components/Dial/OneValue";
import Loader from "react-loader-spinner";
// import IPBeds from '../../assets/img/ipbeds.png'
// import EDBeds from '../../assets/img/edbeds.png'
import {
  getDialerGraphCall,
  getValuesCardCalls,
  getResponse,
} from "./NetworkCallForLabTech";
import { socketUrl } from "../../../public/endpoins";
import socketIOClient from "socket.io-client";

import { reportsLTDashboard } from "../../../public/endpoins";
import Notification from "../../../components/Snackbar/Notification.js";

export default function EDdoctor() {
  const matches = useMediaQuery("(min-width:600px)");
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setsuccessMsg] = useState("");
  const [openNotification, setOpenNotification] = useState(false);
  const [isLoading, setisLoading] = useState(true);
  const [responseData, setResponseData] = useState([]);

  //1st Card
  const [firstTotalPending, set1stTotalPending] = useState("");
  const [firstTotalPendingColor, set1stTotalPendingColor] = useState("");
  const [firstPendingTat, set1stPendingTat] = useState("");
  const [firstPendingTATColor, set1stPendingTATColor] = useState("");
  const [firstGraphPending, set1stGraphPending] = useState("");
  //2nd Card
  const [secondTotalPending, set2ndTotalPending] = useState("");
  const [secondTotalPendingColor, set2ndTotalPendingColor] = useState("");
  const [secondPendingTat, set2ndPendingTat] = useState("");
  const [secondPendingTATColor, set2ndPendingTATColor] = useState("");
  const [secondGraphPending, set2ndGraphPending] = useState("");
  //3rd Card
  const [thirdTotalPending, set3rdTotalPending] = useState("");
  const [thirdTotalPendingColor, set3rdTotalPendingColor] = useState("");
  const [thirdPendingTat, set3rdPendingTat] = useState("");
  const [thirdPendingTATColor, set3rdPendingTATColor] = useState("");
  const [thirdGraphPending, set3rdGraphPending] = useState("");
  //4th Card
  const [fourthTotalPending, set4thTotalPending] = useState("");
  const [fourthTotalPendingColor, set4thTotalPendingColor] = useState("");
  const [fourthPendingTat, set4thPendingTat] = useState("");
  const [fourthPendingTATColor, set4thPendingTATColor] = useState("");
  const [fourthGraphPending, set4thGraphPending] = useState("");
  //5th Card
  const [fifthTotalPending, set5thTotalPending] = useState("");
  const [fifthTotalPendingColor, set5thTotalPendingColor] = useState("");
  const [fifthPendingTat, set5thPendingTat] = useState("");
  const [fifthPendingTATColor, set5thPendingTATColor] = useState("");
  const [fifthGraphPending, set5thGraphPending] = useState("");

  //6th Card
  const [sixthTotalPending, set6thTotalPending] = useState("");
  const [sixthTotalPendingColor, set6thTotalPendingColor] = useState("");
  const [sixthPendingTat, set6thPendingTat] = useState("");
  const [sixthPendingTATColor, set6thPendingTATColor] = useState("");
  const [sixthGraphPending, set6thGraphPending] = useState("");

  //7th Card
  const [cumulativeTests, setCumulativeTests] = useState("");
  const [cumulativeTestsColor, setCumulativeTestsColor] = useState("");
  //8th Card
  const [cumulativeBloodTest, setCumulativeBloodTest] = useState("");
  const [cumulativeBloodTestColor, setCumulativeBloodTestColor] = useState("");

  const [flip, setFlip] = useState(false);

  const [flag1Count, setFlag1Count] = useState(0);
  const [flag2Count, setFlag2Count] = useState(0);
  const [flag3Count, setFlag3Count] = useState(0);
  const [flag4Count, setFlag4Count] = useState(0);

  useEffect(() => {
    getResponse(
      reportsLTDashboard,
      setResponseData,
      setOpenNotification,
      setErrorMsg,
      setisLoading
    );
    console.log("*in getResponse LabTechnician");

    const flagCount = [
      { value: "1st", setFlagCount: (value) => setFlag1Count(value) },
      { value: "2nd", setFlagCount: (value) => setFlag2Count(value) },
      { value: "3rd", setFlagCount: (value) => setFlag3Count(value) },
      { value: "4th", setFlagCount: (value) => setFlag4Count(value) },
    ];

    const socket = socketIOClient(socketUrl);
    socket.emit("labTechnician_flags");
    socket.on("ltPending", (data) => {
      console.log(
        "response coming through socket for lab technician Flags",
        data
      );
      flagCount.forEach(({ value, setFlagCount }) => {
        const cardCount = data.filter((item) => item.card === value).length;
        cardCount && setFlagCount(cardCount);
      });

      console.log("data", data);
    });
  }, []);

  if (openNotification) {
    setTimeout(() => {
      setOpenNotification(true);
      setErrorMsg(" ");
      setsuccessMsg("");
    }, 2000);
  }

  useEffect(() => {
    let arr = [];

    Object.entries(responseData).map(([key, val]) => {
      if (key !== "success") {
        console.log("key of data", key, "val of data", val);
        arr.push({ [key]: val });
      }
    });

    console.log("arr::", arr);
    if (arr && arr.length > 0) {
      getDialerGraphCall(
        3,
        6,
        45,
        60,
        arr[0].firstCard,
        set1stTotalPending,
        set1stTotalPendingColor,
        set1stPendingTat,
        set1stPendingTATColor,
        set1stGraphPending
      );
      getDialerGraphCall(
        3,
        5,
        10,
        20,
        arr[1].secondCard,
        set2ndTotalPending,
        set2ndTotalPendingColor,
        set2ndPendingTat,
        set2ndPendingTATColor,
        set2ndGraphPending
      );
      getDialerGraphCall(
        5,
        10,
        30,
        45,
        arr[2].thirdCard,
        set3rdTotalPending,
        set3rdTotalPendingColor,
        set3rdPendingTat,
        set3rdPendingTATColor,
        set3rdGraphPending
      );
      getDialerGraphCall(
        5,
        10,
        35,
        45,
        arr[3].fourthCard,
        set4thTotalPending,
        set4thTotalPendingColor,
        set4thPendingTat,
        set4thPendingTATColor,
        set4thGraphPending
      );
      getDialerGraphCall(
        10,
        15,
        35,
        65,
        arr[4].fifthCard,
        set5thTotalPending,
        set5thTotalPendingColor,
        set5thPendingTat,
        set5thPendingTATColor,
        set5thGraphPending
      );
      getDialerGraphCall(
        30,
        50,
        35,
        65,
        arr[5].sixthCard,

        set6thTotalPending,
        set6thTotalPendingColor,
        set6thPendingTat,
        set6thPendingTATColor,
        set6thGraphPending
      );
      getValuesCardCalls(
        15,
        20,
        arr[6].cumulativeTests,
        setCumulativeTests,
        setCumulativeTestsColor
      );
      getValuesCardCalls(
        25,
        30,
        arr[7].cumulativeBloodTest,
        setCumulativeBloodTest,
        setCumulativeBloodTestColor
      );
    }
  }, [responseData]);

  const flipIt = () => {
    setFlip(true);
  };
  return (
    <div className="container-fluid" style={{ marginBottom: 10 }}>
      <div className="row">
        <div
          className="col-md-4 col-6"
          style={{ paddingLeft: 4, paddingRight: 4, marginTop: 10 }}
        >
          <div>
            <Flip
              flipped={!flip}
              flipHandler={flipIt}
              mainHeading={"Sample Collections Pending"}
              value={
                isLoading ? (
                  <Loader
                    type="TailSpin"
                    color="green"
                    height={35}
                    width={35}
                  />
                ) : firstTotalPending === 0 || firstTotalPending === null ? (
                  "0"
                ) : (
                  firstTotalPending
                )
              }
              flagsCount={flag1Count}
              mainHeadingForGraph="Sample Collections Pending"
              color={firstTotalPendingColor}
              subHeading={"TAT"}
              childHeading={"Request to Collection"}
              time={
                isLoading ? (
                  <Loader
                    type="TailSpin"
                    color="green"
                    height={35}
                    width={35}
                  />
                ) : firstPendingTat === null || firstPendingTat === 0 ? (
                  "0"
                ) : (
                  firstPendingTat
                )
              }
              idForGraph={"container1"}
              data={firstGraphPending}
              timecolor={firstPendingTATColor}
            />
          </div>
        </div>

        <div
          className="col-md-4 col-6"
          style={{ paddingLeft: 4, paddingRight: 4, marginTop: 10 }}
        >
          <div>
            <Flip
              flipped={!flip}
              flipHandler={flipIt}
              mainHeading={"Test Results Pending"}
              value={
                isLoading ? (
                  <Loader
                    type="TailSpin"
                    color="green"
                    height={35}
                    width={35}
                  />
                ) : secondTotalPending === 0 || secondTotalPending === null ? (
                  "0"
                ) : (
                  secondTotalPending
                )
              }
              flagsCount={flag2Count}
              mainHeadingForGraph="Test Results Pending"
              color={secondTotalPendingColor}
              subHeading={"TAT"}
              childHeading={"Collection to Results"}
              time={
                isLoading ? (
                  <Loader
                    type="TailSpin"
                    color="green"
                    height={35}
                    width={35}
                  />
                ) : secondPendingTat === null || secondPendingTat === 0 ? (
                  "0"
                ) : (
                  secondPendingTat
                )
              }
              idForGraph={"container2"}
              data={secondGraphPending}
              timecolor={secondPendingTATColor}
            />
          </div>
        </div>

        <div
          className="col-md-4 col-6"
          style={{ paddingLeft: 4, paddingRight: 4, marginTop: 10 }}
        >
          <div>
            <Flip
              flipped={!flip}
              flipHandler={flipIt}
              mainHeading={"Blood Sample Collections Pending"}
              value={
                isLoading ? (
                  <Loader
                    type="TailSpin"
                    color="green"
                    height={35}
                    width={35}
                  />
                ) : thirdTotalPending === 0 || thirdTotalPending === null ? (
                  "0"
                ) : (
                  thirdTotalPending
                )
              }
              flagsCount={flag3Count}
              mainHeadingForGraph="Blood Sample Collections Pending"
              color={thirdTotalPendingColor}
              subHeading={"TAT"}
              childHeading={"Request to Collection"}
              time={
                isLoading ? (
                  <Loader
                    type="TailSpin"
                    color="green"
                    height={35}
                    width={35}
                  />
                ) : thirdPendingTat === null || thirdPendingTat === 0 ? (
                  "0"
                ) : (
                  thirdPendingTat
                )
              }
              idForGraph={"container3"}
              data={thirdGraphPending}
              timecolor={thirdPendingTATColor}
            />
          </div>
        </div>
        {/* </div> */}

        {/* <div className='row'> */}
        <div
          className="col-md-4 col-6"
          style={{ paddingLeft: 4, paddingRight: 4, marginTop: 10 }}
        >
          <div>
            <Flip
              flipped={!flip}
              flipHandler={flipIt}
              mainHeading={"Blood Test Results Pending"}
              value={
                isLoading ? (
                  <Loader
                    type="TailSpin"
                    color="green"
                    height={35}
                    width={35}
                  />
                ) : fourthTotalPending === 0 || fourthTotalPending === null ? (
                  "0"
                ) : (
                  fourthTotalPending
                )
              }
              flagsCount={flag4Count}
              mainHeadingForGraph="Blood Test Results Pending"
              color={fourthTotalPendingColor}
              subHeading={"TAT"}
              childHeading={"Collection to Results"}
              time={
                isLoading ? (
                  <Loader
                    type="TailSpin"
                    color="green"
                    height={35}
                    width={35}
                  />
                ) : fourthPendingTat === null || fourthPendingTat === 0 ? (
                  "0"
                ) : (
                  fourthPendingTat
                )
              }
              idForGraph={"container4"}
              data={fourthGraphPending}
              timecolor={fourthPendingTATColor}
            />
          </div>
        </div>

        <div
          className="col-md-4 col-6"
          style={{ paddingLeft: 4, paddingRight: 4, marginTop: 10 }}
        >
          <div>
            <Flip
              flipped={!flip}
              flipHandler={flipIt}
              mainHeading={"Average No. of Tests/Hour"}
              value={
                isLoading ? (
                  <Loader
                    type="TailSpin"
                    color="green"
                    height={35}
                    width={35}
                  />
                ) : fifthTotalPending === 0 || fifthTotalPending === null ? (
                  "0"
                ) : (
                  fifthTotalPending
                )
              }
              mainHeadingForGraph="Average No. of Tests/Hour"
              color={fifthTotalPendingColor}
              subHeading={"TAT"}
              childHeading={"Per Test"}
              time={
                isLoading ? (
                  <Loader
                    type="TailSpin"
                    color="green"
                    height={35}
                    width={35}
                  />
                ) : fifthPendingTat === null || fifthPendingTat === 0 ? (
                  "0"
                ) : (
                  fifthPendingTat
                )
              }
              idForGraph={"container5"}
              data={fifthGraphPending}
              timecolor={fifthPendingTATColor}
            />
          </div>
        </div>

        <div
          className="col-md-4 col-6"
          style={{ paddingLeft: 4, paddingRight: 4, marginTop: 10 }}
        >
          <div>
            <Flip
              flipped={!flip}
              flipHandler={flipIt}
              mainHeading={"Average No. of Blood Tests/Hour"}
              value={
                isLoading ? (
                  <Loader
                    type="TailSpin"
                    color="green"
                    height={35}
                    width={35}
                  />
                ) : sixthTotalPending === 0 || sixthTotalPending === null ? (
                  "0"
                ) : (
                  sixthTotalPending
                )
              }
              mainHeadingForGraph="Average No. of Blood Tests/Hour"
              color={sixthTotalPendingColor}
              subHeading={"TAT"}
              childHeading={"Per Test"}
              time={
                isLoading ? (
                  <Loader
                    type="TailSpin"
                    color="green"
                    height={35}
                    width={35}
                  />
                ) : sixthPendingTat === null || sixthPendingTat === 0 ? (
                  "0"
                ) : (
                  sixthPendingTat
                )
              }
              idForGraph={"container6"}
              data={sixthGraphPending}
              timecolor={sixthPendingTATColor}
            />
          </div>
        </div>

        {/* </div> */}

        <div
          className="col-md-4 col-6"
          style={{ paddingLeft: 4, paddingRight: 4, marginTop: 10 }}
        >
          <div>
            <OneValue
              time={
                isLoading ? (
                  <Loader
                    type="TailSpin"
                    color="green"
                    height={35}
                    width={35}
                  />
                ) : cumulativeTests === null || cumulativeTests === 0 ? (
                  "0"
                ) : (
                  cumulativeTests
                )
              }
              colorTime={cumulativeTestsColor}
              heading={"Cumulative total Tests"}
            />
          </div>
        </div>

        <div
          className="col-md-4 col-6"
          style={{ paddingLeft: 4, paddingRight: 4, marginTop: 10 }}
        >
          <div>
            <OneValue
              time={
                isLoading ? (
                  <Loader
                    type="TailSpin"
                    color="green"
                    height={35}
                    width={35}
                  />
                ) : cumulativeBloodTest === null ||
                  cumulativeBloodTest === 0 ? (
                  "0"
                ) : (
                  cumulativeBloodTest
                )
              }
              colorTime={cumulativeBloodTestColor}
              heading={"Cumulative total Blood Tests Collected"}
            />
          </div>
        </div>
      </div>
      <Notification
        msg={errorMsg}
        open={openNotification}
        success={successMsg}
      />
    </div>
  );
}
