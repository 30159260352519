import React from "react";
import Avatar from "@material-ui/core/Avatar";
import Badge from "@material-ui/core/Badge";
import FileViewer from "react-file-viewer";

import { makeStyles, withStyles, fade } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  avatar: {
    height: 50,
    width: 50,
  },
  badgeImage: {
    backgroundColor: "#FF0C0C",
    color: "white",
  },
}));
export default function Reciever(props) {
  const classes = useStyles();

  return (
    <div className={classes.root} style={{ marginTop: 20 }}>
      <Avatar
        alt="Remy Sharp"
        src="/static/images/avatar/1.jpg"
        className={classes.avatar}
      />
      <div
        style={{
          paddingLeft: 7,
          paddingRight: 7,
          paddingTop: 3,
          paddingBottom: 3,
          alignItems: "center",
          display: "flex",
          borderRadius: 5,
          backgroundColor: "#f3f3f3",
          maxWidth: "50%",
        }}
      >
        {props.type === "image/jpg" ? (
          <FileViewer fileType={"jpg"} filePath={props.recieve} />
        ) : props.type === "video/mp4" ? (
          <FileViewer fileType={"mp4"} filePath={props.recieve} />
        ) : props.type === "image/png" ? (
          <FileViewer fileType={"png"} filePath={props.recieve} />
        ) : props.type === "image/jpeg" ? (
          <FileViewer fileType={"jpeg"} filePath={props.recieve} />
        ) : props.type === "audio/mp3" ? (
          // <audio controls>
          //   <source src={props.recieve} type="audio/mp3" />
          // </audio>
          <FileViewer fileType={"mp3"} filePath={props.recieve} />
        ) : (
          <span>{props.recieve}</span>
        )}
      </div>
    </div>
  );
}
