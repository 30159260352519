import axios from "axios";
import {
  getAllPatientsInDept,
  assingCustomerCareAndInitiateTransfer,
  getAllCustomerCares,
  searchCustomerCare,
  getAllTransferRequestsForRequester,
  assignRoomToPatient,
} from "../../public/endpoins";

export const getPatientsInDept = (
  setPatientsInDept,
  setOpenNotification,
  setErrorMsg,
  currentDept
) => {
  axios
    .get(getAllPatientsInDept + "/" + currentDept)
    .then((res) => {
      if (res.data.success) {
        let updatedResponse = [];

        console.log(res.data.data);
        for (let i = 0; i < res.data.data.length; i++) {
          let obj = {
            ...res.data.data[i],
            mrn: res.data.data[i].patientId.identifier[0].value,
            name:
              res.data.data[i].patientId.name[0].given[0] +
              " " +
              res.data.data[i].patientId.name[0].family,
            area:
              res.data.data[i].chiefComplaint.length > 0
                ? res.data.data[i].chiefComplaint[
                    res.data.data[i].chiefComplaint.length - 1
                  ].chiefComplaintId.name
                : "N/A",
            room:
              res.data.data[i].room.length > 0
                ? res.data.data[i].room[res.data.data[i].room.length - 1].roomId
                    .roomNo
                : "N/A",
            // status: "",
          };
          updatedResponse.push(obj);
        }
        console.log(updatedResponse);
        setPatientsInDept(updatedResponse.reverse());
      } else if (!res.data.success) {
        setOpenNotification(true);
        setErrorMsg("Error while getting records.");
      }
    })
    .catch((e) => {
      console.log("Error while adding staff", e);
      setOpenNotification(true);
      setErrorMsg("Error while getting records.");
    });
};

export const getEdrPatientsForCC = (
  setlabInPatient,
  setErrorMsg,
  setOpenNotification
) => {
  axios
    .get(getAllCustomerCares)
    .then((res) => {
      if (res.data.success) {
        res.data.data.map(
          (d) => (d.patientName = d.name[0].given[0] + " " + d.name[0].family)
        );
        res.data.data.map((d) => {
          d.mrn = d.identifier[0].value;
          d.availability = d.availability ? "Un Assigned" : "Assigned";
        });
        // res.data.data.map((d) => (d.status = d.availability))
        console.log(res.data.data, "Customer Care");
        setlabInPatient(res.data.data);
      } else if (!res.data.success) {
        setErrorMsg(res.data.error);
        setOpenNotification(true);
      }
      return res;
    })
    .catch((e) => {
      console.log("error: ", e);
    });
};

export const assignCustomerCareCC = (
  object,
  setlabInPatient,
  setErrorMsg,
  setOpenNotification
) => {
  console.log("Object to assign customer care and start transfer", object);
  axios
    .put(assingCustomerCareAndInitiateTransfer, object)
    .then((res) => {
      if (res.data.success) {
        // res.data.data.map(
        //   (d) => (d.patientName = d.name[0].given[0] + " " + d.name[0].family),
        // )
        // res.data.data.map((d) => {
        //   d.mrn = d.identifier[0].value
        //   d.availability = d.availability ? "Un Assigned" : "Assigned"
        // })
        // // res.data.data.map((d) => (d.status = d.availability))
        // console.log(res.data.data, "Customer Care")
        // setlabInPatient(res.data.data)
        window.location.reload(true);
      } else if (!res.data.success) {
        setErrorMsg(res.data.error);
        setOpenNotification(true);
      }
      return res;
    })
    .catch((e) => {
      console.log("error: ", e);
      // setErrorMsg(e);
      // setOpenNotification(true);
    });
};

export const assignRoomToPatientForTransfer = (
  object,
  setlabInPatient,
  setErrorMsg,
  setOpenNotification
) => {
  console.log(
    "Object to assign room to patient for transfer to ED from EOU",
    object
  );
  axios
    .put(assignRoomToPatient, object)
    .then((res) => {
      if (res.data.success) {
        console.log(res.data.data);
      } else if (!res.data.success) {
        setErrorMsg(res.data.error);
        setOpenNotification(true);
      }
      return res;
    })
    .catch((e) => {
      console.log("error: ", e);
      setErrorMsg(e);
      setOpenNotification(true);
    });
};

export const searchEdrPatientsForCC = (
  a,
  setlabInPatient,
  setErrorMsg,
  setOpenNotification
) => {
  axios
    .get(searchCustomerCare + "/" + a)
    .then((res) => {
      console.log("res", res);
      if (res.data.success) {
        if (res.data.data) {
          console.log("patient dataaa", res.data.data);
          res.data.data.map(
            (d) => (d.patientName = d.name[0].given[0] + " " + d.name[0].family)
          );
          res.data.data.map((d) => (d.mrn = d.identifier[0].value));
          res.data.data.map((d) => {
            d.telecom.map((a) => {
              if (a.system === "phone") {
                return (d.phoneNo = a.value);
              }
            });
          });
          // var sortedObjs = _.sortBy(res.data.data, 'date').reverse()
          setlabInPatient(res.data.data);
        } else {
          setlabInPatient(" ");
        }
      } else if (!res.data.success) {
        setErrorMsg(res.data.error);
        setOpenNotification(true);
      }
    })
    .catch((e) => {
      console.log("error after searching patient request", e);
    });
};

export const getAllTransferRequests = (
  setlabInPatient,
  setErrorMsg,
  setOpenNotification,
  staffId
) => {
  axios
    .get(getAllTransferRequestsForRequester + "/" + staffId)
    .then((res) => {
      if (res.data.success) {
        let updatedResponse = [];

        console.log(res.data.data);
        for (let i = 0; i < res.data.data.length; i++) {
          let obj = {
            ...res.data.data[i],
            mrn: res.data.data[i].edrId.patientId.identifier[0].value,
            name:
              res.data.data[i].edrId.patientId.name[0].given[0] +
              " " +
              res.data.data[i].edrId.patientId.name[0].family,
            area:
              res.data.data[i].edrId.chiefComplaint[
                res.data.data[i].edrId.chiefComplaint.length - 1
              ].chiefComplaintId.name,
            room:
              res.data.data[i].edrId.room.length > 0
                ? res.data.data[i].edrId.room[
                    res.data.data[i].edrId.room.length - 1
                  ].roomId.roomNo
                : "N/A",
            // status: "",
            customerCare:
              res.data.data[i].requestedTo.name[0].given[0] +
              " " +
              res.data.data[i].requestedTo.name[0].family,
          };
          updatedResponse.push(obj);
        }
        setlabInPatient(updatedResponse);
      } else if (!res.data.success) {
        setErrorMsg(res.data.error);
        setOpenNotification(true);
      }
      return res;
    })
    .catch((e) => {
      console.log("error: ", e);
    });
};
