/*eslint-disable*/
import React, { useState, useEffect, useReducer } from "react";
import Button from "@material-ui/core/Button";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Notification from "../../components/Snackbar/Notification.js";
import CustomTable from "../../components/Table/Table";
import TextField from "@material-ui/core/TextField";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Loader from "react-loader-spinner";
import AccountCircle from "@material-ui/icons/SearchOutlined";
import InputAdornment from "@material-ui/core/InputAdornment";
import MenuItem from "@material-ui/core/MenuItem";
import Fingerprint from "../../assets/img/fingerprint.png";
import BarCode from "../../assets/img/Bar Code.png";
import Header from "../../components/Header/Header";
import FlagManagementIcon from "../../assets/img/Housekeeping.png";
import eye_icon from "../../assets/img/Eye.png";
import Back from "../../assets/img/Back_Arrow.png";
import "../../assets/jss/material-dashboard-react/components/loaderStyle.css";
import cookie from "react-cookies";
import { styles, useStyles, useStylesForTabs } from "./styles.js";
import {
  getPendingSenseiCall,
  getCompletedSenseiCall,
  getCompletedImgTechnicianCall,
  getPendingImgTechnicianCall,
  getEDNurseCall,
  updateStatus,
  updateSenseiStatusCall,
  updateEDNurseStatusCall,
} from "./networkCallsForHousekeeping";

const tableHeading = [
  "Request No",
  "Room No",
  "Production Area",
  "Date/Time",
  "Status",
  "Action",
];
const tableDataKeys = ["requestNo", "roomNo", "productionArea", "assignedTime", "task"];

const tableHeadingForImgTechnician = [
  "Room No",
  "Production Area",
  "Date/Time",
  "Status",
  "Action",
];
const tableDataKeysForImgTechnician = [
  "roomNo",
  "productionArea",
  "assignedTime",
  "task",
];

const actions = { assign: true };

export default function FlagMgmt(props) {
  const classesForTabs = useStylesForTabs();
  const classes = useStyles();
  const matches = useMediaQuery("(min-width:600px)");
  const [currentUser, setCurrentUser] = useState(cookie.load("current_user"));
  const [pendingSenseiArray, setPendingSenseiArray] = useState([]);
  const [completedSenseiArray, setCompletedSenseiArray] = useState([]);
  const [pendingImgTechnicianArray, setPendingImgTechnicianArray] = useState(
    []
  );
  const [edNurseArray, setEdNurseArray] = useState([]);
  const [
    completedImgTechnicianArray,
    setCompletedImgTechnicianArray,
  ] = useState([]);

  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [openNotification, setOpenNotification] = useState(false);
  const [tabName, setTabName] = useState("sensei");
  const [buttonValue, setButtonValue] = useState("pending");
  const [cookies, setCookies] = useState(cookie.load("current_user"));

  if (openNotification) {
    setTimeout(() => {
      setOpenNotification(false);
      setErrorMsg("");
    }, 2000);
  }

  useEffect(() => {
    if (
      props.history.location.pathname === "/dashboard/home/housekeepingrequest"
    ) {
      setButtonValue("completed");
      getCompletedSenseiCall(
        setCompletedSenseiArray,
        setErrorMsg,
        setOpenNotification
      );
    } else {
      getPendingSenseiCall(
        setPendingSenseiArray,
        setErrorMsg,
        setOpenNotification
      );
    }
  }, []);

  // useEffect(() => {}, [tabName])

  const handleButtonChange = (buttonValue) => {
    setButtonValue(buttonValue);
    if (tabName === "sensei") {
      if (buttonValue === "completed") {
        getCompletedSenseiCall(
          setCompletedSenseiArray,
          setErrorMsg,
          setOpenNotification
        );
      } else if (buttonValue === "pending") {
        getPendingSenseiCall(
          setPendingSenseiArray,
          setErrorMsg,
          setOpenNotification
        );
      }
    } else if (tabName === "edNurse") {
      getEDNurseCall(
        buttonValue,
        setEdNurseArray,
        setErrorMsg,
        setOpenNotification
      );
    } else {
      if (buttonValue === "completed") {
        getCompletedImgTechnicianCall(
          setCompletedImgTechnicianArray,
          setErrorMsg,
          setOpenNotification
        );
      } else if (buttonValue === "pending") {
        getPendingImgTechnicianCall(
          setPendingImgTechnicianArray,
          setErrorMsg,
          setOpenNotification
        );
      }
    }
  };

  const handleTabChange = (e, tabName) => {
    setTabName(tabName);
    // getPendingImgTechnicianCall(
    //   setPendingImgTechnicianArray,
    //   setErrorMsg,
    //   setOpenNotification
    // )
    if (tabName === "sensei") {
      if (buttonValue === "completed") {
        getCompletedSenseiCall(
          setCompletedSenseiArray,
          setErrorMsg,
          setOpenNotification
        );
      } else if (buttonValue === "pending") {
        getPendingSenseiCall(
          setPendingSenseiArray,
          setErrorMsg,
          setOpenNotification
        );
      }
    } else if (tabName === "edNurse") {
      getEDNurseCall(
        buttonValue,
        setEdNurseArray,
        setErrorMsg,
        setOpenNotification
      );
    } else {
      if (buttonValue === "completed") {
        getCompletedImgTechnicianCall(
          setCompletedImgTechnicianArray,
          setErrorMsg,
          setOpenNotification
        );
      } else if (buttonValue === "pending") {
        getPendingImgTechnicianCall(
          setPendingImgTechnicianArray,
          setErrorMsg,
          setOpenNotification
        );
      }
    }
  };

  function handleSenseiUpdate(rec) {
    if (rec.task === "To Be Clean") {
      const params = {
        requestId: rec._id,
        task: rec.task === "To Be Clean" ? "Cleaned" : "",
        roomId: rec.roomId._id,
      };
      updateSenseiStatusCall(props, params, setOpenNotification, setErrorMsg);
    } else {
      setOpenNotification(true);
      setErrorMsg("Status is already cleaned");
    }
  }

  function handleEDNurseUpdate(rec) {
    if (rec.task === "To Be Clean") {
      const params = {
        requestId: rec._id,
        task: rec.task === "To Be Clean" ? "Cleaned" : "",
      };
      updateEDNurseStatusCall(props, params, setOpenNotification, setErrorMsg);
    } else {
      setOpenNotification(true);
      setErrorMsg("Status is already cleaned");
    }
  }

  function handleAssign(rec) {
    if (rec.task === "To Be Clean") {
      const params = {
        requestId: rec._id,
        task: rec.task === "To Be Clean" ? "Cleaned" : "",
      };
      updateStatus(props, params, setOpenNotification, setErrorMsg);
    } else {
      setOpenNotification(true);
      setErrorMsg("Status is already cleaned");
    }
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        position: "fixed",
        width: "100%",
        height: "100%",
        backgroundColor: "#60d69f",
        overflowY: "scroll",
      }}
    >
      <Header history={props.history} />

      <div className="cPadding">
        <div className="subheader">
          <div>
            <img src={FlagManagementIcon} style={{ marginLeft: "0px" }} />
            <h4>Housekeeping</h4>
          </div>

          {buttonValue === "pending" ? (
            <Button
              onClick={() => handleButtonChange("completed")}
              style={{
                ...styles.stylesForButton,
                fontSize: matches ? " " : 10,
              }}
              variant="contained"
              color="primary"
            >
              <img src={eye_icon} className="icon-view" />
              &nbsp;&nbsp;
              <strong>Completed Tasks</strong>
            </Button>
          ) : (
            <Button
              onClick={() => handleButtonChange("pending")}
              style={{
                ...styles.stylesForButton,
                fontSize: matches ? " " : 10,
              }}
              variant="contained"
              color="primary"
            >
              <img src={eye_icon} className="icon-view" />
              &nbsp;&nbsp;
              <strong>Pending Tasks</strong>
            </Button>
          )}
        </div>

        <div
          className="container-fluid"
          style={{ paddingLeft: 0, paddingRight: 0 }}
        >
          <div className={classesForTabs.root}>
            <Tabs
              classes={{
                root: classesForTabs.root,
                scroller: classesForTabs.scroller,
              }}
              value={tabName}
              onChange={handleTabChange}
              textColor="primary"
              TabIndicatorProps={{ style: { background: "#12387a" } }}
              centered
            >
              <Tab
                style={{
                  color: "white",
                  borderRadius: 5,
                  outline: "none",
                  color: tabName === "sensei" ? "#12387a" : "#3B988C",
                }}
                label="Sensei"
                value="sensei"
              />
              <Tab
                style={{
                  color: "white",
                  borderRadius: 5,
                  outline: "none",
                  color: tabName === "imgTechnician" ? "#12387a" : "#3B988C",
                }}
                label="Imaging Technician"
                value="imgTechnician"
              />
              <Tab
                style={{
                  color: "white",
                  borderRadius: 5,
                  outline: "none",
                  color: tabName === "edNurse" ? "#12387a" : "#3B988C",
                }}
                label="ED Nurse"
                value="edNurse"
              />
            </Tabs>
          </div>

          <div className="row">
            {(pendingSenseiArray || completedSenseiArray) &&
            tabName === "sensei" ? (
              <div className="col-md-12 col-sm-12 col-12">
                {loading ? (
                  <div style={{ margin: "auto", width: "0%", padding: 10 }}>
                    <Loader
                      type="TailSpin"
                      color="blue"
                      height={50}
                      width={50}
                    />
                  </div>
                ) : (
                  <CustomTable
                    tableData={
                      buttonValue === "pending"
                        ? pendingSenseiArray
                        : completedSenseiArray
                    }
                    handleAssign={handleSenseiUpdate}
                    tableDataKeys={tableDataKeys}
                    tableHeading={tableHeading}
                    action={actions}
                    borderBottomColor={"#60d69f"}
                    borderBottomWidth={20}
                  />
                )}
              </div>
            ) : (completedImgTechnicianArray || pendingImgTechnicianArray) &&
              tabName === "imgTechnician" ? (
              <div className="col-md-12 col-sm-12 col-12">
                {loading ? (
                  <div style={{ margin: "auto", width: "0%", padding: 10 }}>
                    <Loader
                      type="TailSpin"
                      color="blue"
                      height={50}
                      width={50}
                    />
                  </div>
                ) : (
                  <CustomTable
                    tableData={
                      buttonValue === "pending"
                        ? pendingImgTechnicianArray
                        : completedImgTechnicianArray
                    }
                    tableDataKeys={tableDataKeysForImgTechnician}
                    tableHeading={tableHeadingForImgTechnician}
                    action={actions}
                    handleAssign={handleAssign}
                    borderBottomColor={"#60d69f"}
                    borderBottomWidth={20}
                  />
                )}
              </div>
            ) : edNurseArray && tabName === "edNurse" ? (
              <div className="col-md-12 col-sm-12 col-12">
                {loading ? (
                  <div style={{ margin: "auto", width: "0%", padding: 10 }}>
                    <Loader
                      type="TailSpin"
                      color="blue"
                      height={50}
                      width={50}
                    />
                  </div>
                ) : (
                  <CustomTable
                    tableData={edNurseArray}
                    tableDataKeys={tableDataKeysForImgTechnician}
                    tableHeading={tableHeadingForImgTechnician}
                    action={actions}
                    handleAssign={handleEDNurseUpdate}
                    borderBottomColor={"#60d69f"}
                    borderBottomWidth={20}
                  />
                )}
              </div>
            ) : (
              <div className="LoaderStyle">
                <Loader type="TailSpin" color="red" height={50} width={50} />
              </div>
            )}
          </div>

          {/* <h4 style={{ textAlign: 'center' }}>
            Module is under development for now
          </h4> */}

          <div className="row" style={{ marginBottom: 20 }}>
            <div className="col-md-12 col-sm-12 col-12">
              <img
                onClick={() => props.history.goBack()}
                src={Back}
                style={{
                  width: 45,
                  height: 35,
                  cursor: "pointer",
                }}
              />
            </div>
          </div>
        </div>

        <Notification msg={errorMsg} open={openNotification} />
      </div>
    </div>
  );
}
