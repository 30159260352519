/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable array-callback-return */
/* eslint-disable react/jsx-indent */
import React, { useEffect, useState, useReducer } from "react";
import TextField from "@material-ui/core/TextField";
import Badge from "@material-ui/core/Badge";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import ErrorMessage from "../../../components/ErrorMessage/errorMessage";
import validateFirstName from "../../../public/inputValidator";
import validateLastName from "../../../public/inputValidator";
import validateEmail from "../../../public/emailValidator";
import validateCountryCity from "../../../public/countryCityValidator";
import validateGender from "../../../public/genderValidator";
import validateAddress from "../../../public/addressValidator";
import validateTextField from "../../../public/TextFieldValidator";
import validatePhone from "../../../public/validatePhone";
import validateCommunication from "../../../public/communicationValidator";
import validatePassword from "../../../public/passwordValidator";
import Notification from "../../../components/Snackbar/Notification.js";
import DateFnsUtils from "@date-io/date-fns";
import {
  DatePicker,
  MuiPickersUtilsProvider,
  TimePicker,
} from "@material-ui/pickers";
// import { MuiPickersUtilsProvider, TimePicker } from '@material-ui/pickers'
import cookie from "react-cookies";
import { uploadsUrl } from "../../../public/endpoins";
import Header from "../../../components/Header/Header";
import MuiPhoneNumber from "material-ui-phone-number";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@material-ui/core/Checkbox";
import editIcon from "../../../assets/img/Group 12947.png";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Back from "../../../assets/img/Back_Arrow.png";
import Input from "@material-ui/core/Input";
import {
  addNewStaffUser,
  getDoctorSubTypesFunc,
  getNursesSubTypesFunc,
  updateOldStaffUser,
  getShiftsData,
  getAdditionalRoleCall,
} from "./networkCallsForStaff";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { styles, SmallAvatar, useStyles } from "./staffStyles";

let countriesList = require("../../../assets/countries.json");

const statues = [
  {
    key: "active",
    value: "Active",
  },
  {
    key: "in_active",
    value: "In Active",
  },
];

const genderArray = [
  {
    key: "Male",
    value: "Male",
  },
  {
    key: "Female",
    value: "Female",
  },
  {
    key: "Others",
    value: "Others",
  },
];

const workFromArray = [
  {
    key: "08:00 AM",
    value: "08:00 AM",
  },
  {
    key: "02:00 PM",
    value: "02:00 PM",
  },
  {
    key: "10:00 PM",
    value: "10:00 PM",
  },
];

const workToArray = [
  {
    key: "08:00 AM",
    value: "08:00 AM",
  },
  {
    key: "02:00 PM",
    value: "02:00 PM",
  },
  {
    key: "10:00 PM",
    value: "10:00 PM",
  },
];

function AddEditStaff(props) {
  const matches = useMediaQuery("(min-width:600px)");
  const classes = useStyles();
  const initialState = {
    _id: "",
    // staffTypeId: '',
    firstName: "",
    lastName: "",
    designation: "",
    email: "",
    password: "",
    contactNumber: "",
    identificationNumber: "",
    gender: "",
    age: "",
    birthDate: "",
    specialty: [],
    nationality: "",
    subType: [],
    systemAdminId: "",
    status: "",
    shift: "",
    shiftStartTime: "",
    shiftEndTime: "",

    functionalUnit: "",
    additionalRole: "",
  };

  function reducer(state, { field, value }) {
    return {
      ...state,
      [field]: value,
    };
  }

  const [state, dispatch] = useReducer(reducer, initialState);

  const {
    email,
    password,
    gender,
    age,
    birthDate,
    specialty,
    nationality,
    subType,
    shift,
    shiftStartTime,
    shiftEndTime,
    additionalRole,
  } = state;

  const [comingFor, setcomingFor] = useState("");
  const [staffType, setStaffType] = useState("");
  const [, setIsFormSubmitted] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [openNotification, setOpenNotification] = useState(false);
  const [countries, setCountries] = useState("");
  const [cities, setCities] = useState("");
  const [cityBoolean, setCityBoolean] = useState(false);
  const [checked, setChecked] = useState(false);
  const [shiftArray, setShiftArray] = useState([]);
  const [name, setName] = useState([
    { use: "", family: "", given: [""], prefix: "" },
  ]);
  const [telecom, setTelecom] = useState([
    { value: "", system: "email" },
    { value: "", system: "mobile" },
    { value: "", system: "phone" },
  ]);
  const [address, setAddress] = useState([
    {
      type: "",
      text: "",
      city: "",
      country: "",
    },
  ]);
  const [communication, setCommunication] = useState("");
  const [addedBy, setAddedBy] = useState("");
  const [doctorSubTypes, setDoctorSubTypes] = useState([]);
  const [additionalRolesArray, setAdditionalRolesArray] = useState([]);
  const [nursesSubTypes, setNursesSubTypes] = useState([]);
  const [doctorSpeciality, setDoctorSpeciality] = useState([]);
  const [nursesSpeciality, setNursesSpeciality] = useState([]);
  const [photo, setphoto] = useState([]);
  const [detailsForm, setDetailsForm] = useState(false);
  const [slipUpload, setSlipUpload] = useState("");
  const [imagePreview, setImagePreview] = useState("");
  const [disableField, setDisableField] = useState(false);
  const [education, setEducation] = useState([
    // { institution: "", subject: "", degree: "", grade: "" },
  ]);
  const [experience, setExperience] = useState([
    // {
    //   hospitalName: "",
    //   location: "",
    //   jobPosition: "",
    //   experience: "",
    //   startDate: "",
    //   endDate: "",
    //   currentlyWorking: false,
    // },
  ]);
  const [staffId, setStaffId] = useState("");
  const [currentUser, setCurrentUser] = useState(cookie.load("current_user"));

  const onSlipUpload = (event) => {
    console.log("event", event.target.files);
    // event.preventDefault()
    var file = event.target.files[0];
    var fileType = file.name.slice(file.name.length - 3);
    let file_size = event.target.files[0].size;

    // if (event.target.name === 'photo') {
    let obj = {};
    obj.contentType = fileType;
    obj.title = "Patient Image";
    obj.url = file.name;
    obj.size = file.size.toString();
    obj.creation = file.lastModifiedDate.toISOString().substr(0, 10);
    setphoto([obj]);

    setSlipUpload(file);

    // setProgress(true)
    var reader = new FileReader();
    var url = reader.readAsDataURL(file);

    reader.onloadend = function() {
      if (file_size <= 1500000) {
        if (fileType === "png") {
          setImagePreview([reader.result]);
        } else if (fileType === "PNG") {
          setImagePreview([reader.result]);
        } else if (fileType === "peg") {
          setImagePreview([reader.result]);
        } else if (fileType === "PEG") {
          setImagePreview([reader.result]);
        } else if (fileType === "jpg") {
          setImagePreview([reader.result]);
        } else if (fileType === "JPG") {
          setImagePreview([reader.result]);
        } else {
          setErrorMsg("Only jpg, jpeg and png are be allowed");
          setOpenNotification(true);
        }
      } else {
        setErrorMsg("Files size should be less Than or Equal to 1.5MB");
        setOpenNotification(true);
      }
    };
    // }
  };

  useEffect(() => {
    setCountries(Object.keys(countriesList[0]));
    // setCurrentUser(cookie.load('current_user'))
    if (currentUser.staffType === "Sensei") {
      setDisableField(true);
    }
    getDoctorSubTypesFunc(setDoctorSubTypes, setOpenNotification, setErrorMsg);
    getNursesSubTypesFunc(setNursesSubTypes, setOpenNotification, setErrorMsg);
    getShiftsData(setShiftArray, setOpenNotification, setErrorMsg);
    getAdditionalRoleCall(
      setAdditionalRolesArray,
      setOpenNotification,
      setErrorMsg
    );

    setDoctorSpeciality(props.history.location.state.doctorSpeciality);
    setNursesSpeciality(props.history.location.state.nursesSpeciality);

    setAddedBy(cookie.load("current_user")._id);
    setStaffType(props.location.state.staffType);
    const staffSelected = props.history.location.state;
    console.log("props", props.history.location.state.selectedItem);
    setcomingFor(
      staffSelected && staffSelected.comingFor ? staffSelected.comingFor : "add"
    );

    const selectedRec = staffSelected && staffSelected.selectedItem;

    console.log("Selected Staff ", selectedRec);

    if (selectedRec) {
      let cities = Object.entries(countriesList[0]);
      setStaffId(selectedRec._id);

      Object.entries(selectedRec).map(([key, val]) => {
        if (key === "name") {
          setName(val);
        } else if (key === "telecom") {
          setTelecom(val);
        } else if (key === "address") {
          for (var x in cities) {
            let arr = cities[x];
            if (arr[0] === val[0].country) {
              setCities(arr[1]);
            }
          }
          setAddress(val);
        } else if (key === "photo") {
          setphoto(val);
        } else if (key === "education") {
          setEducation(val);
        } else if (key === "experience") {
          setExperience(val);
        } else if (key === "communication") {
          setCommunication(val);
        } else if (key === "shift") {
          dispatch({ field: "shift", value: val._id });
          dispatch({ field: "shiftStartTime", value: val.startTime });
          dispatch({ field: "shiftEndTime", value: val.endTime });
        } else {
          dispatch({ field: key, value: val });
        }
      });
    }
  }, []);

  const onChangeEducationValue = (e, index) => {
    const list = [...education];
    list[index][e.target.name] = e.target.value;
    setEducation(list);
  };

  const onChangeExperienceValue = (e, index, field) => {
    console.log("e", e);
    const list = [...experience];

    if (e.target) {
      if (e.target.name === "currentlyWorking") {
        list[index][e.target.name] = e.target.checked;
        setChecked(!checked);
        list[index]["endDate"] = new Date();
        // console.log('hello')
        var diff =
          (new Date(experience[index].endDate).getTime() -
            new Date(experience[index].startDate).getTime()) /
          1000;
        diff /= 60 * 60 * 24;
        var result = Math.abs(Math.round(diff / 365.25));

        list[index]["experience"] = result;
      } else {
        list[index][e.target.name] = e.target.value;
      }
    } else {
      list[index][field] = e.toISOString().substr(0, 10);
      if (field === "endDate") {
        console.log("hello");
        var diff =
          (e.getTime() - new Date(experience[index].startDate).getTime()) /
          1000;
        diff /= 60 * 60 * 24;
        var res = Math.abs(Math.round(diff / 365.25));

        list[index]["experience"] = res;
      }
    }

    if (checked) {
      list[0]["endDate"] = new Date().toISOString().substr(0, 10);
    }

    setExperience(list);
  };

  // console.log('communica', communication)
  const onChangeNameValue = (e, index) => {
    const list = [...name];
    if (/^[a-zA-Z' ]*$/.test(e.target.value) === false) {
      return;
    } else {
      if (e.target.name === "given") {
        list[index][e.target.name] = [e.target.value.replace(/[^\w'\s]/gi, "")];
      } else {
        list[index][e.target.name] = e.target.value.replace(/[^\w'\s]/gi, "");
      }
    }
    // if (e.target.name === 'given') {
    //   list[index][e.target.name] = [e.target.value]
    // } else {
    //   list[index][e.target.name] = e.target.value
    // }

    setName(list);
  };

  const onChangeTelecomValue = (e, field) => {
    let tempTelecom = [...telecom];
    for (let i = 0; i < tempTelecom.length; i++) {
      if (e.target) {
        if (tempTelecom[i].system === e.target.name) {
          tempTelecom[i].value = e.target.value;
        }
      } else {
        if (tempTelecom[i].system === field) {
          tempTelecom[i].value = e;
        }
      }
    }
    setTelecom(tempTelecom);
  };

  const onChangeAddressValue = (e, index) => {
    console.log("eee", e.target.name, e.target.value);
    const { name, value } = e.target;
    const list = [...address];

    // if (name === 'city') {
    //   if (value === 'Other') {
    //     console.log('e.target.value', value)
    //     setCityBoolean(true)
    //     list[index][name] = 'Other'
    //   } else {
    //     setCityBoolean(false)
    //   }
    // }

    list[index][name] = value;

    let cities = Object.entries(countriesList[0]);
    for (var x in cities) {
      let arr = cities[x];
      if (arr[0] === e.target.value) {
        console.log("cities", arr[1]);
        setCities(arr[1].sort());
      }
    }

    setAddress(list);
  };

  // console.log('education', education)

  const RemoveEducation = (index) => {
    const list = [...education];
    list.splice(index, 1);
    setEducation(list);
  };

  const AddEducation = () => {
    setEducation([
      ...education,
      { institution: "", subject: "", degree: "", grade: "" },
    ]);
  };

  const RemoveExperience = (index) => {
    const list = [...experience];
    list.splice(index, 1);
    setExperience(list);
  };

  const AddExperience = () => {
    setExperience([
      ...experience,
      {
        hospitalName: "",
        location: "",
        jobPosition: "",
        experience: "",
        startDate: "",
        endDate: "",
      },
    ]);
  };

  function telecomValue(toBeDoneFor) {
    for (let i = 0; i < telecom.length; i++) {
      let v = telecom[i];
      if (v.system === toBeDoneFor) {
        if (v.value !== "") {
          return v.value;
        } else {
          return "";
        }
      }
    }
  }

  function validateDetails() {
    const a = education.map((val) => val);
    console.log("validations", a);

    let x = true;

    if (
      props.location.state.staffType === "Doctor" ||
      props.location.state.staffType === "Nurses"
    ) {
      let x =
        subType &&
        validateGender(subType) &&
        specialty &&
        validateGender(specialty);
    }

    return (
      x &&
      name[0].given &&
      name[0].given[0].length > 0 &&
      validateFirstName(name[0].given) &&
      name[0].family &&
      name[0].family.length > 0 &&
      validateLastName(name[0].family) &&
      gender &&
      validateGender(gender) &&
      validateEmail(telecomValue("email")) &&
      telecomValue("mobile") &&
      telecomValue("mobile").length > 0 &&
      !validatePhone(telecomValue("mobile")) &&
      address[0].country &&
      address[0].country.length > 0 &&
      validateCountryCity(address[0].country) &&
      address[0].city &&
      address[0].city.length > 0 &&
      validateCountryCity(address[0].city) &&
      address[0].text &&
      address[0].text.length > 0 &&
      validateAddress(address[0].text) &&
      email &&
      email.length > 0 &&
      validateEmail(email) &&
      communication &&
      communication.length > 0 &&
      validateCommunication(communication) &&
      password &&
      password.length > 0 &&
      validatePassword(password) &&
      birthDate !== "" &&
      age !== "" &&
      shift &&
      shift.length > 0 &&
      shiftStartTime &&
      shiftStartTime.length > 0 &&
      shiftEndTime &&
      shiftEndTime.length > 0
      // &&
      // education.map(
      //   (val) =>
      //     val.institution !== '' &&
      //     val.institution.length > 0 &&
      //     validateTextField(val.institution)
      // )
      // &&
      // education.map(
      //   (val) => val.subject.length > 0 && validateTextField(val.subject)
      // ) &&
      // education.map(
      //   (val) => val.degree.length > 0 && validateTextField(val.degree)
      // ) &&
      // education.map(
      //   (val) => val.grade.length > 0 && validateTextField(val.grade)
      // ) &&
      // experience.map(
      //   (val) =>
      //     val.hospitalName.length > 0 && validateTextField(val.hospitalName)
      // ) &&
      // experience.map(
      //   (val) => val.location.length > 0 && validateTextField(val.location)
      // ) &&
      // experience.map(
      //   (val) =>
      //     val.jobPosition.length > 0 && validateTextField(val.jobPosition)
      // ) &&
      // experience.map(
      //   (val) => val.experience.length > 0 && validateTextField(val.experience)
      // )
    );
  }
  // const a = experience.map((val) => val.hospitalName !== '')
  // console.log('valid', a)

  const handleChangeDate = (value) => {
    console.log("value", value);
    dispatch({ field: "birthDate", value: value.toISOString().substr(0, 10) });
    calculate_age(value.toISOString().substr(0, 10));
  };

  const handleChangeShiftDate = (value, field) => {
    dispatch({ field: field, value: value.toISOString() });
  };

  const calculate_age = (birthDate) => {
    var today = new Date();
    var birthDate = new Date(birthDate);
    var age_now = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age_now--;
    }
    dispatch({ field: "age", value: age_now });
  };

  const filerEmptyRecords = (parameter) => {
    if (parameter === "experience") {
      let exp = experience.filter(
        (exp) => exp.hospitalName !== "" && exp.experience !== ""
      );
      return exp;
    } else if (parameter === "education") {
      let edu = education.filter(
        (edu) => edu.institution !== "" && edu.degree !== ""
      );
      return edu;
    }
  };

  const handleSubmit = () => {
    let formData = new FormData();
    if (slipUpload) {
      formData.append("file", slipUpload, slipUpload.name);
    }
    if (validateDetails()) {
      let params = {
        addedBy: currentUser._id,
        staffType,
        subType,
        name,
        telecom,
        address,
        email,
        password,
        gender,
        birthDate,
        specialty,
        nationality,
        photo,
        education: filerEmptyRecords("education"),
        experience: filerEmptyRecords("experience"),
        communication: communication.split(","),
        age,
        shift,
        shiftStartTime,
        shiftEndTime,
        additionalRole,
      };
      formData.append("data", JSON.stringify(params));

      addNewStaffUser(
        props,
        formData,
        setStaffId,
        setOpenNotification,
        setErrorMsg
      );
    } else {
      setOpenNotification(true);
      setErrorMsg("Please Fill the the empty fields with valid data");
    }
    setDetailsForm(true);
  };

  const handleEdit = () => {
    console.log(communication);
    let formData = new FormData();
    if (slipUpload) {
      formData.append("file", slipUpload, slipUpload.name);
    }
    if (validateDetails()) {
      let params = {
        _id: staffId,
        staffType,
        subType,
        name,
        telecom,
        address,
        email,
        password,
        gender,
        birthDate,
        specialty,
        nationality,
        photo,
        education: filerEmptyRecords("education"),
        experience: filerEmptyRecords("experience"),
        communication: communication.toString().split(","),
        age,
        addedBy,
        shift,
        shiftStartTime,
        shiftEndTime,
        additionalRole,
      };

      console.log("params", params);
      formData.append("data", JSON.stringify(params));
      updateOldStaffUser(
        props,
        formData,
        setStaffId,
        setOpenNotification,
        setErrorMsg
      );
    } else {
      setOpenNotification(true);
      setErrorMsg("Please Fill the the empty fields with valid data");
    }
    setDetailsForm(true);
  };

  console.log("experience", experience);

  const onChangeValue = (e) => {
    if (e.target.name === "communication") {
      setCommunication(e.target.value);
    }
    if (e.target.name === "shift") {
      shiftArray.map((d) => {
        if (d._id === e.target.value) {
          dispatch({ field: "shiftStartTime", value: d.startTime });
          dispatch({ field: "shiftEndTime", value: d.endTime });
        }
      });
    }
    dispatch({ field: e.target.name, value: e.target.value });
  };

  if (openNotification) {
    setTimeout(() => {
      setOpenNotification(false);
      setErrorMsg("");
    }, 2000);
  }

  return (
    <div
      style={{
        backgroundColor: "#13D59F",
        position: "fixed",
        display: "flex",
        width: "100%",
        height: "100%",
        flexDirection: "column",
        flex: 1,
        overflowY: "scroll",
      }}
    >
      <Header history={props.history} />
      <div className={`cPadding `}>
        <div
          className={`${"container-fluid"} `}
          style={{
            backgroundColor: "white",
            padding: "20px 40px",
            borderRadius: 5,
            marginTop: 120,
          }}
        >
          <div
            className="row"
            style={{
              justifyContent: "center",
              marginTop: matches ? -140 : -140,
            }}
          >
            <Badge
              style={{}}
              overlap="circle"
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              badgeContent={
                <>
                  <input
                    // ref={inputFileRef}
                    accept="image/*"
                    hidden
                    id="avatar-image-upload"
                    type="file"
                    onChange={onSlipUpload}
                  />
                  <label htmlFor="avatar-image-upload">
                    <img
                      src={editIcon}
                      style={{
                        width: matches ? 58 : 48,
                        height: matches ? 58 : 48,
                        cursor: "pointer",
                      }}
                    />
                  </label>
                </>
              }
            >
              {photo.length > 0 && photo[0].url.includes("\\") ? (
                <>
                  {photo.length > 0 &&
                  photo[0].url.slice(photo[0].url.length - 3) !== "pdf" ? (
                    <div
                      className="col-md-4 col-sm-4 col-4"
                      style={{
                        ...styles.inputContainerForTextField,
                        ...styles.textFieldPadding,
                      }}
                    >
                      <Avatar
                        alt="Travis Howard"
                        src={uploadsUrl + photo[0].url}
                        className={classes.large}
                      />
                    </div>
                  ) : (
                    undefined
                  )}
                </>
              ) : photo.length > 0 && photo[0].url.includes("/") ? (
                <>
                  {photo.length > 0 && photo[0].contentType !== "pdf" ? (
                    <div
                      className="col-md-4 col-sm-4 col-4"
                      style={{
                        ...styles.inputContainerForTextField,
                        ...styles.textFieldPadding,
                      }}
                    >
                      <Avatar
                        alt="Travis Howard"
                        src={uploadsUrl + photo[0].url}
                        className={classes.large}
                      />
                    </div>
                  ) : (
                    undefined
                  )}
                </>
              ) : (
                <Avatar
                  alt="Travis Howard"
                  src={imagePreview}
                  className={classes.large}
                />
              )}
            </Badge>
          </div>

          <h4
            style={{
              color: "grey",
              fontWeight: "bold",
              marginLeft: -14,
              marginTop: 20,
              fontSize: matches ? " " : 16,
            }}
          >
            Basic Details
          </h4>
          {name.map((x, i) => {
            return (
              <div
                className={`row ${classes.root}`}
                style={{
                  marginTop: "15px",
                  marginRight: "-20px",
                  marginLeft: "-20px",
                }}
              >
                <div
                  className="col-md-6 col-sm-6"
                  style={{
                    ...styles.inputContainerForTextField,
                    ...styles.textFieldPadding,
                  }}
                >
                  <TextField
                    required
                    disabled={disableField}
                    label="First Name"
                    name={"given"}
                    value={name[i].given[0]}
                    onChange={(e) => onChangeNameValue(e, i)}
                    error={name[i].given[0] === "" && detailsForm}
                    className="textInputStyle"
                    variant="filled"
                    InputProps={{
                      className: classes.input,
                      classes: { input: classes.input },
                    }}
                    inputProps={{ maxLength: 40 }}
                  />
                  <ErrorMessage
                    name={name[i].given[0]}
                    type="firstName"
                    isFormSubmitted={detailsForm}
                  />
                </div>
                <div
                  className="col-md-6 col-sm-6"
                  style={{
                    ...styles.inputContainerForTextField,
                    ...styles.textFieldPadding,
                  }}
                >
                  <TextField
                    required
                    label="Last Name"
                    disabled={disableField}
                    name={"family"}
                    value={x.family}
                    onChange={(e) => onChangeNameValue(e, i)}
                    error={x.family === "" && detailsForm}
                    className="textInputStyle"
                    variant="filled"
                    InputProps={{
                      className: classes.input,
                      classes: { input: classes.input },
                    }}
                    inputProps={{ maxLength: 40 }}
                  />
                  <ErrorMessage
                    name={x.family}
                    type="lastName"
                    isFormSubmitted={detailsForm}
                  />
                </div>
              </div>
            );
          })}

          <div
            className={`row ${classes.root}`}
            style={{
              marginTop: "15px",
              marginRight: "-20px",
              marginLeft: "-20px",
            }}
          >
            <div
              className={"col-md-12"}
              style={{
                ...styles.inputContainerForTextField,
                ...styles.textFieldPadding,
              }}
            >
              <TextField
                // required
                select
                label="Additional Role"
                name={"additionalRole"}
                value={additionalRole}
                onChange={onChangeValue}
                // error={additionalRole === '' && detailsForm}
                className="textInputStyle"
                variant="filled"
                InputProps={{
                  className: classes.input,
                  classes: { input: classes.input },
                }}
                inputProps={{ maxLength: 40 }}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>

                {additionalRolesArray.map((val) => {
                  return (
                    <MenuItem key={val} value={val}>
                      {val}
                    </MenuItem>
                  );
                })}
              </TextField>
              {/* <ErrorMessage
                name={gender}
                type='gender'
                isFormSubmitted={detailsForm}
              /> */}
            </div>
          </div>

          <div
            className={`row ${classes.root}`}
            style={{
              marginTop: "15px",
              marginRight: "-20px",
              marginLeft: "-20px",
            }}
          >
            <div
              className={
                props.location.state.staffType === "Doctor" ||
                props.location.state.staffType === "Nurses"
                  ? "col-md-4"
                  : "col-md-6"
              }
              style={{
                ...styles.inputContainerForTextField,
                ...styles.textFieldPadding,
              }}
            >
              <TextField
                required
                select
                label="Gender"
                disabled={disableField}
                name={"gender"}
                value={gender}
                onChange={onChangeValue}
                error={gender === "" && detailsForm}
                className="textInputStyle"
                variant="filled"
                InputProps={{
                  className: classes.input,
                  classes: { input: classes.input },
                }}
                inputProps={{ maxLength: 40 }}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>

                {genderArray.map((val) => {
                  return (
                    <MenuItem
                      key={val.key.charAt(0).toUpperCase() + val.value.slice(1)}
                      value={
                        val.key.charAt(0).toUpperCase() + val.value.slice(1)
                      }
                    >
                      {val.value.charAt(0).toUpperCase() + val.value.slice(1)}
                    </MenuItem>
                  );
                })}
              </TextField>
              <ErrorMessage
                name={gender}
                type="gender"
                isFormSubmitted={detailsForm}
              />
            </div>
            <div
              className={
                props.location.state.staffType === "Doctor" ||
                props.location.state.staffType === "Nurses"
                  ? "col-md-4"
                  : "col-md-6"
              }
              style={{
                ...styles.inputContainerForTextField,
                ...styles.textFieldPadding,
              }}
            >
              <TextField
                required
                disabled
                label="Age"
                name={"age"}
                value={age}
                onChange={onChangeValue}
                className="textInputStyle"
                variant="filled"
                InputProps={{
                  className: classes.input,
                  classes: { input: classes.input },
                }}
                inputProps={{ maxLength: 40 }}
              />
            </div>

            {props.location.state.staffType === "Doctor" ||
            props.location.state.staffType === "Nurses" ? (
              <div
                className={"col-md-4"}
                style={{
                  ...styles.inputContainerForTextField,
                  ...styles.textFieldPadding,
                }}
              >
                <FormControl variant="filled" style={{ width: "100%" }}>
                  <InputLabel htmlFor="name-native-error">Sub Type</InputLabel>
                  <Select
                    required
                    multiple
                    label="Sub Type"
                    disabled={disableField}
                    name={"subType"}
                    value={subType}
                    error={subType === "" && detailsForm}
                    onChange={onChangeValue}
                    // className="textInputStyle"
                    // variant="filled"
                    // InputProps={{
                    //   className: classes.input,
                    //   classes: { input: classes.input },
                    // }}
                  >
                    {/* <MenuItem value="">
                      <em>None</em>
                    </MenuItem> */}
                    {props.location.state.staffType === "Doctor"
                      ? doctorSubTypes &&
                        doctorSubTypes.map((val) => {
                          return <MenuItem value={val}>{val}</MenuItem>;
                        })
                      : nursesSubTypes &&
                        nursesSubTypes.map((val) => {
                          return <MenuItem value={val}>{val}</MenuItem>;
                        })}
                  </Select>
                </FormControl>

                <ErrorMessage
                  name={subType}
                  type="subType"
                  isFormSubmitted={detailsForm}
                ></ErrorMessage>
              </div>
            ) : (
              undefined
            )}
          </div>

          <div
            className={`row ${classes.root}`}
            style={{
              marginTop: "15px",
              marginRight: "-20px",
              marginLeft: "-20px",
            }}
          >
            <div
              className={
                props.location.state.staffType === "Doctor" ||
                props.location.state.staffType === "Nurses"
                  ? "col-md-6"
                  : "col-md-12"
              }
              style={{
                ...styles.inputContainerForTextField,
                ...styles.textFieldPadding,
              }}
            >
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  required
                  disabled={disableField}
                  inputVariant="filled"
                  fullWidth={true}
                  label="Date of birth"
                  format="dd - MM - yyyy"
                  maxDate={new Date()}
                  error={birthDate === "" && detailsForm}
                  onChange={(val) => handleChangeDate(val, "birthDate")}
                  InputProps={{
                    className: classes.input,
                    classes: { input: classes.input },
                  }}
                  style={{ borderRadius: "10px" }}
                  value={birthDate ? birthDate : null}
                />
              </MuiPickersUtilsProvider>

              <ErrorMessage
                name={birthDate}
                type="birthDate"
                isFormSubmitted={detailsForm}
              />
            </div>

            {props.location.state.staffType === "Doctor" ||
            props.location.state.staffType === "Nurses" ? (
              <div
                className="col-md-6"
                style={{
                  ...styles.inputContainerForTextField,
                  ...styles.textFieldPadding,
                }}
              >
                <FormControl variant="filled" style={{ width: "100%" }}>
                  <InputLabel id="demo-mutiple-name-label">
                    Speciality
                  </InputLabel>
                  <Select
                    required
                    multiple
                    label="Speciality"
                    disabled={disableField}
                    name={"specialty"}
                    value={specialty}
                    error={specialty === "" && detailsForm}
                    onChange={onChangeValue}
                    // className="dropDownStyle"
                    // variant="filled"
                    // InputProps={{
                    //   className: classes.input,
                    //   classes: { input: classes.input },
                    // }}
                    // input={<Input />}
                  >
                    <MenuItem value={""}>
                      <em>None</em>
                    </MenuItem>
                    {props.location.state.staffType === "Doctor"
                      ? doctorSpeciality &&
                        doctorSpeciality.map((val) => {
                          return <MenuItem value={val}>{val}</MenuItem>;
                        })
                      : props.location.state.staffType === "Nurses"
                      ? nursesSpeciality &&
                        nursesSpeciality.map((val) => {
                          return <MenuItem value={val}>{val}</MenuItem>;
                        })
                      : undefined}
                  </Select>
                </FormControl>

                <ErrorMessage
                  name={specialty}
                  type="specialty"
                  isFormSubmitted={detailsForm}
                />
              </div>
            ) : (
              undefined
            )}
          </div>

          <div
            className={`row ${classes.root}`}
            style={{
              marginTop: "15px",
              marginRight: "-20px",
              marginLeft: "-20px",
            }}
          >
            {/* {communication.map((x, i) => {
              return ( */}
            <div
              className="col-md-6 col-sm-6"
              style={{
                ...styles.inputContainerForTextField,
                ...styles.textFieldPadding,
              }}
            >
              <TextField
                required
                label="Communication Languages"
                name="communication"
                disabled={disableField}
                value={communication}
                error={communication === "" && detailsForm}
                onChange={onChangeValue}
                className="textInputStyle"
                variant="filled"
                InputProps={{
                  className: classes.input,
                  classes: { input: classes.input },
                }}
                inputProps={{ maxLength: 40 }}
              />
              <ErrorMessage
                name={communication}
                type="communication"
                isFormSubmitted={detailsForm}
              />
            </div>
            {/* )
            })} */}
            <div
              className="col-md-6 col-sm-6"
              style={{
                ...styles.inputContainerForTextField,
                ...styles.textFieldPadding,
              }}
            >
              <TextField
                // required
                type="text"
                select
                label="Nationality"
                name={"nationality"}
                disabled={disableField}
                value={!nationality ? "Jordan" : nationality}
                // error={nationality === '' && detailsForm}
                onChange={(e) => onChangeValue(e)}
                className="textInputStyle"
                variant="filled"
                InputProps={{
                  className: classes.input,
                  classes: { input: classes.input },
                }}
              >
                <MenuItem value="Jordan">
                  <em>None</em>
                </MenuItem>

                {countries &&
                  countries.map((val) => {
                    return (
                      <MenuItem key={val} value={val}>
                        {val}
                      </MenuItem>
                    );
                  })}
              </TextField>

              {/* <ErrorMessage
                  name={nationality}
                  // type='nationName'
                  isFormSubmitted={detailsForm}
                /> */}
            </div>
          </div>

          <div
            className="row"
            style={{ marginTop: 15, marginRight: "-20px", marginLeft: "-20px" }}
          >
            <div
              className="col-md-6 col-sm-6"
              style={{
                ...styles.inputContainerForTextField,
                ...styles.textFieldPadding,
              }}
            >
              <TextField
                // required
                disabled={disableField}
                label="Email"
                name="email"
                value={telecomValue("email")}
                onChange={(e) => onChangeTelecomValue(e)}
                className="textInputStyle"
                variant="filled"
                InputProps={{
                  className: classes.input,
                  classes: { input: classes.input },
                }}
              />
              <ErrorMessage
                name={telecomValue("email")}
                type="email"
                isFormSubmitted={detailsForm}
              />
            </div>

            <div
              className="col-md-6 col-sm-6"
              style={{
                ...styles.inputContainerForTextField,
                ...styles.textFieldPadding,
              }}
            >
              <MuiPhoneNumber
                required
                disabled={disableField}
                label="Mobile Number"
                name="mobile"
                value={telecomValue("mobile")}
                // hyperText='Telephone format +962xxxxxxxx'
                error={telecomValue("mobile") === "" && detailsForm}
                defaultCountry={"jo"}
                onChange={(e) => onChangeTelecomValue(e, "mobile")}
                className="textInputStyle"
                variant="filled"
                InputProps={{
                  className: classes.input,
                  classes: { input: classes.input },
                }}
                InputLabelProps={{
                  className: classes.label,
                  classes: { label: classes.label },
                }}
              />
              {telecomValue("mobile") &&
              !validatePhone(telecomValue("mobile")) ? (
                undefined
              ) : (
                <ErrorMessage
                  name={telecomValue("mobile")}
                  type="phone"
                  isFormSubmitted={detailsForm}
                />
              )}
            </div>
          </div>

          {address.map((x, i) => {
            return (
              <>
                <div
                  className="row"
                  style={{
                    marginTop: 15,
                    marginRight: "-20px",
                    marginLeft: "-20px",
                  }}
                >
                  <div
                    className="col-md-6"
                    style={{
                      ...styles.inputContainerForTextField,
                      ...styles.textFieldPadding,
                    }}
                  >
                    <TextField
                      required
                      select
                      fullWidth
                      id="country"
                      name="country"
                      disabled={disableField}
                      value={x.country}
                      error={x.country === "" && detailsForm}
                      onChange={(e) => onChangeAddressValue(e, i)}
                      label="Country"
                      variant="filled"
                      className="dropDownStyle"
                      InputProps={{
                        className: classes.input,
                        classes: { input: classes.input },
                      }}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>

                      {countries &&
                        countries.map((val) => {
                          return (
                            <MenuItem key={val} value={val}>
                              {val}
                            </MenuItem>
                          );
                        })}
                    </TextField>
                    <ErrorMessage
                      name={x.country}
                      type="country"
                      isFormSubmitted={detailsForm}
                    />
                  </div>

                  <div
                    className="col-md-6"
                    style={{
                      ...styles.inputContainerForTextField,
                      ...styles.textFieldPadding,
                    }}
                  >
                    <TextField
                      required
                      select
                      fullWidth
                      id="city"
                      name="city"
                      disabled={disableField}
                      value={x.city}
                      onChange={(e) => onChangeAddressValue(e, i)}
                      label="City"
                      error={x.city === "" && detailsForm}
                      variant="filled"
                      className="dropDownStyle"
                      InputProps={{
                        className: classes.input,
                        classes: { input: classes.input },
                      }}
                    >
                      <MenuItem value="">None</MenuItem>
                      {cities &&
                        cities.map((val) => {
                          return (
                            <MenuItem key={val} value={val}>
                              {val}
                            </MenuItem>
                          );
                        })}
                      {/* <MenuItem value='Other'>Other</MenuItem> */}
                    </TextField>
                    <ErrorMessage
                      name={x.city}
                      type="city"
                      isFormSubmitted={detailsForm}
                    />
                  </div>
                </div>

                {/* {cityBoolean ? (
                  <div className='row'>
                    <div
                      className='col-md-12 col-sm-12'
                      style={{
                        ...styles.inputContainerForTextField,
                        ...styles.textFieldPadding,
                        marginBottom: 16,
                      }}
                    >
                      <TextField
                        fullWidth
                        id='otherCity'
                        name='type'
                        value={x.type}
                        onChange={(e) => onChangeAddressValue(e, i)}
                        label='Other City/ Village/ Town'
                        variant='filled'
                        className='dropDownStyle'
                        InputProps={{
                          className: classes.input,
                          classes: { input: classes.input },
                        }}
                      />
                    </div>
                  </div>
                ) : (
                  undefined
                )} */}

                <div
                  className="row"
                  style={{
                    marginTop: 15,
                    marginRight: "-20px",
                    marginLeft: "-20px",
                  }}
                >
                  <div
                    className="col-md-12"
                    style={{
                      ...styles.inputContainerForTextField,
                      ...styles.textFieldPadding,
                    }}
                  >
                    <TextField
                      required
                      label="Address"
                      name={"text"}
                      value={x.text}
                      disabled={disableField}
                      error={x.text === "" && detailsForm}
                      onChange={(e) => onChangeAddressValue(e, i)}
                      className="textInputStyle"
                      variant="filled"
                      InputProps={{
                        className: classes.input,
                        classes: { input: classes.input },
                      }}
                      InputLabelProps={{
                        className: classes.label,
                        classes: { label: classes.label },
                      }}
                    />
                    <ErrorMessage
                      name={x.text}
                      type="address"
                      isFormSubmitted={detailsForm}
                    />
                  </div>
                </div>

                <div
                  className={`row ${classes.root}`}
                  style={{
                    marginTop: "15px",
                    marginRight: "-20px",
                    marginLeft: "-20px",
                  }}
                >
                  <div
                    className="col-md-12 col-sm-12"
                    style={{
                      ...styles.inputContainerForTextField,
                      ...styles.textFieldPadding,
                    }}
                  >
                    <TextField
                      required
                      select
                      label="Shift"
                      name={"shift"}
                      value={shift}
                      onChange={onChangeValue}
                      error={shift === "" && detailsForm}
                      className="textInputStyle"
                      variant="filled"
                      InputProps={{
                        className: classes.input,
                        classes: { input: classes.input },
                      }}
                      inputProps={{ maxLength: 40 }}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>

                      {shiftArray.map((val) => {
                        return (
                          <MenuItem key={val._id} value={val._id}>
                            {val.name}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                  </div>
                </div>

                <div
                  className={`row ${classes.root}`}
                  style={{
                    marginTop: "15px",
                    marginRight: "-20px",
                    marginLeft: "-20px",
                  }}
                >
                  <div
                    className="col-md-6 col-sm-6"
                    style={{
                      ...styles.inputContainerForTextField,
                      ...styles.textFieldPadding,
                    }}
                  >
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <TimePicker
                        disabled
                        label="Start Time"
                        inputVariant="filled"
                        fullWidth={true}
                        // ampm={false}
                        error={shiftStartTime === "" && detailsForm}
                        // onChange={(val) =>
                        //   handleChangeShiftDate(val, 'shiftStartTime')
                        // }
                        InputProps={{
                          className: classes.input,
                          classes: { input: classes.input },
                        }}
                        style={{ borderRadius: "10px" }}
                        value={shiftStartTime ? shiftStartTime : null}
                      />
                    </MuiPickersUtilsProvider>
                  </div>

                  <div
                    className="col-md-6 col-sm-6"
                    style={{
                      ...styles.inputContainerForTextField,
                      ...styles.textFieldPadding,
                    }}
                  >
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <TimePicker
                        disabled
                        inputVariant="filled"
                        fullWidth={true}
                        label="End Time"
                        // ampm={false}
                        error={shiftEndTime === "" && detailsForm}
                        // onChange={(val) =>
                        //   handleChangeShiftDate(val, 'shiftEndTime')
                        // }
                        InputProps={{
                          className: classes.input,
                          classes: { input: classes.input },
                        }}
                        style={{ borderRadius: "10px" }}
                        value={shiftEndTime ? shiftEndTime : null}
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                </div>
              </>
            );
          })}

          <Notification msg={errorMsg} open={openNotification} />
        </div>

        <div
          className={`${"container-fluid"} `}
          style={{
            backgroundColor: "white",
            padding: "20px 40px",
            marginTop: 20,
            borderRadius: 5,
          }}
        >
          <div>
            <h4 style={{ color: "grey", fontWeight: "bold", marginLeft: -14 }}>
              Education Information
            </h4>
            <span style={{ color: "grey", fontSize: 14, marginLeft: -14 }}>
              (While adding institution and degree is mandatory)
            </span>
          </div>
          {education.map((x, i) => {
            return (
              <>
                <div
                  className={`row ${classes.root}`}
                  style={{
                    marginTop: "15px",
                    marginRight: "-20px",
                    marginLeft: "-20px",
                  }}
                >
                  <div
                    className="col-md-6 col-sm-6"
                    style={{
                      ...styles.inputContainerForTextField,
                      ...styles.textFieldPadding,
                    }}
                  >
                    <TextField
                      name="institution"
                      label="Institution"
                      variant="filled"
                      className="dropDownStyle"
                      value={x.institution}
                      // error={x.institution === "" && detailsForm}
                      onChange={(e) => onChangeEducationValue(e, i)}
                      InputProps={{
                        className: classes.input,
                        classes: { input: classes.input },
                      }}
                    />
                    {/* <ErrorMessage
                      name={x.institution}
                      type="textField"
                      isFormSubmitted={detailsForm}
                    /> */}
                  </div>

                  <div
                    className="col-md-6 col-sm-6"
                    style={{
                      ...styles.inputContainerForTextField,
                      ...styles.textFieldPadding,
                    }}
                  >
                    <TextField
                      name="subject"
                      variant="filled"
                      label="Subject"
                      value={x.subject}
                      // error={x.subject === "" && detailsForm}
                      className="textInputStyle"
                      onChange={(e) => onChangeEducationValue(e, i)}
                      InputProps={{
                        className: classes.input,
                        classes: { input: classes.input },
                      }}
                    />
                    {/* <ErrorMessage
                      name={x.subject}
                      type="textField"
                      isFormSubmitted={detailsForm}
                    /> */}
                  </div>
                </div>

                <div
                  className={`row ${classes.root}`}
                  style={{
                    marginTop: "15px",
                    marginRight: "-20px",
                    marginLeft: "-20px",
                  }}
                >
                  <div
                    className="col-md-6 col-sm-6"
                    style={{
                      ...styles.inputContainerForTextField,
                      ...styles.textFieldPadding,
                    }}
                  >
                    <TextField
                      name="degree"
                      label="Degree"
                      variant="filled"
                      className="dropDownStyle"
                      value={x.degree}
                      // error={x.degree === "" && detailsForm}
                      onChange={(e) => onChangeEducationValue(e, i)}
                      InputProps={{
                        className: classes.input,
                        classes: { input: classes.input },
                      }}
                    />
                    {/* <ErrorMessage
                      name={x.degree}
                      type="textField"
                      isFormSubmitted={detailsForm}
                    /> */}
                  </div>

                  <div
                    className="col-md-6 col-sm-6"
                    style={{
                      ...styles.inputContainerForTextField,
                      ...styles.textFieldPadding,
                    }}
                  >
                    <TextField
                      name="grade"
                      variant="filled"
                      label="Grade"
                      value={x.grade}
                      // error={x.grade === "" && detailsForm}
                      className="textInputStyle"
                      onChange={(e) => onChangeEducationValue(e, i)}
                      InputProps={{
                        className: classes.input,
                        classes: { input: classes.input },
                      }}
                    />
                    {/* <ErrorMessage
                      name={x.grade}
                      type="textField"
                      isFormSubmitted={detailsForm}
                    /> */}
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flex: 1,
                      justifyContent: "flex-end",
                      marginTop: "2%",
                      marginBottom: "2%",
                    }}
                  >
                    {education.length !== 1 && (
                      <Button
                        style={styles.stylesForRemoveButton}
                        onClick={() => RemoveEducation(i)}
                        variant="contained"
                        // color='primary'
                      >
                        <strong style={{ fontSize: "12px" }}>
                          Remove Institution
                        </strong>
                      </Button>
                    )}
                    &nbsp;
                  </div>
                </div>
              </>
            );
          })}

          <Notification msg={errorMsg} open={openNotification} />
          {/* {education.length - 1 === i && ( */}
          <div
            style={{
              marginLeft: matches ? " " : -15,
              marginRight: matches ? "" : -15,
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              style={{ ...styles.stylesForButton, width: matches ? 200 : 160 }}
              onClick={AddEducation}
              variant="contained"
              color="primary"
              disabled={disableField}
            >
              <strong style={{ fontSize: matches ? "12px" : 10 }}>
                Add Institution
              </strong>
            </Button>
          </div>
          {/* )} */}
        </div>

        <div
          className={`${"container-fluid"} `}
          style={{
            backgroundColor: "white",
            padding: "20px 40px",
            marginTop: 20,
            borderRadius: 5,
          }}
        >
          <div>
            <h4 style={{ color: "grey", fontWeight: "bold", marginLeft: -14 }}>
              Experience Information
            </h4>
            <span style={{ color: "grey", fontSize: 14, marginLeft: -14 }}>
              (While adding hospital name and experience duration is mandatory)
            </span>
          </div>
          {experience.map((x, i) => {
            return (
              <>
                <div
                  className={`row ${classes.root}`}
                  style={{
                    marginTop: "15px",
                    marginRight: "-20px",
                    marginLeft: "-20px",
                  }}
                >
                  <div
                    className="col-md-6 col-sm-6"
                    style={{
                      ...styles.inputContainerForTextField,
                      ...styles.textFieldPadding,
                    }}
                  >
                    <TextField
                      name="hospitalName"
                      label="Hospital Name"
                      variant="filled"
                      className="dropDownStyle"
                      value={x.hospitalName}
                      // error={x.hospitalName === "" && detailsForm}
                      onChange={(e) => onChangeExperienceValue(e, i)}
                      InputProps={{
                        className: classes.input,
                        classes: { input: classes.input },
                      }}
                    />
                    {/* <ErrorMessage
                      name={x.hospitalName}
                      type="textField"
                      isFormSubmitted={detailsForm}
                    /> */}
                  </div>

                  <div
                    className="col-md-6 col-sm-6"
                    style={{
                      ...styles.inputContainerForTextField,
                      ...styles.textFieldPadding,
                    }}
                  >
                    <TextField
                      name="location"
                      variant="filled"
                      label="Location"
                      value={x.location}
                      // error={x.location === "" && detailsForm}
                      className="textInputStyle"
                      onChange={(e) => onChangeExperienceValue(e, i)}
                      InputProps={{
                        className: classes.input,
                        classes: { input: classes.input },
                      }}
                    />
                    {/* <ErrorMessage
                      name={x.location}
                      type="textField"
                      isFormSubmitted={detailsForm}
                    /> */}
                  </div>
                </div>

                <div
                  className={`row ${classes.root}`}
                  style={{
                    marginTop: "15px",
                    marginRight: "-20px",
                    marginLeft: "-20px",
                  }}
                >
                  <div
                    className="col-md-6 col-sm-6"
                    style={{
                      ...styles.inputContainerForTextField,
                      ...styles.textFieldPadding,
                    }}
                  >
                    <TextField
                      name="jobPosition"
                      label="Job Position"
                      variant="filled"
                      className="dropDownStyle"
                      value={x.jobPosition}
                      // error={x.jobPosition === "" && detailsForm}
                      onChange={(e) => onChangeExperienceValue(e, i)}
                      InputProps={{
                        className: classes.input,
                        classes: { input: classes.input },
                      }}
                    />
                    {/* <ErrorMessage
                      name={x.jobPosition}
                      type="textField"
                      isFormSubmitted={detailsForm}
                    /> */}
                  </div>

                  <div
                    className="col-md-6 col-sm-6"
                    style={{
                      ...styles.inputContainerForTextField,
                      ...styles.textFieldPadding,
                    }}
                  >
                    <TextField
                      name="experience"
                      variant="filled"
                      disabled={true}
                      label="Experience (in years)"
                      value={x.experience}
                      // error={x.experience === "" && detailsForm}
                      className="textInputStyle"
                      onChange={(e) => onChangeExperienceValue(e, i)}
                      InputProps={{
                        className: classes.input,
                        classes: { input: classes.input },
                      }}
                    />
                    {/* <ErrorMessage
                      name={x.experience}
                      type="textField"
                      isFormSubmitted={detailsForm}
                    /> */}
                  </div>
                </div>

                <div
                  className={`row ${classes.root}`}
                  style={{
                    marginTop: "15px",
                    marginRight: "-20px",
                    marginLeft: "-20px",
                  }}
                >
                  <div
                    className="col-md-6 col-sm-6"
                    style={{
                      ...styles.inputContainerForTextField,
                      ...styles.textFieldPadding,
                    }}
                  >
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DatePicker
                        // required
                        inputVariant="filled"
                        fullWidth={true}
                        label="Start Date"
                        format="dd - MM - yyyy"
                        maxDate={new Date()}
                        // error={birthDate === '' && detailsForm}
                        onChange={(e) =>
                          onChangeExperienceValue(e, i, "startDate")
                        }
                        InputProps={{
                          className: classes.input,
                          classes: { input: classes.input },
                        }}
                        style={{ borderRadius: "10px" }}
                        value={x.startDate ? x.startDate : null}
                      />
                    </MuiPickersUtilsProvider>
                  </div>

                  <div
                    className="col-md-6 col-sm-6"
                    style={{
                      ...styles.inputContainerForTextField,
                      ...styles.textFieldPadding,
                    }}
                  >
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DatePicker
                        // required
                        inputVariant="filled"
                        fullWidth={true}
                        label="End Date"
                        format="dd - MM - yyyy"
                        maxDate={new Date()}
                        // minDate={x.startDate && new Date(x.startDate)}
                        // error={birthDate === '' && detailsForm}
                        onChange={(e) =>
                          onChangeExperienceValue(e, i, "endDate")
                        }
                        InputProps={{
                          className: classes.input,
                          classes: { input: classes.input },
                        }}
                        style={{ borderRadius: "10px" }}
                        value={x.endDate ? x.endDate : null}
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flex: 1,
                      justifyContent: "flex-start",
                      marginTop: "2%",
                      marginBottom: "2%",
                    }}
                  >
                    <Checkbox
                      name={"currentlyWorking"}
                      checked={x.currentlyWorking}
                      onChange={(e) => onChangeExperienceValue(e, i)}
                      color="default"
                      style={{ marginTop: -20 }}
                      inputProps={{
                        "aria-label": "checkbox with default color",
                      }}
                    />
                    <h6 style={{ fontWeight: "bold", color: "grey" }}>
                      Currently Working Here
                    </h6>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flex: 1,
                      justifyContent: "flex-end",
                      marginTop: "2%",
                      marginBottom: "2%",
                    }}
                  >
                    {experience.length !== 1 && (
                      <Button
                        style={styles.stylesForRemoveButton}
                        onClick={() => RemoveExperience(i)}
                        variant="contained"
                        // color='primary'
                      >
                        <strong style={{ fontSize: "12px" }}>
                          Remove Experience
                        </strong>
                      </Button>
                    )}
                    &nbsp;
                  </div>
                </div>
              </>
            );
          })}

          <Notification msg={errorMsg} open={openNotification} />

          {/* {experience.length - 1 === i && ( */}
          <div
            style={{
              marginLeft: matches ? " " : -15,
              marginRight: matches ? "" : -15,
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              style={{ ...styles.stylesForButton, width: matches ? 200 : 160 }}
              onClick={AddExperience}
              variant="contained"
              color="primary"
              disabled={disableField}
            >
              <strong style={{ fontSize: matches ? "12px" : 10 }}>
                Add Experience
              </strong>
            </Button>
          </div>
          {/* )} */}
        </div>

        {props.history.location.state.comingFor === "add" ? (
          <div
            className={`${"container-fluid"} `}
            style={{
              backgroundColor: "white",
              padding: "20px 40px",
              marginTop: 20,
              borderRadius: 5,
            }}
          >
            <h4 style={{ color: "grey", fontWeight: "bold", marginLeft: -14 }}>
              Account Information
            </h4>

            <>
              <div
                className={`row ${classes.root}`}
                style={{
                  marginTop: "15px",
                  marginRight: "-20px",
                  marginLeft: "-20px",
                }}
              >
                <div
                  className="col-md-6 col-sm-6"
                  style={{
                    ...styles.inputContainerForTextField,
                    ...styles.textFieldPadding,
                  }}
                >
                  <TextField
                    name="email"
                    label="User Email"
                    variant="filled"
                    className="dropDownStyle"
                    value={email}
                    error={email === "" && detailsForm}
                    onChange={onChangeValue}
                    InputProps={{
                      className: classes.input,
                      classes: { input: classes.input },
                    }}
                  />
                  <ErrorMessage
                    name={email}
                    type="email"
                    isFormSubmitted={detailsForm}
                  />
                </div>

                <div
                  className="col-md-6 col-sm-6"
                  style={{
                    ...styles.inputContainerForTextField,
                    ...styles.textFieldPadding,
                  }}
                >
                  <TextField
                    name="password"
                    variant="filled"
                    label="Password"
                    value={password}
                    error={password === "" && detailsForm}
                    className="textInputStyle"
                    onChange={onChangeValue}
                    InputProps={{
                      className: classes.input,
                      classes: { input: classes.input },
                    }}
                  />
                  <ErrorMessage
                    name={password}
                    type="password"
                    isFormSubmitted={detailsForm}
                  />
                </div>
              </div>
            </>

            <Notification msg={errorMsg} open={openNotification} />
          </div>
        ) : (
          undefined
        )}

        <div
          class="row"
          style={{
            display: "flex",
            flex: 1,
            justifyContent: "center",
            paddingLeft: 6,
            paddingRight: 6,
          }}
        >
          <div
            style={{
              paddingRight: matches ? "" : 10,
              display: "flex",
              flex: 1,
              justifyContent: "flex-end",
              marginTop: matches ? "2%" : "8%",
              marginBottom: matches ? "2%" : "5%",
            }}
          >
            <Button
              style={{
                ...styles.stylesSubmitButton,
                width: matches ? 140 : 330,
              }}
              onClick={comingFor === "add" ? handleSubmit : handleEdit}
              variant="contained"
              color="default"
            >
              Submit
            </Button>
          </div>
        </div>
        <div
          class="container-fluid row"
          style={{
            display: "flex",
            flex: 1,
            justifyContent: "center",
            paddingLeft: 6,
            paddingRight: 6,
          }}
        >
          <div
            style={{
              paddingRight: matches ? "" : 10,
              display: "flex",
              flex: 1,
              justifyContent: "flex-start",
              marginTop: matches ? "2%" : "8%",
              marginBottom: matches ? "2%" : "5%",
            }}
          >
            <img
              onClick={() => props.history.goBack()}
              src={Back}
              style={{
                width: 45,
                height: 35,
                cursor: "pointer",
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
export default AddEditStaff;
