import axios from "axios";
import {
  getRadiologyRequestsURL,
  getLabRequestsURL,
  getPharmacyRequestURL,
  updatePharmaStatus,
} from "../../../public/endpoins";

export function getRadiologyRequests(
  setLoading,
  setRadRequests,
  setOpenNotification,
  setErrorMsg
) {
  axios
    .get(getRadiologyRequestsURL)
    .then((res) => {
      if (res.data.success) {
        console.log("response Rad request", res.data.data);
        res.data.data.map(
          (val) => (
            (val.patientName =
              val.patientId.name[0].given[0] +
              " " +
              val.patientId.name[0].family),
            (val.mrn = val.patientId.identifier[0].value),
            (val.date = val.patientId.createdAt)
          )
        );
        setRadRequests(res.data.data);
        setLoading(false);
      } else if (!res.data.success) {
        setErrorMsg(res.data.error);
        setOpenNotification(true);
      }
      return res;
    })
    .catch((e) => {
      console.log("error: ", e);
    });
}

export function getLabRequests(
  setLoading,
  setLabRequests,
  setOpenNotification,
  setErrorMsg
) {
  axios
    .get(getLabRequestsURL)
    .then((res) => {
      if (res.data.success) {
        console.log("response lab request", res.data.data);
        res.data.data.map(
          (val) => (
            (val.patientName =
              val.patientId.name[0].given[0] +
              " " +
              val.patientId.name[0].family),
            (val.mrn = val.patientId.identifier[0].value),
            (val.date = val.patientId.createdAt)
          )
        );
        setLabRequests(res.data.data);
        setLoading(false);
      } else if (!res.data.success) {
        setErrorMsg(res.data.error);
        setOpenNotification(true);
      }
      return res;
    })
    .catch((e) => {
      console.log("error: ", e);
    });
}

export function getPharmacyRequests(
  setLoading,
  setPharmaData,
  setOpenNotification,
  setErrorMsg
) {
  axios
    .get(getPharmacyRequestURL)
    .then((res) => {
      if (res.data.success) {
        console.log("response pharm request", res.data.data);
        res.data.data.map(
          (val) => (
            (val.patientName =
              val.patientId.name[0].given[0] +
              " " +
              val.patientId.name[0].family),
            (val.mrn = val.patientId.identifier[0].value),
            (val.date = val.patientId.createdAt)
          )
        );
        setPharmaData(res.data.data);
        setLoading(false);
      } else if (!res.data.success) {
        setErrorMsg(res.data.error);
        setOpenNotification(true);
      }
      return res;
    })
    .catch((e) => {
      console.log("error: ", e);
    });
}

export function updatePharmRequest(
  data,
  hideDialog,
  props,
  setOpenNotification,
  setErrorMsg
) {
  axios
    .put(updatePharmaStatus, data)
    .then((response) => {
      console.log("Response for Update Pharm", response);
      hideDialog();
      props.history.push({
        pathname: "/dashboard/home/success",
        state: {
          message1: `Status updated for pharmacy request`,
        },
      });
    })
    .catch((error) => {
      console.log("Error");
      hideDialog();
      setOpenNotification(true);
      setErrorMsg("Cannot update the pharmacy request at the moment");
    });
}
