/*eslint-disable*/
import React, { useState, useEffect, useReducer, useCallback } from "react";
import Button from "@material-ui/core/Button";
import Notification from "../../components/Snackbar/Notification.js";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Header from "../../components/Header/Header";
import LabRequests from "../../assets/img/Lab Requests.png";
import formatDate from "../../utils/formatDate";
import ImageViewer from "react-simple-image-viewer";
import { IoIosCloseCircle } from "react-icons/io";

import eye_icon from "../../assets/img/Eye.png";
import { FaUpload } from "react-icons/fa";
import Back from "../../assets/img/Back_Arrow.png";
import "../../assets/jss/material-dashboard-react/components/loaderStyle.css";
import cookie from "react-cookies";
import { styles, useStyles } from "./styles.js";
import { addNurseReport } from "./networkCallsForNurseTechnician";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import SliderComponent from "../../components/SliderComponent/sliderComponent";

import MultiSlider, { Progress, Dot } from "react-multi-bar-slider";
import { validate } from "@material-ui/pickers";

// const statusArray = [
//   {
//     key: "completed",
//     value: "completed",
//   },

//   {
//     key: "pending",
//     value: "pending",
//   },
// ];

export default function CommunicationMgmt(props) {
  const matches = useMediaQuery("(min-width:600px)");
  const initialState = {
    // edrId: "",
    diseaseName: "",
    fever: "",
    sugarLevel: "",
    bloodPressure: "",
    cbcLevel: "",
  };

  function reducer(state, { field, value }) {
    return {
      ...state,
      [field]: value,
    };
  }

  const [state, dispatch] = useReducer(reducer, initialState);

  const {
    // edrId,
    diseaseName,
    fever,
    sugarLevel,
    bloodPressure,
    cbcLevel,
  } = state;

  const classes = useStyles();

  const [currentUser] = useState(cookie.load("current_user"));
  const [errorMsg, setErrorMsg] = useState("");
  //   const [DocumentUpload, setDocumentUpload] = useState([]);
  const [openNotification, setOpenNotification] = useState(false);

  const [edrId, setEdrId] = useState("");
  const [labId, setlabId] = useState("");

  if (openNotification) {
    setTimeout(() => {
      setOpenNotification(false);
      setErrorMsg("");
    }, 2000);
  }

  useEffect(() => {
    const { selectedRecord } = props.history.location.state;
    console.log(selectedRecord, "passed data");
    setEdrId(selectedRecord._id);
  }, []);

  const handleCancel = () => {
    dispatch({ field: "cbcLevel", value: "" });
    dispatch({ field: "bloodPressure", value: "" });
    dispatch({ field: "sugarLevel", value: "" });
    dispatch({ field: "fever", value: "" });
    dispatch({ field: "diseaseName", value: "" });

    props.history.goBack();
  };
  const handleSubmit = () => {
    if (!validateForm()) {
      setOpenNotification(true);
      setErrorMsg("Please complete the form feilds");
    } else {
      const params = {
        edrId,
        staffId: currentUser._id,
        diseaseName,
        fever,
        sugarLevel,
        bloodPressure,
        cbcLevel,
      };

      addNurseReport(props, params, setOpenNotification, setErrorMsg);
      console.log(params);
    }
  };
  const validateForm = () => {
    if (
      (diseaseName && diseaseName !== " ",
      fever && fever !== " ",
      sugarLevel && sugarLevel !== " ",
      bloodPressure && bloodPressure !== " ",
      cbcLevel && cbcLevel !== " ")
    ) {
      return true;
    } else return false;
  };

  const onChangeText = (e) => {
    dispatch({ field: e.target.name, value: e.target.value });
    console.log(e.target.name);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        position: "fixed",
        width: "100%",
        height: "100%",
        backgroundColor: "#60d69f",
        overflowY: "scroll",
      }}
    >
      <Header history={props.history} />

      <div className="cPadding">
        {/* <div className="subheader">
          <div>
            <img src={LabRequests} />
            <h4>Lab Service Requests</h4>
          </div>
        </div> */}

        <div style={{ height: "20px" }} />

        <div className={`container-fluid ${classes.root}`}>
          <div className="row" style={styles.textFieldPadding}>
            <div
              className="col-md-12 col-sm-12 col-12"
              style={styles.textFieldPadding}
            >
              <TextField
                // select
                fullWidth
                label="Disease Name"
                id="diseaseName"
                name="diseaseName"
                value={diseaseName}
                onChange={onChangeText}
                variant="filled"
                className="dropDownStyle"
                InputProps={{
                  className: classes.input,
                  classes: { input: classes.input },
                  disableUnderline: true,
                }}
              />
            </div>
            <div
              className="col-md-6 col-sm-6 col-6"
              style={styles.textFieldPadding}
            >
              <TextField
                // select
                fullWidth
                label="Fever"
                id="fever"
                name="fever"
                value={fever}
                onChange={onChangeText}
                variant="filled"
                className="dropDownStyle"
                InputProps={{
                  className: classes.input,
                  classes: { input: classes.input },
                  disableUnderline: true,
                }}
              />
            </div>

            <div
              className="col-md-6 col-sm-6 col-6"
              style={styles.textFieldPadding}
            >
              <TextField
                // select
                fullWidth
                label="Sugar Level"
                id="sugarLevel"
                name="sugarLevel"
                value={sugarLevel}
                onChange={onChangeText}
                variant="filled"
                className="dropDownStyle"
                InputProps={{
                  className: classes.input,
                  classes: { input: classes.input },
                  disableUnderline: true,
                }}
              />
            </div>
            <div
              className="col-md-6 col-sm-6 col-6"
              style={styles.textFieldPadding}
            >
              <TextField
                // select
                fullWidth
                label="Blood Pressure"
                id="bloodPressure"
                name="bloodPressure"
                value={bloodPressure}
                onChange={onChangeText}
                variant="filled"
                className="dropDownStyle"
                InputProps={{
                  className: classes.input,
                  classes: { input: classes.input },
                  disableUnderline: true,
                }}
              />
            </div>
            <div
              className="col-md-6 col-sm-6 col-6"
              style={styles.textFieldPadding}
            >
              <TextField
                // select
                fullWidth
                label="CBC Level"
                id="cbcLevel"
                name="cbcLevel"
                value={cbcLevel}
                onChange={onChangeText}
                variant="filled"
                className="dropDownStyle"
                InputProps={{
                  className: classes.input,
                  classes: { input: classes.input },
                  disableUnderline: true,
                }}
              />
            </div>
          </div>

          <div className="row" style={{ marginTop: 30 }}>
            <div
              className="col-md-12  col-12  "
              style={{
                display: "flex",
                justifyContent: "space-between",
                //paddingRight: 0,
              }}
            >
              <Button
                onClick={handleCancel}
                // onClick={() => props.history.goBack()}
                style={{
                  ...styles.stylesForButton,
                  width: matches ? 150 : 302,
                  height: matches ? 45 : 40,
                }}
                variant="contained"
                color="primary"
              >
                <strong>Cancel</strong>
              </Button>

              <Button
                onClick={handleSubmit}
                style={{
                  ...styles.stylesForButton,
                  width: matches ? 150 : 302,
                  height: matches ? 45 : 40,
                }}
                variant="contained"
                color="primary"
                // disabled={notes ? false : true}
              >
                <strong>Submit</strong>
              </Button>
            </div>
          </div>

          <div className="row" style={{ marginTop: 20 }}>
            <div className="col-md-6 col-sm-6 col-12">
              <img
                onClick={() => props.history.goBack()}
                src={Back}
                style={{
                  width: 45,
                  height: 35,
                  cursor: "pointer",
                }}
              />
            </div>
          </div>
        </div>

        <Notification msg={errorMsg} open={openNotification} />
      </div>
    </div>
  );
}
