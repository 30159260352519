/*eslint-disable*/
import React, { useState, useEffect, useReducer } from "react";
import Header from "../../components/Header/Header";
import Notification from "../../components/Snackbar/Notification.js";
import CustomTable from "../../components/Table/Table";
import AssistanceRequestedIcon from "../../assets/img/AssistanceRequested.png";
import AssistanceBySenseiIcon from "../../assets/img/AssistanceBy Sensei.png";
import PlusIcon from "../../assets/img/Plus.png";
import Back from "../../assets/img/Back_Arrow.png";
import "../../assets/jss/material-dashboard-react/components/loaderStyle.css";
import Button from "@material-ui/core/Button";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Loader from "react-loader-spinner";
import TextField from "@material-ui/core/TextField";
import cookie from "react-cookies";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import InputAdornment from "@material-ui/core/InputAdornment";
import MenuItem from "@material-ui/core/MenuItem";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import AccountCircle from "@material-ui/icons/SearchOutlined";
import Paper from "@material-ui/core/Paper";
import { styles, useStyles, useStylesForTabs } from "./styles.js";
import {
  getHKRequestsData,
  getHKRequestsDataForSpecificUser,
  getNTRequestsData,
  getNTRequestsDataForSpecificUser,
  getCustomerCareRequestsData,
  getCustomerCareRequestsDataById,
  getSearchPatients,
  getAssistancesNames,
  submitAssistanceReq,
  handleEditRequestCall,
  getAssistancesNamesForSensei,
  getSenseiRequestsData,
  handleCompleteRequestCall,
  handleCompleteRequestCallHousekeeping,
} from "./networkCallsForAssistance";

const tableHeadingForCC = [
  "Customer Care ID",
  "Customer Care Name",
  "MRN",
  "Patient Name",
  "Reason",
  "Status",
];
const tableHeadingForCCById = [
  "Customer Care ID",
  "Customer Care Name",
  "MRN",
  "Patient Name",
  "Reason",
  "Status",
  "Action",
];

const tableDataKeysForCustomerCare = [
  "id",
  "name",
  "patientMRN",
  "patientName",
  "reason",
  "status",
];

const tableHeadingForNurseTech = [
  "Nurse Technician ID",
  "Nurse Technician Name",
  "MRN",
  "Patient Name",
  "Reason",
  "Status",
  "Action",
];

const tableHeadingForAllNurseTech = [
  "Nurse Technician ID",
  "Nurse Technician Name",
  "MRN",
  "Patient Name",
  "Reason",
  "status",
];

const tableDataKeysForNurseTech = [
  "id",
  "name",
  "patientMRN",
  "patientName",
  "reason",
  "status",
];
const tableHeadingForHousekeep = [
  "Housekeeping ID",
  "Housekeeping Name",
  "MRN",
  "Patient Name",
  "Reason",
  "Status",
  "Action",
];

const tableHeadingForAllHousekeep = [
  "Housekeeping ID",
  "Housekeeping Name",
  "MRN",
  "Patient Name",
  "Reason",
  "Status",
  // '',
];

const tableDataKeysForHouseKeeping = [
  "id",
  "name",
  "patientMRN",
  "patientName",
  "reason",
  "status",
];

const tableHeadingForEDNurseInSensei = [
  "ED Nurse ID",
  "ED Nurse Name",
  "MRN",
  "Patient Name",
  "Status",
  // 'Action',
];

const tableHeadingForEDNurse = [
  "ED Nurse ID",
  "ED Nurse Name",
  "MRN",
  "Patient Name",
  "Status",
  "Action",
];

const tableDataKeysForEDNurse = [
  "id",
  "name",
  "patientMRN",
  "patientName",
  "status",
];

const tableDataKeys = ["id", "name", "patientMRN", "patientName", "reason"];

const tableHeadingForHousekeeping = [
  "Housekeeping ID",
  "Housekeeping Name",
  "MRN",
  "Patient Name",
  "Reason",
  "Status",
  // 'Action',
  " ",
];

// const tableDataKeysForHouseKeeping = [
//   'id',
//   'name',
//   'patientMRN',
//   'patientName',
//   'reason',
//   'requestStatus',
// ]

const actionsForReq = { edit: true };
const actionsForSensei = { view: true };
const actions = { edit: true, view: true };

const assistanceFromArray = [
  { key: "Housekeeping", value: "Housekeeping" },
  { key: "Customer Care", value: "Customer Care" },
  { key: "Nurse Technician", value: "Nurse Technician" },
];

const StatusArray = [
  {
    key: "Pending",
    value: "pending",
  },
  {
    key: "In Progress",
    value: "in Progress",
  },
  {
    key: "Completed",
    value: "completed",
  },
];

export default function Assistance(props) {
  const classes = useStyles();
  const classesForTabs = useStylesForTabs();
  const matches = useMediaQuery("(min-width:600px)");

  const initialState = {
    assistanceFrom: "",
    assistanceName: "",
    assistanceFromSensei: "",
    assistanceNameForSensei: "",
    mrn: "",
    patientName: "",
    patientId: "",
    reason: "",
    requestStatus: "",
    remarks: "",
  };

  function reducer(state, { field, value }) {
    return {
      ...state,
      [field]: value,
    };
  }

  const [state, dispatch] = useReducer(reducer, initialState);

  const {
    assistanceFrom,
    assistanceName,
    assistanceFromSensei,
    assistanceNameForSensei,
    mrn,
    patientName,
    patientId,
    reason,
    requestStatus,
    remarks,
  } = state;

  const [currentUser] = useState(cookie.load("current_user"));
  const [errorMsg, setErrorMsg] = useState("");
  const [openNotification, setOpenNotification] = useState(false);

  const [openModal, setOpenModal] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [searchPatientQuery, setSearchPatientQuery] = useState("");
  const [patientFoundSuccessfull, setpatientFoundSuccessfully] = useState(
    false
  );
  const [patientFound, setpatientFound] = useState("");
  const [loadSearchedData, setLoadSearchedData] = useState(false);
  const [timer, setTimer] = useState(null);
  const [assistancesName, setAssistancesName] = useState([]);
  const [assistancesNameForSensei, setAssistancesNameForSensei] = useState([]);
  const [housekeepingData, setHousekeepingData] = useState([]);
  const [customerCareData, setCustomerCareData] = useState([]);
  const [nurseTechnicianData, setNurseTechnicianData] = useState([]);
  const [pathName, setPathName] = useState("");
  const [statusField, setStatusField] = useState("");
  const [selectedEdrId, setSelectedEdrId] = useState("");
  const [senseiRequestData, setSenseiRequestData] = useState("");
  const [selectedRequestId, setSelectedRequestId] = useState("");

  const [tabName, setTabName] = useState(
    currentUser.staffType === "Sensei"
      ? "ED Nurse"
      : currentUser.staffType === "House Keeping" ||
        props.history.location.pathname.includes("/bySensei") ||
        currentUser.subType[0] === "Nurse Technician" ||
        currentUser.staffType === "Customer Care"
      ? ""
      : "Housekeeping"
  );

  useEffect(() => {
    let path = props.history.location.pathname;
    if (path.includes("/requests")) {
      setPathName("requests");
    } else if (path.includes("/bySensei")) {
      setPathName("bySensei");
      // setTabName("Customer Care")
      //   props.history.replace({
      //     pathname: '/dashboard/underprogress',
      //   })
    }
  }, []);

  if (openNotification) {
    setTimeout(() => {
      setOpenNotification(false);
      setErrorMsg("");
    }, 2000);
  }

  useEffect(() => {
    if (tabName === "Housekeeping") {
      getHKRequestsData(setHousekeepingData, setErrorMsg, setOpenNotification);
    } else if (currentUser.staffType === "House Keeping") {
      getHKRequestsDataForSpecificUser(
        currentUser._id,
        setHousekeepingData,
        setErrorMsg,
        setOpenNotification
      );
    } else if (tabName === "Customer Care") {
      getCustomerCareRequestsData(
        setCustomerCareData,
        setErrorMsg,
        setOpenNotification
      );
    } else if (currentUser.staffType === "Customer Care") {
      getCustomerCareRequestsDataById(
        currentUser._id,
        setCustomerCareData,
        setErrorMsg,
        setOpenNotification
      );
    } else if (tabName === "Nurse Technician") {
      getNTRequestsData(
        setNurseTechnicianData,
        setErrorMsg,
        setOpenNotification
      );
      // console.log('**********')
    } else if (currentUser.subType[0] === "Nurse Technician") {
      getNTRequestsDataForSpecificUser(
        currentUser._id,
        setNurseTechnicianData,
        setErrorMsg,
        setOpenNotification
      );
      console.log("**********");
    } else if (tabName === "ED Nurse" || tabName === "") {
      getSenseiRequestsData(
        currentUser._id,
        tabName,
        setSenseiRequestData,
        setErrorMsg,
        setOpenNotification
      );
    }

    getAssistancesNamesForSensei(setAssistancesNameForSensei);
  }, [tabName]);

  console.log(customerCareData, "customerCareData");

  const onChangeValue = (e) => {
    if (e.target.name === "assistanceFrom") {
      getAssistancesNames(e.target.value, setAssistancesName);
    }
    // else if (currentUser.staffType === 'Sensei') {
    //   console.log('helo')
    //   getAssistancesNamesForSensei(e.target.value, setAssistancesNameForSensei)
    // }
    dispatch({
      field: e.target.name,
      value: e.target.value.replace(/,/g, ""),
    });
  };

  console.log("assistanceNameForSensei", assistanceNameForSensei);

  const handleChange = (e, tabValue) => {
    setTabName(tabValue);
  };

  const handleAddRequest = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    dispatch({ field: "assistanceFrom", value: "" });
    dispatch({ field: "mrn", value: "" });
    dispatch({ field: "patientName", value: "" });
    dispatch({ field: "reason", value: "" });
    dispatch({ field: "remarks", value: "" });
    setAssistancesName([]);
    setViewModal(false);
  };

  const handleSubmitRequest = () => {
    let params;
    if (currentUser.staffType === "Sensei") {
      params = {
        edrId: selectedEdrId,
        assignedBy: currentUser._id,
        assignedTo: assistanceNameForSensei,
        reason,
      };
    } else {
      params = {
        patientId,
        staffId: assistanceName,
        asignedBy: currentUser._id,
        staffType: assistanceFrom,
        reason,
      };
    }
    console.log("params", params);

    submitAssistanceReq(
      currentUser,
      props,
      params,
      setOpenNotification,
      setErrorMsg
    );
  };

  const handleEditRequest = () => {
    let params;
    if (currentUser.staffType === "Sensei") {
      params = {
        edrId: selectedEdrId,
        assignedBy: currentUser._id,
        assignedTo: assistanceNameForSensei,
        reason,
      };
    }

    handleEditRequestCall(
      currentUser,
      params,
      setOpenNotification,
      setErrorMsg
    );
  };

  const handleCompleteRequest = () => {
    if (currentUser.subType[0] === "ED Nurse") {
      const params = {
        requestId: selectedRequestId._id,
        nurseNotes: remarks,
      };
      console.log("params", params);

      handleCompleteRequestCall(params, setOpenNotification, setErrorMsg);
      setOpenModal(false);
      // window.location.reload(false)
    } else {
      const paramss = {
        requestId: selectedRequestId._id,
        remarks: remarks,
      };
      console.log("hello");
      handleCompleteRequestCallHousekeeping(
        paramss,
        setOpenNotification,
        setErrorMsg
      );
      setOpenModal(false);
      // window.location.reload(false)
    }
  };

  function handleView(rec) {
    setOpenModal(true);
    setViewModal(true);
    dispatch({ field: "reason", value: rec.reason });
    dispatch({ field: "remarks", value: rec.nurseNotes });
    if (
      currentUser.staffType === "House Keeping" ||
      currentUser.subType[0] === "Nurse Technician" ||
      currentUser.staffType === "Customer Care"
    ) {
      dispatch({ field: "remarks", value: rec.remarks });
    }
  }

  function handleEdit(rec) {
    console.log("handleEdit", rec);
    if (rec.status === "complete") {
      setOpenNotification(true);
      setErrorMsg("Request is already completed");
    } else {
      setSelectedRequestId(rec);
      // dispatch({ field: 'assistanceFrom', value: rec.name })
      // dispatch({ field: 'staffType', value: rec.staffType })
      dispatch({ field: "mrn", value: rec.patientMRN });
      dispatch({ field: "patientName", value: rec.patientName });
      dispatch({ field: "reason", value: rec.reason });
      dispatch({ field: "remarks", value: rec.nurseNotes });
      dispatch({ field: "assistanceNameForSensei", value: rec.name });

      setOpenModal(true);
    }
  }

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      triggerChange();
    }
  };

  const triggerChange = (a) => {
    handlePatientSearch(a);
  };

  const handlePauseSearch = (e) => {
    setLoadSearchedData(true);
    clearTimeout(timer);

    const a = e.target.value.replace(/[^\w\s]/gi, "");
    setSearchPatientQuery(a);

    setTimer(
      setTimeout(() => {
        triggerChange(a);
      }, 600)
    );
  };

  //for search patient
  const handlePatientSearch = (e) => {
    if (e.length >= 3) {
      getSearchPatients(
        e,
        setpatientFoundSuccessfully,
        setpatientFound,
        setLoadSearchedData
      );
    }
  };

  function handleAddPatient(i) {
    console.log("selected banda : ", i);
    setSelectedEdrId(i._id);

    dispatch({ field: "mrn", value: i.patientId.identifier[0].value });
    dispatch({
      field: "patientName",
      value: i.patientId.name[0].given[0] + " " + i.patientId.name[0].family,
    });
    dispatch({ field: "patientId", value: i.patientId._id });

    setSearchPatientQuery("");
    setpatientFoundSuccessfully(false);
    setpatientFound("");
    setLoadSearchedData(false);
  }

  console.log("currentUser", currentUser);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        position: "fixed",
        width: "100%",
        height: "100%",
        backgroundColor: "#60d69f",
        overflowY: "scroll",
      }}
    >
      <Header history={props.history} />

      <div className="cPadding">
        <div className="subheader" style={{ paddingRight: "15px" }}>
          {pathName === "requests" ? (
            <div>
              <img
                src={AssistanceRequestedIcon}
                alt="AssistanceRequestedIcon"
              />
              <h4>Assistance Requests</h4>
            </div>
          ) : (
            <div>
              <img src={AssistanceBySenseiIcon} alt="AssistanceBySenseiIcon" />
              <h4>
                {currentUser.subType[0] === "Nurse Technician"
                  ? "Assistance By ED Nurse"
                  : currentUser.staffType === "Nurses"
                  ? "Assistance By Sensei"
                  : "Assistance By ED Nurse"}
              </h4>
            </div>
          )}

          {pathName === "requests" ? (
            <Button
              onClick={handleAddRequest}
              style={{
                ...styles.stylesForButton,
                fontSize: matches ? " " : 10,
              }}
              variant="contained"
              color="primary"
            >
              <img src={PlusIcon} className="icon-style" />
              &nbsp;&nbsp; Add Request
            </Button>
          ) : (
            undefined
          )}
        </div>

        <div className={classesForTabs.root}>
          {pathName === "bySensei" ||
          currentUser.subType[0] === "Nurse Technician" ? (
            // <Tabs
            //   classes={{
            //     root: classesForTabs.root,
            //     scroller: classesForTabs.scroller,
            //   }}
            //   value={tabName}
            //   onChange={handleChange}
            //   textColor='primary'
            //   TabIndicatorProps={{ style: { background: '#12387a' } }}
            //   centered
            // >
            //   <Tab
            //     style={{
            //       color: 'white',
            //       borderRadius: 5,
            //       outline: 'none',
            //       color: tabName === 'Customer Care' ? '#12387a' : '#3B988C',
            //     }}
            //     label='Customer Care'
            //     value='Customer Care'
            //   />
            // </Tabs>
            <span></span>
          ) : currentUser.staffType === "Sensei" ? (
            <Tabs
              classes={{
                root: classesForTabs.root,
                scroller: classesForTabs.scroller,
              }}
              value={tabName}
              onChange={handleChange}
              textColor="primary"
              TabIndicatorProps={{ style: { background: "#12387a" } }}
              centered
            >
              <Tab
                style={{
                  color: "white",
                  borderRadius: 5,
                  outline: "none",
                  color: tabName === "ED Nurse" ? "#12387a" : "#3B988C",
                }}
                label="ED Nurse"
                value="ED Nurse"
              />
            </Tabs>
          ) : currentUser.staffType === "Nurses" ? (
            <Tabs
              classes={{
                root: classesForTabs.root,
                scroller: classesForTabs.scroller,
              }}
              value={tabName}
              onChange={handleChange}
              textColor="primary"
              TabIndicatorProps={{ style: { background: "#12387a" } }}
              centered
            >
              <Tab
                style={{
                  color: "white",
                  borderRadius: 5,
                  outline: "none",
                  color: tabName === "Housekeeping" ? "#12387a" : "#3B988C",
                }}
                label="Housekeeping"
                value="Housekeeping"
              />
              <Tab
                style={{
                  color: "white",
                  borderRadius: 5,
                  outline: "none",
                  color: tabName === "Customer Care" ? "#12387a" : "#3B988C",
                }}
                label="Customer Care"
                value="Customer Care"
              />
              <Tab
                style={{
                  color: "white",
                  borderRadius: 5,
                  outline: "none",
                  color: tabName === "Nurse Technician" ? "#12387a" : "#3B988C",
                }}
                label="Nurse Technician"
                value="Nurse Technician"
              />
            </Tabs>
          ) : (
            undefined
          )}
        </div>

        <div className="container-fluid">
          <div className="row">
            {tabName === "Housekeeping" ||
            currentUser.staffType === "House Keeping" ? (
              <div className="col-md-12 col-sm-12 col-12">
                {housekeepingData.length > 0 ? (
                  <CustomTable
                    tableData={housekeepingData}
                    tableDataKeys={tableDataKeysForHouseKeeping}
                    tableHeading={
                      currentUser.staffType === "House Keeping"
                        ? tableHeadingForHousekeep
                        : tableHeadingForAllHousekeep
                    }
                    action={
                      currentUser.staffType === "House Keeping" ? actions : ""
                    }
                    handleEdit={
                      currentUser.staffType === "House Keeping"
                        ? handleEdit
                        : ""
                    }
                    handleView={
                      currentUser.staffType === "House Keeping"
                        ? handleView
                        : ""
                    }
                    borderBottomColor={"#60d69f"}
                    borderBottomWidth={20}
                  />
                ) : housekeepingData.length === 0 ? (
                  <div style={{ marginTop: "20px" }}>
                    <h3
                      style={{
                        color: "white",
                        textAlign: "center",
                      }}
                    >
                      Oops! No Data Found
                    </h3>
                  </div>
                ) : (
                  <div style={{ margin: "auto", width: "0%", padding: 10 }}>
                    <Loader
                      type="TailSpin"
                      color="blue"
                      height={50}
                      width={50}
                    />
                  </div>
                )}
              </div>
            ) : tabName === "Customer Care" ||
              currentUser.staffType === "Customer Care" ? (
              <div className="col-md-12 col-sm-12 col-12">
                {customerCareData.length > 0 ? (
                  <CustomTable
                    tableData={customerCareData}
                    tableDataKeys={tableDataKeysForCustomerCare}
                    tableHeading={
                      currentUser.staffType === "Customer Care"
                        ? tableHeadingForCCById
                        : tableHeadingForCC
                    }
                    action={
                      currentUser.staffType === "Customer Care" ? actions : ""
                    }
                    handleEdit={
                      currentUser.staffType === "Customer Care"
                        ? handleEdit
                        : ""
                    }
                    handleView={
                      currentUser.staffType === "Customer Care"
                        ? handleView
                        : ""
                    }
                    borderBottomColor={"#60d69f"}
                    borderBottomWidth={20}
                  />
                ) : customerCareData.length === 0 ? (
                  <div style={{ marginTop: "20px" }}>
                    <h3
                      style={{
                        color: "white",
                        textAlign: "center",
                      }}
                    >
                      Oops! No Data Found
                    </h3>
                  </div>
                ) : (
                  <div style={{ margin: "auto", width: "0%", padding: 10 }}>
                    <Loader
                      type="TailSpin"
                      color="blue"
                      height={50}
                      width={50}
                    />
                  </div>
                )}
              </div>
            ) : tabName === "Nurse Technician" ||
              currentUser.subType[0] === "Nurse Technician" ? (
              <div className="col-md-12 col-sm-12 col-12">
                {nurseTechnicianData.length > 0 ? (
                  <CustomTable
                    tableData={nurseTechnicianData}
                    tableDataKeys={tableDataKeysForNurseTech}
                    tableHeading={
                      currentUser.subType[0] === "Nurse Technician"
                        ? tableHeadingForNurseTech
                        : tableHeadingForAllNurseTech
                    }
                    action={
                      currentUser.subType[0] === "Nurse Technician"
                        ? actions
                        : ""
                    }
                    handleEdit={
                      currentUser.subType[0] === "Nurse Technician"
                        ? handleEdit
                        : ""
                    }
                    handleView={
                      currentUser.subType[0] === "Nurse Technician"
                        ? handleView
                        : ""
                    }
                    borderBottomColor={"#60d69f"}
                    borderBottomWidth={20}
                  />
                ) : nurseTechnicianData.length === 0 ? (
                  <div style={{ marginTop: "20px" }}>
                    <h3
                      style={{
                        color: "white",
                        textAlign: "center",
                      }}
                    >
                      No requests for assistance by ED nurses.
                    </h3>
                  </div>
                ) : (
                  <div style={{ margin: "auto", width: "0%", padding: 10 }}>
                    <Loader
                      type="TailSpin"
                      color="blue"
                      height={50}
                      width={50}
                    />
                  </div>
                )}
              </div>
            ) : tabName === "ED Nurse" ? (
              <div className="col-md-12 col-sm-12 col-12">
                {senseiRequestData.length > 0 ? (
                  <CustomTable
                    tableData={senseiRequestData}
                    tableDataKeys={tableDataKeysForEDNurse}
                    tableHeading={
                      pathName === "bySensei"
                        ? tableHeadingForEDNurse
                        : tableHeadingForEDNurseInSensei
                    }
                    action={pathName === "bySensei" ? actionsForSensei : ""}
                    handleEdit={handleEdit}
                    handleView={handleView}
                    borderBottomColor={"#60d69f"}
                    borderBottomWidth={20}
                  />
                ) : senseiRequestData.length === 0 ? (
                  <div style={{ marginTop: "20px" }}>
                    <h3
                      style={{
                        color: "white",
                        textAlign: "center",
                      }}
                    >
                      Oops! No Data Found for Sensei Request
                    </h3>
                  </div>
                ) : (
                  <div style={{ margin: "auto", width: "0%", padding: 10 }}>
                    <Loader
                      type="TailSpin"
                      color="blue"
                      height={50}
                      width={50}
                    />
                  </div>
                )}
              </div>
            ) : (
              <div className="col-md-12 col-sm-12 col-12">
                {senseiRequestData.length > 0 ? (
                  <CustomTable
                    tableData={senseiRequestData}
                    tableDataKeys={tableDataKeysForEDNurse}
                    tableHeading={tableHeadingForEDNurse}
                    action={actions}
                    handleEdit={handleEdit}
                    handleView={handleView}
                    borderBottomColor={"#60d69f"}
                    borderBottomWidth={20}
                  />
                ) : senseiRequestData.length === 0 ? (
                  <div style={{ marginTop: "20px" }}>
                    <h3
                      style={{
                        color: "white",
                        textAlign: "center",
                      }}
                    >
                      Oopss! No Data Found
                    </h3>
                  </div>
                ) : (
                  <div style={{ margin: "auto", width: "0%", padding: 10 }}>
                    <Loader
                      type="TailSpin"
                      color="blue"
                      height={50}
                      width={50}
                    />
                  </div>
                )}
              </div>
            )}
          </div>

          <div className="row" style={{ marginBottom: 20 }}>
            <div className="col-md-12 col-sm-12 col-12">
              <img
                onClick={() => props.history.goBack()}
                src={Back}
                style={{
                  width: 45,
                  height: 35,
                  cursor: "pointer",
                }}
              />
            </div>
          </div>
        </div>

        <Dialog
          open={openModal}
          onClose={handleCloseModal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth={"lg"}
          fullWidth
        >
          <DialogContent>
            <div
              className={`container-fluid ${classes.root}`}
              style={{
                backgroundColor: "white",
                padding: "20px 10px",
              }}
            >
              <div className="row">
                {!viewModal ? (
                  <>
                    {currentUser.staffType === "House Keeping" ||
                    pathName === "bySensei" ||
                    currentUser.subType[0] === "Nurse Technician" ||
                    currentUser.staffType === "Customer Care" ? (
                      undefined
                    ) : (
                      <div
                        className="col-md-12 col-sm-12 col-12"
                        style={styles.inputFieldHeight}
                      >
                        <TextField
                          className="textInputStyle"
                          id="searchPatientQuery"
                          type="text"
                          variant="filled"
                          label="Search Patient by Name / MRN / National ID / Mobile Number"
                          name={"searchPatientQuery"}
                          value={searchPatientQuery}
                          onChange={handlePauseSearch}
                          onKeyDown={handleKeyDown}
                          InputLabelProps={{
                            classes: {
                              root: classes.label,
                              focused: classes.focusedLabel,
                              error: classes.erroredLabel,
                            },
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <AccountCircle />
                              </InputAdornment>
                            ),
                            className: classes.input,
                            classes: { input: classes.input },
                            disableUnderline: true,
                          }}
                        />
                      </div>
                    )}

                    <div className="col-md-12 col-sm-12 col-12">
                      {searchPatientQuery ? (
                        <div
                          style={{
                            zIndex: 3,
                            position: "absolute",
                            width: "97.5%",
                          }}
                        >
                          <Paper
                            style={{
                              maxHeight: 300,
                              overflow: "auto",
                              backgroundColor: "#ECECEC",
                            }}
                          >
                            {patientFoundSuccessfull && patientFound !== "" ? (
                              <Table size="small">
                                <TableHead>
                                  <TableRow>
                                    <TableCell>MRN</TableCell>
                                    <TableCell>Patient Name</TableCell>
                                    <TableCell>Gender</TableCell>
                                    <TableCell>Age</TableCell>
                                  </TableRow>
                                </TableHead>

                                <TableBody>
                                  {patientFound.map((i) => {
                                    return (
                                      <TableRow
                                        key={i._id}
                                        onClick={() => handleAddPatient(i)}
                                        style={{ cursor: "pointer" }}
                                      >
                                        <TableCell>
                                          {i.patientId.identifier[0].value}
                                        </TableCell>
                                        <TableCell>
                                          {i.patientId.name[0].given[0] +
                                            " " +
                                            i.patientId.name[0].family}
                                        </TableCell>
                                        <TableCell>
                                          {i.patientId.gender}
                                        </TableCell>
                                        <TableCell>{i.patientId.age}</TableCell>
                                      </TableRow>
                                    );
                                  })}
                                </TableBody>
                              </Table>
                            ) : loadSearchedData ? (
                              <div style={{ textAlign: "center" }}>
                                <Loader
                                  type="TailSpin"
                                  color="#2c6ddd"
                                  height={25}
                                  width={25}
                                  style={{
                                    display: "inline-block",
                                    padding: "10px",
                                  }}
                                />
                                <span
                                  style={{
                                    display: "inline-block",
                                    padding: "10px",
                                  }}
                                >
                                  <h4>Searching Patient...</h4>
                                </span>
                              </div>
                            ) : searchPatientQuery &&
                              !patientFoundSuccessfull ? (
                              <div
                                style={{ textAlign: "center", padding: "10px" }}
                              >
                                <h4> No Patient Found !</h4>
                              </div>
                            ) : (
                              undefined
                            )}
                          </Paper>
                        </div>
                      ) : (
                        undefined
                      )}
                    </div>

                    <div
                      className="col-md-6 col-sm-6 col-6"
                      style={styles.inputFieldHeight}
                    >
                      <TextField
                        disabled
                        label="Patient MRN"
                        name={"mrn"}
                        value={mrn}
                        onChange={onChangeValue}
                        className="textInputStyle"
                        variant="filled"
                        InputProps={{
                          className: classes.input,
                          classes: { input: classes.input },
                          disableUnderline: true,
                        }}
                        inputProps={{ maxLength: 40 }}
                      />
                    </div>
                    <div
                      className="col-md-6 col-sm-6 col-6"
                      style={styles.inputFieldHeight}
                    >
                      <TextField
                        disabled
                        label="Patient Name"
                        name={"patientName"}
                        value={patientName}
                        onChange={onChangeValue}
                        className="textInputStyle"
                        variant="filled"
                        InputProps={{
                          className: classes.input,
                          classes: { input: classes.input },
                          disableUnderline: true,
                        }}
                      />
                    </div>
                    {currentUser.staffType === "House Keeping" ||
                    pathName === "bySensei" ||
                    currentUser.subType[0] === "Nurse Technician" ||
                    currentUser.staffType === "Customer Care" ||
                    currentUser.staffType === "Sensei" ? (
                      undefined
                    ) : (
                      <div
                        className="col-md-12 col-sm-12 col-12"
                        style={styles.inputFieldHeight}
                      >
                        <TextField
                          select
                          label="Select Assistance"
                          name={"assistanceFrom"}
                          value={assistanceFrom}
                          onChange={onChangeValue}
                          className="textInputStyle"
                          variant="filled"
                          InputProps={{
                            className: classes.input,
                            classes: { input: classes.input },
                            disableUnderline: true,
                          }}
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>

                          {assistanceFromArray.map((array) => {
                            return (
                              <MenuItem key={array.key} value={array.value}>
                                {array.key}
                              </MenuItem>
                            );
                          })}
                        </TextField>
                      </div>
                    )}
                    {assistancesName.length > 0 ? (
                      <div
                        className="col-md-12 col-sm-12 col-12"
                        style={styles.inputFieldHeight}
                      >
                        <TextField
                          select
                          label={`${assistanceFrom} Name`}
                          name={"assistanceName"}
                          value={assistanceName}
                          onChange={onChangeValue}
                          className="textInputStyle"
                          variant="filled"
                          InputProps={{
                            className: classes.input,
                            classes: { input: classes.input },
                            disableUnderline: true,
                          }}
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>

                          {assistancesName.map((array) => {
                            return (
                              <MenuItem key={array._id} value={array._id}>
                                {array.name}
                              </MenuItem>
                            );
                          })}
                        </TextField>
                      </div>
                    ) : (
                      undefined
                    )}

                    {currentUser.staffType === "Sensei" ? (
                      <div
                        className="col-md-12 col-sm-12 col-12"
                        style={styles.inputFieldHeight}
                      >
                        <TextField
                          select
                          label={`${assistanceFromSensei} Name`}
                          name={"assistanceNameForSensei"}
                          value={assistanceNameForSensei}
                          onChange={onChangeValue}
                          className="textInputStyle"
                          variant="filled"
                          InputProps={{
                            className: classes.input,
                            classes: { input: classes.input },
                            disableUnderline: true,
                          }}
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>

                          {assistancesNameForSensei.map((array) => {
                            return (
                              <MenuItem key={array._id} value={array._id}>
                                {array.name}
                              </MenuItem>
                            );
                          })}
                        </TextField>
                      </div>
                    ) : (
                      undefined
                    )}
                  </>
                ) : (
                  <div className="col-md-12 col-sm-12 col-12">
                    <h4>Reason</h4>
                  </div>
                )}

                <div
                  className="col-md-12 col-sm-12 col-12"
                  style={styles.inputFieldHeight}
                >
                  <TextField
                    disabled={
                      viewModal ||
                      currentUser.staffType === "House Keeping" ||
                      pathName === "bySensei" ||
                      currentUser.subType[0] === "Nurse Technician" ||
                      currentUser.staffType === "Customer Care"
                        ? true
                        : false
                    }
                    multiline
                    rows={4}
                    label="Reason"
                    name={"reason"}
                    value={reason}
                    onChange={onChangeValue}
                    className="textInputStyle"
                    variant="filled"
                    InputProps={{
                      className: classes.input,
                      classes: { input: classes.input },
                      disableUnderline: true,
                    }}
                  />
                </div>

                {pathName === "bySensei" ||
                currentUser.staffType === "House Keeping" ||
                currentUser.subType[0] === "Nurse Technician" ||
                currentUser.staffType === "Customer Care" ? (
                  <div
                    className="col-md-12 col-sm-12 col-12"
                    style={styles.inputFieldHeight}
                  >
                    <TextField
                      disabled={viewModal ? true : false}
                      label="Remarks"
                      name={"remarks"}
                      value={remarks}
                      multiline
                      rows={4}
                      onChange={onChangeValue}
                      className="textInputStyle"
                      variant="filled"
                      InputProps={{
                        className: classes.input,
                        classes: { input: classes.input },
                        disableUnderline: true,
                      }}
                    />
                  </div>
                ) : (
                  undefined
                )}
              </div>
            </div>
          </DialogContent>
          <DialogActions style={{ justifyContent: "center" }}>
            <Button
              onClick={handleCloseModal}
              color="primary"
              style={styles.stylesForCancelButton}
            >
              Cancel
            </Button>
            {!viewModal ? (
              <Button
                onClick={
                  currentUser.staffType === "Sensei" && selectedRequestId
                    ? handleEditRequest
                    : (currentUser.staffType === "Nurses" &&
                        selectedRequestId) ||
                      (currentUser.staffType === "House Keeping" &&
                        selectedRequestId) ||
                      (currentUser.subType[0] === "Nurse Technician" &&
                        selectedRequestId) ||
                      (currentUser.staffType === "Customer Care" &&
                        selectedRequestId)
                    ? handleCompleteRequest
                    : handleSubmitRequest
                }
                color="primary"
                style={{ ...styles.stylesForButton, width: "140px" }}
              >
                Submit
              </Button>
            ) : (
              undefined
            )}
          </DialogActions>
        </Dialog>

        <Notification msg={errorMsg} open={openNotification} />
      </div>
    </div>
  );
}
