import React, { useEffect, useState } from 'react'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Flip from '../../../components/Dial/Flip'
import TwoValue from '../../../components/Dial/TwoValue'
import OneValue from '../../../components/Dial/OneValue'
import { imagingTechncian } from '../../../public/endpoins'
import {
  getDialerGraphCall,
  getResponse,
  getValuesCardCalls,
} from './NetworkCalls'
import Notification from '../../../components/Snackbar/Notification.js'
import Loader from 'react-loader-spinner'
import { CardObj } from './configForImagingTech'
import {
  socketUrl
} from '../../../public/endpoins'
import socketIOClient from 'socket.io-client'

export default function RadTechnician() {
  const matches = useMediaQuery('(min-width:600px)')
  const [errorMsg, setErrorMsg] = useState('')
  const [successMsg, setsuccessMsg] = useState('')
  const [openNotification, setOpenNotification] = useState(false)
  const [isLoading, setisLoading] = useState(true)

  const [responseData, setResponseData] = useState([])

  // Rad request Pending
  const [radRequestPending, setRadRequestPending] = useState('')
  const [radRequestPendingColor, setRadRequestPendingColor] = useState('')
  const [radRequestPendingTAT, setRadRequestPendingTAT] = useState('')
  const [radRequestPendingTATColor, setRadRequestPendingTATColor] = useState('')
  const [radRequestPendingGraphData, setRadRequestPendingGraphData] = useState(
    ''
  )

  // Rad result Pending
  const [radResultPending, setRadResultPending] = useState('')
  const [radResultPendingColor, setRadResultPendingColor] = useState('')
  const [radResultPendingTAT, setRadResultPendingTAT] = useState('')
  const [radResultPendingTATColor, setRadResultPendingTATColor] = useState('')
  const [radResultPendingGraphData, setRadResultPendingGraphData] = useState('')

  // Average No. of patients
  const [averageNoPatients, setAverageNoPatients] = useState('')
  const [averageNoPatientsColor, setAverageNoPatientsColor] = useState('')
  const [averageNoPatientsTAT, setAverageNoPatientsTAT] = useState('')
  const [averageNoPatientsTATColor, setAverageNoPatientsTATColor] = useState('')
  const [averageNoPatientsGraphData, setAverageNoPatientsGraphData] = useState(
    ''
  )

  const [comulativePatients, setcomulativePatients] = useState('')
  const [comulativePatientsColor, setcomulativePatientsColor] = useState('')
  const [flag1Count, setFlag1Count] = useState(0)
  const [flag2Count, setFlag2Count] = useState(0)
  const [flag3Count, setFlag3Count] = useState(0)

  const [flip, setFlip] = useState(false)

  useEffect(() => {
    getResponse(
      imagingTechncian,
      setResponseData,
      setOpenNotification,
      setErrorMsg,
      setisLoading
    )
    console.log('*in getResponse edDoctor')

    // for(let i = 0; i < CardObj.length; i++){
    //   console.log('Card Obj', CardObj[i].key)
    // }
    const flagCount = [{value: '1st', setFlagCount: (value) => setFlag1Count(value)},
    {value: '2nd', setFlagCount: (value) => setFlag2Count(value)},
    {value: '3rd', setFlagCount: (value) => setFlag3Count(value)}];

    const socket = socketIOClient(socketUrl)
      socket.emit('rad_flags')
      socket.on('pendingRad', (data) => {
        console.log('response coming through socket for Rad Flags', data)
        flagCount.forEach(({value, setFlagCount}) => {
          const cardCount = data.filter(item => item.card === value).length;
          cardCount && setFlagCount(cardCount);
        })

        console.log('data', data)
        
      })

  }, [])

  console.log('flag1', flag1Count)
  console.log('flag2', flag2Count)
  console.log('flag3', flag3Count)

  

  if (openNotification) {
    setTimeout(() => {
      setOpenNotification(false)
      setErrorMsg('')
      setsuccessMsg('')
    }, 2000)
  }

  useEffect(() => {
    let arr = []

    Object.entries(responseData).map(([key, val]) => {
      if (key !== 'success') {
        console.log('key of data', key, 'val of data', val)
        arr.push({ [key]: val })
      }
    })

    console.log('arr::', arr)
    if (arr && arr.length > 0) {
      getDialerGraphCall(
        3,
        6,
        30,
        45,
        arr[0].firstCard,
        setRadRequestPending,
        setRadRequestPendingColor,
        setRadRequestPendingTAT,
        setRadRequestPendingTATColor,
        setRadRequestPendingGraphData
      )
      getDialerGraphCall(
        3,
        6,
        60,
        180,
        arr[1].secondCard,
        setRadResultPending,
        setRadResultPendingColor,
        setRadResultPendingTAT,
        setRadResultPendingTATColor,
        setRadResultPendingGraphData
      )
      getDialerGraphCall(
        2,
        4,
        20,
        30,
        arr[2].thirdCard,
        setAverageNoPatients,
        setAverageNoPatientsColor,
        setAverageNoPatientsTAT,
        setAverageNoPatientsTATColor,
        setAverageNoPatientsGraphData
      )

      getValuesCardCalls(
        20,
        30,
        arr[3].cumulativePatients,
        setcomulativePatients,
        setcomulativePatientsColor
      )
    }
  }, [responseData])

  const flipIt = () => {
    setFlip(true)
  }
  return (
    <div className='container-fluid' style={{ marginBottom: 10 }}>
      <div className='row'>
        <div
          className='col-md-4'
          style={{ paddingLeft: 4, paddingRight: 4, marginTop: 10 }}
        >
          <div>
            <Flip
              flipped={!flip}
              flipHandler={flipIt}
              mainHeading={'Radiology Imaging Requests Pending'}
              value={
                isLoading ? (
                  <Loader
                    type='TailSpin'
                    color='green'
                    height={35}
                    width={35}
                  />
                ) : (
                  radRequestPending
                )
              }
              mainHeadingForGraph='Radiology Imaging Requests Pending'
              color={radRequestPendingColor}
              subHeading={'TAT'}
              flagsCount={flag1Count}
              childHeading={'Request to Imaging'}
              time={
                isLoading ? (
                  <Loader
                    type='TailSpin'
                    color='green'
                    height={35}
                    width={35}
                  />
                ) : radRequestPendingTAT === null ? (
                  0
                ) : (
                  radRequestPendingTAT
                )
              }
              timecolor={radRequestPendingTATColor}
              idForGraph={'container1'}
              data={radRequestPendingGraphData}
            />
          </div>
        </div>

        <div
          className='col-md-4'
          style={{ paddingLeft: 4, paddingRight: 4, marginTop: 10 }}
        >
          <div>
            <Flip
              flipped={!flip}
              flipHandler={flipIt}
              mainHeading={'Radiology Results Pending'}
              mainHeadingForGraph={'Radiology Results Pending'}
              value={
                isLoading ? (
                  <Loader
                    type='TailSpin'
                    color='green'
                    height={35}
                    width={35}
                  />
                ) : (
                  radResultPending
                )
              }
              color={radResultPendingColor}
              flagsCount={flag2Count}
              subHeading={'TAT'}
              childHeading={'Request to Completion'}
              time={
                isLoading ? (
                  <Loader
                    type='TailSpin'
                    color='green'
                    height={35}
                    width={35}
                  />
                ) : radResultPendingTAT === null ? (
                  0
                ) : (
                  radResultPendingTAT
                )
              }
              timecolor={radResultPendingTATColor}
              idForGraph={'container2'}
              data={radResultPendingGraphData}
            />
          </div>
        </div>

        <div
          className='col-md-4'
          style={{ paddingLeft: 4, paddingRight: 4, marginTop: 10 }}
        >
          <div>
            <Flip
              flipped={!flip}
              flipHandler={flipIt}
              mainHeading={'Average No. of Patients/Hour'}
              mainHeadingForGraph={'Average No. of Patients/Hour'}
              value={
                isLoading ? (
                  <Loader
                    type='TailSpin'
                    color='green'
                    height={35}
                    width={35}
                  />
                ) : (
                  averageNoPatients
                )
              }
              color={averageNoPatientsColor}
              subHeading={'TAT'}
              childHeading={'Per Patient'}
              time={
                isLoading ? (
                  <Loader
                    type='TailSpin'
                    color='green'
                    height={35}
                    width={35}
                  />
                ) : averageNoPatientsTAT === null ? (
                  0
                ) : (
                  averageNoPatientsTAT
                )
              }
              timecolor={averageNoPatientsTATColor}
              idForGraph={'container3'}
              data={averageNoPatientsGraphData}
            />
          </div>
        </div>
      </div>

      <div className='row'>
        <div
          className='col-md-4'
          style={{ paddingLeft: 4, paddingRight: 4, marginTop: 10 }}
        >
          <div>
            <OneValue
              time={
                isLoading ? (
                  <Loader
                    type='TailSpin'
                    color='green'
                    height={35}
                    width={35}
                  />
                ) : (
                  comulativePatients
                )
              }
              colorTime={comulativePatientsColor}
              heading={'Cumulative total Patients Processed'}
            />
          </div>
        </div>
      </div>

      <Notification
        msg={errorMsg}
        open={openNotification}
        success={successMsg}
      />
    </div>
  )
}
