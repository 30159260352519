import React, { useEffect, useState } from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import DailerMobileView from "./MobileFlip";
import ExclamatorySignIcon from "../../assets/img/ExclamatoryForFlag.png";

import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

// Radial separators
import RadialSeparators from "./RadialSeperators";

const styles = {
  stylesForLabelValue: {
    position: "absolute",
    fontSize: 10,
    opacity: "70%",
  },

  stylesForBars: {
    position: "absolute",
    fontWeight: "bold",
    opacity: "70%",
  },
};

function Dialer(props) {
  const matches = useMediaQuery("(min-width:600px)");
  const [scaleOnXside, setScaleOnXside] = useState([]);
  const [options, setOptions] = useState("");

  useEffect(() => {
    let temp = [];
    for (let i = 6; i > 0; i--) {
      let date = new Date();

      var hours = date.getHours() - i;
      var minutes = date.getMinutes();
      var ampm = hours >= 12 ? "pm" : "am";
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      // minutes = minutes < 10 ? "0" + minutes : minutes;
      minutes = "00";
      var strTime = hours + ":" + minutes + " " + ampm;
      temp.push(hours);
      // console.log(strTime);
    }

    setOptions(options);
  }, []);

  // const viewFlags = () => {
  //   props.history.push({
  //     state: {
  //       pathname: '/dashboard/home/flagMgmt'
  //     },
  //   });
  // }

  return (
    <div
      onClick={() => props.flipHandler(false)}
      style={{
        width: "100%",
        // height: '100%',
        backgroundColor: "white",
        borderRadius: 5,
        height: 270,
      }}
    >
      <div
        style={{
          display: "flex",
          height: "14%",
          justifyContent: "center",
          alignItems: "center",
          padding: matches ? "" : "0  6px",
        }}
      >
        <span
          style={{
            fontSize: matches ? 14 : 12,
            fontWeight: "700",
            opacity: matches ? 0.8 : 1,
            textAlign: "center",
            paddingTop: 24,
          }}
        >
          {props.mainHeading}
        </span>
      </div>

      <div
        style={{
          height: "58%",
          width: "100%",
          justifyContent: "center",
          display: "flex",
          alignItems: "center",
          paddingTop: 24,
          cursor: "pointer" 
        }}
      >
        <div
          style={{ width: matches ? 135 : 100, height: matches ? 135 : 100 }}
        >
          <p
            style={{
              ...styles.stylesForLabelValue,
              marginTop: matches ? 105 : 73,
              marginLeft: matches ? -5 : -7,
            }}
          >
            00
          </p>

          <span
            style={{
              position: "absolute",
              transform: matches ? "rotate(-10deg)" : "rotate(-11deg)",
              zIndex: 3,
              marginTop: matches ? 66 : 42,
              fontWeight: "bold",
              opacity: "70%",
              marginLeft: -8,
            }}
          >
            -
          </span>

          <span
            style={{
              ...styles.stylesForBars,
              transform: "rotate(20deg)",
              marginTop: matches ? 23 : 16,
              marginLeft: -4,
            }}
          >
            -
          </span>

          <span
            style={{
              transform: "rotate(75deg)",
              marginTop: matches ? -14 : -17,
              marginLeft: matches ? 45 : 35,
              ...styles.stylesForBars,
            }}
          >
            -
          </span>

          <span
            style={{
              transform: "rotate(105deg)",
              marginTop: matches ? -14 : -17,
              marginLeft: matches ? 85 : 62,
              ...styles.stylesForBars,
            }}
          >
            -
          </span>

          <span
            style={{
              transform: "rotate(130deg)",
              marginTop: matches ? 4 : -4,
              marginLeft: matches ? 115 : 86,
              ...styles.stylesForBars,
            }}
          >
            -
          </span>

          <span
            style={{
              transform: "rotate(10deg)",
              marginTop: matches ? 65 : 43,
              marginLeft: matches ? 136 : 103,
              ...styles.stylesForBars,
            }}
          >
            -
          </span>

          <p
            style={{
              ...styles.stylesForLabelValue,
              marginLeft: matches ? 7 : 3,
              marginTop: matches ? 3 : 1,
            }}
          >
            40
          </p>
          <p
            style={{
              ...styles.stylesForLabelValue,
              marginTop: matches ? 35 : 23,
              marginLeft: matches ? 140 : 99,
            }}
          >
            80
          </p>
          <p
            style={{
              ...styles.stylesForLabelValue,
              marginTop: matches ? 103 : 73,
              marginLeft: matches ? 125 : 96,
            }}
          >
            100
          </p>

          <CircularProgressbarWithChildren
            value={props.value}
            text={`${""}`}
            strokeWidth={20}
            circleRatio={0.77}
            styles={buildStyles({
              strokeLinecap: "butt",
              rotation: 1 / 2 + 1 / 8.7,
              pathColor: props.color,
              textColor: props.color,
              trailColor: "#F2F2F2",
              width: 30,
              height: 40,
              textSize: "30px",
            })}
          >
            <>
              <RadialSeparators
                count={13}
                style={{
                  background: "#fff",
                  width: "2px",
                  // This needs to be equal to props.strokeWidth
                  height: `${20}%`,
                  width: 2.5,
                }}
              />
              <span
                style={{
                  fontFamily: "Roboto",
                  fontSize: matches ? 37 : 30,
                  color: props.color,
                  fontWeight: "600",
                }}
              >
                {props.value === 0 ? "0" : props.value}
              </span>
            </>
          </CircularProgressbarWithChildren>
        </div>
      </div>

      <div style={{ width: "100%", height: 0.5, backgroundColor: "grey" }} />

      <div
        className="container-fluid"
        style={{
          height: "27.5%",
        }}
      >
        <div
          className="row"
          style={{
            height: "100%",
          }}
        >
          <div
            className="col-9"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <span style={{ fontWeight: "bolder", opacity: 0.7 }}>
              {props.subHeading}
              {props.flagsCount ? (
                <
                  // style={{
                  //   paddingLeft: 10,
                  //   display: "inline-flex",
                  //   fontWeight: "bolder",
                  // }}
                >
                  <img
                    src={ExclamatorySignIcon}
                    // onClick={viewFlags}
                    style={{ marginLeft: 10, marginRight: 7, marginTop: -2, width: 20, height: 20, cursor: 'pointer' }}
                  />
                  {props.flagsCount}
                </>
              ) : (
                undefined
              )}
            </span>
            <span
              style={{
                fontSize: matches ? 12 : 10,
                opacity: 0.7,
                textOverflow: "ellipsis",
                width: "100%",
                whiteSpace: "nowrap",
                overflow: "hidden",
              }}
            >
              {props.childHeading}
            </span>
          </div>

          <div
            className="col-3"
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              paddingLeft: 0,
              paddingRight: 0,
              alignItems: "center",
            }}
          >
            <span
              style={{
                color: props.timecolor ? props.timecolor : "#60D69F",
                // color: props.timecolor,
                fontSize: matches ? 40 : 20,
                fontWeight: "900",
                position: "absolute",
                marginTop: matches ? -10 : 12,
                marginRight: props.time > 1000 ? 80 : 0,
                //           window.innerWidth <= 600
                // ? 300
                // : (window.innerWidth >= 601) & (window.innerWidth <= 768)
                // ? 220
                // : 420
              }}
            >
              {props.time}
            </span>
            <span
              style={{
                fontSize: matches ? 13 : 10,
                position: "absolute",
                marginTop: matches ? 43 : 42,
                // paddingLeft: matches ? "inherit" : 8,
                opacity: "70%",
                textOverflow: "ellipsis",
                width: matches ? undefined : "78%",
                whiteSpace: "nowrap",
                overflow: "hidden",
              }}
            >
              Minutes
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Dialer;
