/*eslint-disable*/
import React, { useState, useEffect, useReducer } from "react";
import { styles } from "./staffStyles.js";
import Button from "@material-ui/core/Button";
import Notification from "../../../components/Snackbar/Notification.js";
import CustomTable from "../../../components/Table/Table";
import ConfirmationModal from "../../../components/Modal/confirmationModal";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Loader from "react-loader-spinner";
import Header from "../../../components/Header/Header";
import business_Unit from "../../../assets/img/Staff.png";
import plus_icon from "../../../assets/img/Plus.png";
import Back from "../../../assets/img/Back_Arrow.png";
import "../../../assets/jss/material-dashboard-react/components/loaderStyle.css";
import cookie from "react-cookies";
import DateFnsUtils from "@date-io/date-fns";
import ExtractTimeFromDate from "../../../utils/extractTimeFromDate";
import useStyles from "../../../assets/jss/material-dashboard-react/greyInputStyle";
import { MuiPickersUtilsProvider, TimePicker } from "@material-ui/pickers";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import AccountCircle from "@material-ui/icons/SearchOutlined";
import InputAdornment from "@material-ui/core/InputAdornment";
import BarCode from "../../../assets/img/Bar Code.png";

import Fingerprint from "../../../assets/img/fingerprint.png";

import EnableDisableModal from "../../../components/EnableDisableModal/enableDisableModal";

import {
  getDoctorSpecialtyFunc,
  getAllStaffType,
  getNursesSpecialtyFunc,
  deleteStaff,
  enableDisalbleStaff,
  allChiefComplaints,
  getAllPAsByCCs,
  updateAssignShift,
  ShiftsCall,
} from "./networkCallsForStaff";

const useStylesForTabs = makeStyles(() => ({
  root: {
    justifyContent: "center",
  },
  scroller: {
    flexGrow: "0",
  },
}));

const tableHeading = ["ID", "Name", "Added By", "Date/Time", "Action"];
const tableDataKeys = ["id", "stafName", "addedByName", "createdAt"];

const tableHeadingForDoctors = [
  "ID",
  "Doctor Name",
  "Added By",
  "Doctor Type",
  "Speciality",
  "Date/Time",
  "Action",
];
const tableDataKeysForDoctors = [
  "id",
  "stafName",
  "addedByName",
  "subType",
  "specialty",
  "createdAt",
];

const tableHeadingForShift = [
  "ID",
  "Name",
  // 'Production Area',
  "Shift",
  "Work From",
  "Work To",
  "Action",
];
const tableDataKeysForShift = [
  "id",
  "stafName",
  // 'production',
  "staffShift",
  "startTime",
  "endTime",
];

const tableHeadingForDoctorsShift = [
  "ID",
  "Name",
  // 'Production Area',
  "Doctor Type",
  "Speciality",
  "Shift",
  "Work From",
  "Work To",
  "Action",
];
const tableDataKeysForDoctorsShift = [
  "id",
  "stafName",
  // 'production',
  "subType",
  "specialty",
  "staffShift",
  "startTime",
  "endTime",
];

const tableHeadingForNursesShift = [
  "ID",
  "Name",
  // 'Production Area',
  "Nurse Type",
  "Speciality",
  "Shift",
  "Work From",
  "Work To",
  "Action",
];
const tableDataKeysForNursesShift = [
  "id",
  "stafName",
  // 'production',
  "subType",
  "specialty",
  "staffShift",
  "startTime",
  "endTime",
];

const tableHeadingForNurses = [
  "ID",
  "Nurse Name",
  "Added By",
  "Nurse Type",
  "Speciality",
  "Date/Time",
  "Action",
];
const tableDataKeysForNurses = [
  "id",
  "stafName",
  "addedByName",
  "subType",
  "specialty",
  "createdAt",
];

const tableHeadingForPorter = [
  "ID",
  "Porter Name",
  "Added By",
  "Speciality",
  "Date/Time",
  "Action",
];
const tableDataKeysForPorter = [
  "id",
  "stafName",
  "subType",
  "specialty",
  "createdAt",
];




const shiftArray = [
  {
    key: "Morning",
    value: "Morning",
  },
  {
    key: "Evening",
    value: "Evening",
  },
  {
    key: "Night",
    value: "Night",
  },
];

const actions = { view: true, enableDisable: true };
const action = { edit: true };

export default function Staff(props) {
  const matches = useMediaQuery("(min-width:600px)");
  const classesForTabs = useStylesForTabs();
  const classes = useStyles();
  const [staffData, setStaffData] = useState([]);
  const [generalArray, setGeneralArray] = useState([]);
  const [filteredArray, setFilteredArray] = useState([]);
  const [doctorSpeciality, setDoctorSpeciality] = useState([]);
  const [nursesSpeciality, setNursesSpeciality] = useState([]);

  const [deleteItem, setdeleteItem] = useState("");
  const [modalVisible, setModalVisible] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [openNotification, setOpenNotification] = useState(false);
  const [open, setOpen] = useState(false);
  const [tabName, setTabName] = useState("Sensei");
  const [specialist, setSpecialist] = useState("");
  const [openEnableDisabledModal, setOpenEnableDisabledModal] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState("");
  const [currentUser, setCurrentUser] = useState(cookie.load("current_user"));
  const [staffId, setStaffId] = useState("");
  const [detailsForm, setDetailsForm] = useState(false);
  const [searchPatientQuery, setSearchPatientQuery] = useState("");
  const [loading, setLoading] = useState(true);

  const initialState = {
    shift: "",
    shiftStartTime: "",
    shiftEndTime: "",
    productionAreaName: "",
    chiefComplaint: "",
    chiefComplaintList: [],
    productionAreaList: [],
    shiftList: [],
    reason: "",
  };
  const [state, dispatch] = useReducer(reducer, initialState);

  function reducer(state, { field, value }) {
    return {
      ...state,
      [field]: value,
    };
  }

  const {
    shift,
    shiftStartTime,
    shiftEndTime,
    productionAreaName,
    chiefComplaint,
    chiefComplaintList,
    productionAreaList,
    shiftList,
    reason,
  } = state;

  const handleClose = () => {
    dispatch({
      field: "chiefComplaint",
      value: "",
    });
    dispatch({ field: "reason", value: "" });
    dispatch({
      field: "productionAreaName",
      value: "",
    });
    dispatch({
      field: "shiftStartTime",
      value: "",
    });
    dispatch({
      field: "shiftEndTime",
      value: "",
    });
    dispatch({
      field: "shift",
      value: "",
    });
    setOpen(false);
  };

  const onChangeValue = (e) => {
    console.log("e.target.value", e.target.value);
    dispatch({ field: e.target.name, value: e.target.value });
  };

  const onChangeSpecialist = (e) => {
    // console.log("e", e.target.value);
    setSpecialist(e.target.value);
    if (e.target.value) {
      const temp = generalArray.filter((val) =>
        val.specialty.find((s) => s === e.target.value)
      );
      setFilteredArray(temp);
    }
  };

  if (openNotification) {
    setTimeout(() => {
      setOpenNotification(false);
      setErrorMsg("");
    }, 2000);
  }

  useEffect(() => {
    getAllStaffType(
      setLoading,
      setStaffData,
      setGeneralArray,
      setOpenNotification,
      setErrorMsg
    );
    getDoctorSpecialtyFunc(
      setDoctorSpeciality,
      setOpenNotification,
      setErrorMsg
    );
    getNursesSpecialtyFunc(
      setNursesSpeciality,
      setOpenNotification,
      setErrorMsg
    );

    if (currentUser.staffType === "Sensei") {
      getChiefComplaint();
      ShiftsCall(dispatch);
    }
  }, []);

  useEffect(() => {
    let tabTableData = [];
    tabTableData = staffData.filter(
      (d) => d.staffType !== "" && d.staffType === tabName
    );
    console.log("log", tabTableData);
    tabTableData.map((d) => {
      return (
        (d.stafName = d.name[0].given[0] + " " + d.name[0].family),
        (d.id = d.identifier[0].value),
        (d.addedByName =
          d.addedBy.name[0].given[0] + " " + d.addedBy.name[0].family),
        // (d.production =
        //   d.chiefComplaint && d.chiefComplaint.length > 0 ? d.chiefComplaint[d.chiefComplaint.length - 1].chiefComplaintId.productionArea[d.chiefComplaint[d.chiefComplaint.length - 1].chiefComplaintId.productionArea.length - 1].productionAreaId.paName
        //     : ''),
        // (d.specialty = d.specialty && d.specialty.join(", ").split(',')),
        (d.staffShift = d.shift ? d.shift.name : "N/A"),
        (d.startTime = d.shift
          ? ExtractTimeFromDate(d.shift.startTime)
          : "N/A"),
        (d.endTime = d.shift ? ExtractTimeFromDate(d.shift.endTime) : "N/A")
      );
    });
    setGeneralArray(tabTableData);
  }, [tabName]);

  const handleChange = (event, tabValue) => {
    // console.log("tabValue", tabValue);
    setSpecialist("");
    setTabName(tabValue);
  };

  const handleChangeDate = (value, field) => {
    dispatch({ field: field, value: value.toISOString() });
  };

  function getChiefComplaint() {
    allChiefComplaints(dispatch);
  }

  function handleEdit(rec) {
    console.log("rec", rec);
    setOpen(true);
    setStaffId(rec._id);
    if (rec) {
      Object.entries(rec).map(([key, val]) => {
        if (key === "chiefComplaint" && val.length > 0) {
          dispatch({
            field: "chiefComplaint",
            value: val[val.length - 1].chiefComplaintId._id,
          });
          getProductionsAreas(val[val.length - 1].chiefComplaintId._id);
          dispatch({
            field: "productionAreaName",
            value:
              val[val.length - 1].chiefComplaintId.productionArea[
                val[val.length - 1].chiefComplaintId.productionArea.length - 1
              ].productionAreaId._id,
          });
        } else if (key === "shift") {
          dispatch({
            field: "shift",
            value: val._id,
          });

          dispatch({
            field: "shiftStartTime",
            value: val.startTime,
          });
          dispatch({
            field: "shiftEndTime",
            value: val.endTime,
          });
        } else if (key === "updateRecord" && val.length > 0) {
          dispatch({ field: "reason", value: val[val.length - 1].reason });
        }
        // else if (key === 'productionArea' && val.length > 0) {
        //   console.log('val', val[val.length - 1])
        //   dispatch({
        //     field: 'productionAreaName',
        //     value:
        //       val.length > 0 &&
        //       val[val.length - 1].productionAreaId &&
        //       val[val.length - 1].productionAreaId._id,
        //   })
        // }
        else {
          dispatch({ field: key, value: val });
        }
      });
    }
  }

  console.log("shiftList::", shiftList);

  const changeHandler = (e) => {
    dispatch({ field: "chiefComplaint", value: e.target.value });
    getProductionsAreas(e.target.value);
  };

  const shiftHandler = (e) => {
    dispatch({ field: "shift", value: e.target.value });
    shiftList.map((d) => {
      if (d._id === e.target.value) {
        dispatch({ field: "shiftStartTime", value: d.startTime });
        dispatch({ field: "shiftEndTime", value: d.endTime });
      }
    });
  };

  const getProductionsAreas = (value) => {
    getAllPAsByCCs(value, dispatch, setOpenNotification, setErrorMsg);
  };

  const changeHandlerProductionArea = (e) => {
    dispatch({ field: "productionAreaName", value: e.target.value });
  };

  console.log("productionAreaList", productionAreaList);

  // console.log("general array", generalArray);

  const addNewItem = () => {
    let path = `staff/add`;
    props.history.push({
      pathname: path,
      state: {
        staffType: tabName,
        comingFor: "add",
        doctorSpeciality,
        nursesSpeciality,
      },
    });
  };

  function handleView(rec) {
    let path = `staff/edit`;
    // console.log("rec", rec);
    props.history.push({
      pathname: path,
      state: {
        comingFor: "edit",
        selectedItem: rec,
        staffType: tabName,
        doctorSpeciality,
        nursesSpeciality,
      },
    });
  }

  function validateDetails() {
    return (
      shift &&
      shift.length > 0 &&
      shiftStartTime &&
      shiftStartTime.length > 0 &&
      shiftEndTime &&
      shiftEndTime.length > 0 &&
      chiefComplaint &&
      chiefComplaint.length > 0 &&
      productionAreaName &&
      productionAreaName.length > 0 &&
      reason &&
      reason.length > 0
    );
  }

  const updateStaffShift = () => {
    if (validateDetails()) {
      let params = {
        chiefComplaint,
        productionAreaName,
        assignedBy: currentUser._id,
        staffId: staffId,
        shift,
        shiftStartTime,
        shiftEndTime,
        reason,
      };
      console.log("params", params);
      updateAssignShift(params, setOpenNotification, setErrorMsg);
    } else {
      setOpenNotification(true);
      setErrorMsg("Please Fill the the empty fields with data");
    }
    setDetailsForm(true);
  };

  function handleDisable(id) {
    setModalVisible(true);
    setdeleteItem(id);
  }

  function deleteVendor() {
    deleteStaff(
      deleteItem,
      setdeleteItem,
      setModalVisible,
      setErrorMsg,
      setOpenNotification
    );
  }

  function enableDisableRecord(rec) {
    setOpenEnableDisabledModal(true);
    setSelectedRecord(rec);
  }

  function handleConfirmDisable(reason) {
    let obj = {
      reason: reason,
      staffId: currentUser._id,
    };

    enableDisalbleStaff(
      selectedRecord,
      obj,
      setOpenEnableDisabledModal,
      setSelectedRecord,
      setErrorMsg,
      setOpenNotification
    );
  }
  const handlePatientSearch = (e) => {
    const a = e.target.value.replace(/[^\+\s]/gi, "");
    setSearchPatientQuery(a);
    if (a.length >= 3) {
      searchEdrPatientsForCC(
        a,
        setGeneralArray,
        setErrorMsg,
        setOpenNotification
      );
    } else if (a.length == 0) {
      getAllPatients(
        setStaffData,
        setGeneralArray,
        setOpenNotification,
        setErrorMsg
      );
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        position: "fixed",
        width: "100%",
        height: "100%",
        backgroundColor: "#60d69f",
        overflowY: "scroll",
      }}
    >
      <Header history={props.history} />

      <div className="cPadding">
        <div className="subheader">
          <div
            style={{ width: "100% !importent" }}
            // className="row"
            // style={{
            //   display: "flex",
            //   flex: 1,
            //   // paddingLeft: 6,
            //   // paddingRight: 6,
            // }}
          >
            {/* <div
              style={{
                display: "flex",
                flex: 1,
                marginTop: "2%",
                marginBottom: "2%",
              }}
            > */}
            <img src={business_Unit} />
            <h4>
              {currentUser.staffType === "Admin"
                ? "Staff Management"
                : "Staff Management (Shift)"}
            </h4>
          </div>

          {/* <div
              style={{
                // ...styles.inputContainerForTextField,
                // ...styles.textFieldPadding,
                display: "flex",
                justifyContent: "flex-end",
              }}
            > */}

          {/* {matches ? (
            (currentUser.staffType === "Admin" ||
              currentUser.staffType === "Sensei") &&
            (tabName === "Doctor" || tabName === "Nurses") ? (
              <TextField
                select
                name="specialist"
                label="specialist"
                onChange={onChangeSpecialist}
                label="Specialists"
                variant="filled"
                className="textInputStyle"
                InputProps={{
                  className: classes.input,
                  classes: { input: classes.input },
                }}
                InputLabelProps={{
                  classes: {
                    root: matches ? classes.labelRoot : "",
                  },
                }}
                value={specialist}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>

                {tabName === "Doctor"
                  ? doctorSpeciality &&
                    doctorSpeciality.map((val) => {
                      return <MenuItem value={val}>{val}</MenuItem>;
                    })
                  : tabName === "Nurses"
                  ? nursesSpeciality &&
                    nursesSpeciality.map((val) => {
                      return <MenuItem value={val}>{val}</MenuItem>;
                    })
                  : undefined}
              </TextField>
            ) : (
              undefined
            )
          ) : (
            undefined
          )} */}

          {currentUser.staffType === "Admin" ? (
            <Button
              onClick={addNewItem}
              style={{
                ...styles.stylesSubmitButton,
                fontSize: matches ? " " : 10,
                height: matches ? 45 : 40,
              }}
              variant="contained"
              color="primary"
            >
              <img
                src={plus_icon}
                style={{ marginRight: 5 }}
                className="icon-style"
              />

              <strong>Add New</strong>
            </Button>
          ) : (
            undefined
          )}
          {/* </div> */}
        </div>
        {!matches ? (
          <div className="row" style={{ marginTop: 20, marginBottom: 10 }}>
            {" "}
            <div className="col-12">
              {(currentUser.staffType === "Admin" ||
                currentUser.staffType === "Sensei") &&
              (tabName === "Doctor" || tabName === "Nurses") ? (
                <TextField
                  select
                  name="specialist"
                  label="specialist"
                  onChange={onChangeSpecialist}
                  label="Specialists"
                  variant="filled"
                  className="textInputStyle"
                  InputProps={{
                    className: classes.input,
                    classes: { input: classes.input },
                  }}
                  InputLabelProps={{
                    classes: {
                      root: matches ? classes.labelRoot : "",
                    },
                  }}
                  value={specialist}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>

                  {tabName === "Doctor"
                    ? doctorSpeciality &&
                      doctorSpeciality.map((val) => {
                        return <MenuItem value={val}>{val}</MenuItem>;
                      })
                    : tabName === "Nurses"
                    ? nursesSpeciality &&
                      nursesSpeciality.map((val) => {
                        return <MenuItem value={val}>{val}</MenuItem>;
                      })
                    : undefined}
                </TextField>
              ) : (
                undefined
              )}{" "}
            </div>{" "}
          </div>
        ) : (
          <div className="row" style={{ marginTop: 20, marginBottom: 10 }}>
            {" "}
            <div className="col-md-3">
              {(currentUser.staffType === "Admin" ||
                currentUser.staffType === "Sensei") &&
              (tabName === "Doctor" || tabName === "Nurses") ? (
                <TextField
                  select
                  name="specialist"
                  label="specialist"
                  onChange={onChangeSpecialist}
                  label="Specialists"
                  variant="filled"
                  className="textInputStyle"
                  InputProps={{
                    className: classes.input,
                    classes: { input: classes.input },
                  }}
                  // InputLabelProps={{
                  //   classes: {
                  //     root: matches ? classes.labelRoot : "",
                  //   },
                  // }}
                  value={specialist}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>

                  {tabName === "Doctor"
                    ? doctorSpeciality &&
                      doctorSpeciality.map((val) => {
                        return <MenuItem value={val}>{val}</MenuItem>;
                      })
                    : tabName === "Nurses"
                    ? nursesSpeciality &&
                      nursesSpeciality.map((val) => {
                        return <MenuItem value={val}>{val}</MenuItem>;
                      })
                    : undefined}
                </TextField>
              ) : (
                undefined
              )}{" "}
            </div>{" "}
          </div>
        )}

        {generalArray ? (
          <>
            {/* <div>
              <h6> Search Box</h6>
            </div> */}

            <div className={classesForTabs.root}>
              <Tabs
                classes={{
                  root: classesForTabs.root,
                  scroller: classesForTabs.scroller,
                }}
                value={tabName}
                onChange={handleChange}
                textColor="primary"
                variant="scrollable"
                TabIndicatorProps={{ style: { background: "#12387a" } }}
              >
                <Tab
                  style={{
                    color: "white",
                    borderRadius: 5,
                    outline: "none",
                    color: tabName === "Sensei" ? "#12387a" : "#3B988C",
                  }}
                  label="Sensei"
                  value="Sensei"
                />
                <Tab
                  style={{
                    color: "white",
                    borderRadius: 5,
                    outline: "none",
                    color: tabName === "Doctor" ? "#12387a" : "#3B988C",
                  }}
                  label="Doctor"
                  value="Doctor"
                />
                <Tab
                  style={{
                    color: "white",
                    borderRadius: 5,
                    outline: "none",
                    color: tabName === "Nurses" ? "#12387a" : "#3B988C",
                  }}
                  label="Nurses"
                  value="Nurses"
                />
                <Tab
                  style={{
                    color: "white",
                    borderRadius: 5,
                    outline: "none",
                    color: tabName === "Porter" ? "#12387a" : "#3B988C",
                  }}
                  label="Porter"
                  value="Porter"
                />
                <Tab
                  style={{
                    color: "white",
                    borderRadius: 5,
                    outline: "none",
                    color: tabName === "Radiology Request Receiver" ? "#12387a" : "#3B988C",
                  }}
                  label="Radiology Request Receiver"
                  value="Radiology Request Receiver"
                />
                <Tab
                  style={{
                    color: "white",
                    borderRadius: 5,
                    outline: "none",
                    color:
                      tabName === "Clinical Pharmacist" ? "#12387a" : "#3B988C",
                  }}
                  label="Clinical Pharmacist"
                  value="Clinical Pharmacist"
                />
                <Tab
                  style={{
                    color: "white",
                    borderRadius: 5,
                    outline: "none",
                    color:
                      tabName === "Registration Officer"
                        ? "#12387a"
                        : "#3B988C",
                  }}
                  label="Registration Officer"
                  value="Registration Officer"
                />
                <Tab
                  style={{
                    color: "white",
                    borderRadius: 5,
                    outline: "none",
                    color: tabName === "Paramedics" ? "#12387a" : "#3B988C",
                  }}
                  label="Paramedics"
                  value="Paramedics"
                />
                <Tab
                  style={{
                    color: "white",
                    borderRadius: 5,
                    outline: "none",
                    color: tabName === "Customer Care" ? "#12387a" : "#3B988C",
                  }}
                  label="Customer Care"
                  value="Customer Care"
                />
                <Tab
                  style={{
                    color: "white",
                    borderRadius: 5,
                    outline: "none",
                    color: tabName === "House Keeping" ? "#12387a" : "#3B988C",
                  }}
                  label="House Keeping"
                  value="House Keeping"
                />
                <Tab
                  style={{
                    color: "white",
                    borderRadius: 5,
                    outline: "none",
                    color: tabName === "Social Worker" ? "#12387a" : "#3B988C",
                  }}
                  label="Social Worker"
                  value="Social Worker"
                />
                <Tab
                  style={{
                    color: "white",
                    borderRadius: 5,
                    outline: "none",
                    color:
                      tabName === "Imaging Technician" ? "#12387a" : "#3B988C",
                  }}
                  label="Imaging Technician"
                  value="Imaging Technician"
                />
                <Tab
                  style={{
                    color: "white",
                    borderRadius: 5,
                    outline: "none",
                    color: tabName === "Lab Technician" ? "#12387a" : "#3B988C",
                  }}
                  label="Lab Technician"
                  value="Lab Technician"
                />
                <Tab
                  style={{
                    color: "white",
                    borderRadius: 5,
                    outline: "none",
                    color: tabName === "Cashier" ? "#12387a" : "#3B988C",
                  }}
                  label="Cashier"
                  value="Cashier"
                />
                <Tab
                  style={{
                    color: "white",
                    borderRadius: 5,
                    outline: "none",
                    color:
                      tabName === "Insurance Claims Manager"
                        ? "#12387a"
                        : "#3B988C",
                  }}
                  label="Insurance Claims Manager"
                  value="Insurance Claims Manager"
                />

                {/* <Tab
                  style={{
                    color: "white",
                    borderRadius: 5,
                    outline: "none",
                    color: tabName === "Code Blue" ? "#12387a" : "#3B988C",
                  }}
                  label="Code Blue"
                  value="Code Blue"
                /> */}
                <Tab
                  style={{
                    color: "white",
                    borderRadius: 5,
                    outline: "none",
                    color: tabName === "Psychiatrist" ? "#12387a" : "#3B988C",
                  }}
                  label="Psychiatrist"
                  value="Psychiatrist"
                />
                <Tab
                  style={{
                    color: "white",
                    borderRadius: 5,
                    outline: "none",
                    color: tabName === "Mental Care" ? "#12387a" : "#3B988C",
                  }}
                  label="Mental Care"
                  value="Mental Care"
                />
                <Tab
                  style={{
                    color: "white",
                    borderRadius: 5,
                    outline: "none",
                    color: tabName === "Advocate" ? "#12387a" : "#3B988C",
                  }}
                  label="Advocate"
                  value="Advocate"
                />
              </Tabs>
            </div>

            <div
              style={{
                flex: 4,
                display: "flex",
                flexDirection: "column",
              }}
            >
              {/* <div
                className={`${"container-fluid"} ${classes.root}`}
                style={{
                  marginTop: "15px",
                  paddingLeft: "10px",
                  paddingRight: "10px",
                }}
              >
                <div className={"row "}>
                  <div
                    className="col-md-10  col-8"
                    style={{ paddingTop: 5, paddingLeft: 3, paddingRight: 3 }}
                  >
                    <TextField
                      className="textInputStyle"
                      id="searchPatientQuery"
                      type="text"
                      variant="filled"
                      label={
                        matches
                          ? "Search Patient by Name/MRN/National ID/Mobile No"
                          : "Search Patient by Name/MRN "
                      }
                      name={"searchPatientQuery"}
                      value={searchPatientQuery}
                      onChange={handlePatientSearch}
                      InputLabelProps={{
                        classes: {
                          root: classes.label,
                          focused: classes.focusedLabel,
                          error: classes.erroredLabel,
                        },
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <AccountCircle />
                          </InputAdornment>
                        ),
                        className: classes.input,
                        classes: { input: classes.input },
                        disableUnderline: true,
                      }}
                    />
                  </div>

                  <div
                    className="col-md-1 col-sm-2 col-2"
                    style={{
                      ...styles.textFieldPadding,
                      paddingTop: 5,
                      paddingLeft: 3,
                      paddingRight: 3,
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "white",
                        borderRadius: 5,
                        height: 55,
                      }}
                    >
                      <img src={BarCode} style={{ width: 70, height: 60 }} />
                    </div>
                  </div>

                  <div
                    className="col-md-1 col-sm-1 col-2"
                    style={{
                      ...styles.textFieldPadding,
                      paddingTop: 5,
                      paddingLeft: 3,
                      paddingRight: 3,
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "white",
                        borderRadius: 5,
                        height: 55,
                      }}
                    >
                      <img
                        src={Fingerprint}
                        style={{ maxWidth: 43, height: 43 }}
                      />
                    </div>
                  </div>
                </div>
              </div> */}

              {generalArray && tabName === "Doctor" ? (
                <div className="container-fluid">
                  <div className="row">
                    <CustomTable
                      tableData={specialist ? filteredArray : generalArray}
                      tableDataKeys={
                        currentUser.staffType === "Sensei"
                          ? tableDataKeysForDoctorsShift
                          : tableDataKeysForDoctors
                      }
                      tableHeading={
                        currentUser.staffType === "Sensei"
                          ? tableHeadingForDoctorsShift
                          : tableHeadingForDoctors
                      }
                      action={
                        currentUser.staffType === "Sensei" ? action : actions
                      }
                      handleEdit={handleEdit}
                      handleView={handleView}
                      handleDisable={handleDisable}
                      borderBottomColor={"#60d69f"}
                      borderBottomWidth={20}
                      enableDisableRecord={enableDisableRecord}
                    />
                    <Notification msg={errorMsg} open={openNotification} />
                  </div>
                </div>
              ) : generalArray && tabName === "Nurses" ? (
                <div className="container-fluid">
                  <div className="row">
                    <CustomTable
                      tableData={specialist ? filteredArray : generalArray}
                      tableDataKeys={
                        currentUser.staffType === "Sensei"
                          ? tableDataKeysForNursesShift
                          : tableDataKeysForNurses
                      }
                      tableHeading={
                        currentUser.staffType === "Sensei"
                          ? tableHeadingForNursesShift
                          : tableHeadingForNurses
                      }
                      action={
                        currentUser.staffType === "Sensei" ? action : actions
                      }
                      handleEdit={handleEdit}
                      handleView={handleView}
                      handleDisable={handleDisable}
                      borderBottomColor={"#60d69f"}
                      borderBottomWidth={20}
                      enableDisableRecord={enableDisableRecord}
                    />

                    <Notification msg={errorMsg} open={openNotification} />
                  </div>
                </div>
              ) : generalArray && tabName === "Porter" ? (
                <div className="container-fluid">
                  <div className="row">
                    <CustomTable
                      tableData={specialist ? filteredArray : generalArray}
                      tableDataKeys={
                        currentUser.staffType === "Sensei"
                          ? tableDataKeysForPorterShift
                          : tableDataKeysForPorter
                      }
                      tableHeading={
                        currentUser.staffType === "Sensei"
                          ? tableHeadingForPorterShift
                          : tableHeadingForPorter
                      }
                      action={
                        currentUser.staffType === "Sensei" ? action : actions
                      }
                      handleEdit={handleEdit}
                      handleView={handleView}
                      handleDisable={handleDisable}
                      borderBottomColor={"#60d69f"}
                      borderBottomWidth={20}
                      enableDisableRecord={enableDisableRecord}
                    />

                    <Notification msg={errorMsg} open={openNotification} />
                  </div>
                </div>
              ) : generalArray ? (
                <div className="container-fluid">
                  <div className="row">
                    {loading ? (
                      <div style={{ margin: "auto", width: "0%", padding: 10 }}>
                        <Loader
                          type="TailSpin"
                          color="blue"
                          height={50}
                          width={50}
                        />
                      </div>
                    ) : (
                      <CustomTable
                        tableData={generalArray}
                        tableDataKeys={
                          currentUser.staffType === "Sensei"
                            ? tableDataKeysForShift
                            : tableDataKeys
                        }
                        tableHeading={
                          currentUser.staffType === "Sensei"
                            ? tableHeadingForShift
                            : tableHeading
                        }
                        action={
                          currentUser.staffType === "Sensei" ? action : actions
                        }
                        handleEdit={handleEdit}
                        handleView={handleView}
                        handleDisable={handleDisable}
                        borderBottomColor={"#60d69f"}
                        borderBottomWidth={20}
                        enableDisableRecord={enableDisableRecord}
                      />
                    )}

                    <Notification msg={errorMsg} open={openNotification} />
                  </div>
                </div>
              ) : (
                <div className="LoaderStyle">
                  <Loader type="TailSpin" color="red" height={50} width={50} />
                </div>
              )}

              <ConfirmationModal
                modalVisible={modalVisible}
                msg="Are you sure want to delete the record?"
                hideconfirmationModal={() => setModalVisible(false)}
                onConfirmDelete={() => deleteVendor()}
                setdeleteItem={() => setdeleteItem("")}
              />
              <div className="container-fluid">
                <div className="row" style={{ marginBottom: 20 }}>
                  <img
                    onClick={() => props.history.goBack()}
                    src={Back}
                    style={{
                      width: 45,
                      height: 35,
                      cursor: "pointer",
                    }}
                  />
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="LoaderStyle">
            <Loader type="TailSpin" color="red" height={50} width={50} />
          </div>
        )}

        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth={"lg"}
          fullWidth
        >
          <DialogContent>
            <div
              className={`${"container-fluid"} `}
              style={{
                backgroundColor: "white",
                padding: "20px 40px",
                marginTop: 20,
                borderRadius: 5,
              }}
            >
              <h4
                style={{ color: "grey", fontWeight: "bold", marginLeft: -14 }}
              >
                Working Shifts
              </h4>

              <>
                <div
                  className={`row ${classes.root}`}
                  style={{
                    marginTop: "15px",
                    marginRight: "-20px",
                    marginLeft: "-20px",
                  }}
                >
                  <div
                    className="col-md-4 col-sm-4"
                    style={{
                      ...styles.inputContainerForTextField,
                      ...styles.textFieldPadding,
                    }}
                  >
                    <TextField
                      required
                      select
                      label="Shift"
                      name={"shift"}
                      value={shift}
                      error={
                        (shift === "" || Array.isArray(shift)) && detailsForm
                      }
                      onChange={shiftHandler}
                      className="textInputStyle"
                      variant="filled"
                      InputProps={{
                        className: classes.input,
                        classes: { input: classes.input },
                      }}
                      inputProps={{ maxLength: 40 }}
                    >
                      {shiftList.map((array) => {
                        return (
                          <MenuItem key={array._id} value={array._id}>
                            {array.name}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                  </div>
                  <div
                    className="col-md-4 col-sm-4"
                    style={{
                      ...styles.inputContainerForTextField,
                      ...styles.textFieldPadding,
                    }}
                  >
                    <TextField
                      required
                      select
                      label="Select Chief Complaint Category"
                      name={"chiefComplaint"}
                      value={chiefComplaint}
                      error={
                        (chiefComplaint === "" ||
                          Array.isArray(chiefComplaint)) &&
                        detailsForm
                      }
                      onChange={changeHandler}
                      className="textInputStyle"
                      variant="filled"
                      InputProps={{
                        className: classes.input,
                        classes: { input: classes.input },
                      }}
                      inputProps={{ maxLength: 40 }}
                    >
                      {chiefComplaintList.map((array) => {
                        return (
                          <MenuItem key={array._id} value={array._id}>
                            {array.name}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                  </div>
                  <div
                    className="col-md-4 col-sm-4"
                    style={{
                      ...styles.inputContainerForTextField,
                      ...styles.textFieldPadding,
                    }}
                  >
                    <TextField
                      required
                      select
                      label="Production Area"
                      name={"productionAreaName"}
                      value={productionAreaName}
                      onChange={changeHandlerProductionArea}
                      error={
                        (productionAreaName === "" ||
                          Array.isArray(productionAreaName)) &&
                        detailsForm
                      }
                      className="textInputStyle"
                      variant="filled"
                      InputProps={{
                        className: classes.input,
                        classes: { input: classes.input },
                      }}
                      inputProps={{ maxLength: 40 }}
                    >
                      {productionAreaList.map((array) => {
                        return (
                          <MenuItem key={array._id} value={array._id}>
                            {array.paName}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                  </div>
                </div>

                <div
                  className={`row ${classes.root}`}
                  style={{
                    marginTop: "15px",
                    marginRight: "-20px",
                    marginLeft: "-20px",
                  }}
                >
                  <div
                    className="col-md-6 col-sm-6"
                    style={{
                      ...styles.inputContainerForTextField,
                      ...styles.textFieldPadding,
                    }}
                  >
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <TimePicker
                        // clearable
                        disabled
                        label="Start Time"
                        inputVariant="filled"
                        fullWidth={true}
                        ampm={false}
                        error={shiftStartTime === "" && detailsForm}
                        onChange={(val) =>
                          handleChangeDate(val, "shiftStartTime")
                        }
                        InputProps={{
                          className: classes.input,
                          classes: { input: classes.input },
                        }}
                        style={{ borderRadius: "10px" }}
                        value={shiftStartTime ? shiftStartTime : null}
                      />
                    </MuiPickersUtilsProvider>
                  </div>

                  <div
                    className="col-md-6 col-sm-6"
                    style={{
                      ...styles.inputContainerForTextField,
                      ...styles.textFieldPadding,
                    }}
                  >
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <TimePicker
                        // clearable
                        disabled
                        inputVariant="filled"
                        fullWidth={true}
                        label="End Time"
                        ampm={false}
                        error={shiftEndTime === "" && detailsForm}
                        onChange={(val) =>
                          handleChangeDate(val, "shiftEndTime")
                        }
                        InputProps={{
                          className: classes.input,
                          classes: { input: classes.input },
                        }}
                        style={{ borderRadius: "10px" }}
                        value={shiftEndTime ? shiftEndTime : null}
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                </div>
                <div
                  className={`row ${classes.root}`}
                  style={{
                    marginTop: "15px",
                    marginRight: "-20px",
                    marginLeft: "-20px",
                  }}
                >
                  <div
                    className="col-md-12 col-sm-12"
                    style={{
                      ...styles.inputContainerForTextField,
                      ...styles.textFieldPadding,
                    }}
                  >
                    <TextField
                      required
                      label="Reason"
                      name={"reason"}
                      value={reason}
                      error={reason === "" && detailsForm}
                      onChange={onChangeValue}
                      className="textInputStyle"
                      variant="filled"
                      InputProps={{
                        className: classes.input,
                        classes: { input: classes.input },
                      }}
                      inputProps={{ maxLength: 40 }}
                    />
                  </div>
                </div>
              </>

              <Notification msg={errorMsg} open={openNotification} />
            </div>
          </DialogContent>
          <DialogActions style={{ justifyContent: "center" }}>
            <Button
              onClick={handleClose}
              color="primary"
              style={styles.stylesForRemoveButton}
            >
              Cancel
            </Button>

            <Button
              onClick={updateStaffShift}
              color="primary"
              style={{
                ...styles.stylesSubmitButton,
                width: matches ? 200 : 100,
                heaight: matches ? 45 : 35,
                marginRight: matches ? " " : 40,
                fontSize: matches ? " " : 12,
              }}
            >
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </div>

      {openEnableDisabledModal && selectedRecord ? (
        <EnableDisableModal
          handleClose={setOpenEnableDisabledModal}
          handleConfirmDisable={handleConfirmDisable}
          disabled={selectedRecord.disabled}
        />
      ) : (
        undefined
      )}
    </div>
  );
}
