import React from "react";
import { Route, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";
import NotFound from "../components/NotFound/NotFound";
// Routes

import PrimaryRoles from "../views/Home/AdminSubMenus/PrimaryRoles/primaryRoles";
import EDDoctorSubMenu from "../views/Home/AdminSubMenus/PrimaryRoles/EDDoctorSubMenu";
import SenseiSubMenu from "../views/Home/AdminSubMenus/PrimaryRoles/senseiSubMenu";
import ObservationSubMenu from "../views/Home/AdminSubMenus/PrimaryRoles/emergencyObserUnitMenu";
import EOUPatients from "../views/EOUPatients/EOUPatients";
import EOUNotifications from "../views/EOUNotifications/EOUNotifications";
import EOUNurses from "../views/EOUNurses/EOUNurses";
import SenseiList from "../views/SenseiListing/senseiList";
import EDNursesList from "../views/EDNursesList/EDNursesList";

import AllEDDoctorPhysician from "../views/EDDoctorListing/allEDDoctorPhysician";
import EDDoctorPatients from "../views/EDDoctorPatients/EDDoctorPatients";
import EDDoctorRequests from "../views/Notifications/NotificationForPrimaryRoles";

import paramedicsRoutes from "./paramedicsRoutes";

class HomeScreenRoutes extends React.PureComponent {
  render() {
    console.log("rendered", this.props.match.url);
    return (
      <Switch>
        <Route
          exact
          path={`${this.props.match.url}`}
          component={PrimaryRoles}
        />

        <Route
          exact
          path={`${this.props.match.url}/eddoctor`}
          component={EDDoctorSubMenu}
        />

        <Route
          exact
          path={`${this.props.match.url}/observationunit`}
          component={ObservationSubMenu}
        />

        <Route
          exact
          path={`${this.props.match.url}/observationunit/notification`}
          component={EOUNotifications}
        />

        <Route
          exact
          path={`${this.props.match.url}/observationunit/eoupatients`}
          component={EOUPatients}
        />

        <Route
          exact
          path={`${this.props.match.url}/observationunit/eounurses`}
          component={EOUNurses}
        />

        <Route
          exact
          path={`${this.props.match.url}/sensei`}
          component={SenseiSubMenu}
        />

        <Route
          path={`${this.props.match.url}/paramedics`}
          component={paramedicsRoutes}
        />

        <Route
          exact
          path={`${this.props.match.url}/sensei/all`}
          component={SenseiList}
        />

        <Route
          exact
          path={`${this.props.match.url}/ednurses`}
          component={EDNursesList}
        />

        <Route
          exact
          path={`${this.props.match.url}/eddoctor/all`}
          component={AllEDDoctorPhysician}
        />

        <Route
          exact
          path={`${this.props.match.url}/eddoctor/eddoctorrequests`}
          component={EDDoctorRequests}
        />

        <Route
          exact
          path={`${this.props.match.url}/eddoctor/eddoctorpatients`}
          component={EDDoctorPatients}
        />

        <Route path="*" component={NotFound} />
      </Switch>
    );
  }
}
export default HomeScreenRoutes;
