import React from "react"
import CareStream from "../../../assets/img/carestream/CareStream.png"
import ContainerCS from "../../../components/CareStream/ContainerCS"
import { precautionsStyles } from "../styles"

export default function Precautions(props) {
  const classes = precautionsStyles()

  const getPrecautionsData = (data) => {
    props.getPrecaution(data)
  }

  return (
    <div className={classes.root}>
      <div className="subheader" style={{ marginLeft: "-10px" }}>
        <img src={CareStream} className={classes.image} />
        <div style={{ flex: 4, display: "flex", alignItems: "center" }}>
          <h4 style={{ color: "white", fontWeight: "700" }}>Precautions</h4>
        </div>
      </div>

      <ContainerCS
        heading="Precautions"
        buttonHeading="Precautions"
        getData={getPrecautionsData}
      />
    </div>
  )
}
