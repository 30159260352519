import React, { useEffect, useState } from 'react'
import cookie from "react-cookies";
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Notification from '../../../components/Snackbar/Notification.js'
import Flip from '../../../components/Dial/Flip'
import Loader from "react-loader-spinner";
import { useTheme } from '@material-ui/core/styles'
import OneValue from '../../../components/Dial/OneValue'
import SliderComponent from '../../../components/BedsAvailableSlider/bedsAvailableSlider'
import {
  getDashboardData,
  setDialerGraphCall,
  setValuesCardCalls,
} from './NetworkCalls'
import {
  socketUrl
} from '../../../public/endpoins'
import socketIOClient from 'socket.io-client'

export default function NurseDashboard() {
  const theme = useTheme()
  const matches = useMediaQuery('(min-width:600px)')
  const matchesForTablet = useMediaQuery(theme.breakpoints.up('md'))

  const [errorMsg, setErrorMsg] = useState('')
  const [openNotification, setOpenNotification] = useState(false)
  const [flip, setFlip] = useState(false)
  const [ipBeds, setIPBeds] = useState(0)
  const [edBeds, setEDBeds] = useState(0)
  const [responseData, setResponseData] = useState([])
  const [isLoading, setisLoading] = useState(true)
  // 1st Card (Patient Assessments/Triage Pending)
  const [triagePending, setTriagePending] = useState('')
  const [triagePendingColor, setTriagePendingColor] = useState('')
  const [triageTAT, setTriageTAT] = useState('')
  const [triageTATColor, setTriageTATColor] = useState('')
  const [triagePendingGraphData, setTriagePendingGraphData] = useState('')
  // 2nd Card (Patient Treatment/Medication Pending)
  const [treatmentPending, setTreatmentPending] = useState('')
  const [treatmentPendingColor, setTreatmentPendingColor] = useState('')
  const [treatmentTAT, setTreatmentTAT] = useState('')
  const [treatmentTATColor, setTreatmentTATColor] = useState('')
  const [treatmentPendingGraphData, setTreatmentPendingGraphData] = useState('')
  // 3rd Card (Tasks Pending)
  const [tasksPending, setTasksPending] = useState('')
  const [tasksPendingColor, setTasksPendingColor] = useState('')
  const [tasksTAT, setTasksTAT] = useState('')
  const [tasksTATColor, setTasksTATColor] = useState('')
  const [tasksPendingGraphData, setTasksPendingGraphData] = useState('')
  // 4th Card (Rad Pending)
  const [radPending, setRadPending] = useState('')
  const [radPendingColor, setRadPendingColor] = useState('')
  const [radTAT, setRadTAT] = useState('')
  const [radTATColor, setRadTATColor] = useState('')
  const [radPendingGraphData, setRadPendingGraphData] = useState('')
  // 5th Card (Order Pending)
  const [orderPending, setOrderPending] = useState('')
  const [orderPendingColor, setOrderPendingColor] = useState('')
  const [orderTAT, setOrderTAT] = useState('')
  const [orderTATColor, setOrderTATColor] = useState('')
  const [orderPendingGraphData, setOrderPendingGraphData] = useState('')
  // 6th Card (Lab Pending)
  const [labPending, setLabPending] = useState('')
  const [labPendingColor, setLabPendingColor] = useState('')
  const [labTAT, setLabTAT] = useState('')
  const [labTATColor, setLabTATColor] = useState('')
  const [labPendingGraphData, setLabPendingGraphData] = useState('')
  // 7th Values cards
  const [comulativeTotal, setComulativeTotal] = useState('')
  const [comulativeTotalColor, setComulativeTotalColor] = useState('')
  // 8th Values Card
  const [patientsPerHour, setPatientsPerHour] = useState('')
  const [patientsPerHourColor, setPatientsPerHourColor] = useState('')

  const [flag1Count, setFlag1Count] = useState(0)
  const [flag2Count, setFlag2Count] = useState(0)
  const [flag3Count, setFlag3Count] = useState(0)
  const [flag4Count, setFlag4Count] = useState(0)
  const [flag5Count, setFlag5Count] = useState(0)
  const [flag6Count, setFlag6Count] = useState(0)

  useEffect(() => {
    getDashboardData(
      cookie.load("current_user")._id,
      cookie.load("current_user").subType,
      setResponseData,
      setisLoading,
      setOpenNotification,
      setErrorMsg
    )

    const flagCount = [{value: '1st', setFlagCount: (value) => setFlag1Count(value)},
    {value: '2nd', setFlagCount: (value) => setFlag2Count(value)},
    {value: '3rd', setFlagCount: (value) => setFlag3Count(value)},
    {value: '4th', setFlagCount: (value) => setFlag4Count(value)},
    {value: '5th', setFlagCount: (value) => setFlag5Count(value)},
    {value: '6th', setFlagCount: (value) => setFlag6Count(value)}];

    const socket = socketIOClient(socketUrl)
    if(cookie.load("current_user").subType[0] === 'ED Nurse')
    {
      socket.emit('edNurse_flags')
      socket.on('edNursePending', (data) => {
        console.log('response coming through socket for ED Nurse Flags', data)
        flagCount.forEach(({value, setFlagCount}) => {
          const cardCount = data.filter(item => item.card === value).length;
          cardCount && setFlagCount(cardCount);
        })

        console.log('data', data)
        
      })
    }
      
  }, [])

  useEffect(() => {
    console.log("access response data :", responseData)

    if (responseData && !isLoading) {
      setEDBeds(responseData.availableEdBeds)
      setDialerGraphCall(
        3,
        6,
        responseData.firstCard,
        setTriagePending,
        setTriagePendingColor,
        3,
        6,
        setTriageTAT,
        setTriageTATColor,
        setTriagePendingGraphData
      )
      setDialerGraphCall(
        3,
        6,
        responseData.secondCard,
        setTreatmentPending,
        setTreatmentPendingColor,
        10,
        20,
        setTreatmentTAT,
        setTreatmentTATColor,
        setTreatmentPendingGraphData
      )
      setDialerGraphCall(
        2,
        5,
        responseData.thirdCard,
        setTasksPending,
        setTasksPendingColor,
        30,
        45,
        setTasksTAT,
        setTasksTATColor,
        setTasksPendingGraphData
      )
      setDialerGraphCall(
        3,
        6,
        responseData.fourthCard,
        setRadPending,
        setRadPendingColor,
        60,
        180,
        setRadTAT,
        setRadTATColor,
        setRadPendingGraphData
      )
      setDialerGraphCall(
        2,
        5,
        responseData.fifthCard,
        setOrderPending,
        setOrderPendingColor,
        5,
        10,
        setOrderTAT,
        setOrderTATColor,
        setOrderPendingGraphData
      )
      setDialerGraphCall(
        3,
        6,
        responseData.sixthCard,
        setLabPending,
        setLabPendingColor,
        30,
        45,
        setLabTAT,
        setLabTATColor,
        setLabPendingGraphData
      )
      setValuesCardCalls(
        40,
        60,
        responseData.cumulativePatients,
        setComulativeTotal,
        setComulativeTotalColor
      )
      setValuesCardCalls(
        3,
        6,
        responseData.PatientsPerHour,
        setPatientsPerHour,
        setPatientsPerHourColor
      )
    }
  }, [responseData, isLoading])

  const flipIt = () => {
    setFlip(true)
  }

  if (openNotification) {
    setTimeout(() => {
      setOpenNotification(false)
      setErrorMsg('')
    }, 2000)
  }

  return (
    <div className='container-fluid' style={{ marginBottom: 10 }}>
      <div className='row'>
        <div
          className='col-md-6 col-12'
          style={{ paddingLeft: 4, paddingRight: 4, marginTop: 10 }}
        >
          <div
            style={{
              width: '100%',
              backgroundColor: 'white',
              height: 180,
              borderRadius: 5,
              padding: 10,
            }}
          >
            <div>
              <span style={{ fontWeight: 'bold', fontSize: 18 }}>
                ED Beds Available
              </span>
            </div>
            <div>
              <span style={{ fontWeight: 'bold', fontSize: 45 }}>
                {isLoading ? <Loader type="TailSpin" color="green" height={35} width={35} /> : edBeds}
              </span>
            </div>
            <SliderComponent
              scale={14}
              top={120}
              bottom={0}
              left={
                matches && matchesForTablet
                  ? 140
                  : matches && !matchesForTablet
                    ? 45
                    : 25
              }
              right={0}
              onSlide={setEDBeds}
              readOnly={true}
              progressValue={edBeds}
            />
          </div>
        </div>

        <div
          className='col-md-6 col-12'
          style={{ paddingLeft: 4, paddingRight: 4, marginTop: 10 }}
        >
          <div
            style={{
              width: '100%',
              backgroundColor: 'white',
              height: 180,
              borderRadius: 5,
              padding: 10,
            }}
          >
            <div>
              <span style={{ fontWeight: 'bold', fontSize: 18 }}>
                IP Beds Available
              </span>
            </div>
            <div>
              <span style={{ fontWeight: 'bold', fontSize: 45 }}>{ipBeds}</span>
            </div>
            <SliderComponent
              top={120}
              bottom={0}
              left={
                matches && matchesForTablet
                  ? 140
                  : matches && !matchesForTablet
                    ? 45
                    : 25
              }
              right={0}
              onSlide={setIPBeds}
              readOnly={true}
              progressValue={ipBeds}
            />
          </div>
        </div>
      </div>
      <div className='row'>
        <div
          className='col-md-4 col-6'
          style={{ paddingLeft: 4, paddingRight: 4, marginTop: 10 }}
        >
          <div>
            <Flip
              flipped={!flip}
              flipHandler={flipIt}
              mainHeading={'Patient Assessments/Triage Pending'}
              value={isLoading ? <Loader type="TailSpin" color="green" height={35} width={35} /> : triagePending}
              mainHeadingForGraph='Patient Assessments/Triage Pending'
              color={triagePendingColor}
              subHeading={'TAT'}
              flagsCount={flag1Count}
              childHeading={'Registration to Assessment / Triage'}
              time={isLoading ? <Loader type="TailSpin" color="green" height={35} width={35} /> : triageTAT === null ? 0 : triageTAT}
              timecolor={triageTATColor}
              data={triagePendingGraphData}
              idForGraph={'container1'}
            />
          </div>
        </div>

        <div
          className='col-md-4 col-6'
          style={{ paddingLeft: 4, paddingRight: 4, marginTop: 10 }}
        >
          <div>
            <Flip
              flipped={!flip}
              flipHandler={flipIt}
              mainHeading={'Patient Treatments/Medications Pending'}
              value={isLoading ? <Loader type="TailSpin" color="green" height={35} width={35} /> : treatmentPending}
              mainHeadingForGraph='Patient Treatments/Medications Pending'
              color={treatmentPendingColor}
              subHeading={'TAT'}
              flagsCount={flag2Count}
              childHeading={'Diagnosis to Medication'}
              time={isLoading ? <Loader type="TailSpin" color="green" height={35} width={35} /> : treatmentTAT === null ? 0 : treatmentTAT}
              timecolor={treatmentTATColor}
              data={treatmentPendingGraphData}
              idForGraph={'container2'}
            />
          </div>
        </div>

        <div
          className='col-md-4 col-6'
          style={{ paddingLeft: 4, paddingRight: 4, marginTop: 10 }}
        >
          <div>
            <Flip
              flipped={!flip}
              flipHandler={flipIt}
              mainHeading={'Tasks Pending'}
              value={isLoading ? <Loader type="TailSpin" color="green" height={35} width={35} /> : tasksPending}
              mainHeadingForGraph='Tasks Pending'
              color={tasksPendingColor}
              subHeading={'TAT'}
              flagsCount={flag3Count}
              childHeading={'Task Assigned to Completion'}
              time={isLoading ? <Loader type="TailSpin" color="green" height={35} width={35} /> : tasksTAT === null ? 0 : tasksTAT}
              timecolor={tasksTATColor}
              data={tasksPendingGraphData}
              idForGraph={'container3'}
            />
          </div>
        </div>

        <div
          className='col-md-4 col-6'
          style={{ paddingLeft: 4, paddingRight: 4, marginTop: 10 }}
        >
          <div>
            <Flip
              flipped={!flip}
              flipHandler={flipIt}
              mainHeading={'Patient Rad Consultation Notes Pending'}
              value={isLoading ? <Loader type="TailSpin" color="green" height={35} width={35} /> : radPending}
              mainHeadingForGraph='Patient Rad Consultation Notes Pending'
              color={radPendingColor}
              subHeading={'TAT'}
              flagsCount={flag4Count}
              childHeading={'Order to Consultation Notes'}
              time={isLoading ? <Loader type="TailSpin" color="green" height={35} width={35} /> : radTAT === null ? 0 : radTAT}
              timecolor={radTATColor}
              data={radPendingGraphData}
              idForGraph={'container4'}
            />
          </div>
        </div>

        <div
          className='col-md-4 col-6'
          style={{ paddingLeft: 4, paddingRight: 4, marginTop: 10 }}
        >
          <div>
            <Flip
              flipped={!flip}
              flipHandler={flipIt}
              mainHeading={'Orders Pending'}
              value={isLoading ? <Loader type="TailSpin" color="green" height={35} width={35} /> : orderPending}
              mainHeadingForGraph='Orders Pending'
              color={orderPendingColor}
              subHeading={'TAT'}
              flagsCount={flag5Count}
              childHeading={'Order Request to Delivery'}
              time={isLoading ? <Loader type="TailSpin" color="green" height={35} width={35} /> : orderTAT === null ? 0 : orderTAT}
              timecolor={orderTATColor}
              idForGraph={'container5'}
              data={orderPendingGraphData}
            />
          </div>
        </div>

        <div
          className='col-md-4 col-6'
          style={{ paddingLeft: 4, paddingRight: 4, marginTop: 10 }}
        >
          <div>
            <Flip
              flipped={!flip}
              flipHandler={flipIt}
              mainHeading={'Patient Lab Results Pending'}
              value={isLoading ? <Loader type="TailSpin" color="green" height={35} width={35} /> : labPending}
              mainHeadingForGraph='Patient Lab Results Pending'
              color={labPendingColor}
              subHeading={'TAT'}
              flagsCount={flag6Count}
              childHeading={'Order to Result'}
              time={isLoading ? <Loader type="TailSpin" color="green" height={35} width={35} /> : labTAT === null ? 0 : labTAT}
              timecolor={labTATColor}
              data={labPendingGraphData}
              idForGraph={'container6'}
            />
          </div>
        </div>

        <div
          className='col-md-4 col-6'
          style={{ paddingLeft: 4, paddingRight: 4, marginTop: 10 }}
        >
          <div>
            <OneValue
              time={isLoading ? <Loader type="TailSpin" color="green" height={35} width={35} /> : comulativeTotal}
              colorTime={comulativeTotalColor}
              heading={'Cumulative total No. of Patients'}
            />
          </div>
        </div>

        <div
          className='col-md-4 col-6'
          style={{ paddingLeft: 4, paddingRight: 4, marginTop: 10 }}
        >
          <div>
            <OneValue
              time={isLoading ? <Loader type="TailSpin" color="green" height={35} width={35} /> : patientsPerHour}
              colorTime={patientsPerHourColor}
              heading={'No. of Patients/Hour'}
            />
          </div>
        </div>
      </div>
      <Notification msg={errorMsg} open={openNotification} />
    </div>
  )
}
