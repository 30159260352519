import { Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Notification from "../../components/Snackbar/Notification.js";
import Header from "../../components/Header/Header";
import MobileStepper from "@material-ui/core/MobileStepper";
import Button from "@material-ui/core/Button";
import Assign from "./Steps/Assign";
import Investigations from "./Steps/Investigations";
import Precautions from "./Steps/Precautions";
import TreatmentAndFluids from "./Steps/TreatmentAndFluids";
import Medication from "./Steps/Medication";
import MDNotifications from "./Steps/MDNotifications";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import ViewAssign from "./ViewSteps/ViewAssign";
import ViewInvestigations from "./ViewSteps/ViewInvestigations";
import ViewPrecautions from "./ViewSteps/ViewPrecautions";
import ViewTreatmentAndFluids from "./ViewSteps/ViewTreatmentAndFluids";
import ViewMedication from "./ViewSteps/ViewMedication";
import ViewMDNotifications from "./ViewSteps/ViewMDNotifications";
import { networkAddCareStream } from "./NetworkCallForCareStream";
import cookie from "react-cookies";
import { careStreamMainStyles } from "./styles";
import Back from "../../assets/img/Back_Arrow.png";
import "../../assets/jss/material-dashboard-react/components/TextInputStyle.css";

function CareStreamMain(props) {
  const classes = careStreamMainStyles();
  const [openNotification, setOpenNotification] = useState(false);
  const [currentUser, setCurrentUser] = useState(cookie.load("current_user"));
  const [comingFrom, setComingFrom] = useState("");
  const [open, setOpen] = React.useState(false);
  const [errorMsg, setErrorMsg] = useState(false);
  const [successMsg, setsuccessMsg] = useState(false);
  const [name, setName] = useState("");
  const [inclusionCriteria, setinclusionCriteria] = useState([]);
  const [exclusionCriteria, setexclusionCriteria] = useState([]);
  const [investigations, setInvestigations] = useState([]);
  const [precautions, setPrecautions] = useState([]);
  const [treatmentOrders, setTreatmentOrders] = useState([]);
  const [fluidsIV, setFluidsIV] = useState([]);
  const [medications, setMedications] = useState([]);
  const [mdNotification, setMdNotification] = useState([]);
  const [activeStep, setActiveStep] = React.useState(0);

  useEffect(() => {
    if (props.history.location.state.comingFrom === "add") {
      console.log("SELECTED RECORD", props.history.location.state.rec);
      setComingFrom(props.history.location.state.comingFrom);
      setinclusionCriteria(props.history.location.state.rec.inclusionCriteria);
      setexclusionCriteria(props.history.location.state.rec.exclusionCriteria);
      setInvestigations(props.history.location.state.rec.investigations);
      setPrecautions(props.history.location.state.rec.investigations);
      setTreatmentOrders(props.history.location.state.rec.investigations);
      setFluidsIV(props.history.location.state.rec.investigations);
      setMedications(props.history.location.state.rec.investigations);
      setMdNotification(props.history.location.state.rec.investigations);
    }
  }, []);

  if (openNotification) {
    setTimeout(() => {
      setOpenNotification(false);
      setErrorMsg("");
      setsuccessMsg("");
    }, 2000);
  }

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const wantToSubmit = () => {
    submitCareStream();
  };

  const dontWantToSubmit = () => {
    setOpen(false);
  };

  const submitting = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getName = (data) => {
    console.log("carestream name", data);
    setName(data);
  };
  const getAssignListForInclusion = (data) => {
    console.log("getAssignListForInclusion", data);
    setinclusionCriteria(data);
  };

  const getAssignListForExclusion = (data) => {
    console.log("getAssignListForExclusion", data);
    setexclusionCriteria(data);
  };
  const getInvestigation = (data) => {
    console.log("getInvestigation", data);
    setInvestigations(data);
  };

  const getPrecaution = (data) => {
    console.log("getPrecaution", data);
    setPrecautions(data);
  };

  const getFluids = (data) => {
    console.log("getFluids", data);
    setFluidsIV(data);
  };

  const getTandO = (data) => {
    console.log("getTandFList", data);
    setTreatmentOrders(data);
  };

  const getMedication = (data) => {
    console.log("getMedication", data);
    setMedications(data);
  };

  const getNotification = (data) => {
    console.log("getMDNotification", data);
    setMdNotification(data);
  };

  const validationsActive = () => {
    switch (activeStep) {
      case 0:
        return name.length > 0 &&
          inclusionCriteria.length > 0 &&
          exclusionCriteria.length > 0
          ? false
          : true;
      case 1:
        return investigations.length > 0 ? false : true;
      case 2:
        return precautions.length > 0 ? false : true;
      case 3:
        return treatmentOrders.length > 0 && fluidsIV.length ? false : true;
      case 4:
        return medications.length > 0 ? false : true;
      case 5:
        return mdNotification.length > 0 ? false : true;
      default:
        break;
    }
  };

  const submitCareStream = () => {
    var fluidsIVList = fluidsIV.map((fluidsIV) => fluidsIV.name);
    var medicationsList = medications.map((medication) => medication.name);
    var precautionsList = precautions.map((precaution) => precaution.name);
    var investigationsList = investigations.map(
      (investigation) => investigation.name
    );
    var inclusionCriteriaList = inclusionCriteria.map(
      (inclusion) => inclusion.name
    );
    var exclusionCriteriaList = exclusionCriteria.map(
      (exclusion) => exclusion.name
    );

    let object = {
      createdBy: currentUser._id,
      name,
      inclusionCriteria: inclusionCriteriaList,
      exclusionCriteria: exclusionCriteriaList,
      investigations: investigationsList,
      precautions: precautionsList,
      treatmentOrders,
      fluidsIV: fluidsIVList,
      medications: medicationsList,
      mdNotification,
    };

    console.log("OBJECT", object);
    networkAddCareStream(props, object);
  };

  return (
    <div
      style={{
        backgroundColor: "rgb(19 213 159)",
        position: "fixed",
        display: "flex",
        width: "100%",
        height: "100%",
        flexDirection: "column",
        flex: 1,
        overflowY: "scroll",
      }}
    >
      <Header history={props.history} />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Confirmation before adding
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Make sure you have enteted a valid CareStream, Once you add it, will
            unable to edit. Thanks.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={dontWantToSubmit} color="primary">
            No
          </Button>
          <Button onClick={wantToSubmit} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <div className="cPadding">
        {activeStep === 0 ? (
          <div>
            {props.history.location.state.rec === undefined ? (
              <Assign
                getInclusion={getAssignListForInclusion}
                sendName={getName}
                getExclusion={getAssignListForExclusion}
              />
            ) : (
              <ViewAssign
                comingFrom={props.history.location.state.comingFrom}
                name={props.history.location.state.rec.name}
                inclusionCriteria={
                  props.history.location.state.rec.inclusionCriteria
                }
                exclusionCriteria={
                  props.history.location.state.rec.exclusionCriteria
                }
              />
            )}
          </div>
        ) : activeStep === 1 ? (
          <div>
            {props.history.location.state.rec === undefined ? (
              <Investigations
                getInvestigation={getInvestigation}
                investigations={investigations}
              />
            ) : (
              <ViewInvestigations
                investigationsArray={
                  props.history.location.state.rec.investigations
                }
              />
            )}
          </div>
        ) : activeStep === 2 ? (
          <div>
            {props.history.location.state.rec === undefined ? (
              <Precautions
                precautions={precautions}
                getPrecaution={getPrecaution}
              />
            ) : (
              <ViewPrecautions
                precautionsArray={props.history.location.state.rec.precautions}
              />
            )}
          </div>
        ) : activeStep === 3 ? (
          <div>
            {props.history.location.state.rec === undefined ? (
              <TreatmentAndFluids
                subTypeOfArray={true}
                getFluids={getFluids}
                getTandO={getTandO}
              />
            ) : (
              <ViewTreatmentAndFluids
                treatmentOrdersArray={
                  props.history.location.state.rec.treatmentOrders
                }
                fluidsIVArray={props.history.location.state.rec.fluidsIV}
              />
            )}
          </div>
        ) : activeStep === 4 ? (
          <div>
            {props.history.location.state.rec === undefined ? (
              <Medication getMedication={getMedication} />
            ) : (
              <ViewMedication
                medicationsArray={props.history.location.state.rec.medications}
              />
            )}
          </div>
        ) : activeStep === 5 ? (
          <div>
            {props.history.location.state.rec === undefined ? (
              <MDNotifications
                subTypeOfArray={true}
                getNotification={getNotification}
              />
            ) : (
              <ViewMDNotifications
                mDNotificationsArray={
                  props.history.location.state.rec.mdNotification
                }
              />
            )}
          </div>
        ) : (
          undefined
        )}
        <MobileStepper
          className={classes.mobileStepper}
          steps={6}
          position="static"
          variant="dots"
          activeStep={activeStep}
          nextButton={
            <Grid>
              {props.history.location.state.comingFrom === "add" ? (
                <Grid>
                  {activeStep === 5 ? (
                    <Button
                      className={classes.viewButtonLabel}
                      variant="contained"
                      size="small"
                      style={{ backgroundColor: "#2962CC" }}
                      onClick={() => props.history.goBack()}
                    >
                      Back
                    </Button>
                  ) : (
                    <Button
                      className={classes.viewButtonLabel}
                      variant="contained"
                      size="small"
                      style={{ backgroundColor: "#2962CC" }}
                      onClick={handleNext}
                    >
                      Next
                    </Button>
                  )}
                </Grid>
              ) : (
                <Grid>
                  <Button
                    className={classes.viewButtonLabel}
                    variant="contained"
                    size="small"
                    disabled={validationsActive()}
                    style={{ backgroundColor: "#2962CC" }}
                    onClick={activeStep === 5 ? submitting : handleNext}
                  >
                    {activeStep === 5 ? "Submit" : "Next"}
                  </Button>
                </Grid>
              )}
            </Grid>
          }
          backButton={
            <div className="row" style={{ marginBottom: 20, marginLeft: 5 }}>
              {activeStep === 0 ? (
                <img
                  onClick={() => props.history.goBack()}
                  src={Back}
                  style={{
                    width: 45,
                    height: 35,
                    cursor: "pointer",
                  }}
                />
              ) : (
                <img
                  onClick={handleBack}
                  src={Back}
                  style={{
                    width: 45,
                    height: 35,
                    cursor: "pointer",
                  }}
                />
              )}
            </div>
          }
        />
        <Notification
          msg={errorMsg}
          open={openNotification}
          success={successMsg}
        />
      </div>
    </div>
  );
}
export default CareStreamMain;
