import React, { useEffect, useState } from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Flip from "../../../components/Dial/Flip";
import TwoValue from "../../../components/Dial/TwoValue";
import OneValue from "../../../components/Dial/OneValue";
import IPBeds from "../../../assets/img/ipbeds.png";
import EDBeds from "../../../assets/img/edbeds.png";
import Axios from "axios";
import { reportsHKDashboardURL } from "../../../public/endpoins";
import {
  getHKReports,
  getGraphsHousekeeping,
} from "./NetworkCallsForHouseKeeping";
import {
  socketUrl
} from '../../../public/endpoins'
import socketIOClient from 'socket.io-client'

export default function Housekeeping() {
  const matches = useMediaQuery("(min-width:600px)");

  //HK Reports
  const [pendingCellsCleaning, setPendingCellsCleaning] = useState("");
  const [timePendingCellsCleaning, setTimePendingCellsCleaning] = useState("");
  const [
    timePendingCellsCleaningColor,
    setTimePendingCellsCleaningColor,
  ] = useState("");
  const [pendingCellsCleaningColor, setPendingCellsCleaningColor] = useState(
    ""
  );
  const [averageNoofBedsColor, setAverageNoofBedsColor] = useState("");
  const [averageNoofBeds, setAverageNoofBeds] = useState("");
  const [timeAverageNoofBeds, setTimeAverageNoofBeds] = useState("");

  const [graphDataHouseKeeping, setGraphDataHouseKeeping] = useState([]);
  const [openNotification, setOpenNotification] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [flag1Count, setFlag1Count] = useState(0)

  const [cumulativeTotalBedsCleaned, setCumulativeTotalBedsCleaned] = useState(
    ""
  );
  const [
    cumulativeTotalBedsCleanedColor,
    setCumulativeTotalBedsCleanedColor,
  ] = useState("");

  const [flip, setFlip] = useState(false);

  useEffect(() => {
    getHKReports(
      setPendingCellsCleaning,
      setTimePendingCellsCleaning,
      setTimePendingCellsCleaningColor,
      setAverageNoofBeds,
      setTimeAverageNoofBeds,
      setCumulativeTotalBedsCleaned,
      setPendingCellsCleaningColor,
      setAverageNoofBedsColor,
      setCumulativeTotalBedsCleanedColor,
      setOpenNotification,
      setErrorMsg
    );

    const flagCount = [{value: '1st', setFlagCount: (value) => setFlag1Count(value)}];

    const socket = socketIOClient(socketUrl)
      socket.emit('hk_flags')
      socket.on('hkPending', (data) => {
        console.log('response coming through socket for ED doctor Flags', data)
        flagCount.forEach(({value, setFlagCount}) => {
          const cardCount = data.filter(item => item.card === value).length;
          cardCount && setFlagCount(cardCount);
        })

        console.log('data', data)
        
      })
  }, []);

  useEffect(() => {
    getGraphsHousekeeping(
      setGraphDataHouseKeeping,
      setErrorMsg,
      setOpenNotification
    );

    //setData(temp);
    // setData(graphDataHouseKeeping);
  }, []);

  if (openNotification) {
    setTimeout(() => {
      setOpenNotification(false);
      setErrorMsg("");
    }, 2000);
  }

  const flipIt = () => {
    setFlip(true);
  };
  return (
    <div className="container-fluid" style={{ marginBottom: 10 }}>
      <div className="row">
        <div
          className="col-md-4 col-6"
          style={{ paddingLeft: 4, paddingRight: 4, marginTop: 10 }}
        >
          <div>
            <Flip
              flipped={!flip}
              flipHandler={flipIt}
              mainHeading={"Cells/Beds Cleaning Pending"}
              value={pendingCellsCleaning}
              // value={
              //   PendingCellsCleaning === 0
              //     ? PendingCellsCleaning + 1
              //     : PendingCellsCleaning
              // }
              flagsCount={flag1Count}
              mainHeadingForGraph="Cells/Beds Cleaning Pending"
              color={pendingCellsCleaningColor}
              subHeading={"TAT"}
              childHeading={"Request to Completion"}
              time={
                timePendingCellsCleaning === null
                  ? "0"
                  : timePendingCellsCleaning
              }
              idForGraph={"container1"}
              data={graphDataHouseKeeping}
              timecolor={timePendingCellsCleaningColor}
            />
          </div>
        </div>

        <div
          className="col-md-4 col-6"
          style={{ paddingLeft: 4, paddingRight: 4, marginTop: 10 }}
        >
          <div>
            <Flip
              flipped={!flip}
              flipHandler={flipIt}
              mainHeading={"Cells/Beds Re-Stocking Pending"}
              value={40}
              mainHeadingForGraph="Patient Decision Pending"
              color="#13D59F"
              subHeading={"TAT"}
              childHeading={"Diagnosis to Decision"}
              time={"55"}
              idForGraph={"container2"}
              data={graphDataHouseKeeping}
            />
          </div>
        </div>

        <div
          className="col-md-4 col-6"
          style={{ paddingLeft: 4, paddingRight: 4, marginTop: 10 }}
        >
          <div>
            <Flip
              flipped={!flip}
              flipHandler={flipIt}
              mainHeading={"Average No. of Beds/Hour"}
              value={averageNoofBeds}
              mainHeadingForGraph="Patient Discharges/Dispositions Pending"
              color={averageNoofBedsColor}
              subHeading={"TAT"}
              childHeading={"Per Bed"}
              time={timeAverageNoofBeds ? timeAverageNoofBeds : "0"}
              idForGraph={"container3"}
              data={graphDataHouseKeeping}
            />
          </div>
        </div>
        {/* </div> */}

        {/* <div className='row'> */}
        <div
          className="col-md-4 col-6"
          style={{ paddingLeft: 4, paddingRight: 4, marginTop: 10 }}
        >
          <div>
            <Flip
              flipped={!flip}
              flipHandler={flipIt}
              mainHeading={"Average No. of Re-Stockings/Hour"}
              value={40}
              mainHeadingForGraph="Consultant/Specialist Notes Pending"
              color="#13D59F"
              subHeading={"TAT"}
              childHeading={"Consultation Order to Receipt of Notes"}
              time={"25"}
              idForGraph={"container4"}
              data={graphDataHouseKeeping}
            />
          </div>
        </div>

        {/* <div
          className="col-md-4 col-6"
          style={{ paddingLeft: 4, paddingRight: 4, marginTop: 10 }}
        >
          <div>
            <Flip
              flipped={!flip}
              flipHandler={flipIt}
              mainHeading={"Blood Test Results Pending"}
              value={60}
              mainHeadingForGraph="Patient Lab Results Pending"
              color="#FF0000"
              subHeading={"TAT"}
              childHeading={"Order to Result"}
              time={"90"}
            />
          </div>
        </div> */}

        {/* <div
          className="col-md-4 col-6"
          style={{ paddingLeft: 4, paddingRight: 4, marginTop: 10 }}
        >
          <div>
            <Flip
              flipped={!flip}
              flipHandler={flipIt}
              mainHeading={"Average No. of Blood Tests/Hour"}
              value={35}
              mainHeadingForGraph="Patient Rad Consultation Notes Pending"
              color="#FFBC28"
              subHeading={"TAT"}
              childHeading={"Order to Consultation Notes"}
              time={"70"}
            />
          </div>
        </div> */}
        {/* </div> */}

        {/* <div className='row'> */}
        {/* <div
          className="col-md-4 col-6"
          style={{ paddingLeft: 4, paddingRight: 4, marginTop: 10 }}
        >
          <div>
            <Flip
              flipped={!flip}
              flipHandler={flipIt}
              mainHeading={"Orders Pending"}
              value={80}
              mainHeadingForGraph="Orders Pending"
              color="#13D59F"
              subHeading={"TAT"}
              childHeading={"Order Request to Delivery"}
              time={"30"}
            />
          </div>
        </div> */}

        <div
          className="col-md-4 col-6"
          style={{ paddingLeft: 4, paddingRight: 4, marginTop: 10 }}
        >
          <div>
            <OneValue
              time={cumulativeTotalBedsCleaned}
              colorTime={cumulativeTotalBedsCleanedColor}
              heading={"Cumulative total Beds Cleaned"}
            />
          </div>
        </div>

        <div
          className="col-md-4 col-6"
          style={{ paddingLeft: 4, paddingRight: 4, marginTop: 10 }}
        >
          <div>
            <OneValue
              time={75}
              colorTime={"#13D59F"}
              heading={"Cumulative total Re-Stockings"}
            />
          </div>
        </div>
        {/* </div> */}

        {/* <div className='row'> */}
        {/* <div
          className="col-md-4 col-6"
          style={{ paddingLeft: 4, paddingRight: 4, marginTop: 10 }}
        >
          <div>
            <OneValue
              time={20}
              colorTime={"#13D59F"}
              heading={"Average No. of Patients Diagnosed Per Hour"}
            />
          </div>
        </div> */}
        {/* <div
          className="col-md-4 col-6"
          style={{ paddingLeft: 4, paddingRight: 4, marginTop: 10 }}
        >
          <div>
            <OneValue
              time={650}
              colorTime={"#13D59F"}
              heading={"Compliance/Variance Report"}
            />
          </div>
        </div> */}
      </div>
    </div>
  );
}
