/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable array-callback-return */
/* eslint-disable react/jsx-indent */
import React, { useEffect, useState, useReducer } from "react";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import Icon from '@material-ui/core/Icon';
import CloseIcon from '@material-ui/icons/Close';
import MicIcon from "@material-ui/icons/Mic";
import StopIcon from "@material-ui/icons/Stop";
import MicRecorder from "mic-recorder-to-mp3";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import axios from "axios";
import Notification from "../../components/Snackbar/Notification.js";
import AccountCircle from "@material-ui/icons/SearchOutlined";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import {
  updateIPR,
  notifyDischarge,
  updateEdr,
  audioURL,
  getSearchedPharmaceuticalItemsUrl,
  getSearchedItemsNonPharmaceuticalUrl,
} from "../../public/endpoins";
import InputLabelComponent from "../../components/InputLabel/inputLabel";
import ErrorMessage from "../../components/ErrorMessage/errorMessage";
import Paper from "@material-ui/core/Paper";
import cookie from "react-cookies";
import Dialog from "@material-ui/core/Dialog";
import Header from "../../components/Header/Header";
import plus_icon from "../../assets/img/Plus.png";
import purchase_request from "../../assets/img/Doctor - Discharge.png";
import Back from "../../assets/img/Back_Arrow.png";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import "../../assets/jss/material-dashboard-react/components/TextInputStyle.css";
import CustomTable from "../../components/Table/Table";
import Loader from "react-loader-spinner";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import stylesForPaper from "../../assets/jss/material-dashboard-react/components/paper.js";
import { displayInHumanDifference, replacePatientIdIfNull } from "../../utils/commonFunc";
import PatientClinicalInformation from "../../components/PatientDetails/PatientClinicalInformation";
import PatientDetailsQuick from "../../components/PatientDetails/PatientDetailsQuick";

const Mp3Recorder = new MicRecorder({ bitRate: 128 });

const scheduleArray = [
  { key: "Now", value: "Now/Immediate" },
  // { key: "Immediate", value: "Immediate" },
];
const priorityArray = [
  { key: "Emergency", value: "Emergency" },
  { key: "Regular", value: "Regular" },
  { key: "PRN", value: "PRN" },
];

const sizeArray = [
  { key: "Small", value: "Small" },
  { key: "Medium", value: "Medium" },
  { key: "Large", value: "Large" },
  { key: "Extra Large", value: "Extra Large" },
];

const modalArray = [
  { key: "Old", value: "Old" },
  { key: "New", value: "New" },
];

const dosageArray = [
  { key: "tablet", value: "tablet" },
  { key: "capsule", value: "capsule" },
  { key: "drop", value: "drop" },
  { key: "ml", value: "ml" },
  { key: "supp", value: "supp" },
  { key: "unit dose vial", value: "unit dose vial" },
  { key: "stroke", value: "stroke" },
  { key: "amp", value: "amp" },
  { key: "patch", value: "patch" },
  { key: "spray", value: "spray" },
  { key: "inhalation", value: "inhalation" },
  { key: "actuation", value: "actuation" },
  { key: "ovule", value: "ovule" },
  { key: "pump", value: "pump" },
  { key: "caplet", value: "caplet" },
  { key: "gm", value: "gm" },
  { key: "vial", value: "vial" },
  { key: "dose", value: "dose" },
  { key: "puff", value: "puff" },
  { key: "application", value: "application" },
  { key: "sachet", value: "sachet" },
  { key: "film", value: "film" },
  { key: "ring", value: "ring" },
  { key: "pre-filled syring", value: "pre-filled syring" },
]

const frequencyArray = [

  { key: 1,  value: "Q.H - Each Hour" },
  { key: 11, value: "Q.D - Every day" },
  { key: 2,  value: "B.I.D - Two times each day" },
  { key: 3,  value: "T.I.D - Three times each day" },
  { key: 4,  value: "Q.I.D - Four times each day" },

  { key: 20,  value: "Q.S - As much as su" },
  { key: 21,  value: "Q.A.C - Before every meal" },
  { key: 22,  value: "Q.O.D - Every other day" },
  { key: 23,  value: "Q.I - As much as you like" },
  { key: 24,  value: "Q.H.S - Every bedtime" },
  { key: 0,  value: "PRN When needed" },
  { key: 25,  value: "Other" },
];

const routeArray = [
  { key: "ROA000", value: "N/A" },
  { key: "ROA001", value: "DENTAL" },
  { key: "ROA002", value: "DENTAL INFILTRATION INJ. DENTAL BLOCK" },
  { key: "ROA003", value: "DENTAL INJECTION" },
  { key: "ROA007", value: "EPIDURAL" },
  { key: "ROA009", value: "CAUDAL" },
  { key: "ROA010", value: "EXTERNAL" },
  { key: "ROA011", value: "GENITAL" },
  { key: "ROA012", value: "IM" },
  { key: "ROA032", value: "IMPLANT" },
  { key: "ROA033", value: "INFILTRATION DENTAL BLOCK" },
  { key: "ROA034", value: "INHALATION" },
  { key: "ROA036", value: "INTRA-AMNIOTIC" },
  { key: "ROA037", value: "INTRA-ARTICULAR" },
  { key: "ROA039", value: "INTRACAVERNOSAL" },
  { key: "ROA040", value: "INTRACERVICAL" },
  { key: "ROA041", value: "INTRAGLUTEALLY" },
  { key: "ROA042", value: "INTRA-MASTITIS" },
  { key: "ROA043", value: "INTRAMMAMARY" },
  { key: "ROA045", value: "INTRA-PERIODONTAL POCKET" },
  { key: "ROA046", value: "INTRA-PERITONEAL" },
  { key: "ROA047", value: "INTRATRACHEAL" },
  { key: "ROA048", value: "INTRAUTERIN" },
  { key: "ROA049", value: "INTRAVAGINAL" },
  { key: "ROA050", value: "INTRA-VESICALLY" },
  { key: "ROA051", value: "IV" },
  { key: "ROA052", value: "IV CATHETER" },
  { key: "ROA053", value: "IV INFUSION" },
  { key: "ROA064", value: "LOCAL ORAL" },
  { key: "ROA065", value: "LOCAL ORAL DENTAL" },
  { key: "ROA066", value: "ORAL RINSE" },
  { key: "ROA067", value: "NASAL" },
  { key: "ROA071", value: "NEBULISATION" },
  { key: "ROA072", value: "OCULAR" },
  { key: "ROA074", value: "ORAL" },
  { key: "ROA075", value: "ORAL INHALATION" },
  { key: "ROA083", value: "ORALLY TUBE FEEDING" },
  { key: "ROA084", value: "OTIC" },
  { key: "ROA085", value: "PARENTERAL" },
  { key: "ROA087", value: "PERITONEAL DIALYSIS" },
  { key: "ROA088", value: "RECTAL" },
  { key: "ROA089", value: "SC" },
  { key: "ROA090", value: "SPINAL" },
  { key: "ROA091", value: "SUBLINGUAL" },
  { key: "ROA092", value: "TOPICAL" },
  { key: "ROA093", value: "TOPICAL ANAL" },
  { key: "ROA094", value: "TOPICAL- SCALP" },
  { key: "ROA095", value: "TRANSDERMAL" },
  { key: "ROA096", value: "TRANSMUCOSAL" },
  { key: "ROA097", value: "VAGINAL" },
  { key: "ROA100", value: "URETHRAL" },
  { key: "ROA101", value: "MISCELLANEOUS" },
  { key: "ROA103", value: "SUBARACHNOID" },
  { key: "ROA107", value: "INTRATHECAL" },
  { key: "ROA108", value: "INTRAOCULAR" },
  { key: "ROA109", value: "INTRACARDIAC" },
  { key: "ROA112", value: "INTRADERMAL" },
  { key: "ROA114", value: "INTRAVENTRICULAR" },
  { key: "ROA115", value: "SUBMUCOSAL" },
  { key: "ROA123", value: "INTRAPLEURAL" },
  { key: "ROA133", value: "INFILTRATION" },
  { key: "ROA141", value: "IRRIGATION" },
  { key: "ROA147", value: "SUBDERMAL" },
  { key: "ROA148", value: "BUCCAL" },
  { key: "ROA159", value: "ARTERIOVENOUS" },
  { key: "ROA160", value: "CENTRAL NERVE BLOCK" },
  { key: "ROA161", value: "DEEP SC" },
  { key: "ROA162", value: "DERMAL" },
  { key: "ROA163", value: "ENDOTRACHEAL" },
  { key: "ROA164", value: "ILIOHYPOGASTRIC (NERVE BLOCK)" },
  { key: "ROA165", value: "ILIONGUINAL" },
  { key: "ROA166", value: "INTRA-ARTERIAL" },
  { key: "ROA167", value: "INTRABURSAL" },
  { key: "ROA168", value: "INTRACAVITARY" },
  { key: "ROA169", value: "INTRALESIONAL" },
  { key: "ROA170", value: "INTRAMEDULLARILY" },
  { key: "ROA171", value: "INTRAOSSEOUS" },
  { key: "ROA172", value: "INTRA-PULMONARY" },
  { key: "ROA173", value: "INTRASPINAL" },
  { key: "ROA174", value: "INTRASUBLESIONAL" },
  { key: "ROA175", value: "INTRASYNOVIAL" },
  { key: "ROA176", value: "IV BOLUS" },
  { key: "ROA177", value: "OROFACIAL" },
  { key: "ROA178", value: "OROPHARYNGEAL" },
  { key: "ROA179", value: "PERIANAL" },
  { key: "ROA180", value: "PERIARTICULAR" },
  { key: "ROA181", value: "PERIBULBAR" },
  { key: "ROA182", value: "PERIPHERAL NERVE BLOCK" },
  { key: "ROA183", value: "SUBCONJUNCTIVAL" },
  { key: "ROA184", value: "SYMPATHETIC NERVE BLOCK" },
  { key: "ROA185", value: "TRANSCERVICAL" },
  { key: "ROA187", value: "SC INFUSION" },
  { key: "ROA191", value: "INTRAVITREAL" },
  { key: "ROA192", value: "CARDIAC PERFUSION" },
  { key: "ROA202", value: "INTRACAMERAL" },
  { key: "ROA206", value: "INTESTINAL INFUSION" },
  { key: "ROA207", value: "EPILESIONAL" },
  { key: "ROA209", value: "INTRAURETERIC" },
  { key: "ROA210", value: "INTRA-LYMPHATIC" },
  { key: "ROA222", value: "PERINEURAL" },
];

const tableHeadingForPharmacyReq = [
  "Medicine Name",
  "Quantity",
  "Unit Price ( JD)",
  "Total Price ( JD)",
  "Action",
];
const tableDataKeysForPharmacyReq = [
  "medicineName",
  "requestedQty",
  "unitPrice",
  "totalPrice",
];
const actions = { edit: true };
const styles = {
  inputContainer: {
    marginTop: 10,
    backgroundColor: "white",
    borderRadius: 5,
    paddingTop: 5,
    paddingBottom: 5,
    marginLeft: 5,
    marginRight: 5,
  },
  stylesForButton: {
    color: "white",
    cursor: "pointer",
    borderRadius: 5,
    backgroundColor: "#2c6ddd",
    width: "140px",
    height: "50px",
    outline: "none",
  },
  stylesForPurchaseButton: {
    color: "white",
    cursor: "pointer",
    borderRadius: 5,
    backgroundColor: "#2c6ddd",
    width: "140px",
    height: "50px",
    outline: "none",
  },
  inputField: {
    outline: "none",
  },
  inputContainerForTextField: {
    marginTop: 10,
  },
  textFieldPadding: {
    paddingLeft: 5,
    paddingRight: 5,
  },
  inputContainerForDropDown: {
    marginTop: 25,
  },
  buttonContainer: {
    marginTop: 25,
  },
};
// const useStyles = makeStyles(tableStyles)

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(0),
  },
  input: {
    backgroundColor: "white",
    boxShadow: "none",
    borderRadius: 5,
    "&:after": {
      borderBottomColor: "black",
      boxShadow: "none",
    },
    "&:hover": {
      backgroundColor: "white",
      boxShadow: "none",
    },
    "&:focus": {
      backgroundColor: "white",
      boxShadow: "none",
    },
  },
  multilineColor: {
    boxShadow: "none",
    backgroundColor: "white",
    borderRadius: 5,
    "&:hover": {
      backgroundColor: "white",
      boxShadow: "none",
    },
    "&:after": {
      borderBottomColor: "black",
      boxShadow: "none",
    },
    "&:focus": {
      boxShadow: "none",
    },
  },
  root: {
    "& .MuiTextField-root": {
      backgroundColor: "white",
    },
    "& .Mui-focused": {
      backgroundColor: "white",
      color: "black",
      boxShadow: "none",
    },
    "& .Mui-disabled": {
      backgroundColor: "white",
      color: "gray",
    },
    "&:focus": {
      backgroundColor: "white",
      boxShadow: "none",
    },
  },
}));

const dosageUnitArray = [
  {
    value: "milligrams",
    label: "milligrams (mg)",
  },
  {
    value: "grams",
    label: "grams (g)",
  },
  {
    value: "ounces",
    label: "ounces (oz)",
  },
];

const frequencyUnitArray = [
  {
    value: "Once per day",
    label: "Once per day",
  },
  {
    value: "Twice per day",
    label: "Twice per day",
  },
  {
    value: "Three times per day",
    label: "Three times/day",
  },
];

const durationArray = [
  {
    value: "Days",
    label: "Day/Days",
  },
  {
    value: "Weeks",
    label: "Week/Weeks",
  },
  {
    value: "Month",
    label: "Month/Months",
  },
];

function AddEditEDR(props) {
  const matches = useMediaQuery("(min-width:600px)");
  const classes = useStyles();
  const initialState = {
    date: new Date(),
    status: "pending",
    requester: "",
    itemId: "",
    medicineName: "",
    priority: "",
    schedule: "",
    dosage: "",
    dosageType: "",
    dosageUnit: "",
    issueUnit: "",
    frequency: "",
    frequencyUnit: "",
    duration: "",
    durationUnit: "",
    requestedQty: "",
    unitPrice: "",
    totalPrice: "",
    dischargeMedicines: "",
    dischargeRequest: "",
    requestType: "",

    make_model: "",
    size: "",



    seletedMedItems: [],
    isBlocked: false,
    isRecording: false,
    recordingField: '',
    blobURL: [],

    selectedItemToSearch: "pharmaceutical",
  };

  function reducer(state, { field, value }) {
    return {
      ...state,
      [field]: value,
    };
  }

  const [state, dispatch] = useReducer(reducer, initialState);

  const {
    date = new Date(),
    status = "pending",
    itemId,
    medicineName,
    priority,
    schedule,
    dosage,
    dosageUnit,
    frequency,
    frequencyUnit,
    duration,
    durationUnit,
    issueUnit,
    requestedQty,
    unitPrice,
    totalPrice,
    dischargeMedicines,
    dischargeRequest,
    requestType,

    selectedItemToSearch,

    make_model,
    size,

    seletedMedItems,
    isBlocked,
    isRecording,
    recordingField,
    blobURL,
  } = state;

  

  const onChangeValue = (e, xIndex, item) => {
    console.log("PharmaReq :: onChangePriorityValue", e, xIndex, item);

    let newSelectedLabItems = seletedMedItems;
    newSelectedLabItems.map((d, index) => {
      if (d.itemId === item){
        console.log("PharmaReq :: onChangePriorityValue :: d", d);
        let xPriority     = d['priority'];
        let xDosage       = d['dosage'];
        let xDuration     = d['duration'];
        let xFrequency    = d['frequency'];
        let xReqQty       = d['requestedQty'];

        if (xIndex === "dosage"){
          xDosage = e;
        }
        if (xIndex === "duration"){
          xDuration = e;
        }
        if (xIndex === "frequency"){
          xFrequency = e;
        }
        if (xIndex === "requestedQty"){
          xReqQty = e;
        }

        let finalReqQty = 0;
        if ( xFrequency === 0 || xFrequency>=11 ){
          finalReqQty = xReqQty;
        }else{
          finalReqQty = xDosage * xDuration * xFrequency;
        }

        // requestedQty: 1,


        d[`${xIndex}`] = e;
        d['requestedQty'] = finalReqQty;
        d['totalPrice'] = d['unitPrice']*finalReqQty;
      }
    });
    console.log("PharmaReq :: onChangePriorityValue :: nv", newSelectedLabItems);
    dispatch({
      field: 'seletedMedItems',
      value: newSelectedLabItems
    });
  };

  const onChangePriorityValue = (e, index, item) => {
    console.log("PharmaReq :: onChangePriorityValue", e, index, item);

    let newSelectedLabItems = seletedMedItems;
    newSelectedLabItems.map((d, index) => {
      if (d.itemId === item){
        d.priority = e;
      }
    });
    console.log("PharmaReq :: onChangePriorityValue :: nv", newSelectedLabItems);
    dispatch({
      field: 'seletedMedItems',
      value: newSelectedLabItems
    });
  };

  const [comingFor, setcomingFor] = useState("");
  const [currentUser, setCurrentUser] = useState("");
  const [isFormSubmitted] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setsuccessMsg] = useState("");
  const [openNotification, setOpenNotification] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [, setSelectedItem] = useState("");
  const [selectItemToEditId, setSelectItemToEditId] = useState("");
  const [id, setId] = useState("");
  const [, setrequestNo] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [itemFound, setItemFound] = useState("");
  const [itemFoundSuccessfull, setItemFoundSuccessfully] = useState(false);
  const [patientId, setpatientId] = useState("");
  const [enableSave, setEnableSave] = useState(false);
  const [dischargeNotes, setdischargeNotes] = useState("");
  const [followUpInstruction, setFollowUpInstructions] = useState("");

  const [edrCompletionReason, setEdrCompletionReason] = useState("");
  const [edrCompletionRequirement, setEdrCompletionRequirement] = useState("");
  const [edrAdmissionType   , setEdrAdmissionType] = useState("discharged");

  const [timer, setTimer] = useState(null);
  const [loadSearchedData, setLoadSearchedData] = useState(false);

  useEffect(() => {
    // const soc = socketIOClient(socketUrl);
    // setSocket(soc);
    // soc.emit("connection");
    setCurrentUser(cookie.load("current_user"));

    setcomingFor(props.history.location.state.comingFor);

    const selectedRec = props.history.location.state.selectedItem;
    console.log("Other... ", props.history.location.state.followUpInstruction);
    console.log("Discharge... ", props.history.location.state.dischargeNotes);

    setpatientId(selectedRec.patientId._id);

    setFollowUpInstructions(props.history.location.state.followUpInstruction);
    setdischargeNotes(props.history.location.state.dischargeNotes);

    setEdrCompletionReason(props.history.location.state.edrCompletionReason);
    setEdrCompletionRequirement(props.history.location.state.edrCompletionRequirement);
    setEdrAdmissionType(props.history.location.state.edrAdmissionType)

    setId(selectedRec._id);
    setrequestNo(selectedRec.requestNo);

    if (selectedRec) {
      Object.entries(selectedRec).map(([key, val]) => {
        if (val && typeof val === "object") {
          if (key === "dischargeRequest") {
            Object.entries(val).map(([key1, val1]) => {
              if (key1 === "dischargeMedication") {
                Object.entries(val1).map(([key2, val2]) => {
                  if (key2 === "medicine") {
                    // val2.map(
                    //   (d) => (
                    //     (d.unitPrice = d.unitPrice.toFixed(4)),
                    //     (d.totalPrice = d.totalPrice.toFixed(4))
                    //   )
                    // );
                    let medAudioData = [];
                    let nBlogURLs = [];

                    Object.entries(val2).map(([ixx, ivv]) => {
                      if (ivv.audio.includes('uploads/')){
                        ivv.audio = `${audioURL}/${ivv.audio}`;
                        medAudioData.push(ivv);
                      }
                    })
                    // console.log(`KKKK:`, medAudioData);

                    // dispatch({ field: "blobURL", value: medAudioData });
                    dispatch({ field: "dischargeMedicines", value: medAudioData });
                    dispatch({ field: "seletedMedItems", value: val2 });
                  }
                });
              }
            });
            dispatch({ field: "dischargeRequest", value: val });
          }
        } else {
          dispatch({ field: key, value: val });
        }
      });
    }
    // return () => soc.disconnect();
  }, []);

  const handleAdd = () => {
    var now = new Date();
    var start = new Date(now.getFullYear(), 0, 0);
    var diff =
      now -
      start +
      (start.getTimezoneOffset() - now.getTimezoneOffset()) * 60 * 1000;
    var oneDay = 1000 * 60 * 60 * 24;
    var day = Math.floor(diff / oneDay);

    var dateNow = new Date();
    var YYYY = dateNow
      .getFullYear()
      .toString()
      .substr(-2);
    var HH = dateNow.getHours();
    var mm = dateNow.getMinutes();
    let ss = dateNow.getSeconds();

    const DischargeNo = "DCN" + day + YYYY + HH + mm + ss;
    // if (!validateForm()) {
    //   setIsFormSubmitted(true);
    //   setOpenNotification(true);
    //   setErrorMsg("Please fill the fields properly");
    // } else {
    // if (validateForm()) {

    // let medicineData = [];

    // for (let i = 0; i < seletedMedItems.length; i++) {
    //   console.log('Fucked::', seletedMedItems[i]);
    //   medicineData = [
    //     ...medicineData,
    //     {
    //       itemId: seletedMedItems[i].itemId,
    //       medicineName: seletedMedItems[i].medicineName,
    //       duration: seletedMedItems[i].duration,
    //       dosage: seletedMedItems[i].dosage,
    //       priority: seletedMedItems[i].priority,
    //       schedule: seletedMedItems[i].schedule,
    //       frequency: seletedMedItems[i].frequency,
    //       requestedQty: seletedMedItems[i].requestedQty,
    //       unitPrice: seletedMedItems[i].unitPrice,
    //       totalPrice: seletedMedItems[i].totalPrice,
    //       itemType: seletedMedItems[i].itemType,
    //       make_model: seletedMedItems[i].make_model,
    //       size: seletedMedItems[i].size,
    //       // totalPrice: seletedMedItems[i].totalPrice,
    //     },
    //   ];
    // }

    //SS
    let formData = new FormData();

    let medicinesList = seletedMedItems;

    for (let ixp = 0; ixp < medicinesList.length; ixp++) {
      if ( medicinesList[ixp].audio!=="" && medicinesList[ixp].audio instanceof Blob )
      {
        formData.append(`AudioFile_${medicinesList[ixp].itemId}`, medicinesList[ixp].audio, `${medicinesList[ixp].itemId}.mp3`);
        medicinesList[ixp].audio = '';
      }
    }


    // Prepare Discharge Request
    let dischargeMedicationObject = {
      date: date,
      status: status,
      requester: currentUser._id,
      medicine: medicinesList,
    };

    let dischargeRequest = {
      dischargeSummary: {
        followUpInstruction,
        dischargeNotes,
        edrCompletionRequirement,
        edrCompletionReason,
        edrAdmissionType
      },
      dischargeMedication: dischargeMedicationObject,
    }


    formData.append("edrId", episodeId);
    formData.append("requestType", requestType);
    formData.append("status", "Discharged");
    formData.append("staffId", currentUser._id);
    formData.append("dischargeRequest", JSON.stringify(dischargeRequest));
    formData.append("_id", id);
    console.log("Fucked::params", formData);
    axios
      .put(updateEdr, formData)
      .then((res) => {
        if (res.data.success) {
          console.log("response while adding Medicine Req", res.data);
          // notifyForDischarge(patientId);
          props.history.push({
            pathname: "success",
            state: {
              // request #
              message1: `Pharmacy Request for patient `,
              code: `MRN: ${res.data.data.patientId.identifier[0].value.toUpperCase()}`,
              message2: ` added successfully`,
            },
          });
        } else if (!res.data.success) {
          setOpenNotification(true);
          setErrorMsg("Error while adding the Medicine request");
        }
      })
      .catch((e) => {
        console.log("error after adding Medicine request", e);
        setOpenNotification(true);
        setErrorMsg("Error after adding the medicine request");
      });
  };
  // }
  // };

  console.log(dischargeMedicines);

  const notifyForDischarge = (id) => {
    axios
      .get(notifyDischarge + "/" + id)
      .then((res) => {
        console.log(res);
      })
      .catch((e) => {
        console.log("error after notify", e);
        setOpenNotification(true);
        setErrorMsg(e);
      });
  };

  if (openNotification) {
    setTimeout(() => {
      setOpenNotification(false);
      setErrorMsg("");
    }, 2000);
  }

  function hideDialog() {
    setDialogOpen(false);
    setSelectedItem("");
    setSelectItemToEditId("");

    dispatch({ field: "itemId", value: "" });
    dispatch({ field: "priority", value: "" });
    dispatch({ field: "schedule", value: "" });
    dispatch({ field: "dosage", value: "" });
    dispatch({ field: "frequency", value: "" });
    dispatch({ field: "duration", value: "" });
    dispatch({ field: "requestedQty", value: "" });
    dispatch({ field: "medicineName", value: "" });
    dispatch({ field: "unitPrice", value: "" });
    dispatch({ field: "totalPrice", value: "" });

    dispatch({ field: "make_model", value: "" });
    dispatch({ field: "size", value: "" });
    dispatch({ field: "selectedItemToSearch", value: "pharmaceutical" });
  }

  function validateItemsForm() {
    // return (
    //   itemId &&
    //   itemId.length > 0 &&
    //   medicineName &&
    //   medicineName.length > 0 &&
    //   priority &&
    //   priority.length > 0 &&
    //   schedule &&
    //   schedule.length > 0 &&
    //   duration &&
    //   duration.length > 0 &&
    //   frequency &&
    //   frequency.length > 0 &&
    //   dosage &&
    //   dosage.length > 0
    // );

    let checkForpharma = true;
    let checkForNonpharma = true;

    if (selectedItemToSearch === "non_pharmaceutical") {
      checkForpharma =
        requestedQty !== "" &&
        requestedQty !== 0 &&
        size !== "" &&
        make_model !== "";
    }

    if (selectedItemToSearch === "pharmaceutical") {
      checkForpharma =
        dosage !== "" &&
        frequency !== "" &&
        duration !== "" &&
        dosage !== "0" &&
        frequency !== "0" &&
        duration !== "0";
      // && schedule !== "" &&
      // priority !== "" &&
      // form !== "";
    }

    return (
      itemId &&
      itemId.length > 0 &&
      medicineName &&
      medicineName.length > 0 &&
      // maximumLevel >= requestedQty &&
      checkForpharma &&
      checkForNonpharma
    );
  }

  const addSelectedItem = () => {
    // setIsFormSubmitted(true);
    if (validateItemsForm()) {
      setDialogOpen(false);

      let found =
        dischargeMedicines &&
        dischargeMedicines.find((item) => item.itemId === itemId);

      if (found) {
        setOpenNotification(true);
        setErrorMsg("This Medicine has already been added.");
      } else {
        dispatch({
          field: "dischargeMedicines",
          value: [
            ...dischargeMedicines,
            {
              itemId,
              priority,
              schedule,
              dosage,
              frequency,
              duration,
              requestedQty:
                selectedItemToSearch === "pharmaceutical"
                  ? frequency * dosage * duration
                  : requestedQty,
              medicineName,
              itemType: selectedItemToSearch,
              make_model,
              size,
              // unitPrice: unitPrice,
              unitPrice: parseFloat(unitPrice).toFixed(4),
              // totalPrice: unitPrice * frequency * dosage * duration,
              totalPrice: parseFloat(
                unitPrice * frequency * dosage * duration
              ).toFixed(4),
            },
          ],
        });
      }
    }

    dispatch({ field: "itemId", value: "" });
    dispatch({ field: "priority", value: "" });
    dispatch({ field: "schedule", value: "" });
    dispatch({ field: "dosage", value: "" });
    dispatch({ field: "frequency", value: "" });
    dispatch({ field: "duration", value: "" });
    dispatch({ field: "requestedQty", value: "" });
    dispatch({ field: "medicineName", value: "" });
    dispatch({ field: "unitPrice", value: "" });
    dispatch({ field: "totalPrice", value: "" });
    dispatch({ field: "make_model", value: "" });
    dispatch({ field: "size", value: "" });
    dispatch({ field: "selectedItemToSearch", value: "pharmaceutical" });
    setEnableSave(false);
  };

  const editSelectedItem = () => {
    // if (validateItemsForm()) {
    console.log("unitprice", unitPrice);
    setDialogOpen(false);
    let temp = [];

    for (let i = 0; i < dischargeMedicines.length; i++) {
      if (dischargeMedicines[i].itemId === selectItemToEditId) {
        let obj = {
          itemId,
          priority,
          schedule,
          dosage,
          frequency,
          duration,
          requestedQty:
            selectedItemToSearch === "pharmaceutical"
              ? frequency * dosage * duration
              : requestedQty,
          medicineName,
          itemType: selectedItemToSearch,
          make_model,
          size,
          // unitPrice: unitPrice,
          unitPrice: parseFloat(unitPrice).toFixed(4),
          // totalPrice: unitPrice * frequency * dosage * duration,
          totalPrice: parseFloat(
            unitPrice * frequency * dosage * duration
          ).toFixed(4),
        };
        temp[i] = obj;
      } else {
        temp = [...temp, dischargeMedicines[i]];
      }
    }

    dispatch({
      field: "dischargeMedicines",
      value: temp,
    });
    // }

    setDialogOpen(false);
    setSelectedItem("");
    setSelectItemToEditId("");

    dispatch({ field: "itemId", value: "" });
    dispatch({ field: "priority", value: "" });
    dispatch({ field: "schedule", value: "" });
    dispatch({ field: "dosage", value: "" });
    dispatch({ field: "frequency", value: "" });
    dispatch({ field: "duration", value: "" });
    dispatch({ field: "requestedQty", value: "" });
    dispatch({ field: "medicineName", value: "" });
    dispatch({ field: "unitPrice", value: "" });
    dispatch({ field: "totalPrice", value: "" });
    dispatch({ field: "make_model", value: "" });
    dispatch({ field: "size", value: "" });
    dispatch({ field: "selectedItemToSearch", value: "pharmaceutical" });
    setEnableSave(false);
  };

  function handleRequestedItemEdit(i) {
    console.log(i);
    // if (i.status === "pending") {
    setDialogOpen(true);
    setSelectedItem(i.itemId);
    setSelectItemToEditId(i.itemId);

    dispatch({ field: "itemId", value: i.itemId });
    dispatch({ field: "priority", value: i.priority });
    dispatch({ field: "schedule", value: i.schedule });
    dispatch({ field: "dosage", value: i.dosage });
    dispatch({ field: "frequency", value: i.frequency });
    dispatch({ field: "duration", value: i.duration });
    dispatch({ field: "requestedQty", value: i.requestedQty });
    dispatch({ field: "medicineName", value: i.medicineName });
    dispatch({ field: "unitPrice", value: i.unitPrice });
    dispatch({ field: "totalPrice", value: i.totalPrice });
    dispatch({ field: "make_model", value: i.make_model });
    dispatch({ field: "size", value: i.size });
    dispatch({ field: "selectedItemToSearch", value: i.itemType });
    // } else {
    //   setOpenNotification(true);
    //   setErrorMsg("Item can not be updated once it is in progess");
    // }
  }

  const triggerMedChange = (a) => {
    handleSearch(a);
  };

  const handlePauseMedSearch = (e) => {
    setLoadSearchedData(true);
    clearTimeout(timer);

    const a = e.target.value.replace(/[^\w\s]/gi, "");
    setSearchQuery(a);

    setTimer(
      setTimeout(() => {
        triggerMedChange(a);
      }, 600)
    );
  };

  const handleSearch = (e) => {
    if (e.length >= 1) {
      let url = "";
      if (selectedItemToSearch === "pharmaceutical") {
        url = getSearchedPharmaceuticalItemsUrl;
      } else {
        url = getSearchedItemsNonPharmaceuticalUrl;
      }
      axios
        .get(url + "/" + e)
        .then((res) => {
          if (res.data.success) {
            if (res.data.data.items.length > 0) {
              console.log("price data", res.data.data);
              setItemFoundSuccessfully(true);
              setItemFound(res.data.data.items);
              setLoadSearchedData(false);
            } else {
              setItemFoundSuccessfully(false);
              setItemFound("");
              setLoadSearchedData(false);
            }
          }
        })
        .catch((e) => {
          console.log("error while searching medicine", e);
        });
    }
  };

  function handleAddItem(i) {
    // console.log("selected med", i);

    // dispatch({ field: "itemId", value: i._id });
    // dispatch({ field: "medicineName", value: i.name });
    // dispatch({ field: "unitPrice", value: i.issueUnitCost });
    // dispatch({ field: "issueUnit", value: i.issueUnit });
    // // dispatch({ field: 'totalPrice', value: i.purchasePrice + i.tax })

    // setSearchQuery("");
    console.log("selected item", i);

    // setSelectedLab(i);
    let readyToAdd = seletedMedItems;

    let itemExists = readyToAdd.filter((element, index, array) => {
        // Return true or false
        return element.itemId === i._id;
    });

    if ( itemExists.length<=0 ){
      readyToAdd.push({
        itemId: i._id,
        itemName: i.name,
        issueUnit: i.issueUnit,
        itemCode: i.itemCode,
        unitPrice: i.issueUnitCost,
        totalPrice: (1*1*1*i.issueUnitCost),
        issueUnit: i.issueUnit,
        schedule: 'Now',
        priority: '',
        dosage: 1,
        duration: 1,
        frequency: 1,
        requestedQty: 1,
        itemType: selectedItemToSearch,
        refill: 0,
        make_model: '',
        size: '',
        route: '',
        audio: null,
        notes: '',
      });
    }

    dispatch({
      field: 'seletedMedItems',
      value: readyToAdd
    });


    setSearchQuery("");
  }

  const onChangeRadio = (e) => {
    dispatch({ field: "selectedItemToSearch", value: e.target.name });
    setItemFoundSuccessfully(false);
    setItemFound("");
  };



  const onChangeNotesValue = (e, index, item) => {
    let newSelectedLabItems = seletedMedItems;
    newSelectedLabItems.map((d, index) => {
      if (d.itemId === item){
        d.notes = e;
      }
    });
    dispatch({
      field: 'seletedMedItems',
      value: newSelectedLabItems
    });
  };

  const removeSelectedLabItem = (index, id) => {
    if (window.confirm('Confirm Removal?')){

      seletedMedItems.map((d, index) => {
        if (d.itemId === id){
          console.log('LabReuqest::remove::', id, 'included');
          let indexOfItem = seletedMedItems.indexOf( d );
          console.log('LabReuqest::remove::', 'indexOfItem', indexOfItem);
          seletedMedItems.splice( indexOfItem, 1 );
          dispatch({
            field: 'seletedMedItems',
            value: seletedMedItems
          });
        }
      });


      console.log('LabReuqest::remove::', index, id);
      if (seletedMedItems.includes(id)){ // Already Exists Item

        
      }
    }
  };



  const startRecording = (index, item) => {
    console.log('handleView::ii::', index, item)
    if (isBlocked) {
      setOpenNotification(true);
      setErrorMsg("The Record is Blocked by the Browser!");
    } else {

      setOpenNotification(true);
      setsuccessMsg("The Record has been started!");
      Mp3Recorder.start()
        .then(() => {
          dispatch({ field: "isRecording", value: true });
          dispatch({ field: "recordingField", value: item });
          console.log('handleView::rec::',true)
        })
        .catch((e) => console.error(e));
    }
  };

  const stopRecording = () => {
    Mp3Recorder.stop()
      .getMp3()
      .then(([buffer, blob]) => {

        const blobURLFinal = URL.createObjectURL(blob);
        let blobURLx = blobURL;
        blobURLx[ recordingField ] = blobURLFinal;
        dispatch({ field: "blobURL", value: blobURLx });

        dispatch({ field: "isRecording", value: false });
        var file = new File([blob], "first", { type: "audio/mp3" });


        // Setting Up Audio File
        if (seletedMedItems.length > 0){
          seletedMedItems.map((i, itemIx) => {
            // let item = mItems.filter((element, index, array) => element._id === recordingField);
            console.log('handleView::rec::stop::', i, itemIx, recordingField)
            if (i.itemId == recordingField){
              i.audio = file;
            }
          });
        }


        dispatch({ field: "fileAudio", value: file });
        dispatch({ field: "recordingField", value: '' });
        console.log('handleView::rec::',false)
      })
      .catch((e) => console.log(e));
  };

  const [ episodeId, setEpisodeId ] = useState(props.match.params.id);

  return (
    <div
      style={{
        backgroundColor: "rgb(19 213 159)",
        position: "fixed",
        display: "flex",
        width: "100%",
        height: "100%",
        flexDirection: "column",
        flex: 1,
        overflowY: "scroll",
      }}
    >
      <Header history={props.history} />
      <PatientDetailsQuick
          edrId={episodeId}
        />
      <div className="cPadding">
        <div className="subheader" style={{ marginLeft: "-14px" }}>
          <div>
            <img src={purchase_request} />
            <h4>{comingFor === "add" ? " Discharge" : " Discharge"}</h4>
          </div>

          <div style={{ marginRight: "-14px" }}>
            {/*
            <Button
              onClick={() => setDialogOpen(true)}
              style={styles.stylesForButton}
              variant="contained"
              color="primary"
            >
              <img className="icon-style" src={plus_icon} />
              &nbsp;&nbsp;
              <strong style={{ fontSize: "12px" }}>Add New</strong>
            </Button>
            */}
          </div>
        </div>
        {/*
        <PatientClinicalInformation
          edrId={episodeId}
        />
        */}

        <div className="alert alert-info"><strong className="text-danger">Note:</strong> Please press the Save button, if the medication is not required!</div>

        <div
          className="form-group "
          style={{
            ...styles.inputContainerForTextField
          }}
        >
          <TextField
            required
            label="Search by Item Name"
            name={"searchQuery"}
            value={searchQuery}
            onChange={handlePauseMedSearch}
            className="textInputStyle"
            variant="filled"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <AccountCircle />
                </InputAdornment>
              ),
              className: classes.input,
              classes: { input: classes.input },
              disableUnderline: true,
            }}
            InputLabelProps={{
              className: classes.label,
              classes: { label: classes.label },
            }}
          />
        </div>


        {searchQuery ? (
          <div
            style={{
              zIndex: 10,
            }}
            className="mb-3 mb-3"
          >
            <Paper style={{ maxHeight: 280, overflow: "auto" }}>
              {itemFoundSuccessfull && itemFound !== "" ? (
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Service Name</TableCell>
                      <TableCell align="right">Type</TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {itemFound.map((i) => {
                      return (
                        <TableRow
                          key={i.itemCode}
                          onClick={() => handleAddItem(i)}
                          style={{ cursor: "pointer" }}
                        >
                          <TableCell><i className="fa fa-caret-right"></i><span className="ml-2"><strong>{i.itemCode}</strong>: {i.tradeName}</span><br />{i.scientificName}</TableCell>
                          <TableCell align="right">{i.category}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              ) : loadSearchedData ? (
                <div style={{ textAlign: "center" }}>
                  <Loader
                    type="TailSpin"
                    color="#2c6ddd"
                    height={25}
                    width={25}
                    style={{
                      display: "inline-block",
                      padding: "10px",
                    }}
                  />
                  <span
                    style={{
                      display: "inline-block",
                      padding: "10px",
                    }}
                  >
                    <h4> Searching Pharma Items...</h4>
                  </span>
                </div>
              ) : searchQuery && !itemFoundSuccessfull ? (
                <div style={{ textAlign: "center", padding: "10px" }}>
                  <h4>No Pharma Item Found !</h4>
                </div>
              ) : (
                undefined
              )}
            </Paper>
          </div>
        ) : (
          undefined
        )}

        <Paper className="mb-3">
          <Table size="small" stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Item Name</TableCell>
              </TableRow>
            </TableHead>
            {seletedMedItems.length > 0 ? (
              <TableBody>
                {seletedMedItems.map((i, itemIx) => {
                  return (
                    <TableRow
                      key={i}
                      style={{ cursor: "pointer" }}
                      title="Click to Remove"
                    >
                      <TableCell>
                        <div className="serviceOffers">
                          <div className="serviceHeader">
                            <div className="serviceDesc-Name">{i.xUrgent==='Yes'?<i className="fa fa-info-circle text-market"></i>:<i className="fa fa-caret-right"></i>}
                            <span className="ml-2 text-capitalize">
                              {
                                typeof(i.itemId) === 'object'?
                                <><strong>{i.itemId.itemCode}</strong>: {i.itemId.name} ( {i.itemId.issueUnit} )</>:
                                <><strong>{i.itemCode}</strong>: {i.itemName} ( {i.issueUnit} )</>
                              }
                            </span>
                            </div>
                            <div className="serviceOption text-right">
                              <Icon className="fa fa-trash" color="secondary" onClick={() => removeSelectedLabItem(i, i.itemId)} />
                            </div>
                          </div>
                          {/*<pre>{JSON.stringify(i, undefined, 4)}</pre>*/}
                          <div className="container-fluid-x">

                          <div className="row">
                            <div
                              className="col-md-3 col-sm-6 col-12"
                              style={{
                                ...styles.inputContainerForTextField,
                              }}
                            >
                              <div className="row row-5 align-items-center">
                                <div className="col-6 col-md-4"><strong>Dosage:</strong>&nbsp;</div>
                                <div className="col-6 col-md-8">
                                  <div className="row row-5">
                                    <div className="col-4">
                                      <TextField
                                        required
                                        disabled={props.disableFields ? true : false}
                                        name={"dosage"}
                                        value={i.dosage}
                                        onChange={(e) => {
                                          onChangeValue(e.target.value, 'dosage', i.itemId)
                                        }}
                                        className="textInputStyle text-center"
                                        size="small"
                                        type="number"
                                        InputProps={{
                                          inputProps: { min: 1 },
                                          shrink: true,
                                          className: classes.input,
                                          classes: { input: classes.input },
                                        }}
                                      />
                                    </div>
                                    <div className="col-8">
                                      <TextField
                                        required
                                        select
                                        fullWidth
                                        disabled={props.disableFields ? true : false}
                                        id="dosageType"
                                        name="dosageType"
                                        value={i.dosageType}
                                        onChange={(e) => {
                                          onChangeValue(e.target.value, 'dosageType', i.itemId)
                                        }}
                                        className="dropDownStyle"
                                        InputProps={{
                                          className: classes.input,
                                          classes: { input: classes.input },
                                        }}
                                      >
                                        <MenuItem value="">None</MenuItem>
                                        {dosageArray.map((val) => {
                                          return (
                                            <MenuItem key={val.key} value={val.key}>
                                              {val.value}
                                            </MenuItem>
                                          );
                                        })}
                                      </TextField>
                                    </div>
                                  </div>
                                  {
                                    isFormSubmitted && (i.dosage === "" || i.dosage <= 0) ? <div class="mt-2 text-danger">Required</div>:undefined
                                  }
                                </div>
                              </div>
                            </div>

                            <div
                              className="col-md-3 col-sm-6 col-6"
                              style={{
                                ...styles.inputContainerForTextField,
                              }}
                            >
                              <div className="row row-5 align-items-center">
                                <div className="col-6 col-md-4"><strong>Frequency:</strong></div>
                                <div className="col-6 col-md-8">
                                  <TextField
                                    required
                                    select
                                    fullWidth
                                    disabled={props.disableFields ? true : false}
                                    id="frequency"
                                    name="frequency"
                                    value={i.frequency}
                                    onChange={(e) => {
                                      onChangeValue(e.target.value, 'frequency', i.itemId)
                                    }}
                                    className="dropDownStyle"
                                    InputProps={{
                                      className: classes.input,
                                      classes: { input: classes.input },
                                    }}
                                  >
                                    <MenuItem value="">None</MenuItem>
                                    {frequencyArray.map((val) => {
                                      return (
                                        <MenuItem key={val.key} value={val.key}>
                                          {val.value}
                                        </MenuItem>
                                      );
                                    })}
                                  </TextField>
                                  {
                                    isFormSubmitted && (i.frequency === "" || i.frequency <= 0) ? <div class="mt-2 text-danger">Required</div>:undefined
                                  }
                                </div>
                              </div>
                            </div>

                            <div
                              className="col-md-3 col-sm-6 col-6"
                              style={{
                                ...styles.inputContainerForTextField,
                              }}
                            >
                              <div className="row row-5 align-items-center">
                                <div className="col-6 col-md-6"><strong>Duration <small>(Days)</small>:</strong></div>
                                <div className="col-6 col-md-6">
                                  <TextField
                                    required
                                    disabled={props.disableFields ? true : false}
                                    name={"duration"}
                                    value={i.duration}
                                    onChange={(e) => {
                                      onChangeValue(e.target.value, 'duration', i.itemId)
                                    }}
                                    className="textInputStyle text-center"
                                    type="number"
                                    InputProps={{
                                      inputProps: { min: 1 },
                                      className: classes.input,
                                      classes: { input: classes.input },
                                    }}
                                  />
                                  {
                                    isFormSubmitted && (i.duration === "" || i.duration <= 0) ? <div class="mt-2 text-danger">Required</div>:undefined
                                  }
                                </div>
                              </div>

                              {/* <ErrorMessage
                                    name={duration}
                                    isFormSubmitted={isFormSubmitted}
                                  /> */}
                            </div>

                            <div
                              className="col-md-3 col-sm-6 col-6"
                              style={{
                                ...styles.inputContainerForTextField,
                              }}
                            >
                              <div className="row row-5 align-items-center">
                                <div className="col-6 col-md-6"><strong>Req. Qty{i.dosageType?` ( ${i.dosageType} )`:''}:</strong></div>
                                <div className="col-6 col-md-6">
                                  <TextField
                                    required
                                    disabled={i.frequency>0&&i.frequency<=10?true:false}
                                    name={"requestedQty"}
                                    value={(i.frequency>=11 || i.frequency===0)?i.dispensed:(i.dosage * i.duration * i.frequency)}
                                    onChange={(e) => {
                                      onChangeValue(e.target.value, 'requestedQty', i.itemId)
                                    }}
                                    className="textInputStyle text-center"
                                    type="number"
                                    InputProps={{
                                      className: classes.input,
                                      classes: { input: classes.input },
                                    }}
                                  />
                                </div>
                              </div>

                              {/* <ErrorMessage
                                    name={requestedQty}
                                    isFormSubmitted={isFormSubmitted}
                                  /> */}
                            </div>

                            <div
                              className="col-md-2 col-sm-6 col-6"
                              style={{
                                ...styles.inputContainerForTextField,
                              }}
                            >
                              <div className="row row-5 align-items-center">
                                <div className="col-6 col-md-6"><strong>Refill:</strong></div>
                                <div className="col-6 col-md-6">
                                  <TextField
                                    required
                                    disabled={props.disableFields ? true : false}
                                    name={"refill"}
                                    value={i.refill}
                                    onChange={(e) => {
                                      onChangeValue(e.target.value, 'refill', i.itemId)
                                    }}
                                    className="textInputStyle text-center"
                                    type="number"
                                    InputProps={{
                                      inputProps: { min: 0 },
                                      className: classes.input,
                                      classes: { input: classes.input },
                                    }}
                                  />
                                  {
                                    isFormSubmitted && (i.refill === "" || i.refill <= 0) ? <div class="mt-2 text-danger">Required</div>:undefined
                                  }
                                </div>
                              </div>

                              {/* <ErrorMessage
                                    name={refill}
                                    isFormSubmitted={isFormSubmitted}
                                  /> */}
                            </div>


                            <div
                              className="col-md-10 col-sm-12 col-12"
                              style={{
                                ...styles.inputContainerForTextField,
                              }}
                            >
                              <div className="row row-5 align-items-center">
                                <div className="col-4 col-md-3 col-lg-2"><strong>Route Taken:</strong></div>
                                <div className="col-8 col-md-9 col-lg-10">
                                  <TextField
                                    required
                                    select
                                    fullWidth
                                    name="route"
                                    value={i.route}
                                    onChange={(e) => {
                                      onChangeValue(e.target.value, 'route', i.itemId)
                                    }}
                                    className="dropDownStyle"
                                    InputProps={{
                                      className: classes.input,
                                      classes: { input: classes.input },
                                    }}
                                  >
                                    {routeArray.map((val) => {
                                      return (
                                        <MenuItem key={val.key} value={val.key}>
                                          {val.value}
                                        </MenuItem>
                                      );
                                    })}
                                  </TextField>
                                  {
                                    isFormSubmitted && i.route === "" ? <div class="mt-2 text-danger">Required</div>:undefined
                                  }
                                  {/* <ErrorMessage
                                        name={route}
                                        isFormSubmitted={isFormSubmitted}
                                      /> */}
                                </div>
                              </div>
                            </div>
                          </div>
                          </div>


                          <div className="serviceNotes">
                            <div className="micControl">
                              <div className="d-flex align-items-center">
                                <div
                                  style={{
                                    backgroundColor: "#2C6DDD",
                                    height: 42,
                                    borderRadius: 42,
                                    width: 42,
                                    paddingLeft: 10,
                                    cursor: 'pointer'
                                  }}
                                >
                                  {isRecording ? (
                                    recordingField===i.itemId?
                                    <StopIcon
                                      style={{ marginTop: 10, color: "white" }}
                                      fontSize="16px"
                                      onClick={stopRecording}
                                      disabled={!isRecording}
                                      color="secondary"
                                    />:
                                    <MicIcon
                                      style={{ marginTop: 10, color: "white" }}
                                      fontSize="16px"
                                      onClick={() => {
                                        startRecording(i, i.itemId)
                                      }}
                                      disabled={isRecording}
                                      color="primary"
                                    />
                                  ) : (
                                    <MicIcon
                                      style={{ marginTop: 10, color: "white" }}
                                      fontSize="16px"
                                      onClick={() => {
                                        startRecording(i, i.itemId)
                                      }}
                                      disabled={isRecording}
                                      color="primary"
                                    />
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="notesDiv">
                              <TextField
                                // required
                                multiline="true"
                                rows="3"
                                // disabled={enableForm}
                                label="Any Comments / Notes? (Optional)"
                                name={"labComments"}
                                value={i.notes}
                                // error={labComments === ""}
                                onChange={(e) => {onChangeNotesValue(e.target.value, i, i.itemId)}}
                                className="textInputStyle"
                                variant="filled"
                                InputProps={{
                                  disableUnderline: true,
                                }}
                              />
                              {isRecording && (recordingField===i.itemId) ? (
                                <div
                                  style={{
                                    marginLeft: matches ? 110 : 18,
                                    marginRight: matches ? 110 : 75,
                                    width: matches ? 550 : 100,
                                  }}
                                >
                                  <Loader
                                    type="ThreeDots"
                                    color="blue"
                                    height={40}
                                    width={40}
                                  />
                                </div>
                              ) : (
                                <audio
                                  style={{
                                    marginRight: 10,
                                    width: matches ? 450 : 260,
                                    marginTop: 6,
                                  }}
                                  src={i.audio && i.audio.includes('uploads')?i.audio:blobURL[i.itemId]}
                                  controls="controls"
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            ) : (
              <TableBody>
                <TableRow key="0">
                  <TableCell align="center" colSpan={5}>No Item Selected</TableCell>
                </TableRow>
              </TableBody>
            )} 
          </Table>
        </Paper>



        <div
          style={{ flex: 4, display: "flex", flexDirection: "column" }}
          className="container-fluid"
        >

          <div
            className="row"
            style={{ marginTop: "25px", marginBottom: "25px" }}
          >
            <div
              className="col-md-6 col-sm-6 col-6"
              style={{ paddingLeft: "1px" }}
            >
              <img
                onClick={() => props.history.goBack()}
                src={Back}
                style={{ width: 45, height: 35, cursor: "pointer" }}
              />
            </div>

            <div
              className="col-md-6 col-sm-6 col-6 d-flex justify-content-end"
              style={{ paddingRight: "1px" }}
            >
              <Button
                style={styles.stylesForPurchaseButton}
                // disabled={!validateForm()}
                disabled={enableSave}
                onClick={handleAdd}
                variant="contained"
                color="primary"
              >
                <strong style={{ fontSize: "12px" }}>Save</strong>
              </Button>
            </div>
          </div>

          <Notification msg={errorMsg} open={openNotification} />

          <Dialog
            aria-labelledby="form-dialog-title"
            open={dialogOpen}
            // maxWidth="xl"
            // fullWidth={true}
            fullScreen
          >
            <DialogContent style={{ backgroundColor: "rgb(19 213 159)" }}>
              <DialogTitle
                id="simple-dialog-title"
                style={{ color: "white", marginLeft: -19 }}
              >
                Add Medicine
              </DialogTitle>
              <div className={`${"container-fluid"} ${classes.root}`}>
                {/*
                <div
                  className="container-fluid"
                  style={{
                    ...styles.inputContainerForTextField,
                    ...styles.textFieldPadding,
                  }}
                >
                  <div
                    className="row"
                    style={{
                      backgroundColor: "white",
                      height: matches ? 55 : 155,
                      paddingLeft: matches ? 0 : 10,
                      display: "flex",
                      alignItems: "center",
                      borderRadius: 5,
                      paddingTop: 8,
                    }}
                  >
                    <h6
                      className="col-md-4"
                      style={{ verticalAlign: "center" }}
                    >
                      Item Type
                    </h6>

                    <FormControl className="col-md-8" component="fieldset">
                      <RadioGroup
                        row
                        aria-label="position"
                        name="position"
                        // defaultValue="top"
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <FormControlLabel
                          // value={selectedItemToSearch}
                          name={"pharmaceutical"}
                          control={<Radio color="primary" />}
                          label="Pharmaceutical"
                          onChange={onChangeRadio}
                          checked={
                            selectedItemToSearch === "pharmaceutical"
                              ? true
                              : false
                          }
                          disabled={selectItemToEditId ? true : false}
                        />

                        <FormControlLabel
                          // value={selectedItemToSearch}
                          name={"non_pharmaceutical"}
                          control={<Radio color="primary" />}
                          label="Non-Pharmaceutical"
                          onChange={onChangeRadio}
                          checked={
                            selectedItemToSearch === "non_pharmaceutical"
                              ? true
                              : false
                          }
                          disabled={selectItemToEditId ? true : false}
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                </div>
                */}
                {selectedItemToSearch === "pharmaceutical" ? (
                  <div>
                    <>
                      {selectItemToEditId === "" ? (
                        <div className="row" style={{ marginTop: 15 }}>
                          <div
                            className="col-md-12 col-sm-12 col-12"
                            style={{
                              ...styles.textFieldPadding,
                              ...styles.inputContainerForTextField,
                            }}
                          >
                            <TextField
                              required
                              type="text"
                              label="Search Medicine by Name"
                              name={"searchQuery"}
                              value={searchQuery}
                              onChange={handlePauseMedSearch}
                              className="textInputStyle"
                              variant="filled"
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <AccountCircle />
                                  </InputAdornment>
                                ),
                                className: classes.input,
                                classes: { input: classes.input },
                              }}
                              InputLabelProps={{
                                className: classes.label,
                                classes: { label: classes.label },
                              }}
                            />
                            {/* <InputLabelComponent>Search Medicine</InputLabelComponent>
                    <input
                      type='text'
                      placeholder='Search medicine by name'
                      name={'searchQuery'}
                      value={searchQuery}
                      onChange={handleSearch}
                      className='textInputStyle'
                    /> */}
                          </div>
                        </div>
                      ) : (
                        undefined
                      )}
                    </>

                    {searchQuery ? (
                      <div
                        style={{
                          zIndex: 3,
                          marginTop: 5,
                          marginLeft: -8,
                          width: "101.5%",
                        }}
                      >
                        <Paper style={{ maxHeight: 200, overflow: "auto" }}>
                          {itemFoundSuccessfull && itemFound !== "" ? (
                            <Table size="small">
                              <TableHead>
                                <TableRow>
                                  <TableCell>Medicine Name</TableCell>
                                  <TableCell>Scientific Name</TableCell>
                                  <TableCell>Item Code</TableCell>
                                  <TableCell>Unit Price (JD)</TableCell>
                                  {/* <TableCell>Total Price</TableCell> */}
                                </TableRow>
                              </TableHead>

                              <TableBody>
                                {itemFound.map((i) => {
                                  return (
                                    <TableRow
                                      key={i.itemCode}
                                      onClick={() => handleAddItem(i)}
                                      style={{ cursor: "pointer" }}
                                    >
                                      <TableCell>{i.tradeName}</TableCell>
                                      <TableCell>{i.scientificName}</TableCell>
                                      <TableCell>{i.itemCode}</TableCell>
                                      <TableCell>
                                        {i.issueUnitCost.toFixed(4)}
                                      </TableCell>
                                      {/* <TableCell>
                                      {i.purchasePrice + i.tax}
                                    </TableCell> */}
                                    </TableRow>
                                  );
                                })}
                              </TableBody>
                            </Table>
                          ) : loadSearchedData ? (
                            <div style={{ textAlign: "center" }}>
                              <Loader
                                type="TailSpin"
                                color="#2c6ddd"
                                height={25}
                                width={25}
                                style={{
                                  display: "inline-block",
                                  padding: "10px",
                                }}
                              />
                              <span
                                style={{
                                  display: "inline-block",
                                  padding: "10px",
                                }}
                              >
                                <h4> Searching Medicine...</h4>
                              </span>
                            </div>
                          ) : searchQuery && !itemFoundSuccessfull ? (
                            <div
                              style={{ textAlign: "center", padding: "10px" }}
                            >
                              <h4>No Medicine Found !</h4>
                            </div>
                          ) : (
                            undefined
                          )}
                        </Paper>
                      </div>
                    ) : (
                      undefined
                    )}

                    <div className="row">
                      <div
                        className="col-md-4 col-sm-4 col-12"
                        style={{
                          ...styles.inputContainerForTextField,
                          ...styles.textFieldPadding,
                        }}
                      >
                        <TextField
                          required
                          label="Item Name"
                          name={"medicineName"}
                          value={medicineName}
                          disabled
                          className="textInputStyle"
                          variant="filled"
                          InputProps={{
                            className: classes.input,
                            classes: { input: classes.input },
                          }}
                        />
                      </div>
                      <div
                        className="col-md-4 col-sm-4 col-12"
                        style={{
                          ...styles.inputContainerForTextField,
                          ...styles.textFieldPadding,
                        }}
                      >
                        <TextField
                          required
                          select
                          label="Schedule"
                          name={"schedule"}
                          value={schedule}
                          onChange={onChangeValue}
                          className="textInputStyle"
                          variant="filled"
                          InputProps={{
                            className: classes.input,
                            classes: { input: classes.input },
                          }}
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          {scheduleArray.map((val) => {
                            return (
                              <MenuItem key={val.key} value={val.key}>
                                {val.value}
                              </MenuItem>
                            );
                          })}
                        </TextField>
                        {/* <ErrorMessage
                          name={schedule}
                          isFormSubmitted={isFormSubmitted}
                        /> */}
                      </div>

                      <div
                        className="col-md-4 col-sm-4 col-12"
                        style={{
                          ...styles.inputContainerForTextField,
                          ...styles.textFieldPadding,
                        }}
                      >
                        <TextField
                          required
                          select
                          fullWidth
                          id="priority"
                          name="priority"
                          value={priority}
                          onChange={onChangeValue}
                          label="Priority"
                          variant="filled"
                          className="dropDownStyle"
                          InputProps={{
                            className: classes.input,
                            classes: { input: classes.input },
                          }}
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          {priorityArray.map((val) => {
                            return (
                              <MenuItem key={val.key} value={val.key}>
                                {val.value}
                              </MenuItem>
                            );
                          })}
                        </TextField>
                        {/* <ErrorMessage
                          name={priority}
                          isFormSubmitted={isFormSubmitted}
                        /> */}
                        {/* <InputLabelComponent>Priority*</InputLabelComponent>
                    <Select
                      fullWidth
                      id='priority'
                      name='priority'
                      value={priority}
                      onChange={onChangeValue}
                      label='Priority'
                      className='dropDownStyle'
                      input={<BootstrapInput />}
                    >
                      <MenuItem value=''>
                        <em>None</em>
                      </MenuItem>
                      {priorityArray.map((val) => {
                        return (
                          <MenuItem key={val.key} value={val.key}>
                            {val.value}
                          </MenuItem>
                        )
                      })}
                    </Select>
                    <ErrorMessage
                      name={priority}
                      isFormSubmitted={isFormSubmitted}
                    /> */}
                      </div>
                    </div>

                    <div className="row">
                      <div
                        className="col-md-3"
                        style={{
                          ...styles.inputContainerForTextField,
                          ...styles.textFieldPadding,
                        }}
                      >
                        <TextField
                          required
                          disabled={true}
                          label="Issue Unit"
                          name={"issueUnit"}
                          value={issueUnit}
                          onChange={onChangeValue}
                          className="textInputStyle"
                          variant="filled"
                          InputProps={{
                            className: classes.input,
                            classes: { input: classes.input },
                          }}
                        />
                        {/* <ErrorMessage
                          name={dosage}
                          isFormSubmitted={isFormSubmitted}
                        /> */}
                      </div>
                      <div
                        className="col-md-3"
                        style={{
                          ...styles.inputContainerForTextField,
                          ...styles.textFieldPadding,
                        }}
                      >
                        <TextField
                          required
                          label="Dosage"
                          name={"dosage"}
                          value={dosage}
                          onChange={onChangeValue}
                          className="textInputStyle"
                          variant="filled"
                          InputProps={{
                            className: classes.input,
                            classes: { input: classes.input },
                          }}
                        />
                        {/* <ErrorMessage
                          name={dosage}
                          isFormSubmitted={isFormSubmitted}
                        /> */}
                      </div>
                      {/* <div
                        className='col-md-2'
                        style={{
                          ...styles.inputContainerForTextField,
                          ...styles.textFieldPadding,
                        }}
                      >
                        <TextField
                          required
                          label='Select Unit'
                          select
                          name={'dosageUnit'}
                          value={
                            !dosageUnit ? dosageUnitArray[0].value : dosageUnit
                          }
                          onChange={onChangeValue}
                          className='textInputStyle'
                          variant='filled'
                          InputProps={{
                            className: classes.input,
                            classes: { input: classes.input },
                          }}
                        >
                          {dosageUnitArray.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </div> */}

                      <div
                        className="col-md-3"
                        style={{
                          ...styles.inputContainerForTextField,
                          ...styles.textFieldPadding,
                        }}
                      >
                        <TextField
                          required
                          label="Frequency"
                          name={"frequency"}
                          value={frequency}
                          onChange={onChangeValue}
                          className="textInputStyle"
                          variant="filled"
                          InputProps={{
                            className: classes.input,
                            classes: { input: classes.input },
                          }}
                        />
                      </div>

                      {/* <div
                        className='col-md-2'
                        style={{
                          ...styles.inputContainerForTextField,
                          ...styles.textFieldPadding,
                        }}
                      >
                        <TextField
                          required
                          label='Select Unit'
                          select
                          name={'frequencyUnit'}
                          value={
                            !frequencyUnit
                              ? frequencyUnitArray[0].value
                              : frequencyUnit
                          }
                          onChange={onChangeValue}
                          className='textInputStyle'
                          variant='filled'
                          InputProps={{
                            className: classes.input,
                            classes: { input: classes.input },
                          }}
                        >
                          {frequencyUnitArray.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </div> */}

                      <div
                        className="col-md-3"
                        style={{
                          ...styles.inputContainerForTextField,
                          ...styles.textFieldPadding,
                        }}
                      >
                        <TextField
                          required
                          label="Duration (Days)"
                          name={"duration"}
                          value={duration}
                          onChange={onChangeValue}
                          className="textInputStyle"
                          variant="filled"
                          InputProps={{
                            className: classes.input,
                            classes: { input: classes.input },
                          }}
                        />

                        {/* <ErrorMessage
                          name={duration}
                          isFormSubmitted={isFormSubmitted}
                        /> */}
                      </div>

                      {/* <div
                        className='col-md-2'
                        style={{
                          ...styles.inputContainerForTextField,
                          ...styles.textFieldPadding,
                        }}
                      >
                        <TextField
                          required
                          label='Select Unit'
                          select
                          name={'durationUnit'}
                          value={
                            !durationUnit
                              ? durationArray[0].value
                              : durationUnit
                          }
                          onChange={onChangeValue}
                          className='textInputStyle'
                          variant='filled'
                          InputProps={{
                            className: classes.input,
                            classes: { input: classes.input },
                          }}
                        >
                          {durationArray.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </div> */}

                      <div
                        className="col-md-12"
                        style={{
                          ...styles.inputContainerForTextField,
                          ...styles.textFieldPadding,
                        }}
                      >
                        <TextField
                          required
                          disabled
                          label="Requested Quantity"
                          name={"requestedQty"}
                          value={dosage * duration * frequency}
                          onChange={onChangeValue}
                          className="textInputStyle"
                          variant="filled"
                          InputProps={{
                            className: classes.input,
                            classes: { input: classes.input },
                          }}
                        />

                        {/* <ErrorMessage
                          name={requestedQty}
                          isFormSubmitted={isFormSubmitted}
                        /> */}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div className="row">
                      {selectItemToEditId === "" ? (
                        <>
                          <div
                            className="col-md-12"
                            style={{
                              ...styles.inputContainerForTextField,
                              ...styles.textFieldPadding,
                            }}
                          >
                            <TextField
                              type="text"
                              label="Item Name / Manufacturer / Vendor"
                              name={"searchQuery"}
                              value={searchQuery}
                              onChange={handlePauseMedSearch}
                              className={classes.margin}
                              variant="filled"
                              InputProps={{
                                className: classes.input,
                                classes: { input: classes.input },
                              }}
                              className="textInputStyle"
                            />
                            {/* </div> */}
                          </div>
                        </>
                      ) : (
                        undefined
                      )}
                    </div>

                    {searchQuery ? (
                      <div
                        style={{
                          zIndex: 3,
                          marginTop: 5,
                          marginLeft: -8,
                          width: "101.5%",
                        }}
                      >
                        <Paper style={{ maxHeight: 200, overflow: "auto" }}>
                          {itemFoundSuccessfull && itemFound !== "" ? (
                            <Table size="small">
                              <TableHead>
                                <TableRow>
                                  <TableCell
                                    align="center"
                                    style={styles.forTableCell}
                                  >
                                    Trade Name
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    style={styles.forTableCell}
                                  >
                                    Scientific Name
                                  </TableCell>

                                  {/* <TableCell
                                  align="center"
                                  style={styles.forTableCell}
                                >
                                  Form
                                </TableCell> */}

                                  <TableCell
                                    style={styles.forTableCell}
                                    align="center"
                                  >
                                    Description
                                  </TableCell>
                                </TableRow>
                              </TableHead>

                              <TableBody>
                                {itemFound.map((i, index) => {
                                  return (
                                    <TableRow
                                      key={i.itemCode}
                                      onClick={() => handleAddItem(i)}
                                      style={{ cursor: "pointer" }}
                                    >
                                      <TableCell align="center">
                                        {i.tradeName}
                                      </TableCell>
                                      <TableCell align="center">
                                        {i.scientificName}
                                      </TableCell>

                                      {/* <TableCell align="center">
                                      {i.form}
                                    </TableCell> */}

                                      <TableCell align="center">
                                        {i.description}
                                      </TableCell>
                                    </TableRow>
                                  );
                                })}
                              </TableBody>
                            </Table>
                          ) : loadSearchedData ? (
                            <div style={{ textAlign: "center" }}>
                              <Loader
                                type="TailSpin"
                                color="#2c6ddd"
                                height={25}
                                width={25}
                                style={{
                                  display: "inline-block",
                                  padding: "10px",
                                }}
                              />
                              <span
                                style={{
                                  display: "inline-block",
                                  padding: "10px",
                                }}
                              >
                                <h4> Searching Medicine...</h4>
                              </span>
                            </div>
                          ) : searchQuery && !itemFoundSuccessfull ? (
                            <div
                              style={{ textAlign: "center", padding: "10px" }}
                            >
                              <h4>No Medicine Found !</h4>
                            </div>
                          ) : (
                            undefined
                          )}
                        </Paper>
                      </div>
                    ) : (
                      undefined
                    )}

                    <div className="row">
                      <div
                        className="col-md-3"
                        style={{
                          ...styles.inputContainerForTextField,
                          ...styles.textFieldPadding,
                        }}
                      >
                        <TextField
                          required
                          id="medicineName"
                          label="Item Name"
                          name={"medicineName"}
                          disabled={true}
                          type="text"
                          value={medicineName}
                          onChange={onChangeValue}
                          variant="filled"
                          className="textInputStyle"
                          InputProps={{
                            className: classes.input,
                            classes: { input: classes.input },
                          }}
                          error={medicineName === "" && isFormSubmitted}
                        />
                      </div>

                      <div
                        className="col-md-3"
                        style={{
                          ...styles.inputContainerForTextField,
                          ...styles.textFieldPadding,
                        }}
                      >
                        <TextField
                          select
                          // required
                          fullWidth
                          id="make_model"
                          name="make_model"
                          value={make_model}
                          onChange={onChangeValue}
                          label="Make/Model"
                          variant="filled"
                          InputProps={{
                            className: classes.input,
                            classes: { input: classes.input },
                          }}
                          error={make_model === "" && isFormSubmitted}
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>

                          {modalArray.map((val) => {
                            return (
                              <MenuItem key={val.key} value={val.key}>
                                {val.value}
                              </MenuItem>
                            );
                          })}
                        </TextField>
                      </div>

                      <div
                        className="col-md-3"
                        style={{
                          ...styles.inputContainerForTextField,
                          ...styles.textFieldPadding,
                        }}
                      >
                        <TextField
                          select
                          // required
                          fullWidth
                          id="size"
                          name="size"
                          value={size}
                          onChange={onChangeValue}
                          label="Size"
                          variant="filled"
                          InputProps={{
                            className: classes.input,
                            classes: { input: classes.input },
                          }}
                          error={size === "" && isFormSubmitted}
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>

                          {sizeArray.map((val) => {
                            return (
                              <MenuItem key={val.key} value={val.key}>
                                {val.value}
                              </MenuItem>
                            );
                          })}
                        </TextField>
                      </div>

                      <div
                        className="col-md-3"
                        style={{
                          ...styles.inputContainerForTextField,
                          ...styles.textFieldPadding,
                        }}
                      >
                        <TextField
                          required
                          id="requestedQty"
                          label="Quantity"
                          name={"requestedQty"}
                          type="number"
                          value={requestedQty}
                          onChange={onChangeValue}
                          className="textInputStyle"
                          variant="filled"
                          onKeyDown={(evt) => {
                            (evt.key === "e" ||
                              evt.key === "E" ||
                              evt.key === "-" ||
                              evt.key === "+") &&
                              evt.preventDefault();
                          }}
                          InputProps={{
                            className: classes.input,
                            classes: { input: classes.input },
                          }}
                          error={requestedQty === "" && isFormSubmitted}
                        />
                      </div>
                    </div>
                  </div>
                )}

                <div
                  class="row"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    paddingLeft: 2,
                    paddingRight: 2,
                  }}
                >
                  <div style={{ marginTop: "2%", marginBottom: "2%" }}>
                    <Button
                      onClick={() => hideDialog()}
                      style={styles.stylesForButton}
                      variant="contained"
                    >
                      <strong>Cancel</strong>
                    </Button>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      marginTop: "2%",
                      marginBottom: "2%",
                    }}
                  >
                    {selectItemToEditId === "" ? (
                      <Button
                        style={{
                          color: "white",
                          cursor: "pointer",
                          borderRadius: 5,
                          backgroundColor: "#2c6ddd",
                          width: "140px",
                          height: "50px",
                          outline: "none",
                          paddingLeft: 30,
                          paddingRight: 30,
                        }}
                        disabled={!validateItemsForm()}
                        onClick={addSelectedItem}
                        variant="contained"
                        color="primary"
                      >
                        Add
                      </Button>
                    ) : (
                      <Button
                        style={{
                          color: "white",
                          cursor: "pointer",
                          borderRadius: 5,
                          backgroundColor: "#2c6ddd",
                          width: "140px",
                          height: "50px",
                          outline: "none",
                          paddingLeft: 30,
                          paddingRight: 30,
                        }}
                        // disabled={!validateItemsForm()}
                        onClick={editSelectedItem}
                        variant="contained"
                        color="primary"
                      >
                        {" "}
                        Edit
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            </DialogContent>
          </Dialog>
        </div>
      </div>
    </div>
  );
}
export default AddEditEDR;
