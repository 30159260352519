import React, { useEffect, useState } from 'react'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Flip from '../../../components/Dial/Flip'
import TwoValue from '../../../components/Dial/TwoValue'
import OneValue from '../../../components/Dial/OneValue'
import { swDashboard } from '../../../public/endpoins'
import {
  getDialerGraphCall,
  getValuesCardCalls,
  getResponse,
} from './NetworkCalls'
import Notification from '../../../components/Snackbar/Notification.js'
import Loader from 'react-loader-spinner'

export default function SocialWorker() {
  const matches = useMediaQuery('(min-width:600px)')
  // const [parPending, setParPending] = useState('')
  // const [totalInsured, setTotalInsured] = useState('')
  // const [totalUnInsured, setTotalUnInsured] = useState('')
  // const [patient, setPatient] = useState('')
  // const [perHour, setPerHour] = useState('')

  const [responseData, setResponseData] = useState([])
  const [errorMsg, setErrorMsg] = useState('')
  const [successMsg, setsuccessMsg] = useState('')
  const [openNotification, setOpenNotification] = useState(false)
  const [isLoading, setisLoading] = useState(true)

  // Pending Patients Interviews
  const [pendingInterviews, setPendingInterviews] = useState('')
  const [pendingInterviewsColor, setPendingInterviewsColor] = useState('')
  const [pendingInterviewsTAT, setPendingInterviewsTAT] = useState('')
  const [pendingInterviewsTATColor, setPendingInterviewsTATColor] = useState('')
  const [pendingInterviewsGraphData, setPendingInterviewsGraphData] = useState(
    ''
  )

  // Patients discharges pending
  const [dischargesPending, setDischargesPending] = useState('')
  const [dischargesPendingColor, setDischargesPendingColor] = useState('')
  const [dischargesPendingTAT, setdischargesPendingTAT] = useState('')
  const [dischargesPendingTATColor, setDischargesPendingTATColor] = useState('')
  const [dischargesPendingGraphData, setDischargesPendingGraphData] = useState(
    ''
  )

  // Patients discharges pending
  const [interviewsPerHour, setInterviewsPerHour] = useState('')
  const [interviewsPerHourColor, setInterviewsPerHourColor] = useState('')
  const [interviewsPerHourTAT, setInterviewsPerHourTAT] = useState('')
  const [interviewsPerHourTATColor, setInterviewsPerHourTATColor] = useState('')
  const [interviewsPerHourGraphData, setInterviewsPerHourGraphData] = useState(
    ''
  )

  // Values cards
  const [clearedInterviews, setClearedInterviews] = useState('')
  const [clearedInterviewsColor, setClearedInterviewsColor] = useState('')

  const [comulativeInterviews, setComulativeInterviews] = useState('')
  const [comulativeInterviewsColor, setComulativeInterviewsColor] = useState('')

  const [flip, setFlip] = useState(false)

  useEffect(() => {
    getResponse(
      swDashboard,
      setResponseData,
      setOpenNotification,
      setErrorMsg,
      setisLoading
    )
    console.log('*in getResponse edDoctor')
  }, [])

  if (openNotification) {
    setTimeout(() => {
      setOpenNotification(false)
      setErrorMsg('')
      setsuccessMsg('')
    }, 2000)
  }

  useEffect(() => {
    let arr = []

    Object.entries(responseData).map(([key, val]) => {
      if (key !== 'success') {
        console.log('key of data', key, 'val of data', val)
        arr.push({ [key]: val })
      }
    })

    console.log('arr::', arr)
    if (arr && arr.length > 0) {
      getDialerGraphCall(
        35,
        65,
        arr[0].firstCard,
        setPendingInterviews,
        setPendingInterviewsColor,
        setPendingInterviewsTAT,
        setPendingInterviewsTATColor,
        setPendingInterviewsGraphData
      )
      getDialerGraphCall(
        35,
        65,
        arr[1].secondCard,
        setDischargesPending,
        setDischargesPendingColor,
        setdischargesPendingTAT,
        setDischargesPendingTATColor,
        setDischargesPendingGraphData
      )
      getDialerGraphCall(
        35,
        65,
        arr[2].thirdCard,
        setInterviewsPerHour,
        setInterviewsPerHourColor,
        setInterviewsPerHourTAT,
        setInterviewsPerHourTATColor,
        setInterviewsPerHourGraphData
      )

      getValuesCardCalls(
        35,
        65,
        arr[3].clearedInterviews,
        setClearedInterviews,
        setClearedInterviewsColor
      )
      getValuesCardCalls(
        35,
        65,
        arr[4].cumulativeInterviews,
        setComulativeInterviews,
        setComulativeInterviewsColor
      )
    }
  }, [responseData])

  const flipIt = () => {
    setFlip(true)
  }
  return (
    <div className='container-fluid' style={{ marginBottom: 10 }}>
      <div className='row'>
        <div
          className='col-md-4 col-6'
          style={{ paddingLeft: 4, paddingRight: 4, marginTop: 10 }}
        >
          <div>
            <Flip
              flipped={!flip}
              flipHandler={flipIt}
              mainHeading={'Pending Patients Interviews'}
              value={
                isLoading ? (
                  <Loader
                    type='TailSpin'
                    color='green'
                    height={35}
                    width={35}
                  />
                ) : (
                  pendingInterviews
                )
              }
              mainHeadingForGraph='Patient Awaiting Clearance Interviews'
              color={pendingInterviewsColor}
              subHeading={'TAT'}
              childHeading={'Assignment to Interview'}
              time={
                isLoading ? (
                  <Loader
                    type='TailSpin'
                    color='green'
                    height={35}
                    width={35}
                  />
                ) : pendingInterviewsTAT === null ? (
                  0
                ) : (
                  pendingInterviewsTAT
                )
              }
              timecolor={pendingInterviewsTATColor}
              idForGraph={'container1'}
              data={pendingInterviewsGraphData}
            />
          </div>
        </div>

        <div
          className='col-md-4 col-6'
          style={{ paddingLeft: 4, paddingRight: 4, marginTop: 10 }}
        >
          <div>
            <Flip
              flipped={!flip}
              flipHandler={flipIt}
              mainHeading={'Patient Discharges/Dispositions Pending'}
              value={
                isLoading ? (
                  <Loader
                    type='TailSpin'
                    color='green'
                    height={35}
                    width={35}
                  />
                ) : (
                  dischargesPending
                )
              }
              mainHeadingForGraph='Patient Discharges/Dispositions Pending'
              color={dischargesPendingColor}
              subHeading={'TAT'}
              childHeading={'Decision to Discharge/Disposition'}
              time={
                isLoading ? (
                  <Loader
                    type='TailSpin'
                    color='green'
                    height={35}
                    width={35}
                  />
                ) : dischargesPendingTAT === null ? (
                  0
                ) : (
                  dischargesPendingTAT
                )
              }
              timecolor={dischargesPendingTATColor}
              idForGraph={'container2'}
              data={dischargesPendingGraphData}
            />
          </div>
        </div>

        <div
          className='col-md-4 col-6'
          style={{ paddingLeft: 4, paddingRight: 4, marginTop: 10 }}
        >
          <div>
            <Flip
              flipped={!flip}
              flipHandler={flipIt}
              mainHeading={'No. of Interviews/Hour'}
              value={
                isLoading ? (
                  <Loader
                    type='TailSpin'
                    color='green'
                    height={35}
                    width={35}
                  />
                ) : (
                  interviewsPerHour
                )
              }
              mainHeadingForGraph='No. of Interviews/Hour'
              color={interviewsPerHourColor}
              subHeading={'TAT'}
              childHeading={'Per Interview'}
              time={
                isLoading ? (
                  <Loader
                    type='TailSpin'
                    color='green'
                    height={35}
                    width={35}
                  />
                ) : interviewsPerHourTAT === null ? (
                  0
                ) : (
                  interviewsPerHourTAT
                )
              }
              timecolor={interviewsPerHourTATColor}
              idForGraph={'container3'}
              data={interviewsPerHourGraphData}
            />
          </div>
        </div>
        {/* </div> */}

        <div
          className='col-md-4 col-6'
          style={{ paddingLeft: 4, paddingRight: 4, marginTop: 10 }}
        >
          <div>
            <OneValue
              time={
                isLoading ? (
                  <Loader
                    type='TailSpin'
                    color='green'
                    height={35}
                    width={35}
                  />
                ) : (
                  35
                )
              }
              colorTime={'#13D59F'}
              heading={'Disputes Opened'}
            />
          </div>
        </div>

        <div
          className='col-md-4 col-6'
          style={{ paddingLeft: 4, paddingRight: 4, marginTop: 10 }}
        >
          <div>
            <OneValue
              time={
                isLoading ? (
                  <Loader
                    type='TailSpin'
                    color='green'
                    height={35}
                    width={35}
                  />
                ) : (
                  clearedInterviews
                )
              }
              colorTime={clearedInterviewsColor}
              heading={'Cleared Interviews'}
            />
          </div>
        </div>
        <div
          className='col-md-4 col-6'
          style={{ paddingLeft: 4, paddingRight: 4, marginTop: 10 }}
        >
          <div>
            <OneValue
              time={
                isLoading ? (
                  <Loader
                    type='TailSpin'
                    color='green'
                    height={35}
                    width={35}
                  />
                ) : (
                  comulativeInterviews
                )
              }
              colorTime={comulativeInterviewsColor}
              heading={'Cumulative number of Interviews'}
            />
          </div>
        </div>
      </div>

      <Notification
        msg={errorMsg}
        open={openNotification}
        success={successMsg}
      />
    </div>
  )
}
