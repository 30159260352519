import React, { useState, useEffect, useReducer } from "react";
import Notification from "../../components/Snackbar/Notification.js";
import CustomTable from "../../components/Table/Table";
import _ from "lodash";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import {
  enableLabService,
  disableLabService,
  disableRadService,
  enableRadService,
} from "../../public/endpoins";
import Back from "../../assets/img/Back_Arrow.png";
import Header from "../../components/Header/Header";
import Lab_Tests from "../../assets/img/Lab Tests Management.png";
import Rad_Tests from "../../assets/img/Rad Tests Management.png";
import ButtonField from "../../components/common/Button";
import CurrencyTextField from "@unicef/material-ui-currency-textfield";
import Fingerprint from "../../assets/img/fingerprint.png";
import AccountCircle from "@material-ui/icons/SearchOutlined";
import InputAdornment from "@material-ui/core/InputAdornment";
import BarCode from "../../assets/img/Bar Code.png";
import TextField from "@material-ui/core/TextField";
import useStyles from "../../assets/jss/material-dashboard-react/greyInputStyle";
import useStylesForSearchInput from "../../assets/jss/material-dashboard-react/inputStyle";
import styles from "./styles";
import "../../assets/jss/material-dashboard-react/components/loaderStyle.css";
import cookie from "react-cookies";

import EnableDisableModal from "../../components/EnableDisableModal/enableDisableModal";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import {
  getAllLabTest,
  getAllRadTest,
  LabTestsSearch,
  RadTestsSearch,
  LabTestsSubmit,
  RadTestsSubmit,
  LabTestEdit,
  RadTestEdit,
  ConfirmEnableDisable,
} from "./NetworkCallForLabRadTests";

const tableHeading = [
  "Lab Test ID",
  "Lab Test Name",
  "Test Type",
  "Price (JD)",
  "Date/Time",
  "Action",
];
const tableDataKeys = ["ID", "name", "category", "price", "createdAt"];

const tableHeadingForRad = [
  "Rad Test ID",
  "Rad Test Name",
  "Test Type",
  "Price (JD)",
  "Date/Time",
  "Action",
];
const tableDataKeysForRad = ["ID", "name", "type", "price", "createdAt"];

const actions = { edit: true, enableDisable: true };

export default function LabRadTests(props) {
  const classes = useStyles();
  const classesForSearchInput = useStylesForSearchInput();
  const matches = useMediaQuery("(min-width:600px)");

  const [labTests, setLabTests] = useState("");
  const [radTests, setRadTests] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [openNotification, setOpenNotification] = useState(false);
  const [searchLabTests, SetSearchLabTests] = useState("");
  const [searchRadTests, setSearchRadTests] = useState("");
  const [open, setOpen] = useState(false);
  const [detailsForm, setDetailsForm] = useState(false);
  const [labTestId, setLabTestId] = useState("");
  const [radTestId, setRadTestId] = useState("");
  const [openEnableDisabledModal, setOpenEnableDisabledModal] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState("");
  const [currentUser, setCurrentUser] = useState(cookie.load("current_user"));
  const [pathname, setPathname] = useState("");

  const initialState = {
    name: "",
    type: "",
    price: "",
    status: "Pending",
    staffId: currentUser._id,
    reason: "",
  };
  const [state, dispatch] = useReducer(reducer, initialState);

  function reducer(state, { field, value }) {
    return {
      ...state,
      [field]: value,
    };
  }

  const { name, type, price, status, staffId, reason } = state;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    dispatch({ field: "name", value: "" });
    dispatch({ field: "price", value: "" });
    dispatch({ field: "type", value: "" });
    setLabTestId("");
    setRadTestId("");
    setOpen(false);
  };

  if (openNotification) {
    setTimeout(() => {
      setOpenNotification(false);
      setErrorMsg("");
    }, 2000);
  }

  useEffect(() => {
    if (props.location.pathname === "/dashboard/home/management/labtests") {
      getAllLabData();
    } else {
      getAllRadData();
    }
    setPathname(props.location.pathname);
  }, []);

  function getAllLabData() {
    getAllLabTest(setLabTests, setOpenNotification, setErrorMsg);
  }

  function getAllRadData() {
    getAllRadTest(setRadTests, setOpenNotification, setErrorMsg);
  }

  function UpdateLabTests(rec) {
    setLabTestId(rec._id);
    setOpen(true);
    if (rec) {
      Object.entries(rec).map(([key, val]) => {
        dispatch({ field: key, value: val });
      });
    }
  }

  function UpdateRadTests(rec) {
    setRadTestId(rec._id);
    setOpen(true);
    if (rec) {
      Object.entries(rec).map(([key, val]) => {
        dispatch({ field: key, value: val });
      });
    }
  }

  const handleLabTestsSearch = (e) => {
    LabTestsSearch(e, SetSearchLabTests, setLabTests, getAllLabData);
  };

  const handleRadTestsSearch = (e) => {
    RadTestsSearch(e, setSearchRadTests, setRadTests, getAllRadData);
  };

  const onChangeValue = (e) => {
    if (e.target.name === "price") {
      dispatch({
        field: e.target.name,
        value: e.target.value.replace(/,/g, ""),
      });
    } else {
      dispatch({ field: e.target.name, value: e.target.value });
    }
  };

  function validateDetails() {
    return (
      name &&
      name.length > 0 &&
      type &&
      type.length > 0 &&
      price &&
      price !== ""
    );
  }

  const handleLabTestsSubmit = () => {
    if (validateDetails()) {
      let params = {
        name,
        type,
        price,
      };
      LabTestsSubmit(params, setOpen, setOpenNotification, setErrorMsg);
    } else {
      setOpenNotification(true);
      setErrorMsg("Please Fill the the empty fields with data");
    }
    setDetailsForm(true);
  };

  const handleRadTestsSubmit = () => {
    if (validateDetails()) {
      let params = {
        name,
        type,
        price,
      };
      RadTestsSubmit(params, setOpen, setOpenNotification, setErrorMsg);
    } else {
      setOpenNotification(true);
      setErrorMsg("Please Fill the the empty fields with data");
    }
    setDetailsForm(true);
  };

  const handleLabTestEdit = () => {
    if (validateDetails() && reason.length > 0) {
      let params = {
        _id: labTestId,
        name,
        type,
        price,
        staffId,
        reason,
      };
      LabTestEdit(params, setOpen, setOpenNotification, setErrorMsg);
    } else {
      setOpenNotification(true);
      setErrorMsg("Please Fill the the empty fields with valid data");
    }
    setDetailsForm(true);
  };

  const handleRadTestEdit = () => {
    if (validateDetails() && reason.length > 0) {
      let params = {
        _id: radTestId,
        name,
        type,
        price,
        staffId,
        reason,
      };
      RadTestEdit(params, setOpen, setOpenNotification, setErrorMsg);
    } else {
      setOpenNotification(true);
      setErrorMsg("Please Fill the the empty fields with valid data");
    }
    setDetailsForm(true);
  };

  function enableDisableRecord(rec) {
    setOpenEnableDisabledModal(true);
    setSelectedRecord(rec);
  }

  function handleConfirmDisable(reason) {
    let obj = {
      reason: reason,
      staffId: staffId,
    };

    if (pathname === "/dashboard/home/management/labtests") {
      var url = !selectedRecord.disabled ? disableLabService : enableLabService;
    } else {
      var url = !selectedRecord.disabled ? disableRadService : enableRadService;
    }
    ConfirmEnableDisable(
      url,
      obj,
      setOpenEnableDisabledModal,
      selectedRecord,
      setSelectedRecord,
      setErrorMsg,
      setOpenNotification
    );
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        position: "fixed",
        width: "100%",
        height: "100%",
        backgroundColor: "rgb(19 213 159)",
        overflowY: "scroll",
      }}
    >
      <Header history={props.history} />

      <div className="cPadding">
        <div className="subheader">
          <div style={{}}>
            <img
              src={
                pathname === "/dashboard/home/management/labtests"
                  ? Lab_Tests
                  : Rad_Tests
              }
            />
            <h4>
              {pathname === "/dashboard/home/management/labtests"
                ? "Lab Tests Management"
                : "Radiology Tests Management"}
            </h4>
          </div>
          <div style={{}}>
            <ButtonField onClick={handleClickOpen} name="add" />
          </div>
        </div>

        <div
          className={`${"container-fluid"} ${classes.root}`}
          style={{
            marginTop: "25px",
            paddingLeft: "10px",
            paddingRight: "10px",
          }}
        >
          <div className="row">
            <div
              className="col-md-10 col-sm-9 col-8"
              style={{
                ...styles.textFieldPadding,
                paddingRight: matches ? 5 : 0,
              }}
            >
              <TextField
                className="textInputStyle"
                id="searchLabTests"
                type="text"
                variant="filled"
                label={
                  pathname === "/dashboard/home/management/labtests"
                    ? "Search Lab Test by Name / Price"
                    : "Search Rad Test by Name / Price"
                }
                name={
                  pathname === "/dashboard/home/management/labtests"
                    ? "searchLabTests"
                    : "searchRadTests"
                }
                value={
                  pathname === "/dashboard/home/management/labtests"
                    ? searchLabTests
                    : searchRadTests
                }
                onChange={
                  pathname === "/dashboard/home/management/labtests"
                    ? handleLabTestsSearch
                    : handleRadTestsSearch
                }
                InputLabelProps={{
                  classes: {
                    root: classesForSearchInput.label,
                    focused: classesForSearchInput.focusedLabel,
                    error: classesForSearchInput.erroredLabel,
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <AccountCircle />
                    </InputAdornment>
                  ),
                  className: classesForSearchInput.input,
                  classes: { input: classesForSearchInput.input },
                  disableUnderline: true,
                }}
              />
            </div>

            <div
              className="col-md-1 col-sm-2 col-2"
              style={{
                ...styles.textFieldPadding,
                paddingRight: matches ? 5 : 3,
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "white",
                  borderRadius: 5,
                  height: 55,
                }}
              >
                <img
                  src={BarCode}
                  style={{
                    width: matches ? 70 : 52,
                    height: matches ? 60 : 58,
                  }}
                />
              </div>
            </div>

            <div
              className="col-md-1 col-sm-1 col-2"
              style={{
                ...styles.textFieldPadding,
                paddingLeft: matches ? 5 : 2,
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "white",
                  borderRadius: 5,
                  height: 55,
                }}
              >
                <img src={Fingerprint} style={{ maxWidth: 43, height: 43 }} />
              </div>
            </div>
          </div>
        </div>

        <div
          style={{
            flex: 4,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div>
            {labTests !== " " &&
            pathname === "/dashboard/home/management/labtests" ? (
              <div>
                <div>
                  <CustomTable
                    tableData={labTests}
                    tableDataKeys={tableDataKeys}
                    tableHeading={tableHeading}
                    action={actions}
                    handleEdit={UpdateLabTests}
                    borderBottomColor={"#60d69f"}
                    borderBottomWidth={20}
                    enableDisableRecord={enableDisableRecord}
                  />
                </div>
                <div style={{ marginTop: 20, marginBottom: 20 }}>
                  <img
                    onClick={() => props.history.goBack()}
                    src={Back}
                    style={{
                      width: 45,
                      height: 35,
                      cursor: "pointer",
                    }}
                  />
                </div>
                <Notification msg={errorMsg} open={openNotification} />
              </div>
            ) : radTests !== " " &&
              pathname === "/dashboard/home/management/radtests" ? (
              <div>
                <div>
                  <CustomTable
                    tableData={radTests}
                    tableDataKeys={tableDataKeysForRad}
                    tableHeading={tableHeadingForRad}
                    action={actions}
                    handleEdit={UpdateRadTests}
                    borderBottomColor={"#60d69f"}
                    borderBottomWidth={20}
                    enableDisableRecord={enableDisableRecord}
                  />
                </div>
                <div style={{ marginTop: 20, marginBottom: 20 }}>
                  <img
                    onClick={() => props.history.goBack()}
                    src={Back}
                    style={{
                      width: 45,
                      height: 35,
                      cursor: "pointer",
                    }}
                  />
                </div>
                <Notification msg={errorMsg} open={openNotification} />
              </div>
            ) : (
              <div className="row " style={{ marginTop: "25px" }}>
                <div className="col-11">
                  <h3
                    style={{
                      color: "white",
                      textAlign: "center",
                      width: "100%",
                      position: "absolute",
                    }}
                  >
                    Opps...No Data Found
                  </h3>
                </div>
                <div className="col-1" style={{ marginTop: 45 }}>
                  <img
                    onClick={() => props.history.goBack()}
                    src={Back}
                    style={{
                      maxWidth: "60%",
                      height: "auto",
                      cursor: "pointer",
                    }}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth={"md"}
        >
          <DialogContent>
            <div
              className={`row ${classes.root}`}
              style={{
                marginTop: "15px",
                marginRight: "-20px",
                marginLeft: "-20px",
              }}
            >
              <div
                className="col-md-12 col-sm-12"
                style={{
                  ...styles.inputContainerForTextField,
                  ...styles.textFieldPadding,
                  paddingLeft: 20,
                  paddingRight: 20,
                }}
              >
                <TextField
                  required
                  label="Test Name"
                  name={"name"}
                  value={name}
                  onChange={onChangeValue}
                  error={name === "" && detailsForm}
                  className="textInputStyle"
                  variant="filled"
                  InputProps={{
                    className: classes.input,
                    classes: { input: classes.input },
                  }}
                  inputProps={{ maxLength: 40 }}
                />
              </div>
              <div
                className="col-md-12 col-sm-12"
                style={{
                  ...styles.inputContainerForTextField,
                  ...styles.textFieldPadding,
                  marginTop: "20px",
                  paddingLeft: 20,
                  paddingRight: 20,
                }}
              >
                <TextField
                  required
                  label="Test Type"
                  name={"type"}
                  value={type}
                  onChange={onChangeValue}
                  error={type === "" && detailsForm}
                  className="textInputStyle"
                  variant="filled"
                  InputProps={{
                    className: classes.input,
                    classes: { input: classes.input },
                  }}
                  inputProps={{ maxLength: 40 }}
                />
              </div>
              <div
                className="col-md-12 col-sm-12"
                style={{
                  ...styles.inputContainerForTextField,
                  ...styles.textFieldPadding,
                  marginTop: "20px",
                  paddingLeft: 20,
                  paddingRight: 20,
                }}
              >
                <CurrencyTextField
                  required
                  label="Test Price"
                  name={"price"}
                  value={price}
                  onBlur={onChangeValue}
                  error={price === "" && detailsForm}
                  className="textInputStyle"
                  variant="filled"
                  textAlign="left"
                  InputProps={{
                    className: classes.input,
                    classes: { input: classes.input },
                  }}
                  currencySymbol="JD"
                  outputFormat="number"
                  decimalPlaces="4"
                />
              </div>
              {labTestId || radTestId ? (
                <div
                  className="col-md-12 col-sm-12"
                  style={{
                    ...styles.inputContainerForTextField,
                    ...styles.textFieldPadding,
                    marginTop: "20px",
                    paddingLeft: 20,
                    paddingRight: 20,
                  }}
                >
                  <TextField
                    required
                    fullWidth
                    name="reason"
                    label="Reason"
                    value={reason}
                    onChange={onChangeValue}
                    error={reason === "" && detailsForm}
                    variant="filled"
                    className="textInputStyle"
                    InputProps={{
                      className: classes.input,
                      classes: { input: classes.input },
                    }}
                  />
                </div>
              ) : (
                undefined
              )}
            </div>
          </DialogContent>
          <DialogActions style={{ justifyContent: "center" }}>
            <Button
              onClick={handleClose}
              color="primary"
              style={styles.stylesForButton}
            >
              Cancel
            </Button>
            {pathname === "/dashboard/home/management/labtests" ? (
              <Button
                onClick={labTestId ? handleLabTestEdit : handleLabTestsSubmit}
                color="primary"
                style={styles.stylesSubmitButton}
              >
                {labTestId ? "Update" : "Submit"}
              </Button>
            ) : (
              <Button
                onClick={radTestId ? handleRadTestEdit : handleRadTestsSubmit}
                color="primary"
                style={styles.stylesSubmitButton}
              >
                {radTestId ? "Update" : "Submit"}
              </Button>
            )}
          </DialogActions>
        </Dialog>
      </div>

      {openEnableDisabledModal && selectedRecord ? (
        <EnableDisableModal
          handleClose={setOpenEnableDisabledModal}
          handleConfirmDisable={handleConfirmDisable}
          disabled={selectedRecord.disabled}
        />
      ) : (
        undefined
      )}
    </div>
  );
}
