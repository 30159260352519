import React, { useState, useEffect, useReducer } from 'react'
import Notification from '../../components/Snackbar/Notification.js'
import CustomTable from '../../components/Table/Table'
import axios from 'axios'
import _ from 'lodash'
import {
  addChiefComplaint,
  getAllChiefComplaints,
  disableChiefComplaint,
  enableChiefComplaint,
  getSingleChiefComplaint,
} from '../../public/endpoins'
import Loader from 'react-loader-spinner'
import Back from '../../assets/img/Back_Arrow.png'
import Header from '../../components/Header/Header'
import chiefCompIcon from '../../assets/img/Chief Complaints.png'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core/styles'
import '../../assets/jss/material-dashboard-react/components/loaderStyle.css'
import Button from '@material-ui/core/Button'
import plus_icon from '../../assets/img/Plus.png'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import cookie from 'react-cookies'
import AccountCircle from '@material-ui/icons/SearchOutlined'
import InputAdornment from '@material-ui/core/InputAdornment'
import EnableDisableModal from '../../components/EnableDisableModal/enableDisableModal'
import ButtonField from '../../components/common/Button'
import useMediaQuery from '@material-ui/core/useMediaQuery'

const tableHeading = [
  'Chief-Complaints ID',
  'Chief-Complaints',
  'Date/Time',
  'Action',
]
const tableDataKeys = ['chiefComplaintId', 'name', 'updatedAt']

const styles = {
  textFieldPadding: {
    paddingLeft: 5,
    paddingRight: 5,
  },
  stylesForButton: {
    color: 'white',
    cursor: 'pointer',
    borderRadius: 5,
    background: '#2c6ddd',
    width: '140px',
    height: '50px',
    outline: 'none',
  },
  stylesForCancelButton: {
    color: 'gray',
    cursor: 'pointer',
    borderRadius: 5,
    width: '140px',
    height: '50px',
    background: 'transparent',
    border: '1px solid gray',
  },
}

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(0),
  },
  input: {
    backgroundColor: 'white',
    boxShadow: 'none',
    borderRadius: 5,
    '&:after': {
      borderBottomColor: 'black',
      boxShadow: 'none',
    },
    '&:hover': {
      backgroundColor: 'white',
      boxShadow: 'none',
    },
    '&:focus': {
      backgroundColor: 'white',
      boxShadow: 'none',
      borderRadius: 5,
    },
  },
  multilineColor: {
    boxShadow: 'none',
    backgroundColor: 'white',
    borderRadius: 5,
    '&:hover': {
      backgroundColor: 'white',
      boxShadow: 'none',
    },
    '&:after': {
      borderBottomColor: 'black',
      boxShadow: 'none',
    },
    '&:focus': {
      boxShadow: 'none',
    },
  },
  root: {
    '& .MuiTextField-root': {
      backgroundColor: 'white',
    },
    '& .Mui-focused': {
      backgroundColor: 'white',
      color: 'black',
      boxShadow: 'none',
    },
    '& .Mui-disabled': {
      backgroundColor: 'white',
      color: 'gray',
    },
    '&:focus': {
      backgroundColor: 'white',
      boxShadow: 'none',
    },
    '& .MuiFormLabel-root': {
      fontSize: '12px',
      padding: '2px',
    },
  },
}))

const actions = { enableDisable: true }

export default function Categories(props) {
  const matches = useMediaQuery('(min-width:600px)')
  const initialState = {
    ccName: '',
    reason: '',
  }

  function reducer(state, { field, value }) {
    return {
      ...state,
      [field]: value,
    }
  }

  const [state, dispatch] = useReducer(reducer, initialState)

  const { ccName, reason } = state

  const onChangeValue = (e) => {
    dispatch({ field: e.target.name, value: e.target.value })
  }

  const classes = useStyles()
  const [currentUser, setcurrentUser] = useState('')
  const [ccData, setccData] = useState('')
  const [errorMsg, setErrorMsg] = useState('')
  const [successMsg, setsuccessMsg] = useState('')
  const [openNotification, setOpenNotification] = useState(false)
  const [modalVisible, setModalVisible] = useState(false)
  const [openEnableDisabledModal, setOpenEnableDisabledModal] = useState(false)
  const [chiefComplaint, setChiefComplaint] = useState('')
  const [searchCategory, setSearchCategory] = useState('')

  if (openNotification) {
    setTimeout(() => {
      setOpenNotification(false)
      setErrorMsg('')
      setsuccessMsg('')
    }, 2000)
  }

  useEffect(() => {
    setcurrentUser(cookie.load('current_user'))
    getCCData()
  }, [])

  function getCCData() {
    axios
      .get(getAllChiefComplaints)
      .then((res) => {
        if (res.data.success) {
          console.log(res.data.data.docs, 'get CC Data')
          setccData(res.data.data.docs.reverse())
        } else if (!res.data.success) {
          setErrorMsg(res.data.error)
          setOpenNotification(true)
        }
        return res
      })
      .catch((e) => {
        console.log('error: ', e)
      })
  }

  const handleCategorySearch = (e) => {
    const a = e.target.value.replace(/[^\w\s]/gi, '')
    setSearchCategory(a)
    if (a.length >= 3) {
      axios
        .get(getSingleChiefComplaint + '/' + a)
        .then((res) => {
          if (res.data.success) {
            if (res.data.data.length > 0) {
              console.log('results of search ', res.data.data)
              setccData(res.data.data.reverse())
            } else {
              setccData([])
            }
          }
        })
        .catch((e) => {
          console.log('error after searching category', e)
        })
    } else if (a.length === 0) {
      console.log('less')
      getCCData()
    }
  }

  function enableDisableRecord(chiefComplaint) {
    setOpenEnableDisabledModal(true)
    setChiefComplaint(chiefComplaint)
  }

  const addNewCC = () => {
    setModalVisible(true)
  }

  function validateCC() {
    return ccName && ccName.length > 0
  }

  const handleAddNewCC = () => {
    let params = {
      name: ccName,
    }
    if (params.name) {
      axios
        .post(addChiefComplaint, params)
        .then((res) => {
          if (res.data.success) {
            setModalVisible(false)
            setOpenNotification(true)
            setsuccessMsg('Category Added Successfully')
            getCCData()
          } else if (!res.data.success) {
            setModalVisible(false)
            setOpenNotification(true)
            setErrorMsg('Cannot Add Category')
          }
        })
        .catch((e) => {
          console.log('Error after adding Category', e)
          setOpenNotification(true)
          setErrorMsg('Error while adding the Category')
        })
    } else {
      setOpenNotification(true)
      setErrorMsg('Add Name or Category')
    }
  }

  const handleDisableCC = () => {
    const params = {
      staffId: currentUser._id,
      reason: reason,
    }
    let url = !chiefComplaint.disabled
      ? disableChiefComplaint
      : enableChiefComplaint
    axios
      .put(url + '/' + chiefComplaint._id, params)
      .then((res) => {
        if (res.data.success) {
          setOpenNotification(true)
          setsuccessMsg(
            `Category ${
              !chiefComplaint.disabled ? 'disabled' : 'enabled'
            } successfully`
          )
          getCCData()
        } else if (!res.data.success) {
          setOpenNotification(true)
          setErrorMsg(res.data.data)
        }
      })
      .catch((e) => {
        console.log('error after updating Category', e)
        setOpenNotification(true)
        setErrorMsg('Error while updating the Category')
      })
    setOpenEnableDisabledModal(false)
  }

  const onModalHide = () => {
    dispatch({ field: 'ccName', value: '' })
    dispatch({ field: 'reason', value: '' })
    setModalVisible(false)
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        position: 'fixed',
        width: '100%',
        height: '100%',
        backgroundColor: 'rgb(19 213 159)',
        overflowY: 'scroll',
      }}
    >
      <Header history={props.history} />

      <div className='cPadding'>
        <div className='subheader'>
          <div>
            <img src={chiefCompIcon} />
            <h4>Chief-Complaints Categories</h4>
          </div>
          {/* <Button
            onClick={addNewCC}
            style={styles.stylesForButton}
            variant="contained"
            color="primary"
          >
            <img src={plus_icon} className="icon-style" />
            &nbsp;&nbsp;
            <strong>Add New</strong>
          </Button> */}
          <Button
            onClick={addNewCC}
            style={{
              ...styles.stylesForButton,
              width: matches ? 140 : 100,
              height: matches ? 50 : 40,
              fontSize: matches ? ' ' : 9,
            }}
            variant='contained'
            color='primary'
          >
            <img src={plus_icon} className='icon-style' />
            &nbsp;&nbsp;
            <strong>Add New</strong>
          </Button>
          {/* <ButtonField onClick={addNewCC} name="add" /> */}
        </div>

        <div className={` ${classes.root}`} style={{ marginTop: '25px' }}>
          <div className='row' style={{ marginTop: '20px' }}>
            <div className='col-md-12 col-sm-12 col-12'>
              <TextField
                className='textInputStyle'
                id='searchCategory'
                type='text'
                variant='filled'
                label='Search a Category'
                name={'searchCategory'}
                value={searchCategory}
                onChange={handleCategorySearch}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <AccountCircle />
                    </InputAdornment>
                  ),
                  className: classes.input,
                  classes: { input: classes.input },
                  disableUnderline: true,
                }}
              />
            </div>
          </div>
        </div>

        <div
          className=''
          style={{
            flex: 4,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {ccData && ccData.length > 0 ? (
            <div>
              <div>
                <CustomTable
                  tableData={ccData}
                  tableDataKeys={tableDataKeys}
                  tableHeading={tableHeading}
                  action={actions}
                  enableDisableRecord={enableDisableRecord}
                  borderBottomColor={'#60d69f'}
                  borderBottomWidth={20}
                />
              </div>
              <div style={{ marginTop: 20, marginBottom: 20 }}>
                <img
                  onClick={() => props.history.goBack()}
                  src={Back}
                  style={{
                    width: 45,
                    height: 35,
                    cursor: 'pointer',
                  }}
                />
              </div>
            </div>
          ) : ccData && ccData.length == 0 ? (
            <div className='row ' style={{ marginTop: '25px' }}>
              <div className='col-md-12 col-sm-12 col-12'>
                <h3
                  style={{
                    color: 'white',
                    textAlign: 'center',
                    width: '100%',
                    position: 'absolute',
                  }}
                >
                  Oops! No Data Found
                </h3>
              </div>
              <div
                className='col-md-12 col-sm-12 col-12'
                style={{ marginTop: 45 }}
              >
                <img
                  onClick={() => props.history.goBack()}
                  src={Back}
                  style={{ maxWidth: '60%', height: 'auto', cursor: 'pointer' }}
                />
              </div>
            </div>
          ) : (
            <>
              <div className='row'>
                <div className='LoaderStyle'>
                  <Loader type='TailSpin' color='red' height={50} width={50} />
                </div>
              </div>
              <div className='row'>
                <div
                  className='col-md-12 col-sm-12 col-12'
                  style={{ marginTop: 45 }}
                >
                  <img
                    onClick={() => props.history.goBack()}
                    src={Back}
                    style={{
                      maxWidth: '60%',
                      height: 'auto',
                      cursor: 'pointer',
                    }}
                  />
                </div>
              </div>
            </>
          )}
        </div>

        <Dialog
          onClose={() => setModalVisible(false)}
          fullWidth={true}
          maxWidth={'lg'}
          bodyStyle={{ backgroundColor: 'red' }}
          contentStyle={{ backgroundColor: 'red' }}
          aria-labelledby='simple-dialog-title'
          open={modalVisible}
        >
          <DialogContent>
            <div className='container-fluid'>
              <div className='row'>
                <div className='d-flex justify-content-start'>
                  <DialogTitle
                    id='simple-dialog-title'
                    style={{ marginLeft: matches ? '-12px' : -28 }}
                  >
                    <h4 style={{ fontSize: matches ? ' ' : 20 }}>
                      Add New Chief Complaint
                    </h4>
                  </DialogTitle>
                </div>
              </div>

              <div className={`row ${classes.root}`}>
                <div className='col-md-12' style={styles.textFieldPadding}>
                  <TextField
                    label='Chief-Complaint Name / Category'
                    name={'ccName'}
                    value={ccName}
                    onChange={onChangeValue}
                    className='textInputStyle'
                    variant='filled'
                    InputProps={{
                      className: classes.input,
                      classes: { input: classes.input },
                      disableUnderline: true,
                    }}
                  />
                </div>
              </div>

              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div
                  style={{
                    display: 'flex',
                    marginTop: matches ? '2%' : '10%',
                    marginBottom: '2%',
                  }}
                >
                  <Button
                    style={{
                      ...styles.stylesForCancelButton,
                      width: matches ? 140 : 100,
                      height: matches ? 50 : 40,
                    }}
                    onClick={() => onModalHide()}
                    variant='contained'
                  >
                    Cancel
                  </Button>
                  &nbsp;&nbsp;
                  <Button
                    style={{
                      ...styles.stylesForButton,
                      width: matches ? 140 : 100,
                      height: matches ? 50 : 40,
                    }}
                    onClick={handleAddNewCC}
                    variant='contained'
                  >
                    Submit
                  </Button>
                </div>
              </div>
            </div>
          </DialogContent>

          {/* <Notification
            msg={errorMsg}
            success={successMsg}
            open={openNotification}
          /> */}
        </Dialog>

        {openEnableDisabledModal && chiefComplaint ? (
          <EnableDisableModal
            handleClose={setOpenEnableDisabledModal}
            handleConfirmDisable={handleDisableCC}
            disabled={chiefComplaint.disabled}
          />
        ) : (
          undefined
        )}

        <Notification
          msg={errorMsg}
          success={successMsg}
          open={openNotification}
        />
      </div>
    </div>
  )
}
