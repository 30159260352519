import React from "react"
import ViewContainerCS from "../../../components/CareStream/ViewContainerCS"
import { viewMedicationsStyles } from "../styles"

export default function Assign(props) {
  const classes = viewMedicationsStyles()

  return (
    <div className={classes.root}>
      <div className="subheader" style={{ marginLeft: "-10px" }}>
        <div style={{ flex: 4, display: "flex", alignItems: "center" }}>
          <h4 style={{ color: "white", fontWeight: "700" }}>Medications</h4>
        </div>
      </div>

      <ViewContainerCS
        heading="Medication"
        buttonHeading="Medication"
        arrayContainerCS={props.medicationsArray}
      />
    </div>
  )
}
