export const performanceForStep1 = {
  heading: 'How were you admitted to our hospital?',
  options: [
    'Through the emergency department 1',
    'Through the emergency department 2',
    'Through the emergency department 3',
  ],
}

export const performanceForStep2 = {
  heading: 'Please rate your experience in our ER department on the following:',
  options: [
    'Waiting time',
    'Clarity about treatment and plan of care',
    'Communication from ER doctors',
    'Courtesy and respect of ER doctors',
    'Communication from ER nurses',
    'Courtesy and respect of ER nurses',
  ],
}

export const performanceForStep3 = {
  heading:
    'Please rate your experience with our Customer Care ambassador on the following:',
  options: [
    'Courtesy and respect of Customer Care ambassador',
    'Clarity of Communication',
    'Promptness to assist',
  ],
}

export const performanceForStep4 = {
  heading: 'Please rate your experience with the admission on the following:',
  options: [
    'Courtesy and respect of our staff',
    'Communication from our staff',
    'Speed and efficiency of admission process',
  ],
}

export const performanceForStep5 = {
  heading:
    'Please rate your experience with out medical team during your stay on the following:',
  options: [
    'Care provided',
    'Attentiveness of our staff',
    'Clarity of communication',
    'Courtesy and respect of Doctors',
    'Courtesy and respect of the nurses',
  ],
}

export const performanceForStep6 = {
  heading:
    'Please rate your experience in the room during your stay on the following:',
  options: [
    'Room facilities',
    'Room comfort',
    'Room cleanliness',
    'Noise levels at night',
  ],
}

export const performanceForStep7 = {
  heading: 'Please rate out nutrition on the following:',
  options: [
    'Quality',
    'Fitting to your specific requirements',
    'Food temperture',
  ],
}

export const performanceForStep8 = {
  heading: 'Please rate out phone communications on the following:',
  options: [
    'Clarity of information provided Over the phone',
    'Response time',
    'Waiting time',
    'Courtesy and respect of Opertor staff',
  ],
}

export const performanceForStep9 = {
  heading: 'Please rate the planning for your discharge:',
  options: [
    'Preparation planning',
    'Your involvement in the discharge process',
    'Clarity and timeliness of information',
    'Communication about expections after discharge',
  ],
}

export const performanceForStep10 = {
  heading: 'What is your overall opinion of your experience compared to:',
  options: ['Your expectations'],
}

export const performanceForStep11 = {
  heading: 'Would you recommend our hospital to others?',
  options: [
    'Definitely Yes',
    'Yes',
    'Probably Yes',
    'Probably Not',
    'Definitely Not',
  ],
}

export const performanceForStep12 = {
  heading:
    'Are there any additional comments that you would like to share with us?',
  options: ['textField'],
}
