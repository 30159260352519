import axios from "axios";
import { reportsRDdDashboard } from "../../../public/endpoins";

export function getReportsRadDoctor(
  setTotalPending,
  setTotalPendingColor,
  setPendingTat,
  setPendingTATColor,
  setGraphPending,
  set2ndTotalPending,
  set2ndTotalPendingColor,
  set2ndPendingTat,
  set2ndPendingTATColor,
  set2ndGraphPending,
  setCumulativeNotes,
  setCumulativeNotesColor
) {
  // currentUser
  axios.get(reportsRDdDashboard).then((res) => {
    if (res.data.success) {
      console.log("Clinical Data", res.data);
      if (
        res.data.firstCard.totalPending >= 0 &&
        res.data.firstCard.totalPending <= 3
      ) {
        setTotalPending(res.data.firstCard.totalPending);
        setTotalPendingColor("#13D59F");
      } else if (
        res.data.firstCard.totalPending >= 4 &&
        res.data.firstCard.totalPending <= 6
      ) {
        setTotalPending(res.data.firstCard.totalPending);
        setTotalPendingColor("#FFBC28");
      } else if (res.data.firstCard.totalPending >= 7) {
        setTotalPending(res.data.firstCard.totalPending);
        setTotalPendingColor("#FF0000");
      } else {
        setTotalPending(res.data.firstCard.totalPending);
      }
      //setTotalPending(res.data.firstCard.totalPending)
      //setTotalPendingColor("#FF0000")
      if (res.data.firstCard.TAT >= 0 && res.data.firstCard.TAT <= 60) {
        setPendingTat(res.data.firstCard.TAT);
        setPendingTATColor("#13D59F");
      } else if (
        res.data.firstCard.TAT >= 61 &&
        res.data.firstCard.TAT <= 180
      ) {
        setPendingTat(res.data.firstCard.TAT);
        setPendingTATColor("#FFBC28");
      } else if (res.data.firstCard.TAT >= 181) {
        setPendingTat(res.data.firstCard.TAT);
        setPendingTATColor("#FF0000");
      } else {
        setPendingTat(res.data.firstCard.TAT);
      }
      // //setPendingTat(res.data.firstCard.TAT);
      // //setPendingTATColor()
      setGraphPending(res.data.firstCard.perHour);

      // // For 3nd Card
      // // Order per Hour

      if (
        res.data.secondCard.totalPending >= 0 &&
        res.data.secondCard.totalPending <= 5
      ) {
        set2ndTotalPending(res.data.secondCard.totalPending);
        set2ndTotalPendingColor("#13D59F");
      } else if (
        res.data.secondCard.totalPending >= 6 &&
        res.data.secondCard.totalPending <= 8
      ) {
        set2ndTotalPending(res.data.secondCard.totalPending);
        set2ndTotalPendingColor("#FFBC28");
      } else if (res.data.secondCard.totalPending >= 9) {
        set2ndTotalPending(res.data.secondCard.totalPending);
        set2ndTotalPendingColor("#FF0000");
      } else {
        set2ndTotalPending(res.data.secondCard.totalPending);
      }
      //setTotalPending(res.data.secondCard.totalPending)
      //setTotalPendingColor("#FF0000")
      if (res.data.secondCard.TAT >= 0 && res.data.secondCard.TAT <= 4) {
        set2ndPendingTat(res.data.secondCard.TAT);
        set2ndPendingTATColor("#13D59F");
      } else if (res.data.secondCard.TAT >= 5 && res.data.secondCard.TAT <= 7) {
        set2ndPendingTat(res.data.secondCard.TAT);
        set2ndPendingTATColor("#13D59F");
      } else if (res.data.secondCard.TAT >= 8) {
        set2ndPendingTat(res.data.secondCard.TAT);
        set2ndPendingTATColor("#FF0000");
      } else {
        set2ndPendingTat(res.data.secondCard.TAT);
      }

      set2ndGraphPending(res.data.secondCard.perHour);

      // // // For 4td Card
      // // //commulative  Per Hour
      if (res.data.cumulativeNotes >= 0 && res.data.cumulativeNotes <= 25) {
        setCumulativeNotes(res.data.cumulativeNotes);
        setCumulativeNotesColor("#13D59F");
      } else if (
        res.data.cumulativeNotes >= 26 &&
        res.data.cumulativeNotes <= 30
      ) {
        setCumulativeNotes(res.data.cumulativeNotes);
        setCumulativeNotesColor("#13D59F");
      } else if (res.data.cumulativeNotes >= 31) {
        setCumulativeNotes(res.data.cumulativeNotes);
        setCumulativeNotesColor("#13D59F");
      } else {
        setCumulativeNotes(res.data.cumulativeNotes);
      }
    }
  });
}
