import React, { useEffect, useState, useReducer } from "react";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import TextField from "@material-ui/core/TextField";
import {
  searchpatient,
  getSearchedpatient,
  getAllPendingAnesthesiaRequestURL,
  getAllCompletedAnesthesiaRequestURL,
} from "../../public/endpoins";
import cookie from "react-cookies";
import Header from "../../components/Header/Header";
import Back from "../../assets/img/Back_Arrow.png";
import "../../assets/jss/material-dashboard-react/components/TextInputStyle.css";
import CustomTable from "../../components/Table/Table";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Notification from "../../components/Snackbar/Notification.js";
import Fingerprint from "../../assets/img/fingerprint.png";
import AccountCircle from "@material-ui/icons/SearchOutlined";
import InputAdornment from "@material-ui/core/InputAdornment";
import BarCode from "../../assets/img/Bar Code.png";
import Loader from "react-loader-spinner";
import { connect } from "react-redux";
import {
  funForReducer,
  setPatientDetailsForReducer,
} from "../../actions/Checking";
import RadIcon from "../../assets/img/Rad.png";

import Button from "@material-ui/core/Button";
import eye_icon from "../../assets/img/Eye.png";

import useMediaQuery from "@material-ui/core/useMediaQuery";

const tableHeadingForPharmacy = [
  "MRN No",
  "Patient Name",
  "Date / Time",
  "Room No",
  "Status",
  "Action",
];
const tableDataKeysForPharmacy = [
  "patientMRN",
  "patientName",
  // ["anesthesiologistNote", "noteTime"],
  "createdAt",
  "roomNo",
  ["anesthesiologistNote", "status"],
];
const actions = { view: true };

const styles = {
  stylesForButton: {
    color: "white",
    cursor: "pointer",
    borderRadius: 5,
    backgroundColor: "#2c6ddd",
    outline: "none",
    height:45
  },
};

function ViewServicesRequests(props) {
  const initialState = {
    diagnosisArray: [],
    medicationArray: [],
    pharmacyRequestArray: [
      {
        requestNo: 321,
        createdAt: "DSA",
        generatedBy: "Dr. Mudassir",
        status: "Pending",
      },
      {
        requestNo: 321,
        createdAt: "DSA",
        generatedBy: "Dr. Mudassir",
        status: "Pending",
      },
      {
        requestNo: 321,
        createdAt: "DSA",
        generatedBy: "Dr. Mudassir",
        status: "Pending",
      },
      {
        requestNo: 321,
        createdAt: "DSA",
        generatedBy: "Dr. Mudassir",
        status: "Pending",
      },
    ],
  };

  function reducer(state, { field, value }) {
    return {
      ...state,
      [field]: value,
    };
  }

  const [state, dispatch] = useReducer(reducer, initialState);

  const { pharmacyRequestArray, diagnosisArray, medicationArray } = state;

  const matches = useMediaQuery("(min-width:600px)");

  const [currentUser] = useState(cookie.load("current_user"));
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setsuccessMsg] = useState("");
  const [openNotification, setOpenNotification] = useState(false);

  const [, setSelectedItem] = useState("");
  const [searchPatientQuery, setSearchPatientQuery] = useState("");
  const [patientFoundSuccessfull, setpatientFoundSuccessfully] = useState(
    false
  );
  const [patientFound, setpatientFound] = useState("");
  const [patientDetails, setPatientDetails] = useState("");
  const [, setSelectedPatientArray] = useState([]);
  const [, openPatientDetailsDialog] = useState(false);
  const [, setenableForm] = useState(true);
  const [, setId] = useState("");
  const [, setIsLoading] = useState(true);
  const [timer, setTimer] = useState(null);
  const [loadSearchedData, setLoadSearchedData] = useState(false);
  const [pendingRequests, setPendingRequests] = useState(false);

  const [statusName, setStatusName] = useState("Pending");

  useEffect(() => {
    if (
      props.history.location.pathname === "/dashboard/home/anesthesiarequests"
    ) {
      getPatientByInfo(getAllPendingAnesthesiaRequestURL);
    } else {
      getPatientByInfo(getAllCompletedAnesthesiaRequestURL);
      setStatusName("Completed");
    }
  }, []);

  const handleView = (rec) => {
    console.log(rec);

    props.history.push({
      pathname: "/dashboard/home/anesthesiarequests/edit",
      state: {
        selectedRecord: rec,
      },
    });
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      triggerChange();
    }
  };

  const triggerChange = (a) => {
    handlePatientSearch(a);
  };

  const handlePauseSearch = (e) => {
    setLoadSearchedData(true);
    clearTimeout(timer);

    const a = e.target.value.replace(/[^\w\s]/gi, "");
    setSearchPatientQuery(a);

    setTimer(
      setTimeout(() => {
        triggerChange(a);
      }, 600)
    );
  };

  //for search patient
  const handlePatientSearch = (e) => {
    if (e.length >= 3) {
      axios
        .get(
          getSearchedpatient + "/" + currentUser.functionalUnit._id + "/" + e
        )
        .then((res) => {
          if (res.data.success) {
            if (res.data.data.length > 0) {
              console.log(res.data.data);
              setpatientFoundSuccessfully(true);
              setpatientFound(res.data.data);
              setLoadSearchedData(false);
            } else {
              setpatientFoundSuccessfully(false);
              setpatientFound("");
              setLoadSearchedData(false);
            }
          }
        })
        .catch((e) => {
          console.log("error after searching patient request", e);
        });
    }
  };

  function handleAddPatient(i) {
    dispatch({ field: "diagnosisArray", value: "" });
    dispatch({ field: "medicationArray", value: "" });
    console.log("selected banda : ", i);

    props.setPatientDetailsForReducer(i);

    setPatientDetails(i);
    getPatientByInfo(i._id);
    openPatientDetailsDialog(true);

    const obj = {
      itemCode: i.itemCode,
    };

    setSelectedPatientArray((pervState) => [...pervState, obj]);
    setSearchPatientQuery("");
  }

  const getPatientByInfo = (url) => {
    axios
      .get(url + "/" + currentUser._id)
      .then((res) => {
        if (res.data.success) {
          if (res.data.data) {
            let response = [...res.data.data];
            response.map(
              (r) => (
                (r.patientName =
                  r.patientId.name[0].given[0] +
                  " " +
                  r.patientId.name[0].family),
                (r.patientMRN = r.patientId.identifier[0].value),
                (r.createdAt = r.anesthesiologistNote.noteTime),
                (r.roomNo = r.room[r.room.length - 1].roomId.roomNo),
                (r.chiefComplaintName =
                  r.chiefComplaint[
                    r.chiefComplaint.length - 1
                  ].chiefComplaintId.name)
              )
            );
            console.log(
              "Response after getting pending pendingRequests: ",
              response
            );

            setPendingRequests(response.reverse());

            setIsLoading(false);
            setSelectedItem(res.data.data);
            setId(res.data.data._id);
            setenableForm(false);
          }
        } else {
          setOpenNotification(true);
          setErrorMsg("EDR/IPR not generated for patient");
        }
      })
      .catch((e) => {
        setOpenNotification(true);
        setErrorMsg(e);
      });
  };

  if (openNotification) {
    setTimeout(() => {
      setOpenNotification(false);
      setErrorMsg("");
      setsuccessMsg("");
    }, 2000);
  }

  const handleChange = (e, tabValue) => {
    setStatusName(tabValue);

    if (tabValue === "Completed") {
      getPatientByInfo(getAllCompletedAnesthesiaRequestURL);
    } else {
      getPatientByInfo(getAllPendingAnesthesiaRequestURL);
    }
  };

  return (
    <div
      style={{
        backgroundColor: "rgb(19 213 159)",
        position: "fixed",
        display: "flex",
        width: "100%",
        height: "100%",
        flexDirection: "column",
        flex: 1,
        overflowY: "scroll",
      }}
    >
      <Header history={props.history} />
      <div className="cPadding">
        <div className="subheader">
          <div>
            <img src={RadIcon} />
            <h4>Anesthesiologist Requests</h4>
          </div>

          <div>
            {statusName === "Pending" ? (
              <Button
                onClick={(e) => handleChange(e, "Completed")}
                style={{ ...styles.stylesForButton }}
                variant="contained"
                color="primary"
              >
                <img src={eye_icon} className="icon-view" />
                &nbsp;&nbsp;
                <strong style={{ fontSize: matches ? " " : 10 }}>
                  View Completed
                </strong>
              </Button>
            ) : (
              <Button
                onClick={(e) => handleChange(e, "Pending")}
                style={{ ...styles.stylesForButton }}
                variant="contained"
                color="primary"
              >
                <img src={eye_icon} className="icon-view" />
                &nbsp;&nbsp;
                <strong style={{ fontSize: matches ? " " : 10 }}>
                  View Pending
                </strong>
              </Button>
            )}
          </div>
        </div>

        {pharmacyRequestArray !== 0 ? (
          <CustomTable
            tableData={pendingRequests}
            tableDataKeys={tableDataKeysForPharmacy}
            tableHeading={tableHeadingForPharmacy}
            handleView={handleView}
            action={actions}
            borderBottomColor={"#60d69f"}
            borderBottomWidth={20}
          />
        ) : (
          undefined
        )}

        <div
          className="container-fluid"
          style={{ marginBottom: "25px", marginTop: "25px" }}
        >
          <div className="row">
            <div className="col-md-12 col-sm-12 col-12">
              <img
                onClick={() => props.history.goBack()}
                src={Back}
                style={{ width: 45, height: 35, cursor: "pointer" }}
              />
            </div>
          </div>
        </div>

        <Notification
          msg={errorMsg}
          open={openNotification}
          success={successMsg}
        />
      </div>
    </div>
  );
}

const mapStateToProps = ({ CheckingReducer }) => {
  const { count, patientDetails } = CheckingReducer;
  return { count, patientDetails };
};
export default connect(mapStateToProps, {
  funForReducer,
  setPatientDetailsForReducer,
})(ViewServicesRequests);
