import React, { useState, useEffect } from 'react';
import Loader from "react-loader-spinner";
import Header from '../../components/Header/Header'
import ccIcon from '../../assets/img/Chief Complaints.png'
import CareStreamsIcon from '../../assets/img/CareStreams Management.png'
import PharmaIcon from '../../assets/img/Pharma.png'
import LabIcon from '../../assets/img/Labortary Department.png'
import RadIcon from '../../assets/img/Rad.png'
import Back from '../../assets/img/Back_Arrow.png'
import Notification from "../../components/Snackbar/Notification.js";
import CustomTable from "../../components/Table/Table";
import {
   tableHeadingForCC,
   tableDataKeysForCC,
   tableHeadingForCareStream,
   tableDataKeysForCareStream,
   tableHeadingForMostMeds,
   tableDataKeysForMostMeds,
   tableHeadingForLabTests,
   tableDataKeysForLabTests,
   tableHeadingForRadTests,
   tableDataKeysForRadTests
} from './configForTables'
import {
   MostSelectedData
} from './networkCalls'

export default function MostSelected(props) {

   const [comingFrom, setComingFrom] = useState('')
   const [errorMsg, setErrorMsg] = useState("");
   const [openNotification, setOpenNotification] = useState(false);
   const [tableDataArray, setTableDataArray] = useState([])
   const [loading, setLoading] = useState(true)

   useEffect(() => {
      let comingFrom = props.history.location.state.comingFrom
      setComingFrom(comingFrom)

      MostSelectedData(
         comingFrom,
         setTableDataArray,
         setLoading,
         setOpenNotification,
         setErrorMsg
      )
   }, [])

   return (
      <div
         style={{
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            position: 'fixed',
            width: '100%',
            height: '100%',
            backgroundColor: '#60d69f',
            overflowY: 'scroll',
         }}
      >
         <Header history={props.history} />

         <div className='cPadding'>
            <div className='subheader'>
               <div>
                  {comingFrom === "Most Selected Chief-Complaints" ?
                     <img src={ccIcon} alt="ccIcon" />
                     : comingFrom === "Most Selected CareStreams" ?
                        <img src={CareStreamsIcon} alt="ccIcon" />
                        : comingFrom === "Most Requested Medication" ?
                           <img src={PharmaIcon} alt="ccIcon" />
                           : comingFrom === "Most Requested Lab Tests" ?
                              <img src={LabIcon} alt="ccIcon" />
                              : comingFrom === "Most Requested Radiology Exams" ?
                                 <img src={RadIcon} alt="ccIcon" />
                                 : undefined
                  }
                  <h4>{comingFrom}</h4>
               </div>
            </div>

            <div className='container-fluid'>

               <div className='row'>
                  {tableDataArray.length > 0 && !loading ? (
                     <div className='col-md-12 col-sm-12 col-12'>
                        <CustomTable
                           tableData={tableDataArray}
                           tableHeading={
                              comingFrom === "Most Selected Chief-Complaints" ?
                                 tableHeadingForCC
                                 : comingFrom === "Most Selected CareStreams" ?
                                    tableHeadingForCareStream
                                    : comingFrom === "Most Requested Medication" ?
                                       tableHeadingForMostMeds
                                       : comingFrom === "Most Requested Lab Tests" ?
                                          tableHeadingForLabTests
                                          : comingFrom === "Most Requested Radiology Exams" ?
                                             tableHeadingForRadTests
                                             : []
                           }
                           tableDataKeys={
                              comingFrom === "Most Selected Chief-Complaints" ?
                                 tableDataKeysForCC
                                 : comingFrom === "Most Selected CareStreams" ?
                                    tableDataKeysForCareStream
                                    : comingFrom === "Most Requested Medication" ?
                                       tableDataKeysForMostMeds
                                       : comingFrom === "Most Requested Lab Tests" ?
                                          tableDataKeysForLabTests
                                          : comingFrom === "Most Requested Radiology Exams" ?
                                             tableDataKeysForRadTests
                                             : []
                           }
                           borderBottomColor={'#60d69f'}
                           borderBottomWidth={20}
                        />
                     </div>
                  ) : loading ? (
                     <div style={{ margin: "auto", width: "0%", padding: 10 }}>
                        <Loader
                           type="TailSpin"
                           color="blue"
                           height={50}
                           width={50}
                        />
                     </div>
                  ) : (
                           <div className='col-md-12 col-sm-12 col-12'>
                              <h3
                                 style={{
                                    color: "white",
                                    textAlign: "center",
                                    margin: 'revert'
                                 }}
                              >
                                 Opps! No Data Found
                              </h3>
                           </div>
                        )}
               </div>
               <div className='row' style={{ marginBottom: 20 }}>
                  <div className='col-md-12 col-sm-12 col-12'>
                     <img
                        onClick={() => props.history.goBack()}
                        src={Back}
                        style={{
                           width: 45,
                           height: 35,
                           cursor: 'pointer',
                        }}
                     />
                  </div>
               </div>

            </div>
            <Notification msg={errorMsg} open={openNotification} />
         </div>
      </div>
   );
}