const styles = {
  textFieldPadding: {
    paddingLeft: 5,
    paddingRight: 5,
  },
  stylesSubmitButton: {
    color: 'white',
    cursor: 'pointer',
    width: '120px',
    backgroundColor: '#2c6ddd',
    height: '40px',
    outline: 'none',
  },
  stylesForButton: {
    color: 'black',
    cursor: 'pointer',
    borderRadius: 5,
    backgroundColor: '#F5F5F5',
    width: '120px',
    height: '40px',
    outline: 'none',
  },
}

export default styles
