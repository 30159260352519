import React from "react";
import Back from "../../../assets/img/Back_Arrow.png";
import { Redirect } from "react-router-dom";
import cookie from "react-cookies";
import Header from "../../../components/Header/Header";
import MenuTree from "../../../components/MenuTree/MenuTree";
// Icons
import PharmIcon from "../../../assets/img/Pharma.png";
// ED Doc Icons
import ViewReqIcon from "../../../assets/img/View Requests- Reports.png";
import LabIcon from "../../../assets/img/RL.png";
import RadIcon from "../../../assets/img/Rad.png";
import ICRIcon from "../../../assets/img/ICR.png";
import ECRIcon from "../../../assets/img/ECR.png";

const Panel = [
  { img: ViewReqIcon, text: "View Requests / Reports" },
  {
    img: PharmIcon,
    text: "Pharma",
    path: "/dashboard/home/patientmanagement/viewrequests/pharma",
  },
  {
    img: RadIcon,
    text: "Rad",
    path: "/dashboard/home/patientmanagement/viewrequests/rad",
  },
  {
    img: LabIcon,
    text: "Lab",
    path: "/dashboard/home/patientmanagement/viewrequests/lab",
  },
  {
    img: ICRIcon,
    text: "ICR",
    path: "/dashboard/home/patientmanagement/viewrequests/icr",
  },
  {
    img: ECRIcon,
    text: "ECR",
    path: "/dashboard/home/patientmanagement/viewrequests/ecr",
  },
];

const EDdocPanel = [
  { img: ViewReqIcon, text: "View Requests / Reports" },
  {
    img: RadIcon,
    text: "Rad",
    path: "viewrequests/rad",
  },
  {
    img: LabIcon,
    text: "Lab",
    path: "viewrequests/lab",
  },
];

class ViewRequests extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      openApps: false,
      goBack: false,
      currentUser: "",
    };
  }

  componentDidMount() {
    this.setState({ currentUser: cookie.load("current_user") });
  }

  render() {
    const userType = this.state.currentUser;
    if (this.state.goBack) {
      return <Redirect to={"/management"} />;
    }

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          position: "fixed",
          width: "100%",
          height: "100%",
          background:
            "linear-gradient(25deg, rgba(101,228,193,1) 0%, rgba(58,219,175,1) 33%, rgba(15,206,147,1) 66%, rgba(6,142,103,1) 100%)",
          backgroundSize: "100%",
        }}
      >
        <Header history={this.props.history} />

        <MenuTree
          history={this.props.history}
          options={
            userType.staffType === "Doctor"
              ? userType.subType.includes("ED Doctor")
                ? EDdocPanel
                : Panel
              : Panel
          }
        />

        <div
          style={{
            position: "fixed",
            width: "100%",
            height: "10%",
            top: "90%",
          }}
        >
          <img
            alt="Back"
            onClick={() => this.props.history.goBack()}
            src={Back}
            style={{
              width: 45,
              height: 35,
              marginLeft: "5%",
              cursor: "pointer",
            }}
          />
        </div>
      </div>
    );
  }
}
export default ViewRequests;
