import { makeStyles } from "@material-ui/core/styles";

export const styles = {
  stylesForButton: {
    color: "white",
    cursor: "pointer",
    borderRadius: 5,
    backgroundColor: "#2c6ddd",
    height: "45px",
    outline: "none",
  },
};

export const useStylesForTabs = makeStyles(() => ({
  root: {
    justifyContent: "center",
  },
  scroller: {
    flexGrow: "0",
  },
}));

export const useStyles = makeStyles((theme) => ({
  margin: {
      margin: theme.spacing(0),
  },
  input: {
      backgroundColor: 'white',
      boxShadow: 'none',
      borderRadius: 5,
      '&:after': {
          borderBottomColor: 'black',
          boxShadow: 'none',
      },
      '&:hover': {
          backgroundColor: 'white',
          boxShadow: 'none',
      },
      '&:focus': {
          backgroundColor: 'white',
          boxShadow: 'none',
          borderRadius: 5,
      },
  },
  multilineColor: {
      boxShadow: 'none',
      backgroundColor: 'white',
      borderRadius: 5,
      '&:hover': {
          backgroundColor: 'white',
          boxShadow: 'none',
      },
      '&:after': {
          borderBottomColor: 'black',
          boxShadow: 'none',
      },
      '&:focus': {
          boxShadow: 'none',
      },
  },
  root: {
      '& .MuiTextField-root': {
          backgroundColor: 'white',
      },
      '& .Mui-focused': {
          backgroundColor: 'white',
          color: 'black',
          boxShadow: 'none',
      },
      '& .Mui-disabled': {
          backgroundColor: 'white',
          color: 'gray',
      },
      '&:focus': {
          backgroundColor: 'white',
          boxShadow: 'none',
      },
      '& .MuiFormLabel-root': {
          fontSize: '13px',
          padding: '0px',
      },
  },
}))