/* eslint-disable react/jsx-indent */
import React from "react";
import { Route, Switch } from "react-router-dom";
import NotFound from "../components/NotFound/NotFound";
import AssistanceMenu from "../views/Home/Assistance/assistance"
import AssistanceScreen from "../views/Assistance/assistance"

class AssistanceRoutes extends React.PureComponent {
    render() {
        return (
            <Switch>
                <Route
                    exact
                    path={`${this.props.match.url}`}
                    component={AssistanceMenu}
                />
                <Route
                    exact
                    path={`${this.props.match.url}/requests`}
                    component={AssistanceScreen}
                />
                <Route
                    exact
                    path={`${this.props.match.url}/bySensei`}
                    component={AssistanceScreen}
                />
                <Route path="*" component={NotFound} />
            </Switch>
        );
    }
}
export default AssistanceRoutes;