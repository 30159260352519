import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import Divider from '@material-ui/core/Divider'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Slide from '@material-ui/core/Slide'
import 'react-circular-progressbar/dist/styles.css'
import Graph from '../../components/Dial/Graph'

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='' ref={ref} {...props} />
})

export default function FullScreenDialog(props) {

  const classes = useStyles()
  const [open, setOpen] = useState(false)

  return (
    <div>
      <Dialog
        fullScreen
        open={props.openIt}
        onClose={() => setOpen(true)}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge='start'
              color='inherit'
              onClick={() => props.setChange(true)}
              aria-label='close'
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Divider />
        <div
          className='row'
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <div style={{ marginTop: 20 }}>
            <span style={{ fontWeight: 'bold', fontSize: 18 }}>
              {props.progressName}
            </span>
          </div>
          <Graph
            mainHeadingForGraph={props.mainHeadingForGraph}
            graphValue={props.progressValue}
            idForGraph={props.idForGraph}
            data={props.data}
          />
        </div>
      </Dialog>
    </div>
  )
}