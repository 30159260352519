import React, { useEffect, useState, useReducer, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import "../../assets/jss/material-dashboard-react/components/TextInputStyle.css";
import axios from "axios";
import { FaUpload } from "react-icons/fa";
import TextField from "@material-ui/core/TextField";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Grid } from "@material-ui/core";
import MicIcon from "@material-ui/icons/Mic";
import StopIcon from "@material-ui/icons/Stop";
import MicRecorder from "mic-recorder-to-mp3";
import Loader from "react-loader-spinner";
import eye_icon from "../../assets/img/Eye.png";
import MenuItem from "@material-ui/core/MenuItem";
import cookie from "react-cookies";
import Button from "@material-ui/core/Button";
import Header from "../../components/Header/Header";
import Back from "../../assets/img/Back_Arrow.png";
import Notification from "../../components/Snackbar/Notification.js";
import RadIcon from "../../assets/img/Rad.png";
import ImageViewer from "react-simple-image-viewer";
import { IoIosCloseCircle } from "react-icons/io";
import { connect } from "react-redux";
import formatDate from "../../utils/formatDate";
import PatientDetailsQuick from "../../components/PatientDetails/PatientDetailsQuick";
import PatientDetails2 from "../../components/PatientDetails/PatientDetailsDirect";
import "./styles.css";
import {
  uploadsUrl,
  audioURL,
  updateRadiologyRequest,
  searchCompletedRadEdr,
} from "../../public/endpoins";
import {
  funForReducer,
  setPatientDetailsForReducer,
} from "../../actions/Checking";

const Mp3Recorder = new MicRecorder({ bitRate: 128 });

const styles = {
  stylesForButton: {
    color: "white",
    cursor: "pointer",
    borderRadius: 5,
    backgroundColor: "#2c6ddd",
    height: "45px",
    outline: "none",
  },
  patientDetails: {
    backgroundColor: "white",
    borderRadius: 5,
  },
  textFieldPadding: {
    paddingTop: 10,
    paddingBottom: 10,
  },
  upload: {
    backgroundColor: "white",
    border: "0px solid #ccc",
    borderRadius: "5px",
    color: "gray",
    width: "100%",
    height: "55px",
    cursor: "pointer",
    padding: "0px 20px 0px 15px",
    display: "flex",
    justifyContent: "space-between",
  },
  headerHeading: {
    display: "flex",
    alignItems: "center",
    verticalAlign: "center",
    paddingTop: 10,
  },
  headingStyles: {
    fontWeight: "bold",
    color: "grey",
    fontSize: 12,
  },
  textStyles: {
    fontWeight: "700",
    color: "black",
    fontSize: 14,
  },
};

const useStylesForInput = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(0),
  },
  input: {
    backgroundColor: "white",
    borderRadius: 5,
    "&:after": {
      borderBottomColor: "black",
    },
    "&:hover": {
      backgroundColor: "white",
    },
    "&:disabled": {
      color: "gray",
    },
    "&:focus": {
      backgroundColor: "white",
      borderRadius: 5,
    },
  },
  multilineColor: {
    boxShadow: "none",
    backgroundColor: "white",
    borderRadius: 5,
    "&:hover": {
      backgroundColor: "white",
      boxShadow: "none",
    },
    "&:after": {
      boxShadow: "none",
    },
    "&:focus": {
      boxShadow: "none",
    },
  },
  root: {
    "& .MuiTextField-root": {
      backgroundColor: "white",
    },
    "& .Mui-focused": {
      backgroundColor: "white",
      color: "black",
    },
    "& .Mui-disabled": {
      backgroundColor: "white",
      color: "black",
    },
    "& .MuiFormLabel-root": {
      fontSize: "13px",
      paddingRight: "50px",
    },
  },
  label: {
    "&$focusedLabel": {
      color: "red",
      display: "none",
    },
  },
}));

function DetailedLabRad(props) {
  const matches = useMediaQuery("(min-width:600px)");
  const classes = useStylesForInput();

  const initialState = {
    patientName: "",
    mrn: "",
    gender: "",
    age: "",
    weight: "",
    testName: "",
    status: "",
    labRadStatus: "",
    delayedReason: "",
    requestId: "",
    requestedAt: "",
    diagnosisArray: [],
    medicationArray: [],
    isRecording: false,
    isBlocked: false,
    blobURL: "",
    fileAudio: null,
    image: [],
    holdTime: "",
    activeTime: "",
    pendingApprovalTime: "",
  };

  function reducer(state, { field, value }) {
    return {
      ...state,
      [field]: value,
    };
  }

  const [state, dispatch] = useReducer(reducer, initialState);

  const {
    patientName,
    mrn,
    gender,
    age,
    weight,
    testName,
    status,
    labRadStatus,
    delayedReason,
    requestId,
    requestedAt,
    diagnosisArray,
    medicationArray,
    isRecording,
    isBlocked,
    blobURL,
    fileAudio,
    image,
    holdTime,
    activeTime,
    pendingApprovalTime,
  } = state;

  const [currentUser] = useState(cookie.load("current_user"));
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setsuccessMsg] = useState("");
  const [openNotification, setOpenNotification] = useState(false);
  const [patientDetails, setPatientDetails] = useState("");
  const [, openPatientDetailsDialog] = useState(false);
  const [DocumentUpload, setDocumentUpload] = useState([]);
  const [imagePreview, setImagePreview] = useState([]);
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [imagesDisplay, setImagesDisplay] = useState([]);
  const [edrId, setEdrId] = useState("");
  const [radId, setRadId] = useState("");
  const [reportStatus, setReportStatus] = useState("");
  const [viewMode, setViewMode] = useState(false);

  useEffect(() => {
    console.log("props", props);
    if (
      props.history.location.state &&
      props.history.location.state.comingFrom === "notification"
    ) {
      axios
        .get(searchCompletedRadEdr + "/" + props.location.state.title.slice(15))
        .then((res) => {
          if (res.data.success) {
            console.log("rad data::", res.data.data);
            // const object = {
            //   patientName:
            //     res.data.data[0].patientId.name[0].given[0] +
            //     " " +
            //     res.data.data[0].patientId.name[0].family,
            //   mrn: res.data.data[0].patientId.identifier[0].value,
            //   age: res.data.data[0].patientId.age,
            //   gender: res.data.data[0].patientId.gender,
            //   weight: res.data.data[0].patientId.weight,
            //   // chiefComplaint:
            // };
            // setPatientDetails(object);
            if (res.data.data[0]) {
              dispatch({
                field: "testName",
                value: res.data.data[0].radRequest.name,
              });
              // dispatch({
              //   field: "labRadStatus",
              //   value: res.data.data[0].radRequest.status,
              // });
              setEdrId(res.data.data[0]._id);
              setRadId(res.data.data[0].radRequest._id);

              Object.entries(res.data.data[0]).map(([key, val]) => {
                if (key === "patientId") {
                  Object.entries(val).map(([key1, val1]) => {
                    dispatch({ field: key1, value: val1 });
                  });
                }
                if (key === "radRequest") {
                  Object.entries(val).map(([key1, val1]) => {
                    if (key1 === "status") {
                      setReportStatus(val1);
                    }
                    if (key1 === "holdTime") {
                      dispatch({
                        field: "holdTime",
                        value: val1,
                      });
                    }
                    if (key1 === "activeTime") {
                      dispatch({
                        field: "activeTime",
                        value: val1,
                      });
                    }
                    if (key1 === "pendingApprovalTime") {
                      dispatch({
                        field: "pendingApprovalTime",
                        value: val1,
                      });
                    }

                    if (key1 === "voiceNotes" && val1 && val1.length !== "") {
                      // var url;

                      // if (val1.includes('\\')) {
                      //   url = `${audioURL}/${val1.split('\\')[1]}`
                      // } else if (val1.includes('/')) {
                      //   url = `${audioURL}/${val1}`
                      // }
                      const url = `${audioURL}/${val1}`;
                      dispatch({ field: "blobURL", value: url });
                    }
                    dispatch({ field: key1, value: val1 });
                  });
                } else {
                  dispatch({ field: key, value: val });
                }
              });
            }
          } else if (!res.data.success) {
            setOpenNotification(true);
          }
        })
        .catch((e) => {
          setOpenNotification(true);
          setErrorMsg("Error while submit Lab Service request");
        });
    } else {
      if (props.patientDetails) {
        setPatientDetails(props.patientDetails);
        openPatientDetailsDialog(true);
      }

      let selectedPatient = props.history.location.state.selectedItem;
      console.log("selected details ", selectedPatient);
      console.log("FC::", props.history.location.state)
      if (currentUser.staffType === "Sensei") {
        dispatch({
          field: "testName",
          value: props.history.location.state.selectedItem.name,
        });
        dispatch({
          field: "labRadStatus",
          value: props.history.location.state.selectedItem.labRadStatus,
        });
      }

      if (props.history.location.state.comingFor === "view") {
        setViewMode(true);
      }

      if (selectedPatient) {
        setEdrId(selectedPatient._id);
        setRadId(selectedPatient.radRequest._id);

        Object.entries(selectedPatient).map(([key, val]) => {
          if (key === "patientId") {
            Object.entries(val).map(([key1, val1]) => {
              dispatch({ field: key1, value: val1 });
            });
          }
          if (key === "radRequest") {
            Object.entries(val).map(([key1, val1]) => {
              if (key1 === "status") {
                setReportStatus(val1);
              }
              if (key1 === "holdTime") {
                dispatch({
                  field: "holdTime",
                  value: val1,
                });
              }
              if (key1 === "activeTime") {
                dispatch({
                  field: "activeTime",
                  value: val1,
                });
              }
              if (key1 === "pendingApprovalTime") {
                dispatch({
                  field: "pendingApprovalTime",
                  value: val1,
                });
              }

              if (key1 === "voiceNotes" && val1 && val1.length !== "") {
                // var url;

                // if (val1.includes('\\')) {
                //   url = `${audioURL}/${val1.split('\\')[1]}`
                // } else if (val1.includes('/')) {
                //   url = `${audioURL}/${val1}`
                // }
                const url = `${audioURL}/${val1}`;
                dispatch({ field: "blobURL", value: url });
              }
              dispatch({ field: key1, value: val1 });
            });
          } else {
            dispatch({ field: key, value: val });
          }
        });
      }

      navigator.mediaDevices &&
        navigator.mediaDevices.getUserMedia(
          { audio: true },
          () => {
            console.log("Permission Granted");
            dispatch({ field: "isBlocked", value: false });
          },
          () => {
            console.log("Permission Denied");
            dispatch({ field: "isBlocked", value: true });
          }
        );
    }
  }, []);

  if (openNotification) {
    setTimeout(() => {
      setOpenNotification(false);
      setErrorMsg("");
      setsuccessMsg("");
    }, 2000);
  }

  const onChangeValue = (e) => {
    if (e.target.name === "status") {
      dispatch({ field: "delayedReason", value: "" });
    }
    dispatch({ field: e.target.name, value: e.target.value });
  };

  function toArray(fileList) {
    return Array.prototype.slice.call(fileList);
  }

  const onDocumentUpload = (event) => {
    var file = event.target.files;

    var joined = toArray(DocumentUpload).concat(toArray(file));
    setDocumentUpload(joined);

    let fileType = [];
    for (let x = 0; x < file.length; x++) {
      fileType.push(file[x].name.slice(file[x].name.length - 3));
    }

    let arr = imagePreview;
    for (let i = 0; i < file.length; i++) {
      let reader = new FileReader();
      reader.readAsDataURL(file[i]);
      reader.onload = function(event) {
        arr.push(event.target.result);
        setImagePreview([...arr]);
      };
    }
    dispatch({ field: "status", value: "pending approval" });
  };

  const onImageDelete = (index) => {
    let temp = imagePreview;
    temp.splice(index, 1);
    setImagePreview([...temp]);

    let temp1 = DocumentUpload;
    temp1.splice(index, 1);
    setDocumentUpload([...temp1]);
  };

  const openImageViewer = useCallback((index, val) => {
    setImagesDisplay(val);
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const openUploadedImageViewer = useCallback((index, val) => {
    let newImage = [];

    val.map((item, i) => {
      if (item.includes("\\")) {
        newImage.push(uploadsUrl + item.split("\\")[1]);
      } else if (item.includes("/")) {
        newImage.push(uploadsUrl + item);
      }
    });

    setImagesDisplay(newImage);
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
    setImagesDisplay([]);
  };

  const start = () => {
    if (isBlocked) {
      console.log("Permission Denied");
    } else {
      Mp3Recorder.start()
        .then(() => {
          dispatch({ field: "isRecording", value: true });
        })
        .catch((e) => console.error(e));
    }
  };

  const stop = () => {
    Mp3Recorder.stop()
      .getMp3()
      .then(([buffer, blob]) => {
        const blobURL = URL.createObjectURL(blob);
        dispatch({ field: "blobURL", value: blobURL });
        dispatch({ field: "isRecording", value: false });
        var file = new File([blob], "first", { type: "audio/mp3" });
        dispatch({ field: "fileAudio", value: file });
      })
      .catch((e) => console.log(e));
  };

  const handleAssignHousekeeping = () => {
    props.history.push({
      pathname: "assignHousekeeping",
      state: {},
    });
  };

  function validateForm() {
    if (reportStatus === "active" && status === "pending approval") {
      setErrorMsg("Please upload the reports to submit report");
      return DocumentUpload && DocumentUpload.length > 0;
    } else if (currentUser.staffType === "Doctor") {
      setErrorMsg("Please add voice note to update report");
      return fileAudio;
    } else {
      return true;
    }
  }

  function validateStatusChange() {
    if (currentUser.staffType === "Doctor") {
      if (
        (reportStatus === "pending approval" && status === "pending") ||
        status === ""
      ) {
        return true;
      } else {
        return false;
      }
    } else if (currentUser.staffType === "Imaging Technician") {
      if (reportStatus === status || status === "") {
        return true;
      } else {
        return false;
      }
    }
  }

  const handleSubmit = () => {
    let formData = new FormData();
    if (DocumentUpload && DocumentUpload.length > 0) {
      for (var x = 0; x < DocumentUpload.length; x++) {
        formData.append("Reports", DocumentUpload[x], DocumentUpload[x].name);
      }
    }
    if (fileAudio) {
      formData.append("Audio", fileAudio);
    }

    let params;
    if (currentUser.staffType === "Imaging Technician") {
      params = {
        edrId,
        radId,
        imageTechnicianId: currentUser._id,
        status,
        activeTime: status === "active" ? new Date() : activeTime,
        pendingApprovalTime: status === "pending approval" ? new Date() : "",
        holdTime: status === "hold" ? new Date() : holdTime,
        delayedReason: status === "hold" ? delayedReason : pendingApprovalTime,
        staffType: currentUser.staffType,
      };
    } else if (currentUser.staffType === "Doctor") {
      params = {
        edrId,
        radId,
        completedBy: currentUser._id,
        status,
        completeTime: status === "completed" ? new Date() : "",
        staffType: currentUser.staffType,
      };
    }
    formData.append("data", JSON.stringify(params));
    console.log("Params ", params);
    // console.log('Document ', DocumentUpload)
    // console.log('Audio ', fileAudio)

    if (validateForm()) {
      axios
        .put(updateRadiologyRequest, formData)
        .then((res) => {
          if (res.data.success) {
            console.log("response", res.data.data);
            // props.history.push({
            //   pathname: "/dashboard/home/success",
            //   state: {
            //     message: `Radiology report has been successfully submitted !!`,
            //   },
            // });
            props.history.goBack();
          } else {
            setOpenNotification(true);
            setErrorMsg("Cannot submit the request at the moment");
          }
        })
        .catch((e) => {
          setOpenNotification(true);
          setErrorMsg("Cannot submit the request at the moment");
        });
    } else {
      setOpenNotification(true);
      // setErrorMsg('Please Complete the form')
    }
  };

  return (
    <div
      style={{
        backgroundColor: "rgb(19 213 159)",
        position: "fixed",
        display: "flex",
        width: "100%",
        height: "100%",
        flexDirection: "column",
        flex: 1,
        overflowY: "scroll",
      }}
    >
      <Header history={props.history} />
      <PatientDetailsQuick
        edrId={edrId} />
      <div className="cPadding">
        <div className="subheader">
          <div>
            <img src={RadIcon} />
            <h4>Radiology Reports</h4>
          </div>
          <Button
            style={styles.stylesForButton}
            onClick={() => props.history.goBack()}
            variant="contained"
            color="default"
            className="mr-3"
          >
            <img src={eye_icon} className="icon-view" />
            &nbsp;&nbsp;
            <strong style={{ fontSize: matches ? " " : 10 }}>
              View All Requests
            </strong>
          </Button>
        </div>

        {edrId ? (
          <>
          <PatientDetails2
            edrId={edrId}
            showChiefComplaint={true}
            showTriageLevel={true}
            showCareStream={true}
            showDiagnosis={true} />
          
          </>
        ) : (
          undefined
        )}

        <div className={`container-fluid ${classes.root}`}>
          <div className="row">
            <div
              className="col-md-12 col-sm-12 col-12"
              style={{ ...styles.textFieldPadding, paddingTop: "20px" }}
            >
              <TextField
                disabled
                label="Test Name"
                id="testName"
                name={"testName"}
                value={testName}
                className="textInputStyle"
                variant="filled"
                InputProps={{
                  className: classes.input,
                  classes: { input: classes.input },
                  disableUnderline: true,
                }}
              />
            </div>
            <div
              className="col-md-6 col-sm-6 col-6"
              style={styles.textFieldPadding}
            >
              <TextField
                disabled
                label="Requested At"
                id="requestedAt"
                name={"requestedAt"}
                value={formatDate(requestedAt)}
                className="textInputStyle"
                variant="filled"
                InputProps={{
                  className: classes.input,
                  classes: { input: classes.input },
                  disableUnderline: true,
                }}
              />
            </div>
            <div
              className="col-md-6 col-sm-6 col-6"
              style={styles.textFieldPadding}
            >
              <TextField
                disabled
                label="Request Id"
                id="requestId"
                name={"requestId"}
                value={requestId}
                className="textInputStyle"
                variant="filled"
                InputProps={{
                  className: classes.input,
                  classes: { input: classes.input },
                  disableUnderline: true,
                }}
              />
            </div>
            <div
              className="col-md-12 col-sm-12 col-12"
              style={styles.textFieldPadding}
            >
              {currentUser.staffType === "Doctor" ? (
                <TextField
                  required
                  disabled={viewMode}
                  select
                  fullWidth
                  id="status"
                  name="status"
                  value={status}
                  onChange={onChangeValue}
                  label="Status"
                  variant="filled"
                  style={{ borderRadius: "3px" }}
                  InputProps={{
                    className: classes.input,
                    classes: { input: classes.input },
                    disableUnderline: true,
                  }}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>

                  <MenuItem key={"Pending"} value={"pending"}>
                    {"Pending"}
                  </MenuItem>
                  <MenuItem key={"Completed"} value={"completed"}>
                    {"Completed"}
                  </MenuItem>
                </TextField>
              ) : currentUser.staffType === "Sensei" ? (
                <TextField
                  required
                  disabled={true}
                  select
                  fullWidth
                  id="labRadStatus"
                  name="labRadStatus"
                  value={labRadStatus}
                  onChange={onChangeValue}
                  label="Status"
                  variant="filled"
                  style={{ borderRadius: "3px" }}
                  InputProps={{
                    className: classes.input,
                    classes: { input: classes.input },
                    disableUnderline: true,
                  }}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>

                  <MenuItem key={"Pending"} value={"pending"}>
                    {"Pending"}
                  </MenuItem>
                  <MenuItem key={"Completed"} value={"completed"}>
                    {"Completed"}
                  </MenuItem>
                </TextField>
              ) : (
                <TextField
                  required
                  select
                  fullWidth
                  disabled={viewMode}
                  id="status"
                  name="status"
                  value={status}
                  onChange={onChangeValue}
                  label="Status"
                  variant="filled"
                  style={{ borderRadius: "3px" }}
                  InputProps={{
                    className: classes.input,
                    classes: { input: classes.input },
                    disableUnderline: true,
                  }}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem
                    key={"Pending"}
                    disabled={
                      reportStatus === "active" || reportStatus === "hold"
                        ? true
                        : false
                    }
                    value={"pending"}
                  >
                    {"Pending"}
                  </MenuItem>
                  <MenuItem key={"Active"} value={"active"}>
                    {"Active"}
                  </MenuItem>
                  <MenuItem
                    key={"Pending Approval"}
                    disabled={
                      reportStatus === "pending" || reportStatus === "hold"
                        ? true
                        : false
                    }
                    value={"pending approval"}
                  >
                    {"Pending Approval"}
                  </MenuItem>
                  <MenuItem key={"Hold"} value={"hold"}>
                    {"Hold"}
                  </MenuItem>
                  {reportStatus === "completed" ? (
                    <MenuItem key={"Completed"} value={"completed"}>
                      {"Completed"}
                    </MenuItem>
                  ) : (
                    undefined
                  )}
                </TextField>
              )}
            </div>

            {reportStatus === "active" ? (
              <div
                className="col-md-12 col-sm-12 col-12"
                style={styles.textFieldPadding}
              >
                <Button
                  variant="contained"
                  component="label"
                  style={styles.upload}
                >
                  Upload Reports
                  <FaUpload size={18} />
                  <input
                    type="file"
                    accept=".png,.PNG,.peg,.PEG,.jpeg,.jpg,."
                    multiple
                    name="document"
                    onChange={onDocumentUpload}
                    style={{ display: "none" }}
                  />
                </Button>
              </div>
            ) : (
              undefined
            )}
          </div>

          {image && image.length > 0 ? (
            <div className="row" style={styles.textFieldPadding}>
              <div className="col-md-12 col-sm-12 col-12">
                <h4 style={{ color: "white" }}>Radiology Reports</h4>
              </div>
              <div className="col-md-12 col-sm-12 col-12 imageContainer">
                {image.map((item, index) => {
                  if (item.includes("\\")) {
                    return (
                      <div key={index}>
                        <img
                          alt=""
                          key={index}
                          onClick={() => openUploadedImageViewer(index, image)}
                          src={uploadsUrl + item.split("\\")[1]}
                          className="reportImage"
                        />
                      </div>
                    );
                  } else if (item.includes("/")) {
                    return (
                      <div key={index}>
                        <img
                          alt=""
                          key={index}
                          onClick={() => openUploadedImageViewer(index, image)}
                          src={uploadsUrl + item}
                          className="reportImage"
                        />
                      </div>
                    );
                  }
                })}
              </div>
            </div>
          ) : (
            undefined
          )}

          {imagePreview && imagePreview.length > 0 ? (
            <div className="row" style={styles.textFieldPadding}>
              <div className="col-md-12 col-sm-12 col-12">
                <h4 style={{ color: "white" }}>Radiology Reports</h4>
              </div>
              <div className="col-md-12 col-sm-12 col-12 imageContainer">
                {imagePreview.map((view, index) => {
                  return (
                    <div key={index}>
                      <img
                        alt=""
                        key={index}
                        src={view}
                        onClick={() => openImageViewer(index, imagePreview)}
                        className="reportImage"
                      />
                      <IoIosCloseCircle
                        onClick={() => onImageDelete(index)}
                        size={30}
                        className="closeIcon"
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          ) : (
            undefined
          )}

          {/*currentUser.staffType === "Doctor" ||
          reportStatus === "completed" ? (
            <>
              <div className="row" style={styles.textFieldPadding}>
                <div className="col-md-12 col-sm-12 col-12">
                  <h4 style={{ color: "white" }}>
                    {viewMode ? "Voice Note" : "Add Voice Note"}
                  </h4>
                </div>
              </div>

              <Grid
                justify="flex-start"
                container
                spacing={24}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  backgroundColor: "white",
                  borderRadius: 5,
                  height: 125,
                  paddingLeft: 10,
                }}
              >
                <div style={{ display: "contents" }}>
                  {!isRecording ? (
                    <audio
                      style={{
                        width: matches
                          ? viewMode
                            ? "100%"
                            : "95%"
                          : viewMode
                          ? "100%"
                          : "80%",
                        marginTop: 6,
                        outline: "none",
                      }}
                      src={blobURL}
                      controls="controls"
                    />
                  ) : (
                    <div
                      style={{
                        marginLeft: matches ? 110 : 18,
                        marginRight: matches ? 110 : 75,
                        width: matches ? 550 : 100,
                      }}
                    >
                      <Loader
                        type="ThreeDots"
                        color="blue"
                        height={40}
                        width={40}
                      />
                    </div>
                  )}
                </div>
                {!viewMode ? (
                  <div
                    style={{
                      marginRight: matches ? 15 : 7,
                      backgroundColor: isRecording ? "#DC004E" : "#2C6DDD",
                      height: matches ? 55 : 50,
                      borderRadius: 30,
                      width: matches ? 55 : 50,
                      paddingLeft: matches ? 10 : 8,
                    }}
                  >
                    {isRecording ? (
                      <StopIcon
                        style={{ color: "white", marginTop: matches ? 11 : 9 }}
                        fontSize="large"
                        onClick={stop}
                        disabled={!isRecording}
                        color="secondary"
                      />
                    ) : (
                      <MicIcon
                        style={{ color: "white", marginTop: matches ? 12 : 10 }}
                        fontSize="large"
                        onClick={start}
                        disabled={isRecording}
                        color="primary"
                      />
                    )}
                  </div>
                ) : (
                  undefined
                )}
              </Grid>
            </>
          ) : (
            undefined
          )*/}

          {status === "hold" ? (
            <div className="row">
              <div
                className="col-md-12 col-sm-12 col-12"
                style={{ ...styles.textFieldPadding, paddingTop: "20px" }}
              >
                <TextField
                  multiline
                  label="Reason of Delay (if any)"
                  name={"delayedReason"}
                  value={delayedReason}
                  onChange={onChangeValue}
                  rows={4}
                  className="textInputStyle"
                  variant="filled"
                  InputProps={{
                    className: classes.multilineColor,
                    classes: {
                      input: classes.multilineColor,
                    },
                    disableUnderline: true,
                  }}
                />
              </div>
            </div>
          ) : (
            undefined
          )}
        </div>

        <div
          className="container-fluid"
          style={{ marginBottom: "25px", marginTop: "5%" }}
        >
          <div className="row">
            <div className="col-md-6 col-sm-6 col-6">
              <img
                onClick={() => props.history.goBack()}
                src={Back}
                style={{ width: 45, height: 35, cursor: "pointer" }}
              />
            </div>

            {!viewMode ? (
              <div className="col-md-6 col-sm-6 col-6 d-flex justify-content-end">
                {currentUser.staffType === "Imaging Technician" ? (
                  <Button
                    style={styles.stylesForButton}
                    onClick={handleAssignHousekeeping}
                    variant="contained"
                    color="default"
                    className="mr-2"
                  >
                    Assign Housekeeping
                  </Button>
                ) : (
                  undefined
                )}
                <Button
                  disabled={validateStatusChange()}
                  style={styles.stylesForButton}
                  onClick={handleSubmit}
                  variant="contained"
                  color="default"
                >
                  {currentUser.staffType === "Imaging Technician"
                    ? "Submit"
                    : "Update"}
                </Button>
              </div>
            ) : (
              undefined
            )}
          </div>
        </div>

        {isViewerOpen && (
          <ImageViewer
            src={imagesDisplay}
            currentIndex={currentImage}
            onClose={closeImageViewer}
            backgroundStyle={{
              backgroundColor: "rgba(0,0,0,0.9)",
            }}
          />
        )}

        <Notification
          msg={errorMsg}
          open={openNotification}
          success={successMsg}
        />
      </div>
    </div>
  );
}

const mapStateToProps = ({ CheckingReducer }) => {
  const { count, patientDetails } = CheckingReducer;
  return { count, patientDetails };
};
export default connect(mapStateToProps, {
  funForReducer,
  setPatientDetailsForReducer,
})(DetailedLabRad);
