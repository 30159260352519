import React, { useEffect, useState } from "react";
import cookie from "react-cookies";
import axios from "axios";
import Button from "@material-ui/core/Button";
import KHMC_White from "../../../assets/img/KHMC Header LOGO - Grey.png";
import DownloadIcon from "../../../assets/img/Download Icon.png";
import PrintIcon from "../../../assets/img/Print Icon.png";
import Back from "../../../assets/img/Back_Arrow_black.png";
import Header from "../../../components/Header/HeaderGrey";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import DetailBlockForDCD from "../../../components/DHR/DCD/ViewScreen/detailBlockForDCD";
import DetailBlock from "../../../components/DHR/DCD/ViewScreen/detailBlock";
import formatDate from "../../../utils/formatDate";
// Icons
import PatientDetailIcon from "../../../assets/img/Patient Details Icon.png";
import PastMedicalIcon from "../../../assets/img/Past Medical History Icon.png";
import InvestigationIcon from "../../../assets/img/Investigations Icon.png";
import PhysicalExamIcon from "../../../assets/img/Physical Exam Icon.png";
import ROSIcon from "../../../assets/img/ROS Icon.png";
import ActionPlanIcon from "../../../assets/img/Action & Plan Icon.png";
import covIcon from "../../../assets/img/Course Of Visit Icon.png";
import TriageAndAssessmentIcon from "../../../assets/img/Triage & Assessment Icon.png";
import PatientDetailsQuick from "../../../components/PatientDetails/PatientDetailsQuick";
import { uploadsUrl } from "../../../public/endpoins";
import jsPDF from "jspdf";
import "jspdf-autotable";
import html2PDF from "jspdf-html2canvas";
import htmltopdf from "html2pdf.js";

const capitalizeTextString = (text) =>{
    return text.toLowerCase().charAt(0).toUpperCase()+(text.slice(1).toLowerCase())
}

function base64Img(img) {
   
   const canvas = document.createElement('canvas');
   const ctx = canvas.getContext('2d');   
   canvas.width = img.width;
   canvas.height = img.height;   
   ctx.drawImage(img, 0, 0);
   return canvas.toDataURL('image/jpeg');
}

const styles = {
  save: {
    cursor: "pointer",
    borderRadius: 5,
    backgroundColor: "#2973CF",
    width: "180px",
    height: "50px",
    outline: "none",
    color: "white",
  },
  Edit: {
    cursor: "pointer",
    borderRadius: 5,
    width: "180px",
    height: "50px",
    outline: "none",
    border: "2px solid gray",
    backgroundColor: "#FEFEFE",
  },
};

function ViewForm(props) {
  const matches = useMediaQuery("(min-width:600px)");
  const [currentUser] = useState(cookie.load("current_user"));
  const [formData, setformData] = useState([]);
  const [formName, setFormName] = useState("");
  const [selectedItem, setSelectedItem] = useState("");
  const [priorECG, setPriorECG] = useState([]);
  const [priorXray, setpriorXray] = useState([]);
  const [MedicationImages, setMedicationImages] = useState([]);
  const [SkinReport, setSkinReport] = useState([]);

  const [mFormData, setmFormData] = useState([]);
  const [isForPDF, setIsForPDF] = useState(false);
  const [isPrinting, setIsPrinting] = useState(false);



  
  const printForm = () => {
    setIsPrinting(true);
    window.setTimeout(function(){
      window.print();
      setIsPrinting(false);
    }, 1000);
  };

  const downloadTriage = async () => {

    const selectedItem = props.history.location.state.selectedItem;
    console.log('Submitting::state::', props.history.location.state.selectedItem)

    let patientName = selectedItem.patientId.name[0].given[0];

    const imgs = [...document.querySelectorAll('.reportImage')]

    let images = document.querySelectorAll('.reportImage');
    /*images.forEach(function(lazyImage) {
      const dataUrl = base64Img(lazyImage);
      document.querySelector('#'+lazyImage.id).src = dataUrl;
    });*/



    
    setIsForPDF(true);
    const data = await document.querySelector("#jstpdf");

    window.setTimeout(function(){
      htmltopdf(data, {
        margin: 0.29,
        filename: `${props.history.location.state.comingFor}-${patientName}.pdf`,
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: {
          useCORS: true,
          letterRendering: true
        },
        jsPDF: { unit: "in", format: "a4", orientation: "portrait" }
      });
      
      window.setTimeout(function(){
        setIsForPDF(false);
      }, 2000);

    }, 2000);
    /*html2PDF(data, {
        jsPDF: {
          format: 'a4',
          unit: 'px',
        },
        html2canvas: {
          imageTimeout: 15000,
          logging: true,
          useCORS: false,
        },
        allowTaint : true,
        useCORS: true,
        margin: {
          top: 10,
          right: 10,
          bottom: 10,
          left: 10,
        },
        imageType: 'image/jpeg',
        output: `${props.history.location.state.comingFor}-${patientName}.pdf`
    });*/
    // htmltopdf
    

    
  };

  useEffect(() => {
    const propsData = props.history.location.state;
    setFormName(propsData.comingFor);

    console.log("propsData:: FA", propsData.selectedItem);

    setSelectedItem(propsData.selectedItem);
    const FormData = propsData.dcdForm[0];
    console.log(currentUser, "current user");

    console.log("propsData::", propsData);
    console.log("propsDataFormData::finale::", FormData);
    let mForm = null;
    if (propsData.comingFor === "Triage & Assessment") {
      mForm = FormData.triageAssessment.length > 0?FormData.triageAssessment[ FormData.triageAssessment.length - 1 ]:{};
    }else if (propsData.comingFor === "Patient Details") {
      mForm = FormData.patientDetails.length > 0?FormData.patientDetails[ FormData.patientDetails.length - 1 ]:{};
    }else if (propsData.comingFor === "Past Medical History") {
      mForm = FormData.pastMedicalHistory.length > 0?FormData.pastMedicalHistory[ FormData.pastMedicalHistory.length - 1 ]:{};
    }else if (propsData.comingFor === "ROS") {
      mForm = FormData.ROS.length > 0?FormData.ROS[ FormData.ROS.length - 1 ]:{};
    }else if (propsData.comingFor === "Action & Plan") {
      mForm = FormData.actionPlan.length > 0?FormData.actionPlan[ FormData.actionPlan.length - 1 ]:{};
    }else if (propsData.comingFor === "Course of Visit") {
      mForm = FormData.courseOfVisit.length > 0?FormData.courseOfVisit[ FormData.courseOfVisit.length - 1 ]:{};
    }else if (propsData.comingFor === "Investigations") {
      mForm = FormData.investigation.length > 0?FormData.investigation[ FormData.investigation.length - 1 ]:{};
    }else if (propsData.comingFor === "Physical Exam") {
      mForm = FormData.physicalExam.length > 0?FormData.physicalExam[ FormData.physicalExam.length - 1 ]:{};
    }
    setmFormData(mForm);

    let arr = [],
      subArray = [];
    Object.entries(FormData).map(([key, val]) => {
      if (
        key === "triageAssessment" &&
        propsData.comingFor === "Triage & Assessment"
      ) {
        const selectedTriage = val[val.length - 1];

        arr = [
          {
            heading: "Triage History",
            columnSize: 4,
            subArray: [
              {
                subheading: "Date/Time",
                description: formatDate(selectedTriage.triageTime),
              },
              {
                subheading: "Patient Name",
                description: propsData.selectedItem.patientName,
              },
              {
                subheading: "Triage No.",
                description: selectedTriage.triageRequestNo,
              },
            ],
          },
          {
            heading: "Patient Vitals",
            columnSize: 4,
            subArray: [
              {
                subheading: "Heart Rate",
                description:
                  selectedTriage.heartRate !== "N/A"
                    ? selectedTriage.heartRate + " bpm"
                    : "",
              },
              {
                subheading: "Blood Pressure Systolic",
                description:
                  selectedTriage.bloodPressureSys !== "N/A"
                    ? selectedTriage.bloodPressureSys + " mmHg"
                    : "",
              },
              {
                subheading: "Blood Pressure Diastolic",
                description:
                  selectedTriage.bloodPressureDia !== "N/A"
                    ? selectedTriage.bloodPressureDia + " mmHg"
                    : "",
              },
              {
                subheading: "Respiratory Rate",
                description:
                  selectedTriage.respiratoryRate !== "N/A"
                    ? selectedTriage.respiratoryRate + " /min"
                    : "",
              },
              {
                subheading: "Temperature",
                description:
                  selectedTriage.temperature !== "N/A"
                    ? selectedTriage.temperature + " °C"
                    : "",
              },
              {
                subheading: "Blood Sugar Level",
                description:
                  selectedTriage.FSBS !== "N/A"
                    ? selectedTriage.FSBS + " mg/dL"
                    : "",
              },
              {
                subheading: "Pain Scale",
                description:
                  selectedTriage.painScale !== "N/A"
                    ? selectedTriage.painScale
                    : "",
              },
              {
                subheading: "Pulse OX",
                description:
                  selectedTriage.pulseOX !== "N/A"
                    ? selectedTriage.pulseOX + " SpO2"
                    : "",
              },
            ],
          },
          {
            heading: "Physical Examination",
            columnSize: 1,
            subArray: [
              {
                subheading: "General Appearance",
                description: selectedTriage.generalAppearance.toString(),
              },
              {
                subheading: "Head and Neck",
                description: selectedTriage.headNeck.toString(),
              },
              {
                subheading: "Respiratory",
                description: selectedTriage.respiratory.toString(),
              },
              {
                subheading: "Cardiac",
                description: selectedTriage.cardiac.toString(),
              },
              {
                subheading: "Abdomen",
                description: selectedTriage.abdomen.toString(),
              },
              {
                subheading: "Neurological",
                description: selectedTriage.neurological.toString(),
              },
            ],
          },
          {
            heading: "Triage Level",
            columnSize: 1,
            subArray: [
              {
                subheading: "Triage Level",
                description: selectedTriage.triageLevel.toString(),
              },
            ],
          },
        ];
      } else if (
        key === "patientDetails" &&
        propsData.comingFor === "Patient Details"
      ) {

        subArray = [
          {
            subheading: "Time Seen",
            description: new Date().getHours() + ":" + new Date().getMinutes(),
          },
          {
            subheading: "Room",
            description:
              propsData.selectedItem.room &&
              propsData.selectedItem.room.length > 0
                ? propsData.selectedItem.room[
                    propsData.selectedItem.room.length - 1
                  ].roomId.roomNo
                : "Not Selected",
          },
          {
            subheading: "Age",
            description: propsData.selectedItem.patientId.age,
          },
          {
            subheading: "Gender",
            description: propsData.selectedItem.patientId.gender,
          },
          {
            subheading: "Nationality",
            description: propsData.selectedItem.patientId.nationality,
          },
          /*
          {
            subheading: "Chief Complaint",
            description: 
              propsData.selectedItem.chiefComplaint &&
              propsData.selectedItem.chiefComplaint.length > 0
                ? propsData.selectedItem.chiefComplaint[
                    propsData.selectedItem.chiefComplaint.length - 1
                  ].chiefComplaintId.name
                : "Not Selected",
          },
          */
        ];
        arr = [
            ...arr,
            {
              heading: "Patient Details",
              columnSize: 4,
              subArray: subArray
            },
          ];
        subArray = [];

        console.log('ViewFormHistory::val::', val);

        // if (val.length > 0) {
        const details = val[val.length - 1].details;

        for (let i = 0; i < details.length; i++) {
          let chips = details[i].chips;
          let value = details[i].value;
          let texts = details[i].Texts;
          let Dropdown = details[i].DropDowns;
          let allergiesValues = details[i].values;
          let allergiesOtherDetails = details[i].detail;


          if(details[i].name == "Severity"){
            subArray = [
              ...subArray,
              {
                subheading: details[i].name,
                description:
                  value <= 4
                    ? "Mild (" + value + ")"
                    : value <= 7
                    ? "Moderate (" + value + ")"
                    : "Severe (" + value + ")",
              },
            ];
          }else if(details[i].name == "Duration / Started"){

            Object.entries(Dropdown).map(([v, vv]) => {
              subArray = [
                ...subArray,
                {
                  subheading: vv.name,
                  description: vv.value
                },
              ];
            })
          }else{
            if (value){
              subArray = [
                ...subArray,
                {
                  subheading: details[i].name,
                  description: value,
                },
              ];
            }
            console.log("detailsI", details[i]);
            for (let j = 0; j < chips.length; j++) {
              let subChips = chips[j].subChips ? chips[j].subChips : [];
              if (subChips.length > 0) {
                for (let k = 0; k < subChips.length; k++) {
                  // if (subChips[k].selected === true) {
                  subArray = [
                    ...subArray,
                    {
                      subheading: chips[j].name,
                      description: chips[j].detail === "" ? " " : chips[j].detail,
                      subheadingType: subChips[k].name,
                    },
                  ];
                  // }
                }
              } else {
                console.log("chips[j]: ", chips[j]);
                subArray = [
                  ...subArray,
                  {
                    subheading:
                      chips[j].name +
                      `${
                        chips[j].selectedMedicines &&
                        chips[j].selectedMedicines.length
                          ? "(" + chips[j].selectedMedicines + ")"
                          : ""
                      }`,
                    description: chips[j].detail === "" ? " " : chips[j].detail,
                  },
                ];
              }

              if (chips[j].audio !== ""){
                subArray = [
                  ...subArray,
                  {
                    subheading: " ", //chip[j].name,
                    audio: (uploadsUrl.replace('uploads/', '')) + chips[j].audio
                  },
                ];
              }
            }
            for (let a = 0; a < texts.length; a++) {
              subArray = [
                ...subArray,
                {
                  subheading: details[i].name,
                  description: texts[a].value,
                },
              ];
            }
            for (let a = 0; a < allergiesValues.length; a++) {
              subArray = [
                ...subArray,
                {
                  subheading: details[i].name,
                  // description: allergiesValues[a].code + ": " + allergiesValues[a].name,
                  description: allergiesValues[a].name,
                },
              ];
            }

            if (details[i].voiceNotes) {
              if (details[i].voiceNotes.audio){
                subArray = [
                  ...subArray,
                  {
                    subheading: "",
                    audio: (uploadsUrl.replace('uploads/', '')) + details[i].voiceNotes.audio,
                  },
                ];
              }
              if (details[i].voiceNotes.notes){
                subArray = [
                  ...subArray,
                  {
                    subheading: "Additional Notes",
                    description: details[i].voiceNotes.notes,
                  },
                ];
              }
            }
          }
          /*if (allergiesOtherDetails.length > 0){
            subArray = [
              ...subArray,
              {
                subheading: details[i].name + " details",
                description: allergiesOtherDetails,
              },
            ];
          }*/
          arr = [
            ...arr,
            {
              heading: details[i].name,
              columnSize: 1,
              subArray:
                subArray.length > 0
                  ? subArray
                  : (subArray = [
                      {
                        subheading: "Section Not Filled Properly !",
                        description: " ",
                      },
                    ]),
            },
          ];
          subArray = [];
        }
        // }
        
      } else if (
        key === "pastMedicalHistory" &&
        propsData.comingFor === "Past Medical History"
      ) {
        // if (val.length > 0) {
        const details = val[val.length - 1].details;

        for (let i = 0; i < details.length; i++) {
          let chips = details[i].chips;
          let texts = details[i].Texts;
          let allergiesValues = details[i].values;
          let allergiesOtherDetails = details[i].detail;


          console.log("detailsI", details[i]);
          for (let j = 0; j < chips.length; j++) {
            let subChips = chips[j].subChips;
            if (subChips.length > 0) {
              for (let k = 0; k < subChips.length; k++) {
                if (subChips[k].selected === true) {
                subArray = [
                  ...subArray,
                  {
                    subheading: chips[j].name,
                    description: chips[j].detail === "" ? " " : chips[j].detail,
                    subheadingType: subChips[k].name,
                  },
                ];
                }
              }
            } else {
              console.log("chips[j]: ", chips[j]);
              if (chips[j].name !== "Add Medication Images" || chips[j].name !== "Add Skin Image"){
                subArray = [
                  ...subArray,
                  {
                    subheading:
                      chips[j].name +
                      `${
                        chips[j].selectedMedicines &&
                        chips[j].selectedMedicines.length
                          ? "(" + chips[j].selectedMedicines + ")"
                          : ""
                      }`,
                    description: chips[j].detail === "" ? " " : chips[j].detail,
                  },
                ];
              }
            }

            if (
                chips[j].name === "Add ECG Report" 
                || chips[j].name === "Add CXR Report"
                || chips[j].name === "Add Medication Images"
                || chips[j].name === "Add Skin Image"
              ) {
                if (chips[j].name === "Add ECG Report") {
                  setPriorECG(chips[j].image);
                } else if (chips[j].name === "Add CXR Report") {
                  setpriorXray(chips[j].image);
                } else if (chips[j].name === "Add Medication Images") {
                  setMedicationImages(chips[j].image);
                } else if (chips[j].name === "Add Skin Image") {
                  setSkinReport(chips[j].image);
                }
                console.log('Submitting::', chips[j].image);
                subArray = [
                  ...subArray,
                  {
                    imageName: "",
                    images: chips[j].image,
                  },
                ];
              }


            if (chips[j].audio !== ""){
              subArray = [
                ...subArray,
                {
                  subheading: " ", //chip[j].name,
                  audio: (uploadsUrl.replace('uploads/', '')) + chips[j].audio
                },
              ];
            }
          }
          for (let a = 0; a < texts.length; a++) {
            subArray = [
              ...subArray,
              {
                subheading: details[i].name,
                description: texts[a].value,
              },
            ];
          }
          for (let a = 0; a < allergiesValues.length; a++) {
            subArray = [
              ...subArray,
              {
                subheading: details[i].name,
                // description: allergiesValues[a].code + ": " + allergiesValues[a].name,
                description: allergiesValues[a].name,
              },
            ];
          }

          if (details[i].voiceNotes) {
            if (details[i].voiceNotes.audio){
              subArray = [
                ...subArray,
                {
                  subheading: "",
                  audio: (uploadsUrl.replace('uploads/', '')) + details[i].voiceNotes.audio,
                },
              ];
            }
            if (details[i].voiceNotes.notes){
              subArray = [
                ...subArray,
                {
                  subheading: "Additional Notes",
                  description: details[i].voiceNotes.notes,
                },
              ];
            }
          }
          /*if (allergiesOtherDetails.length > 0){
            subArray = [
              ...subArray,
              {
                subheading: details[i].name + " details",
                description: allergiesOtherDetails,
              },
            ];
          }*/
          arr = [
            ...arr,
            {
              heading: details[i].name,
              columnSize: 1,
              subArray:
                subArray.length > 0
                  ? subArray
                  : (subArray = [
                      {
                        subheading: "Section Not Filled Properly !",
                        description: " ",
                      },
                    ]),
            },
          ];
          subArray = [];
        }
        // }
      } else if (key === "ROS" && propsData.comingFor === "ROS") {
        const details = val[val.length - 1].details;

        for (let i = 0; i < details.length; i++) {
          let chip = details[i].chips;
          for (let j = 0; j < chip.length; j++) {
            console.log("chip: ", chip);
            if (
              chip[j].name === "Add Skin Image"
            ) {
              if (chip[j].name === "Add Skin Image") {
                setSkinReport(chip[j].image);
              }
              subArray = [
                ...subArray,
                {
                  images: chip[j].image,
                },
              ];
            }else{
              subArray = [
                ...subArray,
                {
                  subheading: chip[j].name,
                  description: " ",
                  details:
                    (chip[j].detail !== "" && chip[j].detail) ||
                    (chip[j].dropdownSelectedValue &&
                    chip[j].dropdownSelectedValue.length
                      ? chip[j].dropdownSelectedValue[0].name
                      : ""),
                },
              ];
            }

            if (chip[j].audio !== ""){
              subArray = [
                ...subArray,
                {
                  subheading: " ", //chip[j].name,
                  audio: (uploadsUrl.replace('uploads/', '')) + chip[j].audio
                },
              ];
            }
          }
          if (details[i].value){
            subArray = [
              ...subArray,
              {
                subheading: details[i].value,
                description: " ",
                details: ''
              },
            ];
          }

          let texts = details[i].Texts;
          for (let a = 0; a < texts.length; a++) {
            console.log("chip2::", texts);
            subArray = [
              ...subArray,
              {
                subheading: details[i].name,
                description: " ",
                details: texts[a].value
              },
            ];
          }

          console.log("chip2::", details[i]);

          arr = [
            ...arr,
            {
              heading: details[i].name,
              columnSize: 1,
              subArray: subArray,
            },
          ];
          subArray = [];
        }
      } else if (
        key === "actionPlan" &&
        propsData.comingFor === "Action & Plan"
      ) {
        const details = val[val.length - 1].details;
        console.log('detailsSX', details);

        // Differential Diagnosis
        subArray = [...subArray, { subheading: 'Action', description: capitalizeTextString(details.differentialDiagnosisRemarks) }];
        if (details.differentialDiagnosisVoiceNotes){
          subArray = [...subArray, { subheading: '', audio: (uploadsUrl.replace('uploads/', '')) + details.differentialDiagnosisVoiceNotes }];
        }
        subArray = [...subArray, { subheading: 'Plan', description: capitalizeTextString(details.differentialDiagnosisPlanRemarks) }];
        if (details.differentialDiagnosisPlanVoiceNotes){
          subArray = [...subArray, { subheading: '', audio: (uploadsUrl.replace('uploads/', '')) + details.differentialDiagnosisPlanVoiceNotes }];
        }
        arr = [ ...arr, { heading: 'Notes/Differential Diagnosis', columnSize: 1, subArray: subArray } ];
        subArray = [];


        // O2 Management
        subArray = [...subArray, { subheading: 'Status', description: capitalizeTextString(details.o2Check) }];
        subArray = [...subArray, { subheading: 'Action:', description: details.o2Remarks }];
        if ( details.o2VoiceNotes ){
          subArray = [...subArray, { subheading: '', audio: (uploadsUrl.replace('uploads/', '')) + details.o2VoiceNotes }];
        }
        subArray = [...subArray, { subheading: 'Plan:', description: details.o2PlanRemarks }];
        if ( details.o2PlanVoiceNotes ){
          subArray = [...subArray, { subheading: '', audio: (uploadsUrl.replace('uploads/', '')) + details.o2PlanVoiceNotes }];
        }
        arr = [ ...arr, { heading: 'O2', columnSize: 1, subArray: subArray } ];
        subArray = [];


        // IV Fluid Management
        subArray = [...subArray, { subheading: 'Status', description: capitalizeTextString(details.ivFluidsCheck) }];
        subArray = [...subArray, { subheading: 'Action', description: details.ivFluidsRemarks }];
        if ( details.ivFluidsVoiceNotes ){
          subArray = [...subArray, { subheading: '', audio: (uploadsUrl.replace('uploads/', '')) + details.ivFluidsVoiceNotes }];
        }
        subArray = [...subArray, { subheading: 'Plan', description: details.ivFluidsPlanRemarks }];
        if ( details.ivFluidsPlanVoiceNotes ){
          subArray = [...subArray, { subheading: '', audio: (uploadsUrl.replace('uploads/', '')) + details.ivFluidsPlanVoiceNotes }];
        }
        arr = [ ...arr, { heading: 'IV Fluids', columnSize: 1, subArray: subArray } ];
        subArray = [];


        // Medication Management
        subArray = [...subArray, { subheading: 'Status', description: capitalizeTextString(details.medicationsCheck) }];
        subArray = [...subArray, { subheading: 'Action', description: details.medicationsRemarks }];
        if ( details.medicationsVoiceNotes ){
          subArray = [...subArray, { subheading: '', audio: (uploadsUrl.replace('uploads/', '')) + details.medicationsVoiceNotes }];
        }
        subArray = [...subArray, { subheading: 'Plan', description: details.medicationsPlanRemarks }];
        if ( details.medicationsPlanVoiceNotes ){
          subArray = [...subArray, { subheading: '', audio: (uploadsUrl.replace('uploads/', '')) + details.medicationsPlanVoiceNotes }];
        }
        arr = [ ...arr, { heading: 'Medications', columnSize: 1, subArray: subArray } ];
        subArray = [];


        // Start Pain Management
        subArray = [...subArray, { subheading: 'Status', description: capitalizeTextString(details.painManagementCheck) }];
        subArray = [...subArray, { subheading: 'Action', description: details.painManagementRemarks }];
        if ( details.painManagementVoiceNotes ){
          subArray = [...subArray, { subheading: '', audio: (uploadsUrl.replace('uploads/', '')) + details.painManagementVoiceNotes }];
        }
        subArray = [...subArray, { subheading: 'Plan', description: details.painManagementPlanRemarks }];
        if ( details.painManagementPlanVoiceNotes ){
          subArray = [...subArray, { subheading: '', audio: (uploadsUrl.replace('uploads/', '')) + details.painManagementPlanVoiceNotes }];
        }
        arr = [ ...arr, { heading: 'Start Pain Management', columnSize: 1, subArray: subArray } ];
        subArray = [];


        // Investigation
        subArray = [...subArray, { subheading: 'Status', description: capitalizeTextString(details.investigationsCheck) }];
        subArray = [...subArray, { subheading: 'Action', description: details.investigationsRemarks }];
        if ( details.investigationsVoiceNotes ){
          subArray = [...subArray, { subheading: '', audio: (uploadsUrl.replace('uploads/', '')) + details.investigationsVoiceNotes }];
        }
        subArray = [...subArray, { subheading: 'Plan', description: details.investigationsRemarks }];
        if ( details.investigationsPlanVoiceNotes ){
          subArray = [...subArray, { subheading: '', audio: (uploadsUrl.replace('uploads/', '')) + details.investigationsPlanVoiceNotes }];
        }
        arr = [ ...arr, { heading: 'Investigation', columnSize: 1, subArray: subArray } ];
        subArray = [];

        /*
        subArray = [...subArray, { subheading: 'IV Fluids', description: 'details' }];
        subArray = [...subArray, { subheading: 'IV Fluids Remarks', description: 'details' }];
        subArray = [...subArray, { subheading: 'Audio Notes', audio: "sss" }];

        subArray = [...subArray, { subheading: 'Medications', description: 'details' }];
        subArray = [...subArray, { subheading: 'Medications Remarks', description: 'details' }];
        subArray = [...subArray, { subheading: 'Audio Notes', audio: "sss" }];

        subArray = [...subArray, { subheading: 'Start Pain Management', description: details }];
        subArray = [...subArray, { subheading: 'Start Pain Management Remarks', description: details }];
        subArray = [...subArray, { subheading: 'Audio Notes', audio: "sss" }];

        subArray = [...subArray, { subheading: 'Investigations', description: details }];
        subArray = [...subArray, { subheading: 'Investigations Remarks', description: details }];
        subArray = [...subArray, { subheading: 'Audio Notes', audio: "sss" }];
        */
        subArray = [...subArray, { subheading: 'Stable', description: capitalizeTextString(details.cpStable) }];
        subArray = [...subArray, { subheading: 'Comfortable', description: capitalizeTextString(details.cpComfortable) }];
        subArray = [...subArray, { subheading: 'Not In Distress', description: capitalizeTextString(details.cpNotInDistress) }];
        subArray = [...subArray, { subheading: 'Respondend Well to Management', description: capitalizeTextString(details.cpRespondendWellManagement) }];
        subArray = [...subArray, { subheading: 'Questions and concerns were answered and explained', description: capitalizeTextString(details.cpQuestionConcerned) }];
        subArray = [...subArray, { subheading: 'Remarks', description: capitalizeTextString(details.cpRemarks) }];
        if ( details.cpVoiceNotes ){
          subArray = [...subArray, { subheading: '', audio: (uploadsUrl.replace('uploads/', '')) + details.cpVoiceNotes }];
        }

        arr = [ ...arr, { heading: 'Condition of Patient', columnSize: 1, subArray: subArray } ];
        subArray = [];






        /*
        for (let i = 0; i < details.length; i++) {
          let chip = details[i].chips;
          for (let j = 0; j < chip.length; j++) {
            subArray = [
              ...subArray,
              {
                subheading: chip[j].name,
                description:
                  chip[j].detail && chip[j].detail.length > 0
                    ? chip[j].detail
                    : " ",
              },
            ];
          }
          arr = [
            ...arr,
            {
              heading: details[i].name,
              columnSize: 1,
              subArray: subArray,
            },
          ];
          subArray = [];
        }
        */
      } else if (
        key === "courseOfVisit" &&
        propsData.comingFor === "Course of Visit"
      ) {
        const details = val[val.length - 1].details;
        console.log('detailsSX', details);

        if (details.cpStable){
          subArray = [...subArray, { subheading: 'Stable', description: capitalizeTextString(details.cpStable) }];
        }
        if (details.cpComfortable){
          subArray = [...subArray, { subheading: 'Comfortable', description: capitalizeTextString(details.cpComfortable) }];
        }
        if (details.cpNotInDistress){
          subArray = [...subArray, { subheading: 'Not In Distress', description: capitalizeTextString(details.cpNotInDistress) }];
        }
        if (details.cpRespondendWellManagement){
          subArray = [...subArray, { subheading: 'Respondend Well to Management', description: capitalizeTextString(details.cpRespondendWellManagement) }];
        }
        if (details.cpQuestionConcerned){
          subArray = [...subArray, { subheading: 'Questions and concerns were answered and explained', description: capitalizeTextString(details.cpQuestionConcerned) }];
        }
        if (details.cpRemarks){
          subArray = [...subArray, { subheading: 'Remarks', description: capitalizeTextString(details.cpRemarks) }];
        }
        if (details.cpVoiceNotes){
          subArray = [...subArray, { subheading: '', audio: (uploadsUrl.replace('uploads/', '')) + details.cpVoiceNotes }];
        }
        arr = [ ...arr, { heading: 'Condition of Patient', columnSize: 1, subArray: subArray } ];
        subArray = [];




        if (details.dischargeDisposition){
          subArray = [...subArray, { subheading: 'Discharge/Disposition', description: (details.dischargeDisposition) }];
        }
        if (details.dischargeDispositionRemarks){
          subArray = [...subArray, { subheading: 'Remarks', description: capitalizeTextString(details.dischargeDispositionRemarks) }];
        }
        if (details.dischargeDispositionVoiceNotes){
          subArray = [...subArray, { subheading: '', audio: (uploadsUrl.replace('uploads/', '')) + details.dischargeDispositionVoiceNotes }];
        }
        arr = [ ...arr, { heading: 'Discharge/Disposition', columnSize: 1, subArray: subArray } ];
        subArray = [];




        if (details.consultationServiceRemarks){
          subArray = [...subArray, { subheading: 'Service/Speciality Remarks', description: (details.consultationServiceRemarks) }];
        }
        if (details.consultationServiceVoiceNotes){
          subArray = [...subArray, { subheading: '', audio: (uploadsUrl.replace('uploads/', '')) + details.consultationServiceVoiceNotes }];
        }
        if (details.consultationDecisionCheck){
          subArray = [...subArray, { subheading: 'Decision', description: (details.consultationDecisionCheck) }];
        }
        if (details.consultationFinalDiagnosisRemarks){
          subArray = [...subArray, { subheading: 'Final Diagnosis Remarks', description: (details.consultationFinalDiagnosisRemarks) }];
        }
        if (details.consultationFinalDiagnosisVoiceNotes){
          subArray = [...subArray, { subheading: '', audio: (uploadsUrl.replace('uploads/', '')) + details.consultationFinalDiagnosisVoiceNotes }];
        }
        if (details.medicationsRemarks){
          subArray = [...subArray, { subheading: 'Medications Remarks', description: (details.medicationsRemarks) }];
        }
        if (details.medicationsVoiceNotes){
          subArray = [...subArray, { subheading: '', audio: (uploadsUrl.replace('uploads/', '')) + details.medicationsVoiceNotes }];
        }
        if (details.consultationRemarks){
          subArray = [...subArray, { subheading: 'Consultation Remarks', description: (details.consultationRemarks) }];
        }
        if (details.consultationVoiceNotes){
          subArray = [...subArray, { subheading: '', audio: (uploadsUrl.replace('uploads/', '')) + details.consultationVoiceNotes }];
        }
        arr = [ ...arr, { heading: 'Consultation', columnSize: 1, subArray: subArray } ];
        subArray = [];


        if (details.patientEducationRemarks){
          subArray = [...subArray, { subheading: 'Remarks', description: (details.patientEducationRemarks) }];
        }
        if (details.patientEducationVoiceNotes){
          subArray = [...subArray, { subheading: '', audio: (uploadsUrl.replace('uploads/', '')) + details.patientEducationVoiceNotes }];
        }
        arr = [ ...arr, { heading: 'Patient Education and Discharge Instructions', columnSize: 1, subArray: subArray } ];
        subArray = [];


        /*
        for (let i = 0; i < details.length; i++) {
          let text = details[i].Texts;
          let chip = details[i].chips;
          for (let j = 0; j < text.length; j++) {
            subArray = [
              ...subArray,
              {
                subheading: details[i].name,
                description:
                  text[j].value && text[j].value.length > 0
                    ? text[j].value
                    : "No details entered",
              },
            ];
          }
          for (let j = 0; j < chip.length; j++) {
            subArray = [
              ...subArray,
              {
                subheading: chip[j].name,
                description:
                  chip[j].detail && chip[j].detail.length > 0
                    ? chip[j].detail
                    : " ",
              },
            ];
          }
          arr = [
            ...arr,
            {
              heading: details[i].name,
              columnSize: 1,
              subArray: subArray,
            },
          ];
          subArray = [];
        }*/
      } else if (
        key === "investigation" &&
        propsData.comingFor === "Investigations"
      ) {
        const details = val[val.length - 1].details;

        for (let i = 0; i < details.length; i++) {
          let options = [];
          let chips = details[i].chips;
          let texts = details[i].Texts;

          if (chips && chips.length > 0) {
            for (let j = 0; j < chips.length; j++) {
              if (
                chips[j].name === "Add ECG Report" ||
                chips[j].name === "Add CXR Report" ||
                chips[j].name === "Add Medication Images" ||
                chips[j].name === "Add Skin Image"
              ) {
                if (chips[j].name === "Add ECG Report") {
                  setPriorECG(chips[j].image);
                } else if (chips[j].name === "Add CXR Report") {
                  setpriorXray(chips[j].image);
                } else if (chips[j].name === "Add Skin Image") {
                  setSkinReport(chips[j].image);
                }
                subArray = [
                  ...subArray,
                  {
                    images: chips[j].image,
                  },
                ];
              } else if (
                chips[j].name === "Normal" ||
                chips[j].name === "Nml Except"
              ) {
                options.push(chips[j].name);
              } else {
                subArray = [
                  ...subArray,
                  {
                    subheading: chips[j].name,
                    description:
                      !chips[j].detail || chips[j].detail === ""
                        ? " "
                        : chips[j].detail,
                  },
                ];
              }
            }
          }
          if (texts && texts.length > 0) {
            for (let a = 0; a < texts.length; a++) {
              subArray = [
                ...subArray,
                {
                  subheading: texts[a].name,
                  description: texts[a].value,
                },
              ];
            }
          }
          arr = [
            ...arr,
            {
              heading:
                options.length > 0
                  ? details[i].name + " (" + options.map((d) => d) + ")"
                  : details[i].name,
              columnSize:
                details[i].name === "CBC" ||
                details[i].name === "Chemistries" ||
                details[i].name === "UA"
                  ? 3
                  : 1,
              subArray:
                subArray.length > 0
                  ? subArray
                  : (subArray = [
                      {
                        subheading: "Section Not Filled Properly !",
                        description: " ",
                      },
                    ]),
            },
          ];
          subArray = [];
        }
      } else if (
        key === "physicalExam" &&
        propsData.comingFor === "Physical Exam"
      ) {
        const details = val[val.length - 1].details;

        for (let i = 0; i < details.length; i++) {
          let chips = details[i].chips;

          if (details[i].scale && details[i].scale.name) {
            subArray = [
              ...subArray,
              {
                subheading: details[i].scale.name,
                description:
                  details[i].scale.value <= 4
                    ? "Mild (" + details[i].scale.value + ")"
                    : details[i].scale.value <= 7
                    ? "Moderate (" + details[i].scale.value + ")"
                    : "Severe (" + details[i].scale.value + ")",
              },
            ];
          }
          if (chips && chips.length > 0) {
            for (let j = 0; j < chips.length; j++) {
              let subChips = chips[j].subChips;
              let right = chips[j].right;
              let left = chips[j].left;
              let imageMarkers = chips[j].imageMarkers;

              if (subChips && subChips.length > 0) {
                for (let k = 0; k < subChips.length; k++) {
                  if (subChips[k].selected === true) {
                    subArray = [
                      ...subArray,
                      {
                        subheading: chips[j].name,
                        description:
                          chips[j].detail === "" ? " " : chips[j].detail,
                        subheadingType: subChips[k].name,
                      },
                    ];
                  }
                }
              } else if (
                (right && right.length > 0) ||
                (left && left.length > 0) ||
                (imageMarkers && imageMarkers.length > 0)
              ) {
                subArray = [
                  ...subArray,
                  {
                    subheading: chips[j].name,
                    description: chips[j].detail === "" ? " " : chips[j].detail,
                    detailsL: left,
                    detailsR: right,
                    detailsImageMarkers: imageMarkers,
                  },
                ];
              } else if (chips[j].name === "Add Skin Report" || chips[j].name === "Add Skin Image") {
                subArray = [
                  ...subArray,
                  {
                    images: chips[j].image,
                  },
                ];
              } else {
                subArray = [
                  ...subArray,
                  {
                    subheading: chips[j].name,
                    description:
                      !chips[j].detail || chips[j].detail === ""
                        ? " "
                        : chips[j].detail,
                  },
                ];
              }
            }
          }
          arr = [
            ...arr,
            {
              heading: details[i].name,
              columnSize: details[i].name === "Physical Exam" ? 2 : 1,
              subArray:
                subArray.length > 0
                  ? subArray
                  : (subArray = [
                      {
                        subheading: "Section Not Filled Properly !",
                        description: " ",
                      },
                    ]),
            },
          ];
          subArray = [];
        }
      }
    });
    setformData(arr);
  }, []);

  const handleEdit = () => {
    if (formName === "Triage & Assessment") {
      props.history.push({
        pathname: "triageForm",
        state: {
          comingFor: formName,
          selectedItem: selectedItem,
        },
      });
    } else {
      console.log('propsDataFormData::click:', mFormData.details);
      props.history.push({
        pathname: "Form",
        state: {
          comingFor: formName,
          selectedItem: selectedItem,
          currentFields: mFormData,
          comingFrom: "edit",
          priorECG: priorECG,
          priorXray: priorXray,
          MedicationImages: MedicationImages,
          SkinReport: SkinReport,
        },
      });
    }
  };

  console.log("props in view form", props);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        position: "fixed",
        width: "100%",
        height: "100%",
        overflowY: "scroll",
        border: "1p",
      }}
    >
    <div id="jstpdf">
      <Header 
        history={props.history}
        showForPDF={isForPDF}
        />

      <PatientDetailsQuick
        edrId={selectedItem._id} />

      <div className="cPadding">
        <div
          className="subheader"
          style={{ marginLeft: "-15px" }}
        >
          <div className="col-md-6 col-8">
            {formName === "Patient Details" ? (
              <img src={PatientDetailIcon} alt="PatientDetailIcon" />
            ) : formName === "Past Medical History" ? (
              <img src={PastMedicalIcon} alt="PastMedicalIcon" />
            ) : formName === "Investigations" ? (
              <img src={InvestigationIcon} alt="InvestigationIcon" />
            ) : formName === "ROS" ? (
              <img src={ROSIcon} alt="ROS" />
            ) : formName === "Physical Exam" ? (
              <img src={PhysicalExamIcon} alt="PhysicalExamIcon" />
            ) : formName === "Action & Plan" ? (
              <img src={ActionPlanIcon} alt="ActionPlanIcon" />
            ) : formName === "Course of Visit" ? (
              <img src={covIcon} alt="covIcon" />
            ) : formName === "Triage & Assessment" ? (
              <img
                src={TriageAndAssessmentIcon}
                alt="TriageAndAssessmentIcon"
              />
            ) : (
              undefined
            )}
            <h4
              style={{
                color: "#000000",
                fontSize: matches ? 34 : 15,
                fontWeight: "bolder",
              }}
            >
              {formName}
            </h4>
          </div>
          <div className="col-md-6 col-4">
            {isForPDF || isPrinting ? undefined:
            <>
            <img
              style={{ width: 35, height: "inherit", marginRight: 10, cursor: 'pointer' }}
              src={DownloadIcon}
              onClick={downloadTriage}
              alt="Alternate"
            />
            <img
              style={{ width: 35, height: "inherit", cursor: 'pointer' }}
              src={PrintIcon}
              onClick={printForm}
              alt="Alternate"
            />
            </>}
          </div>
        </div>

        <div className="container-fluid">
          <div>
          {formData && formData.length > 0
            ? formData.map((arr) => {
                return (
                  <div>
                    {formName === "Triage & Assessment" ||
                    formName === "Investigations" ||
                    formName === "Action & Plan" ||
                    formName === "Course of Visit" ? (
                      <DetailBlock
                        formName={formName}
                        heading={arr.heading}
                        subArray={arr.subArray}
                        columnSize={arr.columnSize}
                        showForPDF={isForPDF}
                      />
                    ) : (
                      <DetailBlockForDCD
                        formName={formName}
                        heading={arr.heading}
                        subArray={arr.subArray}
                        columnSize={arr.columnSize}
                        showForPDF={isForPDF}
                      />
                    )}
                  </div>
                );
              })
            : undefined}
          </div>
          {isForPDF || isPrinting ? undefined : 
          <div className="row" style={{ marginTop: "2%", marginBottom: "2%" }}>
            <div className="col-md-6 col-sm-6 col-6">
              <img
                onClick={() => props.history.goBack()}
                src={Back}
                style={{ width: 45, height: 40, cursor: "pointer" }}
                alt="Alternate"
              />
            </div>
            <div className="col-md-6 col-sm-6 col-6 d-flex justify-content-end">
              <Button
                style={styles.Edit}
                variant="contained"
                color="default"
                onClick={handleEdit}
              >
                Edit
              </Button>
            </div>
          </div>}
        </div>
      </div>
    </div>
    </div>
  );
}
export default ViewForm;
