export const tableHeadingForCC = [
    "CID",
    "Chief-Complaints",
    "Most Selected",
    " "
];
export const tableDataKeysForCC = [
    "chiefComplaintId",
    "name",
    "selected"
];

export const tableHeadingForCareStream = [
    "CarestreamID",
    "CarestreamName",
    "Most Selected",
    " "
];
export const tableDataKeysForCareStream = [
    "identifier",
    "name",
    "selected"
];

export const tableHeadingForMostMeds = [
    "Item Code",
    "Item Name/Brand",
    "Drug",
    "Type",
    "Vendor",
    "Requested (No. Of Times)",
    " "
];
export const tableDataKeysForMostMeds = [
    "itemCode",
    "name",
    "scientificName",
    "medClass",
    ["vendorId", "englishName"],
    "selected"
];

export const tableHeadingForLabTests = [
    "Lab Test ID",
    "Lab Test Name",
    "Test Type",
    "No. Of Requests",
    "Date/Time (Last Request)",
    " "
];
export const tableDataKeysForLabTests = [
    "identifier",
    "name",
    "type",
    "selected",
    "lastRequested",
];

export const tableHeadingForRadTests = [
    "Rad Test ID",
    "Rad Test Name",
    "Test Type",
    "No. Of Requests",
    "Date/Time (Last Request)",
    " "
];
export const tableDataKeysForRadTests = [
    "identifier",
    "name",
    "type",
    "selected",
    "lastRequested",
];