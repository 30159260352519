import React from 'react'
import MenuTree from '../../../../components/MenuTree/MenuTree'
import cookie from 'react-cookies'
import Header from '../../../../components/Header/Header'
import { connect } from 'react-redux'
import {
  funForReducer,
  setPatientDetailsForReducer,
} from '../../../../actions/Checking'

// Icons
import Back from '../../../../assets/img/Back_Arrow.png'
import MyDashboardIcon from '../../../../assets/img/My Dashboard.png'
import MyAppIcon from '../../../../assets/img/My App.png'

// Registration Icons
import NotificationIcon from '../../../../assets/img/External Consultants.png'
import ChatIcon from '../../../../assets/img/Social Workers.png'

// Insurance Icons
import RegisterOfficerIcon from '../../../../assets/img/PatientRegistration.png'

// Admin Panel Icons
import AdminPanelIcon from '../../../../assets/img/PatientRegistration.png'
import Patients from '../../../../assets/img/Imaging Technician.png'
import Management from '../../../../assets/img/Customer Care Services.png'
import TransactionReport from '../../../../assets/img/Anesthesiologists.png'

import SecondaryRoles from '../../../../assets/img/Secondary Roles.png'

const adminPanel = [
  { img: SecondaryRoles, text: 'Secondary Roles' },
  {
    img: NotificationIcon,
    text: 'Notification',
    path: '/dashboard/underprogress',
  },

  {
    img: ChatIcon,
    text: 'Imaging Technician Performed Tests',
    path:
      '/dashboard/home/secondaryroles/imagingTechnician/imagingTechnicianTests',
  },

  {
    img: Patients,
    text: 'Delay In Process (Red Flags)',
    path: '/dashboard/underprogress',
  },
]

class Dashboard extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      openApps: false,
      currentUser: cookie.load('current_user'),
      userStaff: '',

      options: '',

      openHome: false,

      notificationArray: [],

      headingIcon: '',
    }
  }

  componentWillMount() {
    this.setState({
      currentUser: cookie.load('current_user'),
      userStaff: cookie.load('user_staff'),
    })
  }

  componentDidMount() {
    // if (this.state.userStaff !== "" && this.state.userStaff !== "undefined") {
    //   this.setOptions();
    // }

    this.props.setPatientDetailsForReducer('')

    if (this.state.openHome)
      setTimeout(() => {
        document.getElementById('menu-open').checked = true
      }, 120)

    if (this.state.currentUser.email === 'admin@dhr.com') {
      this.setState({
        notificationArray: [
          {
            icon: MyDashboardIcon,
            title: 'New Registrations Arriving',
          },
        ],
        headingIcon: RegisterOfficerIcon,
        headingTitle: 'Admin Panel',
      })
    }

    if (this.state.currentUser.email === 'lab@dhr.com') {
      this.setState({
        notificationArray: [
          {
            icon: MyDashboardIcon,
            title: 'New Registrations Arriving',
          },
        ],
        headingIcon: RegisterOfficerIcon,
        headingTitle: 'Lab Technician',
      })
    }

    // if (true) {
    //   this.setState({
    //     notificationArray: [
    //       {
    //         icon: MyDashboardIcon,
    //         title: 'New Registrations Arriving',
    //       },
    //     ],
    //     headingIcon: RegisterOfficerIcon,
    //     headingTitle: 'Insurance Claims Manager',
    //   })
    // }
    else {
      this.setState({
        notificationArray: [
          {
            icon: MyDashboardIcon,
            title: 'New Registrations Arriving',
          },
        ],
        headingIcon: RegisterOfficerIcon,
        headingTitle: 'Registration Officer',
      })
    }
  }

  render() {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          position: 'fixed',
          width: '100%',
          height: '100%',
          backgroundColor: '#2962CC',
          // overflow: "auto",
        }}
      >
        <Header history={this.props.history} />

        <MenuTree
          history={this.props.history}
          options={
            this.state.currentUser &&
            this.state.currentUser.staffType === 'Admin'
              ? adminPanel
              : ''
          }
        />

        <div
          style={{
            position: 'fixed',
            width: '100%',
            height: '10%',
            top: '90%',
          }}
        >
          <img
            onClick={() => this.props.history.goBack()}
            src={Back}
            style={{
              width: 45,
              height: 35,
              marginLeft: '5%',
              cursor: 'pointer',
            }}
          />
        </div>
      </div>
    )
  }
}
const mapStateToProps = ({ CheckingReducer }) => {
  const { count, patientDetails } = CheckingReducer
  return { count, patientDetails }
}
export default connect(mapStateToProps, {
  funForReducer,
  setPatientDetailsForReducer,
})(Dashboard)
