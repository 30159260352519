import {
  addCareStream,
  getCareStream,
  enableCareStream,
  disableCareStream,
  getMedicationsCareStreams,
  getMedicationsByIdCareStreams,
  getAllEnableDisableCareStreams
} from "../../public/endpoins"
import axios from "axios"

export const networkAddCareStream = (props, object) => {
  axios
    .post(`${addCareStream}`, object)
    .then((response) => {
      console.log(response)

      props.history.goBack()
    })
    .catch((error) => {
      console.log(error)
    })
}

export const networkGetCareStream = (setCareStream) => {
  axios
    .get(getAllEnableDisableCareStreams)
    .then((response) => {
      if (response.data.success) {
        response.data.data.docs.map((d) => (d.ID = d.identifier[0].value))
        setCareStream(response.data.data.docs.reverse())
      }
      return response
    })
    .catch((error) => {
      console.log("error: ", error)
    })
}

export const networkGetMedicationCareStream = (setMedicationCareStream) => {
  axios
    .get(getMedicationsCareStreams)
    .then((response) => {
      if (response.data.success) {
        response.data.data.map((d) => (d.ID = d.identifier[0].value))
        setMedicationCareStream(response.data.data)
      }
      return response
    })
    .catch((error) => {
      console.log("error: ", error)
    })
}

export const networkDisableEnableCareStream = (
  setOpenEnableDisabledModal,
  setSelectedRecord,
  setErrorMsg,
  setOpenNotification,
  selectedRecord,
  object,
) => {
  let url = !selectedRecord.disabled ? disableCareStream : enableCareStream
  axios
    .put(url + "/" + selectedRecord._id, object)
    .then((res) => {
      if (res.data.success) {
        setOpenEnableDisabledModal(false)
        setSelectedRecord("")
        window.location.reload(false)
      } else if (!res.data.success) {
        setErrorMsg(res.data.error)
        setOpenNotification(true)
      }
      return res
    })
    .catch((e) => {
      console.log("error while deletion ", e)
    })
}

export const networkGetMedicationsByIdCareStreams = (props, record) => {
  axios
    .get(`${getMedicationsByIdCareStreams}/${record._id}`)
    .then((response) => {
      let path = `carestreamall/medications`
      props.history.push({
        pathname: path,
        state: {
          medications: response.data.data.medications,
          record,
        },
      })
    })
    .catch((error) => {
      console.log("ERROR", error)
    })
}
