import React, { useEffect, useState } from "react";
import TAA from "../../assets/img/Triage & Assessment Icon.png";
import ViewTriageAndAssessment from "../DCD/ViewForm/ViewTriageAndAssessment/ViewTriageAndAssessment";
import formatDate from "../../utils/formatDate";

function ViewSingleTriage(props) {
  const [selectedSingleTriage, setSelectedSingleTriage] = useState("");

  useEffect(() => {
    console.log("PROPSDATA", props);
    const selectedItem = props.propsData.history.location.state.others.selectedItem;
    console.log("selectedItem", selectedItem);
    const selectedTriage =
      props.propsData.history.location.state.selectedTriage;


    let triageLevelCheck = [
      {
        subheading: "Triage Level (System)",
        description: selectedTriage.triageLevel.toString(),
      },
    ];
    if (selectedTriage.newTriageLevel !== ""){
      triageLevelCheck.push({
        subheading: "Triage Level (Override)",
        description: selectedTriage.newTriageLevel.toString(),
      });
      triageLevelCheck.push({
        subheading: "Triage Level (Override) - Reason",
        description: selectedTriage.reasonChangeTriageLevel.toString(),
      });
    }

    let arr = [
      {
        heading: "Triage History",
        columnSize: 4,
        subArray: [
          {
            subheading: "Date/Time",
            description: formatDate(selectedItem.createdTimeStamp),
          },
          {
            subheading: "Triage No.",
            description: selectedTriage.TAARequestNo,
          },
        ],
      },
      {
        heading: "Triage Level",
        columnSize: 1,
        subArray: triageLevelCheck
      },
      {
        heading: "Patient Vitals",
        columnSize: 4,
        subArray: [
          {
            subheading: "Heart Rate",
            description:
              selectedTriage.heartRate !== "N/A"
                ? selectedTriage.heartRate + " bpm"
                : "",
          },
          {
            subheading: "Blood Pressure Systolic",
            description:
              selectedTriage.bloodPressureSys !== "N/A"
                ? selectedTriage.bloodPressureSys + " mmHg"
                : "",
          },
          {
            subheading: "Blood Pressure Diastolic",
            description:
              selectedTriage.bloodPressureDia !== "N/A"
                ? selectedTriage.bloodPressureDia + " mmHg"
                : "",
          },
          {
            subheading: "Respiratory Rate",
            description:
              selectedTriage.respiratoryRate !== "N/A"
                ? selectedTriage.respiratoryRate + " /min"
                : "",
          },
          {
            subheading: "Temperature",
            description:
              selectedTriage.temperature !== "N/A"
                ? selectedTriage.temperature + " °C"
                : "",
          },
          {
            subheading: "Blood Sugar Level",
            description:
              selectedTriage.FSBS !== "N/A"
                ? selectedTriage.FSBS + " mg/dL"
                : "",
          },
          {
            subheading: "Pain Scale",
            description:
              selectedTriage.painScale !== "N/A"
                ? selectedTriage.painScale
                : "",
          },
          {
            subheading: "Pulse OX",
            description:
              selectedTriage.pulseOX !== "N/A"
                ? selectedTriage.pulseOX + " SpO2"
                : "",
          },
          {
            subheading: "Weight",
            description:
              selectedTriage.weight !== "N/A"
                ? selectedTriage.weight + ""
                : "",
          },
          {
            subheading: "Size",
            description:
              selectedTriage.SIZE !== "N/A"
                ? selectedTriage.SIZE + ""
                : "",
          },
          {
            subheading: "PO2",
            description:
              selectedTriage.PO2 !== "N/A"
                ? selectedTriage.PO2 + ""
                : "",
          },
          {
            subheading: "BMI",
            description:
              selectedTriage.BMI !== "N/A"
                ? selectedTriage.BMI + ""
                : "",
          },
          {
            subheading: "Intraocular Pressure (IOP) - Left",
            description:
              selectedTriage.IOP !== "N/A"
                ? selectedTriage.IOP + ""
                : "",
          },
          {
            subheading: "Intraocular Pressure (IOP) - Right",
            description:
              selectedTriage.IOPRight !== "N/A"
                ? selectedTriage.IOPRight + ""
                : "",
          },
        ],
      },
      {
        heading: "Physical Examination",
        columnSize: 1,
        subArray: [
          {
            subheading: "General Appearance",
            description: selectedTriage.generalAppearance.toString(),
          },
          {
            subheading: "Respiratory",
            description: selectedTriage.respiratory.toString(),
          },
          {
            subheading: "Cardiac",
            description: selectedTriage.cardiac.toString(),
          },
          {
            subheading: "Abdomen",
            description: selectedTriage.abdomen.toString(),
          },
          {
            subheading: "Neurological",
            description: selectedTriage.neurological.toString(),
          },
          {
            subheading: "Glasgow Coma Scale/Score",
            description: selectedTriage.gcsScore.toString(),
          }
        ],
      },
    ];
    setSelectedSingleTriage(arr);
  }, []);

  return (
    <div style={{ overflowX: "hidden" }}>
      <ViewTriageAndAssessment
        heading={"Triage & Assessment"}
        icon={TAA}
        allProps={props}
        propsData={props.propsData.history.location.state}
        selectedSingleTriage={selectedSingleTriage}
      />
    </div>
  );
}
export default ViewSingleTriage;
