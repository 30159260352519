import React, { useEffect } from 'react'
import ShortPatientRegistration from '../../components/ShortPatientRegistration/ShortPatientRegistration'

import useMediaQuery from '@material-ui/core/useMediaQuery'
import {
  patientRegistration,
  updatePatientUrl,
  SearchPatients,
  getPatientByIdUrl,
} from '../../public/endpoins'
import Header from '../../components/Header/Header'
import patientRegister from '../../assets/img/PatientRegistration.png'
import '../../assets/jss/material-dashboard-react/components/TextInputStyle.css'

let matches

function PatientRegistrationForParamedics(props) {
  matches = useMediaQuery('(min-width:600px)')

  const getSaveData = (data) => {
    console.log('dataaa', data)
  }

  useEffect(() => {
    // setCountries(Object.keys(countriesList[0]))
    console.log('props', props)
  }, [])

  return (
    // <div
    //   style={{
    //     backgroundColor: 'rgb(19 213 159)',
    //     position: 'fixed',
    //     display: 'flex',
    //     width: '100%',
    //     height: '100%',
    //     flexDirection: 'column',
    //     flex: 1,
    //     overflowY: 'scroll',
    //   }}
    // >
    //   <Header history={props.history} />
    //   <div className='cPadding'>
    //     <div className='subheader' style={{ marginLeft: '-20px' }}>
    //       <div>
    //         <img src={patientRegister} />
    //         <div style={{ flex: 4, display: 'flex', alignItems: 'center' }}>
    //           <h4 style={{ color: 'white', fontWeight: '700' }}>
    //             Patient Registration
    //           </h4>
    //         </div>
    //       </div>
    //     </div>

        <ShortPatientRegistration
          SaveData={getSaveData}
          props={props}
          patientRegistration={patientRegistration}
          updatePatient={updatePatientUrl}
          SearchPatients={SearchPatients}
          getPatientByIdUrl={getPatientByIdUrl}
        />
    //   </div>
    // </div>
  )
}
export default PatientRegistrationForParamedics
