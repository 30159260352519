import axios from "axios";
import {
  getCSPatients,
  searchCSPatient,
  getCareStream,
  asignCareStream,
} from "../../public/endpoins";

export const getEdrPatientsForCC = (
  setlabInPatient,
  setErrorMsg,
  setOpenNotification,
  setisLoading
) => {
  axios
    .get(getCSPatients)
    .then((res) => {
      if (res.data.success) {
        res.data.data.map(
          (d) =>
            (d.patientName =
              d.patientId.name[0].given[0] + " " + d.patientId.name[0].family)
        );
        res.data.data.map((d) => (d.mrn = d.patientId.identifier[0].value));
        res.data.data.map((d) => (d.gender = d.patientId.gender));
        res.data.data.map((d) => (d.age = d.patientId.age));
        res.data.data.map((d) => (d.createdAt = d.patientId.createdAt));
        res.data.data.map((d) => (d.status = d.dcdFormStatus));

        console.log(res.data.data, "patient data");
        setisLoading(false);
        setlabInPatient(res.data.data.reverse());
      } else if (!res.data.success) {
        setErrorMsg(res.data.error);
        setOpenNotification(true);
      }
      return res;
    })
    .catch((e) => {
      console.log("error: ", e);
    });
};

export const searchEdrPatientsForCC = (
  a,
  setlabInPatient,
  setErrorMsg,
  setOpenNotification,
  setisLoading
) => {
  console.log("A", a);
  axios
    .get(searchCSPatient + "/" + a)
    .then((res) => {
      console.log("res", res);
      if (res.data.success) {
        res.data.data.map(
          (d) =>
            (d.patientName =
              d.patientId.name[0].given[0] + " " + d.patientId.name[0].family)
        );
        res.data.data.map((d) => (d.mrn = d.patientId.identifier[0].value));
        res.data.data.map((d) => (d.gender = d.patientId.gender));
        res.data.data.map((d) => (d.age = d.patientId.age));
        res.data.data.map((d) => (d.createdAt = d.patientId.createdAt));
        res.data.data.map((d) => (d.status = d.dcdFormStatus));
        setisLoading(false);
        console.log(res.data.data, "patient data");
        setlabInPatient(res.data.data);
      } else if (!res.data.success) {
        setErrorMsg(res.data.error);
        setOpenNotification(true);
      }
    })
    .catch((e) => {
      console.log("error after searching patient request", e);
    });
};

export const getAllCareStreams = (
  setNames,
  setlabInPatient,
  setErrorMsg,
  setOpenNotification
) => {
  axios
    .get(getCareStream)
    .then((res) => {
      if (res.data.success) {
        console.log("response", res.data.data.docs);
        setlabInPatient(res.data.data.docs);
        setNames(res.data.data.docs);
      } else if (!res.data.success) {
        setErrorMsg(res.data.error);
        setOpenNotification(true);
      }
    })
    .catch((e) => {
      setErrorMsg(e);
      setOpenNotification(true);
    });
};

export const asignCareStreamCall = (data, setErrorMsg, setOpenNotification) => {
  axios
    .post(asignCareStream, data)
    .then((res) => {
      if (res.data.success) {
        console.log("Res", res);
      } else if (!res.data.success) {
        setErrorMsg(res.data.error);
        setOpenNotification(true);
      }
    })
    .catch((e) => {
      setErrorMsg(e);
      setOpenNotification(true);
    });
};
