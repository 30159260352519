import React, { useEffect, useState } from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import LineChart from "./LineChart";

const styles = {
  stylesForLabelValue: {
    position: "absolute",
    fontSize: 10,
    opacity: "70%",
  },

  stylesForBars: {
    position: "absolute",
    fontWeight: "bold",
    opacity: "70%",
  },
};

function Graph(props) {
  const [scaleOnXside, setScaleOnXside] = useState([]);
  const [options, setOptions] = useState("");
  const minimumWidthForTab = useMediaQuery("(min-width:601px)");
  const maximumWidthForTab = useMediaQuery("(max-width:768px)");

  useEffect(() => {
    let temp = [];
    let count = 1;
    for (let i = 6; i > 0; i--) {
      let date = new Date();
      let hourago = new Date(date.getTime() - 1000 * 60 * 60 * i);
      var hours = hourago.getHours();
      var ampm = hours >= 12 ? "pm" : "am";
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      temp.push({
        label: count,
        value: 10 * count - i,
      });
      count++;
    }
    setOptions(temp);
    // console.log(temp)
  }, []);

  return (
    <div
      style={{
        width: "100%",
        height: 270,
        backgroundColor: "white",
        borderRadius: 5,
      }}
      onClick={() => (props.flipHandler ? props.flipHandler(true) : {})}
    >
      <div
        style={{
          display: "flex",
          height: "10%",
          justifyContent: "center",
          alignItems: "flex-end",
        }}
      >
        <span
          style={{
            fontSize: 13,
            fontWeight: "700",
            // opacity: "70%",
            color: "grey",
          }}
        >
          Last 06 Hours
        </span>
      </div>

      <div
        style={{
          display: "flex",
          height: "12%",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: minimumWidthForTab && maximumWidthForTab ? 10 : "",
        }}
      >
        <span
          style={{
            fontSize: minimumWidthForTab && maximumWidthForTab ? 14 : 12,
            fontWeight: "bold",
            opacity: "60%",
          }}
        >
          {props.mainHeadingForGraph}
        </span>
      </div>

      <div
        style={{
          display: "flex",
          height: "10%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <strong
          style={{
            fontSize: 40,
            opacity: "70%",
            color: "#2973CF",
            position: "absolute",
            marginTop: 4,
          }}
        >
          {props.graphValue}
        </strong>
      </div>

      <div
        className="container"
        style={{
          display: "flex",
          height: "70%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className="row" style={{ overflow: "visible" }}>
          <div className="col-sm-12">
            {props.data ? (
              <LineChart
                data={props.data}
                width={minimumWidthForTab && maximumWidthForTab ? 270 : 320}
                height={140}
                scaleWidth={
                  minimumWidthForTab && maximumWidthForTab ? 270 : 320
                }
                scaleHeight={100}
                idForGraph={props.idForGraph}
                // options={props.options}
              />
            ) : (
              undefined
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default React.memo(Graph);
