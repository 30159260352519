import axios from "axios";
import {
  //  ED Room history urls
  getCCDataForED,
  getTimeInterval,
  getTransferToEOU,
  getDischarged,
  getLabTest,
  getDeceasedPatients,
  // Current Activity urls
  getAvailableEdBeds,
  getEDCCPatients,
  getPatientTreatment,
  getMedicationReconciliation,
  getCurrentTimeInterval,
  getCurrentLabTest,
  getCurrentRadTest,
  getCriticalLabTest,
  getDischargedRequirements,
} from "../../public/endpoins";
import _ from "lodash";

export function getRoomHistoryTableData(
  tabName,
  setTableData,
  setisLoading,
  setNoOfPatients,
  setOpenNotification,
  setErrorMsg
) {
  let url;
  if (tabName === "Chief Complaint") {
    url = getCCDataForED;
  } else if (tabName === "CareStream") {
    // url =
  } else if (tabName === "Time Interval") {
    url = getTimeInterval;
  } else if (tabName === "Transfer to EOU") {
    url = getTransferToEOU;
  } else if (tabName === "Discharged Patients") {
    url = getDischarged;
  } else if (tabName === "Lab Tests") {
    url = getLabTest;
  } else if (tabName === "Deaths Occured") {
    url = getDeceasedPatients;
  } else if (tabName === "Critical Functions") {
    // url =
  } else if (tabName === "CareStream Time Interval") {
    // url =
  }
  axios
    .get(url)
    .then((res) => {
      if (res.data.success) {
        console.log("Response From API", tabName, res.data.data);
        if (tabName === "Time Interval") {
          res.data.data.map(
            (d) => (
              (d.mrn = d.patientId.identifier[0].value),
              (d.patientName =
                d.patientId.name[0].given[0] +
                " " +
                d.patientId.name[0].family),
              (d.hrs = d.days + " Days " + d.hours + " Hrs")
            )
          );
        } else if (tabName === "Transfer to EOU") {
          res.data.data.map(
            (d) => (
              (d.mrn =
                d.edrId && d.edrId.patientId
                  ? d.edrId.patientId.identifier[0].value
                  : "N/A"),
              (d.patientName =
                d.edrId && d.edrId.patientId
                  ? d.edrId.patientId.name[0].given[0] +
                    " " +
                    d.edrId.patientId.name[0].family
                  : "N/A"),
              (d.bedCell = d.edrId.room[d.edrId.room.length - 1].roomId.roomNo),
              (d.productionArea = d.edrId
                ? d.edrId.chiefComplaint[d.edrId.chiefComplaint.length - 1]
                    .chiefComplaintId.productionArea[
                    d.edrId.chiefComplaint[d.edrId.chiefComplaint.length - 1]
                      .chiefComplaintId.productionArea.length - 1
                  ].productionAreaId.paName
                : "N/A")
            )
          );
          setNoOfPatients(res.data.data.length);
        } else if (tabName === "Discharged Patients") {
          res.data.data.map(
            (d) => (
              (d.mrn = d.patientId.identifier[0].value),
              (d.patientName =
                d.patientId.name[0].given[0] +
                " " +
                d.patientId.name[0].family),
              (d.roomId =
                d.room.length > 0 && d.room[d.room.length - 1].roomId.roomId
                  ? d.room[d.room.length - 1].roomId.roomId
                  : "N/A"),
              (d.careStream =
                d.careStream.length > 0
                  ? d.careStream[d.careStream.length - 1].name
                  : "No CareStream")
            )
          );
        } else if (tabName === "Lab Tests") {
          res.data.data.map(
            (d) => (
              (d.mrn = d.patientId.identifier[0].value),
              (d.patientName =
                d.patientId.name[0].given[0] +
                " " +
                d.patientId.name[0].family),
              (d.roomId = d.room[d.room.length - 1].roomId.roomId),
              (d.totalTests = d.labRequest.length)
            )
          );
        } else if (tabName === "Deaths Occured") {
          res.data.data.map(
            (d) => (
              (d.mrn = d.patientId.identifier[0].value),
              (d.patientName =
                d.patientId.name[0].given[0] +
                " " +
                d.patientId.name[0].family),
              (d.roomId = d.room[d.room.length - 1].roomId.roomId),
              (d.careStream =
                d.careStream.length > 0
                  ? d.careStream[d.careStream.length - 1].name
                  : "No CareStream")
            )
          );
          setNoOfPatients(res.data.data.length);
        }
        setTableData(res.data.data);
        setisLoading(false);
      } else if (!res.data.success) {
        setErrorMsg(res.data.error);
        setOpenNotification(true);
      }
      return res;
    })
    .catch((e) => {
      console.log("error: ", e);
    });
}

export function getCurrentActivityTableData(
  tabName,
  setTableData,
  setisLoading,
  setOpenNotification,
  setErrorMsg
) {
  let url;
  if (tabName === "Available ED Beds") {
    url = getAvailableEdBeds;
  } else if (tabName === "Chief Complaint") {
    url = getEDCCPatients;
  } else if (tabName === "CareStream In Process") {
    // url =
  } else if (tabName === "Patient Treatment") {
    url = getPatientTreatment;
  } else if (tabName === "Medication Reconciliation") {
    url = getMedicationReconciliation;
  } else if (tabName === "Flags Management") {
    // url =
  } else if (tabName === "Transfer of Care") {
    // url =
  } else if (tabName === "ED to EOU Room Allocation") {
    // url =
  } else if (tabName === "Time Interval") {
    url = getCurrentTimeInterval;
  } else if (tabName === "Lab Tests") {
    url = getCurrentLabTest;
  } else if (tabName === "Radiology Tests") {
    url = getCurrentRadTest;
  } else if (tabName === "Critical Lab Tests") {
    url = getCriticalLabTest;
  } else if (tabName === "Discharge Requirements") {
    url = getDischargedRequirements;
  } else if (tabName === "Critical Functional Calling") {
    // url =
  } else if (tabName === "CareStream Time Interval") {
    // url =
  }
  axios
    .get(url)
    .then((res) => {
      if (res.data.success) {
        console.log("Response From API", tabName, res.data.data);
        if (tabName === "Patient Treatment") {
          res.data.data.map(
            (d) => (
              (d.mrn = d.patientId.identifier[0].value),
              (d.patientName =
                d.patientId.name[0].given[0] +
                " " +
                d.patientId.name[0].family),
              (d.productionArea =
                d.chiefComplaint.length > 0 &&
                d.chiefComplaint[d.chiefComplaint.length - 1].chiefComplaintId
                  .productionArea.length > 0 &&
                d.chiefComplaint[d.chiefComplaint.length - 1].chiefComplaintId
                  .productionArea[
                  d.chiefComplaint[d.chiefComplaint.length - 1].chiefComplaintId
                    .productionArea.length - 1
                ].productionAreaId
                  ? d.chiefComplaint[d.chiefComplaint.length - 1]
                      .chiefComplaintId.productionArea[
                      d.chiefComplaint[d.chiefComplaint.length - 1]
                        .chiefComplaintId.productionArea.length - 1
                    ].productionAreaId.paName
                  : "No Area Assigned")
            )
          );
        } else if (tabName === "Medication Reconciliation") {
          res.data.data.map(
            (d) => (
              (d.mrn = d.patientId.identifier[0].value),
              (d.patientName =
                d.patientId.name[0].given[0] +
                " " +
                d.patientId.name[0].family),
              (d.careStream =
                d.careStream.length > 0
                  ? d.careStream[d.careStream.length - 1].name
                  : "No CareStream"),
              (d.productionArea =
                d.chiefComplaint[
                  d.chiefComplaint.length - 1
                ].chiefComplaintId.productionArea[
                  d.chiefComplaint[d.chiefComplaint.length - 1].chiefComplaintId
                    .productionArea.length - 1
                ].productionAreaId.paName)
            )
          );
        } else if (tabName === "Time Interval") {
          res.data.data.map(
            (d) => (
              (d.mrn = d.patientId.identifier[0].value),
              (d.patientName =
                d.patientId.name[0].given[0] +
                " " +
                d.patientId.name[0].family),
              (d.timeInterval = d.days + " Days " + d.hours + " Hrs"),
              (d.currentTime = new Date().toISOString())
            )
          );
        } else if (tabName === "Lab Tests") {
          res.data.data.map(
            (d) => (
              (d.mrn = d.patientId.identifier[0].value),
              (d.patientName =
                d.patientId.name[0].given[0] +
                " " +
                d.patientId.name[0].family),
              (d.roomId =
                d.room && d.room.length > 0
                  ? d.room[d.room.length - 1].roomId.roomId
                  : "No ID"),
              (d.totalTests = d.labRequest.length)
            )
          );
        } else if (tabName === "Radiology Tests") {
          res.data.data.map(
            (d) => (
              (d.mrn = d.patientId.identifier[0].value),
              (d.patientName =
                d.patientId.name[0].given[0] +
                " " +
                d.patientId.name[0].family),
              (d.roomId =
                d.room && d.room.length > 0
                  ? d.room[d.room.length - 1].roomId.roomId
                  : "No ID"),
              (d.totalTests = d.radRequest.length)
            )
          );
        } else if (tabName === "Critical Lab Tests") {
          res.data.data.map(
            (d) => (
              (d.mrn = d.patientId.identifier[0].value),
              (d.patientName =
                d.patientId.name[0].given[0] +
                " " +
                d.patientId.name[0].family),
              (d.testName = d.labRequest.name),
              (d.testType = d.labRequest.type),
              (d.date = d.labRequest.requestedAt)
            )
          );
        } else if (tabName === "Discharge Requirements") {
          res.data.data.map(
            (d) => (
              (d.mrn = d.patientId.identifier[0].value),
              (d.patientName =
                d.patientId.name[0].given[0] +
                " " +
                d.patientId.name[0].family),
              (d.roomId =
                d.room && d.room.length > 0
                  ? d.room[d.room.length - 1].roomId.roomId
                  : "N/A"),
              (d.bedCellNo =
                d.room && d.room.length > 0
                  ? d.room[d.room.length - 1].roomId.roomNo
                  : "No Bed"),
              (d.productionArea =
                d.chiefComplaint[
                  d.chiefComplaint.length - 1
                ].chiefComplaintId.productionArea[
                  d.chiefComplaint[d.chiefComplaint.length - 1].chiefComplaintId
                    .productionArea.length - 1
                ].productionAreaId.paName),
              (d.requirement =
                d.dischargeRequest && d.dischargeRequest.dischargeSummary
                  ? d.dischargeRequest.dischargeSummary.edrCompletionRequirement
                  : "N/A")
            )
          );
        }
        setTableData(res.data.data);
        setisLoading(false);
      } else if (!res.data.success) {
        setErrorMsg(res.data.error);
        setOpenNotification(true);
      }
      return res;
    })
    .catch((e) => {
      console.log("error: ", e);
    });
}
