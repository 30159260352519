import {
    SET_PATIENT_DETAILS,
    DELETE_PATIENT_DETAILS
} from '../constants/ActionTypes';

export const setSelectedPatientForReducer = (data) => {
    return {
        payload: data,
        type: SET_PATIENT_DETAILS
    };
};

export const removePatientDetailsReducer = (data) => {
    return {
        payload: data,
        type: DELETE_PATIENT_DETAILS
    };
};