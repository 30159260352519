import React, { useEffect, useState } from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import "react-circular-progressbar/dist/styles.css";
// Radial separators
import MobileFlip from "./MobileFlip";
import Graph from "./Graph";
import Dialer from "./Dialer";

function Flip(props) {
  const matches = useMediaQuery("(min-width:600px)");

  const [scaleOnXside, setScaleOnXside] = useState([]);
  const [options, setOptions] = useState("");
  const [change, setChange] = useState(true);
  // const [timecolor, setTimeColor] = useState(props.timecolor)

  const {
    mainHeading,
    value,
    color,
    subHeading,
    flagsCount,
    childHeading,
    time,
    timecolor,
    idForGraph,
    mainHeadingForGraph,
  } = props;

  return (
    <div>
      {!matches ? (
        <div>
          {change ? (
            <Dialer
              flipHandler={setChange}
              mainHeading={mainHeading}
              value={value}
              color={color}
              subHeading={subHeading}
              flagsCount={flagsCount}
              // mainHeadingForGraph={mainHeadingForGraph}
              childHeading={childHeading}
              time={time}
              timecolor={timecolor}
            />
          ) : (
            <MobileFlip
              openIt={true}
              setChange={setChange}
              idForGraph={idForGraph}
              mainHeadingForGraph={mainHeadingForGraph}
              mainHeading={mainHeading}
              subHeading={subHeading}
              flagsCount={flagsCount}
              childHeading={childHeading}
              value={value}
              color={color}
              time={time}
              timecolor={timecolor}
              data={props.data}
            />
          )}
        </div>
      ) : (
        <div style={{ cursor: "pointer" }}>
          {change ? (
            <Dialer
              flipHandler={setChange}
              mainHeading={mainHeading}
              value={value}
              color={color}
              subHeading={"TAT"}
              flagsCount={flagsCount}
              childHeading={childHeading}
              time={time}
              timecolor={timecolor}
            />
          ) : (
            <Graph
              flipHandler={setChange}
              data={[]}
              mainHeadingForGraph={mainHeadingForGraph}
              graphValue={value}
              idForGraph={idForGraph}
              data={props.data}
            />
          )}
        </div>
      )}
    </div>
  );
}

export default Flip;
