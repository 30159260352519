import React from "react";
import { Route, Switch } from "react-router-dom";
import NotFound from "../components/NotFound/NotFound";
import AllDischargedPatients from "../views/SensiDischarged/AllPatients";
import AllDischargedPatientsStatusReport from "../views/SensiDischarged/StatusReport";
import SuccessScreen from "../components/SuccessScreen/SuccessScreen";

export default class SenseiDischargeRoutes extends React.PureComponent {
  render() {
    return (
      <Switch>

        <Route
          exact
          path={`/dashboard/home/sensei/discharge/:id/details`}
          component={AllDischargedPatientsStatusReport}
        />

        <Route exact path='' component={AllDischargedPatients} />

        <Route path={`/notfound`} component={NotFound} />

        <Route path="*" component={NotFound} />
      </Switch>
    );
  }
}
