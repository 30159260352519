import React, { useEffect, useState } from "react";
import cookie from "react-cookies";
import axios from "axios";
import Button from "@material-ui/core/Button";
import Back from "../../../assets/img/Back_Arrow_black.png";
import Header from "../../../components/Header/HeaderGrey";
// import Notification from "../../../components/Snackbar/Notification.js";
import CustomTable from "../../../components/Table/Table";
import PatientDetails from "../../../components/PatientDetails/PatientDetailsDirect";
import Loader from "react-loader-spinner";
import formatDate from "../../../utils/formatDate";
import PatientDetailsQuick from "../../../components/PatientDetails/PatientDetailsQuick";
import { displayInHumanDifference, replacePatientIdIfNull } from "../../../utils/commonFunc";

import {
  getSingleEdr,
} from "../../../public/endpoins";


// Icons
import PatientDetailIcon from "../../../assets/img/Patient Details Icon.png";

const actions = { view: true };
const tableHeadingForTriage = [
  //"Request No",
  "Time",
  // "Version Code",
  "Reason",
  "Doctor/Staff",
  // "Chief Complaint",
  // "Room No",
  // "Status",
  "Action",
];
const tableDataKeysForTriage = [
  //"triageRequestNo",
  "formatTriageTime",
  // "versionCode",
  "reasonToEdit",
  "staff",
  // "chiefComplaint",
  // "roomNo",
  // "status",
];

function ViewFormHistory(props) {
	// const matches = useMediaQuery("(min-width:600px)");
	const [currentUser] = useState(cookie.load("current_user"));
	const [propsDatas, setPropsDatas] = useState([]);
	const [formData, setformData] = useState([]);
	const [dcdForm, setDcdData] = useState([]);
	const [assessmentData, setAssessmentData] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [isFound, setIsFound] = useState(false);
	const [formName, setFormName] = useState("");
	const [selectedItem, setSelectedItem] = useState("");
	const [priorECG, setPriorECG] = useState([]);
	const [priorXray, setpriorXray] = useState([]);
	useEffect(() => {
		const propsData = props.history.location.state;
		setPropsDatas(props);

		// Setting up from where it is coming
		setFormName(propsData.comingFor);
		setIsLoading(true);

		if (propsData.selectedItem._id){
			axios
	        .get(`${getSingleEdr}/${propsData.selectedItem._id}`)
	        .then((res) => {

	        	setIsLoading(false);
	        	setIsFound(true);

				let dataReturn = replacePatientIdIfNull(res.data.data);
				console.log('ViewFormHistory::axios::', dataReturn);
				setSelectedItem(dataReturn);

				let dcdFormData = dataReturn.dcdForm[ dataReturn.dcdForm.length - 1 ];
				setDcdData(dcdFormData);

				let FormData = dcdFormData;

				let assessmentData = [];

				if (propsData.comingFor === "Patient Details"){
					assessmentData = FormData.patientDetails;
				}else if (propsData.comingFor === "Past Medical History"){
					assessmentData = FormData.pastMedicalHistory;
				} else if (propsData.comingFor === "Investigations") {
			    	assessmentData = FormData.investigation;
			    } else if (propsData.comingFor === "ROS") {
			    	assessmentData = FormData.ROS;
			    } else if (propsData.comingFor === "Action & Plan") {
			    	assessmentData = FormData.actionPlan;
			    } else if (propsData.comingFor === "Course of Visit") {
			    	assessmentData = FormData.courseOfVisit;
			    } else if (propsData.comingFor === "Physical Exam") {
			    	assessmentData = FormData.physicalExam;
			    }


				console.log('ViewFormHistory::assessmentData::', assessmentData);

				assessmentData.sort((a, b) => {
					console.log(a.date, b.date);
					return new Date(b.date) - new Date(a.date);
				});

				console.log("ViewFormHistory::assessmentData::sort::", assessmentData);

				Object.entries(assessmentData).map(([key, val]) => {
					console.log(key);
					console.log(val);
					assessmentData[key].formatTriageTime = formatDate(val.date)
					assessmentData[key].versionCode = 'Verson #' + val.version.toString()
					assessmentData[key].reasonToEdit = (val.reason.toString() === "" ? '': val.reason.toString())
					assessmentData[key].staff = val.updatedBy.name[0].given[0] + " " + val.updatedBy.name[0].family + " / " + val.updatedBy.staffType.toString() + (val.updatedBy.subType.toString()!=""? ' (' + val.updatedBy.subType.toString() + ')':'');
		    	});
				console.log("ViewFormHistory::last::", '======================================');

				setAssessmentData(assessmentData);


	          return res;
	        })
	        .catch((e) => {
	        	setIsLoading(false);
	        	setIsFound(false);
	        	console.log("ViewFormHistory::axios::error::", e);
	        });
		}


		// console.log('ViewFormHistory::selectedItem', propsData.selectedItem);
		// setFormName(propsData.comingFor);
		// setSelectedItem(propsData.selectedItem);
		// setDcdData(propsData.dcdForm);
		// const FormData = propsData.dcdForm[0];
		// console.log(currentUser, "current user");
		// console.log("ViewFormHistory::propsData::", propsData);
		// console.log("ViewFormHistory::FormData::", FormData.assessmentData);

		// console.log('ViewFormHistory', '======================================');
		// console.log('ViewFormHistory::formName::', '======================================');

		
	}, []);



	function handleViewSingleTriage(triageObj) {
		console.log(triageObj);

		let mForm = [{}];

		if (formName === "Triage & Assessment") {
	      mForm = [{triageAssessment: [triageObj]}];
	    }else if (formName === "Patient Details") {
	      mForm = [{patientDetails: [triageObj]}];
	    }else if (formName === "Past Medical History") {
	      mForm = [{pastMedicalHistory: [triageObj]}];
	    }else if (formName === "ROS") {
	      mForm = [{ROS: [triageObj]}];
	    }else if (formName === "Action & Plan") {
	      mForm = [{actionPlan: [triageObj]}];
	    }else if (formName === "Course of Visit") {
	      mForm = [{courseOfVisit: [triageObj]}];
	    }else if (formName === "Investigations") {
	      mForm = [{investigation: [triageObj]}];
	    }else if (formName === "Physical Exam") {
	      mForm = [{physicalExam: [triageObj]}];
	    }

        let path;
        path = "/dashboard/home/dcddashboard/viewForm";
        let dataAlreadyFilled = true;

        console.log(propsDatas);

        console.log(path, {
			comingFor: formName,
			selectedItem: selectedItem,
			dcdForm: mForm,
			patientEdrForm: triageObj,
			careStream: "",
			dataAlreadyFilled,
		})
		propsDatas.history.push({
			pathname: path,
			state: {
			  comingFor: formName,
			  selectedItem: selectedItem,
			  dcdForm: mForm,
			  patientEdrForm: triageObj,
			  careStream: "",
			  dataAlreadyFilled,
			},
		});
	}

	const handleAddNewTriage = () => {
		props.history.push({
			pathname: "/dashboard/home/dcddashboard/Form",
			state: {
				comingFor: formName,
				selectedItem: selectedItem,
			},
		});
	};


	return (
		<div
			style={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        position: "fixed",
        width: "100%",
        height: "100%",
        backgroundColor: "rgb(19 213 159)",
        overflow: "auto",
        paddingBottom: "60px",
      }}
		>
			<Header history={props.history} />

      <PatientDetailsQuick
        edrId={selectedItem._id} />

			<div className="cPadding">
				<div
		          className="subheader"
		          style={{ marginLeft: "-15px", marginBottom: 15 }}
		        >
					<div className="col-md-6 col-8">
						<img
							onClick={() => props.history.goBack()}
							src={Back}
							style={{ width: 45, height: 40, cursor: "pointer" }}
							alt="Alternate"
							/>
						<img src={PatientDetailIcon} alt="PatientDetailIcon" />
						<h4
							style={{
								color: "#000000",
								fontSize: 34,
								fontWeight: "bolder",
							}}
							>{formName}</h4>
					</div>
					<div className="col-md-6 col-sm-6 col-6 d-flex justify-content-end">
							<Button
								variant="contained"
								color="default"
								onClick={handleAddNewTriage}
							>Add New</Button>
						</div>
				</div>
				{/*
				<PatientDetails
		            edrId={selectedItem._id}
		            showChiefComplaint={true}
		            showTriageLevel={true}
		            showCareStream={true}
		            showDiagnosis={true} />
		        	*/}
				<div className="container-fluid">
					<div className="row">
					{
						isLoading===true?
    					<div style={{ margin: "auto", width: "0%", padding: 10 }}><Loader type="ThreeDots" color="blue" height={50} width={50} /></div>:(
    						isFound===true?
							<CustomTable
								tableData={assessmentData}
								tableDataKeys={tableDataKeysForTriage}
								tableHeading={tableHeadingForTriage}
								action={actions}
								borderBottomColor={"#60d69f"}
								borderBottomWidth={20}
								handleView={handleViewSingleTriage}
								/>:
							<div className="col-md-12"><div className="card card-body">No Record Found</div></div>
    					)
					}
					</div>
				</div>
			</div>
		</div>
	);
}
export default ViewFormHistory;