import React, { useEffect, useState } from "react";
import cookie from "react-cookies";
import axios from "axios";
import Chip from "@material-ui/core/Chip";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';

import DownloadIcon from "../../../assets/img/Download Icon.png";
import PrintIcon from "../../../assets/img/Print Icon.png";
import Back from "../../../assets/img/Back_Arrow_black.png";
import Header from "../../../components/Header/HeaderGrey";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import DetailBlock from "../../../components/DHR/DCD/ViewScreen/detailBlock";
import DetailBlockForDCD from "../../../components/DHR/DCD/ViewScreen/detailBlockForDCD";
import formatDate from "../../../utils/formatDate";
import { uploadsUrl } from "../../../public/endpoins";
// Icons
import PatientDetailIcon from "../../../assets/img/Patient Details Icon.png";
import PastMedicalIcon from "../../../assets/img/Past Medical History Icon.png";
import InvestigationIcon from "../../../assets/img/Investigations Icon.png";
import PhysicalExamIcon from "../../../assets/img/Physical Exam Icon.png";
import ROSIcon from "../../../assets/img/ROS Icon.png";
import ActionPlanIcon from "../../../assets/img/Action & Plan Icon.png";
import covIcon from "../../../assets/img/Course Of Visit Icon.png";
import TriageAndAssessmentIcon from "../../../assets/img/Triage & Assessment Icon.png";

import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import Fab from "@material-ui/core/Fab";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";

import Notification from "../../../components/Snackbar/Notification.js";
import moment from "moment";
import CustomTable from "../../../components/Table/Table";
import ReportModel from "../../../components/ReportModel/ReportModel";
import { audioURL, getIcd, getSingleEdr, updateLifeLimbEdr } from "../../../public/endpoins";
import { displayInHumanDifference, replacePatientIdIfNull } from "../../../utils/commonFunc";
import useStylesForInput from "../../../assets/jss/material-dashboard-react/inputStyle";


const capitalizeTextString = (text) =>{
    return text.toLowerCase().charAt(0).toUpperCase()+(text.slice(1).toLowerCase())
}

const stylesx = {
  ROSRowPadding: {
    marginLeft: 0,
    marginRight: 0,
    backgroundColor: "white",
    marginTop: 10,
    padding: "10px 0px 10px 0px",
    borderRadius: 5,
  },
  chipAlignment: {
    display: "inline-block",
    padding: "5px",
  },
  save: {
    color: "white",
    cursor: "pointer",
    borderRadius: 5,
    backgroundColor: "#2c6ddd",
    width: "115px",
    height: "40px",
    outline: "none",
  },
};

const styles = {
  save: {
    cursor: "pointer",
    borderRadius: 5,
    backgroundColor: "#2973CF",
    width: "180px",
    height: "50px",
    outline: "none",
    color: "white",
  },
};

function ActionButtons(props) {
  return (
    <>
      {props.totalCount ? (
        <>
          <Fab
            color="primary"
            size="small"
            style={{ marginRight: 5 }}
            onClick={() => props.changeCount(props.indexValue, "dec")}
          >
            <ArrowRightIcon
              style={{ fontSize: 40, color: "white" }}
            />
          </Fab>

          <span style={{ fontSize: 12 }}>
            {" "}
            {props.totalCount - props.currentCount + 1}/ {props.totalCount}
          </span>
          <Fab
            color="primary"
            size="small"
            style={{ marginLeft: 5 }}
            onClick={() => props.changeCount(props.indexValue, "inc")}
          >
            <ArrowLeftIcon
              style={{ fontSize: 40, color: "white" }}
            />
          </Fab>
        </>
      ) : (
        undefined
      )}
    </>
  );
}

const totalCountArray = [0, 0, 0, 0, 0, 0, 0, 0];

function ViewNewDCDForm(props) {
  const matches = useMediaQuery("(min-width:600px)");
  const classes = useStylesForInput();

  const [currentUser] = useState(cookie.load("current_user"));
  const [priorECG, setPriorECG] = useState([]);
  const [priorXray, setpriorXray] = useState([]);
  const [patientDetailsData, setpatientDetailsData] = useState([]);
  const [pastMedicalData, setpastMedicalData] = useState([]);
  const [rosData, setrosData] = useState([]);
  const [physicalExamData, setphysicalExamData] = useState([]);
  const [investigationsData, setinvestigationsData] = useState([]);
  const [actionPlanData2, setactionPlanData2] = useState([]);
  const [actionPlanData, setactionPlanData] = useState([]);
  const [covData, setcovData] = useState([]);
  const [triageData, settriageData] = useState([]);
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setsuccessMsg] = useState("");
  const [openNotification, setOpenNotification] = useState(false);

  const [allDoctorNotes, setAllDoctorNotes] = useState([]);
  const [allLabReports, setAllLabReports] = useState([]);
  const [allRadReports, setAllRadReports] = useState([]);
  const [allConsultationReports, setAllConsultationReports] = useState([]);
  const [allPharmaReports, setAllPharmaReports] = useState([]);
  const [allNonPharmaReports, setAllNonPharmaReports] = useState([]);
  const [allDischargeReports, setAllDischargeReports] = useState([]);


  const [dischargeDispositionStatus, setdischargeDispositionStatus] = useState("");
  const [dischargeDisposition, setdischargeDisposition] = useState("");
  const [dischargeDispositionType, setdischargeDispositionType] = useState("");
  const [dischargeDispositionRemarks, setdischargeDispositionRemarks] = useState("");
  const [dischargeDispositionFollowup, setdischargeDispositionFollowup] = useState("");



  const [openViewReportsModal, setOpenViewReportsModal] = useState(false);
  const [reportData, setReportData] = useState("");

  const [selectedRecord, setSelectedItem] = useState({})
  const [dcdForm, setDCDForm] = useState({})

  const [count, setCount] = useState([1, 1, 1, 1, 1, 1, 1, 1]);

  const [ episodeId, setEpisodeId ] = useState(props.match.params.id);

  const printDiagnosis = (v) => {
    let dataCode = [];
    Object.entries(v).map(([key, val]) => {
      console.log('FUCKED', val);
      dataCode.push(val.short_desc);
    });
    return dataCode.join(', ');
  }

  function setData(mSelectedRecord, mDCDForm) {
    console.log("ViewNewDCDForm::mSelectedRecord", mSelectedRecord);
    console.log("ViewNewDCDForm::mDCDForm", mDCDForm);

    Object.entries(mDCDForm).map(([key, val]) => {
      if (key === "triageAssessment" && val.length > 0) {
        const selectedTriage = val[val.length - count[0]];
        totalCountArray[0] = val.length;




        let triageLevelCheck = [
          {
            subheading: "Triage Level (System)",
            description: selectedTriage.triageLevel.toString(),
          },
        ];
        if (selectedTriage.newTriageLevel && selectedTriage.newTriageLevel !== ""){
          triageLevelCheck.push({
            subheading: "Triage Level (Override)",
            description: selectedTriage.newTriageLevel.toString(),
          });
          triageLevelCheck.push({
            subheading: "Triage Level (Override) - Reason",
            description: selectedTriage.reasonChangeTriageLevel.toString(),
          });
        }

        let calcBMIText = (BMICalc) => {
          let BMIRangeText = "Healthy";
          if (BMICalc < 18.5){
            BMIRangeText = "Underweight";
          } else if (BMICalc >= 18.5 && BMICalc <= 24.9){
            BMIRangeText = "Healthy";
          } else if (BMICalc >= 25 && BMICalc <= 29.9){
            BMIRangeText = "Overweight";
          } else if (BMICalc >= 30){
            BMIRangeText = "Obese";
          }
          return BMIRangeText;
        }

        let arr = [
          {
            heading: "Triage History",
            columnSize: 4,
            subArray: [
              {
                subheading: "Date/Time",
                description: formatDate(selectedTriage.triageTime),
              },
              // {
              //   subheading: "Doctor/Staff",
              //   description: mDCDForm.doctor,
              // },
              {
                subheading: "Triage No.",
                description: selectedTriage.triageRequestNo,
              },
            ],
          },
          {
            heading: "Triage Level",
            columnSize: 1,
            subArray: triageLevelCheck,
          },
          {
            heading: "Patient Vitals",
            columnSize: 4,
            subArray: [
              {
                subheading: "Heart Rate",
                description:
                  selectedTriage.heartRate !== "N/A"
                    ? selectedTriage.heartRate + " bpm"
                    : "",
              },
              {
                subheading: "Blood Pressure Systolic",
                description:
                  selectedTriage.bloodPressureSys !== "N/A"
                    ? selectedTriage.bloodPressureSys + " mmHg"
                    : "",
              },
              {
                subheading: "Blood Pressure Diastolic",
                description:
                  selectedTriage.bloodPressureDia !== "N/A"
                    ? selectedTriage.bloodPressureDia + " mmHg"
                    : "",
              },
              {
                subheading: "Respiratory Rate",
                description:
                  selectedTriage.respiratoryRate !== "N/A"
                    ? selectedTriage.respiratoryRate + " /min"
                    : "",
              },
              {
                subheading: "Temperature",
                description:
                  selectedTriage.temperature !== "N/A"
                    ? selectedTriage.temperature + " °C"
                    : "",
              },
              {
                subheading: "Blood Sugar Level",
                description:
                  selectedTriage.FSBS !== "N/A"
                    ? selectedTriage.FSBS + " mg/dL"
                    : "",
              },
              {
                subheading: "Pain Scale",
                description:
                  selectedTriage.painScale !== "N/A"
                    ? selectedTriage.painScale
                    : "",
              },
              {
                subheading: "Pulse OX",
                description:
                  selectedTriage.pulseOX !== "N/A"
                    ? selectedTriage.pulseOX + " SpO2"
                    : "",
              },
              {
                subheading: "Weight",
                description:
                  selectedTriage.weight !== "N/A"
                    ? selectedTriage.weight + ""
                    : "",
              },
              {
                subheading: "Size",
                description:
                  selectedTriage.SIZE !== "N/A"
                    ? selectedTriage.SIZE + ""
                    : "",
              },
              {
                subheading: "BMI",
                description:
                  selectedTriage.BMI !== "N/A"
                    ? selectedTriage.BMI + ""
                    : "",
              },
              {
                subheading: "Body Mass Index (BMI)",
                description:
                  selectedTriage.BMI !== "N/A"
                    ? calcBMIText(selectedTriage.BMI) + ""
                    : "",

              },
              {
                subheading: "Intraocular Pressure (IOP) - Left",
                description:
                  selectedTriage.IOP !== "N/A"
                    ? selectedTriage.IOP + ""
                    : "",
              },
              {
                subheading: "Intraocular Pressure (IOP) - Right",
                description:
                  selectedTriage.IOPRight !== "N/A"
                    ? selectedTriage.IOPRight + ""
                    : "",
              },
            ],
          },
          {
            heading: "Physical Examination",
            columnSize: 4,
            subArray: [
              {
                subheading: "General Appearance",
                description: selectedTriage.generalAppearance.toString(),
              },
              {
                subheading: "Respiratory",
                description: selectedTriage.respiratory.toString(),
              },
              {
                subheading: "Cardiac",
                description: selectedTriage.cardiac.toString(),
              },
              {
                subheading: "Abdomen",
                description: selectedTriage.abdomen.toString(),
              },
              {
                subheading: "Neurological",
                description: selectedTriage.neurological.toString(),
              },
              {
                subheading: "Glasgow Coma Scale/Score",
                description: selectedTriage.gcsScore.toString(),
              }
            ],
          },
        ];
        //UncommentMe
        settriageData(arr);
      }

      if (key === "patientDetails" && val.length > 0) {
        let arr = [],
          subArray = [];
        const details = val[val.length - count[1]].details;
        totalCountArray[1] = val.length;

        arr = [
          {
            heading: "Patient Details",
            columnSize: 4,
            subArray: [
              {
                subheading: "Time Seen",
                description: new Date().getHours() + ":" + new Date().getMinutes(),
              },
              {
                subheading: "Room",
                description:
                  mSelectedRecord.room &&
                  mSelectedRecord.room.length > 0
                    ? mSelectedRecord.room[
                        mSelectedRecord.room.length - 1
                      ].roomId.roomNo
                    : "Not Selected",
              },
              {
                subheading: "Age",
                description: mSelectedRecord.patientId.age,
              },
              {
                subheading: "Gender",
                description: mSelectedRecord.patientId.gender,
              },
              {
                subheading: "Nationality",
                description: mSelectedRecord.patientId.nationality,
              },
            ]
          }
        ]


        subArray = [];
        for (let i = 0; i < details.length; i++) {
          let chips = details[i].chips;
          let value = details[i].value;
          let texts = details[i].Texts;
          let Dropdown = details[i].DropDowns;
          let allergiesValues = details[i].values;
          let allergiesOtherDetails = details[i].detail;


          if(details[i].name == "Severity"){
            subArray = [
              ...subArray,
              {
                subheading: details[i].name,
                description:
                  value <= 4
                    ? "Mild (" + value + ")"
                    : value <= 7
                    ? "Moderate (" + value + ")"
                    : "Severe (" + value + ")",
              },
            ];
          }else if(details[i].name == "Duration / Started"){

            Object.entries(Dropdown).map(([v, vv]) => {
              subArray = [
                ...subArray,
                {
                  subheading: vv.name,
                  description: vv.value
                },
              ];
            })
          }else{
            if (value){
              subArray = [
                ...subArray,
                {
                  subheading: details[i].name,
                  description: value,
                },
              ];
            }
            console.log("detailsI", details[i]);
            for (let j = 0; j < chips.length; j++) {
              let subChips = chips[j].subChips ? chips[j].subChips : [];
              if (subChips.length > 0) {
                for (let k = 0; k < subChips.length; k++) {
                  // if (subChips[k].selected === true) {
                  subArray = [
                    ...subArray,
                    {
                      subheading: chips[j].name,
                      description: chips[j].detail === "" ? " " : chips[j].detail,
                      subheadingType: subChips[k].name,
                    },
                  ];
                  // }
                }
              } else {
                console.log("chips[j]: ", chips[j]);
                subArray = [
                  ...subArray,
                  {
                    subheading:
                      chips[j].name +
                      `${
                        chips[j].selectedMedicines &&
                        chips[j].selectedMedicines.length
                          ? "(" + chips[j].selectedMedicines + ")"
                          : ""
                      }`,
                    description: chips[j].detail === "" ? " " : chips[j].detail,
                  },
                ];
              }

              if (chips[j].audio !== ""){
                subArray = [
                  ...subArray,
                  {
                    subheading: " ", //chip[j].name,
                    audio: (uploadsUrl.replace('uploads/', '')) + chips[j].audio
                  },
                ];
              }
            }
            for (let a = 0; a < texts.length; a++) {
              subArray = [
                ...subArray,
                {
                  subheading: details[i].name,
                  description: texts[a].value,
                },
              ];
            }
            for (let a = 0; a < allergiesValues.length; a++) {
              subArray = [
                ...subArray,
                {
                  subheading: details[i].name,
                  // description: allergiesValues[a].code + ": " + allergiesValues[a].name,
                  description: allergiesValues[a].name,
                },
              ];
            }

            if (details[i].voiceNotes) {
              if (details[i].voiceNotes.audio){
                subArray = [
                  ...subArray,
                  {
                    subheading: "",
                    audio: (uploadsUrl.replace('uploads/', '')) + details[i].voiceNotes.audio,
                  },
                ];
              }
              if (details[i].voiceNotes.notes){
                subArray = [
                  ...subArray,
                  {
                    subheading: "Additional Notes",
                    description: details[i].voiceNotes.notes,
                  },
                ];
              }
            }
          }
          arr = [
            ...arr,
            {
              heading: details[i].name,
              columnSize: 4,
              subArray:
                subArray.length > 0
                  ? subArray
                  : (subArray = [
                      {
                        subheading: "Section Not Filled Properly !",
                        description: " ",
                      },
                    ]),
            },
          ];
          subArray = [];
        }




        //UncommentMe
        setpatientDetailsData(arr);
      } else if (key === "pastMedicalHistory" && val.length > 0) {
        let arr = [],
          subArray = [];
        const details = val[val.length - count[2]].details;
        totalCountArray[2] = val.length;

        for (let i = 0; i < details.length; i++) {
          let chips = details[i].chips;
          let texts = details[i].Texts;
          let allergiesValues = details[i].values;
          let allergiesOtherDetails = details[i].detail;

          for (let j = 0; j < chips.length; j++) {
            let subChips = chips[j].subChips;
            if (subChips.length > 0) {
              for (let k = 0; k < subChips.length; k++) {
                if (subChips[k].selected === true) {
                  subArray = [
                    ...subArray,
                    {
                      subheading: chips[j].name,
                      description:
                        chips[j].detail === "" ? " " : chips[j].detail,
                      subheadingType: subChips[k].name,
                    },
                  ];
                }
              }
            } else {
              subArray = [
                ...subArray,
                {
                  subheading: chips[j].name,
                  description: chips[j].detail === "" ? " " : chips[j].detail,
                },
              ];
            }
          }
          for (let a = 0; a < texts.length; a++) {
            subArray = [
              ...subArray,
              {
                subheading: details[i].name,
                description: texts[a].value,
              },
            ];
          }
          for (let a = 0; a < allergiesValues.length; a++) {
            subArray = [
              ...subArray,
              {
                subheading: details[i].name,
                // description: allergiesValues[a].code + ": " + allergiesValues[a].name,
                description: allergiesValues[a].name,
              },
            ];
          }
          arr = [
            ...arr,
            {
              heading: details[i].name,
              columnSize: 1,
              subArray:
                subArray.length > 0
                  ? subArray
                  : (subArray = [
                      {
                        subheading: "Section Not Filled Properly !",
                        description: " ",
                      },
                    ]),
            },
          ];
          subArray = [];
        }

        console.log('ViewNewDCDForm::objForLabTests::arr::', arr);
        //UncommentMe
        setpastMedicalData(arr);
      } else if (key === "ROS" && val.length > 0) {
        let arr = [],
          subArray = [];
        const details = val[val.length - count[3]].details;

        totalCountArray[3] = val.length;

        for (let i = 0; i < details.length; i++) {
          let chip = details[i].chips;
          for (let j = 0; j < chip.length; j++) {
            subArray = [
              ...subArray,
              {
                subheading: chip[j].name,
                description: " ",
              },
            ];
          }
          arr = [
            ...arr,
            {
              heading: details[i].name,
              columnSize: 1,
              subArray: subArray,
            },
          ];
          subArray = [];
        }
        setrosData(arr);
      } else if (key === "physicalExam" && val.length > 0) {
        let arr = [],
          subArray = [];
        const details = val[val.length - count[4]].details;
        totalCountArray[4] = val.length;

        for (let i = 0; i < details.length; i++) {
          let chips = details[i].chips;

          if (details[i].scale && details[i].scale.name) {
            subArray = [
              ...subArray,
              {
                subheading: details[i].scale.name,
                description:
                  details[i].scale.value <= 4
                    ? "Mild (" + details[i].scale.value + ")"
                    : details[i].scale.value <= 7
                    ? "Moderate (" + details[i].scale.value + ")"
                    : "Severe (" + details[i].scale.value + ")",
              },
            ];
          }
          if (chips && chips.length > 0) {
            for (let j = 0; j < chips.length; j++) {
              let subChips = chips[j].subChips;
              let right = chips[j].right;
              let left = chips[j].left;
              let imageMarkers = chips[j].imageMarkers;

              if (subChips && subChips.length > 0) {
                for (let k = 0; k < subChips.length; k++) {
                  if (subChips[k].selected === true) {
                    subArray = [
                      ...subArray,
                      {
                        subheading: chips[j].name,
                        description:
                          chips[j].detail === "" ? " " : chips[j].detail,
                        subheadingType: subChips[k].name,
                      },
                    ];
                  }
                }
              } else if (
                (right && right.length > 0) ||
                (left && left.length > 0) ||
                (imageMarkers && imageMarkers.length > 0)
              ) {
                subArray = [
                  ...subArray,
                  {
                    subheading: chips[j].name,
                    description: chips[j].detail === "" ? " " : chips[j].detail,
                    detailsL: left,
                    detailsR: right,
                    detailsImageMarkers: imageMarkers,
                  },
                ];
              } else if (chips[j].name === "Add Skin Report") {
                subArray = [
                  ...subArray,
                  {
                    images: chips[j].image,
                  },
                ];
              } else {
                subArray = [
                  ...subArray,
                  {
                    subheading: chips[j].name,
                    description:
                      !chips[j].detail || chips[j].detail === ""
                        ? " "
                        : chips[j].detail,
                  },
                ];
              }
            }
          }
          arr = [
            ...arr,
            {
              heading: details[i].name,
              columnSize: details[i].name === "Physical Exam" ? 2 : 1,
              subArray:
                subArray.length > 0
                  ? subArray
                  : (subArray = [
                      {
                        subheading: "Section Not Filled Properly !",
                        description: " ",
                      },
                    ]),
            },
          ];
          subArray = [];
        }
        setphysicalExamData(arr);
      } else if (key === "investigation" && val.length > 0) {
        let arr = [],
          subArray = [];
        const details = val[val.length - count[5]].details;
        totalCountArray[5] = val.length;

        for (let i = 0; i < details.length; i++) {
          let options = [];
          let chips = details[i].chips;
          let texts = details[i].Texts;

          if (chips && chips.length > 0) {
            for (let j = 0; j < chips.length; j++) {
              if (
                chips[j].name === "Add ECG Report" ||
                chips[j].name === "Add CXR Report"
              ) {
                if (chips[j].name === "Add ECG Report") {
                  setPriorECG(chips[j].image);
                } else if (chips[j].name === "Add CXR Report") {
                  setpriorXray(chips[j].image);
                }
                subArray = [
                  ...subArray,
                  {
                    images: chips[j].image,
                  },
                ];
              } else if (
                chips[j].name === "Normal" ||
                chips[j].name === "Nml Except"
              ) {
                options.push(chips[j].name);
              } else {
                subArray = [
                  ...subArray,
                  {
                    subheading: chips[j].name,
                    description:
                      !chips[j].detail || chips[j].detail === ""
                        ? " "
                        : chips[j].detail,
                  },
                ];
              }
            }
          }
          if (texts && texts.length > 0) {
            for (let a = 0; a < texts.length; a++) {
              subArray = [
                ...subArray,
                {
                  subheading: texts[a].name,
                  description: texts[a].value,
                },
              ];
            }
          }
          arr = [
            ...arr,
            {
              heading:
                options.length > 0
                  ? details[i].name + " (" + options.map((d) => d) + ")"
                  : details[i].name,
              columnSize:
                details[i].name === "CBC" ||
                details[i].name === "Chemistries" ||
                details[i].name === "UA"
                  ? 3
                  : 1,
              subArray:
                subArray.length > 0
                  ? subArray
                  : (subArray = [
                      {
                        subheading: "Section Not Filled Properly !",
                        description: " ",
                      },
                    ]),
            },
          ];
          subArray = [];
        }
        setinvestigationsData(arr);
      } else if (key === "actionPlan" && val.length > 0) {
        let arr = [],
          subArray = [];
        const details = val[val.length - count[6]].details;
        totalCountArray[6] = val.length;

        // Differential Diagnosis
        subArray = [...subArray, { subheading: 'Action', description: capitalizeTextString(details.differentialDiagnosisRemarks) }];
        if (details.differentialDiagnosisVoiceNotes){
          subArray = [...subArray, { subheading: '', audio: (uploadsUrl.replace('uploads/', '')) + details.differentialDiagnosisVoiceNotes }];
        }
        subArray = [...subArray, { subheading: 'Plan', description: capitalizeTextString(details.differentialDiagnosisPlanRemarks) }];
        if (details.differentialDiagnosisPlanVoiceNotes){
          subArray = [...subArray, { subheading: '', audio: (uploadsUrl.replace('uploads/', '')) + details.differentialDiagnosisPlanVoiceNotes }];
        }
        arr = [ ...arr, { heading: 'Notes/Differential Diagnosis', columnSize: 1, subArray: subArray } ];
        subArray = [];


        // O2 Management
        subArray = [...subArray, { subheading: 'Status', description: capitalizeTextString(details.o2Check) }];
        subArray = [...subArray, { subheading: 'Action:', description: details.o2Remarks }];
        if ( details.o2VoiceNotes ){
          subArray = [...subArray, { subheading: '', audio: (uploadsUrl.replace('uploads/', '')) + details.o2VoiceNotes }];
        }
        subArray = [...subArray, { subheading: 'Plan:', description: details.o2PlanRemarks }];
        if ( details.o2PlanVoiceNotes ){
          subArray = [...subArray, { subheading: '', audio: (uploadsUrl.replace('uploads/', '')) + details.o2PlanVoiceNotes }];
        }
        arr = [ ...arr, { heading: 'O2', columnSize: 1, subArray: subArray } ];
        subArray = [];


        // IV Fluid Management
        subArray = [...subArray, { subheading: 'Status', description: capitalizeTextString(details.ivFluidsCheck) }];
        subArray = [...subArray, { subheading: 'Action', description: details.ivFluidsRemarks }];
        if ( details.ivFluidsVoiceNotes ){
          subArray = [...subArray, { subheading: '', audio: (uploadsUrl.replace('uploads/', '')) + details.ivFluidsVoiceNotes }];
        }
        subArray = [...subArray, { subheading: 'Plan', description: details.ivFluidsPlanRemarks }];
        if ( details.ivFluidsPlanVoiceNotes ){
          subArray = [...subArray, { subheading: '', audio: (uploadsUrl.replace('uploads/', '')) + details.ivFluidsPlanVoiceNotes }];
        }
        arr = [ ...arr, { heading: 'IV Fluids', columnSize: 1, subArray: subArray } ];
        subArray = [];


        // Medication Management
        subArray = [...subArray, { subheading: 'Status', description: capitalizeTextString(details.medicationsCheck) }];
        subArray = [...subArray, { subheading: 'Action', description: details.medicationsRemarks }];
        if ( details.medicationsVoiceNotes ){
          subArray = [...subArray, { subheading: '', audio: (uploadsUrl.replace('uploads/', '')) + details.medicationsVoiceNotes }];
        }
        subArray = [...subArray, { subheading: 'Plan', description: details.medicationsPlanRemarks }];
        if ( details.medicationsPlanVoiceNotes ){
          subArray = [...subArray, { subheading: '', audio: (uploadsUrl.replace('uploads/', '')) + details.medicationsPlanVoiceNotes }];
        }
        arr = [ ...arr, { heading: 'Medications', columnSize: 1, subArray: subArray } ];
        subArray = [];


        // Start Pain Management
        subArray = [...subArray, { subheading: 'Status', description: capitalizeTextString(details.painManagementCheck) }];
        subArray = [...subArray, { subheading: 'Action', description: details.painManagementRemarks }];
        if ( details.painManagementVoiceNotes ){
          subArray = [...subArray, { subheading: '', audio: (uploadsUrl.replace('uploads/', '')) + details.painManagementVoiceNotes }];
        }
        subArray = [...subArray, { subheading: 'Plan', description: details.painManagementPlanRemarks }];
        if ( details.painManagementPlanVoiceNotes ){
          subArray = [...subArray, { subheading: '', audio: (uploadsUrl.replace('uploads/', '')) + details.painManagementPlanVoiceNotes }];
        }
        arr = [ ...arr, { heading: 'Start Pain Management', columnSize: 1, subArray: subArray } ];
        subArray = [];


        // Investigation
        subArray = [...subArray, { subheading: 'Status', description: capitalizeTextString(details.investigationsCheck) }];
        subArray = [...subArray, { subheading: 'Action', description: details.investigationsRemarks }];
        if ( details.investigationsVoiceNotes ){
          subArray = [...subArray, { subheading: '', audio: (uploadsUrl.replace('uploads/', '')) + details.investigationsVoiceNotes }];
        }
        subArray = [...subArray, { subheading: 'Plan', description: details.investigationsRemarks }];
        if ( details.investigationsPlanVoiceNotes ){
          subArray = [...subArray, { subheading: '', audio: (uploadsUrl.replace('uploads/', '')) + details.investigationsPlanVoiceNotes }];
        }
        arr = [ ...arr, { heading: 'Investigation', columnSize: 1, subArray: subArray } ];
        subArray = [];



        setactionPlanData(arr);
      } else if (key === "courseOfVisit" && val.length > 0) {
        let arr = [],
          subArray = [];
        const details = val[val.length - count[7]].details;
        totalCountArray[7] = val.length;

        for (let i = 0; i < details.length; i++) {
          let text = details[i].Texts;
          let chip = details[i].chips;
          for (let j = 0; j < text.length; j++) {
            subArray = [
              ...subArray,
              {
                subheading: details[i].name,
                description:
                  text[j].value && text[j].value.length > 0
                    ? text[j].value
                    : "No details entered",
              },
            ];
          }
          for (let j = 0; j < chip.length; j++) {
            subArray = [
              ...subArray,
              {
                subheading: chip[j].name,
                description:
                  chip[j].detail && chip[j].detail.length > 0
                    ? chip[j].detail
                    : " ",
              },
            ];
          }
          arr = [
            ...arr,
            {
              heading: details[i].name,
              columnSize: 1,
              subArray: subArray,
            },
          ];
          subArray = [];
        }
        setcovData(arr);
      }
    });



    let finalDoctorNotes = [];
    if (mSelectedRecord.doctorNotes && mSelectedRecord.doctorNotes.length > 0) {
        let doctorNotesArray = mSelectedRecord.doctorNotes;

        Object.entries(doctorNotesArray).map(([did, d]) => {
          let finalDoctorNote = d;

          finalDoctorNote.assignedTime = d.assignedTime ? moment(finalDoctorNote.assignedTime).format('MMM DD, YYYY LT') : "";
          finalDoctorNote.doctor = d.addedBy.name[0].given[0] + " " + d.addedBy.name[0].family;

          finalDoctorNote.diagnosis = d.code.length;
          finalDoctorNote.diagnosisList = printDiagnosis(d.code);
          finalDoctorNote.diagnosisAdditionalList = printDiagnosis(d.additionalCode);
          finalDoctorNotes.push(finalDoctorNote);
        });
        setAllDoctorNotes(finalDoctorNotes);

        // const doctorNotesSorted = _.sortBy(val, "assignedTime").reverse();
    }



    // Getting all Lab Requests
    let finalLabRequests = [];
    let finalRadRequests = [];
    let finalPharmaRequests = [];
    let finalNonPharmaRequests = [];
    let finalDischargePharmaRequests = [];

    if (mSelectedRecord.labRequest && mSelectedRecord.labRequest.length > 0){
      let labRequestsArray = mSelectedRecord.labRequest;

      Object.entries(labRequestsArray).map(([did, d]) => {
        let finalLabRequest = d;
        finalLabRequest.requester = finalLabRequest.requestedBy && finalLabRequest.requestedBy.name[0].given[0] + " " + finalLabRequest.requestedBy.name[0].family;
        finalLabRequest.requestDate = moment(finalLabRequest.requestedAt).format('MMM DD, YYYY LT');
        Object.entries(finalLabRequest.services).map(([key, val]) => {
          // console.log('ViewNewDCDForm::finalLabRequest::val::', val);
          // finalLabRequest.serviceName = val.serviceId.name;
          // finalLabRequest.serviceCategory = val.serviceId.category;
          // finalLabRequest.serviceStatus = val.status;
          // finalLabRequest.serviceUrgent = val.urgent;
          finalLabRequests.push({
            parentRequest: finalLabRequest,
            subRequest: val
          });
        });
      });
      setAllLabReports(finalLabRequests);
    }


    if (mSelectedRecord.dischargeRequest && mSelectedRecord.dischargeRequest.pharmacyRequestNo && mSelectedRecord.dischargeRequest.pharmacyRequestNo.trim() != ""){
      setdischargeDispositionStatus('yes');
      setdischargeDisposition(mSelectedRecord.dischargeRequest.dischargeSummary.edrCompletionReason); //
      setdischargeDispositionType(mSelectedRecord.dischargeRequest.dischargeSummary.edrAdmissionType)
      setdischargeDispositionRemarks(mSelectedRecord.dischargeRequest.dischargeSummary.dischargeNotes);
      setdischargeDispositionFollowup(mSelectedRecord.dischargeRequest.dischargeSummary.followUpInstruction);
    }



    if (mSelectedRecord.radRequest && mSelectedRecord.radRequest.length > 0){
      let radRequestsArray = mSelectedRecord.radRequest;
      Object.entries(radRequestsArray).map(([did, d]) => {
        let finalRadRequest = d;
        finalRadRequest.requester = finalRadRequest.requestedBy && finalRadRequest.requestedBy.name[0].given[0] + " " + finalRadRequest.requestedBy.name[0].family;
        finalRadRequest.requestDate = moment(finalRadRequest.requestedAt).format('MMM DD, YYYY LT');
        Object.entries(finalRadRequest.services).map(([key, val]) => {
          console.log('ViewNewDCDForm::finalRadRequest::val::', val);
          finalRadRequests.push({
            parentRequest: finalRadRequest,
            subRequest: val
          });
        });
      });
      setAllRadReports(finalRadRequests);
    }

    if (mSelectedRecord.consultationNote && mSelectedRecord.consultationNote.length){
      let consultationNoteArray = mSelectedRecord.consultationNote;
      Object.entries(consultationNoteArray).map(([did, d]) => {
        let finalRadRequest = d;
        d.requesterName = d.addedBy && d.addedBy.name[0].given[0] + " " + d.addedBy.name[0].family;
        d.requestDate = moment(d.requestedAt).format('MMM DD, YYYY LT');
        d.consultantName = d.consultant && d.consultant.name[0].given[0] + " " + d.consultant.name[0].family;
      });
      setAllConsultationReports(consultationNoteArray);
    }

    if (mSelectedRecord.pharmacyRequest && mSelectedRecord.pharmacyRequest.length > 0){
      let pharmacyRequestsArray = mSelectedRecord.pharmacyRequest;
      Object.entries(pharmacyRequestsArray).map(([did, d]) => {
        let finalPharmaRequest = d;
        finalPharmaRequest.requester = finalPharmaRequest.requestedBy && finalPharmaRequest.requestedBy.name[0].given[0] + " " + finalPharmaRequest.requestedBy.name[0].family;
        finalPharmaRequest.requestDate = moment(finalPharmaRequest.createdAt).format('MMM DD, YYYY LT');
        Object.entries(finalPharmaRequest.item).map(([key, val]) => {
          console.log('ViewNewDCDForm::finalPharmaRequest::val::', val);
          console.log('ViewNewDCDForm::finalPharmaRequest::finalPharmaRequest::', finalPharmaRequest);
          if (finalPharmaRequest.generatedFor==="pharmaceutical"){
            finalPharmaRequests.push({
              parentRequest: finalPharmaRequest,
              subRequest: val
            });
          }else if (finalPharmaRequest.generatedFor==="non_pharmaceutical"){
            finalNonPharmaRequests.push({
              parentRequest: finalPharmaRequest,
              subRequest: val
            });
          }else{
            finalDischargePharmaRequests.push({
              parentRequest: finalPharmaRequest,
              subRequest: val
            });
          }
        });
      });
      setAllPharmaReports(finalPharmaRequests);
      setAllNonPharmaReports(finalNonPharmaRequests);
      setAllDischargeReports(finalDischargePharmaRequests);
    }



    // let labRequestArray = [];
    // if (mSelectedRecord.labRequest){
    //   labRequestArray = mSelectedRecord.labRequest;
    // }

    // labRequestArray.map(
    //   (d) => (
    //     (d.requester =
    //       d.requestedBy &&
    //       d.requestedBy.name[0].given[0] +
    //         " " +
    //         d.requestedBy.name[0].family),
    //     // (d.serviceCode = d.serviceId.identifier[0].value),
    //     (d.requestDate = moment(d.requestedAt).format('MMM DD, YYYY LT')),
    //     // (d.serviceName = d.serviceId.category + ": " + d.serviceId.name),
    //     (d.status = d.status)
    //   )
    // );

    // console.log('ViewNewDCDForm::objForLabTests::', labRequestArray);
    

    console.log("ViewNewDCDForm::mSelectedRecord::", mSelectedRecord);
  }

  useEffect(() => {
    setData(selectedRecord, dcdForm);
  }, [count]);

  useEffect(() => {

    // Getting Realtime Data from Screen
    axios
      .get(getSingleEdr + "/" + episodeId)
      .then((res) => {
        console.log('handleView::', res.data.data);
        if (res.data.success) {
          let remoteRecord = replacePatientIdIfNull(res.data.data);
          //

          console.log("ViewNewDCDForm::remoteRecord", remoteRecord);

          setSelectedItem(remoteRecord);
          setDCDForm(remoteRecord.dcdForm[0]);
          setactionPlanData2(remoteRecord.dcdForm[0].actionPlan.reverse())
          setData(remoteRecord, remoteRecord.dcdForm[0]);
        }
      })
      .catch((e) => {
        console.log("error after searching patient request", e);
      });
  }, []);

  const handleSubmit = () => {
    console.log("Submit ");
  };

  function changeCount(index, step) {
    let arr = [...count];
    if (step === "inc") {
      if (arr[index] + 1 > totalCountArray[index]) {
        setOpenNotification(true);
        setErrorMsg("No record found before that record");
        return;
      }
      arr[index] = arr[index] + 1;
    } else {
      if (arr[index] - 1 <= 0) {
        setOpenNotification(true);
        setErrorMsg("No record found after that record");
        return;
      }
      arr[index] = arr[index] - 1;
    }
    setCount(arr);
  }

  if (openNotification) {
    setTimeout(() => {
      setOpenNotification(false);
      setErrorMsg("");
      setsuccessMsg("");
    }, 2000);
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        position: "fixed",
        width: "100%",
        height: "100%",
        overflowY: "scroll",
        border: "1p",
      }}
    >
      <Header history={props.history} />

      <div className="cPadding">
        <div
          className="col-12"
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <img
            style={{ width: 35, height: "inherit", marginRight: 10 }}
            src={DownloadIcon}
          />
          <img style={{ width: 35, height: "inherit" }} src={PrintIcon} />
        </div>
        <div
          className="subheader"
          style={{ marginLeft: "-15px", marginBottom: 45 }}
        >
          <div className="col-7">
            <img src={TriageAndAssessmentIcon} />
            <h4
              style={{
                color: "#000000",
                fontSize: matches ? 32 : 15,
                fontWeight: "bolder",
              }}
            >
              Triage & Assessment
            </h4>
          </div>

          <div className="col-5" style={{ padding: 0 }}>
            <ActionButtons
              changeCount={changeCount}
              indexValue={0}
              totalCount={totalCountArray[0]}
              currentCount={count[0]}
            />
          </div>
        </div>

        <div className="container-fluid">
          {triageData && triageData.length > 0 ? (
            triageData.map((arr) => {
              return (
                <DetailBlock
                  heading={arr.heading}
                  subArray={arr.subArray}
                  columnSize={arr.columnSize}
                />
              );
            })
          ) : (
            <div className="row">
              <div className="col d-flex justify-content-center">
                <span style={{ color: "#2F77D1" }}>
                  <h4>Not Completed</h4>
                </span>
              </div>
            </div>
          )}
        </div>

        <div
          className="subheader"
          style={{ marginLeft: "-15px", marginBottom: 45 }}
        >
          <div className="col-7">
            <img src={PatientDetailIcon} />
            <h4
              style={{
                color: "#000000",
                fontSize: matches ? 32 : 15,
                fontWeight: "bolder",
              }}
            >
              Patient Details
            </h4>
          </div>

          <div className="col-5" style={{ padding: 0 }}>
            <ActionButtons
              changeCount={changeCount}
              indexValue={1}
              totalCount={totalCountArray[1]}
              currentCount={count[1]}
            />
          </div>
        </div>

        <div className="container-fluid">
          {patientDetailsData && patientDetailsData.length > 0 ? (
            patientDetailsData.map((arr) => {
              return (
                <DetailBlockForDCD
                  formName={"Patient Details"}
                  heading={arr.heading}
                  subArray={arr.subArray}
                  columnSize={arr.columnSize}
                />
              );
            })
          ) : (
            <div className="row">
              <div className="col d-flex justify-content-center">
                <span style={{ color: "#2F77D1" }}>
                  <h4>Not Completed</h4>
                </span>
              </div>
            </div>
          )}
        </div>

        <div
          className="subheader"
          style={{ marginLeft: "-15px", marginBottom: 45 }}
        >
          <div className="col-7">
            <img src={PastMedicalIcon} />
            <h4
              style={{
                color: "#000000",
                fontSize: matches ? 32 : 15,
                fontWeight: "bolder",
              }}
            >
              Past Medical History
            </h4>
          </div>

          <div className="col-5" style={{ padding: 0 }}>
            <ActionButtons
              changeCount={changeCount}
              indexValue={2}
              totalCount={totalCountArray[2]}
              currentCount={count[2]}
            />
          </div>
        </div>

        <div className="container-fluid">
          {pastMedicalData && pastMedicalData.length > 0 ? (
            pastMedicalData.map((arr) => {
              return (
                <DetailBlockForDCD
                  formName={"Past Medical History"}
                  heading={arr.heading}
                  subArray={arr.subArray}
                  columnSize={arr.columnSize}
                />
              );
            })
          ) : (
            <div className="row">
              <div className="col d-flex justify-content-center">
                <span style={{ color: "#2F77D1" }}>
                  <h4>Not Completed</h4>
                </span>
              </div>
            </div>
          )}
        </div>

        <div
          className="subheader"
          style={{ marginLeft: "-15px", marginBottom: 45 }}
        >
          <div className="col-7">
            <img src={ROSIcon} />
            <h4
              style={{
                color: "#000000",
                fontSize: matches ? 32 : 15,
                fontWeight: "bolder",
              }}
            >
              ROS
            </h4>
          </div>

          <div className="col-5" style={{ padding: 0 }}>
            <ActionButtons
              changeCount={changeCount}
              indexValue={3}
              totalCount={totalCountArray[3]}
              currentCount={count[3]}
            />
          </div>
        </div>

        <div className="container-fluid">
          {rosData && rosData.length > 0 ? (
            rosData.map((arr) => {
              return (
                <DetailBlockForDCD
                  formName={"ROS"}
                  heading={arr.heading}
                  subArray={arr.subArray}
                  columnSize={arr.columnSize}
                />
              );
            })
          ) : (
            <div className="row">
              <div className="col d-flex justify-content-center">
                <span style={{ color: "#2F77D1" }}>
                  <h4>Not Completed</h4>
                </span>
              </div>
            </div>
          )}
        </div>

        <div
          className="subheader"
          style={{ marginLeft: "-15px", marginBottom: 45 }}
        >
          <div className="col-7">
            <img src={PhysicalExamIcon} />
            <h4
              style={{
                color: "#000000",
                fontSize: matches ? 32 : 15,
                fontWeight: "bolder",
              }}
            >
              Physical Exam
            </h4>
          </div>

          <div className="col-5" style={{ padding: 0 }}>
            <ActionButtons
              changeCount={changeCount}
              indexValue={4}
              totalCount={totalCountArray[4]}
              currentCount={count[4]}
            />
          </div>
        </div>

        <div className="container-fluid">
          {physicalExamData && physicalExamData.length > 0 ? (
            physicalExamData.map((arr) => {
              return (
                <DetailBlockForDCD
                  formName={"Physical Exam"}
                  heading={arr.heading}
                  subArray={arr.subArray}
                  columnSize={arr.columnSize}
                />
              );
            })
          ) : (
            <div className="row">
              <div className="col d-flex justify-content-center">
                <span style={{ color: "#2F77D1" }}>
                  <h4>Not Completed</h4>
                </span>
              </div>
            </div>
          )}
        </div>

        <div
          className="subheader"
          style={{ marginLeft: "-15px", marginBottom: 45 }}
        >
          <div className="col-7">
            <img src={InvestigationIcon} />
            <h4
              style={{
                color: "#000000",
                fontSize: matches ? 32 : 15,
                fontWeight: "bolder",
              }}
            >
              Investigations
            </h4>
          </div>

          <div className="col-5" style={{ padding: 0 }}>
            <ActionButtons
              changeCount={changeCount}
              indexValue={5}
              totalCount={totalCountArray[5]}
              currentCount={count[5]}
            />
          </div>
        </div>

        <div className="container-fluid">
          {/*investigationsData && investigationsData.length > 0 ? (
            investigationsData.map((arr) => {
              return (
                <DetailBlock
                  heading={arr.heading}
                  subArray={arr.subArray}
                  columnSize={arr.columnSize}
                />
              );
            })
          ) : (
            <div className="row">
              <div className="col d-flex justify-content-center">
                <span style={{ color: "#2F77D1" }}>
                  <h4>Not Completed</h4>
                </span>
              </div>
            </div>
          )*/}


          <div className="row">
            <div className="col-md-12 col-sm-12" style={{marginBottom: 15}}><span style={{color: "rgb(47, 119, 209)", fontWeight: 700, fontSize:16}}>Lab Requests:</span></div>
            <div className="col-md-12 col-sm-12">
              <table className="table table-sm table-bordered table-stripped">
                <tr>
                  <th className="text-left bg-light">Date &amp; Time</th>
                  <th width="15%" className="text-left bg-light">Request ID</th>
                  <th width="20%" className="text-left bg-light">Request by</th>
                  <th width="10%" className="text-center bg-light">X-Urgent</th>
                  <th width="25%" className="text-left bg-light">Category/Item</th>
                  <th width="10%" className="text-left bg-light">Status</th>
                </tr>
                {
                  allLabReports && allLabReports.length>0?
                  Object.entries(allLabReports).map(([ai,av]) => {
                    return (
                      <tr>
                        <td>{av.parentRequest.requestDate}</td>
                        <td>{av.parentRequest.requestId}</td>
                        <td>{av.parentRequest.requester}</td>
                        <td className="text-center">{(av.subRequest.urgent==="Yes" || av.subRequest.urgent==="yes")?<DoneIcon />:undefined}</td>
                        <td><strong>{av.subRequest.serviceId.category}:</strong> {av.subRequest.serviceId.name}</td>
                        <td>{av.subRequest.status}</td>
                      </tr>
                    )
                  })
                  :<tr><td className="text-center" colspan="6">No Lab Request Ordered</td></tr>
                }
              </table>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12 col-sm-12" style={{marginBottom: 15}}><span style={{color: "rgb(47, 119, 209)", fontWeight: 700, fontSize:16}}>Rad Requests:</span></div>
            <div className="col-md-12 col-sm-12">
              <table className="table table-sm table-bordered table-stripped">
                <tr>
                  <th className="text-left bg-light">Date &amp; Time</th>
                  <th width="15%" className="text-left bg-light">Request ID</th>
                  <th width="20%" className="text-left bg-light">Request by</th>
                  <th width="10%" className="text-center bg-light">X-Urgent</th>
                  <th width="25%" className="text-left bg-light">Category/Item</th>
                  <th width="10%" className="text-left bg-light">Status</th>
                </tr>
                {
                  allRadReports && allRadReports.length>0?
                  Object.entries(allRadReports).map(([ai,av]) => {
                    return (
                      <tr>
                        <td>{av.parentRequest.requestDate}</td>
                        <td>{av.parentRequest.requestId}</td>
                        <td>{av.parentRequest.requester}</td>
                        <td className="text-center">{(av.subRequest.urgent==="Yes" || av.subRequest.urgent==="yes")?<DoneIcon />:undefined}</td>
                        <td><strong>{av.subRequest.category}:</strong> {av.subRequest.name}</td>
                        <td>{av.subRequest.status}</td>
                      </tr>
                    )
                  })
                  :<tr><td className="text-center" colspan="6">No Rad Request Ordered</td></tr>
                }
              </table>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12 col-sm-12" style={{marginBottom: 15}}><span style={{color: "rgb(47, 119, 209)", fontWeight: 700, fontSize:16}}>Consultation Requests:</span></div>
            <div className="col-md-12 col-sm-12">
              <table className="table table-sm table-bordered table-stripped">
                <tr>
                  <th width="15%" className="text-left bg-light">Date &amp; Time</th>
                  <th width="15%" className="text-left bg-light">Request ID</th>
                  <th className="text-left bg-light">Requested by</th>
                  <th className="text-left bg-light">Consultant</th>
                  <th width="10%" className="text-left bg-light">Status</th>
                </tr>
                {
                  allConsultationReports && allConsultationReports.length>0?
                  Object.entries(allConsultationReports).map(([ai,av]) => {
                    return (
                      <tr>
                        <td>{av.requestDate}</td>
                        <td>{av.consultationNo}</td>
                        <td>{av.requesterName}</td>
                        <td>{av.consultantName} ( {av.speciality} )</td>
                        <td>{av.status}</td>
                      </tr>
                    )
                  })
                  :<tr><td className="text-center" colspan="5">No Consultation Request!</td></tr>
                }
              </table>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12 col-sm-12" style={{marginBottom: 15}}><span style={{color: "rgb(47, 119, 209)", fontWeight: 700, fontSize:16}}>Pharma Items:</span></div>
            <div className="col-md-12 col-sm-12">
              <table className="table table-sm table-bordered table-stripped">
                <tr>
                  <th width="15%" className="text-left bg-light">Date &amp; Time</th>
                  <th width="15%" className="text-left bg-light">Request ID</th>
                  <th className="text-left bg-light">Item</th>
                  <th width="25%" className="text-center bg-light">Request by</th>
                </tr>
                {
                  allPharmaReports && allPharmaReports.length>0?
                  Object.entries(allPharmaReports).map(([ai,av]) => {
                    return (
                      <tr>
                        <td>{av.parentRequest.requestDate}</td>
                        <td>{av.parentRequest.pharmacyRequestNo}</td>
                        <td><strong>{av.subRequest.itemId.itemCode}:</strong> {av.subRequest.itemId.name}</td>
                        <td>{av.parentRequest.requester}</td>
                      </tr>
                    )
                  })
                  :<tr><td className="text-center" colspan="6">No Pharma Request!</td></tr>
                }
              </table>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12 col-sm-12" style={{marginBottom: 15}}><span style={{color: "rgb(47, 119, 209)", fontWeight: 700, fontSize:16}}>Non-Pharma Items:</span></div>
            <div className="col-md-12 col-sm-12">
              <table className="table table-sm table-bordered table-stripped">
                <tr>
                  <th width="15%" className="text-left bg-light">Date &amp; Time</th>
                  <th width="15%" className="text-left bg-light">Request ID</th>
                  <th className="text-left bg-light">Item</th>
                  <th width="25%" className="text-center bg-light">Request by</th>
                </tr>
                {
                  allNonPharmaReports && allNonPharmaReports.length>0?
                  Object.entries(allNonPharmaReports).map(([ai,av]) => {
                    return (
                      <tr>
                        <td>{av.parentRequest.requestDate}</td>
                        <td>{av.parentRequest.pharmacyRequestNo}</td>
                        <td><strong>{av.subRequest.itemId.itemCode}:</strong> {av.subRequest.itemId.name||av.subRequest.itemId.description}</td>
                        <td>{av.parentRequest.requester}</td>
                      </tr>
                    )
                  })
                  :<tr><td className="text-center" colspan="6">No Non-Pharma Request!</td></tr>
                }
              </table>
            </div>
          </div>

          {/*
          
          <CustomTable
            tableData={allLabReports}
            tableDataKeys={[
              'requestId',
              'requestDate',
              'serviceName',
              'requester',
              'priority',
              'status',
            ]}
            tableHeading={[
              'Request ID',
              'Request Date',
              'Bundle/Test',
              'Requester',
              'Priority',
              'Status',
              'Action',
            ]}
            handleView={(item) => {
              console.log('ViewNewDCDForm::objForLabTests::item::', item);
              let pathname = "/dashboard/home/viewLaboratoryReport";
              let labRadStatus = item.status;
              if ( labRadStatus === "completed" ){

                setReportData({
                  subheading: "Lab Reports",
                  description: item.image
                });
                setOpenViewReportsModal(true);
                
                
                // props.history.push({
                //   pathname: pathname,
                //   state: {
                //     mSelectedRecord: {
                //       labRadStatus,
                //       ...item,
                //       ...selectedRecord,
                //     }
                //   },
                // });
                
              }else{
                setOpenNotification(true);
                setErrorMsg("Reports can not be viewed untill completed.");
              }
            }}
            action={{ view: true }}
            borderBottomColor={"#60d69f"}
            borderBottomWidth={20}
          />
          {openViewReportsModal && reportData ? (
            <ReportModel
              reportData={reportData}
              openViewReportsModal={openViewReportsModal}
              handleClose={setOpenViewReportsModal}
            />
          ) : (
            undefined
          )}
          */}
        </div>

        <div
          className="subheader"
          style={{ marginLeft: "-15px", marginBottom: 45 }}
        >
          <div className="col-7">
            <img src={ActionPlanIcon} />
            <h4
              style={{
                color: "#000000",
                fontSize: matches ? 32 : 15,
                fontWeight: "bolder",
              }}
            >
              Action & Plan
            </h4>
          </div>

          <div className="col-5" style={{ padding: 0 }}>
            {/*
            <ActionButtons
              changeCount={changeCount}
              indexValue={6}
              totalCount={totalCountArray[6]}
              currentCount={count[6]}
            />*/}
          </div>
        </div>

        <div className="container-fluid">
          
          <div className="row">
            <div className="col-md-12 col-sm-12" style={{marginBottom: 15}}><span style={{color: "rgb(47, 119, 209)", fontWeight: 700, fontSize:16}}>ED Doctor Diagnosis:</span></div>
            <div className="col-md-12 col-sm-12">
              <table className="table table-sm table-bordered table-stripped">
                <tr>
                  <th width="25%" className="text-left bg-light">Date &amp; Time</th>
                  <th width="15%" className="text-left bg-light">Name of Doctor</th>
                  <th width="30%" className="text-center bg-light">Main Diagnosis</th>
                  <th width="30%" className="text-left bg-light">Additional Diagnosis/Clinical Conditions</th>
                </tr>
                {
                  Object.entries(allDoctorNotes).map(([ai,av]) => {
                    // Object.entries(av.details).map(([ai2,av2]) => {
                      return (
                        <tr>
                          <td>{av.assignedTime}</td>
                          <td>{av.doctor}</td>
                          <td>{av.diagnosisList}</td>
                          <td>{av.diagnosisAdditionalList}</td>
                        </tr>
                      )
                    // })
                  })
                }
              </table>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12 col-sm-12" style={{marginBottom: 15}}><span style={{color: "rgb(47, 119, 209)", fontWeight: 700, fontSize:16}}>Notes/Differential Diagnosis:</span></div>
            <div className="col-md-12 col-sm-12">
              <table className="table table-sm table-bordered table-stripped">
                <tr>
                  <th className="text-left bg-light">Date &amp; Time</th>
                  <th width="15%" className="text-left bg-light">Name of Doctor</th>
                  <th width="15%" className="text-center bg-light">Status</th>
                  <th width="25%" className="text-left bg-light">Action</th>
                  <th width="25%" className="text-left bg-light">Plan</th>
                </tr>
                {
                  Object.entries(actionPlanData2).map(([ai,av]) => {
                    // Object.entries(av.details).map(([ai2,av2]) => {
                      return (
                        <tr>
                          <td>{moment(av.date).format('MMM DD, YYYY LT')}</td>
                          <td></td>
                          <td>{av.reason}</td>
                          <td>{av.details.differentialDiagnosisRemarks}</td>
                          <td>{av.details.differentialDiagnosisPlanRemarks}</td>
                        </tr>
                      )
                    // })
                  })
                }
              </table>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 col-sm-12" style={{marginBottom: 15}}><span style={{color: "rgb(47, 119, 209)", fontWeight: 700, fontSize:16}}>Patient Management (O2):</span></div>
            <div className="col-md-12 col-sm-12">
              <table className="table table-sm table-bordered table-stripped">
                <tr>
                  <th className="text-left bg-light">Date &amp; Time</th>
                  <th width="15%" className="text-center bg-light">Remarks</th>
                  <th width="15%" className="text-center bg-light">Status</th>
                  <th width="25%" className="text-left bg-light">Action</th>
                  <th width="25%" className="text-left bg-light">Plan</th>
                </tr>
                {
                  Object.entries(actionPlanData2).map(([ai,av]) => {
                    return (
                      <tr>
                        <td>{moment(av.date).format('MMM DD, YYYY LT')}</td>
                        <td>{av.reason}</td>
                        <td className="text-center">{av.details.o2Check==="yes"?<DoneIcon />:<CloseIcon />}</td>
                        <td>{av.details.o2Remarks}</td>
                        <td>{av.details.o2PlanRemarks}</td>
                      </tr>
                    )
                  })
                }
              </table>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 col-sm-12" style={{marginBottom: 15}}><span style={{color: "rgb(47, 119, 209)", fontWeight: 700, fontSize:16}}>Patient Management (IV Fluids):</span></div>
            <div className="col-md-12 col-sm-12">
              <table className="table table-sm table-bordered table-stripped">
                <tr>
                  <th className="text-left bg-light">Date &amp; Time</th>
                  <th width="15%" className="text-center bg-light">Remarks</th>
                  <th width="15%" className="text-center bg-light">Status</th>
                  <th width="25%" className="text-left bg-light">Action</th>
                  <th width="25%" className="text-left bg-light">Plan</th>
                </tr>
                {
                  Object.entries(actionPlanData2).map(([ai,av]) => {
                    return (
                      <tr>
                        <td>{moment(av.date).format('MMM DD, YYYY LT')}</td>
                        <td>{av.reason}</td>
                        <td className="text-center">{av.details.ivFluidsCheck==="yes"?<DoneIcon />:<CloseIcon />}</td>
                        <td>{av.details.ivFluidsRemarks}</td>
                        <td>{av.details.ivFluidsPlanRemarks}</td>
                      </tr>
                    )
                  })
                }
              </table>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 col-sm-12" style={{marginBottom: 15}}><span style={{color: "rgb(47, 119, 209)", fontWeight: 700, fontSize:16}}>Patient Management (Medications):</span></div>
            <div className="col-md-12 col-sm-12">
              <table className="table table-sm table-bordered table-stripped">
                <tr>
                  <th className="text-left bg-light">Date &amp; Time</th>
                  <th width="15%" className="text-center bg-light">Remarks</th>
                  <th width="15%" className="text-center bg-light">Status</th>
                  <th width="25%" className="text-left bg-light">Action</th>
                  <th width="25%" className="text-left bg-light">Plan</th>
                </tr>
                {
                  Object.entries(actionPlanData2).map(([ai,av]) => {
                    return (
                      <tr>
                        <td>{moment(av.date).format('MMM DD, YYYY LT')}</td>
                        <td>{av.reason}</td>
                        <td className="text-center">{av.details.medicationsCheck==="yes"?<DoneIcon />:<CloseIcon />}</td>
                        <td>{av.details.medicationsRemarks}</td>
                        <td>{av.details.medicationsPlanRemarks}</td>
                      </tr>
                    )
                  })
                }
              </table>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 col-sm-12" style={{marginBottom: 15}}><span style={{color: "rgb(47, 119, 209)", fontWeight: 700, fontSize:16}}>Patient Management (Start Pain Management):</span></div>
            <div className="col-md-12 col-sm-12">
              <table className="table table-sm table-bordered table-stripped">
                <tr>
                  <th className="text-left bg-light">Date &amp; Time</th>
                  <th width="15%" className="text-center bg-light">Remarks</th>
                  <th width="15%" className="text-center bg-light">Status</th>
                  <th width="25%" className="text-left bg-light">Action</th>
                  <th width="25%" className="text-left bg-light">Plan</th>
                </tr>
                {
                  Object.entries(actionPlanData2).map(([ai,av]) => {
                    return (
                      <tr>
                        <td>{moment(av.date).format('MMM DD, YYYY LT')}</td>
                        <td>{av.reason}</td>
                        <td className="text-center">{av.details.painManagementCheck==="yes"?<DoneIcon />:<CloseIcon />}</td>
                        <td>{av.details.painManagementRemarks}</td>
                        <td>{av.details.painManagementPlanRemarks}</td>
                      </tr>
                    )
                  })
                }
              </table>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 col-sm-12" style={{marginBottom: 15}}><span style={{color: "rgb(47, 119, 209)", fontWeight: 700, fontSize:16}}>Patient Management (Investigations):</span></div>
            <div className="col-md-12 col-sm-12">
              <table className="table table-sm table-bordered table-stripped">
                <tr>
                  <th className="text-left bg-light">Date &amp; Time</th>
                  <th width="15%" className="text-center bg-light">Remarks</th>
                  <th width="15%" className="text-center bg-light">Status</th>
                  <th width="25%" className="text-left bg-light">Action</th>
                  <th width="25%" className="text-left bg-light">Plan</th>
                </tr>
                {
                  Object.entries(actionPlanData2).map(([ai,av]) => {
                    return (
                      <tr>
                        <td>{moment(av.date).format('MMM DD, YYYY LT')}</td>
                        <td>{av.reason}</td>
                        <td className="text-center">{av.details.investigationsCheck==="yes"?<DoneIcon />:<CloseIcon />}</td>
                        <td>{av.details.investigationsRemarks}</td>
                        <td>{av.details.investigationsPlanRemarks}</td>
                      </tr>
                    )
                  })
                }
              </table>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 col-sm-12" style={{marginBottom: 15}}><span style={{color: "rgb(47, 119, 209)", fontWeight: 700, fontSize:16}}>Condition of Patient:</span></div>
            <div className="col-md-12 col-sm-12">
              <table className="table table-sm table-bordered table-stripped">
                <tr>
                  <th className="text-left bg-light">Date &amp; Time</th>
                  <th width="15%" className="text-center bg-light">Stable</th>
                  <th width="15%" className="text-center bg-light">Comfortable</th>
                  <th width="15%" className="text-center bg-light">Not In Distress</th>
                  <th width="15%" className="text-center bg-light">Respondend Well to Management</th>
                  <th width="15%" className="text-center bg-light">Questions and concerns</th>
                </tr>
                {
                  Object.entries(actionPlanData2).map(([ai,av]) => {
                      return (
                        <tr>
                          <td>{moment(av.date).format('MMM DD, YYYY LT')}</td>
                          <td className="text-center">{av.details.cpStable==="yes"?<DoneIcon />:<CloseIcon />}</td>
                          <td className="text-center">{av.details.cpComfortable==="yes"?<DoneIcon />:<CloseIcon />}</td>
                          <td className="text-center">{av.details.cpNotInDistress==="yes"?<DoneIcon />:<CloseIcon />}</td>
                          <td className="text-center">{av.details.cpRespondendWellManagement==="yes"?<DoneIcon />:<CloseIcon />}</td>
                          <td className="text-center">{av.details.cpQuestionConcerned==="yes"?<DoneIcon />:<CloseIcon />}</td>
                        </tr>
                      )
                  })
                }
              </table>
            </div>
          </div>
        </div>

        <div
          className="subheader"
          style={{ marginLeft: "-15px", marginBottom: 45 }}
        >
          <div className="col-7">
            <img src={covIcon} />
            <h4
              style={{
                color: "#000000",
                fontSize: matches ? 32 : 15,
                fontWeight: "bolder",
              }}
            >
              Course of Visit
            </h4>
          </div>

          <div className="col-5" style={{ padding: 0 }}>
          {/*
            <ActionButtons
              changeCount={changeCount}
              indexValue={7}
              totalCount={totalCountArray[7]}
              currentCount={count[7]}
            />
          */}
          </div>
        </div>

        <div className="container-fluid">
          {covData && covData.length > 0 ? (
            covData.map((arr) => {
              return (
                <DetailBlock
                  heading={arr.heading}
                  subArray={arr.subArray}
                  columnSize={arr.columnSize}
                />
              );
            })
          ) : (
            <div className="row">
              
            </div>
          )}
        </div>

        <div style={{ fontWeight: "400", color: "#939393" }}>Decision to Discharge/Disposition:</div>
          <div className="row mt-2 align-items-center">
            <div className="col-sm-5"><strong className="">Discharge/Disposition</strong></div>
            <div className="col-sm-7">
              <div className="form-group">
                <span style={stylesx.chipAlignment}>
                  <Chip
                    label="Yes"
                    icon={dischargeDispositionStatus=="yes" ? <DoneIcon style={{ color: "white" }} />:undefined}
                    style={dischargeDispositionStatus=="yes" ? {color: "white",backgroundColor: "rgb(19 213 159)"} : { color: "#070707", backgroundColor: "#F5F5F5"}}
                  />
                </span>
                <span style={stylesx.chipAlignment}>
                  <Chip
                    label="No"
                    icon={dischargeDispositionStatus=="no" ? <DoneIcon style={{ color: "white" }} />:undefined}
                    style={dischargeDispositionStatus=="no" ? {color: "white",backgroundColor: "rgb(19 213 159)"} : { color: "#070707", backgroundColor: "#F5F5F5"}}
                  />
                </span>
              </div>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-sm-5"><strong>Discharge/Disposition Type</strong></div>
            <div className="col-sm-7">
              <div className="form-group">
                <span style={stylesx.chipAlignment}>
                  <Chip
                    label="Discharge to Home"
                    icon={dischargeDisposition=="discharged" ? <DoneIcon style={{ color: "white" }} />:undefined}
                    style={dischargeDisposition=="discharged" ? {color: "white",backgroundColor: "rgb(19 213 159)"} : { color: "#070707", backgroundColor: "#F5F5F5"}}
                  />
                </span>
                <span style={stylesx.chipAlignment}>
                  <Chip
                    label="Admission"
                    icon={dischargeDisposition=="admitted" ? <DoneIcon style={{ color: "white" }} />:undefined}
                    style={dischargeDisposition=="admitted" ? {color: "white",backgroundColor: "rgb(19 213 159)"} : { color: "#070707", backgroundColor: "#F5F5F5"}}
                  />
                </span>
                <span style={stylesx.chipAlignment}>
                  <Chip
                    label="Transfer to another facility"
                    icon={dischargeDisposition=="transferred" ? <DoneIcon style={{ color: "white" }} />:undefined}
                    style={dischargeDisposition=="transferred" ? {color: "white",backgroundColor: "rgb(19 213 159)"} : { color: "#070707", backgroundColor: "#F5F5F5"}}
                  />
                </span>
                <span style={stylesx.chipAlignment}>
                  <Chip
                    label="Deceased"
                    icon={dischargeDisposition=="deceased" ? <DoneIcon style={{ color: "white" }} />:undefined}
                    style={dischargeDisposition=="deceased" ? {color: "white",backgroundColor: "rgb(19 213 159)"} : { color: "#070707", backgroundColor: "#F5F5F5"}}
                  />
                </span>
              </div>
            </div>
          </div>
          {
            dischargeDisposition === "admitted"?
            <div className="row align-items-center">
              <div className="col-sm-5"><strong>Admission Type</strong></div>
              <div className="col-sm-7">
                <div className="form-group">
                  <span style={stylesx.chipAlignment}>
                    <Chip
                      label="OR (Operation Room)"
                      icon={dischargeDispositionType=="operation-room" ? <DoneIcon style={{ color: "white" }} />:undefined}
                      style={dischargeDispositionType=="operation-room" ? {color: "white",backgroundColor: "rgb(19 213 159)"} : { color: "#070707", backgroundColor: "#F5F5F5"}}
                    />
                  </span>
                  <span style={stylesx.chipAlignment}>
                    <Chip
                      label="Non-Monitored Ward"
                      icon={dischargeDispositionType=="non-monitored-ward" ? <DoneIcon style={{ color: "white" }} />:undefined}
                      style={dischargeDispositionType=="non-monitored-ward" ? {color: "white",backgroundColor: "rgb(19 213 159)"} : { color: "#070707", backgroundColor: "#F5F5F5"}}
                    />
                  </span>
                  <span style={stylesx.chipAlignment}>
                    <Chip
                      label="Monitored Ward"
                      icon={dischargeDispositionType=="monitored-ward" ? <DoneIcon style={{ color: "white" }} />:undefined}
                      style={dischargeDispositionType=="monitored-ward" ? {color: "white",backgroundColor: "rgb(19 213 159)"} : { color: "#070707", backgroundColor: "#F5F5F5"}}
                    />
                  </span>
                  <span style={stylesx.chipAlignment}>
                    <Chip
                      label="Step-Down Unit"
                      icon={dischargeDispositionType=="stepdown-unit" ? <DoneIcon style={{ color: "white" }} />:undefined}
                      style={dischargeDispositionType=="stepdown-unit" ? {color: "white",backgroundColor: "rgb(19 213 159)"} : { color: "#070707", backgroundColor: "#F5F5F5"}}
                    />
                  </span>
                  <span style={stylesx.chipAlignment}>
                    <Chip
                      label="Intensive Care Unit (ICU)"
                      icon={dischargeDispositionType=="icu" ? <DoneIcon style={{ color: "white" }} />:undefined}
                      style={dischargeDispositionType=="icu" ? {color: "white",backgroundColor: "rgb(19 213 159)"} : { color: "#070707", backgroundColor: "#F5F5F5"}}
                    />
                  </span>
                  <span style={stylesx.chipAlignment}>
                    <Chip
                      label="Cardiac Care Unit (CCU)"
                      icon={dischargeDispositionType=="ccu" ? <DoneIcon style={{ color: "white" }} />:undefined}
                      style={dischargeDispositionType=="ccu" ? {color: "white",backgroundColor: "rgb(19 213 159)"} : { color: "#070707", backgroundColor: "#F5F5F5"}}
                    />
                  </span>
                  <span style={stylesx.chipAlignment}>
                    <Chip
                      label="Stroke Unit"
                      icon={dischargeDispositionType=="stroke-unit" ? <DoneIcon style={{ color: "white" }} />:undefined}
                      style={dischargeDispositionType=="stroke-unit" ? {color: "white",backgroundColor: "rgb(19 213 159)"} : { color: "#070707", backgroundColor: "#F5F5F5"}}
                    />
                  </span>
                </div>
              </div>
            </div>
            :undefined
          }

          <div className="row mt-2">
            <div className="col-sm-12">
              <div className="form-group">
                <TextField
                  multiline
                  label="Discharge/Disposition Treatment Plan"
                  value={dischargeDispositionRemarks}
                  rows={5}
                  className="textInputStyle"
                  variant="filled"
                  InputProps={{
                    className: classes.input,
                    classes: {
                      input: classes.input,
                    },
                    disableUnderline: true,
                    rows: 5
                  }}
                  />
              </div>
            </div>
          </div>


          <div className="row mt-2">
            <div className="col-sm-12">
              <div className="form-group">
                <TextField
                  multiline
                  label="Follow Up Instruction/Appointment"
                  value={dischargeDispositionFollowup}
                  rows={5}
                  className="textInputStyle"
                  variant="filled"
                  InputProps={{
                    className: classes.input,
                    classes: {
                      input: classes.input,
                    },
                    disableUnderline: true,
                    rows: 5
                  }}
                  />
              </div>
            </div>
          </div>


        <div className="row">
          <div className="col-md-12 col-sm-12" style={{marginBottom: 15}}><span style={{color: "rgb(47, 119, 209)", fontWeight: 700, fontSize:16}}>Discharge Medications:</span></div>
          <div className="col-md-12 col-sm-12">
            <table className="table table-sm table-bordered table-stripped">
              <tr>
                <th width="15%" className="text-left bg-light">Date &amp; Time</th>
                <th width="15%" className="text-left bg-light">Request ID</th>
                <th className="text-left bg-light">Item</th>
                <th width="25%" className="text-center bg-light">Request by</th>
                <th width="10%" className="text-center bg-light">Status</th>
              </tr>
              {
                allDischargeReports && allDischargeReports.length>0?
                Object.entries(allDischargeReports).map(([ai,av]) => {
                  return (
                    <tr>
                      <td>{av.parentRequest.requestDate}</td>
                      <td>{av.parentRequest.pharmacyRequestNo}</td>
                      <td><strong>{av.subRequest.itemId.itemCode}:</strong> {av.subRequest.itemId.name}</td>
                      <td>{av.parentRequest.requester}</td>
                      <td className="text-center">{av.subRequest.status}</td>
                    </tr>
                  )
                })
                :<tr><td className="text-center" colspan="6">No Pharma Request!</td></tr>
              }
            </table>
          </div>
        </div>

        <div className="container-fluid">
          <div className="row" style={{ marginTop: "2%", marginBottom: "2%" }}>
            <div className="col-md-6 col-sm-6 col-6">
              <img
                onClick={() => props.history.goBack()}
                src={Back}
                style={{ width: 45, height: 40, cursor: "pointer" }}
              />
            </div>
            {/* <div className="col-md-6 col-sm-6 col-6 d-flex justify-content-end">
                            <Button
                                style={styles.save}
                                variant="contained"
                                color="default"
                                onClick={handleSubmit}
                            >
                                Submit
              				</Button>
                        </div> */}
          </div>
        </div>
      </div>

      <Notification
        msg={errorMsg}
        open={openNotification}
        success={successMsg}
      />
    </div>
  );
}
export default ViewNewDCDForm;
