import React, { useState, useEffect } from 'react'
import Notification from '../../../components/Snackbar/Notification.js'
import CustomTable from '../../../components/Table/Table'
import ButtonField from '../../../components/common/Button'
import { makeStyles } from '@material-ui/core/styles'
import axios from 'axios'
import { assignPatientStyles } from '../../Consultations/styles'
import Button from '@material-ui/core/Button'
import Header from '../../../components/Header/Header'
//icons and images
import LabRequests from '../../../assets/img/Lab Requests.png'
import Back_Arrow from '../../../assets/img/Back_Arrow.png'
import eye_icon from '../../../assets/img/Eye.png'

import AccountCircle from '@material-ui/icons/SearchOutlined'
import InputAdornment from '@material-ui/core/InputAdornment'
// import '../../assets/jss/material-dashboard-react/components/loaderStyle.css'
import TextField from '@material-ui/core/TextField'
import cookie from 'react-cookies'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import _ from 'lodash'
import moment from 'moment';
import classNames from "classnames";
import { displayInHumanDifference, replacePatientIdIfNull } from "../../../utils/commonFunc";
import {
  getPendingLabReq,
  getCompletedLabReq,
  searchEdrWithCareStream,
} from '../../../public/endpoins'

const styles = {
  textFieldPadding: {
    paddingLeft: 5,
    paddingRight: 5,
  },
  stylesForButton: {
    color: 'white',
    cursor: 'pointer',
    borderRadius: 5,
    backgroundColor: '#2c6ddd',
    width: '210px',
    height: '45px',
  },
}

const useStylesForInput = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(0),
  },
  input: {
    backgroundColor: 'white',
    boxShadow: 'none',
    borderRadius: 5,
    '&:after': {
      borderBottomColor: 'black',
      boxShadow: 'none',
    },
    '&:hover': {
      backgroundColor: 'white',
      boxShadow: 'none',
    },
    '&:focus': {
      backgroundColor: 'white',
      boxShadow: 'none',
      borderRadius: 5,
    },
  },
  multilineColor: {
    boxShadow: 'none',
    backgroundColor: 'white',
    borderRadius: 5,
    '&:hover': {
      backgroundColor: 'white',
      boxShadow: 'none',
    },
    '&:after': {
      borderBottomColor: 'black',
      boxShadow: 'none',
    },
    '&:focus': {
      boxShadow: 'none',
    },
  },
  root: {
    '& .MuiTextField-root': {
      backgroundColor: 'white',
    },
    '& .Mui-focused': {
      backgroundColor: 'white',
      color: 'black',
      boxShadow: 'none',
    },
    '& .Mui-disabled': {
      backgroundColor: 'white',
      color: 'gray',
    },
    '&:focus': {
      backgroundColor: 'white',
      boxShadow: 'none',
    },
    '& .MuiFormLabel-root': {
      fontSize: '12px',

      paddingRight: '15px',
    },
  },
  label: {
    '&$focusedLabel': {
      color: 'red',
      display: 'none',
    },
    // "&$erroredLabel": {
    //   color: "orange"
    // }
  },
  focusedLabel: {},
}))

const tableHeading = [
  'Date/Time',
  'MRN Number',
  'Request ID',
  'Patient Name',
  'Bundle/Test',
  'Priority',
  'Sample Status',
  'Test Status',
  'Actions',
]
const tableDataKeys = [
  'requestAt',
  'mrn',
  'reqID',
  'patientName',
  'serviceName',
  'priority',
  'nurseTechStatus',
  'reqStatus',
]

const tableHeadingForCompleted  = tableHeading;
const tableDataKeysForCompleted = tableDataKeys;

/*
const tableHeadingForCompleted = [
  'MRN Number',
  'Request ID',
  'Patient Name',
  'Date/Time',
  'Status',
  'Actions',
]
const tableDataKeysForCompleted = [
  'mrn',
  'reqID',
  'patientName',
  'requestAt',
  'reqStatus',
]
*/

const actions = { view: true }

export default function CompletedTask(props) {
  const classes = useStylesForInput()
  const classes1 = assignPatientStyles()
  const matches = useMediaQuery('(min-width:600px)')

  const [pharmOPR, setPharmOPR] = useState('')
  const [searchQuery, setSearchQuery] = useState("");
  const [timer, setTimer] = useState(null);

  const [radOPR, setRadOPR] = useState('')
  const [labOPR, setLabOPR] = useState('')
  // const [patient, setPatient] = useState('')
  const [itemModalVisible, setitemModalVisible] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')
  const [openNotification, setOpenNotification] = useState(false)
  const [item, setItem] = useState('')
  const [searchPatientQuery, setSearchPatientQuery] = useState('')
  const [PatientUrl, setPatientUrl] = useState('')
  const [selectedStatus, setSelectedStatus] = useState('pending')
  const [currentUser] = useState(cookie.load("current_user"));

  const [patientDetails, setPatientDetails] = useState("");
  const [selectedRecord, setSelectedRecord] = useState({});

  //Lab Service Req
  const [labRequests, setlabRequests] = useState([])
  const [ labRequest, setLabRequest ] = useState({});

  if (openNotification) {
    setTimeout(() => {
      setOpenNotification(false)
      setErrorMsg('')
    }, 4000)
  }

  useEffect(() => {
    getPatientLabPenReqs();
  }, [selectedStatus])

  //get Pending Lab Requests

  function getPatientLabPenReqs (sQuery) {

    let searchURL = getPendingLabReq + '/'+ currentUser._id;
    if (selectedStatus === 'completed') {
      searchURL = getCompletedLabReq + '/' + currentUser._id
    }
    if (sQuery){
      searchURL = searchURL + '?searchKeyword=' + sQuery;
    }

    axios
      .get(searchURL)
      .then((res) => {
        console.log('Pending task', res)
        if (res.data.success) {
          //patient-name

          /*
          res.data.data.map(
            (d) => (
              (d.patientName =
                d.patientId.name[0].given[0] +
                ' ' +
                d.patientId.name[0].family),
              (d.requestAt = moment(d.labRequest.requestAt).format('MMM DD, YYYY LT')),
              (
                d.serviceName = (
                  d.labRequest.serviceId ? (
                    d.labRequest.serviceId.category + ': ' + d.labRequest.serviceId.name
                  ): null
                )
              ),
              (d.priority = d.labRequest.priority),
              (d.mrn = d.requestNo),
              (d.reqID = d.labRequest.requestId),
              (d.reqStatus = d.labRequest.status),
              (d.nurseTechStatus = d.labRequest.nurseTechnicianStatus)
            )
          )
          */

          res.data.data.map((val) => {
            
            val.patientName = val.patientId.name[0].given[0] + " " + val.patientId.name[0].family;
            val.roomNo = val.room[0].roomId.roomNo;
            val.status = val.labRequest.nurseTechnicianStatus;
            val.labRequestId = val.labRequest.requestId;
            val.labRequestUID = val.labRequest._id;
            // (val.serviceName = val.labRequest.serviceId.category + ': ' + val.labRequest.serviceId.name;
            // val.serviceRequestedBy = val.labRequest.requestedBy.name[0].given[0] + ' ' + val.labRequest.requestedBy.name[0].family;
            val.mrn = val.patientId.identifier[0].value;
            val.episodeNo = val.requestNo;
            // val.labRequestId = val.labRequest.requestNo;

            // Age
            var today = new Date();
            var birthDate = new Date(val.patientId.birthDate);
            var age_now = today.getFullYear() - birthDate.getFullYear();
            var m = today.getMonth() - birthDate.getMonth();
            if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
              age_now--;
            }
            val.ageNow = age_now;

            // Services
            let servicesNames = [];
            Object.entries(val.labRequest.services).map(([key, val]) => {
              servicesNames.push(val.name)
            });
            val.serviceName = servicesNames.join(',');


          });
          console.log('patient name', res.data.data)
          // var allLabSorted = _.sortBy(res.data.data, "createdAt").reverse();
          setlabRequests(res.data.data.reverse())
        } else if (!res.data.success) {
          setErrorMsg(res.data.error)
          setOpenNotification(true)
        }
        return res
      })
      .catch((e) => {
        console.log('error: ', e)
      })
  }
  function getPatientLabCompReqs() {
    axios
      .get(getCompletedLabReq + '/' + currentUser._id)
      .then((res) => {
        console.log('completed task', res)
        if (res.data.success) {
          //patient-name
          res.data.data.map(
            (d) => (
              (d.patientName =
                d.patientId.name[0].given[0] +
                ' ' +
                d.patientId.name[0].family),
              (d.requestAt = moment(d.labRequest.requestAt).format('MMM DD, YYYY LT')),
              (
                d.serviceName = (
                  d.labRequest.serviceId ? (
                    d.labRequest.serviceId.category + ': ' + d.labRequest.serviceId.name
                  ): null
                )
              ),
              (d.priority = d.labRequest.priority),
              (d.mrn = d.requestNo),
              (d.reqID = d.labRequest.requestId),
              (d.reqStatus = d.labRequest.status),
              (d.nurseTechStatus = d.labRequest.nurseTechnicianStatus)
            )
          )

          console.log('patient name', res.data.data)
          // var allLabSorted = _.sortBy(res.data.data, "createdAt").reverse();
          setlabRequests(res.data.data.reverse())
        } else if (!res.data.success) {
          setErrorMsg(res.data.error)
          setOpenNotification(true)
        }
        return res
      })
      .catch((e) => {
        console.log('error: ', e)
      })
  }

  function handleView(rec) {
    console.log(rec)
    if (rec.nurseTechStatus === 'Not Collected') {
      setErrorMsg('You can only update request once the sample is collected')
      setOpenNotification(true)
    } else {
      let path = `/dashboard/labtechnicianpatientdetail`
      props.history.push({
        pathname: path,
        state: {
          // comingFor: "edit",
          selectedItem: rec,
        },
      })
    }
  }

  const handlePauseLabSearch = (e) => {
    // setLoadSearchedData(true);
    // clearTimeout(timer);

    const a = e.target.value.replace(/[^\w\s]/gi, "");
    setSearchQuery(a);

    setTimer(
      setTimeout(() => {
        getPatientLabPenReqs(a);
        /*labServiceSearchCall(
          a,
          setItemFoundSuccessfully,
          setItemFound,
          setLoadSearchedData
        );*/
      }, 600)
    );
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        position: 'fixed',
        width: '100%',
        height: '100%',
        backgroundColor: 'rgb(19 213 159)',
        overflowY: 'scroll',
      }}
    >
      <Header history={props.history} />
      <div className='cPadding'>
        <div className='subheader' style={{ marginLeft: '-10px' }}>
          <div>
            <img src={LabRequests} />

            <h4>Lab Service Requests</h4>
          </div>
          {selectedStatus === 'completed' ? (
            <Button
              className={classes1.stylesForButtonPatient}
              variant='contained'
              color='primary'
              onClick={() => setSelectedStatus('pending')}
              // onClick={() => callCompleted("completed")}
            >
              <img src={eye_icon} className='icon-view' />
              &nbsp;&nbsp;
              <strong style={{ fontSize: matches ? '' : 9 }}>
                Pending Tasks
              </strong>
            </Button>
          ) : (
            <Button
              className={classes1.stylesForButtonPatient}
              variant='contained'
              color='primary'
              onClick={() => setSelectedStatus('completed')}
            >
              <img src={eye_icon} className='icon-view' />
              &nbsp;&nbsp;
              <strong style={{ fontSize: matches ? '' : 9 }}>
                Completed Tasks
              </strong>
            </Button>
          )}
        </div>


        {patientDetails && labRequest ? (
          <div className="mb-3 container-fluid">
            <div className="card p-3">
              <div className="row row-5">
                <div className="col-6 col-lg-3 card-detail-block block-xdiv mb-2"><strong className="lblX">Episode #</strong><span className="valX">{patientDetails.episodeNumber}</span></div>
                <div className="col-6 col-lg-3 card-detail-block block-xdiv mb-2"><strong className="lblX">MRN #</strong><span className="valX">{patientDetails.mrn}</span></div>
                <div className="col-6 col-lg-3 card-detail-block block-xdiv mb-2"><strong className="lblX">Requester</strong><span className="valX">{labRequest.requestedName}</span></div>
                <div className="col-6 col-lg-3 card-detail-block block-xdiv mb-2"><strong className="lblX">Date and Time</strong><span className="valX">{displayInHumanDifference(labRequest.requestedAt)}</span></div>
              </div>
            </div>
          </div>
        ) : (
          undefined
        )}

        <div className="container-fluid">
          <div className="row row-5">
            <div className="col-12">
              <div className="form-group" style={{ ...styles.inputContainerForTextField, marginTop: '10px' }}>
                <TextField
                  required
                  label="Patient MRN/Episode/Request Number or Name"
                  name={"searchQuery"}
                  value={searchQuery}
                  onChange={handlePauseLabSearch}
                  className="textInputStyle"
                  variant="filled"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <AccountCircle />
                      </InputAdornment>
                    ),
                    className: classes.input,
                    classes: { input: classes.input },
                    disableUnderline: true,
                  }}
                  InputLabelProps={{
                    className: classes.label,
                    classes: { label: classes.label },
                  }}
                />
              </div>
            </div>
            {labRequests !== ' ' ? (
              <>
              {
                Object.entries(labRequests).map((obj, entry) => {
                  return (
                    <div className="col-12 col-md-6 col-lg-6 col-xl-6">
                      <div className="card card-detail-info card-detail-small mb-2">
                        <div className="row row-5">
                          <div className="col-sm-12"><strong>Request #:</strong> <span>{obj[1].labRequestId}</span> &nbsp; <strong>PA/Bed#:</strong> <span>{obj[1].roomNo}</span></div>
                          <div className="col-sm-6  mt-3"><strong>Epi.#:</strong> <span>{obj[1].episodeNo}</span></div>
                          <div className="col-sm-6  mt-3"><strong>MRN:</strong> <span>{obj[1].mrn}</span></div>
                          <div className="col-sm-10 mt-3"><strong>Patient:</strong> <span>{obj[1].patientName} / {obj[1].gender==="Female"?"F":"M"} / {obj[1].ageNow}y</span></div>
                          <div className="col-sm-2  mt-3 text-right"><strong>T:</strong> <span>0:00</span></div>
                        </div>
                        <div className="card-status-action">
                          <span className={classNames(`badge`, `status-`+obj[1].status)}>{obj[1].status}</span>
                          { 
                            obj[1].urgent==="Yes"?<span className="ml-info">!</span>:undefined
                          }
                          <i className="ml-view ml-10 zmdi zmdi-eye zmdi-hc-2x" onClick={() => {
                            handleView(obj[1]);
                          }} />
                        </div>
                      </div>
                    </div>
                  )
                })
              }
              <Notification msg={errorMsg} open={openNotification} />

              <div className="col-12" style={{ marginBottom: 20, marginTop: 50 }}>
                <img
                  onClick={() => props.history.goBack()}
                  src={Back_Arrow}
                  style={{ width: 45, height: 35, cursor: 'pointer' }}
                />
              </div>
              </>
            ) : (
              // <div className='LoaderStyle'>
              //   <Loader type='TailSpin' color='red' height={50} width={50} />
              // </div>
              <div className='row ' style={{ marginTop: '25px' }}>
                <div className='col-11'>
                  <h3
                    style={{
                      color: 'white',
                      textAlign: 'center',
                      width: '100%',
                      position: 'absolute',
                    }}
                  >
                    Opps...No Data Found
                  </h3>
                </div>
                <div className='col-1' style={{ marginTop: 45 }}>
                  {/* <img
                    onClick={() => props.history.goBack()}
                    src={Back_Arrow}
                    style={{
                      maxWidth: '60%',
                      height: 'auto',
                      cursor: 'pointer',
                    }}
                  /> */}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
