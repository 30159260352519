import axios from "axios";
import {
  getAllCustomerCares,
  searchCustomerCare,
  assignCustomerCare,
} from "../../public/endpoins.js";

export const getEdrPatientsForCC = (
  setlabInPatient,
  setErrorMsg,
  setOpenNotification
) => {
  axios
    .get(getAllCustomerCares)
    .then((res) => {
      if (res.data.success) {
        res.data.data.map(
          (d) => (d.ccName = d.name[0].given[0] + " " + d.name[0].family)
        );
        res.data.data.map((d) => {
          d.mrn = d.identifier[0].value;
          d.availability = d.availability ? "Un Assigned" : "Assigned";
        });
        // res.data.data.map((d) => (d.status = d.availability))
        console.log(res.data.data, "Customer Care");
        setlabInPatient(res.data.data);
      } else if (!res.data.success) {
        setErrorMsg(res.data.error);
        setOpenNotification(true);
      }
      return res;
    })
    .catch((e) => {
      console.log("error: ", e);
    });
};

export const assignCustomerCareCC = (
  props,
  object,
  setlabInPatient,
  setErrorMsg,
  setOpenNotification
) => {
  console.log("Object to assign customer care", object, props);
  let cameFromPage = props.history.location.state.cameFrom;
  let selectedItem = props.history.location.state.selectedItem;
  axios
    .put(assignCustomerCare, object)
    .then((res) => {
      if (res.data.success) {
        // res.data.data.map(
        //   (d) => (d.ccName = d.name[0].given[0] + " " + d.name[0].family),
        // )
        // res.data.data.map((d) => {
        //   d.mrn = d.identifier[0].value
        //   d.availability = d.availability ? "Un Assigned" : "Assigned"
        // })
        // // res.data.data.map((d) => (d.status = d.availability))
        // console.log(res.data.data, "Customer Care")
        // setlabInPatient(res.data.data)
        // window.location.reload(true)
        console.log("Res", res);
        if (cameFromPage === "successPage"){
          props.history.push({
            pathname: "/dashboard/home/patientmanagement/success",
            state: {
              code: `${selectedItem.requestNo.toUpperCase()}`,
              edrId: selectedItem._id,
              selectedItem: selectedItem,
              mode: "ccAssigned",
            },
          });
        }else{
          props.history.push({
            pathname: "/dashboard/home/success",
            state: {
              message1: `Customer Care Officer has successfully assigned.`,
            },
          });
        }


      } else if (!res.data.success) {
        setErrorMsg(res.data.error);
        setOpenNotification(true);
      }
      return res;
    })
    .catch((e) => {
      console.log("error: ", e);
    });
};

export const searchEdrPatientsForCC = (
  a,
  setlabInPatient,
  setErrorMsg,
  setOpenNotification
) => {
  axios
    .get(searchCustomerCare + "/" + a)
    .then((res) => {
      console.log("res", res);
      if (res.data.success) {
        if (res.data.data) {
          console.log("patient dataaa", res.data.data);
          res.data.data.map(
            (d) => (d.ccName = d.name[0].given[0] + " " + d.name[0].family)
          );
          res.data.data.map((d) => (d.mrn = d.identifier[0].value));
          res.data.data.map((d) => {
            d.telecom.map((a) => {
              if (a.system === "phone") {
                return (d.phoneNo = a.value);
              }
            });
          });
          // var sortedObjs = _.sortBy(res.data.data, 'date').reverse()
          setlabInPatient(res.data.data);
        } else {
          setlabInPatient(" ");
        }
      } else if (!res.data.success) {
        setErrorMsg(res.data.error);
        setOpenNotification(true);
      }
    })
    .catch((e) => {
      console.log("error after searching patient request", e);
    });
};
