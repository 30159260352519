import axios from "axios";
import ExtractTimeFromDate from "../../../utils/extractTimeFromDate";
import getTimeFromDate from "../../../utils/extractTimeFromDate";
import {
  getAllStaff,
  getAllEDDoctorsUrl,
  deleteStaffUrl,
  enableStaff,
  disableStaff,
  registerStaff,
  updateStaff,
  getDoctorSubTypes,
  getNursesSubTypes,
  getDoctorSpecialty,
  getNursesSpecialty,
  getAllSenseiUrl,
  getAnesthesiologist,
  getCustomerCaresURL,
  workDoneByCCURL,
  searchCustomerCareURL,
  searchWorkDoneByCCURL,
  searchSensei,
  searchAnesthesiologist,
  getAllChiefComplaints,
  getPAsByCCs,
  AssignShifts,
  getAllShiftsURL,
  getAdditionalRoles,
} from "../../../public/endpoins";

export const getDoctorSpecialtyFunc = (
  setDoctorSpeciality,
  setOpenNotification,
  setErrorMsg
) => {
  axios
    .get(getDoctorSpecialty)
    .then((res) => {
      if (res.data.success) {
        setDoctorSpeciality(res.data.data);
        // console.log("doctor sub data", res.data);
      } else if (!res.data.success) {
        setOpenNotification(true);
      }
    })
    .catch((e) => {
      console.log("Error while adding staff", e);
      setOpenNotification(true);
      setErrorMsg("Error while adding staff");
    });
};

export const getShiftsData = (
  setShiftArray,
  setOpenNotification,
  setErrorMsg
) => {
  axios
    .get(getAllShiftsURL)
    .then((res) => {
      if (res.data.success) {
        console.log("Shifts response data", res.data.data);
        setShiftArray(res.data.data);
      } else if (!res.data.success) {
        setOpenNotification(true);
        setErrorMsg("Cannot get Shifts at the moment");
      }
    })
    .catch((e) => {
      setOpenNotification(true);
      setErrorMsg("Cannot get Shifts at the moment");
    });
};

export const getDoctorSubTypesFunc = (
  setDoctorSubTypes,
  setOpenNotification,
  setErrorMsg
) => {
  axios
    .get(getDoctorSubTypes)
    .then((res) => {
      if (res.data.success) {
        setDoctorSubTypes(res.data.data);
        console.log("doctor sub data", res.data);
      } else if (!res.data.success) {
        setOpenNotification(true);
      }
    })
    .catch((e) => {
      console.log("Error while adding staff", e);
      setOpenNotification(true);
      setErrorMsg("Error while adding staff");
    });
};

export const getAdditionalRoleCall = (
  setAdditionalRolesArray,
  setOpenNotification,
  setErrorMsg
) => {
  axios
    .get(getAdditionalRoles)
    .then((res) => {
      if (res.data.success) {
        setAdditionalRolesArray(res.data.data);
        console.log("addition roles data", res.data);
      } else if (!res.data.success) {
        setOpenNotification(true);
      }
    })
    .catch((e) => {
      console.log("Error while adding staff", e);
      setOpenNotification(true);
      setErrorMsg("Error while adding staff");
    });
};

export const getNursesSpecialtyFunc = (
  setNursesSpeciality,
  setOpenNotification,
  setErrorMsg
) => {
  axios
    .get(getNursesSpecialty)
    .then((res) => {
      if (res.data.success) {
        setNursesSpeciality(res.data.data);
        // console.log("doctor sub data", res.data);
      } else if (!res.data.success) {
        setOpenNotification(true);
      }
    })
    .catch((e) => {
      console.log("Error while adding staff", e);
      setOpenNotification(true);
      setErrorMsg("Error while adding staff");
    });
};

export const getNursesSubTypesFunc = (
  setNursesSubTypes,
  setOpenNotification,
  setErrorMsg
) => {
  axios
    .get(getNursesSubTypes)
    .then((res) => {
      if (res.data.success) {
        setNursesSubTypes(res.data.data);
        console.log("doctor sub data", res.data);
      } else if (!res.data.success) {
        setOpenNotification(true);
      }
    })
    .catch((e) => {
      console.log("Error while adding staff", e);
      setOpenNotification(true);
      setErrorMsg("Error while adding staff");
    });
};

export function getAllStaffType(
  setLoading,
  setStaffData,
  setGeneralArray,
  setOpenNotification,
  setErrorMsg
) {
  axios
    .get(getAllStaff)
    .then((res) => {
      console.log("res", res.data.data);
      if (res.data.success) {
        setStaffData(res.data.data.reverse());
        const senseiData = res.data.data.filter(
          (val) => val.staffType === "Sensei"
        );
        senseiData.map(
          (d) => (
            (d.id = d.identifier[0].value),
            (d.stafName = d.name[0].given[0] + " " + d.name[0].family),
            (d.addedByName =
              d.addedBy.name[0].given[0] + " " + d.addedBy.name[0].family),
            // (d.production =
            //   d.chiefComplaint && d.chiefComplaint.length  > 0 && d.chiefComplaint[d.chiefComplaint.length - 1].chiefComplaintId !== null  ? d.chiefComplaint[d.chiefComplaint.length - 1].chiefComplaintId.productionArea[d.chiefComplaint[d.chiefComplaint.length - 1].chiefComplaintId.productionArea.length - 1].productionAreaId.paName
            //     : ''),
            (d.staffShift = d.shift ? d.shift.name : "N/A"),
            (d.startTime = d.shift
              ? ExtractTimeFromDate(d.shift.startTime)
              : "N/A"),
            (d.endTime = d.shift ? ExtractTimeFromDate(d.shift.endTime) : "N/A")
          )
        );
        setGeneralArray(senseiData);
        setLoading(false);
      } else if (!res.data.success) {
        setErrorMsg(res.data.error);
        setOpenNotification(true);
      }
      return res;
    })
    .catch((e) => {
      console.log("error: ", e);
    });
}

export function getAllEDDoctors(
  setStaffData,
  setGeneralArray,
  setOpenNotification,
  setErrorMsg
) {
  axios
    .get(getAllEDDoctorsUrl)
    .then((res) => {
      if (res.data.success) {
        console.log(res.data.data);
        setGeneralArray(res.data.data);
      } else if (!res.data.success) {
        setErrorMsg(res.data.error);
        setOpenNotification(true);
      }
      return res;
    })
    .catch((e) => {
      console.log("error: ", e);
    });
}

export function getAllSensei(
  setStaffData,
  setGeneralArray,
  setOpenNotification,
  setErrorMsg
) {
  axios
    .get(getAllSenseiUrl)
    .then((res) => {
      if (res.data.success) {
        console.log(res.data.data);
        setGeneralArray(res.data.data);
      } else if (!res.data.success) {
        setErrorMsg(res.data.error);
        setOpenNotification(true);
      }
      return res;
    })
    .catch((e) => {
      console.log("error: ", e);
    });
}

export function getCustomerCaresWorkDoneCall(
  setStaffData,
  setOpenNotification,
  setErrorMsg
) {
  axios
    .get(workDoneByCCURL)
    .then((res) => {
      if (res.data.success) {
        console.log("customer care Work done", res.data.data);
        res.data.data.map(
          (val) => (
            (val.productionArea =
              val.edrId.chiefComplaint[val.edrId.chiefComplaint.length - 1] &&
              val.edrId.chiefComplaint[val.edrId.chiefComplaint.length - 1]
                .chiefComplaintId
                ? val.edrId.chiefComplaint[val.edrId.chiefComplaint.length - 1]
                    .chiefComplaintId.productionArea[
                    val.edrId.chiefComplaint[
                      val.edrId.chiefComplaint.length - 1
                    ].chiefComplaintId.productionArea.length - 1
                  ].productionAreaId.paName
                : "N/A"),
            (val.mrn = val.edrId.patientId.identifier[0].value),
            (val.ccId = val.costomerCareId
              ? val.costomerCareId.identifier[0].value
              : "N/A"),
            (val.ccName = val.costomerCareId
              ? val.costomerCareId.name[0].given[0] +
                " " +
                val.costomerCareId.name[0].family
              : "N/A"),
            (val.patientName =
              val.edrId.patientId.name[0].given[0] +
              " " +
              val.edrId.patientId.name[0].family),
            (val.requestedFor = val.requestedFor ? val.requestedFor : "N/A")
          )
        );
        setStaffData(res.data.data);
      } else if (!res.data.success) {
        setErrorMsg(res.data.error);
        setOpenNotification(true);
      }
      return res;
    })
    .catch((e) => {
      console.log("error: ", e);
      setOpenNotification(true);
      setErrorMsg(e);
    });
}

export function getCustomerCaresCall(
  setStaffData,
  setGeneralArray,
  setOpenNotification,
  setErrorMsg
) {
  axios
    .get(getCustomerCaresURL)
    .then((res) => {
      if (res.data.success) {
        console.log("customer care", res.data.data);
        res.data.data.map(
          (val) => (
            (val.productionArea =
              val.chiefComplaint[val.chiefComplaint.length - 1] &&
              val.chiefComplaint[val.chiefComplaint.length - 1].chiefComplaintId
                ? val.chiefComplaint[val.chiefComplaint.length - 1]
                    .chiefComplaintId.productionArea[
                    val.chiefComplaint[val.chiefComplaint.length - 1]
                      .chiefComplaintId.productionArea.length - 1
                  ].productionAreaId.paName
                : "N/A"),
            (val.Id = val.identifier[0].value),
            (val.shift = val.shift ? val.shift.name : "Not Assigned"),
            (val.name = val.name[0].given[0] + " " + val.name[0].family)
          )
        );
        setStaffData(res.data.data);
      } else if (!res.data.success) {
        setErrorMsg(res.data.error);
        setOpenNotification(true);
      }
      return res;
    })
    .catch((e) => {
      console.log("error: ", e);
      setOpenNotification(true);
      setErrorMsg(e);
    });
}

export function getListAnesthesiologist(
  setStaffData,
  setGeneralArray,
  setOpenNotification,
  setErrorMsg
) {
  axios
    .get(getAnesthesiologist)
    .then((res) => {
      if (res.data.success) {
        console.log("anesthesiologists", res.data.data);
        res.data.data.map(
          (val) => (
            (val.productionArea = val.chiefComplaint[
              val.chiefComplaint.length - 1
            ]
              ? val.chiefComplaint[val.chiefComplaint.length - 1]
                  .chiefComplaintId.productionArea[0].productionAreaId.paName
              : "N/A"),
            (val.Id = val.identifier[0].value),
            (val.name = val.name[0].given[0] + " " + val.name[0].family),
            (val.shift = val.shift ? val.shift.name : "Not Assigned")
          )
        );
        setStaffData(res.data.data);
      } else if (!res.data.success) {
        setErrorMsg(res.data.error);
        setOpenNotification(true);
      }
      return res;
    })
    .catch((e) => {
      console.log("error: ", e);
      setOpenNotification(true);
      setErrorMsg(e);
    });
}

export const getSearchCustomerCare = (
  a,
  setStaffData,
  setOpenNotification,
  setErrorMsg
) => {
  axios
    .get(searchCustomerCareURL + "/" + a)
    .then((res) => {
      console.log("res", res);
      if (res.data.success) {
        if (res.data.data) {
          console.log("Search CustomerCare ", res.data.data);
          res.data.data.map(
            (val) => (
              (val.productionArea =
                val.chiefComplaint[val.chiefComplaint.length - 1] &&
                val.chiefComplaint[val.chiefComplaint.length - 1]
                  .chiefComplaintId
                  ? val.chiefComplaint[val.chiefComplaint.length - 1]
                      .chiefComplaintId.productionArea[
                      val.chiefComplaint[val.chiefComplaint.length - 1]
                        .chiefComplaintId.productionArea.length - 1
                    ].productionAreaId.paName
                  : "N/A"),
              (val.Id = val.identifier[0].value),
              (val.shift = val.status ? val.shift : "N/A"),
              (val.name = val.name[0].given[0] + " " + val.name[0].family)
            )
          );
          setStaffData(res.data.data);
          console.log(
            "After map Search CustomerCare "
            // res.data.data.map((patInfo) => {
            //   console.log(
            //     ("" ,patInfo.patientName = patInfo.patientId.name[0].given[0])
            //   );
            // })
          );
        } else {
          console.log("Your Not Found");
          setStaffData(" ");
        }
      } else if (!res.data.success) {
        setErrorMsg(res.data.error);
        setOpenNotification(true);
      }
    })
    .catch((e) => {
      console.log("error after searching patient request", e);
    });
};

export const getsearchWorkDoneByCCURL = (
  a,
  setStaffData,
  setOpenNotification,
  setErrorMsg
) => {
  axios
    .get(searchWorkDoneByCCURL + "/" + a)
    .then((res) => {
      if (res.data.success) {
        if (res.data.data) {
          console.log("Search CustomerCare ", res.data.data);
          res.data.data.map(
            (val) => (
              (val.productionArea =
                val.edrId.chiefComplaint[val.edrId.chiefComplaint.length - 1] &&
                val.edrId.chiefComplaint[val.edrId.chiefComplaint.length - 1]
                  .chiefComplaintId
                  ? val.edrId.chiefComplaint[
                      val.edrId.chiefComplaint.length - 1
                    ].chiefComplaintId.productionArea[
                      val.edrId.chiefComplaint[
                        val.edrId.chiefComplaint.length - 1
                      ].chiefComplaintId.productionArea.length - 1
                    ].productionAreaId.paName
                  : "N/A"),
              (val.mrn = val.edrId.patientId.identifier[0].value),
              (val.ccId = val.costomerCareId
                ? val.costomerCareId.identifier[0].value
                : "N/A"),
              (val.ccName = val.costomerCareId
                ? val.costomerCareId.name[0].given[0] +
                  " " +
                  val.costomerCareId.name[0].family
                : "N/A"),
              (val.patientName =
                val.edrId.patientId.name[0].given[0] +
                " " +
                val.edrId.patientId.name[0].family),
              (val.requestedFor = val.requestedFor ? val.requestedFor : "N/A")
            )
          );
          setStaffData(res.data.data);
          console.log(
            "After map Search CustomerCare work done "
            // res.data.data.map((patInfo) => {
            //   console.log(
            //     ("" ,patInfo.patientName = patInfo.patientId.name[0].given[0])
            //   );
            // })
          );
        } else {
          console.log("Your Not Found");
          setStaffData(" ");
        }
      } else if (!res.data.success) {
        setErrorMsg(res.data.error);
        setOpenNotification(true);
      }
    })
    .catch((e) => {
      console.log("error after searching patient request", e);
    });
};

export const getSearchAnesthesiologist = (
  a,
  setStaffData,
  setOpenNotification,
  setErrorMsg
) => {
  axios
    .get(searchAnesthesiologist + "/" + a)
    .then((res) => {
      console.log("res", res);
      if (res.data.success) {
        if (res.data.data) {
          console.log("Search Anesthesiologist ", res.data.data);
          // res.data.data.map(
          //   (val) => (
          //     (val.productionArea = val.chiefComplaint[
          //       val.chiefComplaint.length - 1
          //     ]
          //       ? val.chiefComplaint[val.chiefComplaint.length - 1]
          //           .chiefComplaintId.productionArea[0].productionAreaId.paName
          //       : "N/A"),
          //     (val.Id = val.identifier[0].value),
          //     (val.name = val.name[0].given[0] + " " + val.name[0].family)
          //   )
          // );
          res.data.data.map(
            (val) => (
              (val.productionArea = val.chiefComplaint[
                val.chiefComplaint.length - 1
              ]
                ? val.chiefComplaint[val.chiefComplaint.length - 1]
                    .chiefComplaintId.productionArea[0].productionAreaId.paName
                : "N/A"),
              (val.Id = val.identifier[0].value),
              (val.name = val.name[0].given[0] + " " + val.name[0].family),
              (val.shift = val.shift ? val.shift.name : "Not Assigned")
            )
          );

          setStaffData(res.data.data);
          console.log(
            "after map Search Anesthesiologist "
            // res.data.data.map((patInfo) => {
            //   console.log(
            //     ("" ,patInfo.patientName = patInfo.patientId.name[0].given[0])
            //   );
            // })
          );
        } else {
          console.log("Your   Not Found");
          setStaffData(" ");
        }
      } else if (!res.data.success) {
        setErrorMsg(res.data.error);
        setOpenNotification(true);
      }
    })
    .catch((e) => {
      console.log("error after searching patient request", e);
    });
};

export const getSearchSensei = (
  a,
  setStaffData,
  setOpenNotification,
  setErrorMsg
) => {
  axios
    .get(searchSensei + "/" + a)
    .then((res) => {
      console.log("res", res);
      if (res.data.success) {
        if (res.data.data) {
          console.log("Search Sensei ", res.data.data);
          res.data.data.map(
            (val) => (
              (val.stafName = val.name[0].given[0] + " " + val.name[0].family),
              (val.id = val.identifier[0].value),
              // (addedByName =
              //   val.addedBy.name[0] &&
              //   val.addedBy.name[0].given[0] +
              //     " " +
              //     val.addedBy.name[0].family),
              // (val.shiftStartTime = val.shift
              //   ? getTimeFromDate(val.shift.startTime)
              //   : "N/A"),
              (val.subType = val.subType.length > 0 && val.subType.toString()),
              (val.specialty =
                val.specialty.length > 0
                  ? val.specialty.toString()
                  : "No specialty"),
              (val.shiftName = val.shift.name ? val.shift.name : "No name"),
              // (val.experience =
              //   val.experience.length > 0
              //     ? val.experience[val.experience.length - 1].experience
              //     : "N/A"),
              (val.productionArea =
                val.chiefComplaint && val.chiefComplaint.length > 0
                  ? val.chiefComplaint[val.chiefComplaint.length - 1]
                      .chiefComplaintId.productionArea[
                      val.chiefComplaint[val.chiefComplaint.length - 1]
                        .chiefComplaintId.productionArea.length - 1
                    ].productionAreaId.paName
                  : "N/A")
              // (val.availability = val.availability ? "Yes" : "No")
            )
          );

          setStaffData(res.data.data);
        } else {
          console.log("Your  Sensei Not Found");
          setStaffData(" ");
        }
      } else if (!res.data.success) {
        setErrorMsg(res.data.error);
        setOpenNotification(true);
      }
    })
    .catch((e) => {
      console.log("error after searching patient request", e);
    });
};

export function deleteStaff(
  deleteItem,
  setdeleteItem,
  setModalVisible,
  setErrorMsg,
  setOpenNotification
) {
  axios
    .delete(deleteStaffUrl + "/" + deleteItem._id)
    .then((res) => {
      if (res.data.success) {
        setdeleteItem("");
        setModalVisible(false);
        window.location.reload(false);
      } else if (!res.data.success) {
        setErrorMsg(res.data.error);
        setOpenNotification(true);
      }
      return res;
    })
    .catch((e) => {
      console.log("error while deletion ", e);
    });
}

export const enableDisalbleStaff = (
  selectedRecord,
  obj,
  setOpenEnableDisabledModal,
  setSelectedRecord,
  setErrorMsg,
  setOpenNotification
) => {
  let url = !selectedRecord.disabled ? disableStaff : enableStaff;
  axios
    .put(url + "/" + selectedRecord._id, obj)
    .then((res) => {
      if (res.data.success) {
        setOpenEnableDisabledModal(false);
        setSelectedRecord("");
        window.location.reload(false);
      } else if (!res.data.success) {
        setErrorMsg(res.data.error);
        setOpenNotification(true);
      }
      return res;
    })
    .catch((e) => {
      console.log("error while deletion ", e);
    });
};

export function addNewStaffUser(
  props,
  formData,
  setStaffId,
  setOpenNotification,
  setErrorMsg
) {
  axios
    .post(registerStaff, formData, {
      headers: {
        accept: "application/json",
        "Accept-Language": "en-US,en;q=0.8",
        "content-type": "multipart/form-data",
      },
    })
    .then((res) => {
      if (res.data.success) {
        console.log("staff data", res.data);
        setStaffId(res.data.data._id);
        props.history.goBack();
      } else if (!res.data.success) {
        setOpenNotification(true);
      }
    })
    .catch((e) => {
      console.log("Error while adding staff", e);
      setOpenNotification(true);
      setErrorMsg("Error while adding staff");
    });
}

export function updateOldStaffUser(
  props,
  formData,
  setStaffId,
  setOpenNotification,
  setErrorMsg
) {
  axios
    .put(updateStaff, formData, {
      headers: {
        accept: "application/json",
        "Accept-Language": "en-US,en;q=0.8",
        "content-type": "multipart/form-data",
      },
    })
    .then((res) => {
      if (res.data.success) {
        console.log("res", res.data);
        setStaffId(res.data.data._id);
        props.history.goBack();
      } else if (!res.data.success) {
        setOpenNotification(true);
      }
    })
    .catch((e) => {
      console.log("Error while updating staff", e);
      setOpenNotification(true);
      setErrorMsg("Error while updating staff");
    });
}

export function allChiefComplaints(dispatch) {
  axios
    .get(getAllChiefComplaints)
    .then((response) => {
      dispatch({
        field: "chiefComplaintList",
        value: response.data.data.docs,
      });
    })
    .catch((error) => {
      console.log("Error", error);
    });
}

export function ShiftsCall(dispatch) {
  axios
    .get(getAllShiftsURL)
    .then((response) => {
      console.log("response shift", response.data.data);
      dispatch({
        field: "shiftList",
        value: response.data.data,
      });
    })
    .catch((error) => {
      console.log("Error", error);
    });
}

export function getAllPAsByCCs(
  value,
  dispatch,
  setOpenNotification,
  setErrorMsg
) {
  axios
    .get(getPAsByCCs + "/" + value)
    .then((res) => {
      if (res.data.success) {
        console.log("res", res.data);
        // setPaName(res.data.data)
        dispatch({
          field: "productionAreaList",
          value: res.data.data.map(
            (v) => v.productionArea[0].productionAreaId
            // (
            //   (v.paName = v.productionArea[0].productionAreaId.paName),
            //   (v._id = v.productionArea[0].productionAreaId._id)
            // )
          ),
        });

        // const pa = res.data.data.map(
        //   (v) => (
        //     (v.paName = v.productionArea[0].productionAreaId.paName),
        //     (v._id = v.productionArea[0].productionAreaId._id)
        //   )
        // )
        // dispatch({
        //   field: 'productionAreaList',
        //   value: pa,
        // })
        // console.log('PA', pa)
      } else if (!res.data.success) {
        setOpenNotification(true);
      }
    })
    .catch((e) => {
      console.log("Error while updating staff", e);
      setOpenNotification(true);
      setErrorMsg("Error while updating staff");
    });
}

export function updateAssignShift(params, setOpenNotification, setErrorMsg) {
  axios
    .put(AssignShifts, params)
    .then((res) => {
      if (res.data.success) {
        console.log("res", res.data);
        window.location.reload(false);
      } else if (!res.data.success) {
        setErrorMsg("Staff already assigned to Visit / Staff disabled");
        setOpenNotification(true);
      }
    })
    .catch((e) => {
      console.log("Error while updating staff", e);
      setOpenNotification(true);
      setErrorMsg("Error while updating staff");
    });
}
