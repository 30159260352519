import React from 'react'
import { Route, Switch } from 'react-router-dom'
import NotFound from '../components/NotFound/NotFound'
import MostSelectedScreen from '../views/MostSelected/mostSelected'
import StaffInShiftsScreen from '../views/StaffShift/staffShift'

class AdminDashboardScreens extends React.PureComponent {
    render() {
        return (
            <Switch>
                <Route
                    path={`${this.props.match.url}/mostSelected`}
                    component={MostSelectedScreen}
                />
                <Route
                    path={`${this.props.match.url}/staffInShifts`}
                    component={StaffInShiftsScreen}
                />

                <Route path={`${this.props.match.url}/notfound`} component={NotFound} />

                <Route path='*' component={NotFound} />
            </Switch>
        )
    }
}
export default AdminDashboardScreens