import React, { useState, useEffect } from "react";
import Notification from "../../components/Snackbar/Notification.js";
import CustomTable from "../../components/Table/Table";
import axios from "axios";
import _ from "lodash";
import Button from "@material-ui/core/Button";
import {
  getEDRsWithCareStream,
  searchEdrWithCareStream,
} from "../../public/endpoins";
import Back from "../../assets/img/Back_Arrow.png";
import Header from "../../components/Header/Header";
import Lab_OPR from "../../assets/img/Pending Tasks.png";
import Pending_Patients from "../../assets/img/Patients.png";
import ButtonField from "../../components/common/Button";
import Fingerprint from "../../assets/img/fingerprint.png";
import AccountCircle from "@material-ui/icons/SearchOutlined";
import InputAdornment from "@material-ui/core/InputAdornment";
import BarCode from "../../assets/img/Bar Code.png";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import "../../assets/jss/material-dashboard-react/components/loaderStyle.css";
import socketIOClient from "socket.io-client";
import cookie from "react-cookies";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const tableHeading = [
  "MRN",
  "Patient Name",
  "Production Area",
  "CareStream",
  "Date/Time",
  "Status",
  "Action",
];
const tableDataKeys = [
  "mrn",
  "patientName",
  "productionArea",
  "careStreams",
  "createdAt",
  "status",
];

const styles = {
  textFieldPadding: {
    paddingLeft: 5,
    paddingRight: 5,
  },
};

const useStylesForInput = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(0),
  },
  input: {
    backgroundColor: "white",
    borderRadius: 5,
    "&:after": {
      borderBottomColor: "black",
    },
    "&:hover": {
      backgroundColor: "white",
    },
    "&:focus": {
      boxShadow: "none",
      borderRadius: 5,
    },
  },
  multilineColor: {
    backgroundColor: "white",
    borderRadius: 5,
    "&:hover": {
      backgroundColor: "white",
    },
    "&:after": {
      borderBottomColor: "black",
    },
    "&:focus": {
      backgroundColor: "white",
      boxShadow: "none",
    },
  },
  root: {
    "& .MuiTextField-root": {
      backgroundColor: "white",
    },
    "& .Mui-focused": {
      backgroundColor: "white",
      color: "black",
    },
    "& .Mui-disabled": {
      backgroundColor: "white",
      color: "gray",
    },
    "&:focus": {
      backgroundColor: "white",
      boxShadow: "none",
    },
    "& .MuiFormLabel-root": {
      fontSize: "11px",

      paddingRight: "45px",
    },
  },
  label: {
    "&$focusedLabel": {
      color: "red",
      display: "none",
    },
    // "&$erroredLabel": {
    //   color: "orange"
    // }
  },
  focusedLabel: {},
}));

const actions = { view: true };

export default function EDR(props) {
  const matches = useMediaQuery("(min-width:600px)");
  const classes = useStylesForInput();

  const [labInPatient, setlabInPatient] = useState([]);
  const [errorMsg, setErrorMsg] = useState("");
  const [openNotification, setOpenNotification] = useState(false);
  const [searchPatientQuery, setSearchPatientQuery] = useState("");

  if (openNotification) {
    setTimeout(() => {
      setOpenNotification(false);
      setErrorMsg("");
    }, 2000);
  }

  useEffect(() => {
    getPatientData();

    // return () => socket.disconnect();
  }, []);

  function getPatientData() {
    axios
      .get(getEDRsWithCareStream)
      .then((res) => {
        if (res.data.success) {
          res.data.data.map(
            (d) =>
              (d.patientName =
                d.patientId.name[0].given[0] + " " + d.patientId.name[0].family)
          );
          res.data.data.map((d) => (d.createdAt = d.patientId.createdAt));
          res.data.data.map((d) => (d.mrn = d.patientId.identifier[0].value));
          res.data.data.map(
            (d) =>
              (d.productionArea =
                d.chiefComplaint[
                  d.chiefComplaint.length - 1
                ].chiefComplaintId.productionArea[
                  d.chiefComplaint[d.chiefComplaint.length - 1].chiefComplaintId
                    .productionArea.length - 1
                ].productionAreaId.paName)
          );
          res.data.data.map(
            (v) =>
              (v.careStreams =
                v.careStream &&
                v.careStream.length > 0 &&
                v.careStream[v.careStream.length - 1].careStreamId.name)
          );
          // res.data.data.map(
          //   (v) =>
          //     (v.consultantId = v.consultationNote.map(
          //       (val) => val.consultant && val.consultant._id
          //     ))
          // )

          var allLabSorted = _.sortBy(res.data.data, "createdAt").reverse();
          setlabInPatient(allLabSorted);
        } else if (!res.data.success) {
          setErrorMsg(res.data.error);
          setOpenNotification(true);
        }
        return res;
      })
      .catch((e) => {
        console.log("error: ", e);
      });
  }
  console.log("current user", cookie.load("current_user"));
  console.log(labInPatient);

  function handleView(rec) {
    console.log("rec", rec);

    if (
      rec.chiefComplaint.length > 0 &&
      rec.chiefComplaint &&
      rec.chiefComplaint[rec.chiefComplaint.length - 1].chiefComplaintId
    ) {
      rec.patientId.chiefComplaint =
        rec.chiefComplaint[rec.chiefComplaint.length - 1].chiefComplaintId.name;
    }

    props.history.push({
      pathname: "/dashboard/home/notes",
      state: {
        selectedRecord: rec,
        tabValue: 6,
      },
    });
  }

  const handlePatientSearch = (e) => {
    const a = e.target.value.replace(/[^\w\s]/gi, "");
    setSearchPatientQuery(a);
    if (a.length >= 3) {
      axios
        .get(searchEdrWithCareStream + "/" + a)
        .then((res) => {
          console.log("res after searching", res);
          if (res.data.success) {
            if (res.data.data) {
              res.data.data.map(
                (d) =>
                  (d.patientName =
                    d.patientId.name[0].given[0] +
                    " " +
                    d.patientId.name[0].family)
              );
              res.data.data.map((d) => (d.createdAt = d.patientId.createdAt));
              res.data.data.map(
                (d) => (d.mrn = d.patientId.identifier[0].value)
              );
              res.data.data.map(
                (d) =>
                  (d.productionArea =
                    d.chiefComplaint[
                      d.chiefComplaint.length - 1
                    ].chiefComplaintId.productionArea[
                      d.chiefComplaint[d.chiefComplaint.length - 1]
                        .chiefComplaintId.productionArea.length - 1
                    ].productionAreaId.paName)
              );
              res.data.data.map(
                (v) =>
                  (v.careStreams =
                    v.careStream &&
                    v.careStream.length > 0 &&
                    v.careStream[v.careStream.length - 1].careStreamId.name)
              );
              var sortedObjs = _.sortBy(res.data.data, "createdAt").reverse();
              setlabInPatient(sortedObjs);
              // var sortedObjs = _.sortBy(res.data.data, 'date').reverse()
              // setlabInPatient(res.data.data)
            } else {
              setlabInPatient(" ");
            }
          }
        })
        .catch((e) => {
          console.log("error after searching patient request", e);
        });
    } else if (a.length == 0) {
      getPatientData();
    }
  };

  // const addNewItem = () => {
  //   props.history.push({
  //     pathname: 'patientregistration',
  //     state: { comingFor: 'add' },
  //   })
  // }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        position: "fixed",
        width: "100%",
        height: "100%",
        backgroundColor: "rgb(19 213 159)",
        overflowY: "scroll",
      }}
    >
      <Header history={props.history} />

      <div className="cPadding">
        <div className="subheader">
          <div>
            <img src={Pending_Patients} />
            <h4>Reconciliation Requests</h4>
          </div>
          {/* <div>
            <ButtonField onClick={addNewItem} name='add' />
          </div> */}
        </div>

        <div
          className={`${"container-fluid"} ${classes.root}`}
          style={{
            marginTop: "25px",
            paddingLeft: "10px",
            paddingRight: "10px",
          }}
        >
          <div className="row">
            <div
              className="col-md-10 col-sm-9 col-8"
              style={styles.textFieldPadding}
            >
              <TextField
                className="textInputStyle"
                id="searchPatientQuery"
                type="text"
                variant="filled"
                label="Search Patient by Name / MRN / National ID / Mobile Number"
                name={"searchPatientQuery"}
                value={searchPatientQuery}
                onChange={handlePatientSearch}
                InputLabelProps={{
                  classes: {
                    root: classes.label,
                    focused: classes.focusedLabel,
                    error: classes.erroredLabel,
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <AccountCircle />
                    </InputAdornment>
                  ),
                  className: classes.input,
                  classes: { input: classes.input },
                  disableUnderline: true,
                }}
              />
            </div>

            <div
              className="col-md-1 col-sm-2 col-2"
              style={{
                ...styles.textFieldPadding,
                paddingLeft: matches ? 5 : 0,
                paddingRight: matches ? 5 : 3,
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "white",
                  borderRadius: 5,
                  height: 55,
                }}
              >
                <img src={BarCode} style={{ width: 70, height: 60 }} />
              </div>
            </div>

            <div
              className="col-md-1 col-sm-1 col-2"
              style={{
                ...styles.textFieldPadding,
                paddingLeft: matches ? 5 : 2,
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "white",
                  borderRadius: 5,
                  height: 55,
                }}
              >
                <img src={Fingerprint} style={{ maxWidth: 43, height: 43 }} />
              </div>
            </div>
          </div>
        </div>

        <div
          style={{
            flex: 4,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div>
            {labInPatient !== " " ? (
              <div>
                <div>
                  <CustomTable
                    tableData={labInPatient}
                    tableDataKeys={tableDataKeys}
                    tableHeading={tableHeading}
                    action={actions}
                    handleView={handleView}
                    borderBottomColor={"#60d69f"}
                    borderBottomWidth={20}
                  />
                </div>
                <div style={{ marginTop: 20, marginBottom: 20 }}>
                  <img
                    onClick={() => props.history.goBack()}
                    src={Back}
                    style={{
                      width: 45,
                      height: 35,
                      cursor: "pointer",
                    }}
                  />
                </div>
                <Notification msg={errorMsg} open={openNotification} />
              </div>
            ) : (
              <div className="row " style={{ marginTop: "25px" }}>
                <div className="col-11">
                  <h3
                    style={{
                      color: "white",
                      textAlign: "center",
                      width: "100%",
                      position: "absolute",
                    }}
                  >
                    Opps...No Data Found
                  </h3>
                </div>
                <div className="col-1" style={{ marginTop: 45 }}>
                  <img
                    onClick={() => props.history.goBack()}
                    src={Back}
                    style={{
                      maxWidth: "60%",
                      height: "auto",
                      cursor: "pointer",
                    }}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
