import { Grid, Typography, Checkbox, makeStyles } from "@material-ui/core"
import React from "react"

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    marginBottom: 20,
    marginLeft: 10,
    marginRight: 10,

    "& .Mui-focused": {
      borderBottom: 0,
    },
  },

  viewButtonLabel: { textTransform: "none", color: "white" },
}))

export default function Assign(props) {
  const classes = useStyles()

  console.log("props", props)
  return (
    <div className={classes.root}>
      <Grid style={{ backgroundColor: "white" }}>
        <Grid container>
          <Grid item xs={12} sm container style={{ margin: 20 }}>
            <Grid item xs container direction="column">
              <Typography m={2} color={"textSecondary"}>
                {props.heading}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid container style={{ display: "grid" }}>
          {props.arrayContainerCS.length === 0 ? (
            <Typography>{`There is no ${props.heading}`}</Typography>
          ) : (
            <Grid>
              {props.arrayContainerCS.map((item, i) => (
                <Grid
                  key={i}
                  container
                  style={{
                    marginTop: 0,
                    marginBottom: 10,
                    padding: 11,
                    backgroundColor: "#FBFBFB",
                  }}
                >
                  <Grid item style={{ width: "98%", display: "flex" }}>
                    <Checkbox
                      disabled
                      inputProps={{ "aria-label": "disabled checkbox" }}
                    />
                    <Typography style={{ paddingTop: 9, marginLeft: 15 }}>
                      {item.name ? item.name : item}
                    </Typography>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          )}

          {props.arrayContainerCS.length === 0 ? (
            <Typography>{`There is no ${props.heading}`}</Typography>
          ) : (
            <Grid>
              {props.arrayContainerCS.map((item, i) => (
                <Grid item>
                  {item.subType ? (
                    <Grid
                      style={{
                        width: "100%",
                      }}
                      item
                    >
                      {item.subType.length === 0 ? (
                        undefined
                      ) : (
                        <div>
                          {item.subType.map((subType, j) => {
                            return (
                              <Grid
                                style={{
                                  marginRight: 20,
                                  display: "flex",
                                  marginBottom: 10,
                                  backgroundColor: "#FBFBFB",
                                }}
                              >
                                <Checkbox
                                  disabled
                                  style={{ marginLeft: 20 }}
                                  inputProps={{
                                    "aria-label": "disabled checkbox",
                                  }}
                                />
                                <Typography
                                  key={j}
                                  style={{
                                    borderRadius: 2,
                                    paddingLeft: 20,
                                    marginTop: 10,
                                  }}
                                >
                                  {subType}
                                </Typography>
                              </Grid>
                            )
                          })}
                        </div>
                      )}
                    </Grid>
                  ) : (
                    undefined
                  )}
                </Grid>
              ))}
            </Grid>
          )}
        </Grid>
      </Grid>
    </div>
  )
}
