import React from 'react'
import { Route, Switch } from 'react-router-dom'
import NotFound from '../components/NotFound/NotFound'
import Department from '../views/Home/SenseiSubMenus/Department/Department'
import EDEOURoomsMgmt from '../views/Home/SenseiSubMenus/EDEOUroomsMgmt/edeouRoomsMgmt'
import StaffManagement from '../views/UserManagement/staff/staff'
import AddEditStaffManagement from '../views/UserManagement/staff/addEditStaff'
import CommunicationManagement from '../views/CommunicationMgmt/communicationMgmt'
import ViewCommunicationRequests from '../views/CommunicationMgmt/viewCommunicationRequests'
import ProductionArea from '../views/Home/SenseiSubMenus/ProductionArea/ProductionArea'
import AssingPA from '../views/ProductionAreaForSensei/AssignPAToPatient'
import CodeBlueTeam from '../views/CodeBlueTeam/codeBlue'
import PANurses from '../views/ChiefComplaints/assignCCtoNurses'
import PADoctors from '../views/ChiefComplaints/assignCCtoDoctors'
import ManagePA from '../views/ProductionAreaForSensei/ManagePA'
import ViewManagePA from '../views/ProductionAreaForSensei/ViewManagePA'
import ViewAssingPA from '../views/ProductionAreaForSensei/ViewAssignPA'
// ED and EOU Rooms management views
import EDRoomsHistory from '../views/EDroomsMgmt/history'
import EDRoomsCurrentActivity from '../views/EDroomsMgmt/currentActivity'
import EOURoomsHistory from '../views/EOUroomsMgmt/history'
import EOURoomsCurrentActivity from '../views/EOUroomsMgmt/currentActivity'
import PharmaNotes from '../views/PharmaNotes/addpharmacyRequest'

import ManageProductionTeam from '../views/ProductionTeams/Manage'
import CreateUpdateProductionTeam from '../views/ProductionTeams/CreateUpdate'



// import EDEEOU from "../views/EDEOU/EDEOU"
// import ViewEDEEOU from "../views/DetailsScreen/detailsScreen"

class PatientLogRoutes extends React.PureComponent {
  render() {
    return (
      <Switch>
        <Route exact path={`${this.props.match.url}`} component={Department} />
        <Route
          exact
          path={`${this.props.match.url}/staff`}
          component={StaffManagement}
        />
        <Route
          exact
          path={`${this.props.match.url}/staff/add`}
          component={AddEditStaffManagement}
        />
        <Route
          exact
          path={`${this.props.match.url}/staff/edit`}
          component={AddEditStaffManagement}
        />
        <Route
          exact
          path={`${this.props.match.url}/communicationMgmt`}
          component={CommunicationManagement}
        />
        <Route
          exact
          path={`${this.props.match.url}/communicationMgmt/viewRequests`}
          component={ViewCommunicationRequests}
        />


        <Route
          exact
          path={`${this.props.match.url}/production-teams`}
          component={ManageProductionTeam}
        />
        <Route
          exact
          path={`${this.props.match.url}/production-teams/create`}
          component={CreateUpdateProductionTeam}
        />
        <Route
          exact
          path={`${this.props.match.url}/production-teams/edit`}
          component={CreateUpdateProductionTeam}
        />

        <Route
          exact
          path={`${this.props.match.url}/productionarea`}
          component={ProductionArea}
        />
        <Route
          exact
          path={`${this.props.match.url}/productionarea/assignpa`}
          component={AssingPA}
        />

        <Route
          exact
          path={`${this.props.match.url}/codeblue`}
          component={CodeBlueTeam}
        />

        <Route
          exact
          path={`${this.props.match.url}/productionarea/PANurses`}
          component={PANurses}
        />

        <Route
          exact
          path={`${this.props.match.url}/productionarea/PADoctors`}
          component={PADoctors}
        />

        <Route
          exact
          path={`${this.props.match.url}/productionarea/assignpa/assignpaview`}
          component={ViewAssingPA}
        />
        <Route
          exact
          path={`${this.props.match.url}/productionarea/managepa`}
          component={ManagePA}
        />
        <Route
          exact
          path={`${this.props.match.url}/productionarea/managepa/viewmanagepa`}
          component={ViewManagePA}
        />
        <Route
          exact
          path={`${this.props.match.url}/edRoomsMgmt`}
          component={EDEOURoomsMgmt}
        />
        <Route
          exact
          path={`${this.props.match.url}/edRoomsMgmt/history`}
          component={EDRoomsHistory}
        />
        <Route
          exact
          path={`${this.props.match.url}/edRoomsMgmt/currentActivity`}
          component={EDRoomsCurrentActivity}
        />
        <Route
          exact
          path={`${this.props.match.url}/eouRoomsMgmt`}
          component={EDEOURoomsMgmt}
        />
        <Route
          exact
          path={`${this.props.match.url}/eouRoomsMgmt/history`}
          component={EOURoomsHistory}
        />
        <Route
          exact
          path={`${this.props.match.url}/eouRoomsMgmt/currentActivity`}
          component={EOURoomsCurrentActivity}
        />
        <Route
          exact
          path={`${this.props.match.url}/eouRoomsMgmt/currentActivity/pharmaNotes`}
          component={PharmaNotes}
        />

        {/* <Route exact path={`${this.props.match.url}/ED`} component={EDEEOU} />
        <Route
          exact
          path={`${this.props.match.url}/ED/viewED`}
          component={ViewEDEEOU}
        />
        <Route exact path={`${this.props.match.url}/EOU`} component={EDEEOU} />
        <Route
          exact
          path={`${this.props.match.url}/EOU/viewEOU`}
          component={ViewEDEEOU}
        />

        <Route path={`${this.props.match.url}/notfound`} component={NotFound} />

        <Route path="*" component={NotFound} /> */}
      </Switch>
    )
  }
}
export default PatientLogRoutes
