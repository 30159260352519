import React, { useEffect, useState, useReducer } from "react";
import { makeStyles } from "@material-ui/core/styles";
import tableStyles from "../../assets/jss/material-dashboard-react/components/tableStyle.js";
import Loader from "react-loader-spinner";
import { useTheme, Slide, Dialog, DialogTitle, DialogContent, Backdrop, Fade } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import PatientDetailsModal from "./PatientDetailsDialogueDirect";
import axios from "axios";
import moment from "moment";
import {
  getSingleEdr,
} from "../../public/endpoins";
import FormatDate from "../../utils/formatDateTime";
import { displayInHumanDifference, replacePatientIdIfNull } from "../../utils/commonFunc";
import _ from "lodash";
import classNames from "classnames";

let matches = false;

const styles = {
  inputContainerForTextField: {
    marginTop: 25,
  },

  inputContainerForDropDown: {
    marginTop: 25,
  },

  stylesForLabel: {
    fontWeight: "700",
    color: "white",
  },

  stylesForButton: {
    color: "white",
    cursor: "pointer",
    borderRadius: 15,
    backgroundColor: "#2C6DDD",
    width: "140px",
    height: "50px",
    outline: "none",
  },

  stylesForPurchaseButton: {
    color: "white",
    cursor: "pointer",
    borderRadius: 15,
    backgroundColor: "#2C6DDD",
    width: "60%",
    height: "50px",
    outline: "none",
  },

  forTableCell: {
    color: "black",
    fontSize: 14,
  },

  stylesForPatientButton: {
    color: "white",
    cursor: "pointer",
    borderRadius: 10,
    backgroundColor: "#2C6DDD",
    width: "140px",
    height: "45px",
    // outline: "none",
    // alignSelf:'center'
    marginTop: 30,
  },

  headingStyles: {
    fontWeight: "bold",
    color: "grey",
  },

  root: {
    flexGrow: 1,
  },

  textStyles: {
    fontWeight: "700",
    color: "black",
    fontSize: 12,
    textOverflow: "ellipsis",
    width: "90%",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },

  headerHeading: {
    display: "flex",
    // justifyContent: "center",
    // alignItems: "center",
    // verticalAlign: "center",
    paddingTop: 9,
    paddingLeft: 0,
  },
  headerHeadingText: {
    fontWeight: "700",
    color: "white",
    fontSize: 12,
  },
};
const useStyles = makeStyles(tableStyles);

function PatientDetailsInfo (props) {
  const theme = useTheme();
  matches = useMediaQuery(theme.breakpoints.up("sm"));

  const classes = useStyles();

  const [isLoading, setisLoading] = useState(true);
  const [patientDetails, setPatientDetails] = useState([]);
  const [patientDetailsModal, setPatientDetailsModal] = useState(false);
  const [doctorNotesDiagnosis, setDoctorNotesDiagnosis] = useState([]);
  const [value, setValue] = React.useState(0);
  const [ openMedicationHistory, setOpenMedicationHistory ] = useState(false);
  const [ openNonPharmaHistory, setOpenNonPharmaHistory ] = useState(false);
  const [ allPharmaReports, setAllPharmaReports ] = useState([]);
  const [ allNonPharmaReports, setAllNonPharmaReports ] = useState([]);

  const handleChange = (event, newValue) => {
    // console.log(newValue);
    setValue(newValue);
  };

  const handleOpenMedicationHistory = () => {
    setOpenMedicationHistory(true);
  };

  const closeMedicationHistory = () => {
    setOpenMedicationHistory(false);
  };

  const handleOpenNonPharmaHistory = () => {
    setOpenNonPharmaHistory(true);
  };

  const closeNonPharmaHistory = () => {
    setOpenNonPharmaHistory(false);
  };

  const filterDoctorNotesDiagnosis = (val) => {
    /*val.map(
      (d) => (
        (d.assignedTime = d.assignedTime ? FormatDate(d.assignedTime) : ""),
        (d.doctor = d.addedBy.name[0].given[0] + " " + d.addedBy.name[0].family),
        (d.diagnosis = d.code.length)
      )
    );

    const doctorNotesSorted = _.sortBy(val, "assignedTime").reverse();*/


    if (val && val.length > 0) {
      let data = [];
      let dataCodeName = [];
      val.map((d) => {
        d.code.map((codes) => {
          let singleCode = codes._id;
          // console.log("singleCode::", singleCode);
          let found = data.find((i) => i === singleCode);
          if (!found) {
            data.push(singleCode);
            dataCodeName.push(codes.short_desc); //codes.code + ": " + 
          }
        });
      });

      setDoctorNotesDiagnosis(dataCodeName);
    }


  }

  useEffect(() => {
    // console.log("response single edr::", props);
    if (props.edrId){
      axios
        .get(`${getSingleEdr}/${props.edrId}`)
        .then((res) => {

          let dataReturn = replacePatientIdIfNull(res.data.data);
          // console.log("response single edr::", dataReturn);


          let itemsCount = 0
          let itemsNonCount = 0
              // console.log('BC::', dataReturn);
          if ( dataReturn.pharmacyRequest.length > 0 ){
            Object.entries(dataReturn.pharmacyRequest).map(([i, d]) =>{
              // console.log('BC::', d.item);
              if (d.generatedFor==="pharmaceutical"){
                itemsCount += d.item.length;
              }else if (d.generatedFor==="non_pharmaceutical"){
                itemsNonCount += d.item.length;
              }
            })
          }
          dataReturn.pharmacyRequestsCount = itemsCount
          dataReturn.nonPharmacyRequestsCount = itemsNonCount
          console.log("PatientClinicalInformation::", dataReturn);



          setPatientDetails(dataReturn);
          setisLoading(false);
          if (res.data.data.doctorNotes){
            filterDoctorNotesDiagnosis(res.data.data.doctorNotes);
          }
          // console.log("response single edr:: patientDetails", patientDetails);


          //
          let finalLabRequests = [];
          let finalRadRequests = [];
          let finalPharmaRequests = [];
          let finalNonPharmaRequests = [];
          let finalDischargePharmaRequests = [];

          // 
          if (dataReturn.pharmacyRequest && dataReturn.pharmacyRequest.length > 0){
            let pharmacyRequestsArray = dataReturn.pharmacyRequest;
            Object.entries(pharmacyRequestsArray).map(([did, d]) => {
              let finalPharmaRequest = d;
              finalPharmaRequest.requester = finalPharmaRequest.requestedBy && finalPharmaRequest.requestedBy.name[0].given[0] + " " + finalPharmaRequest.requestedBy.name[0].family;
              finalPharmaRequest.requestDate = moment(finalPharmaRequest.createdAt).format('MMM DD, YYYY LT');
              Object.entries(finalPharmaRequest.item).map(([key, val]) => {
                if (finalPharmaRequest.generatedFor==="pharmaceutical"){
                  finalPharmaRequests.push({
                    parentRequest: finalPharmaRequest,
                    subRequest: val
                  });
                }else if (finalPharmaRequest.generatedFor==="non_pharmaceutical"){
                  finalNonPharmaRequests.push({
                    parentRequest: finalPharmaRequest,
                    subRequest: val
                  });
                }else{
                  finalDischargePharmaRequests.push({
                    parentRequest: finalPharmaRequest,
                    subRequest: val
                  });
                }
              });
            });
            setAllPharmaReports(finalPharmaRequests);
            setAllNonPharmaReports(finalNonPharmaRequests);
          }

          return res;
        })
        .catch((e) => {
          // console.log("response single edr:: error: ", e);
        });
    }
  }, [props.edrId])

  return (
    isLoading===true?
    <div style={{ margin: "auto", width: "0%", padding: 10 }}><Loader type="ThreeDots" color="blue" height={50} width={50} /></div>:
    <div className={classNames(`container-fluid`)}>
      <div className="row">
        <div className="col-12">
        {patientDetails ? (
          <>
            <span
              style={{
                fontSize: 16,
                fontWeight: "bold",
                color: "white",
                marginTop: 15,
              }}
            >
              Patient Details
            </span>

            <div
              // className="container-fluid"
              style={{
                marginTop: 15,
                backgroundColor: "white",
                // minHeight: 200,
                borderRadius: 5,
                width: "100%",
                maxHeight: "300px",
                overflowY: "scroll-x",
                overflowX: "hidden",
              }}
            >
              {/* </Paper> */}

              <div className="container-fluid">
                <div
                  className="row"
                  style={{
                    backgroundColor: "#2C6DDD",
                    // marginTop: 20,
                    paddingLeft: 10,
                    minHeight: "20%",
                    borderTopLeftRadius: 5,
                    borderTopRightRadius: 5,
                    paddingBottom: 10,
                    paddingTop: 10,
                    // minWidth: 600,
                  }}
                  onClick={() => setPatientDetailsModal(true)}
                >
                  <div
                    className={matches ? "col-2" : "col-4"}
                    style={styles.headerHeading}
                  >
                    <h6
                      style={{
                        ...styles.headerHeadingText,
                        fontSize: matches ? 14 : 10,
                      }}
                    >
                      Patient Info
                    </h6>
                  </div>
                  {/* <div className={"col-3"} style={styles.headerHeading}>
                    <h6
                      style={{
                        ...styles.headerHeadingText,
                        fontSize: matches ? 14 : 12,
                      }}
                    >
                      Allergy
                    </h6>
                  </div> */}
                  {/* <div className={"col-2"} style={styles.headerHeading}>
                    <h6
                      style={{
                        ...styles.headerHeadingText,
                        fontSize: matches ? 14 : 10,
                      }}
                    >
                      Medication
                    </h6>
                  </div> */}

                  <div
                    className={matches ? "col-2" : "col-4"}
                    style={styles.headerHeading}
                  >
                    <h6
                      style={{
                        ...styles.headerHeadingText,
                        fontSize: matches ? 14 : 10,
                      }}
                    >
                      Chief Complaint
                    </h6>
                  </div>

                  <div
                    className={matches ? "col-2" : "col-4"}
                    style={styles.headerHeading}
                  >
                    <h6
                      style={{
                        ...styles.headerHeadingText,
                        fontSize: matches ? 14 : 10,
                      }}
                    >
                      Triage Level
                    </h6>
                  </div>
                  <div
                    className={matches ? "col-2" : "col-4"}
                    style={styles.headerHeading}
                  >
                    <h6
                      style={{
                        ...styles.headerHeadingText,
                        fontSize: matches ? 14 : 10,
                      }}
                    >
                      CareStream
                    </h6>
                  </div>
                  {matches ? (
                    <div className={"col-2"} style={styles.headerHeading}>
                      <h6
                        style={{
                          ...styles.headerHeadingText,
                          fontSize: matches ? 14 : 10,
                        }}
                      >
                        Diagnosis
                      </h6>
                    </div>
                  ) : (
                    undefined
                  )}
                  {matches ? (
                    <div
                      className={"col-2"}
                      style={{
                        ...styles.headerHeading,
                        paddingLeft: matches ? 10 : 0,
                      }}
                    >
                      <h6
                        style={{
                          ...styles.headerHeadingText,
                          fontSize: matches ? 14 : 10,
                        }}
                      >
                        Medication/Non-Pharma
                      </h6>
                    </div>
                  ) : (
                    undefined
                  )}
                </div>
              </div>

              <div
                className="row"
                style={{
                  marginTop: 10,
                  paddingLeft: 10,
                  paddingBottom: 10,
                  // minWidth: 600,
                  // overflow: "scroll",
                }}
              >
                <div
                  className={matches ? "col-2" : "col-4"}
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <span
                    style={{
                      ...styles.headingStyles,
                      fontSize: matches ? 12 : 9,
                    }}
                  >
                    Patient
                  </span>
                  <span style={styles.textStyles}>
                    { 
                      patientDetails && patientDetails.patientId
                      ? patientDetails.patientId.name[0].given[0] + " " + patientDetails.patientId.name[0].family
                      : undefined
                    }
                  </span>
                  <span
                    style={{
                      ...styles.headingStyles,
                      fontSize: matches ? 12 : 9,
                    }}
                  >
                    Episode #
                  </span>
                  <span style={styles.textStyles}>
                    {patientDetails && patientDetails.requestNo
                      ? patientDetails.requestNo
                      : "-"}
                  </span>
                  {/* <span
                    style={{
                      ...styles.headingStyles,
                      fontSize: matches ? 12 : 9,
                    }}
                  >
                    Age
                  </span>
                  <span style={styles.textStyles}>
                    {patientDetails && patientDetails.age}
                  </span> */}

                  <span
                    style={{
                      ...styles.headingStyles,
                      fontSize: matches ? 12 : 9,
                    }}
                  >
                    Gender
                  </span>
                  <span style={styles.textStyles}>
                    {patientDetails && patientDetails.patientId
                      ? patientDetails.patientId.gender
                      : undefined}
                  </span>

                  {/* <span
                    style={{
                      ...styles.headingStyles,
                      fontSize: matches ? 12 : 9,
                    }}
                  >
                    Weight
                  </span>
                  <span style={styles.textStyles}>
                    {patientDetails && patientDetails.weight} kg
                  </span> */}

                  <span
                    style={{
                      ...styles.headingStyles,
                      fontSize: matches ? 12 : 9,
                    }}
                  >
                    Department
                  </span>
                  <span style={styles.textStyles}>
                    {patientDetails && patientDetails.currentLocation}
                  </span>
                </div>

                {/* <div className={"col-3"} style={{ paddingLeft: 0 }}>
                  {patientDetails.drugAllergy &&
                    patientDetails.drugAllergy.map((drug) => {
                      return <h6 style={styles.textStyles}>{drug}</h6>;
                    })}
                </div> */}

                {/* <div className={"col-2"} style={{ paddingLeft: 0 }}>
                  {props.pharmacyRequest &&
                    props.pharmacyRequest.map((d, index) => {
                      return (
                        <div style={{ display: "flex", flexDirection: "row" }}>
                          <span
                            style={{
                              ...styles.textStyles,
                            }}
                          >
                            {index + 1}
                            {"."} &nbsp;
                            {d}
                          </span>
                        </div>
                      );
                    })}
                </div> */}

                <div
                  className={matches ? "col-2" : "col-4"}
                  style={{ paddingLeft: 10, display: "flex", flexDirection: "column" }}
                >
                  {
                    props.showChiefComplaint && patientDetails.chiefComplaint.length > 0?
                    (
                      <>
                        {( patientDetails.chiefComplaint[ patientDetails.chiefComplaint.length-1 ].chiefComplaintId.code == "OTHER" ? undefined :
                          <>
                        <span
                          style={{
                            ...styles.headingStyles,
                            fontSize: matches ? 12 : 9,
                          }}
                        >
                          Primary Chief Complaint
                        </span>
                        <span style={styles.textStyles}>
                          {
                            /*patientDetails.chiefComplaint[ patientDetails.chiefComplaint.length-1 ].chiefComplaintId.code + ": " +*/ patientDetails.chiefComplaint[ patientDetails.chiefComplaint.length-1 ].chiefComplaintId.short_desc
                          }
                        </span>
                        </>
                        )}
                        {
                          patientDetails.chiefComplaint[ patientDetails.chiefComplaint.length-1 ].chiefComplaintOthers && 
                          patientDetails.chiefComplaint[ patientDetails.chiefComplaint.length-1 ].chiefComplaintOthers.length>0 ?
                          (
                            <>
                              <span
                                style={{
                                  ...styles.headingStyles,
                                  fontSize: matches ? 12 : 9,
                                }}
                              >
                                Other Complaints`
                              </span>
                              <span style={styles.textStyles}>
                                {
                                  patientDetails.chiefComplaint[ patientDetails.chiefComplaint.length-1 ].chiefComplaintOthers.map((v) => {
                                    return `${v.short_desc}` //${v.code}: 
                                  })
                                }
                              </span>
                            </>
                          ) : undefined
                        }
                      </>
                    ) : 
                    undefined
                  }
                </div>

                <div
                  className={matches ? "col-2" : "col-4"}
                  style={{ paddingLeft: 10 }}
                >
                  <span style={{ ...styles.headingStyles, fontSize: matches ? 12 : 9,}}>
                    { 
                      props.showTriageLevel && patientDetails.dcdForm.length>0 && patientDetails.dcdForm[ patientDetails.dcdForm.length - 1 ].triageAssessment.length>0 ? patientDetails.dcdForm[ patientDetails.dcdForm.length - 1 ].triageAssessment[ patientDetails.dcdForm[ patientDetails.dcdForm.length - 1 ].triageAssessment.length - 1 ].triageLevel.toString(): "N/A"
                    }
                  </span>
                </div>

                <div
                  className={matches ? "col-2" : "col-4"}
                  style={{ paddingLeft: 10 }}
                >
                  {props.showCareStream && props.careStreamArray &&
                    props.careStreamArray.map((d, index) => {
                      return (
                        <div style={{ display: "flex", flexDirection: "row" }}>
                          <span
                            style={{
                              ...styles.textStyles,
                            }}
                          >
                            {index + 1}
                            {"."} &nbsp;
                            {d.name}
                          </span>
                        </div>
                      );
                    })}
                </div>
                {matches ? (
                  <div className={"col-2"} style={{ paddingLeft: 0 }}>
                    {doctorNotesDiagnosis && props.showDiagnosis &&
                      doctorNotesDiagnosis.map((d, index) => {
                        return (
                          <div
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <span
                              style={{
                                ...styles.textStyles,
                              }}
                            >
                              {index + 1}
                              {"."} &nbsp;
                              {d}
                            </span>
                          </div>
                        );
                      })}
                  </div>
                ) : (
                  undefined
                )}
                {matches ? (
                  <div className={"col-2"} style={{ paddingLeft: 0 }}>
                    <div style={{
                      ...styles.textStyles,
                      fontSize: matches ? 12 : 9
                    }} onClick={handleOpenMedicationHistory}>{patientDetails.pharmacyRequestsCount} Medications</div>
                    <div style={{
                      ...styles.textStyles,
                      fontSize: matches ? 12 : 9
                    }} onClick={handleOpenNonPharmaHistory}>{patientDetails.nonPharmacyRequestsCount} Non-Pharma</div>
                    {props.pharmacyRequest &&
                      props.pharmacyRequest.map((d, index) => {
                        return (
                          <div
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <span
                              style={{
                                ...styles.textStyles,
                              }}
                            >
                              {index + 1}
                              {"."} &nbsp;
                              {d}
                            </span>
                          </div>
                        );
                      })}
                  </div>
                ) : (
                  undefined
                )}
              </div>

              {patientDetailsModal ? (
                <PatientDetailsModal
                  openPatientDetailsDialog={PatientDetailsModal}
                  setPatientDetailsModal={setPatientDetailsModal}
                  patientDetails={patientDetails}
                  showChiefComplaint={props.showChiefComplaint}
                  showTriageLevel={props.showTriageLevel}
                  showCareStream={props.showCareStream}
                  showDiagnosis={props.showDiagnosis}
                  showMedication={props.showMedication}
                />
              ) : (
                undefined
              )}


              <Dialog
                aria-labelledby="medication-modal-title"
                aria-describedby="medication-modal-description"
                maxWidth="lg"
                open={openMedicationHistory}
                onClose={closeMedicationHistory}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 500,
                }}
              >
                <Fade in={openMedicationHistory}>
                  <div className={classes.paper}>
                    <DialogTitle id="medication-modal-title">Pharma Items</DialogTitle>
                    <DialogContent>
                      <div className="row">
                        <div className="col-md-12 col-sm-12">
                          <table className="table table-sm table-bordered table-stripped">
                            <tr>
                              <th width="15%" className="text-left bg-light">Date &amp; Time</th>
                              <th width="15%" className="text-left bg-light">Request ID</th>
                              <th className="text-left bg-light">Item</th>
                              <th width="25%" className="text-center bg-light">Request by</th>
                            </tr>
                            {
                              allPharmaReports && allPharmaReports.length>0?
                              Object.entries(allPharmaReports).map(([ai,av]) => {
                                return (
                                  <tr>
                                    <td>{av.parentRequest.requestDate}</td>
                                    <td>{av.parentRequest.pharmacyRequestNo}</td>
                                    <td>
                                      <strong>{av.subRequest.itemId.itemCode}:</strong> {av.subRequest.itemId.name}
                                      <div><small><strong>Administered:</strong> {av.subRequest.administeredDetails.length} / {(Math.ceil(av.subRequest.frequency)>=11 || Math.ceil(av.subRequest.frequency)===0)?av.subRequest.requestedQty:av.subRequest.dosage*av.subRequest.duration*av.subRequest.frequency}</small></div>
                                    </td>
                                    <td>{av.parentRequest.requester}</td>
                                  </tr>
                                )
                              })
                              :<tr><td className="text-center" colspan="6">No Pharma Request!</td></tr>
                            }
                          </table>
                        </div>
                      </div>
                    </DialogContent>
                  </div>
                </Fade>
              </Dialog>


              <Dialog
                aria-labelledby="nonpharma-modal-title"
                aria-describedby="nonpharma-modal-description"
                maxWidth="lg"
                open={openNonPharmaHistory}
                onClose={closeNonPharmaHistory}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 500,
                }}
              >
                <Fade in={openNonPharmaHistory}>
                  <div className={classes.paper}>
                    <DialogTitle id="nonpharma-modal-title">Non-Pharma Items</DialogTitle>
                    <DialogContent>
                      <div className="row">
                        <div className="col-md-12 col-sm-12">
                          <table className="table table-sm table-bordered table-stripped">
                            <tr>
                              <th width="15%" className="text-left bg-light">Date &amp; Time</th>
                              <th width="15%" className="text-left bg-light">Request ID</th>
                              <th className="text-left bg-light">Item</th>
                              <th width="25%" className="text-center bg-light">Request by</th>
                            </tr>
                            {
                              allNonPharmaReports && allNonPharmaReports.length>0?
                              Object.entries(allNonPharmaReports).map(([ai,av]) => {
                                return (
                                  <tr>
                                    <td>{av.parentRequest.requestDate}</td>
                                    <td>{av.parentRequest.pharmacyRequestNo}</td>
                                    <td>
                                      <strong>{av.subRequest.itemId.itemCode}:</strong> {av.subRequest.itemId.name||av.subRequest.itemId.description}
                                      <div><small><strong>Administered:</strong> {av.subRequest.administeredDetails.length} / {(Math.ceil(av.subRequest.frequency)>=11 || Math.ceil(av.subRequest.frequency)===0)?av.subRequest.requestedQty:av.subRequest.dosage*av.subRequest.duration*av.subRequest.frequency}</small></div>
                                    </td>
                                    <td>{av.parentRequest.requester}</td>
                                  </tr>
                                )
                              })
                              :<tr><td className="text-center" colspan="6">No Non-Pharma Request!</td></tr>
                            }
                          </table>
                        </div>
                      </div>
                    </DialogContent>
                  </div>
                </Fade>
              </Dialog>

            </div>
          </>
        ) : undefined}
        </div>
      </div>
    </div>
  );
}
export default PatientDetailsInfo ;
