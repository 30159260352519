import React, { useEffect, useState, useReducer } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  Button, FormControl, InputAdornment, InputLabel, MenuItem, 
  Select, Slider, Tabs, Tab, TextField, Typography
} from '@material-ui/core';
import tableStyles from "../../assets/jss/material-dashboard-react/components/tableStyle.js";
import Header from "../../components/Header/Header";
import business_Unit from "../../assets/img/Triage & Assessment Icon White.png";
import Back from "../../assets/img/Back_Arrow.png";
import cookie from "react-cookies";
import axios from "axios";
import _ from "lodash";
import { addTriageAssesment } from "../../public/endpoins";
import "../../assets/jss/material-dashboard-react/components/TextInputStyle.css";
import Notification from "../../components/Snackbar/Notification.js";
import CustomTable from "../../components/Table/Table";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import PatientDetailsQuick from "../../components/PatientDetails/PatientDetailsQuick";
// import Guage from "../../components/common/Guage";

import GCSModal from "./GSCModel";
import { getTriageLevelValue } from "./TriageLevelValue";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Input from '@material-ui/core/Input';
import Chip from '@material-ui/core/Chip';


import ViewSingleTriage from "./ViewSingleTriage";

const tableHeadingForTriage = [
  "Request No.",
  "Date/Time",
  "Doctor/Staff",
  "Triage Level",
  // "General Appearance",
  // "Head Neck",
  // "Neurological",
  // "Respiratory",
  // "Cardiac",
  // "Abdomen",
  "Actions",
];

const actionsForTriage = { view: true };

const tableDataKeysForTriage = [
  "triageRequestNo",
  "date",
  "doctorName",
  "triageLevel",
  // "status",
  // "generalAppearance",
  // "headNeck",
  // "neurological",
  // "respiratory",
  // "cardiac",
  // "abdomen",
];

const tableHeadingForVitalSigns = [
  "Request No.",
  "Date/Time",
  "Doctor/Staff",
  "Triage Level",
  // "Status",
  // "Heart Rate",
  // "BP (Systolic)",
  // "BP (Diastolic)",
  // "Respiratory Rate",
  // "Temperature",
  // "FSBS",
  // "Pain Scale",
  // "Pulse OX",
  "Actions",
];
const tableDataKeysForVitalSigns = [
  "triageRequestNo",
  "date",
  "doctorName",
  "triageLevel",
  // "status",
  // "heartRate",
  // "bloodPressureSys",
  // "bloodPressureDia",
  // "respiratoryRate",
  // "temperature",
  // "FSBS",
  // "painScale",
  // "pulseOX",
];


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const letStyleMarkLabels = {
  marginTop: '8px',
  '&[data-index="0"]': {
    left: '0!important',
    transform: 'none!important'
  },
  '&[data-index="2"]': {
    left: 'auto!important',
    right: '0!important',
    transform: 'none!important'
  },
};
const letStyleMarkLabels2 = {
  marginTop: '8px',
  '&[data-index="0"]': {
    left: '0!important',
    transform: 'none!important'
  },
  '&[data-index="1"]': {
    left: 'auto!important',
    right: '0!important',
    transform: 'none!important'
  },
};

const styles = {

  triageBox: {
    borderRadius: '3px',
    padding: "3px 6px",
    display: 'inline-block',
    marginLeft: '6px',
    color: '#fff',
  },
  triageNonUrgent: {
    backgroundColor: '#fff',
    color: '#000'
  },
  triageLessUrgent: {
    backgroundColor: '#00b04e',
  },
  triageUrgent: {
    backgroundColor: '#ffff00',
    color: '#000'
  },
  triageEmergent: {
    backgroundColor: '#fe0000',
  },
  triageResuscitation: {
    backgroundColor: '#0071bd',
  },


  stylesForButton: {
    color: "white",
    cursor: "pointer",
    borderRadius: 5,
    backgroundColor: "#2c6ddd",
    width: "120px",
    height: "45px",
    outline: "none",
  },
  stylesForAssessment: {
    color: "white",
    cursor: "pointer",
    borderRadius: 5,
    backgroundColor: "#2c6ddd",
    height: "45px",
    outline: "none",
  },
  stylesForSliderLabels: {
    marginLeft: "-5px",
    marginRight: "-5px",
    marginTop: "10px",
    paddingTop: "10px",
    paddingBottom: "10px",
    color: 'green',
    textAlign: 'center',
    borderTop: "1px dashed #cecece"
  },
  stylesForSlider: {
    paddingTop: "50px",
    paddingLeft: "25px",
    paddingRight: "20px",
    backgroundColor: "#FFF",
    borderRadius: '6px'
  },
  stylesForBMI: {
    paddingTop: "30px",
    paddingLeft: "30px",
    paddingRight: "30px",
    backgroundColor: "#FFF",
    borderRadius: '6px'
  },
  stylesForBMIText: {
    'fontSize': '25px',
    fontWeight: '700',
  },
  stylesForBMIUnderweight: {
    'color': 'red',
    marginTop: 8,
    marginBottom: 20,
  },
  stylesForBMIHealthy: {
    'color': 'green',
    marginTop: 8,
    marginBottom: 20,
  },
  stylesForBMIOverweight: {
    'color': '#adad06',
    marginTop: 8,
    marginBottom: 20,
  },
  stylesForBMIObese: {
    'color': 'brown',
    marginTop: 8,
    marginBottom: 20,
  },
  stylesForSliderBP: {
    paddingTop: "50px",
    paddingLeft: "50px",
    paddingRight: "50px",
    backgroundColor: "#FFF",
    borderRadius: '6px'
  },
};

const useStylesForTabs = makeStyles((theme) => ({
  root: {
    justifyContent: "center",
  },
  scroller: {
    flexGrow: "0",
  },
}));

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(0),
  },
  input: {
    backgroundColor: "white",
    boxShadow: "none",
    borderRadius: 5,
    "&:after": {
      borderBottomColor: "black",
      boxShadow: "none",
    },
    "&:hover": {
      backgroundColor: "white",
      boxShadow: "none",
    },
    "&:focus": {
      backgroundColor: "white",
      boxShadow: "none",
    },
  },
  multilineColor: {
    boxShadow: "none",
    backgroundColor: "white",
    borderRadius: 5,
    "&:hover": {
      backgroundColor: "white",
      boxShadow: "none",
    },
    "&:after": {
      borderBottomColor: "black",
      boxShadow: "none",
    },
    "&:focus": {
      boxShadow: "none",
    },
  },
  root: {
    "& .MuiTextField-root": {
      backgroundColor: "white",
    },
    "& .Mui-focused": {
      backgroundColor: "white",
      // color: "black",
      boxShadow: "none",
    },
    "& .Mui-disabled": {
      backgroundColor: "white",
      color: "gray",
    },
    "&:focus": {
      backgroundColor: "white",
      boxShadow: "none",
    },
  },
}));

const PainScaleSlider = withStyles({
  root: {
    //color: '#52af77',
    color: 'linear-gradient(45deg, #aa6775 30%, #984355 90%)', // Works
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    marginTop: -8,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 8,
    borderRadius: 4,
    background: 'transparent'
    // backgroundImage: "linear-gradient(to right, #16c100 0%,#edc32d 40%,#edc32d 40%,#edc32d 60%,#ed2024 100%)"
  },
  rail: {
    height: 8,
    borderRadius: 4,
    opacity: 1,
    backgroundImage: "linear-gradient(to right, #16c100 0%,#ed2024 100%)"
  },
  markLabel: letStyleMarkLabels
})(Slider);

const PulseOxSlider = withStyles({
  root: {
    // color: '#52af77',
    color: 'linear-gradient(45deg, #aa6775 30%, #984355 90%)', // Works
    // color: 'linear-gradient(45deg, #aa6775 30%, #984355 90%)', // Works
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    marginTop: -8,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 8,
    borderRadius: 4,
    background: 'transparent'
    // backgroundImage: "linear-gradient(to right, #ed2024 0%,#edc32d 40%,#edc32d 40%,#edc32d 60%,#16c100 100%)"
  },
  rail: {
    height: 8,
    borderRadius: 4,
    opacity: 1,
    backgroundImage: "linear-gradient(to right, #ed2024 0%,#ed2024 50%,#f6eb14 50%,#f6eb14 68.75%,#16c100 68.75%,#16c100 100%)"
  },
  markLabel: letStyleMarkLabels
})(Slider);

const RespiratorySlider = withStyles({
  root: {
    // color: '#52af77',
    color: 'linear-gradient(45deg, #aa6775 30%, #984355 90%)', // Works
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    marginTop: -8,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 8,
    borderRadius: 4,
    background: 'transparent'
    // backgroundImage: "linear-gradient(to right, #ed2024 0%,#edc32d 40%,#edc32d 40%,#edc32d 60%,#16c100 100%)"
  },
  rail: {
    height: 8,
    borderRadius: 4,
    opacity: 1,
    backgroundImage: "linear-gradient(to right, #ed2024 0%, #ed2024 17.39%,#16c100 17.39%,#16c100 47.83%,#f6eb14 47.83%,#f6eb14 56.52%,#ed2024 56.52%,#ed2024 100%)"
  },
  markLabel: letStyleMarkLabels
})(Slider);

const SystolicBPSlider = withStyles({
  root: {
    // color: '#52af77',
    color: 'linear-gradient(45deg, #aa6775 30%, #984355 90%)', // Works
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    marginTop: -8,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 8,
    borderRadius: 4,
    background: 'transparent'
    // backgroundImage: "linear-gradient(to right, #ed2024 0%,#edc32d 40%,#edc32d 40%,#edc32d 60%,#16c100 100%)"
  },
  rail: {
    height: 8,
    borderRadius: 4,
    opacity: 1,
    backgroundImage: "linear-gradient(to right, #ed2024 0%,#ed2024 7.80%,#16c100 7.80%,#16c100 29.08%,#f6eb14 29.08%,#f6eb14 70.92%,#ed2024 70.92%,#ed2024 100%)"
  },
  markLabel: letStyleMarkLabels
})(Slider);

const DiastolicBPSlider = withStyles({
  root: {
    // color: '#52af77',
    color: 'linear-gradient(45deg, #aa6775 30%, #984355 90%)', // Works
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    marginTop: -8,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 8,
    borderRadius: 4,
    background: 'transparent'
    // backgroundImage: "linear-gradient(to right, #ed2024 0%,#edc32d 40%,#edc32d 40%,#edc32d 60%,#16c100 100%)"
  },
  rail: {
    height: 8,
    borderRadius: 4,
    opacity: 1,
    backgroundImage: "linear-gradient(to right, #ed2024 0%,#ed2024 12.09%,#16c100 12.09%,#16c100 34.07%,#f6eb14 34.07%,#f6eb14 67.03%,#ed2024 67.03%,#ed2024 100%)"
  },
  markLabel: letStyleMarkLabels
})(Slider);

const TempratureSlider = withStyles({
  root: {
    // color: '#52af77',
    color: 'linear-gradient(45deg, #aa6775 30%, #984355 90%)', // Works
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    marginTop: -8,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 8,
    borderRadius: 4,
    background: 'transparent'
    // backgroundImage: "linear-gradient(to right, #ed2024 0%,#edc32d 40%,#edc32d 40%,#edc32d 60%,#16c100 100%)"
  },
  rail: {
    height: 8,
    borderRadius: 4,
    opacity: 1,
    backgroundImage: "linear-gradient(to right,#ed2024 0%,#ed2024 50.41%,#16c100 50.41%,#16c100 60.33%,#f6eb14 60.33%,#f6eb14 66.94%,#ed2024 66.94%,#ed2024 100%)"
  },
  markLabel: letStyleMarkLabels
})(Slider);

const HeartRateSlider = withStyles({
  root: {
    // color: '#52af77',
    color: 'linear-gradient(45deg, #aa6775 30%, #984355 90%)', // Works
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    marginTop: -8,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 8,
    borderRadius: 4,
    background: 'transparent'
    // backgroundImage: "linear-gradient(to right, #ed2024 0%,#edc32d 40%,#edc32d 40%,#edc32d 60%,#16c100 100%)"
  },
  rail: {
    height: 8,
    borderRadius: 4,
    opacity: 1,
    backgroundImage: "linear-gradient(to right, #ed2024 0%,#ed2024 8.40%,#16c100 8.40%,#16c100 31.30%,#f6eb14 31.30%,#f6eb14 38.17%,#ed2024 38.17%,#ed2024 100%)"
  },
  markLabel: letStyleMarkLabels
})(Slider);

const TriageProcessSlider = withStyles({
  root: {
    // color: '#52af77',
    color: 'linear-gradient(45deg, #aa6775 30%, #984355 90%)', // Works
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    marginTop: -8,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 8,
    borderRadius: 4,
    background: 'transparent'
    // backgroundImage: "linear-gradient(to right, #ed2024 0%,#edc32d 40%,#edc32d 40%,#edc32d 60%,#16c100 100%)"
  },
  rail: {
    height: 8,
    borderRadius: 4,
    opacity: 1,
    backgroundImage: "linear-gradient(to right, #ed2024 0%, #edc32d 50%,#16c100 100%)"
  },
})(Slider);



const NormalSlider = withStyles({
  root: {
    // color: '#52af77',
    color: 'linear-gradient(45deg, #aa6775 30%, #984355 90%)', // Works
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    marginTop: -8,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 8,
    borderRadius: 4,
    background: 'transparent'
    // backgroundImage: "linear-gradient(to right, #ed2024 0%,#edc32d 40%,#edc32d 40%,#edc32d 60%,#16c100 100%)"
  },
  rail: {
    height: 8,
    borderRadius: 4,
    opacity: 1,
    backgroundColor: "#16c100"
  },
  markLabel: letStyleMarkLabels2
})(Slider);


function TriageAndAssessment(props) {
  const matches = useMediaQuery("(min-width:600px)");
  const classes = useStyles();

  var initialState = {
    triageAssessmentArray: "",
    reasonChangeTriageLevel: "",
    newTriageLevel: "",

    isResuscitation: false,
    triageLevelDisplay: 5,
    triageLevel: ["NonUrgent"],
    generalAppearance: "",
    headNeck: "",
    respiratory: "",
    cardiac: "",
    abdomen: "",
    neurological: "",
    gcsScore: 0,
    gcsScoreValues: [-1,-1,-1,-1],

    generalAppearanceText: "",
    headNeckText: "",
    respiratoryText: "",
    cardiacText: "",
    abdomenText: "",
    neurologicalText: "",

    heartRate: "0", //72
    bloodPressureSys: "0", //120
    bloodPressureDia: "0", //80
    respiratoryRate: "0", //12
    temperature: 0, //37
    FSBS: "",
    weight: 0,
    SIZE: 0,
    PO2: "0",
    BMI: "0",
    BMIRange: "-",
    IOP: "",
    IOPRight: "",
    painScale: "",
    pulseOX: "0", //95
    finalObject,
  };

  function reducer(state, { field, value }) {
    return {
      ...state,
      [field]: value,
    };
  }

  const [state, dispatch] = useReducer(reducer, initialState);

  var {
    triageAssessmentArray,

    isResuscitation,
    reasonChangeTriageLevel,
    newTriageLevel,
    triageLevelDisplay,
    triageLevel,
    generalAppearance,
    headNeck,
    respiratory,
    cardiac,
    abdomen,
    neurological,
    gcsScore,
    gcsScoreValues,

    generalAppearanceText,
    headNeckText,
    respiratoryText,
    cardiacText,
    abdomenText,
    neurologicalText,

    heartRate,
    bloodPressureSys,
    bloodPressureDia,
    respiratoryRate,
    temperature,
    weight,
    FSBS,
    SIZE,
    PO2,
    BMI,
    BMIRange,
    IOP,
    IOPRight,
    painScale,
    pulseOX,
    finalObject,
  } = state;

  const classesForTabs = useStylesForTabs();

  const [value, setValue] = useState(0);
  const [currentUser, setCurrentUser] = useState(cookie.load("current_user"));

  const [historyValue, sethistoryValue] = useState(0);
  const [id, setId] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setsuccessMsg] = useState("");
  const [requestType, setrequestType] = useState("");
  const [openNotification, setOpenNotification] = useState(false);
  const [MRN, setMRN] = useState("");
  const [patientId, setpatientId] = useState("");

  const [generalAppearanceBoolean, setGeneralAppearanceBoolean] = useState(
    false
  );
  const [headNeckBoolean, setHeadNeckBoolean] = useState(false);
  const [neurologicalBoolean, setNeurologicalBoolean] = useState(false);
  const [respiratoryBoolean, setRespiratoryBoolean] = useState(false);
  const [cardiacBoolean, setCardiacBoolean] = useState(false);
  const [abdomenBoolean, setAbdomenBoolean] = useState(false);

  const [openGCSModal, setVisibilityGCSModal] = useState(false);

  const [openDialogForTriage, setOpenDialogForTriage] = useState(false);

  const [selectedTriage, setSelectedTriage] = useState(false);
  const [openChangeTriageLevel, setOpenChangeTriageLevel] = useState(false);

  const [sliderMode, setSliderMode] = useState(true);

  const onChangeValue = (e) => {
    // console.log("onChangeValue", e.target);
    dispatch({ field: e.target.name, value: e.target.value });
  };

  function hideTriageDialog() {
    setOpenDialogForTriage(false);
    setValue(0);
  }

  useEffect(() => {
    setCurrentUser(cookie.load("current_user"));

    // if (selectedRec.triageAssessment) {
    //   selectedRec.triageAssessment.map(
    //     (d) =>
    //       (d.doctorName = d.requester
    //         ? d.requester.firstName + " " + d.requester.lastName
    //         : ""),
    //   )

    //   console.log("selectedRec.triageAssessment", selectedRec.triageAssessment)

    //   let temp = []

    //   for (let i = 0; i < selectedRec.triageAssessment.length; i++) {
    //     let singleT = [...selectedRec.triageAssessment[i].triageLevel]

    //     let t = singleT.toString()
    //     // for (let j = 0; j < singleT.length; j++) {
    //     //   if (singleT[j] !== "") {
    //     //     if (j === singleT.length - 1) {
    //     //       t = t + singleT[j];
    //     //     } else {
    //     //       t = t + singleT[j] + ", ";
    //     //     }
    //     //   }
    //     // }

    //     let singleGeneral = [
    //       ...selectedRec.triageAssessment[i].generalAppearance,
    //     ]

    //     let ga = singleGeneral.toString()

    //     let singleHead = [...selectedRec.triageAssessment[i].headNeck]
    //     let h = singleHead.toString()

    //     let singleResp = [...selectedRec.triageAssessment[i].respiratory]
    //     let r = singleResp.toString()

    //     let singleAbdomen = [...selectedRec.triageAssessment[i].abdomen]
    //     let a = singleAbdomen.toString()

    //     let singleNeurological = [
    //       ...selectedRec.triageAssessment[i].neurological,
    //     ]
    //     let n = singleNeurological.toString()

    //     let singleCardiac = [...selectedRec.triageAssessment[i].cardiac]
    //     let c = singleCardiac.toString()

    //     temp.push({
    //       ...selectedRec.triageAssessment[i],
    //       generalAppearance: ga,
    //       triageLevel: t,
    //       headNeck: h,
    //       abdomen: a,
    //       neurological: n,
    //       respiratory: r,
    //       cardiac: c,
    //     })
    //   }

    //   console.log("temp", temp)

    //   let obj = { ...selectedRec, triageAssessment: [...temp] }

    //   dispatch({
    //     field: "triageAssessmentArray",
    //     value: _.sortBy(obj.triageAssessment.reverse(), "date").reverse(),
    //   })
    // }
  }, []);

  useEffect(() => {
    getTriageLevelValue(
      isResuscitation,
      heartRate,
      triageLevel,
      generalAppearance,
      temperature,
      headNeck,
      respiratory,
      cardiac,
      abdomen,
      neurological,
      bloodPressureSys,
      bloodPressureDia,
      painScale,
      respiratoryRate,
      pulseOX,
      dispatch
    );
  }, [heartRate, temperature, bloodPressureSys, bloodPressureDia, painScale, respiratoryRate, pulseOX]);

  useEffect(() => {
    if (SIZE > 0 && weight > 0){
      let BMICalc = (weight / ((SIZE * SIZE) / 10000)).toFixed(2)
      dispatch({ field: 'BMI', value: BMICalc });

      let BMIRangeText = "Healthy";
      if (BMICalc < 18.5){
        BMIRangeText = "Underweight";
      } else if (BMICalc >= 18.5 && BMICalc <= 24.9){
        BMIRangeText = "Healthy";
      } else if (BMICalc >= 25 && BMICalc <= 29.9){
        BMIRangeText = "Overweight";
      } else if (BMICalc >= 30){
        BMIRangeText = "Obese";
      }


      dispatch({ field: 'BMIRange', value: BMIRangeText });


      // console.log("BMI", BMICalc);
    }
  }, [SIZE, weight]);

  const handleChangeTriageLevel = () => {
    if (reasonChangeTriageLevel === "" && newTriageLevel !== ""){
      setOpenNotification(true);
      setErrorMsg("Please fill up the form correctly.");
    }else{
      setOpenChangeTriageLevel(false);
    }
  }

  const onCheckedValue = (e) => {
    // console.log("ds", e.target.value)
    // if (e.target.value === "generalAppearanceText") {
    //   dispatch({ field: "generalAppearanceText", value: "" })
    // } else if (e.target.value === "respiratoryText") {
    //   dispatch({ field: "respiratoryText", value: "" })
    // } else if (e.target.value === "neurologicalText") {
    //   dispatch({ field: "neurologicalText", value: "" })
    // } else if (e.target.value === "headNeckText") {
    //   dispatch({ field: "headNeckText", value: "" })
    // } else if (e.target.value === "abdomenText") {
    //   dispatch({ field: "abdomenText", value: "" })
    // } else if (e.target.value === "cardiacText") {
    //   dispatch({ field: "cardiacText", value: "" })
    // } else {
    //   // dispatch({ field: "generalAppearanceText", value: null })
    //   dispatch({ field: "respiratoryText", value: null })
    //   dispatch({ field: "neurologicalText", value: null })
    //   dispatch({ field: "headNeckText", value: null })
    //   dispatch({ field: "abdomenText", value: null })
    //   dispatch({ field: "cardiacText", value: null })
    // }
    // dispatch({ field: e.target.name, value: e.target.value })
  };

  const checkPage1 = () => {
    console.log("handleChange", heartRate + " <= 0 || " + temperature + " <= 0 || " + bloodPressureSys + " <= 0 || " + bloodPressureDia + " <= 0 || " + painScale + " <= 0 || " + respiratoryRate + " <= 0 || " + pulseOX + " <= 0");
    if (
      isResuscitation === true
    ){
      return true;
    }else if (
      isResuscitation ===false && 
      (heartRate <= 0 || temperature <= 0 || bloodPressureSys <= 0 || bloodPressureDia <= 0 || painScale <= 0 || respiratoryRate <= 0 || pulseOX <= 0 || SIZE <= 0 || weight <= 0 || IOPRight <= 0 || IOP <= 0 || FSBS <= 0)
    ){
      return false;
    }
    return true;
  }

  const checkPage2 = () => {
    console.log('checkPage2', generalAppearance, respiratory, cardiac, abdomen, neurological, gcsScore)
    if(
      generalAppearance === "" || respiratory === "" || cardiac === "" || abdomen === "" || neurological === "" || gcsScore === 0
    ){
      return false;
    }
    return true;
  }

  const handleChange = (event, newValue) => {
    // console.log("handleChange", value, newValue);
    let nValue = true;
    let nMessage = "";
    if (value == 0){
      if (checkPage1() === false){
        nValue = false;
        nMessage = "Please pick proper values for Vital Signs from the below ranges.";
      }
    }

    if (nValue){
      // console.log(newValue);
      setValue(newValue);
      // setValue(newValue);
    }else{
      setOpenNotification(true);
      setErrorMsg(nMessage);
    }
  };

  const handleHistoryTabChange = (event, newValue) => {
    sethistoryValue(newValue);
  };

  const onNext = () => {
    setValue(value + 1);
  };

  const onSpecify = (e) => {
    // if (e.target.name === "generalAppearance") {
    //   dispatch({ field: "generalAppearanceText", value: e.target.value })
    // } else if (e.target.name === "headNeck") {
    //   dispatch({ field: "headNeckText", value: e.target.value })
    // } else if (e.target.name === "respiratory") {
    //   dispatch({ field: "respiratoryText", value: e.target.value })
    // } else if (e.target.name === "abdomen") {
    //   dispatch({ field: "abdomenText", value: e.target.value })
    // } else if (e.target.name === "neurological") {
    //   dispatch({ field: "neurologicalText", value: e.target.value })
    // } else if (e.target.name === "cardiac") {
    //   dispatch({ field: "cardiacText", value: e.target.value })
    // }
    // dispatch({ field: e.target.name, value: e.target.value })
  };

  const onTextChange = (e) => {
    // if (
    //   (e.target.name === "painScale" && e.target.value < 7) ||
    //   e.target.value > 100
    // ) {
    //   return;
    // } else {
    dispatch({ field: e.target.name, value: e.target.value });
    // }
  };

  const onPainScaleChange = (e) => {
    if (
      (e.target.name === "painScale" && e.target.value < 0) ||
      e.target.value > 10
    ) {
      return;
    } else {
      dispatch({ field: e.target.name, value: e.target.value });
    }
  };

  const handleSwitchMode = (e) => {
    if (sliderMode === true){
      setSliderMode(false);
    }else{
      setSliderMode(true);
    }
  }

  const handleResuscitation = () => {
    if ( isResuscitation ){
      dispatch({ field: "isResuscitation", value: false });
      dispatch({ field: "triageLevel", value: ["NonUrgent"] });
    }else{
      dispatch({ field: "isResuscitation", value: true });
      dispatch({ field: "triageLevel", value: ["Resuscitation"] });
    }
  }

  const handleSubmitAssessment = (e) => {

    if (value === 0){
      if (checkPage1() === false){
        setOpenNotification(true);
        setErrorMsg("Please pick proper values for Vital Signs from the below ranges.");
      }else{
        setValue(1);
      }
    }else{

      if (checkPage2() == false){
        setOpenNotification(true);
        setErrorMsg("Please pick proper values for Physical Exams from the below page.");
      }else{
        var now = new Date();
        var start = new Date(now.getFullYear(), 0, 0);
        var diff =
          now -
          start +
          (start.getTimezoneOffset() - now.getTimezoneOffset()) * 60 * 1000;
        var oneDay = 1000 * 60 * 60 * 24;
        var day = Math.floor(diff / oneDay);

        var dateNow = new Date();
        var YYYY = dateNow
          .getFullYear()
          .toString()
          .substr(-2);
        var HH = dateNow.getHours();
        var mm = dateNow.getMinutes();
        let ss = dateNow.getSeconds();

        const TAArequestNo = "TAA" + day + YYYY + HH + mm + ss;

        if (generalAppearanceText !== "") {
          let w = generalAppearance.indexOf("");
          w = generalAppearanceText;
          generalAppearance = [...generalAppearance, w];
        }

        if (headNeckText !== "") {
          let w = headNeck.indexOf("");
          w = headNeckText;
          headNeck = [...headNeck, w];
        }

        if (respiratoryText !== "") {
          let w = respiratory.indexOf("");
          w = respiratoryText;
          respiratory = [...respiratory, w];
        }
        if (cardiacText !== "") {
          var w = cardiac.indexOf("");
          w = cardiacText;
          cardiac = [...cardiac, w];
        }
        if (abdomenText !== "") {
          var w = abdomen.indexOf("");
          w = abdomenText;
          abdomen = [...abdomen, w];
        }
        if (neurologicalText !== "") {
          var w = neurological.indexOf("");
          w = neurologicalText;
          neurological = [...neurological, w];
        }

        let object = {
          TAARequestNo: TAArequestNo,
          requester: currentUser.staffId,
          triageLevel: triageLevel,
          reasonChangeTriageLevel: reasonChangeTriageLevel,
          newTriageLevel: newTriageLevel,
          generalAppearance: generalAppearance,
          headNeck: headNeck,
          respiratory: respiratory,
          cardiac: cardiac,
          abdomen: abdomen,
          neurological: neurological,
          gcsScore: gcsScore,
          heartRate: heartRate === "" ? "N/A" : heartRate,
          bloodPressureSys: bloodPressureSys === "" ? "N/A" : bloodPressureSys,
          bloodPressureDia: bloodPressureDia === "" ? "N/A" : bloodPressureDia,
          respiratoryRate: respiratoryRate === "" ? "N/A" : respiratoryRate,
          temperature: temperature === "" ? "N/A" : temperature,
          FSBS: FSBS === "" ? "N/A" : FSBS,
          weight:     weight  === "" ? "N/A" : weight,
          SIZE:       SIZE    === "" ? "N/A" : SIZE,
          PO2:        PO2     === "" ? "N/A" : PO2,
          BMI:        BMI     === "" ? "N/A" : BMI,
          IOP:        IOP     === "" ? "N/A" : IOP,
          IOPRight:   IOPRight === "" ? "N/A": IOPRight,
          painScale: painScale === "" ? "N/A" : painScale,
          pulseOX: pulseOX === "" ? "N/A" : pulseOX,
          staffId: currentUser._id,
          patientId: props.history.location.state.selectedItem.patientId._id,
          edrId: props.history.location.state.selectedItem._id,
        };

        /*if (
          (FSBS === "" || (FSBS >= 70 && FSBS <= 100)) &&
          (heartRate === "" || (heartRate >= 60 && heartRate <= 100)) &&
          (respiratoryRate === "" ||
            (respiratoryRate >= 12 && respiratoryRate <= 18)) &&
          (bloodPressureSys === "" ||
            (bloodPressureSys >= 90 && bloodPressureSys <= 120)) &&
          (bloodPressureDia === "" ||
            (bloodPressureDia >= 60 && bloodPressureDia <= 80)) &&
          (temperature === "" || (temperature >= 97.8 && temperature <= 99.1)) &&
          (pulseOX === "" || pulseOX >= 95)
        ) {*/
        if (
          (FSBS === "" || (FSBS >= 0)) &&
          (heartRate === "" || (heartRate >= 0)) &&
          (respiratoryRate === "" || (respiratoryRate >= 0)) &&
          (bloodPressureSys === "" || (bloodPressureSys >= 0)) &&
          (bloodPressureDia === "" || (bloodPressureDia >= 0)) &&
          (temperature === "" || (temperature >= 0)) &&
          (pulseOX === "" || pulseOX >= 0)
        ) {
          props.history.push({
            pathname: "triageForm/viewTriageForm",
            state: {
              selectedTriage: object,
              others: props.history.location.state,
            },
          });
        } else {
          setOpenNotification(true);
          setErrorMsg("Please check for vital signs values");
        }
      }

      // console.log(object);

      // axios
      //   .put(addTriageAssesment, { data: object })
      //   .then((res) => {
      //     if (res.data.success) {
      //       console.log("SUCCESS")
      //       // console.log("Update Patient data patient assessment: ", res.data.data)
      //       // notifyForTriage(patientId)
      //       // props.history.push({
      //       //   pathname: "success",
      //       //   state: {
      //       //     message: `Triage & Assessment No: ${TAArequestNo.toUpperCase()} for patient MRN: ${res.data.data.patientId.profileNo.toUpperCase()} added successfully`,
      //       //   },
      //       //   comingFor: "Triage",
      //       // })
      //     } else if (!res.data.success) {
      //       setOpenNotification(true)
      //       setErrorMsg("Error in Submitting Assessment")
      //     }
      //   })
      //   .catch((e) => {
      //     console.log("error after submitting Assessment", e)
      //     setOpenNotification(true)
      //     setErrorMsg("Error while submitting Assessment")
      //   })
    }
  };

  const notifyForTriage = (id) => {
    axios
      // .get(notifyTriage + "/" + id)
      .then((res) => {
        console.log(res);
      })
      .catch((e) => {
        console.log("error after notify", e);
        setOpenNotification(true);
        setErrorMsg(e);
      });
  };

  if (openNotification) {
    setTimeout(() => {
      setOpenNotification(false);
      setErrorMsg("");
      setsuccessMsg("");
    }, 5000);
  }

  /*
  getTriageLevelValue(
    heartRate,
    triageLevel,
    generalAppearance,
    temperature,
    headNeck,
    respiratory,
    cardiac,
    abdomen,
    neurological,
    bloodPressureSys,
    bloodPressureDia,
    painScale,
    respiratoryRate,
    pulseOX,
    dispatch
  );*/

  const onChangeHandler = (e, index) => {
    var a = [...triageLevel];
    if (a.includes(e.target.value)) {
      for (var i = 0; i < a.length; i++) {
        if (a[i] === e.target.value) {
          a.splice(i, 1);
          // console.log("aaaaa::", a);
          dispatch({ field: "triageLevel", value: a });
        }
      }
    } else {
      a = [...triageLevel, e.target.value];
      dispatch({ field: "triageLevel", value: a });
    }
  };

  const onOtherTheValues = (e, min, max) => {
    let eValue = e.target.value;
    // if (e.target.name === "respiratoryRate") {
    if (eValue < min){
      eValue = min;
    }
    if (eValue > max){
      eValue = max;
    }
    // }

    console.log("e.target.name", min, max, e.target.name, eValue);
    dispatch({ field: e.target.name, value: eValue });
  }

  const onOtherChange = (e) => {
    // console.log("e.target.name", e.target.name);

    if (e.target.name === "headNeck") {
      dispatch({ field: "headNeckText", value: e.target.value });
    } else if (e.target.name === "generalAppearance") {
      setGeneralAppearanceBoolean(true);
      dispatch({ field: "generalAppearanceText", value: e.target.value });
    } else if (e.target.name === "respiratory") {
      dispatch({ field: "respiratoryText", value: e.target.value });
    } else if (e.target.name === "cardiac") {
      dispatch({ field: "cardiacText", value: e.target.value });
    } else if (e.target.name === "abdomen") {
      dispatch({ field: "abdomenText", value: e.target.value });
    } else {
      dispatch({ field: "neurologicalText", value: e.target.value });
    }
  };

  const onSelectChange = (e) => {
    // console.log("SelectChange", e.target);
    dispatch({field: e.target.name, value: e.target.value});
  }

  const onGeneralAppearance = (e) => {
    if (e.target.name === "generalAppearanceOther") {
      setGeneralAppearanceBoolean(true);
    }

    var a = [...generalAppearance];
    if (a.includes(e.target.value)) {
      for (var i = 0; i < a.length; i++) {
        if (a[i] === e.target.value) {
          a.splice(i, 1);
          dispatch({ field: "generalAppearance", value: a });
        }
      }
    } else {
      a = [...generalAppearance, e.target.value];
      dispatch({ field: "generalAppearance", value: a });
    }
  };

  if (openNotification) {
    setTimeout(() => {
      setOpenNotification(false);
      setErrorMsg("");
      setsuccessMsg("");
    }, 2000);
  }

  const onHeadNeck = (e) => {
    // if (e.target.value === "generalAppearance") {
    //   console.log("Name")
    // }

    if (e.target.name === "headNeckOther") {
      setHeadNeckBoolean(true);
    }

    var a = [...headNeck];
    if (a.includes(e.target.value)) {
      for (var i = 0; i < a.length; i++) {
        if (a[i] === e.target.value) {
          a.splice(i, 1);
          dispatch({ field: "headNeck", value: a });
        }
      }
    } else {
      a = [...headNeck, e.target.value];
      dispatch({ field: "headNeck", value: a });
    }
  };

  const onRespiratory = (e) => {
    // if (e.target.value === "generalAppearance") {
    //   console.log("Name")
    // }

    if (e.target.name === "respiratoryOther") {
      setRespiratoryBoolean(true);
    }

    var a = [...respiratory];
    if (a.includes(e.target.value)) {
      for (var i = 0; i < a.length; i++) {
        if (a[i] === e.target.value) {
          a.splice(i, 1);
          dispatch({ field: "respiratory", value: a });
        }
      }
    } else {
      a = [...respiratory, e.target.value];
      dispatch({ field: "respiratory", value: a });
    }
  };

  const onCardiac = (e) => {
    // if (e.target.value === "generalAppearance") {
    //   console.log("Name")
    // }

    if (e.target.name === "cardiacOther") {
      setCardiacBoolean(true);
    }

  
    var a = [...cardiac];
    if (a.includes(e.target.value)) {
      for (var i = 0; i < a.length; i++) {
        if (a[i] === e.target.value) {
          a.splice(i, 1);
          dispatch({ field: "cardiac", value: a });
        }
      }
    } else {
      a = [...cardiac, e.target.value];
      dispatch({ field: "cardiac", value: a });
    }
  };

  const onAbdomen = (e) => {
    // if (e.target.value === "generalAppearance") {
    //   console.log("Name")
    // }

    if (e.target.name === "abdomenOther") {
      setAbdomenBoolean(true);
    }

    var a = [...abdomen];
    if (a.includes(e.target.value)) {
      for (var i = 0; i < a.length; i++) {
        if (a[i] === e.target.value) {
          a.splice(i, 1);
          dispatch({ field: "abdomen", value: a });
        }
      }
    } else {
      a = [...abdomen, e.target.value];
      dispatch({ field: "abdomen", value: a });
    }
  };

  const onNeurological = (e) => {
    // if (e.target.value === "generalAppearance") {
    //   console.log("Name")
    // }

    if (e.target.name === "neurologicalOther") {
      setNeurologicalBoolean(true);
    }

    if (e.target.value === "GCS") {
      if (checkForGCSValue()) {
        let a = [...neurological];
        let b = [];
        for (let i = 0; i < a.length; i++) {
          if (!a[i].includes(":")) {
            b.push(a[i]);
          }
        }
        dispatch({ field: "neurological", value: b });

        return;
      }
      setVisibilityGCSModal(true);
    }

    var a = [...neurological];
    if (a.includes(e.target.value)) {
      for (var i = 0; i < a.length; i++) {
        if (a[i] === e.target.value) {
          a.splice(i, 1);
          dispatch({ field: "neurological", value: a });
        }
      }
    } else {
      a = [...neurological, e.target.value];
      dispatch({ field: "neurological", value: a });
    }
  };

  function hideGCSModal() {
    var a = [...neurological];
    if (a.includes("GCS")) {
      for (var i = 0; i < a.length; i++) {
        if (a[i] === "GCS") {
          a.splice(i, 1);
          dispatch({ field: "neurological", value: a });
        }
      }
    }
    setVisibilityGCSModal(false);
  }

  function handleGCSDone(gcsValue) {
    dispatch({ field: "gcsScore", value: gcsValue });
    setVisibilityGCSModal(false);
  }

  function dataGSCModal(dGSCModal){
    console.log('gcsScoreValues::', gcsScoreValues);
    console.log('gcsScoreValues::', dGSCModal);
    dispatch({ field: "gcsScoreValues", value: dGSCModal });
  }

  function checkForGCSValue() {
    for (let i = 0; i < neurological.length; i++) {
      if (
        (neurological[i].includes("GCS") ||
          neurological[0].split(":")[0] === "GCS") &&
        neurological.length > 0
      ) {
        return true;
      }
    }
    return false;
  }

  function findGCSValue() {
    for (let i = 0; i < neurological.length; i++) {
      if (
        (neurological[i].includes("GCS") ||
          neurological[0].split(":")[0] === "GCS") &&
        neurological.length > 0
      ) {
        return neurological[i];
      }
    }
    return false;
  }

  // console.log("triageLevel", triageLevel);
  // console.log("generalAppearance", generalAppearance);
  // console.log("headNeck", headNeck);
  // console.log("respiratory", respiratory);
  // console.log("cardiac", cardiac);
  // console.log("abdomen", abdomen);
  // console.log("neurological", neurological);

  function handleViewRecord(triageObj) {
    // console.log(triageObj);
    // setSelectedTriage(triageObj);

    props.history.push({
      pathname: "triageAssessment/view",
      state: {
        selectedTriage: triageObj,
        requestType,
        requestTypeId: id,
      },
      comingFor: "view",
    });
  }

  // console.log("generalAppearance", generalAppearance);
  // console.log("cardiac", cardiac);
  // console.log("neurological", neurological);
  // console.log("abdomen", abdomen);
  // console.log("respiratory", respiratory);
  // console.log("headNeck", headNeck);
  // console.log("triageLevel", triageLevel);

  if (
    props.history.location.pathname ===
    "/dashboard/home/dcddashboard/triageForm"
  ) {
    return (
      <div
        style={{
          backgroundColor: "rgb(19 213 159)",
          position: "fixed",
          display: "flex",
          width: "100%",
          height: "100%",
          flexDirection: "column",
          flex: 1,
          overflowY: "scroll",
          paddingBottom: 50,
        }}
      >
        <Header history={props.history} />

        <PatientDetailsQuick
          edrId={props.history.location.state.selectedItem._id} />

        <div className="cPadding">
          <div className="subheader" style={{ marginLeft: "-10px" }}>
            <div>
              <img src={business_Unit} />
              <div style={{ flex: 4, display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <h3
                  style={{
                    color: "white",
                    fontWeight: "700",
                    fontSize: matches ? " " : "15px ",
                  }}
                >
                  {"Triage & Assessment"}
                </h3>
              </div>
            </div>
            <div>
              {
                (heartRate > 0 && temperature > 0 && bloodPressureSys > 0 && bloodPressureDia > 0 && respiratoryRate > 0 && pulseOX > 0) || isResuscitation?
                <>
                <span
                    style={{
                      ...styles.triageBox,
                      ...styles["triage" + triageLevel.toString()],
                      marginRight: '10px'
                    }} ><strong>Level:</strong> { triageLevel }</span>
                <span style={{ cursor: 'pointer' }} onClick={() => { setOpenChangeTriageLevel(true); }}>Change</span></>:<span
                    style={{
                      ...styles.triageBox,
                      ...styles["triage" + triageLevel.toString()],
                      marginRight: '10px'
                    }} ><strong>Level:</strong> Not Selected</span>
              }
              {
                newTriageLevel !== "" ? 
                <>
                  <span
                    style={{
                      ...styles.triageBox,
                      ...styles["triage" + newTriageLevel.toString()],
                      marginLeft: '20px'
                    }} ><strong>{currentUser.staffType === "Doctor" || currentUser.staffType === "Nurses" ? currentUser.subType[0]:currentUser.staffType }:</strong> { newTriageLevel }</span>
                </> : undefined
              }
            </div>
          </div>
          
          <div className={classesForTabs.root}>
            <Tabs
              classes={{
                root: classesForTabs.root,
                scroller: classesForTabs.scroller,
              }}
              value={value}
              onChange={handleChange}
              textColor="primary"
              variant="scrollable"
              TabIndicatorProps={{ style: { background: "#12387a" } }}
            >
              {/* <Tab
                style={{
                  color: "white",
                  borderRadius: 5,
                  outline: "none",
                  color: value === 0 ? "#12387a" : "#3B988C",
                }}
                label="RN Assessment"
              /> */}
              <Tab
                style={{
                  color: "white",
                  borderRadius: 5,
                  outline: "none",
                  color: value === 0 ? "#12387a" : "#3B988C",
                }}
                label="Vital Signs"
              />
              <Tab
                style={{
                  color: "white",
                  borderRadius: 5,
                  outline: "none",
                  color: value === 1 ? "#12387a" : "#3B988C",
                }}
                label="Physical Examination"
              />
              {/*
              <Tab
                style={{
                  color: "white",
                  borderRadius: 5,
                  outline: "none",
                  color: value === 2 ? "#12387a" : "#3B988C",
                }}
                label="Triage Level"
              />
              */}
            </Tabs>
          </div>
          {value === 0 ? (
            <>
              <div className={`container-fluid`}>
                <Button
                  //disabled={!validateFormType1()}
                  onClick={handleSwitchMode}
                  variant="contained"
                  color="primary"
                >{sliderMode === true?"Switch to Input Mode (Manual Entry)":"Switch to Slider Mode"}</Button>
                {isResuscitation?<div className="alert alert-danger text-center mt-2"><strong>Resuscitation Mode</strong> is <span className="badge badge-success">Active</span></div>:undefined}
              </div>
              <div
                style={{
                  flex: 4,
                  display: "flex",
                  flexDirection: "column",
                  marginTop: "25px",
                  marginBottom: "25px",
                  borderRadius: "5px",
                }}
              >
                <div className={`container-fluid ${classes.root}`}>
                  <div className="row">
                    <div className="col-md-9">
                      <div className="row row-5">
                        <div className="form-group col-md-6">
                          <div style={{...styles.stylesForSlider}}>
                            {
                              sliderMode === true?
                              <RespiratorySlider
                                aria-labelledby="enter-respiratiry-rate"
                                step={1}
                                min={8}
                                max={30}
                                marks={[
                                  {
                                    value: 8,
                                    label: '8 min',
                                  },
                                  {
                                    value: 19,
                                    label: '19',
                                  },
                                  {
                                    value: 30,
                                    label: '30 min',
                                  },
                                ]}
                                value={respiratoryRate}
                                valueLabelDisplay="on"
                                onChange={(e, value) => {
                                  dispatch({ field: 'respiratoryRate', value: value });
                                }}
                              />:
                              <TextField
                                style={{
                                  outline: "none",
                                  backgroundColor: "#F7F5F5",
                                }}
                                type="number"
                                label="Enter a value between 0 to 100 per minute"
                                variant="outlined"
                                fullWidth
                                onChange={(e) => {
                                  onOtherTheValues(e, 0, 100)
                                }}
                                name="respiratoryRate"
                                value={respiratoryRate}
                                autoComplete="current-password"
                                InputProps={{
                                  min: 0,
                                  max: 100
                                }}
                                maxlength="500"
                                min="0"
                                max="100"
                              />
                            }
                            <Typography id="enter-respiratiry-rate" style={{...styles.stylesForSliderLabels}} guttertop={true}>Respiratory Rate</Typography>
                          </div>
                        </div>
                        <div className="form-group col-md-6">
                          <div style={{...styles.stylesForSlider}}>
                            {
                              sliderMode === true?
                              <PulseOxSlider
                                aria-labelledby="enter-pulse-ox"
                                step={1}
                                min={85}
                                max={100}
                                marks={[
                                  {
                                    value: 85,
                                    label: '85 SpO2',
                                  },
                                  {
                                    value: 92,
                                    label: '92',
                                  },
                                  {
                                    value: 100,
                                    label: '100 SpO2',
                                  },
                                ]}
                                value={pulseOX}
                                valueLabelDisplay="on"
                                onChange={(e, value) => {
                                  dispatch({ field: 'pulseOX', value: value });
                                }}
                              />:
                              <TextField
                                style={{
                                  outline: "none",
                                  backgroundColor: "#F7F5F5",
                                  marginLeft: "-5px",
                                  width: matches ? "" : "114%",
                                }}
                                type="number"
                                label="Enter a value between 0 to 100 SpO2"
                                variant="outlined"
                                fullWidth
                                onChange={(e) => {
                                  onOtherTheValues(e, 0, 100)
                                }}
                                name="pulseOX"
                                value={pulseOX}
                                autoComplete="current-password"
                                InputProps={{
                                  min: 0,
                                  max: 100
                                }}
                                maxlength="500"
                                min="0"
                                max="100"
                              />
                            }
                            <Typography id="enter-pulse-ox" style={{...styles.stylesForSliderLabels}} guttertop={true}>Pulse OX (SpO2)</Typography>
                          </div>
                        </div>
                        <div className="form-group col-md-6">
                          <div style={{...styles.stylesForSlider}}>
                            {
                              sliderMode === true?
                              <SystolicBPSlider
                                aria-labelledby="enter-blood-pressure-systolic"
                                step={1}
                                min={80}
                                max={250}
                                marks={[
                                  {
                                    value: 80,
                                    label: '80 mmHg',
                                  },
                                  {
                                    value: 150,
                                    label: '150',
                                  },
                                  {
                                    value: 220,
                                    label: '220 mmHg',
                                  },
                                ]}
                                value={bloodPressureSys}
                                valueLabelDisplay="on"
                                onChange={(e, value) => {
                                  dispatch({ field: 'bloodPressureSys', value: value });
                                }}
                              />:
                              <TextField
                                style={{
                                  outline: "none",
                                  backgroundColor: "#F7F5F5",
                                }}
                                type="number"
                                label="Enter a value between 0 to 250 mmHg"
                                variant="outlined"
                                fullWidth
                                onChange={(e) => {
                                  onOtherTheValues(e, 0, 250)
                                }}
                                name="bloodPressureSys"
                                value={bloodPressureSys}
                                autoComplete="current-password"
                                InputProps={{
                                  min: 0,
                                  max: 250
                                }}
                                maxlength="500"
                                min="0"
                                max="250"
                              />
                            }
                            <Typography id="enter-blood-pressure-systolic" style={{...styles.stylesForSliderLabels}} guttertop={true}>Blood Pressure Systolic</Typography>
                          </div>
                        </div>
                        <div className="form-group col-md-6">
                          <div style={{...styles.stylesForSlider}}>
                            {
                              sliderMode === true?
                              <DiastolicBPSlider
                                aria-labelledby="enter-blood-pressure-dia"
                                step={1}
                                min={50}
                                max={140}
                                marks={[
                                  {
                                    value: 50,
                                    label: '50 mmHg',
                                  },
                                  {
                                    value: 95,
                                    label: '95',
                                  },
                                  {
                                    value: 140,
                                    label: '140 mmHg',
                                  },
                                ]}
                                value={bloodPressureDia}
                                valueLabelDisplay="on"
                                onChange={(e, value) => {
                                  dispatch({ field: 'bloodPressureDia', value: value });
                                }}
                              />:
                              <TextField
                                style={{
                                  outline: "none",
                                  backgroundColor: "#F7F5F5",
                                }}
                                type="number"
                                label="Enter a value between 0 to 250 mmHg"
                                variant="outlined"
                                fullWidth
                                onChange={(e) => {
                                  onOtherTheValues(e, 0, 250)
                                }}
                                name="bloodPressureDia"
                                value={bloodPressureDia}
                                autoComplete="current-password"
                                InputProps={{
                                  min: 0,
                                  max: 250
                                }}
                                maxlength="500"
                                min="0"
                                max="250"
                              />
                            }
                            <Typography id="enter-blood-pressure-dia" style={{...styles.stylesForSliderLabels}} guttertop={true}>Blood Pressure Diastolic</Typography>
                          </div>
                        </div>
                        <div className="form-group col-md-6">
                          <div style={{...styles.stylesForSlider}}>
                            {
                              sliderMode === true?
                              <TempratureSlider
                                aria-labelledby="enter-temperature"
                                step={0.1}
                                min={30}
                                max={42}
                                marks={[
                                  {
                                    value: 30,
                                    label: '30°C',
                                  },
                                  {
                                    value: 42,
                                    label: '42°C',
                                  },
                                ]}
                                value={temperature}
                                valueLabelDisplay="on"
                                onChange={(e, value) => {
                                  dispatch({ field: 'temperature', value: value });
                                }}
                              />:
                              <TextField
                                style={{
                                  outline: "none",
                                  backgroundColor: "#F7F5F5",
                                }}
                                type="number"
                                label="Enter a value between 0 to 42°C"
                                variant="outlined"
                                fullWidth
                                onChange={(e) => {
                                  onOtherTheValues(e, 0, 42)
                                }}
                                name="temperature"
                                value={temperature}
                                autoComplete="current-password"
                                inputProps={{
                                  min: 0,
                                  max: 42,
                                  step: 0.1
                                }}
                                maxlength="500"
                                min="0"
                                max="42"
                                step="0.1"
                              />
                            }
                            <Typography id="enter-temperature" style={{...styles.stylesForSliderLabels}} guttertop={true}>Temperature</Typography>
                          </div>
                        </div>
                        <div className="form-group col-md-6">
                          <div style={{...styles.stylesForSlider}}>
                            {
                              sliderMode === true?
                              <HeartRateSlider
                                aria-labelledby="enter-heart-rate"
                                step={1}
                                min={50}
                                max={180}
                                marks={[
                                  {
                                    value: 50,
                                    label: '50 bpm',
                                  },
                                  {
                                    value: 115,
                                    label: '115',
                                  },
                                  {
                                    value: 180,
                                    label: '180 bpm',
                                  },
                                ]}
                                value={heartRate}
                                valueLabelDisplay="on"
                                onChange={(e, value) => {
                                  if (value<=49){
                                    value = 50
                                  }
                                  dispatch({ field: 'heartRate', value: value });
                                }}
                              />:
                              <TextField
                                style={{
                                  outline: "none",
                                  backgroundColor: "#F7F5F5",
                                }}
                                type="number"
                                label="Enter a value between 0 to 300 bpm"
                                variant="outlined"
                                fullWidth
                                onChange={(e) => {
                                  onOtherTheValues(e, 0, 300)
                                }}
                                name="heartRate"
                                value={heartRate}
                                autoComplete="current-password"
                                InputProps={{
                                  min: 0,
                                  max: 300
                                }}
                                maxlength="500"
                                min="0"
                                max="300"
                              />
                            }
                            <Typography id="enter-heart-rate" style={{...styles.stylesForSliderLabels}} guttertop={true}>Heart Rate</Typography>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div style={{...styles.stylesForSlider}}>
                        <PainScaleSlider
                          aria-labelledby="enter-respiratiry-rate"
                          step={1}
                          max={10}
                          marks={[
                            {
                              value: 0,
                              label: '0',
                            },
                            {
                              value: 5,
                              label: '5',
                            },
                            {
                              value: 10,
                              label: '10',
                            },
                          ]}
                          value={painScale}
                          valueLabelDisplay="on"
                          onChange={(e, value) => {
                            dispatch({ field: 'painScale', value: value });
                          }}
                        />
                        <Typography id="enter-respiratiry-rate" style={{...styles.stylesForSliderLabels}} guttertop={true}>Pain Scale</Typography>
                      </div>
                      <Button
                        //disabled={!validateFormType1()}
                        style={{
                          marginTop: 15,
                        }}
                        onClick={handleResuscitation}
                        variant="contained"
                        color={isResuscitation?"secondary":"primary"}
                        size="small"
                      >{isResuscitation?"Resuscitation Active":"Select Resuscitation"}</Button>
                    </div>
                  </div>
                  <hr />
                  <div className="row row-5">
                    <div className="col-md-9">
                      <div className="row row-5">
                        <div className="form-group col-md-6 col-sm-6 col-12">

                          <div style={{...styles.stylesForSlider}}>
                            <NormalSlider
                              aria-labelledby="enter-respiratiry-rate"
                              step={1}
                              max={250}
                              marks={[
                                {
                                  value: 0,
                                  label: '0 cm',
                                },
                                {
                                  value: 250,
                                  label: '250 cm',
                                },
                              ]}
                              value={SIZE}
                              valueLabelDisplay="on"
                              onChange={(e, value) => {
                                dispatch({ field: 'SIZE', value: value });
                              }}
                            />
                            <Typography id="enter-respiratiry-rate" style={{...styles.stylesForSliderLabels}} guttertop={true}>Height</Typography>
                          </div>
                        </div>

                        <div className="form-group col-md-6 col-sm-6 col-12">

                          <div style={{...styles.stylesForSlider}}>
                            <NormalSlider
                              aria-labelledby="enter-respiratiry-rate"
                              step={1}
                              max={300}
                              marks={[
                                {
                                  value: 0,
                                  label: '0 kg',
                                },
                                {
                                  value: 300,
                                  label: '300 kg',
                                },
                              ]}
                              value={weight}
                              valueLabelDisplay="on"
                              onChange={(e, value) => {
                                dispatch({ field: 'weight', value: value });
                              }}
                            />
                            <Typography id="enter-respiratiry-rate" style={{...styles.stylesForSliderLabels}} guttertop={true}>Weight</Typography>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div style={{...styles.stylesForBMI}}>
                        <div style={{...styles.stylesForBMIText}}>{BMI}</div>
                        <div style={{...styles[`stylesForBMI${BMIRange}`]}}>{BMIRange}</div>
                        <Typography id="enter-respiratiry-rate" style={{...styles.stylesForSliderLabels}} guttertop={true}>Body Mass Index (BMI)</Typography>
                      </div>
                    </div>

                    <div className="col-md-9">
                      <div className="row row-5">
                        <div className="form-group col-md-6 col-sm-6 col-12">
                          <div style={{...styles.stylesForSlider}}>
                            <NormalSlider
                              aria-labelledby="enter-respiratiry-rate"
                              step={1}
                              max={50}
                              marks={[
                                {
                                  value: 0,
                                  label: '0 mmHg',
                                },
                                {
                                  value: 50,
                                  label: '50 mmHg',
                                },
                              ]}
                              value={IOP}
                              valueLabelDisplay="on"
                              onChange={(e, value) => {
                                dispatch({ field: 'IOP', value: value });
                              }}
                            />
                            <Typography id="enter-respiratiry-rate" style={{...styles.stylesForSliderLabels}} guttertop={true}>Intraocular Pressure (IOP) - Left</Typography>
                          </div>
                        </div>
                        <div className="form-group col-md-6 col-sm-6 col-12">
                          <div style={{...styles.stylesForSlider}}>
                            <NormalSlider
                              aria-labelledby="enter-respiratiry-rate"
                              step={1}
                              max={50}
                              marks={[
                                {
                                  value: 0,
                                  label: '0 mmHg',
                                },
                                {
                                  value: 50,
                                  label: '50 mmHg',
                                },
                              ]}
                              value={IOPRight}
                              valueLabelDisplay="on"
                              onChange={(e, value) => {
                                dispatch({ field: 'IOPRight', value: value });
                              }}
                            />
                            <Typography id="enter-respiratiry-rate" style={{...styles.stylesForSliderLabels}} guttertop={true}>Intraocular Pressure (IOP) - Right</Typography>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div style={{...styles.stylesForSlider}}>
                        <NormalSlider
                          aria-labelledby="enter-respiratiry-rate"
                          step={1}
                          max={1000}
                          marks={[
                            {
                              value: 0,
                              label: '0 mgdL',
                            },
                            {
                              value: 1000,
                              label: '1000 mgdL',
                            },
                          ]}
                          value={FSBS}
                          valueLabelDisplay="on"
                          onChange={(e, value) => {
                            dispatch({ field: 'FSBS', value: value });
                          }}
                        />
                        <Typography id="enter-respiratiry-rate" style={{...styles.stylesForSliderLabels}} guttertop={true}>Random Blood Sugar</Typography>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  justifyContent: "center",
                  marginTop: -10,
                }}
              >
                <div className="container-fluid">
                  <div
                    className="row"
                    style={{ margin: "0px -25px 10px -15px" }}
                  >
                    <div className="col-md-6 col-sm-6 col-6">
                      <img
                        onClick={() => props.history.goBack()}
                        src={Back}
                        style={{
                          width: 45,
                          height: 35,
                          cursor: "pointer",
                        }}
                      />
                    </div>
                    <div className="col-md-6 col-sm-6 col-6 d-flex justify-content-end">
                      <Button
                        style={styles.stylesForButton}
                        //disabled={!validateFormType1()}
                        onClick={handleSubmitAssessment}
                        variant="contained"
                        color="primary"
                      >
                        Next
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : value === 1 ? (
            <>
              <div
                style={{
                  flex: 4,
                  display: "flex",
                  flexDirection: "column",
                  backgroundColor: "white",
                  marginTop: "25px",
                  marginBottom: "25px",
                  padding: "25px",
                  borderRadius: "5px",
                }}
              >
                <div className="container-fluid">
                  <div className="row align-items-center">
                    <div className="col-md-4"><label><strong>General Appearance</strong></label></div>
                    <div className="col-md-8">
                      <div className="form-group">
                        <FormControl variant="outlined" className="{classes.formControl}" fullWidth>
                          <InputLabel htmlFor="outlined-age-native-simple">General Appearance</InputLabel>
                          <Select
                            fullWidth
                            name="generalAppearance"
                            value={generalAppearance}
                            onChange={onSelectChange}
                            label="Select General Appearance"
                          >
                            <MenuItem aria-label="None" value=""><em>None</em></MenuItem>
                            <MenuItem value="Comfortable">Comfortable</MenuItem>
                            <MenuItem value="Uncomfortable">Uncomfortable</MenuItem>
                            <MenuItem value="Pain">Pain</MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                  </div>

                  <div className="row align-items-center">
                    <div className="col-md-4"><label><strong>Respiratory</strong></label></div>
                    <div className="col-md-8">
                      <div className="form-group">
                        <FormControl variant="outlined" className="{classes.formControl}" fullWidth>
                          <InputLabel htmlFor="outlined-age-native-simple">Respiratory</InputLabel>
                          <Select
                            fullWidth
                            name="respiratory"
                            value={respiratory}
                            onChange={onSelectChange}
                            label="Select Respiratory"
                          >
                            <MenuItem aria-label="None" value=""><em>None</em></MenuItem>
                            <MenuItem value="Comfortable">Comfortable</MenuItem>
                            <MenuItem value="Distressed">Distressed</MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                  </div>

                  <div className="row align-items-center">
                    <div className="col-md-4"><label><strong>Cardiac</strong></label></div>
                    <div className="col-md-8">
                      <div className="form-group">
                        <FormControl variant="outlined" className="{classes.formControl}" fullWidth>
                          <InputLabel htmlFor="outlined-age-native-simple">Cardiac</InputLabel>
                          <Select
                            fullWidth
                            name="cardiac"
                            value={cardiac}
                            onChange={onSelectChange}
                            label="Select Cardiac"
                          >
                            <MenuItem aria-label="None" value=""><em>None</em></MenuItem>
                            <MenuItem value="Normal S1, S2">Normal S1, S2</MenuItem>
                            <MenuItem value="Regular">Regular</MenuItem>
                            <MenuItem value="Irregular">Irregular</MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                  </div>

                  <div className="row align-items-center">
                    <div className="col-md-4"><label><strong>Abdomen</strong></label></div>
                    <div className="col-md-8">
                      <div className="form-group">
                        <FormControl variant="outlined" className="{classes.formControl}" fullWidth>
                          <InputLabel htmlFor="outlined-age-native-simple">Abdomen</InputLabel>
                          <Select
                            fullWidth
                            name="abdomen"
                            value={abdomen}
                            onChange={onSelectChange}
                            label="Select Abdomen"
                          >
                            <MenuItem aria-label="None" value=""><em>None</em></MenuItem>
                            <MenuItem value="Soft & Lax">Soft & Lax</MenuItem>
                            <MenuItem value="Tender">Tender</MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                  </div>

                  <div className="row align-items-center">
                    <div className="col-md-4"><label><strong>Neurological</strong></label></div>
                    <div className="col-md-8">
                      <div className="form-group">
                        <FormControl variant="outlined" className="{classes.formControl}" fullWidth>
                          <InputLabel htmlFor="outlined-age-native-simple">Neurological</InputLabel>
                          <Select
                            fullWidth
                            name="neurological"
                            value={neurological}
                            onChange={onSelectChange}
                            label="Select Neurological"
                          >
                            <MenuItem aria-label="None" value=""><em>None</em></MenuItem>
                            <MenuItem value="Alert">Alert</MenuItem>
                            <MenuItem value="Oriented">Oriented</MenuItem>
                            <MenuItem value="Confused ">Confused</MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                  </div>

                  <div className="row align-items-center">
                    <div className="col-md-4"><label><strong>Glasgow Coma Scale</strong></label></div>
                    <div className="col-md-8">
                      <div className="form-group d-flex align-items-center">
                        <Button
                          style={styles.stylesForAssessment}
                          onClick={(e) => {
                            setVisibilityGCSModal(true)
                          }}
                          variant="contained"
                          color="primary"
                        >Start Assessment</Button>
                        <div className="ml-3">
                        Score ({gcsScore})
                        </div>
                      </div>
                    </div>
                  </div>
                  

                </div>
                <div className="form-group col-md-12">
                  {generalAppearanceBoolean ? (
                    <input
                      style={{
                        outline: "none",
                        backgroundColor: "#F7F5F5",
                        width: matches ? "" : "114%",
                      }}
                      type="text"
                      placeholder="Specify"
                      onChange={onOtherChange}
                      name="generalAppearance"
                      value={
                        generalAppearanceBoolean === true
                          ? generalAppearanceText
                          : ""
                      }
                      className="control-label textInputStyle"
                      maxlength="500"
                    />
                  ) : (
                    undefined
                  )}
                </div>
                <div className="form-group col-md-12">
                    {neurologicalBoolean ? (
                      <input
                        style={{
                          outline: "none",
                          backgroundColor: "#F7F5F5",
                          marginLeft: "-5px",
                          width: matches ? "" : "114%",
                        }}
                        type="text"
                        placeholder="Specify"
                        onChange={onOtherChange}
                        name="neurological"
                        value={neurologicalText}
                        className="textInputStyle"
                        maxlength="500"
                      />
                    ) : (
                      undefined
                    )}
                  </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    justifyContent: "flex-end",
                    // marginTop: "2%",
                    marginBottom: "2%",
                    paddingRight: "15px",
                  }}
                  className="container-fluid"
                >
                  <div className="row">
                    <Button
                      style={styles.stylesForButton}
                      //disabled={!validateFormType1()}
                      onClick={handleSubmitAssessment}
                      variant="contained"
                      color="primary"
                    >
                      Save
                    </Button>
                  </div>
                </div>
              </div>
            </>
          ) : value === 2 ? (
            <>
              <div
                style={{
                  flex: 4,
                  display: "flex",
                  flexDirection: "column",
                  backgroundColor: "white",
                  marginTop: "25px",
                  marginBottom: "25px",
                  padding: "25px",
                  borderRadius: "5px",
                }}
                className="container-fluid"
              >
                <div className="row">
                  <label style={{ paddingLeft: "15px", paddingBottom: 20 }}>
                    <strong>Triage Level</strong>
                  </label>
                </div>
                <div value={triageLevel}>
                  <div className="row" style={{ marginLeft: 15 }}>
                    <div className="col-md-4" style={{ marginBottom: 30 }}>
                      <input
                        type="checkbox"
                        name="triageLevel"
                        value="Resuscitation"
                        onChange={onChangeHandler}
                        checked={
                          triageLevel.length > 0 &&
                          triageLevel.toString().includes("Resuscitation")
                            ? true
                            : false
                        }
                      />
                      <label for="male">&nbsp;&nbsp;1 - Resuscitation</label>
                    </div>
                    <div className="col-md-4" style={{ marginBottom: 30 }}>
                      <input
                        type="checkbox"
                        name="triageLevel"
                        value="Emergent"
                        onChange={onChangeHandler}
                        checked={
                          triageLevel.length > 0 &&
                          triageLevel.toString().includes("Emergent")
                            ? true
                            : false
                        }
                      />
                      <label for="male">&nbsp;&nbsp;2 - Emergent</label>
                    </div>
                    <div className="col-md-4" style={{ marginBottom: 30 }}>
                      <input
                        type="checkbox"
                        name="triageLevel"
                        onChange={onChangeHandler}
                        value="Urgent"
                        checked={
                          triageLevel.length > 0 &&
                          triageLevel.includes("Urgent")
                            ? true
                            : false
                        }
                        // checked={
                        //   triageLevel.length > 0 &&
                        //   triageLevel.filter(function(item) {
                        //     return item === "Urgent";
                        //   })
                        //     ? true
                        //     : false
                        // }
                      />
                      <label for="male">&nbsp;&nbsp;3 - Urgent</label>
                    </div>
                  </div>
                  <div className="row" style={{ marginLeft: 15 }}>
                    <div className="col-md-4" style={{ marginBottom: 30 }}>
                      <input
                        type="checkbox"
                        name="triageLevel"
                        value="LessUrgent"
                        onChange={onChangeHandler}
                        checked={
                          triageLevel.length > 0 &&
                          triageLevel.toString().includes("LessUrgent")
                            ? true
                            : false
                        }
                      />
                      <label for="male">&nbsp;&nbsp;4 - Less Urgent</label>
                    </div>
                    <div className="col-md-4" style={{ marginBottom: 30 }}>
                      <input
                        type="checkbox"
                        name="triageLevel"
                        value="NonUrgent"
                        onChange={onChangeHandler}
                        checked={
                          triageLevel.length > 0 &&
                          triageLevel.toString().includes("NonUrgent")
                            ? true
                            : false
                        }
                      />
                      <label for="male">&nbsp;&nbsp;5 - Non Urgent</label>
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    justifyContent: "flex-end",
                    // marginTop: "2%",
                    marginBottom: "2%",
                    paddingRight: "15px",
                  }}
                  className="container-fluid"
                >
                  <div className="row">
                    <Button
                      style={styles.stylesForButton}
                      //disabled={!validateFormType1()}
                      onClick={handleSubmitAssessment}
                      variant="contained"
                      color="primary"
                    >
                      Save
                    </Button>
                  </div>
                </div>
              </div>
            </>
          ) : (
            undefined
          )}

          <Notification
            msg={errorMsg}
            open={openNotification}
            success={successMsg}
          />
        </div>

        {openGCSModal ? (
          <GCSModal
            openGCSModal={openGCSModal}
            setVisibilityGCSModal={hideGCSModal}
            gcsScoreValues={gcsScoreValues}
            dataGSCModal={dataGSCModal}
            handleGCSDone={handleGCSDone}
          />
        ) : (
          undefined
        )}

        {openChangeTriageLevel ?
          <Dialog open={openChangeTriageLevel} onClose={() => {
              setOpenChangeTriageLevel(false);
            }} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Change Triage Level</DialogTitle>
            <DialogContent>
              <DialogContentText>Please fill up the following data.</DialogContentText>
              <div className="row">
                <div className="col-md-12">
                  <TextField
                    autoFocus
                    required
                    margin="dense"
                    name="reasonChangeTriageLevel"
                    value={reasonChangeTriageLevel}
                    onChange={onChangeValue}
                    label="Reason to Change"
                    type="text"
                    variant="outlined"
                    fullWidth
                  />
                </div>
              </div>

              <FormControl className={classes.formControl} fullWidth style={{
                marginTop: 15
              }}>
                <InputLabel id="newTriageLevel">New Triage Level</InputLabel>
                <Select
                  labelId="newTriageLevel"
                  id="demo-mutiple-chip"
                  fullWidth
                  variant="outlined"
                  name="newTriageLevel"
                  value={newTriageLevel}
                  onChange={onChangeValue}
                  input={<Input id="select-multiple-chip"/>}
                  label="Production Area (Short Name)"
                  renderValue={(selected) => (
                    <div className={classes.chips}>{selected}</div>
                  )}
                  MenuProps={MenuProps}
                >
                  <MenuItem value="">None</MenuItem>
                  <MenuItem key="NonUrgent" value="NonUrgent">Non Urgent</MenuItem>
                  <MenuItem key="LessUrgent" value="LessUrgent">Less Urgent</MenuItem>
                  <MenuItem key="Urgent" value="Urgent">Urgent</MenuItem>
                  <MenuItem key="Emergent" value="Emergent">Emergent</MenuItem>
                  {/*<MenuItem key="Resuscitation" value="Resuscitation">Resuscitation</MenuItem>*/}
                </Select>
              </FormControl>

            </DialogContent>
            <DialogActions>
              <Button onClick={handleChangeTriageLevel} color="primary">Update</Button>
            </DialogActions>
          </Dialog>
        :undefined}
      </div>
    );
  } else if (
    props.history.location.pathname ===
    "/dashboard/home/dcddashboard/triageForm/viewTriageForm"
  ) {
    return <ViewSingleTriage propsData={{ ...props }} />;
  }
}
export default TriageAndAssessment;
