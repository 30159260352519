import React, { useEffect, useState } from 'react'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import 'react-circular-progressbar/dist/styles.css'
import { useTheme } from '@material-ui/core/styles'
// Radial separators
import MobileFlipForProgress from './MobileFlipProgress'
import SliderComponent from '../../components/BedsAvailableSlider/bedsAvailableSlider'
import LineChart from '../../components/Dial/LineChart'

function Flip(props) {
  const theme = useTheme()
  const matches = useMediaQuery('(min-width:600px)')
  const matchesForTablet = useMediaQuery(theme.breakpoints.up('md'))

  const [change, setChange] = useState(true)
  const [bedLineChartWidth, setBedLineChartWidth] = useState(
    window.innerWidth <= 600 ? 300 : 420
  )

  const {
    idForGraph,
    mainHeadingForGraph,
    progressValue,
    progressName,
  } = props

  console.log('change', change)

  return (
    <div>
      {change ? (
        <div
          style={{
            width: '100%',
            backgroundColor: 'white',
            height: 180,
            borderRadius: 5,
            padding: 10,
          }}
          onClick={() => setChange(!change)}
        >
          <div>
            <span style={{ fontWeight: 'bold', fontSize: 18 }}>
              {progressName}
            </span>
          </div>
          <div>
            <span style={{ fontWeight: 'bold', fontSize: 45 }}>
              {progressValue}
            </span>
          </div>
          <SliderComponent
            scale={progressName.includes('ED') ? 14 : 113}
            top={120}
            bottom={0}
            left={
              matches && matchesForTablet
                ? 140
                : matches && !matchesForTablet
                  ? 45
                  : 25
            }
            right={0}
            readOnly={true}
            progressValue={progressValue}
          />
        </div>
      ) : (
          <div>
            <MobileFlipForProgress
              openIt={true}
              setChange={setChange}
              idForGraph={idForGraph}
              mainHeadingForGraph={mainHeadingForGraph}
              data={props.data}
              progressName={progressName}
              progressValue={progressValue}
            />
          </div>
        )}
    </div>
  )
}

export default Flip
