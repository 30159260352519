import React, { useEffect, useState, useReducer } from "react";
import { withStyles } from '@material-ui/core/styles';
import Header from "../../components/Header/Header";
import classNames from "classnames";
import { 
  Button, 
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment,
  MenuItem,
  TextField,
  Select,
  useMediaQuery,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
} from "@material-ui/core";
import cookie from "react-cookies";
import {
  labServiceSearchCall,
  labServiceCategoryCall,
  getLabTechniciansCall,
} from "./NetworkCallForNotesScreen";
import { displayInHumanDifference, replacePatientIdIfNull } from "../../utils/commonFunc";

import axios from "axios";
import {
  getFavoriteList,
  addToFavoriteList,
  getSingleEdr,
  getLabServiceCategory,
  addLabRequestBulk
} from "../../public/endpoins";

import useStylesForInput from "../../assets/jss/material-dashboard-react/inputStyle";
import styles from "./styles";
import Loader from "react-loader-spinner";
import {
  Checkbox, 
  FormLabel, 
  FormControl, 
  FormGroup, 
  FormControlLabel,
  RadioGroup, 
  Radio
} from '@material-ui/core';
import {
  priorityArray,
  statusArray,
} from "./configForNotesScreen";
import Icon from '@material-ui/core/Icon';
import CloseIcon from '@material-ui/icons/Close';
import MicIcon from "@material-ui/icons/Mic";
import StopIcon from "@material-ui/icons/Stop";
import MicRecorder from "mic-recorder-to-mp3";
import AccountCircle from "@material-ui/icons/SearchOutlined";
import Notification from "../../components/Snackbar/Notification.js";

const Mp3Recorder = new MicRecorder({ bitRate: 128 });

export default function LabRequestsPage(props) {
  
  const matches = useMediaQuery("(min-width:600px)");
  const classes = useStylesForInput();

  const mystyle = {
    color: "white",
    backgroundColor: "DodgerBlue",
    padding: "10px",
    fontFamily: "Arial"
  };

  const GreenCheckbox = withStyles({
    root: {
      color: '#AAAAAA',
      '&$checked': {
        color: '#AAAAAA',
      },
    },
    checked: {
      color: '#2C6DDD',
      '&$checked': {
        color: '#2C6DDD',
      },
    },
  })((props) => <Checkbox color="default" {...props} />);

  const GreenRadio = withStyles({
    root: {
      color: '#AAAAAA',
      '&$checked': {
        color: '#AAAAAA',
      },
    },
    checked: {
      color: '#2C6DDD',
      '&$checked': {
        color: '#2C6DDD',
      },
    },
  })((props) => <Radio color="default" {...props} />);

  


  const initialState = {
    bulkTestSelected: false,
    resetedOn: "",
    defaultItems: {},
    seletedLabItems: [],
    labPriority: "",
    labTechnician: "",
    labComments: "",
    labTechniciansList: [],

    isBlocked: false,
    isRecording: false,
    recordingField: '',
    blobURL: [],
  };
  function reducer(state, { field, value }) {
    return {
      ...state,
      [field]: value,
    };
  }
  const [state, dispatch] = useReducer(reducer, initialState);
  const {
    bulkTestSelected,
    resetedOn,
    defaultItems,
    seletedLabItems,
    labPriority,
    labTechnician,
    labComments,
    labTechniciansList,

    isBlocked,
    isRecording,
    recordingField,
    blobURL,
  } = state;

  const handleCheckupChange = (event) => {
    dispatch({field: 'bulkTestSelected', value: event.target.value});
  };

  const uniqueCodeField = (name) => {
    name = name.toLowerCase();
    name = name.replace(/[^a-z0-9]+/g, '-');
    name = name.replace(/^-+|-+$/g, '');
    return name;
  }

  const itemsExistInDefaultSelection = (slug, item) => {

    if ( `id-`+slug in defaultItems ){
      let defaultSelected = defaultItems[ `id-`+slug ].items;
      if ( defaultSelected.includes( item ) )
      { // Already Exists Item and Remove it
        return true;
      }
    }

    return false;
  };

  const onChangeValue = (e) => {
    dispatch({
      field: e.target.name,
      value: e.target.value.replace(/[^\w\s]/gi, ""),
    });
  };

  const onChangePriorityValue = (e, index, item) => {
    console.log("ConsoleLabRequest :: onChangePriorityValue", e, index, item);

    let newSelectedLabItems = seletedLabItems;
    newSelectedLabItems.map((d, index) => {
      if (d.id === item){
        d.priority = e;
      }
    });
    console.log("ConsoleLabRequest :: onChangePriorityValue :: nv", newSelectedLabItems);
    dispatch({
      field: 'seletedLabItems',
      value: newSelectedLabItems
    });
  };

  const onChangeNotesValue = (e, index, item) => {
    let newSelectedLabItems = seletedLabItems;
    newSelectedLabItems.map((d, index) => {
      if (d.id === item){
        d.notes.text = e;
      }
    });
    dispatch({
      field: 'seletedLabItems',
      value: newSelectedLabItems
    });
  };

  const removeSelectedLabItem = (index, id) => {
    if (window.confirm('Confirm Removal?')){

      seletedLabItems.map((d, index) => {
        if (d.id === id){
          console.log('LabReuqest::remove::', id, 'included');
          let indexOfItem = seletedLabItems.indexOf( d );
          console.log('LabReuqest::remove::', 'indexOfItem', indexOfItem);
          seletedLabItems.splice( indexOfItem, 1 );
          dispatch({
            field: 'seletedLabItems',
            value: seletedLabItems
          });
        }
      });


      console.log('LabReuqest::remove::', index, id);
      if (seletedLabItems.includes(id)){ // Already Exists Item

        
      }
    }
  };

  const saveTheList = (listName) => {
    if (
      seletedLabItems.length > 0
    ){
      let idsToSave = [];
      Object.entries(seletedLabItems).map((obj, item) => {
        idsToSave.push({
          requestId: obj[1].id
        })
      })

      const params = {
        listName: listName,
        serviceIds: idsToSave,
        staffId: currentUser._id,
        listType: 'lab',
      };

      axios
        .put(addToFavoriteList, params)
        .then((res) => {
          if (res.data.success) {
            setFavoriteListData(res.data.data);
          } else if (!res.data.success) {
            setOpenNotification(true);
            setErrorMsg("Error while adding the Lab Request");
          }
        })
        .catch((e) => {
          console.log('ConsoleLabRequest', "error after adding Lab Request", e);
          setOpenNotification(true);
          setErrorMsg("An error occurred while adding the Lab Request.");
        });
        
    }else{
      setOpenNotification(true);
      setErrorMsg("An error occurred while saving the Lab Request as your favorite list.");
    }
  }

  const addSelectedLabItem = () => {
    // ConsoleLabRequest 

    if (
      // labComments &&
      // labComments.length > 0 &&
      // labPriority &&
      // labPriority.length > 0 &&
      seletedLabItems.length > 0
    ){
      setOpenNotification(true);
      setsuccessMsg("Adding the Lab Request");
      const params = {
        serviceIds: seletedLabItems,
        // priority: labPriority,
        staffId: currentUser._id,
        edrId: episodeId,
        // notes: labComments,
        labTechnicianId: null,
      };
      console.log( 'ConsoleLabRequest', params );
      // props.hideDialog(false);
      
      //SS
      let formData = new FormData();

      console.log( 'ConsoleLabRequest::seletedLabItems', seletedLabItems );
      formData.append("serviceIds", JSON.stringify(seletedLabItems));
      formData.append("staffId", currentUser._id);
      formData.append("edrId", episodeId);
      formData.append("labTechnicianId", null);
      formData.append("urgent", (props.history.location.state.lifelimbStatus?'Yes':'No'));

      for (let ixp = 0; ixp < seletedLabItems.length; ixp++) {
        if (seletedLabItems[ixp].notes){

          if ( seletedLabItems[ixp].notes.audio!=="" && seletedLabItems[ixp].notes.audio instanceof Blob )
          {
            formData.append("AudioFile", seletedLabItems[ixp].notes.audio, seletedLabItems[ixp].id);
          }
        }
      }
      
      axios
        .put(addLabRequestBulk, formData)
        .then((res) => {
          console.log( 'ConsoleLabRequest', res.data );
          if (res.data.success) {
            // console.log("response after adding Lab Request", res.data);
            
            // props.selectedPatientForReducer(res.data.data);
            // dispatch({
            //   field: "labComments",
            //   value: "",
            // });
            // dispatch({
            //   field: "labPriority",
            //   value: "",
            // });
            // dispatch({
            //   field: "labServiceName",
            //   value: "",
            // });
            // dispatch({
            //   field: "labTechnician",
            //   value: "",
            // });
            // props.hideDialog(false);
            
            props.history.push({
              pathname: "/dashboard/home/"+episodeId+"/lab",
            });
          } else if (!res.data.success) {
            setOpenNotification(true);
            setErrorMsg("Error while adding the Lab Request");
          }
        })
        .catch((e) => {
          console.log('ConsoleLabRequest', "error after adding Lab Request", e);
          setOpenNotification(true);
          setErrorMsg("An error occurred while adding the Lab Request.");
        });
        
    }else{
      setOpenNotification(true);
      setErrorMsg("Error while adding the Lab Request");
    }

    /*
    if (validateFields()) {
      const params = {
        serviceId: selectedLab._id,
        name: selectedLab.name,
        type: selectedLab.type,
        price: selectedLab.price,
        priority: labPriority,
        staffId: currentUser._id,
        edrId:
          props.history.location.state.comingFrom === "notification"
            ? reponseThroughNotification._id
            : selectedRecord._id,
        notes: labComments,
        labTechnicianId: labTechnician,
      };
      console.log("Lab params", params);
      saveLabRequestCall(
        params,
        setOpenNotification,
        setErrorMsg,
        props,
        dispatch
      );
    } else {
      setOpenNotification(true);
      setErrorMsg("Error while adding the Lab Request");
    }
    setLabForm(true);
    */
  };

  const [currentUser] = useState(cookie.load("current_user"));
  const [timer, setTimer] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [itemFound, setItemFound] = useState("");
  const [itemFoundSuccessfull, setItemFoundSuccessfully] = useState(false);
  const [loadSearchedData, setLoadSearchedData] = useState(false);
  const [selectedLab, setSelectedLab] = useState("");
  const [categoriesData, setCategoriesData] = useState([]);
  const [mItems, setMItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [myDefaultItems, setMyDefaultItems] = useState([]);

  const [patientInsured, setPatientInsured] = useState(false);

  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setsuccessMsg] = useState("");
  const [openNotification, setOpenNotification] = useState(false);


  const [patientDetails, setPatientDetails] = useState({});
  const [selectedRecord, setSelectedRecord] = useState({});

  const [ episodeId, setEpisodeId ] = useState(props.match.params.id);
  const [ pageId, setPageId ] = useState(props.match.params.pagename);

  const [ favoriteListData, setFavoriteListData ] = useState([]);
  
  if (openNotification) {
    setTimeout(() => {
      setOpenNotification(false);
      setErrorMsg("");
      setsuccessMsg("");
    }, 4000);
  }

  useEffect(() => {

    axios
      .get(getSingleEdr + "/" + episodeId)
      .then((res) => {
        console.log('handleView::', res.data.data);
        if (res.data.success) {

          let remoteRecord = replacePatientIdIfNull(res.data.data);
          setSelectedRecord(remoteRecord);

          if (remoteRecord.patientId.paymentMode === "Insured"){
            setPatientInsured(true);
          }

          const object = {
            '_id': remoteRecord._id,
            patientName: remoteRecord.patientId.name[0].given[0] + " " + remoteRecord.patientId.name[0].family,
            mrn: remoteRecord.requestNo,//remoteRecord.patientId.identifier[0].value,
            age: remoteRecord.patientId.age,
            gender: remoteRecord.patientId.gender,
            weight: remoteRecord.patientId.weight,
            department: remoteRecord.currentLocation,
            chiefComplaint: remoteRecord.chiefComplaint.length > 0 ? remoteRecord.chiefComplaint.pop().chiefComplaintId.short_desc : "N/A",
            chiefComplaintOthers: remoteRecord.chiefComplaintOthers,
          };
          setPatientDetails(object);
          loadAsyncData();

        }
      })
      .catch((e) => {
        console.log("error after searching patient request", e);
      });

      console.log('handleView::rb::', isRecording, isBlocked);
    navigator.mediaDevices &&
      navigator.mediaDevices.getUserMedia(
        { audio: true },
        () => {
          dispatch({ field: "isBlocked", value: false });
        },
        () => {
          dispatch({ field: "isBlocked", value: true });
        }
      );

    /*getLabTechniciansCall( currentUser._id, dispatch, setOpenNotification, setErrorMsg );*/
  }, []);

  useEffect(() => {
  }, [myDefaultItems]);

  const startRecording = (index, item) => {
    console.log('handleView::ii::', index, item)
    if (isBlocked) {

    } else {
      Mp3Recorder.start()
        .then(() => {
          dispatch({ field: "isRecording", value: true });
          dispatch({ field: "recordingField", value: item });
          console.log('handleView::rec::',true)
        })
        .catch((e) => console.error(e));
    }
  };

  const stopRecording = () => {
    Mp3Recorder.stop()
      .getMp3()
      .then(([buffer, blob]) => {

        const blobURLFinal = URL.createObjectURL(blob);
        let blobURLx = blobURL;
        blobURLx[ recordingField ] = blobURLFinal;
        dispatch({ field: "blobURL", value: blobURLx });

        dispatch({ field: "isRecording", value: false });
        var file = new File([blob], "first", { type: "audio/mp3" });


        // Setting Up Audio File
        if (seletedLabItems.length > 0){
          seletedLabItems.map((i, itemIx) => {
            // let item = mItems.filter((element, index, array) => element._id === recordingField);
            console.log('handleView::rec::stop::', i, itemIx, recordingField)
            if (i.id == recordingField){
              i.notes.audio = file;
            }
          });
        }


        dispatch({ field: "fileAudio", value: file });
        dispatch({ field: "recordingField", value: '' });
        console.log('handleView::rec::',false)
      })
      .catch((e) => console.log(e));
  };

  const loadFavoriteData = () => {
    // labServiceCategoryCall(setCategoriesData);
      axios
        .get(getFavoriteList + "?staffId="+currentUser._id+"&listType=lab")
        .then((res) => {
          if (res.data.success) {
            setFavoriteListData(res.data.data);
          }
        })
        .catch((e) => {
          console.log("error while searching req", e);
        });

  }

  const loadAsyncData = () => {
    // labServiceCategoryCall(setCategoriesData);
      axios
        .get(getLabServiceCategory)
        .then((res) => {
          if (res.data.success) {
            // console.log('labServiceCategoryCall', res.data.data);
            let remoteData = res.data.data;
            setCategoriesData(remoteData);

            loadFavoriteData();

            let itemsInList = [];
            let defaultItemsInList = [];
            let newDefaultItemsInList = [];

            // Items Extractor
            remoteData.map((d, index) => {
              let slug = uniqueCodeField(d._id);
              let values = [];

              if (d.items.length > 0){
                d.items.map((nd, nindex) => {
                  // values.push(nd);
                  itemsInList.push(nd);

                  if (nd.checked){
                    values.push(nd._id);
                  }

                });
              }
              /*defaultItemsInList.push({
                'id': slug,
                'name': d._id,
                'menuOpen': false,
                'xUrgent': (props.history.location.state.lifelimbStatus?'Yes':'No'),
                'priority': 'High',
                'items': values,
              });*/
              newDefaultItemsInList[ `id-${slug}` ] = {
                'id': slug,
                'name': d._id,
                'menuOpened': false,
                'xUrgent': (props.history.location.state.lifelimbStatus?'Yes':'No'),
                'priority': 'High',
                'items': values,
              };
              // defaultItemsInList[ slug ] = values;
            });
            // dispatch({ xx: 'defaultItems', value: defaultItemsInList });
            dispatch({ field: 'resetedOn', value: 'now' });

            setMyDefaultItems(defaultItemsInList);
            dispatch({
              field: 'defaultItems',
              value: newDefaultItemsInList
            });

            setIsLoading(false);
            setMItems(itemsInList);
            // setCategoriesData([
            //   {_id: "Hello"}
            // ])
          }
        })
        .catch((e) => {
          console.log("error while searching req", e);
        });

  }
  // loadAsyncData();

  const handlePauseLabSearch = (e) => {
    // setLoadSearchedData(true);
    // clearTimeout(timer);

    const a = e.target.value.replace(/[^\w\s]/gi, "");
    setSearchQuery(a);

    setTimer(
      setTimeout(() => {
        labServiceSearchCall(
          a,
          setItemFoundSuccessfully,
          setItemFound,
          setLoadSearchedData
        );
      }, 600)
    );
  };

  const handleChange = (event) => {
    // setState({ ...state, [event.target.name]: event.target.checked });

    let nSlug = event.target.name;
    console.log( 'RadReq::handleChange::', event.target.checked, event.target.name, event.target.value, `id-`+nSlug, defaultItems );
    if ( `id-`+nSlug in defaultItems ){
      let defaultSelected = defaultItems[ `id-`+nSlug ].items;

      console.log( 'RadReq::handleChange::', defaultSelected );

      if (defaultSelected.includes(event.target.value)){ // Already Exists Item and Remove it
        let indexOfItem = defaultSelected.indexOf( event.target.value );
        defaultSelected.splice( indexOfItem, 1 );
      }else{ // Adding Item
        defaultSelected.push( event.target.value );
      }

      let nItems = defaultItems;
      nItems[ `id-`+nSlug ].items = defaultSelected;

      dispatch({
        field: 'defaultItems',
        value: nItems
      });
    }

  };

  const addSelectedCheckup = () => {
    let items = [];

    let kidneyFunctionalText = ['62e266d698975c58dae5dd48', '62e266d698975c58dae5dd49', '62e266d698975c58dae5dd4c','62e266d698975c58dae5dd4a','62e266d698975c58dae5dd4b', '62e266d698975c58dae5dd7f'];///*'NA', 'K', 'CL',*/
    let liverFunctionalTest  = ['62e266d698975c58dae5dd4d', '62e266d698975c58dae5dd4e', '62e266d698975c58dae5dd4f', '62e266d698975c58dae5dd50', '62e266d698975c58dae5dd51', '62e266d698975c58dae5dd52', '62e266d698975c58dae5dd53', '62e266d698975c58dae5dd54', '62e266d698975c58dae5dd55'];
    let checkup1 = [
      '62e266d698975c58dae5dd71',
      '62e266d698975c58dae5dd42',
      '62e266d698975c58dae5dd41',
      '62e266d698975c58dae5de85',
      '62e266d698975c58dae5dd85',
      '62e266d698975c58dae5de35', //
      '62e266d698975c58dae5de86',
      ...kidneyFunctionalText,
      ...liverFunctionalTest
    ];

    let checkup2 = [
      ...checkup1,
      // 'HEPATITES B, ',
      // 'HEPATITIS C , ',
      '62e266d698975c58dae5de99',
      '62e266d698975c58dae5de94',
      '62e266d698975c58dae5de97',
      '62e266d698975c58dae5dd58',
      '62e266d698975c58dae5de93',
      '62e266d698975c58dae5de98',
      '62e266d698975c58dae5de96',
    ];

    let checkup3 = [
      ...checkup1,
      '62e266d698975c58dae5dd89',
      '62e266d698975c58dae5dd88',
      '62e266d698975c58dae5ddf5',
      '62e266d698975c58dae5de10',
      '62e266d698975c58dae5dd6a',
    ];

    let superCheckup = [
      ...checkup1,
      ...checkup2,
      ...checkup3,
      '62e266d698975c58dae5de9a',
      '62e266d698975c58dae5de95',
    ];

    let royalCheckup = [
      ...superCheckup,
      '62e266d698975c58dae5de47', // ZINC
      '62e266d698975c58dae5dd6f', // RF
      '62e266d698975c58dae5dd6e', // ASO
      '62e266d698975c58dae5de48', // MG
      '62e266d698975c58dae5dded', // CMV-IGM
      '62e266d698975c58dae5dda6', // VDRL
      '62e266d698975c58dae5de58', // B2 MICRGLOBULIN
      // HBA1C
      // MCR
      '62e266d698975c58dae5de3a', // H.PYLORI IGA
      '62e266d698975c58dae5de60', // IGE

      //62e266d698975c58dae5de48
    ];

    let defaultSelected = [];

    if ("cu1" == bulkTestSelected){
      defaultSelected = checkup1;
    }else if ("cu2" == bulkTestSelected){
      defaultSelected = checkup2;
    }else if ("cu3" == bulkTestSelected){
      defaultSelected = checkup3;
    }else if ("cu4" == bulkTestSelected){ // Super Checkup
      defaultSelected = superCheckup;
    }else if ("cu5" == bulkTestSelected){ // Royal Checkup
      defaultSelected = royalCheckup;
    }

    let readyToAdd = seletedLabItems;
    if ( defaultSelected.length > 0 ) {
      defaultSelected.map((dx, dindex) => {
        console.log( 'ConsoleLabRequest', dx );
        
        let itemExists = readyToAdd.filter((element, index, array) => {
            // Return true or false
            return element.id === dx;
        });

        if ( itemExists.length<=0 ){
          readyToAdd.push({
            id: dx,
            priority: 'High',
            xUrgent : (props.history.location.state.lifelimbStatus?'Yes':'No'),
            notes: {
              text: '',
              audio: null,
            }
          });
          console.log('ConsoleLabRequest', {
            id: dx,
            priority: 'High',
            xUrgent : (props.history.location.state.lifelimbStatus?'Yes':'No'),
            notes: {
              text: '',
              audio: null,
            }
          })
        }
      });
      console.log( 'ConsoleLabRequest', readyToAdd );

      dispatch({
        field: 'seletedLabItems',
        value: readyToAdd
      });
    }else{
      setOpenNotification(true);
      setErrorMsg('Please select an item from the above list.');
    }
  
    
  }

  const addSelectedItems = (slug) => {
    
    let defaultSelected = [];
    let currentIndex = -1;
    let sPriority = 'High';
    let sUrgent   = (props.history.location.state.lifelimbStatus?'Yes':'No');

    if ( `id-${slug}` in defaultItems )
    {
      let d = defaultItems[ `id-${slug}` ];
      console.log( 'RadReq::addSelectedItems::', d );

      sPriority = d.priority;
      sUrgent = d.xUrgent;
      defaultSelected = d.items;
      d.menuOpened = false;

    }

    let readyToAdd = seletedLabItems;
    console.log( 'ConsoleLabRequest', readyToAdd );

    if ( defaultSelected.length > 0 ) {
      defaultSelected.map((dx, dindex) => {
        console.log( 'ConsoleLabRequest', dx );

        let itemExists = readyToAdd.filter((element, index, array) => {
            // Return true or false
            return element.id === dx;
        });


        console.log( 'ConsoleLabRequest', itemExists );

        if ( itemExists.length<=0 ){
          readyToAdd.push({
            id: dx,
            priority: sPriority,
            xUrgent : sUrgent,
            notes: {
              text: '',
              audio: null,
            }
          });
        }
      });
      console.log( 'ConsoleLabRequest', readyToAdd );

      dispatch({
        field: 'seletedLabItems',
        value: readyToAdd
      });
    }else{
      setOpenNotification(true);
      setErrorMsg('Please select an item from the above list.');
    }

  };

  const toggleDropdownMenu = (slug) => {
    let newSlug = uniqueCodeField(slug);

    // Reset all opened
    // dispatch({field: 'newSearchTerm', value: ''});

    Object.entries(defaultItems).map(([x, i]) => {
      console.log('LabReq::toggleDropdownMenu::x,i::', x, i );
      if ( `id-${newSlug}` ===  x){

      }else{
        i.menuOpened = false;
        i.items = [];
      }
    })


    console.log('LabReq::toggleDropdownMenu::slug::', slug );
    console.log('LabReq::toggleDropdownMenu::newSlug::', newSlug );
    console.log('LabReq::toggleDropdownMenu::defaultItems::', defaultItems );
    if ( `id-${newSlug}` in defaultItems){
      console.log('LabReq::toggleDropdownMenu::exists::', "Yes" );
      let obj = defaultItems[ `id-${newSlug}` ];
      console.log('LabReq::toggleDropdownMenu::exists::obj::', obj );
      if (obj.menuOpened === true){
        obj.menuOpened = false
        obj.items = [];
      }else{
        obj.menuOpened = true;
      }
      console.log('LabReq::toggleDropdownMenu::opened::', obj );
    }else{
      console.log('LabReq::toggleDropdownMenu::', "Not exists" );
    }
    dispatch({
      field: 'defaultItems',
      value: defaultItems
    });
  }

  const onChangeDropDownXUrgentValue = (e, index, item) => {
    console.log("ConsoleLabRequest :: onChangePriorityValue", e, index, item);

    defaultItems.map((d, index) => {
      if (d.name === item){
        d.xUrgent = e;
      }
    });
    dispatch({
      field: 'defaultItems',
      value: defaultItems
    });
  };

  const onChangeDropDownPriorityValue = (e, item, type) => {
    let nSlug = uniqueCodeField(item);
    console.log("ConsoleLabRequest :: onChangePriorityValue", e, "item::", item, 'nSlug::', nSlug);

    if (type==="xUrgent"){

      if (`id-`+nSlug in defaultItems){
        defaultItems[ `id-`+nSlug ].xUrgent = e;
      }
      // defaultItems.filter(obj => obj.name === item).forEach(obj => obj.xUrgent = e);
    }else if (type==="priority"){
      // defaultItems.filter(obj => obj.name === item).forEach(obj => obj.priority = e);

      if (`id-`+nSlug in defaultItems){
        defaultItems[ `id-`+nSlug ].priority = e;
      }
    }

    /*
    defaultItems.map((d, index) => {
      if (d.name === item){
        if (type==="xUrgent"){
          d.xUrgent = e;
        }else if (type==="priority"){
          d.priority = e;
        }
      }
    });
    */
    console.log('defaultItems', defaultItems);
    dispatch({
      field: 'defaultItems',
      value: defaultItems
    });
  };

  const labPriorityDefaultSelection = (slug) => {
    let menuOpened = "";
    let currentIndex = -1;
    let nDefaultItem;

    let nSlug = uniqueCodeField(slug);
    console.log(`id-`+nSlug);

    if (`id-`+nSlug in defaultItems){
      nDefaultItem = defaultItems[ `id-`+nSlug ]
    }


    // let nDefaultItem = defaultItems.filter((d) => {
    //   return d.name === slug
    // });

    let ret = nDefaultItem ? nDefaultItem:{'priority':'High', 'xUrgent': (props.history.location.state.lifelimbStatus?'Yes':'No')};
    // let ret = {'priority':'High', 'xUrgent': (props.history.location.state.lifelimbStatus?'Yes':'No')};
    return ret;
  };

  const dropdownOpened = (slug) => {
    let menuOpened = "";
    let currentIndex = -1;

    let nSlug = uniqueCodeField(slug);
    if (`id-`+nSlug in defaultItems){
      if ( defaultItems[ `id-`+nSlug ].menuOpened === true ){
        menuOpened = "show";
      }
    }

    return menuOpened;
  };

  const isDropdownOpened = (slug) => {
    let menuOpened = false;
    let currentIndex = -1;

    let nSlug = uniqueCodeField(slug);
    if (`id-`+nSlug in defaultItems){
      if ( defaultItems[ `id-`+nSlug ].menuOpened === true ){
        menuOpened = true;
      }
    }

    return menuOpened;
  };

  function handleAddItem(i) {
    console.log("selected item", i);

    // setSelectedLab(i);
    let readyToAdd = seletedLabItems;

    let itemExists = readyToAdd.filter((element, index, array) => {
        // Return true or false
        return element.id === i._id;
    });

    if ( itemExists.length<=0 ){
      readyToAdd.push({
        id: i._id,
        priority: 'High',
        xUrgent : (props.history.location.state.lifelimbStatus?'Yes':'No'),
        notes: {
          text: '',
          audio: null,
        }
      });
    }

    dispatch({
      field: 'seletedLabItems',
      value: readyToAdd
    });


    setSearchQuery("");
    // setaddLabRequest(true)
  }

  const handleCallFromBookmark = (entryId) => {
    let favItems = favoriteListData.filter((element, index, array) => {
        // Return true or false
        return element._id == entryId;
    });

    let readyToAdd = seletedLabItems;

    if (favItems.length > 0){
      let currentItem = favItems[0];
      Object.entries(favItems[0].serviceIds).map((req, reqIndex) =>{

        let itemExists = readyToAdd.filter((element, index, array) => {
            // Return true or false
            return element.id === req[1].requestId;
        });
        if ( itemExists.length<=0 ){
          readyToAdd.push({
            id: req[1].requestId,
            priority: 'High',
            xUrgent : (props.history.location.state.lifelimbStatus?'Yes':'No'),
            notes: {
              text: '',
              audio: null,
            }
          });
        }
      })

      dispatch({
        field: 'seletedLabItems',
        value: readyToAdd
      });
      
    }else{
      setOpenNotification(true);
      setErrorMsg('Sorry, the items doesn\'t exists');
    }
  }

  return (
    
    <div
      style={{
        backgroundColor: "rgb(19 213 159)",
        position: "fixed",
        display: "flex",
        width: "100%",
        height: "100%",
        flexDirection: "column",
        flex: 1,
        overflowY: "scroll",
      }}
    >
      <Header history={props.history} />

      <div className="cPadding">
        <h4 className="h6 text-white"
          style={{
            fontWeight: '700'
          }}>Selected Profiles/Lab Requests 
          {
            props.history.location.state.lifelimbStatus?<span className="badge badge-success pull-right">LifeLimb: {(props.history.location.state.lifelimbStatus?'ON':'Off')}</span>:undefined
          }
        </h4>
        <div
          style={{
            zIndex: 10,
            marginTop: 10,
          }}
        >
          <Paper className="mb-3">
            <Table size="small" stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>Service Name</TableCell>
                </TableRow>
              </TableHead>
              {seletedLabItems.length > 0 ? (
                <TableBody>
                  {seletedLabItems.map((i, itemIx) => {
                    let item = mItems.filter(function callback(element, index, array) {
                        // Return true or false
                        return element._id === i.id;
                    });
                    return (
                      <TableRow
                        key={i}
                        style={{ cursor: "pointer" }}
                        title="Click to Remove"
                      >
                        <TableCell>
                          <div className="serviceOffers">
                            <div className="serviceHeader">
                              <div className="serviceDesc-Name">{i.xUrgent==='Yes'?<i className="fa fa-info-circle text-market"></i>:<i className="fa fa-caret-right"></i>}<span className="ml-2"><strong>{item[0].category}</strong>: {item[0].name}</span></div>
                              <div className="serviceOption">
                                <FormControl variant="filled" className="mServiceDropdown" InputProps={{ disableUnderline: true }}>
                                  <Select
                                    name={"labPriority"}
                                    value={i.priority}
                                    InputProps={{ disableUnderline: true }}
                                    onChange={(e) => {
                                      onChangePriorityValue(e.target.value, i, item[0]._id)
                                    }}
                                  >
                                    {priorityArray.map((val) => {
                                      return (
                                        <MenuItem key={val.key} value={val.key}>
                                          {val.value}
                                        </MenuItem>
                                      );
                                    })}
                                  </Select>
                                </FormControl>
                                <Icon className="fa fa-trash" color="secondary" onClick={() => removeSelectedLabItem(i, item[0]._id)} />
                              </div>
                            </div>
                            <div className="serviceNotes">
                              <div className="micControl">
                                <div className="d-flex align-items-center">
                                  <div
                                    style={{
                                      backgroundColor: "#2C6DDD",
                                      height: 42,
                                      borderRadius: 42,
                                      width: 42,
                                      paddingLeft: 10,
                                      cursor: 'pointer'
                                    }}
                                  >
                                    {isRecording ? (
                                      recordingField===item[0]._id?
                                      <StopIcon
                                        style={{ marginTop: 10, color: "white" }}
                                        fontSize="16px"
                                        onClick={stopRecording}
                                        disabled={!isRecording}
                                        color="secondary"
                                      />:
                                      <MicIcon
                                        style={{ marginTop: 10, color: "white" }}
                                        fontSize="16px"
                                        onClick={() => {
                                          startRecording(i, item[0]._id)
                                        }}
                                        disabled={isRecording}
                                        color="primary"
                                      />
                                    ) : (
                                      <MicIcon
                                        style={{ marginTop: 10, color: "white" }}
                                        fontSize="16px"
                                        onClick={() => {
                                          startRecording(i, item[0]._id)
                                        }}
                                        disabled={isRecording}
                                        color="primary"
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="notesDiv">
                                <TextField
                                  // required
                                  multiline="true"
                                  rows="3"
                                  // disabled={enableForm}
                                  label="Any Comments / Notes? (Optional)"
                                  name={"labComments"}
                                  value={i.notes.text}
                                  // error={labComments === ""}
                                  onChange={(e) => {onChangeNotesValue(e.target.value, i, item[0]._id)}}
                                  className="textInputStyle"
                                  variant="filled"
                                  InputProps={{
                                    disableUnderline: true,
                                  }}
                                />
                                {isRecording && (recordingField===item[0]._id) ? (
                                  <div
                                    style={{
                                      marginLeft: matches ? 110 : 18,
                                      marginRight: matches ? 110 : 75,
                                      width: matches ? 550 : 100,
                                    }}
                                  >
                                    <Loader
                                      type="ThreeDots"
                                      color="blue"
                                      height={40}
                                      width={40}
                                    />
                                  </div>
                                ) : (
                                  <audio
                                    style={{
                                      marginRight: 10,
                                      width: matches ? 450 : 260,
                                      marginTop: 6,
                                    }}
                                    src={blobURL[item[0]._id]}
                                    controls="controls"
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              ) : (
                <TableBody>
                  <TableRow key="0">
                    <TableCell align="center" colSpan={5}>No Profiles/Lab Requests Selected</TableCell>
                  </TableRow>
                </TableBody>
              )} 
            </Table>
          </Paper>
          {seletedLabItems.length > 0 ? (
            <div className="row row-5">
              <div className="col-12 col-md-4 offset-md-4">
                <Button
                  // className="addButton"
                  style={{
                    ...styles.stylesForButton,
                    backgroundColor: "#AD6BBF",
                    color: "white",
                    cursor: "pointer",
                    borderRadius: 5,
                    backgroundColor: "rgb(173, 107, 191)",
                    height: matches ? 56 : 45,
                    outline: "none",
                    width: "100%",
                  }}
                  // disabled={!addLabRequest}
                  onClick={addSelectedLabItem}
                  variant="contained"
                  color="primary"
                  fullWidth
                >Submit</Button>
              </div>
            </div>
          ) : (
            undefined
          )} 
          <hr />
        </div>
        
        <div class="dropdown pull-right dropdown-favorite">
          <button class="btn btn-light dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i className="float-right fa fa-heart text-danger"></i></button>
          <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
            <a class="dropdown-item" href="/dashboard/home/manage-favorites/lab"><i className="fa fa-list" /> Manage Favorite</a>
            <div className="dropdown-divider"></div>
            {
              Object.entries(favoriteListData).map((obj, item) => {
                return <span class="dropdown-item d-flex justify-content-between align-items-center" onClick={() => {
                  handleCallFromBookmark(obj[1]._id)
                }}>{obj[1].listName} - <i className="badge badge-danger">{obj[1].serviceIds.length}</i></span>;
              })
            }
          </div>
        </div>


        <h4 
          className="h6 text-white"
          style={{
            fontWeight: '700'
          }}>Select Test(s)/Bundle(s)</h4>
        <div
          className="form-group "
          style={{
            ...styles.inputContainerForTextField
          }}
        >
          <TextField
            required
            label="Search by Lab Test"
            name={"searchQuery"}
            value={searchQuery}
            onChange={handlePauseLabSearch}
            className="textInputStyle"
            variant="filled"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <AccountCircle />
                </InputAdornment>
              ),
              className: classes.input,
              classes: { input: classes.input },
              disableUnderline: true,
            }}
            InputLabelProps={{
              className: classes.label,
              classes: { label: classes.label },
            }}
          />
        </div>

        {searchQuery ? (
          <div
            style={{
              zIndex: 10,
              marginTop: 10,
            }}
          >
            <Paper style={{ maxHeight: 200, overflow: "auto" }}>
              {itemFoundSuccessfull && itemFound !== "" ? (
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Service Name</TableCell>
                      <TableCell align="right">Type</TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {itemFound.map((i) => {
                      return (
                        <TableRow
                          key={i.serviceNo}
                          onClick={() => handleAddItem(i)}
                          style={{ cursor: "pointer" }}
                        >
                          <TableCell>{i.name}</TableCell>
                          <TableCell align="right">{i.category}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              ) : loadSearchedData ? (
                <div style={{ textAlign: "center" }}>
                  <Loader
                    type="TailSpin"
                    color="#2c6ddd"
                    height={25}
                    width={25}
                    style={{
                      display: "inline-block",
                      padding: "10px",
                    }}
                  />
                  <span
                    style={{
                      display: "inline-block",
                      padding: "10px",
                    }}
                  >
                    <h4> Searching Lab Test...</h4>
                  </span>
                </div>
              ) : searchQuery && !itemFoundSuccessfull ? (
                <div style={{ textAlign: "center", padding: "10px" }}>
                  <h4>No Lab Test Found !</h4>
                </div>
              ) : (
                undefined
              )}
            </Paper>
          </div>
        ) : (
          undefined
        )} 
        
        <div className="row row-5">
          {
            isLoading ? <div className="col-md-12"><div className="alert alert-success">Profiles are loading! Please wait...</div></div> :
            categoriesData ? 
              categoriesData.map((d, index) => {
                return (
                  <div className="col-12 col-md-3 text-center">
                    <div className="mb-3">
                      <button className="pl-2 pr-2 btn btn-block bg-white dropdown-toggle" 
                        type="button" id={ "dropdownMenuButton-" + (index + 1) } 
                        onClick={() => toggleDropdownMenu(d._id)}
                        style={{ height: 65 }}
                        aria-haspopup="true" aria-expanded="false">
                        <span style={{ ...styles.textStyles, "white-space": "initial" }}>{ d._id }</span>
                      </button>
                      <div className={classNames(`dropdown-menu w-100 pb-0`, dropdownOpened(d._id))} aria-labelledby={ "dropdownMenuButton-" + (index + 1) }>
                        <div style={{ height: 160, overflowY: "scroll"}}>
                        {
                         d.items.length > 0 ? 
                            d.items.map((nd, nindex) => {
                              return (
                                <div className="dropdown-items pl-3 pr-3 d-flex align-items-center justify-content-between">
                                  <FormControlLabel
                                    control={<GreenCheckbox checked={ itemsExistInDefaultSelection( uniqueCodeField(d._id), nd._id )  } value={ nd._id } onChange={handleChange} name={ uniqueCodeField(d._id) } />}
                                    label={nd.name}
                                    style={{
                                      margin: 0,
                                    }}
                                  />
                                  
                                </div>
                              )
                            }) : ''
                        }
                        </div>
                        <div className="p-3">
                          <div className="row row-5">
                            <div className="col-6">
                              <strong>Priority:&nbsp;&nbsp;</strong> 
                              <FormControl variant="filled" className="mServiceDropdown" InputProps={{ disableUnderline: true }}>
                                <Select
                                  name={"labPriority"}
                                  value={labPriorityDefaultSelection( d._id ).priority}
                                  InputProps={{ disableUnderline: true }}
                                  onChange={(e) => {
                                    onChangeDropDownPriorityValue(e.target.value, d._id, 'priority')
                                  }}
                                >
                                  {priorityArray.map((val) => {
                                    return (
                                      <MenuItem key={val.key} value={val.key}>
                                        {val.value}
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                              </FormControl>
                            </div>
                          </div>
                        </div>
                        <div className="p-2 bg-light">
                          <Button
                            color="primary"
                            size="small"
                            onClick={() => addSelectedItems(uniqueCodeField(d._id))}
                            variant="contained"
                            fullWidth="true"
                          >Add Selected</Button>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })
              : ''
          }

          {
            !isLoading ? 
            <div className="col-12 col-md-3 text-center">
              <div className="dropdown mb-3">
                <button className="pl-2 pr-2 btn btn-block bg-white dropdown-toggle" 
                  type="button" id={ "dropdownMenuButton-checkups" } 
                  data-toggle="dropdown" 
                  style={{ height: 65 }}
                  aria-haspopup="true" aria-expanded="false">
                  <span style={{ ...styles.textStyles, "white-space": "initial" }}>Checkups</span>
                </button>
                <div className="dropdown-menu w-100 pb-0" aria-labelledby={ "dropdownMenuButton-checkups" }>
                  <div style={{ height: 160, overflowY: "scroll"}}>
                  

                    <RadioGroup aria-label="gender" name="gender1" value={bulkTestSelected} onChange={handleCheckupChange}>
                      <div className="dropdown-items pl-3 pr-3 d-flex align-items-center justify-content-between"><FormControlLabel value="cu1" control={<GreenRadio />} label="Check-up - 1" style={{ margin: 0 }} /></div>
                      <div className="dropdown-items pl-3 pr-3 d-flex align-items-center justify-content-between"><FormControlLabel value="cu2" control={<GreenRadio />} label="Check-up - 2" style={{ margin: 0 }} /></div>
                      <div className="dropdown-items pl-3 pr-3 d-flex align-items-center justify-content-between"><FormControlLabel value="cu3" control={<GreenRadio />} label="Check-up - 3" style={{ margin: 0 }} /></div>
                      <div className="dropdown-items pl-3 pr-3 d-flex align-items-center justify-content-between"><FormControlLabel value="cu4" control={<GreenRadio />} label="Super Check-up" style={{ margin: 0 }} /></div>
                      <div className="dropdown-items pl-3 pr-3 d-flex align-items-center justify-content-between"><FormControlLabel value="cu5" control={<GreenRadio />} label="Royal Check-up" style={{ margin: 0 }} /></div>
                    </RadioGroup>



                  </div>
                  <div className="p-2 bg-light">
                    <Button
                      color="primary"
                      size="small"
                      onClick={() => addSelectedCheckup()}
                      variant="contained"
                      fullWidth="true"
                    >Add Selected</Button>
                  </div>
                </div>
              </div>
            </div>
            :null
          }
        </div>

        <div style={{ display: "flex", justifyContent: "space-between", marginTop: 20 }}>
          <div style={{ marginTop: "2%", marginBottom: "2%" }}>
            <Button
              onClick={() => {
                // Cancel
                props.history.push({
                  pathname: "/dashboard/home/"+episodeId+"/lab",
                });
              }}
              style={{
                ...styles.stylesForButton,
                backgroundColor: "white",
                color: "grey",
                width: matches ? " " : 150,
              }}
              variant="contained"
            >
              <strong>Cancel</strong>
            </Button>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "2%",
              marginBottom: "2%",
            }}
          >
            {/*
            <Button
              style={{
                ...styles.stylesForSubmitButton,
              }}
              variant="contained"
              color="primary"
            >Submit</Button>
            */}
          </div>
        </div>
      </div>
      <Notification
        msg={errorMsg}
        open={openNotification}
        success={successMsg}
      />
    </div>
  );
}