/*eslint-disable*/
import React, { useState, useEffect } from "react";
// @material-ui/core components
import Button from "@material-ui/core/Button";
import Notification from "../../components/Snackbar/Notification.js";
import Paper from "@material-ui/core/Paper";
import CustomTable from "../../components/Table/Table";
import ConfirmationModal from "../../components/Modal/confirmationModal";
import axios from "axios";
import { getDischargedEDR } from "../../public/endpoins";

import Loader from "react-loader-spinner";

import Header from "../../components/Header/Header";

import business_Unit from "../../assets/img/Medication Order.png";

import cookie from "react-cookies";

import Back_Arrow from "../../assets/img/Back_Arrow.png";

import "../../assets/jss/material-dashboard-react/components/loaderStyle.css";
import { makeStyles } from "@material-ui/core/styles";

const styles = {
  inputContainerForTextField: {
    marginTop: 6,
  },

  inputContainerForDropDown: {
    marginTop: 6,
  },

  stylesForLabel: {
    fontWeight: "700",
    color: "white",
  },

  stylesForButton: {
    color: "white",
    cursor: "pointer",
    borderRadius: 15,
    backgroundColor: "#2C6DDD",
    width: "140px",
    height: "50px",
    outline: "none",
  },

  stylesForPurchaseButton: {
    color: "white",
    cursor: "pointer",
    borderRadius: 15,
    backgroundColor: "#2C6DDD",
    width: "60%",
    height: "50px",
    outline: "none",
  },
};
const useStyles = makeStyles(styles);

const tableHeadingForBUMember = [
  "Request No",
  "Patient MRN",
  "Patient Name",
  "Date Generated",
  "Status",
  "Actions",
];
const tableDataKeysForBUMember = [
  "requestNo",
  ["patientId", "profileNo"],
  ["patientId", "fullName"],
  "createdAt",
  "status",
];

const actions = { view: true };

export default function ReplenishmentRequest(props) {
  const classes = useStyles();

  const [purchaseRequests, setPurchaseRequest] = useState("");
  const [vendors, setVendor] = useState("");
  const [statues, setStatus] = useState("");
  const [items, setItems] = useState("");
  const [deleteItem, setdeleteItem] = useState("");
  const [modalVisible, setModalVisible] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [openNotification, setOpenNotification] = useState(false);

  const [currentUser, setCurrentUser] = useState(cookie.load("current_user"));
  const [buObj, setBUObj] = useState("");

  const [actionsForTesting, setActions] = useState({
    edit: false,
    delete: false,
    view: false,
  });

  if (openNotification) {
    setTimeout(() => {
      setOpenNotification(false);
      setErrorMsg("");
    }, 2000);
  }

  function getPurchaseRequests() {
    axios
      .get(getDischargedEDR)
      .then((res) => {
        if (res.data.success) {
          console.log(res.data.data);
          res.data.data.map(
            (p) => (
              (p.patientId.profileNo = p.patientId.identifier[0].value),
              (p.patientId.firstName = p.patientId.name[0].given[0]),
              (p.patientId.lastName = p.patientId.name[0].family),
              (p.patientId.fullName = p.patientId.name[0].given[0] + " " + p.patientId.name[0].family),
              (p.patientId.gender = p.patientId.gender),
              (p.patientId.age = p.patientId.age),
              (p.patientId.weight = p.patientId.weight),
              (p.createdAt = p.createdTimeStamp)
            )
          );
          setPurchaseRequest(res.data.data.reverse());
        } else if (!res.data.success) {
          setErrorMsg(res.data.error);
          setOpenNotification(true);
        }
        return res;
      })
      .catch((e) => {
        console.log("error: ", e);
      });
  }

  useEffect(() => {
    // setOptions();

    getPurchaseRequests();
  }, []);

  const handleView = (obj) => {
    let path = `patientclearence/add`;
    props.history.push({
      pathname: path,
      state: {
        comingFor: "add",
        selectedItem: obj,
      },
    });
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        position: "fixed",
        width: "100%",
        height: "100%",
        backgroundColor: "#60d69f",
        overflowY: "scroll",
      }}
    >
      <Header history={props.history} />
      <div className="cPadding">
        <div className="subheader">
          <div>
            <img src={business_Unit} />
            <h4>Pending Clearence</h4>
          </div>
        </div>

        <div
          style={{
            flex: 4,
            display: "flex",
            flexDirection: "column",
          }}
        >
          {purchaseRequests ? (
            <div>
              {purchaseRequests && purchaseRequests.length > 0 ? (
                <CustomTable
                  tableData={purchaseRequests}
                  action={actions}
                  tableDataKeys={tableDataKeysForBUMember}
                  tableHeading={tableHeadingForBUMember}
                  handleView={handleView}
                  borderBottomColor={"#60d69f"}
                  borderBottomWidth={20}
                />
              ) : (
                <h4 style={{ textAlign: "center" }}>No Data Found</h4>
              )}

              <ConfirmationModal
                modalVisible={modalVisible}
                msg="Are you sure want to delete the record?"
                hideconfirmationModal={() => setModalVisible(false)}
                onConfirmDelete={() => deleteVendor()}
                setdeleteItem={() => setdeleteItem("")}
              />

              <Notification msg={errorMsg} open={openNotification} />
            </div>
          ) : (
            <div className="LoaderStyle">
              <Loader type="TailSpin" color="red" height={50} width={50} />
            </div>
          )}
        </div>
        <div style={{ marginBottom: 20 }}>
          <img
            onClick={() => props.history.goBack()}
            src={Back_Arrow}
            style={{ width: 40, height: 30, cursor: "pointer" }}
          />
        </div>
      </div>
    </div>
  );
}
