export const tableHeadingForCC = ["CCID", "CC Name", "No. of Patients", " "];
export const tableDataKeysForCC = ["chiefComplaintId", "name", "count"];

export const tableHeadingForCareStreams = [
  "Room ID",
  "CareStream",
  "Production Area",
  "Total Patients",
];
export const tableDataKeysForCareStreams = [
  "id",
  "stafName",
  "production",
  "startTime",
];

export const tableHeadingForTimeInterval = [
  "MRN",
  "Patient Name",
  "Admitted Date/Time",
  "Discharged Date/Time",
  "Time Interval",
  " ",
];
export const tableDataKeysForTimeInterval = [
  "mrn",
  "patientName",
  "createdTimeStamp",
  "dischargeTimestamp",
  "hrs",
];

export const tableHeadingForTransferToEOU = [
  "MRN",
  "Patient Name",
  "Production Area",
  "Bed Cell No",
  " ",
];
export const tableDataKeysForTransferToEOU = [
  "mrn",
  "patientName",
  "productionArea",
  "bedCell",
];

export const tableHeadingForDischargedPatients = [
  "Room ID",
  "MRN",
  "Patient Name",
  "CareStream",
  "Date / Time",
  " ",
];
export const tableDataKeysForDischargedPatients = [
  "roomId",
  "mrn",
  "patientName",
  "careStream",
  "dischargeTimestamp",
];

export const tableHeadingForLabTests = [
  "Room ID",
  "MRN",
  "Patient Name",
  "Total Tests",
  " ",
];
export const tableDataKeysForLabTests = [
  "roomId",
  "mrn",
  "patientName",
  "totalTests",
];

export const tableHeadingForDeathsOccured = [
  "Room ID",
  "MRN",
  "Patient Name",
  "CareStream",
  "Date / Time",
  " ",
];
export const tableDataKeysForDeathsOccured = [
  "roomId",
  "mrn",
  "patientName",
  "careStream",
  "dischargeTimestamp",
];

export const tableHeadingForCriticalFunctions = [
  "Room ID",
  "Production Team",
  "Critical Function Called",
  "Date / Time",
];
export const tableDataKeysForCriticalFunctions = [
  "id",
  "stafName",
  "addedByName",
  "createdAt",
];

export const tableHeadingForCareStreamTimeInterval = [
  "MRN",
  "Patient Name",
  "Production Team",
  "Admitted Date/Time",
  "Current Date/Time",
  "Time Interval",
];
export const tableDataKeysForCareStreamTimeInterval = [
  "id",
  "stafName",
  "addedByName",
  "createdAt",
];
