import React from "react";
import cookie from "react-cookies";
import Header from "../../../../components/Header/Header";
import RoomsHistoryIcon from "../../../../assets/img/ED RoomsHistory.png";
import EDMgmtIcon from "../../../../assets/img/ED RoomsManagement.png";
import EOUMgmtIcon from "../../../../assets/img/EOU RoomsManagement.png";
import RoomsCurrentActivityIcon from "../../../../assets/img/ED RoomsCurrent Activity.png";
import Back from "../../../../assets/img/Back_Arrow.png";
import MenuTree from "../../../../components/MenuTree/MenuTree";

const EDroomManagement = [
  { img: EDMgmtIcon, text: "ED Rooms Management" },
  {
    img: RoomsHistoryIcon,
    text: "ED Rooms History",
    path: "edRoomsMgmt/history",
  },
  {
    img: RoomsCurrentActivityIcon,
    text: "ED Rooms Current Activity",
    path: "edRoomsMgmt/currentActivity",
  },
];

const EOUroomManagement = [
  { img: EOUMgmtIcon, text: "EOU Rooms Management" },
  {
    img: RoomsHistoryIcon,
    text: "EOU Rooms History",
    path: "eouRoomsMgmt/history",
  },
  {
    img: RoomsCurrentActivityIcon,
    text: "EOU Rooms Current Activity",
    path: "eouRoomsMgmt/currentActivity",
  },
];

class EDEOUroomsMgmt extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: "",
    };
  }

  componentDidMount() {
    this.setState({ currentUser: cookie.load("current_user") });
  }

  render() {
    let path = this.props.history.location.pathname;
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          position: "fixed",
          width: "100%",
          height: "100%",
          background:
            "linear-gradient(25deg, rgba(101,228,193,1) 0%, rgba(58,219,175,1) 33%, rgba(15,206,147,1) 66%, rgba(6,142,103,1) 100%)",
          backgroundSize: "100%",
        }}
      >
        <Header history={this.props.history} />

        <MenuTree
          history={this.props.history}
          options={
            path.includes("edRoomsMgmt")
              ? EDroomManagement
              : path.includes("eouRoomsMgmt")
              ? EOUroomManagement
              : ""
          }
        />

        <div
          style={{
            position: "fixed",
            width: "100%",
            height: "10%",
            top: "90%",
          }}
        >
          <img
            alt="Back"
            onClick={() => this.props.history.goBack()}
            src={Back}
            style={{
              width: 45,
              height: 35,
              marginLeft: "5%",
              cursor: "pointer",
            }}
          />
        </div>
      </div>
    );
  }
}
export default EDEOUroomsMgmt;
