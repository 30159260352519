import axios from "axios";
import extractTime from "../../../utils/extractTimeFromDate";
import {
  addNewShiftURL,
  getAllShiftsURL,
  updateShiftURL,
} from "../../../public/endpoins";

export function getShiftsData(
  setShiftData,
  setIsLoading,
  setErrorMsg,
  setOpenNotification
) {
  axios
    .get(getAllShiftsURL)
    .then((res) => {
      if (res.data.success) {
        console.log("Shifts data : ", res.data.data);
        res.data.data.map(
          (d) => (
            (d.addedBy =
              d.addedBy.name[0].given[0] + " " + d.addedBy.name[0].family),
            (d.startTimeFormatted = extractTime(d.startTime)),
            (d.endTimeFormatted = extractTime(d.endTime))
          )
        );
        setShiftData(res.data.data);
        setIsLoading(false);
      } else if (!res.data.success) {
        setErrorMsg(res.data.error);
        setOpenNotification(true);
      }
      return res;
    })
    .catch((e) => {
      console.log("error: ", e);
      setErrorMsg("Cannot get shifts data at the moment");
      setOpenNotification(true);
    });
}

export function addNewShift(params, props, setOpenNotification, setErrorMsg) {
  axios
    .post(addNewShiftURL, params)
    .then((res) => {
      console.log("resonse : ", res);
      if (res.data && res.data.success) {
        props.history.push({
          pathname: "/dashboard/home/success",
          state: {
            message1: "New Shift added successfully !",
          },
        });
      } else {
        console.log("In else if ", res);
        setErrorMsg(res.error);
        setOpenNotification(true);
      }
    })
    .catch((e) => {
      console.log("catch error : ", e);
      setOpenNotification(true);
      setErrorMsg("Cannot add a new shift at the moment");
    });
}

export function editShift(props, params, setOpenNotification, setErrorMsg) {
  console.log("props", props);
  axios
    .put(updateShiftURL, params)
    .then((res) => {
      if (res.data.success) {
        props.history.push({
          pathname: "/dashboard/home/success",
          state: {
            message1: "Shift updated successfully !",
          },
        });
      } else if (!res.data.success) {
        setErrorMsg(res.data.error);
        setOpenNotification(true);
      }
    })
    .catch((e) => {
      setOpenNotification(true);
      setErrorMsg("Cannot update this shift at the moment");
    });
}
