import axios from "axios";
import { colorForDashboard } from "../../../utils/Constants";

export function getResponse(
  endpoint,
  setResponseData,
  setOpenNotification,
  setErrorMsg,
  setisLoading
) {
  axios
    .get(endpoint)
    .then((res) => {
      if (res.data.success) {
        console.log("res.data: ", res.data);
        setResponseData(res.data);
        setisLoading(false);
      } else if (!res.data.success) {
        setErrorMsg(res.data.error);
        setOpenNotification(true);
      }
      return res;
    })
    .catch((e) => {
      console.log("error: ", e);
    });
}

export function getDialerGraphCall(
  firstDialerComparisonNumber,
  secondDialerComparisonNumber,
  firstTatComparisonNumber,
  secondTatComparisonNumber,
  cardObj,
  graphField,
  setDialorData,
  setDialorColor,
  setTAT,
  setTATColor,
  setGraphData
) {
  const { TAT, totalPending } = cardObj;
  setGraphData(cardObj[graphField]);
  if (totalPending >= 0 && totalPending <= firstDialerComparisonNumber) {
    setDialorColor(colorForDashboard.first);
  } else if (
    totalPending >= firstDialerComparisonNumber + 1 &&
    totalPending <= secondDialerComparisonNumber
  ) {
    setDialorColor(colorForDashboard.second);
  } else if (totalPending >= secondDialerComparisonNumber + 1) {
    setDialorColor(colorForDashboard.third);
  }

  console.log("****");
  if (TAT >= 0 && TAT <= firstTatComparisonNumber) {
    setTATColor(colorForDashboard.first);
  } else if (
    TAT >= firstTatComparisonNumber + 1 &&
    TAT <= secondTatComparisonNumber
  ) {
    setTATColor(colorForDashboard.second);
  } else if (TAT >= secondTatComparisonNumber + 1) {
    setTATColor(colorForDashboard.third);
  }

  setDialorData(totalPending);
  setTAT(TAT);
}

export function getValuesCardCalls(
  firstTatComparisonNumber,
  secondTatComparisonNumber,
  data,
  valueCard,
  valueCardColor
) {
  console.log("response after fetch**", data);
  valueCard(data);
  if (data >= 0 && data <= firstTatComparisonNumber) {
    valueCardColor(colorForDashboard.first);
  } else if (
    data >= firstTatComparisonNumber + 1 &&
    data <= secondTatComparisonNumber
  ) {
    valueCardColor(colorForDashboard.second);
  } else if (data >= secondTatComparisonNumber + 1) {
    valueCardColor(colorForDashboard.third);
  }
}
