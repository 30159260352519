import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import "../css/call-window.css";
// const getButtonClass = (icon, enabled) =>
//   classnames(`btn-action fa ${icon}`, { disable: !enabled });

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import VideoCallIcon from "@material-ui/icons/VideoCall";
import CallIcon from "@material-ui/icons/Call";
import CallEndIcon from "@material-ui/icons/CallEnd";

import MicIcon from "@material-ui/icons/Mic";
import MicOffIcon from "@material-ui/icons/MicOff";

function CallWindow({
  peerSrc,
  localSrc,
  config,
  mediaDevice,
  status,
  endCall,
}) {
  const peerVideo = useRef(null);
  const localVideo = useRef(null);
  const [video, setVideo] = useState(config.video);
  const [audio, setAudio] = useState(config.audio);

  useEffect(() => {
    if (peerVideo.current && peerSrc) peerVideo.current.srcObject = peerSrc;
    if (localVideo.current && localSrc) localVideo.current.srcObject = localSrc;
  });

  useEffect(() => {
    if (mediaDevice) {
      mediaDevice.toggle("Video", video);
      mediaDevice.toggle("Audio", audio);
    }
  });

  /**
   * Turn on/off a media device
   * @param {String} deviceType - Type of the device eg: Video, Audio
   */
  const toggleMediaDevice = (deviceType) => {
    if (deviceType === "video") {
      setVideo(!video);
      mediaDevice.toggle("Video");
    }
    if (deviceType === "audio") {
      setAudio(!audio);
      mediaDevice.toggle("Audio");
    }
  };

  return (
    // <Dialog
    //   open={status}
    //   aria-labelledby="alert-dialog-title"
    //   aria-describedby="alert-dialog-description"
    //   fullScreen={true}
    // >
    //   <DialogContent>
    <div style={{backgroundColor:'#60d69f'}}>
      {status ? (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            backgroundColor: "white",
            height: "100%",
            opacity: 1,
            zIndex: 1,
            bottom: 0,
            overflowY: "scroll",
          }}
        >
          <div
            style={{ marginTop: 10, display: "flex", justifyContent: "center" }}
          >
            <VideoCallIcon
              onClick={() => toggleMediaDevice("video")}
              color="primary"
              style={{ fontSize: 40 }}
            />

            {/* <button
              key="btnAudio"
              type="button"
              // className={getButtonClass("fa-microphone", audio)}
              className="btn-action fa fa-microphone"
              onClick={() => toggleMediaDevice("audio")}
            /> */}

            {audio ? (
              <MicIcon
                onClick={() => toggleMediaDevice("audio")}
                color="primary"
                style={{ fontSize: 40 }}
              />
            ) : (
              <MicOffIcon
                onClick={() => toggleMediaDevice("audio")}
                color="secondary"
                style={{ fontSize: 40 }}
              />
            )}

            <CallEndIcon
              onClick={() => endCall(true)}
              color="secondary"
              style={{ fontSize: 40 }}
            />
          </div>
          <video width='100%' height="70%" ref={peerVideo} autoPlay />
          <video width="140" height="100" style={{float:'right'}} ref={localVideo} autoPlay muted />
        </div>
      ) : (
        undefined
      )}
    </div>
  );
}

CallWindow.propTypes = {
  status: PropTypes.string.isRequired,
  localSrc: PropTypes.object, // eslint-disable-line
  peerSrc: PropTypes.object, // eslint-disable-line
  config: PropTypes.shape({
    audio: PropTypes.bool.isRequired,
    video: PropTypes.bool.isRequired,
  }).isRequired,
  mediaDevice: PropTypes.object, // eslint-disable-line
  endCall: PropTypes.func.isRequired,
};

export default CallWindow;
