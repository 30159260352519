import React, { useState, useEffect, useReducer } from 'react'
import Notification from '../../components/Snackbar/Notification.js'
import CustomTable from '../../components/Table/Table'
import axios from 'axios'
import _ from 'lodash'
import QRCodeScannerComponent from '../../components/QRCodeScanner/QRCodeScanner'
import {
  assignCC,
  getNursesWithPA,
  getAllStaff,
  getAvailablePA,
  getCCNursesByKeyword,
  getNursesSpecialty,
  getAllNurses,
} from '../../public/endpoins'
import { getNursesSpecialtyFunc } from '../UserManagement/staff/networkCallsForStaff'
import Loader from 'react-loader-spinner'
import Back from '../../assets/img/Back_Arrow.png'
import Header from '../../components/Header/Header'
import chiefCompIcon from '../../assets/img/Chief Complaints.png'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core/styles'
import '../../assets/jss/material-dashboard-react/components/loaderStyle.css'
import Button from '@material-ui/core/Button'
import plus_icon from '../../assets/img/Plus.png'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import cookie from 'react-cookies'
import AccountCircle from '@material-ui/icons/SearchOutlined'
import InputAdornment from '@material-ui/core/InputAdornment'
import Fingerprint from '../../assets/img/fingerprint.png'
import BarCode from '../../assets/img/Bar Code.png'
import Filter from '../../assets/img/Filter.png'
import MenuItem from '@material-ui/core/MenuItem'
import Chip from '@material-ui/core/Chip'
import DoneIcon from '@material-ui/icons/Done'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import DateFnsUtils from '@date-io/date-fns'
import {
  DatePicker,
  MuiPickersUtilsProvider,
  TimePicker,
} from '@material-ui/pickers'
import getTimeFromDate from '../../utils/extractTimeFromDate'

const tableHeading = [
  'Nurse ID',
  'Nurse Name',
  'Speciality',
  'Production Area',
  // 'Consultant Type',
  'Shift Start Time',
  'Shift End Time',
  ' ',
]
const tableDataKeys = [
  'identifier',
  'name',
  'speciality',
  'productionArea',
  // 'subType',
  'shiftStartTime',
  'shiftEndTime',
]

const experienceArray = [
  {
    key: '01',
    value: '01',
  },
  {
    key: '02',
    value: '02',
  },
  {
    key: '03',
    value: '03',
  },
  {
    key: '04',
    value: '04',
  },
  {
    key: '05',
    value: '05',
  },
]

const styles = {
  textFieldPadding: {
    paddingLeft: 5,
    paddingRight: 5,
  },
  stylesForButton: {
    color: 'white',
    cursor: 'pointer',
    borderRadius: 5,
    background: '#2c6ddd',
    width: '140px',
    height: '50px',
    outline: 'none',
  },
  stylesForCancelButton: {
    color: 'gray',
    cursor: 'pointer',
    borderRadius: 5,
    width: '140px',
    height: '50px',
    background: 'transparent',
    border: '1px solid gray',
  },
  unselectedChip: {
    color: '#070707',
    backgroundColor: '#F5F5F5',
    padding: '0px 20px 0px 20px',
  },
  selectedChip: {
    color: 'white',
    backgroundColor: 'rgb(19 213 159)',
    padding: '0px 20px 0px 20px',
  },
  textFieldMargin: {
    padding: '10px 0px 10px 18px',
  },
}

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(0),
  },
  input: {
    backgroundColor: 'white',
    boxShadow: 'none',
    borderRadius: 5,
    '&:after': {
      borderBottomColor: 'black',
      boxShadow: 'none',
    },
    '&:hover': {
      backgroundColor: 'white',
      boxShadow: 'none',
    },
    '&:focus': {
      backgroundColor: 'white',
      boxShadow: 'none',
      borderRadius: 5,
    },
  },
  root: {
    '& .MuiTextField-root': {
      backgroundColor: 'white',
    },
    '& .Mui-focused': {
      backgroundColor: 'white',
      color: 'black',
      boxShadow: 'none',
    },
    '& .Mui-disabled': {
      backgroundColor: 'white',
      color: 'gray',
    },
    '&:focus': {
      backgroundColor: 'white',
      boxShadow: 'none',
    },
    '& .MuiFormLabel-root': {
      fontSize: '13px',
      padding: '0px',
    },
  },
}))

export default function CCdoctors(props) {
  const matches = useMediaQuery('(min-width:600px)')
  const classes = useStyles()

  const initialState = {
    doctor: '',
    chiefComplaint: '',
    selectCC: '',
    experience: '',
    consultantType: '',
    availability: '',
    shifts: '',
  }

  function reducer(state, { field, value }) {
    return {
      ...state,
      [field]: value,
    }
  }

  const [state, dispatch] = useReducer(reducer, initialState)

  const {
    doctor,
    chiefComplaint,
    selectCC,
    experience,
    consultantType,
    availability,
    shifts,
  } = state

  const [currentUser, setcurrentUser] = useState(cookie.load('current_user'))
  const [ccData, setccData] = useState([])
  const [filteredccData, setfilteredccData] = useState([])
  const [errorMsg, setErrorMsg] = useState('')
  const [successMsg, setsuccessMsg] = useState('')
  const [openNotification, setOpenNotification] = useState(false)
  const [modalVisible, setModalVisible] = useState(false)
  const [filterModalVisible, setFilterModalVisible] = useState(false)
  const [searchCC, setSearchCC] = useState('')
  const [doctors, setDoctors] = useState([])
  const [chiefComplaints, setChiefComplaints] = useState([])
  const [doctorSpeciality, setDoctorSpeciality] = useState([])
  const [nursesSpeciality, setNursesSpeciality] = useState([])
  const [shiftStartTime, setShiftStartTime] = useState('')
  const [shiftEndTime, setShiftEndTime] = useState('')
  const [filterDialog, setFilterDialog] = useState(false)
  const [speciality, setSpeciality] = useState([])
  const [generalArray, setGeneralArray] = useState([])
  const [staffData, setStaffData] = useState([])
  const [QRCodeScanner, setQRCodeScanner] = useState(false)

  if (openNotification) {
    setTimeout(() => {
      setOpenNotification(false)
      setErrorMsg('')
      setsuccessMsg('')
    }, 2000)
  }

  useEffect(() => {
    getDoctorsWithCCData()
    getCCData()
    getDoctors()
    getSpecialities()
  }, [])

  useEffect(() => {
    setFilterData(ccData)
  }, [ccData])

  function getDoctors() {
    axios
      .get(getAllNurses)
      .then((res) => {
        if (res.data.success) {
          console.log('response', res.data.data)
          res.data.data.map(
            (d) => (d.name = d.name[0].given[0] + ' ' + d.name[0].family)
          )
          setDoctors(res.data.data)
        } else if (!res.data.success) {
          setErrorMsg(res.data.error)
          setOpenNotification(true)
        }
        return res
      })
      .catch((e) => {
        console.log('error: ', e)
      })
  }

  function getCCData() {
    axios
      .get(getAvailablePA)
      .then((res) => {
        if (res.data.success) {
          console.log(res.data.data.docs, 'Chief Complaints')
          setChiefComplaints(res.data.data)
        } else if (!res.data.success) {
          setErrorMsg(res.data.error)
          setOpenNotification(true)
        }
        return res
      })
      .catch((e) => {
        console.log('error: ', e)
      })
  }

  function getDoctorsWithCCData() {
    axios
      .get(getNursesWithPA)
      .then((res) => {
        if (res.data.success) {
          console.log('CC doc data', res.data.data)
          res.data.data.map(
            (d) => (
              (d.productionArea = d.chiefComplaint[d.chiefComplaint.length - 1]
                .chiefComplaintId
                ? d.chiefComplaint[d.chiefComplaint.length - 1].chiefComplaintId
                    .productionArea[
                    d.chiefComplaint[d.chiefComplaint.length - 1]
                      .chiefComplaintId.productionArea.length - 1
                  ].productionAreaId.paName
                : 'N/A'),
              (d.shiftStartTime = d.shift
                ? getTimeFromDate(d.shift.startTime)
                : 'N/A'),
              (d.shiftEndTime = d.shift
                ? getTimeFromDate(d.shift.endTime)
                : 'N/A'),
              (d.experience = d.experience.length > 0 ? '0' : '0'),
              (d.identifier = d.identifier[0].value),
              (d.name = d.name[0].given[0] + ' ' + d.name[0].family),
              (d.speciality = d.specialty.toString()),
              (d.subType = d.subType[0])
            )
          )
          setccData(res.data.data)
        } else if (!res.data.success) {
          setErrorMsg(res.data.error)
          setOpenNotification(true)
        }
        return res
      })
      .catch((e) => {
        console.log('error: ', e)
      })
  }

  function getSpecialities() {
    // axios
    //   .get(getNursesSpecialty)
    //   .then((res) => {
    //     if (res.data.success) {
    //       console.log('doctor speciality', res.data.data)
    //       setDoctorSpeciality(res.data.data)
    //     }
    //   })
    //   .catch((e) => {
    //     console.log('Error while adding staff', e)
    //     setOpenNotification(true)
    //     setErrorMsg('Error while getting speciality')
    //   })
    getNursesSpecialtyFunc(
      setNursesSpeciality,
      setOpenNotification,
      setErrorMsg
    )
  }

  const handleChipClick = (name, value) => {
    dispatch({ field: name, value: value })
  }

  const onChangeValue = (e) => {
    dispatch({ field: e.target.name, value: e.target.value })
  }

  useEffect(() => {
    setValuesInData(generalArray)
  }, [generalArray])

  function setValuesInData(generalArray) {
    let EDNursesData = generalArray.map((d) => {
      let obj = {
        ...d,
        nurseName: d.name[0].given[0] + ' ' + d.name[0].family,
        nurseId: d.identifier[0].value,
        shiftStartTime: d.shift
          ? getTimeFromDate(d.shift.startTime)
          : 'N/A',
        shiftEndTime: d.shift ? getTimeFromDate(d.shift.endTime) : 'N/A',
        specialty: d.specialty.toString(),
      }
      return obj
    })

    setStaffData([...EDNursesData.reverse()])
  }

  const handleCCsearch = (e) => {
    const a = e.target.value.replace(/[^\w\s]/gi, '')
    setSearchCC(a)
    if (a.length >= 3) {
      axios
        .get(getCCNursesByKeyword + '/' + a)
        .then((res) => {
          if (res.data.success) {
            if (res.data.data.length > 0) {
              console.log('results of search ', res.data.data)
              // res.data.data.map(
              //   (d) => (
              //     (d.productionArea =
              //       d.productionArea[0].productionAreaId.paName),
              //     (d.experience = d.experience),
              //     (d.identifier = d.identifier[0].value),
              //     (d.name = 'Dr. ' + d.name[0].given[0]),
              //     (d.speciality = d.specialty.toString()),
              //     (d.subType = d.subType[0])
              //   )
              // )
              res.data.data.map(
                (d) => (
                  (d.productionArea = d.chiefComplaint[
                    d.chiefComplaint.length - 1
                  ].chiefComplaintId
                    ? d.chiefComplaint[d.chiefComplaint.length - 1]
                        .chiefComplaintId.productionArea[
                        d.chiefComplaint[d.chiefComplaint.length - 1]
                          .chiefComplaintId.productionArea.length - 1
                      ].productionAreaId.paName
                    : 'N/A'),
                  (d.shiftStartTime = d.shift
                    ? getTimeFromDate(d.shift.startTime)
                    : 'N/A'),
                  (d.shiftEndTime = d.shift
                    ? getTimeFromDate(d.shift.endTime)
                    : 'N/A'),
                  (d.identifier = d.identifier[0].value),
                  (d.name = d.name[0].given[0] + ' ' + d.name[0].family),
                  (d.speciality = d.specialty.toString())
                  // (d.subType = d.subType[0])
                )
              )
              setccData(res.data.data)
            } else {
              setccData([])
            }
          }
        })
        .catch((e) => {
          console.log('error after searching Chief Complaints', e)
        })
    } else if (a.length === 0) {
      console.log('less')
      getDoctorsWithCCData()
    }
  }

  function scanQRCode() {
    setQRCodeScanner((pervState) => !pervState)
  }

  function handleScanQR(data) {
    setQRCodeScanner(false)
    console.log('json', JSON.parse(data))
    if (JSON.parse(data).profileNo) {
      handleCCsearch({
        target: {
          value: JSON.parse(data).profileNo,
          type: 'text',
        },
      })
    }
  }

  if (QRCodeScanner) {
    return (
      <div>
        {QRCodeScanner ? (
          <QRCodeScannerComponent
            handleScanQR={handleScanQR}
            scanQRCode={scanQRCode}
          />
        ) : (
          undefined
        )}
      </div>
    )
  }

  const handleChangeDate = (value, field) => {
    if (field === 'shiftStartTime') {
      setShiftStartTime(value ? value.toISOString() : '')
    } else setShiftEndTime(value ? value.toISOString() : '')
  }

  const handleRemove = (val, type) => {
    if (type === 'speciality') {
      let spec = speciality.filter((s) => s !== val)
      setSpeciality(spec)
    }
  }

  const checkValueAdded = (val, type) => {
    if (type === 'speciality') {
      let spec = speciality.find((s) => s === val)
      return spec
    }
  }

  const handleAddedValue = (val, type) => {
    if (type === 'speciality') {
      let spec = speciality.find((s) => s === val)
      if (!spec) {
        setSpeciality((pervState) => [...pervState, val])
      }
    }
  }

  function handleApplyFilters() {
    let res = [...generalArray]
    let resForSpeciality = []
    let resForTimeStart = []
    let resForTimeEnd = []

    if (speciality.length > 0) {
      for (let j = 0; j < res.length; j++) {
        for (let i = 0; i < speciality.length; i++) {
          if (res[j].specialty.includes(speciality[i])) {
            if (!resForSpeciality.find((i) => i._id === res[j]._id)) {
              resForSpeciality.push(res[j])
            }
          }
        }
      }
      res = resForSpeciality
    }

    if (shiftStartTime) {
      for (let i = 0; i < res.length; i++) {
        if (res[i].shift) {
          let sHForDb = new Date(res[i].shift.startTime)
          let sHForFilter = new Date(shiftStartTime)

          sHForDb.setSeconds(0, 0)
          sHForFilter.setSeconds(0, 0)

          let startHoursForDb = sHForDb.toISOString()
          let startHoursForFilter = sHForFilter.toISOString()

          if (
            startHoursForDb.split('T')[1] === startHoursForFilter.split('T')[1]
          ) {
            if (!resForTimeStart.find((i) => i._id === res[i]._id)) {
              resForTimeStart.push(res[i])
            }
          }
        }
      }

      res = resForTimeStart
    }

    if (shiftEndTime) {
      for (let i = 0; i < res.length; i++) {
        if (res[i].shift) {
          let sHForDb = new Date(res[i].shift.endTime)
          let sHForFilter = new Date(shiftEndTime)

          sHForDb.setSeconds(0, 0)
          sHForFilter.setSeconds(0, 0)

          let startHoursForDb = sHForDb.toISOString()
          let startHoursForFilter = sHForFilter.toISOString()

          if (
            startHoursForDb.split('T')[1] === startHoursForFilter.split('T')[1]
          ) {
            if (!resForTimeEnd.find((i) => i._id === res[i]._id)) {
              resForTimeEnd.push(res[i])
            }
          }
        }
      }

      res = resForTimeEnd
    }

    setValuesInData(res)
    setFilterDialog(false)
  }

  const addNewCC = () => {
    setModalVisible(true)
  }

  const addFilterToSearch = () => {
    setFilterModalVisible(true)
  }

  function setFilterData(filterData) {
    setfilteredccData([...filterData])
  }

  const handleFilterApply = () => {
    console.log('Chief complaint filter ', selectCC)
    console.log('Speciality ', speciality)
    console.log('Experience ', experience)
    console.log('Consultant type ', consultantType)
    console.log('Availability ', availability)
    console.log('Shifts ', shifts)

    let res = [...ccData]
    let resForCC = []
    let resForSpeciality = []
    let resForExperience = []
    let resForConsultant = []
    let resForAvailability = []

    if (selectCC !== '') {
      for (let j = 0; j < res.length; j++) {
        if (res[j].productionArea === selectCC) {
          if (!resForCC.find((i) => i._id === res[j]._id)) {
            resForCC.push(res[j])
          }
        }
      }
      res = resForCC
    }

    if (speciality !== '') {
      for (let j = 0; j < res.length; j++) {
        if (res[j].speciality.includes(speciality)) {
          if (!resForSpeciality.find((i) => i._id === res[j]._id)) {
            resForSpeciality.push(res[j])
          }
        }
      }
      res = resForSpeciality
    }

    if (experience !== '') {
      for (let j = 0; j < res.length; j++) {
        if (res[j].experience === experience) {
          if (!resForExperience.find((i) => i._id === res[j]._id)) {
            resForExperience.push(res[j])
          }
        }
      }
      res = resForExperience
    }

    if (consultantType !== '') {
      for (let j = 0; j < res.length; j++) {
        if (res[j].subType.includes(consultantType)) {
          if (!resForConsultant.find((i) => i._id === res[j]._id)) {
            resForConsultant.push(res[j])
          }
        }
      }
      res = resForConsultant
    }

    if (availability !== '') {
      for (let j = 0; j < res.length; j++) {
        if (availability === 'yes' && res[j].availability) {
          if (!resForAvailability.find((i) => i._id === res[j]._id)) {
            resForAvailability.push(res[j])
          }
        } else if (availability === 'no' && !res[j].availability) {
          if (!resForAvailability.find((i) => i._id === res[j]._id)) {
            resForAvailability.push(res[j])
          }
        }
      }
      res = resForAvailability
    }
    setFilterData(res)
    setFilterModalVisible(false)
  }

  const onModalHide = () => {
    dispatch({ field: 'doctor', value: '' })
    dispatch({ field: 'chiefComplaint', value: '' })
    dispatch({ field: 'selectCC', value: '' })
    dispatch({ field: 'speciality', value: '' })
    dispatch({ field: 'experience', value: '' })
    dispatch({ field: 'consultantType', value: '' })
    dispatch({ field: 'availability', value: '' })
    dispatch({ field: 'shifts', value: '' })
    setModalVisible(false)
    setFilterModalVisible(false)
  }

  const handleAssignCC = () => {
    let params = {
      staffId: doctor,
      assignedBy: currentUser._id,
      productionAreaId: chiefComplaint,
    }
    console.log('params', params)
    axios
      .put(assignCC, params)
      .then((res) => {
        if (res.data.success) {
          setOpenNotification(true)
          setsuccessMsg('Production Area Assigned Successfully')
          getDoctorsWithCCData()
        } else if (!res.data.success) {
          setOpenNotification(true)
          setErrorMsg('Cannot Assign Production Area')
        }
      })
      .catch((e) => {
        console.log('error after assigning Chief Complaint', e)
        setOpenNotification(true)
        setErrorMsg(e)
      })
    onModalHide()
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        position: 'fixed',
        width: '100%',
        height: '100%',
        backgroundColor: 'rgb(19 213 159)',
        overflowY: 'scroll',
      }}
    >
      <Header history={props.history} />

      <div className='cPadding'>
        <div className='subheader'>
          <div>
            <img src={chiefCompIcon} />
            <h4>Production Area Nurses</h4>
          </div>
          <div>
            <Button
              onClick={addFilterToSearch}
              style={{
                ...styles.stylesForButton,
                background: 'transparent',
                border: '1px solid white',
                fontSize: matches ? '' : 10,
              }}
              variant='contained'
              color='primary'
            >
              <img src={Filter} className='icon-style' />
              &nbsp; Filter
            </Button>
            &nbsp;&nbsp;
            <Button
              onClick={addNewCC}
              style={{ ...styles.stylesForButton, fontSize: matches ? '' : 10 }}
              variant='contained'
              color='primary'
            >
              <img src={plus_icon} className='icon-style' />
              &nbsp;&nbsp;
              <strong>Add New</strong>
            </Button>
          </div>
        </div>

        <div className={` ${classes.root}`} style={{ marginTop: '25px' }}>
          <div className='row' style={{ marginTop: '20px', marginRight: -5 }}>
            <div
              className='col-md-12 col-sm-12 col-12'
              style={{
                paddingRight: matches ? 5 : 0,
              }}
            >
              <TextField
                className='textInputStyle'
                id='searchCC'
                type='text'
                variant='filled'
                label='Search Nurses by Name / ID'
                name={'searchCC'}
                value={searchCC}
                onChange={handleCCsearch}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <AccountCircle />
                    </InputAdornment>
                  ),
                  className: classes.input,
                  classes: { input: classes.input },
                  disableUnderline: true,
                }}
              />
            </div>
          </div>
        </div>

        <div
          style={{
            flex: 4,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {filteredccData && filteredccData.length > 0 ? (
            <div>
              <div>
                <CustomTable
                  tableData={filteredccData}
                  tableDataKeys={tableDataKeys}
                  tableHeading={tableHeading}
                  borderBottomColor={'#60d69f'}
                  borderBottomWidth={20}
                />
              </div>
            </div>
          ) : filteredccData && filteredccData.length === 0 ? (
            <div className='row ' style={{ marginTop: '25px' }}>
              <div className='col-md-12 col-sm-12 col-12'>
                <h3
                  style={{
                    color: 'white',
                    textAlign: 'center',
                  }}
                >
                  Oops! No Data Found
                </h3>
              </div>
            </div>
          ) : (
            <div className='row'>
              <div className='LoaderStyle'>
                <Loader type='TailSpin' color='red' height={50} width={50} />
              </div>
            </div>
          )}

          <div className='row'>
            <div className='col-md-12 col-sm-12 col-12'>
              <img
                onClick={() => props.history.goBack()}
                src={Back}
                style={{
                  maxWidth: '45px',
                  height: 'auto',
                  cursor: 'pointer',
                }}
              />
            </div>
          </div>
        </div>

        <Dialog
          onClose={() => setModalVisible(false)}
          fullWidth={true}
          maxWidth={'lg'}
          bodyStyle={{ backgroundColor: 'red' }}
          contentStyle={{ backgroundColor: 'red' }}
          aria-labelledby='simple-dialog-title'
          open={modalVisible}
        >
          <DialogContent>
            <div className='container-fluid'>
              <div className='row'>
                <div className='d-flex justify-content-start'>
                  <DialogTitle
                    id='simple-dialog-title'
                    style={{ marginLeft: matches ? '-12px' : -28 }}
                  >
                    <h4 style={{ fontSize: matches ? ' ' : 16 }}>
                      Assign Production Area to Nurses
                    </h4>
                  </DialogTitle>
                </div>
              </div>

              <div className={`row ${classes.root}`}>
                <div
                  className='col-md-12 col-sm-12 col-12'
                  style={styles.textFieldPadding}
                >
                  <TextField
                    disabled
                    label='Assigned By'
                    name={'currentUser'}
                    value={
                      currentUser.name[0].given[0] +
                      ' ' +
                      currentUser.name[0].family
                    }
                    onChange={onChangeValue}
                    className='textInputStyle'
                    variant='filled'
                    InputProps={{
                      className: classes.input,
                      classes: { input: classes.input },
                      disableUnderline: true,
                    }}
                  />
                </div>
              </div>

              <div className={`row ${classes.root}`}>
                <div
                  className='col-md-12 col-sm-12 col-12'
                  style={styles.textFieldPadding}
                >
                  <TextField
                    select
                    fullWidth
                    label='Select Nurse'
                    name='doctor'
                    value={doctor}
                    onChange={onChangeValue}
                    variant='filled'
                    className='textInputStyle'
                    InputProps={{
                      className: classes.input,
                      classes: { input: classes.input },
                    }}
                  >
                    <MenuItem value=''>
                      <em>None</em>
                    </MenuItem>

                    {doctors.map((val) => {
                      return (
                        <MenuItem key={val._id} value={val._id}>
                          {val.name}
                        </MenuItem>
                      )
                    })}
                  </TextField>
                </div>
              </div>

              <div className={`row ${classes.root}`}>
                <div
                  className='col-md-12 col-sm-12 col-12'
                  style={styles.textFieldPadding}
                >
                  <TextField
                    select
                    fullWidth
                    label='Select Production Area'
                    name='chiefComplaint'
                    value={chiefComplaint}
                    onChange={onChangeValue}
                    variant='filled'
                    className='textInputStyle'
                    InputProps={{
                      className: classes.input,
                      classes: { input: classes.input },
                    }}
                  >
                    {chiefComplaints.map((val) => {
                      return (
                        <MenuItem key={val._id} value={val._id}>
                          {val.paName}
                        </MenuItem>
                      )
                    })}
                  </TextField>
                </div>
              </div>

              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div
                  style={{
                    display: 'flex',
                    marginTop: matches ? '2%' : '10%',
                    marginBottom: '2%',
                  }}
                >
                  <Button
                    style={{
                      ...styles.stylesForCancelButton,
                      width: matches ? 140 : 100,
                      height: matches ? 50 : 40,
                    }}
                    onClick={() => onModalHide()}
                    variant='contained'
                  >
                    Cancel
                  </Button>
                  &nbsp;&nbsp;
                  <Button
                    style={{
                      ...styles.stylesForButton,
                      width: matches ? 140 : 100,
                      height: matches ? 50 : 40,
                    }}
                    onClick={handleAssignCC}
                    variant='contained'
                  >
                    Assign
                  </Button>
                </div>
              </div>
            </div>
          </DialogContent>
        </Dialog>

        <Dialog
          onClose={() => setFilterModalVisible(false)}
          fullWidth={true}
          maxWidth={'lg'}
          bodyStyle={{ backgroundColor: 'red' }}
          contentStyle={{ backgroundColor: 'red' }}
          aria-labelledby='simple-dialog-title'
          open={filterModalVisible}
        >
          <DialogContent>
            <div className='container-fluid'>
              <div className='row'>
                <div className='d-flex justify-content-start'>
                  <DialogTitle
                    id='simple-dialog-title'
                    style={{ marginLeft: '-12px' }}
                  >
                    <h4>Search Filter</h4>
                  </DialogTitle>
                </div>
              </div>

              <div className={`row ${classes.root}`}>
                <div
                  className='col-md-12 col-sm-12 col-12'
                  style={styles.textFieldPadding}
                >
                  <TextField
                    select
                    fullWidth
                    label='Production Area'
                    id='selectCC'
                    name='selectCC'
                    value={selectCC}
                    onChange={onChangeValue}
                    variant='filled'
                    className='dropDownStyle'
                    InputProps={{
                      className: classes.input,
                      classes: { input: classes.input },
                      disableUnderline: true,
                    }}
                  >
                    <MenuItem value=''>
                      <em>None</em>
                    </MenuItem>

                    {chiefComplaints.map((val) => {
                      return (
                        <MenuItem key={val._id} value={val.paName}>
                          {val.paName}
                        </MenuItem>
                      )
                    })}
                  </TextField>
                </div>
              </div>

              <div>
                <div style={{}}>
                  <span style={{ color: 'grey' }}>Categories</span>
                  <div style={{}}>
                    {nursesSpeciality &&
                      nursesSpeciality.map((type) => {
                        return (
                          <Chip
                            key={type}
                            label={type}
                            onDelete={
                              checkValueAdded(type, 'speciality')
                                ? () => handleRemove(type, 'speciality')
                                : undefined
                            }
                            style={{
                              marginRight: 5,
                              marginTop: 10,
                              backgroundColor: checkValueAdded(
                                type,
                                'speciality'
                              )
                                ? '#60D69F'
                                : undefined,
                              color: checkValueAdded(type, 'speciality')
                                ? 'white'
                                : undefined,
                            }}
                            clickable
                            onClick={() => handleAddedValue(type, 'speciality')}
                          />
                        )
                      })}
                  </div>
                </div>
              </div>

              <div style={{ marginTop: 10 }}>
                <span style={{ color: 'grey' }}>Shifts</span>
                <div className={`row`}>
                  <div
                    className='col-md-6 col-sm-6'
                    style={{
                      marginTop: 10,
                    }}
                  >
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <TimePicker
                        clearable
                        label='Shift Start Time'
                        inputVariant='filled'
                        fullWidth={true}
                        ampm={false}
                        onChange={(val) =>
                          handleChangeDate(val, 'shiftStartTime')
                        }
                        // InputProps={{
                        //   className: classes.input,
                        //   classes: { input: classes.input },
                        // }}
                        style={{ borderRadius: '10px' }}
                        value={shiftStartTime ? shiftStartTime : null}
                      />
                    </MuiPickersUtilsProvider>
                  </div>

                  <div
                    className='col-md-6 col-sm-6'
                    style={{
                      marginTop: 10,
                    }}
                  >
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <TimePicker
                        clearable
                        inputVariant='filled'
                        fullWidth={true}
                        label='End Time'
                        ampm={false}
                        onChange={(val) =>
                          handleChangeDate(val, 'shiftEndTime')
                        }
                        // InputProps={{
                        //   className: classes.input,
                        //   classes: { input: classes.input },
                        // }}
                        style={{ borderRadius: '10px' }}
                        value={shiftEndTime ? shiftEndTime : null}
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                </div>
              </div>

              {/* <div className='row'>
                <div className='col-md-12' style={styles.textFieldMargin}>
                  <span style={{ color: 'gray' }}>Consultant Type</span>
                </div>
              </div>

              <div className='row'>
                <div className='col-md-12'>
                  <span style={{ padding: '20px' }}>
                    <Chip
                      label='Internal'
                      onClick={(e) =>
                        handleChipClick('consultantType', 'Internal')
                      }
                      icon={
                        consultantType === 'Internal' ? (
                          <DoneIcon style={{ color: 'white' }} />
                        ) : (
                          ''
                        )
                      }
                      style={
                        consultantType === 'Internal'
                          ? styles.selectedChip
                          : styles.unselectedChip
                      }
                    />
                  </span>
                  <span style={{ padding: '10px' }}>
                    <Chip
                      label='External'
                      onClick={(e) =>
                        handleChipClick('consultantType', 'External')
                      }
                      icon={
                        consultantType === 'External' ? (
                          <DoneIcon style={{ color: 'white' }} />
                        ) : (
                          ''
                        )
                      }
                      style={
                        consultantType === 'External'
                          ? styles.selectedChip
                          : styles.unselectedChip
                      }
                    />
                  </span>
                </div>
              </div>

              <div className='row'>
                <div className='col-md-12' style={styles.textFieldMargin}>
                  <span style={{ color: 'gray' }}>Availability</span>
                </div>
              </div>

              <div className='row'>
                <div className='col-md-12'>
                  <span style={{ padding: '20px' }}>
                    <Chip
                      label='Yes'
                      onClick={(e) => handleChipClick('availability', 'yes')}
                      icon={
                        availability === 'yes' ? (
                          <DoneIcon style={{ color: 'white' }} />
                        ) : (
                          ''
                        )
                      }
                      style={
                        availability === 'yes'
                          ? styles.selectedChip
                          : styles.unselectedChip
                      }
                    />
                  </span>
                  <span style={{ padding: '10px' }}>
                    <Chip
                      label='No'
                      onClick={(e) => handleChipClick('availability', 'no')}
                      icon={
                        availability === 'no' ? (
                          <DoneIcon style={{ color: 'white' }} />
                        ) : (
                          ''
                        )
                      }
                      style={
                        availability === 'no'
                          ? styles.selectedChip
                          : styles.unselectedChip
                      }
                    />
                  </span>
                </div>
              </div>

              <div className='row'>
                <div className='col-md-12' style={styles.textFieldMargin}>
                  <span style={{ color: 'gray' }}>Shifts</span>
                </div>
              </div>

              <div className='row'>
                <div className='col-md-12'>
                  <span style={{ padding: '20px' }}>
                    <Chip
                      label='Morning'
                      onClick={(e) => handleChipClick('shifts', 'Morning')}
                      icon={
                        shifts === 'Morning' ? (
                          <DoneIcon style={{ color: 'white' }} />
                        ) : (
                          ''
                        )
                      }
                      style={
                        shifts === 'Morning'
                          ? styles.selectedChip
                          : styles.unselectedChip
                      }
                    />
                  </span>
                  <span style={{ padding: '10px' }}>
                    <Chip
                      label='Evening'
                      onClick={(e) => handleChipClick('shifts', 'Evening')}
                      icon={
                        shifts === 'Evening' ? (
                          <DoneIcon style={{ color: 'white' }} />
                        ) : (
                          ''
                        )
                      }
                      style={
                        shifts === 'Evening'
                          ? styles.selectedChip
                          : styles.unselectedChip
                      }
                    />
                  </span>
                </div>
              </div> */}

              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div
                  style={{
                    display: 'flex',
                    marginTop: matches ? '2%' : '8%',
                    marginBottom: '2%',
                  }}
                >
                  <Button
                    style={{
                      ...styles.stylesForCancelButton,
                      width: matches ? 140 : 100,
                      height: matches ? 50 : 40,
                      fontSize: matches ? ' ' : 9,
                    }}
                    onClick={() => onModalHide()}
                    variant='contained'
                  >
                    Cancel
                  </Button>
                  &nbsp;&nbsp;
                  <Button
                    style={{
                      ...styles.stylesForButton,
                      width: matches ? 140 : 100,
                      height: matches ? 50 : 40,
                      fontSize: matches ? ' ' : 9,
                    }}
                    onClick={handleFilterApply}
                    variant='contained'
                  >
                    Apply Filter
                  </Button>
                </div>
              </div>
            </div>
          </DialogContent>
        </Dialog>

        <Notification
          msg={errorMsg}
          success={successMsg}
          open={openNotification}
        />
      </div>
    </div>
  )
}
