import React from "react"
import CareStream from "../../../assets/img/carestream/CareStream.png"
import ContainerCS from "../../../components/CareStream/ContainerCS"
import { investigationStyles } from "../styles"

export default function Investigations(props) {
  const classes = investigationStyles()

  const getInvestigationData = (data) => {
    props.getInvestigation(data)
  }

  return (
    <div className={classes.root}>
      <div className="subheader" style={{ marginLeft: "-10px" }}>
        <img src={CareStream} className={classes.image} />
        <div style={{ flex: 4, display: "flex", alignItems: "center" }}>
          <h4 style={{ color: "white", fontWeight: "700" }}>Investigations</h4>
        </div>
      </div>

      <ContainerCS
        heading="Investigation"
        buttonHeading="Investigation"
        getData={getInvestigationData}
      />
    </div>
  )
}
