import React, { useEffect, useState, useReducer } from "react";
import { withStyles } from '@material-ui/core/styles';
import Header from "../../components/Header/Header";
import classNames from "classnames";
import { 
  Button, 
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment,
  MenuItem,
  TextField,
  Select,
  useMediaQuery,
} from "@material-ui/core";
import cookie from "react-cookies";

import axios from "axios";
import {
  getLabServiceCategory,
  addLabRequestBulk,
  getSingleEdr,
  updateNurseLabSampleRequest,
  updatePorterStatusRequest,
} from "../../public/endpoins";

import useStylesForInput from "../../assets/jss/material-dashboard-react/inputStyle";
import styles from "./styles";
import Loader from "react-loader-spinner";
import Icon from '@material-ui/core/Icon';
import CloseIcon from '@material-ui/icons/Close';
import MicIcon from "@material-ui/icons/Mic";
import StopIcon from "@material-ui/icons/Stop";
import MicRecorder from "mic-recorder-to-mp3";
import AccountCircle from "@material-ui/icons/SearchOutlined";
import Notification from "../../components/Snackbar/Notification.js";

import LabIcon from "../../assets/img/Lab.png";
import { displayInHumanDifference, displayInHumanClassic, replacePatientIdIfNull } from "../../utils/commonFunc";
import PatientDetailsQuick from "../../components/PatientDetails/PatientDetailsQuick";
import PatientDetails from "../../components/PatientDetails/PatientDetails";
import PatientDetails2 from "../../components/PatientDetails/PatientDetailsDirect";
import Back from '../../assets/img/Back_Arrow.png'

export default function NurseTechnicianLabDetails(props) {
  
  const matches = useMediaQuery("(min-width:600px)");
  const classes = useStylesForInput();

  const mystyle = {
    color: "white",
    backgroundColor: "DodgerBlue",
    padding: "10px",
    fontFamily: "Arial"
  };

  


  const initialState = {
    bulkTestSelected: false,
    resetedOn: "",
    defaultItems: {},
    seletedLabItems: [],
    labPriority: "",
    labTechnician: "",
    labComments: "",
    labTechniciansList: [],
  };
  function reducer(state, { field, value }) {
    return {
      ...state,
      [field]: value,
    };
  }
  const [state, dispatch] = useReducer(reducer, initialState);
  const {
    bulkTestSelected,
    resetedOn,
    defaultItems,
    seletedLabItems,
    labPriority,
    labTechnician,
    labComments,
    labTechniciansList,
  } = state;

  const handleCheckupChange = (event) => {
    dispatch({field: 'bulkTestSelected', value: event.target.value});
  };

  const uniqueCodeField = (name) => {
    name = name.toLowerCase();
    name = name.replace(/[^a-z0-9]+/g, '-');
    name = name.replace(/^-+|-+$/g, '');
    return name;
  }

  const [currentUser] = useState(cookie.load("current_user"));
  const [timer, setTimer] = useState(null);
  const [selectedLab, setSelectedLab] = useState("");
  const [categoriesData, setCategoriesData] = useState([]);
  const [mItems, setMItems] = useState([]);

  const [patientDetails, setPatientDetails] = useState("");
  const [selectedRecord, setSelectedRecord] = useState({});

  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setsuccessMsg] = useState("");
  const [openNotification, setOpenNotification] = useState(false);

  const [ episodeId, setEpisodeId ] = useState(props.match.params.id);
  const [ labRequestId, setLabRequestId ] = useState(props.match.params.requestId);
  const [ labRequest, setLabRequest ] = useState({});
  const [ labServices, setLabServices ] = useState([]);
  const [ hasPending, setHasPending] = useState(false);

  
  if (openNotification) {
    setTimeout(() => {
      setOpenNotification(false);
      setErrorMsg("");
      setsuccessMsg("");
    }, 9000);
  }

  useEffect(() => {
    axios
      .get(getSingleEdr + "/" + episodeId)
      .then((res) => {
        console.log('handleView::', res.data.data);
        if (res.data.success) {

          let remoteRecord = replacePatientIdIfNull(res.data.data);
          setSelectedRecord(remoteRecord);

          const object = {
            '_id': remoteRecord._id,
            patientName: remoteRecord.patientId.name[0].given[0] + " " + remoteRecord.patientId.name[0].family,
            episodeNumber: remoteRecord.requestNo,//remoteRecord.patientId.identifier[0].value,
            mrn: remoteRecord.patientId.identifier[0].value,
            age: remoteRecord.patientId.age,
            gender: remoteRecord.patientId.gender,
            weight: remoteRecord.patientId.weight,
            department: remoteRecord.currentLocation,
            chiefComplaint: remoteRecord.chiefComplaint.length > 0 ? remoteRecord.chiefComplaint.pop().chiefComplaintId.short_desc : "N/A",
            chiefComplaintOthers: remoteRecord.chiefComplaintOthers,
          };
          setPatientDetails(object);

          let labServicesX = remoteRecord.labRequest.filter(obj => obj.requestId === labRequestId);
          console.log('labServicesX', labServicesX, labServicesX.length);
          if (labServicesX.length > 0){
            let labXN = labServicesX[0];
            labXN.requestedName = labXN.requestedBy.name[0].given[0] + " " + labXN.requestedBy.name[0].family;
            console.log('labServicesX', labXN.requestedBy.name);
            console.log('labServicesX', labXN);
            setLabRequest(labXN)
            // setLabRequest(labServicesX[0])
            setLabServices(labServicesX[0].services);
          }

        }
      })
      .catch((e) => {
        console.log("error after searching patient request", e);
      });
    /*getLabTechniciansCall( currentUser._id, dispatch, setOpenNotification, setErrorMsg );*/
  }, []);

  const handleView = (rec) => {
    console.log('rec::rec::', rec);
    if (rec.nurseTechnicianStatus === "Collected"){
      let url = '/dashboard/home/porter/details/'+episodeId+'/'+labRequestId+'/view/'+rec.requestId+'';
      props.history.push({
        pathname: url,
      });
    }else{
      // window.alert(`The task for ${rec.category}: ${rec.name} is not completed by Nurse Technician yet.`)
      setErrorMsg(`The Sample for ${rec.category}: ${rec.name} is not available!`)
      setOpenNotification(true);
    }
  }

  const handleSaveArrived = () => {
    setOpenNotification(true);
    setsuccessMsg(`The Lab Request has been marked as Arrived!`);
  }

  const handleCompleteAll = () => {
    axios
        .put(updatePorterStatusRequest, {
          edrId: episodeId,
          requestId: labRequestId,
          staffId: currentUser._id,
          requestStatus: 'Collected',
          completeType: 'all'
        })
        .then((res) => {
          if (res.data.success) {
            setOpenNotification(true);
            setsuccessMsg(`The Lab Request has been marked as Completed!`);
            window.location.reload();
          }
        })
        .catch((e) => {
          setOpenNotification(true);
          setErrorMsg("Unable to Update the Record");
        });
  }

  return (
    
    <div
      style={{
        backgroundColor: "rgb(19 213 159)",
        position: "fixed",
        display: "flex",
        width: "100%",
        height: "100%",
        flexDirection: "column",
        flex: 1,
        overflowY: "scroll",
      }}
    >
      <Header history={props.history} />

      <PatientDetailsQuick
        edrId={episodeId} />

      <div className="cPadding">

        {patientDetails && labRequest ? (
          <div className="mb-3">
            <div className="card p-3">
              <div className="row row-5">
                <div className="col-6 col-lg-3 card-detail-block block-xdiv mb-2"><strong className="lblX">Episode #</strong><span className="valX">{patientDetails.episodeNumber}</span></div>
                <div className="col-6 col-lg-3 card-detail-block block-xdiv mb-2"><strong className="lblX">MRN #</strong><span className="valX">{patientDetails.mrn}</span></div>
                <div className="col-6 col-lg-3 card-detail-block block-xdiv mb-2"><strong className="lblX">Requester</strong><span className="valX">{labRequest.requestedName}</span></div>
                <div className="col-6 col-lg-3 card-detail-block block-xdiv mb-2"><strong className="lblX">Date and Time</strong><span className="valX">{displayInHumanClassic(labRequest.requestedAt)}</span></div>
              </div>
            </div>
          </div>
        ) : (
          undefined
        )}

        <h4 className="h6 text-white mb-3"
          style={{
            fontWeight: '700'
          }}>{labRequestId} Service Details</h4>

        {/*
        <div className="row row-5">
          {
            labServices ? 
            Object.entries(labServices).map((obj, entry) => {
              return (
                <div className="col-12 col-md-6 col-lg-6 col-xl-6">
                  <div className="card card-detail-info mb-2">
                    <div className="row row-5">
                      <div className="col-sm-12 card-detail-block block-xdiv mb-2">&nbsp;</div>
                      <div className="col-sm-12 card-detail-block block-xdiv"><strong className="lblX">Profile/Test</strong><span className="valX">{obj[1].category}: <span>{obj[1].name}</span></span></div>
                    </div>
                    <div className="card-status-action">
                      {obj[1].priority==="High"?<span className="badge badge-danger">High</span>:undefined}
                      <span className={classNames(`badge`, (obj[1].porterStatus=="Delivered"?'badge-success':'status-pending'))}>{obj[1].porterStatus}</span>
                      { 
                        obj[1].urgent==="Yes"?<span className="ml-info">!</span>:undefined
                      }
                      <i className="ml-view ml-10 zmdi zmdi-eye zmdi-hc-2x" onClick={() => {
                        handleView(obj[1]);
                      }} />
                    </div>
                  </div>
                </div>
              );
            }):undefined
          }
        </div>
        */}

        <div className="row row-5">
          {
            labServices ? 
            <>
              {/*
              <div className="col mt-3 mb-4 text-center">
                <Button
                  style={{
                    backgroundColor: "#AD6BBF",
                    color: "white",
                    cursor: "pointer",
                    borderRadius: 5,
                    backgroundColor: "rgb(173, 107, 191)",
                    height: matches ? 56 : 45,
                    outline: "none",
                    width: "100%",
                  }}
                  // disabled={!addLabRequest}
                  variant="contained"
                  color="primary"
                  onClick={handleSaveArrived}
                  fullWidth>Arrived</Button>
              </div>
              */}
              <div className="col-md-6 offset-md-3 mt-3 mb-4 text-center">
                {
                  labServices.length>0?
                  <Button
                    // className="addButton"
                    style={{
                      backgroundColor: "#AD6BBF",
                      color: "white",
                      cursor: "pointer",
                      borderRadius: 5,
                      // backgroundColor: "rgb(173, 107, 191)",
                      height: matches ? 56 : 45,
                      outline: "none",
                      width: "100%",
                    }}
                    onClick={handleCompleteAll}
                    variant="contained"
                    color="primary"
                    disabled={labServices.filter(obj => (obj.porterStatus === 'Pending')).length>0?false:true}
                    fullWidth
                  >{labServices.filter(obj => (obj.porterStatus === 'Pending')).length>0?"Pending Delivery":"Delivered"}</Button>:undefined
                }
              </div>
            </>:undefined
          }
        </div>
        <div className='row mt-4 mb-4'>
          <div className='col-md-12 col-sm-12 col-12'>
            <img
              onClick={() => {
                let url = '/dashboard/home/porter';
                props.history.push({
                  pathname: url,
                });
              }}
              src={Back}
              style={{
                width: 45,
                height: 35,
                cursor: 'pointer',
              }}
            />
          </div>
        </div>
      </div>
      <Notification
        msg={errorMsg}
        open={openNotification}
        success={successMsg}
      />
    </div>
  );
}