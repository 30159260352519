import React, { useState, useEffect } from "react";
import Notification from "../../components/Snackbar/Notification.js";
import CustomTable from "../../components/Table/Table";
import ButtonField from "../../components/common/Button";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import { assignPatientStyles } from "../Consultations/styles";
import Button from "@material-ui/core/Button";
import Header from "../../components/Header/Header";
//icons and images
import LabFlagIcon from "../../assets/img/LabTechFlags.png";
import Back_Arrow from "../../assets/img/Back_Arrow.png";
import eye_icon from "../../assets/img/Eye.png";

import AccountCircle from "@material-ui/icons/SearchOutlined";
import InputAdornment from "@material-ui/core/InputAdornment";
// import '../../assets/jss/material-dashboard-react/components/loaderStyle.css'
import TextField from "@material-ui/core/TextField";
import cookie from "react-cookies";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import _ from "lodash";

const styles = {
  textFieldPadding: {
    paddingLeft: 5,
    paddingRight: 5,
  },
  stylesForButton: {
    color: "white",
    cursor: "pointer",
    borderRadius: 5,
    backgroundColor: "#2c6ddd",
    width: "210px",
    height: "45px",
  },
};

const useStylesForInput = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(0),
  },
  input: {
    backgroundColor: "white",
    boxShadow: "none",
    borderRadius: 5,
    "&:after": {
      borderBottomColor: "black",
      boxShadow: "none",
    },
    "&:hover": {
      backgroundColor: "white",
      boxShadow: "none",
    },
    "&:focus": {
      backgroundColor: "white",
      boxShadow: "none",
      borderRadius: 5,
    },
  },
  multilineColor: {
    boxShadow: "none",
    backgroundColor: "white",
    borderRadius: 5,
    "&:hover": {
      backgroundColor: "white",
      boxShadow: "none",
    },
    "&:after": {
      borderBottomColor: "black",
      boxShadow: "none",
    },
    "&:focus": {
      boxShadow: "none",
    },
  },
  root: {
    "& .MuiTextField-root": {
      backgroundColor: "white",
    },
    "& .Mui-focused": {
      backgroundColor: "white",
      color: "black",
      boxShadow: "none",
    },
    "& .Mui-disabled": {
      backgroundColor: "white",
      color: "gray",
    },
    "&:focus": {
      backgroundColor: "white",
      boxShadow: "none",
    },
    "& .MuiFormLabel-root": {
      fontSize: "12px",

      paddingRight: "15px",
    },
  },
  label: {
    "&$focusedLabel": {
      color: "red",
      display: "none",
    },
    // "&$erroredLabel": {
    //   color: "orange"
    // }
  },
  focusedLabel: {},
}));

const PatientList = [
  {
    id: "a",
    request: "b",
    name: "d",
    date: "no",
    status: "pending",
  },
];

const tableHeading = [
  "MRN Number",
  "Request ID",
  "Patient Name",
  "Date/Time",
  "Status",
  "Actions",
];
const tableDataKeys = ["id", "request", "name", "date", "status"];

const actions = { edit: true };

export default function PatientListing(props) {
  const classes = useStylesForInput();
  const classes1 = assignPatientStyles();
  const matches = useMediaQuery("(min-width:600px)");

  const [pharmOPR, setPharmOPR] = useState("");
  const [radOPR, setRadOPR] = useState("");
  const [labOPR, setLabOPR] = useState("");
  // const [patient, setPatient] = useState('')
  const [itemModalVisible, setitemModalVisible] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [openNotification, setOpenNotification] = useState(false);
  const [item, setItem] = useState("");
  const [searchPatientQuery, setSearchPatientQuery] = useState("");
  const [PatientUrl, setPatientUrl] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("pending");

  useEffect(() => {}, []);

  if (openNotification) {
    setTimeout(() => {
      setOpenNotification(false);
      setErrorMsg("");
    }, 2000);
  }

  function handleEdit(rec) {
    let path = `/dashboard/labtechnician/patientdetail`;
    props.history.push({
      pathname: path,
      state: {
        comingFor: "edit",
        selectedItem: rec,
      },
    });
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        position: "fixed",
        width: "100%",
        height: "100%",
        backgroundColor: "rgb(19 213 159)",
        overflowY: "scroll",
      }}
    >
      <Header history={props.history} />
      <div className="cPadding">
        <div className="subheader" style={{ marginLeft: "-10px" }}>
          <div>
            <img src={LabFlagIcon} />

            <h4>Flag Management</h4>
          </div>
          {selectedStatus === "pending" ? (
            <Button
              className={classes1.stylesForButtonPatient}
              variant="contained"
              color="primary"
              // onClick={() => callCompleted("completed")}
            >
              <img src={eye_icon} className="icon-view" />
              &nbsp;&nbsp;
              <strong style={{ fontSize: matches ? "" : 9 }}>
                Completed Requests
              </strong>
            </Button>
          ) : (
            <Button
              className={classes1.stylesForButtonPatient}
              variant="contained"
              color="primary"
              // onClick={() => callCompleted("pending")}
            >
              <img src={eye_icon} className="icon-view" />
              &nbsp;&nbsp;
              <strong style={{ fontSize: matches ? "" : 9 }}>
                Pending Requests
              </strong>
            </Button>
          )}
        </div>

        <div
          style={{
            flex: 4,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div>
            {PatientList !== " " ? (
              <div>
                <div>
                  <CustomTable
                    tableData={PatientList}
                    tableDataKeys={tableDataKeys}
                    tableHeading={tableHeading}
                    action={actions}
                    borderBottomColor={"#60d69f"}
                    handleEdit={handleEdit}
                    borderBottomWidth={20}
                  />
                </div>

                <Notification msg={errorMsg} open={openNotification} />

                <div style={{ marginBottom: 20, marginTop: 50 }}>
                  <img
                    onClick={() => props.history.goBack()}
                    src={Back_Arrow}
                    style={{ width: 45, height: 35, cursor: "pointer" }}
                  />
                </div>
              </div>
            ) : (
              // <div className='LoaderStyle'>
              //   <Loader type='TailSpin' color='red' height={50} width={50} />
              // </div>
              <div className="row " style={{ marginTop: "25px" }}>
                <div className="col-11">
                  <h3
                    style={{
                      color: "white",
                      textAlign: "center",
                      width: "100%",
                      position: "absolute",
                    }}
                  >
                    Opps...No Data Found
                  </h3>
                </div>
                <div className="col-1" style={{ marginTop: 45 }}>
                  {/* <img
                    onClick={() => props.history.goBack()}
                    src={Back_Arrow}
                    style={{
                      maxWidth: '60%',
                      height: 'auto',
                      cursor: 'pointer',
                    }}
                  /> */}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
