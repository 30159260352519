import React, { useEffect, useState } from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import Flip from "../../../components/Dial/Flip";
import TwoValue from "../../../components/Dial/TwoValue";
import OneValue from "../../../components/Dial/OneValue";
import FlipForProgress from "../../../components/Dial/FlipForProgress";
import SliderComponent from "../../../components/BedsAvailableSlider/bedsAvailableSlider";
import axios from "axios";
import { roSenseiPending, roTotalPending } from "../../../public/endpoins";
import { getRODashboardCalls, getROCall } from "./NetworkCallForRegistration";
import LineChart from "../../../components/Dial/LineChart";
import { colorForDashboard } from "../../../utils/Constants";
import Notification from "../../../components/Snackbar/Notification.js";
import ExclamatorySignIcon from "../../../assets/img/ExclamatoryForFlag.png";
import Loader from "react-loader-spinner";
import { CardObj } from "./configForRegistration";
import {
  socketUrl
} from '../../../public/endpoins'
import socketIOClient from 'socket.io-client'

// import axios from 'axios'
// import { registrationOfficer } from '../../public/endpoins'

export default function CommitteeMemberDashboard() {
  const theme = useTheme();

  const matches = useMediaQuery("(min-width:600px)");
  const matchesForTablet = useMediaQuery(theme.breakpoints.up("md"));
  const minimumWidthForTab = useMediaQuery("(min-width:601px)");
  const maximumWidthForTab = useMediaQuery("(max-width:768px)");
  const [isLoading, setisLoading] = useState(true);

  const [errorMsg, setErrorMsg] = useState("");
  const [openNotification, setOpenNotification] = useState(false);
  const [successMsg, setsuccessMsg] = useState("");
  const [responseData, setResponseData] = useState("");
  const [totalInsured, setTotalInsured] = useState("");
  const [totalUnInsured, setTotalUnInsured] = useState("");
  const [cumulativeRegistrations, setCumulativeRegistrations] = useState("");
  const [availableEdBeds, setAvailableEdBeds] = useState(0);
  const [availableIpBeds, setAvailableIpBeds] = useState(0);
  const [averageTAT, setAverageTAT] = useState("");
  const [registeredPatients, setRegisteredPatients] = useState("");

  // Patient Registration Pending
  const [TATPending, setTATPending] = useState("");
  const [totalPending, setTotalPending] = useState("");
  const [TATPendingColor, setTATPendingColor] = useState("");
  const [totalPendingColor, setTotalPendingColor] = useState("");
  const [pendingGraphData, setPendingGraphData] = useState("");

  // Patient Registration Pending (After Sensei)
  const [averageTATSensei, setAverageTATSensei] = useState("");
  const [totalSenseiPending, setTotalSenseiPending] = useState("");
  const [averageTATSenseiColor, setAverageTATSenseiColor] = useState("");
  const [totalSenseiPendingColor, setTotalSenseiPendingColor] = useState("");
  const [senseiPendingGraphData, setSenseiPendingGraphData] = useState("");

  const [enableEDBedsProgress, setEnableEDBedsProgress] = useState(true);
  const [enableIPBedsProgress, setEnableIPBedsProgress] = useState(true);
  const [bedLineChartWidth, setBedLineChartWidth] = useState(
    window.innerWidth <= 600
      ? 300
      : (window.innerWidth >= 601) & (window.innerWidth <= 768)
      ? 220
      : 420
  );

  // Patient Discharges Pending
  const [dischargesPending, setDischargesPending] = useState("");
  const [dichargeTAT, setDichargeTAT] = useState("");
  const [dichargeTATColor, setDichargeTATColor] = useState("");
  const [dischargesPendingColor, setDischargesPendingColor] = useState("");
  const [dischargeGraphData, setDischargeGraphData] = useState("");

  // colors
  const [totalInsuredColor, setTotalInsuredColor] = useState("");
  const [totalUnInsuredColor, setTotalUnInsuredColor] = useState("");
  const [
    cumulativeRegistrationsColor,
    setCumulativeRegistrationsColor,
  ] = useState("");
  // const [availableEdBedsColor, setAvailableEdBedsColor] = useState('')
  const [averageTATColor, setAverageTATColor] = useState("");
  const [registeredPatientsColor, setRegisteredPatientsColor] = useState("");

  const [flip, setFlip] = useState(false);
  const [dummyData, setDummyData] = useState("");

  const [flag1Count, setFlag1Count] = useState(0)
  const [flag2Count, setFlag2Count] = useState(0)
  const [bedsFlags, setBedsFlags] = useState(0)
  const [flag5Count, setFlag5Count] = useState(0)

  useEffect(() => {
    getROCall(
      setResponseData,
      setTotalInsuredColor,
      setTotalUnInsuredColor,
      setCumulativeRegistrationsColor,
      setAverageTATColor,
      setRegisteredPatientsColor,
      setTotalInsured,
      setTotalUnInsured,
      setOpenNotification,
      setErrorMsg,
      setCumulativeRegistrations,
      setAvailableEdBeds,
      setAverageTAT,
      setRegisteredPatients,
      setDischargesPending,
      setDichargeTAT,
      setDichargeTATColor,
      setDischargesPendingColor,
      setDischargeGraphData,
      setisLoading
    );

    getRODashboardCalls(
      colorForDashboard,
      roSenseiPending,
      "pendingRegistrationSensei",
      "totalSenseiPending",
      setAverageTATSensei,
      setAverageTATSenseiColor,
      setTotalSenseiPending,
      setTotalSenseiPendingColor,
      setOpenNotification,
      setErrorMsg,
      setPendingGraphData,
      "senseiPerHour",
      setisLoading
    );

    getRODashboardCalls(
      colorForDashboard,
      roTotalPending,
      "pendingRegistration",
      "totalPending",
      setTATPending,
      setTATPendingColor,
      setTotalPending,
      setTotalPendingColor,
      setOpenNotification,
      setErrorMsg,
      setSenseiPendingGraphData,
      "totalPerHour",
      setisLoading
    );

    for (let i = 0; i < CardObj.length; i++) {
      console.log("Card Obj", CardObj[i].key);
    }

    // const socket = socketIOClient(socketUrl)
    //   socket.emit('get_count', loggedUser._id)
    //   socket.on('count', (data) => {
    //     console.log('response coming through socket for count', data)
    //     this.setState({ data: data })
    //   })

    const flagCount = [{value: '1st', setFlagCount: (value) => setFlag1Count(value)},
    {value: '2nd', setFlagCount: (value) => setFlag2Count(value)},
    {value: '5th', setFlagCount: (value) => setFlag5Count(value)},
    {value: 'beds', setFlagCount: (value) => setFlag5Count(value)}];

    const socket = socketIOClient(socketUrl)
      socket.emit('ro_flags')
      socket.on('pendingRO', (data) => {
        console.log('response coming through socket for RO Flags', data)
        flagCount.forEach(({value, setFlagCount}) => {
          const cardCount = data.filter(item => item.card === value).length;
          cardCount && setFlagCount(cardCount);
        })

        console.log('data', data)
        
      })
  }, []);

  // console.log('*******', flag5Count)

  useEffect(() => {
    const dummyData = [
      { value: 10, label: new Date("2021-02-16T07:48:26.948Z") },
      { value: 100, label: new Date("2021-02-16T12:48:26.948Z") },
      { value: 80, label: new Date("2021-02-16T11:48:26.948Z") },
      { value: 70, label: new Date("2021-02-16T10:48:26.948Z") },
      { value: 60, label: new Date("2021-02-16T09:48:26.948Z") },
      { value: 50, label: new Date("2021-02-16T08:48:26.948Z") },
    ];

    setDummyData(dummyData);
  }, []);

  if (openNotification) {
    setTimeout(() => {
      setOpenNotification(false);
      setErrorMsg("");
      setsuccessMsg("");
    }, 2000);
  }

  const flipIt = () => {
    setFlip(true);
  };
  return (
    <div className="container-fluid" style={{ marginBottom: 10 }}>
      <div className="row">
        <div
          className="col-md-6 col-12"
          style={{ paddingLeft: 4, paddingRight: 4, marginTop: 10 }}
        >
          {/* {!matches ? (
            <FlipForProgress
              mobile={matches ? false : true}
              flipped={!flip}
              flipHandler={flipIt}
              idForGraph={'container1'}
              data={dummyData}
              progressValue={availableEdBeds}
              progressName={'ED Beds Available'}
            />
          ) : ( */}
          <div
            style={{
              width: "100%",
              backgroundColor: "white",
              height: 180,
              borderRadius: 5,
              padding: 10,
            }}
            onClick={() => setEnableEDBedsProgress(!enableEDBedsProgress)}
          >
            <div>
              <span style={{ fontWeight: "bold", fontSize: 18 }}>
                ED Beds Available
              </span>
            </div>
            <div>
              <span style={{ fontWeight: "bold", fontSize: 45 }}>
                {isLoading ? (
                  <Loader
                    type="TailSpin"
                    color="green"
                    height={35}
                    width={35}
                  />
                ) : (
                  availableEdBeds
                )}
              </span>
            </div>
            {/* {enableEDBedsProgress === true ? ( */}
            <div style={{ cursor: "pointer" }}>
              <SliderComponent
                scale={14}
                top={120}
                bottom={0}
                left={
                  matches && matchesForTablet
                    ? 140
                    : matches && !matchesForTablet
                    ? 45
                    : 25
                }
                right={0}
                onSlide={setAvailableEdBeds}
                readOnly={true}
                progressValue={
                  isLoading ? (
                    <Loader
                      type="TailSpin"
                      color="green"
                      height={35}
                      width={35}
                    />
                  ) : (
                    availableEdBeds
                  )
                }
              />
              {/* <span style={{ fontWeight: "bolder", opacity: 0.7 }}>
              {bedsFlags ? (
                <
                  // style={{
                  //   paddingLeft: 10,
                  //   display: "inline-flex",
                  //   fontWeight: "bolder",
                  // }}
                >
                  <img
                    src={ExclamatorySignIcon}
                    // onClick={viewFlags}
                    style={{ marginLeft: 10, marginRight: 7, marginTop: -2, width: 20, height: 20, cursor: 'pointer' }}
                  />
                  {bedsFlags}
                </>
              ) : (
                undefined
              )}
            </span> */}
            </div>
            {/* ) : (
              dummyData && (
                <div
                  style={{
                    marginTop: '-90px',
                    marginLeft: '40px',
                    cursor: 'pointer',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      height: '10%',
                      justifyContent: 'center',
                      alignItems: 'flex-end',
                      marginTop: 20,
                    }}
                  >
                    <span
                      style={{
                        fontSize: 13,
                        fontWeight: '700',
                        textAlign: 'center',
                        opacity: '70%',
                      }}
                    >
                      Last 06 Hours
                    </span>
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      height: '10%',
                      justifyContent: 'center',
                      alignItems: 'flex-end',
                      marginTop: 20,
                    }}
                  >
                    <LineChart
                      data={dummyData}
                      width={
                        minimumWidthForTab && maximumWidthForTab ? 280 : 420
                      }
                      height={100}
                      scaleWidth={
                        minimumWidthForTab && maximumWidthForTab ? 280 : 420
                      }
                      scaleHeight={100}
                      idForGraph='senseiEdBedsAvailableId'
                    />
                  </div>
                </div>
              )
            )} */}
          </div>
          {/* )} */}
        </div>

        <div
          className="col-md-6 col-12"
          style={{ paddingLeft: 4, paddingRight: 4, marginTop: 10 }}
        >
          {/* {!matches ? (
            <FlipForProgress
              mobile={matches ? false : true}
              flipped={!flip}
              flipHandler={flipIt}
              idForGraph={'container2'}
              data={dummyData}
              progressValue={availableIpBeds}
              progressName={'IP Beds Available'}
            />
          ) : ( */}
          <div
            style={{
              width: "100%",
              backgroundColor: "white",
              height: 180,
              borderRadius: 5,
              padding: 10,
            }}
            onClick={() => setEnableIPBedsProgress(!enableIPBedsProgress)}
          >
            <div>
              <span style={{ fontWeight: "bold", fontSize: 18 }}>
                IP Beds Available
              </span>
            </div>
            <div>
              <span style={{ fontWeight: "bold", fontSize: 45 }}>
                {isLoading ? (
                  <Loader
                    type="TailSpin"
                    color="green"
                    height={35}
                    width={35}
                  />
                ) : (
                  availableEdBeds
                )}
              </span>
            </div>
            {/* {enableIPBedsProgress === true ? ( */}
            <div>
              <SliderComponent
                top={120}
                bottom={0}
                left={
                  matches && matchesForTablet
                    ? 140
                    : matches && !matchesForTablet
                    ? 45
                    : 25
                }
                right={0}
                onSlide={setAvailableIpBeds}
                readOnly={true}
                progressValue={
                  isLoading ? (
                    <Loader
                      type="TailSpin"
                      color="green"
                      height={35}
                      width={35}
                    />
                  ) : (
                    availableEdBeds
                  )
                }
              />
            </div>
            {/* ) : (
                dummyData && (
                  <div style={{ marginTop: '-90px', marginLeft: '40px' }}>
                    <div
                      style={{
                        display: 'flex',
                        height: '10%',
                        justifyContent: 'center',
                        alignItems: 'flex-end',
                        marginTop: 20,
                      }}
                    >
                      <span
                        style={{
                          fontSize: 13,
                          fontWeight: '700',
                          textAlign: 'center',
                          opacity: '70%',
                        }}
                      >
                        Last 06 Hours
                      </span>
                    </div>

                    <div
                      style={{
                        display: 'flex',
                        height: '10%',
                        justifyContent: 'center',
                        alignItems: 'flex-end',
                        marginTop: 20,
                      }}
                    >
                      <LineChart
                        data={dummyData}
                        width={
                          minimumWidthForTab && maximumWidthForTab ? 280 : 420
                        }
                        height={100}
                        scaleWidth={
                          minimumWidthForTab && maximumWidthForTab ? 280 : 420
                        }
                        scaleHeight={100}
                        idForGraph='IPBedsAvailableId'
                      />
                    </div>
                  </div>
                )
              )} */}
          </div>
          {/* )} */}
        </div>
      </div>
      <div className="row">
        <div
          className="col-md-4 col-6"
          style={{ paddingLeft: 4, paddingRight: 4, marginTop: 10 }}
        >
          <div>
            <Flip
              mobile={matches ? false : true}
              flipped={!flip}
              flipHandler={flipIt}
              mainHeading={"Patient Registration Pending"}
              value={
                isLoading ? (
                  <Loader
                    type="TailSpin"
                    color="green"
                    height={35}
                    width={35}
                  />
                ) : (
                  totalPending
                  // Number.parseFloat(totalPending).toPrecision(3)
                )
              }
              mainHeadingForGraph="Patient Registration Pending"
              color={totalPendingColor}
              subHeading={"TAT"}
              flagsCount={flag1Count}
              childHeading={"Arrival to Registration"}
              time={
                isLoading ? (
                  <Loader
                    type="TailSpin"
                    color="green"
                    height={35}
                    width={35}
                  />
                ) : TATPending === null ? (
                  0
                ) : (
                  TATPending
                  // Number.parseFloat(TATPending).toPrecision(3)
                )
              }
              timecolor={TATPendingColor}
              idForGraph={"container1"}
              data={senseiPendingGraphData}
            />
          </div>
        </div>

        <div
          className="col-md-4 col-6"
          style={{ paddingLeft: 4, paddingRight: 4, marginTop: 10 }}
        >
          <div>
            <Flip
              mobile={matches ? false : true}
              flipped={!flip}
              flipHandler={flipIt}
              mainHeading={"Patient Registrations Pending (After Sensei)"}
              value={
                isLoading ? (
                  <Loader
                    type="TailSpin"
                    color="green"
                    height={35}
                    width={35}
                  />
                ) : (
                  totalSenseiPending
                  // Number.parseFloat(totalSenseiPending).toPrecision(3)
                )
              }
              mainHeadingForGraph="Patient Registrations Pending (After Sensei)"
              color={totalSenseiPendingColor}
              subHeading={"TAT"}
              flagsCount={flag2Count}
              childHeading={"Sensei to Registration"}
              time={
                isLoading ? (
                  <Loader
                    type="TailSpin"
                    color="green"
                    height={35}
                    width={35}
                  />
                ) : averageTATSensei === null ? (
                  0
                ) : (
                  averageTATSensei
                  // Number.parseFloat(averageTATSensei).toPrecision(3)
                )
              }
              timecolor={averageTATSenseiColor}
              idForGraph={"container2"}
              data={pendingGraphData}
            />
          </div>
        </div>

        <div
          className="col-md-4 col-6"
          style={{ paddingLeft: 4, paddingRight: 4, marginTop: 10 }}
        >
          <div>
            <Flip
              mobile={matches ? false : true}
              flipped={!flip}
              flipHandler={flipIt}
              mainHeading={"Patient Insurance Pre Approvals Pending"}
              value={
                isLoading ? (
                  <Loader
                    type="TailSpin"
                    color="green"
                    height={35}
                    width={35}
                  />
                ) : (
                  20
                )
              }
              mainHeadingForGraph="Patient Insurance Pre Approvals Pending"
              color="#13D59F"
              subHeading={"TAT"}
              childHeading={"Registration to Pre-Approval"}
              time={
                isLoading ? (
                  <Loader
                    type="TailSpin"
                    color="green"
                    height={35}
                    width={35}
                  />
                ) : (
                  "60"
                )
              }
              timecolor="#FFBC28"
              idForGraph={"container3"}
              data={dummyData}
            />
          </div>
        </div>
        {/* </div> */}

        {/* <div className="row"> */}
        <div
          className="col-md-4 col-6"
          style={{ paddingLeft: 4, paddingRight: 4, marginTop: 10 }}
        >
          <div>
            <Flip
              mobile={matches ? false : true}
              flipped={!flip}
              flipHandler={flipIt}
              mainHeading={"DCD Verifications Pending"}
              value={
                isLoading ? (
                  <Loader
                    type="TailSpin"
                    color="green"
                    height={35}
                    width={35}
                  />
                ) : (
                  30
                )
              }
              mainHeadingForGraph="DCD Verifications Pending"
              color="#13D59F"
              subHeading={"TAT"}
              childHeading={"Assignment to DCD Verification"}
              time={
                isLoading ? (
                  <Loader
                    type="TailSpin"
                    color="green"
                    height={35}
                    width={35}
                  />
                ) : (
                  "25"
                )
              }
              idForGraph={"container4"}
              data={dummyData}
            />
          </div>
        </div>

        <div
          className="col-md-4 col-6"
          style={{ paddingLeft: 4, paddingRight: 4, marginTop: 10 }}
        >
          <div>
            <Flip
              mobile={matches ? false : true}
              flipped={!flip}
              flipHandler={flipIt}
              mainHeading={"Patient Dispositions/Discharges Pending"}
              value={
                isLoading ? (
                  <Loader
                    type="TailSpin"
                    color="green"
                    height={35}
                    width={35}
                  />
                ) : dischargesPending <= 99 ? (
                  dischargesPending
                  // Number.parseFloat(dischargesPending).toFixed(1)
                ) : (
                  dischargesPending
                  // Number.parseFloat(dischargesPending).toPrecision(3)
                )
              }
              mainHeadingForGraph="Patient Dispositions/Discharges Pending"
              color={dischargesPendingColor}
              subHeading={"TAT"}
              flagsCount={flag5Count}
              childHeading={"Decision to Disposition/Discharge"}
              time={
                isLoading ? (
                  <Loader
                    type="TailSpin"
                    color="green"
                    height={35}
                    width={35}
                  />
                ) : dichargeTAT === null ? (
                  0
                ) : (
                  dichargeTAT
                  // Number.parseFloat(dichargeTAT).toPrecision(3)
                )
              }
              timecolor={dichargeTATColor}
              idForGraph={"container5"}
              data={dischargeGraphData}
            />
          </div>
        </div>

        <div
          className="col-md-4 col-6"
          style={{ paddingLeft: 4, paddingRight: 4, marginTop: 10 }}
        >
          <div>
            <Flip
              mobile={matches ? false : true}
              flipped={!flip}
              flipHandler={flipIt}
              mainHeading={"Patient Admissions Pending"}
              value={
                isLoading ? (
                  <Loader
                    type="TailSpin"
                    color="green"
                    height={35}
                    width={35}
                  />
                ) : (
                  50
                )
              }
              mainHeadingForGraph="Patient Admissions Pending"
              color="#FFBC28"
              subHeading={"TAT"}
              childHeading={"Disposition to Admission"}
              time={
                isLoading ? (
                  <Loader
                    type="TailSpin"
                    color="green"
                    height={35}
                    width={35}
                  />
                ) : (
                  "80"
                )
              }
              timecolor="#FF0000"
              idForGraph={"container6"}
              data={dummyData}
            />
          </div>
        </div>
        {/* </div> */}

        {/* <div className="row"> */}
        <div
          className="col-md-4 col-6"
          style={{ paddingLeft: 4, paddingRight: 4, marginTop: 10 }}
        >
          <div>
            <Flip
              mobile={matches ? false : true}
              flipped={!flip}
              flipHandler={flipIt}
              mainHeading={"No. of Registrations/Hour"}
              value={
                isLoading ? (
                  <Loader
                    type="TailSpin"
                    color="green"
                    height={35}
                    width={35}
                  />
                ) : registeredPatients <= 99 ? (
                  registeredPatients
                  // Number.parseFloat(registeredPatients).toFixed(1)
                ) : (
                  registeredPatients
                  // Number.parseFloat(registeredPatients).toPrecision(3)
                )
              }
              color={registeredPatientsColor}
              mainHeadingForGraph="No. of Registrations/Hour"
              subHeading={"TAT"}
              childHeading={"Per Patient"}
              time={
                isLoading ? (
                  <Loader
                    type="TailSpin"
                    color="green"
                    height={35}
                    width={35}
                  />
                ) : averageTAT === null ? (
                  0
                ) : (
                  averageTAT
                  // Number.parseFloat(averageTAT).toPrecision(3)
                )
              }
              timecolor={averageTATColor}
              idForGraph={"container7"}
              data={responseData.registrationPerHour}
            />
          </div>
        </div>

        <div
          className="col-md-4 col-6"
          style={{ paddingLeft: 4, paddingRight: 4, marginTop: 10 }}
        >
          <div>
            <TwoValue
              heading1={"Total Insured Patients"}
              time1={
                isLoading ? (
                  <Loader
                    type="TailSpin"
                    color="green"
                    height={35}
                    width={35}
                  />
                ) : (
                  totalInsured
                )
              }
              time1Color={totalInsuredColor}
              time2Color={totalUnInsuredColor}
              heading2={"Total Uninsured Patients"}
              time2={
                isLoading ? (
                  <Loader
                    type="TailSpin"
                    color="green"
                    height={35}
                    width={35}
                  />
                ) : (
                  totalUnInsured
                )
              }
            />
          </div>
        </div>

        <div
          className="col-md-4 col-6"
          style={{ paddingLeft: 4, paddingRight: 4, marginTop: 10 }}
        >
          <div>
            <TwoValue
              heading1={"Total Insurance Patients Rejected"}
              time1={
                isLoading ? (
                  <Loader
                    type="TailSpin"
                    color="green"
                    height={35}
                    width={35}
                  />
                ) : (
                  "5"
                )
              }
              time1Color={"#FFBC28"}
              time2Color={"#13D59F"}
              heading2={"Total Insurance Patients Denied"}
              time2={
                isLoading ? (
                  <Loader
                    type="TailSpin"
                    color="green"
                    height={35}
                    width={35}
                  />
                ) : (
                  "10"
                )
              }
            />
          </div>
        </div>
        {/* </div> */}

        {/* <div className="row"> */}
        <div
          className="col-md-4 col-6"
          style={{ paddingLeft: 4, paddingRight: 4, marginTop: 10 }}
        >
          <div>
            <OneValue
              time={
                isLoading ? (
                  <Loader
                    type="TailSpin"
                    color="green"
                    height={35}
                    width={35}
                  />
                ) : (
                  cumulativeRegistrations
                )
              }
              colorTime={cumulativeRegistrationsColor}
              heading={"Cumulative number of Registrations"}
            />
          </div>
        </div>
      </div>{" "}
      <Notification
        msg={errorMsg}
        open={openNotification}
        success={successMsg}
      />
    </div>
  );
}
