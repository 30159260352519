export const tableHeadingForDoctors = [
    "Doctor ID",
    "Doctor Name",
    "Speciality",
    "Date/Time",
    "Production Area",
    "Type",
    " "
]
export const tableDataKeysForDoctors = [
    "identifier",
    "name",
    "speciality",
    "createdAt",
    "paName",
    "subType"
]

export const tableHeadingForNurses = [
    "Nurse ID",
    "Nurse Name",
    "Speciality",
    "Production Area",
    "Date/Time",
    " "
]
export const tableDataKeysForNurses = [
    "identifier",
    "name",
    "speciality",
    "paName",
    "createdAt"
]