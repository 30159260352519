/*eslint-disable*/
import React, { useState, useEffect, useReducer } from "react";
// import "./staffStyles.js";
import Button from "@material-ui/core/Button";
import Notification from "../../components/Snackbar/Notification.js";
import CustomTable from "../../components/Table/Table";
import Loader from "react-loader-spinner";
import Header from "../../components/Header/Header";
import Paramedics from "../../assets/img/Paramedics.png";
import plus_icon from "../../assets/img/Plus.png";
import Back from "../../assets/img/Back_Arrow.png";
import { makeStyles } from "@material-ui/core/styles";
import { assignPatientStyles } from "../../views/CustomerCare/styles";
import "../../assets/jss/material-dashboard-react/components/loaderStyle.css";
import getTimeFromDate from "../../utils/extractTimeFromDate";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import AccountCircle from "@material-ui/icons/SearchOutlined";
import InputAdornment from "@material-ui/core/InputAdornment";

import {
  getAllParamedics,
  getSearchParamedics,
} from "./networkCallsForParamedics";

import TextField from "@material-ui/core/TextField";
const useStyles = makeStyles((theme) => ({
  input: {
    "&:focus": {
      backgroundColor: "white",
      boxShadow: "none",
      borderRadius: 5,
    },
  },
  root: {
    "& .Mui-focused": {
      backgroundColor: "white",
      color: "black",
      boxShadow: "none",
    },
    "&:focus": {
      backgroundColor: "white",
      boxShadow: "none",
    },
    "& .MuiFormLabel-root": {
      fontSize: "10px",
      paddingRight: "45px",
    },
  },
}));

const styles = {
  stylesForButton: {
    color: "white",
    cursor: "pointer",
    // borderRadius: 5,
    background: "#2c6ddd",
    // width: "140px",
    height: "50px",
    outline: "none",
  },
};

const tableHeadingForDoctors = [
  "ID",
  "Name",
  //   "Added By",
  //   "Doctor Type",
  //   "Speciality",
  //   "Date/Time",
  // "Experience",
  "Shift Start Time",
  "Shift End Time",
  // "Availability",
];
const tableDataKeysForDoctors = [
  "id",
  "staffName",
  //   "addedByName",
  //   "subType",
  //   "specialty",
  //   "createdAt",
  // "experience",
  "shiftStartTime",
  "shiftEndTime",
  // "availability",
];

const actions = { view: false };

export default function Staff(props) {
  const classes1 = useStyles();
  const classes = assignPatientStyles();
  const matches = useMediaQuery("(min-width:600px)");
  const [staffData, setStaffData] = useState([]);
  const [generalArray, setGeneralArray] = useState([]);
  const [errorMsg, setErrorMsg] = useState("");
  const [openNotification, setOpenNotification] = useState(false);

  const [searchPatientQuery, setSearchPatientQuery] = useState("");
  const onChangeSpecialist = (e) => {
    setSpecialist(e.target.value);
    if (e.target.value) {
      const temp = generalArray.filter((val) =>
        val.specialty.find((s) => s === e.target.value)
      );
      setFilteredArray(temp);
    }
  };

  if (openNotification) {
    setTimeout(() => {
      setOpenNotification(false);
      setErrorMsg("");
    }, 2000);
  }

  useEffect(() => {
    getAllParamedics(
      setStaffData,
      setGeneralArray,
      setOpenNotification,
      setErrorMsg
    );
  }, []);

  function setValuesInData(generalArray) {
    let senseiData = generalArray.map((d) => {
      let obj = {
        ...d,
        staffName: d.name[0].given[0] + " " + d.name[0].family,
        id: d.identifier[0].value,
        addedByName:
          d.addedBy.name[0] &&
          d.addedBy.name[0].given[0] + " " + d.addedBy.name[0].family,
        shiftStartTime: d.shiftStartTime
          ? getTimeFromDate(d.shiftStartTime)
          : "N/A",
        shiftEndTime: d.shiftEndTime ? getTimeFromDate(d.shiftEndTime) : "N/A",
        subType: d.subType.toString(),
        specialty: d.specialty.toString(),
        experience:
          d.experience.length > 0
            ? d.experience[d.experience.length - 1].experience
            : "N/A",
        availability: d.availability ? "Yes" : "No",
      };
      return obj;
    });

    setStaffData([...senseiData.reverse()]);
  }

  useEffect(() => {
    setValuesInData(generalArray);
  }, [generalArray]);

  const handlePatientSearch = (e) => {
    const a = e.target.value.replace(/[^\w+\s]/gi, "");
    setSearchPatientQuery(a);
    if (a.length >= 3) {
      getSearchParamedics(a, setGeneralArray, setErrorMsg, setOpenNotification);
    } else if (a.length == 0) {
      getAllParamedics(
        setStaffData,
        setGeneralArray,
        setOpenNotification,
        setErrorMsg
      );
    }
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        position: "fixed",
        width: "100%",
        height: "100%",
        backgroundColor: "#60d69f",
        overflowY: "scroll",
      }}
    >
      <Header history={props.history} />

      <div className="cPadding">
        <div className="subheader">
          <div
            style={{
              display: "flex",
              flex: 1,
            }}
          >
            <img src={Paramedics} />
            <h4>Paramedics List</h4>
          </div>
        </div>

        <div
          style={{
            flex: 4,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            className={`${"container-fluid"}`}
            style={{
              marginTop: "25px",
              paddingLeft: "10px",
              paddingRight: "10px",
            }}
          >
            <div className={`row  ${classes1.root} ${classes1.input} `}>
              <div
                className="col-md-12  col-12"
                style={{ paddingTop: 5, paddingLeft: 3, paddingRight: 3 }}
              >
                <TextField
                  className="textInputStyle"
                  id="searchPatientQuery"
                  type="text"
                  variant="filled"
                  label={"Search by Paramedics Name / ID"}
                  name={"searchPatientQuery"}
                  value={searchPatientQuery}
                  onChange={handlePatientSearch}
                  InputLabelProps={{
                    classes: {
                      root: classes.label,
                      focused: classes.focusedLabel,
                      error: classes.erroredLabel,
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <AccountCircle />
                      </InputAdornment>
                    ),
                    className: classes.input,
                    classes: { input: classes.input },
                    disableUnderline: true,
                  }}
                />
              </div>

              {/* <div
                className="col-md-1 col-sm-2 col-2"
                style={{
                  ...styles.textFieldPadding,
                  paddingTop: 5,
                  paddingLeft: 3,
                  paddingRight: 3,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "white",
                    borderRadius: 5,
                    height: 55,
                  }}
                >
                  <img src={BarCode} style={{ width: 70, height: 60 }} />
                </div>
              </div> */}

              {/* <div
                className="col-md-1 col-sm-1 col-2"
                style={{
                  ...styles.textFieldPadding,
                  paddingTop: 5,
                  paddingLeft: 3,
                  paddingRight: 3,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "white",
                    borderRadius: 5,
                    height: 55,
                  }}
                >
                  <img src={Fingerprint} style={{ maxWidth: 43, height: 43 }} />
                </div>
              </div> */}
            </div>
          </div>
          {staffData ? (
            <div className="container-fluid">
              <div className="row">
                <CustomTable
                  tableData={staffData}
                  tableDataKeys={tableDataKeysForDoctors}
                  tableHeading={tableHeadingForDoctors}
                  action={""}
                  borderBottomColor={"#60d69f"}
                  borderBottomWidth={20}
                />

                <Notification msg={errorMsg} open={openNotification} />
              </div>
            </div>
          ) : (
            <div className="LoaderStyle">
              <Loader type="TailSpin" color="red" height={50} width={50} />
            </div>
          )}

          <div className="container-fluid">
            <div className="row" style={{ marginBottom: 20 }}>
              <img
                onClick={() => props.history.goBack()}
                src={Back}
                style={{
                  width: 45,
                  height: 35,
                  cursor: "pointer",
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
