import React from "react";
import { Button } from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: "8px",
    height: "40px",
    backgroundColor: "#2873CF",
  },
  root: {
    "& .MuiButton-label": {
      fontSize: "0.675rem",
    },
  },
}));

function ButtonValueCombined(props) {
  const classes = useStyles();
  const matches = useMediaQuery("(min-width:600px)");

  return (
    <div style={{ display: "flex" }}>
      <div
        style={{
          width: "100%",
          backgroundColor: "white",
          paddingTop: 20,
          borderRight: '1px solid lightgray',
          borderTopLeftRadius: '5px',
          borderBottomLeftRadius: '5px',
          height: 270,
        }}
      >
        <div
          style={{
            display: "flex",
            height: "40%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <span
            style={{
              fontSize: matches ? 14 : 11,
              fontWeight: "700",
              opacity: matches ? "70%" : "80%",
              textAlign: "center",
            }}
          >
            {props.heading1}
          </span>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
          }}
        >
          <span
            style={{
              color: props.timeColor1,
              fontSize: matches ? 50 : 40,
              fontWeight: "700",
              position: "absolute",
              marginTop: -20,
            }}
          >
            {props.time1}
          </span>
        </div>
        <div
          className="col-12"
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            marginTop: 60,
            paddingLeft: 0,
            paddingRight: 0,
            alignItems: "center",
          }}
        >
          <span
            style={{
              color: "green",
              fontSize: matches ? 50 : 40,
              fontWeight: "900",
              position: "absolute",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              size="medium"
              className={[classes.button, matches ? " " : classes.root]}
              startIcon={<VisibilityIcon fontSize={matches ? 'small' : 'small'} />}
              onClick={() => props.onClick1(props.heading1)}
            >
              View Details
            </Button>
          </span>
        </div>
      </div>
      <div
        style={{
          width: "100%",
          backgroundColor: "white",
          paddingTop: 20,
          borderRight: '1px solid lightgray',
          height: 270,
        }}
      >
        <div
          style={{
            display: "flex",
            height: "40%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <span
            style={{
              fontSize: matches ? 14 : 11,
              fontWeight: "700",
              opacity: matches ? "70%" : "80%",
              textAlign: "center",
            }}
          >
            {props.heading2}
          </span>
        </div>
        <div
          className="col-12"
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            paddingLeft: 0,
            paddingRight: 0,
            alignItems: "center",
          }}
        >
          <span
            style={{
              color: props.timeColor2,
              fontSize: matches ? 50 : 40,
              fontWeight: "900",
              position: "absolute",
              marginTop: -20,
            }}
          >
            {props.time2}
          </span>
        </div>
        <div
          className="col-12"
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            marginTop: 60,
            paddingLeft: 0,
            paddingRight: 0,
            alignItems: "center",
          }}
        >
          <span
            style={{
              color: "green",
              fontSize: matches ? 50 : 40,
              fontWeight: "900",
              position: "absolute",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              size="medium"
              className={[classes.button, matches ? " " : classes.root]}
              startIcon={<VisibilityIcon />}
              onClick={() => props.onClick2(props.heading2)}
            >
              View Details
            </Button>
          </span>
        </div>
      </div>
      <div
        style={{
          width: "100%",
          backgroundColor: "white",
          paddingTop: 20,
          borderTopRightRadius: '5px',
          borderBottomRightRadius: '5px',
          height: 270,
        }}
      >
        <div
          style={{
            display: "flex",
            height: "40%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <span
            style={{
              fontSize: matches ? 14 : 11,
              fontWeight: "700",
              opacity: matches ? '70%' : '80%',
              textAlign: "center",
            }}
          >
            {props.heading3}
          </span>
        </div>
        <div
          className="col-12"
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            paddingLeft: 0,
            paddingRight: 0,
            alignItems: "center",
          }}
        >
          <span
            style={{
              color: props.timeColor3,
              fontSize: matches ? 50 : 40,
              fontWeight: "900",
              position: "absolute",
              marginTop: -20,
            }}
          >
            {props.time3}
          </span>
        </div>
        <div
          className="col-12"
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            marginTop: 60,
            paddingLeft: 0,
            paddingRight: 0,
            alignItems: "center",
          }}
        >
          <span
            style={{
              color: "green",
              fontSize: matches ? 50 : 40,
              fontWeight: "900",
              position: "absolute",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              size="medium"
              className={[classes.button, matches ? " " : classes.root]}
              startIcon={<VisibilityIcon />}
              onClick={() => props.onClick3(props.heading3)}
            >
              View Details
            </Button>
          </span>
        </div>
      </div>
    </div>
  );
}
export default ButtonValueCombined;
