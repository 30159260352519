import React, { useState, useEffect } from "react";
import Notification from "../../components/Snackbar/Notification.js";
import CustomTable from "../../components/Table/Table";
import axios from "axios";
import _ from "lodash";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Loader from "react-loader-spinner";
import Back from "../../assets/img/Back_Arrow.png";
import Header from "../../components/Header/Header";
import Lab_OPR from "../../assets/img/carestream/CareStream.png";
import ButtonField from "../../components/common/Button";
import Rad_OPR from "../../assets/img/RR.png";
import Pharmacist_OPR from "../../assets/img/PHR.png";
import Fingerprint from "../../assets/img/fingerprint.png";
import AccountCircle from "@material-ui/icons/SearchOutlined";
import InputAdornment from "@material-ui/core/InputAdornment";
import BarCode from "../../assets/img/Bar Code.png";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import "../../assets/jss/material-dashboard-react/components/loaderStyle.css";
import socketIOClient from "socket.io-client";
import cookie from "react-cookies";
import { assignStyles } from "./styles";
import {
  getEdrPatientsForCC,
  searchEdrPatientsForCC,
} from "./NetworkCallForCareStreamAssign";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import QRCodeScannerComponent from "../../components/QRCodeScanner/QRCodeScanner";

const tableHeading = [
  "MRN",
  "Patient Name",
  "Gender",
  "Age",
  "Date/Time",
  "Status",
  "Action",
];
const tableDataKeys = [
  "mrn",
  "patientName",
  "gender",
  "age",
  "createdAt",
  "status",
];

const styles = {
  textFieldPadding: {
    paddingLeft: 5,
    paddingRight: 5,
  },
};

const actions = { edit: true };

export default function EDR(props) {
  const classes = assignStyles();
  const matches = useMediaQuery("(min-width:600px)");
  const [labInPatient, setlabInPatient] = useState([]);
  const [isLoading, setisLoading] = useState(true);
  const [radInPatient, setradInPatient] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [openNotification, setOpenNotification] = useState(false);
  const [searchPatientQuery, setSearchPatientQuery] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState("");
  const [QRCodeScanner, setQRCodeScanner] = useState(false);
  const [currentUser] = useState(cookie.load("current_user"));

  if (openNotification) {
    setTimeout(() => {
      setOpenNotification(false);
      setErrorMsg("");
    }, 4000);
  }

  useEffect(() => {
    console.log("props::", props);
    if (
      props.location.state &&
      props.location.state.comingFrom === "notification"
    ) {
      setSearchPatientQuery(props.location.state.edrId);
      if (props.location.state.edrId.length >= 3) {
        console.log("testing");
        searchEdrPatientsForCC(
          props.location.state.edrId,
          setlabInPatient,
          setErrorMsg,
          setOpenNotification,
          setisLoading
        );
      } else if (props.location.state.edrId.length == 0) {
        getPatientData();
      }
    } else {
      getPatientData();
    }
  }, []);

  console.log("labInPatient::", labInPatient);

  function getPatientData() {
    getEdrPatientsForCC(
      setlabInPatient,
      setErrorMsg,
      setOpenNotification,
      setisLoading
    );
  }

  function hideDialog() {
    setOpenDialog(false);
  }

  function handleView(rec) {
    // console.log("REC", rec);
    if (rec.careStream.length > 0) {
      setSelectedRecord(rec);
      setOpenDialog(true);
    } else {
      handleEdit(rec);
    }
  }

  function handleEdit(rec) {
    let path = `careStreamPatients/assign`;

    console.log("selectedRecord", rec);

    if (
      rec.dcdForm.length > 0 &&
      rec.dcdForm[ rec.dcdForm.length - 1 ].triageAssessment.length > 0 &&
      rec.dcdForm[ rec.dcdForm.length - 1 ].patientDetails.length > 0 &&
      rec.dcdForm[ rec.dcdForm.length - 1 ].pastMedicalHistory.length > 0 &&
      rec.dcdForm[ rec.dcdForm.length - 1 ].ROS.length > 0 &&
      rec.dcdForm[ rec.dcdForm.length - 1 ].physicalExam.length > 0
    ){
      props.history.push({
        pathname: path,
        state: {
          // selectedItem: selectedRecord,
          selectedItem: rec,
        },
      });
    } else {
      setErrorMsg("Please assign ROS and Physical Exam before assigning careStream");
      setOpenNotification(true);
    }
  }

  const handlePatientSearch = (e) => {
    const a = e.target.value.replace(/[^\w\s]/gi, "");
    setSearchPatientQuery(a);
    if (a.length >= 3) {
      searchEdrPatientsForCC(
        a,
        setlabInPatient,
        setErrorMsg,
        setOpenNotification,
        setisLoading
      );
    } else if (a.length == 0) {
      getPatientData();
    }
  };

  const addNewItem = () => {
    props.history.push({
      pathname: "patientregistration",
      state: { comingFor: "add" },
    });
  };

  function scanQRCode() {
    setQRCodeScanner((pervState) => !pervState);
  }

  function handleScanQR(data) {
    setQRCodeScanner(false);
    console.log("json", JSON.parse(data));
    if (JSON.parse(data).profileNo) {
      handlePatientSearch({
        target: {
          value: JSON.parse(data).profileNo,
          type: "text",
        },
      });
    }
  }

  if (QRCodeScanner) {
    return (
      <div>
        {QRCodeScanner ? (
          <QRCodeScannerComponent
            handleScanQR={handleScanQR}
            scanQRCode={scanQRCode}
          />
        ) : (
          undefined
        )}
      </div>
    );
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        position: "fixed",
        width: "100%",
        height: "100%",
        backgroundColor: "rgb(19 213 159)",
        overflowY: "scroll",
      }}
    >
      <Header history={props.history} />

      <div className="cPadding">
        <div className="subheader">
          <div>
            <img src={Lab_OPR} />
            <h4>Assign CareStream</h4>
          </div>
        </div>

        <div
          className={`${"container-fluid"} ${classes.root}`}
          style={{
            marginTop: "25px",
            paddingLeft: "10px",
            paddingRight: "10px",
          }}
        >
          <div className="row">
            <div
              className="col-md-10 col-sm-9 col-8"
              style={styles.textFieldPadding}
            >
              <TextField
                className="textInputStyle"
                id="searchPatientQuery"
                type="text"
                variant="filled"
                label="Search Patient by Name / MRN / National ID / Mobile Number"
                name={"searchPatientQuery"}
                value={searchPatientQuery}
                onChange={handlePatientSearch}
                InputLabelProps={{
                  classes: {
                    root: classes.label,
                    focused: classes.focusedLabel,
                    error: classes.erroredLabel,
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <AccountCircle />
                    </InputAdornment>
                  ),
                  className: classes.input,
                  classes: { input: classes.input },
                  disableUnderline: true,
                }}
              />
            </div>

            <div
              className="col-md-1 col-sm-2 col-2"
              style={{
                ...styles.textFieldPadding,
                paddingLeft: matches ? 5 : 0,
                paddingRight: matches ? 5 : 3,
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "white",
                  borderRadius: 5,
                  height: 55,
                }}
              >
                <img
                  src={BarCode}
                  onClick={scanQRCode}
                  style={{ width: 70, height: 60 }}
                />
              </div>
            </div>

            <div
              className="col-md-1 col-sm-1 col-2"
              style={{
                ...styles.textFieldPadding,
                paddingLeft: matches ? 5 : 2,
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "white",
                  borderRadius: 5,
                  height: 55,
                }}
              >
                <img src={Fingerprint} style={{ maxWidth: 43, height: 43 }} />
              </div>
            </div>
          </div>
        </div>

        <div
          style={{
            flex: 4,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div>
            {isLoading ? (
              <div style={{ margin: "auto", width: "0%", padding: 10 }}>
                <Loader type="TailSpin" color="red" height={50} width={50} />
              </div>
            ) : labInPatient && labInPatient.length ? (
              <div>
                <div>
                  <CustomTable
                    tableData={labInPatient}
                    tableDataKeys={tableDataKeys}
                    tableHeading={tableHeading}
                    action={actions}
                    handleEdit={handleView}
                    borderBottomColor={"#60d69f"}
                    borderBottomWidth={20}
                  />
                </div>
                <div style={{ marginTop: 20, marginBottom: 20 }}>
                  <img
                    onClick={() => props.history.goBack()}
                    src={Back}
                    style={{
                      width: 45,
                      height: 35,
                      cursor: "pointer",
                    }}
                  />
                </div>
                <Notification msg={errorMsg} open={openNotification} />
              </div>
            ) : (
              <div className="row " style={{ marginTop: "25px" }}>
                <div className="col-11">
                  <h3
                    style={{
                      color: "white",
                      textAlign: "center",
                      width: "100%",
                      position: "absolute",
                    }}
                  >
                    Opps...No Data Found
                  </h3>
                </div>
                <div className="col-1" style={{ marginTop: 45 }}>
                  <img
                    onClick={() => props.history.goBack()}
                    src={Back}
                    style={{
                      maxWidth: "60%",
                      height: "auto",
                      cursor: "pointer",
                    }}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <div>
        <Dialog
          open={openDialog}
          // maxWidth='md'
          // onClick={() => hideDialog()}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth={true}
        >
          <DialogTitle id="alert-dialog-title">
            <h5
              style={{
                fontWeight: "bold",
                marginTop: 10,
                textAlign: "center",
              }}
            >
              {`'${selectedRecord &&
                selectedRecord.careStream[selectedRecord.careStream.length - 1]
                  .name}'` + " is already assigned to this EDR"}
            </h5>

            <h5
              style={{
                fontWeight: "bold",
                marginTop: 10,
                textAlign: "center",
              }}
            >
              Do you want to assign a new Caresteam?
            </h5>
          </DialogTitle>
          <DialogContent>
            {/* <DialogContentText id='alert-dialog-description'>
              Let Google help apps determine location. This means sending
              anonymous location data to Google, even when no apps are running.
            </DialogContentText> */}
          </DialogContent>
          <DialogActions>
            <div className={`container-fluid ${classes.root}`}>
              <div
                class="row"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingLeft: 5,
                  paddingRight: 5,
                  marginTop: 20,
                }}
              >
                <div style={{ marginTop: "2%", marginBottom: "2%" }}>
                  <Button
                    onClick={() => hideDialog()}
                    style={{
                      ...styles.stylesForButton,
                      backgroundColor: "white",
                      color: "grey",
                      width: matches ? " " : 150,
                    }}
                    variant="contained"
                  >
                    <strong>Cancel</strong>
                  </Button>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: "2%",
                    marginBottom: "2%",
                  }}
                >
                  <Button
                    style={{
                      ...styles.stylesForSubmitButton,
                    }}
                    onClick={() => handleEdit(selectedRecord)}
                    variant="contained"
                    color="primary"
                  >
                    Yes
                  </Button>
                </div>
              </div>
            </div>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
}
