
export const  CONST_FOR_SAGA_FUNCTION="const_for_saga_function";
export const CONST_FOR_REDUCER_FUNCTION="const_for_reducer_function";

export const CONST_FOR_SET_PATIENT_DETAILS="const_for_set_patient_details";


export const SET_PATIENT_DETAILS="SET_PATIENT_DETAILS";
export const DELETE_PATIENT_DETAILS="DELETE_PATIENT_DETAILS";


