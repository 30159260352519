import React, { useState, useEffect } from "react";
import Notification from "../../components/Snackbar/Notification.js"
import CustomTable from "../../components/Table/Table"
import axios from "axios"
import _ from "lodash"
import ButtonField from "../../components/common/Button";
import Button from "@material-ui/core/Button"
import { getCCPatients, SearchEDRPatients } from "../../public/endpoins"
import Back from "../../assets/img/Back_Arrow.png"
import Header from "../../components/Header/Header"
import Pending_Patients from "../../assets/img/Production Area.png";
import Fingerprint from "../../assets/img/fingerprint.png";
import AccountCircle from "@material-ui/icons/SearchOutlined";
import InputAdornment from "@material-ui/core/InputAdornment";
import BarCode from "../../assets/img/Bar Code.png";
import TextField from "@material-ui/core/TextField";
import "../../assets/jss/material-dashboard-react/components/loaderStyle.css";
import socketIOClient from "socket.io-client";
import useStylesForInput from "../../assets/jss/material-dashboard-react/inputStyle";
import cookie from "react-cookies";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { getAllProductionTeams } from "./NetworkCall";
import styles from "./styles";
const tableHeading = [
  "Team Name",
  "No of Doctors",
  "No of Nurses",
  "Number of Nurse Technician",
  "Action",
];
const tableDataKeys = ["name", "noDoctors", "noNurses", "noNurseTechnicians"];

const actions = { edit: true };

export default function ManageProductionTeam(props) {
  const matches = useMediaQuery("(min-width:600px)");
  const classes = useStylesForInput();

  const [data, setData] = useState([]);
  const [errorMsg, setErrorMsg] = useState("");
  const [openNotification, setOpenNotification] = useState(false);
  const [searchPatientQuery, setSearchPatientQuery] = useState("");

  if (openNotification) {
    setTimeout(() => {
      setOpenNotification(false);
      setErrorMsg("");
    }, 2000);
  }

  useEffect(() => {
    getProductionTeam();
    console.log("datadata", data);
  }, []);

  function getProductionTeam() {
    getAllProductionTeams(setData, setErrorMsg, setOpenNotification);
  }

  function handleView(rec) {
    console.log("rec pushed", rec);
    props.history.push({
      pathname: "production-teams/edit",
      state: { 
        comingFor: "edit",
        editId: rec._id
      },
    });
  }

  const handlePatientSearch = (e) => {
    const a = e.target.value.replace(/[^\w\s]/gi, "");
    setSearchPatientQuery(a);
    if (a.length >= 3) {
      // SearchProductionArea(setData, a);
    } else if (a.length == 0) {
      getProductionTeam();
    }
  };

  const addNewItem = () => {
    props.history.push({
      pathname: "production-teams/create",
      state: { comingFor: "add" },
    });
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        position: "fixed",
        width: "100%",
        height: "100%",
        backgroundColor: "rgb(19 213 159)",
        overflowY: "scroll",
      }}
    >
      <Header history={props.history} />
      <div className="cPadding">
        <div className="subheader">
          <div>
            <img src={Pending_Patients} />
            <h4>Manage Production Team</h4>
          </div>
          <div>
            <ButtonField onClick={addNewItem} name="add" />
          </div>
        </div>

        <div
          style={{
            flex: 4,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div>
            {data.length > 0 ? (
              <div>
                <div>
                  <CustomTable
                    tableData={data}
                    tableDataKeys={tableDataKeys}
                    tableHeading={tableHeading}
                    action={actions}
                    handleEdit={handleView}
                    borderBottomColor={"#60d69f"}
                    borderBottomWidth={20}
                  />
                </div>
                <div style={{ marginTop: 20, marginBottom: 20 }}>
                  <img
                    onClick={() => props.history.goBack()}
                    src={Back}
                    style={{
                      width: 45,
                      height: 35,
                      cursor: "pointer",
                    }}
                  />
                </div>
                <Notification msg={errorMsg} open={openNotification} />
              </div>
            ) : (
              <div className="row " style={{ marginTop: "25px" }}>
                <div className="col-11">
                  <h3
                    style={{
                      color: "white",
                      textAlign: "center",
                      width: "100%",
                      position: "absolute",
                    }}
                  >
                    Opps...No Data Found
                  </h3>
                </div>
                <div className="col-1" style={{ marginTop: 45 }}>
                  <img
                    onClick={() => props.history.goBack()}
                    src={Back}
                    style={{
                      maxWidth: "60%",
                      height: "auto",
                      cursor: "pointer",
                    }}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
