import React, { useEffect, useState } from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import Flip from "../../../components/Dial/Flip";
import OneValue from "../../../components/Dial/OneValue";
import SliderComponent from "../../../components/SliderComponent/sliderComponent";
import LineChart from "../../../components/Dial/LineChart";
import FlipForProgress from "../../../components/Dial/FlipForProgress";
import { roDashboard } from '../../../public/endpoins';
import { getParamedics } from './NetworkCallForParamedics';

// import axios from 'axios'
// import { registrationOfficer } from '../../public/endpoins'

export default function ParamedicsDashboard() {
  const theme = useTheme();

  const matches = useMediaQuery("(min-width:600px)");
  const matchesForTablet = useMediaQuery(theme.breakpoints.up("md"));

  const [parPending, setParPending] = useState("");
  const [totalInsured, setTotalInsured] = useState("");
  const [totalUnInsured, setTotalUnInsured] = useState("");
  const [patient, setPatient] = useState("");
  const [perHour, setPerHour] = useState("");
  const [enableEDBedsProgress, setEnableEDBedsProgress] = useState(true);
  const [enableIPBedsProgressBar, setEnableIPBedsProgressBar] = useState(true);

  // colors
  const [parPendingColor, setParPendingColor] = useState("");
  const [totalInsuredColor, setTotalInsuredColor] = useState("");
  const [totalUnInsuredColor, setTotalUnInsuredColor] = useState("");
  const [patientColor, setPatientColor] = useState("");
  const [perHourColor, setPerHourColor] = useState("");
  const [options, setOptions] = useState([]);
  const [bedLineChartWidth, setBedLineChartWidth] = useState(
    window.innerWidth <= 600 ? 300 : 420
  );

  const [flip, setFlip] = useState(false);

  const [ipBeds, setIPBeds] = useState(85);
  const [edBeds, setEDBeds] = useState(85);

  useEffect(() => {
    const getData = async () => {
      const response = await getParamedics(roDashboard);
      console.log("response: ", response)
    }
    getData();
    // axios
    //   .get(registrationOfficer)
    //   .then((res) => {
    //     console.log(res.data)
    //     if (res.data.success) {
    //       if (res.data.parPending >= 0 && res.data.parPending <= 39) {
    //         setParPendingColor('#60D69F')
    //       } else if (res.data.parPending >= 40 && res.data.parPending <= 79) {
    //         setParPendingColor('#FFBC28')
    //       } else if (res.data.parPending >= 80 && res.data.parPending <= 100) {
    //         setParPendingColor('#FF0000')
    //       }
    //       if (res.data.totalInsured >= 0 && res.data.totalInsured <= 39) {
    //         setTotalInsuredColor('#60D69F')
    //       } else if (
    //         res.data.totalInsured >= 40 &&
    //         res.data.totalInsured <= 70
    //       ) {
    //         setTotalInsuredColor('#FFBC28')
    //       } else if (
    //         res.data.totalInsured >= 80 &&
    //         res.data.totalInsured <= 100
    //       ) {
    //         setTotalInsuredColor('#FF0000')
    //       }
    //       if (res.data.totalUnInsured >= 0 && res.data.totalUnInsured <= 39) {
    //         setTotalUnInsuredColor('#60D69F')
    //       } else if (
    //         res.data.totalUnInsured >= 40 &&
    //         res.data.totalUnInsured <= 70
    //       ) {
    //         setTotalUnInsuredColor('#FFBC28')
    //       } else if (
    //         res.data.totalUnInsured >= 80 &&
    //         res.data.totalUnInsured <= 100
    //       ) {
    //         setTotalUnInsuredColor('#FF0000')
    //       }
    //       if (res.data.patient >= 0 && res.data.patient <= 39) {
    //         setPatientColor('#60D69F')
    //       } else if (res.data.patient >= 40 && res.data.patient <= 70) {
    //         setPatientColor('#FFBC28')
    //       } else if (res.data.patient >= 80 && res.data.patient <= 100) {
    //         setPatientColor('#FF0000')
    //       }
    //       if (res.data.perHour >= 0 && res.data.perHour <= 39) {
    //         setPerHourColor('#60D69F')
    //       } else if (res.data.perHour >= 40 && res.data.perHour <= 70) {
    //         setPerHourColor('#FFBC28')
    //       } else if (res.data.perHour >= 80 && res.data.perHour <= 100) {
    //         setPerHourColor('#FF0000')
    //       }
    //       setParPending(res.data.parPending)
    //       setTotalInsured(res.data.totalInsured)
    //       setTotalUnInsured(res.data.totalUnInsured)
    //       setPatient(res.data.patient)
    //       setPerHour(res.data.perHour)
    //     }
    //   })
    //   .catch((error) => {
    //     console.log(error)
    //   })
  }, []);

  const flipIt = () => {
    setFlip(true);
  };

  useEffect(() => {
    const dummyData = [
      { value: 100, label: new Date("2021-02-16T12:48:26.948Z") },
      { value: 80, label: new Date("2021-02-16T11:48:26.948Z") },
      { value: 70, label: new Date("2021-02-16T10:48:26.948Z") },
      { value: 60, label: new Date("2021-02-16T09:48:26.948Z") },
      { value: 50, label: new Date("2021-02-16T08:48:26.948Z") },
      { value: 10, label: new Date("2021-02-16T07:48:26.948Z") },
    ];

    setOptions(dummyData);
  }, []);

  return (
    <div className="container-fluid" style={{ marginBottom: 10 }}>
      <div className="row">
        <div
          className="col-md-6 col-12"
          style={{ paddingLeft: 4, paddingRight: 4, marginTop: 10 }}
        >
          {!matches ? (
            <FlipForProgress
              mobile={matches ? false : true}
              flipped={!flip}
              flipHandler={flipIt}
              idForGraph={"container1"}
              data={options}
              progressValue={""}
              progressName={"ED Beds Available"}
            />
          ) : (
            <div
              style={{
                width: "100%",
                backgroundColor: "white",
                height: 180,
                borderRadius: 5,
                padding: 10,
              }}
              onClick={() => setEnableEDBedsProgress(!enableEDBedsProgress)}
            >
              <div>
                <span style={{ fontWeight: "bold", fontSize: 18 }}>
                  ED Beds Available
                </span>
              </div>
              <div>
                <span style={{ fontWeight: "bold", fontSize: 45 }}>
                  {edBeds}
                </span>
              </div>
              {enableEDBedsProgress === true ? (
                <div style={{ cursor: "pointer" }}>
                  <SliderComponent
                    top={120}
                    bottom={0}
                    left={
                      matches && matchesForTablet
                        ? 140
                        : matches && !matchesForTablet
                        ? 45
                        : 25
                    }
                    right={0}
                    onSlide={setEDBeds}
                    readOnly={true}
                    progressValue={edBeds}
                  />
                </div>
              ) : (
                [] && (
                  <div
                    style={{
                      marginTop: "-90px",
                      marginLeft: "40px",
                      cursor: "pointer",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        height: "10%",
                        justifyContent: "center",
                        alignItems: "flex-end",
                      }}
                    >
                      <span
                        style={{
                          fontSize: 13,
                          fontWeight: "700",
                          textAlign: "center",
                          opacity: "70%",
                        }}
                      >
                        Last 06 Hours
                      </span>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        height: "10%",
                        justifyContent: "center",
                        alignItems: "flex-end",
                      }}
                    >
                      <LineChart
                        data={options}
                        width={bedLineChartWidth}
                        height={140}
                        scaleWidth={bedLineChartWidth}
                        scaleHeight={100}
                        idForGraph="senseiEdBedsAvailableId"
                      />
                    </div>
                  </div>
                )
              )}
            </div>
          )}
        </div>

        <div
          className="col-md-6 col-12"
          style={{ paddingLeft: 4, paddingRight: 4, marginTop: 10 }}
        >
          {!matches ? (
            <FlipForProgress
              mobile={matches ? false : true}
              flipped={!flip}
              flipHandler={flipIt}
              idForGraph={"container1"}
              data={options}
              progressValue={""}
              progressName={"IP Beds Available"}
            />
          ) : (
            <div
              style={{
                width: "100%",
                backgroundColor: "white",
                height: 180,
                borderRadius: 5,
                padding: 10,
              }}
              onClick={() => setEnableIPBedsProgressBar(!enableIPBedsProgressBar)}
            >
              <div>
                <span style={{ fontWeight: "bold", fontSize: 18 }}>
                  IP Beds Available
                </span>
              </div>
              <div>
                <span style={{ fontWeight: "bold", fontSize: 45 }}>
                  {edBeds}
                </span>
              </div>
              {enableIPBedsProgressBar === true ? (
                <div style={{ cursor: "pointer" }}>
                  <SliderComponent
                    top={120}
                    bottom={0}
                    left={
                      matches && matchesForTablet
                        ? 140
                        : matches && !matchesForTablet
                        ? 45
                        : 25
                    }
                    right={0}
                    onSlide={setEDBeds}
                    readOnly={true}
                    progressValue={edBeds}
                  />
                </div>
              ) : (
                [] && (
                  <div
                    style={{
                      marginTop: "-90px",
                      marginLeft: "40px",
                      cursor: "pointer",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        height: "10%",
                        justifyContent: "center",
                        alignItems: "flex-end",
                      }}
                    >
                      <span
                        style={{
                          fontSize: 13,
                          fontWeight: "700",
                          textAlign: "center",
                          opacity: "70%",
                        }}
                      >
                        Last 06 Hours
                      </span>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        height: "10%",
                        justifyContent: "center",
                        alignItems: "flex-end",
                      }}
                    >
                      <LineChart
                        data={options}
                        width={bedLineChartWidth}
                        height={140}
                        scaleWidth={bedLineChartWidth}
                        scaleHeight={100}
                        idForGraph="senseiEdBedsAvailableId"
                      />
                    </div>
                  </div>
                )
              )}
            </div>
          )}
        </div>
      </div>
      <div className="row">
        <div
          className="col-md-4 col-6"
          style={{ paddingLeft: 4, paddingRight: 4, marginTop: 10 }}
        >
          <div>
            <Flip
              mobile={matches ? false : true}
              flipped={!flip}
              flipHandler={flipIt}
              mainHeading={"Pending Patient Paramedic Request"}
              value={70}
              mainHeadingForGraph="Pending Patient Paramedic Request"
              color="#FFBC28"
              subHeading={"TAT"}
              childHeading={"Request to Attendance"}
              time={"30"}
              timecolor="#FFBC28"
              idForGraph={"paramedicsContainer7"}
              data={options}
            />
          </div>
        </div>

        <div
          className="col-md-4 col-6"
          style={{ paddingLeft: 4, paddingRight: 4, marginTop: 10 }}
        >
          <div>
            <Flip
              mobile={matches ? false : true}
              flipped={!flip}
              flipHandler={flipIt}
              mainHeading={"No. of Requests/Hour"}
              value={40}
              mainHeadingForGraph="No. of Requests/Hour"
              color="#13D59F"
              subHeading={"TAT"}
              childHeading={"Per Patient"}
              time={"55"}
              timecolor="#FFBC28"
              idForGraph={"paramedicsContainer9"}
              data={options}
            />
          </div>
        </div>

        <div
          className="col-md-4 col-6"
          style={{ paddingLeft: 4, paddingRight: 4, marginTop: 10 }}
        >
          <div>
            <OneValue
              time={250}
              colorTime={"#FFBC28"}
              heading={"Cumulative number of Patients"}
            />
          </div>
        </div>
      </div>{" "}
    </div>
  );
}
