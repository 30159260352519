import React, { useEffect, useState, useReducer } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import { FaUpload } from "react-icons/fa";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import DateFnsUtils from "@date-io/date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import Notification from "../../components/Snackbar/Notification.js";
import { MdRemoveCircle } from "react-icons/md";
import axios from "axios";
import Fingerprint from "../../assets/img/fingerprint.png";
import validateEmergencyName from "../../public/inputValidator";
import validateRelation from "../../public/relationValidator";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import BarCode from "../../assets/img/Bar Code.png";
import ErrorMessage from "../../components/ErrorMessage/errorMessage";
import validateEmail from "../../public/emailValidator";
import validateFirstName from "../../public/inputValidator";
import validateLastName from "../../public/inputValidatorLName";
import validateNationalId from "../../public/numbersValidator";
import MuiPhoneNumber from "material-ui-phone-number";
import validateCountryCity from "../../public/countryCityValidator";
import validateGender from "../../public/genderValidator";
import validateAddress from "../../public/addressValidator";
import validateOtherDetails from "../../public/TextFieldValidator";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import useStylesForSearchInput from "../../assets/jss/material-dashboard-react/inputStyle";
import styles from "./styles";
import { uploadsUrl, patientRegistration } from "../../public/endpoins";
// import "../../assets/jss/material-dash  board-react/components/TextInputStyle.css";
import FormData from "form-data";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import AccountCircle from "@material-ui/icons/SearchOutlined";
import InputAdornment from "@material-ui/core/InputAdornment";
import Loader from "react-loader-spinner";
import validatePhone from "../../public/validatePhone";
import Cookies from "react-cookies";
import CloseIcon from "../../assets/img/CloseIconPng.png";

import { generateEDR, SearchPatients } from "../../public/endpoins";

import {
  savePatientData,
  editPatientData,
  patientSearch,
  addSearchedPatient,
  generateEDRecord,
} from "./NetworkCallForShort";
import Header from "../../components/Header/Header";
import patientRegister from "../../assets/img/PatientRegistration.png";

import QRCodeScannerComponent from "../../components/QRCodeScanner/QRCodeScanner";

let countriesList = require("../../assets/countries.json");
let matches;

const titles = [
  {
    key: "mr" || "Mr",
    value: "Mr",
  },
  {
    key: "miss" || "Miss",
    value: "Miss",
  },
  {
    key: "mrs" || "Mrs",
    value: "Mrs",
  },
  {
    key: "ms" || "Ms",
    value: "Ms",
  },
];

const genderArray = [
  {
    key: "Male",
    value: "Male",
  },
  {
    key: "Female",
    value: "Female",
  },
  {
    key: "Others",
    value: "Others",
  },
];

const bloodGroups = [
  {
    key: "A+",
    value: "A+",
  },
  {
    key: "A-",
    value: "A-",
  },
  {
    key: "B+",
    value: "B+",
  },
  {
    key: "B-",
    value: "B-",
  },
  {
    key: "O+",
    value: "O+",
  },
  {
    key: "O-",
    value: "O-",
  },
  {
    key: "AB+",
    value: "AB+",
  },
  {
    key: "AB-",
    value: "AB-",
  },
];

const relationArray = [
  {
    key: "brother",
    value: "Brother",
  },
  {
    key: "father",
    value: "Father",
  },
  {
    key: "mother",
    value: "Mother",
  },
  {
    key: "sister",
    value: "Sister",
  },
  {
    key: "uncle",
    value: "Uncle",
  },
  {
    key: "other",
    value: "Other",
  },
];

const useStylesForTabs = makeStyles(() => ({
  root: {
    justifyContent: "center",
  },
  scroller: {
    flexGrow: "0",
  },
}));

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(0),
  },
  input: {
    backgroundColor: "white",
    boxShadow: "none",
    borderRadius: 5,
    "&:after": {
      borderBottomColor: "black",
      boxShadow: "none",
    },
    "&:hover": {
      backgroundColor: "white",
      boxShadow: "none",
    },
    "&:focus": {
      backgroundColor: "white",
      boxShadow: "none",
      borderRadius: 5,
    },
  },
  multilineColor: {
    boxShadow: "none",
    backgroundColor: "white",
    borderRadius: 5,
    "&:hover": {
      backgroundColor: "white",
      boxShadow: "none",
    },
    "&:after": {
      borderBottomColor: "black",
      boxShadow: "none",
    },
    "&:focus": {
      boxShadow: "none",
    },
  },
  root: {
    "& .MuiTextField-root": {
      backgroundColor: "white",
    },
    "& .Mui-focused": {
      backgroundColor: "white",
      color: "black",
      boxShadow: "none",
    },
    "& .Mui-disabled": {
      backgroundColor: "white",
      color: "gray",
    },
    "&:focus": {
      backgroundColor: "white",
      boxShadow: "none",
      display: matches ? " " : "none",
    },
    "& .MuiFormLabel-root": {
      fontSize: "15px",
      paddingRight: "45px",
    },
  },
  label: {
    "&$focusedLabel": {
      color: "red",
      display: "none",
    },
  },

  square: {
    // color: theme.palette.getContrastText(deepOrange[500]),
    // backgroundColor: deepOrange[500],
    width: theme.spacing(35),
    height: theme.spacing(25),
  },
  large: {
    width: theme.spacing(20),
    height: theme.spacing(20),
  },
}));

function PatientRegistration(props) {
  const matches = useMediaQuery("(min-width:600px)");
  const classes = useStyles();
  const classesForTabs = useStylesForTabs();

  const initialState = {
    identifier: [{ value: "" }],
    nationalID: "",
    nationality: "Jordan",
    isJohnDoe: false,
    identityType: "national-id",
    gender: "",
    isChild: false,
    age: "0",
    height: "",
    weight: "",
    blood: "",
    birthDate: new Date(),
    otherDetails: "",
    insuranceNumber: "",
    coverageDetails: "",
    coverageTerms: "",
    coPayment: "",
    insuranceVendor: "",
    otherCoverageDetails: "",
    registrationStatus: "",
  };

  function reducer(state, { field, value }) {
    return {
      ...state,
      [field]: value,
    };
  }

  const [state, dispatch] = useReducer(reducer, initialState);

  const {
    identifier,
    nationalID,
    isJohnDoe,
    identityType,
    nationality,
    gender,
    isChild,
    age,
    height,
    weight,
    blood,
    birthDate,
    otherDetails,
    insuranceNumber,
    coverageDetails,
    coverageTerms,
    coPayment,
    insuranceVendor,
    otherCoverageDetails,
    registrationStatus,
  } = state;

  const [comingFor, setcomingFor] = useState("");
  const [currentUser, setCurrentUser] = useState(Cookies.load("current_user"));
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setsuccessMsg] = useState("");
  const [openNotification, setOpenNotification] = useState(false);
  const [countries, setCountries] = useState("");
  const [cities, setCities] = useState("");
  const [value, setValue] = React.useState(0);
  const [frontUpload, setFrontUpload] = useState("");
  const [backUpload, setBackUpload] = useState("");
  const [MRN, setMRN] = useState("");
  const [frontPreview, setFrontPreview] = useState("");
  const [backPreview, setBackPreview] = useState("");
  const [patientId, setPatientId] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [itemFound, setItemFound] = useState("");
  const [itemFoundSuccessfull, setItemFoundSuccessfully] = useState(false);
  const [searchActivated, setsearchActivated] = useState(false);
  const [isPatientSubmitted, setIsPatientSubmitted] = useState(false);
  const [detailsForm, setDetailsForm] = useState(false);
  const [timer, setTimer] = useState(null);
  const [cityBoolean, setCityBoolean] = useState(false);
  const [enableForm, setenableForm] = useState(true);
  const [enableNext, setenableNext] = useState(true);
  const [insuranceForm, setinsuranceForm] = useState(false);
  const [Insuranceform, setInsuranceForm] = useState(true);
  const [photo, setphoto] = useState([]);
  const [insuranceCard, setInsuranceCard] = useState("");
  const [loadSearchedData, setLoadSearchedData] = useState(false);
  const [idCardFront, setIdCardFront] = useState("");
  const [idCardBack, setIdCardBack] = useState("");
  const [saveData, setSaveData] = useState("");
  const [emergencyForm, setEmergencyForm] = useState(false);
  const [name, setName] = useState([
    { use: "official", family: "", given: [""], prefix: "" },
  ]);

  const [time, setTime] = useState([{ processStartTime: new Date() }]);

  const [telecom, setTelecom] = useState([
    { value: "", system: "email" },
    { value: "", system: "mobile" },
    { value: "", system: "phone" },
  ]);
  const [address, setAddress] = useState([
    {
      use: "home",
      type: "postal",
      text: "",
      city: "",
      country: "",
    },
  ]);
  const [contact, setContact] = useState([
    {
      name: "",
      telecom: [{ value: "", system: "mobile" }],
      typeOfRelation: "",
    },
  ]);
  const [paymentMethod, setpaymentMethod] = useState([
    {
      payment: "",
      paymentDate: new Date(),
      recipient: "",
      payee: "",
      amount: "",
    },
  ]);
  const [coveredFamilyMember, setCoveredFamilyMember] = useState([
    {
      name: "",
      telecom: [{ system: "mobile", value: "" }],
      typeOfRelation: "",
    },
  ]);

  const [QRCodeScanner, setQRCodeScanner] = useState(false);

  useEffect(() => {
    setCountries(Object.keys(countriesList[0]));
  }, []);

  // console.log("props::", props);

  useEffect(() => {
    if (
      props.props.location.state !== undefined &&
      props.props.location.state.comingFrom === "notification"
    ) {
      setSearchQuery(props.props.location.state.patientMRN);
      patientSearch(
        props.props.location.state.patientMRN,
        SearchPatients,
        setItemFoundSuccessfully,
        setItemFound,
        setLoadSearchedData
      );
    }

    // addSearchedPatient(
    //   patientRegistration,
    //   props.props.location.state.roPatient,
    //   setPatientId,
    //   setName,
    //   setIdCardBack,
    //   setIdCardFront,
    //   setAddress,
    //   setTelecom,
    //   setContact,
    //   setphoto,
    //   setCoveredFamilyMember,
    //   setpaymentMethod,
    //   setInsuranceCard,
    //   setenableForm,
    //   setInsuranceForm,
    //   setenableNext,
    //   setSearchQuery,
    //   setsearchActivated,
    //   countriesList,
    //   setCities,
    //   setOpenNotification,
    //   setErrorMsg,
    //   dispatch
    // );
  }, []);

  // console.log("current user", currentUser);

  // handle input change
  const handleNameChange = (e, index) => {
    const list = [...name];
    if (/^[a-zA-Z' ]*$/.test(e.target.value) === false) {
      return;
    } else {
      if (e.target.name === "given") {
        list[index][e.target.name] = [e.target.value.replace(/[^\w'\s]/gi, "")];
      } else {
        list[index][e.target.name] = e.target.value.replace(/[^\w'\s]/gi, "");
      }
    }

    setName(list);
  };

  const handleContactChange = (e, index) => {
    const { name, value } = e.target;
    console.log("name", name, "value", value);

    const list = [...contact];
    if (name === "name") {
      if (/^[a-zA-Z' ]*$/.test(value) === false) {
        return;
      } else {
        list[index][name] = value.replace(/[^\w'\s]/gi, "");
      }
    } else {
      list[index][name] = value;
    }
    setContact(list);
  };

  const handleContactTelecomChange = (e, index) => {
    console.log("telecom ", e, " ", index);

    let list = [...contact];

    list[0].telecom[index].value = e;

    setContact(list);
  };

  const handleTelecomChange = (e, field) => {
    let tempTelecom = [...telecom];
    for (let i = 0; i < tempTelecom.length; i++) {
      if (e.target) {
        if (tempTelecom[i].system === e.target.name) {
          tempTelecom[i].value = e.target.value.replace(/[^\w@.\s]/gi, "");
        }
      } else {
        if (tempTelecom[i].system === field) {
          tempTelecom[i].value = e;
        }
      }
    }
    setTelecom(tempTelecom);
  };

  const handleAddressChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...address];

    // if (name === 'city') {
    //   if (value === 'Other') {
    //     setCityBoolean(true)
    //     list[index][name] = 'Other'
    //   } else {
    //     setCityBoolean(false)
    //   }
    // }

    list[index][name] = value;

    let cities = Object.entries(countriesList[0]);
    for (var x in cities) {
      let arr = cities[x];
      if (arr[0] === e.target.value) {
        setCities(arr[1].sort());
      }
    }
    setAddress(list);
  };

  const EmergencyOnClick = () => {
    if (validateEmergencyForm()) {
      setValue(value + 1);
    } else {
      setErrorMsg("Please Check the form for errors");
      setOpenNotification(true);
    }
    setEmergencyForm(true);
  };

  const onSlipUpload = (event) => {
    var file = event.target.files[0];
    var fileType = file.name.slice(file.name.length - 3);
    let file_size = event.target.files[0].size;

    if (event.target.name === "idCardFront") {
      setIdCardFront(file.name);
      setFrontUpload(file);
      var frontReader = new FileReader();
      var url = frontReader.readAsDataURL(file);

      frontReader.onloadend = function() {
        if (file_size <= 1500000) {
          if (fileType === "png") {
            setFrontPreview([frontReader.result]);
          } else if (fileType === "PNG") {
            setFrontPreview([frontReader.result]);
          } else if (fileType === "peg") {
            setFrontPreview([frontReader.result]);
          } else if (fileType === "PEG") {
            setFrontPreview([frontReader.result]);
          } else if (fileType === "jpg") {
            setFrontPreview([frontReader.result]);
          } else if (fileType === "JPG") {
            setFrontPreview([frontReader.result]);
          } else {
            setErrorMsg("Only jpg, jpeg and png are be allowed");
            setOpenNotification(true);
          }
        } else {
          setErrorMsg("Files size should be less Than or Equal to 1.5MB");
          setOpenNotification(true);
        }
      };
    }

    if (event.target.name === "idCardBack") {
      setIdCardBack(file.name);
      setBackUpload(file);
      var backReader = new FileReader();
      var url = backReader.readAsDataURL(file);

      backReader.onloadend = function() {
        if (file_size <= 1500000) {
          if (fileType === "png") {
            setBackPreview([backReader.result]);
          } else if (fileType === "PNG") {
            setBackPreview([backReader.result]);
          } else if (fileType === "peg") {
            setBackPreview([backReader.result]);
          } else if (fileType === "PEG") {
            setBackPreview([backReader.result]);
          } else if (fileType === "jpg") {
            setBackPreview([backReader.result]);
          } else if (fileType === "JPG") {
            setBackPreview([backReader.result]);
          } else {
            setErrorMsg("Only jpg, jpeg and png are be allowed");
            setOpenNotification(true);
          }
        } else {
          setErrorMsg("Files size should be less Than or Equal to 1.5MB");
          setOpenNotification(true);
        }
      };
    }
  };

  const removeUploadedSlip = (e) => {
    if (e === "idCardFront") {
      var frontFileType = frontUpload.name.slice(frontUpload.name.length - 3);
      setFrontUpload("");
      setIdCardFront("");
      if (frontFileType === "png") {
        setFrontPreview("");
      } else if (frontFileType === "PNG") {
        setFrontPreview("");
      } else if (frontFileType === "peg") {
        setFrontPreview("");
      } else if (frontFileType === "PEG") {
        setFrontPreview("");
      } else if (frontFileType === "jpg") {
        setFrontPreview("");
      } else if (frontFileType === "JPG") {
        setFrontPreview("");
      } else {
        setErrorMsg("Cannot remove file");
        setOpenNotification(true);
      }
    }
    if (e === "idCardBack") {
      var backFileType = backUpload.name.slice(backUpload.name.length - 3);
      setBackUpload("");
      setIdCardBack("");
      if (backFileType === "png") {
        setBackPreview("");
      } else if (backFileType === "PNG") {
        setBackPreview("");
      } else if (backFileType === "peg") {
        setBackPreview("");
      } else if (backFileType === "PEG") {
        setBackPreview("");
      } else if (backFileType === "jpg") {
        setBackPreview("");
      } else if (backFileType === "JPG") {
        setBackPreview("");
      } else {
        setErrorMsg("Cannot remove file");
        setOpenNotification(true);
      }
    }
  };

  function validatePatientJohnDoeForm() {
    console.log('johnDoeCheck :: johnDoe', isJohnDoe);
    console.log('johnDoeCheck :: validation', name[0].given + " && " + //John
      name[0].given[0].length + " > 0 && " + // 4 > 0
      validateFirstName(name[0].given)+ " && " + // true
      name[0].family+ " && " + // Doe
      name[0].family.length+ " > 0 && " + // 17 > 0
      validateLastName(name[0].family)+ " && " + // true
      gender+ " && " + // Male
      gender.length + " > 0 && " + // 4 > 0
      validateGender(gender) + " && " + // true
      name[0].prefix + " && " + // mr
      name[0].prefix.length + " > 0 && " + // 2 > 0
      validateGender(name[0].prefix) // true
    );

    return (
      name[0].given &&
      name[0].given[0].length > 0 &&
      validateFirstName(name[0].given) &&
      name[0].family &&
      name[0].family.length > 0 &&
      validateLastName(name[0].family) &&
      gender &&
      gender.length > 0 &&
      validateGender(gender) &&
      name[0].prefix &&
      name[0].prefix.length > 0 &&
      validateGender(name[0].prefix)
    );
  }

  function validatePatientForm() {
    console.log('johnDoeCheck :: regular');
    return (
      nationalID &&
      nationalID.length > 0 &&
      validateNationalId(nationalID) &&
      name[0].given &&
      name[0].given[0].length > 0 &&
      validateFirstName(name[0].given) &&
      name[0].family &&
      name[0].family.length > 0 &&
      validateLastName(name[0].family) &&
      gender &&
      gender.length > 0 &&
      validateGender(gender) &&
      name[0].prefix &&
      name[0].prefix.length > 0 &&
      validateGender(name[0].prefix) &&
      validateEmail(telecomValue("email")) &&
      telecomValue("mobile") &&
      telecomValue("mobile").length > 0 &&
      !validatePhone(telecomValue("mobile"))
      // && telecomValue("phone") &&
      // telecomValue("phone").length > 0 &&
      // !validatePhone(telecomValue("phone")) &&
      // address[0].country &&
      // address[0].country.length > 0 &&
      // validateCountryCity(address[0].country) &&
      // address[0].city &&
      // address[0].city.length > 0 &&
      // validateCountryCity(address[0].city) &&
      // address[0].text &&
      // address[0].text.length > 0 &&
      // validateAddress(address[0].text) && 
      // otherDetails &&
      // otherDetails.length > 0
      // validateOtherDetails(otherDetails)
    );
  }

  function validateEmergencyForm() {
    return (
      contact[0].name &&
      contact[0].name.length > 0 &&
      validateEmergencyName(contact[0].name) &&
      contact[0].typeOfRelation &&
      contact[0].typeOfRelation.length > 0 &&
      validateRelation(contact[0].typeOfRelation)
    );
  }

  const handleChangeDate = (value) => {
    dispatch({ field: "birthDate", value: value.toISOString().substr(0, 10) });
    calculate_age(value.toISOString().substr(0, 10));
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  props.SaveData(saveData);

  const handleAdd = () => {
    let formData = new FormData();
    if (frontUpload) {
      formData.append("front", frontUpload, frontUpload.name);
    }
    if (backUpload) {
      formData.append("back", backUpload, backUpload.name);
    }

    const temp = [...time];
    temp[0].processEndTime = new Date();
    temp[0].processName =
      currentUser.staffType === "Sensei" ? "Sensei" : "Paramedics";
    temp[0].staffId = currentUser._id;
    setTime(temp);
    console.log("time", time);
    console.log("johnDoeCheck", time);
    console.log("johnDoeCheck", typeof(isJohnDoe));

    let validateValue;
    if (currentUser.staffType === "Sensei") {
      console.log("johnDoeCheck", isJohnDoe);
      console.log("johnDoeCheck", (isJohnDoe === true ? "validatePatientJohnDoeForm" : "validatePatientForm"));
      validateValue = (isJohnDoe === true ? validatePatientJohnDoeForm() : validatePatientForm());
    } else if (currentUser.staffType === "Paramedics") {
      validateValue = (isJohnDoe === true ? validatePatientJohnDoeForm() : validatePatientForm()) && validateEmergencyForm();
    }

    if (validateValue) {
      const params = {
        isJohnDoe,
        isChild,
        identityType,
        nationalID,
        name,
        gender,
        birthDate,
        age,
        height,
        weight,
        telecom,
        nationality,
        address,
        blood,
        otherDetails,
        contact,
        paymentMethod,
        coveredFamilyMember,
        idCardFront,
        idCardBack,
        time,
        registrationStatus: "pending",
      };
      formData.append("data", JSON.stringify(params));
      console.log("Params data ", params);
      console.log("FORM data ", formData);
      savePatientData(
        props.patientRegistration,
        formData,
        setSaveData,
        setPatientId,
        setMRN,
        setIsPatientSubmitted,
        setOpenNotification,
        setsuccessMsg,
        setErrorMsg
      );
    } else {
      setOpenNotification(true);
      setErrorMsg(
        "Please Fill the the empty fields with valid data / Please add payment method"
      );
    }
    setDetailsForm(true);
    setEmergencyForm(true);
  };

  const DetailsOnClick = () => {
    if (validatePatientForm()) {
      setValue(value + 1);
    } else {
      setErrorMsg("Please Check the form for errors");
      setOpenNotification(true);
    }
    setDetailsForm(true);
  };

  const handleEdit = () => {
    console.log("In edit");

    let formData = new FormData();
    if (frontUpload) {
      formData.append("front", frontUpload, frontUpload.name);
    }
    if (backUpload) {
      formData.append("back", backUpload, backUpload.name);
    }

    const temp = [...time];
    temp[0].processEndTime = new Date();
    temp[0].processName =
      currentUser.staffType === "Sensei" ? "Sensei" : "Paramedics";
    temp[0].staffId = currentUser._id;
    setTime(temp);

    let validateValue;
    if (currentUser.staffType === "Sensei") {
      validateValue = (isJohnDoe === true ? validatePatientJohnDoeForm() : validatePatientForm());
    } else if (currentUser.staffType === "Paramedics") {
      validateValue = (isJohnDoe === true ? validatePatientJohnDoeForm() : validatePatientForm()) && validateEmergencyForm();
    }
    if (validateValue) {
      const params = {
        _id: patientId,
        isJohnDoe,
        identityType,
        nationalID,
        name,
        gender,
        birthDate,
        age,
        height,
        weight,
        telecom,
        nationality,
        address,
        blood,
        otherDetails,
        photo,
        idCardFront,
        idCardBack,
        contact,
        paymentMethod,
        insuranceNumber,
        insuranceVendor,
        coverageTerms,
        coPayment,
        coveredFamilyMember,
        coverageDetails,
        insuranceCard,
        time,
        registrationStatus,
      };
      formData.append("data", JSON.stringify(params));
      console.log("Params data ", params);
      console.log("FORM data ", formData);
      editPatientData(
        props.updatePatient,
        formData,
        setSaveData,
        setPatientId,
        setMRN,
        setIsPatientSubmitted,
        setOpenNotification,
        setsuccessMsg,
        setErrorMsg
      );
    } else {
      setOpenNotification(true);
      setErrorMsg(
        "Please Fill the the empty fields with valid data / Please add payment method"
      );
    }
    setDetailsForm(true);
    setEmergencyForm(true);
  };

  // console.log("current user", currentUser);
  // console.log("props", props);

  const handleGenerateEDR = () => {
    let validateValue;
    if (currentUser.staffType === "Sensei") {
      validateValue = (isJohnDoe === true ? validatePatientJohnDoeForm() : validatePatientForm());
    } else if (currentUser.staffType === "Paramedics") {
      validateValue = (isJohnDoe === true ? validatePatientJohnDoeForm() : validatePatientForm()) && validateEmergencyForm();
    }
    if (validateValue && isPatientSubmitted) {
      const params = {
        patientId,
        generatedBy: currentUser._id,
        status: "pending",
        generatedFrom:
          currentUser.staffType === "Paramedics" ? "Paramedics" : "Sensei",
        patientInHospital:
          currentUser.staffType === "Paramedics" ? false : true,
      };
      console.log("PARAMS ARE : ", params);
      generateEDRecord(
        props,
        generateEDR,
        params,
        setsuccessMsg,
        setOpenNotification,
        setErrorMsg
      );
    } else {
      setOpenNotification(true);
      setErrorMsg("Please save the patient first");
    }
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      triggerChange();
    }
  };

  const triggerChange = (a) => {
    handleSearch(a);
  };

  const handlePauseSearch = (e) => {
    setLoadSearchedData(true);
    clearTimeout(timer);

    const a = e.target.value.replace(/[^\w+\s]/gi, "");
    setSearchQuery(a);

    setTimer(
      setTimeout(() => {
        triggerChange(a);
      }, 600)
    );
  };

  const handleSearch = (e) => {
    console.log("handleSearch", e.toString().length);
    if (e.toString().length >= 3) {
      patientSearch(
        e,
        props.SearchPatients,
        setItemFoundSuccessfully,
        setItemFound,
        setLoadSearchedData
      );
    }
  };

  const onTabNavigation = () => {
    value === 1
      ? setValue(0)
      : value === 2
      ? setValue(1)
      : props.history.goBack();
    // setValue(tabIndex);
  };

  function handleAddItem(item) {
    console.log("selected banda", item);
    addSearchedPatient(
      props.getPatientByIdUrl,
      item,
      setPatientId,
      setName,
      setIdCardBack,
      setIdCardFront,
      setAddress,
      setTelecom,
      setContact,
      setphoto,
      setCoveredFamilyMember,
      setpaymentMethod,
      setInsuranceCard,
      setenableForm,
      setInsuranceForm,
      setenableNext,
      setSearchQuery,
      setsearchActivated,
      countriesList,
      setCities,
      setOpenNotification,
      setErrorMsg,
      dispatch
    );
  }

  const onChangeBloodGroup = (e) => {
    dispatch({
      field: e.target.name,
      value: e.target.value,
    });
  };

  const onChangeIsChildValue = (e) => {
    dispatch({
      field: e.target.name,
      value: e.target.checked,
    });
    console.log({
      field: e.target.name,
      value: e.target.checked,
    })
  };

  const onChangeValue = (e) => {
    if (e.target.name === "isJohnDoe"){
      if (e.target.value === "true"){
        const list = [...name];
        list[0]['given'] = ["John"];
        list[0]['family'] = "Doe";// + Date.now()
        setName(list);
        dispatch({
          field: "isJohnDoe",
          value: true,
        });
      }else{
        dispatch({
          field: "isJohnDoe",
          value: false,
        });
      }
    }else{
      var heightWeightPattern = /^[0-9. ]*$/;
      if (e.target.name === "height" || e.target.name === "weight") {
        if (heightWeightPattern.test(e.target.value) === false) {
          return;
        }
      }
      if (e.target.name === "birthDate") {
        dispatch({
          field: e.target.name,
          value: e.target.value,
        });
      } else {
        dispatch({
          field: e.target.name,
          value: e.target.value.replace(/[^\w.'-\s]/gi, ""),
        });
      }

      if (e.target.name === "birthDate") {
        calculate_age(e.target.value);
      }
    }
  };

  const calculate_age = (birthDate) => {
    var today = new Date();
    var birthDate = new Date(birthDate);
    var age_now = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age_now--;
    }
    dispatch({ field: "age", value: age_now });
  };

  if (openNotification) {
    setTimeout(() => {
      setOpenNotification(false);
      setErrorMsg("");
      setsuccessMsg("");
    }, 2000);
  }

  function telecomValue(toBeDoneFor) {
    for (let i = 0; i < telecom.length; i++) {
      let v = telecom[i];
      if (v.system === toBeDoneFor) {
        if (v.value !== "") {
          return v.value;
        } else {
          return "";
        }
      }
    }
  }

  function scanQRCode() {
    setQRCodeScanner((pervState) => !pervState);
  }

  function handleScanQR(data) {
    setQRCodeScanner(false);
    console.log(JSON.parse(data));
    if (JSON.parse(data).profileNo) {
      handlePauseSearch({
        target: {
          value: JSON.parse(data).profileNo,
          type: "text",
        },
      });
    } else if (JSON.parse(data).insuranceCardNumber) {
      dispatch({
        field: "insuranceNo",
        value: JSON.parse(data).insuranceCardNumber,
      });
    }
  }

  if (QRCodeScanner) {
    return (
      <div>
        {QRCodeScanner ? (
          <QRCodeScannerComponent
            handleScanQR={handleScanQR}
            scanQRCode={scanQRCode}
          />
        ) : (
          undefined
        )}
      </div>
    );
  } else {
     // Sensi Patient Registration
    return (
      <div
        style={{
          backgroundColor: "rgb(19 213 159)",
          position: "fixed",
          display: "flex",
          width: "100%",
          height: "100%",
          flexDirection: "column",
          flex: 1,
          overflowY: "scroll",
          paddingBottom: 50,
        }}
      >
        <Header history={props.props.history} />
        <div className="cPadding">
          <div className="subheader" style={{ marginLeft: "-20px" }}>
            <div>
              <img src={patientRegister} />
              <div style={{ flex: 4, display: "flex", alignItems: "center" }}>
                <h4 style={{ color: "white", fontWeight: "700" }}>
                  Patient Registration <small>(Short Form)</small>
                </h4>
              </div>
            </div>
          </div>

          <div
            style={{ flex: 4, display: "flex", flexDirection: "column" }}
            className={`${"container-fluid"} ${classes.root}`}
          >
            {currentUser.staffType === "Paramedics" ? (
              <div className={classesForTabs.root}>
                <Tabs
                  classes={{
                    root: classesForTabs.root,
                    scroller: classesForTabs.scroller,
                  }}
                  value={value}
                  onChange={handleChange}
                  textColor="primary"
                  variant="scrollable"
                  TabIndicatorProps={{ style: { background: "#12387a" } }}
                  // centered
                >
                  <Tab
                    style={{
                      color: "white",
                      borderRadius: 5,
                      outline: "none",
                      color: value === 0 ? "#12387a" : "#3B988C",
                    }}
                    label="Patient Details"
                  />
                  <Tab
                    style={{
                      color: "white",
                      borderRadius: 5,
                      outline: "none",
                      color: value === 1 ? "#12387a" : "#3B988C",
                    }}
                    label="Emergency Contact"
                  />
                </Tabs>
              </div>
            ) : (
              undefined
            )}

            {value === 0 ? (
              <>
                <div
                  className="row"
                  style={{
                    marginBottom: 16,
                    marginTop: 10,
                  }}
                >
                  <div
                    className="col-md-10 col-sm-9 col-8"
                    style={{
                      ...styles.textFieldPadding,
                    }}
                  >
                    <TextField
                      type="text"
                      label={
                        matches
                          ? "Search Patient by Name / MRN / National ID / Passport # / Mobile Number"
                          : "Search Patient"
                      }
                      name={"searchQuery"}
                      value={searchQuery}
                      onChange={handlePauseSearch}
                      onKeyDown={handleKeyDown}
                      className="textInputStyle"
                      variant="filled"
                      InputLabelProps={{
                        classes: {
                          root: classes.label,
                          focused: classes.focusedLabel,
                          error: classes.erroredLabel,
                        },
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <AccountCircle />
                          </InputAdornment>
                        ),

                        className: classes.input,
                        classes: { input: classes.input },
                      }}
                    />
                  </div>

                  <div
                    className="col-md-1 col-sm-2 col-2"
                    style={{
                      ...styles.textFieldPadding,
                      paddingLeft: matches ? 5 : 0,
                      paddingRight: matches ? 5 : 1,
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: 55,
                        backgroundColor: "white",
                        borderRadius: 5,
                      }}
                    >
                      <img
                        src={BarCode}
                        onClick={scanQRCode}
                        style={{ width: 70, height: 60 }}
                      />
                    </div>
                  </div>

                  <div
                    className="col-md-1 col-sm-2 col-2"
                    style={{
                      ...styles.textFieldPadding,
                      paddingLeft: matches ? 5 : 2,
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "white",
                        borderRadius: 5,
                        height: 55,
                      }}
                    >
                      <img
                        src={Fingerprint}
                        style={{ maxWidth: 43, height: 43 }}
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div
                    className="col-md-10 col-sm-11 col-10"
                    style={{
                      ...styles.textFieldPadding,
                    }}
                  >
                    {searchQuery ? (
                      <div
                        style={{
                          zIndex: 3,
                          position: "absolute",
                          width: "99%",
                        }}
                      >
                        <Paper style={{ maxHeight: 300, overflow: "auto" }}>
                          {itemFoundSuccessfull && itemFound !== "" ? (
                            <Table size="small">
                              <TableHead>
                                <TableRow>
                                  <TableCell>Patient Id</TableCell>
                                  <TableCell>Patient Name</TableCell>
                                  <TableCell>Identification</TableCell>
                                  <TableCell>Gender</TableCell>
                                  <TableCell>Mobile No</TableCell>
                                  <TableCell>Age</TableCell>
                                </TableRow>
                              </TableHead>

                              <TableBody>
                                {itemFound.map((i) => {
                                  return (
                                    <TableRow
                                      key={i._id}
                                      onClick={() => handleAddItem(i)}
                                      style={{ cursor: "pointer" }}
                                    >
                                      <TableCell>
                                        {i.identifier[0].value}
                                      </TableCell>
                                      <TableCell>
                                        {i.name[0].given[0] +
                                          " " +
                                          i.name[0].family}
                                      </TableCell>
                                      <TableCell>{i.nationalID} - {
                                        (
                                          i.identityType === "national-id" ? "National ID":
                                            (
                                              i.identityType === "passport" ? "Passport":
                                                (
                                                  i.identityType === "driving-license" ? "Driving License":
                                                    (
                                                      i.identityType === "refugee" ? "Refugee #":"Other"
                                                    )
                                                )
                                            )
                                        )
                                      }</TableCell>
                                      <TableCell>{i.gender}</TableCell>
                                      <TableCell>{i.telecom.map((ix) => {
                                        if (ix.system === "mobile"){
                                          return ix.value;
                                        }
                                      })}</TableCell>
                                      <TableCell>{i.age}</TableCell>
                                    </TableRow>
                                  );
                                })}
                              </TableBody>
                            </Table>
                          ) : loadSearchedData ? (
                            <div style={{ textAlign: "center" }}>
                              <Loader
                                type="TailSpin"
                                color="#2c6ddd"
                                height={25}
                                width={25}
                                style={{
                                  display: "inline-block",
                                  padding: "10px",
                                }}
                              />
                              <span
                                style={{
                                  display: "inline-block",
                                  padding: "10px",
                                }}
                              >
                                <h4> Searching Patient...</h4>
                              </span>
                            </div>
                          ) : searchQuery && !itemFoundSuccessfull ? (
                            <div
                              style={{ textAlign: "center", padding: "10px" }}
                            >
                              <h4>No Patient Found !</h4>
                            </div>
                          ) : (
                            undefined
                          )}
                        </Paper>
                      </div>
                    ) : (
                      undefined
                    )}
                  </div>
                </div>

                <div
                  className="row"
                  style={{ marginTop: matches ? undefined : -15 }}
                >
                  <div
                    className="col-md-3 col-sm-3"
                    style={{
                      ...styles.inputContainerForTextField,
                      ...styles.textFieldPadding,
                      marginTop: matches ? 10 : 5,
                    }}
                  >
                    <TextField
                      required
                      label="Patient MRN"
                      name={"identifier"}
                      value={identifier[0].value}
                      disabled={true}
                      onChange={onChangeValue}
                      className="textInputStyle"
                      variant="filled"
                      InputProps={{
                        className: classes.input,
                        classes: { input: classes.input },
                      }}
                      InputLabelProps={{
                        className: classes.label,
                        classes: { label: classes.label },
                      }}
                    />
                  </div>
                  <div
                    className="col-md-2 col-sm-2"
                    style={{
                      ...styles.inputContainerForTextField,
                      ...styles.textFieldPadding,
                      marginTop: matches ? 10 : 5,
                    }}
                  >
                      <TextField
                        select
                        fullWidth
                        id="isJohnDoe"
                        name="isJohnDoe"
                        value={isJohnDoe}
                        onChange={onChangeValue}
                        label="John Doe Registration?"
                        variant="filled"
                        className="dropDownStyle"
                        InputProps={{
                          className: classes.input,
                          classes: { input: classes.input },
                        }}
                      >
                        <MenuItem key="true" value="true">Yes</MenuItem>
                        <MenuItem key="false" value="false">No</MenuItem>
                      </TextField>
                  </div>
                  <div
                    className="col-md-2 col-sm-2"
                    style={{
                      ...styles.inputContainerForTextField,
                      ...styles.textFieldPadding,
                      marginTop: matches ? 10 : 5,
                    }}
                  >
                      <TextField
                        select
                        fullWidth
                        id="identityType"
                        name="identityType"
                        value={identityType}
                        onChange={onChangeValue}
                        label="ID Document?"
                        variant="filled"
                        className="dropDownStyle"
                        InputProps={{
                          className: classes.input,
                          classes: { input: classes.input },
                        }}
                        disabled={ isJohnDoe }
                      >
                        <MenuItem key="national-id" value="national-id">National ID</MenuItem>
                        <MenuItem key="passport" value="passport">Passport</MenuItem>
                        <MenuItem key="driving-license" value="driving-license">Driving License</MenuItem>
                        <MenuItem key="refugee" value="refugee">Refugee #</MenuItem>
                        <MenuItem key="others" value="others">Others</MenuItem>
                      </TextField>
                  </div>
                  <div
                    className="col-md-5 col-sm-5"
                    style={{
                      ...styles.inputContainerForTextField,
                      ...styles.textFieldPadding,
                      marginTop: matches ? 10 : 7,
                    }}
                  >
                    <TextField
                      required
                      type="number"
                      label={
                      identityType=="passport"?
                        "Passport Number" : (
                          identityType=="national-id" ? 
                            "National ID Number" : 
                            identityType=="driving-license" ? 
                            "Driving License Number" :
                              identityType=="refugee" ? 
                                "Refugee #" :
                                "Other Number"
                        )
                      }
                      name={"nationalID"} // now Identity
                      value={nationalID}
                      error={nationalID === "" && detailsForm && isJohnDoe === false}
                      onChange={onChangeValue}
                      className="textInputStyle"
                      variant="filled"
                      InputProps={{
                        className: classes.input,
                        classes: { input: classes.input },
                      }}
                      disabled={ isJohnDoe }
                    />
                    <ErrorMessage
                      name={nationalID}
                      type="nationalId"
                      isFormSubmitted={detailsForm && isJohnDoe === false}
                    />
                  </div>
                </div>
                {name.map((x, i) => {
                  return (
                    <div
                      className="row"
                      style={{ marginTop: matches ? undefined : -20 }}
                    >
                      <div
                        className="col-md-2 col-sm-2"
                        style={{
                          ...styles.inputContainerForTextField,
                          ...styles.textFieldPadding,
                          paddingRight: 5,
                        }}
                      >
                        <TextField
                          required
                          select
                          fullWidth
                          name="prefix"
                          label="Prefix"
                          value={x.prefix}
                          error={x.prefix === "" && detailsForm}
                          onChange={(e) => handleNameChange(e, i)}
                          label="Title"
                          variant="filled"
                          className="textInputStyle"
                          InputProps={{
                            className: classes.input,
                            classes: { input: classes.input },
                          }}
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>

                          {titles.map((val) => {
                            return (
                              <MenuItem key={val.key} value={val.key}>
                                {val.value}
                              </MenuItem>
                            );
                          })}
                        </TextField>
                        <ErrorMessage
                          name={x.prefix}
                          type="title"
                          isFormSubmitted={detailsForm}
                        />
                      </div>
                      <div
                        className="col-md-5 col-sm-5"
                        style={{
                          ...styles.inputContainerForTextField,
                          ...styles.textFieldPadding,
                          fontSize: matches ? 10 : 17,
                          marginTop: !matches ? -9 : 10,
                        }}
                      >
                        <TextField
                          required
                          label="First Name"
                          name={"given"}
                          value={name[i].given[0]}
                          onChange={(e) => handleNameChange(e, i)}
                          error={name[i].given[0] === "" && detailsForm}
                          className="textInputStyle"
                          variant="filled"
                          InputProps={{
                            className: classes.input,
                            classes: { input: classes.input },
                          }}
                          inputProps={{ maxLength: 40 }}
                        />
                        <ErrorMessage
                          name={name[i].given[0]}
                          type="firstName"
                          isFormSubmitted={detailsForm}
                        />
                      </div>
                      <div
                        className="col-md-5 col-sm-5"
                        style={{
                          ...styles.inputContainerForTextField,
                          ...styles.textFieldPadding,
                          marginTop: matches ? 10 : -10,
                        }}
                      >
                        <TextField
                          required
                          label="Last Name"
                          name={"family"}
                          value={x.family}
                          onChange={(e) => handleNameChange(e, i)}
                          error={x.family === "" && detailsForm}
                          className="textInputStyle"
                          variant="filled"
                          InputProps={{
                            className: classes.input,
                            classes: { input: classes.input },
                          }}
                          inputProps={{ maxLength: 40 }}
                        />
                        <ErrorMessage
                          name={x.family}
                          type="lastName"
                          isFormSubmitted={detailsForm}
                        />
                      </div>
                    </div>
                  );
                })}

                <div
                  className="row"
                  style={{ marginTop: matches ? undefined : -20 }}
                >
                  <div
                    className="col-md-2 col-sm-2 col-6"
                    style={{
                      ...styles.inputContainerForTextField,
                      ...styles.textFieldPadding,
                    }}
                  >
                    <TextField
                      required
                      select
                      fullWidth
                      id="gender"
                      name="gender"
                      value={gender}
                      error={gender === "" && detailsForm}
                      onChange={onChangeValue}
                      label="Gender"
                      variant="filled"
                      className="dropDownStyle"
                      InputProps={{
                        className: classes.input,
                        classes: { input: classes.input },
                      }}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>

                      {genderArray.map((val) => {
                        return (
                          <MenuItem
                            key={
                              val.key.charAt(0).toUpperCase() +
                              val.value.slice(1)
                            }
                            value={
                              val.key.charAt(0).toUpperCase() +
                              val.value.slice(1)
                            }
                          >
                            {val.value.charAt(0).toUpperCase() +
                              val.value.slice(1)}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                    <ErrorMessage
                      name={gender}
                      type="gender"
                      isFormSubmitted={detailsForm}
                    />
                  </div>


                  <div
                    className="col-md-2 col-sm-2 col-6"
                    style={{
                      ...styles.inputContainerForTextField,
                      ...styles.textFieldPadding,
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flex: 1,
                        justifyContent: "flex-start",
                        alignItems: 'center',
                        backgroundColor: '#FFF',
                        borderRadius: '5px',
                        padding: '15px 10px 6px',
                      }}
                    >
                      <Checkbox
                        name="isChild"
                        checked={isChild}
                        value={true}
                        onChange={(e) => onChangeIsChildValue(e)}
                        color="default"
                        style={{ marginTop: -7 }}
                        inputProps={{
                          "aria-label": "checkbox with default color",
                        }}
                      />
                      <h6 style={{ fontWeight: "bold", color: "grey", marginTop: 0 }}>
                        Child
                      </h6>
                    </div>
                  </div>

                  <div
                    className="col-md-4 col-sm-4 col-6"
                    style={{
                      ...styles.inputContainerForTextField,
                      ...styles.textFieldPadding,
                      paddingLeft: matches ? 5 : 0,
                    }}
                  >
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DatePicker
                        // required
                        inputVariant="filled"
                        fullWidth={true}
                        label="Date of birth"
                        format="dd - MM - yyyy"
                        maxDate={new Date()}
                        onChange={(val) => handleChangeDate(val, "birthDate")}
                        InputProps={{
                          className: classes.input,
                          classes: { input: classes.input },
                        }}
                        style={{ borderRadius: "10px" }}
                        value={birthDate}
                        disabled={ isJohnDoe }
                      />
                    </MuiPickersUtilsProvider>
                  </div>

                  <div
                    className="col-md-4 col-sm-4 col-12"
                    style={{
                      ...styles.inputContainerForTextField,
                      ...styles.textFieldPadding,
                      marginTop: matches ? 10 : -10,
                    }}
                  >
                    <TextField
                      // required
                      type="text"
                      select
                      label="Nationality"
                      name={"nationality"}
                      value={!nationality ? "Jordan" : nationality}
                      onChange={(e) => onChangeValue(e)}
                      className="textInputStyle"
                      variant="filled"
                      InputProps={{
                        className: classes.input,
                        classes: { input: classes.input },
                      }}
                      disabled={ isJohnDoe }
                    >
                      <MenuItem value="Jordan">
                        <em>None</em>
                      </MenuItem>

                      {countries &&
                        countries.map((val) => {
                          return (
                            <MenuItem key={val} value={val}>
                              {val}
                            </MenuItem>
                          );
                        })}
                    </TextField>
                  </div>
                </div>

                <div
                  className="row"
                  style={{ marginTop: matches ? undefined : -4 }}
                >
                  <div
                    className="col-md-3 col-sm-3 col-6"
                    style={{
                      ...styles.inputContainerForTextField,
                      ...styles.textFieldPadding,
                    }}
                  >
                    <TextField
                      disabled
                      label="Age"
                      name={"age"}
                      value={age}
                      onChange={onChangeValue}
                      className="textInputStyle"
                      variant="filled"
                      InputProps={{
                        className: classes.input,
                        classes: { input: classes.input },
                      }}
                    />
                  </div>
                  <div
                    className="col-md-3 col-sm-3 col-6"
                    style={{
                      ...styles.inputContainerForTextField,
                      ...styles.textFieldPadding,
                      paddingLeft: matches ? 5 : 0,
                    }}
                  >
                    <TextField
                      label="Height"
                      id="height"
                      name={"height"}
                      value={height}
                      onChange={onChangeValue}
                      className="textInputStyle"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start">cm</InputAdornment>
                        ),
                        className: classes.input,
                        classes: { input: classes.input },
                      }}
                      inputProps={{ maxLength: 4 }}
                      variant="filled"
                    />
                  </div>
                  <div
                    className="col-md-3 col-sm-3 col-6"
                    style={{
                      ...styles.inputContainerForTextField,
                      ...styles.textFieldPadding,
                      // marginTop: matches ? " " : 20,
                      marginTop: matches ? 10 : 5,
                    }}
                  >
                    <TextField
                      label="Weight"
                      id="weight"
                      name={"weight"}
                      value={weight}
                      onChange={onChangeValue}
                      className="textInputStyle"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start">Kg</InputAdornment>
                        ),
                        className: classes.input,
                        classes: { input: classes.input },
                      }}
                      inputProps={{ maxLength: 4 }}
                      variant="filled"
                    />
                  </div>

                  <div
                    className="col-md-3 col-sm-3 col-6"
                    style={{
                      ...styles.inputContainerForTextField,
                      ...styles.textFieldPadding,
                      // marginTop: matches ? " " : 20,
                      marginTop: matches ? 10 : 5,
                      paddingLeft: matches ? 5 : 0,
                    }}
                  >
                    <TextField
                      select
                      fullWidth
                      id="blood"
                      name="blood"
                      value={blood}
                      onChange={onChangeBloodGroup}
                      // error={blood === '' && isFormSubmitted}
                      label="Blood Group"
                      variant="filled"
                      className="dropDownStyle"
                      InputProps={{
                        className: classes.input,
                        classes: { input: classes.input },
                      }}
                    >
                      <MenuItem value={blood}>{blood}</MenuItem>

                      {bloodGroups.map((val) => {
                        return (
                          <MenuItem key={val.key} value={val.key}>
                            {val.value}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                  </div>
                </div>

                <div className="row" style={{ marginTop: matches ? 15 : -4 }}>
                  <div
                    className="col-md-4 col-sm-4"
                    style={{
                      ...styles.inputContainerForTextField,
                      ...styles.textFieldPadding,
                    }}
                  >
                    <TextField
                      // required
                      label="Email"
                      name="email"
                      value={telecomValue("email")}
                      onChange={(e) => handleTelecomChange(e)}
                      className="textInputStyle"
                      variant="filled"
                      InputProps={{
                        className: classes.input,
                        classes: { input: classes.input },
                      }}
                      disabled={ isJohnDoe }
                    />
                    <ErrorMessage
                      name={telecomValue("email")}
                      type="email"
                      isFormSubmitted={detailsForm}
                    />
                  </div>

                  <div
                    className="col-md-4 col-sm-4"
                    style={{
                      ...styles.inputContainerForTextField,
                      ...styles.textFieldPadding,
                      marginTop: matches ? 10 : -10,
                    }}
                  >
                    <MuiPhoneNumber
                      required
                      label="Mobile Number"
                      name="mobile"
                      value={telecomValue("mobile")}
                      error={telecomValue("mobile") === "" && detailsForm && isJohnDoe === false}
                      defaultCountry={"jo"}
                      onChange={(e) => handleTelecomChange(e, "mobile")}
                      className="textInputStyle"
                      variant="filled"
                      InputProps={{
                        className: classes.input,
                        classes: { input: classes.input },
                      }}
                      InputLabelProps={{
                        className: classes.label,
                        classes: { label: classes.label },
                      }}
                      disabled={ isJohnDoe }
                    />
                    {telecomValue("mobile") &&
                    !validatePhone(telecomValue("mobile")) ? (
                      undefined
                    ) : (
                      <ErrorMessage
                        name={telecomValue("mobile")}
                        type="phone"
                        isFormSubmitted={detailsForm && isJohnDoe === false}
                      />
                    )}
                  </div>

                  <div
                    className="col-md-4 col-sm-4"
                    style={{
                      ...styles.inputContainerForTextField,
                      ...styles.textFieldPadding,
                      marginTop: matches
                        ? 10
                        : validatePhone(telecomValue("mobile")) === false
                        ? 5
                        : -10,
                    }}
                  >
                    <MuiPhoneNumber
                      label="Telephone Number"
                      name="value"
                      value={telecomValue("phone")}
                      error={telecomValue("phone") !== "" && detailsForm && isJohnDoe === false}
                      defaultCountry={"jo"}
                      onChange={(e) => handleTelecomChange(e, "phone")}
                      className="textInputStyle"
                      variant="filled"
                      InputProps={{
                        className: classes.input,
                        classes: { input: classes.input },
                      }}
                      InputLabelProps={{
                        className: classes.label,
                        classes: { label: classes.label },
                      }}
                      disabled={ isJohnDoe }
                    />
                    {telecomValue("phone") &&
                    !validatePhone(telecomValue("phone")) ? (
                      undefined
                    ) : (
                      telecomValue("phone") !== ""?
                      <ErrorMessage
                        name={telecomValue("phone")}
                        type="phone"
                        isFormSubmitted={detailsForm && isJohnDoe === false}
                      />:undefined
                    )}
                  </div>
                </div>

                {address.map((x, i) => {
                  return (
                    <>
                      <div
                        className="row"
                        style={{
                          // marginTop: matches ? undefined : -5,
                          marginTop: matches
                            ? 5
                            : validatePhone(telecomValue("phone")) === false
                            ? -4
                            : -20,
                        }}
                      >
                        <div
                          className="col-md-4"
                          style={{
                            ...styles.inputContainerForTextField,
                            ...styles.textFieldPadding,
                          }}
                        >
                          <TextField
                            // required
                            label="Address"
                            name={"text"}
                            value={x.text}
                            // error={x.text === "" && detailsForm && isJohnDoe === false}
                            onChange={(e) => handleAddressChange(e, i)}
                            className="textInputStyle"
                            variant="filled"
                            InputProps={{
                              className: classes.input,
                              classes: { input: classes.input },
                            }}
                            InputLabelProps={{
                              className: classes.label,
                              classes: { label: classes.label },
                            }}
                            disabled={ isJohnDoe }
                          />
                          {
                            x.text &&
                            x.text.length > 0 ? (
                              <ErrorMessage
                                name={x.text}
                                type="address"
                                isFormSubmitted={detailsForm && isJohnDoe === false}
                              />
                            ) : undefined
                          }
                        </div>

                        <div
                          className="col-md-4"
                          style={{
                            ...styles.inputContainerForTextField,
                            ...styles.textFieldPadding,
                            marginTop: matches ? 10 : -10,
                          }}
                        >
                          <TextField
                            // required
                            select
                            fullWidth
                            id="country"
                            name="country"
                            value={x.country}
                            // error={x.country === "" && detailsForm && isJohnDoe === false}
                            onChange={(e) => handleAddressChange(e, i)}
                            label="Country"
                            variant="filled"
                            className="dropDownStyle"
                            InputProps={{
                              className: classes.input,
                              classes: { input: classes.input },
                            }}
                            disabled={ isJohnDoe }
                          >
                            <MenuItem value="">
                              <em>None</em>
                            </MenuItem>

                            {countries &&
                              countries.map((val) => {
                                return (
                                  <MenuItem key={val} value={val}>
                                    {val}
                                  </MenuItem>
                                );
                              })}
                          </TextField>
                          {
                            x.country &&
                            x.country.length > 0 ? (
                              <ErrorMessage
                                name={x.country}
                                type="country"
                                isFormSubmitted={detailsForm && isJohnDoe === false}
                              />
                            ) : undefined
                          }
                        </div>

                        <div
                          className="col-md-4"
                          style={{
                            ...styles.inputContainerForTextField,
                            ...styles.textFieldPadding,
                            marginTop: matches ? 10 : -10,
                            paddingLeft: 5,
                          }}
                        >
                          <TextField
                            // required
                            select
                            fullWidth
                            id="city"
                            name="city"
                            value={x.city}
                            onChange={(e) => handleAddressChange(e, i)}
                            label="City"
                            // error={x.city === "" && detailsForm && isJohnDoe === false}
                            variant="filled"
                            className="dropDownStyle"
                            InputProps={{
                              className: classes.input,
                              classes: { input: classes.input },
                            }}
                            disabled={ isJohnDoe }
                          >
                            {cities &&
                              cities.map((val) => {
                                return (
                                  <MenuItem key={val} value={val}>
                                    {val}
                                  </MenuItem>
                                );
                              })}
                            {/* <MenuItem value='Other'>Other</MenuItem> */}
                          </TextField>
                          {
                            x.city &&
                            x.city.length > 0 ? (
                              <ErrorMessage
                                name={x.city}
                                type="city"
                                isFormSubmitted={detailsForm && isJohnDoe === false}
                              />
                            ) : undefined
                          }
                        </div>
                      </div>

                      {/* {cityBoolean ? (
              <div className='row'>
                <div
                  className='col-md-12 col-sm-12'
                  style={{
                    ...styles.inputContainerForTextField,
                    ...styles.textFieldPadding,
                    marginBottom: 16,
                  }}
                >
                  <TextField
                    fullWidth
                    id='otherCity'
                    name='type'
                    value={x.type}
                    onChange={(e) => handleAddressChange(e, i)}
                    label='Other City/ Village/ Town'
                    variant='filled'
                    className='dropDownStyle'
                    InputProps={{
                      className: classes.input,
                      classes: { input: classes.input },
                    }}
                  />
                </div>
              </div>
            ) : (
              undefined
            )} */}
                    </>
                  );
                })}

                <div
                  className="row"
                  style={{ marginTop: matches ? undefined : -20 }}
                >
                  <div
                    className="col-md-6 col-sm-6 col-6"
                    style={{
                      ...styles.inputContainerForTextField,
                      ...styles.textFieldPadding,
                    }}
                  >
                    <label
                      style={{ ...styles.upload, fontSize: 15, height: 55 }}
                    >
                      <TextField
                        required
                        type="file"
                        style={styles.input}
                        onChange={onSlipUpload}
                        onClick={(event) => {
                          event.target.value = null;
                        }}
                        name="idCardFront"
                        Error={errorMsg}
                        disabled={ isJohnDoe }
                      />
                      <FaUpload /> {matches ? <>&nbsp;&nbsp;</> : ""} ID Card
                      (Front)
                    </label>
                  </div>

                  <div
                    className="col-md-6 col-sm-6 col-6"
                    style={{
                      ...styles.inputContainerForTextField,
                      ...styles.textFieldPadding,
                      // paddingLeft: !matches ? 6 : "",
                    }}
                  >
                    <label
                      style={{
                        ...styles.upload,
                        fontSize: 15,
                        height: 55,
                      }}
                    >
                      <TextField
                        required
                        type="file"
                        style={styles.input}
                        onChange={onSlipUpload}
                        onClick={(event) => {
                          event.target.value = null;
                        }}
                        name="idCardBack"
                        Error={errorMsg}
                        disabled={ isJohnDoe }
                      />
                      <FaUpload /> {matches ? <>&nbsp;&nbsp;</> : ""} ID Card
                      (Back)
                    </label>
                  </div>
                </div>

                <div className="row">
                  {idCardFront.length > 0 && idCardFront.includes("\\") ? (
                    <>
                      {idCardFront.length > 0 &&
                      idCardFront.slice(idCardFront.length - 3) !== "pdf" ? (
                        <div
                          className="col-md-6 col-sm-6 col-6"
                          style={{
                            ...styles.inputContainerForTextField,
                            ...styles.textFieldPadding,
                          }}
                        >
                          <img
                            src={uploadsUrl + idCardFront}
                            className="depositSlipImg"
                          />
                          <div className="row">
                            <div className="col-md-6 col-sm-6 col-12">
                              <img
                                src={CloseIcon}
                                onClick={() => setIdCardFront("")}
                                //  className="preview-image depositSlipImg"
                                style={{
                                  width: matches ? undefined : 20,
                                  height: matches ? undefined : 20,
                                  marginTop: matches ? -404 : -207,
                                  marginLeft: matches ? 250 : 72,
                                }}
                              />
                              <div
                                style={{
                                  marginTop: matches ? -20 : -30,
                                  textAlign: matches ? "center" : "left",
                                  color: "grey",
                                  fontSize: matches ? "" : 14,
                                  fontWeight: 500,
                                }}
                              >
                                ID Card (Front)
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        undefined
                      )}
                    </>
                  ) : idCardFront.length > 0 && idCardFront.includes("/") ? (
                    <>
                      {idCardFront.length > 0 &&
                      idCardFront.slice(idCardFront.length - 3) !== "pdf" ? (
                        <div
                          className="col-md-6 col-sm-6 col-6"
                          style={{
                            ...styles.inputContainerForTextField,
                            ...styles.textFieldPadding,
                          }}
                        >
                          <img
                            src={uploadsUrl + idCardFront}
                            className="depositSlipImg"
                          />
                          <div className="row">
                            <div className="col-md-6 col-sm-6 col-12">
                              <img
                                src={CloseIcon}
                                onClick={() => setIdCardFront("")}
                                //  className="preview-image depositSlipImg"
                                style={{
                                  width: matches ? undefined : 20,
                                  height: matches ? undefined : 20,
                                  marginTop: matches ? -404 : -207,
                                  marginLeft: matches ? 250 : 72,
                                }}
                              />
                              <div
                                style={{
                                  marginTop: matches ? -20 : -30,
                                  textAlign: matches ? "center" : "left",
                                  color: "grey",
                                  fontSize: matches ? "" : 14,
                                  fontWeight: 500,
                                }}
                              >
                                ID Card (Front)
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        undefined
                      )}
                    </>
                  ) : (
                    undefined
                  )}

                  {frontPreview !== "" ? (
                    <div
                      className="col-md-6 col-sm-6 col-6"
                      style={{
                        ...styles.inputContainerForTextField,
                        ...styles.textFieldPadding,
                      }}
                    >
                      <img src={frontPreview} className="depositSlipImg" />
                      <div className="row">
                        <div className="col-md-6 col-sm-6 col-6">
                          <img
                            src={CloseIcon}
                            onClick={() => removeUploadedSlip("idCardFront")}
                            //  className="preview-image depositSlipImg"
                            style={{
                              width: matches ? undefined : 20,
                              height: matches ? undefined : 20,
                              marginTop: matches ? -404 : -330,
                              marginLeft: matches ? 250 : 130,
                            }}
                          />
                          {idCardFront !== "" ? (
                            <div
                              style={{
                                marginTop: matches ? -20 : -30,
                                textAlign: matches ? "center" : "left",
                                color: "grey",
                                fontSize: matches ? "" : 14,
                                fontWeight: 500,
                              }}
                            >
                              Frond Side
                            </div>
                          ) : (
                            undefined
                          )}
                        </div>
                      </div>
                    </div>
                  ) : (idCardFront.length > 0 && idCardFront.includes("\\")) ||
                    (idCardFront.length > 0 && idCardFront.includes("/")) ? (
                    undefined
                  ) : (
                    <div className="col-md-6 col-sm-6 col-6"></div>
                  )}

                  {idCardBack.length > 0 && idCardBack.includes("\\") ? (
                    <>
                      {idCardBack.length > 0 &&
                      idCardBack.slice(idCardBack.length - 3) !== "pdf" ? (
                        <div
                          className="col-md-6 col-sm-6 col-6"
                          style={{
                            ...styles.inputContainerForTextField,
                            ...styles.textFieldPadding,
                          }}
                        >
                          <img
                            src={uploadsUrl + idCardBack}
                            className="depositSlipImg"
                          />
                          <div className="row">
                            <div className="col-md-6 col-sm-6 col-12">
                              <img
                                src={CloseIcon}
                                onClick={() => setIdCardBack("")}
                                //  className="preview-image depositSlipImg"
                                style={{
                                  width: matches ? undefined : 20,
                                  height: matches ? undefined : 20,
                                  marginTop: matches ? -404 : -207,
                                  marginLeft: matches ? 250 : 72,
                                }}
                              />
                              {idCardBack !== "" ? (
                                <div
                                  style={{
                                    marginTop: matches ? -20 : -30,
                                    textAlign: matches ? "center" : "left",
                                    color: "grey",
                                    fontSize: matches ? "" : 14,
                                    fontWeight: 500,
                                  }}
                                >
                                  ID Card (Back)
                                </div>
                              ) : (
                                undefined
                              )}
                            </div>
                          </div>
                        </div>
                      ) : (
                        undefined
                      )}
                    </>
                  ) : idCardBack.length > 0 && idCardBack.includes("/") ? (
                    <>
                      {idCardBack.length > 0 &&
                      idCardBack.slice(idCardBack.length - 3) !== "pdf" ? (
                        <div
                          className="col-md-6 col-sm-6 col-6"
                          style={{
                            ...styles.inputContainerForTextField,
                            ...styles.textFieldPadding,
                          }}
                        >
                          <img
                            src={uploadsUrl + idCardBack}
                            className="depositSlipImg"
                          />
                          <div className="row">
                            <div className="col-md-6 col-sm-6 col-12">
                              <img
                                src={CloseIcon}
                                onClick={() => setIdCardBack("")}
                                //  className="preview-image depositSlipImg"
                                style={{
                                  width: matches ? undefined : 20,
                                  height: matches ? undefined : 20,
                                  marginTop: matches ? -404 : -207,
                                  marginLeft: matches ? 250 : 72,
                                }}
                              />
                              {idCardBack !== "" ? (
                                <div
                                  style={{
                                    marginTop: matches ? -20 : -30,
                                    textAlign: matches ? "center" : "left",
                                    color: "grey",
                                    fontSize: matches ? "" : 14,
                                    fontWeight: 500,
                                  }}
                                >
                                  ID Card (Back)
                                </div>
                              ) : (
                                undefined
                              )}
                            </div>
                          </div>
                        </div>
                      ) : (
                        undefined
                      )}
                    </>
                  ) : (
                    undefined
                  )}

                  {backPreview !== "" ? (
                    <div
                      className="col-md-6 col-sm-6 col-6"
                      style={{
                        ...styles.inputContainerForTextField,
                        ...styles.textFieldPadding,
                      }}
                    >
                      <img src={backPreview} className="depositSlipImg" />
                      <div className="row">
                        <div className="col-md-6 col-sm-6 col-6">
                          <img
                            src={CloseIcon}
                            onClick={() => removeUploadedSlip("idCardBack")}
                            //  className="preview-image depositSlipImg"
                            style={{
                              width: matches ? undefined : 20,
                              height: matches ? undefined : 20,
                              marginTop: matches ? -404 : -330,
                              marginLeft: matches ? 250 : 130,
                            }}
                          />
                          {idCardBack !== "" ? (
                            <div
                              style={{
                                marginTop: matches ? -20 : -30,
                                textAlign: matches ? "center" : "left",
                                color: "grey",
                                fontSize: matches ? "" : 14,
                                fontWeight: 500,
                              }}
                            >
                              Back Side
                            </div>
                          ) : (
                            undefined
                          )}
                        </div>
                      </div>
                    </div>
                  ) : (idCardBack.length > 0 && idCardBack.includes("\\")) ||
                    (idCardBack.length > 0 && idCardBack.includes("/")) ? (
                    undefined
                  ) : (
                    <div className="col-md-6 col-sm-6 col-6"></div>
                  )}
                </div>

                <div
                  className="row"
                  style={{ marginTop: matches ? 8 : value !== 3 ? -10 : -5 }}
                >
                  <div
                    className="col-md-12"
                    style={{
                      ...styles.inputContainerForTextField,
                      ...styles.textFieldPadding,
                    }}
                  >
                    <TextField
                      multiline
                      label="Other Details"
                      name={"otherDetails"}
                      value={otherDetails}
                      /*error={otherDetails === "" && detailsForm}*/
                      onChange={onChangeValue}
                      rows={4}
                      className="textInputStyle"
                      variant="filled"
                      InputProps={{
                        className: classes.multilineColor,
                        classes: {
                          input: classes.multilineColor,
                        },
                      }}
                    />
                    {/*
                    <ErrorMessage
                      name={otherDetails}
                      type="textField"
                      isFormSubmitted={detailsForm}
                    />*/ }
                  </div>
                </div>

                <div
                  class="row"
                  style={{
                    display: "flex",
                    flex: 1,
                    justifyContent: "center",
                    paddingLeft: 6,
                    paddingRight: 6,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flex: 1,
                      justifyContent: "flex",
                      marginTop: "1%",
                      marginBottom: "1%",
                    }}
                  >
                    <Button
                      style={{ ...styles.stylesForButton }}
                      onClick={() => props.props.history.goBack()}
                      variant="contained"
                      color="default"
                    >
                      Cancel
                    </Button>
                  </div>

                  {currentUser.staffType === "Sensei" ? (
                    <div
                      style={{
                        display: "flex",
                        flex: 1,
                        justifyContent: "flex-end",
                        marginTop: "1%",
                        marginBottom: "1%",
                      }}
                    >
                      <Button
                        style={
                          matches
                            ? { ...styles.save }
                            : { ...styles.mobileSave }
                        }
                        onClick={searchActivated ? handleEdit : handleAdd}
                        variant="contained"
                        color="default"
                      >
                        Save
                      </Button>
                      &nbsp; &nbsp;
                      <Button
                        style={
                          matches
                            ? { ...styles.generate }
                            : { ...styles.mobileGenerate }
                        }
                        onClick={handleGenerateEDR}
                        variant="contained"
                        color="primary"
                      >
                        {"Generate ED Record"}
                      </Button>
                    </div>
                  ) : (
                    <Button
                      style={{
                        ...styles.stylesForButton,
                        marginTop: "1%",
                        marginBottom: "1%",
                      }}
                      onClick={DetailsOnClick}
                      variant="contained"
                      color="primary"
                    >
                      Next
                    </Button>
                  )}
                </div>
              </>
            ) : value === 1 ? (
              <div
                style={{
                  flex: 4,
                  display: "flex",
                  flexDirection: "column",
                }}
                className={`${"container-fluid"} ${classes.root}`}
              >
                {contact.map((x, i) => {
                  return (
                    <>
                      <div className="row">
                        <div
                          className="col-md-12"
                          style={{
                            ...styles.inputContainerForTextField,
                            ...styles.textFieldPadding,
                          }}
                        >
                          <div>
                            <TextField
                              required
                              label="Name"
                              name="name"
                              value={x.name}
                              onChange={(e) => handleContactChange(e, i)}
                              error={x.name === "" && emergencyForm}
                              className="textInputStyle"
                              variant="filled"
                              InputProps={{
                                className: classes.input,
                                classes: { input: classes.input },
                              }}
                              inputProps={{ maxLength: 80 }}
                            />
                            <ErrorMessage
                              name={x.name}
                              type="emergencyName"
                              isFormSubmitted={emergencyForm}
                            />
                          </div>
                        </div>
                      </div>

                      <div
                        className="row"
                        style={{
                          marginBottom: 15,
                          marginTop: matches ? undefined : -20,
                        }}
                      >
                        <div
                          className="col-md-12"
                          style={{
                            ...styles.inputContainerForTextField,
                            ...styles.textFieldPadding,
                          }}
                        >
                          <div>
                            <MuiPhoneNumber
                              // required
                              label="Contact"
                              name="mobile"
                              value={contact[i].telecom[0].value}
                              // value={contact[i].telecomValue('mobile')}
                              defaultCountry={"jo"}
                              onChange={(e) => handleContactTelecomChange(e, 0)}
                              // onChange={(e) =>
                              //   handleContactTelecomChange(e, 'mobile')
                              // }
                              className="textInputStyle"
                              variant="filled"
                              InputProps={{
                                className: classes.input,
                                classes: { input: classes.input },
                              }}
                              InputLabelProps={{
                                className: classes.label,
                                classes: { label: classes.label },
                              }}
                            />
                          </div>
                        </div>
                      </div>

                      <div
                        className="row"
                        style={{ marginTop: matches ? undefined : -20 }}
                      >
                        <div
                          className="col-md-12"
                          style={{
                            ...styles.inputContainerForTextField,
                            ...styles.textFieldPadding,
                          }}
                        >
                          <div>
                            <TextField
                              required
                              select
                              label="Relation"
                              name="typeOfRelation"
                              value={x.typeOfRelation}
                              onChange={(e) => handleContactChange(e, i)}
                              error={x.typeOfRelation === "" && emergencyForm}
                              className="textInputStyle"
                              variant="filled"
                              InputProps={{
                                className: classes.input,
                                classes: { input: classes.input },
                              }}
                            >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>

                              {relationArray.map((val) => {
                                return (
                                  <MenuItem key={val.key} value={val.key}>
                                    {val.value}
                                  </MenuItem>
                                );
                              })}
                            </TextField>
                            <ErrorMessage
                              name={x.typeOfRelation}
                              type="relation"
                              isFormSubmitted={emergencyForm}
                            />
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}

                <div
                  style={{ display: "flex", flex: 1, justifyContent: "center" }}
                  class="row"
                >
                  <div
                    style={{
                      display: "flex",
                      flex: 1,
                      paddingLeft: 6,
                      justifyContent: "flex",
                      marginTop: "1%",
                      marginBottom: "2%",
                    }}
                  >
                    <Button
                      style={styles.stylesForButton}
                      onClick={onTabNavigation}
                      variant="contained"
                      color="default"
                    >
                      Cancel
                    </Button>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flex: 1,
                      justifyContent: "flex-end",
                      marginTop: "1%",
                      marginBottom: "2%",
                    }}
                  >
                    <Button
                      style={
                        matches ? { ...styles.save } : { ...styles.mobileSave }
                      }
                      onClick={searchActivated ? handleEdit : handleAdd}
                      variant="contained"
                      color="default"
                    >
                      Save
                    </Button>
                    &nbsp; &nbsp;
                    <Button
                      style={
                        matches
                          ? { ...styles.generate }
                          : { ...styles.mobileGenerate, marginRight: 10 }
                      }
                      onClick={handleGenerateEDR}
                      variant="contained"
                      color="primary"
                    >
                      {"Generate ED Record"}
                    </Button>
                  </div>
                </div>
              </div>
            ) : (
              undefined
            )}

            <Notification
              msg={errorMsg}
              open={openNotification}
              success={successMsg}
            />
          </div>
        </div>
      </div>
    );
  }
}
export default PatientRegistration;
