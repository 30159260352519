import React from "react";
import Back from "../../assets/img/Back_Arrow.png";
import useMediaQuery from "@material-ui/core/useMediaQuery";

function ModuleUnderDevelopment(props) {
  const matches = useMediaQuery("(min-width:600px)");
  return (
    <div
      style={{
        // display: "flex",
        // justifyContent: "center",
        // alignItems: "center",
        // width: "100%",
        // height: "100%",
        // position: "fixed",
        // fontWeight: "bold",
        position: "fixed",
        display: "flex",
        width: "100%",
        height: "100%",
        flexDirection: "column",
        flex: 1,
        // backgroundImage: `url("${ThankYou}")`,
        backgroundColor: "#0062cc",
        backgroundSize: "100%",
      }}
    >
      <div
        className="thankyou"
        style={{
          alignItems: "center",
          flex: 4,
          display: "flex",
          marginTop: -200,
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <h4
          style={{
            color: "white",
            fontWeight: "bold",
            fontSize: 25,
            marginLeft: matches ? " " : 54,
          }}
        >
          This module is under development for now.
        </h4>
        <h2 style={{ color: "white", fontWeight: "bold", fontSize: 20 }}>
          Back You Please!
        </h2>
        <img
          onClick={() => props.history.goBack()}
          src={Back}
          style={{
            borderRadius: 15,
            backgroundColor: "#000000",
            width: 65,
            height: 45,
            cursor: "pointer",
            padding: 10,
          }}
        />
      </div>
    </div>
  );
}

export default ModuleUnderDevelopment;
