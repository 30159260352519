import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import useStylesForInput from "../../assets/jss/material-dashboard-react/inputStyle";
import styles from "./styles";
import { Grid } from "@material-ui/core";
import Loader from "react-loader-spinner";
import MicIcon from "@material-ui/icons/Mic";
import StopIcon from "@material-ui/icons/Stop";
import MicRecorder from "mic-recorder-to-mp3";
import MenuItem from "@material-ui/core/MenuItem";
import InputAdornment from "@material-ui/core/InputAdornment";
import AccountCircle from "@material-ui/icons/SearchOutlined";
import Autocomplete from "@material-ui/lab/Autocomplete";
import axios from "axios";
import {
  getIcdForDropdown
} from "../../public/endpoins";

export default function EdrRequest(props) {
  const matches = useMediaQuery("(min-width:600px)");

  const classes = useStylesForInput();

  const [Icd10CodesList, setIcd10CodesList] = useState([]);
  const [icd10SearchedCodes, setIcd10SearchedCodes] = useState("");

  useEffect(() => {
    console.log("DoctorNotesDialog:: props:: ", props);
  }, []);

  useEffect(() => {
    console.log("icd10CodesDropdown", icd10SearchedCodes);
    const getSearchedIcd10KeywordList = () => {
      console.log("icd10CodesDropdownStart", icd10SearchedCodes, getIcdForDropdown);
      axios
        // .get(getSearchedPharmaceuticalItemsUrl + "/" + icd10SearchedCodes)
        .get(getIcdForDropdown, {
          params: {
            foo: 'bar',
            keyword: icd10SearchedCodes
          }
        })
        .then((response) => {
          console.log("icd10CodesDropdown", response.data.items);
          let icd10CodesDropdown = [];
          Object.entries(response.data.items).map(([key, val]) => {
            icd10CodesDropdown.push({
              id: val._id,
              name: val.short_desc,
              short_desc: val.short_desc,
              code: val.code,
            });
          });
          console.log("icd10CodesDropdown", icd10CodesDropdown);
          setIcd10CodesList(icd10CodesDropdown);
        })
        .catch((error) => {
          console.log("Error", error);
        });
    };
    icd10SearchedCodes && icd10SearchedCodes.length>=2 && getSearchedIcd10KeywordList();
  }, [icd10SearchedCodes]);

  return (
    <Dialog
      fullScreen
      aria-labelledby="form-dialog-title"
      open={props.openAddDoctorNoteDialog}
      maxWidth="xl"
      fullWidth={true}
    >
      <DialogContent style={{ backgroundColor: "rgb(19 213 159)" }}>
        <DialogTitle
          id="simple-dialog-title"
          style={{ color: "white", marginLeft: -9 }}
        >
          {!props.viewRecord ? "Add Notes" : "View Note"}
        </DialogTitle>
        <div className={`container-fluid ${classes.root}`}>
          <div className="row">

            <div
              className="col-md-12 col-sm-12 col-12"
              style={{
                ...styles.inputContainerForTextField,
                padding: matches ? " " : "0 0px",
              }}
            >
              <Autocomplete
                multiple
                id="icdDiagnosisDropdown"
                options={Icd10CodesList}
                values={props.selectedCodes}
                defaultValue={props.selectedCodes}
                getOptionLabel={(option) =>
                  option.code + ": " + option.short_desc
                }
                onChange={(e, value) =>{
                  props.onCodeSelected(value);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Main Diagnosis"
                    placeholder="Type Code or Name"
                    onChange={(event) => {
                      setIcd10SearchedCodes(
                        event.target.value
                      );
                    }}
                    className="textInputStyle"
                    variant="filled"
                    error={props.selectedCodes.length<=0 && props.selectedCodes && props.doctorForm}
                  />
                )}
              />
            </div>

            <div
              className="col-md-12 col-sm-12 col-12"
              style={{
                ...styles.inputContainerForTextField,
                padding: matches ? "" : "0px",
              }}
            >
              <TextField
                disabled={!props.viewRecord ? false : true}
                multiline
                type="text"
                error={props.doctorNotes === "" && props.doctorForm}
                label="Description / Condition"
                name={"doctorNotes"}
                value={props.doctorNotes}
                onChange={props.onChangeValue}
                rows={4}
                className="textInputStyle"
                variant="filled"
                InputProps={{
                  className: classes.multilineColor,
                  classes: {
                    input: classes.multilineColor,
                  },
                }}
                // inputProps={{ maxLength: 300 }}
              />
            </div>



            <div
              className="col-md-12 col-sm-12 col-12"
              style={{
                ...styles.inputContainerForTextField,
                padding: matches ? " " : "0 0px",
              }}
            >
              <Autocomplete
                multiple
                required
                id="icdDiagnosisDropdown"
                options={Icd10CodesList}
                values={props.selectedAdditionalCodes}
                defaultValue={props.selectedAdditionalCodes}
                getOptionLabel={(option) =>
                  option.code + ": " + option.short_desc
                }
                onChange={(e, value) =>{
                  props.onAdditionalCodeSelected(value);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Additional Diagnosis/Clinical Conditions"
                    placeholder="Type Code or Name"
                    onChange={(event) => {
                      setIcd10SearchedCodes(
                        event.target.value
                      );
                    }}
                    className="textInputStyle"
                    variant="filled"
                    error={props.selectedAdditionalCodes.length<=0 && props.selectedAdditionalCodes && props.doctorForm}
                  />
                )}
              />
            </div>

            <div
              className="col-md-12 col-sm-12 col-12"
              style={{
                ...styles.inputContainerForTextField,
                padding: matches ? "" : "0px",
              }}
            >
              <TextField
                disabled={!props.viewRecord ? false : true}
                multiline
                type="text"
                error={props.doctorNotesAdditional === "" && props.doctorForm}
                label="Notes for Additional Diagnosis/Clinical Conditions"
                name={"doctorNotesAdditional"}
                value={props.doctorNotesAdditional}
                onChange={props.onChangeValue}
                rows={4}
                className="textInputStyle"
                variant="filled"
                InputProps={{
                  className: classes.multilineColor,
                  classes: {
                    input: classes.multilineColor,
                  },
                }}
                // inputProps={{ maxLength: 300 }}
              />
            </div>
          </div>

          <div className="row">
            <div
              className="col-md-12 col-sm-12 col-12"
              style={{
                padding: matches ? "" : "0px",
              }}
            >
              <Grid
                // justify="center" // Add it here :)
                justify="flex-start"
                container
                spacing={10}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  backgroundColor: "white",
                  marginLeft: 0,
                  marginRight: 0,
                  marginTop: 15,
                  borderRadius: 5,
                  height: 125,
                  marginBottom: 15,
                  paddingLeft: 10,
                  // justifyContent: "center",
                  width: '100%',
                }}
              >
                <label
                  style={{
                    color: "#878787",
                    paddingRight: 30,
                    fontSize: "smaller",
                  }}
                >
                  Voice Note
                </label>
                <div>
                  {!props.isRecording ? (
                    <audio
                      style={{
                        marginRight: 10,
                        width: matches ? 450 : 220,
                        marginTop: 6,
                        outline: "none",
                      }}
                      src={props.blobURL}
                      controls="controls"
                    />
                  ) : (
                    <div
                      style={{
                        marginLeft: matches ? 110 : 18,
                        marginRight: matches ? 110 : 75,
                        width: matches ? 550 : 100,
                      }}
                    >
                      <Loader
                        type="ThreeDots"
                        color="blue"
                        height={40}
                        width={40}
                      />
                    </div>
                  )}
                </div>
                {!props.viewRecord ? (
                  <div
                    style={{
                      marginRight: matches ? 15 : 7,
                      backgroundColor: props.isRecording
                        ? "#DC004E"
                        : "#2C6DDD",
                      height: matches ? 55 : 50,
                      borderRadius: 30,
                      width: matches ? 55 : 50,
                      paddingLeft: matches ? 10 : 8,
                    }}
                  >
                    {props.isRecording ? (
                      <StopIcon
                        style={{
                          marginTop: matches ? 11 : 9,
                          color: "white",
                          cursor: "pointer",
                        }}
                        fontSize="large"
                        onClick={
                          !props.viewRecord ? () => props.stop("") : () => {}
                        }
                      />
                    ) : (
                      <MicIcon
                        style={{
                          marginTop: matches ? 12 : 10,
                          color: "white",
                          cursor: "pointer",
                        }}
                        fontSize="large"
                        onClick={
                          !props.viewRecord ? () => props.start("") : () => {}
                        }
                      />
                    )}
                  </div>
                ) : (
                  undefined
                )}
              </Grid>
            </div>
          </div>

        </div>

        <div
          className="container-fluid"
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: 20,
          }}
        >
          <Button
            onClick={() => props.hideDialog()}
            style={{
              ...styles.stylesForButton,
              color: "gray",
              backgroundColor: "white",
              // width: matches ? " " : 150,
            }}
            variant="contained"
          >
            <strong>Cancel</strong>
          </Button>

          {!props.viewRecord ? (
            <Button
              style={{
                color: "white",
                cursor: "pointer",
                borderRadius: 5,
                backgroundColor: "#2c6ddd",
                outline: "none",
              }}
              // disabled={!validateFormRR()}
              // onClick={handleEditDoctorNotes}
              onClick={
                props.selectedNoteToEdit
                  ? props.handleEditDoctorNotes
                  : props.submitDoctorNotes
              }
              variant="contained"
              color="primary"
            >
              Submit
            </Button>
          ) : (
            undefined
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
}
