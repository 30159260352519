import { makeStyles } from "@material-ui/core"
export const containerCSStyles = makeStyles(() => ({
  image: {
    width: 50,
    height: 50,
    marginLeft: 15,
  },
  root: {
    flexGrow: 1,
    marginBottom: 20,
    marginLeft: 10,
    marginRight: 10,

    "& .Mui-focused": {
      borderBottom: 0,
    },
    "& .MuiFormHelperText-root": {
      color: "red",
    },
    "& .MuiTypography-root": {
      marginTop: 8,
    },

    "& .MuiSvgIcon-colorError": {
      marginTop: 10,
    },
    "& .MuiSvgIcon-colorPrimary": {
      marginTop: 10,
    },
  },
  viewButtonLabel: {
    textTransform: "none",
    color: "white",
    marginBottom: 10,
    backgroundColor: "#2973CF",
  },
  input: {
    backgroundColor: "white",
    boxShadow: "none",
    borderRadius: 5,
    "&:after": {
      borderBottomColor: "black",
      boxShadow: "none",
    },
    "&:hover": {
      backgroundColor: "white",
      boxShadow: "none",
    },
    "&:focus": {
      backgroundColor: "white",
      boxShadow: "none",
      borderRadius: 5,
    },
  },
}))
