/*eslint-disable*/
import React, { useState, useEffect, useReducer } from "react";
import Button from "@material-ui/core/Button";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Header from "../../components/Header/Header";
import Back from "../../assets/img/Back_Arrow.png";
import cookie from "react-cookies";
import "../../assets/jss/material-dashboard-react/components/loaderStyle.css";
import Notification from "../../components/Snackbar/Notification.js";
import { styles, useStyles, useStylesForTabs } from "./styles.js";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import axios from "axios";
import { addSocialSurvey } from "../../public/endpoins";
import {
  performanceForStep1,
  performanceForStep2,
  performanceForStep3,
  performanceForStep4,
  performanceForStep5,
  performanceForStep6,
  performanceForStep7,
  performanceForStep8,
  performanceForStep9,
  performanceForStep10,
  performanceForStep11,
  performanceForStep12,
} from "./ConfigForServeyScreen";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const performanceArray = [
  {
    key: "Excellent",
    value: "Excellent",
  },
  {
    key: "Very Good",
    value: "Very Good",
  },
  {
    key: "Good",
    value: "Good",
  },
  {
    key: "Fair",
    value: "Fair",
  },
];

const performanceArray2 = [
  {
    key: "Much better",
    value: "Much better",
  },
  {
    key: "Little Better",
    value: "Little Better",
  },
  {
    key: "About the same",
    value: "About the same",
  },
  {
    key: "Little worse",
    value: "Little worse",
  },
];

export default function DischargeSubTasks(props) {
  const classesForTabs = useStylesForTabs();
  const classes = useStyles();
  const matches = useMediaQuery("(min-width:600px)");

  const initialState = {
    textField: "",
  };

  function reducer(state, { field, value }) {
    return {
      ...state,
      [field]: value,
    };
  }

  const [state, dispatch] = useReducer(reducer, initialState);

  const { textField } = state;

  const [counter, setCounter] = useState(1);
  const [onCheck, setOnCheck] = useState("");
  const [step1, setStep1] = useState("");
  const [step2, setStep2] = useState("");
  const [step3, setStep3] = useState("");
  const [step4, setStep4] = useState("");
  const [step5, setStep5] = useState("");
  const [step6, setStep6] = useState("");
  const [step7, setStep7] = useState("");
  const [step8, setStep8] = useState("");
  const [step9, setStep9] = useState("");
  const [step10, setStep10] = useState("");
  const [step11, setStep11] = useState("");
  const [step12, setStep12] = useState("");
  const [generalArray, setGeneralArray] = useState([]);
  const [progressValue, setProgressValue] = useState(1);
  const [percentageValue, setPercentageValue] = useState(8.33);
  const [openNotification, setOpenNotification] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setsuccessMsg] = useState("");
  const [currentUser, setCurrentUser] = useState(cookie.load("current_user"));

  console.log("propss", props);

  function handleNext() {
    console.log("generalArray", generalArray);
    if ((counter === 1 && step1 !== "") || (counter === 8 && step11 !== "")) {
      let count = counter + 1;
      setCounter(count);
      let progressStep = progressValue + 1;
      setProgressValue(progressStep);
      let percentageStep = 8.33 * progressStep;
      setPercentageValue(percentageStep);
      setGeneralArray([]);
    } else if (generalArray.length > 0 && counter > 1) {
      let count = counter + 1;
      setCounter(count);
      setGeneralArray([]);
      let progressStep = progressValue + 1;
      setProgressValue(progressStep);
      let percentageStep = 8.33 * progressStep;
      setPercentageValue(percentageStep);
    } else {
      console.log("please enter data");
      setErrorMsg("Please select some data");
      setOpenNotification(true);
    }
  }

  function handleBack() {
    // if (counter === 1) {
    props.history.goBack();
    // } else {
    //   let count = counter - 1
    //   setCounter(count)
    //   // setGeneralArray([])
    //   let progressStep = progressValue - 1
    //   setProgressValue(progressStep)
    //   let percentageStep = 8.33 * progressStep
    //   setPercentageValue(percentageStep)
    // }
  }

  console.log(currentUser);
  function handleSubmit() {
    // console.log(step2)
    // console.log(step3)
    const params = {
      edrId: props.location.state.selectedRecord._id,
      object: [
        step1,
        step2,
        step3,
        step4,
        step5,
        step6,
        step7,
        step8,
        step9,
        step10,
        step11,
        step12,
      ],
      staffId: currentUser._id,
    };

    console.log("params", params);

    axios
      .put(addSocialSurvey, params)
      .then((res) => {
        if (res.data.success) {
          console.log(res.data.data, "response");
          if (
            props.location.pathname ===
            "/dashboard/home/customerList/customersatisfactionform/servey"
          ) {
            props.history.push({
              pathname: "/dashboard/home/success",
              state: {
                reqCode: `${
                  res.data.data.survey[res.data.data.survey.length - 1]
                    .requestId
                }`,
                message3: `
                  : For taking the time to share your thoughts with us against Patient`,
                code: `MRN: ${res.data.data.patientId.identifier[0].value}`,
                message2: `we wish you a fast recovery`,
              },
            });
          } else {
            props.history.push({
              pathname: "servey/dischargesubtasklist",
              state: {
                selectedItem: props.location.state.selectedRecord,
              },
            });
          }
        }
      })
      .catch((e) => {
        console.log("error ", e);
        setOpenNotification(true);
        setErrorMsg("fff");
      });
  }

  const onChangeCheck = (e, heading, stepNo) => {
    console.log("e", e.target);
    let checkObject = {
      key: heading,
      value: [{ name: heading, value: e.target.value }],
    };
    console.log("checkObject", checkObject);
    setOnCheck(e.target.value);
    if (stepNo === "1") {
      setStep1(checkObject);
    } else if (stepNo === "11") {
      setStep11(checkObject);
    }
  };

  const onChangeValue = (e, heading, stepNo) => {
    console.log("e", e.target);
    let found = generalArray.find((val) => val.name === e.target.name);
    console.log(found);
    let temp = [];
    if (found) {
      found = {
        ...found,
        value: e.target.value,
      };
      temp = [...generalArray];
      for (let i = 0; i < generalArray.length; i++) {
        if (generalArray[i].name === e.target.name) {
          temp[i] = found;
        }
      }
    } else {
      temp = [...generalArray, e.target];
    }

    setGeneralArray([...temp]);
    let obj = { key: heading, value: temp };
    if (stepNo === "2") {
      setStep2(obj);
    } else if (stepNo === "3") {
      setStep3(obj);
    } else if (stepNo === "4") {
      setStep4(obj);
    } else if (stepNo === "5") {
      setStep5(obj);
    } else if (stepNo === "6") {
      setStep6(obj);
    } else if (stepNo === "7") {
      setStep7(obj);
    } else if (stepNo === "8") {
      setStep8(obj);
    } else if (stepNo === "9") {
      setStep9(obj);
    } else if (stepNo === "10") {
      setStep10(obj);
    }
  };
  console.log("step1", step1);
  console.log("step2", step2);
  console.log("step3", step3);

  const onChangeTextField = (e, heading) => {
    console.log("e.target", e.target.value, e.target.name);

    dispatch({
      field: e.target.name,
      value: e.target.value,
    });
    let obj = {
      key: heading,
      text: e.target.value,
    };
    setStep12(obj);
  };

  console.log("text", textField);
  if (openNotification) {
    setTimeout(() => {
      setOpenNotification(false);
      setErrorMsg("");
      setsuccessMsg("");
    }, 2000);
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        position: "fixed",
        width: "100%",
        height: "100%",
        backgroundColor: "#60d69f",
        overflowY: "scroll",
      }}
    >
      <Header history={props.history} />

      <div className="cPadding">
        <div
          className="container-fluid"
          style={{ paddingLeft: 0, paddingRight: 0 }}
        >
          <div
            className="row"
            style={{
              display: "flex",
              flex: 1,
              justifyContent: "center",
              paddingLeft: 6,
              paddingRight: 6,
            }}
          >
            <div
              style={{
                display: "flex",
                flex: 1,
                justifyContent: "center",
                // marginTop: '2%',
                marginBottom: "2%",
              }}
            >
              <div style={{ height: "100px", width: "100px" }}>
                <CircularProgressbar
                  styles={buildStyles({
                    textColor: "WHITE",
                    pathColor: "WHITE",
                    trailColor: "#A9ECC8",
                    // fontWeight: 'bold',
                  })}
                  strokeWidth={10}
                  value={percentageValue}
                  text={`${progressValue}/12`}
                />
              </div>
            </div>
          </div>
          <div
            style={{
              flex: 4,
              display: "flex",
              flexDirection: "column",
              backgroundColor: "white",

              borderRadius: 5,
              padding: 40,
            }}
          >
            {counter === 1 ? (
              <div>
                <div
                  style={{
                    fontWeight: "bold",
                    paddingBottom: 30,
                  }}
                >
                  {performanceForStep1.heading}
                </div>
                {performanceForStep1.options.map((val) => {
                  return (
                    <div className="row">
                      <div className="col-md-12 col-sm-12" style={styles.form}>
                        <FormControl>
                          <RadioGroup
                            name="onCheck"
                            onChange={(e) =>
                              onChangeCheck(e, performanceForStep1.heading, "1")
                            }
                            value={onCheck}
                          >
                            <FormControlLabel
                              value={val}
                              control={<Radio />}
                              color="default"
                              label={val}
                            />
                          </RadioGroup>
                        </FormControl>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : counter === 2 ? (
              <div>
                <div
                  style={{
                    fontWeight: "bold",
                    paddingBottom: 30,
                  }}
                >
                  {performanceForStep2.heading}
                </div>
                {performanceForStep2.options.map((val) => {
                  return (
                    <div className="row" style={{ marginBottom: "10px" }}>
                      <div className="col-md-6" style={styles.form}>
                        <span>{val}</span>
                      </div>

                      <div
                        className="col-md-6"
                        style={{
                          ...styles.inputContainerForTextField,
                          ...styles.textFieldPadding,
                        }}
                      >
                        <TextField
                          required
                          select
                          fullWidth
                          name={val}
                          onChange={(e) =>
                            onChangeValue(e, performanceForStep2.heading, "2")
                          }
                          variant="filled"
                          className="dropDownStyle"
                          InputProps={{
                            className: classes.input,
                            classes: { input: classes.input },
                            disableUnderline: true,
                          }}
                        >
                          {performanceArray.map((val) => {
                            return (
                              <MenuItem key={val.key} value={val.key}>
                                {val.value}
                              </MenuItem>
                            );
                          })}
                        </TextField>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : counter === 3 ? (
              <div>
                <div
                  style={{
                    fontWeight: "bold",
                    paddingBottom: 30,
                  }}
                >
                  {performanceForStep3.heading}
                </div>
                <div>
                  {performanceForStep3.options.map((val) => {
                    return (
                      <div className="row" style={{ marginBottom: "10px" }}>
                        <div className="col-md-6" style={styles.form}>
                          <span>{val}</span>
                        </div>

                        <div
                          className="col-md-6"
                          style={{
                            ...styles.inputContainerForTextField,
                            ...styles.textFieldPadding,
                          }}
                        >
                          <TextField
                            required
                            select
                            fullWidth
                            name={val}
                            // error={step3 === '' && detailsForm}
                            onChange={(e) =>
                              onChangeValue(e, performanceForStep3.heading, "3")
                            }
                            variant="filled"
                            className="dropDownStyle"
                            InputProps={{
                              className: classes.input,
                              classes: { input: classes.input },
                              disableUnderline: true,
                            }}
                          >
                            {performanceArray.map((val) => {
                              return (
                                <MenuItem key={val.key} value={val.key}>
                                  {val.value}
                                </MenuItem>
                              );
                            })}
                          </TextField>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : counter === 4 ? (
              <div>
                <div
                  style={{
                    fontWeight: "bold",
                    paddingBottom: 30,
                  }}
                >
                  {performanceForStep4.heading}
                </div>
                {performanceForStep4.options.map((val) => {
                  return (
                    <div className="row" style={{ marginBottom: "10px" }}>
                      <div className="col-md-6" style={styles.form}>
                        <span>{val}</span>
                      </div>

                      <div
                        className="col-md-6"
                        style={{
                          ...styles.inputContainerForTextField,
                          ...styles.textFieldPadding,
                        }}
                      >
                        <TextField
                          required
                          select
                          fullWidth
                          name={val}
                          onChange={(e) =>
                            onChangeValue(e, performanceForStep4.heading, "4")
                          }
                          variant="filled"
                          className="dropDownStyle"
                          InputProps={{
                            className: classes.input,
                            classes: { input: classes.input },
                            disableUnderline: true,
                          }}
                        >
                          {performanceArray.map((val) => {
                            return (
                              <MenuItem key={val.key} value={val.key}>
                                {val.value}
                              </MenuItem>
                            );
                          })}
                        </TextField>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : counter === 5 ? (
              <div>
                <div
                  style={{
                    fontWeight: "bold",
                    paddingBottom: 30,
                  }}
                >
                  {performanceForStep5.heading}
                </div>
                <div>
                  {performanceForStep5.options.map((val) => {
                    return (
                      <div className="row" style={{ marginBottom: "10px" }}>
                        <div className="col-md-6" style={styles.form}>
                          <span>{val}</span>
                        </div>

                        <div
                          className="col-md-6"
                          style={{
                            ...styles.inputContainerForTextField,
                            ...styles.textFieldPadding,
                          }}
                        >
                          <TextField
                            required
                            select
                            fullWidth
                            name={val}
                            // error={step5 === '' && detailsForm}
                            onChange={(e) =>
                              onChangeValue(e, performanceForStep5.heading, "5")
                            }
                            variant="filled"
                            className="dropDownStyle"
                            InputProps={{
                              className: classes.input,
                              classes: { input: classes.input },
                              disableUnderline: true,
                            }}
                          >
                            {performanceArray.map((val) => {
                              return (
                                <MenuItem key={val.key} value={val.key}>
                                  {val.value}
                                </MenuItem>
                              );
                            })}
                          </TextField>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : counter === 6 ? (
              <div>
                <div
                  style={{
                    fontWeight: "bold",
                    paddingBottom: 30,
                  }}
                >
                  {performanceForStep6.heading}
                </div>
                {performanceForStep6.options.map((val) => {
                  return (
                    <div className="row" style={{ marginBottom: "10px" }}>
                      <div className="col-md-6" style={styles.form}>
                        <span>{val}</span>
                      </div>

                      <div
                        className="col-md-6"
                        style={{
                          ...styles.inputContainerForTextField,
                          ...styles.textFieldPadding,
                        }}
                      >
                        <TextField
                          required
                          select
                          fullWidth
                          name={val}
                          onChange={(e) =>
                            onChangeValue(e, performanceForStep6.heading, "6")
                          }
                          variant="filled"
                          className="dropDownStyle"
                          InputProps={{
                            className: classes.input,
                            classes: { input: classes.input },
                            disableUnderline: true,
                          }}
                        >
                          {performanceArray.map((val) => {
                            return (
                              <MenuItem key={val.key} value={val.key}>
                                {val.value}
                              </MenuItem>
                            );
                          })}
                        </TextField>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : counter === 7 ? (
              <div>
                <div
                  style={{
                    fontWeight: "bold",
                    paddingBottom: 30,
                  }}
                >
                  {performanceForStep7.heading}
                </div>
                <div>
                  {performanceForStep7.options.map((val) => {
                    return (
                      <div className="row" style={{ marginBottom: "10px" }}>
                        <div className="col-md-6" style={styles.form}>
                          <span>{val}</span>
                        </div>

                        <div
                          className="col-md-6"
                          style={{
                            ...styles.inputContainerForTextField,
                            ...styles.textFieldPadding,
                          }}
                        >
                          <TextField
                            required
                            select
                            fullWidth
                            name={val}
                            // error={step2 === '' && detailsForm}
                            onChange={(e) =>
                              onChangeValue(e, performanceForStep7.heading, "7")
                            }
                            variant="filled"
                            className="dropDownStyle"
                            InputProps={{
                              className: classes.input,
                              classes: { input: classes.input },
                              disableUnderline: true,
                            }}
                          >
                            {performanceArray.map((val) => {
                              return (
                                <MenuItem key={val.key} value={val.key}>
                                  {val.value}
                                </MenuItem>
                              );
                            })}
                          </TextField>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : counter === 10 ? (
              <div>
                <div
                  style={{
                    fontWeight: "bold",
                    paddingBottom: 30,
                  }}
                >
                  {performanceForStep8.heading}
                </div>
                <div>
                  {performanceForStep8.options.map((val) => {
                    return (
                      <div className="row" style={{ marginBottom: "10px" }}>
                        <div className="col-md-6" style={styles.form}>
                          <span>{val}</span>
                        </div>

                        <div
                          className="col-md-6"
                          style={{
                            ...styles.inputContainerForTextField,
                            ...styles.textFieldPadding,
                          }}
                        >
                          <TextField
                            required
                            select
                            fullWidth
                            name={val}
                            // error={step3 === '' && detailsForm}
                            onChange={(e) =>
                              onChangeValue(e, performanceForStep8.heading, "8")
                            }
                            variant="filled"
                            className="dropDownStyle"
                            InputProps={{
                              className: classes.input,
                              classes: { input: classes.input },
                              disableUnderline: true,
                            }}
                          >
                            {performanceArray.map((val) => {
                              return (
                                <MenuItem key={val.key} value={val.key}>
                                  {val.value}
                                </MenuItem>
                              );
                            })}
                          </TextField>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : counter === 11 ? (
              <div>
                <div
                  style={{
                    fontWeight: "bold",
                    paddingBottom: 30,
                  }}
                >
                  {performanceForStep9.heading}
                </div>
                {performanceForStep9.options.map((val) => {
                  return (
                    <div className="row" style={{ marginBottom: "10px" }}>
                      <div className="col-md-6" style={styles.form}>
                        <span>{val}</span>
                      </div>

                      <div
                        className="col-md-6"
                        style={{
                          ...styles.inputContainerForTextField,
                          ...styles.textFieldPadding,
                        }}
                      >
                        <TextField
                          required
                          select
                          fullWidth
                          name={val}
                          onChange={(e) =>
                            onChangeValue(e, performanceForStep9.heading, "9")
                          }
                          variant="filled"
                          className="dropDownStyle"
                          InputProps={{
                            className: classes.input,
                            classes: { input: classes.input },
                            disableUnderline: true,
                          }}
                        >
                          {performanceArray.map((val) => {
                            return (
                              <MenuItem key={val.key} value={val.key}>
                                {val.value}
                              </MenuItem>
                            );
                          })}
                        </TextField>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : counter === 9 ? (
              <div>
                <div
                  style={{
                    fontWeight: "bold",
                    paddingBottom: 30,
                  }}
                >
                  {performanceForStep10.heading}
                </div>
                {performanceForStep10.options.map((val) => {
                  return (
                    <div className="row" style={{ marginBottom: "10px" }}>
                      <div className="col-md-6" style={styles.form}>
                        <span>{val}</span>
                      </div>

                      <div
                        className="col-md-6"
                        style={{
                          ...styles.inputContainerForTextField,
                          ...styles.textFieldPadding,
                        }}
                      >
                        <TextField
                          required
                          select
                          fullWidth
                          name={val}
                          onChange={(e) =>
                            onChangeValue(e, performanceForStep10.heading, "10")
                          }
                          variant="filled"
                          className="dropDownStyle"
                          InputProps={{
                            className: classes.input,
                            classes: { input: classes.input },
                            disableUnderline: true,
                          }}
                        >
                          {performanceArray2.map((val) => {
                            return (
                              <MenuItem key={val.key} value={val.key}>
                                {val.value}
                              </MenuItem>
                            );
                          })}
                        </TextField>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : counter === 8 ? (
              <div>
                <div
                  style={{
                    fontWeight: "bold",
                    paddingBottom: 30,
                  }}
                >
                  {performanceForStep11.heading}
                </div>
                {performanceForStep11.options.map((val) => {
                  return (
                    <div className="row">
                      <div className="col-md-12" style={styles.form}>
                        <FormControl>
                          <RadioGroup
                            name="onCheck"
                            onChange={(e) =>
                              onChangeCheck(
                                e,
                                performanceForStep11.heading,
                                "11"
                              )
                            }
                            value={onCheck}
                          >
                            <FormControlLabel
                              value={val}
                              control={<Radio />}
                              color="default"
                              label={val}
                            />
                          </RadioGroup>
                        </FormControl>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : counter === 12 ? (
              <div>
                <div
                  style={{
                    fontWeight: "bold",
                    paddingBottom: 30,
                  }}
                >
                  {performanceForStep12.heading}
                </div>
                <div>
                  {performanceForStep12.options.map((val) => {
                    return (
                      <div className="row" style={{ marginBottom: "10px" }}>
                        {/* <div className='col-md-6' style={styles.form}>
                            <span>{val}</span>
                          </div> */}

                        <div
                          className="col-md-12"
                          style={{
                            ...styles.inputContainerForTextField,
                            ...styles.textFieldPadding,
                          }}
                        >
                          <TextField
                            required
                            multiline
                            rows={4}
                            fullWidth
                            name={"textField"}
                            onChange={(e) =>
                              onChangeTextField(e, performanceForStep12.heading)
                            }
                            value={textField}
                            variant="filled"
                            className="dropDownStyle"
                            InputProps={{
                              className: classes.input,
                              classes: { input: classes.input },
                              disableUnderline: true,
                            }}
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : (
              undefined
            )}
          </div>

          <div
            // class='row'
            className="container-fluid"
            style={{
              display: "flex",
              flex: 1,
              justifyContent: "center",
              paddingLeft: 6,
              paddingRight: 6,
            }}
          >
            <div
              style={{
                display: "flex",
                flex: 1,
                justifyContent: "flex",
                marginTop: "2%",
                marginBottom: "2%",
                marginLeft: "-8px",
              }}
            >
              {/* <img
                onClick={handleBack}
                src={Back}
                style={{
                  width: 45,
                  height: 35,
                  cursor: 'pointer',
                }}
              /> */}
              <Button
                onClick={handleBack}
                style={{
                  ...styles.stylesForNextButton,
                  fontSize: matches ? " " : 10,
                }}
                variant="contained"
                color="primary"
              >
                <strong>Cancel</strong>
              </Button>
            </div>

            <div
              style={{
                display: "flex",
                flex: 1,
                justifyContent: "flex-end",
                marginTop: "2%",
                marginBottom: "2%",
                marginRight: "-6px",
              }}
            >
              <Button
                onClick={counter === 12 ? handleSubmit : handleNext}
                style={{
                  ...styles.stylesForNextButton,
                  fontSize: matches ? " " : 10,
                }}
                variant="contained"
                color="primary"
              >
                <strong>{counter === 12 ? "Submit" : "Next"}</strong>
              </Button>
            </div>
          </div>
        </div>
      </div>

      <Notification
        msg={errorMsg}
        open={openNotification}
        success={successMsg}
      />
    </div>
  );
}
