export const allowedRolesForAdmin = [
  { tabValue: 0, key: 'Sensei', value: 'Sensei' },
  { tabValue: 1, key: 'Doctor-ED Doctor', value: 'ED Doctor' },
  { tabValue: 2, key: 'Nurse-ED Nurse', value: 'ED Nurse' },
  { tabValue: 3, key: 'Production Team', value: 'Production Team' },
  // { tabValue: 4, key: "Nurse-EOU Nurse", value: "EOU Nurse" },
  // { tabValue: 5, key: "Nurse-Nurse Technician", value: "Nurse Technician" },
  { tabValue: 4, key: 'Doctor-Rad Doctor', value: 'Radiology Doctor' },
  { tabValue: 5, key: 'Registration Officer', value: 'Registration Officer' },
]

export const allowedRolesForRO = [
  { tabValue: 0, key: 'Sensei', value: 'Sensei' },
  { tabValue: 1, key: 'Paramedics', value: 'Paramedics' },
  {
    tabValue: 2,
    key: 'Insurance Claims Manager',
    value: 'Insurance Claims Manager',
  },
  { tabValue: 3, key: 'Cashier', value: 'Cashier' },
  { tabValue: 4, key: 'Admin', value: 'Admin' },

  // { tabValue: 2, key: "Registration Officer", value: "Registration Officer" },
  // { tabValue: 3, key: "Doctor-ED Doctor", value: "ED Doctor" },
  // { tabValue: 4, key: "Nurse-ED Nurse", value: "ED Nurse" },
  // { tabValue: 5, key: "Nurse-EOU Nurse", value: "EOU Nurse" },
  // { tabValue: 6, key: "Nurse-Nurse Technician", value: "Nurse Technician" },
  // { tabValue: 7, key: "Doctor-Rad Doctor", value: "Radiology Doctor" },
]

export const allowedRolesForSensei = [
  { tabValue: 0, key: 'Registration Officer', value: 'Registration Officer' },
  { tabValue: 1, key: 'Paramedics', value: 'Paramedics' },
  { tabValue: 2, key: 'Nurse-ED Nurse', value: 'ED Nurse' },
  { tabValue: 3, key: 'Doctor-Anesthesiologist', value: 'Anesthesiologist' },
  { tabValue: 4, key: 'Doctor-ED Doctor', value: 'ED Doctor' },
  { tabValue: 5, key: 'Nurse-EOU Nurse', value: 'EOU Nurse' },
  { tabValue: 6, key: 'House Keeping', value: 'House Keeping' },
  { tabValue: 7, key: 'Admin', value: 'Admin' },
  // { tabValue: 6, key: "Nurse-Nurse Technician", value: "Nurse Technician" },
  // { tabValue: 7, key: "Doctor-Rad Doctor", value: "Radiology Doctor" },
]

export const allowedRolesForEDDoctor = [
  { tabValue: 0, key: 'Sensei', value: 'Sensei' },
  { tabValue: 1, key: 'Paramedics', value: 'Paramedics' },
  { tabValue: 2, key: 'Nurse-ED Nurse', value: 'ED Nurse' },
  { tabValue: 3, key: 'Doctor-Rad Doctor', value: 'Rad Doctor' },
  { tabValue: 4, key: 'Imaging Technician', value: 'Imaging Tech' },
  { tabValue: 5, key: 'Clinical Pharmacist', value: 'Clinical Pharmacist' },
  { tabValue: 6, key: 'Lab Technician', value: 'Lab Technician' },
  { tabValue: 7, key: 'Doctor-Anesthesiologist', value: 'Anesthesiologist' },
  { tabValue: 8, key: 'Doctor-Internal', value: 'Internal Consultant' },
  { tabValue: 9, key: 'Doctor-External', value: 'External Consultant' },
  { tabValue: 10, key: 'Admin', value: 'Admin' },
  // { tabValue: 4, key: "Doctor-ED Doctor", value: "ED Doctor" },
  // { tabValue: 5, key: "Nurse-EOU Nurse", value: "EOU Nurse" },
  // { tabValue: 6, key: "Nurse-Nurse Technician", value: "Nurse Technician" },
  // { tabValue: 7, key: "Doctor-Rad Doctor", value: "Radiology Doctor" },
]
export const allowedRoleForLabTech = [
  { tabValue: 0, key: 'Sensei', value: 'Sensei' },
  { tabValue: 1, key: 'Doctor-ED Doctor', value: 'ED Doctor' },

  // { tabValue: 3, key: "Doctor-Anesthesiologist", value: "Anesthesiologist" },
  // { tabValue: 4, key: "Doctor-ED Doctor", value: "ED Doctor" },
  // { tabValue: 5, key: "Nurse-EOU Nurse", value: "EOU Nurse" },
  // { tabValue: 6, key: "Nurse-Nurse Technician", value: "Nurse Technician" },
  // { tabValue: 7, key: "Doctor-Rad Doctor", value: "Radiology Doctor" },
]
export const allowedRoleForCustomerCareServices = [
  { tabValue: 0, key: 'Sensei', value: 'Sensei' },
  { tabValue: 1, key: 'Doctor-ED Doctor', value: 'ED Doctor' },
  { tabValue: 2, key: 'Nurse-ED Nurse', value: 'ED Nurse' },
  // { tabValue: 3, key: "Doctor-Anesthesiologist", value: "Anesthesiologist" },
  // { tabValue: 4, key: "Doctor-ED Doctor", value: "ED Doctor" },
  // { tabValue: 5, key: "Nurse-EOU Nurse", value: "EOU Nurse" },
  // { tabValue: 6, key: "Nurse-Nurse Technician", value: "Nurse Technician" },
  // { tabValue: 7, key: "Doctor-Rad Doctor", value: "Radiology Doctor" },
]
export const allowedRoleForNurseTech = [
  { tabValue: 0, key: 'Sensei', value: 'Sensei' },
  { tabValue: 1, key: 'Doctor-ED Doctor', value: 'ED Doctor' },
  { tabValue: 2, key: 'Nurse-ED Nurse', value: 'ED Nurse' },
  // { tabValue: 3, key: "Doctor-Anesthesiologist", value: "Anesthesiologist" },
  // { tabValue: 4, key: "Doctor-ED Doctor", value: "ED Doctor" },
  // { tabValue: 5, key: "Nurse-EOU Nurse", value: "EOU Nurse" },
  // { tabValue: 6, key: "Nurse-Nurse Technician", value: "Nurse Technician" },
  // { tabValue: 7, key: "Doctor-Rad Doctor", value: "Radiology Doctor" },
]

export const allowedRoleForEDNurse = [
  { tabValue: 0, key: 'Sensei', value: 'Sensei' },
  { tabValue: 1, key: 'Doctor-ED Doctor', value: 'ED Doctor' },
  { tabValue: 2, key: 'Lab Technician', value: 'Lab Technician' },
  { tabValue: 3, key: 'Imaging Technician', value: 'Imaging Tech' },
  { tabValue: 4, key: 'Nurse-Nurse Technician', value: 'Nurse Technician' },
  // { tabValue: 7, key: "Doctor-Rad Doctor", value: "Radiology Doctor" },
  // { tabValue: 3, key: "Doctor-Anesthesiologist", value: "Anesthesiologist" },
  // { tabValue: 5, key: "Nurse-EOU Nurse", value: "EOU Nurse" },
]

export const allowedRoleForEOUNurse = [
  { tabValue: 0, key: 'Sensei', value: 'Sensei' },
  { tabValue: 1, key: 'Production Team', value: 'Production Team' },
  // { tabValue: 2, key: "Lab Technician", value: "Lab Technician" },
  // { tabValue: 3, key: "Imaging Technician", value: "Imaging Tech" },
  // { tabValue: 4, key: "Nurse-Nurse Technician", value: "Nurse Technician" },
  // { tabValue: 7, key: "Doctor-Rad Doctor", value: "Radiology Doctor" },
  // { tabValue: 3, key: "Doctor-Anesthesiologist", value: "Anesthesiologist" },
  // { tabValue: 5, key: "Nurse-EOU Nurse", value: "EOU Nurse" },
]

export const allowedRolesForAnesthesiologist = [
  { tabValue: 0, key: 'Sensei', value: 'Sensei' },
  { tabValue: 1, key: 'Doctor-ED Doctor', value: 'ED Doctor' },
  { tabValue: 2, key: 'Nurse-ED Nurse', value: 'ED Nurse' },
  { tabValue: 3, key: 'Admin', value: 'Admin' },
]

export const allowedRolesForInternalConsultant = [
  { tabValue: 0, key: 'Doctor-ED Doctor', value: 'ED Doctor' },
  // { tabValue: 1, key: "Admin", value: "Admin" },
]

export const allowedRolesForExternalConsultant = [
  { tabValue: 0, key: 'Doctor-ED Doctor', value: 'ED Doctor' },
  // { tabValue: 1, key: "Admin", value: "Admin" },
]

export const allowedRolesForRadDoc = [
  { tabValue: 0, key: 'Imaging Technician', value: 'Imaging Technician' },
  { tabValue: 1, key: 'Sensei', value: 'Sensei' },
  { tabValue: 2, key: 'Doctor-ED Doctor', value: 'ED Doctor' },
  { tabValue: 3, key: 'Nurse-ED Nurse', value: 'ED Nurse' },
]

export const allowedRolesForPharmacist = [
  { tabValue: 0, key: 'Doctor-ED Doctor', value: 'ED Doctor' },
  { tabValue: 1, key: 'Sensei', value: 'Sensei' },
  { tabValue: 2, key: 'Admin', value: 'Admin' },
]

export const allowedRolesForParamedics = [
  { tabValue: 0, key: 'Sensei', value: 'Sensei' },
  { tabValue: 1, key: 'Doctor-ED Doctor', value: 'ED Doctor' },
  // { tabValue: 2, key: "Admin", value: "Admin" },
]

export const allowedRolesForHouseKeeping = [
  { tabValue: 0, key: 'Sensei', value: 'Sensei' },
  { tabValue: 1, key: 'Imaging Technician', value: 'Imaging Tech' },
  { tabValue: 2, key: 'Admin', value: 'Admin' },
]

export const allowedRoleForImagingTech = [
  { tabValue: 0, key: 'Sensei', value: 'Sensei' },
  { tabValue: 1, key: 'Doctor-ED Doctor', value: 'ED Doctor' },
  { tabValue: 2, key: 'Nurse-ED Nurse', value: 'ED Nurse' },
  { tabValue: 3, key: 'Doctor-Rad Doctor', value: 'Radiology Doctor' },
  { tabValue: 4, key: 'House Keeping', value: 'House Keeping' },
]

export function setUsrRoles(currentUser) {
  if (currentUser && currentUser.staffType === 'Admin') {
    return allowedRolesForAdmin
  } else if (currentUser && currentUser.staffType === 'Registration Officer') {
    return allowedRolesForRO
  } else if (currentUser && currentUser.staffType === 'Sensei') {
    return allowedRolesForSensei
  } else if (currentUser && currentUser.staffType === 'Doctor') {
    if (currentUser.subType.includes('Anesthesiologist')) {
      return allowedRolesForAnesthesiologist
    } else if (currentUser.subType.includes('ED Doctor')) {
      return allowedRolesForEDDoctor
    } else if (currentUser.subType.includes('Internal')) {
      return allowedRolesForInternalConsultant
    } else if (currentUser.subType.includes('External')) {
      return allowedRolesForExternalConsultant
    } else if (currentUser.subType.includes('Rad Doctor')) {
      return allowedRolesForRadDoc
    }
    return allowedRolesForEDDoctor
  } else if (currentUser && currentUser.staffType === 'Lab Technician') {
    return allowedRoleForLabTech
  } else if (currentUser && currentUser.staffType === 'Customer Care') {
    return allowedRoleForCustomerCareServices
  } else if (currentUser && currentUser.staffType === 'Nurses') {
    if (currentUser.subType.includes('ED Nurse')) {
      return allowedRoleForEDNurse
    } else if (currentUser.subType.includes('EOU Nurse')) {
      return allowedRoleForEOUNurse
    }
    return allowedRoleForNurseTech
  } else if (currentUser && currentUser.staffType === 'Clinical Pharmacist') {
    return allowedRolesForPharmacist
  } else if (currentUser && currentUser.staffType === 'Paramedics') {
    return allowedRolesForParamedics
  } else if (currentUser && currentUser.staffType === 'House Keeping') {
    return allowedRolesForHouseKeeping
  } else if (currentUser && currentUser.staffType === 'Imaging Technician') {
    return allowedRoleForImagingTech
  }
}
