import axios from 'axios'
import _ from 'lodash'
import { getCR } from '../../../public/endpoins'
export const getInternalConsultationRequestsCall = (
  setlabInPatient,
  setErrorMsg,
  setOpenNotification
) => {
  axios
    .get(getCR)
    .then((res) => {
      console.log('response', res.data.data)
      if (res.data.success) {
        res.data.data.map(
          (d) =>
            (d.patientName =
              d.patientId.name[0].given[0] + ' ' + d.patientId.name[0].family)
        )
        res.data.data.map((d) => (d.mrn = d.patientId.identifier[0].value))
        // res.data.data.map((d) => (d.gender = d.patientId.gender))
        // res.data.data.map(
        //   (d) => (d.requestNo = d.consultationNote.consultationNo)
        // )
        res.data.data.map((d) => (d.date = d.patientId.createdAt))
        var sortedObjForPen = _.sortBy(res.data.data, 'updatedAt').reverse()
        setlabInPatient(sortedObjForPen)
      } else if (!res.data.success) {
        setErrorMsg(res.data.error)
        setOpenNotification(true)
      }
      return res
    })
    .catch((e) => {
      console.log('error: ', e)
    })
}
