import axios from 'axios'
import { colorForDashboard } from '../../../utils/Constants'
import { getEdNurseDashboardData, getEOUDashboardDataURL } from '../../../public/endpoins'

export function getDashboardData(
  id,
  subType,
  setResponseData,
  setisLoading,
  setOpenNotification,
  setErrorMsg
) {
  let url
  if (subType.includes('ED Nurse')) {
    url = getEdNurseDashboardData
  } else if (subType.includes('EOU Nurse')) {
    url = getEOUDashboardDataURL
  }
  axios
    .get(url + "/" + id)
    .then((res) => {
      if (res.data.success) {
        console.log('response data for ED nurse ', res.data)
        setResponseData(res.data)
        setisLoading(false)
      } else if (!res.data.success) {
        setErrorMsg(res.data.error)
        setOpenNotification(true)
      }
      return res
    })
    .catch((e) => {
      console.log('error: ', e)
    })
}

export function setDialerGraphCall(
  firstValueComparisonNumber,
  secondValueComparisonNumber,
  cardObj,
  setDialorData,
  setDialorColor,
  firstTatComparisonNumber,
  secondTatComparisonNumber,
  setTAT,
  setTATColor,
  setGraphData
) {
  const { TAT, perHour, totalPending } = cardObj
  setGraphData(perHour)
  if (totalPending >= 0 && totalPending <= firstValueComparisonNumber) {
    setDialorColor(colorForDashboard.first)
  } else if (totalPending >= firstValueComparisonNumber + 1 && totalPending <= secondValueComparisonNumber) {
    setDialorColor(colorForDashboard.second)
  } else if (totalPending >= secondValueComparisonNumber + 1) {
    setDialorColor(colorForDashboard.third)
  }

  if (TAT >= 0 && TAT <= firstTatComparisonNumber) {
    setTATColor(colorForDashboard.first)
  } else if (
    TAT >= firstTatComparisonNumber + 1 &&
    TAT <= secondTatComparisonNumber
  ) {
    setTATColor(colorForDashboard.second)
  } else if (TAT >= secondTatComparisonNumber + 1) {
    setTATColor(colorForDashboard.third)
  }

  setDialorData(totalPending)
  setTAT(TAT)
}

export function setValuesCardCalls(
  firstVal,
  secondVal,
  data,
  valueCard,
  valueCardColor
) {
  console.log('response after fetch**', data)
  valueCard(data)
  if (data >= 0 && data <= firstVal) {
    valueCardColor(colorForDashboard.first)
  } else if (
    data >= firstVal + 1 &&
    data <= secondVal
  ) {
    valueCardColor(colorForDashboard.second)
  } else if (data >= secondVal + 1) {
    valueCardColor(colorForDashboard.third)
  }
}