/*eslint-disable*/
import React from 'react'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import MultiSlider, { Progress, Dot } from 'react-multi-bar-slider'

export default function BedsAvailableSlider(props) {
  const matches = useMediaQuery('(min-width:600px)')

  function handleSlide(newProgress) {
    console.log(newProgress)
    if (props.scale === 14) {
      var scaled
      if (newProgress > 0 && newProgress <= 7) {
        scaled = 0
      } else if (newProgress > 7 && newProgress <= 14) {
        scaled = 1
      } else if (newProgress > 14 && newProgress <= 21) {
        scaled = 2
      } else if (newProgress > 21 && newProgress <= 28) {
        scaled = 3
      } else if (newProgress > 28 && newProgress <= 35) {
        scaled = 4
      } else if (newProgress > 35 && newProgress <= 42) {
        scaled = 5
      } else if (newProgress > 42 && newProgress <= 49) {
        scaled = 6
      } else if (newProgress > 49 && newProgress <= 56) {
        scaled = 7
      } else if (newProgress > 56 && newProgress <= 63) {
        scaled = 8
      } else if (newProgress > 63 && newProgress <= 70) {
        scaled = 9
      } else if (newProgress > 70 && newProgress <= 77) {
        scaled = 10
      } else if (newProgress > 77 && newProgress <= 84) {
        scaled = 11
      } else if (newProgress > 84 && newProgress <= 91) {
        scaled = 12
      } else if (newProgress > 91 && newProgress <= 96) {
        scaled = 13
      } else if (newProgress > 96 && newProgress <= 100) {
        scaled = 14
      }
      props.onSlide ? props.onSlide(scaled) : ''
    } else {
      props.onSlide ? props.onSlide(newProgress) : ''
    }
  }

  return (
    <div
      style={{
        position: 'absolute',
        top: props.top ? props.top : 0,
        left: props.left ? props.left : 0,
        bottom: props.bottom ? props.bottom : 0,
      }}
    >
      <MultiSlider
        width={props.scale === 14 ? 280 : matches ? 280 : 245}
        height={17}
        slidableZoneSize={props.scale === 14 ? 100 : 113}
        backgroundColor='bisque'
        equalColor='blue'
        style={{ marginBottom: 40 }}
        onSlide={handleSlide}
        roundedCorners
        readOnly={props.readOnly}
      >
        <Progress
          color={
            props.progressValue > props.scale === 14
              ? 7
              : 80
                ? '#60d69f'
                : props.progressValue > props.scale === 14
                  ? 4
                  : 20
                    ? '#ffbc28'
                    : props.progressValue >= 0
                      ? '#d73c3f'
                      : '#d73c3f'
          }
        // progress={props.progressValue}
        >
          <Dot
            color={
              props.progressValue > props.scale === 14
                ? 7
                : 80
                  ? '#60d69f'
                  : props.progressValue > props.scale === 14
                    ? 4
                    : 20
                      ? '#ffbc28'
                      : props.progressValue >= 0
                        ? '#d73c3f'
                        : '#d73c3f'
            }
            width={0}
            height={20}
          />
        </Progress>

        <Progress color='#d73c3f' progress={props.scale === 14 ? 30 : 41}>
          <Dot
            color='grey'
            color={'blue'}
            width={14}
            height={30}
            style={{ display: 'none' }}
          />
        </Progress>

        <Progress color='#ffbc28' progress={props.scale === 14 ? 58 : 61}>
          <Dot color='grey' width={0} height={0} style={{ display: 'none' }} />
        </Progress>

        <Progress color='#60d69f' progress={props.scale === 14 ? 100 : 113}>
          <Dot color='grey' width={0} height={0} style={{ display: 'none' }} />
        </Progress>
      </MultiSlider>

      <div style={{ marginTop: 45 }}>
        <span
          style={{
            position: 'absolute',
            marginTop: -40,
            marginLeft: 1,
            fontSize: 13,
          }}
        >
          0
        </span>

        <span
          style={{
            position: 'absolute',
            marginTop: -40,
            marginLeft: props.scale === 14 ? 80 : matches ? 108 : 90,
            fontSize: 13,
          }}
        >
          {props.scale === 14 ? '4' : '40'}
        </span>

        <span
          style={{
            position: 'absolute',
            marginTop: -40,
            marginLeft: props.scale === 14 ? 158 : matches ? 166 : 140,
            fontSize: 13,
          }}
        >
          {props.scale === 14 ? '8' : '60'}
        </span>

        <span
          style={{
            position: 'absolute',
            marginTop: -40,
            marginLeft: props.scale === 14 ? 268 : matches ? 300 : 262,
            fontSize: 13,
          }}
        >
          {props.scale === 14 ? '14' : '113'}
        </span>
      </div>

      <div
        style={{
          marginLeft: !matches ? 0 : 0,
          width: 5,
          height: 30,
          backgroundColor: 'blue',
          zIndex: 3,
          marginTop: -68,
          borderRadius: 14,
          position: 'absolute',
          left: `${props.scale === 14
            ? props.progressValue * 7.1
            : !matches
              ? props.progressValue
              : props.progressValue
            }%`,
        }}
      >
        <span
          style={{
            marginTop: -23,
            position: 'absolute',
            marginLeft: -5,
            fontSize: 13,
            color: 'blue',
            fontWeight: 'bold',
          }}
        >
          {props.progressValue}
        </span>
      </div>
    </div>
  )
}
