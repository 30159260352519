function getTimeFromDate(date) {
  const d = new Date(date)

  let minutes = ''

  if (d.getHours().toString().length === 1) {
    minutes = '0' + d.getHours()
  } else {
    minutes = d.getHours()
  }

  // console.log('CALLED', minutes + ':' + ('00' + d.getMinutes()).slice(-2))
  return minutes + ':' + ('00' + d.getMinutes()).slice(-2)
}
export default getTimeFromDate
