import React, { useState, useEffect, useReducer } from "react";
import axios from "axios";
import {
  getSingleEdr, edrQRView, uploadsUrl
} from "../../public/endpoins";
import moment from 'moment';
import { displayInHumanDifference, replacePatientIdIfNull } from "../../utils/commonFunc";

function QRBracelets(props) {

  const [patientDetails, setPatientDetails] = useState("");
  const [qrData, setQrData] = useState("");
  const [nameDisplay, setNameDisplay] = useState(false);
  

  useEffect(() => {
    // console.log("Props passed ", props.history.location.state.selectedItem);
    let search = props.history.location.search;
    const params = new URLSearchParams(search);
    console.log("QRP", search, params.get('edrId'));
    console.log("QRP", search, params.get('nameDisplay'));

    setNameDisplay(params.get('nameDisplay')==="yes"?true:false);

    let edrId = params.get('edrId');
    if (edrId){
      axios
        .get(`${getSingleEdr}/${edrId}`)
        .then((res) => {
          let dataReturn = replacePatientIdIfNull(res.data.data);
          setPatientDetails(dataReturn);
          return res;
        })
        .catch((e) => {
          console.log("QRP error: ", e);
        });


      axios
        .get(`${edrQRView}/?edrId=${edrId}`, {
          edrId: edrId
        })
        .then((res) => {
          console.log("QRP", res.data.qr);
          setQrData(res.data.qr);
        })
        .catch((e) => {
          console.log("QRP error: ", e);
        });
    }



    // const { selectedItem } = props.history.location.state;
    
  }, []);

  return (
    <div
      style={{
        position: "fixed",
        display: "flex",
        width: "100%",
        height: "100%",
        flexDirection: "column",
        flex: 1,
        overflowY: "scroll",
        paddingBottom: 50,
      }}
    >
      { 
        patientDetails ? (
          <div className="d-flex align-items-center">
            <img src={qrData} width="120" />
            <div>
              {
                nameDisplay?
                <>
                  <strong>Name:</strong> { patientDetails.patientId.name[0].given[0] + " " + patientDetails.patientId.name[0].family }<br />
                  <strong>Date of Birth:</strong> { moment(patientDetails.patientId.birthDate).format('MMM DD, YYYY') }<br />
                  <strong>MRN:</strong> { patientDetails.patientId.identifier[0].value }<br />
                </>:undefined
              }
              <strong>Episode:</strong> {patientDetails.requestNo.toString()}
            </div>
          </div>
        ) : undefined
      }
    </div>
  );
}
export default QRBracelets;
