import axios from "axios";
export function savePatientData(
  patientRegistration,
  formData,
  setSaveData,
  setPatientId,
  setMRN,
  setIsPatientSubmitted,
  setOpenNotification,
  setsuccessMsg,
  setErrorMsg
) {
  axios
    .post(patientRegistration, formData, {
      headers: {
        accept: "application/json",
        "Accept-Language": "en-US,en;q=0.8",
        "content-type": "multipart/form-data",
      },
    })
    .then((res) => {
      if (res.data.success) {
        console.log(res.data.data, "patients data");
        setSaveData(res.data.data);
        setPatientId(res.data.data._id);
        setMRN(res.data.data.identifier);
        setIsPatientSubmitted(true);
        setOpenNotification(true);
        setsuccessMsg(
          "Patient details saved successfully, Generate ED Record now"
        );
      } else if (!res.data.success) {
        setOpenNotification(true);
      }
    })
    .catch((e) => {
      console.log("error after adding patient details", e);
      setOpenNotification(true);
      setErrorMsg("Error while adding patient details");
    });
}

export function editPatientData(
  updatePatientUrl,
  formData,
  setSaveData,
  setPatientId,
  setMRN,
  setIsPatientSubmitted,
  setOpenNotification,
  setsuccessMsg,
  setErrorMsg
) {
  axios
    .put(updatePatientUrl, formData)
    .then((res) => {
      if (res.data.success) {
        setSaveData(res.data.data);
        setPatientId(res.data.data._id);
        setMRN(res.data.data.identifier);
        setOpenNotification(true);
        setsuccessMsg(
          "Patient details updated successfully, Generate ED Record now"
        );
        setIsPatientSubmitted(true);
        // if (!searchActivated) {
        //   props.history.push({
        //     pathname: 'success',
        //     state: {
        //       message: `Details of Patient with MRN: ${res.data.data.identifier.toUpperCase()} Updated Successfully`,
        //     },
        //   })
        // }
      } else if (!res.data.success) {
        setOpenNotification(true);
        setErrorMsg("Error");
      }
    })
    .catch((e) => {
      console.log("error after updating patient details", e);
      setOpenNotification(true);
      setErrorMsg("Patient with same MRN already exists");
    });
}

export function patientSearch(
  e,
  SearchPatients,
  setItemFoundSuccessfully,
  setItemFound,
  setLoadSearchedData
) {
  axios
    .get(SearchPatients + "/" + e)
    .then((res) => {
      if (res.data.success) {
        if (res.data.data.length > 0) {
          console.log("patients search data ", res.data.data);
          setItemFoundSuccessfully(true);
          setItemFound(res.data.data);
          // setLoadSearchedData(false)
        } else {
          setItemFoundSuccessfully(false);
          setItemFound("");
          setLoadSearchedData(false);
        }
      }
    })
    .catch((e) => {
      console.log("error while searching patient", e);
    });
}

export function addSearchedPatient(
  getPatientByIdUrl,
  item,
  setPatientId,
  setName,
  setIdCardBack,
  setIdCardFront,
  setAddress,
  setTelecom,
  setContact,
  setphoto,
  setCoveredFamilyMember,
  setpaymentMethod,
  setInsuranceCard,
  setenableForm,
  setInsuranceForm,
  setenableNext,
  setSearchQuery,
  setsearchActivated,
  countriesList,
  setCities,
  setOpenNotification,
  setErrorMsg,
  dispatch
) {
  axios
    .get(getPatientByIdUrl + "/" + item._id)
    .then((res) => {
      if (res.data.success) {
        console.log("Data of selected banda ", res.data.data);

        let i = res.data.data;

        setPatientId(i._id);
        setName(i.name);
        dispatch({ field: "identifier", value: i.identifier });
        dispatch({ field: "gender", value: i.gender });
        dispatch({ field: "age", value: i.age });
        dispatch({ field: "birthDate", value: i.birthDate });
        dispatch({ field: "nationalID", value: i.nationalID });
        dispatch({ field: "height", value: i.height });
        dispatch({ field: "weight", value: i.weight });
        dispatch({ field: "blood", value: i.blood });
        dispatch({ field: "nationality", value: i.nationality });
        dispatch({ field: "otherDetails", value: i.otherDetails });
        dispatch({ field: "registrationStatus", value: i.registrationStatus });
        setIdCardBack(i.idCardBack ? i.idCardBack : "");
        setIdCardFront(i.idCardFront ? i.idCardFront : "");
        let cities = Object.entries(countriesList[0]);
        for (var x in cities) {
          let arr = cities[x];
          if (arr[0] === i.address[0].country) {
            console.log("cities", arr[1]);
            setCities(arr[1]);
          }
        }
        setAddress(i.address);
        setTelecom(i.telecom);
        setContact(i.contact);
        setphoto(i.photo);
        dispatch({ field: "insuranceNumber", value: i.insuranceNumber });
        dispatch({ field: "insuranceVendor", value: i.insuranceVendor });
        dispatch({ field: "coverageTerms", value: i.coverageTerms });
        dispatch({ field: "coPayment", value: i.coPayment });
        dispatch({ field: "coverageDetails", value: i.coverageDetails });
        setCoveredFamilyMember(i.coveredFamilyMember);
        setInsuranceCard(i.insuranceCard ? i.insuranceCard : "");
        setpaymentMethod(i.paymentMethod);

        if (i.paymentMethod[0].payment === "Insured") {
          setenableForm(false);
          setInsuranceForm(false);
          setenableNext(false);
        }
        if (i.paymentMethod[0].payment === "Uninsured") {
          setenableForm(true);
          setenableNext(true);
        }

        setSearchQuery("");
        setsearchActivated(true);
      }
    })
    .catch((e) => {
      console.log("Error while searching patient", e);
      setOpenNotification(true);
      setErrorMsg("Error while fetching details of patient");
    });
}

export function generateEDRecord(
  props,
  generateEDR,
  params,
  setsuccessMsg,
  setOpenNotification,
  setErrorMsg
) {
  axios
    .post(generateEDR, params)
    .then((res) => {
      if (res.data.success) {
        console.log(res.data.data, "generateEDRecord");
        console.log("propssss", props);

        setsuccessMsg("ED Record generated successfully");
        setOpenNotification(true);
        // props.history.goBack()
        props.props.history.push({
          pathname: "success",
          state: {
            message1: `ED Record for patient `,
            code: `MRN: ${res.data.data.requestNo.toUpperCase()}`,
            message2: ` generated successfully`,
            printBracelet: true,
            edrId: res.data.data._id,
            selectedItem: res.data.data
          },
        });
      }
    })
    .catch((e) => {
      setOpenNotification(true);
      setErrorMsg("ED Record already exists");
    });
}


export function generateEDEpisodeRecord(
  props,
  generateEDR,
  params,
  setsuccessMsg,
  setOpenNotification,
  setErrorMsg
) {
  axios
    .post(generateEDR, params)
    .then((res) => {
      if (res.data.success) {
        console.log(res.data.data, "generateEDRecord");
        console.log("propssss", props);

        setsuccessMsg("ED Record generated successfully");
        setOpenNotification(true);
        // props.history.goBack()
        props.props.history.push({
          pathname: "success",
          state: {
            code: `${res.data.data.requestNo.toUpperCase()}`,
            edrId: res.data.data._id,
            selectedItem: res.data.data
          },
        });
      }
    })
    .catch((e) => {
      console.log('generateEpisodeRecord', e);
      setOpenNotification(true);
      // setErrorMsg("ED Record already exists");
      setErrorMsg("Something went wrong. Episode Number is not generated.");
    });
}
