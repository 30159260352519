import axios from "axios";
import _ from "lodash";
import { func } from "prop-types";
import { radTestStats, searchRadTestsStats } from "../../public/endpoins";
import FormatDate from "../../utils/formatDate";

export const getPendingParamedicsEdr = (
  setlabInPatient,
  setErrorMsg,
  setOpenNotification
) => {
  axios
    .get(radTestStats)
    .then((res) => {
      if (res.data.success) {
        let mappedData = [];
        const { data } = res.data;
        data.forEach((item) => {
          for (let key in item.rads) {
            let obj = {
              ...item,
              testName: [key],
              noOfTests: item.rads[key],
            };
            // Extracting rads from obj to finalObj
            let finalObj = (({ rads, ...o }) => o)(obj);
            mappedData.push(finalObj);
          }
        });

        mappedData.map(
          (val) => (
            (val.ITDName = val.name[0].given[0] + " " + val.name[0].family),
            (val.ITD = val.identifier[0].value),
            (val.testName = val.testName[0])
          )
        );

        // res.data.data.map((d) => (d.createdAt = d.patientId.createdAt))
        // var sortedObjForPen = _.sortBy(res.data.data, 'createdAt').reverse()
        setlabInPatient(mappedData);
      } else if (!res.data.success) {
        setErrorMsg(res.data.error);
        setOpenNotification(true);
      }
      return res;
    })
    .catch((e) => {
      console.log("error: ", e);
    });
};

export const searchPendingEdrPatients = (
  a,
  setlabInPatient,
  setErrorMsg,
  setOpenNotification
) => {
  axios
    .get(searchRadTestsStats + "/" + a)
    .then((res) => {
      console.log("res", res);
      if (res.data.success) {
        let mappedData = [];
        const { data } = res.data;
        data.forEach((item) => {
          for (let key in item.rads) {
            let obj = {
              ...item,
              testName: [key],
              noOfTests: item.rads[key],
            };
            // Extracting rads from obj to finalObj
            let finalObj = (({ rads, ...o }) => o)(obj);
            mappedData.push(finalObj);
          }
        });

        mappedData.map(
          (val) => (
            (val.ITDName = val.name[0].given[0] + " " + val.name[0].family),
            (val.ITD = val.identifier[0].value),
            (val.testName = val.testName[0])
          )
        );

        // var searchSortedObjForPen = _.sortBy(
        //   res.data.data,
        //   'createdAt'
        // ).reverse()
        setlabInPatient(mappedData);
      } else if (!res.data.success) {
        setErrorMsg(res.data.error);
        setOpenNotification(true);
      }
    })
    .catch((e) => {
      console.log("error after searching patient request", e);
    });
};
