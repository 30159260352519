import axios from "axios";
import _ from "lodash";
import { searchSWAssistance, getSWAssistance } from "../../public/endpoins";
import FormatDate from "../../utils/formatDate";

export const getPendingParamedicsEdr = (
  setlabInPatient,
  setErrorMsg,
  setOpenNotification
) => {
  axios
    .get(getSWAssistance)
    .then((res) => {
      if (res.data.success) {
        res.data.data.map(
          (d) =>
            (d.patientName =
              d.patientId.name[0].given[0] + " " + d.patientId.name[0].family)
        );
        res.data.data.map(
          (d) => (
            (d.mrn = d.patientId.identifier[0].value),
            (d.roomNo = d.room[d.room.length - 1].roomId.roomNo)
          )
        );
        res.data.data.map(
          (val) => (
            (val.productionArea =
              val.chiefComplaint[val.chiefComplaint.length - 1] &&
              val.chiefComplaint[val.chiefComplaint.length - 1].chiefComplaintId
                ? val.chiefComplaint[val.chiefComplaint.length - 1]
                    .chiefComplaintId.productionArea[
                    val.chiefComplaint[val.chiefComplaint.length - 1]
                      .chiefComplaintId.productionArea.length - 1
                  ].productionAreaId.paName
                : "N/A"),
            ((val.SWID =
              val.socialWorkerAssistance[
                val.socialWorkerAssistance.length - 1
              ].requestedBy.identifier[0].value),
            (val.SWName =
              val.socialWorkerAssistance[val.socialWorkerAssistance.length - 1]
                .requestedBy.name[0].given[0] +
              " " +
              val.socialWorkerAssistance[val.socialWorkerAssistance.length - 1]
                .requestedBy.name[0].family))
          )
        );
        // res.data.data.map((d) => (d.status = d.dcdFormStatus))
        var sortedObjForPen = _.sortBy(res.data.data, "createdAt").reverse();
        setlabInPatient(sortedObjForPen);
      } else if (!res.data.success) {
        setErrorMsg(res.data.error);
        setOpenNotification(true);
      }
      return res;
    })
    .catch((e) => {
      console.log("error: ", e);
    });
};

export const searchPendingEdrPatients = (
  a,
  setlabInPatient,
  setErrorMsg,
  setOpenNotification
) => {
  axios
    .get(searchSWAssistance + "/" + a)
    .then((res) => {
      if (res.data.success) {
        res.data.data.map(
          (d) =>
            (d.patientName =
              d.patientId.name[0].given[0] + " " + d.patientId.name[0].family)
        );
        res.data.data.map(
          (d) => (
            (d.mrn = d.patientId.identifier[0].value),
            (d.roomNo = d.room[d.room.length - 1].roomId.roomNo)
          )
        );
        res.data.data.map(
          (val) => (
            (val.productionArea =
              val.chiefComplaint[val.chiefComplaint.length - 1] &&
              val.chiefComplaint[val.chiefComplaint.length - 1].chiefComplaintId
                ? val.chiefComplaint[val.chiefComplaint.length - 1]
                    .chiefComplaintId.productionArea[
                    val.chiefComplaint[val.chiefComplaint.length - 1]
                      .chiefComplaintId.productionArea.length - 1
                  ].productionAreaId.paName
                : "N/A"),
            ((val.SWID =
              val.socialWorkerAssistance[
                val.socialWorkerAssistance.length - 1
              ].requestedBy.identifier[0].value),
            (val.SWName =
              val.socialWorkerAssistance[val.socialWorkerAssistance.length - 1]
                .requestedBy.name[0].given[0] +
              " " +
              val.socialWorkerAssistance[val.socialWorkerAssistance.length - 1]
                .requestedBy.name[0].family))
          )
        );

        var searchSortedObjForPen = _.sortBy(
          res.data.data,
          "createdAt"
        ).reverse();
        setlabInPatient(searchSortedObjForPen);
      } else if (!res.data.success) {
        setErrorMsg(res.data.error);
        setOpenNotification(true);
      }
    })
    .catch((e) => {
      console.log("error after searching patient request", e);
    });
};
