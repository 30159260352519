import React, { useEffect, useState } from "react";
import cookie from "react-cookies";
import axios from "axios";
import Button from "@material-ui/core/Button";
import DownloadIcon from "../../../assets/img/Download Icon.png";
import PrintIcon from "../../../assets/img/Print Icon.png";
import Back from "../../../assets/img/Back_Arrow_black.png";
import Header from "../../../components/Header/HeaderGrey";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import DetailBlock from "../../../components/DHR/DCD/ViewScreen/detailBlock";
import DetailBlockForDCD from "../../../components/DHR/DCD/ViewScreen/detailBlockForDCD";
import formatDate from "../../../utils/formatDate";
import { uploadsUrl } from "../../../public/endpoins";
// Icons
import PatientDetailIcon from "../../../assets/img/Patient Details Icon.png";
import PastMedicalIcon from "../../../assets/img/Past Medical History Icon.png";
import InvestigationIcon from "../../../assets/img/Investigations Icon.png";
import PhysicalExamIcon from "../../../assets/img/Physical Exam Icon.png";
import ROSIcon from "../../../assets/img/ROS Icon.png";
import ActionPlanIcon from "../../../assets/img/Action & Plan Icon.png";
import covIcon from "../../../assets/img/Course Of Visit Icon.png";
import TriageAndAssessmentIcon from "../../../assets/img/Triage & Assessment Icon.png";

import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import Fab from "@material-ui/core/Fab";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";

import Notification from "../../../components/Snackbar/Notification.js";
import moment from "moment";
import CustomTable from "../../../components/Table/Table";
import ReportModel from "../../../components/ReportModel/ReportModel";


const capitalizeTextString = (text) =>{
    return text.toLowerCase().charAt(0).toUpperCase()+(text.slice(1).toLowerCase())
}

const styles = {
  save: {
    cursor: "pointer",
    borderRadius: 5,
    backgroundColor: "#2973CF",
    width: "180px",
    height: "50px",
    outline: "none",
    color: "white",
  },
};

function ActionButtons(props) {
  return (
    <>
      {props.totalCount ? (
        <>
          <Fab
            color="primary"
            size="small"
            style={{ marginRight: 5 }}
            onClick={() => props.changeCount(props.indexValue, "dec")}
          >
            <ArrowRightIcon
              style={{ fontSize: 40, color: "white" }}
            />
          </Fab>

          <span style={{ fontSize: 12 }}>
            {" "}
            {props.totalCount - props.currentCount + 1}/ {props.totalCount}
          </span>
          <Fab
            color="primary"
            size="small"
            style={{ marginLeft: 5 }}
            onClick={() => props.changeCount(props.indexValue, "inc")}
          >
            <ArrowLeftIcon
              style={{ fontSize: 40, color: "white" }}
            />
          </Fab>
        </>
      ) : (
        undefined
      )}
    </>
  );
}

const totalCountArray = [0, 0, 0, 0, 0, 0, 0, 0];

function ViewDCDForm(props) {
  const matches = useMediaQuery("(min-width:600px)");

  const [currentUser] = useState(cookie.load("current_user"));
  const [priorECG, setPriorECG] = useState([]);
  const [priorXray, setpriorXray] = useState([]);
  const [patientDetailsData, setpatientDetailsData] = useState([]);
  const [pastMedicalData, setpastMedicalData] = useState([]);
  const [rosData, setrosData] = useState([]);
  const [physicalExamData, setphysicalExamData] = useState([]);
  const [investigationsData, setinvestigationsData] = useState([]);
  const [actionPlanData, setactionPlanData] = useState([]);
  const [covData, setcovData] = useState([]);
  const [triageData, settriageData] = useState([]);
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setsuccessMsg] = useState("");
  const [openNotification, setOpenNotification] = useState(false);

  const [allLabReports, setAllLabReports] = useState([]);
  const [openViewReportsModal, setOpenViewReportsModal] = useState(false);
  const [reportData, setReportData] = useState("");

  const [count, setCount] = useState([1, 1, 1, 1, 1, 1, 1, 1]);

  function setData() {
    const propsData = props.history.location.state;
    console.log("ViewDCDForm::", propsData);

    Object.entries(propsData.dcdForm).map(([key, val]) => {
      if (key === "triageAssessment" && val.length > 0) {
        const selectedTriage = val[val.length - count[0]];
        totalCountArray[0] = val.length;




        let triageLevelCheck = [
          {
            subheading: "Triage Level (System)",
            description: selectedTriage.triageLevel.toString(),
          },
        ];
        if (selectedTriage.newTriageLevel && selectedTriage.newTriageLevel !== ""){
          triageLevelCheck.push({
            subheading: "Triage Level (Override)",
            description: selectedTriage.newTriageLevel.toString(),
          });
          triageLevelCheck.push({
            subheading: "Triage Level (Override) - Reason",
            description: selectedTriage.reasonChangeTriageLevel.toString(),
          });
        }

        let calcBMIText = (BMICalc) => {
          let BMIRangeText = "Healthy";
          if (BMICalc < 18.5){
            BMIRangeText = "Underweight";
          } else if (BMICalc >= 18.5 && BMICalc <= 24.9){
            BMIRangeText = "Healthy";
          } else if (BMICalc >= 25 && BMICalc <= 29.9){
            BMIRangeText = "Overweight";
          } else if (BMICalc >= 30){
            BMIRangeText = "Obese";
          }
          return BMIRangeText;
        }

        let arr = [
          {
            heading: "Triage History",
            columnSize: 4,
            subArray: [
              {
                subheading: "Date/Time",
                description: formatDate(selectedTriage.triageTime),
              },
              // {
              //   subheading: "Doctor/Staff",
              //   description: propsData.dcdForm.doctor,
              // },
              {
                subheading: "Triage No.",
                description: selectedTriage.triageRequestNo,
              },
            ],
          },
          {
            heading: "Triage Level",
            columnSize: 1,
            subArray: triageLevelCheck,
          },
          {
            heading: "Patient Vitals",
            columnSize: 4,
            subArray: [
              {
                subheading: "Heart Rate",
                description:
                  selectedTriage.heartRate !== "N/A"
                    ? selectedTriage.heartRate + " bpm"
                    : "",
              },
              {
                subheading: "Blood Pressure Systolic",
                description:
                  selectedTriage.bloodPressureSys !== "N/A"
                    ? selectedTriage.bloodPressureSys + " mmHg"
                    : "",
              },
              {
                subheading: "Blood Pressure Diastolic",
                description:
                  selectedTriage.bloodPressureDia !== "N/A"
                    ? selectedTriage.bloodPressureDia + " mmHg"
                    : "",
              },
              {
                subheading: "Respiratory Rate",
                description:
                  selectedTriage.respiratoryRate !== "N/A"
                    ? selectedTriage.respiratoryRate + " /min"
                    : "",
              },
              {
                subheading: "Temperature",
                description:
                  selectedTriage.temperature !== "N/A"
                    ? selectedTriage.temperature + " °C"
                    : "",
              },
              {
                subheading: "Blood Sugar Level",
                description:
                  selectedTriage.FSBS !== "N/A"
                    ? selectedTriage.FSBS + " mg/dL"
                    : "",
              },
              {
                subheading: "Pain Scale",
                description:
                  selectedTriage.painScale !== "N/A"
                    ? selectedTriage.painScale
                    : "",
              },
              {
                subheading: "Pulse OX",
                description:
                  selectedTriage.pulseOX !== "N/A"
                    ? selectedTriage.pulseOX + " SpO2"
                    : "",
              },
              {
                subheading: "Weight",
                description:
                  selectedTriage.weight !== "N/A"
                    ? selectedTriage.weight + ""
                    : "",
              },
              {
                subheading: "Size",
                description:
                  selectedTriage.SIZE !== "N/A"
                    ? selectedTriage.SIZE + ""
                    : "",
              },
              {
                subheading: "BMI",
                description:
                  selectedTriage.BMI !== "N/A"
                    ? selectedTriage.BMI + ""
                    : "",
              },
              {
                subheading: "Body Mass Index (BMI)",
                description:
                  selectedTriage.BMI !== "N/A"
                    ? calcBMIText(selectedTriage.BMI) + ""
                    : "",

              },
              {
                subheading: "Intraocular Pressure (IOP) - Left",
                description:
                  selectedTriage.IOP !== "N/A"
                    ? selectedTriage.IOP + ""
                    : "",
              },
              {
                subheading: "Intraocular Pressure (IOP) - Right",
                description:
                  selectedTriage.IOPRight !== "N/A"
                    ? selectedTriage.IOPRight + ""
                    : "",
              },
            ],
          },
          {
            heading: "Physical Examination",
            columnSize: 4,
            subArray: [
              {
                subheading: "General Appearance",
                description: selectedTriage.generalAppearance.toString(),
              },
              {
                subheading: "Respiratory",
                description: selectedTriage.respiratory.toString(),
              },
              {
                subheading: "Cardiac",
                description: selectedTriage.cardiac.toString(),
              },
              {
                subheading: "Abdomen",
                description: selectedTriage.abdomen.toString(),
              },
              {
                subheading: "Neurological",
                description: selectedTriage.neurological.toString(),
              },
              {
                subheading: "Glasgow Coma Scale/Score",
                description: selectedTriage.gcsScore.toString(),
              }
            ],
          },
        ];
        //UncommentMe
        settriageData(arr);
      }

      if (key === "patientDetails" && val.length > 0) {
        let arr = [],
          subArray = [];
        const details = val[val.length - count[1]].details;
        totalCountArray[1] = val.length;

        arr = [
          {
            heading: "Patient Details",
            columnSize: 4,
            subArray: [
              {
                subheading: "Time Seen",
                description: new Date().getHours() + ":" + new Date().getMinutes(),
              },
              {
                subheading: "Room",
                description:
                  propsData.selectedItem.room &&
                  propsData.selectedItem.room.length > 0
                    ? propsData.selectedItem.room[
                        propsData.selectedItem.room.length - 1
                      ].roomId.roomNo
                    : "Not Selected",
              },
              {
                subheading: "Age",
                description: propsData.selectedItem.patientId.age,
              },
              {
                subheading: "Gender",
                description: propsData.selectedItem.patientId.gender,
              },
              {
                subheading: "Nationality",
                description: propsData.selectedItem.patientId.nationality,
              },
            ]
          }
        ]


        subArray = [];
        for (let i = 0; i < details.length; i++) {
          let chips = details[i].chips;
          let value = details[i].value;
          let texts = details[i].Texts;
          let Dropdown = details[i].DropDowns;
          let allergiesValues = details[i].values;
          let allergiesOtherDetails = details[i].detail;


          if(details[i].name == "Severity"){
            subArray = [
              ...subArray,
              {
                subheading: details[i].name,
                description:
                  value <= 4
                    ? "Mild (" + value + ")"
                    : value <= 7
                    ? "Moderate (" + value + ")"
                    : "Severe (" + value + ")",
              },
            ];
          }else if(details[i].name == "Duration / Started"){

            Object.entries(Dropdown).map(([v, vv]) => {
              subArray = [
                ...subArray,
                {
                  subheading: vv.name,
                  description: vv.value
                },
              ];
            })
          }else{
            if (value){
              subArray = [
                ...subArray,
                {
                  subheading: details[i].name,
                  description: value,
                },
              ];
            }
            console.log("detailsI", details[i]);
            for (let j = 0; j < chips.length; j++) {
              let subChips = chips[j].subChips ? chips[j].subChips : [];
              if (subChips.length > 0) {
                for (let k = 0; k < subChips.length; k++) {
                  // if (subChips[k].selected === true) {
                  subArray = [
                    ...subArray,
                    {
                      subheading: chips[j].name,
                      description: chips[j].detail === "" ? " " : chips[j].detail,
                      subheadingType: subChips[k].name,
                    },
                  ];
                  // }
                }
              } else {
                console.log("chips[j]: ", chips[j]);
                subArray = [
                  ...subArray,
                  {
                    subheading:
                      chips[j].name +
                      `${
                        chips[j].selectedMedicines &&
                        chips[j].selectedMedicines.length
                          ? "(" + chips[j].selectedMedicines + ")"
                          : ""
                      }`,
                    description: chips[j].detail === "" ? " " : chips[j].detail,
                  },
                ];
              }

              if (chips[j].audio !== ""){
                subArray = [
                  ...subArray,
                  {
                    subheading: " ", //chip[j].name,
                    audio: (uploadsUrl.replace('uploads/', '')) + chips[j].audio
                  },
                ];
              }
            }
            for (let a = 0; a < texts.length; a++) {
              subArray = [
                ...subArray,
                {
                  subheading: details[i].name,
                  description: texts[a].value,
                },
              ];
            }
            for (let a = 0; a < allergiesValues.length; a++) {
              subArray = [
                ...subArray,
                {
                  subheading: details[i].name,
                  // description: allergiesValues[a].code + ": " + allergiesValues[a].name,
                  description: allergiesValues[a].name,
                },
              ];
            }

            if (details[i].voiceNotes) {
              if (details[i].voiceNotes.audio){
                subArray = [
                  ...subArray,
                  {
                    subheading: "",
                    audio: (uploadsUrl.replace('uploads/', '')) + details[i].voiceNotes.audio,
                  },
                ];
              }
              if (details[i].voiceNotes.notes){
                subArray = [
                  ...subArray,
                  {
                    subheading: "Additional Notes",
                    description: details[i].voiceNotes.notes,
                  },
                ];
              }
            }
          }
          arr = [
            ...arr,
            {
              heading: details[i].name,
              columnSize: 4,
              subArray:
                subArray.length > 0
                  ? subArray
                  : (subArray = [
                      {
                        subheading: "Section Not Filled Properly !",
                        description: " ",
                      },
                    ]),
            },
          ];
          subArray = [];
        }




        //UncommentMe
        setpatientDetailsData(arr);
      } else if (key === "pastMedicalHistory" && val.length > 0) {
        let arr = [],
          subArray = [];
        const details = val[val.length - count[2]].details;
        totalCountArray[2] = val.length;

        for (let i = 0; i < details.length; i++) {
          let chips = details[i].chips;
          let texts = details[i].Texts;
          let allergiesValues = details[i].values;
          let allergiesOtherDetails = details[i].detail;

          for (let j = 0; j < chips.length; j++) {
            let subChips = chips[j].subChips;
            if (subChips.length > 0) {
              for (let k = 0; k < subChips.length; k++) {
                if (subChips[k].selected === true) {
                  subArray = [
                    ...subArray,
                    {
                      subheading: chips[j].name,
                      description:
                        chips[j].detail === "" ? " " : chips[j].detail,
                      subheadingType: subChips[k].name,
                    },
                  ];
                }
              }
            } else {
              subArray = [
                ...subArray,
                {
                  subheading: chips[j].name,
                  description: chips[j].detail === "" ? " " : chips[j].detail,
                },
              ];
            }
          }
          for (let a = 0; a < texts.length; a++) {
            subArray = [
              ...subArray,
              {
                subheading: details[i].name,
                description: texts[a].value,
              },
            ];
          }
          for (let a = 0; a < allergiesValues.length; a++) {
            subArray = [
              ...subArray,
              {
                subheading: details[i].name,
                // description: allergiesValues[a].code + ": " + allergiesValues[a].name,
                description: allergiesValues[a].name,
              },
            ];
          }
          arr = [
            ...arr,
            {
              heading: details[i].name,
              columnSize: 1,
              subArray:
                subArray.length > 0
                  ? subArray
                  : (subArray = [
                      {
                        subheading: "Section Not Filled Properly !",
                        description: " ",
                      },
                    ]),
            },
          ];
          subArray = [];
        }

        console.log('ViewDCDForm::objForLabTests::arr::', arr);
        //UncommentMe
        setpastMedicalData(arr);
      } else if (key === "ROS" && val.length > 0) {
        let arr = [],
          subArray = [];
        const details = val[val.length - count[3]].details;

        totalCountArray[3] = val.length;

        for (let i = 0; i < details.length; i++) {
          let chip = details[i].chips;
          for (let j = 0; j < chip.length; j++) {
            subArray = [
              ...subArray,
              {
                subheading: chip[j].name,
                description: " ",
              },
            ];
          }
          arr = [
            ...arr,
            {
              heading: details[i].name,
              columnSize: 1,
              subArray: subArray,
            },
          ];
          subArray = [];
        }
        setrosData(arr);
      } else if (key === "physicalExam" && val.length > 0) {
        let arr = [],
          subArray = [];
        const details = val[val.length - count[4]].details;
        totalCountArray[4] = val.length;

        for (let i = 0; i < details.length; i++) {
          let chips = details[i].chips;

          if (details[i].scale && details[i].scale.name) {
            subArray = [
              ...subArray,
              {
                subheading: details[i].scale.name,
                description:
                  details[i].scale.value <= 4
                    ? "Mild (" + details[i].scale.value + ")"
                    : details[i].scale.value <= 7
                    ? "Moderate (" + details[i].scale.value + ")"
                    : "Severe (" + details[i].scale.value + ")",
              },
            ];
          }
          if (chips && chips.length > 0) {
            for (let j = 0; j < chips.length; j++) {
              let subChips = chips[j].subChips;
              let right = chips[j].right;
              let left = chips[j].left;
              let imageMarkers = chips[j].imageMarkers;

              if (subChips && subChips.length > 0) {
                for (let k = 0; k < subChips.length; k++) {
                  if (subChips[k].selected === true) {
                    subArray = [
                      ...subArray,
                      {
                        subheading: chips[j].name,
                        description:
                          chips[j].detail === "" ? " " : chips[j].detail,
                        subheadingType: subChips[k].name,
                      },
                    ];
                  }
                }
              } else if (
                (right && right.length > 0) ||
                (left && left.length > 0) ||
                (imageMarkers && imageMarkers.length > 0)
              ) {
                subArray = [
                  ...subArray,
                  {
                    subheading: chips[j].name,
                    description: chips[j].detail === "" ? " " : chips[j].detail,
                    detailsL: left,
                    detailsR: right,
                    detailsImageMarkers: imageMarkers,
                  },
                ];
              } else if (chips[j].name === "Add Skin Report") {
                subArray = [
                  ...subArray,
                  {
                    images: chips[j].image,
                  },
                ];
              } else {
                subArray = [
                  ...subArray,
                  {
                    subheading: chips[j].name,
                    description:
                      !chips[j].detail || chips[j].detail === ""
                        ? " "
                        : chips[j].detail,
                  },
                ];
              }
            }
          }
          arr = [
            ...arr,
            {
              heading: details[i].name,
              columnSize: details[i].name === "Physical Exam" ? 2 : 1,
              subArray:
                subArray.length > 0
                  ? subArray
                  : (subArray = [
                      {
                        subheading: "Section Not Filled Properly !",
                        description: " ",
                      },
                    ]),
            },
          ];
          subArray = [];
        }
        setphysicalExamData(arr);
      } else if (key === "investigation" && val.length > 0) {
        let arr = [],
          subArray = [];
        const details = val[val.length - count[5]].details;
        totalCountArray[5] = val.length;

        for (let i = 0; i < details.length; i++) {
          let options = [];
          let chips = details[i].chips;
          let texts = details[i].Texts;

          if (chips && chips.length > 0) {
            for (let j = 0; j < chips.length; j++) {
              if (
                chips[j].name === "Add ECG Report" ||
                chips[j].name === "Add CXR Report"
              ) {
                if (chips[j].name === "Add ECG Report") {
                  setPriorECG(chips[j].image);
                } else if (chips[j].name === "Add CXR Report") {
                  setpriorXray(chips[j].image);
                }
                subArray = [
                  ...subArray,
                  {
                    images: chips[j].image,
                  },
                ];
              } else if (
                chips[j].name === "Normal" ||
                chips[j].name === "Nml Except"
              ) {
                options.push(chips[j].name);
              } else {
                subArray = [
                  ...subArray,
                  {
                    subheading: chips[j].name,
                    description:
                      !chips[j].detail || chips[j].detail === ""
                        ? " "
                        : chips[j].detail,
                  },
                ];
              }
            }
          }
          if (texts && texts.length > 0) {
            for (let a = 0; a < texts.length; a++) {
              subArray = [
                ...subArray,
                {
                  subheading: texts[a].name,
                  description: texts[a].value,
                },
              ];
            }
          }
          arr = [
            ...arr,
            {
              heading:
                options.length > 0
                  ? details[i].name + " (" + options.map((d) => d) + ")"
                  : details[i].name,
              columnSize:
                details[i].name === "CBC" ||
                details[i].name === "Chemistries" ||
                details[i].name === "UA"
                  ? 3
                  : 1,
              subArray:
                subArray.length > 0
                  ? subArray
                  : (subArray = [
                      {
                        subheading: "Section Not Filled Properly !",
                        description: " ",
                      },
                    ]),
            },
          ];
          subArray = [];
        }
        setinvestigationsData(arr);
      } else if (key === "actionPlan" && val.length > 0) {
        let arr = [],
          subArray = [];
        const details = val[val.length - count[6]].details;
        totalCountArray[6] = val.length;

        /*for (let i = 0; i < details.length; i++) {
          let chip = details[i].chips;
          for (let j = 0; j < chip.length; j++) {
            subArray = [
              ...subArray,
              {
                subheading: chip[j].name,
                description:
                  chip[j].detail && chip[j].detail.length > 0
                    ? chip[j].detail
                    : " ",
              },
            ];
          }
          arr = [
            ...arr,
            {
              heading: details[i].name,
              columnSize: 1,
              subArray: subArray,
            },
          ];
          subArray = [];
        }*/


        // Differential Diagnosis
        subArray = [...subArray, { subheading: 'Action', description: capitalizeTextString(details.differentialDiagnosisRemarks) }];
        if (details.differentialDiagnosisVoiceNotes){
          subArray = [...subArray, { subheading: '', audio: (uploadsUrl.replace('uploads/', '')) + details.differentialDiagnosisVoiceNotes }];
        }
        subArray = [...subArray, { subheading: 'Plan', description: capitalizeTextString(details.differentialDiagnosisPlanRemarks) }];
        if (details.differentialDiagnosisPlanVoiceNotes){
          subArray = [...subArray, { subheading: '', audio: (uploadsUrl.replace('uploads/', '')) + details.differentialDiagnosisPlanVoiceNotes }];
        }
        arr = [ ...arr, { heading: 'Notes/Differential Diagnosis', columnSize: 1, subArray: subArray } ];
        subArray = [];


        // O2 Management
        subArray = [...subArray, { subheading: 'Status', description: capitalizeTextString(details.o2Check) }];
        subArray = [...subArray, { subheading: 'Action:', description: details.o2Remarks }];
        if ( details.o2VoiceNotes ){
          subArray = [...subArray, { subheading: '', audio: (uploadsUrl.replace('uploads/', '')) + details.o2VoiceNotes }];
        }
        subArray = [...subArray, { subheading: 'Plan:', description: details.o2PlanRemarks }];
        if ( details.o2PlanVoiceNotes ){
          subArray = [...subArray, { subheading: '', audio: (uploadsUrl.replace('uploads/', '')) + details.o2PlanVoiceNotes }];
        }
        arr = [ ...arr, { heading: 'O2', columnSize: 1, subArray: subArray } ];
        subArray = [];


        // IV Fluid Management
        subArray = [...subArray, { subheading: 'Status', description: capitalizeTextString(details.ivFluidsCheck) }];
        subArray = [...subArray, { subheading: 'Action', description: details.ivFluidsRemarks }];
        if ( details.ivFluidsVoiceNotes ){
          subArray = [...subArray, { subheading: '', audio: (uploadsUrl.replace('uploads/', '')) + details.ivFluidsVoiceNotes }];
        }
        subArray = [...subArray, { subheading: 'Plan', description: details.ivFluidsPlanRemarks }];
        if ( details.ivFluidsPlanVoiceNotes ){
          subArray = [...subArray, { subheading: '', audio: (uploadsUrl.replace('uploads/', '')) + details.ivFluidsPlanVoiceNotes }];
        }
        arr = [ ...arr, { heading: 'IV Fluids', columnSize: 1, subArray: subArray } ];
        subArray = [];


        // Medication Management
        subArray = [...subArray, { subheading: 'Status', description: capitalizeTextString(details.medicationsCheck) }];
        subArray = [...subArray, { subheading: 'Action', description: details.medicationsRemarks }];
        if ( details.medicationsVoiceNotes ){
          subArray = [...subArray, { subheading: '', audio: (uploadsUrl.replace('uploads/', '')) + details.medicationsVoiceNotes }];
        }
        subArray = [...subArray, { subheading: 'Plan', description: details.medicationsPlanRemarks }];
        if ( details.medicationsPlanVoiceNotes ){
          subArray = [...subArray, { subheading: '', audio: (uploadsUrl.replace('uploads/', '')) + details.medicationsPlanVoiceNotes }];
        }
        arr = [ ...arr, { heading: 'Medications', columnSize: 1, subArray: subArray } ];
        subArray = [];


        // Start Pain Management
        subArray = [...subArray, { subheading: 'Status', description: capitalizeTextString(details.painManagementCheck) }];
        subArray = [...subArray, { subheading: 'Action', description: details.painManagementRemarks }];
        if ( details.painManagementVoiceNotes ){
          subArray = [...subArray, { subheading: '', audio: (uploadsUrl.replace('uploads/', '')) + details.painManagementVoiceNotes }];
        }
        subArray = [...subArray, { subheading: 'Plan', description: details.painManagementPlanRemarks }];
        if ( details.painManagementPlanVoiceNotes ){
          subArray = [...subArray, { subheading: '', audio: (uploadsUrl.replace('uploads/', '')) + details.painManagementPlanVoiceNotes }];
        }
        arr = [ ...arr, { heading: 'Start Pain Management', columnSize: 1, subArray: subArray } ];
        subArray = [];


        // Investigation
        subArray = [...subArray, { subheading: 'Status', description: capitalizeTextString(details.investigationsCheck) }];
        subArray = [...subArray, { subheading: 'Action', description: details.investigationsRemarks }];
        if ( details.investigationsVoiceNotes ){
          subArray = [...subArray, { subheading: '', audio: (uploadsUrl.replace('uploads/', '')) + details.investigationsVoiceNotes }];
        }
        subArray = [...subArray, { subheading: 'Plan', description: details.investigationsRemarks }];
        if ( details.investigationsPlanVoiceNotes ){
          subArray = [...subArray, { subheading: '', audio: (uploadsUrl.replace('uploads/', '')) + details.investigationsPlanVoiceNotes }];
        }
        arr = [ ...arr, { heading: 'Investigation', columnSize: 1, subArray: subArray } ];
        subArray = [];



        setactionPlanData(arr);
      } else if (key === "courseOfVisit" && val.length > 0) {
        let arr = [],
          subArray = [];
        const details = val[val.length - count[7]].details;
        totalCountArray[7] = val.length;

        for (let i = 0; i < details.length; i++) {
          let text = details[i].Texts;
          let chip = details[i].chips;
          for (let j = 0; j < text.length; j++) {
            subArray = [
              ...subArray,
              {
                subheading: details[i].name,
                description:
                  text[j].value && text[j].value.length > 0
                    ? text[j].value
                    : "No details entered",
              },
            ];
          }
          for (let j = 0; j < chip.length; j++) {
            subArray = [
              ...subArray,
              {
                subheading: chip[j].name,
                description:
                  chip[j].detail && chip[j].detail.length > 0
                    ? chip[j].detail
                    : " ",
              },
            ];
          }
          arr = [
            ...arr,
            {
              heading: details[i].name,
              columnSize: 1,
              subArray: subArray,
            },
          ];
          subArray = [];
        }
        setcovData(arr);
      }
    });

    // Getting all Lab Requests
    let labRequestArray = [];
    if (props.history.location.state.selectedItem.labRequest){
      labRequestArray = props.history.location.state.selectedItem.labRequest;
    }

    labRequestArray.map(
      (d) => (
        (d.requester =
          d.requestedBy &&
          d.requestedBy.name[0].given[0] +
            " " +
            d.requestedBy.name[0].family),
        // (d.serviceCode = d.serviceId.identifier[0].value),
        (d.requestDate = moment(d.requestedAt).format('MMM DD, YYYY LT')),
        // (d.serviceName = d.serviceId.category + ": " + d.serviceId.name),
        (d.status = d.status)
      )
    );

    console.log('ViewDCDForm::objForLabTests::', labRequestArray);
    setAllLabReports(labRequestArray);

    console.log("ViewDCDForm::selectedItem::", propsData.selectedItem);
  }

  useEffect(() => {
    setData();
  }, [count]);

  useEffect(() => {
    setData();
  }, []);

  const handleSubmit = () => {
    console.log("Submit ");
  };

  function changeCount(index, step) {
    let arr = [...count];
    if (step === "inc") {
      if (arr[index] + 1 > totalCountArray[index]) {
        setOpenNotification(true);
        setErrorMsg("No record found before that record");
        return;
      }
      arr[index] = arr[index] + 1;
    } else {
      if (arr[index] - 1 <= 0) {
        setOpenNotification(true);
        setErrorMsg("No record found after that record");
        return;
      }
      arr[index] = arr[index] - 1;
    }
    setCount(arr);
  }

  if (openNotification) {
    setTimeout(() => {
      setOpenNotification(false);
      setErrorMsg("");
      setsuccessMsg("");
    }, 2000);
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        position: "fixed",
        width: "100%",
        height: "100%",
        overflowY: "scroll",
        border: "1p",
      }}
    >
      <Header history={props.history} />

      <div className="cPadding">
        <div
          className="col-12"
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <img
            style={{ width: 35, height: "inherit", marginRight: 10 }}
            src={DownloadIcon}
          />
          <img style={{ width: 35, height: "inherit" }} src={PrintIcon} />
        </div>
        <div
          className="subheader"
          style={{ marginLeft: "-15px", marginBottom: 45 }}
        >
          <div className="col-7">
            <img src={TriageAndAssessmentIcon} />
            <h4
              style={{
                color: "#000000",
                fontSize: matches ? 32 : 15,
                fontWeight: "bolder",
              }}
            >
              Triage & Assessment
            </h4>
          </div>

          <div className="col-5" style={{ padding: 0 }}>
            <ActionButtons
              changeCount={changeCount}
              indexValue={0}
              totalCount={totalCountArray[0]}
              currentCount={count[0]}
            />
          </div>
        </div>

        <div className="container-fluid">
          {triageData && triageData.length > 0 ? (
            triageData.map((arr) => {
              return (
                <DetailBlock
                  heading={arr.heading}
                  subArray={arr.subArray}
                  columnSize={arr.columnSize}
                />
              );
            })
          ) : (
            <div className="row">
              <div className="col d-flex justify-content-center">
                <span style={{ color: "#2F77D1" }}>
                  <h4>Form not filled yet !</h4>
                </span>
              </div>
            </div>
          )}
        </div>

        <div
          className="subheader"
          style={{ marginLeft: "-15px", marginBottom: 45 }}
        >
          <div className="col-7">
            <img src={PatientDetailIcon} />
            <h4
              style={{
                color: "#000000",
                fontSize: matches ? 32 : 15,
                fontWeight: "bolder",
              }}
            >
              Patient Details
            </h4>
          </div>

          <div className="col-5" style={{ padding: 0 }}>
            <ActionButtons
              changeCount={changeCount}
              indexValue={1}
              totalCount={totalCountArray[1]}
              currentCount={count[1]}
            />
          </div>
        </div>

        <div className="container-fluid">
          {patientDetailsData && patientDetailsData.length > 0 ? (
            patientDetailsData.map((arr) => {
              return (
                <DetailBlockForDCD
                  formName={"Patient Details"}
                  heading={arr.heading}
                  subArray={arr.subArray}
                  columnSize={arr.columnSize}
                />
              );
            })
          ) : (
            <div className="row">
              <div className="col d-flex justify-content-center">
                <span style={{ color: "#2F77D1" }}>
                  <h4>Form not filled yet !</h4>
                </span>
              </div>
            </div>
          )}
        </div>

        <div
          className="subheader"
          style={{ marginLeft: "-15px", marginBottom: 45 }}
        >
          <div className="col-7">
            <img src={PastMedicalIcon} />
            <h4
              style={{
                color: "#000000",
                fontSize: matches ? 32 : 15,
                fontWeight: "bolder",
              }}
            >
              Past Medical History
            </h4>
          </div>

          <div className="col-5" style={{ padding: 0 }}>
            <ActionButtons
              changeCount={changeCount}
              indexValue={2}
              totalCount={totalCountArray[2]}
              currentCount={count[2]}
            />
          </div>
        </div>

        <div className="container-fluid">
          {pastMedicalData && pastMedicalData.length > 0 ? (
            pastMedicalData.map((arr) => {
              return (
                <DetailBlockForDCD
                  formName={"Past Medical History"}
                  heading={arr.heading}
                  subArray={arr.subArray}
                  columnSize={arr.columnSize}
                />
              );
            })
          ) : (
            <div className="row">
              <div className="col d-flex justify-content-center">
                <span style={{ color: "#2F77D1" }}>
                  <h4>Form not filled yet !</h4>
                </span>
              </div>
            </div>
          )}
        </div>

        <div
          className="subheader"
          style={{ marginLeft: "-15px", marginBottom: 45 }}
        >
          <div className="col-7">
            <img src={ROSIcon} />
            <h4
              style={{
                color: "#000000",
                fontSize: matches ? 32 : 15,
                fontWeight: "bolder",
              }}
            >
              ROS
            </h4>
          </div>

          <div className="col-5" style={{ padding: 0 }}>
            <ActionButtons
              changeCount={changeCount}
              indexValue={3}
              totalCount={totalCountArray[3]}
              currentCount={count[3]}
            />
          </div>
        </div>

        <div className="container-fluid">
          {rosData && rosData.length > 0 ? (
            rosData.map((arr) => {
              return (
                <DetailBlockForDCD
                  formName={"ROS"}
                  heading={arr.heading}
                  subArray={arr.subArray}
                  columnSize={arr.columnSize}
                />
              );
            })
          ) : (
            <div className="row">
              <div className="col d-flex justify-content-center">
                <span style={{ color: "#2F77D1" }}>
                  <h4>Form not filled yet !</h4>
                </span>
              </div>
            </div>
          )}
        </div>

        <div
          className="subheader"
          style={{ marginLeft: "-15px", marginBottom: 45 }}
        >
          <div className="col-7">
            <img src={PhysicalExamIcon} />
            <h4
              style={{
                color: "#000000",
                fontSize: matches ? 32 : 15,
                fontWeight: "bolder",
              }}
            >
              Physical Exam
            </h4>
          </div>

          <div className="col-5" style={{ padding: 0 }}>
            <ActionButtons
              changeCount={changeCount}
              indexValue={4}
              totalCount={totalCountArray[4]}
              currentCount={count[4]}
            />
          </div>
        </div>

        <div className="container-fluid">
          {physicalExamData && physicalExamData.length > 0 ? (
            physicalExamData.map((arr) => {
              return (
                <DetailBlockForDCD
                  formName={"Physical Exam"}
                  heading={arr.heading}
                  subArray={arr.subArray}
                  columnSize={arr.columnSize}
                />
              );
            })
          ) : (
            <div className="row">
              <div className="col d-flex justify-content-center">
                <span style={{ color: "#2F77D1" }}>
                  <h4>Form not filled yet !</h4>
                </span>
              </div>
            </div>
          )}
        </div>

        <div
          className="subheader"
          style={{ marginLeft: "-15px", marginBottom: 45 }}
        >
          <div className="col-7">
            <img src={InvestigationIcon} />
            <h4
              style={{
                color: "#000000",
                fontSize: matches ? 32 : 15,
                fontWeight: "bolder",
              }}
            >
              Investigations
            </h4>
          </div>

          <div className="col-5" style={{ padding: 0 }}>
            <ActionButtons
              changeCount={changeCount}
              indexValue={5}
              totalCount={totalCountArray[5]}
              currentCount={count[5]}
            />
          </div>
        </div>

        <div className="container-fluid">
          {investigationsData && investigationsData.length > 0 ? (
            investigationsData.map((arr) => {
              return (
                <DetailBlock
                  heading={arr.heading}
                  subArray={arr.subArray}
                  columnSize={arr.columnSize}
                />
              );
            })
          ) : (
            <div className="row">
              <div className="col d-flex justify-content-center">
                <span style={{ color: "#2F77D1" }}>
                  <h4>Form not filled yet !</h4>
                </span>
              </div>
            </div>
          )}

          {/*
          
          <CustomTable
            tableData={allLabReports}
            tableDataKeys={[
              'requestId',
              'requestDate',
              'serviceName',
              'requester',
              'priority',
              'status',
            ]}
            tableHeading={[
              'Request ID',
              'Request Date',
              'Bundle/Test',
              'Requester',
              'Priority',
              'Status',
              'Action',
            ]}
            handleView={(item) => {
              console.log('ViewDCDForm::objForLabTests::item::', item);
              let pathname = "/dashboard/home/viewLaboratoryReport";
              let labRadStatus = item.status;
              if ( labRadStatus === "completed" ){

                setReportData({
                  subheading: "Lab Reports",
                  description: item.image
                });
                setOpenViewReportsModal(true);
                
                
                // props.history.push({
                //   pathname: pathname,
                //   state: {
                //     selectedItem: {
                //       labRadStatus,
                //       ...item,
                //       ...selectedRecord,
                //     }
                //   },
                // });
                
              }else{
                setOpenNotification(true);
                setErrorMsg("Reports can not be viewed untill completed.");
              }
            }}
            action={{ view: true }}
            borderBottomColor={"#60d69f"}
            borderBottomWidth={20}
          />
          {openViewReportsModal && reportData ? (
            <ReportModel
              reportData={reportData}
              openViewReportsModal={openViewReportsModal}
              handleClose={setOpenViewReportsModal}
            />
          ) : (
            undefined
          )}
          */}
        </div>

        <div
          className="subheader"
          style={{ marginLeft: "-15px", marginBottom: 45 }}
        >
          <div className="col-7">
            <img src={ActionPlanIcon} />
            <h4
              style={{
                color: "#000000",
                fontSize: matches ? 32 : 15,
                fontWeight: "bolder",
              }}
            >
              Action & Plan
            </h4>
          </div>

          <div className="col-5" style={{ padding: 0 }}>
            <ActionButtons
              changeCount={changeCount}
              indexValue={6}
              totalCount={totalCountArray[6]}
              currentCount={count[6]}
            />
          </div>
        </div>

        <div className="container-fluid">
          {actionPlanData && actionPlanData.length > 0 ? (
            actionPlanData.map((arr) => {
              return (
                <DetailBlock
                  heading={arr.heading}
                  subArray={arr.subArray}
                  columnSize={arr.columnSize}
                />
              );
            })
          ) : (
            <div className="row">
              <div className="col d-flex justify-content-center">
                <span style={{ color: "#2F77D1" }}>
                  <h4>Form not filled yet !</h4>
                </span>
              </div>
            </div>
          )}
        </div>

        <div
          className="subheader"
          style={{ marginLeft: "-15px", marginBottom: 45 }}
        >
          <div className="col-7">
            <img src={covIcon} />
            <h4
              style={{
                color: "#000000",
                fontSize: matches ? 32 : 15,
                fontWeight: "bolder",
              }}
            >
              Course of Visit
            </h4>
          </div>

          <div className="col-5" style={{ padding: 0 }}>
            <ActionButtons
              changeCount={changeCount}
              indexValue={7}
              totalCount={totalCountArray[7]}
              currentCount={count[7]}
            />
          </div>
        </div>

        <div className="container-fluid">
          {covData && covData.length > 0 ? (
            covData.map((arr) => {
              return (
                <DetailBlock
                  heading={arr.heading}
                  subArray={arr.subArray}
                  columnSize={arr.columnSize}
                />
              );
            })
          ) : (
            <div className="row">
              <div className="col d-flex justify-content-center">
                <span style={{ color: "#2F77D1" }}>
                  <h4>Form not filled yet !</h4>
                </span>
              </div>
            </div>
          )}
        </div>

        <div className="container-fluid">
          <div className="row" style={{ marginTop: "2%", marginBottom: "2%" }}>
            <div className="col-md-6 col-sm-6 col-6">
              <img
                onClick={() => props.history.goBack()}
                src={Back}
                style={{ width: 45, height: 40, cursor: "pointer" }}
              />
            </div>
            {/* <div className="col-md-6 col-sm-6 col-6 d-flex justify-content-end">
                            <Button
                                style={styles.save}
                                variant="contained"
                                color="default"
                                onClick={handleSubmit}
                            >
                                Submit
              				</Button>
                        </div> */}
          </div>
        </div>
      </div>

      <Notification
        msg={errorMsg}
        open={openNotification}
        success={successMsg}
      />
    </div>
  );
}
export default ViewDCDForm;
