import axios from "axios";
import _ from "lodash";
import {
  ParamedicsEdr,
  searchPendingPMEdr,
  searchCompletedPMEdr,
  EdrTransfer,
  TransferredPMEdr,
} from "../../public/endpoins";
import FormatDate from "../../utils/formatDate";

export const getCompletedParamedicsEdrCall = (
  setCompletedSenseiArray,
  setErrorMsg,
  setOpenNotification
) => {
  axios
    .get(TransferredPMEdr)
    .then((response) => {
      console.log("Response Completed", response.data);
      response.data.data.map(
        (d) =>
          (d.patientName =
            d.patientId.name[0].given[0] + " " + d.patientId.name[0].family)
      );
      response.data.data.map((d) => (d.mrn = d.patientId.identifier[0].value));
      response.data.data.map((d) => (d.gender = d.patientId.gender));
      response.data.data.map((d) => (d.age = d.patientId.age));
      response.data.data.map((d) => (d.createdAt = d.patientId.createdAt));
      response.data.data.map((d) => (d.status = d.dcdFormStatus));
      var sortedObj = _.sortBy(response.data.data, "createdAt").reverse();
      setCompletedSenseiArray(sortedObj);
    })
    .catch((error) => {
      console.log("Error", error);
      setOpenNotification(true);
      setErrorMsg(error);
    });
};

export function updatePending(
  props,
  staffId,
  edrId,
  setOpenNotification,
  setErrorMsg
) {
  // console.log('propss', props)
  const body = {
    edrId: edrId,
    staffId: staffId,
  };
  console.log("body", body);
  axios
    .put(EdrTransfer, body)
    .then((res) => {
      if (res.data.success) {
        console.log("response after", res.data);
        props.history.push({
          pathname: "success",
          state: {
            message1: `Transfer to ED completed Successfully`,
          },
        });
      } else if (!res.data.success) {
        setOpenNotification(true);
        setErrorMsg("Error while transferring to ED completed");
      }
    })
    .catch((e) => {
      setOpenNotification(true);
      setErrorMsg("A request is already assigned to this patient");
    });
}

export const getPendingParamedicsEdr = (
  setlabInPatient,
  setErrorMsg,
  setOpenNotification
) => {
  axios
    .get(ParamedicsEdr)
    .then((res) => {
      console.log("response 1", res.data.data);
      if (res.data.success) {
        res.data.data.map(
          (d) =>
            (d.patientName =
              d.patientId.name[0].given[0] + " " + d.patientId.name[0].family)
        );
        res.data.data.map((d) => (d.patientIdNumber = d.patientId.identifier[0].value));
        res.data.data.map((d) => (d.mrn = d.requestNo));
        res.data.data.map((d) => (d.gender = d.patientId.gender));
        res.data.data.map((d) => (d.age = d.patientId.age));
        res.data.data.map((d) => (d.createdAt = d.patientId.createdAt));
        // res.data.data.map((d) => (d.status = d.dcdFormStatus))
        var sortedObjForPen = _.sortBy(res.data.data, "createdAt").reverse();
        setlabInPatient(sortedObjForPen);
      } else if (!res.data.success) {
        setErrorMsg(res.data.error);
        setOpenNotification(true);
      }
      return res;
    })
    .catch((e) => {
      console.log("error: ", e);
    });
};

export const searchCompletedEdrPatients = (
  a,
  setCompletedSenseiArray,
  setErrorMsg,
  setOpenNotification
) => {
  console.log("A", a);

  axios
    .get(searchCompletedPMEdr + "/" + a)
    .then((res) => {
      console.log("res", res);
      if (res.data.success) {
        res.data.data.map(
          (d) =>
            (d.patientName =
              d.patientId.name[0].given[0] + " " + d.patientId.name[0].family)
        );
        res.data.data.map((d) => (d.mrn = d.patientId.identifier[0].value));
        res.data.data.map((d) => (d.gender = d.patientId.gender));
        res.data.data.map((d) => (d.age = d.patientId.age));
        res.data.data.map((d) => (d.createdAt = d.patientId.createdAt));
        res.data.data.map((d) => (d.status = d.dcdFormStatus));
        var searchSortedObj = _.sortBy(res.data.data, "createdAt").reverse();
        console.log(res.data.data, "patient data");
        setCompletedSenseiArray(searchSortedObj);
      } else if (!res.data.success) {
        setErrorMsg(res.data.error);
        setOpenNotification(true);
      }
    })
    .catch((e) => {
      console.log("error after searching patient request", e);
    });
};

export const searchPendingEdrPatients = (
  a,
  setlabInPatient,
  setErrorMsg,
  setOpenNotification
) => {
  console.log("A", a);

  axios
    .get(searchPendingPMEdr + "/" + a)
    .then((res) => {
      console.log("res", res);
      if (res.data.success) {
        res.data.data.map(
          (d) =>
            (d.patientName =
              d.patientId.name[0].given[0] + " " + d.patientId.name[0].family)
        );
        res.data.data.map((d) => (d.mrn = d.patientId.identifier[0].value));
        res.data.data.map((d) => (d.gender = d.patientId.gender));
        res.data.data.map((d) => (d.age = d.patientId.age));
        res.data.data.map((d) => (d.createdAt = d.patientId.createdAt));
        res.data.data.map((d) => (d.status = d.dcdFormStatus));

        var searchSortedObjForPen = _.sortBy(
          res.data.data,
          "createdAt"
        ).reverse();
        // console.log(res.data.data, 'patient data')
        setlabInPatient(searchSortedObjForPen);
      } else if (!res.data.success) {
        setErrorMsg(res.data.error);
        setOpenNotification(true);
      }
    })
    .catch((e) => {
      console.log("error after searching patient request", e);
    });
};
