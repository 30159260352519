import React from "react";
import MenuTree from "../../../../components/MenuTree/MenuTree";
import cookie from "react-cookies";
import Header from "../../../../components/Header/Header";

// Icons
import Back from "../../../../assets/img/Back_Arrow.png";
import MyDashboardIcon from "../../../../assets/img/My Dashboard.png";

// Registration Icons
import NotificationIcon from "../../../../assets/img/Notification.png";

//Anesthesiologists
import Anesthesiologists from "../../../../assets/img/Anesthesiologists.png";

// Insurance Icons
import RegisterOfficerIcon from "../../../../assets/img/PatientRegistration.png";

const adminPanel = [
  {
    img: Anesthesiologists,
    text: "Anesthesiologists",
  },
  {
    img: NotificationIcon,
    text: "Notifications",
    path: "/dashboard/underprogress",
  },
  {
    img: Anesthesiologists,
    text: "Anesthesiologists List",
    path: "/dashboard/home/secondaryroles/anesthesiologists/list",
  },
  {
    img: Anesthesiologists,
    text: "Anesthesiologists Shift List",
    path: "/dashboard/home/secondaryroles/anesthesiologists/shiftList",
  },

  //   {
  //     img: NotificationIcon,
  //     text: "ED Doctor / Physician Requests",
  //     path: "/dashboard/underprogress",
  //   },

  //   {
  //     img: NotificationIcon,
  //     text: "ED Doctor Patients",
  //     path: "",
  //   },
];

class Dashboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      openApps: false,
      currentUser: cookie.load("current_user"),
      userStaff: "",

      options: "",

      openHome: false,

      notificationArray: [],

      headingIcon: "",
    };
  }

  componentWillMount() {
    this.setState({
      currentUser: cookie.load("current_user"),
      userStaff: cookie.load("user_staff"),
    });
  }

  componentDidMount() {
    if (this.state.openHome)
      setTimeout(() => {
        document.getElementById("menu-open").checked = true;
      }, 120);

    if (this.state.currentUser.email === "admin@dhr.com") {
      this.setState({
        notificationArray: [
          {
            icon: MyDashboardIcon,
            title: "New Registrations Arriving",
          },
        ],
        headingIcon: RegisterOfficerIcon,
        headingTitle: "Admin Panel",
      });
    }
  }

  render() {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          position: "fixed",
          width: "100%",
          height: "100%",
          backgroundColor: "#2962CC",
          // overflow: "auto",
        }}
      >
        <Header history={this.props.history} />

        <MenuTree
          history={this.props.history}
          options={
            this.state.currentUser &&
            this.state.currentUser.staffType === "Admin"
              ? adminPanel
              : ""
          }
        />

        <div
          style={{
            position: "fixed",
            width: "100%",
            height: "10%",
            top: "90%",
          }}
        >
          <img
            onClick={() => this.props.history.goBack()}
            src={Back}
            style={{
              width: 45,
              height: 35,
              marginLeft: "5%",
              cursor: "pointer",
            }}
          />
        </div>
      </div>
    );
  }
}

export default Dashboard;
