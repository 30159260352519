import React from "react"
import ViewContainerCS from "../../../components/CareStream/ViewContainerCS"
import { viewTreatmentNFluidsStyles } from "../styles"

export default function Assign(props) {
  const classes = viewTreatmentNFluidsStyles()
  return (
    <div className={classes.root}>
      <div className="subheader" style={{ marginLeft: "-10px" }}>
        <div style={{ flex: 4, display: "flex", alignItems: "center" }}>
          <h4 style={{ color: "white", fontWeight: "700" }}>
            Treatments and Orders
          </h4>
        </div>
      </div>

      <ViewContainerCS
        arrayContainerCS={props.treatmentOrdersArray}
        heading="Treatment Order"
        buttonHeading="Treatment Order"
      />

      <div style={{ flex: 4, display: "flex", alignItems: "center" }}>
        <h4 style={{ color: "white", fontWeight: "700", marginLeft: 9 }}>
          IV Fluids
        </h4>
      </div>

      <ViewContainerCS
        arrayContainerCS={props.fluidsIVArray}
        heading="IV Fluids"
        buttonHeading="IV Fluids"
      />
    </div>
  )
}
