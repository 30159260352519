/*eslint-disable*/
import React, { useState, useEffect, useReducer } from "react";
// import "./staffStyles.js";
import Button from "@material-ui/core/Button";
import Notification from "../../../../components/Snackbar/Notification.js";
import CustomTable from "../../../../components/Table/Table";
import Loader from "react-loader-spinner";
import Header from "../../../../components/Header/Header";
import business_Unit from "../../../../assets/img/Patients.png";
import plus_icon from "../../../../assets/img/Plus.png";
import Back from "../../../../assets/img/Back_Arrow.png";
import "../../../../assets/jss/material-dashboard-react/components/loaderStyle.css";
import cookie from "react-cookies";

import getTimeFromDate from "../../../../utils/extractTimeFromDate";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { makeStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";

import { getAllPatients, searchEdrPatientsForCC } from "./networkCalls";

import TextField from "@material-ui/core/TextField";

import { assignPatientStyles } from "../../../CustomerCare/styles";

import InputAdornment from "@material-ui/core/InputAdornment";
import AccountCircle from "@material-ui/icons/SearchOutlined";

import BarCode from "../../../../assets/img/Bar Code.png";

import Fingerprint from "../../../../assets/img/fingerprint.png";
import Filter from "../../../../assets/img/Filter.png";
import QRCodeScannerComponent from "../../../../components/QRCodeScanner/QRCodeScanner";

const styles = {
  stylesForButton: {
    color: "white",
    cursor: "pointer",
    // borderRadius: 5,
    background: "#2c6ddd",
    // width: "140px",
    height: "50px",
    outline: "none",
  },
};
const useStyles = makeStyles((theme) => ({
  input: {
    "&:focus": {
      backgroundColor: "white",
      boxShadow: "none",
      borderRadius: 5,
    },
  },
  root: {
    "& .Mui-focused": {
      backgroundColor: "white",
      color: "black",
      boxShadow: "none",
    },
    "&:focus": {
      backgroundColor: "white",
      boxShadow: "none",
    },
    "& .MuiFormLabel-root": {
      fontSize: "10px",
      paddingRight: "45px",
    },
  },
}));

const filtersArray = [
  { key: "chiefComplaints", value: "Chief Complaints Allocated" },
  { key: "carestream", value: "Carestreams" },
  { key: "discharged", value: "Discharged" },
  { key: "currentlyTreated", value: "Currently Treated" },
];

const tableHeadingForDoctors = [
  "Request No",
  "MRN",
  "Patient Name",
  "Chief Complaint",
  "Room No",
  "CareStream",
  "Action",
];
const tableDataKeysForDoctors = [
  "requestNo",
  "mrn",
  "name",
  "chiefComplaint",
  "roomNo",
  "careStream",
];

const actions = { view: true };

export default function Staff(props) {
  const matches = useMediaQuery("(min-width:600px)");
  const classes = assignPatientStyles();
  const classes1 = useStyles();

  const [isLoading, setisLoading] = useState(true);
  const [staffData, setStaffData] = useState("");
  const [generalArray, setGeneralArray] = useState([]);
  const [filteredArray, setFilteredArray] = useState([]);
  const [doctorSpeciality, setDoctorSpeciality] = useState([]);

  const [errorMsg, setErrorMsg] = useState("");
  const [openNotification, setOpenNotification] = useState(false);

  const [filterDialog, setFilterDialog] = useState(false);
  const [shiftStartTime, setShiftStartTime] = useState("");
  const [shiftEndTime, setShiftEndTime] = useState("");

  const [experience, setExperience] = useState("");
  const [consultantType, setConsultantType] = useState("");

  const [searchPatientQuery, setSearchPatientQuery] = useState("");

  const [QRCodeScanner, setQRCodeScanner] = useState(false);

  const onChangeSpecialist = (e) => {
    setSpecialist(e.target.value);
    if (e.target.value) {
      const temp = generalArray.filter((val) =>
        val.specialty.find((s) => s === e.target.value)
      );
      setFilteredArray(temp);
    }
  };

  if (openNotification) {
    setTimeout(() => {
      setOpenNotification(false);
      setErrorMsg("");
    }, 2000);
  }

  useEffect(() => {
    getAllPatients(
      setStaffData,
      setGeneralArray,
      setOpenNotification,
      setErrorMsg,
      setisLoading
    );
  }, []);

  function setValuesInData(generalArray) {
    let senseiData = generalArray.map((d) => {
      let obj = {
        ...d,
        mrn: d.patientId.identifier[d.patientId.identifier.length - 1].value,
        name:
          d.patientId.name[d.patientId.name.length - 1].given[0] +
          " " +
          d.patientId.name[d.patientId.name.length - 1].family,
        chiefComplaint:
          d.chiefComplaint.length > 0
            ? d.chiefComplaint[d.chiefComplaint.length - 1].chiefComplaintId
                .name
            : "N/A",
        careStream:
          d.careStream.length > 0
            ? d.careStream[d.careStream.length - 1].name
            : "N/A",
        roomNo:
          d.room.length > 0 ? d.room[d.room.length - 1].roomId.roomNo : "N/A",
      };
      return obj;
    });

    setStaffData([...senseiData.reverse()]);
  }

  useEffect(() => {
    setValuesInData(generalArray);
  }, [generalArray]);

  const setDialogOpen = () => {
    setFilterDialog(true);
  };

  const handleClose = () => {
    setFilterDialog(false);
  };

  const handleRemove = (val, type) => {
    if (type === "speciality") {
      let spec = speciality.filter((s) => s !== val);
      setSpeciality(spec);
    } else {
      //   let spec = consultantType.filter((s) => s !== val);
      setConsultantType("");
    }
  };

  const checkValueAdded = (val, type) => {
    if (type === "speciality") {
      let spec = speciality.find((s) => s === val);
      return spec;
    }
  };

  const handleAddedValue = (val, type) => {
    if (type === "speciality") {
      let spec = speciality.find((s) => s === val);
      if (!spec) {
        setSpeciality((pervState) => [...pervState, val]);
      }
    } else {
      //   let spec = consultantType.find((s) => s === val);
      //   if (!spec) {
      setConsultantType(val);
      //   }
    }
  };

  console.log("consultantType", consultantType);

  function handleApplyFilters() {
    let res = [...generalArray];
    let resForSpeciality = [];
    let resForConsultant = [];

    if (experience !== "") {
      for (let j = 0; j < res.length; j++) {
        if (
          res[j].experience.length > 0 &&
          res[j].experience[res[j].experience.length - 1].experience ===
            experience
        ) {
          if (!resForSpeciality.find((i) => i._id === res[j]._id)) {
            resForSpeciality.push(res[j]);
          }
        }
      }
      res = resForSpeciality;
    }

    if (consultantType !== "") {
      for (let j = 0; j < res.length; j++) {
        if (
          res[j].status === "pending" &&
          consultantType === "currentlyTreated"
        ) {
          if (!resForConsultant.find((i) => i._id === res[j]._id)) {
            resForConsultant.push(res[j]);
          }
        } else if (
          res[j].status === "Discharged" &&
          consultantType === "discharged"
        ) {
          if (!resForConsultant.find((i) => i._id === res[j]._id)) {
            resForConsultant.push(res[j]);
          }
        } else if (
          res[j].chiefComplaint.length > 0 &&
          consultantType === "chiefComplaints"
        ) {
          if (!resForConsultant.find((i) => i._id === res[j]._id)) {
            resForConsultant.push(res[j]);
          }
        } else if (
          res[j].careStream.length > 0 &&
          consultantType === "carestream"
        ) {
          if (!resForConsultant.find((i) => i._id === res[j]._id)) {
            resForConsultant.push(res[j]);
          }
        }
      }
      res = resForConsultant;
    }

    setValuesInData(res);
    setFilterDialog(false);
  }

  function handleViewSinglePatient(patientObj) {
    console.log(patientObj);
    props.history.push({
      pathname: "/dashboard/home/viewpatient",
      state: {
        selectedItem: {
          ...patientObj.patientId,
          chiefComplaint: patientObj.chiefComplaint,
          roomNo: patientObj.roomNo,
          labRequest: patientObj.labRequest,
          radiologyRequest: patientObj.radRequest,
          careStream: patientObj.careStream,
        },

        comingFor:
          props.history.location.pathname ===
          "/dashboard/home/patientmgmt/patienthistory"
            ? "patient history"
            : "",
      },
    });
  }

  const handlePatientSearch = (e) => {
    let type = typeof e.target.value;
    console.log(type, "type of input");
    const a = e.target.value.replace(/[^\w+\s]/gi, "");
    setSearchPatientQuery(a);
    if (a.length >= 3) {
      searchEdrPatientsForCC(
        a,
        setGeneralArray,
        setErrorMsg,
        setOpenNotification
      );
    } else if (a.length == 0) {
      getAllPatients(
        setStaffData,
        setGeneralArray,
        setOpenNotification,
        setErrorMsg
      );
    }
  };

  function scanQRCode() {
    setQRCodeScanner((pervState) => !pervState);
  }

  function handleScanQR(data) {
    setQRCodeScanner(false);
    console.log(JSON.parse(data));
    if (JSON.parse(data).profileNo) {
      handlePatientSearch({
        target: {
          value: JSON.parse(data).profileNo,
          type: "text",
        },
      });
    }
    //  else if (JSON.parse(data).insuranceCardNumber) {
    //   dispatch({
    //     field: "insuranceNo",
    //     value: JSON.parse(data).insuranceCardNumber,
    //   });
    // }
  }

  if (QRCodeScanner) {
    return (
      <div>
        {QRCodeScanner ? (
          <QRCodeScannerComponent
            handleScanQR={handleScanQR}
            scanQRCode={scanQRCode}
          />
        ) : (
          undefined
        )}
      </div>
    );
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        position: "fixed",
        width: "100%",
        height: "100%",
        backgroundColor: "#60d69f",
        overflowY: "scroll",
      }}
    >
      <Header history={props.history} />

      <div className="cPadding">
        <div className="subheader">
          <div
            style={{
              display: "flex",
              flex: 1,
            }}
          >
            <img src={business_Unit} />
            <h4>
              {props.history.location.pathname ===
                "/dashboard/home/patientmgmt/patientlist" ||
              "/dashboard/home/patientmgmt/patientlist"
                ? "Patients List"
                : "Patients History"}
            </h4>
          </div>

          {props.history.location.pathname ===
          "/dashboard/home/patientmgmt/patientlist" ? (
            <Button
              onClick={setDialogOpen}
              style={{ ...styles.stylesForButton, height: matches ? 50 : 40 }}
              variant="contained"
              color="primary"
            >
              <img
                src={Filter}
                style={{ width: 20, height: 20, marginRight: 10 }}
              />{" "}
              <strong>Filter</strong>
            </Button>
          ) : (
            undefined
          )}
        </div>

        <div
          style={{
            flex: 4,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            className={`${"container-fluid"}`}
            style={{
              marginTop: "25px",
              paddingLeft: "10px",
              paddingRight: "10px",
            }}
          >
            <div className={`row  ${classes1.root} ${classes1.input} `}>
              <div
                className="col-md-10  col-8"
                style={{ paddingTop: 5, paddingLeft: 3, paddingRight: 3 }}
              >
                <TextField
                  className="textInputStyle"
                  id="searchPatientQuery"
                  type="text"
                  variant="filled"
                  label={
                    matches
                      ? "Search Patient by Name/MRN/National ID/Mobile No"
                      : "Search Patient by Name/MRN "
                  }
                  name={"searchPatientQuery"}
                  value={searchPatientQuery}
                  onChange={handlePatientSearch}
                  InputLabelProps={{
                    classes: {
                      root: classes.label,
                      focused: classes.focusedLabel,
                      error: classes.erroredLabel,
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <AccountCircle />
                      </InputAdornment>
                    ),
                    className: classes.input,
                    classes: { input: classes.input },
                    disableUnderline: true,
                  }}
                />
              </div>

              <div
                className="col-md-1 col-sm-2 col-2"
                style={{
                  ...styles.textFieldPadding,
                  paddingTop: 5,
                  paddingLeft: 3,
                  paddingRight: 3,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "white",
                    borderRadius: 5,
                    height: 55,
                  }}
                >
                  <img
                    src={BarCode}
                    style={{ width: 70, height: 60 }}
                    onClick={scanQRCode}
                  />
                </div>
              </div>

              <div
                className="col-md-1 col-sm-1 col-2"
                style={{
                  ...styles.textFieldPadding,
                  paddingTop: 5,
                  paddingLeft: 3,
                  paddingRight: 3,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "white",
                    borderRadius: 5,
                    height: 55,
                  }}
                >
                  <img src={Fingerprint} style={{ maxWidth: 43, height: 43 }} />
                </div>
              </div>
            </div>
          </div>

          {isLoading ? (
            <div className="LoaderStyle">
              <Loader type="TailSpin" color="red" height={50} width={50} />
            </div>
          ) : staffData.length !== " " ? (
            <div className="container-fluid">
              <div className="row">
                <CustomTable
                  tableData={staffData}
                  tableDataKeys={tableDataKeysForDoctors}
                  tableHeading={tableHeadingForDoctors}
                  action={actions}
                  borderBottomColor={"#60d69f"}
                  borderBottomWidth={20}
                  handleView={handleViewSinglePatient}
                />

                <Notification msg={errorMsg} open={openNotification} />
              </div>
            </div>
          ) : staffData.length === 0 ? (
            <div className="col-md-12 col-sm-12 col-12">
              <h3
                style={{
                  color: "white",
                  textAlign: "center",
                }}
              >
                Opps...No Data Found
              </h3>
            </div>
          ) : (
            undefined
          )}

          <div className="container-fluid">
            <div className="row" style={{ marginBottom: 20 }}>
              <img
                onClick={() => props.history.goBack()}
                src={Back}
                style={{
                  width: 45,
                  height: 35,
                  cursor: "pointer",
                }}
              />
            </div>
          </div>
        </div>
      </div>

      <Dialog
        maxWidth={"lg"}
        fullWidth
        open={filterDialog}
        onClose={handleClose}
      >
        <DialogTitle>Search Filters</DialogTitle>
        <DialogContent>
          <div style={{ marginTop: 10 }}>
            <div style={{}}>
              <span style={{ color: "grey" }}>Filters</span>
              <div style={{}}>
                {filtersArray &&
                  filtersArray.map((type) => {
                    return (
                      <Chip
                        key={type.key}
                        label={type.value}
                        onDelete={
                          consultantType === type.key
                            ? () => handleRemove(type.key, "consultantType")
                            : undefined
                        }
                        style={{
                          marginRight: 5,
                          marginTop: 10,
                          backgroundColor:
                            consultantType === type.key ? "#60D69F" : undefined,
                          color:
                            consultantType === type.key ? "white" : undefined,
                        }}
                        clickable
                        onClick={() =>
                          handleAddedValue(type.key, "consultantType")
                        }
                      />
                    );
                  })}
              </div>
            </div>
          </div>
        </DialogContent>

        <DialogActions
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button onClick={handleClose} variant="outlined">
            Cancel
          </Button>

          <Button
            onClick={handleApplyFilters}
            variant="contained"
            color="primary"
          >
            Apply Filters
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
