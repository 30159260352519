/*eslint-disable*/
import React, { useState, useEffect } from "react";
import cookie from 'react-cookies'
import Button from '@material-ui/core/Button'
import Notification from "../../../components/Snackbar/Notification.js";
import CustomTable from "../../../components/Table/Table";
import Loader from "react-loader-spinner";
import Header from "../../../components/Header/Header";
import addIcon from "../../../assets/img/Plus.png";
import shiftsIcon from "../../../assets/img/ED RoomsHistory.png";
import Back from '../../../assets/img/Back_Arrow.png'
import { styles } from './styles.js'
import {
  getShiftsData
} from './networkCalls'

const tableHeading = [
  "Shift ID",
  "Shift Name",
  "Start Time",
  "End Time",
  "Added By",
  "Actions",
];
const tableDataKeys = [
  "shiftId",
  "name",
  "startTimeFormatted",
  "endTimeFormatted",
  "addedBy",
];
const actions = { edit: true };

export default function Shifts(props) {

  const [currentUser] = useState(cookie.load('current_user'))
  const [errorMsg, setErrorMsg] = useState("");
  const [openNotification, setOpenNotification] = useState(false);
  const [shiftData, setShiftData] = useState([]);
  const [deleteItem, setdeleteItem] = useState("");
  const [modalVisible, setModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  if (openNotification) {
    setTimeout(() => {
      setOpenNotification(false);
      setErrorMsg("");
    }, 2000);
  }

  useEffect(() => {
    getShiftsData(
      setShiftData,
      setIsLoading,
      setErrorMsg,
      setOpenNotification,
    );
  }, []);

  const addNewItem = () => {
    let path = `shifts/add`;
    props.history.push({
      pathname: path,
      state: {
        comingFor: "add"
      },
    });
  };

  function handleEdit(rec) {
    let path = `shifts/edit`;
    props.history.push({
      pathname: path,
      state: {
        comingFor: "edit",
        selectedItem: rec
      },
    });
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        position: "fixed",
        width: "100%",
        height: "100%",
        backgroundColor: "#60d69f",
        overflowY: "scroll",
      }}
    >
      <Header history={props.history} />

      <div className='cPadding'>
        <div className='subheader' style={{ paddingRight: '15px' }}>
          <div >
            <img src={shiftsIcon} />
            <h4>Shifts Management</h4>
          </div>
          <Button
            variant="contained"
            style={styles.stylesForButton}
            onClick={addNewItem}
            color='primary'
          >
            <img src={addIcon} alt="add_Icon" className='icon-style mr-2' />
            Add New Shift
          </Button>
        </div>

        <div className='container-fluid'>

          <div className='row'>
            {shiftData.length > 0 && !isLoading ? (
              <div className='col-12'>
                <CustomTable
                  tableData={shiftData}
                  tableDataKeys={tableDataKeys}
                  tableHeading={tableHeading}
                  action={actions}
                  handleEdit={handleEdit}
                  borderBottomColor={"#60d69f"}
                  borderBottomWidth={20}
                />
              </div>
            ) : isLoading ? (
              <div className='LoaderStyle'>
                <Loader type='TailSpin' color='red' height={50} width={50} />
              </div>
            ) : (
                  <div className='col-12 m-4'>
                    <h3
                      style={{
                        color: "white",
                        textAlign: "center"
                      }}
                    >
                      Opps! No Data Found
                  </h3>
                  </div>
                )}
          </div>

          <div className='row mt-2'>
            <div className='col-12'>
              <img
                onClick={() => props.history.goBack()}
                src={Back}
                style={{
                  width: 45,
                  height: 35,
                  cursor: 'pointer',
                }}
              />
            </div>
          </div>

          <Notification msg={errorMsg} open={openNotification} />
        </div>
      </div>
    </div >
  );
}