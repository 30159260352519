/*eslint-disable*/
import React, { useState, useEffect, useReducer } from "react";
import axios from "axios";
import Button from "@material-ui/core/Button";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Notification from "../../components/Snackbar/Notification.js";
import CustomTable from "../../components/Table/Table";
import TextField from "@material-ui/core/TextField";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Loader from "react-loader-spinner";
import Header from "../../components/Header/Header";
import AnesthIcon from "../../assets/img/Anesthesiologists.png";
import eye_icon from "../../assets/img/Eye.png";
import Back from "../../assets/img/Back_Arrow.png";
import "../../assets/jss/material-dashboard-react/components/loaderStyle.css";
import cookie from "react-cookies";
import { styles, useStyles, useStylesForTabs } from "./styles.js";
import classNames from "classnames";

import {
  getRegisteredPharmaPendingReq,
  getRegisteredPharmaCompletedReq,
} from "../../public/endpoins";

const actions = { edit: true };

export default function RegisteredPharmaRequestList(props) {
  const classesForTabs = useStylesForTabs();
  const classes = useStyles();
  const matches = useMediaQuery("(min-width:600px)");
  const [currentUser, setCurrentUser] = useState(cookie.load("current_user"));

  const [ listType, setListType ] = useState(props.match.params.listType?props.match.params.listType:'pending');

  const capitalizeWord = s => s && s[0].toUpperCase() + s.slice(1)


  const [errorMsg, setErrorMsg] = useState("");
  const [openNotification, setOpenNotification] = useState(false);
  const [tabName, setTabName] = useState("Medication Request");
  const [buttonValue, setButtonValue] = useState("pending");
  const [pharmaRequests, setPharmaRequests] = useState("");

  if (openNotification) {
    setTimeout(() => {
      setOpenNotification(false);
      setErrorMsg("");
    }, 2000);
  }

  const getPatientRemotePharmaRequest = (sQuery) => {

    let searchURL = getRegisteredPharmaPendingReq; // + '/'+ currentUser._id;
    if ( listType === 'completed' ) {
      searchURL = getRegisteredPharmaCompletedReq; // + '/' + currentUser._id
    }
    if (sQuery){
      searchURL = searchURL + '?searchKeyword=' + sQuery;
    }

    console.log('searchURL::', searchURL);

    axios
      .get(searchURL)
      .then((res) => {
        console.log('Pending task', res)
        if (res.data.success) {
          res.data.data.map((val) => {
            val.patientName = val.patientId.name[0].given[0] + " " + val.patientId.name[0].family;
            val.roomNo = val.room[0].roomId.roomNo;
            // val.status = val.pharmacyRequest.nurseTechnicianStatus;
            val.pharmacyRequestId = val.pharmacyRequest.pharmacyRequestNo;
            val.pharmacyRequestUID = val.pharmacyRequest._id;
            // (val.serviceName = val.pharmacyRequest.serviceId.category + ': ' + val.pharmacyRequest.serviceId.name;
            // val.serviceRequestedBy = val.pharmacyRequest.requestedBy.name[0].given[0] + ' ' + val.pharmacyRequest.requestedBy.name[0].family;
            val.mrn = val.patientId.identifier[0].value;
            val.episodeNo = val.requestNo;
            // val.pharmacyRequestId = val.pharmacyRequest.requestNo;

            // Age
            var today = new Date();
            var birthDate = new Date(val.patientId.birthDate);
            var age_now = today.getFullYear() - birthDate.getFullYear();
            var m = today.getMonth() - birthDate.getMonth();
            if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
              age_now--;
            }
            val.ageNow = age_now;

            // Services
            let xStatus = "Completed";
            let rcmStatus = "Approved";
            let itemNames = [];

            let totalReported = 0;

            Object.entries(val.pharmacyRequest.item).map(([key, val]) => {
              itemNames.push(val.itemName.toLowerCase())
              if (val.status === "pending"){
                xStatus = 'Pending';
              }
            });


            if (totalReported == val.pharmacyRequest.item){
              val.reported = true;
            }else{
              val.reported = false;
            }


            val.itemNames = itemNames.join(',');
            val.statusTasks = '';//xStatus;
            console.log('itemNames::', itemNames);

          });
          console.log('patient name', res.data.data)
          // var allLabSorted = _.sortBy(res.data.data, "createdAt").reverse();
          setPharmaRequests(res.data.data.reverse())
        } else if (!res.data.success) {
          setErrorMsg(res.data.error)
          setOpenNotification(true)
        }
        return res
      })
      .catch((e) => {
        console.log('error: ', e)
      })
  }

  useEffect(() => {
    getPatientRemotePharmaRequest('');
  }, []);

  const handleButtonChange = (buttonValue) => {
    props.history.push({
      pathname: (listType === 'completed'?`/dashboard/home/rplist/pending`:`/dashboard/home/rplist/completed`),
    });
    props.history.go();
  };

  function handleEditPharma(rec) {
    console.log("rec", rec);

    if (
      rec.chiefComplaint.length > 0 &&
      rec.chiefComplaint &&
      rec.chiefComplaint[rec.chiefComplaint.length - 1].chiefComplaintId
    ) {
      rec.patientId.chiefComplaint =
        rec.chiefComplaint[rec.chiefComplaint.length - 1].chiefComplaintId.name;
    }

    props.history.push({
      pathname: "pharmanotes/requestWithPatientDetails",
      state: {
        selectedRecord: rec,
      },
    });
  }

  const handleView = (rec) => {
    let url = '/dashboard/home/rplist/details/'+rec._id+'/'+rec.pharmacyRequest._id;
    props.history.push({
      pathname: url,
    });
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        position: "fixed",
        width: "100%",
        height: "100%",
        backgroundColor: "#60d69f",
        overflowY: "scroll",
      }}
    >
      <Header history={props.history} />

      <div className="cPadding">
        <div className="subheader">
          <div>
            <img src={AnesthIcon} style={{ marginLeft: "0px" }} />
            <h4>{capitalizeWord(listType)} Registered Pharmacist</h4>
          </div>

          {listType === 'pending' ? (
            <Button
              onClick={() => handleButtonChange("completed")}
              style={{
                ...styles.stylesForButton,
                fontSize: matches ? " " : 10,
              }}
              variant="contained"
              color="primary"
            >
              <img src={eye_icon} className="icon-view" />
              &nbsp;&nbsp;
              <strong>Completed Tasks</strong>
            </Button>
          ) : (
            <Button
              onClick={() => handleButtonChange("pending")}
              style={{
                ...styles.stylesForButton,
                fontSize: matches ? " " : 10,
              }}
              variant="contained"
              color="primary"
            >
              <img src={eye_icon} className="icon-view" />
              &nbsp;&nbsp;
              <strong>Pending Tasks</strong>
            </Button>
          )}
        </div>

        <div
          className="container-fluid"
          style={{ paddingLeft: 0, paddingRight: 0 }}
        >

          <div className="row">
          {pharmaRequests !== ' ' ? (
            <>
            {
              Object.entries(pharmaRequests).map((obj, entry) => {
                return (
                  <div className="col-12 col-md-6 col-lg-6 col-xl-6">
                    <div className="card card-detail-info card-detail-small mb-2">
                      <div className="row row-5">
                        <div className="col-sm-12"><strong>Request #:</strong> <span>{obj[1].pharmacyRequestId}</span> &nbsp; <strong>PA/Bed#:</strong> <span>{obj[1].roomNo}</span></div>
                        <div className="col-sm-6  mt-3"><strong>Epi.#:</strong> <span>{obj[1].episodeNo}</span></div>
                        <div className="col-sm-6  mt-3"><strong>MRN:</strong> <span>{obj[1].mrn}</span></div>
                        <div className="col-sm-12 mt-3"><strong>Patient:</strong> <span>{obj[1].patientName} / {obj[1].gender==="Female"?"F":"M"} / {obj[1].ageNow}y</span></div>
                        <div className="col-sm-4 col-xl-2 mt-3"><strong>Item(s):</strong></div>
                        <div className="col-sm-6 col-xl-10 mt-md-3"><span className="line-clamp-1 text-capitalize">{obj[1].itemNames}</span></div>
                      </div>
                      <div className="card-status-action">
                        <span className={classNames(`badge`, `badge-light`)}>{obj[1].pharmacyRequest.item.length}</span>
                        { 
                          obj[1].urgent==="Yes"?<span className="ml-info">!</span>:undefined
                        }
                        <i className="ml-view ml-10 zmdi zmdi-eye zmdi-hc-2x" onClick={() => {
                          handleView(obj[1]);
                        }} />
                      </div>
                    </div>
                  </div>
                )
              })
            }
            <Notification msg={errorMsg} open={openNotification} />
            </>
          ) : (
            // <div className='LoaderStyle'>
            //   <Loader type='TailSpin' color='red' height={50} width={50} />
            // </div>
            <div className='row ' style={{ marginTop: '25px' }}>
              <div className='col-11'>
                <h3
                  style={{
                    color: 'white',
                    textAlign: 'center',
                    width: '100%',
                    position: 'absolute',
                  }}
                >
                  Opps...No Data Found
                </h3>
              </div>
              <div className='col-1' style={{ marginTop: 45 }}>
                {/* <img
                  onClick={() => props.history.goBack()}
                  src={Back_Arrow}
                  style={{
                    maxWidth: '60%',
                    height: 'auto',
                    cursor: 'pointer',
                  }}
                /> */}
              </div>
            </div>
          )}
          </div>

          <div className="row mt-3">
            <div className="col-md-12 col-sm-12 col-12">
              <img
                onClick={() => props.history.goBack()}
                src={Back}
                style={{
                  width: 45,
                  height: 35,
                  cursor: "pointer",
                }}
              />
            </div>
          </div>
        </div>

        <Notification msg={errorMsg} open={openNotification} />
      </div>
    </div>
  );
}
