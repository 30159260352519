/*
Heart rate:               between 60 and 100
Temperature:              between 36.1 and 37,2 Centigrades
Systolic blood:           between 90 and 20
Diastolic blood:          between 60 and 80
Respiratory rate:         between 12 and 18 bpm
Spo2:                     between 95 and 100%
Emergent    = 4
Urgent      = 3
Less Urgent = 2
Non Urgent  = 1
*/


export function getTriageLevelValue(
  isResuscitation,
  heartRate, triageLevel, generalAppearance, temperature, headNeck, respiratory, cardiac, abdomen, 
  neurological, bloodPressureSys, bloodPressureDia, painScale, respiratoryRate, pulseOX, dispatch
) {

  let levelMode = 0;

  // Checking Respiratory Rate
  if (respiratoryRate >= 12 && respiratoryRate <= 18 && levelMode<=0){
    levelMode = 0;
  } else if (respiratoryRate >= 19 && levelMode<1){
    levelMode = 1;
  } else if ((respiratoryRate >= 20 || respiratoryRate < 12) && levelMode<2){
    levelMode = 2;
  }

  // Checking Pulse Rate
  if (pulseOX >= 95 && pulseOX <= 100 && levelMode<=0){
    levelMode = 0;
  } else if (pulseOX > 92 && pulseOX < 95 && levelMode<1){
    levelMode = 1;
  } else if (pulseOX <= 92 && levelMode<2){
    levelMode = 2;
  }

  // Checking Systolic Rate
  if (bloodPressureSys >= 90 && bloodPressureSys <= 120 && levelMode<=0){
    levelMode = 0;
  } else if (bloodPressureSys > 120 && bloodPressureSys < 180 && levelMode<1){
    levelMode = 1;
  } else if ((bloodPressureSys >= 180 || bloodPressureSys < 90) && levelMode<2){
    levelMode = 2;
  }

  // Checking Systolic Rate
  if (bloodPressureDia >= 60 && bloodPressureDia <= 80 && levelMode<=0){
    levelMode = 0;
  } else if (bloodPressureDia > 80 && bloodPressureDia < 110 && levelMode<1){
    levelMode = 1;
  } else if ((bloodPressureDia >= 110 || bloodPressureDia <= 60) && levelMode<2){
    levelMode = 2;
  }

  // Checking Temperature Rate
  if (temperature >= 36.1 && temperature <= 37.2 && levelMode<=0){
    levelMode = 0;
  } else if (temperature > 37.2 && temperature < 38 && levelMode<1){
    levelMode = 1;
  } else if ((temperature >= 38 || temperature <= 36) && levelMode<2){
    levelMode = 2;
  }

  // Checking Heart Rate
  if (heartRate >= 60 && heartRate <= 90 && levelMode<=0){
    levelMode = 0;
  } else if (heartRate > 90 && heartRate < 100 && levelMode<1){
    levelMode = 1;
  } else if ((heartRate >= 100 || heartRate < 60) && levelMode<2){
    levelMode = 2;
  }


  if ( 
    (levelMode === 0 && painScale <= 3) ||
    (levelMode === 0 && painScale === 0)
  ){
    dispatch({ field: "triageLevel", value: ["NonUrgent"] });
  } else if (levelMode === 0 && painScale === 4){
    dispatch({ field: "triageLevel", value: ["LessUrgent"] });
  } else if (levelMode === 0 && (painScale >= 5 && painScale <= 7) ) {
    dispatch({ field: "triageLevel", value: ["Urgent"] });
  } else if (levelMode === 0 && painScale >= 8) {
    dispatch({ field: "triageLevel", value: ["Emergent"] });
  } else if (levelMode === 1 && (painScale >= 0 && painScale <= 7) ) {
    dispatch({ field: "triageLevel", value: ["Urgent"] });
  } else if (levelMode === 1 && painScale >= 8) {
    dispatch({ field: "triageLevel", value: ["Emergent"] });
  } else if (levelMode === 2) {
    dispatch({ field: "triageLevel", value: ["Emergent"] });
  }


  if ( isResuscitation ){
    dispatch({ field: "triageLevel", value: ["Resuscitation"] });
  }
  
  console.log('TriageAssessment::levelMode::', levelMode);
  console.log('TriageAssessment::painScale::', painScale);



  /*if (
    heartRate >= 60 && heartRate <= 100 &&
    temperature >= 36.1 && temperature <= 37.2 &&
    bloodPressureSys >= 90 && bloodPressureSys <= 120 &&
    bloodPressureDia >= 60 && bloodPressureDia <= 80 &&
    respiratoryRate >= 12 && respiratoryRate <= 18 && 
    pulseOX >= 95 && pulseOX <= 100
  ) {
    if(painScale == 4){
      dispatch({ field: "triageLevel", value: ["LessUrgent"] });
    }else if( painScale < 4 ){
      dispatch({ field: "triageLevel", value: ["NonUrgent"] });
    }else if( painScale >= 5 &&  painScale <= 7 ){
      dispatch({ field: "triageLevel", value: ["Urgent"] });
    }else if( painScale >= 8 ){
      dispatch({ field: "triageLevel", value: ["Emergent"] });
    }
  }else{
    let levelMode = 0;

  }*/









  /*





  if (
    heartRate > 90 &&
    temperature > 38 &&
    bloodPressureSys > 180 &&
    bloodPressureDia > 110 &&
    painScale >= 8 &&
    painScale <= 10 &&
    respiratoryRate > 20 &&
    pulseOX < 92
  ) {
    dispatch({ field: "triageLevel", value: ["Emergent"] });
  } else if (
    heartRate > 90 &&
    temperature > 38 &&
    bloodPressureSys > 180 &&
    bloodPressureDia > 110 &&
    painScale >= 5 &&
    painScale < 8 &&
    respiratoryRate > 20 &&
    pulseOX < 92
  ) {
    dispatch({ field: "triageLevel", value: ["Urgent"] });
  } else if (
    heartRate >= 60 && heartRate <= 100 &&
    temperature >= 36.1 && temperature <= 37.2 &&
    bloodPressureSys >= 90 && bloodPressureSys <= 120 &&
    bloodPressureDia >= 60 && bloodPressureDia <= 80 &&
    respiratoryRate >= 12 && respiratoryRate <= 18 && 
    pulseOX >= 95 && pulseOX <= 100 &&
    painScale == 4
  ) {
    dispatch({ field: "triageLevel", value: ["LessUrgent"] });
  } else if (
    heartRate >= 60 &&
    heartRate <= 100 &&
    temperature >= 36.1 &&
    temperature <= 37.2 &&
    bloodPressureSys > 120 && // ( > 90 to 120 )
    bloodPressureDia > 80 &&  // ( > 60 to 80 )
    respiratoryRate > 18 &&    // ( > 12 to 18)
    painScale >= 0 &&
    painScale < 4 &&
    pulseOX < 95 // ( < 95 to 100 )
  ) {
    dispatch({ field: "triageLevel", value: ["NonUrgent"] });
  }else{
    dispatch({ field: "triageLevel", value: ["Resuscitation"] });
  }
  */
}
