import React from "react";
import Avatar from "@material-ui/core/Avatar";
import FileViewer from "react-file-viewer";

import { makeStyles, withStyles, fade } from "@material-ui/core/styles";

import { FilePreviewerThumbnail } from "react-file-previewer";

import formatDateTime from "../../utils/formatDateTime";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  avatar: {
    height: 50,
    width: 50,
  },
  badgeImage: {
    backgroundColor: "#FF0C0C",
    color: "white",
  },
}));

function Sender(props) {
  const classes = useStyles();

  return (
    <div
      style={{
        display: "flex",
        marginTop: 20,
        flexDirection: "column",
        alignItems: props.for === "sender" ? "flex-end" : "flex-start",
        marginRight: 10,
        marginLeft: 10,
      }}
    >
      {props.type !== "" ? (
        <div
          style={{
            paddingLeft: 7,
            paddingRight: 7,
            paddingTop: 6,
            paddingBottom: 6,
            borderRadius: 10,
            // backgroundColor: props.for === "sender" ? "#2a5ec4" : "#d2d7e3",
            maxWidth: "100%",
            display: "flex",
            justifyContent: "flex-start",
          }}
        >
          {props.type !== "" ? (
            props.type.split("/")[0] === "image" ? (
              <a href={props.send} download target="_blank">
                <img
                  src={props.send}
                  style={{ width: 100, height: 100, display: "block" }}
                />
              </a>
            ) : props.type.split("/")[0] === "audio" ? (
              <audio controls>
                <source src={props.send} type="audio/mp3" />
              </audio>
            ) : (
              <a href={props.send} download target="_blank">
                {props.send.split("Z-")[1]}
              </a>
            )
          ) : (
            undefined
          )}
        </div>
      ) : (
        <div
          style={{
            paddingLeft: 7,
            paddingRight: 7,
            paddingTop: 6,
            paddingBottom: 6,
            borderRadius: 10,
            backgroundColor: props.for === "sender" ? "#2a5ec4" : "#d2d7e3",
            maxWidth: "100%",
         textAlign:'left'
          }}
        >
          <span
            style={{
              fontSize: 14,
              maxWidth:'90%',
              color: props.for === "sender" ? "white" : "black",
            }}
          >
            {props.send}
          </span>
        </div>
      )}

      <p
        style={{
          fontSize: 10,
          color: "grey",
        }}
      >
        {formatDateTime(props.time)}
      </p>
    </div>
  );
}

export default React.memo(Sender);
