import React, { useEffect, useState, useReducer } from "react";
import { withStyles } from '@material-ui/core/styles';
import Header from "../../components/Header/Header";
import classNames from "classnames";
import { 
  Button, 
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment,
  MenuItem,
  TextField,
  Select,
  useMediaQuery,
} from "@material-ui/core";
import cookie from "react-cookies";

import axios from "axios";
import {
  getSingleEdr,
  updatePorterPharmaDeliveryDetail,
} from "../../public/endpoins";

import useStylesForInput from "../../assets/jss/material-dashboard-react/inputStyle";
import styles from "./styles";
import Loader from "react-loader-spinner";
import Icon from '@material-ui/core/Icon';
import CloseIcon from '@material-ui/icons/Close';
import MicIcon from "@material-ui/icons/Mic";
import StopIcon from "@material-ui/icons/Stop";
import MicRecorder from "mic-recorder-to-mp3";
import AccountCircle from "@material-ui/icons/SearchOutlined";
import Notification from "../../components/Snackbar/Notification.js";

import LabIcon from "../../assets/img/Lab.png";
import { displayInHumanDifference, displayInHumanClassic, replacePatientIdIfNull } from "../../utils/commonFunc";
import PatientDetailsQuick from "../../components/PatientDetails/PatientDetailsQuick";
import PatientDetails from "../../components/PatientDetails/PatientDetails";
import PatientDetails2 from "../../components/PatientDetails/PatientDetailsDirect";
import Back from '../../assets/img/Back_Arrow.png'

export default function PorterPharmaDetails(props) {
  
  const matches = useMediaQuery("(min-width:600px)");
  const classes = useStylesForInput();

  const mystyle = {
    color: "white",
    backgroundColor: "DodgerBlue",
    padding: "10px",
    fontFamily: "Arial"
  };

  


  const initialState = {
    bulkTestSelected: false,
    resetedOn: "",
    defaultItems: {},
    seletedLabItems: [],
    labPriority: "",
    labTechnician: "",
    labComments: "",
    labTechniciansList: [],
  };
  function reducer(state, { field, value }) {
    return {
      ...state,
      [field]: value,
    };
  }
  const [state, dispatch] = useReducer(reducer, initialState);
  const {
    bulkTestSelected,
    resetedOn,
    defaultItems,
    seletedLabItems,
    labPriority,
    labTechnician,
    labComments,
    labTechniciansList,
  } = state;

  const handleCheckupChange = (event) => {
    dispatch({field: 'bulkTestSelected', value: event.target.value});
  };

  const uniqueCodeField = (name) => {
    name = name.toLowerCase();
    name = name.replace(/[^a-z0-9]+/g, '-');
    name = name.replace(/^-+|-+$/g, '');
    return name;
  }

  const [currentUser] = useState(cookie.load("current_user"));
  const [timer, setTimer] = useState(null);
  const [selectedLab, setSelectedLab] = useState("");
  const [categoriesData, setCategoriesData] = useState([]);
  const [mItems, setMItems] = useState([]);

  const [patientDetails, setPatientDetails] = useState("");
  const [selectedRecord, setSelectedRecord] = useState({});

  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setsuccessMsg] = useState("");
  const [openNotification, setOpenNotification] = useState(false);

  const [ episodeId, setEpisodeId ] = useState(props.match.params.id);
  const [ pharmaRequestId, setPharmaRequestId ] = useState(props.match.params.requestId);
  const [ pharmaRequest, setPharmaRequest ] = useState({});
  const [ pharmaServices, setPharmaServices ] = useState([]);
  const [ hasPending, setHasPending] = useState(false);

  
  if (openNotification) {
    setTimeout(() => {
      setOpenNotification(false);
      setErrorMsg("");
      setsuccessMsg("");
    }, 9000);
  }

  useEffect(() => {
    axios
      .get(getSingleEdr + "/" + episodeId)
      .then((res) => {
        console.log('handleView::', res.data.data);
        if (res.data.success) {

          let remoteRecord = replacePatientIdIfNull(res.data.data);
          setSelectedRecord(remoteRecord);

          const object = {
            '_id': remoteRecord._id,
            patientName: remoteRecord.patientId.name[0].given[0] + " " + remoteRecord.patientId.name[0].family,
            episodeNumber: remoteRecord.requestNo,//remoteRecord.patientId.identifier[0].value,
            mrn: remoteRecord.patientId.identifier[0].value,
            age: remoteRecord.patientId.age,
            gender: remoteRecord.patientId.gender,
            weight: remoteRecord.patientId.weight,
            department: remoteRecord.currentLocation,
            chiefComplaint: remoteRecord.chiefComplaint.length > 0 ? remoteRecord.chiefComplaint.pop().chiefComplaintId.short_desc : "N/A",
            chiefComplaintOthers: remoteRecord.chiefComplaintOthers,
          };
          setPatientDetails(object);

          let pharmaServicesX = remoteRecord.pharmacyRequest.filter(obj => obj._id === pharmaRequestId);
          console.log('pharmaServicesX', pharmaServicesX, pharmaServicesX.length);
          if (pharmaServicesX.length > 0){
            let labXN = pharmaServicesX[0];
            labXN.requestedName = labXN.requestedBy.name[0].given[0] + " " + labXN.requestedBy.name[0].family;
            console.log('pharmaServicesX', labXN.requestedBy.name);
            console.log('pharmaServicesX', labXN);
            setPharmaRequest(labXN)
            // setPharmaRequest(pharmaServicesX[0])
            setPharmaServices(pharmaServicesX[0].item);
          }

        }
      })
      .catch((e) => {
        console.log("error after searching patient request", e);
      });
    /*getLabTechniciansCall( currentUser._id, dispatch, setOpenNotification, setErrorMsg );*/
  }, []);

  const handleView = (rec) => {
    console.log('rec::rec::', rec);
    if (rec.nurseTechnicianStatus === "Collected"){
      let url = '/dashboard/home/porter/details/'+episodeId+'/'+pharmaRequestId+'/view/'+rec.requestId+'';
      props.history.push({
        pathname: url,
      });
    }else{
      // window.alert(`The task for ${rec.category}: ${rec.name} is not completed by Nurse Technician yet.`)
      setErrorMsg(`The Sample for ${rec.category}: ${rec.name} is not available!`)
      setOpenNotification(true);
    }
  }

  const handleSaveArrived = () => {
    setOpenNotification(true);
    setsuccessMsg(`The Lab Request has been marked as Arrived!`);
  }

  const handleCompleteAll = () => {
    axios
        .post(updatePorterPharmaDeliveryDetail, {
          edrId: episodeId,
          requestId: pharmaRequestId,
          staffId: currentUser._id,
        })
        .then((res) => {
          if (res.data.success) {
            setOpenNotification(true);
            setsuccessMsg(`The Pharma Request has been marked as delivered!`);
            window.location.reload();
          }
        })
        .catch((e) => {
          setOpenNotification(true);
          setErrorMsg("Unable to Update the Record");
        });
  }

  return (
    
    <div
      style={{
        backgroundColor: "rgb(19 213 159)",
        position: "fixed",
        display: "flex",
        width: "100%",
        height: "100%",
        flexDirection: "column",
        flex: 1,
        overflowY: "scroll",
      }}
    >
      <Header history={props.history} />

      <PatientDetailsQuick
        edrId={episodeId} />

      <div className="cPadding">

        {patientDetails && pharmaRequest ? (
          <div className="mb-3">
            <div className="card p-3">
              <div className="row row-5">
                <div className="col-6 col-lg-3 card-detail-block block-xdiv mb-2"><strong className="lblX">Episode #</strong><span className="valX">{patientDetails.episodeNumber}</span></div>
                <div className="col-6 col-lg-3 card-detail-block block-xdiv mb-2"><strong className="lblX">MRN #</strong><span className="valX">{patientDetails.mrn}</span></div>
                <div className="col-6 col-lg-3 card-detail-block block-xdiv mb-2"><strong className="lblX">Requester</strong><span className="valX">{pharmaRequest.requestedName}</span></div>
                <div className="col-6 col-lg-3 card-detail-block block-xdiv mb-2"><strong className="lblX">Date and Time</strong><span className="valX">{displayInHumanClassic(pharmaRequest.requestedAt)}</span></div>
              </div>
            </div>
          </div>
        ) : (
          undefined
        )}

        <h4 className="h6 text-white mb-3"
          style={{
            fontWeight: '700'
          }}>{pharmaRequest.pharmacyRequestNo} Details</h4>

        <div className="row row-5">
          {
            pharmaServices ? 
            <>
              <div className="col-md-12">
                <div className="card card-detail-info card-detail-small mb-2">
                {
                  Object.entries(pharmaServices).map((obj, entry) => {
                    return (
                      <>
                        <div className="row row-5">
                          <div className="col-sm-10 col-md-11 card-detail-block block-xdiv"><strong className="lblX">Item Name</strong><span className="valX"><strong className="text-capitalize">{obj[1].itemName.toLowerCase()}</strong></span></div>
                          <div className="col-sm-2 col-md-1 card-detail-block block-xdiv mt-1"><strong className="lblX">Requested Qty</strong><span className="valX">{(Math.ceil(obj[1].frequency)>=11 || Math.ceil(obj[1].frequency)===0)?obj[1].requestedQty:obj[1].dosage*obj[1].duration*obj[1].frequency}</span></div>
                          {
                            (entry < (pharmaServices.length-1)?<div className="col-sm-12"><hr /></div>:undefined)
                          }
                        </div>
                      </>
                    )
                  })
                }
                </div>
              </div>
              <div className="col-md-6 offset-md-3 mt-3 mb-4 text-center">
                {
                  pharmaServices.length>0?
                  <Button
                    // className="addButton"
                    style={{
                      backgroundColor: "#AD6BBF",
                      color: "white",
                      cursor: "pointer",
                      borderRadius: 5,
                      // backgroundColor: "rgb(173, 107, 191)",
                      height: matches ? 56 : 45,
                      outline: "none",
                      width: "100%",
                    }}
                    onClick={handleCompleteAll}
                    variant="contained"
                    color="primary"
                    disabled={pharmaRequest.deliveryStatus === 'pending'?false:true}
                    fullWidth
                  >{pharmaRequest.deliveryStatus === 'pending'?"Pending Delivery":"Delivered"}</Button>:undefined
                }
              </div>
            </>:undefined
          }
        </div>
        <div className='row mt-4 mb-4'>
          <div className='col-md-12 col-sm-12 col-12'>
            <img
              onClick={() => {
                let url = '/dashboard/home/porter?type=pharma';
                props.history.push({
                  pathname: url,
                });
              }}
              src={Back}
              style={{
                width: 45,
                height: 35,
                cursor: 'pointer',
              }}
            />
          </div>
        </div>
      </div>
      <Notification
        msg={errorMsg}
        open={openNotification}
        success={successMsg}
      />
    </div>
  );
}