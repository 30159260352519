import { Redirect } from "react-router-dom"
import cookie from "react-cookies"
import Header from "../../../../components/Header/Header"
import Managementt from "../../../../assets/img/Management.png"
import CareStram from "../../../../assets/img/CareStreams Management.png"
import staffManagement from "../../../../assets/img/Staff Management.png"
import LabTests from "../../../../assets/img/Lab Tests Management.png"
import RadTests from "../../../../assets/img/Rad Tests Management.png"
import React from "react"
import Back from "../../../../assets/img/Back_Arrow.png"
import MenuTree from "../../../../components/MenuTree/MenuTree"
import productionAreaIcon from "../../../../assets/img/Production Area Management.png"
import roomMgmtIcon from "../../../../assets/img/Room Management.png"
import chiefCompIcon from "../../../../assets/img/Chief Complaints.png"

const Management = [
  { img: Managementt, text: "Management" },
  {
    img: CareStram,
    text: "CareStreams Management",
    path: "management/carestreamall",
  },
  {
    img: staffManagement,
    text: "Staff Management",
    path: "management/staff",
  },
  {
    img: LabTests,
    text: "Lab Tests Management",
    path: "management/labtests",
  },
  {
    img: RadTests,
    text: "Rad Tests Management",
    path: "management/radtests",
  },
  {
    img: productionAreaIcon,
    text: "Production Area Management",
    path: "management/productionArea",
  },
  {
    img: roomMgmtIcon,
    text: "Room Management",
    path: "management/roomManagement",
  },
  {
    img: chiefCompIcon,
    text: "Chief Complaints",
    path: "management/chiefComplaints",
  },
]

const managementED = [
  { img: Managementt, text: "Management" },

  {
    img: CareStram,
    text: "CareStreams Management",
    path: "management/assignCarestream",
  },
]

class ManagementComponent extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      openApps: false,
      goBack: false,

      currentUser: "",
    }
  }

  componentDidMount() {
    this.setState({ currentUser: cookie.load("current_user") })
  }

  render() {
    if (this.state.goBack) {
      return <Redirect to={"/management"} />
    }

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          position: "fixed",
          width: "100%",
          height: "100%",
          background:
            "linear-gradient(25deg, rgba(101,228,193,1) 0%, rgba(58,219,175,1) 33%, rgba(15,206,147,1) 66%, rgba(6,142,103,1) 100%)",
          backgroundSize: "100%",
        }}
      >
        <Header history={this.props.history} />

        <MenuTree
          history={this.props.history}
          options={
            this.state.currentUser.staffType === "ED Doctor"
              ? managementED
              : Management
          }
        />

        <div
          style={{
            position: "fixed",
            width: "100%",
            height: "10%",
            top: "90%",
          }}
        >
          <img
            alt="Back"
            onClick={() => this.props.history.goBack()}
            src={Back}
            style={{
              width: 45,
              height: 35,
              marginLeft: "5%",
              cursor: "pointer",
            }}
          />
        </div>
      </div>
    )
  }
}

export default ManagementComponent
