import React from "react";
import cookie from "react-cookies";
import { Route, Switch, Router, Redirect } from "react-router-dom";
import { createBrowserHistory } from "history";
import NotFound from "../components/NotFound/NotFound";

import SuccessScreen from "../components/SuccessScreen/SuccessScreen";

import patientsWithPharmaReq from "../views/PharmaNotes/patientsWithPharmaReqList";
import addpharmacyRequest from "../views/PharmaNotes/addpharmacyRequest";
import completePharmaRequest from "../views/PharmaNotes/completePharmaRequest";
import patientDetailsWithPharamRequest from "../views/PharmaNotes/patientDetailsWithPharamRequest";
import ViewNotes from "../views/PharmaNotes/viewNotes"

const PrivateRoute = ({ component: Component, ...rest }) => {
  const [currentUser, setCurrentUser] = React.useState(
    cookie.load("current_user")
  );

  return (
    <Route
      {...rest}
      render={(props) =>
        currentUser.staffTypeId.type === "admin" ||
          currentUser.staffTypeId.type === "Committe Member" ||
          currentUser.staffTypeId.type === "Accounts Member" ||
          currentUser.staffTypeId.type === "Warehouse Member" ? (
            <Component {...props} />
          ) : (
            <Redirect to="notfound" />
          )
      }
    />
  );
};

class WMSRoutes extends React.PureComponent {
  render() {
    return (
      <Switch>
        {/* <PrivateRoute
          path={`${this.props.match.url}/staff`}
          component={StaffRoutes}
        /> */}

        <Route
          exact
          path={`${this.props.match.url}`}
          component={patientsWithPharmaReq}
        />

        <Route
          exact
          path={`${this.props.match.url}/viewNotes`}
          component={ViewNotes}
        />

        <Route
          exact
          path={`${this.props.match.url}/requestWithPatientDetails`}
          component={patientDetailsWithPharamRequest}
        />

        <Route
          exact
          path={`${this.props.match.url}/add`}
          component={addpharmacyRequest}
        />

        <Route
          exact
          path={`${this.props.match.url}/requestWithPatientDetails/completerequest`}
          component={completePharmaRequest}
        />

        <Route
          exact
          path={`${this.props.match.url}/success`}
          component={SuccessScreen}
        />

        <Route path={`${this.props.match.url}/notfound`} component={NotFound} />
        <Route path="*" component={NotFound} />
      </Switch>
    );
  }
}

export default WMSRoutes;
