import axios from "axios";
import {
  getAllPatientsInDept,
  assingCustomerCareAndInitiateTransfer,
  getAllCustomerCares,
  searchCustomerCare,
  getAllTransferRequestsForRequester,
  assignRoomToPatient,
  getAllEDDoctorsUrl,
  getEDNurses,
  getAnesthesiologist,
  postCodeBlueTeam,
  getCodeBlueTeam,
  updateCodeBlueTeam,
  getPendingEDRs,
  assignCodeBlueTeam,
} from "../../public/endpoins";

export const getPendingEDRscall = (
  setPatientList,
  setErrorMsg,
  setOpenNotification,
  setisLoading
) => {
  axios
    .get(getPendingEDRs)
    .then((response) => {
      console.log("getPendingEDRs", response.data.data);
      response.data.data.map(
        (r) => (
          (r.patientName =
            r.patientId.name[0].given[0] + " " + r.patientId.name[0].family),
          (r.patientMRN = r.patientId.identifier[0].value),
          (r.roomNo =
            r.room && r.room.length > 0
              ? r.room[r.room.length - 1].roomId.roomNo
              : "N/A"),
          (r.productionAreaName =
            r.chiefComplaint &&
            r.chiefComplaint.length > 0 &&
            r.chiefComplaint[r.chiefComplaint.length - 1].chiefComplaintId &&
            r.chiefComplaint[r.chiefComplaint.length - 1].chiefComplaintId
              .productionArea &&
            r.chiefComplaint[r.chiefComplaint.length - 1].chiefComplaintId
              .productionArea.length > 0
              ? r.chiefComplaint[r.chiefComplaint.length - 1].chiefComplaintId
                  .productionArea[
                  r.chiefComplaint[r.chiefComplaint.length - 1].chiefComplaintId
                    .productionArea.length - 1
                ].productionAreaId.paName
              : "N/A")
        )
      );

      setPatientList(response.data.data);
      setisLoading(false);
    })
    .catch((error) => {
      console.log("Error", error);
      setOpenNotification(true);
      setErrorMsg("Cannot get Housekeeping requests at the moment");
    });
};

export const getAllEDDoctorsCall = (
  setDoctorsList,
  setErrorMsg,
  setOpenNotification
) => {
  axios
    .get(getAllEDDoctorsUrl)
    .then((response) => {
      console.log("getAllEDDoctorsUrl", response.data.data);
      setDoctorsList(response.data.data);
    })
    .catch((error) => {
      console.log("Error", error);
      setOpenNotification(true);
      setErrorMsg("Cannot get Housekeeping requests at the moment");
    });
};

export const getAllEDNursesCall = (
  setNursesList,
  setErrorMsg,
  setOpenNotification
) => {
  axios
    .get(getEDNurses)
    .then((response) => {
      console.log("setNursesList", response.data.data);
      setNursesList(response.data.data);
    })
    .catch((error) => {
      console.log("Error", error);
      setOpenNotification(true);
      setErrorMsg("Cannot get Housekeeping requests at the moment");
    });
};

export const getAnesthesiologistCall = (
  setAnesthesiologistList,
  setErrorMsg,
  setOpenNotification
) => {
  axios
    .get(getAnesthesiologist)
    .then((response) => {
      console.log("setAnesthesiologistList", response.data.data);
      setAnesthesiologistList(response.data.data);
    })
    .catch((error) => {
      console.log("Error", error);
      setOpenNotification(true);
      setErrorMsg("Cannot get Housekeeping requests at the moment");
    });
};

export const submitCodeBlueTeamCall = (
  params,
  setErrorMsg,
  setOpenNotification,
  setOpenModal
) => {
  axios
    .post(postCodeBlueTeam, params)
    .then((response) => {
      console.log("setAnesthesiologistList", response.data.data);
      setOpenModal(false);
      window.location.reload();
    })
    .catch((error) => {
      console.log("Error", error);
      setOpenNotification(true);
      setErrorMsg("Cannot get Housekeeping requests at the moment");
    });
};

export const EditTeamCall = (params, setErrorMsg, setOpenNotification) => {
  axios
    .put(updateCodeBlueTeam, params)
    .then((response) => {
      console.log("setAnesthesiologistList", response.data.data);
      window.location.reload(true);
    })
    .catch((error) => {
      console.log("Error", error);
      setOpenNotification(true);
      setErrorMsg("Cannot get Housekeeping requests at the moment");
    });
};

export const getCodeBlueTeamCall = (
  setAnesthesiologistList,
  setErrorMsg,
  setOpenNotification,
  setisLoading
) => {
  axios
    .get(getCodeBlueTeam)
    .then((response) => {
      console.log("fff", response.data.data);
      response.data.data.map(
        (val) => (
          (val.doctorsName = val.doctors
            .map((d) => d.name[0].given[0] + " " + d.name[0].family)
            .toString()
            .split(", ")),
          (val.doctorsCount = val.doctors.length),
          (val.nursesName = val.nurses
            .map((d) => d.name[0].given[0] + " " + d.name[0].family)
            .toString()
            .split(", ")),
          (val.nursesCount = val.nurses.length),
          (val.anesthesiologistName = val.anesthesiologists
            .map((d) => d.name[0].given[0] + " " + d.name[0].family)
            .toString()
            .split(", ")),
          (val.anesthesiologistsCount = val.anesthesiologists.length)
        )
      );
      setAnesthesiologistList(response.data.data);
      setisLoading(false);
    })
    .catch((error) => {
      console.log("Error", error);
      setOpenNotification(true);
      setErrorMsg("Cannot get Housekeeping requests at the moment");
    });
};

export const assignCodeBlueTeamCall = (
  object,
  setErrorMsg,
  setOpenNotification
) => {
  console.log("Object to assign customer care", object);
  axios
    .put(assignCodeBlueTeam, object)
    .then((res) => {
      if (res.data.success) {
        window.location.reload(true);
        console.log("Res", res.data.data);
      } else if (!res.data.success) {
        setErrorMsg(res.data.error);
        setOpenNotification(true);
      }
      return res;
    })
    .catch((e) => {
      console.log("error: ", e);
    });
};
