import React from 'react'
import { Route, Switch, BrowserRouter, Redirect } from 'react-router-dom'
import NotFound from '../components/NotFound/NotFound'
// Sub-Routes
import StaffRoutes from './staffRoutes'
import ChiefComplaintsRoutes from './chiefComplaintsRoutes'
import ShiftsRoutes from './shiftsRoutes'
import cookie from "react-cookies";
// Screens (views)
import ManagementComponent from '../views/Home/AdminSubMenus/Management/Management'
import LabRadTests from '../views/LabRadTests/LabRadTests'
import ProductionArea from '../views/ProductionArea/productionArea'
import RoomManagement from '../views/RoomManagement/roomManagement'
import CareStream from '../views/CareStream/CareStreamMain'
import CareStreamTable from '../views/CareStream/CareStreamTable'
import InprogressCareStream from '../views/CareStream/InprogressCareStream'
import MedicationsCareStream from '../views/CareStream/ViewSteps/ViewMedications'
import PrintCareStream from '../views/CareStream/Steps/PrintCareStream'
import UnAuthorizedAccess from '../components/NotFound/UnAuthorizedAccess';
import DashboardScreen from "../views/Home/home";

class ManagementRoutes extends React.Component {
  render() {
  
    return (
        <Switch>
          {/* {currentUser.staffType === "Admin" ?  
          <div>*/}
          <Route
            exact
            path={`${this.props.match.url}`}
            component={ManagementComponent}
          />
          <Route path={`${this.props.match.url}/staff`} component={StaffRoutes} />
          <Route path={`${this.props.match.url}/shifts`} component={ShiftsRoutes} />
          <Route
            exact
            path={`${this.props.match.url}/roomManagement`}
            component={RoomManagement}
          />
         <Route
          exact
          path={`${this.props.match.url}/productionArea`}
          component={ProductionArea}
        />
          <Route
            path={`${this.props.match.url}/chiefComplaints`}
            component={ChiefComplaintsRoutes}
          />
          <Route
            exact
            path={`${this.props.match.url}/labtests`}
            component={LabRadTests}
          />
          <Route
            exact
            path={`${this.props.match.url}/radtests`}
            component={LabRadTests}
          />
          <Route
            exact
            path={`${this.props.match.url}/carestream`}
            component={CareStream}
          />
          <Route
            exact
            path={`${this.props.match.url}/carestreaminprogress`}
            component={InprogressCareStream}
          />
          <Route
            exact
            path={`${this.props.match.url}/carestreamall`}
            component={CareStreamTable}
          />
          <Route
            exact
            path={`${this.props.match.url}/carestreamall/medications`}
            component={MedicationsCareStream}
          />
          <Route
            exact
            path={`${this.props.match.url}/carestream/printCareStream`}
            component={PrintCareStream}
          />

          <Route path={`${this.props.match.url}/notfound`} component={NotFound} />
          <Route path='*' component={NotFound} />
          
          {/* </div> :
          <Route path='*' component={UnAuthorizedAccess} />} */}
        </Switch>
    )
  }
}
export default ManagementRoutes