/*eslint-disable*/
import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { makeStyles } from "@material-ui/core/styles";
import cookie from "react-cookies";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import DialogActions from "@material-ui/core/DialogActions";
import axios from "axios";
import { getAvailableRoomsAganistPA } from "../../public/endpoins";

const styles = {
  textFieldPadding: {
    paddingLeft: 5,
    paddingRight: 5,
  },
  stylesForButton: {
    color: "white",
    cursor: "pointer",
    borderRadius: 5,
    backgroundColor: "#2c6ddd",
    width: "170px",
    height: "45px",
    fontSize: 12,
  },
  stylesForCancel: {
    color: "gray",
    cursor: "pointer",
    borderRadius: 5,
    backgroundColor: "transparent",
    width: "170px",
    height: "45px",
    border: "2px solid gray",
    fontSize: 12,
  },
};

const useStylesForInput = makeStyles((theme) => ({
  underline: {
    "&&&:before": {
      borderBottom: "none",
    },
    "&&:after": {
      borderBottom: "none",
    },
  },
  margin: {
    margin: theme.spacing(0),
  },
  input: {
    backgroundColor: "white",
    borderRadius: 6,
    "&:after": {
      borderBottomColor: "black",
    },
    "&:hover": {
      backgroundColor: "white",
    },
    "&:disabled": {
      color: "gray",
    },
  },
  root: {
    "& .MuiTextField-root": {
      backgroundColor: "white",
    },
    "& .Mui-focused": {
      backgroundColor: "white",
      color: "black",
    },
    "& .Mui-disabled": {
      backgroundColor: "white",
      color: "gray",
    },
  },
}));

export default function PatientTransfer(props) {
  const classes = useStylesForInput();

  const [currentUser] = useState(cookie.load("current_user"));
  const [availableRooms, setAvailableRooms] = useState("");
  const [selectedRoom, setSelectedRoom] = useState("");

  useEffect(() => {
    console.log(props, "Data in modal");

    const chiefComplaint = props.selectedPatient.chiefComplaint.pop();
    const produnctionArea = chiefComplaint.chiefComplaintId.productionArea.pop();
    axios
      .get(getAvailableRoomsAganistPA + "/" + produnctionArea.productionAreaId)
      .then((res) => {
        let roomNo = [];
        for (let i = 0; i < res.data.data.rooms.length; i++) {
          if (res.data.data.rooms[i].roomId) {
            roomNo.push(res.data.data.rooms[i].roomId);
          }
        }
        console.log(roomNo);

        setAvailableRooms(roomNo);
      })
      .catch((error) => console.log(error));
  }, []);

  return (
    <Dialog
      onClose={() => props.handleEdit("")}
      fullWidth={true}
      maxWidth={"lg"}
      aria-labelledby="simple-dialog-title"
      aria-describedby="alert-dialog-description"
      open={props.open}
    >
      <DialogTitle id="alert-dialog-title">
        <span style={{ opacity: "70%", fontWeight: "700", fontSize: 20 }}>
          Transfer to {props.transferType}
        </span>
        <br />
        <span style={{ fontSize: 14, opacity: "70%" }}>
          Initiate Transfer to transfer patient to {props.transferType}
        </span>
      </DialogTitle>

      <DialogContent>
        {// currentUser.staffType === "Sensei" &&
        props.transferType === "ED from EOU" ? (
          <div className={classes.root}>
            <TextField
              select
              fullWidth
              id={"availableBedCell"}
              label="Available Bed Cell"
              name="availableBedCell"
              value={selectedRoom}
              variant="filled"
              className="dropDownStyle"
              InputProps={{
                className: classes.input,
                classes: { input: classes.input },
                disableUnderline: true,
              }}
              onChange={(e) => setSelectedRoom(e.target.value)}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>

              {availableRooms &&
                availableRooms.map((val) => {
                  return (
                    <MenuItem key={val._id} value={val._id}>
                      {val.roomNo}
                    </MenuItem>
                  );
                })}
            </TextField>
          </div>
        ) : (
          undefined
        )}
      </DialogContent>

      <DialogActions
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "15px",
        }}
      >
        <Button
          onClick={() => props.handleEdit("")}
          variant="contained"
          style={styles.stylesForCancel}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          style={styles.stylesForButton}
          onClick={() =>
            props.handleInitiateTransfer(props.selectedPatient, selectedRoom)
          }
          color="primary"
        >
          Initiate Transfer
        </Button>
      </DialogActions>
    </Dialog>
  );
}
