import React, { useState } from "react";
import Divider from "@material-ui/core/Divider";
import Dialog from "@material-ui/core/Dialog";
import { makeStyles } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import { uploadsUrl } from "../../public/endpoins";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function EnableDisableModal(props) {
  const classes = useStyles();
  return (
    <div>
      <Dialog
        fullScreen
        aria-labelledby="form-dialog-title"
        open={props.openViewReportsModal}
        maxWidth="xl"
        fullWidth={true}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => props.handleClose()}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Reports
            </Typography>
          </Toolbar>
        </AppBar>
        <List>
          <ListItem button>
            <div className="container-fluid">
              <div className="row">
                {props.reportData.subheading === "Lab Reports" ||
                props.reportData.subheading === "Rad Reports" ? (
                  <>
                    {props.reportData.description.map((val) => {
                      if (val && val !== "" && val.includes("\\"))
                        return (
                          <>
                            {val !== "" &&
                            val.slice(val.length - 3) !== "pdf" ? (
                              <div
                                style={{
                                  height: "50px",
                                }}
                              >
                                <img
                                  style={{ width: 400, height: 200 }}
                                  alt=""
                                  src={(uploadsUrl + val.split("\\")[1]).replace('/uploads/uploads', '/uploads')}
                                  className="detailBlockImages"
                                  // onClick={() =>
                                  //   openImageViewer(0, e.description[0])
                                  // }
                                />{" "}
                                &nbsp; &nbsp;
                              </div>
                            ) : (
                              undefined
                            )}
                          </>
                        );
                      else if (val && val !== "" && val.includes("/"))
                        return (
                          <>
                            {val !== "" &&
                            val.slice(val.length - 3) !== "pdf" ? (
                              <div
                                style={{
                                  height: "50px",
                                }}
                              >
                                <img
                                  style={{ width: 400, height: 200 }}
                                  alt=""
                                  src={(uploadsUrl + val).replace('/uploads/uploads', '/uploads')}
                                  className="detailBlockImages"
                                  // onClick={() =>
                                  //   openImageViewer(0, e.description)
                                  // }
                                />{" "}
                                &nbsp; &nbsp;
                              </div>
                            ) : (
                              undefined
                            )}
                          </>
                        );
                      else return "No Image";
                    })}
                  </>
                ) : (
                  undefined
                )}
              </div>
            </div>
          </ListItem>
        </List>
      </Dialog>
    </div>
  );
}
