import React, { useState, useEffect, useReducer, useCallback } from "react";
import {
  Chip,
  MenuItem,
  makeStyles,
  TextField,
  Input,
  Select,
  InputAdornment,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  useMediaQuery,
} from "@material-ui/core";
import DoneIcon from "@material-ui/icons/Done";
import AddIcon from "@material-ui/icons/Add";
import Back from "../../../../assets/img/Back_Arrow.png";
import ImageViewer from "react-simple-image-viewer";
import { IoIosCloseCircle } from "react-icons/io";
import {
  uploadsUrl,
  getSearchedPharmaceuticalItemsUrl,
  getAllergiesDropdown,
  getEDRFromPatientIdForDischarge,
} from "../../../../public/endpoins";
import "./formRow.css";
import Autocomplete from "@material-ui/lab/Autocomplete";
import axios from "axios";
import MicIcon from "@material-ui/icons/Mic";
import StopIcon from "@material-ui/icons/Stop";
import MicRecorder from "mic-recorder-to-mp3";
import Loader from "react-loader-spinner";
import VoiceComponent from "../../../../components/VoiceComponent/VoiceComponent";
import { displayInHumanDifference, replacePatientIdIfNull } from "../../../../utils/commonFunc";

const Mp3Recorder = new MicRecorder({ bitRate: 128 });

const styles = {
  ROSRowPadding: {
    marginLeft: 0,
    marginRight: 0,
    backgroundColor: "white",
    marginTop: 10,
    padding: "10px 0px 10px 0px",
    borderRadius: 5,
  },
  chipAlignment: {
    display: "inline-block",
    padding: "5px",
  },
  save: {
    color: "white",
    cursor: "pointer",
    borderRadius: 5,
    backgroundColor: "#2c6ddd",
    width: "115px",
    height: "40px",
    outline: "none",
  },
};

const useStyles = makeStyles((theme) => ({
  input: {
    backgroundColor: "#F5F5F5",
    boxShadow: "none",
    borderRadius: 5,
    "&:after": {
      borderBottomColor: "black",
      boxShadow: "none",
    },
    "&:hover": {
      backgroundColor: "#F5F5F5",
      boxShadow: "none",
    },
    "&:focus": {
      backgroundColor: "#F5F5F5",
      boxShadow: "none",
      borderRadius: 5,
    },
  },
  root: {
    "& .MuiTextField-root": {
      backgroundColor: "white",
    },
    "& .Mui-focused": {
      backgroundColor: "white",
      color: "black",
      boxShadow: "none",
    },
    "& .Mui-disabled": {
      backgroundColor: "white",
      color: "gray",
    },
    "& .MuiFormLabel-root": {
      fontSize: "12px",
      color: "black",
      paddingRight: "15px",
    },
    "& .MuiInputLabel-filled": {
      color: "gray",
    },
    "& .MuiFilledInput-adornedEnd": {
      backgroundColor: "#F5F5F5",
      paddingRight: "20px",
    },
  },
}));

const useAutocompleteStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(3),
    },
  },
}));

export default function CourseVisit(props) {
  console.log('Fucked::', props);
  const matches = useMediaQuery("(min-width: 600)");
  const classes = useStyles();
  const [congestionSelectedItem, setCongestionSelectedItem] = useState({});

  const initialState = {
    investigation: [],
    isBlocked: true,
    isRecording: false,
    blobURL: [],
    recordingMode: ""
  };

  function reducer(state, { field, value }) {
    return {
      ...state,
      [field]: value,
    };
  }

  const [state, dispatch] = useReducer(reducer, initialState);

  const { investigation, isBlocked, isRecording, blobURL, recordingMode } = state;

  const [ECGreport, setECGreport] = useState([]);
  const [ECGpreview, setECGpreview] = useState([]);
  const [XRAYreport, setXRAYreport] = useState([]);
  const [XRAYpreview, setXRAYpreview] = useState([]);
  const [currentImage, setCurrentImage] = useState(0);


  const [dischargeDispositionStatus       , setDischargeDispositionStatus]               = useState("");
  const [educateTreatmentPlan             , setEducateTreatmentPlan]                     = useState("no");
  const [educateMedication                , setEducateMedication]                        = useState("no");
  const [educateFollowupInstruction       , setEducateFollowupInstruction]               = useState("no");

  // Consultation Service Box
  const [dischargeDisposition           , setDischargeDisposition]            = useState("");
  const [dischargeDispositionRemarks    , setDischargeDispositionRemarks]     = useState("");
  const [dischargeDispositionVoiceNotes , setDischargeDispositionVoiceNotes]  = useState("");
  const [consultationServiceRemarks     , setConsultationServiceRemarks]    = useState("");
  const [educateTreatmentPlanRemarks    , setEducateTreatmentPlanRemarks]         = useState("")
  const [educateMedicationRemarks    , setEducateMedicationRemarks]     = useState("");
  const [educateFollowUpInstructionsRemarks   , setEducateFollowUpInstructionsRemarks]    = useState("");
  const [edrAdmissionType, setEdrAdmissionType] = useState("");
  

  // Patient Education
  const [patientEducationRemarks      , setPatientEducationRemarks]       = useState("");
  const [patientEducationVoiceNotes   , setPatientEducationVoiceNotes]    = useState("");

  const triggerSave = () => {
    dispatch({field: 'investigation', value: {
      'dischargeDispositionStatus'              : dischargeDispositionStatus,
      'dischargeDisposition'                    : dischargeDisposition,
      'dischargeDispositionRemarks'             : dischargeDispositionRemarks,
      'dischargeDispositionVoiceNotes'          : dischargeDispositionVoiceNotes,
      'educateTreatmentPlan'                    : educateTreatmentPlan,
      'educateMedication'                       : educateMedication,
      'educateFollowupInstruction'              : educateFollowupInstruction,
      'patientEducationRemarks'                 : patientEducationRemarks,
      'patientEducationVoiceNotes'              : patientEducationVoiceNotes,
      'consultationServiceRemarks'              : consultationServiceRemarks,
      'educateTreatmentPlanRemarks'             : educateTreatmentPlanRemarks,
      'educateMedicationRemarks'                : educateMedicationRemarks,
      'educateFollowUpInstructionsRemarks'      : educateFollowUpInstructionsRemarks,
    }})
    // console.log('dischargeDispositionStatus'              , dischargeDispositionStatus);
    // console.log('dischargeDisposition'                    , dischargeDisposition);
    // console.log('dischargeDispositionRemarks'             , dischargeDispositionRemarks);
    // console.log('dischargeDispositionVoiceNotes'          , dischargeDispositionVoiceNotes);
    console.log('educateTreatmentPlan'                    , educateTreatmentPlan);
    console.log('educateMedication'                       , educateMedication);
    console.log('educateFollowupInstruction'              , educateFollowupInstruction);
    console.log('patientEducationRemarks'                 , patientEducationRemarks);
    console.log('patientEducationVoiceNotes'              , patientEducationVoiceNotes);
    console.log('consultationServiceRemarks'              , consultationServiceRemarks);
    console.log('educateTreatmentPlanRemarks'             , educateTreatmentPlanRemarks);
    console.log('educateMedicationRemarks'                , educateMedicationRemarks);
    console.log('educateFollowUpInstructionsRemarks'      , educateFollowUpInstructionsRemarks);

    /*
    educateTreatmentPlan yes
    CourseVisit.js:193 educateMedication yes
    CourseVisit.js:194 educateFollowupInstruction yes
    CourseVisit.js:195 patientEducationRemarks Patient Educated
    CourseVisit.js:196 patientEducationVoiceNotes 
    CourseVisit.js:197 consultationServiceRemarks 
    CourseVisit.js:198 educateTreatmentPlanRemarks Discharge Disposition Instructions
    CourseVisit.js:199 educateMedicationRemarks Discharge medication
    CourseVisit.js:200 educateFollowUpInstructionsRemarks Follow up on Monday 11 December 2023
    */


    props.handleSave({
      // 'dischargeDispositionStatus'              : dischargeDispositionStatus,
      // 'dischargeDisposition'                    : dischargeDisposition,
      // 'dischargeDispositionRemarks'             : dischargeDispositionRemarks,
      // 'dischargeDispositionVoiceNotes'          : dischargeDispositionVoiceNotes,
      'educateTreatmentPlan'                    : educateTreatmentPlan,
      'educateMedication'                       : educateMedication,
      'educateFollowupInstruction'              : educateFollowupInstruction,

      'patientEducationRemarks'                 : patientEducationRemarks,
      'patientEducationVoiceNotes'              : patientEducationVoiceNotes,

      // 'consultationServiceRemarks'              : consultationServiceRemarks,
      // 'educateTreatmentPlanRemarks'             : educateTreatmentPlanRemarks,
      // 'educateMedicationRemarks'                : educateMedicationRemarks,
      // 'educateFollowUpInstructionsRemarks'      : educateFollowUpInstructionsRemarks,
    }, ECGreport, XRAYreport, []);
  }



  const DCDHX = () => {
    // console.log(DCDFormData);
    let state = props.history.location.state;
    window.open(`/dashboard/home/${state.patientEdrForm._id}/dcd-history/`, '_blank', 'noreferrer');
  }

  useEffect(() => {
    let state = props.history.location.state;

    let episodeId = state.patientEdrForm._id;

    axios
      .get(getEDRFromPatientIdForDischarge + "/" + episodeId)
      .then((res) => {
        console.log('Fucked::handleView::', res.data.data);
        if (res.data.success) {

          let remoteRecord = replacePatientIdIfNull(res.data.data);
          // setSelectedItem(remoteRecord);

          const object = {
            '_id': remoteRecord._id,
            patientName: remoteRecord.patientId.name[0].given[0] + " " + remoteRecord.patientId.name[0].family,
            mrn: remoteRecord.requestNo,//remoteRecord.patientId.identifier[0].value,
            age: remoteRecord.patientId.age,
            gender: remoteRecord.patientId.gender,
            weight: remoteRecord.patientId.weight,
            department: remoteRecord.currentLocation,
            chiefComplaint: remoteRecord.chiefComplaint.length > 0 ? remoteRecord.chiefComplaint.pop().chiefComplaintId.short_desc : "N/A",
            chiefComplaintOthers: remoteRecord.chiefComplaintOthers,
          };
          // setPatientDetails(object);

          if (remoteRecord.dischargeRequest) {
            Object.entries(remoteRecord.dischargeRequest).map(([key1, val1]) => {
              if (key1 === "dischargeSummary") {
                setDischargeDispositionStatus('yes')
                dispatch({ field: "dischargeSummary", value: val1 });
                dispatch({
                  field: "dischargeNotes",
                  value: val1.dischargeNotes,
                });
                dispatch({
                  field: "followUpInstruction",
                  value: val1.followUpInstruction,
                });

                setDischargeDisposition(val1.edrCompletionReason)
                setDischargeDispositionRemarks(val1.dischargeNotes)
                setEducateFollowUpInstructionsRemarks(val1.followUpInstruction)
                setEdrAdmissionType(val1.edrAdmissionType)

                // setEdrCompletionReason(val1.edrCompletionReason);
                // setEdrCompletionRequirement(
                //   val1.edrCompletionRequirement
                // );
              } else if (key1 === "dischargeMedication") {
                dispatch({ field: "dischargeMedArray", value: [val1] });
                dispatch({
                  field: "dischargeReportArray",
                  value: val1.medicine ? val1.medicine : "",
                });
              }
            });
            dispatch({ field: "dischargeRequest", value: remoteRecord.dischargeRequest });
          } 
        }
      })
      .catch((e) => {
        console.log("error after searching patient request", e);
      });






    const filteredDropdownSelectedValue = props.form
      .map(
        (item) =>
          item.chips &&
          item.chips.find(
            (x) => x.dropdownSelectedValue && x.dropdownSelectedValue
          )
      )
      .filter(Boolean)[0];
    filteredDropdownSelectedValue &&
      filteredDropdownSelectedValue.length &&
      setCongestionSelectedItem(
        filteredDropdownSelectedValue.dropdownSelectedValue
      );

    let modePage = 'add';
    if (state.currentFields && state.comingFrom === "edit"){
      modePage = 'edit';
      console.log("FormRow::currentFields::", state.currentFields);

      let nData = [];
      Object.entries(state.currentFields.details).map(([key, val]) => {
        console.log("FormRow::currentFields::", key, val);
        if (key === "dischargeDisposition" ){
          setDischargeDisposition(val);
        } else if (key === "dischargeDispositionRemarks" ){
          setDischargeDispositionRemarks(val);
        } else if (key === "dischargeDispositionVoiceNotes" ){
          setDischargeDispositionVoiceNotes(val);
        } else if (key === "consultationServiceRemarks" ){
          setConsultationServiceRemarks(val);
        } else if (key === "educateTreatmentPlanRemarks" ){
          setEducateTreatmentPlanRemarks(val);
        } else if (key === "educateMedicationRemarks" ){
          setEducateMedicationRemarks(val);
        } else if (key === "educateFollowUpInstructionsRemarks" ){
          setEducateFollowUpInstructionsRemarks(val);
        } else if (key === "patientEducationRemarks" ){
          setPatientEducationRemarks(val);
        } else if (key === "patientEducationVoiceNotes" ){
          setPatientEducationVoiceNotes(val);
        }
      });

      console.log("FormRow::currentFields::", state.currentFields.details);
      dispatch({
          field: "investigation",
          value: state.currentFields.details
      });


      console.log("FormRow::currentFields::", investigation);
    }

    // Navigator for MP3
    navigator.mediaDevices &&
      navigator
        .mediaDevices.getUserMedia({ audio: true })
        .then( stream => {
          dispatch({ field: "isBlocked", value: false });
        })
        .catch( error => {
          dispatch({ field: "isBlocked", value: true });
        });
  }, []);

  return (
    <>
      <div className="row" style={styles.ROSRowPadding}>
        <div className="col-md-12 col-sm-12 col-12">
          <div style={{ fontWeight: "400", color: "#939393" }}>Decision to Discharge/Disposition:</div>
          <div className="row mt-2 align-items-center">
            <div className="col-sm-5"><strong className="">Discharge/Disposition</strong></div>
            <div className="col-sm-7">
              <div className="form-group">
                <span style={styles.chipAlignment}>
                  <Chip
                    label="Yes"
                    icon={dischargeDispositionStatus=="yes" ? <DoneIcon style={{ color: "white" }} />:undefined}
                    style={dischargeDispositionStatus=="yes" ? {color: "white",backgroundColor: "rgb(19 213 159)"} : { color: "#070707", backgroundColor: "#F5F5F5"}}
                  />
                </span>
                <span style={styles.chipAlignment}>
                  <Chip
                    label="No"
                    icon={dischargeDispositionStatus=="no" ? <DoneIcon style={{ color: "white" }} />:undefined}
                    style={dischargeDispositionStatus=="no" ? {color: "white",backgroundColor: "rgb(19 213 159)"} : { color: "#070707", backgroundColor: "#F5F5F5"}}
                  />
                </span>
              </div>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-sm-5"><strong>Discharge/Disposition Type</strong></div>
            <div className="col-sm-7">
              <div className="form-group">
                <span style={styles.chipAlignment}>
                  <Chip
                    label="Discharge to Home"
                    icon={dischargeDisposition=="discharged" ? <DoneIcon style={{ color: "white" }} />:undefined}
                    style={dischargeDisposition=="discharged" ? {color: "white",backgroundColor: "rgb(19 213 159)"} : { color: "#070707", backgroundColor: "#F5F5F5"}}
                  />
                </span>
                <span style={styles.chipAlignment}>
                  <Chip
                    label="Admission"
                    icon={dischargeDisposition=="admitted" ? <DoneIcon style={{ color: "white" }} />:undefined}
                    style={dischargeDisposition=="admitted" ? {color: "white",backgroundColor: "rgb(19 213 159)"} : { color: "#070707", backgroundColor: "#F5F5F5"}}
                  />
                </span>
                <span style={styles.chipAlignment}>
                  <Chip
                    label="Transfer to another facility"
                    icon={dischargeDisposition=="transferred" ? <DoneIcon style={{ color: "white" }} />:undefined}
                    style={dischargeDisposition=="transferred" ? {color: "white",backgroundColor: "rgb(19 213 159)"} : { color: "#070707", backgroundColor: "#F5F5F5"}}
                  />
                </span>
                <span style={styles.chipAlignment}>
                  <Chip
                    label="Deceased"
                    icon={dischargeDisposition=="deceased" ? <DoneIcon style={{ color: "white" }} />:undefined}
                    style={dischargeDisposition=="deceased" ? {color: "white",backgroundColor: "rgb(19 213 159)"} : { color: "#070707", backgroundColor: "#F5F5F5"}}
                  />
                </span>
              </div>
            </div>
          </div>
          {
            dischargeDisposition === "admitted"?
            <div className="row align-items-center">
              <div className="col-sm-5"><strong>Admission Type</strong></div>
              <div className="col-sm-7">
                <div className="form-group">
                  <span style={styles.chipAlignment}>
                    <Chip
                      label="OR (Operation Room)"
                      icon={edrAdmissionType=="operation-room" ? <DoneIcon style={{ color: "white" }} />:undefined}
                      style={edrAdmissionType=="operation-room" ? {color: "white",backgroundColor: "rgb(19 213 159)"} : { color: "#070707", backgroundColor: "#F5F5F5"}}
                    />
                  </span>
                  <span style={styles.chipAlignment}>
                    <Chip
                      label="Non-Monitored Ward"
                      icon={edrAdmissionType=="non-monitored-ward" ? <DoneIcon style={{ color: "white" }} />:undefined}
                      style={edrAdmissionType=="non-monitored-ward" ? {color: "white",backgroundColor: "rgb(19 213 159)"} : { color: "#070707", backgroundColor: "#F5F5F5"}}
                    />
                  </span>
                  <span style={styles.chipAlignment}>
                    <Chip
                      label="Monitored Ward"
                      icon={edrAdmissionType=="monitored-ward" ? <DoneIcon style={{ color: "white" }} />:undefined}
                      style={edrAdmissionType=="monitored-ward" ? {color: "white",backgroundColor: "rgb(19 213 159)"} : { color: "#070707", backgroundColor: "#F5F5F5"}}
                    />
                  </span>
                  <span style={styles.chipAlignment}>
                    <Chip
                      label="Step-Down Unit"
                      icon={edrAdmissionType=="stepdown-unit" ? <DoneIcon style={{ color: "white" }} />:undefined}
                      style={edrAdmissionType=="stepdown-unit" ? {color: "white",backgroundColor: "rgb(19 213 159)"} : { color: "#070707", backgroundColor: "#F5F5F5"}}
                    />
                  </span>
                  <span style={styles.chipAlignment}>
                    <Chip
                      label="Intensive Care Unit (ICU)"
                      icon={edrAdmissionType=="icu" ? <DoneIcon style={{ color: "white" }} />:undefined}
                      style={edrAdmissionType=="icu" ? {color: "white",backgroundColor: "rgb(19 213 159)"} : { color: "#070707", backgroundColor: "#F5F5F5"}}
                    />
                  </span>
                  <span style={styles.chipAlignment}>
                    <Chip
                      label="Cardiac Care Unit (CCU)"
                      icon={edrAdmissionType=="ccu" ? <DoneIcon style={{ color: "white" }} />:undefined}
                      style={edrAdmissionType=="ccu" ? {color: "white",backgroundColor: "rgb(19 213 159)"} : { color: "#070707", backgroundColor: "#F5F5F5"}}
                    />
                  </span>
                  <span style={styles.chipAlignment}>
                    <Chip
                      label="Stroke Unit"
                      icon={edrAdmissionType=="stroke-unit" ? <DoneIcon style={{ color: "white" }} />:undefined}
                      style={edrAdmissionType=="stroke-unit" ? {color: "white",backgroundColor: "rgb(19 213 159)"} : { color: "#070707", backgroundColor: "#F5F5F5"}}
                    />
                  </span>
                </div>
              </div>
            </div>
            :undefined
          }


          <div className="row mt-2">
            <div className="col-sm-12">
              <div className="form-group">
                <TextField
                  multiline
                  label="Remarks:"
                  value={dischargeDispositionRemarks}
                  rows={5}
                  className="textInputStyle"
                  variant="filled"
                  InputProps={{
                    className: classes.input,
                    classes: {
                      input: classes.input,
                    },
                    disableUnderline: true,
                    rows: 5
                  }}
                  />
              </div>
              <VoiceComponent
                audioFile={dischargeDispositionVoiceNotes}
                setAudioFile={setDischargeDispositionVoiceNotes}
                />
            </div>
          </div>
        </div>
      </div>


      <div className="row" style={styles.ROSRowPadding}>
        <div className="col-md-12 col-sm-12 col-12">
          <div className="row mt-2">
            {/*
            <div className="col-sm-12 mb-2"><strong>Discharge/Disposition Summary</strong></div>
            <div className="col-sm-12">
              <div className="form-group">
                <TextField
                  multiline
                  disabled
                  label="(propagation of all work completed on patient & Action & Plan)"
                  value={consultationServiceRemarks}
                  onChange={(e) =>{
                    setConsultationServiceRemarks(e.target.value)
                  }}
                  rows={5}
                  className="textInputStyle"
                  variant="filled"
                  InputProps={{
                    className: classes.input,
                    classes: {
                      input: classes.input,
                    },
                    disableUnderline: true,
                    rows: 5
                  }}
                  />
              </div>
            </div>
            */}
            <div className="col-sm-12 mb-2 d-flex justify-content-between"><strong>Discharge/Disposition Instructions:</strong> 
              <Button
                  onClick={DCDHX}
                  variant="contained"
                  color="primary"
                ><strong>View Discharge Summary</strong></Button>
            </div>
            <div className="col-sm-12">
              {/*
              <div className="form-group">
                <TextField
                  multiline
                  label="Treatment Plan"
                  value={educateTreatmentPlanRemarks}
                  onChange={(e) =>{
                    setEducateTreatmentPlanRemarks(e.target.value)
                  }}
                  rows={5}
                  className="textInputStyle"
                  variant="filled"
                  InputProps={{
                    className: classes.input,
                    classes: {
                      input: classes.input,
                    },
                    disableUnderline: true,
                    rows: 5
                  }}
                  />
              </div>
              <div className="form-group">
                <TextField
                  multiline
                  label="Discharge Medication:"
                  value={educateMedicationRemarks}
                  onChange={(e) =>{
                    setEducateMedicationRemarks(e.target.value)
                  }}
                  rows={5}
                  className="textInputStyle"
                  variant="filled"
                  InputProps={{
                    className: classes.input,
                    classes: {
                      input: classes.input,
                    },
                    disableUnderline: true,
                    rows: 5
                  }}
                  />
              </div>
              */}
              <div className="form-group">
                <TextField
                  multiline
                  label="Follow-Up Instructions:"
                  value={educateFollowUpInstructionsRemarks}
                  onChange={(e) =>{
                    setEducateFollowUpInstructionsRemarks(e.target.value)
                  }}
                  rows={5}
                  className="textInputStyle"
                  variant="filled"
                  InputProps={{
                    className: classes.input,
                    classes: {
                      input: classes.input,
                    },
                    disableUnderline: true,
                    rows: 5
                  }}
                  />
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className="row" style={styles.ROSRowPadding}>
        <div className="col-md-12 col-sm-12 col-12">
          <div style={{ fontWeight: "400", color: "#939393" }}>Patient Education and Discharge Instructions:</div>
          <div className="row mt-2 align-items-center">
            <div className="col-sm-5"><strong className="">Patient Education</strong></div>
            <div className="col-sm-7">
              <div className="form-group">
                <span style={styles.chipAlignment}>
                  <Chip
                    label="Treatment Plan"
                    onClick={() => { educateTreatmentPlan === "no" ? setEducateTreatmentPlan('yes') : setEducateTreatmentPlan('no') } }
                    icon={educateTreatmentPlan=="yes" ? <DoneIcon style={{ color: "white" }} />:undefined}
                    style={educateTreatmentPlan=="yes" ? {color: "white",backgroundColor: "rgb(19 213 159)"} : { color: "#070707", backgroundColor: "#F5F5F5"}}
                  />
                </span>
                <span style={styles.chipAlignment}>
                  <Chip
                    label="Medication"
                    onClick={() => { educateMedication === "no" ? setEducateMedication('yes') : setEducateMedication('no') } }
                    icon={educateMedication=="yes" ? <DoneIcon style={{ color: "white" }} />:undefined}
                    style={educateMedication=="yes" ? {color: "white",backgroundColor: "rgb(19 213 159)"} : { color: "#070707", backgroundColor: "#F5F5F5"}}
                  />
                </span>
                <span style={styles.chipAlignment}>
                  <Chip
                    label="Followup Instructions"
                    onClick={() => { educateFollowupInstruction === "no" ? setEducateFollowupInstruction('yes') : setEducateFollowupInstruction('no') } }
                    icon={educateFollowupInstruction=="yes" ? <DoneIcon style={{ color: "white" }} />:undefined}
                    style={educateFollowupInstruction=="yes" ? {color: "white",backgroundColor: "rgb(19 213 159)"} : { color: "#070707", backgroundColor: "#F5F5F5"}}
                  />
                </span>
              </div>
            </div>
          </div>
          <div className="form-group">
            <TextField
              multiline
              label="Remarks:"
              value={patientEducationRemarks}
              onChange={(e) =>{
                setPatientEducationRemarks(e.target.value)
              }}
              rows={5}
              className="textInputStyle"
              variant="filled"
              InputProps={{
                className: classes.input,
                classes: {
                  input: classes.input,
                },
                disableUnderline: true,
                rows: 5
              }}
              />
          </div>
          <VoiceComponent
            audioFile={patientEducationVoiceNotes}
            setAudioFile={setPatientEducationVoiceNotes}
            />
        </div>
      </div>

      <div
        className="row"
        style={{
          display: "flex",
          justifyContent: "flex-start",
          marginTop: "30px",
          marginBottom: "2%",
        }}
      >
        <div className="col-md-6 col-sm-6 col-6 d-flex justify-content-start">
          <img
            onClick={() => props.history.goBack()}
            src={Back}
            style={{
              width: 45,
              height: 35,
              cursor: "pointer",
            }}
          />
        </div>
        <div className="col-md-6 col-sm-6 col-6 d-flex justify-content-end">
          <Button
            /*disabled={investigation.length > 0 ? false : true}*/
            onClick={() =>
              triggerSave(investigation, ECGreport, XRAYreport, [])
            }
            style={{ ...styles.save, backgroundColor: "#13D59F" }}
            variant="contained"
            color="default"
          >
            Save
          </Button>
        </div>
      </div>
    </>
  );
}
