import React, { useState, useEffect } from "react";
import Notification from "../../components/Snackbar/Notification.js";
import CustomTable from "../../components/Table/Table";
import axios from "axios";
import _ from "lodash";
import Button from "@material-ui/core/Button";
import Loader from "react-loader-spinner";
import {
  getEDRsWithCareStream,
  searchEdrWithCareStream,
} from "../../public/endpoins";
import Back from "../../assets/img/Back_Arrow.png";
import Header from "../../components/Header/Header";
import Pending_Patients from "../../assets/img/Patients.png";
import ButtonField from "../../components/common/Button";
import Fingerprint from "../../assets/img/fingerprint.png";
import AccountCircle from "@material-ui/icons/SearchOutlined";
import InputAdornment from "@material-ui/core/InputAdornment";
import BarCode from "../../assets/img/Bar Code.png";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import "../../assets/jss/material-dashboard-react/components/loaderStyle.css";
import cookie from "react-cookies";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import QRCodeScannerComponent from "../../components/QRCodeScanner/QRCodeScanner";
import { displayInHumanDifference, replacePatientIdIfNull } from "../../utils/commonFunc";

import { setSelectedPatientForReducer } from "../../actions/patientDetails";
import { connect } from "react-redux";

const tableHeading = [
  "MRN",
  "Episode#",
  "Patient Name",
  "Production Area",
  "Chief Complaint",
  "CareStream",
  "Department",
  "Date/Time",
  "status",
  "Action",
];
const tableDataKeys = [
  "mrn",
  "episodeNo",
  "patientName",
  "productionArea",
  "chiefComplaintName",
  "careStreams",
  "currentLocation",
  "createdAt",
  "status",
];

const styles = {
  textFieldPadding: {
    paddingLeft: 5,
    paddingRight: 5,
  },
};

const useStylesForInput = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(0),
  },
  input: {
    backgroundColor: "white",
    borderRadius: 5,
    "&:after": {
      borderBottomColor: "black",
    },
    "&:hover": {
      backgroundColor: "white",
    },
    "&:focus": {
      boxShadow: "none",
      borderRadius: 5,
    },
  },
  root: {
    "& .MuiTextField-root": {
      backgroundColor: "white",
    },
    "& .Mui-focused": {
      backgroundColor: "white",
      color: "black",
    },
    "& .Mui-disabled": {
      backgroundColor: "white",
      color: "gray",
    },
    "& .MuiFormLabel-root": {
      fontSize: "11px",
      paddingRight: "45px",
    },
  },
}));

const actions = { view: true };

function EDR(props) {
  const matches = useMediaQuery("(min-width:600px)");
  const classes = useStylesForInput();

  const [labInPatient, setlabInPatient] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [openNotification, setOpenNotification] = useState(false);
  const [searchPatientQuery, setSearchPatientQuery] = useState("");

  const [QRCodeScanner, setQRCodeScanner] = useState(false);

  if (openNotification) {
    setTimeout(() => {
      setOpenNotification(false);
      setErrorMsg("");
    }, 2000);
  }

  useEffect(() => {
    getPatientData();
  }, []);

  function getPatientData() {
    axios
      .get(getEDRsWithCareStream)
      .then((res) => {
        console.log("response", res.data.data);
        // console.log("response 2", res.data.data[10].createdTimeStamp);
        if (res.data.success) {
          res.data.data.map((d) => {
              d = replacePatientIdIfNull(d);
              console.log(d);
              d.mrn = d.patientId.identifier[0].value;
              d.patientName = d.patientId.name[0].given[0] + " " + d.patientId.name[0].family;
              d.createdAt = d.createdTimeStamp ? d.createdTimeStamp : "N/A";
              d.episodeNo = d.requestNo;
              d.productionArea = d.room.length>0 && d.room[d.room.length - 1].productionRoomId?d.room[d.room.length - 1].productionRoomId.paName:'';//d.room[d.room.length - 1]?d.room[d.room.length - 1].productionRoomId.paName:''),
              /*(d.productionArea =
                d.chiefComplaint &&
                d.chiefComplaint.length > 0 &&
                d.chiefComplaint[d.chiefComplaint.length - 1].chiefComplaintId
                  .productionArea.length > 0
                  ? d.chiefComplaint[d.chiefComplaint.length - 1]
                      .chiefComplaintId.productionArea[
                      d.chiefComplaint[d.chiefComplaint.length - 1]
                        .chiefComplaintId.productionArea.length - 1
                    ].productionAreaId.paName
                  : "N/A"),*/
              d.chiefComplaintName = d.chiefComplaint.length>0?d.chiefComplaint[ d.chiefComplaint.length-1 ].chiefComplaintId.short_desc:'-';//, //d.chiefComplaint[ d.chiefComplaint.length-1 ].chiefComplaintId?d.chiefComplaint[ d.chiefComplaint.length-1 ].chiefComplaintId.short_desc:'-'),
              // (
              //   d.chiefComplaintName = (
              //     d.chiefComplaint[ d.chiefComplaint.length - 1 ].chiefComplaintId
              //       ?d.chiefComplaint[ d.chiefComplaint.length - 1 ].chiefComplaintId.short_desc:''
              //     )
              // ),
              d.careStreams =
                d.careStream && d.careStream.length > 0
                  ? d.careStream[d.careStream.length - 1].careStreamId.name
                  : "N/A";
              return d;
          });
          console.log("RED:", res.data.data);
          // res.data.data.map(
          //   (v) =>
          //     (v.consultantId = v.consultationNote.map(
          //       (val) => val.consultant && val.consultant._id
          //     ))
          // )

          setlabInPatient(res.data.data.reverse());
        } else if (!res.data.success) {
          setErrorMsg(res.data.error);
          setOpenNotification(true);
        }
        return res;
      })
      .catch((e) => {
        console.log("error: ", e);
      });
  }

  function handleView(rec) {
    if (
      rec.chiefComplaint.length > 0 &&
      rec.chiefComplaint &&
      rec.chiefComplaint[rec.chiefComplaint.length - 1].chiefComplaintId
    ) {
      rec.patientId.chiefComplaint =
        rec.chiefComplaint[rec.chiefComplaint.length - 1].chiefComplaintId.name;
    }

    console.log('handleView::', rec);

    props.history.push({
      pathname: `/dashboard/home/${rec._id}/notes`,
    });

    /*props.setSelectedPatientForReducer(rec);
    props.history.push({
      pathname: "/dashboard/home/notes",
      state: {
        edrId: rec._id,
        cameFrom: 'patientlist',
        // selectedRecord: rec,
      },
    });*/
  }

  const handlePatientSearch = (e) => {
    const a = e.target.value.replace(/[^\w+\s]/gi, "");
    setSearchPatientQuery(a);
    if (a.length >= 3) {
      axios
        .get(searchEdrWithCareStream + "/" + a)
        .then((res) => {
          console.log("res after searching", res);
          if (res.data.success) {
            if (res.data.data) {

              res.data.data.map((d) => {
                d = replacePatientIdIfNull(d);
                console.log(d);
                d.mrn = d.patientId.identifier[0].value;
                d.patientName = d.patientId.name[0].given[0] + " " + d.patientId.name[0].family;
                d.createdAt = d.createdTimeStamp ? d.createdTimeStamp : "N/A";
                d.episodeNo = d.requestNo;
                d.productionArea = d.room.length>0 && d.room[d.room.length - 1].productionRoomId?d.room[d.room.length - 1].productionRoomId.paName:'';//d.room[d.room.length - 1]?d.room[d.room.length - 1].productionRoomId.paName:''),
                /*(d.productionArea =
                  d.chiefComplaint &&
                  d.chiefComplaint.length > 0 &&
                  d.chiefComplaint[d.chiefComplaint.length - 1].chiefComplaintId
                    .productionArea.length > 0
                    ? d.chiefComplaint[d.chiefComplaint.length - 1]
                        .chiefComplaintId.productionArea[
                        d.chiefComplaint[d.chiefComplaint.length - 1]
                          .chiefComplaintId.productionArea.length - 1
                      ].productionAreaId.paName
                    : "N/A"),*/
                d.chiefComplaintName = d.chiefComplaint.length>0?d.chiefComplaint[ d.chiefComplaint.length-1 ].chiefComplaintId.short_desc:'-';//, //d.chiefComplaint[ d.chiefComplaint.length-1 ].chiefComplaintId?d.chiefComplaint[ d.chiefComplaint.length-1 ].chiefComplaintId.short_desc:'-'),
                // (
                //   d.chiefComplaintName = (
                //     d.chiefComplaint[ d.chiefComplaint.length - 1 ].chiefComplaintId
                //       ?d.chiefComplaint[ d.chiefComplaint.length - 1 ].chiefComplaintId.short_desc:''
                //     )
                // ),
                d.careStreams =
                  d.careStream && d.careStream.length > 0
                    ? d.careStream[d.careStream.length - 1].careStreamId.name
                    : "N/A";
                return d;
            });

              /*res.data.data.map(
                (d) => (
                  (d.mrn = d.requestNo),
                  (d.episodeNo = d.requestNo),
                  (d.patientName = d.patientId.name[0].given[0] + " " + d.patientId.name[0].family),
                  (d.createdAt = d.createdTimeStamp ? d.createdTimeStamp : "N/A"),
                  (d.productionArea = d.room[d.room.length - 1].productionRoomId.paName),
                  (d.chiefComplaintName = 
                    d.chiefComplaint.length>0? (
                      d.chiefComplaint[ d.chiefComplaint.length-1 ].chiefComplaintId ?
                      d.chiefComplaint[ d.chiefComplaint.length-1 ].chiefComplaintId.short_desc:'-'
                    ):'-'
                  ),
                  (d.careStreams =
                    d.careStream && d.careStream.length > 0
                      ? d.careStream[d.careStream.length - 1].careStreamId.name
                      : "N/A")
                )
              );*/

              
              /*res.data.data.map(
                (d) =>
                  (d.patientName =
                    d.patientId.name[0].given[0] +
                    " " +
                    d.patientId.name[0].family)
              );
              res.data.data.map(
                (d) =>
                  (d.createdAt = d.patientId.createdAt
                    ? d.patientId.createdAt
                    : "N/A")
              );
              res.data.data.map(
                (d) => (d.mrn = d.patientId.identifier[0].value)
              );

              res.data.data.map(
                (d) => (d.productionArea = d.room[d.room.length - 1].productionRoomId.paName)
              );*/

              /*res.data.data.map(
                (d) => (
                  (d.productionArea =
                    d.chiefComplaint && d.chiefComplaint.length > 0
                      ? d.chiefComplaint[d.chiefComplaint.length - 1]
                          .chiefComplaintId.productionArea[
                          d.chiefComplaint[d.chiefComplaint.length - 1]
                            .chiefComplaintId.productionArea.length - 1
                        ].productionAreaId.paName
                      : "N/A"),
                  (d.chiefComplaintName =
                    d.chiefComplaint[
                      d.chiefComplaint.length - 1
                    ].chiefComplaintId.name),
                  (d.careStreams =
                    d.careStream && d.careStream.length > 0
                      ? d.careStream[d.careStream.length - 1].name
                      : "N/A")
                )
              );*/
              // res.data.data.map(
              //   (v) =>
              //     (v.careStream =
              //       v.careStream &&
              //       v.careStream.length > 0 &&
              //       v.careStream[v.careStream.length - 1].careStreamId.name)
              // )
              var sortedObjs = _.sortBy(res.data.data, "createdAt").reverse();
              setlabInPatient(sortedObjs);
              // var sortedObjs = _.sortBy(res.data.data, 'date').reverse()
              // setlabInPatient(res.data.data)
            } else {
              setlabInPatient(" ");
            }
          }
        })
        .catch((e) => {
          console.log("error after searching patient request", e);
        });
    } else if (a.length == 0) {
      getPatientData();
    }
  };

  // const addNewItem = () => {
  //   props.history.push({
  //     pathname: 'patientregistration',
  //     state: { comingFor: 'add' },
  //   })
  // }

  function scanQRCode() {
    setQRCodeScanner((pervState) => !pervState);
  }

  function handleScanQR(data) {
    setQRCodeScanner(false);
    console.log(JSON.parse(data));
    if (JSON.parse(data).profileNo) {
      handlePatientSearch({
        target: {
          value: JSON.parse(data).profileNo,
          type: "text",
        },
      });
    } else if (JSON.parse(data).insuranceCardNumber) {
      // dispatch({
      //   field: "insuranceNo",
      //   value: JSON.parse(data).insuranceCardNumber,
      // });
    }
  }

  if (QRCodeScanner) {
    return (
      <div>
        {QRCodeScanner ? (
          <QRCodeScannerComponent
            handleScanQR={handleScanQR}
            scanQRCode={scanQRCode}
          />
        ) : (
          undefined
        )}
      </div>
    );
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        position: "fixed",
        width: "100%",
        height: "100%",
        backgroundColor: "rgb(19 213 159)",
        overflowY: "scroll",
      }}
    >
      <Header history={props.history} />

      <div className="cPadding">
        <div className="subheader">
          <div>
            <img src={Pending_Patients} alt="Pending_Patients" />
            <h4>Patient List</h4>
          </div>
        </div>

        <div className={`container-fluid ${classes.root}`}>
          <div className="row" style={{ marginTop: "20px" }}>
            <div
              className="col-md-10 col-12"
              style={{
                ...styles.textFieldPadding,
                // paddingLeft: "15px",
                marginTop: "10px",
              }}
            >
              <TextField
                className="textInputStyle"
                id="searchPatientQuery"
                type="text"
                variant="filled"
                label="Search Patient by Name / MRN / National ID / Mobile No"
                name={"searchPatientQuery"}
                value={searchPatientQuery}
                onChange={handlePatientSearch}
                InputLabelProps={{
                  classes: {
                    root: classes.label,
                    focused: classes.focusedLabel,
                    error: classes.erroredLabel,
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <AccountCircle />
                    </InputAdornment>
                  ),
                  className: classes.input,
                  classes: { input: classes.input },
                  disableUnderline: true,
                }}
              />
            </div>
            <div
              className="col-md-1 col-6"
              style={{
                paddingLeft: matches ? 3 : 3,
                paddingRight: matches ? 3 : 3,
                marginTop: "10px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "white",
                  borderRadius: 5,
                  height: 55,
                }}
              >
                <img
                  src={BarCode}
                  onClick={scanQRCode}
                  style={{ width: 70, height: 60 }}
                />
              </div>
            </div>
            <div
              className="col-md-1 col-6"
              style={{
                paddingRight: matches ? 3 : 3,
                paddingLeft: matches ? 3 : 3,
                marginTop: "10px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "white",
                  borderRadius: 5,
                  height: 55,
                }}
              >
                <img src={Fingerprint} style={{ maxWidth: 43, height: 43 }} />
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid">
          <div className="row">
            {labInPatient && labInPatient.length > 0 ? (
              <div
                className="col-12"
                style={{
                  ...styles.textFieldPadding,
                }}
              >
                <CustomTable
                  tableData={labInPatient}
                  tableDataKeys={tableDataKeys}
                  tableHeading={tableHeading}
                  action={actions}
                  handleView={handleView}
                  borderBottomColor={"#60d69f"}
                  borderBottomWidth={20}
                />
              </div>
            ) : Array.isArray(labInPatient) && labInPatient.length === 0 ? (
              <div className="col-md-12 col-sm-12 col-12">
                <h3
                  style={{
                    color: "white",
                    textAlign: "center",
                  }}
                >
                  Opps...No Data Found
                </h3>
              </div>
            ) : (
              <div style={{ margin: "auto", width: "0%", padding: 10 }}>
                <Loader type="TailSpin" color="red" height={50} width={50} />
              </div>
            )}
          </div>
          <div className="row" style={{ marginTop: 20, marginBottom: 20 }}>
            <div className="col-md-12 col-sm-12 col-12">
              <img
                onClick={() => props.history.goBack()}
                src={Back}
                style={{
                  width: 45,
                  height: 35,
                  cursor: "pointer",
                }}
              />
            </div>
          </div>

          <Notification msg={errorMsg} open={openNotification} />
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = ({ PatientReducer }) => {
  const { selectedPatientDetails } = PatientReducer;
  return { selectedPatientDetails };
};
export default connect(mapStateToProps, {
  setSelectedPatientForReducer,
})(EDR);
