import React, { useState } from "react";
import CareStream from "../../../assets/img/carestream/CareStream.png";
import ContainerCS from "../../../components/CareStream/ContainerCS";
import { TextField, Grid, makeStyles } from "@material-ui/core";
import { assignStyles } from "../styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

export default function Assign(props) {
  const matches = useMediaQuery("(min-width:600px)");
  const classes = assignStyles();
  const [name, setName] = useState("");

  const getInclusionData = (data) => {
    props.getInclusion(data);
  };

  const getExclusionData = (data) => {
    props.getExclusion(data);
  };

  if (props) {
    props.sendName(name);
  }

  return (
    <div className={classes.root}>
      <div className="subheader" style={{ marginLeft: "-16px" }}>
        <img src={CareStream} className={classes.image} />
        <div style={{ flex: 4, display: "flex", alignItems: "center" }}>
          <h4
            style={{
              fontSize: matches ? "" : "14px",
              color: "white",
              fontWeight: "700",
            }}
          >
            Assign CareStream
          </h4>
        </div>
      </div>

      <Grid
        style={{
          width: "98.5%",
          marginBottom: 15,
          paddingRight: matches ? 0 : 15,
          marginLeft: 10,
        }}
      >
        <TextField
          label="CareStream Name"
          name={"name"}
          value={name}
          onChange={(e) => setName(e.target.value)}
          className="textInputStyle"
          variant="filled"
          disabled={props.comingFrom ? true : false}
          InputProps={{
            className: classes.input,
            classes: { input: classes.input },
          }}
          inputProps={{ maxLength: 40 }}
        />
      </Grid>
      <ContainerCS
        heading="Inclusion Criteria"
        buttonHeading="Inclusion Criteria"
        getData={getInclusionData}
      />
      <ContainerCS
        heading="Exclusion Criteria"
        buttonHeading="Exclusion Criteria"
        getData={getExclusionData}
      />
    </div>
  );
}
