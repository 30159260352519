/*eslint-disable*/
import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Notification from "../../components/Snackbar/Notification.js";
import CustomTable from "../../components/Table/Table";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Loader from "react-loader-spinner";
import TextField from "@material-ui/core/TextField";
import Header from "../../components/Header/Header";
import historyIcon from "../../assets/img/ED RoomsHistory.png";
import Back from "../../assets/img/Back_Arrow.png";
import "../../assets/jss/material-dashboard-react/components/loaderStyle.css";
import useStylesForInput from "../../assets/jss/material-dashboard-react/inputStyle"
import { styles, useStylesForTabs } from "./styles.js"
import {
    tableHeadingForTransfer,
    tableDataKeysForTransfer,
    // tableHeadingForCareStream,
    // tableDataKeysForCareStream,
    tableHeadingForTimeInterval,
    tableDataKeysForTimeInterval
} from "./historyTables"
import {
    getRoomHistoryTableData
} from "./networkCallForEOUMgmt"

export default function History(props) {
    const classesForTabs = useStylesForTabs();
    const classes = useStylesForInput();

    const [errorMsg, setErrorMsg] = useState("");
    const [openNotification, setOpenNotification] = useState(false);
    const [openFilterModal, setOpenFilterModal] = useState(false);
    const [tabName, setTabName] = useState("Transferred to EOU");
    const [tableData, setTableData] = useState([]);
    const [isLoading, setisLoading] = useState(true);

    if (openNotification) {
        setTimeout(() => {
            setOpenNotification(false);
            setErrorMsg("");
        }, 2000);
    }

    useEffect(() => {
        setisLoading(true)
        setTableData([])
        getRoomHistoryTableData(
            tabName,
            setTableData,
            setisLoading,
            setOpenNotification,
            setErrorMsg
        );
    }, [tabName]);

    const handleChange = (event, tabValue) => {
        setTabName(tabValue);
    };

    const handleFilteredTimeInterval = () => {
    }

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                flex: 1,
                position: "fixed",
                width: "100%",
                height: "100%",
                backgroundColor: "#60d69f",
                overflowY: "scroll",
            }}
        >
            <Header history={props.history} />

            <div className="cPadding">
                <div className="subheader" style={{ paddingRight: '15px' }}>
                    <div>
                        <img src={historyIcon} />
                        <h4>EOU Rooms History</h4>
                    </div>
                    {tabName === "Time Interval" ? (
                        <>
                        </>
                        // <Button
                        //     onClick={handleSelectTimeInterval}
                        //     style={styles.stylesForButton}
                        //     variant="contained"
                        //     color="primary"
                        // >
                        //     <img src={FilterIcon} className="icon-view" />
                        //     &nbsp;&nbsp;Select Time Interval
                        // </Button>
                    ) : (
                            undefined
                        )}
                </div>

                <div style={{ height: '20px' }} />

                <div className={classesForTabs.root}>
                    <Tabs
                        classes={{
                            root: classesForTabs.root,
                            scroller: classesForTabs.scroller,
                        }}
                        value={tabName}
                        onChange={handleChange}
                        textColor="primary"
                        variant="scrollable"
                        TabIndicatorProps={{ style: { background: "#12387a" } }}
                    >
                        <Tab
                            style={{
                                color: "white",
                                borderRadius: 5,
                                outline: "none",
                                color: tabName === "Transferred to EOU" ? "#12387a" : "#3B988C",
                            }}
                            label="Transferred to EOU"
                            value="Transferred to EOU"
                        />
                        <Tab
                            style={{
                                color: "white",
                                borderRadius: 5,
                                outline: "none",
                                color: tabName === "Time Interval" ? "#12387a" : "#3B988C",
                            }}
                            label="Time Interval"
                            value="Time Interval"
                        />
                        <Tab
                            style={{
                                color: "white",
                                borderRadius: 5,
                                outline: "none",
                                color: tabName === "CareStream Time Interval" ? "#12387a" : "#3B988C",
                            }}
                            label="CareStream Time Interval"
                            value="CareStream Time Interval"
                        />
                    </Tabs>
                </div>

                <div className='container-fluid'>

                    {tabName === "Transferred to EOU" ? (
                        <div className="row">
                            {tableData.length > 0 && !isLoading ? (
                                <div className='col-md-12 col-sm-12 col-12'>
                                    <CustomTable
                                        tableData={tableData}
                                        tableDataKeys={tableDataKeysForTransfer}
                                        tableHeading={tableHeadingForTransfer}
                                        borderBottomColor={"#60d69f"}
                                        borderBottomWidth={20}
                                    />
                                </div>
                            ) : isLoading ? (
                                <div style={{ margin: "auto", width: "0%", padding: 10 }}>
                                    <Loader
                                        type="TailSpin"
                                        color="blue"
                                        height={50}
                                        width={50}
                                    />
                                </div>
                            ) : (
                                        <div className='col-md-12 col-sm-12 col-12'>
                                            <h3
                                                style={{
                                                    color: "white",
                                                    textAlign: "center",
                                                    margin: 'revert'
                                                }}
                                            >
                                                Opps! No Data Found
                                            </h3>
                                        </div>
                                    )}
                        </div>
                    ) : tabName === "CareStream Time Interval" ? (
                        <div className="row">
                            <div className='col-md-12 col-sm-12 col-12'>
                                <h3
                                    style={{
                                        color: "white",
                                        textAlign: "center",
                                        margin: 'revert'
                                    }}
                                >
                                    CareStream under development !!
                            </h3>
                            </div>
                            {/* {tableData.length > 0 && !isLoading ? (
                                <div className='col-md-12 col-sm-12 col-12'>
                                    <CustomTable
                                        tableData={tableData}
                                        tableDataKeys={tableDataKeysForCareStream}
                                        tableHeading={tableHeadingForCareStream}
                                        borderBottomColor={"#60d69f"}
                                        borderBottomWidth={20}
                                    />
                                </div>
                            ) : isLoading ? (
                                <div style={{ margin: "auto", width: "0%", padding: 10 }}>
                                    <Loader
                                        type="TailSpin"
                                        color="blue"
                                        height={50}
                                        width={50}
                                    />
                                </div>
                            ) : (
                                        <div className='col-md-12 col-sm-12 col-12'>
                                            <h3
                                                style={{
                                                    color: "white",
                                                    textAlign: "center",
                                                    margin: 'revert'
                                                }}
                                            >
                                                Opps! No Data Found
                                            </h3>
                                        </div>
                                    )} */}
                        </div>
                    ) : tabName === "Time Interval" ? (
                        <div className="row">
                            {tableData.length > 0 && !isLoading ? (
                                <div className='col-md-12 col-sm-12 col-12'>
                                    <CustomTable
                                        tableData={tableData}
                                        tableDataKeys={tableDataKeysForTimeInterval}
                                        tableHeading={tableHeadingForTimeInterval}
                                        borderBottomColor={"#60d69f"}
                                        borderBottomWidth={20}
                                    />
                                </div>
                            ) : isLoading ? (
                                <div style={{ margin: "auto", width: "0%", padding: 10 }}>
                                    <Loader
                                        type="TailSpin"
                                        color="blue"
                                        height={50}
                                        width={50}
                                    />
                                </div>
                            ) : (
                                        <div className='col-md-12 col-sm-12 col-12'>
                                            <h3
                                                style={{
                                                    color: "white",
                                                    textAlign: "center",
                                                    margin: 'revert'
                                                }}
                                            >
                                                Opps! No Data Found
                                            </h3>
                                        </div>
                                    )}
                        </div>
                    ) : (
                                    <div className="LoaderStyle">
                                        <Loader type="TailSpin" color="red" height={50} width={50} />
                                    </div>
                                )}

                    <div className="row" style={{ marginBottom: 20 }}>
                        <div className='col-md-12 col-sm-12 col-12'>
                            <img
                                onClick={() => props.history.goBack()}
                                src={Back}
                                style={{
                                    width: 45,
                                    height: 35,
                                    cursor: "pointer",
                                }}
                            />
                        </div>
                    </div>
                    <Notification msg={errorMsg} open={openNotification} />
                </div>

                <Dialog
                    onClose={() => setOpenFilterModal(false)}
                    fullWidth={true}
                    maxWidth={'lg'}
                    
                    
                    aria-labelledby='simple-dialog-title'
                    open={openFilterModal}
                >
                    <DialogTitle id='simple-dialog-title'>
                        <h4>Select Time Interval</h4>
                    </DialogTitle>
                    <DialogContent>
                        <div className={`container-fluid ${classes.root}`}>
                            <div className='row'>
                                <div className='col-md-6 col-sm-6 col-6'>
                                    <TextField
                                        select
                                        fullWidth
                                        label="Start Date"
                                        name={"startDate"}
                                        value={"startDate"}
                                        // onChange={dropDownHandler}
                                        variant="filled"
                                        className='dropDownStyle'
                                        InputProps={{
                                            className: classes.input,
                                            classes: { input: classes.input },
                                            disableUnderline: true
                                        }}
                                    >
                                        {/* {props.dropDownArray.map((array) => {
                                            return (
                                                <MenuItem key={array.key} value={array.value}>
                                                    {array.value}
                                                </MenuItem>
                                            );
                                        })} */}
                                    </TextField>
                                </div>
                                <div className='col-md-6 col-sm-6 col-6'>
                                    <TextField
                                        select
                                        fullWidth
                                        label="End Date"
                                        name={"endDate"}
                                        // value={"endDate"}
                                        // onChange={dropDownHandler}
                                        variant="filled"
                                        className='dropDownStyle'
                                        InputProps={{
                                            className: classes.input,
                                            classes: { input: classes.input },
                                            disableUnderline: true
                                        }}
                                    >
                                        {/* {props.dropDownArray.map((array) => {
                                            return (
                                                <MenuItem key={array.key} value={array.value}>
                                                    {array.value}
                                                </MenuItem>
                                            );
                                        })} */}
                                    </TextField>
                                </div>
                            </div>
                            <div className='row' style={{ marginTop: '10px' }}>
                                <div className='col-md-6 col-sm-6 col-6'>
                                    <TextField
                                        select
                                        fullWidth
                                        label="Start Time"
                                        name={"startTime"}
                                        // value={"startTime"}
                                        // onChange={dropDownHandler}
                                        variant="filled"
                                        className='dropDownStyle'
                                        InputProps={{
                                            className: classes.input,
                                            classes: { input: classes.input },
                                            disableUnderline: true
                                        }}
                                    >
                                        {/* {props.dropDownArray.map((array) => {
                                            return (
                                                <MenuItem key={array.key} value={array.value}>
                                                    {array.value}
                                                </MenuItem>
                                            );
                                        })} */}
                                    </TextField>
                                </div>
                                <div className='col-md-6 col-sm-6 col-6'>
                                    <TextField
                                        select
                                        fullWidth
                                        label="End Time"
                                        name={"endTime"}
                                        // value={"endTime"}
                                        // onChange={dropDownHandler}
                                        variant="filled"
                                        className='dropDownStyle'
                                        InputProps={{
                                            className: classes.input,
                                            classes: { input: classes.input },
                                            disableUnderline: true
                                        }}
                                    >
                                        {/* {props.dropDownArray.map((array) => {
                                            return (
                                                <MenuItem key={array.key} value={array.value}>
                                                    {array.value}
                                                </MenuItem>
                                            );
                                        })} */}
                                    </TextField>
                                </div>
                            </div>
                        </div>
                    </DialogContent>

                    <DialogActions style={{ display: 'flex', justifyContent: 'center' }}>
                        <Button
                            style={styles.stylesForCloseButton}
                            onClick={() => setOpenFilterModal(false)}
                            variant='contained'
                        >
                            Close
                        </Button>
                        &nbsp;
                        <Button
                            style={styles.stylesForButton}
                            onClick={handleFilteredTimeInterval}
                            variant='contained'
                        >
                            Done
                        </Button>
                    </DialogActions>
                </Dialog>

            </div>
        </div>
    );
}