import axios from 'axios'
import _ from 'lodash'
import { getEOUPatients, searchEOUPatients } from '../../public/endpoins'
import FormatDate from '../../utils/formatDate'

export const getPendingParamedicsEdr = (
  setlabInPatient,
  setErrorMsg,
  setOpenNotification
) => {
  axios
    .get(getEOUPatients)
    .then((res) => {
      console.log('response', res.data.data)
      if (res.data.success) {
        res.data.data.map(
          (d) =>
            (d.patientName =
              d.patientId.name[0].given[0] + ' ' + d.patientId.name[0].family)
        )
        res.data.data.map((d) => (d.mrn = d.patientId.identifier[0].value))
        res.data.data.map((d) => (d.gender = d.patientId.gender))
        res.data.data.map((d) => (d.age = d.patientId.age))
        res.data.data.map((d) => (d.createdAt = d.patientId.createdAt))
        res.data.data.map(
          (d) => (
            (d.careStreamName =
              d.careStream[d.careStream.length - 1] &&
              d.careStream[d.careStream.length - 1].length > 0
                ? d.careStream[d.careStream.length - 1].name
                : 'N/A'),
            ((d.productionArea = d.chiefComplaint[d.chiefComplaint.length - 1]
              .chiefComplaintId
              ? d.chiefComplaint[d.chiefComplaint.length - 1].chiefComplaintId
                  .productionArea[
                  d.chiefComplaint[d.chiefComplaint.length - 1].chiefComplaintId
                    .productionArea.length - 1
                ].productionAreaId.paName
              : 'N/A'),
            (d.RoomNo = d.room[d.room.length - 1]
              ? d.room[d.room.length - 1].roomId.roomNo
              : 'N/A'))
          )
        )

        // var sortedObjForPen = _.sortBy(res.data.data, 'createdAt').reverse()
        setlabInPatient(res.data.data)
      } else if (!res.data.success) {
        setErrorMsg(res.data.error)
        setOpenNotification(true)
      }
      return res
    })
    .catch((e) => {
      console.log('error: ', e)
    })
}

export const searchPendingEdrPatients = (
  a,
  setlabInPatient,
  setErrorMsg,
  setOpenNotification
) => {
  console.log('A', a)

  axios
    .get(searchEOUPatients + '/' + a)
    .then((res) => {
      console.log('res', res)
      if (res.data.success) {
        res.data.data.map(
          (d) =>
            (d.patientName =
              d.patientId.name[0].given[0] + ' ' + d.patientId.name[0].family)
        )
        res.data.data.map((d) => (d.mrn = d.patientId.identifier[0].value))
        res.data.data.map((d) => (d.gender = d.patientId.gender))
        res.data.data.map((d) => (d.age = d.patientId.age))
        res.data.data.map((d) => (d.createdAt = d.patientId.createdAt))
        res.data.data.map(
          (d) => (
            (d.careStreamName =
              d.careStream[d.careStream.length - 1] &&
              d.careStream[d.careStream.length - 1].length > 0
                ? d.careStream[d.careStream.length - 1].name
                : 'N/A'),
            ((d.productionArea = d.chiefComplaint[d.chiefComplaint.length - 1]
              .chiefComplaintId
              ? d.chiefComplaint[d.chiefComplaint.length - 1].chiefComplaintId
                  .productionArea[
                  d.chiefComplaint[d.chiefComplaint.length - 1].chiefComplaintId
                    .productionArea.length - 1
                ].productionAreaId.paName
              : 'N/A'),
            (d.RoomNo = d.room[d.room.length - 1]
              ? d.room[d.room.length - 1].roomId.roomNo
              : 'N/A'))
          )
        )

        console.log(res.data.data)

        // var searchSortedObjForPen = _.sortBy(
        //   res.data.data,
        //   'createdAt'
        // ).reverse()
        // console.log(res.data.data, 'patient data')
        setlabInPatient(res.data.data)
      } else if (!res.data.success) {
        setErrorMsg(res.data.error)
        setOpenNotification(true)
      }
    })
    .catch((e) => {
      console.log('error after searching patient request', e)
    })
}
