import React from 'react'
import cookie from 'react-cookies'
import { Route, Switch, Redirect } from 'react-router-dom'
import NotFound from '../components/NotFound/NotFound'
// import ControlRoom from '../views/Dashboard/ControlRoom'
import StaffRoutes from './staffRoutes'

const PrivateRoute = ({ component: Component, ...rest }) => {
  const [currentUser] = React.useState(
    cookie.load('current_user')
  )

  return (
    <Route
      {...rest}
      render={(props) =>
        currentUser.staffTypeId.type === 'admin' ||
        currentUser.staffTypeId.type === 'Committe Member' ||
        currentUser.staffTypeId.type === 'Accounts Member' ||
        currentUser.staffTypeId.type === 'Warehouse Member' ? (
          <Component {...props} />
        ) : (
          <Redirect to='notfound' />
        )
      }
    />
  )
}

class ControlRoomRoutes extends React.PureComponent {
  render() {
    return (
      <Switch>
        {/* <Route exact path={`${this.props.match.url}`} component={ControlRoom} /> */}
        
        <PrivateRoute
          path={`${this.props.match.url}/staff`}
          component={StaffRoutes}
        />

        <Route path={`${this.props.match.url}/notfound`} component={NotFound} />

        <Route path='*' component={NotFound} />
      </Switch>
    )
  }
}
export default ControlRoomRoutes