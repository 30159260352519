import React, { useEffect, useState } from "react";
import { Button } from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: "8px",
    backgroundColor: "#2873CF",
  },
  root: {
    "& .MuiButton-label": {
      fontSize: "0.675rem",
    },
  },
}));
function ButtonValue(props) {
  const classes = useStyles();
  const matches = useMediaQuery("(min-width:600px)");

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        backgroundColor: "white",
        borderRadius: 5,
        paddingTop: matches ? 20 : undefined,
        height: matches ? 240 : 190,
      }}
    >
      <div
        style={{
          display: "flex",
          height: "40%",
          justifyContent: "center",
          alignItems: "center",
          padding: matches ? "" : "0  6px",
        }}
      >
        <h5
          style={{
            fontSize: matches ? 14 : 11,
            fontWeight: "900",
            opacity: matches ? "70%" : "80%",
            textAlign: "center",
          }}
        >
          {props.heading}
        </h5>
      </div>
      <div
        className="col-12"
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          paddingLeft: 0,
          paddingRight: 0,
          alignItems: "center",
        }}
      >
        <span
          style={{
            color: props.timeColor,
            fontSize: matches ? 50 : 40,
            fontWeight: "700",
            position: "absolute",
            marginTop: -20,
          }}
        >
          {props.time}
        </span>
      </div>

      <div
        className="col-12"
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          marginTop: 60,
          paddingLeft: 0,
          paddingRight: 0,
          alignItems: "center",
        }}
      >
        <Button
          variant="contained"
          color="primary"
          className={[classes.button, matches ? " " : classes.root]}
          startIcon={<VisibilityIcon />}
          onClick={() => props.onClick(props.heading)}
        >
          View Details
        </Button>
      </div>
    </div>
  );
}

export default ButtonValue;
