/*eslint-disable*/
import React, { useState, useEffect, useReducer } from 'react'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Notification from '../../components/Snackbar/Notification.js'
import CustomTable from '../../components/Table/Table'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Loader from 'react-loader-spinner'
import Header from '../../components/Header/Header'
import FlagManagementIcon from '../../assets/img/Housekeeping.png'
import Back from '../../assets/img/Back_Arrow.png'
import '../../assets/jss/material-dashboard-react/components/loaderStyle.css'
import cookie from 'react-cookies'
import { useStyles, useStylesForTabs } from './styles.js'
import {
  getPendingSenseiCall,
  getPendingImgTechnicianCall,
  getEDNurseCall,
} from './networkCalls'

const tableHeading = [
  'MRN No',
  'Patient Name',
  'Production Area',
  'CareStream',
  'Date / Time',
  ' ',
]
const tableDataKeys = [
  'mrn',
  'patientName',
  'productionArea',
  'careStreamName',
  'dateTime',
]

const tableHeadingForImgTechnician = [
  'MRN No',
  'Patient Name',
  'Production Area',
  'CareStream',
  'Date / Time',
  ' ',
]
const tableDataKeysForImgTechnician = [
  'mrn',
  'patientName',
  'productionArea',
  'careStreamName',
  'dateTime',
]

export default function FlagMgmt(props) {
  const classesForTabs = useStylesForTabs()
  const [pendingDoctorPatients, setPendingDoctorPatients] = useState([])
  const [pendingImgTechnicianArray, setPendingImgTechnicianArray] = useState([])
  const [edNurseArray, setEdNurseArray] = useState([])

  const [loading, setLoading] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')
  const [openNotification, setOpenNotification] = useState(false)
  const [tabName, setTabName] = useState('Pending Patients')

  if (openNotification) {
    setTimeout(() => {
      setOpenNotification(false)
      setErrorMsg('')
    }, 2000)
  }

  useEffect(() => {
    console.log('props', props)

    getPendingSenseiCall(
      setPendingDoctorPatients,
      setErrorMsg,
      setOpenNotification
    )
  }, [])

  const handleTabChange = (e, tabName) => {
    console.log('tabName', tabName)
    setTabName(tabName)
    if (tabName === 'Pending Patients') {
      getPendingSenseiCall(
        setPendingDoctorPatients,
        setErrorMsg,
        setOpenNotification
      )
    } else if (tabName === 'Patients in-Progress') {
      getEDNurseCall(setEdNurseArray, setErrorMsg, setOpenNotification)
    } else {
      getPendingImgTechnicianCall(
        setPendingImgTechnicianArray,
        setErrorMsg,
        setOpenNotification
      )
    }
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        position: 'fixed',
        width: '100%',
        height: '100%',
        backgroundColor: '#60d69f',
        overflowY: 'scroll',
      }}
    >
      <Header history={props.history} />

      <div className='cPadding'>
        <div className='subheader'>
          <div>
            <img src={FlagManagementIcon} style={{ marginLeft: '0px' }} />
            <h4>
              {tabName === 'Pending Patients'
                ? 'Pending Patients'
                : tabName === 'Patients in-Progress'
                ? 'Patients In Progress'
                : 'Completed Patients List'}
            </h4>
          </div>
        </div>

        <div
          className='container-fluid'
          style={{ paddingLeft: 0, paddingRight: 0 }}
        >
          <div className={classesForTabs.root}>
            <Tabs
              classes={{
                root: classesForTabs.root,
                scroller: classesForTabs.scroller,
              }}
              value={tabName}
              onChange={handleTabChange}
              textColor='primary'
              TabIndicatorProps={{ style: { background: '#12387a' } }}
              centered
            >
              <Tab
                style={{
                  color: 'white',
                  borderRadius: 5,
                  outline: 'none',
                  color: tabName === 'Pending Patients' ? '#12387a' : '#3B988C',
                }}
                label='Pending Patients'
                value='Pending Patients'
              />
              <Tab
                style={{
                  color: 'white',
                  borderRadius: 5,
                  outline: 'none',
                  color:
                    tabName === 'Patients in-Progress' ? '#12387a' : '#3B988C',
                }}
                label='Patients in-Progress'
                value='Patients in-Progress'
              />
              <Tab
                style={{
                  color: 'white',
                  borderRadius: 5,
                  outline: 'none',
                  color:
                    tabName === 'Completed Patients' ? '#12387a' : '#3B988C',
                }}
                label='Completed Patients'
                value='Completed Patients'
              />
            </Tabs>
          </div>

          <div className='row'>
            {pendingDoctorPatients && tabName === 'Pending Patients' ? (
              <div className='col-md-12 col-sm-12 col-12'>
                {loading ? (
                  <div style={{ margin: 'auto', width: '0%', padding: 10 }}>
                    <Loader
                      type='TailSpin'
                      color='blue'
                      height={50}
                      width={50}
                    />
                  </div>
                ) : (
                  <CustomTable
                    tableData={pendingDoctorPatients}
                    tableDataKeys={tableDataKeys}
                    tableHeading={tableHeading}
                    borderBottomColor={'#60d69f'}
                    borderBottomWidth={20}
                  />
                )}
              </div>
            ) : pendingImgTechnicianArray &&
              tabName === 'Completed Patients' ? (
              <div className='col-md-12 col-sm-12 col-12'>
                {loading ? (
                  <div style={{ margin: 'auto', width: '0%', padding: 10 }}>
                    <Loader
                      type='TailSpin'
                      color='blue'
                      height={50}
                      width={50}
                    />
                  </div>
                ) : (
                  <CustomTable
                    tableData={pendingImgTechnicianArray}
                    tableDataKeys={tableDataKeysForImgTechnician}
                    tableHeading={tableHeadingForImgTechnician}
                    borderBottomColor={'#60d69f'}
                    borderBottomWidth={20}
                  />
                )}
              </div>
            ) : edNurseArray && tabName === 'Patients in-Progress' ? (
              <div className='col-md-12 col-sm-12 col-12'>
                {loading ? (
                  <div style={{ margin: 'auto', width: '0%', padding: 10 }}>
                    <Loader
                      type='TailSpin'
                      color='blue'
                      height={50}
                      width={50}
                    />
                  </div>
                ) : (
                  <CustomTable
                    tableData={edNurseArray}
                    tableDataKeys={tableDataKeysForImgTechnician}
                    tableHeading={tableHeadingForImgTechnician}
                    borderBottomColor={'#60d69f'}
                    borderBottomWidth={20}
                  />
                )}
              </div>
            ) : (
              <div className='LoaderStyle'>
                <Loader type='TailSpin' color='red' height={50} width={50} />
              </div>
            )}
          </div>

          <div className='row' style={{ marginBottom: 20 }}>
            <div className='col-md-12 col-sm-12 col-12'>
              <img
                onClick={() => props.history.goBack()}
                src={Back}
                style={{
                  width: 45,
                  height: 35,
                  cursor: 'pointer',
                }}
              />
            </div>
          </div>
        </div>

        <Notification msg={errorMsg} open={openNotification} />
      </div>
    </div>
  )
}
