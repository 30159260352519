import React from "react";
import { Route, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";
import NotFound from "../components/NotFound/NotFound";
// Routes
import InsuranceRoutes from "../subRoutes/Insurance";
import ControlRoomRoutes from "../subRoutes/controlRoom";
import ManagementRoutes from "./managementRoutes";
import PatientManagementRoutes from "./PatientManagement";
import renconciliationRequestRoutes from "./renconciliationRequestRoutes";
import pharmacyRequestRoutes from "./pharmacyRequestRoutes";
import RegisteredPharmaRoutes from "./registeredPharmaRoutes";
import PatientLogRoutes from "./PatientLog";
import LabTechnicianRoutes from "./LabTechnician";
import AssistanceRoutes from "./assistanceRoutes";
import PrimaryRoleRoutes from "./primaryRoleRoutes";
import SecondaryRoleRoutes from "./secondaryRoleRoutes";
import PatientMgmtRoutes from "./patientMgmtRoutes";
import CommunicationMgmtRoutes from "./communicationMgmtRoutes";
import DepartmentRoutes from "./DepartmentRoutes";
import DCDDashboardRoutes from "./DCD";
import AdminDashboardScreenRoutes from "./adminDashboardScreens";
import DischargeRoutes from "./dischargeRoutes";
import NewDischargeRoutes from "./newDischargeRoutes";
import SenseiDischargeRoutes from "./SenseiDischargeRoutes";

// Screens
import DashboardScreen from "../views/Home/home";
import newPatientsScreen from "../views/NewPatients/newPatients";
import dischargedPatientsScreen from "../views/DischargedPatients/dischargedPatients";
import StaffTypes from "../views/UserManagement/staffType/staffTypes";
import AddEditStaffTypes from "../views/UserManagement/staffType/addEditStaffTypes";
import AccessLevel from "../views/UserManagement/accessLevel/accessLevel";
import AddEditAccessLevel from "../views/UserManagement/accessLevel/addEditAccessLevel";
import systemAdmin from "../views/UserManagement/systemAdmin/systemAdmin";
import addEditSystemAdmin from "../views/UserManagement/systemAdmin/addEditSystemAdmin";
import notificationCenter from "../views/Notifications/notifications";
// import TasksList from "../views/TasksList/TasksList";
import LabTasksList from "../views/LabTechnician/TasksList/TasksList";
import LabCompletedTasks from "../views/LabTechnician/CompletedTasks/CompletedTasks";
import LabTechFlagMgmnt from "../views/LabTechFlagMgmt/FlagMgmnt";
import HousekeepingRequests from "../views/HousekeepingRequests/HousekeepingRequests";
import AssistanceByEDNurse from "../views/Assistance/assistance";
import SocialWorkerTasks from "../views/SocialWorkerTasks/SocialWorkerTasks";
import CustomerSatisfactionForm from "../views/SocialWorkerTasks/CustomerSatisfactionForm";
import ServeyScreen from "../views/SocialWorkerTasks/ServeyScreen";
import CustomerList from "../views/SocialWorkerTasks/CustomerList";
import viewSurvey from "../views/SocialWorkerTasks/ViewSurvey";
import DischargeSubTaskList from "../views/SocialWorkerTasks/DischargeSubTasks";
import PatientRegistration from "../views/Registration/PatientRegistration";
import SuccessScreen from "../components/SuccessScreen/SuccessScreen";
import QRCodeScanner from "../components/QRCodeScanner/QRCodeScanner";
import CompletedRegistration from "../views/Registration/CompletedRegistration";
import PendingRegistration from "../views/Registration/PendingRegistration";
import PendingEpisodeRegistration from "../views/Registration/PendingEpisodeRegistration";
import PendingEpisodeAssociatedSuccess from "../views/Registration/PendingEpisodeAssociatedSuccess";
import ViewPatient from "../views/DCD/ViewForm/ViewPatientDetails/ViewPatientDetails";
import FlagMgmt from "../views/FlagMgmt/flagMgmt";
import notesScreen from "../views/NotesScreen/NotesScreen";
import episodeInfoDetailPage from "../views/NotesScreen/EpisodeInfoDetailPage";
import PharmacyListMedicationDetailPage from "../views/NotesScreen/PharmacyListMedicationDetailPage";
import PharmacyListMedicationPage from "../views/NotesScreen/PharmacyListMedicationPage";
import PharmacyHistoryMedicationPage from "../views/NotesScreen/PharmacyHistoryMedicationPage";
import PharmacyRequestsDetail from "../views/NotesScreen/PharmacyRequestsDetailPage";
import PharmacyRequestsPage from "../views/NotesScreen/PharmacyRequestsPage";
import PainManagementPage from "../views/NotesScreen/PainManagementPage";


import ConsultancyRequestsPage from "../views/NotesScreen/ConsultancyRequestsPage";
import ConsultationRequestsDetail from "../views/NotesScreen/ConsultationRequestsDetail";


import LabRequestsPage from "../views/NotesScreen/LabRequestsPage";
import LabRequestsDetail from "../views/NotesScreen/LabRequestsDetailPage";
import RadRequestsPage from "../views/NotesScreen/RadRequestsPage";
import RadRequestsDetail from "../views/NotesScreen/LabRequestsDetailPage";

import patientList from "../views/PatientList/PatientList";
import dischargedPatients from "../views/DischargeEDRs/DischargedEDRs";
import viewdischargedPatients from "../views/DischargeEDRs/viewDischargedEDRs";
//import PharmaNotes from "../views/PharmaNotes/pharmaNotes";
//import Discharge from "../views/Discharge/Discharge";
//import AddMedication from "../views/Discharge/addDischargeMed";
import TriageAssessment from "../views/TriageAssessment/TriageAssessment";
import viewDCDForm from "../views/DCD/ViewForm/viewDCDform";
import viewNewDCDForm from "../views/DCD/ViewForm/viewNewDCDform";
import UnderDevelopment from "../components/UnderDevelopment/underDevelopment";
import clickPicture from "../components/ClickPicture/clickPicture";
import Chat from "../views/Chat/Chat";
import PatientClearenceRoutes from "./patientClearenceRoutes";
import AnestesiaRequestRoutes from "./anesthesiaRequestRoutes";
import LabPatientDetailIndividual from "../views/LabTechnician/patientDetailIndividual";
import LabPatientDetail from "../views/LabTechnician/patientDetail";
import AddEditLabReq from "../views/LabTechnician/addLabReq";
//Nurse Technician
import NursePatientTransferSubmit from "../views/NurseTechnician/transferPatientSubmit";
import NurseTechnician from "../views/NurseTechnician/NurseTechnician";
import NurseTechnicianLabDetails from "../views/NurseTechnician/NurseTechnicianLabDetails";
import NurseTechnicianLabDetailsIndividual from "../views/NurseTechnician/NurseTechnicianLabDetailsIndividual";
import PrintQR from "../views/NurseTechnician//PrintQR";
import PrintQRAll from "../views/NurseTechnician//PrintQRAll";

//Porter
import Porter from "../views/Porter/Porter";
import PorterLabDetails from "../views/Porter/PorterLabDetails";
import PorterLabDetailsIndividual from "../views/Porter/PorterLabDetailsIndividual";
import PorterPharmaDetails from "../views/Porter/PorterPharmaDetails";

// Rad Request Receiver
import RadReqReceiver from "../views/RadReqReceiver/RadReqReceiver";
import RadReqReceiverDetails from "../views/RadReqReceiver/RadReqReceiverDetails";
import RadReqReceiverDetailsIndividual from "../views/RadReqReceiver/RadReqReceiverDetailsIndividual";

import RadReqReceiver2 from "../views/RadReqReceiver/RadReqReceiverWork";
import RadReqReceiver2Details from "../views/RadReqReceiver/RadReqReceiverWorkDetails";
import RadReqReceiver2DetailsIndividual from "../views/RadReqReceiver/RadReqReceiverDetailsWorkIndividual";

// Code Blue Team
import CodeBlueTeam from "../views/CodeBlueTeam/PatientList";
import AssignCodeBlueTeam from "../views/CodeBlueTeam/AssignCodeBlueTeam";
import ViewAllRequestBlueTeam from "../views/CodeBlueTeam/viewBlueTeamRequests";

//Customer Care
import CustomerCareServices from "../views/CustomerCareServices/customerCareServices";
import CustomerCareSatisfactionForm from "../views/CustomerCareServices/CustomerSatisfactionForm";
import CustomerCareServey from "../views/CustomerCareServices/ServeyScreen";
import CustomerCareList from "../views/CustomerCareServices/CustomerList";
import MedicationView from "../views/CustomerCareServices/MedicationView";
import CustomerCareServicesDetail from "../views/CustomerCareServices/ServiceDetail";
//import LabTasksList from "../views/LabTechnician/TasksList/TasksList";
//import LabCompletedTasks from '../views/LabTechnician/CompletedTasks/CompletedTasks'
//import LabTechFlagMgmnt from '../views/LabTechFlagMgmt/FlagMgmnt'
//import HousekeepingRequests from '../views/HousekeepingRequests/HousekeepingRequests'
//import SocialWorkerTasks from '../views/SocialWorkerTasks/SocialWorkerTasks'
//import CustomerSatisfactionForm from '../views/SocialWorkerTasks/CustomerSatisfactionForm'
//import ServeyScreen from '../views/SocialWorkerTasks/ServeyScreen'
//import CustomerList from '../views/SocialWorkerTasks/CustomerList'
//import viewSurvey from '../views/SocialWorkerTasks/ViewSurvey'
//import DischargeSubTaskList from '../views/SocialWorkerTasks/DischargeSubTasks'
//import PatientRegistration from '../views/Registration/PatientRegistration'
import PatientRegistrationForParamedics from "../views/PatientRegistrationForParamedics/PatientRegistrationForParamedics";
import ParamedicsPendingEDRList from "../views/EDPatientlist/PendingList";
import CCAssign from "../views/DCD/Assign/CCAssign";
import CareStreamTable from "../views/EDPatientlist/PendingList";
import CareStreamMain from "../views/CareStreamAssign/CareStreamMain";
import ParamedicsPendingEdr from "../views/EDPatientlist/PendingList";
import ParamedicsCompletedEdr from "../views/EDPatientlist/CompletedList";

import NursesTasksScreen from "../views/NurseTasksList/tasksList";
import RadiologyTasksScreen from "../views/RadiologyTaskList/tasksList";
import RadiologyReportsScreen from "../views/RadiologyReports/radReports";
import LabReportsScreen from "../views/LaboratoryReport/labReport";
import ViewLabReportsScreen from "../views/LaboratoryReport/viewLabReport";
import AssignHousekeepingScreen from "../views/AssignHousekeeping/assignHousekeeping";
import transactionReports from "../views/TransactionReports/transactionReports";
import viewTransactionReport from "../views/TransactionReports/viewTransactionReport";
import codeblue from "../views/CodeBlueTeam/PatientList";

import Favorites from "../views/Favorites/Favorites";

import notifications from "../views/Notifications/dhrNotification";
class HomeScreenRoutes extends React.PureComponent {
  render() {
    return (
      <Switch>
        <Route exact path={"/dashboard"} component={DashboardScreen} />
        <Route exact path={"/dashboard/home"} component={DashboardScreen} />
        <Route exact path={"/dashboard/home/chat"} component={Chat} />
        <Route
          exact
          path={"/dashboard/notification"}
          component={notifications}
        />
        <Route
          exact
          path={"/dashboard/home/manage-favorites/:serviceType(lab|rad)"}
          component={Favorites}
        />
        <Route
          exact
          path={"/dashboard/home/notification"}
          component={notifications}
        />
        <Route
          exact
          path={"/dashboard/home/transactionreports"}
          component={transactionReports}
        />{" "}
        <Route
          exact
          path={"/dashboard/home/transactionreports/view"}
          component={viewTransactionReport}
        />
        <Route
          path={"/dashboard/home/adminDashboard"}
          component={AdminDashboardScreenRoutes}
        />
        <Route
          exact
          path={"/dashboard/home/chat/clickpicture"}
          component={Chat}
        />
        <Route
          path={"/dashboard/home/patientclearence"}
          component={PatientClearenceRoutes}
        />
        <Route
          path={"/dashboard/home/anesthesiarequests"}
          component={AnestesiaRequestRoutes}
        />
        <Route exact path={"/dashboard/home/chat/video"} component={Chat} />
        <Route
          path={"/dashboard/home/dcddashboard"}
          component={DCDDashboardRoutes}
        />
        <Route path={"/dashboard/home/viewDCD"} component={viewDCDForm} />
        <Route
          exact
          path={"/dashboard/underprogress"}
          component={UnderDevelopment}
        />
        <Route
          path={"/dashboard/home/management"}
          component={ManagementRoutes}
        />
        <Route
          path={"/dashboard/home/reconciliationrequest"}
          component={renconciliationRequestRoutes}
        />
        <Route
          path={"/dashboard/home/patientmanagement"}
          component={PatientManagementRoutes}
        />
        <Route
          path={"/dashboard/home/patientlog"}
          component={PatientLogRoutes}
        />
        <Route
          path={"/dashboard/home/departmentmanagement"}
          component={DepartmentRoutes}
        />
        <Route exact path={"/dashboard/home/codeblue"} component={codeblue} />
        <Route
          exact
          path={"/dashboard/home/codeblue/AssignCodeBlueTeam"}
          component={AssignCodeBlueTeam}
        />
        <Route
          path={"/dashboard/home/primaryroles"}
          component={PrimaryRoleRoutes}
        />
        <Route
          path={"/dashboard/home/secondaryroles"}
          component={SecondaryRoleRoutes}
        />
        <Route
          path={"/dashboard/home/patientmgmt"}
          component={PatientMgmtRoutes}
        />
        <Route
          path={"/dashboard/home/patientregistration"}
          component={PatientRegistration}
        />
        <Route
          path={"/dashboard/home/PatientRegistrationFromParamedics"}
          component={PatientRegistrationForParamedics}
        />
        <Route
          exact
          path={`/dashboard/home/assignDCDForm`}
          component={ParamedicsPendingEDRList}
        />
        <Route
          exact
          path={`/dashboard/home/assignDCDForm/assignCC`}
          component={CCAssign}
        />
        <Route
          exact
          path={`/dashboard/home/careStreamPatients`}
          component={CareStreamTable}
        />
        <Route
          exact
          path={`/dashboard/home/careStreamPatients/assign`}
          component={CareStreamMain}
        />
        <Route
          exact
          path={`/dashboard/home/careStreamPatients/assign/printAssignCS`}
          component={CareStreamMain}
        />
        <Route
          exact
          path={`/dashboard/home/transferpending`}
          component={ParamedicsPendingEdr}
        />
        <Route
          exact
          path={`/dashboard/home/transfercompleted`}
          component={ParamedicsCompletedEdr}
        />
        <Route path={"/dashboard/home/success"} component={SuccessScreen} />
        <Route path={"/dashboard/home/qrScan"} component={QRCodeScanner} />
        <Route
          path={"/dashboard/home/completedregistration"}
          component={CompletedRegistration}
        />
        <Route
          path={"/dashboard/home/pendingregistration"}
          component={PendingRegistration}
        />
        <Route
          path={"/dashboard/home/pendingepisode/success"}
          component={PendingEpisodeAssociatedSuccess}
        />
        <Route
          path={"/dashboard/home/pendingepisode"}
          component={PendingEpisodeRegistration}
        />
        <Route
          path={"/dashboard/home/communicationMgmt"}
          component={CommunicationMgmtRoutes}
        />
        <Route path={"/dashboard/home/viewpatient"} component={ViewPatient} />
        <Route path={"/dashboard/home/flagMgmt"} component={FlagMgmt} />
        <Route path={"/dashboard/home/insurance"} component={InsuranceRoutes} />
        <Route
          path={"/dashboard/labtechnician"}
          component={LabTechnicianRoutes}
        />
        <Route path={"/dashboard/taskslist"} component={LabTasksList} />
        <Route
          path={"/dashboard/completedtasks"}
          component={LabCompletedTasks}
        />
        <Route
          exact
          path={"/dashboard/flagmanagement"}
          component={LabTechFlagMgmnt}
        />
        <Route
          exact
          path={"/dashboard/labtechnicianpatientdetail/details/:requestId/:serviceId"}
          component={LabPatientDetailIndividual}
        />
        <Route
          exact
          path={"/dashboard/labtechnicianpatientdetail"}
          component={LabPatientDetail}
        />
        <Route
          exact
          path={"/dashboard/labtechnicianForm/"}
          component={AddEditLabReq}
        />
        <Route exact path={"/dashboard/home/nursetechnician/details/:id/:requestId/printAll"} component={PrintQRAll} />
        <Route exact path={"/dashboard/home/nursetechnician/details/:id/:requestId/view/:serviceId/print"} component={PrintQR} />
        <Route
          exact
          path={"/dashboard/home/nursetechnician/details/:id/:requestId/view/:serviceId"}
          component={NurseTechnicianLabDetailsIndividual}
        />
        <Route
          exact
          path={"/dashboard/home/nursetechnician/details/:id/:requestId"}
          component={NurseTechnicianLabDetails}
        />
        <Route
          exact
          path={"/dashboard/home/nursetechnician"}
          component={NurseTechnician}
        />
        <Route
          exact
          path={"/dashboard/home/nursetechniciancompleted"}
          component={NurseTechnician}
        />

        <Route path={"/dashboard/home/rplist"} component={RegisteredPharmaRoutes} />

        <Route exact path={"/dashboard/home/porter/details/:id/:requestId/view/:serviceId/print"} component={PrintQR} />
        <Route
          exact
          path={"/dashboard/home/porter/details/:id/:requestId/view/:serviceId"}
          component={PorterLabDetailsIndividual}
        />
        <Route
          exact
          path={"/dashboard/home/porter/details/:id/:requestId"}
          component={PorterLabDetails}
        />




        <Route
          exact
          path={"/dashboard/home/porter/pharma-details/:id/:requestId"}
          component={PorterPharmaDetails}
        />

        <Route
          exact
          path={"/dashboard/home/porter"}
          component={Porter}
        />
        <Route
          exact
          path={"/dashboard/home/portercompleted"}
          component={Porter}
        />

        <Route
          exact
          path={"/dashboard/home/rrrp2/details/:id/:subRequestId/view/:serviceId"}
          component={RadReqReceiver2DetailsIndividual}
        />
        <Route
          exact
          path={"/dashboard/home/rrrp2/details/:id/:subRequestId"}
          component={RadReqReceiver2Details}
        />
        <Route
          exact
          path={"/dashboard/home/rrrp2"}
          component={RadReqReceiver2}
        />
        <Route
          exact
          path={"/dashboard/home/rrrp2completed"}
          component={RadReqReceiver2}
        />


        <Route
          exact
          path={"/dashboard/home/rrr/details/:id/:subRequestId/view/:serviceId"}
          component={RadReqReceiverDetailsIndividual}
        />
        <Route
          exact
          path={"/dashboard/home/rrr/details/:id/:subRequestId"}
          component={RadReqReceiverDetails}
        />
        <Route
          exact
          path={"/dashboard/home/rrr"}
          component={RadReqReceiver}
        />
        <Route
          exact
          path={"/dashboard/home/rrrcompleted"}
          component={RadReqReceiver}
        />


        <Route
          exact
          path={"/dashboard/home/patienttransfer"}
          component={NursePatientTransferSubmit}
        />
        <Route
          path={"/dashboard/home/newPatients"}
          path={"/dashboard/home/housekeepingrequests"}
          path={"/dashboard/home/newPatients"}
          path={"/dashboard/home/housekeepingrequests"}
          component={HousekeepingRequests}
        />
        <Route
          path={"/dashboard/home/housekeepingrequest"}
          component={HousekeepingRequests}
        />
        <Route
          path={"/dashboard/home/assistanceByEDNurse"}
          component={AssistanceByEDNurse}
        />
        <Route
          path={"/dashboard/home/customercare/details/:taskId"}
          component={CustomerCareServicesDetail}
        />
        <Route
          path={"/dashboard/home/taskslistforcustomercare"}
          component={CustomerCareServices}
        />
        <Route
          path={"/dashboard/home/completedtasksforcustomercare"}
          component={CustomerCareServices}
        />
        <Route
          exact
          path={"/dashboard/home/codeblue"}
          component={CodeBlueTeam}
        />
        <Route
          exact
          path={"/dashboard/home/codeblue/AssignCodeBlueTeam"}
          component={AssignCodeBlueTeam}
        />
  
        <Route
          path={"/dashboard/home/customercare/satisfactionform"}
          component={CustomerCareSatisfactionForm}
        />
        <Route
          path={"/dashboard/home/customercare/servey"}
          component={CustomerCareServey}
        />
        <Route
          path={"/dashboard/home/customercareList"}
          component={CustomerCareList}
        />
        <Route
          path={"/dashboard/home/medicationView"}
          component={MedicationView}
        />
        <Route
          exact
          path={"/dashboard/home/success"}
          component={SuccessScreen}
        />
        <Route
          exact
          path={"/dashboard/home/customercare/viewsurvey"}
          component={viewSurvey}
        />
        <Route
          path={"/dashboard/home/taskslistforsocialworker"}
          exact
          path={"/dashboard/home/taskslistforsocialworker"}
          component={SocialWorkerTasks}
        />
        <Route
          path={"/dashboard/home/completedtasksforsocialworker"}
          component={SocialWorkerTasks}
        />
        <Route
          exact
          path={
            "/dashboard/home/taskslistforsocialworker/customersatisfactionform"
          }
          component={CustomerSatisfactionForm}
        />
        <Route
          exact
          path={
            "/dashboard/home/taskslistforsocialworker/customersatisfactionform/servey"
          }
          component={ServeyScreen}
        />
        <Route
          exact
          path={
            "/dashboard/home/taskslistforsocialworker/customersatisfactionform/servey/dischargesubtasklist"
          }
          component={DischargeSubTaskList}
        />
        <Route
          exact
          path={"/dashboard/home/customerList"}
          component={CustomerList}
        />
        <Route
          exact
          path={"/dashboard/home/customerList/customersatisfactionform"}
          component={CustomerSatisfactionForm}
        />
        <Route
          exact
          path={"/dashboard/home/customerList/customersatisfactionform/servey"}
          component={ServeyScreen}
        />
        <Route
          exact
          path={"/dashboard/home/viewsurvey"}
          component={viewSurvey}
        />
        <Route
          path={"/dashboard/home/newPatients"}
          component={newPatientsScreen}
        />
        <Route
          path={"/dashboard/home/dischargedPatients"}
          component={dischargedPatientsScreen}
        />
        <Route path={"/dashboard/controlroom"} component={ControlRoomRoutes} />
        <Route
          path={"/dashboard/notificationCenter"}
          component={notificationCenter}
        />
        <Route exact path={"/dashboard/typestaff"} component={StaffTypes} />
        <Route
          path={"/dashboard/typestaff/add"}
          component={AddEditStaffTypes}
        />
        <Route
          path={"/dashboard/typestaff/edit"}
          component={AddEditStaffTypes}
        />
        <Route exact path={"/dashboard/accesslevel"} component={AccessLevel} />
        <Route
          path={"/dashboard/accesslevel/add"}
          component={AddEditAccessLevel}
        />
        <Route
          path={"/dashboard/accesslevel/edit"}
          component={AddEditAccessLevel}
        />
        <Route exact path={"/dashboard/systemadmin"} component={systemAdmin} />
        <Route
          path={"/dashboard/systemadmin/add"}
          component={addEditSystemAdmin}
        />
        <Route
          path={"/dashboard/systemadmin/edit"}
          component={addEditSystemAdmin}
        />
        <Route path={"/dashboard/home/patientlist"} component={patientList} />
        <Route
          path={"/dashboard/home/alldischargedpatients"}
          component={dischargedPatients}
        />
        <Route
          path={"/dashboard/home/viewalldischargedpatients"}
          component={viewdischargedPatients}
        />
        <Route
          path={"/dashboard/home/notes/discharge"}
          component={DischargeRoutes}
        />
        <Route
          path={"/dashboard/home/pendingpatients/notes"}
          component={notesScreen}
        />
        <Route
          path={"/dashboard/home/pharmanotes"}
          component={pharmacyRequestRoutes}
        />
        <Route
          path={"/dashboard/home/triageassessment"}
          component={TriageAssessment}
        />
        <Route
          path={"/dashboard/home/radiologyReports"}
          component={RadiologyReportsScreen}
        />
        <Route
          path={"/dashboard/home/laboratoryReports"}
          component={LabReportsScreen}
        />
        <Route
          path={"/dashboard/home/viewLaboratoryReport"}
          component={ViewLabReportsScreen}
        />
        <Route
          path={"/dashboard/home/radiologyTasks"}
          component={RadiologyTasksScreen}
        />
        <Route
          path={"/dashboard/home/radiologyTasksCompleted"}
          component={RadiologyTasksScreen}
        />
        <Route
          path={"/dashboard/home/assignHousekeeping"}
          component={AssignHousekeepingScreen}
        />
        <Route
          path={"/dashboard/home/assistance"}
          component={AssistanceRoutes}
        />
        <Route
          path={"/dashboard/home/nurseTasks"}
          component={NursesTasksScreen}
        />

        <Route path={"/dashboard/home/sensei/discharge"} component={SenseiDischargeRoutes} />

        <Route path={"/dashboard/home/:id/discharge"} component={NewDischargeRoutes} />
        <Route path={"/dashboard/home/:id/dcd-history"} component={viewNewDCDForm} />

        <Route path={"/dashboard/home/:id/consultation/:requestId/details"} component={ConsultationRequestsDetail} />
        <Route path={"/dashboard/home/:id/consultation/new"} component={ConsultancyRequestsPage} />

        <Route path={"/dashboard/home/:id/painmanagement"} component={PainManagementPage} />
        <Route path={"/dashboard/home/:id/:serviceType(pharmacy|non-pharmacy)/listMedication/:requestId/details/:subRequestId"} component={PharmacyListMedicationDetailPage} />
        <Route path={"/dashboard/home/:id/:serviceType(pharmacy|non-pharmacy)/medication-history"} component={PharmacyHistoryMedicationPage} />
        <Route path={"/dashboard/home/:id/:serviceType(pharmacy|non-pharmacy)/listMedication"} component={PharmacyListMedicationPage} />
        <Route path={"/dashboard/home/:id/:serviceType(pharmacy|non-pharmacy)/:requestId/details"} component={PharmacyRequestsDetail} />
        <Route path={"/dashboard/home/:id/:serviceType(pharmacy|non-pharmacy)/new"} component={PharmacyRequestsPage} />
        <Route path={"/dashboard/home/:id/:serviceType(lab|rad)/:requestId/details"} component={LabRequestsDetail} />
        /*
        <Route path={"/dashboard/home/:id/lab/:requestId/details"} component={LabRequestsDetail} />
        */
        <Route path={"/dashboard/home/:id/rad/new"} component={RadRequestsPage} />
        <Route path={"/dashboard/home/:id/lab/new"} component={LabRequestsPage} />
        <Route path={"/dashboard/home/:id/:pagename"} component={episodeInfoDetailPage} />
        <Route path={"/dashboard/home/notes"} component={notesScreen} />
        <Route path="*" component={NotFound} />
      </Switch>
    );
  }
}
export default HomeScreenRoutes;
