import axios from "axios";
import { reportsCPDashboard } from "../../../public/endpoins";

export function getReportsPharmacist(
  setTotalPending,
  setTotalPendingColor,
  setPendingTat,
  setPendingTATColor,
  setGraphPending,
  set3rdTotalPending,
  set3rdTotalPendingColor,
  set3rdPendingTat,
  set3rdPendingTATColor,
  set3rdGraphPending,
  setCumulativeOrders,
  setCumulativeOrdersColor
) {
  // currentUser
  axios.get(reportsCPDashboard).then((res) => {
    if (res.data.success) {
      console.log("Clinical Data", res.data);
      if (
        res.data.firstCard.totalPending >= 0 &&
        res.data.firstCard.totalPending <= 3
      ) {
        setTotalPending(res.data.firstCard.totalPending);
        setTotalPendingColor("#13D59F");
      } else if (
        res.data.firstCard.totalPending >= 4 &&
        res.data.firstCard.totalPending <= 5
      ) {
        setTotalPending(res.data.firstCard.totalPending);
        setTotalPendingColor("#FFBC28");
      } else if (res.data.firstCard.totalPending >= 6) {
        setTotalPending(res.data.firstCard.totalPending);
        setTotalPendingColor("#FF0000");
      } else {
        setTotalPending(res.data.firstCard.totalPending);
      }
      //setTotalPending(res.data.firstCard.totalPending)
      //setTotalPendingColor("#FF0000")
      if (res.data.firstCard.TAT >= 0 && res.data.firstCard.TAT <= 4) {
        setPendingTat(res.data.firstCard.TAT);
        setPendingTATColor("#13D59F");
      } else if (res.data.firstCard.TAT >= 5 && res.data.firstCard.TAT <= 7) {
        setPendingTat(res.data.firstCard.TAT);
        setPendingTATColor("#FFBC28");
      } else if (res.data.firstCard.TAT >= 8) {
        setPendingTat(res.data.firstCard.TAT);
        setPendingTATColor("#FF0000");
      } else {
        setPendingTat(res.data.firstCard.TAT);
      }
      // //setPendingTat(res.data.firstCard.TAT);
      // //setPendingTATColor()
      setGraphPending(res.data.firstCard.perHour);

      // // For 3nd Card
      // // Order per Hour

      if (
        res.data.thirdCard.totalPending >= 0 &&
        res.data.thirdCard.totalPending <= 10
      ) {
        set3rdTotalPending(res.data.thirdCard.totalPending);
        set3rdTotalPendingColor("#13D59F");
      } else if (
        res.data.thirdCard.totalPending >= 11 &&
        res.data.thirdCard.totalPending <= 3
      ) {
        set3rdTotalPending(res.data.thirdCard.totalPending);
        set3rdTotalPendingColor("#FFBC28");
      } else if (res.data.thirdCard.totalPending >= 14) {
        set3rdTotalPending(res.data.thirdCard.totalPending);
        set3rdTotalPendingColor("#FF0000");
      } else {
        set3rdTotalPending(res.data.thirdCard.totalPending);
      }
      //setTotalPending(res.data.thirdCard.totalPending)
      //setTotalPendingColor("#FF0000")
      if (res.data.thirdCard.TAT >= 0 && res.data.thirdCard.TAT <= 4) {
        set3rdPendingTat(res.data.thirdCard.TAT);
        set3rdPendingTATColor("#13D59F");
      } else if (res.data.thirdCard.TAT >= 5 && res.data.thirdCard.TAT <= 7) {
        set3rdPendingTat(res.data.thirdCard.TAT);
        set3rdPendingTATColor("#FFBC28");
      } else if (res.data.thirdCard.TAT >= 8) {
        set3rdPendingTat(res.data.thirdCard.TAT);
        set3rdPendingTATColor("#FF0000");
      } else {
        set3rdPendingTat(res.data.thirdCard.TAT);
      }
      // //setPendingTat(res.data.thirdCard.TAT);
      // //setPendingTATColor()
      set3rdGraphPending(res.data.thirdCard.perHour);

      // // // For 4td Card
      // // //commulative  Per Hour
      if (res.data.cumulativeOrders >= 0 && res.data.cumulativeOrders <= 25) {
        setCumulativeOrders(res.data.cumulativeOrders);
        setCumulativeOrdersColor("#13D59F");
      } else if (
        res.data.cumulativeOrders >= 26 &&
        res.data.cumulativeOrders <= 30
      ) {
        setCumulativeOrders(res.data.cumulativeOrders);
        setCumulativeOrdersColor("#FFBC28");
      } else if (res.data.cumulativeOrders >= 31) {
        setCumulativeOrders(res.data.cumulativeOrders);
        setCumulativeOrdersColor("#FF0000");
      } else {
        setCumulativeOrders(res.data.cumulativeOrders);
      }
    }
  });
}
