import React, { useState, useEffect, useReducer } from "react";
import cookie from "react-cookies";
import Header from "../../../components/Header/Header";
import Notification from "../../../components/Snackbar/Notification.js";
import _ from "lodash";
import "../../../assets/jss/material-dashboard-react/components/loaderStyle.css";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import axios from "axios";
import {
  addPatientDetails,
  addPastHistory,
  addInvestigation,
  addRos,
  addPhysicalExam,
  addActionPlan,
  addCourseOfVisit,
  getAllChiefComplaints,
  getIcdForDropdown,
  getIcdbyFeaturedGroup,
} from "../../../public/endpoins";
// Icons
import PastMedicalBoxIcon from "../../../assets/img/PastHistory.png";
import RosBoxIcon from "../../../assets/img/ROSicon.png";
import InvestigationsBoxIcon from "../../../assets/img/InvestigationsIcon.png";
import ActionPlanBoxIcon from "../../../assets/img/Action and Plan.png";
import CourseVisitBoxIcon from "../../../assets/img/CourseOfVisit.png";
import PatientDetailBoxIcon from "../../../assets/img/PatientDetails.png";
import PhysicalExamIcon from "../../../assets/img/PatientCare.png";
import PatientDetailsQuick from "../../../components/PatientDetails/PatientDetailsQuick";
// Chips Rows
import ActionPlanComponent from "./FormRows/ActionPlan";
import CourseVisitComponent from "./FormRows/CourseVisit";
import FormRows from "./FormRows/FormRow";
import PatientDetailsRows from "./FormRows/PatientDetailsFormRow";
import PhysicalExamFormRow from "./FormRows/PhysicalExamFormRow";

export default function Form(props) {

  const matches = useMediaQuery("(min-width:600px)");

  const initialState = {
    reason: "",
  };

  function reducer(state, { field, value }) {
    return {
      ...state,
      [field]: value,
    };
  }

  const [state, dispatch] = useReducer(reducer, initialState);

  const { reason } = state;

  const [currentUser, setCurrentUser] = useState(cookie.load("current_user"));
  const [edrId, setedrId] = useState("");
  const [patientDetails, setPatientDetails] = useState("");
  const [OpenReasonModal, setOpenReasonModal] = useState(false);
  const [rowSelected, setRowSelected] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setsuccessMsg] = useState("");
  const [openNotification, setOpenNotification] = useState(false);
  const [nasalDrainageCongestion, setNasalDrainageCongestion] = useState(
    []
    // "Nasal congestion"
  );
  const [CVSMurmurConfig, setCVSMurmurConfig] = useState([]);
  const [TendernessConfig, setTendernessConfig] = useState([]);
  const [ReboundConfig, setReboundConfig] = useState([]);
  const [AbnormalBowelSoundsConfig, setAbnormalBowelSoundsConfig] = useState([]);
  const [HepatomegalySplenomegalyMassConfig, setHepatomegalySplenomegalyMassConfig] = useState([]);
  const [BlackBloodyHemePostStoolConfig, setBlackBloodyHemePostStoolConfig] = useState([]);
  const [CyanosisDiaphoresisPallorConfig, setCyanosisDiaphoresisPallorConfig] = useState([]);
  const [FacialDroop, setFacialDroop] = useState([]);
  const [WeaknessSensoryLoss, setWeaknessSensoryLoss] = useState([]);
  
  const [troubleBreathing, setTroubleBreathing] = useState(
    []
    // "Dyspnea, unspecified?"
  );
  const [chestPain, setChestPain] = useState([]);
  const [abdominalPain, setAbdominalPain] = useState(
    [],
    // "Unspecified abdominal pain"
  );
  const [vomiting, setVomiting] = useState([]); //"Nausea");
  const [diarrhea, setDiarrhea] = useState("Melena");
  const [stools, setStools] = useState([]); //"Melena");
  const [problemUrinating, setProblemUrinating] = useState("Dysuria");
  const [frequentUrination, setFrequentUrination] = useState("Dysuria");
  const [abnormalBleeding, setAbnormalBleeding] = useState(
    "Abnormal uterine and vaginal bleeding, unspecified"
  );
  const [postmenopousol, setPostmenopousol] = useState(
    "Menopausal and female climacteric states (Postmenopausal!)"
  );
  const [backPain, setBackPain] = useState("Low back pain");
  const [legPain, setLegPain] = useState("Pain in right leg");
  const [footSwelling, setFootSwelling] = useState(
    "Localized swelling, mass and lump, unspecified lower limb"
  );
  const [lostFeeling, setLostFeeling] = useState(
    "Unspecified disturbances of skin sensation"
  );
  const [chiefComplaintList, setChiefComplaintList] = useState([]);
  const [Icd10CodesList, setIcd10CodesList] = useState([{
    name: "Loading",
    code: "Please Wait:"
  },{
    name: "Loading",
    code: "Please Wait:"
  }]);
  const [Icd10Codes, setIcd10Codes] = useState([]);
  const [searchedICD10Keyword, setSearchedICD10Keyword] = useState("");
  

  const styles = {
    stylesForButton: {
      color: "white",
      cursor: "pointer",
      borderRadius: 5,
      backgroundColor: "#2c6ddd",
      width: "185px",
      height: "45px",
    },
    stylesForDCDButton: {
      cursor: "pointer",
      borderRadius: 5,
      height: "45px",
    },
  };

  const investigation = [
    {
      name: "Rhythm ECG",
      sortNumber: 1,
      chips: [
        {
          name: "Add ECG Report",
          image: [],
        },
        {
          name: "NSR",
        },
        {
          name: "Abnml",
        },
      ],
      voiceNotes: {
        audio: '',
        notes: ''
      },
    },
    {
      name: "12-Lead ECG",
      sortNumber: 2,
      chips: [
        {
          name: "Rate",
          detail: "",
        },
        {
          name: "NSR",
          detail: "",
        },
        {
          name: "Nml Intervals",
          detail: "",
        },
        {
          name: "Nml QRS",
          detail: "",
        },
        {
          name: "Nml ST/T",
          detail: "",
        },
        {
          name: "Nml 12-Lead",
          detail: "",
        },
        {
          name: "Tachycardia/Bradycardia/Atrial Fibrillation",
          detail: "",
        },
        {
          name: "Wide QRS LBBB RBBB IVCD",
          detail: "",
        },
        {
          name: "Heart Black 1* 2* 3*",
          detail: "",
        },
        {
          name: "Non Specific ST. T Abnormalities",
          detail: "",
        },
        {
          name: "ST Elevation/ST Depression/T-Wave Inversion",
          detail: "",
        },
      ],
    },
    {
      name: "Prior ECG",
      sortNumber: 3,
      chips: [
        {
          name: "Unavailable",
          detail: "",
        },
        {
          name: "Unchanged",
          detail: "",
        },
        {
          name: "Changed",
          detail: "",
        },
        {
          name: "Interp Contemporaneously by me",
          detail: "",
        },
        {
          name: "I Agree/Confirm Computer Reading",
          detail: "",
        },
      ],
    },
    {
      name: "CBC",
      sortNumber: 4,
      radios: [
        {
          name: "Normal",
          code: "Normal",
        },
        {
          name: "Nml Except",
          code: "Nml Except",
        },
      ],
      Texts: [
        {
          name: "WBC",
          value: "",
        },
        {
          name: "Hgb",
          value: "",
        },
        {
          name: "Hct",
          value: "",
        },
        {
          name: "Platelets",
          value: "",
        },
        {
          name: "Segs",
          value: "",
        },
        {
          name: "Bands",
          value: "",
        },
        {
          name: "Lymphs",
          value: "",
        },
      ],
      voiceNotes: {
        audio: '',
        notes: ''
      },
    },
    {
      name: "Chemistries",
      sortNumber: 5,
      radios: [
        {
          name: "Normal",
          code: "Normal",
        },
        {
          name: "Nml Except",
          code: "Nml Except",
        },
      ],
      Texts: [
        {
          name: "Na",
          value: "",
        },
        {
          name: "K",
          value: "",
        },
        {
          name: "CL",
          value: "",
        },
        {
          name: "CO2",
          value: "",
        },
        {
          name: "BUN",
          value: "",
        },
        {
          name: "Gluc",
          value: "",
        },
        {
          name: "Creat",
          value: "",
        },
        {
          name: "Lactate",
          value: "",
        },
        {
          name: "CRP",
          value: "",
        },
        {
          name: "Procalcitonin",
          value: "",
        },
        {
          name: "Troponin",
          value: "",
        },
        {
          name: "PT",
          value: "",
        },
        {
          name: "PTT",
          value: "",
        },
        {
          name: "Amylase",
          value: "",
        },
        {
          name: "Lipase",
          value: "",
        },
      ],
      voiceNotes: {
        audio: '',
        notes: ''
      },
    },
    {
      name: "UA",
      sortNumber: 6,
      radios: [
        {
          name: "Normal",
          code: "Normal",
        },
        {
          name: "Nml Except",
          code: "Nml Except",
        },
      ],
      Texts: [
        {
          name: "WBC",
          value: "",
        },
        {
          name: `RBC's`,
          value: "",
        },
        {
          name: "Bacteria",
          value: "",
        },
        {
          name: "Ketones",
          value: "",
        },
      ],
      voiceNotes: {
        audio: '',
        notes: ''
      },
    },
    {
      name: "CXR",
      sortNumber: 7,
      chips: [
        {
          name: "Add CXR Report",
          image: [],
        },
        {
          name: "Chest PA/LAT",
          detail: "",
        },
        {
          name: "AP Port",
          detail: "",
        },
        {
          name: "# of Views",
          detail: "",
        },
        {
          name: "Nml Heart Size",
          detail: "",
        },
        {
          name: "Nml Lung Markings",
          detail: "",
        },
        {
          name: "Nml Great Vessels and Mediastinum",
          detail: "",
        },
        {
          name: "Nml",
          detail: "",
        },
        {
          name: "NAD",
          detail: "",
        },
        {
          name: "Under Penetrated/Over Penetrated/Rotated",
          detail: "",
        },
        {
          name: "Decr Lung Markings C/W COPO",
          detail: "",
        },
        {
          name: "Density C/W Pleural Effusion",
          detail: "",
        },
        {
          name: "Cardiomegaly",
          code: "I51.7",
          detail: "",
        },
      ],
    },
    {
      name: "Prior Xray",
      sortNumber: 8,
      chips: [
        {
          name: "Unchanged",
          detail: "",
        },
        {
          name: "Unavailable",
          detail: "",
        },
        {
          name: "Changed",
          detail: "",
        },
        {
          name: "Interp Contemporaneously by me",
          detail: "",
        },
        {
          name: "Discussed W/Radiologist",
          detail: "",
        },
        {
          name: "Interp by Radiologist",
          detail: "",
        },
        {
          name: "See Separate Report for further Radiographic Interpretation",
          detail: "",
        },
        {
          name: "Personally Reviewed by me",
          detail: "",
        },
      ],
    },
  ];

  const pastMedicalHistory = [
    {
      name: "Past History",
      sortNumber: 1,
      chips: [
        {
          name: "Agree W / Nurse's Note",
          detail: "",
        },
        {
          name: "Tetanus UTD",
          detail: "",
        },
        {
          name: "Neurological Problems",
          detail: "",
          subChips: [
            {
              name: "CVA",
              selected: false,
            },
            {
              name: "Seizure Disorder",
              code: "R56.9",
              selected: false,
            },
          ],
        },
        {
          name: "Lung Disease",
          detail: "",
          subChips: [
            {
              name: "Asthma",
              code: "J45.909",
              selected: false,
            },
            {
              name: "Emphysema, unspecified",
              code: "J43.9",
              selected: false,
            },
          ],
        },
        {
          name: "Cardiac Disease",
          detail: "",
          subChips: [
            {
              name: "Heart Attack (MI)",
              code: "I21.9",
              selected: false,
            },
            {
              name: "Angina",
              code: "I20.9",
              selected: false,
            },
            {
              name: "Heart Failure",
              code: "I50.9",
              selected: false,
            },
          ],
        },
        {
          name: "Diabetes",
          detail: "",
          subChips: [
            {
              name: "Insulin Dependent",
              code: "Z79.4",
              selected: false,
            },
            {
              name: "Diet Controlled",
              code: "E11.9",
              selected: false,
            },
            {
              name: "Oral Antidiabetics",
              code: "Z79.84",
              selected: false,
            },
          ],
        },
        {
          name: "High Blood Pressure",
          code: "I10",
          detail: "",
        },
        {
          name: "High Cholesterol",
          code: "E78.00",
          detail: "",
        },
        {
          name: "Kidney Disease",
          detail: "",
          subChips: [
            {
              name: "Failure",
              selected: false,
            },
            {
              name: "Transplant",
              selected: false,
            },
          ],
        },
        {
          name: "Other / Notes",
          detail: "",
        },
      ],
    },
    {
      name: "Medications",
      sortNumber: 2,
      chips: [
        {
          name: "Add Medication Images",
          image: [],
        },
        /*
        {
          name: "None",
        },
        {
          name: "ASA",
        },
        {
          name: "NSAID",
        },
        {
          name: "Acetaminophen",
        },
        {
          name: "See the List",
          List: [
            {
              name: "Panadol",
            },
            {
              name: "Kestine",
            },
            {
              name: "Azit",
            },
            {
              name: "Ponstone",
            },
            {
              name: "Amoxial",
            },
            {
              name: "Desprine",
            },
            {
              name: "Aspirine",
            },
            {
              name: "Entox",
            },
            {
              name: "Ampicillin",
            },
            {
              name: "Amifostine",
            },
            {
              name: "Omega",
            },
          ],
          autocomplete: true,
        },
        */
      ],
      /*Texts: [
        {
          name: "Notes...",
          value: "",
        },
      ],*/
      voiceNotes: {
        audio: '',
        notes: ''
      },
    },
    {
      name: "Allergies",
      sortNumber: 3,
      /*chips: [
        {
          name: "NKDA",
          detail: "",
        },
        {
          name: "See List",
          List: [
            {
              name: "Drug Allergy",
            },
            {
              name: "Food Allergy",
            },
            {
              name: "Insect Allergy",
            },
            {
              name: "Latex Allergy",
            },
            {
              name: "Mold Allergy",
            },
            {
              name: "Pet Allergy",
            },
            {
              name: "Pollen Allergy",
            },
            {
              name: "Dust Allergy",
            },
            {
              name: "Smoke Allergy",
            },
            {
              name: "Skin Allergy",
            },
            {
              name: "Rhinitis",
            },
          ],
        },
      ],*/
    },
    {
      name: "Social HX",
      sortNumber: 4,
      chips: [
        {
          name: "Smoker",
          detail: "",
          subChips: [
            {
              name: "Occasional",
              selected: false,
            },
            {
              name: "Frequent",
              selected: false,
            },
            {
              name: "Recent",
              selected: false,
            },
          ],
        },
        {
          name: "Drugs",
          detail: "",
          subChips: [
            {
              name: "Occasional",
              selected: false,
            },
            {
              name: "Frequent",
              selected: false,
            },
            {
              name: "Recent",
              selected: false,
            },
          ],
        },
        {
          name: "Alcohol",
          detail: "",
          subChips: [
            {
              name: "Occasional",
              selected: false,
            },
            {
              name: "Frequent",
              selected: false,
            },
            {
              name: "Recent",
              selected: false,
            },
          ],
        },
        {
          name: "None",
          detail: "",
          // subChips: [],
        },
      ],
    },
    {
      name: "Family HX",
      sortNumber: 5,
      chips:[],
      voiceNotes: {
        audio: '',
        notes: ''
      },
    },
  ];

  const ROS = [
    {
      name: "Constitutional", // CONST
      chips: [
        {
          name: "Fever, unspecified",
          detail: "",
          audio: "",
          code: "R50.9",
        },
        {
          name: "Chills (without fever)",
          detail: "",
          audio: "",
          code: "R68.83",
        },
        {
          name: "Normal",
          detail: "",
          audio: "",
          code: "",
        },
      ],
    }, // 1
    {
      name: "Ear, Nose & Throat", // ENT
      chips: [
        {
          name: "Pain in Throat",
          code: "R07.0",
          detail: "",
          audio: "",
        },
        {
          name: "Nasal Drainage / Congestion",
          detail: "",
          audio: "",
          subChips: [
            {
              name: "Nasal congestion",
              code: "R09.81",
              selected: false,
            },
            {
              name: "Postnasal drip",
              code: "R09.82",
              selected: false,
            },
          ],
          /*
          subChipsItems: [
            {
              name: "Nasal congestion",
              code: "R09.81",
            },
            {
              name: "Postnasal drip",
              code: "R09.82",
            },
          ],
          // dropdownOptions: [
          //   {
          //     name: "Nasal congestion",
          //     code: "R09.81",
          //   },
          //   {
          //     name: "Postnasal drip",
          //     code: "R09.82",
          //   },
          // ],
          // dropdownSelectedValue: {
          //   name: "Nasal congestion",
          //   code: "R09.81",
          // },
          defaultValue: nasalDrainageCongestion,
          setter: setNasalDrainageCongestion,
          */
        },
        {
          name: "Normal",
          detail: "",
          audio: "",
          code: "",
        },
      ],
    },
    {
      name: "Cardiovascular System", //CHEST/ CVS
      chips: [
        {
          name: "Cough",
          code: "R05",
          detail: "",
          audio: "",
        },
        {
          name: "Abnormal Sputum",
          code: "R09.3",
          detail: "",
          audio: "",
        },
        {
          name: "Trouble Breathing",
          subChips: [
            {
              name: "Dyspnea, unspecified?",
              code: "R06.00",
              selected: false,
            },
            {
              name: "Acute respiratory distress?",
              code: "R06.03",
              selected: false,
            },
          ],
          detail: "",
          audio: "",
          /*
            dropdownOptions: [
              {
                name: "Dyspnea, unspecified?",
                code: "R06.00",
              },
              {
                name: "Acute respiratory distress?",
                code: "R06.03",
              },
            ],
            dropdownSelectedValue: {
              name: "Dyspnea, unspecified?",
              code: "R06.00",
            },
            defaultValue: troubleBreathing,
            setter: setTroubleBreathing,
          */
        },
        {
          name: "Chest Pain",
          subChips: [
            {
              name: "Chest pain, unspecified",
              code: "R07.9",
              selected: false,
            },
            {
              name: "Chest pain on breathing",
              code: "R07.1",
              selected: false,
            },
            {
              name: "Precordial pain",
              code: "R07.2",
              selected: false,
            },
            {
              name: "Pleurodynia",
              code: "R07.81",
              selected: false,
            },
            {
              name: "Intercostal pain",
              code: "R07.82",
              selected: false,
            },
            {
              name: "Other chest pain",
              code: "R07.89",
              selected: false,
            },
          ],
          detail: "",
          audio: "",
          // defaultValue: chestPain,
          // setter: setChestPain,
        },
        {
          name: "Normal",
          detail: "",
          audio: "",
          code: "",
        },
      ],
      /*
        Texts: [
          {
            name: "Chest / CS Notes...",
            value: "",
          },
        ],
      */
    },
    {
      name: "Gastrointestinal", // GI
      chips: [
        {
          name: "Abdominal Pain",
          subChips: [
            {
              name: "Unspecified abdominal pain",
              code: "R10.9",
              selected: false,
            },
            {
              name: "Acute abdomen",
              code: "R10.0",
              selected: false,
            },
            {
              name: "Upper abdominal pain, unspecified",
              code: "R10.10",
              selected: false,
            },
            {
              name: "Right upper quadrant pain",
              code: "R10.11",
              selected: false,
            },
            {
              name: "Left upper quadrant pain",
              code: "R10.12",
              selected: false,
            },
            {
              name: "Epigastric pain",
              code: "R10.13",
              selected: false,
            },
            {
              name: "Pelvic and perineal pain",
              code: "R10.2",
              selected: false,
            },
            {
              name: "Lower abdominal pain, unspecified",
              code: "R10.30",
              selected: false,
            },
            {
              name: "Right lower quadrant pain",
              code: "R10.31",
              selected: false,
            },
            {
              name: "Left lower quadrant pain",
              code: "R10.32",
              selected: false,
            },
            {
              name: "Periumbilical pain",
              code: "R10.33",
              selected: false,
            },
          ],          
          detail: "",
          audio: "",
          /*
            dropdownSelectedValue: {
              name: "Unspecified abdominal pain",
              code: "R10.9",
            },
            defaultValue: abdominalPain,
            setter: setAbdominalPain,
          */
        },
        {
          name: "Nausea / Vomiting",
          subChips: [
            {
              name: "Nausea",
              code: "R11.0",
              selected: false,
            },
            {
              name: "Vomiting, unspecified",
              code: "R11.10",
              selected: false,
            },
            {
              name: "Vomiting without nausea",
              code: "R11.11",
              selected: false,
            },
            {
              name: "Projectile vomiting",
              code: "R11.12",
              selected: false,
            },
            {
              name: "Vomiting of fecal matter",
              code: "R11.13",
              selected: false,
            },
            {
              name: "Bilious vomitingNausea with vomiting, unspecified",
              code: "R11.14",
              selected: false,
            },
          ],
          detail: "",
          audio: "",
          /*
            dropdownSelectedValue: {
              name: "Nausea",
              code: "R11.0",
            },
            defaultValue: vomiting,
            setter: setVomiting,
          */
        },
        {
          name: "Black / Bloody Stools",
          subChips: [
            {
              name: "Melena",
              code: "K92.1",
              selected: false,
            },
            {
              name: "Hemorrhage of anus and rectum",
              code: "K62.5",
              selected: false,
            },
          ],
          detail: "",
          audio: "",
          /*
            dropdownSelectedValue: {
              name: "Melena",
              code: "K92.1",
            },
            defaultValue: stools,
            setter: setStools,
          */
        },
        {
          name: "Diarrhea, unspecified",
          code: "R19.7",
          detail: "",
          audio: "",
          /*
            dropdownOptions: [
              {
                name: "Melena",
                code: "K92.1",
              },
              {
                name: "Hemorrhage of anus and rectum",
                code: "K62.5",
              },
            ],
            dropdownSelectedValue: {
              name: "Melena",
              code: "K92.1",
            },
            defaultValue: diarrhea,
            setter: setDiarrhea,
          */
        },
        {
          name: "Normal",
          detail: "",
          audio: "",
          code: "",
        },
      ],
      /*
        Texts: [
          {
            name: "GI Notes...",
            value: "",
          },
        ],
      */
    },
    {
      name: "URINARY",
      chips: [
        {
          name: "Dysuria",
          code: "R30.0",
          detail: "",
          audio: "",
        },
        {
          name: "Vesical tenesmus",
          code: "R30.1",
          detail: "",
          audio: "",
        },
        {
          name: "Painful micturition, unspecified",
          code: "R30.9",
          detail: "",
          audio: "",
        },
        {
          name: "Gross hematuria",
          code: "R31.0",
          detail: "",
          audio: "",
        },
        {
          name: "Hematuria, unspecified",
          code: "R31.9",
          detail: "",
          audio: "",
        },
        {
          name: "Unspecified urinary incontinence",
          code: "R32",
          detail: "",
          audio: "",
        },
        {
          name: "Drug induced retention of urine",
          code: "R33.0",
          detail: "",
          audio: "",
        },
        {
          name: "Other retention of urine",
          code: "R33.8",
          detail: "",
          audio: "",
        },
        {
          name: "Retention of urine, unspecified",
          code: "R33.9",
          detail: "",
          audio: "",
        },
        {
          name: "Anuria and oliguria",
          code: "R34",
          detail: "",
          audio: "",
        },
        {
          name: "Frequency of micturition",
          code: "R35.0",
          detail: "",
          audio: "",
        },
        {
          name: "Nocturia",
          code: "R35.1",
          detail: "",
          audio: "",
        },
        {
          name: "Other polyuria",
          code: "R35.8",
          detail: "",
          audio: "",
        },
        {
          name: "Urethral discharge without blood",
          code: "R36.0",
          detail: "",
          audio: "",
        },
        {
          name: "Urethral discharge, unspecified",
          code: "R36.9",
          detail: "",
          audio: "",
        },
        {
          name: "Normal",
          detail: "",
          audio: "",
          code: "",
        },
        /*{
          name: "Problem Urinating",
          dropdownOptions: [
            {
              name: "Dysuria",
              code: "R30.0",
            },
            {
              name: "Vesical tenesmus",
              code: "R30.1",
            },
            {
              name: "Painful micturition, unspecified",
              code: "R30.9",
            },
            {
              name: "Gross hematuria",
              code: "R31.0",
            },
            {
              name: "Hematuria, unspecified",
              code: "R31.9",
            },
            {
              name: "Unspecified urinary incontinence",
              code: "R32",
            },
            {
              name: "Drug induced retention of urine",
              code: "R33.0",
            },
            {
              name: "Other retention of urine",
              code: "R33.8",
            },
            {
              name: "Retention of urine, unspecified",
              code: "R33.9",
            },
            {
              name: "Anuria and oliguria",
              code: "R34",
            },
            {
              name: "Frequency of micturition",
              code: "R35.0",
            },
            {
              name: "Nocturia",
              code: "R35.1",
            },
            {
              name: "Other polyuria",
              code: "R35.8",
            },
            {
              name: "Urethral discharge without blood",
              code: "R36.0",
            },
            {
              name: "Urethral discharge, unspecified",
              code: "R36.9",
            },
          ],
          dropdownSelectedValue: {
            name: "Dysuria",
            code: "R30.0",
          },
          defaultValue: problemUrinating,
          setter: setProblemUrinating,
        },
        {
          name: "Frequent Urination",
          dropdownOptions: [
            {
              name: "Dysuria",
              code: "R30.0",
            },
            {
              name: "Vesical tenesmus",
              code: "R30.1",
            },
            {
              name: "Painful micturition, unspecified",
              code: "R30.9",
            },
            {
              name: "Gross hematuria",
              code: "R31.0",
            },
            {
              name: "Hematuria, unspecified",
              code: "R31.9",
            },
            {
              name: "Unspecified urinary incontinence",
              code: "R32",
            },
            {
              name: "Drug induced retention of urine",
              code: "R33.0",
            },
            {
              name: "Other retention of urine",
              code: "R33.8",
            },
            {
              name: "Retention of urine, unspecified",
              code: "R33.9",
            },
            {
              name: "Anuria and oliguria",
              code: "R34",
            },
            {
              name: "Frequency of micturition",
              code: "R35.0",
            },
            {
              name: "Nocturia",
              code: "R35.1",
            },
            {
              name: "Other polyuria",
              code: "R35.8",
            },
            {
              name: "Urethral discharge without blood",
              code: "R36.0",
            },
            {
              name: "Urethral discharge, unspecified",
              code: "R36.9",
            },
          ],
          dropdownSelectedValue: {
            name: "Dysuria",
            code: "R30.0",
          },
          defaultValue: frequentUrination,
          setter: setFrequentUrination,
        },*/
      ],
      voiceNotes: {
        audio: '',
        notes: ''
      },
      /*
        Texts: [
          {
            name: "Urinary Notes...",
            value: "",
          },
        ],
      */
    },
    {
      name: "FEMALE GENITAL",
      chips: [
        {
          name: "Abnormal Bleeding / Discharge LNMP",
          subChips: [
            {
              name: "Abnormal uterine and vaginal bleeding, unspecified",
              code: "N93.9",
              selected: false,
            },
            {
              name:
                "Noninflammatory disorder of vagina, unspecified (for discharge!)",
              code: "N89.9",
              selected:false,
            },
          ],
          /*
          dropdownSelectedValue: {
            name: "Abnormal uterine and vaginal bleeding, unspecified",
            code: "N93.9",
          },
          defaultValue: abnormalBleeding,
          setter: setAbnormalBleeding,
          */
        },
        {
          name: "Postmenopousol / Hysterectomy",
          subChips: [
            {
              name:
                "Menopausal and female climacteric states (Postmenopausal!)",
              code: "N95.1",
              selected: false,
            },
            {
              name:
                "Acquired absence of both cervix and uterus (post hysterectomy?)",
              code: "90.710",
              selected: false,
            },
          ],
          detail: "",
          audio: "",
          /*
            dropdownSelectedValue: {
              name: "Menopausal and female climacteric states (Postmenopausal!)",
              code: "N95.1",
            },
            defaultValue: postmenopousol,
            setter: setPostmenopousol,
          */
        },
        {
          name: "Normal",
          detail: "",
          audio: "",
          code: "",
        },
      ],
    }, // 6
    {
      name: "SKIN / Musculoskeletal",
      chips: [
        {
          name: "Rash and other nonspecific skin eruption",
          code: "R21",
          detail: "",
          audio: "",
        },
        {
          name: "Back Pain",
          subChips: [
            {
              name: "Low back pain",
              code: "M54.5",
              selected: false,
            },
            {
              name: "Pain in thoracic spine",
              code: "M54.6",
              selected: false,
            },
            {
              name: "Occipital neuralgia",
              code: "M54.81",
              selected: false,
            },
            {
              name: "Other dorsalgia",
              code: "M54.89",
              selected: false,
            },
            {
              name: "Dorsalgia, unspecified",
              code: "M54.9",
              selected: false,
            },
          ],
          detail: "",
          audio: "",
          /*
            dropdownSelectedValue: {
              name: "Low back pain",
              code: "M54.5",
            },
            defaultValue: backPain,
            setter: setBackPain,
          */
        },
        {
          name: "Leg Pain",
          subChips: [
            {
              name: "Pain in right leg",
              code: "M79.604",
              selected: false,
            },
            {
              name: "Pain in left leg",
              code: "M79.604",
              selected: false,
            },
            {
              name: "Pain in leg, unspecified",
              code: "M79.606",
              selected: false,
            },
          ],
          detail: "",
          audio: "",
          /*
            dropdownSelectedValue: {
              name: "Pain in right leg",
              code: "M79.604",
            },
            defaultValue: legPain,
            setter: setLegPain,
          */
        },
        {
          name: "Foot Swelling",
          subChips: [
            {
              name: "Localized swelling, mass and lump, unspecified lower limb",
              code: "R22.40",
              selected: false,
            },
            {
              name: "Localized swelling, mass and lump, right lower limb",
              code: "R22.41",
              selected: false,
            },
            {
              name: "Localized swelling, mass and lump, left lower limb",
              code: "R22.42",
              selected: false,
            },
            {
              name: "Localized swelling, mass and lump, lower limb, bilateral",
              code: "R22.43",
              selected: false,
            },
          ],
          detail: "",
          audio: "",
          /*
            dropdownSelectedValue: {
              name: "Localized swelling, mass and lump, unspecified lower limb",
              code: "R22.40",
            },
            defaultValue: footSwelling,
            setter: setFootSwelling,
          */
        },
        {
          name: "Normal",
          detail: "",
          audio: "",
          code: "",
        },
        {
          name: "Add Skin Image",
          image: [],
        },
      ],
      /*
      Texts: [
        {
          name: "Skin / Musculoskeletol Notes...",
          value: "",
        },
      ],
      */
    },
    {
      name: "NEURO / EYES",
      chips: [
        {
          name: "Headache",
          code: "R51",
          detail: "",
          audio: "",
        },
        {
          name: "Syncope and collapse",
          code: "R55",
          detail: "",
          audio: "",
        },
        {
          name: "Lost Feeling / Power",
          subChips: [
            {
              name: "Unspecified disturbances of skin sensation",
              code: "R20.9",
              selected: false,
            },
            {
              name: "Weakness",
              code: "R53.1",
              selected: false,
            },
          ],
          detail: "",
          audio: "",
          /*
            dropdownSelectedValue: {
              name: "Unspecified disturbances of skin sensation",
              code: "R20.9",
            },
            defaultValue: lostFeeling,
            setter: setLostFeeling,
          */
        },
        {
          name: "Difficulty in walking, not elsewhere classified",
          code: "R26.2",
          detail: "",
          audio: "",
        },
        {
          name: "Unspecified speech disturbances",
          code: "R47.9",
          detail: "",
          audio: "",
        },
        {
          name: "Diplopia",
          code: "H53.2",
          detail: "",
          audio: "",
        },
        {
          name: "Confusional arousals",
          code: "G47.51",
          detail: "",
          audio: "",
        },
        {
          name: "Normal",
          detail: "",
          audio: "",
          code: "",
        },
      ],
      /*
        Texts: [
          {
            name: "Neuro / Eyes Notes...",
            value: "",
          },
        ],
      */
    },
    /*
    {
      name: "ROS",
      radios: [
        {
          name: "ROS Otherwise Negative",
          code: "ROS Otherwise Negative",
        },
        {
          name: "ROS Otherwise Positive",
          code: "ROS Otherwise Positive",
        },
      ],
      Texts: [
        {
          name: "ROS Notes...",
          value: "",
        },
      ],
    },*/ // 0
  ];

  let isSkipFemale = true;
  if (patientDetails && patientDetails.patientId !=null && patientDetails.patientId.gender === "Female") {
    isSkipFemale = false;
  }

  let tfROS = [];
  Object.entries(ROS).map(([name, val]) => {
    if (patientDetails){
      if ( "FEMALE GENITAL" === val.name ) {
        if (patientDetails.patientId !=null && patientDetails.patientId.gender === "Female") {
          tfROS.push(val);
        }
      }else{
        tfROS.push(val);
      }
    }
  });
  const tmpROS = tfROS;

  const ActionPlan = [
    {
      name: "Notes/Differential Diagnosis",
      Texts: [
        {
          name: "Notes...",
          value: "",
        },
      ],
      chips: [
        {
          name: "Template Complete",
        },
        {
          name: "Dictated addendum",
        },
        {
          name: "Progress Sheet",
        },
      ],
    },
  ];

  const CourseOfVisit = [
    {
      name: "Emergency Department Course",
      Texts: [
        {
          name: "Text...",
          value: "",
        },
      ],
      chips: [
        {
          name: "Unchanged",
        },
        {
          name: "Improved",
        },
        /*{
          name: "Consultation",
        },*/
        {
          name: "Re Examined",
          detail: "",
        },
        {
          name: "Critical Care",
          detail: "",
        },
        {
          name:
            "Counseled Patient / Family regarding lab results diagnosis need for follow up",
        },
        {
          name: "Prior Records Ordered / Reviewed / See ED Course",
        },
        {
          name: "Admit Orders Written",
        },
      ],
    },
  ];

  // let chiefComplaintList = [];

  

  const PatientDetails = [
    {
      sortNumber: 0,
      Texts: [
        {
          name: "Time Seen",
          value: "",
        },
        {
          name: "Room",
          value: "",
        },
      ],
    },
    {
      name: "Historian",
      sortNumber: 1,
      chips: [
        {
          name: "Patient",
        },
        {
          name: "Paramedics",
        },
        {
          name: "Family",
        },
        {
          name: "Others",
          detail: "",
        },
      ],
    },
    {
      sortNumber: 2,
      Texts: [
        {
          name: "Age",
          value: "",
        },
        {
          name: "Gender",
          value: "",
        },
        {
          name: "Nationality",
          value: "",
        },
      ],
    },
    {
      name: "History Limited By",
      sortNumber: 3,
      chips: [
        {
          name: "Altered level of consciousness",
          detail: "",
        },
        {
          name: "Lethargy",
          detail: "",
        },
        {
          name: "Agitation",
          detail: "",
        },
        {
          name: "Language barrier",
          detail: "",
        },
        {
          name: "Cultural limitation",
          detail: "",
        },
        {
          name: "None/Not Limited",
          detail: "",
        },
        {
          name: "Others",
          detail: "",
        },
      ],
    },
    {
      sortNumber: 4,
      Texts: [
        {
          name: "Chief Complaint",
          value: "",
        }
      ],
    },

    // {
    //   name: "Chief Complaint",
    //   chips: chiefComplaintList,
      // chips: [
      //   {
      //     name: "Sore Throat",
      //     code: "R07.0",
      //   },
      //   {
      //     name: "Cough",
      //     code: "R05",
      //   },
      //   {
      //     name: "Fever",
      //     code: "R50.9"
      //   },
      //   {
      //     name: "Abdominal Pain",
      //     code: "ABD",
      //   },
      //   {
      //     name: "Chest Pain",
      //     code: "R000"
      //   },
      //   {
      //     name: "Vomiting",
      //     code: "R11.10"
      //   },
      //   {
      //     name: "Headache",
      //     code: "R51"
      //   },
      //   {
      //     name: "Shortness of breath",
      //     code: "R06.02"
      //   },
      //   {
      //     name: "Diarrhea",
      //     code: "R19.7",
      //   },
      //   {
      //     name: "Others",
      //     code: "OTHER",
      //     detail: "",
      //   },
      // ]
    // },
    /*{
      DropDowns: [
        {
          name: "Chief Complaint",
          value: "",
        },
      ]
    },*/
    {
      sortNumber: 5,
      DropDowns: [
        {
          name: "CareStream",
          value: "",
        },
      ],
    },
    {
      name: "Duration / Started",
      sortNumber: 6,
      DropDowns: [
        {
          name: "Minutes",
          value: "",
          options: [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59]
        },
        {
          name: "Hours",
          value: "",
          options: [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23]
        },
        {
          name: "Days",
          value: "",
          options: [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30]
        },
        {
          name: "Month",
          value: "",
          options: [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59]
        },
        // {
        //   name: "Week",
        //   value: "",
        // }
      ],
    },
    {
      name: "Current Condition / Status", // / Timing
      sortNumber: 7,
      radios: [
        {
          name: "Still Present",
          code: "Still Present",
        },
        {
          name: "Better",
          code: "Better",
        },
        {
          name: "Gone Now",
          code: "Gone Now",
        },
        {
          name: "Worse",
          code: "Worse",
        },
      ],
      voiceNotes: {
        audio: '',
        notes: ''
      },
    },
    {
      name: "Pain Scale",
      sortNumber: 8,
      value: "",
    },
    {
      name: "Modifying Factors",
      sortNumber: 9,
      chips: [
        {
          name: "None",
          // detail: "",
        },
      ],
      voiceNotes: {
        audio: '',
        notes: ''
      },
    },
    {
      name: "Similar Symptoms Previously",
      sortNumber: 10,
      radios: [
        {
          name: "Yes",
          code: "Yes",
        },
        {
          name: "No",
          code: "No",
        },
      ],
      voiceNotes: {
        audio: '',
        notes: ''
      },
    },
    {
      name: "Recently Seen / Treated By Doctor",
      sortNumber: 11,
      radios: [
        {
          name: "Yes",
          code: "Yes",
        },
        {
          name: "No",
          code: "No",
        },
      ],
      voiceNotes: {
        audio: '',
        notes: ''
      },
    },
  ];

  const PhysicalExam = [
    {
      name: "Physical Exam",
      chips: [
        {
          name: "Agree W / Vital Sign",
        },
        {
          name: "Exam Limited By",
          detail: "",
          subChips: [
            {
              name: "Altered level of consciousness",
              selected: false,
            },
            {
              name: "Lethargy",
              selected: false,
            },
            {
              name: "Language barrier",
              selected: false,
            },
            {
              name: "Cultural limitation",
              selected: false,
            },
            {
              name: "Others",
              selected: false,
              detail: "",
            },
          ],
        },
      ],
      scale: {
        name: "Pain Scale",
        value: "",
      },
      voiceNotes: {
        audio: '',
        notes: ''
      },
    },
    {
      name: "General Appearance",
      chips: [
        {
          name: "Distress",
        },
        {
          name: "No Acute",
        },
        {
          name: "Moderate",
        },
        {
          name: "Severe",
        },
        {
          name: "Alert",
        },
        {
          name: "Lethargic",
        },
        {
          name: "Anxious",
        },
      ],
      /*Texts: [
        {
          name: "Notes...",
          value: "",
        },
      ],*/
      voiceNotes: {
        audio: '',
        notes: ''
      },
    },
    {
      name: "HEENT",
      chips: [
        {
          name: "ENT Inspection nml",
          detail: "",
        },
        {
          name: "No Dental Injury",
          detail: "",
        },
        {
          name: "Scelral Icterus",
          detail: "",
        },
        {
          name: "Postnasal drip",//"Purulent Nasal Drainage",
          code: "R09.82",
          detail: "",
        },
        {
          name: "Pharyngeal Erythema / Exudate",
          detail: "",
        },
      ],
    },
    {
      name: "Neck",
      chips: [
        {
          name: "Nml Inspection",
          detail: "",
        },
        {
          name: "Thyroid Nml",
          detail: "",
        },
        {
          name: "Thyromegaly",
          code: "E01.0",
          detail: "",
        },
        {
          name: "Localized enlarged lymph nodes",//"Lymphadenopathy (R/L)",
          code: "R59.0",
          detail: "",
        },
        {
          name: "Occipital",
          detail: "",
        },
      ],
    },
    {
      name: "Respiratory",
      chips: [
        {
          name: "No Respiratory Disease",
          detail: "",
        },
        {
          name: "Breath Sounds Nml",
          detail: "",
        },
        {
          name: "Chest Non Tender",
          detail: "",
        },
        {
          name: "See Diagram",
          detail: "",
          imageMarkers: [],
        },
        {
          name: "Wheezing",
          code: "R06.2",
          detail: "",
        },
        {
          name: "Rales",
          detail: "",
        },
        {
          name: "Rhonchi",
          detail: "",
        },
      ],
    },
    {
      name: "CVS",
      chips: [
        {
          name: "Regular Rate Rhythm",
          detail: "",
        },
        {
          name: "No Murmur",
          detail: "",
        },
        {
          name: "No Gallop",
          detail: "",
        },
        {
          name: "Irregular Rhythm",
          detail: "",
        },
        {
          name: "Extra systoles (Occasional / Frequent)",
          detail: "",
        },
        {
          name: "Tachycardia / Bradycardia",
          detail: "",
          subChips: [
            {
              name: "Tachycardia, unspecified",
              code: "R00.0",
              selected: false,
            },
            {
              name: "Bradycardia, unspecified",
              code: "R00.1",
              selected: false,
            },
          ],
        },
        {
          name: "PMI Displaced Laterally",
          detail: "",
        },
        {
          name: "JVD Present",
          detail: "",
        },
        {
          name: "Murmur",
          detail: "",
          subChips: [
            {
              name: "Cardiac murmur, unspecified",
              code: "R01.1",
              selected: false,
            },
          ],
        },
        
        {
          name: "Gallop (S3/S4)",
          detail: "",
        },
        {
          name: "Friction Rub",
          detail: "",
        },
        {
          name: "Decreased Pulse(s)",
          detail: "",
          right: [
            {
              name: "Coroted",
              value: "",
            },
            {
              name: "Fem",
              value: "",
            },
            {
              name: "Dors Ped",
              value: "",
            },
          ],
          left: [
            {
              name: "Coroted",
              value: "",
            },
            {
              name: "Fem",
              value: "",
            },
            {
              name: "Dors Ped",
              value: "",
            },
          ],
          imageMarkers: [],
        },
      ],
    },
    {
      name: "Abdomen",
      chips: [
        {
          name: "Non Tender",
          detail: "",
        },
        {
          name: "No Organomegaly",
          detail: "",
        },
        {
          name: "Nml Bowel Sounds",
          detail: "",
        },
        {
          name: "Tenderness",
          detail: "",
          subChips: [
            {
              name: "Right upper quadrant abdominal tenderness",
              code: "R10.811",
              selected: false,
            },
            {
              name: "Left upper quadrant abdominal tenderness",
              code: "R10.812",
              selected: false,
            },
            {
              name: "Right lower quadrant abdominal tenderness",
              code: "R10.813",
              selected: false,
            },
            {
              name: "Left lower quadrant abdominal tenderness",
              code: "R10.814",
              selected: false,
            },
            {
              name: "Periumbilic abdominal tenderness",
              code: "R10.815",
              selected: false,
            },
            {
              name: "Epigastric abdominal tenderness",
              code: "R10.816",
              selected: false,
            },
            {
              name: "Generalized abdominal tenderness",
              code: "R10.817",
              selected: false,
            },
            {
              name: "Abdominal tenderness, unspecified site",
              code: "R10.819",
              selected: false,
            },
          ],
          /*subChipsItems: [
            {
              name: "Right upper quadrant abdominal tenderness",
              code: "R10.811",
            },
            {
              name: "Left upper quadrant abdominal tenderness",
              code: "R10.812",
            },
            {
              name: "Right lower quadrant abdominal tenderness",
              code: "R10.813",
            },
            {
              name: "Left lower quadrant abdominal tenderness",
              code: "R10.814",
            },
            {
              name: "Periumbilic abdominal tenderness",
              code: "R10.815",
            },
            {
              name: "Epigastric abdominal tenderness",
              code: "R10.816",
            },
            {
              name: "Generalized abdominal tenderness",
              code: "R10.817",
            },
            {
              name: "Abdominal tenderness, unspecified site",
              code: "R10.819",
            },
          ],
          defaultValue: TendernessConfig,
          setter: setTendernessConfig,*/
          /*dropdownSelectedValue: {
            name: "Right upper quadrant abdominal tenderness",
            code: "R10.811",
          },*/
        },
        {
          name: "Guarding",
          detail: "",
        },
        {
          name: "Rebound",
          subChips: [
            {
              name: "Right upper quadrant rebound abdominal tenderness",
              code: "R10.821",
              selected: false,
            },
            {
              name: "Left upper quadrant rebound abdominal tenderness",
              code: "R10.822",
              selected: false,
            },
            {
              name: "Right lower quadrant rebound abdominal tenderness",
              code: "R10.823",
              selected: false,
            },
            {
              name: "Left lower quadrant rebound abdominal tenderness",
              code: "R10.824",
              selected: false,
            },
            {
              name: "Periumbilic rebound abdominal tenderness",
              code: "R10.825",
              selected: false,
            },
            {
              name: "Epigastric rebound abdominal tenderness",
              code: "R10.826",
              selected: false,
            },
            {
              name: "Generalized rebound abdominal tenderness",
              code: "R10.827",
              selected: false,
            },
            {
              name: "Rebound abdominal tenderness, unspecified site",
              code: "R10.829",
              selected: false,
            },
          ],
          /*dropdownSelectedValue: {
            name: "Right upper quadrant rebound abdominal tenderness",
            code: "R10.821",
          },
          defaultValue: ReboundConfig,
          setter: setReboundConfig,*/
        },
        {
          name: "Abnormal Bowel Sounds",
          detail: '',
          subChips: [
            {
              name: "Absent bowel sounds",
              code: "R19.11",
              selected: false,
            },
            {
              name: "Hyperactive bowel sounds",
              code: "R19.12",
              selected: false,
            },
            {
              name: "Other abnormal bowel sounds",
              code: "R19.15",
              selected: false,
            },
            {
              name: "Visible peristalsis",
              code: "R19.2",
              selected: false,
            },
          ],
          /*
          defaultValue: AbnormalBowelSoundsConfig,
          setter: setAbnormalBowelSoundsConfig,
          detail: "",
          subChips: [
            {
              name: "Absent",
              dropdownOptions: [
                {
                  name: "Absent bowel sounds",
                  code: "R19.11",
                },
                {
                  name: "Hyperactive bowel sounds",
                  code: "R19.12",
                },
                {
                  name: "Other abnormal bowel sounds",
                  code: "R19.15",
                },
                {
                  name: "Visible peristalsis",
                  code: "R19.2",
                },
              ],
              dropdownSelectedValue: {
                name: "Absent bowel sounds",
                code: "R19.11",
              },
            },
            {
              name: "Increased",
            },
            {
              name: "Decreased",
            },
          ],
          */
        },
        {
          name: "Hepatomegaly / Splenomegaly / Mass",
          detail: '',
          subChips: [
            {
              name: "Hepatomegaly, not elsewhere classified",
              code: "R16.0",
              selected: false,
            },
            {
              name: "Splenomegaly, not elsewhere classified",
              code: "R16.1",
              selected: false,
            },
            {
              name: "Hepatomegaly with splenomegaly, not elsewhere classified",
              code: "R16.2",
              selected: false,
            },
          ],
          /*
          dropdownSelectedValue: {
            name: "Hepatomegaly, not elsewhere classified",
            code: "R16.0",
          },
          defaultValue: HepatomegalySplenomegalyMassConfig,
          setter: setHepatomegalySplenomegalyMassConfig,
          */
        },
      ],
    },
    {
      name: "Rectal",
      chips: [
        {
          name: "Non Tender",
          detail: '',
        },
        {
          name: "Heme Neg Stool",
          detail: '',
        },
        {
          name: "Black / Bloody / Heme Post Stool",
          detail: '',
          subChips: [
            {
              name: "Melena",
              code: "K92.1",
              selected: false,
            },
            {
              name: "Hemorrhage of anus and rectum",
              code: "K62.5",
              selected: false,
            },
          ],
          /*
          dropdownSelectedValue: {
            name: "Melena",
            code: "K92.1",
          },
          defaultValue: BlackBloodyHemePostStoolConfig,
          setter: setBlackBloodyHemePostStoolConfig,
          */
        },
        {
          name: "Tenderness / Mass / Nodule",
          detail: "",
        },
      ],
    },
    {
      name: "Back",
      chips: [
        {
          name: "Nml",
          detail: '',
        },
        {
          name: "CVA Tenderness (R/L)",
          detail: "",
        },
      ],
    },
    {
      name: "Skin",
      chips: [
        {
          name: "Color Nml, No Rash",
          detail: '',
        },
        {
          name: "Warm, Dry",
          detail: '',
        },
        {
          name: "Cyanosis / Diaphoresis / Pallor",
          subChips: [
            {
              name: "Cyanosis",
              code: "R23.0",
              selected: false,
            },
            {
              name: "Generalized hyperhidrosis",
              code: "R61",
              selected: false,
            },
            {
              name: "Pallor",
              code: "R23.1",
              selected: false,
            },
          ],
          /*
          dropdownSelectedValue: {
            name: "Cyanosis",
            code: "K92.1",
          },
          defaultValue: CyanosisDiaphoresisPallorConfig,
          setter: setCyanosisDiaphoresisPallorConfig,
          */
        },
        {
          name: "Rash and other nonspecific skin eruption",
          code: "R21",
          detail: "",
          /*subChipsItems: [
            {
              name: "Rash and other nonspecific skin eruption",
              code: "R21",
            }
          ],
          defaultValue: SkinRashConfig,
          setter: setSkinRashConfig,*/
        },
        {
          name: "Add Skin Image",
          image: [],
          detail: '',
        },
      ],
    },
    {
      name: "Extremities",
      chips: [
        {
          name: "Non Tender",
          detail: '',
        },
        {
          name: "Full ROM",
          detail: '',
        },
        {
          name: "Localized edema",
          code: "R60.0",
          detail: "",
        },
        {
          name: "Calf Tenderness",
          detail: "",
        },
      ],
    },
    {
      name: "Neuro / Psych",
      chips: [
        {
          name: "Oriented x3",
          detail: '',
        },
        {
          name: "No Motor / Sensory Deficit",
          detail: '',
        },
        {
          name: "Mood / Affect Nml",
          detail: '',
        },
        {
          name: "CN's Nml (2-12)",
          detail: '',
        },
        {
          name: "Disorientation, unspecified",
          code: "R41.0",
          detail: "",
        },
        {
          // name: "Depressed Affect",
          name: "Unspecified mood [affective] disorder",
          code: "F39",
          detail: "",
        },
        {
          name: "Facial Droop / EOM Palsy / Anisocoria",
          detail: "",
          subChips: [
            {
              name: "Facial weakness",
              code: "R29.810",
              selected: false,
            },
            {
              name: "Third [oculomotor] nerve palsy, right eye",
              code: "H49.01",
              selected: false,
            },
            {
              name: "Third [oculomotor] nerve palsy, left eye",
              code: "H49.02",
              selected: false,
            },
            {
              name: "Third [oculomotor] nerve palsy, bilateral",
              code: "H49.03",
              selected: false,
            },
            {
              name: "Anisocoria",
              code: "H57.02",
              selected: false,
            },
          ],

          /*
          subChipsItems: [
            {
              name: "Facial weakness",
              code: "R29.810",
            },
            {
              name: "Third [oculomotor] nerve palsy, right eye",
              code: "H49.01",
            },
            {
              name: "Third [oculomotor] nerve palsy, left eye",
              code: "H49.02",
            },
            {
              name: "Third [oculomotor] nerve palsy, bilateral",
              code: "H49.03",
            },
            {
              name: "Anisocoria",
              code: "H57.02",
            },
          ],
          defaultValue: FacialDroop,
          setter: setFacialDroop,
          */
          /*dropdownOptions: [
            {
              name:
                "Facial weaknessH49.01	Third [oculomotor] nerve palsy, right eye",
              code: "R29.810",
            },
            {
              name: "Third [oculomotor] nerve palsy, left eye",
              code: "H49.02",
            },
            {
              name: "Third [oculomotor] nerve palsy, bilateralH57.02	Anisocoria",
              code: "H49.03",
            },
          ],
          dropdownSelectedValue: {
            name:
              "Facial weaknessH49.01	Third [oculomotor] nerve palsy, right eye",
            code: "R29.810",
          },*/
        },
        {
          name: "Weakness / Sensory Loss",
          detail: "",
          subChips: [
            {
              name: "Weakness",
              code: "R53.1",
              selected: false,
            },
            {
              name: "Other disturbances of skin sensation",
              code: "R20.8",
              selected: false,
            },
            {
              name: "Dual Sensory Loss",
              code: "Z73.82",
              selected: false,
            }
          ],
        },
     ],
    },
  ];

  const useStylesForInput = makeStyles((theme) => ({
    input: {
      backgroundColor: "#f5f5f5",
      borderRadius: 6,
      "&:after": {
        borderBottomColor: "black",
      },
      "&:hover": {
        backgroundColor: "#f5f5f5",
      },
      "&:disabled": {
        color: "gray",
      },
      "&:focus": {
        backgroundColor: "#f5f5f5",
        boxShadow: "none",
      },
    },
    root: {
      "& .MuiTextField-root": {
        backgroundColor: "white",
      },
      "& .Mui-focused": {
        backgroundColor: "white",
        color: "black",
      },
      "& .Mui-disabled": {
        backgroundColor: "white",
        color: "gray",
      },
    },
  }));

  const classes = useStylesForInput();

  useEffect(() => {
    let patientDetails = props.history.location.state.selectedItem;
    setPatientDetails(patientDetails);
    setedrId(patientDetails._id);
    if (
      props.history.location.state.comingFrom &&
      props.history.location.state.comingFrom === "edit"
    ) {
      setOpenReasonModal(true);
      /*
      console.log('propsDataFormData::click::form:', props.history.location.state.currentFields);
      console.log('propsDataFormData::click::form2:', props.history.location.state);
      Object.entries(props.history.location.state.currentFields.details).map(([key, val]) => {
          dispatch({
              field: "investigation",
              value: [
                ...investigation,
                val
              ],
              // value: props.history.location.state.currentFields.details,
            });
          console.log('propsDataFormData::click::form:', val);
        });
      console.log('propsDataFormData::click::form:investigation', investigation);*/
    }

    // Getting List of All Chief Complaint ICD Codes
    // console.log("Chief Complaints", getIcdbyFeaturedGroup);
    axios
      .get(getIcdbyFeaturedGroup+"ChiefComplaint")
      .then((response) => {
        // console.log("chiefComplaintListX", response.data.data.items);
        let chiefComplaintListX = [];
        Object.entries(response.data.data.items).map(([key, val]) => {
          chiefComplaintListX.push({
            name: val.short_name,
            code: val.code,
          });
          // console.log(key);
          // console.log(val);
        });
        chiefComplaintListX.push({
          name: "Others",
          code: "OTHER",
          // detail: "",
          autocompleteAjax: "icd10Codes",
          // defaultValue: Icd10CodesList,
          setter: setIcd10Codes,
          // search: setNasalDrainageCongestion,
          // searchWord: searchedICD10Keyword, 
          // setSearchWord: setSearchedICD10Keyword
        });
        // console.log("chiefComplaintListX", chiefComplaintListX);
        setChiefComplaintList(chiefComplaintListX);
      })
      .catch((error) => {
        console.log("Error", error);
      });

  }, []);

  if (openNotification) {
    setTimeout(() => {
      setOpenNotification(false);
      setErrorMsg("");
      setsuccessMsg("");
    }, 2000);
  }

  const handleChange = (e) => {
    dispatch({ field: e.target.name, value: e.target.value });
  };

  const uniqueCodeField = (name) => {
    name = name.toLowerCase();
    name = name.replace(/[^a-z0-9]+/g, '-');
    name = name.replace(/^-+|-+$/g, '');
    return name;
  }

  const onSave = (form, ecg, xray, skinreport, medication) => {
    let formName = props.history.location.state.comingFor;
    if (formName === "Action & Plan" || formName === "Course of Visit"){
      // Resetting Attachment Data
      console.log(form);
    }else{
      const filteredForm = form.map((item) =>
        item.name
          ? {
              ...item,
              chips:
                item.chips &&
                item.chips.length &&
                item.chips.map((c) =>
                  c.name && c.dropdownSelectedValue
                    ? { dropdownSelectedValue: c.dropdownSelectedValue }
                    : { ...c }
                ),
            }
          : item
      );
    }

    // Resetting Image Addon
    for (let ixp = 0; ixp < form.length; ixp++) {
      if (form[ixp].chips){
        console.log('hasChips', form[ixp].chips);
        for (let j = 0; j < form[ixp].chips.length; j++) {
          if (
            form[ixp].chips[j] &&
            form[ixp].chips[j].image
          ) {
            let fxjdata = [];
            let xjdata = form[ixp].chips[j].image;
            for (let xj = 0; xj < xjdata; xj++){
              if (!xjdata[xj].includes('data:')){
                fxjdata.push(xjdata[xj]);
              }
            }
            form[ixp].chips[j].image = fxjdata;
          }
        }
      }
    }

    // Submission Params
    const params = {
      edrId: props.history.location.state.selectedItem._id,
      staffId: currentUser._id,
      details: form,
      reason,
    };

    let imageForms = // true;
       formName === "Investigations" || formName === "Physical Exam" || formName === "ROS" || formName === "Action & Plan" || formName === "Course of Visit" || formName === "Past Medical History" || formName === "Patient Details";
    let formData = new FormData();
    if (imageForms) {
      if (ecg && ecg.length > 0) {
        for (var x = 0; x < ecg.length; x++) {
          formData.append("ECG", ecg[x], ecg[x].name);
        }
      }
      if (xray && xray.length > 0) {
        for (var x = 0; x < xray.length; x++) {
          formData.append("XRAY", xray[x], xray[x].name);
        }
      }
      if (skinreport && skinreport.length > 0) {
        for (var x = 0; x < skinreport.length; x++) {
          formData.append("SkinReport", skinreport[x], skinreport[x].name);
        }
      }
      if (medication && medication.length > 0) {
        for (var x = 0; x < medication.length; x++) {
          formData.append("Medication", medication[x], medication[x].name);
        }
      }

      if (formName === "Action & Plan"){
        console.log('Submit', form);
        if (form.differentialDiagnosisVoiceNotes != ""){
          formData.append("vnDifferentialDiagnosis", form.differentialDiagnosisVoiceNotes, 'ap-differential-diagnosis-vn');
        }
        if (form.differentialDiagnosisPlanVoiceNotes != ""){
          formData.append("vnDifferentialDiagnosisPlan", form.differentialDiagnosisPlanVoiceNotes, 'ap-differential-diagnosis-plan-vn');
        }
        if (form.investigationsVoiceNotes != ""){
          formData.append("vnInvestigation", form.investigationsVoiceNotes, 'ap-investigation-vn');
        }
        if (form.investigationsPlanVoiceNotes != ""){
          formData.append("vnInvestigationPlan", form.investigationsPlanVoiceNotes, 'ap-investigation-plan-vn');
        }
        if (form.ivFluidsVoiceNotes != ""){
          formData.append("vnIVFluids", form.ivFluidsVoiceNotes, 'ap-ivfluid-vn');
        }
        if (form.ivFluidsVoiceNotes != ""){
          formData.append("vnIVFluidsPlan", form.ivFluidsVoiceNotes, 'ap-ivfluid-plan-vn');
        }
        if (form.medicationsVoiceNotes != ""){
          formData.append("vnMedication", form.medicationsVoiceNotes, 'ap-medications-vn');
        }
        if (form.medicationsPlanVoiceNotes != ""){
          formData.append("vnMedicationPlan", form.medicationsPlanVoiceNotes, 'ap-medications-plan-vn');
        }
        if (form.painManagementVoiceNotes != ""){
          formData.append("vnPain", form.painManagementVoiceNotes, 'ap-pain-vn');
        }
        if (form.painManagementPlanVoiceNotes != ""){
          formData.append("vnPainPlan", form.painManagementPlanVoiceNotes, 'ap-pain-plan-vn');
        }
        if (form.o2VoiceNotes != ""){
          formData.append("vnO2", form.o2VoiceNotes, 'ap-o2-vn');
        }
        if (form.o2PlanVoiceNotes != ""){
          formData.append("vnO2Plan", form.o2PlanVoiceNotes, 'ap-o2-planvn');
        }
        if (form.cpVoiceNotes != ""){
          formData.append("cpVoiceNotes", form.cpVoiceNotes, 'ap-cp-vn');
        }


        params.details.differentialDiagnosisVoiceNotes = '';
        params.details.differentialDiagnosisPlanVoiceNotes = '';
        params.details.investigationsVoiceNotes = '';
        params.details.investigationsPlanVoiceNotes = '';
        params.details.ivFluidsVoiceNotes = '';
        params.details.ivFluidsVoiceNotes = '';
        params.details.medicationsVoiceNotes = '';
        params.details.medicationsPlanVoiceNotes = '';
        params.details.painManagementVoiceNotes = '';
        params.details.painManagementPlanVoiceNotes = '';
        params.details.o2VoiceNotes = '';
        params.details.o2PlanVoiceNotes = '';
        params.details.cpVoiceNotes = '';
      }

      if (formName === "Course of Visit"){
        // if (form.cpVoiceNotes != ""){
        //   formData.append("cpVoiceNotes", form.cpVoiceNotes, 'ap-cp-vn');
        // }
        // if (form.dischargeDispositionVoiceNotes != ""){
        //   formData.append("dischargeDispositionVoiceNotes", form.dischargeDispositionVoiceNotes, 'ap-discharge-disposition-vn');
        // }
        // if (form.consultationServiceVoiceNotes != ""){
        //   formData.append("consultationServiceVoiceNotes", form.consultationServiceVoiceNotes, 'ap-consultation-service-vn');
        // }
        // if (form.consultationFinalDiagnosisVoiceNotes != ""){
        //   formData.append("consultationFinalDiagnosisVoiceNotes", form.consultationFinalDiagnosisVoiceNotes, 'ap-consultation-final-diagnosis-vn');
        // }
        // if (form.medicationsVoiceNotes != ""){
        //   formData.append("medicationsVoiceNotes", form.medicationsVoiceNotes, 'ap-medications-vn');
        // }
        // if (form.consultationVoiceNotes != ""){
        //   formData.append("consultationVoiceNotes", form.consultationVoiceNotes, 'ap-consultation-vn');
        // }
        if (form.patientEducationVoiceNotes != ""){
          formData.append("patientEducationVoiceNotes", form.patientEducationVoiceNotes, 'ap-patient-education-vn');
        }
      }

      /*Object.entries(params).map(([key, val]) => {
        console.log(key, val);
        formData.append(key, val);
      });*/
      /*for ( var key in params ) {
        formData.append(key, params[key]);
      }*/

      for (let key in params) {
        Array.isArray(params[key])
            ? params[key].forEach(value => formData.append(key + '[]', value))
            : formData.append(key, params[key]) ;
      }
      console.log("stringify", JSON.stringify(params));



      console.log("stringify", JSON.stringify(params));
      formData.append("data", JSON.stringify(params));
    }

    for (let ixp = 0; ixp < form.length; ixp++) {
      if (form[ixp].chips){
        console.log('hasChips', form[ixp].chips);
        for (let j = 0; j < form[ixp].chips.length; j++) {
          if (
            form[ixp].chips[j] &&
            form[ixp].chips[j].audio
          ) {
            if (form[ixp].chips[j].audio!=="" && form[ixp].chips[j].audio instanceof Blob){
              console.log('hasChips and AudioFile', form[ixp].chips[j].audio);
              formData.append("AudioFile", form[ixp].chips[j].audio, uniqueCodeField(form[ixp].name + "_" + form[ixp].chips[j].name));
            }
          }
        }
      }

      if (form[ixp].voiceNotes){
        console.log('voiceNotes', form[ixp].voiceNotes);
        if (form[ixp].voiceNotes.audio!=="" && form[ixp].voiceNotes.audio instanceof Blob){
          console.log('voiceNotes and AudioFile', form[ixp].voiceNotes.audio);
          formData.append("AudioFile", form[ixp].voiceNotes.audio, uniqueCodeField(form[ixp].name + "_voiceNotes"));
        }
      }
    }

    let url;
    if (formName === "Patient Details") {
      url = addPatientDetails;
    } else if (formName === "Past Medical History") {
      url = addPastHistory;
    } else if (formName === "Investigations") {
      url = addInvestigation;
    } else if (formName === "ROS") {
      url = addRos;
    } else if (formName === "Action & Plan") {
      url = addActionPlan;
    } else if (formName === "Course of Visit") {
      url = addCourseOfVisit;
    } else if (formName === "Physical Exam") {
      url = addPhysicalExam;
    }

    console.log("Submitting", url);
    console.log("Submitting", (imageForms));
    console.log("Submitting", (params));
    console.log("Submitting", (imageForms ? formData.entries() : params));

    axios
      .put(url, imageForms ? formData : params)
      .then((response) => {
        if (response.data.success) {
          props.history.push({
            pathname: "/dashboard/home/dcddashboard",
            state: patientDetails,
          });
        }
      })
      .catch((e) => {
        setOpenNotification(true);
        setErrorMsg("Cannot Submit Form :(");
      });
  };

  const onRowsSelected = (val) => {
    setRowSelected(val);
  };

  const BacktoDoctorScreen = () => {
    props.history.push({
      pathname: "/dashboard/home/notes",
      state: {
        edrId: edrId,
        cameFrom: 'investigations',
        // selectedRecord: rec,
      },
    });
  }



  const handlePainManagement = () => {
    let newURL = "/dashboard/home/"+edrId+"/painmanagement";
    props.history.push({
      pathname: newURL,
      state: {
        // 'lifelimbStatus': lifeLimbOn
      }
    });
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        position: "fixed",
        width: "100%",
        height: "100%",
        backgroundColor: rowSelected ? "#13D59F" : "#2973CF",
        overflowY: "scroll",
      }}
    >
      <Header history={props.history} />


      <PatientDetailsQuick
        edrId={edrId} />


      <div className="cPadding">
        <div className="subheader" style={{ marginBottom: "30px" }}>
          <div>
            {props.history.location.state.comingFor ===
            "Past Medical History" ? (
              <img src={PastMedicalBoxIcon} />
            ) : props.history.location.state.comingFor === "ROS" ? (
              <img src={RosBoxIcon} />
            ) : props.history.location.state.comingFor === "Investigations" ? (
              <img src={InvestigationsBoxIcon} />
            ) : props.history.location.state.comingFor === "Action & Plan" ? (
              <img src={ActionPlanBoxIcon} />
            ) : props.history.location.state.comingFor === "Course of Visit" ? (
              <img src={CourseVisitBoxIcon} />
            ) : props.history.location.state.comingFor === "Patient Details" ? (
              <img src={PatientDetailBoxIcon} />
            ) : props.history.location.state.comingFor === "Physical Exam" ? (
              <img src={PhysicalExamIcon} />
            ) : (
              undefined
            )}
            <h4>{props.history.location.state.comingFor}</h4>
          </div>

          {
            currentUser.staffType === "Doctor" &&
            currentUser.subType.includes("ED Doctor") ? 
            <div>
              <Button
                onClick={handlePainManagement}
                style={{
                  ...styles.stylesForDCDButton,
                  fontSize: matches ? 12 : 8,
                }}
                variant="contained"
                color="primary"
                Error={errorMsg}
              >
                <strong>Pain Mgmt.</strong>
              </Button>
              <Button
                  onClick={BacktoDoctorScreen}
                  style={{
                    ...styles.stylesForButton,
                    fontSize: matches ? 12 : 8,
                  }}
                  variant="contained"
                  color="primary"
                >ED Doctor</Button>
            </div>:null
          }
        </div>

        <div className="container-fluid" style={{ marginBottom: 80 }}>
          {props.history.location.state.comingFor === "Past Medical History" ? (
            <FormRows
              handleSave={onSave}
              form={pastMedicalHistory}
              rowsSelected={onRowsSelected}
              history={props.history}
            />
          ) : props.history.location.state.comingFor === "ROS" ? (
            <FormRows
              handleSave={onSave}
              form={ROS}
              rowsSelected={onRowsSelected}
              history={props.history}
              skipROSFemale={isSkipFemale}
            />
          ) : props.history.location.state.comingFor === "Investigations" ? (
            <FormRows
              handleSave={onSave}
              form={investigation}
              rowsSelected={onRowsSelected}
              history={props.history}
            />
          ) : props.history.location.state.comingFor === "Action & Plan" ? (
            <ActionPlanComponent
              handleSave={onSave}
              form={ActionPlan}
              rowsSelected={onRowsSelected}
              history={props.history}
              edrId={edrId}
            />
          ) : props.history.location.state.comingFor === "Course of Visit" ? (
            <CourseVisitComponent
              handleSave={onSave}
              form={CourseOfVisit}
              rowsSelected={onRowsSelected}
              history={props.history}
            />
          ) : props.history.location.state.comingFor === "Patient Details" ? (
            <PatientDetailsRows
              handleSave={onSave}
              form={PatientDetails}
              rowsSelected={onRowsSelected}
              history={props.history}
            />
          ) : props.history.location.state.comingFor === "Physical Exam" ? (
            <PhysicalExamFormRow
              handleSave={onSave}
              form={PhysicalExam}
              rowsSelected={onRowsSelected}
              history={props.history}
            />
          ) : (
            undefined
          )}
        </div>

        <Dialog
          onClose={() => (reason.length > 0 ? setOpenReasonModal(false) : "")}
          fullWidth={true}
          maxWidth={"lg"}
          bodyStyle={{ backgroundColor: "red" }}
          contentStyle={{ backgroundColor: "red" }}
          aria-labelledby="simple-dialog-title"
          open={OpenReasonModal}
        >
          <DialogTitle id="simple-dialog-title">
            <h4>Reason</h4>
            <h6>Please state the reason for new version</h6>
          </DialogTitle>
          <DialogContent>
            <div className={`container-fluid ${classes.root}`}>
              <div className="row">
                <div className="col-md-12 col-sm-12 col-12">
                  <TextField
                    required
                    label="Reason"
                    name={"reason"}
                    value={reason}
                    onChange={handleChange}
                    className="textInputStyle"
                    variant="filled"
                    InputProps={{
                      className: classes.input,
                      classes: { input: classes.input },
                      disableUnderline: true,
                    }}
                  />
                </div>
              </div>
            </div>
          </DialogContent>

          <DialogActions style={{ display: "flex", justifyContent: "center" }}>
            <Button
              disabled={reason.length > 0 ? false : true}
              style={styles.stylesForButton}
              onClick={() => setOpenReasonModal(false)}
              variant="contained"
            >
              Submit
            </Button>
          </DialogActions>
        </Dialog>

        <Notification
          msg={errorMsg}
          success={successMsg}
          open={openNotification}
        />
      </div>
    </div>
  );
}
