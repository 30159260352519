import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  '@global': {
      '.MuiAutocomplete-option[data-focus="true"]': {
          background: '#FFF'
      }
  },
  margin: {
    margin: theme.spacing(0),
  },
  autocomplete: {
    backgroundColor: "white",
    boxShadow: "none",
    borderRadius: 5,
  },
  input: {
    backgroundColor: "white",
    boxShadow: "none",
    borderRadius: 5,
    "&:after": {
      borderBottomColor: "black",
      boxShadow: "none",
    },
    "&:hover": {
      backgroundColor: "white",
      boxShadow: "none",
    },
    "&:focus": {
      backgroundColor: "white",
      boxShadow: "none",
      borderRadius: 5,
    },
  },
  multilineColor: {
    boxShadow: "none",
    backgroundColor: "white",
    borderRadius: 5,
    "&:hover": {
      backgroundColor: "white",
      boxShadow: "none",
    },
    "&:after": {
      borderBottomColor: "black",
      boxShadow: "none",
    },
    "&:focus": {
      boxShadow: "none",
    },
  },
  root: {
    "& .MuiTextField-root": {
      backgroundColor: "white",
    },
    "& .Mui-focused": {
      backgroundColor: "white",
      color: "black",
      boxShadow: "none",
    },
    "& .Mui-disabled": {
      backgroundColor: "white",
      color: "gray",
    },
    "&:focus": {
      backgroundColor: "white",
      boxShadow: "none",
    },
    "& .MuiFormLabel-root": {
      fontSize: "11px",
      paddingRight: "50px",
    },
  },

  small: {
    width: theme.spacing(5),
    height: theme.spacing(5),
    cursor: "pointer",
  },
  large: {
    width: theme.spacing(22),
    height: theme.spacing(22),
  },
}));
