import React, { useEffect, useState, useReducer } from "react";
import { withStyles } from '@material-ui/core/styles';
import Header from "../../components/Header/Header";
import classNames from "classnames";
import { 
  Button, 
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment,
  MenuItem,
  TextField,
  Select,
  useMediaQuery,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  FormLabel,
} from "@material-ui/core";
import cookie from "react-cookies";

import PatientDetailsQuick from "../../components/PatientDetails/PatientDetailsQuick";
import { displayInHumanDifference, replacePatientIdIfNull } from "../../utils/commonFunc";

import axios from "axios";
import {
  getSingleEdr,
  getRadShortServiceCategory,
  addRadRequestBulk,
  getFavoriteList
} from "../../public/endpoins";

import useStylesForInput from "../../assets/jss/material-dashboard-react/inputStyle";
import styles from "./styles";
import Loader from "react-loader-spinner";
import {
  Checkbox, 
  FormRadel, 
  FormControl, 
  FormGroup, 
  FormControlLabel,
  RadioGroup, 
  Radio
} from '@material-ui/core';
import {
  priorityArray,
  statusArray,
} from "./configForNotesScreen";
import Icon from '@material-ui/core/Icon';
import CloseIcon from '@material-ui/icons/Close';
import MicIcon from "@material-ui/icons/Mic";
import StopIcon from "@material-ui/icons/Stop";
import MicRecorder from "mic-recorder-to-mp3";
import AccountCircle from "@material-ui/icons/SearchOutlined";
import Notification from "../../components/Snackbar/Notification.js";

const Mp3Recorder = new MicRecorder({ bitRate: 128 });

export default function RadRequestsPage(props) {
  
  const matches = useMediaQuery("(min-width:600px)");
  const classes = useStylesForInput();

  const mystyle = {
    color: "white",
    backgroundColor: "DodgerBlue",
    padding: "10px",
    fontFamily: "Arial"
  };

  const GreenCheckbox = withStyles({
    root: {
      color: '#AAAAAA',
      '&$checked': {
        color: '#AAAAAA',
      },
    },
    checked: {
      color: '#2C6DDD',
      '&$checked': {
        color: '#2C6DDD',
      },
    },
  })((props) => <Checkbox color="default" {...props} />);

  const GreenRadio = withStyles({
    root: {
      color: '#AAAAAA',
      '&$checked': {
        color: '#AAAAAA',
      },
    },
    checked: {
      color: '#2C6DDD',
      '&$checked': {
        color: '#2C6DDD',
      },
    },
  })((props) => <Radio color="default" {...props} />);

  


  const initialState = {
    bulkTestSelected: false,
    resetedOn: "",
    defaultItems: {},
    seletedRadItems: [],
    labPriority: "",
    labTechnician: "",
    labComments: "",
    labTechniciansList: [],

    isBlocked: false,
    isRecording: false,
    recordingField: '',
    blobURL: [],

    formSubmit: false,


    newSearchTerm: '',
    checkOneComplete: true,
    assistantRequired: 'no',

    pregnantCheck: '',
    breastFeeding: '',
    highInfectionRisk: '',
    highInfectionRiskDesc: '',
    patientDiabetic: '',
    diabetesControlled: '',
    patientAllergies: '',
    patientAllergiesDesc: '',
    pacemaker: '',
    cerebralAneurysmClip: '',
    cochlearImplants: '',
    neuroStimulators: '',
    programmableHydrocephalusShunt: '',
    metallicForeignBodyInEye: '',
    otherMetallicImplants: '',
    otherMetallicImplantsDesc: '',
  };
  function reducer(state, { field, value }) {
    return {
      ...state,
      [field]: value,
    };
  }
  const [state, dispatch] = useReducer(reducer, initialState);
  const {
    bulkTestSelected,
    resetedOn,
    defaultItems,
    seletedRadItems,
    labPriority,
    labTechnician,
    labComments,
    labTechniciansList,

    isBlocked,
    isRecording,
    recordingField,
    blobURL,

    formSubmit,

    newSearchTerm,
    checkOneComplete,
    pregnantCheck,
    breastFeeding,
    highInfectionRisk,
    highInfectionRiskDesc,
    patientDiabetic,
    diabetesControlled,
    patientAllergies,
    patientAllergiesDesc,
    pacemaker,
    cerebralAneurysmClip,
    cochlearImplants,
    neuroStimulators,
    programmableHydrocephalusShunt,
    metallicForeignBodyInEye,
    otherMetallicImplants,
    otherMetallicImplantsDesc,
    assistantRequired,
  } = state;

  const handleCheckupChange = (event) => {
    dispatch({field: 'bulkTestSelected', value: event.target.value});
  };

  const uniqueCodeField = (name) => {
    name = name.toLowerCase();
    name = name.replace(/[^a-z0-9]+/g, '-');
    name = name.replace(/^-+|-+$/g, '');
    return name;
  }

  const itemsExistInDefaultSelection = (slug, item) => {

    if ( `id-`+slug in defaultItems ){
      let defaultSelected = defaultItems[ `id-`+slug ].items;
      if ( defaultSelected.includes( item ) )
      { // Already Exists Item and Remove it
        return true;
      }
    }

    return false;
  };

  const onChangeValue = (e) => {
    dispatch({
      field: e.target.name,
      value: e.target.value.replace(/[^\w\s]/gi, ""),
    });
  };

  const onChangePriorityValue = (e, index, item) => {
    console.log("ConsoleRadRequest :: onChangePriorityValue", e, index, item);

    let newSelectedRadItems = seletedRadItems;
    newSelectedRadItems.map((d, index) => {
      if (d.id === item){
        d.priority = e;
      }
    });
    console.log("ConsoleRadRequest :: onChangePriorityValue :: nv", newSelectedRadItems);
    dispatch({
      field: 'seletedRadItems',
      value: newSelectedRadItems
    });
  };

  const onChangeNotesValue = (e, index, item) => {
    let newSelectedRadItems = seletedRadItems;
    newSelectedRadItems.map((d, index) => {
      if (d.id === item){
        d.notes.text = e;
      }
    });
    dispatch({
      field: 'seletedRadItems',
      value: newSelectedRadItems
    });
  };

  const removeSelectedRadItem = (index, id) => {
    if (window.confirm('Confirm Removal?')){

      seletedRadItems.map((d, index) => {
        if (d.id === id){
          console.log('RadReuqest::remove::', id, 'included');
          let indexOfItem = seletedRadItems.indexOf( d );
          console.log('RadReuqest::remove::', 'indexOfItem', indexOfItem);
          seletedRadItems.splice( indexOfItem, 1 );
          dispatch({
            field: 'seletedRadItems',
            value: seletedRadItems
          });
        }
      });


      console.log('RadReuqest::remove::', index, id);
      if (seletedRadItems.includes(id)){ // Already Exists Item

        
      }
    }
  };

  const addToCheckListPage  = () => {
    if (
      seletedRadItems.length > 0
    ){
      dispatch({
        field: 'formSubmit',
        value: true,
      })
      // Check if notes are empty
      console.log('RADREQ:seletedRadItems:', seletedRadItems)
      let notesEmpty = false;
      let serviceName = "";
      Object.entries(seletedRadItems).map(([xindex, val]) => {
        let item = mItems.filter(function callback(element, index, array) {
            // Return true or false
            return element._id === val.id;
        });
        if ( item[0].category === "MRI/MRA" || item[0].category === "CT SCAN" ){
          if ( ( 
            val.notes.audio === null ||
            val.notes.audio === ""
          ) && val.notes.text ==="" ){
            notesEmpty = true;
            // serviceName = `${val.category}: ${val.name}`
          }
        }
      });


      if ( notesEmpty ){
        setOpenNotification(true);
        setErrorMsg(`Alert::Notes are required for the CT SCAN and MRI/MRA Radiology Test.`);
      }else{
        dispatch({
          field: 'checkOneComplete',
          value: false,
        });
      }
    }else{
      setOpenNotification(true);
      setErrorMsg(`Please select some items for the Radiology Test.`);
    }
  }

  const addSelectedRadItem = () => {
    // ConsoleRadRequest 

    if (
      // labComments &&
      // labComments.length > 0 &&
      // labPriority &&
      // labPriority.length > 0 &&
      seletedRadItems.length > 0
    ){
      // Check if notes are empty

      let notesEmpty = false;
      let serviceName = "";

      Object.entries(seletedRadItems).map(([xindex, val]) => {
        let item = mItems.filter(function callback(element, index, array) {
            // Return true or false
            return element._id === val.id;
        });
        if ( item[0].category === "MRI/MRA" || item[0].category === "CT SCAN" ){
          if ( ( 
            val.notes.audio === null ||
            val.notes.audio === ""
          ) && val.notes.text ==="" ){
            notesEmpty = true;
            // serviceName = `${val.category}: ${val.name}`
          }
        }
      });



      console.log("notesCheckSubmit::", highInfectionRisk, highInfectionRiskDesc, highInfectionRiskDesc.trim().length)
      console.log("notesCheckSubmit::", otherMetallicImplants, otherMetallicImplantsDesc, otherMetallicImplantsDesc.trim().length)


      let hasFormError = "";
      /*if ( pregnantCheck === "" ){
        hasFormError = "Please select a value from Is the patient a high infection risk?";
      }else */if ( patientDiabetic === "" ){
        hasFormError = "Please select a value for Is the patient diabetic?";
      }else if ( patientDiabetic === "yes" && diabetesControlled === "" ){
        hasFormError = "Please select a value for Is the diabetes controlled?";
      }else if ( patientAllergies === "" ){
        hasFormError = "Please select a value for Does the patient have any allergies?";
      }

      if (hasMRIExists()){
        if ( pacemaker === "" ){
          hasFormError = "Please select a value for A pacemaker?";
        }else if ( cerebralAneurysmClip === "" ){
          hasFormError = "Please select a value for A cerebral aneurysm clip?";
        }else if ( cochlearImplants === "" ){
          hasFormError = "Please select a value for Cochlear implants?";
        }else if ( neuroStimulators === "" ){
          hasFormError = "Please select a value for Neuro stimulators?";
        }else if ( programmableHydrocephalusShunt === "" ){
          hasFormError = "Please select a value for Programmable hydrocephalus shunt?";
        }else if ( metallicForeignBodyInEye === "" ){
          hasFormError = "Please select a value for Metallic foreign body in eye?";
        }
      }




      if (highInfectionRisk==="yes" && highInfectionRiskDesc.trim().length<=0){
        hasFormError = "Please write Some information about high infection risk.";
      }else{
        if (otherMetallicImplants === "yes" && otherMetallicImplantsDesc.trim().length<=0){
          hasFormError = "Please write Some information about Other metallic implants.";
        }else{

          if (patientAllergies==="yes" && patientAllergiesDesc.trim().length<=0){
            hasFormError = "Please write Some information about patient Allergies.";
          }else{
            if (patientDiabetic==="yes" && diabetesControlled.trim().length<=0){
              hasFormError = "Please select a diabetes controlled.";
            }
          }
        }
      }

      console.log("notesCheckSubmit::", hasFormError, (hasFormError.trim() != ""), hasFormError.trim())


      setOpenNotification(true);
      if ( notesEmpty ){
        setErrorMsg(`Alert::Notes are required for the Radiology Test.`);
      }else{
        if (hasFormError.trim() != ""){
          setErrorMsg(`Alert::${hasFormError}`)
        }else{
          setsuccessMsg("Adding the Rad Request");
          const params = {
            serviceIds: seletedRadItems,
            radRequestChecklist: {
              pregnantCheck,
              breastFeeding,
              highInfectionRisk,
              highInfectionRiskDesc,
              patientDiabetic,
              diabetesControlled,
              patientAllergies,
              patientAllergiesDesc,
              pacemaker,
              cerebralAneurysmClip,
              cochlearImplants,
              neuroStimulators,
              programmableHydrocephalusShunt,
              metallicForeignBodyInEye,
              otherMetallicImplants,
              assistantRequired,
            },
            // priority: labPriority,
            staffId: currentUser._id,
            edrId: episodeId,
            // notes: labComments,
            labTechnicianId: null,
          };
          console.log( 'ConsoleRadRequest', params );
          // props.hideDialog(false);
          
          //SS
          let formData = new FormData();

          formData.append("serviceIds", JSON.stringify(seletedRadItems));
          formData.append("radRequestChecklist[pregnantCheck]", pregnantCheck);
          formData.append("radRequestChecklist[breastFeeding]", breastFeeding);
          formData.append("radRequestChecklist[highInfectionRisk]", highInfectionRisk);
          formData.append("radRequestChecklist[highInfectionRiskDesc]", highInfectionRiskDesc);
          formData.append("radRequestChecklist[patientDiabetic]", patientDiabetic);
          formData.append("radRequestChecklist[diabetesControlled]", diabetesControlled);
          formData.append("radRequestChecklist[patientAllergies]", patientAllergies);
          formData.append("radRequestChecklist[patientAllergiesDesc]", patientAllergiesDesc);
          formData.append("radRequestChecklist[pacemaker]", pacemaker);
          formData.append("radRequestChecklist[cerebralAneurysmClip]", cerebralAneurysmClip);
          formData.append("radRequestChecklist[cochlearImplants]", cochlearImplants);
          formData.append("radRequestChecklist[neuroStimulators]", neuroStimulators);
          formData.append("radRequestChecklist[programmableHydrocephalusShunt]", programmableHydrocephalusShunt);
          formData.append("radRequestChecklist[metallicForeignBodyInEye]", metallicForeignBodyInEye);
          formData.append("radRequestChecklist[otherMetallicImplants]", otherMetallicImplants);
          formData.append("radRequestChecklist[otherMetallicImplantsDesc]", otherMetallicImplantsDesc);
          formData.append("radRequestChecklist[assistantRequired]", assistantRequired);
          formData.append("staffId", currentUser._id);
          formData.append("edrId", episodeId);
          formData.append("labTechnicianId", null);
          formData.append("urgent", (props.history.location.state.lifelimbStatus?'Yes':'No'));

          for (let ixp = 0; ixp < seletedRadItems.length; ixp++) {
            if (seletedRadItems[ixp].notes){

              if ( seletedRadItems[ixp].notes.audio!=="" && seletedRadItems[ixp].notes.audio instanceof Blob )
              {
                formData.append("AudioFile", seletedRadItems[ixp].notes.audio, seletedRadItems[ixp].id);
              }
            }
          }
          
          axios
            // .put(addRadRequestBulk, params)
            .put(addRadRequestBulk, formData)
            .then((res) => {
              console.log( 'ConsoleRadRequest', res.data );
              if (res.data.success) {
                props.history.push({
                  pathname: "/dashboard/home/"+episodeId+"/rad",
                });
              } else if (!res.data.success) {
                setOpenNotification(true);
                setErrorMsg("Error while adding the Rad Request");
              }
            })
            .catch((e) => {
              console.log('ConsoleRadRequest', "error after adding Rad Request", e);
              setOpenNotification(true);
              setErrorMsg("An error occurred while adding the Rad Request.");
            });
          
        }

      }
    }else{
      setOpenNotification(true);
      setErrorMsg("Error while adding the Rad Request");
    }

    /*
    if (validateFields()) {
      const params = {
        serviceId: selectedRad._id,
        name: selectedRad.name,
        type: selectedRad.type,
        price: selectedRad.price,
        priority: labPriority,
        staffId: currentUser._id,
        edrId:
          props.history.location.state.comingFrom === "notification"
            ? reponseThroughNotification._id
            : selectedRecord._id,
        notes: labComments,
        labTechnicianId: labTechnician,
      };
      console.log("Rad params", params);
      saveRadRequestCall(
        params,
        setOpenNotification,
        setErrorMsg,
        props,
        dispatch
      );
    } else {
      setOpenNotification(true);
      setErrorMsg("Error while adding the Rad Request");
    }
    setRadForm(true);
    */
  };

  const [currentUser] = useState(cookie.load("current_user"));
  const [timer, setTimer] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [itemFound, setItemFound] = useState("");
  const [itemFoundSuccessfull, setItemFoundSuccessfully] = useState(false);
  const [loadSearchedData, setLoadSearchedData] = useState(false);
  const [selectedRad, setSelectedRad] = useState("");
  const [categoriesData, setCategoriesData] = useState([]);
  const [mItems, setMItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [myDefaultItems, setMyDefaultItems] = useState([]);

  const [patientInsured, setPatientInsured] = useState(false);

  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setsuccessMsg] = useState("");
  const [openNotification, setOpenNotification] = useState(false);


  const [patientDetails, setPatientDetails] = useState({});
  const [selectedRecord, setSelectedRecord] = useState({});

  const [ episodeId, setEpisodeId ] = useState(props.match.params.id);
  const [ pageId, setPageId ] = useState(props.match.params.pagename);

  const [ favoriteListData, setFavoriteListData ] = useState([]);
  
  if (openNotification) {
    setTimeout(() => {
      setOpenNotification(false);
      setErrorMsg("");
      setsuccessMsg("");
    }, 9000);
  }

  useEffect(() => {

    axios
      .get(getSingleEdr + "/" + episodeId)
      .then((res) => {

        console.log('handleView::', res.data.data);
        if (res.data.success) {

          let remoteRecord = replacePatientIdIfNull(res.data.data);
          setSelectedRecord(remoteRecord);

          if (remoteRecord.patientId.paymentMode === "Insured"){
            setPatientInsured(true);
          }

          if (remoteRecord.defaultRADCheckList){
            Object.entries(remoteRecord.defaultRADCheckList).map(([x, i]) => {
              dispatch({ field: x, value: i });
              console.log('FFFFFFFFF::', { field: x, value: i })
            })
          }
          // props.history.location.state.defaultRADCheckList

          const object = {
            '_id': remoteRecord._id,
            patientName: remoteRecord.patientId.name[0].given[0] + " " + remoteRecord.patientId.name[0].family,
            mrn: remoteRecord.requestNo,//remoteRecord.patientId.identifier[0].value,
            age: remoteRecord.patientId.age,
            gender: remoteRecord.patientId.gender,
            weight: remoteRecord.patientId.weight,
            department: remoteRecord.currentLocation,
            chiefComplaint: remoteRecord.chiefComplaint.length > 0 ? remoteRecord.chiefComplaint.pop().chiefComplaintId.short_desc : "N/A",
            chiefComplaintOthers: remoteRecord.chiefComplaintOthers,
          };
          setPatientDetails(object);
          loadAsyncData();

        }
      })
      .catch((e) => {
        console.log("error after searching patient request", e);
      });

      console.log('handleView::rb::', isRecording, isBlocked);
    navigator.mediaDevices &&
      navigator.mediaDevices.getUserMedia(
        { audio: true },
        () => {
          dispatch({ field: "isBlocked", value: false });
        },
        () => {
          dispatch({ field: "isBlocked", value: true });
        }
      );
  }, []);

  useEffect(() => {
  }, [myDefaultItems]);

  const startRecording = (index, item) => {
    console.log('handleView::ii::', index, item)
    if (isBlocked) {

    } else {
      Mp3Recorder.start()
        .then(() => {
          dispatch({ field: "isRecording", value: true });
          dispatch({ field: "recordingField", value: item });
          console.log('handleView::rec::',true)
        })
        .catch((e) => console.error(e));
    }
  };

  const stopRecording = () => {
    Mp3Recorder.stop()
      .getMp3()
      .then(([buffer, blob]) => {

        const blobURLFinal = URL.createObjectURL(blob);
        let blobURLx = blobURL;
        blobURLx[ recordingField ] = blobURLFinal;
        dispatch({ field: "blobURL", value: blobURLx });

        dispatch({ field: "isRecording", value: false });
        var file = new File([blob], "first", { type: "audio/mp3" });


        // Setting Up Audio File
        if (seletedRadItems.length > 0){
          seletedRadItems.map((i, itemIx) => {
            // let item = mItems.filter((element, index, array) => element._id === recordingField);
            console.log('handleView::rec::stop::', i, itemIx, recordingField)
            i.notes.audio = file;
          });
        }


        dispatch({ field: "fileAudio", value: file });
        dispatch({ field: "recordingField", value: '' });
        console.log('handleView::rec::',false)
      })
      .catch((e) => console.log(e));
  };

  const loadFavoriteData = () => {
      axios
        .get(getFavoriteList + "?staffId="+currentUser._id+"&listType=rad")
        .then((res) => {
          if (res.data.success) {
            setFavoriteListData(res.data.data);
          }
        })
        .catch((e) => {
          console.log("error while searching req", e);
        });
  }

  const loadAsyncData = () => {
      axios
        .get(getRadShortServiceCategory)
        .then((res) => {
          if (res.data.success) {
            let remoteData = res.data.data;
            setCategoriesData(remoteData);

            loadFavoriteData();

            let itemsInList = [];
            let defaultItemsInList = [];
            let newDefaultItemsInList = [];

            // Items Extractor
            remoteData.map((d, index) => {
              let slug = uniqueCodeField(d._id);
              let values = [];

              if (d.items.length > 0){
                d.items.map((nd, nindex) => {
                  // values.push(nd);
                  itemsInList.push(nd);

                  if (nd.checked){
                    values.push(nd._id);
                  }

                });
              }
              /*
              defaultItemsInList.push({
                'id': slug,
                'name': d._id,
                'menuOpen': false,
                'xUrgent': 'No',
                'priority': 'High',
                'items': values,
              });
              */
              newDefaultItemsInList[ `id-${slug}` ] = {
                'id': slug,
                'name': d._id,
                'menuOpened': false,
                'xUrgent': (props.history.location.state.lifelimbStatus?'Yes':'No'),
                'priority': 'High',
                'items': values,
              };
            });

            // dispatch({ xx: 'defaultItems', value: defaultItemsInList });
            dispatch({ field: 'resetedOn', value: 'now' });

            setMyDefaultItems(newDefaultItemsInList);
            dispatch({
              field: 'defaultItems',
              value: newDefaultItemsInList
            });

            setIsLoading(false);
            setMItems(itemsInList);
            // setCategoriesData([
            //   {_id: "Hello"}
            // ])
          }
        })
        .catch((e) => {
          console.log("error while searching req", e);
        });

  }
  // loadAsyncData();

  const handlePauseRadSearch = (e) => {
    // setLoadSearchedData(true);
    // clearTimeout(timer);

    const a = e.target.value.replace(/[^\w\s]/gi, "");
    setSearchQuery(a);

    setTimer(
      setTimeout(() => {
        
      }, 600)
    );
  };

  const handleChange = (event) => {
    // setState({ ...state, [event.target.name]: event.target.checked });


    let nSlug = event.target.name;
    console.log( 'RadReq::handleChange::', event.target.checked, event.target.name, event.target.value, `id-`+nSlug, defaultItems );
    if ( `id-`+nSlug in defaultItems ){
      let defaultSelected = defaultItems[ `id-`+nSlug ].items;

      console.log( 'RadReq::handleChange::', defaultSelected );

      if (defaultSelected.includes(event.target.value)){ // Already Exists Item and Remove it
        let indexOfItem = defaultSelected.indexOf( event.target.value );
        defaultSelected.splice( indexOfItem, 1 );
      }else{ // Adding Item
        defaultSelected.push( event.target.value );
      }

      let nItems = defaultItems;
      nItems[ `id-`+nSlug ].items = defaultSelected;

      dispatch({
        field: 'defaultItems',
        value: nItems
      });
    }

  };

  const addSelectedItems = (slug) => {
    
    let defaultSelected = [];
    let currentIndex = -1;
    let sPriority = 'High';
    let sUrgent   = (props.history.location.state.lifelimbStatus?'Yes':'No');

    console.log( 'RadReq::addSelectedItems::', slug )

    if ( `id-${slug}` in defaultItems )
    {
      let d = defaultItems[ `id-${slug}` ];
      console.log( 'RadReq::addSelectedItems::', d );

      sPriority = d.priority;
      sUrgent = (props.history.location.state.lifelimbStatus?'Yes':'No');
      defaultSelected = d.items;
      d.menuOpened = false;

    }
 
    // Selected Items
    let readyToAdd = seletedRadItems;
    console.log( 'RadReq::ConsoleRadRequest::readyToAdd', readyToAdd );

    if ( defaultSelected.length > 0 ) {
      defaultSelected.map((dx, dindex) => {
        console.log( 'RadReq::ConsoleRadRequest::dx::dindex::', dx, dindex );

        let itemExists = readyToAdd.filter((element, index, array) => {
            // Return true or false
            return element.id === dx;
        });

        if ( itemExists.length<=0 ){
          readyToAdd.push({
            id: dx,
            priority: sPriority,
            xUrgent : sUrgent,
            notes: {
              text: '',
              audio: null,
            }
          });
        }
      });

      dispatch({
        field: 'seletedRadItems',
        value: readyToAdd
      });
    }else{
      setOpenNotification(true);
      setErrorMsg('Please select an item from the above list.');
    }

  };

  const toggleDropdownMenu = (slug) => {
    
    let newSlug = uniqueCodeField(slug);

    // Reset all opened
    dispatch({field: 'newSearchTerm', value: ''});

    Object.entries(defaultItems).map(([x, i]) => {
      console.log('RadReq::toggleDropdownMenu::x,i::', x, i );
      if ( `id-${newSlug}` ===  x){

      }else{
        i.menuOpened = false;
        i.items = [];
      }
    })


    console.log('RadReq::toggleDropdownMenu::slug::', slug );
    console.log('RadReq::toggleDropdownMenu::newSlug::', newSlug );
    console.log('RadReq::toggleDropdownMenu::defaultItems::', defaultItems );
    if ( `id-${newSlug}` in defaultItems){
      console.log('RadReq::toggleDropdownMenu::exists::', "Yes" );
      let obj = defaultItems[ `id-${newSlug}` ];
      console.log('RadReq::toggleDropdownMenu::exists::obj::', obj );
      if (obj.menuOpened === true){
        obj.menuOpened = false
        obj.items = [];
      }else{
        obj.menuOpened = true;
      }
      console.log('RadReq::toggleDropdownMenu::opened::', obj );
    }else{
      console.log('RadReq::toggleDropdownMenu::', "Not exists" );
    }
    dispatch({
      field: 'defaultItems',
      value: defaultItems
    });
  }

  const onChangeDropDownXUrgentValue = (e, index, item) => {
    console.log("ConsoleRadRequest :: onChangePriorityValue", e, index, item);

    defaultItems.map((d, index) => {
      if (d.name === item){
        d.xUrgent = e;
      }
    });
    dispatch({
      field: 'defaultItems',
      value: defaultItems
    });
  };

  const onChangeDropDownPriorityValue = (e, item, type) => {
    let nSlug = uniqueCodeField(item);
    console.log("ConsoleRadRequest :: onChangePriorityValue", e, item);

    if (type==="xUrgent"){

      if (`id-`+nSlug in defaultItems){
        defaultItems[ `id-`+nSlug ].xUrgent = e;
      }
      // defaultItems.filter(obj => obj.name === item).forEach(obj => obj.xUrgent = e);
    }else if (type==="priority"){
      // defaultItems.filter(obj => obj.name === item).forEach(obj => obj.priority = e);

      if (`id-`+nSlug in defaultItems){
        defaultItems[ `id-`+nSlug ].priority = e;
      }
    }

    /*
    defaultItems.map((d, index) => {
      if (d.name === item){
        if (type==="xUrgent"){
          d.xUrgent = e;
        }else if (type==="priority"){
          d.priority = e;
        }
      }
    });
    */
    console.log('defaultItems', defaultItems);
    dispatch({
      field: 'defaultItems',
      value: defaultItems
    });
  };

  const labPriorityDefaultSelection = (slug) => {
    let menuOpened = "";
    let currentIndex = -1;
    let nDefaultItem;

    let nSlug = uniqueCodeField(slug);
    console.log(`id-`+nSlug);

    if (`id-`+nSlug in defaultItems){
      nDefaultItem = defaultItems[ `id-`+nSlug ]
    }

    return nDefaultItem ? nDefaultItem:{'priority':'High', 'xUrgent': (props.history.location.state.lifelimbStatus?'Yes':'No')};
  };

  const dropdownOpened = (slug) => {
    let menuOpened = "";
    let currentIndex = -1;

    let nSlug = uniqueCodeField(slug);
    if (`id-`+nSlug in defaultItems){
      if ( defaultItems[ `id-`+nSlug ].menuOpened === true ){
        menuOpened = "show";
      }
    }

    return menuOpened;
  };

  const isDropdownOpened = (slug) => {
    let menuOpened = false;
    let currentIndex = -1;

    let nSlug = uniqueCodeField(slug);
    if (`id-`+nSlug in defaultItems){
      if ( defaultItems[ `id-`+nSlug ].menuOpened === true ){
        menuOpened = true;
      }
    }

    return menuOpened;
  };

  const hasMRIExists = (slug) => {
    let hasMRIEx = false;
    seletedRadItems.map((i, itemIx) => {
      let item = mItems.filter(function callback(element, index, array) {
          // Return true or false
          return element._id === i.id;
      });
      if ( item[0].category === "MRI/MRA" ){
        hasMRIEx = true;
      }
    });

    return hasMRIEx;
  };

  function handleAddItem(i) {
    console.log("selected item", i);

    setSelectedRad(i);
    /*
    dispatch({
      field: "labServiceName",
      value: i.name,
    });
    */

    setSearchQuery("");
    // setaddRadRequest(true)
  }

  const handleRadioFormChange = (event) => {
    dispatch({field: event.target.name, value: event.target.value});
    console.log("FFFFFFFFF::",{field: event.target.name, value: event.target.value});

    if (event.target.name === "highInfectionRisk" && (event.target.value === "no" || event.target.value === "") ){
      dispatch({
        field: "highInfectionRiskDesc",
        value: ""
      });
    }

    if (event.target.name === "patientAllergies" && (event.target.value === "no" || event.target.value === "")){
      dispatch({
        field: "patientAllergiesDesc",
        value: ""
      });
    }

    // setValue(event.target.value);
    // setHelperText(' ');
    // setError(false);
  };

  const handleCallFromBookmark = (entryId) => {
    let favItems = favoriteListData.filter((element, index, array) => {
        // Return true or false
        return element._id == entryId;
    });

    let readyToAdd = seletedRadItems;

    if (favItems.length > 0){
      let currentItem = favItems[0];
      Object.entries(favItems[0].serviceIds).map((req, reqIndex) =>{

        let itemExists = readyToAdd.filter((element, index, array) => {
            // Return true or false
            return element.id === req[1].requestId;
        });
        if ( itemExists.length<=0 ){
          readyToAdd.push({
            id: req[1].requestId,
            priority: 'High',
            xUrgent : (props.history.location.state.lifelimbStatus?'Yes':'No'),
            notes: {
              text: '',
              audio: null,
            }
          });
        }
      })
      console.log('readyToAdd', readyToAdd);
      dispatch({
        field: 'seletedRadItems',
        value: readyToAdd
      });
      
    }else{
      setOpenNotification(true);
      setErrorMsg('Sorry, the items doesn\'t exists');
    }
  }

  return (
    
    <div
      style={{
        backgroundColor: "rgb(19 213 159)",
        position: "fixed",
        display: "flex",
        width: "100%",
        height: "100%",
        flexDirection: "column",
        flex: 1,
        overflowY: "scroll",
      }}
    >
      <Header history={props.history} />

      <PatientDetailsQuick
        edrId={episodeId} />

      <div className="cPadding">
        {checkOneComplete}
        {
          checkOneComplete === false?
          <div className="row mb-5">
            <div className={classNames((hasMRIExists() ? 'col-6':'col-12'))}>
              <h4 className="h6 text-white mb-2"
                style={{
                  fontWeight: '700'
                }}>Safety Check</h4>
                <div className="card card-body">
                  <div className={props.history.location.state.gender==="Female"?'':'d-none'}>
                    <div className="row align-items-md-center">
                      <div className="col-12 col-md-8"><FormLabel component="legend" className="mb-0">Could the patient be pregnant?</FormLabel></div>
                      <div className="col-12 col-md-4 text-right">
                        <RadioGroup row aria-label="pregnantCheck" name="pregnantCheck" value={pregnantCheck} onChange={handleRadioFormChange}>
                          <FormControlLabel className="mb-0" value="yes" control={<Radio />} label="Yes" />
                          <FormControlLabel className="mb-0" value="no" control={<Radio />} label="No" />
                        </RadioGroup>
                      </div>
                    </div>
                    <hr />
                    <div className="row align-items-md-center">
                      <div className="col-12 col-md-8"><FormLabel component="legend" className="mb-0">Is the patient breast feeding?</FormLabel></div>
                      <div className="col-12 col-md-4 text-right">
                        <RadioGroup row aria-label="breastFeeding" name="breastFeeding" value={breastFeeding} onChange={handleRadioFormChange}>
                          <FormControlLabel className="mb-0" value="yes" control={<Radio />} label="Yes" />
                          <FormControlLabel className="mb-0" value="no" control={<Radio />} label="No" />
                        </RadioGroup>
                      </div>
                    </div>
                    <hr />
                  </div>
                  <div className="row align-items-md-center">
                    <div className="col-12 col-md-8"><FormLabel component="legend" className="mb-0">Is the patient a high infection risk?</FormLabel></div>
                    <div className="col-12 col-md-4 text-right">
                      <RadioGroup row aria-label="highInfectionRisk" name="highInfectionRisk" value={highInfectionRisk} onChange={handleRadioFormChange}>
                        <FormControlLabel className="mb-0" value="yes" control={<Radio />} label="Yes" />
                        <FormControlLabel className="mb-0" value="no" control={<Radio />} label="No" />
                      </RadioGroup>
                    </div>
                  </div>
                  <TextField
                    required

                    className="textInputStyle mb-3"
                    id="highInfectionRiskDesc"
                    type="text"
                    variant="filled"
                    label="Specify any"
                    name={"highInfectionRiskDesc"}
                    value={highInfectionRiskDesc}
                    onChange={(event) => {
                      dispatch({
                        field: event.target.name,
                        value: event.target.value.replace(/[^\w\s]/gi, ""),
                      });
                    }}
                    InputProps={{
                      disableUnderline: true,
                    }}
                    disabled={highInfectionRisk==="no"?true:false}
                  />
                  <hr />
                  <div className="row align-items-md-center">
                    <div className="col-12 col-md-8"><FormLabel component="legend" className="mb-0">Is the patient diabetic?</FormLabel></div>
                    <div className="col-12 col-md-4">
                      <RadioGroup row aria-label="patientDiabetic" name="patientDiabetic" value={patientDiabetic} onChange={handleRadioFormChange}>
                        <FormControlLabel className="mb-0" value="yes" control={<Radio />} label="Yes" />
                        <FormControlLabel className="mb-0" value="no" control={<Radio />} label="No" />
                      </RadioGroup>
                    </div>
                  </div>
                  <div className={(patientDiabetic==="yes"?'':'d-none')}>
                    <hr />
                    <div className="row align-items-md-center">
                      <div className="col-12"><FormLabel component="legend" className="mb-0">Is the diabetes controlled:</FormLabel></div>
                      <div className="col-12">
                        <RadioGroup row aria-label="diabetesControlled" name="diabetesControlled" value={diabetesControlled} onChange={handleRadioFormChange}>
                          <FormControlLabel className="mb-0" value="Diet" control={<Radio />} label="Diet" />
                          <FormControlLabel className="mb-0" value="Insulin" control={<Radio />} label="Insulin" />
                          <FormControlLabel className="mb-0" value="Tablet" control={<Radio />} label="Tablet" />
                          <FormControlLabel className="mb-0" value="Not Controlled" control={<Radio />} label="Not Controlled" />
                        </RadioGroup>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="row align-items-md-center">
                    <div className="col-12 col-md-8"><FormLabel component="legend" className="mb-0">Does the patient have any allergies?</FormLabel></div>
                    <div className="col-12 col-md-4">
                      <RadioGroup row aria-label="patientAllergies" name="patientAllergies" value={patientAllergies} onChange={handleRadioFormChange}>
                        <FormControlLabel className="mb-0" value="yes" control={<Radio />} label="Yes" />
                        <FormControlLabel className="mb-0" value="no" control={<Radio />} label="No" />
                      </RadioGroup>
                    </div>
                  </div>

                  <TextField
                    required

                    className="textInputStyle mb-3"
                    id="patientAllergiesDesc"
                    type="text"
                    variant="filled"
                    label="Specify any"
                    name={"patientAllergiesDesc"}
                    value={patientAllergiesDesc}
                    onChange={(event) => {
                      dispatch({
                        field: event.target.name,
                        value: event.target.value.replace(/[^\w\s]/gi, ""),
                      });
                    }}
                    InputProps={{
                      disableUnderline: true,
                    }}
                    disabled={patientAllergies==="no"?true:false}
                  />
                </div>
            </div>
            <div className={classNames('col-6', (hasMRIExists() ? '':'d-none'))}>
              <h4 className="h6 text-white mb-2"
                style={{
                  fontWeight: '700'
                }}>MRI Contraindications</h4>
                <div className="card card-body">
                  <div className="row align-items-md-center">
                    <div className="col-12 col-md-8"><FormLabel component="legend" className="mb-0">A pacemaker?</FormLabel></div>
                    <div className="col-12 col-md-4">
                      <RadioGroup row aria-label="pacemaker" name="pacemaker" value={pacemaker} onChange={handleRadioFormChange}>
                        <FormControlLabel className="mb-0" value="yes" control={<Radio />} label="Yes" />
                        <FormControlLabel className="mb-0" value="no" control={<Radio />} label="No" />
                      </RadioGroup>
                    </div>
                  </div>
                  <hr />
                  <div className="row align-items-md-center">
                    <div className="col-12 col-md-8"><FormLabel component="legend" className="mb-0">A cerebral aneurysm clip?</FormLabel></div>
                    <div className="col-12 col-md-4">
                      <RadioGroup row aria-label="cerebralAneurysmClip" name="cerebralAneurysmClip" value={cerebralAneurysmClip} onChange={handleRadioFormChange}>
                        <FormControlLabel className="mb-0" value="yes" control={<Radio />} label="Yes" />
                        <FormControlLabel className="mb-0" value="no" control={<Radio />} label="No" />
                      </RadioGroup>
                    </div>
                  </div>
                  <hr />
                  <div className="row align-items-md-center">
                    <div className="col-12 col-md-8"><FormLabel component="legend" className="mb-0">Cochlear implants?</FormLabel></div>
                    <div className="col-12 col-md-4">
                      <RadioGroup row aria-label="cochlearImplants" name="cochlearImplants" value={cochlearImplants} onChange={handleRadioFormChange}>
                        <FormControlLabel className="mb-0" value="yes" control={<Radio />} label="Yes" />
                        <FormControlLabel className="mb-0" value="no" control={<Radio />} label="No" />
                      </RadioGroup>
                    </div>
                  </div>
                  <hr />
                  <div className="row align-items-md-center">
                    <div className="col-12 col-md-8"><FormLabel component="legend" className="mb-0">Neuro stimulators?</FormLabel></div>
                    <div className="col-12 col-md-4">
                      <RadioGroup row aria-label="neuroStimulators" name="neuroStimulators" value={neuroStimulators} onChange={handleRadioFormChange}>
                        <FormControlLabel className="mb-0" value="yes" control={<Radio />} label="Yes" />
                        <FormControlLabel className="mb-0" value="no" control={<Radio />} label="No" />
                      </RadioGroup>
                    </div>
                  </div>
                  <hr />
                  <div className="row align-items-md-center">
                    <div className="col-12 col-md-8"><FormLabel component="legend" className="mb-0">Programmable hydrocephalus shunt?</FormLabel></div>
                    <div className="col-12 col-md-4">
                      <RadioGroup row aria-label="programmableHydrocephalusShunt" name="programmableHydrocephalusShunt" value={programmableHydrocephalusShunt} onChange={handleRadioFormChange}>
                        <FormControlLabel className="mb-0" value="yes" control={<Radio />} label="Yes" />
                        <FormControlLabel className="mb-0" value="no" control={<Radio />} label="No" />
                      </RadioGroup>
                    </div>
                  </div>
                  <hr />
                  <div className="row align-items-md-center">
                    <div className="col-12 col-md-8"><FormLabel component="legend" className="mb-0">Metallic foreign body in eye?</FormLabel></div>
                    <div className="col-12 col-md-4">
                      <RadioGroup row aria-label="metallicForeignBodyInEye" name="metallicForeignBodyInEye" value={metallicForeignBodyInEye} onChange={handleRadioFormChange}>
                        <FormControlLabel className="mb-0" value="yes" control={<Radio />} label="Yes" />
                        <FormControlLabel className="mb-0" value="no" control={<Radio />} label="No" />
                      </RadioGroup>
                    </div>
                  </div>
                  <hr />
                  <div className="row align-items-md-center">
                    <div className="col-12 col-md-8"><FormLabel component="legend" className="mb-0">Other metallic implants?</FormLabel></div>
                    <div className="col-12 col-md-4">
                      <RadioGroup row aria-label="otherMetallicImplants" name="otherMetallicImplants" value={otherMetallicImplants} onChange={handleRadioFormChange}>
                        <FormControlLabel className="mb-0" value="yes" control={<Radio />} label="Yes" />
                        <FormControlLabel className="mb-0" value="no" control={<Radio />} label="No" />
                      </RadioGroup>
                    </div>
                  </div>
                  <TextField
                    required

                    className="textInputStyle mb-3"
                    id="otherMetallicImplantsDesc"
                    type="text"
                    variant="filled"
                    label="Specify any"
                    name={"otherMetallicImplantsDesc"}
                    value={otherMetallicImplantsDesc}
                    onChange={(event) => {
                      dispatch({
                        field: event.target.name,
                        value: event.target.value.replace(/[^\w\s]/gi, ""),
                      });
                    }}
                    InputProps={{
                      disableUnderline: true,
                    }}
                    disabled={otherMetallicImplants==="no"?true:false}
                  />
                </div>
            </div>
            <div className="col-12">
                <div className="card card-body mt-4">
                  <div className="row align-items-md-center">
                    <div className="col-12 col-md-8"><FormLabel component="legend" className="mb-0">ED Nurse Assistance Required?</FormLabel></div>
                    <div className="col-12 col-md-4">
                      <RadioGroup row aria-label="assistantRequired" name="assistantRequired" value={assistantRequired} onChange={handleRadioFormChange}>
                        <FormControlLabel className="mb-0" value="yes" control={<Radio />} label="Yes" />
                        <FormControlLabel className="mb-0" value="no" control={<Radio />} label="No" />
                      </RadioGroup>
                    </div>
                  </div>
                </div>
            </div>
            <div className="col-md-6">
              <Button
                className="mt-4"
                style={{
                  ...styles.stylesForButton,
                  color: "white",
                  cursor: "pointer",
                  borderRadius: 5,

                  height: matches ? 56 : 45,
                  outline: "none",
                  width: "100%",
                }}
                // disabled={!addRadRequest}
                onClick={() => {
                  dispatch({
                    field: 'checkOneComplete',
                    value: true,
                  });
                }}
                variant="contained"
                color="primary"
                fullWidth
              >&laquo; Back</Button>
            </div>
            <div className="col-md-6">
              <Button
                className="mt-4"
                style={{
                  ...styles.stylesForButton,
                  backgroundColor: "#AD6BBF",
                  color: "white",
                  cursor: "pointer",
                  borderRadius: 5,
                  backgroundColor: "rgb(173, 107, 191)",
                  height: matches ? 56 : 45,
                  outline: "none",
                  width: "100%",
                }}
                onClick={addSelectedRadItem}
                variant="contained"
                color="primary"
                fullWidth
              >Submit</Button>
            </div>
          </div>:undefined
        }

        {
          checkOneComplete === true?
          <>
            <h4 className="h6 text-white"
              style={{
                fontWeight: '700'
              }}>Selected Profiles/Rad Requests
              {
                props.history.location.state.lifelimbStatus?<span className="badge badge-success pull-right">LifeLimb: {(props.history.location.state.lifelimbStatus?'ON':'Off')}</span>:undefined
              }
            </h4>

            <div
              style={{
                zIndex: 10,
                marginTop: 10,
              }}
            >
              <Paper className="mb-3">
                <Table size="small" stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell>Service Name</TableCell>
                    </TableRow>
                  </TableHead>
                  {seletedRadItems.length > 0 ? (
                    <TableBody>
                      {seletedRadItems.map((i, itemIx) => {
                        let item = mItems.filter(function callback(element, index, array) {
                            // Return true or false
                            return element._id === i.id;
                        });

                        if (item.length > 0){
                        return (
                          <TableRow
                            key={i}
                            style={{ cursor: "pointer" }}
                          >
                            <TableCell>
                              <div className="serviceOffers">
                                <div className="serviceHeader">
                                  <div className="serviceDesc-Name">{i.xUrgent==='Yes'?<i className="fa fa-info-circle text-market"></i>:<i className="fa fa-caret-right"></i>}<span className="ml-2"><strong>{item[0].category}</strong>: {item[0].name}</span></div>
                                  <div className="serviceOption">
                                    <FormControl variant="filled" className="mServiceDropdown" InputProps={{ disableUnderline: true }}>
                                      <Select
                                        name={"labPriority"}
                                        value={i.priority}
                                        InputProps={{ disableUnderline: true }}
                                        onChange={(e) => {
                                          onChangePriorityValue(e.target.value, i, item[0]._id)
                                        }}
                                      >
                                        {priorityArray.map((val) => {
                                          return (
                                            <MenuItem key={val.key} value={val.key}>
                                              {val.value}
                                            </MenuItem>
                                          );
                                        })}
                                      </Select>
                                    </FormControl>
                                    <Icon className="fa fa-trash" color="secondary" onClick={() => removeSelectedRadItem(i, item[0]._id)} />
                                  </div>
                                </div>
                                <div className="serviceNotes">
                                  <div className="micControl">
                                    <div className="d-flex align-items-center">
                                      <div
                                        style={{
                                          backgroundColor: "#2C6DDD",
                                          height: 42,
                                          borderRadius: 42,
                                          width: 42,
                                          paddingLeft: 10,
                                          cursor: 'pointer'
                                        }}
                                      >
                                        {isRecording ? (
                                          recordingField===item[0]._id?
                                          <StopIcon
                                            style={{ marginTop: 10, color: "white" }}
                                            fontSize="16px"
                                            onClick={stopRecording}
                                            disabled={!isRecording}
                                            color="secondary"
                                          />:
                                          <MicIcon
                                            style={{ marginTop: 10, color: "white" }}
                                            fontSize="16px"
                                            onClick={() => {
                                              startRecording(i, item[0]._id)
                                            }}
                                            disabled={isRecording}
                                            color="primary"
                                          />
                                        ) : (
                                          <MicIcon
                                            style={{ marginTop: 10, color: "white" }}
                                            fontSize="16px"
                                            onClick={() => {
                                              startRecording(i, item[0]._id)
                                            }}
                                            disabled={isRecording}
                                            color="primary"
                                          />
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="notesDiv">
                                    <TextField
                                      // required
                                      multiline="true"
                                      rows="3"
                                      // disabled={enableForm}
                                      label="Any Comments / Notes?"
                                      name={"labComments"}
                                      value={i.notes.text}
                                      // error={labComments === ""}
                                      onChange={(e) => {onChangeNotesValue(e.target.value, i, item[0]._id)}}
                                      className="textInputStyle"
                                      variant="filled"
                                      InputProps={{
                                        disableUnderline: true,
                                      }}
                                    />


                                    {isRecording && (recordingField===item[0]._id) ? (
                                      <div
                                        style={{
                                          marginLeft: matches ? 110 : 18,
                                          marginRight: matches ? 110 : 75,
                                          width: matches ? 550 : 100,
                                        }}
                                      >
                                        <Loader
                                          type="ThreeDots"
                                          color="blue"
                                          height={40}
                                          width={40}
                                        />
                                      </div>
                                    ) : (
                                      <audio
                                        style={{
                                          marginRight: 10,
                                          width: matches ? 450 : 260,
                                          marginTop: 6,
                                        }}
                                        src={blobURL[item[0]._id]}
                                        controls="controls"
                                      />
                                    )}



                                  </div>
                                </div>
                                {
                                  formSubmit && (item[0].category==="MRI/MRA" || item[0].category==="CT SCAN") && ( i.notes.audio === null || i.notes.audio === "" ) && i.notes.text === "" ? <div className="text-danger">Notes are Required!</div>:undefined
                                }
                                
                              </div>
                            </TableCell>
                          </TableRow>
                        );
                        }
                      })}
                    </TableBody>
                  ) : (
                    <TableBody>
                      <TableRow key="0">
                        <TableCell align="center" colSpan={5}>No Profiles/Rad Requests Selected</TableCell>
                      </TableRow>
                    </TableBody>
                  )} 
                </Table>
              </Paper>
              {seletedRadItems.length > 0 ? (

                <div className="row row-5">
                  <div className="col-12 col-md-4 offset-md-4">
                    <Button
                      // className="addButton"
                      style={{
                        ...styles.stylesForButton,
                        backgroundColor: "#AD6BBF",
                        color: "white",
                        cursor: "pointer",
                        borderRadius: 5,
                        backgroundColor: "rgb(173, 107, 191)",
                        height: matches ? 56 : 45,
                        outline: "none",
                        width: "100%",
                      }}
                      // disabled={!addRadRequest}
                      // onClick={addSelectedRadItem}
                      onClick={addToCheckListPage}
                      variant="contained"
                      color="primary"
                      fullWidth
                    >Next &raquo;</Button>
                  </div>
                </div>
              ) : (
                undefined
              )} 
              <hr />
            </div>
            
            
        
            <div class="dropdown pull-right dropdown-favorite">
              <button class="btn btn-light dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i className="float-right fa fa-heart text-danger"></i></button>
              <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                <a class="dropdown-item" href="/dashboard/home/manage-favorites/rad"><i className="fa fa-list" /> Manage Favorite</a>
                <div className="dropdown-divider"></div>
                {
                  Object.entries(favoriteListData).map((obj, item) => {
                    return <span class="dropdown-item d-flex justify-content-between align-items-center" onClick={() => {
                      handleCallFromBookmark(obj[1]._id)
                    }}>{obj[1].listName} - <i className="badge badge-danger">{obj[1].serviceIds.length}</i></span>;
                  })
                }
              </div>
            </div>
            <h4 className="h6 text-white" style={{ fontWeight: '700' }}>Select Test(s)/Bundle(s)</h4>

            {searchQuery ? (
              <div
                style={{
                  zIndex: 10,
                  marginTop: 10,
                }}
              >
                <Paper style={{ maxHeight: 200, overflow: "auto" }}>
                  {itemFoundSuccessfull && itemFound !== "" ? (
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell>Service Name</TableCell>
                          <TableCell align="right">Type</TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {itemFound.map((i) => {
                          return (
                            <TableRow
                              key={i.serviceNo}
                              onClick={() => handleAddItem(i)}
                              style={{ cursor: "pointer" }}
                            >
                              <TableCell>{i.name}</TableCell>
                              <TableCell align="right">{i.category}</TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  ) : loadSearchedData ? (
                    <div style={{ textAlign: "center" }}>
                      <Loader
                        type="TailSpin"
                        color="#2c6ddd"
                        height={25}
                        width={25}
                        style={{
                          display: "inline-block",
                          padding: "10px",
                        }}
                      />
                      <span
                        style={{
                          display: "inline-block",
                          padding: "10px",
                        }}
                      >
                        <h4> Searching Rad Test...</h4>
                      </span>
                    </div>
                  ) : searchQuery && !itemFoundSuccessfull ? (
                    <div style={{ textAlign: "center", padding: "10px" }}>
                      <h4>No Rad Test Found !</h4>
                    </div>
                  ) : (
                    undefined
                  )}
                </Paper>
              </div>
            ) : (
              undefined
            )} 
            
            <div className="row row-5 mt-4">
              {
                isLoading ? <div className="col-md-12"><div className="alert alert-success">Profiles are loading! Please wait...</div></div> :
                categoriesData ? 
                  categoriesData.map((d, index) => {
                    return (
                      <div className="col-12 col-md-3 text-center">
                        <div className="mt-3">
                          <button className="pl-2 pr-2 btn btn-block bg-white dropdown-toggle" 
                            type="button" id={ "dropdownMenuButton-" + (index + 1) } 
                            onClick={() => toggleDropdownMenu(d._id)}
                            style={{ height: 65 }}
                            aria-haspopup="true" aria-expanded="false">
                            <span style={{ ...styles.textStyles, "white-space": "initial" }}>{ d._id }</span>
                          </button>
                          <div className={classNames(`dropdown-menu w-100 p-0`, dropdownOpened(d._id))} aria-labelledby={ "dropdownMenuButton-" + (index + 1) }>
                            <div className="searchField bg-light">
                              <input type="text" placeholder="Enter keyword to search..."
                              value={newSearchTerm}
                              onChange={(event) => {
                                dispatch({field: 'newSearchTerm', value: event.target.value})
                              }} />
                            </div>
                            <div style={{ height: 250, overflowY: "scroll"}}>
                            {
                             d.items.length > 0 ? 
                                d.items.filter((val) => {
                                  if (newSearchTerm && isDropdownOpened(d._id)){
                                    return val.name.toLowerCase().includes(newSearchTerm.toLowerCase())
                                  }
                                  return val;
                                }).map((nd, nindex) => {
                                  return (
                                    <div className="dropdown-items pl-1 pr-3 d-flex align-items-center justify-content-between">
                                      <FormControlLabel
                                        control={<GreenCheckbox checked={ itemsExistInDefaultSelection( uniqueCodeField(d._id), nd._id )  } value={ nd._id } onChange={handleChange} name={ uniqueCodeField(d._id) } />}
                                        label={nd.name}
                                        style={{
                                          margin: 0,
                                        }}
                                      />
                                      
                                    </div>
                                  )
                                }) : ''
                            }
                            </div>
                            <div className="p-3">
                              <div className="row row-5">
                                <div className="col-6">
                                  <strong>Priority:</strong> 
                                  <FormControl variant="filled" className="mServiceDropdown" InputProps={{ disableUnderline: true }}>
                                    <Select
                                      name={"labPriority"}
                                      value={labPriorityDefaultSelection( d._id).priority}
                                      InputProps={{ disableUnderline: true }}
                                      onChange={(e) => {
                                        onChangeDropDownPriorityValue(e.target.value, d._id, 'priority')
                                      }}
                                    >
                                      {priorityArray.map((val) => {
                                        return (
                                          <MenuItem key={val.key} value={val.key}>
                                            {val.value}
                                          </MenuItem>
                                        );
                                      })}
                                    </Select>
                                  </FormControl>
                                </div>
                              </div>
                            </div>
                            <div className="p-2 bg-light">
                              <Button
                                color="primary"
                                size="small"
                                onClick={() => addSelectedItems(uniqueCodeField(d._id))}
                                variant="contained"
                                fullWidth="true"
                              >Add Selected</Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })
                  : ''
              }
            </div>

            <div style={{ display: "flex", justifyContent: "space-between", marginTop: 20 }}>
              <div style={{ marginTop: "2%", marginBottom: "2%" }}>
                <Button
                  onClick={() => {
                    // Cancel
                    props.history.push({
                      pathname: "/dashboard/home/"+episodeId+"/rad",
                    });
                  }}
                  style={{
                    ...styles.stylesForButton,
                    backgroundColor: "white",
                    color: "grey",
                    width: matches ? " " : 150,
                  }}
                  variant="contained"
                >
                  <strong>Cancel</strong>
                </Button>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: "2%",
                  marginBottom: "2%",
                }}
              >
                <Button
                  style={{
                    ...styles.stylesForSubmitButton,
                  }}
                  variant="contained"
                  color="primary"
                >Submit</Button>
              </div>
            </div>
          </>
          :undefined
        }
      </div>
      <Notification
        msg={errorMsg}
        open={openNotification}
        success={successMsg}
      />
    </div>
  );
}