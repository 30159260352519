import { Redirect } from 'react-router-dom'
import cookie from 'react-cookies'
import Header from '../../../../components/Header/Header'
import PatientLog from '../../../../assets/img/Patient Log.png'
import ED from '../../../../assets/img/ED.png'
import EOU from '../../../../assets/img/EOU.png'
import React from 'react'
import Back from '../../../../assets/img/Back_Arrow.png'
import MenuTree from '../../../../components/MenuTree/MenuTree'

const Management = [
  { img: PatientLog, text: 'Patient Log' },
  {
    img: ED,
    text: 'ED',
    path: '/dashboard/home/patientlog/ED',
  },
  {
    img: EOU,
    text: 'EOU',
    path: '/dashboard/home/patientlog/EOU',
  },
  {
    img: EOU,
    text: 'Discharge',
    path: '/dashboard/home/sensei/discharge/',
  },
]

class ManagementComponent extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      openApps: false,
      goBack: false,

      currentUser: '',
    }
  }

  componentDidMount() {
    this.setState({ currentUser: cookie.load('current_user') })
  }

  render() {
    if (this.state.goBack) {
      return <Redirect to={'/management'} />
    }

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          position: 'fixed',
          width: '100%',
          height: '100%',
          background:
            'linear-gradient(25deg, rgba(101,228,193,1) 0%, rgba(58,219,175,1) 33%, rgba(15,206,147,1) 66%, rgba(6,142,103,1) 100%)',
          backgroundSize: '100%',
        }}
      >
        <Header history={this.props.history} />

        <MenuTree history={this.props.history} options={Management} />

        <div
          style={{
            position: 'fixed',
            width: '100%',
            height: '10%',
            top: '90%',
          }}
        >
          <img
            alt='Back'
            onClick={() => this.props.history.goBack()}
            src={Back}
            style={{
              width: 45,
              height: 35,
              marginLeft: '5%',
              cursor: 'pointer',
            }}
          />
        </div>
      </div>
    )
  }
}

export default ManagementComponent
