import axios from "axios";
import {
  getAllCompletedFlag,
  getAllPendingFlag,
  getFlagCount,
  getFlagPatientByKeyword,
  updateFlag,
} from "../../public/endpoins";

export const getFlagCountCall = (
  setFlagCount,
  setErrorMsg,
  setOpenNotification
) => {
  // axios
  //   .get(getFlagCount)
  //   .then((response) => {
  //     console.log("Response Count", response.data)
  //     setFlagCount(response.data.data)
  //   })
  //   .catch((error) => {
  //     console.log("Error", error)
  //     setOpenNotification(true)
  //     setErrorMsg(error)
  //   })
};

export const getAllPendingFlagCall = (
  params,
  setPendingGeneralArray,
  setErrorMsg,
  setOpenNotification
) => {
  console.log("params", params);
  axios
    .put(getAllPendingFlag, params)
    .then((response) => {
      console.log("Response Pending", response.data.data);
      response.data.data.map(
        (flag) => (
          (flag.mrn = flag.edrId
            ? flag.edrId.patientId.identifier[0].value
            : flag.patientId
            ? flag.patientId.identifier[0].value
            : "---"),
          // (flag.type = flag.generatedBy.subType[0]),
          // (flag.staff =
          //   flag.generatedBy.name[0].given[0] +
          //   " " +
          //   flag.generatedBy.name[0].family),
          (flag.name = flag.edrId
            ? flag.edrId.patientId.name[0].given[0] +
              " " +
              flag.edrId.patientId.name[0].family
            : flag.patientId
            ? flag.patientId.name[0].given[0] +
              " " +
              flag.patientId.name[0].family
            : "-- --")
        )
      );
      setPendingGeneralArray(response.data.data);
    })
    .catch((error) => {
      console.log("Error", error);
      setOpenNotification(true);
      setErrorMsg(error);
    });
};

export const updateFlagCall = (params) => {
  console.log("params", params);
  axios
    .put(updateFlag, params)
    .then((response) => {
      console.log(response, "update api");
      window.location.reload();
    })
    .catch((error) => {
      console.log("Error", error);
      // setOpenNotification(true)
      // setErrorMsg(error)
    });
};

export const getAllCompletedFlagCall = (
  params,
  setCompletedGeneralArray,
  setErrorMsg,
  setOpenNotification
) => {
  axios
    .put(getAllCompletedFlag, params)
    .then((response) => {
      response.data.data.map(
        (flag) => (
          (flag.mrn = flag.edrId
            ? flag.edrId.patientId.identifier[0].value
            : flag.patientId
            ? flag.patientId.identifier[0].value
            : "---"),
          // (flag.type = flag.generatedBy.subType[0]),
          // (flag.staff =
          //   flag.generatedBy.name[0].given[0] +
          //   " " +
          //   flag.generatedBy.name[0].family),
          (flag.name = flag.edrId
            ? flag.edrId.patientId.name[0].given[0] +
              " " +
              flag.edrId.patientId.name[0].family
            : flag.patientId
            ? flag.patientId.name[0].given[0] +
              " " +
              flag.patientId.name[0].family
            : "-- --")
        )
      );

      console.log("Response Completed", response.data);
      setCompletedGeneralArray(response.data.data);
    })
    .catch((error) => {
      console.log("Error", error);
      setOpenNotification(true);
      setErrorMsg(error);
    });
};

export const getFlagPatientByKeywordCall = (
  a,
  setCompletedGeneralArray,
  setPendingGeneralArray,
  setErrorMsg,
  setOpenNotification
) => {
  axios
    .get(`${getFlagPatientByKeyword}/${a}`)
    .then((response) => {
      response.data.data.map(
        (flag) => (
          (flag.mrn = flag.edrId.patientId.identifier[0].value),
          (flag.type = flag.generatedBy.subType[0]),
          (flag.staff =
            flag.generatedBy.name[0].given[0] +
            " " +
            flag.generatedBy.name[0].family),
          (flag.name =
            flag.edrId.patientId.name[0].given[0] +
            " " +
            flag.edrId.patientId.name[0].family)
        )
      );

      console.log("Searched", response.data);
      setCompletedGeneralArray(response.data.data);
      setPendingGeneralArray(response.data.data);
    })
    .catch((error) => {
      console.log("Error", error);
      setOpenNotification(true);
      setErrorMsg(error);
    });
};
