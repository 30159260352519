import React, { useState, useEffect, useReducer, forwardRef } from "react";
import Notification from "../../components/Snackbar/Notification.js";
import CustomTable from "../../components/Table/Table";
import axios from "axios";
import _ from "lodash";
import Button from "@material-ui/core/Button";
import {
  pendingEdrRegistration,
  SearchPendingRegistration,
  SearchInRegistration,
  AssignEdrToPatient
} from "../../public/endpoins";
import Loader from "react-loader-spinner";
import Back from "../../assets/img/Back_Arrow.png";
import Header from "../../components/Header/Header";
import Pending_Patients from "../../assets/img/PendingPatients.png";
import ButtonField from "../../components/common/Button";
import Rad_OPR from "../../assets/img/RR.png";
import Pharmacist_OPR from "../../assets/img/PHR.png";
import Fingerprint from "../../assets/img/fingerprint.png";
import AccountCircle from "@material-ui/icons/SearchOutlined";
import InputAdornment from "@material-ui/core/InputAdornment";
import BarCode from "../../assets/img/Bar Code.png";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import "../../assets/jss/material-dashboard-react/components/loaderStyle.css";
import socketIOClient from "socket.io-client";
import cookie from "react-cookies";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import QRCodeScannerComponent from "../../components/QRCodeScanner/QRCodeScanner";
import { displayInHumanDifference, replacePatientIdIfNull } from "../../utils/commonFunc";
import MCloseIcon from '@material-ui/icons/Close';
import { 
  Dialog, DialogActions, DialogTitle, DialogContent, DialogContentText,
  AppBar, Toolbar, IconButton, Slide, Typography,
  Paper, Table, TableHead, TableRow, TableBody, TableCell
} from '@material-ui/core';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const tableHeading = [
  "Episode#",
  "Patient Name",
  "Chief Complaint",
  "Production Area",
  "Bed",
  "Date/Time",
  "Action",
];
const tableDataKeys = [
  "mrn",
  "patientName",
  "chiefComplaintName",
  "productionRoomName",
  "roomNumber",
  "createdTimeStamp",
];

const styles = {
  textFieldPadding: {
    paddingLeft: 5,
    paddingRight: 5,
  },
};

const useStylesForInput = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(0),
  },
  input: {
    backgroundColor: "white",
    borderRadius: 5,
    "&:after": {
      borderBottomColor: "black",
    },
    "&:hover": {
      backgroundColor: "white",
    },
    "&:focus": {
      boxShadow: "none",
      borderRadius: 5,
    },
  },
  multilineColor: {
    backgroundColor: "white",
    borderRadius: 5,
    "&:hover": {
      backgroundColor: "white",
    },
    "&:after": {
      borderBottomColor: "black",
    },
    "&:focus": {
      backgroundColor: "white",
      boxShadow: "none",
    },
  },
  root: {
    "& .MuiTextField-root": {
      backgroundColor: "white",
    },
    "& .Mui-focused": {
      backgroundColor: "white",
      color: "black",
    },
    "& .Mui-disabled": {
      backgroundColor: "white",
      color: "gray",
    },
    "&:focus": {
      backgroundColor: "white",
      boxShadow: "none",
    },
    "& .MuiFormLabel-root": {
      fontSize: "11px",

      paddingRight: "45px",
    },
  },
  label: {
    "&$focusedLabel": {
      color: "red",
      display: "none",
    },
    // "&$erroredLabel": {
    //   color: "orange"
    // }
  },
  focusedLabel: {},
}));

const actions = { assign: true };

export default function PendingEpisodeRegistration(props) {
  const matches = useMediaQuery("(min-width:600px)");
  const classes = useStylesForInput();

  const [patientList, setPatientList] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setsuccessMsg] = useState('')
  const [openNotification, setOpenNotification] = useState(false);
  const [searchPatientQuery, setSearchPatientQuery] = useState("");

  const [QRCodeScanner, setQRCodeScanner] = useState(false);
  const [isAssignDialogOpen, setAssignDialogOpen] = useState(false);
  const [searchAssignPatientQuery, setAssignSearchPatientQuery] = useState("");
  const [patientAssignList, setPatientAssignList] = useState([]);
  const [patientAssignFoundSuccessfull, setPatientAssignFoundSuccessfull] = useState(false);
  const [loadAssignSearchedData, setLoadAssignSearchedData] = useState(false);
  const [patientEdrRequest, setPatientEdrRequest] = useState(null);

  if (openNotification) {
    setTimeout(() => {
      setOpenNotification(false);
      setErrorMsg("");
    }, 5000);
  }

  useEffect(() => {
    console.log("props::", props);
    if (
      props.location.state &&
      props.location.state.comingFrom === "notification"
    ) {
      setSearchPatientQuery(props.location.state.edrId);
      if (props.location.state.edrId.length >= 3) {
        axios
          .get(pendingEdrRegistration + "/" + props.location.state.edrId)
          .then((res) => {
            console.log("res", res);
            if (res.data.success) {
              if (res.data.data) {
                console.log("patient dataaa", res.data.data);
                res.data.data.map((d) => {
                  d = replacePatientIdIfNull(d);
                  d.patientName = d.patientId.name[0].given[0] + " " + d.patientId.name[0].family;
                  d.mrn = d.requestNo.trim();
                  d.chiefComplaintName = d.chiefComplaint.length>0?d.chiefComplaint[d.chiefComplaint.length - 1].chiefComplaintId.short_desc:undefined;
                  if (d.chiefComplaintName === "Others")
                  {

                  }
                  d.productionRoomName = d.room && d.room.length > 0 ? d.room[d.room.length - 1].productionRoomId.paName:undefined;
                  d.roomNumber = d.room && d.room.length > 0 ? d.room[d.room.length - 1].roomId.roomNo:undefined;
                  return d;
                });
                // var sortedObjs2 = _.sortBy(
                //   res.data.data,
                //   "createdAt"
                // ).reverse();
                setPatientList(res.data.data);
                // var sortedObjs = _.sortBy(res.data.data, 'date').reverse()
                // setPatientList(res.data.data)
              } else {
                setPatientList(" ");
              }
            }
          })
          .catch((e) => {
            console.log("error after searching patient request", e);
          });
      } else if (props.location.state.edrId.length == 0) {
        getPatientData();
      }
    } else {
      getPatientData();
    }

    // return () => socket.disconnect();
  }, []);
  /*
  useEffect(() => {
    if (e.length >= 3) {
      axios
        .get(pendingEdrRegistration + "/" + e)
        .then((res) => {
          if (res.data.success) {
            if (res.data.data.length > 0) {
              console.log(res.data.data);
              // let response = res.data.data.map((edr) => {
              //   return { ...edr.patientId };
              // });

              res.data.data.map((d) => {
                d = replacePatientIdIfNull(d);
                d.patientName = d.patientId.name[0].given[0] + " " + d.patientId.name[0].family;
                d.mrn = d.requestNo.trim();
                d.chiefComplaintName = d.chiefComplaint.length>0?d.chiefComplaint[d.chiefComplaint.length - 1].chiefComplaintId.short_desc:undefined;
                d.productionRoomName = d.room && d.room.length > 0 ? d.room[d.room.length - 1].productionRoomId.paName:undefined;
                d.roomNumber = d.room && d.room.length > 0 ? d.room[d.room.length - 1].roomId.roomNo:undefined;
                return d;
              });
              let response = res.data.data;
              console.log(response);

              setpatientFoundSuccessfully(true);
              setpatientFound(response);
              setLoadSearchedData(false);
            } else {
              setpatientFoundSuccessfully(false);
              setpatientFound("");
              setLoadSearchedData(false);
            }
          }
        })
        .catch((e) => {
          console.log("error after searching patient request", e);
        });
    }
  })*/

  // const handleEdit = (rec) => {
  // };

  function getPatientData() {
    axios
      .get(pendingEdrRegistration)
      .then((res) => {
        if (res.data.success) {
          console.log("DataFucked::", res.data.data);
          res.data.data.map((d) => {
            d = replacePatientIdIfNull(d);
            d.patientName = d.patientId.name[0].given[0] + " " + d.patientId.name[0].family;
            d.mrn = d.requestNo.trim();
            d.chiefComplaintName = d.chiefComplaint.length>0?d.chiefComplaint[d.chiefComplaint.length - 1].chiefComplaintId.short_desc:undefined;
            if (
              d.chiefComplaintName === "Others" && 
              (
                d.chiefComplaint[d.chiefComplaint.length - 1].chiefComplaintOthers && d.chiefComplaint[d.chiefComplaint.length - 1].chiefComplaintOthers.length > 0
              ) 
            )
            {
              d.chiefComplaintName = d.chiefComplaint[d.chiefComplaint.length - 1].chiefComplaintOthers[0].short_desc;
            }
            d.productionRoomName = d.room && d.room.length > 0 ? d.room[d.room.length - 1].productionRoomId.paName:undefined;
            d.roomNumber = d.room && d.room.length > 0 ? d.room[d.room.length - 1].roomId.roomNo:undefined;
            return d;
          });
          setPatientList(res.data.data);
          // setPatientList(res.data.data)
        } else if (!res.data.success) {
          setErrorMsg(res.data.error);
          setOpenNotification(true);
        }
        return res;
      })
      .catch((e) => {
        console.log("error: ", e);
      });
  }

  function handleAssign(rec) {
    let path = `patientregistration`
    console.log("handleAssign::", rec);
    setPatientEdrRequest(rec);
    // setAssignDialogOpen(true);
    
    props.history.push({
      pathname: path,
      state: {
        edrId: rec._id,
        episodeNumber: rec.requestNo,
        comingFor: "edrAssign",
      },
    });
    
  }

  const handleAssignDialogClose = () => {
    setAssignDialogOpen(false);
    setAssignSearchPatientQuery("");
    setPatientAssignList([]);
    setPatientAssignFoundSuccessfull(false);
    setLoadAssignSearchedData(false);
    setPatientEdrRequest(null);
  };

  const handlePatientSearch = (e) => {
    const a = e.target.value.replace(/[^\w+\s]/gi, "");
    setSearchPatientQuery(a);
    if (a.length >= 3) {
      axios
        .get(SearchPendingRegistration + "/" + a)
        .then((res) => {
          console.log("res", res);
          if (res.data.success) {
            if (res.data.data) {
              console.log("patient dataaa", res.data.data);
              res.data.data.map(
                (d) =>
                  (d.patientName = d.name[0].given[0] + " " + d.name[0].family)
              );
              res.data.data.map((d) => (d.mrn = d.identifier[0].value));
              res.data.data.map((d) => {
                d.telecom.map((a) => {
                  if (a.system === "phone") {
                    return (d.phoneNo = a.value);
                  }
                });
              });
              var sortedObjs2 = _.sortBy(res.data.data, "createdAt").reverse();
              setPatientList(sortedObjs2);
              // var sortedObjs = _.sortBy(res.data.data, 'date').reverse()
              // setPatientList(res.data.data)
            } else {
              setPatientList(" ");
            }
          }
        })
        .catch((e) => {
          console.log("error after searching patient request", e);
        });
    } else if (a.length == 0) {
      getPatientData();
    }
  };

  const handleAssignPatientSearch = (e) => {
    const a = e.target.value.replace(/[^\w+\s]/gi, "");
    setAssignSearchPatientQuery(a);
    if (a.length >= 2) {
      let searchInfo = SearchInRegistration + "/?searchIn=" + a;
      console.log('Search Results', searchInfo)
      axios
        .get(searchInfo)
        .then((res) => {
          console.log('Search Results', res.data.data)
          if (res.data.success) {
            if (res.data.data) {
              console.log('Search Results', res.data.data);
              setPatientAssignList(res.data.data);
              setPatientAssignFoundSuccessfull(true);
            } else {
              setPatientAssignList([]);
              setPatientAssignFoundSuccessfull(false);
            }
          }
        })
        .catch((e) => {
          console.log("Search Results", "error after searching patient request", e);
        });
    } else if (a.length == 0) {
      setAssignSearchPatientQuery("");
      setPatientAssignFoundSuccessfull(false);
      setPatientAssignList([]);
    }
  };

  function handleAssignToPatient(i) {
    if ( window.confirm(`Are you sure to Associate Episode #${patientEdrRequest.requestNo} to ${i.completeName}?\n\nNote: This action will not revert.`) ){
      axios
        .post(AssignEdrToPatient, {
          'edrId': patientEdrRequest._id,
          'patientId': i._id
        })
        .then((res) => {
          if (res.data.success) {
            console.log("res::", res);
            getPatientData();
            handleAssignDialogClose();
            setOpenNotification(true);
            setsuccessMsg(res.data.message); 
            props.history.push({
              pathname: "/dashboard/home/pendingepisode/success",
              state: {
                code: `${res.data.message}`,
                edrId: patientEdrRequest._id,
                selectedItem: res.data.data
              },
            });
          }
          /*
          if (res.data.success) {
            if (res.data.data) {
              console.log("patient dataaa", res.data.data);
              res.data.data.map(
                (d) =>
                  (d.patientName =
                    d.name[0].given[0] + " " + d.name[0].family)
              );
              res.data.data.map((d) => (d.mrn = d.identifier[0].value));
              res.data.data.map((d) => {
                d.telecom.map((a) => {
                  if (a.system === "phone") {
                    return (d.phoneNo = a.value);
                  }
                });
              });
              var sortedObjs2 = _.sortBy(
                res.data.data,
                "createdAt"
              ).reverse();
              setPatientList(sortedObjs2);
              // var sortedObjs = _.sortBy(res.data.data, 'date').reverse()
              // setPatientList(res.data.data)
            } else {
              setPatientList(" ");
            }
          }
          */
        })
        .catch((e) => {
          console.log("error after searching patient request", e);
        });
    }
  }

  function scanQRCode() {
    setQRCodeScanner((pervState) => !pervState);
  }

  function handleScanQR(data) {
    setQRCodeScanner(false);
    console.log(JSON.parse(data));
    if (JSON.parse(data).profileNo) {
      handlePatientSearch({
        target: {
          value: JSON.parse(data).profileNo,
          type: "text",
        },
      });
    } else if (JSON.parse(data).insuranceCardNumber) {
      // dispatch({
      //   field: "insuranceNo",
      //   value: JSON.parse(data).insuranceCardNumber,
      // });
    }
  }

  if (QRCodeScanner) {
    return (
      <div>
        {QRCodeScanner ? (
          <QRCodeScannerComponent
            handleScanQR={handleScanQR}
            scanQRCode={scanQRCode}
          />
        ) : (
          undefined
        )}
      </div>
    );
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        position: "fixed",
        width: "100%",
        height: "100%",
        backgroundColor: "rgb(19 213 159)",
        overflowY: "scroll",
      }}
    >
      <Header history={props.history} />

      <div className="cPadding">
        <div className="subheader">
          <div>
            <img src={Pending_Patients} />
            <h4>Pending Episode Registration</h4>
          </div>
          <div>
            
          </div>
        </div>

        <div
          className={`${"container-fluid"} ${classes.root}`}
          style={{
            marginTop: "25px",
            paddingLeft: "10px",
            paddingRight: "10px",
          }}
        >
          <div className="row">
            <div
              className="col-md-10 col-sm-9 col-8"
              style={{
                ...styles.textFieldPadding,
                paddingRight: matches ? 5 : 0,
              }}
            >
              <TextField
                className="textInputStyle"
                id="searchPatientQuery"
                type="text"
                variant="filled"
                label="Search Patient by Episode Number"
                name={"searchPatientQuery"}
                value={searchPatientQuery}
                onChange={handlePatientSearch}
                InputLabelProps={{
                  classes: {
                    root: classes.label,
                    focused: classes.focusedLabel,
                    error: classes.erroredLabel,
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <AccountCircle />
                    </InputAdornment>
                  ),
                  className: classes.input,
                  classes: { input: classes.input },
                  disableUnderline: true,
                }}
              />
            </div>

            <div
              className="col-md-1 col-sm-2 col-2"
              style={{
                ...styles.textFieldPadding,
                paddingRight: matches ? 5 : 3,
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "white",
                  borderRadius: 5,
                  height: 55,
                }}
              >
                <img
                  src={BarCode}
                  onClick={scanQRCode}
                  style={{
                    width: matches ? 70 : 52,
                    height: matches ? 60 : 58,
                  }}
                />
              </div>
            </div>

            <div
              className="col-md-1 col-sm-1 col-2"
              style={{
                ...styles.textFieldPadding,
                paddingLeft: matches ? 5 : 2,
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "white",
                  borderRadius: 5,
                  height: 55,
                }}
              >
                <img src={Fingerprint} style={{ maxWidth: 43, height: 43 }} />
              </div>
            </div>
          </div>
        </div>

        <div
          style={{
            flex: 4,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div>
            {patientList !== " " ? (
              <div>
                <div>
                  <CustomTable
                    tableData={patientList}
                    tableDataKeys={tableDataKeys}
                    tableHeading={tableHeading}
                    action={actions}
                    handleAssign={handleAssign}
                    // handleView={handleView}
                    borderBottomColor={"#60d69f"}
                    borderBottomWidth={20}
                  />
                </div>
                <div style={{ marginTop: 20, marginBottom: 20 }}>
                  <img
                    onClick={() => props.history.goBack()}
                    src={Back}
                    style={{
                      width: 45,
                      height: 35,
                      cursor: "pointer",
                    }}
                  />
                </div>
              </div>
            ) : (
              <div className="row " style={{ marginTop: "25px" }}>
                <div className="col-11">
                  <h3
                    style={{
                      color: "white",
                      textAlign: "center",
                      width: "100%",
                      position: "absolute",
                    }}
                  >
                    Opps...No Data Found
                  </h3>
                </div>
                <div className="col-1" style={{ marginTop: 45 }}>
                  <img
                    onClick={() => props.history.goBack()}
                    src={Back}
                    style={{
                      maxWidth: "60%",
                      height: "auto",
                      cursor: "pointer",
                    }}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>


      <Notification msg={errorMsg} open={openNotification} success={successMsg} />

      <Dialog fullScreen open={isAssignDialogOpen} onClose={handleAssignDialogClose} TransitionComponent={Transition}>
        <AppBar className={classes.dialogAppBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleAssignDialogClose} aria-label="close">
              <MCloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.dialogTitle}>Assign / New Profile</Typography>
          </Toolbar>
        </AppBar>
        <DialogContent
          style={{
            backgroundColor: "rgb(19 213 159)",
          }}>
          <DialogContentText><br /><br /><br /></DialogContentText>
          <TextField
            className="textInputStyle"
            id="searchAssignPatientQuery"
            type="text"
            variant="filled"
            label="Search Patient by Name / MRN / Phone Number / Email Address"
            name={"searchAssignPatientQuery"}
            value={searchAssignPatientQuery}
            onChange={handleAssignPatientSearch}
            InputLabelProps={{
              classes: {
                // root: classes.label,
                // focused: classes.focusedLabel,
                // error: classes.erroredLabel,
              },
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <AccountCircle />
                </InputAdornment>
              ),
              className: classes.input,
              classes: { input: classes.input },
              disableUnderline: true,
            }}
          />

          {searchAssignPatientQuery ? (
            <div
              className="searchAssignPatienQueryPage"
              style={{
                zIndex: 3,
                marginTop: 5,
              }}
            >
              <Paper style={{ maxHeight: 300, overflow: "auto" }}>
                {patientAssignFoundSuccessfull && patientAssignList !== "" ? (
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>MRN</TableCell>
                        <TableCell>Patient Name</TableCell>
                        <TableCell>Gender</TableCell>
                        <TableCell>Age</TableCell>
                        <TableCell>Document</TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {patientAssignList.map((i) => {
                        return (
                          <TableRow
                            key={i._id}
                            onClick={() => handleAssignToPatient(i)}
                            style={{ cursor: "pointer" }}
                          >
                            <TableCell>{i.mrn}</TableCell>
                            <TableCell>{i.completeName}</TableCell>
                            <TableCell>{i.gender}</TableCell>
                            <TableCell>{i.age}</TableCell>
                            <TableCell>
                              {i.identityType=="passport"?
                                "Passport Number" : (
                                  i.identityType=="national-id" ? 
                                    "National ID Number" : 
                                    i.identityType=="driving-license" ? 
                                    "Driving License Number" :
                                      i.identityType=="refugee" ? 
                                        "Refugee #" :
                                        "ID Document Number"
                                )} / {i.nationalID}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                ) : loadAssignSearchedData ? (
                  <div style={{ textAlign: "center" }}>
                    <Loader
                      type="TailSpin"
                      color="#2c6ddd"
                      height={25}
                      width={25}
                      style={{ display: "inline-block", padding: "10px" }}
                    />
                    <span
                      style={{ display: "inline-block", padding: "10px" }}
                    >
                      <h4>Searching Patient...</h4>
                    </span>
                  </div>
                ) : searchAssignPatientQuery && !patientAssignFoundSuccessfull ? (
                  <div style={{ textAlign: "center", padding: "10px" }}>
                    <h4> No Patient Found !</h4>
                  </div>
                ) : (
                  undefined
                )}
              </Paper>
            </div>
          ) : (
            undefined
          )}
        </DialogContent>
      </Dialog>


    </div>
  );
}
