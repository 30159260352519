/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable array-callback-return */
/* eslint-disable react/jsx-indent */
import { FaUpload } from "react-icons/fa";
import React, { useEffect, useState, useReducer } from 'react'
import { makeStyles, Checkbox, Button, Dialog, DialogContent, DialogContentText, DialogTitle, MenuItem, FormControl, Select, TextField, FormLabel, RadioGroup, Radio, FormControlLabel, InputAdornment, Tabs, Tab } from '@material-ui/core'
import axios from 'axios'
import {
  audioURL,
  getSingleIPRPatient,
  getLabRequest,
  getRadEdrRequest,
  updateLabSampleInsuranceRequest,
  updateRadSampleInsuranceRequest
} from '../../../public/endpoins'
import classNames from "classnames";
import cookie from 'react-cookies'
import Header from '../../../components/Header/Header'
import PreApproval from '../../../assets/img/Pre-Approval.png'
import Back from '../../../assets/img/Back_Arrow.png'
import '../../../assets/jss/material-dashboard-react/components/TextInputStyle.css'
import CustomTable from '../../../components/Table/Table'
import Notification from '../../../components/Snackbar/Notification.js'
import Loader from 'react-loader-spinner'
import useMediaQuery from "@material-ui/core/useMediaQuery";
import PatientDetailsQuick from "../../../components/PatientDetails/PatientDetailsQuick";
import PatientDetails from "../../../components/PatientDetails/PatientDetails";
import PatientDetails2 from "../../../components/PatientDetails/PatientDetailsDirect";
import { displayInHumanDifference, replacePatientIdIfNull } from "../../../utils/commonFunc";
import moment from 'moment';

const tableHeadingForNeedApproval = [
  'Request No',
  'Request Type',
  'Item',
  'Total Cost (JD)',
  'Status',
  'Insurance',
  'Action',
]
const tableDataKeysForNeedApproval = [
  'requestId',
  'RequestType',
  'name',
  'totalCost',
  'status',
  'insurance',
]
const actions = { view: true }

const styles = {
  patientDetails: {
    backgroundColor: 'white',
    borderRadius: 5,
    padding: '20px',
  },
  headingStyles: {
    fontWeight: 'bold',
    color: 'grey',
    fontSize: 12,
  },
  textStyles: {
    fontWeight: '700',
    color: 'black',
    fontSize: 14,
  },
  upload: {
    backgroundColor: "#E2E2E2",
    border: "0px solid #ccc",
    borderRadius: "5px",
    color: "gray",
    width: "100%",
    height: "60px",
    cursor: "pointer",
    padding: "15px",
  },
  input: {
    display: "none",
  },
}

const useStylesForTabs = makeStyles({
  root: {
    justifyContent: 'center',
  },
  scroller: {
    flexGrow: '0',
  },
})

function ViewPreApprovalList(props) {
  const classesForTabs = useStylesForTabs()
  const matches = useMediaQuery("(min-width:600px)");

  const initialState = {
    serviceIds: [],
    diagnosisArray: [],
    medicationArray: [],
  }

  function reducer(state, { field, value }) {
    return {
      ...state,
      [field]: value,
    }
  }

  const [state, dispatch] = useReducer(reducer, initialState)

  const {
    serviceIds,
    diagnosisArray,
    medicationArray,
  } = state

  const [currentUser, setCurrentUser] = useState(cookie.load("current_user"));
  const [errorMsg, setErrorMsg] = useState('')
  const [successMsg, setsuccessMsg] = useState("");
  const [openNotification, setOpenNotification] = useState(false)
  const [value, setValue] = useState(0)
  const [selectedItem, setSelectedItem] = useState('')
  const [selectedPatient, setSelectedPatient] = useState('')
  const [Id, setId] = useState('')

  const [patientDetails, setPatientDetails] = useState("");
  const [selectedRecord, setSelectedRecord] = useState({});
  const [ dialogBoxInsurance, setDialogBoxInsurance ] = useState(false);
  const [ openDialogSubmit, setOpenDialogSubmit ] = useState(false);
  const [ newLabServiceStatus, setNewLabServiceStatus ] = useState('');
  const [ insuranceApprovalNo, setInsuranceApprovalNo ] = useState("");

  const [ selfConsentFileObject, setSelfConsentFileObject ] = useState({
    title: 'Consent to Upload',
  });

  const [ selfConsentFile, setSelfConsentFile ] = useState("");

  const [needApprovalArray, setneedApprovalArray] = useState([
    // {
    //   id: '1',
    //   RequestType: 'Loreum',
    //   serviceName: 'Loreum',
    //   totalCost: 'Loreum',
    //   status: 'pending',
    //   insurance: 'Loreum',
    // }
  ])

  const [isLoading, setIsLoading] = useState(true)
  const [ episodeId, setEpisodeId ] = useState(props.match.params.id);
  const [ preApprovalType, setPreApprovalType ] = useState(props.match.params.preApprovalType);
  const [ labRequestId, setLabRequestId ] = useState(props.match.params.labRadId);
  const [ labServices, setLabServices ] = useState([]);
  const [ labRequest, setLabRequest ] = useState({});

  const [ testsStatus, setTestsStatus ] = useState(false);


  const [ errorFileConsentMsg, setErrorFileConsentMsg ] = useState("");
  const [ disableButtonIfSelected, setDisableButtonIfSelected ] = useState(true);

  // getLabRequest

  useEffect(() => {

    // const selectedRec = props.history.location.state.selectedItem
    console.log('Record::', episodeId, labRequestId);

    setId(episodeId)

    if (labRequestId !== ""){

      let reqNewURL = `${getLabRequest}/${labRequestId}`;
      if ( preApprovalType === "radpreapproval" ){
        reqNewURL = `${getRadEdrRequest}/${labRequestId}`;
      }

      axios
      .get( reqNewURL )
      .then((res) => {
        if (res.data.success) {
          if (res.data.data) {

            let dataR = res.data.data[0];

            let remoteRecord = replacePatientIdIfNull(dataR);
            setSelectedRecord(remoteRecord);
            console.log('Record::data::remoteRecord::', remoteRecord);

            const object = {
              '_id': remoteRecord._id,
              patientName: remoteRecord.patientId.name[0].given[0] + " " + remoteRecord.patientId.name[0].family,
              episodeNumber: remoteRecord.requestNo,//remoteRecord.patientId.identifier[0].value,
              mrn: remoteRecord.patientId.identifier[0].value,
              age: remoteRecord.patientId.age,
              gender: remoteRecord.patientId.gender,
              weight: remoteRecord.patientId.weight,
              department: remoteRecord.currentLocation,
              // chiefComplaint: remoteRecord.chiefComplaint.length > 0 ? remoteRecord.chiefComplaint.pop().chiefComplaintId.short_desc : "N/A",
              // chiefComplaintOthers: remoteRecord.chiefComplaintOthers,
            };
            setPatientDetails(object);

            if ( preApprovalType === "radpreapproval" ) 
            {
              dataR.radRequest.requestedName = dataR.radRequest.requestedBy.name[0].given[0] + " " + dataR.radRequest.requestedBy.name[0].family;
              setLabRequest(dataR.radRequest);

              res.data.data.map((val) => {
                // Services
                let servicesNames = [];
                Object.entries(val.radRequest.services).map(([key, val]) => {
                  servicesNames.push(val.name)
                });
                val.serviceName = servicesNames.join(',');
              });
              if (dataR.radRequest){
                setTestsStatus( (dataR.radRequest.urgent==="Yes"?true:false) )  ;
                setLabServices(dataR.radRequest.services);
              }
            } else {
              dataR.labRequest.requestedName = dataR.labRequest.requestedBy.name[0].given[0] + " " + dataR.labRequest.requestedBy.name[0].family;
              setLabRequest(dataR.labRequest);

              res.data.data.map((val) => {
                // Services
                let servicesNames = [];
                Object.entries(val.labRequest.services).map(([key, val]) => {
                  servicesNames.push(val.name)
                });
                val.serviceName = servicesNames.join(',');
              });
              if (dataR.labRequest){
                setTestsStatus( (dataR.labRequest.urgent==="Yes"?true:false) );
                setLabServices(dataR.labRequest.services);
              }
            }
          }
          // var sortedObjs = _.sortBy(res.data.data, "date");
          // setpreApproval(sortedObjs);
        } else if (!res.data.success) {
          setErrorMsg(res.data.error);
          setOpenNotification(true);
        }
        return res;
      })
      .catch((e) => {
        console.log("error: ", e);
      });
    }
  }, [])

  const handleChange = (e, newValue) => {
    setValue(newValue)
  }



  const handleChangeCheckbox = (event, newValue) => {
    let xServiceId = serviceIds;
    if (event.target.checked){
      xServiceId.push(event.target.value);
    }else{
      let indexOfItem = xServiceId.indexOf( event.target.value );
      xServiceId.splice( indexOfItem, 1 );
    }

    dispatch({
      field: 'serviceIds',
      value: xServiceId,
    })
  }

  const handleView = (rec) => {
    let url = '/dashboard/home/insurance/preapproval/'+episodeId+'/'+labRequestId+'/view/'+rec._id+'';
    if ( preApprovalType === "radpreapproval" ){
      url = '/dashboard/home/insurance/radpreapproval/'+episodeId+'/'+labRequestId+'/view/'+rec._id+'';
    }
    props.history.push({
      pathname: url,
    });
  }

  function viewNeedApproval(rec) {
    let path = `viewpreapproval/needapproval`
    props.history.push({
      pathname: path,
      state: {
        selectedItem: rec,
      },
    })
  }

  const handleSaveApprove = () => {
    setOpenDialogSubmit(true);
    // if (insuranceApprovalNo.trim !== ''){
    //   handleSave();
    // }
  }

  const onSlipUpload = (event) => {
    // event.preventDefault()
    console.log("event::", event.target, event.target.name, event.target.files);
    var file = event.target.files[0];
    var fileType = file.name.slice(file.name.length - 3);
    let file_size = event.target.files[0].size;

    setDisableButtonIfSelected(true);

    if (event.target.name === "consentSelfpay") {
      
      setErrorFileConsentMsg("");

      let obj = {};
      obj.contentType = fileType;
      obj.title = "Consent to Upload - " + file.name;
      obj.url = file.name;
      obj.size = file.size.toString();
      // obj.creation = file.lastModifiedDate.toISOString().substr(0, 10);
      console.log('file::file::', obj);
      setSelfConsentFileObject(obj);

      // setProgress(true)
      var reader = new FileReader();
      var url = reader.readAsDataURL(file);

      reader.onloadend = function() {
        if (file_size <= 10500000) {
          if (
            fileType === "png" || fileType === "PNG" || 
            fileType === "peg" || fileType === "PEG" || 
            fileType === "jpg" || fileType === "JPG" || 
            fileType === "PDF" || fileType === "pdf"
          ) {
            // setImagePreview([reader.result]);
            setDisableButtonIfSelected(false);
            setSelfConsentFile(file);
            console.log('file::file::', file);
          } else {
            setErrorFileConsentMsg("Only jpg, jpeg and png are be allowed");
          }
        } else {
          setErrorFileConsentMsg("Files size should be less Than or Equal to 10MB");
        }
      };
    }
  };

  const serviceIdExists = (xServiceId) => {
    if ( serviceIds.indexOf( xServiceId ) >= 0 ){
      return true;
    }
    return false;
  }

  const handleSave = () => {
    let formData = new FormData();
    if (selfConsentFile) {
      formData.append("consentFile", selfConsentFile, selfConsentFile.name);
    }

    formData.append( "edrId",         episodeId );
    formData.append( "staffId",       currentUser._id );
    formData.append( "requestId",     labRequestId );
    formData.append( "serviceId",     JSON.stringify(serviceIds) );
    formData.append( "requestStatus", newLabServiceStatus );
    formData.append( "completeType",  'all' );

    if (newLabServiceStatus === 'Approved'){
      formData.append( "insuranceApprovalNo", insuranceApprovalNo );
      formData.append( "insuranceRequestApproved", 'yes' );
    }else{
      formData.append( "insuranceApprovalNo", "" );
      formData.append( "insuranceRequestApproved", "no" );
    }

    console.log('file::file::', formData);

    let finalURL = updateLabSampleInsuranceRequest;
    if ( preApprovalType === "radpreapproval" )
    {
      finalURL = updateRadSampleInsuranceRequest;
    }

    let submissionStart = true;

    console.log( selfConsentFile, newLabServiceStatus, testsStatus,  (selfConsentFile === "" && newLabServiceStatus === "Not Approved" && testsStatus === false) );

    if ( selfConsentFile === "" && newLabServiceStatus === "Not Approved" && testsStatus === false ){
      submissionStart = false;
      setOpenNotification(true);
      setErrorMsg("Against Medical Advice (AMA) Acknowledgement is required!");
    }

    if ( submissionStart ){

      console.log('finalURL::', finalURL);
      axios
        .put(finalURL, formData)
        .then((res) => {
          if (res.data.success) {
            setOpenNotification(true);
            setsuccessMsg(`The Request has been marked as ${newLabServiceStatus}!`);
            window.location.reload();
          }
        })
        .catch((e) => {
          setOpenNotification(true);
          setErrorMsg("Unable to Update the Record");
        });
    }
    
    // axios
    //   .put(finalURL, formData)
    //   .then((res) => {
    //     if (res.data.success) {
    //       setOpenNotification(true);
    //       setsuccessMsg(`The Request has been marked as ${newLabServiceStatus}!`);
    //       window.location.reload();
    //     }
    //   })
    //   .catch((e) => {
    //     setOpenNotification(true);
    //     setErrorMsg("Unable to Update the Record");
    //   });
  }

  if (openNotification) {
    setTimeout(() => {
      setOpenNotification(false)
      setErrorMsg('')
    }, 2000)
  }

  return (
    <div
      style={{
        backgroundColor: 'rgb(19 213 159)',
        position: 'fixed',
        display: 'flex',
        width: '100%',
        height: '100%',
        flexDirection: 'column',
        flex: 1,
        overflowY: 'scroll',
      }}
    >
      <Header history={props.history} />

      <PatientDetailsQuick
        edrId={episodeId} />

      <div className='cPadding'>
        <div className='subheader' style={{ marginLeft: '-6px' }}>
          <div>
            <img src={PreApproval} />
            <h4>Pre-Approval</h4>
          </div>
        </div>
        {/*
        <PatientDetails2
            edrId={episodeId}
            showChiefComplaint={true}
            showTriageLevel={true}
            showCareStream={true}
            showDiagnosis={true} />
        */}
        {patientDetails && labRequest ? (
          <div className="mb-3 container-fluid">
            <div className="card p-3">
              <div className="row row-5">
                <div className="col-6 col-lg-3 card-detail-block block-xdiv mb-2"><strong className="lblX">Episode #</strong><span className="valX">{patientDetails.episodeNumber}</span></div>
                <div className="col-6 col-lg-3 card-detail-block block-xdiv mb-2"><strong className="lblX">MRN #</strong><span className="valX">{patientDetails.mrn}</span></div>
                <div className="col-6 col-lg-3 card-detail-block block-xdiv mb-2"><strong className="lblX">Requester</strong><span className="valX">{labRequest.requestedName}</span></div>
                <div className="col-6 col-lg-3 card-detail-block block-xdiv mb-2"><strong className="lblX">Date and Time</strong><span className="valX">{displayInHumanDifference(labRequest.requestedAt)}</span></div>
              </div>
            </div>
          </div>
        ) : (
          undefined
        )}

        <div style={{ height: '20px' }} />

        <div className={classesForTabs.root}>
          <Tabs
            classes={{
              root: classesForTabs.root,
              scroller: classesForTabs.scroller,
            }}
            value={value}
            onChange={handleChange}
            textColor='primary'
            TabIndicatorProps={{ style: { background: '#12387a' } }}
            centered={false}
            variant='scrollable'
            fullWidth={true}
          >
            <Tab
              style={{
                color: 'white',
                borderRadius: 5,
                outline: 'none',
                color: value === 0 ? '#12387a' : '#3B988C',
              }}
              label='Need Approval'
            />
          </Tabs>
        </div>



        <div className='container-fluid mt-4'>
          <div className='row row-5'>
            <div className="col-sm-12">
            {
              selectedRecord?
              <>
                <h4 className="h6 text-white mt-3" style={{ fontWeight: '700' }}>Insurance Details</h4>
                <div className="card border-0 mb-5">
                  {
                    selectedRecord.patientId ?
                    (
                      selectedRecord.patientId.paymentMode==="Insured"?
                      <div className="card-body pb-0">
                        <div className="row row-10">
                          <div className="col-4 col-lg-2 card-detail-block block-xdiv mb-lg-2 mb-4"><strong className="lblX">Insurance Vendor</strong><span className="valX">{selectedRecord.patientId.insuranceVendor}</span></div>
                          <div className="col-4 col-lg-2 card-detail-block block-xdiv mb-lg-2 mb-4"><strong className="lblX">Policy Number</strong><span className="valX">{selectedRecord.patientId.insurancePolicyNumber}</span></div>
                          <div className="col-4 col-lg-2 card-detail-block block-xdiv mb-lg-2 mb-4"><strong className="lblX">Network/Plan</strong><span className="valX">{selectedRecord.patientId.insurancePlanNetwork}</span></div>
                          <div className="col-4 col-lg-2 card-detail-block block-xdiv mb-lg-2 mb-4"><strong className="lblX">Effective From</strong><span className="valX">{moment(selectedRecord.patientId.insuranceFrom).format('MMM DD, YYYY')}</span></div>
                          <div className="col-4 col-lg-2 card-detail-block block-xdiv mb-lg-2 mb-4"><strong className="lblX">Expiry Date</strong><span className="valX">{moment(selectedRecord.patientId.insuranceTo).format('MMM DD, YYYY')}</span></div>
                          <div className="col-4 col-lg-2 card-detail-block block-xdiv mb-lg-2 mb-4"><strong className="lblX">Relation</strong><span className="valX">{selectedRecord.patientId.coveredFamilyMember[0].typeOfRelation}</span></div>
                        </div>
                        <div className="p-3 bg-light mt-3" style={{ marginLeft: '-18px', marginRight: '-18px' }}>
                          <Button
                            color="primary"
                            size="small"
                            onClick={() => setDialogBoxInsurance(true)}
                            variant="contained"
                            fullWidth="true"
                          >+ View Details</Button>
                        </div>
                      </div>:
                      <div className="card-body">
                        No Information Found. The Payment mode of this patient is <strong>SelfPay</strong>.
                      </div> 
                    )
                    :undefined
                  }
                </div>
              </>:undefined
            }
            </div>
          </div>


          {value === 0 ? (
            <div className='row row-5' style={{ marginTop: matches ? 0 : 10 }}>
              {labServices && labServices.length > 0 ? (
                Object.entries(labServices).map((obj, entry) => {
                  return (
                    <div className="col-12 col-md-6 col-lg-6 col-xl-6">
                      <div className="card card-detail-info mb-2">
                        <div className="row row-5">
                          <div className="col-sm-6 card-detail-block block-xdiv mb-2">
                            <FormControlLabel
                              control={<Checkbox checked={serviceIdExists(obj[1]._id)} onChange={handleChangeCheckbox} value={obj[1]._id} name="checkedA" disabled={obj[1].insuranceStatus!="Pending"} />}
                              label="Select"
                            />
                          </div>
                          <div className="col-sm-6">&nbsp;</div>
                          <div className="col-sm-12 card-detail-block block-xdiv"><strong className="lblX">Profile/Test</strong><span className="valX">{obj[1].category}: <span>{obj[1].name}</span></span></div>
                          {
                            obj[1].cServiceId ? (
                              obj[1].cServiceId.length > 0 ? (
                                Object.entries(obj[1].cServiceId).map((objxxx, entryxxx) => {
                                  return <div className="col-sm-12 card-detail-block block-xdiv mt-2"><strong className="lblX">Associated Profile/Test</strong><span className="valX">{objxxx[1].mRef.category}: <span>{objxxx[1].mRef.name}</span></span></div>
                                })
                              ):undefined
                            )
                            :undefined
                          }
                        </div>
                        <div className="card-status-action">
                          {
                            (
                            obj[1].insuranceConsentFile.trim()==="" ||
                            obj[1].insuranceConsentFile.trim()===null
                            )?undefined:<i className="ml-attachment ml-10 zmdi zmdi-attachment zmdi-hc-2x" onClick={() => {
                              // 👇 Open link in new tab programmatically
                              window.open(`${audioURL}/${obj[1].insuranceConsentFile.trim().replace('\\','/')}`, '_blank', 'noreferrer');
                            }} />
                          }
                          <span className={classNames(`badge`, (
                          (
                            obj[1].insuranceStatus=="Approved" || 
                            obj[1].insuranceStatus=="Selfpay" || 
                            obj[1].insuranceStatus=="Covered"
                          )?'badge-success':(obj[1].insuranceStatus=="Not Approved"?'badge-warning':'status-pending')))}>RCM {obj[1].insuranceStatus}</span>
                          <span title="Nurse Technician Status" className={classNames(`badge`, (obj[1].nurseTechnicianStatus=="Collected"?'badge-success':'status-pending'))}>{obj[1].nurseTechnicianStatus=="Collected"?"Completed":obj[1].nurseTechnicianStatus}</span>
                          {obj[1].priority==="High"?<span className="badge badge-danger">High</span>:undefined}
                          { 
                            obj[1].urgent==="Yes"?<span className="ml-info">!</span>:undefined
                          }
                          <i className="ml-view ml-10 zmdi zmdi-eye zmdi-hc-2x" onClick={() => {
                            handleView(obj[1]);
                          }} />
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : labServices && labServices.length === 0 ? (
                <div className='col-md-12 col-sm-12 col-12' style={{ marginTop: '20px' }}>
                  <h3
                    style={{
                      color: 'white',
                      textAlign: 'center'
                    }}
                  >
                    Opps!! No Data Found
                  </h3>
                </div>
              ) : (
                <div className='LoaderStyle'>
                  <Loader
                    type='TailSpin'
                    color='red'
                    height={50}
                    width={50}
                  />
                </div>
              )}
              <div className="col-sm-8 offset-sm-2">
                {labServices.filter(obj => (obj.insuranceStatus === 'Pending')).length>0?
                <Button
                  style={{
                    backgroundColor: "#AD6BBF",
                    color: "white",
                    cursor: "pointer",
                    borderRadius: 5,
                    backgroundColor: "rgb(173, 107, 191)",
                    height: matches ? 56 : 45,
                    outline: "none",
                    width: "100%",
                  }}
                  className="mt-2 mb-3"
                  variant="contained"
                  color="primary"
                  disabled={serviceIds.length<=0}
                  onClick={handleSaveApprove}
                  fullWidth>Update Status</Button>:undefined
                }
              </div>
            </div>
          ) : (
            undefined
          )}

          <div className='row' style={{ marginTop: '20px', marginBottom: '25px' }}>
            <div className='col-md-12 col-sm-12 col-12'>
              <img
                onClick={() => props.history.goBack()}
                src={Back}
                style={{ width: 45, height: 35, cursor: 'pointer' }}
              />
            </div>
          </div>
        </div>

        {
          openDialogSubmit ?
          <Dialog
            aria-labelledby="form-dialog-title"
            open={openDialogSubmit}
            onClose={() => setOpenDialogSubmit(false)}
            maxWidth="md"
          >
            <DialogTitle
              id="simple-dialog-title"
              className=""
            >Update Status</DialogTitle>
            <DialogContent>
              <DialogContentText>Please fill up the following data in order to update the status.</DialogContentText>
              <FormControl variant="outlined" className="mb-3" fullWidth>
                <Select
                  name={"newLabServiceStatus"}
                  value={newLabServiceStatus}
                  variant="filled"
                  fullWidth
                  label="Status"
                  onChange={(e) => {
                    setNewLabServiceStatus(e.target.value);
                    if (
                      e.target.value === "Approved" ||
                      e.target.value === "Selfpay" ||
                      e.target.value === "Not Approved"
                    ){
                      // setOpenConsentDialog(true);
                    }
                  }}
                >
                  {selectedRecord.patientId.paymentMode==="Insured"?<MenuItem value="Covered" key="Covered">Covered</MenuItem>:undefined}
                  {selectedRecord.patientId.paymentMode==="Insured"?<MenuItem value="Approved" key="Approved">Approved</MenuItem>:undefined}
                  <MenuItem value="Selfpay" key="Selfpay">Selfpay</MenuItem>
                  <MenuItem value="Not Approved" key="Not Approved">Not Approved</MenuItem>
                  <MenuItem value="" key="">Select Status</MenuItem>
                </Select>
              </FormControl>

              {
                newLabServiceStatus === "Approved"?
                <TextField
                  required
                  fullWidth
                  className="textInputStyle mb-3"
                  id="insuranceApprovalNo"
                  type="text"
                  variant="filled"
                  label="Approval Number"
                  name={"insuranceApprovalNo"}
                  value={insuranceApprovalNo}
                  onChange={(event) => {
                    setInsuranceApprovalNo( event.target.value );
                  }}
                />:undefined
              }
              <label
                style={{
                  ...styles.upload,
                  fontSize: matches ? 15 : 12,
                  height: 55,
                }}
              >
                <TextField
                  required
                  type="file"
                  accept="image/*,application/pdf"
                  style={styles.input}
                  onChange={onSlipUpload}
                  onClick={(event) => {
                    event.target.value = null;
                  }}
                  name="consentSelfpay"
                  Error={errorFileConsentMsg}
                />
                <FaUpload />
                {matches ? <>&nbsp;&nbsp;</> : ""} Upload associated documents (if any)
              </label>

              <Button
                style={{
                  backgroundColor: "#AD6BBF",
                  color: "white",
                  cursor: "pointer",
                  borderRadius: 5,
                  backgroundColor: "rgb(173, 107, 191)",
                  height: matches ? 56 : 45,
                  outline: "none",
                  width: "100%",
                }}
                className="mt-2 mb-3"
                onClick={handleSave}
                variant="contained"
                color="primary"
                fullWidth>Submit</Button>

            </DialogContent>
          </Dialog>:undefined
        }


        {
          dialogBoxInsurance ?
          <Dialog
            aria-labelledby="form-dialog-title"
            open={dialogBoxInsurance}
            onClose={() => setDialogBoxInsurance(false)}
            maxWidth="md"
          >
            <DialogTitle
              id="simple-dialog-title"
              className=""
            >Insurance Details</DialogTitle>
            <DialogContent>
              <div className="row row-10">
                <div className="col-4 card-detail-block block-xdiv"><strong className="lblX">Insurance Vendor</strong><span className="valX">{selectedRecord.patientId.insuranceVendor}</span></div>
                <div className="col-4 card-detail-block block-xdiv"><strong className="lblX">Policy Number</strong><span className="valX">{selectedRecord.patientId.insurancePolicyNumber}</span></div>
                <div className="col-4 card-detail-block block-xdiv"><strong className="lblX">Network/Plan</strong><span className="valX">{selectedRecord.patientId.insurancePlanNetwork}</span></div>
                <div className="col-4 card-detail-block block-xdiv mt-lg-3 mt-md-3 mt-2"><strong className="lblX">Effective From</strong><span className="valX">{moment(selectedRecord.patientId.insuranceFrom).format('MMM DD, YYYY')}</span></div>
                <div className="col-4 card-detail-block block-xdiv mt-lg-3 mt-md-3 mt-2"><strong className="lblX">Expiry Date</strong><span className="valX">{moment(selectedRecord.patientId.insuranceTo).format('MMM DD, YYYY')}</span></div>
                <div className="col-4 card-detail-block block-xdiv mt-lg-3 mt-md-3 mt-2"><strong className="lblX">Relation</strong><span className="valX">{selectedRecord.patientId.coveredFamilyMember[0].typeOfRelation}</span></div>

                <div className="col-4 card-detail-block block-xdiv mt-lg-3 mt-md-3 mt-2"><strong className="lblX">Coverage Terms</strong><span className="valX">{selectedRecord.patientId.coverageTerms} ({selectedRecord.patientId.coPayment}%)</span></div>
                <div className="col-8 card-detail-block block-xdiv mt-lg-3 mt-md-3 mt-2"><strong className="lblX">Eligibility</strong><span className="valX">{selectedRecord.patientId.insuranceEligibility}</span></div>
                {
                  selectedRecord.patientId.insuranceItemsInfo?
                  <>
                      <div className="col-4 card-detail-block block-xdiv mt-lg-3 mt-md-3 mt-2"><strong className="lblX">Consultation</strong><span className="valX">{selectedRecord.patientId.insuranceItemsInfo[0].consultationJod ?? "-"}</span></div>
                      <div className="col-4 card-detail-block block-xdiv mt-lg-3 mt-md-3 mt-2"><strong className="lblX">Min</strong><span className="valX">JD {selectedRecord.patientId.insuranceItemsInfo[0].consultationMin ?? "-"}</span></div>
                      <div className="col-4 card-detail-block block-xdiv mt-lg-3 mt-md-3 mt-2"><strong className="lblX">Max</strong><span className="valX">JD {selectedRecord.patientId.insuranceItemsInfo[0].consultationMax ?? "-"}</span></div>
                      
                      <div className="col-4 card-detail-block block-xdiv mt-lg-3 mt-md-3 mt-2"><strong className="lblX">Lab</strong><span className="valX">{selectedRecord.patientId.insuranceItemsInfo[0].labJod ?? "-"}</span></div>
                      <div className="col-4 card-detail-block block-xdiv mt-lg-3 mt-md-3 mt-2"><strong className="lblX">Min</strong><span className="valX">JD {selectedRecord.patientId.insuranceItemsInfo[0].labMin ?? "-"}</span></div>
                      <div className="col-4 card-detail-block block-xdiv mt-lg-3 mt-md-3 mt-2"><strong className="lblX">Max</strong><span className="valX">JD {selectedRecord.patientId.insuranceItemsInfo[0].labMax ?? "-"}</span></div>
                      
                      <div className="col-4 card-detail-block block-xdiv mt-lg-3 mt-md-3 mt-2"><strong className="lblX">Radiology</strong><span className="valX">{selectedRecord.patientId.insuranceItemsInfo[0].radiologyJod ?? "-"}</span></div>
                      <div className="col-4 card-detail-block block-xdiv mt-lg-3 mt-md-3 mt-2"><strong className="lblX">Min</strong><span className="valX">JD {selectedRecord.patientId.insuranceItemsInfo[0].radiologyMin ?? "-"}</span></div>
                      <div className="col-4 card-detail-block block-xdiv mt-lg-3 mt-md-3 mt-2"><strong className="lblX">Max</strong><span className="valX">JD {selectedRecord.patientId.insuranceItemsInfo[0].radiologyMax ?? "-"}</span></div>
                      
                      <div className="col-4 card-detail-block block-xdiv mt-lg-3 mt-md-3 mt-2"><strong className="lblX">Treatment</strong><span className="valX">{selectedRecord.patientId.insuranceItemsInfo[0].treatmentJod ?? "-"}</span></div>
                      <div className="col-4 card-detail-block block-xdiv mt-lg-3 mt-md-3 mt-2"><strong className="lblX">Min</strong><span className="valX">JD {selectedRecord.patientId.insuranceItemsInfo[0].treatmentMin ?? "-"}</span></div>
                      <div className="col-4 card-detail-block block-xdiv mt-lg-3 mt-md-3 mt-2"><strong className="lblX">Max</strong><span className="valX">JD {selectedRecord.patientId.insuranceItemsInfo[0].treatmentMax ?? "-"}</span></div>
                      
                      <div className="col-4 card-detail-block block-xdiv mt-lg-3 mt-md-3 mt-2"><strong className="lblX">Medicine</strong><span className="valX">{selectedRecord.patientId.insuranceItemsInfo[0].medicineJod ?? "-"}</span></div>
                      <div className="col-4 card-detail-block block-xdiv mt-lg-3 mt-md-3 mt-2"><strong className="lblX">Min</strong><span className="valX">JD {selectedRecord.patientId.insuranceItemsInfo[0].medicineMin ?? "-"}</span></div>
                      <div className="col-4 card-detail-block block-xdiv mt-lg-3 mt-md-3 mt-2"><strong className="lblX">Max</strong><span className="valX">JD {selectedRecord.patientId.insuranceItemsInfo[0].medicineMax ?? "-"}</span></div>
                      
                      <div className="col-4 card-detail-block block-xdiv mt-lg-3 mt-md-3 mt-2"><strong className="lblX">Dental</strong><span className="valX">{selectedRecord.patientId.insuranceItemsInfo[0].dentalJod ?? "-"}</span></div>
                      <div className="col-4 card-detail-block block-xdiv mt-lg-3 mt-md-3 mt-2"><strong className="lblX">Min</strong><span className="valX">JD {selectedRecord.patientId.insuranceItemsInfo[0].dentalMin ?? "-"}</span></div>
                      <div className="col-4 card-detail-block block-xdiv mt-lg-3 mt-md-3 mt-2"><strong className="lblX">Max</strong><span className="valX">JD {selectedRecord.patientId.insuranceItemsInfo[0].dentalMax ?? "-"}</span></div>
                      
                    <div className="col-4 card-detail-block block-xdiv mt-lg-3 mt-md-3 mt-2"><strong className="lblX">Invoice - Max</strong><span className="valX">{selectedRecord.patientId.insuranceItemsInfo[0].invoiceMax ?? "-"}</span></div>
                    <div className="col-4 card-detail-block block-xdiv mt-lg-3 mt-md-3 mt-2"><strong className="lblX">Preapproval Limit</strong><span className="valX">{selectedRecord.patientId.insuranceItemsInfo[0].preApprovalLimit ?? "-"}</span></div>
                    <div className="col-4 card-detail-block block-xdiv mt-lg-3 mt-md-3 mt-2"><strong className="lblX">Invoice Max Co-Pay</strong><span className="valX">{selectedRecord.patientId.insuranceItemsInfo[0].invoiceMaxCopay ?? "-"}</span></div>

                    <div className="col-4 card-detail-block block-xdiv mt-lg-3 mt-md-3 mt-2"><strong className="lblX">Out-Patient (Co-Pay)</strong><span className="valX">{selectedRecord.patientId.insuranceItemsInfo[0].outPatientCopay ?? "-"}</span></div>
                    <div className="col-8 card-detail-block block-xdiv mt-lg-3 mt-md-3 mt-2"><strong className="lblX">In-Patient (Co-Pay)</strong><span className="valX">{selectedRecord.patientId.insuranceItemsInfo[0].inPatientCopay ?? "-"}</span></div>
                  </>:undefined
                }

                <div className="col-4 card-detail-block block-xdiv mt-lg-2 mt-md-3 mt-2"><strong className="lblX">Pre-Approval Telephone</strong><span className="valX">{selectedRecord.patientId.insuranceVendorId.phoneNumber ?? "-"}</span></div>
                <div className="col-8 card-detail-block block-xdiv mt-lg-2 mt-md-3 mt-2"><strong className="lblX">Pre-Approval Fax</strong><span className="valX">{selectedRecord.patientId.insuranceVendorId.faxNumber ?? "-"}</span></div>
                <div className="col-12 card-detail-block block-xdiv mt-lg-2 mt-md-3 mt-2"><strong className="lblX">Pre-Approval Email</strong><span className="valX">{selectedRecord.patientId.insuranceVendorId.preapprovalsEmail ?? "-"}</span></div>
                <div className="col-12 card-detail-block block-xdiv mt-lg-2 mt-md-3 mt-2"><strong className="lblX">Eligibility Email</strong><span className="valX">{selectedRecord.patientId.insuranceVendorId.eligibilityEmail ?? "-"}</span></div>
                <div className="col-12 card-detail-block block-xdiv mt-lg-2 mt-md-3 mt-2"><strong className="lblX">Claim Email</strong><span className="valX">{selectedRecord.patientId.insuranceVendorId.claimsEmail ?? "-"}</span></div>



              </div>
            </DialogContent>
          </Dialog>:undefined
        }

        <Notification msg={errorMsg} open={openNotification}
        success={successMsg} />
      </div>
    </div>
  )
}
export default ViewPreApprovalList