
export const tableHeadingForEDBeds = [
    "Room ID",
    "Bed Cell No.",
    " "
];
export const tableDataKeysForEDBeds = [
    "roomId",
    "roomNo",
];

export const tableHeadingForCC = [
    "CCID",
    "CC Name",
    "No. of Patients",
    " "
];
export const tableDataKeysForCC = [
    "chiefComplaintId",
    "name",
    "count"
];

export const tableHeadingForCSinProcess = [
    "Room ID",
    "CareStream",
    "Production Area",
    "MRN",
    "Patient Name",
];
export const tableDataKeysForCSinProcess = [
    "mrn",
    "name",
    "area",
    "id"
];

export const tableHeadingForPatientTreatment = [
    "Production Area",
    "MRN",
    "Patient Name",
    " "
];
export const tableDataKeysForPatientTreatment = [
    "productionArea",
    "mrn",
    "patientName",
];

export const tableHeadingForMedReconciliation = [
    "MRN",
    "Patient Name",
    "Production Area",
    "CareStream",
    "Action"
];
export const tableDataKeysForMedReconciliation = [
    "mrn",
    "patientName",
    "productionArea",
    "careStream"
];

export const tableHeadingForFlagsMgmt = [
    "MRN",
    "Patient Name",
    "Room ID",
    "Reason",
    "Production Area",
    "CareStream"
];
export const tableDataKeysForFlagsMgmt = [
    "mrn",
    "name",
    "area",
    "mrn",
    "area",
];

export const tableHeadingForTransferOfCare = [
    "MRN",
    "Patient Name",
    "Room ID",
    "Production Area",
    "Bed Cell No",
    "Doctor Name"
];
export const tableDataKeysForTransferOfCare = [
    "mrn",
    "name",
    "area",
    "mrn",
    "mrn"
];

export const tableHeadingForEDtoEOU = [
    "MRN",
    "Patient Name",
    "Room ID",
    "Production Area",
    "Bed Cell No.",
    "Action"
];
export const tableDataKeysForEDtoEOU = [
    "mrn",
    "name",
    "name",
    "name",
    "name"
];

export const tableHeadingForTimeInterval = [
    "MRN",
    "Patient Name",
    "Admitted Date/Time",
    "Current Date/Time",
    "Time Interval",
    " "
];
export const tableDataKeysForTimeInterval = [
    "mrn",
    "patientName",
    "createdTimeStamp",
    "currentTime",
    "timeInterval"
];

export const tableHeadingForLabTests = [
    "Room ID",
    "MRN",
    "Patient Name",
    "Total Tests",
    "Action"
];
export const tableDataKeysForLabTests = [
    "roomId",
    "mrn",
    "patientName",
    "totalTests"
];

export const tableHeadingForRadiologyTests = [
    "Room ID",
    "MRN",
    "Patient Name",
    "Total Tests",
    "Action"
];
export const tableDataKeysForRadiologyTests = [
    "roomId",
    "mrn",
    "patientName",
    "totalTests"
];

export const tableHeadingForCriticalLab = [
    "MRN",
    "Patient Name",
    "Lab Test Name",
    "Test Type",
    "Date/Time",
    " "
];
export const tableDataKeysForCriticalLab = [
    "mrn",
    "patientName",
    "testName",
    "testType",
    "date"
];

export const tableHeadingForDischargeReq = [
    "Room ID",
    "MRN",
    "Patient Name",
    "Production Area",
    "Bed Cell No",
    "Requirements",
    " "
];
export const tableDataKeysForDischargeReq = [
    "roomId",
    "mrn",
    "patientName",
    "productionArea",
    "bedCellNo",
    "requirement"
];

export const tableHeadingForCriticalFunc = [
    "Room ID",
    "Production Area",
    "Production Team",
    "Date/Time",
];
export const tableDataKeysForCriticalFunc = [
    "mrn",
    "name",
    "name"
];

export const tableHeadingForCStimeInterval = [
    "MRN",
    "Patient Name",
    "Production Team",
    "Admitted Date/Time",
    "Current Date/Time",
    "Time Interval"
];
export const tableDataKeysForCStimeInterval = [
    "mrn",
    "name",
    "name",
    "name",
    "name"
];
