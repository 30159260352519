import React, { useEffect, useState, useReducer } from "react";
import { makeStyles } from "@material-ui/core/styles";
import "../../assets/jss/material-dashboard-react/components/TextInputStyle.css";
import axios from "axios";
import TextField from "@material-ui/core/TextField";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import MenuItem from "@material-ui/core/MenuItem";
import cookie from "react-cookies";
import Button from "@material-ui/core/Button";
import Header from "../../components/Header/Header";
import Back from "../../assets/img/Back_Arrow.png";
import Notification from "../../components/Snackbar/Notification.js";
import HousekeepingIcon from "../../assets/img/Housekeeping.png";
import { connect } from "react-redux";
import {
  getAllHouseKeepers,
  assignHouseKeeper,
  getProductionAreas,
} from "../../public/endpoins";
import {
  funForReducer,
  setPatientDetailsForReducer,
} from "../../actions/Checking";

const styles = {
  stylesForButton: {
    color: "white",
    cursor: "pointer",
    borderRadius: 5,
    backgroundColor: "#2c6ddd",
    height: "45px",
    width: "150px",
    outline: "none",
  },
  patientDetails: {
    backgroundColor: "white",
    borderRadius: 5,
  },
  textFieldPadding: {
    paddingTop: 10,
    paddingBottom: 10,
  },
};

const useStylesForInput = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(0),
  },
  input: {
    backgroundColor: "white",
    borderRadius: 5,
    "&:after": {
      borderBottomColor: "black",
    },
    "&:hover": {
      backgroundColor: "white",
    },
    "&:disabled": {
      color: "gray",
    },
    "&:focus": {
      backgroundColor: "white",
      borderRadius: 5,
    },
  },
  root: {
    "& .MuiTextField-root": {
      backgroundColor: "white",
    },
    "& .Mui-focused": {
      backgroundColor: "white",
      color: "black",
    },
    "& .Mui-disabled": {
      backgroundColor: "white",
      color: "black",
    },
    "& .MuiFormLabel-root": {
      fontSize: "13px",
      paddingRight: "50px",
    },
  },
}));

const statusArray = [
  { key: "Pending", value: "pending" },
  { key: "Completed", value: "completed" },
];

const tasksArray = [{ key: "Cleaning", value: "To Be Clean" }];

function AssignHousekeeping(props) {
  const matches = useMediaQuery("(min-width:600px)");
  const classes = useStylesForInput();

  const initialState = {
    houseKeeper: "",
    status: "pending",
    productionArea: "",
    room: "",
    task: "",
  };

  function reducer(state, { field, value }) {
    return {
      ...state,
      [field]: value,
    };
  }

  const [state, dispatch] = useReducer(reducer, initialState);

  const { houseKeeper, status, productionArea, room, task } = state;

  const [currentUser] = useState(cookie.load("current_user"));
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setsuccessMsg] = useState("");
  const [openNotification, setOpenNotification] = useState(false);
  const [patientDetails, setPatientDetails] = useState("");
  const [, openPatientDetailsDialog] = useState(false);
  const [allHousekeepers, setAllHousekeepers] = useState([]);
  const [allPAs, setAllPAs] = useState([]);
  const [allRooms, setAllRooms] = useState([]);

  useEffect(() => {
    if (props.patientDetails) {
      setPatientDetails(props.patientDetails);
      openPatientDetailsDialog(true);
    }
    console.log("User ", cookie.load("current_user"));

    getHousekeeperData();
    getProductionAreaData();
  }, []);

  function getHousekeeperData() {
    axios
      .get(getAllHouseKeepers)
      .then((res) => {
        if (res.data.success) {
          console.log("response", res.data.data);
          res.data.data.map(
            (d) => (d.name = d.name[0].given[0] + " " + d.name[0].family)
          );
          setAllHousekeepers(res.data.data);
        } else {
          setOpenNotification(true);
          setErrorMsg("Cannot get Housekeepers at the moment");
        }
      })
      .catch((e) => {
        setOpenNotification(true);
        setErrorMsg("Cannot get Housekeepers at the moment");
      });
  }

  function getProductionAreaData() {
    axios
      .get(getProductionAreas)
      .then((res) => {
        if (res.data.success) {
          console.log("response", res.data.data);
          setAllPAs(res.data.data);
        } else {
          setOpenNotification(true);
          setErrorMsg("Cannot get Production Areas at the moment");
        }
      })
      .catch((e) => {
        setOpenNotification(true);
        setErrorMsg("Cannot get Production Areas at the moment");
      });
  }

  if (openNotification) {
    setTimeout(() => {
      setOpenNotification(false);
      setErrorMsg("");
      setsuccessMsg("");
    }, 2000);
  }

  useEffect(() => {
    if (productionArea !== "") {
      axios
        .get(getProductionAreas)
        .then((res) => {
          if (res.data.success) {
            res.data.data.map((d) => {
              if (d._id === productionArea) {
                setAllRooms(d.rooms);
              }
            });
          }
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      setAllRooms([]);
    }
  }, [productionArea]);

  const onChangeValue = (e) => {
    dispatch({ field: e.target.name, value: e.target.value });
  };

  function validateForm() {
    return (
      houseKeeper &&
      houseKeeper.length > 0 &&
      productionArea &&
      productionArea.length > 0 &&
      room &&
      room.length > 0 &&
      task &&
      task.length > 0
    );
  }

  const handleAssign = () => {
    const params = {
      staffId: currentUser._id,
      requestedBy:
        currentUser.subType.length > 0
          ? currentUser.subType.toString()
          : currentUser.staffType,
      houseKeeperId: houseKeeper,
      productionAreaId: productionArea,
      roomId: room,
      status,
      task,
    };
    console.log("Params ", params);

    if (validateForm()) {
      axios
        .post(assignHouseKeeper, params)
        .then((res) => {
          if (res.data.success) {
            console.log("response", res.data.data);
            props.history.push({
              pathname: "/dashboard/home/success",
              state: {
                message1: `Housekeeper assigned successfully`,
              },
            });
          } else {
            setOpenNotification(true);
            setErrorMsg("Cannot assign housekeeper at the moment");
          }
        })
        .catch((e) => {
          setOpenNotification(true);
          setErrorMsg("Cannot assign housekeeper at the moment");
        });
    } else {
      setOpenNotification(true);
      setErrorMsg("Please fill the form");
    }
  };

  return (
    <div
      style={{
        backgroundColor: "rgb(19 213 159)",
        position: "fixed",
        display: "flex",
        width: "100%",
        height: "100%",
        flexDirection: "column",
        flex: 1,
        overflowY: "scroll",
      }}
    >
      <Header history={props.history} />
      <div className="cPadding">
        <div className="subheader">
          <div>
            <img src={HousekeepingIcon} />
            <h4>Assign Housekeeping</h4>
          </div>
        </div>

        <div className={`container-fluid ${classes.root}`}>
          <div className="row">
            <div
              className="col-md-12 col-sm-12 col-12"
              style={{ ...styles.textFieldPadding, paddingTop: "40px" }}
            >
              <TextField
                required
                select
                fullWidth
                label="Select Housekeeper"
                id="houseKeeper"
                name="houseKeeper"
                value={houseKeeper}
                onChange={onChangeValue}
                variant="filled"
                style={{ borderRadius: "3px" }}
                InputProps={{
                  className: classes.input,
                  classes: { input: classes.input },
                  disableUnderline: true,
                }}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>

                {allHousekeepers.map((val) => {
                  return (
                    <MenuItem key={val._id} value={val._id}>
                      {val.name}
                    </MenuItem>
                  );
                })}
              </TextField>
            </div>
            <div
              className="col-md-6 col-sm-6 col-12"
              style={styles.textFieldPadding}
            >
              <TextField
                required
                select
                fullWidth
                id="productionArea"
                name="productionArea"
                value={productionArea}
                onChange={onChangeValue}
                label="Select Production Area"
                variant="filled"
                style={{ borderRadius: "3px" }}
                InputProps={{
                  className: classes.input,
                  classes: { input: classes.input },
                  disableUnderline: true,
                }}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>

                {allPAs.map((val) => {
                  return (
                    <MenuItem key={val._id} value={val._id} rooms={val.rooms}>
                      {val.paName}
                    </MenuItem>
                  );
                })}
              </TextField>
            </div>
            <div
              className="col-md-6 col-sm-6 col-12"
              style={styles.textFieldPadding}
            >
              <TextField
                required
                select
                fullWidth
                id="room"
                name="room"
                value={room}
                onChange={onChangeValue}
                label="Select Room"
                variant="filled"
                style={{ borderRadius: "3px" }}
                InputProps={{
                  className: classes.input,
                  classes: { input: classes.input },
                  disableUnderline: true,
                }}
              >
                {allRooms.length === 0 ? (
                  <MenuItem value="">
                    <em>Select Production Area First</em>
                  </MenuItem>
                ) : (
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                )}

                {allRooms.map((val) => {
                  return (
                    <MenuItem key={val.roomId._id} value={val.roomId._id}>
                      Room No. {val.roomId.roomNo}
                    </MenuItem>
                  );
                })}
              </TextField>
            </div>
            <div
              className="col-md-6 col-sm-6 col-12"
              style={styles.textFieldPadding}
            >
              <TextField
                required
                select
                fullWidth
                id="task"
                name="task"
                value={task}
                onChange={onChangeValue}
                label="Select Task"
                variant="filled"
                style={{ borderRadius: "3px" }}
                InputProps={{
                  className: classes.input,
                  classes: { input: classes.input },
                  disableUnderline: true,
                }}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>

                {tasksArray.map((val) => {
                  return (
                    <MenuItem key={val.key} value={val.value}>
                      {val.key}
                    </MenuItem>
                  );
                })}
              </TextField>
            </div>
            <div
              className="col-md-6 col-sm-6 col-12"
              style={styles.textFieldPadding}
            >
              <TextField
                disabled
                select
                fullWidth
                id="status"
                name="status"
                value={status}
                onChange={onChangeValue}
                label="Status"
                variant="filled"
                style={{ borderRadius: "3px" }}
                InputProps={{
                  className: classes.input,
                  classes: { input: classes.input },
                  disableUnderline: true,
                }}
              >
                {statusArray.map((val) => {
                  return (
                    <MenuItem key={val.key} value={val.value}>
                      {val.key}
                    </MenuItem>
                  );
                })}
              </TextField>
            </div>
          </div>
        </div>

        <div
          className="container-fluid"
          style={{ marginBottom: "25px", marginTop: "5%" }}
        >
          <div className="row">
            <div className="col-md-6 col-sm-6 col-6">
              <img
                onClick={() => props.history.goBack()}
                src={Back}
                style={{ width: 45, height: 35, cursor: "pointer" }}
              />
            </div>
            <div className="col-md-6 col-sm-6 col-6 d-flex justify-content-end">
              <Button
                style={styles.stylesForButton}
                onClick={handleAssign}
                variant="contained"
                color="default"
              >
                Assign
              </Button>
            </div>
          </div>
        </div>

        <Notification
          msg={errorMsg}
          open={openNotification}
          success={successMsg}
        />
      </div>
    </div>
  );
}

const mapStateToProps = ({ CheckingReducer }) => {
  const { count, patientDetails } = CheckingReducer;
  return { count, patientDetails };
};
export default connect(mapStateToProps, {
  funForReducer,
  setPatientDetailsForReducer,
})(AssignHousekeeping);
