import React, { useEffect, useState } from "react";
import TAA from "../../assets/img/Triage & Assessment Icon.png";
import ViewTriageAndAssessment from "../DCD/ViewForm/ViewTriageAndAssessment/ViewTriageAndAssessment";
import formatDate from "../../utils/formatDate";
import axios from "axios";

import Button from "@material-ui/core/Button";

import cookie from "react-cookies";

function ViewSingleTriage(props) {
  const [selectedSingleTriage, setSelectedSingleTriage] = useState("");

  const { careStream } = props.propsData.history.location.state;
  useEffect(() => {
    console.log("PROPSDATA", careStream);

    let arr = [
      // {
      //   heading: "Time",
      //   columnSize: 4,
      //   subArray: [
      //     {
      //       subheading: "Start Time",
      //       description: "das",
      //     },
      //     {
      //       subheading: "End Time",
      //       description: "Dasda",
      //     },
      //     {
      //       subheading: "Total Time",
      //       description: "dsadaseqw",
      //     },
      //   ],
      // },

      {
        heading: "Assign CareStream",
        columnSize: 4,
        subArray: [
          {
            subheading: "CareStream Name",
            description: careStream.name,
          },
        ],
      },

      {
        heading: "Investigations",
        columnSize: 1,
        subArray: [
          {
            description: careStream.investigations.toString(),
          },
        ],
      },

      {
        heading: "Precautions",
        columnSize: 1,
        subArray: [
          {
            description: careStream.precautions.toString(),
          },
        ],
      },

      {
        heading: "Treatment Orders",
        columnSize: 1,
        subArray: [
          {
            description: careStream.treatmentOrders.map((v) =>
              v.name ? v.name.toString() : "N/A"
            ),
          },
        ],
      },

      {
        heading: "IV Fluids",
        columnSize: 1,
        subArray: [
          {
            description: careStream.fluidsIV.toString(),
          },
        ],
      },

      {
        heading: "Medications",
        columnSize: 1,
        subArray: [
          {
            description: careStream.medications.toString(),
          },
        ],
      },

      {
        heading: "MD Notifications",
        columnSize: 1,
        subArray: [
          {
            description: careStream.mdNotification.map((v) =>
              v.name ? v.name.toString() : "N/A"
            ),
          },
        ],
      },
    ];

    setSelectedSingleTriage(arr);
  }, []);

  return (
    <div style={{ overflowX: "hidden" }}>
      <ViewTriageAndAssessment
        heading={"Summary"}
        allProps={props}
        propsData={props.propsData.history.location.state}
        selectedSingleTriage={selectedSingleTriage}
      />
    </div>
  );
}

export default ViewSingleTriage;
