/*eslint-disable*/
import React, { useState, useEffect, useReducer } from "react";
import Button from "@material-ui/core/Button";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Notification from "../../components/Snackbar/Notification.js";
import CustomTable from "../../components/Table/Table";
import TextField from "@material-ui/core/TextField";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Loader from "react-loader-spinner";
import AccountCircle from "@material-ui/icons/SearchOutlined";
import InputAdornment from "@material-ui/core/InputAdornment";
import MenuItem from "@material-ui/core/MenuItem";
import Fingerprint from "../../assets/img/fingerprint.png";
import BarCode from "../../assets/img/Bar Code.png";
import Header from "../../components/Header/Header";
import CustomerCareIcon from "../../assets/img/Customer Care.png";
import eye_icon from "../../assets/img/Eye.png";
import Back from "../../assets/img/Back_Arrow.png";
import "../../assets/jss/material-dashboard-react/components/loaderStyle.css";
import cookie from "react-cookies";
import axios from "axios";
import classNames from "classnames";
import { styles, useStyles, useStylesForTabs } from "./styles.js";
import {
  getTransferRequestCall,
  getTransferBedCall,
  getTransferEDtoEOUCall,
  getTransferEOUtoEDCall,
  getAmbulanceRequestCall,
  getTransferredCall,
  getDischargeCall,
  getUpdateTransferEDtoEOUCall,
  getUpdateTransferEOUtoEDCall,
  getUpdateCompleteDischargeCall,
  updateMedicationStatus,
  updateAmbulanceStatus,
} from "./networkCallsTasks";
import {
  getisUpdateCompletedEDTransfer,
  getisUpdateCompletedEouTransfer,
  getisUpdateCompletedBedTransfer,
} from "../../public/endpoins";

const tableHeadingsForTransferBed = [
  "MRN",
  "Production Area",
  "Transfer Room No",
  "Status",
  "Action",
];
const tableDataKeysForTransferBed = [
  "requestNo",
  "productionArea",
  "roomNo",
  "status",
];

const tableHeadingsForTransferEDtoEOU = [
  "MRN",
  "Production Area",
  "Transfer Room No",
  "Status",
  "Action",
];
const tableDataKeysForTransferEDtoEOU = [
  "mrn",
  "productionArea",
  "roomNo",
  "status",
];

const tableHeadingsForAmbulance = [
  "MRN",
  "Room No",
  "Production Area",
  "Requested By",
  "Status",
  "Action",
];
const tableDataKeysForAmbulance = [
  "mrn",
  "roomNo",
  "productionArea",
  "requestedBy",
  "status",
];

const tableHeadingsForMedications = [
  "MRN",
  "Production Area",
  "Room No",
  "Pharmacist ID",
  "Status",
  "Action",
];
const tableDataKeysForMedications = [
  "mrn",
  "productionArea",
  "roomNo",
  "pharmacistId",
  "pharmaStatus",
];

const tableHeadingsForDischarge = [
  "MRN",
  "Production Area",
  "Room No",
  "Requested By",
  "Status",
  "Current Status",
  "Action",
];
const tableDataKeysForDischarge = [
  "mrn",
  "productionArea",
  "roomNo",
  "requestedBy",
  "dischargeStatus",
  "status",
];

const actions = { assign: true };
const medicationActions = { view: true, assign: true };
// const actions2 = { view: true };

export default function SocialWorkerTasks(props) {
  const classesForTabs = useStylesForTabs();
  const classes = useStyles();
  const matches = useMediaQuery("(min-width:600px)");
  const [currentUser, setCurrentUser] = useState(cookie.load("current_user"));
  const [transferDataArray, setTransferDataArray] = useState([]);
  const [transferBedArray, setTransferBedArray] = useState([]);
  const [transferEDtoEOUArray, setTransferEDtoEOUArray] = useState([]);
  const [transferEOUtoEDArray, setTransferEOUtoEDArray] = useState([]);
  const [ambulanceRequestArray, setAmbulanceRequestArray] = useState([]);
  const [medicationsRequestArray, setMedicationsRequestArray] = useState([]);
  const [dischargeArray, setDischargeArray] = useState([]);

  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [openNotification, setOpenNotification] = useState(false);
  const [tabName, setTabName] = useState("transfertoBed");
  const [buttonValue, setButtonValue] = useState("pending");
  const [cookies, setCookies] = useState(cookie.load("current_user"));

  if (openNotification) {
    setTimeout(() => {
      setOpenNotification(false);
      setErrorMsg("");
    }, 2000);
  }

  useEffect(() => {
    console.log("props", props);
    console.log("Current User", currentUser._id);
    if (
      props.history.location.pathname ===
      "/dashboard/home/completedtasksforcustomercare"
    ) {
      console.log("when completed task list");
      setButtonValue("completed");
      getTransferBedCall(
        currentUser._id,
        buttonValue,
        setTransferEDtoEOUArray,
        setErrorMsg,
        setOpenNotification
      );
    } else {
      getTransferBedCall(
        currentUser._id,
        buttonValue,
        setTransferBedArray,
        setErrorMsg,
        setOpenNotification
      );
    }

    // }
  }, []);

  // useEffect(() => {}, [tabName])

  const handleButtonChange = (buttonValue) => {
    console.log("buttonValue", buttonValue);
    setButtonValue(buttonValue);
    if (tabName === "transferEDtoEOU") {
      getTransferEDtoEOUCall(
        currentUser._id,
        buttonValue,
        setTransferEDtoEOUArray,
        setErrorMsg,
        setOpenNotification
      );
    } else if (tabName === "transfertoBed") {
      getTransferBedCall(
        currentUser._id,
        buttonValue,
        setTransferBedArray,
        setErrorMsg,
        setOpenNotification
      );
    } else if (tabName === "transferEOUtoED") {
      getTransferEOUtoEDCall(
        currentUser._id,
        buttonValue,
        setTransferEOUtoEDArray,
        setErrorMsg,
        setOpenNotification
      );
    } else if (tabName === "ambulancerequest") {
      getAmbulanceRequestCall(
        currentUser._id,
        buttonValue,
        setAmbulanceRequestArray,
        setErrorMsg,
        setOpenNotification
      );
    } else if (tabName === "medicationsRequest") {
      getTransferredCall(
        currentUser._id,
        buttonValue,
        setMedicationsRequestArray,
        setErrorMsg,
        setOpenNotification
      );
    } else if (tabName === "discharge") {
      getDischargeCall(
        currentUser._id,
        buttonValue,
        setDischargeArray,
        setErrorMsg,
        setOpenNotification
      );
    }
  };

  const handleTabChange = (e, tabName) => {
    console.log("tabName", tabName);
    setTabName(tabName);
    if (tabName === "transfertoBed") {
      getTransferBedCall(
        currentUser._id,
        buttonValue,
        setTransferBedArray,
        setErrorMsg,
        setOpenNotification
      );
    } else if (tabName === "transferEDtoRD") {
      getTransferRequestCall(
        currentUser._id,
        buttonValue,
        'ED',
        'RD',
        setTransferDataArray,
        setErrorMsg,
        setOpenNotification
      );
    } else if (tabName === "transferEDtoEOU") {
      getTransferEDtoEOUCall(
        currentUser._id,
        buttonValue,
        setTransferEDtoEOUArray,
        setErrorMsg,
        setOpenNotification
      );
    } else if (tabName === "transferEOUtoED") {
      getTransferEOUtoEDCall(
        currentUser._id,
        buttonValue,
        setTransferEOUtoEDArray,
        setErrorMsg,
        setOpenNotification
      );
    } else if (tabName === "ambulancerequest") {
      getAmbulanceRequestCall(
        currentUser._id,
        buttonValue,
        setAmbulanceRequestArray,
        setErrorMsg,
        setOpenNotification
      );
    } else if (tabName === "medicationsRequest") {
      getTransferredCall(
        currentUser._id,
        buttonValue,
        setMedicationsRequestArray,
        setErrorMsg,
        setOpenNotification
      );
    } else if (tabName === "discharge") {
      getDischargeCall(
        currentUser._id,
        buttonValue,
        setDischargeArray,
        setErrorMsg,
        setOpenNotification
      );
    }
  };

  // const handleDischarge = (rec) => {
  //   console.log("rec", rec);

  //   // props.history.push({
  //   //   pathname: "dischargesubtasklist",
  //   //   state: {
  //   //     selectedItem: rec,
  //   //   },
  //   // });
  // };

  const handleMedication = (rec) => {
    console.log("medication record", rec);

    if (tabName === "medicationsRequest") {
      if (rec.pharmaStatus === "in_progress") {
        const params = {
          requestId: rec.pharmacyRequest._id,
          edrId: rec._id,
          // task: rec.task === "To Be Clean" ? "Cleaned" : "",
        };
        updateMedicationStatus(props, params, setOpenNotification, setErrorMsg);
      } else {
        setOpenNotification(true);
        setErrorMsg("Already Completed");
      }
    } else if (tabName === "ambulancerequest") {
      if (rec.status === "pending") {
        const params = {
          requestId: rec._id,
          edrId: rec.edrId._id,
        };
        updateAmbulanceStatus(props, params, setOpenNotification, setErrorMsg);
      } else {
        setOpenNotification(true);
        setErrorMsg("Already Completed Ambulance Request");
      }
    }
  };

  const handleEDtoRDView = (rec) => {
    let url = '/dashboard/home/customercare/details/'+rec._id;
    props.history.push({
      pathname: url,
    });
  }


  const handleBed = (rec) => {
    console.log("rec", rec);
    let transferId;

    if (rec.status === "pending") {
      axios
        .put(getisUpdateCompletedBedTransfer + "/" + rec._id, {
          status: "completed",
        })
        .then((res) => {
          if (res.data.success) {
            console.log("response", res.data.data);
            props.history.push({
              pathname: "/dashboard/home/success",
              state: {
                message1: `Patient is Transferred to Bed`,
              },
            });
          } else {
            setOpenNotification(true);
            setErrorMsg("Cannot submit the request at the moment");
          }
        })
        .catch((e) => {
          setOpenNotification(true);
          setErrorMsg("Cannot submit the request at the moment");
        });
    }/* else if (rec.status === "in_progress") {
      getUpdateTransferEOUtoEDCall(
        props,
        rec._id,
        rec.status,
        setErrorMsg,
        setOpenNotification
      );
      console.log("rec", rec);
    } */else if (rec.status === "completed") {
      setOpenNotification(true);
      setErrorMsg("Already Transferd to ED ");
    }
  }


  const handleEDtoEOU = (rec) => {
    console.log("rec", rec);
    let transferId;

    if (tabName === "transferEDtoRD"){
      if (rec.status === "pending") {
        const params = {
          status: rec.status === "pending" ? "in_progress" : "pending",
          transferId: rec._id,
        };

        axios
          .put(getisUpdateCompletedEouTransfer + "/" + rec._id, {
            status: rec.status === "pending" ? "in_progress" : "pending",
          })
          .then((res) => {
            if (res.data.success) {
              console.log("response", res.data.data);
              props.history.push({
                pathname: "/dashboard/home/success",
                state: {
                  message1: `Patient is In Progress  if completly transfered then go back and update Again`,
                },
              });
            } else {
              setOpenNotification(true);
              setErrorMsg("Cannot submit the request at the moment");
            }
          })
          .catch((e) => {
            setOpenNotification(true);
            setErrorMsg("Cannot submit the request at the moment");
          });


      }
    }else if (tabName === "transferEOUtoED") {
      if (rec.status === "pending") {
        const params = {
          status: rec.status === "pending" ? "in_progress" : "pending",
          transferId: rec._id,
        };

        axios
          .put(getisUpdateCompletedEouTransfer + "/" + rec._id, {
            status: rec.status === "pending" ? "in_progress" : "pending",
          })
          .then((res) => {
            if (res.data.success) {
              console.log("response", res.data.data);
              props.history.push({
                pathname: "/dashboard/home/success",
                state: {
                  message1: `Patient is In Progress  if completly transfered then go back and update Again`,
                },
              });
            } else {
              setOpenNotification(true);
              setErrorMsg("Cannot submit the request at the moment");
            }
          })
          .catch((e) => {
            setOpenNotification(true);
            setErrorMsg("Cannot submit the request at the moment");
          });
      } else if (rec.status === "in_progress") {
        getUpdateTransferEOUtoEDCall(
          props,
          rec._id,
          rec.status,
          setErrorMsg,
          setOpenNotification
        );
        console.log("rec", rec);
      } else if (rec.status === "completed") {
        setOpenNotification(true);
        setErrorMsg("Already Transferd to ED ");
      }
    } else if (tabName === "transferEDtoEOU") {
      if (rec.status === "pending") {
        const params = {
          status: rec.status === "pending" ? "in_progress" : "pending",
          transferId: rec._id,
        };
        axios
          .put(getisUpdateCompletedEouTransfer + "/" + rec._id, {
            status: rec.status === "pending" ? "in_progress" : "pending",
          })
          .then((res) => {
            if (res.data.success) {
              console.log("params", params);
              console.log("response", res.data.data);
              props.history.push({
                pathname: "/dashboard/home/success",
                state: {
                  message1: `Patient is In Progress  if completly transfered then go back and Update Again`,
                },
              });
            } else {
              setOpenNotification(true);
              setErrorMsg("Cannot submit the request at the moment now");
            }
          })
          .catch((e) => {
            setOpenNotification(true);
            setErrorMsg("Cannot submit the request at the moment");
          });
      } else if (rec.status === "in_progress") {
        getUpdateTransferEDtoEOUCall(
          props,
          rec._id,
          rec.status,
          setErrorMsg,
          setOpenNotification
        );
        console.log("rec", rec);
      } else {
        setOpenNotification(true);
        setErrorMsg("Already Transferd Patient");
      }
    }
  };
  const handleDischargeNew = (rec) => {
    console.log("rec", rec);

    if (rec.status === "pending") {
      getUpdateCompleteDischargeCall(
        props,
        rec._id,
        setErrorMsg,
        setOpenNotification
      );
      console.log("rec", rec);
    } else {
      setOpenNotification(true);
      setErrorMsg("Already Completed");
    }
  };
  const handleMedicationView = (rec) => {
    console.log("this is view for Medication", rec);

    props.history.push({
      pathname: "/dashboard/home/medicationView",
      state: {
        selectedRecord: rec,
      },
    });

    // if (rec.status === "pending") {
    //   getUpdateCompleteDischargeCall(
    //     props,
    //     rec._id,
    //     setErrorMsg,
    //     setOpenNotification
    //   );
    //   console.log("rec", rec);
    // } else {
    //   setOpenNotification(true);
    //   setErrorMsg("Already Completed");
    // }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        position: "fixed",
        width: "100%",
        height: "100%",
        backgroundColor: "#60d69f",
        overflowY: "scroll",
      }}
    >
      <Header history={props.history} />

      <div className="cPadding">
        <div className="subheader">
          <div>
            <img src={CustomerCareIcon} style={{ marginLeft: "0px" }} />
            <h4>Customer Care Services</h4>
          </div>

          {buttonValue === "pending" ? (
            <Button
              onClick={() => handleButtonChange("completed")}
              style={{
                ...styles.stylesForButton,
                fontSize: matches ? " " : 10,
              }}
              variant="contained"
              color="primary"
            >
              <img src={eye_icon} className="icon-view" />
              &nbsp;&nbsp;
              <strong>Completed Tasks</strong>
            </Button>
          ) : (
            <Button
              onClick={() => handleButtonChange("pending")}
              style={{
                ...styles.stylesForButton,
                fontSize: matches ? " " : 10,
              }}
              variant="contained"
              color="primary"
            >
              <img src={eye_icon} className="icon-view" />
              &nbsp;&nbsp;
              <strong>Pending Tasks</strong>
            </Button>
          )}
        </div>

        <div
          className="container-fluid"
          style={{ paddingLeft: 0, paddingRight: 0 }}
        >
          <div className={classesForTabs.root}>
            <Tabs
              classes={{
                root: classesForTabs.root,
                scroller: classesForTabs.scroller,
              }}
              value={tabName}
              onChange={handleTabChange}
              textColor="primary"
              TabIndicatorProps={{ style: { background: "#12387a" } }}
              centered
            >
              <Tab
                style={{
                  color: "white",
                  borderRadius: 5,
                  outline: "none",
                  color: tabName === "transfertoBed" ? "#12387a" : "#3B988C",
                }}
                label="Transfer to Bed"
                value="transfertoBed"
              />
              <Tab
                style={{
                  color: "white",
                  borderRadius: 5,
                  outline: "none",
                  color: tabName === "transferEDtoEOU" ? "#12387a" : "#3B988C",
                }}
                label="Transfer ED to EOU"
                value="transferEDtoEOU"
              />
              <Tab
                style={{
                  color: "white",
                  borderRadius: 5,
                  outline: "none",
                  color: tabName === "transferEOUtoED" ? "#12387a" : "#3B988C",
                }}
                label="Transfer EOU to ED"
                value="transferEOUtoED"
              />
              <Tab
                style={{
                  color: "white",
                  borderRadius: 5,
                  outline: "none",
                  color: tabName === "transferEDtoRD" ? "#12387a" : "#3B988C",
                }}
                label="Transfer ED to RD"
                value="transferEDtoRD"
              />
              <Tab
                style={{
                  color: "white",
                  borderRadius: 5,
                  outline: "none",
                  color: tabName === "transferRDtoED" ? "#12387a" : "#3B988C",
                }}
                label="Transfer RD to ED"
                value="transferRDtoED"
              />
              <Tab
                style={{
                  color: "white",
                  borderRadius: 5,
                  outline: "none",
                  color: tabName === "ambulancerequest" ? "#12387a" : "#3B988C",
                }}
                label="Ambulance Request"
                value="ambulancerequest"
              />
              <Tab
                style={{
                  color: "white",
                  borderRadius: 5,
                  outline: "none",
                  color:
                    tabName === "medicationsRequest" ? "#12387a" : "#3B988C",
                }}
                label="Medications Request"
                value="medicationsRequest"
              />
              <Tab
                style={{
                  color: "white",
                  borderRadius: 5,
                  outline: "none",
                  color: tabName === "discharge" ? "#12387a" : "#3B988C",
                }}
                label="Discharge/Disposition"
                value="discharge"
              />
            </Tabs>
          </div>
          <div className="row">
            {
            transferDataArray && tabName === "transferEDtoRD" ? (
              loading ? (
                <div style={{ margin: "auto", width: "0%", padding: 10 }}>
                  <Loader
                    type="TailSpin"
                    color="blue"
                    height={50}
                    width={50}
                  />
                </div>
              ) : (
                Object.entries(transferDataArray).map((obj, entry) => {
                  return (
                    <div className="col-12 col-md-6 col-lg-6 col-xl-6">
                      <div className="card card-detail-info card-detail-small mt-2">
                        <div className="row row-5">
                          <div className="col-sm-12"><strong>PA/Bed#:</strong> <span>{obj[1].roomNo}</span></div>
                          <div className="col-sm-6  mt-3"><strong>Epi.#:</strong> <span>{obj[1].episodeNo}</span></div>
                          <div className="col-sm-6  mt-3"><strong>MRN:</strong> <span>{obj[1].mrn}</span></div>
                          <div className="col-sm-10 mt-3"><strong>Patient:</strong> <span>{obj[1].patientName} / {obj[1].gender==="Female"?"F":"M"} / {obj[1].ageNow}y</span></div>
                          <div className="col-sm-2  mt-3 text-right"><strong>T:</strong> <span>0:00</span></div>
                        </div>
                        <div className="card-status-action">
                          <span className={classNames(`badge`, ((obj[1].status=="completed"||obj[1].status=="arrived")?'badge-success':'status-pending'))}>{obj[1].status}</span>
                          { 
                            obj[1].urgent==="Yes"?<span className="ml-info">!</span>:undefined
                          }
                          <i className="ml-view ml-10 zmdi zmdi-eye zmdi-hc-2x" onClick={() => {
                            handleEDtoRDView(obj[1]);
                          }} />
                        </div>
                      </div>
                    </div>
                  )
                })
              )
            ) : transferBedArray && tabName === "transfertoBed" ? (
              <div className="col-md-12 col-sm-12 col-12">
                {loading ? (
                  <div style={{ margin: "auto", width: "0%", padding: 10 }}>
                    <Loader
                      type="TailSpin"
                      color="blue"
                      height={50}
                      width={50}
                    />
                  </div>
                ) : (
                  <CustomTable
                    tableData={transferBedArray}
                    tableDataKeys={tableDataKeysForTransferBed}
                    tableHeading={tableHeadingsForTransferBed}
                    action={actions}
                    handleAssign={handleBed}
                    borderBottomColor={"#60d69f"}
                    borderBottomWidth={20}
                  />
                )}
              </div>
            ) : transferEDtoEOUArray && tabName === "transferEDtoEOU" ? (
              <div className="col-md-12 col-sm-12 col-12">
                {loading ? (
                  <div style={{ margin: "auto", width: "0%", padding: 10 }}>
                    <Loader
                      type="TailSpin"
                      color="blue"
                      height={50}
                      width={50}
                    />
                  </div>
                ) : (
                  <CustomTable
                    tableData={transferEDtoEOUArray}
                    tableDataKeys={tableDataKeysForTransferEDtoEOU}
                    tableHeading={tableHeadingsForTransferEDtoEOU}
                    action={actions}
                    handleAssign={handleEDtoEOU}
                    borderBottomColor={"#60d69f"}
                    borderBottomWidth={20}
                  />
                )}
              </div>
            ) : transferEOUtoEDArray && tabName === "transferEOUtoED" ? (
              <div className="col-md-12 col-sm-12 col-12">
                {loading ? (
                  <div style={{ margin: "auto", width: "0%", padding: 10 }}>
                    <Loader
                      type="TailSpin"
                      color="blue"
                      height={50}
                      width={50}
                    />
                  </div>
                ) : (
                  <CustomTable
                    tableData={transferEOUtoEDArray}
                    tableDataKeys={tableDataKeysForTransferEDtoEOU}
                    tableHeading={tableHeadingsForTransferEDtoEOU}
                    action={actions}
                    handleAssign={handleEDtoEOU}
                    borderBottomColor={"#60d69f"}
                    borderBottomWidth={20}
                  />
                )}
              </div>
            ) : ambulanceRequestArray && tabName === "ambulancerequest" ? (
              <div className="col-md-12 col-sm-12 col-12">
                {loading ? (
                  <div style={{ margin: "auto", width: "0%", padding: 10 }}>
                    <Loader
                      type="TailSpin"
                      color="blue"
                      height={50}
                      width={50}
                    />
                  </div>
                ) : (
                  <CustomTable
                    tableData={ambulanceRequestArray}
                    tableDataKeys={tableDataKeysForAmbulance}
                    tableHeading={tableHeadingsForAmbulance}
                    action={actions}
                    handleAssign={handleMedication}
                    borderBottomColor={"#60d69f"}
                    borderBottomWidth={20}
                  />
                )}
              </div>
            ) : medicationsRequestArray && tabName === "medicationsRequest" ? (
              <div className="col-md-12 col-sm-12 col-12">
                {loading ? (
                  <div style={{ margin: "auto", width: "0%", padding: 10 }}>
                    <Loader
                      type="TailSpin"
                      color="blue"
                      height={50}
                      width={50}
                    />
                  </div>
                ) : (
                  <CustomTable
                    tableData={medicationsRequestArray}
                    tableDataKeys={tableDataKeysForMedications}
                    tableHeading={tableHeadingsForMedications}
                    action={medicationActions}
                    handleAssign={handleMedication}
                    handleView={handleMedicationView}
                    borderBottomColor={"#60d69f"}
                    borderBottomWidth={20}
                  />
                )}
              </div>
            ) : dischargeArray && tabName === "discharge" ? (
              <div className="col-md-12 col-sm-12 col-12">
                {loading ? (
                  <div style={{ margin: "auto", width: "0%", padding: 10 }}>
                    <Loader
                      type="TailSpin"
                      color="blue"
                      height={50}
                      width={50}
                    />
                  </div>
                ) : (
                  <CustomTable
                    tableData={dischargeArray}
                    tableDataKeys={tableDataKeysForDischarge}
                    tableHeading={tableHeadingsForDischarge}
                    action={actions}
                    // action={actions2}
                    handleAssign={handleDischargeNew}
                    borderBottomColor={"#60d69f"}
                    borderBottomWidth={20}
                  />
                )}
              </div>
            ) : (
              <div className="LoaderStyle">
                <Loader type="TailSpin" color="red" height={50} width={50} />
              </div>
            )}
          </div>

          {/* <h4 style={{ textAlign: 'center' }}>
            Module is under development for now
          </h4> */}

          <div className="row" style={{ marginBottom: 20 }}>
            <div className="col-md-12 col-sm-12 col-12">
              <img
                onClick={() => props.history.goBack()}
                src={Back}
                style={{
                  width: 45,
                  height: 35,
                  cursor: "pointer",
                }}
              />
            </div>
          </div>
        </div>

        <Notification msg={errorMsg} open={openNotification} />
      </div>
    </div>
  );
}
