/*eslint-disable*/
import React, { useState, useEffect } from 'react'
// import Button from '@material-ui/core/Button'
// import Notification from '../../components/Snackbar/Notification.js'
// import CustomTable from '../../components/Table/Table'
// import Loader from 'react-loader-spinner'
// import Header from '../../components/Header/Header'
// import TransferIcon from '../../assets/img/EOU.png'
// import eye_icon from '../../assets/img/Eye.png'
// import Back from '../../assets/img/Back_Arrow.png'
// import '../../assets/jss/material-dashboard-react/components/loaderStyle.css'
// import cookie from 'react-cookies'
// import { styles, useStyles } from './styles.js'
// import useMediaQuery from '@material-ui/core/useMediaQuery'

// import {
//   getPatientsInDept,
//   getAllTransferRequests,
// } from './networkCallsForBlueTeam'

// const tableHeading = [
//   'MRN',
//   'Patient Name',
//   'Production Area',
//   'Room No',
//   'Called By',
// ]

// const tableDataKeys = ['mrn', 'name', 'area', 'room', 'customerCare']

// const actionsForPending = { edit: false }
// const actionsForCompleted = { view: false }

export default function EOUfromED(props) {
	return (
		<div>
			View Blue Team Request
		</div>
	)
}

// export default function EOUfromED(props) {
//   const matches = useMediaQuery('(min-width:600px)')
//   const [currentUser] = useState(cookie.load('current_user'))
//   const [generalArray, setGeneralArray] = useState([])
//   const [filteredResponse, setFilteredResponse] = useState('')

//   const [errorMsg, setErrorMsg] = useState('')
//   const [openNotification, setOpenNotification] = useState(false)
//   const [statusName, setStatusName] = useState('Pending')
//   const [openEditModal, setOpenEditModal] = useState(false)
//   const [selectedPatient, setSelectedPatient] = useState('')
//   const [patientsInDept, setPatientsInDept] = useState('')

//   if (openNotification) {
//     setTimeout(() => {
//       setOpenNotification(false)
//       setErrorMsg('')
//     }, 2000)
//   }

//   useEffect(() => {
//     getAllTransferRequests(
//       setGeneralArray,
//       setOpenNotification,
//       setErrorMsg,
//       currentUser._id
//     )
//   }, [])

//   useEffect(() => {}, [])

//   return (
//     <div
//       style={{
//         display: 'flex',
//         flexDirection: 'column',
//         flex: 1,
//         position: 'fixed',
//         width: '100%',
//         height: '100%',
//         backgroundColor: '#60d69f',
//         overflowY: 'scroll',
//       }}
//     >
//       <Header history={props.history} />

//       <div className='cPadding'>
//         <div className='subheader'>
//           <div>
//             <h4>Code Blue Team Requests</h4>
//           </div>
//         </div>

//         <div className='container-fluid'>
//           <div className='row'>
//             {/* {filteredResponse.length ? ( */}
//             <div
//               className='col-md-12 col-sm-12 col-12'
//               style={{ paddingLeft: 3, paddingRight: 0 }}
//             >
//               <CustomTable
//                 tableData={filteredResponse}
//                 tableDataKeys={tableDataKeys}
//                 tableHeading={tableHeading}
//                 action={''}
//                 borderBottomColor={'#60d69f'}
//                 borderBottomWidth={20}
//               />
//             </div>
//             {/* ) : (
//               <div className='LoaderStyle'>
//                 <Loader type='TailSpin' color='red' height={50} width={50} />
//               </div>
//             )} */}
//           </div>

//           <div className='row' style={{ marginBottom: 20 }}>
//             <div className='col-md-12 col-sm-12 col-12'>
//               <img
//                 onClick={() => props.history.goBack()}
//                 src={Back}
//                 style={{
//                   width: 45,
//                   height: 35,
//                   cursor: 'pointer',
//                 }}
//               />
//             </div>
//           </div>
//         </div>

//         <Notification msg={errorMsg} open={openNotification} />
//       </div>
//     </div>
//   )
// }
