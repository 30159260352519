import React, { useState, useEffect, useReducer } from "react";
import Notification from "../../components/Snackbar/Notification.js";
import CustomTable from "../../components/Table/Table";
import _ from "lodash";
import Button from "@material-ui/core/Button";
import Loader from "react-loader-spinner";
import Back from "../../assets/img/Back_Arrow.png";
import Header from "../../components/Header/Header";
import CareStreamIcon from "../../assets/img/CareStream in Progress.png";
import Fingerprint from "../../assets/img/fingerprint.png";
import AccountCircle from "@material-ui/icons/SearchOutlined";
import InputAdornment from "@material-ui/core/InputAdornment";
import BarCode from "../../assets/img/Bar Code.png";
import TextField from "@material-ui/core/TextField";
import useStyles from "../../assets/jss/material-dashboard-react/greyInputStyle";
import styles from "./styles";
import "../../assets/jss/material-dashboard-react/components/loaderStyle.css";
import cookie from "react-cookies";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import {
  getAllEdrPatients,
  getAllEOUPatients,
  searchEdrPatientsFor,
  getCSInProgress,
  completeCareStreamCall,
  getCompletedCSCall,
  getCSInProgressForDoctors,
} from "./NetworkCalls";
import QRCodeScannerComponent from "../../components/QRCodeScanner/QRCodeScanner";

const tableHeading = [
  "MRN No",
  "Request No",
  "Patient Name",
  "Date/Time",
  "Status",
  "Action",
];
const tableDataKeys = [
  "mrn",
  "requestNo",
  "patientName",
  "createdAt",
  "careStatus",
];

const tableHeadingForED = [
  "MRN No",
  "Patient Name",
  "careStreamName",
  "Date/Time",
  "Status",
  "Action",
];
const tableDataKeysForED = [
  "mrn",
  "patientName",
  "careStreamName",
  "createdAt",
  "careStatus",
];

const actions = { edit: true };

export default function CSinProgress(props) {
  const matches = useMediaQuery("(min-width:600px)");
  const classes = useStyles();

  const [currentUser] = useState(cookie.load("current_user"));
  const [careStreamData, setCareStreamData] = useState([]);
  const [careStreamCompleted, setCareStreamCompleted] = useState([]);

  const [errorMsg, setErrorMsg] = useState("");
  const [openNotification, setOpenNotification] = useState(false);
  const [searchPatientQuery, setSearchPatientQuery] = useState("");
  const [loading, setLoading] = useState(true);
  const [QRCodeScanner, setQRCodeScanner] = useState(false);
  const [activeInProgress, setActiveInProgress] = useState(true);
  const [selectedCareStream, setSelectedCareStream] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [buttonValue, setButtonValue] = useState("pending");

  if (openNotification) {
    setTimeout(() => {
      setOpenNotification(false);
      setErrorMsg("");
    }, 2000);
  }

  useEffect(() => {
    if (cookie.load("current_user").staffType === "Doctor") {
      getCSInProgressForDoctors(
        setLoading,
        setCareStreamData,
        setErrorMsg,
        setOpenNotification
      );
    } else {
      getCSInProgress(
        setLoading,
        setCareStreamData,
        setErrorMsg,
        setOpenNotification
      );
    }

    console.log("careStreamData", careStreamData);

    // if (pathName === 'ED') {
    //   getAllEdrPatients(
    //     setLoading,
    //     setCareStreamData,
    //     setErrorMsg,
    //     setOpenNotification
    //   )
    // }
    // else if (pathName === 'EOU') {
    //   getAllEOUPatients(
    //     setLoading,
    //     setCareStreamData,
    //     setErrorMsg,
    //     setOpenNotification
    //   )
    // }
  }, []);

  const handleButtonChange = (buttonValue) => {
    console.log("buttonValue", buttonValue);
    setButtonValue(buttonValue);
    if (buttonValue === "completed") {
      getCompletedCSCall(
        setLoading,
        setCareStreamCompleted,
        setErrorMsg,
        setOpenNotification
      );
    } else if (buttonValue === "pending") {
      getCSInProgressForDoctors(
        setLoading,
        setCareStreamData,
        setErrorMsg,
        setOpenNotification
      );
    }
  };

  function handleEdit(rec) {
    console.log("Edit Record", rec);

    if (currentUser.staffType === "Doctor") {
      if (rec.careStatus === "completed") {
        setOpenNotification(true);
        setErrorMsg("CareStream is aleady completed");
      } else {
        setSelectedCareStream(rec);
        setOpenDialog(true);
      }
    } else {
      let path = `/dashboard/home/notes`;
      props.history.push({
        pathname: path,
        state: {
          selectedRecord: rec,
        },
      });
    }
  }

  function hideDialog() {
    setOpenDialog(false);
  }

  function handleComplete(rec) {
    const body = {
      edrId: rec._id,
      csId: rec.careStream._id,
      staffId: currentUser._id,
    };
    console.log("rec", rec);
    completeCareStreamCall(body, setErrorMsg, setOpenNotification);
  }

  // const handlePatientSearch = (e) => {
  //   const a = e.target.value.replace(/[^\w\s]/gi, "");
  //   setSearchPatientQuery(a);
  //   if (a.length >= 3) {
  //     searchEdrPatientsFor(
  //       a,
  //       setCareStreamData,
  //       setErrorMsg,
  //       setOpenNotification
  //     );
  //   } else if (a.length == 0) {
  //   }
  // };

  function scanQRCode() {
    setQRCodeScanner((pervState) => !pervState);
  }

  function handleScanQR(data) {
    setQRCodeScanner(false);
    console.log("json", JSON.parse(data));
    if (JSON.parse(data).profileNo) {
      // handlePatientSearch({
      //   target: {
      //     value: JSON.parse(data).profileNo,
      //     type: "text",
      //   },
      // });
    }
  }

  if (QRCodeScanner) {
    return (
      <div>
        {QRCodeScanner ? (
          <QRCodeScannerComponent
            handleScanQR={handleScanQR}
            scanQRCode={scanQRCode}
          />
        ) : (
          undefined
        )}
      </div>
    );
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        position: "fixed",
        width: "100%",
        height: "100%",
        backgroundColor: "rgb(19 213 159)",
        overflowY: "scroll",
      }}
    >
      <Header history={props.history} />

      <div className="cPadding">
        <div className="subheader">
          <div>
            <img src={CareStreamIcon} />
            <h4>
              {buttonValue === "completed"
                ? "CareStream Completed"
                : "CareStream In-Progress"}
            </h4>
          </div>

          {currentUser.subType[0] === "ED Doctor" ? (
            <>
              {buttonValue === "pending" ? (
                <Button
                  onClick={() => handleButtonChange("completed")}
                  style={{
                    ...styles.stylesForButton,
                    fontSize: matches ? " " : 10,
                  }}
                  variant="contained"
                  color="primary"
                >
                  {/* <img src={eye_icon} className='icon-view' /> */}
                  &nbsp;&nbsp;
                  <strong>Completed CareStream</strong>
                </Button>
              ) : (
                <Button
                  onClick={() => handleButtonChange("pending")}
                  style={{
                    ...styles.stylesForButton,
                    fontSize: matches ? " " : 10,
                  }}
                  variant="contained"
                  color="primary"
                >
                  {/* <img src={eye_icon} className='icon-view' /> */}
                  &nbsp;&nbsp;
                  <strong>In Progress CareStream</strong>
                </Button>
              )}
            </>
          ) : (
            undefined
          )}
          {/* {activeInProgress ? (
            <Button
              // onClick={inprogresCareStream}
              style={{
                ...styles.stylesForButton,
                backgroundColor: "#2973CF",
                fontSize: matches ? 12 : 10,
              }}
              // className={classes.careStreamMedicine}
              variant="contained"
              color="primary"
            >
              <strong>Completed CareStream</strong>
            </Button>
          ) : (
            <Button
              // onClick={inprogresCareStream}
              style={{
                ...styles.stylesForButton,
                backgroundColor: "#2973CF",
                fontSize: matches ? 12 : 10,
              }}
              // className={classes.careStreamMedicine}
              variant="contained"
              color="primary"
            >
              <strong>In Progress CareStream</strong>
            </Button>
          )} */}
        </div>

        <div className={`container-fluid ${classes.root}`}>
          {/* <div className="row" style={{ marginTop: "20px" }}>
            <div
              className="col-md-10 col-sm-9 col-8"
              style={{ ...styles.textFieldPadding, paddingLeft: "15px" }}
            >
              <TextField
                className="textInputStyle"
                id="searchPatientQuery"
                type="text"
                variant="filled"
                label="Search Patient by Name / MRN / National ID / Mobile Number"
                name={"searchPatientQuery"}
                value={searchPatientQuery}
                onChange={handlePatientSearch}
                InputLabelProps={{
                  classes: {
                    root: classes.label,
                    focused: classes.focusedLabel,
                    error: classes.erroredLabel,
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <AccountCircle />
                    </InputAdornment>
                  ),
                  className: classes.input,
                  classes: { input: classes.input },
                  disableUnderline: true,
                }}
              />
            </div>
            <div
              className="col-md-1 col-sm-2 col-2"
              style={{
                paddingLeft: matches ? "" : 0,
                paddingRight: matches ? "" : 3,
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "white",
                  borderRadius: 5,
                  height: 55,
                }}
              >
                <img
                  src={BarCode}
                  onClick={scanQRCode}
                  style={{ width: 70, height: 60 }}
                />
              </div>
            </div>
            <div
              className="col-md-1 col-sm-1 col-2"
              style={{
                paddingLeft: matches ? "5" : 2,
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "white",
                  borderRadius: 5,
                  height: 55,
                }}
              >
                <img src={Fingerprint} style={{ maxWidth: 43, height: 43 }} />
              </div>
            </div>
          </div> */}
        </div>

        <div className="container-fluid">
          <div className="row">
            {(careStreamData.length > 0 && !loading) || careStreamCompleted ? (
              <div className="col-12" style={{ paddingRight: 0 }}>
                <CustomTable
                  tableData={
                    buttonValue === "completed"
                      ? careStreamCompleted
                      : careStreamData
                  }
                  tableDataKeys={
                    currentUser.subType[0] === "ED Doctor"
                      ? tableDataKeysForED
                      : tableDataKeys
                  }
                  tableHeading={
                    currentUser.subType[0] === "ED Doctor"
                      ? tableHeadingForED
                      : tableHeading
                  }
                  action={actions}
                  handleEdit={handleEdit}
                  borderBottomColor={"#60d69f"}
                  borderBottomWidth={20}
                />
              </div>
            ) : loading ? (
              <div style={{ margin: "auto", width: "0%", padding: 10 }}>
                <Loader type="TailSpin" color="blue" height={50} width={50} />
              </div>
            ) : (
              <div className="col-12">
                <h3
                  style={{
                    color: "white",
                    textAlign: "center",
                    margin: "revert",
                  }}
                >
                  Opss! No Data Found
                </h3>
              </div>
            )}
          </div>

          <div
            className="row "
            style={{ marginTop: "25px", marginBottom: "20px" }}
          >
            <div className="col-12">
              <img
                onClick={() => props.history.goBack()}
                src={Back}
                style={{
                  width: 45,
                  height: "auto",
                  cursor: "pointer",
                }}
              />
            </div>
          </div>
        </div>

        <div>
          <Dialog
            open={openDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth={true}
          >
            <DialogTitle id="alert-dialog-title">
              <h5
                style={{
                  fontWeight: "bold",
                  marginTop: 10,
                  textAlign: "center",
                }}
              >
                {`'${selectedCareStream &&
                  selectedCareStream.careStream.careStreamId.name}'` +
                  " is already In Progress to this EDR"}
              </h5>

              <h5
                style={{
                  fontWeight: "bold",
                  marginTop: 10,
                  textAlign: "center",
                }}
              >
                Do you want to complete this caresteam?
              </h5>
            </DialogTitle>
            <DialogActions>
              <div className={`container-fluid ${classes.root}`}>
                <div
                  class="row"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    paddingLeft: 5,
                    paddingRight: 5,
                    marginTop: 20,
                  }}
                >
                  <div style={{ marginTop: "2%", marginBottom: "2%" }}>
                    <Button
                      onClick={() => hideDialog()}
                      style={{
                        ...styles.stylesForButton,
                        backgroundColor: "white",
                        color: "grey",
                        width: matches ? " " : 150,
                      }}
                      variant="contained"
                    >
                      <strong>Cancel</strong>
                    </Button>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      marginTop: "2%",
                      marginBottom: "2%",
                    }}
                  >
                    <Button
                      style={{
                        ...styles.stylesForSubmitButton,
                      }}
                      onClick={() => handleComplete(selectedCareStream)}
                      variant="contained"
                      color="primary"
                    >
                      Yes
                    </Button>
                  </div>
                </div>
              </div>
            </DialogActions>
          </Dialog>
        </div>
        <Notification msg={errorMsg} open={openNotification} />
      </div>
    </div>
  );
}
