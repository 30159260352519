import React, { useEffect } from "react";
import EpisodeRegistration from "../../components/ShortPatientRegistration/EpisodeRegistration";

import useMediaQuery from "@material-ui/core/useMediaQuery";
import {
  patientRegistration,
  updatePatientUrl,
  SearchPatients,
  getPatientByIdUrl,
} from "../../public/endpoins";
import Header from "../../components/Header/Header";
import patientRegister from "../../assets/img/PatientRegistration.png";
import "../../assets/jss/material-dashboard-react/components/TextInputStyle.css";

let matches;

function EpisoreRegistration(props) {
  matches = useMediaQuery("(min-width:600px)");

  const getSaveData = (data) => {
    console.log("dataaa", data);
  };

  useEffect(() => {
    // setCountries(Object.keys(countriesList[0]))
    console.log("props", props);
    // console.log('current user', )
  }, []);

  return (
    <EpisodeRegistration
      SaveData={getSaveData}
      props={props}
      patientRegistration={patientRegistration}
      updatePatient={updatePatientUrl}
      SearchPatients={SearchPatients}
      getPatientByIdUrl={getPatientByIdUrl}
    />
    //   </div>
    // </div>
  );
}
export default EpisoreRegistration;
