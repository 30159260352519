/* eslint-disable react/jsx-indent */
/* eslint-disable array-callback-return */
import Notification from "../../components/Snackbar/Notification.js";
import React, { useEffect, useState, useReducer } from "react";

// import { makeStyles } from '@material-ui/core/styles';
import { ToastsStore } from "react-toasts";
import cookie from 'react-cookies'

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,

  Button,
  Select,
  TextField,
  FormControl,
  InputLabel,
  MenuItem
} from "@material-ui/core";



import CashIcon from '@material-ui/icons/LocalAtm';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';


import { Redirect } from "react-router-dom";

import Header from "../../components/Header/Header";

import Back from "../../assets/img/Back.png";
import Home from "../../assets/img/Home.png";

import ThankYou from "../../assets/img/ThankYou.png";

import { connect } from "react-redux";
import {
  funForSaga,
  funForReducer,
  setPatientDetailsForReducer,
} from "../../actions/Checking";

import { getSingleEdr, addDepositForPatient } from '../../public/endpoins'
import axios from 'axios'
import { displayInHumanDifference, replacePatientIdIfNull } from "../../utils/commonFunc";

var boldSentence = " ";

function PendingEpisodeAssociatedSuccess(props) {

  const currentUser = cookie.load('current_user') || ''

  const [goToHome, setGoToHome] = useState(false);
  const [goBackScreen, setGoBackScreen] = useState(false);
  const [edrId, setEdrId] = useState("");
  const [edrData, setEdrData] = useState(null);

  const [errorMsg, setErrorMsg] = useState("")
  const [successMsg, setSuccessMsg] = useState("")
  const [openNotification, setOpenNotification] = useState(false)

  const [showAddDepositsForm, setShowAddDepositsForm] = useState(false);
  const [submitButtonVisible, setSubmitButtonVisible] = useState(false);
  const [submissionError, setSubmissionError] = useState('');

  const initialState = {
    currentCurrencyCode: "JOD",
    currentCurrencyValue: "",
    currencyRateJOD: 1,
    currentCurrencyValueJord: "",
    depositType: 'cash',
    depositorName: '',
    staffId: currentUser._id,
  };

  function reducer(state, { field, value }) {
    return {
      ...state,
      [field]: value,
    };
  }

  const [state, dispatch] = useReducer(reducer, initialState);
  const {
    currentCurrencyCode,
    currentCurrencyValue,
    currencyRateJOD,
    currentCurrencyValueJord,
    staffId,
    depositType,
    depositorName,
  } = state;


  const handleAddDepositsModelClose = () => {
    setShowAddDepositsForm(false);
    dispatch({ field: 'currentCurrencyCode', value: "JOD" });
    dispatch({ field: 'currentCurrencyValue', value: "" });
    dispatch({ field: 'currencyRateJOD', value: "" });
    dispatch({ field: 'currentCurrencyValueJord', value: "" });
  }

  const onChangeValue = (e) => {
    dispatch({
      field: e.target.name,
      value: e.target.value,
    });
  }

  const displayButtonForSubmit = () => {
    setSubmissionError('');
    if (currentCurrencyCode === "JOD"){
      if (currentCurrencyValue > 0){
        handleSubmit();
      }else{
        setSubmissionError('Please fill up the form correctly!');
      }
    }else{
      if (currentCurrencyValue > 0 && currentCurrencyValueJord > 0){
        handleSubmit();
      }else{
        setSubmissionError('Please fill up the form correctly!');
      }
    }
  }

  const handleSubmit = () => {
    let submitCode = {
      currencyCode: currentCurrencyCode,
      amount: currentCurrencyCode==="JOD"?currentCurrencyValue:currentCurrencyValueJord,
      exchange: currentCurrencyCode==="JOD"?0:currentCurrencyValue,
      staffId,
      depositType,
      depositorName,
      patientId: edrData.patientId._id,
      edrId,
    };
    console.log(submitCode);

    axios
      .post(addDepositForPatient, submitCode)
      .then((res) => {
        if (res.data.success) {
          // 
          handleAddDepositsModelClose();
          setOpenNotification(true);
          setSuccessMsg("Deposit successfully saved.");
        } else if (!res.data.success) {
          setOpenNotification(true);
          setErrorMsg("There is some error in the API Endpoint.");
        }
      })
      .catch((e) => {
        setSubmissionError(e.error);
      });
  }


  if (openNotification) {
    setTimeout(() => {
      setOpenNotification(false)
      setErrorMsg("")
    }, 2000)
  }

  useEffect(() => {
    // EDR ID

    let edrIds = props.history.location.state.edrId;
    setEdrId(edrIds);


    console.log(props.history.location.state.patientDetails);
    console.log(props.history.location.state.message1, "message1");
    console.log(props.history.location.state.message2, "message2");
    console.log(props.history.location.state.code, "code");
    // var str = props.history.location.state.message;
    // var a = str.split(" ");
    // var mrn = a.find(checkMRN);
    // console.log(a.find(checkMRN), "aftr split");
    // boldSentence = " ";
    // boldSentence = str.replace(`/${mrn}/gi`, `<strong>${mrn}</strong>`);

    // console.log(boldSentence, "bold Sentance");

    if (
      props.history.location.state &&
      props.history.location.state.patientDetails
    ) {
      props.setPatientDetailsForReducer(
        props.history.location.state.patientDetails
      );
    }
  }, []);

  useEffect(() => {
    if (currentCurrencyCode !== "" && currentCurrencyCode.toLowerCase()!=="usd"){
      let currencyCode = currentCurrencyCode.toLowerCase();
      axios
      .get(`https://cdn.jsdelivr.net/gh/fawazahmed0/currency-api@1/latest/currencies/${currencyCode}/jod.json`)
      .then((res) => {
        dispatch({
          field: 'currencyRateJOD',
          value: res.data.jod,
        });
        return res;
      })
      .catch((e) => {
        console.log("getPatientEdrDetails:: error: ", e);
      });
    }else{
      dispatch({
        field: 'currencyRateJOD',
        value: 1,
      });
    }
  }, [currentCurrencyCode]);

  useEffect(() => {
    if (edrId){
      axios
      .get(`${getSingleEdr}/${edrId}`)
      .then((res) => {
        let dataReturn = replacePatientIdIfNull(res.data.data);
        console.log('singleEdr', dataReturn);
        setEdrData(dataReturn);
        return res;
      })
      .catch((e) => {
        console.log("getPatientEdrDetails:: error: ", e);
      });
    }
  }, [edrId])

  function checkMRN(details) {
    return details.includes("KHMC");
  }

  if (goToHome) {
    // props.history.replace('', null);
    console.log(boldSentence, "bold Sentance 2");
    props.setPatientDetailsForReducer("");
    return <Redirect to="/dashboard" />;
  }

  return (
    <div
      style={{
        position: "fixed",
        display: "flex",
        width: "100%",
        height: "100%",
        flexDirection: "column",
        flex: 1,
        // backgroundImage: `url("${ThankYou}")`,
        backgroundColor: "pink",
        backgroundSize: "100%",
      }}
    >
      {/* <div style={{ alignItems: 'center', flex: 1, display: 'flex' }}> */}
      <Header history={props.history} />
      {/* </div> */}

      <div
        className="thankyou"
        style={{
          alignItems: "center",
          flex: 4,
          display: "flex",
          marginTop: -600,
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <h1
          style={{
            color: "white",
            fontWeight: "bold",
            fontSize: 55,
            marginTop: 400,
          }}
        >Thank You!</h1>
        <p
          style={{
            color: "white",
            fontSize: 24,
            maxWidth: "83%",
            textAlign: "center",
          }}
        >{props.history.location.state.code}</p>
        <div className="container">
        <div className="row">
          <div className="col-md-6 offset-md-3">
            <div className="card">
              <div className="card-body" style={{
                color: "black",
                fontSize: 24,
                textAlign: "center",
              }}>The following processes are available!
              </div>
              
              <div className="card-body pt-0">
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  style={{
                    backgroundColor: "#2973CF",
                    borderRadius: 10,
                    textTransform: "none",
                  }}
                  onClick={() => {
                    // 👇 Open link in new tab programmatically
                    window.open(`/printQRBracelets?edrId=${props.history.location.state.edrId}&nameDisplay=yes`, '_blank', 'noreferrer');
                  }}
                >Print Bracelet</Button>
                {
                edrData ? 
                <div>
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    style={{
                      backgroundColor: "#2973CF",
                      borderRadius: 10,
                      textTransform: "none",
                    }}
                    onClick={(e) => {
                      setShowAddDepositsForm(true);
                    }}
                    className="mt-2"
                  >Add Down Payment</Button>
                  {/*
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    style={{
                      backgroundColor: "#2973CF",
                      borderRadius: 10,
                      textTransform: "none",
                    }}
                    startIcon={<CashIcon />}
                    onClick={(e) => {
                      
                    }}
                    className="mt-2"
                  >View All Deposits</Button>
                  */}
                </div>
                :undefined
                }
              </div> 
            </div>
          </div>
        </div>
        </div>

        <Dialog open={showAddDepositsForm} fullWidth="true" maxWidth={"sm"} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Add Down Payment</DialogTitle>
          <DialogContent>
            {
              submissionError?
              <DialogContentText className="alert alert-danger mb-4">{submissionError}</DialogContentText>
              :undefined
            }
            <div className="row">
              <div className="col-md-5 col-12">
                <div className="form-group" fullWidth="true">
                  <FormControl variant="outlined" fullWidth="true">
                    <TextField
                      required
                      select
                      fullWidth
                      variant="outlined"
                      id="currentCurrencyCode"
                      name="currentCurrencyCode"
                      onChange={onChangeValue}
                      label="Currency"
                      variant="outlined"
                      className="dropDownStyle"
                      InputProps={{
                        
                      }}
                      value={currentCurrencyCode}
                    >
                      <MenuItem value="JOD">Jordanian dinar</MenuItem>
                      <MenuItem value="USD">US Dollar</MenuItem>
                      <MenuItem value="CAD">Canadian Dollar</MenuItem>
                      <MenuItem value="GBP">British Sterling Pound</MenuItem>
                      <MenuItem value="EUR">Euro</MenuItem>
                    </TextField>

                  </FormControl>
                </div>
              </div>
              
              <div className="col-md-7 col-12">
                <div className="form-group">
                  <FormControl variant="outlined" fullWidth="true">
                    <TextField
                      required
                      fullWidth
                      variant="outlined"
                      id="currentCurrencyValue"
                      name="currentCurrencyValue"
                      type="number"
                      onChange={onChangeValue}
                      label="Enter Down Payment Amount"
                      variant="outlined"
                      className="dropDownStyle"
                      InputProps={{
                        
                      }}
                      value={currentCurrencyValue}
                    />

                  </FormControl>
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group" fullWidth="true">
                  <FormControl variant="outlined" fullWidth="true">
                    <TextField
                      required
                      select
                      fullWidth
                      variant="outlined"
                      id="depositType"
                      name="depositType"
                      onChange={onChangeValue}
                      label="Deposit Type"
                      variant="outlined"
                      className="dropDownStyle"
                      InputProps={{
                        
                      }}
                      value={depositType}
                    >
                      <MenuItem value="cash">Cash</MenuItem>
                      <MenuItem value="debitcard">Debit</MenuItem>
                      <MenuItem value="check">Check</MenuItem>
                      <MenuItem value="creditcard">Credit card</MenuItem>
                      <MenuItem value="amountblocked">Amount Blocked - Credit card</MenuItem>
                    </TextField>

                  </FormControl>
                </div>
              </div>
              {
                currentCurrencyCode.toLowerCase() !="jod"?
                <div className="col-md-12">
                <div className="form-group">
                  <FormControl variant="outlined" fullWidth="true">
                    <TextField
                      required
                      fullWidth
                      variant="outlined"
                      id="currentCurrencyValueJord"
                      name="currentCurrencyValueJord"
                      type="number"
                      onChange={onChangeValue}
                      label="Enter Amount after Conversion"
                      variant="outlined"
                      className="dropDownStyle"
                      InputProps={{
                        
                      }}
                      value={currentCurrencyValueJord}
                    />

                  </FormControl>
                </div>
                </div>:undefined
              }
              <div className="col-md-12">
                <div className="form-group">
                  <FormControl variant="outlined" fullWidth="true">
                    <TextField
                      required
                      variant="outlined"
                      id="depositorName"
                      name="depositorName"
                      onChange={onChangeValue}
                      label="Depositor Name "
                      variant="outlined"
                      className="dropDownStyle"
                      InputProps={{
                        
                      }}
                      value={depositorName}
                    />

                  </FormControl>
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <FormControl variant="outlined" fullWidth="true">
                    <TextField
                      fullWidth
                      readOnly
                      variant="outlined"
                      id="currentCurrencyValue"
                      name="currentCurrencyValue"
                      label="Receiver Name"
                      variant="outlined"
                      className="dropDownStyle"
                      InputProps={{
                        
                      }}
                      value={currentUser.name[0].given[0] + " " + currentUser.name[0].family}
                    />

                  </FormControl>
                </div>
              </div>
            </div>
            <DialogActions>
              <Button onClick={handleAddDepositsModelClose} color="primary">Close</Button>
              <Button color="primary" onClick={displayButtonForSubmit} autoFocus>Submit</Button>
            </DialogActions>
          </DialogContent>
        </Dialog>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            onClick={() => setGoToHome(true)}
            src={Home}
            style={{ maxWidth: "40%", cursor: "pointer" }}
          />
        </div>


        <Notification 
          msg={errorMsg}
          success={successMsg}
          open={openNotification} />
      </div>
    </div>
  );
}

const mapStateToProps = ({ CheckingReducer }) => {
  const { count, patientDetails } = CheckingReducer;
  return { count, patientDetails };
};
export default connect(mapStateToProps, {
  funForReducer,
  setPatientDetailsForReducer,
})(PendingEpisodeAssociatedSuccess);
