import axios from "axios";
import _ from "lodash";
import {
  getExternalConsultants,
  getSearchedExternalConsultants,
} from "../../public/endpoins";

export const getExternalConsultantData = (
  setOriginalExternalConsultantData,
  setDoctorTypes
) => {
  axios
    .get(getExternalConsultants)
    .then((res) => {
      const { data } = res.data;
      const mappedData = toMapData(data);
      setOriginalExternalConsultantData(mappedData);
      const doctorSpecialities = ["None"];
      data.forEach(({ specialty }) => {
        if (specialty && specialty.length === 1) {
          doctorSpecialities.push(specialty[0]);
        } else if (specialty && specialty.length) {
          specialty.forEach((item) => {
            doctorSpecialities.push(item);
          });
        }
      });
      const uniqueDoctorSpecialities = [...new Set(doctorSpecialities)];
      setDoctorTypes(uniqueDoctorSpecialities);
    })
    .catch((e) => {
      console.log("error: ", e);
    });
};

export const getSearchedConsultant = (
  a,
  setOriginalExternalConsultantData,
  setDoctorSpecialities
) => {
  axios
    .get(getSearchedExternalConsultants + "/" + a)
    .then((res) => {
      const { data } = res.data;
      const filteredData = data.map(({identifier, specialty, name, experience, chiefComplaint}) => ({identifier, specialty, name, experience, chiefComplaint}));
      const mappedData = toMapData(filteredData);
      setOriginalExternalConsultantData(mappedData);
    })
    .catch((err) => {
      console.log("Error after searching patient request", err);
    });
};

const toMapData = (data) => {
  return data.map(
    (
      { specialty, identifier, name: doctorName, experience, chiefComplaint },
      index
    ) => ({
      "Doctor Id": identifier[0].value,
      Speciality: specialty.join(", "),
      "Doctor Name": doctorName.map(
        ({ given, family }) => given + " " + family
      ),
      Experience: experience,
      "Production Area": getProductionArea(chiefComplaint),
    })
  );
}

const getProductionArea = (chiefComplaint) => {
  if(chiefComplaint && chiefComplaint.length) {
    return   chiefComplaint[chiefComplaint.length - 1].chiefComplaintId.productionArea[chiefComplaint[0].chiefComplaintId.productionArea.length - 1].productionAreaId.paName
  }
  return "";
}
