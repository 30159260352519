import React, { useEffect, useState, useReducer } from "react";
import { withStyles } from '@material-ui/core/styles';
import Header from "../../components/Header/Header";
import classNames from "classnames";
import { 
  InputLabel,
  FormHelperText,
  FormControl,
  Button, 
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment,
  MenuItem,
  TextField,
  Select,
  Checkbox,
  FormControlLabel,
  useMediaQuery,
} from "@material-ui/core";
import cookie from "react-cookies";

import axios from "axios";
import {
  audioURL,
  getLabServiceCategory,
  addLabRequestBulk,
  getSingleRRRSample,
  updateSingleRRRSample,

  getRadServiceCategory,
} from "../../public/endpoins";
import Back from '../../assets/img/Back_Arrow.png'

import useStylesForInput from "../../assets/jss/material-dashboard-react/inputStyle";
import { styles } from "./styles";
import Loader from "react-loader-spinner";
import Icon from '@material-ui/core/Icon';
import CloseIcon from '@material-ui/icons/Close';
import MicIcon from "@material-ui/icons/Mic";
import StopIcon from "@material-ui/icons/Stop";
import MicRecorder from "mic-recorder-to-mp3";
import AccountCircle from "@material-ui/icons/SearchOutlined";
import Notification from "../../components/Snackbar/Notification.js";

import LabIcon from "../../assets/img/Lab.png";
import { displayInHumanDifference, displayInHumanClassic, replacePatientIdIfNull } from "../../utils/commonFunc";
import PatientDetailsQuick from "../../components/PatientDetails/PatientDetailsQuick";
import PatientDetails from "../../components/PatientDetails/PatientDetails";
import PatientDetails2 from "../../components/PatientDetails/PatientDetailsDirect";

export default function RadReqReceiverDetailsIndividual(props) {
  
  const matches = useMediaQuery("(min-width:600px)");
  const classes = useStylesForInput();

  const mystyle = {
    color: "white",
    backgroundColor: "DodgerBlue",
    padding: "10px",
    fontFamily: "Arial"
  };



  const GreenCheckbox = withStyles({
    root: {
      color: '#AAAAAA',
      '&$checked': {
        color: '#AAAAAA',
      },
    },
    checked: {
      color: '#2C6DDD',
      '&$checked': {
        color: '#2C6DDD',
      },
    },
  })((props) => <Checkbox color="default" {...props} />);

  


  const initialState = {
    bulkTestSelected: false,
    seletedRadItems: [],
    resetedOn: "",
    defaultItems: {},
    seletedLabItems: [],
    labPriority: "",
    labTechnician: "",
    labComments: "",
    labTechniciansList: [],

    newSearchTerm: '',
  };
  function reducer(state, { field, value }) {
    return {
      ...state,
      [field]: value,
    };
  }
  const [state, dispatch] = useReducer(reducer, initialState);
  const {
    bulkTestSelected,
    seletedRadItems,
    resetedOn,
    defaultItems,
    seletedLabItems,
    labPriority,
    labTechnician,
    labComments,
    labTechniciansList,
    
    newSearchTerm
  } = state;

  const handleCheckupChange = (event) => {
    dispatch({field: 'bulkTestSelected', value: event.target.value});
  };

  const uniqueCodeField = (name) => {
    name = name.toLowerCase();
    name = name.replace(/[^a-z0-9]+/g, '-');
    name = name.replace(/^-+|-+$/g, '');
    return name;
  }


  const [currentUser] = useState(cookie.load("current_user"));
  const [timer, setTimer] = useState(null);
  const [selectedLab, setSelectedLab] = useState("");
  const [categoriesData, setCategoriesData] = useState([]);
  const [mItems, setMItems] = useState([]);
  const [myDefaultItems, setMyDefaultItems] = useState([]);

  const [patientDetails, setPatientDetails] = useState("");
  const [selectedRecord, setSelectedRecord] = useState({});

  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setsuccessMsg] = useState("");
  const [openNotification, setOpenNotification] = useState(false);
  const [viewSafetyDialog, setViewSafetyDialog] = useState(false);

  const [ episodeId, setEpisodeId ] = useState(props.match.params.id);
  const [ radRequestId, setRadRequestId ] = useState(props.match.params.subRequestId);
  const [ radServiceId, setRadServiceId ] = useState(props.match.params.serviceId);
  const [ radRequest, setRadRequest ] = useState({});
  const [ radServices, setRadServices ] = useState([]);
  const [ radService, setRadService ] = useState({});
  const [ status, setStatus ] = useState("");
  const [ mriChecklist, setMRIChecklist ] = useState({});
  const [ cServiceIdDBAssigned, setCServiceIdDBAssigned ] = useState(true);


  
  if (openNotification) {
    setTimeout(() => {
      setOpenNotification(false);
      setErrorMsg("");
      setsuccessMsg("");
    }, 4000);
  }

  useEffect(() => {
    axios
      .get(`${getSingleRRRSample}/${episodeId}/${radRequestId}`)
      .then((res) => {
        console.log('handleView::', res.data.data);
        if (res.data.success) {

          let mData = res.data.data[0];
          let remoteRecord = replacePatientIdIfNull(mData);

          const object = {
            '_id': remoteRecord._id,
            patientName: remoteRecord.patientId.name[0].given[0] + " " + remoteRecord.patientId.name[0].family,
            episodeNumber: remoteRecord.requestNo,//remoteRecord.patientId.identifier[0].value,
            mrn: remoteRecord.patientId.identifier[0].value,
            age: remoteRecord.patientId.age,
            gender: remoteRecord.patientId.gender,
            weight: remoteRecord.patientId.weight,
            department: remoteRecord.currentLocation,
            chiefComplaint: remoteRecord.chiefComplaint.length > 0 ? remoteRecord.chiefComplaint.pop().chiefComplaintId.short_desc : "N/A",
            chiefComplaintOthers: remoteRecord.chiefComplaintOthers,
          };
          setPatientDetails(object);

          let radServicesX = remoteRecord.radRequest;
          if (radServicesX){
            let labXN = radServicesX;
            labXN.requestedName = labXN.requestedBy.name[0].given[0] + " " + labXN.requestedBy.name[0].family;
            console.log('radServicesX', labXN.requestedBy.name);
            console.log('radServicesX', labXN);

            setMRIChecklist(labXN.checklist);

            setRadRequest(labXN)
            // setRadRequest(radServicesX[0])
            setRadServices(radServicesX.services);

            let radServiceX = labXN.services.filter(obj => obj.requestId === radServiceId);
            setRadService(radServiceX[0]);
            console.log('labXN::', radServiceX[0]);
            loadAsyncData( radServiceX[0].category, radServiceX[0] )
            setStatus(radServiceX[0].porterStatus);

            console.log('radServicesX::radServiceX', radServiceX[0].cServiceId);

          }

        }
      })
      .catch((e) => {
        console.log("error after searching patient request", e);
      });
    /*getLabTechniciansCall( currentUser._id, dispatch, setOpenNotification, setErrorMsg );*/
  }, []);

  const loadAsyncData = (category, radServiceDB) => {
    // labServiceCategoryCall(setCategoriesData);
    axios
      .get(`${getRadServiceCategory}?filterServices=${category}`)
      .then((res) => {
        if (res.data.success) {
          // console.log('labServiceCategoryCall', res.data.data);
          let remoteData = res.data.data;
          setCategoriesData(remoteData);

          let itemsInList = [];
          let defaultItemsInList = [];
          let newDefaultItemsInList = [];

          // Items Extractor
          remoteData.map((d, index) => {
            let slug = uniqueCodeField(d._id);
            let values = [];

            if (d.items.length > 0){
              d.items.map((nd, nindex) => {
                // values.push(nd);
                itemsInList.push(nd);

                if (nd.checked){
                  values.push(nd._id);
                }

              });
            }
            newDefaultItemsInList[ `id-${slug}` ] = {
              'id': slug,
              'name': d._id,
              'items': values,
            };
          });

          dispatch({ field: 'resetedOn', value: 'now' });

          setMyDefaultItems(newDefaultItemsInList);
          dispatch({
            field: 'defaultItems',
            value: newDefaultItemsInList
          });
          setMItems(itemsInList);
          // setCategoriesData([
          //   {_id: "Hello"}
          // ])



          console.log('radService::', radServiceDB);

          let alreadyAddedInDB = [];
          if ( radServiceDB.cServiceId.length >= 0 ){
            setCServiceIdDBAssigned(true);
            Object.entries(radServiceDB.cServiceId).map((obj, entry) => {
              console.log('radServicesX::radServiceX::cServiceId', obj[1]);
              alreadyAddedInDB.push({
                id: obj[1].mRef._id,
                priority: 'Normal',
                xUrgent : 'No',
                notes: {
                  text: '',
                  audio: null,
                }
              });
            })
          }

          if (alreadyAddedInDB.length === 0){
            setCServiceIdDBAssigned(false);
          }

          console.log('radServicesX::radServiceX::alreadyAddedInDB::', alreadyAddedInDB);
          dispatch({
            field: 'seletedRadItems',
            value: alreadyAddedInDB
          });
        }
      })
      .catch((e) => {
        console.log("error while searching req", e);
      });

  }

  const itemsExistInDefaultSelection = (slug, item) => {
    if ( `id-`+slug in defaultItems ){
      let defaultSelected = defaultItems[ `id-`+slug ].items;
      if ( defaultSelected.includes( item ) )
      { // Already Exists Item and Remove it
        return true;
      }
    }

    return false;
  };
  const associateSelectedItems = (slug) => {
    
    let defaultSelected = [];
    let currentIndex = -1;
    let sPriority = 'Normal';
    let sUrgent   = 'No';

    console.log( 'RadReq::addSelectedItems::', slug )

    if ( `id-${slug}` in defaultItems )
    {
      let d = defaultItems[ `id-${slug}` ];
      console.log( 'RadReq::addSelectedItems::', d );

      sPriority = d.priority;
      sUrgent = d.xUrgent;
      defaultSelected = d.items;
      d.menuOpened = false;

    }
 
    // Selected Items
    let readyToAdd = seletedRadItems;
    console.log( 'RadReq::ConsoleRadRequest::readyToAdd', readyToAdd );

    if ( defaultSelected.length > 0 ) {
      defaultSelected.map((dx, dindex) => {
        console.log( 'RadReq::ConsoleRadRequest::dx::dindex::', dx, dindex );

        let itemExists = readyToAdd.filter((element, index, array) => {
            // Return true or false
            return element.id === dx;
        });

        if ( itemExists.length<=0 ){
          readyToAdd.push({
            id: dx,
            priority: sPriority,
            xUrgent : sUrgent,
            notes: {
              text: '',
              audio: null,
            }
          });
        }
      });

      dispatch({
        field: 'seletedRadItems',
        value: readyToAdd
      });
    }else{
      setOpenNotification(true);
      setErrorMsg('Please select an item from the above list.');
    }

  };

  const handleView = (rec) => {
    let url = '/dashboard/home/rrr/details/'+episodeId+'/'+radRequestId+'/view/'+rec.requestId+'';
    props.history.push({
      pathname: url,
    });
  }

  const handleChange = (event) => {
    setStatus(event.target.value);
  };

  const handleSave = () => {
    if (seletedRadItems.length > 0){
      axios
        .put(updateSingleRRRSample, {
          edrId: episodeId,
          requestId: radRequestId,
          serviceId: radServiceId,
          requestStatus: status,
          completeType: 'individual',
          seletedRadItems: seletedRadItems,
          staffId: currentUser._id,
        })
        .then((res) => {
          if (res.data.success) {
            setOpenNotification(true);
            setsuccessMsg(`The Rediology Request has been Submitted!`);
            window.location.reload();
          }
        })
        .catch((e) => {
          setOpenNotification(true);
          setErrorMsg("Unable to Update the Record");
        });
    }else{
      setOpenNotification(true);
      setErrorMsg("Please select Associated Procedures first.");
    }
  }

  const handleAssociateChange = (event) => {

    let nSlug = event.target.name;
    console.log( 'RadReq::handleChange::', event.target.checked, event.target.name, event.target.value, `id-`+nSlug, defaultItems );
    if ( `id-`+nSlug in defaultItems ){
      let defaultSelected = defaultItems[ `id-`+nSlug ].items;

      console.log( 'RadReq::handleChange::', defaultSelected );

      if (defaultSelected.includes(event.target.value)){ // Already Exists Item and Remove it
        let indexOfItem = defaultSelected.indexOf( event.target.value );
        defaultSelected.splice( indexOfItem, 1 );
      }else{ // Adding Item
        defaultSelected.push( event.target.value );
      }

      let nItems = defaultItems;
      nItems[ `id-`+nSlug ].items = defaultSelected;
      console.log('labXN::nItems', nItems);
      dispatch({
        field: 'defaultItems',
        value: nItems
      });
    }
  }

  // Associated
  const toggleDropdownMenu = (slug) => {
    let newSlug = uniqueCodeField(slug);

    // Reset all opened
    dispatch({field: 'newSearchTerm', value: ''});

    Object.entries(defaultItems).map(([x, i]) => {
      console.log('RadReq::toggleDropdownMenu::x,i::', x, i );
      if ( `id-${newSlug}` ===  x){

      }else{
        i.menuOpened = false;
        i.items = [];
      }
    })

    if ( `id-${newSlug}` in defaultItems){
      let obj = defaultItems[ `id-${newSlug}` ];
      if (obj.menuOpened === true){
        obj.menuOpened = false
        obj.items = [];
      }else{
        obj.menuOpened = true;
      }
    }
    dispatch({
      field: 'defaultItems',
      value: defaultItems
    });
  }

  const dropdownOpened = (slug) => {
    let menuOpened = "";
    let currentIndex = -1;

    let nSlug = uniqueCodeField(slug);
    if (`id-`+nSlug in defaultItems){
      if ( defaultItems[ `id-`+nSlug ].menuOpened === true ){
        menuOpened = "show";
      }
    }

    return menuOpened;
  };

  const isDropdownOpened = (slug) => {
    let menuOpened = false;
    let currentIndex = -1;

    let nSlug = uniqueCodeField(slug);
    if (`id-`+nSlug in defaultItems){
      if ( defaultItems[ `id-`+nSlug ].menuOpened === true ){
        menuOpened = true;
      }
    }

    return menuOpened;
  };

  const removeSelectedRadItem = (index, id) => {
    if (window.confirm('Are you sure to remove?')){

      seletedRadItems.map((d, index) => {
        if (d.id === id){
          console.log('RadReuqest::remove::', id, 'included');
          let indexOfItem = seletedRadItems.indexOf( d );
          console.log('RadReuqest::remove::', 'indexOfItem', indexOfItem);
          seletedRadItems.splice( indexOfItem, 1 );
          dispatch({
            field: 'seletedRadItems',
            value: seletedRadItems
          });
        }
      });


      console.log('RadReuqest::remove::', index, id);
      if (seletedRadItems.includes(id)){ // Already Exists Item

        
      }
    }
  };

  return (
    
    <div
      style={{
        backgroundColor: "rgb(19 213 159)",
        position: "fixed",
        display: "flex",
        width: "100%",
        height: "100%",
        flexDirection: "column",
        flex: 1,
        overflowY: "scroll",
      }}
    >
      <Header history={props.history} />

      <PatientDetailsQuick
        edrId={episodeId} />

      <div className="cPadding">

        {patientDetails && radRequest ? (
          <div className="mb-3">
            <div className="card pt-3 pl-3 pr-3">
              <div className="row row-5">
                <div className="col-6 col-lg-3 card-detail-block block-xdiv mb-3"><strong className="lblX">Episode #</strong><span className="valX">{patientDetails.episodeNumber}</span></div>
                <div className="col-6 col-lg-3 card-detail-block block-xdiv mb-3"><strong className="lblX">MRN #</strong><span className="valX">{patientDetails.mrn}</span></div>
                <div className="col-6 col-lg-3 card-detail-block block-xdiv mb-3"><strong className="lblX">Requester</strong><span className="valX">{radRequest.requestedName}</span></div>
                <div className="col-6 col-lg-3 card-detail-block block-xdiv mb-3"><strong className="lblX">Date and Time</strong><span className="valX">{displayInHumanClassic(radRequest.requestedAt)}</span></div>
              </div>
            </div>
          </div>
        ) : (
          undefined
        )}

        <h4 className="h6 text-white mb-3"
          style={{
            fontWeight: '700'
          }}>{ radService.requestId } - Profiles/Radiology Requests</h4>
        <div className="row row-5">
          {
            radService ? 
              <div className="col-12">
                <div className="card card-detail-info mb-2">
                  <div className="row row-5">
                    <div className="col-sm-6 card-detail-block block-xdiv mb-2"><strong className="lblX">Request ID</strong><span className="valX">{radService.requestId}</span></div>
                    <div className="col-sm-6">&nbsp;</div>
                    <div className="col-sm-12 card-detail-block block-xdiv mb-2"><strong className="lblX">Profile/Test</strong><span className="valX">{radService.category}: <span>{radService.name}</span></span></div>
                    <div className="col-sm-12 card-detail-block block-xdiv">
                      <strong className="lblX">Notes</strong><span className="valX"><span>{ radService.notes }</span></span>
                      <audio
                        style={{
                          marginRight: 10,
                          width: matches ? 450 : 260,
                          marginTop: 6,
                        }}
                        src={ `${audioURL}/${radService.audio}` }
                        controlsList="nodownload"
                        controls="controls"
                        />
                    </div>
                    <div className="col-12 text-right">
                      <span onClick={() => {
                        setViewSafetyDialog(true);
                      }}><i className="fa fa-angle-right"></i> View Safety / MRI Con</span>
                    </div>
                    <div className="col-12">
                      <hr />
                      <strong>Associated Procedure(s)</strong>
                      {seletedRadItems.length > 0 ? (
                        
                        seletedRadItems.map((i, itemIx) => {
                          let item = mItems.filter(function callback(element, index, array) {
                              // Return true or false
                              return element._id === i.id;
                          });
                          return (
                            <div className="serviceOffers pb-0">
                              <div className="serviceHeader">
                                <div className="serviceDesc-Name">{i.xUrgent==='Yes'?<i className="fa fa-info-circle"></i>:<i className="fa fa-caret-right"></i>}<span className="ml-2"><strong>{item[0].category}</strong>: {item[0].name}</span></div>
                                <div className="serviceOption text-right" style={{
                                  justifyContent: 'end'
                                }}>
                                  <Icon className="fa fa-trash" color="secondary" onClick={() => removeSelectedRadItem(i, item[0]._id)} />
                                </div>
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <p>No Procedures Associated at the moment.</p>
                      )} 
                    </div>
                  </div>
                  <div className="card-status-action">
                    <span className={classNames(`badge`, (radService.porterStatus=="Collected"?'badge-success':'status-pending'))}>{radService.porterStatus}</span>
                    {
                      radService.priority === "High"?
                      <span className='badge badge-danger'>High</span>:undefined
                    }
                    { 
                      radService.urgent==="Yes"?<span className="ml-info">!</span>:undefined
                    }
                  </div>
                </div>
              </div>
              :undefined
          }
          {
            radServices ? 
              <>
              {cServiceIdDBAssigned===false?
              <>
                <div className="col-12">
                  <hr />
                  <h4 className="h6 text-white mb-2" style={{ fontWeight: '700' }}>Associate Procedure</h4>
                  <div className="mt-2">
                    {
                      categoriesData ? 
                      categoriesData.map((d, index) => {
                        return (
                          <div className="mt-2">
                            <button className="pl-2 pr-2 btn btn-block bg-white dropdown-toggle" 
                              type="button" id={ "dropdownMenuButton-" + (index + 1) } 
                              onClick={() => toggleDropdownMenu(d._id)}
                              style={{ height: 65 }}
                              aria-haspopup="true" aria-expanded="false">
                              <span style={{ ...styles.textStyles, "white-space": "initial" }}>{ d._id }</span>
                            </button>
                            <div className={classNames(`dropdown-menu w-100`, dropdownOpened(d._id))} aria-labelledby={ "dropdownMenuButton-" + (index + 1) }>
                              <div className="searchField bg-light">
                                <input type="text" placeholder="Enter keyword to search..."
                                value={newSearchTerm}
                                onChange={(event) => {
                                  dispatch({field: 'newSearchTerm', value: event.target.value})
                                }} />
                              </div>
                              <div style={{ height: 250, overflowY: "scroll"}}>
                              {
                               d.items.length > 0 ? 
                                  d.items.filter((val) => {
                                    if (newSearchTerm && isDropdownOpened(d._id)){
                                      return val.name.toLowerCase().includes(newSearchTerm.toLowerCase())
                                    }
                                    return val;
                                  }).map((nd, nindex) => {
                                    return (
                                      <div className="dropdown-items pl-1 pr-3 d-flex align-items-center justify-content-between">
                                        <FormControlLabel
                                          control={<GreenCheckbox checked={ itemsExistInDefaultSelection( uniqueCodeField(d._id), nd._id )  } value={ nd._id } onChange={handleAssociateChange} name={ uniqueCodeField(d._id) } />}
                                          label={nd.name}
                                          style={{
                                            margin: 0,
                                          }}
                                        />
                                        
                                      </div>
                                    )
                                  }) : ''
                              }
                              </div>
                              <div className="p-2 bg-light">
                                <Button
                                  color="primary"
                                  size="small"
                                  onClick={() => associateSelectedItems(uniqueCodeField(d._id))}
                                  variant="contained"
                                  fullWidth="true"
                                >Associate Selected</Button>
                              </div>
                            </div>
                          </div>
                        )
                      })
                      : ''
                    }
                  </div>
                </div>
              
                <div className="col-12 col-md-8 offset-md-2 mt-3 mt-lg-3 text-center">
                  <Button
                    style={{
                      backgroundColor: "#AD6BBF",
                      color: "white",
                      cursor: "pointer",
                      borderRadius: 5,
                      backgroundColor: "rgb(173, 107, 191)",
                      height: matches ? 56 : 45,
                      outline: "none",
                      width: "100%",
                    }}
                    onClick={handleSave}
                    variant="contained"
                    color="primary"
                    fullWidth>Submit</Button>
                </div>
              </>
              :undefined}
              </>
            : undefined
          }
        </div>
        <div className='row mt-4 mb-4'>
          <div className='col-md-12 col-sm-12 col-12'>
            <img
              onClick={() => {
                let url = '/dashboard/home/rrr/details/'+episodeId+'/'+radRequestId;
                props.history.push({
                  pathname: url,
                });
              }}
              src={Back}
              style={{
                width: 45,
                height: 35,
                cursor: 'pointer',
              }}
            />
          </div>
        </div>
      </div>



        <Dialog
            aria-labelledby="form-dialog-title"
            open={viewSafetyDialog}
            onClose={() => {
              setViewSafetyDialog(false)
            }}
            maxWidth="md"
          >
          <DialogTitle
              id="simple-dialog-title"
              className=""
            ><strong>Safety</strong> / MRI Con</DialogTitle>
          <DialogContent>

            
            <div className="row mb-2">
              <div className="col-10">Assistant Required?</div>
              <div className="col-2 text-right">{mriChecklist.assistantRequired=="yes"?<i className="fa fa-check text-success"></i>:<i className="fa fa-close text-danger"></i>}</div>
            </div>

            <h4 className="h6 mt-4 mb-3"><strong>Safety Check</strong></h4>
            <div className="row mb-2">
              <div className="col-10">Could the patient be pregnant?</div>
              <div className="col-2 text-right">{mriChecklist.pregnantCheck=="yes"?<i className="fa fa-check text-success"></i>:<i className="fa fa-close text-danger"></i>}</div>
            </div>
            <div className="row mb-2">
              <div className="col-10">Is the patient breast feeding?</div>
              <div className="col-2 text-right">{mriChecklist.breastFeeding=="yes"?<i className="fa fa-check text-success"></i>:<i className="fa fa-close text-danger"></i>}</div>
            </div>
            <div className="row mb-2">
              <div className="col-10">Is the patient a high infection risk?</div>
              <div className="col-2 text-right">{mriChecklist.highInfectionRisk=="yes"?<i className="fa fa-check text-success"></i>:<i className="fa fa-close text-danger"></i>}</div>
            </div>
            <div className="row mb-2">
              <div className="col-10">Is the patient diabetic?</div>
              <div className="col-2 text-right">{mriChecklist.patientDiabetic=="yes"?<i className="fa fa-check text-success"></i>:<i className="fa fa-close text-danger"></i>}</div>
            </div>
            <div className="row mb-2">
              <div className="col-9">Is the diabetes controlled:</div>
              <div className="col-3 text-right">{mriChecklist.diabetesControlled}</div>
            </div>
            <div className="row mb-2">
              <div className="col-10">Does the patient have any allergies?{mriChecklist.patientAllergies=="yes"?<><br /><small>{mriChecklist.patientAllergiesDesc}</small></>:undefined}</div>
              <div className="col-2 text-right">{mriChecklist.patientAllergies=="yes"?<i className="fa fa-check text-success"></i>:<i className="fa fa-close text-danger"></i>}</div>
            </div>
            
            <h4 className="h6 mb-2 mt-4"><strong>MRI Contraindications</strong></h4>
            <div className="row mb-2">
              <div className="col-10">A pacemaker?</div>
              <div className="col-2 text-right">{mriChecklist.pacemaker=="yes"?<i className="fa fa-check text-success"></i>:<i className="fa fa-close text-danger"></i>}</div>
            </div>
            <div className="row mb-2">
              <div className="col-10">A cerebral aneurysm clip?</div>
              <div className="col-2 text-right">{mriChecklist.cerebralAneurysmClip=="yes"?<i className="fa fa-check text-success"></i>:<i className="fa fa-close text-danger"></i>}</div>
            </div>
            <div className="row mb-2">
              <div className="col-10">Cochlear implants?</div>
              <div className="col-2 text-right">{mriChecklist.cochlearImplants=="yes"?<i className="fa fa-check text-success"></i>:<i className="fa fa-close text-danger"></i>}</div>
            </div>
            <div className="row mb-2">
              <div className="col-10">Neuro stimulators?</div>
              <div className="col-2 text-right">{mriChecklist.neuroStimulators=="yes"?<i className="fa fa-check text-success"></i>:<i className="fa fa-close text-danger"></i>}</div>
            </div>
            <div className="row mb-2">
              <div className="col-10">Programmable hydrocephalus shunt?</div>
              <div className="col-2 text-right">{mriChecklist.programmableHydrocephalusShunt=="yes"?<i className="fa fa-check text-success"></i>:<i className="fa fa-close text-danger"></i>}</div>
            </div>
            <div className="row mb-2">
              <div className="col-10">Metallic foreign body in eye?</div>
              <div className="col-2 text-right">{mriChecklist.metallicForeignBodyInEye=="yes"?<i className="fa fa-check text-success"></i>:<i className="fa fa-close text-danger"></i>}</div>
            </div>
            <div className="row mb-2">
              <div className="col-10">Other metallic implants?</div>
              <div className="col-2 text-right">{mriChecklist.otherMetallicImplants=="yes"?<i className="fa fa-check text-success"></i>:<i className="fa fa-close text-danger"></i>}</div>
              <div className="col-12">{mriChecklist.otherMetallicImplantsDesc}</div>
            </div>
          </DialogContent>
        </Dialog>

      <Notification
        msg={errorMsg}
        open={openNotification}
        success={successMsg}
      />
    </div>
  );
}