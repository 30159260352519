import React, { useEffect, useState, useReducer } from "react";
import { withStyles } from '@material-ui/core/styles';
import Header from "../../components/Header/Header";
import classNames from "classnames";
import ErrorMessage from "../../components/ErrorMessage/errorMessage";
import Back from "../../assets/img/Back_Arrow.png";
import { 
  Button, 
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment,
  MenuItem,
  TextField,
  Select,
  useMediaQuery,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
} from "@material-ui/core";
import cookie from "react-cookies";
import { displayInHumanDifference, replacePatientIdIfNull } from "../../utils/commonFunc";

import axios from "axios";
import {
  getSingleEdr,
  getSearchedPharmaceuticalItemsUrl,
  getSearchedItemsNonPharmaceuticalUrl,
  addLabRequestBulk,
  addPharmacyRequest,
  getStaffByType,
} from "../../public/endpoins";

import useStylesForInput from "../../assets/jss/material-dashboard-react/inputStyle";
import styles from "./styles";
import Loader from "react-loader-spinner";
import {
  Checkbox, 
  FormLabel, 
  FormControl, 
  FormGroup, 
  FormControlLabel,
  RadioGroup, 
  Radio
} from '@material-ui/core';
import {
  statusArray,
  routeArray,
} from "./configForNotesScreen";
import Icon from '@material-ui/core/Icon';
import CloseIcon from '@material-ui/icons/Close';
import MicIcon from "@material-ui/icons/Mic";
import StopIcon from "@material-ui/icons/Stop";
import MicRecorder from "mic-recorder-to-mp3";
import AccountCircle from "@material-ui/icons/SearchOutlined";
import Notification from "../../components/Snackbar/Notification.js";
import PatientClinicalInformation from "../../components/PatientDetails/PatientClinicalInformation";
import PatientDetailsQuick from "../../components/PatientDetails/PatientDetailsQuick";

const Mp3Recorder = new MicRecorder({ bitRate: 128 });

const scheduleArray = [
  { key: "Now", value: "Now" },
  // { key: "Immediate", value: "Immediate" },
];
const priorityArray = [
  { key: "High", value: "High" },
  { key: "Low", value: "Low" },
  // { key: "PRN", value: "PRN" },
];

const frequencyArray = [
  // { key: 1, value: "QD" },
  // { key: 2, value: "BiD" },
  // { key: 3, value: "TiD" },
  // { key: 4, value: "QiD" },
  // { key: 0, value: "PRN" },

  { key: 1,  value: "Q.H - Each Hour" },
  { key: 11, value: "Q.D - Every day" },
  { key: 2,  value: "B.I.D - Two times each day" },
  { key: 3,  value: "T.I.D - Three times each day" },
  { key: 4,  value: "Q.I.D - Four times each day" },

  { key: 20,  value: "Q.S - As much as su" },
  { key: 21,  value: "Q.A.C - Before every meal" },
  { key: 22,  value: "Q.O.D - Every other day" },
  { key: 23,  value: "Q.I - As much as you like" },
  { key: 24,  value: "Q.H.S - Every bedtime" },
  { key: 0,  value: "PRN When needed" },
  { key: 25,  value: "Other" },
];

const sizeArray = [
  { key: "Small", value: "Small" },
  { key: "Medium", value: "Medium" },
  { key: "Large", value: "Large" },
  { key: "Extra Large", value: "Extra Large" },
];

const modalArray = [
  { key: "Old", value: "Old" },
  { key: "New", value: "New" },
];

const dosageArray = [
  { key: "tablet", value: "tablet" },
  { key: "capsule", value: "capsule" },
  { key: "drop", value: "drop" },
  { key: "ml", value: "ml" },
  { key: "supp", value: "supp" },
  { key: "unit dose vial", value: "unit dose vial" },
  { key: "stroke", value: "stroke" },
  { key: "amp", value: "amp" },
  { key: "patch", value: "patch" },
  { key: "spray", value: "spray" },
  { key: "inhalation", value: "inhalation" },
  { key: "actuation", value: "actuation" },
  { key: "ovule", value: "ovule" },
  { key: "pump", value: "pump" },
  { key: "caplet", value: "caplet" },
  { key: "gm", value: "gm" },
  { key: "vial", value: "vial" },
  { key: "dose", value: "dose" },
  { key: "puff", value: "puff" },
  { key: "application", value: "application" },
  { key: "sachet", value: "sachet" },
  { key: "film", value: "film" },
  { key: "ring", value: "ring" },
  { key: "pre-filled syring", value: "pre-filled syring" },
  { key: "insuline", value: "insuline" },
]

export default function PharmacyRequestsPage(props) {
  
  const matches = useMediaQuery("(min-width:600px)");
  const classes = useStylesForInput();

  const mystyle = {
    color: "white",
    backgroundColor: "DodgerBlue",
    padding: "10px",
    fontFamily: "Arial"
  };

  const GreenCheckbox = withStyles({
    root: {
      color: '#AAAAAA',
      '&$checked': {
        color: '#AAAAAA',
      },
    },
    checked: {
      color: '#2C6DDD',
      '&$checked': {
        color: '#2C6DDD',
      },
    },
  })((props) => <Checkbox color="default" {...props} />);

  const GreenRadio = withStyles({
    root: {
      color: '#AAAAAA',
      '&$checked': {
        color: '#AAAAAA',
      },
    },
    checked: {
      color: '#2C6DDD',
      '&$checked': {
        color: '#2C6DDD',
      },
    },
  })((props) => <Radio color="default" {...props} />);

  


  const initialState = {
    isEDNurse: false,
    currentRequester: null,
    isFormSubmitted: false,
    bulkTestSelected: false,
    resetedOn: "",
    defaultItems: {},
    seletedMedItems: [],
    labPriority: "",
    labTechnician: "",
    labComments: "",
    labTechniciansList: [],

    isBlocked: false,
    isRecording: false,
    recordingField: '',
    blobURL: [],

    selectedItemToSearch: "pharmaceutical",
    displayItemToSearch: "Pharma",


  };
  function reducer(state, { field, value }) {
    return {
      ...state,
      [field]: value,
    };
  }
  const [state, dispatch] = useReducer(reducer, initialState);
  const {
    isEDNurse,
    currentRequester,
    isFormSubmitted,
    bulkTestSelected,
    resetedOn,
    defaultItems,
    seletedMedItems,
    labPriority,
    labTechnician,
    labComments,
    labTechniciansList,
    isBlocked,
    isRecording,
    recordingField,
    blobURL,
    selectedItemToSearch,
    displayItemToSearch
  } = state;

  const handleCheckupChange = (event) => {
    dispatch({field: 'bulkTestSelected', value: event.target.value});
  };

  const uniqueCodeField = (name) => {
    name = name.toLowerCase();
    name = name.replace(/[^a-z0-9]+/g, '-');
    name = name.replace(/^-+|-+$/g, '');
    return name;
  }

  const itemsExistInDefaultSelection = (slug, item) => {

    if ( `id-`+slug in defaultItems ){
      let defaultSelected = defaultItems[ `id-`+slug ].items;
      if ( defaultSelected.includes( item ) )
      { // Already Exists Item and Remove it
        return true;
      }
    }

    return false;
  };

  const onChangeValue = (e, xIndex, item) => {
    console.log("PharmaReq :: onChangePriorityValue", e, xIndex, item);

    let newSelectedLabItems = seletedMedItems;
    newSelectedLabItems.map((d, index) => {
      if (d.itemId === item){
        console.log("PharmaReq :: onChangePriorityValue :: d", d);
        let xPriority     = d['priority'];
        let xDosage       = d['dosage'];
        let xDuration     = d['duration'];
        let xFrequency    = d['frequency'];
        let xReqQty       = d['requestedQty'];

        if (xIndex === "dosage"){
          xDosage = e;
        }
        if (xIndex === "duration"){
          xDuration = e;
        }
        if (xIndex === "frequency"){
          xFrequency = e;
        }
        if (xIndex === "requestedQty"){
          xReqQty = e;
        }

        // requestedQty: 1,


        d[`${xIndex}`] = e;
        // d['requestedQty'] = finalReqQty;
        d['totalPrice'] = d['unitPrice']*d['requestedQty'];
      }
    });
    console.log("PharmaReq :: onChangePriorityValue :: nv", newSelectedLabItems);
    dispatch({
      field: 'seletedMedItems',
      value: newSelectedLabItems
    });
  };

  const onChangePriorityValue = (e, index, item) => {
    console.log("PharmaReq :: onChangePriorityValue", e, index, item);

    let newSelectedLabItems = seletedMedItems;
    newSelectedLabItems.map((d, index) => {
      if (d.itemId === item){
        d.priority = e;
      }
    });
    console.log("PharmaReq :: onChangePriorityValue :: nv", newSelectedLabItems);
    dispatch({
      field: 'seletedMedItems',
      value: newSelectedLabItems
    });
  };

  const onChangeNotesValue = (e, index, item) => {
    let newSelectedLabItems = seletedMedItems;
    newSelectedLabItems.map((d, index) => {
      if (d.itemId === item){
        d.notes.text = e;
      }
    });
    dispatch({
      field: 'seletedMedItems',
      value: newSelectedLabItems
    });
  };

  const removeSelectedLabItem = (index, id) => {
    if (window.confirm('Confirm Removal?')){

      seletedMedItems.map((d, index) => {
        if (d.itemId === id){
          console.log('LabReuqest::remove::', id, 'included');
          let indexOfItem = seletedMedItems.indexOf( d );
          console.log('LabReuqest::remove::', 'indexOfItem', indexOfItem);
          seletedMedItems.splice( indexOfItem, 1 );
          dispatch({
            field: 'seletedMedItems',
            value: seletedMedItems
          });
        }
      });


      console.log('LabReuqest::remove::', index, id);
      if (seletedMedItems.includes(id)){ // Already Exists Item

        
      }
    }
  };

  const validateFields = () => {

    let panadTS = true;

    if (seletedMedItems.length > 0){

      seletedMedItems.map((d, index) => {
        if (selectedItemToSearch==='pharmaceutical'){
          if ( d.schedule  === "" ){
            panadTS = false;
          }
          if ( d.dosage  <= 0 ){
            panadTS = false;
          }
          if ( d.duration  <= 0 ){
            panadTS = false;
          }
          if ( d.frequency === "" ){
            panadTS = false;
          }
          if ( d.priority  === "" ){
            panadTS = false;
          }
          if ( d.requestedQty  <= 0 ){
            panadTS = false;
          }
          if ( d.dosageType  === "" ){
            panadTS = false;
          }
          if ( d.route === "" ){
            panadTS = false;
          }
        }else{
          if ( d.requestedQty  <= 0 ){
            panadTS = false;
          }
          // if ( d.notes.text === '' && d.notes.audio === null ){
          //   panadTS = false;
          // }
        }
      });

      if (panadTS === false){
        setOpenNotification(true);
        setErrorMsg("There are some missing data for the medicines.");
      }else{
        console.log('validatedRequestNew', isEDNurse, currentRequester);
        if (isEDNurse && (currentRequester === null||currentRequester === '')){
          panadTS = false;
          setOpenNotification(true);
          setErrorMsg("Please select a ED Doctor from the list.");
        }
      }

    }else{
      setOpenNotification(true);
      setErrorMsg("You must have to add some medicines.");
      panadTS = false;
    }

    return panadTS;
  }

  const addSelectedLabItem = () => {
    // PharmaReq 
    dispatch({
      field: 'isFormSubmitted',
      value: true
    });

    if (
      // labComments &&
      // labComments.length > 0 &&
      // labPriority &&
      // labPriority.length > 0 &&
      // seletedMedItems.length > 0
      validateFields()
    ){
      
      setOpenNotification(true);
      setsuccessMsg(`${displayItemToSearch} Request Submitted`);
      const params = {
        requestedBy: currentUser._id,
        reconciliationNotes: [],
        item: [
          ...seletedMedItems
        ],
        status: 'pending',
        secondStatus: 'pending',
        edrId: episodeId,
      };
      console.log( 'PharmaReqs', params );
      // props.hideDialog(false);
      
      //SS
      let formData = new FormData();

      console.log( 'PharmaReq::seletedMedItems', seletedMedItems );
      formData.append("requestType", selectedItemToSearch);
      formData.append("requestedBy", currentUser._id);
      formData.append("currentRequester", currentRequester);
      formData.append("reconciliationNotes", JSON.stringify([]));
      formData.append("item", JSON.stringify(seletedMedItems));
      formData.append("status", 'pending');
      formData.append("secondStatus", 'pending');
      // formData.append("urgent", (props.history.location.state.lifelimbStatus?'Yes':'No'));
      formData.append("edrId", episodeId);
      formData.append("isEDNurse", (isEDNurse?'yes':'no'));

      for (let ixp = 0; ixp < seletedMedItems.length; ixp++) {
        if (seletedMedItems[ixp].notes){

          if ( seletedMedItems[ixp].notes.audio!=="" && seletedMedItems[ixp].notes.audio instanceof Blob )
          {
            formData.append("AudioFile", seletedMedItems[ixp].notes.audio, seletedMedItems[ixp].itemId);
          }
        }
      }
      console.log("PharmaReq :: onChangePriorityValue", seletedMedItems)
      axios
        .put(addPharmacyRequest, formData)//addPharmacyRequest
        .then((res) => {
          console.log( 'PharmaReq', res.data );
          if (res.data.success) {
            props.history.push({
              pathname: "/dashboard/home/"+episodeId+"/"+serviceType+"",
            });
          } else if (!res.data.success) {
            setOpenNotification(true);
            setErrorMsg("Error while adding the Parma Request");
          }
        })
        .catch((e) => {
          console.log('PharmaReq', "error after adding Parma Request", e);
          setOpenNotification(true);
          setErrorMsg("An error occurred while adding the Parma Request.");
        });






        /*
        if (validateFields()) {
          const params = {
            serviceId: selectedLab._id,
            name: selectedLab.name,
            type: selectedLab.type,
            price: selectedLab.price,
            priority: labPriority,
            staffId: currentUser._id,
            edrId:
              props.history.location.state.comingFrom === "notification"
                ? reponseThroughNotification._id
                : selectedRecord._id,
            notes: labComments,
            labTechnicianId: labTechnician,
          };
          console.log("Lab params", params);
          saveLabRequestCall(
            params,
            setOpenNotification,
            setErrorMsg,
            props,
            dispatch
          );
        } else {
          setOpenNotification(true);
          setErrorMsg("Error while adding the Lab Request");
        }
        setLabForm(true);
        */
        
    }
    // else{
    //   setOpenNotification(true);
    //   setErrorMsg("Error while adding the Parma Request");
    // }
  };

  const [currentUser] = useState(cookie.load("current_user"));
  const [timer, setTimer] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [itemFound, setItemFound] = useState("");
  const [itemFoundSuccessfull, setItemFoundSuccessfully] = useState(false);
  const [loadSearchedData, setLoadSearchedData] = useState(false);
  const [selectedLab, setSelectedLab] = useState("");

  const [mItems, setMItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [myDefaultItems, setMyDefaultItems] = useState([]);


  const [edDoctorStaff, setEdDoctorStaff] = useState([]);

  const [patientInsured, setPatientInsured] = useState(false);

  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setsuccessMsg] = useState("");
  const [openNotification, setOpenNotification] = useState(false);


  const [patientDetails, setPatientDetails] = useState({});
  const [selectedRecord, setSelectedRecord] = useState({});

  const [ episodeId, setEpisodeId ] = useState(props.match.params.id);
  const [ pageId, setPageId ] = useState(props.match.params.pagename);
  const [ serviceType, setServiceType ] = useState(props.match.params.serviceType?props.match.params.serviceType:'pharmacy');
  
  if (openNotification) {
    setTimeout(() => {
      setOpenNotification(false);
      setErrorMsg("");
      setsuccessMsg("");
    }, 4000);
  }

  useEffect(() => {

    if (serviceType === "non-pharmacy"){
      // console.log(`${serviceType} - Type`);
      dispatch({ field: "selectedItemToSearch", value: 'non_pharmaceutical' });
      dispatch({ field: "displayItemToSearch", value: 'Non - Pharma' });
    }

    if ( currentUser.staffType === "Nurses" && currentUser.subType.includes("ED Nurse") )
    {
      dispatch({ field: "isEDNurse", value: true });
    }else{
      dispatch({ field: "currentRequester", value: currentUser._id });
    }
    console.log('currentRequester', currentRequester);

    axios
      .get(getSingleEdr + "/" + episodeId)
      .then((res) => {
        console.log('handleView::', res.data.data);
        if (res.data.success) {

          let remoteRecord = replacePatientIdIfNull(res.data.data);
          setSelectedRecord(remoteRecord);

          if (remoteRecord.patientId.paymentMode === "Insured"){
            setPatientInsured(true);
          }

          const object = {
            '_id': remoteRecord._id,
            patientName: remoteRecord.patientId.name[0].given[0] + " " + remoteRecord.patientId.name[0].family,
            mrn: remoteRecord.requestNo,//remoteRecord.patientId.identifier[0].value,
            age: remoteRecord.patientId.age,
            gender: remoteRecord.patientId.gender,
            weight: remoteRecord.patientId.weight,
            department: remoteRecord.currentLocation,
            chiefComplaint: remoteRecord.chiefComplaint.length > 0 ? remoteRecord.chiefComplaint.pop().chiefComplaintId.short_desc : "N/A",
            chiefComplaintOthers: remoteRecord.chiefComplaintOthers,
          };
          setPatientDetails(object);
        }
      })
      .catch((e) => {
        console.log("error after searching patient request", e);
      });

      console.log('handleView::rb::', isRecording, isBlocked);
    navigator.mediaDevices &&
      navigator.mediaDevices.getUserMedia(
        { audio: true },
        () => {
          dispatch({ field: "isBlocked", value: false });
        },
        () => {
          dispatch({ field: "isBlocked", value: true });
        }
      );
  }, []);

  useEffect(() => {

    axios
      .get(`${getStaffByType}?role=Doctor&sub_role=ED+Doctor`)
      .then((res) => {
        if (res.data.success) {

          let staffEntries = [];
          Object.entries( res.data.data ).map(([key, val]) => {
            staffEntries.push({
              id: val._id,
              name: `Dr. ${val.name[0].given[0]} ${val.name[0].family}`
            });
            
          });
          setEdDoctorStaff(staffEntries);
          console.log( staffEntries );
        }
      })
      .catch((e) => {
        console.log("error after searching patient request", e);
      });
  }, [patientDetails]);

  const startRecording = (index, item) => {
    console.log('handleView::ii::', index, item)
    if (isBlocked) {
      setOpenNotification(true);
      setErrorMsg("The Record is Blocked by the Browser!");
    } else {

      setOpenNotification(true);
      setsuccessMsg("The Record has been started!");
      Mp3Recorder.start()
        .then(() => {
          dispatch({ field: "isRecording", value: true });
          dispatch({ field: "recordingField", value: item });
          console.log('handleView::rec::',true)
        })
        .catch((e) => console.error(e));
    }
  };

  const stopRecording = () => {
    Mp3Recorder.stop()
      .getMp3()
      .then(([buffer, blob]) => {

        const blobURLFinal = URL.createObjectURL(blob);
        let blobURLx = blobURL;
        blobURLx[ recordingField ] = blobURLFinal;
        dispatch({ field: "blobURL", value: blobURLx });

        dispatch({ field: "isRecording", value: false });
        var file = new File([blob], "first", { type: "audio/mp3" });


        // Setting Up Audio File
        if (seletedMedItems.length > 0){
          seletedMedItems.map((i, itemIx) => {
            // let item = mItems.filter((element, index, array) => element._id === recordingField);
            console.log('handleView::rec::stop::', i, itemIx, recordingField)
            if (i.itemId == recordingField){
              i.notes.audio = file;
            }
          });
        }


        dispatch({ field: "fileAudio", value: file });
        dispatch({ field: "recordingField", value: '' });
        console.log('handleView::rec::',false)
      })
      .catch((e) => console.log(e));
  };

  const handlePauseMedSearch = (e) => {
    // setLoadSearchedData(true);
    // clearTimeout(timer);

    const a = e.target.value.replace(/[^\w\s]/gi, "");
    setSearchQuery(a);

    setTimer(
      setTimeout(() => {
        handleSearch(a);
        // labServiceSearchCall(
        //   a,
        //   setItemFoundSuccessfully,
        //   setItemFound,
        //   setLoadSearchedData
        // );
      }, 600)
    );
  };

  const handleSearch = (e) => {
    if (e.length >= 1) {
      let url = "";
      if (selectedItemToSearch === "pharmaceutical") {
        url = getSearchedPharmaceuticalItemsUrl;
      } else {
        url = getSearchedItemsNonPharmaceuticalUrl;
      }
      console.log("price data", url);
      axios
        .get(url + "/" + e)
        .then((res) => {
          if (res.data.success) {
            if (res.data.data.items.length > 0) {
              console.log("price data", res.data.data);
              setItemFoundSuccessfully(true);
              setItemFound(res.data.data.items);
              setLoadSearchedData(false);
            } else {
              setItemFoundSuccessfully(false);
              setItemFound("");
              setLoadSearchedData(false);
            }
          }
        })
        .catch((e) => {
          console.log("error while searching medicine", e);
        });
    }
  };

  const handleChange = (event) => {
    // setState({ ...state, [event.target.name]: event.target.checked });

    let nSlug = event.target.name;
    console.log( 'PharmaReq::handleChange::', event.target.checked, event.target.name, event.target.value, `id-`+nSlug, defaultItems );
    if ( `id-`+nSlug in defaultItems ){
      let defaultSelected = defaultItems[ `id-`+nSlug ].items;

      console.log( 'PharmaReq::handleChange::', defaultSelected );

      if (defaultSelected.includes(event.target.value)){ // Already Exists Item and Remove it
        let indexOfItem = defaultSelected.indexOf( event.target.value );
        defaultSelected.splice( indexOfItem, 1 );
      }else{ // Adding Item
        defaultSelected.push( event.target.value );
      }

      let nItems = defaultItems;
      nItems[ `id-`+nSlug ].items = defaultSelected;

      dispatch({
        field: 'defaultItems',
        value: nItems
      });
    }
  };

  function handleAddItem(i) {
    console.log("selected item", i);

    // setSelectedLab(i);
    let readyToAdd = seletedMedItems;

    let itemExists = readyToAdd.filter((element, index, array) => {
        // Return true or false
        return element.itemId === i._id;
    });

    if ( itemExists.length<=0 ){
      readyToAdd.push({
        itemId: i._id,
        itemName: i.name || i.description,
        issueUnit: i.issueUnit,
        itemCode: i.itemCode,
        unitPrice: i.issueUnitCost,
        totalPrice: (1*1*1*i.issueUnitCost),
        issueUnit: i.issueUnit,
        schedule: 'Now',
        priority: '',
        dosage: 1,
        dosageType: '',
        duration: 1,
        frequency: 1,
        requestedQty: '',
        itemType: selectedItemToSearch,
        make_model: '',
        size: '',
        route: '',
        notes: {
          text: '',
          audio: null,
        }
      });
    }

    dispatch({
      field: 'seletedMedItems',
      value: readyToAdd
    });


    setSearchQuery("");
    // setaddLabRequest(true)
  }


  // Medical Form Declarations from Here
  const onChangeItemType = (e) => {
    dispatch({ field: "selectedItemToSearch", value: e.target.name });
    setItemFoundSuccessfully(false);
    setItemFound("");
  };

  const handlePauseMedsSearch = (e) => {
    /*setLoadSearchedData(true);
    clearTimeout(timer);

    const a = e.target.value.replace(/[^\w\s]/gi, "");
    setSearchQuery(a);

    setTimer(
      setTimeout(() => {
        triggerMedChange(a);
      }, 600)
    );*/
  };


  return (
    
    <div
      style={{
        backgroundColor: "rgb(19 213 159)",
        position: "fixed",
        display: "flex",
        width: "100%",
        height: "100%",
        flexDirection: "column",
        flex: 1,
        overflowY: "scroll",
      }}
    >
      <Header history={props.history} />

      <PatientDetailsQuick
        edrId={episodeId} />

      <div className="cPadding">


        <PatientClinicalInformation
          edrId={episodeId}
          wrapClass="mt-3 mb-3" />



        <div
          className="form-group "
          style={{
            ...styles.inputContainerForTextField
          }}
        >
          <TextField
            required
            label="Search by Item Name"
            name={"searchQuery"}
            value={searchQuery}
            onChange={handlePauseMedSearch}
            className="textInputStyle"
            variant="filled"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <AccountCircle />
                </InputAdornment>
              ),
              className: classes.input,
              classes: { input: classes.input },
              disableUnderline: true,
            }}
            InputLabelProps={{
              className: classes.label,
              classes: { label: classes.label },
            }}
          />
        </div>

        {searchQuery ? (
          <div
            style={{
              zIndex: 10,
            }}
            className="mb-3 mb-3"
          >
            <Paper style={{ maxHeight: 280, overflow: "auto" }}>
              {itemFoundSuccessfull && itemFound !== "" ? (
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Service Name</TableCell>
                      <TableCell align="right">Type</TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {itemFound.map((i) => {
                      return (
                        <TableRow
                          key={i.itemCode}
                          onClick={() => handleAddItem(i)}
                          style={{ cursor: "pointer" }}
                        >
                          <TableCell><i className="fa fa-caret-right"></i><span className="ml-2"><strong>{i.itemCode}</strong>: {i.description}</span><br />{i.scientificName}</TableCell>
                          <TableCell align="right">{i.cls}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              ) : loadSearchedData ? (
                <div style={{ textAlign: "center" }}>
                  <Loader
                    type="TailSpin"
                    color="#2c6ddd"
                    height={25}
                    width={25}
                    style={{
                      display: "inline-block",
                      padding: "10px",
                    }}
                  />
                  <span
                    style={{
                      display: "inline-block",
                      padding: "10px",
                    }}
                  >
                    <h4>Searching {displayItemToSearch} items...</h4>
                  </span>
                </div>
              ) : searchQuery && !itemFoundSuccessfull ? (
                <div style={{ textAlign: "center", padding: "10px" }}>
                  <h4>No {displayItemToSearch} Items Found!</h4>
                </div>
              ) : (
                undefined
              )}
            </Paper>
          </div>
        ) : (
          undefined
        )}

        {
          isEDNurse?
          <div
            className="form-group "
            style={{
              ...styles.inputContainerForTextField
            }}
          >
            

            <TextField
              required
              select
              fullWidth
              id="requestFrom"
              name="requestFrom"
              label="Verbal Request from"
              variant="filled"
              className="dropDownStyle"
              value={currentRequester}
              onChange={(e) => {
                dispatch({ field: "currentRequester", value: e.target.value });
              }}
              InputProps={{
                className: classes.input,
                classes: { input: classes.input },
              }}
            >
              {edDoctorStaff ?
                Object.entries(edDoctorStaff).map(([vali, val]) => {
                  return (
                    <MenuItem key={val.id} value={val.id}>{val.name}</MenuItem>
                  );
                }):undefined}
            </TextField>
          </div>:undefined
        }

        <h4 className="h6 text-white"
          style={{
            fontWeight: '700'
          }}>{ `Selected ${displayItemToSearch} Item(s)` } 
          {
            /*props.history.location.state.lifelimbStatus?<span className="badge badge-success pull-right">LifeLimb: {(props.history.location.state.lifelimbStatus?'ON':'Off')}</span>:undefined*/
          }
        </h4>
        <div
          style={{
            zIndex: 10,
            marginTop: 10,
          }}
        >
          <Paper className="mb-3">
            <Table size="small" stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>Item Name</TableCell>
                </TableRow>
              </TableHead>
              {seletedMedItems.length > 0 ? (
                <TableBody>
                  {seletedMedItems.map((i, itemIx) => {
                    return (
                      <TableRow
                        key={i}
                        style={{ cursor: "pointer" }}
                        title="Click to Remove"
                      >
                        <TableCell>
                          <div className="serviceOffers">
                            <div className="serviceHeader">
                              <div className="serviceDesc-Name">{i.xUrgent==='Yes'?<i className="fa fa-info-circle text-market"></i>:<i className="fa fa-caret-right"></i>}<span className="ml-2 text-capitalize"><strong>{i.itemCode}</strong>: {i.itemName} ( {i.issueUnit} )</span></div>
                              <div className="serviceOption text-right">
                                <Icon className="fa fa-trash" color="secondary" onClick={() => removeSelectedLabItem(i, i.itemId)} />
                              </div>
                            </div>
                            {/*<pre>{JSON.stringify(i, undefined, 4)}</pre>*/}
                            <div className="container-fluid-x">

                            {
                              selectedItemToSearch==='non_pharmaceutical'?<div className="row">
                                <div
                                  className="col-md-3 col-sm-6 col-6"
                                  style={{
                                    ...styles.inputContainerForTextField,
                                  }}
                                >
                                  <div className="row row-5 align-items-center">
                                    <div className="col-6 col-md-6"><strong>Req. Qty{i.dosageType?` ( ${i.dosageType} )`:''}:</strong></div>
                                    <div className="col-6 col-md-6">
                                      <TextField
                                        required
                                        name={"requestedQty"}
                                        value={i.requestedQty}
                                        onChange={(e) => {
                                          onChangeValue(e.target.value, 'requestedQty', i.itemId)
                                        }}
                                        className="textInputStyle text-center"
                                        type="number"
                                        InputProps={{
                                          endAdornment: (
                                            <InputAdornment position="end">
                                              {i.dosageType}
                                            </InputAdornment>
                                          ),
                                          className: classes.input,
                                          classes: { input: classes.input },
                                          disableUnderline: true,
                                        }}
                                        InputProps={{
                                          className: classes.input,
                                          classes: { input: classes.input },
                                        }}
                                      />
                                      {
                                        isFormSubmitted && (i.requestedQty === "" || i.requestedQty <= 0) ? <div class="mt-2 text-danger">Required</div>:undefined
                                      }
                                    </div>
                                  </div>

                                  {/* <ErrorMessage
                                        name={requestedQty}
                                        isFormSubmitted={isFormSubmitted}
                                      /> */}
                                </div>
                              </div>:
                              <div className="row">



                                <div
                                  className="col-md-3 col-sm-6 col-6"
                                  style={{
                                    ...styles.inputContainerForTextField,
                                  }}
                                >

                                  <div className="row row-5 align-items-center">
                                    <div className="col-6 col-md-6"><strong>Schedule:</strong>&nbsp;</div>
                                    <div className="col-6 col-md-6">
                                      <TextField
                                        required
                                        select
                                        disabled={props.disableFields ? true : false}
                                        name={"schedule"}
                                        value={i.schedule}
                                        onChange={(e) => {
                                          onChangeValue(e.target.value, 'schedule', i.itemId)
                                        }}
                                        className="textInputStyle text-center"
                                        InputProps={{
                                          // shrink: true,
                                          className: classes.input,
                                          classes: { input: classes.input },
                                        }}
                                      >
                                        {scheduleArray.map((val) => {
                                          return (
                                            <MenuItem key={val.key} value={val.key}>
                                              {val.value}
                                            </MenuItem>
                                          );
                                        })}
                                      </TextField>
                                    </div>
                                  </div>
                                  {/* <ErrorMessage
                                        name={schedule}
                                        isFormSubmitted={isFormSubmitted}
                                      /> */}
                                </div>

                                <div
                                  className="col-md-3 col-sm-6 col-6"
                                  style={{
                                    ...styles.inputContainerForTextField,
                                  }}
                                >
                                  <div className="row row-5 align-items-center">
                                    <div className="col-6 col-md-6"><strong>Priority:</strong></div>
                                    <div className="col-6 col-md-6">
                                      <TextField
                                        required
                                        select
                                        fullWidth
                                        disabled={props.disableFields ? true : false}
                                        id="priority"
                                        name="priority"
                                        value={i.priority}
                                        onChange={(e) => {
                                          onChangeValue(e.target.value, 'priority', i.itemId)
                                        }}
                                        className="dropDownStyle"
                                        InputProps={{
                                          className: classes.input,
                                          classes: { input: classes.input },
                                        }}
                                      >
                                        <MenuItem value="">
                                          <em>None</em>
                                        </MenuItem>
                                        {priorityArray.map((val) => {
                                          return (
                                            <MenuItem key={val.key} value={val.key}>
                                              {val.value}
                                            </MenuItem>
                                          );
                                        })}
                                      </TextField>
                                      {
                                        isFormSubmitted && i.priority === "" ? <div class="mt-2 text-danger">Required</div>:undefined
                                      }
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="col-md-3 col-sm-6 col-12"
                                  style={{
                                    ...styles.inputContainerForTextField,
                                  }}
                                >
                                  <div className="row row-5 align-items-center">
                                    <div className="col-6 col-md-4"><strong>Dosage:</strong>&nbsp;</div>
                                    <div className="col-6 col-md-8">
                                      <div className="row row-5">
                                        <div className="col-4">
                                          <TextField
                                            required
                                            disabled={props.disableFields ? true : false}
                                            name={"dosage"}
                                            value={i.dosage}
                                            onChange={(e) => {
                                              onChangeValue(e.target.value, 'dosage', i.itemId)
                                            }}
                                            className="textInputStyle text-center"
                                            size="small"
                                            type="number"
                                            InputProps={{
                                              inputProps: { min: 1 },
                                              shrink: true,
                                              className: classes.input,
                                              classes: { input: classes.input },
                                            }}
                                          />
                                        </div>
                                        <div className="col-8">
                                          <TextField
                                            required
                                            select
                                            fullWidth
                                            disabled={props.disableFields ? true : false}
                                            id="dosageType"
                                            name="dosageType"
                                            value={i.dosageType}
                                            onChange={(e) => {
                                              onChangeValue(e.target.value, 'dosageType', i.itemId)
                                            }}
                                            className="dropDownStyle"
                                            InputProps={{
                                              className: classes.input,
                                              classes: { input: classes.input },
                                            }}
                                          >
                                            <MenuItem value="">None</MenuItem>
                                            {dosageArray.map((val) => {
                                              return (
                                                <MenuItem key={val.key} value={val.key}>
                                                  {val.value}
                                                </MenuItem>
                                              );
                                            })}
                                          </TextField>
                                        </div>
                                      </div>
                                      {
                                        isFormSubmitted && (i.dosage === "" || i.dosage <= 0) ? <div class="mt-2 text-danger">Required</div>:undefined
                                      }
                                      {
                                        isFormSubmitted && (i.dosageType === "") ? <div class="mt-2 text-danger">Required</div>:undefined
                                      }
                                    </div>
                                  </div>
                                </div>

                                <div
                                  className="col-md-3 col-sm-6 col-6"
                                  style={{
                                    ...styles.inputContainerForTextField,
                                  }}
                                >
                                  <div className="row row-5 align-items-center">
                                    <div className="col-6 col-md-6"><strong>Frequency:</strong></div>
                                    <div className="col-6 col-md-6">
                                      <TextField
                                        required
                                        select
                                        fullWidth
                                        disabled={props.disableFields ? true : false}
                                        id="frequency"
                                        name="frequency"
                                        value={i.frequency}
                                        onChange={(e) => {
                                          onChangeValue(e.target.value, 'frequency', i.itemId)
                                        }}
                                        className="dropDownStyle"
                                        InputProps={{
                                          className: classes.input,
                                          classes: { input: classes.input },
                                        }}
                                      >
                                        <MenuItem value="">None</MenuItem>
                                        {frequencyArray.map((val) => {
                                          return (
                                            <MenuItem key={val.key} value={val.key}>
                                              {val.value}
                                            </MenuItem>
                                          );
                                        })}
                                      </TextField>
                                      {
                                        isFormSubmitted && (i.frequency === "" || i.frequency <= 0) ? <div class="mt-2 text-danger">Required</div>:undefined
                                      }
                                    </div>
                                  </div>
                                </div>

                                <div
                                  className="col-md-3 col-sm-6 col-6"
                                  style={{
                                    ...styles.inputContainerForTextField,
                                  }}
                                >
                                  <div className="row row-5 align-items-center">
                                    <div className="col-6 col-md-6"><strong>Duration <small>(Days)</small>:</strong></div>
                                    <div className="col-6 col-md-6">
                                      <TextField
                                        required
                                        disabled={props.disableFields ? true : false}
                                        name={"duration"}
                                        value={i.duration}
                                        onChange={(e) => {
                                          onChangeValue(e.target.value, 'duration', i.itemId)
                                        }}
                                        className="textInputStyle text-center"
                                        type="number"
                                        InputProps={{
                                          inputProps: { min: 1 },
                                          className: classes.input,
                                          classes: { input: classes.input },
                                        }}
                                      />
                                      {
                                        isFormSubmitted && (i.duration === "" || i.duration <= 0) ? <div class="mt-2 text-danger">Required</div>:undefined
                                      }
                                    </div>
                                  </div>

                                  {/* <ErrorMessage
                                        name={duration}
                                        isFormSubmitted={isFormSubmitted}
                                      /> */}
                                </div>

                                <div
                                  className="col-md-3 col-sm-6 col-6"
                                  style={{
                                    ...styles.inputContainerForTextField,
                                  }}
                                >
                                  <div className="row row-5 align-items-center">
                                    <div className="col-6 col-md-6"><strong>Req. Qty{i.dosageType?` ( ${i.dosageType} )`:''}:</strong></div>
                                    <div className="col-6 col-md-6">
                                      <TextField
                                        required
                                        name={"requestedQty"}
                                        value={i.requestedQty}
                                        onChange={(e) => {
                                          onChangeValue(e.target.value, 'requestedQty', i.itemId)
                                        }}
                                        className="textInputStyle text-center"
                                        type="number"
                                        InputProps={{
                                          endAdornment: (
                                            <InputAdornment position="end">
                                              {i.dosageType}
                                            </InputAdornment>
                                          ),
                                          className: classes.input,
                                          classes: { input: classes.input },
                                          disableUnderline: true,
                                        }}
                                        InputProps={{
                                          className: classes.input,
                                          classes: { input: classes.input },
                                        }}
                                      />
                                      {
                                        isFormSubmitted && (i.requestedQty === "" || i.requestedQty <= 0) ? <div class="mt-2 text-danger">Required</div>:undefined
                                      }
                                    </div>
                                  </div>

                                  {/* <ErrorMessage
                                        name={requestedQty}
                                        isFormSubmitted={isFormSubmitted}
                                      /> */}
                                </div>

                                <div
                                  className="col-md-6 col-sm-12 col-12"
                                  style={{
                                    ...styles.inputContainerForTextField,
                                  }}
                                >
                                  <div className="row row-5 align-items-center">
                                    <div className="col-4 col-md-3 col-lg-2"><strong>Route Taken:</strong></div>
                                    <div className="col-8 col-md-9 col-lg-10">
                                      <TextField
                                        required
                                        select
                                        fullWidth
                                        name="route"
                                        value={i.route}
                                        onChange={(e) => {
                                          onChangeValue(e.target.value, 'route', i.itemId)
                                        }}
                                        className="dropDownStyle"
                                        InputProps={{
                                          className: classes.input,
                                          classes: { input: classes.input },
                                        }}
                                      >
                                        {routeArray.map((val) => {
                                          return (
                                            <MenuItem key={val.key} value={val.key}>
                                              {val.value}
                                            </MenuItem>
                                          );
                                        })}
                                      </TextField>
                                      {
                                        isFormSubmitted && i.route === "" ? <div class="mt-2 text-danger">Required</div>:undefined
                                      }
                                      {/* <ErrorMessage
                                            name={route}
                                            isFormSubmitted={isFormSubmitted}
                                          /> */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            }
                            </div>



                            <div className="serviceNotes">
                              <div className="micControl">
                                <div className="d-flex align-items-center">
                                  <div
                                    style={{
                                      backgroundColor: "#2C6DDD",
                                      height: 42,
                                      borderRadius: 42,
                                      width: 42,
                                      paddingLeft: 10,
                                      cursor: 'pointer'
                                    }}
                                  >
                                    {isRecording ? (
                                      recordingField===i.itemId?
                                      <StopIcon
                                        style={{ marginTop: 10, color: "white" }}
                                        fontSize="16px"
                                        onClick={stopRecording}
                                        disabled={!isRecording}
                                        color="secondary"
                                      />:
                                      <MicIcon
                                        style={{ marginTop: 10, color: "white" }}
                                        fontSize="16px"
                                        onClick={() => {
                                          startRecording(i, i.itemId)
                                        }}
                                        disabled={isRecording}
                                        color="primary"
                                      />
                                    ) : (
                                      <MicIcon
                                        style={{ marginTop: 10, color: "white" }}
                                        fontSize="16px"
                                        onClick={() => {
                                          startRecording(i, i.itemId)
                                        }}
                                        disabled={isRecording}
                                        color="primary"
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="notesDiv">
                                <TextField
                                  // required
                                  multiline="true"
                                  rows="3"
                                  // disabled={enableForm}
                                  label="Any Comments / Notes? (Optional)"
                                  name={"labComments"}
                                  value={i.notes.text}
                                  // error={labComments === ""}
                                  onChange={(e) => {onChangeNotesValue(e.target.value, i, i.itemId)}}
                                  className="textInputStyle"
                                  variant="filled"
                                  InputProps={{
                                    disableUnderline: true,
                                  }}
                                />
                                {isRecording && (recordingField===i.itemId) ? (
                                  <div
                                    style={{
                                      marginLeft: matches ? 110 : 18,
                                      marginRight: matches ? 110 : 75,
                                      width: matches ? 550 : 100,
                                    }}
                                  >
                                    <Loader
                                      type="ThreeDots"
                                      color="blue"
                                      height={40}
                                      width={40}
                                    />
                                  </div>
                                ) : (
                                  <audio
                                    style={{
                                      marginRight: 10,
                                      width: matches ? 450 : 260,
                                      marginTop: 6,
                                    }}
                                    src={blobURL[i.itemId]}
                                    controls="controls"
                                  />
                                )}

                                {
                                  selectedItemToSearch==='non_pharmaceutical' && ( i.notes.text === '' && i.notes.audio === null ) && isFormSubmitted ? <div class="mt-2 text-danger">Required</div>:undefined
                                }

                              </div>
                            </div>
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              ) : (
                <TableBody>
                  <TableRow key="0">
                    <TableCell align="center" colSpan={5}>No <strong>{displayItemToSearch}</strong> Item Selected</TableCell>
                  </TableRow>
                </TableBody>
              )} 
            </Table>
          </Paper>
          {seletedMedItems.length > 0 ? (
            <div className="row row-5">
              <div className="col-12 col-md-4 offset-md-4">
                <Button
                  // className="addButton"
                  style={{
                    ...styles.stylesForButton,
                    backgroundColor: "#AD6BBF",
                    color: "white",
                    cursor: "pointer",
                    borderRadius: 5,
                    backgroundColor: "rgb(173, 107, 191)",
                    height: matches ? 56 : 45,
                    outline: "none",
                    width: "100%",
                  }}
                  // disabled={!addLabRequest}
                  onClick={addSelectedLabItem}
                  variant="contained"
                  color="primary"
                  fullWidth
                >Submit</Button>
              </div>
            </div>
          ) : (
            undefined
          )} 
          <hr />
        </div>


        <div
          className="container-fluid d-none"
        >
          <div
            className="row"
            style={{
              backgroundColor: "white",
              height: matches ? 55 : 155,
              display: "flex",
              alignItems: "center",
              borderRadius: 5,
              paddingTop: 8,
            }}
          >
            <h6 className="col-md-4" style={{ verticalAlign: "center" }}>
              Item Type
            </h6>

            <FormControl className="col-md-8" component="fieldset">
              <RadioGroup
                row
                aria-label="position"
                name="position"
                // defaultValue="top"
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <FormControlLabel
                  // value={selectedItemToSearch}
                  name={"pharmaceutical"}
                  control={<Radio color="primary" />}
                  label="Pharmaceutical"
                  onChange={onChangeItemType}
                  checked={
                    selectedItemToSearch === "pharmaceutical"
                      ? true
                      : false
                  }
                />

                <FormControlLabel
                  // value={selectedItemToSearch}
                  name={"non_pharmaceutical"}
                  control={<Radio color="primary" />}
                  label="Non-Pharmaceutical"
                  onChange={onChangeItemType}
                  checked={
                    selectedItemToSearch === "non_pharmaceutical"
                      ? true
                      : false
                  }
                />
              </RadioGroup>
            </FormControl>
          </div>
        </div>

        <div style={{ display: "flex", justifyContent: "space-between", marginTop: 20 }}>
          <div style={{ marginTop: "2%", marginBottom: "2%" }}>
            <img
              onClick={() => {
                // Cancel
                props.history.push({
                  pathname: "/dashboard/home/"+episodeId+"/pharmacy",
                });
              }}
              src={Back}
              style={{ width: 45, height: 35, cursor: "pointer" }}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "2%",
              marginBottom: "2%",
            }}
          >
            {/*
            <Button
              style={{
                ...styles.stylesForSubmitButton,
              }}
              variant="contained"
              color="primary"
            >Submit</Button>
            */}
          </div>
        </div>
      </div>
      <Notification
        msg={errorMsg}
        open={openNotification}
        success={successMsg}
      />
    </div>
  );
}