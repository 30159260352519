import React, { useEffect, useState, useReducer } from "react";
import { withStyles } from '@material-ui/core/styles';
import Header from "../../components/Header/Header";
import classNames from "classnames";
import ErrorMessage from "../../components/ErrorMessage/errorMessage";
import Back from "../../assets/img/Back_Arrow.png";
import { 
  Button, 
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment,
  MenuItem,
  TextField,
  Select,
  useMediaQuery,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
} from "@material-ui/core";
import cookie from "react-cookies";
import { displayInHumanDifference, replacePatientIdIfNull } from "../../utils/commonFunc";

import axios from "axios";
import {
  getSingleEdr,
  getSearchedPharmaceuticalItemsUrl,
  getSearchedItemsNonPharmaceuticalUrl,
  addConsultationNotes,
  getDoctorSpecialty,
  getSpecialityDoctor2,
} from "../../public/endpoins";

import useStylesForInput from "../../assets/jss/material-dashboard-react/inputStyle";
import styles from "./styles";
import Loader from "react-loader-spinner";
import {
  Checkbox, 
  FormLabel, 
  FormControl, 
  FormGroup, 
  FormControlLabel,
  RadioGroup, 
  Radio
} from '@material-ui/core';
import {
  statusArray,
} from "./configForNotesScreen";
import Icon from '@material-ui/core/Icon';
import CloseIcon from '@material-ui/icons/Close';
import MicIcon from "@material-ui/icons/Mic";
import StopIcon from "@material-ui/icons/Stop";
import MicRecorder from "mic-recorder-to-mp3";
import AccountCircle from "@material-ui/icons/SearchOutlined";
import Notification from "../../components/Snackbar/Notification.js";
import PatientClinicalInformation from "../../components/PatientDetails/PatientClinicalInformation";
import PatientDetailsQuick from "../../components/PatientDetails/PatientDetailsQuick";

const Mp3Recorder = new MicRecorder({ bitRate: 128 });

const scheduleArray = [
  { key: "Now", value: "Now" },
  // { key: "Immediate", value: "Immediate" },
];
const priorityArray = [
  { key: "High", value: "High" },
  { key: "Low", value: "Low" },
  // { key: "PRN", value: "PRN" },
];

const frequencyArray = [
  // { key: 1, value: "QD" },
  // { key: 2, value: "BiD" },
  // { key: 3, value: "TiD" },
  // { key: 4, value: "QiD" },
  // { key: 0, value: "PRN" },

  { key: 1,  value: "Q.H - Each Hour" },
  { key: 11, value: "Q.D - Every day" },
  { key: 2,  value: "B.I.D - Two times each day" },
  { key: 3,  value: "T.I.D - Three times each day" },
  { key: 4,  value: "Q.I.D - Four times each day" },

  { key: 20,  value: "Q.S - As much as su" },
  { key: 21,  value: "Q.A.C - Before every meal" },
  { key: 22,  value: "Q.O.D - Every other day" },
  { key: 23,  value: "Q.I - As much as you like" },
  { key: 24,  value: "Q.H.S - Every bedtime" },
  { key: 0,  value: "PRN When needed" },
  { key: 25,  value: "Other" },
];

const sizeArray = [
  { key: "Small", value: "Small" },
  { key: "Medium", value: "Medium" },
  { key: "Large", value: "Large" },
  { key: "Extra Large", value: "Extra Large" },
];

const modalArray = [
  { key: "Old", value: "Old" },
  { key: "New", value: "New" },
];

const routeArray = [
  { key: "ROA000", value: "N/A" },
  { key: "ROA001", value: "DENTAL" },
  { key: "ROA002", value: "DENTAL INFILTRATION INJ. DENTAL BLOCK" },
  { key: "ROA003", value: "DENTAL INJECTION" },
  { key: "ROA007", value: "EPIDURAL" },
  { key: "ROA009", value: "CAUDAL" },
  { key: "ROA010", value: "EXTERNAL" },
  { key: "ROA011", value: "GENITAL" },
  { key: "ROA012", value: "IM" },
  { key: "ROA032", value: "IMPLANT" },
  { key: "ROA033", value: "INFILTRATION DENTAL BLOCK" },
  { key: "ROA034", value: "INHALATION" },
  { key: "ROA036", value: "INTRA-AMNIOTIC" },
  { key: "ROA037", value: "INTRA-ARTICULAR" },
  { key: "ROA039", value: "INTRACAVERNOSAL" },
  { key: "ROA040", value: "INTRACERVICAL" },
  { key: "ROA041", value: "INTRAGLUTEALLY" },
  { key: "ROA042", value: "INTRA-MASTITIS" },
  { key: "ROA043", value: "INTRAMMAMARY" },
  { key: "ROA045", value: "INTRA-PERIODONTAL POCKET" },
  { key: "ROA046", value: "INTRA-PERITONEAL" },
  { key: "ROA047", value: "INTRATRACHEAL" },
  { key: "ROA048", value: "INTRAUTERIN" },
  { key: "ROA049", value: "INTRAVAGINAL" },
  { key: "ROA050", value: "INTRA-VESICALLY" },
  { key: "ROA051", value: "IV" },
  { key: "ROA052", value: "IV CATHETER" },
  { key: "ROA053", value: "IV INFUSION" },
  { key: "ROA064", value: "LOCAL ORAL" },
  { key: "ROA065", value: "LOCAL ORAL DENTAL" },
  { key: "ROA066", value: "ORAL RINSE" },
  { key: "ROA067", value: "NASAL" },
  { key: "ROA071", value: "NEBULISATION" },
  { key: "ROA072", value: "OCULAR" },
  { key: "ROA074", value: "ORAL" },
  { key: "ROA075", value: "ORAL INHALATION" },
  { key: "ROA083", value: "ORALLY TUBE FEEDING" },
  { key: "ROA084", value: "OTIC" },
  { key: "ROA085", value: "PARENTERAL" },
  { key: "ROA087", value: "PERITONEAL DIALYSIS" },
  { key: "ROA088", value: "RECTAL" },
  { key: "ROA089", value: "SC" },
  { key: "ROA090", value: "SPINAL" },
  { key: "ROA091", value: "SUBLINGUAL" },
  { key: "ROA092", value: "TOPICAL" },
  { key: "ROA093", value: "TOPICAL ANAL" },
  { key: "ROA094", value: "TOPICAL- SCALP" },
  { key: "ROA095", value: "TRANSDERMAL" },
  { key: "ROA096", value: "TRANSMUCOSAL" },
  { key: "ROA097", value: "VAGINAL" },
  { key: "ROA100", value: "URETHRAL" },
  { key: "ROA101", value: "MISCELLANEOUS" },
  { key: "ROA103", value: "SUBARACHNOID" },
  { key: "ROA107", value: "INTRATHECAL" },
  { key: "ROA108", value: "INTRAOCULAR" },
  { key: "ROA109", value: "INTRACARDIAC" },
  { key: "ROA112", value: "INTRADERMAL" },
  { key: "ROA114", value: "INTRAVENTRICULAR" },
  { key: "ROA115", value: "SUBMUCOSAL" },
  { key: "ROA123", value: "INTRAPLEURAL" },
  { key: "ROA133", value: "INFILTRATION" },
  { key: "ROA141", value: "IRRIGATION" },
  { key: "ROA147", value: "SUBDERMAL" },
  { key: "ROA148", value: "BUCCAL" },
  { key: "ROA159", value: "ARTERIOVENOUS" },
  { key: "ROA160", value: "CENTRAL NERVE BLOCK" },
  { key: "ROA161", value: "DEEP SC" },
  { key: "ROA162", value: "DERMAL" },
  { key: "ROA163", value: "ENDOTRACHEAL" },
  { key: "ROA164", value: "ILIOHYPOGASTRIC (NERVE BLOCK)" },
  { key: "ROA165", value: "ILIONGUINAL" },
  { key: "ROA166", value: "INTRA-ARTERIAL" },
  { key: "ROA167", value: "INTRABURSAL" },
  { key: "ROA168", value: "INTRACAVITARY" },
  { key: "ROA169", value: "INTRALESIONAL" },
  { key: "ROA170", value: "INTRAMEDULLARILY" },
  { key: "ROA171", value: "INTRAOSSEOUS" },
  { key: "ROA172", value: "INTRA-PULMONARY" },
  { key: "ROA173", value: "INTRASPINAL" },
  { key: "ROA174", value: "INTRASUBLESIONAL" },
  { key: "ROA175", value: "INTRASYNOVIAL" },
  { key: "ROA176", value: "IV BOLUS" },
  { key: "ROA177", value: "OROFACIAL" },
  { key: "ROA178", value: "OROPHARYNGEAL" },
  { key: "ROA179", value: "PERIANAL" },
  { key: "ROA180", value: "PERIARTICULAR" },
  { key: "ROA181", value: "PERIBULBAR" },
  { key: "ROA182", value: "PERIPHERAL NERVE BLOCK" },
  { key: "ROA183", value: "SUBCONJUNCTIVAL" },
  { key: "ROA184", value: "SYMPATHETIC NERVE BLOCK" },
  { key: "ROA185", value: "TRANSCERVICAL" },
  { key: "ROA187", value: "SC INFUSION" },
  { key: "ROA191", value: "INTRAVITREAL" },
  { key: "ROA192", value: "CARDIAC PERFUSION" },
  { key: "ROA202", value: "INTRACAMERAL" },
  { key: "ROA206", value: "INTESTINAL INFUSION" },
  { key: "ROA207", value: "EPILESIONAL" },
  { key: "ROA209", value: "INTRAURETERIC" },
  { key: "ROA210", value: "INTRA-LYMPHATIC" },
  { key: "ROA222", value: "PERINEURAL" },
];

export default function ConsultancyRequestsPage(props) {
  
  const matches = useMediaQuery("(min-width:600px)");
  const classes = useStylesForInput();

  const mystyle = {
    color: "white",
    backgroundColor: "DodgerBlue",
    padding: "10px",
    fontFamily: "Arial"
  };

  const initialState = {
    isEDNurse: false,
    edDoctorSpeciality: null,
    edDoctorsInternal: [],
    edDoctorsExternal: [],
    isFormSubmitted: false,
    bulkTestSelected: false,
    resetedOn: "",
    defaultItems: {},
    seletedMedItems: [],
    labPriority: "",
    labTechnician: "",
    comments: "",
    labTechniciansList: [],

    isBlocked: false,
    isRecording: false,
    blobURL: '',
    fileAudio: '',
    selectedItemToSearch: "pharmaceutical",


  };
  function reducer(state, { field, value }) {
    return {
      ...state,
      [field]: value,
    };
  }
  const [state, dispatch] = useReducer(reducer, initialState);
  const {
    isEDNurse,
    edDoctorSpeciality,
    edDoctorsInternal,
    edDoctorsExternal,
    isFormSubmitted,
    bulkTestSelected,
    resetedOn,
    defaultItems,
    seletedMedItems,
    labPriority,
    labTechnician,
    comments,
    labTechniciansList,
    isBlocked,
    isRecording,
    blobURL,
    fileAudio,
    selectedItemToSearch
  } = state;

  const handleCheckupChange = (event) => {
    dispatch({field: 'bulkTestSelected', value: event.target.value});
  };

  const uniqueCodeField = (name) => {
    name = name.toLowerCase();
    name = name.replace(/[^a-z0-9]+/g, '-');
    name = name.replace(/^-+|-+$/g, '');
    return name;
  }

  const itemsExistInDefaultSelection = (slug, item) => {

    if ( `id-`+slug in defaultItems ){
      let defaultSelected = defaultItems[ `id-`+slug ].items;
      if ( defaultSelected.includes( item ) )
      { // Already Exists Item and Remove it
        return true;
      }
    }

    return false;
  };

  const onChangeValue = (e, xIndex, item) => {
    console.log("PharmaReq :: onChangePriorityValue", e, xIndex, item);

    let newSelectedLabItems = seletedMedItems;
    newSelectedLabItems.map((d, index) => {
      if (d.itemId === item){
        console.log("PharmaReq :: onChangePriorityValue :: d", d);
        let xPriority     = d['priority'];
        let xDosage       = d['dosage'];
        let xDuration     = d['duration'];
        let xFrequency    = d['frequency'];
        let xReqQty       = d['requestedQty'];

        if (xIndex === "dosage"){
          xDosage = e;
        }
        if (xIndex === "duration"){
          xDuration = e;
        }
        if (xIndex === "frequency"){
          xFrequency = e;
        }
        if (xIndex === "requestedQty"){
          xReqQty = e;
        }

        let finalReqQty = 0;
        if ( xFrequency === 0 || xFrequency>=11 ){
          finalReqQty = xReqQty;
        }else{
          finalReqQty = xDosage * xDuration * xFrequency;
        }

        // requestedQty: 1,


        d[`${xIndex}`] = e;
        d['requestedQty'] = finalReqQty;
        d['totalPrice'] = d['unitPrice']*finalReqQty;
      }
    });
    console.log("PharmaReq :: onChangePriorityValue :: nv", newSelectedLabItems);
    dispatch({
      field: 'seletedMedItems',
      value: newSelectedLabItems
    });
  };

  const onChangePriorityValue = (e, index, item) => {
    console.log("PharmaReq :: onChangePriorityValue", e, index, item);

    let newSelectedLabItems = seletedMedItems;
    newSelectedLabItems.map((d, index) => {
      if (d.itemId === item){
        d.priority = e;
      }
    });
    console.log("PharmaReq :: onChangePriorityValue :: nv", newSelectedLabItems);
    dispatch({
      field: 'seletedMedItems',
      value: newSelectedLabItems
    });
  };

  const onChangeNotesValue = (e) => {
    dispatch({
      field: 'comments',
      value: e
    });
  };

  const removeSelectedLabItem = (index, id) => {
    if (window.confirm('Confirm Removal?')){

      seletedMedItems.map((d, index) => {
        if (d.itemId === id){
          console.log('LabReuqest::remove::', id, 'included');
          let indexOfItem = seletedMedItems.indexOf( d );
          console.log('LabReuqest::remove::', 'indexOfItem', indexOfItem);
          seletedMedItems.splice( indexOfItem, 1 );
          dispatch({
            field: 'seletedMedItems',
            value: seletedMedItems
          });
        }
      });


      console.log('LabReuqest::remove::', index, id);
      if (seletedMedItems.includes(id)){ // Already Exists Item

        
      }
    }
  };

  const validateFields = () => {

    let panadTS = true;

    if (selectUserData && selectUserType!==""){

    }else{
      setOpenNotification(true);
      setErrorMsg("You must select a Consultant.");
      panadTS = false;
    }

    return panadTS;
  }

  const addSelectedLabItem = () => {
    // PharmaReq 
    dispatch({
      field: 'isFormSubmitted',
      value: true
    });

    if (
      validateFields()
    ){
      
      setOpenNotification(true);
      setsuccessMsg("Adding the Consultation Request");

      const object = {
        addedBy: currentUser._id,
        edrId: episodeId,
        notes: comments,
        consultant: selectUserData.id,
        speciality: edDoctorSpeciality,
        subType: (selectUserType==="external"?"External":"Internal"),
        status: 'pending'
      };

      console.log('RequestNotes', object)




      const params = {
        requestedBy: currentUser._id,
        reconciliationNotes: [],
        item: [
          ...seletedMedItems
        ],
        status: 'pending',
        secondStatus: 'pending',
        edrId: episodeId,
      };
      console.log( 'PharmaReqs', params );
      // props.hideDialog(false);
      
      //SS
      let formData = new FormData();
      formData.append("addedBy", currentUser._id);
      formData.append("edrId", episodeId);
      formData.append("notes", comments);
      formData.append("consultant", selectUserData.id);
      formData.append("speciality", edDoctorSpeciality);
      formData.append("status", 'pending');
      formData.append("subType", (selectUserType==="external"?"External":"Internal"));
      if ( fileAudio!=="" && fileAudio instanceof Blob )
      {
        formData.append("file", fileAudio, 'file');
      }

      axios
        .put(addConsultationNotes, formData)//addConsultationNotes
        .then((res) => {
          console.log( 'PharmaReq', res.data );
          if (res.data.success) {
            props.history.push({
              pathname: "/dashboard/home/"+episodeId+"/consultation",
            });
          } else if (!res.data.success) {
            setOpenNotification(true);
            setErrorMsg("Error while adding the Parma Request");
          }
        })
        .catch((e) => {
          console.log('PharmaReq', "error after adding Parma Request", e);
          setOpenNotification(true);
          setErrorMsg("An error occurred while adding the Parma Request.");
        });
    }
    // else{
    //   setOpenNotification(true);
    //   setErrorMsg("Error while adding the Parma Request");
    // }
  };

  const [currentUser] = useState(cookie.load("current_user"));
  const [timer, setTimer] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [itemFound, setItemFound] = useState("");
  const [itemFoundSuccessfull, setItemFoundSuccessfully] = useState(false);
  const [loadSearchedData, setLoadSearchedData] = useState(false);
  const [selectedLab, setSelectedLab] = useState("");

  const [mItems, setMItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [myDefaultItems, setMyDefaultItems] = useState([]);

  const [selectUserData, setSelectUserData] = useState({})
  const [selectUserType, setSelectUserType] = useState('')


  const [consultationSpecialityList, setConsultationSpecialityList] = useState([]);

  const [patientInsured, setPatientInsured] = useState(false);

  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setsuccessMsg] = useState("");
  const [openNotification, setOpenNotification] = useState(false);


  const [patientDetails, setPatientDetails] = useState({});
  const [selectedRecord, setSelectedRecord] = useState({});

  const [ episodeId, setEpisodeId ] = useState(props.match.params.id);
  const [ pageId, setPageId ] = useState(props.match.params.pagename);
  
  if (openNotification) {
    setTimeout(() => {
      setOpenNotification(false);
      setErrorMsg("");
      setsuccessMsg("");
    }, 4000);
  }

  useEffect(() => {

    if ( currentUser.staffType === "Nurses" && currentUser.subType.includes("ED Nurse") )
    {
      dispatch({ field: "isEDNurse", value: true });
    }else{
      dispatch({ field: "edDoctorSpeciality", value: currentUser._id });
    }
    console.log('edDoctorSpeciality', edDoctorSpeciality);

    axios
      .get(getSingleEdr + "/" + episodeId)
      .then((res) => {
        console.log('handleView::', res.data.data);
        if (res.data.success) {

          let remoteRecord = replacePatientIdIfNull(res.data.data);
          setSelectedRecord(remoteRecord);

          if (remoteRecord.patientId.paymentMode === "Insured"){
            setPatientInsured(true);
          }

          const object = {
            '_id': remoteRecord._id,
            patientName: remoteRecord.patientId.name[0].given[0] + " " + remoteRecord.patientId.name[0].family,
            mrn: remoteRecord.requestNo,//remoteRecord.patientId.identifier[0].value,
            age: remoteRecord.patientId.age,
            gender: remoteRecord.patientId.gender,
            weight: remoteRecord.patientId.weight,
            department: remoteRecord.currentLocation,
            chiefComplaint: remoteRecord.chiefComplaint.length > 0 ? remoteRecord.chiefComplaint.pop().chiefComplaintId.short_desc : "N/A",
            chiefComplaintOthers: remoteRecord.chiefComplaintOthers,
          };
          setPatientDetails(object);
        }
      })
      .catch((e) => {
        console.log("error after searching patient request", e);
      });

      console.log('handleView::rb::', isRecording, isBlocked);
    navigator.mediaDevices &&
      navigator.mediaDevices.getUserMedia(
        { audio: true },
        () => {
          dispatch({ field: "isBlocked", value: false });
        },
        () => {
          dispatch({ field: "isBlocked", value: true });
        }
      );
  }, []);

  useEffect(() => {

    axios
    .get(getDoctorSpecialty)
    .then((res) => {
      if (res.data.success) {
        console.log("response", res.data.data);

        let staffEntries = [];
        Object.entries( res.data.data ).map(([key, val]) => {
          staffEntries.push({
            id: val,
            name: val
          });
          
        });
        setConsultationSpecialityList(staffEntries);
      } else if (!res.data.success) {
        setOpenNotification(true);
      }
    })
    .catch((e) => {
      console.log("Error while adding staff", e);
      setOpenNotification(true);
      setErrorMsg("Error while adding staff");
    });
  }, [patientDetails]);

  const triggerSpeciality = (speciality) => {
    //
    axios
    .get(`${getSpecialityDoctor2}?speciality=${speciality}`)
    .then((res) => {
      if (res.data.success) {
        console.log("response", res.data.data);

        let externalStaff = [];
        let internalStaff = [];
        Object.entries( res.data.data ).map(([key, val]) => {
          var itemData = {
            id: val._id,
            name: `Dr. ${val.name[0].given[0]} ${val.name[0].family}`,
            speciality: val.specialty,
          };
          console.log('EDDOctor', itemData)
          if (val.subType.includes('External')){
            externalStaff.push(itemData);
          }else{
            internalStaff.push(itemData);
          }
          
        });
        dispatch({ field: "edDoctorsExternal", value: externalStaff });
        dispatch({ field: "edDoctorsInternal", value: internalStaff });
        // setConsultationSpecialityList(staffEntries);
      } else if (!res.data.success) {
        setOpenNotification(true);
      }
    })
    .catch((e) => {
      console.log("Error while adding staff", e);
      setOpenNotification(true);
      setErrorMsg("Error while adding staff");
    });
  }

  const startRecording = () => {
    if (isBlocked) {
      setOpenNotification(true);
      setErrorMsg("The Record is Blocked by the Browser!");
    } else {
      setOpenNotification(true);
      setsuccessMsg("The Record has been started!");
      Mp3Recorder.start()
        .then(() => {
          dispatch({ field: "isRecording", value: true });
          console.log('handleView::rec::',true)
        })
        .catch((e) => console.error(e));
    }
  };

  const stopRecording = () => {
    Mp3Recorder.stop()
      .getMp3()
      .then(([buffer, blob]) => {

        const blobURLFinal = URL.createObjectURL(blob);
        dispatch({ field: "blobURL", value: blobURLFinal });

        dispatch({ field: "isRecording", value: false });
        var file = new File([blob], "first", { type: "audio/mp3" });

        dispatch({ field: "fileAudio", value: file });
        dispatch({ field: "recordingField", value: '' });
        console.log('handleView::rec::',false)
      })
      .catch((e) => console.log(e));
  };

  const handlePauseMedSearch = (e) => {
    // setLoadSearchedData(true);
    // clearTimeout(timer);

    const a = e.target.value.replace(/[^\w\s]/gi, "");
    setSearchQuery(a);

    setTimer(
      setTimeout(() => {
        handleSearch(a);
        // labServiceSearchCall(
        //   a,
        //   setItemFoundSuccessfully,
        //   setItemFound,
        //   setLoadSearchedData
        // );
      }, 600)
    );
  };

  const handleSearch = (e) => {
    if (e.length >= 1) {
      let url = "";
      if (selectedItemToSearch === "pharmaceutical") {
        url = getSearchedPharmaceuticalItemsUrl;
      } else {
        url = getSearchedItemsNonPharmaceuticalUrl;
      }
      console.log("price data", url);
      axios
        .get(url + "/" + e)
        .then((res) => {
          if (res.data.success) {
            if (res.data.data.items.length > 0) {
              console.log("price data", res.data.data);
              setItemFoundSuccessfully(true);
              setItemFound(res.data.data.items);
              setLoadSearchedData(false);
            } else {
              setItemFoundSuccessfully(false);
              setItemFound("");
              setLoadSearchedData(false);
            }
          }
        })
        .catch((e) => {
          console.log("error while searching medicine", e);
        });
    }
  };

  const handleChange = (event) => {
    // setState({ ...state, [event.target.name]: event.target.checked });

    let nSlug = event.target.name;
    console.log( 'PharmaReq::handleChange::', event.target.checked, event.target.name, event.target.value, `id-`+nSlug, defaultItems );
    if ( `id-`+nSlug in defaultItems ){
      let defaultSelected = defaultItems[ `id-`+nSlug ].items;

      console.log( 'PharmaReq::handleChange::', defaultSelected );

      if (defaultSelected.includes(event.target.value)){ // Already Exists Item and Remove it
        let indexOfItem = defaultSelected.indexOf( event.target.value );
        defaultSelected.splice( indexOfItem, 1 );
      }else{ // Adding Item
        defaultSelected.push( event.target.value );
      }

      let nItems = defaultItems;
      nItems[ `id-`+nSlug ].items = defaultSelected;

      dispatch({
        field: 'defaultItems',
        value: nItems
      });
    }
  };

  function handleAddItem(i) {
    console.log("selected item", i);

    // setSelectedLab(i);
    let readyToAdd = seletedMedItems;

    let itemExists = readyToAdd.filter((element, index, array) => {
        // Return true or false
        return element.itemId === i._id;
    });

    if ( itemExists.length<=0 ){
      readyToAdd.push({
        itemId: i._id,
        itemName: i.name,
        issueUnit: i.issueUnit,
        itemCode: i.itemCode,
        unitPrice: i.issueUnitCost,
        totalPrice: (1*1*1*i.issueUnitCost),
        issueUnit: i.issueUnit,
        schedule: 'Now',
        priority: '',
        dosage: 1,
        duration: 1,
        frequency: 1,
        requestedQty: 1,
        itemType: selectedItemToSearch,
        make_model: '',
        size: '',
        route: '',
        notes: {
          text: '',
          audio: null,
        }
      });
    }

    dispatch({
      field: 'seletedMedItems',
      value: readyToAdd
    });


    setSearchQuery("");
    // setaddLabRequest(true)
  }


  // Medical Form Declarations from Here
  const onChangeItemType = (e) => {
    dispatch({ field: "selectedItemToSearch", value: e.target.name });
    setItemFoundSuccessfully(false);
    setItemFound("");
  };

  const handlePauseMedsSearch = (e) => {
    /*setLoadSearchedData(true);
    clearTimeout(timer);

    const a = e.target.value.replace(/[^\w\s]/gi, "");
    setSearchQuery(a);

    setTimer(
      setTimeout(() => {
        triggerMedChange(a);
      }, 600)
    );*/
  };


  return (
    
    <div
      style={{
        backgroundColor: "rgb(19 213 159)",
        position: "fixed",
        display: "flex",
        width: "100%",
        height: "100%",
        flexDirection: "column",
        flex: 1,
        overflowY: "scroll",
      }}
    >
      <Header history={props.history} />

      <PatientDetailsQuick
        edrId={episodeId} />

      <div className="cPadding">


        <PatientClinicalInformation
          edrId={episodeId}
          wrapClass="mt-3 mb-3" />

        {
          selectUserType === ''?
          <>
            <div
              className="form-group "
              style={{
                ...styles.inputContainerForTextField
              }}>
              

              <TextField
                required
                select
                fullWidth
                id="coveredFamilyMembers"
                name="typeOfRelation"
                label="Speciality"
                variant="filled"
                className="dropDownStyle"
                value={edDoctorSpeciality}
                onChange={(e) => {
                  dispatch({ field: "edDoctorSpeciality", value: e.target.value });
                  triggerSpeciality(e.target.value)
                }}
                InputProps={{
                  className: classes.input,
                  classes: { input: classes.input },
                }}
              >
                {
                  consultationSpecialityList ?
                  Object.entries(consultationSpecialityList).map(([vali, val]) => {
                    return <MenuItem key={val.id} value={val.id}>{val.name}</MenuItem>
                  }):undefined
                }
              </TextField>
            </div>

            <h4 className="h6 text-white"
              style={{
                fontWeight: '700'
              }}>Hospital Doctor(s)</h4>
            <Paper className="mb-3">
              <Table size="small" stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>Doctor Name</TableCell>
                  </TableRow>
                </TableHead>
                {edDoctorsInternal.length > 0 ? (
                  <TableBody>
                    {edDoctorsInternal.map((itemIx, i) => {
                      return (
                        <TableRow
                          key={i}
                          style={{ cursor: "pointer" }}>
                          <TableCell onClick={() => {
                            setSelectUserData(itemIx)
                            setSelectUserType('internal')
                          }}>{itemIx.name}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                ) : (
                  <TableBody>
                    <TableRow key="0">
                      <TableCell align="center" colSpan={5}>No Hospital Doctors found!</TableCell>
                    </TableRow>
                  </TableBody>
                )} 
              </Table>
            </Paper>

            <h4 className="h6 text-white"
              style={{
                fontWeight: '700'
              }}>Affiliated Doctor(s)</h4>
            <Paper className="mb-3">
              <Table size="small" stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>Doctor Name</TableCell>
                  </TableRow>
                </TableHead>
                {edDoctorsExternal.length > 0 ? (
                  <TableBody>
                    {edDoctorsExternal.map((itemIx, i) => {
                      return (
                        <TableRow
                          key={i}
                          style={{ cursor: "pointer" }}>
                          <TableCell onClick={() => {
                            setSelectUserData(itemIx)
                            setSelectUserType('external')
                          }}>{itemIx.name}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                ) : (
                  <TableBody>
                    <TableRow key="0">
                      <TableCell align="center" colSpan={5}>No Affiliated Doctors found!</TableCell>
                    </TableRow>
                  </TableBody>
                )} 
              </Table>
            </Paper>
          </>:
          <>
            <h4 className="h6 text-white"
              style={{
                fontWeight: '700'
              }}>Selected Consultant Doctor</h4>
            <Paper className="mb-3">
              <Table size="small" stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <strong>{selectUserData.name}</strong> ({edDoctorSpeciality})
                      <Icon className="fa fa-trash float-right" color="secondary" onClick={() => {
                        setSelectUserData({})
                        setSelectUserType('')
                      }} />
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>



                        <div className="serviceNotes">
                          <div className="micControl">
                            <div className="d-flex align-items-center">
                              <div
                                style={{
                                  backgroundColor: "#2C6DDD",
                                  height: 42,
                                  borderRadius: 42,
                                  width: 42,
                                  paddingLeft: 10,
                                  cursor: 'pointer'
                                }}
                              >
                                {isRecording ? (
                                  <StopIcon
                                    style={{ marginTop: 10, color: "white" }}
                                    fontSize="16px"
                                    onClick={stopRecording}
                                    disabled={!isRecording}
                                    color="secondary"
                                  />
                                ) : (
                                  <MicIcon
                                    style={{ marginTop: 10, color: "white" }}
                                    fontSize="16px"
                                    onClick={() => {
                                      startRecording()
                                    }}
                                    disabled={isRecording}
                                    color="primary"
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="notesDiv">
                            <TextField
                              // required
                              multiline="true"
                              rows="3"
                              // disabled={enableForm}
                              label="Any Comments / Notes? (Optional)"
                              name={"comments"}
                              value={comments}
                              // error={comments === ""}
                              onChange={(e) => {onChangeNotesValue(e.target.value)}}
                              className="textInputStyle"
                              variant="filled"
                              InputProps={{
                                disableUnderline: true,
                              }}
                            />
                            {isRecording ? (
                              <div
                                style={{
                                  marginLeft: matches ? 110 : 18,
                                  marginRight: matches ? 110 : 75,
                                  width: matches ? 550 : 100,
                                }}
                              >
                                <Loader
                                  type="ThreeDots"
                                  color="blue"
                                  height={40}
                                  width={40}
                                />
                              </div>
                            ) : (
                              <audio
                                style={{
                                  marginRight: 10,
                                  width: matches ? 450 : 260,
                                  marginTop: 6,
                                }}
                                src={blobURL}
                                controls="controls"
                              />
                            )}
                          </div>
                        </div>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Paper>
            <Button
              // className="addButton"
              style={{
                ...styles.stylesForButton,
                backgroundColor: "#AD6BBF",
                color: "white",
                cursor: "pointer",
                borderRadius: 5,
                backgroundColor: "rgb(173, 107, 191)",
                height: matches ? 56 : 45,
                outline: "none",
                width: "100%",
              }}
              // disabled={!addLabRequest}
              onClick={addSelectedLabItem}
              variant="contained"
              color="primary"
              fullWidth
            >Submit</Button>
          </>
        }









        <div
          style={{
            zIndex: 10,
            marginTop: 10,
          }}
        >
          
          {seletedMedItems.length > 0 ? (
            <div className="row row-5">
              <div className="col-12 col-md-4 offset-md-4">
                <Button
                  // className="addButton"
                  style={{
                    ...styles.stylesForButton,
                    backgroundColor: "#AD6BBF",
                    color: "white",
                    cursor: "pointer",
                    borderRadius: 5,
                    backgroundColor: "rgb(173, 107, 191)",
                    height: matches ? 56 : 45,
                    outline: "none",
                    width: "100%",
                  }}
                  // disabled={!addLabRequest}
                  onClick={addSelectedLabItem}
                  variant="contained"
                  color="primary"
                  fullWidth
                >Submit</Button>
              </div>
            </div>
          ) : (
            undefined
          )} 
          <hr />
        </div>


        <div
          className="container-fluid d-none"
        >
          <div
            className="row"
            style={{
              backgroundColor: "white",
              height: matches ? 55 : 155,
              display: "flex",
              alignItems: "center",
              borderRadius: 5,
              paddingTop: 8,
            }}
          >
            <h6 className="col-md-4" style={{ verticalAlign: "center" }}>
              Item Type
            </h6>

            <FormControl className="col-md-8" component="fieldset">
              <RadioGroup
                row
                aria-label="position"
                name="position"
                // defaultValue="top"
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <FormControlLabel
                  // value={selectedItemToSearch}
                  name={"pharmaceutical"}
                  control={<Radio color="primary" />}
                  label="Pharmaceutical"
                  onChange={onChangeItemType}
                  checked={
                    selectedItemToSearch === "pharmaceutical"
                      ? true
                      : false
                  }
                />

                <FormControlLabel
                  // value={selectedItemToSearch}
                  name={"non_pharmaceutical"}
                  control={<Radio color="primary" />}
                  label="Non-Pharmaceutical"
                  onChange={onChangeItemType}
                  checked={
                    selectedItemToSearch === "non_pharmaceutical"
                      ? true
                      : false
                  }
                />
              </RadioGroup>
            </FormControl>
          </div>
        </div>

        <div style={{ display: "flex", justifyContent: "space-between", marginTop: 20 }}>
          <div style={{ marginTop: "2%", marginBottom: "2%" }}>
            <img
              onClick={() => {
                // Cancel
                props.history.push({
                  pathname: "/dashboard/home/"+episodeId+"/pharmacy",
                });
              }}
              src={Back}
              style={{ width: 45, height: 35, cursor: "pointer" }}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "2%",
              marginBottom: "2%",
            }}
          >
            {/*
            <Button
              style={{
                ...styles.stylesForSubmitButton,
              }}
              variant="contained"
              color="primary"
            >Submit</Button>
            */}
          </div>
        </div>
      </div>
      <Notification
        msg={errorMsg}
        open={openNotification}
        success={successMsg}
      />
    </div>
  );
}