import React, { useEffect, useState, useReducer } from "react"
import axios from "axios"
import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import { connect } from "react-redux"
import cookie from "react-cookies"
import MenuItem from '@material-ui/core/MenuItem'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Header from "../../../components/Header/Header"
import Back from "../../../assets/img/Back_Arrow.png"
import format from "../../../utils/formatDate"
import "../../../assets/jss/material-dashboard-react/components/TextInputStyle.css"
import CustomTable from "../../../components/Table/Table"
import Notification from "../../../components/Snackbar/Notification.js"
import PharmIcon from "../../../assets/img/Pharma.png"
import {
  funForReducer,
  setPatientDetailsForReducer,
} from "../../../actions/Checking"

const tableHeadingForPharmacy = [
  "Medical Name",
  "Quantity",
  "Unit Price",
  "Total Price",
  "Action",
]
const tableDataKeysForPharmacy = [
  "itemName",
  "requestedQty",
  "unitPrice",
  "totalPrice",
]
const actions = { view: true }

const durationOptions = [
  {
    key: '01',
    value: '01',
  },
  {
    key: '02',
    value: '02',
  },
  {
    key: '03',
    value: '03',
  },
]

const styles = {
  stylesForButton: {
    color: 'white',
    cursor: 'pointer',
    borderRadius: 5,
    background: '#2c6ddd',
    width: '140px',
    height: '50px',
    outline: 'none',
  },
  textFieldPadding: {
    paddingTop: 10,
    paddingBottom: 10
  }
}

const useStylesForInput = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(0),
  },
  input: {
    boxShadow: 'none',
    borderRadius: 5,
    '&:after': {
      borderBottomColor: 'black',
      boxShadow: 'none',
    },
    '&:hover': {
      boxShadow: 'none',
    },
    '&:focus': {
      boxShadow: 'none',
      borderRadius: 5,
    },
  },
  root: {
    '& .Mui-focused': {
      color: 'black',
      boxShadow: 'none',
    },
    '& .Mui-disabled': {
      color: 'black',
    },
    '&:focus': {
      boxShadow: 'none',
    },
    '& .MuiFormLabel-root': {
      fontSize: '12px',
      paddingRight: '15px',
    },
  },
}))

function DetailedPharma(props) {
  const classes = useStylesForInput()

  const [currentUser, setcurrentUser] = useState(cookie.load("current_user"))
  const [errorMsg, setErrorMsg] = useState("")
  const [successMsg, setsuccessMsg] = useState("")
  const [openNotification, setOpenNotification] = useState(false)
  const [itemModalVisible, setitemModalVisible] = useState(false)
  const [item, setItem] = useState(false)
  const [pharmacyRequestArray, setPharmacyRequestArray] = useState([])

  useEffect(() => {
    // if (props.patientDetails) {
    //   setPatientDetails(props.patientDetails)
    //   getPatientByInfo(props.patientDetails._id)
    //   openPatientDetailsDialog(true)
    // }
    var itemChosen = props.history.location.state.selectedItem
    console.log("prop State : ", itemChosen.item)
    itemChosen.item.map((d) => (
      d.unitPrice = d.itemId.issueUnitCost,
      d.totalPrice = d.requestedQty * d.itemId.issueUnitCost
    ))
    setPharmacyRequestArray(itemChosen.item)
  }, [])

  const handleView = (obj) => {
    console.log("item selected: ", obj)
    if (obj !== '') {
      setitemModalVisible(true)
      setItem(obj)
    } else {
      setitemModalVisible(false)
      setItem('')
    }
  }

  if (openNotification) {
    setTimeout(() => {
      setOpenNotification(false)
      setErrorMsg("")
      setsuccessMsg("")
    }, 2000)
  }

  return (
    <div
      style={{
        backgroundColor: "rgb(19 213 159)",
        position: "fixed",
        display: "flex",
        width: "100%",
        height: "100%",
        flexDirection: "column",
        flex: 1,
        overflowY: "scroll",
      }}
    >
      <Header history={props.history} />
      <div className="cPadding">
        <div className="subheader">
          <div>
            <img src={PharmIcon} />
            <h4>Pharmacy Service Requests</h4>
          </div>
        </div>

        <div
          style={{
            height: "20px",
          }}
        />

        <div className="container-fluid">

          <div className="row">
            {pharmacyRequestArray !== 0 ? (
              <div className='col-md-12 col-sm-12 col-12'>
                <CustomTable
                  tableData={pharmacyRequestArray}
                  tableDataKeys={tableDataKeysForPharmacy}
                  tableHeading={tableHeadingForPharmacy}
                  handleView={handleView}
                  action={actions}
                  borderBottomColor={"#60d69f"}
                  borderBottomWidth={20}
                />
              </div>
            ) : (
                undefined
              )}
          </div>

          <div className="row" style={{ marginBottom: "25px", marginTop: "25px" }}>
            <div className='col-md-12 col-sm-12 col-12'>
              <img
                onClick={() => props.history.goBack()}
                src={Back}
                style={{ width: 45, height: 35, cursor: "pointer" }}
              />
            </div>
          </div>

        </div>

        <Dialog
          onClose={() => handleView('')}
          fullWidth={true}
          maxWidth={'lg'}
          
          
          aria-labelledby='simple-dialog-title'
          open={itemModalVisible}
        >
          <DialogContent>
            <div className={`container-fluid ${classes.root}`}>
              <div className='row'>
                <div
                  className='col-md-12 col-sm-12 col-12'
                  style={{ ...styles.textFieldPadding, marginTop: '20px' }}
                >
                  <TextField
                    disabled
                    label="Medicine Name"
                    name={"medicineName"}
                    value={item.itemName}
                    className="textInputStyle"
                    variant="filled"
                    InputProps={{
                      className: classes.input,
                      classes: { input: classes.input },
                      disableUnderline: true,
                    }}
                  />
                </div>
              </div>

              <div className='row'>
                <div
                  className='col-md-6 col-sm-6 col-6'
                  style={styles.textFieldPadding}
                >
                  <TextField
                    disabled
                    select
                    fullWidth
                    label='Duration'
                    name='duration'
                    value={item.duration}
                    variant='filled'
                    className='textInputStyle'
                    InputProps={{
                      className: classes.input,
                      classes: { input: classes.input },
                      disableUnderline: true,
                    }}
                  >
                    <MenuItem key={item.duration} value={item.duration}>
                      {item.duration}
                    </MenuItem>
                  </TextField>
                </div>
                <div className='col-md-6 col-sm-6 col-6'
                  style={styles.textFieldPadding}
                >
                  <TextField
                    disabled
                    label="Dosage"
                    name={"dosage"}
                    value={item.dosage}
                    className="textInputStyle"
                    variant="filled"
                    InputProps={{
                      className: classes.input,
                      classes: { input: classes.input },
                      disableUnderline: true,
                    }}
                  />
                </div>
              </div>

              <div className='row'>
                <div
                  className='col-md-6 col-sm-6 col-6'
                  style={styles.textFieldPadding}
                >
                  <TextField
                    disabled
                    label="Quantity"
                    name={"quantity"}
                    value={item.requestedQty}
                    className="textInputStyle"
                    variant="filled"
                    InputProps={{
                      className: classes.input,
                      classes: { input: classes.input },
                      disableUnderline: true,
                    }}
                  />
                </div>
                <div
                  className='col-md-6 col-sm-6 col-6'
                  style={styles.textFieldPadding}
                >
                  <TextField
                    disabled
                    label="Time"
                    name={"time"}
                    value={item.itemId ? format(item.itemId.updatedAt) : ''}
                    className="textInputStyle"
                    variant="filled"
                    InputProps={{
                      className: classes.input,
                      classes: { input: classes.input },
                      disableUnderline: true,
                    }}
                  />
                </div>
              </div>

              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div style={{ marginTop: '2%', marginBottom: '2%' }}>
                  <Button
                    style={styles.stylesForButton}
                    onClick={() => handleView('')}
                    variant='contained'
                  >
                    Close
                  </Button>
                </div>
              </div>
            </div>
          </DialogContent>
        </Dialog>

        <Notification
          msg={errorMsg}
          open={openNotification}
          success={successMsg}
        />
      </div>
    </div>
  )
}

const mapStateToProps = ({ CheckingReducer }) => {
  const { count, patientDetails } = CheckingReducer
  return { count, patientDetails }
}
export default connect(mapStateToProps, {
  funForReducer,
  setPatientDetailsForReducer,
})(DetailedPharma)