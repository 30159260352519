import React, { useState, useEffect } from "react";
import cookie from "react-cookies";
import Notification from "../../../components/Snackbar/Notification.js";
import CustomTable from "../../../components/Table/Table";
import Back_Arrow from "../../../assets/img/Back_Arrow.png";
import Header from "../../../components/Header/Header";
import _ from "lodash";
import LabIcon from "../../../assets/img/RL.png";
import RadIcon from "../../../assets/img/Rad.png";
import PharmIcon from "../../../assets/img/Pharma.png";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Loader from "react-loader-spinner";
import {
  getLabRequests,
  getRadiologyRequests,
  getPharmacyRequests,
} from "./networkCalls";

const tableHeadingForPharma = ["MRN", "Patient Name", "Date/Time", "Action"];
const tableDataKeysForPharma = ["mrn", "patientName", "date"];

const tableHeadingForRad = ["MRN", "Patient Name", "Date/Time", "Action"];
const tableDataKeysForRad = ["mrn", "patientName", "date"];

const tableHeadingForLab = ["MRN", "Patient Name", "Date/Time", "Action"];
const tableDataKeysForLab = ["mrn", "patientName", "date"];

const actions = { view: true };

export default function ServicesRequests(props) {
  const matches = useMediaQuery("(min-width:600px)");

  const [currentUser] = useState(cookie.load("current_user"));
  const [errorMsg, setErrorMsg] = useState("");
  const [openNotification, setOpenNotification] = useState(false);
  const [pathName, setPathName] = useState("");
  const [labRequests, setLabRequests] = useState([]);
  const [radRequests, setRadRequests] = useState([]);
  const [PharmaData, setPharmaData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let path = props.history.location.pathname.split("/");
    setPathName(path[path.length - 1]);

    if (path[path.length - 1] === "lab") {
      getLabRequests(
        setLoading,
        setLabRequests,
        setOpenNotification,
        setErrorMsg
      );
    } else if (path[path.length - 1] === "rad") {
      getRadiologyRequests(
        setLoading,
        setRadRequests,
        setOpenNotification,
        setErrorMsg
      );
    } else if (path[path.length - 1] === "pharma") {
      getPharmacyRequests(
        setLoading,
        setPharmaData,
        setOpenNotification,
        setErrorMsg
      );
    }
  }, []);

  if (openNotification) {
    setTimeout(() => {
      setOpenNotification(false);
      setErrorMsg("");
    }, 2000);
  }

  function handleView(rec) {
    let path;
    if (pathName === "pharma") {
      path = "pharma/viewpharma";
    } else if (pathName === "lab") {
      path = "lab/viewlab";
    } else {
      path = "rad/viewrad";
    }
    props.history.push({
      pathname: path,
      state: {
        path: pathName,
        selectedItem: rec,
      },
    });
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        position: "fixed",
        width: "100%",
        height: "100%",
        backgroundColor: "rgb(19 213 159)",
        overflowY: "scroll",
        paddingBottom: 30,
      }}
    >
      <Header history={props.history} />

      <div className="cPadding">
        <div className="subheader">
          {pathName === "rad" ? (
            currentUser.staffType === "Doctor" ? (
              currentUser.subType.includes("ED Doctor") ? (
                <div>
                  <img src={RadIcon} />
                  <h4>Radiology Reports</h4>
                </div>
              ) : (
                undefined
              )
            ) : (
              <div>
                <img src={RadIcon} />
                <h4>Radiology Service Requests</h4>
              </div>
            )
          ) : pathName === "lab" ? (
            currentUser.staffType === "Doctor" ? (
              currentUser.subType.includes("ED Doctor") ? (
                <div>
                  <img src={LabIcon} />
                  <h4>Lab Reports</h4>
                </div>
              ) : (
                undefined
              )
            ) : (
              <div>
                <img src={LabIcon} />
                <h4>Lab Service Requests</h4>
              </div>
            )
          ) : pathName === "pharma" ? (
            <div>
              <img src={PharmIcon} />
              <h4>Pharmacy Service Requests</h4>
            </div>
          ) : (
            undefined
          )}
        </div>

        <div className="container-fluid">
          {pathName === "pharma" ? (
            <div className="row">
              {PharmaData.length > 0 && !loading ? (
                <div className="col-md-12 col-sm-12 col-12">
                  <CustomTable
                    tableData={PharmaData}
                    tableDataKeys={tableDataKeysForPharma}
                    tableHeading={tableHeadingForPharma}
                    action={actions}
                    borderBottomColor={"#60d69f"}
                    handleView={handleView}
                    borderBottomWidth={20}
                  />
                </div>
              ) : loading ? (
                <div style={{ margin: "auto", width: "0%", padding: 10 }}>
                  <Loader type="TailSpin" color="blue" height={50} width={50} />
                </div>
              ) : (
                <div className="col-md-12 col-sm-12 col-12">
                  <h3
                    style={{
                      color: "white",
                      textAlign: "center",
                      margin: "revert",
                    }}
                  >
                    Opps! No Data Found
                  </h3>
                </div>
              )}
            </div>
          ) : (
            undefined
          )}

          {pathName === "rad" ? (
            <div className="row">
              {radRequests.length > 0 && !loading ? (
                <div className="col-md-12 col-sm-12 col-12">
                  <CustomTable
                    tableData={radRequests}
                    tableDataKeys={tableDataKeysForRad}
                    tableHeading={tableHeadingForRad}
                    action={actions}
                    handleView={handleView}
                    borderBottomColor={"#60d69f"}
                    borderBottomWidth={20}
                  />
                </div>
              ) : loading ? (
                <div style={{ margin: "auto", width: "0%", padding: 10 }}>
                  <Loader type="TailSpin" color="blue" height={50} width={50} />
                </div>
              ) : (
                <div className="col-md-12 col-sm-12 col-12">
                  <h3
                    style={{
                      color: "white",
                      textAlign: "center",
                      margin: "revert",
                    }}
                  >
                    Opps! No Data Found
                  </h3>
                </div>
              )}
            </div>
          ) : (
            undefined
          )}

          {pathName === "lab" ? (
            <div className="row">
              {labRequests.length > 0 && !loading ? (
                <div className="col-md-12 col-sm-12 col-12">
                  <CustomTable
                    tableData={labRequests}
                    tableDataKeys={tableDataKeysForLab}
                    tableHeading={tableHeadingForLab}
                    action={actions}
                    borderBottomColor={"#60d69f"}
                    handleView={handleView}
                    borderBottomWidth={20}
                  />
                </div>
              ) : loading ? (
                <div style={{ margin: "auto", width: "0%", padding: 10 }}>
                  <Loader type="TailSpin" color="blue" height={50} width={50} />
                </div>
              ) : (
                <div className="col-md-12 col-sm-12 col-12">
                  <h3
                    style={{
                      color: "white",
                      textAlign: "center",
                      margin: "revert",
                    }}
                  >
                    Opps! No Data Found
                  </h3>
                </div>
              )}
            </div>
          ) : (
            undefined
          )}

          <div className="row">
            <div className="col-md-12 col-sm-12 col-12">
              <img
                onClick={() => props.history.goBack()}
                src={Back_Arrow}
                style={{
                  width: 45,
                  height: "auto",
                  cursor: "pointer",
                }}
              />
            </div>
          </div>
        </div>

        <Notification msg={errorMsg} open={openNotification} />
      </div>
    </div>
  );
}
