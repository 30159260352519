import axios from 'axios'
import {
  getAdmittedEDRs,
  getDischargedEDRs,
  getDeceasedEDRs,
  getTransferedEDRs,
  getPsychiatrist,
  getMentalCare,
  getAdvocate,
  getCompletedAdmittedEDRs,
  getCompletedDeceasedEDRs,
  getCompletedDischargedEDRs,
  getCompletedTransferedEDRs,
} from '../../public/endpoins'
import FormatDate from '../../utils/formatDate'

export const getAdmittedCall = (
  buttonValue,
  setAdmittedArray,
  setErrorMsg,
  setOpenNotification
) => {
  let apiCall
  if (buttonValue === 'pending') {
    apiCall = getAdmittedEDRs
  } else if (buttonValue === 'completed') {
    apiCall = getCompletedAdmittedEDRs
  }
  axios
    .get(apiCall)
    .then((response) => {
      console.log('admitted', response.data)
      response.data.data.map(
        (val) => (
          (val.productionArea =
            val.chiefComplaint[
              val.chiefComplaint.length - 1
            ].chiefComplaintId.productionArea[0].productionAreaId.paName),
          (val.roomNo = val.room[val.room.length - 1].roomId.roomNo),
          (val.mrn = val.patientId.identifier[0].value)
        )
      )
      setAdmittedArray(response.data.data)
    })
    .catch((error) => {
      console.log('Error', error)
      setOpenNotification(true)
      setErrorMsg(error)
    })
}

export const getDischargedCall = (
  buttonValue,
  setDischargedArray,
  setErrorMsg,
  setOpenNotification
) => {
  let apiCall
  if (buttonValue === 'pending') {
    apiCall = getDischargedEDRs
  } else if (buttonValue === 'completed') {
    apiCall = getCompletedDischargedEDRs
  }
  axios
    .get(apiCall)
    .then((response) => {
      console.log('Response Pending', response.data.data)
      response.data.data.map(
        (val) => (
          // (val.productionArea =
          //   val.chiefComplaint[
          //     val.chiefComplaint.length - 1
          //   ].chiefComplaintId.productionArea[0].productionAreaId.paName),
          (val.roomNo = val.room[val.room.length - 1].roomId.roomNo),
          (val.mrn = val.patientId.identifier[0].value)
        )
      )
      console.log('Response Pending', response.data.data)
      setDischargedArray(response.data.data)
    })
    .catch((error) => {
      console.log('Error', error)
      setOpenNotification(true)
      setErrorMsg(error.response)
    })
}

export const getTransferredCall = (
  buttonValue,
  setTransferredArray,
  setErrorMsg,
  setOpenNotification
) => {
  let apiCall
  if (buttonValue === 'pending') {
    apiCall = getTransferedEDRs
  } else if (buttonValue === 'completed') {
    apiCall = getCompletedTransferedEDRs
  }
  axios
    .get(apiCall)
    .then((response) => {
      console.log('Responsee', response.data)
      response.data.data.map(
        (val) => (
          (val.productionArea =
            val.chiefComplaint[
              val.chiefComplaint.length - 1
            ].chiefComplaintId.productionArea[0].productionAreaId.paName),
          (val.roomNo = val.room[val.room.length - 1].roomId.roomNo),
          (val.mrn = val.patientId.identifier[0].value)
        )
      )
      setTransferredArray(response.data.data)
    })
    .catch((error) => {
      console.log('Error', error)
      setOpenNotification(true)
      setErrorMsg(error)
    })
}

export const getDeceasedCall = (
  buttonValue,
  setDeceasedArray,
  setErrorMsg,
  setOpenNotification
) => {
  let apiCall
  if (buttonValue === 'pending') {
    apiCall = getDeceasedEDRs
  } else if (buttonValue === 'completed') {
    apiCall = getCompletedDeceasedEDRs
  }
  axios
    .get(apiCall)
    .then((response) => {
      console.log('Response Pending', response.data)
      response.data.data.map(
        (val) => (
          (val.productionArea =
            val.chiefComplaint[
              val.chiefComplaint.length - 1
            ].chiefComplaintId.productionArea[0].productionAreaId.paName),
          (val.roomNo = val.room[val.room.length - 1].roomId.roomNo),
          (val.mrn = val.patientId.identifier[0].value)
        )
      )
      setDeceasedArray(response.data.data)
    })
    .catch((error) => {
      console.log('Error', error)
      setOpenNotification(true)
      setErrorMsg(error)
    })
}

export const getPsychiatristCall = (
  setPsychiatristArray,
  setErrorMsg,
  setOpenNotification
) => {
  axios
    .get(getPsychiatrist)
    .then((response) => {
      console.log('Responseee', response.data)
      response.data.data.map(
        (val) => (
          (val.name = val.name[0].given[0] + ' ' + val.name[0].family),
          val.telecom.map((a) => {
            if (a.system === 'mobile') {
              return (val.phone = a.value)
            }
          }),
          val.telecom.map((a) => {
            if (a.system === 'email') {
              return (val.email = a.value)
            }
          })
        )
      )
      setPsychiatristArray(response.data.data)
    })
    .catch((error) => {
      console.log('Error', error)
      setOpenNotification(true)
      setErrorMsg(error)
    })
}

export const getMentalCareCall = (
  setMentalCareArray,
  setErrorMsg,
  setOpenNotification
) => {
  axios
    .get(getMentalCare)
    .then((response) => {
      console.log('Responseee', response.data)
      response.data.data.map(
        (val) => (
          (val.name = val.name[0].given[0] + ' ' + val.name[0].family),
          val.telecom.map((a) => {
            if (a.system === 'mobile') {
              return (val.phone = a.value)
            }
          }),
          val.telecom.map((a) => {
            if (a.system === 'email') {
              return (val.email = a.value)
            }
          })
        )
      )
      setMentalCareArray(response.data.data)
    })
    .catch((error) => {
      console.log('Error', error)
      setOpenNotification(true)
      setErrorMsg(error)
    })
}

export const getAdvocateCall = (
  setAdvocateArray,
  setErrorMsg,
  setOpenNotification
) => {
  axios
    .get(getAdvocate)
    .then((response) => {
      console.log('Responseee', response.data)
      response.data.data.map(
        (val) => (
          (val.name = val.name[0].given[0] + ' ' + val.name[0].family),
          val.telecom.map((a) => {
            if (a.system === 'mobile') {
              return (val.phone = a.value)
            }
          }),
          val.telecom.map((a) => {
            if (a.system === 'email') {
              return (val.email = a.value)
            }
          })
        )
      )
      setAdvocateArray(response.data.data)
    })
    .catch((error) => {
      console.log('Error', error)
      setOpenNotification(true)
      setErrorMsg(error)
    })
}
