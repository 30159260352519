
export const tableHeadingForEOUTransferReq = [
    "MRN",
    "Patient Name",
    "Production Area",
    "Bed Cell No",
    " "
];
export const tableDataKeysForEOUTransferReq = [
    "mrn",
    "patientName",
    "productionArea",
    "bedCell"
];

export const tableHeadingForCS = [
    "MRN",
    "Patient Name",
    "Production Area",
    "Bed Cell No",
    "Status",
    " "
];
export const tableDataKeysForCS = [
    "mrn",
    "patientName",
    "productionArea",
    "bedCell",
    "status"
];

export const tableHeadingForAvailableBeds = [
    "Room ID",
    "Bed Cell No",
    " "
];
export const tableDataKeysForAvailableBeds = [
    "roomId",
    "bedCell"
];

export const tableHeadingForOccupiedBeds = [
    "Room ID",
    "Production Area",
    "MRN",
    "Patient Name",
    "Bed Cell No",
    " "
];
export const tableDataKeysForOccupiedBeds = [
    "roomId",
    "productionArea",
    "mrn",
    "patientName",
    "bedCell"
];

export const tableHeadingForFlagsRaised = [
    "MRN",
    "Patient Name",
    "Room ID",
    "Production Area",
    "Activity",
    "Reason",
    " "
];
export const tableDataKeysForFlagsRaised = [
    "mrn",
    "patientName",
    "roomId",
    "productionArea",
    "activity",
    "reason"
];

export const tableHeadingForDoctorResponse = [
    "MRN",
    "Patient Name",
    "Production Area",
    "Doctor Name",
    "Consultant Type",
    "Response Time",
    " "
];
export const tableDataKeysForDoctorResponse = [
    "mrn",
    "patientName",
    "productionArea",
    "doctorName",
    "consultantType",
    "responseTime"
];

export const tableHeadingForEDnurse = [
    "MRN",
    "Patient Name",
    "Production Area",
    "Nurse Name",
    " "
];
export const tableDataKeysForEDnurse = [
    "mrn",
    "patientName",
    "productionArea",
    "nurseName"
];