import React, { useEffect, useState } from "react";
import cookie from "react-cookies";
import axios from "axios";
import Button from "@material-ui/core/Button";
import Back from "../../../assets/img/Back_Arrow_black.png";
import Header from "../../../components/Header/HeaderGrey";
// import Notification from "../../../components/Snackbar/Notification.js";
import CustomTable from "../../../components/Table/Table";
import PatientDetails from "../../../components/PatientDetails/PatientDetailsDirect";
import Loader from "react-loader-spinner";
import formatDate from "../../../utils/formatDate";
import PatientDetailsQuick from "../../../components/PatientDetails/PatientDetailsQuick";

// Icons
import PatientDetailIcon from "../../../assets/img/Patient Details Icon.png";

const actions = { view: true };
const tableHeadingForTriage = [
  //"Request No",
  "Time",
  "Triage Level",
  "Doctor/Staff",
  // "Chief Complaint",
  // "Room No",
  // "Status",
  "Action",
];
const tableDataKeysForTriage = [
  //"triageRequestNo",
  "formatTriageTime",
  "formatTriageLevel",
  "staff",
  // "chiefComplaint",
  // "roomNo",
  // "status",
];

function TriageHistory(props) {
	// const matches = useMediaQuery("(min-width:600px)");
	const [currentUser] = useState(cookie.load("current_user"));
	const [propsDatas, setPropsDatas] = useState([]);
	const [formData, setformData] = useState([]);
	const [dcdForm, setDcdData] = useState([]);
	const [triageAssessment, setTriageAssessment] = useState([]);
	const [formName, setFormName] = useState("");
	const [selectedItem, setSelectedItem] = useState("");
	const [priorECG, setPriorECG] = useState([]);
	const [priorXray, setpriorXray] = useState([]);
	useEffect(() => {
		const propsData = props.history.location.state;
		setPropsDatas(props);

		console.log('triageHistory', propsData.selectedItem);
		setFormName(propsData.comingFor);
		setSelectedItem(propsData.selectedItem);
		setDcdData(propsData.dcdForm);
		const FormData = propsData.dcdForm[0];
		console.log(currentUser, "current user");
		console.log("propsData::", propsData);
		console.log("FormData::", FormData.triageAssessment);

		console.log('======================================');

		let triageAssessmentData = FormData.triageAssessment;

		triageAssessmentData.sort((a, b) => {
			console.log(a.triageTime, b.triageTime);
			return new Date(b.triageTime) - new Date(a.triageTime);
		});

		console.log("triageAssessmentData", triageAssessmentData);

		Object.entries(triageAssessmentData).map(([key, val]) => {
			console.log(key);
			console.log(val);
			triageAssessmentData[key].formatTriageTime = formatDate(val.triageTime)
			triageAssessmentData[key].formatTriageLevel = val.triageLevel.toString()
			triageAssessmentData[key].staff = val.requester.name[0].given[0] + " " + val.requester.name[0].family + " / " + val.requester.subType.toString();
    });
		console.log('======================================');

		setTriageAssessment(triageAssessmentData);
	}, []);



	function handleViewSingleTriage(triageObj) {
		console.log(triageObj);

        let path;
        path = "/dashboard/home/dcddashboard/triage-history/view";
        let dataAlreadyFilled = true;

        console.log(propsDatas);

        console.log(path, {
			comingFor: "Triage & Assessment",
			selectedItem: selectedItem,
			dcdForm: dcdForm,
			careStream: "",
			triageData: triageObj,
			dataAlreadyFilled,
		})
		propsDatas.history.push({
			pathname: path,
			state: {
			  comingFor: "Triage & Assessment",
			  selectedItem: selectedItem,
			  dcdForm: dcdForm,
			  careStream: "",
			  triageData: triageObj,
			  dataAlreadyFilled,
			},
		});
	}

	const handleAddNewTriage = () => {
		props.history.push({
			pathname: "/dashboard/home/dcddashboard/triageForm",
			state: {
				comingFor: "Triage & Assessment",
				selectedItem: selectedItem,
			},
		});
	};


	return (
		<div
			style={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        position: "fixed",
        width: "100%",
        height: "100%",
        backgroundColor: "rgb(19 213 159)",
        overflow: "auto",
        paddingBottom: "60px",
      }}
		>
			<Header history={props.history} />

      <PatientDetailsQuick
        edrId={selectedItem._id} />

			<div className="cPadding">
				<div
		          className="subheader"
		          style={{ marginLeft: "-15px", marginBottom: 15 }}
		        >
					<div className="col-md-6 col-8">
						<img
							onClick={() => props.history.goBack()}
							src={Back}
							style={{ width: 45, height: 40, cursor: "pointer" }}
							alt="Alternate"
							/>
						<img src={PatientDetailIcon} alt="PatientDetailIcon" />
						<h4
							style={{
								color: "#000000",
								fontSize: 34,
								fontWeight: "bolder",
							}}
							>{formName}</h4>
					</div>
					<div className="col-md-6 col-sm-6 col-6 d-flex justify-content-end">
							<Button
								variant="contained"
								color="default"
								onClick={handleAddNewTriage}
							>Add New</Button>
						</div>
				</div>
				{/*
				<PatientDetails
            edrId={selectedItem._id}
            showChiefComplaint={true}
            showTriageLevel={true}
            showCareStream={true}
            showDiagnosis={true} />
        */}
				<div className="container-fluid">
					<div className="row">
						<CustomTable
							tableData={triageAssessment}
							tableDataKeys={tableDataKeysForTriage}
							tableHeading={tableHeadingForTriage}
							action={actions}
							borderBottomColor={"#60d69f"}
							borderBottomWidth={20}
							handleView={handleViewSingleTriage}
							/>
					</div>
				</div>
			</div>
		</div>
	);
}
export default TriageHistory;