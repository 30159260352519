import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Header from "../../../../components/Header/HeaderGrey";
import DownloadIcon from "../../../../assets/img/Download Icon.png";
import PrintIcon from "../../../../assets/img/Print Icon.png";
import SummaryQR from "../../../../assets/img/Summary_QR.png";
import Button from "@material-ui/core/Button";
import DetailBlock from "../../../../components/DHR/DCD/ViewScreen/detailBlock";
import Back from "../../../../assets/img/Back_Arrow_black.png";
import { uploadsUrl } from "../../../../public/endpoins";
import _ from "lodash";
import "../../../../views/Registration/registrationOfficer.css";
import cookie from "react-cookies";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Avatar from "@material-ui/core/Avatar";

import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

import html2PDF from "jspdf-html2canvas";
import moment from 'moment';

const styles = {
  save: {
    cursor: "pointer",
    borderRadius: 5,
    backgroundColor: "#2973CF",
    // width: "180px",
    // height: "50px",
    outline: "none",
    color: "white",
  },
  Edit: {
    cursor: "pointer",
    borderRadius: 5,
    width: "180px",
    height: "50px",
    outline: "none",
    border: "2px solid gray",
    backgroundColor: "#FEFEFE",
  },
  Cheif: {
    cursor: "pointer",
    borderRadius: 5,
    backgroundColor: "#2973CF",
    width: "220px",
    height: "50px",
    outline: "none",
    color: "white",
  },
};

const useStylesForInput = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(0),
  },
  input: {
    backgroundColor: "white",
    boxShadow: "none",
    borderRadius: 5,
    "&:after": {
      borderBottomColor: "black",
      boxShadow: "none",
    },
    "&:hover": {
      backgroundColor: "white",
      boxShadow: "none",
    },
    "&:focus": {
      backgroundColor: "white",
      boxShadow: "none",
      borderRadius: 5,
    },
  },
  root: {
    "& .MuiTextField-root": {
      backgroundColor: "white",
    },
    "& .Mui-focused": {
      backgroundColor: "white",
      color: "black",
      boxShadow: "none",
    },
    "& .Mui-disabled": {
      backgroundColor: "white",
      color: "gray",
    },
    "&:focus": {
      backgroundColor: "white",
      boxShadow: "none",
    },
    "& .MuiFormLabel-root": {
      fontSize: "12px",
      paddingRight: "15px",
    },
  },
  label: {
    "&$focusedLabel": {
      color: "red",
      display: "none",
    },
  },
}));

export default function ViewPhysicalExam(props) {
  const matches = useMediaQuery("(min-width:600px)");
  const classes = useStylesForInput();

  const [selectedPatient, setSelectedPatient] = useState([]);
  const [currentUser, setCurrentUser] = useState(cookie.load("current_user"));
  const [print, setPrint] = useState(false);

  console.log('ViewPatientDetails', 'YES');

  function setRequestsData() {
    let selectedItem = props.history.location.state.selectedItem;

    console.log("selectedItem", selectedItem);
    let lab = [];

    if (selectedItem.labRequest && selectedItem.labRequest.length > 0) {
      for (let i = 0; i < selectedItem.labRequest.length; i++) {
        const reqDate = {
          subheading: "Date/Time",
          description: moment( selectedItem.labRequest[i].requestedAt ).format('MMM DD, YYYY LT'),
        };
        const id = {
          subheading: "Test Id",
          description: selectedItem.labRequest[i].requestId,
        };
        const name = {
          subheading: "Test Name",
          description: selectedItem.labRequest[i].name,
        };
        const result = {
          subheading: "Priority",
          description: selectedItem.labRequest[i].priority,
        };
        const status = {
          subheading: "Status",
          description: selectedItem.labRequest[i].status,
        };

        const labReports = {
          subheading: "Lab Reports",
          description: '', //selectedItem.labRequest[i].image.map((val) => val),
        };
        lab.push(reqDate);
        lab.push(id);
        lab.push(name);
        lab.push(result);
        lab.push(status);
        lab.push(labReports);
      }
    }

    let rad = [];
    if (selectedItem.radRequest && selectedItem.radRequest.length > 0) {
      for (let i = 0; i < selectedItem.radRequest.length; i++) {
        const id = {
          subheading: "Test Id",
          description: selectedItem.radRequest[i].requestId,
        };
        const name = {
          subheading: "Test Name",
          description: selectedItem.radRequest[i].name,
        };
        const result = {
          subheading: "Priority",
          description: selectedItem.radRequest[i].priority,
        };

        const radReports = {
          subheading: "Rad Reports",
          description: selectedItem.radRequest[i].image.map((val) => val),
        };
        rad.push(id);
        rad.push(name);
        rad.push(result);
        rad.push(radReports);
      }
    }
    return { lab: [...lab], rad: [...rad] };
  }

  function setSocialWorkerData() {
    let selectedRecord = props.history.location.state.rec;

    // console.log('selectedRecord', rec)
    let socialWorkerArray = [];

    if (
      props.history.location.state.comingFor ===
        "social worker assisting patients" &&
      selectedRecord.socialWorkerAssistance &&
      selectedRecord.socialWorkerAssistance.length > 0
    ) {
      for (let i = 0; i < selectedRecord.socialWorkerAssistance.length; i++) {
        const id = {
          subheading: "Social Worker ID",
          description:
            selectedRecord.socialWorkerAssistance[i].requestedBy.identifier[0]
              .value,
        };
        const name = {
          subheading: "Social Worker Name",
          description:
            selectedRecord.socialWorkerAssistance[i].requestedBy.name[0]
              .given[0] +
            " " +
            selectedRecord.socialWorkerAssistance[i].requestedBy.name[0].family,
        };
        const assistance = {
          subheading: "Required Assistance",
          description:
            selectedRecord.socialWorkerAssistance[i].requiredAssistance,
        };

        const contactPerson = {
          subheading: "Contact Person",
          description:
            selectedRecord.socialWorkerAssistance[i].requestedTo.staffType,
        };
        socialWorkerArray.push(id);
        socialWorkerArray.push(name);
        socialWorkerArray.push(assistance);
        socialWorkerArray.push(contactPerson);
      }
    }

    let contactPerson = [];
    if (
      props.history.location.state.comingFor ===
        "social worker assisting patients" &&
      selectedRecord.socialWorkerAssistance &&
      selectedRecord.socialWorkerAssistance.length > 0
    ) {
      for (let i = 0; i < selectedRecord.socialWorkerAssistance.length; i++) {
        const name = {
          subheading: "Name",
          description:
            selectedRecord.socialWorkerAssistance[i].requestedTo.name[0]
              .given[0] +
            " " +
            selectedRecord.socialWorkerAssistance[i].requestedTo.name[0].family,
        };
        const email = {
          subheading: "Email",
          description: selectedRecord.socialWorkerAssistance[
            i
          ].requestedTo.telecom.map((v) => {
            if (v.system === "email") {
              return v.value;
            }
          }),
        };
        const mobile = {
          subheading: "Contact No",
          description: selectedRecord.socialWorkerAssistance[
            i
          ].requestedTo.telecom.map((v) => {
            if (v.system === "phone" || v.system === "mobile") {
              return v.value;
            }
          }),
        };
        contactPerson.push(name);
        contactPerson.push(mobile);
        contactPerson.push(email);
      }
    }

    return {
      socialWorkerArray: [...socialWorkerArray],
      contactPerson: [...contactPerson],
    };
  }

  useEffect(() => {
    let selectedItem = props.history.location.state.selectedItem;
    console.log(
      "patient data passed",
      props.history.location.state.selectedItem
    );
    console.log("current user ", currentUser.staffType);

    var email, mobileNumber, telephoneNumber, contactNumber;

    selectedItem.telecom &&
      selectedItem.telecom.map((v) => {
        if (v.system === "email") {
          email = v.value;
        }
        if (v.system === "mobile") {
          mobileNumber = v.value;
        }
        if (v.system === "phone") {
          telephoneNumber = v.value;
        }
      });
    selectedItem.contact &&
      selectedItem.contact[0].telecom.map((v) => {
        if (v.system === "phone" || v.system === "mobile") {
          contactNumber = v.value;
        }
      });

    let objForPatientDetails = {
      heading: "Patient Details",
      columnSize: 4,
      mobilecolumnSize: 2,
      subArray: [
        {
          subheading: "Patient MRN",
          description:
            selectedItem.identifier && selectedItem.identifier[0].value,
        },
        {
          subheading: "John Doe Registration",
          description:
            selectedItem.isJohnDoe?"Yes":null,
        },
        {
          subheading: "National ID",
          description: selectedItem.nationalID && selectedItem.nationalID,
        },
        {
          subheading: "Gender",
          description: selectedItem.gender && selectedItem.gender,
        },
        {
          subheading: "Age",
          description: selectedItem.age && selectedItem.age,
        },
        {
          subheading: "Date of Birth",
          description:
            selectedItem.birthDate &&
            formatDateWithoutTime(selectedItem.birthDate),
        },
        {
          subheading: "Email",
          description: email && email,
        },
        {
          subheading: "Height (cm)",
          description: selectedItem.height && selectedItem.height,
        },
        {
          subheading: "Weight (Kg)",
          description: selectedItem.weight && selectedItem.weight,
        },
        {
          subheading: "Mobile Number",
          description: mobileNumber && mobileNumber,
        },
        {
          subheading: "Telephone Number",
          description: telephoneNumber && telephoneNumber,
        },
        {
          subheading: "Country",
          description: selectedItem.address && selectedItem.address[0].country,
        },
        {
          subheading: "City",
          description: selectedItem.address && selectedItem.address[0].city,
        },
        {
          subheading: "Nationality",
          description: selectedItem.nationality && selectedItem.nationality,
        },
        {
          subheading: "Address",
          description: selectedItem.address && selectedItem.address[0].text,
        },
        {
          subheading: "ID Card (Front)",
          description: selectedItem.idCardFront && selectedItem.idCardFront,
        },
        {
          subheading: "ID Card (Back)",
          description: selectedItem.idCardBack && selectedItem.idCardBack,
        },
      ],
    };

    let objForEmergencyDetails = {
      heading: "Emergency Contact",
      columnSize: 4,
      mobilecolumnSize: 2,
      subArray: [
        {
          subheading: "Name",
          description: selectedItem.contact && selectedItem.contact[0].name,
        },
        {
          subheading: "Contact No.",
          description: contactNumber && contactNumber,
        },
        {
          subheading: "Relation",
          description:
            selectedItem.contact && selectedItem.contact[0].typeOfRelation,
        },
      ],
    };

    let objForPaymentMethod = {
      heading: "Payment Method",
      columnSize: 4,
      mobilecolumnSize: 2,
      subArray: [
        {
          subheading: "Payment Mode",
          description:
            selectedItem.paymentMode,
        },
        /*
        {
          subheading: "Date/Time",
          description:
            selectedItem.paymentMethod &&
            formatDate(selectedItem.paymentMethod[0].paymentDate),
        },
        {
          subheading: "Receiver Name",
          description:
            selectedItem.paymentMethod &&
            selectedItem.paymentMethod[0].recipient,
        },
        {
          subheading: "Depositor",
          description:
            selectedItem.paymentMethod && selectedItem.paymentMethod[0].payee,
        },
        {
          subheading: "Amount Received (JD)",
          description:
            selectedItem.paymentMethod && selectedItem.paymentMethod[0].amount,
        },
        */
      ],
    };

    let objForInsuranceDetails = {
      heading: "Insurance Details",
      columnSize: 4,
      mobilecolumnSize: 2,
      subArray: [
        {
          subheading: "Insured",
          description:
            selectedItem.paymentMode && selectedItem.paymentMode === "Insured"?"Yes":"No",
        },
        {
          subheading: "Insurance No.",
          description:
            selectedItem.insuranceNumber && selectedItem.insuranceNumber,
        },
        {
          subheading: "Insurance Vendor",
          description:
            selectedItem.insuranceVendor && selectedItem.insuranceVendor,
        },
        {
          subheading: "Coverage Terms",
          description: selectedItem.coverageTerms && selectedItem.coverageTerms,
        },
        {
          subheading: "Co-Payment (%)",
          description: selectedItem.coPayment && selectedItem.coPayment,
        },
        {
          subheading: "Covered Family Members",
          description:
            selectedItem.coveredFamilyMember &&
            selectedItem.coveredFamilyMember[0].typeOfRelation,
        },
        {
          subheading: "Coverage Details",
          description:
            selectedItem.coverageDetails && selectedItem.coverageDetails,
        },
        {
          subheading: "Other Details",
          description:
            selectedItem.insuranceDetails && selectedItem.insuranceDetails,
        },
        {
          subheading: "Insurance Card",
          description: selectedItem.insuranceCard && selectedItem.insuranceCard,
        },
      ],
    };
    console.log("selectedItem::selectedItem::", selectedItem);
    const insuranceDetailAdditional = (insuranceDetail) => {
      let iDA = [];
      if (insuranceDetail) {

        if (selectedItem.insuranceItemsInfo.length > 0){
          var objFilter = {
            "consultation": "Consultation",
            "lab": "Lab",
            "radiology": "Radiology",
            "treatment": "Treatment",
            "medicine": "Medicine",
            "dental": "Dental",
            "maternity": "Maternity",
          };

          Object.entries(objFilter).map(([key, val]) => {


            let consultation = [];
            if (
              selectedItem.insuranceItemsInfo[0][key + "Jod"] || 
              selectedItem.insuranceItemsInfo[0][key + "Copay"] || 
              selectedItem.insuranceItemsInfo[0][key + "Min"] || 
              selectedItem.insuranceItemsInfo[0][key + "Max"]
            ){
              if (selectedItem.insuranceItemsInfo[0][key + "Jod"]){
                consultation.push("JOD: " + selectedItem.insuranceItemsInfo[0][key + "Jod"]);
              }
              if (selectedItem.insuranceItemsInfo[0][key + "Copay"]){
                consultation.push("Co Pay: " + selectedItem.insuranceItemsInfo[0][key + "Copay"] + '%'); 
              }
              if (selectedItem.insuranceItemsInfo[0][key + "Min"]){
                consultation.push("Min: " + selectedItem.insuranceItemsInfo[0][key + "Min"]);
                
              }
              if (selectedItem.insuranceItemsInfo[0][key + "Max"]){
                consultation.push("Max: " + selectedItem.insuranceItemsInfo[0][key + "Max"]);
              }
            }

            iDA.push({
              subheading: val,
              description: consultation.join(', ')
            })
          });



          iDA.push({
              subheading: "Insurance - Max Insurance Liability",
              description: selectedItem.insuranceItemsInfo ? selectedItem.insuranceItemsInfo[0].maxInsuranceLiability: undefined
          });
          iDA.push({
              subheading: "Pre-Approval Limit",
              description: selectedItem.insuranceItemsInfo ? selectedItem.insuranceItemsInfo[0].preApprovalLimit: undefined
          });
          iDA.push({
              subheading: "Invoice Max Co-Pay",
              description: selectedItem.insuranceItemsInfo ? selectedItem.insuranceItemsInfo[0].invoiceMaxCopay: undefined
          });
          iDA.push({
              subheading: "Out-Patient (Co-Pay %)",
              description: selectedItem.insuranceItemsInfo ? selectedItem.insuranceItemsInfo[0].outPatientCopay: undefined
          });
          iDA.push({
              subheading: "In-Patient (Co-Pay %)",
              description: selectedItem.insuranceItemsInfo ? selectedItem.insuranceItemsInfo[0].inPatientCopay: undefined
          });
          iDA.push({
              subheading: "Exclusions",
              description: selectedItem.insuranceItemsInfo ? selectedItem.insuranceItemsInfo[0].insuranceExclusions: undefined
          });
        }






        return iDA;

        /*[
          {
            subheading: "Consultation",
            description: selectedItem.insuranceItemsInfo ? (
                "JOD: " + selectedItem.insuranceItemsInfo[0].consultationJod + ", " + 
                "Co Pay: " + selectedItem.insuranceItemsInfo[0].consultationCopay + "%, " + 
                "Min: " + selectedItem.insuranceItemsInfo[0].consultationMin + ", " + 
                "Max: " + selectedItem.insuranceItemsInfo[0].consultationMax
              ) : undefined,
          },
          {
            subheading: "Lab",
            description: selectedItem.insuranceItemsInfo ? (
                "JOD: " + selectedItem.insuranceItemsInfo[0].labJod + ", " + 
                "Co Pay: " + selectedItem.insuranceItemsInfo[0].labCopay + "%, " + 
                "Coverage Type: " + selectedItem.insuranceItemsInfo[0].labCopay + ", " + 
                "Min: " + selectedItem.insuranceItemsInfo[0].labMin + ", " + 
                "Max: " + selectedItem.insuranceItemsInfo[0].labMax
              ) : undefined,
          },
          {
            subheading: "Radiology",
            description: selectedItem.insuranceItemsInfo ? (
                "JOD: " + selectedItem.insuranceItemsInfo[0].radiologyJod + ", " + 
                "Co Pay: " + selectedItem.insuranceItemsInfo[0].radiologyCopay + "%, " + 
                "Coverage Type: " + selectedItem.insuranceItemsInfo[0].radiologyCopay + ", " + 
                "Min: " + selectedItem.insuranceItemsInfo[0].radiologyMin + ", " + 
                "Max: " + selectedItem.insuranceItemsInfo[0].radiologyMax
              ) : undefined,
          },
          {
            subheading: "Treatment",
            description: selectedItem.insuranceItemsInfo ? (
                "JOD: " + selectedItem.insuranceItemsInfo[0].treatmentJod + ", " + 
                "Co Pay: " + selectedItem.insuranceItemsInfo[0].treatmentCopay + "%, " + 
                "Coverage Type: " + selectedItem.insuranceItemsInfo[0].treatmentCopay + ", " + 
                "Min: " + selectedItem.insuranceItemsInfo[0].treatmentMin + ", " + 
                "Max: " + selectedItem.insuranceItemsInfo[0].treatmentMax
              ) : undefined,
          },
          {
            subheading: "Medicine",
            description: selectedItem.insuranceItemsInfo ? (
                "JOD: " + selectedItem.insuranceItemsInfo[0].medicineJod + ", " + 
                "Co Pay: " + selectedItem.insuranceItemsInfo[0].medicineCopay + "%, " + 
                "Coverage Type: " + selectedItem.insuranceItemsInfo[0].medicineCopay + ", " + 
                "Min: " + selectedItem.insuranceItemsInfo[0].medicineMin + ", " + 
                "Max: " + selectedItem.insuranceItemsInfo[0].medicineMax
              ) : undefined,
          },
          {
            subheading: "Dental",
            description: selectedItem.insuranceItemsInfo ? (
                "JOD: " + selectedItem.insuranceItemsInfo[0].dentalJod + ", " + 
                "Co Pay: " + selectedItem.insuranceItemsInfo[0].dentalCopay + "%, " + 
                "Coverage Type: " + selectedItem.insuranceItemsInfo[0].dentalCopay + ", " + 
                "Min: " + selectedItem.insuranceItemsInfo[0].dentalMin + ", " + 
                "Max: " + selectedItem.insuranceItemsInfo[0].dentalMax
              ) : undefined,
          },
          {
            subheading: "maternityCopay",
            description: selectedItem.insuranceItemsInfo ? (
                "Co Pay: " + selectedItem.insuranceItemsInfo[0].maternityCopay + "%, " + 
                "Coverage Type: " + selectedItem.insuranceItemsInfo[0].maternityCopay + ", " + 
                "Min: " + selectedItem.insuranceItemsInfo[0].maternityMin + ", " + 
                "Max: " + selectedItem.insuranceItemsInfo[0].maternityMax
              ) : undefined,
          },
          {
            subheading: "Other Details",
            description:
              selectedItem.insuranceDetails && selectedItem.insuranceDetails,
          },
          {
            subheading: "Insurance Card",
            description: selectedItem.insuranceCard && selectedItem.insuranceCard,
          },
        ]*/

      }
    }

    let objForInsuranceAdditionDetails = {
      heading: "Additional Insurance Details",
      columnSize: 1,
      mobilecolumnSize: 2,
      subArray: insuranceDetailAdditional(selectedItem.insuranceItemsInfo),
    };
    if (props.history.location.state.comingFor === "EOUPatients Screen") {
      var objForEOUPatients = {
        heading: "Department Details",
        columnSize: 4,
        subArray: [
          {
            subheading: "ProductionArea",
            description: props.history.location.state.rec.productionArea
              ? props.history.location.state.rec.productionArea
              : "N/A",
          },
          {
            subheading: "Room No",
            description: props.history.location.state.rec.RoomNo
              ? props.history.location.state.rec.RoomNo
              : "N/A",
          },
          {
            subheading: "CareStream",
            description:
              props.history.location.state.rec.careStream &&
              props.history.location.state.rec.careStream[
                props.history.location.state.rec.careStream.length - 1
              ]
                ? props.history.location.state.rec.careStream[
                    props.history.location.state.rec.careStream.length - 1
                  ].name
                : "N/A",
          },
        ],
      };
    }

    
    
    var objForCareStream = {
      heading: "Chief Complaint & CareStream",
      columnSize: 4,
      mobilecolumnSize: 2,
      subArray: [
        {
          subheading: "Chief Complaint",
          description: selectedItem.chiefComplaint
            ? selectedItem.chiefComplaint
            : "N/A",
        },
        {
          subheading: "CareStream",
          description: selectedItem.careStream
            ? selectedItem.careStream
            : "N/A",
        },
      ],
    };

    let objForDepartmentalDetails = {
      heading: "Departmental Details",
      columnSize: 4,
      mobilecolumnSize: 2,
      subArray: [
        {
          subheading: "Chief Complaint",
          description: selectedItem.chiefComplaint
            ? selectedItem.chiefComplaint
            : "N/A",
        },

        {
          subheading: "Room No",
          description: selectedItem.roomNo ? selectedItem.roomNo : "N/A",
        },
        {
          subheading: "CareStream",
          description: selectedItem.careStream
            ? selectedItem.careStream
            : "N/A",
        },
      ],
    };

    let objForLabTests = {
      heading: "Lab Tests",
      columnSize: 6,
      subArray: setRequestsData().lab,
    };

    let objForRadTests = {
      heading: "Radiology Tests",
      columnSize: 4,
      subArray: setRequestsData().rad,
    };

    let objForSocialWorker = {
      heading: "Social Worker Assistance",
      columnSize: 4,
      subArray: setSocialWorkerData().socialWorkerArray,
    };

    let objForContactPerson = {
      heading: "Contact Person",
      columnSize: 3,
      subArray: setSocialWorkerData().contactPerson,
    };

    let arr = [];
    console.log('ViewPatientDetails', props.history.location.state.comingFor);
    if (currentUser.staffType === "Admin") {
      arr.push(objForPatientDetails);
      arr.push(objForEmergencyDetails);
      arr.push(objForPaymentMethod);
      arr.push(objForInsuranceDetails);
      arr.push(objForInsuranceAdditionDetails);

      if (props.history.location.state.comingFor === "patient history") {
        arr.push(objForDepartmentalDetails);
        arr.push(objForLabTests);
        arr.push(objForRadTests);
      }
      if (props.history.location.state.comingFor === "EOUPatients Screen") {
        arr.push(objForEOUPatients);
      }
      if (
        props.history.location.state.comingFor ===
        "social worker assisting patients"
      ) {
        arr.push(objForSocialWorker);
        arr.push(objForContactPerson);
      }
    } else if (currentUser.staffType === "Registration Officer") {
      arr = [
        {
          ...objForPatientDetails,
        },
        {
          ...objForEmergencyDetails,
        },
        {
          ...objForPaymentMethod,
        },
        {
          ...objForInsuranceDetails,
        },
        {
          ...objForInsuranceAdditionDetails,
        }
      ];
    } else if (currentUser.staffType === "Insurance Claims Manager") {
      arr = [
        {
          ...objForPatientDetails,
        },
        {
          ...objForEmergencyDetails,
        },
        {
          ...objForPaymentMethod,
        },
        {
          ...objForInsuranceDetails,
        },
      ];
    } else if (currentUser.staffType === "Sensei") {
      arr = [
        {
          ...objForPatientDetails,
        },
        {
          ...objForEmergencyDetails,
        },
        {
          ...objForCareStream,
        },
      ];
    }
    setSelectedPatient(arr);
  }, []);

  const formatDate = (date) => {
    const d = new Date(date);
    let minutes = "";
    if (d.getHours().toString().length === 1) {
      minutes = "0" + d.getHours();
    } else {
      minutes = d.getHours();
    }
    return (
      d
        .getDate()
        .toString()
        .padStart(2, "0") +
      " - " +
      (d.getMonth() + 1).toString().padStart(2, "0") +
      " - " +
      d.getFullYear() +
      " " +
      minutes +
      ":" +
      ("00" + d.getMinutes()).slice(-2)
    );
  };

  const formatDateWithoutTime = (date) => {
    const d = new Date(date);
    let minutes = "";
    if (d.getHours().toString().length === 1) {
      minutes = "0" + d.getHours();
    } else {
      minutes = d.getHours();
    }
    return (
      d
        .getDate()
        .toString()
        .padStart(2, "0") +
      " - " +
      (d.getMonth() + 1).toString().padStart(2, "0") +
      " - " +
      d.getFullYear()
    );
  };

  const handleEdit = () => {
    let path = `/dashboard/home/patientregistration`;
    props.history.push({
      pathname: path,
      state: {
        comingFor: "edit",
        selectedItem: props.history.location.state.selectedItem,
      },
    });
  };

  const handleCheifComplaint = () => {
    let path = `viewpatients/assigncheifcomplaint`;
    props.history.push({
      pathname: path,
      state: {
        selectedItem: props.history.location.state.rec,
      },
    });
  };

  const handleViewDCDform = () => {
    const selectedItem = props.history.location.state.rec;
    props.history.push({
      pathname: "/dashboard/home/dcddashboard",
      state: selectedItem,
    });
  };

  function viewBillingInfo() {
    let rec = {
      ...props.history.location.state.selectedItem.selectedPatientBill,
    };

    rec.patientId.profileNo = rec.patientId.identifier[0].value;
    rec.patientId.firstName = rec.patientId.name[0].given[0];
    rec.patientId.lastName = rec.patientId.name[0].family;
    rec.patientId.gender = rec.patientId.gender;
    rec.patientId.age = rec.patientId.age;
    rec.patientId.weight = rec.patientId.weight;

    let path = `/dashboard/home/patientclearence/edit`;
    props.history.push({
      pathname: path,
      state: {
        comingFor: "view",
        selectedItem: rec,
      },
    });
  }

  function handlePrint() {
    console.log("called");
    window.print();

    setPrint(false);
  }



  const downloadPatientDetails = async () => {

    const data = await document.querySelector("#jstpdf");

    html2PDF(data, {
        jsPDF: {
          format: 'a4',
          unit: 'px',
        },
        margin: {
        top: 10,
        right: 10,
        bottom: 10,
        left: 10,
      },
        imageType: 'image/jpeg',
        output: `Patient-${props.history.location.state.selectedItem.name[0].given[0]}-${props.history.location.state.selectedItem.name[0].family}`
        // output: `Patient-${selectedTriage.triageRequestNo}.pdf`
    });
  };

  function downloadQRMRN(){
    props.history.push({
      pathname: "/generateQRView",
      state: {
        comingFor: "view",
        selectedItem: props.history.location.state.selectedItem,
      },
    });
  }

  useEffect(() => {
    if (print) {
      handlePrint();
    }
  }, [print]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        position: "fixed",
        width: "100%",
        height: "100%",
        overflowY: "scroll",
        border: "1p",
      }}
    >
      <Header history={props.history} />
      <div className="cPadding" id="jstpdf">
        <div className="patientDetailsHeader" style={{ marginBottom: 45 }}>
          <div className="col-md-6 col-6">
            {props.history.location.state.selectedItem.photo &&
            props.history.location.state.selectedItem.photo.length > 0 &&
            props.history.location.state.selectedItem.photo[0].url.includes(
              "\\"
            ) ? (
              // <>
              //   {props.history.location.state.selectedItem.photo.length > 0 &&
              //   props.history.location.state.selectedItem.photo[0].url.slice(
              //     props.history.location.state.selectedItem.photo[0].url
              //       .length - 3
              //   ) !== 'pdf' ? (
              <div>
                <img
                  src={
                    (uploadsUrl +
                    props.history.location.state.selectedItem.photo[0].url.split(
                      "\\"
                    )[1]).replace('/uploads/uploads', '/uploads')
                  }
                  className="profilePicture"
                />
              </div>
            ) : //   ) : (
            //     undefined
            //   )}
            // </>
            props.history.location.state.selectedItem.photo &&
              props.history.location.state.selectedItem.photo.length > 0 &&
              props.history.location.state.selectedItem.photo[0].url.includes(
                "/"
              ) ? (
              // <>
              //   {props.history.location.state.selectedItem.photo &&
              //   props.history.location.state.selectedItem.photo.length > 0 &&
              //   props.history.location.state.selectedItem.photo[0].url.slice(
              //     props.history.location.state.selectedItem.photo[0].url
              //       .length - 3
              //   ) !== 'pdf' ? (
              <div>
                <img
                  src={
                    (uploadsUrl +
                    "uploads" +
                    props.history.location.state.selectedItem.QR.split(
                      "/uploads"
                    )[1]).replace('/uploads/uploads', '/uploads')
                  }
                  className="profilePicture"
                />
              </div>
            ) : (
              //   ) : (
              //     undefined
              //   )}
              // </>
              <Avatar src="/broken-image.jpg" />
            )}

            <h4 className="PatientName">
              {props.history.location.state.selectedItem.name[0].given[0] +
                " " +
                props.history.location.state.selectedItem.name[0].family}
            </h4>
          </div>

          <div className="col-md-6 col-6 d-flex justify-content-end">
            <img
              className="patientDetailIcons"
              src={DownloadIcon}
              onClick={downloadPatientDetails}
              alt="Download"
            />
            <img
              className="patientDetailIcons"
              src={PrintIcon}
              alt="Print"
              onClick={() => handlePrint()}
            />
            {/*
            <img className="patientDetailIcons" src={SummaryQR} alt="QR Code"
              onClick={() => downloadQRMRN()} />
            */}
          </div>
        </div>

        <div
          style={{ flex: 4, display: "flex", flexDirection: "column" }}
          className={`${"container-fluid"} ${classes.root}`}
        >
          {/* {selectedPatient.map((arr) => {
            return (
              <DetailBlock
                heading={arr.heading}
                subArray={arr.subArray}
                columnSize={arr.columnSize}
                mobilecolumnSize={arr.mobilecolumnSize}
              />
            )
          })}

          <div
            className='row'
            style={{ marginTop: '20px', marginBottom: '20px' }}
          >
            <div className='col-md-6 col-sm-6 col-6'>
              <img
                onClick={() => props.history.goBack()}
                src={Back}
                style={{
                  maxWidth: matches ? '60%' : 45,
                  height: matches ? 'auto' : 30,
                  cursor: 'pointer',
                }}
              />
            </div>
            <div className='col-md-6 col-sm-6 col-6 d-flex justify-content-end'>
              {currentUser.staffType === 'Sensei' ? (
                <Button
                  style={styles.Cheif}
                  variant='contained'
                  // color='default'
                  onClick={handleCheifComplaint}
                >
                  Assign Chief Complaint
                </Button>
              ) : currentUser.staffType === 'Registration Officer' ? (
                <Button
                  style={styles.save}
                  variant='contained'
                  color='default'
                  onClick={handleEdit}
                >
                  Edit
                </Button>
              ) : props.history.location.state.comingFor === 'bill summary' ? (
                <Button
                  style={styles.save}
                  variant='contained'
                  color='default'
                  onClick={viewBillingInfo}
                >
                  Billing Infomation
                </Button>
              ) : (
                undefined
              )}
            </div>
          </div> */}

          <div
            style={{ flex: 4, display: "flex", flexDirection: "column" }}
            className={`${"container-fluid"} ${classes.root}`}
          >
            {selectedPatient.map((arr) => {
              return (
                <DetailBlock
                  heading={arr.heading}
                  subArray={arr.subArray}
                  columnSize={arr.columnSize}
                  mobilecolumnSize={arr.mobilecolumnSize}
                  print={true}
                />
              );
            })}
            {!print ? (
              <div
                className="row"
                style={{ marginTop: "20px", marginBottom: "20px" }}
              >
                <div className="col-md-6 col-sm-6 col-6">
                  <img
                    onClick={() => props.history.goBack()}
                    src={Back}
                    style={{
                      maxWidth: matches ? "60%" : 45,
                      height: matches ? "auto" : 30,
                      cursor: "pointer",
                    }}
                  />
                </div>
                <div className="col-md-6 col-sm-6 col-6 d-flex justify-content-end">
                  {currentUser.staffType === "Sensei" ? (
                    <div>
                      {
                        props.history.location.state.selectedItem.chiefComplaint &&
                        props.history.location.state.selectedItem.chiefComplaint.length > 0 ? (
                          <Button
                            style={styles.Cheif}
                            variant="contained"
                            // color='default'
                            onClick={handleViewDCDform}
                          >View DCD Form</Button>
                        ) : (
                          <Button
                            style={styles.Cheif}
                            variant="contained"
                            // color='default'
                            onClick={handleCheifComplaint}
                          >Assign Chief Complaint</Button>
                        )
                      }
                    </div>
                  ) : currentUser.staffType === "Registration Officer" ? (
                    <Button
                      style={styles.Edit}
                      variant="contained"
                      color="default"
                      onClick={handleEdit}
                    >
                      Edit
                    </Button>
                  ) : props.history.location.state.comingFor ===
                    "bill summary" ? (
                    <Button
                      style={styles.save}
                      variant="contained"
                      color="default"
                      onClick={viewBillingInfo}
                    >
                      Billing Infomation
                    </Button>
                  ) : (
                    undefined
                  )}
                </div>
              </div>
            ) : (
              undefined
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
