import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(0),
  },
  labelRoot: {
    left: "150px",
  },
  input: {
    backgroundColor: "#F5F5F5",
    boxShadow: "none",
    borderRadius: 5,
    "&:after": {
      borderBottomColor: "black",
      boxShadow: "none",
    },
    "&:hover": {
      backgroundColor: "#F5F5F5",
      boxShadow: "none",
    },
    "&:focus": {
      backgroundColor: "#F5F5F5",
      boxShadow: "none",
      borderRadius: 5,
    },
  },
  multilineColor: {
    boxShadow: "none",
    backgroundColor: "#F5F5F5",
    borderRadius: 5,
    "&:hover": {
      backgroundColor: "#F5F5F5",
      boxShadow: "none",
    },
    "&:after": {
      borderBottomColor: "black",
      boxShadow: "none",
    },
    "&:focus": {
      boxShadow: "none",
    },
  },
  root: {
    "& .MuiTextField-root": {
      backgroundColor: "#F5F5F5",
    },
    "& .Mui-focused": {
      backgroundColor: "#F5F5F5",
      color: "black",
      boxShadow: "none",
    },
    "& .Mui-disabled": {
      backgroundColor: "#F5F5F5",
      color: "gray",
    },
    "&:focus": {
      backgroundColor: "#F5F5F5",
      boxShadow: "none",
    },
    "& .MuiFormLabel-root": {
      fontSize: "11px",
      paddingRight: 50,
    },
  },

  small: {
    width: theme.spacing(5),
    height: theme.spacing(5),
    cursor: "pointer",
  },
  large: {
    width: theme.spacing(22),
    height: theme.spacing(22),
  },
}));
