import React, { useState, useEffect, useReducer, useCallback } from "react";
// import Chip from "@material-ui/core/Chip";
import {
  Chip,
  MenuItem,
  makeStyles,
  TextField,
  Input,
  Select,
  InputAdornment,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import DoneIcon from "@material-ui/icons/Done";
import MicIcon from "@material-ui/icons/Mic";
import StopIcon from "@material-ui/icons/Stop";
import MicRecorder from "mic-recorder-to-mp3";
import Loader from "react-loader-spinner";
// import { makeStyles, useTheme } from "@material-ui/core/styles";
// import useMediaQuery from "@material-ui/core/useMediaQuery";
// import TextField from "@material-ui/core/TextField";
// import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import Back from "../../../../assets/img/Back_Arrow.png";
import Diagram from "../../../../assets/img/diagram.png";
import ImageViewer from "react-simple-image-viewer";
import { IoIosCloseCircle } from "react-icons/io";
import SliderComponent from "../../../../components/SliderComponent/sliderComponent";
import "./formRow.css";
import ImageMarker, { Marker, MarkerComponentProps } from "react-image-marker";
const CustomMarker = (props: MarkerComponentProps) => {
    return (
        <p className="custom-marker" style={{borderRadius:20, width:20,height:20,backgroundColor:'#FF0000'}}></p>
    );
};

const Mp3Recorder = new MicRecorder({ bitRate: 128 });

const styles = {
  ROSRowPadding: {
    marginLeft: 0,
    marginRight: 0,
    backgroundColor: "white",
    marginTop: 10,
    padding: "10px 0px 10px 0px",
    borderRadius: 5,
  },
  chipAlignment: {
    display: "inline-block",
    padding: "5px",
  },
  save: {
    color: "white",
    cursor: "pointer",
    borderRadius: 5,
    backgroundColor: "#2c6ddd",
    width: "115px",
    height: "40px",
    outline: "none",
  },
};

const useStyles = makeStyles((theme) => ({
  input: {
    backgroundColor: "#F5F5F5",
    boxShadow: "none",
    borderRadius: 5,
    "&:after": {
      borderBottomColor: "black",
      boxShadow: "none",
    },
    "&:hover": {
      backgroundColor: "#F5F5F5",
      boxShadow: "none",
    },
    "&:focus": {
      backgroundColor: "#F5F5F5",
      boxShadow: "none",
      borderRadius: 5,
    },
  },
  root: {
    "& .MuiTextField-root": {
      backgroundColor: "white",
    },
    "& .Mui-focused": {
      backgroundColor: "white",
      color: "black",
      boxShadow: "none",
    },
    "& .Mui-disabled": {
      backgroundColor: "white",
      color: "gray",
    },
    "&:focus": {
      backgroundColor: "white",
      boxShadow: "none",
    },
    "& .MuiFormLabel-root": {
      fontSize: "12px",
      color: "black",
      paddingRight: "15px",
    },
    "& .MuiInputLabel-filled": {
      color: "gray",
    },
  },

  
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

export default function PhysicalExamFormRow(props) {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery("(min-width:600px)");
  const matchesForTablet = useMediaQuery(theme.breakpoints.up("md"));
  const [congestionSelectedItem, setCongestionSelectedItem] = useState({});

  const initialState = {
    investigation: [],
    isBlocked: true,
    isRecording: false,
    blobURL: [],
    recordingMode: ""
  };

  function reducer(state, { field, value }) {
    return {
      ...state,
      [field]: value,
    };
  }

  const [state, dispatch] = useReducer(reducer, initialState);

  const { investigation, isBlocked, isRecording, blobURL, recordingMode } = state;

  const [clickedRow, setClickedRow] = useState([]);
  const [skinreport, setSkinreport] = useState([]);
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [imagesDisplay, setImagesDisplay] = useState([]);
  const [painScale, setPainScale] = useState(0);
  const [markers, setMarkers] = useState([]);
  const [deceasedPulseObj, setDeceasedPulseObj] = useState({});
  const [markersForSeeDiagram, setMarkersForSeeDiagram] = useState([]);
  const [seeDiagram, setSeeDiagram] = useState({});

  useEffect(() => {}, []);

  // console.log("Form Array", investigation);

  const onRowClick = (row) => {
    if (!clickedRow.includes(row)) {
      setClickedRow([...clickedRow, row]);
    }
    props.rowsSelected(true);
  };

  const setMarkerFunction = (markers) => {
    deceasedPulseObj.imageMarkers = markers;
  };

  const setMarkerSeeDiagramFunction = (markers) => {
    seeDiagram.imageMarkers = markers;
  };

  useEffect(() => {
    // Navigator for MP3
    navigator.mediaDevices &&
      navigator
        .mediaDevices.getUserMedia({ audio: true })
        .then( stream => {
          dispatch({ field: "isBlocked", value: false });
        })
        .catch( error => {
          dispatch({ field: "isBlocked", value: true });
        });
  })

  useEffect(() => {
    setMarkerFunction(markers);
  }, [markers]);

  useEffect(() => {
    setMarkerSeeDiagramFunction(markersForSeeDiagram);
  }, [markersForSeeDiagram]);

  const handleChipClick = (e, row, val) => {
    console.log("Clicked chip ", e, row, val);

    if (val.name === "Decreased Pulse(s)") {
      setDeceasedPulseObj(val);
    } else if (val.name === "See Diagram") {
      setSeeDiagram(val);
    }

    let tempVal = val;

    if (investigation.length > 0) {
      var rowFound = false;
      for (let i = 0; i < investigation.length; i++) {
        if (!rowFound) {
          if (investigation[i].name === row) {
            rowFound = true;
            let chip = investigation[i].chips;
            var chipFound = false;
            for (let j = 0; j < chip.length; j++) {
              if (!chipFound) {
                if (chip[j].name === val.name) {
                  if (chip[j].name.includes("Add")) {
                    if (val.image && val.image.length > 0) {
                      chip.splice(j, 1);
                      chip.push(tempVal);
                      dispatch({
                        field: "investigation",
                        value: investigation,
                      });
                      chipFound = true;
                      j--;
                    } else {
                      chip.splice(j, 1);
                      dispatch({
                        field: "investigation",
                        value: investigation,
                      });
                      chipFound = true;
                    }
                  } else {
                    chip.splice(j, 1);
                    dispatch({
                      field: "investigation",
                      value: investigation,
                    });
                    chipFound = true;
                    j--;
                  }
                }
              }
            }
            if (!chipFound) {
              chip.push(tempVal);
              dispatch({
                field: "investigation",
                value: investigation,
              });
            }
          }
        }
      }
      if (!rowFound) {
        dispatch({
          field: "investigation",
          value: [
            ...investigation,
            {
              name: row,
              chips: [tempVal],
              Texts: [],
              voiceNotes: {
                audio: '',
                notes: ''
              }
            },
          ],
        });
      }
    } else {
      let obj = {};
      let chipsArray = [];
      obj.name = row;
      chipsArray.push(tempVal);
      obj.chips = chipsArray;
      obj.Texts = [];
      obj.voiceNotes = {
        audio: '',
        notes: ''
      };

      dispatch({
        field: "investigation",
        value: [obj],
      });
    }
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const handleSubChipClick = (subChip, row, chipp) => {
    console.log("Subchip clicked", subChip, row, chipp);

    var rowFound = false;
    for (let i = 0; i < investigation.length; i++) {
      if (!rowFound) {
        if (investigation[i].name === row) {
          rowFound = true;
          let chip = investigation[i].chips;
          for (let j = 0; j < chip.length; j++) {
            if (chip[j].name === chipp.name) {
              let subChips = chip[j].subChips;
              var subchipFound = false;
              for (let k = 0; k < subChips.length; k++) {
                if (!subchipFound) {
                  if (subChips[k].name === subChip.name) {
                    if (subChips[k].selected) {
                      subChips[k].selected = false;
                      dispatch({
                        field: "investigation",
                        value: investigation,
                      });
                      subchipFound = true;
                    } else if (!subChips[k].selected) {
                      subChips[k].selected = true;
                      dispatch({
                        field: "investigation",
                        value: investigation,
                      });
                      subchipFound = true;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  };

  const onTextChange = (e, row, text) => {
    text.value = e.target.value;
    if (investigation.length > 0) {
      var rowFound = false;
      for (let i = 0; i < investigation.length; i++) {
        if (!rowFound) {
          if (investigation[i].name === row) {
            rowFound = true;
            let texts = investigation[i].Texts;
            var fieldFound = false;
            for (let j = 0; j < texts.length; j++) {
              if (!fieldFound) {
                if (texts[j].name === text.name) {
                  texts[j].value = e.target.value;

                  dispatch({
                    field: "investigation",
                    value: investigation,
                  });
                  fieldFound = true;
                }
              }
            }
            if (!fieldFound) {
              texts.push(text);
              dispatch({
                field: "investigation",
                value: investigation,
              });
            }
          }
        }
      }
      if (!rowFound) {
        dispatch({
          field: "investigation",
          value: [
            ...investigation,
            {
              name: row,
              Texts: [text],
              chips: [],
            },
          ],
        });
      }
    } else {
      let obj = {};
      let textsArray = [];
      obj.name = row;
      textsArray.push(text);
      obj.Texts = textsArray;
      obj.chips = [];

      dispatch({
        field: "investigation",
        value: [obj],
      });
    }
  };

  const onChipText = (e, row, chipText) => {
    for (let i = 0; i < investigation.length; i++) {
      if (investigation[i].name === row) {
        let chip = investigation[i].chips;
        for (let j = 0; j < chip.length; j++) {
          if (chip[j].name === chipText.name) {
            chip[j].detail = e.target.value;
            dispatch({
              field: "investigation",
              value: investigation,
            });
          }
        }
      }
    }
  };

  const onChipSubText = (e, row, text, side) => {
    text.value = e.target.value;

    for (let i = 0; i < investigation.length; i++) {
      if (investigation[i].name === row) {
        let chip = investigation[i].chips;
        for (let j = 0; j < chip.length; j++) {
          if (chip[j].name === "Decreased Pulse(s)") {
            let right = chip[j].right;
            let left = chip[j].left;
            if (side === "right") {
              for (let k = 0; k < right.length; k++) {
                if (right[k].name === text.name) {
                  right[k].value = e.target.value;
                  dispatch({
                    field: "investigation",
                    value: investigation,
                  });
                }
              }
            } else if (side === "left") {
              for (let k = 0; k < left.length; k++) {
                if (left[k].name === text.name) {
                  left[k].value = e.target.value;
                  dispatch({
                    field: "investigation",
                    value: investigation,
                  });
                }
              }
            }
          }
        }
      }
    }
  };

  function toArray(fileList) {
    return Array.prototype.slice.call(fileList);
  }

  const onDocumentUpload = (event, chipName, rowName, val) => {
    var file = event.target.files;
    var joined = toArray(skinreport).concat(toArray(file));
    setSkinreport(joined);

    for (let i = 0; i < file.length; i++) {
      let reader = new FileReader();
      reader.readAsDataURL(file[i]);
      reader.onload = function(event) {
        val.image.push(event.target.result);
        handleChipClick(chipName, rowName, val);
      };
    }
  };

  const onImageDelete = (index, chipName, rowName, val) => {
    let temp = skinreport;
    temp.splice(index, 1);
    setSkinreport(temp);
    val.image.splice(index, 1);
    handleChipClick(chipName, rowName, val);
  };

  const openImageViewer = useCallback((index, val) => {
    setImagesDisplay(val);
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
    setImagesDisplay([]);
  };

  const handlePainScale = (e, row, scale) => {
    setPainScale(e);

    scale.value = e;

    if (investigation.length > 0) {
      var rowFound = false;
      for (let i = 0; i < investigation.length; i++) {
        if (!rowFound) {
          if (investigation[i].name === row) {
            rowFound = true;
            investigation[i].scale = scale;
            dispatch({
              field: "investigation",
              value: investigation,
            });
          }
        }
      }
      if (!rowFound) {
        dispatch({
          field: "investigation",
          value: [
            ...investigation,
            {
              name: row,
              scale: scale,
              chips: [],
            },
          ],
        });
      }
    } else {
      dispatch({
        field: "investigation",
        value: [
          {
            name: row,
            scale: scale,
            chips: [],
          },
        ],
      });
    }
  };



  const handleDropdownChange = (
    setter,
    value,
    dropdownOptions,
    rowName,
    dropdownName
  ) => {
    setter(value);
    const selectedItem = dropdownOptions.find(
      (option) => option.name === value
    );
    setCongestionSelectedItem(selectedItem);
    const modifiedInvestigation = investigation.map((item) =>
      item.name === rowName
        ? {
            ...item,
            chips: item.chips.map((c) =>
              c.name === dropdownName
                ? { ...c, dropdownSelectedValue: selectedItem }
                : { ...c }
            ),
          }
        : item
    );
    dispatch({
      field: "investigation",
      value: modifiedInvestigation,
    });
  };

  

  const setAudioFileToChips = (e, row, val) => {
    let tempVal = val;
    if (investigation.length > 0) {
      var rowFound = false;
      for (let i = 0; i < investigation.length; i++) {
        if (!rowFound) {
          if (investigation[i].name === row) {
            rowFound = true;
            let chip = investigation[i].chips;
            var chipFound = false;
            for (let j = 0; j < chip.length; j++) {
              if (!chipFound) {
                if (chip[j].name === val.name) {
                  if (chip[j].audio!=""){
                    chip.splice(j, 1);
                    chip.push(tempVal);
                    dispatch({
                      field: "investigation",
                      value: investigation,
                    });
                    chipFound = true;
                  }
                }
              }
            }
            if (!chipFound) {
              chip.push(tempVal);
              dispatch({
                field: "investigation",
                value: investigation,
              });
            }
          }
        }
      }
    } else {
      let obj = {};
      let chipsArray = [];
      obj.name = row;
      chipsArray.push(tempVal);
      obj.chips = chipsArray;
      obj.Texts = [];
      obj.voiceNotes = {
        audio: '',
        notes: ''
      };

      dispatch({
        field: "investigation",
        value: [obj],
      });
    }
  };

  const setAudioFileToNotes = (e, row, val) => {
    let tempVal = val;
    if (investigation.length > 0) {
      var rowFound = false;
      for (let i = 0; i < investigation.length; i++) {
        if (!rowFound) {
          if (investigation[i].name === row) {
            console.log("INVESTIGATION GLOBAL: ", investigation[i]);
            investigation[i].voiceNotes.audio = val.voiceNotes.audio;
            console.log("INVESTIGATION GLOBAL: FINISH", investigation[i]);
            rowFound = true;
            dispatch({
              field: "investigation",
              value: investigation,
            });
          }
        }
      }
    } else {
      let obj = {};
      let chipsArray = [];
      obj.name = row;
      obj.chips = chipsArray;
      obj.Texts = [];
      obj.voiceNotes = {
        audio: val.voiceNotes.audio,
        notes: ''
      };

      dispatch({
        field: "investigation",
        value: [obj],
      });
    }
  };

  const onRecordAudioFinishGlobal = (file, chipName, rowName, val) => {
    console.log("ST+", file, chipName, rowName, val);
    val.voiceNotes.audio = file;
    console.log("ST+", val);
    setAudioFileToNotes(chipName, rowName, val);
  };

  const onRecordAudioFinish = (file, chipName, rowName, val) => {
    console.log("ST+", file, chipName, rowName, val);
    val.audio = file;
    console.log("ST+", val);
    setAudioFileToChips(chipName, rowName, val);
  };

  const checkTextValue = (codeName) => {
    let codeValue = investigation.filter((row1) => {
      return row1.name == codeName
    });
    let output = "";
    if (codeValue.length > 0){
      if ( codeValue[0].voiceNotes ){
        // console.log("codeValue", codeValue[0].voiceNotes);
        output = codeValue[0].voiceNotes  ? codeValue[0].voiceNotes.notes : ""
      }
    }
    return output;
  }

  const onGlobalChipText = (e, row, chipText) => {
    if (investigation.length > 0) {
      for (let i = 0; i < investigation.length; i++) {
        if (investigation[i].name === row) {
          investigation[i].voiceNotes.notes = e.target.value;
          dispatch({
            field: "investigation",
            value: investigation,
          });
        }
      }
    }else{
      let obj = {
        name: row,
        chips: [],
        Texts: [],
        voiceNotes: {
          audio: "",
          notes: e.target.value
        },
      };
      dispatch({
        field: "investigation",
        value: [obj],
      });
    }
  };

  return (
    <>
      {props.form.map((row) => {
        return (
          <div
            className="row"
            style={styles.ROSRowPadding}
            onClick={() => onRowClick(row.name)}
          >
            <div className="col-md-12 col-sm-12 col-12">
              {clickedRow.includes(row.name) ? (
                <>
                  <div
                    style={{
                      fontWeight: "400",
                      color: "#939393",
                    }}
                  >
                    {row.name}
                  </div>

                  {row.chips && row.chips.length > 0 ? (
                    <div style={{ marginTop: 10 }}>
                      {row.chips.map((val) => {
                        let Icon;
                        let Style = {
                          color: "#070707",
                          backgroundColor: "#F5F5F5",
                        };
                        let chipIncluded = false;

                        if (val.name) {
                          if (val.name.includes("Add")) {
                            Icon = <AddIcon style={{ color: "#FF6F91" }} />;
                            Style = {
                              color: "#FF6F91",
                              backgroundColor: "#FFE2E9",
                              borderRadius: "25px",
                            };
                          }

                          investigation.map((row1) => {
                            if (row1.name === row.name) {
                              if (row1.chips && row1.chips.length > 0) {
                                row1.chips.map((chip) => {
                                  if (chip.name === val.name) {
                                    if (val.name.includes("Add")) {
                                      Icon = (
                                        <AddIcon style={{ color: "white" }} />
                                      );
                                      Style = {
                                        color: "white",
                                        backgroundColor: "#FF6F91",
                                        borderRadius: "25px",
                                      };
                                    } else {
                                      Icon = (
                                        <DoneIcon style={{ color: "white" }} />
                                      );
                                      Style = {
                                        color: "white",
                                        backgroundColor: "rgb(19 213 159)",
                                      };
                                      chipIncluded = true;
                                    }
                                  }
                                });
                              }
                            }
                          });
                        }

                        return (
                          <>
                            {chipIncluded ? (
                              <>
                                {val.detail === "" ||
                                (
                                  val.detail && val.detail.length > 0 ||
                                  val.subChipsItems && val.subChipsItems.length > 0
                                ) ? (
                                  <hr />
                                ) : (
                                  undefined
                                )}
                              </>
                            ) : (
                              undefined
                            )}

                            <span style={styles.chipAlignment}>
                              {val.name && val.name.includes("Add") ? (
                                <Button
                                  variant="contained"
                                  component="label"
                                  style={Style}
                                >
                                  {Icon}
                                  {val.name}
                                  <input
                                    type="file"
                                    accept=".png,.PNG,.peg,.PEG,.jpeg,.jpg"
                                    multiple
                                    name="document"
                                    onChange={(e) =>
                                      onDocumentUpload(
                                        e,
                                        val.name,
                                        row.name,
                                        val
                                      )
                                    }
                                    onClick={(event) => {
                                      event.target.value = null;
                                    }}
                                    style={{ display: "none" }}
                                  />
                                </Button>
                              ) : (
                                <Chip
                                  label={val.name}
                                  onClick={() =>
                                    handleChipClick(val.name, row.name, val)
                                  }
                                  icon={Icon}
                                  style={Style}
                                />
                              )}
                            </span>

                            {chipIncluded ? (
                              <>
                                {val.detail === "" ||
                                (val.detail && val.detail.length > 0) ? (
                                  <>
                                    <div
                                      className={`${"row"} ${classes.root}`}
                                      style={{
                                        marginTop: 15,
                                        marginBottom: 15,
                                      }}
                                    >
                                      <div
                                        className={
                                          val.subChips &&
                                          val.subChips.length > 0
                                            ? "col-md-8 col-sm-8 col-8"
                                            : "col-md-12 col-sm-12 col-12"
                                        }
                                      >
                                        <div className="row">
                                          <div className={
                                            val.subChips &&
                                            val.subChips.length > 0
                                              ? "col-md-4"
                                              : "col-md-3"
                                          }>
                                            <div className="d-flex align-items-center">
                                              <div
                                                style={{
                                                  marginRight: matches ? 15 : 7,
                                                  backgroundColor: (isRecording && (recordingMode === (row.name + "_" + val.name))) ? "#DC004E" : "#2C6DDD",
                                                  height: 55,
                                                  borderRadius: 30,
                                                  maxWidth: 55,
                                                  flex: "0 0 55px",
                                                  paddingLeft: matches ? 10 : 8,
                                                  cursor: 'pointer'
                                                }}
                                              >

                                                {isRecording && (recordingMode === (row.name + "_" + val.name)) ? (
                                                  <StopIcon
                                                    style={{ marginTop: matches ? 11 : 9, color: "white" }}
                                                    fontSize="large"
                                                    onClick={(name, field) => {
                                                      console.log("Recording: stop", row.name, val.name);
                                                      Mp3Recorder.stop()
                                                        .getMp3()
                                                        .then(([buffer, blob]) => {
                                                          const blobURLFinal = URL.createObjectURL(blob);
                                                          let blobURLx = blobURL;
                                                          blobURLx[(row.name + "_" + val.name)] = blobURLFinal;
                                                          dispatch({ field: "blobURL", value: blobURLx });
                                                          dispatch({ field: "isRecording", value: false });
                                                          dispatch({ field: "recordingMode", value: "" });
                                                          var file = new File([blob], "first", { type: "audio/mp3" });
                                                          // dispatch({ field: "fileAudio", value: file });
                                                          onRecordAudioFinish(file, val.name, row.name, val)
                                                        })
                                                        .catch((e) => console.log(e));
                                                    }}
                                                    disabled={!isRecording}
                                                    color="secondary"
                                                  />
                                                ) : (
                                                  <MicIcon
                                                    style={{ marginTop: matches ? 12 : 10, color: "white" }}
                                                    fontSize="large"
                                                    onClick={(e) => {
                                                      if (isBlocked) {
                                                        alert("Your browser doesn't have permission to record Audio!");
                                                      } else {
                                                        console.log("Recording: start", row.name, val.name);
                                                        Mp3Recorder.start()
                                                          .then(() => {
                                                            dispatch({ field: "isRecording", value: true });
                                                            dispatch({ field: "recordingMode", value: `${row.name}_${val.name}` });
                                                          })
                                                          .catch((e) => console.error(e));
                                                      }
                                                    }}
                                                    disabled={isRecording && recordingMode === `${row.name}_${val.name}`}
                                                    color="primary"
                                                  />
                                                )}
                                              </div>


                                              {isRecording && (recordingMode === (row.name + "_" + val.name)) ? (
                                                <div
                                                  style={{
                                                    marginLeft: matches ? 110 : 18,
                                                    marginRight: matches ? 110 : 75,
                                                    width: matches ? 550 : 100,
                                                  }}
                                                >
                                                  <Loader
                                                    type="ThreeDots"
                                                    color="blue"
                                                    height={40}
                                                    width={40}
                                                  />
                                                </div>
                                              ) : (
                                                <audio
                                                  style={{
                                                    marginRight: 10,
                                                    width: matches ? 450 : 260,
                                                    marginTop: 6,
                                                  }}
                                                  src={blobURL[(row.name + "_" + val.name)]}
                                                  controls="controls"
                                                />
                                              )}
                                            </div>
                                          </div>
                                          <div className={
                                            val.subChips &&
                                            val.subChips.length > 0
                                              ? "col-md-8"
                                              : "col-md-9"
                                          }>
                                            <TextField
                                              type="text"
                                              label="Text.."
                                              value={val.detail}
                                              onChange={(e) =>
                                                onChipText(e, row.name, val)
                                              }
                                              rows={4}
                                              className="textInputStyle"
                                              variant="filled"
                                              InputProps={{
                                                className: classes.input,
                                                classes: { input: classes.input },
                                                disableUnderline: true,
                                              }}
                                            />
                                          </div>
                                        </div>
                                      </div>

                                      {val.right && val.right.length > 0 ? (
                                        <div
                                          className="col-md-12 col-sm-12 col-12"
                                          style={{ marginTop: 10 }}
                                        >
                                          <div className="row">
                                            <div className="col-md-1 col-sm-1 col-1 d-flex">
                                              <h5>R</h5>
                                            </div>
                                            {val.right.map((textVal) => {
                                              return (
                                                <div
                                                  className="col-md-3 col-sm-3 col-11"
                                                  style={{
                                                    padding: "5px 0px 5px 15px",
                                                  }}
                                                >
                                                  <TextField
                                                    type="text"
                                                    label={textVal.name}
                                                    value={textVal.value}
                                                    onChange={(e) =>
                                                      onChipSubText(
                                                        e,
                                                        row.name,
                                                        textVal,
                                                        "right"
                                                      )
                                                    }
                                                    rows={4}
                                                    className="textInputStyle"
                                                    variant="filled"
                                                    InputProps={{
                                                      className: classes.input,
                                                      classes: {
                                                        input: classes.input,
                                                      },
                                                      disableUnderline: true,
                                                    }}
                                                  />
                                                </div>
                                              );
                                            })}
                                          </div>
                                        </div>
                                      ) : (
                                        undefined
                                      )}

                                      {val.left && val.left.length > 0 ? (
                                        <div
                                          className="col-md-12 col-sm-12 col-12"
                                          style={{ marginTop: 10 }}
                                        >
                                          <div className="row">
                                            <div className="col-md-1 col-sm-1 col-1 d-flex">
                                              <h5>L</h5>
                                            </div>
                                            {val.left.map((textVal) => {
                                              return (
                                                <div
                                                  className="col-md-3 col-sm-3 col-11"
                                                  style={{
                                                    padding: "5px 0px 5px 15px",
                                                  }}
                                                >
                                                  <TextField
                                                    type="text"
                                                    label={textVal.name}
                                                    value={textVal.value}
                                                    onChange={(e) =>
                                                      onChipSubText(
                                                        e,
                                                        row.name,
                                                        textVal,
                                                        "left"
                                                      )
                                                    }
                                                    rows={4}
                                                    className="textInputStyle"
                                                    variant="filled"
                                                    InputProps={{
                                                      className: classes.input,
                                                      classes: {
                                                        input: classes.input,
                                                      },
                                                      disableUnderline: true,
                                                    }}
                                                  />
                                                </div>
                                              );
                                            })}
                                          </div>
                                        </div>
                                      ) : (
                                        undefined
                                      )}

                                      {val.name === "See Diagram" ||
                                      val.name === "Decreased Pulse(s)" ? (
                                        <span className="col-md-12 col-sm-12 col-12 d-flex justify-content-center" style={{
                                          marginTop: '45px',
                                          marginBottom: '35px'
                                        }}>
                                          {/* <img
                                            alt=""
                                            src={Diagram}
                                            className='diagram'
                                          /> */}
                                          <ImageMarker
                                            src={Diagram}
                                            markerComponent={CustomMarker}
                                            markers={
                                              val.name === "Decreased Pulse(s)"
                                                ? markers
                                                : markersForSeeDiagram
                                            }
                                            onAddMarker={(marker) =>
                                              val.name === "Decreased Pulse(s)"
                                                ? setMarkers([
                                                    ...markers,
                                                    marker,
                                                  ])
                                                : setMarkersForSeeDiagram([
                                                    ...markersForSeeDiagram,
                                                    marker,
                                                  ])
                                            }
                                          />
                                        </span>
                                      ) : (
                                        undefined
                                      )}

                                      {val.name === "See Diagram" ||
                                      val.name === "Decreased Pulse(s)" ? (
                                        <div className="col-md-12 col-sm-12 col- d-flex justify-content-center">
                                          <Button
                                            // disabled={investigation.length > 0 ? false : true}
                                            onClick={() =>
                                              val.name === "Decreased Pulse(s)"
                                                ? setMarkers([])
                                                : setMarkersForSeeDiagram([])
                                            }
                                            style={{
                                              backgroundColor: "#13D59F",
                                              width: "115px",
                                            }}
                                            variant="contained"
                                            color="default"
                                          >
                                            Reset
                                          </Button>
                                          &nbsp;&nbsp;
                                          <Button
                                            // disabled={investigation.length > 0 ? false : true}
                                            onClick={() =>
                                              val.name === "Decreased Pulse(s)"
                                                ? setMarkers(
                                                    markers.filter(
                                                      (marker, index) =>
                                                        index !==
                                                        markers.length - 1
                                                    )
                                                  )
                                                : setMarkersForSeeDiagram(
                                                    markersForSeeDiagram.filter(
                                                      (marker, index) =>
                                                        index !==
                                                        markersForSeeDiagram.length -
                                                          1
                                                    )
                                                  )
                                            }
                                            style={styles.save}
                                            variant="contained"
                                            color="default"
                                          >
                                            Correction
                                          </Button>
                                        </div>
                                      ) : (
                                        undefined
                                      )}

                                      {val.subChips &&
                                      val.subChips.length > 0 ? (
                                        <span className="col-md-4 col-sm-4 col-4">
                                          {val.subChips.map((subChip) => {
                                            let subchipIcon;
                                            let subchipStyle = {
                                              color: "#070707",
                                              backgroundColor: "#F5F5F5",
                                            };

                                            investigation.map((row2) => {
                                              if (row2.name === row.name) {
                                                if (
                                                  row2.chips &&
                                                  row2.chips.length > 0
                                                ) {
                                                  row2.chips.map((chip) => {
                                                    if (
                                                      chip.name === val.name
                                                    ) {
                                                      if (
                                                        chip.subChips &&
                                                        chip.subChips.length > 0
                                                      ) {
                                                        chip.subChips.map(
                                                          (subChips) => {
                                                            if (
                                                              subChips.name ===
                                                                subChip.name &&
                                                              subChip.selected
                                                            ) {
                                                              subchipIcon = (
                                                                <DoneIcon
                                                                  style={{
                                                                    color:
                                                                      "#16D5A0",
                                                                  }}
                                                                />
                                                              );
                                                              subchipStyle = {
                                                                color:
                                                                  "#16D5A0",
                                                                backgroundColor:
                                                                  "#D0F7EC",
                                                              };
                                                            }
                                                          }
                                                        );
                                                      }
                                                    }
                                                  });
                                                }
                                              }
                                            });
                                            return (
                                              <span
                                                style={{
                                                  ...styles.chipAlignment,
                                                  padding: "10px",
                                                }}
                                              >
                                                <Chip
                                                  label={subChip.name}
                                                  onClick={() =>
                                                    handleSubChipClick(
                                                      subChip,
                                                      row.name,
                                                      val
                                                    )
                                                  }
                                                  icon={subchipIcon}
                                                  style={subchipStyle}
                                                />
                                              </span>
                                            );
                                          })}
                                        </span>
                                      ) : (
                                        undefined
                                      )}
                                    </div>
                                  </>
                                ) : //code for subchips dropdown
                                val.subChipsItems ? (
                                  <div>
                                    <Select
                                      id={val.name + "Id"}
                                      multiple
                                      fullWidth
                                      value={val.defaultValue}
                                      input={<Input />}
                                      MenuProps={MenuProps}
                                      className="dropDownStyle"
                                      variant="filled"
                                      renderValue={(selected) => (
                                        <div className={classes.chips}>
                                          {selected.map((value) => (
                                            <Chip key={value} label={value} className={classes.chip} />
                                          ))}
                                        </div>
                                      )}
                                      InputProps={{
                                        className: classes.input,
                                        classes: { input: classes.input },
                                        disableUnderline: true,
                                      }}
                                      onChange={(e) =>
                                        handleDropdownChange(
                                          val.setter,
                                          e.target.value,
                                          val.subChipsItems,
                                          row.name,
                                          val.name
                                        )
                                      }
                                    >
                                      {val.subChipsItems.map((item) => (
                                        <MenuItem
                                          key={item.code}
                                          value={item.name}
                                        >
                                          {item.name}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                    <hr />
                                  </div>
                                ) : (
                                  undefined
                                )}
                              </>
                            ) : (
                              undefined
                            )}

                            {val.image && val.image.length > 0 ? (
                              <span className="imageContainer">
                                {val.image.map((view, index) => {
                                  return (
                                    <div key={index}>
                                      <img
                                        alt=""
                                        key={index}
                                        src={view}
                                        onClick={() =>
                                          openImageViewer(index, val.image)
                                        }
                                        className="reportImage"
                                      />
                                      <IoIosCloseCircle
                                        onClick={() =>
                                          onImageDelete(
                                            index,
                                            val.name,
                                            row.name,
                                            val
                                          )
                                        }
                                        size={30}
                                        className="closeIcon"
                                      />
                                    </div>
                                  );
                                })}
                              </span>
                            ) : (
                              undefined
                            )}

                            {isViewerOpen && (
                              <ImageViewer
                                src={imagesDisplay}
                                currentIndex={currentImage}
                                onClose={closeImageViewer}
                                backgroundStyle={{
                                  backgroundColor: "rgba(0,0,0,0.9)",
                                }}
                              />
                            )}
                          </>
                        );
                      })}
                    </div>
                  ) : (
                    undefined
                  )}

                  {row.name === "Physical Exam" ? (
                    <div className="row">
                      <div className="col-md-5 col-sm-3 col-1"></div>
                      <div
                        className="col-md-7 col-sm-9 col-11"
                        style={{ height: "60px" }}
                      >
                        <SliderComponent
                          scale={10}
                          top={20}
                          bottom={0}
                          left={
                            matches && matchesForTablet
                              ? 10
                              : matches && !matchesForTablet
                              ? 10
                              : 10
                          }
                          right={0}
                          onSlide={(e) =>
                            handlePainScale(e, row.name, row.scale)
                          }
                          progressValue={painScale}
                        />
                      </div>
                      <div className="col-md-12 col-sm-12 col-12 d-flex justify-content-center">
                        <span style={{ color: "gray", paddingLeft: "40px" }}>
                          {row.scale.name}
                        </span>
                      </div>
                    </div>
                  ) : (
                    undefined
                  )}


                  {row.Texts && row.Texts.length > 0 ? (
                    <div
                      className={`${"row"} ${classes.root}`}
                      style={{ marginTop: 10 }}
                    >
                      {row.Texts.map((textVal) => {
                        return (
                          <div
                            className={
                              row.Texts.length > 1
                                ? "col-md-6 col-sm-6 col-6"
                                : "col-md-12 col-sm-12 col-12"
                            }
                            style={
                              row.Texts.length > 1
                                ? { padding: "5px 0px 5px 15px" }
                                : { padding: "5px 15px 5px 15px" }
                            }
                          >
                            <TextField
                              type="text"
                              label={textVal.name}
                              value={textVal.value}
                              onChange={(e) =>
                                onTextChange(e, row.name, textVal)
                              }
                              rows={4}
                              className="textInputStyle"
                              variant="filled"
                              InputProps={{
                                className: classes.input,
                                classes: { input: classes.input },
                                disableUnderline: true,
                              }}
                            />
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    undefined
                  )}

                  {
                    row.voiceNotes ? (
                      <div className="row mt-4">
                        <div className="col-md-4">
                          <div className="d-flex align-items-center">
                            <div
                              className="text-center"
                              style={{
                                marginRight: matches ? 15 : 7,
                                backgroundColor: (isRecording && (recordingMode === (row.name + "_" + "voiceNotes"))) ? "#DC004E" : "#2C6DDD",
                                height: 50,
                                borderRadius: 30,
                                width: 50,
                                cursor: 'pointer',
                                flex: '0 0 50px'
                              }}
                            >

                              {isRecording && (recordingMode === (row.name + "_" + "voiceNotes")) ? (
                                <StopIcon
                                  style={{ marginTop: matches ? 11 : 9, color: "white" }}
                                  fontSize="large"
                                  onClick={(name, field) => {
                                    console.log("Recording: stop", row.name, "voiceNotes");
                                    Mp3Recorder.stop()
                                      .getMp3()
                                      .then(([buffer, blob]) => {
                                        const blobURLFinal = URL.createObjectURL(blob);
                                        let blobURLx = blobURL;
                                        blobURLx[(row.name + "_" + "voiceNotes")] = blobURLFinal;
                                        dispatch({ field: "blobURL", value: blobURLx });
                                        dispatch({ field: "isRecording", value: false });
                                        dispatch({ field: "recordingMode", value: "" });
                                        var file = new File([blob], "first", { type: "audio/mp3" });
                                        // dispatch({ field: "fileAudio", value: file });
                                        onRecordAudioFinishGlobal(file, "voiceNotes", row.name, row)
                                      })
                                      .catch((e) => console.log(e));
                                  }}
                                  disabled={!isRecording}
                                  color="secondary"
                                />
                              ) : (
                                <MicIcon
                                  style={{ marginTop: matches ? 12 : 10, color: "white" }}
                                  fontSize="large"
                                  onClick={(e) => {
                                    if (isBlocked) {
                                      alert("Your browser doesn't have permission to record Audio!");
                                    } else {
                                      console.log("Recording: start", row.name, "voiceNotes");
                                      Mp3Recorder.start()
                                        .then(() => {
                                          dispatch({ field: "isRecording", value: true });
                                          dispatch({ field: "recordingMode", value: `${row.name}_voiceNotes` });
                                        })
                                        .catch((e) => console.error(e));
                                    }
                                  }}
                                  disabled={isRecording && recordingMode === `${row.name}_voiceNotes`}
                                  color="primary"
                                />
                              )}
                            </div>


                            {isRecording && (recordingMode === (row.name + "_" + "voiceNotes")) ? (
                              <div
                                style={{
                                  marginLeft: matches ? 110 : 18,
                                  marginRight: matches ? 110 : 75,
                                  width: matches ? 550 : 100,
                                }}
                              >
                                <Loader
                                  type="ThreeDots"
                                  color="blue"
                                  height={40}
                                  width={40}
                                />
                              </div>
                            ) : (
                              <audio
                                style={{
                                  marginRight: 10,
                                  width: matches ? 450 : 260,
                                  marginTop: 6,
                                }}
                                src={blobURL[(row.name + "_" + "voiceNotes")]}
                                controls="controls"
                              />
                            )}
                          </div>
                        </div>
                        <div className="col-md-8">
                          {
                            <TextField
                              type="text"
                              label="Notes ..."
                              value={ checkTextValue(row.name) }
                              onChange={(e) =>
                                onGlobalChipText(e, row.name, row.voiceNotes.notes)
                              }
                              rows={4}
                              className="textInputStyle"
                              variant="filled"
                              InputProps={{
                                className: classes.input,
                                classes: {
                                  input: classes.input,
                                  adornedEnd: classes.root,
                                },
                                disableUnderline: true,
                              }}
                            />
                          }
                        </div>
                      </div>
                    ) : undefined
                  }
                </>
              ) : (
                <div
                  style={{
                    paddingTop: 10,
                    paddingBottom: 10,
                    cursor: "pointer",
                  }}
                >
                  <span style={{ fontWeight: "bold", color: "#939393" }}>
                    {row.name}
                  </span>
                </div>
              )}
            </div>
          </div>
        );
      })}

      <div
        className="row"
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "30px",
          marginBottom: "2%",
        }}
      >
        <div className="col-md-6 col-sm-6 col-6">
          <img
            onClick={() => props.history.goBack()}
            src={Back}
            style={{
              width: 45,
              height: 35,
              cursor: "pointer",
            }}
          />
        </div>
        <div className="col-md-6 col-sm-6 col-6 d-flex justify-content-end">
          <Button
            disabled={investigation.length > 0 ? false : true}
            onClick={() => props.handleSave(investigation, [], [], skinreport)}
            style={
              clickedRow.length > 0
                ? styles.save
                : { ...styles.save, backgroundColor: "#13D59F" }
            }
            variant="contained"
            color="default"
          >
            Save
          </Button>
        </div>
      </div>
    </>
  );
}
