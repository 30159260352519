/*eslint-disable*/
import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Notification from "../../components/Snackbar/Notification.js";
import CustomTable from "../../components/Table/Table";
import ConfirmationModal from "../../components/Modal/confirmationModal";
import Header from "../../components/Header/Header";
import careStreamList from "../../assets/img/carestream/CareStream.png";
import View from "../../assets/img/carestream/View White.png";
import Back from "../../assets/img/Back_Arrow.png";
import "../../assets/jss/material-dashboard-react/components/loaderStyle.css";
import { makeStyles } from "@material-ui/core/styles";
import { careStreamInprogressStyles } from "./styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {
  getCSInProgress,
  searchPatientCS,
} from "../CareStreamInProgress/NetworkCalls";
import TextField from "@material-ui/core/TextField";
import BarCode from "../../assets/img/Bar Code.png";

import InputAdornment from "@material-ui/core/InputAdornment";
import AccountCircle from "@material-ui/icons/SearchOutlined";

import Fingerprint from "../../assets/img/fingerprint.png";
import Filter from "../../assets/img/Filter.png";
import QRCodeScannerComponent from "../../components/QRCodeScanner/QRCodeScanner";

const careStreamHeading = [
  "MRN No",
  "Patient Name",
  "Production Area",
  "Date/Time",
  "CareStream",
  "Status",
  " ",
];
const careStreamKeys = [
  "mrn",
  "patientName",
  "productionArea",
  "createdAt",
  "careStreams",
  "careStatus",
];
const careStreamHeadingForDashboard = [
  "CareStream ID",
  "CareStream Name",
  "Production Area",
  "Date/Time",

  " ",
];
const careStreamKeysForDashboard = [
  "CSId",
  "careStreams",
  "productionArea",
  "createdAt",
];
const actions = { view: false };

const styles = {
  stylesForButton: {
    color: "white",
    cursor: "pointer",
    // borderRadius: 5,
    background: "#2c6ddd",
    // width: "140px",
    height: "50px",
    outline: "none",
  },
};
const useStyles = makeStyles((theme) => ({
  input: {
    "&:focus": {
      backgroundColor: "white",
      boxShadow: "none",
      borderRadius: 5,
    },
  },
  root: {
    "& .MuiTextField-root": {
      backgroundColor: "white",
    },
    "& .Mui-focused": {
      backgroundColor: "white",
      color: "black",
    },
    "& .Mui-disabled": {
      backgroundColor: "white",
      color: "gray",
    },
    "& .MuiFormLabel-root": {
      fontSize: "11px",
      paddingRight: "45px",
    },
    "& .MuiFilledInput-adornedEnd": {
      backgroundColor: "#fff",
    },
  },
}));

export default function Staff(props) {
  const matches = useMediaQuery("(min-width:600px)");
  const classes = careStreamInprogressStyles();
  const classes1 = useStyles();
  const [careStreamData, setCareStreamData] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState("");
  const [openNotification, setOpenNotification] = useState(false);
  const [dashboardScreen, setDashboardScreen] = useState(" ");
  const [searchPatientQuery, setSearchPatientQuery] = useState("");
  const [QRCodeScanner, setQRCodeScanner] = useState(false);
  if (openNotification) {
    setTimeout(() => {
      setOpenNotification(false);
      setErrorMsg("");
    }, 2000);
  }

  useEffect(() => {
    if (props.history.location.state.dashboardCare === false) {
      setDashboardScreen(true);
    } else setDashboardScreen(false);
    console.log(props.history.location.state.dashboardCare, "dashboardCare  ");
    getCSInProgress(
      setLoading,
      setCareStreamData,
      setErrorMsg,
      setOpenNotification
    );
  }, []);

  const handlePatientSearch = (e) => {
    const a = e.target.value.replace(/[^\w\s]/gi, "");
    setSearchPatientQuery(a);
    if (a.length >= 3) {
      searchPatientCS(a, setCareStreamData, setErrorMsg, setOpenNotification);
    } else if (a.length == 0) {
      getCSInProgress(
        setLoading,
        setCareStreamData,
        setErrorMsg,
        setOpenNotification
      );
    }
  };

  function scanQRCode() {
    setQRCodeScanner((pervState) => !pervState);
  }

  function handleScanQR(data) {
    setQRCodeScanner(false);
    console.log(JSON.parse(data));
    if (JSON.parse(data).profileNo) {
      handlePatientSearch({
        target: {
          value: JSON.parse(data).profileNo,
          type: "text",
        },
      });
    }
  }

  if (QRCodeScanner) {
    return (
      <div>
        {QRCodeScanner ? (
          <QRCodeScannerComponent
            handleScanQR={handleScanQR}
            scanQRCode={scanQRCode}
          />
        ) : (
          undefined
        )}
      </div>
    );
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        position: "fixed",
        width: "100%",
        height: "100%",
        backgroundColor: "#60d69f",
        overflowY: "scroll",
      }}
    >
      <Header history={props.history} />

      <div className="cPadding">
        <div className="subheader">
          {/* <div
            className="row"
            style={{
              display: "flex",
              flex: 1,
              justifyContent: "center",
              paddingLeft: 6,
              paddingRight: 6,
            }}
          > */}
          <div>
            <img src={careStreamList} />
            {dashboardScreen ? (
              <h4>CareStreams Currently in Process</h4>
            ) : (
              <h4>CareStream List</h4>
            )}
          </div>
          {!dashboardScreen ? (
            <div
            // className="col-md-12 col-sm-12"
            // style={{
            //   display: "flex",
            //   flex: 1,
            //   marginTop: "2%",
            //   marginBottom: "2%",
            // }}
            >
              <Button
                onClick={() => props.history.goBack()}
                className={{
                  ...classes.careStream,
                  width: matches ? 220 : 160,
                  height: matches ? 45 : 40,
                  backgroundColor: "#2973CF",
                }}
                variant="contained"
                color="primary"
              >
                <img
                  src={View}
                  style={{
                    width: matches ? 40 : 20,
                    height: matches ? 40 : 20,
                  }}
                />
                &nbsp;
                <strong style={{ fontSize: matches ? " " : 9 }}>
                  View All CareStream
                </strong>
              </Button>
            </div>
          ) : (
            " "
          )}

          {/* </div> */}
        </div>

        <div
          style={{
            flex: 4,
            display: "flex",
            flexDirection: "column",
          }}
        >
          {dashboardScreen ? (
            <div
              className={`${"container-fluid"}`}
              style={{
                marginTop: "25px",
                paddingLeft: "10px",
                paddingRight: "10px",
              }}
            >
              <div className={`row  ${classes1.root} ${classes1.input} `}>
                <div
                  className="col-md-10  col-8"
                  style={{ paddingTop: 5, paddingLeft: 3, paddingRight: 3 }}
                >
                  <TextField
                    className="textInputStyle"
                    id="searchPatientQuery"
                    type="text"
                    variant="filled"
                    label={
                      matches
                        ? "Search by CareStean ID/CareStream Name / Production Area"
                        : "Search by CareStean ID/ P. Area "
                    }
                    name={"searchPatientQuery"}
                    value={searchPatientQuery}
                    onChange={handlePatientSearch}
                    InputLabelProps={{
                      classes: {
                        root: classes.label,
                        focused: classes.focusedLabel,
                        error: classes.erroredLabel,
                      },
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <AccountCircle />
                        </InputAdornment>
                      ),
                      className: classes.input,
                      classes: { input: classes.input },
                      disableUnderline: true,
                    }}
                  />
                </div>

                <div
                  className="col-md-1 col-sm-2 col-2"
                  style={{
                    ...styles.textFieldPadding,
                    paddingTop: 5,
                    paddingLeft: 3,
                    paddingRight: 3,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "white",
                      borderRadius: 5,
                      height: 55,
                    }}
                  >
                    <img
                      src={BarCode}
                      onClick={scanQRCode}
                      style={{ width: 70, height: 60 }}
                    />
                  </div>
                </div>

                <div
                  className="col-md-1 col-sm-1 col-2"
                  style={{
                    ...styles.textFieldPadding,
                    paddingTop: 5,
                    paddingLeft: 3,
                    paddingRight: 3,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "white",
                      borderRadius: 5,
                      height: 55,
                    }}
                  >
                    <img
                      src={Fingerprint}
                      style={{ maxWidth: 43, height: 43 }}
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : null}
          <div className="container-fluid">
            <div className="row">
              <CustomTable
                tableData={careStreamData}
                tableDataKeys={
                  dashboardScreen ? careStreamKeysForDashboard : careStreamKeys
                }
                tableHeading={
                  dashboardScreen
                    ? careStreamHeadingForDashboard
                    : careStreamHeading
                }
                action={actions}
                handleView={() => console.log("view")}
                borderBottomColor={"#60d69f"}
                borderBottomWidth={20}
              />
              <Notification msg={errorMsg} open={openNotification} />
            </div>
          </div>

          <ConfirmationModal
            modalVisible={modalVisible}
            msg="Are you sure want to delete the record?"
            hideconfirmationModal={() => setModalVisible(false)}
            onConfirmDelete={() => deleteVendor()}
            setdeleteItem={() => setdeleteItem("")}
          />
          <div className="container-fluid">
            <div className="row" style={{ marginBottom: 20 }}>
              <img
                onClick={() => props.history.goBack()}
                src={Back}
                style={{
                  width: 45,
                  height: 35,
                  cursor: "pointer",
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
