import React, { useEffect, useState, useReducer } from "react";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import cookie from "react-cookies";
import Header from "../../components/Header/Header";
import AssessIcon from "../../assets/img/Production Area.png";
import "../../assets/jss/material-dashboard-react/components/TextInputStyle.css";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import CustomTable from "../../components/Table/Table";
import Notification from "../../components/Snackbar/Notification.js";
import Button from "@material-ui/core/Button";
import axios from "axios";
import { AssignProductionArea } from "../../public/endpoins";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import styles from "./styles";
import useStyles from "../../assets/jss/material-dashboard-react/greyInputStyle";
import { connect } from "react-redux";
import {
  funForReducer,
  setPatientDetailsForReducer,
} from "../../actions/Checking";

const tableHeadingForProductionArea = [
  "Production Area ID",
  "Production Area",
  "Rooms",
  "No of Patients",
  "Action",
];
const tableDataKeysForProductionArea = ["id", "name", "rooms", "no"];
const tableHeadingForRooms = ["Room ID", "Room Number", "Status", "Action"];
const tableDataKeysForRooms = ["id", "roomNo", "status"];
const tableHeadingForBedCell = [
  "Room ID",
  "Room Number",
  "Bed Id",
  "Patient Name",
  "Patient MRN",
  "Status",
  "Action",
];
const tableDataKeysForBedCell = [
  "roomId",
  "roomNo",
  "bedId",
  "patientName",
  "mrn",
  "status",
];
const actions = { edit: true };

const useStylesForTabs = makeStyles({
  root: {
    justifyContent: "center",
  },
  scroller: {
    flexGrow: "0",
  },
});

function ViewAssignPA(props) {
  const matches = useMediaQuery("(min-width:600px)");
  const classes = useStyles();
  const classesForTabs = useStylesForTabs();

  const initialState = {
    patientDetails: {},

    ProductionAreaArray: "",
    RoomsArray: "",
    BedCellArray: "",
  };

  function reducer(state, { field, value }) {
    return {
      ...state,
      [field]: value,
    };
  }

  const [state, dispatch] = useReducer(reducer, initialState);

  const {
    patientDetails,
    ProductionAreaArray,
    RoomsArray,
    BedCellArray,
  } = state;

  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setsuccessMsg] = useState("");
  const [openNotification, setOpenNotification] = useState(false);
  const [value, setValue] = useState(0);
  const [selectedItem, setSelectedItem] = useState("");
  const [currentUser, setCurrentUser] = useState(cookie.load("current_user"));
  const [roomTab, setRoomTab] = useState(true);
  const [bedTab, setBedTab] = useState(true);
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    console.log("props", props);
    setSelectedItem(props.location.state.selectedItem);

    const object = {
      patientName: props.location.state.selectedItem.patientName,
      mrn: props.location.state.selectedItem.mrn,
      age: props.location.state.selectedItem.patientId.age,
      gender: props.location.state.selectedItem.patientId.gender,
      weight: props.location.state.selectedItem.patientId.weight,
    };
    dispatch({ field: "patientDetails", value: object });

    Object.entries(props.location.state.selectedItem).map(([key, val]) => {
      if (val && typeof val === "object") {
        if (key === "chiefComplaint") {
          const a = val[val.length - 1].chiefComplaintId.productionArea;
          a.map(
            (v) => (
              (v.id = v.productionAreaId && v.productionAreaId.paId),
              (v.name = v.productionAreaId && v.productionAreaId.paName),
              (v.rooms = v.productionAreaId && v.productionAreaId.rooms.length)
            )
          );
          dispatch({ field: "ProductionAreaArray", value: a });
        }
      }
    });
  }, []);

  console.log("selectedItem", selectedItem);
  console.log("patientDetails", patientDetails);

  console.log("productionArea", ProductionAreaArray);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === 0) {
      setBedTab(true);
      setRoomTab(true);
    }
  };

  function handlePA(rec) {
    setOpen(true);
  }
  console.log("RoomsArray", RoomsArray);

  function handleRoom(rec) {
    if (value === 0) {
      setValue(1);
    } else if (value === 1) {
      setValue(2);
    }
    setBedTab(false);
    setRoomTab(true);
    console.log("rec", rec);
    Object.entries(rec.roomId).map(([key, val]) => {
      console.log("val", rec.roomId);
      if (val && typeof val === "object") {
        if (key === "beds") {
          const a = val;
          a.map(
            (v) => (
              (v.bedId = v.bedId),
              (v.status =
                rec.roomId.availability === false ? "Occopied" : "Vacant"),
              (v.roomNo = rec.roomId.roomNo),
              (v.roomId = rec.roomId.roomId),
              (v.room_id = rec.roomId._id)
            )
          );
          dispatch({ field: "BedCellArray", value: a });
        }
      }
    });
  }

  console.log("bedCellArray", BedCellArray);

  function handleAssig(rec) {
    console.log("record", rec);
    let params = {
      staffId: currentUser._id,
      edrId: selectedItem.patientId._id,
      roomId: rec.room_id,
      // bedId: rec._id,
      // availability: rec.status === "Vacant" ? true : false
    };
    console.log("params", params);

    axios
      .put(AssignProductionArea, params)
      .then((res) => {
        console.log("response", res.data);
        if (res.data.success) {
          console.log("success");
          // window.location.reload(false)
        } else if (!res.data.success) {
          setOpenNotification(true);
          setErrorMsg(res.data.error);
          // setErrorMsg('Bed is already assigned')
        }
        return res;
      })
      .catch((e) => {
        console.log("error: ", e);
      });
  }

  if (openNotification) {
    setTimeout(() => {
      setOpenNotification(false);
      setErrorMsg("");
      setsuccessMsg("");
    }, 2000);
  }

  return (
    <div
      style={{
        backgroundColor: "rgb(19 213 159)",
        position: "fixed",
        display: "flex",
        width: "100%",
        height: "100%",
        flexDirection: "column",
        flex: 1,
        overflowY: "scroll",
      }}
    >
      <Header history={props.history} />
      <div className="cPadding">
        <div className="subheader" style={{ marginLeft: "-10px" }}>
          <div>
            <img src={AssessIcon} />

            <h4>Manage Production Area</h4>
          </div>
        </div>

        <div>
          <div
            style={{
              height: "20px",
            }}
          />
          <div className={classesForTabs.root}>
            <Tabs
              classes={{
                root: classesForTabs.root,
                scroller: classesForTabs.scroller,
              }}
              value={value}
              onChange={handleChange}
              textColor="primary"
              TabIndicatorProps={{ style: { background: "#12387a" } }}
              centered={false}
              variant="scrollable"
              fullWidth={true}
            >
              <Tab
                style={{
                  color: "white",
                  borderRadius: 5,
                  outline: "none",
                  color: value === 0 ? "#12387a" : "#3B988C",
                }}
                label="Production Area"
              />
              <Tab
                style={{
                  color: "white",
                  borderRadius: 5,
                  outline: "none",
                  color: value === 1 ? "#12387a" : "#3B988C",
                }}
                label="Rooms"
                disabled={roomTab}
              />
              <Tab
                style={{
                  color: "white",
                  borderRadius: 5,
                  outline: "none",
                  color: value === 2 ? "#12387a" : "#3B988C",
                }}
                label="Bed Cell"
                disabled={bedTab}
              />
            </Tabs>
          </div>

          {value === 0 ? (
            <div
              style={{ flex: 4, display: "flex", flexDirection: "column" }}
              className="container-fluid"
            >
              <div className="row">
                {ProductionAreaArray !== 0 ? (
                  <CustomTable
                    tableData={ProductionAreaArray}
                    tableDataKeys={tableDataKeysForProductionArea}
                    tableHeading={tableHeadingForProductionArea}
                    handleEdit={handlePA}
                    action={actions}
                    borderBottomColor={"#60d69f"}
                    borderBottomWidth={20}
                  />
                ) : (
                  undefined
                )}
              </div>
            </div>
          ) : value === 1 ? (
            <div
              style={{ flex: 4, display: "flex", flexDirection: "column" }}
              className=" container-fluid"
            >
              <div className="row">
                {RoomsArray !== 0 ? (
                  <CustomTable
                    tableData={RoomsArray}
                    tableDataKeys={tableDataKeysForRooms}
                    tableHeading={tableHeadingForRooms}
                    handleEdit={handleRoom}
                    action={actions}
                    borderBottomColor={"#60d69f"}
                    borderBottomWidth={20}
                  />
                ) : (
                  undefined
                )}
              </div>
            </div>
          ) : value === 2 ? (
            <div
              style={{
                flex: 4,
                display: "flex",
                flexDirection: "column",
                paddingLeft: "10px",
                paddingRight: "10px",
              }}
              className={`container-fluid `}
            >
              <div
                className="row"
                style={{
                  paddingLeft: "5px",
                  paddingRight: "5px",
                }}
              >
                {BedCellArray !== 0 ? (
                  <CustomTable
                    tableData={BedCellArray}
                    tableDataKeys={tableDataKeysForBedCell}
                    tableHeading={tableHeadingForBedCell}
                    handleEdit={handleAssig}
                    action={actions}
                    borderBottomColor={"#60d69f"}
                    borderBottomWidth={20}
                  />
                ) : (
                  undefined
                )}
              </div>
            </div>
          ) : (
            undefined
          )}
        </div>

        <Notification
          msg={errorMsg}
          open={openNotification}
          success={successMsg}
        />
      </div>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={"lg"}
        fullWidth
      >
        <DialogContent>
          <div
            className={`${"container-fluid"} `}
            style={{
              backgroundColor: "white",
              padding: "20px 40px",
              marginTop: 20,
              borderRadius: 5,
            }}
          >
            <h4 style={{ color: "grey", fontWeight: "bold", marginLeft: -14 }}>
              Working Shifts
            </h4>

            <>
              <div
                className={`row ${classes.root}`}
                style={{
                  marginTop: "15px",
                  marginRight: "-20px",
                  marginLeft: "-20px",
                }}
              >
                <div
                  className="col-md-4 col-sm-4"
                  style={{
                    ...styles.inputContainerForTextField,
                    ...styles.textFieldPadding,
                  }}
                >
                  <TextField
                    required
                    select
                    label="Shift"
                    name={"shift"}
                    // value={shift}
                    // onChange={onChangeValue}
                    // error={shift === '' && detailsForm}
                    className="textInputStyle"
                    variant="filled"
                    InputProps={{
                      className: classes.input,
                      classes: { input: classes.input },
                    }}
                    inputProps={{ maxLength: 40 }}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>

                    {/* {shiftArray.map((val) => {
                        return (
                          <MenuItem
                            key={
                              val.key.charAt(0).toUpperCase() +
                              val.value.slice(1)
                            }
                            value={
                              val.key.charAt(0).toUpperCase() +
                              val.value.slice(1)
                            }
                          >
                            {val.value.charAt(0).toUpperCase() +
                              val.value.slice(1)}
                          </MenuItem>
                        )
                      })} */}
                  </TextField>
                </div>
                <div
                  className="col-md-4 col-sm-4"
                  style={{
                    ...styles.inputContainerForTextField,
                    ...styles.textFieldPadding,
                  }}
                >
                  <TextField
                    required
                    select
                    label="Select Chief Complaint Category"
                    name={"chiefComplaint"}
                    // value={chiefComplaint}
                    // error={
                    //   (chiefComplaint === '' ||
                    //     Array.isArray(chiefComplaint)) &&
                    //   detailsForm
                    // }
                    // onChange={changeHandler}
                    className="textInputStyle"
                    variant="filled"
                    InputProps={{
                      className: classes.input,
                      classes: { input: classes.input },
                    }}
                    inputProps={{ maxLength: 40 }}
                  >
                    {/* {chiefComplaintList.map((array) => {
                        return (
                          <MenuItem key={array._id} value={array._id}>
                            {array.name}
                          </MenuItem>
                        )
                      })} */}
                  </TextField>
                </div>
                <div
                  className="col-md-4 col-sm-4"
                  style={{
                    ...styles.inputContainerForTextField,
                    ...styles.textFieldPadding,
                  }}
                >
                  <TextField
                    required
                    select
                    label="Production Area"
                    name={"productionAreaName"}
                    // value={productionAreaName}
                    // onChange={changeHandlerProductionArea}
                    // error={
                    //   (productionAreaName === '' ||
                    //     Array.isArray(productionAreaName)) &&
                    //   detailsForm
                    // }
                    className="textInputStyle"
                    variant="filled"
                    InputProps={{
                      className: classes.input,
                      classes: { input: classes.input },
                    }}
                    inputProps={{ maxLength: 40 }}
                  >
                    {/* {productionAreaList.map((array) => {
                        return (
                          <MenuItem key={array._id} value={array._id}>
                            {array.paName}
                          </MenuItem>
                        )
                      })} */}
                  </TextField>
                </div>
              </div>

              <div
                className={`row ${classes.root}`}
                style={{
                  marginTop: "15px",
                  marginRight: "-20px",
                  marginLeft: "-20px",
                }}
              >
                <div
                  className="col-md-12 col-sm-12"
                  style={{
                    ...styles.inputContainerForTextField,
                    ...styles.textFieldPadding,
                  }}
                >
                  <TextField
                    required
                    label="Reason"
                    name={"reason"}
                    // value={reason}
                    // error={reason === '' && detailsForm}
                    // onChange={onChangeValue}
                    className="textInputStyle"
                    variant="filled"
                    InputProps={{
                      className: classes.input,
                      classes: { input: classes.input },
                    }}
                    inputProps={{ maxLength: 40 }}
                  />
                </div>
              </div>
            </>

            <Notification msg={errorMsg} open={openNotification} />
          </div>
        </DialogContent>
        <DialogActions style={{ justifyContent: "center" }}>
          <Button
            // onClick={handleClose}
            color="primary"
            style={styles.stylesForRemoveButton}
          >
            Cancel
          </Button>

          <Button
            // onClick={updateStaffShift}
            color="primary"
            style={styles.stylesSubmitButton}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const mapStateToProps = ({ CheckingReducer }) => {
  const { count, patientDetails } = CheckingReducer;
  return { count, patientDetails };
};
export default connect(mapStateToProps, {
  funForReducer,
  setPatientDetailsForReducer,
})(ViewAssignPA);
