import React, { useState, useEffect, useReducer, useCallback } from "react";
import {
  Chip,
  MenuItem,
  makeStyles,
  TextField,
  Input,
  Select,
  InputAdornment,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  useMediaQuery,
} from "@material-ui/core";
import moment from "moment";
import DoneIcon from "@material-ui/icons/Done";
import AddIcon from "@material-ui/icons/Add";
import Back from "../../../../assets/img/Back_Arrow.png";
import ImageViewer from "react-simple-image-viewer";
import { IoIosCloseCircle } from "react-icons/io";
import {
  uploadsUrl,
  getSearchedPharmaceuticalItemsUrl,
  getAllergiesDropdown,
  getSingleEdr,
} from "../../../../public/endpoins";
import "./formRow.css";
import Autocomplete from "@material-ui/lab/Autocomplete";
import axios from "axios";
import MicIcon from "@material-ui/icons/Mic";
import StopIcon from "@material-ui/icons/Stop";
import MicRecorder from "mic-recorder-to-mp3";
import Loader from "react-loader-spinner";
import VoiceComponent from "../../../../components/VoiceComponent/VoiceComponent";

const Mp3Recorder = new MicRecorder({ bitRate: 128 });

const styles = {
  ROSRowPadding: {
    marginLeft: 0,
    marginRight: 0,
    backgroundColor: "white",
    marginTop: 10,
    padding: "10px 0px 10px 0px",
    borderRadius: 5,
  },
  chipAlignment: {
    display: "inline-block",
    padding: "5px",
  },
  save: {
    color: "white",
    cursor: "pointer",
    borderRadius: 5,
    backgroundColor: "#2c6ddd",
    width: "115px",
    height: "40px",
    outline: "none",
  },
};

const useStyles = makeStyles((theme) => ({
  input: {
    backgroundColor: "#F5F5F5",
    boxShadow: "none",
    borderRadius: 5,
    "&:after": {
      borderBottomColor: "black",
      boxShadow: "none",
    },
    "&:hover": {
      backgroundColor: "#F5F5F5",
      boxShadow: "none",
    },
    "&:focus": {
      backgroundColor: "#F5F5F5",
      boxShadow: "none",
      borderRadius: 5,
    },
  },
  root: {
    "& .MuiTextField-root": {
      backgroundColor: "white",
    },
    "& .Mui-focused": {
      backgroundColor: "white",
      color: "black",
      boxShadow: "none",
    },
    "& .Mui-disabled": {
      backgroundColor: "white",
      color: "gray",
    },
    "& .MuiFormLabel-root": {
      fontSize: "12px",
      color: "black",
      paddingRight: "15px",
    },
    "& .MuiInputLabel-filled": {
      color: "gray",
    },
    "& .MuiFilledInput-adornedEnd": {
      backgroundColor: "#F5F5F5",
      paddingRight: "20px",
    },
  },
}));

const useAutocompleteStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(3),
    },
  },
}));

export default function ActionPlan(props) {
  const matches = useMediaQuery("(min-width: 600)");
  const classes = useStyles();
  const [congestionSelectedItem, setCongestionSelectedItem] = useState({});

  const initialState = {
    investigation: [],
    isBlocked: true,
    isRecording: false,
    blobURL: [],
    recordingMode: ""
  };

  function reducer(state, { field, value }) {
    return {
      ...state,
      [field]: value,
    };
  }

  const [state, dispatch] = useReducer(reducer, initialState);

  const { investigation, isBlocked, isRecording, blobURL, recordingMode } = state;

  const [clickedRow, setClickedRow] = useState([]);
  const [openList, setOpenList] = useState(false);
  const [listItems, setlistItems] = useState([]);
  const [ECGreport, setECGreport] = useState([]);
  const [ECGpreview, setECGpreview] = useState([]);
  const [XRAYreport, setXRAYreport] = useState([]);
  const [XRAYpreview, setXRAYpreview] = useState([]);
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [imagesDisplay, setImagesDisplay] = useState([]);
  const autocompleteClasses = useAutocompleteStyles();
  const [searchedKeyword, setSearchedKeyword] = useState("");
  const [medicines, setMedicines] = useState([]);
  const [selectedMedicines, setselectedMedicines] = useState([]);

  const [personName, setPersonName] = useState([]);

  const [allergiesList, setAllergiesList] = useState([]);
  const [selectedAllergiesList, setSelectedAllergiesList] = useState([]);
  const [otherAlergiesText, setOtherAlergiesText] = useState("");
  const [selectedAlertgiesContainOthers, setSelectedAlertgiesContainOthers] = useState(false);

  // Action & Plan for Differential Diagnosis
  const [differentialDiagnosisRemarks     , setDifferentialDiagnosisRemarks]      = useState("");
  const [differentialDiagnosisVoiceNotes     , setDifferentialDiagnosisVoiceNotes]      = useState("");
  const [differentialDiagnosisPlanRemarks     , setDifferentialDiagnosisPlanRemarks]      = useState("");
  const [differentialDiagnosisPlanVoiceNotes     , setDifferentialDiagnosisPlanVoiceNotes]      = useState("");



  const [o2Check         , setO2Check]          = useState("no");
  const [o2Remarks       , setO2Remarks]        = useState("");
  const [o2PlanRemarks   , setO2PlanRemarks]    = useState("");
  const [o2VoiceNotes    , setO2VoiceNotes]     = useState("");
  const [o2PlanVoiceNotes, setO2PlanVoiceNotes] = useState("");

  const [ivFluidsCheck          , setIVFluidsCheck]              = useState("no");
  const [ivFluidsRemarks        , setIVFluidsRemarks]            = useState("");
  const [ivFluidsPlanRemarks    , setIVFluidsPlanRemarks]        = useState("");
  const [ivFluidsVoiceNotes     , setIVFluidsVoiceNotes]         = useState("");
  const [ivFluidsPlanVoiceNotes     , setIVFluidsPlanVoiceNotes] = useState("");

  const [medicationsCheck         , setMedicationsCheck]          = useState("no");
  const [medicationsRemarks       , setMedicationsRemarks]        = useState("");
  const [medicationsPlanRemarks   , setMedicationsPlanRemarks]    = useState("");
  const [medicationsVoiceNotes    , setMedicationsVoiceNotes]     = useState("");
  const [medicationsPlanVoiceNotes, setMedicationsPlanVoiceNotes] = useState("");

  const [painManagementCheck          , setPainManagementCheck]          = useState("no");
  const [painManagementRemarks        , setPainManagementRemarks]        = useState("");
  const [painManagementPlanRemarks    , setPainManagementPlanRemarks]    = useState("");
  const [painManagementVoiceNotes     , setPainManagementVoiceNotes]     = useState("");
  const [painManagementPlanVoiceNotes , setPainManagementPlanVoiceNotes] = useState("");

  const [investigationsCheck            , setInvestigationsCheck]           = useState("no");
  const [investigationsRemarks          , setInvestigationsRemarks]         = useState("");
  const [investigationsPlanRemarks      , setInvestigationsPlanRemarks]     = useState("");
  const [investigationsVoiceNotes       , setInvestigationsVoiceNotes]      = useState("");
  const [investigationsPlayVoiceNotes   , setInvestigationsPlayVoiceNotes]  = useState("");
  const [investigationsPlanVoiceNotes   , setInvestigationsPlanVoiceNotes]  = useState("");

  const [allDoctorNotes, setAllDoctorNotes] = useState([]);
  const [painScaleData, setPainScaleData] = useState([])



  // Course of Visit Previous Form
  const [cpStable                       , setCPStable]                        = useState("");
  const [cpComfortable                  , setCPComfortable]                   = useState("");
  const [cpNotInDistress                , setCPNotInDistress]                 = useState("");
  const [cpRespondendWellManagement     , setCPRespondendWellManagement]      = useState("");
  const [cpQuestionConcerned            , setCPQuestionConcerned]             = useState("");
  const [cpRemarks                      , setCPRemarks]                       = useState("");
  const [cpVoiceNotes                   , setCPVoiceNotes]                    = useState("");

  // Consultation Service Box
  const [dischargeDisposition           , setDischargeDisposition]            = useState("");
  const [dischargeDispositionRemarks    , setDischargeDispositionRemarks]     = useState("");
  const [dischargeDispositionVoiceNotes , setDischargeDispositionVoiceNotes]  = useState("");
  const [consultationServiceRemarks     , setConsultationServiceRemarks]    = useState("");
  const [consultationServiceVoiceNotes  , setConsultationServiceVoiceNotes] = useState("");
  const [consultationDecisionCheck      , setConsultationDecisionCheck]     = useState("");
  const [consultationFinalDiagnosisRemarks    , setConsultationFinalDiagnosisRemarks]         = useState("")
  const [consultationFinalDiagnosisVoiceNotes , setConsultationFinalDiagnosisVoiceNotes]      = useState("")
  // const [medicationsRemarks    , setMedicationsRemarks]     = useState("");
  // const [medicationsVoiceNotes , setMedicationsVoiceNotes]  = useState("");
  const [consultationRemarks   , setConsultationRemarks]    = useState("");
  const [consultationVoiceNotes, setConsultationVoiceNotes] = useState("");
  // Patient Education
  const [patientEducationRemarks      , setPatientEducationRemarks]       = useState("");
  const [patientEducationVoiceNotes   , setPatientEducationVoiceNotes]    = useState("");


  const triggerSave = () => {
    dispatch({field: 'investigation', value: {
      'differentialDiagnosisRemarks' : differentialDiagnosisRemarks,
      'differentialDiagnosisVoiceNotes' : differentialDiagnosisVoiceNotes,
      'differentialDiagnosisPlanVoiceNotes' : differentialDiagnosisPlanVoiceNotes,
      'o2Check'                 : o2Check,
      'o2Remarks'                 : o2Remarks,
      'o2PlanRemarks'                 : o2PlanRemarks,
      'o2VoiceNotes'                  : o2VoiceNotes,
      'o2PlanVoiceNotes'                  : o2PlanVoiceNotes,
      'ivFluidsCheck'                 : ivFluidsCheck,
      'ivFluidsRemarks'                 : ivFluidsRemarks,
      'ivFluidsPlanRemarks'                 : ivFluidsPlanRemarks,
      'ivFluidsVoiceNotes'                  : ivFluidsVoiceNotes,
      'ivFluidsPlanVoiceNotes'                  : ivFluidsPlanVoiceNotes,
      'medicationsCheck'                  : medicationsCheck,
      'medicationsRemarks'                  : medicationsRemarks,
      'medicationsPlanRemarks'                  : medicationsPlanRemarks,
      'medicationsVoiceNotes'                 : medicationsVoiceNotes,
      'medicationsPlanVoiceNotes'                 : medicationsPlanVoiceNotes,
      'painManagementCheck'                 : painManagementCheck,
      'painManagementRemarks'                 : painManagementRemarks,
      'painManagementPlanRemarks'                 : painManagementPlanRemarks,
      'painManagementVoiceNotes'                  : painManagementVoiceNotes,
      'painManagementPlanVoiceNotes'                  : painManagementPlanVoiceNotes,
      'investigationsCheck'                 : investigationsCheck,
      'investigationsRemarks'                 : investigationsRemarks,
      'investigationsPlanRemarks'                 : investigationsPlanRemarks,
      'investigationsVoiceNotes'                  : investigationsVoiceNotes,
      'investigationsPlanVoiceNotes'                  : investigationsPlanVoiceNotes,
      'investigationsPlayVoiceNotes'                  : investigationsPlayVoiceNotes,



      'cpStable'                                : cpStable,
      'cpComfortable'                           : cpComfortable,
      'cpNotInDistress'                         : cpNotInDistress,
      'cpRespondendWellManagement'              : cpRespondendWellManagement,
      'cpQuestionConcerned'                     : cpQuestionConcerned,
      'cpRemarks'                               : cpRemarks,
      'cpVoiceNotes'                            : cpVoiceNotes,
      // 'dischargeDisposition'                    : dischargeDisposition,
      // 'dischargeDispositionRemarks'             : dischargeDispositionRemarks,
      // 'dischargeDispositionVoiceNotes'          : dischargeDispositionVoiceNotes,
      // 'consultationServiceRemarks'              : consultationServiceRemarks,
      // 'consultationServiceVoiceNotes'           : consultationServiceVoiceNotes,
      // 'consultationDecisionCheck'               : consultationDecisionCheck,
      // 'consultationFinalDiagnosisRemarks'       : consultationFinalDiagnosisRemarks,
      // 'consultationFinalDiagnosisVoiceNotes'    : consultationFinalDiagnosisVoiceNotes,
      // 'medicationsRemarks'                      : medicationsRemarks,
      // 'medicationsVoiceNotes'                   : medicationsVoiceNotes,
      // 'consultationRemarks'                     : consultationRemarks,
      // 'consultationVoiceNotes'                  : consultationVoiceNotes,
      // 'patientEducationRemarks'                 : patientEducationRemarks,
      // 'patientEducationVoiceNotes'              : patientEducationVoiceNotes,


    }})
    props.handleSave({
      'differentialDiagnosisRemarks' : differentialDiagnosisRemarks,
      'differentialDiagnosisVoiceNotes' : differentialDiagnosisVoiceNotes,
      'differentialDiagnosisPlanVoiceNotes' : differentialDiagnosisPlanVoiceNotes,
      'o2Check'                 : o2Check,
      'o2Remarks'                 : o2Remarks,
      'o2PlanRemarks'                 : o2PlanRemarks,
      'o2VoiceNotes'                  : o2VoiceNotes,
      'o2PlanVoiceNotes'                  : o2PlanVoiceNotes,
      'ivFluidsCheck'                 : ivFluidsCheck,
      'ivFluidsRemarks'                 : ivFluidsRemarks,
      'ivFluidsPlanRemarks'                 : ivFluidsPlanRemarks,
      'ivFluidsVoiceNotes'                  : ivFluidsVoiceNotes,
      'ivFluidsPlanVoiceNotes'                  : ivFluidsPlanVoiceNotes,
      'medicationsCheck'                  : medicationsCheck,
      'medicationsRemarks'                  : medicationsRemarks,
      'medicationsPlanRemarks'                  : medicationsPlanRemarks,
      'medicationsVoiceNotes'                 : medicationsVoiceNotes,
      'medicationsPlanVoiceNotes'                 : medicationsPlanVoiceNotes,
      'painManagementCheck'                 : painManagementCheck,
      'painManagementRemarks'                 : painManagementRemarks,
      'painManagementPlanRemarks'                 : painManagementPlanRemarks,
      'painManagementVoiceNotes'                  : painManagementVoiceNotes,
      'painManagementPlanVoiceNotes'                  : painManagementPlanVoiceNotes,
      'investigationsCheck'                 : investigationsCheck,
      'investigationsRemarks'                 : investigationsRemarks,
      'investigationsPlanRemarks'                 : investigationsPlanRemarks,
      'investigationsVoiceNotes'                  : investigationsVoiceNotes,
      'investigationsPlayVoiceNotes'                  : investigationsPlayVoiceNotes,
      'investigationsPlanVoiceNotes'                  : investigationsPlanVoiceNotes,


      'cpStable'                                : cpStable,
      'cpComfortable'                           : cpComfortable,
      'cpNotInDistress'                         : cpNotInDistress,
      'cpRespondendWellManagement'              : cpRespondendWellManagement,
      'cpQuestionConcerned'                     : cpQuestionConcerned,
      'cpRemarks'                               : cpRemarks,
      'cpVoiceNotes'                            : cpVoiceNotes,
      // 'dischargeDisposition'                    : dischargeDisposition,
      // 'dischargeDispositionRemarks'             : dischargeDispositionRemarks,
      // 'dischargeDispositionVoiceNotes'          : dischargeDispositionVoiceNotes,
      // 'consultationServiceRemarks'              : consultationServiceRemarks,
      // 'consultationServiceVoiceNotes'           : consultationServiceVoiceNotes,
      // 'consultationDecisionCheck'               : consultationDecisionCheck,
      // 'consultationFinalDiagnosisRemarks'       : consultationFinalDiagnosisRemarks,
      // 'consultationFinalDiagnosisVoiceNotes'    : consultationFinalDiagnosisVoiceNotes,
      // 'medicationsRemarks'                      : medicationsRemarks,
      // 'medicationsVoiceNotes'                   : medicationsVoiceNotes,
      // 'consultationRemarks'                     : consultationRemarks,
      // 'consultationVoiceNotes'                  : consultationVoiceNotes,
      // 'patientEducationRemarks'                 : patientEducationRemarks,
      // 'patientEducationVoiceNotes'              : patientEducationVoiceNotes,
    }, ECGreport, XRAYreport, []);
  }

  const printDiagnosis = (v) => {
    let dataCode = [];
    Object.entries(v).map(([key, val]) => {
      console.log('FUCKED', val);
      dataCode.push(val.short_desc);
    });
    return dataCode.join(', ');
  }


  useEffect(() => {
    let state = props.history.location.state;
    const filteredDropdownSelectedValue = props.form
      .map(
        (item) =>
          item.chips &&
          item.chips.find(
            (x) => x.dropdownSelectedValue && x.dropdownSelectedValue
          )
      )
      .filter(Boolean)[0];
    filteredDropdownSelectedValue &&
      filteredDropdownSelectedValue.length &&
      setCongestionSelectedItem(
        filteredDropdownSelectedValue.dropdownSelectedValue
      );


    let modePage = 'add';
    if (state.currentFields && state.comingFrom === "edit"){
      modePage = 'edit';
      console.log("FormRow::currentFields::", state.currentFields);

      let nData = [];
      Object.entries(state.currentFields.details).map(([key, val]) => {
        console.log(key);
        if(key === "differentialDiagnosisRemarks"){
          setDifferentialDiagnosisRemarks(val);
        }else if(key === "differentialDiagnosisVoiceNotes"){
          setDifferentialDiagnosisVoiceNotes(val);
        }else if(key === "differentialDiagnosisPlanRemarks"){
          setDifferentialDiagnosisPlanRemarks(val);
        }else if(key === "differentialDiagnosisPlanVoiceNotes"){
          setDifferentialDiagnosisPlanVoiceNotes(val);
        }else if(key === "o2Check"){
          setO2Check(val);
        }else if(key === "o2Remarks"){
          setO2Remarks(val);
        }else if(key === "o2VoiceNotes"){
          setO2VoiceNotes(val);
        }else if(key === "o2PlanRemarks"){
          setO2PlanRemarks(val);
        }else if(key === "o2PlanVoiceNotes"){
          setO2PlanVoiceNotes(val);
        }else if(key === "ivFluidsCheck"){
          setIVFluidsCheck(val);
        }else if(key === "ivFluidsRemarks"){
          setIVFluidsRemarks(val);
        }else if(key === "ivFluidsVoiceNotes"){
          setIVFluidsVoiceNotes(val);
        }else if(key === "ivFluidsPlanRemarks"){
          setIVFluidsPlanRemarks(val);
        }else if(key === "ivFluidsPlanVoiceNotes"){
          setIVFluidsPlanVoiceNotes(val);
        }else if(key === "medicationsCheck"){
          setMedicationsCheck(val);
        }else if(key === "medicationsRemarks"){
          setMedicationsRemarks(val);
        }else if(key === "medicationsVoiceNotes"){
          setMedicationsVoiceNotes(val);
        }else if(key === "medicationsPlanRemarks"){
          setMedicationsPlanRemarks(val);
        }else if(key === "medicationsPlanVoiceNotes"){
          setMedicationsPlanVoiceNotes(val);
        }else if(key === "painManagementCheck"){
          setPainManagementCheck(val);
        }else if(key === "painManagementRemarks"){
          setPainManagementRemarks(val);
        }else if(key === "painManagementVoiceNotes"){
          setPainManagementVoiceNotes(val);
        }else if(key === "painManagementPlanRemarks"){
          setPainManagementPlanRemarks(val);
        }else if(key === "painManagementPlanVoiceNotes"){
          setPainManagementPlanVoiceNotes(val);
        }else if(key === "investigationsCheck"){
          setInvestigationsCheck(val);
        }else if(key === "investigationsRemarks"){
          setInvestigationsRemarks(val);
        }else if(key === "investigationsVoiceNotes"){
          setInvestigationsVoiceNotes(val);
        }else if(key === "investigationsPlanRemarks"){
          setInvestigationsPlanRemarks(val);
        }else if(key === "investigationsPlanVoiceNotes"){
          setInvestigationsPlanVoiceNotes(val);
        }else if(key === "investigationsPlayVoiceNotes"){
          setInvestigationsPlayVoiceNotes(val);
        }else if(key === "cpStable"){
          setCPStable(val);
        }else if(key === "cpComfortable"){
          setCPComfortable(val);
        }else if(key === "cpNotInDistress"){
          setCPNotInDistress(val);
        }else if(key === "cpRespondendWellManagement"){
          setCPRespondendWellManagement(val);
        }else if(key === "cpQuestionConcerned"){
          setCPQuestionConcerned(val);
        }else if(key === "cpRemarks"){
          setCPRemarks(val);
        }else if(key === "cpVoiceNotes"){
          setCPVoiceNotes(val);
        }




      });
    }
    // Navigator for MP3
    navigator.mediaDevices &&
      navigator
        .mediaDevices.getUserMedia({ audio: true })
        .then( stream => {
          dispatch({ field: "isBlocked", value: false });
        })
        .catch( error => {
          dispatch({ field: "isBlocked", value: true });
        });
  }, []);

  useEffect(() => {

    console.log('props.edrId::', props.edrId);
    // Getting Realtime Data from Screen
    if (props.edrId){
      axios
        .get(getSingleEdr + "/" + props.edrId)
        .then((res) => {
          console.log('handleView::', res.data.data);
          if (res.data.success) {
            let remoteRecord = res.data.data;

            let finalDoctorNotes = [];
            if (remoteRecord.doctorNotes && remoteRecord.doctorNotes.length > 0) {
              let doctorNotesArray = remoteRecord.doctorNotes;

              Object.entries(doctorNotesArray).map(([did, d]) => {
                let finalDoctorNote = d;

                finalDoctorNote.assignedTime = d.assignedTime ? moment(finalDoctorNote.assignedTime).format('MMM DD, YYYY LT') : "";
                finalDoctorNote.doctor = d.addedBy.name[0].given[0] + " " + d.addedBy.name[0].family;

                finalDoctorNote.diagnosis = d.code.length;
                finalDoctorNote.diagnosisList = printDiagnosis(d.code);
                finalDoctorNote.diagnosisAdditionalList = printDiagnosis(d.additionalCode);
                finalDoctorNotes.push(finalDoctorNote);
              });
              setAllDoctorNotes(finalDoctorNotes);

              // const doctorNotesSorted = _.sortBy(val, "assignedTime").reverse();
            }

            if (remoteRecord.painManagement){
              let psPainManagement = [],
                  psDataBox = [];
              Object.entries(remoteRecord.painManagement).map( ( [inx, iv] ) => {
                psPainManagement.push({
                  name: moment(iv.date).format('MM/DD/YYYY hh:mm:ss a'),
                  pain: iv.painScale,
                  intervention: iv.intervention.join(', '),
                })

                psDataBox.push({
                  "datecol"            : moment(iv.date).format('LL LT'),
                  "scale"           : iv.painScale,
                  "intervention"    : iv.intervention.join(', '),
                  "requester"       : `${iv.requestedBy.name[0].given[0]} ${iv.requestedBy.name[0].family}`
                })

              } );
              // dispatch({ field: 'painScaleData', value: psPainManagement })
              setPainScaleData(psDataBox);
            }


          }
        })
        .catch((e) => {
          console.log("error after searching patient request", e);
        });
    }
  }, [props.edrId])

  return (
    <>
      <div className="row" style={styles.ROSRowPadding}>

        <div className="col-md-12 col-sm-12 col-12">
          <div className="row">
            <div className="col-md-12 col-sm-12" style={{marginBottom: 15}}><span style={{color: "rgb(47, 119, 209)", fontWeight: 700, fontSize:16}}>ED Doctor Diagnosis:</span></div>
            <div className="col-md-12 col-sm-12">
              <table className="table table-sm table-bordered table-stripped">
                <tr>
                  <th width="25%" className="text-left bg-light">Date &amp; Time</th>
                  <th width="15%" className="text-left bg-light">Name of Doctor</th>
                  <th width="30%" className="text-center bg-light">Main Diagnosis</th>
                  <th width="30%" className="text-left bg-light">Additional Diagnosis/Clinical Conditions</th>
                </tr>
                {

                  allDoctorNotes.length > 0?
                  (
                    Object.entries(allDoctorNotes).map(([ai,av]) => {
                      // Object.entries(av.details).map(([ai2,av2]) => {
                        return (
                          <tr>
                            <td>{av.assignedTime}</td>
                            <td>{av.doctor}</td>
                            <td>{av.diagnosisList}</td>
                            <td>{av.diagnosisAdditionalList}</td>
                          </tr>
                        )
                      // })
                    })
                  )
                  : <tr>
                    <td className="text-center" colspan="4">No diagnosis in the list.</td>
                  </tr>
                }
              </table>
            </div>
          </div>


          <div style={{ fontWeight: "400", color: "#939393" }}>Notes/Differential Diagnosis:</div>
          <div className="row mt-2">
            <div className="col-md-6 col-sm-12 col-12">
              <div className="form-group">
                <TextField
                  multiline
                  label="Action:"
                  value={differentialDiagnosisRemarks}
                  onChange={(e) =>{
                    setDifferentialDiagnosisRemarks(e.target.value)
                  }}
                  rows={5}
                  className="textInputStyle"
                  variant="filled"
                  InputProps={{
                    className: classes.input,
                    classes: {
                      input: classes.input,
                    },
                    disableUnderline: true,
                    rows: 5
                  }}
                  />
              </div>
              <VoiceComponent
                audioFile={differentialDiagnosisVoiceNotes}
                setAudioFile={setDifferentialDiagnosisVoiceNotes}
                 />
            </div>

            <div className="col-md-6 col-sm-12 col-12">
              <div className="form-group">
                <TextField
                  multiline
                  label="Plan:"
                  value={differentialDiagnosisPlanRemarks}
                  onChange={(e) =>{
                    setDifferentialDiagnosisPlanRemarks(e.target.value)
                  }}
                  rows={5}
                  className="textInputStyle"
                  variant="filled"
                  InputProps={{
                    className: classes.input,
                    classes: {
                      input: classes.input,
                    },
                    disableUnderline: true,
                    rows: 5
                  }}
                  />
              </div>
              <VoiceComponent
                audioFile={differentialDiagnosisPlanVoiceNotes}
                setAudioFile={setDifferentialDiagnosisPlanVoiceNotes}
                 />
            </div>
          </div>
        </div>
      </div>
      <div className="row" style={styles.ROSRowPadding}>
        <div className="col-md-12 col-sm-12 col-12">
          <div style={{ fontWeight: "400", color: "#939393" }}>Patient Management:</div>

          <table className="table table-sm table-bordered table-stripped mt-2">
            <tr>
              <th width="25%" className="text-left bg-light">Date &amp; Time</th>
              <th width="15%" className="text-center bg-light">Scale</th>
              <th width="30%" className="text-center bg-light">Intervention</th>
              <th width="30%" className="text-left bg-light">Requested</th>
            </tr>
            {

              painScaleData.length > 0?
              (
                Object.entries(painScaleData).map(([ai,av]) => {
                  // Object.entries(av.details).map(([ai2,av2]) => {
                    return (
                      <tr>
                        <td>{av.datecol}</td>
                        <td className="text-center">{av.scale}</td>
                        <td>{av.intervention}</td>
                        <td>{av.requester}</td>
                      </tr>
                    )
                  // })
                })
              )
              : <tr>
                <td className="text-center" colspan="4">No pain management in the list.</td>
              </tr>
            }
          </table>


          <div className="row mt-2">
            <div className="col-sm-2"><strong className="badge badge-primary">O2</strong></div>
            <div className="col-sm-10">
              <div className="form-group">
                <span style={styles.chipAlignment}>
                  <Chip
                    label="Yes"
                    onClick={() => setO2Check('yes') }
                    icon={o2Check=="yes" ? <DoneIcon style={{ color: "white" }} />:undefined}
                    style={o2Check=="yes" ? {color: "white",backgroundColor: "rgb(19 213 159)"} : { color: "#070707", backgroundColor: "#F5F5F5"}}
                  />
                </span>
                <span style={styles.chipAlignment}>
                  <Chip
                    label="No"
                    onClick={() => setO2Check('no') }
                    icon={o2Check=="no" ? <DoneIcon style={{ color: "white" }} />:undefined}
                    style={o2Check=="no" ? {color: "white",backgroundColor: "rgb(19 213 159)"} : { color: "#070707", backgroundColor: "#F5F5F5"}}
                  />
                </span>
              </div>

              <div className="row mt-2">
                <div className="col-md-6 col-sm-12 col-12">
                  <div className="form-group">
                    <TextField
                      multiline
                      label="Action:"
                      value={o2Remarks}
                      onChange={(e) =>{
                        setO2Remarks(e.target.value)
                      }}
                      rows={5}
                      className="textInputStyle"
                      variant="filled"
                      InputProps={{
                        className: classes.input,
                        classes: {
                          input: classes.input,
                        },
                        disableUnderline: true,
                        rows: 5
                      }}
                      />
                  </div>
                  <VoiceComponent
                    audioFile={o2VoiceNotes}
                    setAudioFile={setO2VoiceNotes}
                    />
                </div>
                <div className="col-md-6 col-sm-12 col-12">
                  <div className="form-group">
                    <TextField
                      multiline
                      label="Plan:"
                      value={o2PlanRemarks}
                      onChange={(e) =>{
                        setO2PlanRemarks(e.target.value)
                      }}
                      rows={5}
                      className="textInputStyle"
                      variant="filled"
                      InputProps={{
                        className: classes.input,
                        classes: {
                          input: classes.input,
                        },
                        disableUnderline: true,
                        rows: 5
                      }}
                      />
                  </div>
                  <VoiceComponent
                    audioFile={o2PlanVoiceNotes}
                    setAudioFile={setO2PlanVoiceNotes}
                    />
                </div>
              </div>
            </div>
          </div>
          <hr />



          <div className="row mt-2">
            <div className="col-sm-2"><strong className="badge badge-primary">IV Fluids</strong></div>
            <div className="col-sm-10">
              <div className="form-group">
                <span style={styles.chipAlignment}>
                  <Chip
                    label="Yes"
                    onClick={() => setIVFluidsCheck('yes') }
                    icon={ivFluidsCheck=="yes" ? <DoneIcon style={{ color: "white" }} />:undefined}
                    style={ivFluidsCheck=="yes" ? {color: "white",backgroundColor: "rgb(19 213 159)"} : { color: "#070707", backgroundColor: "#F5F5F5"}}
                  />
                </span>
                <span style={styles.chipAlignment}>
                  <Chip
                    label="No"
                    onClick={() => setIVFluidsCheck('no') }
                    icon={ivFluidsCheck=="no" ? <DoneIcon style={{ color: "white" }} />:undefined}
                    style={ivFluidsCheck=="no" ? {color: "white",backgroundColor: "rgb(19 213 159)"} : { color: "#070707", backgroundColor: "#F5F5F5"}}
                  />
                </span>
              </div>


              <div className="row mt-2">
                <div className="col-md-6 col-sm-12 col-12">
                  <div className="form-group">
                    <TextField
                      multiline
                      label="Action:"
                      value={ivFluidsRemarks}
                      onChange={(e) =>{
                        setIVFluidsRemarks(e.target.value)
                      }}
                      rows={5}
                      className="textInputStyle"
                      variant="filled"
                      InputProps={{
                        className: classes.input,
                        classes: {
                          input: classes.input,
                        },
                        disableUnderline: true,
                        rows: 5
                      }}
                      />
                  </div>
                  <VoiceComponent
                    audioFile={ivFluidsVoiceNotes}
                    setAudioFile={setIVFluidsVoiceNotes} />
                </div>
                <div className="col-md-6 col-sm-12 col-12">
                  <div className="form-group">
                    <TextField
                      multiline
                      label="Plan:"
                      value={ivFluidsPlanRemarks}
                      onChange={(e) =>{
                        setIVFluidsPlanRemarks(e.target.value)
                      }}
                      rows={5}
                      className="textInputStyle"
                      variant="filled"
                      InputProps={{
                        className: classes.input,
                        classes: {
                          input: classes.input,
                        },
                        disableUnderline: true,
                        rows: 5
                      }}
                      />
                  </div>
                  <VoiceComponent
                    audioFile={ivFluidsPlanVoiceNotes}
                    setAudioFile={setIVFluidsPlanVoiceNotes} />
                </div>
              </div>
            </div>
          </div>
          <hr />


          
          <div className="row mt-2">
            <div className="col-sm-2"><strong className="badge badge-primary">Medications</strong></div>
            <div className="col-sm-10">
              <div className="form-group">
                <span style={styles.chipAlignment}>
                  <Chip
                    label="Yes"
                    onClick={() => setMedicationsCheck('yes') }
                    icon={medicationsCheck=="yes" ? <DoneIcon style={{ color: "white" }} />:undefined}
                    style={medicationsCheck=="yes" ? {color: "white",backgroundColor: "rgb(19 213 159)"} : { color: "#070707", backgroundColor: "#F5F5F5"}}
                  />
                </span>
                <span style={styles.chipAlignment}>
                  <Chip
                    label="No"
                    onClick={() => setMedicationsCheck('no') }
                    icon={medicationsCheck=="no" ? <DoneIcon style={{ color: "white" }} />:undefined}
                    style={medicationsCheck=="no" ? {color: "white",backgroundColor: "rgb(19 213 159)"} : { color: "#070707", backgroundColor: "#F5F5F5"}}
                  />
                </span>
              </div>


              <div className="row mt-2">
                <div className="col-md-6 col-sm-12 col-12">
                  <div className="form-group">
                    <TextField
                      multiline
                      label="Action:"
                      value={medicationsRemarks}
                      onChange={(e) =>{
                        setMedicationsRemarks(e.target.value)
                      }}
                      rows={5}
                      className="textInputStyle"
                      variant="filled"
                      InputProps={{
                        className: classes.input,
                        classes: {
                          input: classes.input,
                        },
                        disableUnderline: true,
                        rows: 5
                      }}
                      />
                  </div>
                  <VoiceComponent
                    audioFile={medicationsVoiceNotes}
                    setAudioFile={setMedicationsVoiceNotes} />
                </div>
                <div className="col-md-6 col-sm-12 col-12">
                  <div className="form-group">
                    <TextField
                      multiline
                      label="Plan:"
                      value={medicationsPlanRemarks}
                      onChange={(e) =>{
                        setMedicationsPlanRemarks(e.target.value)
                      }}
                      rows={5}
                      className="textInputStyle"
                      variant="filled"
                      InputProps={{
                        className: classes.input,
                        classes: {
                          input: classes.input,
                        },
                        disableUnderline: true,
                        rows: 5
                      }}
                      />
                  </div>
                  <VoiceComponent
                    audioFile={medicationsPlanVoiceNotes}
                    setAudioFile={setMedicationsPlanVoiceNotes} />
                </div>
              </div>
            </div>
          </div>
          <hr />


          
          <div className="row mt-2">
            <div className="col-sm-2"><strong className="badge badge-primary">Start Pain Management</strong></div>
            <div className="col-sm-10">
              <div className="form-group">
                <span style={styles.chipAlignment}>
                  <Chip
                    label="Yes"
                    onClick={() => setPainManagementCheck('yes') }
                    icon={painManagementCheck=="yes" ? <DoneIcon style={{ color: "white" }} />:undefined}
                    style={painManagementCheck=="yes" ? {color: "white",backgroundColor: "rgb(19 213 159)"} : { color: "#070707", backgroundColor: "#F5F5F5"}}
                  />
                </span>
                <span style={styles.chipAlignment}>
                  <Chip
                    label="No"
                    onClick={() => setPainManagementCheck('no') }
                    icon={painManagementCheck=="no" ? <DoneIcon style={{ color: "white" }} />:undefined}
                    style={painManagementCheck=="no" ? {color: "white",backgroundColor: "rgb(19 213 159)"} : { color: "#070707", backgroundColor: "#F5F5F5"}}
                  />
                </span>
              </div>


              <div className="row mt-2">
                <div className="col-md-6 col-sm-12 col-12">
                  <div className="form-group">
                    <TextField
                      multiline
                      label="Action:"
                      value={painManagementRemarks}
                      onChange={(e) =>{
                        setPainManagementRemarks(e.target.value)
                      }}
                      rows={5}
                      className="textInputStyle"
                      variant="filled"
                      InputProps={{
                        className: classes.input,
                        classes: {
                          input: classes.input,
                        },
                        disableUnderline: true,
                        rows: 5
                      }}
                      />
                  </div>
                  <VoiceComponent
                    audioFile={painManagementVoiceNotes}
                    setAudioFile={setPainManagementVoiceNotes} />
                </div>
                <div className="col-md-6 col-sm-12 col-12">
                  <div className="form-group">
                    <TextField
                      multiline
                      label="Plan:"
                      value={painManagementPlanRemarks}
                      onChange={(e) =>{
                        setPainManagementPlanRemarks(e.target.value)
                      }}
                      rows={5}
                      className="textInputStyle"
                      variant="filled"
                      InputProps={{
                        className: classes.input,
                        classes: {
                          input: classes.input,
                        },
                        disableUnderline: true,
                        rows: 5
                      }}
                      />
                  </div>
                  <VoiceComponent
                    audioFile={painManagementPlanVoiceNotes}
                    setAudioFile={setPainManagementPlanVoiceNotes} />
                </div>
              </div>
            </div>
          </div>
          <hr />


          
          <div className="row mt-2">
            <div className="col-sm-2"><strong className="badge badge-primary">Investigations</strong></div>
            <div className="col-sm-10">
              <div className="form-group">
                <span style={styles.chipAlignment}>
                  <Chip
                    label="Yes"
                    onClick={() => setInvestigationsCheck('yes') }
                    icon={investigationsCheck=="yes" ? <DoneIcon style={{ color: "white" }} />:undefined}
                    style={investigationsCheck=="yes" ? {color: "white",backgroundColor: "rgb(19 213 159)"} : { color: "#070707", backgroundColor: "#F5F5F5"}}
                  />
                </span>
                <span style={styles.chipAlignment}>
                  <Chip
                    label="No"
                    onClick={() => setInvestigationsCheck('no') }
                    icon={investigationsCheck=="no" ? <DoneIcon style={{ color: "white" }} />:undefined}
                    style={investigationsCheck=="no" ? {color: "white",backgroundColor: "rgb(19 213 159)"} : { color: "#070707", backgroundColor: "#F5F5F5"}}
                  />
                </span>
              </div>


              <div className="row mt-2">
                <div className="col-md-6 col-sm-12 col-12">
                  <div className="form-group">
                    <TextField
                      multiline
                      label="Action:"
                      value={investigationsRemarks}
                      onChange={(e) =>{
                        setInvestigationsRemarks(e.target.value)
                      }}
                      rows={5}
                      className="textInputStyle"
                      variant="filled"
                      InputProps={{
                        className: classes.input,
                        classes: {
                          input: classes.input,
                        },
                        disableUnderline: true,
                        rows: 5
                      }}
                      />
                  </div>
                  <VoiceComponent
                    audioFile={investigationsVoiceNotes}
                    setAudioFile={setInvestigationsVoiceNotes} />
                </div>
                <div className="col-md-6 col-sm-12 col-12">
                  <div className="form-group">
                    <TextField
                      multiline
                      label="Plan:"
                      value={investigationsPlanRemarks}
                      onChange={(e) =>{
                        setInvestigationsPlanRemarks(e.target.value)
                      }}
                      rows={5}
                      className="textInputStyle"
                      variant="filled"
                      InputProps={{
                        className: classes.input,
                        classes: {
                          input: classes.input,
                        },
                        disableUnderline: true,
                        rows: 5
                      }}
                      />
                  </div>
                  <VoiceComponent
                    audioFile={investigationsPlanVoiceNotes}
                    setAudioFile={setInvestigationsPlanVoiceNotes} />
                </div>
              </div>
            </div>
          </div>
          <hr />



        </div>
      </div>


      { /*Course of Visit Imported*/ }

      <div className="row" style={styles.ROSRowPadding}>
        <div className="col-md-12 col-sm-12 col-12">
          <div style={{ fontWeight: "400", color: "#939393" }}>Condition of Patient:</div>
          <div className="row mt-2 align-items-center">
            <div className="col-sm-5"><strong className="">Stable</strong></div>
            <div className="col-sm-7">
              <div className="form-group">
                <span style={styles.chipAlignment}>
                  <Chip
                    label="Yes"
                    onClick={() => setCPStable('yes') }
                    icon={cpStable=="yes" ? <DoneIcon style={{ color: "white" }} />:undefined}
                    style={cpStable=="yes" ? {color: "white",backgroundColor: "rgb(19 213 159)"} : { color: "#070707", backgroundColor: "#F5F5F5"}}
                  />
                </span>
                <span style={styles.chipAlignment}>
                  <Chip
                    label="No"
                    onClick={() => setCPStable('no') }
                    icon={cpStable=="no" ? <DoneIcon style={{ color: "white" }} />:undefined}
                    style={cpStable=="no" ? {color: "white",backgroundColor: "rgb(19 213 159)"} : { color: "#070707", backgroundColor: "#F5F5F5"}}
                  />
                </span>
              </div>
            </div>
          </div>
          <div className="row mt-2 align-items-center">
            <div className="col-sm-5"><strong className="">Comfortable</strong></div>
            <div className="col-sm-7">
              <div className="form-group">
                <span style={styles.chipAlignment}>
                  <Chip
                    label="Yes"
                    onClick={() => setCPComfortable('yes') }
                    icon={cpComfortable=="yes" ? <DoneIcon style={{ color: "white" }} />:undefined}
                    style={cpComfortable=="yes" ? {color: "white",backgroundColor: "rgb(19 213 159)"} : { color: "#070707", backgroundColor: "#F5F5F5"}}
                  />
                </span>
                <span style={styles.chipAlignment}>
                  <Chip
                    label="No"
                    onClick={() => setCPComfortable('no') }
                    icon={cpComfortable=="no" ? <DoneIcon style={{ color: "white" }} />:undefined}
                    style={cpComfortable=="no" ? {color: "white",backgroundColor: "rgb(19 213 159)"} : { color: "#070707", backgroundColor: "#F5F5F5"}}
                  />
                </span>
              </div>
            </div>
          </div>
          <div className="row mt-2 align-items-center">
            <div className="col-sm-5"><strong className="">Not In Distress</strong></div>
            <div className="col-sm-7">
              <div className="form-group">
                <span style={styles.chipAlignment}>
                  <Chip
                    label="Yes"
                    onClick={() => setCPNotInDistress('yes') }
                    icon={cpNotInDistress=="yes" ? <DoneIcon style={{ color: "white" }} />:undefined}
                    style={cpNotInDistress=="yes" ? {color: "white",backgroundColor: "rgb(19 213 159)"} : { color: "#070707", backgroundColor: "#F5F5F5"}}
                  />
                </span>
                <span style={styles.chipAlignment}>
                  <Chip
                    label="No"
                    onClick={() => setCPNotInDistress('no') }
                    icon={cpNotInDistress=="no" ? <DoneIcon style={{ color: "white" }} />:undefined}
                    style={cpNotInDistress=="no" ? {color: "white",backgroundColor: "rgb(19 213 159)"} : { color: "#070707", backgroundColor: "#F5F5F5"}}
                  />
                </span>
              </div>
            </div>
          </div>
          <div className="row mt-2 align-items-center">
            <div className="col-sm-5"><strong className="">Respondend Well to Management</strong></div>
            <div className="col-sm-7">
              <div className="form-group">
                <span style={styles.chipAlignment}>
                  <Chip
                    label="Yes"
                    onClick={() => setCPRespondendWellManagement('yes') }
                    icon={cpRespondendWellManagement=="yes" ? <DoneIcon style={{ color: "white" }} />:undefined}
                    style={cpRespondendWellManagement=="yes" ? {color: "white",backgroundColor: "rgb(19 213 159)"} : { color: "#070707", backgroundColor: "#F5F5F5"}}
                  />
                </span>
                <span style={styles.chipAlignment}>
                  <Chip
                    label="No"
                    onClick={() => setCPRespondendWellManagement('no') }
                    icon={cpRespondendWellManagement=="no" ? <DoneIcon style={{ color: "white" }} />:undefined}
                    style={cpRespondendWellManagement=="no" ? {color: "white",backgroundColor: "rgb(19 213 159)"} : { color: "#070707", backgroundColor: "#F5F5F5"}}
                  />
                </span>
              </div>
            </div>
          </div>
          <div className="row mt-2 align-items-center">
            <div className="col-sm-5"><strong className="">Questions and concerns were answered and explained</strong></div>
            <div className="col-sm-7">
              <div className="form-group">
                <span style={styles.chipAlignment}>
                  <Chip
                    label="Yes"
                    onClick={() => setCPQuestionConcerned('yes') }
                    icon={cpQuestionConcerned=="yes" ? <DoneIcon style={{ color: "white" }} />:undefined}
                    style={cpQuestionConcerned=="yes" ? {color: "white",backgroundColor: "rgb(19 213 159)"} : { color: "#070707", backgroundColor: "#F5F5F5"}}
                  />
                </span>
                <span style={styles.chipAlignment}>
                  <Chip
                    label="No"
                    onClick={() => setCPQuestionConcerned('no') }
                    icon={cpQuestionConcerned=="no" ? <DoneIcon style={{ color: "white" }} />:undefined}
                    style={cpQuestionConcerned=="no" ? {color: "white",backgroundColor: "rgb(19 213 159)"} : { color: "#070707", backgroundColor: "#F5F5F5"}}
                  />
                </span>
              </div>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-sm-12">
              <div className="form-group">
                <TextField
                  multiline
                  label="Remarks: (ready for discharge/disposition or to remain in ED)"
                  value={cpRemarks}
                  onChange={(e) =>{
                    setCPRemarks(e.target.value)
                  }}
                  rows={5}
                  className="textInputStyle"
                  variant="filled"
                  InputProps={{
                    className: classes.input,
                    classes: {
                      input: classes.input,
                    },
                    disableUnderline: true,
                    rows: 5
                  }}
                  />
              </div>
              <VoiceComponent
                audioFile={cpVoiceNotes}
                setAudioFile={setCPVoiceNotes}
                />
            </div>
          </div>
        </div>
      </div>

      {/*

      <div className="row" style={styles.ROSRowPadding}>
        <div className="col-md-12 col-sm-12 col-12">
          <div style={{ fontWeight: "400", color: "#939393" }}>Discharge/Disposition</div>
          <div className="row mt-2 align-items-center">
            <div className="col-sm-12">
              <div className="form-group">
                <span style={styles.chipAlignment}>
                  <Chip
                    label="Discharge to Home"
                    onClick={() => setDischargeDisposition('Discharge to Home') }
                    icon={dischargeDisposition=="Discharge to Home" ? <DoneIcon style={{ color: "white" }} />:undefined}
                    style={dischargeDisposition=="Discharge to Home" ? {color: "white",backgroundColor: "rgb(19 213 159)"} : { color: "#070707", backgroundColor: "#F5F5F5"}}
                  />
                </span>
                <span style={styles.chipAlignment}>
                  <Chip
                    label="Admission"
                    onClick={() => setDischargeDisposition('Admission') }
                    icon={dischargeDisposition=="Admission" ? <DoneIcon style={{ color: "white" }} />:undefined}
                    style={dischargeDisposition=="Admission" ? {color: "white",backgroundColor: "rgb(19 213 159)"} : { color: "#070707", backgroundColor: "#F5F5F5"}}
                  />
                </span>
                <span style={styles.chipAlignment}>
                  <Chip
                    label="OR (Operation Room)"
                    onClick={() => setDischargeDisposition('Operation Room') }
                    icon={dischargeDisposition=="Operation Room" ? <DoneIcon style={{ color: "white" }} />:undefined}
                    style={dischargeDisposition=="Operation Room" ? {color: "white",backgroundColor: "rgb(19 213 159)"} : { color: "#070707", backgroundColor: "#F5F5F5"}}
                  />
                </span>
                <span style={styles.chipAlignment}>
                  <Chip
                    label="Regular Bed"
                    onClick={() => setDischargeDisposition('Regular Bed') }
                    icon={dischargeDisposition=="Regular Bed" ? <DoneIcon style={{ color: "white" }} />:undefined}
                    style={dischargeDisposition=="Regular Bed" ? {color: "white",backgroundColor: "rgb(19 213 159)"} : { color: "#070707", backgroundColor: "#F5F5F5"}}
                  />
                </span>
                <span style={styles.chipAlignment}>
                  <Chip
                    label="Monitored Bed"
                    onClick={() => setDischargeDisposition('Monitored Bed') }
                    icon={dischargeDisposition=="Monitored Bed" ? <DoneIcon style={{ color: "white" }} />:undefined}
                    style={dischargeDisposition=="Monitored Bed" ? {color: "white",backgroundColor: "rgb(19 213 159)"} : { color: "#070707", backgroundColor: "#F5F5F5"}}
                  />
                </span>
                <span style={styles.chipAlignment}>
                  <Chip
                    label="Step-Down Bed"
                    onClick={() => setDischargeDisposition('Step-Down Bed') }
                    icon={dischargeDisposition=="Step-Down Bed" ? <DoneIcon style={{ color: "white" }} />:undefined}
                    style={dischargeDisposition=="Step-Down Bed" ? {color: "white",backgroundColor: "rgb(19 213 159)"} : { color: "#070707", backgroundColor: "#F5F5F5"}}
                  />
                </span>
                <span style={styles.chipAlignment}>
                  <Chip
                    label="ICU"
                    onClick={() => setDischargeDisposition('ICU') }
                    icon={dischargeDisposition=="ICU" ? <DoneIcon style={{ color: "white" }} />:undefined}
                    style={dischargeDisposition=="ICU" ? {color: "white",backgroundColor: "rgb(19 213 159)"} : { color: "#070707", backgroundColor: "#F5F5F5"}}
                  />
                </span>
                <span style={styles.chipAlignment}>
                  <Chip
                    label="Transfer to another facility"
                    onClick={() => setDischargeDisposition('Transfer to another facility') }
                    icon={dischargeDisposition=="Transfer to another facility" ? <DoneIcon style={{ color: "white" }} />:undefined}
                    style={dischargeDisposition=="Transfer to another facility" ? {color: "white",backgroundColor: "rgb(19 213 159)"} : { color: "#070707", backgroundColor: "#F5F5F5"}}
                  />
                </span>
                <span style={styles.chipAlignment}>
                  <Chip
                    label="Deceased"
                    onClick={() => setDischargeDisposition('Deceased') }
                    icon={dischargeDisposition=="Deceased" ? <DoneIcon style={{ color: "white" }} />:undefined}
                    style={dischargeDisposition=="Deceased" ? {color: "white",backgroundColor: "rgb(19 213 159)"} : { color: "#070707", backgroundColor: "#F5F5F5"}}
                  />
                </span>
              </div>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-sm-12">
              <div className="form-group">
                <TextField
                  multiline
                  label="Action:"
                  value={dischargeDispositionRemarks}
                  onChange={(e) =>{
                    setDischargeDispositionRemarks(e.target.value)
                  }}
                  rows={5}
                  className="textInputStyle"
                  variant="filled"
                  InputProps={{
                    className: classes.input,
                    classes: {
                      input: classes.input,
                    },
                    disableUnderline: true,
                    rows: 5
                  }}
                  />
              </div>
              <VoiceComponent
                audioFile={cpVoiceNotes}
                setAudioFile={setCPVoiceNotes}
                />
            </div>
          </div>
        </div>
      </div>


      <div className="row" style={styles.ROSRowPadding}>
        <div className="col-md-12 col-sm-12 col-12">
          <div style={{ fontWeight: "400", color: "#939393" }}>Consultation:</div>

          <div className="row mt-2">
            <div className="col-sm-12 mb-2"><strong>Service/Speciality</strong></div>
            <div className="col-sm-12">
              <div className="form-group">
                <TextField
                  multiline
                  label="Action:"
                  value={consultationServiceRemarks}
                  onChange={(e) =>{
                    setConsultationServiceRemarks(e.target.value)
                  }}
                  rows={5}
                  className="textInputStyle"
                  variant="filled"
                  InputProps={{
                    className: classes.input,
                    classes: {
                      input: classes.input,
                    },
                    disableUnderline: true,
                    rows: 5
                  }}
                  />
              </div>
              <VoiceComponent
                audioFile={consultationServiceVoiceNotes}
                setAudioFile={setConsultationServiceVoiceNotes}
                />
            </div>
          </div>
          <hr />

          <div className="form-group">
            <span style={styles.chipAlignment}>
              <Chip
                label="Inpatient"
                onClick={() => setConsultationDecisionCheck('Inpatient') }
                icon={consultationDecisionCheck=="Inpatient" ? <DoneIcon style={{ color: "white" }} />:undefined}
                style={consultationDecisionCheck=="Inpatient" ? {color: "white",backgroundColor: "rgb(19 213 159)"} : { color: "#070707", backgroundColor: "#F5F5F5"}}
              />
            </span>
            <span style={styles.chipAlignment}>
              <Chip
                label="Outpatient"
                onClick={() => setConsultationDecisionCheck('Outpatient') }
                icon={consultationDecisionCheck=="Outpatient" ? <DoneIcon style={{ color: "white" }} />:undefined}
                style={consultationDecisionCheck=="Outpatient" ? {color: "white",backgroundColor: "rgb(19 213 159)"} : { color: "#070707", backgroundColor: "#F5F5F5"}}
              />
            </span>
            <span style={styles.chipAlignment}>
              <Chip
                label="ER"
                onClick={() => setConsultationDecisionCheck('ER') }
                icon={consultationDecisionCheck=="ER" ? <DoneIcon style={{ color: "white" }} />:undefined}
                style={consultationDecisionCheck=="ER" ? {color: "white",backgroundColor: "rgb(19 213 159)"} : { color: "#070707", backgroundColor: "#F5F5F5"}}
              />
            </span>
          </div>
          <hr />

          <div className="row mt-2">
            <div className="col-sm-12 mb-2"><strong>Final Diagnosis</strong></div>
            <div className="col-sm-12">
              <div className="form-group">
                <TextField
                  multiline
                  label="Action:"
                  value={consultationFinalDiagnosisRemarks}
                  onChange={(e) =>{
                    setConsultationFinalDiagnosisRemarks(e.target.value)
                  }}
                  rows={5}
                  className="textInputStyle"
                  variant="filled"
                  InputProps={{
                    className: classes.input,
                    classes: {
                      input: classes.input,
                    },
                    disableUnderline: true,
                    rows: 5
                  }}
                  />
              </div>
              <VoiceComponent
                audioFile={consultationFinalDiagnosisVoiceNotes}
                setAudioFile={setConsultationFinalDiagnosisVoiceNotes}
                />
            </div>
          </div>
          <hr />


          <div className="row mt-2">
            <div className="col-sm-12 mb-2"><strong>Medications</strong></div>
            <div className="col-sm-12">
              <div className="form-group">
                <TextField
                  multiline
                  label="Action:"
                  value={medicationsRemarks}
                  onChange={(e) =>{
                    setMedicationsRemarks(e.target.value)
                  }}
                  rows={5}
                  className="textInputStyle"
                  variant="filled"
                  InputProps={{
                    className: classes.input,
                    classes: {
                      input: classes.input,
                    },
                    disableUnderline: true,
                    rows: 5
                  }}
                  />
              </div>
              <VoiceComponent
                audioFile={medicationsVoiceNotes}
                setAudioFile={setMedicationsVoiceNotes}
                />
            </div>
          </div>
          <hr />

          <div className="mt-1 mb-2"><strong>Consultation Remarks</strong></div>
          <div className="form-group">
            <TextField
              multiline
              label="Action:"
              value={consultationRemarks}
              onChange={(e) =>{
                setConsultationRemarks(e.target.value)
              }}
              rows={5}
              className="textInputStyle"
              variant="filled"
              InputProps={{
                className: classes.input,
                classes: {
                  input: classes.input,
                },
                disableUnderline: true,
                rows: 5
              }}
              />
          </div>
          <VoiceComponent
            audioFile={consultationVoiceNotes}
            setAudioFile={setConsultationVoiceNotes}
            />
        </div>
      </div>


      <div className="row" style={styles.ROSRowPadding}>
        <div className="col-md-12 col-sm-12 col-12">
          <div style={{ fontWeight: "400", color: "#939393" }}>Patient Education and Discharge Instructions:</div>
          <div className="form-group">
            <TextField
              multiline
              label="Action:"
              value={patientEducationRemarks}
              onChange={(e) =>{
                setPatientEducationRemarks(e.target.value)
              }}
              rows={5}
              className="textInputStyle"
              variant="filled"
              InputProps={{
                className: classes.input,
                classes: {
                  input: classes.input,
                },
                disableUnderline: true,
                rows: 5
              }}
              />
          </div>
          <VoiceComponent
            audioFile={patientEducationVoiceNotes}
            setAudioFile={setPatientEducationVoiceNotes}
            />
        </div>
      </div>

      */}



      <div
        className="row"
        style={{
          display: "flex",
          justifyContent: "flex-start",
          marginTop: "30px",
          marginBottom: "2%",
        }}
      >
        <div className="col-md-6 col-sm-6 col-6 d-flex justify-content-start">
          <img
            onClick={() => props.history.goBack()}
            src={Back}
            style={{
              width: 45,
              height: 35,
              cursor: "pointer",
            }}
          />
        </div>
        <div className="col-md-6 col-sm-6 col-6 d-flex justify-content-end">
          <Button
            onClick={() =>
              triggerSave(investigation, ECGreport, XRAYreport, [])
            }
            style={
              clickedRow.length > 0
                ? styles.save
                : { ...styles.save, backgroundColor: "#13D59F" }
            }
            variant="contained"
            color="default"
          >
            Save
          </Button>
        </div>
      </div>
    </>
  );
}
