import axios from 'axios'
import { getPendingEDRs, searchPendingEdr } from '../../../public/endpoins.js'

export const getEdrPatientsForCC = (
  setlabInPatient,
  setErrorMsg,
  setOpenNotification
) => {
  axios
    .get(getPendingEDRs)
    .then((res) => {
      if (res.data.success) {
        console.log(res.data.data)
        res.data.data.map(
          (d) => (
            console.log(d),
            (d.patientName =
              d.patientId.name[0].given[0] + ' ' + d.patientId.name[0].family),
            (d.mrn = d.patientId.identifier[0].value),
            (d.gender = d.patientId.gender),
            (d.status = d.dcdFormStatus),
            (d.age = d.patientId.age),
            (d.createdAt = d.patientId.createdAt)
          )
        )
        // res.data.data.map((d) => (d.mrn = d.patientId.identifier[0].value));
        // res.data.data.map((d) => (d.gender = d.patientId.gender));
        // res.data.data.map((d) => (d.age = d.patientId.age));
        // res.data.data.map((d) => (d.createdAt = d.patientId.createdAt));
        // res.data.data.map((d) => (d.status = d.dcdFormStatus));

        console.log(res.data.data, 'patient data')
        setlabInPatient(res.data.data.reverse())
      } else if (!res.data.success) {
        setErrorMsg(res.data.error)
        setOpenNotification(true)
      }
      return res
    })
    .catch((e) => {
      console.log('error: ', e)
    })
}

export const searchEdrPatientsForCC = (
  a,
  setlabInPatient,
  setErrorMsg,
  setOpenNotification
) => {
  console.log('A', a)
  axios
    .get(searchPendingEdr + '/' + a)
    .then((res) => {
      console.log('res', res)
      if (res.data.success) {
        res.data.data.map(
          (d) =>
            (d.patientName =
              d.patientId.name[0].given[0] + ' ' + d.patientId.name[0].family)
        )
        res.data.data.map((d) => (d.mrn = d.patientId.identifier[0].value))
        res.data.data.map((d) => (d.gender = d.patientId.gender))
        res.data.data.map((d) => (d.age = d.patientId.age))
        res.data.data.map((d) => (d.createdAt = d.patientId.createdAt))
        res.data.data.map((d) => (d.status = d.dcdFormStatus))

        console.log(res.data.data, 'patient data')
        setlabInPatient(res.data.data)
      } else if (!res.data.success) {
        setErrorMsg(res.data.error)
        setOpenNotification(true)
      }
    })
    .catch((e) => {
      console.log('error after searching patient request', e)
    })
}
