/*eslint-disable*/
import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Notification from "../../components/Snackbar/Notification.js";
import CustomTable from "../../components/Table/Table";
import EnableDisableModal from "../../components/EnableDisableModal/enableDisableModal";
import cookie from "react-cookies";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Header from "../../components/Header/Header";
import CareStream from "../../assets/img/carestream/CareStream.png";
import plus_icon from "../../assets/img/Plus.png";
import Back from "../../assets/img/Back_Arrow.png";
import { careStreamTableStyles } from "./styles";
import "../../assets/jss/material-dashboard-react/components/loaderStyle.css";
import {
  networkGetCareStream,
  networkGetMedicationCareStream,
  networkDisableEnableCareStream,
  networkGetMedicationsByIdCareStreams,
} from "./NetworkCallForCareStream";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const styles = {
  stylesForButton: {
    color: "white",
    cursor: "pointer",
    borderRadius: 5,
    background: "#2c6ddd",
    width: "200px",
    height: "50px",
    outline: "none",
  },
};

const careStreamHeading = [
  "CareStream ID",
  "CareStream Name",
  "Date/Time",
  "Action",
];
const careStreamKeys = ["ID", "name", "createdAt"];
const medicationCareStreamHeading = [
  "CareStream ID",
  "CareStream Name",
  "Action",
];
const medicationCareStreamKeys = ["ID", "name"];
const actionsCareStream = { view: true, enableDisable: true };
const actionsCareStreamMedication = { view: true };

export default function Staff(props) {
  const matches = useMediaQuery("(min-width:600px)");
  const classes = careStreamTableStyles();
  const [careStream, setCareStream] = useState([]);
  const [medicationCareStream, setMedicationCareStream] = useState([]);
  const [errorMsg, setErrorMsg] = useState("");
  const [openNotification, setOpenNotification] = useState(false);
  const [tabName, setTabName] = useState("carestream");
  const [openEnableDisabledModal, setOpenEnableDisabledModal] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState("");
  const [currentUser, setCurrentUser] = useState(cookie.load("current_user"));

  if (openNotification) {
    setTimeout(() => {
      setOpenNotification(false);
      setErrorMsg("");
    }, 2000);
  }

  const getCareStreamFun = () => {
    networkGetCareStream(setCareStream);
  };

  const getMedicationsCareStreamsFun = () => {
    networkGetMedicationCareStream(setMedicationCareStream);
  };

  useEffect(() => {
    getMedicationsCareStreamsFun();
    getCareStreamFun();
  }, []);

  const handleChange = (event, tabValue) => {
    console.log("tabValue", tabValue);
    setTabName(tabValue);
  };

  const enableDisableRecord = (rec) => {
    setOpenEnableDisabledModal(true);
    setSelectedRecord(rec);
  };

  const handleConfirmDisable = (reason) => {
    let object = {
      reason: reason,
      staffId: currentUser._id,
    };

    networkDisableEnableCareStream(
      setOpenEnableDisabledModal,
      setSelectedRecord,
      setErrorMsg,
      setOpenNotification,
      selectedRecord,
      object
    );
  };

  const addNewItem = () => {
    let path = `carestream`;
    let staffType = tabName;
    props.history.push({
      pathname: path,
      state: {
        staffType,
        comingFor: "add",
      },
    });
  };

  const handleViewCareStream = (rec) => {
    console.log("rec", rec);
    let path = `carestream`;
    props.history.push({
      pathname: path,
      state: {
        comingFrom: "add",
        rec,
      },
    });
  };

  const handleViewMedicationCareStream = (record) => {
    networkGetMedicationsByIdCareStreams(props, record);
  };

  const inprogresCareStream = () => {
    let path = `carestreaminprogress`;
    let staffType = tabName;
    props.history.push({
      pathname: path,
      state: {
        staffType,
        comingFor: "add",
      },
    });
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        position: "fixed",
        width: "100%",
        height: "100%",
        backgroundColor: "#60d69f",
        overflowY: "scroll",
      }}
    >
      <Header history={props.history} />

      <div className="cPadding">
        <div className="subheader" style={{ paddingRight: "0px !important" }}>
          {/* <div
              className="row"
              style={{
                display: "flex",
                flex: 1,
                justifyContent: "center",
                paddingLeft: 6,
                paddingRight: 6,
              }}
            > */}
          <div
            style={{
              display: "flex",
              flex: 1,
            }}
          >
            <img src={CareStream} />
            <h4 style={{ marginLeft: matches ? " " : -5 }}>CareStream </h4>
          </div>
          {/* <div
            className="col-md-12 col-sm-12"
            style={{
              display: "flex",
              flex: 1,
              marginTop: "2%",
              marginBottom: "2%",
            }}
          > */}
          <div>
            {" "}
            <Button
              onClick={addNewItem}
              // className={classes.careStream}
              style={{
                ...styles.stylesForButton,
                background: "#845DC2",
                // border: "1px solid white",
                fontSize: matches ? 12 : 10,
                marginRight: 10,
              }}
              variant="contained"
              color="primary"
            >
              <img src={plus_icon} className="icon-style" />
              &nbsp;&nbsp;
              <strong>New CareStream</strong>
            </Button>
            <Button
              onClick={inprogresCareStream}
              style={{
                ...styles.stylesForButton,
                backgroundColor: "#2973CF",
                fontSize: matches ? 12 : 10,
              }}
              // className={classes.careStreamMedicine}
              variant="contained"
              color="primary"
            >
              <strong>In-Progress CareStream</strong>
            </Button>
          </div>
        </div>
        {/* </div> */}
        {/* </div> */}

        <div className={classes.root}>
          <Tabs
            classes={{
              root: classes.root,
              scroller: classes.scroller,
            }}
            value={tabName}
            onChange={handleChange}
            textColor="primary"
            variant="scrollable"
            TabIndicatorProps={{ style: { background: "#12387a" } }}
            // centered
          >
            <Tab
              style={{
                color: "white",
                borderRadius: 5,
                outline: "none",
                color: tabName === "carestream" ? "#12387a" : "#3B988C",
              }}
              label="CareStream"
              value="carestream"
            />
            <Tab
              style={{
                color: "white",
                borderRadius: 5,
                outline: "none",
                color:
                  tabName === "CareStream Medication" ? "#12387a" : "#3B988C",
              }}
              label="CareStream Medication"
              value="carestreammedication"
            />
          </Tabs>
        </div>

        <div
          style={{
            flex: 4,
            display: "flex",
            flexDirection: "column",
          }}
        >
          {careStream && tabName === "carestream" ? (
            <div className="container-fluid">
              <div className="row">
                <CustomTable
                  tableData={careStream}
                  tableDataKeys={careStreamKeys}
                  tableHeading={careStreamHeading}
                  action={actionsCareStream}
                  handleView={handleViewCareStream}
                  borderBottomColor={"#60d69f"}
                  borderBottomWidth={20}
                  enableDisableRecord={enableDisableRecord}
                />

                <Notification msg={errorMsg} open={openNotification} />
              </div>
            </div>
          ) : medicationCareStream && tabName === "carestreammedication" ? (
            <div className="container-fluid">
              <div className="row">
                <CustomTable
                  tableData={medicationCareStream}
                  tableDataKeys={medicationCareStreamKeys}
                  tableHeading={medicationCareStreamHeading}
                  action={actionsCareStreamMedication}
                  handleView={handleViewMedicationCareStream}
                  borderBottomColor={"#60d69f"}
                  borderBottomWidth={20}
                />

                <Notification msg={errorMsg} open={openNotification} />
              </div>
            </div>
          ) : (
            undefined
          )}

          <div className="container-fluid">
            <div className="row" style={{ marginBottom: 20 }}>
              <img
                onClick={() => props.history.goBack()}
                src={Back}
                style={{
                  width: 45,
                  height: 35,
                  cursor: "pointer",
                }}
              />
            </div>
          </div>
        </div>
      </div>

      {openEnableDisabledModal && selectedRecord ? (
        <EnableDisableModal
          handleClose={setOpenEnableDisabledModal}
          handleConfirmDisable={handleConfirmDisable}
          disabled={selectedRecord.disabled}
        />
      ) : (
        undefined
      )}
    </div>
  );
}
