import React, { useState, useEffect, useReducer } from "react";
import Notification from "../../components/Snackbar/Notification.js";
import CustomTable from "../../components/Table/Table";
import axios from "axios";
import _ from "lodash";
import Button from "@material-ui/core/Button";
import {
  getEDRsWithCareStream,
  searchEdrWithCareStream,
} from "../../public/endpoins";
import Back from "../../assets/img/Back_Arrow.png";
import Header from "../../components/Header/Header";
import Lab_OPR from "../../assets/img/Pending Tasks.png";
import MedicationRequestIcon from "../../assets/img/carestream/Medication.png";
import ButtonField from "../../components/common/Button";
import Fingerprint from "../../assets/img/fingerprint.png";
import AccountCircle from "@material-ui/icons/SearchOutlined";
import InputAdornment from "@material-ui/core/InputAdornment";
import BarCode from "../../assets/img/Bar Code.png";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import "../../assets/jss/material-dashboard-react/components/loaderStyle.css";
import socketIOClient from "socket.io-client";
import cookie from "react-cookies";
import eye_icon from "../../assets/img/Eye.png";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import PatientDetails from "../../components/PatientDetails/PatientDetails";

const tableHeading = [
  "Medicine Name",
  "Duration",
  "Dosage",
  "Additional Note",
  " ",
];
const tableDataKeys = ["itemName", "duration", "dosage", "additionalNotes"];

// const tableDataKeys = [
//   "mrn",
//   "patientName",
//   "productionArea",
//   "careStream",
//   "createdAt",
// ];
// const tableDataKeys = [
//   "mrn",
//   "patientName",
//   "productionArea",
//   "careStream",
//   "createdAt",
// ];

const styles = {
  textFieldPadding: {
    paddingLeft: 5,
    paddingRight: 5,
  },
};

const useStylesForInput = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(0),
  },
  input: {
    backgroundColor: "white",
    borderRadius: 5,
    "&:after": {
      borderBottomColor: "black",
    },
    "&:hover": {
      backgroundColor: "white",
    },
    "&:focus": {
      boxShadow: "none",
      borderRadius: 5,
    },
  },
  multilineColor: {
    backgroundColor: "white",
    borderRadius: 5,
    "&:hover": {
      backgroundColor: "white",
    },
    "&:after": {
      borderBottomColor: "black",
    },
    "&:focus": {
      backgroundColor: "white",
      boxShadow: "none",
    },
  },
  root: {
    "& .MuiTextField-root": {
      backgroundColor: "white",
    },
    "& .Mui-focused": {
      backgroundColor: "white",
      color: "black",
    },
    "& .Mui-disabled": {
      backgroundColor: "white",
      color: "gray",
    },
    "&:focus": {
      backgroundColor: "white",
      boxShadow: "none",
    },
    "& .MuiFormLabel-root": {
      fontSize: "11px",

      paddingRight: "45px",
    },
  },
  label: {
    "&$focusedLabel": {
      color: "red",
      display: "none",
    },
    // "&$erroredLabel": {
    //   color: "orange"
    // }
  },
  focusedLabel: {},
}));

const actions = { view: true };

export default function PatientDetail(props) {
  const matches = useMediaQuery("(min-width:600px)");
  const classes = useStylesForInput();

  const initialState = {
    medicineItemArray: [],
    diagnosisArray: [],
    medicationArray: [],

    // isBlocked: false,
    // isRecording: false,
    // blobURL: "",
    // fileAudio: null,
    // errorMsg: "",
    // chiefComplaintList: [],
    currentUser: cookie.load("current_user"),
    // successMsg: "",
    // openNotification: false,
    // patientDetails: {}
  };
  const [labInPatient, setlabInPatient] = useState([]);
  const [patientlabRequests, setPatientlabRequests] = useState([]);
  const [patientDetails, setPatientDetails] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [openNotification, setOpenNotification] = useState(false);
  const [searchPatientQuery, setSearchPatientQuery] = useState("");

  function reducer(state, { field, value }) {
    return {
      ...state,
      [field]: value,
    };
  }

  const [state, dispatch] = useReducer(reducer, initialState);
  const [patientDetailsDialog, openPatientDetailsDialog] = useState(false);

  const {
    currentUser,

    // errorMsg,
    // successMsg,
    // openNotification,
    // patientDetails,
    medicineItemArray,
    diagnosisArray,
    medicationArray,
  } = state;

  if (openNotification) {
    setTimeout(() => {
      setOpenNotification(false);
      setErrorMsg("");
    }, 2000);
  }

  useEffect(() => {
    // getPatientData();
    const { selectedRecord } = props.history.location.state;
    console.log("props.history.location.state", selectedRecord);
    Object.entries(selectedRecord.pharmacyRequest).map(([key, val]) => {
      if (val && typeof val === "object") {
        if (key === "item") {
          console.log("value test", val);

          dispatch({ field: "medicineItemArray", value: val });
          //setlabInPatient([val]);
        }
      }
    });

    Object.entries(selectedRecord).map(([key, val]) => {
      if (val && typeof val === "object") {
        if (key === "pharmacyRequest") {
          console.log("value of Pharmacy", val);

          // if (val && val.length > 0) {
          let data = [];
          // val.map((d) => {
          val.item.map((singleCode) => {
            let found = data.find((i) => i === singleCode.itemName);
            if (!found) {
              data.push(singleCode.itemName);
            }
          });
          // });

          console.log(data);
          dispatch({
            field: "medicationArray",
            value: data.reverse(),
          });

          console.log("value of pharmacy request", val);
        } else if (key === "doctorNotes") {
          if (val && val.length > 0) {
            let data = [];
            val.map((d) => {
              d.code.map((singleCode) => {
                let found = data.find((i) => i === singleCode);
                if (!found) {
                  data.push(singleCode);
                }
              });
            });
            console.log("diagnosis aray", data);
            dispatch({ field: "diagnosisArray", value: data });
          }
        }
      }
    });

    //
    const object = {
      patientName:
        selectedRecord.patientId.name[0].given[0] +
        " " +
        selectedRecord.patientId.name[0].family,
      mrn: selectedRecord.patientId.identifier[0].value,
      age: selectedRecord.patientId.age,
      gender: selectedRecord.patientId.gender,
      weight: selectedRecord.patientId.weight,
    };
    setPatientDetails(object);
    console.log("selected object data", object);
    // console.log();

    // return () => socket.disconnect();
  }, []);
  console.log("selected patient ID", medicineItemArray);

  console.log("current user", cookie.load("current_user"));

  function handleView(rec) {
    console.log("rec", rec);

    // if (
    //   rec.chiefComplaint.length > 0 &&
    //   rec.chiefComplaint &&
    //   rec.chiefComplaint[rec.chiefComplaint.length - 1].chiefComplaintId
    // ) {
    //   rec.patientId.chiefComplaint =
    //     rec.chiefComplaint[rec.chiefComplaint.length - 1].chiefComplaintId.name;
    // }

    props.history.push({
      pathname: "/dashboard/labtechnicianForm/",
      state: {
        selectedRecord: rec,
        selectedItem: props.history.location.state,
      },
    });
  }

  function showPatientDetails() {
    openPatientDetailsDialog(false);
  }

  // const addNewItem = () => {
  //   props.history.push({
  //     pathname: 'patientregistration',
  //     state: { comingFor: 'add' },
  //   })
  // }

  console.log(medicationArray, "medication");

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        position: "fixed",
        width: "100%",
        height: "100%",
        backgroundColor: "rgb(19 213 159)",
        overflowY: "scroll",
      }}
    >
      <Header history={props.history} />

      <div className="cPadding">
        <div className="subheader">
          <div>
            <img src={MedicationRequestIcon} />
            <h4>Medication Requests</h4>
          </div>
          <Button
            onClick={() => props.history.goBack()}
            style={{
              ...styles.stylesForButton,
              background: "#2973CF",
              width: matches ? " " : 140,
              fontSize: matches ? " " : 10,
            }}
            variant="contained"
            color="primary"
          >
            <img src={eye_icon} className="icon-view" />
            &nbsp;&nbsp;
            <strong>View All Requests</strong>
          </Button>
          {/* <div>
            <ButtonField onClick={addNewItem} name='add' />
          </div> */}
        </div>

        {/* <div
          className={`${"container-fluid"} ${classes.root}`}
          style={{
            marginTop: "25px",
            paddingLeft: "10px",
            paddingRight: "10px",
          }}
        >
          <div className="row">
            <div
              className="col-md-10 col-sm-9 col-8"
              style={styles.textFieldPadding}
            >
              <TextField
                className="textInputStyle"
                id="searchPatientQuery"
                type="text"
                variant="filled"
                label="Search Patient by Name / MRN / National ID / Mobile Number"
                name={"searchPatientQuery"}
                value={searchPatientQuery}
                onChange={handlePatientSearch}
                InputLabelProps={{
                  classes: {
                    root: classes.label,
                    focused: classes.focusedLabel,
                    error: classes.erroredLabel,
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <AccountCircle />
                    </InputAdornment>
                  ),
                  className: classes.input,
                  classes: { input: classes.input },
                  disableUnderline: true,
                }}
              />
            </div>

            <div
              className="col-md-1 col-sm-2 col-2"
              style={{
                ...styles.textFieldPadding,
                paddingLeft: matches ? 5 : 0,
                paddingRight: matches ? 5 : 3,
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "white",
                  borderRadius: 5,
                  height: 55,
                }}
              >
                <img src={BarCode} style={{ width: 70, height: 60 }} />
              </div>
            </div>

            <div
              className="col-md-1 col-sm-1 col-2"
              style={{
                ...styles.textFieldPadding,
                paddingLeft: matches ? 5 : 2,
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "white",
                  borderRadius: 5,
                  height: 55,
                }}
              >
                <img src={Fingerprint} style={{ maxWidth: 43, height: 43 }} />
              </div>
            </div>
          </div>
        </div> */}

        {/* <div
          className="row container-fluid"
          style={{
            color: "#fff",
            marginTop: 30,
            fontWeight: "900",
            fontFamily: " Open Sans",
          }}
        >
          <h3>Patient Details</h3>
        </div> */}

        {patientDetails ? (
          <PatientDetails
            patientDetails={patientDetails}
            showPatientDetails={showPatientDetails}
            pharmacyRequest={medicationArray}
            diagnosisArray={diagnosisArray}
          />
        ) : (
          undefined
        )}

        <div
          style={{
            flex: 4,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div>
            {medicineItemArray ? (
              <div>
                <div>
                  <CustomTable
                    tableData={medicineItemArray}
                    tableDataKeys={tableDataKeys}
                    tableHeading={tableHeading}
                    //action={actions}
                    // handleView={handleView}
                    borderBottomColor={"#60d69f"}
                    borderBottomWidth={20}
                  />
                </div>
                <div style={{ marginTop: 20, marginBottom: 20 }}>
                  <img
                    onClick={() => props.history.goBack()}
                    src={Back}
                    style={{
                      width: 45,
                      height: 35,
                      cursor: "pointer",
                    }}
                  />
                </div>
                <Notification msg={errorMsg} open={openNotification} />
              </div>
            ) : (
              <div className="row " style={{ marginTop: "25px" }}>
                <div className="col-11">
                  <h3
                    style={{
                      color: "white",
                      textAlign: "center",
                      width: "100%",
                      position: "absolute",
                    }}
                  >
                    Opps...No Data Found
                  </h3>
                </div>
                <div className="col-1" style={{ marginTop: 45 }}>
                  <img
                    onClick={() => props.history.goBack()}
                    src={Back}
                    style={{
                      maxWidth: "60%",
                      height: "auto",
                      cursor: "pointer",
                    }}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
