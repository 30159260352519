import { connectRouter } from 'connected-react-router'
import CheckingReducer from './CheckingReducer';
import patientReducer from './patientReducer';


export default (history) => ({
  router: connectRouter(history),
  CheckingReducer:CheckingReducer,
  PatientReducer: patientReducer
});
