import axios from 'axios'
import { getProductionTeams, getProductionStaff, searchCCPatients, getSingleProductionTeam } from '../../public/endpoins'
import _ from 'lodash'

export function getAllProductionTeams(setData, setErrorMsg, setOpenNotification) {
  axios
    .get(getProductionTeams)
    .then((res) => {
      console.log('response', res.data.items)
      if (res.data.success) {

        res.data.items.map(
          (d) => (
            (d.noDoctors = d.doctors.length),
            (d.noNurses = d.nurses.length),
            (d.noNurseTechnicians = d.nurseTechnicians.length)
          )
        );

        setData(res.data.items)
      } else if (!res.data.success) {
        setErrorMsg(res.data.error)
        setOpenNotification(true)
      }
      return res
    })
    .catch((e) => {
      console.log('error: ', e)
    })
}



export function getAllProductionStaff(setDoctors, setNurses, setNurseTechnicians, setErrorMsg, setOpenNotification) {
  axios
    .get(getProductionStaff)
    .then((res) => {
      console.log('response', res.data.items)
      if (res.data.success) {
        let doctors      = res.data.data.doctors;
        let nurses       = res.data.data.nurses;
        let technicians  = res.data.data.technicians;

        let doctorData = [];
        let nurseData = [];
        let technData = [];

        Object.entries(doctors).map((v, i) => {
          doctorData.push({ id: v[1]._id, fullName: (v[1].name[0].given[0] + " " + v[1].name[0].family) });
        });

        Object.entries(nurses).map((v, i) => {
          nurseData.push({ id: v[1]._id, fullName: (v[1].name[0].given[0] + " " + v[1].name[0].family) });
        });

        Object.entries(technicians).map((v, i) => {
          technData.push({ id: v[1]._id, fullName: (v[1].name[0].given[0] + " " + v[1].name[0].family) });
          // technData.push(v[1]._id);
        });


        setDoctors(doctorData);
        setNurses(nurseData);
        setNurseTechnicians(technData);

        console.log('getAllProductionStaff:: finish', doctors, nurses, technicians);

        /*res.data.items.map(
          (d) => (
            (d.noDoctors = d.doctors.length),
            (d.noNurses = d.nurses.length),
            (d.noNurseTechnicians = d.nurseTechnicians.length)
          )
        );

        setData(res.data.items)*/
      } else if (!res.data.success) {
        setErrorMsg(res.data.error)
        setOpenNotification(true)
      }
      return res
    })
    .catch((e) => {
      console.log('error: ', e)
    })
}

export function SearchProductionArea(setData, a) {
  axios
    .get(searchCCPatients + '/' + a)
    .then((res) => {
      console.log('searched response', res)
      if (res.data.success) {
        if (res.data.data) {
          res.data.data.map(
            (d) =>
              (d.patientName =
                d.patientId.name[0].given[0] + ' ' + d.patientId.name[0].family)
          )
          res.data.data.map((d) => (d.createdAt = d.patientId.createdAt))
          res.data.data.map((d) => (d.mrn = d.patientId.identifier[0].value))
          res.data.data.map(
            (d) =>
              (d.chiefCompl =
                d.chiefComplaint.length > 0 &&
                d.chiefComplaint[d.chiefComplaint.length - 1]
                  .chiefComplaintId &&
                d.chiefComplaint[d.chiefComplaint.length - 1].chiefComplaintId
                  .name)
          )
          var sortSearchedObj = _.sortBy(res.data.data, 'createdAt').reverse()
          setData(sortSearchedObj)
        } else {
          setData(' ')
        }
      }
    })
    .catch((e) => {
      console.log('error after searching patient request', e)
    })
}

export function getProductionTeam(editId, setDoctorsSelected, setNursesSelected, setNurseTechniciansSelected, dispatch) {
  console.log("getProductionTeamItem", editId);
  axios
    .get(`${getSingleProductionTeam}/${editId}`)
    .then((res) => {
      console.log("getProductionTeamItem", res);
      let remoteData = res.data.data;
      dispatch({
        field: 'teamName',
        value: remoteData.name,
      });

      let doctors      = remoteData.doctors;
      let nurses       = remoteData.nurses;
      let technicians  = remoteData.nurseTechnicians;

      console.log('getAllProductionStaff', doctors);

      let doctorData = [];
      let doctorDataDispatch = [];
      let nurseData = [];
      let nurseDataDispatch = [];
      let technData = [];
      let technDataDispatch = [];

      Object.entries(doctors).map((v, i) => {
        doctorData.push({ id: v[1]._id, fullName: (v[1].name[0].given[0] + " " + v[1].name[0].family) });
        doctorDataDispatch.push(v[1]._id);
      });
      dispatch({ field: 'doctors', value: doctorDataDispatch });

      Object.entries(nurses).map((v, i) => {
        nurseData.push({ id: v[1]._id, fullName: (v[1].name[0].given[0] + " " + v[1].name[0].family) });
        nurseDataDispatch.push(v[1]._id);
      });
      dispatch({ field: 'nurses', value: nurseDataDispatch });

      Object.entries(technicians).map((v, i) => {
        technData.push({ id: v[1]._id, fullName: (v[1].name[0].given[0] + " " + v[1].name[0].family) });
        technDataDispatch.push(v[1]._id);
      });
      dispatch({ field: 'nurseTechnicians', value: technDataDispatch });

      console.log("teamEdit", doctorData, nurseData, technData)
      setDoctorsSelected(doctorData);
      setNursesSelected(nurseData);
      setNurseTechniciansSelected(technData);

      // setData(res.data.data);
      return res;
    })
    .catch((e) => {
      console.log("getProductionTeamDetails:: error: ", e);
    });
}
