import axios from "axios";
import {
  getPendingRadHKRequests,
  getCompletedRadHKRequests,
  getPendingSenseiHKRequests,
  getCompletedSenseiHKRequests,
  updateRadHKRequestStatus,
  updateSenseiStatus,
  getPendingEDNurseHKRequests,
  completedEDNurseHKRequests,
  updateEDNurseStatus,
} from "../../public/endpoins";
import FormatDate from "../../utils/formatDate";

export const getPendingSenseiCall = (
  setPendingSenseiArray,
  setErrorMsg,
  setOpenNotification
) => {
  axios
    .get(getPendingSenseiHKRequests)
    .then((response) => {
      response.data.data.map(
        (val) => (
          (val.roomNo = val.roomId?.roomNo),
          (val.productionArea = val.productionAreaId?.paName),
          (val.assignedTime = FormatDate(val.assignedTime))
        )
      );
      setPendingSenseiArray(response.data.data);
    })
    .catch((error) => {
      setOpenNotification(true);
      setErrorMsg(error.message);
    });
};

export const getCompletedSenseiCall = (
  setCompletedSenseiArray,
  setErrorMsg,
  setOpenNotification
) => {
  axios
    .get(getCompletedSenseiHKRequests)
    .then((response) => {
      response.data.data.map(
        (val) => (
          (val.roomNo = val.roomId.roomNo),
          (val.productionArea = val.productionAreaId.paName),
          (val.assignedTime = FormatDate(val.assignedTime))
        )
      );
      setCompletedSenseiArray(response.data.data);
    })
    .catch((error) => {
      console.log("Error", error);
      setOpenNotification(true);
      setErrorMsg(error);
    });
};

export const getPendingImgTechnicianCall = (
  setPendingImgTechnicianArray,
  setErrorMsg,
  setOpenNotification
) => {
  axios
    .get(getPendingRadHKRequests)
    .then((response) => {
      response.data.data.map(
        (val) => (
          (val.roomNo = val.roomId.roomNo),
          (val.productionArea = val.productionAreaId.paName),
          (val.assignedTime = FormatDate(val.assignedTime))
        )
      );
      setPendingImgTechnicianArray(response.data.data);
    })
    .catch((error) => {
      console.log("Error", error);
      setOpenNotification(true);
      setErrorMsg(error);
    });
};

export const getCompletedImgTechnicianCall = (
  setCompletedImgTechnicianArray,
  setErrorMsg,
  setOpenNotification
) => {
  axios
    .get(getCompletedRadHKRequests)
    .then((response) => {
      response.data.data.map(
        (val) => (
          (val.roomNo = val.roomId.roomNo),
          (val.productionArea = val.productionAreaId.paName),
          (val.assignedTime = FormatDate(val.assignedTime))
        )
      );
      setCompletedImgTechnicianArray(response.data.data);
    })
    .catch((error) => {
      console.log("Error", error);
      setOpenNotification(true);
      setErrorMsg(error);
    });
};

export const getEDNurseCall = (
  buttonValue,
  setEdNurseArray,
  setErrorMsg,
  setOpenNotification
) => {
  let apiCall;
  if (buttonValue === "pending") {
    apiCall = getPendingEDNurseHKRequests;
  } else if (buttonValue === "completed") {
    apiCall = completedEDNurseHKRequests;
  }
  axios
    .get(apiCall)
    .then((response) => {
      response.data.data.map(
        (val) => (
          (val.roomNo = val.roomId.roomNo),
          (val.productionArea = val.productionAreaId.paName),
          (val.assignedTime = FormatDate(val.assignedTime))
        )
      );
      setEdNurseArray(response.data.data);
    })
    .catch((error) => {
      console.log("Error", error);
      setOpenNotification(true);
      setErrorMsg(error);
    });
};

export function updateStatus(props, params, setOpenNotification, setErrorMsg) {
  axios
    .put(updateRadHKRequestStatus, params)
    .then((res) => {
      if (res.data.success) {
        props.history.push({
          pathname: "success",
          state: {
            reqCode: `${res.data.data.requestNo}`,
            message3: `Housekeeping task completed Successfully`,
          },
        });
      } else if (!res.data.success) {
        setOpenNotification(true);
        setErrorMsg("Error while updating the status");
      }
    })
    .catch((e) => {
      setOpenNotification(true);
      setErrorMsg("Error while updating the status");
    });
}

export function updateSenseiStatusCall(
  props,
  params,
  setOpenNotification,
  setErrorMsg
) {
  axios
    .put(updateSenseiStatus, params)
    .then((res) => {
      if (res.data.success) {
        props.history.push({
          pathname: "success",
          state: {
            reqCode: `${res.data.data.requestNo}`,
            message3: `Housekeeping task completed Successfully`,
          },
        });
      } else if (!res.data.success) {
        setOpenNotification(true);
        setErrorMsg("Error while updating the status");
      }
    })
    .catch((e) => {
      setOpenNotification(true);
      setErrorMsg("Error while updating the status");
    });
}

export function updateEDNurseStatusCall(
  props,
  params,
  setOpenNotification,
  setErrorMsg
) {
  axios
    .put(updateEDNurseStatus, params)
    .then((res) => {
      if (res.data.success) {
        props.history.push({
          pathname: "success",
          state: {
            reqCode: `${res.data.data.requestNo}`,
            message3: `Housekeeping task completed Successfully`,
          },
        });
      } else if (!res.data.success) {
        setOpenNotification(true);
        setErrorMsg("Error while updating the status");
      }
    })
    .catch((e) => {
      setOpenNotification(true);
      setErrorMsg("Error while updating the status");
    });
}
