import React, { useEffect, useState } from "react";
import moment from "moment";
import {
  LineChart as CustomLineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
} from "recharts";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles({
  root: {
    minWidth: 20,
    backgroundColor: "#F26D91",
  },
  title: {
    padding: 10,
    fontSize: 14,
    color: "#ffff",
  },
});

const getIntroOfPage = (pointedDateTime, data) => {
  if (data && data.length) {
    const filteredObj = data.find(
      ({ label: dateTime }) => dateTime === pointedDateTime
    );
    return filteredObj.value;
  }
  return null;
};

const CustomTooltip = (receivedProps) => {
  const classes = useStyles();
  const { active, label: dateTime, data } = receivedProps;
  if (active) {
    return (
      <Card className={classes.root}>
        <Typography
          className={classes.title}
          color="textSecondary"
          gutterBottom
        >
          {`value : ${getIntroOfPage(dateTime, data)}`}
        </Typography>
      </Card>
    );
  }
  return null;
};

function LineChart(props) {
  const { data, width, height } = props;
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    const sortedTimestamps = data
      .map((item) => item.label)
      .sort((a, b) => a - b);
    const chartMappedData = data.map((item, i) => ({
      value: item.value,
      label: sortedTimestamps[i],
    }));
    chartMappedData && chartMappedData.length && setChartData(chartMappedData);
  }, [data]);

  return (
    chartData &&
    chartData.length && (
      <CustomLineChart
        data={chartData}
        width={width}
        height={height}
        style={{ marginRight: 40 }}
      >
        <Line
          type="monotone"
          dataKey="value"
          stroke="#F26D91"
          style={{ strokeWidth: "4px" }}
        />
        <CartesianGrid stroke="#ccc" strokeDasharray="3 3" vertical={false} />
        <XAxis
          interval={0}
          dataKey="label"
          tickFormatter={(label) => moment(label).format("HH:mm")}
          minTickGap={50}
          type="category"
          axisLine={false}
          tickLine={false}
          style={{ fontSize: "15px", opacity: "70%" }}
          scale="auto"
        />
        <YAxis
          dataKey="value"
          axisLine={false}
          tickLine={false}
          style={{ fontSize: "15px", opacity: "70%" }}
        />
        <Tooltip content={<CustomTooltip data={chartData} />} />
      </CustomLineChart>
    )
  );
}

export default React.memo(LineChart);
