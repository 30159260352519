// import React, { useState } from 'react'
// import Button from '@material-ui/core/Button'
// import Dialog from '@material-ui/core/Dialog'
// import DialogActions from '@material-ui/core/DialogActions'
// import DialogContent from '@material-ui/core/DialogContent'
// import DialogContentText from '@material-ui/core/DialogContentText'
// import DialogTitle from '@material-ui/core/DialogTitle'

// import TextField from '@material-ui/core/TextField'

// export default function EnableDisableModal(props) {
//   const [reason, setReason] = useState('')
//   return (
//     <div>
//       <Dialog
//         open={true}
//         onClose={props.handleClose}
//         aria-labelledby='alert-dialog-title'
//         aria-describedby='alert-dialog-description'
//         fullWidth={true}
//         maxWidth={'md'}
//       >
//         <DialogContent style={{ backgroundColor: '#31e2aa' }}>
//           <DialogTitle
//             id='simple-dialog-title'
//             style={{ color: 'white', marginLeft: -20 }}
//           >
//             Add Consultation Note
//           </DialogTitle>
//           <div className={`container-fluid`}>
//             <div className='row'>
//               <div
//                 className='col-md-12 col-sm-12 col-12'
//                 style={{
//                   ...styles.inputContainerForTextField,
//                   ...styles.textFieldPadding,
//                 }}
//               >
//                 <TextField
//                   required
//                   multiline
//                   rows={4}
//                   label='Comments/Notes'
//                   name={'doctorconsultationNotes'}
//                   // value={doctorconsultationNotes}
//                   // error={doctorconsultationNotes === '' && isFormSubmitted}
//                   // onChange={onChangeValue}
//                   className='textInputStyle'
//                   variant='filled'
//                   variant='filled'
//                   InputProps={{
//                     className: classes.multilineColor,
//                     classes: {
//                       input: classes.multilineColor,
//                     },
//                   }}
//                 />
//               </div>
//             </div>

//             <div className='row'>
//               <div
//                 className='col-md-12 col-sm-12 col-12'
//                 style={{
//                   ...styles.inputContainerForTextField,
//                   ...styles.textFieldPadding,
//                 }}
//               >
//                 <TextField
//                   required
//                   multiline
//                   rows={4}
//                   label='Description'
//                   name={'description'}
//                   // value={description}
//                   // error={description === '' && isFormSubmitted}
//                   onChange={onChangeValue}
//                   className='textInputStyle'
//                   variant='filled'
//                   InputProps={{
//                     className: classes.multilineColor,
//                     classes: {
//                       input: classes.multilineColor,
//                     },
//                   }}
//                 />
//               </div>
//             </div>

//             <div className='row'>
//               <div
//                 className='col-md-6'
//                 style={{
//                   ...styles.inputContainerForTextField,
//                   ...styles.textFieldPadding,
//                 }}
//               >
//                 <TextField
//                   required
//                   select
//                   fullWidth
//                   label='Speciality'
//                   name={'speciality'}
//                   // value={speciality}
//                   // error={speciality === '' && isFormSubmitted}
//                   onChange={onChangeValue}
//                   variant='filled'
//                   className='dropDownStyle'
//                   InputProps={{
//                     className: classes.input,
//                     classes: { input: classes.input },
//                     disableUnderline: true,
//                   }}
//                 >
//                   <MenuItem value=''>
//                     <em>Speciality</em>
//                   </MenuItem>

//                   {/* {specialityArray.map((val) => {
//                       return (
//                         <MenuItem key={val.key} value={val.key}>
//                           {val.value}
//                         </MenuItem>
//                       )
//                     })} */}
//                 </TextField>
//               </div>
//               <div
//                 className='col-md-6'
//                 style={{
//                   ...styles.inputContainerForTextField,
//                   ...styles.textFieldPadding,
//                 }}
//               >
//                 <TextField
//                   required
//                   select
//                   fullWidth
//                   label='Select Consultant/Specialist'
//                   name={'specialist'}
//                   // value={specialist}
//                   // error={specialist === '' && isFormSubmitted}
//                   onChange={onChangeValue}
//                   variant='filled'
//                   className='dropDownStyle'
//                   InputProps={{
//                     className: classes.input,
//                     classes: { input: classes.input },
//                     disableUnderline: true,
//                   }}
//                 >
//                   <MenuItem value=''>
//                     <em>Specialist</em>
//                   </MenuItem>

//                   {/* {specialistArray.map((val) => {
//                       return (
//                         <MenuItem key={val.key} value={val.key}>
//                           {val.value}
//                         </MenuItem>
//                       )
//                     })} */}
//                 </TextField>
//               </div>
//             </div>

//             <div
//               class='row'
//               style={{
//                 display: 'flex',
//                 justifyContent: 'space-between',
//                 paddingLeft: 5,
//                 paddingRight: 5,
//                 marginTop: 20,
//               }}
//             >
//               <div style={{ marginTop: '2%', marginBottom: '2%' }}>
//                 <Button
//                   // onClick={() => hideDialog()}
//                   style={{
//                     ...styles.stylesForButton,
//                     backgroundColor: 'white',
//                     color: 'grey',
//                   }}
//                   variant='contained'
//                 >
//                   <strong>Cancel</strong>
//                 </Button>
//               </div>

//               <div
//                 style={{
//                   display: 'flex',
//                   justifyContent: 'flex-end',
//                   marginTop: '2%',
//                   marginBottom: '2%',
//                 }}
//               >
//                 <Button
//                   style={{
//                     color: 'white',
//                     cursor: 'pointer',
//                     borderRadius: 5,
//                     backgroundColor: '#2c6ddd',
//                     width: '140px',
//                     height: '50px',
//                     outline: 'none',
//                     paddingLeft: 30,
//                     paddingRight: 30,
//                   }}
//                   // disabled={!validateForm()}
//                   // onClick={addConsultRequest}
//                   variant='contained'
//                   color='primary'
//                 >
//                   Submit
//                 </Button>
//               </div>
//             </div>
//           </div>
//         </DialogContent>
//       </Dialog>
//     </div>
//   )
// }
