/*eslint-disable*/
import React, { useState, useEffect, useReducer } from "react";
import Button from "@material-ui/core/Button";
import Notification from "../../components/Snackbar/Notification.js";
import CustomTable from "../../components/Table/Table";
import Loader from "react-loader-spinner";
import Header from "../../components/Header/Header";
import PendingTasksIcon from "../../assets/img/Pending Tasks.png";
import eye_icon from "../../assets/img/Eye.png";
import Back from "../../assets/img/Back_Arrow.png";
import "../../assets/jss/material-dashboard-react/components/loaderStyle.css";
import useStylesForInput from "../../assets/jss/material-dashboard-react/inputStyle";
import cookie from "react-cookies";
import TextField from "@material-ui/core/TextField";
import { styles } from "./styles.js";
import { Grid } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import MenuItem from "@material-ui/core/MenuItem";
import { audioURL } from "../../public/endpoins";
import {
  getPendingTasksForED,
  getCompletedTasksForED,
  getPendingTasksForEOU,
  getCompletedTasksForEOU,
  editNurseCall,
  getNurseCall,
} from "./networkCallsForTasksList";

const tableHeading = [
  "MRN",
  "Patient Name",
  "Date/Time",
  "Task Details",
  "Status",
  "Action",
];
const tableDataKeys = ["mrn", "patientName", "requestedAt", "notes", "status"];

const actionsForPending = { edit: true };
const actionsForCompleted = { assigned: true };

export const statusArray = [
  {
    key: "Pending",
    value: "pending",
  },
  {
    key: "Completed",
    value: "completed",
  },
];

export default function TasksList(props) {
  const classes = useStylesForInput();
  const matches = useMediaQuery("(min-width:600px)");

  const initialState = {
    edNurseSpeciality: "",
    edNurse: "",
    requestDescription: "",
    requestStatus: "",
    isRecording: false,
    blobURL: "",
  };

  function reducer(state, { field, value }) {
    return {
      ...state,
      [field]: value,
    };
  }

  const [state, dispatch] = useReducer(reducer, initialState);

  const { requestDescription, requestStatus, isRecording, blobURL } = state;

  const onChangeValue = (e) => {
    dispatch({
      field: e.target.name,
      value: e.target.value.replace(/[^\w\s]/gi, ""),
    });
  };

  const [currentUser] = useState(cookie.load("current_user"));
  const [errorMsg, setErrorMsg] = useState("");
  const [openNotification, setOpenNotification] = useState(false);
  const [statusName, setStatusName] = useState("Pending");
  const [openDialog, setOpenDialog] = useState(false);
  const [viewRecord, setViewRecord] = useState(false);
  const [dialogForm, setDialogForm] = useState(false);
  const [selectedNurseNoteToEdit, setSelectedNurseNoteToEdit] = useState("");
  const [pendingTasks, setPendingTasks] = useState([]);
  const [completedTasks, setCompletedTasks] = useState([]);

  if (openNotification) {
    setTimeout(() => {
      setOpenNotification(false);
      setErrorMsg("");
    }, 2000);
  }

  useEffect(() => {
    if (currentUser.subType.includes("ED Nurse")) {
      getPendingTasksForED(
        currentUser._id,
        setPendingTasks,
        setOpenNotification,
        setErrorMsg
      );
      getCompletedTasksForED(
        currentUser._id,
        setCompletedTasks,
        setOpenNotification,
        setErrorMsg
      );
    } else if (currentUser.subType.includes("EOU Nurse")) {
      getPendingTasksForEOU(
        currentUser._id,
        setPendingTasks,
        setOpenNotification,
        setErrorMsg
      );
      getCompletedTasksForEOU(
        currentUser._id,
        setCompletedTasks,
        setOpenNotification,
        setErrorMsg
      );
    }
  }, []);

  const handleChange = (e, tabValue) => {
    setStatusName(tabValue);
  };

  const handleEdit = (rec) => {
    console.log("Edit record", rec);
    setSelectedNurseNoteToEdit(rec);
    const url = `${audioURL}/${rec.voiceNotes}`;

    getNurseCall(
      currentUser.subType,
      rec,
      url,
      dispatch,
      setOpenDialog,
      currentUser._id
    );
  };

  const handleView = (rec) => {
    console.log("View record", rec);
    setViewRecord(true);
    setSelectedNurseNoteToEdit(rec);
    const url = `${audioURL}/${rec.voiceNotes}`;

    getNurseCall(currentUser.subType, rec, url, dispatch, setOpenDialog);
  };

  function hideDialog() {
    setViewRecord(false);
    setOpenDialog(false);
    setSelectedNurseNoteToEdit("");

    dispatch({ field: "blobURL", value: "" });
    dispatch({ field: "requestDescription", value: "" });
    dispatch({ field: "requestStatus", value: "" });
    dispatch({ field: "edNurse", value: "" });
  }

  const validateEDNurse = () => {
    if (requestStatus.length === 0 || requestStatus === "pending") {
      return false;
    } else {
      return true;
    }
  };

  const handleEditNurseNote = () => {
    let object = {
      edrId: selectedNurseNoteToEdit._id,
      requestId: currentUser.subType.includes("ED Nurse")
        ? selectedNurseNoteToEdit.edNurseRequest._id
        : selectedNurseNoteToEdit.eouNurseRequest._id,
    };
    console.log("Params for edit : ", object);

    editNurseCall(currentUser.subType, object, setOpenDialog, props);
    setDialogForm(true);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        position: "fixed",
        width: "100%",
        height: "100%",
        backgroundColor: "#60d69f",
        overflowY: "scroll",
      }}
    >
      <Header history={props.history} />

      <div className="cPadding">
        <div
          className="subheader"
          style={{ paddingRight: "10px", marginLeft: "-5px" }}
        >
          <div>
            <img src={PendingTasksIcon} />
            <h4>Task List</h4>
          </div>

          {statusName === "Pending" ? (
            <Button
              onClick={(e) => handleChange(e, "Completed")}
              style={styles.stylesForButton}
              variant="contained"
              color="primary"
            >
              <img src={eye_icon} className="icon-view" />
              &nbsp;&nbsp;
              <strong style={{ fontSize: matches ? " " : 10 }}>
                Completed Tasks
              </strong>
            </Button>
          ) : (
            <Button
              onClick={(e) => handleChange(e, "Pending")}
              style={styles.stylesForButton}
              variant="contained"
              color="primary"
            >
              <img src={eye_icon} className="icon-view" />
              &nbsp;&nbsp;
              <strong style={{ fontSize: matches ? " " : 10 }}>
                Pending Tasks
              </strong>
            </Button>
          )}
        </div>

        <div className="container-fluid">
          {statusName === "Pending" && pendingTasks.length > 0 ? (
            <div className="row">
              <div className="col-md-12 col-sm-12 col-12">
                <CustomTable
                  tableData={pendingTasks}
                  tableDataKeys={tableDataKeys}
                  tableHeading={tableHeading}
                  action={actionsForPending}
                  handleEdit={handleEdit}
                  borderBottomColor={"#60d69f"}
                  borderBottomWidth={20}
                />
              </div>
            </div>
          ) : statusName === "Completed" && completedTasks.length > 0 ? (
            <div className="row">
              <div className="col-md-12 col-sm-12 col-12">
                <CustomTable
                  tableData={completedTasks}
                  tableDataKeys={tableDataKeys}
                  tableHeading={tableHeading}
                  action={actionsForCompleted}
                  handleView={handleView}
                  borderBottomColor={"#60d69f"}
                  borderBottomWidth={20}
                />
              </div>
            </div>
          ) : pendingTasks.length === 0 || completedTasks.length === 0 ? (
            <div className="row" style={{ marginTop: "25px" }}>
              <div className="col-md-12 col-sm-12 col-12">
                <h3
                  style={{
                    color: "white",
                    textAlign: "center",
                  }}
                >
                  Opps! No Data Found
                </h3>
              </div>
            </div>
          ) : (
            <div className="LoaderStyle">
              <Loader type="TailSpin" color="red" height={50} width={50} />
            </div>
          )}

          <div className="row" style={{ marginBottom: 20 }}>
            <div className="col-md-12 col-sm-12 col-12">
              <img
                onClick={() => props.history.goBack()}
                src={Back}
                style={{
                  width: 45,
                  height: 35,
                  cursor: "pointer",
                }}
              />
            </div>
          </div>
        </div>

        <Dialog
          fullScreen
          aria-labelledby="form-dialog-title"
          open={openDialog}
          maxWidth="xl"
          fullWidth={true}
        >
          <DialogContent style={{ backgroundColor: "rgb(19 213 159)" }}>
            <DialogTitle
              id="simple-dialog-title"
              style={{ color: "white", marginLeft: -9 }}
            >
              {currentUser.subType.includes("ED Nurse")
                ? viewRecord
                  ? "View ED Nurse Request"
                  : "Edit ED Nurse Request"
                : viewRecord
                ? "View EOU Nurse Request"
                : "Edit EOU Nurse Request"}
            </DialogTitle>
            <div className={`container-fluid ${classes.root}`}>
              <div className="row">
                <div
                  className="col-md-12 col-sm-12 col-12"
                  style={{
                    ...styles.inputContainerForTextField,
                    ...styles.textFieldPadding,
                    padding: matches ? "" : "0px",
                  }}
                >
                  <TextField
                    required
                    multiline
                    disabled
                    rows={4}
                    label={"Description"}
                    name={"requestDescription"}
                    value={requestDescription}
                    error={requestDescription === "" && dialogForm}
                    onChange={onChangeValue}
                    className="textInputStyle"
                    variant="filled"
                    InputProps={{
                      className: classes.multilineColor,
                      classes: {
                        input: classes.multilineColor,
                      },
                    }}
                  />
                </div>
              </div>

              <h5
                style={{
                  color: "white",
                  fontWeight: "bold",
                  marginTop: 10,
                }}
              >
                Voice Notes
              </h5>
              <div className="row">
                <div
                  className="col-md-12 col-sm-12 col-12"
                  style={{
                    padding: matches ? "" : "0px",
                  }}
                >
                  <Grid
                    justify="flex-start"
                    container
                    spacing={24}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      backgroundColor: "white",
                      marginLeft: 0,
                      marginRight: 0,
                      marginTop: 15,
                      borderRadius: 5,
                      height: 125,
                      marginBottom: 15,
                      paddingLeft: 10,
                    }}
                  >
                    <div>
                      {!isRecording ? (
                        <audio
                          style={{
                            marginRight: 10,
                            width: matches ? 450 : 220,
                            marginTop: 6,
                            outline: "none",
                          }}
                          src={blobURL}
                          controls="controls"
                        />
                      ) : (
                        <div
                          style={{
                            marginLeft: matches ? 110 : 18,
                            marginRight: matches ? 110 : 75,
                            width: matches ? 550 : 100,
                          }}
                        >
                          <Loader
                            type="ThreeDots"
                            color="blue"
                            height={40}
                            width={40}
                          />
                        </div>
                      )}
                    </div>
                  </Grid>
                </div>
              </div>

              <div className="row">
                <div
                  className="col-12"
                  style={{
                    ...styles.inputContainerForTextField,
                    ...styles.textFieldPadding,
                    padding: matches ? "" : "0px",
                  }}
                >
                  <TextField
                    required
                    select
                    disabled={viewRecord ? true : false}
                    label="Status"
                    name={"requestStatus"}
                    value={requestStatus}
                    onChange={onChangeValue}
                    className="textInputStyle"
                    variant="filled"
                    InputProps={{
                      className: classes.input,
                      classes: { input: classes.input },
                      disableUnderline: true,
                    }}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>

                    {statusArray.map((val) => {
                      return (
                        <MenuItem key={val.key} value={val.value}>
                          {val.key}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingLeft: 5,
                  paddingRight: 5,
                  marginTop: 20,
                }}
              >
                <div style={{ marginTop: "2%", marginBottom: "2%" }}>
                  <Button
                    onClick={() => hideDialog()}
                    style={{
                      ...styles.stylesForButton,
                      backgroundColor: "white",
                      color: "grey",
                      width: matches ? " " : 150,
                    }}
                    variant="contained"
                  >
                    <strong>Cancel</strong>
                  </Button>
                </div>
                {!viewRecord ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      marginTop: "2%",
                      marginBottom: "2%",
                    }}
                  >
                    <Button
                      disabled={!validateEDNurse()}
                      style={styles.stylesForButton}
                      onClick={handleEditNurseNote}
                      variant="contained"
                      color="primary"
                    >
                      Submit
                    </Button>
                  </div>
                ) : (
                  undefined
                )}
              </div>
            </div>
          </DialogContent>
        </Dialog>

        <Notification msg={errorMsg} open={openNotification} />
      </div>
    </div>
  );
}
