import React, { useState, useEffect, useReducer } from "react";
import Notification from "../../components/Snackbar/Notification.js";
import CustomTable from "../../components/Table/Table";
import _ from "lodash";
import Loader from "react-loader-spinner";
import Back from "../../assets/img/Back_Arrow.png";
import Header from "../../components/Header/Header";
import ED_Icon from "../../assets/img/ED.png";
import EOU_Icon from "../../assets/img/EOU.png";
import Fingerprint from "../../assets/img/fingerprint.png";
import AccountCircle from "@material-ui/icons/SearchOutlined";
import InputAdornment from "@material-ui/core/InputAdornment";
import BarCode from "../../assets/img/Bar Code.png";
import TextField from "@material-ui/core/TextField";
import useStyles from "../../assets/jss/material-dashboard-react/greyInputStyle";
import styles from "./styles";
import "../../assets/jss/material-dashboard-react/components/loaderStyle.css";
import cookie from "react-cookies";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {
  getAllEdrPatients,
  getAllEOUPatients,
  searchEdrPatientsFor,
} from "./NetworkCallForEDEOU";
import QRCodeScannerComponent from "../../components/QRCodeScanner/QRCodeScanner";

const tableHeading = [
  "MRN",
  "Patient Name",
  "Gender",
  "Age",
  "Date/Time",
  "Status",
  "Action",
];
const tableDataKeys = [
  "mrn",
  "patientName",
  "gender",
  "age",
  "createdAt",
  "status",
];

const actions = { view: true };

export default function EDEOU(props) {
  const matches = useMediaQuery("(min-width:600px)");
  const classes = useStyles();

  const [currentUser] = useState(cookie.load("current_user"));
  const [patientData, setPatientData] = useState([]);
  const [errorMsg, setErrorMsg] = useState("");
  const [openNotification, setOpenNotification] = useState(false);
  const [pathname, setPathname] = useState("");
  const [searchPatientQuery, setSearchPatientQuery] = useState("");
  const [loading, setLoading] = useState(true);
  const [QRCodeScanner, setQRCodeScanner] = useState(false);

  if (openNotification) {
    setTimeout(() => {
      setOpenNotification(false);
      setErrorMsg("");
    }, 2000);
  }

  useEffect(() => {
    let path = props.location.pathname.split("/");
    let pathName = path[path.length - 1];

    if (pathName === "ED") {
      getAllEdrPatients(
        setLoading,
        setPatientData,
        setErrorMsg,
        setOpenNotification
      );
    } else if (pathName === "EOU") {
      getAllEOUPatients(
        setLoading,
        setPatientData,
        setErrorMsg,
        setOpenNotification
      );
    }
    setPathname(pathName);
  }, []);

  function handleView(rec) {
    console.log("view record", rec);
    let path = `/dashboard/home/notes`;
    props.history.push({
      pathname: path,
      state: {
        selectedRecord: rec,
      },
    });
  }

  const handlePatientSearch = (e) => {
    const a = e.target.value.replace(/[^\w\s]/gi, "");
    setSearchPatientQuery(a);
    if (a.length >= 3) {
      searchEdrPatientsFor(
        a,
        pathname,
        setPatientData,
        setErrorMsg,
        setOpenNotification
      );
    } else if (a.length == 0) {
      if (pathname === "ED") {
        getAllEdrPatients(
          setLoading,
          setPatientData,
          setErrorMsg,
          setOpenNotification
        );
      } else if (pathname === "EOU") {
        getAllEOUPatients(
          setLoading,
          setPatientData,
          setErrorMsg,
          setOpenNotification
        );
      }
    }
  };

  function scanQRCode() {
    setQRCodeScanner((pervState) => !pervState);
  }

  function handleScanQR(data) {
    setQRCodeScanner(false);
    console.log("json", JSON.parse(data));
    if (JSON.parse(data).profileNo) {
      handlePatientSearch({
        target: {
          value: JSON.parse(data).profileNo,
          type: "text",
        },
      });
    }
  }

  if (QRCodeScanner) {
    return (
      <div>
        {QRCodeScanner ? (
          <QRCodeScannerComponent
            handleScanQR={handleScanQR}
            scanQRCode={scanQRCode}
          />
        ) : (
          undefined
        )}
      </div>
    );
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        position: "fixed",
        width: "100%",
        height: "100%",
        backgroundColor: "rgb(19 213 159)",
        overflowY: "scroll",
      }}
    >
      <Header history={props.history} />

      <div className="cPadding">
        <div className="subheader">
          <div>
            <img
              src={
                pathname === "ED"
                  ? ED_Icon
                  : pathname === "EOU"
                  ? EOU_Icon
                  : undefined
              }
            />
            <h4>
              {pathname === "ED"
                ? "Emergency Department"
                : pathname === "EOU"
                ? "Emergency Observation Unit"
                : ""}
            </h4>
          </div>
        </div>

        <div className={`container-fluid ${classes.root}`}>
          <div className="row" style={{ marginTop: "20px" }}>
            <div
              className="col-md-10 col-sm-9 col-8"
              style={{ ...styles.textFieldPadding, paddingLeft: "15px" }}
            >
              <TextField
                className="textInputStyle"
                id="searchPatientQuery"
                type="text"
                variant="filled"
                label="Search Patient by Name / MRN / National ID / Mobile Number"
                name={"searchPatientQuery"}
                value={searchPatientQuery}
                onChange={handlePatientSearch}
                InputLabelProps={{
                  classes: {
                    root: classes.label,
                    focused: classes.focusedLabel,
                    error: classes.erroredLabel,
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <AccountCircle />
                    </InputAdornment>
                  ),
                  className: classes.input,
                  classes: { input: classes.input },
                  disableUnderline: true,
                }}
              />
            </div>
            <div
              className="col-md-1 col-sm-2 col-2"
              style={{
                paddingLeft: matches ? "" : 0,
                paddingRight: matches ? "" : 3,
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "white",
                  borderRadius: 5,
                  height: 55,
                }}
              >
                <img
                  src={BarCode}
                  onClick={scanQRCode}
                  style={{ width: 70, height: 60 }}
                />
              </div>
            </div>
            <div
              className="col-md-1 col-sm-1 col-2"
              style={{
                paddingLeft: matches ? "5" : 2,
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "white",
                  borderRadius: 5,
                  height: 55,
                }}
              >
                <img src={Fingerprint} style={{ maxWidth: 43, height: 43 }} />
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid">
          <div className="row">
            {patientData.length > 0 && !loading ? (
              <div className="col-12">
                <CustomTable
                  tableData={patientData}
                  tableDataKeys={tableDataKeys}
                  tableHeading={tableHeading}
                  action={actions}
                  handleView={handleView}
                  borderBottomColor={"#60d69f"}
                  borderBottomWidth={20}
                />
              </div>
            ) : loading ? (
              <div style={{ margin: "auto", width: "0%", padding: 10 }}>
                <Loader type="TailSpin" color="blue" height={50} width={50} />
              </div>
            ) : (
              <div className="col-12">
                <h3
                  style={{
                    color: "white",
                    textAlign: "center",
                    margin: "revert",
                  }}
                >
                  Opss! No Data Found
                </h3>
              </div>
            )}
          </div>

          <div
            className="row "
            style={{ marginTop: "25px", marginBottom: "20px" }}
          >
            <div className="col-12">
              <img
                onClick={() => props.history.goBack()}
                src={Back}
                style={{
                  width: 45,
                  height: "auto",
                  cursor: "pointer",
                }}
              />
            </div>
          </div>
        </div>
        <Notification msg={errorMsg} open={openNotification} />
      </div>
    </div>
  );
}
