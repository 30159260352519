/*eslint-disable*/
import React, { useState, useEffect, useReducer } from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import MultiSlider, { Progress, Dot } from "react-multi-bar-slider";
import { transform } from "lodash";

export default function CommunicationMgmt(props) {
  const matches = useMediaQuery("(min-width:600px)");

  function handleSlide(newProgress) {
    if (props.scale === 10) {
      var scaled;
      if (newProgress > 0 && newProgress <= 5) {
        scaled = 0;
      } else if (newProgress > 5 && newProgress <= 10) {
        scaled = 1;
      } else if (newProgress > 10 && newProgress <= 20) {
        scaled = 2;
      } else if (newProgress > 20 && newProgress <= 30) {
        scaled = 3;
      } else if (newProgress > 30 && newProgress <= 40) {
        scaled = 4;
      } else if (newProgress > 40 && newProgress <= 50) {
        scaled = 5;
      } else if (newProgress > 50 && newProgress <= 60) {
        scaled = 6;
      } else if (newProgress > 60 && newProgress <= 70) {
        scaled = 7;
      } else if (newProgress > 70 && newProgress <= 80) {
        scaled = 8;
      } else if (newProgress > 80 && newProgress <= 90) {
        scaled = 9;
      } else if (newProgress > 90 && newProgress <= 100) {
        scaled = 10;
      }
      props.onSlide ? props.onSlide(scaled) : "";
    } else {
      props.onSlide ? props.onSlide(newProgress) : "";
    }
  }

  return (
    <div
      style={{
        position: matches ? "absolute" : "initial",
        top: props.top ? props.top : 0,
        left: props.left ? props.left : 0,
        bottom: props.bottom ? props.bottom : 0,
        // right: props.right ? props.right : 0,
      }}
    >
      <MultiSlider
        width={280}
        height={17}
        slidableZoneSize={100}
        backgroundColor="bisque"
        equalColor="blue"
        style={{ marginBottom: 40 }}
        onSlide={handleSlide}
        roundedCorners
        readOnly={props.readOnly}
      >
        <Progress
          color={
            props.progressValue > props.scale === 10
              ? 7
              : 80
              ? "#60d69f"
              : props.progressValue > props.scale === 10
              ? 4
              : 20
              ? "#ffbc28"
              : props.progressValue >= 0
              ? "#d73c3f"
              : "#d73c3f"
          }
          // progress={props.progressValue}
        >
          <Dot
            color={
              props.progressValue > props.scale === 10
                ? 7
                : 80
                ? "#60d69f"
                : props.progressValue > props.scale === 10
                ? 4
                : 20
                ? "#ffbc28"
                : props.progressValue >= 0
                ? "#d73c3f"
                : "#d73c3f"
            }
            width={0}
            height={20}
          />
        </Progress>

        <Progress color="#60d69f" progress={props.scale === 10 ? 30 : 20}>
          <Dot
            color="grey"
            color={"blue"}
            width={10}
            height={30}
            style={{ display: "none" }}
          />
        </Progress>

        <Progress color="#ffbc28" progress={props.scale === 10 ? 70 : 80}>
          <Dot color="grey" width={0} height={0} style={{ display: "none" }} />
        </Progress>

        <Progress color="#d73c3f" progress={100}>
          <Dot color="grey" width={0} height={0} style={{ display: "none" }} />
        </Progress>
      </MultiSlider>

      <div style={{ marginTop: 45 }}>
        <span
          style={{
            position: "absolute",
            marginTop: -40,
            marginLeft: 1,
            fontSize: 13,
          }}
        >
          0
        </span>

        <span
          style={{
            position: "absolute",
            marginTop: -40,
            marginLeft: props.scale === 10 ? 80 : 48,
            fontSize: 13,
          }}
        >
          {props.scale === 10 ? "3" : "20"}
        </span>

        <span
          style={{
            position: "absolute",
            marginTop: -40,
            marginLeft: props.scale === 10 ? 190 : 210,
            fontSize: 13,
          }}
        >
          {props.scale === 10 ? "7" : "80"}
        </span>

        <span
          style={{
            position: "absolute",
            marginTop: -40,
            marginLeft: 268,
            fontSize: 13,
          }}
        >
          {props.scale === 10 ? "10" : "100"}
        </span>
      </div>

      <div
        style={{
          marginLeft: matches ? " " : 15,
          width: 5,
          height: 30,
          marginLeft: !matches ? `${props.progressValue + 10}` : null,
          backgroundColor: "blue",
          zIndex: 3,
          marginTop: -68,
          borderRadius: 10,
          position: "absolute",
          left: matches
            ? `${
                props.scale === 10
                  ? props.progressValue * 10 - 1
                  : props.progressValue - 0.5
              }%`
            : `${
                props.scale === 10
                  ? props.progressValue + 1
                  : props.progressValue - 0.5
              }%`,
        }}
      >
        <span
          style={{
            marginTop: -23,
            position: "absolute",
            marginLeft: -5,
            fontSize: 13,
            color: "blue",
            fontWeight: "bold",
          }}
        >
          {props.progressValue}
        </span>
      </div>
    </div>
  );
}
