import React from "react";
import { Redirect } from "react-router-dom";
import cookie from "react-cookies";
import Back from "../../../assets/img/Back_Arrow.png";
import MenuTree from "../../../components/MenuTree/MenuTree";
import Header from "../../../components/Header/Header";
// Sensei Icons
import PatientManagementIcon from "../../../assets/img/PatientManagement.png";
import CareStreamsIcon from "../../../assets/img/CareStreams Management.png";
import PatientRegistration from "../../../assets/img/PatientRegistration.png";

const SenseiPanel = [
  { img: PatientManagementIcon, text: "CareStream" },
  {
    img: CareStreamsIcon,
    text: "Assign CareStream",
    path: "/dashboard/home/patientmanagement/careStreamPatients",
  },
  {
    img: PatientRegistration,
    text: "Complete CareStream",
    path: "/dashboard/home/patientmanagement/CareStreamInProgress",
  },
];



class PatientManagement extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      openApps: false,
      goBack: false,
      currentUser: "",
    };
  }

  componentDidMount() {
    this.setState({ currentUser: cookie.load("current_user") });
  }

  render() {
    const userType = this.state.currentUser;
    if (this.state.goBack) {
      return <Redirect to={"/management"} />;
    }

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          position: "fixed",
          width: "100%",
          height: "100%",
          background:
            "linear-gradient(25deg, rgba(101,228,193,1) 0%, rgba(58,219,175,1) 33%, rgba(15,206,147,1) 66%, rgba(6,142,103,1) 100%)",
          backgroundSize: "100%",
        }}
      >
        <Header history={this.props.history} />

        <MenuTree
          history={this.props.history}
          options={
            SenseiPanel}
        />

        <div
          style={{
            position: "fixed",
            width: "100%",
            height: "10%",
            top: "90%",
          }}
        >
          <img
            alt="Back"
            onClick={() => this.props.history.goBack()}
            src={Back}
            style={{
              width: 45,
              height: 35,
              marginLeft: "5%",
              cursor: "pointer",
            }}
          />
        </div>
      </div>
    );
  }
}
export default PatientManagement;
