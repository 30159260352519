import React, { useState, useEffect, useReducer } from "react";
import Notification from "../../components/Snackbar/Notification.js";
import CustomTable from "../../components/Table/Table";
import axios from "axios";
import _ from "lodash";
import {
  assignPAtoCC,
  getAvailablePA,
  getAllChiefComplaints,
  getAvailablePAwithCC,
  searchCCandPA,
} from "../../public/endpoins";
import Loader from "react-loader-spinner";
import Back from "../../assets/img/Back_Arrow.png";
import Header from "../../components/Header/Header";
import chiefCompIcon from "../../assets/img/Chief Complaints.png";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import "../../assets/jss/material-dashboard-react/components/loaderStyle.css";
import Button from "@material-ui/core/Button";
import plus_icon from "../../assets/img/Plus.png";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import cookie from "react-cookies";
import AccountCircle from "@material-ui/icons/SearchOutlined";
import InputAdornment from "@material-ui/core/InputAdornment";
import Fingerprint from "../../assets/img/fingerprint.png";
import BarCode from "../../assets/img/Bar Code.png";
import MenuItem from "@material-ui/core/MenuItem";
import useMediaQuery from "@material-ui/core/useMediaQuery";
const tableHeading = [
  "Chief-Complaints ID",
  "Chief-Complaints",
  "Production Area",
  "Date/Time",
  " ",
];
const tableDataKeys = [
  "chiefComplaintId",
  "name",
  "productionAreaName",
  "updatedAt",
];

const styles = {
  textFieldPadding: {
    paddingLeft: 5,
    paddingRight: 5,
  },
  stylesForButton: {
    color: "white",
    cursor: "pointer",
    borderRadius: 5,
    background: "#2c6ddd",
    width: "140px",
    height: "50px",
    outline: "none",
  },
  stylesForCancelButton: {
    color: "gray",
    cursor: "pointer",
    borderRadius: 5,
    width: "140px",
    height: "50px",
    background: "transparent",
    border: "1px solid gray",
  },
};

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(0),
  },
  input: {
    backgroundColor: "white",
    boxShadow: "none",
    borderRadius: 5,
    "&:after": {
      borderBottomColor: "black",
      boxShadow: "none",
    },
    "&:hover": {
      backgroundColor: "white",
      boxShadow: "none",
    },
    "&:focus": {
      backgroundColor: "white",
      boxShadow: "none",
      borderRadius: 5,
    },
  },
  multilineColor: {
    boxShadow: "none",
    backgroundColor: "white",
    borderRadius: 5,
    "&:hover": {
      backgroundColor: "white",
      boxShadow: "none",
    },
    "&:after": {
      borderBottomColor: "black",
      boxShadow: "none",
    },
    "&:focus": {
      boxShadow: "none",
    },
  },
  root: {
    "& .MuiTextField-root": {
      backgroundColor: "white",
    },
    "& .Mui-focused": {
      backgroundColor: "white",
      color: "black",
      boxShadow: "none",
    },
    "& .Mui-disabled": {
      backgroundColor: "white",
      color: "gray",
    },
    "&:focus": {
      backgroundColor: "white",
      boxShadow: "none",
    },
    "& .MuiFormLabel-root": {
      fontSize: "11px",
      padding: "2px",
    },
  },
}));

export default function CCproductionArea(props) {
  const matches = useMediaQuery("(min-width:600px)");
  const initialState = {
    ccName: "",
    availablePA: "",
  };

  function reducer(state, { field, value }) {
    return {
      ...state,
      [field]: value,
    };
  }

  const [state, dispatch] = useReducer(reducer, initialState);

  const { ccName, availablePA } = state;

  const onChangeValue = (e) => {
    dispatch({ field: e.target.name, value: e.target.value });
  };

  const classes = useStyles();
  const [currentUser, setcurrentUser] = useState(cookie.load("current_user"));
  const [ccData, setccData] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setsuccessMsg] = useState("");
  const [openNotification, setOpenNotification] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [searchCC, setSearchCC] = useState("");
  const [avaiablePAs, setAvaiablePAs] = useState([]);
  const [chiefComplaints, setChiefComplaints] = useState([]);

  if (openNotification) {
    setTimeout(() => {
      setOpenNotification(false);
      setErrorMsg("");
      setsuccessMsg("");
    }, 2000);
  }

  useEffect(() => {
    getCCData();
    getAvailablePAdata();
    getCCProductionAreaData();
  }, []);

  function getCCData() {
    axios
      .get(getAllChiefComplaints)
      .then((res) => {
        if (res.data.success) {
          console.log(res.data.data.docs, "Chief Complaints");
          setChiefComplaints(res.data.data.docs);
        } else if (!res.data.success) {
          setErrorMsg(res.data.error);
          setOpenNotification(true);
        }
        return res;
      })
      .catch((e) => {
        console.log("error: ", e);
      });
  }

  function getAvailablePAdata() {
    axios
      .get(getAvailablePA)
      .then((res) => {
        if (res.data.success) {
          console.log(res.data.data, "available PAs");
          setAvaiablePAs(res.data.data);
        } else if (!res.data.success) {
          setErrorMsg(res.data.error);
          setOpenNotification(true);
        }
        return res;
      })
      .catch((e) => {
        console.log("error: ", e);
      });
  }

  function getCCProductionAreaData() {
    axios
      .get(getAvailablePAwithCC)
      .then((res) => {
        if (res.data.success) {
          console.log(res.data.data, "get CC PA Data");
          /*res.data.data.map(
            (d) =>
              (d.productionAreaName =
                d.productionArea && d.productionArea.length > 0
                  ? d.productionArea[d.productionArea.length - 1]
                       .productionAreaId.paName
                  : "")
          );*/
          res.data.data.map(
            (d) =>
              (
                  d.productionAreaName =
                    d.productionArea && d.productionArea.length > 0
                    ? (
                        (
                          d.productionArea[d.productionArea.length - 1].productionAreaId ? d.productionArea[d.productionArea.length - 1].productionAreaId.paName : ""
                        ): ""
                      )
                    : ""
              )
          );
          // res.data.data.map(
          //   (d) => (d.productionAreaName = d.productionArea.length)
          // );
          console.log("getAvailablePAwithCC", res.data.data);
          setccData(res.data.data.reverse());
        } else if (!res.data.success) {
          setErrorMsg(res.data.error);
          setOpenNotification(true);
        }
        return res;
      })
      .catch((e) => {
        console.log("error: ", e);
      });
  }

  const handleCCsearch = (e) => {
    const a = e.target.value.replace(/[^\w\s]/gi, "");
    setSearchCC(a);
    if (a.length >= 3) {
      axios
        .get(searchCCandPA + "/" + a)
        .then((res) => {
          if (res.data.success) {
            if (res.data.data.length > 0) {
              console.log("results of search ", res.data.data);
              res.data.data.map(
                (d) =>
                  (d.productionArea =
                    d.productionArea && d.productionArea.length > 0
                      ? d.productionArea[d.productionArea.length - 1]
                          .productionAreaId.paName
                      : "")
              );
              setccData(res.data.data);
            } else {
              setccData([]);
            }
          }
        })
        .catch((e) => {
          console.log("error after searching Chief Complaints", e);
        });
    } else if (a.length === 0) {
      console.log("less");
      getCCProductionAreaData();
    }
  };

  const addNewCC = () => {
    setModalVisible(true);
  };

  const handleAssignCC = () => {
    let params = {
      productionAreaId: availablePA,
      assignedBy: currentUser._id,
      chiefComplaintId: ccName,
    };
    if (params.chiefComplaintId.length > 0 && params.productionAreaId) {
      axios
        .put(assignPAtoCC, params)
        .then((res) => {
          if (res.data.success) {
            setOpenNotification(true);
            setsuccessMsg("Chief Complaint Assigned Successfully");
            getCCProductionAreaData();
          } else if (!res.data.success) {
            setOpenNotification(true);
            setErrorMsg(res.data.data);
          }
        })
        .catch((e) => {
          console.log("error after assigning Chief Complaint", e);
          setOpenNotification(true);
          setErrorMsg(
            "Production area has already been assigned to a chief complaint"
          );
        });
      onModalHide();
    } else {
      setOpenNotification(true);
      setErrorMsg("Please Fill the Empty Feilds");
    }
  };

  const onModalHide = () => {
    dispatch({ field: "ccName", value: "" });
    dispatch({ field: "availablePA", value: "" });
    setModalVisible(false);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        position: "fixed",
        width: "100%",
        height: "100%",
        backgroundColor: "rgb(19 213 159)",
        overflowY: "scroll",
      }}
    >
      <Header history={props.history} />

      <div className="cPadding">
        <div className="subheader">
          <div>
            <img src={chiefCompIcon} />
            <h4>CC Production Area</h4>
          </div>
          <Button
            onClick={addNewCC}
            style={{
              ...styles.stylesForButton,
              width: matches ? 140 : 100,
              height: matches ? 50 : 40,
              fontSize: matches ? " " : 9,
            }}
            variant="contained"
            color="primary"
          >
            <img src={plus_icon} className="icon-style" />
            &nbsp;&nbsp;
            <strong>Add New</strong>
          </Button>
        </div>

        <div className={` ${classes.root}`} style={{ marginTop: "25px" }}>
          <div className="row" style={{ marginTop: "20px" }}>
            <div
              className="col-md-10 col-sm-9 col-8"
              style={{
                paddingRight: matches ? 5 : 0,
              }}
            >
              <TextField
                className="textInputStyle"
                id="searchCC"
                type="text"
                variant="filled"
                label="Search Chief Complaint"
                name={"searchCC"}
                value={searchCC}
                onChange={handleCCsearch}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <AccountCircle />
                    </InputAdornment>
                  ),
                  className: classes.input,
                  classes: { input: classes.input },
                  disableUnderline: true,
                }}
              />
            </div>

            <div
              className="col-md-1 col-sm-2 col-2"
              style={{
                ...styles.textFieldPadding,
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "white",
                  borderRadius: 5,
                  height: 55,
                }}
              >
                <img src={BarCode} style={{ width: 70, height: 60 }} />
              </div>
            </div>

            <div
              className="col-md-1 col-sm-1 col-2"
              style={{ paddingLeft: matches ? 5 : 0 }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "white",
                  borderRadius: 5,
                  height: 55,
                }}
              >
                <img src={Fingerprint} style={{ maxWidth: 43, height: 43 }} />
              </div>
            </div>
          </div>
        </div>

        <div
          className=""
          style={{
            flex: 4,
            display: "flex",
            flexDirection: "column",
          }}
        >
          {ccData && ccData.length > 0 ? (
            <div>
              <div>
                <CustomTable
                  tableData={ccData}
                  tableDataKeys={tableDataKeys}
                  tableHeading={tableHeading}
                  borderBottomColor={"#60d69f"}
                  borderBottomWidth={20}
                />
              </div>
              <div style={{ marginTop: 20, marginBottom: 20 }}>
                <img
                  onClick={() => props.history.goBack()}
                  src={Back}
                  style={{
                    width: 45,
                    height: 35,
                    cursor: "pointer",
                  }}
                />
              </div>
            </div>
          ) : ccData && ccData.length == 0 ? (
            <div className="row " style={{ marginTop: "25px" }}>
              <div className="col-md-12 col-sm-12 col-12">
                <h3
                  style={{
                    color: "white",
                    textAlign: "center",
                    width: "100%",
                    position: "absolute",
                  }}
                >
                  Oops! No Data Found
                </h3>
              </div>
              <div
                className="col-md-12 col-sm-12 col-12"
                style={{ marginTop: 45 }}
              >
                <img
                  onClick={() => props.history.goBack()}
                  src={Back}
                  style={{ maxWidth: "60%", height: "auto", cursor: "pointer" }}
                />
              </div>
            </div>
          ) : (
            <>
              <div className="row">
                <div className="LoaderStyle">
                  <Loader type="TailSpin" color="red" height={50} width={50} />
                </div>
              </div>
              <div className="row">
                <div
                  className="col-md-12 col-sm-12 col-12"
                  style={{ marginTop: 45 }}
                >
                  <img
                    onClick={() => props.history.goBack()}
                    src={Back}
                    style={{
                      maxWidth: "60%",
                      height: "auto",
                      cursor: "pointer",
                    }}
                  />
                </div>
              </div>
            </>
          )}
        </div>

        <Dialog
          onClose={() => setModalVisible(false)}
          fullWidth={true}
          maxWidth={"lg"}
          bodyStyle={{ backgroundColor: "red" }}
          contentStyle={{ backgroundColor: "red" }}
          aria-labelledby="simple-dialog-title"
          open={modalVisible}
        >
          <DialogContent>
            <div className="container-fluid">
              <div className="row">
                <div className="d-flex justify-content-start">
                  <DialogTitle
                    id="simple-dialog-title"
                    style={{ marginLeft: matches ? "-12px" : -28 }}
                  >
                    <h4
                      style={{
                        fontSize: matches ? " " : 12,
                        fontWeight: matches ? "" : "bold",
                      }}
                    >
                      Assign Chief Complaint to Production Area
                    </h4>
                  </DialogTitle>
                </div>
              </div>

              <div className={`row ${classes.root}`}>
                <div
                  className="col-md-12 col-sm-12 col-12"
                  style={styles.textFieldPadding}
                >
                  <TextField
                    disabled
                    label="Assigned By"
                    name={"currentUser"}
                    value={
                      currentUser.name[0].given[0] +
                      " " +
                      currentUser.name[0].family
                    }
                    onChange={onChangeValue}
                    className="textInputStyle"
                    variant="filled"
                    InputProps={{
                      className: classes.input,
                      classes: { input: classes.input },
                      disableUnderline: true,
                    }}
                  />
                </div>
              </div>

              <div className={`row ${classes.root}`}>
                <div
                  className="col-md-12 col-sm-12 col-12"
                  style={styles.textFieldPadding}
                >
                  <TextField
                    select
                    fullWidth
                    label="Chief-Complaint Name / Category"
                    name="ccName"
                    value={ccName}
                    onChange={onChangeValue}
                    variant="filled"
                    className="textInputStyle"
                    InputProps={{
                      className: classes.input,
                      classes: { input: classes.input },
                    }}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>

                    {chiefComplaints.map((val) => {
                      return (
                        <MenuItem key={val._id} value={val._id}>
                          {val.name}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </div>
              </div>

              <div className={`row ${classes.root}`}>
                <div
                  className="col-md-12 col-sm-12 col-12"
                  style={styles.textFieldPadding}
                >
                  <TextField
                    select
                    fullWidth
                    label="Available Production Area"
                    name="availablePA"
                    value={availablePA}
                    onChange={onChangeValue}
                    variant="filled"
                    className="textInputStyle"
                    InputProps={{
                      className: classes.input,
                      classes: { input: classes.input },
                    }}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>

                    {avaiablePAs.map((val) => {
                      return (
                        <MenuItem key={val._id} value={val._id}>
                          {val.paName}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </div>
              </div>

              <div style={{ display: "flex", justifyContent: "center" }}>
                <div
                  style={{
                    display: "flex",
                    marginTop: matches ? "2%" : "10%",
                    marginBottom: "2%",
                  }}
                >
                  <Button
                    style={{
                      ...styles.stylesForCancelButton,
                      width: matches ? 140 : 100,
                      height: matches ? 50 : 40,
                    }}
                    onClick={() => onModalHide()}
                    variant="contained"
                  >
                    Cancel
                  </Button>
                  &nbsp;&nbsp;
                  <Button
                    style={{
                      ...styles.stylesForButton,
                      width: matches ? 140 : 100,
                      height: matches ? 50 : 40,
                    }}
                    onClick={handleAssignCC}
                    variant="contained"
                  >
                    Assign
                  </Button>
                </div>
              </div>
            </div>
          </DialogContent>
        </Dialog>

        <Notification
          msg={errorMsg}
          success={successMsg}
          open={openNotification}
        />
      </div>
    </div>
  );
}
