import React, { useEffect, useState } from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import Flip from "../../../components/Dial/Flip";
import TwoValue from "../../../components/Dial/TwoValue";
import OneValue from "../../../components/Dial/OneValue";
import SliderComponent from "../../../components/BedsAvailableSlider/bedsAvailableSlider";
import { senseiDashboard } from "../../../public/endpoins";
import LineChart from "../../../components/Dial/LineChart";
import Loader from "react-loader-spinner";
import {
  getDialerGraphCall,
  getValuesCardCalls,
  getResponse,
} from "./NetworkCalls";
import {
  socketUrl
} from '../../../public/endpoins'
import socketIOClient from 'socket.io-client'

// import axios from 'axios'
// import { registrationOfficer } from '../../public/endpoins'

export default function CommitteeMemberDashboard() {
  const theme = useTheme();

  const matches = useMediaQuery("(min-width:600px)");
  const matchesForTablet = useMediaQuery(theme.breakpoints.up("md"));
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setsuccessMsg] = useState("");
  const [openNotification, setOpenNotification] = useState(false);
  const [isLoading, setisLoading] = useState(true);
  const [responseData, setResponseData] = useState([]);

  // Patient Diagnosis Pending
  const [diagnosisPending, setDiagnosisPending] = useState("");
  const [diagnosisPendingColor, setDiagnosisPendingColor] = useState("");
  const [diagnosisPendingTAT, setDiagnosisPendingTAT] = useState("");
  const [diagnosisPendingTATColor, setDiagnosisPendingTATColor] = useState("");
  const [diagnosisPendingGraphData, setDiagnosisPendingGraphData] = useState(
    ""
  );

  // Patient Diagnosis Pending
  const [decisionPending, setDecisionPending] = useState("");
  const [decisionPendingColor, setDecisionPendingColor] = useState("");
  const [decisionPendingTAT, setDecisionPendingTAT] = useState("");
  const [decisionPendingTATColor, setDecisionPendingTATColor] = useState("");
  const [decisionPendingGraphData, setDecisionPendingGraphData] = useState("");

  // Patient Discharges Pending
  const [dischargesPending, setDischargesPending] = useState("");
  const [dischargesPendingColor, setDischargesPendingColor] = useState("");
  const [dischargesPendingTAT, setDischargesPendingTAT] = useState("");
  const [dischargesPendingTATColor, setDischargesPendingTATColor] = useState(
    ""
  );
  const [dischargesPendingGraphData, setDischargesPendingGraphData] = useState(
    ""
  );

  // Consultant Notes Pending
  const [consultantPending, setConsultantPending] = useState("");
  const [consultantPendingColor, setConsultantPendingColor] = useState("");
  const [consultationOrderTAT, setConsultationOrderTAT] = useState("");
  const [consultationOrderTATColor, setConsultationOrderTATColor] = useState(
    ""
  );
  const [consultantNotesGraphData, setConsultantNotesGraphData] = useState("");

  // Lab Pending
  const [labPending, setLabPending] = useState("");
  const [labPendingColor, setLabPendingColor] = useState("");
  const [labTAT, setLabTAT] = useState("");
  const [labTATColor, setLabTATColor] = useState("");
  const [labPendingGraphData, setLabPendingGraphData] = useState("");

  // Rad Pending
  const [radPending, setRadPending] = useState("");
  const [radPendingColor, setRadPendingColor] = useState("");
  const [radTAT, setRadTAT] = useState("");
  const [radTATColor, setRadTATColor] = useState("");
  const [radPendingGraphData, setRadPendingGraphData] = useState("");

  // Order Pending
  const [orderPending, setOrderPending] = useState("");
  const [orderPendingColor, setOrderPendingColor] = useState("");
  const [orderTAT, setOrderTAT] = useState("");
  const [orderTATColor, setOrderTATColor] = useState("");
  const [orderPendingGraphData, setOrderPendingGraphData] = useState("");

  // Ninth Card
  const [ninthCard, setNinthCard] = useState("");
  const [ninthCardColor, setNinthCardColor] = useState("");
  const [ninthCardTAT, setNinthCardTAT] = useState("");
  const [ninthCardTATColor, setNinthCardTATColor] = useState("");
  const [ninthCardGraphData, setNinthCardGraphData] = useState("");

  // Tenth Card
  const [tenthCard, setTenthCard] = useState("");
  const [tenthCardColor, setTenthCardColor] = useState("");
  const [tenthCardTAT, setTenthCardTAT] = useState("");
  const [tenthCardTATColor, setTenthCardTATColor] = useState("");
  const [tenthCardGraphData, setTenthCardGraphData] = useState("");

  // Values cards

  const [comulativeTotal, setComulativeTotal] = useState("");
  const [comulativeTotalColor, setComulativeTotalColor] = useState("");

  const [dummyData, setDummyData] = useState("");

  // colors
  const [parPendingColor, setParPendingColor] = useState("");
  const [totalInsuredColor, setTotalInsuredColor] = useState("");
  const [totalUnInsuredColor, setTotalUnInsuredColor] = useState("");
  const [patientColor, setPatientColor] = useState("");
  const [perHourColor, setPerHourColor] = useState("");
  const [enableEDBedsProgress, setEnableEDBedsProgress] = useState(true);
  const [enableIPBedsProgressBar, setEnableIPBedsProgressBar] = useState(true);
  const [change, setChange] = useState(true);
  const [options, setOptions] = useState("");
  const [bedLineChartWidth, setBedLineChartWidth] = useState(
    window.innerWidth <= 600 ? 300 : 420
  );

  const [flip, setFlip] = useState(false);
  const [flag1Count, setFlag1Count] = useState(0)
  const [flag2Count, setFlag2Count] = useState(0)
  const [flag3Count, setFlag3Count] = useState(0)
  const [flag4Count, setFlag4Count] = useState(0)
  const [flag5Count, setFlag5Count] = useState(0)
  const [flag6Count, setFlag6Count] = useState(0)
  const [flag7Count, setFlag7Count] = useState(0)
  const [flag9Count, setFlag9Count] = useState(0)

  const [ipBeds, setIPBeds] = useState(70);
  const [edBeds, setEDBeds] = useState(20);
  const graphModel = {
    width: 320,
    height: 70,
    scaleWidth: 180,
    scaleHeight: 100,
  };

  // useEffect(() => {
  //   let temp = []
  //   let count = 1
  //   for (let i = 6; i > 0; i--) {
  //     let date = new Date()
  //     let hourago = new Date(date.getTime() - 1000 * 60 * 60 * i)
  //     var hours = hourago.getHours()
  //     hours = hours % 12
  //     hours = hours ? hours : 12 // the hour '0' should be '12'
  //     temp.push({
  //       label: count,
  //       value: 10 * count - i,
  //     })
  //     count++
  //   }

  //   setOptions(temp)
  // }, [])

  // useEffect(() => {
  //   const checkWindowWidth = () => {
  //     console.log('resize called', window.innerWidth)
  //     if (bedLineChartWidth > 600 && window.innerWidth <= 600) {
  //       console.log('100 value set')
  //       setBedLineChartWidth(100)
  //     } else if (bedLineChartWidth <= 600 && window.innerWidth >= 600) {
  //       console.log('420 value set')
  //       setBedLineChartWidth(420)
  //     }
  //   }
  //   window.addEventListener('resize', () => checkWindowWidth())
  // }, [])

  useEffect(() => {
    getResponse(
      senseiDashboard,
      setResponseData,
      setOpenNotification,
      setErrorMsg,
      setisLoading
    );
    console.log("*in getResponse edDoctor");

    const flagCount = [{ value: '1st', setFlagCount: (value) => setFlag1Count(value) },
    { value: '2nd', setFlagCount: (value) => setFlag2Count(value) },
    { value: '3rd', setFlagCount: (value) => setFlag3Count(value) },
    { value: '4th', setFlagCount: (value) => setFlag4Count(value) },
    { value: '5th', setFlagCount: (value) => setFlag5Count(value) }]

    const flagCountFromRegistration = [
      { value: '5th', setFlagCount: (value) => setFlag9Count(value) }];
    const flagCountFromRadTechnician = [
      { value: '1st', setFlagCount: (value) => setFlag6Count(value) }];

    const socket = socketIOClient(socketUrl)
    socket.emit('sensei_flags')
    socket.on('pendingSensei', (data) => {
      console.log('response coming through socket for Sensei Flags', data)
      flagCount.forEach(({ value, setFlagCount }) => {
        const cardCount = data.filter(item => item.card === value).length;
        cardCount && setFlagCount(cardCount);
      })

      console.log('data', data)

    })
    socket.emit('ro_flags')
    socket.on('pendingRO', (data) => {
      console.log('response coming through socket for RO Flags', data)
      flagCountFromRegistration.forEach(({ value, setFlagCount }) => {
        const cardCount = data.filter(item => item.card === value).length;
        cardCount && setFlagCount(cardCount);
      })

      console.log('data', data)

    })
    socket.emit('rad_flags')
    socket.on('pendingRad', (data) => {
      console.log('response coming through socket for Rad Flags', data)
      flagCountFromRadTechnician.forEach(({ value, setFlagCount }) => {
        const cardCount = data.filter(item => item.card === value).length;
        cardCount && setFlagCount(cardCount);
      })

      console.log('data', data)

    })

  }, []);

  console.log("diagnosisPending", diagnosisPending);

  if (openNotification) {
    setTimeout(() => {
      setOpenNotification(false);
      setErrorMsg("");
      setsuccessMsg("");
    }, 2000);
  }

  useEffect(() => {
    let arr = [];

    Object.entries(responseData).map(([key, val]) => {
      if (key !== "success") {
        console.log("key of data", key, "val of data", val);
        arr.push({ [key]: val });
      }
    });

    console.log("arr::", arr);
    if (arr && arr.length > 0) {
      getDialerGraphCall(
        3,
        5,
        3,
        6,
        arr[1].firstCard,
        setDiagnosisPending,
        setDiagnosisPendingColor,
        setDiagnosisPendingTAT,
        setDiagnosisPendingTATColor,
        setDiagnosisPendingGraphData
      );
      getDialerGraphCall(
        3,
        5,
        3,
        6,
        arr[2].secondCard,
        setDecisionPending,
        setDecisionPendingColor,
        setDecisionPendingTAT,
        setDecisionPendingTATColor,
        setDecisionPendingGraphData
      );
      getDialerGraphCall(
        3,
        5,
        45,
        60,
        arr[3].thirdCard,
        setDischargesPending,
        setDischargesPendingColor,
        setDischargesPendingTAT,
        setDischargesPendingTATColor,
        setDischargesPendingGraphData
      );
      getDialerGraphCall(
        3,
        5,
        15,
        30,
        arr[4].fourthCard,
        setConsultantPending,
        setConsultantPendingColor,
        setConsultationOrderTAT,
        setConsultationOrderTATColor,
        setConsultantNotesGraphData
      );
      getDialerGraphCall(
        3,
        5,
        30,
        45,
        arr[5].fifthCard,
        setLabPending,
        setLabPendingColor,
        setLabTAT,
        setLabTATColor,
        setLabPendingGraphData
      );
      getDialerGraphCall(
        3,
        5,
        60,
        180,
        arr[6].sixthCard,
        setRadPending,
        setRadPendingColor,
        setRadTAT,
        setRadTATColor,
        setRadPendingGraphData
      );
      getDialerGraphCall(
        3,
        6,
        10,
        20,
        arr[7].seventhCard,
        setOrderPending,
        setOrderPendingColor,
        setOrderTAT,
        setOrderTATColor,
        setOrderPendingGraphData
      );
      getDialerGraphCall(
        2,
        4,
        20,
        30,
        arr[8].ninthCard,
        setNinthCard,
        setNinthCardColor,
        setNinthCardTAT,
        setNinthCardTATColor,
        setNinthCardGraphData
      );
      getDialerGraphCall(
        3,
        6,
        45,
        60,
        arr[9].tenthCard,
        setTenthCard,
        setTenthCardColor,
        setTenthCardTAT,
        setTenthCardTATColor,
        setTenthCardGraphData
      );
      getValuesCardCalls(
        40,
        60,
        arr[10].cumulativePatient,
        setComulativeTotal,
        setComulativeTotalColor
      );
    }
  }, [responseData]);

  useEffect(() => {
    let temp = [
      { label: "2021-02-16T12:48:26.948Z", value: 5 },
      { label: "2021-02-16T11:48:26.948Z", value: 4 },
      { label: "2021-02-16T10:48:26.948Z", value: 10 },
      { label: "2021-02-16T09:48:26.948Z", value: 12 },
      { label: "2021-02-16T08:48:26.948Z", value: 9 },
      { label: "2021-02-16T07:48:26.948Z", value: 1 },
    ];

    setDummyData(temp);
  }, []);

  console.log("responsese daaaa", responseData);

  const flipIt = () => {
    setFlip(true);
  };

  // const handleSlide = (slideValue) => {
  //   console.log(slideValue);

  // };

  console.log(matches, matchesForTablet);

  return (
    <div className="container-fluid" style={{ marginBottom: 10 }}>
      <div className="row">
        <div
          className="col-md-6 col-12"
          style={{ paddingLeft: 4, paddingRight: 4, marginTop: 10 }}
        >
          <div
            style={{
              width: "100%",
              backgroundColor: "white",
              height: 180,
              borderRadius: 5,
              padding: 10,
            }}
            onClick={() => setEnableEDBedsProgress(!enableEDBedsProgress)}
          >
            <div>
              <span style={{ fontWeight: "bold", fontSize: 18 }}>
                ED Beds Available
              </span>
            </div>
            <div>
              <span style={{ fontWeight: "bold", fontSize: 45 }}>
                {responseData.EdBeds}
              </span>
            </div>
            <div>
              <SliderComponent
                scale={14}
                top={120}
                bottom={0}
                left={
                  matches && matchesForTablet
                    ? 140
                    : matches && !matchesForTablet
                      ? 45
                      : 25
                }
                right={0}
                onSlide={setEDBeds}
                readOnly={true}
                progressValue={responseData.EdBeds}
              />
            </div>
          </div>
        </div>

        <div
          className="col-md-6 col-12"
          style={{ paddingLeft: 4, paddingRight: 4, marginTop: 10 }}
        >
          <div
            style={{
              width: "100%",
              backgroundColor: "white",
              height: 180,
              borderRadius: 5,
              padding: 10,
            }}
            onClick={() => setEnableIPBedsProgressBar(!enableIPBedsProgressBar)}
          >
            <div>
              <span style={{ fontWeight: "bold", fontSize: 18 }}>
                IP Beds Available
              </span>
            </div>
            <div>
              <span style={{ fontWeight: "bold", fontSize: 45 }}>{ipBeds}</span>
            </div>
            {/* {enableIPBedsProgressBar === true ? ( */}
            <div>
              <SliderComponent
                top={120}
                bottom={0}
                left={
                  matches && matchesForTablet
                    ? 140
                    : matches && !matchesForTablet
                      ? 45
                      : 25
                }
                right={0}
                onSlide={setIPBeds}
                readOnly={true}
                progressValue={ipBeds}
              />
            </div>
            {/* ) : (
              options && (
                <div style={{ marginTop: '-90px', marginLeft: '40px' }}>
                  <div
                    style={{
                      display: 'flex',
                      height: '10%',
                      justifyContent: 'center',
                      alignItems: 'flex-end',
                    }}
                  >
                    <span
                      style={{
                        fontSize: 13,
                        fontWeight: '700',
                        textAlign: 'center',
                        opacity: '70%',
                      }}
                    >
                      Last 06 Hours
                    </span>
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      height: '10%',
                      justifyContent: 'center',
                      alignItems: 'flex-end',
                    }}
                  >
                    <LineChart
                      data={options}
                      width={bedLineChartWidth}
                      height={140}
                      scaleWidth={bedLineChartWidth}
                      scaleHeight={100}
                      idForGraph='senseiIPBedsAvailableId'
                    />
                  </div>
                </div>
              )
            )} */}
          </div>
        </div>
      </div>
      <div className="row">
        <div
          className="col-md-4 col-6"
          style={{ paddingLeft: 4, paddingRight: 4, marginTop: 10 }}
        >
          <div>
            <Flip
              flipped={!flip}
              flipHandler={flipIt}
              mainHeading={"Patient Assignments to Production Area Pending"}
              value={
                isLoading ? (
                  <Loader
                    type="TailSpin"
                    color="green"
                    height={35}
                    width={35}
                  />
                ) : (
                  diagnosisPending
                )
              }
              mainHeadingForGraph="Patient Assignments to Production Area Pending"
              color={diagnosisPendingColor}
              subHeading={"TAT"}
              flagsCount={flag1Count}
              childHeading={"Registration to Production Area Assignment"}
              time={
                isLoading ? (
                  <Loader
                    type="TailSpin"
                    color="green"
                    height={35}
                    width={35}
                  />
                ) : diagnosisPendingTAT === null ? (
                  0
                ) : (
                  diagnosisPendingTAT
                  // Number.parseFloat(diagnosisPendingTAT).toPrecision(3)
                )
              }
              timecolor={diagnosisPendingTATColor}
              idForGraph={"container1"}
              data={diagnosisPendingGraphData}
            />
          </div>
        </div>

        <div
          className="col-md-4 col-6"
          style={{ paddingLeft: 4, paddingRight: 4, marginTop: 10 }}
        >
          <div>
            <Flip
              flipped={!flip}
              flipHandler={flipIt}
              mainHeading={"Patient Assessments/Triage By Nurse Pending"}
              value={
                isLoading ? (
                  <Loader
                    type="TailSpin"
                    color="green"
                    height={35}
                    width={35}
                  />
                ) : decisionPending <= 99 ? (
                  decisionPending
                  // Number.parseFloat(decisionPending).toFixed(1)
                ) : (
                  decisionPending
                  // Number.parseFloat(decisionPending).toPrecision(3)
                )
              }
              flagsCount={flag2Count}
              mainHeadingForGraph="Patient Assessments/Triage By Nurse Pending"
              color={decisionPendingColor}
              subHeading={"TAT"}
              childHeading={
                "Assignment to Production Area to Assessment/Triage"
              }
              time={
                isLoading ? (
                  <Loader
                    type="TailSpin"
                    color="green"
                    height={35}
                    width={35}
                  />
                ) : decisionPendingTAT === null ? (
                  0
                ) : (
                  decisionPendingTAT
                  // Number.parseFloat(decisionPendingTAT).toPrecision(3)
                )
              }
              timecolor={decisionPendingTATColor}
              idForGraph={"container2"}
              data={decisionPendingGraphData}
            />
          </div>
        </div>

        <div
          className="col-md-4 col-6"
          style={{ paddingLeft: 4, paddingRight: 4, marginTop: 10 }}
        >
          <div>
            <Flip
              flipped={!flip}
              flipHandler={flipIt}
              mainHeading={"Patient Diagnosis By Doctor Pending"}
              value={
                isLoading ? (
                  <Loader
                    type="TailSpin"
                    color="green"
                    height={35}
                    width={35}
                  />
                ) : (
                  dischargesPending
                )
              }
              mainHeadingForGraph="Patient Diagnosis By Doctor Pending"
              color={dischargesPendingColor}
              subHeading={"TAT"}
              childHeading={"Assessment/Triage to Diagnosis"}
              time={
                isLoading ? (
                  <Loader
                    type="TailSpin"
                    color="green"
                    height={35}
                    width={35}
                  />
                ) : dischargesPendingTAT === null ? (
                  0
                ) : (
                  dischargesPendingTAT
                  // Number.parseFloat(dischargesPendingTAT).toPrecision(3)
                )
              }
              flagsCount={flag3Count}
              timecolor={dischargesPendingTATColor}
              idForGraph={"container3"}
              data={dischargesPendingGraphData}
            />
          </div>
        </div>
        <div
          className="col-md-4 col-6"
          style={{ paddingLeft: 4, paddingRight: 4, marginTop: 10 }}
        >
          <div>
            <Flip
              flipped={!flip}
              flipHandler={flipIt}
              mainHeading={"Patient Decisions By Doctor Pending"}
              value={
                isLoading ? (
                  <Loader
                    type="TailSpin"
                    color="green"
                    height={35}
                    width={35}
                  />
                ) : consultantPending <= 99 ? (
                  consultantPending
                  // Number.parseFloat(consultantPending).toFixed(1)
                ) : (
                  consultantPending
                  // Number.parseFloat(consultantPending).toPrecision(3)
                )
              }
              mainHeadingForGraph="Patient Decisions By Doctor Pending"
              color={consultantPendingColor}
              subHeading={"TAT"}
              childHeading={"Results/Treatment to Decision"}
              time={
                isLoading ? (
                  <Loader
                    type="TailSpin"
                    color="green"
                    height={35}
                    width={35}
                  />
                ) : consultationOrderTAT === null ? (
                  0
                ) : (
                  consultationOrderTAT
                  // Number.parseFloat(consultationOrderTAT).toPrecision(3)
                )
              }
              flagsCount={flag4Count}
              timecolor={consultationOrderTATColor}
              idForGraph={"container4"}
              data={consultantNotesGraphData}
            />
          </div>
        </div>

        <div
          className="col-md-4 col-6"
          style={{ paddingLeft: 4, paddingRight: 4, marginTop: 10 }}
        >
          <div>
            <Flip
              flipped={!flip}
              flipHandler={flipIt}
              mainHeading={"Patient Lab Results Pending"}
              value={
                isLoading ? (
                  <Loader
                    type="TailSpin"
                    color="green"
                    height={35}
                    width={35}
                  />
                ) : labPending <= 99 ? (
                  labPending
                  // Number.parseFloat(labPending).toFixed(1)
                ) : (
                  labPending
                  // Number.parseFloat(labPending).toPrecision(3)
                )
              }
              mainHeadingForGraph="Patient Lab Results Pending"
              color={labPendingColor}
              subHeading={"TAT"}
              childHeading={"Order to result"}
              time={
                isLoading ? (
                  <Loader
                    type="TailSpin"
                    color="green"
                    height={35}
                    width={35}
                  />
                ) : labTAT === null ? (
                  0
                ) : (
                  labTAT
                  // Number.parseFloat(labTAT).toPrecision(3)
                )
              }
              flagsCount={flag5Count}
              timecolor={labTATColor}
              idForGraph={"container5"}
              data={labPendingGraphData}
            />
          </div>
        </div>
        <div
          className="col-md-4 col-6"
          style={{ paddingLeft: 4, paddingRight: 4, marginTop: 10 }}
        >
          <div>
            <Flip
              flipped={!flip}
              flipHandler={flipIt}
              mainHeading={"Patient Rad Consultation Notes Pending"}
              value={
                isLoading ? (
                  <Loader
                    type="TailSpin"
                    color="green"
                    height={35}
                    width={35}
                  />
                ) : radPending <= 99 ? (
                  radPending
                  // Number.parseFloat(radPending).toFixed(1)
                ) : (
                  radPending
                  // Number.parseFloat(radPending).toPrecision(3)
                )
              }
              mainHeadingForGraph="Patient Rad Consultation Notes Pending"
              color={radPendingColor}
              subHeading={"TAT"}
              childHeading={"Order to Consultation Notes"}
              time={
                isLoading ? (
                  <Loader
                    type="TailSpin"
                    color="green"
                    height={35}
                    width={35}
                  />
                ) : radTAT === null ? (
                  0
                ) : (
                  radTAT
                  // Number.parseFloat(radTAT).toPrecision(3)
                )
              }
              flagsCount={flag6Count}
              timecolor={radTATColor}
              idForGraph={"container6"}
              data={radPendingGraphData}
            />
          </div>
        </div>
        <div
          className="col-md-4 col-6"
          style={{ paddingLeft: 4, paddingRight: 4, marginTop: 10 }}
        >
          <div>
            <Flip
              flipped={!flip}
              flipHandler={flipIt}
              mainHeading={"Patient Treatments/Medications By Nurse Pending"}
              value={
                isLoading ? (
                  <Loader
                    type="TailSpin"
                    color="green"
                    height={35}
                    width={35}
                  />
                ) : orderPending <= 99 ? (
                  orderPending
                  // Number.parseFloat(orderPending).toFixed(1)
                ) : (
                  orderPending
                  // Number.parseFloat(orderPending).toPrecision(3)
                )
              }
              flagsCount={flag7Count}
              mainHeadingForGraph="Patient Treatments/Medications By Nurse Pending"
              color={orderPendingColor}
              subHeading={"TAT"}
              childHeading={"Diagnosis to Treatment"}
              time={
                isLoading ? (
                  <Loader
                    type="TailSpin"
                    color="green"
                    height={35}
                    width={35}
                  />
                ) : orderTAT === null ? (
                  0
                ) : (
                  orderTAT
                  // Number.parseFloat(orderTAT).toPrecision(3)
                )
              }
              timecolor={orderTATColor}
              idForGraph={"container7"}
              data={orderPendingGraphData}
            />
          </div>
        </div>
        <div
          className="col-md-4 col-6"
          style={{ paddingLeft: 4, paddingRight: 4, marginTop: 10 }}
        >
          <div>
            <Flip
              flipped={!flip}
              flipHandler={flipIt}
              mainHeading={"Flags Pending"}
              value={
                isLoading ? (
                  <Loader
                    type="TailSpin"
                    color="green"
                    height={35}
                    width={35}
                  />
                ) : (
                  80
                )
              }
              mainHeadingForGraph="Flags Pending"
              color="#FF0000"
              subHeading={"TAT"}
              childHeading={"Flag raised to resolution"}
              time={
                isLoading ? (
                  <Loader
                    type="TailSpin"
                    color="green"
                    height={35}
                    width={35}
                  />
                ) : (
                  "15"
                )
              }
              idForGraph={"container4"}
              data={dummyData}
            />
          </div>
        </div>
        <div
          className="col-md-4 col-6"
          style={{ paddingLeft: 4, paddingRight: 4, marginTop: 10 }}
        >
          <div>
            <Flip
              flipped={!flip}
              flipHandler={flipIt}
              mainHeading={"Patient Discharges/Dispositions Pending"}
              value={
                isLoading ? (
                  <Loader
                    type="TailSpin"
                    color="green"
                    height={35}
                    width={35}
                  />
                ) : ninthCard <= 99 ? (
                  ninthCard
                  // Number.parseFloat(ninthCard).toFixed(1)
                ) : (
                  ninthCard
                  // Number.parseFloat(ninthCard).toPrecision(3)
                )
              }
              flagsCount={flag9Count}
              mainHeadingForGraph="Patient Discharges/Dispositions Pending"
              color={ninthCardColor}
              subHeading={"TAT"}
              childHeading={"Decision to Discharge/Disposition"}
              time={
                isLoading ? (
                  <Loader
                    type="TailSpin"
                    color="green"
                    height={35}
                    width={35}
                  />
                ) : ninthCardTAT === null ? (
                  0
                ) : (
                  Number.parseFloat(ninthCardTAT).toPrecision(3)
                )
              }
              timecolor={ninthCardTATColor}
              idForGraph={"container9"}
              data={ninthCardGraphData}
            />
          </div>
        </div>
        <div
          className="col-md-4 col-6"
          style={{ paddingLeft: 4, paddingRight: 4, marginTop: 10 }}
        >
          <div>
            <Flip
              flipped={!flip}
              flipHandler={flipIt}
              mainHeading={"No. of Assignments/Hour"}
              value={
                isLoading ? (
                  <Loader
                    type="TailSpin"
                    color="green"
                    height={35}
                    width={35}
                  />
                ) : tenthCard <= 99 ? (
                  // tenthCard
                  Number.parseFloat(tenthCard).toFixed(1)
                ) : (
                  // tenthCard
                  Number.parseFloat(tenthCard).toPrecision(3)
                )
              }
              mainHeadingForGraph="No. of Assignments/Hour"
              color={tenthCardColor}
              subHeading={"TAT"}
              childHeading={"Per Patient"}
              time={
                isLoading ? (
                  <Loader
                    type="TailSpin"
                    color="green"
                    height={35}
                    width={35}
                  />
                ) : tenthCardTAT === null ? (
                  0
                ) : (
                  tenthCardTAT
                  // Number.parseFloat(tenthCardTAT).toPrecision(3)
                )
              }
              timecolor={tenthCardTATColor}
              idForGraph={"container10"}
              data={tenthCardGraphData}
            />
          </div>
        </div>
        <div
          className="col-md-4 col-6"
          style={{ paddingLeft: 4, paddingRight: 4, marginTop: 10 }}
        >
          <div>
            <OneValue
              time={
                isLoading ? (
                  <Loader
                    type="TailSpin"
                    color="green"
                    height={35}
                    width={35}
                  />
                ) : (
                  comulativeTotal
                )
              }
              colorTime={comulativeTotalColor}
              heading={"Cumulative number of Registrations"}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
