import React, { useState, useEffect, useRef } from "react";
import "./notification.css";
import notificationIcon from "../../assets/img/Notification.png";
import Header from "../../components/Header/Header";
import moment from "moment";
import { AlertTriangle } from "react-feather";
import cookie from "react-cookies";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import makeStyles from "../../assets/jss/material-dashboard-react/inputStyle";
import useStylesForInput from "../../assets/jss/material-dashboard-react/inputStyle";
import _ from "lodash";
import {
  markNotification,
  getNotifications,
  socketUrl,
  unReadNotifications,
  readNotifications,
} from "../../public/endpoins";
import axios from "axios";
import Notification from "../../components/Snackbar/Notification.js";
import Back_Arrow from "../../assets/img/Back_Arrow.png";
import { setUsrRoles } from "./config";
import { Badge, Tabs, Tab } from "@material-ui/core";
import CardData from "./cardData";
import CardDataManual from "./cardDataManual";
import socketIOClient from "socket.io-client";

const styles = {
  styleForTabs: {
    color: "white",
    borderRadius: 5,
    outline: "none",
    // fontSize: "large",
    textTransform: "none",
    width: 180,
  },
};

const readUnreadDropdown = [
  {
    key: "Read",
    value: "Read",
  },
  {
    key: "Unread",
    value: "Unread",
  },
];

export default function Notifications(props) {
  // const classes = makeStyles()
  const classes = useStylesForInput();
  const [errorMsg, setErrorMsg] = useState("");
  const [value, setValue] = useState("Tab Value");
  const [successMsg, setsuccessMsg] = useState("");
  const [openNotification, setOpenNotification] = useState(false);
  const [notificationData, setnotificationData] = useState("");
  const [generalArray, setGeneralArray] = useState([]);
  const [currentUser] = useState(cookie.load("current_user"));
  const [staffData, setStaffData] = useState([]);
  const [allowedUserRoles, setAllowedUserRoles] = useState([]);
  const [readUnread, setReadUnread] = useState("Unread");

  useEffect(() => {
    if (currentUser && currentUser.staffType) {
      const x = setUsrRoles(currentUser);
      setAllowedUserRoles(x);
      setValue(x[0].key);
    }
  }, []);

  useEffect(() => {
    console.log("tabbbb", value);
    let apiCall;
    if (readUnread === "Unread") {
      apiCall = unReadNotifications;
    } else {
      apiCall = readNotifications;
    }

    if (currentUser) {
      axios
        .get(apiCall + "/" + currentUser._id + "/" + value)
        .then((res) => {
          if (res.data.success) {
            console.log("Load Notificationsss", res.data.data);
            const orderNotification = res.data.data;
            var sortedObjs = _.sortBy(orderNotification, "date").reverse();
            setnotificationData(sortedObjs);
          }
        })
        .catch((e) => {
          console.log("Cannot get Notifications", e);
        });
      const socket = socketIOClient(socketUrl);
      socket.on("get_data", (data) => {
        console.log("response coming through socket for Data", data);
      });
    }
  }, [value, readUnread]);

  console.log("alloweduserroles", allowedUserRoles);

  const onChangeValue = (e) => {
    setReadUnread(e.target.value);
  };

  const handleChange = (event, newValue) => {
    console.log("EventCalue", event, newValue);
    setValue(newValue);
  };

  useEffect(
    () => {
      if (allowedUserRoles[0]) {
        // filerSingleRoleUsers(allowedUserRoles[0])
      }
    },
    // [notificationData]
    []
  );

  const getWhen = (timestamp) => {
    let when = `${moment(timestamp).format("DD-MM-YYYY")} ${moment(
      timestamp
    ).format("LTS")}`;
    return when;
  };

  console.log("value::", value);

  const handleViewNotification = (message, userId) => {
    console.log("mesaageee", message);
    if (message.sendTo.userId._id === currentUser._id) {
      var id = message._id;
      const params = {
        read: true,
      };
      axios
        .get(markNotification + "/" + id + "/" + userId, params)
        .then((res) => {
          if (res.data.success) {
            console.log("Response data ", res.data.data);
            props.history.push({
              pathname: message.route,
              state: {
                comingFrom: "notification",
                // SearchId: message.searchId,
                edrId:
                  message.patient && message.patient._id
                    ? message.patient.requestNo
                    : "",
                patientMRN: message.roPatient
                  ? message.roPatient.identifier[0].value
                  : "",
                roPatient: message.roPatient ? message.roPatient : "N/A",
                title: message.title && message.title,
                sendFrom: message.sendFrom,
              },
            });
          }
        })
        .catch((e) => {
          console.log(e);
          setOpenNotification(true);
          setErrorMsg("Cannot Open Notifications");
        });
    } else {
      setOpenNotification(true);
      setErrorMsg("You are not allowed to see this notification");
    }
  };

  if (openNotification) {
    setTimeout(() => {
      setOpenNotification(false);
      setErrorMsg("");
      setsuccessMsg("");
    }, 2000);
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        position: "fixed",
        width: "100%",
        height: "100%",
        backgroundColor: "rgb(19 213 159)",
        overflowY: "scroll",
      }}
    >
      <Header history={props.history} />

      <div className="cPadding">
        <div className="subheader">
          <div>
            <img src={notificationIcon} />
            <h4>Notifications</h4>
          </div>
        </div>

        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            textColor="primary"
            TabIndicatorProps={{ style: { background: "#12387a" } }}
            // centered
            variant="scrollable"
            // scrollButtons="auto"
          >
            {allowedUserRoles &&
              allowedUserRoles.map((role) => {
                return (
                  <Tab
                    style={{
                      ...styles.styleForTabs,
                      color: value === role.value ? "#12387a" : "#3B988C",
                      width: 200,
                    }}
                    value={role.key}
                    label={
                      // <Badge
                      //   color="primary"
                      //   badgeContent="2"
                      //   classes={{ badge: classes.badge }}
                      // >
                      //   {role.value}
                      // </Badge>

                      role.value
                    }
                  />
                );
              })}
          </Tabs>
        </div>

        <div
          className="row"
          style={{
            ...styles.stylesForTextfield,
            display: "flex",
            justifyContent: "flex-end",
            marginRight: -5,
            marginTop: 10,
          }}
        >
          <div className="col-md-3">
            <TextField
              type="text"
              select
              label="Select Notifications"
              name={"readUnread"}
              value={readUnread}
              onChange={(e) => onChangeValue(e)}
              className="textInputStyle"
              variant="filled"
              InputProps={{
                className: classes.input,
                classes: { input: classes.input },
              }}
            >
              {readUnreadDropdown.map((val) => {
                return (
                  <MenuItem key={val.key} value={val.key}>
                    {val.value}
                  </MenuItem>
                );
              })}
            </TextField>
          </div>
        </div>

        {notificationData.length > 0 ? (
          <ul className="notification-info-panel">
            {notificationData.map((message, index) => {
              console.log('notificationData::', currentUser._id)
              // return message.sendTo.map((checkRead, indexx) => {
              if (
                message.sendTo.read === false &&
                message.sendTo.userId._id === currentUser._id
              ) {
                // console.log("Unread", index)
                return (
                  <li
                    key={index}
                    className={
                      "notification-message unread"
                      // 'notification-message'
                    }
                    onClick={() =>
                      handleViewNotification(message, message.sendTo.userId._id)
                    }
                  >
                    <div className="timestamp d-flex">
                      <span className="title mr-auto p-2">
                        {message.message}
                        <Badge
                          className="notify-dot"
                          color="error"
                          variant="dot"
                        />
                      </span>
                      <span className="time p-2">{getWhen(message.date)}</span>
                    </div>
                    <hr style={{ marginTop: 0, marginBottom: "5px" }} />
                    {/* <div
                        className='content'
                        dangerouslySetInnerHTML={getContent(message['message'])}
                      /> */}
                    {
                      message.notificationMode=="manual"?
                      <CardDataManual patient={message} />:<CardData patient={message} />
                    }
                  </li>
                );
              } else if (
                message.sendTo.read === true &&
                message.sendTo.userId._id === currentUser._id
              ) {
                // console.log("Read", index)
                return (
                  <li
                    key={index}
                    className={
                      // 'notification-message unread'
                      "notification-message"
                    }
                    onClick={() =>
                      handleViewNotification(message, message.sendTo.userId._id)
                    }
                  >
                    <div className="timestamp d-flex">
                      <span className="title mr-auto p-2">
                        {message.message}
                      </span>
                      <span className="time p-2">{getWhen(message.date)}</span>
                    </div>
                    <hr style={{ marginTop: 0, marginBottom: "5px" }} />
                    {/* <div
                        className='content'
                        dangerouslySetInnerHTML={getContent(message['message'])}
                      /> */}

                    
                    {
                      message.notificationMode=="manual"?
                      <CardDataManual patient={message} />:<CardData patient={message} />
                    }
                  </li>
                );
              }
              // else {
              //   return (
              //     <div className='notification-info-panel'>
              //       <div className='notification-message no-notify'>
              //         <AlertTriangle
              //           color={'#000000'}
              //           size={28}
              //           style={{ marginTop: '1rem' }}
              //         />
              //         <hr />
              //         <h5 style={{ paddingBottom: '0.5rem' }}>
              //           No Notifications found!
              //         </h5>
              //       </div>
              //     </div>
              //   )
              // }
              // })
            })}
          </ul>
        ) : (
          <div className="notification-info-panel">
            <div className="notification-message no-notify">
              <AlertTriangle
                color={"#000000"}
                size={28}
                style={{ marginTop: "1rem" }}
              />
              <hr />
              <h5 style={{ paddingBottom: "0.5rem" }}>
                No Notifications found!
              </h5>
            </div>
          </div>
        )}

        <Notification
          msg={errorMsg}
          open={openNotification}
          success={successMsg}
        />

        <div
          className="row"
          style={{
            display: "flex",
            flex: 1,
            marginTop: "2%",
            marginBottom: "2%",
            marginLeft: "12px",
          }}
        >
          <img
            onClick={() => props.history.goBack()}
            src={Back_Arrow}
            style={{ width: 40, height: 30, cursor: "pointer" }}
          />
        </div>
      </div>
    </div>
  );
}
