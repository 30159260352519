import React, { useEffect, useState, useReducer } from "react";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import cookie from "react-cookies";
import Header from "../../components/Header/Header";
import AssessIcon from "../../assets/img/Production Area.png";
import "../../assets/jss/material-dashboard-react/components/TextInputStyle.css";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import CustomTable from "../../components/Table/Table";
import Notification from "../../components/Snackbar/Notification.js";
import Loader from "react-loader-spinner";
import axios from "axios";
import { AssignProductionArea, getPatientsByRoom, getProductionAreas } from "../../public/endpoins";
import PatientDetails from "../../components/PatientDetails/PatientDetailsDirect";
import PatientDetailsQuick from "../../components/PatientDetails/PatientDetailsQuick";
import { getPatientEdrDetails } from "./NetworkCall";
import { connect } from "react-redux";
import {
  funForReducer,
  setPatientDetailsForReducer,
} from "../../actions/Checking";
import { FormatListBulletedSharp } from "@material-ui/icons";

const tableHeadingForProductionArea = [
  "Production Area",
  "Rooms",
  "No of Patients",
  "Action",
];
const tableDataKeysForProductionArea = ["name", "totalRooms", "noOfPatients"];
const tableHeadingForRooms = ["Bed/Cell Number", "Status", "Action"];
const tableDataKeysForRooms = [ "roomNo", "status"];
const tableHeadingForBedCell = [
  "Room ID",
  "Room Number",
  "Bed Id",
  "Patient Name",
  "Patient MRN",
  "Status",
  "Action",
];
const tableDataKeysForBedCell = [
  "roomId",
  "roomNo",
  "bedId",
  "patientName",
  "mrn",
  "status",
];
const actions = { assign: true };

const useStylesForTabs = makeStyles({
  root: {
    justifyContent: "center",
  },
  scroller: {
    flexGrow: "0",
  },
});

const styles = {
  save: {
    color: "white",
    cursor: "pointer",
    borderRadius: 5,
    backgroundColor: "#ba55d3",
    width: "190px",
    height: "56px",
    outline: "none",
  },
}

const useStyles = makeStyles((theme) => ({
  input: {
    backgroundColor: "#F5F5F5",
    boxShadow: "none",
    borderRadius: 5,
    "&:after": {
      borderBottomColor: "black",
      boxShadow: "none",
    },
    "&:hover": {
      backgroundColor: "#F5F5F5",
      boxShadow: "none",
    },
    "&:focus": {
      backgroundColor: "#F5F5F5",
      boxShadow: "none",
      borderRadius: 5,
    },
  },
  save: {
    color: "white",
    cursor: "pointer",
    borderRadius: 5,
    backgroundColor: "#ba55d3",
    width: "130px",
    height: "45px",
    outline: "none",
  },
  root: {
    "& .MuiTextField-root": {
      backgroundColor: "white",
    },
    "& .Mui-focused": {
      backgroundColor: "white",
      color: "black",
      boxShadow: "none",
    },
    "& .Mui-disabled": {
      backgroundColor: "white",
      color: "gray",
    },
    "&:focus": {
      backgroundColor: "white",
      boxShadow: "none",
    },
    "& .MuiFormLabel-root": {
      fontSize: "12px",
      color: "black",
      paddingRight: "15px",
    },
    "& .MuiInputLabel-filled": {
      color: "gray",
    },
  },

  
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

function ViewAssignPA(props) {
  const matches = useMediaQuery("(min-width:600px)");
  const classes = useStyles();

  const classesForTabs = useStylesForTabs();

  const initialState = {
    patientDetails: {},

    ProductionAreaArray: "",
    RoomsArray: "",
    BedCellArray: "",
  };

  function reducer(state, { field, value }) {
    return {
      ...state,
      [field]: value,
    };
  }

  const [state, dispatch] = useReducer(reducer, initialState);

  const {
    patientDetails,
    ProductionAreaArray,
    RoomsArray,
    BedCellArray,
  } = state;

  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setsuccessMsg] = useState("");
  const [openNotification, setOpenNotification] = useState(false);
  const [value, setValue] = useState(0);
  const [selectedItem, setSelectedItem] = useState([]);
  const [currentUser, setCurrentUser] = useState(cookie.load("current_user"));
  const [roomTab, setRoomTab] = useState(true);
  const [bedTab, setBedTab] = useState(true);
  const [roomData, setRoomData] = useState("");
  const [patientEdrId, setPatientEdrId] = useState("");
  const [productionId, setProductionId] = useState("");
  const [productionAreas, setProductionAreas] = useState([]);
  const [productionAreaId, setProductionAreaId] = useState("");
  const [productionRooms, setProductionRooms] = useState([]);
  const [productionTeams, setProductionTeams] = useState([]);
  const [bedCellId, setBedCellId] = useState("");
  const [productionTeamId, setProductionTeamId] = useState("");
  const [cameFromPage, setCameFromPage] = useState('');

  const [patientDetailsDialog, openPatientDetailsDialog] = useState(false);

  function showPatientDetails() {
    openPatientDetailsDialog(false);
  }

  useEffect(() => {
    console.log("propsViewAssignPA", props.history.location.state);


    let cameFrom = props.history.location.state.cameFrom;
    setCameFromPage(cameFrom);

    let selectedItemState = props.location.state.selectedItem;
    let edrId = selectedItemState._id;

    // Setting EDR Id
    setPatientEdrId(edrId);


    
    getPatientEdrDetails(edrId, (item) => {
      console.log("ViewAssignPA:: async", item);
      setSelectedItem(item)
      let patientName = item.patientId.name.pop();
      const object = {
        patientName: patientName.given[0] + " " + patientName.family,
        mrn: item.patientId.identifier[0].value,
        age: item.patientId.age,
        gender: item.patientId.gender,
        weight: item.patientId.weight
      };
      dispatch({ field: "patientDetails", value: object });
    });


    

    console.log("ViewAssignPA::", "Action 2 =============");
    // Getting Live Data
    console.log("ViewAssignPA::", selectedItemState, edrId);
  }, []);

  useEffect(() => {
    console.log("ViewAssignPA::", "Action 3 =============");

    console.log("ViewAssignPA:: selectedItem", selectedItem);

    // Get all Production Areas
    axios
      .get(getProductionAreas)
      .then((res) => {
        console.log("response", res);
          console.log("success::productionArea", res.data.data);
        if (res.data.success) {

          const a = res.data.data;
          a.map(
            (v) => (
              (v.id = v && v.paId),
              (v.name = v && v.paName),
              (v.totalRooms = v && v.rooms.length),
              (v.noOfPatients = 0), //props.location.state.selectedItem.count,
              (v.availableRooms = v.rooms.filter((xval) => {
                console.log(xval);
                return (xval.roomId.availability == true);
              })),
              (v.availableTeams = v.productionTeams)
            )
          );
          setProductionAreas(res.data.data);
          dispatch({ field: "ProductionAreaArray", value: res.data.data });
        }
        return res;
      })
      .catch((e) => {
        console.log("error: ", e);
      });

    Object.entries(props.location.state.selectedItem).map(([key, val]) => {
      if (val && typeof val === "object") {
        if (key === "chiefComplaint") {
          // const a =
          //   // val &&
          //   // val.length > 0 &&
          //   // val[val.length - 1].chiefComplaintId &&
          //   val[val.length - 1].chiefComplaintId.productionArea;
          // a.map(
          //   (v) => (
          //     (v.id = v.productionAreaId && v.productionAreaId.paId),
          //     (v.name = v.productionAreaId && v.productionAreaId.paName),
          //     (v.rooms = v.productionAreaId && v.productionAreaId.rooms.length),
          //     (v.noOfPatients = props.location.state.selectedItem.count)
          //   )
          // );
          // dispatch({ field: "ProductionAreaArray", value: a });
        }
      }
    });
  }, [selectedItem]);

  console.log("ViewAssignPA::", "Action 1 =============");

  console.log("selectedItem::", selectedItem);
  console.log("selectedItem::patientDetails", patientDetails);

  console.log("productionArea", ProductionAreaArray);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === 0) {
      setBedTab(true);
      setRoomTab(true);
    }
  };

  function handlePA(rec) {
    if (value === 0) {
      setValue(1);
    } else if (value === 1) {
      setValue(2);
    }
    setRoomTab(false);

    console.log("rec", rec);
    console.log("rec rooms", rec.rooms);

    setProductionId(rec._id);

    Object.entries(rec).map(([key, val]) => {
      if (val && typeof val === "object") {
        if (key === "rooms") {
          // console.log('Status', val[0].roomId.availability)
          const a = val;
          a.map(
            (v) => (
              (v.id = v.roomId.roomId),
              (v.roomNo = v.roomId.roomNo),
              (v.status =
                v.roomId.availability === false ? "Occopied" : "Vacant")
            )
          );
          console.log("rec rooms mapped", a);
          dispatch({ field: "RoomsArray", value: a });
        }
      }
    });
  }
  console.log("RoomsArray", RoomsArray);

  const roomDataReturn = (data) => {
    setRoomData(data);
    // return data
  };

  function handleRoom(rec) {

    if (selectedItem.room.length > 0) {
      setOpenNotification(true);
      setErrorMsg("This patient has already been assigned a room");
      return;
    }


    // Check if the current patient already have a room.
    if (bedCellId === "" || productionAreaId === "" || productionTeamId === "") {
      setOpenNotification(true);
      setErrorMsg("Please select a production area and room");
      return;
    }

    // if (rec.status === 'Vacant') {
      // setValue(2)
      let params = {
        staffId: currentUser._id,
        edrId: selectedItem._id,
        productionRoomId: productionAreaId, 
        roomId: bedCellId,
        productionTeamId: productionTeamId,
      };
      console.log("productionRooms:: final params", params);

      axios
        .put(AssignProductionArea, params)
        .then((res) => {
          console.log("response after assigning", res.data);
          if (res.data.success) {

            if (cameFromPage === "successPage"){
              props.history.push({
                pathname: "/dashboard/home/patientmanagement/success",
                state: {
                  code: `${selectedItem.requestNo.toUpperCase()}`,
                  edrId: selectedItem._id,
                  selectedItem: selectedItem,
                  mode: "bedRoomAssigned",
                },
              });
            }else{
              props.history.push({
                pathname: "/dashboard/home/success",
                state: {
                  message1: `Bed and Production Team assigned to patient`,
                },
              });
            }
            // window.location.reload(false)
          } else if (!res.data.success) {
            setOpenNotification(true);
            setErrorMsg(res.data.error);
          }
          return res;
        })
        .catch((e) => {
          console.log("error: ", e);
          setErrorMsg("Bed is already assigned");
          setOpenNotification(true);
        });



    // } else {
    //   setErrorMsg('Room is occopied')
    //   setOpenNotification(true)
    // }


    /*
    if (rec.status === "Vacant") {
      Object.entries(rec.roomId).map(([key, val]) => {
        if (val && typeof val === "object") {
          if (key === "beds") {
            const a = val;
            a.map(
              (v) => (
                (v.bedId = v.bedId),
                (v.status =
                  rec.roomId.availability === false ? "Occopied" : "Vacant"),
                (v.roomNo = rec.roomId.roomNo),
                (v.roomId = rec.roomId.roomId),
                (v.room_id = rec.roomId._id),
                (v.patientName = "..."),
                (v.mrn = "....")
              )
            );
            dispatch({ field: "BedCellArray", value: a });
          }
        }
      });
    } else {
      axios
        .get(getPatientsByRoom + "/" + rec.roomId._id)
        .then((res) => {
          console.log("response", res);
          if (res.data.success) {
            console.log("success", res.data.data);
            Object.entries(rec.roomId).map(([key, val]) => {
              if (val && typeof val === "object") {
                if (key === "beds") {
                  const a = val;
                  a.map(
                    (v) => (
                      (v.bedId = v.bedId),
                      (v.status =
                        rec.roomId.availability === false
                          ? "Occopied"
                          : "Vacant"),
                      (v.roomNo = rec.roomId.roomNo),
                      (v.roomId = rec.roomId.roomId),
                      (v.room_id = rec.roomId._id),
                      (v.patientName =
                        res.data != null
                          ? res.data.data.patientId.name[0].given[0] +
                            " " +
                            res.data.data.patientId.name[0].family
                          : "...."),
                      (v.mrn =
                        res.data != null
                          ? res.data.data.patientId.identifier[0].value
                          : "....")
                    )
                  );
                  dispatch({ field: "BedCellArray", value: a });
                }
              }
            });
          } else if (!res.data.success) {
            setOpenNotification(true);
            setErrorMsg(res.data.error);
          }
          return res;
        })
        .catch((e) => {
          console.log("error: ", e);
        });
    }
    */
  }

  console.log("bedCellArray", BedCellArray);

  function handleAssig(rec) {
    console.log("selectedItem", selectedItem);
    console.log("record", rec);

    let occupied = false;
    for (let i = 0; i < BedCellArray.length; i++) {
      if (
        BedCellArray[i].status === "Occopied" &&
        rec._id === BedCellArray[i]._id
      ) {
        setOpenNotification(true);
        setErrorMsg("This bed is already asigned to some patient.");
        return;
      }
    }

    // for (let i = 0; i < RoomsArray.length; i++) {
    //   if (
    //     BedCellArray[i].status === 'Occopied' &&
    //     rec._id === BedCellArray[i]._id
    //   ) {
    //     setOpenNotification(true)
    //     setErrorMsg('This bed is already asigned to some patient.')
    //     return
    //   }
    // }

    if (selectedItem.room.length > 0) {
      setOpenNotification(true);
      setErrorMsg("This patient has already been assigned a room");
      return;
    }

    let params = {
      staffId: currentUser._id,
      edrId: selectedItem._id,
      roomId: rec.room_id,
    };
    console.log("params", params);

    // rec.roomArray.map((v) => v.)
    console.log("roomssss", RoomsArray);
    // let occupied = false
    // for (let i = 0; i < RoomsArray.length; i++) {
    //   if (RoomsArray[i].status === 'Occopied') {
    //     let bed = RoomsArray[i].roomId.beds
    //     console.log('back', bed)
    //     if (!occupied && bed) {
    //       for (let j = 0; j < bed.length; j++) {
    //         if (bed[j].status === 'Occopied') {
    //           if (bed[j].mrn === selectedItem.patientId.identifier[0].value) {
    //             occupied = true
    //             console.log('hello')
    //           }
    //         }
    //       }
    //     }
    //   }
    // }

    if (!occupied) {
      axios
        .put(AssignProductionArea, params)
        .then((res) => {
          console.log("response after assigning", res.data);
          if (res.data.success) {
            console.log("success");
            props.history.push({
              pathname: "/dashboard/home/patientmanagement/success",
              state: {
                code: `${setSelectedItem.requestNo.toUpperCase()}`,
                edrId: setSelectedItem._id,
                selectedItem: setSelectedItem,
                mode: "bedRoomAssigned",
              },
            });
            // window.location.reload(false)
          } else if (!res.data.success) {
            setOpenNotification(true);
            setErrorMsg(res.data.error);
          }
          return res;
        })
        .catch((e) => {
          console.log("error: ", e);
          setErrorMsg("Bed is already assigned");
          setOpenNotification(true);
        });
    } else if (occupied) {
      setErrorMsg("Patient is already assigned a bed");
      setOpenNotification(true);
    }
  }

  if (openNotification) {
    setTimeout(() => {
      setOpenNotification(false);
      setErrorMsg("");
      setsuccessMsg("");
    }, 2000);
  }

  return (
    selectedItem ? (
      <div
        style={{
          backgroundColor: "rgb(19 213 159)",
          position: "fixed",
          display: "flex",
          width: "100%",
          height: "100%",
          flexDirection: "column",
          flex: 1,
          overflowY: "scroll",
        }}
      >
        <Header history={props.history} />

        <PatientDetailsQuick
          edrId={patientEdrId} />

        <div className="cPadding">
          <div className="subheader" style={{ marginLeft: "-10px" }}>
            <div>
              <img src={AssessIcon} />

              <h4>Assign Production Area :)</h4>
            </div>
          </div>

          <PatientDetails
            edrId={patientEdrId}
            showPatientDetails={showPatientDetails}
            showChiefComplaint={true}
            showTriageLevel={false}
            showCareStream={false}
            showDiagnosis={false}
            showMedication={false}
          />

          <div className="container-fluid" style={{marginTop: 15}}>
            <div className="row row-5">
              <div className="col-md-3">
                <div className="form-group">
                  <TextField
                    select
                    fullWidth
                    id="productionAreaId"
                    name="productionAreaId"
                    value={productionAreaId}
                    onChange={(e) => {
                      console.log("productionRooms:: value cahnge", e.target.value);
                      setProductionAreaId(e.target.value);
                      setProductionRooms([]);
                      setProductionTeams([]);
                      setBedCellId("");
                      productionAreas.map((val) => {
                        console.log("productionRooms::", val._id, productionAreaId, (val._id === e.target.value));
                        if (val._id === e.target.value){
                          setProductionRooms(val.rooms);
                          console.log("productionRooms:: rooms", val.rooms);
                          setProductionTeams(val.availableTeams);
                        }
                      });
                    }}
                    label="Select Production Area"
                    variant="filled"
                    className="dropDownStyle"
                    InputProps={{
                      className: classes.input,
                      classes: { input: classes.input },
                    }}
                  >
                    <MenuItem key="" value="" disabled={true}>Select One</MenuItem>
                    {productionAreas.map((val) => {
                      return (
                        <MenuItem key={val._id} value={val._id}>
                          {val.paName}&nbsp;&mdash;&nbsp;<span className="text-success float-right">{ val.availableRooms.length } of { val.rooms.length } Beds Available</span>
                        </MenuItem>
                      );
                    })}

                  </TextField>
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <TextField
                    select
                    fullWidth
                    id="bedCellId"
                    name="bedCellId"
                    value={bedCellId}
                    label="Select Bed/Cell"
                    variant="filled"
                    className="dropDownStyle"
                    InputProps={{
                      className: classes.input,
                      classes: { input: classes.input },
                    }}
                    onChange={(e) => {
                      setBedCellId(e.target.value);
                    }}
                  >
                    {productionAreaId && productionRooms.length > 0 && productionRooms.map((val) => {
                        console.log("productionRooms", val);
                        return (
                          <MenuItem key={val.roomId._id} value={val.roomId._id} disabled={val.roomId.availability === false}>
                            Bed #{ val.roomId.roomNo }&nbsp;&mdash;&nbsp;{ val.roomId.availability ? (<span className="text-success float-right">Available</span>) : (<span className="text-danger float-right">Not Available</span>)  }
                          </MenuItem>
                        );
                    })}

                  </TextField>
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <TextField
                    select
                    fullWidth
                    id="productionTeamId"
                    name="productionTeamId"
                    value={productionTeamId}
                    label="Select Production Team"
                    variant="filled"
                    className="dropDownStyle"
                    InputProps={{
                      className: classes.input,
                      classes: { input: classes.input },
                    }}
                    onChange={(e) => {
                      setProductionTeamId(e.target.value);
                    }}
                  >
                    {productionAreaId && productionTeams.length > 0 && productionTeams.map((val) => {
                        console.log("productionTeamDD", val);
                        return (
                          <MenuItem key={val._id} value={val._id}>
                            { val.nameShort}
                          </MenuItem>
                        );
                    })}

                  </TextField>
                </div>
              </div>
              <div className="col-md-3">
                <Button
                  style={{
                    ...styles.save,
                    width: matches ? 130 : "100%",
                    height: matches ? 45 : 40,
                  }}
                  disabled={
                    (productionAreaId=="" || bedCellId=="" || productionTeamId=="")
                  }
                  onClick={
                    handleRoom
                  }
                  variant="contained"
                  color="default"
                >
                  Assign Now
                </Button>
              </div>
            </div>
          </div>

          <Notification
            msg={errorMsg}
            open={openNotification}
            success={successMsg}
          />
        </div>
      </div>
    ):(
      <div className="LoaderStyle">
        <Loader type="TailSpin" color="red" height={50} width={50} />
      </div>
    )
  );
}

const mapStateToProps = ({ CheckingReducer }) => {
  const { count, patientDetails } = CheckingReducer;
  return { count, patientDetails };
};
export default connect(mapStateToProps, {
  funForReducer,
  setPatientDetailsForReducer,
})(ViewAssignPA);
