import React, { useState, useEffect, useReducer } from "react";
import Header from "../../components/Header/Header";
import Notification from "../../components/Snackbar/Notification.js";
import CustomTable from "../../components/Table/Table";
import AssistanceRequestedIcon from "../../assets/img/AssistanceRequested.png";
import PlusIcon from "../../assets/img/Plus.png";
import Back from "../../assets/img/Back_Arrow.png";
import "../../assets/jss/material-dashboard-react/components/loaderStyle.css";
import Button from "@material-ui/core/Button";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Loader from "react-loader-spinner";
import TextField from "@material-ui/core/TextField";
import cookie from "react-cookies";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { styles, useStyles, useStylesForTabs } from "./styles.js";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import {
  getAllEDDoctorsCall,
  getAllEDNursesCall,
  getAnesthesiologistCall,
  submitCodeBlueTeamCall,
  getCodeBlueTeamCall,
  EditTeamCall,
  assignCodeBlueTeamCall,
} from "./networkCallsForBlueTeam";
import Chip from "@material-ui/core/Chip";
import Autocomplete from "@material-ui/lab/Autocomplete";

const tableHeadingForCodeBlueTeam = [
  "Team Name",
  "Doctors",
  "Nurses",
  "Anesthesiologists",
  "Status",
  "Actions",
];

const tableDataKeysForCodeBlueTeam = [
  "teamName",
  "doctorsCount",
  "nursesCount",
  "anesthesiologistsCount",
  "status",
];

const actions = { edit: true, view: true, assign: true };

export default function CodeBlueTeam(props) {
  const classes = useStyles();
  const matches = useMediaQuery("(min-width:600px)");

  const initialState = {
    codeBlueTeamName: "",
  };

  function reducer(state, { field, value }) {
    return {
      ...state,
      [field]: value,
    };
  }

  const [state, dispatch] = useReducer(reducer, initialState);

  const { codeBlueTeamName } = state;

  const [currentUser] = useState(cookie.load("current_user"));
  const [errorMsg, setErrorMsg] = useState("");
  const [openNotification, setOpenNotification] = useState(false);

  const [openModal, setOpenModal] = useState(false);
  const [codeBlueTeamData, setCodeBlueTeamData] = useState([]);
  const [doctorsList, setDoctorsList] = useState([]);
  const [nursesList, setNursesList] = useState([]);
  const [anesthesiologistList, setAnesthesiologistList] = useState([]);
  const [anesthesiologist, setAnesthesiologist] = useState([]);
  const [EDNurse, setEDNurse] = useState([]);
  const [EDDoctor, setEDDoctor] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState("");
  const [fieldDisabled, setFieldDisabled] = useState(false);
  const [isLoading, setisLoading] = useState(true);

  if (openNotification) {
    setTimeout(() => {
      setOpenNotification(false);
      setErrorMsg("");
    }, 2000);
  }

  useEffect(() => {
    getAllEDDoctorsCall(setDoctorsList, setErrorMsg, setOpenNotification);
    getAllEDNursesCall(setNursesList, setErrorMsg, setOpenNotification);
    getAnesthesiologistCall(
      setAnesthesiologistList,
      setErrorMsg,
      setOpenNotification
    );
    getCodeBlueTeamCall(
      setCodeBlueTeamData,
      setErrorMsg,
      setOpenNotification,
      setisLoading
    );

    console.log("props", props);
  }, []);

  const onChangeValue = (e) => {
    dispatch({
      field: e.target.name,
      value: e.target.value,
    });
  };

  const handleAddRequest = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setEDNurse([]);
    setEDDoctor([]);
    setAnesthesiologist([]);
    dispatch({ field: "codeBlueTeamName", value: "" });
    setFieldDisabled(false);
  };

  // const onSelectDoctor = (e, target) => {
  //   if (e.target.value) {
  //     console.log('e.target.value', e.target.value)
  //   } else if (!e.target.value) {
  //     const filteredDoctor = doctorsList
  //       .filter((item) =>
  //         target.includes(item.name[0].given[0] + ' ' + item.name[0].family)
  //       )
  //       .map((filteredItem) => filteredItem._id)

  //     setEDDoctor(filteredDoctor)
  //   }
  // }

  // const onSelectNurse = (e, target) => {
  //   const filteredNurse = nursesList
  //     .filter((item) =>
  //       target.includes(item.name[0].given[0] + ' ' + item.name[0].family)
  //     )
  //     .map((filteredItem) => filteredItem._id)

  //   setEDNurse(filteredNurse)
  // }
  // const onSelectAnesthesiologist = (e, target) => {
  //   const filteredAnesthesiologist = anesthesiologistList
  //     .filter((item) =>
  //       target.includes(item.name[0].given[0] + ' ' + item.name[0].family)
  //     )
  //     .map((filteredItem) => filteredItem._id)

  //   setAnesthesiologist(filteredAnesthesiologist)
  // }
  console.log("EDDoctor:", EDDoctor);
  console.log("EDNurse:", EDNurse);
  console.log("codeBlueTeamData:", codeBlueTeamData);

  function handleView(rec) {
    console.log("REC Customer Care", rec);

    var object = {
      assignedBy: currentUser._id,
      teamId: rec._id,
      edrId: props.history.location.state.selectedRecord._id,
    };

    console.log("object", object);
    if (rec.status == "Assigned") {
      setOpenNotification(true);
      setErrorMsg(`This team is already assigned to a patient`);
    } else if (
      props.history.location.state.selectedRecord.codeBlueTeam &&
      props.history.location.state.selectedRecord.codeBlueTeam.length > 0
    ) {
      setOpenNotification(true);
      setErrorMsg(
        `${props.history.location.state.selectedRecord.patientName} is already assigned a team`
      );
    } else {
      assignCodeBlueTeamCall(object, setErrorMsg, setOpenNotification);
      // window.location.reload()
    }
  }

  const handleViewItem = (rec) => {
    setFieldDisabled(true);
    setOpenModal(true);
    setSelectedTeam(rec);
    setEDDoctor(rec.doctors.map((v) => v._id));
    setEDNurse(rec.nurses.map((v) => v._id));
    setAnesthesiologist(rec.anesthesiologists.map((v) => v._id));
    dispatch({ field: "codeBlueTeamName", value: rec.teamName });
  };

  const handleSubmitRequest = () => {
    if (
      (EDDoctor && EDDoctor.length > 0,
      EDNurse && EDNurse.length > 0,
      anesthesiologist && anesthesiologist.length > 0,
      codeBlueTeamName && codeBlueTeamName.length > 0)
    ) {
      let params;
      params = {
        addedBy: currentUser._id,
        teamName: codeBlueTeamName,
        edDoctor: EDDoctor,
        edNurse: EDNurse,
        anesthesiologist: anesthesiologist,
        status: "Un Assigned",
      };

      console.log("params", params);

      submitCodeBlueTeamCall(
        params,
        setOpenNotification,
        setErrorMsg,
        setOpenModal
      );
    } else {
      setOpenNotification(true);
      setErrorMsg("First Complete the Forms");
    }
  };

  const selectedTeamToEdit = (rec) => {
    console.log("edit", rec);
    setOpenModal(true);
    setSelectedTeam(rec);
    setEDDoctor(rec.doctors.map((v) => v._id));
    setEDNurse(rec.nurses.map((v) => v._id));
    setAnesthesiologist(rec.anesthesiologists.map((v) => v._id));
    dispatch({ field: "codeBlueTeamName", value: rec.teamName });
    // dispatch({ field: 'diagnosisArray', value: data })
  };

  const handleEdit = () => {
    let params;
    params = {
      teamId: selectedTeam._id,
      updatedBy: currentUser._id,
      teamName: codeBlueTeamName,
      edDoctor: EDDoctor,
      edNurse: EDNurse,
      anesthesiologist: anesthesiologist,
      // status: 'Un Assigned'
    };

    console.log("params", params);

    EditTeamCall(params, setOpenNotification, setErrorMsg);
  };

  const handleChange = (event, field) => {
    console.log("field", field);
    if (field === "EDDoctor") {
      setEDDoctor(event.target.value);
    } else if (field === "EDNurse") {
      setEDNurse(event.target.value);
    } else if (field === "Anesthesiologist") {
      setAnesthesiologist(event.target.value);
    }
  };

  // console.log('EDDoctor', EDDoctor)

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        position: "fixed",
        width: "100%",
        height: "100%",
        backgroundColor: "#60d69f",
        overflowY: "scroll",
      }}
    >
      <Header history={props.history} />

      <div className="cPadding">
        <div className="subheader" style={{ paddingRight: "15px" }}>
          <div>
            <img src={AssistanceRequestedIcon} alt="AssistanceRequestedIcon" />
            <h4>Code Blue Team</h4>
          </div>

          <Button
            onClick={handleAddRequest}
            style={{
              ...styles.stylesForButton,
              fontSize: matches ? " " : 10,
            }}
            variant="contained"
            color="primary"
          >
            <img src={PlusIcon} className="icon-style" />
            &nbsp;&nbsp; Add Team
          </Button>
        </div>

        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 col-sm-12 col-12">
              {isLoading ? (
                <div className="LoaderStyle">
                  <Loader type="TailSpin" color="red" height={50} width={50} />
                </div>
              ) : codeBlueTeamData.length > 0 ? (
                <CustomTable
                  tableData={codeBlueTeamData}
                  tableDataKeys={tableDataKeysForCodeBlueTeam}
                  tableHeading={tableHeadingForCodeBlueTeam}
                  action={actions}
                  handleAssign={handleView}
                  handleView={handleViewItem}
                  handleEdit={selectedTeamToEdit}
                  borderBottomColor={"#60d69f"}
                  borderBottomWidth={20}
                />
              ) : codeBlueTeamData.length === 0 ? (
                <div style={{ marginTop: "20px" }}>
                  <h3
                    style={{
                      color: "white",
                      textAlign: "center",
                    }}
                  >
                    Oops! No Data Found
                  </h3>
                </div>
              ) : (
                undefined
              )}
            </div>
          </div>

          <div className="row" style={{ marginBottom: 20 }}>
            <div className="col-md-12 col-sm-12 col-12">
              <img
                onClick={() => props.history.goBack()}
                src={Back}
                style={{
                  width: 45,
                  height: 35,
                  cursor: "pointer",
                }}
              />
            </div>
          </div>
        </div>

        <Dialog
          open={openModal}
          onClose={handleCloseModal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth={"lg"}
          fullWidth
        >
          <DialogContent>
            <div
              className={`container-fluid ${classes.root}`}
              style={{
                backgroundColor: "white",
                padding: "20px 10px",
              }}
            >
              <div
                className="col-md-12"
                style={{
                  ...styles.inputContainerForTextField,
                  ...styles.textFieldPadding,
                }}
              >
                <FormControl variant="filled" style={{ width: "100%" }}>
                  <InputLabel id="demo-mutiple-name-label">
                    Select ED Doctors
                  </InputLabel>
                  <Select
                    labelId="demo-mutiple-name-label"
                    id="demo-mutiple-name"
                    multiple
                    disabled={fieldDisabled}
                    value={EDDoctor}
                    onChange={(e) => handleChange(e, "EDDoctor")}
                  >
                    {doctorsList.map((array) => {
                      return (
                        <MenuItem key={array._id} value={array._id}>
                          {array.name[0].given[0] + " " + array.name[0].family}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </div>

              <div
                className="col-md-12"
                style={{
                  ...styles.inputContainerForTextField,
                  ...styles.textFieldPadding,
                  marginTop: 20,
                }}
              >
                <FormControl variant="filled" style={{ width: "100%" }}>
                  <InputLabel id="demo-mutiple-name-label">
                    Select ED Nurses
                  </InputLabel>
                  <Select
                    labelId="demo-mutiple-name-label"
                    id="demo-mutiple-name"
                    multiple
                    disabled={fieldDisabled}
                    value={EDNurse}
                    onChange={(e) => handleChange(e, "EDNurse")}
                  >
                    {nursesList.map((array) => {
                      return (
                        <MenuItem key={array._id} value={array._id}>
                          {array.name[0].given[0] + " " + array.name[0].family}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </div>

              <div
                className="col-md-12"
                style={{
                  ...styles.inputContainerForTextField,
                  ...styles.textFieldPadding,
                  marginTop: 20,
                }}
              >
                <FormControl variant="filled" style={{ width: "100%" }}>
                  <InputLabel id="demo-mutiple-name-label">
                    Select Anesthesiologists
                  </InputLabel>
                  <Select
                    labelId="demo-mutiple-name-label"
                    id="demo-mutiple-name"
                    multiple
                    disabled={fieldDisabled}
                    value={anesthesiologist}
                    onChange={(e) => handleChange(e, "Anesthesiologist")}
                  >
                    {anesthesiologistList.map((array) => {
                      return (
                        <MenuItem key={array._id} value={array._id}>
                          {array.name[0].given[0] + " " + array.name[0].family}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </div>

              {/* <div
                className='col-md-12'
                style={{
                  ...styles.inputContainerForTextField,
                  ...styles.textFieldPadding,
                }}
              >
                <Autocomplete
                  multiple
                  id='tags-filled'
                  options={doctorsList.map(
                    (option) =>
                      option.name[0].given[0] + ' ' + option.name[0].family
                  )}
                  // onChange={(e, v) => setEDDoctor(v)}
                  onChange={(e, target) => onSelectDoctor(e, target)}
                  freeSolo
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        variant='outlined'
                        label={option}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant='filled'
                      label='Select ED Doctor'
                      placeholder='Favorites'
                      // onChange={({ target }) => setEDDoctor(target.value)}
                      onChange={(e, target) => onSelectDoctor(e, target)}
                      // onKeyDown={(e) => {
                      //   if (e.keyCode === 13 && ) {
                      //     // setAutoCompleteValue(autoCompleteValue.concat(e.target.value));
                      //     console.log('e.target', e.target)
                      //   }
                      // }}
                    />
                  )}
                />
              </div> 

               <div
                className='col-md-12'
                style={{
                  ...styles.inputContainerForTextField,
                  ...styles.textFieldPadding,
                  marginTop: 20,
                }}
              >
                <Autocomplete
                  multiple
                  id='tags-filled'
                  options={nursesList.map(
                    (option) =>
                      option.name[0].given[0] + ' ' + option.name[0].family
                  )}
                  onChange={(e, target) => onSelectNurse(e, target)}
                  freeSolo
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        variant='outlined'
                        label={option}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant='filled'
                      label='Select ED Nurse'
                      placeholder='Favorites'
                      onChange={(e, target) => onSelectNurse(e, target)}
                    />
                  )}
                />
              </div>

              <div
                className='col-md-12'
                style={{
                  ...styles.inputContainerForTextField,
                  ...styles.textFieldPadding,
                  marginTop: 20,
                }}
              >
                <Autocomplete
                  multiple
                  id='tags-filled'
                  options={anesthesiologistList.map(
                    (option) =>
                      option.name[0].given[0] + ' ' + option.name[0].family
                  )}
                  onChange={(e, target) => onSelectAnesthesiologist(e, target)}
                  freeSolo
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        variant='outlined'
                        label={option}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant='filled'
                      label='Select Anesthesiologist'
                      placeholder='Favorites'
                      onChange={(e, target) =>
                        onSelectAnesthesiologist(e, target)
                      }
                    />
                  )}
                />
              </div> */}

              <div
                className={"col-md-12"}
                style={{
                  ...styles.inputContainerForTextField,
                  ...styles.textFieldPadding,
                  marginTop: 20,
                }}
              >
                <TextField
                  required
                  disabled={fieldDisabled}
                  label="Code Blue Team Name"
                  name={"codeBlueTeamName"}
                  value={codeBlueTeamName}
                  onChange={onChangeValue}
                  className="textInputStyle"
                  variant="filled"
                />
              </div>
            </div>
          </DialogContent>
          <DialogActions style={{ justifyContent: "center" }}>
            <Button
              onClick={handleCloseModal}
              color="primary"
              variant="contained"
              // style={styles.stylesForCancelButton}
            >
              Cancel
            </Button>
            {!fieldDisabled ? (
              <Button
                onClick={selectedTeam ? handleEdit : handleSubmitRequest}
                color="primary"
                variant="contained"
                // style={{ ...styles.stylesForButton, width: '140px' }}
              >
                Submit
              </Button>
            ) : (
              undefined
            )}
            {/* <Button
              onClick={handleEdit}
              color='primary'
              variant='contained'
              // style={{ ...styles.stylesForButton, width: '140px' }}
            >
              Edit
            </Button> */}
          </DialogActions>
        </Dialog>

        <Notification msg={errorMsg} open={openNotification} />
      </div>
    </div>
  );
}
