import React, { useEffect, useState, useReducer } from "react";
import { withStyles } from '@material-ui/core/styles';
import Header from "../../components/Header/Header";
import LabRequestIcon from "../../assets/img/Lab Requests.png";
import classNames from "classnames";
import { 
  InputLabel,
  FormHelperText,
  FormControl,
  Button, 
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment,
  MenuItem,
  TextField,
  Select,
  useMediaQuery,
} from "@material-ui/core";
import cookie from "react-cookies";

import axios from "axios";
import {
  getLabServiceCategory,
  addLabRequestBulk,
  getLabRequest,
  completeLabTechnicianServiceRequest,
  porterReceiptNotificationLabTechnician
} from "../../public/endpoins";
import Back from '../../assets/img/Back_Arrow.png'

import useStylesForInput from "../../assets/jss/material-dashboard-react/inputStyle";
import { styles } from "./styles";
import Loader from "react-loader-spinner";
import Icon from '@material-ui/core/Icon';
import CloseIcon from '@material-ui/icons/Close';
import MicIcon from "@material-ui/icons/Mic";
import StopIcon from "@material-ui/icons/Stop";
import MicRecorder from "mic-recorder-to-mp3";
import AccountCircle from "@material-ui/icons/SearchOutlined";
import Notification from "../../components/Snackbar/Notification.js";

import LabIcon from "../../assets/img/Lab.png";
import { displayInHumanDifference, replacePatientIdIfNull } from "../../utils/commonFunc";
import PatientDetailsQuick from "../../components/PatientDetails/PatientDetailsQuick";
import PatientDetails from "../../components/PatientDetails/PatientDetails";
import PatientDetails2 from "../../components/PatientDetails/PatientDetailsDirect";

import moment from 'moment';

export default function LabPatientDetailIndividual(props) {
  
  const matches = useMediaQuery("(min-width:600px)");
  const classes = useStylesForInput();

  const mystyle = {
    color: "white",
    backgroundColor: "DodgerBlue",
    padding: "10px",
    fontFamily: "Arial"
  };

  


  const initialState = {
    bulkTestSelected: false,
    resetedOn: "",
    defaultItems: {},
    seletedLabItems: [],
    labPriority: "",
    labTechnician: "",
    labComments: "",
    labTechniciansList: [],
  };
  function reducer(state, { field, value }) {
    return {
      ...state,
      [field]: value,
    };
  }
  const [state, dispatch] = useReducer(reducer, initialState);
  const {
    bulkTestSelected,
    resetedOn,
    defaultItems,
    seletedLabItems,
    labPriority,
    labTechnician,
    labComments,
    labTechniciansList,
  } = state;

  const handleCheckupChange = (event) => {
    dispatch({field: 'bulkTestSelected', value: event.target.value});
  };

  const uniqueCodeField = (name) => {
    name = name.toLowerCase();
    name = name.replace(/[^a-z0-9]+/g, '-');
    name = name.replace(/^-+|-+$/g, '');
    return name;
  }

  const [currentUser] = useState(cookie.load("current_user"));
  const [timer, setTimer] = useState(null);
  const [selectedLab, setSelectedLab] = useState("");
  const [categoriesData, setCategoriesData] = useState([]);
  const [mItems, setMItems] = useState([]);

  const [patientDetails, setPatientDetails] = useState("");
  const [selectedRecord, setSelectedRecord] = useState({});

  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setsuccessMsg] = useState("");
  const [openNotification, setOpenNotification] = useState(false);

  const [ episodeId, setEpisodeId ] = useState(props.match.params.id);
  const [ labRequestId, setLabRequestId ] = useState(props.match.params.requestId);
  const [ labServiceId, setLabServiceId ] = useState(props.match.params.serviceId);
  const [ labRequest, setLabRequest ] = useState({});
  const [ labServices, setLabServices ] = useState([]);
  const [ labService, setLabService ] = useState({});
  const [ status, setStatus ] = useState("");
  
  if (openNotification) {
    setTimeout(() => {
      setOpenNotification(false);
      setErrorMsg("");
      setsuccessMsg("");
    }, 4000);
  }

  useEffect(() => {
    axios
      .get(getLabRequest + "/" + labRequestId)
      .then((res) => {
        if (res.data.success) {
          if (res.data.data[0]) {


            let dataR = res.data.data[0];

            let remoteRecord = replacePatientIdIfNull(dataR);
            setSelectedRecord(remoteRecord);
            console.log('rec::rec::', remoteRecord.labRequest);

            const object = {
              '_id': remoteRecord._id,
              patientName: remoteRecord.patientId.name[0].given[0] + " " + remoteRecord.patientId.name[0].family,
              episodeNumber: remoteRecord.requestNo,//remoteRecord.patientId.identifier[0].value,
              mrn: remoteRecord.patientId.identifier[0].value,
              age: remoteRecord.patientId.age,
              gender: remoteRecord.patientId.gender,
              weight: remoteRecord.patientId.weight,
              department: remoteRecord.currentLocation,
              // chiefComplaint: remoteRecord.chiefComplaint.length > 0 ? remoteRecord.chiefComplaint.pop().chiefComplaintId.short_desc : "N/A",
              // chiefComplaintOthers: remoteRecord.chiefComplaintOthers,
            };
            setPatientDetails(object);

            dataR.labRequest.requestedName = dataR.labRequest.requestedBy.name[0].given[0] + " " + dataR.labRequest.requestedBy.name[0].family;
            setLabRequest(dataR.labRequest);
            console.log('dataR.labRequest', dataR.labRequest);

            console.log(res.data.data, "data");
            res.data.data.map((val) => {
              // Services
              let servicesNames = [];
              Object.entries(val.labRequest.services).map(([key, val]) => {
                servicesNames.push(val.name)
              });
              val.serviceName = servicesNames.join(',');
            });
            if (dataR.labRequest){
              console.log('Record::data::join::', dataR.labRequest.services);
              setLabServices(dataR.labRequest.services);
            }


            /////// INDIVIDUAL

            let labServiceX = remoteRecord.labRequest.services.filter(obj => obj._id === labServiceId);
            console.log('rec::rec::labServicesX', labServiceX);
            if (labServiceX.length > 0){
              /*
              let labXN = labServicesX[0];
              labXN.requestedName = labXN.requestedBy.name[0].given[0] + " " + labXN.requestedBy.name[0].family;
              console.log('labServicesX', labXN.requestedBy.name);
              console.log('labServicesX', labXN);
              setLabRequest(labXN)
              // setLabRequest(labServicesX[0])
              setLabServices(labServicesX[0].services);
              */
              setLabService(labServiceX[0]);
              setStatus(labServiceX[0].status);

              console.log('labServicesX::labServiceX', labServiceX[0]);
            }


          }
        }
      });
  }, []);

  const handleView = (rec) => {
    let url = '/dashboard/home/labtechnicianpatientdetail/details/'+episodeId+'/'+labRequestId+'/view/'+rec.requestId+'';
    props.history.push({
      pathname: url,
    });
  }

  const handleChange = (event) => {
    setStatus(event.target.value);
  };

  const handleSavePorter = () => {
    axios
      .post(porterReceiptNotificationLabTechnician, {
        edrId: selectedRecord._id,
        requestId: labRequestId,
        serviceId: labServiceId,
        requestStatus: 'completed',
        completeType: 'individual'
      })
      .then((res) => {
        if (res.data.success) {
          setOpenNotification(true);
          setsuccessMsg(`The Lab Request has been marked as ${status}!`);
          window.location.reload();
        }
      })
      .catch((e) => {
        setOpenNotification(true);
        setErrorMsg("Unable to Send Receipt to Porter");
      });
  }

  const handleSave = () => {
    axios
        .put(completeLabTechnicianServiceRequest, {
          edrId: selectedRecord._id,
          requestId: labRequestId,
          serviceId: labServiceId,
          requestStatus: 'completed',
          completeType: 'individual'
        })
        .then((res) => {
          if (res.data.success) {
            setOpenNotification(true);
            setsuccessMsg(`The Lab Request has been marked as ${status}!`);
            window.location.reload();
          }
        })
        .catch((e) => {
          setOpenNotification(true);
          setErrorMsg("Unable to Update the Record");
        });
  }

  return (
    
    <div
      style={{
        backgroundColor: "rgb(19 213 159)",
        position: "fixed",
        display: "flex",
        width: "100%",
        height: "100%",
        flexDirection: "column",
        flex: 1,
        overflowY: "scroll",
      }}
    >
      <Header history={props.history} />

      <PatientDetailsQuick
        edrId={patientDetails._id} />

      <div className="cPadding">


        <div className="subheader">
          <div>
            <img src={LabRequestIcon} />
            <h4>Lab Service Requests</h4>
          </div>
          {/* <div>
            <ButtonField onClick={addNewItem} name='add' />
          </div> */}
        </div>

        <div className="container-fluid"
          style={{
            marginTop: "25px",
          }}>

          {patientDetails && labRequest ? (
            <div className="mb-3">
              <div className="card pt-3 pl-3 pr-3">
                <div className="row row-5">
                  <div className="col-6 col-lg-3 card-detail-block block-xdiv mb-3"><strong className="lblX">Episode #</strong><span className="valX">{patientDetails.episodeNumber}</span></div>
                  <div className="col-6 col-lg-3 card-detail-block block-xdiv mb-3"><strong className="lblX">MRN #</strong><span className="valX">{patientDetails.mrn}</span></div>
                  <div className="col-6 col-lg-3 card-detail-block block-xdiv mb-3"><strong className="lblX">Requester</strong><span className="valX">{labRequest.requestedName}</span></div>
                  <div className="col-6 col-lg-3 card-detail-block block-xdiv mb-3"><strong className="lblX">Turn Around Time</strong><span className="valX">{displayInHumanDifference(labRequest.requestedAt)}</span></div>
                </div>
              </div>
            </div>
          ) : (
            undefined
          )}

          <h4 className="h6 text-white mb-3"
            style={{
              fontWeight: '700'
            }}>{labRequest.requestId} Service Details</h4>
          <div className="row row-5">
            {
              labService ? 
                <div className="col-12">
                  <div className="card card-detail-info mb-2">
                    <div className="row row-5">
                      <div className="col-sm-6 card-detail-block block-xdiv mb-2"><strong className="lblX">Request ID</strong><span className="valX">{labService.requestId}</span></div>
                      <div className="col-sm-6">&nbsp;</div>
                      <div className="col-sm-12 card-detail-block block-xdiv"><strong className="lblX">Profile/Test</strong><span className="valX">{labService.category}: <span>{labService.name}</span></span></div>
                      <div className="col-12 col-md-6 card-detail-block block-xdiv mt-3"><strong className="lblX">Requester</strong><span className="valX">{labRequest.requestedName}</span></div>
                      <div className="col-12 col-md-6 card-detail-block block-xdiv mt-3"><strong className="lblX">Date and Time</strong><span className="valX">{displayInHumanDifference(labRequest.requestedAt)}</span></div>
                      <div className="col-sm-12 card-detail-block block-xdiv mt-3"><strong className="lblX">Notes</strong><span className="valX"><span>{labService.notes ?? '-'}</span></span></div>
                    </div>
                    <div className="card-status-action">
                      <span className={classNames(`badge`, (labService.status=="completed"?'badge-success':'status-pending'))}>{labService.status}</span>
                      <span className={classNames(`badge`, (labService.nurseTechnician=="Collected"?'badge-success':'status-pending'))}>{labService.nurseTechnician}</span>
                      { 
                        labService.urgent==="Yes"?<span className="ml-info">!</span>:undefined
                      }
                    </div>
                  </div>
                </div>
                :undefined
            }
            {
              labServices && status !== "completed" ? 
                <>
                <div className="col-12 col-md-6 mt-3 mt-lg-3 text-center">
                  <Button
                    style={{
                      backgroundColor: "#AD6BBF",
                      color: "white",
                      cursor: "pointer",
                      borderRadius: 5,
                      backgroundColor: "rgb(173, 107, 191)",
                      height: matches ? 56 : 45,
                      outline: "none",
                      width: "100%",
                    }}
                    onClick={handleSavePorter}
                    variant="contained"
                    color="primary"
                    disabled={labService.porterId==null}
                    fullWidth>Send Receipt to Porter</Button>
                </div>
                <div className="col-12 col-md-6 mt-3 mt-lg-3 text-center">
                  <Button
                    style={{
                      backgroundColor: "#AD6BBF",
                      color: "white",
                      cursor: "pointer",
                      borderRadius: 5,
                      backgroundColor: "rgb(173, 107, 191)",
                      height: matches ? 56 : 45,
                      outline: "none",
                      width: "100%",
                    }}
                    onClick={handleSave}
                    variant="contained"
                    color="primary"
                    fullWidth>Completed</Button>
                </div>
                </>
              : undefined
            }
          </div>
          <div className='row mt-4 mb-4'>
            <div className='col-md-12 col-sm-12 col-12'>
              <img
                onClick={() => props.history.goBack()}
                src={Back}
                style={{
                  width: 45,
                  height: 35,
                  cursor: 'pointer',
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <Notification
        msg={errorMsg}
        open={openNotification}
        success={successMsg}
      />
    </div>
  );
}