import React from 'react'
import { Redirect } from 'react-router-dom'
import cookie from 'react-cookies'
import Header from '../../../../components/Header/Header'
import DepartmentManagementIcon from '../../../../assets/img/DepartmentManagement.png'
import EDMgmtIcon from '../../../../assets/img/ED RoomsManagement.png'
import PAIcon from '../../../../assets/img/Production Area.png'
import EOUMgmtIcon from '../../../../assets/img/EOU RoomsManagement.png'
import CommunicationManagementIcon from '../../../../assets/img/CommunicationManagement.png'
import StaffManagementIcon from '../../../../assets/img/Staff Management.png'
import Back from '../../../../assets/img/Back_Arrow.png'
import ShiftMgmtIcon from '../../../../assets/img/ED RoomsHistory.png'
import MenuTree from '../../../../components/MenuTree/MenuTree'

const Management = [
  { img: DepartmentManagementIcon, text: 'Department Management' },
  {
    img: EDMgmtIcon,
    text: 'ED Rooms Management',
    path: '/dashboard/home/departmentmanagement/edRoomsMgmt',
  },
  {
    img: EOUMgmtIcon,
    text: 'EOU Rooms Management',
    path: '/dashboard/home/departmentmanagement/eouRoomsMgmt',
  },
  {
    img: CommunicationManagementIcon,
    text: 'Communication Management',
    path: '/dashboard/home/departmentmanagement/communicationMgmt',
  },

  {
    img: PAIcon,
    text: 'Production Area',
    path: '/dashboard/home/departmentmanagement/productionarea',
  },
  {
    img: StaffManagementIcon,
    text: 'Staff Management',
    path: '/dashboard/home/departmentmanagement/staff',
  },
  {
    img: ShiftMgmtIcon,
    text: 'Shifts Management',
    path: 'management/shifts',
  },

  // {
  //   img: ShiftMgmtIcon,
  //   text: 'Code Blue Team',
  //   path: '/dashboard/home/codeblue',
  // },
]

class ManagementComponent extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      openApps: false,
      goBack: false,

      currentUser: '',
    }
  }

  componentDidMount() {
    this.setState({ currentUser: cookie.load('current_user') })
  }

  render() {
    if (this.state.goBack) {
      return <Redirect to={'/management'} />
    }

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          position: 'fixed',
          width: '100%',
          height: '100%',
          background:
            'linear-gradient(25deg, rgba(101,228,193,1) 0%, rgba(58,219,175,1) 33%, rgba(15,206,147,1) 66%, rgba(6,142,103,1) 100%)',
          backgroundSize: '100%',
        }}
      >
        <Header history={this.props.history} />

        <MenuTree history={this.props.history} options={Management} />

        <div
          style={{
            position: 'fixed',
            width: '100%',
            height: '10%',
            top: '90%',
          }}
        >
          <img
            alt='Back'
            onClick={() => this.props.history.goBack()}
            src={Back}
            style={{
              width: 45,
              height: 35,
              marginLeft: '5%',
              cursor: 'pointer',
            }}
          />
        </div>
      </div>
    )
  }
}
export default ManagementComponent
