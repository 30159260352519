import React, { useEffect, useState, useReducer } from "react";
import { withStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import Header from "../../components/Header/Header";
import classNames from "classnames";
import Back from "../../assets/img/Back_Arrow.png";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { DefaultTooltipContent } from 'recharts/lib/component/DefaultTooltipContent';
import {medicineFrequencyArray} from "./configForNotesScreen"
import DoneIcon from "@material-ui/icons/Done";
import AddIcon from "@material-ui/icons/Add";
import CustomTable from "../../components/Table/Table";
import VoiceComponent from "../../components/VoiceComponent/VoiceComponent";
import '../../assets/jss/material-dashboard-react/components/TextInputStyle.css';
import moment from 'moment';
import { 
  Chip,
  Button, 
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment,
  MenuItem,
  TextField,
  Select,
  useMediaQuery,
} from "@material-ui/core";
import cookie from "react-cookies";

import axios from "axios";
import {
  audioURL,
  updatePainScaleInterventions,
  getSingleEdr,
  updateEdr
} from "../../public/endpoins";

import useStylesForInput from "../../assets/jss/material-dashboard-react/inputStyle";
import styles from "./styles";
import Loader from "react-loader-spinner";
import {
  Checkbox, 
  FormLabel, 
  FormControl, 
  FormGroup, 
  FormControlLabel,
  RadioGroup, 
  Radio,
  makeStyles
} from '@material-ui/core';
import {
  priorityArray,
  statusArray,
} from "./configForNotesScreen";
import Icon from '@material-ui/core/Icon';
import CloseIcon from '@material-ui/icons/Close';
import MicIcon from "@material-ui/icons/Mic";
import StopIcon from "@material-ui/icons/Stop";
import MicRecorder from "mic-recorder-to-mp3";
import AccountCircle from "@material-ui/icons/SearchOutlined";
import Notification from "../../components/Snackbar/Notification.js";

import LabIcon from "../../assets/img/Lab.png";
import { displayInHumanDifference, replacePatientIdIfNull, displayInHumanClassic } from "../../utils/commonFunc";
import PatientDetailsQuick from "../../components/PatientDetails/PatientDetailsQuick";
import PatientDetails from "../../components/PatientDetails/PatientDetails";
import PatientClinicalInformation from "../../components/PatientDetails/PatientClinicalInformation";

const tableHeadingForPainScale = ["Date/Time", "Scale", "Intervention", "Requested", "Action"];
const tableDataKeysForPainScale = ["datecol", "scale", "intervention", "requester"];

const actions = { view: false };

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(0),
  },
  input: {
    backgroundColor: "white",
    boxShadow: "none",
    borderRadius: 5,
    "&:after": {
      borderBottomColor: "black",
      boxShadow: "none",
    },
    "&:hover": {
      backgroundColor: "white",
      boxShadow: "none",
    },
    "&:focus": {
      backgroundColor: "white",
      boxShadow: "none",
      borderRadius: 5,
    },
  },
  multilineColor: {
    boxShadow: "none",
    backgroundColor: "#e5e5e5",
    borderRadius: 5,
    "&:hover": {
      backgroundColor: "#e5e5e5",
      boxShadow: "none",
    },
    "&:after": {
      borderBottomColor: "#e5e5e5",
      boxShadow: "none",
    },
    "&:focus": {
      boxShadow: "none",
    },
  },
}));

export default function PainManagementPage(props) {
  
  const matches = useMediaQuery("(min-width:600px)");
  const classes = useStylesForInput();
  const classesx = useStyles();

  const mystyle = {
    color: "white",
    backgroundColor: "DodgerBlue",
    padding: "10px",
    fontFamily: "Arial"
  };

  const handleView = (rec) => {

  }

  const GreenCheckbox = withStyles({
    root: {
      color: '#AAAAAA',
      '&$checked': {
        color: '#AAAAAA',
      },
    },
    checked: {
      color: '#2C6DDD',
      '&$checked': {
        color: '#2C6DDD',
      },
    },
  })((props) => <Checkbox color="default" {...props} />);

  const GreenRadio = withStyles({
    root: {
      color: '#AAAAAA',
      '&$checked': {
        color: '#AAAAAA',
      },
    },
    checked: {
      color: '#2C6DDD',
      '&$checked': {
        color: '#2C6DDD',
      },
    },
  })((props) => <Radio color="default" {...props} />);

  
  const letStyleMarkLabels = {
    marginTop: '8px',
    '&[data-index="0"]': {
      left: '0!important',
      transform: 'none!important'
    },
    '&[data-index="2"]': {
      left: 'auto!important',
      right: '0!important',
      transform: 'none!important'
    },
  };

  const stylesx = {
    ROSRowPadding: {
      marginLeft: 0,
      marginRight: 0,
      backgroundColor: "white",
      marginTop: 10,
      padding: "10px 0px 10px 0px",
      borderRadius: 5,
    },
    chipAlignment: {
      display: "inline-block",
      padding: "5px",
    },
    save: {
      color: "white",
      cursor: "pointer",
      borderRadius: 5,
      backgroundColor: "#2c6ddd",
      width: "115px",
      height: "40px",
      outline: "none",
    },
  };


  const PainScaleSlider = withStyles({
    root: {
      //color: '#52af77',
      color: 'linear-gradient(45deg, #aa6775 30%, #984355 90%)', // Works
      height: 8,
    },
    thumb: {
      height: 24,
      width: 24,
      backgroundColor: '#fff',
      border: '2px solid currentColor',
      marginTop: -8,
      marginLeft: -12,
      '&:focus, &:hover, &$active': {
        boxShadow: 'inherit',
      },
    },
    active: {},
    valueLabel: {
      left: 'calc(-50% + 4px)',
    },
    track: {
      height: 8,
      borderRadius: 4,
      background: 'transparent'
      // backgroundImage: "linear-gradient(to right, #16c100 0%,#edc32d 40%,#edc32d 40%,#edc32d 60%,#ed2024 100%)"
    },
    rail: {
      height: 8,
      borderRadius: 4,
      opacity: 1,
      backgroundImage: "linear-gradient(to right, #16c100 0%,#ed2024 100%)"
    },
    markLabel: letStyleMarkLabels
  })(Slider);


  const CustomTooltip = (props) => {
    // we just render the default
    // return <div><pre>{JSON.stringify(props.payload, 4, undefined)}</pre></div>;



    if (!props.active) {
      // I think returning null works based on this: http://recharts.org/en-US/examples/CustomContentOfTooltip
      return null
    }
    // mutating props directly is against react's conventions
    // so we create a new payload with the name and value fields set to what we want
    const newPayload = [
      {
        name: 'Date Taken',
        value: (
          props.payload ? (
            props.payload[0] && props.payload[0].payload ?
            displayInHumanDifference(props.payload[0].payload.name):''
          ):''
        ),
      },
      {
        name: 'Intervention',
        value: (props.payload ? (
          props.payload[0] && props.payload[0].payload?
          props.payload[0].payload.intervention:''
        ):''),
      },
      ...props.payload,
    ];

    // we render the default, but with our overridden payload
    return <DefaultTooltipContent {...props} payload={newPayload} />;
  };

  const initialState = {
    newPainCount: 0,
    intervention: [],
    notes: '',
    painScaleData: [],
    painScaleTable: []
  };
  function reducer(state, { field, value }) {
    return {
      ...state,
      [field]: value,
    };
  }
  const [state, dispatch] = useReducer(reducer, initialState);
  const [audioNotes, setAudioNotes]  = useState("");
  const {
    newPainCount,
    intervention,
    notes,
    painScaleData,
    painScaleTable,
  } = state;

  const handleCheckupChange = (event) => {
    dispatch({field: 'bulkTestSelected', value: event.target.value});
  };

  const uniqueCodeField = (name) => {
    name = name.toLowerCase();
    name = name.replace(/[^a-z0-9]+/g, '-');
    name = name.replace(/^-+|-+$/g, '');
    return name;
  }

  const handleCastTrigger = (parentId, subId) => {
    let urlX = `/dashboard/home/${episodeId}/pharmacy/listMedication/${parentId}/details/${subId}`
    console.log('remoteRecord.pharmacyRequest::click', urlX)

    let formData = new FormData();
    formData.append("edrId", episodeId);
    formData.append("painScale", newPainCount);
    formData.append("interventions", JSON.stringify(intervention));
    formData.append("notes", notes);

    if ( audioNotes && audioNotes instanceof Blob )
    {
      formData.append(`AudioFile`, audioNotes, `interventions.mp3`);
    }

    console.log(audioNotes)

    axios
      .put(updatePainScaleInterventions, formData)
      .then((res) => {
        if (res.data.success) {
          setOpenNotification(true)
          setsuccessMsg(`The Administered has been completed!`)
          // window.location.reload()
          getRefreshedItem()
          dispatch({field: 'refreshedOn', value: 1})
        }
      })
      .catch((e) => {
        setOpenNotification(true)
        setErrorMsg("Unable to Update the Record")
      });
  }

  const handlePharmaHistory = () => {
    let url = `/dashboard/home/${episodeId}/pharmacy/medication-history`
    props.history.push({
      pathname: url,
    });
    // props.history.go();
  }

  const viewAdministedDetails = (parentId, subId) => {
    props.history.push({
      pathname: `/dashboard/home/${episodeId}/pharmacy/listMedication/${parentId}/details/${subId}`
    });
  }

  const [currentUser] = useState(cookie.load("current_user"));
  const [timer, setTimer] = useState(null);
  const [selectedLab, setSelectedLab] = useState("");
  const [mItems, setMItems] = useState([]);

  const [patientDetails, setPatientDetails] = useState("");
  const [selectedRecord, setSelectedRecord] = useState({});

  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setsuccessMsg] = useState("");
  const [openNotification, setOpenNotification] = useState(false);

  const [ episodeId, setEpisodeId ] = useState(props.match.params.id);
  const [ pharmaRequestId, setPharmaRequestId ] = useState(props.match.params.requestId);
  const [ pharmaRequests, setPharmaRequests ] = useState([]);
  const [ serviceType, setServiceType ] = useState(props.match.params.serviceType);

  const handleTheIntervention = (nType) => {
    console.log(nType);
    let nSelectedIntervention = intervention;
    if (intervention.includes(nType)){
      let indexItem = nSelectedIntervention.indexOf(nType);
      nSelectedIntervention.splice(indexItem);
    }else{
      nSelectedIntervention.push(nType)
    }

    console.log(nSelectedIntervention);
    dispatch({ field: 'intervention', value: nSelectedIntervention })
  }

  if (openNotification) {
    setTimeout(() => {
      setOpenNotification(false);
      setErrorMsg("");
      setsuccessMsg("");
    }, 4000);
  }

  const getRefreshedItem = () => {
    axios
      .get(getSingleEdr + "/" + episodeId)
      .then((res) => {
        console.log('handleView::', res.data.data);
        if (res.data.success) {

          let remoteRecord = replacePatientIdIfNull(res.data.data);
          setSelectedRecord(remoteRecord);

          const object = {
            '_id': remoteRecord._id,
            patientName: remoteRecord.patientId.name[0].given[0] + " " + remoteRecord.patientId.name[0].family,
            mrn: remoteRecord.requestNo,//remoteRecord.patientId.identifier[0].value,
            age: remoteRecord.patientId.age,
            gender: remoteRecord.patientId.gender,
            weight: remoteRecord.patientId.weight,
            department: remoteRecord.currentLocation,
            chiefComplaint: remoteRecord.chiefComplaint.length > 0 ? remoteRecord.chiefComplaint.pop().chiefComplaintId.short_desc : "N/A",
            chiefComplaintOthers: remoteRecord.chiefComplaintOthers,
          };
          setPatientDetails(object);

          let pharmaServicesX = remoteRecord.pharmacyRequest.filter(obj => (obj.deliveryStatus === "completed" && obj.status === "completed"));
          if (pharmaServicesX.length > 0){
            console.log('remoteRecord.pharmacyRequest::fintered', pharmaServicesX);

            let pharmaAdministrationItems = [];

            Object.entries(pharmaServicesX).map((obj, entry) => {
              Object.entries(obj[1].item).map((pharmaRequest, entryIndex) => {
                let nItem = pharmaRequest[1]
                nItem.parentId = obj[1]._id
                nItem.pharmacyRequestNo = obj[1].pharmacyRequestNo
                pharmaAdministrationItems.push(nItem)
              })
            })
            setPharmaRequests( pharmaAdministrationItems )
            console.log('remoteRecord.pharmacyRequest::pharmaAdministrationItems', pharmaAdministrationItems)
          }

          if (remoteRecord.painManagement){
            let psPainManagement = [],
                psDataBox = [];
            Object.entries(remoteRecord.painManagement).map( ( [inx, iv] ) => {
              psPainManagement.push({
                name: moment(iv.date).format('MM/DD/YYYY hh:mm:ss a'),
                pain: iv.painScale,
                intervention: iv.intervention.join(', '),
              })

              psDataBox.push({
                "datecol"            : moment(iv.date).format('LL LT'),
                "scale"           : iv.painScale,
                "intervention"    : iv.intervention.join(', '),
                "requester"       : `${iv.requestedBy.name[0].given[0]} ${iv.requestedBy.name[0].family}`
              })

            } );
            dispatch({ field: 'painScaleData', value: psPainManagement })
            dispatch({ field: 'painScaleTable', value: psDataBox.reverse() })
          }
          

        }
      })
      .catch((e) => {
        console.log("error after searching patient request", e);
      });
  }

  // const painScaleData = [
  //   {
  //     name: 'September 11, 2023',
  //     pain: 4,
  //   },
  //   {
  //     name: 'September 12, 2023',
  //     pain: 3,
  //   },
  //   {
  //     name: 'September 15, 2023',
  //     pain: 2,
  //   },
  //   {
  //     name: 'September 16, 2023',
  //     pain: 2,
  //   },
  //   {
  //     name: 'September 21, 2023',
  //     pain: 1,
  //   },
  //   {
  //     name: 'September 25, 2023',
  //     pain: 2,
  //   },
  //   {
  //     name: 'September 27, 2023',
  //     pain: 3,
  //   },
  // ];

  useEffect(() => {
    getRefreshedItem()
    
  }, []);

  return (
    
    <div
      style={{
        backgroundColor: "rgb(19 213 159)",
        position: "fixed",
        display: "flex",
        width: "100%",
        height: "100%",
        flexDirection: "column",
        flex: 1,
        overflowY: "scroll",
      }}
    >
      <Header history={props.history} />

      <PatientDetailsQuick
        edrId={episodeId} />

      <div className="cPadding">
        <div className="subheader">
          <div>
            <img src={LabIcon} />
            <h4>{patientDetails.patientName}</h4>
          </div>
        </div>

        <PatientClinicalInformation
          edrId={episodeId}
          wrapClass="mt-3 mb-3" />

        <h4 className="h6 text-white mb-3"
          style={{
            fontWeight: '700'
          }}>Previous Pain Management</h4>
        <div className="row row-5">
          <div className="col-12 col-md-12 col-lg-12 col-xl-12">
            <div className="card card-detail-info skip-top-padding mb-2">
              <div style={{height: 600}}>
                <ResponsiveContainer width="100%" height="100%">
                  <LineChart
                    width={500}
                    height={300}
                    data={painScaleData}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    {painScaleData.length > 0 ? <Tooltip content={<CustomTooltip />} /> : undefined }
                    <Legend />
                    <Line type="monotone" dataKey="pain" stroke="#82ca9d" activeDot={{ r: 8 }} />
                  </LineChart>
                </ResponsiveContainer>
              </div>
            </div>
            
            <div className="card card-detail-info skip-top-padding mb-2">
              <strong>Pain Management Register</strong>
              <div className="mt">
                <CustomTable
                    tableData={painScaleTable}
                    tableDataKeys={tableDataKeysForPainScale}
                    tableHeading={tableHeadingForPainScale}
                    action={actions}
                    borderBottomColor={"#60d69f"}
                    handleView={handleView}
                    borderBottomWidth={20}
                  />
              </div>
            </div>

            <div className="card card-detail-info skip-top-padding mb-2">
              <strong>New Pain Registration</strong>
              <div className="mt-5 px-3">
                <PainScaleSlider
                    aria-labelledby="enter-pulse-ox"
                    step={1}
                    min={0}
                    max={10}
                    marks={[
                      {
                        value: 0,
                        label: '0',
                      },
                      {
                        value: 5,
                        label: '5',
                      },
                      {
                        value: 10,
                        label: '10',
                      },
                    ]}
                    value={newPainCount}
                    valueLabelDisplay="on"
                    onChange={(e, value) => {
                      dispatch({ field: 'newPainCount', value: value });
                    }}
                  />
                <div className="intervention mt-3">
                  <div className = "row">
                    <div className="col-md-3"><strong>Intervension</strong>: </div>
                    <div className="col-md-9">
                      {
                        newPainCount <= 0?
                        <div className="form-group">No Intervention Required</div>:
                        <div className="form-group">
                          <span style={stylesx.chipAlignment}>
                            <Chip
                              label="ICE Elevate"
                              icon= { intervention.includes("ICE Elevate") ? <DoneIcon style={{ color: "white" }} />:undefined}
                              style={ intervention.includes("ICE Elevate") ? {color: "white",backgroundColor: "rgb(19 213 159)"} : { color: "#070707", backgroundColor: "#F5F5F5"}}
                              onClick={() => handleTheIntervention('ICE Elevate') } />
                          </span>
                          <span style={stylesx.chipAlignment}>
                            <Chip
                              label="Soft splint"
                              icon= { intervention.includes("Soft splint") ? <DoneIcon style={{ color: "white" }} />:undefined}
                              style={ intervention.includes("Soft splint") ? {color: "white",backgroundColor: "rgb(19 213 159)"} : { color: "#070707", backgroundColor: "#F5F5F5"}}
                              onClick={() => handleTheIntervention('Soft splint') } />
                          </span>
                          <span style={stylesx.chipAlignment}>
                            <Chip
                              label="Dressing applied"
                              icon= { intervention.includes("Dressing applied") ? <DoneIcon style={{ color: "white" }} />:undefined}
                              style={ intervention.includes("Dressing applied") ? {color: "white",backgroundColor: "rgb(19 213 159)"} : { color: "#070707", backgroundColor: "#F5F5F5"}}
                              onClick={() => handleTheIntervention('Dressing applied') } />
                          </span>
                          <span style={stylesx.chipAlignment}>
                            <Chip
                              label="Bleeding Controlled"
                              icon= { intervention.includes("Bleeding Controlled") ? <DoneIcon style={{ color: "white" }} />:undefined}
                              style={ intervention.includes("Bleeding Controlled") ? {color: "white",backgroundColor: "rgb(19 213 159)"} : { color: "#070707", backgroundColor: "#F5F5F5"}}
                              onClick={() => handleTheIntervention('Bleeding Controlled') } />
                          </span>
                          <span style={stylesx.chipAlignment}>
                            <Chip
                              label="Hard Collar placed"
                              icon= { intervention.includes("Hard Collar placed") ? <DoneIcon style={{ color: "white" }} />:undefined}
                              style={ intervention.includes("Hard Collar placed") ? {color: "white",backgroundColor: "rgb(19 213 159)"} : { color: "#070707", backgroundColor: "#F5F5F5"}}
                              onClick={() => handleTheIntervention('Hard Collar placed') } />
                          </span>
                          <span style={stylesx.chipAlignment}>
                            <Chip
                              label="Acetaminophen"
                              icon= { intervention.includes("Acetaminophen") ? <DoneIcon style={{ color: "white" }} />:undefined}
                              style={ intervention.includes("Acetaminophen") ? {color: "white",backgroundColor: "rgb(19 213 159)"} : { color: "#070707", backgroundColor: "#F5F5F5"}}
                              onClick={() => handleTheIntervention('Acetaminophen') } />
                          </span>
                          <span style={stylesx.chipAlignment}>
                            <Chip
                              label="NPO Instruction given"
                              icon= { intervention.includes("NPO Instruction given") ? <DoneIcon style={{ color: "white" }} />:undefined}
                              style={ intervention.includes("NPO Instruction given") ? {color: "white",backgroundColor: "rgb(19 213 159)"} : { color: "#070707", backgroundColor: "#F5F5F5"}}
                              onClick={() => handleTheIntervention('NPO Instruction given') } />
                          </span>
                          <span style={stylesx.chipAlignment}>
                            <Chip
                              label="Ibuprofen"
                              icon= { intervention.includes("Ibuprofen") ? <DoneIcon style={{ color: "white" }} />:undefined}
                              style={ intervention.includes("Ibuprofen") ? {color: "white",backgroundColor: "rgb(19 213 159)"} : { color: "#070707", backgroundColor: "#F5F5F5"}}
                              onClick={() => handleTheIntervention('Ibuprofen') } />
                          </span>
                          <span style={stylesx.chipAlignment}>
                            <Chip
                              label="Respiratory Precautions Initiated"
                              icon= { intervention.includes("Respiratory Precautions Initiated") ? <DoneIcon style={{ color: "white" }} />:undefined}
                              style={ intervention.includes("Respiratory Precautions Initiated") ? {color: "white",backgroundColor: "rgb(19 213 159)"} : { color: "#070707", backgroundColor: "#F5F5F5"}}
                              onClick={() => handleTheIntervention('Respiratory Precautions Initiated') } />
                          </span>
                        </div>
                      }
                    </div>
                  </div>

                  <div className="form-group" style={{
                    backgroundColor: 'gray',
                    borderRadius: '5px'
                  }}>
                    <TextField
                      multiline
                      label="Remarks"
                      name={"notes"}
                      value={notes}
                      // error={dischargeNotes === '' && detailsForm}
                      onChange={(e) => dispatch({field: 'notes', value: e.target.value})}
                      rows={4}
                      className="textInputStyle2x"
                      variant="filled"
                      InputProps={{
                        className: classesx.multilineColor,
                        classes: {
                          input: classesx.multilineColor,
                        },
                      }}
                      // inputProps={{ maxLength: 12 }}
                    />
                  </div>

                  <VoiceComponent
                    audioFile={audioNotes}
                    setAudioFile={setAudioNotes}
                    />
                </div>

                <div className="mt-3 text-right">
                  <Button
                      style={{
                        color: 'white',
                        cursor: 'pointer',
                        borderRadius: 5,
                        backgroundColor: '#AD6BBF',
                        height: '50px',
                        outline: 'none',
                      }}
                      onClick={handleCastTrigger}
                      variant="contained"
                      color="primary"
                    >
                      Register New
                    </Button>
                </div>
              </div>
            </div>


          </div>
        </div>

        
        <div
          className="container-fluid"
          style={{ marginBottom: "25px", marginTop: "25px" }}
        >
          <div className="row">
            <img
              onClick={() => props.history.goBack()}
              src={Back}
              style={{ width: 45, height: 35, cursor: "pointer" }}
            />
          </div>
        </div>
      </div>


      <Notification
        msg={errorMsg}
        open={openNotification}
        success={successMsg}
      />
    </div>
  );
}