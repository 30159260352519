import React, { useEffect, useState } from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Flip from "../../../components/Dial/Flip";
import TwoValue from "../../../components/Dial/TwoValue";
import OneValue from "../../../components/Dial/OneValue";
import { nurseTechnician } from "../../../public/endpoins";
import Loader from "react-loader-spinner";
import {
  getDialerGraphCall,
  getValuesCardCalls,
  getResponse,
} from "./NetworkCalls";

export default function NurseTechnician() {
  const matches = useMediaQuery("(min-width:600px)");
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setsuccessMsg] = useState("");
  const [openNotification, setOpenNotification] = useState(false);

  const [responseData, setResponseData] = useState([]);

  // Sample collection pending
  const [samplePending, setSamplePending] = useState("");
  const [samplePendingColor, setSamplePendingColor] = useState("");
  const [samplePendingTAT, setSamplePendingTAT] = useState("");
  const [samplePendingTATColor, setSamplePendingTATColor] = useState("");
  const [samplePendingGraphData, setSamplePendingGraphData] = useState("");
  // Patient transfer pending
  const [transfersPending, setTransfersPending] = useState("");
  const [transfersPendingColor, setTransfersPendingColor] = useState("");
  const [transfersPendingTAT, setTransfersPendingTAT] = useState("");
  const [transfersPendingTATColor, setTransfersPendingTATColor] = useState("");
  const [isLoading, setisLoading] = useState(true);
  const [transfersPendingGraphData, setTransfersPendingGraphData] = useState(
    ""
  );
  // Average No of Tasks
  const [noOfHour, setNoOfHour] = useState("");
  const [noOfHourColor, setNoOfHourColor] = useState("");
  const [noOfHourTAT, setNoOfHourTAT] = useState("");
  const [noOfHourTATColor, setNoOfHourTATColor] = useState("");
  const [noOfHourGraphData, setNoOfHourGraphData] = useState("");

  const [flip, setFlip] = useState(false);
  const [dummyData, setDummyData] = useState("");

  useEffect(() => {
    const dummyData = [
      { value: 10, label: new Date("2021-02-16T07:48:26.948Z") },
      { value: 100, label: new Date("2021-02-16T12:48:26.948Z") },
      { value: 80, label: new Date("2021-02-16T11:48:26.948Z") },
      { value: 70, label: new Date("2021-02-16T10:48:26.948Z") },
      { value: 60, label: new Date("2021-02-16T09:48:26.948Z") },
      { value: 50, label: new Date("2021-02-16T08:48:26.948Z") },
    ];

    setDummyData(dummyData);
  }, []);

  useEffect(() => {
    getResponse(
      nurseTechnician,
      setResponseData,
      setOpenNotification,
      setErrorMsg,
      setisLoading
    );
    console.log("*in getResponse edDoctor");
  }, []);

  if (openNotification) {
    setTimeout(() => {
      setOpenNotification(false);
      setErrorMsg("");
      setsuccessMsg("");
    }, 2000);
  }

  useEffect(() => {
    let arr = [];

    Object.entries(responseData).map(([key, val]) => {
      if (key !== "success") {
        console.log("key of data", key, "val of data", val);
        arr.push({ [key]: val });
      }
    });

    console.log("arr::", arr);
    if (arr && arr.length > 0) {
      getDialerGraphCall(
        35,
        65,
        35,
        65,
        arr[0].firstCard,
        "perHour",
        setSamplePending,
        setSamplePendingColor,
        setSamplePendingTAT,
        setSamplePendingTATColor,
        setSamplePendingGraphData
      );
      getDialerGraphCall(
        35,
        65,
        35,
        65,
        arr[1].secondCard,
        "transferPerHour",
        setTransfersPending,
        setTransfersPendingColor,
        setTransfersPendingTAT,
        setTransfersPendingTATColor,
        setTransfersPendingGraphData
      );
      // getDialerGraphCall(
      //   35,
      //   65,
      //   35,
      //   65,
      //   arr[2].thirdCard,
      //   'tasksPerHour',
      //   setNoOfHour,
      //   setNoOfHourColor,
      //   setNoOfHourTAT,
      //   setNoOfHourTATColor,
      //   setNoOfHourGraphData
      // )

      //   getValuesCardCalls(
      //     150,
      //     260,
      //     arr[5].cumulativeTasks,
      //     setComulativeTasks,
      //     setComulativeTasksColor
      //   )
    }
  }, [responseData]);

  const flipIt = () => {
    setFlip(true);
  };
  return (
    <div className="container-fluid" style={{ marginBottom: 10 }}>
      <div className="row">
        <div
          className="col-md-4 col-6"
          style={{ paddingLeft: 4, paddingRight: 4, marginTop: 10 }}
        >
          <div>
            <Flip
              flipped={!flip}
              flipHandler={flipIt}
              mainHeading={"Sample Collections Pending"}
              value={
                isLoading ? (
                  <Loader
                    type="TailSpin"
                    color="green"
                    height={35}
                    width={35}
                  />
                ) : samplePending === 0 || samplePending === null ? (
                  "0"
                ) : (
                  samplePending
                )
              }
              mainHeadingForGraph="Sample Collections Pending"
              color={samplePendingColor}
              subHeading={"TAT"}
              flagsCount={10}
              childHeading={"Request to Collection"}
              time={
                isLoading ? (
                  <Loader
                    type="TailSpin"
                    color="green"
                    height={35}
                    width={35}
                  />
                ) : samplePendingTAT === 0 || samplePendingTAT === null ? (
                  "0"
                ) : (
                  samplePendingTAT
                )
              }
              timeColor={samplePendingTATColor}
              idForGraph={"container1"}
              data={
                isLoading ? (
                  <Loader
                    type="TailSpin"
                    color="green"
                    height={35}
                    width={35}
                  />
                ) : (
                  samplePendingGraphData
                )
              }
            />
          </div>
        </div>

        <div
          className="col-md-4 col-6"
          style={{ paddingLeft: 4, paddingRight: 4, marginTop: 10 }}
        >
          <div>
            <Flip
              flipped={!flip}
              flipHandler={flipIt}
              mainHeading={"Patient Transfers Pending"}
              value={
                isLoading ? (
                  <Loader
                    type="TailSpin"
                    color="green"
                    height={35}
                    width={35}
                  />
                ) : transfersPending === 0 || transfersPending === null ? (
                  "0"
                ) : (
                  transfersPending
                )
              }
              mainHeadingForGraph="Patient Transfers Pending"
              color={transfersPendingColor}
              subHeading={"TAT"}
              childHeading={"Request to Transfer"}
              time={
                isLoading ? (
                  <Loader
                    type="TailSpin"
                    color="green"
                    height={35}
                    width={35}
                  />
                ) : transfersPendingTAT === 0 ||
                  transfersPendingTAT === null ? (
                  "0"
                ) : (
                  transfersPendingTAT
                )
              }
              timeColor={transfersPendingTATColor}
              idForGraph={"container2"}
              data={
                isLoading ? (
                  <Loader
                    type="TailSpin"
                    color="green"
                    height={35}
                    width={35}
                  />
                ) : (
                  transfersPendingGraphData
                )
              }
            />
          </div>
        </div>

        <div
          className="col-md-4 col-6"
          style={{ paddingLeft: 4, paddingRight: 4, marginTop: 10 }}
        >
          <div>
            <Flip
              flipped={!flip}
              flipHandler={flipIt}
              mainHeading={"Average No. of Tasks/Hour"}
              // value={
              //   isLoading ? (
              //     <Loader
              //       type="TailSpin"
              //       color="green"
              //       height={35}
              //       width={35}
              //     />
              //   ) : noOfHour === 0 || noOfHour === null ? (
              //     "0"
              //   ) : (
              //     noOfHour
              //   )
              // }
              value={5}
              mainHeadingForGraph="Average No. of Tasks/Hour"
              // color={noOfHourColor}
              color={"#60D69F"}
              subHeading={"TAT"}
              childHeading={"Per Task"}
              // time={
              //   isLoading ? (
              //     <Loader
              //       type="TailSpin"
              //       color="green"
              //       height={35}
              //       width={35}
              //     />
              //   ) : noOfHourTAT === 0 || noOfHourTAT === null ? (
              //     "0"
              //   ) : (
              //     noOfHourTAT
              //   )
              // }
              time={"0"}
              // timeColor={noOfHourTATColor}
              timeColor={"#60D69F"}
              idForGraph={"container3"}
              data={dummyData}
            />
          </div>
        </div>
        {/* </div> */}

        {/* <div className='row'> */}

        {/* <div
          className="col-md-4 col-6"
          style={{ paddingLeft: 4, paddingRight: 4, marginTop: 10 }}
        >
          <div>
            <Flip
              flipped={!flip}
              flipHandler={flipIt}
              mainHeading={"Average No. of Blood Tests/Hour"}
              value={35}
              mainHeadingForGraph="Patient Rad Consultation Notes Pending"
              color="#FFBC28"
              subHeading={"TAT"}
              childHeading={"Order to Consultation Notes"}
              time={"70"}
            />
          </div>
        </div> */}
        {/* </div> */}

        <div
          className="col-md-4 col-6"
          style={{ paddingLeft: 4, paddingRight: 4, marginTop: 10 }}
        >
          <div>
            <OneValue
              time={250}
              colorTime={"#FFBC28"}
              heading={"Cumulative total Tasks Processed"}
            />
          </div>
        </div>

        {/* </div> */}

        {/* <div className='row'> */}
        {/* <div
          className="col-md-4 col-6"
          style={{ paddingLeft: 4, paddingRight: 4, marginTop: 10 }}
        >
          <div>
            <OneValue
              time={20}
              colorTime={"#13D59F"}
              heading={"Average No. of Patients Diagnosed Per Hour"}
            />
          </div>
        </div> */}
        {/* <div
          className="col-md-4 col-6"
          style={{ paddingLeft: 4, paddingRight: 4, marginTop: 10 }}
        >
          <div>
            <OneValue
              time={650}
              colorTime={"#13D59F"}
              heading={"Compliance/Variance Report"}
            />
          </div>
        </div> */}
      </div>
    </div>
  );
}
