export const allowedRolesForRO = [
  { tabValue: 0, key: "Sensei", value: "Sensei" },
  { tabValue: 1, key: "Paramedics", value: "Paramedics" },
];

export const allowedRolesForSensei = [
  {
    tabValue: 0,
    key: "Registration Officer",
    value: "Registration Officer",
  },
  { tabValue: 1, key: "Paramedics", value: "Paramedics" },
  { tabValue: 2, key: "ED Doctor", value: "ED Doctor" },
  { tabValue: 3, key: "Transfer To EOU", value: "Transfer To EOU" },
  // { tabValue: 4, key: "ED", value: "ED" },
  // { tabValue: 5, key: "EOU", value: "EOU" },
  { tabValue: 6, key: "Code Blue Team", value: "Code Blue Team" },
];

export const allowedRolesForAdmin = [
  {
    tabValue: 0,
    key: "Communication Requests",
    value: "Communication Requests",
  },
  {
    tabValue: 1,
    key: "New Patient Entry and Allocation",
    value: "New Patient Entry and Allocation",
  },
  {
    tabValue: 2,
    key: "Critial Function Calls",
    value: "Critial Function Calls",
  },
  { tabValue: 3, key: "Medication Requests", value: "Medication Requests" },
  {
    tabValue: 3,
    key: "Social Workers Assisting Patients",
    value: "Social Workers Assisting Patients",
  },
  // { tabValue: 4, key: 'ED', value: 'ED' },
  // { tabValue: 5, key: 'EOU', value: 'EOU' },
  // { tabValue: 6, key: 'Code Blue Team', value: 'Code Blue Team' },
];

export const allowedRolesForImagingTechnician = [
  {
    tabValue: 0,
    key: "ED Doctor",
    value: "ED Doctor",
  },
];

export const allowedRolesForPorter = [
  {
    tabValue: 0,
    key: "Nurse Technician",
    value: "Nurse Technician",
  },
  {
    tabValue: 1,
    key: "Lab Technicians",
    value: "Lab Technicians",
  },
  {
    tabValue: 2,
    key: "Pharmacist",
    value: "Pharmacist",
  },
];

export const allowedRolesForLabTechnician = [
  {
    tabValue: 0,
    key: "ED Doctor",
    value: "ED Doctor",
  },
  /*
  {
    tabValue: 1,
    key: "Nurse Technician",
    value: "Nurse Technician",
  },
  {
    tabValue: 2,
    key: "RCM Officer",
    value: "RCM Officer",
  },*/
  {
    tabValue: 2,
    key: "Porter",
    value: "Porter",
  },
];

export const allowedRolesForHouseKeeping = [
  {
    tabValue: 0,
    key: "Sensei",
    value: "Sensei",
  },
  {
    tabValue: 1,
    key: "Imaging Technician",
    value: "Imaging Technician",
  },
];

export const allowedRolesForSocialWorker = [
  {
    tabValue: 0,
    key: "Admitted",
    value: "Admitted",
  },
  {
    tabValue: 1,
    key: "Discharged",
    value: "Discharged",
  },
  {
    tabValue: 2,
    key: "Transferred",
    value: "Transferred",
  },
  {
    tabValue: 3,
    key: "Deceased",
    value: "Deceased",
  },
];

export const allowedRolesForAnesthesiologist = [
  {
    tabValue: 0,
    key: "ED Doctor",
    value: "ED Doctor",
  },
];

export const allowedRolesForInternalConsultant = [
  {
    tabValue: 0,
    key: "ED Doctor",
    value: "ED Doctor",
  },
];

export const allowedRolesForExternalConsultant = [
  {
    tabValue: 0,
    key: "ED Doctor",
    value: "ED Doctor",
  },
];

export const allowedRolesForCustomerCare = [
  {
    tabValue: 0,
    key: "ED Doctor",
    value: "ED Doctor",
  },
  {
    tabValue: 0,
    key: "Transfer To Bed",
    value: "Transfer To Bed",
  },
  {
    tabValue: 0,
    key: "Transfer To EOU",
    value: "Transfer To EOU",
  },
  {
    tabValue: 0,
    key: "Transfer To Radiology",
    value: "Transfer To Radiology",
  },
  {
    tabValue: 1,
    key: "Ambulance Request",
    value: "Ambulance Request",
  },
  {
    tabValue: 2,
    key: "Medications Request",
    value: "Medications Request",
  },
  {
    tabValue: 3,
    key: "Discharge Request",
    value: "Discharge Request",
  },
];

export const allowedRolesForEDDoctor = [
  {
    tabValue: 0,
    key: "Paramedics",
    value: "Sensei",
  },
  {
    tabValue: 1,
    key: "Registration Officer",
    value: "Registration Officer",
  },
  {
    tabValue: 2,
    key: "Clinical Pharmacist",
    value: "Clinical Pharmacist",
  },
  {
    tabValue: 3,
    key: "Nurse",
    value: "Nurse",
  },
  {
    tabValue: 31,
    key: "ED Nurse",
    value: "ED Nurse",
  },
  {
    tabValue: 41,
    key: "Consultant",
    value: "Consultant",
  },
  {
    tabValue: 4,
    key: "Code Blue Calls",
    value: "Code Blue Calls",
  },
  {
    tabValue: 5,
    key: "Discharged",
    value: "Discharge OR Disposition",
  },
  {
    tabValue: 6,
    key: "Lab Technicians",
    value: "Lab Technicians",
  },
  {
    tabValue: 7,
    key: "Imaging Technicians",
    value: "Imaging Technicians",
  },
  {
    tabValue: 8,
    key: "RCM Officer",
    value: "RCM Officer",
  },
  {
    tabValue: 9,
    key: "Customer Care",
    value: "Customer Care",
  },
];

export const allowedRolesForRRR = [
  {
    tabValue: 0,
    key: "ED Doctor",
    value: "ED Doctor",
  },
  {
    tabValue: 0,
    key: "RCM Officer",
    value: "RCM Officer",
  },
  {
    tabValue: 0,
    key: "Customer Care",
    value: "Customer Care",
  }
]

export const allowedRolesForPharmacist = [
  {
    tabValue: 0,
    key: "ED Doctor",
    value: "ED Doctor",
  },
  {
    tabValue: 1,
    key: "CareStream Assigned",
    value: "CareStream Assigned",
  },
  {
    tabValue: 2,
    key: "Reconciliation Request",
    value: "Reconciliation Request",
  },
];

export const allowedRolesForEDNurse = [
  {
    tabValue: 0,
    key: "Paramedics",
    value: "Sensei",
  },
  {
    tabValue: 1,
    key: "Registration Officer",
    value: "Registration Officer",
  },
  {
    tabValue: 2,
    key: "ED Doctor",
    value: "ED Doctor",
  },
  {
    tabValue: 3,
    key: "CareStream",
    value: "CareStream",
  },
  {
    tabValue: 4,
    key: "Disposition OR Discharge",
    value: "Disposition OR Discharge",
  },
  {
    tabValue: 5,
    key: "Lab Technicians",
    value: "Lab Technicians",
  },
  {
    tabValue: 6,
    key: "Radiologist",
    value: "Radiologist",
  },
  {
    tabValue: 7,
    key: "Pharmacist",
    value: "Pharmacist",
  },
  {
    tabValue: 8,
    key: "Porter",
    value: "Porter",
  },
  {
    tabValue: 9,
    key: "Customer Care",
    value: "Customer Care",
  },
];

export const allowedRolesForInsuranceClaim = [
  {
    tabValue: 0,
    key: "ED Doctor",
    value: "ED Doctor",
  }
  /*
  {
    tabValue: 0,
    key: "New Patient",
    value: "New Patient",
  },
  {
    tabValue: 1,
    key: "Patient Disposition OR Discharged",
    value: "Patient Disposition OR Discharged",
  },
  */
];

export const allowedRolesForCashier = [
  {
    tabValue: 0,
    key: "Registration Officer",
    value: "Registration Officer",
  },
  {
    tabValue: 1,
    key: "Insurance Officer",
    value: "Insurance Officer",
  },
];

export const allowedRolesForEOUNurse = [
  {
    tabValue: 0,
    key: "Customer Care",
    value: "Customer Care",
  },
];

export const allowedRolesForNurseTechnician = [
  /*
  {
    tabValue: 1,
    key: "Registration Officer",
    value: "Registration Officer",
  },
  */
  {
    tabValue: 0,
    key: "ED Doctor",
    value: "ED Doctor",
  },
];

export const allowedRolesForRadDoctor = [
  {
    tabValue: 0,
    key: "CareStream Assigned",
    value: "CareStream Assigned",
  },
];

export const allowedRolesForPharmaDoctor = [
  {
    tabValue: 0,
    key: "ED Doctor",
    value: "ED Doctor",
  },
  {
    tabValue: 0,
    key: "Clinical Pharmacist",
    value: "Clinical Pharmacist",
  },
];

export function setUsrRoles(currentUser) {
  if (currentUser && currentUser.staffType === "Sensei") {
    return allowedRolesForSensei;
  } else if (currentUser && currentUser.staffType === "Registration Officer") {
    return allowedRolesForRO;
  } else if (currentUser && currentUser.staffType === "Admin") {
    return allowedRolesForAdmin;
  } else if (currentUser && currentUser.staffType === "Imaging Technician") {
    return allowedRolesForImagingTechnician;
  } else if (currentUser && currentUser.staffType === "Lab Technician") {
    return allowedRolesForLabTechnician;
  } else if (currentUser && currentUser.staffType === "Porter") {
    return allowedRolesForPorter;
  } else if (currentUser && currentUser.staffType === "Registered Pharmacist") {
    return allowedRolesForPharmaDoctor
  } else if (currentUser && currentUser.staffType === "House Keeping") {
    return allowedRolesForHouseKeeping;
  } else if (currentUser && currentUser.staffType === "Social Worker") {
    return allowedRolesForSocialWorker;
  } else if (currentUser && currentUser.subType[0] === "Anesthesiologist") {
    return allowedRolesForAnesthesiologist;
  } else if (currentUser && currentUser.subType[0] === "Internal") {
    return allowedRolesForInternalConsultant;
  } else if (currentUser && currentUser.subType[0] === "External") {
    return allowedRolesForExternalConsultant;
  } else if (currentUser && currentUser.staffType === "Customer Care") {
    return allowedRolesForCustomerCare;
  } else if (currentUser && currentUser.subType[0] === "ED Doctor") {
    return allowedRolesForEDDoctor;
  } else if (currentUser && currentUser.subType[0] === "ED Nurse") {
    return allowedRolesForEDNurse;
  } else if (currentUser && currentUser.subType[0] === "EOU Nurse") {
    return allowedRolesForEOUNurse;
  } else if (currentUser && currentUser.subType[0] === "Nurse Technician") {
    return allowedRolesForNurseTechnician;
  } else if (currentUser && currentUser.subType[0] === "Rad Doctor") {
    return allowedRolesForRadDoctor;
  } else if (currentUser && currentUser.staffType === "Clinical Pharmacist") {
    return allowedRolesForPharmacist;
  } else if (currentUser && currentUser.staffType === "Radiology Request Receiver") {
    return allowedRolesForRRR;
  } else if (
    currentUser &&
    currentUser.staffType === "Insurance Claims Manager"
  ) {
    return allowedRolesForInsuranceClaim;
  } else if (currentUser && currentUser.staffType === "Cashier") {
    return allowedRolesForCashier;
  }
}
