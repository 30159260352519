import { makeStyles } from '@material-ui/core/styles'

export const styles = {
  stylesForButton: {
    color: 'white',
    cursor: 'pointer',
    borderRadius: 5,
    backgroundColor: '#2c6ddd',
    height: '45px',
    outline: 'none',
  },
  stylesForNextButton: {
    color: 'white',
    cursor: 'pointer',
    borderRadius: 5,
    backgroundColor: '#2c6ddd',
    width: '140px',
    height: '45px',
    outline: 'none',
  },
}

export const useStylesForTabs = makeStyles(() => ({
  root: {
    justifyContent: 'center',
  },
  scroller: {
    flexGrow: '0',
  },
}))

export const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(0),
  },
  input: {
    backgroundColor: '#F5F5F5',
    boxShadow: 'none',
    borderRadius: 5,
    '&:after': {
      borderBottomColor: 'black',
      boxShadow: 'none',
    },
    '&:hover': {
      backgroundColor: '#F5F5F5',
      boxShadow: 'none',
    },
    '&:focus': {
      backgroundColor: '#F5F5F5',
      boxShadow: 'none',
      borderRadius: 5,
    },
  },
  multilineColor: {
    boxShadow: 'none',
    backgroundColor: '#F5F5F5',
    borderRadius: 5,
    '&:hover': {
      backgroundColor: '#F5F5F5',
      boxShadow: 'none',
    },
    '&:after': {
      borderBottomColor: 'black',
      boxShadow: 'none',
    },
    '&:focus': {
      boxShadow: 'none',
    },
  },
  root: {
    '& .MuiTextField-root': {
      backgroundColor: '#F5F5F5',
    },
    '& .Mui-focused': {
      backgroundColor: '#F5F5F5',
      color: 'black',
      boxShadow: 'none',
    },
    '& .Mui-disabled': {
      backgroundColor: '#F5F5F5',
      color: 'gray',
    },
    '&:focus': {
      backgroundColor: '#F5F5F5',
      boxShadow: 'none',
    },
    '& .MuiFormLabel-root': {
      fontSize: '11px',
      paddingRight: '45px',
    },
  },
}))
