import React from "react";
import { Route, Switch } from "react-router-dom";
import NotFound from "../components/NotFound/NotFound";
import ReconciliationRequest from "../views/ReconsiliationRequests/reconciliationRequests";
import AddReconsiliationRequest from "../views/ReconsiliationRequests/addReconsiliationRequest";

class PatientManagementRoutes extends React.PureComponent {
  render() {
    return (
      <Switch>
        <Route
          exact
          path={`${this.props.match.url}`}
          component={ReconciliationRequest}
        />

        <Route
          exact
          path={`${this.props.match.url}/add`}
          component={AddReconsiliationRequest}
        />

        <Route path={`${this.props.match.url}/notfound`} component={NotFound} />

        <Route path="*" component={NotFound} />
      </Switch>
    );
  }
}
export default PatientManagementRoutes;
