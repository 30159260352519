// ----------- use these lines when running on local
// const local = 'http://192.168.43.55:4000/api'
const serverURL = "khmc.servehttp.com";
// const serverURL = "165.227.170.128";
// const serverURL = "localhost";

// const local = `http://${serverURL}:4000/api`;
// export const audioURL = `http://${serverURL}:4000`;
// export const uploadsUrl = `http://${serverURL}:4000/uploads/`;
// export const socketUrl = `ws://${serverURL}:4001`;
// export const socketUrlForWebRTC = `ws://${serverURL}:4002`;

const local = `https://${serverURL}/backend`;
export const audioURL = `https://${serverURL}`;
export const uploadsUrl = `https://${serverURL}/uploads/`;
export const socketUrl = `https://${serverURL}:4001`;
export const socketUrlForWebRTC = `https://${serverURL}:4002`;

// const local = 'http://142.93.170.247/api';
// export const audioURL = 'http://142.93.170.247/';
// export const uploadsUrl = 'http://142.93.170.247/';
// export const socketUrl = 'ws://142.93.170.247:4001';
// export const socketUrlForWebRTC = 'ws://142.93.170.247:4002';

// -------uncomment below lines on production
// const stagingDigitalOcean = "https://dev.khmc-staging.online/api";
// export const audioURL = "https://dev.khmc-staging.online";
// export const uploadsUrl = "https://dev.khmc-staging.online/";
// export const socketUrl = 'wss://dev.khmc-staging.online:4001'

const baseUrl = local;
// const baseUrl = stagingDigitalOcean;

// subscribe
export const addSubscriber = `${baseUrl}/subscriber/postsubscriber`;

// login
export const login = `${baseUrl}/auth/login`;

// notification Center Endpoints
export const markNotification = `${baseUrl}/notifications/updatenotifications`;
export const getNotifications = `${baseUrl}/notifications/getnotifications`;
export const notificationCount = `${baseUrl}/notifications/notificationCount`;
export const readNotifications = `${baseUrl}/notifications/readNotifications`;
export const unReadNotifications = `${baseUrl}/notifications/unReadNotifications`;

// login/logout recording endpoints
export const recordLogin = `${baseUrl}/loginRecord/createLogin`;
export const recordLogout = `${baseUrl}/loginRecord/recordLogout`;
export const authLogout = `${baseUrl}/auth/logout`;

// Registration Officer / Patient endpoints
export const patientRegistration = `${baseUrl}/dhrPatient/registerPatient`;
export const updatePatientUrl = `${baseUrl}/dhrPatient/updatePatient`;
export const completedRegistration = `${baseUrl}/dhrPatient/completedRegistration`;
export const pendingRegistration = `${baseUrl}/dhrPatient/pendingRegistration`;
export const SearchCompletedRegistration = `${baseUrl}/dhrPatient/approvedPatient`;
export const SearchPendingRegistration = `${baseUrl}/dhrPatient/pendingPatient`;
export const SearchInRegistration  = `${baseUrl}/dhrPatient/patientSearch`;
export const SearchPatients = `${baseUrl}/dhrPatient/searchPatient`;
export const getPatientByIdUrl = `${baseUrl}/dhrPatient/getPatient`;
export const addDepositForPatient = `${baseUrl}/dhrPatient/addDeposits`;
export const pendingEdrRegistration = `${baseUrl}/edr/pendingRegistration`;
export const generateEDR = `${baseUrl}/edr/generateEDR`;
export const getEDRs = `${baseUrl}/edr/getEDRs`;
export const edrQRView = `${baseUrl}/edr/qrview`;
export const getAllPatients = `${baseUrl}/dhrPatient/getAllPatients`;
export const SearchEDRPatients = `${baseUrl}/edr/searchEdrPatient`;
export const getPendingEDRs = `${baseUrl}/edr/getPendingEDRs`;
export const getSenseiPendingEDRs = `${baseUrl}/edr/getSenseiPendingEDRs`;
export const searchPendingSenseiEdr = `${baseUrl}/edr/searchPendingSenseiEdr`;
export const searchAllEdrs = `${baseUrl}/edr/searchAllEdrs`;
export const getPendingDischargedEDRs = `${baseUrl}/edr/getPendingDischargedEDRs`;
export const searchPendingDischargedEDRs = `${baseUrl}/edr/getPendingDischargedEDRs`;

export const searchPendingEdr = `${baseUrl}/edr/searchPendingEdr`;
//export const patientRegistration = `${baseUrl}/dhrPatient/registerPatient`;
//export const updatePatientUrl = `${baseUrl}/dhrPatient/updatePatient`;
//export const completedRegistration = `${baseUrl}/dhrPatient/completedRegistration`;
//export const pendingRegistration = `${baseUrl}/dhrPatient/pendingRegistration`;
//export const SearchCompletedRegistration = `${baseUrl}/dhrPatient/approvedPatient`;
//export const SearchPendingRegistration = `${baseUrl}/dhrPatient/pendingPatient`;
//export const SearchPatients = `${baseUrl}/dhrPatient/searchPatient`;
//export const getPatientByIdUrl = `${baseUrl}/dhrPatient/getPatient`;
//export const generateEDR = `${baseUrl}/edr/generateEDR`;
//export const getEDRs = `${baseUrl}/edr/getEDRs`;
//export const getAllPatients = `${baseUrl}/dhrPatient/getAllPatients`;
//export const SearchEDRPatients = `${baseUrl}/edr/searchEdrPatient`;
//export const getPendingEDRs = `${baseUrl}/edr/getPendingEDRs`;
//export const getSenseiPendingEDRs = `${baseUrl}/edr/getSenseiPendingEDRs`;
//export const searchPendingSenseiEdr = `${baseUrl}/edr/searchPendingSenseiEdr`;

//export const searchPendingEdr = `${baseUrl}/edr/searchPendingEdr`;
export const pendingDoctorNotes = `${baseUrl}/edr/pendingDoctorNotes`;
export const inprogressDoctorNotes = `${baseUrl}/edr/inprogressDoctorNotes`;
export const completedDoctorNotes = `${baseUrl}/edr/completedDoctorNotes`;

// Registration Officer Dashboard
export const roDashboard = `${baseUrl}/reports/roDashboard`;
export const roSenseiPending = `${baseUrl}/reports/roSenseiPending`;
export const roTotalPending = `${baseUrl}/reports/roTotalPending`;

// ED Doctor Dashboard
export const edDoctorDashboard = `${baseUrl}/reports/edDoctorDashboard`;

// Sensei
export const senseiDashboard = `${baseUrl}/reports/senseiDashboard`;

//chat
export const createChat = `${baseUrl}/chatroom/createchat`;
export const deleteChat = `${baseUrl}/chatroom/deletechat`;
export const uploadChatFile = `${baseUrl}/chatroom/uploadchatfile`;

// Staff Endpoints
export const registerStaff = `${baseUrl}/staff/registerStaff`;
export const getAllStaff = `${baseUrl}/staff/getAllStaff`;
export const getAllEDDoctorsUrl = `${baseUrl}/staff/geteddoctors`;
export const getAllSenseiUrl = `${baseUrl}/staff/getAllSensei`;
export const getAllParamedicsUrl = `${baseUrl}/staff/getAllParamedics`;
export const getUsersFromRoleUrl = `${baseUrl}/staff/getUsersFromRole`;
export const updateStaff = `${baseUrl}/staff/updateStaff`;
export const getDoctorSubTypes = `${baseUrl}/staff/getDoctorSubTypes`;
export const getNursesSubTypes = `${baseUrl}/staff/getNurseSubTypes`;
export const getDoctorSpecialty = `${baseUrl}/staff/getDoctorSpecialty`;
export const getNursesSpecialty = `${baseUrl}/staff/getNurseSpecialty`;
export const disableStaff = `${baseUrl}/staff/disableStaff`;
export const enableStaff = `${baseUrl}/staff/enableStaff`;
export const getAllDoctors = `${baseUrl}/staff/getAllDoctors`;
export const searchSensei = `${baseUrl}/staff/searchSensei`;
export const searchParamedics = `${baseUrl}/staff/searchParamedics`;
export const AssignShifts = `${baseUrl}/sensei/assignShifts`;
export const getPAsByCCs = `${baseUrl}/chiefComplaint/getPAsByCCs`;
export const getEDNurses = `${baseUrl}/staff/getEDNurses`;
export const getAllNurses = `${baseUrl}/staff/getAllNurses`;
export const getAllEOUNurses = `${baseUrl}/staff/getAllEOUNurses`;
export const searchEDNurses = `${baseUrl}/staff/searchEDNurse`;
export const searchEouNurses = `${baseUrl}/staff/searchEouNurses`;
export const radTestStats = `${baseUrl}/staff/radTestStats`;
export const searchRadTestsStats = `${baseUrl}/staff/searchRadTestsStats`;
export const getAdditionalRoles = `${baseUrl}/staff/getAdditionalRoles`;
export const getProductionStaff =  `${baseUrl}/staff/getProductionTeam`;
export const getStaffByType = `${baseUrl}/staff/getStaffByType`;

// Sensei
export const getCCPatients = `${baseUrl}/sensei/getCCPatients`;
export const searchCCPatients = `${baseUrl}/sensei/searchCCPatients`;
export const AssignProductionArea = `${baseUrl}/room/assignRoom`;
export const assignRoomToPatient = `${baseUrl}/room/assignRoom`;
export const getPatientsByPA = `${baseUrl}/getPatientsByPA`;
export const getPatientsByRoom = `${baseUrl}/sensei/getPatientByRoom`;
export const getEOUPatients = `${baseUrl}/sensei/getEOUPatients`;
export const getEDPatients = `${baseUrl}/sensei/getEDPatients`;
export const getCR = `${baseUrl}/sensei/getCR`;
export const searchEDPatients = `${baseUrl}/sensei/searchEDPatients`;
export const searchEOUPatients = `${baseUrl}/sensei/searchEOUPatients`;
export const addReqForNurse = `${baseUrl}/senseiAssistanceRequest/addReqForNurse`;
export const updateReqForNurse = `${baseUrl}/senseiAssistanceRequest/updateReqForNurse`;
export const getReqForNurse = `${baseUrl}/senseiAssistanceRequest/getReqForNurse`;
export const completeReqForNurse = `${baseUrl}/senseiAssistanceRequest/completeReqForNurse`;
export const updateSubmitRequestForHousekeeping = `${baseUrl}/edNurse/updateSubmitRequest`;
/////// statistics APIs
//// ED Room history
export const getTimeInterval = `${baseUrl}/sensei/timeInterval`;
export const getTransferToEOU = `${baseUrl}/sensei/transferToEOU`;
export const getDischarged = `${baseUrl}/sensei/getDischarged`;
export const getLabTest = `${baseUrl}/sensei/getLabTest`;
export const getDeceasedPatients = `${baseUrl}/sensei/getDeceased`;
//// ED current activity
export const getAvailableEdBeds = `${baseUrl}/sensei/availableEdBeds`;
export const getEDCCPatients = `${baseUrl}/sensei/getEDCCPatients`;
export const getPatientTreatment = `${baseUrl}/sensei/getPatientTreatment`;
export const getMedicationReconciliation = `${baseUrl}/sensei/getMedicationReconciliation`;
export const getCurrentTimeInterval = `${baseUrl}/sensei/currentTimeInterval`;
export const getCurrentLabTest = `${baseUrl}/sensei/getCurrentLabTest`;
export const getCurrentRadTest = `${baseUrl}/sensei/getCurrentRadTest`;
export const getCriticalLabTest = `${baseUrl}/sensei/getCriticalLabTest`;
export const getDischargedRequirements = `${baseUrl}/sensei/getDischargedRequirements`;
//// EOU room history
export const geteouTimeInterval = `${baseUrl}/sensei/eouTimeInterval`;
//// EOU Current activity
export const geteouTransferRequest = `${baseUrl}/sensei/eouTransferRequest`;
export const getdoctorResponseTime = `${baseUrl}/sensei/doctorResponseTime`;

// ED Doctor
export const getEDRsWithCareStream = `${baseUrl}/careStream/getEDRswithCS`;
export const addDoctorNotes = `${baseUrl}/edr/addDoctorNotes`;
export const updateDoctorNotes = `${baseUrl}/edr/updateDoctorNotes`;
export const searchEdrWithCareStream = `${baseUrl}/careStream/searchEdrwithCS`;
export const addLabRequest = `${baseUrl}/edr/addLabRequest`;
export const addLabRequestBulk = `${baseUrl}/edr/addLabRequestBulk`;
export const updateLabRequest = `${baseUrl}/edr/updateLab`;
export const addRadRequest = `${baseUrl}/edr/addRadRequest`;
export const addRadRequestBulk = `${baseUrl}/edr/addRadRequestBulk`;
export const updateRadRequest = `${baseUrl}/edr/updateRad`;
export const getSpecialityDoctor = `${baseUrl}/staff/getSpecialityDoctor`;
export const getSpecialityDoctor2 = `${baseUrl}/staff/getSpecialityDoctor2`;
export const addConsultationNotes = `${baseUrl}/edr/addConsultationNote`;
export const updateConsultationNote = `${baseUrl}/edr/updateConsultationNote`;
export const getAnesthesiologist = `${baseUrl}/staff/getAnesthesiologist`;
export const searchAnesthesiologist = `${baseUrl}/staff/searchAnesthesiologist`;
export const completeCareStream = `${baseUrl}/careStream/completeCS`;
export const getInProgressCSForDoctors = `${baseUrl}/careStream/edInProgressCS`;
export const getCompletedCS = `${baseUrl}/careStream/getCompletedCS`;

export const addAnesthesiologistNote = `${baseUrl}/edr/addAnesthesiologistNote`;
export const updateAnesthesiologistNote = `${baseUrl}/edr/updateAnesthesiologistNote`;
export const getSpecialityNurse = `${baseUrl}/staff/getSpecialityNurse`;
export const addEDNurseRequest = `${baseUrl}/edr/addEDNurseRequest`;
export const updateEDNurseRequest = `${baseUrl}/edr/updateEDNurseRequest`;
export const addEOUNurseRequest = `${baseUrl}/edr/addEOUNurseRequest`;
export const updateEOUNurseRequest = `${baseUrl}/edr/updateEOUNurseRequest`;
export const getEOUNurse = `${baseUrl}/staff/getEOUNurse`;
export const addNurseTechnicianRequest = `${baseUrl}/edr/addNurseTechnicianRequest`;
export const updateNurseTechnicianRequest = `${baseUrl}/edr/updateNurseTechnicianRequest`;
export const getNurseTechnician = `${baseUrl}/staff/getNurseTechnician`;
export const getAllPendingRadRequests = `${baseUrl}/edr/getAllPendingRadRequests`;
export const getAllPendingLabRequests = `${baseUrl}/edr/getAllPendingLabRequests`;
export const getAllCompletedRadRequests = `${baseUrl}/edr/getAllCompletedRadRequests`;
export const getAllCompletedLabRequests = `${baseUrl}/edr/getAllCompletedLabRequests`;

export const addPharmacyRequest = `${baseUrl}/edr/addPharmacyRequest`;
export const updatePharmacyRequest = `${baseUrl}/edr/updatePharmacyRequest`;
export const deliverPharmcayRequest = `${baseUrl}/edr/deliverPharmcayRequest`;
export const getEDRsWithPharmacyRequestURL = `${baseUrl}/edr/getEDRsWithPharmacyRequest`;
export const getAllDeliverInProgessPharmaRequestURL = `${baseUrl}/edr/getAllDeliverInProgessPharmaRequest`;
export const updateConflictRequestByClinicalPharma  = `${baseUrl}/edr/updateConflictRequestByClinicalPharma`;
export const updateConflictRequestByEdDoctor  = `${baseUrl}/edr/updateConflictRequestByEdDoctor`;

// Registered Pharma
export const getPharmaListsForRegisteredPharma = `${baseUrl}/edr/getMedicine`;
export const getRegisteredPharmaPendingReq   = `${baseUrl}/pharmaRequest/getRegisteredPharmaPendingEdr`;
export const getRegisteredPharmaCompletedReq = `${baseUrl}/pharmaRequest/getRegisteredPharmaCompletedEdr`;
export const getRegisteredPharmaReqDetail    = `${baseUrl}/pharmaRequest/getRegisteredPharmaEdrDetail`;
export const updateRegisteredPharmaReqDetail = `${baseUrl}/pharmaRequest/updateRegisteredPharmaReqDetail`;
export const updateRegisteredPharmaReqEducation = `${baseUrl}/pharmaRequest/updateRegisteredPharmaReqEducation`;

export const qrBuilder = `${baseUrl}/miscData/qrBuilder`;
export const addToFavoriteList = `${baseUrl}/miscData/addToFavorite`;
export const getFavoriteList = `${baseUrl}/miscData/getFavoriteList`;
export const getFavoriteItem = `${baseUrl}/miscData/getFavoriteItem`;
export const updateFavoriteList = `${baseUrl}/miscData/updateFavoriteList`;

// Lab Tests
export const getAllLabServices = `${baseUrl}/labService/getAllLabServices`;
export const getLabServices = `${baseUrl}/labService/allLabServices`;
export const searchAllLabs = `${baseUrl}/labService/searchAllLabs`;
export const addLabService = `${baseUrl}/labService/addLabService`;
export const updateLabService = `${baseUrl}/labService/updateLabService`;
export const searchLabService = `${baseUrl}/labService/searchLabService`;
export const disableLabService = `${baseUrl}/labService/disableLabService`;
export const enableLabService = `${baseUrl}/labService/enableLabService`;
export const getLabServiceCategory = `${baseUrl}/labService/listByCategories`;

// Rad Tests
export const getAllRadServices = `${baseUrl}/radService/getAllRadServices`;
export const addRadService = `${baseUrl}/radService/addRadService`;
export const updateRadService = `${baseUrl}/radService/updateRadService`;
export const searchRadService = `${baseUrl}/radService/searchRadService`;
export const disableRadService = `${baseUrl}/radService/disableRadService`;
export const enableRadService = `${baseUrl}/radService/enableRadService`;
export const getRadServiceCategory = `${baseUrl}/radService/listByCategories`;
export const getRadShortServiceCategory = `${baseUrl}/radService/listByShortCategories`;

// Housekeeping
export const getPendingRadHKRequests = `${baseUrl}/houseKeeper/getPendingRadHKRequests`;
export const getCompletedRadHKRequests = `${baseUrl}/houseKeeper/getCompletedRadHKRequests`;
export const getPendingSenseiHKRequests = `${baseUrl}/houseKeeper/pendingSenseiHKRequests`;
export const getCompletedSenseiHKRequests = `${baseUrl}/houseKeeper/completedSenseiHKRequests`;
export const updateRadHKRequestStatus = `${baseUrl}/houseKeeper/updateStatus`;
export const updateSenseiStatus = `${baseUrl}/houseKeeper/updateSenseiStatus`;
export const getPendingEDNurseHKRequests = `${baseUrl}/houseKeeper/pendingEDNurseHKRequests`;
export const completedEDNurseHKRequests = `${baseUrl}/houseKeeper/completedEDNurseHKRequests`;
export const updateEDNurseStatus = `${baseUrl}/houseKeeper/updateEDNurseStatus`;

// Social worker
export const getDeceasedEDRs = `${baseUrl}/socialWorker/getDeceasedEDRs`;
export const getDischargedEDRs = `${baseUrl}/socialWorker/getDischargedEDRs`;
export const getAdmittedEDRs = `${baseUrl}/socialWorker/getAdmittedEDRs`;
export const getTransferedEDRs = `${baseUrl}/socialWorker/getTransferedEDRs`;
export const addSurvey = `${baseUrl}/socialWorker/addSurvey`;
export const addSocialSurvey = `${baseUrl}/socialWorker/addSocialSurvey`;
export const getPsychiatrist = `${baseUrl}/socialWorker/getPsychiatrist`;
export const getMentalCare = `${baseUrl}/socialWorker/getMentalCare`;
export const getAdvocate = `${baseUrl}/socialWorker/getAdvocate`;
export const getCompletedAdmittedEDRs = `${baseUrl}/socialWorker/getCompletedAdmittedEDRs`;
export const getCompletedDeceasedEDRs = `${baseUrl}/socialWorker/getCompletedDeceasedEDRs`;
export const getCompletedDischargedEDRs = `${baseUrl}/socialWorker/getCompletedDischargedEDRs`;
export const getCompletedTransferedEDRs = `${baseUrl}/socialWorker/getCompletedTransferedEDRs`;
export const sendEmail = `${baseUrl}/socialWorker/sendEmail`;

export const getSWAssistance = `${baseUrl}/socialWorker/getSWAssistance`;
export const searchSWAssistance = `${baseUrl}/socialWorker/searchSWAssistance`;

// Social worker Dashboard
export const swDashboard = `${baseUrl}/reports/swDashboard`;

// Paramedics
export const ParamedicsEdr = `${baseUrl}/paramedics/paramedicsEdr`;
export const EdrTransfer = `${baseUrl}/paramedics/edrTransfer`;
export const TransferredPMEdr = `${baseUrl}/paramedics/transferredPMEdr`;
export const searchCompletedPMEdr = `${baseUrl}/paramedics/searchCompletedPMEdr`;
export const searchPendingPMEdr = `${baseUrl}/paramedics/searchPendingPMEdr`;
export const criticalCasesPerParamedicsUrl = `${baseUrl}/paramedics/criticalCasesPerParamedics`;
export const searchParamedicsByCriricalCasesUrl = `${baseUrl}/paramedics/searchParamedicsByCriricalCases`;

// Insurance Claims Manager Endpoints
export const getSearchedpatient = `${baseUrl}/reimbursementclaim/getpatient`;
export const getPreApproval = `${baseUrl}/par/getedrandipr`;
export const getRadPreApproval = `${baseUrl}/par/getedrandiprrad`;
export const addPreApproval = `${baseUrl}/par/addpar`;
export const getInsuredPatientsRegistered = `${baseUrl}/dhrPatient/getInsuredPatients`;

// Submit Claim
export const addClaim = `${baseUrl}/reimbursementclaim/addclaim`;
export const getClaim = `${baseUrl}/reimbursementclaim/getclaim`;
export const updateClaim = `${baseUrl}/reimbursementclaim/updateclaim`;
export const getInsuredPatients = `${baseUrl}/reimbursementclaim/getpatientinsurance`;
export const getpatienthistoryUrl = `${baseUrl}/reimbursementclaim/getpatienthistory`;
export const getBillSummaryUrl = `${baseUrl}/reimbursementclaim/getedripr`;
export const getNewInsuredEdrs = `${baseUrl}/reimbursementclaim/getinsuredrs`;
export const getDischargedInsurededrs = `${baseUrl}/reimbursementclaim/getdischargededrs`;

// patient endpoints
export const getPatientById = `${baseUrl}/patient/getpatient`;
export const addPatientUrl = `${baseUrl}/patient/addpatient`;
// export const generateEDR = `${baseUrl}/edr/addedr`
export const generateIPR = `${baseUrl}/ipr/addipr`;
export const getVendorApproval = `${baseUrl}/insurance/verify`;
export const searchpatient = `${baseUrl}/patient/searchpatient`;
export const searchPatientsURL = `${baseUrl}/patient/getpatientall`;

// for notifications
export const notifyTriage = `${baseUrl}/patient/triage`;
export const notifyPharmacy = `${baseUrl}/patient/pharmacy`;
export const notifyLab = `${baseUrl}/patient/lab`;
export const notifyRad = `${baseUrl}/patient/rad`;
export const notifyConsultation = `${baseUrl}/patient/consultation`;
export const notifyDischarge = `${baseUrl}/patient/discharge`;

// //IPR endpoints
export const getSingleIPRPatient = `${baseUrl}/ipr/getipr`;

// staff Types
export const getStaffTypeUrl = `${baseUrl}/stafftype/getstafftype`;
export const addStaffTypeUrl = `${baseUrl}/stafftype/addstafftype`;
export const deleteStaffTypeUrl = `${baseUrl}/stafftype/deletestafftype`;
export const updateStaffTypeUrl = `${baseUrl}/stafftype/updatestafftype`;

// staffs
export const getStaffUrl = `${baseUrl}/staff/getstaff`;
export const addStaffUrl = `${baseUrl}/staff/addstaff`;
export const deleteStaffUrl = `${baseUrl}/staff/deletestaff`;
export const updateStaffTUrl = `${baseUrl}/staff/updatestaff`;

// accessLevel
export const getAccessLevelUrl = `${baseUrl}/accesslevel/getaccesslevel`;
export const addAccessLevelUrl = `${baseUrl}/accesslevel/addaccesslevel`;
export const deleteAccessLevelUrl = `${baseUrl}/accesslevel/deleteaccesslevel`;
export const updateAccessLevelUrl = `${baseUrl}/accesslevel/updateaccesslevel`;

// system admin
export const getSystemAdminUrl = `${baseUrl}/systemadmin/getsystemadmin`;
export const addSystemAdminUrl = `${baseUrl}/systemadmin/addsystemadmin`;
export const deleteSystemAdminUrl = `${baseUrl}/systemadmin/deletesystemadmin`;
export const updateSystemAdminUrl = `${baseUrl}/systemadmin/updatesystemadmin`;

// laboratory services
export const getSearchedLaboratoryService = `${baseUrl}/laboratoryservice/getsearchedlabs`;

// PHR
export const generateOPR = `${baseUrl}/opr/addopr`;

// get RRIPR
export const getRRPatient = `${baseUrl}/ipr/getrrpatient`;

// get LR
export const getLRPatient = `${baseUrl}/ipr/getlrpatient`;

// icd code
export const getIcd = `${baseUrl}/codes/geticdcat`;
export const getIcdbyFeaturedGroup = `${baseUrl}/miscData/icdCodesByFeatured/`;
export const getIcdForDropdown = `${baseUrl}/miscData/icdCodes2/`;

// alergies
export const getAllergiesList = `${baseUrl}/alergies/`;
export const getAllergiesDropdown = `${baseUrl}/alergies/dropdown`;

// room endpoints
export const createNewRoom = `${baseUrl}/room/createRoom`;
export const getRooms = `${baseUrl}/room/getRooms`;
export const disableRoom = `${baseUrl}/room/disableRoom`;
export const enableRoom = `${baseUrl}/room/enableRoom`;
export const availableRoomsURL = `${baseUrl}/room/getAvailableRooms`;
export const availableRoomsForAssignmentURL = `${baseUrl}/room/getAvailableRoomsForAssignment`;
export const allRoomsForAssignmentURL = `${baseUrl}/room/getAllRooms`;
export const getAvailableRoomsAganistPA = `${baseUrl}/room/getAvailableRoomsAganistPA`;

// production Area Endpoints
export const createProductionArea = `${baseUrl}/productionArea/createProductionArea`;
export const updateProductionArea = `${baseUrl}/productionArea/updateProductionArea`;
export const deleteProductionArea = `${baseUrl}/productionArea/deleteProductionArea`;
export const getProductionAreas = `${baseUrl}/productionArea/getProductionAreas`;
export const disableProductionArea = `${baseUrl}/productionArea/disableProductionArea`;
export const enableProductionArea = `${baseUrl}/productionArea/enableProductionArea`;
export const getProductionArea = `${baseUrl}/productionArea/get/`;

// Chief Complaints Endpoints
export const addChiefComplaint = `${baseUrl}/chiefComplaint/addChiefComplaint`;
export const getAllChiefComplaints = `${baseUrl}/chiefComplaint/getAllChiefComplaints`;
export const getSingleChiefComplaint = `${baseUrl}/chiefComplaint/getChiefComplaintByKeyword`;
export const disableChiefComplaint = `${baseUrl}/chiefComplaint/disableChiefComplaint`;
export const enableChiefComplaint = `${baseUrl}/chiefComplaint/enableChiefComplaint`;
export const getDoctorsWithCC = `${baseUrl}/chiefComplaint/getDoctorsWithCC`;
export const getNursesWithPA = `${baseUrl}/chiefComplaint/getNursesWithCC`;
export const assignCCtoDoctors = `${baseUrl}/chiefComplaint/assignCC`;
export const assignCC = `${baseUrl}/chiefComplaint/assignCC`;
export const getCCDoctorByKeyword = `${baseUrl}/chiefComplaint/getCCDoctorByKeyword`;
export const getCCNursesByKeyword = `${baseUrl}/chiefComplaint/getCCNurseByKeyword`;
export const assignCCtoPatient = `${baseUrl}/chiefComplaint/assignCCtoPatient`;
export const getEdrPatients = `${baseUrl}/edr/getEDRs`;

export const assignPAtoCC = `${baseUrl}/chiefComplaint/assignPAtoCC`;
export const searchCCandPA = `${baseUrl}/chiefComplaint/searchCCandPA`;
export const getAvailablePA = `${baseUrl}/chiefComplaint/getAvailablePA`;
export const getAvailablePAwithCC = `${baseUrl}/chiefComplaint/getAvailablePAwithCC`;

export const getProductionTeams = `${baseUrl}/productionTeams`;
export const createProductionTeam = `${baseUrl}/productionTeams/create`;
export const updateProductionTeam = `${baseUrl}/productionTeams/update`;
export const getSingleProductionTeam = `${baseUrl}/productionTeams/get`;

// carestreams
export const addCareStream = `${baseUrl}/careStream/addCareStream`;
export const getCareStream = `${baseUrl}/careStream/getAllCareStreams`;
export const getAllEnableDisableCareStreams = `${baseUrl}/careStream/getAllEnableDisableCareStreams`;
export const enableCareStream = `${baseUrl}/careStream/enableCareStream`;
export const disableCareStream = `${baseUrl}/careStream/disableCareStream`;
export const getMedicationsCareStreams = `${baseUrl}/careStream/getMedicationsCareStreams`;
export const getMedicationsByIdCareStreams = `${baseUrl}/careStream/getMedicationsByIdCareStreams`;
export const getCSPatients = `${baseUrl}/careStream/getCSPatients`;
export const searchCSPatient = `${baseUrl}/careStream/searchCSPatient`;
export const asignCareStream = `${baseUrl}/careStream/asignCareStream`;
export const getInProgressCS = `${baseUrl}/careStream/getInProgressCS`;
export const searchInProgressCS = `${baseUrl}/careStream/searchInProgressCS`;

// consultation requests
export const getEDRWihtConsultationNote = `${baseUrl}/edr/getEDRWihtConsultationNote`;
export const addConsultationNote = `${baseUrl}/edr/addConsultationNote`;
export const completeConsultationNote = `${baseUrl}/edr/completeConsultationNote`;

// Customer Care
export const getAllCustomerCares = `${baseUrl}/customerCare/getAllCustomerCares`;
export const searchCustomerCare = `${baseUrl}/customerCare/searchCustomerCare`;
export const assignCustomerCare = `${baseUrl}/customerCare/assignCustomerCare`;

// Customer Care Dashboard
export const customerCareDashboard = `${baseUrl}/reports/ccDashboard`;

// DCD Form
export const getSingleEdr = `${baseUrl}/edr/getSingleEdr`;
export const updateLifeLimbEdr = `${baseUrl}/edr/updateLifeLimb`;
export const getEdrsByPatient = `${baseUrl}/edr/getEdrsByPatient`;
export const addTriageAssesment = `${baseUrl}/dcdForm/addTriageAssessment`;
export const addPatientDetails = `${baseUrl}/dcdForm/addPatientDetails`;
export const addPastHistory = `${baseUrl}/dcdForm/addPastHistory`;
export const addRos = `${baseUrl}/dcdForm/addRos`;
export const addPhysicalExam = `${baseUrl}/dcdForm/addPhysicalExam`;
export const addInvestigation = `${baseUrl}/dcdForm/addInvestigation`;
export const addActionPlan = `${baseUrl}/dcdForm/addActionPlan`;
export const addCourseOfVisit = `${baseUrl}/dcdForm/addCourseOfVisit`;

// flag management
export const getAllCompletedFlag = `${baseUrl}/flag/getAllCompletedFlag`;
export const getAllPendingFlag = `${baseUrl}/flag/getAllPendingFlag`;
export const updateFlag = `${baseUrl}/flag/updateFlag`;
export const getFlagCount = `${baseUrl}/flag/getFlagCount`;
export const getFlagPatientByKeyword = `${baseUrl}/flag/getFlagPatientByKeyword`;

// communication management
export const addCommunicationRequest = `${baseUrl}/communication/addCommunicationRequest`;
export const getAllCommunicationRequests = `${baseUrl}/communication/getAllCommunicationRequests`;

// ED Rooms management Routes
export const getCCDataForED = `${baseUrl}/sensei/patientsByCC`;

// patientTransferED/EOU
export const getAllPatientsInDept = `${baseUrl}/patientTransfer/getPatientsInDept`;
export const assingCustomerCareAndInitiateTransfer = `${baseUrl}/patientTransfer/assigncustomercare`;
export const getAllTransferRequestsForRequester = `${baseUrl}/patientTransfer/getAllTransferRequestsForRequester`;

// export const getAllPendingFlag = `${baseUrl}/flag/getAllPendingFlag`;
// export const getFlagCount = `${baseUrl}/flag/getFlagCount`;
// export const getFlagPatientByKeyword = `${baseUrl}/flag/getFlagPatientByKeyword`;

//items
export const getSearchedItemUrl = `${baseUrl}/item/getsearcheditems`;
export const getSearchedPharmaceuticalItemsUrl = `${baseUrl}/item/getsearcheditemsp`;
export const getSearchedItemsNonPharmaceuticalUrl = `${baseUrl}/item/getsearcheditemsnp`;
export const getSearchedNonPharmaceuticalItemsUrl = `${baseUrl}/item/getsearcheditemsnm`;

//EDR
export const updateEdr = `${baseUrl}/edr/updateEdr`;
export const confirmDischargeStatus = `${baseUrl}/edr/confirmDischargeStatus`;
export const updatePainScaleInterventions = `${baseUrl}/edr/updatePainScaleInterventions`;
export const getEDRFromPatientForDischarge = `${baseUrl}/edr/getEDRFromPatientForDischarge`;
export const getEDRFromPatientIdForDischarge = `${baseUrl}/edr/getEDRFromPatientIdForDischarge`;
export const getDischargedEDRFromPatient = `${baseUrl}/edr/getDischargedEDRFromPatient`;
export const getedripr = `${baseUrl}/edr/getedripr`;
export const getDischargedEDR = `${baseUrl}/edr/getDischargedEDR`;
export const getCompletedEDR = `${baseUrl}/edr/getCompletedEDR`;
export const edrFromParamedicsURL = `${baseUrl}/paramedics/edrFromParamedics`;
export const searchParamedicsEdrURL = `${baseUrl}/paramedics/searchPMEdr`;
export const submitDCDformURL = `${baseUrl}/edr/updatedDcdFormStatus`;
export const searchDischargedEDR = `${baseUrl}/edr/searchDischargedEDR`;
export const AssignEdrToPatient = `${baseUrl}/edr/assignEDRToPatient`;

//patient clearance
export const getPatientClearanceURL = `${baseUrl}/patientclearance/getpatientclearance`;
export const getClearedPatientsURL = `${baseUrl}/patientclearance/getclearedpatients`;
export const searchClearedPatientsURL = `${baseUrl}/patientclearance/searchClearedPatients`;
export const addPatientClearanceURL = `${baseUrl}/patientclearance/addpatientclearance`;
export const getPatientClearanceByIdURL = `${baseUrl}/patientclearance/getpatientclearance/:id`;
export const updatePatientClearanceURL = `${baseUrl}/patientclearance/updatepatientclearance`;
export const getSearchDischargedPatient = `${baseUrl}/reimbursementclaim/getpatientdischarge`;

// Porter
export const getPorterCompletedLabReq = `${baseUrl}/labRequest/getPorterCompletedLabEdr`;
export const getPorterPendingLabReq   = `${baseUrl}/labRequest/getPorterPendingLabEdr`;
export const updatePorterStatusRequest = `${baseUrl}/labRequest/updatePorterStatusRequest`;
export const getPorterPendingPharmaReq   = `${baseUrl}/pharmaRequest/getPorterPendingPharmaReq`;
export const getPorterCompletedPharmaReq = `${baseUrl}/pharmaRequest/getPorterCompletedPharmaReq`;
export const updatePorterPharmaDeliveryDetail = `${baseUrl}/pharmaRequest/updatePorterPharmaDeliveryDetail`;
export const updateReceiptNonPharmaDetail = `${baseUrl}/pharmaRequest/updateReceiptNonPharmaDetail`;
export const updateReceiptDoctorDetail = `${baseUrl}/pharmaRequest/updateReceiptDoctorDetail`;

export const getPendingRRRSample = `${baseUrl}/radRequest/getRRRPendingEdr`;
export const getCompletedRRRSample = `${baseUrl}/radRequest/getRRRCompletedEdr`;
export const getSingleRRRSample = `${baseUrl}/radRequest/getRRRSingleEdr`;
export const updateSingleRRRSample = `${baseUrl}/radRequest/updateRRRSingleEdr`;

export const getPendingRRRP2Sample = `${baseUrl}/radRequest/getRRRP2PendingEdr`;
export const getCompletedRRRP2Sample = `${baseUrl}/radRequest/getRRRP2CompletedEdr`;
export const getSingleRRRP2Sample = `${baseUrl}/radRequest/getRRRP2SingleEdr`;
export const updateSingleRRRP2Sample = `${baseUrl}/radRequest/updateRRRP2SingleEdr`;
export const updateRRRP2ConsentFile = `${baseUrl}/radRequest/updateRRRP2ConsentFile`;
export const updateSingleRRRP2Status = `${baseUrl}/radRequest/updateRRRP2Status`;

//Lab Technician
export const getCompletedLabReq = `${baseUrl}/labRequest/getCompletedLabEdr`;
export const getPendingLabReq = `${baseUrl}/labRequest/getPendingLabEdr`;
export const updateLabTechRequest = `${baseUrl}/labRequest/updateLabRequest`;
export const porterReceiptNotificationLabTechnician = `${baseUrl}/labRequest/porterReceiptNotificationLabTechnician`;
export const completeLabTechnicianServiceRequest = `${baseUrl}/labRequest/completeLabTechnicianServiceRequest`;
export const updateLabSampleInsuranceRequest = `${baseUrl}/labRequest/updateLabSampleInsuranceRequest`;
export const getLabTechnician = `${baseUrl}/staff/getLabTechnician`;
export const getRadTechnician = `${baseUrl}/staff/getRadTechnician`;
// export const getPatientClearanceURL = `${baseUrl}/patientclearance/getpatientclearance`;
// export const addPatientClearanceURL = `${baseUrl}/patientclearance/addpatientclearance`;
// export const getPatientClearanceByIdURL = `${baseUrl}/patientclearance/getpatientclearance/:id`;
// export const updatePatientClearanceURL = `${baseUrl}/patientclearance/updatepatientclearance`;
// export const getSearchDischargedPatient = `${baseUrl}/reimbursementclaim/getpatientdischarge`;
export const getLabRequest = `${baseUrl}/labRequest/getLabEdr`;
export const searchCompletedLabEdr = `${baseUrl}/labRequest/searchCompletedLabEdr`;

// Radiology Reports Routes for Technician and Doctor
export const getRadEdrRequest = `${baseUrl}/radRequest/getRadEdr`;
export const getPendingRadRequests = `${baseUrl}/radRequest/getPendingRadEdr`;
export const getCompletedRadRequests = `${baseUrl}/radRequest/getCompletedRadEdr`;
export const updateRadiologyRequest = `${baseUrl}/radRequest/updateRadRequest`;
export const getAllHouseKeepers = `${baseUrl}/staff/getAllHouseKeepers`;
export const assignHouseKeeper = `${baseUrl}/radRequest/assignHouseKeeper`;
export const getPendingRadRequestsForED = `${baseUrl}/radRequest/getPendingRadEdrForED`;
export const getCompletedRadRequestsForED = `${baseUrl}/radRequest/getCompletedRadEdrForED`;
export const searchCompletedRadEdr = `${baseUrl}/radRequest/searchCompletedRadEdr`;
export const updateRadSampleInsuranceRequest = `${baseUrl}/radRequest/updateRadSampleInsuranceRequest`;

//anesthesia requests
export const getAllPendingAnesthesiaRequestURL = `${baseUrl}/anesthesiarequest/getAllPendingAnesthesiaRequests`;
export const getAllCompletedAnesthesiaRequestURL = `${baseUrl}/anesthesiarequest/getAllCompletedAnesthesiaRequests`;
export const completeAnesthesiaRequestUrl = `${baseUrl}/anesthesiarequest/completeAnesthesiaRequest`;

//consultation notes
export const getAllPendingConsultationNotesURL = `${baseUrl}/consultationNotes/getAllPendingConsultationNotes`;
export const getAllCompletedConsultationNotesURL = `${baseUrl}/consultationNotes/getAllCompletedConsultationNotes`;
export const completeConsultationNotesURL = `${baseUrl}/consultationNotes/completeConsultationNotes`;
export const getAllConsultationNotesForDoctor = `${baseUrl}/consultationNotes/getAllConsultationNotesForDoctor`;
export const getSingleConsultationNotesForDoctor = `${baseUrl}/consultationNotes/getSingleConsultationNotesForDoctor`;
export const updateConsultaionNotesForDoctorRequest = `${baseUrl}/consultationNotes/updateConsultaionNotesForDoctorRequest`;
export const updateConsultationNoteForDoctorRequest = `${baseUrl}/consultationNotes/updateConsultationNoteForDoctorRequest`;
// export const updatePatientClearanceURL = `${baseUrl}/anesthesiarequest/updatepatientclearance`;

//reconciliation notes
// export const getAllPendingConsultationNotesURL = `${baseUrl}/consultationNotes/getAllPendingConsultationNotes`;
// export const getAllCompletedConsultationNotesURL = `${baseUrl}/consultationNotes/getAllCompletedConsultationNotes`;
export const completeReconciliationNotesURL = `${baseUrl}/reconciliationNotes/completeReconciliationNotes`;
export const addReconciliationNotesURL = `${baseUrl}/reconciliationNotes/addReconciliationNotes`;
export const updateReconciliationNotesURL = `${baseUrl}/reconciliationNotes/updateReconciliationNotes`;
//Nurse Technician
export const getNursePendingTransfers = `${baseUrl}/nurseTechnician/getPendingTransfers`;
export const getNurseCompletedTransfers = `${baseUrl}/nurseTechnician/getCompletedTransfers`;
export const addNurseReporturl = `${baseUrl}/nurseTechnician/addReport`;
export const getNursePendingLabSample = `${baseUrl}/nurseTechnician/getPendingLabs`;
export const getNurseCompletedLabSample = `${baseUrl}/nurseTechnician/getCompletedLabs`;
export const updateNurseLabSample = `${baseUrl}/nurseTechnician/completeLab`;
export const nurseTechnicianQRView = `${baseUrl}/nurseTechnician/qrview`;
export const updateNurseLabSampleRequest = `${baseUrl}/nurseTechnician/updateLabRequest`;


//Customer Care Services
export const getCCTransferRequest	= `${baseUrl}/customerCare/TransferRequest`;
export const getCCTransferTaskInfo  = `${baseUrl}/customerCare/TransferRequestInfo`;
export const updateCCTransferTaskInfo  = `${baseUrl}/customerCare/UpdateTransferRequestInfo`;

//Bed transfer
export const getPendingBedTransfer = `${baseUrl}/customerCare/pendingBedTransfer`;
export const getCompletedBedTransfer = `${baseUrl}/customerCare/completedBedTransfer`;
export const getisUpdateCompletedBedTransfer = `${baseUrl}/customerCare/completeBedTransfer`;

//ED to EOU transfer
export const getPendingEouTransfer = `${baseUrl}/customerCare/pendingEouTransfer`;
export const getCompletedEouTransfer = `${baseUrl}/customerCare/completedEouTransfer`;
export const getisUpdateCompletedEouTransfer = `${baseUrl}/customerCare/completeEOUTransfer`;
export const getisUpdateCompletedEDTransfer = `${baseUrl}/customerCare/completeEDTransfer`;
export const pendingEdTransfer = `${baseUrl}/customerCare/pendingEdTransfer`;
export const completedEdTransfer = `${baseUrl}/customerCare/completedEdTransfer`;

//discharge/disposition
export const getPendingDischargeEdrs = `${baseUrl}/customerCare/pendingDischargeEdrs`;
export const getCompletedDischargeEdrs = `${baseUrl}/customerCare/completedDischargeEdrs`;
export const getisUpdateCompleteDischarge = `${baseUrl}/customerCare/completeDischarge`;
//Customer Care Surveys
export const getPendingSurveyEdrs = `${baseUrl}/customerCare/getPendingSurveyEdrs`;
export const getCompletedSurveyEdrs = `${baseUrl}/customerCare/getCompletedSurveyEdrs`;

//Medication
export const getPendingMedications = `${baseUrl}/customerCare/pendingMedications`;
export const getCompletedMedications = `${baseUrl}/customerCare/completedMedications`;
export const getUpdateMedicationStatus = `${baseUrl}/customerCare/updateMedicationStatus`;
//Ambulance
export const getPendingAmbulance = `${baseUrl}/customerCare/pendingAmbulanceRequest`;
export const getCompletedAmbulance = `${baseUrl}/customerCare/completedAmbulanceRequest`;
export const getUpdateAmbulanceRequest = `${baseUrl}/customerCare/updateAmbulanceRequest`;
//localhost:4000/api/customerCare/updateAmbulanceRequest
//localhost:4000/api/customerCare/completedAmbulanceRequest/600ed23b9ef2093e58ad1110
//localhost:4000/api/customerCare/pendingAmbulanceRequest/600ed23b9ef2093e58ad1110

//localhost:4000/api/customerCare/pendingDischargeEdrs/5fe2fae71829542da4da0692
//export const getPendingEouTransfer = `${baseUrl}/customerCare/pendingEouTransfer`;
//export const getCompletedEouTransfer = `${baseUrl}/customerCare/completedEouTransfer`;
//export const getisUpdateCompletedEouTransfer = `${baseUrl}/customerCare/completeTransfer`;

// ED nurse Calls
export const getRadiologyRequestsURL = `${baseUrl}/edNurse/getRad`;
export const getLabRequestsURL = `${baseUrl}/edNurse/getLab`;
export const searchAssistancePatients = `${baseUrl}/edr/getNurseEdrByKeyword`;
export const getNurseTechniciansURL = `${baseUrl}/staff/getNurseTechnicians`;
export const getCustomerCaresURL = `${baseUrl}/staff/getCustomerCares`;
export const searchCustomerCareURL = `${baseUrl}/staff/searchCustomerCare`;
export const workDoneByCCURL = `${baseUrl}/customerCare/workDoneByCC`;
export const searchWorkDoneByCCURL = `${baseUrl}/customerCare/searchWorkDoneByCC`;
export const getEdNurseDashboardData = `${baseUrl}/edNurse/getDashboardData`;
export const submitAssistanceRequest = `${baseUrl}/edNurse/submitRequest`;
export const getHKRequests = `${baseUrl}/edNurse/getHKRequests`;
export const getHKRequestsInEDNurse = `${baseUrl}/edNurse/getHKRequestsById`;
export const getNTRequestsinED = `${baseUrl}/edNurse/getNTRequestsById`;
export const getNTRequests = `${baseUrl}/edNurse/getNTRequests`;

export const getCustomerCareRequests = `${baseUrl}/edNurse/getCCRequests`;
export const getCustomerCareRequestsById = `${baseUrl}/edNurse/getCCRequestsById`;

export const getPendingTasksForEDurl = `${baseUrl}/edNurse/pendingEDNurseEdrRequest`;
export const getCompletedTasksForEDurl = `${baseUrl}/edNurse/completedEDNurseEdrRequest`;
export const updateEDNurseTask = `${baseUrl}/edNurse/completeRequest`;
export const getPharmacyRequestURL = `${baseUrl}/edNurse/getPharmacy`;
export const updatePharmaStatus = `${baseUrl}/edNurse/updateMedicationStatus`;
export const searchGetPharmacy = `${baseUrl}/searchGetPharmacy`;
export const updateEDNursePharmaAdministeredDetail = `${baseUrl}/pharmaRequest/updateEDNursePharmaAdministeredDetail`;

// EOU Nurse calls
export const getPendingTasksForEOUurl = `${baseUrl}/eouNurse/pendingEOUNurseEdrRequest`;
export const getCompletedTasksForEOUurl = `${baseUrl}/eouNurse/completedEOUNurseEdrRequest`;
export const updateEOUNurseTask = `${baseUrl}/eouNurse/completeRequest`;
export const getEOUDashboardDataURL = `${baseUrl}/eouNurse/getDashboardData`;
// Shift API calls
export const addNewShiftURL = `${baseUrl}/shift/addShift`;
export const updateShiftURL = `${baseUrl}/shift/updateShift`;
export const getAllShiftsURL = `${baseUrl}/shift/getAllShifts`;

// External Consultants
export const getExternalConsultants = `${baseUrl}/staff/getExternal`;
export const getSearchedExternalConsultants = `${baseUrl}/staff/searchExternalConsultant`;
export const getExternalConsultatntsCases = `${baseUrl}/staff/externalCC`;
export const getSearchExternalConsultatntsCases = `${baseUrl}/staff/searchExternalCC`;

// Admin dashboard API calls
export const getAdminDashboardData = `${baseUrl}/adminDashboard/dashboard`;
export const getMostSelectedCCURL = `${baseUrl}/adminDashboard/chiefComplaints`;
export const getMostSelectedCSURL = `${baseUrl}/adminDashboard/careStreams`;
export const getMostRequestedlabTestsURL = `${baseUrl}/adminDashboard/labTests`;
export const getMostRequestedRadTestsURL = `${baseUrl}/adminDashboard/radiologyExams`;
export const getMostRequestedMedicationURL = `${baseUrl}/adminDashboard/medication`;
export const getNursesAssignedInShifts = `${baseUrl}/adminDashboard/nursesAssigned`;
export const getDoctorsAssignedInShifts = `${baseUrl}/adminDashboard/doctorsAssigned`;

//House keeping dashboard
export const reportsHKDashboardURL = `${baseUrl}/reports/hkDashboard`;
export const reportsHKGraphsDashboardURL = `${baseUrl}/reports/hkRoomPending`;

//Anesthialogist dashboard
export const reportsAnesthesiologistDBURL = `${baseUrl}/reports/anesthesiologistDB`;
//External Dashboard
export const reportsExternalConsultantDBURL = `${baseUrl}/reports/externalConsultantDB`;
//Internal Dashboard
export const reportsInternalConsultantDBURL = `${baseUrl}/reports/internalConsultantDB`;

// export const getAllEDRByKeyword = `${baseUrl}/edr/getAllEDRByKeyword`;
//Clinical Pharmacist
export const reportsCPDashboard = `${baseUrl}/reports/cpDashboard`;
//Rad Doctor
export const reportsRDdDashboard = `${baseUrl}/reports/rdDashboard`;
//Lab Technician
export const reportsLTDashboard = `${baseUrl}/reports/ltDashboard`;
export const getAllEDRByKeyword = `${baseUrl}/edr/getAllEDRByKeyword`;

// Imaging Technicain
export const imagingTechncian = `${baseUrl}/reports/itDashboard`;

// Nurse technician dashboard
export const nurseTechnician = `${baseUrl}/reports/ntDashboard`;

// Code Blue Team
export const postCodeBlueTeam = `${baseUrl}/codeBlue/addCodeBlueTeam`;
export const getCodeBlueTeam = `${baseUrl}/codeBlue/getCodeBlueTeam`;
export const updateCodeBlueTeam = `${baseUrl}/codeBlue/updateCodeBlueTeam`;
export const assignCodeBlueTeam = `${baseUrl}/codeBlue/assignCodeBlueTeam`;
