import { Typography } from "@material-ui/core";
import React from "react";
import CareStream from "../../../assets/img/carestream/CareStream.png";
import Header from "../../../components/Header/Header";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import useMediaQuery from "@material-ui/core/useMediaQuery";

export default function Assign(props) {
  const matches = useMediaQuery("(min-width:600px)");
  return (
    <div
      style={{
        backgroundColor: "rgb(19 213 159)",
        position: "fixed",
        display: "flex",
        width: "100%",
        height: "100%",
        flexDirection: "column",
        flex: 1,
        overflowY: "scroll",
      }}
    >
      <Header history={props.history} />
      <div
        className="cPadding"
        style={{
          // marginLeft: "-16px",
          display: "flex",
        }}
      >
        <img
          style={{ width: matches ? " " : 70, height: matches ? " " : 70 }}
          src={CareStream}
        />
        <div style={{ flex: 4, display: "flex", alignItems: "center" }}>
          <h4 style={{ color: "white", fontWeight: "700" }}>Medication List</h4>
        </div>
      </div>
      <div
        style={{
          backgroundColor: "white",
          marginLeft: 20,
          marginRight: 20,
          borderRadius: 5,
        }}
      >
        <List>
          {props.history.location.state.medications.length === 0 ? (
            <h3 style={{ textAlign: "center" }}>No data found</h3>
          ) : (
            <div>
              <Typography
                variant="h5"
                component="h5"
                style={{ marginLeft: 10 }}
              >
                Medication for {props.history.location.state.record.name}
              </Typography>
              {props.history.location.state.medications.map((medication) => {
                return (
                  <div style={{ marginLeft: 10, marginRight: 10 }}>
                    <ListItem>
                      <ListItemText primary={medication} />
                    </ListItem>
                    <Divider />
                  </div>
                );
              })}
            </div>
          )}
        </List>
      </div>
    </div>
  );
}
