/*eslint-disable*/
import React, { useState, useEffect, useReducer } from "react";
import Button from "@material-ui/core/Button";
import Notification from "../../components/Snackbar/Notification.js";
import CustomTable from "../../components/Table/Table";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Loader from "react-loader-spinner";
import TextField from "@material-ui/core/TextField";
import Header from "../../components/Header/Header";
import CurrentActivityIcon from "../../assets/img/ED RoomsCurrent Activity.png";
import Back from "../../assets/img/Back_Arrow.png";
import "../../assets/jss/material-dashboard-react/components/loaderStyle.css";
import cookie from "react-cookies";
import useStylesForInput from "../../assets/jss/material-dashboard-react/inputStyle"
import { styles, useStylesForTabs } from "./styles.js"
import {
    tableHeadingForEOUTransferReq,
    tableDataKeysForEOUTransferReq,
    tableHeadingForCS,
    tableDataKeysForCS,
    tableHeadingForAvailableBeds,
    tableDataKeysForAvailableBeds,
    tableHeadingForOccupiedBeds,
    tableDataKeysForOccupiedBeds,
    tableHeadingForFlagsRaised,
    tableDataKeysForFlagsRaised,
    tableHeadingForDoctorResponse,
    tableDataKeysForDoctorResponse,
    tableHeadingForEDnurse,
    tableDataKeysForEDnurse
} from "./currentActivityTables"
import {
    getCurrentActivityTableData
} from "./networkCallForEOUMgmt"

const actionsForView = { view: true };

export default function CurrentActivity(props) {
    const classesForTabs = useStylesForTabs();
    const classes = useStylesForInput();

    const [currentUser] = useState(cookie.load("current_user"));
    const [errorMsg, setErrorMsg] = useState("");
    const [openNotification, setOpenNotification] = useState(false);
    const [openFilterModal, setOpenFilterModal] = useState(false);
    const [tabName, setTabName] = useState("EOU Transfer Requests");
    const [tableData, setTableData] = useState([]);
    const [isLoading, setisLoading] = useState(true);

    if (openNotification) {
        setTimeout(() => {
            setOpenNotification(false);
            setErrorMsg("");
        }, 2000);
    }

    useEffect(() => {
        setTableData([])
        setisLoading(true)
        getCurrentActivityTableData(
            tabName,
            setTableData,
            setisLoading,
            setOpenNotification,
            setErrorMsg
        );
    }, [tabName]);

    const handleChange = (event, tabValue) => {
        setTabName(tabValue);
    };

    const handleFilteredTimeInterval = () => {
    }

    const handleViewMedRec = (e) => {
        let path = '/dashboard/home/pharmanotes/viewNotes'
        props.history.push({
            pathname: path,
            state: {
                selectedRecord: e.pharmacyRequest
            }
        })
    }

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                flex: 1,
                position: "fixed",
                width: "100%",
                height: "100%",
                backgroundColor: "#60d69f",
                overflowY: "scroll",
            }}
        >
            <Header history={props.history} />

            <div className="cPadding">
                <div className="subheader" style={{ paddingRight: '15px' }}>
                    <div>
                        <img src={CurrentActivityIcon} />
                        <h4>EOU Current Activities</h4>
                    </div>
                </div>

                <div style={{ height: '20px' }} />

                <div className={classesForTabs.root}>
                    <Tabs
                        classes={{
                            root: classesForTabs.root,
                            scroller: classesForTabs.scroller,
                        }}
                        value={tabName}
                        onChange={handleChange}
                        textColor="primary"
                        variant="scrollable"
                        TabIndicatorProps={{ style: { background: "#12387a" } }}
                    >
                        <Tab
                            style={{
                                color: "white",
                                borderRadius: 5,
                                outline: "none",
                                color: tabName === "EOU Transfer Requests" ? "#12387a" : "#3B988C",
                            }}
                            label="EOU Transfer Requests"
                            value="EOU Transfer Requests"
                        />
                        <Tab
                            style={{
                                color: "white",
                                borderRadius: 5,
                                outline: "none",
                                color: tabName === "CareStreams" ? "#12387a" : "#3B988C",
                            }}
                            label="CareStreams"
                            value="CareStreams"
                        />
                        <Tab
                            style={{
                                color: "white",
                                borderRadius: 5,
                                outline: "none",
                                color: tabName === "Available Beds" ? "#12387a" : "#3B988C",
                            }}
                            label="Available Beds"
                            value="Available Beds"
                        />

                        <Tab
                            style={{
                                color: "white",
                                borderRadius: 5,
                                outline: "none",
                                color:
                                    tabName === "Occupied Beds" ? "#12387a" : "#3B988C",
                            }}
                            label="Occupied Beds"
                            value="Occupied Beds"
                        />
                        <Tab
                            style={{
                                color: "white",
                                borderRadius: 5,
                                outline: "none",
                                color:
                                    tabName === "Flags Raised"
                                        ? "#12387a"
                                        : "#3B988C",
                            }}
                            label="Flags Raised"
                            value="Flags Raised"
                        />
                        <Tab
                            style={{
                                color: "white",
                                borderRadius: 5,
                                outline: "none",
                                color: tabName === "Doctor Response Time" ? "#12387a" : "#3B988C",
                            }}
                            label="Doctor Response Time"
                            value="Doctor Response Time"
                        />
                        <Tab
                            style={{
                                color: "white",
                                borderRadius: 5,
                                outline: "none",
                                color: tabName === "ED Nurse" ? "#12387a" : "#3B988C",
                            }}
                            label="ED Nurse"
                            value="ED Nurse"
                        />
                    </Tabs>
                </div>

                <div className='container-fluid'>

                    {tabName === "EOU Transfer Requests" ? (
                        <div className="row">
                            {tableData.length > 0 && !isLoading ? (
                                <div className='col-md-12 col-sm-12 col-12'>
                                    <CustomTable
                                        tableData={tableData}
                                        tableDataKeys={tableDataKeysForEOUTransferReq}
                                        tableHeading={tableHeadingForEOUTransferReq}
                                        borderBottomColor={"#60d69f"}
                                        borderBottomWidth={20}
                                    />
                                </div>
                            ) : isLoading ? (
                                <div style={{ margin: "auto", width: "0%", padding: 10 }}>
                                    <Loader
                                        type="TailSpin"
                                        color="blue"
                                        height={50}
                                        width={50}
                                    />
                                </div>
                            ) : (
                                        <div className='col-md-12 col-sm-12 col-12'>
                                            <h3
                                                style={{
                                                    color: "white",
                                                    textAlign: "center",
                                                    margin: 'revert'
                                                }}
                                            >
                                                Opps! No Data Found
                                            </h3>
                                        </div>
                                    )}
                        </div>
                    ) : tabName === "CareStreams" ? (
                        <div className="row">
                            <div className='col-md-12 col-sm-12 col-12'>
                                <h3
                                    style={{
                                        color: "white",
                                        textAlign: "center",
                                        margin: 'revert'
                                    }}
                                >
                                    CareStream under development !!
                                </h3>
                            </div>
                            {/* {tableData.length > 0 && !isLoading ? (
                                <div className='col-md-12 col-sm-12 col-12'>
                                    <CustomTable
                                        tableData={tableData}
                                        tableDataKeys={tableDataKeysForCS}
                                        tableHeading={tableHeadingForCS}
                                        borderBottomColor={"#60d69f"}
                                        borderBottomWidth={20}
                                    />
                                </div>
                            ) : isLoading ? (
                                <div style={{ margin: "auto", width: "0%", padding: 10 }}>
                                    <Loader
                                        type="TailSpin"
                                        color="blue"
                                        height={50}
                                        width={50}
                                    />
                                </div>
                            ) : (
                                        <div className='col-md-12 col-sm-12 col-12'>
                                            <h3
                                                style={{
                                                    color: "white",
                                                    textAlign: "center",
                                                    margin: 'revert'
                                                }}
                                            >
                                                Opps! No Data Found
                                                </h3>
                                        </div>
                                    )} */}
                        </div>
                    ) : tabName === "Occupied Beds" ? (
                        <div className="row">
                            <div className='col-md-12 col-sm-12 col-12'>
                                <h3
                                    style={{
                                        color: "white",
                                        textAlign: "center",
                                        margin: 'revert'
                                    }}
                                >
                                    Occupied Beds under development !!
                                </h3>
                            </div>
                            {/* {tableData.length > 0 && !isLoading ? (
                                <div className='col-md-12 col-sm-12 col-12'>
                                    <CustomTable
                                        tableData={tableData}
                                        tableDataKeys={tableDataKeysForOccupiedBeds}
                                        tableHeading={tableHeadingForOccupiedBeds}
                                        borderBottomColor={"#60d69f"}
                                        borderBottomWidth={20}
                                    />
                                </div>
                            ) : isLoading ? (
                                <div style={{ margin: "auto", width: "0%", padding: 10 }}>
                                    <Loader
                                        type="TailSpin"
                                        color="blue"
                                        height={50}
                                        width={50}
                                    />
                                </div>
                            ) : (
                                        <div className='col-md-12 col-sm-12 col-12'>
                                            <h3
                                                style={{
                                                    color: "white",
                                                    textAlign: "center",
                                                    margin: 'revert'
                                                }}
                                            >
                                                Opps! No Data Found
                                                </h3>
                                        </div>
                                    )} */}
                        </div>
                    ) : tabName === "Available Beds" ? (
                        <div className="row">
                            <div className='col-md-12 col-sm-12 col-12'>
                                <h3
                                    style={{
                                        color: "white",
                                        textAlign: "center",
                                        margin: 'revert'
                                    }}
                                >
                                    Available Beds under development !!
                                </h3>
                            </div>
                            {/* {tableData.length > 0 && !isLoading ? (
                                <div className='col-md-12 col-sm-12 col-12'>
                                    <CustomTable
                                        tableData={tableData}
                                        tableDataKeys={tableDataKeysForAvailableBeds}
                                        tableHeading={tableHeadingForAvailableBeds}
                                        borderBottomColor={"#60d69f"}
                                        borderBottomWidth={20}
                                    />
                                </div>
                            ) : isLoading ? (
                                <div style={{ margin: "auto", width: "0%", padding: 10 }}>
                                    <Loader
                                        type="TailSpin"
                                        color="blue"
                                        height={50}
                                        width={50}
                                    />
                                </div>
                            ) : (
                                        <div className='col-md-12 col-sm-12 col-12'>
                                            <h3
                                                style={{
                                                    color: "white",
                                                    textAlign: "center",
                                                    margin: 'revert'
                                                }}
                                            >
                                                Opps! No Data Found
                                                </h3>
                                        </div>
                                    )} */}
                        </div>
                    ) : tabName === "Flags Raised" ? (
                        <div className="row">
                            <div className='col-md-12 col-sm-12 col-12'>
                                <h3
                                    style={{
                                        color: "white",
                                        textAlign: "center",
                                        margin: 'revert'
                                    }}
                                >
                                    Flag Management under development !!
                                </h3>
                            </div>
                            {/* {tableData.length > 0 && !isLoading ? (
                                <div className='col-md-12 col-sm-12 col-12'>
                                    <CustomTable
                                        tableData={tableData}
                                        tableDataKeys={tableDataKeysForFlagsRaised}
                                        tableHeading={tableHeadingForFlagsRaised}
                                        action={actionsForView}
                                        handleView={handleViewMedRec}
                                        borderBottomColor={"#60d69f"}
                                        borderBottomWidth={20}
                                    />
                                </div>
                            ) : isLoading ? (
                                <div style={{ margin: "auto", width: "0%", padding: 10 }}>
                                    <Loader
                                        type="TailSpin"
                                        color="blue"
                                        height={50}
                                        width={50}
                                    />
                                </div>
                            ) : (
                                        <div className='col-md-12 col-sm-12 col-12'>
                                            <h3
                                                style={{
                                                    color: "white",
                                                    textAlign: "center",
                                                    margin: 'revert'
                                                }}
                                            >
                                                Opps! No Data Found
                                                </h3>
                                        </div>
                                    )} */}
                        </div>
                    ) : tabName === "Doctor Response Time" ? (
                        <div className="row">
                            {tableData.length > 0 && !isLoading ? (
                                <div className='col-md-12 col-sm-12 col-12'>
                                    <CustomTable
                                        tableData={tableData}
                                        tableDataKeys={tableDataKeysForDoctorResponse}
                                        tableHeading={tableHeadingForDoctorResponse}
                                        borderBottomColor={"#60d69f"}
                                        borderBottomWidth={20}
                                    />
                                </div>
                            ) : isLoading ? (
                                <div style={{ margin: "auto", width: "0%", padding: 10 }}>
                                    <Loader
                                        type="TailSpin"
                                        color="blue"
                                        height={50}
                                        width={50}
                                    />
                                </div>
                            ) : (
                                        <div className='col-md-12 col-sm-12 col-12'>
                                            <h3
                                                style={{
                                                    color: "white",
                                                    textAlign: "center",
                                                    margin: 'revert'
                                                }}
                                            >
                                                Opps! No Data Found
                                                </h3>
                                        </div>
                                    )}
                        </div>
                    ) : tabName === "ED Nurse" ? (
                        <div className="row">   
                            <div className='col-md-12 col-sm-12 col-12'>
                                <h3
                                    style={{
                                        color: "white",
                                        textAlign: "center",
                                        margin: 'revert'
                                    }}
                                >
                                    ED Nurse under development !!
                                </h3>
                            </div>
                            {/* {tableData.length > 0 && !isLoading ? (
                                <div className='col-md-12 col-sm-12 col-12'>
                                    <CustomTable
                                        tableData={tableData}
                                        tableDataKeys={tableDataKeysForEDnurse}
                                        tableHeading={tableHeadingForEDnurse}
                                        borderBottomColor={"#60d69f"}
                                        borderBottomWidth={20}
                                    />
                                </div>
                            ) : isLoading ? (
                                <div style={{ margin: "auto", width: "0%", padding: 10 }}>
                                    <Loader
                                        type="TailSpin"
                                        color="blue"
                                        height={50}
                                        width={50}
                                    />
                                </div>
                            ) : (
                                        <div className='col-md-12 col-sm-12 col-12'>
                                            <h3
                                                style={{
                                                    color: "white",
                                                    textAlign: "center",
                                                    margin: 'revert'
                                                }}
                                            >
                                                Opps! No Data Found
                                                </h3>
                                        </div>
                                    )} */}
                        </div>
                    ) : (
                                                    <div className="LoaderStyle">
                                                        <Loader type="TailSpin" color="red" height={50} width={50} />
                                                    </div>
                                                )}

                    <div className="row" style={{ marginBottom: 20 }}>
                        <div className='col-md-12 col-sm-12 col-12'>
                            <img
                                onClick={() => props.history.goBack()}
                                src={Back}
                                style={{
                                    width: 45,
                                    height: 35,
                                    cursor: "pointer",
                                }}
                            />
                        </div>
                    </div>
                    <Notification msg={errorMsg} open={openNotification} />
                </div>
            </div>
        </div>
    );
}