import React, { useState, useEffect } from "react";
import { getClaim } from "../../../public/endpoins";
import Notification from "../../../components/Snackbar/Notification.js";
import CustomTable from "../../../components/Table/Table";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import Loader from "react-loader-spinner";
import Header from "../../../components/Header/Header";
import claimsReview from "../../../assets/img/ClaimsReview.png";
import Back_Arrow from "../../../assets/img/Back_Arrow.png";
import plus_icon from "../../../assets/img/Plus.png";
import Button from "@material-ui/core/Button";
import Fingerprint from "../../../assets/img/fingerprint.png";
import AccountCircle from "@material-ui/icons/SearchOutlined";
import InputAdornment from "@material-ui/core/InputAdornment";
import BarCode from "../../../assets/img/Bar Code.png";
import TextField from "@material-ui/core/TextField";
import "../../../assets/jss/material-dashboard-react/components/loaderStyle.css";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const tableHeading = [
  "Request No",
  "Insurer",
  "Response Code",
  "Status",
  "Action",
];
const tableDataKeys = ["requestNo", "insurer", "responseCode", "status"];
const actions = { edit: true };

const styles = {
  stylesForButton: {
    color: "white",
    cursor: "pointer",
    borderRadius: 5,
    background: "#2c6ddd",
    width: "165px",
    height: "50px",
    outline: "none",
  },
  textFieldPadding: {
    paddingLeft: 5,
    paddingRight: 5,
  },
};

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(0),
  },
  input: {
    backgroundColor: "white",
    boxShadow: "none",
    borderRadius: 3,
    "&:after": {
      borderBottomColor: "black",
      boxShadow: "none",
    },
    "&:hover": {
      backgroundColor: "white",
      boxShadow: "none",
    },
    "&:focus": {
      backgroundColor: "white",
      boxShadow: "none",
      borderRadius: 3,
    },
  },
  root: {
    "& .MuiTextField-root": {
      backgroundColor: "white",
    },
    "& .Mui-focused": {
      backgroundColor: "white",
      color: "black",
      boxShadow: "none",
    },
    "& .Mui-disabled": {
      backgroundColor: "white",
      color: "gray",
    },
    "&:focus": {
      backgroundColor: "white",
      boxShadow: "none",
    },
    "& .MuiFormLabel-root": {
      fontSize: "12px",
    },
  },
}));

export default function Reimbursement(props) {
  const classes = useStyles();
  const matches = useMediaQuery("(min-width:600px)");

  const [insurance, setinsurance] = useState([
    // {
    //   requestNo: '123',
    //   insurer: 'Loreum',
    //   responseCode: 'Loreum',
    //   status: 'pending'
    // }
  ]);
  // const [itemModalVisible, setitemModalVisible] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [openNotification, setOpenNotification] = useState(false);
  // const [item, setItem] = useState("");
  const [searchPatientQuery, setSearchPatientQuery] = useState("");

  useEffect(() => {
    getinsuranceData();
  }, []);

  function getinsuranceData() {
    axios
      .get(getClaim)
      .then((res) => {
        if (res.data.success) {
          res.data.data.map((d) => (d.insurer = "N/A"));
          setinsurance(res.data.data.reverse());
        } else if (!res.data.success) {
          setErrorMsg(res.data.error);
          setOpenNotification(true);
        }
        return res;
      })
      .catch((e) => {
        console.log("error: ", e);
      });
  }

  if (openNotification) {
    setTimeout(() => {
      setOpenNotification(false);
      setErrorMsg("");
    }, 2000);
  }

  const submitClaim = () => {
    let path = `submitClaim`;
    props.history.push({
      pathname: path,
      state: {
        comingFor: "add",
      },
    });
  };

  function handleEdit(rec) {
    let path = `submitClaim`;
    props.history.push({
      pathname: path,
      state: {
        comingFor: "edit",
        selectedItem: rec,
      },
    });
  }

  const handlePatientSearch = (e) => {
    const a = e.target.value.replace(/[^\w\s]/gi, "");
    setSearchPatientQuery(a);
    if (a.length >= 3) {
      axios
        .get(getClaim + "/" + a)
        .then((res) => {
          if (res.data.success) {
            if (res.data.data.length > 0) {
              console.log(res.data.data);
              res.data.data.map((d) => (d.insurer = "N/A"));
              setinsurance(res.data.data.reverse());
            } else {
              setinsurance([]);
            }
          }
        })
        .catch((e) => {
          console.log("error after searching patient request", e);
        });
    } else if (a.length === 0) {
      getinsuranceData();
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        position: "fixed",
        width: "100%",
        height: "100%",
        backgroundColor: "rgb(19 213 159)",
        overflowY: "scroll",
      }}
    >
      <Header history={props.history} />
      <div className="cPadding">
        <div className="subheader" style={{ paddingRight: "15px" }}>
          <div style={{ marginLeft: "-5px" }}>
            <img src={claimsReview} />
            <h4>Claims Review</h4>
          </div>

          <Button
            onClick={submitClaim}
            style={{
              ...styles.stylesForButton,
              width: matches ? 165 : 90,
              height: matches ? 50 : 30,
            }}
            variant="contained"
            color="primary"
          >
            <img src={plus_icon} className="icon-style" />
            &nbsp;&nbsp;
            <span style={{ fontSize: matches ? 14 : 8, flex: "none" }}>
              Submit Claim
            </span>
          </Button>
        </div>

        <div
          className={`container-fluid ${classes.root}`}
          style={{
            marginTop: "25px",
          }}
        >
          <div className="row">
            <div
              className="col-md-10 col-sm-9 col-8"
              style={{ paddingRight: matches ? 5 : 0, paddingLeft: "15px" }}
            >
              <TextField
                className="textInputStyle"
                id="searchPatientQuery"
                type="text"
                variant="filled"
                label="Search By Request No"
                name={"searchPatientQuery"}
                value={searchPatientQuery}
                onChange={handlePatientSearch}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <AccountCircle />
                    </InputAdornment>
                  ),
                  className: classes.input,
                  classes: { input: classes.input },
                  disableUnderline: true,
                }}
              />
            </div>
            <div
              className="col-md-1 col-sm-2 col-2"
              style={styles.textFieldPadding}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "white",
                  borderRadius: 3,
                  height: 55,
                }}
              >
                <img
                  src={BarCode}
                  style={{
                    width: matches ? 70 : 52,
                    height: matches ? 60 : 58,
                  }}
                />
              </div>
            </div>
            <div
              className="col-md-1 col-sm-1 col-2"
              style={{ paddingLeft: matches ? 5 : 0, paddingRight: "15px" }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "white",
                  borderRadius: 3,
                  height: 55,
                }}
              >
                <img src={Fingerprint} style={{ maxWidth: 43, height: 43 }} />
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid">
          {insurance && insurance.length > 0 ? (
            <div className="row">
              <div className="col-md-12 col-sm-12 col-12">
                <CustomTable
                  tableData={insurance}
                  tableDataKeys={tableDataKeys}
                  tableHeading={tableHeading}
                  action={actions}
                  borderBottomColor={"#60d69f"}
                  handleEdit={handleEdit}
                  borderBottomWidth={20}
                />
              </div>
            </div>
          ) : insurance && insurance.length === 0 ? (
            <div className="row " style={{ marginTop: "25px" }}>
              <div className="col-md-12 col-sm-12 col-12">
                <h3
                  style={{
                    color: "white",
                    textAlign: "center",
                  }}
                >
                  Opps...No Data Found
                </h3>
              </div>
            </div>
          ) : (
            <div className="row">
              <div className="LoaderStyle">
                <Loader type="TailSpin" color="red" height={50} width={50} />
              </div>
            </div>
          )}

          <div className="row">
            <div
              className="col-md-12 col-sm-12 col-12"
              style={{ marginBottom: 20, marginTop: 50 }}
            >
              <img
                onClick={() => props.history.goBack()}
                src={Back_Arrow}
                style={{ width: 45, height: 35, cursor: "pointer" }}
              />
            </div>
          </div>
          <Notification msg={errorMsg} open={openNotification} />
        </div>
      </div>
    </div>
  );
}
