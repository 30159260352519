/*eslint-disable*/
import React, { useState, useEffect } from "react"
import cookie from "react-cookies"
import Notification from "../../components/Snackbar/Notification.js"
import Header from "../../components/Header/Header"
import CommunicationManagementIcon from "../../assets/img/CommunicationManagement.png"
import Back from "../../assets/img/Back_Arrow.png"
import "../../assets/jss/material-dashboard-react/components/loaderStyle.css"
import CustomTable from "../../components/Table/Table"
import Dialog from "@material-ui/core/Dialog"
import Button from "@material-ui/core/Button"

import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"
import { styles, useStyles } from "./styles.js"
import { getAllCommunicationRequestsCall } from "./networkCallsForCommMgmt"

const tableHeading = ["Request ID", "Reason", "Date/Time", "Action"]
const tableDataKeys = ["requestId", "reason", "createdAt"]

const actions = { view: true }

export default function ViewCommunicationRequests(props) {
  const classes = useStyles()

  const [currentUser] = useState(cookie.load("current_user"))
  const [errorMsg, setErrorMsg] = useState("")
  const [openNotification, setOpenNotification] = useState(false)
  const [communicationReqData, setCommunicationReqData] = useState([])
  const [open, setOpen] = React.useState(false)
  const [selectedReason, setSelectedReason] = useState({})

  const handleClose = () => {
    setOpen(false)
  }

  if (openNotification) {
    setTimeout(() => {
      setOpenNotification(false)
      setErrorMsg("")
    }, 2000)
  }

  useEffect(() => {
    getAllCommunicationRequestsCall(
      currentUser,
      setCommunicationReqData,
      setOpenNotification,
      setErrorMsg,
    )
  }, [])

  function handleView(e) {
    setSelectedReason(e)
    setOpen(true)
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        position: "fixed",
        width: "100%",
        height: "100%",
        backgroundColor: "#60d69f",
        overflowY: "scroll",
      }}
    >
      <Header history={props.history} />

      <div className="cPadding">
        <div className="subheader">
          <div>
            <img src={CommunicationManagementIcon} />
            <h4>Communication Requests</h4>
          </div>
        </div>

        <div style={{ height: "20px" }} />

        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Reason to Communicate"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {selectedReason.reason
                ? selectedReason.reason
                : "No reason found"}
              <br></br>
              {selectedReason.others
                ? selectedReason.others
                : "No others found"}
            </DialogContentText>
          </DialogContent>
          <DialogActions style={{ justifyContent: "center" }}>
            <Button
              onClick={() => setOpen(false)}
              style={styles.stylesForDialogue}
              variant="contained"
              color="primary"
            >
              <strong>Close</strong>
            </Button>
          </DialogActions>
        </Dialog>

        <div className={`container-fluid`}>
          <div className="row" style={styles.textFieldPadding}>
            <div className="col-md-12 col-sm-12 col-12">
              <CustomTable
                tableData={communicationReqData}
                tableDataKeys={tableDataKeys}
                tableHeading={tableHeading}
                action={actions}
                handleView={handleView}
                borderBottomColor={"#60d69f"}
                borderBottomWidth={20}
              />
            </div>
          </div>

          <div className="row" style={{ marginTop: 30 }}>
            <div className="col-md-6 col-sm-6 col-6">
              <img
                onClick={() => props.history.goBack()}
                src={Back}
                style={{
                  width: 45,
                  height: 35,
                  cursor: "pointer",
                }}
              />
            </div>
          </div>
        </div>

        <Notification msg={errorMsg} open={openNotification} />
      </div>
    </div>
  )
}
