/*eslint-disable*/
import React, { useState, useEffect, useReducer } from "react";
import Button from "@material-ui/core/Button";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Notification from "../../components/Snackbar/Notification.js";
import Header from "../../components/Header/Header";
import Back from "../../assets/img/Back_Arrow.png";
import "../../assets/jss/material-dashboard-react/components/loaderStyle.css";
import cookie from "react-cookies";
import { styles, useStyles, useStylesForTabs } from "./styles.js";

export default function DischargeSubTasks(props) {
  const classesForTabs = useStylesForTabs();
  const classes = useStyles();
  const matches = useMediaQuery("(min-width:600px)");

  const [cookies, setCookies] = useState(cookie.load("current_user"));

  console.log("props", props);

  function handleStartServey() {
    props.history.push({
      pathname: "/dashboard/home/customercare/servey",
      state: {
        selectedRecord: props.location.state.selectedRecord,
      },
    });
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        position: "fixed",
        width: "100%",
        height: "100%",
        backgroundColor: "#60d69f",
        overflowY: "scroll",
      }}
    >
      <Header history={props.history} />

      <div className="cPadding">
        <div
          className="container-fluid"
          style={{ paddingLeft: 0, paddingRight: 0 }}
        >
          {/* <h4 style={{ textAlign: 'center' }}>
            Module is under development for now
          </h4> */}

          <div
            style={{
              flex: 4,
              display: "flex",
              flexDirection: "column",
              color: "white",
              borderRadius: 5,
              padding: 40,
            }}
          >
            <h1 style={{ fontWeight: "bold", textAlign: "center" }}>
              Dear Customer!
            </h1>
            <div>
              <h6 style={{ textAlign: "center" }}>
                Thank you for using Al Khalidi Hospital and Medical Center. We
                appreciate you choosing us as your healthcare provider. To help
                us improve, we would like to ask you a few questions about your
                experience with us. It will only take few minutes and your
                answers will help us improve our services.
              </h6>
            </div>
            <div style={{ marginTop: 40 }}>
              <h6>Thank you,</h6>
              <h6>KHMC Team</h6>
            </div>
            <div style={{ marginTop: 50 }}>
              <h6>In order to participate please click the button below</h6>
            </div>
          </div>

          <div
            className="row container-fluid"
            style={{
              display: "flex",
              flex: 1,
              justifyContent: "center",
              paddingLeft: 6,
              paddingRight: 6,
            }}
          >
            <div
              style={{
                display: "flex",
                flex: 1,
                justifyContent: "flex",
                marginTop: "2%",
                marginBottom: "2%",
              }}
            >
              <img
                onClick={() => props.history.goBack()}
                src={Back}
                style={{
                  width: 45,
                  height: 35,
                  cursor: "pointer",
                  marginLeft: 25,
                }}
              />
            </div>

            <div
              style={{
                display: "flex",
                flex: 1,
                justifyContent: "flex-end",
                marginTop: "2%",
                marginBottom: "2%",
              }}
            >
              <Button
                onClick={handleStartServey}
                style={{
                  ...styles.stylesForButton,
                  fontSize: matches ? " " : 10,
                }}
                variant="contained"
                color="primary"
              >
                <strong>Start Servey</strong>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
