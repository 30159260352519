/*eslint-disable*/
import React, { useState, useEffect, useReducer } from 'react'
import Button from '@material-ui/core/Button'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Notification from '../../components/Snackbar/Notification.js'
import CustomTable from '../../components/Table/Table'
import TextField from '@material-ui/core/TextField'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Loader from 'react-loader-spinner'
import AccountCircle from '@material-ui/icons/SearchOutlined'
import InputAdornment from '@material-ui/core/InputAdornment'
import MenuItem from '@material-ui/core/MenuItem'
import Fingerprint from '../../assets/img/fingerprint.png'
import BarCode from '../../assets/img/Bar Code.png'
import Header from '../../components/Header/Header'
import FlagManagementIcon from '../../assets/img/Housekeeping.png'
import eye_icon from '../../assets/img/Eye.png'
import Back from '../../assets/img/Back_Arrow.png'
import NewPatientIcon from '../../assets/img/New Patient.png'
import '../../assets/jss/material-dashboard-react/components/loaderStyle.css'
import cookie from 'react-cookies'
import { styles, useStyles, useStylesForTabs } from './styles.js'
import classNames from "classnames";
import { displayInHumanDifference, replacePatientIdIfNull } from "../../utils/commonFunc";
import axios from "axios";
import {
  getPendingRRRSample,
  getCompletedRRRSample,
} from "../../public/endpoins";

import {
  getCompletedRRRCall,
  getPendingRRRCall,
  updateStatus,
  updateSenseiStatusCall,
} from './networkCalls'

const tableHeading = [
  'MRN No',
  'Patient Name',
  'Room No',
  'Status',
  'Action',
  '',
]
const tableDataKeys = ['mrn', 'patientName', 'roomNo', 'status']

const tableHeadingForPorter = [
  'MRN No',
  'Patient Name',
  'Request ID',
  'Bundle/Test',
  'Requested By',
  'Room No',
  'status',
  'Action',
  '',
]
const tableDataKeysForPorter = ['mrn', 'patientName', 'radRequestId', 'serviceName',  'serviceRequestedBy', 'roomNo', 'status']

const actions = { edit: true }
const actions2 = { assign: true }

export default function RadReqReceiver(props) {
  const classesForTabs = useStylesForTabs()
  const classes = useStyles()
  const matches = useMediaQuery('(min-width:600px)')
  const [currentUser, setCurrentUser] = useState(cookie.load('current_user'))
  const [pendingSenseiArray, setPendingSenseiArray] = useState([])
  const [completedSenseiArray, setCompletedSenseiArray] = useState([])
  const [pendingPorterArray, setPendingPorterArray] = useState([])
  const [
    completedPorterArray,
    setCompletedPorterArray,
  ] = useState([])

  const [loading, setLoading] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')
  const [openNotification, setOpenNotification] = useState(false)
  const [tabName, setTabName] = useState('transferPatient')
  const [buttonValue, setButtonValue] = useState('pending')
  const [cookies, setCookies] = useState(cookie.load('current_user'))

  const [searchQuery, setSearchQuery] = useState("");
  const [timer, setTimer] = useState(null);
  const  [radRequests, setRadRequests ] = useState([])
  const [ radRequest, setRadRequest ] = useState({});

  if (openNotification) {
    setTimeout(() => {
      setOpenNotification(false)
      setErrorMsg('')
    }, 2000)
  }

  useEffect(() => {
    console.log('props', props)
    // let currentNurseId = currentUser._id;
    console.log('current user', currentUser._id)

    getPatientRemoteRequest('');

  }, [])

  // useEffect(() => {}, [tabName])

  const handleButtonChange = (buttonValue) => {
    console.log('buttonValue', buttonValue)
    console.log('ID', currentUser._id)
    setButtonValue(buttonValue)
    props.history.push({
      pathname: buttonValue === 'completed'?'/dashboard/home/rrrcompleted':'/dashboard/home/rrr',
    })
    getPatientRemoteRequest('');
    
    /*
    if (buttonValue === 'completed') {
      getCompletedRRRCall(
        currentUser._id,
        setCompletedPorterArray,
        setErrorMsg,
        setOpenNotification
      )
    } else if (buttonValue === 'pending') {
      getPendingRRRCall(
        currentUser._id,
        setPendingPorterArray,
        setErrorMsg,
        setOpenNotification
      )
    }
    */
  }

  const handlePauseLabSearch = (e) => {
    // setLoadSearchedData(true);
    // clearTimeout(timer);

    const a = e.target.value.replace(/[^\w\s]/gi, "");
    setSearchQuery(a);

    setTimer(
      setTimeout(() => {
        getPatientRemoteRequest(a);
        /*labServiceSearchCall(
          a,
          setItemFoundSuccessfully,
          setItemFound,
          setLoadSearchedData
        );*/
      }, 600)
    );
  };


  const getPatientRemoteRequest = (sQuery) => {

    let searchURL = getPendingRRRSample + '/'+ currentUser._id;
    if ( props.history.location.pathname === '/dashboard/home/rrrcompleted' ) {
      searchURL = getCompletedRRRSample + '/' + currentUser._id
    }
    if (sQuery){
      searchURL = searchURL + '?searchKeyword=' + sQuery;
    }

    console.log('searchURL::', searchURL);

    axios
      .get(searchURL)
      .then((res) => {
        console.log('Pending task', res)
        if (res.data.success) {
          res.data.data.map((val) => {
            val.patientName = val.patientId.name[0].given[0] + " " + val.patientId.name[0].family;
            val.roomNo = val.room[0].roomId.roomNo;
            // val.status = val.radRequest.nurseTechnicianStatus;
            val.radRequestId = val.radRequest.requestId;
            val.radRequestUID = val.radRequest._id;
            // (val.serviceName = val.radRequest.serviceId.category + ': ' + val.radRequest.serviceId.name;
            // val.serviceRequestedBy = val.radRequest.requestedBy.name[0].given[0] + ' ' + val.radRequest.requestedBy.name[0].family;
            val.mrn = val.patientId.identifier[0].value;
            val.episodeNo = val.requestNo;
            // val.radRequestId = val.radRequest.requestNo;

            // Age
            var today = new Date();
            var birthDate = new Date(val.patientId.birthDate);
            var age_now = today.getFullYear() - birthDate.getFullYear();
            var m = today.getMonth() - birthDate.getMonth();
            if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
              age_now--;
            }
            val.ageNow = age_now;

            // Services
            let xStatus = "Completed";
            let servicesNames = [];
            Object.entries(val.radRequest.services).map(([key, val]) => {
              servicesNames.push(val.name)
              if (val.cServiceId.length <= 0){
                xStatus = 'Pending';
              }
            });
            val.serviceName = servicesNames.join(',');
            val.statusTasks = xStatus;


          });
          console.log('patient name', res.data.data)
          // var allLabSorted = _.sortBy(res.data.data, "createdAt").reverse();
          setRadRequests(res.data.data.reverse())
        } else if (!res.data.success) {
          setErrorMsg(res.data.error)
          setOpenNotification(true)
        }
        return res
      })
      .catch((e) => {
        console.log('error: ', e)
      })
  }

  function handletransferPatient(rec) {
    console.log('rec', rec)
    props.history.push({
      pathname: '/dashboard/home/patienttransfer',
      state: {
        selectedRecord: rec,
        //   selectedItem: props.history.location.state,
      },
    })
  }
  function handleUnderObserved() {
    setOpenNotification(true)
    setErrorMsg('Already Observed patient')
  }

  const handleView = (rec) => {
    let url = '/dashboard/home/rrr/details/'+rec._id+'/'+rec.radRequest._id;
    props.history.push({
      pathname: url,
    });
  }

  function handleAssign(rec) {
    console.log('rec', rec)
    console.log('rec', rec.radRequest.nurseTechnicianStatus)

    if (rec.radRequest.nurseTechnicianStatus === 'Not Collected') {
      const params = {
        staffId: currentUser._id,
        edrId: rec._id,
        // task: rec.task === "To Be Clean" ? "Cleaned" : "",
      }
      updateStatus(props, params, setOpenNotification, setErrorMsg)
    } else {
      setOpenNotification(true)
      setErrorMsg('Already Collected Lab Sample....Thanks')
    }
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        position: 'fixed',
        width: '100%',
        height: '100%',
        backgroundColor: '#60d69f',
        overflowY: 'scroll',
      }}
    >
      <Header history={props.history} />

      <div className='cPadding'>
        <div className='subheader'>
          <div>
            <img src={NewPatientIcon} style={{ marginLeft: '0px' }} />
            <h4>Radiology Request Receiver</h4>
          </div>

          {buttonValue === 'pending' ? (
            <Button
              onClick={() => handleButtonChange('completed')}
              style={{
                ...styles.stylesForButton,
                fontSize: matches ? ' ' : 10,
              }}
              variant='contained'
              color='primary'
            >
              <img src={eye_icon} className='icon-view' />
              &nbsp;&nbsp;
              <strong>Completed Tasks</strong>
            </Button>
          ) : (
            <Button
              onClick={() => handleButtonChange('pending')}
              style={{
                ...styles.stylesForButton,
                fontSize: matches ? ' ' : 10,
              }}
              variant='contained'
              color='primary'
            >
              <img src={eye_icon} className='icon-view' />
              &nbsp;&nbsp;
              <strong>Pending Tasks</strong>
            </Button>
          )}
        </div>

        <div
          className='container-fluid'
          style={{ paddingLeft: 0, paddingRight: 0 }}
        >

          <div className='row row-5'>

            <div className="col-12">
              <div className="form-group" style={{ ...styles.inputContainerForTextField, marginTop: '10px' }}>
                <TextField
                  required
                  label="Patient MRN/Episode/Request Number or Name"
                  name={"searchQuery"}
                  value={searchQuery}
                  onChange={handlePauseLabSearch}
                  className="textInputStyle"
                  variant="filled"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <AccountCircle />
                      </InputAdornment>
                    ),
                    className: classes.input,
                    classes: { input: classes.input },
                    disableUnderline: true,
                  }}
                  InputLabelProps={{
                    className: classes.label,
                    classes: { label: classes.label },
                  }}
                />
              </div>
            </div>


            {radRequests !== ' ' ? (
              <>
              {
                Object.entries(radRequests).map((obj, entry) => {
                  return (
                    <div className="col-12 col-md-6 col-lg-6 col-xl-6">
                      <div className="card card-detail-info card-detail-small mb-2">
                        <div className="row row-5">
                          <div className="col-sm-12"><strong>Request #:</strong> <span>{obj[1].radRequestId}</span> &nbsp; <strong>PA/Bed#:</strong> <span>{obj[1].roomNo}</span></div>
                          <div className="col-sm-6  mt-3"><strong>Epi.#:</strong> <span>{obj[1].episodeNo}</span></div>
                          <div className="col-sm-6  mt-3"><strong>MRN:</strong> <span>{obj[1].mrn}</span></div>
                          <div className="col-sm-10 mt-3"><strong>Patient:</strong> <span>{obj[1].patientName} / {obj[1].gender==="Female"?"F":"M"} / {obj[1].ageNow}y</span></div>
                          <div className="col-sm-2  mt-3 text-right"><strong>T:</strong> <span>0:00</span></div>
                        </div>
                        <div className="card-status-action">
                          <span className={classNames(`badge`, `status-`+obj[1].statusTasks.toLowerCase())}>{obj[1].statusTasks}</span>
                          { 
                            obj[1].urgent==="Yes"?<span className="ml-info">!</span>:undefined
                          }
                          <i className="ml-view ml-10 zmdi zmdi-eye zmdi-hc-2x" onClick={() => {
                            handleView(obj[1]);
                          }} />
                        </div>
                      </div>
                    </div>
                  )
                })
              }
              <Notification msg={errorMsg} open={openNotification} />

              <div className="col-12" style={{ marginBottom: 20, marginTop: 50 }}>
                <img
                  onClick={() => props.history.goBack()}
                  src={Back}
                  style={{ width: 45, height: 35, cursor: 'pointer' }}
                />
              </div>
              </>
            ) : (
              // <div className='LoaderStyle'>
              //   <Loader type='TailSpin' color='red' height={50} width={50} />
              // </div>
              <div className='row ' style={{ marginTop: '25px' }}>
                <div className='col-11'>
                  <h3
                    style={{
                      color: 'white',
                      textAlign: 'center',
                      width: '100%',
                      position: 'absolute',
                    }}
                  >
                    Opps...No Data Found
                  </h3>
                </div>
                <div className='col-1' style={{ marginTop: 45 }}>
                  {/* <img
                    onClick={() => props.history.goBack()}
                    src={Back_Arrow}
                    style={{
                      maxWidth: '60%',
                      height: 'auto',
                      cursor: 'pointer',
                    }}
                  /> */}
                </div>
              </div>
            )}


            
          </div>
        </div>

        <Notification msg={errorMsg} open={openNotification} />
      </div>
    </div>
  )
}
