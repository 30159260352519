import React from "react";
import "./MenuPage.css";
import { Badge } from "@material-ui/core";
import NotifyMe from "../../components/Header/NotificationTray";

class MenuTree extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    setTimeout(() => {
      document.getElementById("menu-open").checked = true;
    }, 1020);
  }

  render() {
    return (
      <div
        className="menupage"
        style={{
          display: "flex",
          flex: 4,
          minHeight: "100%",
          alignItems: "center",
          justifyContent: "center",
          position: "center",
          left: "45%",
        }}
      >
        <nav className="menu">
          <input
            type="checkbox"
            href="#"
            className="menu-open"
            name="menu-open"
            id="menu-open"
          />

          <label
            className="menu-open-button "
            for="menu-open"
            style={{
              boxShadow: "3px 3px 3px #2433a5",
              height: 115,
              width: 115,
            }}
          >
            {this.props.options[0] ? (
              <div className="containerStyle" onClick={() => {}}>
                <img
                  src={this.props.options[0].img}
                  className="imageStyleCenter"
                  alt="Alternate"
                />
                {/* <img
                  src={this.props.options[0].img}
                  className="imageStyleCenter"
                /> */}
                <h6 className="headingStyle">{this.props.options[0].text}</h6>
              </div>
            ) : (
              undefined
            )}
          </label>

          <a className="menu-item item-1">
            {this.props.options[1] ? (
              <div
                className="containerStyle"
                onClick={() =>
                  this.props.options[1].path
                    ? this.props.history.push(this.props.options[1].path)
                    : {}
                }
              >
                <img src={this.props.options[1].img} className="imageStyle" />
                <h6 className="headingStyle">{this.props.options[1].text}</h6>
              </div>
            ) : (
              undefined
            )}
          </a>

          <a className="menu-item item-2">
            {this.props.options[2] ? (
              <div
                className="containerStyle"
                onClick={() =>
                  this.props.options[2].path
                    ? this.props.history.push(this.props.options[2].path)
                    : {}
                }
              >
                <img src={this.props.options[2].img} className="imageStyle" />
                <h6 className="headingStyle">{this.props.options[2].text}</h6>
              </div>
            ) : (
              undefined
            )}
          </a>

          <a className="menu-item item-3">
            {this.props.options[3] ? (
              <div
                className="containerStyle"
                onClick={() =>
                  this.props.options[3].path
                    ? this.props.history.push(this.props.options[3].path)
                    : {}
                }
              >
                <img src={this.props.options[3].img} className="imageStyle" />
                <h6 className="headingStyle">{this.props.options[3].text}</h6>
              </div>
            ) : (
              undefined
            )}
          </a>

          <a className="menu-item item-4">
            {this.props.options[4] ? (
              <div
                className="containerStyle"
                onClick={() =>
                  this.props.options[4].path
                    ? this.props.history.push(this.props.options[4].path)
                    : {}
                }
              >
                <img src={this.props.options[4].img} className="imageStyle" />
                <h6 className="headingStyle">{this.props.options[4].text}</h6>
              </div>
            ) : (
              undefined
            )}
          </a>

          <a className="menu-item item-5">
            {this.props.options[5] ? (
              <div
                className="containerStyle"
                onClick={() =>
                  this.props.options[5].path
                    ? this.props.history.push(this.props.options[5].path)
                    : {}
                }
              >
                <img src={this.props.options[5].img} className="imageStyle" />
                <h6 className="headingStyle">{this.props.options[5].text}</h6>
              </div>
            ) : (
              undefined
            )}
          </a>

          <a className="menu-item item-6">
            {this.props.options[6] ? (
              <div
                className="containerStyle"
                onClick={() =>
                  this.props.options[6].path
                    ? this.props.history.push(this.props.options[6].path)
                    : {}
                }
              >
                <img src={this.props.options[6].img} className="imageStyle" />
                <h6 className="headingStyle">{this.props.options[6].text}</h6>
              </div>
            ) : (
              undefined
            )}
          </a>

          <a className="menu-item item-7">
            {this.props.options[7] ? (
              <div
                className="containerStyle"
                onClick={() =>
                  this.props.options[7]
                    ? this.props.history.push(this.props.options[7].path)
                    : {}
                }
              >
                <img src={this.props.options[7].img} className="imageStyle" />
                <h6 className="headingStyle">{this.props.options[7].text}</h6>
              </div>
            ) : (
              undefined
            )}
          </a>
        </nav>
      </div>
    );
  }
}
export default MenuTree;
