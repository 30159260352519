import React, { useEffect, useState, useReducer } from "react";
import { withStyles } from '@material-ui/core/styles';
import { 
  Button, 
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment,
  MenuItem,
  TextField,
  useMediaQuery,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
} from "@material-ui/core";
import cookie from "react-cookies";
import {
  labServiceSearchCall,
  labServiceCategoryCall,
  getRadTechniciansCall,
} from "./NetworkCallForNotesScreen";

import axios from "axios";
import {
  getRadServiceCategory,
  addRadRequestBulk
} from "../../public/endpoins";

import useStylesForInput from "../../assets/jss/material-dashboard-react/inputStyle";
import styles from "./styles";
import Loader from "react-loader-spinner";
import {
  Checkbox, 
  FormLabel, 
  FormControl, 
  FormGroup, 
  FormControlLabel,
  RadioGroup, 
  Radio
} from '@material-ui/core';
import {
  priorityArray,
  statusArray,
} from "./configForNotesScreen";
import CloseIcon from '@material-ui/icons/Close';
import MicIcon from "@material-ui/icons/Mic";
import StopIcon from "@material-ui/icons/Stop";
import MicRecorder from "mic-recorder-to-mp3";
import AccountCircle from "@material-ui/icons/SearchOutlined";
import Notification from "../../components/Snackbar/Notification.js";

export default function RadRequestsDialog(props) {
  
  const matches = useMediaQuery("(min-width:600px)");
  const classes = useStylesForInput();

  const GreenCheckbox = withStyles({
    root: {
      color: '#AAAAAA',
      '&$checked': {
        color: '#AAAAAA',
      },
    },
    checked: {},
  })((props) => <Checkbox color="default" {...props} />);

  const GreenRadio = withStyles({
    root: {
      color: '#AAAAAA',
      '&$checked': {
        color: '#AAAAAA',
      },
    },
    checked: {},
  })((props) => <Radio color="default" {...props} />);

  


  const initialState = {
    bulkTestSelected: false,
    resetedOn: "",
    defaultItems: {},
    seletedRadItems: [],
    labPriority: "",
    labTechnician: "",
    labComments: "",
    labTechniciansList: [],
  };
  function reducer(state, { field, value }) {
    return {
      ...state,
      [field]: value,
    };
  }
  const [state, dispatch] = useReducer(reducer, initialState);
  const {
    bulkTestSelected,
    resetedOn,
    defaultItems,
    seletedRadItems,
    labPriority,
    labTechnician,
    labComments,
    labTechniciansList,
  } = state;

  const handleCheckupChange = (event) => {
    dispatch({field: 'bulkTestSelected', value: event.target.value});
  };

  const uniqueCodeField = (name) => {
    name = name.toLowerCase();
    name = name.replace(/[^a-z0-9]+/g, '-');
    name = name.replace(/^-+|-+$/g, '');
    return name;
  }

  const itemsExistInDefaultSelection = (slug, item) => {

    let defaultSelected = [];
    let currentIndex = -1;
    defaultItems.map((d, index) => {
      if ( d.id === slug ){
        currentIndex = index;
        defaultSelected = d.items;
      }
    });

    if (defaultSelected.includes(item)){ // Already Exists Item
      return true;
    }

    return false;
  };

  const onChangeValue = (e) => {
    dispatch({
      field: e.target.name,
      value: e.target.value.replace(/[^\w\s]/gi, ""),
    });
  };

  const onChangePriorityValue = (e, index, item) => {
    console.log("ConsoleRadRequest :: onChangePriorityValue", e, index, item);

    let newSelectedRadItems = seletedRadItems;
    newSelectedRadItems.map((d, index) => {
      if (d.id === item){
        d.priority = e;
      }
    });
    console.log("ConsoleRadRequest :: onChangePriorityValue :: nv", newSelectedRadItems);
    dispatch({
      field: 'seletedRadItems',
      value: newSelectedRadItems
    });
  };

  const removeSelectedRadItem = (index, id) => {
    if (window.confirm('Are you sure to remove?')){
      if (seletedRadItems.includes(id)){ // Already Exists Item
        let indexOfItem = seletedRadItems.indexOf( id );
        seletedRadItems.splice( indexOfItem, 1 );
        dispatch({
          field: 'seletedRadItems',
          value: seletedRadItems
        });
      }
    }
  };

  const addSelectedRadItem = () => {
    // ConsoleRadRequest 

    if (
      // labComments &&
      // labComments.length > 0 &&
      // labPriority &&
      // labPriority.length > 0 &&
      seletedRadItems.length > 0
    ){
      setOpenNotification(true);
      setsuccessMsg("Adding the Rad Request");
      const params = {
        serviceIds: seletedRadItems,
        priority: labPriority,
        staffId: currentUser._id,
        edrId: props.edrId,
        notes: labComments,
        labTechnicianId: labTechnician,
      };
      console.log( 'ConsoleRadRequest', params );
      // props.hideDialog(false);
      
      //SS
      axios
        .put(addRadRequestBulk, params)
        .then((res) => {
          console.log( 'ConsoleRadRequest', res.data );
          if (res.data.success) {
            // console.log("response after adding Rad Request", res.data);
            props.selectedPatientForReducer(res.data.data);
            dispatch({
              field: "labComments",
              value: "",
            });
            dispatch({
              field: "labPriority",
              value: "",
            });
            dispatch({
              field: "labServiceName",
              value: "",
            });
            dispatch({
              field: "labTechnician",
              value: "",
            });
            props.hideDialog(false);
          } else if (!res.data.success) {
            setOpenNotification(true);
            setErrorMsg("Error while adding the Rad Request");
          }
        })
        .catch((e) => {
          console.log('ConsoleRadRequest', "error after adding Rad Request", e);
          setOpenNotification(true);
          setErrorMsg("An error occurred while adding the Rad Request.");
        });
      
    }else{
      setOpenNotification(true);
      setErrorMsg("Error while adding the Rad Request");
    }

    /*
    if (validateFields()) {
      const params = {
        serviceId: selectedRad._id,
        name: selectedRad.name,
        type: selectedRad.type,
        price: selectedRad.price,
        priority: labPriority,
        staffId: currentUser._id,
        edrId:
          props.history.location.state.comingFrom === "notification"
            ? reponseThroughNotification._id
            : selectedRecord._id,
        notes: labComments,
        labTechnicianId: labTechnician,
      };
      console.log("Rad params", params);
      saveRadRequestCall(
        params,
        setOpenNotification,
        setErrorMsg,
        props,
        dispatch
      );
    } else {
      setOpenNotification(true);
      setErrorMsg("Error while adding the Rad Request");
    }
    setRadForm(true);
    */
  };

  const [currentUser] = useState(cookie.load("current_user"));
  const [timer, setTimer] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [itemFound, setItemFound] = useState("");
  const [itemFoundSuccessfull, setItemFoundSuccessfully] = useState(false);
  const [loadSearchedData, setLoadSearchedData] = useState(false);
  const [selectedRad, setSelectedRad] = useState("");
  const [categoriesData, setCategoriesData] = useState([]);
  const [mItems, setMItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [myDefaultItems, setMyDefaultItems] = useState([]);


  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setsuccessMsg] = useState("");
  const [openNotification, setOpenNotification] = useState(false);
  
  if (openNotification) {
    setTimeout(() => {
      setOpenNotification(false);
      setErrorMsg("");
      setsuccessMsg("");
    }, 4000);
  }

  useEffect(() => {
    loadAsyncData();
    /*getRadTechniciansCall( currentUser._id, dispatch, setOpenNotification, setErrorMsg );*/
  }, []);

  useEffect(() => {
  }, [myDefaultItems]);

  const loadAsyncData = () => {
    // labServiceCategoryCall(setCategoriesData);
      axios
        .get(getRadServiceCategory)
        .then((res) => {
          if (res.data.success) {
            // console.log('labServiceCategoryCall', res.data.data);
            let remoteData = res.data.data;
            setCategoriesData(remoteData);

            let itemsInList = [];
            let defaultItemsInList = [];

            // Items Extractor
            remoteData.map((d, index) => {
              let slug = uniqueCodeField(d._id);
              let values = [];

              if (d.items.length > 0){
                d.items.map((nd, nindex) => {
                  // values.push(nd);
                  itemsInList.push(nd);

                  if (nd.checked){
                    values.push(nd._id);
                  }

                });
              }
              defaultItemsInList.push({
                'id': slug,
                'name': d._id,
                'items': values,
              });
              // defaultItemsInList[ slug ] = values;
            });
            // dispatch({ xx: 'defaultItems', value: defaultItemsInList });
            dispatch({ field: 'resetedOn', value: 'now' });

            setMyDefaultItems(defaultItemsInList);
            dispatch({
              field: 'defaultItems',
              value: defaultItemsInList
            });

            setIsLoading(false);
            setMItems(itemsInList);
            // setCategoriesData([
            //   {_id: "Hello"}
            // ])
          }
        })
        .catch((e) => {
          console.log("error while searching req", e);
        });

  }
  // loadAsyncData();

  const handlePauseRadSearch = (e) => {
    // setLoadSearchedData(true);
    // clearTimeout(timer);

    const a = e.target.value.replace(/[^\w\s]/gi, "");
    setSearchQuery(a);

    setTimer(
      setTimeout(() => {
        labServiceSearchCall(
          a,
          setItemFoundSuccessfully,
          setItemFound,
          setLoadSearchedData
        );
      }, 600)
    );
  };

  const handleChange = (event) => {
    // setState({ ...state, [event.target.name]: event.target.checked });
    let defaultSelected = [];
    let currentIndex = -1;
    defaultItems.map((d, index) => {
      if ( d.id === event.target.name ){
        currentIndex = index;
        defaultSelected = d.items;
      }
    });


    if (defaultSelected.includes(event.target.value)){ // Already Exists Item
      let indexOfItem = defaultSelected.indexOf( event.target.value );
      defaultSelected.splice( indexOfItem, 1 );
    }else{ // Adding Item
      defaultSelected.push( event.target.value );
    }

    let nItems = defaultItems;
    nItems[ currentIndex ].items = defaultSelected;
    dispatch({
      field: 'defaultItems',
      value: nItems
    });

  };

  const addSelectedCheckup = () => {
    let items = [];

    let kidneyFunctionalText = ['62e266d698975c58dae5dd48', '62e266d698975c58dae5dd49', '62e266d698975c58dae5dd4c','62e266d698975c58dae5dd4a','62e266d698975c58dae5dd4b', '62e266d698975c58dae5dd7f'];///*'NA', 'K', 'CL',*/
    let liverFunctionalTest  = ['62e266d698975c58dae5dd4d', '62e266d698975c58dae5dd4e', '62e266d698975c58dae5dd4f', '62e266d698975c58dae5dd50', '62e266d698975c58dae5dd51', '62e266d698975c58dae5dd52', '62e266d698975c58dae5dd53', '62e266d698975c58dae5dd54', '62e266d698975c58dae5dd55'];
    let checkup1 = [
      '62e266d698975c58dae5dd71',
      '62e266d698975c58dae5dd42',
      '62e266d698975c58dae5dd41',
      '62e266d698975c58dae5de85',
      '62e266d698975c58dae5dd85',
      '62e266d698975c58dae5de35', //
      '62e266d698975c58dae5de86',
      ...kidneyFunctionalText,
      ...liverFunctionalTest
    ];

    let checkup2 = [
      ...checkup1,
      // 'HEPATITES B, ',
      // 'HEPATITIS C , ',
      '62e266d698975c58dae5de99',
      '62e266d698975c58dae5de94',
      '62e266d698975c58dae5de97',
      '62e266d698975c58dae5dd58',
      '62e266d698975c58dae5de93',
      '62e266d698975c58dae5de98',
      '62e266d698975c58dae5de96',
    ];

    let checkup3 = [
      ...checkup1,
      '62e266d698975c58dae5dd89',
      '62e266d698975c58dae5dd88',
      '62e266d698975c58dae5ddf5',
      '62e266d698975c58dae5de10',
      '62e266d698975c58dae5dd6a',
    ];

    let superCheckup = [
      ...checkup1,
      ...checkup2,
      ...checkup3,
      '62e266d698975c58dae5de9a',
      '62e266d698975c58dae5de95',
    ];

    let royalCheckup = [
      ...superCheckup,
      '62e266d698975c58dae5de47', // ZINC
      '62e266d698975c58dae5dd6f', // RF
      '62e266d698975c58dae5dd6e', // ASO
      '62e266d698975c58dae5de48', // MG
      '62e266d698975c58dae5dded', // CMV-IGM
      '62e266d698975c58dae5dda6', // VDRL
      '62e266d698975c58dae5de58', // B2 MICRGLOBULIN
      // HBA1C
      // MCR
      '62e266d698975c58dae5de3a', // H.PYLORI IGA
      '62e266d698975c58dae5de60', // IGE

      //62e266d698975c58dae5de48
    ];

    let defaultSelected = [];

    if ("cu1" == bulkTestSelected){
      defaultSelected = checkup1;
    }else if ("cu2" == bulkTestSelected){
      defaultSelected = checkup2;
    }else if ("cu3" == bulkTestSelected){
      defaultSelected = checkup3;
    }else if ("cu4" == bulkTestSelected){ // Super Checkup
      defaultSelected = superCheckup;
    }else if ("cu5" == bulkTestSelected){ // Royal Checkup
      defaultSelected = royalCheckup;
    }

    let readyToAdd = seletedRadItems;
    if ( defaultSelected.length > 0 ) {
      defaultSelected.map((dx, dindex) => {
        console.log( 'ConsoleRadRequest', dx );
        if ( !readyToAdd.includes( dx ) ){
          readyToAdd.push(dx);
        }
      });
      console.log( 'ConsoleRadRequest', readyToAdd );

      dispatch({
        field: 'seletedRadItems',
        value: readyToAdd
      });
    }else{
      setOpenNotification(true);
      setErrorMsg('Please select an item from the above list.');
    }
  
    
  }

  const addSelectedItems = (slug) => {
    
    let defaultSelected = [];
    let currentIndex = -1;
    defaultItems.map((d, index) => {
      if ( d.id === slug ){
        currentIndex = index;
        defaultSelected = d.items;
      }
    });
 

    let readyToAdd = seletedRadItems;
    console.log( 'ConsoleRadRequest', readyToAdd );

    if ( defaultSelected.length > 0 ) {
      defaultSelected.map((dx, dindex) => {
        console.log( 'ConsoleRadRequest', dx );

        let itemExists = readyToAdd.filter((element, index, array) => {
            // Return true or false
            return element.id === dx;
        });


        console.log( 'ConsoleRadRequest', itemExists );

        if ( itemExists.length<=0 ){
          readyToAdd.push({
            id: dx,
            priority: 'Normal',
          });
        }
      });
      console.log( 'ConsoleRadRequest', readyToAdd );

      dispatch({
        field: 'seletedRadItems',
        value: readyToAdd
      });
    }else{
      setOpenNotification(true);
      setErrorMsg('Please select an item from the above list.');
    }

  };

  function handleAddItem(i) {
    console.log("selected item", i);

    setSelectedRad(i);
    /*
    dispatch({
      field: "labServiceName",
      value: i.name,
    });
    */

    setSearchQuery("");
    // setaddRadRequest(true)
  }

  return (
    <Dialog
      fullScreen
      aria-labelledby="form-dialog-title"
      open={props.openDialog}
      maxWidth="xl"
      fullWidth={true}
    >
      <DialogContent style={{ backgroundColor: "rgb(19 213 159)" }}>
        <DialogTitle
          id="simple-dialog-title"
          className="pl-0"
          style={{ color: "white" }}
        >Profiles/Rad Requests</DialogTitle>
        <div className="">
          <h4 
            className="h6 text-white"
            style={{
              fontWeight: '700'
            }}>Selected Profiles/Rad Requests</h4>
          <div
            style={{
              zIndex: 10,
              marginTop: 10,
            }}
          >
            <Paper className="mb-3" style={{ maxHeight: 200, overflow: "auto" }}>
              <Table size="small" stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell width="1%">#</TableCell>
                    <TableCell>Service Name</TableCell>
                    <TableCell>Priority</TableCell>
                    <TableCell align="left">Type</TableCell>
                    <TableCell align="left" width="1">&nbsp;</TableCell>
                  </TableRow>
                </TableHead>
                {seletedRadItems.length > 0 ? (
                  <TableBody>
                    {seletedRadItems.map((i, itemIx) => {
                      let item = mItems.filter(function callback(element, index, array) {
                          // Return true or false
                          return element._id === i.id;
                      });
                      return (
                        <TableRow
                          key={i}
                          style={{ cursor: "pointer" }}
                          title="Click to Remove"
                        >
                          <TableCell>{itemIx+1}</TableCell>
                          <TableCell>
                            <span className="d-flex align-items-center">
                              <span style={{
                                display: 'inline-block',
                                width: 16,
                                height: 16,
                                borderRadius: 16,
                                backgroundColor: item[0].colorCode,
                                border: '1px solid #f2f2f2'
                              }}></span>
                              <span className="ml-2"> {item[0].name}</span>
                            </span>
                          </TableCell>
                          <TableCell className="subItemChangeDropdownPadding">
                            <TextField
                              required
                              select
                              // disabled={enableForm}
                              name={"labPriority"}
                              value={i.priority}
                              onChange={(e) => {
                                onChangePriorityValue(e.target.value, i, item[0]._id)
                              }}
                              className="textInputStyle"
                              variant="filled"
                              InputProps={{
                                className: classes.input,
                                classes: { input: classes.input },
                                disableUnderline: true,
                              }}
                            >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                              {priorityArray.map((val) => {
                                return (
                                  <MenuItem key={val.key} value={val.key}>
                                    {val.value}
                                  </MenuItem>
                                );
                              })}
                            </TextField>
                          </TableCell>
                          <TableCell>{item[0].category}</TableCell>
                          <TableCell align="right"><CloseIcon onClick={() => removeSelectedRadItem(i, item[0]._id)} /></TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                ) : (
                  <TableBody>
                    <TableRow key="0">
                      <TableCell align="center" colSpan={5}>No Profiles/Rad Requests Selected</TableCell>
                    </TableRow>
                  </TableBody>
                )} 
              </Table>
            </Paper>
            {seletedRadItems.length > 0 ? (
              <div className="row row-5">
                <div className={`col-xs-6 col-md-6 d-none`}>
                  <div className="form-group">
                    <TextField
                      required
                      // disabled={viewRecord}
                      select
                      fullWidth
                      label={"Select Rad Techncian"}
                      name={"labTechnician"}
                      value={labTechnician}
                      error={labTechnician === ""}
                      onChange={onChangeValue}
                      variant="filled"
                      className="dropDownStyle"
                      InputProps={{
                        className: classes.input,
                        classes: { input: classes.input },
                        disableUnderline: true,
                      }}
                    >
                      <MenuItem value="">
                        <em>Rad Technicians</em>
                      </MenuItem>

                      {labTechniciansList.map((val) => {
                        return (
                          <MenuItem key={val._id} value={val._id}>
                            {val.name[0].given[0] + " " + val.name[0].family}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                  </div>
                </div>
                <div className={`col-xs-12 col-md-6 d-none`}>
                  <div className="form-group">
                    <TextField
                      required
                      select
                      // disabled={enableForm}
                      label="Priority"
                      name={"labPriority"}
                      value={labPriority}
                      onChange={onChangeValue}
                      error={labPriority === ""}
                      className="textInputStyle"
                      variant="filled"
                      InputProps={{
                        className: classes.input,
                        classes: { input: classes.input },
                        disableUnderline: true,
                      }}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {priorityArray.map((val) => {
                        return (
                          <MenuItem key={val.key} value={val.key}>
                            {val.value}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                  </div>
                </div>
                <div className={`col-xs-12 col-md-1`}>
                  <div className="d-flex align-items-center">
                    <div
                      style={{
                        marginRight: matches ? 15 : 7,
                        backgroundColor: "#2C6DDD",
                        height: matches ? 55 : 50,
                        borderRadius: 30,
                        width: matches ? 55 : 50,
                        paddingLeft: matches ? 10 : 8,
                        cursor: 'pointer'
                      }}
                    >
                      <MicIcon
                        style={{ marginTop: matches ? 12 : 10, color: "white" }}
                        fontSize="large"
                        onClick={(e) => {
                          
                        }}
                        color="primary"
                      />
                    </div>
                  </div>
                </div>
                <div className={`col-xs-12 col-md-9`}>
                  <div className="form-group">
                    <TextField
                      // required
                      multiline="true"
                      // disabled={enableForm}
                      label="Comments / Notes"
                      name={"labComments"}
                      value={labComments}
                      // error={labComments === ""}
                      onChange={onChangeValue}
                      className="textInputStyle"
                      variant="filled"
                      InputProps={{
                        className: classes.input,
                        classes: { input: classes.input },
                        disableUnderline: true,
                      }}
                    />
                  </div>
                </div>
                <div className="col-xs-12 col-md-2">
                  <Button
                    // className="addButton"
                    style={{
                      ...styles.stylesForButton,
                      backgroundColor: "#AD6BBF",
                      color: "white",
                      cursor: "pointer",
                      borderRadius: 5,
                      backgroundColor: "rgb(173, 107, 191)",
                      height: matches ? 56 : 45,
                      outline: "none",
                      width: "100%",
                    }}
                    // disabled={!addRadRequest}
                    onClick={addSelectedRadItem}
                    variant="contained"
                    color="primary"
                    fullWidth
                  >
                    Add
                  </Button>
                </div>
              </div>
            ) : (
              undefined
            )} 
            <hr />
          </div>
          




          <h4 
            className="h6 text-white"
            style={{
              fontWeight: '700'
            }}>Select Test(s)/Bundle(s)</h4>
          <div
            className="form-group "
            style={{
              ...styles.inputContainerForTextField
            }}
          >
            <TextField
              required
              label="Search by Rad Test"
              name={"searchQuery"}
              value={searchQuery}
              onChange={handlePauseRadSearch}
              className="textInputStyle"
              variant="filled"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <AccountCircle />
                  </InputAdornment>
                ),
                className: classes.input,
                classes: { input: classes.input },
                disableUnderline: true,
              }}
              InputLabelProps={{
                className: classes.label,
                classes: { label: classes.label },
              }}
            />
          </div>

          {searchQuery ? (
            <div
              style={{
                zIndex: 10,
                marginTop: 10,
              }}
            >
              <Paper style={{ maxHeight: 200, overflow: "auto" }}>
                {itemFoundSuccessfull && itemFound !== "" ? (
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Service Name</TableCell>
                        <TableCell align="right">Type</TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {itemFound.map((i) => {
                        return (
                          <TableRow
                            key={i.serviceNo}
                            onClick={() => handleAddItem(i)}
                            style={{ cursor: "pointer" }}
                          >
                            <TableCell>{i.name}</TableCell>
                            <TableCell align="right">{i.category}</TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                ) : loadSearchedData ? (
                  <div style={{ textAlign: "center" }}>
                    <Loader
                      type="TailSpin"
                      color="#2c6ddd"
                      height={25}
                      width={25}
                      style={{
                        display: "inline-block",
                        padding: "10px",
                      }}
                    />
                    <span
                      style={{
                        display: "inline-block",
                        padding: "10px",
                      }}
                    >
                      <h4> Searching Rad Test...</h4>
                    </span>
                  </div>
                ) : searchQuery && !itemFoundSuccessfull ? (
                  <div style={{ textAlign: "center", padding: "10px" }}>
                    <h4>No Rad Test Found !</h4>
                  </div>
                ) : (
                  undefined
                )}
              </Paper>
            </div>
          ) : (
            undefined
          )} 
          
          <div className="row row-5">
            {
              isLoading ? 'Profiles are loading! Please wait...' :
              categoriesData ? 
                categoriesData.map((d, index) => {
                  return (
                    <div className="col-12 col-md-3 text-center">
                      <div className="dropdown mb-3">
                        <button className="pl-2 pr-2 btn btn-block bg-white dropdown-toggle" 
                          type="button" id={ "dropdownMenuButton-" + (index + 1) } 
                          data-toggle="dropdown" 
                          style={{ height: 65 }}
                          aria-haspopup="true" aria-expanded="false">
                        <span style={{ ...styles.textStyles, "white-space": "initial" }}>{ d._id }</span>
                        </button>
                        <div className="dropdown-menu w-100 pb-0" aria-labelledby={ "dropdownMenuButton-" + (index + 1) }>
                          <div style={{ height: 160, overflowY: "scroll"}}>
                          {
                           d.items.length > 0 ? 
                              d.items.map((nd, nindex) => {
                                return (
                                  <div className="dropdown-items pl-3 pr-3 d-flex align-items-center justify-content-between">
                                    <FormControlLabel
                                      control={<GreenCheckbox checked={ itemsExistInDefaultSelection( uniqueCodeField(d._id), nd._id )  } value={ nd._id } onChange={handleChange} name={ uniqueCodeField(d._id) } />}
                                      label={nd.name}
                                      style={{
                                        margin: 0,
                                      }}
                                    />
                                    <span style={{
                                      display: 'block',
                                      width: 16,
                                      height: 16,
                                      borderRadius: 16,
                                      backgroundColor: nd.colorCode,
                                      border: '1px solid #f2f2f2'
                                    }}></span>
                                  </div>
                                )
                              }) : ''
                          }
                          </div>
                          <div className="p-2 bg-light">
                            <Button
                              color="primary"
                              size="small"
                              onClick={() => addSelectedItems(uniqueCodeField(d._id))}
                              variant="contained"
                              fullWidth="true"
                            >Add Selected</Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })
                : ''
            }

            {
              !isLoading ? 
              <div className="col-12 col-md-3 text-center">
                <div className="dropdown mb-3">
                  <button className="pl-2 pr-2 btn btn-block bg-white dropdown-toggle" 
                    type="button" id={ "dropdownMenuButton-checkups" } 
                    data-toggle="dropdown" 
                    style={{ height: 65 }}
                    aria-haspopup="true" aria-expanded="false">
                    <span style={{ ...styles.textStyles, "white-space": "initial" }}>Checkups</span>
                  </button>
                  <div className="dropdown-menu w-100 pb-0" aria-labelledby={ "dropdownMenuButton-checkups" }>
                    <div style={{ height: 160, overflowY: "scroll"}}>
                    

                      <RadioGroup aria-label="gender" name="gender1" value={bulkTestSelected} onChange={handleCheckupChange}>
                        <div className="dropdown-items pl-3 pr-3 d-flex align-items-center justify-content-between"><FormControlLabel value="cu1" control={<GreenRadio />} label="Check-up - 1" style={{ margin: 0 }} /></div>
                        <div className="dropdown-items pl-3 pr-3 d-flex align-items-center justify-content-between"><FormControlLabel value="cu2" control={<GreenRadio />} label="Check-up - 2" style={{ margin: 0 }} /></div>
                        <div className="dropdown-items pl-3 pr-3 d-flex align-items-center justify-content-between"><FormControlLabel value="cu3" control={<GreenRadio />} label="Check-up - 3" style={{ margin: 0 }} /></div>
                        <div className="dropdown-items pl-3 pr-3 d-flex align-items-center justify-content-between"><FormControlLabel value="cu4" control={<GreenRadio />} label="Super Check-up" style={{ margin: 0 }} /></div>
                        <div className="dropdown-items pl-3 pr-3 d-flex align-items-center justify-content-between"><FormControlLabel value="cu5" control={<GreenRadio />} label="Royal Check-up" style={{ margin: 0 }} /></div>
                      </RadioGroup>



                    </div>
                    <div className="p-2 bg-light">
                      <Button
                        color="primary"
                        size="small"
                        onClick={() => addSelectedCheckup()}
                        variant="contained"
                        fullWidth="true"
                      >Add Selected</Button>
                    </div>
                  </div>
                </div>
              </div>
              :null
            }
          </div>


          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: 20,
            }}
          >
            <div style={{ marginTop: "2%", marginBottom: "2%" }}>
              <Button
                onClick={() => props.hideDialog(false)}
                style={{
                  ...styles.stylesForButton,
                  backgroundColor: "white",
                  color: "grey",
                  width: matches ? " " : 150,
                }}
                variant="contained"
              >
                <strong>Cancel</strong>
              </Button>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "2%",
                marginBottom: "2%",
              }}
            >
              <Button
                style={{
                  ...styles.stylesForSubmitButton,
                }}
                variant="contained"
                color="primary"
              >Submit</Button>
            </div>
          </div>
        </div>
        <Notification
          msg={errorMsg}
          open={openNotification}
          success={successMsg}
        />
      </DialogContent>
    </Dialog>
  );
}