export const styles = {
  stylesForButton: {
    color: "white",
    cursor: "pointer",
    borderRadius: 5,
    backgroundColor: "#2c6ddd",
    height: "45px",
    outline: "none",
  },
  inputContainerForTextField: {
    marginTop: 25,
  },
  textFieldPadding: {
    paddingLeft: 5,
    paddingRight: 5,
  },
};