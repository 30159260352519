import React from "react"
import CareStream from "../../../assets/img/carestream/CareStream.png"
import ViewContainerCS from "../../../components/CareStream/ViewContainerCS"
import { viewInvestigationsStyles } from "../styles"

export default function Assign(props) {
  const classes = viewInvestigationsStyles()

  return (
    <div className={classes.root}>
      <div className="subheader" style={{ marginLeft: "-10px" }}>
        <img src={CareStream} className={classes.image} />
        <div style={{ flex: 4, display: "flex", alignItems: "center" }}>
          <h4 style={{ color: "white", fontWeight: "700" }}>Investigations</h4>
        </div>
      </div>

      <ViewContainerCS
        arrayContainerCS={props.investigationsArray}
        heading="Investigation"
        buttonHeading="Investigation"
      />
    </div>
  )
}
