import React, { useEffect, useState, useReducer } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle, 
  DialogActions,
  DialogContentText
} from "@material-ui/core";
import axios from "axios";
import TextField from "@material-ui/core/TextField";
import { Grid } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import useStylesForInput from "../../assets/jss/material-dashboard-react/inputStyle";
import FormatDate from "../../utils/formatDateTime";
import MicIcon from "@material-ui/icons/Mic";
import StopIcon from "@material-ui/icons/Stop";
import MicRecorder from "mic-recorder-to-mp3";
import AssessIcon from "../../assets/img/ED Doctor Notes.png";
import Back from "../../assets/img/Back_Arrow.png";
import _ from "lodash";
import { searchAllEdrs, confirmDischargeStatus } from "../../public/endpoins";
import { displayInHumanDifference, replacePatientIdIfNull } from "../../utils/commonFunc";
import { audioURL, getIcd, getSingleEdr, updateLifeLimbEdr, addPharmacyRequest } from "../../public/endpoins";
import cookie from "react-cookies";
import Header from "../../components/Header/Header";
// import LabRequestsDialog from "./LabRequestsDialog";
import Loader from "react-loader-spinner";
import eye_icon from "../../assets/img/Eye.png";
import PatientDetailsQuick from "../../components/PatientDetails/PatientDetailsQuick";
import PatientDetails from "../../components/PatientDetails/PatientDetails";
import PatientDetails2 from "../../components/PatientDetails/PatientDetailsDirect";
import ConfirmationDialog from "../../components/confirmationDialog/confirmationDialog";
import { connect } from "react-redux";
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import {
  funForReducer,
  setPatientDetailsForReducer,
} from "../../actions/Checking";

import { setSelectedPatientForReducer } from "../../actions/patientDetails";
import classNames from "classnames";

const actions = { view: true, edit: true };
const action = { edit: true };

const useStylesForTabs = makeStyles({
  root: {
    justifyContent: "center",
  },
  scroller: {
    flexGrow: "0",
  },
});

function EpisodeInfoDetailPage(props) {
  const matches = useMediaQuery("(min-width:600px)");

  const classes = useStylesForInput();

  const initialState = {
    labRequestArray: "",
    labComments: "",
    labPriority: "",
    editComments: "",
    editPriority: "",
    serviceName: "",
    labServiceName: "",
    labTechniciansList: "",
    labTechnician: "",

    radRequestArray: "",
    radServiceName: "",
    radComments: "",
    radPriority: "",
    radTechniciansList: "",
    radTechnician: "",

    doctorNotesArray: "",
    consultationNo: "",
    date: new Date(),
    description: "",
    consultationNotes: "",
    doctorconsultationNotes: "",

    consultant: "",
    consultationType: "",
    consultantArray: [],

    consultationNotesArray: "",
    consultationNotes: "",
    consultantNotes: "",
    consultantVoiceNotes: "",
    consultationSpeciality: "",

    requestDescription: "",
    requestStatus: "",

    residentNoteArray: "",
    doctorNotes: "",
    doctorNotesAdditional: "",
    note: "",
    doctor: cookie.load("current_user").name,

    anesthesiologist: "",
    anesthesiologistList: [],
    anesthesiologistArray: "",
    anesthesiologistNotes: "",

    edNurseSpeciality: "",
    edNurseSpecialityList: [],
    edNurse: "",
    edNurseList: [],
    edNurseArray: "",
    edNurseDescription: "",
    eouNurseArray: "",
    nurseTechnicianArray: "",

    pharmacyRequestArray: "",
    nonpharmacyRequestArray: "",
    requestType: "",
    section: "",
    code: [],
    patientId: "",
    diagnosisArray: "",
    medicationArray: "",
    careStreamArray: "",
    chiefComplaintArray: "",

    price: "",

    isBlocked: false,
    isRecording: false,

    isRecordingForConsultant: false,

    blobURL: "",
    fileAudio: null,
    fileAudioForConsultant: null,
    audioUrl: "",
  };

  function reducer(state, { field, value }) {
    return {
      ...state,
      [field]: value,
    };
  }

  const [state, dispatch] = useReducer(reducer, initialState);

  const {
    labRequestArray,
    labComments,
    labPriority,
    labServiceName,
    labTechniciansList,
    labTechnician,

    radRequestArray,
    radComments,
    radPriority,
    radServiceName,
    radTechniciansList,
    radTechnician,

    editComments,
    editPriority,
    serviceName,

    pharmacyRequestArray,
    nonpharmacyRequestArray,
    code,
    section,
    diagnosisArray,
    medicationArray,
    careStreamArray,
    chiefComplaintArray,

    doctorNotesArray,
    doctorNotes,
    doctorNotesAdditional,

    anesthesiologist,
    anesthesiologistList,
    anesthesiologistArray,

    edNurseSpeciality,
    edNurseSpecialityList,
    edNurse,
    edNurseList,
    edNurseArray,

    eouNurseArray,

    nurseTechnicianArray,

    consultationNotesArray,
    consultationNotes,
    consultantNotes,
    consultantVoiceNotes,
    consultationSpeciality,
    requestDescription,
    requestStatus,

    consultant,
    consultationType,
    consultantArray,

    isBlocked,
    isRecording,
    isRecordingForConsultant,
    blobURL,
    fileAudio,
    fileAudioForConsultant,
  } = state;

  const [currentUser] = useState(cookie.load("current_user"));
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setsuccessMsg] = useState("");
  const [openNotification, setOpenNotification] = useState(false);
  const [value, setValue] = useState(0);
  const [patientDetails, setPatientDetails] = useState("");
  const [openItemDialog, setOpenItemDialog] = useState(false);
  const [openRequestDialog, setOpenRequestDialog] = useState(false);
  const [openAddDoctorNoteDialog, setOpenAddDoctorNoteDialog] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [userCameFrom, setUserCameFrom] = useState("patientlist");
  const [selectedRecord, setSelectedRecord] = useState({});
  const [lifeLimbOn, setLifeLimbOn] = useState(false);
  const [dischargeInitiated, setDischargeInitiated] = useState(false);
  const [defaultRADCheckList, setDefaultRADCheckList] = useState({});

  // Notes
  const [openRadDialog, setOpenRadDialog] = useState(false);

  const [item, setItem] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [itemFound, setItemFound] = useState("");
  const [itemFoundSuccessfull, setItemFoundSuccessfully] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [doctorForm, setDoctorForm] = useState(false);
  const [dialogForm, setDialogForm] = useState(false);

  const [labForm, setLabForm] = useState(false);
  const [editForm, setEditForm] = useState(false);
  const [radForm, setRadForm] = useState(false);
  const [enableFields, setEnableFields] = useState(false);

  const [id, setId] = useState("");
  const [searchRadioQuery, setSearchRadioQuery] = useState("");
  const [radioItemFoundSuccessfull, setRadioItemFoundSuccessfully] = useState(
    ""
  );

  const [radioItemFound, setRadioItemFound] = useState("");
  const [, setIsLoading] = useState(true);
  const [timer, setTimer] = useState(null);
  const [selectedNoteToEdit, setSelectedNoteToEdit] = useState("");
  const [viewRecord, setViewRecord] = useState(false);
  const [selectedEDNurseToEdit, setSelectedEDNurseToEdit] = useState("");

  const [selectedConsultationToEdit, setSelectedConsultationToEdit] = useState(
    ""
  );
  const [anesthesiologistToEdit, setSelectedAnesthesiologistToEdit] = useState(
    ""
  );
  const [selectedLabRequestToEdit, setSelectedLabRequestToEdit] = useState("");
  const [selectedRadRequestToEdit, setSelectedRadRequestToEdit] = useState("");
  const [loadSearchedData, setLoadSearchedData] = useState(false);
  const [selectedLab, setSelectedLab] = useState("");
  const [selectedRad, setSelectedRad] = useState("");
  const [consultationSpecialityList, setConsultationSpecialityList] = useState(
    []
  );
  const [selectedCodes, setSelectedCodes] = useState([]);
  const [selectedAdditionalCodes, setSelectedAdditionalCodes] = useState([]);
  const [reponseThroughNotification, setReponseThroughNotification] = useState(
    ""
  );

  const [showConfirmationModel, setShowConfirmationModel] = useState(false);
  const [openUpdateStatusModal, setOpenUpdateStatusModal] = useState(false);
  // var allCareStreams = [];
  
  // React
  const [ episodeId, setEpisodeId ] = useState(props.match.params.id);
  const [ pageId, setPageId ] = useState(props.match.params.pagename);

  const [ cpEducated, setCPEducated ] = useState(false);
  const [ edEducated, setEDEducated ] = useState(false);
  const [ isSocialSurveyComplete, setSocialSurveyComplete ] = useState(false);
  const [ isCEOSurveyComplete, setCEOSurveyComplete ] = useState(false);
  const [ isPatientTransferComplete, setPatientTransferComplete ] = useState(false);
  const [ isHouseKeepingCleaningComplete, setHouseKeepingCleaningComplete ] = useState(false);
  const [ houseKeepingCleaingRequestId, setHouseKeepingCleaingRequestId ] = useState("");
  const [ arrivedOpen , setArrivedOpen ] = useState(false);

  const handleSave = (statusMode) => {
    axios
      .post(`${confirmDischargeStatus}`, {
        edrId: episodeId,
        requestType: 'discharged'
      })
      .then((res) => {
        if (res.data.success) {
          setOpenNotification(true);
          setsuccessMsg(`The status has been changed to ${statusMode}!`);
          window.location.reload();
        }else if (res.data.success === false) {
          setArrivedOpen(false);
          setOpenNotification(true);
          setErrorMsg(`There might be some problem while saving the record.`);
        }
      })
      .catch((e) => {
        setOpenNotification(true);
        setErrorMsg("Unable to Update the Record");
      });
  }

  const handleArrivedClose = () => {
    setArrivedOpen(false);
  };

  useEffect(() => {
    console.log("props", props);

    console.log('DEBUG::', 'edrId::', episodeId);
    console.log('DEBUG::', 'params::', props.match.params);
    console.log('DEBUG::', getSingleEdr + "/" + episodeId);

    // Getting Realtime Data from Screen
    axios
      .get(getSingleEdr + "/" + episodeId)
      .then((res) => {
        console.log('handleView::', res.data.data);
        if (res.data.success) {

          let remoteRecord = replacePatientIdIfNull(res.data.data);

          setSelectedRecord(remoteRecord);
          setLifeLimbOn(remoteRecord.lifelimb ? true : false);

          setDischargeInitiated((remoteRecord.status==='Discharge' || remoteRecord.status==='Discharging'));

          setDefaultRADCheckList(remoteRecord.defaultRADCheckList ? remoteRecord.defaultRADCheckList : {
            pregnantCheck: 'no',
            breastFeeding: 'no',
            highInfectionRisk: 'no',
            highInfectionRiskDesc: '',
            patientDiabetic: 'no',
            diabetesControlled: '',
            patientAllergies: 'no',
            patientAllergiesDesc: null,
            pacemaker: 'no',
            cerebralAneurysmClip: 'no',
            cochlearImplants: 'no',
            neuroStimulators: 'no',
            programmableHydrocephalusShunt: 'no',
            metallicForeignBodyInEye: 'no',
            otherMetallicImplants: 'no',
            otherMetallicImplantsDesc: '',
            assistantRequired: 'no'
          });

          const object = {
            '_id': remoteRecord._id,
            patientName:
              remoteRecord.patientId.name[0].given[0] +
              " " +
              remoteRecord.patientId.name[0].family,
            mrn: remoteRecord.requestNo,//remoteRecord.patientId.identifier[0].value,
            age: remoteRecord.patientId.age,
            gender: remoteRecord.patientId.gender,
            weight: remoteRecord.patientId.weight,
            department: remoteRecord.currentLocation,
            chiefComplaint: remoteRecord.chiefComplaint.length > 0
                      ? remoteRecord.chiefComplaint.pop().chiefComplaintId.short_desc
                      : "N/A",
            chiefComplaintOthers: remoteRecord.chiefComplaintOthers,
          };
          console.log('handleView::', object)
          setPatientDetails(object);

          if (remoteRecord.dischargeRequest && remoteRecord.dischargeRequest.pharmacyRequestId){
            if (remoteRecord.pharmacyRequest){
              let findRecordOfDischarge = remoteRecord.pharmacyRequest.filter(item => item._id === remoteRecord.dischargeRequest.pharmacyRequestId);
              if (findRecordOfDischarge) {
                console.log('findRecordOfDischarge', findRecordOfDischarge)
                if (findRecordOfDischarge[0].educated){
                  setCPEducated(findRecordOfDischarge[0].educated)
                }
              }
            }
          }

          if (remoteRecord.dcdForm[0] && remoteRecord.dcdForm[0].courseOfVisit.length > 0){
            let lastRecord = remoteRecord.dcdForm[0].courseOfVisit[ remoteRecord.dcdForm[0].courseOfVisit.length - 1 ].details;
            console.log( 'lastRecord::', lastRecord );
            if (lastRecord.educateTreatmentPlan === 'yes' || lastRecord.educateMedication === 'yes' || lastRecord.educateFollowupInstruction === 'yes' || (lastRecord.patientEducationRemarks != "" || lastRecord.patientEducationRemarks != null)){
              setEDEducated(true);
            }
          }

          //
          if (remoteRecord.socialSurvey && remoteRecord.socialSurvey.length > 0){
            setSocialSurveyComplete(true);
          }

          if (remoteRecord.survey && remoteRecord.survey.length > 0){
            setCEOSurveyComplete(true);
          }

          if (remoteRecord.status && remoteRecord.status.toLowerCase() === "discharged"){
            setPatientTransferComplete(true);
          }
          if ( remoteRecord.housekeepingTaskId && remoteRecord.housekeepingTaskId!=null ){
            setHouseKeepingCleaingRequestId(remoteRecord.housekeepingTaskId.requestNo)
            setHouseKeepingCleaningComplete(remoteRecord.housekeepingTaskId.status==="completed"?true:false);
          }
        }
      })
      .catch((e) => {
        console.log("error after searching patient request", e);
      });

  }, []);


  function validateFields() {
    return (
      labComments &&
      labComments.length > 0 &&
      labPriority &&
      labPriority.length > 0 &&
      selectedLab
    );
  }

  if (openNotification) {
    setTimeout(() => {
      setOpenNotification(false);
      setErrorMsg("");
      setsuccessMsg("");
    }, 4000);
  }

  console.log("from reducer", props.selectedPatientDetails);

  return (
    <div
      style={{
        backgroundColor: "rgb(19 213 159)",
        position: "fixed",
        display: "flex",
        width: "100%",
        height: "100%",
        flexDirection: "column",
        flex: 1,
        overflowY: "scroll",
      }}
    >
      <Header history={props.history} />

      <PatientDetailsQuick
        edrId={patientDetails._id} />

      <div className="cPadding">
        <div className="subheader" style={{ marginLeft: "-10px" }}>
          <div>
            <img src={AssessIcon}/>

            <h4>
                {patientDetails.patientName}
            </h4>
          </div>
        </div>

        {patientDetails ? (
          <>
          <PatientDetails2
            edrId={patientDetails._id}
            showChiefComplaint={true}
            showTriageLevel={true}
            showCareStream={true}
            showDiagnosis={true} />
          
          </>
        ) : (
          undefined
        )}

        <div>
          <div
            style={{
              height: "20px",
            }}
          />

          {
            selectedRecord && selectedRecord.dischargeRequest?
            <div
              style={{ flex: 4, display: "flex", flexDirection: "column" }}
              className="container-fluid"
            >
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-body">
                      {
                        selectedRecord.dischargeRequest.dischargeSummary.edrCompletionReason === "deceased"?
                        <>
                          <div className="row mb-2">
                            <div className="col-md-8">ED Doctor: Notes/Instructions as to Deceased Patient</div>
                            <div className="col-md-4 text-right"><DoneIcon className="text-success" /></div>
                          </div>
                          <div className="row mb-2">
                            <div className="col-md-8">Porter Transfer of Patient to Morgue</div>
                            <div className="col-md-4 text-right"><CloseIcon className="text-danger" /></div>
                          </div>
                          <div className="row mb-2">
                            <div className="col-md-8">Housekeeping Room Cleaning/Restocking</div>
                            <div className="col-md-4 text-right"><CloseIcon className="text-danger" /></div>
                          </div>

                          <div className="row mb-2">
                            <div className="col-md-8">Sensei: Discharge/Disposition Completion Confirmation</div>
                            <div className="col-md-4 text-right"><CloseIcon className="text-danger" /></div>
                          </div>
                        </>:
                        <>
                          <div className="row mb-2">
                            <div className="col-md-8">ED Doctor - Discharge/Disposition Instructions Prepared</div>
                            <div className="col-md-4 text-right"><DoneIcon className="text-success" /></div>
                          </div>
                          <div className="row mb-2">
                            <div className="col-md-8">No Discharge Medication/Discharge Medication Prepared</div>
                            <div className="col-md-4 text-right"><DoneIcon className="text-success" /></div>
                          </div>
                          <div className="row mb-2">
                            <div className="col-md-8">ED Doctor instructions to Patient</div>
                            <div className="col-md-4 text-right">{edEducated?<DoneIcon className="text-success" />:<CloseIcon className="text-danger" />}</div>
                          </div>
                          <div className="row mb-2">
                            <div className="col-md-8">Registered Pharmacist - Patient Education</div>
                            <div className="col-md-4 text-right">{cpEducated?<DoneIcon className="text-success" />:<CloseIcon className="text-danger" />}</div>
                          </div>
                          <div className="row mb-2">
                            <div className="col-md-8">Social Woker Interview Completed</div>
                            <div className="col-md-4 text-right">{isSocialSurveyComplete?<DoneIcon className="text-success" />:<CloseIcon className="text-danger" />}</div>
                          </div>
                          <div className="row mb-2">
                            <div className="col-md-8">RCM Officer Completion of Bills</div>
                            <div className="col-md-4 text-right"><CloseIcon className="text-danger" /></div>
                          </div>
                          <div className="row mb-2">
                            <div className="col-md-8">Cashier Collection of Payment from Patient</div>
                            <div className="col-md-4 text-right"><CloseIcon className="text-danger" /></div>
                          </div>
                          <div className="row mb-2">
                            <div className="col-md-8">CEO ED Visit Survey Completed</div>
                            <div className="col-md-4 text-right">{isCEOSurveyComplete?<DoneIcon className="text-success" />:<CloseIcon className="text-danger" />}</div>
                          </div>
                          <div className="row mb-2">
                            <div className="col-md-8"></div>
                            <div className="col-md-4 text-right">
                              <Button
                                onClick={() => {
                                  setArrivedOpen(true);
                                  /*props.history.push({
                                    pathname: "/dashboard/home/success",
                                    state: {
                                      message1: `The patient has been discharged`,
                                    },
                                  });*/
                                }}
                                disabled={isPatientTransferComplete && isHouseKeepingCleaningComplete}
                                style={{
                                  fontSize: matches ? 12 : 8,
                                }}
                                variant="contained"
                                color="primary"
                              >
                                <strong>Discharge Now</strong>
                              </Button>
                            </div>
                          </div>
                          <div className="row mb-2">
                            <div className="col-md-8">CEO Patient Transfer Completed</div>
                            <div className="col-md-4 text-right">{isPatientTransferComplete?<DoneIcon className="text-success" />:<CloseIcon className="text-danger" />}</div>
                          </div>
                          <div className="row mb-2">
                            <div className="col-md-8">Housekeeping Room Cleaning/Restocking - { houseKeepingCleaingRequestId }</div>
                            <div className="col-md-4 text-right">{isHouseKeepingCleaningComplete?<DoneIcon className="text-success" />:<CloseIcon className="text-danger" />}</div>
                          </div>
                        </>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>:undefined
          }
          </div>

        <Dialog
          open={arrivedOpen}
          onClose={handleArrivedClose}
          aria-labelledby="alert-dialog-arrived-title"
          aria-describedby="alert-dialog-arrived-description"
        >
          <DialogTitle id="alert-dialog-arrived-title">{"Ready to Discharge?"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-arrived-description">
              {
                (edEducated & (cpEducated!="") & isSocialSurveyComplete & isCEOSurveyComplete) ?
                <>
                Are you sure that <strong>the patient is ready to discharge?</strong><br /><br />
                Do you want to confirm it?
                </>:
                <>
                  Cannot initiate the discharge request<br />Some of the tasks has not completed yet!
                </>
              }
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            {
                (edEducated & (cpEducated!="") & isSocialSurveyComplete & isCEOSurveyComplete) ?
                <>
                  <Button onClick={handleArrivedClose} color="primary">No</Button>
                  <Button onClick={() => {
                    handleSave('arrived');
                  }} color="primary" autoFocus>Yes</Button>
                </>: 
                <>
                  <Button onClick={handleArrivedClose} color="primary">Close</Button>
                </>
            }
          </DialogActions>
        </Dialog>

        <div
          className="container-fluid"
          style={{ marginBottom: "25px", marginTop: "25px" }}
        >
          <div className="row">
            <img
              onClick={() => props.history.goBack()}
              src={Back}
              style={{ width: 45, height: 35, cursor: "pointer" }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
const mapStateToProps = ({ CheckingReducer, PatientReducer }) => {
  const { count } = CheckingReducer;
  const { selectedPatientDetails } = PatientReducer;

  return { count, selectedPatientDetails };
};
export default connect(mapStateToProps, {
  funForReducer,
  setSelectedPatientForReducer,
  setPatientDetailsForReducer,
})(EpisodeInfoDetailPage);
