export const styles = {
  textFieldPadding: {
    paddingLeft: 5,
    paddingRight: 5,
  },
  stylesForButton: {
    color: "white",
    cursor: "pointer",
    borderRadius: 5,
    background: "#2c6ddd",
    width: "140px",
    height: "50px",
    outline: "none",
  },
  stylesForCancelButton: {
    color: "gray",
    cursor: "pointer",
    borderRadius: 5,
    width: "140px",
    height: "50px",
    background: "transparent",
    border: "1px solid gray",
  },
  unselectedChip: {
    color: "#070707",
    backgroundColor: "#F5F5F5",
    padding: "0px 20px 0px 20px",
  },
  selectedChip: {
    color: "white",
    backgroundColor: "rgb(19 213 159)",
    padding: "0px 20px 0px 20px",
  },
  textFieldMargin: {
    padding: "10px 0px 10px 18px",
  },
};
