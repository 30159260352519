import React, { PureComponent } from "react";
import { Route, Switch } from "react-router-dom";
import NotFound from "../components/NotFound/NotFound";

import ExternalConsultantsViews from "../views/ExternalConsultants/ExternalConsultants";
import ExternalConsultantsCases from "../views/ExternalConsultantsCases/ExternalConsultantsCases";
import ExternalConsultants from "../views/Home/AdminSubMenus/ExternalConsultants/ExternalConsultants";

export default class ExternalConsultantsRoutes extends PureComponent {
  render() {
    return (
      <Switch>
        <Route
          exact
          path={`${this.props.match.url}`}
          component={ExternalConsultants}
        />

        <Route
          exact
          path={`${this.props.match.url}/all`}
          component={ExternalConsultantsViews}
        />

        <Route
          exact
          path={`${this.props.match.url}/cases`}
          component={ExternalConsultantsCases}
        />

        <Route path="*" component={NotFound} />
      </Switch>
    );
  }
}
