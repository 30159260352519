/*eslint-disable*/
import React, { useState, useEffect, useReducer } from "react";
import Button from "@material-ui/core/Button";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Header from "../../components/Header/Header";
import Back from "../../assets/img/Back_Arrow.png";
import "../../assets/jss/material-dashboard-react/components/loaderStyle.css";
import Notification from "../../components/Snackbar/Notification.js";
import { styles, useStyles, useStylesForTabs } from "./styles.js";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import axios from "axios";
import cookie from "react-cookies";
import { addSurvey } from "../../public/endpoins";
import {
  performanceForStep1,
  performanceForStep2,
  performanceForStep3,
  performanceForStep4,
  performanceForStep5,
  performanceForStep6,
  performanceForStep7,
  performanceForStep8,
  performanceForStep9,
  performanceForStep10,
  performanceForStep11,
  performanceForStep12,
} from "./ConfigForServeyScreen";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const performanceArray = [
  {
    key: "Excellent",
    value: "Excellent",
  },
  {
    key: "Very Good",
    value: "Very Good",
  },
  {
    key: "Good",
    value: "Good",
  },
  {
    key: "Fair",
    value: "Fair",
  },
];

const performanceArray2 = [
  {
    key: "Much better",
    value: "Much better",
  },
  {
    key: "Little Better",
    value: "Little Better",
  },
  {
    key: "About the same",
    value: "About the same",
  },
  {
    key: "Little worse",
    value: "Little worse",
  },
];

const questions = [
  {
    key: 'q1',
    heading: '1. Thinking about this visit, what was the main reason why you went to the emergency department?',
    options: ['An accident or injury', 'A new health problem', 'An ongoing health condition or concern', ]
  },
  {
    key: 'q2',
    heading: '2. For this visit, did you go to the emergency department in an ambulance?',
    options: ['Yes', 'No', ]
  },
  {
    key: 'q3',
    heading: '3. When you first arrived at the emergency department, how long was it before someone talked to you about the reason why you were there?',
    options: ['Less than 5 minutes', '5 to 15 minutes', 'More than 15 minutes', ]
  },
  {
    key: 'q4',
    heading: '4. Using any number from 0 to 10, where 0 is not at all important and 10 is extremely important, when you first arrived at the emergency department, how important was it for you to get care right away? 0 – Not at all important and 10 – Extremely important',
    options: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10',  ]
  },
  {
    key: 'q5',
    heading: '5. During this emergency department visit, did you get care within 1 hour of getting to the emergency department?',
    options: ['Yes', 'No', ]
  },
  {
    key: 'q6',
    heading: '6. During this emergency department visit, did the doctors or nurses ask about all of the medicines you were taking?',
    options: ['Yes, definitely', 'Yes, somewhat', 'No', ]
  },
  {
    key: 'q7',
    heading: '7. During this emergency department visit, were you given any medicine that you had not taken before?',
    options: ['Yes', 'Don’t Know', 'No → If No, Go to Question 10', ]
  },
  {
    key: 'q8',
    heading: '8. Before giving you any new medicine, did the doctors or nurses tell you what the medicine was for?',
    options: ['Yes, definitely', 'Yes, somewhat', 'No', 'N/A', ]
  },
  {
    key: 'q9',
    heading: '9. Before giving you any new medicine, did the doctors or nurses describe possible side effects to you in a way you could understand?',
    options: ['Yes, definitely', 'Yes, somewhat', 'No', 'N/A', ]
  },
  {
    key: 'q10',
    heading: '10. During this emergency department visit, did you have any pain?',
    options: ['Yes, definitely', 'Yes, somewhat', 'No →If No, Go to Question 14',  ]
  },
  {
    key: 'q11',
    heading: '11. During this emergency department visit, did the doctors and nurses try to help reduce your pain?',
    options: ['Yes, definitely', 'Yes, somewhat', 'No', 'N/A', ]
  },
  {
    key: 'q12',
    heading: '12. During this emergency department visit, did you get medicine for pain?',
    options: ['Yes', 'No → If No, go to Question 14', 'N/A', ]
  },
  {
    key: 'q13',
    heading: '13. Before giving you pain medicine, did the doctors and nurses describe possible side effects in a way you could understand?',
    options: ['Yes, definitely', 'Yes, somewhat', 'No', 'N/A', ]
  },
  {
    key: 'q14',
    heading: '14. During this emergency department visit, did you have a blood test, x-ray, or any other test?',
    options: ['Yes', 'No → If No, go to Question 16', ]
  },
  {
    key: 'q15',
    heading: '15. During this emergency department visit, did doctors and nurses give you as much information as you wanted about the results of these tests?',
    options: ['Yes, definitely', 'Yes, somewhat', 'No', 'N/A', ]
  },
  {
    key: 'q16',
    heading: '16. During this emergency department visit, how often did nurses treat you with courtesy and respect?',
    options: ['Never', 'Sometimes', 'Usually', 'Always', ]
  },
  {
    key: 'q17',
    heading: '17. During this emergency department visit, how often did nurses listen carefully to you?',
    options: ['Never', 'Sometimes', 'Usually', 'Always', ]
  },
  {
    key: 'q18',
    heading: '18. During this emergency department visit, how often did nurses explain things in a way you could understand?',
    options: ['Never', 'Sometimes', 'Usually', 'Always', ]
  },
  {
    key: 'q19',
    heading: '19. During this emergency department visit, did nurses spend enough time with you?',
    options: ['Yes, definitely', 'Yes, somewhat', 'No', ]
  },
  {
    key: 'q20',
    heading: '20. During this emergency department visit, how often did doctors treat you with courtesy and respect?',
    options: ['Never', 'Sometimes', 'Usually', 'Always', ]
  },
  {
    key: 'q21',
    heading: '21. During this emergency department visit, how often did doctors listen carefully to you?',
    options: ['Never', 'Sometimes', 'Usually', 'Always', ]
  },
  {
    key: 'q22',
    heading: '22. During this emergency department visit, how often did doctors explain things in a way you could understand?',
    options: ['Never', 'Sometimes', 'Usually', 'Always', ]
  },
  {
    key: 'q23',
    heading: '23. During this emergency department visit, did doctors spend enough time with you?',
    options: ['Yes, definitely', 'Yes, somewhat', 'No', ]
  },
  {
    key: 'q24',
    heading: '24. Before you left the emergency department, did you understand what your main health problem was?',
    options: ['Yes', 'No', ]
  },
  {
    key: 'q25',
    heading: '25. Before you left the emergency department, did you understand what symptoms or health problems to look out for when you left the emergency department?',
    options: ['Yes', 'No', ]
  },
  {
    key: 'q26',
    heading: '26. Before you left the emergency department, did a doctor or nurse tell you that you should take any new medicines that you had not taken before?',
    options: ['Yes', 'No →If No, Go to Question 28', ]
  },
  {
    key: 'q27',
    heading: '27. Before you left the emergency department, did a doctor or nurse tell you what the new medicines were for?',
    options: ['Yes, definitely', 'Yes, somewhat', 'No', 'N/A', ]
  },
  {
    key: 'q28',
    heading: '28. Before you left the emergency department, did someone discuss with you whether you needed follow-up care?',
    options: ['Yes', 'No→ If No, Go to Question 30', ]
  },
  {
    key: 'q29',
    heading: '29. Before you left the emergency department, did someone ask if you would be able to get this follow-up care?',
    options: ['Yes', 'No', 'N/A', ]
  },
  {
    key: 'q30',
    heading: '30. Using any number from 0 to 10, where 0 is the worst care possible and 10 is the best care possible, what number would you use to rate your care during this emergency department visit? ( 0 - Worst care possible, 10 – Best care possible',
    options: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', ]
  },
  {
    key: 'q31',
    heading: '31. Would you recommend this emergency department to your friends and family?',
    options: ['Definitely no', 'Probably no', 'Probably yes', 'Definitely yes', ]
  },
  {
    key: 'q32',
    heading: '32. Overall, how long did your visit to the emergency department last?',
    options: ['Less than 1 hour', '1 – 3 hours', '3 – 6 hours', '6 – 10 hours', '10 – 12 hours', '12 – 24 hours', 'Greater than 24 hours', ]
  },
  {
    key: 'q33',
    heading: '33. During this emergency department visit, did you have to wait too long to get care?',
    options: ['Yes, definitely', 'Yes, somewhat', 'No', ]
  },
  {
    key: 'q34',
    heading: '34. In the last 6 months, how many times have you visited any emergency department to get care for yourself? Please include the emergency department visit you have been answering questions about in this survey.',
    options: ['1 time', '2 times', '3 times', '4 times', '5 to 9 times', '10 or more times', ]
  },
  {
    key: 'q35',
    heading: '35. Not counting the emergency department, is there a doctor’s office, clinic, or other place you usually go if you need a check-up, want advice about a health problem, or get sick or hurt?',
    options: ['Yes', 'No →If No, Go to Question37', ]
  },
  {
    key: 'q36',
    heading: '36. How many times in the last 6 months did you visit that doctor’s office, clinic, health center, or otherplace to get care or advice about your health?',
    options: ['None', '1 time', '2 times', '3 times', '4 times', '5 to 9 times', '10 or more times', 'N/A', ]
  },
  {
    key: 'q37',
    heading: '37. In general, how would you rate your overall physical health?',
    options: ['Excellent','Very good','Good','Fair','Poor',]
  },
  {
    key: 'q38',
    heading: '38. In general, how would you rate your overall mental or emotional health?',
    options: ['Excellent','Very good','Good','Fair','Poor',]
  },
  {
    key: 'q40',
    heading: '40. What is your gender?',
    options: ['Male','Female','Other',]
  },
]


export default function DischargeSubTasks(props) {
  const classesForTabs = useStylesForTabs();
  const classes = useStyles();
  const matches = useMediaQuery("(min-width:600px)");

  const initialState = {
    textField: "",
  };

  function reducer(state, { field, value }) {
    return {
      ...state,
      [field]: value,
    };
  }

  const [state, dispatch] = useReducer(reducer, initialState);

  const { textField } = state;
  const [ questionAnswers, setQuestionAnswers ] = useState({});

  const [cookies, setCookies] = useState(cookie.load("current_user"));
  const [counter, setCounter] = useState(1);
  const [onCheck, setOnCheck] = useState("");
  const [step1, setStep1] = useState("");
  const [step2, setStep2] = useState("");
  const [step3, setStep3] = useState("");
  const [step4, setStep4] = useState("");
  const [step5, setStep5] = useState("");
  const [step6, setStep6] = useState("");
  const [step7, setStep7] = useState("");
  const [step8, setStep8] = useState("");
  const [step9, setStep9] = useState("");
  const [step10, setStep10] = useState("");
  const [step11, setStep11] = useState("");
  const [step12, setStep12] = useState("");
  const [generalArray, setGeneralArray] = useState([]);
  const [progressValue, setProgressValue] = useState(1);
  const [percentageValue, setPercentageValue] = useState(8.33);
  const [openNotification, setOpenNotification] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setsuccessMsg] = useState("");

  // console.log("propss", props);

  // useEffect(() => {
  //   console.log("staffId", cookies);
  // }, []);

  function handleNext() {
    if ( counter <= (questions.length - 1) ){

      if ( questionAnswers[ `q${counter}` ] ){
        let newCounter = (counter + 1);
        setCounter( newCounter )
        setProgressValue( newCounter );
        let percentageStep = (100 / Math.round(questions.length)) * newCounter;
        setPercentageValue(percentageStep);
      }else{
        setErrorMsg("Please select an answer.");
        setOpenNotification(true);
      }
    }

    /*
    console.log("generalArray", generalArray);
    if ((counter === 1 && step1 !== "") || (counter === 8 && step11 !== "")) {
      let count = counter + 1;
      setCounter(count);
      let progressStep = progressValue + 1;
      setProgressValue(progressStep);
      let percentageStep = 8.33 * progressStep;
      setPercentageValue(percentageStep);
      setGeneralArray([]);
    } else if (generalArray.length > 0 && counter > 1) {
      let count = counter + 1;
      setCounter(count);
      setGeneralArray([]);
      let progressStep = progressValue + 1;
      setProgressValue(progressStep);
      let percentageStep = 8.33 * progressStep;
      setPercentageValue(percentageStep);
    } else {
      console.log("please enter data");
      setErrorMsg("Please select some data");
      setOpenNotification(true);
    }*/
  }
  function handleBackArrow() {
    console.log("generalArray", generalArray);
    if ( counter > 1 ){
      let newCounter = (counter - 1);
      setCounter( newCounter )
      setProgressValue( newCounter );
      let percentageStep = (100 / Math.round(questions.length)) * newCounter;
      setPercentageValue(percentageStep);
    }
  }

  function handleBack() {
    // if (counter === 1) {
    props.history.goBack();
    // } else {
    //   let count = counter - 1
    //   setCounter(count)
    //   // setGeneralArray([])
    //   let progressStep = progressValue - 1
    //   setProgressValue(progressStep)
    //   let percentageStep = 8.33 * progressStep
    //   setPercentageValue(percentageStep)
    // }
  }

  function handleSubmit() {
    // console.log(step2)
    console.log(props.location.state.selectedRecord, "record of submit");
    const params = {
      edrId: props.location.state.selectedRecord._id,
      object: generalArray,
      staffId: cookies._id,
    };
    console.log("params", params);

    axios
      .put(addSurvey, params)
      .then((res) => {
        if (res.data.success) {
          console.log(res.data.data, "response");
          props.history.push({
            pathname: "/dashboard/home/success",
            state: {
              //message: "For testing",
              reqCode: `${
                res.data.data.survey[res.data.data.survey.length - 1].requestId
              }`,
              message3: `: For taking the time to share your thoughts with us against Patient`,
              code: `MRN: ${res.data.data.patientId.identifier[0].value}`,
              message2: `we wish you a fast recovery`,
            },
          });
        }
      })
      .catch((e) => {
        console.log("error ", e);
        setOpenNotification(true);
        setErrorMsg("fff");
      });
  }

  const onChangeCheck = (e, key, stepNo) => {
    console.log("SurveyScreen::Check::", e.target);
    console.log("SurveyScreen::Check::value", e.target.value);


    let tmpQA = questionAnswers;

    if ( tmpQA[key] ){
      questionAnswers[key] = e.target.value;
    }else{
      questionAnswers[key] = e.target.value;
    }
    setQuestionAnswers(tmpQA);


    let checkObject = {
      key: key,
      value: e.target.value,
    };


    let found = generalArray.find((val) => val.key === key);
    console.log("SurveyScreen::Check::", found);

    let temp = [];
    if (found) {
      found = {
        ...found,
        value: e.target.value,
      };
      temp = [...generalArray];
      for (let i = 0; i < generalArray.length; i++) {
        if (generalArray[i].key === e.target.key) {
          temp[i] = found;
        }
      }
    } else {
      temp = [...generalArray, checkObject];
    }

    console.log("SurveyScreen::Check::temp::", [...temp]);
    setGeneralArray([...temp]);




  };

  const onChangeValue = (e, heading, stepNo) => {
    console.log("e", e.target);
    let found = generalArray.find((val) => val.name === e.target.name);
    console.log(found);
    let temp = [];
    if (found) {
      found = {
        ...found,
        value: e.target.value,
      };
      temp = [...generalArray];
      for (let i = 0; i < generalArray.length; i++) {
        if (generalArray[i].name === e.target.name) {
          temp[i] = found;
        }
      }
    } else {
      temp = [...generalArray, e.target];
    }

    setGeneralArray([...temp]);
    let obj = { key: heading, value: temp };
    if (stepNo === "2") {
      setStep2(obj);
    } else if (stepNo === "3") {
      setStep3(obj);
    } else if (stepNo === "4") {
      setStep4(obj);
    } else if (stepNo === "5") {
      setStep5(obj);
    } else if (stepNo === "6") {
      setStep6(obj);
    } else if (stepNo === "7") {
      setStep7(obj);
    } else if (stepNo === "8") {
      setStep8(obj);
    } else if (stepNo === "9") {
      setStep9(obj);
    } else if (stepNo === "10") {
      setStep10(obj);
    }
  };
  // console.log("step1", step1);
  // console.log("step2", step2);
  // console.log("step3", step3);

  const onChangeTextField = (e, heading) => {
    console.log("e.target", e.target.value, e.target.name);

    dispatch({
      field: e.target.name,
      value: e.target.value,
    });
    let obj = {
      key: heading,
      text: e.target.value,
    };
    setStep12(obj);
  };

  console.log("text", textField);
  if (openNotification) {
    setTimeout(() => {
      setOpenNotification(false);
      setErrorMsg("");
      setsuccessMsg("");
    }, 2000);
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        position: "fixed",
        width: "100%",
        height: "100%",
        backgroundColor: "#60d69f",
        overflowY: "scroll",
      }}
    >
      <Header history={props.history} />

      <div className="cPadding">
        <div
          className="container-fluid"
          style={{ paddingLeft: 0, paddingRight: 0 }}
        >
          <div
            className="row"
            style={{
              display: "flex",
              flex: 1,
              justifyContent: "center",
              paddingLeft: 6,
              paddingRight: 6,
            }}
          >
            <div
              style={{
                display: "flex",
                flex: 1,
                justifyContent: "center",
                // marginTop: '2%',
                marginBottom: "2%",
              }}
            >
              <div style={{ height: "100px", width: "100px" }}>
                <CircularProgressbar
                  styles={buildStyles({
                    textColor: "WHITE",
                    pathColor: "WHITE",
                    trailColor: "#A9ECC8",
                    // fontWeight: 'bold',
                  })}
                  strokeWidth={10}
                  value={percentageValue}
                  text={`${progressValue}/${questions.length}`}
                />
              </div>
            </div>
          </div>
          <div
            style={{
              flex: 4,
              display: "flex",
              flexDirection: "column",
              backgroundColor: "white",

              borderRadius: 5,
              padding: 40,
            }}
          >
            <div className="row">
              {
                questions[counter - 1]?
                <>
                  <div className="col-md-12 col-sm-12" style={styles.form}>
                    <div style={{ fontWeight: "bold", paddingBottom: 30 }}>{questions[counter - 1].heading}</div>
                    <FormControl>
                      <RadioGroup
                        name="onCheck"
                        onChange={(e) =>
                          onChangeCheck(e, questions[counter - 1].key, counter)
                        }
                        value={questionAnswers[ questions[counter - 1].key ]?questionAnswers[ questions[counter - 1].key ]:''}
                      >
                        {
                          questions[counter - 1].options.map((v) => {
                            return (
                              <FormControlLabel
                                value={v}
                                control={<Radio />}
                                color="default"
                                label={v}
                              />
                            )
                          })
                        }
                      </RadioGroup>
                    </FormControl>
                  </div>
                  <div className="col-12 mb-2"><hr /></div>
                </>:undefined
              }
            </div>
          </div>
          {counter === 1 ? (
            undefined
          ) : (
            <div className="row" style={{ marginBottom: 20, marginTop: 20 }}>
              <div className="col-md-12 col-sm-12 col-12">
                <img
                  onClick={handleBackArrow}
                  src={Back}
                  style={{
                    width: 45,
                    height: 35,
                    cursor: "pointer",
                  }}
                />
              </div>
            </div>
          )}

          <div
            // class='row'
            className="container-fluid"
            style={{
              display: "flex",
              flex: 1,
              justifyContent: "center",
              paddingLeft: 6,
              paddingRight: 6,
            }}
          >
            <div
              style={{
                display: "flex",
                flex: 1,
                justifyContent: "flex",
                marginTop: "2%",
                marginBottom: "2%",
                marginLeft: "-8px",
              }}
            >
              {/* <img
                onClick={handleBack}
                src={Back}
                style={{
                  width: 45,
                  height: 35,
                  cursor: 'pointer',
                }}
              /> */}
              <Button
                onClick={handleBack}
                style={{
                  ...styles.stylesForNextButton,
                  fontSize: matches ? " " : 10,
                }}
                variant="contained"
                color="primary"
              >
                <strong>Cancel</strong>
              </Button>
            </div>

            <div
              style={{
                display: "flex",
                flex: 1,
                justifyContent: "flex-end",
                marginTop: "2%",
                marginBottom: "2%",
                marginRight: "-6px",
              }}
            >
              <Button
                onClick={counter === (questions.length) ? handleSubmit : handleNext}
                style={{
                  ...styles.stylesForNextButton,
                  fontSize: matches ? " " : 10,
                }}
                variant="contained"
                color="primary"
              >
                <strong>{counter === (questions.length) ? "Submit" : "Next"}</strong>
              </Button>
            </div>
          </div>
        </div>
      </div>

      <Notification
        msg={errorMsg}
        open={openNotification}
        success={successMsg}
      />
    </div>
  );
}
