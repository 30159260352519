import React from "react"
import ContainerCS from "../../../components/CareStream/ContainerCS"
import { treatmentNFluidsStyles } from "../styles"

export default function TreatmentAndFluids(props) {
  const classes = treatmentNFluidsStyles()

  const getTandOData = (data) => {
    props.getTandO(data)
  }

  const getFluidsData = (data) => {
    props.getFluids(data)
  }

  return (
    <div className={classes.root}>
      <div className="subheader" style={{ marginLeft: "-10px" }}>
        <div style={{ flex: 4, display: "flex", alignItems: "center" }}>
          <h4 style={{ color: "white", fontWeight: "700", marginLeft: 20 }}>
            Treatments and Orders
          </h4>
        </div>
      </div>

      <ContainerCS
        subType={props.subTypeOfArray}
        heading="Treatment Order"
        buttonHeading="Treatment Order"
        getData={getTandOData}
      />

      <div style={{ flex: 4, display: "flex", alignItems: "center" }}>
        <h4 style={{ color: "white", fontWeight: "700", marginLeft: 9 }}>
          IV Fluids
        </h4>
      </div>

      <ContainerCS
        heading="IV Fluids"
        buttonHeading="IV Fluids"
        getData={getFluidsData}
      />
    </div>
  )
}
