import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import EyeIcon from "../../../src/assets/img/Eye.png";

import Dialer from "../../components/Dial/Dialer";
import Graph from "../../components/Dial/Graph";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="" ref={ref} {...props} />;
});

export default function FullScreenDialog(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [buttonchange, setButtonchange] = useState(true);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        fullScreen
        open={props.openIt}
        onClose={() => setOpen(true)}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => props.setChange(true)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            {/* <Typography variant="h6" className={classes.title}>
              Sound
            </Typography> */}
            {/* <Button autoFocus color="inherit" onClick={handleClose}>
              save
            </Button> */}
          </Toolbar>
        </AppBar>

        <Divider />

        {buttonchange ? (
          <div>
            <Dialer
              mainHeading={props.mainHeading}
              value={props.value}
              color={props.color}
              subHeading={props.subHeading}
              childHeading={props.childHeading}
              time={props.time}
              timecolor={props.timecolor}
            />
          </div>
        ) : (
          <div>
            {console.log("this is graph component")}
            <Graph
              // data={[]}
              mainHeadingForGraph={props.mainHeadingForGraph}
              graphValue={props.value}
              idForGraph={props.idForGraph}
              data={props.data}
            />
          </div>
        )}
        <div className="container-fluid">
          <div
            className="row"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {buttonchange ? (
              <button
                type="button"
                onClick={() => setButtonchange(!buttonchange)}
                class="btn btn-primary"
                // style={{ backgroundColor: "red" }}
              >
                <span style={{ color: "#fff" }}>
                  {" "}
                  <img
                    style={{
                      width: 20,
                      height: 14,
                      marginRight: 5,
                      marginBottom: 3,
                    }}
                    src={EyeIcon}
                  />
                  Last 06 Hours
                </span>
              </button>
            ) : (
              undefined
            )}

            {!buttonchange ? (
              <button
                type="button"
                onClick={() => setButtonchange(true)}
                class="btn btn-primary"
                // style={{ backgroundColor: "red" }}
              >
                <span style={{ color: "#fff" }}>
                  {" "}
                  <img
                    style={{
                      width: 20,
                      height: 14,
                      marginRight: 5,
                      marginBottom: 3,
                    }}
                    src={EyeIcon}
                  />
                  Turn Around Time
                </span>
              </button>
            ) : (
              undefined
            )}
          </div>
        </div>
      </Dialog>
    </div>
  );
}
