import React, { useEffect, useState } from 'react';
import Notification from "../../components/Snackbar/Notification.js";
import Loader from "react-loader-spinner";
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import CustomTable from "../../components/Table/Table";
import Header from "../../components/Header/Header";
import DoctorIcon from "../../assets/img/ED Doctors - Physicians.png";
import NurseIcon from "../../assets/img/ED Nurses.png";
import Back from "../../assets/img/Back_Arrow.png";
import useStylesForInput from '../../assets/jss/material-dashboard-react/inputStyle'
import { getStaffShiftData } from './networkCalls'
import { styles } from './style'
import { getDoctorSpecialtyFunc, getNursesSpecialtyFunc } from '../UserManagement/staff/networkCallsForStaff'
import {
   tableHeadingForDoctors,
   tableDataKeysForDoctors,
   tableHeadingForNurses,
   tableDataKeysForNurses
} from "./configForTables"

export default function StaffShift(props) {

   const classes = useStylesForInput()
   const [errorMsg, setErrorMsg] = useState("");
   const [openNotification, setOpenNotification] = useState(false);
   const [isLoading, setisLoading] = useState(true)
   const [tableDataArray, setTableDataArray] = useState([])
   const [filteredTableDataArray, setFilteredTableDataArray] = useState([])
   const [shift, setShift] = useState('')
   const [specialist, setSpecialist] = useState('')
   const [staffType, setStaffType] = useState('')
   const [doctorSpeciality, setDoctorSpeciality] = useState([])
   const [nursesSpeciality, setNursesSpeciality] = useState([])

   useEffect(() => {
      let comingFrom = props.history.location.state.comingFrom.split(' ')
      setShift(comingFrom[3])
      setStaffType(comingFrom[0])

      getStaffShiftData(
         comingFrom[3],
         comingFrom[0],
         setTableDataArray,
         setFilteredTableDataArray,
         setisLoading,
         setOpenNotification,
         setErrorMsg
      )
      getDoctorSpecialtyFunc(
         setDoctorSpeciality,
         setOpenNotification,
         setErrorMsg
      )
      getNursesSpecialtyFunc(
         setNursesSpeciality,
         setOpenNotification,
         setErrorMsg
      )
   }, [])

   const onChangeSpecialist = (e) => {
      console.log("specialist selected : ", e.target.value);
      setSpecialist(e.target.value)
      if (e.target.value) {
         const temp = tableDataArray.filter((val) =>
            val.specialty.find((s) => s === e.target.value)
         )
         setFilteredTableDataArray(temp)
      }
      else {
         setFilteredTableDataArray(tableDataArray)
      }
   }

   return (
      <div
         style={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            position: "fixed",
            width: "100%",
            height: "100%",
            backgroundColor: "#60d69f",
            overflowY: "scroll",
         }}
      >
         <Header history={props.history} />

         <div className="cPadding">
            <div className="subheader">
               <div>
                  <img src={staffType === "Doctors" ? DoctorIcon : NurseIcon} alt="StaffTypeIcon" />
                  <h4>{staffType} Working in {shift} Shift</h4>
               </div>
               <div className={classes.root} style={styles.stylesForTextfield}>
                  <TextField
                     select
                     fullWidth
                     id='specialist'
                     name='specialist'
                     value={specialist}
                     onChange={onChangeSpecialist}
                     label='Select Speciality'
                     variant='filled'
                     className='textInputStyle'
                     InputProps={{
                        className: classes.input,
                        classes: { input: classes.input },
                        disableUnderline: true
                     }}
                  >
                     <MenuItem value=''>
                        <em>None</em>
                     </MenuItem>

                     {staffType === 'Doctors' ?
                        doctorSpeciality &&
                        doctorSpeciality.map((val) => {
                           return <MenuItem value={val}>{val}</MenuItem>
                        })
                        : nursesSpeciality &&
                        nursesSpeciality.map((val) => {
                           return <MenuItem value={val}>{val}</MenuItem>
                        })
                     }
                  </TextField>
               </div>
            </div>

            <div className='container-fluid'>
               <div className='row'>
                  {filteredTableDataArray.length > 0 && !isLoading ? (
                     <div className='col-12'>
                        <CustomTable
                           tableData={filteredTableDataArray}
                           tableHeading={staffType === "Doctors" ? tableHeadingForDoctors : tableHeadingForNurses}
                           tableDataKeys={staffType === "Doctors" ? tableDataKeysForDoctors : tableDataKeysForNurses}
                           borderBottomColor={"#60d69f"}
                           borderBottomWidth={20}
                        />
                     </div>
                  ) : isLoading ? (
                     <div style={{ margin: "auto", width: "0%", padding: 10 }}>
                        <Loader
                           type="TailSpin"
                           color="blue"
                           height={50}
                           width={50}
                        />
                     </div>
                  ) : (
                           <div className='col-md-12 col-sm-12 col-12'>
                              <h3
                                 style={{
                                    color: "white",
                                    textAlign: "center",
                                    margin: 'revert'
                                 }}
                              >
                                 No {staffType} working in {shift} Shift.
                              </h3>
                           </div>
                        )}
               </div>

               <div className="row" style={{ marginBottom: 20 }}>
                  <div className='col'>
                     <img
                        onClick={() => props.history.goBack()}
                        src={Back}
                        style={{
                           width: 45,
                           height: 35,
                           cursor: "pointer",
                        }}
                     />
                  </div>
               </div>
            </div>
            <Notification msg={errorMsg} open={openNotification} />
         </div>
      </div>
   );
}