import axios from 'axios'
import {
  getEOUPatients,
  getEDPatients,
  searchEOUPatients,
  searchEDPatients
} from '../../public/endpoins'
import _ from 'lodash'

export function getAllEdrPatients(
  setLoading,
  setPatientData,
  setErrorMsg,
  setOpenNotification
) {
  axios
    .get(getEDPatients)
    .then((res) => {
      if (res.data.success) {
        res.data.data.map((d) => (
          d.patientName = d.patientId.name[0].given[0] + ' ' + d.patientId.name[0].family,
          d.mrn = d.patientId.identifier[0].value,
          d.gender = d.patientId.gender,
          d.age = d.patientId.age,
          d.createdAt = d.patientId.createdAt
        ))
        console.log(res.data.data, 'ED patient data')
        setPatientData(res.data.data.reverse())
        setLoading(false)
      } else if (!res.data.success) {
        setErrorMsg(res.data.error)
        setOpenNotification(true)
      }
      return res
    })
    .catch((e) => {
      console.log('error: ', e)
    })
}

export function getAllEOUPatients(
  setLoading,
  setPatientData,
  setErrorMsg,
  setOpenNotification
) {
  axios
    .get(getEOUPatients)
    .then((res) => {
      if (res.data.success) {
        res.data.data.map((d) => (
          d.patientName = d.patientId.name[0].given[0] + ' ' + d.patientId.name[0].family,
          d.mrn = d.patientId.identifier[0].value,
          d.gender = d.patientId.gender,
          d.age = d.patientId.age,
          d.createdAt = d.patientId.createdAt
        ))
        console.log(res.data.data, 'EOU patient data')
        setPatientData(res.data.data.reverse())
        setLoading(false)
      } else if (!res.data.success) {
        setErrorMsg(res.data.error)
        setOpenNotification(true)
      }
      return res
    })
    .catch((e) => {
      console.log('error: ', e)
    })
}

export const searchEdrPatientsFor = (
  a,
  from,
  setPatientData,
  setErrorMsg,
  setOpenNotification
) => {
  let url;
  if (from === "EOU") {
    url = searchEOUPatients
  } else if (from === "ED") {
    url = searchEDPatients
  }
  axios
    .get(url + '/' + a)
    .then((res) => {
      if (res.data.success) {
        res.data.data.map((d) => (
          d.patientName = d.patientId.name[0].given[0] + ' ' + d.patientId.name[0].family,
          d.mrn = d.patientId.identifier[0].value,
          d.gender = d.patientId.gender,
          d.age = d.patientId.age,
          d.createdAt = d.patientId.createdAt
        ))
        console.log(res.data.data, 'patient data from search')
        setPatientData(res.data.data)
      } else if (!res.data.success) {
        setErrorMsg(res.data.error)
        setOpenNotification(true)
      }
    })
    .catch((e) => {
      console.log('error after searching patient request', e)
    })
}