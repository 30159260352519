import axios from 'axios'
import {
    getNursesAssignedInShifts,
    getDoctorsAssignedInShifts
} from '../../public/endpoins'

export function getStaffShiftData(
    shift,
    staffType,
    setTableDataArray,
    setFilteredTableDataArray,
    setisLoading,
    setOpenNotification,
    setErrorMsg
) {
    let url
    if (staffType === "Doctors") {
        url = getDoctorsAssignedInShifts
    } else if (staffType === "Nurses") {
        url = getNursesAssignedInShifts
    }
    axios.get(url)
        .then((res) => {
            console.log("Response for ", staffType, res.data.data)
            if (res.data.success) {

                let filteredArray = res.data.data.filter(data => data.shift.name === shift)
                filteredArray.map(d => (
                    d.name = d.name[0].given[0] + " " + d.name[0].family,
                    d.identifier = d.identifier[0].value,
                    d.subType = d.subType.toString(),
                    d.speciality = d.specialty.length > 0 ? d.specialty.toString() : "Not Assigned",
                    d.paName = d.chiefComplaint[d.chiefComplaint.length - 1].chiefComplaintId
                        .productionArea[d.chiefComplaint[d.chiefComplaint.length - 1].chiefComplaintId.productionArea.length - 1].productionAreaId.paName
                ))
                setTableDataArray(filteredArray)
                setFilteredTableDataArray(filteredArray)
                setisLoading(false)
            }
            else {
                setErrorMsg("Cannot get Staffs data at the moment")
                setOpenNotification(true)
            }
        })
        .catch((e) => {
            console.log("error : ", e)
        })
}